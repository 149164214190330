<template>
    <td class="io-cell-icon-action" :class="cellClass">
        <template v-if="!isHidden()">
            <div @click.stop="emitAction">
                <i v-if="tooltip" class="fas" :class="[iconName, { 'io-disabled': cantDeleteContact }]" v-tooltip.top="{ content: tooltip, visible: tooltip }"></i>

                <i v-else class="fas" :class="iconName"></i>

                <span v-if="iconText">{{ iconText }}</span>
            </div>
        </template>
    </td>
</template>

<script lang="ts" src="./IconAction.ts"></script>
<style lang="scss" src="./IconAction.scss" scoped></style>
