import * as getters from './getters'

export default {
    namespaced: true,
    namespace: 'main/',
    state () {
        return {
            openLeftMenu: false,
            isLeftMenuWider: false,
            lockLeftMenu: null,
            hoverLeftMenu: false,
            topBarInFront: false,
            pageTemplate: false,
            tmp: null,
            routerHistory: {
                routerTo: null,
                routerFrom: null
            },
            usersOnline: [],
            openTaskModal: false,
        }
    },
    mutations: {
        SET_TOPBARINFRONT (state, value) {
            state.topBarInFront = value
        },
        SET_OPENLEFTMENU (state, value) {
            state.openLeftMenu = value
        },
        SET_LOCKLEFTMENU (state, value) {
            state.lockLeftMenu = value
        },
        SET_IS_LEFT_MENU_WIDER (state, value) {
            state.isLeftMenuWider = value
        },
        SET_TMP (state, value) {
            state.tmp = value
        },
        SET_ROUTER (state, value) {
            state.routerHistory['routerTo'] = value.routerTo
            state.routerHistory['routerFrom'] = value.routerFrom
        },
        SET_USERS_ONLINE (state, value) {
            state.usersOnline = value
        },
        ADD_USER_ONLINE (state, value) {
            state.usersOnline.push(value)
        },
        REMOVE_USER_ONLINE (state, value) {
            let index = state.usersOnline.findIndex(user => user.id === value.id)
            if (index > -1) state.usersOnline.splice(index, 1)
        },
        SET_HOVER_LEFT_MENU (state, value) {
            state.hoverLeftMenu = value
        },
        SET_PAGE_TEMPLATE (state, value) {
            state.pageTemplate = value
        },
        SET_OPENTASKMODAL (state, value) {
            state.openTaskModal = value
        }
    },
    actions: {
        setTopBarInFront ({commit}, value) {
            commit('SET_TOPBARINFRONT', value)
        },
        setLeftMenuStatus ({commit}, value) {
            commit('SET_OPENLEFTMENU', value)
        },
        setLockLeftMenu ({commit}, value) {
            commit('SET_LOCKLEFTMENU', value)
        },
        setIsLeftMenuWider ({commit}, value) {
            commit('SET_IS_LEFT_MENU_WIDER', value)
        },
        setTmp ({commit}, value) {
            commit('SET_TMP', value)
        },
        setRouter ({ commit, dispatch }, value) {
            commit('SET_ROUTER', value || {})
        },
        setUsersOnline ({ commit, dispatch }, value) {
            commit('SET_USERS_ONLINE', value || {})
        },
        addUserOnline ({ commit, dispatch }, value) {
            commit('ADD_USER_ONLINE', value || {})
        },
        removeUserOnline ({ commit, dispatch }, value) {
            commit('REMOVE_USER_ONLINE', value || {})
        },
        setHoverLeftMenu ({ commit }, value) {
            commit('SET_HOVER_LEFT_MENU', value)
        },
        setPageTemplate ({ commit }, value) {
            commit('SET_PAGE_TEMPLATE', value)
        },
        setTaskModalOpenStatus ({ commit }, value) {
            commit('SET_OPENTASKMODAL', value)
        }
    },
    getters
}
