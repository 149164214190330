import { defineComponent } from 'vue'
import { mapState } from 'vuex'
// @ts-ignore
import modalMixin from '@/mixins/modalMixin'

export default defineComponent({
    name: 'FiltersButton',
    props: {
        count: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tooltipText: {
            type: String,
            default: 'Filters',
        },
    },
    mixins: [modalMixin],
    computed: {
        ...mapState('filtersModal', {
            // @ts-ignore
            filtersCounter: state => state.filtersCounter
        }),
        computedFiltersCounter (): number {
            return this.count ?? this.filtersCounter
        },
    },
    methods: {
        openFilters (): void {
            if (this.disabled) {
                return
            }
            this.openModal('modal-filters')
        },
    },
})
