<template>
    <PopupModal :width="850" customClass="io-popup-client-approval" :showLoader="loading">

        <template #header>
            <div class="io-popup-modal__title">
                {{ $t('Record Client Approval') }}
            </div>
            <div class="io-popup-modal__close" @click="closePopup"><i class="fal fa-times"></i></div>
        </template>

        <template #default>
            <div class="io-f-row">
                <FormInputColumn
                    :label="$tc('Approved By')"
                    :required="true"
                    :customContent="true"
                >
                    <input type="text" v-model="approved_by"/>
                </FormInputColumn>

                <FormInputColumn
                    :label="$tc('Date Approved')"
                    :required="true"
                    :customContent="true"
                >
                    <FieldDateTime :options="datepickerOptions" v-model="approved_date"/>
                </FormInputColumn>
            </div>

            <FormInputColumn
                :label="$tc('Type “CONFIRM“ to confirm client approval and add a note.')"
                :required="true"
                :customContent="true"
            >
                <input
                    :placeholder="$tc('Type “CONFIRM“ in Uppercase')"
                    type="text"
                    v-model.trim="confirmText"
                />
            </FormInputColumn>

            <FormInputColumn
                :label="$tc('Add Note')"
                :customContent="true"
            >
                <textarea v-model="note"></textarea>
            </FormInputColumn>

            <FilesComponent
                :key="`client_approval_${resourceId}`"
                :arrayId="`client_approval_${resourceId}`"
                :showShared="false"
                :forceHideSharedTab="true"
                :showUploader="true"
                :hideShareColumn="true"
                modelType="resource_client_approval_record"
                :filesSection="activeResource"
                :addToTemporary="true"
                :modelId="resourceId"
                :filesInNewTab="true"
                :showModalVersion="true"
                @newFileAdded="onFileAdd"
                @tempFileRemove="onFileRemove"
            />
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="closePopup">{{ $t('Cancel') }}</IOButton>
            <IOButton :disabled="!isValid" @click="recordClientApproval">{{ $t('Record Client Approval') }}</IOButton>
        </template>

    </PopupModal>
</template>

<script lang="ts" src="./PopupClientApproval.ts"></script>
<style lang="scss" src="./PopupClientApproval.scss" scoped></style>
