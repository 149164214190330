export function SET_MODEL (state, val) {
    state['model'] = val
}

export function SET_ANNOTATION_ADDED (state, val) {
    state['annotationAdded'] = val
}
export function SET_SELECTED_ANNOTATION (state, val) {
    state['selectedAnnotation'] = val
}
export function CLEAR_SELECTED_ANNOTATION (state) {
    state['selectedAnnotation'] = {}
}
export function SET_ADDING_ANNOTATION (state, val) {
    state['addingAnnotation'] = val
}
export function SET_PANEL_CONTENT (state, val) {
    state['panelContent'] = val
}
export function SET_SELECTED_EXISTING_ANNOTATION (state, val) {
    state['selectedExistingAnnotation'] = val
}
export function SET_MENTION_USERS (state, val) {
    state['mentionUsers'] = val
}
export function SET_ACTIVE_COMPONENT (state, val) {
    state['activeComponent'] = val
}
export function SET_ANNOTATION_OBJECT (state, val) {
    state['annotationObject'] = val
}
export function SET_ANNOTATION_CONTENT (state, val) {
    state['annotationContent'] = val
}
export function SET_DRAWING_PIN (state, val) {
    state['drawingPin'] = val
}
export function SET_SELECTED_STAMP (state, val) {
    state['selectedStamp'] = val
}
export function SET_SELECT_STAMP_POPUP (state, val) {
    state['selectStampPopup'] = val
}
export function SET_MANUAL_ANNOTATION (state, val) {
    state['manualAnnotation'] = val
}
export function SET_SELECT_SHEET_POPUP (state, val) {
    state['selectSheetPopup'] = val
}
export function SET_SELECT_SHEET_POPUP_SKIP_DRAWING (state, val) {
    state['selectSheetPopupSkipDrawing'] = val
}
export function SET_LINKS_IMPORTED (state, val) {
    state['linksImported'] = val
}
