<template>
    <td class="io-cell-text-column">
        <div class="io-f-row">
            <span>{{ getHeader() }}</span>
            <span>{{ getSubHeader() }}</span>
        </div>
    </td>
</template>

<script lang="ts" src="./TextColumn.ts"></script>

<style lang="scss" src="./TextColumn.scss" scoped/>
