import { AxiosPromise } from 'axios'
import { ApiClientV3 as client } from '@/api/api'
import type { Category, BudgetCostCodesFilters } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'

export default {
    getBudgetCostCodes (
        budgetId: string,
        { siteId }: BudgetCostCodesFilters = {}
    ): AxiosPromise<{ data: Category[] }> {
        return client.get(
            `budgets/${ budgetId }/cost-codes`,
            { params: { site_id: siteId } },
        )
    },

    getProjectCostCodes (
        projectLocalId: string,
        { budgetType, siteId }: BudgetCostCodesFilters & Required<Pick<BudgetCostCodesFilters, 'budgetType'>>
    ): AxiosPromise<{ data: Category[] }> {
        return client.get(
            `project/${ projectLocalId }/cost-codes`,
            { params: { budget_type: budgetType, site_id: siteId } },
        )
    },
}
