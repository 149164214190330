import { defineStore } from 'pinia'
import ProjectFundingSummary from '../interfaces/ProjectFundingSummary'

export const projectFundingStore = defineStore('projectFunding', {
    state: ()=> ({
        needToUpdateData: false,
        projectFundingSummary: null as ProjectFundingSummary,
        isAllocationModalOpened: false
    }),
    actions: {
        setDataUpdation (value: boolean): void {
            this.needToUpdateData = value
        },
        setProjectFundingSummary (value: ProjectFundingSummary): void {
            this.projectFundingSummary = value
        },
        setShowAllocationModal (value: boolean): void {
            this.isAllocationModalOpened = value
        }
    }
})
