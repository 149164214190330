import { RelatedType } from '@/layouts/parts/menu-left/enums/RelatedType'
import { NotificationType } from '@/layouts/parts/menu-left/enums/NotificationType'
import { Notification } from '@/layouts/parts/menu-left/interfaces/MenuLeftInterface'

const RouteMapper = {
    [RelatedType.TODO]: {
        name: 'todo-view',
        params: { id: ':relatedId' }
    },
    [RelatedType.CONTRACT]: {
        name: 'contract-edit',
        params: { id: ':relatedId' }
    },
    [RelatedType.COST_LIABILITY]: {
        name: 'project-mychangeorder-edit',
        params: { id: ':relatedId' }
    },
    [RelatedType.RFP]: {
        additionalParams: {
            [NotificationType.RECEIVER_PROPOSAL]: {
                name: 'proposal-view-new',
                params: { id: ':id' }
            },
            [NotificationType.RECEIVER_RFP]: {
                name: 'rfp-view-new',
                params: { id: ':id' },
                query: { tab: 'discussion' }
            },
            'sender-project': {
                name: 'project-bid-requests-view-bid-leveling',
                params: {
                    pid: ':id',
                    package: ':bidRelatedId'
                },
                query: {
                    sender: ':senderId',
                    discussion: 1
                }
            },
            'sender-proposal': {
                name: 'project-bid-requests-view-bid-leveling',
                params: {
                    id: ':id',
                    package: ':bidRelatedId'
                },
                query: {
                    sender: ':senderId',
                    discussion: 1
                }
            }
        },
        defaultRfpRoute: {
            name: 'rfp-view-new',
            params: { id: ':relatedId' }
        }
    },
}

const getRawLocation = (notificationData: Notification): null | object => {
    let routerConfig = RouteMapper[RelatedType[notificationData.relatedType.toUpperCase()]]
    let rawLocation = null
    const relatedId = [NotificationType.RECEIVER_PROPOSAL, NotificationType.RECEIVER_RFP].includes(notificationData.additionalParams.to)
        ? notificationData.additionalParams.id
        : notificationData.relatedId

    if (routerConfig) {
        const additionalParams = routerConfig.additionalParams
        if (additionalParams) {
            routerConfig = routerConfig.additionalParams[notificationData.additionalParams.to]  || routerConfig.defaultRfpRoute
            rawLocation = {
                name: routerConfig.name,
                params: {
                    ...routerConfig.params,
                    id: relatedId,
                    pid: notificationData.additionalParams.id,
                    package: notificationData.additionalParams.bidRelatedId,
                },
                query: {
                    ...routerConfig.query,
                    sender: notificationData.additionalParams.senderId,
                },
            }

            return rawLocation
        }

        rawLocation = {
            name: routerConfig.name,
            params: {
                ...routerConfig.params,
                id: notificationData.relatedId
            }
        }
    }

    return rawLocation
}

export default getRawLocation


