<template>
    <div class="io-label-text">
        <IOLabel :text="label" :type="labelType"/>
        <p :class="`io-label-text__text io-label-text__text--${ textSize }`">
            {{ text }}
        </p>
    </div>
</template>

<script lang="ts" src="./LabelText.ts"></script>
<style lang="scss" src="./LabelText.scss" scoped></style>
