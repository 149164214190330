<template>
    <IOModal
        icon="icon-copy"
        width="500"
        :title="title"
    >
        <template #default>
            <div class="io-apply-to-other-sites__text">
                {{ $t('Are you sure you want to apply these line items to other sites?') }}
            </div>
            <div class="io-apply-to-other-sites__subtext">
                {{ 'This will override existing Line Items on the selected sites.' }}
            </div>

            <BudgetSiteSelect
                :project-id="projectId"
                :budget-type="budgetType"
                :preselected-site-ids="preselectedSiteIds"
                :provided-sites="preselectedSites"
                :label-text="sitesSelectorLabel"
                :multiple="applyToOtherSites"
                :can-uncheck-preselected-sites="false"
                v-model="selectedSites"
            />
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">
                {{ $t('Cancel') }}
            </IOButton>

            <IOButton :disabled="!selectedSites.length" @click="emitSelected">
                {{ buttonTitle }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./AffectOtherSites.ts"></script>
<style lang="scss" src="./AffectOtherSites.scss" scoped></style>
