<template>
    <div data-testid="uploading-list-item" class="io-uploading-list-item">
        <div class="io-uploading-list-item__checkbox-holder">
            <Checkbox
                :disabled="true"
                v-model="uploadingCheckbox"
            />
        </div>
        <div class="io-uploading-list-item__cell io-uploading-list-item__file-name">
            <div class="io-extension">{{ extension }}</div>
            <p v-tooltip.bottom="{ content: item.fileName, visible: item.fileName.length > 19 }">{{ textToShow }}</p>
        </div>
        <div class="io-uploading-list-item__cell io-uploading-list-item__progress">
            <ProgressBar
                variant="small"
                :items="progressItem"
                :showLegend="false"
            />
        </div>
        <div class="io-uploading-list-item__cell io-uploading-list-item__actions">
            <p
                v-if="showCancelButton"
                @click="removeFileFromQueue()"
            >
                Cancel
            </p>
        </div>
    </div>
</template>
<script lang="ts" src="./UploadingListItem.ts"></script>
<style lang="scss" src="./UploadingListItem.scss"></style>
