import { defineComponent } from 'vue'
import StatusDot from '@/components/atoms/status-dot/StatusDot.vue'
import IconColor from '@/interfaces/components/accordion/IconColor.ts'
import { RotateDirections } from '@/interfaces/components/accordion/RotateDirections.ts'
import type Button from '@/interfaces/components/accordion/Button.ts'
import type Status from '@/interfaces/components/accordion/Status.ts'
import type StatusDotInterface from '@/interfaces/components/card/StatusDot.ts'

export default defineComponent({
    name: 'Accordion',
    components : {
        StatusDot,
    },
    props: {
        id: { type: String, default: null, required: false },
        title: { type: String, required: true },
        open: { type: Boolean, default: true, required: false },
        status: { type: Object as () => Status, default: null, required: false },
        button: { type: Object as () => Button, default: null, required: false },
        showLine: { type: Boolean, default: true, required: false },
        icon: { type: String, default: 'icon-chevron-up' },
        rotateDegrees: { type: Number, default: 180 },
        rotateDirection: { type: String, default: () => RotateDirections.RIGHT },
        iconColor: { type: String, default: () => IconColor.DARK },
        statusDot: { type: Object as () => StatusDotInterface, default: null },
        tooltip: { type: String, default: null, required: false },
        tooltipLabel: { type: String, default: null, required: false },
        headerStyles: { type: Object, default: null, required: false }
    },
    emits: ['toggle', 'toggle-open', 'click'],
    data () {
        return {
            isOpen: true,
        }
    },
    computed: {
        directionSign (): string {
            return this.rotateDirection === RotateDirections.RIGHT ? '' : '-'
        },
        rotateChevronIcon (): string {
            return this.isOpen ? '' : `transform: rotate(${ this.directionSign }${ this.rotateDegrees }deg)`
        },
        iconColorClass (): string {
            return `io-icon-${ this.iconColor }`
        }
    },
    watch: {
        open (): void {
            this.isOpen = this.open
        },
        isOpen (): void {
            this.$emit('toggle', this.isOpen)
        },
    },
    created (): void {
        this.isOpen = this.open
    },
    methods: {
        enter (element: HTMLElement): void {
            element.style.width = getComputedStyle(element).width
            element.style.position = 'absolute'
            element.style.visibility = 'hidden'
            element.style.height = 'auto'

            const height = getComputedStyle(element).height

            element.style.width = null
            element.style.position = null
            element.style.visibility = null
            element.style.height = '0'
            getComputedStyle(element).height
            requestAnimationFrame(() => {
                element.style.height = height
            })
        },
        afterEnter (element: HTMLElement): void  {
            element.style.height = 'auto'
        },
        leave (element: HTMLElement): void  {
            element.style.height = getComputedStyle(element).height
            getComputedStyle(element).height
            requestAnimationFrame(() => {
              element.style.height = '0'
            })
        },
        toggleAccordion (): void {
            this.isOpen = !this.isOpen
            this.$emit('toggle-open', this.id)
        },
        collapseAccordion (): void {
            this.isOpen = false
        },
        expandAccordion (): void {
            this.isOpen = true
        },
        emitCallToAction (id: string = null): void {
            this.$emit('click', { id })
        },
    }
})
