import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
    name: 'ProjectBreadcrumbs',
    computed: {
        ...mapState('project', {
            projectObj: (state: any) => state.projectObj
        }),
        currentProjectName (): string {
            return this.projectObj.name
        }
    },
    methods: {
        onProjectRootClick (): void {
            this.$router.push({ name: 'project-list' })
        }
    }
})
