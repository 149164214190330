import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import Tooltip from '@/components/atoms/TooltipIcon/TooltipIcon.vue'

export default defineComponent({
    name: 'FormInputColumn',
    components: {
        Tooltip,
    },
    props: {
        type: { type: String, default: 'text' },
        className: { type: String, default: '' },
        label: { type: [String, Object as () => TranslateResult], required: true },
        required: { type: Boolean, default: false },
        tooltipEnable: { type: Boolean, default: false },
        tooltipText: { type: [String, Object as () => TranslateResult], default: '' },
        placeholder: { type: [String, Object as () => TranslateResult], default: '' },
        isValid: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        customContent: { type: Boolean, default: false },
        errorText: { type: [String, Object as () => TranslateResult], default: '' },
        content: { type: [String, Object as () => TranslateResult], default: '' },
        optional: { type: Boolean, default: false },
        staticText: { type: String, default: '' },
        maxLength: { type: Number, default: null },
    },
    emits: ['onChange', 'change', 'enter'],
    data () {
        return {
            value: '' as String | TranslateResult,
        }
    },
    watch: {
        'content' (): void {
            this.value = this.content
        },
    },
    mounted () {
        this.value = this.content
    },
    methods: {
        focus (): void {
            const input = this.$refs.input as HTMLInputElement
            if (input) {
                input.focus()
            }
        },
    },
})
