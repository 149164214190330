<template>
    <div data-testid="uploading-grid-item" class="io-uploading-grid-item" :class="{ 'io-is-inside-modal': showModalVersion }">
        <div v-if="item" class="io-uploading-grid-item__header">
            <p v-tooltip.bottom="{ content: item.fileName, visible: item.fileName.length > 19 }">{{ textToShow }}</p>
            <span
                v-if="showCancelButton"
                v-tooltip.bottom="$t('Cancel')"
                class="icon-cross"
                data-testid="uploading-button-cancel"
                @click="removeFileFromQueue()"
            ></span>
        </div>
        <div class="io-uploading-grid-item__thumbnail">
            <span class="icon-file">
                <span class="io-extension">{{ extension }}</span>
            </span>
        </div>
        <div class="io-uploading-grid-item__progress">
            <ProgressBar
                variant="small"
                :items="progressItem"
                :showLegend="false"
            />
        </div>
    </div>
</template>
<script lang="ts" src="./UploadingGridItem.ts"></script>
<style lang="scss" src="./UploadingGridItem.scss" scoped></style>
