import { sortBy, concat } from 'lodash'

export default {
    name: 'DocumentDetails',
    data () {
        return {
            documentObj: {},
            documentNewName: '',
            documentPreviewUrl: null, // API2 document preview URL - used to load document content
            documentPreviewMode: '', // default preview mode - possible values ['image', 'pdf']
            documentAnnotableTypes: ['image'], // annotable file types
            documentNamEditMode: false, // flag true/false => document name is in edit mode or not
            documentFileNewName: '', // temporary holds file name of document when in edit mode of document name
            documentFileExtension: '', // document file extension - keeps string value that are value after last dot of the file name
            showTopBarSpinner: false, // show top bar spinner when edited name of document is being saved or when document is being starred or unstarred
            documentState: '', // document preview is loading, loaded or not loaded
        }
    },
    computed: {
        documentId () {
            return this.$route.params.id
        }
    },
    methods: {
        resetDocumentData () {
            this.documentNamEditMode = false
        },
        // important on/off
        toggleDocumentImportance (event = null, source = '') {
            this.documentObj.flag = (Math.abs(this.documentObj.flag - 1))
            // show spinner
            this.showTopBarSpinner = true
            // save in DB
            this.$api.post('/document/' + this.documentId + '/file/flag/' + this.documentObj.flag).then((response) => {
                if (response.status === 200) {
                    // update file name in vuex store
                    this.$store.dispatch('documents/updateElementProperties', {
                        itemTypeName: 'file',
                        itemId: this.documentId,
                        properties: this.documentObj
                    })
                } else {
                    // something goes wrong
                }
            }).catch((error) => {
                console.error(error)
            // this acts as Promise "finally"
            }).then(() => {}, () => {}).then(() => {
                this.showTopBarSpinner = false
            })
        },
        setDocumentNewName (value) {
            this.documentNewName = value
        },
        // document name edit mode on
        documentNameEditOn () {
            // when dot is present
            if (this.documentObj.file_name.indexOf('.') !== -1) {
                this.documentNewName = this.documentObj.file_name.substr(0, this.documentObj.file_name.lastIndexOf('.'))
                this.documentFileExtension = this.documentObj.file_name.split('.').pop()
            // when dot is not present
            } else {
                this.documentNewName = this.documentObj.file_name
                this.documentFileExtension = ''
            }

            this.documentNamEditMode = true
            this.$nextTick(() => {
                document.getElementById('documentNameEdit').focus()
            })
        },
        // document edit mode off
        documentNameEditOff () {
            this.documentNamEditMode = false
        },
        // save new document name
        saveDocumentNewName () {
            // turn off edit mode of document name
            this.documentNameEditOff()

            // current & new file name to compare
            let currFileName = this.documentObj.file_name
            let newFileName = this.documentNewName + (this.documentFileExtension ? '.' + this.documentFileExtension : '')

            // checks
            if ((currFileName !== newFileName) && (this.documentNewName.trim() !== '')) {
                // set saving flag for spinner display
                this.showTopBarSpinner = true
                // assign new file name
                this.documentObj.file_name = newFileName
                // save new name of document
                this.$api.post('/document/' + this.documentId + '/rename', { file_name: this.documentObj.file_name }).then((response) => {
                    if (response.status === 200) {
                        // update file name in vuex store
                        this.$store.dispatch('documents/updateElementProperties', {
                            itemTypeName: 'file',
                            itemId: this.documentId,
                            properties: this.documentObj
                        })
                    }
                }).catch((error) => {
                    console.error(error)
                // this acts as Promise "finally"
                }).then(() => {}, () => {}).then(() => {
                    this.showTopBarSpinner = false
                })
            }
        },
        onUpdateAttachedEmployees (data) {
            if (data) {
                // update "attached_employees_ids" property
                this.documentObj.attached_employees_ids = data
                // update file name in vuex store
                this.$store.dispatch('documents/updateElementProperties', {
                    itemTypeName: 'file',
                    itemId: this.documentId,
                    properties: this.documentObj
                })
            }
        },
        downloadDocument (documentId = null) {
            const link = document.createElement('a')
            link.setAttribute('type', 'hidden')
            document.body.appendChild(link)

            let downloadLink = this.$api.getApi2RequestUrl('/documents/download')

            const files = null === documentId
              ? [this.documentId]
              : [documentId]

            const filesQuery = files.map((value, index) => {
                return 'files[' + index + ']=' + value
            }).join('&')

            downloadLink += ('?' + filesQuery)

            link.href = downloadLink
            link.click()
            document.body.removeChild(link)
        },
        // get document content
        async getDocumentPreview (initial = false) {
            // set initial document state to loading
            this.documentState = 'loading'

            // load image to img tag
            this.documentPreviewUrl = this.$api.getApi2RequestUrl('/document/' + this.documentId + '/preview')
            // add timestamp to force reload of image - cache fix
            this.documentPreviewUrl += '?timestamp=' + (new Date()).getTime()

            // load image preview
            if (this.documentPreviewMode === 'image') {
                let imageV = new Image()
                imageV.onload = () => {
                    // change document state to loaded
                    this.documentState = 'loaded'
                    // template rerender
                    this.$nextTick(() => {
                        let image = document.getElementById('documentFile')
                        // assign img src
                        image.src = imageV.src

                        // set image sizes - set canvas sizes same as IMG has
                        setTimeout(() => {
                            this.setImageSizes()

                            // draw all annotations on initial document load
                            if (initial) {
                                this.drawAllAnnotations()
                            }
                        }, 500)
                    })
                }

                // on load error
                imageV.onerror = () => {
                    this.documentState = 'unavailable'
                }

                imageV.src = this.documentPreviewUrl
            // load PDF preview (iframe)
            } else if (this.documentPreviewMode === 'pdf') {
                this.documentState = 'loaded'
            } else {
                this.documentState = 'unavailable'
            }
        },
        // get basic document info
        async getDocument (initial = true) {
            await this.$api.get('/document/' + this.documentId).then((response) => {
                if (response.status === 200) {
                    this.onAfterDocumentDataReceived(response.data.data, initial)
                }
            }).catch((error) => {
                console.error(error)
                // on load error navigate back to the list
                this.backFromDetails()
            })
        },
        onAfterDocumentDataReceived (documentObj, initial = true) {
            return new Promise((resolve, reject) => {
                // set initial flag value for document importance if missing
                documentObj.flag = documentObj.flag ? documentObj.flag : 0

                // convert annotations to array when comes down as an object
                if (typeof documentObj.annotations === 'object') {
                    documentObj.annotations = Object.values(documentObj.annotations)
                }

                // set document
                this.documentObj = documentObj

                const publicAnnotations = this.preparePublicAnnotations()

                // set annotations
                const annotations = this.documentObj.annotations ? this.documentObj.annotations.filter(item => item.is_public === false) : []
                this.annotationsSaved = sortBy(concat(annotations, publicAnnotations), item => parseInt(item.number))
                // execute on initial document load
                if (initial) {
                    this.$nextTick(() => {
                        // set different preview for ex. PDF file
                        this.documentAdjustPreviewMode()
                        // load document preview content
                        this.getDocumentPreview(true)
                    })
                }
                // resolve
                resolve(true)
            })
        },
        documentAdjustPreviewMode () {
            if (this.documentObj && this.documentObj.file_mime_type) {
                if (this.documentObj.file_mime_type === 'application/pdf' || this.documentObj.file_name.endsWith('.pdf')) {
                    this.documentPreviewMode = 'pdf'
                } else if (this.documentObj.file_mime_type.indexOf('image') !== -1) {
                    this.documentPreviewMode = 'image'
                }
            }
        }
    }
}
