<template>
    <label class="io-invitation-radio-row" :class="{ 'io-selected': isChecked }">
        <input
            type="radio"
            name="contact"
            class="io-invitation-radio-row__input"
            :checked="isChecked"
            :value="value"
            :class="`io-single-radio-row-${ idx }`"
            v-model="selected"
            @change="$emit('update:modelValue', $event.target.value)"
        />
        <SingleContact
            :showActions="false"
            :item="item"
        />
    </label>
</template>
<script lang="ts" src="./SingleRadioRow.ts"></script>
<style lang="scss" src="./SingleRadioRow.scss" scoped></style>
