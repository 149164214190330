export function getLoadingStatus (state) {
    return state.loading
}

export function getLoadingSpinnerStatus (state) {
    return state.loadingSpinner
}
export function getLoadingTableContentSpinnerStatus (state) {
    return state.loadingTableContentSpinner
}
