<template>
	<div class="combo-table-wrapper"> TESTAAA{{ slug }}END
		<div class="component-wrapper"
			 v-if="data.content.switch.enabled === true &&  data.content.switch.showLogic === true">
			<vue-form-generator :schema="schema" :model="$parent.model"></vue-form-generator>
		</div>
	</div>
</template>

<script>
    export default {
        mixins: [],
        components: {},
        props: {
            data: {
                type: Object
            },
            slug: {
                type: String
            }
        },
        watch: {},
        data () {
            return {
                sectionsTotal: '',
                isGeneral: false,
                packStructure: {},
                schema: {
                    separate: true,
                    fields: []
                },
                schemaListing: {
                    separate: true,
                    fields: []
                },
                content: {},
                structure: {}
            }
        },
        computed: {},
        mounted: function () {
            this.$emit('fieldElements', this)
            // optional toggler
            if (this.data.content.switch.enabled === true) {
                this.schema.fields.push(this.$parent.fields[this.data.content.switch.field])
            }
        }
    }
</script>

<style lang="scss" scoped>
	.combo-table-wrapper {
		h2 {
			margin-top: 0;
			padding-top: 0;
		}
		h3 {
			margin-top: 0;
			padding-top: 0;
		}
		table.combo-component-table {
			width: 100%;
			margin: 0;
			margin-bottom: 20px;
		}
	}
</style>
