export function getLeftMenuStatus (state) {
    return state.openLeftMenu
}

export function getTmp (state) {
    return state.tmp
}

export function getRouterHistory (state) {
    return state.routerHistory
}

export function getUsersOnline (state) {
    return state.usersOnline
}
