<template>
    <span
        class="io-design-system io-status-pill-component"
        :class="pillClasses"
        v-html="formattedText"
    >
    </span>
</template>
<script lang="ts" src="./StatusPill.ts"></script>
<style lang="scss" src="./StatusPill.scss" scoped></style>
