import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SingleWorkspace',
    props: {
        item: { type: Object, required: true },
        componentKey: { type: String, required: true },
        value: { type: String, default: undefined },
        isDisabled: { type: Boolean, default: false },
        modelValue: { default: '' }
    },
    computed: {
        isChecked () {
            return this.modelValue == this.value
        },
        currentValue: {
            get () {
                return this.modelValue
            },
            set (value) {
                this.$emit('update:modelValue', value)
            }
        },
        workspaceTypeSelected () {
            return this.$store.getters['inviteModal/getSelectedWorkspaceType'](this.componentKey)
        }
    },
    mounted () {
        // @ts-ignore
        this.preselectWorkspaceType()
    },
    methods: {
        preselectWorkspaceType () {
            if (!!this.workspaceTypeSelected && this.value === this.workspaceTypeSelected) {
                this.$emit('update:modelValue', this.value)

                const input = document.querySelector(`.io-workspace-type-${this.item.id}`) as HTMLElement

                if (input) {
                    input.click()
                }
            }
        }
    }
})
