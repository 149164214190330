import dayjs from 'dayjs'
import cashFlowClient from '@/api/project/cashFlowClient'
import CostCodeItemConverted from '../../../interfaces/modules/projects/modules/common/budget/CostCodeItemConverted'
import CostCodeCategoryConverted from '@/interfaces/modules/projects/modules/common/budget/CostCodeCategoryConverted'
import CashFlow from '@/interfaces/modules/projects/modules/forecasting/CashFlow'
import CashFlowCostCode from '@/interfaces/modules/projects/modules/forecasting/CashFlowCostCode'
import { AxiosResponse } from 'axios'
import CashFlowMonth from '@/interfaces/modules/projects/modules/forecasting/CashFlowMonth'
import CurveType from '@/interfaces/modules/projects/modules/forecasting/CurveType'
import CashFlowCategory from '@/interfaces/modules/projects/modules/forecasting/CashFlowCategory.ts'
import CashFlowResponse from '@/interfaces/modules/projects/modules/forecasting/request/CashFlowResponse.ts'
import CashFlowTask from '@/interfaces/modules/projects/modules/forecasting/CashFlowTask.ts'
import CashFlowMonthResponse from '@/interfaces/modules/projects/modules/forecasting/request/CashFlowMonthResponse.ts'
import BudgetClient from '@/io-modules/budget/api-clients/BudgetClient.ts'
import CashFlowOption from '@/interfaces/modules/projects/modules/forecasting/CashFlowOption.ts'
import CashFlowType from '@/interfaces/modules/projects/modules/forecasting/CashFlowType.ts'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'
import minMax from 'dayjs/plugin/minMax'
import CashFlowCategoryResponse
    from '@/interfaces/modules/projects/modules/forecasting/request/CashFlowCategoryResponse.ts'
import CashFlowCostCodeResponse
    from '@/interfaces/modules/projects/modules/forecasting/request/CashFlowCostCodeResponse.ts'
import CashFlowTaskResponse from '@/interfaces/modules/projects/modules/forecasting/request/CashFlowTaskResponse.ts'
import CashFlowPhaseResponse from '@/interfaces/modules/projects/modules/forecasting/request/CashFlowPhaseResponse.ts'
import CashFlowPhase from '@/interfaces/modules/projects/modules/forecasting/CashFlowPhase.ts'

dayjs.extend(minMax)

export const loadCashFlows = async function ({ commit, state }, { projectId }: { projectId: string }): Promise<void> {
    const { data } = await cashFlowClient.getCashFlowsByProjectId(projectId)

    commit('SET_CASH_FLOWS_OPTIONS', data)
}

export const loadCashFlow = function ({ commit, state }, cashFlowId: string): Promise<void> {
    return new Promise(resolve => {
        const periodResponseToCashFlowMonth = (period: CashFlowMonthResponse): CashFlowMonth => {
            return {
                id: period.id,
                actualValue: Number(period.actual_value),
                value: Number(period.value), // forecasted
                revisedAfterPeriodPassed: period.revised_after_period_passed,
                startDate: dayjs(period.start_date, 'YYYY-MM'),
                startDateFormatted: period.start_date,
            }
        }

        const mapTask = (task: CashFlowTaskResponse, costCode: CashFlowCostCodeResponse): CashFlowTask => {
            return {
                id: task.id,
                name: task.name,
                code: task.code,
                value: Number(task.value),
                forecastedAmount: Number(task.forecasted_amount),
                startDate: dayjs(task.start_date),
                endDate: dayjs(task.end_date),
                curveType: task.curve_type,
                isNew: task.is_new,
                commited: task.commited,
                parentId: costCode.id,
                periods: task.periods.map(periodResponseToCashFlowMonth),
            }
        }

        const mapCostCode = (costCode: CashFlowCostCodeResponse, category: CashFlowCategoryResponse): CashFlowCostCode => {
            const minDate = dayjs.min(costCode.tasks.map((task): dayjs.Dayjs => dayjs(task.start_date)))
            const maxDate = dayjs.max(costCode.tasks.map((task): dayjs.Dayjs => dayjs(task.end_date)))

            return {
                id: costCode.id,
                name: costCode.name,
                budgetCostCodeId: costCode.budget_cost_code_id,
                code: costCode.code,
                startDate: minDate,
                endDate: maxDate,
                curveType: costCode.tasks.length === 1 ? costCode.tasks[0] : CurveType.CUSTOM,
                isNew: costCode.is_new,
                parentId: category.id,
                tasks: costCode.tasks.map(task => mapTask(task, costCode)),
            }
        }

        const mapCategory = (category: CashFlowCategoryResponse): CashFlowCategory => {
            return {
                id: category.id,
                name: category.name,
                code: category.code,
                costCodes: category.cost_codes.map(costCode => mapCostCode(costCode, category)),
                containsForeignCostCodes: category.contains_foreign_cost_codes,
            }
        }

        const mapPhase = (phase: CashFlowPhaseResponse): CashFlowPhase => {
            return {
                id: phase.id,
                name: phase.name,
                code: phase.code,
                has_new: phase.has_new,
                project_site_id: phase.project_site_id,
                categories: phase.categories.map(mapCategory),
            }
        }

        cashFlowClient.getById(cashFlowId).then((response: AxiosResponse<CashFlowResponse>) => {
            commit('SET_CASH_FLOW', {
                id: response.data.id,
                projectId: response.data.project_id,
                status: response.data.status,
                type: response.data.type,
                startDate: dayjs(response.data.start_date, 'YYYY-MM'),
                endDate: dayjs(response.data.end_date, 'YYYY-MM'),
                readyToShare: response.data.ready_to_share,
                includeApprovedInvoices: response.data.include_approved_invoices,
                isShared: response.data.is_shared,
                phases: response.data.phases.map(mapPhase),
            })

            if (response.data.sites.length) {
                commit('SET_SITES', response.data.sites)
                commit('SET_ACTIVE_SITE_ID', response.data.sites[0].project_site_id)
            }
        }).finally(() => {
            commit('SET_LOADING_CASH_FLOW', false)
            resolve()
        })
    })
}

export const createCashFlow = async function ({ commit, dispatch, state, actions }, projectId: string): Promise<void> {
    commit('SET_EDITING_CASH_FLOW')

    const { data } = await cashFlowClient.createCashFlow({
        type: state.currentCashFlowOption.type || state.currentCashFlowOption.value,
        projectId,
    })

    commit('UPDATE_CURRENT_CASH_FLOW_OPTION_ID', data)
}

export const deleteCashFlow = async function ({ commit, state }, cashFlowId: string): Promise<void> {
    await cashFlowClient.delete(cashFlowId).then(() => {
        commit('DELETE_CASH_FLOW')

        const options = []

        state.cashFlowOptions.forEach((option: CashFlowOption) => {
            if (option.id === cashFlowId) {
                option.id = null
            }

            options.push(option)
        })

        commit('SET_CASH_FLOWS_OPTIONS', options)
    })
}

const idsByCategory = function (cashFlow: CashFlow, category: CostCodeCategoryConverted): string[] {
    return category.costCodes.map((element: CostCodeItemConverted): string[] => {
        let targetCostCode: CashFlowCostCode

        cashFlow.categories.forEach((category: CashFlowCategory) => category.costCodes.forEach((costCode: CashFlowCostCode) => {
            if (element.id === costCode.id) {
                targetCostCode = costCode
            }
        }))

        const childCostCodes = targetCostCode.tasks

        return 0 === childCostCodes.length ? [ targetCostCode.id ] : childCostCodes.map((task: CashFlowTask): string => task.id)
    }).reduce((carry: string[], ids: string[]): string[] => [ ...carry, ...ids ])
}

export const selectCategory = function ({ commit, state }, category: CostCodeCategoryConverted): void {
    const itemIds = idsByCategory(state.cashFlow, category)

    const itemsCheckboxes = [
        ...state.itemsCheckboxes.filter((element: string): boolean => !itemIds.includes(element)), ...itemIds
    ]
    const categoryCheckboxes = [...state.categoryCheckboxes, category.id]

    commit('SET_ITEMS_CHECKBOXES', itemsCheckboxes)
    commit('SET_CATEGORY_CHECKBOXES', categoryCheckboxes)
}

export const unselectCategory = function ({ commit, state }, category: CostCodeCategoryConverted): void {
    const itemIds = idsByCategory(state.cashFlow, category)
    const itemsCheckboxes = state.itemsCheckboxes.filter((id: string): boolean => !itemIds.includes(id))

    const categoryCheckboxes = state.categoryCheckboxes.filter((element: string): boolean => element !== category.id)

    commit('SET_ITEMS_CHECKBOXES', itemsCheckboxes)
    commit('SET_CATEGORY_CHECKBOXES', categoryCheckboxes)
}

export const toggleCollapsed = function ({ commit, state }): void {
    commit('TOGGLE_COLLAPSED')
    localStorage.setItem('cashFlowTableCollapse', state.collapsed ? 'true' : 'false')
}

export const setCashFlowShared = function ({ commit, state }): void {
    commit('SET_CASH_FLOW_SHARED')
}

export const generateCurveData = function ({ commit, state }, { categoryId, costCodeId, task, curveType, totalAnticipatedValue }: { categoryId: string, costCodeId: string, task: CashFlowCostCode, curveType: CurveType, totalAnticipatedValue: string }): Promise<void> {
    return cashFlowClient.generateCurveData(
        state.cashFlow.projectId,
        state.cashFlow.id,
        task,
        curveType,
        totalAnticipatedValue,
    ).then((response) => {
        commit('SET_TASK_PERIODS', {
            categoryId,
            costCodeId,
            taskId: task.id,
            periods: response.data.map((costCodePeriod: CashFlowMonthResponse): CashFlowMonth => {
                const existingPeriod = task.periods.find((period: CashFlowMonth): boolean => period.startDateFormatted === costCodePeriod.start_date)

                if (existingPeriod) {
                    return {
                        ...existingPeriod,
                        value: Number(costCodePeriod.value),
                    }
                }

                return {
                    ...costCodePeriod,
                    startDate: dayjs(costCodePeriod.start_date),
                    value: Number(costCodePeriod.value),
                }
            })
        })
    })
}
