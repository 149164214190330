<template>
    <div class="io-design-system io-multi-switch">
        <div
            v-for="option in options"
            :key="option.key"
            :class="`io-multi-switch__option ${isOptionSelected(option) ? activeClass : ''}`"
            @click="selectOption(option)"
        >
            <i v-if="option.icon" :class="iconClass(option)"></i> {{ option.label }}
        </div>
    </div>
</template>

<script lang="ts" src="./MultiSwitch.ts"/>
<style lang="scss" src="./MultiSwitch.scss" scoped/>
