<template>
    <div class="io-menu-left__container">
        <div
            class="io-menu-left"
            :class="{ 'io-menu-left_opened': isShowProfileMenuVisible,
                      'io-menu-left_wider': isProjectNavigationActive,
                      'io-menu-left_wider_training': isProjectNavigationActive && isTrainingEnv }"
        >
            <div
                class="io-menu-left__top"
                @click="closeNotificationCenter"
            >
                <div class="io-menu-left__app-logo-container">
                    <div class="io-logo">
                        <router-link
                            class="io-logo__link"
                            :to="{ name: 'dashboard' }"
                        >
                            <img
                                class="io-logo__icon"
                                alt=""
                                :src="currentAppType.logo"
                            />
                            <span class="io-logo__text">
                                {{ currentAppType.text }}
                            </span>
                        </router-link>
                    </div>

                    <MenuLock
                        :isProjectNavigationActive="isProjectNavigationActive"
                        @setShowProfileMenuVisible="onSetShowProfileMenuVisible"
                    />
                </div>

                <template v-if="isProjectNavigationActive">
                    <EnvBanner v-if="isTrainingEnv"/>

                    <ProjectBreadcrumbs />

                    <ProjectSearch @setProjectSearchValue="onProjectSearchValueSet"/>
                </template>


                <div
                    ref="listContainerNode"
                    class="io-menu-left__list-container"
                    @scroll="onMenuListContainerScroll"
                >

                    <template v-if="isProjectNavigationActive">
                        <ProjectNavigation :projectSearchValue="projectSearchValue" />
                    </template>
                    <template v-else>
                        <ul class="io-menu-left__list">
                            <template v-for="(item, index) in menuItems" :key="index">
                                <li
                                    class="io-menu-left__list-item"
                                    :class="{ 'io-menu-left__list-item_arrowed': item.subitems?.length,
                                              'io-menu-left__list-item_opened': item.isOpened,
                                              'io-menu-left__list-item_active': isItemActive(item.subitems, index)
                                    }"
                                >
                                    <template v-if="item.subitems?.length">
                                        <ItemLink
                                            :isRouterLink="false"
                                            :isItemActive="isItemActive(item.subitems, index)"
                                            :itemIndex="index"
                                            :item="item"
                                            @setMenuItemOpened="onSetMenuItemOpened"
                                        />

                                        <ul class="io-menu-left__list-submenu">
                                            <template v-for="(subItem, index) in item.subitems" :key="`subItem_${ index }`">
                                                <li
                                                    ref="subItemNode"
                                                    class="io-menu-left__list-submenu-item"
                                                >
                                                    <ItemLink
                                                        :isRouterLink="true"
                                                        :isSubItem="true"
                                                        :subItem="subItem"
                                                        :isSubItemActive="isSubItemActive(subItem.url)"
                                                    />
                                                </li>
                                            </template>
                                        </ul>
                                    </template>

                                    <template v-else>
                                        <ItemLink
                                            :isRouterLink="true"
                                            :item="item"
                                            :isItemActive="isItemActive(item.subitems, index)"
                                            @setMenuItemOpened="onAllSubmenusClose"
                                        />
                                    </template>

                                </li>
                            </template>
                        </ul>
                    </template>
                </div>
            </div>
            <div
                class="io-menu-left__middle"
                @click="closeNotificationCenter">

                <div
                    class="io-menu-left__middle-shadow"
                    :class="{ 'io-menu-left__middle-shadow_visible': isMenuMiddleShadowVisible }"
                ></div>

            </div>
            <div class="io-menu-left__bottom">

                <div class="io-menu-left__bottom-container">
                    <span
                        v-if="isProjectNavigationActive"
                        class="io-menu-left__list-link"
                        @click.stop="onExitProjectClick"
                    >
                        <i class="io-menu-left__list-item-icon io-menu-left__list-item-icon_highlighted icon-arrow-left-rec-2"></i>
                        <span class="io-menu-left__list-item-text io-menu-left__list-item-text_highlighted">
                            {{ $t('Exit Project') }}
                        </span>
                    </span>

                    <span
                        v-else
                        class="io-menu-left__list-link"
                        @click.stop="openTaskModal()"
                    >
                        <i class="io-menu-left__list-item-icon io-menu-left__list-item-icon_highlighted icon-plus-rec-2"></i>
                        <span class="io-menu-left__list-item-text io-menu-left__list-item-text_highlighted">
                            {{ $t('Add New Task') }}
                        </span>
                    </span>

                    <span
                        v-for="(item, index) in menuItemsBottom"
                        :key="`bottom-item-${ index }`"
                        class="io-menu-left__list-link"
                        :class="{ 'io-menu-left__list-link_active': isBottomItemActive(item.title) }"
                        @click="handleBottomItemClick(item.title)"
                    >
                        <i
                            class="io-menu-left__list-item-icon io-menu-left__list-item-icon"
                            :class="{ [item.icon]: !isBottomItemActive(item.title),
                                      [item.iconActive]: isBottomItemActive(item.title) }"
                        ></i>
                        <span class="io-menu-left__list-item-text io-menu-left__list-item-text">
                            {{ $t(item.title) }}
                        </span>
                        <span
                            v-if="item.counter"
                            class="io-menu-left__list-link_notification"
                        >
                            {{ item.counter }}
                        </span>
                    </span>

                    <Profile @clicked="closeNotificationCenter" />
                </div>
            </div>
        </div>

        <transition name="notification-slide">
            <NotificationsCenter
                v-if="isNotificationsCenterOpened"
                ref="notificationsCenterNode"
                :isOpened="isNotificationsCenterOpened"
                :unreadNotifications="notificationsCounter"
                @toggleNotificationsCenterOpened="toggleNotificationsCenterOpened"
            />
        </transition>

        <transition name="menu-left-mask">
            <div
                v-if="isNotificationsCenterOpened"
                class="io-menu-left__mask"
                @click="toggleNotificationsCenterOpened"
            ></div>
        </transition>
    </div>
</template>

<script src="./MenuLeft.ts"></script>
<style lang="scss" src="./MenuLeft.scss" scoped></style>
