import ContentTwoSidesTemplate from '@/components/content-two-sides-template/ContentTwoSidesTemplate.vue'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import dateTimeHelper from '@/helpers/dateTime'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { mapGetters as mapGettersVuex, mapState as mapStateVuex } from 'vuex'
import invoiceCreationClient from '@/io-modules/invoices/api-clients/invoiceCreationClient'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import DropdownButton from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import SimpleOption from '@/io-modules/invoices/interfaces/SimpleOption'
import Radio from '@/components/atoms/Radio/Radio.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType'
import PaidByCompany from '@/io-modules/invoices/interfaces/PaidByCompany'
import { NonContractedInvoiceDetails } from '@/io-modules/invoices/api-clients/invoiceViewClient.ts'
import { InvoiceSubType } from '@/io-modules/invoices/enums/InvoiceSubType'
import InvoiceType from '@/io-modules/invoices/enums/InvoiceType.ts'
import invoiceEditingClient from '@/io-modules/invoices/api-clients/invoiceEditingClient.ts'
import IOTextarea from '@/components/atoms/IOTextarea/IOTextarea.vue'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV.ts'
import IODropdown from '@/components/atoms/io-dropdown/IODropdown.vue'

export default defineComponent({
    name: 'EditNoNContractedInvoiceDetails',
    components: {
        IODropdown,
        ContentTwoSidesTemplate,
        FieldDateTime,
        LoadingScreen,
        DropdownButton,
        Radio,
        StatusPill,
        IOTextarea,
    },
    data () {
        return {
            datepickerOptions: {
                'only-date': true,
                noClearButton: true,
                format: 'YYYY-MM-DD',
                formatted: dateTimeHelper.getDateFormat(),
            },
            loading: false,
            vendors: [] as SimpleOption[],
            vendorsPerPage: 10,
            categories: [],
            selectedVendor: null,
            searchVendor: '',
            selectedCategory: null,
            selectedCompany: '' as BudgetType,
            PillVariant,
            multiselectSettings: {
                'is-type-and-add-enabled': true,
                label: 'name',
                'track-by': 'name',
                taggable: true
            }
        }
    },
    computed: {
        ...mapState(invoiceViewStore, {
            invoice: state => state.invoice as NonContractedInvoiceDetails,
            editing: state => state.editing,
            creating: state => state.creating,
            dataLoading: state => state.dataLoading,
            uniqueNumberCounter: state => state.uniqueNumberCounter,
            uniqueNumberMaxCharacters: state => state.uniqueNumberMaxCharacters,
            uniqueNumberIsValid: state => state.uniqueNumberIsValid,
        }),

        ...mapState(invoiceSoVStore, ['nonContractedLineItems']),

        ...mapStateVuex('project', ['projectObj']),

        ...mapStateVuex('appStore', ['authData']),

        ...mapGettersVuex('appStore', ['isOwnerRep']),

        filteredVendors (): SimpleOption[] {
            const vendors = this.searchVendor ? this.vendors.filter(item => item.name.toLowerCase().includes(this.searchVendor.toLowerCase())) : this.vendors

            return this.vendorsPerPage < vendors.length ? vendors.slice(0, this.vendorsPerPage) : vendors
        },

        invoicePaidByCompanies (): PaidByCompany[] {
            return [
                {
                    mongo_id: this.projectObj._res_mongo_id,
                    name: this.projectObj._res_name,
                    type: this.$tc('Owner'),
                    value: BudgetType.OWNER_BUDGET
                },
                {
                    mongo_id: this.authData.company_mongo_id,
                    name: this.authData.u_company_name,
                    type: this.$tc('Rep'),
                    value: BudgetType.MY_BUDGET
                }
            ]
        }
    },
    watch: {
        selectedCompany (value: BudgetType): void {
            this.invoice.paid_by = value
        },

        searchVendor (value: string): void {
            if (value) {
                this.vendorsPerPage = 10
            }
        },

        'invoice.invoice_unique_number' (): void {
            this.setUniqueNumberValid(true)
        },

        'invoice.paid_by' (): void {
            this.nonContractedLineItems.forEach(item => {
                item.cost_code_id = null
                item.cost_code = null
            })
        }
    },
    async created (): Promise<void> {
        if (!this.invoice.invoice_date) {
            this.setInvoiceDateToday()
        }
        if (this.creating) { //When creating a new invoice
            await this.onNewNonContractedInvoiceCreating()
        } else if (this.editing) {
            await this.onNonContractedInvoiceEditing()
        }

        this.selectedVendor = this.invoice.vendor || null
        this.selectedCompany = this.invoice.paid_by ? this.invoice.paid_by : this.isOwnerRep ? BudgetType.OWNER_BUDGET : BudgetType.MY_BUDGET
        this.selectedCategory = { name: this.invoice.category_name } || null
    },
    methods: {
        ...mapActions(invoiceViewStore, [
            'setInvoiceDateToday',
            'setInvoiceContractId',
            'setDataLoadingValue',
            'setUniqueNumberValid',
            'setAppPackages'
        ]),

        async onNonContractedInvoiceEditing (): Promise<void> {
            this.setDataLoadingValue(true)
            const { data } = await invoiceEditingClient.getNonContractedInvoiceEditingData(this.projectObj.project_local_id, this.invoice.id)
            if (data) {
                this.vendors = []
                data.vendors.forEach((vendorGroup) => {
                    this.vendors.push(
                        ...vendorGroup.vendors,
                    )
                })

                this.categories = data.invoice_category_names && Object.keys(data.invoice_category_names).length > 0
                    ? Object.values(data.invoice_category_names).map(name => ({ name }))
                    : []

                this.setAppPackages(data.application_packages)
            }
            this.setDataLoadingValue(false)
        },

        async onNewNonContractedInvoiceCreating (): Promise<void> {
            this.setDataLoadingValue(true)
            const { data } = await invoiceCreationClient.getNonContractedInvoiceCreationData(this.projectObj.project_local_id)
            if (data) {
                this.vendors = []
                data.vendors.forEach((vendorGroup) => {
                    this.vendors.push(
                        ...vendorGroup.vendors,
                    )
                })

                this.categories = data.invoice_category_names && Object.keys(data.invoice_category_names).length > 0
                    ? Object.values(data.invoice_category_names).map(name => ({ name }))
                    : []

                this.setAppPackages(data.application_packages)
            }
            this.setDataLoadingValue(false)
            this.invoice.type = InvoiceType.NonContracted
            this.invoice.sub_type = InvoiceSubType.NonContractedInvoice
        },

        showMoreVendors (): void {
            if (this.vendorsPerPage < this.vendors.length) {
                this.vendorsPerPage += 10
            }
        },

        selectVendor (item: SimpleOption): void {
            this.selectedVendor = item
            this.invoice.vendor = item
            this.invoice.vendor_id = item.id
        },

        selectCompany (item: PaidByCompany): void {
            this.selectedCompany = item.value
        },

        onCategorySelect (category: SimpleOption): void {
            this.invoice.category_name = category.name
        }
    },
})
