import store from '@/store/index'
import apiClient from '@/api/base/baseClient'

const getProjectAccess = async (to, from, next) => {
    await apiClient
        .get('/project/access/' + to.params.pid)
        .then((response) => {
            return next()
        })
        .catch((error) => {
            // if project status is external pending then redirect to project wizard 
            if (
                302 === error?.response?.status && 
                true === error?.response?.data?.project_external_pending
            ) {
                return next({ name: 'project-add', query: { id: to.params.pid } })
            } 

            // top bar message
            store.dispatch('notification/setShow', {
                type: 'error',
                text: 'Access Denied'
            })

            // redirect to dashboard
            return next({ name: 'dashboard' })
        })
}
export { getProjectAccess }
