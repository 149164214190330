<template>
    <filterSection :title="title">
        <template #default>
            <Checkbox
                v-if="!hideSelectAllStatus"
                key='all'
                labelText="All"
                v-model="allSelected"
                :checkboxValue="true"
                inputName="all"
                @click="handleSelectAll"
            />
            <template v-for="(item, key) in preparedOptions" :key="key">
                <Checkbox
                    :labelText="prepareName(item)"
                    v-model="checkboxesValue"
                    :checkboxValue="key"
                    :inputName="key"
                />
            </template>
        </template>
    </filterSection>
</template>

<script>
    import filterSection from './filterSection.vue'
    import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue'
    export default {
        name: 'FilterCheckboxesAll',
        emit: [ 'update:modelValue' ],
        components: {
            filterSection,
            Checkbox
        },
        data () {
            return {}
        },
        props: {
            title: {
                type: String,
                required: true
            },
            modelValue: {
                required: true,
                type: Array
            },
            options: {
                required: true,
                type: Object
            },
            translate: {
                required: false,
                default: false
            },
            pillsColorFunction: {
                required: true,
                type: Function
            },
            hideSelectAllStatus: {
                required: false,
                type: Boolean,
                default: false
            },
            returnNumber: {
                required: false,
                default: false
            }
        },
        computed: {
            checkboxesValue: {
                get () {
                    return this.modelValue
                },
                set (val) {
                    this.$emit('update:modelValue', this.returnNumber ? val.map((v) => parseInt(v)) : val)
                }
            },
            preparedOptions () {
                if (Array.isArray(this.options)) {
                    return this.options.reduce((acc, { id, name }) => ({...acc, [id]: name}), {})
                }
                return this.options
            },
            allCheckboxesValues () {
                if (Array.isArray(this.options)) {
                    return this.options.reduce((acc, { id }) => [...acc, id], [])
                }
                return this.options
            },
            allSelected () {
                return [this.modelValue?.length === this.options.length]
            }
        },
        methods: {
            prepareName (name) {
                if (this.translate) {
                    return this.$t(name)
                }
                return name
            },
            handleSelectAll () {
                if (this.allSelected.includes(true)) {
                    this.checkboxesValue = []
                } else {
                    this.checkboxesValue = this.allCheckboxesValues
                }
            }
        }
    }
</script>

<style lang="scss">
</style>
