const ProjectProvider = () => import(/* webpackChunkName: "project-provider" */ '@/layouts/project-provider/ProjectProvider.vue')
const PunchListsIndex = () => import(/* webpackChunkName: "punch-lists-index" */ '@/io-modules/punch-lists/views/punch-lists-index/PunchListsIndex.vue')
const PunchItemsIndex = () => import(/* webpackChunkName: "punch-lists-index" */ '@/io-modules/punch-lists/views/punch-items-index/PunchItemsIndex.vue')
const PunchStampsTemplatesIndex = () => import(/* webpackChunkName: "punch-stamps-templates-index" */ '@/io-modules/punch-lists/views/punch-stamps-templates-index/PunchStampsTemplatesIndex.vue')
const PunchStampsTemplatesShow = () => import(/* webpackChunkName: "punch-stamps-templates-index" */ '@/io-modules/punch-lists/views/punch-stamps-templates-show/PunchStampsTemplatesShow.vue')
const PunchStampsTemplatesCreate = () => import(/* webpackChunkName: "punch-stamps-templates-index" */ '@/io-modules/punch-lists/views/punch-stamps-templates-create/PunchStampsTemplatesCreate.vue')

export default [
    {
        path: '/project/:pid/punchlist',
        component: ProjectProvider,
        redirect: { name: 'punch-items-index' },
        children: [
            {
                path: 'lists/:punchListId?',
                props: true,
                meta: {
                    title: 'Punch Lists',
                },
                component: PunchListsIndex,
                children: [
                    {
                        path: 'items/:punchItemId?/:edit?',
                        name: 'punch-items-index',
                        props: true,
                        meta: {
                            name: 'Punch Items',
                            navigationKey: 'punchlist'
                        },
                        component: PunchItemsIndex,
                    },
                ],
            },
        ],
    },
    {
        path: '/lists-templates/punch-stamps',
        name: 'punch-stamps-templates-index',
        component: PunchStampsTemplatesIndex,
    },
    {
        path: '/lists-templates/punch-stamps/create',
        name: 'punch-stamps-templates-create',
        component: PunchStampsTemplatesCreate,
        meta: {
            showNotificationsForRoutes: ['punch-stamps-templates-index'],
        }
    },
    {
        path: '/lists-templates/punch-stamps/:templateId',
        name: 'punch-stamps-templates-show',
        component: PunchStampsTemplatesShow,
        props: true,
    },
    {
        path: '/lists-templates/punch-stamps/:templateId/edit',
        name: 'punch-stamps-templates-edit',
        component: PunchStampsTemplatesCreate,
        props: true,
        meta: {
            showNotificationsForRoutes: ['punch-stamps-templates-index'],
        }
    }
]
