import { ApiClient } from '@/api/api'
let client = ApiClient

export default {
    getIntercomCompanyData () {
        return client.get(`/intercom/company-data`)
    },

    getIntercomUserData () {
        return client.get(`intercom/user-data`)
    }
}
