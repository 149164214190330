import { defineComponent } from 'vue'
import Table from '@/components/table/Table.vue'
import fundingClient from '../../api-clients/fundingClient'
import FundingSourcesProjectRow from '../funding-sources-project-row/FundingSourcesProjectRow.vue'
import Accordion from '@/components/accordion/Accordion.vue'
import { mapActions, mapState } from 'pinia'
import { globalFundingStore } from '../../stores/globalFundingStore'
import { FundingSourcesFunds } from '../../interfaces/GlobalFundingInterfaces.ts'
import CapitalFundsAccordion from '../capital-funds-accordion/CapitalFundsAccordion.vue'
import Placeholder from '@/components/placeholder/Placeholder.vue'

export default defineComponent({
    name: 'CapitalFunds',
    components: { Placeholder, CapitalFundsAccordion, FundingSourcesProjectRow, Table, Accordion },
    props: {
        providerId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            capitalFunds: [] as FundingSourcesFunds[],
        }
    },
    computed: {
        ...mapState(globalFundingStore, {
            showAddNewCapitalFundModal: state => state.addNewCapitalFundModal.show
        }),
        createNewCapitalFundCta (): Array<{ id: string, name: string, customAction: string }> {
            return [
                {
                    id: 'button-new-capital-fund',
                    name: this.$tc('Create New Program Fund'),
                    customAction: 'createNewCapitalFund',
                },
            ]
        },

    },
    async beforeMount () {
        try {
            const response = await fundingClient.getProviderCapitalFunds(this.providerId)

            if (response && response.data.items.length) {
                this.capitalFunds = response.data.items
            } else {
                this.capitalFunds = null
            }
        } catch (e) {
            this.errorHandleNoRedirect(e)
        }
    },
    methods: {
        ...mapActions(globalFundingStore, [
                'setAddNewCapitalFundModal'
        ]),
        toggleShowNewFundModal (): void {
            this.setAddNewCapitalFundModal({
                show: !this.showAddNewCapitalFundModal,
                editMode: false
            })
        },
    },
})
