import { defineComponent } from 'vue'
import WebViewer, { Core, type WebViewerInstance, type WebViewerOptions } from '@pdftron/webviewer'
import { webCredentialStore } from '@/io-modules/web-credential/store/webCredentialStore.ts'
import { WebCredentialEnum } from '@/io-modules/web-credential/enums/WebCredentialEnum.ts'
import type { Stamp, BaseStamp } from '@/io-modules/punch-lists/interfaces/Stamp.ts'

export default defineComponent({
    name: 'SheetWebViewer',
    props: {
        url: {
            type: String,
            required: true,
        },
        stamp: {
            type: Object as () => Stamp | BaseStamp,
            default: null,
        },
        pinColor: String,
        cursorClass: String,
    },
    emits: ['referenceSelected'],
    computed: {
        options (): WebViewerOptions {
            return {
                path: '/webviewer',
                css: process.env.CDN_URL + 'assets/css/pdftron.css',
                licenseKey: webCredentialStore.findWebCredential(WebCredentialEnum.PDFTRON_LICENSE_KEY),
                disabledElements: [
                    'leftPanelButton',
                    'panToolButton',
                    'selectToolButton',
                    'toolbarGroup-View',
                    'toolbarGroup-Annotate',
                    'toolbarGroup-Shapes',
                    'toolbarGroup-Insert',
                    'toolbarGroup-Edit',
                    'toolbarGroup-FillAndSign',
                    'toolbarGroup-Forms',
                    'toolsHeader',
                    'searchButton',
                    'toggleNotesButton',
                    'menuButton',
                    'annotationPopup',
                    'formFieldEditPopup',
                    'contextMenuPopup',
                ],
            }
        },
        svg (): string {
            return this.stamp ? this.stampSvg : this.pinSvg
        },
        pinSvg (): string {
            return `
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                            d="M0 0.627451C0 0.280919 0.280919 0 0.627451 0H16C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16V0.627451Z"
                            fill="${ this.pinColor }"
                        />
                    </g>
                </svg>
            `
        },
        stampSvg (): string {
            return this.stamp && `
                <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <circle stroke-width="2" cx="16" cy="16" r="15" stroke="${ this.stamp.color }" fill="none" />
                        <text
                            font-family="'Inter', sans-serif"
                            font-weight="600"
                            fill="${ this.stamp.color }"
                            x="50%"
                            y="50%"
                            text-anchor="middle"
                            dy=".3em"
                            font-size="14px"
                        >
                            ${ this.stamp.initials }
                        </text>
                    </g>
                </svg>
            `
        }
    },
    async mounted (): Promise<void> {
        const webViewer = await WebViewer(this.options, this.$refs.webViewer as HTMLElement)
        webViewer.UI.loadDocument(this.url, { withCredentials: true })

        this.toggleStampCursorStyleClass(webViewer, true)
        this.setStamp(webViewer)
        this.addMarkupEvents(webViewer)
    },
    methods: {
        toggleStampCursorStyleClass (webViewer: WebViewerInstance, enabled: boolean): void {
            if (!this.cursorClass) {
                return
            }

            const documentContainer = webViewer
                .UI
                .iframeWindow
                .document
                .querySelector('[data-element="documentContainer"]')

            enabled ? documentContainer.classList.add(this.cursorClass) : documentContainer.classList.remove(this.cursorClass)
        },
        async setStamp (webViewer: WebViewerInstance): Promise<void> {
            // @ts-ignore
            const { docViewer } = webViewer

            const stampTool = docViewer.getTool('AnnotationCreateRubberStamp')
            const blob = new Blob([this.svg], { type: 'image/svg+xml' })
            const stampBlob = URL.createObjectURL(blob)

            stampTool.setStandardStamps([stampBlob])
            docViewer.setToolMode(stampTool)

            const [createdStampTool] = await stampTool.getStandardStampAnnotations()
            await stampTool.setRubberStamp(createdStampTool)
        },
        addMarkupEvents (webViewer: WebViewerInstance): void {
            const { annotationManager } = webViewer.Core

            annotationManager.addEventListener(
                'annotationChanged',
                async (annotations: Core.Annotations.Annotation[],
                   action: string,
                ): Promise<void> => {
                    action === 'add' && this.toggleStampCursorStyleClass(webViewer, false)
                    const [annotation] = annotations
                    annotation.disableRotationControl()
                    annotation.NoResize = true
                    annotation.NoDelete = true

                    const xfdf = await annotationManager.exportAnnotationCommand()
                    this.$emit('referenceSelected', { xfdf, uuid: annotation.Id })
                }
            )
        },
    },
})

