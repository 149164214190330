import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import store from '@/store/index'
import contractClient from '@/io-modules/project-contracts/api-clients/contractV3.ts'
import budgetClient from '@/io-modules/budget/api-clients/BudgetClient.ts'
import budgetCostCodeClient from '@/components/budget-cost-code-select/BudgetCostCodeClient.ts'
import SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument.ts'
import Rules from '@/io-modules/project-contracts/interfaces/Rules.ts'
import Summary from '@/io-modules/project-contracts/interfaces/Summary.ts'
import Overview from '@/io-modules/project-contracts/interfaces/Overview.ts'
import Configuration from '@/io-modules/project-contracts/interfaces/Configuration.ts'
import { TimelineLog } from '@/io-modules/project-contracts/interfaces/TimelineLog.ts'
import ContractChanges from '@/io-modules/project-contracts/interfaces/ContractChanges.ts'
import ContractGMPStatus from '@/io-modules/project-contracts/interfaces/ContractGMPStatus.ts'
import { Phase, QuotedItem, Task } from '@/io-modules/project-contracts/interfaces/WbsGroup.ts'
import { WbsPhase, WbsQuotedItem, WbsTask } from '@/io-modules/project-contracts/interfaces/WbsItem.ts'
import { ContractDocument, SupportingDocuments } from '@/io-modules/project-contracts/interfaces/ContractDocuments.ts'
import { LienWaiver } from '@/io-modules/project-contracts/interfaces/LienWaivers.ts'
import TableViewMode from '@/io-modules/project-contracts/enums/TableViewMode.ts'
import ContractStatusAction from '@/io-modules/project-contracts/enums/ContractStatusAction.ts'
import ContractExecutionType from '@/io-modules/project-contracts/enums/ContractExecutionType.ts'
import { InsuranceRequirement } from '@/interfaces/modules/list-templates/InsuranceRequirementsInterface.ts'
import WbsType from '@/io-modules/project-contracts/enums/WbsType.ts'
import { Contract } from '@/modules/projects/modules/apps/common/contract-changes/interfaces/ContractTypes.ts'
import { ExecuteSlideoutData } from '@/interfaces/modules/projects/contract-changes/ContractChange.ts'
import ContractType from '@/base/ContractType.ts'
import ChangeStatusParams from '@/io-modules/project-contracts/interfaces/ChangeStatusParams.ts'
import { Category } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'
import { DeprecatedFlags } from '@/io-modules/project-contracts/interfaces/DeprecatedFlags.ts'
import BudgetStatusInfo from '@/io-modules/budget/interfaces/BudgetStatusInfo.ts'
import { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'
import budgetSitesClient from '@/components/budget-site-select/client.ts'
import ContractHolderType from '@/io-modules/project-contracts/enums/ContractHolderType.ts'

export type ContractState = {
    myBudget: Category[],
    ownerBudget: Category[],
    budgetStatusInfo: BudgetStatusInfo,
    loadedBudgetSites: Record<BudgetType, ProjectSite[]>,
    selectedSiteIds: string[],

    filesArrayId: string,
    isDisplayUnitsEnabled: boolean,
    isDisplayRetentionEnabled: boolean,
    searchString: string,
    wbsTableViewMode: TableViewMode,
    wbsType: WbsType,

    isMainContract: boolean,
    contractExecutionType: ContractExecutionType,
    executionNote: string,
    executionFiles: SingleModelDocument[],

    isContractDetailsLoading: boolean,
    isSovWbsLoading: boolean,
    wbsItems: WbsQuotedItem[],
    isSummaryLoading: boolean,
    summary: Summary,
    executeSlideoutData: ExecuteSlideoutData | null,
    contractConfiguration: Configuration,
    isOverviewLoading: boolean,
    overview: Overview,
    isDocumentsLoading: boolean,
    documents: ContractDocument[],
    lienWaivers: LienWaiver[],
    supportingDocuments: SupportingDocuments,
    insuranceRequirements: InsuranceRequirement[],
    lienWaiverTemplate: {
        id: string,
        name: string,
    },
    rules: Rules,
    attachments: ContractDocument[],
    contractChanges: ContractChanges,
    contractGMPStatus: ContractGMPStatus,
    timeline: TimelineLog[],
    deprecatedFlags: DeprecatedFlags,

    executedContracts: Contract[]
    sitesFromQuotedItems: { id: string, name: string }[]
}

const contractInitialState: ContractState = {
    myBudget: [] as Category[],
    ownerBudget: [] as Category[],
    budgetStatusInfo: null as BudgetStatusInfo,
    loadedBudgetSites: {} as Record<BudgetType, ProjectSite[]>,
    selectedSiteIds: [] as string[],

    filesArrayId: null as string,
    isDisplayUnitsEnabled: false as boolean,
    isDisplayRetentionEnabled: true as boolean,
    searchString: null as string,
    wbsTableViewMode: null as TableViewMode,
    wbsType: WbsType.Original as WbsType,

    isMainContract: null as boolean,
    contractExecutionType: null as ContractExecutionType,
    executionNote: null as string,
    executionFiles: [] as SingleModelDocument[],

    isContractDetailsLoading: true as boolean,
    isSovWbsLoading: true as boolean,
    wbsItems: [] as WbsQuotedItem[],
    isSummaryLoading: true as boolean,
    summary: null as Summary,
    executeSlideoutData: null as ExecuteSlideoutData,
    contractConfiguration: null as Configuration,
    isOverviewLoading: true as boolean,
    overview: null as Overview,
    isDocumentsLoading: true as boolean,
    documents: null as ContractDocument[],
    lienWaivers: null as LienWaiver[],
    supportingDocuments: null as SupportingDocuments,
    insuranceRequirements: null as InsuranceRequirement[],
    lienWaiverTemplate: {
        id: null as string,
        name: null as string,
    },
    rules: null as Rules,
    attachments: null as ContractDocument[],
    contractChanges: null as ContractChanges,
    contractGMPStatus: null as ContractGMPStatus,
    timeline: null as TimelineLog[],
    deprecatedFlags: null as DeprecatedFlags,

    executedContracts: [] as Contract[],
    sitesFromQuotedItems: [],
}

export const contractStore = defineStore('contract', {
    state: (): ContractState => structuredClone(contractInitialState),
    getters: {
        wbsItemsMain (): WbsQuotedItem[] {
            return this.wbsItems
                .filter((item) => !item.isAlternate)
                .sort((a, b) => (
                    dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1
                ))
        },
        wbsItemsMainTotalValue (): number {
            let summaryValue = 0

            this.wbsItemsMain.forEach(item => summaryValue += Number(item.value))

            return summaryValue
        },
        wbsItemsMainTotalRetention (): number {
            let summaryRetention = 0

            this.wbsItemsMain.forEach(item => summaryRetention += Number(item.value) * Number(item.retention))

            return (summaryRetention / this.wbsItemsMainTotalValue) || 0
        },
        wbsItemsAlternate (): WbsQuotedItem[] {
            return this.wbsItems.filter((item) => item.isAlternate).sort((a, b) => a.number - b.number)
        },
        wbsItemsAlternateTotalValue (): number {
            let summaryValue = 0

            this.wbsItemsAlternate.forEach(item => summaryValue += Number(item.value))

            return summaryValue
        },
        wbsItemsAlternateTotalRetention (): number {
            let summaryRetention = 0

            this.wbsItemsAlternate.forEach(item => summaryRetention += Number(item.value) * Number(item.retention))

            return (summaryRetention / this.wbsItemsAlternateTotalValue) || 0
        },
        originalScopeQIs (): WbsQuotedItem[] {
            return this.wbsItemsMain.filter((item) => !Boolean(item.contract_change_id))
        },
        executedCOsQIs (): WbsQuotedItem[] {
            return this.wbsItemsMain.filter((item => Boolean(item.contract_change_id)))
        },
        hasExecutedCOs (): boolean {
            return ContractType.GMP === this.summary?.type
                ? this.amendments?.length > 0
                : this.changeOrders?.length > 0
        },
        changeOrders (): any[] {
            return this.contractChanges?.change_orders || []
        },
        amendments (): any[] {
            return this.contractChanges?.amendments || []
        },
        hasMyBudgetSites (): boolean {
            return this.budgetStatusInfo?.has_my_budget_sites
        },
        hasOwnerBudgetSites (): boolean {
            return this.budgetStatusInfo?.has_owner_budget_sites
        },
        hasBudgetSites (): boolean {
            return this.hasMyBudgetSites || this.hasOwnerBudgetSites
        },
        hasSelectedBudgetSites (): boolean {
            return this.selectedSiteIds.length > 0 || this.sitesFromQuotedItems.length > 0
        },
        selectedMyBudgetSites (): ProjectSite[] {
            return this.loadedBudgetSites[BudgetType.MY_BUDGET]?.filter(site => this.selectedSiteIds.includes(site.project_site_id)) || []
        },
        selectedOwnerBudgetSites (): ProjectSite[] {
            return this.loadedBudgetSites[BudgetType.OWNER_BUDGET]?.filter(site => this.selectedSiteIds.includes(site.project_site_id)) || []
        },
        myBudgetSites (): ProjectSite[] {
            return this.loadedBudgetSites[BudgetType.MY_BUDGET] || []
        },
        ownerBudgetSites (): ProjectSite[] {
            return this.loadedBudgetSites[BudgetType.OWNER_BUDGET] || []
        },
    },
    actions: {
        resetContractState (): void {
            this.$reset()
        },

        async getContractSummary (contractId: string): Promise<void> {
            const result = await contractClient.getContractSummary(contractId)

            if (!result?.data) {
                throw { response: { status: 404 } }
            }
            this.summary = result.data
            this.isMainContract = result.data.is_main
            this.deprecatedFlags = result.data.deprecated_flags
        },

        async getContractConfiguration (contractId: string): Promise<void> {
            const { data } = await contractClient.getContractConfiguration(contractId)

            this.contractConfiguration = data
        },

        async getExecutedAmendment (contractId: string): Promise<void> {
            const { data } = await contractClient.getExecutedAmendment(contractId)

            this.executeSlideoutData = data
        },

        async getContractDocuments (contractId: string): Promise<void> {
            const { data } = await contractClient.getContractDocuments(contractId)
            const { data: lienWaiversData } = await contractClient.getLienWaivers(contractId)

            this.documents = [ ...data.agreement_documents, ...data.initial_agreement_documents ]
            this.supportingDocuments = data.supporting_documents
            this.lienWaiverTemplate.id = data.supporting_documents.lien_waiver_template?.file_id
            this.lienWaiverTemplate.name = data.supporting_documents.lien_waiver_template?.file_name
            this.lienWaivers = lienWaiversData.lien_waivers
        },

        async getInsuranceDetails (contractId: string): Promise<void> {
            const { data } = await contractClient.getInsuranceDetails(contractId)

            this.insuranceRequirements = data.insurance_requirements
        },

        async getAdditionalAttachments (contractId: string): Promise<void> {
            const { data } = await contractClient.getAttachments(contractId)

            this.attachments = data
        },

        async getSovWbs (contractId: string, type: WbsType): Promise<void> {
            const { data } = await contractClient.getSovWbs(contractId, type)

            this.sitesFromQuotedItems = data.project_sites

            this.wbsItems = data.quoted_items.map((item: QuotedItem): WbsQuotedItem => {
                return {
                    contract_change_id: item.contract_change_id,
                    contract_change_relates_to_id: item.contract_change_relates_to_id,
                    id: item.id,
                    number: item.number,
                    number_global: item.number,
                    lvl: item.lvl,
                    name: item.name,
                    value: item.value,
                    costCode: item.cost_code,
                    retention: item.retention,
                    isAlternate: item.is_alternate,
                    enabled: item.enabled,
                    selected: false,
                    focus: false,
                    sovLine: item.contract_quoted_item,
                    unit: item.unit,
                    unitPrice: item.unit_price,
                    qty: item.qty,
                    date: item.date,
                    projectSiteId: item.project_site_id,
                    tasks: item.phases.map((phase: Phase): WbsPhase => {
                        return {
                            id: phase.id,
                            number: phase.number,
                            lvl: phase.lvl,
                            description: phase.description,
                            value: phase.value,
                            retention: phase.retention,
                            parent: phase.parent_id,
                            costType: phase.cost_type,
                            unit: phase.unit,
                            unitPrice: phase.unit_price,
                            qty: phase.qty,
                            number_global: phase.number,
                            tasks: phase.tasks.map((task: Task): WbsTask => {
                                return {
                                    id: task.id,
                                    number: task.number,
                                    lvl: task.lvl,
                                    description: task.description,
                                    value: task.value,
                                    retention: task.retention,
                                    parent: task.parent_id,
                                    costType: task.cost_type,
                                    unit: task.unit,
                                    unitPrice: task.unit_price,
                                    qty: task.qty,
                                    number_global: task.number,
                                }
                            }),
                        }
                    }),
                }
            })
            this.isDisplayRetentionEnabled = data.display_retention
            this.isDisplayUnitsEnabled = data.display_units
        },

        async getContractChanges (contractId: string): Promise<void> {
            const { data } = await contractClient.getContractChanges(contractId)

            this.contractChanges = data
        },

        async getTimeline (contractId: string): Promise<void> {
            const { data } = await contractClient.getTimeline(contractId)

            this.timeline = data
        },

        async deleteDocument (contractId: string, documentId: string): Promise<void> {
            await contractClient.deleteDocument(contractId, documentId)
        },

        async changeStatus (contractId: string, action: ContractStatusAction, params?: ChangeStatusParams): Promise<void> {
            await contractClient.changeStatus(contractId, action, params)
        },

        async getGMPStatus (contractId: string): Promise<void> {
            const { data } = await contractClient.getGMPStatus(contractId)

            this.contractGMPStatus = data
        },

        async getContractApprovableResourceId (contractId: string): Promise<string> {
            const { data } = await contractClient.getApprovableResourceId(contractId)

            return data
        },

        async getMyBudget (projectLocalId: string): Promise<void> {
            const siteIds = this.loadedBudgetSites[BudgetType.MY_BUDGET]?.map(site => site.project_site_id)
            if (!siteIds?.length) {
                const { data } = await budgetCostCodeClient.getProjectCostCodes(
                    projectLocalId, { budgetType: BudgetType.MY_BUDGET },
                )

                this.myBudget = data.data
            } else {
                const requests = siteIds.map(siteId => budgetCostCodeClient.getProjectCostCodes(
                    projectLocalId, { budgetType: BudgetType.MY_BUDGET, siteId },
                ))

                const budgets = await Promise.all(requests)

                this.myBudget = budgets.flatMap(({ data }, index) => {
                    return data.data.map((category: Category) => {
                        return {
                            ...category,
                            project_site_id: siteIds[index],
                        }
                    })
                })
            }
        },

        async getOwnerBudget (projectLocalId: string): Promise<void> {
            const siteIds = this.loadedBudgetSites[BudgetType.OWNER_BUDGET]?.map(site => site.project_site_id)
            if (!siteIds?.length) {
                const { data } = await budgetCostCodeClient.getProjectCostCodes(
                    projectLocalId, { budgetType: BudgetType.OWNER_BUDGET },
                )

                this.ownerBudget = data.data
            } else {
                const requests = siteIds.map(siteId => budgetCostCodeClient.getProjectCostCodes(
                    projectLocalId, { budgetType: BudgetType.OWNER_BUDGET, siteId },
                ))

                const budgets = await Promise.all(requests)

                this.ownerBudget = budgets.flatMap(({ data }, index) => {
                    return data.data.map((category: Category) => {
                        return {
                            ...category,
                            project_site_id: siteIds[index],
                        }
                    })
                })
            }
        },

        async getBudgetSites (projectGlobalId: string, budgetType: BudgetType): Promise<void> {
            const result = await budgetSitesClient.getSitesByProject(projectGlobalId, budgetType)

            this.loadedBudgetSites[budgetType] = result.data
        },

        async getBudget (projectLocalId: string): Promise<void> {
            await this.getMyBudget(projectLocalId)
            await this.getOwnerBudget(projectLocalId)
        },

        async getBudgetStatus (projectLocalId: string): Promise<void> {
            const { data } = await budgetClient.getBudgetStatus(projectLocalId)

            this.budgetStatusInfo = data.data
        },
    },
})
