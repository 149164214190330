import defaultState from './state'
import { cloneDeep, isArray } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export function RESET_INVITATION_DATA (state) {
    state.invitationData = cloneDeep(defaultState.invitationData)
}

export function SET_UNITS (state, value) {
    state.units = value
}

export function SET_COST_CODES (state, value) {
    state.costCodes = value
}

export function SET_PREVIOUS_RETENTION_RULES (state, value) {
    state.previousRetentionRules = isArray(value) ? cloneDeep(value) : []
}

export function SET_COST_CODES_PROJECT (state, value) {
    state.costCodesProject = value
}

export function VUE_REFRESH_TASKS (state) {
    state.vueRefreshTasks = state.vueRefreshTasks + 1
}

export function SET_NEED_RELOAD (state, value) {
    state.needReload = value
}

export function SET_ACTIVE_TAB (state, value) {
    state.activeTab = value
}

export function SET_GMP_CONTRACT_TAB (state, value) {
    state.gmpContractTab = value
}

export function SET_COMPANIES_MAP (state, value) {
    state.companies_map = value
}

export function RESET_VALIDATION_ERRORS (state, value) {
    state.validationErrors = cloneDeep(defaultState.validationErrors)
}

export function SET_VALIDATION_ERROR (state, data) {
    if (!data.type || !state.validationErrors[data.type]) {
        return
    }
    const errors = (data.subtype) ? state.validationErrors[data.type][data.subtype] : state.validationErrors[data.type]
    if (!errors) {
        return
    }
    if (isArray(errors)) {
        errors.push(data.value)
    }
}

export function REMOVE_VALIDATION_ERROR (state, data) {
    if (!data.type || !state.validationErrors[data.type]) {
        return
    }
    const errors = (data.subtype) ? state.validationErrors[data.type][data.subtype] : state.validationErrors[data.type]
    if (!errors) {
        return
    }
    if (isArray(errors)) {
        const index = errors.findIndex(error => {
            return error === data.value
        })
        if (index > -1) {
            errors.splice(index, 1)
        }
    }
}


export function SET_MODAL_SOV_LINE (state, value) {
    if (value) {
        state.modalSelectSovLineData = value
        state.modalSelectSovLine = true
    } else {
        state.modalSelectSovLineData = null
        state.modalSelectSovLine = false
    }
}

export function SET_RETENTION_RULES_OPTIONS (state, value) {
    state.retentionRulesOptions = value
}

export function SET_MODAL_CONTRACT_STAGE (state, value) {
    state.modalContractStage = value
}
export function SET_MODAL_CONTRACT (state, value) {
    state.modalContract = value

    if (value) {
        $('.intercom-app').hide()
        $('html, body').addClass('io-no-scroll')
    } else {
        $('.intercom-app').show()
        $('html, body').removeClass('io-no-scroll')
    }
}

export function SET_COST_TYPES (state, value) {
    state.costTypes = value
}

export function SET_QUOTED_ITEMS_LIST (state, categories) {
    const elements = []
    if (isArray(categories) && categories.length) {
        categories.forEach(category => {
            if (!category.items || !isArray(category.items)) {
                return false
            }
            category.items.forEach(code => {
                elements.push(code)
            })
        })
    }
    state.quotedItemsList = elements
}

export function SET_MAIN_CONTRACT (state, value) {
    state.mainContract = value
}

export function SET_MAIN_CONTRACT_RESOURCE (state, value) {
    state.mainContractResource = value
}

export function SET_IN_PROJECT (state, value) {
    state.inProject = value
}

export function SET_CHANGE_ORDERS (state, value) {
    state.changeOrders = value
}

export function SET_AMENDMENTS (state, value) {
    state.amendments = value
}

export function SET_INVOICES (state, value) {
    state.invoices = value
}

export function SET_CLIENTS_INTERNAL (state, value) {
    state.clientsInternal = value
}

export function SET_ALLOWED_CLIENTS_TYPES (state, value) {
    state.allowedClientsTypes = value
}

export function SET_DIVISIONS (state, value) {
    state.divisions = value
}

export function SET_CONTRACT (state, value) {
    state.contract = value
}

export function SET_CONTRACT_STATUS (state, value) {
    state.contract.status = value
}

export function SET_CONTRACT_INITIAL_STATUS (state, value) {
    state.contract.initialContractStatus = value
}

export function SET_CONTRACT_APPROVAL_STATUS (state, value) {
    state.contract.approvalStatus = value
}

export function SET_CONTRACT_SOV_STATUS (state, value) {
    state.contract.statusSov = value
}

export function SET_LIEN_WAIVER_FILES (state, value) {
    state.contract.lien_waiver_files = value
}

export function SET_CONTRACT_FILES (state, value) {
    state.contract.files = value
}

export function SET_SHOW_POPUP_CLIENT_APPROVAL (state, value) {
    state.showPopupClientApproval = value
}

export function SET_SHOW_POPUP_USER_REJECTION (state, value) {
    state.showPopupUserRejection = value
}

export function SET_SHOW_POPUP_USER_APPROVAL (state, value) {
    state.showPopupUserApproval = value
}

export function SET_SHOW_POPUP_MANUAL_APPROVAL (state, value) {
    state.showPopupManualApproval = value
}

export function SET_SHOW_POPUP_MANUAL_REJECTION (state, value) {
    state.showPopupManualRejection = value
}

export function SET_CREATING_FROM_TEMPLATE_MODE (state, value) {
    state.creatingContractFromTemplatesMode = value
}
export function SET_ADDING_DOCUMENT_TEMPLATES_TO_CONTRACT_MODE (state, value) {
    state.addingDocumentTemplatesToContractMode = value
}
export function SET_SELECTED_CONTRACT_TEMPLATES_IDS (state, value) {
    state.selectedContractTemplatesIds = value
}
export function SET_DISABLED_CONTRACT_TEMPLATES_IDS (state, value) {
    state.disabledContractTemplatesIds = value
}
export function SET_CREATING_CONTRACT_DATA (state, value) {
    state.creatingContractData = value
}

export function CLEAR_CREATING_CONTRACT_FROM_TEMPLATES_DATA (state) {
    state.creatingContractData = {}
}

export function CLEAR_SELECTED_CONTRACT_TEMPLATES_IDS (state) {
    state.selectedContractTemplatesIds = []
}
export function CLEAR_DISABLED_CONTRACT_TEMPLATES_IDS (state) {
    state.disabledContractTemplatesIds = []
}

export function SET_REPLACING_INSURANCE (state, value) {
    state.replacingInsurance = value
}

export function SET_REPLACED_INSURANCE (state, value) {
    state.replacedInsurance = value
}

export function SET_SHOW_INSURANCE_REPLACE_MODAL (state, value) {
    state.showInsuranceReplaceModal = value
}

export function SET_SHOW_CONTRACT_TYPE_MODAL (state, value) {
    state.showContractTypeSelectorModal = value
}

export function SET_CONTRACT_DOCUMENTS (state, value) {
    state.contract.contractDocuments = value
}

export function SET_IS_CONTRACT_SYNCED (state, value) {
    state.isSynced = value
}

export function SET_CONTRACT_INSURANCE_REQUIREMENTS (state, value) {
    state.contract.insurance_requirements = value
}

export function SET_CONTRACT_QUOTED_ITEMS (state, value) {
    state.contract.phases = value
}

export function SET_WBS_ITEMS ( state, value ) {
    state.wbsItems = value
}

export function SET_DISPLAY_UNITS_TOGGLE_INITIAL ( state, value ) {
    state.isDisplayUnitsEnabledInitial = value
}

export function SET_DISPLAY_RETENTION_TOGGLE_INITIAL ( state, value ) {
    state.isDisplayRetentionEnabledInitial = value
}

export function SET_DISPLAY_UNITS_TOGGLE ( state, value ) {
    state.isDisplayUnitsEnabled = value
}

export function SET_DISPLAY_RETENTION_TOGGLE ( state, value ) {
    state.isDisplayRetentionEnabled = value
}

export function SET_TABLE_VIEW_MODE ( state, value ) {
    state.tableViewMode = value
}

export function SET_REQUIRE_SOV_APPROVAL_TOGGLE ( state, value ) {
    state.isRequireSoVApprovalEnabled = value
}

export function SET_REQUIRE_UPDATE_SOV_APPROVAL_TOGGLE ( state, value ) {
    state.isRequireUpdateSoVApprovalEnabled = value
}
