<template>
    <td v-if="!isHidden()" :class="columnClass" v-on:click.stop>
        <div class="io-atoms-checkbox">
            <input
                type="checkbox"
                :value="handle"
                :disabled="isDisabled"
                v-model="selectedRows"
                @input="addSelectedRow"
            />
        </div>
    </td>
</template>

<script lang="ts" src="./ServerSideCheckbox.ts"></script>
<style lang="scss" src="./ServerSideCheckbox.scss" scoped></style>
