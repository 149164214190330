<template>
    <div v-if="fundInfo" class="io-create-proj-spec-fund-right">
        <ProgressBar variant="small" :items="progressBar" :showLegend="false" :useItemValueAsRemains="true"/>
        <div class="io-create-proj-spec-fund-right__comparison">
            <IOSingleInfoRow :label="$t('Total Funding')" :info="$filters.formatCurrency(calcTotalFunding)"/>
            <div class="io-create-proj-spec-fund-right__equal">
                <span :class="defineIcon"/>
            </div>
            <IOSingleInfoRow :label="$t('Current Budget')" :info="$filters.formatCurrency(currentBudget)"/>
        </div>
        <IOSingleInfoRow :label="`% ${ $t('of Total Project Funding') }`" :info="calcPercentOfProjectFunding"/>

        <template v-if="fundInfo.isEdit">
            <IOSingleInfoRow :label="$t('Paid to Date')" :info="$filters.formatCurrency(fundInfo.paid_to_date)"/>
            <IOSingleInfoRow :label="$t('Remaining Balance')" :info="$filters.formatCurrency(calcRemainingBalance)"/>
        </template>
    </div>
</template>

<script lang="ts" src="./CreateProjectSpecFundRight.ts"></script>
<style lang="scss" src="./CreateProjectSpecFundRight.scss" scoped></style>
