<template>
    <div class="io-project-search">
        <i class="icon-search io-project-search__icon"></i>
        <input
            type="text"
            class="io-project-search__input"
            :placeholder="$t('Search category...')"
            @input="onProjectSearchChange"
        >
    </div>
</template>

<script src="./ProjectSearch.ts"></script>
<style lang="scss" src="./ProjectSearch.scss" scoped></style>
