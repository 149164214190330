<template>
    <div v-show="paymentInfo" class="io-content-wrapper">
        <div class="io-form-item">
            <IOLabel :text="$t('Amount')" :is-required="true"/>
            <field-numeric-int
                :id="'amount'"
                class="io-amount-field"
                :class="{ 'io-amount-field--error': !isAmountValid }"
                :options="{ numeralPositiveOnly: false, numericOnly: false }"
                :showRounded="true"
                :sign="'$'"
                v-model="paymentInfo.amount"
            />
            <div v-if="!isAmountValid" class="io-input-error">{{ $t('Cannot exceed the Net Invoice Amount') }}</div>
        </div>
        <div class="io-form-item">
            <IOLabel :text="$t('Method')" :is-required="true"/>
            <DropdownButton
                buttonClass="io-custom-dropdown"
                name=""
            >
                <template #selectedItem>
                    <span v-if="paymentInfo.method" class="io-f-row io-f-center">
                        {{ paymentInfo.method.name }}
                    </span>
                    <span v-else></span>
                </template>
                <template #default>
                    <div
                        v-for="item in paymentMethods"
                        :key="item.id"
                        class="io-dropdown-option"
                        @click="selectMethod(item)"
                    >
                        {{ item.name }}
                    </div>
                </template>
            </DropdownButton>
        </div>
        <div class="io-form-item">
            <IOLabel
                :text="$t('Payment Date')"
                :is-required="true"
            />
            <FieldDateTime :options="datepickerOptions" :showIcon="true" v-model="paymentInfo.date"/>
        </div>
        <div class="io-form-item">
            <IOLabel
                :text="PaymentMethod.Check === paymentInfo.method?.id ? $t('Check Number') : $t('Reference Number')"
                :is-required="true"
            />
            <IOInput v-model="paymentInfo.referenceNumber"/>
        </div>
    </div>
</template>

<script lang="ts" src="./MarkAsPaidBody.ts"></script>
<style lang="scss" src="./MarkAsPaidBody.scss" scoped></style>
