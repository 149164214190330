<template>
    <IOModal
        class="io-override-approver-modal"
        icon="icon-users"
        width="451"
        :title="$t('Override Approver')"
        @close="closeModal"
    >
        <template #default>
            <div class="io-content-wrapper">
                <AlertBox>
                    {{ $t('Overriding this user will result in a one-time replacement within this approval workflow.') }}
                </AlertBox>
                <div>
                    <IOLabel :text="$t('Overridden User')" />
                    <Avatar
                        size="sm"
                        :employeeMongoId="approver.id"
                        :employeeFullName="approver.full_name"
                        :companyName="approver.company"
                        :showFullName="true"
                    />
                </div>
                <div>
                    <IOLabel :text="$t('Replacement')" />
                    <DropdownButton
                        buttonClass="io-custom-dropdown"
                        name=""
                    >
                        <template #selectedItem>
                            <span v-if="selectedUser" class="io-custom-dropdown__item">
                                <Avatar
                                    size="sm"
                                    :employeeMongoId="selectedUser.id"
                                    :employeeFullName="selectedUser.full_name"
                                    :showFullName="true"
                                />
                            </span>
                            <span v-else class="io-custom-dropdown__placeholder">
                                <div class="io-custom-dropdown__placeholder-icon">
                                    <span class="icon-plus"></span>
                                </div>
                                <IOInput :placeholder="$t('Choose New User')" v-model="searchUser" />
                            </span>
                        </template>
                        <template #default>
                            <Avatar
                                v-for="item in filteredUsers"
                                :key="item.id"
                                class="io-dropdown-option"
                                size="sm"
                                :employeeMongoId="item.id"
                                :employeeFullName="item.full_name"
                                :showFullName="true"
                                @click="selectUser(item)"
                            />
                            <div v-if="!eligibleApprovers.length" class="io-placeholder">{{ $t('No approvers to override') }}</div>
                        </template>
                    </DropdownButton>
                </div>
            </div>
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="closeModal">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton :disabled="!selectedUser" @click="overrideApprover">
                {{ $t('Override') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./OverrideApproverModal.ts"></script>
<style lang="scss" src="./OverrideApproverModal.scss" scoped></style>
