import { defineComponent } from 'vue'
import { Section } from '@/io-modules/specifications/components/spec-reference-select/interfaces/Specification'

export default defineComponent({
    name: 'SectionsShow',
    props: {
        section: {
            type: Object as () => Section,
            required: true,
        },
    },
    computed: {
        active (): boolean {
            return this.$route.query['spec-section'] === this.section.current_version_id
        },
    },
})
