import { defineComponent } from 'vue'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import filterSection from '@/components/ModalFilters/filters/filterSection.vue'
import { TranslateResult } from 'vue-i18n'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import { AvatarType } from '@/constants/AvatarConstants'

export default defineComponent({
    components: {
        filterSection,
        CustomMultiselect,
        Avatar
    },
    props: {
        placeholder: { type: String, default: '' },
        title: { type: String, default: '' },
        tooltipText: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        labelName: { type: String, default: 'full_name' },
        avatarType: { type: String, default: AvatarType.PERSON },
        modelValue: { type: Array as () => string[], default: () => [] },
        options: { type: Array as () => Array<any>, default: () => [] }
    },
    data () {
        return {
            dropdownExpanded: false,
            checkboxesValue: [],
            searchKeyword: ''
        }
    },
    computed: {
        filteredOptions (): Array<any> {
            if (!this.options.length) {
                return []
            }

            if (!this.usersVariant) {
                return this.options.filter((option) => option[this.labelName]?.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) > -1)
            }

            const selected = this.options.filter((option) => this.checkboxesValue.includes(option.id))
            const unSelected = this.options.filter((option) => !this.checkboxesValue.includes(option.id))
            const all = [...selected, ...unSelected]

            return all.filter((option) => option[this.labelName]?.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) > -1)
        },
        selectedOptions (): string | TranslateResult {
            if (this.searchKeyword) {
                return this.searchKeyword
            }

            if (!this.checkboxesValue?.length) {
                return this.placeholder
            }

            return this.checkboxesValue.map((val: string) => this.options.find((option: {}) => option.id === val)?.[this.labelName]).join(', ')

        },
        usersVariant (): boolean {
            return this.labelName === 'full_name'
        }
    },
    watch: {
        'checkboxesValue' (): void {
            this.searchKeyword = ''
            this.$emit('update:modelValue', this.checkboxesValue)
        },
        modelValue (): void {
            this.checkboxesValue = this.modelValue
        }
    },
    mounted () {
        this.checkboxesValue = this.modelValue
    },
    methods: {
        selectOption (id: string): void {
            if (this.checkboxesValue.includes(id)) {
                  this.checkboxesValue = this.checkboxesValue.filter((value) => value !== id)
            } else {
                this.checkboxesValue.push(id)
            }
        },
        toggleDropdown (): void {
            if (this.disabled) {
                return
            }

            this.dropdownExpanded = !this.dropdownExpanded

            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.focus()
                }
            })
        },
        closeDropdown (): void {
            this.dropdownExpanded = false
        }
    }
})
