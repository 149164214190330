import { defineComponent } from 'vue'

export default defineComponent({
    data () {
        return {
            show: false
        }
    },
    created (): void {
        const showSplashScreen = !localStorage.getItem('hide_mobile_splash_screen')
        if (document.documentElement.clientWidth <= 1080 && showSplashScreen) {
            this.setViewportMeta(true)
            this.show = true
        }
    },
    methods: {
        setViewportMeta (disableScaling: boolean): void {
            const viewportMeta: HTMLMetaElement | null = document.querySelector('meta[name="viewport"]')
            const content: string = disableScaling ? 'width=device-width, initial-scale=1, shrink-to-fit=no' : ''

            if (!viewportMeta) return
            viewportMeta.setAttribute('content', content)
        },
        dismiss (): void {
            this.setViewportMeta(false)
            this.show = false
            localStorage.setItem('hide_mobile_splash_screen', 'true')
        }
    }
})
