<template>
    <div class="io-financial-panel">
        <div v-if="!error && !loading" class="io-financial-panel__item">
            <div class="io-financial-panel__item-title">
                {{ $t('Committed Contract Value') }}
            </div>
            <div class="io-financial-panel__item-content">
                <div class="io-two-columns">
                    <IOSingleInfoRow :label="$t('Project Total')" :info="projectTotal" />
                    <IOSingleInfoRow :label="$t('Included in Package')" :info="includedInPackage" />
                </div>
            </div>
        </div>
        <RequestedDataErrorPlaceholder v-else :title="$t('Committed Contract Value')" :loading="loading" @reload="fetchData" />
        <div v-if="!error && !loading" class="io-financial-panel__item">
            <div class="io-financial-panel__item-title">
                {{ $t('Billed to Date') }}
            </div>
            <div class="io-financial-panel__item-content">
                <div class="io-two-columns">
                    <IOSingleInfoRow :label="$t('Gross Total')" :info="grossTotal" />
                    <IOSingleInfoRow :label="$t('Net Total')" :info="netTotal" />
                </div>
            </div>
        </div>
        <RequestedDataErrorPlaceholder v-else :title="$t('Billed to Date')" :loading="loading" @reload="fetchData" />
        <div v-if="!error && !loading" class="io-financial-panel__item">
            <div class="io-financial-panel__item-title">
                {{ $t('Paid to Date') }}
            </div>
            <div class="io-financial-panel__item-content">
                <div class="io-two-columns">
                    <IOSingleInfoRow :label="$t('Paid to Date (%)')" :info="paidToDatePercent" />
                    <IOSingleInfoRow :label="$t('Paid to Date Amount')" :info="paidToDateAmount" />
                </div>
            </div>
        </div>
        <RequestedDataErrorPlaceholder v-else :title="$t('Paid to Date')" :loading="loading" @reload="fetchData" />
    </div>
</template>

<script lang="ts" src="./FinancialPanel.ts"></script>
<style lang="scss" src="./FinancialPanel.scss" scoped></style>
