import { defineComponent, PropType } from 'vue'
import { DotVariant } from '@/components/atoms/status-dot/enums/StatusDotEnums'
import StatusDot from '@/components/atoms/status-dot/StatusDot.vue'

export default defineComponent({
    name: 'Stepper',
    props: {
        modelValue: { type: Number, required: true },
        showStepNumber: { type: Boolean, default: true },
        isFloating: { type: Boolean, default: true },
        availableSteps: {
            type: Array as PropType<Object[]>,
            default: null,
        },
        shadow: {
            type: Boolean,
            default: true
        },
        noContent: {
            type: Boolean,
            default: false
        },
    },
    components: {
        StatusDot,
    },
    data () {
        return {
            DotVariant,
            steps: [],
        }
    },
    watch: {
        availableSteps: {
            handler () {
                this.steps = this.availableSteps.map(item => {
                    return {
                        props: item
                    }
                })
            }
        }
    },
    mounted (): void {
        this.updateSlots()
    },
    methods: {
        selectStep (indexToSelect: number, stepDisabled?: boolean): void {
            this.$emit('onClickStep', indexToSelect)

            if (stepDisabled) {
                return
            }

            this.$emit('update:modelValue', indexToSelect)
        },
        updateSlots (): void {
            if (this.availableSteps && this.availableSteps.length) {
                this.steps = this.availableSteps.map(item => {
                    return {
                        props: item
                    }
                })
            } else {
                this.steps = this.$slots.default().filter(slots => slots.props)
            }
        }
    },
})
