<template>
    <div v-if="foldersBreadcrumbs" class="io-breadcrumbs">
        <div v-if="isGoBackEnabled" class="io-breadcrumbs__go-back" @click="goBack"><span class="icon-undo"></span> {{ $t('Back') }}</div>

        <div
            v-for="(breadcrumb, idx) in breadcrumbs"
            :key="idx"
            class="io-breadcrumbs__item"
            :class="{ 'io-active': idx === breadcrumbs.length - 1 }"
        >
            <p @click="loadFolders(breadcrumb)">{{ breadcrumb.name }}</p>

            <span v-if="idx !== breadcrumbs.length - 1" class="icon-chevron-right"></span>
        </div>
    </div>
</template>
<script src="./Breadcrumbs.ts" lang="ts"></script>
<style lang="scss" src="./Breadcrumbs.scss" scoped></style>
