export const setChatWindowComponent = ({commit}, val) => {
    commit('SET_CHAT_WINDOW_COMPONENT', val)
}

export const clearChatConfig = ({commit}) => {
    commit('CLEAR_CHAT_CONFIG')
}

export const setChatConfig = ({commit}, val) => {
    commit('SET_CHAT_CONFIG', val)
}

export const setExpanded = ({commit}, val) => {
    commit('SET_CHAT_EXPANDED', val)
}

export const setUnreadMessages = ({commit}, val) => {
    commit('SET_UNREAD_MESSAGES', val)
}

export const setNotificationId = ({commit}, val) => {
    commit('SET_NOTIFICATION_ID', val)
}
