import { defineComponent } from 'vue'

import { Size } from '@/io-modules/punch-lists/enums/Stamp'

export default defineComponent({
    name: 'StampPreview',
    props: {
        initials: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '#63666E',
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String as () => Size,
            default: Size.MD,
        },
        buttonMode: Boolean,
        isActive: Boolean,
        disabled: Boolean,
    },
    emits: ['toggleActive'],
})
