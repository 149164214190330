import { defineComponent } from 'vue'
import EmptyDescription from '../empty-description/EmptyDescription.vue'

export default defineComponent({
    components: {
        EmptyDescription,
    },
    props: {
        exclusions: { type: String, required: true, default: '' }
    },
    data () {
        return {
            projectExclusionsCopy: ''
        }
    },
    beforeMount () {
        this.projectExclusionsCopy = this.exclusions
    },
})
