<template>
    <div class="io-sites-navigation" :class="{ 'io-sites-navigation--empty': !sites }">
        <div v-if="showHeader" class="io-sites-navigation__header">
            <span>{{ $t('Sites') }}</span>
            <IOButton v-if="canEditSites" variant="link" @click="initEditSites">{{ $t('Edit Sites') }}</IOButton>
        </div>
        <div class="io-sites-navigation__content">
            <template v-if="sites">
                <div
                    v-for="site in sites"
                    :key="site.id"
                    class="io-sites-navigation__item"
                    :class="{'io-sites-navigation__item--active': activeItem === site.project_site_id}"
                    @click.prevent="scrollToItem(site.project_site_id)"
                >
                    {{ site.title }}
                    <span v-if="site.completed" :class="completedIcon"></span>
                </div>
            </template>
            <div v-else class="io-sites-navigation__content-placeholder">{{ $t('No sites selected') }}</div>
        </div>
    </div>
</template>

<script lang="ts" src="./SitesNavigation.ts"></script>
<style lang="scss" src="./SitesNavigation.scss" scoped></style>
