<template>
    <div
        class="io-notification-wrapper"
        :class="{ 'io-notification-wrapper-loading': isLoading }"
        @click="onNotificationWrapperClick"
    >

        <div
            v-if="isLoading"
            class="io-loader-small"
        ></div>

        <div class="io-notification-content">
            <div
                v-html="notificationData.message"
                class="io-notification-content__title"></div>
        </div>

        <div
            v-if="isInvitationControlsVisible"
            class="io-notification-controls"
        >
            <IOButton
                :disabled="isLoading"
                @click.stop="onAcceptInvitationClick">
                {{ $t('Accept Invitation') }}
            </IOButton>

            <IOButton
                variant="link"
                :disabled="isLoading"
                @click.stop="toggleRejectModalVisibility"
            >
                {{ $t('Reject') }}
            </IOButton>
        </div>

        <div
            v-if="isInvitationStatusVisible"
            class="io-notification-status">
            <i
                class="io-notification-status__icon"
                :class="{ 'io-notification-status__icon_accepted': isInvitationAccepted,
                          'icon-check-circle-3': isInvitationAccepted,
                          'io-notification-status__icon_rejected': isInvitationRejected,
                          'icon-cross-circle-2': isInvitationRejected, }"
            ></i>

            <span class="io-notification-status__text">

                <template v-if="isInvitationAccepted">
                    {{ $t('Invitation accepted') }}

                    <span
                        class="io-notification-status__link"
                        @click="onGoToClick"
                    >
                        {{ $t('Go to') }} {{ notificationData.typeText }}
                    </span>
                </template>

                <template v-if="isInvitationRejected">
                    {{ $t('Invitation rejected') }}
                </template>
            </span>
        </div>

        <RejectModal
            v-if="isRejectInvitationModalVisible"
            @close="toggleRejectModalVisibility"
            @rejectInvitation="onInvitationReject"
        />

    </div>
</template>

<script src="./Invitation.ts"></script>
<style lang="scss" src="./Invitation.scss" scoped></style>
