<template>
    <td>

        <i v-if="data.status === 'accepted'"
           class="io-invite-success fas fa-check-circle"
        ></i>

        <span
            v-else-if="data.status === 'rejected'"
            class="io-invite"
        >
            <span fa-times-circle
                class="io-invite-rejected fas fa-times-circle"
            >
            </span>
            <span class="io-invite-reject-text">
                {{ $t('Invitation Rejected') }}
            </span>
        </span>

        <span
            v-else-if="data.status === 'saved'"
            class="io-invite"
        >
            {{ $t('Bookmarked') }}
            <span
                class="io-invite-button fal fa-paper-plane"
                @click="onInviteClick"
            >
                <span class="io-invite-text">
                    {{ $t('Invite') }}
                </span>
            </span>
        </span>

        <span
            v-else-if="data.status === 'invited'"
            class="io-invite"
        >
            {{ $t('Invite Sent') }}
            <span
                class="io-invite-button fal fa-paper-plane"
                @click="onInviteClick"
            >
                <span class="io-invite-text">
                    {{ $t('Remind') }}
                </span>
            </span>
        </span>

    </td>
</template>

<script lang="ts" src="./InvitationButton.ts"></script>
<style lang="scss" src="./InvitationButton.scss" scoped></style>
