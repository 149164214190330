import { ApiClient } from '../api'
import { AxiosPromise } from 'axios'
import { FolderSharingRequestInterface } from '@/interfaces/modules/documents/FolderSharingRequestInterface'
const client = ApiClient

const documentsFolderClient = {
    archiveFolder (folderId: string): AxiosPromise<void> {
        return client.post(`/documents/folder/${ folderId }/delete`)
    },
    hasSharedDocument (folderId: string): AxiosPromise<void> {
        return client.get(`/folders/${ folderId }/has-shared-document`)
    },
    renameFolder (id: string, name: string): AxiosPromise<void> {
        return client.post(`/documents/folder/${ id }/rename`, { folder_name: name })
    },
    folderSharing (folderId: string, data: FolderSharingRequestInterface): AxiosPromise<FolderSharingRequestInterface> {
        return client.patch(`/documents/folder-sharing/${ folderId }`, data)
    },
}

export default documentsFolderClient
