export enum ContractStatus {
    DRAFT = 'contract.status_draft',
    CONTRACT_REQUESTED = 'contract.status_requested',
    FINAL_DRAFT_APPROVED = 'contract.status_final_draft_approved',
    READY_FOR_APPROVAL = 'contract.status_ready_for_approval',
    APPROVAL_PROCESS = 'contract.status_approval_process',
    UNDER_REVIEW = 'contract.status_under_review',
    PARTIALLY_SIGNED = 'contract.status_partial_signed',
    READY_FOR_SIGNATURE = 'contract.status_ready_for_signature',
    INITIAL_EXECUTED = 'contract.status_initial_execution',
    ORIGINAL_EXECUTED = 'contract.status_original_execution',
    EXECUTED = 'contract.status_executed',
    FINAL_EXECUTION = 'contract.status_final_execution',
}

export enum ContractStatusNumeric {
    DRAFT = 100,
    CONTRACT_REQUESTED = 150,
    FINAL_DRAFT_APPROVED = 260,
    READY_FOR_APPROVAL = 170,
    UNDER_REVIEW = 180,
    PARTIALLY_SIGNED = 250,
    READY_FOR_SIGNATURE = 280,
    EXECUTED = 600,
    SIGNED = 350,
    DECLINED = 400,
    SIGN_AND_FINALIZE = 270,
}
