import { defineComponent } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import PaymentMethod from '@/interfaces/PaymentMethod'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import dateTimeHelper from '@/helpers/dateTime'
import dayjs from 'dayjs'
import DropdownButton from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import { mapState as mapStateVuex } from 'vuex'
import PaymentInfo from '@/io-modules/invoices/interfaces/PaymentInfo.ts'

export default defineComponent({
    name: 'MarkAsPaidBody',
    components: {
        IOModal,
        FieldDateTime,
        DropdownButton,
    },
    props: {
        netInvoiceValue: {
            type: Number,
            default: 0
        },
        modelValue: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            PaymentMethod,
            paymentInfo: this.getInitialPaymentInfo(),
            open: false,
            datepickerOptions: {
                'only-date': true,
                noClearButton: true,
                format: 'YYYY-MM-DD',
                formatted: dateTimeHelper.getDateFormat(),
            },
        }
    },
    computed: {
        ...mapStateVuex('project', { projectID: (state: any) => state.projectObj.project_local_id }),

        isAmountValid (): boolean {
            if (!this.paymentInfo) {
                return false
            }
            if (!this.paymentInfo.amount || !this.netInvoiceValue) {
                return true
            }

            return Math.abs(this.paymentInfo.amount) <= Math.abs(this.netInvoiceValue)
        },

        paymentMethods (): object[] {
            return [
                {
                    id: PaymentMethod.ACH,
                    name: this.$t('ACH'),
                },
                {
                    id: PaymentMethod.Check,
                    name: this.$t('Check'),
                },
                {
                    id: PaymentMethod.CreditCard,
                    name: this.$t('Credit Card'),
                },
            ]
        },
    },
    watch: {
        paymentInfo: {
            handler (): void {
                this.$emit('update:modelValue', this.paymentInfo)
            },
            deep: true
        },
        modelValue: {
            handler (newValue: PaymentInfo): void {
                this.paymentInfo = newValue
            },
            deep: true,
        },

    },
    mounted () {
        if (this.modelValue) {
            this.paymentInfo = this.modelValue
            if (this.paymentInfo && !this.paymentInfo.date) {
                this.paymentInfo.date = dayjs(new Date()).format('YYYY-MM-DD')
            }
        }
    },
    methods: {
        closeModal (): void {
            this.open = false
            this.clearData()
        },

        getInitialPaymentInfo (): PaymentInfo {
            return {
                amount: this.netInvoiceValue,
                method: null,
                date: dayjs(new Date()).format('YYYY-MM-DD'),
                referenceNumber: '',
            }
        },

        clearData (): void {
            this.paymentInfo = this.getInitialPaymentInfo()
        },

        show (): void {
            this.open = true
            this.paymentInfo.amount = this.netInvoiceValue
        },

        selectMethod (method: object): void {
            this.paymentInfo.method = method
        },
    }
})
