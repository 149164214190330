<template>
    <div class="io-notices">

        <transition-group tag="div" class="io-notices__list" appear name="alert">
            <template v-for="alert in alertsList" :key="alert.id">
                <single-alert :alert="alert"/>
            </template>
        </transition-group>

        <transition-group tag="div" class="io-notices__list" appear name="notification">
            <template v-for="notice in noticesList" :key="notice.id" >
                <single-notification :notice="notice"/>
            </template>
        </transition-group>

    </div>
</template>

<script src="./notices.js"></script>
<style lang="scss" src="./notices.scss" scoped></style>
