const ProjectProvider = () => import('@/layouts/project-provider/ProjectProvider.vue')

const RfisIndex = () => import('@/io-modules/rfis/views/rfis-index/RfisIndex.vue')

const RfisShow = () => import('@/io-modules/rfis/views/rfis-show/RfisShow.vue')

export default {
    path: '/project/:pid/',
    component: ProjectProvider,
    redirect: { name: 'rfis-index' },
    props: true,
    children: [
        {
            path: 'rfis',
            name: 'rfis-index',
            component: RfisIndex,
            meta: {
                filtersName: 'project-rfis',
                navigationKey: 'rfi',
                title: 'RFIs',
            },
        },
        {
            path: 'rfis/:rfiId',
            name: 'rfis-show',
            component: RfisShow,
            meta: {
                showNotificationsForRoutes: ['rfis-show'],
                filtersName: 'project-rfis-show',
                navigationKey: 'rfi',
                title: 'RFIs',
            },
        },
    ],
}
