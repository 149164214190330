import { createStore } from 'vuex'
import appStore from './app'
import loadingBar from './component/loadingBar'
import listingFilter from './component/listingFilter'
import modal from './component/modal'
import company from './component/company'
import documents from './component/documents'
import todo from './component/todo'
import common from './component/common'
import financialApprovals from './component/financial-approvals'
import project from './component/project'
import projectBudget from './component/projectBudget'
import dataImporter from './component/data-importer'
import projectDirectory from './component/project-directory'
import notification from './component/notification'
import notices from './component/notices'
import popupAlert from './component/popup-alert'
import chat from './component/chat'
import chatWindow from './component/chat-window'
import cached from './component/cached'
import communication from './component/communication'
import contract from './component/contract'
import letterOfIntent from './component/letter-of-intent'
import payapps from './component/payapps'
import topbar from './component/top-bar'
import changeOrder from './component/change-order'
import main from './component/main'
import settings from './component/settings'
import filtersModal from './component/filtersModal'
import filesComponent from './component/filesComponent'
import fundingSources from './component/fundingSources'
import projectSettings from './component/project-settings'
import meetingMinutesTemplates from './component/meeting-minutes-templates'
import notificationToastr from './component/notification-toastr'
import inviteModal from './component/invite-modal'
import listing from './component/listing'
import lazyList from './component/lazy-list'
import drawingsV2 from './component/drawings-v2'
import drawingMarkup from './component/drawing-markup'
import specifications from './component/specifications'
import storage from './component/storage'
import intercom from './component/intercom'
import projectSettingsJobsiteLocations from './component/project-settings-jobsite-locations'
import submittalV2 from './component/submittal-v2'
import budgetVersioning from './component/budget-versioning'
import projectSettingsSubmittals from './component/project-settings-submittals'
import forecasting from './component/forecasting'
import lienWaiverSettings from './component/lien-waiver-settings'
import insuranceRequirements from './component/insurances'
import contractDocument from './component/contract-document'
import scheduleV2 from './component/schedule-v2'
import financesCommon from './component/finances-common'
import projectNavigation from './component/project-navigation'


export default createStore({
    strict: false,
    modules: {
        insuranceRequirements,
        listingFilter,
        listing,
        loadingBar,
        cached,
        appStore,
        topbar,
        financialApprovals,
        modal,
        documents,
        company,
        todo,
        settings,
        changeOrder,
        common,
        contract,
        letterOfIntent,
        notification,
        notices,
        popupAlert,
        chat,
        chatWindow,
        project,
        projectBudget,
        dataImporter,
        projectSettings,
        projectDirectory,
        communication,
        payapps,
        meetingMinutesTemplates,
        main,
        filtersModal,
        notificationToastr,
        filesComponent,
        fundingSources,
        lazyList,
        inviteModal,
        drawingsV2,
        drawingMarkup,
        specifications,
        storage,
        intercom,
        projectSettingsJobsiteLocations,
        submittalV2,
        budgetVersioning,
        projectSettingsSubmittals,
        forecasting,
        lienWaiverSettings,
        contractDocument,
        scheduleV2,
        financesCommon,
        projectNavigation,
    }
})
