import { get } from 'lodash'
import RightModal from '@/components/RightModal/RightModal.vue'
import modalMixin from '@/mixins/modalMixin'
import fieldDateTime from '@/components/fields/fieldDateTime'
import dateTimeHelper from '@/helpers/dateTime'
import moment from 'moment'

export default {
    name: 'JournalModal',
    components: {
        RightModal,
        fieldDateTime
    },
    mixins: [modalMixin],
    data () {
        return {
            items: [],
            search: '',
            rendered: false,
            escPressed: false,
            selectedDate: null,
            showShortcuts: false,
            activeId: null
        }
    },
    computed: {
        activeItem () {
            return this.filteredItems.find(item => {
                return item.id === this.activeId
            })
        },
        dateTimeOptions () {
            return {
                'auto-close': true,
                'format': 'YYYY-MM-DD HH:mm:ss',
                'formatted': dateTimeHelper.getDateFormat(),
                'only-date': true,
                'only-time': false,
                'placeholder': this.$t('Select Date')
            }
        },
        filteredItems () {
            return this.items.filter(item => {
                const searchByTitle = item.title.toLowerCase().includes(this.search.toLowerCase())
                const searchByDate = this.selectedDate ? moment(item.date).isSame(this.selectedDate, 'day') : true
                return searchByTitle && searchByDate
            })
        }
    },
    methods: {
        init () {
            this.rendered = false
            const id = this.$route.params.pid
            this.$api.get(`projects-notes/${ id }`).then(({ data }) => {
                this.items = get(data, 'data', [])
                this.rendered = true
            }).catch(error => {
                this.errorHandle(error)
                this.rendered = true
            })
        },
        redirectToItem () {
            const id = this.activeItem.id
            this.$router.push({ name: 'project-journal', params: { id } })
        },
        redirectToNew () {
            this.$router.push({ name: 'project-journal', params: { id: 0 } })
        },
        setEscPressed (val) {
            this.escPressed = val
        },
        toggleShortcuts () {
            this.showShortcuts = !this.showShortcuts
        },
        hideShortcuts () {
            this.showShortcuts = false
        },
        getHealthIcon (health) {
            switch (health) {
            case 1: return 'fa-smile'
            case 3: return 'fa-meh'
            case 5: return 'fa-frown'
            }
        },
        setActiveId (id) {
            this.activeId = id
        },
        isInViewport (item) {
            let bounding = item.getBoundingClientRect()
            return (bounding.top >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight))
        },
        handleKeyDown (e) {
            if (e.key === 'ArrowDown') {
                e.preventDefault()
                this.hideShortcuts()
                const index = this.filteredItems.indexOf(this.activeItem)
                if (index < this.filteredItems.length - 1) {
                    this.activeId = this.filteredItems[index + 1].id
                }
            }
            if (e.key === 'ArrowUp') {
                e.preventDefault()
                this.hideShortcuts()
                const index = this.filteredItems.indexOf(this.activeItem)
                if (index > 0) {
                    this.activeId = this.filteredItems[index - 1].id
                }
            }
            if (e.shiftKey && e.key === 'ArrowLeft') {
                e.preventDefault()
                this.hideShortcuts()
                if (this.selectedDate) {
                    this.selectedDate = moment(this.selectedDate).subtract(1, 'days')
                }
            }
            if (e.shiftKey && e.key === 'ArrowRight') {
                e.preventDefault()
                this.hideShortcuts()
                if (this.selectedDate) {
                    this.selectedDate = moment(this.selectedDate).add(1, 'days')
                }
            }
        }
    },
    created () {
        this.init()
    },
    updated () {
        if (!this.activeItem && this.filteredItems.length > 0) {
            this.activeId = this.filteredItems[0].id
        }
        const selectedItem = document.querySelector('.io-single-item.io-active')
        if (selectedItem && !this.isInViewport(selectedItem)) {
            selectedItem.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    },
    beforeMount () {
        document.addEventListener('keydown', this.handleKeyDown)
    },
    beforeUnmount () {
        document.removeEventListener('keydown', this.handleKeyDown)
    }
}
