<template>
    <div class="io-list-header" :class="{ 'io-list-header--padding': SoVTableViewMode.AllColumns === columnsViewMode && showLeftNavigation }">
        <div class="io-list-header__left" :class="{ 'io-list-header__left--link': link }" @click="goToLink">
            {{ title }}
            <span v-if="link" class="icon-external-link"></span>
        </div>
        <div class="io-list-header__right">
            <div class="io-label">{{ valueLabel }}</div>
            <div class="io-value">{{ $filters[valueFilter](value) }}</div>
        </div>
    </div>
</template>

<script lang="ts" src="./ListHeader.ts"></script>
<style lang="scss" src="./ListHeader.scss" scoped></style>