import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import { TranslateResult } from 'vue-i18n'
import projectWizardClient from '@/api/project-wizard/projectWizardClient'
import { mapGetters, mapMutations, mapState } from 'vuex'
import validationMixin from '@/mixins/validationMixin'
import { eventBus } from '@/mixins/eventBus'
import AgreementType from './popup/agreement-type/main'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
import ContractType from '@/base/ContractType'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import PopupCompany
    from '@/io-modules/bid-requests/components/wizard-form-steps/bidders/components/popup-company/PopupCompany.vue'
import { head, indexOf, isEmpty, isUndefined, trim } from 'lodash'
import { requiredIf, maxLength, required } from '@vuelidate/validators'
import ViewModal from '@/components/new-theme/modal.vue'
import ViewEditForm from '@/views/default/edit.vue'
import StepSelectContractType from './parts/StepSelectContractType/StepSelectContractType.vue'
import StepSelectContract from './parts/StepSelectContract/StepSelectContract.vue'
import StepUploadContract from './parts/StepUploadContract/StepUploadContract.vue'
import StepProjectDetails from './parts/StepProjectDetails/StepProjectDetails.vue'
import StepFeesAndPhases from './parts/StepFeesAndPhases/StepFeesAndPhases.vue'
import cachedListMixin from '@/mixins/cachedListMixin'


export default defineComponent({
    name: 'ProjectWizardNew',
    components: {
        ViewModal,
        PopupModal,
        PopupCompany,
        ViewEditForm,
        AgreementType,
        StepSelectContractType,
        StepSelectContract,
        StepUploadContract,
        StepProjectDetails,
        StepFeesAndPhases,
    },
    props: {
        proposal: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    mixins: [validationMixin, featureFlagsMixin, cachedListMixin],
    data () {
        return {
            loaded: false,
            invitationClientKey: InviteModalClientKeys.PROJECT_WIZARD_CLIENT,
            invitationProjectManagerKey: InviteModalClientKeys.PROJECT_WIZARD_MANAGER,
            invitationProjectExecutiveKey: InviteModalClientKeys.PROJECT_WIZARD_EXECUTIVE,
            // Global loading
            loading: true,
            uploading: false,
            allowedClientsTypes: null,

            step: 1,
            stepCount: 0,
            // Selections
            selectionContract: {},
            invitationData: null,
            // Lists
            contracts: [],
            clientsInternal: [],
            contactsInternal: [],
            // Main containers
            manager: {
                id: null,
                type: 'manager',
                name: null,
                email: null,
            },
            projectExecutive: {
                id: null,
                type: 'executive',
                name: null,
                email: null,
            },
            projectExecutiveInvitedefault: () => ({}),
            client: {
                id: null,
                name: null,
                type: 'connection',
                mainType: 'connection',
                contact: null,
                company_name: null,
                email: null,
                mongo: null,
                appType: null,
                employee_mongo: null,
            },
            contract: {
                id: null,
                type: 'skip',
                files: [],
                proposal: null,
                itemId: null,
                itemType: null,
                executeConfirm: false,
                executeError: false,
            },
            project: {
                name: '',
                project_address1: '',
                project_address2: '',
                project_city: '',
                project_state_id: {
                    id: null,
                    name: null,
                },
                project_zip: '',
                type: '1',
                value: null,
                gmp: null,
                currency: '',
                phases: [],
                agreement_type: '',
                external_id: null,
                main_contract_type: null,
                project_country: null,
            },
            contractValueValid: true,
            proposedId: {
                formatted: null,
                value: null,
            },
            isProposal: false,
            sending: false,
            isIdExist: false,
            validationOptions: {
                allowedExtensions: ['pdf', 'doc', 'docx', 'txt'],
                acceptFiles: ['.pdf', '.doc', '.docx', '.txt'],
            },
            stateDisabled: false,
            showAgreementTypePopup: false,
            showMainContractTypePopup: false,
            PreviewTypes: PreviewTypes,
            selectedContact: null,
            isPMApprover: false,
            showPopupCompany: false,
            companyForPopup: null,
            // @todo figure out what is this for else revoeve
            selectionClientInternal: {
                id: null,
                label: null,
            },
            // @todo figure out what is this for else revoeve
            selectionContactInternal: null,
            files: [],
        }
    },
    computed: {
        modalTitleText (): string | TranslateResult {
            switch (this.step) {
            case 1:
                return this.$t('Select Contract Type')
            case 11:
                return this.$t('Select Contract')
            case 12:
                return this.$t('Upload Contract')
            case 2:
                return this.$t('Project Details')
            case 3:
                return this.$t('Fees and Phases')
            default:
                return this.$t('Create Project')
            }
        },
        currentStep (): number {
            if ([1, 11, 12].includes(this.step)) {
                return 1
            }

            if (this.step === 2) {
                return this.skipContract ? 1 : 2
            }

            if (this.step === 3) {
                return this.skipContract ? 2 : 3
            }
        },
        hasProposal (): boolean {
            return !isEmpty(this.proposal)
        },
        ...mapGetters('appStore', ['getAuthData']),
        ...mapGetters('common', {
            currencies: 'getCurrenciesList',
        }),
        ...mapState('common', {
            states: state => state.states,
            countries: state => state.countries,
        }),
        ...mapState('modal', {
            showModal: (state) => state.show,
            // @todo figure out what is this for; else remove
            returnData: (state) => state.returnData,
        }),
        ...mapState('cached', {
            listContacts: (state) => state.lists.contacts,
        }),

        inviteModalOptionsProjectExecutive (): Object {
            return {
                showEmployees: true,
                showContacts: false,
                oneOnly: true,
                restrictEmployeeTypes: ['ROLE_PRINCIPAL', 'ROLE_ADMIN', 'ROLE_PROJECT_EXECUTIVE'],
            }
        },
        hasAlternatePhases (): boolean {
            for (let x in this.project.phases) {
                if (this.project.phases[x].alternateTo) {
                    return true
                }
            }
            return false
        },
        /**
         * Is project value higher than maximum price on hourly contract?
         *
         * @return {boolean}
         */
        isProjectValueInvalid (): boolean {
            if (this.project.type !== '2') {
                return false
            }

            return this.project.value > this.project.gmp
        },
        getForcedDataContact (): Object {
            return {
                contact_company: {
                    id: this.selectionClientInternal.id,
                    name: this.selectionClientInternal.label,
                },
            }
        },
        isOwner (): boolean {
            return this.getAuthData.app_is_owner
        },
        skipContract (): boolean {
            return this.isOwner || this.getAuthData.app_type === this.appTypes.TYPE_GC || (this.proposal && this.proposal && this.proposal.proposal_contract_id)
        },
        canAddPhases (): boolean {
            // @ts-ignore
            return !this.isProposal && this.getAuthData.app_type !== this.appTypes.TYPE_GC
        },
        contractTypes (): Array<Object> {
            const appType = this.getAuthData.app_type

            return [
                {
                    id: '1',
                    type: this.$t('project_wizard_contract_type_1'),
                },
                {
                    id: '2',
                    // @ts-ignore
                    type: appType === this.appTypes.TYPE_GC || appType === this.appTypes.TYPE_SC ? this.$t('project_wizard_contract_type_3') : this.$t('project_wizard_contract_type_2'),
                },
            ]
        },
        clientTooltipContent (): string {
            return `<strong>${ this.$t('project_wizard_content_tooltip_1') }</strong>${ this.$t('project_wizard_content_tooltip_2') }</br></br><b>${ this.$t('project_wizard_content_tooltip_3') }</b>${ this.$t('project_wizard_content_tooltip_4') }`
        },
        showManualOption (): boolean {
            // @ts-ignore
            return this.getAuthData.app_type === this.appTypes.TYPE_DEV ? this.isOwner : true
        },
        canEditAgreementType (): boolean {
            return this.appTypes.TYPE_DEV === this.getAuthData.app_type
        },
        isGmpEnabled (): boolean {
            return this.isFeatureEnabled(FeatureFlagsConsts.GUARANTEED_MAXIMUM_PRICE_CONTRACT, false)
        },
        mainContractTypeLabel (): TranslateResult {
            if (ContractType.GMP === this.project.main_contract_type) {
                return this.$tc('Initial Contract & Original Contract (GMP)')
            }

            return this.$tc('Lump Sum Contract')
        },
        isDevAppType (): boolean {
            // @ts-ignore
            return this.getAuthData.app_type === this.appTypes.TYPE_DEV
        },
    },
    validations: {
        project: {
            name: {
                required,
            },
            agreement_type: {
                required: requiredIf(function (): boolean {
                    // @ts-ignore
                    return this.canEditAgreementType
                }),
            },
            external_id: {
                maxlength: maxLength(140),
            },
            project_address2: {
                customMaxLength: function (value): boolean {
                    if (this.v$.project.project_address2.$isEmpty || !value) {
                        return true
                    }
                    return value.length <= 60
                },
            },
        },
        invitationData: {
            email: {
                required: requiredIf(function (): boolean {
                    // @ts-ignore
                    return !this.isOwner
                }),
            },
        },
    },
    watch: {
        'project.type': function (): void {
            this.validateContractValue()
        },
        'project.gmp': function (): void {
            this.validateContractValue()
        },
        'project.value': function (): void {
            this.validateContractValue()
        },
        'proposedId.formatted': function (): void {
            this.isIdExist = false
        },
        showModal: function (val: string): void {
            if (!val) {
                // @ts-ignore
                $('.io-project-wizard-container').removeClass('hidden')
            } else if (val === 'contact' || val === 'client') {
                // @ts-ignore
                $('.io-project-wizard-container').addClass('hidden')
            }
        },
        step: function (val: number): void {
            if (val === 1) {
                this.resetContract()
                if (!this.proposal) {
                    this.isProposal = false
                }
            }

            if (val === 2) {
                this.loadProposalFromContract()
            }

            // Add default phase
            if (val === 3 && !this.project.phases.length) {
                this.addPhase()
            }
        },
        selectionContract (value?: { id: string, itemId: string, itemType: string }): void {
            if (value && typeof value === 'object' && value.id) {
                this.resetContract()
                this.contract.id = value.id
                this.contract.type = 'existing'
                this.contract.itemId = value.itemId
                this.contract.itemType = value.itemType
            }
        },
        /**
         * Watch modals
         * @todo figure out what is this for; else remove
         */
        returnData: async function (returnData: {
            response: {
                id: string,
                model: { id: string },
                module: string
            }
        }): Promise<boolean> {
            if (isUndefined(returnData.response)) {
                return false
            }
            let response = returnData.response
            if (response.module && response.module === 'company') {
                let newId = parseInt(response.model.id)
                const { data } = await projectWizardClient.loadClientsInternal()
                this.clientsInternal = data.feed
                const newElement = data.feed.find((client) => {
                    return parseInt(client.id) === newId
                })
                if (newElement) {
                    this.selectionClientInternal = newElement
                    this.selectionContactInternal = {}
                    this.invitationData.internal.company_id = newElement.id
                }
            }
            if (response.module && response.module === 'contact') {
                let newId = parseInt(response.id)
                await this.$store.dispatch('cached/refreshList', 'contacts')
                await this.refreshContactsList()
                const newElement = this.contactsParsed.find((element) => {
                    return parseInt(element.id) === newId
                })
                if (newElement) {
                    this.selectionContactInternal = newElement
                    this.invitationData.internal.contact_id = newElement.id
                }
            }
        },
        'project.project_country': {
            handler (newVal: { id: string }, oldVal: { id: string }): void {
                if (newVal && newVal.id && newVal.id === 'US') {
                    this.stateDisabled = false
                } else {
                    this.project.project_state_id = {
                        id: null,
                        name: null,
                    }
                    this.stateDisabled = true
                }
            },
            immediate: true,
        },
    },
    beforeMount () {
        this.beforeMountStuff()
        this.step = this.showManualOption ? 1 : 2
    },
    beforeUnmount () {
        eventBus.$off('appStore/authDataReady')
        this.clearInvitationState(this.invitationClientKey)
        this.clearInvitationState(this.invitationProjectManagerKey)
    },
    methods: {
        setPMAsApprover (): boolean {
            return this.isPMApprover = !this.isPMApprover
        },
        onClickCloseModal (): void {
            this.$emit('close')
        },
        ...mapMutations('inviteModal', {
            setStateObj: 'SET_STATE_OBJECT',
            clearInvitationState: 'CLEAR_STATE_OBJECT',
            removeAllSelected: 'REMOVE_ALL_SELECTED',
            setSearch: 'SET_SEARCH',
            clearSearchResults: 'CLEAR_SEARCH_RESULTS',
        }),
        selectCurrency (value: string): void {
            this.project.currency = value
        },
        beforeMountStuff (): void {
            this.initContract()
            const keys = Object.keys(this.proposal)
            if (keys.length > 0) {
                this.setClientType('offsystem')
                this.client.id = this.proposal.proposal_workspace_company_id.resource_id
                this.client.name = this.proposal.proposal_workspace_company_id.name
                this.client.company_name = this.proposal.quote_sender_mongo_name

                this.invitationData = this.proposal.invitation_data

                this.project.name = this.proposal.proposal_project_name
                this.project.project_address1 = this.proposal.proposal_address1
                this.project.project_address2 = this.proposal.proposal_address2
                this.project.project_city = this.proposal.proposal_city
                this.project.project_state_id = this.proposal.proposal_state_id
                this.project.project_country = this.proposal.proposal_country
                this.project.project_zip = this.proposal.proposal_zip
                this.project.value = this.proposal.cost.main

                this.setProjectType(this.proposal.proposal_type)
                this.project.gmp = this.proposal.proposal_gmp

                this.contract.itemId = this.proposal.proposal_id
                this.contract.itemType = 'proposal'

                // data prom proposal
                this.isProposal = true
                this.proposedId.formatted = this.proposal.proposal_formatted_id
                this.proposedId.value = this.proposal.proposal_formatted_id

                this.selectionClientInternal = {
                    id: this.proposal.proposal_workspace_company_id.resource_id,
                    label: this.proposal.proposal_workspace_company_id.name,
                }

                setTimeout(() => {
                    if (this.proposal.proposal_contact_recipient && this.proposal.proposal_contact_recipient.id) {
                        const element = this.contactsParsed?.find((contact) => {
                            return contact.id === this.proposal.proposal_contact_recipient.id
                        })
                        if (element) {
                            this.selectionContactInternal = element
                        }
                    }
                }, 300)


                this.attachPhasesFromProposal()
                this.recalculateContractValue()
                this.setStepCount(2)
            }
        },

        togglePopupCompany (): void {
            this.showPopupCompany = !this.showPopupCompany
        },

        updateLocalEmail (data: Object): void {
            this.invitationData.email = data.email
            this.togglePopupCompany()
            this.saveFromContract(true)
        },
        /**
         * Load data from proposal linked to contract
         *
         * @return {Promise<void>}
         */
        async loadProposalFromContract (): Promise<void> {
            if (this.contract.itemType !== 'proposal' || !this.contract.itemId) {
                return
            }

            this.loading = true
            const { data } = await projectWizardClient.getProposal(this.contract.itemId)
            this.loading = false

            this.client.type = 'connection'
            this.client.mainType = 'connection'
            this.client.id = data.proposal.workspace_data.id
            this.client.name = data.proposal.workspace_data.name

            this.project.name = data.proposal.proposal_project_name
            this.project.project_address1 = data.proposal.proposal_address1
            this.project.project_address2 = data.proposal.proposal_address2
            this.project.project_city = data.proposal.proposal_city
            this.project.project_state_id = data.proposal.proposal_state_id
            this.project.project_zip = data.proposal.proposal_zip
            this.project.value = this.proposal.cost.main
            this.contract.itemType = 'proposal'
            this.contract.itemId = data.proposal.id

            this.proposedId.formatted = data.proposal.formatted_id
            this.proposedId.value = data.proposal.formatted_id

            this.isProposal = true
        },
        /**
         * Check contract value and validate if required
         */
        validateContractValue (): void {
            this.contractValueValid = true
            if (this.project.type === '2') {
                if (this.project.gmp < this.project.value) {
                    this.contractValueValid = false
                }
            }
        },
        /**
         * Reset client object
         */
        resetClient (): void {
            this.client.id = null
            this.client.type = null
            this.client.name = null
            this.client.contact = null
            this.client.email = null
            this.client.mongo = null
            this.client.appType = null
            this.client.mainType = null
        },
        /**
         * Reset manager object
         */
        resetManager (): void {
            this.manager.id = null
            this.manager.type = null
            this.manager.name = null
            this.manager.email = null
        },
        /**
         * Reset project executive object
         */
        resetProjectExecutive (): void {
            this.projectExecutive.id = null
            this.projectExecutive.type = null
            this.projectExecutive.name = null
            this.projectExecutive.email = null
        },
        /**
         * Reset contract object
         */
        resetContract (): void {
            this.contract.id = null
            this.contract.files = []
            this.contract.type = 'skip'
            this.contract.itemId = null
            this.contract.itemType = null
            this.contract.executeConfirm = false
            this.contract.executeError = false

            if (this.proposal) {
                this.contract.itemId = this.proposal.proposal_id
                this.contract.itemType = 'proposal'
            } else {
                this.isProposal = false
            }
        },
        /**
         * After upload files - backup files, reset and set new type
         */
        onUploadComplete (): void {
            this.uploading = false
            let files = this.contract.files
            this.resetContract()
            this.contract.type = 'upload'
            this.contract.files = files
        },
        attachPhasesFromProposal (): void {
            this.project.phases = []

            for (let x in this.proposal.sections) {
                let phase = this.proposal.sections[x]
                phase.enabled = true

                if (this.proposal.sections[x].alternateTo) {
                    phase.enabled = false
                }
                this.project.phases.push(phase)
            }
        },
        recalculateContractValue (): void {
            let mainToDisable = []
            this.project.phases.forEach((phase) => {
                if (phase.enabled && phase.alternateTo) {
                    mainToDisable.push(phase.alternateTo)
                }
            })

            this.project.phases.forEach((phase) => {
                if (indexOf(mainToDisable, phase.uuid) > -1) {
                    phase.enabled = false
                } else if (!phase.alternateTo) {
                    phase.enabled = true
                }
            })

            this.project.value = 0
            this.project.phases.forEach((phase) => {
                if (phase.enabled) {
                    this.project.value += phase.price
                }
            })
        },
        /**
         * Add new empty phase into contract
         */
        addPhase (): void {
            this.project.phases.push({
                id: crypto.randomUUID(),
                name: '',
                price: null,
                enabled: true,
            })
        },
        /**
         * Remove one phase
         *
         * @param id
         */
        removePhase (id: string | number): void {
            const phaseIndex = this.project.phases.findIndex((o) => {
                return o.id === id
            })
            // We allow removing only second on next phases, so it's ok
            if (phaseIndex) {
                this.project.phases.splice(phaseIndex, 1)
                this.recalculateContractValue()
            }
        },
        invitationsUpdateManager (update: any): void {
            this.resetManager()

            let data: any = update.invited_pending.length ? head(update.invited_pending) : head(update.attachedParsed)
            if (update.invited_pending.length) {
                this.manager = {
                    id: null,
                    type: 'invite',
                    email: data.email,
                    name: data.email,
                }
            } else {
                this.manager = {
                    id: data.id,
                    type: 'manager',
                    name: data.full_name,
                    email: data.e_email,
                }
            }
        },
        onUpdateProjectExecutive (contactsList: Array<any>): void {
            this.resetProjectExecutive()

            if (!contactsList.length) {
                return
            }

            const executive = contactsList[0]

            this.projectExecutive = {
                id: executive.id,
                type: 'executive',
                name: `${ executive.firstname } ${ executive.lastname }`,
                email: executive.email,
            }
        },
        /**
         * Get required data and set for shared usage
         *
         * @return {Promise<void>}
         */
        async initContract (): Promise<void> {
            try {
                this.setLoadingBar(true)
                this.loaded = false
                this.$store.dispatch('contract/resetInvitationData')
                const { data } = await projectWizardClient.getData({ type: 'client' })
                this.loading = false
                this.clientsInternal = data.clientsInternal
                this.allowedClientsTypes = data.allowedClientsTypes
                this.contracts = data.contracts
                this.project.currency = data.defaultCurrency.id

                if (!this.isProposal) {
                    this.proposedId = data.proposedId
                    this.proposedId.value = data.proposedId.value
                }

                if (this.skipContract) {
                    this.step = 2
                }
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.loaded = true
                this.setLoadingBar(false)
            }
        },
        /**
         * Check phases names
         */
        validatePhases (): boolean {
            if (!this.canAddPhases) {
                return true
            }

            for (let i = 0; i < this.project.phases.length; i++) {
                if (trim(this.project.phases[i].name) === '') {
                    this.$store.dispatch('notification/setShow', {
                        type: 'error',
                        text: this.$t('Please set all phases names'),
                    })
                    return false
                }
            }
            return true
        },
        onStartWithProposalConfirm (sendObject) {
            this.showPopupAlert({
                title: this.$t('Starting New Project'),
                caption: this.$t('Proposal will be closed and read-only. Are you sure?'),
                icon: 'far fa-exclamation-triangle',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Start Project'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.submitWithProposal(sendObject)
                        },
                    },
                ],
            })
        },
        async submitWithProposal (sendObject) {
            this.contract.itemType = 'proposal'
            this.contract.proposal = this.proposal.proposal_id

            this.sending = true
            await this.send(sendObject)
        },
        /**
         * Save all contract data
         */
        async saveFromContract (skipPhases: boolean = false): Promise<void | boolean> {
            if (!skipPhases && !this.validatePhases()) {
                return false
            }

            this.proposedId.formatted = this.proposedId.formatted?.trim()

            if (this.invitationData) {
                this.invitationData.type = this.client.type
                this.invitationData.contact = this.client.contact
                this.invitationData.mongo = this.client.mongo
            }
            let sendObject = {
                proposedId: this.proposedId,
                manager: this.manager,
                client: this.client,
                contract: this.contract,
                project: this.project,
                files: this.files,
                invitationData: this.invitationData,
                project_executive: null,
                set_PM_as_approver: this.isPMApprover,
            }

            if (this.getAuthData.app_type === this.appTypes.TYPE_DEV) {
                sendObject.project_executive = this.projectExecutive
            }

            if (!this.sending) {
                // Confirmation
                if (this.isProposal) {
                    this.onStartWithProposalConfirm(sendObject)
                    return
                } else {
                    try {
                        this.sending = true
                        const { data } = await projectWizardClient.postCheckId({ id: this.proposedId.formatted })
                        if (!data.result) {
                            this.sending = false
                            this.isIdExist = true
                            this.step = 2
                            this.$scrollTo('.io-project-wizard-contentbox', 500, {
                                el: '.io-v-error',
                                container: '#io-projectWizard',
                            })
                            return false
                        }
                    } catch (e) {
                        this.consoleError(e)
                        this.showNotification('error', this.$t('Error occurred during checking new ID'))
                        return false
                    }
                }

                this.sending = true
                await this.send(sendObject)
            }
        },
        /**
         * Send contract wizard to create new project
         *
         * @param sendObject
         */
        async send (sendObject: any): Promise<void> {
            try {
                if (sendObject.client.isNewCompany) {
                    const newCompanyData = {
                        company_name: sendObject.client.newCompany.company_name,
                        addresses: [{
                            address_line1: sendObject.client.newCompany.address.address_line1,
                            address_line2: sendObject.client.newCompany.address.address_line2,
                            city: sendObject.client.newCompany.address.city,
                            country: sendObject.client.newCompany.address.country.id,
                            is_main: sendObject.client.newCompany.address.is_main,
                            label: sendObject.client.newCompany.address.label,
                            state: sendObject.client.newCompany.address.state.id,
                            zip: sendObject.client.newCompany.address.zip,
                        }],
                    }

                    delete sendObject.client.newCompany
                    delete sendObject.client.isNewCompany
                    delete sendObject.invitationData.newCompany
                    delete sendObject.invitationData.isNewCompany

                    const { data } = await projectWizardClient.postCompany(newCompanyData)

                    sendObject.invitationData.company_name = sendObject.client.company_name = data.res_name
                    sendObject.invitationData.company = sendObject.client.company = {
                        id: data.shared_workspaces_data_id,
                        logo: '',
                        name: data.res_name,
                    }
                }

                if (this.selectedContact) {
                    sendObject.invitationData.app_type = this.selectedContact.app_type
                }

                delete sendObject.client

                const { data } = await projectWizardClient.postContract(sendObject)
                await this.getOne('projects', data.id)
                this.$store.dispatch('cached/refreshListForce', 'employees')
                this.$store.dispatch('cached/refreshListForce', 'contacts')

                this.showNotification('success', this.$t('Project has been created'))
                this.sending = false
                const routeName = this.getAuthData.app_type === this.appTypes.TYPE_DEV ? 'project-dashboard' : 'project-summary'

                this.clearInvitationState(this.invitationClientKey)
                this.clearInvitationState(this.invitationProjectManagerKey)
                this.clearInvitationState(this.invitationProjectExecutiveKey)
                this.closeProjectWizard()

                eventBus.$emit('global/projects/reload')
                await this.$router.push({ name: routeName, params: { pid: data.id } })
            } catch (error) {
                this.sending = false
                error?.response?.status === 422
                    ? this.errorHandle(error)
                    : this.showNotification('error', this.$t('Error occurred. Project has not been created'))
            }
        },
        /**
         * Change wizard step
         * @param count
         */
        setStepCount (count: number): void {
            this.stepCount = count
        },
        validateManual (step: number): void {
            if (!this.contract.executeConfirm) {
                this.contract.executeError = true
            } else {
                this.setStep(step)
            }
        },
        setStep (step: number): void {
            this.step = step
        },
        stepSkipContract (): void {
            this.resetContract()
            this.contract.type = 'skip'
            this.setStep(2)
            this.setStepCount(2)
        },
        goToUploadContract (): void {
            this.step = 12
            if (!this.isProposal) {
                this.setStepCount(3)
            }
        },
        onStartNewProject (): void {
            const validationResult = this.goToLast(true)
            if (validationResult) {
                this.saveFromContract(true)
            }
        },
        goToLast (validateOnly: boolean = false): boolean {
            this.v$.project.$touch()
            this.v$.invitationData.$touch()
            if (!this.v$.project.$error && !this.v$.invitationData.$error && !this.isIdExist) {
                if (!validateOnly) {
                    this.step = 3
                } else {
                    return true
                }
            } else if (this.v$.project.$error || this.isIdExist) {
                let errorText = ''
                if (this.v$.project.name.$error) {
                    errorText = this.$t('Please complete all required fields')
                } else if (this.v$.project.external_id.$error) {
                    errorText = this.$t('Custom ID can\'t exceed 140 characters')
                } else if (this.canEditAgreementType && '' === this.project.agreement_type) {
                    errorText = this.$t('Please select the Agreement Type')
                } else if (this.v$.project.project_address2.$error) {
                    errorText = this.$t('Address can\'t exceed 60 characters')
                }

                this.$store.dispatch('notification/setShow', {
                    type: 'error',
                    text: errorText,
                })

                if (validateOnly) {
                    return false
                }
            } else {
                this.$store.dispatch('notification/setShow', {
                    type: 'error',
                    text: this.$t('Please select client'),
                })

                if (this.proposal?.proposal_workspace_company_id?.resource_id) {
                    this.companyForPopup = {
                        name: this.proposal.proposal_workspace_company_id.name,
                        resource_id: this.proposal.proposal_workspace_company_id.resource_id,
                        isLocal: true,
                        isGlobal: false,
                    }

                    this.togglePopupCompany()
                }
            }
        },
        closeProjectWizard (): void {
            this.removeSelectedClient()
            this.resetManager()
            this.resetProjectExecutive()

            let keys = [this.invitationClientKey, this.invitationProjectManagerKey, this.invitationProjectExecutiveKey]

            keys.forEach(stateKey => {
                this.setStateObj(stateKey)
                this.removeAllSelected(stateKey)
                this.setSearch({ key: stateKey, search: '' })
                this.clearSearchResults({ key: stateKey })
            })

            this.$emit('close')
        },
        setClientType (type: string): void {
            this.selectionClientInternal = {
                id: null,
                label: null,
            }
            this.selectionContactInternal = {}

            this.resetClient()
            if (type === 'invite') {
                this.client.mainType = 'connection'
            } else {
                this.client.mainType = type
            }
            this.client.type = type

            this.v$.invitationData.$reset()

            this.$store.dispatch('contract/resetInvitationData')

            if (!this.invitationData) {
                this.invitationData = {}
            }

            this.invitationData.type = (this.client.mainType === 'connection') ? 'onsystem' : 'offsystem'
        },
        setProjectType (type: string): void {
            this.project.type = type
        },
        hideInviteModal (): void {
            this.step = 2
        },
        setDefaultProjectExecutive (): void {
            this.projectExecutive.id = this.getAuthData.u_mongo
            this.projectExecutive.name = `${ this.getAuthData.u_firstname } ${ this.getAuthData.u_lastname }`
            this.projectExecutive.email = this.getAuthData.u_email
            this.projectExecutiveInviteDefault = [
                {
                    type: 3,
                    _id: parseInt(this.getAuthData.u_id),
                },
            ]
        },
        /**
         * Refresh list
         */
        refreshContactsList (): boolean | void {
            this.selectionContactInternal = null
            if (!this.selectionClientInternal || !this.selectionClientInternal.id) {
                this.contactsInternal = []
            } else {
                this.contactsInternal = this.listContacts.filter((contact) => {
                    return contact._company_id === this.selectionClientInternal.id
                })
            }
        },
        setAgreementType (agreementType: string): void {
            this.project.agreement_type = agreementType
        },
        setMainContractType (type: string): void {
            this.project.main_contract_type = type

            if (type) {
                this.setMainContractTypePopup(false)
            }
        },
        openAgreementTypePopup (): void {
            this.showAgreementTypePopup = true
        },
        setMainContractTypePopup (state: boolean): void {
            this.showMainContractTypePopup = state
        },
        closeAgreementTypePopup (): void {
            this.showAgreementTypePopup = false
        },
        onChangeUpdateManager (contactsList: Array<{
            employee_id: string,
            firstname?: string,
            lastname?: string,
            email: string,
        }>): void {
            this.resetManager()

            if (!contactsList.length) {
                return
            }

            const manager = contactsList[0]

            this.manager = {
                id: manager.id,
                type: 'manager',
                name: `${ manager?.firstname } ${ manager?.lastname }`,
                email: manager.email,
            }
        },
        onSelectedClientChange (contacts: any): void {
            if (!contacts) {
                this.removeSelectedClient()

                return
            }
            this.selectedContact = null !== contacts.app_type ? null : contacts

            this.invitationData = {
                ...contacts,
                workspace: contacts.company_id,
            }
            this.client = {
                ...contacts,
                type: 'invite',
                appType: contacts.app_type,
                employee_mongo: contacts.id,
                mongo: contacts.company_id,
            }

            if (contacts._newAdded) {
                this.client.employee_mongo = null
            }
        },
        removeSelectedClient (): void {
            this.client = {
                id: null,
                name: null,
                type: 'connection',
                mainType: 'connection',
                contact: null,
            } as typeof this.client
            this.resetClient()
            this.invitationData = null
            this.selectedContact = null
        },
    },
})
