import { defineComponent, PropType } from 'vue'

import { MenuItem, MenuSubItem } from '../interfaces/MenuLeftInterface'

export default defineComponent({
    name: 'ItemLink',
    props: {
        isRouterLink: { type: Boolean, required: true },
        isSubItem: { type: Boolean, default: false },
        itemIndex: { type: Number, default: 0 },
        item: { type: Object as PropType<MenuItem>, default: () => ({}) },
        subItem: { type: Object as PropType<MenuSubItem>, default: () => ({}) },
        isSubItemActive: { type: Boolean, default: false },
        isItemActive: { type: Boolean, default: false },
    },
    emits: ['setMenuItemOpened'],
    computed: {
        itemData (): MenuItem | MenuSubItem {
            return this.isSubItem ? this.subItem : this.item
        },

        iconClass (): string {
            return this.isItemActive ? this.item.iconActive : this.item.icon
        },
    },
    methods: {
        onSetMenuItemOpenedClick (): void {
            this.$emit('setMenuItemOpened', this.itemIndex)
        },
    },
})
