import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import { getAuthUrl } from '@/helpers/urls'

export default defineComponent({
    name: 'Profile',
    mixins: [
        isLoggedMixin
    ],
    data () {
        return {
            showProfile: false,
            storageInfo: null
        }
    },
    computed: {
        ...mapGetters('storage', [
            'getRecalculateUsedStorage'
        ]),
        ...mapGetters('appStore', [
            'getAuthData'
        ]),
        authData () {
            return this.getAuthData
        },
        storage () {
            const usedStorage = this.storageInfo?.used ?? 0
            const totalStorage = this.storageInfo?.total ?? 0
            const usedStoragePercent = Math.round((usedStorage / totalStorage) * 100)
            const chartColor = usedStoragePercent > 70 ? 'var(--error-lighten)' : 'var(--accent)'

            return { usedStorage, totalStorage, usedStoragePercent, chartColor }
        }
    },
    watch: {
        getRecalculateUsedStorage () {
            this.getStorageInfo()
        }
    },
    methods: {
        toggleShowProfile () {
            this.showProfile = !this.showProfile
        },
        closeProfile () {
            this.showProfile = false
        },
        async logout () {
            localStorage.removeItem('contactsList')
            localStorage.removeItem('employeesList')
            localStorage.removeItem('projectsList')
            localStorage.removeItem('proposalsList')
            localStorage.removeItem('tasksgeneralList')
            localStorage.removeItem('appType')
            sessionStorage.removeItem('qvToken')
            sessionStorage.removeItem('qv_widget_url')

            const appName = (window.location.host).split('.')[0]

            window.location.href = `${ getAuthUrl() }/logout/${ appName }`
        },
        async getStorageInfo () {
            const { data } = await this.$api.get('cloud-space-info')

            this.storageInfo = data
        }
    },
    beforeMount () {
        this.getStorageInfo()
    }
})
