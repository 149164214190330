import i18n from '@/base/i18n'
import FilterType from '@/components/ModalFilters/interfaces/FilterType'
import FilterInterface from '@/components/ModalFilters/interfaces/FilterInterface'
import InvoiceHeader from '@/io-modules/invoices/enums/InvoiceHeader'

const invoicesFiltersSchema: FilterInterface[] = [
    {
        field: InvoiceHeader.Type,
        type: FilterType.Checkbox,
        label: i18n.global.tc('Invoice Type'),
    },
    {
        field: InvoiceHeader.InvoiceDate,
        type: FilterType.Date,
        label: i18n.global.tc('Invoice Date'),
    },
    {
        field: InvoiceHeader.Status,
        type: FilterType.Status,
        label: i18n.global.tc('Invoice Status'),
    },
    {
        field: InvoiceHeader.LienWaiverStatus,
        type: FilterType.ColorDot,
        label: i18n.global.tc('Lien Waiver Status'),
    },
    {
        field: InvoiceHeader.BallInCourt,
        type: FilterType.Multiselect,
        label: i18n.global.tc('Ball In Court'),
        tooltip: i18n.global.tc('There is currently no user who has been designated as Ball in Court.')
    },
    {
        field: InvoiceHeader.Vendor,
        type: FilterType.Multiselect,
        label: i18n.global.tc('Vendor'),
    },
    {
        field: InvoiceHeader.PaymentStatus,
        type: FilterType.ColorDot,
        label: i18n.global.tc('Payment Status'),
    },
    {
        field: InvoiceHeader.BillingDate,
        type: FilterType.Date,
        label: i18n.global.tc('Billing Date'),
    },
    {
        field: 'billing_period',
        type: FilterType.Date,
        label: i18n.global.t('Billing Period'),
    },
]

export default invoicesFiltersSchema
