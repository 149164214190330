import { defineComponent, PropType } from 'vue'
import { cloneDeep } from 'lodash'
import Multiselect from 'vue-multiselect'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import MultiselectSettingsInterface from '@/interfaces/components/io-dropdown/MultiselectSettings.ts'

export default defineComponent({
    name: 'IODropdown',
    components: { StatusPill, Multiselect },
    props: {
        multiselectSettings: {
            type: Object as PropType<MultiselectSettingsInterface>,
            default: () => ({})
        },
        multiselectOptions: {
            type: Array,
            default: () => []
        },
        canAddOnlyOne: {
            type: Boolean,
            default: true
        },
        preselectedValue: {
            type: Object,
            default: () => ({})
        }
    },

    data () {
        return {
            multiselectSearchValue: '',
            selectedValue: null,
            options: []
        }
    },

    computed: {
      getPlaceholder (): string {
          return this.multiselectSettings['placeholder'] || this.multiselectSettings['is-type-and-add-enabled'] ? this.$t('Select or type new...') : this.$t('Select option...')
      }
    },

    watch: {
        selectedValue (): void {
            this.$emit('selected', this.selectedValue)
        },
        preselectedValue (): void {
            this.selectedValue = { ...this.preselectedValue }
        },
        multiselectOptions: {
            handler (): void {
                this.options = cloneDeep(this.multiselectOptions)
            },
            immediate: true
        }
    },

    methods: {
        handleMultiselectSearchChange (value: string): void {
            this.multiselectSearchValue = value
        },
        addTag (tag: string): void {
            const newTag = {
                name: tag,
                isNew: true
            }

            if (this.canAddOnlyOne) {
                this.options = this.options.filter(item => !item.isNew)
            }

            this.options = [newTag, ...this.options]
            this.selectedValue = newTag
        }
    }
})
