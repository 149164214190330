import MultiSwitch from '@/components/atoms/MultiSwitch/MultiSwitch.vue'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName'
import ListsMixin from '../../../mixins/ListsMixin'
import { SwitchOptionItem } from '@/components/atoms/MultiSwitch/MultiSwitch'
import { defineComponent } from 'vue'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { mapState } from 'pinia'

export default defineComponent({
    name: 'InvoicesListHeader',
    components: {
        MultiSwitch,
    },
    props: {
        title: String,
        hasManageColumns: {
            type: Boolean,
            default: true
        },
    },
    mixins: [ListsMixin],
    emits: ['openManageColumns'],
    data () {
        return {
            activeTab: localStorage.getItem('vendorInvoicesActiveTab') || InvoiceRouteName.InvoicesReceivedDefaultList,
            InvoiceRouteName,
            showManageColumns: false,
        }
    },
    computed: {
        ...mapState(invoicesStore, [
            'receivedInvoicesVendors',
            'vendorsInvoices',
            'getListScrollableColumns',
            'getListFixedColumns',
            'getInvoicesListHeaders',
            'vendorsDetails',
        ]),

        invoicesTabs (): SwitchOptionItem[] | null {
            if ([InvoiceRouteName.InvoicesReceivedDefaultList, InvoiceRouteName.InvoicesReceivedVendorsList].includes(this.$route.name)) {
                return [
                    {
                        key: InvoiceRouteName.InvoicesReceivedDefaultList,
                        label: this.$tc('Default')
                    },
                    {
                        key: InvoiceRouteName.InvoicesReceivedVendorsList,
                        label: this.$tc('Grouped by Vendor')
                    },
                ]
            }

            return null
        },

        isVendorsList (): boolean {
            return InvoiceRouteName.InvoicesReceivedVendorsList === this.$route.name
        },

        isVendorDetailsView (): boolean {
            return InvoiceRouteName.InvoicesReceivedVendorDetails === this.$route.name
        },

        vendorsStatusPillText (): string {
            return `${ this.receivedInvoicesVendors.length } ${ this.receivedInvoicesVendors.length > 1 ? this.$t('Vendors') : this.$t('Vendor') }`
        },

        invoicesStatusPillText (): string {
            const vendorInfo = this.vendorsDetails[this.$route.params.id]
            if (vendorInfo) {
                return `${ vendorInfo.invoices_count } ${ vendorInfo.invoices_count > 1 ? this.$t('Invoices') : this.$t('Invoice') }`
            }

            return `0 ${ this.$t('Invoices') }`
        },
    },
    watch: {
        $route: {
            handler (): void {
                if ([InvoiceRouteName.InvoicesReceivedDefaultList, InvoiceRouteName.InvoicesReceivedVendorsList].includes(this.$route.name)) {
                    this.activeTab = localStorage.getItem('vendorInvoicesActiveTab') || InvoiceRouteName.InvoicesReceivedDefaultList
                }
            },
            immediate: true
        },

        activeTab: {
            handler (newTab: string): void {
                if ([InvoiceRouteName.InvoicesReceivedDefaultList, InvoiceRouteName.InvoicesReceivedVendorsList].includes(this.$route.name)) {
                    this.$router.push({ name: newTab })
                    localStorage.setItem('vendorInvoicesActiveTab', newTab)
                }
            },
            immediate: true
        }
    },
    methods: {
        initManageColumns (): void {
            this.$emit('openManageColumns')
        }
    }
})
