<template>
    <IOModal
        width="750"
        persistent
        scrollable
        :loading="loading"
        :title="$t('Add New Drawings')"
        icon="icon-file-agreement"
        class="io-sets-create"
        @close="$emit('close')"
    >
        <Transition name="fade-from-left" mode="out-in">
            <SetTypesIndex
                v-if="selectingTypeStep"
                v-model="type"
                @next="selectingTypeStep = false"
                @vue:mounted="reset"
            />

            <SetFilesCreate
                v-else
                :active-draft-sets="activeDraftSets"
                :type="type"
                :active-current-set="activeCurrentSet"
                v-model:date="date"
                v-model:set="set"
                v-model:document="document"
            />
        </Transition>

        <template #footer>
            <template v-if="selectingTypeStep">
                <IOButton variant="secondary" @click="$emit('close')">{{ $t('Cancel') }}</IOButton>

                <IOButton @click="selectingTypeStep = false">{{ $t('Next') }}</IOButton>
            </template>

            <template v-else>
                <IOButton
                    variant="secondary"
                    icon="icon-undo"
                    @click="selectingTypeStep = true"
                >
                    {{ $t('Back') }}
                </IOButton>

                <IOButton :disabled="!valid" @click="uploadNewSet">{{ $t('Upload') }}</IOButton>
            </template>
        </template>
    </IOModal>
</template>


<script lang="ts" src="./SetsCreate.ts"></script>
<style lang="scss" src="./SetsCreate.scss" scoped></style>
