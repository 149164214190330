import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import SingleRfi from './single-rfi/SingleRfi.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'

export default defineComponent({
    components: {
        SingleRfi,
        IOPlaceholder,
    },
    props: {
        searchValue: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
            selectedAnnotation: 'getSelectedAnnotation',
        }),
        isAddingAnnotation (): boolean {
            return <boolean>(this.selectedAnnotation &&
                this.selectedAnnotation.xfdf &&
                this.selectedAnnotation.type === RightSideBar.RFI)
        },
        rfis (): unknown[] {
            const rfiKey = 'rfi_v2'
            const titleKey = 'title'

            return this.model.annotations[rfiKey]
                .filter(annotation =>
                    annotation[rfiKey][titleKey]
                        .toLowerCase()
                        .includes(this.searchValue),
                )
                .slice()
                .reverse()
        },
    },
    watch: {
        selectedAnnotation: {
            handler (newVal: unknown): void {
                if (newVal?.type === RightSideBar.RFI) {
                    this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', RightSideBar.RFI)
                }
            },
        },
    },
    methods: {
        annotationDeselected (): void {
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: '',
                type: RightSideBar.DEFAULT,
            })
        },
    },
})
