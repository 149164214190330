<template>
    <div
        v-if="!showContentLoader"
        class="io-invoices-list"
        :class="{ 'io-invoices-list--empty': placeholderVisible }"
    >
        <Placeholder
            v-if="showMainPlaceholder"
            icon="icon-invoice"
            :title="$t('There are no {name}', {name: $t('Shared Invoices')})"
        />
        <div v-else>
            <InvoicesListHeader :title="$t('Shared Invoices')" @openManageColumns="$emit('openManageColumns')"/>
            <InvoicesListTable
                :headers="getListHeaders"
                :items="getList"
                :loading-data="loadingData"
                :totals="totals"
                @loadData="loadData"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./SharedInvoicesList.ts"></script>
<style lang="scss" src="../InvoicesLists.scss" scoped></style>
