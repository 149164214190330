<template>
    <tr :key="item.id" @click="openRowDetails">
        <td
            v-for="(header, index) in headers"
            :key="index"
            :class="`io-cell-${header.valueType} ${header.valueAlign || 'aleft'} ${header.extraClasses}`"
            :style="header.extraStyles"
        >
            <span
                v-if="CellType.Text === header.valueType"
                :class="item.multi_funded === true ? `io-cell-content-${header.valueType}` : ''"
            >
                {{ getCellValue(header) }}

                <span
                    v-if="item.multi_funded === true && InvoiceHeader.InvoiceNumber === header.value"
                    class="icon-page-break"
                />
                <span
                    v-if="item.multi_funded === true && (InvoiceHeader.GrossBilledThisPeriod === header.value ||
                        InvoiceHeader.NetBilledThisPeriod === header.value ||
                        InvoiceHeader.BilledToDate === header.value)"
                    :class="`io-cell-content-${header.valueType}__bottom`"
                >
                    {{ $t('from the total of') }}
                    {{ $filters.formatProjectCurrencyInt(item[`total_${header.value}`]) }}
                </span>
            </span>
            <img
                v-if="CellType.Image === header.valueType"
                v-tooltip.top.start="$t(header.customValue(item).tooltip)"
                :src="header.customValue(item).src"
            />
            <span class="io-f-row io-f-start">
                <StatusDot
                    v-if="CellType.StatusDot === header.valueType"
                    :variant="getStatusDotVariant(header)"
                    :text="getStatusDotText(header)"
                    :tooltip="header.tooltip ? { text: header.tooltip(item), top: true, start: true } : null"
                />
                <span
                    v-if="item.isOutstandingAttached && InvoiceHeader.LienWaiverStatus === header.value"
                    v-tooltip.top.end="$t('Outstanding attached Lien Waiver(s)')"
                    class="icon-alert-triangle-2"
                ></span>
                <span v-if="CellType.Avatar === header.valueType && item[header.value]" class="io-f-row io-f-start">
                    <span v-if="item[header.value].length" class="io-f-row io-f-start">
                        <Avatar
                            v-if="item[header.value].length < 2"
                            size="sm"
                            :show-full-name="true"
                            :employee-mongo-id="item[header.value][0].id"
                            :employee-full-name="item[header.value][0].name"
                        >
                            <div class="io-company-name">{{ item[header.value][0].companyName }}</div>
                        </Avatar>
                        <span
                            v-for="(user, index) in item[header.value].slice(0, maxAvatarsCount)"
                            v-else
                            :key="index"
                            v-tooltip.top="user.name"
                            class="io-avatar-wrapper"
                        >
                            <Avatar
                                size="sm"
                                :employee-mongo-id="user.id"
                                :employeeFullName="user.name"
                            />
                        </span>
                        <span v-tooltip.top.start="{ html: 'avatars-tooltip' }" class="io-avatar-wrapper">
                            <Avatar
                                v-if="item[header.value].length > 5"
                                size="sm"
                                bg-color="var(--queen-pink)"
                                :initials="`+${item[header.value].length - maxAvatarsCount}`"
                            />
                        </span>
                        <div
                            v-if="item[header.value].length > 5"
                            id="avatars-tooltip"
                            class="io-html-tooltip"
                            v-html="getAvatarsTooltip(item[header.value].slice(maxAvatarsCount))"
                        ></div>
                    </span>
                    <Avatar
                        v-else-if="item[header.value].id"
                        size="sm"
                        type="company"
                        :show-full-name="true"
                        :employee-mongo-id="item[header.value].id"
                        :employeeFullName="item[header.value].name"
                    />
                    <Avatar
                        v-else
                        size="sm"
                        :show-full-name="true"
                        employee-mongo-id="-"
                        employeeFullName="-"
                    />
                </span>
            </span>
            <StatusPill
                v-if="CellType.StatusPill === header.valueType"
                size="big"
                :variant="getStatusPillVariant(item)"
                :text="getStatusPillText(header)"
            />
            <span v-if="CellType.Actions === header.valueType" class="io-column-actions">
                <template v-for="(button, index) in header.customValue(item)" :key="index">
                    <IOButton
                        v-if="undefined !== button.isDisplayed ? button.isDisplayed : true"
                        v-tooltip.top="{
                            content: button.tooltip?.text,
                            visible: undefined !== button.tooltip?.text
                        }"
                        :variant="button.variant"
                        :icon="hoveringOn === index && button.hoverText ? button.hoverIcon : button.icon"
                        @mouseover="hoveringOn = index"
                        @mouseleave="hoveringOn = null"
                        @click.stop="onButtonClick(button)"
                    >
                        <span v-if="hoveringOn === index && button.hoverText">{{ button.hoverText }}</span>
                        <span v-else-if="button.text">{{ button.text }}</span>
                    </IOButton>
                </template>
            </span>
        </td>
    </tr>
</template>

<script lang="ts" src="./InvoiceTableRow.ts"></script>
<style lang="scss" src="./InvoiceTableRow.scss" scoped></style>
