import apiProjectWizard from '@/api/project-wizard/projectWizardClient'
import { ContractStage } from '@/interfaces/modules/contract/ContractStage'

export const resetState = function ({commit, dispatch}) {
    commit('RESET_STATE')
}

export const resetInvitationData = function ({commit, dispatch}) {
    commit('RESET_INVITATION_DATA')
}

export const setUnits = function ({commit, dispatch}, value) {
    commit('SET_UNITS', value || [])
}

export const setModalContractStage = function ({commit, dispatch}, value) {
    commit('SET_MODAL_CONTRACT_STAGE', value || ContractStage.ORIGINAL)
}

export const setModalContract = function ({commit, dispatch}, value) {
    commit('SET_MODAL_CONTRACT', value || false)
}

export const setNeedReload = function ({commit, dispatch}, value) {
    commit('SET_NEED_RELOAD', value)
}

export const setChangeOrders = function ({commit, dispatch}, value) {
    commit('SET_CHANGE_ORDERS', value)
}

export const setAmendments = function ({commit, dispatch}, value) {
    commit('SET_AMENDMENTS', value)
}

export const setQuotedItemsList = function ({commit, dispatch}, value) {
    commit('SET_QUOTED_ITEMS_LIST', value)
}

/**
 *
 * @param commit
 * @param dispatch
 * @param value
 */
export const setInvoices = function ({commit, dispatch}, value) {
    commit('SET_INVOICES', value)
}

export const setActiveTab = function ({commit, dispatch}, value) {
    commit('SET_ACTIVE_TAB', value)
}

export const resetValidationErrors = function ({commit, dispatch}, value) {
    commit('RESET_VALIDATION_ERRORS', value)
}

export const setValidationError = function ({commit, dispatch}, value) {
    commit('SET_VALIDATION_ERROR', value)
}

export const removeValidationError = function ({commit, dispatch}, value) {
    commit('REMOVE_VALIDATION_ERROR', value)
}

export const setModalSovLine = function ({commit, dispatch}, value) {
    commit('SET_MODAL_SOV_LINE', value)
}

export const setPreviousRetentionRules = function ({commit, dispatch}, value) {
    commit('SET_PREVIOUS_RETENTION_RULES', value)
}

export const setMainContract = function ({commit, dispatch}, value) {
    commit('SET_MAIN_CONTRACT', value)
}

export const vueRefreshTasks = function ({commit, dispatch}) {
    commit('VUE_REFRESH_TASKS')
}

export const setSharedData = function ({commit, dispatch}, value) {
    commit('SET_IN_PROJECT', value.inProject)
    commit('SET_COST_CODES', value.costCodes)
    commit('SET_COST_CODES_PROJECT', value.costCodesProject)
    commit('SET_COST_TYPES', value.costTypes)
    commit('SET_DIVISIONS', value.divisions)
    commit('SET_UNITS', value.units)
    commit('SET_RETENTION_RULES_OPTIONS', value.retentionRulesOptions)
}

export const setCostCodesProject = function ({commit, dispatch}, value) {
    commit('SET_COST_CODES_PROJECT', value.costCodesProject)
}

export const loadProjectWizardData = function ({commit, dispatch, state}, contractId = null) {
    return apiProjectWizard.getData(contractId).then(response => {
        if (response && response.data) {
            commit('SET_CLIENTS_INTERNAL', response.data.clientsInternal)
            commit('SET_COMPANIES_MAP', response.data.companies_map)
            commit('SET_ALLOWED_CLIENTS_TYPES', response.data.allowedClientsTypes)
        }
    })
}

export const setContract = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT', value)
}

export const setContractStatus = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT_STATUS', value)
}

export const setContractInitialStatus = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT_INITIAL_STATUS', value)
}

export const setContractApprovalStatus = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT_APPROVAL_STATUS', value)
}

export const setContractSovStatus = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT_SOV_STATUS', value)
}

export const setCreatingFromTemplateMode = function ({ commit, dispatch }, value) {
    commit('SET_CREATING_FROM_TEMPLATE_MODE', value)
}

export const setSelectedContractTemplatesIds = function ({ commit, dispatch }, value) {
    commit('SET_SELECTED_CONTRACT_TEMPLATES_IDS', value)
}
export const setCreatingContractData = function ({ commit, dispatch }, value) {
    commit('SET_CREATING_CONTRACT_DATA', value)
}

export const clearCreatingContractFromTemplatesFields = function ({ commit }) {
    commit('CLEAR_CREATING_CONTRACT_FROM_TEMPLATES_DATA')
    commit('CLEAR_SELECTED_CONTRACT_TEMPLATES_IDS')
    commit('CLEAR_DISABLED_CONTRACT_TEMPLATES_IDS')
    commit('SET_CREATING_FROM_TEMPLATE_MODE', false)
    commit('SET_ADDING_DOCUMENT_TEMPLATES_TO_CONTRACT_MODE', false)
}

export const setLienWaiverFiles = function ({ commit }, value) {
    commit('SET_LIEN_WAIVER_FILES', value)
}

export const setReplacingInsurance = function ({ commit, dispatch }, value) {
    commit('SET_REPLACING_INSURANCE', value)
}

export const setReplacedInsurance = function ({ commit, dispatch }, value) {
    commit('SET_REPLACED_INSURANCE', value)
}

export const setShowInsuranceReplaceModal = function ({ commit, dispatch }, value) {
    commit('SET_SHOW_INSURANCE_REPLACE_MODAL', value)
}

export const setShowContractTypeSelectorModal = function ({ commit, dispatch }, value) {
    commit('SET_SHOW_CONTRACT_TYPE_MODAL', value)
}

export const setContractDocuments = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT_DOCUMENTS', value)
}

export const setIsContractSynced = function ({ commit, dispatch }, value) {
    commit('SET_IS_CONTRACT_SYNCED', value)
}

export const setContractInsuranceRequirements = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT_INSURANCE_REQUIREMENTS', value)
}

export const setContractQuotedItems = function ( { commit }, value ) {
    commit('SET_CONTRACT_QUOTED_ITEMS', value)
}

export const setWbsItems = function ( { commit }, value ) {
    commit('SET_WBS_ITEMS', value)
}

export const setDisplayUnitsToggleInitial = function ( { commit }, value ) {
    commit('SET_DISPLAY_UNITS_TOGGLE_INITIAL', value)
}

export const setDisplayRetentionToggleInitial = function ( { commit }, value ) {
    commit('SET_DISPLAY_RETENTION_TOGGLE_INITIAL', value)
}

export const setDisplayUnitsToggle = function ( { commit }, value ) {
    commit('SET_DISPLAY_UNITS_TOGGLE', value)
}

export const setDisplayRetentionToggle = function ( { commit }, value ) {
    commit('SET_DISPLAY_RETENTION_TOGGLE', value)
}

export const setRequireSOVApprovalToggle = function ( { commit }, value ) {
    commit('SET_REQUIRE_SOV_APPROVAL_TOGGLE', value)
}

export const setRequireUpdateSOVApprovalToggle = function ( { commit }, value ) {
    commit('SET_REQUIRE_UPDATE_SOV_APPROVAL_TOGGLE', value)
}

export const setTableViewMode = function ( { commit }, value ) {
    commit('SET_TABLE_VIEW_MODE', value)
}
