import { defineComponent } from 'vue'
import TieredMenu from './parts/TieredMenu/TieredMenu.vue'
import { MenuItem } from './parts/TieredMenu/MenuItem.d.ts'
import { ButtonIconPosition, ButtonSize, ButtonVariant } from '@/components/atoms/IOButton/IOButton.ts'

export default defineComponent({
    name: 'DropdownButtonMultilayer',
    components: {
        TieredMenu,
    },
    data () {
        return {
            id: crypto.randomUUID() as string,
            show: false,
            ButtonSize,
            ButtonVariant,
            ButtonIconPosition,
        }
    },
    props: {
        items: {
            default: () => [],
            type: Array as MenuItem,
        },
        left: {
            default: false,
            type: Boolean,
        },
        buttonLabel: {
            type: String,
            default: undefined,
        },
        buttonColor: {
            type: String,
            default: '',
        },
        buttonAttributes: {
            type: Object,
            default: () => ({
            }),
        },
    },
    computed: {
        buttonIcon (): string {
            return this.show ? 'icon-chevron-down' : 'icon-chevron-up'
        }
    },
    methods: {
        toggleMoreMenu (event): void {
            this.$refs.menu.toggle(event)
        },
        setShow (isOpen: boolean): void {
            this.show = isOpen
        },
    },
})
