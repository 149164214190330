export const addCheckedElement = function ({ commit, dispatch }, value) {
    commit('ADD_CHECKED_ELEMENT', value)
}
export const deleteCheckedElement = function ({ commit, dispatch }, value) {
    commit('DELETE_CHECKED_ELEMENT', value)
}
export const deleteAllCheckedElements = function ({ commit, dispatch }) {
    commit('DELETE_ALL_CHECKED_ELEMENTS')
}
export const clearAllItemsHolders = function ({ commit, dispatch }) {
    commit('CLEAR_ALL_ITEMS_HOLDERS')
}
export const setDisplayMode = function ({ commit, dispatch }, value) {
    commit('SET_DISPLAY_MODE', value)
}
export const setRecentlyAdded = function ({ commit, dispatch }, value) {
    commit('SET_RECENTLY_ADDED', value)
}
export const setFolders = function ({ commit, dispatch }, value) {
    commit('SET_FOLDERS', value)
}
export const setFiles = function ({ commit, dispatch }, value) {
    commit('SET_FILES', value)
}
export const setProjects = function ({ commit, dispatch }, value) {
    commit('SET_PROJECTS', value)
}
export const addItemToPathStack = function ({ commit, dispatch }, value) {
    commit('ADD_ITEM_TO_PATH_STACK', value)
}
export const reducePathStackToElementIndex = function ({ commit, dispatch }, value) {
    commit('REDUCE_PATH_STACK_TO_ELEMENT_INDEX', value)
}
export const setSearchInputValue = function ({ commit, dispatch }, value) {
    commit('SET_SEARCH_INPUT_VALUE', value)
}
export const setSortBy = function ({ commit, dispatch }, value) {
    commit('SET_SORT_BY', value)
}
export const setSortByDirection = function ({ commit, dispatch }, value) {
    commit('SET_SORT_BY_DIRECTION', value)
}
export const setActiveDisplayFilterId = function ({ commit, dispatch }, value) {
    commit('SET_ACTIVE_DISPLAY_FILTER_ID', value)
}
export const setFabButtonDisplayFlag = function ({ commit, dispatch }, value) {
    commit('SET_FAB_BUTTON_DISPLAY_FLAG', value)
}
export const setActiveFilter = function ({ commit, dispatch }, value) {
    commit('SET_ACTIVE_FILTER', value)
}
export const updateItemNameByIndex = function ({ commit, dispatch }, value) {
    commit('UPDATE_ITEM_NAME_BY_INDEX', value)
}
export const deleteItemById = function ({ commit, dispatch }, value) {
    commit('DELETE_ITEM_BY_ID', value)
}
export const appendItemToHolder = function ({ commit, dispatch }, value) {
    commit('APPEND_ITEM_TO_HOLDER', value)
}
export const setSpaceUsage = function ({ commit, dispatch }, value) {
    commit('SET_SPACE_USAGE', value)
}
export const setRequestRunning = function ({ commit, dispatch }, value) {
    commit('SET_REQUEST_RUNNING', value)
}
export const setUploaderBoxDisplayFlag = function ({ commit, dispatch }, value) {
    commit('SET_UPLOADER_BOX_DISPLAY_FLAG', value)
}
export const enableRelocateBox = function ({ commit, dispatch }, value) {
    commit('ENABLE_RELOCATE_BOX', value)
}
export const setRelocateBoxMode = function ({ commit, dispatch }, value) {
    commit('SET_RELOCATE_BOX_MODE', value)
}
export const setRelocateBoxItem = function ({ commit, dispatch }, value) {
    commit('SET_RELOCATE_BOX_ITEM', value)
}
export const setCurrentPreviewDocumentId = function ({ commit, dispatch }, value) {
    commit('SET_CURRENT_PREVIEW_DOCUMENT_ID', value)
}
export const setDateFormat = function ({ commit, dispatch }, value) {
    commit('SET_DATE_FORMAT', value)
}
export const updateElementProperties = function ({ commit, dispatch }, payload) {
    commit('UPDATE_ELEMENT_PROPERTIES', payload)
}
export const setInviteModalItemId = function ({ commit, dispatch }, payload) {
    commit('SET_INVITE_MODAL_ITEM_ID', payload)
}
export const setInProjectConfig = function ({ commit, dispatch }, payload) {
    commit('SET_IN_PROJECT_CONFIG', payload)
}
