import { defineComponent } from 'vue'
import SingleContact from './SingleContact.vue'
import { Contact } from '@/interfaces/components/invite-modal/Contact'

export default defineComponent({
    name: 'SingleRadioRow',
    components: {
        SingleContact
    },
    props: {
        componentKey: { type: String, required: true },
        idx: { type: Number, required: true },
        item: { type: Object as () => Contact, required: true },
        value: { type: String, default: undefined },
        modelValue: { default: '' }
    },
    computed: {
        isChecked () {
            return this.modelValue == this.value
        },
        selectedContact () {
            return this.$store.getters['inviteModal/getSelectedContact'](this.componentKey)
        }
    },
    mounted () {
        // @ts-ignore
        this.preselectContact()
    },
    methods: {
        preselectContact () {
            if (this.selectedContact) {
                if (this.item.id === this.selectedContact.id) {
                    this.clickHTMLElement()
                }
            } else if (this.idx === 0) {
                this.clickHTMLElement()
            }
        },
        clickHTMLElement () {
            this.$emit('update:modelValue', this.value)

            const input = document.querySelector(`.io-single-radio-row-${this.idx}`) as HTMLElement

            if (input) {
                input.click()
            }
        }
    }
})
