import { defineComponent } from 'vue'

import {
    HolderCellCustomValueReturnType,
    SimpleLazyListHolderHeader,
} from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'
import LogoSize from '@/io-modules/bid-requests/components/company-logo/enums/LogoSize.ts'

import CompanyLogo from '@/io-modules/bid-requests/components/company-logo/CompanyLogo.vue'

export default defineComponent({
    name: 'HolderCell',
    components: {
        CompanyLogo,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        column: {
            type: Object as () => SimpleLazyListHolderHeader<unknown>,
            required: true,
        },
    },
    setup () {
        return {
            LogoSize,
        }
    },
    computed: {
        value (): HolderCellCustomValueReturnType<unknown> {
            if (this.column.customValue) {
                return this.column.customValue(this.item)
            }

            return this.item[this.column.value]
        },
        nameLabel (): string {
            if (Array.isArray(this.value.name)) {
                return this.value.name.filter(el => !!el).join(' ')
            }

            return this.value.name
        },
    },
})
