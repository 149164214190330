<template>
    <popup :width="600" @close="closeModal" class="io-add-invoice-new">
        <template #header>
            <p>Add Invoice
                <span class="io-small-pill io-grey" v-if="appAvail([appTypes.TYPE_DEV, appTypes.TYPE_OWNER]) && availableStepsLength > 1">{{activeStepIndex + 1}} OF {{ availableStepsLength }}</span>
            </p>
            <div @click.prevent="closeModal" class="io-popup-close"><i class="fal fa-times"></i></div>
            <div class="io-popup-header-info" v-if="activeStep.id === 'documents'">Select the document name or create
                new, and attach the document. <strong>Documents are optional.</strong></div>
        </template>

        <template #default>
            <template v-if="loading">
                <div class="io-loader-small"></div>
            </template>
            <keep-alive v-else>
                <component :is="activeStep.component" :ref="activeStep.id"></component>
            </keep-alive>
        </template>

        <template #footer>
            <div class="io-add-invoice-new-footer">
                <a @click.prevent="goStepPrev" class="io-btn io-btn-secondary" v-if="!stepIsFirst">Back</a>
                <a @click.prevent="closeModal" class="io-btn io-btn-secondary" v-else>Cancel</a>
                <a @click.prevent="goStepNext" class="io-btn io-btn-primary" v-if="!stepIsLast && activeStep.allowNextStep">Next Step</a>
                <template v-else>
                    <a @click.prevent="addInvoice" class="io-btn io-btn-primary" v-if="!loadingButton">Create</a>
                    <a class="io-btn io-btn-primary" v-else>
                        <div class="io-loader-small"></div>
                    </a>
                </template>
            </div>
        </template>
    </popup>
</template>

<script src="./add-invoice.js"></script>
<style lang="scss" src="./add-invoice.scss"></style>
