<template>
    <Popup :width="500" :zindex="10000" :disableOutsideClose="true" class="io-popup-add-company-contact">
        <template #header>
            <div class="io-popup-header__title">{{ $t('Company has no associated email.') }}</div>
            <div class="io-popup-header__close" @click="closePopup"><i class="fal fa-times"></i></div>
        </template>
        <template #default>
            <div>
                <p>{{ company?.name }} {{ $t('Company has no associated email.') }}</p>
                <template v-if="company?.isGlobal">
                    <p>{{ $t("It's global company and you can not add new email.") }}</p>
                    <p>{{ $t("Please use \"Invite Bidder\" option instead.") }}</p>
                </template>
                <template v-else>
                    <p>{{ $t('Please select company contact to send Bid Package.') }}</p>
                    <multiselect
                        track-by="id"
                        label="name"
                        :options="optionsContacts"
                        :searchable="true"
                        :placeholder="$t('Please Select')"
                        v-model="selectedContact"
                        @select="selectContact"
                        @remove="removeContact"
                    />
                </template>
            </div>
        </template>
        <template #footer>
            <div class="io-f-row io-f-end io-buttons-row">
                <IOButton v-if="!saving" variant="secondary" size="small" @click="closePopup">{{ $t('Cancel') }}</IOButton>
                <IOButton v-if="company?.isLocal" @click="add">
                    {{ submitText }}
                </IOButton>
            </div>
        </template>
    </Popup>
</template>

<script lang="ts" src="./PopupCompany.ts"></script>
<style lang="scss" src="./PopupCompany.scss" scoped></style>
