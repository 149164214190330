const ProjectProvider = () => import('@/layouts/project-provider/ProjectProvider.vue')
const HistorySheetsIndex = () => import('@/io-modules/drawings/components/history-sheets-index/HistorySheetsIndex.vue')
const HistorySheetsShow = () => import('@/io-modules/drawings/components/history-sheets-show/HistorySheetsShow.vue')
const CompareSheetsShow = () => import('@/io-modules/drawings/components/compare-sheets-show/CompareSheetsShow.vue')
const DrawingsSets = () => import('@/io-modules/drawings/components/drawings-sets/DrawingsSets.vue')
const SheetsIndex = () => import('@/io-modules/drawings/components/sheets-index/SheetsIndex.vue')
const ProcessingSetsEdit = () => import('@/io-modules/drawings/components/processing-sets-edit/ProcessingSetsEdit.vue')
const PendingSetsShow = () => import('@/io-modules/drawings/components/pending-sets-show/PendingSetsShow.vue')
const SheetsShow = () => import('@/io-modules/drawings/components/sheets-show/SheetsShow.vue')

export default [
    {
        path: '/project/:pid/drawings',
        component: ProjectProvider,
        redirect: { name: 'drawings-sets-index' },
        children: [
            {
                path: 'sets',
                props: true,
                name: 'drawings-sets-index',
                meta: {
                    title: 'Drawings Sets',
                    navigationKey: 'blueprints',
                },
                component: DrawingsSets,
            },
            {
                path: 'processing/sets/:setId/sheets/:sheetId',
                props: true,
                name: 'drawings-processing-sheets-edit',
                meta: {
                    title: 'Configure Sheet',
                    navigationKey: 'blueprints',
                    showNotificationsForRoutes: ['drawings-sets-index'],
                },
                component: ProcessingSetsEdit,
            },
            {
                path: 'processing/sets/:setId/edit',
                props: true,
                name: 'drawings-processing-sets-edit',
                meta: {
                    title: 'Configure Set',
                    navigationKey: 'blueprints',
                    showNotificationsForRoutes: ['drawings-sets-index'],
                },
                component: ProcessingSetsEdit,
            },
            {
                path: 'pending/sets/:setId',
                props: true,
                name: 'drawings-pending-sets-show',
                meta: {
                    title: 'Review Results',
                    navigationKey: 'blueprints',
                },
                component: PendingSetsShow,
            },
            {
                path: 'sets/:setId/sheets',
                props: true,
                name: 'drawings-sheets-index',
                meta: {
                    title: 'Drawings Sheets',
                    navigationKey: 'blueprints',
                },
                component: SheetsIndex,
            },
            {
                path: 'sheets/:sheetId/versions/:versionId',
                props: true,
                name: 'drawings-sheets-show',
                meta: {
                    title: 'Drawings Sheet',
                    navigationKey: 'blueprints',
                },
                component: SheetsShow,
            },
            {
                path: 'history/sets/:setId/sheets',
                props: true,
                name: 'drawings-history-sheets-index',
                meta: {
                    title: 'Uploading History',
                },
                component: HistorySheetsIndex,
            },
            {
                path: 'history/sets/:setId/sheets/:sheetId',
                props: true,
                name: 'drawings-history-sheets-show',
                meta: {
                    title: 'Uploading History',
                },
                component: HistorySheetsShow,
            },
            {
                path: 'compare/sheets/:sheetId',
                props: true,
                name: 'drawings-compare-sheets-show',
                meta: {
                    title: 'Compare Sheets',
                },
                component: CompareSheetsShow,
            },
        ],
    },
]
