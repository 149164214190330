<template>
    <div class="io-invite-modal__form-view" data-testid="add-contact-details">
        <div class="io-popup-add-contact__row io-f-row">
            <div class="io-input-holder">
                <label class="io-required">
                    {{ $t('First name') }}
                </label>
                <input
                    type="text"
                    name="first_name"
                    v-model="contact.first_name"
                    :class="{'io-disabled': isUpdatingWorkspaceType}"
                    :disabled="isUpdatingWorkspaceType"
                />
            </div>
            <div class="io-input-holder">
                <label class="io-required">
                    {{ $t('Last name') }}
                </label>
                <input
                    type="text"
                    name="last_name"
                    v-model="contact.last_name"
                    :class="{'io-disabled': isUpdatingWorkspaceType}"
                    :disabled="isUpdatingWorkspaceType"
                />
            </div>
        </div>
        <div v-if="isFetchingExistingWorkspaces && !isUpdatingWorkspaceType" class="io-existing-workspaces__loading">
            {{ $t('Fetching existing workspaces...')}}
        </div>
        <div v-else-if="existingWorkspaces.length > 0 && !isUpdatingWorkspaceType" class="io-existing-workspaces">
            <div class="io-input-note">
                <span>{{ $t("You're adding a contact to an existing (on-system) company: ") }}</span>
                <span class="io-input-note__domain">{{ emailDomain }}</span>
            </div>
            <div class="io-popup-add-contact__row io-f-row">
                <div class="io-input-holder">
                    <div class="io-label-icon">
                      <label class="io-required">
                          {{ $t('Select a workspace for your contact to be associated with') }}
                      </label>
                      <i
                          class="icon-question"
                          v-tooltip.bottom.center="$t('If you couldn\'t find the right workspace, you can reach out to the user to notify the workspace administrator by email.')"
                      ></i>
                    </div>
                    <WorkspaceRadio
                        v-if="existingWorkspaces.length"
                        v-for="workspace in existingWorkspaces"
                        key="workspace.id"
                        :workspace="workspace"
                        :input-name="'existing-workspace'"
                        :radio-value="workspace.id"
                        :model-value="contact.workspace.id"
                        v-model="selectedExistingWorkspaceId"
                    />
                </div>
            </div>
        </div>
        <div v-else>
            <div class="io-popup-add-contact__row io-f-row">
                <div class="io-input-holder">
                    <label class="io-required">
                        {{ $t('Company') }}
                    </label>

                    <input
                        type="text"
                        v-model="contact.workspace.name"
                        placeholder="Type Company Name..."
                        :class="{'io-disabled': isUpdatingWorkspaceType}"
                        :disabled="isUpdatingWorkspaceType"
                    />
                </div>
            </div>

            <div v-if="!isUpdatingWorkspaceType">
                <div class="io-popup-add-contact__row io-f-row">
                    <div class="io-input-holder">
                        <label class="io-form-label io-required">
                            {{ $t('Company address line 1') }}
                        </label>
                        <input
                            class="address1"
                            v-model="contact.workspace.address_line1"
                            placeholder=""
                        />
                    </div>
                    <div class="io-input-holder">
                        <label class="io-form-label">
                            {{ $t('Company address line 2') }}
                        </label>
                        <input
                            class="address2"
                            v-model="contact.workspace.address_line2"
                        />
                    </div>
                </div>

                <div class="io-popup-add-contact__row io-f-row">
                    <div class="io-input-holder">
                        <label class="io-form-label io-required">
                            {{ $t('ZIP/postal code') }}
                        </label>
                        <input
                            v-model="contact.workspace.zip"
                        />
                    </div>

                    <div class="io-input-holder">
                        <label class="io-form-label io-required">
                            {{ $t('City') }}
                        </label>
                        <input
                            v-model="contact.workspace.city"
                        />
                    </div>

                </div>

                <div class="io-popup-add-contact__row io-f-row">
                    <div class="io-input-holder">
                        <label class="io-form-label io-required">
                            {{ $t('Country') }}
                        </label>

                        <Multiselect
                            track-by="id"
                            label="name"
                            :options="countries"
                            :searchable="true"
                            :select-label="$t('Select country')"
                            v-model="selectedCountry"
                        />
                    </div>

                    <div class="io-input-holder">
                        <label class="io-form-label">
                            {{ $t('State/province/region') }}
                        </label>

                        <Multiselect
                            track-by="id"
                            label="name"
                            :options="states"
                            :searchable="true"
                            :select-label="$t('Select state')"
                            :disabled="shouldDisableStateSelection"
                            v-model="selectedState"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="shouldCreateNewCompany || isUpdatingWorkspaceType"
            class="io-popup-add-contact__row io-f-row"
        >
            <div class="io-input-holder">
                <label class="io-required">
                    {{ $t('What does the company do?') }}
                </label>
                <div class="io-inner-text">
                    {{ $t("Choose the appropriate workspace according to the company’s function.") }}
                </div>
                <CustomMultiselect
                    :addNewBtnDisabled="false"
                    :addingNewInProcess="false"
                    :options="workspaceTypeOptions"
                    :settings="workspaceTypeSettings"
                    :modelValue="selectedWorkspaceType"
                    :statusEnable="false"
                    :enableStopPropagation="false"
                    @changeOption="onSelectCompanyType"
                    @menuToggled="onCompanyTypeToggle"
                >
                    <template v-slot:header="{ activeOption, config }">
                        <div v-if="activeOption" class="io-multiselect__name">
                            <SingleCompanyType
                                :label="activeOption.name"
                                :description="activeOption.description"
                                :icon-class="activeOption.iconClass"
                                :icon-svg="activeOption.iconSvg"
                                :hide-description="true"
                            />
                        </div>
                    </template>
                    <template v-slot:option="{ option }">
                        <SingleCompanyType
                            :label="option.name"
                            :description="option.description"
                            :icon-class="option.iconClass"
                            :icon-svg="option.iconSvg"
                        />
                    </template>
                </CustomMultiselect>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./AddContactDetails.ts"></script>
<style lang="scss" src="./AddContactDetails.scss" scoped></style>
