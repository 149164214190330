import client from '@/api/change-order/client'


/**
 *
 * @param commit
 * @param dispatch
 */
export const resetState = function ({commit, dispatch}) {
    commit('RESET_STATE')
}

/**
 *
 * @param commit
 * @param dispatch
 * @param state
 * @param config
 */
export const getContractData = async function ({commit, dispatch, state}, config) {
    if (config) {
        commit('SET_CONFIG', config)
    }

    const projectId = (config) ? config.projectId : state.projectId
    const contractId = (config) ? config.contractId : state.contractId

    try {
        const {data} = await client.getContractData(projectId, contractId)
        commit('SET_COST_CODES', data.costCodes)
        commit('SET_COST_CODES_SOV', data.costCodesSov)
        commit('SET_COST_CODES_PROJECT', data.costCodesProject)
        commit('SET_COST_CODES', data.costCodes)
        commit('SET_CONTRACT_PHASES', data.contractPhases)

        return true
    } catch (exception) {
        return exception
    }
}

export const setCOFilters = function ({commit, dispatch}, filters) {
    commit('SET_FILTERS', filters)
}




