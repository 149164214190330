import { AxiosError } from 'axios'

export default {
    methods: {
        /**
         * Shows the error message and redirect when the response code is 403 and 404.
         * @param {AxiosError} error
         * @returns {boolean}
         */
        errorHandle (error, silent = false) {
            this.consoleError(error)

            if (silent) {
                return true
            }

            if (error.response) {
                if (error.response.status === 422) {
                    return this.$_errorMixin_error422(error.response.data)
                } else if (error.response.status === 403) {
                    // when access is denied
                    if (error.response.data && error.response.data.accessDenied) {
                        return this.$_errorMixin_accessDenied(error.response.data)
                    } else {
                        return this.$_errorMixin_error403(error)
                    }
                } else if (error.response.status === 404) {
                    return this.$_errorMixin_error404(error.response?.data?.msg || error.response?.data?.message || error?.message)
                } else {
                    return this.$_errorMixin_error(error.response?.data?.msg || error.response?.data?.message || error?.message)
                }
            }
            return this.$_errorMixin_error()
        },

        /**
         * Shows the error message without redirecting to error page.
         * @param {AxiosError} error
         * @returns {boolean}
         */
         errorHandleNoRedirect (error) {
            this.consoleError(error)
            if (error.response) {
                if (error.response.status === 422) {
                    return this.$_errorMixin_error422(error.response.data)
                } else if (error.response.status === 403) {
                    if (error.response.data && error.response.data.accessDenied) {
                        return this.$_errorMixin_accessDeniedNoRedirect(error.response.data)
                    } else {
                        return this.$_errorMixin_error403NoRedirect(error)
                    }
                } else {
                    return this.$_errorMixin_error(error.response.data.msg || error.response.data.message)
                }
            }
            return this.$_errorMixin_error()
        },

        /**
         * @param {object} response
         * @returns {boolean}
         */
        $_errorMixin_accessDenied (response) {
            // top bar message
            this.showNotification('error', response.message || 'Access Denied')

            // by default redirect to dashboard
            if (response.redirect) {
                this.$router.push({name: '403'})
            }

            return true
        },

        /**
         * @param {object} response
         * @returns {boolean}
         */
        $_errorMixin_accessDeniedNoRedirect (response) {
            // top bar message
            this.showNotification('error', response.message || 'Access Denied')

            return true
        },

        /**
         * @param {string} msg
         * @returns {boolean}
         */
        $_errorMixin_error (msg) {
            this.showNotification('error', msg || 'Error! Try again later.')
            return true
        },

        /**
         * @param {object} response
         * @returns {boolean}
         */
        $_errorMixin_error422 (response) {
            let result = response.result || response.errors
            if (result) {
                var m = Object.keys(result)[0]
                const payload = {
                    type: 'error',
                    text: result[m][0]
                }
                this.showNotification(payload.type, payload.text)
            } else if (response.message) {
                const payload = {
                    type: 'error',
                    text: response.message
                }
                this.showNotification(payload.type, payload.text)
            }
        },

        /**
         * @param {AxiosError} error
         * @returns {boolean}
         */
        $_errorMixin_error403 (error) {
            let msg = error.response.data.msg || error.response.data.message || 'Access Denied'
            this.showNotification('error', msg.replace('Forbidden', 'Denied'))

            // redirect to dashboard
            this.$router.push({name: '403'})

            return true
        },

        /**
         * @param {AxiosError} error
         * @returns {boolean}
         */
        $_errorMixin_error403NoRedirect (error) {
            let msg = error.response.data.msg || error.response.data.message || 'Access Denied'

            this.showNotification('error', msg.replace('Forbidden', 'Denied'))

            return true
        },

        /**
         * @param {string} msg
         * @returns {boolean}
         */
        $_errorMixin_error404 (msg) {
            this.showNotification('error', msg)

            this.$router.push({name: 'notFound'})

            return true
        },
    }
}
