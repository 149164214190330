<template>
    <RightModal crop-to-content-no-gap :disableMaskClose="true">
        <template #default>
            <div class="io-workflow-edit-modal">
                <div class="io-workflow-edit-modal__header">
                    <div class="io-workflow-edit-modal__title">
                        {{ $t('Edit Approval Workflow') }}
                    </div>
                    <IOButton variant="secondary" @click="handleCloseModal">{{ $t('Cancel') }}</IOButton>
                    <IOButton
                        class="io-workflow-edit-modal__save-button"
                        :disabled="!isSaveEnabled"
                        @click="handleSave"
                    >
                        <div v-if="isSavingInProgress" class="io-loader-small"></div>
                        <template v-else>{{ $t('Save') }}</template>
                    </IOButton>
                </div>
                <div>
                    <AlertBox class="io-workflow-edit-modal__info-box" color="blue" icon="icon-info">
                        <template #default>
                            {{ $tc('Functionality is limited to adding approvers only. Changes made here will only apply to this item. To use the full functionality or change the workflow for all items go to') }}
                            <a class="io-accent" @click="handleGoToProjectSettings">
                                {{ $tc('Project Settings') }}
                            </a>
                        </template>
                    </AlertBox>
                    <SingleWorkflow
                        v-for="(workflow) in workflows"
                        :key="workflow._id"
                        :cost-codes-categories="costCodesCategories"
                        :cost-codes-enabled="costCodesEnabled"
                        :data="workflow"
                        :edit="true"
                        :allow-templates="false"
                        :allow-limit-edit="false"
                        :allow-sequencing="false"
                        :allow-cost-code-selection="false"
                        :individual-resource-view="true"
                        :employees="employees"
                    />
                </div>
            </div>
        </template>
    </RightModal>
</template>

<script lang="ts" src="./ApprovalWorkflowEditModal.ts"></script>
<style lang="scss" src="./ApprovalWorkflowEditModal.scss" scoped></style>
