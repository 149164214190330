import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import { get, isNil, isFunction } from 'lodash'
import { mapActions, mapState } from 'vuex'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn.vue'
import Template from '@/modules/lists-templates/views/bid-questions/templates/parts/template'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'


interface PopupButton {
    text: string
    class?: string
    icon?: string
    action?: Function
    saveDisabledInSession?: boolean
    loaderOnAction: boolean
    validateInput: boolean
}
interface PopupAlert {
    content: string
    buttonText?: string
    color?: string
    icon?: string
    action?: Function
}
export interface PopupConfig {
    title: string
    caption: string
    captionHTML?: boolean
    buttons: PopupButton[]
    width?: number
    header?: string
    icon?: string
    disableOutsideClose?: boolean
    onClose?: () => void
    radioOptions?: object
    iconClass?: string
    zIndex?: number
    iconColor?: string
    sessionKey?: string
    disabledInSessionCallback?: () => void
    checkBox?: string
    input?: string,
    inputRequired?: boolean,
}

enum DefaultSettings {
    Z_INDEX = 50
}

export default defineComponent({
    components: {
        AlertBox,
        Template,
        FormInputColumn,
        PopupModal
    },
    setup () {
        return {
            get,
        }
    },
    data () {
        return {
            selectedRadio: null as string | null,
            checkBoxChecked: false,
            checkBoxErrorVisible: false,
            inputValue: '',
            disableInSession: false,
            loader: false,
            inputErrorVisible: false,
        }
    },
    computed: {
        ...mapState('popupAlert', {
            popup: state => state.popup
        }),
        showPopup (): boolean {
            return !isNil(this.popup)
        },
        zIndex (): number {
            return this.popup.zIndex ?? DefaultSettings.Z_INDEX
        }
    },
    watch: {
        'popup.radioOptions' (newValue) {
            this.selectedRadio = Object.keys(newValue ?? {})[0] ?? null
        },
        'checkBoxChecked' (newValue: boolean): void {
            this.checkBoxErrorVisible = false === newValue
        },
    },
    methods: {
        ...mapActions('popupAlert', {
            setPopupAlert: 'setPopupAlert'
        }),
        closePopup (buttonIndex: number = null): void {
            if (isFunction(this.popup?.onClose)) {
                this.popup?.onClose(buttonIndex)
            }
            this.setPopupAlert(null)
            this.checkBoxChecked = false
            this.checkBoxErrorVisible = false
            this.inputValue = ''
            this.inputErrorVisible = false
        },
        handleButtonClick (button: PopupButton, index: number): void {
            if (null !== this.popup?.sessionKey && this.disableInSession && button?.saveDisabledInSession) {
                const disabledPopups = JSON.parse(sessionStorage.getItem('disabledPopups') ?? '[]')

                disabledPopups.push(this.popup.sessionKey)
                sessionStorage.setItem('disabledPopups', JSON.stringify(disabledPopups))
            }

            if (isFunction(button.action)) {
                if (button.loaderOnAction) {
                    this.loader = true
                }

                if (this.popup?.checkBox && false === this.checkBoxChecked) {
                    this.checkBoxErrorVisible = true
                    return
                }
                if (this.popup?.input && this.popup?.inputRequired === true && !this.inputValue) {
                    this.inputErrorVisible = true
                    return
                }

                const { selectedRadio, inputValue } = this
                button.action({ selectedRadio, inputValue })
            }

            this.closePopup(index)
            this.loader = false
        },
        handleAlertButtonClick (alert: PopupAlert): void {
            if (isFunction(alert.action)) {
                alert.action()
                this.closePopup()
            }
        },
        handleClose (): void {
            if (!this.popup.disableOutsideClose) {
                this.closePopup()
            }
        },
    }
})
