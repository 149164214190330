import { defineComponent } from 'vue'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { mapActions, mapState } from 'pinia'
import InvoicesListTable from '@/io-modules/invoices/components/invoices-list/invoices-list-table/InvoicesListTable.vue'
import CellType from '@/components/table/CellType'
import InvoiceHeader from '@/io-modules/invoices/enums/InvoiceHeader'
import Invoice from '@/io-modules/invoices/interfaces/Invoice'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { TranslateResult } from 'vue-i18n'
import dayjs from 'dayjs'
import { mapState as mapStateVuex } from 'vuex'

export default defineComponent({
    name: 'AttachVendorInvoiceView',
    components: {
        InvoicesListTable,
    },
    data () {
        return {
            loadingData: false,
            invoicesToDisplay: [],
            attachedInvoices: []
        }
    },
    computed: {
        ...mapStateVuex('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id,
        }),
        ...mapState(invoiceViewStore, ['vendorsInvoices', 'isEditingMode', 'creating', 'editing', 'invoice', 'dataLoading']),

        vendorsInvoicesHeaders (): HeaderTable[] {
            let headers: HeaderTable[] = [
                {
                    key: 'off_on_system',
                    text: '',
                    valueType: CellType.Image,
                    customValue: (item: Invoice): object => {
                        return {
                            src: `/img/avatar-${ item.is_provided_manually ? 'gray' : 'colored' }.svg`,
                            tooltip: item.is_provided_manually ? this.$t('Provided Manually') : this.$t('Provided via System')
                        }
                    },
                    sort: false,
                },
                {
                    key: InvoiceHeader.Type,
                    text: this.$t('Invoice Type'),
                    valueType: CellType.Text,
                    value: InvoiceHeader.Type,
                    customValue: (item: Invoice): TranslateResult => {
                        return this.$t(item.type)
                    },
                },
                {
                    key: InvoiceHeader.InvoiceNumber,
                    text: this.$t('Generated ID'),
                    value: InvoiceHeader.InvoiceNumber,
                    valueType: CellType.Text,
                },
                {
                    key: InvoiceHeader.UniqueId,
                    text: this.$t('Custom ID'),
                    value: InvoiceHeader.UniqueId,
                    valueType: CellType.Text,
                },
                {
                    key: InvoiceHeader.Vendor,
                    text: this.$t('Vendor'),
                    value: InvoiceHeader.Vendor,
                    valueType: CellType.Avatar,
                },
                {
                    key: InvoiceHeader.InvoiceDate,
                    text: this.$t('Invoice Date'),
                    valueType: CellType.Text,
                    value: InvoiceHeader.InvoiceDate,
                    customValue: (item: Invoice): string => {
                        return dayjs(item.invoice_date).format('DD MMM YYYY')
                    },
                },
                {
                    key: InvoiceHeader.Status,
                    text: this.$t('Invoice Status'),
                    value: InvoiceHeader.Status,
                    valueType: CellType.StatusPill,
                },
                {
                    key: InvoiceHeader.PaymentStatus,
                    text: this.$t('Payment Status'),
                    value: InvoiceHeader.PaymentStatus,
                    valueType: CellType.StatusDot,
                },
                {
                    key: InvoiceHeader.GrossBilledThisPeriod,
                    text: this.$t('Gross Billed This Period'),
                    value: InvoiceHeader.GrossBilledThisPeriod,
                    valueType: CellType.Text,
                    filter: 'formatProjectCurrencyInt',
                    valueAlign: 'aright',
                    extraStyles: 'width: 160px;',
                },
            ]

            if (this.isEditingMode) {
                headers.push(
                    {
                        text: '',
                        valueType: CellType.Actions,
                        customValue: (item: Invoice): object[] => {
                            return [
                                {
                                    variant: 'link',
                                    icon: 'icon-paperclip',
                                    text: this.$t('Attach'),
                                    isDisplayed: !item.attached,
                                    onClick: (item: Invoice): void => this.onAttachClick(item),
                                },
                                {
                                    variant: 'link',
                                    icon: 'icon-check-circle-2-2 io-success-icon',
                                    text: this.$t('Attached'),
                                    hoverIcon: 'icon-cross io-red-icon',
                                    hoverText: this.$t('Remove'),
                                    isDisplayed: item.attached,
                                    onClick: (item: Invoice): void  => this.onRemoveClick(item),
                                },
                            ]
                        },
                        sort: false,
                        extraStyles: 'width: 155px;',
                    }
                )
            }

            return headers
        }
    },
    watch: {
        isEditingMode (): void {
            this.setInvoicesToDisplay()
        },
        'invoice.attached_invoices' (): void {
            this.setInvoicesToDisplay()
        },
    },
    mounted () {
        this.setInitiallyAttachedInvoices()
        this.setInvoicesToDisplay()
    },
    methods: {
        ...mapActions(invoiceViewStore, ['fetchVendorsInvoices', 'setAttachedInvoices', 'setDataLoadingValue']),

        async loadData (): Promise<void> {
            this.setDataLoadingValue(true)
            await this.fetchVendorsInvoices(this.projectLocalID)
            this.setDataLoadingValue(false)
        },

        async setInvoicesToDisplay (): Promise<void> {
            if (this.creating) {
                this.invoicesToDisplay = this.vendorsInvoices.map(invoice => ({ ...invoice, attached: false }))
            } else if (this.editing) {
                await this.loadData()
                this.invoicesToDisplay = [ ...this.vendorsInvoices.map(invoice => ({ ...invoice, attached: false })),
                    ...this.invoice.attached_invoices.map(invoice => ({ ...invoice, attached: true }))
                ]
            } else {
                this.invoicesToDisplay = this.invoice.attached_invoices
            }
        },

        setInitiallyAttachedInvoices (): void {
            this.invoice?.attached_invoices?.map(inv => this.attachedInvoices.push(inv.id))

            this.setAttachedInvoices(this.attachedInvoices)
        },

        onAttachClick (item: Invoice): void {
            item.attached = true
            this.attachedInvoices.push(item.id)
            this.setAttachedInvoices(this.attachedInvoices)
        },

        onRemoveClick (item: Invoice): void {
            item.attached = false
            this.attachedInvoices = this.attachedInvoices.filter(invoice => {
                return invoice !== item.id
            })
            this.setAttachedInvoices(this.attachedInvoices)
        },
    }
})
