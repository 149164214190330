import { defineComponent } from 'vue'

import {
    SimpleLazyListStatusPillHeader,
    StatusPillCellCustomValueReturnType,
} from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'
import { PillSize, PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'

export default defineComponent({
    name: 'StatusPillCell',
    props: {
        item: {
            type: Object,
            required: true,
        },
        column: {
            type: Object as () => SimpleLazyListStatusPillHeader<unknown>,
            required: true,
        },
    },
    computed: {
        value (): string | StatusPillCellCustomValueReturnType<unknown> {
            if (this.column.customValue) {
                return this.column.customValue(this.item)
            }

            return this.item[this.column.value]
        },
        pillVariant (): PillVariant {
            if (this.column.customValue) {
                const pillVariant = this.column.customValue(this.item).pillVariant
                return typeof pillVariant === 'function' ? pillVariant(this.item) : pillVariant
            }

            return this.column.getPillVariant ? this.column.getPillVariant(this.item) : PillVariant.GRAY_LIGHT
        },
        pillText (): string {
            if (this.column.customValue) {
                const pillText = this.column.customValue(this.item).pillText
                return typeof pillText === 'function' ? pillText(this.item) : pillText
            }

            return this.column.getPillText ? this.column.getPillText(this.item) : ''
        },
        pillSize (): PillSize {
            if (this.column.getPillSize) {
                if (typeof this.column.getPillSize === 'function') {
                    return this.column.getPillSize(this.item)
                }

                return this.column.getPillSize
            }
        }
    },
})
