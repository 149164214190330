export default {
    model: {},
    selectedAnnotation: {},
    addingAnnotation: {},
    panelContent: 'details',
    annotationContent: 'comment',
    selectedExistingAnnotation: {},
    mentionUsers: [],
    activeComponent: 'drawing',
    annotationObject: {},
    manualAnnotation: {},
    drawingPin: {},
    selectedStamp: {},
    selectStampPopup: false,
    linksImported: false,
    selectSheetPopup: false,
    selectSheetPopupSkipDrawing: false,
    annotationAdded: false
}
