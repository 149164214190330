import { computed } from 'vue'

import i18n from '@/base/i18n'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'
import { RfiStatus } from '@/io-modules/rfis/enums/Rfi'

export type RfiStatusInfo = { [key in RfiStatus]: { text: string, pillVariant: PillVariant } }

const { t } = i18n.global

export default () => computed((): RfiStatusInfo => ({
    [RfiStatus.DRAFT]: {
        text: t('Draft'),
        pillVariant: PillVariant.BLUE_LIGHT,
    },
    [RfiStatus.SUBMITTED]: {
        text: t('Submitted'),
        pillVariant: PillVariant.YELLOW_LIGHT,
    },
    [RfiStatus.RESPONDED]: {
        text: t('Responded'),
        pillVariant: PillVariant.GREEN_LIGHT,
    },
    [RfiStatus.REVIEWED]: {
        text: t('Reviewed'),
        pillVariant: PillVariant.GREEN_LIGHT,
    },
    [RfiStatus.CONVERTED_TO_PROJECT_RFI]: {
        text: t('Converted to Project RFI'),
        pillVariant: PillVariant.GREEN_LIGHT,
    },
    [RfiStatus.DESIGN_REVIEW]: {
        text: t('Design Review'),
        pillVariant: PillVariant.BLUE_LIGHT,
    },
    [RfiStatus.RFI_CLARIFICATION]: {
        text: t('Clarify Question'),
        pillVariant: PillVariant.RED_LIGHT,
    },
    [RfiStatus.SOLUTION_CLARIFICATION]: {
        text: t('Clarify Solution'),
        pillVariant: PillVariant.RED_LIGHT,
    },
    [RfiStatus.CLOSED]: {
        text: t('Closed'),
        pillVariant: PillVariant.GRAY_LIGHT,
    },
    [RfiStatus.ARCHIVED]: {
        text: t('Archived'),
        pillVariant: PillVariant.GRAY_LIGHT,
    },
}))
