import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { find } from 'lodash'
import filterDateTimePicker from '@/components/ModalFilters/filters/filterDateTimePicker.vue'
import FilterCheckboxes from '@/components/ModalFilters/filters/filterCheckboxes.vue'
import FilterStatuses from '@/components/ModalFilters/filters/filterStatuses.vue'
import FilterMultiselect from '@/components/ModalFilters/filters/filterMultiselect.vue'
import FilterTree from '@/components/ModalFilters/filters/filterTree.vue'
import FilterColorDot from '@/components/ModalFilters/filters/filterColorDot.vue'

export default defineComponent({
    name: 'Filters',
    components: {
        filterDateTimePicker,
        FilterCheckboxes,
        FilterMultiselect,
        FilterStatuses,
        FilterTree,
        FilterColorDot
    },
    props: {
        filtersSchema: { required: true, default: null }
    },
    data () {
        return {
            isOpen: false as boolean,
            rendered: false as boolean,
            isFiltersToggled: {}
        }
    },
    computed: {
        ...mapGetters('lazyList', [
            'filters',
            'filtersSelected'
        ]),
    },
    created (): void {
        this.filtersSchema && this.filtersSchema.forEach((filter: any) => {
            const defaultType = ['radio', 'radio-pill'].includes(filter.type) ? '' : []

            this.$store.dispatch('lazyList/setSingleFilter', {
                field: filter.field,
                value: this.filtersSelected[filter.field] || defaultType
            })
        })
        this.rendered = true
    },
    methods: {
        toggleFilter (key: any): void {
            this.isFiltersToggled[key] = true
        },
        findFilterOptions (field: string): [] {
            const filtersList = find(this.filters, { 'name': field })

            if (filtersList && filtersList.options && filtersList.options.length <= 7) {
                this.isFiltersToggled[field] = true
            }
            return filtersList ? filtersList.options : []
        },
        normalizer (node: any): object {
            return {
                id: node._id,
                label: node.name,
                children: node.children
            }
        },
        pillsColorFunction (item: string): string {
            switch (item) {
                case 'Completed': case 'contract.status_executed':
                    return 'io-green'
                case 'In Progress': case 'contract.status_draft': case 'Scheduled':
                    return 'io-blue'
                case 'On Hold': case 'contract.status_requested': case 'contract.status_under_review': case 'Feasibility':
                    return 'io-yellow'
                case 'Cancelled': case 'contract.status_declined': case 'Failed':
                    return 'io-red'
                case 'contract.status_partial_signed':
                    return 'io-orange'
                default:
                    return 'io-yellow'
            }
        },
        colorDotFunction (item: string): string {
            switch (item) {
                case 'contract.sov_status_pending': case 'contract.sov_status_submitted': case 'loi.status_pending_signature': case 'Pending Signature':
                    return 'io-yellow'
                case 'loi.status_pending_client_approval': case 'Pending Client Approval':
                    return 'io-yellow'
                case 'contract.sov_status_rejected': case 'contract.sov_status_taken_by_sender': case 'loi.status_voided': case 'loi.status_rejected': case 'Voided': case 'Rejected':
                    return 'io-red'
                case 'contract.sov_status_approved': case 'loi.status_signed': case 'Signed':
                    return 'io-green'
                case 'contract.sov_status_draft': case 'Draft':
                    return 'io-blue'
                case 'contract.sov_status_na': case 'loi.status_na': case 'N/A':
                    return 'io-grey'
                default:
                    return 'io-grey'
            }
        }
    }
})
