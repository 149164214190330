export function getModel (state) {
    const model = state.model

    if (
        // regex that determine whether a user entered a North American phone number,
        // including the local area code, in a common format.
        // These formats include 1234567890, 123-456-7890, 123.456.7890, 123 456 7890, (123) 456 7890, and all related combinations
        new RegExp('^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$').test(model.res_phone)
    ) {
        model.res_phone_country = 'US'
    }
    // regex that delete every dot from number, and space before last set of digits in US phone number
    model.res_phone = model.res_phone ? model.res_phone.replace(/\.|\s+(?=[0-9]{4})/g, '') : null

    return model
}