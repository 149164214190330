<template>
    <td :class="[column.extraClasses]">
        <div
            class="io-text-cell"
            :class="[justifyClass]"
        >
            {{ $filters.filterEmptyValue(value) }}

            <StatusPill
                v-if="!!pillText"
                :text="pillText"
                :variant="pillVariant"
                :size="pillSize"
            />
        </div>
    </td>
</template>

<script lang="ts" src="./TextCell.ts"></script>
<style lang="scss" src="./TextCell.scss" scoped></style>
