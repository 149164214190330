import { defineComponent, nextTick } from 'vue'
import { mapActions } from 'vuex'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'
import RightModal from '@/components/RightModal/RightModal.vue'
import modalMixin from '@/mixins/modalMixin'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'

export default defineComponent({
    name: 'ModalFilters',
    components: {
        IOButton,
        RightModal,
        StatusPill
    },
    props: {
        countFilters: { type: Number, default: 0 },
        showClearFiltersButton: { type: Boolean, default: true }
    },
    mixins: [modalMixin],
    data () {
        return {
            escPressed: false as boolean
        }
    },
    watch: {
        countFilters: {
            handler (value: number): void {
                this.setFiltersCounter(value)
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions('filtersModal', ['setFiltersCounter']),
        clearFilters (): void {
            this.setFiltersCounter(0)
            this.$emit('clearFilters')

            nextTick(() => {
                this.apply()
            })
        },
        apply (): void {
            this.$emit('apply')
            this.close()
        },
        setEscPressed (val: boolean): void {
            this.escPressed = val
            this.$emit('closeModal')
        },
        close (): void {
            this.$emit('closeModal')
            this.closeModal()
        },
        clearChanges (): void {
            this.$emit('clearChanges')
            this.close()
        }
    }
})
