<template>
    <div>
        <IOModal
            :width="655"
            :z-index="48"
            icon="icon-cash"
            :title="headerTitle"
            @close="closeModal"
        >
            <template #default>
                <div class="io-add-capital-fund-modal">
                    <div class="io-add-capital-fund-modal__fields">
                        <FormGroup
                            :label="$t('Fund Name')"
                            :label-type="LabelType.BoldMax"
                            is-required
                            v-model="capitalFundData.name"
                        />

                        <FormInputColumn
                            :customContent="true"
                            :label="$t('Contact Person')"
                            :required="true"
                        >
                            <InvitePreview
                                v-if="createFromProject ? true : fundContactInfo"
                                :key="invitationClientKey"
                                class="io-add-capital-fund-modal__invite"
                                :componentKey="invitationClientKey"
                                :model-type="invitationClientKey"
                                :previewType="PreviewTypes.BUTTON_SECONDARY"
                                :allowedClientsTypes="[appTypes.TYPE_OWNER]"
                                :preselected-workspace-type="appTypes.TYPE_OWNER"
                                :disable-inviting="true"
                                :selectMultiple="false"
                                :modalZIndex="100"
                                :buttonLabelText="$t('Select')"
                                :categories="[InviteModalTabs.ALL, InviteModalTabs.EMPLOYEES, InviteModalTabs.CONTACTS]"
                                :allowed-companies-ids="providerCompanyId && providerCompanyId.length ? [providerCompanyId] : null"
                                :bookmarkedContactPersons="createFromProject ? [] : [fundContactInfo]"
                                :is-adding-allowed="false"
                                include-current-user-on-search-results
                                :show-single-contact-avatar="true"
                                :show-status-for-single-contact="false"
                                @inviteModalDone="onSelectProvider"
                            />
                        </FormInputColumn>
                    </div>
                    <div class="io-add-capital-fund-modal__fields">
                        <FormGroup
                            :label="$t('Account Number')"
                            :label-type="LabelType.BoldMax"
                            v-model="capitalFundData.account_number"
                        />

                        <FormInputColumn
                            :customContent="true"
                            :label="$t('Total Fund Amount')"
                            :required="true"
                        >
                            <FieldNumeric
                                :show-placeholder="false"
                                v-model="capitalFundData.amount"
                            />
                        </FormInputColumn>
                    </div>
                    <div class="io-add-capital-fund-modal__fields">
                        <FormInputColumn
                            :customContent="true"
                            :label="$t('Start Date')"
                        >
                            <FieldDateTime
                                :showIcon="true"
                                :options="dateOptions"
                                v-model="capitalFundData.start_date"
                            />
                        </FormInputColumn>

                        <FormInputColumn
                            :customContent="true"
                            :label="$t('Expiration Date')"
                        >
                            <FieldDateTime
                                :showIcon="true"
                                :options="dateOptions"
                                v-model="capitalFundData.expiration_date"
                            />
                        </FormInputColumn>
                    </div>
                </div>
            </template>
            <template #footer>
                <IOButton variant="secondary" @click="closeModal">{{ $t('Cancel') }}</IOButton>

                <IOButton v-if="!addNewCapitalFundModal.editMode" :disabled="!allowToCreate"
                          @click="createNewCapitalFund">
                    {{ $t('Create New Fund') }}
                </IOButton>

                <IOButton v-else :disabled="!allowToCreate" @click="editNewCapitalFund">{{ $t('Save') }}</IOButton>
            </template>
        </IOModal>
    </div>
</template>

<script lang="ts" src="./AddNewCapitalFundModal.ts"></script>
<style lang="scss" src="./AddNewCapitalFundModal.scss" scoped></style>
