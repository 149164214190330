import InvoiceHeader from '../enums/InvoiceHeader'
import Invoice from '../interfaces/Invoice'
import i18n from '@/base/i18n'
import CellType from '@/components/table/CellType'
import BillingDateStatus from '../enums/BillingDateStatus'
import dayjs from 'dayjs'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { TranslateResult } from 'vue-i18n'
import InvoiceStatus from '@/io-modules/invoices/enums/InvoiceStatus.ts'
import { AppPackageTableType } from '@/io-modules/invoices/enums/AppPackageTableType.ts'
import { AppPackageInvoicesHeader } from '@/io-modules/invoices/enums/AppPackageInvoicesHeader.ts'

export default function appPackagesTableHeaders (type: AppPackageTableType): HeaderTable[] {
    const defaultHeaders = [
        {
            key: InvoiceHeader.Type,
            text: i18n.global.t('Invoice type'),
            valueType: CellType.Text,
            value: type === AppPackageTableType.InvoicesToAttach ? 'invoice_type' : InvoiceHeader.Type,
            customValue: (item: Invoice): TranslateResult => {
                return i18n.global.t(item.type)
            },
        },
        {
            key: InvoiceHeader.InvoiceNumber,
            text: i18n.global.t('Generated ID'),
            value: InvoiceHeader.InvoiceNumber,
            valueType: CellType.Text,
        },
        {
            key: InvoiceHeader.UniqueId,
            text: i18n.global.t('Custom ID'),
            value: InvoiceHeader.UniqueId,
            valueType: CellType.Text,
        },
        {
            key: InvoiceHeader.InvoiceDate,
            text: i18n.global.t('Invoice date'),
            valueType: CellType.Text,
            value: InvoiceHeader.InvoiceDate,
            customValue: (item: Invoice): string => {
                return dayjs(item.invoice_date).format('DD MMM YYYY')
            },
            sortDates: true,
        },
        {
            key: InvoiceHeader.Vendor,
            text: i18n.global.t('Vendor'),
            value: InvoiceHeader.Vendor,
            valueType: CellType.Avatar,
            sort: (item) => item.name,
            extraStyles: 'min-width: 160px;',
        },
        {
            key: InvoiceHeader.Status,
            text: i18n.global.t('Status'),
            value: InvoiceHeader.Status,
            valueType: CellType.StatusPill,
        },
    ]

    if (type === AppPackageTableType.InvoicesToAttach) {
        return [
            ...defaultHeaders,
            {
                text: i18n.global.t('Gross Invoice Amount'),
                value: InvoiceHeader.GrossBilledThisPeriod,
                valueType: CellType.Text,
                sort: (item) => Number(item)
            },
            {
                text: '',
                sort: false,
                extraStyles: 'width: 120px; padding: 0',
            },
        ]
    }

    if (type === AppPackageTableType.AttachedInvoices) {
        return [
            {
                key: 'off_on_system',
                text: '',
                valueType: CellType.Image,
                customValue: (item: Invoice): object => {
                    return {
                        src: `/img/avatar-${ item.is_provided_manually ? 'gray' : 'colored' }.svg`,
                        tooltip: item.is_provided_manually ? i18n.global.t('Provided Manually') : i18n.global.t('Provided via System'),
                    }
                },
                sort: false,
            },
            ...defaultHeaders,
            {
                key: InvoiceHeader.LienWaiverStatus,
                text: i18n.global.t('Lien waiver status'),
                value: InvoiceHeader.LienWaiverStatus,
                valueType: CellType.StatusDot,
            },
            {
                key: InvoiceHeader.GrossBilledThisPeriod,
                text: i18n.global.t('Gross billed this period'),
                value: InvoiceHeader.GrossBilledThisPeriod,
                valueType: CellType.Text,
                filter: 'formatProjectCurrencyInt',
                valueAlign: 'aright',
                extraStyles: 'width: 174px;',
            },
            {
                key: InvoiceHeader.NetBilledThisPeriod,
                text: i18n.global.t('Net billed this period'),
                value: InvoiceHeader.NetBilledThisPeriod,
                valueType: CellType.Text,
                filter: 'formatProjectCurrencyInt',
                valueAlign: 'aright',
                extraStyles: 'width: 160px;',
            },
            {
                key: InvoiceHeader.BilledToDate,
                text: i18n.global.t('Billed to date'),
                value: InvoiceHeader.BilledToDate,
                valueType: CellType.Text,
                filter: 'formatProjectCurrencyInt',
                valueAlign: 'aright',
                extraStyles: 'width: 114px;',
            },
            {
                key: InvoiceHeader.BillingDate,
                text: i18n.global.t('Billing date'),
                value: InvoiceHeader.BillingDate,
                valueType: CellType.StatusDot,
                customValue: (item: Invoice): string => {
                    return item.billing_date ? dayjs(item.billing_date).format('MM/DD/YYYY') : '-'
                },
                tooltip: (item: Invoice): TranslateResult | boolean => {
                    return {
                        [BillingDateStatus.Overdue]: i18n.global.t('Billing Date that is past due'),
                        [BillingDateStatus.NoRisk]: i18n.global.t('Billing Date that is further than 5 work days away'),
                        [BillingDateStatus.NearPaymentDate]: i18n.global.t('Billing Date that is between 1 - 4 work days away'),
                    }[item.billing_date_status]
                },
                sortDates: true,
            },
        ]
    }

    if (type === AppPackageTableType.InvoicesToReject) {
        const filteredHeaders = defaultHeaders.filter(header => {
            return header.key !== InvoiceHeader.Status && header.key !== InvoiceHeader.Type
        })

        return [
            ...filteredHeaders,
            {
                key: InvoiceHeader.GrossBilledThisPeriod,
                text: i18n.global.t('Gross billed this period'),
                value: InvoiceHeader.GrossBilledThisPeriod,
                valueType: CellType.Text,
                filter: 'formatProjectCurrencyInt',
                valueAlign: 'aright',
                extraStyles: 'width: 174px;',
            },
            {
                key: InvoiceHeader.NetBilledThisPeriod,
                text: i18n.global.t('Net billed this period'),
                value: InvoiceHeader.NetBilledThisPeriod,
                valueType: CellType.Text,
                filter: 'formatProjectCurrencyInt',
                valueAlign: 'aright',
                extraStyles: 'width: 160px;',
            },
        ]
    }
}
