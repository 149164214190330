import { defineComponent } from 'vue'
import { Color } from './enums/Color'

export default defineComponent({
    name: 'ColorPicker',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        colors: {
            type: Array as () => string[],
            default: () => [
                Color.ORANGE,
                Color.GREEN,
                Color.BLUE,
                Color.RED,
                Color.PURPLE,
                Color.CYAN,
                Color.TEAL,
            ]
        }
    },
    emits: ['update:modelValue'],
})
