export enum Type {
    CONSTRUCTION = 'CONSTRUCTION',
    DRAFT = 'DRAFT'
}

export enum Status {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED'
}

export enum View {
    LIST = 'list',
    GRID = 'grid',
}
