import { defineComponent } from 'vue'
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'LazyLoad',
    props: {
        endpoint: { type: String, required: true },
        fetchRecords: { type: Boolean, default: true },
        skip: { type: Number, default: 15 },
        apiVersion: { type: Number, default: 1 },
    },
    data () {
        return {
            prevScroll: 0 as number
        }
    },
    computed: {
        ...mapGetters('lazyList', [
            'getRecords',
            'isLastPage',
            'isFetching'
        ]),
        records (): any[] {
            return this.getRecords
        }
    },
    watch: {
        records (value: any[]): void {
            this.recordsFetched(value)
        }
    },
    async beforeMount (): Promise<void> {
        await this.$store.dispatch('lazyList/setConfig', { endpoint: this.endpoint, takeItems: 15, apiVersion: this.apiVersion })
    },
    mounted (): void {
        this.init()
    },
    beforeUnmount (): void {
        const wrapper = this.$refs.lazyLoadWrapper as HTMLElement
        if (wrapper) {
            wrapper.removeEventListener('scroll', this.handleScroll)
        }
    },
    methods: {
        init (): void {
            this.setLoadingBar(true)
            try {
                this.$store.dispatch('lazyList/getListData', true)
            } catch (e) {
                this.showNotification('error', e.response.data.message)
            } finally {
                this.setLoadingBar(false)
            }

            this.$nextTick(() => {
                const wrapper = this.$refs.lazyLoadWrapper as HTMLElement
                if (wrapper) {
                    wrapper.addEventListener('scroll', this.handleScroll)
                }
            })
        },
        recordsFetched (records: any[]): any[] {
            this.$emit('onRecordsFetched', records)
            return records
        },
        handleScroll: debounce(function (this: any, event: any): void {
            if (this.isLastPage || this.isFetching) {
                return
            }

            const wrapper = this.$refs.lazyLoadWrapper as HTMLElement
            const wrapperHeight = wrapper ? wrapper.offsetHeight : 0

            const currentScroll = event.target.scrollTop
            const scrollHeight = event.target.scrollHeight

            this.prevScroll = currentScroll

            if (currentScroll < this.prevScroll) {
                return
            }

            if (currentScroll >= scrollHeight - wrapperHeight - 10) {
                this.$store.dispatch('lazyList/getListData')
            }
        }, 300)
    }
})
