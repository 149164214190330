<template>
    <td class="io-invite-members" @click.stop>
        <InvitePreview
            :key="column.additional.invitationClientKey + generateUuid()"
            :is-invite-allowed="data.can_invite_members"
            :disable-bookmarking="disableBookmarking"
            :component-key="column.additional.invitationClientKey"
            :model-type="column.additional.modelType"
            :preview-type="column.additional.invitationPreviewType"
            :resource-id="data._id"
            :filter-options="column.additional.inviteModalFilters"
            :employees-list="data.on_item"
            :users-ball-in-court="data.company_attached_employees_have_ball_in_court"
            :avatar-ring-checker="getAdditionalFunction"
            :avatar-ring-tooltip="tooltipValue"
            :invited-contact-persons="data.members"
            :bookmarked-contact-persons="bookmarkedContacts"
            :button-label-text="$t('Add, Invite, or Bookmark for later')"
            select-multiple
            save-from-modal
            @invite-modal-done="handleSaveContacts"
        />
    </td>
</template>

<script lang="ts" src="./InviteContacts.ts"></script>
<style lang="scss" src="./InviteContacts.scss" scoped></style>
