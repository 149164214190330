<template>
    <ActionDropdown v-if="!isEditingMode && invoice.id" :show-menu="false" :icon-tooltip="$t('Export to PDF')" @click="initExportToPdf" />
    <ActionDropdown
        v-if="!isEditingMode && availableDropdownActions.length"
        icon-class="icon-more-vert-2"
        :icon-tooltip="$t('More')"
    >
        <template #default>
            <a
                v-for="(action, index) in availableDropdownActions"
                :key="index"
                v-tooltip.bottom="{ content: action.tooltip, visible: !!action.tooltip }"
                :class="{ 'io-dropdown-item--disabled': action.disabled }"
                class="io-dropdown-item"
                @click="action.disabled ? null : action.onClick()"
            >
                <span v-if="action.iconClass" class="io-dropdown-item__icon" :class="[action.iconClass]"></span>
                <span>{{ action.text }}</span>
            </a>
        </template>
    </ActionDropdown>
    <IOButton v-if="isEditingMode" variant="secondary" @click="cancelClick">
        {{ $t('Cancel') }}
    </IOButton>
    <IOButton v-if="isEditingMode && !canAddressRevision && !canApplyFunding"
              variant="secondary"
              :disabled="loading"
              @click="saveAsDraft()"
    >
        {{ $t('Save as Draft') }}
    </IOButton>
    <IOButton
        v-if="InvoiceRouteName.InvoiceDetails !== $route.name && creating"
        variant="secondary"
        @click="goToPrevTab"
    >
        {{ $t('Previous Step') }}
    </IOButton>
    <IOButton
        v-if="!isLastStepActive && creating"
        :disabled="nextStepButtonDisabled"
        @click="goToNextTab"
    >
        {{ $t('Next Step') }}
    </IOButton>
    <IOButton
        v-if="canSave"
        :disabled="!isDetailsFilled"
        @click="saveAfterProvidedRevision"
    >
        {{ $t('Save') }}
    </IOButton>
    <IOButton
        v-if="(this.canApprove && this.isSubmitApproveActionPrimary && this.invoiceId === '0') && !this.isActiveReviewer"
        variant="secondary"
        :disabled="!this.allStepsCompleted || this.fundingIsNotProvided"
        @click="initMarkAsPendingInternalApproval"
    >
        {{ $t('Mark as Pending Internal Approval') }}
    </IOButton>
    <IOButton
        v-for="(button, key) in availablePrimaryActions"
        :key="`primary-action-${key}`"
        v-tooltip.bottom="{ content: button.tooltip, visible: !!button.tooltip }"
        :icon="button.icon"
        :disabled="button.disabled"
        @click="button.onClick()"
    >
        {{ button.text }}
    </IOButton>
    <InvoicesToRevertList ref="revertInvoices" :invoices="revertToDraftInvoices"/>
    <MarkAsPaidModal ref="markAsPaidModal" />

    <RequestRevisionModal
        v-if="showRequestRevisionModal"
        @requested="initRequestRevision"
        @close="toggleRequestRevisionModal"
    >
        <template v-if="isRevisionFeatureEnabled && !isSoVTab" #aboveTextarea>
            <AlertBox>
                <template #default>
                    {{ $t('You can also request revision by adjusting specific lines in the') }}
                    <IOButton variant="link" size="small" @click="goToSOV">
                        {{ $t('SoV / WBS Tab') }}
                    </IOButton>
                </template>
            </AlertBox>
        </template>
    </RequestRevisionModal>

    <VoidInvoice
        v-if="confirmVoid"
        @void="voidInvoice"
        @close="confirmVoid=false"
    />
    <RemoveCoverPageModal
        v-if="showConfirmRemoveCoverPageModal"
        @exportInvoice="exportToPdf"
        @close="showConfirmRemoveCoverPageModal=false"
    />
</template>

<script lang="ts" src="./TopBarActions.ts"></script>
<style lang="scss" src="./TopBarActions.scss" scoped></style>
