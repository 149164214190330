import { defineComponent } from 'vue'
import SingleRadioRow from '../../parts/SingleRadioRow.vue'
import ActionsView from '@/components/invite-modal/views/actions-view/ActionsView.vue'
import { mapMutations } from 'vuex'

export default defineComponent({
    name: 'ContactFound',
    components: {
        ActionsView,
        SingleRadioRow
    },
    props: {
        componentKey: { type: String, required: true },
        isAddingNewContact: { type: Boolean, required: false, default: false }
    },
    data () {
        return {
            selectedContact: null
        }
    },
    computed: {
        matchedAccountsList () {
            return this.$store.getters['inviteModal/getMatchedAccountsList'](this.componentKey)
        },
        accountsPluralization (): number {
            return this.matchedAccountsList.length === 1 ? 1 : 2
        }
    },
    watch: {
        'selectedContact' (value) {
            // @ts-ignore
            this.setSelectedContact({ key: this.componentKey, value })
        }
    },
    methods: {
        ...mapMutations('inviteModal', {
            setSelectedContact: 'SET_SELECTED_CONTACT'
        })
    }
})
