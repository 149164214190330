import { BuildingsRouteName } from './enums/BuildingsRouteName.ts'
import { SubsiteRouteName } from './enums/SubsiteRouteName.ts'
import appTypes from '@/base/appTypes.ts'
const BuildingsListing = () => import('@/io-modules/buildings/views/buildings-listing/BuildingsListing.vue')
const BuildingView = () => import('@/io-modules/buildings/views/building-view/BuildingView.vue')
const BuildingDetails = () => import('@/io-modules/buildings/views/building-details/BuildingDetails.vue')
const BuildingSubsites = () => import('@/io-modules/buildings/views/building-subsites/BuildingSubsites.vue')
const BuildingTenants = () => import('@/io-modules/buildings/views/building-tenants/BuildingTenants.vue')
const BuildingProjectSummary = () => import('@/io-modules/buildings/views/building-project-summary/BuildingProjectSummary.vue')
const BuildingProjects = () => import('@/io-modules/buildings/views/building-projects/BuildingProjects.vue')
const SubsiteDetails = () => import('@/io-modules/buildings/views/subsite-details/SubsiteDetails.vue')
const SubsiteView = () => import('@/io-modules/buildings/views/subsite-view/SubsiteView.vue')
const SubsiteProjects = () => import('@/io-modules/buildings/views/subsite-projects/SubsiteProjects.vue')
const SubsiteProjectSummary = () => import('@/io-modules/buildings/views/subsite-project-summary/SubsiteProjectSummary.vue')
const BuildingFiles = () => import('@/io-modules/buildings/views/building-files/BuildingFiles.vue')
const SubsiteFiles = () => import('@/io-modules/buildings/views/subsite-files/SubsiteFiles.vue')
const BuildingTimeline = () => import('@/io-modules/buildings/views/building-timeline/BuildingTimeline.vue')
const SubsiteTimeline = () => import('@/io-modules/buildings/views/subsite-timeline/SubsiteTimeline.vue')

const defaultMeta = {
    name: 'Buildings',
    navigationKey: 'buildings'
}

const appType = localStorage.getItem('appType')
const disabledWorkspaceTypes = [ appTypes.TYPE_GC, appTypes.TYPE_SUB, appTypes.TYPE_DESIGN ]

export default [
    {
        path: '/buildings',
        name: BuildingsRouteName.BUILDINGS,
        component: BuildingsListing,
        meta: defaultMeta,
        async beforeEnter (to, from, next) {
            if (disabledWorkspaceTypes.includes(appType)) {
                next({ name: 'dashboard' })
            }
            next()
        },
    },
    {
        path: '/buildings/:id',
        component: BuildingView,
        redirect: { name: BuildingsRouteName.BUILDINGS_DETAILS },
        async beforeEnter (to, from, next) {
            if (disabledWorkspaceTypes.includes(appType)) {
                next({ name: 'dashboard' })
            }
            next()
        },
        children: [
            {
                path: 'details',
                name: BuildingsRouteName.BUILDINGS_DETAILS,
                meta: {
                    name: 'Building Details'
                },
                component: BuildingDetails
            },
            {
                path: 'projects',
                name: BuildingsRouteName.BUILDINGS_PROJECTS,
                meta: {
                    name: 'Building Projects'
                },
                component: BuildingProjects
            },
            {
                path: 'subsites',
                name: BuildingsRouteName.BUILDINGS_SUBSITES,
                meta: {
                    name: 'Subsites'
                },
                component: BuildingSubsites,
            },
            {
                path: 'occupants',
                name: BuildingsRouteName.BUILDINGS_OCCUPANTS,
                meta: {
                    name: 'Occupants'
                },
                component: BuildingTenants
            },

            {
                path: 'project-summary',
                name: BuildingsRouteName.BUILDINGS_PROJECT_SUMMARY,
                meta: {
                    name: 'Project Summary'
                },
                component: BuildingProjectSummary
            },


            {
                path: 'files',
                name: BuildingsRouteName.BUILDINGS_FILES,
                meta: {
                    name: 'Files'
                },
                component: BuildingFiles
            },
            {
                path: 'timeline',
                name: BuildingsRouteName.BUILDINGS_TIMELINE,
                meta: {
                    name: 'Timeline'
                },
                component: BuildingTimeline
            },
        ],
    },
    {
        path: '/buildings/:id/subsites/:subsiteId',
        component: SubsiteView,
        redirect: { name: SubsiteRouteName.SUBSITE_DETAILS },
        async beforeEnter (to, from, next) {
            if (disabledWorkspaceTypes.includes(appType)) {
                next({ name: 'dashboard' })
            }
            next()
        },
        children: [
            {
                path: 'details',
                name: SubsiteRouteName.SUBSITE_DETAILS,
                meta: {
                    name: 'Subsite Details'
                },
                component: SubsiteDetails
            },
            {
                path: 'projects',
                name: SubsiteRouteName.SUBSITE_PROJECTS,
                meta: {
                    name: 'Subsite Projects',
                },
                component: SubsiteProjects,
            },
            {
                path: 'project-summary',
                name: SubsiteRouteName.SUBSITE_PROJECT_SUMMARY,
                meta: {
                    name: 'Subsite Project Summary'
                },
                component: SubsiteProjectSummary
            },
            {
                path: 'files',
                name: SubsiteRouteName.SUBSITE_FILES,
                meta : {
                    name: 'Subsite Files'
                },
                component: SubsiteFiles
            },
            {
                path: 'timeline',
                name: SubsiteRouteName.SUBSITE_TIMELINE,
                meta: {
                    name: 'Subsite Timeline'
                },
                component: SubsiteTimeline
            },
        ]
    },
]
