<template>
    <div
        ref="schedule-ticket"
        data-testid="schedule-ticket"
        :class="containerClasses"
    >
        <template v-if="editMode">
            <IOInput
                :placeholder="placeholder"
                v-model="clonedScheduleTicket.name"
                @onKeyupEnter="onKeyupEnter"
            />
            <div class="io-schedule-ticket__form-buttons">
                <IOButton icon="icon-check" variant="link" :disabled="!isValid" @click="onClickSave(false)" />
                <IOButton icon="icon-cross" variant="link" @click="onClickReject" />
            </div>
        </template>

        <template v-else>
            <div class="io-schedule-ticket__title">
                <span v-if="canEdit" class="icon-drag"></span>
                {{ scheduleTicket.name }}
            </div>

            <div v-if="canEdit" class="io-schedule-ticket__static-buttons">
                <IOButton variant="link" size="small" icon="icon-edit-3" @click="onClickEdit">{{ $t('Rename') }}</IOButton>
                <IOButton variant="link" size="small" icon="icon-trash" @click="onClickDelete">{{ $t('Delete') }}</IOButton>
            </div>
        </template>
    </div>
</template>

<script lang="ts" src="./ScheduleTicket.ts"/>
<style lang="scss" src="./ScheduleTicket.scss" scoped/>
