<template>
    <td>
        <div
            class="io-text-icon-cell"
            :class="[extraClasses]"
        >
            <div
                class="io-text-icon-cell__icon"
                :class="[iconColorClass, iconBackgroundColorClass]"
            >
                <i :class="icon"/>
            </div>

            <div class="io-text-icon-cell__text">{{ label }}</div>
        </div>
    </td>
</template>

<script lang="ts" src="./TextIconCell.ts"></script>
<style lang="scss" src="./TextIconCell.scss" scoped></style>
