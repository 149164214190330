<template>
    <div id="io-project-scope" class="io-project-scope">
        <h3>{{ $t('Project Scope') }}</h3>
        <template v-if="projectScopeCopy">
            <FieldWysiwyg
                :settings="{ readonly: 1, hideToolbar: true }"
                v-model="projectScopeCopy"
            />
        </template>
        <template v-else>
            <EmptyDescription
                :description="$t('You haven\'t set the Project Scope yet. You can add it in the Project Settings.')"
            />
        </template>
    </div>
</template>
<script lang="ts" src="./ProjectScope.ts"></script>
<style lang="scss" src="./ProjectScope.scss" scoped></style>
