import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import PopupModal from '@/components/popup-modal/PopupModal.vue'
import CustomDropdown from '@/io-modules/drawings/components/custom-dropdown/CustomDropdown.vue'
import useLoader from '@/composables/useLoader.ts'
import sheetsClient from '@/io-modules/drawings/api-clients/sheetsClient.ts'
import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'

export default defineComponent({
    components: {
        PopupModal,
        CustomDropdown,
    },
    props: {
        onSheetList: {
            type: Boolean,
            default: false,
        },
        updatingSheetVersionId: {
            type: String,
            required: false,
        },
        updatingSheetDrawingId: {
            type: String,
            required: false,
        },
    },
    setup () {
        const { load } = useLoader({ globalSpinner: true })
        return { load }
    },
    data () {
        return {
            selectedCategory: {
                value: '',
                translation: '',
            },
            updatedPageNumber: '',
            updatedPageTitle: '',
            categoriesArray: [],
        }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
        }),
        selectedCategoryLabel (): string {
            return this.selectedCategory?.translation ? this.$t(this.selectedCategory.translation) : null
        },
    },
    async beforeMount (): Promise<void> {
        if (this.onSheetList) {
            await this.getModel()
        }
        this.updatedPageNumber = this.model.drawing.page_label
        this.updatedPageTitle = this.model.drawing.page_title
        Object.entries(this.model.drawing.enum.page_category.values).forEach((category: unknown) => {
            let singleCategory = {
                value: category[0],
                translation: category[1],
            }
            this.categoriesArray.push(singleCategory)
        })
        this.selectedCategory = this.categoriesArray.find((category: unknown) => category.value === this.model.drawing.page_category) ?? null
    },
    methods: {
        async getModel (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const { data } = await setsClient.getDrawingVersion(this.updatingSheetVersionId)
                this.$store.commit('drawingMarkup/SET_MODEL', data)
            })
        },
        close (): void {
            this.$emit('close')
        },
        async save (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const id = this.onSheetList ? this.updatingSheetDrawingId : this.$route.params.sheetId as string
                const title = this.updatedPageTitle
                const postData = this.selectedCategory?.value ? {
                    id,
                    title,
                    category: this.selectedCategory?.value,
                } : { id, title }

                await sheetsClient.updateDrawingSheet(postData)
                await this.getModel()
                this.$emit('data-saved')
                this.close()
            })
        },
        categoryOptionLabel (option: { value: string, translation: string }): string {
            return this.$t(option.translation)
        },
        selectCategory (option: { value: string, translation: string }): void {
            this.selectedCategory = option
        },
    },
})
