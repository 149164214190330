/**
 * Opens a route in new background tab.
 *
 * Basic usage:
 * <tr @click.middle.exact="openInBackgroundTab({ name: 'home' })">...</tr>
 */

import { RouteLocationRaw } from 'vue-router'
import router from '@/router'

export default (): { openNewTabRoute: Function } => {
    const openNewTabRoute = (route: RouteLocationRaw): Window => window.open(router.resolve(route).href, '_blank')

    return { openNewTabRoute }
}
