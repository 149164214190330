<template>
    <ContentTwoSidesTemplate iconClass="icon-info" :title="$t('Information')">
        <template #leftSide>
            <div class="io-funding-header">
                <IOLabel size="medium" type="bold-max" :text="$t('Funding Sources')" />
                <StatusPill v-if="isEditingMode" variant="red-light" :text="$t('Required')"/>
            </div>
            <FundingSourcesTable :init="init" />
            <div v-if="isEditingMode" class="io-add-funding-source">
                <IOButton variant="link" icon="icon-plus" @click="addFundingSource"><span>{{ $t('Add Funding Source') }}</span></IOButton>
            </div>
            <hr v-if="isEditingMode" class="io-divider io-section-wrapper" />

            <template v-if="purchaseOrderIsOn && (isEditingMode || hasPurchaseOrderAssociate)">
                <div class="io-funding-header io-purchase-order">
                    <IOLabel size="medium" type="bold-max" :text="$t('Purchase Order')" />
                    <StatusPill v-if="isEditingMode && isPurchaseOrderRequired" variant="red-light" :text="$t('Required')"/>
                </div>
                <PurchaseOrderField v-if="hasPurchaseOrderAssociate" />
                <div v-if="isEditingMode && !hasPurchaseOrderAssociate" class="io-add-funding-source">
                    <IOButton variant="link" icon="icon-plus" @click="openPurchaseOrderPopup"><span>{{ $t('Add Purchase Order') }}</span></IOButton>
                </div>
            </template>

            <div class="io-section-wrapper">
                <SelectAppPackage :app-packages="draftAppPackages" v-model="invoice.application_package" />
            </div>
        </template>
        <template #rightSide>
            <ProgressBar variant="small" :items="progressBarItems" :showLegend="false" :useItemValueAsRemains="true" />
            <section class="io-funding-applied">
                <LabelText :label="$t('Funding Applied')" :text="$filters.formatProjectCurrencyInt(appliedFundingAmount)" />
                <div class="io-equality-sign" :class="{ 'io-equality-sign--equal': isEqualAppliedFundToInvoiceValue }">
                    <span :class="isEqualAppliedFundToInvoiceValue ? 'icon-equal' : 'icon-equal-slash'"></span>
                </div>
                <LabelText :label="$t('Net Invoice Value')" :text="$filters.formatProjectCurrencyInt(netInvoiceValue)" />
            </section>
            <hr class="io-divider" />
            <section>
                <IOLabel :text="$t('Funding Source')" />
                <div class="io-value">{{ $t('Select the funding source(s) that will fund the net value of this invoice. You can select multiple funding funding sources if required.') }}</div>
            </section>
            <section v-if="purchaseOrderIsOn">
                <IOLabel :text="$t('Purchase Order')" />
                <div class="io-value">{{ $t('Select the associated Purchase Order for this invoice') }}</div>
            </section>
            <section>
                <IOLabel :text="$t('Application Package')" />
                <div class="io-value">{{ $t('Assign this invoice to an existing application package if required.') }}</div>
            </section>
        </template>
        <template #rightSideActions>
            <IOLabel size="medium" type="bold" :text="$filters.formatPercent(percentsOfInvoiceApplied)" />
        </template>
    </ContentTwoSidesTemplate>
    <PurchaseOrdersPopup v-if="showPurchaseOrderPopup" @selected="onSelectedPurchaseOrder" @close="closePurchaseOrderPopup" />
</template>

<script lang="ts" src="./Funding.ts"></script>
<style lang="scss" src="./Funding.scss" scoped></style>
