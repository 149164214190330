<template>
    <td>
        <StatusPill
            :text="pillText"
            :variant="pillVariant"
            :size="pillSize"
        />
    </td>
</template>

<script lang="ts" src="./StatusPillCell.ts"></script>
