export const getRecords = (state) => {
    return state.dataList
}

export const getEditedRecords = (state) => {
    return state.editedDatalist
}

export const getEditedRecordsClone = (state) => {
    return state.editedDatalistClone
}

export const isFetching = (state) => {
    return state.isLoading
}

export const isLastPage = (state) => {
    return state.isLastPage
}

export const sortBy = (state) => {
    return state.sortBy
}

export const sortDirection = (state) => {
    return state.sortDirection
}

export const search = (state) => {
    return state.search
}

export const filters = (state) => {
    return state.filters
}

export const filtersSelected = (state) => {
    return state.filtersSelected
}

export const selectedFiltersOverload = (state) => {
    return state.selectedFiltersOverload
}

export const filtersApplied = (state) => {
    return state.appliedFilters
}

export const showFilters = (state) => {
    return state.showFilters
}

export const listCounters = (state) => {
    return state.counters
}

export const isEditMode = (state) => {
    return state.isEditMode
}

export const requestParams = (state) => {
    return state.requestParams
}

export const additionalOptions = (state) => {
    return state.additionalOptions
}

export const hasFetchedOnce = (state) => {
    return state.hasFetchedOnce
}
export const exceptionResponse = (state) => {
    return state.exception
}

export const getRowPercentage = (state) => (id) => {
    let foundItem = state.dataList.find((item) => item.id === id)

    if (foundItem && foundItem.status === 'Importing') {
        return foundItem?.percentage ?? 0
    }
}

export const getRowStatus = (state) => (id) => {
    let foundItem = state.dataList.find((item) => item.id === id)

    return foundItem?.status

}

export const hasAllRowsSelected = (state) => {
    return state.hasAllRowsSelected
}

export const getSelectedRows = (state) => {
    return state.selectedRows
}

export const isFetchAllRecords = (state) => {
    return state.isFetchAllRecords
}

export const isFrontEndSortingEnabled = (state) => {
    return state.isFrontEndSortingEnabled
}

export const selectedFiltersOverloadData = (state) => {
    return state.selectedFiltersOverload
}
