import * as actions from './actions'
import * as mutations from './mutations'

export default {
    namespaced: true,
    namespace: 'documents/',
    state () {
        return {
            checkedElements: [], // selected elements on the list view
            spaceUsage: { used: 0, total: 0, usedPercent: 0 }, // holds space usage data
            pathStack: [], // single item properties => {id: '', name: '', type: ''}
            displayRecentlyAddedFiltersIds: ['home'], // on these filters views recently added items will be displayed
            recentlyAddedLimit: 4, // maximum count of recently added items to display
            recentlyAdded: [], // items recently added
            folders: [], // folders
            files: [], // files
            projects: [], // projects
            activeFilter: null, // used in requests (in "displayFilters" object property "filter")
            activeDisplayFilterId: 'home', // used to highlight selected filter view (in "displayFilters" object property "id")
            // used for validation when setting value from route param
            displayFiltersIds: [
                // 'none', when the same filter selected set this filter?
                'home',
                'my-projects',
                'my-documents',
                'shared-with-me',
                'shared-with-others',
                'recently-changed',
                'starred',
                'deleted-files'
            ],
            // used to render possible filter views
            displayFilters: [
                { label: 'Home', id: 'home', iconClass: 'fas fa-home' },
                { label: '', id: 'separator', iconClass: '' },
                { label: 'My projects', id: 'my-projects', iconClass: 'fas fa-th-large' },
                { label: 'My documents', id: 'my-documents', filter: 'owner', iconClass: 'far fa-file' },
                { label: 'Shared with me', id: 'shared-with-me', filter: 'sharedFrom', iconClass: 'fas fa-share-square' },
                { label: 'Shared with others', id: 'shared-with-others', filter: 'sharedTo', iconClass: 'fas fa-users' },
                { label: '', id: 'separator', iconClass: '' },
                { label: 'Recently changed', id: 'recently-changed', filter: 'updated', iconClass: 'fas fa-clock' },
                { label: 'Starred', id: 'starred', filter: 'starred', iconClass: 'fas fa-star' },
                { label: '', id: 'separator', iconClass: '' },
                { label: 'Deleted files', id: 'deleted-files', filter: 'deleted', iconClass: 'far fa-trash-alt' }
            ],
            displayMode: 'list', // active display mode
            displayModes: ['grid', 'list'], // list of possible display modes
            /* context menu options */
            contextMenuItemTypes: ['file', 'folder'], // these items types have context menu enabled
            starrableItemTypes: ['file', 'folder'], // these items types can be starred or unstarred
            renamableItemTypes: ['file', 'folder'], // these items types can be renamed
            downloadableItemTypes: ['file', 'folder'], // these items types can be downloaded
            deletableItemTypes: ['file', 'folder'], // these items types can be deleted
            annotableItemTypes: ['file'], // these items types can be annotated
            moveableItemTypes: ['file', 'folder'], // these items types can be moved
            copyableItemTypes: ['file', 'folder'], // these items types can be copied
            shareableItemTypes: ['file'], // these items types can be shared ['file', 'folder', 'project']
            withMoreItemTypes: [], // these items types can have more options in context menu
            /* ... */
            enableFab: true, // fast access button (circle element with + inside)
            enableRelocateBox: false, // items relocate box (copy, move actions)
            relocateBoxMode: '', // set replocate box mode (move, copy)
            relocateBoxItem: null, // contains item to (copy or move), item could be (file or folder) ex. {itemId: '5d5d1cb19e84212241d2991b', itemTypeName: 'file'}
            searchInputValue: '', // search input value
            sortByLabel: 'Name', // sort by display name
            sortByDirection: 'ASC', // sort by direction
            // remap "sort by" Label to "sort by" key that will be recognized at back-end side
            sortByMap: {
                files: {
                    'Name': 'name',
                    'Last updated': 'updated_at',
                    'Last opened by me': 'last_opened_by_me',
                    'File size': 'file_size',
                    'Created at': 'created_at'
                },
                folders: {
                    'Name': 'name',
                    'Last updated': 'updated_at',
                    'Last opened by me': 'last_opened_by_me',
                    'File size': 'name',
                    'Created at': 'created_at'
                }
            },
            // contains flags of currently running requests
            requestsRunning: {
                projects: false,
                recent: false,
                files: false,
                folders: false
            },
            enableUploadBox: false, // show/hide upload box,
            currentPreviewDocumentId: null, // contains id of document opened to preview from the documents listing
            dateMode: 'ago', // default date format,
            inviteModalItemId: null, // element id to show invite modal for, used on listing - list view to show invite modal for single element (file),
            inProjectConfig: null // when in project details documents (needed to get narrowed data for just one project)
        }
    },
    actions,
    mutations
}