import { defineComponent } from 'vue'
import RightModal from '@/components/RightModal/RightModal.vue'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'
import { ButtonSize } from '@/components/atoms/IOButton/IOButton.ts'
import { MenuItems } from '@/io-modules/project-funding/enums/ProjectFundingEnums'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { FundTypes } from '@/io-modules/project-funding/enums/ProjectFundingEnums'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'
import SimpleFileListFromProp from '@/components/simple-file-list-from-props/SimpleFileListFromProps.vue'
import projectFundingClient from '@/io-modules/project-funding/api-clients/projectFundingClient'
import modalOnDelete from '@/io-modules/project-funding/helpers/modalOnDelete'
import ManageProjectSpecFundModal from '@/io-modules/project-funding/components/modals/manage-project-specific-fund/ManageProjectSpecFundModal.vue'
import ProjectFundingProviderFund from '@/io-modules/project-funding/interfaces/ProjectFundingProviderFund'
import { mapState } from 'vuex'
import Timeline from '@/components/timeline/timeline.vue'
import TimelineLogs from '@/interfaces/components/timeline/TimelineLogs'
import ProjectFundingTimeline from '@/io-modules/project-funding/interfaces/ProjectFundingTimeline'
import SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument'
import { mapActions as piniaMapActions } from 'pinia'
import { projectFundingStore } from '@/io-modules/project-funding/stores/projectFundingStore'
import FinancialTableCalculator from '@/components/financial-table-calculator/FinancialTableCalculator.vue'

export default defineComponent({
    name: 'FundDetailsModal',
    components: {
        FinancialTableCalculator,
        ManageProjectSpecFundModal,
        SimpleFileListFromProp,
        StatusPill,
        Avatar,
        IOSingleInfoRow,
        IOButton,
        RightModal,
        Timeline,
    },
    props: {
        fundId: {
            type: String,
        },
    },
    mixins: [modalOnDelete],
    emits: ['close', 'fundRemoved'],
    data () {
        return {
            activeTab: MenuItems.DETAILS,
            menuItemsEnum: MenuItems,
            pillVariant: PillVariant,
            fundTypes: FundTypes,
            buttonSize: ButtonSize,
            menuItems: [
                {
                    name: this.$t('Details'),
                    number: MenuItems.DETAILS,
                },
                {
                    name: this.$t('Timeline'),
                    number: MenuItems.TIMELINE,
                },
            ],
            showAllocation: false,
            showEditModal: false,
            fundDetails: null as ProjectFundingProviderFund,
            timelineHistory: [] as TimelineLogs[],
            allocationModalTitles: {
                table: 'Allocation to Project',
                allocatedSum: 'Allocation to Project'
            }
        }
    },
    computed: {
        ...mapState('project', {
            projectObj: state => state.projectObj,
        }),
        filesLines (): SingleModelDocument[] {
            return this.fundDetails.documents.map(doc => {
                return {
                    ...doc,
                    creator_full_name: `${ doc.created_by.first_name } ${ doc.created_by.last_name }`,
                    updated_at: doc.created_at,
                }
            })
        },
    },
    async beforeMount () {
        await this.getInfo()
    },
    methods: {
        ...piniaMapActions(projectFundingStore, [
            'setDataUpdation',
        ]),
        async getFundDetailsData (): Promise<void> {
            try {
                const { data } = await projectFundingClient.getProjectFundDetails(this.projectObj.project_local_id, this.fundId)
                if (data) {
                    this.fundDetails = data
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        async getFundTimeline (): Promise<void> {
            try {
                const fundTimelineResponse = await projectFundingClient.getParticularFundTimeline(this.projectObj.project_local_id, this.fundId)
                if (fundTimelineResponse && fundTimelineResponse.data) {
                    this.timelineHistory = fundTimelineResponse.data.items.map((event: ProjectFundingTimeline) => ({
                        id: event.id,
                        employee_mongo_id: event.user.id,
                        user: `${ event.user.firstname } ${ event.user.lastname }`,
                        company_name: event.user.company_name,
                        company_type: event.user.company_app_type,
                        created_at: event.created_at,
                        content: event.content.replace(/\n/g, '<br>'),
                    }))
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        async getInfo (): Promise<void> {
            await this.getFundDetailsData()
            await this.getFundTimeline()
        },
        changeActiveTab (tab: MenuItems): void {
            this.activeTab = tab
        },
        closeModal (): void {
            this.$emit('close')
        },
        toggleAllocation (): void {
            this.showAllocation = !this.showAllocation
        },
        toggleEditModal (): void {
            this.showEditModal = !this.showEditModal
        },
        goToGlobalFund (fundId: string): void {
            const routeData = this.$router.resolve({
                name: 'funding-sources-fund-details',
                params: { id: fundId },
            })
            window.open(routeData.href, '_blank', 'noopener')
        },
        removeFundModals (): void {
            if (this.fundDetails.type === this.fundTypes.CAPITAL) {
                this.showModalOnDelete(async () => {
                    await this.removeFundQuery(this.fundDetails.fund.id)

                    this.closeModal()
                })
            } else {
                this.showModalOnDeleteProjectSpecificFund(async () => {
                    await this.removeFundQuery(this.fundDetails.fund.id)

                    this.closeModal()
                })
            }
        },
        async removeFundQuery (fundId: string): Promise<void> {
            try {
                if (this.fundDetails.type === this.fundTypes.CAPITAL) {
                    await projectFundingClient.removeCapFundFromProject(this.projectObj.project_local_id, fundId)
                } else {
                    await projectFundingClient.removeFund(fundId)
                }

                this.setDataUpdation(true)
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
    },
})
