export default {
    name: 'single-notice',
    props: {
        notice: {
            type: Object,
            required: true
        }
    },
    methods: {
        dismissNotice () {
            this.$store.dispatch('notices/removeNotice', this.notice.id)
        },
        redirect () {
            if (this.notice.routeLink && this.notice.openNewTab) {
                let routeData = this.$router.resolve(this.notice.routeLink)
                var win = window.open(routeData.href, '_blank', 'noopener')
                return win
            }
            if (this.notice.routeLink) {
                this.dismissNotice()
                this.$router.push(this.notice.routeLink)
            }
        }
    }
}
