import axios from 'axios'
import { ApiClientCore } from './core'
import { ingeniousTelemetry } from '@/telemetry/provider'

function createApiClient () {
    const client = axios.create({
        withCredentials: true,
        baseURL: `${ window.location.origin }/api/v1/`,
        validateStatus: status => status >= 200 && status < 300,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest'
        }
    })

    ingeniousTelemetry.instrumentAxios(client)

    return client
}

class ApiClient extends ApiClientCore {
    constructor () {
        super()

        this.client = createApiClient()
        this.instance = this

        return this.instance
    }
}

const ApiClientInstance = new ApiClient()

Object.freeze(ApiClientInstance)

export { ApiClientInstance as ApiClient }
