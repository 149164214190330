import { defineComponent } from 'vue'
import { debounce } from 'lodash'

export default defineComponent({
    name: 'ProjectSearch',
    methods: {
        onProjectSearchChange: debounce (async function (this: any, event) {
            this.$emit('setProjectSearchValue', (<HTMLInputElement>event.target).value)
        }, 200)
    }
})
