import { isFunction } from 'lodash'

export default {
    name: 'single-alert',
    props: {
        alert: {
            type: Object,
            required: true
        }
    },
    methods: {
        dismissAlert () {
            this.$store.dispatch('notices/removeAlert', this.alert.id)
        },
        buttonClick (button) {
            if (isFunction(button.action)) {
                button.action()
            }
            if (button.closeAlert) {
                this.dismissAlert()
            }
        }
    }
}
