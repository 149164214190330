<template>
    <div class="io-select-stamp">
        <StampPreview
            :color="modelValue?.color"
            :initials="modelValue?.initials"
            :is-active="stampsIndexShown"
            size="lg"
            button-mode
            @toggle-active="toggleStampsIndex"
        />

        <Transition name="fade-from-right">
            <div
                v-if="stampsIndexShown"
                v-click-outside="toggleStampsIndex"
                class="io-select-stamp__list"
            >
                <slot :toggleStampsIndex="toggleStampsIndex" />

                <StampsIndex v-if="!hasSlotContent" @select-stamp="selectStamp" />
            </div>
        </Transition>
    </div>
</template>

<script lang="ts" src="./StampSelect.ts"></script>
<style scoped lang="scss" src="./StampSelect.scss"></style>
