<template>
	<div :id="componentId" :ref="componentId" class="page-title io-page-title" v-if="(title !== '' && title !== undefined) || buttons.length > 0">
		<div class="row justify-content-between io-f-row io-title-row">
			<div :class="{'col-auto offset-auto io-f-row' : buttons.length > 0, 'col-12 io-close-row io-f-row' : buttons.length === 0}">
                <template v-if="!disabledHeader">
                    <div class="io-close-modal">
                        <i class="fas fa-times"  @click="closeModal()"></i>
                    </div>
                    <h1 class="fleft io-modal-header" ref="pageTitle">
                        {{title}}
                    </h1>
                </template>

                <template v-if="hasBreadcrumbs() && !disabledHeader">
                    <h1 class="fleft" ref="pageTitle">
                        <template v-for="(breadcrumb, index) in breadcrumbs" :key="index">
                            <router-link v-if="breadcrumb.url !== undefined" :to="breadcrumb.url">{{breadcrumb.name}}</router-link>
                            <span v-else>{{breadcrumb.name}}</span>
                            <span v-if="index !== breadcrumbs.length - 1"> - </span>
                        </template>
                    </h1>
                </template>

				<ul id="tabs-nav" class="io-tab-nav" v-if="tabs.length > 1 || Object.keys(tabs).length > 1">
					<template v-for="(tab, index) in tabs" :key="index">
                        <li :class="{ active : instance.activeTab == index }" v-if="isTabVisible(tab)">
                            <a @click="instance.onTabSwitch, instance.activeTab = index"
                               v-if="tabAllowed(tab)"
                               :class="{ active : instance.activeTab == index }">
                                {{ $t(tab.title) }}
                            </a>
                        </li>
                    </template>
				</ul>
			</div>
			<div v-if="buttons.length > 0" class="col-auto io-f-row io-btns-row">
				<template v-for="(button, index) in buttons" :key="index">

					<template v-if="button.isHint != true && button.fileLoader != true && button.fancybox === true">
                        <template v-if="button.fancybox === true && button.fancyboxVue !== true">
                            <IOButton
                                :key="index"
                                @click="$router.push(button.url)"
                                :icon="`fa ${button.icon}`"
                                :class="setButtonClass(button)"
                            >
                                {{button.name}}
                            </IOButton>
                        </template>
                        <template v-else-if="button.fancybox === true && button.fancyboxVue === true">
                            <IOButton
                                :key="index"
                                :class="setButtonClass(button)"
                                :icon="`fa ${button.icon}`"
                                @click="openModal(button.fancyboxVar)"
                            >
                                {{button.name}}
                            </IOButton>
                        </template>
					</template>


					<template v-if="button.fileLoader === true">
                        <IOButton
                            :key="index"
                            :class="setButtonClass(button)"
                            :icon="`fa ${button.icon}`"
                            @click="openFileUpload"
                        >
                            {{button.name}}
                        </IOButton>
						<input id="fileInput" ref="fileInput" type="file" style="display:none" v-on:change="handleFileUpload()" />
					</template>

					<template v-if="button.isHint === true && button.fileLoader != true && button.fancybox != true">
                        <Tooltip :htmlContent="button.hint" />
					</template>

					<template
							v-if="button.isHint != true && button.fileLoader != true && button.fancybox != true && fieldVisible(button.visible)">
						<template v-if="button.load === undefined">
                            <IOButton
                                :key="index"
                                :class="setButtonClass(button)"
                                :icon="`fa ${button.icon}`"
                                @click="$router.push(button.url)"
                            >
                                {{buttonName(button.name)}}
                            </IOButton>
						</template>
						<template v-else-if="button.load === 'vue'">
                            <IOButton
                                :key="index"
                                :class="setButtonClass(button)"
                                :icon="`fa ${button.icon}`"
                                @click="$parent[button.onSubmitName]()"
                            >
                                {{buttonName(button.name)}}
                            </IOButton>
						</template>
						<template v-else-if="button.load === 'php-application'">
							<a :key="index"
							   :href="button.url"
							   :class="setButtonClass(button)">
								<i class="fa" :class="button.icon"></i>{{buttonName(button.name)}}
							</a>
						</template>

					</template>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
    import isLoggedMixin from '@/mixins/isLoggedMixin'
    import { getCurrentInstance } from 'vue'
    import { isUndefined, isObject } from 'lodash'

    export default {
        data () {
            return {
                foo: '',
                instanceProxy: getCurrentInstance().proxy
            }
        },
        mixins: [isLoggedMixin],
        props: {
            headlineType: {
                type: String,
                required: false
            },
            disabledHeader: {
                type: Boolean,
                required: false,
                default: false
            },
            title: {
                type: String,
                required: true
            },
            componentId: {
                type: String,
                required: false,
                default: 'pageTitle'
            },
            breadcrumbs: {
                type: Array,
                required: false
            },
            schemaSlug: {
                type: String,
                required: false
            },
            buttons: {
                type: Array,
                default: function () {
                    return []
                }
            },
            tabs: {
                type: Array,
                default: function () {
                    return []
                }
            },
            record: {}
        },
        computed: {
           instance () {
               return (typeof this.instanceProxy.$parent.activeTab !== 'undefined') ? this.instanceProxy.$parent : this.instanceProxy.$parent.$parent
           },
        },
        methods: {
            isTabVisible (tab) {
                if (isUndefined(tab.visible)) {
                    return true
                } else {
                    return tab.visible
                }
            },
            hasBreadcrumbs () {
                return (this.breadcrumbs !== undefined && this.breadcrumbs.length !== undefined && this.breadcrumbs.length)
            },
            openFileUpload () {
                this.$refs.fileInput[0].click()
            },
            handleFileUpload () {
                if (typeof this.$parent.handleFileUpload === 'function') {
                    this.$parent.handleFileUpload()
                }
            },
            tabAllowed: function (tab) {
                if (typeof this.instance.allowedToShow === 'function') {
                    return this.instance.allowedToShow(tab.ref)
                } else {
                    return true
                }
            },
            setButtonClass: function (button) {
                return button.fancybox && !button.fancyboxVue ? 'vuefancybox' : ''
            },
            openModal (modal) {
                this.$store.dispatch('modal/setShow', modal, {root: true})
                if (this.$parent.forcedId !== undefined) {
                    this.$parent.forcedId = 0
                }
            },
            closeModal () {
                this.$store.dispatch('modal/setShow', false, {root: true})
            },
            buttonName: function (button) {
                if (isObject(button)) {
                    return button[this.getCurrentApp()]
                } else {
                    return button
                }
            },
            fieldVisible: function (visible) {
                if (visible === false) {
                    return visible
                } else {
                    return true
                }
            },
            prepareButtons: function (node) {
                node.buttons.forEach((btn, idx) => {
                    if (btn.forward === true) {
                        var routerParams = this.$route.params
                        var params = []
                        if (btn.params !== undefined) {
                            for (let i = 0; i < btn.params.length; i++) {
                                let foundVal = ''

                                Object.keys(routerParams).forEach(function (key) {
                                    if (key === btn.params[i]) {
                                        foundVal = routerParams[key]
                                    }
                                })

                                if (foundVal !== '') {
                                    var obj = {
                                        key: btn.params[i],
                                        value: foundVal
                                    }
                                    params.push(obj)
                                }
                            }
                        }
                        var back = '/back/' + window.btoa(JSON.stringify((params)))
                        node.buttons[idx].url = node.buttons[idx].base + back
                    }
                })
            }
        },
        watch: {
            $route (to, from) {
                this.prepareButtons(this)
            }
        },
        mounted: function () {
            this.prepareButtons(this)
        }
    }
</script>

<style lang="scss" scoped src="./PageTitle.scss"></style>
