<template>
    <AppPackageCreateEdit v-if="isEditingMode" />

    <div v-if="!isEditingMode && '0' != $route.params.id" class="io-app-package-details-info">
        <div
            v-if="attachedInvoices && numberOfInvoices && isDraft"
            class="io-line-progress"
        >
            <p class="io-line-progress__title">
                <span>{{ attachedInvoices.number_of_invoices_internally_approved }} / {{ attachedInvoices.number_of_invoices }}</span>
                {{ $t('Invoices Internally Approved') }}
            </p>

            <ProgressBar
                variant="small"
                :items="progressBarData"
                :showLegend="false"
            />
        </div>
        <DetailsSection />
        <FinancialPanel />
        <AttachedInvoicesTable/>
    </div>
</template>

<script lang="ts" src="./AppPackageDetailsView.ts"></script>
<style lang="scss" src="./AppPackageDetailsView.scss" scoped></style>
