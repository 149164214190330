import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash'
import jobsiteLocationDefaultState from '@/components/jobsite-locations/store/jobsiteLocationDefaultState.ts'
import { JobsiteLocationsStoreInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsStoreInterface.ts'
import jobsiteLocationsClient from '@/components/jobsite-locations/api-clients/jobsiteLocationsClient.ts'

export const jobsiteLocationsStore = defineStore('jobsiteLocations', {
    state: (): JobsiteLocationsStoreInterface => cloneDeep(jobsiteLocationDefaultState),
    actions: {
        async fetchJobsiteLocations (projectId: string): Promise<void> {
            try {
                const { data } = await jobsiteLocationsClient.fetchJobsiteLocations(projectId)

                this.jobsiteLocations = data
            } catch (exception) {
                throw exception
            }
        },
        setSelectedLocationsIds (data: string[]): void {
            this.selectedJobsiteLocationsIds = cloneDeep(data)
        },
        addSelectedLocationId (id: string): void {
            this.selectedJobsiteLocationsIds.push(id)
        },
        removeSelectedLocationId (id: string): void {
            this.selectedJobsiteLocationsIds = this.selectedJobsiteLocationsIds.filter((locationId: string) => locationId !== id)
        },
        addSelectedTreeLocationId (id: string): void {
            if (!this.selectedTreeJobsiteLocationsIds.includes(id)) {
                this.selectedTreeJobsiteLocationsIds.push(id)
            }
        },
        setSelectedTreeLocationsIds (value: string[]): void {
            this.selectedTreeJobsiteLocationsIds = value
        },
        clearSelectedTreeLocationsIds (): void {
            this.setSelectedTreeLocationsIds([])
        },
    },
})
