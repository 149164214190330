import { defineComponent } from 'vue'
import { LienWaiver, StatusLienWaiver, StatusRequest, StatusStyle } from "@/interfaces/modules/projects/lien-waiver"
import { PayAppStatus } from '@/interfaces/modules/projects/pay-apps/PayAppStatus'
import { mapActions, mapState } from 'vuex';


interface Invoice {
    invoice_id: string,
    is_off_system_invoice: boolean,
    status: string,
    _id: string
}

const lienWaiverMixin = defineComponent({

    computed : {
        ...mapState('payapps', {
            payApp: (state: any) => state.payApp
        }),

        isShared (): boolean {
            return !this.payApp.flags.is_sender && !this.payApp.flags.is_receiver
        }
    },
    methods: {

        ...mapActions('lienWaiverSettings', ['loadLienWaiverSettings']),

        downloadDocumentMixin (documentId: string): void {
            let link = document.createElement('a')
            link.setAttribute('type', 'hidden')
            document.body.appendChild(link)
            link.href = this.getUrlDocumentMixin(documentId)
            link.click()
            document.body.removeChild(link)
        },

        getUrlDocumentMixin (documentId: string): string {
            let downloadLink = this.$api.getApi2RequestUrl(`/documents/download`)
            const files = [documentId]
            const filesQuery = files.map((value, index) => `files[${index}]=${value}`).join('&')
            return downloadLink += (`?${filesQuery}`)
        },

        viewDocumentMixin (documentId: string): void {
            this.getUrlDocumentByIdMixin(documentId).then((documentPreviewUrl: string) => window.open(documentPreviewUrl, '_blank', 'noopener'))
        },

        async getUrlDocumentByIdMixin (documentId: string): Promise<string> {
            return this.$api.getApi2RequestUrl(`/document/${ documentId }/preview?timestamp=${ (new Date()).getTime() }`)
        },

        generateStatusStyleMixin (status: StatusRequest | StatusLienWaiver): StatusStyle {
            const styles = {
                [StatusRequest.Requested]: StatusStyle.YellowLight,
                [StatusRequest.RequestSent]: StatusStyle.BlueLight,
                [StatusRequest.Draft]: StatusStyle.BlueLight,
                [StatusRequest.NotReceived]: StatusStyle.RedLight,
                [StatusLienWaiver.Sent]: StatusStyle.BlueLight,
                [StatusLienWaiver.Review]: StatusStyle.YellowLight,
                [StatusLienWaiver.RevisionRequest]: StatusStyle.BlueLight,
                [StatusLienWaiver.RequestRevision]: StatusStyle.YellowLight,
                [StatusLienWaiver.Approved]: StatusStyle.GreenLight,
                [StatusLienWaiver.Attached]: StatusStyle.GreenLight,
                'default': StatusStyle.GrayLight
            }
            return styles[status] || styles['default']
        },

        defineStatusToDisplayMixin (isSenderCreator: boolean, isOffSystem: boolean, status: StatusRequest | StatusLienWaiver): StatusRequest | StatusLienWaiver {

            const vendorStatuses = {
                [StatusLienWaiver.Sent]: StatusLienWaiver.Review,
                [StatusRequest.Requested]: StatusRequest.RequestSent,
                [StatusRequest.Processing]: StatusRequest.RequestSent,
                [StatusLienWaiver.UnderReview]: StatusLienWaiver.RevisionRequest,
                'default': status
            }

            const receiverStatutes = {
                [StatusLienWaiver.UnderReview]: StatusLienWaiver.RequestRevision,
                'default': status
            }

            if (isOffSystem) {
                return vendorStatuses[status] || vendorStatuses['default']
            }

            return isSenderCreator ? (receiverStatutes[status] || receiverStatutes['default']) : (vendorStatuses[status] || vendorStatuses['default'])
        },

        getPayAppIdMixin (): string {
            return this.$route.params.id
        },

        isSentInvoiceStatusMixin (invoice: Invoice): Boolean {
            if (invoice.is_off_system_invoice) {
                return false
            }

            return !!invoice && PayAppStatus.DRAFT !== invoice.status
        },

        getFinallyStatusMixin (invoice: Invoice): StatusLienWaiver {

            if (invoice.is_off_system_invoice) {
                return StatusLienWaiver.Sent
            }

            return this.isSentInvoiceStatusMixin(invoice)
                    ? StatusLienWaiver.Sent
                    : StatusLienWaiver.Attached
        },

        buildFinalDataOfLienWaiverMixin (lienWaiver, IsDocumentSigned: undefined | Blob ): LienWaiver {
            return {
                'pay_app_id': lienWaiver.pay_app_id,
                'name': lienWaiver.name,
                'amount': lienWaiver.amount,
                'lien_waiver_type': lienWaiver.lien_waiver_type,
                'payment_type': lienWaiver.payment_type,
                'lien_waiver_request_id': lienWaiver.lien_waiver_request_id,
                'lien_waiver_template_id': lienWaiver.lien_waiver_template_id,
                'format': lienWaiver.format,
                'status': this.getFinallyStatusMixin(this.lienWaiver.invoice),
                'content': lienWaiver.content,
                'is_signed': lienWaiver.is_signed,
                'is_document_signed': IsDocumentSigned
            }
        },

        async getLienWaiverSettingsMixin () {
            try {
                if (this.isShared) {
                    return
                }
                await this.loadLienWaiverSettings(this.$route.params.id)
            } catch (exception) {}
        }
    }
})

export default lienWaiverMixin
