import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource'
import PopupMixin from '@/mixins/financial-approvals/PopupMixin'
import PhaseType from '@/interfaces/modules/projects/financial-approvals/PhaseType'
import PhaseTypeRadio from '@/components/financial-approvals/controls/phase-type-radio/PhaseTypeRadio'
import FeatureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import IOSwitcher from '@/components/atoms/IOSwitcher/IOSwitcher'
import financialApprovalsClient from '@/api/financial-approvals/financialApprovalsClient'
import SubmitToClientConfirmationService
    from '@/io-modules/approval-workflows/services/SubmitToClientConfirmationService'
import { TranslateResult } from 'vue-i18n'
import FilesComponent from '@/components/files-new/files.vue'

export default defineComponent({
    name: 'PopupManualApproval',
    components: {
        IOSwitcher,
        PhaseTypeRadio,
        FilesComponent,
    },
    props: {
        fullScreen: { type: Boolean, default: false },
        width: { type: Number, default: 650 },
        canClientApprove: { type: Boolean, default: false },
        isNewVersion: { type: Boolean, default: false }
    },
    mixins: [PopupMixin, FeatureFlagsMixin],
    data () {
        return {
            note: '' as string,
            confirmText: '' as string,
            type: undefined as PhaseType,
            name: '' as string,
            filesIds: [] as string[],
        }
    },
    computed: {
        ...mapGetters('projectBudget', {
            budgetType: 'budgetTypeValue',
            isOurBudget: 'isOurBudget',
        }),
        filesArrayId (): string {
            return `manual_approval_${this.resourceId}`
        },
        descriptionText (): TranslateResult | null {
            switch (this.activeResource) {
                case Resource.BUDGET_APPROVAL:
                    return this.$t(
                        '{action} this Budget will finalize and lock the budget from further modifications. Any subsequent changes must be made via the ‘Budget Change’ screen.',
                        { action: this.approveAdjectiveText }
                    )
                case Resource.INVOICES:
                    return this.$t(
                        '{action} this Invoice will finalize the approval process and allow you to mark it as paid.',
                        { action: this.approveAdjectiveText }
                    )
            case Resource.CONTRACTS:
                    return this.$t(
                        'By clicking {action} you are confirming that the information that you have entered is correct and matches the executed document you have uploaded.',
                        { action: this.approveTitleText.toLowerCase() }
                    )
                default:
                    return null
            }
        },
        snapshotData (): object {
            switch (this.activeResource) {
                case Resource.BUDGET_APPROVAL:
                    return {
                        budget_type: this.budgetType,
                    }
                default:
                    return {}
            }
        },
        isValid (): boolean {
            const isConfirmTextValid = 'YES' === this.confirmText || this.$t('YES') === this.confirmText
            const isNoteValid = this.note.length >= 3

            if (Resource.BUDGET_APPROVAL === this.activeResource && this.showPhasesControls) {
                return isConfirmTextValid && Boolean(this.type) && Boolean(this.name) && isNoteValid
            }

            return isConfirmTextValid && isNoteValid
        },
        showPhasesControls (): boolean {
            return Resource.BUDGET_APPROVAL === this.activeResource && this.isOurBudget
        },
    },
    methods: {
        async sendManualApproval (): Promise<void> {
            await this.$api.realJson('/resource-manual-approval', {
                approvable_resource_name: this.activeResource,
                resource_id: this.resourceId,
                note: this.note,
                approval_type: this.approvalType,
                note_visibility: this.noteVisibility,
                temporary_attached_documents: this.filesIds,
                ...({
                        type: this.type,
                        name: this.name,
                        snapshot_data: this.snapshotData,
                    }
                ),
                ...(this.canClientApprove
                        ? { external: true }
                        : {}
                ),
            })
        },
        async approve (): Promise<void> {
            try {
                this.loading = true

                await this.sendManualApproval()

                this.$emit('approved')
                this.closePopup()
                this.showNotification(
                    'success',
                    this.$t('{resourceName} has been approved.', { resourceName: this.$t(this.resourceName) })
                )
                this.refreshResources()
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.loading = false
            }
        },

        async approveAndSubmitToClient (): Promise<void> {
            try {
                await this.sendManualApproval()

                this.showPopupAlert(
                    SubmitToClientConfirmationService.getPopupAlertData(
                        this.activeResource,
                        async (): Promise<void> => {
                            await financialApprovalsClient.createExternalClientApproval(
                                this.activeResource,
                                this.currentResourceId
                            )

                            this.refreshResources()
                        },
                        this.refreshResources,
                    )
                )
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }

            this.closePopup()
        },
        onFileAdd (file: any): void {
            this.filesIds.push(file._id)
        },
        onFileRemove (fileId: string): void {
            this.filesIds = this.filesIds.filter((id: string) => id !== fileId)
        },
    },
    beforeMount (): void {
        this.$store.dispatch('filesComponent/setMyDocs', {
            arrayId: this.filesArrayId,
            files: []
        })
    },
})
