export enum SoVLineItemField {
    Id = 'id',
    Name = 'name',
    BalanceToComplete = 'balance_to_complete',
    ChangeOrderId = 'change_order_id',
    ChangeOrderName = 'change_order_name',
    ChangeOrderNumber = 'change_order_number',
    ContractWBSId = 'contract_wbs_id',
    CurrentApplication = 'current_application',
    CurrentRetentionPercent = 'current_retention_percent',
    CurrentRetentionAmount = 'current_retention_amount',
    MaterialsStored = 'materials_stored',
    MaterialsStoredRetention = 'materials_stored_retention',
    PercentOfCompletedWork = 'percent_of_completed_work',
    PreviousApplied = 'previous_applications',
    PreviousRetentionAmount = 'previous_retention_amount',
    Retention = 'retention',
    RetentionReleased = 'retention_released',
    ScheduleValue = 'scheduled_value',
    PhaseId = 'phase_id',
    QuotedItemId = 'quoted_item_id',
    NetCurrentApplication = 'net_current_application',
    AdjustmentsValue = 'adjustments_value',
    Adjustments = 'adjustments',
    AdjustmentsHistory = 'adjustments_history',
    TotalRetentionAmount = 'total_retention_amount',
    TotalRetentionPercent = 'total_retention_percent',
}

export default SoVLineItemField
