import { ApiClient as client } from '../api'

export default {
    getLocations (projectID) {
        return client.get(`/jobsite-location/${projectID}/locations`)
    },
    updateLocation (projectID, location) {
        return client.patch(`/jobsite-location/${projectID}/locations/${location.id}`, {
            title: location.title
        })
    }
}
