import Resource from '../enums/Resource'

export default class ApprovalWorkflowsRouterService {
    static getProjectSettingsRouteName (resourceType: Resource): string {
        return {
            [Resource.MyBudgetPhase]: 'project-settings-financial-approvals-budget-approval',
            [Resource.OwnerBudgetPhase]: 'project-settings-financial-approvals-budget-approval',
            [Resource.BUDGET_APPROVAL]: 'project-settings-financial-approvals-budget-approval',
            [Resource.BUDGET_CHANGES]: 'project-settings-financial-approvals-budget-changes',
            [Resource.OwnerBudgetChange]: 'project-settings-financial-approvals-budget-changes',
            [Resource.MyBudgetChange]: 'project-settings-financial-approvals-budget-changes',
            [Resource.CONTRACT]: 'project-settings-financial-approvals-contracts',
            [Resource.ORIGINAL_CONTRACT]: 'project-settings-financial-approvals-contracts',
            [Resource.INITIAL_CONTRACT]: 'project-settings-financial-approvals-initial-contracts',
            [Resource.CHANGE_ORDER]: 'project-settings-financial-approvals-change-orders',
            [Resource.AMENDMENT]: 'project-settings-financial-approvals-amendments',
            [Resource.INVOICE]: 'project-settings-financial-approvals-invoices-edit',
        }[resourceType]
    }
}
