import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Archive',
    props: {
        data: { type: Object, required: true },
        column: { type: Object, required: true },
        columnClass: { type: String, required: false, default: null }
    },
    computed: {
        tooltip (): string | undefined {
            return this.column?.additional?.tooltip
        },
        iconName (): string | undefined {
            return this.column?.additional?.iconName
        },
        additionalIconName (): string | undefined {
            return this.column?.additional?.additionalIconName
        },
        iconClass (): string | undefined {
            return this.data.invitation_status === 'Archived' || this.data.is_archived
                ? this.additionalIconName
                : this.iconName
        },
        cellClass (): string | undefined {
            let className = this.column?.columnClass

            if (this.data[this.column.field]) {
                className = `${className} io-active`
            }

            return className
        },
        disableAction (): boolean {
            if (this.column.additional && this.column.additional.disableActionValue) {
                return this.data[this.column.additional.disableActionField] === this.column.additional.disableActionValue
            }
            return false
        }
    },
    methods: {
        emitAction (e: Event): void {
            e.preventDefault()

            this.$emit(
                'emitIconAction',
                {
                    field: this.column.field,
                    id: this.column?.additional?.id
                        ? this.data[this.column.additional.id]
                        : this.data.contact_person_id
                })
        },
        isHidden (): boolean {
            const hiddenField = this.column?.additional?.hidden

            if (hiddenField) {
                // @ts-ignore
                return this.data[this.column?.additional?.hidden]
            }

            return false
        }
    }
})
