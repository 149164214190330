import type { BidRequestQuotedItem, ProposalSection, Quote } from '../interfaces'

export function computeMainCost (bid: Quote, phasesMain: BidRequestQuotedItem[]): number {
    return phasesMain.reduce((acc, phase) => add([acc, getPhasePrice(bid, phase)]), 0)
}

export function computeAlternateCost (bid: Quote, phasesAlternate: BidRequestQuotedItem[]): number {
    return phasesAlternate
        .filter((phase) => phase.enabled)
        .reduce((acc, phase) => add([acc, getPhasePrice(bid, phase)]), 0)
}

export function getPhasePrice (bid: Quote, phase: BidRequestQuotedItem): number {
    if (!bid.quote || !bid.quote?.sections) {
        return 0
    }

    if (bid.is_manual) {
        return getManualPhasePrice(bid, phase)
    }

    return getNotManualPhasePrice(bid, phase)
}

function getNotManualPhasePrice (bid: Quote, phase: BidRequestQuotedItem): number {
    if (!bid.quote?.quoted_items || !Array.isArray(bid.quote?.quoted_items[phase.uuid])) {
        return 0
    }

    return bid.quote?.quoted_items[phase.uuid].reduce(
        (acc, sectionBlock) => add([acc, getSectionsBlockPrice(bid, sectionBlock)]),
        0
    )
}

function getManualPhasePrice (bid: Quote, phase: BidRequestQuotedItem): number {
    const section = bid.quote?.sections?.find((section: ProposalSection) => section.phase === phase.uuid)

    if (!section) {
        return 0
    }

    return parseFloat(section.price) || 0
}

export function getSectionsBlockPrice (bid: Quote, phases: any): number {
    return phases.enabled ? getSectionPrice(bid, phases.enabled) : getSectionPrice(bid, phases.sections[0])
}

export function getSectionPrice (bid: Quote, id: string): number {
    const hasValidQuote = bid.quote?.sections_prices?.[id]

    return hasValidQuote ? bid.quote.sections_prices[id] : 0
}

function add (values: number[], precision: number = 2): number {
    const total = values.reduce((previousValue, currentValue) => {
        return (Number(previousValue) || 0) + (Number(currentValue) || 0)
    })
    return cutPrecision(total, precision)
}

function cutPrecision (value: number, precision: number = 2): number {
    value = Number(value) || 0
    if (Number.isInteger(value)) { return value }

    precision = Math.pow(10, precision)
    return Math.sign(value) * Math.round(Math.abs(value) * precision) / precision
}
