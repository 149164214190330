<template>
    <div
        v-if="resourceApprovals && !hideApprovalWorkflow"
        class="io-approvals-list"
    >
        <template v-if="showApprovalWorkflows">
            <template v-for="(workflow) in resourceApprovals.workflows" :key="workflow.id">
                <ApprovalWorkflow
                    :total="total"
                    :workflow-data="workflow"
                    :can-approve-or-reject="true"
                    :is-total-visible="isTotalVisible"
                    :custom-title="customTitle"
                    :resourceApprovals="resourceApprovals"
                    @approverApproveSelected="data => $emit('approverApproveSelected', data)"
                    @approverRejectSelected="data => $emit('approverRejectSelected', data)"
                    @approverReviewSelected="data => $emit('approverReviewSelected', data)"
                    @approverOverridden="$emit('approverOverridden')"
                />
            </template>
        </template>

        <Placeholder
            v-else
            icon="icon-thumbs-up"
            :buttonsCta="buttonsCta"
            :title="$t('No Approval Workflows Established')"
            :subtitle="$t('You have not yet created any approval workflows for this module within this project. Please select the option below to begin using Approval Workflows.')"
            @addWorkflow="redirectToProjectSettings"
        />
    </div>

    <Placeholder
        v-if="hideApprovalWorkflow"
        icon="icon-timer"
        :title="$t('Your approval is not required at this moment')"
    />
</template>

<script lang="ts" src="./ResourceApprovals.ts"></script>
<style lang="scss" src="./ResourceApprovals.scss" scoped></style>
