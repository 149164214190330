import { defineComponent } from 'vue'

export default defineComponent({
    name: 'DismissibleBanner',
    props: {
        header: {
            type: String
        },
        headerIcon: {
            type: String
        },
        text: {
            type: Array
        },
        sessionKey: {
            type: String
        }
    },
    data () {
        return {
            makeDismissible: false,
            showText: false,
            disabled: false
        }
    },
    computed: {
        disabledPopups (): Array<string> {
            return JSON.parse(sessionStorage.getItem('disabledPopups') ?? '[]')
        },
        isCurrentPopupDisabled (): boolean {
            return this.disabledPopups.includes(this.sessionKey)
        }
    },
    created () {
        this.disabled = this.isCurrentPopupDisabled
    },
    methods: {
        toggleText (value: boolean): void {
            if (value === false && this.makeDismissible === true) {
                this.disableInSession()
            }
            this.showText = value
        },
        disableInSession (): void {
            if (this.sessionKey) {
                this.disabledPopups.push(this.sessionKey)
                sessionStorage.setItem('disabledPopups', JSON.stringify(this.disabledPopups))
            }
        }
    }
})
