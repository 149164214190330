<template>
    <div v-if="providerInfo" class="io-provider-details">
        <PageHeader :title="providerName" :route-back="{ name: 'funding-sources-providers' }">
            <template #actions>
                <ActionDropdown :show-menu="false" :icon-tooltip="$t('Export to PDF')" @click="exportPdf()" />

                <IOButton
                    icon="icon-plus"
                    @click="toggleShowNewFundModal"
                >
                    {{ $t('Create New Program Fund') }}
                </IOButton>

            </template>
        </PageHeader>
        <div class="io-provider-details__content">
            <FundingSourcesSummary :provider-id="providerId" :summary="providerSummary"/>

            <div class="io-provider-details__menu">
                <div class="io-provider-details__tabs io-section-tabs">
                    <template v-for="tab in menuItems" :key="tab.name">
                        <div
                            class="io-single-tab"
                            :class="{'io-is-active': activeTab === tab.number}"
                            @click.prevent="changeActiveTab(tab.number)"
                        >
                            {{ tab.name }}
                        </div>
                    </template>
                </div>

                <Avatar
                    type="company"
                    :employeeMongoId="providerInfo.logo"
                    :employeeFullName="providerName"
                    showFullName
                />
            </div>

            <TabCapitalFunds v-if="activeTab === menuItemsEnum.CAPITAL_FUNDS" :provider-id="providerId"/>
            <TabProjectSpecificFunds v-else :provider-id="providerId"/>

        </div>
        <AddNewCapitalFundModal v-if="addNewCapitalFundModal.show"
                                :provider-company-id="providerInfo.company_id"
                                :provider-id="providerId"
                                :contact-info="providerInfo.person"
                                @close="toggleShowNewFundModal"
        />
    </div>
</template>

<script lang="ts" src="./ProviderDetails.ts"></script>
<style lang="scss" src="./ProviderDetails.scss" scoped></style>
