<template>
    <CustomMultiselect
        v-model="value"
        :addNewBtnDisabled="false"
        :addingNewInProcess="false"
        :disabled="disableMultiselect"
        :options="employeeMultiselectOptions"
        :optionsTitle="optionsTitle"
        :settings="multiselectSettings"
        :statusEnable="false"
    >
        <template #header="{ activeOption, config }">
            <div v-if="activeOption" class="io-multiselect__name">
                <Initials
                    class="io-single-option__logo"
                    :bigAvatar="true"
                    :fullName="activeOption.name"
                />
                {{ activeOption.name }}
            </div>

            <div v-else v-tooltip.bottom="{ html: 'selector-tooltip', visible: disableMultiselect }" class="io-multiselect__name io-placeholder"><span class="io-avatars"><i class="fas fa-plus"></i></span>{{ config.textPlaceholder }}</div>
            <div v-if="!activeOption" id="selector-tooltip">
                {{ 'Please assign a project team member to this role in' }}
                <RouterLink :to="{ name: 'project-directory' }" target="_blank">{{ $t('Project Directory') }}</RouterLink>
            </div>
        </template>

        <template #option="{ option }">
            <template v-if="!option.$isLabel">
                <Initials
                    class="io-single-option__logo"
                    :bigAvatar="true"
                    :fullName="option.name"
                />
                <div class="io-single-option__text-wrapper">
                    <div class="io-single-option__title-row">
                        <div class="io-single-option__title">{{ option.name }}</div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="io-single-option__text-wrapper">
                    <div class="io-single-option__title-row">
                        <div class="io-single-option__title io-single-option__label">{{ option.name }} <StatusPill size="big" variant="blue-light" :text="option.type" /></div>
                    </div>
                </div>
            </template>
        </template>
    </CustomMultiselect>
</template>

<script lang="ts" src="./ApproverSelector.ts"></script>
<style lang="scss" src="./ApproverSelector.scss" scoped></style>
