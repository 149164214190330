<template>
    <Popup width="536" class="io-popup-rename" @close="onClose">
        <template #header>
            <div class="io-popup-header__title">{{ $t('Rename') }} {{ name }}</div>
            <div class="io-popup-header__close" @click="onClose"><i class="icon-cross" /></div>
        </template>

        <template #default>
            <div class="io-f-row">
                <FormInputColumn :label="$t('Enter New Name')" :isValid="isValid" @change="onChangeTitle"/>
            </div>
        </template>

        <template #footer>
            <div class="io-f-row io-buttons-row">
                <div class="io-btn io-btn-light" @click="onClose">{{ $t('Cancel') }}</div>
                <div class="io-btn" :class="[!isValid ? 'io-btn-disabled' : 'io-btn-primary']" @click="submitForm">{{ $t('Rename') }}</div>
            </div>
        </template>
    </Popup>
</template>

<script lang="ts" src="./RenameSet.ts"></script>

<style lang="scss" src="./RenameSet.scss" scoped></style>
