<template>
    <div class="io-selected-contacts" data-testid="selected-contacts">
        <DynamicScroller
            :items="contacts"    
            :min-item-size="63"
            class="scroller"
            page-mode
        >
            <template v-slot="{ item, index, active }">
                <DynamicScrollerItem
                    :item="item"
                    :active="active"
                    :data-index="index"
                >
                    <SingleContact
                        :componentKey="componentKey"
                        :key="`selected-single-contact-${index}`"
                        :contact="item"
                        :isActionsVisible="false"
                        :disableRemoval="disableRemoval"
                    />
                </DynamicScrollerItem>
            </template>
        </DynamicScroller>
    </div>
</template>

<script lang="ts" src="./SelectedContacts.ts"></script>
<style src="./SelectedContacts.scss" lang="scss" scoped></style>
