<template>
    <div class="io-multiselect">
        <multiselect
            data-testid="io-custom-multiselect"
            :placeholder="getPlaceholder"
            :options="options"
            :group-label="multiselectSettings['group-label']"
            :group-values="multiselectSettings['group-values']"
            :label="multiselectSettings['label']"
            :track-by="multiselectSettings['track-by']"
            :max-height="multiselectSettings['max-height']"
            :allow-empty="false"
            :searchable="multiselectSettings['searchable']"
            :taggable="multiselectSettings['taggable']"
            :tag-placeholder="multiselectSettings['tag-placeholder'] || ''"
            :show-labels="false"
            tag-position="top"
            v-model="selectedValue"
            @tag="addTag"
            @search-change="handleMultiselectSearchChange"
            @click.stop
        >
            <template #caret="{ toggle }">
                <div class="multiselect__select" @mousedown.prevent.stop="toggle()">
                    <span class="icon-chevron-down"></span>
                </div>
            </template>

            <template
                v-if="multiselectSettings['is-type-and-add-enabled']"
                #noOptions
            >
                <div class="io-multiselect__placeholder io-multiselect__before-list">
                    {{ $t('Type and press') }} <span class="icon-arrow-corner-cw-lb-rec"></span>
                    {{ $t('to add new') }}
                </div>
            </template>
            <template
                v-if="multiselectSettings['is-type-and-add-enabled'] && !multiselectSearchValue.length && options?.length"
                #beforeList
            >
                <div class="io-multiselect__placeholder io-multiselect__before-list">
                    {{ $t('Type and press') }} <span class="icon-arrow-corner-cw-lb-rec"></span>
                    {{ $t('to add new') }}
                </div>
            </template>

            <!--Options in dropdown-->
            <template
                v-if="multiselectSettings['is-type-and-add-enabled']"
                #option="props"
            >
                <div class="io-option" :class="{ 'io-new': props.option.isTag }">
                    <template v-if="props.option.isTag">
                        <div class="io-option--new">
                            {{ props.option.label }}

                            <StatusPill :text="$t('New')" variant="blue-light"/>
                        </div>

                        <div class="io-option--placeholder io-multiselect__placeholder">
                            {{ $t('Press') }} <span class="icon-arrow-corner-cw-lb-rec"></span>
                            {{ $t('to add new') }}
                        </div>
                    </template>

                    <template v-else>
                        <div class="io-option--new">
                            {{ props.option[multiselectSettings['label']] }}
                            <StatusPill v-if="props.option?.isNew" :text="$t('New')" variant="blue-light"/>
                        </div>
                    </template>
                </div>
            </template>

            <!--To show some indicator near selected item-->
            <template
                v-if="!!multiselectSettings['taggable']"
                #singleLabel="{ option }"
            >
                <div class="flex items-center gap-4-px">
                    <span v-if="multiselectSettings['label']">{{ option[multiselectSettings['label']] }}</span>
                    <StatusPill
                        variant="blue-light"
                        :text="multiselectSettings['selected-pill'] || option.isNew ? $t('New') : ''"
                    />
                </div>
            </template>
        </multiselect>
    </div>
</template>

<script lang="ts" src="./IODropdown.ts"></script>
<style lang="scss" src="./IODropdown.scss" scoped></style>
