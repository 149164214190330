import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { isEmpty } from 'lodash'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import { WorkflowTemplate } from '@/interfaces/modules/projects/financial-approvals/WorkflowTemplate'
import Placeholder from '@/components/placeholder/Placeholder.vue'

export default defineComponent({
    name: 'WorkflowTemplatesPopup',
    components: {
        PopupModal,
        Placeholder
    },
    setup () {
        return {
            isEmpty,
        }
    },
    data () {
        return {
            searchValue: '' as string,
            activeTemplate: null as WorkflowTemplate | null,
        }
    },
    computed: {
        ...mapState('projectBudget', {
            templates: (state: any) => state.approval_workflow_templates,
        }),
        filteredTemplates (): WorkflowTemplate[] {
            return this.templates.filter(template => {
                return template.name.toLowerCase().includes(this.searchValue)
            })
        },
        activeTemplateId (): string | undefined {
            return this.activeTemplate?._id
        }
    },
    methods: {
        onSearchChanged (keyword: string): void {
            this.searchValue = keyword
        },

        setActiveTemplate (templateId: string): void {
            this.activeTemplate = this.templates.find(template => template._id === templateId) ?? null
        },

        setTemplate (): void {
            this.$emit('setTemplate', this.activeTemplate)
        },

        close (): void {
            this.$emit('close')
        }
    }
})
