<template>
    <label class="io-existing-workspace" :class="{ 'io-selected': isChecked }">
        <input
            type="radio"
            name="contact"
            :class="`io-existing-workspace-${ idx }`"
            :checked="isChecked"
            :value="value"
            v-model="selected"
            @change="$emit('update:modelValue', $event.target.value)"
        />
        <div
            v-if="item.logo"
            class="io-avatar-holder__logo"
            :style="getBackgroundCompanyLogo(item.logo)"
        >
        </div>
        <Initials
            v-else
            :bigAvatar="true"
            class="io-avatar-holder__initials"
            :fullName="item.company_name"
        />
        <div class="io-existing-workspace__text">
            <p>
                {{ item.company_name }}
                <StatusPill
                    v-if="item.app_type"
                    size="normal"
                    :variant="statusClass(item.app_type)"
                    :text="item.app_type"
                />
            </p>
            <p class="io-address">
                {{ getAddress(item) }}
            </p>
        </div>
    </label>
</template>
<script lang="ts" src="./ExistingWorkspace.ts"></script>
<style lang="scss" src="./ExistingWorkspace.scss" scoped></style>
