import { isNil, isUndefined, isNumber, isString, isArray, isFunction, defaults } from 'lodash'
import moment from 'moment'
import { ApiClient } from '@/api/api'

let apiClient = ApiClient

let resources = {
    fieldIsRequired: 'This field is required!',
    invalidFormat: 'Invalid format!',

    invalidPositiveInteger: 'The number is too small! Minimum: {0}',
    numberTooSmall: 'The number is too small! Minimum: {0}',
    numberTooBig: 'The number is too big! Maximum: {0}',
    invalidNumber: 'Invalid number',
    invalidNumericValue: 'Invalid value',
    valueLower: 'Value can\'t be lower',
    rfpIdUsed: 'This ID is already used',

    passwordLowerCaseLetter: 'A lowercase letter',
    passwordNeedUpperCase: 'A capital (uppercase) letter',
    passwordNeedNumber: 'A number',
    passwordNeeSpecialCharacter: 'A special character',
    passwordTooSmall: 'Minimum 8 characters',
    passwordNotEqualEmail: 'Password must not be equal to email',
    passwordMismatch: 'Password fields must be the same',
    passwordCannotBeReused: 'Password cannot be reused',

    passwordTooBig: 'The length of password is too big! Current: {0}, Maximum: {1}',
    textTooSmall: 'The length of text is too small! Current: {0}, Minimum: {1}',
    textTooBig: 'The length of text is too big! Current: {0}, Maximum: {1}',
    thisNotText: 'This is not a text!',

    numberingIDRequired: 'Numbering fields must contain "ID" variable',


    thisNotArray: 'This is not an array!',

    selectMinItems: 'Select minimum {0} items!',
    selectMaxItems: 'Select maximum {0} items!',

    invalidDate: 'Invalid date!',
    dateIsEarly: 'The date is too early! Current: {0}, Minimum: {1}',
    dateIsLate: 'The date is too late! Current: {0}, Maximum: {1}',

    invalidEmail: 'Invalid e-mail address!',
    invalidURL: 'Invalid URL! URL should start with http:// or https://',

    invalidCard: 'Invalid card format!',
    invalidCardNumber: 'Invalid card number!',

    invalidCostCode: 'Invalid cost code!',
    costCodeTooSmall: 'The length of cost code is too small! Current: {0}, Minimum: {1}',
    costCodeTooBig: 'The length of cost code is too big! Current: {0}, Maximum: {1}',
    invalidContactID: 'Invalid ID!',
    selectOption: 'Please select option',
    selectMinOneObject: 'Please select option',
    days: 'Days range incorrect',

    invalidTextContainNumber: 'Invalid text! Cannot contains numbers or special characters',
    invalidTextContainSpec: 'Invalid text! Cannot contains special characters',

    retainageError: 'Value must be equal to or lower than the Retainage Balance',
    retainerError: 'Value must be equal to or lower than the Retainer amount"',

    fieldObservationCompletionError: 'Value cannot be less than {0}% regards to previous report',
    meetingSubjectUniqueError: 'Subject already exists!',

    invalidSSN: 'Social Security Number must be 9 digits',
    address2Length: 'Max length exceeded (60 characters)',
    atLeastOneFromSetGreaterThanZero: 'At least one of these fields {0} must have a value greater than zero',
}

function checkEmpty (value, field, messages = resources, internally = false) {
    if (field.validator !== undefined) {
        if (internally === true && field.validator.length > 0) {
            return null
        }
    }
    if (isNil(value) || value === '') {
        if (field.required) {
            return [msg(messages.fieldIsRequired)]
        } else {
            return []
        }
    }
    return null
}

function msg (text) {
    if (text != null && arguments.length > 1) {
        for (let i = 1; i < arguments.length; i++) {
            text = text.replace('{' + (i - 1) + '}', arguments[i])
        }
    }
    return text
}

function hasNumber (myString) {
    return (/\d/.test(myString))
}

function isNumeric (data) {
    return !isNaN(parseFloat(data)) && isFinite(data) && data.constructor !== Array
}

function isDigit (str) {
    var code, i, len
    if (str !== '' && str !== null) {
        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i)
            if (!(code > 47 && code < 58)) { // numeric (0-9)
                // !(code > 64 && code < 91) && // upper alpha (A-Z)
                // !(code > 96 && code < 123)) { // lower alpha (a-z)
                return false
            }
        }
    }
    return true
}

function isCurrency (str) {
    var code, i, len
    if (str !== '' && str !== null) {
        str = str + ''
        str = str.replace('-', '')
        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i)
            if (!(code > 47 && code < 58) && !(str[i] === ',') && !(str[i] === '.')) {
                return false
            }
        }
    }
    return true
}

const validators = {
    resources,
    required (value, field, model, messages = resources) {
        // prevent duplicates
        if (field.validator.length > 1) {
            return false
        }
        return checkEmpty(value, field, messages)
    },

    number (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) {
            return res
        }

        let err = []
        if (isNumeric(value)) {
            if (!isNil(field.min) && value < field.min) {
                err.push(msg(messages.numberTooSmall, field.min))
            }

            if (!isNil(field.max) && value > field.max) {
                err.push(msg(messages.numberTooBig, field.max))
            }
        } else {
            err.push(msg(messages.invalidNumber))
        }

        return err
    },
    integerPositive (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) {
            return res
        }
        if (isDigit(value) === false || value < 1) {
            return [msg(messages.invalidPositiveInteger, 1)]
        }
    },
    integer (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) {
            return res
        }
        if (isDigit(value) === false) {
            return [msg(messages.invalidNumber)]
        }
    },
    async rfpId (value, field, model, messages = resources) {
        // Skip if model already exists
        if (model._id || model.proposal_id) {
            return null
        }
        if (isUndefined(value.value)) {
            return [msg(messages.invalidNumber)]
        }
        if (isDigit(value.value) === false) {
            return [msg(messages.invalidNumber)]
        }
        let response = await apiClient.post('/bidsend/checkidfullavailability', { id: value.formatted || value.original })
        if (!response.data.result) {
            return [msg(messages.rfpIdUsed)]
        }
    },
    customId (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) {
            return res
        }
        if (isDigit(value) === false) {
            return [msg(messages.invalidNumber)]
        }
        if (parseInt(value) < parseInt(model._custom_id_min)) {
            return [msg(messages.valueLower)]
        }
    },
    days4 (value, field, model, messages = resources) {
        let res = true
        let arr = [value.day_1, value.day_2, value.day_3, value.day_4]
        arr.forEach(function (value, i) {
            arr.forEach(function (value2, i2) {
                if (value === value2 && i !== i2) {
                    res = false
                }
            })
        })
        if (value.day_2 >= value.day_3) {
            res = false
        }
        if (!res) {
            return [msg(messages.days)]
        }
    },
    days2 (value, field, model, messages = resources) {
        let res = true
        if (value.day_1 === value.day_2) {
            res = false
        }
        if (!res) {
            return [msg(messages.days)]
        }
    },
    currency (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) {
            return res
        }
        if (isCurrency(value) === false) {
            return [msg(messages.invalidNumericValue)]
        }
    },
    minonestr (value, field, model, messages = resources) {
        if (value === '' || String(value) === '0') {
            return [msg(messages.selectOption)]
        }
    },
    minone (value, field, model, messages = resources) {
        value = parseInt(value)
        if (!(Number(value) === value && value % 1 === 0)) {
            return [msg(messages.selectOption)]
        }
    },
    minonenonzero (value, field, model, messages = resources) {
        value = parseInt(value)
        if (!(Number(value) === value && value % 1 === 0) || value === 0) {
            return [msg(messages.selectOption)]
        }
    },
    minoneobj (value, field, model, messages = resources) {
        if (value !== null && value !== undefined) {
            if (value._id) value.id = value._id
            else if (value.key) value.id = value.key
        }

        if (value === null || value === undefined || !value.id || String(value.id) === '0') {
            return [msg(messages.selectMinOneObject)]
        }
    },
    double (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res

        if (!isNumber(value) || isNaN(value)) {
            return [msg(messages.invalidNumber)]
        }
    },
    string (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res
        let err = []
        if (isString(value)) {
            if (!isNil(field.min) && value.length < field.min) {
                err.push(msg(messages.textTooSmall, value.length, field.min))
            }
            if (!isNil(field.max) && value.length > field.max) {
                err.push(msg(messages.textTooBig, value.length, field.max))
            }
        } else {
            err.push(msg(messages.thisNotText))
        }
        return err
    },

    numbering (value, field, model, messages = resources) {
        const err = []
        if (!value || !isString(value) || !value.includes('ID')) {
            err.push(msg(messages.numberingIDRequired))
        }
        return err
    },
    address2 (value, field, model, messages = resources) {
        if (value !== null && value !== undefined) {
            if (value.length > 60) {
                return [msg(messages.address2Length)]
            }
        }
    },
    password (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res
        let err = []

        if (!value.match(/[a-z]/g)) {
            err.push(this.$t(msg(messages.passwordLowerCaseLetter)))
        }

        if (!value.match(/[A-Z]/g)) {
            err.push(this.$t(msg(messages.passwordNeedUpperCase)))
        }

        // now validate rules
        if (hasNumber(value) === false) {
            err.push(msg(messages.passwordNeedNumber))
        }

        var specialCharacterExp = /[^A-Za-z0-9]/
        if (!specialCharacterExp.test(value)) {
            err.push(msg(messages.passwordNeeSpecialCharacter))
        }

        if (!isNil(field.min) && value.length < field.min) {
            err.push(msg(messages.passwordTooSmall, value.length, field.min))
        }

        if (value === model.e_email) {
            err.push(this.$t(msg(messages.passwordNotEqualEmail)))
        }

        if (value === model.e_current_password) {
            err.push(this.$t(msg(messages.passwordCannotBeReused)))
        }

        return err
    },
    passwordmatch (value, field, model, messages = resources) {
        let err = []
        if (value !== model[field.matchTo]) {
            err.push(msg(messages.passwordMismatch))
        }
        return err
    },
    array (value, field, model, messages = resources) {
        if (field.required) {
            if (!isArray(value)) {
                return [msg(messages.thisNotArray)]
            }

            if (value.length === 0) {
                return [msg(messages.fieldIsRequired)]
            }
        }

        if (!isNil(value)) {
            if (!isNil(field.min)) {
                if (value.length < field.min) {
                    return [msg(messages.selectMinItems, field.min)]
                }
            }

            if (!isNil(field.max)) {
                if (value.length > field.max) {
                    return [msg(messages.selectMaxItems, field.max)]
                }
            }
        }
    },
    date (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res

        let m = new Date(value)
        if (!m) {
            return [msg(messages.invalidDate)]
        }

        let err = []

        if (!isNil(field.min)) {
            let min = new Date(field.min)
            if (m.valueOf() < min.valueOf()) {
                err.push(msg(messages.dateIsEarly, moment.utc(m), moment.utc(min)))
            }
        }

        if (!isNil(field.max)) {
            let max = new Date(field.max)
            if (m.valueOf() > max.valueOf()) {
                err.push(msg(messages.dateIsLate, moment.utc(m), moment.utc(max)))
            }
        }

        return err
    },
    regexp (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res

        if (!isNil(field.pattern)) {
            let re = new RegExp(field.pattern)
            if (!re.test(value)) {
                return [msg(messages.invalidFormat)]
            }
        }
    },
    email (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res

        let re = /^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
        if (!re.test(value)) {
            return [msg(messages.invalidEmail)]
        }
    },
    emails (value, field, model, messages = resources) {
        for (let i = 0; i < value.length; i++) {
            let errorMsg = validators.email(value[i], field, model, messages)
            if (errorMsg) {
                return errorMsg
            }
        }
    },
    atLeastOneFromSetGreaterThanZero (value, field, model, messages = resources) {
        let err = []
        let result = false
        field.atLeastOneFromSetGreaterThanZero.forEach((fieldName) => {
            if (parseFloat(model[fieldName]) > 0) {
                result = true
            }
        })

        if (!result) {
            err.push(msg(messages.atLeastOneFromSetGreaterThanZero, field.setFieldsNames.join(', ')))
        }

        return err
    },
    url (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res

        let test1 = true
        let test2 = true

        // check domain
        let re1 = /^(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g //eslint-disable-line
        if (!re1.test(value)) {
            test1 = false // is not a domain
        }
        if (test1 === false) {
            // check url
            let re2 = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g //eslint-disable-line
            if (!re2.test(value)) {
                test2 = false
            }
        }
        if (test1 === false && test2 === false) {
            return [msg(messages.invalidURL)]
        }
    },
    creditCard (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res

        /*  From validator.js code
         https://github.com/chriso/validator.js/blob/master/src/lib/isCreditCard.js
         */
        const creditCard = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/ //eslint-disable-line
        const sanitized = value.replace(/[^0-9]+/g, '') //eslint-disable-line
        if (!creditCard.test(sanitized)) {
            return [msg(messages.invalidCard)]
        }
        let sum = 0
        let digit
        let tmpNum
        let shouldDouble
        for (let i = sanitized.length - 1; i >= 0; i--) {
            digit = sanitized.substring(i, (i + 1))
            tmpNum = parseInt(digit, 10)
            if (shouldDouble) {
                tmpNum *= 2
                if (tmpNum >= 10) {
                    sum += ((tmpNum % 10) + 1)
                } else {
                    sum += tmpNum
                }
            } else {
                sum += tmpNum
            }
            shouldDouble = !shouldDouble
        }

        if (!((sum % 10) === 0 ? sanitized : false)) {
            return [msg(messages.invalidCardNumber)]
        }
    },
    alpha (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) {
            return res
        }

        let re = /^[a-zA-Z]*$/
        if (!re.test(value)) {
            return [msg(messages.invalidTextContainNumber)]
        }
    },
    socialSecurityNumber (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res

        const ssnPatern = /^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/ //eslint-disable-line
        if (!ssnPatern.test(value)) {
            return [msg(messages.invalidSSN)]
        }
    },
    alphaNumeric (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res

        let re = /^[a-zA-Z0-9]*$/
        if (!re.test(value)) {
            return [msg(messages.invalidTextContainSpec)]
        }
    },
    field_observation_completion (value, field, model, messages = resources) {
        let err = []
        if (value === '') {
            value = 0.00
        } else {
            if (typeof value === 'string' || value instanceof String) {
                value = value.replace(',', '')
            }
        }
        if (parseFloat(value) < model[field.matchTo]) {
            return [msg(messages.fieldObservationCompletionError, model[field.matchTo])]
        }
        return err
    },
    meeting_subject_unique (value, field, model, messages = resources) {
        if (typeof value === 'string' && value.length > 0 && value !== '') {
            if (model._subjects && model._subjects.includes(value)) {
                return [msg(messages.meetingSubjectUniqueError)]
            } else {
                return []
            }
        } else {
            return checkEmpty(value, field, messages)
        }
    },
    retainage (value, field, model, messages = resources) {
        let err = []
        if (value === '') {
            value = 0.00
        } else {
            if (typeof value === 'string' || value instanceof String) {
                value = value.replace(',', '')
            }
        }
        if (parseFloat(value) > model[field.matchTo]) {
            err.push(msg(messages.retainageError))
        }
        return err
    },
    retainer (value, field, model, messages = resources) {
        let err = []
        if (value === '') {
            value = 0.00
        } else {
            if (typeof value === 'string' || value instanceof String) {
                value = value.replace(',', '')
            }
        }
        if (parseFloat(value) > model[field.matchTo]) {
            err.push(msg(messages.retainerError))
        }
        return err
    },
    costcode (value, field, model, messages = resources) {
        let res = checkEmpty(value, field, messages, false)
        if (res != null) return res
        let err = []
        if (!isNil(field.min) && value.length < field.min) {
            err.push(msg(messages.costCodeTooSmall, value.length, field.min))
        }
        if (!isNil(field.max) && value.length > field.max) {
            err.push(msg(messages.costCodeTooBig, value.length, field.max))
        }

        if (err.length > 0) {
            return err
        }
        var divisionCode = value.substr(0, 2)
        var divisionOption = this.$parent.$parent.extraData.extraPack.divisionCodes

        let divisionId = ''

        divisionOption.forEach(function (entry) {
            if (entry.divisionCode === divisionCode) {
                divisionId = entry.divisionId
            }
        })

        if (divisionId !== '') {
            model.cc_division_id = divisionId
        } else {
            err.push(msg(messages.invalidCostCode, divisionCode))
        }

        return err
    },
}

Object.keys(validators).forEach(name => {
    const fn = validators[name]
    if (isFunction(fn)) {
        fn.locale = customMessages => (value, field, model) => fn(value, field, model, defaults(customMessages, resources))
    }
})

export default validators
