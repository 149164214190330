export default {
    period: null,
    periods: [],
    has_billing_settings: false,
    contractors: [],
    categories: [],
    attenders: {
        to: [],
        from: []
    },
    providerList: [],
    contractor: null,
    showPayappTabs: false,
    loaded: {
        invoice: false,
        /** @type {string|boolean} Are billing periods loaded? Can contain id of the loaded data (project id) */
        periods: false,
        contractors: false
    },
    modal: {
        addInvoice: false,
        addInvoiceOptions: false,
        addPayment: false,
        attachInvoice: false,
        assignFunding: false,
        revertPaidToDraft: false,
    },
    numAdjustedTasks: 0,
    numNeedAttentionTasks: 0,
    adjustmentRecalculationCompleted: false,

    fundingSources: [],
    bannerAccountingMessages: [],

    /**
     * Single payapp details
     */
    payApp: {
        id: null,
        saveFlag: 0,
        finance: null,
        balance: null,
        payments: [],
        basic: null,
        specific: {
            cost_codes: [],
            general_invoicable_items: []
        },
        actions: [],
        actionsCalculated: [],
        status: null,
        payment_status: null,
        quoted_items: {
            phases: []
        },
        injected: {},
        flags: {},
        attached_vendor_invoices: [],
        change_orders: [],
        attached_vendor_totals: {},
        submitted_invoice_notification_email: null,
        encountered_error_on_invoice_submission: false,
    },

    linesVisibleBy: '',
    columnsViewMode: '',
    tablesViewMode: '',

    payAppRouter: {
        routerTo: null,
        routerFrom: null
    },

    /**
     * Billing period details
     */
    periodDetails: {
        package_vendors: [],
        basic_data: null,
        invoices_data: null,
        invoice_providers: [],
        no_payapps_for_contracts: [],
        items: null,
        project_totals: {},
        actions: [],
        widgets_visibility: null
    },

    calculatedTotals: {
        previous_application: 0,
        total_completed_stored_amount: 0,
        total_payments_incl_process_amount: 0,
        current_application: 0,
        material_stored_offsite: 0,
        net_current_application: 0,
        total_retention_amount: 0,
        retention_percent: 0,
        invoice_retention_amount: 0
    },

    /**
     * Add new invoice modal
     */
    modalInvoice: {
        enabled: false,
        vendors: [],
        contractData: {},
        data: {
            type: null,
            subType: null,
            contract_id: null,
            budget_type: null,
            invoice_id: null,
            value: null,
            start_date: null,
            end_date: null,
            invoiceDate: null,
            billing_period: null,
            vendor: null,
            sources: [],
            category: null,
            invoice_expense_type: null,
        }
    },

    modalSources: {
        loading: true,
        isLocked: false,
        sourcesProject: [],
        sourcesSelected: [],
        sourcesPreselected: [],
        invoiceAmount: 0
    },

    /**
     * Modal for invoices
     */
    modalPackagePayments: {
        enabled: false,
        data: {
            invoices: []
        }
    },

    /**
     * Modal for payments
     */
    modalInvoicePayments: {
        enabled: false,
        withProvider: false,
        payments: []
    },

    showPopupManualApproval: false,
    showPopupManualRejection: false,
    showPopupClientApproval: false,
    showPopupUserApproval: false,
    showPopupUserRejection: false,

    dependents: [],
    revisionPopup: {
        enabled: false,
        type: null,
        action: null
    },
    returnToDraftPopup: {
        enabled: false,
        action: null
    },
    canSaveFundingSources: true,
}
