import { defineComponent, PropType } from 'vue'
import { StatusClass } from '@/components/add-new-contact-modal/enums/StatusClass'
import { Contact } from './interfaces/Contact'

export default defineComponent({
    name: 'SingleContact',
    props: {
        data: { type: Object as PropType<Contact>, required: true }
    },
    methods: {
        statusClass (status: string): string {
            return StatusClass[status] || StatusClass['default']
        }
    }
})
