import { assign, isEqual } from 'lodash'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'

const defaultSettings = {
    textPlaceholder: 'Select...',
    textAddNewOption: 'Add new option',
    textAddNewOptionButton: 'Add',
    enableAddNew: false,
    closeAfterSelect: false,
    enableSearch: false,
    searchPropertyNames: [],
}

export default {
    name: 'CustomMultiselect',

    components: {
        IOButton,
    },

    emits: ['search', 'menuToggled', 'changeOption', 'input', 'reset', 'addNew', 'update:modelValue', 'loadMoreElements'],

    props: {
        settings: {
            type: Object,
            default: () => defaultSettings,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: false
        },
        optionsTitle: {
            type: String,
            default: null,
        },
        additionalOptions: {
            type: Array,
            required: false
        },
        additionalOptionsTitle: {
            type: String,
            default: null,
        },
        addingNewInProcess: {
            type: Boolean,
            default: false,
        },
        addNewBtnDisabled: {
            type: Boolean,
            default: false,
        },
        statusEnable: {
            type: Boolean,
            default: true,
        },
        clearIconEnable: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [Object, String],
            default: null,
        },
        icon: {
            type: String,
            default: 'icon-chevron-right',
        },
        blockAutomaticValueChange: {
            type: Boolean,
            default: false,
        },
        enableStopPropagation: {
            type: Boolean,
            default: true,
        },
        clearSearchOnValueReset: {
            type: Boolean,
            default: false,
        },
        enableLazyLoading: {
            type: Boolean,
            default: false,
        },
    },

    setup () {
        return { isEqual }
    },

    data () {
        return {
            showMenu: false,
            searchKeyword: '',
            showOptionRemove: false,
            showAddNewView: false,
            vcoConfig: {
                handler: this.hideMenu,
                capture: true
            },
            prevScroll: 0,
        }
    },

    computed: {
        value: {
            get () {
                return this.modelValue
            },
            set (value) {
                this.$emit('update:modelValue', value)
            },
        },
        optionsFiltered () {
            if (!this.config.enableSearch || 0 === this.config.searchPropertyNames.length) {
                return this.options
            }
            return this.options.filter(option => {
                return this.config.searchPropertyNames.some(propertyName => {
                    if (option[propertyName]) {
                        return option[propertyName].toLowerCase().includes(this.searchKeyword.toLowerCase())
                    }
                })
            })
        },
        additionalOptionsFiltered () {
            if (!this.config.enableSearch || 0 === this.config.searchPropertyNames.length) {
                return this.additionalOptions
            }
            return this.additionalOptions.filter(option => {
                return this.config.searchPropertyNames.some(propertyName => {
                    return option[propertyName].toLowerCase().includes(this.searchKeyword.toLowerCase())
                })
            })
        },
        config () {
            const defaultConfig = {
                textPlaceholder: this.$t('Select...'),
                textNewOption: this.$t('New option'),
                textNewOptionPlaceholder: this.$t('New option name'),
                textAddNewOption: this.$t('Add new option'),
                textAddNewOptionButton: this.$t('Add'),
                enableAddNew: false,
                closeAfterSelect: false,
                enableSearch: false,
                enabledSearchIcon: true,
                searchPropertyNames: []
            }

            return assign(defaultConfig, this.settings)
        },
        createButtonClass () {
            if (this.addingNewInProcess) {
                return 'io-btn-primary'
            }
            return (this.addNewBtnDisabled ? 'io-btn-disabled-compact' : 'io-btn-primary-compact')
        }
    },
    watch: {
        searchKeyword (value) {
            this.$emit('search', value)
        },

        showMenu (state) {
            this.$emit('menuToggled', state)
        },
    },

    methods: {
        setShowAddNewView (arg) {
            this.$nextTick(() => {
                this.showAddNewView = arg
            })
        },
        setShowOptionRemove (arg) {
            this.showOptionRemove = arg
        },
        toggleMenu (event) {
            if (this.disabled) {
                return
            }
            if(this.enableStopPropagation) {
                event.stopPropagation()
            }

            this.showMenu = !this.showMenu
            this.setShowAddNewView(false)
            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.focus()
                }
            })
        },
        hideMenu () {
            this.showMenu = false
            this.setShowAddNewView(false)
        },
        setActiveOption (option) {
            if (option.$isLabel) {
                return
            }

            if (false === this.blockAutomaticValueChange) {
                this.value = option
            }

            this.$emit('changeOption', option)
            this.$emit('update:modelValue', option)

            if (this.config.closeAfterSelect) {
                this.$nextTick(() => {
                    this.hideMenu()
                })
            }
        },
        resetActiveOption () {
            this.value = null
            this.setShowOptionRemove(false)
            this.$emit('reset')
            if (this.clearSearchOnValueReset) {
                this.searchKeyword = ''
            }
        },
        addNewOption () {
            this.$emit('addNew')
        },
        onScroll () {
            if (!this.enableLazyLoading) {
                return
            }

            const el = this.$refs['multiselect-list']
            const elHeight = el ? el.offsetHeight : 0

            const currentScroll = event.target.scrollTop
            const scrollHeight = event.target.scrollHeight

            if (currentScroll > this.prevScroll) {
                this.prevScroll = currentScroll
            } else {
                this.prevScroll = currentScroll

                return
            }

            if (currentScroll >= scrollHeight - elHeight - 10) {
                this.$emit('loadMoreElements')
            }
        }
    },
}
