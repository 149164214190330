import { AxiosPromise } from 'axios'
import { ApiClientV3 as client } from '@/api/api'
import type Unit from '@/io-modules/budget/interfaces/Unit'

const unitsClient = {
    getByUnitType (unitType: string): AxiosPromise<{ units: Unit[] }> {
        return client.get(`units/${ unitType }`)
    },

    /**
     * @param projectLocalId If only this is provided, return the units with the default quantities for the
     * project.
     * @param isCalledFromBudgetModule This param prevents exposing units like "% of dependency" if called
     * out of the budget.
     * @param budgetOptionId If this is provided, return the units with the default quantities for the budget
     * option.
     */
    getUnitsForFinanceable (
        projectLocalId: string,
        isCalledFromBudgetModule: boolean,
        budgetOptionId?: string,
    ): AxiosPromise<{ units: Unit[] }> {
        return client.get(
            `projects/${ projectLocalId }/units-for-financeable`,
            {
                params: {
                    budget_option_id: budgetOptionId,
                    is_called_from_budget_module: +isCalledFromBudgetModule,
                },
            },
        )
    },
}

export default unitsClient
