import { defineComponent } from 'vue'
import SelectedJobsiteLocations
    from '@/components/jobsite-locations/components/selected-jobsite-locations/SelectedJobsiteLocations.vue'
import JobsiteLocationsModal from '@/components/jobsite-locations/JobsiteLocationsModal.vue'
import { cloneDeep } from 'lodash'
import { mapState, mapStores } from 'pinia'
import { jobsiteLocationsStore } from '@/components/jobsite-locations/store/jobsiteLocationsStore.ts'
import {
    buildSelectedLocationsPaths,
} from '@/components/jobsite-locations/helpers'
import { JobsiteLocationsInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsInterface.ts'

export default defineComponent({
    name: 'JobsiteLocationsPreview',
    components: {
        JobsiteLocationsModal,
        SelectedJobsiteLocations,
    },
    emits: ['update:modelValue', 'update-path'],
    props: {
        modelValue: { type: Array as () => string[], default: [] },
        locationsPaths: { type: Array as () => JobsiteLocationsInterface[][], default: [] },
        isEditable: { type: Boolean, default: true },
        isSkinnyMode: { type: Boolean, default: false },
        selectMultiple: { type: Boolean, default: true },
        projectId: { type: String, default: null },
        title: { type: [String, null], default: 'Jobsite Locations' },
    },
    data () {
        return {
            isJobSiteLocationsModalShown: false,
            isLoaded: false,
            selectedLocationsIds: [],
            copiedLocationsPaths: cloneDeep(this.locationsPaths)
        }
    },
    computed: {
        ...mapStores(jobsiteLocationsStore),
        ...mapState(jobsiteLocationsStore, [
            'jobsiteLocations',
            'selectedJobsiteLocationsIds',
            'selectedTreeJobsiteLocationsIds'
        ]),

        selectedLocationsPath (): JobsiteLocationsInterface[][] {
            if (!this.jobsiteLocations.length) {
                return this.copiedLocationsPaths
            }

            return buildSelectedLocationsPaths(this.selectedLocationsIds, this.jobsiteLocations)
        },
    },
    async beforeMount (): Promise<void> {
        await this.jobsiteLocationsStore.$reset()
    },
    watch: {
        'locationsPaths': {
            handler (): void {
                this.copiedLocationsPaths = cloneDeep(this.locationsPaths)
                this.initData()
            },
            deep: true
        },
    },
    async mounted (): Promise<void> {
        this.initData()
    },
    methods: {
        getInitiallySelectedIds (): string[] {
            const ids = []

            this.copiedLocationsPaths.forEach((path: JobsiteLocationsInterface[]) => {
                ids.push(path[path.length - 1].id)
            })

            return ids
        },
        initData (): void {
            this.selectedLocationsIds = cloneDeep(this.getInitiallySelectedIds())
            this.jobsiteLocationsStore.setSelectedLocationsIds(this.selectedLocationsIds)

            if (!this.modelValue.length && !!this.selectedLocationsIds.length) {
                this.$emit('update:modelValue', this.selectedLocationsIds)
            }

            this.isLoaded = true
        },

        openJobSiteLocationsModal (): void {
            this.jobsiteLocationsStore.setSelectedLocationsIds(this.selectedLocationsIds)
            this.isJobSiteLocationsModalShown = true
        },
        closeJobSiteLocationsModal (): void {
            this.selectedLocationsIds = cloneDeep(this.selectedLocationsIds)
            this.isJobSiteLocationsModalShown = false
        },
        onSaveJobSiteLocations (): void {
            this.updateModelValue()

            this.isJobSiteLocationsModalShown = false
        },
        updateSelectedList (updatedList: JobsiteLocationsInterface[][]): void {
            this.copiedLocationsPaths = cloneDeep(updatedList)

            this.updateModelValue()
        },
        updateModelValue (): void {
            this.selectedLocationsIds = cloneDeep(this.selectedJobsiteLocationsIds)
            this.$emit('update:modelValue', this.selectedLocationsIds)
            this.$emit('update-path', this.selectedLocationsPath)
        },
    },
})
