<template>
    <div
        class="io-action-dropdown__menu"
        :class="directionClass"
        role="menu"
    >
        <slot />
    </div>
</template>

<script lang="ts" src="./ActionDropdownMenu.ts" />
<style lang="scss" src="./ActionDropdownMenu.scss" scoped />
