export enum PortfolioRouteName {
    PORTFOLIO_SUMMARY = 'single-portfolio-summary',
    PORTFOLIO_FUNDING = 'single-portfolio-funding',
    PORTFOLIO_LAND_ACQUISITION = 'single-portfolio-land-acquisition',
    PORTFOLIO_PROJECTS = 'single-portfolio-projects',
    PORTFOLIO_MASTER_SCHEDULE = 'single-portfolio-master-schedule',
    PORTFOLIO_TIMELINE = 'single-portfolio-timeline',
    PORTFOLIO_SETTINGS = 'single-portfolio-settings',
    PORTFOLIO_MANAGE_PROJECTS = 'single-portfolio-manage-projects',
}
