<template>
    <div>
        <Transition name="fade-from-bottom" mode="out-in">
            <IOButton
                v-if="editable && !modelValue"
                variant="link"
                icon="icon-align-left"
                size="small"
                :disabled="loading"
                @click="openNewReferenceModal"
            >
                {{ $t('Select Text') }}
            </IOButton>

            <div
                v-else
                class="flex justify-between items-center gap-16-px px-16-px py-8-px rounded-lg border border-darker-bg leading-relaxed"
            >
                <RouterLink
                    v-if="modelValue.id"
                    class="text-dark-green-lighten-1 text-sm-px truncate"
                    target="_blank"
                    :to="{
                        name: 'specifications-show',
                        params: { id: modelValue.version_id },
                        query: { [querySidebarTabName]: 'details' },
                    }"
                >
                    {{ name }}
                </RouterLink>

                <div v-else :title="name" class="text-sm-px truncate">{{ name }}</div>

                <FloatMenu
                    static
                    :actions="actions"
                    @clear="$emit('update:modelValue', null)"
                />
            </div>
        </Transition>

        <IOModal
            v-if="newReferenceModalShown"
            fullscreen
            scrollable
            widest
            :loading="!divisions || (!section && loading)"
            icon="icon-align-left"
            :title="$t('Select Text')"
            @close="closeNewReferenceModal"
        >
            <template #header-action>
                <IOButton variant="secondary" @click="closeNewReferenceModal">{{ $t('Cancel') }}</IOButton>

                <IOButton
                    :disabled="!reference"
                    @click="$emit('update:modelValue', reference); closeNewReferenceModal()"
                >
                    {{ $t('Save') }}
                </IOButton>
            </template>

            <div class="h-full pt-24-px pl-24-px bg-light-bg">
                <Transition name="fade-from-bottom">
                    <div v-if="section">
                        <div class="flex gap-24-px">
                            <div v-progress="!webViewerLoaded || loading" class="grow">
                                <SpecificationWebViewer
                                    page-scrolling
                                    :disabled-elements="webViewerDisabledElements"
                                    :url="section.file_url"
                                    @document-loaded="webViewerLoaded = true"
                                    @document-unloaded="webViewerLoaded = false"
                                    @annotation-changed="annotation = $event"
                                    @annotation-selected="annotation = $event"
                                />
                            </div>

                            <div v-progress="!webViewerLoaded">
                                <IOSidebar
                                    collapsible
                                    class="io-spec-reference-select-sidebar"
                                    width="460"
                                    :query-tab-name="querySidebarTabName"
                                    :query-section-name="querySidebarSectionName"
                                    :tabs="sidebarTabs"
                                >
                                    <template #table-of-contents>
                                        <DivisionsIndex :divisions="divisions"/>
                                    </template>
                                </IOSidebar>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="divisions && !loading" class="h-full flex flex-col justify-center">
                        <IOPlaceholder
                            icon="icon-file-text"
                            :title="$t('No Specifications Uploaded')"
                            :subtitle="$t('Click Add New Specifications to add one to your project.')"
                        >
                            <IOButton @click="$router.push({ name: 'specifications' })">
                                {{ $t('Add New Specification') }}
                            </IOButton>
                        </IOPlaceholder>
                    </div>
                </Transition>
            </div>
        </IOModal>
    </div>
</template>

<script lang="ts" src="./SpecReferenceSelect.ts"></script>
<style lang="scss" src="./SpecReferenceSelect.scss" scoped></style>
