import { defineComponent } from 'vue'

import specReferenceSelectClient from '@/io-modules/specifications/components/spec-reference-select/api-clients/specReferenceSelectClient'
import useLoader from '@/composables/useLoader.ts'

import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'
import FloatMenu, { Action } from '@/components/atoms/FloatMenu/FloatMenu.vue'
import IOSidebar from '@/components/atoms/IOSidebar/IOSidebar.vue'
import SpecificationWebViewer
    from '@/io-modules/specifications/components/spec-reference-select/parts/specification-web-viewer/SpecificationWebViewer.vue'
import DivisionsIndex from '@/io-modules/specifications/components/spec-reference-select/parts/divisions-index/DivisionsIndex.vue'

import { Division, Section, Annotation, Reference } from '@/io-modules/specifications/components/spec-reference-select/interfaces/Specification'
import { Tab } from '@/components/atoms/IOSidebar/IOSidebar'

export default defineComponent({
    name: 'SpecReferenceSelect',
    components: {
        IOSidebar,
        IOModal,
        IOPlaceholder,
        FloatMenu,
        SpecificationWebViewer,
        DivisionsIndex,
    },
    inject: ['projectId'],
    props: {
        modelValue: {
            type: Object as () => Reference,
            default: null,
        },
        editable: Boolean,
    },
    emits: ['update:modelValue'],
    setup () {
        const querySidebarTabName = 'spec-ref-select-tab'
        const querySidebarSectionName = 'spec-ref-select-section'
        const { loading, load } = useLoader()
        const webViewerDisabledElements = [
            'leftPanelButton',
            'ribbons',
            'ribbonsDropdown',
            'toggleNotesButton',
            'underlineToolGroupButton',
            'strikeoutToolGroupButton',
            'squigglyToolGroupButton',
            'stickyToolGroupButton',
            'freeTextToolGroupButton',
            'shapeToolGroupButton',
            'freeHandToolGroupButton',
            'freeHandHighlightToolGroupButton',
            'undoButton',
            'redoButton',
            'eraserToolButton',
            'linkButton',
            'stickyToolButton',
            'annotationCommentButton',
            'contextMenuPopup',
            'textUnderlineToolButton',
            'textSquigglyToolButton',
            'textStrikeoutToolButton',
        ]

        return {
            querySidebarTabName, querySidebarSectionName,
            loading, load,
            webViewerDisabledElements,
        }
    },
    data () {
        return {
            webViewerLoaded: false,
            newReferenceModalShown: false,

            divisions: null as Division[],
            section: null as Section,

            annotation: null as Annotation,
        }
    },
    computed: {
        sidebarTabs (): Tab[] {
            return [
                {
                    title: this.$t('Table of Contents'),
                    name: 'table-of-contents',
                },
            ]
        },
        reference (): Reference {
            return this.annotation
                ? {
                    ...this.annotation,
                    id: this.modelValue?.id || null,
                    version_id: this.section.latest_version_id,
                    section: {
                        label: this.section.label,
                        title: this.section.title,
                    },
                }
                : null
        },
        actions (): Action[] {
            const actions: Action[] = []

            const preview: Action = {
                icon: 'icon-eye',
                name: 'preview',
                tooltip: this.summary,
            }
            const clear: Action = {
                icon: 'icon-cross',
                name: 'clear',
            }

            actions.push(preview)
            this.editable && actions.push(clear)

            return actions
        },
        summary (): string {
            const threshold = 300
            return this.modelValue?.text?.length > threshold
                ? `${ this.modelValue?.text?.slice(0, threshold) }...`
                : this.modelValue?.text
        },
        name (): string {
            return this.modelValue
                ? `${ this.modelValue.section?.label } - ${ this.modelValue.section?.title }`
                : ''
        },
    },
    watch: {
        '$route.query.spec-section' (sectionId: string): void {
            this.webViewerLoaded = false
            this.annotation = null
            sectionId && this.getSection(sectionId)
        },
    },
    methods: {
        getDivisions (): Promise<void> {
            return this.load(async () => {
                const { data } = await specReferenceSelectClient.getDivisions(this.projectId)
                this.divisions = data.items
            })
        },
        getSection (sectionId: string): Promise<void> {
            return this.load(async () => {
                const { data } = await specReferenceSelectClient.getSection(sectionId)
                this.section = data
            })
        },
        closeNewReferenceModal (): void {
            this.webViewerLoaded = false
            this.newReferenceModalShown = false
            this.divisions = null
            this.section = null
            this.annotation = null
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    [this.querySidebarTabName]: undefined,
                    [this.querySidebarSectionName]: undefined,
                    'spec-section': undefined,
                    'spec-divisions': undefined,
                },
            })
        },
        async openNewReferenceModal (): Promise<void> {
            this.newReferenceModalShown = true
            this.$router.replace({ query: { ...this.$route.query, [this.querySidebarTabName]: 'table-of-contents' } })

            await this.getDivisions()

            this.selectFirstSection()
        },
        selectFirstSection (): void {
            const [firstDivision] = this.divisions
            const firstDivisionId = firstDivision?.id
            const firstSectionId = firstDivision?.sections[0]?.current_version_id

            firstDivisionId && this.$router.replace({
                query: {
                    ...this.$route.query,
                    'spec-divisions': [firstDivisionId],
                    'spec-section': firstSectionId,
                },
            })
        },
    },
})
