import { PropType, defineComponent } from 'vue'
import { mapGetters as mapGettersVuex } from 'vuex'
import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse'
import ApprovalWorkflowsRouterService from '@/io-modules/approval-workflows/services/ApprovalWorkflowsRouterService'
import ApprovalWorkflow from '@/io-modules/approval-workflows/components/approval-workflow/ApprovalWorkflow.vue'

export default defineComponent({
    name: 'ResourceApprovals',
    components: {
        ApprovalWorkflow,
    },
    props: {
        resourceApprovals: {
            type: Object as PropType<ResourceApprovalsResponse>,
            default: null,
        },
        total: { type: Number, default: 0 },
        isTotalVisible: { type: Boolean, required: false, default: true },
        customTitle: { type: String, required: false, default: '' },
        hideApprovalWorkflow: { type: Boolean, required: false, default: false }
    },
    emits: ['approverApproveSelected', 'approverReviewSelected', 'approverRejectSelected', 'approverOverridden'],
    computed: {
        ...mapGettersVuex('project', ['projectCompanyMongoId']),

        showApprovalWorkflows (): boolean {
            return this.resourceApprovals.workflows.length > 0
        },

        projectSettingsRouting (): string {
            if (this.resourceApprovals?.resource_type) {
                return ApprovalWorkflowsRouterService.getProjectSettingsRouteName(
                    this.resourceApprovals.resource_type,
                )
            }

            return null
        },

        buttonsCta (): any[] {
            return [
                {
                    name: this.$t('Create Approval Workflow'),
                    url: { name: this.projectSettingsRouting }
                },
            ]
        },
    },
})
