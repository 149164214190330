import initials from '@/components/new-theme/initials'
import DiscussionItem from './discussion-item'
import notificationMixin from './mixins/notificationMixin'

export default {
    name: 'discussions',
    mixins: [notificationMixin],
    components: {
        initials,
        DiscussionItem
    },
    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        },
        newChatEvent: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            showList: false
        }
    },
    computed: {

    },
    methods: {
        toggleShowList () {
            this.showList = !this.showList
            if (this.showList) {
                this.$emit('clearDot', 'chat')
            }
        },
        closeList () {
            this.showList = false
        }
    }
}
