import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'

import { Division, Section } from '@/io-modules/specifications/components/spec-reference-select/interfaces/Specification'

const specReferenceSelectClient = {
    getDivisions: (projectId: string): AxiosPromise<{ items: Division[] }> =>
        client.post(`/ca/specification/divisions/${ projectId }`, { statuses: ['ACTIVE'] }),

    getSection: (sectionId: string): AxiosPromise<Section> =>
        client.get(`ca/specification/section-version/${ sectionId }`),
}

export default specReferenceSelectClient
