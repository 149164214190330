export enum Status {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    ARCHIVED = 'ARCHIVED'
}

export enum ItemsVisibility {
    PROJECT_DIRECTORY = 'project-directory',
    WORKSPACE_ONLY = 'workspace-only',
}

export enum RouteOperation {
    CREATE = 'create',
    EDIT = 'edit',
}
