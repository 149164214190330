<template>
    <div v-if="notAllInvitesAccepted"
         class="io-invite-button-group"
         :class="{ [column.additional?.iconName]: true }"
         @click="onInviteAllClick"
    >
        <span class="io-invite-button-text">
            {{ column.label }}
        </span>
    </div>

    <div v-else
         class="io-invite-button-group-disabled"
         :class="{ [column.additional?.iconName]: true }"
    >
        <span class="io-invite-button-text-disabled">
            {{ column.label }}
        </span>
    </div>
</template>

<script lang="ts" src="./InvitationButtonGroup.ts"></script>
<style lang="scss" src="./InvitationButtonGroup.scss" scoped></style>
