import { defineComponent } from 'vue'
import { RouteLocationNamedRaw } from 'vue-router'

import { SimpleLazyListHeader } from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'
import { SimpleLazyListCellType } from '@/io-modules/contracts/components/simple-lazy-list/enums/SimpleLazyListCellType.ts'

import TextCell from '@/io-modules/contracts/components/simple-lazy-list/parts/text-cell/TextCell.vue'
import AvatarCell from '@/io-modules/contracts/components/simple-lazy-list/parts/avatar-cell/AvatarCell.vue'
import StatusPillCell from '@/io-modules/contracts/components/simple-lazy-list/parts/status-pill-cell/StatusPillCell.vue'
import TextIconCell from '@/io-modules/contracts/components/simple-lazy-list/parts/text-icon-cell/TextIconCell.vue'
import HolderCell from '@/io-modules/contracts/components/simple-lazy-list/parts/holder-cell/HolderCell.vue'

export default defineComponent({
    name: 'SimpleLazyListRow',
    components:{
        TextCell,
        AvatarCell,
        StatusPillCell,
        TextIconCell,
        HolderCell,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        columns: {
            type: Array as () => Array<SimpleLazyListHeader<unknown>>,
            required: true,
        },
        itemRoute: {
            type: Object as () => RouteLocationNamedRaw,
            required: true,
        },
    },
    computed:{
        isClickable (): boolean {
            return !!this.itemRoute && !!this.itemRoute.name && this.itemRoute.name !== this.$route.name
        }
    },
    methods: {
        getCellComponent (columnType: SimpleLazyListCellType): string {
            const mapComponents = {
                [SimpleLazyListCellType.Text]: 'TextCell',
                [SimpleLazyListCellType.Avatar]: 'AvatarCell',
                [SimpleLazyListCellType.StatusPill]: 'StatusPillCell',
                [SimpleLazyListCellType.TextIcon]: 'TextIconCell',
                [SimpleLazyListCellType.Holder]: 'HolderCell',
            } as const

            return mapComponents[columnType] ?? 'TextCell'
        },
        prepareNewRouteForItem (item: unknown): RouteLocationNamedRaw {
            const newRoute: RouteLocationNamedRaw = {
                name: this.itemRoute.name,
            }

            for (const key in this.itemRoute.params) {
                newRoute.params = {
                    ...newRoute.params,
                    [key]: item[this.itemRoute.params[key]] as string,
                }
            }

            return newRoute
        },
        handleLeftClick (item: unknown): void {
            const newRoute = this.prepareNewRouteForItem(item)

            this.$router.push(newRoute)
        },
        handleMiddleClick (item: unknown): void {
            const newRoute = this.prepareNewRouteForItem(item)

            window.open(this.$router.resolve(newRoute).href, '_blank')
        },
    }
})
