<template>
    <IOLabel
        v-if="label"
        :text="label"
        :is-required="required"
        :type="labelType"
        :for="label"
    />

    <div class="io-limited-textarea" :class="[classname, { 'io-focused': focused, 'io-error': error, 'io-red': maxLength && currentLength >= maxLength, 'dynamic-height': dynamicHeight, 'upper-counter': upperCounter }]">
        <div v-if="maxLength" class="io-limited-textarea__bar" :style="`transform: scaleX(${currentLength / maxLength})`"></div>
        <textarea
            :id="label || id"
            ref="textarea"
            type="text"
            class="io-limited-textarea__textarea"
            :name="name"
            :maxlength="maxLength"
            :placeholder="placeholder"
            :disabled="!editable"
            v-model="value"
            @focus="setFocused(true)"
            @blur="setFocused(false)"
        />
        <div v-if="maxLength" class="io-limited-textarea__length">
            {{ $t('{number} Characters', { number: currentLength + '/' + maxLength }) }}
        </div>
    </div>
</template>

<script lang="ts" src="./LimitedTextarea.ts"></script>
<style lang="scss" src="./LimitedTextarea.scss" scoped></style>
