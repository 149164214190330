<template>
    <RightModal
        class="io-invoice-creation-slideout"
        width="600px"
        showDivider
        @close="$emit('close')"
    >
        <template #header>
            <div class="io-invoice-creation-slideout__header">
                <div class="io-invoice-creation-slideout__title">{{ $t('Create') }}</div>
                <div class="io-invoice-creation-slideout__actions">
                    <IOButton
                        :disabled="disableNextStep"
                        @click="defineNextStep()"
                    >
                        {{ $t('Next Step') }}
                    </IOButton>
                    <span class="icon-cross" @click="$emit('close')"/>
                </div>
            </div>
        </template>
        <template #default>
            <div class="io-invoice-creation-slideout__body">
                <StepSlideout
                    v-if="loaded"
                    :selectOptions="selectOptions"
                    @flowCompleted="handleFlowCompleted"
                >
                    <template #customMessage>
                        <div  class="io-invoice-creation-slideout__warning" @click.stop>
                            <span>{{ $t('No executed contracts.') }}&nbsp;</span>
                            <router-link :to="{ name: ContractRouteName.ProjectContractsList, params: { pid: $route.params.pid } }">
                                {{ $t('Project Contracts') }}&nbsp;<span class="icon-external-link"/>
                            </router-link>
                        </div>
                    </template>
                </StepSlideout>
            </div>
        </template>
    </RightModal>
</template>

<script lang="ts" src="./InvoiceCreationSlideout.ts"></script>
<style lang="scss" src="./InvoiceCreationSlideout.scss" scoped></style>
