export const getChatType = state => {
    return state.chatType
}

export const getChatThreadId = state => {
    return state.chatThreadId
}

export const getChatThreadName = state => {
    return state.chatThreadName
}

export const getChatThreadType = state => {
    return state.chatThreadType
}
