import { defineComponent } from 'vue'
import getCascadeAnimationDelay from '@/helpers/getCascadeAnimationDelay.ts'
import type { Sheet } from '@/components/drawing-reference-select/interfaces/Sheet.ts'

export default defineComponent({
    name: 'SheetsGridView',
    props: {
        sheets: {
            type: Array as () => Sheet[],
            default: () => [],
        },
    },
    emits: ['select'],
    setup () {
        return {
            getCascadeAnimationDelay,
        }
    },
})
