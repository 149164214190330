<template>
    <filterSection :title="title">
        <template #default>
            <template v-for="(item, key, index) in preparedOptions" :key="key">
                <Radio
                    v-if="index < maxLength || showMore"
                    v-model="radioValue"
                    :labelText="prepareName(item)"
                    :radioValue="key"
                    :inputName="id"
                />
            </template>
            <div v-if="Object.keys(preparedOptions).length > maxLength" class="io-filter-section__show-more" @click="toggleShowMore" :class="{ 'io-active': showMore }">
                <i class="fas fa-chevron-down"></i> {{ showMore ? $t('Show Less') : $t('Show More') }}
            </div>
        </template>
    </filterSection>
</template>

<script>
    import filterSection from './filterSection.vue'

    export default {
        name: 'FilterRadio',
        emit: [ 'update:modelValue' ],
        components: {
            filterSection
        },
        data () {
            return {
                showMore: false
            }
        },
        props: {
            title: {
                type: String,
                required: true
            },
            modelValue: {
                required: true
            },
            options: {
                required: true,
                type: Object
            },
            translate: {
                required: false,
                default: false
            },
            returnNumber: {
                required: false,
                default: false
            },
            maxLength: {
                required: false,
                default: 3
            }
        },
        computed: {
            radioValue: {
                get () {
                    return this.modelValue
                },
                set (val) {
                    this.$emit('update:modelValue', this.returnNumber ? parseInt(val) : val)
                }
            },
            id () {
                return this.generateUuid()
            },
            preparedOptions () {
                if (Array.isArray(this.options)) {
                    return this.options.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {})
                }
                return this.options
            }
        },
        methods: {
            prepareName (name) {
                if (this.translate) {
                    return this.$t(name)
                }
                return name
            },
            toggleShowMore () {
                this.showMore = !this.showMore
            }
        }
    }
</script>
