<template>
    <td class="io-tags">
        <template v-if="data && data.length">
            <div
                class="io-tags__container"
                :class="{'io-tags__container--wrap': wrap}"
            >
                <template v-for="(tag, index) in data.slice(0, maxLength)" :key="index">
                    <div
                        v-tooltip.top="{ content: tag, visible: tag.length > maxTagLength}"
                        class="io-status-pill io-grey"
                    >
                        {{ tagText(tag) }}
                    </div>
                </template>

                <div
                    v-if="data.length > maxLength"
                    class="io-status-pill io-grey"
                    v-tooltip="remainingTagsTooltipText"
                >
                    {{ `+${data.length - maxLength}` }}
                </div>
            </div>
        </template>

        <template v-else>-</template>
    </td>
</template>

<script lang="ts" src="./Tags.ts"></script>
<style lang="scss" src="./Tags.scss" scoped></style>
