import { cloneDeep, omit } from 'lodash'

import ModalFilters from '@/components/ModalFilters/ModalFilters.vue'
import FilterCheckboxes from '@/components/ModalFilters/filters/filterCheckboxes.vue'
import FilterDateTimePicker from '@/components/ModalFilters/filters/filterDateTimePicker.vue'
import MultiselectFilter from '@/components/ModalFilters/filters/filterMultiselect.vue'
import { SetType } from '@/io-modules/drawings/enums/SetType'

export default {
    name: 'Filters',
    components: {
        ModalFilters,
        FilterCheckboxes,
        FilterDateTimePicker,
        MultiselectFilter,
    },
    inject: ['mentionUsers'],
    props: {
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        defaultFilters: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        isDrawingSetConstructionType (): boolean {
            return this.model.drawing_set_type === SetType.CONSTRUCTION
        },
        showFilters: (self): unknown => self.$store.getters['lazyList/showFilters'],
        model: (self): unknown => self.$store.getters['drawingMarkup/getModel'],
        countFilters: (self): number => {
            let val = 0

            Object.entries(self.modelValue).forEach((item: unknown) => {
                // count array
                if (Array.isArray(item[1])) {
                    val += item[1].length
                    return
                }
                // count single string or number
                if (typeof item[1] === 'number' || (typeof item[1] === 'string' && item[1].length)) {
                    val += 1
                    return
                }
                // count datetime range
                if (item[1] && item[1].end !== null) {
                    val += 1
                }
            })

            return val
        },
        unsupportedFilters (): string[] {
            const unsupportedFilters = [
                'punch',
                this.isDrawingSetConstructionType
                    ? 'rfi'
                    : ['rfi', 'rfi_v2', 'rfi_v2_solution'],
            ]
            return unsupportedFilters.flat()
        },
        pinTypes (): { [key: string]: string } {
            return this.model.enum.type.values
                ? omit(this.model.enum.type.values, this.unsupportedFilters)
                : {}
        },
    },
    methods: {
        async closeModal (): Promise<void> {
            await this.$store.dispatch('lazyList/setShowFilters', false)
        },
        clearAllFilters (): void {
            this.$emit('update:modelValue', cloneDeep(this.defaultFilters))
        },
        applyFilters (): void {
            this.$emit('applyFilters')
            this.closeModal()
        },
    },
}
