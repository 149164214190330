<template>
    <div class="io-single-company" data-testid="single-company">
        <div class="io-company-container" :class="{'io-multiselect__name' : !hideDetails}">
            <div v-if="logo"
                 class="io-single-option io-option-avatar"
                 :style="getBackgroundCompanyLogo(logo)">
            </div>
            <initials
                v-else
                :bigAvatar="true"
                :fullName="name"
                class="io-single-option__logo"
                :class="{ 'io-offsystem-logo': offSystem}"
            />
            <template v-if="hideDetails">
                {{ name }}
            </template>
            <template v-else>
                <div class="io-single-option__text-wrapper">
                    <div class="io-single-option__title-row">
                        <div class="io-single-option__title">
                            {{ name }}
                        </div>
                    </div>
                    <div v-if="!hideDetails" class="io-single-option__company-row">
                        <div class="io-single-option__company-row__pill">
                            <span v-if="offSystem" class="io-small-pill io-grey">
                                {{ $t('OFF-SYSTEM') }}
                            </span>

                            <span
                                v-else class="io-small-pill"
                                :class="getAccentPillColor(workspaceType)"
                            >
                                {{ workspaceType }}
                            </span>
                        </div>

                        <div class="io-single-option__company-row__address">
                            {{ getCompanyAddress(address) }}
                        </div>
                    </div>
                </div>
            </template>
        </div>

    </div>
</template>

<script lang="ts" src="./SingleCompany.ts"></script>
<style lang="scss" src="./SingleCompany.scss" scoped></style>
