import { defineComponent } from 'vue'
import LogoSize from '@/io-modules/bid-requests/components/company-logo/enums/LogoSize.ts'

export default defineComponent({
    name: 'CompanyLogo',
    props: {
        logo: { type: String, required: true },
        size: { type: String as () => LogoSize, default: LogoSize.BIG },
    },
    computed: {
        sizeClassName (): string {
            return `io-${ this.size }`
        },
    }
})
