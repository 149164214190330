import { defineComponent, PropType } from 'vue'
import Radio from '@/components/atoms/Radio/Radio.vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import { ItemData } from './interfaces/ItemData'
import { LabelSize, LabelType } from '@/constants/LabelConstants'
import IOLabel from '@/components/atoms/IOLabel/IOLabel.vue'
import { AvatarType } from '@/constants/AvatarConstants.ts'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'

export default defineComponent({
    name: 'ChooseItemCard',
    components: {
        Avatar,
        Radio,
        AlertBox,
        IOLabel,
    },
    props: {
        items: {
            type: Array as PropType<ItemData[]>,
            default: () => [],
        },
        modelValue: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: ''
        },
        showRequiredLabelMark: {
            type: Boolean,
            default: false,
        },
        labelSize: {
            type: String as PropType<LabelSize>
        },
        showLogo: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    data () {
        return {
            AvatarType,
            LabelType
        }
    },
    computed: {
        value: {
            get (): string {
                return this.modelValue
            },
            set (value: string): void {
                if (value !== this.modelValue) {
                    this.$emit('update:modelValue', value)
                }
            }
        },
    },
    methods: {
        selectItem (item: ItemData): void {
            if (!item.disabled) {
                this.$emit('update:modelValue', item.id)
            }
        },

        goToLink (item: ItemData): void {
            if (!item.info || !item.info.linkData) {
                return
            }

            const route = this.$router.resolve(item.info.linkData)
            window.open(route.href, '_blank', 'noopener')
        }
    }
})
