<template>
    <filterSection :title="title">
        <template #default>
            <treeselect
                v-model="selectedOptions"
                :multiple="true"
                :options="options"
                placeholder="Filter by Business Unit"
                :normalizer="normalizer"
                :default-expand-level="1"
                :show-count="true"
                :alwaysOpen="false"
                :flat="false"
                :disable-branch-nodes="false"
                :searchable="true"
                :clearable="false"
            />
        </template>
    </filterSection>
</template>

<script>
import filterSection from './filterSection.vue'
import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'

export default {
    name: 'FilterTree',
    emit: [ 'update:modelValue' ],
    components: {
        filterSection,
        Treeselect
    },
    props: {
        title: {
            type: String,
            required: true
        },
        modelValue: {
            required: true
        },
        addFieldToLabel: {
            type: String,
            required: false,
            default: null,
        },
        options: {
            required: true,
            type: Object
        }
    },
    computed: {
        selectedOptions: {
            get () {
                return this.modelValue
            },
            set (val) {
                this.$emit('update:modelValue', val)
            }
        },
    },
    methods: {
        normalizer (node) {
            return {
                id: node._id,
                label: this.addFieldToLabel && node[this.addFieldToLabel] ? `${node.name} - ${node[this.addFieldToLabel]}` : node.name,
                children: node.children
            }
        }
    }
}
</script>
<style lang="scss">
.io-filters {
    .vue-treeselect {
        &__input {
            border: none;
            margin: 0;
            height: 22px;
            padding-left: 0 !important;
        }

        &__placeholder {
            color: rgba(0,0,0,.54);
        }

        &__multi-value-label {
            color: var(--accent);
        }

        &__checkbox {
            width: 16px;
            height: 16px;
            border: 1px solid #9a9da4;
            border-radius: 4px;
        }

        &__checkbox--checked {
            border-color: var(--accent);
            background: var(--accent);
        }

        &__checkbox--indeterminate {
            border-color: var(--accent);
            background: var(--accent);
        }

        &__option {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        &__label-container:hover .vue-treeselect__checkbox--unchecked {
            border-color: var(--accent);
        }

        &__label-container:hover .vue-treeselect__checkbox--checked {
            border-color: var(--accent-hover);
            background: var(--accent-hover);
        }

        &__minus-mark {
            left: 3px;
            top: 3px;
        }

        &__check-mark {
            left: 3px;
            top: 3px;
        }

        &__label {
            color: #63666e;
        }

        &__icon {
            color: var(--accent);
        }

        &__control {
            border-color: #b9beca !important;
        }

        &__multi-value-item {
            color: var(--accent);
            background-color: rgba((var(--accent-rgb)), .2);
        }
    }
}
</style>
