import { defineComponent, PropType } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import ContentTwoSidesTemplate from '@/components/content-two-sides-template/ContentTwoSidesTemplate.vue'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn.vue'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes'
import { LabelType } from '@/constants/LabelConstants'
import dateTimeHelper from '@/helpers/dateTime'
import ProjectFundingProviderFund from '@/io-modules/project-funding/interfaces/ProjectFundingProviderFund'
import { Contact } from '@/interfaces/components/invite-modal/Contact'
import projectFundingClient from '@/io-modules/project-funding/api-clients/projectFundingClient'
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import TooltipIcon from '@/components/atoms/TooltipIcon/TooltipIcon.vue'
import FilesComponent from '@/components/files-new/files.vue'
import { mapActions as piniaMapActions, mapState as piniaMapState } from 'pinia'
import { projectFundingStore } from '@/io-modules/project-funding/stores/projectFundingStore'
import { ProjectFundingAllocatedRows } from '@/io-modules/project-funding/interfaces/ProjectFundingAllocatedRows'
import CreateProjectSpecFundRight from '../../../components/create-project-spec-fund-right/CreateProjectSpecFundRight.vue'
import appTypes from '@/base/appTypes'
import LimitedTextarea from '@/components/atoms/LimitedTextarea/LimitedTextarea.vue'
import { FundTypes } from '@/io-modules/project-funding/enums/ProjectFundingEnums'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import FieldNumeric from '@/components/fields/fieldNumeric.vue'
import FinancialTableCalculator from '@/components/financial-table-calculator/FinancialTableCalculator.vue'
import FinancialTableCalculatorLines
    from '@/components/financial-table-calculator/interfaces/FinancialTableCalculatorLines.ts'
import { InviteModalTabs } from '@/components/invite-modal-v3/enums/InviteModalTabs.ts'


export default defineComponent({
    name: 'ManageProjectSpecFundModal',
    components: {
        FinancialTableCalculator,
        FieldNumeric,
        Avatar,
        IOSingleInfoRow,
        CreateProjectSpecFundRight,
        FilesComponent,
        TooltipIcon,
        FieldDateTime,
        FormInputColumn,
        InvitePreview,
        ContentTwoSidesTemplate,
        IOModal,
        LimitedTextarea
    },
    props: {
        fundInfoForEdit: {
            type: Object as PropType<ProjectFundingProviderFund>
        }
    },
    data () {
        return {
            PreviewTypes: PreviewTypes,
            invitationClientKey: InviteModalClientKeys.PROJECT_FUNDING_SOURCES_PROJECT_SPEC_FUNDS,
            LabelType: LabelType,
            dateOptions: {
                formatted: dateTimeHelper.getDateFormat(),
                format: 'YYYY-MM-DD',
                'only-date': true,
            },
            projSpecFundData: {
                id: crypto.randomUUID(),
                name: null,
                account_number: null,
                allocated_amount: null,
                start_date: null,
                expiration_date: null,
                contact_id: null,
                note: null,
                lines: [],
                temporary_attached_documents: [],
            } as ProjectFundingProviderFund,
            allocationTableData: { lines: [] } as ProjectFundingAllocatedRows,
            infoData: null,
            fundingPercentFromBudget: 0,
            appTypes: appTypes,
            docUnicId: 'add-project-specific-docs-' + crypto.randomUUID(),
            lessPaidToDate: { valid: true, text: this.$t('Cannot be less than Paid to Date') },
            moreCapitalFund: { valid: true, text: this.$t('Cannot be more than Total Fund Amount') },
            allocatedLessZero: this.$t('Allocation to a project must be greater than {currency}', { currency: this.$filters.formatCurrency(0) }),
            allocationModalTitles: {
                table: 'Allocation to Project',
                allocatedSum: 'Allocation to Project'
            },
            isLoading: true,
            InviteModalTabs: InviteModalTabs
        }
    },
    computed: {
        ...mapState('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id
        }),
        ...piniaMapState(projectFundingStore, [
            'isAllocationModalOpened'
        ]),
        ...mapState('filesComponent', {
            deletedFiles: state => state.trash_documents
        }),
        allowToCreate (): boolean {
            return (!!(this.projSpecFundData.name &&
                this.projSpecFundData.contact_id &&
                this.projSpecFundData.allocated_amount &&
                this.isValidAllocation))
        },
        isForEditCapFund (): boolean {
            return !!this.fundInfoForEdit && this.fundInfoForEdit.type === FundTypes.CAPITAL
        },
        isForEditProjFund (): boolean {
            return !!this.fundInfoForEdit && this.fundInfoForEdit.type === FundTypes.PROJECT_SPECIFIC
        },
        isValidAllocation (): boolean {
            if (this.projSpecFundData.allocated_amount !== null && this.projSpecFundData.allocated_amount <= 0) {
                return false
            }

            if (this.fundInfoForEdit) {
                this.lessPaidToDate.valid = this.projSpecFundData.allocated_amount >= parseFloat(this.projSpecFundData.summary.total_paid_to_date_amount)
                this.moreCapitalFund.valid = this.projSpecFundData.allocated_amount <= parseFloat(this.projSpecFundData.summary.total_fund_amount)

                return this.projSpecFundData.type === FundTypes.CAPITAL ? this.lessPaidToDate.valid && this.moreCapitalFund.valid : this.lessPaidToDate.valid
            } else {
                return true
            }
        },
        errorMessage (): string {
            if (!this.lessPaidToDate.valid) {
                return this.lessPaidToDate.text
            } else if (!this.moreCapitalFund.valid) {
                return this.moreCapitalFund.text
            } else if (this.projSpecFundData.allocated_amount <= 0) {
                return this.allocatedLessZero
            }
            return null
        }
    },
    watch: {
        'projSpecFundData.allocated_amount' (): void {
            this.getInfoData()
        },
        deletedFiles: {
            handler (): void {
                this.deletedFiles[this.projSpecFundData.id].forEach(elem => {
                    this.onFileRemove(elem._id)
                })
            },
            deep: true
        }
    },
    async beforeMount () {
        if (this.fundInfoForEdit) {
            this.projSpecFundData = { ...this.fundInfoForEdit,
                id: this.fundInfoForEdit.fund.id,
                contact: this.fundInfoForEdit.fund.contact,
                account_number: this.fundInfoForEdit.fund.account_number,
                expiration_date: this.fundInfoForEdit.fund.expiration_date,
                start_date: this.fundInfoForEdit.fund.start_date,
                name: this.fundInfoForEdit.fund.name,
                contact_id: this.fundInfoForEdit.fund.contact.id,
                temporary_attached_documents: [],
                note: null
            }

            try {
                const { data } = await projectFundingClient.getProjectFundDetailsFiles(this.projectLocalID, this.projSpecFundData.id)
                this.setFiles(this.projSpecFundData.id, { model_documents: data.documents })
                data.documents.files.map(file => {
                    this.projSpecFundData.temporary_attached_documents.push(file._id)
                })
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.isLoading = false
            }

            this.allocationTableData.lines = this.fundInfoForEdit.lines.map(line => {
                return { ...line,
                    unit_quantity: parseFloat(line.unit_quantity)
                }
            })
        } else {
            await this.getDefaultProvider()
        }
    },
    mounted () {
        this.getInfoData()
    },
    methods: {
        ...piniaMapActions(projectFundingStore, [
            'setShowAllocationModal',
            'setDataUpdation'
        ]),
        closeModal (): void {
            this.$emit('closeModal')
        },
        onSelectProvider (contact: Contact): void {
            if (contact) {
                const selectedContact = contact.bookmarked[0]
                this.projSpecFundData.contact_id = selectedContact.id
                this.projSpecFundData.contact = selectedContact
            }
        },
        clearProvider (): void {
            this.projSpecFundData.contact_id = null
        },
        modifyDate (date: string): string {
            if (!date) {
                return
            }
            return dayjs(date).format('YYYY-MM-DD ')
        },
        onFileAdd (file: any): void {
            this.projSpecFundData.temporary_attached_documents.push(file._id)
        },
        onFileRemove (fileId: string): void {
            this.projSpecFundData.temporary_attached_documents = this.projSpecFundData.temporary_attached_documents.filter((id: string) => id !== fileId)
        },
        toggleAllocationModal (): void {
            this.setShowAllocationModal(!this.isAllocationModalOpened)
        },
        getInfoData (): void {
            this.infoData = {
                allocated_amount: this.projSpecFundData.allocated_amount || 0,
                isEdit: !!this.fundInfoForEdit,
                paid_to_date: this.fundInfoForEdit ? this.fundInfoForEdit.summary?.total_paid_to_date_amount : null,
            }
        },
        saveAllocatedLines (data: FinancialTableCalculatorLines[] | number, field: string): void {
            if (field === 'lines') {
                this.allocationTableData.lines = data
                this.projSpecFundData.lines = data
            } else if (field === 'allocated_amount') {
                this.projSpecFundData.allocated_amount = data
            }
        },
        clearLinesOnInput (): void {
            this.allocationTableData.lines = []
            this.projSpecFundData.lines = []
        },
        saveFundingPercentFromBudget (value: number): void {
            this.fundingPercentFromBudget = value
        },
        async createNewCapitalFund (): Promise<void> {
            const data = {
                ...this.projSpecFundData,
                allocated_amount: this.projSpecFundData.allocated_amount ? this.projSpecFundData.allocated_amount.toString() : '0',
                start_date: this.modifyDate(this.projSpecFundData.start_date) || null,
                expiration_date: this.modifyDate(this.projSpecFundData.expiration_date) || null,
                lines: this.projSpecFundData.lines.map(line => {
                    return {
                        ...line,
                        total: line.total.toString(),
                        unit_amount: line.unit_amount.toString(),
                        unit_quantity: line.unit_quantity.toString(),
                    }
                })
            }

            try {
                if (!this.fundInfoForEdit) {
                    await projectFundingClient.createProjSpecFund(this.projectLocalID, data)
                    this.setDataUpdation(true)
                    this.closeModal()
                    this.showNotification('success', this.$t('Project Specific Fund has been added!'))
                } else {
                    await projectFundingClient.updateProjectFundDetails(this.projectLocalID, this.projSpecFundData.id, data)
                    this.setDataUpdation(true)
                    this.$emit('fundChanged')
                    this.closeModal()
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },

        async getDefaultProvider (): Promise<void> {
            try {
                const { data } = await projectFundingClient.getDefaultProvider(this.projectLocalID)
                if (data && Object.keys(data).length > 0) {
                    this.projSpecFundData = {
                        contact: data,
                        contact_id: data.id
                    }
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.isLoading = false
            }
        }
    },
})
