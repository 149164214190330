<template>
    <div
        class="io-notification"
        :class="{ 'io-notification_unread': notificationData.isUnread }"
    >
        <div
            v-if="notificationData.isUnread"
            class="io-notification__unread-dot"></div>

        <Invitation
            v-if="notificationData.type === 'invitation'"
            :notificationData="notificationData"
            @notificationCenterClose="onNotificationsCenterClose"
            @updateNotification="onNotificationUpdate"
        />

        <Message
            v-if="notificationData.type === 'chat'"
            :notificationData="notificationData"
            @notificationCenterClose="onNotificationsCenterClose"
        />

        <General
            v-if="notificationData.type === 'notification'"
            :notificationData="notificationData"
            @notificationCenterClose="onNotificationsCenterClose"
        />

        <div class="io-notification__date">
            {{ formatDate($filters.agoDate(notificationData.date)) }}
        </div>
    </div>
</template>

<script src="./Notification.ts"></script>
<style lang="scss" src="./Notification.scss" scoped></style>
