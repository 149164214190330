import { defineComponent, PropType } from 'vue'
import ProgressBar from '@/components/atoms/progress-bar/ProgressBar.vue'
import { ProgressBarItemInterface } from '@/components/atoms/progress-bar/ProgressBarItemInterface.ts'
import SingleChecklistItem from './parts/SingleChecklistItem.vue'

import { mapGetters } from 'vuex'
import {
    mapState as mapStatePinia,
    mapActions as mapActionsPinia,
} from 'pinia'
import { useProjectChecklistStore } from '@/modules/projects/store/projectChecklistStore.ts'

export interface EventChecklistInterface {
    needRefresh: boolean,
    data: {
        completed: number,
        projectId: string,
        type: string,
    }
}

export default defineComponent({
    components: {
        ProgressBar,
        SingleChecklistItem,
    },
    data () {
        return {
            showModal: false,
        }
    },
    computed: {
        ...mapStatePinia(useProjectChecklistStore, [
            'checklistElementsData',
        ]),
        ...mapGetters(
            'appStore', ['getAuthData']
        ),
        progressBarData (): ProgressBarItemInterface[] {
            return [
                {
                    value: this.completedItemsPercentage,
                    color: 'var(--success-text)',
                }
            ]
        },
        completedItems (): number {
            return this.checklistElementsData.filter(item => item.completed === 1).length
        },
        completedItemsPercentage (): number {
            return Math.round((this.completedItems / this.checklistElementsData.length) * 100)
        },
        isNextForAction () {
            return this.checklistElementsData.find(item => item.completed === 0)
        }
    },
    mounted () {
        this.$echo.private(`WorkspaceChannel.${ this.getAuthData.company_mongo_id }`)
            .listen('ProjectChecklistItem.Events.ProjectChecklistItemBroadcastEvent', this.handleEvent)
    },
    beforeUnmount () {
        this.$echo.private(`WorkspaceChannel.${ this.getAuthData.company_mongo_id }`)
            .stopListening('ProjectChecklistItem.Events.ProjectChecklistItemBroadcastEvent')
    },
    methods: {
        ...mapActionsPinia(useProjectChecklistStore, [
            'updateCompletedItems',
            'updateSingleItem',
        ]),
        closeModal (): void {
            this.showModal = false
        },
        handleEvent (data: EventChecklistInterface): void {
            if (data) {
                this.updateSingleItem(data.data)
            }
        },
    }
})
