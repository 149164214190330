import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { Company, Person } from '@/io-modules/rfis/interfaces/ProjectDirectory'


export default {
    getCompanies (projectIdLocal: string): AxiosPromise<{ items: Company[] }> {
        return client.get(`ca/project/${ projectIdLocal }/dictionaries/project-directory/companies`)
    },
    getPersons (projectIdLocal: string): AxiosPromise<{ items: Person[] }> {
        return client.get(`ca/project/${ projectIdLocal }/dictionaries/project-directory/persons`)
    },

    /**
     * @deprecated
     * Use getOfficialReviewers (supports invitations) from RFI client instead
     */
    getRfiOfficialReviewers (projectIdLocal: string): AxiosPromise<{ items: Person[] }> {
        return client.get(`ca/project/${ projectIdLocal }/dictionaries/project-directory/rfi-official-reviewers-persons`)
    },

    /**
     * @deprecated
     * Use getManagers (supports invitations) from RFI client instead
     */
    getRfiManagers (projectIdLocal: string): AxiosPromise<{ items: Person[] }> {
        return client.get(`ca/project/${ projectIdLocal }/dictionaries/project-directory/rfi-managers-persons`)
    },
}
