<template>
    <div class="io-filters" v-if="rendered">
        <transition v-if="areFiltersEmpty" name="loader-mask">
            <div class="io-popup-modal__loader-mask">
                <div class="io-loader-small" />
            </div>
        </transition>
        <div class="io-single-filter">
            <template v-for="(filter, index) in filtersSchema" :key="index">
                <div v-if="isFilterVisible(filter)" class="io-single-category" :class="filter.className">
                    <template v-if="filter.type === 'radio'">
                        <FilterRadio
                            :key="3"
                            :maxLength="5"
                            :translate="true"
                            :title="$t(filter.label)"
                            :options="findFilterOptions(filter)"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-if="filter.type === 'checkbox'">
                        <FilterCheckboxes
                            :title="$t(filter.label)"
                            :options="findFilterOptions(filter)"
                            :maxLength="6"
                            :returnNumber="filter.returnNumber"
                            v-model="filtersSelected[filter.field]"
                            :field="filter.field"
                            :dynamicClass="filter.dynamicClass"
                            :translate="filter.translate"
                            :hideSelectAllCheckboxes="getHideSelectAllCheckboxes(filter)"
                        />
                    </template>

                    <template v-if="filter.type === 'toggle_switch'">
                        <ToggleSwitchFilter
                            :title="$t(filter.label)"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'status'">
                        <FilterStatuses
                            :title="$t(filter.label)"
                            :options="findFilterOptions(filter)"
                            :translate="true"
                            :pillsColorFunction="pillsColorFunction"
                            :returnNumber="filter.returnNumber"
                            v-model="filtersSelected[filter.field]"
                            :hideSelectAllStatus="getHideSelectAllStatus(filter)"
                        />
                    </template>

                    <template v-else-if="filter.type === 'color-dot'">
                        <FilterColorDot
                            :title="$t(filter.label)"
                            :options="findFilterOptions(filter)"
                            :translate="true"
                            :colorDotFunction="colorDotFunction"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'date'">
                        <filterDateTimePicker
                            :title="$t(filter.label)"
                            :options="{ formatted: 'YYYY-MM-DD', 'only-date': true, range: true, placeholder: filter.placeholder }"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'multiselect'">
                        <FilterMultiselect
                            :disabled="filter.disabled"
                            :title="$t(filter.label)"
                            :clearSelection="filter.clearSelection"
                            :placeholder="filter.placeholder"
                            :selectedLang="filter.selectedTitle"
                            :selectedLangPlural="filter.selectedTitlePlural"
                            :options="findFilterOptions(filter)"
                            :map-value-by="filter.mapValueBy"
                            :settings="{ multiple: true }"
                            :groupLabel="filter.groupLabel || ''"
                            :groupValues="filter.groupValues || ''"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>
                    <template v-else-if="filter.type === 'custom-multiselect-bic'">
                        <FilterCustomMultiselectBic
                            :disabled="filter.disabled"
                            :title="$t(filter.label)"
                            :placeholder="filter.placeholder"
                            :options="findFilterOptions(filter)"
                            :avatarType="filter.additional?.avatarType"
                            :tooltipText="filter.additional?.tooltipText"
                            :labelName="filter.additional?.labelName"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'tree_multi'">
                        <FilterTree
                            :title="$t(filter.label)"
                            :options="findFilterOptions(filter)"
                            :addFieldToLabel="filter.addFieldToLabel ?? null"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'tags'">
                        <FilterTags
                            :title="$t(filter.label)"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'radio-pill'">
                        <FilterRadioPill
                            :title="$t(filter.label)"
                            :options="findFilterOptions(filter)"
                            :translate="false"
                            :pillsColorFunction="pillsColorFunction"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>
                    <template v-else-if="filter.type === 'checkbox-all'">
                        <FilterCheckboxesAll
                            :title="$t(filter.label)"
                            :options="findFilterOptions(filter)"
                            :translate="true"
                            v-model="filtersSelected[filter.field]"
                            :hideSelectAllStatus="getHideSelectAllStatus(filter)"
                        />
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" src="./Filters.ts"></script>
<style lang="scss" src="./Filters.scss" scoped />
