<template>
	<div class="combo-table-wrapper">
		<div class="component-wrapper"
             v-if="data.content !== undefined && data.content.switch !== undefined && data.content.switch.enabled === true &&  data.content.switch.showLogic === true">
            <!--  undefined check added to avoid errors -->
            <!-- v-if="data.content.switch.enabled === true &&  data.content.switch.showLogic === true"> -->
            <vue-form-generator :schema="schema" :model="this.instance.$parent.model"></vue-form-generator>
		</div>
        <template v-if="data.content !== undefined && data.content.isDisplayed === true">
        <!--  undefined check added to avoid errors -->
        <!-- <template v-if="data.content.isDisplayed === true"> -->
            <h2 v-if="data.content.title.enabled === true">{{ data.content.title.label }}</h2>
			<vue-form-generator :schema="schemaListing" :model="this.instance.$parent.model"></vue-form-generator>
		</template>
	</div>
</template>

<script>
    import VueFormGenerator from '@/plugins/form-generator/formGenerator.vue'
    import { getCurrentInstance } from 'vue'
    export default {
        mixins: [],
        components: {
            'vue-form-generator': VueFormGenerator
        },
        props: {
            data: {
                type: Object
            },
            isView: {
                type: Boolean,
                default: false
            }
        },
        watch: {},
        data () {
            return {
                sectionsTotal: '',
                isGeneral: false,
                packStructure: {},
                schema: {
                    separate: true,
                    fields: []
                },
                schemaListing: {
                    separate: true,
                    fields: []
                },
                content: {},
                structure: {},
                instance: getCurrentInstance().proxy,
            }
        },
        methods: {
            countPhases () {
                return 0
            }
        },
        computed: {

        },
        mounted: function () {
            // optional toggler
            // undefined check added to avoid errors
            if (this.data.content !== undefined) {
                if (this.data.content.switch !== undefined) {
                    if (typeof this.instance.$parent.fields !== 'undefined') {
                        this.schema.fields.push(this.instance.$parent.fields[this.data.content.switch.field])
                    } else {
                        this.schema.fields.push(this.instance.$parent.$parent.fields[this.data.content.switch.field])
                    }
                }

                // match with model
                var field = this.data.content.modelMatch

                // undefined check added to avoid errors
                if (typeof this.instance.$parent.fields !== 'undefined') {
                    if (this.instance.$parent.fields[field] !== undefined) {
                        // bind values
                        this.instance.$parent.fields[field].mode = 'combo'
                        this.instance.$parent.fields[field].layout = 'table'
                        this.instance.$parent.fields[field].inputName = field
                        this.instance.$parent.fields[field].generate = this.data
                    }
                } else {
                    if (this.instance.$parent.$parent.fields[field] !== undefined) {
                        // bind values
                        this.instance.$parent.$parent.fields[field].mode = 'combo'
                        this.instance.$parent.$parent.fields[field].layout = 'table'
                        this.instance.$parent.$parent.fields[field].inputName = field
                        this.instance.$parent.$parent.fields[field].generate = this.data
                    }
                }
            }

            var vNode = null

            if (typeof this.instance.$parent.vFields !== 'undefined') {
                this.instance.$parent.vFields.forEach(function (node) {
                    if (node.inputName === field) {
                        vNode = node
                    }
                })
            } else {
                this.instance.$parent.$parent.vFields.forEach(function (node) {
                    if (node.inputName === field) {
                        vNode = node
                    }
                })
            }

            if (vNode !== false) {
                this.schemaListing.fields.push(vNode)
            }

            if (this.isView === true && this.data.content !== undefined) {
                this.data.content.isLocked = true
                if (this.data.content.totalItems === 0) {
                    this.data.content.isDisplayed = false
                } else {
                    this.data.content.isDisplayed = true
                }
                this.data.content.removeRows = false
                this.data.content.removeSubRows = false
            }
        }
    }
</script>

<style lang="scss" scoped>
	.combo-table-wrapper {
		h2 {
			margin-top: 0;
			padding-top: 0;
		}
		h3 {
			margin-top: 0;
			padding-top: 0;
		}
		table.combo-component-table {
			width: 100%;
			margin: 0;
			margin-bottom: 20px;
		}
	}
</style>
