<template>
    <div class="io-documents-show">
        <PageHeader
            v-if="!noHeader"
            :title="pageTitle"
            :route-back="routeBack"
            @click-back="handleBackClick"
        >
            <template #actions>
                <div class="io-actions-header">
                    <DropdownButtonNew
                        buttonClass="io-btn-light"
                        fontAwesomeClass="icon-more-vert-2"
                        :iconMode="true"
                        :isInTopBar="true"
                        :menuAlign="'right'"
                        :disabled="!document"
                    >
                        <div class="io-single-option" @click="renameModalShow = true">
                            <i class="icon-edit-2"/>
                            {{ $t('Rename') }}
                        </div>

                        <div class="io-single-option" @click="downloadModalShow = true">
                            <i class="icon-file-download"/>
                            {{ $t('Download') }}
                        </div>

                        <div v-if="fileBelongsToCurrentUser" class="io-single-option" @click="onClickSharingAndPermissions">
                            <i class="icon-share"/>
                            {{ $t('Sharing & Permissions') }}
                        </div>
                    </DropdownButtonNew>
                </div>
            </template>
        </PageHeader>

        <div class="io-documents-show__content io-comment-cursor">
            <div class="io-web-viewer-wrapper relative">
                <AlertBox
                    v-if="showAlertBox"
                    class="mb-4"
                    icon="icon-mouse"
                    dismissible
                    @hide="rememberChoice"
                >
                    <div class="text-center">{{ $t('Use your right click on the sheet to Add Quick Pin') }}</div>
                </AlertBox>

                <AlertBox v-if="isCommentBeingAdded" class="io-web-viewer-wrapper__adding-annotation-infobox" icon="icon-mouse">
                    <div class="text-center">{{ $t('Click to place a Pin on the Drawing') }}</div>
                </AlertBox>

                <DocumentFileViewer
                    v-if="document?.id"
                    ref="documentFileViewer"
                    :url="documentUrl"
                    :disabledElements="disabledElements"
                    :documentId="id"
                    :extension="fileExtension"
                    @document-loaded="setLoadingBar(false)"
                    @update-annotations="getDocumentAnnotations"
                    @trigger-adding-annotation-event="enableAddingAnnotationMode"
                />
            </div>

            <RightPanel
                v-if="document"
                :document="document"
                :view-only="viewOnly"
                @update-annotations="getDocumentAnnotations"
                @cancel-adding-annotation="deselectAnnotations"
                @select-annotation="selectAnnotation"
                @delete-basic-annotation="deleteBasicAnnotation"
            />
        </div>

        <DocumentRenameModal
            v-if="renameModalShow"
            :name="pageTitle"
            @close="renameModalShow = false"
            @save="rename"
        />

        <DocumentDownloadModal
            v-if="downloadModalShow"
            :id="id"
            @close="downloadModalShow = false"
            @download="download"
        />

        <InvitePreview
            v-if="document?.id"
            ref="invitePreview"
            :key="invitationClientKey"
            :componentKey="invitationClientKey"
            :projectGlobalId="document.project_mongo_id_global"
            :previewType="previewTypes"
            :selectMultiple="true"
            :resourceId="document.id"
            :saveFromModal="true"
            :disableBookmarking="true"
            :modelType="modelType"
            @inviteModalDone="updateInviteModalData"
        />
    </div>
</template>

<script lang="ts" src="./DocumentsShow.ts"></script>
<style lang="scss" src="./DocumentsShow.scss" scoped></style>
