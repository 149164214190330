import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import {
    ContractChangeItemPaths,
    ContractChangeItemRoutesNames,
    ContractChangesCategoryRoutes,
    RequestPackagesRoutes,
} from './interfaces/RoutesNames'

import { isFeatureFlagEnabled } from '@/modules/projects/routes/feature-flag-status'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
import appTypes from '@/base/appTypes.ts'

const appType = localStorage.getItem('appType')

const ContractChanges = () => import('@/modules/projects/modules/apps/common/contract-changes/ContractChanges.vue')
const ChangeOrders = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/ChangeOrdersView.vue')
const MyChangeOrdersV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/my-change-orders/MyChangeOrders.vue')
const ReceivedChangeOrdersV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/received-change-orders/ReceivedChangeOrders.vue')
const Amendments = () => import('@/modules/projects/modules/apps/common/contract-changes/amendments/AmendmentsView.vue')
const MyAmendments = () => import('@/modules/projects/modules/apps/common/contract-changes/amendments/my-amendments/MyAmendments.vue')
const ReceivedAmendments = () => import('@/modules/projects/modules/apps/common/contract-changes/amendments/received-amendments/ReceivedAmendments.vue')
const ContractChangeItem = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/ContractChangeItem.vue')
const ContractChangeItemSummary = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/contract-change-item-summary/ContractChangeItemSummary.vue')
const ContractChangeItemAttachedCC = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/contract-change-item-summary/contract-change-item-attached-cc/ContractChangeItemAttachedCC.vue')
const ContractChangeItemSupportingDocuments = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/contract-change-item-summary/contract-change-item-supporting-documents/ContractChangeItemSupportingDocuments.vue')
const ContractChangeItemExecutedAgreement = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/contract-change-item-summary/contract-change-item-executed-agreement/ContractChangeItemExecutedAgreement.vue')
const ContractChangeItemReferences = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/contract-change-item-summary/contract-change-item-references/ContractChangeItemReferences.vue')
const ContractChangeItemSovWbs = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/contract-change-item-sov-wbs/ContractChangeItemSovWbs.vue')
const ContractChangeItemApprovals = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/contract-change-item-approvals/ContractChangeItemApprovals.vue')
const ContractChangeItemTimeline = () => import('@/modules/projects/modules/apps/common/contract-changes/components/contract-change-item/contract-change-item-timeline/ContractChangeItemTimeline.vue')
const ContractChangeRequests = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/contract-change-requests/ContractChangeRequests.vue')
const MyRequestPackagesV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/my-change-order-request-packages/MyChangeOrderRequestPackages.vue')
const MyRequestPackagesItemV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/my-change-order-request-packages/my-request-package-item/MyRequestPackageItem.vue')
const MyRequestPackagesItemSummaryV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/my-change-order-request-packages/my-request-package-item/my-request-package-item-summary/MyRequestPackageItemSummary.vue')
const MyRequestPackagesItemRequestsV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/my-change-order-request-packages/my-request-package-item/my-request-package-item-requests/MyRequestPackageRequests.vue')
const MyRequestPackagesItemTimelineV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/my-change-order-request-packages/my-request-package-item/my-request-package-item-timeline/MyRequestPackageItemTimeline.vue')
const ReceivedChangeOrderRequestsV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/received-change-order-requests/ReceivedChangeOrderRequests.vue')
const ReceivedChangeOrderRequestPackagesItemV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/received-change-order-requests/request-package-item/ReceivedRequestPackageItem.vue')
const ReceivedChangeOrderRequestPackagesItemSummaryV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/received-change-order-requests/request-package-item/received-request-item-summary/ReceivedRequestPackageItemSummary.vue')
const ReceivedChangeOrderRequestPackagesItemTimelineV3 = () => import('@/modules/projects/modules/apps/common/contract-changes/change-orders/received-change-order-requests/request-package-item/received-request-item-timeline/ReceivedRequestItemTimeline.vue')

const routes: RouteRecordRaw[] = []

const defaultMetaChildrenChangeOrders = {
    parent: ContractChangesCategoryRoutes.CHANGE_ORDERS,
    showNotificationsForRoutes: [ContractChangeItemRoutesNames.ITEM_SUMMARY, ContractChangeItemRoutesNames.ITEM_EXECUTED_AGREEMENT, ContractChangeItemRoutesNames.ITEM_SUPPORTING_DOCUMENTS],
}

const defaultMetaChildrenAmendments = {
    parent: ContractChangesCategoryRoutes.AMENDMENTS,
    showNotificationsForRoutes: [ContractChangeItemRoutesNames.ITEM_SUMMARY, ContractChangeItemRoutesNames.ITEM_EXECUTED_AGREEMENT, ContractChangeItemRoutesNames.ITEM_SUPPORTING_DOCUMENTS],

}

const defaultMetaChildrenReceivedChangeOrders = {
    parent: ContractChangeItemRoutesNames.ITEM,
    showNotificationsForRoutes: [ContractChangeItemRoutesNames.ITEM_SUMMARY, ContractChangeItemRoutesNames.ITEM_EXECUTED_AGREEMENT, ContractChangeItemRoutesNames.ITEM_SUPPORTING_DOCUMENTS],
}

const defaultMetaChildrenMyRequestPackages = {
    parent: ContractChangeItemRoutesNames.ITEM,
}

const defaultMetaChildrenReceivedRequests = {
    parent: ContractChangeItemRoutesNames.ITEM,
}

const defaultMetaChildrenContractChangeRequests = {
    parent: ContractChangesCategoryRoutes.CONTRACT_CHANGE_REQUESTS,
}

routes.push({
    path: ContractChangesCategoryRoutes.CONTRACT_CHANGES,
    name: ContractChangesCategoryRoutes.CONTRACT_CHANGES,
    component: ContractChanges,
    meta: {
        name: 'Contract Changes',
        beta: true,
        dontShowMenu: true,
        title: 'Contract Changes',
        navigationKey: 'contract_changes',
    },
    redirect: {
        name: ContractChangesCategoryRoutes.CHANGE_ORDERS,
    },
    children: [
        {
            name: ContractChangesCategoryRoutes.CHANGE_ORDERS,
            path: ContractChangesCategoryRoutes.CHANGE_ORDERS,
            component: ChangeOrders,
            beforeEnter (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
                if (to.name !== ContractChangesCategoryRoutes.MY_CHANGE_ORDERS &&
                    to.name !== ContractChangesCategoryRoutes.RECEIVED_CHANGE_ORDERS
                ) {
                    if (appType === appTypes.TYPE_GC || appType === appTypes.TYPE_SUB || appType === appTypes.TYPE_DESIGN) {
                        next({
                            name: ContractChangesCategoryRoutes.MY_CHANGE_ORDERS,
                            params: to.params,
                            query: to.query,
                        })
                        return
                    }
                    next({
                        name: ContractChangesCategoryRoutes.RECEIVED_CHANGE_ORDERS,
                        params: to.params,
                        query: to.query,
                    })
                } else {
                    next()
                }
            },
            children: [
                {
                    path: ContractChangesCategoryRoutes.MY_CHANGE_ORDERS,
                    name: ContractChangesCategoryRoutes.MY_CHANGE_ORDERS,
                    component: MyChangeOrdersV3,
                    beforeEnter (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
                        if (to.name === ContractChangesCategoryRoutes.MY_CHANGE_ORDERS && appType === appTypes.TYPE_OWNER) {
                            next({
                                name: ContractChangesCategoryRoutes.RECEIVED_CHANGE_ORDERS,
                                params: to.params,
                                query: to.query,
                            })
                            return
                        }

                        next()
                    },
                    meta: {
                        ...defaultMetaChildrenChangeOrders,
                        name: 'My Change Orders',
                        title: 'My Change Orders',
                        tab: ContractChangesCategoryRoutes.MY_CHANGE_ORDERS,
                    },
                },
                {
                    path: ContractChangesCategoryRoutes.RECEIVED_CHANGE_ORDERS,
                    name: ContractChangesCategoryRoutes.RECEIVED_CHANGE_ORDERS,
                    component: ReceivedChangeOrdersV3,
                    meta: {
                        ...defaultMetaChildrenChangeOrders,
                        name: 'Received Change Orders',
                        title: 'Received Change Orders',
                        tab: ContractChangesCategoryRoutes.RECEIVED_CHANGE_ORDERS,
                    },
                },
            ],
        },
        {
            name: ContractChangesCategoryRoutes.AMENDMENTS,
            path: ContractChangesCategoryRoutes.AMENDMENTS,
            component: Amendments,
            beforeEnter (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
                if (to.name !== ContractChangesCategoryRoutes.MY_AMENDMENTS &&
                    to.name !== ContractChangesCategoryRoutes.RECEIVED_AMENDMENTS
                ) {
                    if (appType === appTypes.TYPE_GC || appType === appTypes.TYPE_SUB || appType === appTypes.TYPE_DESIGN) {
                        next({
                            name: ContractChangesCategoryRoutes.MY_AMENDMENTS,
                            params: to.params,
                            query: to.query,
                        })
                        return
                    }
                    next({
                        name: ContractChangesCategoryRoutes.RECEIVED_AMENDMENTS,
                        params: to.params,
                        query: to.query,
                    })
                } else {
                    next()
                }
            },
            children: [
                {
                    name: ContractChangesCategoryRoutes.MY_AMENDMENTS,
                    path: ContractChangesCategoryRoutes.MY_AMENDMENTS,
                    component: MyAmendments,
                    beforeEnter (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
                        if (to.name === ContractChangesCategoryRoutes.MY_AMENDMENTS && appType === appTypes.TYPE_OWNER) {
                            next({
                                name: ContractChangesCategoryRoutes.RECEIVED_AMENDMENTS,
                                params: to.params,
                                query: to.query,
                            })
                            return
                        }

                        next()
                    },
                    meta: {
                        ...defaultMetaChildrenAmendments,
                        name: 'My Amendments',
                        title: 'My Amendments',
                        tab: ContractChangesCategoryRoutes.MY_AMENDMENTS,
                    },
                },
                {
                    name: ContractChangesCategoryRoutes.RECEIVED_AMENDMENTS,
                    path: ContractChangesCategoryRoutes.RECEIVED_AMENDMENTS,
                    component: ReceivedAmendments,
                    meta: {
                        ...defaultMetaChildrenAmendments,
                        name: 'Received Amendments',
                        title: 'Received Amendments',
                        tab: ContractChangesCategoryRoutes.RECEIVED_AMENDMENTS,
                    },
                },
            ],
        },
        {
            name: ContractChangesCategoryRoutes.CONTRACT_CHANGE_REQUESTS,
            path: ContractChangesCategoryRoutes.CONTRACT_CHANGE_REQUESTS,
            component: ContractChangeRequests,
            redirect: {
                name: ContractChangesCategoryRoutes.MY_REQUEST_PACKAGES,
            },
            children: [
                {
                    path: ContractChangesCategoryRoutes.MY_REQUEST_PACKAGES,
                    name: ContractChangesCategoryRoutes.MY_REQUEST_PACKAGES,
                    component: MyRequestPackagesV3,
                    meta: {
                        ...defaultMetaChildrenContractChangeRequests,
                        name: 'Contract change request packages',
                        title: 'Contract change request packages',
                        tab: ContractChangesCategoryRoutes.MY_REQUEST_PACKAGES,
                    },
                },
                {
                    path: ContractChangesCategoryRoutes.RECEIVED_REQUESTS,
                    name: ContractChangesCategoryRoutes.RECEIVED_REQUESTS,
                    component: ReceivedChangeOrderRequestsV3,
                    meta: {
                        ...defaultMetaChildrenContractChangeRequests,
                        name: 'Received contract change requests',
                        title: 'Received contract change requests',
                        tab: RequestPackagesRoutes.ReceivedRequests,
                    },
                },
            ],
        },
    ],
})

routes.push({
    path: 'contract-changes/:contractChangeType/:category/:id',
    name: ContractChangeItemRoutesNames.ITEM,
    component: ContractChangeItem,
    props: true,
    meta: {
        beta: true,
        dontShowMenu: true,
        navigationKey: 'contract_changes',
        title: 'Contract Change Item',
    },
    redirect: { name: ContractChangeItemRoutesNames.ITEM_SUMMARY },
    children: [
        {
            name: ContractChangeItemRoutesNames.ITEM_SUMMARY,
            path: ContractChangeItemPaths.SUMMARY,
            component: ContractChangeItemSummary,
            meta: {
                ...defaultMetaChildrenReceivedChangeOrders,
                tab: ContractChangeItemRoutesNames.ITEM_SUMMARY,
                title: 'Summary',
            },
            redirect: { name: ContractChangeItemRoutesNames.ITEM_SUPPORTING_DOCUMENTS },
            children: [
                {
                    name: ContractChangeItemRoutesNames.ITEM_ATTACHED_CONTRACT_CHANGES,
                    path: ContractChangeItemPaths.ATTACHED_CONTRACT_CHANGES,
                    component: ContractChangeItemAttachedCC,
                    meta: {
                        ...defaultMetaChildrenReceivedChangeOrders,
                        title: 'Summary - Attached Contract Changes',
                    },
                },
                {
                    name: ContractChangeItemRoutesNames.ITEM_SUPPORTING_DOCUMENTS,
                    path: ContractChangeItemPaths.SUPPORTING_DOCUMENTS,
                    component: ContractChangeItemSupportingDocuments,
                    meta: {
                        ...defaultMetaChildrenReceivedChangeOrders,
                        title: 'Summary - Supporting Documents',
                    },
                },
                {
                    name: ContractChangeItemRoutesNames.ITEM_EXECUTED_AGREEMENT,
                    path: ContractChangeItemPaths.EXECUTED_AGREEMENT,
                    component: ContractChangeItemExecutedAgreement,
                    meta: {
                        ...defaultMetaChildrenReceivedChangeOrders,
                        title: 'Summary - Executed Agreement',
                    },
                },
                {
                    name: ContractChangeItemRoutesNames.ITEM_REFERENCES,
                    path: ContractChangeItemPaths.REFERENCES,
                    component: ContractChangeItemReferences,
                    meta: {
                        ...defaultMetaChildrenReceivedChangeOrders,
                        title: 'Summary - References',
                    },
                },
            ],
        },
        {
            name: ContractChangeItemRoutesNames.ITEM_SOV_WBS,
            path: ContractChangeItemPaths.SOV_WBS,
            component: ContractChangeItemSovWbs,
            meta: {
                ...defaultMetaChildrenReceivedChangeOrders,
                tab: ContractChangeItemRoutesNames.ITEM_SOV_WBS,
                title: 'SoV/WBS',
            },
        },
        {
            name: ContractChangeItemRoutesNames.ITEM_APPROVALS,
            path: ContractChangeItemPaths.APPROVALS,
            component: ContractChangeItemApprovals,
            meta: {
                ...defaultMetaChildrenReceivedChangeOrders,
                tab: ContractChangeItemRoutesNames.ITEM_APPROVALS,
                title: 'Approvals',
            },
        },
        {
            name: ContractChangeItemRoutesNames.ITEM_TIMELINE,
            path: ContractChangeItemPaths.TIMELINE,
            component: ContractChangeItemTimeline,
            meta: {
                ...defaultMetaChildrenReceivedChangeOrders,
                tab: ContractChangeItemRoutesNames.ITEM_TIMELINE,
                title: 'Timeline',
            },
        },
    ],
})

routes.push({
    path: 'contract-changes/my-request-packages/:id',
    name: RequestPackagesRoutes.MyRequestPackagesItem,
    component: MyRequestPackagesItemV3,
    meta: {
        name: 'My Request Package',
        beta: true,
        dontShowMenu: true,
        title: 'My Request Package',
        navigationKey: 'contract_changes',
    },
    redirect: { name: RequestPackagesRoutes.MyRequestPackagesItemSummary },
    children: [
        {
            name: RequestPackagesRoutes.MyRequestPackagesItemSummary,
            path: RequestPackagesRoutes.MyRequestPackagesItemSummary,
            component: MyRequestPackagesItemSummaryV3,
            meta: {
                ...defaultMetaChildrenMyRequestPackages,
                name: 'My Request Package Summary',
                title: 'My Request Package Summary',
                tab: RequestPackagesRoutes.MyRequestPackagesItemSummary,
            },
        },
        {
            name: RequestPackagesRoutes.MyRequestPackagesItemRequests,
            path: RequestPackagesRoutes.MyRequestPackagesItemRequests,
            component: MyRequestPackagesItemRequestsV3,
            meta: {
                ...defaultMetaChildrenMyRequestPackages,
                name: 'My Request Package Requests',
                title: 'My Request Package Requests',
                tab: RequestPackagesRoutes.MyRequestPackagesItemRequests,
            },
        },
        {
            name: RequestPackagesRoutes.MyRequestPackagesItemTimeline,
            path: RequestPackagesRoutes.MyRequestPackagesItemTimeline,
            component: MyRequestPackagesItemTimelineV3,
            meta: {
                ...defaultMetaChildrenMyRequestPackages,
                name: 'My Request Package Timeline',
                title: 'My Request Package Timeline',
                tab: RequestPackagesRoutes.MyRequestPackagesItemTimeline,
            },
        },
    ],
})

routes.push({
    path: 'contract-changes/received-request-packages/:id',
    name: RequestPackagesRoutes.ReceivedRequestPackagesItem,
    component: ReceivedChangeOrderRequestPackagesItemV3,
    meta: {
        name: 'Received Request',
        beta: true,
        dontShowMenu: true,
        title: 'Received Request',
        navigationKey: 'contract_changes',
    },
    redirect: { name: RequestPackagesRoutes.ReceivedRequestPackagesItemSummary },
    children: [
        {
            name: RequestPackagesRoutes.ReceivedRequestPackagesItemSummary,
            path: RequestPackagesRoutes.ReceivedRequestPackagesItemSummary,
            component: ReceivedChangeOrderRequestPackagesItemSummaryV3,
            meta: {
                ...defaultMetaChildrenReceivedRequests,
                name: 'Received Request Summary',
                title: 'Received Request Summary',
                tab: RequestPackagesRoutes.ReceivedRequestPackagesItemSummary,
            },
        },
        {
            name: RequestPackagesRoutes.ReceivedRequestPackagesItemTimeline,
            path: RequestPackagesRoutes.ReceivedRequestPackagesItemTimeline,
            component: ReceivedChangeOrderRequestPackagesItemTimelineV3,
            meta: {
                ...defaultMetaChildrenReceivedRequests,
                name: 'Received Request Timeline',
                title: 'Received Request Timeline',
                tab: RequestPackagesRoutes.ReceivedRequestPackagesItemTimeline,
            },
        },
    ],
})

export default routes
