<template>
    <div
        v-click-outside="vcoConfig"
        class="io-multiselect"
        :class="{'io-active': showMenu}"
        data-testid="custom-multiselect"
    >
        <div
            class="io-multiselect__content"
            :class="{ 'io-active': showMenu, 'io-disabled': disabled}"
            data-testid="custom-multiselect-header"
            @click="toggleMenu"
        >
            <template v-if="showMenu && config.enableSearch">
                <div v-if="config.enabledSearchIcon" class="io-multiselect__search-icon">
                    <i class="icon-search" />
                </div>

                <input
                    ref="searchInput"
                    type="text"
                    class="io-multiselect__search"
                    data-testid="custom-multiselect-search-input"
                    v-model="searchKeyword"
                    @click.stop
                />
            </template>

            <slot v-else name="header" :activeOption="value" :config="config">
                <div v-if="value" class="io-option-active">
                    <div class="io-option-active__title">
                        {{ value.name }}
                    </div>
                </div>

                <div v-else class="io-multiselect__name io-placeholder">{{ $t(config.textPlaceholder) }}</div>
            </slot>

            <div
                v-if="value && clearIconEnable"
                class="io-multiselect__clear"
                data-testid="custom-multiselect-header-clear"
                @click.stop="resetActiveOption"
            >
                <i class="icon-cross" />
            </div>

            <div class="io-multiselect__icon">
                <i :class="icon" data-testid="custom-multiselect-header-icon"></i>
            </div>
        </div>

        <transition name="multiselect-menu">
            <div v-if="showMenu" class="io-multiselect__menu" data-testid="custom-multiselect-menu">
                <div v-if="!showAddNewView" class="io-multiselect__list-container">
                    <slot name="top"></slot>

                    <div class="io-multiselect__list" ref="multiselect-list" @scroll="onScroll">
                        <div>
                            <div
                                v-if="optionsTitle"
                                class="io-multiselect-list__title"
                                data-testid="custom-multiselect-option-title"
                            >
                                {{ optionsTitle }}
                            </div>

                            <div v-for="(option, index) in optionsFiltered"
                                 :key="index"
                                 class="io-single-option"
                                 :class="{'io-selected': isEqual(option, value), 'io-option-disable-hover': option.$isLabel}"
                                 data-testid="custom-multiselect-option"
                                 @click.stop="setActiveOption(option)"
                            >
                                <div class="io-single-option__content">
                                    <slot name="option" :option="option">
                                        <div class="io-option"></div>

                                        <div class="io-option__title">
                                            {{ option.name }}
                                        </div>
                                    </slot>
                                </div>

                                <transition name="option-status">
                                    <div v-if="isEqual(option, value) && statusEnable"
                                         class="io-single-option__status"
                                         :class="{'io-selected': !showOptionRemove, 'io-remove': showOptionRemove}"
                                         data-testid="custom-multiselect-option-action-reset"
                                         @click.stop="resetActiveOption"
                                         @mouseover="setShowOptionRemove(true)"
                                         @mouseleave="setShowOptionRemove(false)"
                                    >
                                        <template v-if="showOptionRemove"><i class="icon-cross" />{{ $t('Remove') }}</template>
                                        <template v-else><i class="icon-check" />{{ $t('Selected') }}</template>
                                    </div>
                                </transition>
                            </div>
                        </div>

                        <div v-if="additionalOptions && additionalOptionsFiltered">
                            <div
                                v-if="additionalOptionsTitle"
                                class="io-multiselect-list__title"
                                data-testid="custom-multiselect-additional-option-title"
                            >
                                {{ additionalOptionsTitle }}
                            </div>

                            <div v-for="(option, index2) in additionalOptionsFiltered"
                                 :key="index2"
                                 class="io-single-option"
                                 :class="{'io-selected': isEqual(option, value), 'io-option-disable-hover': option.$isLabel}"
                                 data-testid="custom-multiselect-additional-option"
                                 @click.stop="setActiveOption(option)"
                            >
                                <div class="io-single-option__content">
                                    <slot name="option" :option="option">
                                        <div class="io-option"></div>

                                        <div class="io-option__title">
                                            {{ option.name }}
                                        </div>
                                    </slot>
                                </div>

                                <transition name="option-status">
                                    <div v-if="isEqual(option, value) && statusEnable"
                                         class="io-single-option__status"
                                         :class="{'io-selected': !showOptionRemove, 'io-remove': showOptionRemove}"
                                         data-testid="custom-multiselect-additional-option-action-reset"
                                         @click.stop="resetActiveOption"
                                         @mouseover="setShowOptionRemove(true)"
                                         @mouseleave="setShowOptionRemove(false)"
                                    >
                                        <template v-if="showOptionRemove"><i class="icon-cross" />{{ $t('Remove') }}</template>
                                        <template v-else><i class="icon-check" />{{ $t('Selected') }}</template>
                                    </div>
                                </transition>
                            </div>
                        </div>

                        <slot name="footer"></slot>
                    </div>

                    <div v-if="config.enableAddNew" class="io-multiselect__footer" data-testid="custom-multiselect-add-new-option-footer">
                        <div class="io-multiselect__add-new" data-testid="custom-multiselect-add-new-option-action" @click.stop="setShowAddNewView(true)">
                            <i class="icon-plus-circle" />{{ $t(config.textAddNewOption) }}
                        </div>
                    </div>
                </div>

                <div v-else class="io-multiselect__add-new-container" data-testid="custom-multiselect-add-new-option-container">
                    <slot name="add-new" />

                    <div class="io-multiselect__buttons">
                        <IOButton size="small" variant="secondary" @click.stop="setShowAddNewView(false)">{{ $t('Cancel') }}</IOButton>

                        <IOButton size="small" data-testid="custom-multiselect-add-new-option-button" @click="addNewOption">
                            <div v-if="addingNewInProcess" class="io-loader-small" />
                            <template v-else>{{ $t(config.textAddNewOptionButton) }}</template>
                        </IOButton>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script src="./CustomMultiselect.js"></script>
<style lang="scss" src="./CustomMultiselect.scss"></style>
