import moment from 'moment'
import states from '@/helpers/states.js'
import iconLibrary from '@/base/iconLibrary.js'
import dateTimeHelper from './dateTime'
import dayjs from 'dayjs'
import ContractType from '@/base/ContractType'
import i18n from '@/base/i18n'
import { formatMoney } from '@/helpers/currencyFormatters'
import { isNull, isUndefined, isEmpty, isArray } from 'lodash'

/**
 * Format value helper
 * @param total
 * @param ifZeroValue
 * @param formattedValue
 * @param precision
 * @param strictPrecision
 * @return {string}
 */
function formatFromInt (total, ifZeroValue = '', formattedValue = null, precision = 2, strictPrecision = false) {
    total = iconLibrary.methods.toFloat(total)
    if (total === null) {
        total = 0
    }
    if (total === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
        return ifZeroValue
    }

    if (precision !== null) {
        total = iconLibrary.methods.applyPrecision(total, precision, strictPrecision)
    }

    // if not formatted already
    if (formattedValue === null) {
        formattedValue = iconLibrary.methods.getFormatterCurrency(total, precision)
    }

    return formattedValue
}

/**
 * Filters data
 */
const filters = {
    capitalize: function (input) {
        if (input !== null && input !== undefined) {
            return input.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }
    },
    // replace diacritical characters with ASCII equivalents
    ascii (value) {
        return (String(value)).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    uppercase: function (input) {
        if (input !== null && input !== undefined) {
            return input.toUpperCase()
        }
    },
    lowercase: function (input) {
        if (input) {
            return String(input).toLowerCase()
        }
    },
    website: function (input) {
        return input.replace(/(^\w+:|^)\/\//, '')
    },
    hasHttp: function (input) {
        if (input.indexOf('http') === -1) {
            input = 'http://' + input
        }
        return input
    },
    filterEmptyValue (value, emptyValue = '-') {
        return filters.checkValueIsEmpty(value) ? emptyValue : value
    },
    checkValueIsEmpty (value) {
        return isNull(value) || isUndefined(value) || value === ''
    },
    ago (time, displayInLocalTime = true) {
        return displayInLocalTime ? moment.unix(time).local().fromNow(false) : moment.unix(time).fromNow(false)
    },
    agoDate (time, displayInLocalTime = true) {
        return displayInLocalTime
            ? moment.utc(time, 'YYYY-MM-DD HH:mm').local().fromNow(false)
            : moment(time, 'YYYY-MM-DD HH:mm').fromNow(false)
    },
    timeLeft (date, label = 'Expired') {
        const localTime = moment()
        date = moment.utc(date).local()
        const difference = moment.duration(date.diff(localTime))
        if (difference.asSeconds() <= 0) {
            return label
        } else {
            let days = parseInt(difference.asDays())
            return (days === 0 ? 1 : days) + ' days left'
        }
    },
    toNowDate (time) {
        return moment(time, 'YYYY-MM-DD HH:mm').fromNow(false)
    },
    uploaderAcceptableFiles (value, delimiter = ',', text = 'Acceptable Files Types are: ') {
        return isArray(value) && !isEmpty(value) ? text + value.join(delimiter) : ''
    },
    isDue (time) {
        return !moment.utc(time).local().isAfter()
    },
    formatDecimal: function (value, decimal = 2) {
        return iconLibrary.methods.cutPrecision(value, decimal)
    },
    formatHours: function (value, decimal = 2) {
        if (!value) {
            value = 0
        }
        return parseFloat(value).toFixed(decimal)
    },

    /**
     *
     * @param input
     * @param style
     * @param precision
     * @param ifZeroValue
     * @return {string}
     */
    formatNumberUSD: function (input, style = 'currency', precision = 2, ifZeroValue = '') {
        if (input === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }
        return iconLibrary.methods.getFormatterCurrency(input, precision)
    },
    formatProjectCurrencyValue: function (input, style = 'currency', precision = 2, ifZeroValue = '') {
        if (input === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }
        return iconLibrary.methods.getProjectFormatterCurrency(input, precision)
    },
    formatPercent: function (total, decimal = 0, withLeadingZero = false) {
        if (typeof total === 'object') {
            total = 0
        }
        let prefix = ''
        if (withLeadingZero && String(total).length === 1) {
            prefix += '0'
        }
        return prefix + iconLibrary.methods.cutPrecision(total, decimal) + '%'
    },
    formatPercentIfNotString: function (value) {
        if (typeof value !== 'string') {
            return filters.formatPercent(value, 2)
        } else {
            return value // ex. N/A
        }
    },
    formatPercentAsDecimal: function (value) {
        if (typeof value !== 'string') {
            return filters.formatPercent(value, 2)
        } else {
            return value // ex. N/A
        }
    },
    formatCurrencyValue: function (total, ifZeroValue = '') {
        if (total !== null && total !== undefined) {
            const value = iconLibrary.methods.cutPrecision(total)
            if (isNaN(value)) {
                return '0.00'
            }

            // if total holds zero value then ex. N/A will be printed
            // ifZeroValue - any string
            if (total === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
                return ifZeroValue
            }

            return value.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        } else {
            return '0.00'
        }
    },
    formatCurrencyValueIfNotString: function (value) {
        if (typeof value !== 'string') {
            return filters.formatCurrencyValue(value)
        } else {
            return value // ex. N/A
        }
    },
    formatByProvidedCurrency (value, currency) {
        return formatMoney(currency, value, 2)
    },
    formatCurrency: function (total, ifZeroValue = '') {
        if (typeof total === 'undefined') {
            total = 0
        }
        if (typeof total === 'string') {
            if (total.indexOf('%') !== -1) {
                return total
            } else {
                total = total.replace('$', '')
                total = total.replace(',', '')
            }
        }

        const value = iconLibrary.methods.cutPrecision(total)
        if (isNaN(value)) {
            return filters.formatNumberUSD(0, 'currency', 2, ifZeroValue)
        }

        // if total holds zero value then ex. N/A will be printed
        // ifZeroValue - any string
        if (total === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }

        return filters.formatNumberUSD(value, 'currency', 2, ifZeroValue)
    },
    formatWithComma: function (total, precision = 2, ifZeroValue = '') {
        if (typeof total === 'undefined') {
            total = 0
        }
        if (total < 0) {
            total = Math.abs(total)
        }
        let value = parseFloat(total, precision)
        if (isNaN(value)) {
            return parseFloat(0, precision)
        }

        // if total holds zero value then ex. N/A will be printed
        // ifZeroValue - any string
        if (total === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }

        return value.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    },
    formatIntegerWithCommas (value) {
        let formatter = new Intl.NumberFormat()
        return formatter.format(value)
    },
    formatNumberWithCommas: function (x, defaultValue = '0.00', ifZeroValue = '') {
        x = x !== null && x !== undefined ? x : false
        if (x === false) {
            return defaultValue
        }

        // if x holds zero value then ex. N/A will be printed
        // ifZeroValue - any string
        if (x === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }

        let parts = String(x).split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
    },
    multiselectLabel: function (value) {
        if (typeof value.id !== 'undefined' && typeof value.name !== 'undefined') {
            return value.name
        }
        return '-'
    },
    nl2br: function (value) {
        value = !value ? '' : value
        return String(value).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')
    },
    parseDate (date) {
        return moment.utc(date).local().format(dateTimeHelper.getDateFormat())
    },
    parseDateTime (date) {
        return moment.utc(date).local().format(dateTimeHelper.getDateTimeFormat())
    },
    parseDateTimeUniversal (dateTime) {
        return dayjs(dateTime).format('MMMM DD, YYYY [at] HH:mm')
    },
    parseTime (date) {
        return moment.utc(date).local().format(dateTimeHelper.getTimeFormat())
    },
    formatHistoryLogDate (date) {
        return filters.parseDateTime(date)
    },
    numberToDay (number) {
        let obj = {
            0: 'Monday',
            1: 'Tuesday',
            2: 'Wednesday',
            3: 'Thursday',
            4: 'Friday',
            5: 'Saturday',
            6: 'Sunday'
        }

        return obj[number]
    },
    formatDate: function (value, displayInLocalTime = true) {
        if (value) {
            return displayInLocalTime
                ? moment.utc(String(value)).local().format(dateTimeHelper.getDateTimeFormat())
                : moment.utc(String(value)).format(dateTimeHelper.getDateTimeFormat())
        }
    },
    formatDateOnly: function (value, emptyValueText = '-') {
        if (value) {
            return moment.utc(String(value)).format(dateTimeHelper.getDateFormat())
        } else {
            return emptyValueText
        }
    },
    fileSize (bytes) {
        if (bytes < 1024) {
            return bytes + ' B'
        }
        let i = Math.floor(Math.log(bytes) / Math.log(1024))
        let num = bytes / Math.pow(1024, i)
        let round = Math.round(num)
        num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
        return `${ num } ${ 'KMGTPEZY'[i - 1] }B`
    },
    timeAgo (dateString, displayInLocalTime = true) {
        return displayInLocalTime ? moment.utc(dateString).local().fromNow() : moment(dateString).fromNow()
    },
    timeAgoCustom (dateString, displayInLocalTime = true) {
        let now = displayInLocalTime ? moment.utc().local() : moment.utc()
        let date = displayInLocalTime ? moment.utc(dateString).local() : moment.utc(dateString)
        // Today, 8:33 AM
        if (now.format('MM/DD/YYYY') === date.format('MM/DD/YYYY')) {
            return date.format('[Today], h:mm A')
        } else {
            return date.format(dateTimeHelper.getDateTimeFormat())
        }
    },
    hex2rgba (hex, alpha = 1) {
        const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
        return `rgba(${ r },${ g },${ b },${ alpha })`
    },
    stateShort: function (value) {
        return states.getShort(value)
    },
    stateLong: function (value) {
        return states.getLong(value)
    },
    boolToWord (value, valueTrue = 'Yes', valueFlase = 'No') {
        if (value === false || String(value) === '0') {
            return valueFlase
        } else {
            return valueTrue
        }
    },

    /**
     * Format currency from bigint value
     * @param total
     * @param ifZeroValue
     * @return {string|string}
     */
    formatCurrencyInt: function (total, ifZeroValue = '') {
        return formatFromInt(total, ifZeroValue, iconLibrary.methods.getFormatterCurrency(total, 2, true))
    },
    formatProjectCurrencyInt: function (total, ifZeroValue = '') {
        return formatFromInt(total, ifZeroValue, iconLibrary.methods.getProjectFormatterCurrency(total, 2, true))
    },

    /**
     * Format value from bigint value
     * @param total
     * @param ifZeroValue
     * @return {string|string}
     */
    formatCurrencyValueInt: function (total, ifZeroValue = '') {
        return formatFromInt(total, ifZeroValue, iconLibrary.methods.getFormatterLocale(total, 2, true))
    },
    /**
     * Format value with percent value
     * @param total
     * @param strictPrecision
     * @param ifZeroValue
     * @return {string}
     */
    formatPercentInt: function (total, strictPrecision = false, ifZeroValue = '') {
        const valueFormatFromInt = formatFromInt(
            total,
            ifZeroValue,
            iconLibrary.methods.getFormatterLocale(total, 2, true, strictPrecision),
            2,
            strictPrecision
        )
        return `${ valueFormatFromInt }%`
    },
    strippedContent (string) {
        let regex = /(<([^>]+)>)/ig
        return string.replace(regex, '')
    },

    limitStrLength (text, maxLength = 7) {
        if (text && text.length > maxLength - 3) {
            return text.substring(0, maxLength).trimEnd() + '...'
        }
        return text
    },
    /**
     * Format text to slug format (foo-bar)
     * @param text
     * @param separator
     * @return {string}
     */
    slugify (text, separator = '-') {
        return text.toString()
            .normalize('NFD')                   // split an accented letter in the base letter and the acent
            .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
            .replace(/\s+/g, separator)
    },
    formatPhoneNumber (number) {
        if (!number) {
            return '-'
        }

        const match = number.match(/^(\d{3})(\d{3})(\d{4})$/)

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }

        return number
    },
    contractFullNameFromType (type) {
        return i18n.global.t(`contract_type_${ type || ContractType.LUMP_SUM }_fullname`)
    }
}

export default filters
