import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'
import type { Category } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'
import budgetCostCodeClient from '@/components/budget-cost-code-select/BudgetCostCodeClient.ts'
import useNotification from '@/composables/useNotification.ts'
import i18n from '@/base/i18n'

const { t } = i18n.global
const { showNotification } = useNotification()

export default () => {
    const getProjectCostCodeCategories = async (projectLocalId: string, budgetType: BudgetType, siteId = ''): Promise<{ data: Category[] }> => {
        if (!projectLocalId) {
            showNotification('error', t('Project Local Id required'))
        }

        if (!budgetType) {
            showNotification('error', t('Budget Type required'))
        }

        const { data } = await budgetCostCodeClient.getProjectCostCodes(projectLocalId, { budgetType, siteId })

        return data
    }

    const getBudgetCostCodeCategories = async (budgetId: string, siteId = ''): Promise<{ data: Category[] }> => {
        if (!budgetId) {
            showNotification('error', t('Budget Id required'))
        }

        const { data } = await budgetCostCodeClient.getBudgetCostCodes(budgetId, { siteId })

        return data
    }

    return {
        getProjectCostCodeCategories,
        getBudgetCostCodeCategories
    }
}
