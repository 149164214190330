import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import { Contact } from '@/interfaces/components/invite-modal/Contact'
import { mapActions, mapState } from 'pinia'
import { globalFundingStore } from '../../../stores/globalFundingStore'
import appTypes from '@/base/appTypes'
import fundingClient from '@/io-modules/global-funding/api-clients/fundingClient'
import { InviteModalTabs } from '@/components/invite-modal-v3/enums/InviteModalTabs'
import { inviteModalStore } from '@/components/invite-modal-v3/store/inviteModalStore'

export default defineComponent({
    name: 'AddProviderModal',
    components: { AlertBox, InvitePreview, PopupModal },
    emits: ['close'],
    data () {
        return {
            selectedProvider: null,
            invitationClientKey: InviteModalClientKeys.PROJECT_FUNDING_SOURCES_PROVIDER,
            PreviewTypes: PreviewTypes,
            showAlert: false,
            appTypes: appTypes,
            InviteModalTabs: InviteModalTabs,
            createdProviderId: null
        }
    },
    computed: {
        ...mapState(globalFundingStore, [
            'providersList'
        ])
    },
    watch: {
        selectedProvider (value: Contact): void {
            if (value && this.providersList) {
                this.showAlert = !!this.providersList.find(item => {
                    return item.provider.company_id === value.workspace?.id
                })
            } else {
                this.showAlert = false
            }
        },
    },
    unmounted () {
        this.clearSelectedPersons(this.invitationClientKey)
    },
    methods: {
        ...mapActions(inviteModalStore, ['clearSelectedPersons']),
        onSelectProvider (contact: Contact): void {
            if (contact) {
                this.selectedProvider = contact.bookmarked[0]
            }
        },
        clearProvider (): void {
            this.selectedProvider = null
        },
        async sendProvider (): Promise<void> {
            try {
                if (!this.providersList || !this.providersList.find(item => item.provider.company_id === this.selectedProvider.company_id)) {
                    const { data } = await fundingClient.setProvider(this.selectedProvider.id)
                    this.createdProviderId = data
                }

                const providerId = this.matchSelectedProviderId(this.selectedProvider.company_id)
                if (providerId) {
                    this.$router.push({
                        name: 'funding-sources-provider-details',
                        params: { id: providerId }
                    })
                }

            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        closeModal (): void {
            this.$emit('close')
        },
        matchSelectedProviderId (company_id: string): string {
            const match = this.createdProviderId || this.providersList.find(elem => elem.provider.company_id === company_id)
            return match?.id
        },
    },
})
