import { defineComponent } from 'vue'
import ImageWithNameTableCell from '../image-with-name-table-cell/ImageWithNameTableCell.vue'
import StatusPill from '../status-pill/StatusPill.vue'
import GeneralStatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import { ItemRouteInterface } from '@/interfaces/components/lazy-list/ItemRoute'
import ColorDot from '../color-dot/ColorDot.vue'
import IconAction from '../icon-action/IconAction.vue'
import Stamp from '../stamp/Stamp.vue'
import Tags from '../tags/Tags.vue'
import EmployeeList from '../employee-list/EmployeeList.vue'
import DropdownCell from '../dropdown-cell/DropdownCell.vue'
import TextIcon from '../text-icon/TextIcon.vue'
import TextColumn from '../text-column/TextColumn.vue'
import Thumbnail from '../thumbnail/Thumbnail.vue'
import Checkbox from '@/components/lazy-list/parts/checkbox/Checkbox.vue'
import ServerSideCheckbox from '@/components/lazy-list/parts/server-side-checkbox/ServerSideCheckbox.vue'
import moment from 'moment'
import InviteMembers from '../invite-members/InviteMembers.vue'
import DropdownStatusPill from '../dropdown-status-pill/DropdownStatusPill.vue'
import AddressColumn from '../address/AddressColumn.vue'
import Archive from '../archive/Archive.vue'
import StatusLoading from '../status-loading/StatusLoading.vue'
import ContractType from '@/base/ContractType'
import InvitationStatus from '../invitation-status/InvitationStatus.vue'
import InvitationButton from '../invitation-button/InvitationButton.vue'
import InviteContacts from '../invite-contacts/InviteContacts.vue'
import dateTimeHelper from '@/helpers/dateTime'
import SingleContact from '@/components/lazy-list/parts/single-contact/SingleContact'
import InvitationButtonNew from '@/components/lazy-list/parts/invitation-button-new/InvitationButtonNew'
import { get, isEmpty } from 'lodash'
import appTypes from '@/base/appTypes'
import type Currency from '@/interfaces/Currency'

export default defineComponent({
    components: {
        ImageWithNameTableCell,
        StatusPill,
        ColorDot,
        IconAction,
        Stamp,
        Tags,
        EmployeeList,
        DropdownCell,
        TextIcon,
        TextColumn,
        Thumbnail,
        InviteMembers,
        AddressColumn,
        Checkbox,
        ServerSideCheckbox,
        DropdownStatusPill,
        Archive,
        GeneralStatusPill,
        StatusLoading,
        SingleContact,
        InvitationButtonNew,
        InvitationButton,
        InvitationStatus,
        InviteContacts,
    },
    props: {
        tableRow: { type: Object, required: true, default: null },
        columns: { type: Array as () => ColumnInterface[], required: true, default: null },
        itemRoute: { type: [Object as () => ItemRouteInterface, null], required: true, default: null },
        pillsColorFunction: { type: Function, required: true, default: null },
        dotsColorFunction: { type: Function, default: null },
        customStatusTextFunction: { type: Function, default: null },
        pillsTextFunction: { type: Function, default: null },
        showIconToolTip: { type: Function, default: null },
        customClassFunction: { type: Function, default: () => [] },
        isDisabled: { type: Boolean, default: false },
        isEditMode: { type: Boolean, default: false },
        noReroute: { type: Boolean, default: false },
        selectedRows: { type: Array, default: null },
        tableRowClass: { type: String, default: '' },
        isLastRow: { type: Boolean },
        isRowSelectedByRadio: { type: Boolean, default: false },
        isStrictWidth: { type: Boolean, default: false },
        disableBookmarking: { type: Boolean, default: false },
    },
    data () {
        return {
            rowSelectedByRadio: false,
            routeParams: {} as any,
            isRowExpaneded: false,
        }
    },
    computed: {
        routeNameIsTheSameAsCurrentRoute (): boolean {
            return this.itemRoute?.name === this.$route.name
        },
        rowIsClickable (): boolean {
            return !this.isEditMode && (this.noReroute || null !== this.itemRoute?.name)
        },
        currenciesList (): Currency[] {
            return this.$store.state.common.currencies_list
        }
    },
    beforeMount () {
        this.setRouteParams()
        this.rowSelectedByRadio = this.isRowSelectedByRadio
    },
    methods: {
        onClickToggleRow (): void {
            this.isRowExpaneded = !this.isRowExpaneded

            this.$emit('toggle-expand-table-row')
        },
        calculateColumnsWidth (): string | undefined {
            return this.isStrictWidth ? `${ 100 / this.columns.length }vw` : undefined
        },
        onMiddleClick (): void {
            const isInsideProject = !!this.$route.params.pid
            const params = {
                ...this.routeParams
            }

            if (isInsideProject) {
                params.pid = this.$route.params.pid
            }

            let routeData = null

            if (this.$route.name === 'tasks-listing') {
                routeData = this.$router.resolve({ name: 'todo-view', params: { id: this.tableRow._id } })
            } else if (this.$route.name === 'lists-templates-meetings-templates') {
                routeData = this.$router.resolve({
                    name: 'lists-templates-meetings-template-view',
                    params: { id: this.tableRow.id },
                })
            } else if (this.$route.name === 'project-list') {
                const appType = localStorage.getItem('appType')
                routeData = this.$router.resolve({
                    name: appType === appTypes.TYPE_DEV || appType === appTypes.TYPE_OWNER ? 'project-dashboard' : 'project-summary',
                    params: { pid: this.tableRow.id },
                })
            } else if (this.noReroute) {
                return
            } else {
                routeData = this.$router.resolve({ name: this.itemRoute.name, params: params })
            }

            window.open(routeData.href, '_blank')

            return
        },
        onTableRowClick (e: any): void {
            if (this.isEditMode) {
                return
            }

            if (this.noReroute) {
                this.$emit('tableRowClick', this.tableRow)
                return
            }

            this.setRouteParams()

            if (this.itemRoute && !this.routeNameIsTheSameAsCurrentRoute) {
                this.$router.push({
                    name: this.itemRoute.name,
                    params: this.routeParams
                })
            }

            return
        },
        onAdditionalHTMLClicked (field: string): void {
            this.$emit('additionalHTMLClicked', { row: this.tableRow, field })
        },
        onRadioClick (e: any): void {
            if (e !== false) {
                this.$emit('radioClick', this.tableRow)
            }
        },
        columnIsVisibleInSelectedMode (column: ColumnInterface): boolean {
            if (column.additional?.visible === false) {
                return false
            }

            if (!this.isEditMode) {
                return !column.additional?.hiddenInStaticMode
            }

            return true
        },
        emitIconAction (action: object): void {
            this.$emit('emitIconAction', action)
        },
        actionSelect (action: object): void {
            this.$emit('actionSelect', action)
        },
        selectStatusPill (data: object): void {
            this.$emit('selectStatusPill', data)
        },
        changeFavorite (id: number): void {
            this.$emit('changeFavorite', id)
        },
        dateFormat (column: ColumnInterface): string {
            return column.additional?.dateFormat ?? dateTimeHelper.getDateFormat()
        },
        formatDate (column: ColumnInterface): string {
            let date = this.tableRow[column.field]
            if (this.tableRow[column.field]) {
                if (column.additional?.formatDateToUserTimezone) {
                    date = this.$filters.parseDateTime(date)
                }
                return moment(date).format(this.dateFormat(column))
            }

            return '-'
        },
        getColumnAdditionalHTML (column: any): string {
            // @ts-ignore
            const additionalHTMLFunction = get(column, ['additional', 'additionalHTMLFunction'], null)
            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.tableRow)
            }
            return ''
        },
        filterField (column: any): string {
            if (column.additional?.ignoreValue) {
                return
            }

            if ('undefined' === typeof this.tableRow[column.field] || null === this.tableRow[column.field]) {
                return column.additional?.defaultValue ?? '-'
            }

            if (column.additional?.isTranslation) {
                return this.$t(this.tableRow[column.field]) || '-'
            }

            if (column.filter === 'formatCurrency' || column.filter === 'formatCurrencyInt') {
                return column.additional?.subfield ?
                    this.$filters[column.filter](this.tableRow[column.field][column.additional.subfield]) :
                    this.$filters[column.filter](this.tableRow[column.field])
            }

            if (column.additional?.subfield) {
                return this.tableRow[column.field][column.additional.subfield]
            }

            if (column.filter) {
                // @ts-ignore

                if (column.filter === 'formatByProvidedCurrency' && !isEmpty(get(column, [ 'additional', 'id' ]))) {
                    // @ts-ignore
                    return this.$filters[column.filter](this.tableRow[column.field], this.tableRow[column.additional.id])
                }
                if (column.filter === 'contractFullNameFromType') {
                    return this.$t(`contract_type_${ this.tableRow[column.field] || ContractType.LUMP_SUM }_fullname`)
                }

                if (column.filter === 'contractTypeText') {
                    return this.$t(this.tableRow[column.field] === ContractType.STANDARD ? 'Single Stage Agreement' : 'Multi-Stage Agreement')
                }

                if (column.filter === 'formatByProvidedCurrencyId') {
                    const currency: Currency = this.currenciesList.find((item: Currency) => {
                        return item.id === this.tableRow.currency
                    })

                    return this.$filters.formatByProvidedCurrency(this.tableRow[column.field], currency)
                }

                // @ts-ignore
                return this.$filters[column.filter](this.tableRow[column.field])
            } else {
                return this.tableRow[column.field] || '-'
            }
        },
        isColumnEditable (column: ColumnInterface): boolean {
            return this.isEditMode && (column.additional?.isEditable ?? false)
        },
        additionalPill (column: ColumnInterface): boolean {
            const hasAdditionalPill = Boolean(column.additional && column.additional.additionalPill)
            if (column.additional && column.additional.conditionToShow instanceof Function) {
                return hasAdditionalPill && column.additional.conditionToShow(this.tableRow)
            }

            return hasAdditionalPill
        },
        onInviteButtonClick (inviteData: any): void {
            this.$emit('inviteButtonClick', inviteData)
        },
        onInviteButtonNewClick (data: any): void {
            this.$emit('inviteButtonNewClick', data)
        },
        setRouteParams (): void {
            if (this.routeNameIsTheSameAsCurrentRoute) {
                return
            }

            for (const key in this.itemRoute?.params) {
                if (typeof this.itemRoute?.params[key] === 'string') {
                    // @ts-ignore
                    this.routeParams[key] = this.tableRow[this.itemRoute?.params[key]]
                } else {
                    this.routeParams[key] = this.itemRoute?.params[key]
                }
            }
        },
    }
})
