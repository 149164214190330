import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        description: {
            type: String,
            required: true,
        }
    },
    data () {
        return {}
    },
    computed: {
    },
    methods: {
        handleRedirect (): void {
            this.$router.push({ name: 'project-settings-project-information-edit', params: { pid: this.$route.params.pid } })
        }
    }
})
