import { ApiClient as client, ApiClientV2 as clientV2 } from '@/api/api'

import { GetNotificationsPayload, Notification } from '../interfaces/MenuLeftInterface'

export default {
    getMenu () {
        return client.get('/get-menu')
    },
    getNotifications (data: GetNotificationsPayload) {
        return client.post(`/notifications/${ data.type }/${ data.skip }`, data.payload)
    },
    markAllNotificationsAsRead () {
        return client.post('/notifications/mark-all-as-read/all')
    },
    acceptInvitation (id: string) {
        return clientV2.get(`/invitation/accept/${ id }`)
    },
    markSingleNotificationsAsRead (notification: Notification) {
        if (notification.isUnread){
            return client.post(`/notifications/mark-as-read/${ notification.id }`)
        }
    },
    rejectInvitation (payload: any) {
        return clientV2.post(`/invitation/reject/${ payload.invitationId }`, { reason: payload.reason })
    },
    getProjectNavigationItems (projectId: string) {
        return client.get(`/project/left-menu/${ projectId }`)
    }
}
