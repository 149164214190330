<template>
    <div class="io-invite-modal__holder">
        <div class="io-invite-modal__preview">

            <template v-if="previewType === PreviewTypes.BUTTON_SECONDARY">
                <template v-if="!selectMultiple">
                    <template v-if="selectedContactsList && selectedContactsList.length">
                        <div class="io-f-row io-usr-inv">
                            <div class="io-f-row io-align-center">
                                <Avatar
                                    v-if="showSingleContactAvatar"
                                    class="io-invited-avatar"
                                    :employeeMongoId="firstSelectedContact"
                                    :employeeFullName="selectedContactFullName"
                                />

                                <div class="io-invited-name">
                                    {{ selectedContactFullName }}
                                </div>

                                <template v-if="showStatusForSingleContact">
                                    <template
                                        v-if="selectedContactsList[0].save_only || selectedContactsList[0].invitation_status === 'saved'"
                                    >
                                        <StatusPill
                                            size="big"
                                            variant="yellow-light"
                                            :text="$t('Bookmarked')"
                                        />
                                    </template>

                                    <template v-else>
                                        <StatusPill
                                            size="big"
                                            variant="blue-light"
                                            :text="$t('Pending Invitation')"
                                        />
                                    </template>
                                </template>
                                <div
                                    class="io-remove-invitation io-cursor-pointer"
                                    @click="showInviteModal = true"
                                >
                                    <i class="icon-refresh-ccw"></i>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <IOButton
                            data-testid="preview-button-secondary"
                            variant="link"
                            :size="buttonSize"
                            :icon="buttonIcon"
                            @click="showInviteModal = true"
                        >
                            {{ buttonLabelText }}
                        </IOButton>
                    </template>
                </template>
            </template>

            <template v-if="previewType === PreviewTypes.VERTICAL_LIST">
                <InvitePreviewVerticalList
                    :key="componentKey"
                    :selectedContactsList="selectedContactsList"
                    :contactsBallInCourt="usersBallInCourt"
                    :contactsOnline="usersOnline"
                    :disableInviting="disableInviting"
                    @setBallInCourt="onAvatarClick"
                    @showInviteModal="showInviteModal = true"
                />
            </template>

            <template v-if="previewType === PreviewTypes.HORIZONTAL_LIST">
                <InvitePreviewHorizontalList
                    :key="componentKey"
                    :selectedContactsList="selectedContactsList"
                    :avatarRingTooltip="avatarRingTooltip"
                    :avatarRingChecker="avatarRingChecker"
                    :contactsBallInCourt="usersBallInCourt"
                    :isAdditionalContactsVisible="isAdditionalEmployeesVisible"
                    :displayLimit="displayLimit"
                    :isInviteAllowed="isInviteAllowed"
                    @setBallInCourt="onAvatarClick"
                    @showInviteModal="showInviteModal = true"
                />
            </template>

            <template v-if="previewType === PreviewTypes.CONTACT_LIST_FULL">
                <InvitePreviewContactListFull
                    :selectedContactsList="selectedContactsList"
                    :showStatusForSingleContact="showStatusForSingleContact"
                    :showAppTypeForSingleContact="showAppTypeForSingleContact"
                    :isInviteAllowed="isInviteAllowed"
                    :selectMultiple="selectMultiple"
                    :buttonLabelText="buttonLabelText"
                    :button-icon="buttonIcon"
                    :buttonSize="buttonSize"
                    @showInviteModal="openInviteModal"
                    @removeSelectedContact="removeSelectedContactFromModal"
                />
            </template>

            <template v-if="previewType === PreviewTypes.CONTACT_LIST_FULL_VERTICAL">
                <div class="io-invite-modal__vertical-full-list io-f-row">
                    <template v-for="(contact, index) in selectedContactsList" :key="contact.id">
                        <div class="io-single-contact-full">
                            <div
                                v-if="contact.id && contact.avatar"
                                :key="index"
                                :class="['io-img-holder', { 'io-img-holder--large': true }]"
                                :style="getBackgroundAvatar(contact.id)"
                            />
                            <Initials
                                v-else
                                :class="['io-img-holder io-f-column', { 'io-img-holder--large': true, 'io-offsystem-logo': isArchivedOrPendingOrOffSystem(contact) }]"
                                :fullName="`${ contact.firstname } ${ contact.lastname }`"
                            />
                            <div class="io-single-contact-full__info">
                                <div class="io-single-contact-full__info-top">
                                    <div class="io-full-name">
                                        {{ contact.firstname }} {{ contact.lastname }}
                                    </div>
                                    <template v-if="showStatusForSingleContact">
                                        <template v-if="selectedContactsList[index].is_bookmarked">
                                            <StatusPill
                                                size="small"
                                                variant="blue-light"
                                                :text="$t('Bookmarked')"
                                            />
                                        </template>

                                        <template v-else>
                                            <StatusPill
                                                size="small"
                                                variant="yellow-light"
                                                :text="$t('Pending')"
                                            />
                                        </template>
                                    </template>
                                </div>
                                <div class="io-single-contact-full__info-bottom">
                                    <template v-if="showAppTypeForSingleContact">
                                        <StatusPill
                                            v-if="contact.workspace.app_type"
                                            size="small"
                                            :variant="getCompanyPillVariant(contact.workspace.app_type)"
                                            :text="getCompanyPillText(contact.workspace.app_type)"
                                        />
                                        <span v-else class="io-small-pill io-grey">
                                            {{ $t('Off-System') }}
                                        </span>
                                    </template>

                                    <div class="io-company-name">
                                        {{ contact.workspace?.name || '' }}
                                    </div>
                                </div>
                            </div>

                            <template v-if="!isPreviewRemoveItemDisabled">
                                <div
                                    class="io-remove-invitation io-cursor-pointer"
                                    @click="showInviteModal = true"
                                >
                                    <i class="icon-edit"></i>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
                <div
                    v-if="isButtonDisplayed"
                    class="io-f-row mt-4"
                >
                    <IOButton
                        variant="link"
                        :size="buttonSize"
                        :icon="buttonIcon"
                        @click.stop="showInviteModal = true"
                    >
                        <span>{{ buttonLabelText }}</span>
                    </IOButton>
                </div>
            </template>

            <template v-if="previewType === PreviewTypes.SINGLE_CONTACT_FULL">
                <template v-if="!selectMultiple">
                    <template v-if="selectedContactsList && selectedContactsList.length && selectedContactsList[0]">
                        <div class="io-invite-modal__vertical-full-list io-f-row">
                            <div class="io-single-contact-full">
                                <div
                                    v-if="selectedContactsList[0].id && selectedContactsList[0].avatar"
                                    :key="index"
                                    :class="['io-img-holder', { 'io-img-holder--large': isLargeIcon }]"
                                    :style="getBackgroundAvatar(selectedContactsList[0].id)"
                                />
                                <Initials
                                    v-else
                                    :class="['io-img-holder io-f-column', { 'io-img-holder--large': isLargeIcon, 'io-offsystem-logo': isArchivedOrPendingOrOffSystem(selectedContactsList[0]) }]"
                                    :fullName="`${ selectedContactsList[0].firstname } ${ selectedContactsList[0].lastname }`"
                                />

                                <div class="io-single-contact-full__info">
                                    <div class="io-single-contact-full__info-top">
                                        <div class="io-full-name">
                                            {{ selectedContactsList[0].firstname }} {{
                                                selectedContactsList[0].lastname
                                            }}
                                        </div>
                                        <template v-if="showStatusForSingleContact">
                                            <template v-if="selectedContactsList[0]?.invitation?.is_bookmarked">
                                                <StatusPill
                                                    size="small"
                                                    variant="blue-light"
                                                    :text="$t('Bookmarked')"
                                                />
                                            </template>

                                            <template
                                                v-else-if="selectedContactsList[0]?.invitation?.status === 'invited'">
                                                <StatusPill
                                                    size="small"
                                                    variant="yellow-light"
                                                    :text="$t('Pending')"
                                                />
                                            </template>

                                            <template
                                                v-else-if="selectedContactsList[0]?.invitation?.status === 'accepted'">
                                                <StatusPill
                                                    size="small"
                                                    variant="green-light"
                                                    :text="$t('Accepted')"
                                                />
                                            </template>
                                        </template>
                                    </div>
                                    <div class="io-single-contact-full__info-bottom">
                                        <template v-if="showAppTypeForSingleContact">
                                            <StatusPill
                                                v-if="selectedContactsList[0].workspace.app_type || selectedContactsList[0].app_type"
                                                size="small"
                                                :variant="getCompanyPillVariant(selectedContactsList[0].workspace.app_type || selectedContactsList[0].app_type)"
                                                :text="getCompanyPillText(selectedContactsList[0].workspace.app_type || selectedContactsList[0].app_type)"
                                            />
                                            <span v-else class="io-small-pill io-grey">
                                                {{ $t('Off-System') }}
                                            </span>
                                        </template>

                                        <div class="io-company-name">
                                            {{ selectedContactsList[0].workspace?.name }}
                                        </div>
                                    </div>
                                </div>

                                <template v-if="!isPreviewRemoveItemDisabled">
                                    <div
                                        class="io-remove-invitation io-cursor-pointer"
                                        @click="showInviteModal = true"
                                    >
                                        <i class="icon-edit"></i>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <IOButton
                            variant="link"
                            :size="buttonSize"
                            :icon="buttonIcon"
                            @click="showInviteModal = true"
                        >
                            {{ buttonLabelText }}
                        </IOButton>
                    </template>
                </template>
            </template>

        </div>
        <InviteModalV3
            v-if="showInviteModal"
            :key="componentKey"
            :componentKey="componentKey"
            :modalZIndex="modalZIndex"
            :inviteModalTitle="customModalTitle"
            :invitationName="invitationName"
            :projectGlobalId="projectGlobalId"
            :filterByProjectGlobalId="filterByProjectGlobalId"
            :resourceId="resourceId"
            :resourceType="modelType"
            :role="section"
            :bookmarkedContactPersons="bookmarkedContactsList"
            :invitedContactPersons="invitedContactsList"
            :bookmarkedContactPersonIds="bookmarkedContactPersonIds"
            :invitedContactPersonIds="invitedContactPersonIds"
            :withInvitationSurvey="true"
            :employeesOnly="showEmployeesOnly"
            :singleSelect="!selectMultiple"
            :disableBookmarking="disableBookmarking"
            :disableInviting="disableInviting"
            :disableInviteRemoval="disableInviteRemoval"
            :disableBookmarkRemoval="disableBookmarkRemoval"
            :allowedClientsTypes="allowedClientsTypes"
            :allowedCompaniesIds="allowedCompaniesIds"
            :excludedCompaniesIds="excludedCompaniesIds"
            :preselectedWorkspaceType="preselectedWorkspaceType"
            :initialSearchValue="initialSearchValue"
            :requireSelectionToSave="requireSelectionToSave"
            :categories="categories"
            :saveButtonCustomTitle="saveButtonCustomTitle"
            :searchInputCustomLabel="searchInputCustomLabel"
            :includeCurrentUserOnSearchResults="includeCurrentUserOnSearchResults"
            @inviteModalDone="onInviteModalDone"
            @setInviteModalOpened="onCloseInviteModal"
            @setInitialBookmarkedList="onSetInitialBookmarkedList"
            @setInitialInvitedList="onSetInitialInvitedList"
        />
    </div>
</template>

<script lang="ts" src="./InvitePreview.ts"></script>
<style src="./InvitePreview.scss" lang="scss" scoped></style>
