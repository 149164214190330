<template>
    <div class="io-notification-toastr">
        <template v-if="alertsList && alertsList.length > 1">
            <div class="io-notification-toastr__close-all" @click="dismissAllAlerts()">
                {{ $t('Dismiss All') }}
                <i class="icon-cross"></i>
            </div>
        </template>
        <transition-group tag="div" class="io-notification-toastr__list" appear name="alert">
            <SingleNotification v-for="alert in alertsList" :key="alert._id" :alert="alert" />
        </transition-group>
    </div>
</template>

<script src="./NotificationToastr.ts" lang="ts"></script>
<style lang="scss" src="./NotificationToastr.scss" scoped></style>
