import { defineComponent } from 'vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn'
import PhaseType from '@/interfaces/modules/projects/financial-approvals/PhaseType'

export default defineComponent({
    name: 'PhaseTypeRadio',
    components: {
        FormInputColumn
    },
    props: {
        resource: {
            type: String,
            required: true
        },
        isWorkflowSequenced: {
            type: Boolean,
            required: true
        },
        value: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            PhaseType
        }
    },
    computed: {
        type: {
            get (): string {
                return this.value
            },
            set (value: string): void {
                this.$emit('update:modelValue', value)
            }
        }
    }
})
