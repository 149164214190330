<template>
    <div
        ref="lazyLoadWrapper"
        class="io-lazy-load-wrapper"
    >
        <slot
            name="content"
            :records="records"
        ></slot>
    </div>
</template>

<script lang="ts" src="./LazyLoad.ts"></script>
<style lang="scss" src="./LazyLoad.scss" scoped></style>
