<template>
    <FilterSection :title="title">
        <template #default>
            <SmartTags
                v-model="selectedTags"
                :editable="true"
                :pattern="'task'"
                :placeholder="$t('Write tag and hit ”Enter”...')"
            />
        </template>
    </FilterSection>
</template>

<script>
import FilterSection from './filterSection.vue'
import SmartTags from '@/components/atoms/SmartTags/SmartTags.vue'

export default {
    name: 'FilterInput',
    emit: [ 'update:modelValue' ],
    components: {
        FilterSection,
        SmartTags
    },
    props: {
        title: {
            type: String,
            required: true
        },
        modelValue: {
            required: true
        }
    },
    computed: {
        selectedTags: {
            get () {
                return this.modelValue
            },
            set (val) {
                const values = val.map((v) => v.name)

                this.$emit('update:modelValue', values)
            }
        }
    }
}
</script>
<style lang="scss">
.io-filter-section {
    .io-smart-tags {
        .ti-input {
            padding: 0;
            border: 0;
        }

        .ti-tags {
            flex-wrap: wrap-reverse;
        }

        .ti-tag {
            font-size: 10px;
            line-height: 16px;
            font-weight: bold;
            color: #7B7E8E;
            background-color: #EAEAED;
            padding: 0 8px;
            margin: 0 8px 8px 0;
            border-radius: 10px;
            position: relative;
            transition: all .25s, background-color .22s;
            cursor: pointer;

            .ti-content {
                opacity: 1;
                transition: opacity .18s;
                user-select: none;
            }

            &:hover {
                background-color: rgba(242, 102, 102, 0.2);

                .ti-actions {
                    transform: scale(1);
                    opacity: 1;
                }

                .ti-content {
                    opacity: 0;
                }
            }
        }

        .ti-actions {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            opacity: 0;
            transition: transform .22s;

            .ti-icon-close {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 900;
                color: #f26666;
            }
        }

        .ti-new-tag-input-wrapper {
            margin-bottom: 8px;

            &:before {
                content: '\F067';
                font-family: 'Font Awesome 5 Pro';
                color: var(--input-icon-color);
                font-size: 12px;
                line-height: 16px;
                position: absolute;
                top: 13px;
                left: 20px;
                font-weight: 300;
            }

            input {
                padding: 0 12px 0 40px !important;
                border: 1px solid var(--input-border-color);
                border-radius: 4px;
                height: 40px;

                &::placeholder {
                    color: var(--input-placeholder-color);
                }
            }
        }

        .ti-autocomplete {
            max-height: 200px;
            overflow-y: auto;
            border: none;
            border-radius: 4px;
            box-shadow: 0 2px 8px 0 rgba(26, 26, 26, 0.1);

            &::-webkit-scrollbar {
                width: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #b9beca;
            }

            &::-webkit-scrollbar-track {
                background-color: var(--scrollbar-track);
            }

            ul {
                padding: 8px 0;

                li {
                    padding: 8px 16px;

                    > div {
                        background: #EAEAED;
                        border-radius: 10px;
                        font-weight: bold;
                        font-size: 10px;
                        line-height: 16px;
                        align-items: center;
                        text-transform: uppercase;
                        color: #7B7E8E;
                        width: auto;
                        padding: 0 8px;
                        display: inline-block;
                    }

                    &.ti-selected-item {
                        color: var(--text-light);
                        background-color: rgba(var(--accent-rgb), 0.16);
                    }
                }
            }
        }
    }
}
</style>
