import { mapState } from "vuex"

const InvoiceSurveyMixin = {
    computed: {
        ...mapState('payapps', {
            flags: (state: any): any => state.payApp.flags,
        }),

        ...mapState('appStore', {
            authData: (state: any) => state.authData
        }),
        isUserEmailFromIngenious (): boolean {
            return this.authData.u_email.includes('@ingenious.build')
        },
        isTrainingEnv (): boolean {
            return window.location.host.split('.')[2] === 'training'
        },
    },

    beforeMount () {
        !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var u=t.getElementsByTagName("script")[0];u.parentNode.insertBefore(o,u)}}(window,document,"iWamLn6wkjuzfe6x","delightedCes7")
    },
    methods: {
        initDelightedSurvey (): void {
            if (!delightedCes7 ||
                !this.isProduction ||
                this.isUserEmailFromIngenious ||
                this.isTrainingEnv
            ) {
                return
            }

            delightedCes7.survey({
                name: `${ this.authData.u_firstname } ${ this.authData.u_lastname }`,
                email: this.authData.u_email,
                locale: 'en',
                properties: {
                    workspaceName: this.authData.workspace_subdomain,
                    workspaceType: this.authData.app_type,
                    platform: 'Web',
                    locationToShow: this.$route.meta?.title
                },
            })
        },
    },
}

export default InvoiceSurveyMixin
