import { defineComponent, PropType } from 'vue'
import FinancialInfoTile from '@/components/financial-info-tile/FinancialInfoTile.vue'
import { FundingSourcesFundSummary } from '../../interfaces/GlobalFundingInterfaces.ts'

export default defineComponent({
    name: 'ProviderDetailsSummary',
    components: { FinancialInfoTile },
    props: {
        isCapitalFund: {
            type: Boolean,
            default: false,
        },
        summary: {
            type: null as PropType<FundingSourcesFundSummary>
        }
    },
})
