import { defineComponent } from 'vue'
import IOBanner from '@/components/atoms/IOBanner/IOBanner'
import LazyList from '@/components/lazy-list/LazyList'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder'
import scheduleTemplatesLazyListMixin
    from '@/io-modules/lists-templates/schedule-templates/mixins/scheduleTemplatesLazyListMixin'
import { mapState as mapStateVuex } from 'vuex'

export default defineComponent({
    name: 'MilestonesTemplatesListing',
    components: { IOPlaceholder, LazyList, IOBanner },
    mixins: [ scheduleTemplatesLazyListMixin ],
    computed: {
        ...mapStateVuex('lazyList', [
            'canEdit',
        ])
    },
    methods: {
        async createNewList (): Promise<void> {
            await this.$router.push({ name: 'lists-templates-schedule-templates-view', params: { id: 0 } })
        },
    }
})
