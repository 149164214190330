import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import Checkbox from '@/components/atoms/Checkbox/Checkbox'
import { inviteModalStore } from '@/components/invite-modal-v3/store/inviteModalStore'

export default defineComponent({
    name: 'InfoPanel',

    components: {
        Checkbox,
    },

    props: {
        componentKey: { type: String, required: true },
        disableInviting: { type: Boolean, required: false, default: false },
        disableBookmarking: { type: Boolean, required: false, default: false },
        employeesOnly: { type: Boolean, required: false, default: false },
    },

    emits: [
        'infoPanelExpanded',
    ],

    data () {
        return {
            isExpanded: false,
        }
    },

    computed: {
        ...mapState(inviteModalStore, {
            isInfoPanelVisible (store) {
                return store.isInfoPanelVisible(this.componentKey)
            },
        }),
    },

    methods: {
        ...mapActions(inviteModalStore, ['hideInfoPanel']),

        expand (): void {
            this.isExpanded = true
            this.$emit('infoPanelExpanded', this.isExpanded);
        },

        collapse (): void {
            this.isExpanded = false
            this.$emit('infoPanelExpanded', this.isExpanded);
        },
    }
})
