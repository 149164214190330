import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import { TranslateResult } from 'vue-i18n'
import { get, isFunction } from 'lodash'
import moment from 'moment/moment'
import dayjs from 'dayjs'

export default defineComponent({
    props: {
        column: { type: Object as () => ColumnInterface, required: true, default: null },
        dotsColorFunction: { type: Function, required: true, default: null },
        isDate: { type: Boolean, default: false },
        isTranslation: { type: Boolean, default: true },
        status: { type: [String, Number], required: true, default: null },
        className: { type: String, required: false, default: '' },
        isBoolean: { type: Boolean, required: false, default: null },
        tableRow: { type: Object, required: true, default: null }
    },
    computed: {
        tooltipField (): any {
            // @ts-ignore
            return get(this.column, ['additional', 'tooltip'], null)
        },
        dotColorClassName (): string {
            return this.column.additional?.callFunctionByRowData
                ? this.dotsColorFunction(this.tableRow, this.column.field)
                : this.dotsColorFunction(this.status, this.column.field)
        },
        textColorClassName (): string {
            return this.column.additional?.colorTextSameAsDot
                ? 'io-text-color-same-as-dot'
                : ''
        },
        noTooltipClassName (): string {
            return !this.tooltipValue
                ? 'io-no-tooltip'
                : ''
        },
        tooltipValue (): string {
            return typeof this.tooltipField === 'function' ? this.tooltipField(this.tableRow) : this.tooltipField
        },
        tooltipAdditionalClassName (): string {
            const additionalHTMLFunction = get(this.column, ['additional', 'tooltipDotColorFunction'], null)
            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.tableRow)
            }

            return ''
        },
        text (): string | TranslateResult {
            if (this.isDate) {
                const dateFormat = this.column.additional?.dateFormat ?? null

                if (isFunction(this.column?.additional?.additionalHTMLFunction)) {
                    return this.column.additional.additionalHTMLFunction(this.tableRow)
                }

                if (dateFormat) {
                    return dayjs(this.tableRow[this.column.field]).format(dateFormat)
                }

                // @ts-ignore
                return this.$filters.formatDateOnly(this.status)
            }

            // @ts-ignore
            const additionalHTMLFunction = get(this.column, ['additional', 'additionalHTMLFunction'], null)

            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.tableRow)
            }

            if (this.isTranslation) {
                return this.$t(this.status)
            }

            if (this.isBoolean) {
                return this.column?.additional?.booleanStatusText
            }

            return this.status
        },
        tooltipIcon (): boolean {
            return this.column?.additional?.tooltipIcon
        }
    }
})
