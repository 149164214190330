import { defineComponent } from 'vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import Placeholder from '@/components/placeholder/Placeholder.vue'
import { PlaceholderButton } from '@/io-modules/bid-requests/interfaces'
import Draggable from 'vuedraggable'
import projectFundingRulesClient from '@/io-modules/project-funding/api-clients/projectFundingRulesClient'
import { mapState } from 'vuex'
import DoubleInput from '@/components/atoms/double-input/DoubleInput.vue'
import ProjectFundingRules from '@/io-modules/project-funding/interfaces/ProjectFundingRules'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import CostCodesDropdown
    from '@/modules/projects/modules/apps/common/project-settings/parts/financial-approvals/parts/cost-codes-dropdown/CostCodesDropdown.vue'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'
import ProjectFundingFundName from '@/io-modules/project-funding/interfaces/ProjectFundingFundName'
import { CostCodes } from '@/io-modules/project-funding/interfaces/ProjectFundingCostCodes'

export default defineComponent({
    name: 'ProjectFundingRules',
    components: { IOButton, CostCodesDropdown, CustomMultiselect, DoubleInput, Draggable, Placeholder, AlertBox },
    data () {
        return {
            loaded: false,
            projectRules: [] as ProjectFundingRules[],
            dropdownOptions: [
                {
                    name: `${ this.$t('Percent') } (%)`,
                    value: 'percent',
                },
                {
                    name: `${ this.$t('Amount') } ($)`,
                    value: 'amount',
                },
            ],
            multiselectOptions: {
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: false,
                preserveSearch: false,
                textPlaceholder: ''
            },
            fundsForRules: null as ProjectFundingFundName[],
            costCodes: null as CostCodes[],
        }
    },
    computed: {
        ...mapState('project', {
            projectLocalID: state => state.projectObj.project_local_id,
        }),
        buttonsCta (): Array<PlaceholderButton> {
            return [
                {
                    name: this.$tc('Add Rule'),
                    customAction: 'addRule',
                },
            ]
        }
    },
    async beforeMount () {
        await this.getProjectRules()
        await this.getCostCodes()
        this.loaded = true
    },
    methods: {
        async getProjectRules (): Promise<void> {
            try {
                const { data } = await projectFundingRulesClient.getProjectRules(this.projectLocalID)
                if (data.items instanceof Array) {
                    this.projectRules = data.items.map(item => ({
                        ...item,
                        cost_code_ids: item.cost_code_ids.map(id => ({ cost_code_id: id })),
                        creating: false,
                    }))
                }

                const fundsWithNoRulesResponse = await projectFundingRulesClient.getProjectRulesFunds(this.projectLocalID)
                if (fundsWithNoRulesResponse?.data?.items && fundsWithNoRulesResponse.data.items.length) {
                    this.fundsForRules = fundsWithNoRulesResponse.data.items
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        async getCostCodes (): Promise<void> {
            try {
                const { data } = await projectFundingRulesClient.getCostCodes(this.projectLocalID)
                this.costCodes = data
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        findRule (ruleId: string): ProjectFundingRules {
            return this.projectRules.find(rule => rule.id === ruleId)
        },
        updateRuleField (ruleId: string, field: string, value: string | number): void {
            const ruleToUpdate = this.findRule(ruleId)
            ruleToUpdate[field] = value
        },
        addRule (): void {
            this.projectRules.push({
                id: crypto.randomUUID(),
                project_local_id: this.projectLocalID,
                for_fund: null,
                unit_type: null,
                unit_value: null,
                cost_code_ids: [],
                creating: true,
            })
        },
        canCreateRule (ruleId: string): boolean {
            const rule = this.findRule(ruleId)
            return rule.for_fund !== null && rule.cost_code_ids?.length > 0 && rule.unit_value && rule.unit_type !== null
        },
        goToEditMode (ruleId: string): void {
            let rule = this.findRule(ruleId)
            if (rule) {
                rule.creating = true
                rule.editing = true
            }
        },
        async manageRule (ruleId: string): Promise<void> {
            try {
                const rule = this.findRule(ruleId)

                const ruleToCreate = {
                    project_local_id: rule.project_local_id,
                    for_fund_id: rule.for_fund.id,
                    unit_type: rule.unit_type ? rule.unit_type.value : this.dropdownOptions[0].value,
                    unit_value: rule.unit_value.toString(),
                    cost_code_ids: rule.cost_code_ids.map(elem => elem.cost_code_id)
                }

                if (rule.editing) {
                    await projectFundingRulesClient.editSingleProjectRule(ruleId, ruleToCreate)
                    rule.editing = false
                } else {
                    await projectFundingRulesClient.createSingleProjectRule(rule.project_local_id, ruleToCreate)
                }

                await this.getProjectRules()
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        getCostCodesNames (rule: ProjectFundingRules): string {
            const foundCodeNames = []

            rule.cost_code_ids.forEach(budgetLine => {
                this.costCodes.forEach(item => {
                    item.codes.forEach(code => {
                        if (code.id === budgetLine.cost_code_id) {
                            foundCodeNames.push(code.code_name)
                        }
                    })
                })
            })

            const costCodesCount = this.costCodes.reduce((count, item) => {
                return count + item.codes.length
            }, 0)

            if (foundCodeNames.length === costCodesCount) {
                return this.$t('All Cost Codes')
            } else {
                return foundCodeNames.join(', ')
            }
        },
        async specifyRulesOrder (): Promise<void> {
            try {
                const orderIDs = this.projectRules.map(rule => rule.id)
                await projectFundingRulesClient.specifyRulesOrder(this.projectLocalID, orderIDs)
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        async removeRule (ruleId: string): Promise<void> {
            try {
                await projectFundingRulesClient.deleteSingleProjectRule(ruleId)
                await this.getProjectRules()
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        deleteRule (ruleId: string): void {
            const rule = this.findRule(ruleId)

            if (rule.editing) {
                rule.creating = false
                rule.editing = false
            } else {
                this.projectRules = this.projectRules.filter(rule => rule.id !== ruleId)
            }
        },
    },
})
