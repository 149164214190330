import Resource from '@/io-modules/approval-workflows/enums/Resource'
import i18n from '@/base/i18n'
import VueI18n from 'vue-i18n'
import { PopupConfig } from '@/layouts/offcanvas/popup-alert/PopupAlert'
import ApprovalWorkflowsService from './ApprovalWorkflowsService'
import ApprovalTextService from './ApprovalTextService'
import Workflow from '../interfaces/Workflow'


class ApprovalConfirmationModalService {
    static i18n: typeof VueI18n = i18n

    public static sendReminder (callback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Do you want to send a reminder to active user?'),
            icon: 'icon-send-2',
            caption: '',
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Send'),
                    class: 'io-btn-primary',
                    saveDisabledInSession: true,
                    loaderOnAction: false,
                    action: callback,
                },
            ],
        }
    }

    public static revertToDraft (callback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to revert to draft?'),
            icon: 'icon-undo-rec',
            iconColor: 'var(--warning-darker)',
            caption: this.i18n.global.t('Reverting to the draft will require a re-submission for approval.'),
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Revert to Draft'),
                    class: 'io-btn-primary',
                    saveDisabledInSession: true,
                    loaderOnAction: false,
                    action: callback,
                },
            ],
        }
    }

    public static resetApprovalWorkflow (callback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to reset the approval workflow?'),
            icon: 'icon-undo-rec',
            iconColor: 'var(--warning-darker)',
            caption: this.i18n.global.t('Resetting the approval workflow will require a re-submission for approval.'),
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Reset Approval Workflow'),
                    class: 'io-btn-primary',
                    saveDisabledInSession: true,
                    loaderOnAction: false,
                    action: callback,
                },
            ],
        }
    }

    public static submitForInternalApproval (resourceType: Resource, workflows: Workflow[], callback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to submit for internal approval?'),
            caption: this.submitForApprovalText(resourceType, workflows),
            icon: 'icon-send-2',
            iconColor: 'var(--info)',
            sessionKey: this.getSessionKey(resourceType),
            disabledInSessionCallback: callback,
            width: 572,
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Submit'),
                    class: 'io-btn-primary',
                    saveDisabledInSession: true,
                    loaderOnAction: false,
                    action: callback,
                },
            ]
        }
    }

    private static submitForApprovalText (resourceType: Resource, workflows: Workflow[]): string {
        if (ApprovalWorkflowsService.allWorkflowsSequenced(workflows)) {
            return this.i18n.global.t(`Selecting ‘Submit’ will notify the first approver within the selected workflow that this ${ ApprovalTextService.getResourceName(resourceType)?.toLocaleLowerCase() || '' } is ready for their review and approval.`)
        }
        if (ApprovalWorkflowsService.noWorkflowSequenced(workflows)) {
            return this.i18n.global.t(`Selecting ‘Submit’ will notify all approvers within the selected workflow that this ${ ApprovalTextService.getResourceName(resourceType)?.toLocaleLowerCase() || '' } is ready for their review and approval.`)
        }

        return this.i18n.global.t(`Selecting ‘Submit’ will notify the relevant approvers within the selected workflow that this ${ ApprovalTextService.getResourceName(resourceType)?.toLocaleLowerCase() || '' } is ready for their review and approval.`)
    }

    private static getSessionKey (resourceType: Resource): string {
        return {
            [Resource.BUDGET_APPROVAL]: 'budget-submit-to-client',
            [Resource.BUDGET_CHANGES]: 'budget-change-submit-to-client',
            [Resource.CONTRACT]: 'contract-submit-to-client',
            [Resource.CHANGE_ORDER]: 'change-order-submit-to-client',
            [Resource.INVOICE]: 'invoice-submit-for-funding',
        }[resourceType]
    }

    public static submitToClient (resourceType: Resource, callback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to submit to client this {resource}?', { resource: ApprovalTextService.getResourceName(resourceType)?.toLocaleLowerCase() || '' }),
            icon: 'icon-send-2',
            iconColor: 'var(--info)',
            caption: '',
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Submit to Client'),
                    class: 'io-btn-primary',
                    loaderOnAction: false,
                    action: callback,
                },
            ],
        }
    }

    public static markAsSubmittedToClient (resource: Resource, callback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t(`Are you sure you want to mark this ${ ApprovalTextService.getResourceName(resource)?.toLocaleLowerCase() || '' } as submitted to the client?`),
            icon: 'icon-send-2',
            iconColor: 'var(--info)',
            caption: '',
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Mark as Submitted to Client'),
                    class: 'io-btn-primary',
                    loaderOnAction: false,
                    action: callback,
                },
            ],
        }
    }
}

export default ApprovalConfirmationModalService
