import { ApiClient as client } from '@/api/api'
import Settings from '@/interfaces/modules/projects/settings/invoices-settings/Settings'
import { AxiosPromise } from 'axios'

const BillingSettingsClient = {
    getInvoicesSettings (projectCompanyId: string): AxiosPromise<{data: Settings}> {
        return client.get('invoices-settings', { params: { project_company_id: projectCompanyId } })
    },
}

export default BillingSettingsClient
