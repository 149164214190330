import { defineComponent } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

import useLoader from '@/composables/useLoader.ts'
import getInitials from '@/io-modules/punch-lists/composables/getInitials'
import punchStampsClient from '@/io-modules/punch-lists/api-clients/punchStampsClient'

import ColorPicker from '@/components/atoms/ColorPicker/ColorPicker.vue'

import { Stamp, BaseStamp, StampPayload } from '@/io-modules/punch-lists/interfaces/Stamp'
import { Color } from '@/components/atoms/ColorPicker/enums/Color'

export default defineComponent({
    name: 'StampCreate',
    components: {
        ColorPicker,
    },
    props: {
        initialTitle: {
            type: String,
            default: '',
        },
    },
    emits: ['close', 'stampCreated'],
    setup () {
        const { loading, load } = useLoader()
        return { loading, load, v$: useVuelidate() }
    },
    data () {
        return {
            stamps: null as Stamp[],
            title: this.initialTitle,
            initials: getInitials(this.initialTitle),
            color: Color.ORANGE as Color,
        }
    },
    computed: {
        projectId (): string {
            return this.$store.getters['project/projectCompanyMongoId']
        },
        payload (): StampPayload {
            const stamps = [
                ...this.stamps.map((stamp: Stamp) => {
                    return {
                        id: stamp.id,
                        title: stamp.title,
                        initials: stamp.initials,
                        color: stamp.color
                    }
                }),
                this.newStamp,
            ]
            return { stamps }
        },
        newStamp (): BaseStamp {
            return {
                id: null,
                title: this.title,
                initials: this.initials.toUpperCase(),
                color: this.color,
            }
        },
        formValid (): boolean {
            return !this.v$.$invalid && this.title && this.initials.length > 0
        },
    },
    watch: {
        title (title: string): void {
            this.initials = getInitials(title)
        },
    },
    created () {
        this.getStamps()
    },
    mounted () {
        this.v$.$touch()
    },
    methods: {
        getStamps (): Promise<void>  {
            return this.load(async (): Promise<void> => {
                const { data } = await punchStampsClient.getPunchStamps(this.projectId)
                this.stamps = data
            })
        },
        initialsUnique (initials: string): boolean {
            return !this.stamps.map((stamp: Stamp) => stamp.initials).includes(initials)
        },
        create (): void {
            if (!this.formValid) {
                return
            }

            this.load(async (): Promise<void> => {
                const { data } = await punchStampsClient.savePunchStamps(this.projectId, this.payload)
                const createdStamp = data.find((stamp: Stamp) => stamp.initials === this.initials)
                this.$emit('stampCreated', createdStamp)
            })
        },
    },
    validations () {
        return {
            title: { required , $autoDirty: true },
            initials: {
                required: helpers.withMessage(this.$t('The field is required', { field: this.$t('Initials') }), required),
                initialsUnique: helpers.withMessage(this.$t('These initials are in use'), this.initialsUnique),
            },
        }
    },
})
