import { defineComponent } from 'vue'
import { mapGetters as mapGettersVuex, mapActions as mapActionsVuex } from 'vuex'
import { mapState } from 'pinia'
import Table from '@/components/table/Table.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import purchaseOrdersClient from '@/api/project-purchase-orders/purchaseOrdersClient'

export default defineComponent({
    name: 'PurchaseOrderField',
    components: {
        Table,
    },
    data () {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapGettersVuex('payapps', ['getPayAppPurchaseOrder']),

        ...mapState(invoiceViewStore, ['isEditingMode']),

        headers (): HeaderTable[] {
            const allHeaders = [
                {
                    text: this.$t('Purchase Order Name'),
                    visible: true,
                    sort: false,
                    extraStyles: 'width: 60%',
                },
                {
                    text: this.$t('Amount'),
                    visible: true,
                    sort: false,
                    align: 'end',
                },
                {
                    text: this.$t('Billed Against'),
                    visible: true,
                    sort: false,
                    align: 'end',
                },
                {
                    text: '',
                    visible: this.isEditingMode,
                    sort: false,
                },
            ]

            return allHeaders.filter(item => item.visible)
        },
    },
    methods: {
        ...mapActionsVuex('payapps', ['setPurchaseOrder']),

        goPurchaseOrder (): void {
            const link = this.$router.resolve({
                name: 'project-purchase-order',
                params: {
                    id: this.getPayAppPurchaseOrder.id,
                },
            }).href
    
            window.open(link, '_blank', 'noopener')
        },

        handleRemovePurchaseOrder (): void {
            // if (this.isSubmittedForApproval || this.isApprovedInternally) {
            //     this.showNotification('error', this.$t('An invoice that is submitted for approval or is approved cannot be disassociated from a purchase order.'))
            //     return
            // }

            this.showPopupAlert({
                icon: 'icon-trash',
                iconColor: 'var(--warning-darker)',
                title: this.$t('Are you sure you want to remove the purchase order from this invoice?'),
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Remove Purchase Order'),
                        class: 'io-btn-primary',
                        action: () => this.removePurchaseOrder()
                    }
                ]
            })
        },

        async removePurchaseOrder (): Promise<void> {
            try {
                this.isLoading = true
                //const { data } = await purchaseOrdersClient.disassociatePurchaseOrder(this.$route.params.id)
                this.setPurchaseOrder(null)
                //this.showNotification('success', data.message)
            } catch (error) {
                this.isLoading = false
                this.errorHandleNoRedirect(error)
            } finally {
                this.isLoading = false
            }
        },
    },
})