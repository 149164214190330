import store from '@/store/index'

export default {
    /**
     *
     * GET LIST FROM LOCAL STORAGE
     * @param type
     * @returns {Promise<null>}
     */
    async getList (type) {
        return store.state.cached.lists[type]
    },

    /**
     * Force refresh list
     * @param type
     */
    async refreshList (type) {
        await store.dispatch('cached/clearList', type)
        return this.getList(type)
    }
}