<template>
    <div class="io-budget-site-select">
        <IOLabel v-if="hasLabel" is-required type="bold-max" :text="labelText ?? $t('Project site locations')" />

        <Multiselect
            track-by="id"
            label="formatted_address"
            class="d-inline"
            data-testid="budget-site-selector"
            :multiple="multiple"
            :close-on-select="!multiple"
            :show-labels="false"
            :searchable="!!options?.length"
            :options="options"
            :placeholder="placeholder"
            v-model="sites"
            @update:modelValue="$emit('update:modelValue', $event)"
        >
            <template #beforeList>
                <div v-if="multiple" class="flex items-center io-list-header" @click="selectAll">
                    <Checkbox
                        :checkbox-value="true"
                        :model-value="allSelected"
                        :partial-check="someItemsSelected && !allSelected"
                    />
                    {{ $t('Select All') }}
                </div>
            </template>

            <template #selection="{ values, search, isOpen }">
                <span v-if="values.length && !search.length" v-show="!isOpen" class="io-multiple-selected-options">
                    <template v-if="Array.isArray(sites)">
                        <span class="io-selected-amount">{{ sites.length }}</span> {{ $t('Project site locations selected') }}
                    </template>

                    <span v-else>{{ placeholder }}</span>
                </span>
            </template>

            <template #option="{ option }">
                <div
                    class="flex items-center"
                    :class="{ 'multiselect__option--disabled': option.$isDisabled }"
                    :data-testid="option.id"
                >
                    <Checkbox
                        v-if="Array.isArray(sites)"
                        :checkbox-value="option.id"
                        :model-value="!!sites.find((site) => site.id === option.id) || option.$isDisabled"
                        :disabled="option.$isDisabled"
                    />

                    <div>
                        <span>{{ option.formatted_address }}</span>

                        <template v-if="option.status">
                            <span class="io-dot"></span>
                            <span class="io-site-label">{{ option.status }}</span>
                        </template>

                        <StatusPill
                            v-if="option.$isDisabled"
                            class="ml-3"
                            variant="green-light"
                            :text="$t('Added')"
                        />

                        <div v-if="option.tags">
                            <StatusPill
                                v-for="tag in option.tags"
                                :key="tag.id"
                                variant="gray-light"
                                class="mr-2"
                                :text="tag.name"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <template #noResult>{{ $t('No results found') }}</template>
        </Multiselect>
    </div>
</template>

<script lang='ts' src='./BudgetSiteSelect.ts'></script>
<style lang="scss" src='./BudgetSiteSelect.scss' scoped></style>
