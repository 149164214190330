import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'SearchInput2',
    props: {
        placeholder: { type: [String, Object as () => TranslateResult], default: '' },
        iconColor: { type: String },
        timeout: { type: Number, default: 500 },
        startLength: { type: Number, default: 2 },
        loader: { type: Boolean, default: true },
        totalRecords: { type: Number, required: false, default: 1 },
        show: { type: Boolean, required: false, default: false },
        searchDisabled: { type: Boolean, required: false, default: false },
        width: { type: Number, required: false },
        searchVal: { type: String, required: false, default: '' },
        modelValue: { type: String, required: false, default: '' },
        tooltipText: { type: String, default: 'Search' },
        openSearchDisabled: { type: Boolean, default: false },
    },
    emits: ['searchChanged', 'update:modelValue'],
    data () {
        return {
            searchKeyword: '' as string,
            timeoutHandle: setTimeout(() => '', this.timeout) as ReturnType<typeof setTimeout>,
            total: 0 as number, // if this === 0, searchInput is not displayed
            isSearch: false as boolean,
            showLoader: false,
            visible: false,
        }
    },
    computed: {
        isSearching (): any {
            this.isSearch = this.$store.getters['listingFilter/getIsSearching'] ?? true
        },

        searchInputWidth (): string {
            return 'width: ' + this.width + 'px'
        },

        showComponent (): boolean {
            return (this.total > 0 && this.searchKeyword.length === 0) ||
                this.searchKeyword.length > 0 ||
                this.show
        }
    },
    watch: {
        searchVal (value: string): void {
            this.searchKeyword = value
        },
        value (value: string): void {
            this.searchKeyword = value
        },
    },
    mounted () {
        this.total = this.totalRecords
        this.searchKeyword = this.searchVal
    },
    methods: {
        clearInput (): void {
            this.searchKeyword = ''
            this.searchChanged()
        },
        clearInputWithoutEmit (): void {
            this.searchKeyword = ''
        },
        searchChanged (): void {
            if (
                this.searchKeyword.length >= this.startLength ||
                this.searchKeyword.length === 0
            ) {
                if (this.loader) {
                    try {
                        this.$store.dispatch('listingFilter/setIsSearching', true)
                        this.showLoader = true
                    } catch (e) {
                        //for displaying loader in storybook
                        this.showLoader = true
                    }
                }
                clearTimeout(this.timeoutHandle)
                this.timeoutHandle = setTimeout(() => {
                    this.$emit('searchChanged', this.searchKeyword)
                    this.$emit('update:modelValue', this.searchKeyword)
                    this.showLoader = false
                }, this.timeout)
            }
        },
        showSearch (): void {
            if (this.openSearchDisabled) {
                return
            }

            this.visible = true

            this.$nextTick(() => {
                this.$refs.searchInputNode.focus()
            })
        },
        hideSearch (): void {
            this.visible = false
        },
    },
})
