import { defineComponent, PropType } from 'vue'
import Table from '@/components/table/Table.vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface.ts'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import { mapActions, mapState } from 'pinia'
import { appPackageViewStore } from '@/io-modules/invoices/stores/appPackageView.ts'
import ApprovalAction from '@/io-modules/approval-workflows/enums/ApprovalAction.ts'
import ApprovalModalType from '@/io-modules/approval-workflows/enums/ApprovalModalType.ts'
import appPackagesTableHeaders from '@/io-modules/invoices/helpers/appPackageTableHeaders.ts'
import { AppPackageTableType } from '@/io-modules/invoices/enums/AppPackageTableType.ts'

export default defineComponent({
    name: 'InternalRejectionModal',
    components: { IOModal, Table, AlertBox },
    data () {
        return {
            invoices: [],
        }
    },
    computed: {
        ...mapState(appPackageViewStore, [
            'attachedInvoicesList',
        ]),

        tableHeaders (): HeaderTable[] {
            return appPackagesTableHeaders(AppPackageTableType.InvoicesToReject)
        },
        headerCheckboxClass (): string {
            if (this.invoices.every(invoice => invoice.isSelected)) {
                return 'io-check'
            } else if (this.invoices.some(invoice => invoice.isSelected)) {
                return 'io-minus'
            } else {
                return ''
            }
        },
        areAllItemsSelected (): boolean {
            return this.invoices.every(invoice => invoice.isSelected)
        },

        collectSelectedInvoices (): Array<string> {
            const selInvIds = this.invoices
                .filter(inv => inv.isSelected)
                .map(inv => inv.id)

            this.updateInvoiceIDsSelectedToReject(selInvIds)

            return selInvIds
        },

        selectedFromInvoices (): string {
            return `${ this.collectSelectedInvoices.length }/${ this.invoices.length }`
        },
    },
    beforeMount () {
        this.prepareData()
    },
    methods: {
        ...mapActions(appPackageViewStore, [
            'updateInvoiceIDsSelectedToReject',
        ]),

        prepareData (): void {
            this.attachedInvoicesList.forEach(invoice => {
                this.invoices.push({ ...invoice, isSelected: false })
            })
        },

        addCheckboxValue (value: boolean): void {
            this.invoices.forEach((invoice) => {
                invoice.isSelected = value
            })
        },

        submitForInternalRejection (): void {
            this.$emit('close')
            this.$emit('update:approvalModalData',
                {
                    action: ApprovalAction.REJECT,
                    modal: ApprovalModalType.UserApproval,
                    step: this.selectedFromInvoices
                })
        },
    },
})
