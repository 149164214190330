import { defineComponent } from 'vue'
import { NavItem } from '../../interfaces/NavItem.ts'
export default defineComponent({
    data () {
        return {
            navElements: [
                {
                    id: 0,
                    text: 'Project Scope',
                    element: '#io-project-scope',
                },
                {
                    id: 1,
                    text: 'Exclusions from Project Scope',
                    element: '#io-project-exclusions',
                },
            ] as NavItem[],
            activeElement: 0,
        }
    },
    methods: {
        setActiveElement (element: NavItem): void {
            this.activeElement = element.id
            this.scrollToElementWithOffset(element.element, -140)
        }
    }
})
