import { defineComponent, VNode } from 'vue'

export enum Size {
    SM = 'sm',
    MD = 'md',
}

export default defineComponent({
    name: 'TileStack',
    props: {
        limit: { // How many items to show
            type: Number,
            default: 3,
            validator: (value: number): boolean => value >= 0,
        },
        size: { // Affects counter dimensions and spacings
            type: String as () => Size,
            default: Size.MD,
            validator: (value: string): boolean => Object.values<string>(Size).includes(value),
        },
        counter: { // Shows a counter of remaining items past the limit
            type: Boolean,
            default: true,
        },
        addable: Boolean, // Shows a plus button
    },
    emits: ['stackClick', 'itemClick', 'counterClick', 'addClick'],
    data () {
        return {
            hoveredIndexes: new Set(),
        }
    },
    computed: {
        items (): VNode[] {
            return this.$slots.default()
                .map((slot: VNode) => slot.children || slot)
                .flat() as VNode[]
        },
        renderedItems (): VNode[] {
            return this.limit ? this.items.slice(0, this.limit) : this.items
        },
        remainingCount (): number {
            return this.items.length - this.limit
        },
    },
})
