<template>
    <div class="io-dashboard-card io-task-card">
        <div class="io-task-card__header">
            <div class="io-task-card__legend">
                <i class="fas fa-circle io-accent"></i>
                {{ $t('Tasks Completed') }}
                <span>{{ $t('vs.') }}</span>
                <i class="fas fa-circle io-teal"></i>
                {{ $t('Tasks Created') }}
            </div>
            <div class="io-task-card__buttons">
                <div :class="['io-dashboard-card__arrow-btn', {'io-dashboard-card__arrow-btn--disabled': loading}]" @click="getPrevious()"><i class="far fa-angle-left"></i></div>
                <div :class="['io-dashboard-card__arrow-btn', {'io-dashboard-card__arrow-btn--disabled': !canGetNext || loading}]" @click="getNext()"><i class="far fa-angle-right"></i></div>
            </div>
        </div>
        <div class="io-task-card__chart">
            <apexchart ref="apexchart" :options="chartOptions" :series="series" height="332px" width="100%"/>
        </div>
    </div>
</template>

<script>
    import VueApexCharts from 'vue3-apexcharts'
    import { mapGetters, mapState } from 'vuex'
    import moment from 'moment'
    import { get, isArray, clone } from 'lodash'

    export default {
        name: 'task-statistics',
        components: {
            apexchart: VueApexCharts
        },
        props: {
            data: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        data () {
            return {
                weeksBack: 1,
                allData: {}
            }
        },
        computed: {
            ...mapGetters('appStore', ['getAuthData']),
            ...mapState('loadingBar', {
                loading: state => state.loading
            }),
            getAppColor () {
                const appType = this.getAuthData.app_type
                switch (appType) {
                    case this.appTypes.TYPE_AC:
                        return '#8d43ef'
                    case this.appTypes.TYPE_SC:
                        return '#ffab00'
                    case this.appTypes.TYPE_GC:
                        return '#fb6a00'
                    case this.appTypes.TYPE_OWNER:
                        return '#26d07c'
                    case this.appTypes.TYPE_DEV:
                        return '#1952e1'
                    case this.appTypes.TYPE_INVESTOR:
                        return '#3da063'
                    case this.appTypes.TYPE_GENIUS:
                        return '#3da063'
                    default:
                        return '#ef8342'
                }
            },
            currentData () {
                if (this.allData) {
                    return this.allData[this.weeksBack]
                }

                return []
            },
            series () {
                return [
                    {
                        name: 'Tasks Completed',
                        type: 'column',
                        data: [
                            get(this.currentData, '[0].tasks_completed_count', 0),
                            get(this.currentData, '[1].tasks_completed_count', 0),
                            get(this.currentData, '[2].tasks_completed_count', 0),
                            get(this.currentData, '[3].tasks_completed_count', 0)
                        ]
                    },
                    {
                        name: 'Tasks Created',
                        type: 'column',
                        data: [
                            get(this.currentData, '[0].tasks_created_count', 0),
                            get(this.currentData, '[1].tasks_created_count', 0),
                            get(this.currentData, '[2].tasks_created_count', 0),
                            get(this.currentData, '[3].tasks_created_count', 0)
                        ]
                    },
                    {
                        name: 'Tasks Completed (area)',
                        type: 'area',
                        data: [
                            get(this.currentData, '[0].tasks_completed_count', 0),
                            get(this.currentData, '[1].tasks_completed_count', 0),
                            get(this.currentData, '[2].tasks_completed_count', 0),
                            get(this.currentData, '[3].tasks_completed_count', 0)
                        ]
                    }
                ]
            },
            chartOptions () {
                return {
                    chart: {
                        type: 'line',
                        stacked: false,
                        redrawOnParentResize: true,
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        width: [8, 8, 0],
                        curve: 'smooth',
                        colors: ['transparent']
                    },
                    states: {
                        normal: {
                            filter: {
                                type: 'none'
                            }
                        },
                        hover: {
                            filter: {
                                type: 'none'
                            }
                        },
                        active: {
                            allowMultipleDataPointsSelection: false,
                            filter: {
                                type: 'none'
                            }
                        }
                    },
                    title: {
                        show: false
                    },
                    xaxis: {
                        categories: this.getXaxisCategories,
                        labels: {
                            style: {
                                colors: 'var(--text-caption)',
                                fontSize: '13px',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600
                            }
                        },
                        tickPlacement: 'between',
                        offsetY: 8,
                        tooltip: {
                            enabled: false
                        },
                        crosshairs: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        }
                    },
                    yaxis: {
                        min: 0,
                        tickAmount: 5,
                        axisTicks: {
                            show: false
                        },
                        axisBorder: {
                            show: false
                        },
                        labels: {
                            show: true,
                            align: 'right',
                            offsetX: -10,
                            style: {
                                colors: 'var(--text-caption)',
                                fontSize: '12px',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600
                            }
                        },
                        followCursor: {
                            enabled: false
                        },
                        crosshairs: {
                            show: false
                        }
                    },
                    grid: {
                        borderColor: 'var(--separator)'
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '25%',
                            colors: {
                                backgroundBarRadius: 50
                            }
                        }
                    },
                    colors: [this.getAppColor, '#2bd2c9'],
                    fill: {
                        type: ['solid', 'solid', 'gradient'],
                        opacity: [1, 1, 1],
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            type: 'vertical',
                            opacityFrom: 0.4,
                            opacityTo: 0,
                            stops: [10, 90]
                        }
                    },
                    tooltip: {
                        enabled: true,
                        followCursor: false,
                        intersect: true,
                        shared: false,
                        x: {
                            show: false
                        },
                        y: {
                            formatter: (value) => value
                        }
                    },
                    legend: {
                        show: false
                    }
                }
            },
            getXaxisCategories () {
                if (this.currentData && isArray(this.currentData) && this.currentData.length > 0) {
                    let result = []
                    for (let i = 0; i < this.currentData.length; i++) {
                        result.push(`${ this.getChartSingleCategory(this.currentData[i].start_date) } - ${ this.getChartSingleCategory(this.currentData[i].end_date) }`)
                    }

                    return result
                }

                return ['-', '-', '-', '-']
            },
            canGetNext () {
                let weeksBack = clone(this.weeksBack)
                return this.allData[--weeksBack]
            }
        },
        watch: {
            data: {
                handler (value) {
                    if (isArray(value)) {
                        this.allData = {}
                        this.allData[this.weeksBack] = value
                    }
                },
                deep: true
            }
        },
        methods: {
            getChartSingleCategory (value) {
                return moment.utc(String(value)).local().format('MMMM Do')
            },
            getPrevious () {
                if (!this.loading) {
                    let weeksBack = clone(this.weeksBack)
                    if (this.allData[++weeksBack]) {
                        this.weeksBack++
                        return
                    }

                    this.loadPreviousFromDatabase()
                }
            },
            loadPreviousFromDatabase () {
                if (!this.loading) {
                    const weeksCount = 4
                    this.$store.dispatch('loadingBar/setLoading', true, { root: true })
                    this.$api.get('dashboard/task-stats', { params: { weeks_back: this.weeksBack * weeksCount } }).then(({ data }) => {
                        this.weeksBack++
                        this.allData[this.weeksBack] = data
                        this.$store.dispatch('loadingBar/setLoading', false, { root: true })
                    }).catch(error => {
                        this.errorHandle(error)
                        this.$store.dispatch('loadingBar/setLoading', false, { root: true })
                    })
                }
            },
            getNext () {
                if (this.canGetNext) {
                    this.weeksBack--
                }
            }
        }
    }
</script>

<style lang="scss" src="./task-statistics.scss" scoped></style>
