import { defineComponent } from 'vue'

import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import Popup from '@/components/popup/Popup.vue'
import TileStack from '@/components/atoms/TileStack/TileStack.vue'
import documentClient from '@/api/document/documentClient'

export default defineComponent({
    name: 'PreviewPopup',
    components: { Avatar, Popup, TileStack },
    props: {
        file: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            documentData: { on_item: [] },
            imageError: false,
        }
    },
    computed: {
        extension (): String {
            return this.file.file_name.includes('.') ? this.file.file_name.split('.').pop() : '-'
        },
        fileName (): String {
            const fileName = this.file.file_name
            const lastDotIndex = fileName.lastIndexOf('.')
            if (lastDotIndex === -1) {
                return fileName
            }
            return fileName.substring(0, lastDotIndex)
        },
    },
    methods: {
        async getDocumentData () {
            try {
                const { data } = await documentClient.getDocumentData(this.file._id)
                const filteredUsers = data.data.on_item.filter(user => user.employee_id !== this.file.author.sql_employee_id)

                this.documentData = { ...data.data, on_item: filteredUsers }
            } catch (e) {
                this.showNotification('error', e.message)
            }
        },
        imageLoadError () {
            this.imageError = true
        }
    },
    mounted () {
        this.getDocumentData()
    },
})
