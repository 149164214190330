
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
// @ts-ignore
import notificationMixin from '@/layouts/parts/top-bar/mixins/notificationMixin.js'

export default defineComponent({
    name: 'SingleNotification',
    props: {
        alert: { default: null }
    },
    mixins: [notificationMixin],
    data () {
        return {
            loading: false as boolean
        }
    },
    computed: {
        ...mapGetters('notificationToastr', ['isDuringAction'])
    },
    methods: {
        ...mapActions('notificationToastr', [
            'removeAlert',
            'setDuringAction'
        ]),
        dismissAlert (): void {
            this.markAlertAsRead(this.alert)
            this.removeAlert(this.alert._id)
        },
        async acceptInvite (): Promise<any> {
            if (this.isDuringAction) {
                return false
            }
            this.setDuringAction(true)
            this.loading = true
            try {
                const { data } = await this.$api.post(`/invitation/accept/${this.alert.relatedId}`)
                await this.$store.dispatch('cached/refreshList', 'projects')
                this.showNotification('success', data.msg)

                await this.$router.push({ path: data.custom_url })
            } catch (error) {
                this.showNotification('error', error.response.data.message, error)
            } finally {
                this.loading = false
                this.markAlertAsRead(this.alert)
                this.setDuringAction(false)
                this.removeAlert(this.alert._id)
            }
        },
        previewNotification (): void{
            this.markAlertAsRead(this.alert)
            this.removeAlert(this.alert._id)
            this.redirectToNotificationResource(this.alert)
        }
    }
})
