<template>
    <div
        v-if="displayAlert"
        class="io-design-system io-alert-box"
        :class="[`io-${ color }`, { 'io-alert-box--shadow': shadow }]"
    >
        <div class="io-alert-box__icon">
            <i :class="icon" />
        </div>

        <div class="io-alert-box__content">
            <slot />
        </div>

        <div
            v-if="dismissible"
            data-testid="alertbox-close"
            class="io-alert-box__icon"
            @click="hideAlert"
        >
            <i class="icon-cross" />
        </div>
    </div>
</template>

<script lang="ts" src="./AlertBox.ts"></script>
<style scoped lang="scss" src="./AlertBox.scss"></style>
