export interface Contact {
    id: string,
    firstname: string,
    lastname: string,
    email: string,
    avatar?: number|string,
    title?: string,
    workspace: {
        id: string,
        name: string,
        app_type: string,
        logo?: string
    },
    is_archived: boolean,
    is_employee: boolean,
    is_pending: boolean,
    is_removed: boolean,
    is_replaced: boolean,
    is_on_system: boolean,
    invitation: {
        id: string,
        type: string,
        status: string,
        can_delete: boolean, // This replaced the 'is_invitation_deletable_for_current_user' attribute
    },
    save_only?: boolean,
    invitation_status: string,
    is_bookmarked: boolean,
    matches_all_filters: boolean,
    is_current_workspace_contact: boolean,
}

export interface Domain {
    id: string,
    company_name: string,
    app_type: string,
    subdomain: string,
    address_line1: string,
    address_line2: string,
    city: string,
    zip: number,
    state_id: number,
    state_name: string | null,
    logo: string | null
}

export interface SearchData {
    matched_accounts: Contact[],
    matched_domains: Domain[],
    matched_my_contacts: Contact[],
    on_item: Contact[],
    workspace_types: string[]
}

export interface InviteModalState {
    isLoading: boolean,
    isClosing: boolean,
    matchedAccounts: Contact[],
    matchedDomains: Domain[],
    search: string,
    skip: number,
    resourceId: string|null,
    resourceType: string|null,
    role: string|null,
    invitedContacts: Contact[],
    bookmarkedContacts: Contact[],
    removedContacts: Contact[],
    replacedContacts: Contact[],
    isInfoPanelVisible: boolean,
    projectGlobalId: string|null,
    selectedWorkspaceType: string,
    contactsList: Contact[],
    filters?: InviteModalFilters,
    pagination?: InviteModalPagination,
}

export interface fetchContactsPayload {
    search: string,
    skip: number,
    filters: InviteModalFilters,
    resource_id: string|null,
    resource_type: string|null,
    project_global_id: string|null,
    role: string|null,
}

export interface fetchContactsByIdsPayload {
    contactPersonIds: string[],
    resourceId: string|number,
    resourceType: string,
    projectGlobalId: string|null,
}

export interface InviteModalFilters {
    project_global_id?: string,
    ids?: string[],
    workspace?: {
        app_types: string[],
        ids: string[],
    }
}

export interface InviteModalPagination {
    skip: number,
    take: number,
    order?: 'asc' | 'desc',
    orderBy?: string,
}

export interface fetchContactsDataPayload {
    filters?: InviteModalFilters,
    pagination?: InviteModalPagination,
}

export interface fetchAssignedContactsByResourcePayload {
    resourceId: string|number,
    resourceType: string,
}

export interface fetchContactsStorePayload {
    filters: InviteModalFilters,
}

export interface SplashScreen {
    icon: string,
    heading: string,
    subtext: string
}

export interface InviteModalDonePayload {
    resource_id: string|number,
    resource_type: string,
    project_global_id: string,
    invited: Contact[],
    bookmarked: Contact[],
}

export enum InvitationStatuses {
    ACCEPTED = 'accepted',
    INVITED = 'invited',
    READY_TO_REMIND = 'readyToRemind',
    READY_TO_SEND = 'readyToSend',
    READY_TO_SAVE = 'readyToSave',
    SAVED = 'saved'
}

export interface CreateContactPayload {
    email: string,
    first_name: string,
    last_name: string,
    is_new_company: boolean,
    workspace: {
        id: string,
        name: string,
        app_type: string,
        division_id: string,
        address_line1: string,
        address_line2: string,
        zip: string,
        city: string,
        state_id: string,
        country_id: string,
    }
}

export interface NewAddress {
    address_line1: string,
    address_line2: string,
    city: string,
    state: State,
    country: Country,
    zip: string,
}

export interface Country {
    id: string,
    code?: string,
    name: string
}

export interface State {
    id: string,
    code?: string,
    name: string,
    country_id: string
}

export interface FetchCompaniesPayload {
    modal: string,
    search: string,
}

export interface InviteModalDoneEvent {
    invited: Contact[],
    bookmarked: Contact[],
    removed: Contact[],
    replaced: Contact[],
}

export interface UpdateGlobalWorkspaceAppTypePayload {
    global_workspace_id: string,
    app_type: string,
}

export interface ExistingWorkspace {
    id: string,
    name: string,
    logo: string,
    app_type: string,
    subdomain: string,
    is_on_system: boolean,
    address: NewAddress,
}
