<template>
    <tr>
        <td v-for="header in columnValues" :key="header.field">
            <field-numeric-int
                v-if="CellType.InputNumInt === header.valueType"
                :id="header.field"
                :modelValue="cellModelValue(header)"
                :max="maxValue(header)"
                :min="minValue(header)"
                :options="{
                    ...header.inputOptions,
                    numeralPositiveOnly: header.inputOptions?.numeralPositiveOnly ?? false,
                    numericOnly: header.inputOptions?.numericOnly ?? false,
                    nonNumericAsZero: header.inputOptions?.nonNumericAsZero ?? false
                }"
                :showRounded="true"
                :sign="header.sign"
                @update:modelValue="data=>updateValue(header, data)"
            />

            <span v-else>
                {{ header.customValue(item) }}
            </span>
        </td>
    </tr>
</template>

<script lang="ts" src="./RevisionModalRow.ts"></script>
