<template>
    <div class="io-design-system io-step-container">
        <div
            class="io-step-container__header"
            :class="{
                'io-step-container__header--floating': isFloating,
                'io-step-container__header--no-shadow': !shadow,
                'io-step-container__header--no-content': noContent
            }"
        >
            <ul class="io-step-container__steps">
                <li v-for="(step, index) in steps" :key="index">
                    <a
                        class="io-step-container__step"
                        :class="{
                            'io-step-container__step--active': modelValue === index,
                            'io-step-container__step--valid': step.props.valid,
                            'io-step-container__step--invalid': step.props.hasError,
                        }"
                        @click="selectStep(index, step.props.disabled)">
                        <div class="io-step-container__title">
                            <div class="io-step-container__title--main">
                                <i
                                    v-if="showStepNumber"
                                    class="io-step-container__number"
                                    :class="{
                                        'io-step-container__number--active': modelValue === index,
                                        'io-step-container__number--valid': step.props.valid,
                                    }">
                                    <span v-if="!step.props.valid">{{ index + 1 }}</span>

                                    <i v-if="step.props.valid" class="fa fa-check"></i>
                                </i>
                                <transition name="fade">
                                    <StatusDot
                                        v-if="step.props.hasError"
                                        :variant="DotVariant.ERROR"
                                    />
                                </transition>
                                {{ step.props.title }}
                            </div>

                            <div
                                v-if="step.props.subtitle"
                                class="io-step-container__subtitle"
                                :class="{ 'io-step-container__subtitle--create': showStepNumber}"
                            >
                                {{ step.props.subtitle }}
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>


        <div v-if="!noContent" class="io-step-container__content" :class="{ 'io-step-container__content--floating': isFloating }">
            <div v-if="$slots.navigation" class="io-step-content__nav">
                <slot name="navigation"></slot>
            </div>

            <div ref="content" class="io-step-content__container">
                <div v-if="$slots.topInfo">
                    <slot name="topInfo"></slot>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./Stepper.ts"></script>
<style lang="scss" src="./Stepper.scss" scoped></style>
