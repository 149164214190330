<template>
    <div class="io-dropdown-content-wrapper">
        <transition name="fade">
            <div v-if="selectedItem" class="io-selected-container">
                <div
                    v-if="showAvatar && selectedItem.image"
                    class="io-initials"
                    :style="getImage(selectedItem)"
                ></div>

                <initials v-else-if="showAvatar && selectedItem.name" :fullName="getName(selectedItem.name)"/>

                <div class="io-selected-container__content">
                    <div v-if="selectedItem.name" class="io-name"  data-testid="selected-option-name">{{ getName(selectedItem.name) }}</div>

                    <div v-if="showDescription && description" class="io-info" data-testid="selected-option-description">{{ description }}</div>
                </div>

                <div v-if="editable" class="io-icon-edit" data-testid="selected-option-reset" @click="resetSelected">
                    <i class="fas fa-times"></i>
                </div>
            </div>
        </transition>

        <transition name="fade">
            <div
                v-if="!selectedItem"
                v-click-outside="hideMenu"
                class="io-custom-dropdown"
                :class="{'io-active': !isCollapsed, 'io-error': validationError}"
                data-testid="drop-down"
            >

                <div class="io-dropdown-header" data-testid="drop-down-header" @click="menuCollapsed">
                    <p class="io-dropdown-header__selected-option" data-testid="drop-down-placeholder">
                        {{ selectedItem ? selectedItem.name : placeholder }}
                    </p>
                    <div class="io-dropdown-header__arrow">
                        <em class="fas fa-caret-down"></em>
                    </div>
                </div>

                <transition name="io-dropdownAppear">
                    <div v-show="!isCollapsed" class="io-dropdown-options" data-testid="drop-down-options">
                        <div v-if="showSearch" class="io-dropdown-options__options-search">
                            <div class="io-dropdown-options__options-icon">
                                <i class="far fa-search"></i>
                            </div>
                            <input
                                ref="searchInput"
                                type="text"
                                :placeholder="placeholderSearch"
                                data-testid="search-input"
                                v-model="search"
                            />
                        </div>

                        <div v-if="isGrouped" class="io-dropdown-options__options-wrapper">

                            <template v-for="(option, idx) in showSearch ? filteredValues : options" :key="idx">
                                <div class="io-dropdown-options__options-section">
                                    <p
                                        v-if="option.groupName !== 'Invite Contact'"
                                        class="io-dropdown-options__options-title"
                                        data-testid="drop-down-group-name"
                                    >
                                        {{ option.groupName }}
                                    </p>

                                    <template v-for="item in option.groupList" :key="item.id">
                                        <single-option
                                            v-if="item.id !== 'new'"
                                            :item="item"
                                            :showDescription="showDescription"
                                            :image="getImage(item)"
                                            @select="selectOption"
                                        />
                                    </template>
                                </div>
                            </template>
                        </div>

                        <div class="io-dropdown-options__options-wrapper" v-else>
                            <template v-for="item in filteredOptions" :key="item.id">
                                <single-option
                                    v-if="item.id !== 'new'"
                                    :item="item"
                                    :showDescription="showDescription"
                                    :image="getImage(item)"
                                    @select="selectOption"
                                />
                            </template>
                        </div>

                        <div v-if="allowInvite" class="io-dropdown-options__bottom-bar" data-testid="add-new-option">
                            <div class="io-dropdown-options__add-option" data-testid="add-new-option-action" @click="addNew()">
                                <i class="fas fa-plus-circle"></i>
                                <p data-testid="add-new-option-label">{{ addNewLabel }}</p>
                            </div>
                        </div>

                    </div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script src="./CustomDropdown.js"></script>
<style lang="scss" src="./CustomDropdown.scss" scoped></style>
