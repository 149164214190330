import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { ProjectSettings, ProjectSettingsPayload } from '@/io-modules/rfis/interfaces/ProjectSettings'


export default {
    getSettings (projectIdLocal: string): AxiosPromise<ProjectSettings> {
        return client.get(`ca/rfi/${ projectIdLocal }/settings`)
    },
    saveSettings (projectIdLocal: string, payload: ProjectSettingsPayload): AxiosPromise<ProjectSettings> {
        return client.post(`ca/rfi/${ projectIdLocal }/settings`, payload)
    },
}
