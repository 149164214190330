<template>
    <div class="io-error-placeholder">
        <div v-if="title" class="io-error-placeholder__header">
            {{ title }}
        </div>
        <div v-if="!loading" class="io-error-placeholder__content">
            <div class="io-error-placeholder__title">{{ $t('Something went wrong') }}</div>
            <div class="io-error-placeholder__sub-title">{{ $t('We couldn\'t load the required data.') }}</div>
            <IOButton variant="link" icon="icon-refresh-cw" size="big" @click="$emit('reload')">
                {{ $t('Reload') }}
            </IOButton>
        </div>
        <div v-if="loading" class="io-error-placeholder__content">
            <div class="io-loader-small"></div>
        </div>
    </div>
</template>

<script lang="ts" src="./RequestedDataErrorPlaceholder.ts"></script>
<style lang="scss" src="./RequestedDataErrorPlaceholder.scss" scoped></style>