<template>
    <div class="io-right-panel-markups">
        <div class="io-markups-header">
            <div class="io-markups-header__top">
                <SearchInput
                    :placeholder="$t('Search for markups...')"
                    :loader="false"
                    :startLength="1"
                    :width="260"
                    @searchChanged="searchValue = $event"
                />
                <IOButton
                    v-if="addButtonText(activeTab.componentName) && !isArchivedVersion"
                    variant="link"
                    icon="icon-comment-plus"
                    @click="startAddingAnnotation(activeTab.type)"
                >
                    {{ addButtonText(activeTab.componentName) }}
                </IOButton>
            </div>
            <div class="io-markups-header__bottom">
                <ul>
                    <li
                        v-for="tab in filteredTabs"
                        :key="tab.name"
                        :class="{ 'io-active': activeTab.componentName === tab.componentName }"
                        @mouseenter="hoveredTab = tab.componentName"
                        @mouseleave="hoveredTab = null"
                        @click="changeActiveTab(tab)"
                    >
                        <i :class="'icon-' + tab.icon" />
                        <span
                            class="io-tab-name"
                            :class="{ 'io-tab-name--active': activeTab.componentName === tab.componentName || hoveredTab === tab.componentName  }"
                        >
                            {{ tab.name }}
                        </span>
                    </li>
                </ul>
                <DropdownButtonNew
                    v-if="activeTabType === 'comment'"
                    :iconMode="true"
                    fontAwesomeClass="icon-filter"
                >
                    <div class="io-single-option flex gap-8-px p-16-px" @click="toggleResolvedComments(!showResolved)">
                        {{
                            $t('Show Resolved Comments')
                        }}
                        <i v-if="showResolved" class="icon-check text-main-lighten-3 m-0" />
                    </div>
                </DropdownButtonNew>
            </div>
        </div>

        <div class="io-right-panel-markups__content">
            <transition name="fade" mode="out-in">
                <Component
                    :is="activeTab.componentName"
                    :searchValue="searchValue"
                    :isResolved="showResolved"
                    :selectedAnnotation="selectedAnnotation"
                    :addingComment="addingAnnotation"
                    @delete-pdftron-markup="deletePdftronMarkup"
                    @cancel-annotation="cancelAnnotation"
                    @annotation-deleted="annotationDeleted"
                />
            </transition>
        </div>
    </div>
</template>

<script lang="ts" src="./Markups.ts"></script>
<style lang="scss" src="./Markups.scss" scoped></style>
