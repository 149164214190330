import { defineComponent } from 'vue'

import matchLabelTitle from '@/io-modules/specifications/components/spec-reference-select/utils/matchLabelTitle'
import ascend from '@/io-modules/specifications/components/spec-reference-select/utils/ascend'

import SearchInput from '@/components/search-input/SearchInput.vue'
import DivisionsShow from '@/io-modules/specifications/components/spec-reference-select/parts/divisions-show/DivisionsShow.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'

import { Division } from '@/io-modules/specifications/components/spec-reference-select/interfaces/Specification'

export default defineComponent({
    name: 'DivisionsIndex',
    components: {
        SearchInput,
        DivisionsShow,
        IOPlaceholder,
    },
    props: {
        divisions: {
            type: Object as () => Division[],
            required: true,
        },
    },
    data () {
        return {
            search: '',
        }
    },
    computed: {
        sortedDivisions (): Division[] {
            return this.divisions.sort(ascend)
        },
        filteredDivisions (): Division[] {
            return this.search
                ? this.sortedDivisions
                    .filter((division: Division) => {
                        const matchUserSearch = matchLabelTitle(this.search)

                        const divisionMatched = matchUserSearch(division)
                        const sectionsMatched = division.sections.some(matchUserSearch)

                        return divisionMatched || sectionsMatched
                    })
                : this.sortedDivisions
        },
    },
})
