<template>
    <div class="io-invoices">
        <LoadingScreen :custom-show-loading="!loaded"/>
        <ManageColumnsModal
            v-if="showManageColumns && loaded"
            :fixed-columns="fixedColumnsList"
            :scrollable-columns="scrollableColumnsList"
            @close="closeManageColumns"
            @save-settings="saveManageColumnsSettings"
        />
        <ListFiltersModal v-if="loaded" @save-filters="saveFilters" />
        <div v-if="loaded">
            <PageHeader :title="pageHeaderTitle" :route-back="$route.meta.back ? { name: $route.meta.back } : null">
                <template #actions>
                    <TopbarActions
                        :is-owner="isOwner"
                        :is-owner-rep="isOwnerRep"
                        @createInvoiceClick="createInvoice"
                        @openExportModal="showExportModal = true"
                    />
                </template>
                <template v-if="$route.meta.list" #tabs>
                    <Tabs
                        :tabs="invoicesTabs"
                        :active-tab="currentRouteTab"
                        :show-title="false"
                        @click="switchTab"
                    />
                </template>
            </PageHeader>
            
            <LoadingScreen :custom-show-loading="showContentLoader"/>
            <AlertBox v-if="showTableCorrectnessInfo" color="yellow" icon="icon-alert-circle">
                <span>
                    {{ $t('The current browser window is too small to display this table correctly. Consider resizing your browser window or adjusting fixed columns in') }}
                    <span class="io-alert-link" @click="openManageColumns">{{ $t('Manage Columns') }}</span>
                </span>
            </AlertBox>
            <router-view
                @createInvoiceClick="createInvoice"
                @openManageColumns="openManageColumns"
            />
        </div>
        <AddInvoice v-if="modalInvoiceEnabled" @invoiceAdded="handleInvoiceAdded"/>
        <AddInvoiceOptions v-if="showInvoiceOptions" :vendorsList="vendorsList"/>
        <InvoiceCreationSlideout
            v-if="showCreateInvoiceModal"
            :pre-selected-contract-id="preSelectedContractId"
            @close="showCreateInvoiceModal = false"
        />
        <ExportToExcelModal v-if="showExportModal" :tabs="invoicesTabs" @close="showExportModal = false"/>
    </div>
</template>

<script lang="ts" src="./ProjectInvoicesTemplate.ts"></script>
<style lang="scss" src="./ProjectInvoicesTemplate.scss" scoped></style>
