// @ts-ignore
const DocumentsShow = () => import(/* webpackChunkName: "documents" */ '@/io-modules/documents/views/DocumentsShow.vue')


export default [
    {
        path: '/project/:pid/documents/:id',
        name: 'project-documents-show',
        props: true,
        component: DocumentsShow,
        meta: {
            navigationKey: 'documents'
        }
    },
    {
        path: '/documents/:id',
        name: 'documents-show',
        props: true,
        component: DocumentsShow,
        meta: {
            navigationKey: 'documents'
        }
    }
]
