<template>
    <ul class="p-7 grid grid-cols-12 gap-9">
        <li
            v-for="detail in details"
            :key="detail.title"
            class="col-span-6"
        >
            <div class="text-main-lighten-2 text-sm mb-2">{{ detail.title }}</div>
            <Avatar
                v-if="detail.value?._id"
                size="sm"
                :employee-mongo-id="detail.value._id"
                :show-full-name="true"
                :employeeFullName="`${ detail.value.firstname } ${ detail.value.lastname }`"
            />
            <div v-else class="text-main-lighten-1 text-sm font-medium">{{ detail.value }}</div>
        </li>
    </ul>
</template>

<script lang="ts" src="./DetailsTab.ts"></script>
