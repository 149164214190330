export enum InviteModalClientKeys {
    TASK_DETAILS = 'task-details-client',
    TASK_LISTING = 'task-listing-client',
    TASK_CREATE = 'task-create-client',
    TASK_KANBAN = 'task-kanban-client',
    DOCUMENT_DETAILS = 'document-details-client',
    DOCUMENT_FILE = 'documents-file-client',
    DRAWING_DRAFT = 'drawing-draft',
    PROJECT_DIRECTORY_MY_COMPANY = 'project-directory-my-company',
    PROJECT_DIRECTORY_COMPANIES = 'project-directory-companies',
    EDIT_CHANGE_ORDER = 'edit-change-order',
    PROJECT_WIZARD_CLIENT = 'project-wizard-client',
    PROJECT_WIZARD_MANAGER = 'project-wizard-manager',
    PROJECT_WIZARD_EXECUTIVE = 'project-wizard-executive',
    PROJECT_WIZARD_INTERNAL_TEAM_MEMBERS = 'project-wizard-internal-team-members',
    ADD_CONTACT_MODAL = 'add-contact-modal',
    ADD_BID_MANAGER = 'add-bid-manager',
    ADD_BIDDER = 'add-bidder',
    ADD_BIDDER_MANUAL = 'add-bidder-manual',
    ADD_PORTFOLIO_EDITOR = 'add-portfolio-editor',
    ADD_PORTFOLIO_VIEWER = 'add-portfolio-viewer',
    ADD_BUILDING_EDITOR = 'add-building-editor',
    ADD_BUILDING_VIEWER = 'add-building-viewer',
    ADD_SUBSITE_EDITOR = 'add-subsite-editor',
    ADD_SUBSITE_VIEWER = 'add-subsite-viewer',
    MEETING_MINUTES_ADDITIONAL_AUTHORS = 'meeting-minutes-additional-authors',
    MEETING_MINUTES_AUTHORS = 'authors',
    MEETING_MINUTES_MEMBERS_ADD_CONTACT = 'meeting-minutes-members-add-contact',
    CONTRACT_WIZARD_PROJECT_MANAGERS = 'contract-wizard-project-managers',
    CONTRACT_WIZARD_CLIENT = 'contract-wizard-client',
    CONTRACT_WIZARD_VENDOR = 'contract-wizard-vendor',
    CONTRACT = 'contract',
    CONTRACT_RECEIVER = 'contract-receiver',
    CONTRACT_MANAGER = 'contract-manager',
    EDIT_RFP_PROPOSAL_TEAM = 'edit-rfp-proposal-team',
    PROJECT_SUMMARY_LEADER = 'project-summary-leader',
    PROJECT_SUMMARY_EXECUTIVE = 'project-summary-executive',
    PROJECT_SUMMARY_EMPLOYEES = 'project-summary-employees',
    PROJECT_SUMMARY_PRINCIPAL = 'project-summary-principal',
    PROJECT_SUMMARY_SUPERVISOR = 'project-summary-supervisor',
    PROJECT_SUMMARY_MANAGER = 'project-summary-manager',
    PROJECT_SUMMARY_INTERNAL_MANAGER = 'project-summary-internal-manager',
    BIDDER_LIST_INVITE_CONTACT = 'bidder-list-invite-contact',
    PROJECT_SCHEDULE_ACTIVITY_RESPONSIBLE_PARTY = 'activity_responsible_party',
    PROJECT_SCHEDULE_SHARING_ACCESS = 'project_schedule_sharing_access',
    PROJECT_FUNDING_SOURCES_PROVIDER = 'project_funding_sources_provider',
    PROJECT_FUNDING_SOURCES_CAPITAL_FUNDS = 'project_funding_sources_capital_funds',
    PROJECT_FUNDING_SOURCES_PROJECT_SPEC_FUNDS = 'project_funding_sources_project_spec_funds',
    REQUEST_PACKAGE_ITEM = 'request-package-item',
    BUILDING_OWNER = 'building-owner',
    SCHEDULE_RESPONSIBLE_PARTY = 'schedule-responsible-party',
    BUILDING_OCCUPANT = 'building-occupant',
    BUILDING_SUBSITE_OCCUPANT = 'building-subsite-occupant',
    CREATE_PORTFOLIO = 'create-portfolio',
    INSPECTIONS_MEMBERS = 'inspections-members',
    INSPECTIONS_INSPECTOR = 'inspections-inspector',
    ASSIGN_PROJECT_MEMBERS = 'assign-project-members',
    REMOVE_PROJECT_MEMBERS = 'remove-project-members',
    REPLACE_PROJECT_MEMBERS = 'replace-project-members',
}
