import { toString, isArray, trim, isObject, clone, debounce, get, head, isUndefined, isFunction } from 'lodash'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import invitationClient from '@/api/invitation/invitationClient'
import itemBox from './parts/itemBox.vue'
import companyBox from './parts/companyBox.vue'
import mixinLists from './lists'
import validationMixin from '@/mixins/validationMixin'
import { required, email } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import cachedListMixin from '@/mixins/cachedListMixin.js'

export default {
    name: 'searchForPerson',
    props: {
        itemId: {
            required: false,
            type: String,
            default: null
        },
        invited_people: {
            required: false,
            type: Array,
            default: null
        },
        invited_pending: {
            required: false,
            type: Array,
            default: null
        },
        attachedParsed: {
            required: false,
            type: Array,
            default: () => []
        },

        /**
         * Hide creator info
         */
        hideCreator: {
            type: Boolean,
            require: false,
            default: false
        },

        /**
         * Hide creator record by email
         */
        hideEntriesWithEmail: {
            type: String,
            require: false,
            default: ''
        },


        /**
         * Special prop to set all options
         */
        inviteModalOptions: {
            type: Object,
            default: null
        },

        /**
         * @param oneOnly - if `true` you can invite or attach only one person
         */
        oneOnly: {
            type: Boolean,
            required: false,
            default: false
        },

        /**
         * @param restrictEmployeeTypes - array of employees groups to show
         */
        restrictEmployeeTypes: {
            type: Array,
            required: false,
            default: () => []
        },
        /**
         * @param showEmployees - if true, show internal employees
         */
        showEmployees: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * @param showContacts - if true, show contacts
         */
        showContacts: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * @param showPendings - if true, show pendings list
         */
        showPendings: {
            required: false,
            type: Boolean,
            default: true
        },
        /**
         * @param isSend - if `false` you don't send anything, but pendings have update
         */
        isSend: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * @param isSendAttach - if `false` you don't send attach request (client.attachEmployee) and detach request
         * emit action
         */
        isSendAttach: {
            type: Boolean,
            required: false,
            default: true
        },
        restrictedAppTypes: {
            type: Array,
            required: false,
            default: () => []
        },
        forcedAppType: {
            type: String,
            required: false,
            default: null
        },
        section: {
            type: String,
            required: false,
            default: 'default'
        },
        apiClient: {
            type: Object,
            default: null
        },
        creatorMongoId: {
            required: true,
            type: String
        },
        invitedMongoIds: {
            required: false,
            type: Array,
            default: () => []
        },
        notDeletedEmployeesIds: {
            type: Array,
            required: false
        },
        getAttached: {
            type: Boolean,
            required: false,
            default: true
        },
        getFromCustomEndpoint: {
            type: Boolean,
            required: false,
            default: false
        },
        customEndpoint: {
            type: String,
            required: false
        },
        customEndpointMerge: {
            type: Boolean,
            required: false,
            default: false
        },
        customProjectContactLabel: {
            type: String,
            required: false,
            default: null
        },
        customContactsLabel: {
            type: String,
            required: false,
            default: 'Contacts'
        },
        customAction: {
            type: Object,
            required: false,
            default: null
        },
        type: {
            required: false,
            type: String,
            default: ''
        },
        allowToInviteSelf: {
            type: Boolean,
            required: false,
            default: false
        },
        hideSaveButton: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    components: {
        itemBox,
        companyBox,
        DynamicScroller,
        DynamicScrollerItem
    },
    mixins: [validationMixin, mixinLists, cachedListMixin],
    data () {
        return {
            /**
             * Cloned data
             */
            cloned_invited_pending: [],
            cloned_invited_people: [],
            cloned_invited_mongo_ids: [],

            searchEmployee: '',
            searchEmployeeCleaned: '',
            searchDomain: '',
            allowedClientsTypes: null,

            /**
             * Display contacts only from company X
             */
            restrictedCompanyId: null,
            restrictedCompanyMongo: null,

            /**
             * Disabling workspaces search
             */
            disableWorkspacesSearch: false,

            /**
             * Mysql id for company to invited
             */
            companyMysqlId: null,

            invited: [],
            added: [],
            addedCustom: [],
            employees: [],
            contacts: [],
            addedFirst: 0,
            addedCustomFirst: 0,
            invitedFirst: 0,
            employeesFirst: 0,
            contactsFirst: 0,
            pendingsFirst: 0,
            customListFirst: 0,
            firsts: {
                foundEmail: 0,
                workspaces: 0
            },
            listWorkspaces: {
                email: [],
                trusted: []
            },
            addedLast: 0,
            addedCustomLast: 0,
            invitedLast: 0,
            employeesLast: 0,
            contactsLast: 0,
            pendingsLast: 0,
            customListLast: 0,

            isEmail: false,
            modalScreen: 1,
            loading: true,
            ajaxPending: 0,

            /**
             * Selected user instance
             */
            selectedUser: null,

            /**
             * User selected to remove
             */
            userToRemove: null,
            disableInvitation: false,
            allowedEmployees: null,
            customListSource: null,
            customList: null,
            showCustomList: false,
            useEmployeesMongoIds: false,
            forceGetInvited: false,
            isOnHold: false
        }
    },

    mounted () {
        this.init()
        this.initProps()
        this.resetSelectedUser(true)
    },

    watch: {
        searchEmployee (newValue) {
            this.performSearch(newValue)
        }
    },

    computed: {
        ...mapGetters('appStore', ['getAuthData']),

        disableSaveBtn () {
            const shortlisted = this.filteredList.filter(item => {
                return (item.key === this.addedFirst || (this.addedFirst === 0 && item.key === this.addedCustomFirst)) || (item.key === this.invitedFirst) || (item.key === this.pendingsFirst)
            })
            return !shortlisted.length
        },

        userMongoId () {
            return this.getAuthData && this.getAuthData.u_mongo ? toString(this.getAuthData.u_mongo) : null
        },

        canRemoveInvited () {
            return this.userMongoId === this.creatorMongoId
        },

        /**
         * Check if email is in trusted domains list
         * @return {boolean}
         */
        invalidTrustedDomain () {
            if (!this.showEmployees) {
                return false
            }
            const trusted = this.getAuthData.trusted_domains
            if (!isArray(trusted) || !trusted.length) {
                return false
            }
            const subdomain = this.searchEmployeeCleaned.replace(/^[^@]+@/, '')
            return !trusted.includes(subdomain)
        },

        /**
         * Data prepared for emit
         *
         * @return {{added: *, pending: [], attached: *}}
         */
        invitationData () {
            let attached = []
            this.added.forEach((item) => {
                attached.push({
                    _id: parseInt(item.id),
                    type: item.typeList
                })
            })

            return {
                invited_pending: this.cloned_invited_pending,
                added: this.added,
                addedCustom: this.addedCustom,
                invited: this.invited,
                attached_employees_ids: attached
            }
        },

        /**
         * Check if we can use API actions
         *
         * @return {default.props.isSendAttach|{default, type, required}|*}
         */
        canUseApiActions () {
            return this.itemId && this.itemId !== '0' && this.isSendAttach && this.apiClient !== null
        },

        /**
         * Check if we can send new invitation using global API
         *
         * @return {default.props.isSendAttach|{default, type, required}|*}
         */
        canSendInvitation () {
            return this.itemId && this.itemId !== '0' && this.isSend
        },
        isSelfMail () {
            let isSelfEmail = this.searchEmployeeCleaned === this.prepareKeyword(this.getAuthData.u_email)
            if (isSelfEmail && this.allowToInviteSelf) {
                this.openWorkspacesSearch()
                return
            }
            return isSelfEmail
        },

        showSaveButton () {
            return this.filteredList
                && this.filteredList.length
                && this.modalScreen === 1
                && !this.hideSaveButton
        }
    },

    methods: {
        /**
         * Emit all changes
         */
        async emitChanges () {
            if (!this.ajaxPending) {
                this.buildList()
                this.$emit('invitationsUpdate', this.invitationData)
            }
        },

        /**
         * Check if email is valid
         *
         * @param newValue
         */
        validateEmail (newValue) {
            this.searchEmployeeCleaned = trim(newValue.toLowerCase())
            this.v$.$touch()
            this.isEmail = !this.v$.searchEmployeeCleaned.$anyError
        },

        /**
         * Display screen for selecting app for existing user (workspace)
         * Also reset some data - it's new user
         * FIRST: check for duplicates
         */
        async addNewWorkspace () {
            this.loading = true
            let { data } = await invitationClient.checkExistingInvitation(this.selectedUser.email, this.restrictedAppTypes)
            this.loading = false
            this.selectedUser.isExternal = true

            if (data && data.result) {
                this.selectedUser.app_type = data.appType
                this.selectedUser.company_app = data.appType
                this.selectedUser.company_mongo = data.mongoId || null
                this.sendInvite()
                return true
            }

            this.selectedUser.id = null
            this.selectedUser.mongo = null
            this.selectedUser.name = this.selectedUser.email
            this.selectedUser.contact_id = null
            this.selectedUser.app_type = null
            this.selectedUser.company_app = null
            this.selectedUser.company_id = null
            this.selectedUser.company_name = null
            this.selectedUser.company_mongo = null
            this.selectedUser.employee_mongo = null
            this.selectedUser.isNew = true

            if (!this.forcedAppType) {
                this.modalScreen = 3
            } else {
                this.selectedUser.app_type = this.forcedAppType
                this.selectedUser.company_app = this.forcedAppType
                this.sendInvite()
                return true
            }
        },

        resetSelectedUser (withMail = false) {
            if (!isObject(this.selectedUser)) {
                this.selectedUser = {}
                this.selectedUser.email = null
            }

            this.selectedUser.id = null
            this.selectedUser.name = null
            this.selectedUser.company_app_skip = false
            this.selectedUser.app_type = null
            this.selectedUser.company_app = null
            this.selectedUser.company_id = null
            this.selectedUser.company_name = null
            this.selectedUser.company_mongo = null
            this.selectedUser.company_mysql_id = null
            this.selectedUser.employee_mongo = null
            this.selectedUser.contact_id = null
            this.selectedUser.mongo = null
            this.selectedUser.isExternal = null
            this.selectedUser.isNew = null
            if (withMail) {
                this.selectedUser.email = null
            }
        },

        initProps () {
            const searchEmployeeRef = this.$refs.searchEmployeeRef
            this.modalScreen = 1
            this.searchEmployee = ''
            this.resetSelectedUser(true)
            setTimeout(() => {
                if (searchEmployeeRef) {
                    searchEmployeeRef.focus()
                }
            }, 250)
        },

        init () {
            if (this.inviteModalOptions) {
                for (let option in this.inviteModalOptions) {
                    this[option] = this.inviteModalOptions[option]
                }
            }
            this.prepareRestrictedCompanyData()
            this.getData()
        },

        /**
         * Preformat data about restricted company to speed up parsing
         */
        prepareRestrictedCompanyData () {
            if (this.restrictedCompanyId !== null && this.restrictedCompanyId !== undefined) {
                this.restrictedCompanyId = parseInt(this.restrictedCompanyId)
            } else {
                // Required
                this.restrictedCompanyId = null
            }

            if (this.restrictedCompanyMongo !== null && this.restrictedCompanyMongo !== undefined) {
                this.restrictedCompanyMongo = toString(this.restrictedCompanyMongo)
            } else {
                this.restrictedCompanyMongo = null
            }
        },

        async getData () {
            this.loading = true
            try {
                this.invited = []
                this.added = []
                await this.getInvited()
                await this.getPendings()
                await this.getContacts()
                await this.getEmployees()
                this.prepareCustomList()
                this.buildList()
                this.loading = false
            } catch (e) {
                console.error(e)
                this.loading = false
            }
        },

        /**
         * Update added object and list
         *
         * @param item
         * @param action
         */
        async updateAdded (item, action) {
            if (action === 'attach') {
                item.isAdded = true
                if (item.typeList === 'customList') {
                    this.addedCustom.push(item)
                    this.setFirstAddedCustom(this.addedCustom)
                } else {
                    this.added.push(item)
                    this.setFirstAdded(this.added)
                }

                if (item.typeList === 'employee') {
                    this.employees = this.employees.filter((listItem) => {
                        return listItem.email !== item.email
                    })
                    this.setFirstEmployee(this.employees)
                }

                if (item.typeList === 'contact') {
                    this.contacts = this.contacts.filter((listItem) => {
                        return listItem.email !== item.email
                    })
                    this.setFirstContact(this.contacts)
                }

                if (item.typeList === 'pending') {
                    this.cloned_invited_pending = this.cloned_invited_pending.filter((listItem) => {
                        return listItem.email !== item.email
                    })
                    this.setFirstPending(this.cloned_invited_pending)
                }

                if (item.typeList === 'customList') {
                    this.customList = this.customList.filter((listItem) => {
                        return listItem.email !== item.email
                    })
                    this.setFirstCustom(this.customList)
                }
            } else {
                item.isAdded = false
                if (item.typeList === 'customList') {
                    this.addedCustom = this.addedCustom.filter((listItem) => {
                        return listItem.email !== item.email
                    })
                    this.setFirstAddedCustom(this.addedCustom)
                } else {
                    this.added = this.added.filter((listItem) => {
                        return listItem.email !== item.email
                    })
                    this.setFirstAdded(this.added)
                }

                if (item.typeList === 'employee') {
                    this.employees.unshift(item)
                    this.setFirstEmployee(this.employees)
                }
                if (item.typeList === 'customList') {
                    this.customList.unshift(item)
                    this.setFirstCustom(this.customList)
                }
                if (item.typeList === 'contact') {
                    this.contacts.unshift(item)
                    this.setFirstContact(this.contacts)
                }
                if (item.typeList === 'pending') {
                    this.userToRemove = clone(item)
                    await this.invitationRemove()
                    this.setFirstPending(this.cloned_invited_pending)
                }
            }
            this.$emit('updateAdded', item.id, action)
        },

        isLastRow (item) {
            return (
                item.email === this.addedLast ||
                item.email === this.addedCustomLast ||
                item.email === this.invitedLast ||
                item.email === this.employeesLast ||
                item.email === this.contactsLast ||
                item.email === this.pendingsLast ||
                item.email === this.customListLast
            )
        },

        /**
         * Attach user to connected - if client/external, go to our flow
         */
        attachPerson (item) {
            if (!item || item.isAdded || item.loading) {
                return false
            }

            if (this.isAttached(item.email)) {
                return false
            }

            // If contact, fill search field, set data and go to next step
            if ((item.typeList === 'contact') || item.typeList === 'company') {
                if (item.typeList === 'contact' && !item.company_mongo && !isUndefined(item.email)) {
                    this.searchEmployee = item.email
                }
                this.validateEmail(this.searchEmployee)

                this.selectedUser.id = item.id
                this.selectedUser.name = item.name
                this.selectedUser.email = item.email
                this.selectedUser.contact_id = item.id
                this.selectedUser.company_id = item.company_id
                this.selectedUser.company_name = item.company_name
                this.selectedUser.company_mongo = item.company_mongo
                this.selectedUser.employee_mongo = item.employee_mongo || null

                this.selectExternal(item)
            } else {
                if (
                    this.canUseApiActions &&
                    this.apiClient &&
                    this.apiClient.attachEmployee &&
                    isFunction(this.apiClient.attachEmployee)
                ) {
                    this.item['loading'] = true
                    let data = {
                        section: this.section
                    }

                    this.ajaxPending++
                    this.apiClient
                        .attachEmployee(this.itemId, item.id, data)
                        .then(() => {
                            this.ajaxPending--
                            this.item['loading'] = false
                            this.afterAttachPerson(item)
                        })
                        .catch((error) => {
                            console.error(error)
                            this.ajaxPending--
                        })
                } else {
                    this.afterAttachPerson(item)
                }
            }
        },

        /**
         * Helper function for attachPerson to avoid code duplication
         */
        afterAttachPerson (item) {
            this.updateAdded(item, 'attach')
            if (this.oneOnly) {
                this.goBack()
            }
        },

        /**
         * Ask users to remove invited
         */
        askRemovePending (item) {
            this.userToRemove = clone(item)
            this.modalScreen = 2
        },

        /**
         * Remove existing invitation
         */
        async invitationRemove () {
            if (!this.canUseApiActions) {
                this.cloned_invited_pending = this.cloned_invited_pending.filter((item) => {
                    return item.email !== this.userToRemove.email
                })
                this.emitChanges()
            } else {
                this.loading = true
                await invitationClient.invitationRemove(this.userToRemove)
                await this.getPendings(true)

                // Reload list to make this contact available again
                await this.getContacts()
                await this.getEmployees()
                this.emitChanges()
            }
            this.loading = false
            this.modalScreen = 1
        },

        /**
         * Check if item is on connected list
         *
         * @param item
         * @return {T}
         */
        onInvitedList (item) {
            return this.cloned_invited_mongo_ids.includes(item.mongoId)
        },

        /**
         * Remove person from added or pending
         *
         * @param item
         * @param forceRemove
         * @return {boolean}
         */
        async detachPerson (item, forceRemove = false) {
            if (!item.typeList) {
                item = this.searchForItem(item)
            }
            if (!item) {
                return false
            }

            if (item.typeList === 'invited') {
                return false
            }

            if (this.useEmployeesMongoIds && item.typeList === 'employee' && !item.isFromMyCompany) {
                this.$store.dispatch('notification/setShow', {
                    type: 'warning',
                    text: 'You can only detach employees from your company!'
                })
                return false
            }

            if (this.notDeletedEmployeesIds && this.notDeletedEmployeesIds.includes(item.id)) {
                this.$store.dispatch('notification/setShow', {
                    type: 'warning',
                    text: 'You cannot detach this person!'
                })
                return false
            }

            if (this.isCreator(item)) {
                this.$store.dispatch('notification/setShow', {
                    type: 'warning',
                    text: 'You cannot detach creator!'
                })
                return false
            }
            if (this.onInvitedList(item) && !this.canRemoveInvited) {
                this.$store.dispatch('notification/setShow', {
                    type: 'warning',
                    text: 'Only creator can remove connected users!'
                })
                return false
            }
            if (item.typeList !== 'employee' && item.typeList !== 'customList' && item.isPending && !forceRemove) {
                this.askRemovePending(item)
                return false
            }

            if (!item || item.loading) {
                return false
            }

            if (!this.isAttached(item.email)) {
                return false
            }

            if (this.canUseApiActions && this.apiClient.detachEmployee && isFunction(this.apiClient.detachEmployee)) {
                this.item['loading'] = true
                let data = {
                    section: this.section
                }

                this.ajaxPending++
                this.apiClient
                    .detachEmployee(this.itemId, item.id, data)
                    .then(() => {
                        this.ajaxPending--
                        this.updateAdded(item, 'detach')
                        this.item['loading'] = false
                    })
                    .catch((error) => {
                        console.error(error)
                        this.ajaxPending--
                    })
            } else {
                this.updateAdded(item, 'detach')
            }
        },

        /**
         * Check if email already exists in attached list
         */
        isAttached (email) {
            return this.findItemByEmail(email) !== false
        },

        /**
         * Search item by email
         *
         * @param email
         * @return {*|boolean}
         */
        findItemByEmail (email) {
            let result = false
            result = this.invited.find((attached) => {
                return attached.email === email
            })
            if (result) {
                return result
            }

            result = this.cloned_invited_pending.find((attached) => {
                return attached.email === email
            })
            if (result) {
                return result
            }

            result = this.added.find((attached) => {
                return attached.email === email
            })
            if (result) {
                return result
            }

            result = this.addedCustom.find((attached) => {
                return attached.email === email
            })
            if (result) {
                return result
            }

            return false
        },

        /**
         * Get item for all collections
         *
         * @param item
         * @return {boolean|*}
         */
        searchForItem (item) {
            if (item.e_email) {
                return this.findItemByEmail(item.e_email)
            } else if (item.contact_email) {
                return this.findItemByEmail(item.contact_email)
            } else if (item.email) {
                return this.findItemByEmail(item.email)
            }
            return false
        },

        /**
         * Search for alternative workspaces
         * Only if no results - and valid email if in email search
         */
        runWorkspaceSearchByEmail: debounce(function () {
            this.getWorkspacesByEmail()
        }, 500),

        selectInternal () {
            if (!this.loading) {
                this.selectedUser.app_type = localStorage.getItem('appType')
                this.selectedUser.isExternal = null
                this.selectedUser.isNew = null
            }
        },

        /**
         * Attach an external user and send invitation
         * For case in new users and selecting using existing
         */
        async attachExternal () {
            await this.sendInvite()
            this.resetSelectedUser()

            if (this.oneOnly) {
                this.goBack(true)
            } else if (!this.canSendInvitation) {
                this.initProps()
            }
        },

        /**
         * If we select external, go to special screen with workspaces listed by email
         * Then user can decide - create new one, or use existing
         */
        async selectExternal (item = null) {
            if (item && item.company_mongo) {
                if (!this.selectedUser.name) {
                    this.selectedUser.name = item.fullname
                }
                if (item.workspace) {
                    item.email = this.searchEmployeeCleaned
                    this.selectedUser.name = item.email
                }
                this.selectedUser.app_type = item.app_type
                this.selectedUser.company_app = item.app_type
                this.selectedUser.email = item.email
                this.selectedUser.mongo = item.employee
                this.selectedUser.company_name = item.fullname
                this.selectedUser.company_mongo = item.company_mongo
                this.selectedUser.employee_mongo = item.employee_mongo || null
                this.selectedUser.isExternal = true
                if (item.typeList === 'contact') {
                    const contact = this.contacts.find((contactItem) => contactItem.email === item.email)
                    if (contact) {
                        this.selectedUser.contactId = contact.id
                    }
                }
                this.attachExternal()
            } else if (this.forcedAppType || (item && item.app_type)) {
                this.selectedUser.app_type = this.forcedAppType || item.app_type
                this.selectedUser.company_app = this.forcedAppType || item.app_type
                this.selectedUser.isExternal = true
                this.openWorkspacesSearch()
            } else {
                this.openWorkspacesSearch()
            }
        },

        /**
         * Open screen to search by email and run search
         */
        openWorkspacesSearch () {
            if (this.disableWorkspacesSearch) {
                this.selectedUser.isExternal = true
                this.selectedUser.company_app_skip = true
                this.sendInvite()
                return false
            }

            this.modalScreen = 5
            this.loading = true

            this.listWorkspaces.email = []
            this.listWorkspaces.trusted = []

            this.searchDomain = this.selectedUser.email.split('@')[1]
            this.runWorkspaceSearchByEmail()
        },

        /**
         * Send an invite - real send only if we use isSend option
         */
        async sendInvite () {
            if (this.isSelfMail) {
                this.showNotification('error', 'It is not possible to send an invitation to yourself')
                return false
            }

            let data = {
                email: this.selectedUser.email,
                app_type: this.selectedUser.isExternal ? this.selectedUser.company_app : localStorage.getItem('appType'),
                company_mongo: this.selectedUser.isExternal ? this.selectedUser.company_mongo : null,
                company_mysql_id: this.companyMysqlId || null,
                employee_mongo: this.selectedUser.employee_mongo || null,
                type: this.$route.meta.inviteType,
                element_id: this.itemId,
                app_type_skip: this.selectedUser.company_app_skip || false,
                internal: this.selectedUser.isExternal ? 0 : 1,
                section: this.section,
                is_on_hold: this.isOnHold
            }

            if (null === data.app_type) {
                this.showNotification('error', 'Please select workspace type.')
                return false
            }

            if (this.canSendInvitation) {
                if (this.itemId) {
                    this.loading = true
                    let response = await invitationClient.createInvitation(data)
                    if (response.data) {
                        this.removeContactFromList()
                    }
                }

                await this.$store.dispatch('cached/refreshList', 'employees')
                await this.$store.dispatch('cached/refreshList', 'contacts')
                await this.getPendings(true)
                await this.getInvited(true)
                await this.getEmployees()
                await this.getContacts()
                this.modalScreen = 4
                this.loading = false
                this.emitChanges()
            } else {
                data.isPending = true
                data.isAdded = true
                data.isExternal = this.selectedUser.isExternal
                data.fullname = this.selectedUser.email
                data.photo = 'pending'
                data.typeList = 'pending'

                let result = {}
                result = Object.assign(result, data, this.selectedUser)
                this.cloned_invited_pending.push(result)
                this.emitChanges()

                if (this.oneOnly) {
                    if (!this.showContacts && !this.showEmployees) {
                        this.modalScreen = 4
                        this.loading = false
                    } else {
                        this.closeModal()
                    }
                } else {
                    this.initProps()
                }
            }
        },

        /**
         * Reset some values, go to first screen
         */
        sendNext () {
            this.initProps()
        },

        setAppType (type) {
            this.selectedUser['company_app'] = type
            this.selectedUser['app_type'] = type
            this.$forceUpdate()
        },

        removeContactFromList () {
            if (this.selectedUser.email) {
                const contactIndex = this.contacts.findIndex((item) => item.email === this.selectedUser.email)
                if (contactIndex !== undefined && contactIndex > -1) {
                    this.contacts.splice(contactIndex, 1)
                }
            }
        },

        isCreator (item) {
            return item && item.mongoId && this.creatorMongoId && item.mongoId === this.creatorMongoId
        },

        closeModal () {
            this.emitChanges()
            if (
                this.$parent &&
                !isUndefined(this.$parent['hideInviteModal']) &&
                isFunction(this.$parent['hideInviteModal'])
            ) {
                this.$parent.hideInviteModal()
            } else {
                this.searchEmployee = ''
                this.$emit('close')
            }
        },

        /**
         * Bo back to component, exit modal or just back to list
         *
         * @param forceExit

         */
        goBack (forceExit = false) {
            if (forceExit) {
                this.closeModal()
                return
            }

            // From after send
            if (this.modalScreen === 4) {
                if (this.oneOnly) {
                    this.closeModal()
                } else {
                    this.modalScreen = 1
                }
                return
            }

            // From searching workspace
            if (this.modalScreen === 5) {
                if (this.selectedUser.isNew) {
                    this.modalScreen = 3
                } else {
                    this.initProps()
                }
                return
            }

            // From new company
            if (this.modalScreen === 3) {
                this.modalScreen = 5
                this.selectedUser.isNew = null
                return
            }

            // From removing user
            if (this.modalScreen === 2) {
                this.modalScreen = 1
                return
            }

            if (this.modalScreen === 1) {
                this.closeModal()
            }

            // From other
            this.initProps()
        },

        checkIsCustomFirst (item) {
            return get(head(this.filteredList.filter(item => item.custom1)), 'key', false) === item.key
        },
        performSearch: debounce(function (newValue) {
            if (this.modalScreen === 5) {
                return false
            }

            this.validateEmail(newValue)
            if (!this.isEmail) {
                this.modalScreen = 1
            } else {
                this.selectedUser.email = this.searchEmployeeCleaned
            }
        }, 250)
    },

    validations: {
        searchEmployeeCleaned: {
            required,
            email
        }
    }
}
