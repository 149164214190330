import { defineComponent } from 'vue'
import { Contact, InvitationStatuses } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'

export default defineComponent({
    name: 'InvitePreviewHorizontalList',

    props: {
        selectedContactsList: {
            type: Array as () => Array<Contact>,
            required: true,
            default: () => []
        },
        avatarRingTooltip: { type: String, required: false, default: null },
        avatarRingChecker: { type: [Object, Function], required: false, default: null },
        contactsBallInCourt: { type: Array as Array<string>, required: false, default: () => [] },
        isAdditionalContactsVisible: { type: Boolean, required: false, default: true },
        displayLimit: { type: Number, required: false, default: 3 },
        isInviteAllowed: { type: Boolean, required: false, default: true },
    },

    emits: [
        'showInviteModal',
    ],

    data () {
        return {
            invitationStatuses: InvitationStatuses
        }
    },

    computed: {
        isAvatarRingTooltipVisible (): boolean {
            return !!this.avatarRingTooltip?.length
        },

        limitedContactsList (): Contact[] {
            return this.selectedContactsList.length && this.selectedContactsList.slice(0, this.displayLimit) || []
        },

        remainingContacts (): number {
            return this.selectedContactsList.length - this.limitedContactsList.length
        },

        isAdditionalContactsItemsVisible (): boolean {
            return this.isAdditionalContactsVisible && !!this.remainingContacts
        },
    },

    methods: {
        showInviteModal (): void {
            this.$emit('showInviteModal', true)
        },

        isMarkedWithRing (contact: Contact): boolean {
            if (typeof this.avatarRingChecker === 'function') {
                return this.avatarRingChecker(contact.id)
            }

            return false
        },

        isContactInvited (contact: Contact): boolean {
            return contact.invitation?.status === this.invitationStatuses.INVITED && !contact.id
        },

        isBallInCourt (contact: Contact): boolean {
            return this.contactsBallInCourt.includes(contact.id)
        },

        isContactArchived (contact: Contact): boolean {
            return contact.is_archived !== undefined ? contact.is_archived : false
        },

        isArchivedOrPendingOrOffSystem (contact: Contact): boolean {
            let isArchived = this.isContactArchived(contact)

            return !contact.is_employee || (contact.is_employee && isArchived) || (contact.is_employee && contact.is_pending)
        },

        isArchivedEmployee (contact: Contact): boolean {
            return contact.is_employee && this.isContactArchived(contact)
        },
    }
})
