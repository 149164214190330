import { app } from '@/app'
import { trim, isObject, isNumber, isString } from 'lodash'

app.config.globalProperties.showFullName = (item) => {
    return (
        item &&
        (
            (item.e_firstname && item.e_lastname) ||
            (item.contact_firstname && item.contact_lastname) ||
            (item.firstname && item.lastname)
        )
    )
}

app.config.globalProperties.fullName = (item) => {
    if (app.prototype.showFullName(item)) {
        return trim(
            (item.e_firstname || item.contact_firstname || item.firstname) +
            ' ' +
            (item.e_lastname || item.contact_lastname || item.lastname)
        )
    } else {
        return 'No Name'
    }
}

app.config.globalProperties.personColor = (item) => {
    if (item && item.hasOwnProperty('_color') && item._color) {
        return item._color
    }
    return false
}

app.config.globalProperties.checkAttachedPerson = (item) => {
    return (
        item &&
        isObject(item) &&
        item.hasOwnProperty('_id') &&
        item.hasOwnProperty('type') &&
        (isNumber(item._id) || isString(item._id))
    )
}


