const ProjectProvider = () => import(/* webpackChunkName: "project-provider" */ '@/layouts/project-provider/ProjectProvider.vue')
const DailyReports = () => import(/* webpackChunkName: "daily-reports" */ '@/io-modules/daily-reports/components/daily-reports/DailyReports.vue')
const DailyReportsIndex = () => import(/* webpackChunkName: "daily-reports-index" */ '@/io-modules/daily-reports/components/daily-reports-index/DailyReportsIndex.vue')
const DailyReportsCreate = () => import(/* webpackChunkName: "daily-reports-create" */ '@/io-modules/daily-reports/components/daily-reports-create/DailyReportsCreate.vue')
const DailyReport = () => import(/* webpackChunkName: "daily-report" */ '@/io-modules/daily-reports/components/daily-report/DailyReport.vue')
const DailyReportsShow = () => import(/* webpackChunkName: "daily-reports-show" */ '@/io-modules/daily-reports/components/daily-reports-show/DailyReportsShow.vue')

export default [
    {
        path: '/project/:pid/daily-reports',
        component: ProjectProvider,
        redirect: { name: 'daily-reports-index' },
        children: [
            {
                path: '',
                name: 'daily-reports',
                meta: {
                    title: 'Daily Reports',
                    navigationKey: 'daily_reports',
                },
                redirect: { name: 'daily-reports-index' },
                component: DailyReports,
                children: [
                    {
                        path: '',
                        name: 'daily-reports-index',
                        meta: {
                            title: 'Daily Reports List',
                            navigationKey: 'daily_reports',
                        },
                        component: DailyReportsIndex,
                    },
                ],
            },
            {
                path: 'create',
                name: 'daily-reports-create',
                meta: {
                    title: 'Create Daily Report',
                    showNotificationsForRoutes: ['daily-reports-index'],
                    navigationKey: 'daily_reports',
                },
                component: DailyReportsCreate,
            },
            {
                path: ':id',
                name: 'daily-reports-show',
                props: true,
                meta: {
                    navigationKey: 'daily_reports',
                },
                component: DailyReport,
                redirect: { name: 'daily-report-details-show' },
                children: [
                    {
                        path: 'details',
                        name: 'daily-report-details-show',
                        meta: {
                            title: 'Daily Report',
                            navigationKey: 'daily_reports',
                        },
                        component: DailyReportsShow,
                    },
                ],
            },
        ],
    },
]
