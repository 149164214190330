<template>
    <div
        data-testid="io-atom-tooltip"
        class="io-design-system io-atom-tooltip"
        :class="additionalClassName"
        v-tooltip.top="{ content: text, visible, html: htmlId, class: $slots.default ? 'html': '' }"
    >
        <div v-if="htmlContent || $slots.default" :id="tooltipHtmlId">
            <slot v-if="$slots.default" />
            <div v-if="htmlContent" v-html="htmlContent"/>
        </div>
    </div>
</template>

<script lang="ts" src="./TooltipIcon.ts"></script>
<style lang="scss" src="./TooltipIcon.scss"/>
