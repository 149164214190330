import { defineComponent, PropType } from 'vue'
import commonContractMixin from '@/io-modules/project-contracts/mixins/commonContractMixin.ts'
import { Contact } from '@/components/invite-modal-v3/interfaces/InviteModalInterface'
import { ButtonSize } from '@/components/atoms/IOButton/IOButton.ts'

export default defineComponent({
    name: 'InvitePreviewContactListFull',

    props: {
        selectedContactsList: {
            type: Array as () => Array<Contact>,
            required: true,
            default: () => [],
        },
        showStatusForSingleContact: { type: Boolean, default: true },
        showAppTypeForSingleContact: { type: Boolean, default: false },
        isInviteAllowed: { type: Boolean, required: false, default: true },
        buttonLabelText: { type: String, required: false, default: '' },
        buttonIcon: { type: String, default: 'icon-plus' },
        buttonSize: { type: String as () => ButtonSize, default: ButtonSize.NORMAL },
        selectMultiple: { type: Boolean, required: false, default: true },
    },

    mixins: [commonContractMixin],

    emits: [
        'showInviteModal',
        'removeSelectedContact',
    ],

    computed: {
        isButtonDisplayed (): boolean {
            if (!this.selectMultiple) {
                return this.isInviteAllowed && this.selectedContactsList.length === 0
            }

            return this.isInviteAllowed
        },
        isLargeIcon (): boolean {
            return !this.selectMultiple
        },
    },

    methods: {
        showInviteModal (): void {
            this.$emit('showInviteModal', true)
        },

        removeSelectedContact (contact: Contact): void {
            this.$emit('removeSelectedContact', contact)
        },

        isContactArchived (contact: Contact): boolean {
            return contact.is_archived !== undefined ? contact.is_archived : false
        },

        isArchivedOrPendingOrOffSystem (contact: Contact): boolean {
            let isArchived = this.isContactArchived(contact)

            return !contact.is_employee || (contact.is_employee && isArchived) || (contact.is_employee && contact.is_pending)
        },
    },
})
