import { defineComponent } from 'vue'
import SingleContact from '@/components/invite-modal-v3/components/single-contact/SingleContact.vue'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default defineComponent({
    name: 'SelectedContacts',
    components: {
        SingleContact,
        DynamicScroller,
        DynamicScrollerItem,
    },
    props: {
        componentKey: { type: String, required: true },
        contacts: { type: Array, required: false, default: () => [] },
        disableRemoval: { type: Boolean, required: false, default: false },
    }
})
