export const addingSource = function ({ commit, dispatch }, value) {
    commit('ADDING_SOURCE', value || false)
}

export const setProviderId = function ({ commit, dispatch }, value) {
    commit('SET_PROVIDER_ID', value || null)
}

export const setProviderName = function ({ commit, dispatch }, value) {
    commit('SET_PROVIDER_NAME', value || null)
}

export const resetProvider = function ({ commit, dispatch }) {
    commit('RESET_PROVIDER')
}
