<template>
    <div class="io-invite-modal__form-view">
        <div class="io-popup-add-contact__row io-f-row">
            <div class="io-input-holder">
                <label>
                    {{ $t('Email') }}
                </label>
                <input
                    type="text"
                    :class="{'io-disabled': emailPreselected}"
                    :disabled="emailPreselected"
                    :readonly="emailPreselected"
                    v-model="model.email"
                />
            </div>
        </div>

        <div class="io-popup-add-contact__row io-f-row">
            <div class="io-input-holder">
                <label class="io-required">
                    {{ $t('First Name') }}
                </label>
                <input type="text" v-model="model.firstName"/>
            </div>
            <div class="io-input-holder">
                <label class="io-required">
                    {{ $t('Last Name') }}
                </label>
                <input
                    type="text"
                    v-model="model.lastName"
                    @keydown="onLastNameInput"
                />
            </div>
        </div>

        <div class="io-popup-add-contact__row io-f-row">
            <div class="io-input-holder">
                <label class="io-required">
                    {{ $t('Company') }}
                </label>
                <CustomMultiselect
                    v-if="!model.isNewCompany"
                    ref="customMultiSelect"
                    :addNewBtnDisabled="false"
                    :addingNewInProcess="false"
                    :disabled="selectCompanyDisabled"
                    :options="companiesList"
                    :settings="multiselectSettings"
                    :statusEnable="false"
                    :modelValue="modelCompany"
                    :enableStopPropagation="false"
                    @changeOption="onSelectCompany"
                    @search="onSearchInput"
                >
                    <template v-slot:header="{ activeOption, config }">
                        <div v-if="activeOption" class="io-multiselect__name">
                            <div v-if="activeOption.logo"
                                class="io-single-option io-option-avatar"
                                :style="getBackgroundCompanyLogo(activeOption.logo)">
                            </div>
                            <initials
                                v-else
                                :bigAvatar="true"
                                :fullName="activeOption.name"
                                class="io-single-option__logo"
                                :class="{ 'io-offsystem-logo': activeOption.off_system}"
                            />
                            {{ activeOption.name }}
                        </div>

                        <div v-else class="io-multiselect__name io-placeholder">
                            <span class="io-avatars">
                                +
                            </span>
                            {{ config.textPlaceholder }}
                        </div>
                    </template>

                    <template v-slot:option="{ option }">
                        <div v-if="option.logo"
                            class="io-single-option io-option-avatar"
                            :style="getBackgroundCompanyLogo(option.logo)">
                        </div>
                        <initials
                            v-else
                            :bigAvatar="true"
                            :fullName="option.name"
                            class="io-single-option__logo"
                            :class="{ 'io-offsystem-logo': option.off_system}"
                        />
                        <div class="io-single-option__text-wrapper">
                            <div class="io-single-option__title-row">
                                <div class="io-single-option__title">
                                    {{ option.name }}
                                </div>
                            </div>
                            <div class="io-single-option__company-row">
                                <div class="io-single-option__company-row__pill">
                                    <span v-if="option.off_system" class="io-small-pill io-grey">
                                        {{ $t('OFF-SYSTEM') }}
                                    </span>

                                        <span
                                            v-else class="io-small-pill"
                                            :class="getAccentPillColor(option.workspace_type)"
                                        >
                                        {{ option.workspace_type }}
                                    </span>
                                </div>

                                <div class="io-single-option__company-row__address">
                                    {{ getCompanyAddress(option.address) }}
                                </div>
                            </div>
                        </div>
                    </template>

                    <template
                        v-slot:footer
                        v-if="companyDoesNotExist"
                    >
                        <div
                            class="io-single-option io-add-new-company"
                            @click="onCreateCompany"
                        >
                            <i class="icon-plus"></i>
                            {{ $t('Add New Company') }}
                        </div>
                    </template>
                </CustomMultiselect>

                <template v-else>
                    <input type="text" v-model="model.newCompany.company_name"/>
                </template>
            </div>
        </div>

        <template v-if="model.isNewCompany">
            <div class="io-popup-add-contact__row io-f-row">
                <div class="io-input-holder">
                    <label class="io-form-label io-required">
                        {{ $t('Address 1') }}
                    </label>
                    <input
                        class="address1"
                        v-model="model.newCompany.address.address_line1"
                        @input="updateAddress"
                    >
                </div>
            </div>

            <div class="io-popup-add-contact__row io-f-row">
                <div class="io-input-holder">
                    <label class="io-form-label">
                        {{ $t('Address 2') }}
                    </label>
                    <input
                        class="address2"
                        v-model="model.newCompany.address.address_line2"
                        @input="updateAddress"
                    >
                </div>
            </div>

            <div class="io-popup-add-contact__row io-f-row">
                <div class="io-input-holder">
                    <label class="io-form-label io-required">
                        {{ $t('Country') }}
                    </label>

                    <Multiselect
                        track-by="id"
                        label="name"
                        :options="countries"
                        :searchable="true"
                        v-model="model.newCompany.address.country"
                        @select="updateCountry"
                    />
                </div>

                <div class="io-input-holder">
                    <label class="io-form-label">
                        {{ $t('State / Province / Region') }}
                    </label>

                    <Multiselect
                        track-by="id"
                        label="name"
                        :options="states"
                        :disabled="isStateSelectDisabled"
                        :searchable="true"
                        v-model="model.newCompany.address.state"
                        @select="updateAddress"
                    />
                </div>
            </div>

            <div class="io-popup-add-contact__row io-f-row">
                <div class="io-input-holder">
                    <label class="io-form-label io-required">
                        {{ $t('City / Town') }}
                    </label>
                    <input
                        v-model="model.newCompany.address.city"
                        @input="updateAddress"
                    />
                </div>

                <div class="io-input-holder">
                    <label class="io-form-label">
                        {{ $t('Zip Code / Postal Code') }}
                    </label>
                    <input
                        v-model="model.newCompany.address.zip"
                        @input="updateAddress"
                        :class="{ 'io-error': !isZipCodeValid }"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts" src="./AddContactDetails.ts"></script>
<style lang="scss" src="./AddContactDetails.scss" scoped></style>
