import { ApiClient } from '@/api/api'

let client = ApiClient

export default {
    getList (type, data, conf) {
        return client.post(`/cached-list/${ type }`, data, conf)
    },
    getListOne (type, id) {
        return client.post(`/cached-list/${ type }?id=${ id }`)
    }
}
