import { defineComponent } from 'vue'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'

export default defineComponent ({
    name: 'RequestedDataErrorPlaceholder',
    components: {
        LoadingScreen,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
})
