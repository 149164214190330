<template>
    <filterSection :title="title">
        <template #default>
            <Checkbox
                v-if="!hideSelectAllCheckboxes"
                key='all'
                :labelText="$t('All')"
                inputName="all"
                :checkboxValue="true"
                v-model="allSelected"
                @click="handleSelectAll"
            />
            <template v-for="(item, key, index) in preparedOptions" :key="key">
                <Checkbox
                    v-if="index < maxLength || showMore"
                    :labelText="prepareName(item)"
                    :checkboxValue="key"
                    :inputName="id"
                    :classSpan=" dynamicClass ?  'filter-checkbox-' + field + ' ' + key : '' "
                    v-model="checkboxesValue"
                />
            </template>
            <div v-if="Object.keys(preparedOptions).length > maxLength" class="io-filter-section__show-more" :class="{ 'io-active': showMore }" @click="toggleShowMore">
                <i class="fas fa-caret-down"></i> {{ showMore ? $t('Show Less') : $t('Show More') }}
            </div>
        </template>
    </filterSection>
</template>
<script>
    import filterSection from './filterSection.vue'

    export default {
        name: 'FilterCheckboxes',
        emit: [ 'update:modelValue' ],
        components: {
            filterSection,
        },
        props: {
            title: {
                type: String,
                required: true
            },
            modelValue: {
                required: true,
                type: Array
            },
            options: {
                type: Object,
                default: () => ({}),
            },
            translate: {
                required: false,
                default: false
            },
            maxLength: {
                required: false,
                default: 3
            },
            returnNumber: {
                required: false,
                default: false
            },
            field: {
                required: false,
                default: ''
            },
            dynamicClass: {
                required: false,
                default: ''
            },
            hideSelectAllCheckboxes: {
                required: false,
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                showMore: false,
            }
        },
        computed: {
            checkboxesValue: {
                get () {
                    return this.modelValue
                },
                set (val) {
                    this.$emit('update:modelValue', this.returnNumber ? val.map((v) => parseInt(v)) : val)
                }
            },
            preparedOptions () {
                if (Array.isArray(this.options)) {
                    return this.options.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {})
                }
                return this.options
            },
            id () {
                return this.generateUuid()
            },
            allCheckboxesValues () {
                if (Array.isArray(this.options)) {
                    return this.options.reduce((acc, { id }) => [...acc, id], [])
                }
                return this.options
            },
            allSelected () {
                return [this.modelValue?.length === this.options.length]
            }
        },
        methods: {
            prepareName (name) {
                if (this.translate) {
                    return this.$t(name)
                }
                return name
            },
            toggleShowMore () {
                this.showMore = !this.showMore
            },
            handleSelectAll () {
                if (this.allSelected.includes(true)) {
                    this.checkboxesValue = []
                } else {
                    this.checkboxesValue = this.allCheckboxesValues
                }
            }
        }
    }
</script>
