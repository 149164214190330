<template>
    <div v-click-outside="hidePicker" class="io-chrome-color-picker-container">
        <span class="io-chrome-picker-current-color" :style="'background-color: ' + value" @click="togglePicker"></span>
        <div class="io-chrome-color-picker-wrapper">
            <chrome-picker v-if="showPickerCopy" :value="valueCopy" :disable-alpha="disableAlpha" :disable-fields="disableFields" @input="updateColor"></chrome-picker>
        </div>
    </div>
</template>

<script>
import { Chrome } from '@lk77/vue3-color'

export default {
    components: {
        'chrome-picker': Chrome
    },
    props: {
        value: {type: String, default: '#000000'},
        disableAlpha: {type: Boolean, default: true},
        disableFields: {type: Boolean, default: false},
        showPicker: {type: Boolean, default: false},
        correctPosition: {type: String, default: ''},
        pickerId: {type: String, default: 'chromeColorPicker'}
    },
    data () {
        return {
            showPickerCopy: false,
            valueCopy: '#000000'
        }
    },
    methods: {
        updateColor (color) {
            this.valueCopy = color.hex
            //
            this.$emit('onChanged', this.valueCopy)
        },
        togglePicker () {
            this.showPickerCopy = !this.showPickerCopy
            // correct position if show
            if (this.showPickerCopy && this.correctPosition !== '') {
                this.$nextTick(() => {
                    let correctionValues = this.correctPosition.split('_')
                    $(this.$el).find('.vc-chrome').css(correctionValues[0], correctionValues[1])
                })
            }
            //
            if (this.showPickerCopy) {
                this.$emit('onShow', {pickerId: this.pickerId})
            } else if (!this.showPickerCopy) {
                this.$emit('onClose', {pickerId: this.pickerId})
            }
        },
        hidePicker () {
            this.showPickerCopy = false
            //
            this.$emit('onClose', {pickerId: this.pickerId})
            this.$emit('onConfirm', this.valueCopy)
        }
    },
    watch: {
      'value' () {
          this.valueCopy = this.value
      }
    },
    beforeMount () {
        this.showPickerCopy = this.showPicker
        this.valueCopy = this.value
    }
}
</script>

<style type="text/css">
    .io-chrome-picker-current-color {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #000000;
        cursor: pointer;
    }
    .vc-chrome {
        position: absolute;
    }
    .io-chrome-color-picker-wrapper {
        position: relative;
        min-width: 225px;
    }
</style>
