import { defineComponent } from 'vue'

export default defineComponent({
    name: 'StepperStep',
    props: {
        title: { type: String, required: true },
        subtitle: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        valid: { type: Boolean, default: false },
        hasError: { type: Boolean, default: false },
        step: { type: Number, required: true },
        transitionAnimation: { type: Boolean, default: true },
    },
    computed: {
        isActive (): boolean {
            return this.$parent.$props.modelValue == this.step
        },
    },
    watch: {
        disabled (): void {
            this.$parent.updateSlots()
        },
        valid (): void {
            this.$parent.updateSlots()
        },
        hasError (): void {
            this.$parent.updateSlots()
        },
        subtitle (): void {
            this.$parent.updateSlots()
        },
    },
})
