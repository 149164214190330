import BudgetVersionsSorting from '@/interfaces/modules/budget/versioning/BudgetVersionsSorting'
import FeasibilityStudyState from '@/interfaces/store/FeasibilityStudyState'
import UnitOfMeasure from '@/interfaces/UnitOfMeasure'

export function SET_FEASIBILITY_STUDY (state: FeasibilityStudyState, value: any): void {
    state.feasibilityStudy = value
}

export function SET_SORTING_TYPE (state: FeasibilityStudyState, value: BudgetVersionsSorting): void {
    state.selectedSortingType = value
}

export function SET_SHOW_HIDDEN_BUDGET_VERSIONS (state: FeasibilityStudyState, value: boolean): void {
    state.showHiddenBudgetVersions = value
}

export function SET_BUDGET_VERSIONS_COUNT (state: FeasibilityStudyState, value: number): void {
    state.budgetVersionsCount = value
}

export function SET_HIDDEN_BUDGET_VERSIONS_COUNT (state: FeasibilityStudyState, value: number): void {
    state.hiddenBudgetVersionsCount = value
}

export function SET_SHOW_TOTAL_PER_UNIT (state: FeasibilityStudyState, value: boolean): void {
    state.showTotalPerUnit = value
}

export function SET_UNIT_OF_MEASURE (state: FeasibilityStudyState, value: UnitOfMeasure): void {
    state.unitOfMeasure = value
}

export function SET_UNIT_OF_MEASURE_AS_LOADED (state: FeasibilityStudyState): void {
    state.unitOfMeasureLoaded = true
}

export function SET_HAS_BUDGET_VERSIONS (state: FeasibilityStudyState, value: boolean): void {
    state.hasBudgetVersions = true
}
