<template>
    <div class="io-modal-container">
        <div
            class="io-mask"
            @click="closeModal"
        ></div>
        <transition name="slide-fade">
            <div
                :class="[{ 'io-modal io-active' : !hideDefaultClass }, customClass]"
                :style="customStyle"
            >
                <div
                    v-if="visible && skipLoader"
                    id="io-loader-1"
                    class="io-loader"
                >
                    <p class="io-text-load">{{ $t('Loading form...') }}</p>
                </div>
                <slot name="body"/>
                <slot/>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { includes } from 'lodash'

    export default {
        name: 'Modal',
        props: {
            customClass: {
                type: String,
                required: false,
            },
            hideDefaultClass: {
                type: Boolean,
                required: false,
                default: false,
            },
            customStyle: {
                type: Object,
                required: false,
                default: () => ({}),
            },
            // if true then window scroll overflow property will remain untouched
            forceNoScroll: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        data () {
            return {
                skipModal: ['success'],
            }
        },
        mounted () {
            this.toggleNoScrollClass(true)
            $('.intercom-app').hide()
            document.body.addEventListener('keyup', this.eventCloseModal)
        },
        methods: {
            closeModal () {
                this.$store.dispatch('modal/setShow', false, { root: true })
                this.$store.dispatch('modal/resetAdditionalData')
                $('.intercom-app').show()
            },
            toggleNoScrollClass (enable) {
                if (!this.forceNoScroll) {
                    if (enable) {$('html, body').addClass('io-no-scroll')}
                    else {$('html, body').removeClass('io-no-scroll')}
                }
            },
            eventCloseModal (e) {
                if (e.keyCode === 27) {
                    this.closeModal()
                }
            },
        },
        computed: {
            ...mapState('loadingBar', {
                visible: (state) => state.loading,
            }),
            ...mapState('modal', {
                showModal: (state) => state.show,
            }),
            skipLoader () {
                return !includes(this.skipModal, this.showModal)
            },
        },
        beforeUnmount () {
            document.body.removeEventListener('keyup', this.eventCloseModal)
        },
        unmounted () {
            this.toggleNoScrollClass(false)
            $('.intercom-app').show()
        },
    }
</script>

<style lang="scss">
    #cancelFromEdit:not(.union-cancel) {
        display: none;
    }
</style>
