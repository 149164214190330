import { defineComponent } from 'vue'
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer'
import { webCredentialStore } from '@/io-modules/web-credential/store/webCredentialStore.ts'
import { WebCredentialEnum } from '@/io-modules/web-credential/enums/WebCredentialEnum.ts'

export default defineComponent({
    name: 'WebViewer',
    props: {
        url: {
            type: String,
            required: true,
        },
        extension: {
            type: String,
            default: null,
        },
        disabledElements: {
            type: Array as () => string[],
            default: () => [],
        },
        readonly: Boolean,
        pageScrolling: Boolean,
    },
    emits: ['documentLoaded'],
    data () {
        return {
            loaded: false,
            instance: {} as WebViewerInstance,
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        async init (): Promise<void> {
            await this.createWebViewer()
            this.instance.Core.documentViewer.addEventListener('documentLoaded', this.configure)
        },
        async createWebViewer (): Promise<void> {
            this.instance = await WebViewer(
                {
                    path: '/webviewer',
                    css: process.env.CDN_URL + 'assets/css/pdftron.css',
                    licenseKey: webCredentialStore.findWebCredential(WebCredentialEnum.PDFTRON_LICENSE_KEY),
                    fullAPI: true,
                    loadAsPDF: true,
                },
                this.$refs.viewer as HTMLElement,
            )
            const options = { withCredentials: true }

            if (this.extension) {
                options.extension = this.extension
            }

            this.instance.UI.loadDocument(this.url, options)
        },
        configure (): void {
            const { annotationManager } = this.instance.Core

            this.instance.UI.disableElements(this.disabledElements)
            this.instance.UI.enableFeatures([this.instance.UI.Feature.Measurement])
            this.instance.UI.disableFeatures([this.instance.UI.Feature.PageNavigation])
            this.instance.UI.setLayoutMode(this.instance.Core.DisplayModes.Single)
            this.setSnapMode()
            this.instance.UI.openElements(['pageNavOverlay'])
            this.disableHotkeys()

            this.readonly && annotationManager.enableReadOnlyMode()
            this.pageScrolling && this.enablePageScrolling()

            this.loaded = true
            this.$emit('documentLoaded')
        },
        /**
         * this mode makes measurement tools snap to the nearest line or point
         */
        setSnapMode (): void {
            const { documentViewer, annotationManager } = this.instance.Core

            const measurementToolsNames = [
                'AnnotationCreateAreaMeasurement',
                'AnnotationCreateDistanceMeasurement',
                'AnnotationCreateRectangularAreaMeasurement',
                'AnnotationCreateArcMeasurement',
                'AnnotationCreateCloudyRectangularAreaMeasurement',
                'AnnotationCreatePerimeterMeasurement',
            ]
            for (const toolName of measurementToolsNames) {
                const toolsObjects = [
                    documentViewer.getTool(toolName),
                    documentViewer.getTool(`${ toolName }2`),
                    documentViewer.getTool(`${ toolName }3`),
                    documentViewer.getTool(`${ toolName }4`),
                ]

                for (const tool of toolsObjects) {
                    if (
                        tool instanceof this.instance.Core.Tools.RectangleCreateTool ||
                        tool instanceof this.instance.Core.Tools.LineCreateTool ||
                        tool instanceof this.instance.Core.Tools.PolygonCreateTool
                    ) {
                        tool.setSnapMode(this.instance.Core.Tools.SnapModes.DEFAULT)
                    }
                }
            }

            annotationManager.setSnapDefaultOptions({
                indicatorSize: 20,
                radiusThreshold: 20,
                indicatorColor: '#00a5e4',
            })
        },
        enablePageScrolling (): void {
            const displayMode = new this.instance.Core.DisplayMode(this.instance.Core.documentViewer, this.instance.Core.DisplayModes.Continuous)
            this.instance.Core.documentViewer
                .getDisplayModeManager()
                .setDisplayMode(displayMode)
        },
        disableHotkeys (): void {
            ['A', 'C', 'E', 'F', 'I', 'L', 'N', 'O', 'T', 'S', 'G', 'K', 'U'].forEach((key: string) => this.instance.UI.hotkeys.off(key))
        },
    },
})

