import budgetSettingsApi from '@/modules/projects/modules/apps/common/project-settings/parts/budget/api.js'

export const resetState = function ({commit, dispatch}) {
    commit('RESET_STATE')
}

export const setModel = function ({commit, dispatch}, value) {
    commit('SET_MODEL', value || {})
}

export const setFullModel = function ({commit, dispatch}, value) {
    commit('SET_FULL_MODEL', value || {})
}

export const setShowAddNewWorkflowPopup = function ({commit, dispatch}, value) {
    commit('SET_SHOW_ADD_NEW_WORKFLOW_POPUP', value)
}

export const getBudgetSettings = async function ({commit, dispatch}, project_local_id) {
    const { data } = await budgetSettingsApi.getSettings(project_local_id)
    commit('SET_BUDGET_SETTINGS', data)
}

export const setAttributesOwnerRemoteProjectId = function ({commit, dispatch}, value) {
    commit('SET_ATTRIBUTES_OWNER_REMOTE_PROJECT_ID', value)
}
