<template>
    <FormInputColumn
        :label="$t('Approval Type')"
        :customContent="true"
        :required="true"
        :tooltip-enable="isWorkflowSequenced"
        :tooltip-text="$t('Final Approval will lock the {resource} and any further changes will have to go through Budget Change Workflow.', { resource: $t(resource) })"
    >

        <div class="io-phase-type-control-wrapper">
            <Radio
                :radio-value="PhaseType.PHASED"
                input-name="submission-type"
                :label-text="$t('Phased Approval')"
                v-model="type"
            />

            <Radio
                :radio-value="PhaseType.FINAL"
                input-name="submission-type"
                :label-text="$t('Final Approval')"
                v-model="type"
            />
        </div>
    </FormInputColumn>
</template>

<script src="./PhaseTypeRadio.ts" lang="ts"/>
<style src="./PhaseTypeRadio.scss" lang="scss" scoped/>
