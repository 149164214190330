<template>
    <div class="io-schedule-templates-listing">
        <PageHeader :title="$t('Schedule Templates')" :route-back="{ name: 'lists-templates-cost-code-groups' }">
            <template #actions>
                <IOButton v-if="canEdit" @click="createNewList">{{ $t('Create New List') }}</IOButton>
            </template>
        </PageHeader>

        <div class="io-container">
            <IOBanner
                type="info"
                icon="icon-info"
                :text="$t('Schedule Lists enable the creation of activity and milestone groupings that can be imported into a project schedule')"
            />
            <LazyList
                v-if="readyToRender"
                key="schedule-tickets-templates-lazy-list"
                :columns="columns"
                :endpoint="endpoint"
                :filtersSchema="filtersSchema"
                :itemRoute="itemRoute"
                :noReroute="true"
                :showCustomComponentWhenNoRecords="true"
                @emitIconAction="emitIconAction"
                @tableRowClick="onClickScheduleList"
            >
                <template #custom-component-when-no-records>
                    <tr class="io-lazy-list-fetching-row">
                        <td :colspan="columns.length">
                            <IOPlaceholder
                                class="io-list-placeholder"
                                icon="icon-calendar-event"
                                :title="$t('No records found')"
                                :subtitle="$t('You can create a new schedule list by clicking the button below')"
                            >
                                <IOButton @click="createNewList">{{ $t('Create New List') }}</IOButton>
                            </IOPlaceholder>
                        </td>
                    </tr>
                </template>
            </LazyList>
        </div>
    </div>
</template>

<script lang="ts" src="./ScheduleTemplatesListing.ts"></script>
<style lang="scss" src="./ScheduleTemplatesListing.scss" scoped></style>
