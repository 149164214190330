import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'

export default defineComponent({
    name: 'Punch Stamp Generator Mixin',
    computed: {
        ...mapGetters('drawingMarkup', ['getSelectedAnnotation'])
    },
    methods: {
        generateAnnotationIconSvg (color: string, text: string, type: RightSideBar): string {
            if (type === RightSideBar.PUNCH) {
                return `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" version="1.1">
                     <g>
                       <circle stroke-width="2" cx="16" cy="16" r="15" stroke="${ color }" fill="none" />
                       <text font-family="'Inter', sans-serif" font-weight="600" fill="${ color }" x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="14px" >${ text }</text>
                     </g>
                    </svg>`
            }
            return `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" version="1.1">
                 <g>
                   <path d="M0 0.627451C0 0.280919 0.280919 0 0.627451 0H16C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16V0.627451Z" fill="${ color }"/>
                   <text font-family="'Inter', sans-serif" font-weight="600" fill="#fff" x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="14px" >${ text }</text>
                 </g>
                </svg>`
        },
        generateIconBlobUrl (color: string, text: string, type: string): string {
            return URL.createObjectURL(new Blob([this.generateAnnotationIconSvg(color, text, type)], {
                type: 'image/svg+xml'
            }))
        }
    }
})
