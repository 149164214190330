<template>
    <div
        class="io-invite-modal__actions-view"
        :class="{ 'io-invite-modal__actions-view_full': !isPartOfView }"
    >
        <p>
            {{ $t('Select an action:') }}
        </p>

        <ActionRadioRow
            v-if="!isAddingNewContact"
            :title="$t('Add or invite a new contact to this item')"
            :description="$t('An email will be sent to invite this contact to collaborate on this item.')"
            :value="ContactActions.SEND_INVITE"
            :key="0"
            :idx="0"
            :componentKey="componentKey"
            v-model="selectedAction"
        />

        <ActionRadioRow
            :title="$t('Bookmark and do not send invite')"
            :description="$t('This contact will be bookmarked to this item for record but will not be invited to collaborate. You can easily invite them later.')"
            :value="ContactActions.SAVE"
            :key="1"
            :idx="1"
            :componentKey="componentKey"
            v-model="selectedAction"
        />
    </div>
</template>

<script lang="ts" src="./ActionsView.ts"></script>
<style lang="scss" src="./ActionsView.scss" scoped></style>
