import { defineComponent, PropType } from 'vue'
import Resource from '@/io-modules/approval-workflows/enums/Resource'
import ApprovalAction from '@/io-modules/approval-workflows/enums/ApprovalAction'
import { Color } from '@/components/atoms/IOModal/IOModal.ts'
import ApprovalWorkflowsRouterService from '@/io-modules/approval-workflows/services/ApprovalWorkflowsRouterService'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import { mapState as mapStateVuex } from 'vuex'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import { LabelType } from '@/constants/LabelConstants'
import IOTextarea from '@/components/atoms/IOTextarea/IOTextarea.vue'
import FilesComponent from '@/components/files-new/files.vue'
import ApprovalsClient from '@/io-modules/approval-workflows/api-clients/ApprovalsClient'
import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse'
import Approver from '@/io-modules/approval-workflows/interfaces/Approver'
import Workflow from '@/io-modules/approval-workflows/interfaces/Workflow'
import ApprovalModalService from '../../services/ApprovalModalService'
import ApprovalTextService from '../../services/ApprovalTextService'
import { ClientApprovalSetting } from '../../enums/ClientApprovalSetting'
import ApprovalNoteVisibility from '@/io-modules/approval-workflows/enums/ApprovalNoteVisibility'

export default defineComponent({
    name: 'ApprovalModal',
    components: {
        IOModal,
        AlertBox,
        IOTextarea,
        FilesComponent,
    },
    props: {
        action: {
            type: String as PropType<ApprovalAction>,
            default: ApprovalAction.APPROVE,
        },
        resourceName: {
            type: String as PropType<Resource>,
            default: '',
        },
        resourceApprovals: {
            type: Object as PropType<ResourceApprovalsResponse>,
            required: true,
        },
        hideFiles: {
            type: Boolean,
            required: false,
            default: false,
        },
        isClientOffSystem: {
            type: Boolean,
            required: false,
            default: false,
        },
        step: {
            type: String
        },
        zIndex: {
            type: Number,
            required: false,
            default: 10,
        },
        forceClientApproval: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['close', 'approved', 'rejected', 'recordClientApproval', 'submitToClient'],
    data () {
        return {
            type: 'approve',
            LabelType,
            confirmInput: '',
            note: '',
            isNotePublic: true,
            filesIds: [],
            isRequestInProcess: false,
            validationOptions: {
                allowedExtensions: ['pdf', 'doc', 'docx', 'txt'],
                acceptFiles: ['.pdf', '.doc', '.docx', '.txt'],
            }
        }
    },
    computed: {
        ...mapStateVuex('appStore', ['authData']),

        modalIcon (): string {
            if (ApprovalAction.REJECT === this.action) {
                return 'icon-cross-circle'
            }

            return 'icon-check-circle-2'
        },

        modalIconColor (): string {
            if (ApprovalAction.REJECT === this.action) {
                return Color.RED
            }

            return Color.GREEN
        },

        actionNameCapitalized (): string {
            return this.$t(this.action.charAt(0).toUpperCase() + this.action.substring(1))
        },

        resourceNameInLowerCase (): string {
            return ApprovalTextService.getResourceName(this.resourceName).toLowerCase()
        },

        modalTitle (): string {
            return `${ this.actionNameCapitalized } ${ this.resourceNameInLowerCase }`
        },

        isManualApproval (): boolean {
            return !this.resourceApprovals?.workflows?.length && !this.isRejection
        },

        isManualRejection (): boolean {
            return !this.resourceApprovals?.workflows?.length && this.isRejection
        },

        isOwnerRepAppType (): boolean {
            return this.authData.app_type === 'dev'
        },

        isDirectApproval (): boolean {
            return !this.isOwnerRepAppType
                   || (this.isOwnerRepAppType && this.resourceApprovals?.client_approval_setting === ClientApprovalSetting.DISABLED)
        },

        isRecordClientApproval (): boolean {
            return this.resourceApprovals?.client_approval_setting === ClientApprovalSetting.RECORD_APPROVAL
        },

        projectSettingsRouteName (): string {
            if (this.resourceApprovals?.resource_type) {
                return ApprovalWorkflowsRouterService.getProjectSettingsRouteName(
                    this.resourceApprovals.resource_type,
                )
            }

            return null
        },

        subTitleText (): string {
            if (ApprovalAction.REJECT === this.action) {
                if (this.isApplicationPackage) {
                    return this.$t('By rejecting this application package you will also update the statuses of selected invoices.')
                }

                return this.$t('This {name} is in the middle of an active approval sequence. Rejection will require a modification and re-submission for approval.', { name: this.resourceNameInLowerCase })
            }

            if (ApprovalAction.REVIEW === this.action) {
                return this.$t('Reviewing this {name} will mark it as reviewed and move to the next party in the selected approval sequence.', { name: this.resourceNameInLowerCase })
            }

            if (this.isApplicationPackage && ApprovalAction.APPROVE === this.action) {
                return this.$t('By approving this application package you will also approve all invoices that are included in it.')
            }

            if (ApprovalAction.APPROVE === this.action && this.isClientOffSystem) {
                return this.$t('Approving this {name} will finalize the approval process and allow you to send it to your client.', { name: this.resourceNameInLowerCase })
            }

            return this.$t('Approving this {name} will mark it as approved and move to the next party in the selected approval sequence.', { name: this.resourceNameInLowerCase })
        },

        subTitleTextManualApproval (): string {
            if (this.isApplicationPackage) {
                return this.$t('By approving this application package you will also approve all invoices that are included in it.')
            }
            // return this.$t('Approving this {name} will finalize the approval process and allow you to send it to your client.', { name: this.resourceNameInLowerCase })
            return this.$t('Approving this {name} will finalize the approval process.', { name: this.resourceNameInLowerCase })
        },

        isApplicationPackage (): boolean {
            return this.resourceApprovals.resource_type === Resource.APPLICATION_PACKAGE
        },

        isRejection (): boolean {
            return ApprovalAction.REJECT === this.action
        },

        currentApproverData (): Approver {
            let approver = null
            this.resourceApprovals.workflows.forEach(workflow => {
                if (!approver) {
                    approver = workflow.approvers.find(approver => approver.contact_person_id === this.authData.u_mongo)
                }
            })

            return approver
        },

        currentWorkflow (): Workflow {
            return this.resourceApprovals.workflows.find(workflow => workflow.approvers.some(approver => approver.contact_person_id === this.authData.u_mongo))
        },

        filesArrayId (): string {
            return `user_approval_${ this.resourceApprovals?.id }`
        },

        confirmText (): string {
            return this.isRejection ? 'REJECT' : 'YES'
        },

        isValid (): boolean {
            return this.confirmText === this.confirmInput && (!this.isRejection || this.note.length > 1)
        },

        showSecondaryButton (): boolean {
            // return !this.isRejection && (this.isManualApproval || this.isLastApprover())
            return !this.isRejection && !this.isApplicationPackage && !this.isDirectApproval && (this.isManualApproval || this.isLastApprover())
        },

        secondaryButtonText (): string {
            if (ApprovalAction.APPROVE === this.action) {
                return this.$t('Approve & Hold')
            } else {
                return this.$t('Review & Hold')
            }
        },

        primaryButtonText (): string {
            if (ApprovalAction.REJECT === this.action) {
                return this.$t('Yes, Reject')
            }

            if (ApprovalAction.APPROVE === this.action) {
                if (this.isDirectApproval || !this.isLastApprover()) {
                    return `${ this.actionNameCapitalized } ${ this.resourceNameInLowerCase }`
                }

                if ((this.isRecordClientApproval || this.isClientOffSystem) && !this.forceClientApproval) {
                    return this.$t('Approve & Record Client Approval')
                }

                return this.$t('Approve & Submit to Client')
            }

            if (!this.isManualApproval || this.isDirectApproval) {
                return this.isLastApprover() ? this.$t('{action} & Submit to Client', { action: this.actionNameCapitalized }) : `${ this.actionNameCapitalized } ${ this.resourceNameInLowerCase }`
            }

            return `${ this.actionNameCapitalized } ${ this.resourceNameInLowerCase }`
        },

        noteVisibility (): ApprovalNoteVisibility {
            return this.isNotePublic
                ? ApprovalNoteVisibility.PUBLIC
                : ApprovalNoteVisibility.WORKSPACE
        },
    },
    beforeMount (): void {
        this.$store.dispatch('filesComponent/setMyDocs', {
            arrayId: this.filesArrayId,
            files: []
        })
    },
    methods: {
        isLastApprover (): boolean {
            return this.resourceApprovals?.workflows?.length === 0 || ApprovalModalService.isLastApprover(this.resourceApprovals, this.authData.u_mongo)
        },

        goToProjectSettings (): void {
            this.$router.push({ name: this.projectSettingsRouteName })
        },

        onFileAdd (file: any): void {
            this.filesIds.push(file._id)
        },

        onFileRemove (fileId: string): void {
            this.filesIds = this.filesIds.filter((id: string) => id !== fileId)
        },

        async secondaryButtonClick (): Promise<void> {
            await this.onApproveClick(false)

            this.$emit('close')
        },

        async primaryButtonClick (): Promise<void> {
            if (this.isRejection) {
                await this.onRejectClick()
            } else {
                await this.onApproveClick(!this.isDirectApproval)
            }

            this.$emit('close')
        },

        async onApproveClick (submit: boolean = false): Promise<void> {
            if (this.isRequestInProcess) {
                return
            }

            try {
                this.isRequestInProcess = true

                if (this.isManualApproval || this.isApplicationPackage) {
                    await ApprovalsClient.recordManualApproval(
                        this.resourceApprovals.id,
                        this.note,
                        this.noteVisibility,
                        this.filesIds,
                    )

                    if (submit) {
                        await this.submitToClient()
                    }
                } else {
                    await ApprovalsClient.recordUserApproval(
                        this.resourceApprovals.id,
                        this.currentApproverData?.id || this.authData.u_mongo,
                        this.note,
                        this.noteVisibility,
                        this.filesIds,
                    )

                    if (submit && this.isLastApprover()) {
                        await this.submitToClient()
                    }
                }

                this.$emit('approved', !submit)
            } finally {
                this.isRequestInProcess = false
            }
        },

        async onRejectClick (): Promise<void> {
            if (this.isRequestInProcess) {
                return
            }

            try {
                this.isRequestInProcess = true

                if (this.resourceApprovals.resource_type === Resource.APPLICATION_PACKAGE) {
                    const rejectionModalData = {
                        note: this.note,
                        noteVisibility: this.noteVisibility,
                        filesIds: this.filesIds
                    }

                    this.$emit('rejected', rejectionModalData)

                } else {
                    if (this.isManualRejection || this.isApplicationPackage) {
                        await ApprovalsClient.recordManualRejection(
                            this.resourceApprovals.id,
                            this.note,
                            this.noteVisibility,
                            this.filesIds,
                        )
                    } else {
                        await ApprovalsClient.recordUserRejection(
                            this.resourceApprovals.id,
                            this.currentApproverData?.id || this.authData.u_mongo,
                            this.note,
                            this.noteVisibility,
                            this.filesIds,
                        )
                    }

                    this.$emit('rejected')
                }
            } finally {
                this.isRequestInProcess = false
            }
        },

        async submitToClient (): Promise<void> {
            if ((this.isClientOffSystem || this.isRecordClientApproval) && !this.isDirectApproval && !this.forceClientApproval) {
                this.$emit('recordClientApproval')
            } else {
                //submit to the client
                this.$emit('submitToClient')
            }
        },
    },
})
