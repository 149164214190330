import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'

import { Location } from '@/components/jobsite-locations-select/interfaces/JobsiteLocations'

const jobsiteLocationsSelectClient = {
    getJobsiteLocations:  (projectId: string): AxiosPromise<{ enums: { status: Record<string, string> }, items: Location[] }> =>
        client.get(`/jobsite-location/${ projectId }/locations`),
}

export default jobsiteLocationsSelectClient
