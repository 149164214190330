import { defineComponent } from 'vue'
import { mapActions, mapWritableState } from 'pinia'

import Comments from './comments/Comments.vue'
import Rfis from './rfis/Rfis.vue'
import Punches from './punches/Punches.vue'
import Others from './others/Others.vue'

import { MarkupTabInterface } from '@/io-modules/documents/interfaces/MarkupTabInterface'
import DocumentInterface from '@/io-modules/documents/interfaces/DocumentInterface'

import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'
import { rightPanelTabsStore } from '@/io-modules/documents/store/rightPanelStore'
import { MarkupsTabs } from '@/io-modules/documents/enums/MarkupsTabs'
import { MarkupsTabsDetailsInterface } from '@/io-modules/documents/interfaces/MarkupsTabsDetailsInterface'

export default defineComponent({
    name: 'MarkupsTab',
    components: {
        Comments,
        Rfis,
        Punches,
        Others,
    },
    props: {
        document: {
            type: Object as () => DocumentInterface,
            required: true,
        },
    },
    data () {
        return {
            searchValue: '',
            activeTab: {} as MarkupTabInterface,
            hoveredTab: {}
        }
    },
    computed: {
        ...mapWritableState(rightPanelTabsStore, [
            'markupsActiveTab'
        ]),
        tabs (): Array<MarkupsTabs> {
            const { COMMENT, BASIC } = MarkupsTabs
            return [COMMENT, BASIC]
        },
        tabsDetails (): MarkupsTabsDetailsInterface {
            return {
                comment: {
                    title: this.$t('Comments'),
                    icon: 'comment-text',
                    addButtonText: this.$t('Add Comment'),
                    annotationType: AnnotationTypes.COMMENT,
                    component: 'Comments'
                },
                // rfi: {
                //     title: this.$t('RFIs'),
                //     icon: 'comment-2-text',
                //     addButtonText: this.$t('Add RFI'),
                //     annotationType: AnnotationTypes.RFI,
                //     component: 'Rfis'
                // },
                // punch: {
                //     title: this.$t('Punch List'),
                //     icon: 'pin-2',
                //     addButtonText: this.$t('Add Punch Item'),
                //     annotationType: AnnotationTypes.PUNCH,
                //     component: 'Punches'
                // },
                basic: {
                    title: this.$t('Others'),
                    icon: 'box',
                    addButtonText: '',
                    annotationType: AnnotationTypes.BASIC,
                    component: 'Others'
                }
            }
        },
    },
    methods: {
        ...mapActions(viewDocumentStore, ['triggerAddingAnnotationEvent']),
        onAddAnnotationClick (): void {
            this.triggerAddingAnnotationEvent(this.tabsDetails[this.markupsActiveTab].annotationType)
        },
        updateAnnotations (): void {
            this.$emit('update-annotations')
        },
        cancelAddingAnnotation (): void {
            this.$emit('cancel-adding-annotation')
        },
        selectAnnotation (uuid: string): void {
            this.$emit('select-annotation', uuid)
        },
        deleteBasicAnnotation (data: { document_id: string, annotation_id: string }): void {
            this.$emit('delete-basic-annotation', data)
        },
    }
})
