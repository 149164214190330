import * as getters from './getters'

export default {
    namespaced: true,
    namespace: 'loadingBar/',
    state () {
        return {
            loading: false, // true or false,
            loadingSpinner: false,
            loadingTableContentSpinner: false,
            loadingScreen: false,
            disableLoading: false
        }
    },
    mutations: {
        SET_LOADINGBAR (state, value) {
            state.loading = value
        },
        SET_LOADINGSPINNER (state, value) {
            state.loadingSpinner = value
        },
        SET_LOADINGTABLECONTENTSPINNER (state, value) {
            state.loadingTableContentSpinner = value
        },
        SET_DISABLE_LOADING (state, value) {
            state.disableLoading = value
        }
    },
    actions: {
        setLoading ({ commit, state }, value) {
            if (!state.disableLoading) {
                commit('SET_LOADINGBAR', value)
            }
        },
        setLoadingSpinner ({ commit }, value) {
            commit('SET_LOADINGSPINNER', value)
        },
        setLoadingTableContentSpinner ({ commit }, value) {
            commit('SET_LOADINGTABLECONTENTSPINNER', value)
        },
        setDisableLoading ({ commit }, value) {
            commit('SET_DISABLE_LOADING', value)
        }
    },
    getters
}
