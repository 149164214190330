import dateTimeHelper from './dateTime'
import dayjs from 'dayjs'
import iconLibrary from '@/base/iconLibrary.js'
import moment from 'moment'
import Router from '@/router.js'
import states from '@/helpers/states.js'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import i18n from '@/base/i18n'
import { isArray, isEmpty, isNull, isUndefined } from 'lodash'
import { formatCurrencyValue, formatCurrencyValueIfNotString, formatMoney } from '@/helpers/currencyFormatters.ts'
import {
    formatPercent,
    formatPercentIfNotString,
    formatPercentAsDecimal,
    formatPercentWithDecimalIfNotInteger
} from '@/helpers/percentFormatter.ts'
import getAreaUnit from '@/helpers/getAreaUnit.ts'

dayjs.extend(utc)
dayjs.extend(relativeTime)

/**
 * Format value helper
 * @param total
 * @param ifZeroValue
 * @param formattedValue
 * @param precision
 * @param strictPrecision
 * @return {string}
 */
function formatFromInt (total, ifZeroValue = '', formattedValue = null, precision = 2, strictPrecision = false) {
    total = iconLibrary.methods.toFloat(total)
    if (total === null) {
        total = 0
    }
    if (total === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
        return ifZeroValue
    }

    if (precision !== null) {
        total = iconLibrary.methods.applyPrecision(total, precision, strictPrecision)
    }

    // if not formatted already
    if (formattedValue === null) {
        formattedValue = iconLibrary.methods.getFormatterCurrency(total, precision)
    }

    return formattedValue
}

const filters = {
    capitalize (input: string): string {
        if (input !== null && input !== undefined) {
            return input.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
            })
        }
    },
    // replace diacritical characters with ASCII equivalents
    ascii (value: string): string {
        return (String(value)).normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    uppercase (input: string): string {
        if (input !== null && input !== undefined) {
            return input.toUpperCase()
        }
    },
    lowercase (input: string): string {
        if (input) {
            return String(input).toLowerCase()
        }
    },
    website (input: string): string {
        return input.replace(/(^\w+:|^)\/\//, '')
    },
    hasHttp (input: string): string {
        if (input.indexOf('http') === -1) {
            input = 'http://' + input
        }
        return input
    },
    filterEmptyValue<T> (value: T, emptyValue: string = '-'): T | string {
        return filters.checkValueIsEmpty(value) ? emptyValue : value
    },
    checkValueIsEmpty (value): boolean {
        return isNull(value) || isUndefined(value) || value === ''
    },
    ago (time, displayInLocalTime: boolean = true) {
        return displayInLocalTime ? moment.unix(time).local().fromNow(false) : moment.unix(time).fromNow(false)
    },
    agoDate (time, displayInLocalTime: boolean = true) {
        return displayInLocalTime
            ? moment.utc(time, 'YYYY-MM-DD HH:mm').locale(i18n.global.locale).local().fromNow(false)
            : moment(time, 'YYYY-MM-DD HH:mm').locale(i18n.global.locale).fromNow(false)
    },
    timeLeft (date, label: string = 'Expired'): string {
        const localTime = moment()
        date = moment.utc(date).local()
        const difference = moment.duration(date.diff(localTime))
        if (difference.asSeconds() <= 0) {
            return label
        } else {
            let days = parseInt(String(difference.asDays()))
            return (days === 0 ? 1 : days) + ' days left'
        }
    },
    toNowDate (time) {
        return moment(time, 'YYYY-MM-DD HH:mm').fromNow(false)
    },
    uploaderAcceptableFiles (value, delimiter: string = ',', text: string = i18n.global.t('Acceptable Files Types are: ')) {
        return isArray(value) && !isEmpty(value) ? text + value.join(delimiter) : ''
    },
    isDue (time: string): boolean {
        if (!time) {
            return false
        }

        return !moment.utc(time).local().isAfter()
    },
    formatDecimal (value, decimal = 2) {
        return iconLibrary.methods.cutPrecision(value, decimal)
    },
    formatHours (value, decimal = 2) {
        if (!value) {
            value = 0
        }
        return parseFloat(value).toFixed(decimal)
    },

    /**
     *
     * @param input
     * @param style
     * @param precision
     * @param ifZeroValue
     * @return {string}
     */
    formatNumberUSD (input, style: string = 'currency', precision: number = 2, ifZeroValue: string = '') {
        if (input === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }
        return iconLibrary.methods.getFormatterCurrency(input, precision)
    },
    formatProjectCurrencyValue (input, style: string = 'currency', precision: number = 2, ifZeroValue: string = '') {
        if (input === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }
        return iconLibrary.methods.getProjectFormatterCurrency(input, precision)
    },
    formatPercent,
    formatPercentIfNotString,
    formatPercentAsDecimal,
    formatPercentWithDecimalIfNotInteger,

    formatCurrencyValue,
    formatCurrencyValueIfNotString,

    formatByProvidedCurrency (value, currency) {
        return formatMoney(currency, value, 2)
    },
    formatCurrency (total, ifZeroValue: string = ''): string {
        if (typeof total === 'undefined') {
            total = 0
        }
        if (typeof total === 'string') {
            if (total.indexOf('%') !== -1) {
                return total
            } else {
                total = total.replace('$', '')
                total = total.replace(',', '')
            }
        }

        const value = iconLibrary.methods.cutPrecision(total)
        if (isNaN(value)) {
            return filters.formatNumberUSD(0, 'currency', 2, ifZeroValue)
        }

        // if total holds zero value then ex. N/A will be printed
        // ifZeroValue - any string
        if (total === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }

        return filters.formatNumberUSD(value, 'currency', 2, ifZeroValue)
    },

    formatWithComma (total, precision: number = 2, ifZeroValue: string = ''): string {
        if (typeof total === 'undefined') {
            total = 0
        }
        if (total < 0) {
            total = Math.abs(total)
        }
        let value = parseFloat(total).toFixed(precision)
        if (isNaN(Number(value))) {
            return parseFloat('0').toFixed(precision)
        }

        // if total holds zero value then ex. N/A will be printed
        // ifZeroValue - any string
        if (total === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }

        return value.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    },
    formatIntegerWithCommas (value) {
        let formatter = new Intl.NumberFormat()
        return formatter.format(value)
    },
    formatNumberWithCommas (x, defaultValue: string = '0.00', ifZeroValue: string = ''): string {
        x = x !== null && x !== undefined ? x : false
        if (x === false) {
            return defaultValue
        }

        // if x holds zero value then ex. N/A will be printed
        // ifZeroValue - any string
        if (x === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }

        let parts = String(x).split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
    },
    multiselectLabel (value): string {
        if (typeof value.id !== 'undefined' && typeof value.name !== 'undefined') {
            return value.name
        }
        return '-'
    },
    nl2br (value): string {
        value = !value ? '' : value
        return String(value).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')
    },
    /** Display in the local timezone only the date portion of a UTC date. */
    parseDate (date: unknown, format?: string, emptyValueText: string = '-'): string {
        if (!dayjs(date).isValid()) {
            return emptyValueText
        }
        return moment.utc(date).local().format(format ? format : dateTimeHelper.getDateFormat())
    },
    parseDateTime (date: string, format?: string, emptyValueText: string = '-'): string {
        if (!dayjs(date).isValid()) {
            return emptyValueText
        }

        return dayjs.utc(date).local().format(format ? format : dateTimeHelper.getDateTimeFormat())
    },
    parseDateTimeUniversal (dateTime, emptyValueText: string = '-'): string {
        if (!dayjs(dateTime).isValid()) {
            return emptyValueText
        }
        return dayjs(dateTime).format('MMMM DD, YYYY [at] HH:mm')
    },
    parseTime (date: string, utc: boolean = true): string {
        if (utc) {
            return dayjs.utc(date).local().format(dateTimeHelper.getTimeFormat())
        }

        return dayjs(date).format(dateTimeHelper.getTimeFormat())
    },
    formatHistoryLogDate (date): string {
        return filters.parseDateTime(date)
    },
    numberToDay (number): string {
        let obj = {
            0: 'Monday',
            1: 'Tuesday',
            2: 'Wednesday',
            3: 'Thursday',
            4: 'Friday',
            5: 'Saturday',
            6: 'Sunday',
        }

        return obj[number]
    },
    formatDate: (value: unknown, displayInLocalTime: boolean = true, emptyValueText: string = '-'): string => {
        if (!dayjs(String(value)).isValid()) {
            return emptyValueText
        }
        if (value) {
            return displayInLocalTime
                ? moment.utc(String(value)).local().format(dateTimeHelper.getDateTimeFormat())
                : moment.utc(String(value)).format(dateTimeHelper.getDateTimeFormat())
        }
    },
    formatDateOnly: (value: unknown, emptyValueText: string = '-', displayInLocalTime: boolean = false): string => {
        if (!dayjs(String(value)).isValid()) {
            return emptyValueText
        }
        return true === displayInLocalTime
            ? dayjs.utc(String(value)).local().format(dateTimeHelper.getDateFormat())
            : dayjs.utc(String(value)).format(dateTimeHelper.getDateFormat())

    },
    fileSize (bytes): string {
        if (bytes < 1024) {
            return bytes + ' B'
        }
        let i = Math.floor(Math.log(bytes) / Math.log(1024))
        let num: string | number = bytes / Math.pow(1024, i)
        let round = Math.round(num)
        num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round
        return `${ num } ${ 'KMGTPEZY'[i - 1] }B`
    },
    remainingTime (value: number): string {
        let start = dayjs().unix()
        let end = start + value

        let date1 = dayjs.unix(start).format()
        let date2 = dayjs.unix(end).format()

        return dayjs(date1).to(date2, true)
    },
    timeAgo (dateString, displayInLocalTime: boolean = true, emptyValueText: string = '-'): string {
        if (!dayjs(dateString).isValid()) {
            return emptyValueText
        }
        return displayInLocalTime ? moment.utc(dateString).local().fromNow() : moment(dateString).fromNow()
    },
    timeAgoCustom (dateString, displayInLocalTime: boolean = true): string {
        let now = displayInLocalTime ? moment.utc().local() : moment.utc()
        let date = displayInLocalTime ? moment.utc(dateString).local() : moment.utc(dateString)
        // Today, 8:33 AM
        if (now.format('MM/DD/YYYY') === date.format('MM/DD/YYYY')) {
            return date.format('[Today], h:mm A')
        } else {
            return date.format(dateTimeHelper.getDateTimeFormat())
        }
    },
    hex2rgba (hex, alpha: number = 1): string {
        const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
        return `rgba(${ r },${ g },${ b },${ alpha })`
    },
    stateShort (value: string): string {
        return states.getShort(value)
    },
    stateLong (value: string): string {
        return states.getLong(value)
    },
    boolToWord (value: boolean, valueTrue: string = i18n.global.t('Yes'), valueFlase: string = i18n.global.t('No')): string {
        if (value === false || String(value) === '0') {
            return valueFlase
        } else {
            return valueTrue
        }
    },

    /**
     * Format currency from bigint value
     * @param total
     * @param ifZeroValue
     * @return {string|string}
     */
    formatCurrencyInt (total, ifZeroValue: string = ''): string {
        return formatFromInt(total, ifZeroValue, iconLibrary.methods.getFormatterCurrency(total, 2, true))
    },
    formatProjectCurrencyInt (total, ifZeroValue: string = ''): string {
        return formatFromInt(total, ifZeroValue, iconLibrary.methods.getProjectFormatterCurrency(total, 2, true))
    },

    /**
     * Format value from bigint value
     * @param total
     * @param ifZeroValue
     * @return {string|string}
     */
    formatCurrencyValueInt (total, ifZeroValue: string = ''): string {
        return formatFromInt(total, ifZeroValue, iconLibrary.methods.getFormatterLocale(total, 2, true))
    },
    /**
     * Format value with percent value
     * @param total
     * @param strictPrecision
     * @param ifZeroValue
     * @return {string}
     */
    formatPercentInt (total, strictPrecision: boolean = false, ifZeroValue: string = ''): string {
        const valueFormatFromInt = formatFromInt(
            total,
            ifZeroValue,
            iconLibrary.methods.getFormatterLocale(total, 2, true, strictPrecision),
            2,
            strictPrecision,
        )
        return `${ valueFormatFromInt }%`
    },
    strippedContent (string: string): string {
        let regex = /(<([^>]+)>)/ig
        return string.replace(regex, '')
    },

    limitStrLength (text: string, maxLength: number = 7): string {
        if (text && text.length > maxLength - 3) {
            return text.substring(0, maxLength).trimEnd() + '...'
        }
        return text
    },
    /**
     * Format text to slug format (foo-bar)
     * @param text
     * @param separator
     * @return {string}
     */
    slugify (text: string, separator: string = '-'): string {
        return text.toString()
            .normalize('NFD')                   // split an accented letter in the base letter and the acent
            .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
            .replace(/\s+/g, separator)
    },
    formatPhoneNumber (number: string): string {
        if (!number) {
            return '-'
        }

        const match = number.match(/^(\d{3})(\d{3})(\d{4})$/)

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }

        return number
    },
    employeeUrl (employeeId: number | string): string {
        if (employeeId) {
            return Router.resolve({
                name: 'employee-view',
                params: { id: employeeId.toString() },
            }).href
        }
    },
    getAreaUnit,
}

export default filters
