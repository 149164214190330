<template>
    <div class="io-vendors-list">
        <Placeholder
            v-if="!vendors.length"
            icon="icon-inbox"
            :title="$t('No records found')"
            :subtitle="$t('Please change filters to see matching records.')"
        />
        <div v-else class="io-vendors-list__content">
            <Accordion
                v-for="(vendor, index) in vendors"
                :key="vendor.id"
                ref="vendors"
                class="io-vendors-list-item"
                icon="icon-chevron-down"
                :rotate-degrees="90"
                rotate-direction="left"
                icon-color="gray"
                :open="false"
                :show-line="false"
                :status="getInvoicesCount"
                @toggle="(data) => toggleItem(data, index, vendor.id)"
            >
                <template #header-left>
                    <Avatar
                        type="company"
                        :show-full-name="true"
                        :employee-mongo-id="vendor.id"
                        :employeeFullName="vendor.name"
                    />
                    <StatusPill variant="blue-light" :text="getVendorHeaderPillText(vendor)"/>
                </template>
                <template #header-right>
                    <IOButton
                        variant="link"
                        :disabled="!vendor.id"
                        @click.stop="showVendorDetails(vendor.id)"
                    >
                        {{ $t('Show Vendor Details') }}
                    </IOButton>
                    <span class="io-label">{{ $t('Total Billed to Date') }}</span>
                    <span class="io-value">{{ $filters.formatProjectCurrencyInt(vendor.total_billed_to_date) }}</span>
                </template>
                <template #default>
                    <div v-if="loading" class="io-accordion-content-wrapper">
                        <div class="io-loader-small"></div>
                    </div>
                    <InvoicesListTable
                        v-else-if="vendorsInvoices[vendor.id] && vendorsInvoices[vendor.id].length"
                        :headers="invoicesHeaders"
                        :items="vendorsInvoices[vendor.id] || []"
                        :tableHeight="'62vh'"
                        :loading-data="loadingData"
                        @loadData="() => loadData(vendor.id)"
                    />
                    <div v-else class="io-placeholder">
                        <div class="io-placeholder__title">{{ $t('No records found') }}</div>
                        <div class="io-placeholder__subtitle">
                            {{ $t('Please change filters to see matching records.') }}
                        </div>
                    </div>
                </template>
            </Accordion>
        </div>
    </div>
</template>

<script lang="ts" src="./InvoicesVendorsList.ts"></script>
<style lang="scss" src="./InvoicesVendorsList.scss" scoped></style>
