<template>
    <IOModal
        class="io-select-set-modal"
        :title="$t('Current Sets')"
        :loading="loading"
        icon="icon-list-unordered"
        width="1100"
        scrollable
        @close="close"
    >
        <div class="io-select-set-modal__table">
            <Table
                v-if="sets.length"
                :headers="tableHeaders"
                :items="sets"
            >
                <template #tbody="{ sortedItems }">
                    <tr
                        v-for="item in sortedItems"
                        :key="item.id"
                        class="cursor-pointer hover-darken prevent-select"
                        @click="selectedSet = item"
                    >
                        <td>
                            <div class="flex items-center gap-24-px">
                                <Radio input-name="set" :radio-value="item" v-model="selectedSet" />

                                <div>{{ item.name }}</div>
                            </div>
                        </td>

                        <td>
                            <Avatar
                                :employee-mongo-id="item.created_by_full_name.id"
                                :employee-full-name="item.created_by_full_name.name"
                                :company-name="item.created_by_full_name.position"
                                size="sm"
                                show-full-name
                            />
                        </td>

                        <td>{{ item.issue_date }}</td>

                        <td class="text-right">{{ item.drawings_count }}</td>
                    </tr>
                </template>
            </Table>

            <IOPlaceholder
                v-else
                :title="$t('Drawings Sets Not Found')"
                icon="icon-image-3"
            >
                <i18n-t keypath="You should {action} first" tag="span">
                    <template #action>
                        <IOButton
                            variant="link"
                            @click="$emit('addNewDrawings')"
                        >
                            {{ $t('Add New Drawings') }}
                        </IOButton>
                    </template>
                </i18n-t>
            </IOPlaceholder>
        </div>

        <template #footer>
            <IOButton variant="secondary" @click="close">{{ $t('Cancel') }}</IOButton>

            <IOButton :disabled="!selectedSet" @click="$emit('setSelected', selectedSet)">
                {{ $t('Next') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./SelectSetModal.ts"></script>
<style lang="scss" src="./SelectSetModal.scss" scoped></style>
