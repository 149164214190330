<template>
    <PopupModal
        class="io-export-modal"
        :width="451"
        @close="$emit('close')"
    >
        <template #header>
            <div class="io-export-modal-header">
                <div>
                    <span class="io-export-modal-header__icon icon-export"></span>
                    <span class="io-export-modal-header__title">{{ $t('Export to Excel') }}</span>
                </div>
                <div class="io-export-modal-header__icon icon-cross" @click="$emit('close')"></div>
            </div>                
        </template>
        <template #default>
            <div class="io-export-modal-content">
                <IOLabel :is-required="true" :text="$t('Please select the items you would like to export to excel')"/>
                <Checkbox 
                    v-for="item in items" 
                    :key="item.key" 
                    :noValueValidation="true" 
                    :labelText="item.title"
                    :classSpan="item.checked ? 'io-item--checked' : ''"
                    v-model="item.checked" 
                />
            </div>
        </template>
        <template #footer>
            <div>
                <IOButton variant="secondary" @click="$emit('close')">{{ $t('Cancel') }}</IOButton>
                <IOButton :disabled="!hasCheckedItems" @click="exportToExcel">{{ $t('Export') }}</IOButton>
            </div>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./ExportToExcelModal.ts"></script>
<style lang="scss" src="./ExportToExcelModal.scss" scoped></style>