<template>
    <div class="io-jobsite-locations-preview">
        <div class="io-jobsite-locations-preview__header" :class="{ 'has-selected-items': !!selectedLocationsIds.length }" data-testid="preview-header">
            <div v-if="title" class="io-header__title" data-testid="preview-title">{{ $t(title) }}</div>

            <template v-if="isEditable">
                <IOButton v-if="!selectedLocationsIds.length" data-testid="preview-action-add" variant="link" icon="icon-pin-3" @click="openJobSiteLocationsModal">{{ $t('Select Jobsite Locations') }}</IOButton>
                <IOButton v-else data-testid="preview-action-add" variant="link" size="small" icon="icon-plus" @click="openJobSiteLocationsModal">{{ $t('Add') }}</IOButton>
            </template>
        </div>

        <SelectedJobsiteLocations
            v-if="!!selectedLocationsIds.length"
            :selected-paths="selectedLocationsPath"
            :is-editable="isEditable"
            :is-skinny-mode="isSkinnyMode"
            @update-model-value="updateModelValue"
            @update-selected-list="updateSelectedList"
        />

        <JobsiteLocationsModal
            v-if="isJobSiteLocationsModalShown"
            :selected-jobsite-locations-ids="selectedJobsiteLocationsIds"
            :jobsite-locations="jobsiteLocations"
            :select-multiple="selectMultiple"
            :projectId="projectId"
            @save="onSaveJobSiteLocations"
            @close="closeJobSiteLocationsModal"
        />
    </div>
</template>

<script lang="ts" src="./JobsiteLocationsPreview.ts"></script>
<style lang="scss" src="./JobsiteLocationsPreview.scss" scoped></style>
