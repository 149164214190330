import { trace, context, propagation, SpanKind, SpanStatusCode, Tracer, Span, TracerProvider } from '@opentelemetry/api'
import { BatchSpanProcessor, WebTracerProvider, ConsoleSpanExporter } from '@opentelemetry/sdk-trace-web'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { ATTR_SERVICE_NAME } from '@opentelemetry/semantic-conventions'
import { Resource } from '@opentelemetry/resources'
import { WebCredentialEnum } from '@/io-modules/web-credential/enums/WebCredentialEnum.ts'
import Router from 'vue-router'
import AxiosInstance from 'axios'

class IngeniousTelemetry {
    private static instance: IngeniousTelemetry
    private debug: null|Boolean
    private tracer: null|Tracer
    private resource: null|Resource
    private provider: null|TracerProvider
    private rootSpan: null|Span
    private rootContext: null|Context
    private webCredentialStore: any
    private currentNavigationSpan: null|Span

    private constructor () {}

    static getInstance (): IngeniousTelemetry {
        if (this.instance) {
            return this.instance
        }
        this.instance = new IngeniousTelemetry()
        return this.instance
    }

    init (webCredentialStore: any): void {
        this.webCredentialStore = webCredentialStore

        const tracesEnabled = this.webCredentialStore.findWebCredential(WebCredentialEnum.TRACES_ENABLED)
        if (!tracesEnabled) {
            return
        }

        this.debug = this.webCredentialStore.findWebCredential(WebCredentialEnum.TRACES_DEBUG)
        this.resource = Resource.default().merge(
            new Resource({
                [ATTR_SERVICE_NAME]: 'Ingenious-Web-App',
            })
        )

        this.provider = new WebTracerProvider({
            resource: this.resource,
        })

        let exporter = null
        if (this.debug) {
            exporter = new ConsoleSpanExporter()
        } else {
            exporter = new OTLPTraceExporter({
                url: '/v1/traces',
                concurrencyLimit: 10,
            })
        }

        if (null !== exporter) {
            this.provider.addSpanProcessor(new BatchSpanProcessor(exporter, {
                maxQueueSize: 100,
                maxExportBatchSize: 10,
                scheduledDelayMillis: 500,
                exportTimeoutMillis: 30000,
            }))
        }

        this.provider.register({
            contextManager: new ZoneContextManager(),
        })

        this.tracer = this.provider.getTracer('ingenious-app-tracer')
        this.rootSpan = this.tracer.startSpan('user-session')
        this.rootContext = trace.setSpan(context.active(), this.rootSpan)
    }

    instrumentRouter (router: Router): void {
        if (!this.tracer) {
            return
        }

        router.afterEach((to, from, failure) => {
            if (this.currentNavigationSpan) {
                this.currentNavigationSpan.end()
            }

            const fromPath = this.parseUrl(from.path)
            const toPath = this.parseUrl(to.path)
            this.currentNavigationSpan = this.startSpan(`VUE-ROUTE ${ toPath }`, {
                attributes: {
                    'url.path': fromPath,
                },
            }, this.rootContext)

            if (failure && this.currentNavigationSpan) {
                this.currentNavigationSpan.setStatus({
                    code: SpanStatusCode.ERROR,
                    message: `Navigation error ${ failure.type }. From: ${ failure.from.path }, to: ${ failure.to.path }`,
                })
            }
        })
    }

    instrumentAxios (client: AxiosInstance): void {
        if (!this.tracer) {
            return
        }

        client.interceptors.request.use((config) => {
            const activeContext = context.active()
            const parentSpan = this.currentNavigationSpan
            const parentContext = parentSpan ? trace.setSpan(activeContext, parentSpan) : activeContext
            const urlParsed = this.parseUrl(config.url)
            const requestMethod = config.method?.toUpperCase()

            try {
                const requestUrl = new URL(config.baseURL + config.url)

                const traceHeaders = {}
                config.otelSpan = this.startSpan(`${ requestMethod } ${ urlParsed }`, {
                    kind: SpanKind.CLIENT,
                    attributes: {
                        'http.request.method': requestMethod,
                        'url.full': requestUrl.url,
                        'url.path': urlParsed
                    }
                }, parentContext)

                propagation.inject(trace.setSpan(activeContext, config.otelSpan), traceHeaders)
                if (traceHeaders.traceparent) {
                    traceHeaders['X-Ingenious-Trace'] = traceHeaders.traceparent
                    delete (traceHeaders.traceparent)
                }

                config.headers = {
                    ...config.headers,
                    ...traceHeaders
                }
            } catch (e) {
                if (this.debug) {
                    console.error(e)
                }
            }

            return config
        })

        client.interceptors.response.use(res => {
            if (res.config.otelSpan) {
                const span = res.config.otelSpan
                span.setAttribute('http.response.status_code', res.status)
                span.end()
            }
            return res
        }, error => {
            if (error.config && error.config.otelSpan) {
                const span = error.config.otelSpan
                span.setAttribute('http.response.status_code', error.response ? error.response.status : 0)
                if (!error.response || error.response.status >= 400) {
                    span.setStatus({
                        code: SpanStatusCode.ERROR,
                        message: error.message,
                    })

                }
                span.recordException(error)
                span.end()
            }
            return Promise.reject(error)
        })
    }

    parseUrl (url: string): string {
        const replacements = [
            {
                // replace subdomain and apptype
                regex: /(.*)\/([a-zA-Z0-9-]+)\/(sc|gc|ac|dev|owner|null)(.*)/,
                replacement: '$1/<subdomain>/<apptype>$4'
            },
            {
                // replace UUIDs
                regex: /(.*)([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(.*)/,
                replacement: '$1<uuid>$3'
            },
            {
                // replace mongo ids
                regex: /(.*)([a-f\d]{24})(.*)/,
                replacement: '$1<objectid>$3'
            },
            {
                // replace ids
                regex: /(.*)\/(\d+)(.*)/,
                replacement: '$1/<id>$3'
            },
            {
                // replace email
                regex: /(.*)\/(.*@.*\.[\w-]+)(.*)/,
                replacement: '$1/<email>$3'
            }
        ]

        let result = url
        replacements.forEach(item => {
            result = result.replace(item.regex, item.replacement)
        })
        return result
    }

    startSpan (name: string, config: [], context: Context = undefined): null|Span {
        if (!this.tracer) {
            return null
        }

        if (!config.attributes) {
            config.attributes = {}
        }
        config.attributes['service.domain'] = window.location.host
        config.attributes['server.address'] = window.location.host
        config.attributes['server.port'] = window.location.port ? window.location.port : (window.location.protocol === 'http:' ? 80 : 443)
        config.attributes['env'] = this.webCredentialStore.findWebCredential(WebCredentialEnum.ENV)
        config.attributes['env_name'] = this.webCredentialStore.findWebCredential(WebCredentialEnum.ENV_NAME)
        config.attributes['is_ephemeral'] = this.webCredentialStore.findWebCredential(WebCredentialEnum.IS_EPHEMERAL)
        config.attributes['region'] = this.webCredentialStore.findWebCredential(WebCredentialEnum.REGION)

        return this.tracer.startSpan(name, config, context)
    }

    getTracer (): null|Tracer {
        return this.tracer
    }

    getRootSpan (): null|Span {
        return this.rootSpan
    }

    getRootContext (): null|Context {
        return this.rootContext
    }
}

const ingeniousTelemetry = IngeniousTelemetry.getInstance()
export { ingeniousTelemetry }
