import { defineComponent } from 'vue'
import Invoice from '@/io-modules/invoices/interface/Invoice'
import InvoiceTableRow
    from '@/io-modules/invoices/components/invoices-list/invoices-list-table/invoice-table-row/InvoiceTableRow.vue'
import Table from '@/components/table/Table.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface.ts'
import DismissibleBanner from '@/components/dismissible-banner/DismissibleBanner.vue'
import { mapActions, mapState } from 'pinia'
import { appPackageViewStore } from '@/io-modules/invoices/stores/appPackageView.ts'
import AppPackageRouteName from '@/io-modules/invoices/enums/AppPackageRouteName.ts'
import { mapState as mapStateVuex } from 'vuex'
import RequestedDataErrorPlaceholder from '../../../requested-data-error-placeholder/RequestedDataErrorPlaceholder.vue'
import { AppPackageTableType } from '@/io-modules/invoices/enums/AppPackageTableType.ts'
import appPackagesTableHeaders from '@/io-modules/invoices/helpers/appPackageTableHeaders.ts'
import appPackageClient from '../../../../api-clients/appPackageClient'

export default defineComponent({
    name: 'AttachedInvoicesTable',
    components: { RequestedDataErrorPlaceholder, DismissibleBanner, Table, InvoiceTableRow },
    data () {
        return {
            loading: false,
            error: false,
            bannerHeader: 'Invoice with multiple funding sources',
            bannerText: [
                `This icon next to the invoice number indicates that multiple funding sources were used for this invoice,
                 and it will be displayed in different funding summaries.`,
                'Each summary will only display the cost of this invoice per fund.',
            ],
        }
    },
    computed: {
        ...mapStateVuex('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id,
        }),
        ...mapState(appPackageViewStore, ['areAttachedInvoices', 'attachedInvoices', 'isDraft', 'attachedInvoices', 'appPackage']),

        getTableHeaders (): HeaderTable[] {
            return appPackagesTableHeaders(AppPackageTableType.AttachedInvoices)
        },

        attachedInvoicesList (): Invoice[] {
            return this.attachedInvoices?.attached_invoices_per_fund || []
        },
    },
    async created () {
        await this.fetchAttachedInvoices()
    },
    methods: {
        ...mapActions(appPackageViewStore, [
            'setEditModeValue',
            'fetchAppPackageAttachedInvoices'
        ]),

        async fetchAttachedInvoices (): Promise<void> {
            try {
                this.loading = true
                await this.fetchAppPackageAttachedInvoices(this.projectLocalID, this.$route.params.id)
                this.loading = false
                this.error = false
            } catch (e) {
                this.error = true
            } finally {
                this.loading = false
            }
        },

        goToAttachInvoicesStep (): void {
            this.setEditModeValue(true)
            this.$router.push({ name: AppPackageRouteName.AppPackageAttachInvoices })
        },

        initExportToPdf (): void {
            this.showPopupAlert({
                title: this.$t('Only PDF, JPEG and PNG file types that are attached to the invoices will be exported to the system generated PDF of this application package.'),
                caption: this.$t('All other file types will not be included.'),
                icon: 'icon-alert-triangle',
                iconColor: 'var(--warning-darker)',
                sessionKey: 'application-package-export',
                disabledInSessionCallback: this.exportToPdf,
                width: 572,
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        loaderOnAction: false,
                        action: null,
                    },
                    {
                        text: this.$t('Continue'),
                        class: 'io-btn-primary',
                        saveDisabledInSession: true,
                        loaderOnAction: true,
                        action: this.exportToPdf,
                    },
                ]
            })
        },

        exportToPdf (): void {
            appPackageClient.getAppPackagePDF(this.projectLocalID, this.$route.params.id)
        },
    }
})
