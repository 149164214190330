enum CurveType {
    STRAIGHT_LINE = 'straight-line',
    BELL_STANDARD = 'bell-standard',
    POSITIVELY_SKEWED = 'positively-skewed',
    NEGATIVELY_SKEWED = 'negatively-skewed',
    TRAPEZOID = 'trapezoid',
    S_CURVE = 's-curve',
    CUSTOM = 'custom'
}

export default CurveType
