import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { NewBasicAnnotationPayloadInterface } from '@/io-modules/documents/interfaces/NewBasicAnnotationPayloadInterface'

const basicAnnotationsClient = {
    addBasicAnnotation (documentId: string, postData: NewBasicAnnotationPayloadInterface): AxiosPromise<void> {
        return client.post(`/document/${ documentId }/annotation`, postData)
    },
    modifyBasicAnnotation (documentId: string, annotationId: string, postData: { xfdf: string }): AxiosPromise<void> {
        return client.patch(`/document/${ documentId }/annotation/${ annotationId }`, postData)
    },
    deleteBasicAnnotation (documentId: string, annotationId: string): AxiosPromise<void> {
        return client.delete(`/document/${ documentId }/annotation/${ annotationId }`)
    }
}

export default basicAnnotationsClient
