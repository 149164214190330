<template>
    <div class="io-invite-modal__search-view">
        <p>{{ $t('Please select the workspace type for this user.') }}</p>
        <p><strong>{{ selectedContact.email }}</strong></p>

        <SelectWorkspaceType :componentKey="componentKey" :preselectedWorkspaceType="preselectedWorkspaceType" />
    </div>
</template>

<script lang="ts" src="./WorkspaceTypeOffSystemCompany.ts"></script>
