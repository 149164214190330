<template>
    <tr class="io-single-file" :class="{ 'io-clickable io-row-hovered': rowClickable }" @click="onRowClickPreview">
        <td>
            <IOInput
                v-if="isEditMode"
                v-model="newFileName"
                ref="inputName"
                class="io-single-file__input-wrapper"
            />

            <template v-else>
                <div class="io-single-file__file-name">{{ file.file_name }}</div>
            </template>
        </td>
        <td v-if="showFileSize">{{ $filters.fileSize(file.file_size) }}</td>
        <td v-if="showCreatorName" class="io-single-file__file-creator">
            <Avatar
                v-if="showCreatorAvatar"
                :employeeMongoId="file.creator_mongo_id"
                :employeeFullName="file.creator_full_name"
            />
            {{ file.creator_full_name }}
        </td>
        <td v-if="showUpdateDate">{{ $filters.formatDateOnly(file.updated_at) }}</td>
        <td v-if="showSharedYesNo">
            <StatusPill
                :text="isShared ? $t('Yes') : $t('No')"
                :variant="isShared ? 'green-light' : 'red-light'"
            />
        </td>
        <td class="io-action aright" @click.stop="">
            <template v-if="!isEditMode">
                <IOButton
                    v-if="showPreviewButton"
                    icon="fas fa-file-alt"
                    variant="link"
                    size="small"
                    :disabled="isSynchronizing"
                    @click.stop="onPreview"
                >
                    {{ $t("Preview") }}
                </IOButton>
                <IOButton
                    v-if="showDownloadButton"
                    class="download-button"
                    icon="icon-file-download"
                    variant="link"
                    size="small"
                    :disabled="isSynchronizing"
                    @click.stop="onClickDownload"
                >
                    {{ $t("Download") }}
                </IOButton>
                <IOButton
                    v-if="showDeleteButton"
                    class="download-button"
                    icon="icon-trash"
                    variant="link"
                    @click.stop="onClickDelete"
                >
                    {{ $t("Delete") }}
                </IOButton>
            </template>

            <template v-if="toSync">
                <IOButton size="small" @click.stop="syncFile">{{ $t("Sync") }}</IOButton>
            </template>
            <template v-else-if="editable">
                <div v-if="isEditMode">
                    <i class="icon-check" @click.stop="onClickSave"></i>
                    <i class="icon-cross" @click.stop="onClickCancel"></i>
                </div>
                <div v-else>
                    <i class="icon-edit" @click.stop="onClickEdit"></i>
                    <i class="icon-trash" @click.stop="onClickDelete"></i>
                </div>
            </template>
        </td>
    </tr>
</template>

<script lang="ts" src="./SingleFile.ts"></script>

<style lang="scss" src="./SingleFile.scss" scoped></style>
