export const getCurrentView = (state) => {
    return state.currentView
}
export const filters = (state) => {
    return state.filters
}
export const showFilters = (state) => {
    return state.showFilters
}
export const filtersSelected = (state) => {
    return state.filtersSelected
}
export const constructionSetType = (state) => {
    return state.constructionSetType
}
export const getActiveDrawingSheet = (state) => {
    return state.activeDrawingSheet
}
export const getParentFormShown = (state) => {
    return state.parentFormShown
}
export const getShareMarkupPopupShown = (state) => {
    return state.shareMarkupPopupShown
}
export const getShareMarkupData = (state) => {
    return state.shareMarkupData
}
export const getDrawingVersionSwitched = (state) => {
    return state.drawingVersionSwitched
}

export const getActiveDrawingSet = (state) => {
    return state.activeDrawingSet
}

export const getDrawingSetsShown = (state) => {
    return state.drawingSetsShown
}
export const getDrawingSheetsShown = (state) => {
    return state.drawingSheetsShown
}
export const getDrawingSheetShown = (state) => {
    return state.drawingSheetShown
}
