import { ApprovalSubmissionParams } from '@/interfaces/modules/projects/financial-approvals/ApprovalSubmissionParams.ts'
import { AxiosPromise } from 'axios'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus.ts'
import ApprovalType from '@/interfaces/modules/projects/financial-approvals/ApprovalType.ts'
import ApprovalNoteVisibility from '@/io-modules/approval-workflows/enums/ApprovalNoteVisibility.ts'
import ApprovalSubmissionUpdateWorkflow
    from '@/io-modules/approval-workflows/interfaces/ApprovalSubmissionUpdateWorkflow.ts'
import PhasedApprovalSubmissionParams
    from '@/interfaces/modules/projects/financial-approvals/PhasedApprovalSubmissionParams.ts'
import ApprovalPhaseData from '@/interfaces/modules/projects/financial-approvals/ApprovalPhaseData.ts'
import { ApiClient as client } from '@/api/api'

const financialApprovalSubmissionClient = {

    resetApprovalSubmission (request: ApprovalSubmissionParams): AxiosPromise {
        return client.post('/approval-submissions/reset', request)
    },
    approveSubmission (
        instancedApproverId: string,
        note: string,
        status: ApprovalStatus,
        approvalType: ApprovalType,
        noteVisibility: ApprovalNoteVisibility,
        filesIds: string[]
    ): AxiosPromise {
        return client.post('/approvals', {
            instanced_approver_id: instancedApproverId,
            note: note,
            status: status,
            type: approvalType,
            note_visibility: noteVisibility,
            temporary_attached_documents: filesIds
        })
    },
    updateSubmission (approvalSubmissionId: string, workflows: Array<ApprovalSubmissionUpdateWorkflow>): AxiosPromise {
        return client.put(`/approval-submissions/${ approvalSubmissionId }`, { workflows })
    },
    getApprovalSubmissionsList (request: ApprovalSubmissionParams | PhasedApprovalSubmissionParams): AxiosPromise<ApprovalPhaseData[]> {
        return client.get('/approval-submissions/list', {
            params: request
        })
    },
    createApprovalSubmission (request: ApprovalSubmissionParams | PhasedApprovalSubmissionParams): AxiosPromise {
        return client.post('/approval-submissions', request)
    },
    getApprovalSubmissions (params: ApprovalSubmissionParams): AxiosPromise {
        return client.get('/approval-submissions', { params })
    },
    deleteApprovalSubmissions (params: ApprovalSubmissionParams): AxiosPromise {
        return client.delete('/approval-submissions', { params })
    },
}

export default financialApprovalSubmissionClient
