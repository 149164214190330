import { defineComponent, PropType } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import dayjs from 'dayjs'
import InvoiceRevision from '@/io-modules/invoices/interfaces/InvoiceRevision.ts'

export default defineComponent({
    name: 'InvoiceRevisionNoteModal',
    components: { IOModal },
    props: {
        revisionInfo: {
            type: Object as PropType<InvoiceRevision>,
            required: true
        }
    },
    computed: {
        getDate (): string {
            return this.$filters.parseDate(this.revisionInfo.created_at)
        },
        getTime (): string {
            return this.$filters.parseTime(this.revisionInfo.created_at)
        }
    }
})
