import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import PopupModal from '@/components/popup-modal/PopupModal.vue'

export default defineComponent({
    components: {
        PopupModal
    },
    props: {
        modelValue: { type: String, default: '' },
    },
    emits: ['close', 'update:modelValue'],
    data () {
        return {
            agreementTypeInternal: null,
        }
    },
    computed: {
        ...mapGetters('common', {
            agreementTypes: 'getAgreementTypes'
        }),
        isDisabled (): boolean {
            return this.agreementTypeInternal === ''
        },
    },
    mounted () {
        this.agreementTypeInternal = this.modelValue
    },
    methods: {
        selectAgreementType (agreementType: string): void {
            this.agreementTypeInternal = agreementType
        },
        closePopup (): void {
            this.$emit('close')
        },
        setAgreementType (): string {
            this.$emit('update:modelValue', this.agreementTypeInternal)
            this.closePopup()
            return this.agreementTypeInternal
        }
    }
})
