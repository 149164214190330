<template>
    <div
        :id="commentId" class="io-single-annotation shadow"
        :class="{'io-selected': isSelected, 'io-resolved': data.is_resolved && !isSelected }"
        @click.stop="selectAnnotation"
    >
        <div class="io-single-annotation__header">
            <div class="io-left-side">
                <i class="icon-comment-text"/>
                <span>{{ $t('Comment no:') }}&nbsp;<span class="io-number">{{ data.comment.internal_id }}</span></span>
            </div>

            <div class="io-right-side">
                <span>{{ $filters.parseDate(data.created_at) }}</span>

                <i
                    v-tooltip="{ content: data.is_resolved ? $t('Mark as Unresolved') : $t('Mark as Resolved') }"
                    :class="[data.is_resolved ? 'icon-checkbox-circle-2' : 'icon-checkbox-circle']"
                    @click="resolveCommentPopup"
                />

                <DropdownButtonNew
                    v-if="canApplyActions" :disabled="data.is_resolved" :iconMode="true"
                    fontAwesomeClass="icon-more-vert-2"
                >
                    <div class="io-single-option" @click="openEditMode">{{ $t('Edit') }}</div>
                    <div class="io-single-option" @click="deleteCommentPopup">{{ $t('Delete') }}</div>
                </DropdownButtonNew>
            </div>
        </div>

        <div class="io-single-annotation-creator">
            <div class="io-single-annotation-creator__avatar">
                <template v-if="data.created_by_full_name.imageExist">
                    <img :src="getApiUrlAvatar(data.created_by_full_name.id)"/>
                </template>
                <template v-else>
                    <Initials :fullName="data.created_by_full_name.name"/>
                </template>
            </div>
            <div class="io-single-annotation-creator__user-info">
                <div>{{ data.created_by_full_name.name }}</div>
                <div>{{ data.created_by_full_name.position }}</div>
            </div>
        </div>


        <template v-if="editMode">
            <Mentions
                :key="`comment-${data.id}`"
                :attached_employees_ids="mentionUsers"
                :custom-people-list="mentionUsers"
                :attach-below="true"
                v-model="editedComment"
                @trigger-mention="scrollToCommentDelay"
                @on-submit="saveComment"
                @update-mentions="shareWithComment = $event"
            />

            <div class="io-edit-mode-buttons">
                <IOButton variant="secondary" size="small" @click="cancelEditMode">{{ $t('Cancel') }}</IOButton>
                <IOButton :disabled="!editedComment" size="small" @click="saveComment">{{ $t('Save') }}</IOButton>
            </div>
        </template>

        <div
            v-else
            class="io-single-annotation__text"
            :class="{'io-collapsed': !isSelected, 'io-disabled': data.is_resolved}"
            @click="setReplyingToComment"
            v-html="data.comment.comment"
        />


        <div v-if="parsedComments().length" class="io-divider-with-text">
            <span
                v-if="data.comment.comments.length > 1"
                class="io-show-all-comments"
                :class="{'io-hide': showAllComments}"
                @click="toggleComments"
            >
                {{ toggleCommentsText }}
            </span>
        </div>

        <div v-if="replyingToComment" class="io-single-annotation__reply-input">
            <Mentions
                :key="`reply-${ data.id }`"
                :attached_employees_ids="mentionUsers"
                :custom-people-list="mentionUsers"
                :attach-below="true"
                :placeholder="$t('Reply...')"
                v-model="replyMessage"
                @update-mentions="shareWithReply = $event"
            />
            <div class="io-buttons">
                <IOButton variant="secondary" size="small" @click="cancelReplying">{{ $t('Cancel') }}</IOButton>
                <IOButton size="small" :disabled="!replyMessage" @click="addReply">{{ $t('Post') }}</IOButton>
            </div>
        </div>

        <Reply
            v-for="reply in parsedComments()"
            :key="reply.id"
            :lastReplyId="getLastReplyId"
            :data="reply"
            :comment="data"
        />
    </div>
</template>

<script lang="ts" src="./SingleComment.ts"></script>
<style lang="scss" src="./SingleComment.scss" scoped></style>
