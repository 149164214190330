import client from '@/api/cached-list/client'
import { isArray } from 'lodash'

export const setEmployees = function ({commit, dispatch}, value) {
    commit('SET_EMPLOYEES', value || [])
}

export const setContacts = function ({commit, dispatch}, value) {
    commit('SET_CONTACTS', value || [])
}

export const setMaterials = function ({commit, dispatch}, value) {
    commit('SET_MATERIALS', value || [])
}

export const updateProjectContractOpenBookById = function ({ commit, dispatch }, payload) {
    commit('UPDATE_PROJECT_CONTRACT_OPEN_BOOK_BY_ID', payload)
}

export const clearList = function ({commit, dispatch}, value) {
    commit('CLEAR_LIST', value)
}

export const refreshList = function ({commit, dispatch}, type) {
    return dispatch('getList', {
        type: type
    })
}

/**
 *
 * @param commit
 * @param dispatch
 * @param type
 * @return {*}
 */
export const refreshListForce = function ({commit, dispatch}, type) {
    dispatch('clearList', type)
    return dispatch('getList', {
        type: type
    })
}

export const getListOne = function ({commit, state, dispatch}, payload) {
    const type = payload.type
    const id = payload.id

    if (!type || !id) {
        return null
    }

    if (!payload.forced) {
        const existing = state.lists[type].find(item => String(item._id) === String(id))
        if (existing) {
            return existing
        }
    }

    if (state.promises[type]) {
        return state.promises[type]
    }

    let promise = client.getListOne(type, id)
        .then(response => {
            if (response.status === 200) {
                response.data.list = response.data.list.map(el => {
                    el.type = response.data.type
                    return el
                })
                commit('ADD_TO_LIST', {
                    type: type,
                    data: response.data.list
                })
                commit('REMOVE_PROMISE', type)
            }
        }).catch(() => {
            commit('REMOVE_PROMISE', type)
        })

    commit('SET_PROMISE', {
        type: type,
        promise: promise
    })

    return promise
}



/**
 *
 * @param commit
 * @param state
 * @param dispatch
 * @param type
 * @param refresh
 * @return {Promise<T>|boolean|*}
 */
export const getList = function ({commit, state, dispatch}, type) {
    if (typeof type === 'object') {
        type = type.type
    } else if (isArray(state.lists[type]) && state.lists[type].length) {
        return false
    }

    if (state.promises[type]) {
        return state.promises[type]
    }

    const cacheInfo = {...state.cacheInfo[type]}
    const lastModified = state.lastModified[type] || null

    const options = {}
    if (lastModified) {
        options.headers = {}
        options.headers['If-Modified-Since'] = lastModified
    }

    let promise = client.getList(type, {cache_info: cacheInfo}, options)
        .then(response => {
            if (response.status === 200) {
                response.data.list = response.data.list.map(el => {
                    el.type = response.data.type
                    return el
                })

                commit('SET_LIST', {
                    type: type,
                    data: response.data.list
                })
                commit('SET_CACHE_INFO', {
                    type: type,
                    data: response.data.cache_info
                })
                commit('SET_LAST_MODIFIED', {
                    type: type,
                    data: response.data.query_date
                })
                commit('REMOVE_PROMISE', type)
            }
        }).catch(() => {
            commit('REMOVE_PROMISE', type)
        })

    commit('SET_PROMISE', {
        type: type,
        promise: promise
    })

    return promise
}
