import { defineComponent, PropType } from 'vue'
import ResourceApprovals from '@/io-modules/approval-workflows/views/resource-approvals/ResourceApprovals.vue'
import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse'
import ApprovalModalType from '@/io-modules/approval-workflows/enums/ApprovalModalType'
import ApprovalAction from '@/io-modules/approval-workflows/enums/ApprovalAction'
import ApprovalModalData from '@/io-modules/approval-workflows/interfaces/ApprovalModalData'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import { invoiceViewStore } from '../../../stores/invoiceView'
import ClientApprovalCard from '../../../../approval-workflows/components/client-approval-card/ClientApprovalCard.vue'
import { mapState } from 'pinia'
import ActionData from '@/io-modules/invoices/interfaces/ActionData'
import InvoiceStatus from '@/io-modules/invoices/enums/InvoiceStatus'
import InvoiceActions from '@/io-modules/invoices/enums/InvoiceActions'
import { mapState as mapStateVuex,  mapGetters as mapGettersVuex } from 'vuex'

export default defineComponent({
    name: 'ApprovalProcess',
    components: {
        ResourceApprovals,
        ClientApprovalCard,
    },
    props: {
        resourceApprovals: {
            type: Object as PropType<ResourceApprovalsResponse>,
            default: null,
        },
        approvalModalData: {
            type: Object as PropType<ApprovalModalData>,
            default: () => ({}),
        },
    },
    emits: ['update:approvalModalData', 'refreshApprovableResourceData', 'submitToClient', 'markAsSubmittedToClient'],
    computed: {
        ...mapState(invoiceSoVStore, ['netInvoiceValueWithoutFactor']),

        ...mapState(invoiceViewStore, ['invoice', 'availableActions', 'invoiceIsDraft']),

        showClientApprovalCard (): boolean {
            return this.invoice.client_approval && this.invoice.client_approval.client?.id && this.invoiceInternallyApproved
        },

        invoiceInternallyApproved (): boolean {
            return InvoiceStatus.PendingInternalApproval !== this.invoice.status && !this.invoiceIsDraft && InvoiceStatus.InternallyRejected !== this.invoice.status
        },

        ...mapStateVuex('appStore', ['authData']),
        ...mapGettersVuex('appStore', ['isOwner']),

        clientCardActions (): ActionData[] {
            const actions = [
                {
                    icon: 'icon-send-2',
                    text: this.$t('Submit to Client'),
                    visible: this.availableActions.some(item => InvoiceActions.SubmitToClient === item.name && item.available),
                    onClick: () => this.$emit('submitToClient'),
                },
                {
                    text: this.$t('Record Client Approval'),
                    variant: InvoiceStatus.InternallyApproved === this.invoice.status ? 'secondary' : 'primary',
                    visible: this.availableActions.some(item => InvoiceActions.RecordClientApproval === item.name && item.available),
                    onClick: () => this.initClientApproval()
                },
                {
                    text: this.$t('Mark as Submitted to Client'),
                    visible: this.availableActions.some(item => InvoiceActions.MarkAsSubmittedToClient === item.name && item.available) && InvoiceStatus.InternallyApproved === this.invoice.status,
                    onClick: () => this.$emit('markAsSubmittedToClient')
                },
            ]

            return actions.filter(item => item.visible)
        },

        hideApprovalWorkflow (): boolean {
            return !!(this.isOwner && this.invoice.is_shared && this.invoice.role === 'observer')
        }
    },
    methods: {
        initApproveModal (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.APPROVE, modal: ApprovalModalType.UserApproval })
        },

        initReviewModal (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.REVIEW, modal: ApprovalModalType.UserApproval })
        },

        initClientApproval (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.APPROVE, modal: ApprovalModalType.ClientApproval })
        },

        initRejectModal (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.REJECT, modal: ApprovalModalType.UserApproval })
        },
    }
})
