<template>
    <div class="io-project-drawings-sheet-grid">
        <LazyGrid
            :filters-schema="[]"
            :endpoint="endpoint"
            :filterSections="filterSections"
        >
            <template #lazy-item="{ data }">
                <LazyGridItem :data="data"/>
            </template>
        </LazyGrid>
    </div>
</template>

<script lang="ts" src="./SheetsGrid.ts"></script>
<style lang="scss" src="./SheetsGrid.scss" scoped></style>
