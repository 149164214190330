import appTypes from '@/base/appTypes.ts'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'
import regionTypes from '@/base/regionTypes.ts'

const variants = {
    [appTypes.TYPE_AC]: PillVariant.WORKSPACE_AC,
    [appTypes.TYPE_DEV]: PillVariant.WORKSPACE_DEV,
    [appTypes.TYPE_GC]: PillVariant.WORKSPACE_GC,
    [appTypes.TYPE_DESIGN]: PillVariant.WORKSPACE_AC,
    [appTypes.TYPE_REP]: PillVariant.WORKSPACE_REP,
    [appTypes.TYPE_OWNER]: PillVariant.WORKSPACE_OWNER,
    [appTypes.TYPE_SUB]: PillVariant.WORKSPACE_SC,
    [appTypes.TYPE_SC]: PillVariant.WORKSPACE_SC,
    [appTypes.TYPE_OFF_SYSTEM]: PillVariant.GRAY_LIGHT,
    [regionTypes.TYPE_US]: PillVariant.GRAY_LIGHT,
    [regionTypes.TYPE_KSA]: PillVariant.GREEN_MEDIUM,
    [regionTypes.TYPE_EUROPE]: PillVariant.INFO_MEDIUM,
}

export default (type: string): PillVariant =>
    variants[type.toLowerCase()] || PillVariant.GRAY_LIGHT
