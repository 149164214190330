import Workflow from '../interfaces/Workflow'

class ApprovalWorkflowsService {
    public static allWorkflowsSequenced (workflows: Workflow[] = []): boolean {
        return workflows.every(workflow => workflow.is_sequence_enabled)
    }

    public static noWorkflowSequenced (workflows: Workflow[] = []): boolean {
        return workflows.every(workflow => !workflow.is_sequence_enabled)
    }
}

export default ApprovalWorkflowsService