<template>
    <div
        v-tooltip="{
            content: tooltipText,
            delay: { show: 2000, hide: 500 },
            visible: showTooltip,
        }"
        data-testid="dropdown-button-new"
        class="io-button-dropdown"
        role="button"
        :class="{ 'io-in-header': inHeader }"
    >
        <i
            v-if="iconMode"
            class="io-button-dropdown__icon"
            :class="[fontAwesomeClass, { 'io-menu-active': showMenu }, { 'io-disabled': disabled }]"
            data-testid="dropdown-button-new-icon-mode"
            @click.stop="toggleMenu"
        />

        <div
            v-else
            class="io-btn"
            :class="[buttonClass, { 'io-disabled': disabled }]"
            data-testid="dropdown-button-new-button-mode"
            @click="toggleMenu"
        >
            <i
                v-if="fontAwesomeByLabelClass.length"
                class="io-btn__left-icon"
                :class="fontAwesomeByLabelClass"
                data-testid="dropdown-button-new-icon-class"
            />

            <span
                v-if="anronIconClass.length"
                class="io-btn__left-icon"
                :class="anronIconClass"
                data-testid="dropdown-button-new-icon-class-anron"
            />

            <span
                v-if="name"
                class="dropdown-button-name"
                data-testid="dropdown-button-name"
            >
                {{ name }}
            </span>

            <slot name="selectedItem"/>

            <span class="icon-chevron-down io-btn__right-icon"/>
        </div>

        <Transition name="dropdown-menu">
            <div
                v-if="showMenu"
                ref="dropdownMenu"
                class="io-button-dropdown__menu"
                :class="menuClass"
                :style="topPosition"
                data-testid="dropdown-button-menu"
                @click="handleMenuClick"
            >
                <slot/>
            </div>
        </Transition>

        <Transition name="dropdown-mask">
            <div
                v-if="showMenu"
                class="io-button-dropdown__mask"
                :class="maskClass"
                data-testid="dropdown-button-mask"
                @click.stop="toggleMenu"
            />
        </Transition>
    </div>
</template>

<script lang="ts" src="./DropdownButtonNew.ts"></script>
<style lang="scss" src="./DropdownButtonNew.scss" scoped></style>
