<template>
    <div v-if="(attachableInvoices && attachableInvoices.length) || filtersApplied" class="io-app-packages-invoices">
        <AppPackageInvoicesFilters :invoices-list="attachableInvoices" :projectLocalID="projectLocalID" @fetchData="fetchAttachedInvoices"/>
        <AppPackageInvoicesTable v-if="attachableInvoices && attachableInvoices.length" :invoices-list="attachableInvoices"/>
        <Placeholder
            v-else-if="filtersApplied"
            icon="icon-file-agreement"
            :title="$t('No invoices available with applied filters')"
        />
    </div>
    <Placeholder
        v-if="(!attachableInvoices || !attachableInvoices.length) && !loading && !filtersApplied"
        icon="icon-file-agreement"
        :title="$t('No Invoices Available')"
    />
</template>

<script lang="ts" src="./AppPackageAttachInvoices.ts"></script>
<style lang="scss" src="./AppPackageAttachInvoices.scss" scoped></style>
