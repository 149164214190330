<template>
    <div class="io-capital-provider">
        <template v-if="!loading && providerFundsList && providerFundsList.length">
            <div class="io-capital-provider__add">
                <IOButton variant="link" icon="icon-plus" @click="toggleAddNewCapitalFundModal">
                    {{ $t('Create New Program Fund') }}
                </IOButton>
            </div>

            <Accordion
                v-for="provider in providerFundsListEdited"
                :open="isSearch || openIfCreatedNewCapFund(provider.id)"
                :show-line="false"
                icon="icon-chevron-down"
                :rotate-degrees="90"
                rotate-direction="left"
                icon-color="gray"
            >
                <template #header-left>
                    <Avatar
                        type="company"
                        :show-full-name="true"
                        :employee-mongo-id="provider.provider.id"
                        :employeeFullName="provider.provider.name"
                    />
                </template>
                <template #header-right>
                    <div class="io-capital-provider__funds-number">
                        <span>{{ provider.provider.funds.length }}</span>
                        {{ $t(provider.provider.funds.length === 1 ? 'Fund' : 'Funds') }}
                    </div>
                </template>
                <template #default>
                    <div class="io-capital-provider__table">
                        <Table
                            :headers="tableHeaders"
                            :items="provider.provider.funds"
                            show-select
                            :check-all-class="headerCheckboxClass(provider.id)"
                            :checkbox-value="provider.provider.isCheckAll"
                            @all-items-selected="toggleCheckAllFunds(provider.id)"
                        >
                            <template #tbody="{ sortedItems }">
                                <tr v-for="fund in sortedItems" class="io-capital-provider__row">
                                    <td class="aleft">
                                        <div class="io-capital-provider__centered">
                                            <Checkbox v-model="fund.isSelected"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="io-capital-provider__centered" @click="goToFundPage(fund.id)">
                                            {{ fund.name }}
                                            <span class="icon-external-link io-capital-provider__icon-link"/>
                                        </div>
                                    </td>
                                    <td>
                                        {{ fund.start_date }}
                                    </td>
                                    <td>
                                        {{ fund.expiration_date }}
                                    </td>
                                    <td class="aright">
                                        {{ $filters.formatCurrency(fund.amount) }}
                                    </td>
                                    <td class="aright">
                                        {{ $filters.formatCurrency(fund.summary.total_projects_unallocated_amount) }}
                                    </td>
                                </tr>
                            </template>
                        </Table>
                    </div>
                </template>
            </Accordion>
        </template>
        <div v-if="!loading && !providerFundsList">
            <Placeholder
                class="io-capital-provider__splash"
                icon="icon-cash"
                :title="$t('Didn\'t find what you are looking for?')"
                :subtitle="$t('You can create a new Program Fund by clicking the button below.')"
                :buttonsCta="buttonsCta"
                @toggleAddNewCapitalFundModal="toggleAddNewCapitalFundModal"
            />
        </div>
        <AddNewCapitalFundModal v-if="addNewCapitalFundModal.show"
                                :create-from-project="true"
                                @close="toggleAddNewCapitalFundModal"/>
    </div>
</template>

<script lang="ts" src="./CapitalProviderFundsList.ts"></script>
<style lang="scss" src="./CapitalProviderFundsList.scss" scoped></style>
