import { JobsiteLocationsStoreInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsStoreInterface.ts'

const jobsiteLocationDefaultState: JobsiteLocationsStoreInterface = {
    jobsiteLocations: [],
    selectedJobsiteLocationsIds: [],
    selectedTreeJobsiteLocationsIds: [],
    isAllLocationsCollapsed: false,
}

export default jobsiteLocationDefaultState

