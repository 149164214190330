<template>
    <IOModal
        icon="icon-export"
        :title="$t('Export Funding Package Details Sorted by {sortType} to PDF', { sortType: fundExportSortTypesReadable[sortType]})"
        :z-index="49"
        @close="$emit('close')"
    >
        <template #default>
            <AlertBox
                class="io-project-funding-export__alert"
                :icon="'icon-info'"
                :dismissible="true"
            >
                {{ $t('Select funds that you would like to export.') }}
            </AlertBox>
            <Table
                v-if="!loading && projectFunds && projectFunds.length"
                :headers="setTableHeaders(HeaderType.EXPORT)"
                :items="projectFunds"
                show-select
                :check-all-class="headerCheckboxClass"
                :checkbox-value="areAllItemsSelected"
                @all-items-selected="areAllItemsSelected ? addCheckboxValue(false) : addCheckboxValue(true)"
            >
                <template #tbody="{ sortedItems }">
                    <tr v-for="fund in sortedItems">
                        <td class="aleft">
                            <div class="io-project-funding-export__checkbox">
                                <Checkbox v-model="fund.isSelected"/>
                            </div>
                        </td>
                        <td>
                            <span>
                                {{ fund.fund_name }}
                                <span
                                    v-if="!fund.fund_has_started && fund.fund_start_date"
                                    v-tooltip.top="`${ $t('This fund will be available on')} ${ fund.fund_start_date }.`"
                                    class="icon-alert-triangle-2 io-project-funds-list__icon-alert"
                                />
                            </span>
                        </td>
                        <td>
                            <Avatar
                                type="company"
                                :employeeMongoId="fund.provider.id"
                                :employeeFullName="fund.provider.name"
                                size="sm"
                                showFullName
                            />
                        </td>
                        <td class="aright">
                            {{ $filters.formatCurrency(fund.fund_amount) }}
                        </td>
                        <td class="aright">
                            {{ $filters.formatCurrency(fund.allocated_amount) }}
                        </td>
                        <td class="aright">
                            {{ `${fund.funding_factor}  %` }}
                        </td>
                        <td class="aright">
                            {{ $filters.formatCurrency(fund.paid_to_date_amount) }}
                        </td>
                    </tr>
                </template>
            </Table>
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton @click="exportPdf()">
                {{ $t('Export to PDF') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./FundsExportTableModal.ts"></script>
<style lang="scss" src="./FundsExportTableModal.scss" scoped></style>
