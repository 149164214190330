import RightModal from '@/components/RightModal/RightModal.vue'
// @ts-ignore
import Draggable from 'vuedraggable'
import ColumnSettings from './interfaces/ColumnSettings'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    name: 'ManageColumnsModal',
    components: {
        RightModal,
        Draggable,
    },
    props: {
        fixedColumns: {
            type: Array as PropType<ColumnSettings[]>,
            required: true,
        },
        scrollableColumns: {
            type: Array as PropType<ColumnSettings[]>,
            required: true,
        },
    },
    emits: ['save-settings', 'close'],
    data () {
        return {
            allFixedVisible: true,
            allScrollableVisible: true,
            fixedColumnsCopy: [],
            scrollableColumnsCopy: [],
            drag: false,
            moveToEmptyList: false,
        }
    },
    computed: {
        scrollableVisibleColumns (): ColumnSettings[] {
            return this.scrollableColumnsCopy.filter(item => item.visible)
        },

        fixedVisibleColumns (): ColumnSettings[] {
            return this.fixedColumnsCopy.filter(item => item.visible)
        },

        saveButtonDisabled (): boolean {
            return this.scrollableVisibleColumns.length < 3 || (!this.fixedVisibleColumns.length && !this.scrollableVisibleColumns.length)
        },

        saveButtonTooltipText (): string {
            if (!this.saveButtonDisabled) {
                return ''
            }
            if (!this.fixedVisibleColumns.length && !this.scrollableVisibleColumns.length) {
                return this.$tc('To save changes, at least one column must be visible.')
            }

            return this.$tc('To save changes, at least three visible columns must be scrollable.')
        },

        allScrollablePartialCheck (): boolean {
            return this.scrollableVisibleColumns.length && this.scrollableVisibleColumns.length !== this.scrollableColumnsCopy.length
        },

        allFixedPartialCheck (): boolean {
            return this.fixedVisibleColumns.length && this.fixedVisibleColumns.length !== this.fixedColumnsCopy.length
        },
    },
    watch: {
        allScrollableVisible (value: boolean): void {
            if (value) {
                this.scrollableColumnsCopy.map(item => item.visible = true)
            } else {
                if (this.scrollableVisibleColumns.length === this.scrollableColumnsCopy.length) {
                    this.scrollableColumnsCopy.map(item => item.visible = false)
                }
            }
        },

        allFixedVisible (value: boolean): void {
            if (value) {
                this.fixedColumnsCopy.map(item => item.visible = true)
            } else {
                if (this.fixedVisibleColumns.length === this.fixedColumnsCopy.length) {
                    this.fixedColumnsCopy.map(item => item.visible = false)
                }
            }
        },

        fixedVisibleColumns (): void {
            this.setupHeaderCheckboxes()
        },

        scrollableVisibleColumns (): void {
            this.setupHeaderCheckboxes()
        },

        drag (value: boolean): void {
            if (!value) {
                this.moveToEmptyList = false
            }
        },
    },
    mounted () {
        this.fixedColumnsCopy = JSON.parse(JSON.stringify(this.fixedColumns))
        this.scrollableColumnsCopy = JSON.parse(JSON.stringify(this.scrollableColumns))
        this.setupHeaderCheckboxes()
    },
    methods: {
        setEscPressed (): void {
            this.closeModal()
        },

        closeModal (): void {
            this.$emit('close')
        },

        saveChanges (): void {
            this.fixedColumnsCopy = this.fixedColumnsCopy.map((item, index) => {
                return {...item, order: index + 1, fixed: true}
            })

            this.scrollableColumnsCopy = this.scrollableColumnsCopy.map((item, index) => {
                return {...item, order: this.fixedColumnsCopy.length + index + 1, fixed: false}
            })

            this.$emit('save-settings', [ ...this.fixedColumnsCopy, ...this.scrollableColumnsCopy ])
        },

        setupHeaderCheckboxes (): void {
            this.allFixedVisible = this.fixedVisibleColumns.length === this.fixedColumnsCopy.length
            this.allScrollableVisible = this.scrollableVisibleColumns.length === this.scrollableColumnsCopy.length
        },

        handleMove (evt: object): void {
            if ('io-no-data-block' === evt.related.className) {
                this.moveToEmptyList = true
            } else {
                this.moveToEmptyList = false
            }
        },
    }
})