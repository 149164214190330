<template>
    <div class="io-invite-modal__search-view">
        <template v-if="isPendingCompany">
            <p>{{ $t('The company you\'ve selected for') }}</p>
            <p><strong>{{ contactFormData.email }}</strong></p>
            <p>{{ $t('has already been created, but it\'s currently pending approval') }}</p>
            <p>{{ $t('Their workspace type is:') }}</p>
        </template>

        <template v-else-if="isOffSystemCompany">
            <p>{{ $t('The company for the following user is off-system') }}</p>
            <p><strong>{{ contactFormData.email }}</strong></p>
            <p>{{ $t('Select the workspace the user should create.') }}</p>
        </template>

        <template v-else>
            <p>{{ $t('Select the workspace the user should create.') }}</p>
        </template>

        <SelectWorkspaceType :componentKey="componentKey" :preselectedWorkspaceType="pendingWorkspaceType"/>
    </div>
</template>
<script lang="ts" src="./WorkspaceTypeForTheNewContact.ts"></script>
