import { HeaderTable } from '@/components/table/HeaderTableInterface'
import i18n from '@/base/i18n'
import { HeaderType } from '@/io-modules/project-funding/enums/ProjectFundingEnums'

export default function setTableHeaders (type: HeaderType, isOwner: boolean = false): HeaderTable[] {
    const defaultHeaders: HeaderTable[] = [
        {
            text: i18n.global.t('Fund Name'),
            value: 'fund_name',
        },
        {
            text: i18n.global.t('Provider'),
            value: 'provider',
            sort: (item) => item.name,
        },
        {
            text: i18n.global.t('Total Fund Amount'),
            value: 'fund_amount',
            sort: (item) => parseFloat(item),
            align: 'end',
        },
        {
            text: i18n.global.t('Allocation to Project'),
            value: 'allocated_amount',
            sort: (item) => parseFloat(item),
            align: 'end',
        },
        {
            text: `% ${ i18n.global.t('of Total Project Funding') }`,
            value: 'funding_factor',
            sort: (item) => parseFloat(item),
            align: 'end',
        },
        {
            text: i18n.global.t('Paid to Date'),
            value: 'paid_to_date_amount',
            sort: (item) => parseFloat(item),
            align: 'end',
        },
        {
            text: '',
            value: '',
            sort: false,
        },
    ]

    if (type === HeaderType.EXPORT) {
        defaultHeaders.splice(-1, 1)

        return defaultHeaders
    } else {
        const fundType = {
            text: i18n.global.t('Fund Type'),
            value: 'fund_type',
        }

        defaultHeaders.splice(1, 0, fundType)

        if (isOwner) {
            defaultHeaders.unshift({
                text: '',
                sort: false,
            })
        }

        return defaultHeaders
    }
}
