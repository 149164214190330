import { defineComponent, PropType } from 'vue'
import { groupBy } from 'lodash'
import { Contact } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'

export default defineComponent ({
    name: 'InvitePreviewVerticalList',

    props: {
        selectedContactsList: {
            type: Array as () => Array<Contact>,
            required: true,
            default: () => []
        },
        contactsBallInCourt: { type: Array as Array<string>, required: false, default: () => [] },
        contactsOnline: { type: Array, required: false, default: () => [] },
        disableInviting: { type: Boolean, default: false },
    },

    emits: [
        'showInviteModal',
        'setBallInCourt',
    ],

    data () {
        return {
            groupedSelectedContactsList: [],
        }
    },

    mounted (): void {
        this.updateGroupedSelectedContactsList()
    },

    watch: {
        'selectedContactsList': {
            handler (): void {
                this.updateGroupedSelectedContactsList()
            },
            deep: true
        }
    },

    methods: {
        showInviteModal (): void {
            this.$emit('showInviteModal', true)
        },

        companyIsOffSystem (contact: Contact): boolean {
            return !contact.workspace?.app_type
        },

        updateGroupedSelectedContactsList (): void {
            this.groupedSelectedContactsList = []
            if (this.selectedContactsList.length) {
                this.selectedContactsList.map(contact => { contact['workspace_name'] = contact.workspace?.name || '' })
                this.groupedSelectedContactsList = groupBy(this.selectedContactsList, 'workspace_name')
            }
        },

        isBallInCourt (contact: Contact): boolean {
            return this.contactsBallInCourt.includes(contact.id)
        },

        setBallInCourt (contact: Contact): void {
            this.$emit('setBallInCourt', contact.id)
        },

        isUserOnline (contact: Contact): boolean {
            if (!this.contactsOnline) {
                return false
            }

            return this.contactsOnline.some(item => item['mongo_id'] === contact.id)
        },

        getContactInvitedStatus (contact: Contact): string {
            return `${ contact.firstname ? contact.firstname : contact.email } ${ this.$t('is online') }`
        },

        isContactArchived (contact: Contact): boolean {
            return contact.is_archived !== undefined ? contact.is_archived : false
        },

        isArchivedOrPendingOrOffSystem (contact: Contact): boolean {
            let isArchived = this.isContactArchived(contact)

            return !contact.is_employee || (contact.is_employee && isArchived) || (contact.is_employee && contact.is_pending)
        },

        isArchivedEmployee (contact: Contact): boolean {
            return contact.is_employee && this.isContactArchived(contact)
        },
    }
})
