import {ApiClient} from '@/api/api'

let client = ApiClient

const baseClient = {
    post (url, data) {
        return client
            .post(url, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
    realJson (url, data) {
        return client
            .realJson(url, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
    put (url, data) {
        return client
            .put(url, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
    get (url, conf) {
        return client
            .get(url, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
    sessionRefresh (conf) {
        return client
            .get(`auth/session-refresh`, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },
    getAuthData (type, data, conf) {
        return client
            .get(`session-status`, conf)
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error))
    },
    delete (url, conf) {
        return client
            .delete(url, conf)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    }
}

export default baseClient
