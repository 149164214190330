export function SET_DIVISIONS (state, val) {
    state['divisions'] = val
}

export function SET_COLLAPSED_DIVISIONS (state, val) {
    state['collapsedDivisions'] = val
}

export function SET_PROCESSING_SETS (state, val) {
    state['processingSets'] = val
}

export function SET_PUBLISHED_SETS (state, val) {
    state['publishedSets'] = val
}

export function SET_ACTIVE_SECTION (state, val) {
    state['activeSection'] = val
}

export function SET_CHECKED_SECTIONS (state, data) {
    state['checkedSections'] = data
}

export function SET_IS_DISABLED_BTN (state, data) {
    state['isDisabledBtn'] = data
}
