<template>
    <PopupModal :width="420" customClass="io-select-stamp" @close="close">
        <template #header>
            <div class="io-popup-modal__title">{{ $t('Select Punch Stamp') }}</div>
            <div class="io-popup-modal__close" @click="close"><i class="icon-cross" /></div>
        </template>

        <template #default>
            <div class="io-f-row">
                <div>
                    <label>{{ $t('Stamp') }}</label>
                    <StampDropdown
                        :selected="stamp"
                        @select="selectStamp"
                        @delete-stamp="deleteStamp"
                    />
                </div>

                <div class="io-stamp-preview" :style="{ borderColor: stamp.color, color: stamp.color }">{{ stamp.code }}</div>
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="close">{{ $t('Cancel') }}</IOButton>
            <IOButton :disabled="!isValid" @click="nextStep">{{ $t('Next') }}</IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./SelectStamp.ts"></script>
<style lang="scss" src="./SelectStamp.scss" scoped></style>
