import { defineComponent } from 'vue'
import { differenceWith, findIndex } from 'lodash'
import { Code } from '@/interfaces/modules/projects/financial-approvals/Code'
import { CostCodeCategory } from '@/interfaces/modules/projects/financial-approvals/CostCodeCategory'


export default defineComponent({
    name: 'GroupCheck',
    props: {
        costCodesSync: { type: Array as () => Code[] },
        lockedCostCodes: { type: Array as () => Code[] },
        category: { type: Object as () => CostCodeCategory },
    },
    computed: {
        costCodes: {
            get (): Code[] {
                return this.costCodesSync
            },
            set (value: any): void {
                this.$emit('update:costCodesSync', value)
            },
        },
        allChecked (): boolean {
            return this.category.codes.every(code => this.isChecked(code.id))
        },

        noneChecked (): boolean {
            return this.category.codes.every(code => !this.isChecked(code.id))
        },

        someChecked (): boolean {
            return !this.allChecked && !this.noneChecked
        },

        hasDisabledCode (): boolean {
            return this.category.codes.some(code => this.isDisabled(code.id))
        },

        lockedCostCodesParsed (): Code[] {
            return differenceWith(this.lockedCostCodes, this.costCodes, (code1, code2) => {
                return code1.cost_code_id === code2.cost_code_id
            })
        },

    },
    methods: {
        toggleCode (codeId: number): void {
            if (this.isChecked(codeId)) {
                this.deleteCode(codeId)
            } else {
                this.addCode(codeId)
            }
        },

        deleteCode (codeId: number): void {
            if (!this.isChecked(codeId)) {
                return
            }
            const index = findIndex(this.costCodes, code => code.cost_code_id === codeId)
            if (index < 0) {
                return
            }
            this.costCodes.splice(index, 1)
        },

        addCode (codeId: number): void {
            if (this.isChecked(codeId)) {
                return
            }
            this.costCodes.push({ cost_code_id: codeId })
        },

        isChecked (codeId: number): boolean {
            return this.costCodes.some(code => code.cost_code_id === codeId)
        },

        isDisabled (codeId: number): boolean {
            return this.lockedCostCodesParsed.some(code => code.cost_code_id === codeId)
        },

        toggleAll (): void {
            if (this.allChecked) {
                this.category.codes.forEach(code => this.deleteCode(code.id))
            } else {
                this.category.codes.forEach(code => this.addCode(code.id))
            }
        },

    }
})
