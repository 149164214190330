<template>
    <tr class="io-single-approval">
        <td>
            <template v-if="showSequencePosition && approverData.sequence_position">
                {{ moment.localeData().ordinal(approverData.sequence_position) }}
            </template>
            <template v-else>-</template>
        </td>

        <td class="io-single-approval__approver">
            <Avatar
                size="sm"
                :employeeMongoId="approverData.user_id"
                :employeeFullName="approverData.full_name || '-'"
                :showFullName="true"
                :companyName="approverData.role_name || '-'"
            />
        </td>

        <td class="io-single-approval__type">
            {{ ApproverAssignmentType.APPROVER === approverData.assignment_type ? $tc('Approver') : $tc('Reviewer') }}
        </td>

        <td class="io-single-approval__limit">
            <template v-if="showApprovalLimit && approverData.limit_amount">
                {{ $t(approvalType) }} {{ $filters.formatCurrency(approverData.limit_amount) }}
            </template>
            <template v-else>-</template>
        </td>

        <td class="io-single-approval__note">
            {{ approvalNoteData?.note || '-' }}
        </td>

        <td class="io-single-approval__files">
            <template v-if="!approvalNoteData?.files?.length">-</template>
            <template v-else>
                <router-link
                    v-for="(file) in approvalNoteData?.files"
                    :key="file._id"
                    class="io-single-approval__files-link"
                    target="_blank"
                    :to="{ name: 'project-documents-show', params: { pid: $route.params.pid, id: file._id } }"
                >
                    <i class="icon-file" /> <span>{{ file.file_name }}</span>
                </router-link>
            </template>
        </td>

        <td class="io-single-approval__status">
            <div class="io-approval-actions">
                <template v-if="approverData.approval">
                    <SingleApprovalStatus
                        :status="approverData.approval.status"
                        :date="approverData.approval.updated_at"
                        :approved-at-text="approvedAtText"
                    />
                </template>
                <template v-else>
                    <IOButton
                        v-if="approverData.actions.override"
                        variant="link"
                        size="small"
                        icon="icon-users"
                        @click="openPopupOverrideApprover"
                    >{{ $t('Override Approver') }}</IOButton>
                    <!-- <IOButton
                        v-if="approverData.actions.remind"
                        variant="link"
                        size="small"
                        icon="icon-send-2"
                        @click="handleSendReminder"
                    >{{ $t('Send Reminder') }}</IOButton> -->
                    <IOButton
                        v-if="approverData.actions.reject"
                        variant="secondary"
                        size="small"
                        @click="reject"
                    >{{ $t('Reject') }}</IOButton>
                    <IOButton
                        v-if="approverData.actions.approve"
                        size="small"
                        @click="approve"
                    >
                        {{ approveActionText }}
                    </IOButton>
                    <div
                        v-if="waitingForApproval && !approverData.actions.approve && !approverData.actions.override && !approverData.actions.remind"
                        class="io-approval-action io-approval-waiting"
                    >
                        <i class="icon-time"></i>
                        <span>{{ $t('Waiting for Approval') }}</span>
                    </div>
                </template>
            </div>
        </td>
    </tr>
</template>

<script lang="ts" src="./SingleApproval.ts"></script>
<style lang="scss" src="./SingleApproval.scss" scoped></style>
