export function SET_IS_ABLE_TO_COLLAPSE_SOV (state, value) {
    state.isAbleToCollapseSoV = value
}

export function SET_COLLAPSE_SOV (state, value) {
    state.collapseAllSoV = value
}

export function SET_EXPAND_SOV (state, value) {
    state.expandAllSoV = value
}

export function SET_ROWS_TO_EXPAND (state, value) {
    state.rowsToExpand = value
}

export function SET_ALL_ROWS_TO_EXPAND (state, value) {
    state.allRowsToExpand = value
}
