var widgets = {
    employeePrivileges: {
        id: 'EmployeePrivileges',
    },
    attachments: {
        id: 'Attachments',
    },
    fieldticketsHistory: {
        id: 'FieldTicketsHistory',
    },
    documentsUploader: {
        id: 'DocumentsUploader',
    },
    usersRateUpdater: {
        id: 'UsersRateUpdater',
        data: 'UsersRateUpdater',
    },
    fieldTicketsItems: {
        id: 'ComboTable',
        data: 'FieldTicketsItems',
    },
    federalHolidaysView: {
        id: 'FederalHolidaysView',
    },
    federalHolidaysCalendar: {
        id: 'FederalHolidaysCalendar',
    },
    employeeRulesItems: {
        id: 'ComboTable',
        data: 'EmployeeRulesItems',
    },
    employeeRulesItemsView: {
        id: 'EmployeeRulesItemsView',
    },
    employeeProjectsView: {
        id: 'EmployeeProjects/EmployeeProjects',
        data: 'EmployeeProjectsView',
    },
    exportQB: {
        id: 'ExportQB',
    },
    exportCSV: {
        id: 'ExportCSV',
    },
    exportCSVPanel: {
        id: 'ExportCSVPanel',
    },
    employeeHistoryBio: {
        id: 'HistoryComponent',
        data: 'employeeHistoryBio',
    },
    employeeHistoryHR: {
        id: 'HistoryComponent',
        data: 'employeeHistoryHR',
    },
    employeeHistoryDetails: {
        id: 'HistoryComponent',
        data: 'employeeHistoryDetails',
    },
    employeeBusinnesUnits: {
        id: 'EmployeeBusinessUnitsComponent',
        data: 'employeeBusinnesUnits',
    },
    rfiTimeline: {
        id: 'RfiTimeline',
    },
    rfiIssuedTo: {
        id: 'RfiIssuedTo',
    },
    mrHistory: {
        id: 'MrHistory',
    },
    feasibilityFields: {
        id: 'FeasibilityFields',
    },
    modalLayout: {
        id: 'modalLayout',
    },
    myAccountNotifications: {
        id: 'MyAccountNotifications',
    },
    myIntegrationMarketplace: {
        id: 'MyIntegrationMarketplace',
    },
    customFields: {
        id: 'CustomFields',
    },
}

export default widgets
