import { defineComponent } from 'vue'

export default defineComponent({
    emit: ['update:modelValue'],
    props: {
        title: { type: String, required: true },
        modelValue: { type: Boolean, required: true }
    },
    computed: {
        isChecked (): boolean {
            return this.modelValue === true
        }
    },
    methods: {
        change (evt: Event): void {
            this.$emit('update:modelValue', (evt.target as HTMLInputElement).checked)
        }
    }
})
