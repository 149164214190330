import { isElement, isEmpty } from 'lodash'

let DomHandler = {
    innerWidth (el: HTMLElement): number {
        if (el) {
            let width = el.offsetWidth
            let style = getComputedStyle(el)

            width += parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)

            return width
        }

        return 0
    },

    width (el: HTMLElement): number {
        if (el) {
            let width = el.offsetWidth
            let style = getComputedStyle(el)

            width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight)

            return width
        }

        return 0
    },

    getWindowScrollTop (): number {
        let doc = document.documentElement

        return (window.scrollY || doc.scrollTop) - (doc.clientTop || 0)
    },

    getWindowScrollLeft (): number {
        let doc = document.documentElement

        return (window.scrollX || doc.scrollLeft) - (doc.clientLeft || 0)
    },

    getOuterWidth (el: HTMLElement, margin?: boolean): number {
        if (el) {
            let width = el.offsetWidth

            if (margin) {
                let style = getComputedStyle(el)

                width += parseFloat(style.marginLeft) + parseFloat(style.marginRight)
            }

            return width
        }

        return 0
    },

    getViewport (): { height: number, width: number } {
        let win = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            width = win.innerWidth || e.clientWidth || g.clientWidth,
            height = win.innerHeight || e.clientHeight || g.clientHeight

        return { width, height }
    },

    index (el: HTMLElement): number {
        if (el) {
            let children = el.parentNode.childNodes
            let num = 0

            for (let i = 0; i < children.length; i++) {
                if (children[i] === el) return num
                if (children[i].nodeType === 1) num++
            }
        }

        return -1
    },

    addClass (el: HTMLElement, className: string): void {
        if (el && className) {
            if (el.classList) el.classList.add(className)
            else el.className += ' ' + className
        }
    },

    removeClass (element: HTMLElement, className: string): void {
        if (element && className) {
            if (element.classList) element.classList.remove(className)
            else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
        }
    },

    hasClass (element: HTMLElement, className: string): boolean {
        if (element) {
            if (element.classList) return element.classList.contains(className)
            else return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className)
        }

        return false
    },

    find (element: HTMLElement, selector: string): NodeListOf<HTMLElement> | any[] {
        return isElement(element) ? element.querySelectorAll(selector) : []
    },

    findSingle (element: HTMLElement, selector: string): HTMLElement {
        return isElement(element) ? element.querySelector(selector) : null
    },

    absolutePosition (element: HTMLElement, target: HTMLElement): void {
        if (element) {
            let elementDimensions = element.offsetParent ? {
                width: element.offsetWidth,
                height: element.offsetHeight,
            } : this.getHiddenElementDimensions(element)
            let elementOuterHeight = elementDimensions.height
            let elementOuterWidth = elementDimensions.width
            let targetOuterHeight = target.offsetHeight
            let targetOuterWidth = target.offsetWidth
            let targetOffset = target.getBoundingClientRect()
            let windowScrollTop = this.getWindowScrollTop()
            let windowScrollLeft = this.getWindowScrollLeft()
            let viewport = this.getViewport()
            let top, left

            if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
                top = targetOffset.top + windowScrollTop - elementOuterHeight
                element.style.transformOrigin = 'bottom'

                if (top < 0) {
                    top = windowScrollTop
                }
            } else {
                top = targetOuterHeight + targetOffset.top + windowScrollTop
                element.style.transformOrigin = 'top'
            }

            if (targetOffset.left + elementOuterWidth > viewport.width) left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth)
            else left = targetOffset.left + windowScrollLeft

            element.style.top = top + 'px'
            element.style.left = left + 'px'
        }
    },

    getParents (element: HTMLElement, parents = []) {
        return element.parentNode === null ? parents : this.getParents(<HTMLElement>element.parentNode, parents.concat([element.parentNode]))
    },

    getScrollableParents (element: HTMLElement) {
        let scrollableParents = []

        if (element) {
            let parents = this.getParents(element)
            const overflowRegex = /(auto|scroll)/

            const overflowCheck = (node) => {
                let styleDeclaration = window.getComputedStyle(node, null)

                return overflowRegex.test(styleDeclaration.getPropertyValue('overflow')) || overflowRegex.test(styleDeclaration.getPropertyValue('overflowX')) || overflowRegex.test(styleDeclaration.getPropertyValue('overflowY'))
            }

            for (let parent of parents) {
                let scrollSelectors = parent.nodeType === 1 && parent.dataset.scrollselectors

                if (scrollSelectors) {
                    let selectors = scrollSelectors.split(',')

                    for (let selector of selectors) {
                        let el = this.findSingle(parent, selector)

                        if (el && overflowCheck(el)) {
                            scrollableParents.push(el)
                        }
                    }
                }

                if (parent.nodeType !== 9 && overflowCheck(parent)) {
                    scrollableParents.push(parent)
                }
            }
        }

        return scrollableParents
    },

    getHiddenElementDimensions (element: HTMLElement): { width: number, height: number } | number {
        if (element) {
            let dimensions = {} as { width: number, height: number }

            element.style.visibility = 'hidden'
            element.style.display = 'block'
            dimensions.width = element.offsetWidth
            dimensions.height = element.offsetHeight
            element.style.display = 'none'
            element.style.visibility = 'visible'

            return dimensions
        }

        return 0
    },

    fadeIn (element: HTMLElement, duration: number): void {
        if (element) {
            element.style.opacity = '0'

            let last = +new Date()
            let opacity = 0

            let tick = function () {
                opacity = +element.style.opacity + (new Date().getTime() - last) / duration
                element.style.opacity = String(last = +new Date())

                if (+opacity < 1) {
                    (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16)
                }
            }

            tick()
        }
    },

    fadeOut (element: HTMLElement, ms: number): void {
        if (element) {
            let opacity = 1,
                interval = 50,
                duration = ms,
                gap = interval / duration

            let fading = setInterval(() => {
                opacity -= gap

                if (opacity <= 0) {
                    opacity = 0
                    clearInterval(fading)
                }

                element.style.opacity = String(opacity)
            }, interval)
        }
    },

    appendChild (element: HTMLElement, target: HTMLElement): void {
        if (isElement(target)) {
            target.appendChild(element)
        } else if (target.el && target.elElement) {
            target.elElement.appendChild(element)
        } else {
            throw new Error('Cannot append ' + target + ' to ' + element)
        }
    },

    focus (el: HTMLElement, options?: { preventScroll: boolean, focusVisible: boolean }): void {
        el && document.activeElement !== el && el.focus(options)
    },

    isTouchDevice (): boolean {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    },
}

class ConnectedOverlayScrollHandler {
    private element: HTMLElement
    private listener: () => void
    private scrollableParents: HTMLElement[]

    constructor (element: HTMLElement, listener = (...args): void => {
    }) {
        this.element = element
        this.listener = listener
    }

    bindScrollListener (): void {
        this.scrollableParents = DomHandler.getScrollableParents(this.element)

        for (let i = 0; i < this.scrollableParents.length; i++) {
            this.scrollableParents[i].addEventListener('scroll', this.listener)
        }
    }

    unbindScrollListener (): void {
        if (this.scrollableParents) {
            for (let i = 0; i < this.scrollableParents.length; i++) {
                this.scrollableParents[i].removeEventListener('scroll', this.listener)
            }
        }
    }

    destroy (): void {
        this.unbindScrollListener()
        this.element = null
        this.listener = null
        this.scrollableParents = null
    }
}

let ObjectUtils = {
    resolveFieldData (data, field) {
        if (data && Object.keys(data).length && field) {
            if (this.isFunction(field)) {
                return field(data)
            } else if (field.indexOf('.') === -1) {
                return data[field]
            } else {
                let fields = field.split('.')
                let value = data

                for (let i = 0, len = fields.length; i < len; ++i) {
                    if (value === null) {
                        return null
                    }

                    value = value[fields[i]]
                }

                return value
            }
        } else {
            return null
        }
    },

    isFunction (obj) {
        return !!(obj && obj.constructor && obj.call && obj.apply)
    },

    getItemValue (obj, ...params) {
        return this.isFunction(obj) ? obj(...params) : obj
    },

    filter (value: string, fields: [], filterValue: string): [] {
        let filteredItems = []

        if (value) {
            for (let item of value) {
                for (let field of fields) {
                    if (String(this.resolveFieldData(item, field)).toLowerCase().indexOf(filterValue.toLowerCase()) > -1) {
                        filteredItems.push(item)
                        break
                    }
                }
            }
        }

        return filteredItems
    },

    findLastIndex (arr, callback: Function): number {
        let index = -1

        if (!isEmpty(arr)) {
            try {
                index = arr.findLastIndex(callback)
            } catch {
                index = arr.lastIndexOf([...arr].reverse().find(callback))
            }
        }

        return index
    },
}

let lastId = 0

function UniqueComponentId (prefix: string = 'c_id_'): string {
    lastId++

    return `${ prefix }${ lastId }`
}

function handler () {
    let zIndexes = []

    const generateZIndex = (key: string, autoZIndex: boolean, baseZIndex: number = 999): number => {
        const lastZIndex = getLastZIndex(key, autoZIndex, baseZIndex)
        const newZIndex = lastZIndex.value + (lastZIndex.key === key ? 0 : baseZIndex) + 1

        zIndexes.push({ key, value: newZIndex })

        return newZIndex
    }

    const revertZIndex = (zIndex): void => {
        zIndexes = zIndexes.filter((obj) => obj.value !== zIndex)
    }

    const getLastZIndex = (key: string, autoZIndex: boolean, baseZIndex: number = 0): {
        key: string,
        value: number
    } => {
        return [...zIndexes].reverse().find((obj) => (autoZIndex ? true : obj.key === key)) || {
            key,
            value: baseZIndex,
        }
    }

    const getZIndex = (el: HTMLElement): number => {
        return el ? parseInt(el.style.zIndex, 10) || 0 : 0
    }

    return {
        get: getZIndex,
        set: (key: string, el: HTMLElement, baseZIndex: number) => {
            if (el) {
                el.style.zIndex = String(generateZIndex(key, true, baseZIndex))
            }
        },
        clear: (el: HTMLElement): void => {
            if (el) {
                revertZIndex(getZIndex(el))
                el.style.zIndex = ''
            }
        },
    }
}

let ZIndexUtils = handler()

export { ConnectedOverlayScrollHandler, DomHandler, ObjectUtils, UniqueComponentId, ZIndexUtils }
