<template>
    <div class="io-reply">
        <div class="io-reply__header">
            <div class="io-left-side">
                <div class="io-reply-creator">
                    <div class="io-reply-creator__avatar">
                        <template v-if="data.created_by_full_name.imageExist">
                            <img :src="getApiUrlAvatar(data.created_by_full_name.id)"/>
                        </template>
                        <template v-else>
                            <Initials :fullName="data.created_by_full_name.name"/>
                        </template>
                    </div>
                    <div class="io-reply-creator__user-info">
                        <div>{{ data.created_by_full_name.name }}</div>
                        <div>{{ data.created_by_full_name.position }}</div>
                    </div>
                </div>
            </div>

            <div class="io-right-side">
                <span>{{ $filters.parseDate(data.created_at) }}</span>
                <DropdownButtonNew
                    v-if="isCreator"
                    :attach-above="isLastReply"
                    :icon-mode="true"
                    :disabled="comment.is_resolved"
                    font-awesome-class="icon-more-vert-2"
                >
                    <div class="io-single-option" @click="openEditMode">{{ $t('Edit') }}</div>
                    <div class="io-single-option" @click="deleteReply">{{ $t('Delete') }}</div>
                </DropdownButtonNew>
            </div>
        </div>

        <template v-if="editMode">
            <Mentions
                :key="`reply-${ data.id }`"
                :attached_employees_ids="mentionUsers"
                :custom-people-list="mentionUsers"
                :attach-below="true"
                v-model="editedComment"
                @update-mentions="mentionsIds = $event"
            />
            <div class="io-buttons">
                <IOButton variant="secondary" size="small" @click="cancelEditMode">{{ $t('Cancel') }}</IOButton>
                <IOButton :disabled="!editedComment" size="small" @click="saveComment">{{ $t('Save') }}</IOButton>
            </div>
        </template>

        <div
            v-else
            ref="replyText"
            class="io-reply__text"
            :class="{'io-collapsed': commentCollapsed && isLongReply}"
            v-html="data.comment"
        >
        </div>

        <template v-if="isLongReply">
            <div class="io-toggle-collapse" @click="toggleCollapse">
                <template v-if="commentCollapsed">
                    <i class="icon-chevron-down"/>
                    <span>{{ $t('Show more') }}</span>
                </template>

                <template v-else>
                    <i class="icon-chevron-up"/>
                    <span>{{ $t('Show less') }}</span>
                </template>
            </div>
        </template>
    </div>
</template>

<script lang="ts" src="./Reply.ts"></script>
<style lang="scss" src="./Reply.scss" scoped></style>
