import { defineComponent, PropType } from 'vue'

import { HeaderTable } from '@/components/table/HeaderTableInterface.ts'
import CellType from '@/components/table/CellType.ts'
import BudgetCostCostCodeSelectSettings from '@/components/expandable-table/interfaces/BudgetCostCodeSelectSettings.ts'
import { ButtonVariant } from '@/components/atoms/IOButton/IOButton.ts'

import DropdownButtonMultilayer from '@/components/atoms/DropdownButtonMultilayer/DropdownButtonMultilayer.vue'
import BudgetCostCodeSelect from '@/components/budget-cost-code-select/BudgetCostCodeSelect.vue'
import TruncatedText from '@/components/truncated-text/TruncatedText.vue'

export default defineComponent({
    components: {
        DropdownButtonMultilayer,
        BudgetCostCodeSelect,
        TruncatedText,
    },
    inject: ['itemInEditId', 'haveALotOfItems'],
    props: {
        headers: {
            type: Array as PropType<HeaderTable[]>,
            default: () => [],
        },
        item: {
            type: Object,
            default: () => ({}),
        },
        nestedRowsIndicator: {
            type: String,
            default: '',
        },
        levelIndex: { type: [Number, String] },
        parentLevelIndex: { type: [Number, String] },
        parentItem: {
            type: Object,
            default: () => ({}),
        },
        index: { type: Number },
        classes: { type: String, default: '' },
        isExpanded: { type: Boolean, default: false },
        injected: { type: Boolean, default: false },
        topShadow: {
            type: Boolean,
            default: false,
        },
        bottomShadow: {
            type: Boolean,
            default: false,
        },
        itemIdentifier: String,
    },
    emits: ['clickEditItem', 'inputClicked'],
    data () {
        return {
            CellType,
            ButtonVariant,
            multiselectSearchValue: '',
        }
    },
    computed: {
        hasNestedRows (): boolean {
            return this.item[this.nestedRowsIndicator] && this.item[this.nestedRowsIndicator].length
        },

        hasError (): boolean {
            return this.item.errors && Object.keys(this.item.errors).length > 0
        },

        getRowClasses (): string {
            const injectedClasses = this.injected ? 'io-expandable-table-row--injected' : ''
            const shadowClasses = `${ this.topShadow ? 'io-top-shadow' : '' } ${ this.bottomShadow ? 'io-bottom-shadow' : '' }`
            const errorClasses = `${ this.hasError ? 'io-expandable-table-row--error' : '' }`
            const itemExtraClasses = this.item.classes || ''

            return `${ this.classes } ${ this.rowBackgroundColorClass } ${ injectedClasses } ${ shadowClasses } ${ itemExtraClasses } ${ errorClasses }`
        },

        rowBackgroundColorClass (): string {
            if ('number' === typeof this.levelIndex) {
                return 'io-white'
            }
            const temp = this.levelIndex.split('.')
            if (2 === temp.length) {
                return 'io-lighten-bg-2'
            }
            if (3 === temp.length) {
                return 'io-lighten-bg-1'
            }
            if (4 === temp.length) {
                return 'io-lighten-bg-3'
            }
        },

        indentationStyles (): string {
            if ('number' === typeof this.levelIndex) {
                return ''
            }
            const temp = this.levelIndex.split('.')
            if (2 === temp.length) {
                return 'margin-left: 24px;'
            }
            if (3 === temp.length) {
                return 'margin-left: 48px;'
            }
            if (4 === temp.length) {
                return 'margin-left: 72px;'
            }
        },
    },
    watch: {
        headers (newVal, prevVal) {
            if (!prevVal.length && newVal.length) {
                const header = newVal.find(item => item.onInit instanceof Function)
                if (header) {
                    header.onInit(this.item, this.levelPrefix(header))
                }
            }
        },
    },
    mounted () {
        const header = this.headers.find(item => item.onInit instanceof Function)
        if (header) {
            header.onInit(this.item, this.levelPrefix(header))
        }
    },
    methods: {
        hasPostfixAction (header: HeaderTable): boolean {
            if (header.hasPostfixAction instanceof Function) {
                return header.hasPostfixAction(this.item)
            }

            return false
        },

        postfixAction (header: HeaderTable): object {
            if (header.postfixAction instanceof Function) {
                return header.postfixAction(this.item)
            }
        },

        colspan (header: HeaderTable): number {
            if (header.colspan instanceof Function) {
                return header.colspan(this.item)
            }

            return 1
        },

        separator (header: HeaderTable): string {
            if (header.customSeparator) {
                return header.customSeparator
            }

            return 'number' === typeof this.levelIndex ? ':' : ' -'
        },

        hidden (header: HeaderTable): boolean {
            if (header.hidden instanceof Function && header.hidden(this.item)) {
                return header.hidden(this.item)
            }

            return false
        },

        invisible (header: HeaderTable): boolean {
            if (header.invisible instanceof Function) {
                return header.invisible(this.item)
            }

            return header.invisible || false
        },

        disableSign (header: HeaderTable): boolean {
            if (header.disableSign instanceof Function) {
                return header.disableSign(this.item)
            }

            return header.disableSign || false
        },

        getClasses (header: HeaderTable): string {
            if (header.classes instanceof Function) {
                return header.classes(this.item)
            }

            return header.classes || ''
        },

        levelPrefix (header: HeaderTable): string {
            if (!header.showLevelPrefix || (header.showLevelPrefix instanceof Function && !header.showLevelPrefix(this.item))) {
                return ''
            }

            if (header.customLevelPrefix instanceof Function) {
                return header.customLevelPrefix(this.index, this.parentItem, this.parentLevelIndex, this.item) || `${ this.levelIndex }${ this.separator(header) }`
            }

            return `${ this.levelIndex }${ this.separator(header) }`
        },

        cellValue (header: HeaderTable): string {
            let value = ''
            if (this.injected && header.injectValue && header.injectValue instanceof Function) {
                value = header.injectValue(this.item) || ''
            } else if (header.customValue instanceof Function) {
                value = header.customValue(this.item)
            } else if (header.value) {
                value = header.filter ? this.$filters[header.filter](header.value) : header.value
            }

            if (header.inCellLevelPrefix === false) {
                return value
            }
            return this.levelPrefix(header) && typeof value === 'string' ? `${ this.levelPrefix(header) } ${ value }` : value
        },

        cellCostCodeValue (header: HeaderTable): string {
            if (header.costCodeCustomValue instanceof Function && this.item[this.itemIdentifier] !== this.itemInEditId) {
                return header.costCodeCustomValue(this.item)
            }

            return this.cellValue(header)
        },

        cellSubValue (header: HeaderTable): string {
            let value = ''
            if (header.subValue instanceof Function) {
                value = header.subValue(this.item)
            } else if (header.subValue) {
                value = header.subValue
            }

            return value
        },

        cellErrorMessage (header: HeaderTable): string {
            if (header.errorMessage instanceof Function) {
                return header.errorMessage(this.item)
            }

            return header.errorMessage
        },

        cellModelValue (header: HeaderTable): string {
            if (header.nestedKeyName) {
                return this.item[header.field][header.nestedKeyName]
            }

            if (header.field instanceof Function) {
                return this.item[header.field(this.item)]
            }

            return this.item[header.field]
        },

        minValue (header: HeaderTable): number {
            if (header.minValue) {
                return header.minValue instanceof Function ? header.minValue(this.item) : header.minValue
            }

            return null
        },

        maxValue (header: HeaderTable): number {
            if (header.maxValue) {
                return header.maxValue instanceof Function ? header.maxValue(this.item) : header.maxValue
            }

            return null
        },

        sign (header: HeaderTable): string {
            if (header.sign) {
                return header.sign instanceof Function ? header.sign(this.item) : header.sign
            }

            return undefined
        },

        placeholder (header: HeaderTable, item: object): string {
            if (header.placeholder instanceof Function) {
                return header.placeholder(item)
            }

            return header.placeholder ?? ''
        },

        itemInfo (header: HeaderTable, item: object): string {
            if (header.cellInfo) {
                return (header.cellInfo instanceof Function ? header.cellInfo(item) : header.cellInfo)
            }

            return ''
        },

        setSmallPadding (header: HeaderTable): boolean {
            if (header.hasSmallPadding) {
                return header.hasSmallPadding
            }

            return header.editable(this.item) && (
                CellType.InputNumInt === header.valueType
                || CellType.InputNum === header.valueType
                || CellType.Input === header.valueType
                || CellType.Dropdown === header.valueType
            )
        },

        showLink (header: HeaderTable): boolean {
            return header.link instanceof Function && header.link(this.item)
        },

        showLeftIcon (header: HeaderTable): boolean {
            return Boolean(header.leftIcons instanceof Function && header.leftIcons(this.item))
        },

        showRightIcon (header: HeaderTable): boolean {
            return Boolean(header.rightIcons instanceof Function && header.rightIcons(this.item))
        },

        updateValue (header: HeaderTable, data: number | string): void {
            if (header.onValueChange instanceof Function) {
                header.onValueChange(this.item, header.field, data)
            } else {
                this.item[header.field] = data
            }
        },

        openLink (linkData: object): void {
            const routeData = this.$router.resolve(linkData)
            window.open(routeData.href, '_blank')
        },

        showLevelPrefix (header: HeaderTable): boolean {
            return header.showLevelPrefix instanceof Function ? header.showLevelPrefix(this.item) : true
        },

        cellValueType (header: HeaderTable): string {
            if (header.valueType instanceof Function) {
                return header.valueType(this.item)
            }

            return header.valueType
        },

        headerActions (header: HeaderTable): Array<object> {
            if (header.actions instanceof Function) {
                return header.actions(this.item)
            }

            return header.actions
        },

        multiselectOptions (header: HeaderTable): Array<object> {
            if (header.options instanceof Function) {
                return header.options(this.item)
            }

            return header.options
        },

        multiselectSettings (header: HeaderTable): object {
            if (header.settings instanceof Function) {
                return header.settings(this.item)
            }

            return header.settings
        },

        addTag (header: HeaderTable, tag: string): void {
            if (header.addTag instanceof Function) {
                header.addTag(this.item, tag)
            }
        },

        headerAvatar (header: HeaderTable): string {
            if (header.avatar instanceof Function) {
                return header.avatar(this.item)
            }

            return header.avatar
        },
        textStatusPill (header: HeaderTable): string {
            if (header.textStatusPill instanceof Function) {
                return header.textStatusPill(this.item)
            }

            return header.textStatusPill
        },
        costCodeSelectSettings (header: HeaderTable): BudgetCostCostCodeSelectSettings {
            if (header.costCodeSelectSettings instanceof Function) {
                return header.costCodeSelectSettings(this.item)
            }

            return header.costCodeSelectSettings
        },
        handleMultiselectSearchChange (value: string): void {
            this.multiselectSearchValue = value
        },
        numberCharsLimit (header: HeaderTable): number {
            if (header.numberCharsLimit instanceof Function) {
                return header.numberCharsLimit(this.item)
            }

            return header.numberCharsLimit
        },
        handleClickEditItem (item: object): void {
            this.$emit('clickEditItem', item[this.itemIdentifier])
        },
        emitInputClick (item: object): void {
            this.$emit('inputClicked', item[this.itemIdentifier], item)
        },

        fieldNumericIntOptions(header: HeaderTable): object {
            return {
                numeralPositiveOnly: header.inputOptions?.numeralPositiveOnly ?? false,
                numericOnly: header.inputOptions?.numericOnly ?? false,
                nonNumericAsZero: header.inputOptions?.nonNumericAsZero ?? false,
                ...(header.inputOptions || {})
            }
        }
    },
})
