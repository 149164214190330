<template>
    <PopupModal
        :width="1150"
        :disableEscClose="true"
        :disableOutsideClose="true"
        class="io-jobsite-locations-modal-wrapper"
    >
        <template #header>
            <div class="io-popup-modal__icon"><span class="icon-pin info"></span></div>
            <div class="io-popup-modal__title" data-testid="modal-title">{{ $t('Project Site Addresses') }}</div>
            <div class="io-popup-modal__close" @click.stop="onClose"><span class="icon-cross"></span></div>
        </template>

        <template #default>
            <div class="io-jobsite-locations-modal">
                <div class="io-jobsite-locations-modal__main">
                    <div class="io-jobsite-locations-modal__main-header">
                        <SearchInputNew
                            :placeholder="$t('Search project site address...')"
                            :loader="false"
                            :search-val="searchVal"
                            @search-changed="onSearchValueChange"
                        />


                        <IOSwitcher
                            :title="isShowArchived ? $t('Show Archived') : $t('Hide Archived')"
                            v-model="isShowArchived"
                        />

                        <IOButton variant="link" size="small" :icon="collapseButtonTextIcon" @click="onClickCollapseButton(true)">{{ collapseButtonText }}</IOButton>
                    </div>
                    <div class="io-jobsite-locations-modal__main-content">
                        <template v-if="!isFetching && jobsiteLocations.length">
                            <JobsiteLocationsAccordion
                                v-for="(jobsiteLocation, idx) in filteredLocations"
                                :key="idx"
                                :jobsite-location="jobsiteLocation"
                                :is-disabled="disabledTreesParentsIds.includes(jobsiteLocation.id)"
                                :is-list-updated="isListUpdated"
                            />
                        </template>

                        <div v-if="!isFetching && !jobsiteLocations.length" class="io-jobsite-locations-modal__placeholder">{{ $t('No records...') }}</div>

                        <div v-if="isFetching" class="io-jobsite-locations-modal__placeholder" data-testid="data-loading">{{ $t('Fetching data...') }}</div>
                    </div>
                </div>

                <div class="io-jobsite-locations-modal__aside">
                    <div class="io-jobsite-locations-modal__aside-header">{{ $t('Selected') }}</div>

                    <div class="io-jobsite-locations-modal__aside-content">
                        <SelectedJobsiteLocations
                            v-if="!isFetching && selectedJobsiteLocationsIds.length"
                            :selected-paths="selectedLocationsPath"
                            :is-skinny-mode="false"
                            :is-modal-view="true"
                        />

                        <AsidePlaceholder v-else />
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click.stop="onClose">{{ $t('Close') }}</IOButton>
            <IOButton @click="onSave">{{ $t('Save') }}</IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./JobsiteLocationsModal.ts"></script>
<style lang="scss" src="./JobsiteLocationsModal.scss" scoped></style>
