<template>
    <div
        class="io-design-system io-sidebar"
        data-testid="io-sidebar-component"
        :class="{ 'io-sidebar--collapsed': collapsed, 'io-sidebar--collapsible': collapsible }"
        :style="!collapsed && { width: `${ width }px` }"
    >
        <div class="io-sidebar__header">
            <IOButton
                v-if="collapsible"
                icon="icon-align-bottom"
                variant="icon"
                class="io-sidebar__toggle-btn"
                data-testid="toggle"
                size="small"
                @click="collapsed ? show() : hide()"
            />

            <ul v-if="!collapsed" class="io-sidebar__tabs" data-testid="tabs">
                <li
                    v-for="tab in tabs"
                    :key="tab.name"
                    class="io-sidebar__tab"
                    data-testid="tab"
                    :class="{ 'io-sidebar__tab--active': $route.query[queryTabName] === tab.name }"
                    @click="$router.replace({
                        query: {
                            ...(preserveExternalRouteQueries && $route.query),
                            [queryTabName]: tab.name,
                            [querySectionName]: $route.query[querySectionName] || tab.sections?.[0]?.name,
                        },
                    })"
                >
                    {{ tab.title }}
                </li>
            </ul>
        </div>

        <div v-if="!collapsed" class="io-sidebar__body">
            <div class="io-sidebar__tab-content" data-testid="tab-content">
                <slot :name="activeTab.name" />
            </div>

            <template v-if="activeTab.sections?.length">
                <ul class="io-sidebar__sections" data-testid="sections">
                    <li
                        v-for="section in activeTab.sections"
                        :key="section.name"
                        class="io-sidebar__section"
                        :class="{ 'io-sidebar__section--active': activeSection.name === section.name }"
                        @mouseenter="hoveredSectionName = section.name"
                        @mouseleave="hoveredSectionName = null"
                        @click="$router.replace({
                            query: {
                                [queryTabName]: $route.query[queryTabName],
                                [querySectionName]: section.name,
                            },
                        })"
                    >
                        <i :class="section.icon" />

                        <span
                            class="io-sidebar__section-name"
                            :class="{ 'io-sidebar__section-name--active': [activeSection.name, hoveredSectionName].includes(section.name) }"
                        >
                            {{ section.title }}
                        </span>
                    </li>
                </ul>

                <div class="io-sidebar__section-content" data-testid="section-content">
                    <slot :name="activeSection.name" />
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" src="./IOSidebar.ts"></script>
<style lang="scss" src="./IOSidebar.scss" scoped></style>
