import * as actions from './actions'
import * as mutations from './mutations'

export default {
    namespaced: true,
    namespace: 'todo/',
    state () {
        return {
            showAddFromTemplateModal: false,
            loading: false,
            saving: false,
            saved: false,
            projects: null,
            projectsList: null,
            employees: null,
            category: 'Today',
            editMode: false,
            fields: null,
            extraData: null,
            currentTask: null,
            currentTaskAdditionalData: null,
            currentTaskLocked: true,
            currentTaskLoading: true,
            currentTaskCopy: null,
            deletedTaskId: null,
            rendered: false,
            kanbanViewMode: 'custom',
            tasksViewMode: 'tasks-listing',
            kanbanShowTags: true,
            boardTemplates: null,
            workflowData: {},
            search: null,
            refreshedTask: null,
            tasks_filters: {
                my: 0,
                issues: 0,
                due_date_range: 'all',
                filter_rfps: [],
                filter_projects: [],
                filter_proposals: [],
                filter_meeting_minutes: [],
                filter_general: false,
                filter_type: {
                    general: 1,
                    issues: 1,
                    tasks: 1,
                    meetingMinute: 1,
                    rfp: 1,
                    report: 1,
                    inspection: 1
                }
            },
            elementData: {
                id: null,
                type: null
            },
            tasks: [],
            disableInputs: false,
            checkList: {
                addMode: false,
                editMode: false,
                task: null
            },
            cachedLists: null,
            refresh: false,
            statisticsRefresh: false,
            taskProjectMongoId: null,
            timelineShown: false,
            taskProjectId: null,
            taskSaved: false
        }
    },
    actions,
    mutations
}
