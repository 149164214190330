import { defineComponent } from 'vue'
import { get } from 'lodash'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    props: {
        data: { type: Object as () => any, required: true, default: null },
        column: { type: Object as () => ColumnInterface, required: true, default: null },
        columnClass: { type: String, default: null }
    },
    computed: {
        cantDeleteContact (): boolean {
            return this.column?.field === 'can_delete_contact' && !this.data[this.column?.field]
        },
        tooltip (): string | undefined {
            return this.cantDeleteContact
                ? this.column?.additional?.additionalTooltip
                : this.column?.additional?.tooltip

        },
        iconName (): string | undefined {
            if (this.column?.additional?.activeIconName && !!this.data[this.column.field]) {
                return this.column?.additional?.activeIconName
            }
            return this.column?.additional?.iconName
        },
        iconText (): string | boolean {
            // @ts-ignore
            const additionalHTMLFunction = get(this.column, ['additional', 'additionalHTMLFunction'], null)

            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.data)
            }

            return false
        },
        cellClass (): string | undefined {
            let className = this.column?.columnClass

            if (this.data[this.column.field]) {
                className = `${ className } io-active`
            }

            return className
        }
    },
    methods: {
        emitAction (e: Event): void {
            e.preventDefault()

            let id = this.data.id ? this.data.id : this.data._id

            if (this.column?.field === 'can_delete_employee') {
                id = this.data.contact_person_id
            }

            if (this.cantDeleteContact) {
                return
            }
            this.$emit('emitIconAction', { field: this.column.field, id })
        },
        isHidden (): boolean {
            const hiddenField = this.column?.additional?.hidden

            if (hiddenField) {
                // @ts-ignore
                return this.data[this.column?.additional?.hidden]
            }

            return false
        }
    }
})
