<template>
    <IOModal
        icon="icon-info"
        :title="$t('Export Configuration')"
        width="536"
        :color="Color.BLUE"
        @close="closeModal"
    >
        <template #default>
            <div class="io-content-wrapper">
                <div>
                    <h3 class="io-content-wrapper__title">{{ $t('Include Cover Page') }}</h3>
                    <div class="io-content-wrapper__sub-title">
                        {{ $t('The cover page includes a summary of the contract and invoice values.') }}
                    </div>
                    <div class="io-content-wrapper__actions">
                        <IOSwitcher
                            :title="removeCoverPage ? $t('Yes') : $t('No')"
                            :model-value="!!removeCoverPage"
                            @update:model-value="changeCoverPage"
                        />
                    </div>
                    <div class="io-content-wrapper__information">
                        <div>
                            <span class="io-content-wrapper__icon icon-info"></span>
                        </div>
                        <div>
                            <span class="io-content-wrapper__text">
                                {{
                                    $t('Only PDF, JPEG and PNG file types that are attached to the invoice will be exported to the system generated PDF of this invoice.')
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="closeModal">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton @click="exportInvoice">
                {{ $t('Export') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./RemoveCoverPageModal.ts"></script>
<style lang="scss" src="./RemoveCoverPageModal.scss" scoped></style>
