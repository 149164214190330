<template>
    <div class="io-project-specific-funds">
        <Table
            v-if="!loading && relatedProjects"
            :headers="tableHeaders"
            :items="relatedProjects.items"
            sort-by="project_name"
        >
            <template #tbody="{ sortedItems }">
                <FundingSourcesProjectRow
                    v-for="project in sortedItems"
                    :key="project.id"
                    :is-capital-fund="false"
                    :project="project"
                />
                <FundingSourcesProjectRow
                    :is-capital-fund="false"
                    :project="relatedProjects.totals"
                    :is-final="true"
                />
            </template>
        </Table>
        <Placeholder
            v-if="!loading && !relatedProjects"
            :title="$t('No Project Specific Funds within this Provider')"
            icon="icon-cash"
        />
    </div>
</template>

<script lang="ts" src="./TabProjectSpecificFunds.ts"></script>
<style lang="scss" src="./TabProjectSpecificFunds.scss" scoped></style>
