export const setFirstDraftProvider = function ({ commit, dispatch }, value) {
    commit('SET_FIRST_DRAFT_PROVIDER', value)
}

export const setProceedMode = function ({ commit, dispatch }, value) {
    commit('SET_PROCEED_MODE', value)
}

export const setLetterOfIntentFormat = function ({ commit, dispatch }, value) {
    commit('SET_LETTER_OF_INTENT_FORMAT', value)
}

export const setDescription = function ({ commit, dispatch }, value) {
    commit('SET_DESCRIPTION', value)
}

export const setDocument = function ({ commit, dispatch }, value) {
    commit('SET_DOCUMENT', value)
}

export const setIsSigned = function ({ commit, dispatch }, value) {
    commit('SET_IS_SIGNED', value)
}

export const setClientApprovedBy = function ({ commit, dispatch }, value) {
    commit('SET_CLIENT_APPROVED_BY', value)
}

export const setClientApprovedAt = function ({ commit, dispatch }, value) {
    commit('SET_CLIENT_APPROVED_AT', value)
}

export const setClientApprovedNote = function ({ commit, dispatch }, value) {
    commit('SET_CLIENT_APPROVED_NOTE', value)
}

export const setConfirmText = function ({ commit, dispatch }, value) {
    commit('SET_CONFIRM_TEXT', value)
}

export const setShowLoader = function ({ commit, dispatch }, value) {
    commit('SET_SHOW_LOADER', value)
}

export const setSendViaMail = function ({ commit, dispatch }, value) {
    commit('SET_SEND_VIA_MAIL', value)
}

export const setVendorRecipients = function ({ commit, dispatch }, value) {
    commit('SET_VENDOR_RECIPIENTS', value)
}

export const setEmailBody = function ({ commit, dispatch }, value) {
    commit('SET_EMAIL_BODY', value)
}

export const setSelectedTemplate = function ({ commit, dispatch }, value) {
    commit('SET_SELECTED_TEMPLATE', value)
}

export const setIsOffSystem = function ({ commit, dispatch }, value) {
    commit('SET_IS_OFF_SYSTEM', value)
}

export const setLetterId = function ({ commit, dispatch }, value) {
    commit('SET_LETTER_ID', value)
}

export const setTemplateId = function ({ commit, dispatch }, value) {
    commit('SET_TEMPLATE_ID', value)
}

export const setReviseMode = function ({ commit, dispatch }, value) {
    commit('SET_REVISE_MODE', value)
}

export const setViewOnlyMode = function ({ commit, dispatch }, value) {
    commit('SET_VIEW_ONLY_MODE', value)
}

export const clearLetterOfIntent = function ({ commit }) {
    commit('CLEAR_LETTER_OF_INTENT')
}
