import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    props: {
        data: { type: Object as () => any, required: true, default: null },
        column: { type: Object as () => ColumnInterface, required: true, default: null }
    },
    computed: {
        getIconClass (): string {
            if (this.column?.additional?.getIconClass instanceof Function) {
                return this.column?.additional?.getIconClass(this.data)
            }

            return this.column?.additional?.iconClass
        },
        getText (): string {
            const field = this.column.field
            const value = this.data[field] ?? '-'

            if (this.column?.additional?.getText instanceof Function) {
                return this.column?.additional?.getText(this.data)
            }

            if ((field === 'contact_office_phone' || field === 'contact_cell_phone' || field === 'cellphone') && value !== '-') {
                return this.phonePipe(value)
            }

            return value
        },
        favouriteDefined (): boolean {
            return !!this.column?.additional?.favorite
        },
        isFavourite (): boolean {
            return !!this.favouriteDefined ? this.data.favorite : false
        },
        favouriteIconClass (): string {
            return this.isFavourite ? 'icon-star-2 io-favorite' : 'icon-star'
        },
        getCustomAction (): string {
            return this.column?.additional?.customOnClickAction
        }
    },
    methods: {
        phonePipe(phone: any): string {
            let number = phone.number

            if (!number) {
                return '-'
            }

            const match = number?.match(/^(\d{3})(\d{3})(\d{4})$/);

            if (match) {
                number = `(${ match[1] }) ${ match[2] }-${ match[3] }`
            }

            let code = phone.country?.dial_code ?? phone.country ?? ''
            let result = `${ code } ${ number }`

            if (phone.prefix) {
                result = `${ result } x${ phone.prefix }`
            }

            return result
        },
        emitAction (e: Event): void {
            e.preventDefault()

            if (this.getCustomAction) {
                this.$emit('emitIconAction', { action: this.column?.additional?.customOnClickAction, id: this.data.id })
                return
            }

            this.$emit('change-favorite', this.data.id)
        },

    }
})
