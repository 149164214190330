export default {
    divisions: [],
    collapsedDivisions: [],
    processingSets: [],
    publishedSets: [],
    activeSection: '',
    checkedSections: [],
    isDisabledBtn: true,
    annotationList: [],
}
