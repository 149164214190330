const mathHelpers = {
    cutPrecision (value: number, precision: number = 2): number {
        value = Number(value) || 0
        if (Number.isInteger(value)) {
            return value
        }
        precision = Math.pow(10, precision)
        return Math.sign(value) * Math.round(Math.abs(value) * precision) / precision
    },

    add (values: number[], precision: number = 2): number {
        const total = values.reduce((previousValue, currentValue) => {
            return (Number(previousValue) || 0) + (Number(currentValue) || 0)
        })
        return this.cutPrecision(total, precision)
    },

    sub (values: number[], precision: number = 2): number {
        const total = values.reduce((previousValue, currentValue) => {
            return (Number(previousValue) || 0) - (Number(currentValue) || 0)
        })
        return this.cutPrecision(total, precision)
    },

    divide (dividend: number, divisor: number, precision: number = 2): number {
        return this.cutPrecision((dividend / divisor), precision)
    },

    multiply (values: number[], precision: number = 2): number {
        const total = values.reduce((previousValue, currentValue) => {
            return (Number(previousValue) || 0) * (Number(currentValue) || 0)
        })
        return this.cutPrecision(total, precision)
    },

    getPercent (value: number, percent: number, precision: number = 4): number {
        return this.multiply([value, (this.divide(percent, 100, precision))], precision)
    },

    calculateTotalByField (object: object, fieldName: string, precision: number = 2): number {
        if (!Array.isArray(object)) {
            return 0
        }

        let total = 0

        object.forEach((object) => {
            const fieldValue = parseFloat(object[fieldName])
            if (!isNaN(fieldValue)) {
                total += fieldValue
            }
        })

        return this.cutPrecision(total, precision)
    },
}

export default mathHelpers
