import { defineComponent } from 'vue'
import ExistingWorkspace from '../../parts/ExistingWorkspace.vue'
import ActionsView from '../actions-view/ActionsView.vue'
import { mapMutations } from 'vuex'

export default defineComponent({
    name: 'WorkspaceFound',
    components: {
        ExistingWorkspace,
        ActionsView
    },
    props: {
        componentKey: { type: String, required: true },
        allowToSave: { type: Boolean, required: true }
    },
    data () {
        return {
            selectedWorkspace: null
        }
    },
    computed: {
        matchedDomainsList (): any[] {
            return this.$store.getters['inviteModal/getMatchedDomainsList'](this.componentKey)
        },
        companyDomain (): any[] {
            return this.$store.getters['inviteModal/getSearchDomain'](this.componentKey)
        },
        searchValue (): string {
            return this.$store.getters['inviteModal/getSearchKeyword'](this.componentKey)
        }
    },
    watch: {
        'selectedWorkspace' (value) {
            this.setSelectedWorkspace({ key: this.componentKey, value })
        }
    },
    methods: {
        ...mapMutations('inviteModal', {
            setSelectedWorkspace: 'SET_SELECTED_WORKSPACE'
        }),
    }
})
