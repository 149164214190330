<template>
    <div class="employeeBox io-f-row-invite io-member-row"
         :class="{ 'io-member-row-last': isLastRow }"
         v-on:click.stop="!item.isAdded ? $emit('attachPerson') : $emit('detachPerson')">
        <div v-if="item.photo && item.photo !== '' && item.photo !== 'pending'"
             class="io-emplo-avatar"
             :style="'background-image: url('+ item.photo +')'"></div>
        <div v-else-if="item.photo === 'pending'" class="io-pending-avatar"></div>
        <initials
            v-else
            class="io-img-holder io-f-column"
            :fullName="item.fullname"
            :bgColor="personColor(item)"
            :bigAvatar="true"
        />

        <div class="io-name-column">
            <div class="io-name">
                {{ item.fullname }}
                <span class="io-name-apptype" :class="appTypeClean">{{ appType }}</span>
                <br/>
                <small class="io-company-address">{{ item.address }}</small>
            </div>
        </div>

        <div v-if="item.loading" class="io-loader-small"></div>
        <div v-else-if="item.photo === 'pending'">
            <a>
                <img src="/new-theme/img/added-icon.svg"/>
            </a>
        </div>
        <div v-else>
            <a v-if="!item.isPending && !item.isAdded">
                <img src="/new-theme/img/add-grey.svg"/>
            </a>
            <a v-else>
                <img src="/new-theme/img/added-icon.svg"/>
            </a>
        </div>
    </div>
</template>

<script>
    import initials from '@/components/new-theme/initials.vue'
    import { isUndefined } from 'lodash'

    export default {
        name: 'boxCompany',
        components: {
            initials
        },
        props: {
            item: {
                required: true,
                type: Object
            },
            isLastRow: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            appType () {
                if (this.item.app_type === this.appTypes.TYPE_SC) {return 'SUB'}
                else if (this.item.app_type === this.appTypes.TYPE_AC) {return 'DESIGN'}
                else if (this.item.app_type === this.appTypes.TYPE_GC) {return 'GC'}
                else if (this.item.app_type === this.appTypes.TYPE_OWNER) {return 'OWNER'}
                else if (this.item.app_type === this.appTypes.TYPE_DEV) {return 'OWNER REP'}
            },

            appTypeClean () {
                let type = this.item.app_type
                if (isUndefined(type)) {
                    return ''
                }
                return 'io-' + type
            }
        }
    }
</script>

<style type="text/scss" lang="scss">
    .io-company-address {
        white-space: normal;
    }
</style>
