// new components
const MainLayout = () => import(/* webpackChunkName: "lists-templates" */ './main.vue')

const TabsLayout = () => import(/* webpackChunkName: "lists-templates" */ './tabs/main.vue')
const CostCodeGroups = () => import(/* webpackChunkName: "lists-templates" */ './tabs/cost-code-groups/main.vue')
const Integrations = () => import(/* webpackChunkName: "lists-templates" */ './tabs/integrations/Main.vue')

const BudgetLayout = () => import(/* webpackChunkName: "lists-templates" */ './tabs/budget-layout-view/main.vue')
const BudgetLayoutEdit = () => import(/* webpackChunkName: "lists-templates" */ './views/layout-edit/main.vue')

const ListingBudgetLineItems = () => import(/* webpackChunkName: "lists-templates" */ './views/budget-line-items/listing/listing.vue')
const ListLayoutBudgetLineItems = () => import(/* webpackChunkName: "lists-templates" */ './views/budget-line-items/layout/layout.vue')
const ListDetailsBudgetLineItems = () => import(/* webpackChunkName: "lists-templates" */ './views/budget-line-items/details/details.vue')
const TemplatesBudgetLineItems = () => import(/* webpackChunkName: "lists-templates" */ './views/budget-line-items/templates/templates.vue')
const TemplateEditBudgetLineItems = () => import(/* webpackChunkName: "lists-templates" */ './views/budget-line-items/template-edit/template-edit.vue')

const ListingQuotedItemLines = () => import(/* webpackChunkName: "lists-templates" */ './views/quoted-item-lines/listing/listing.vue')
const ListLayoutQuotedItemLines = () => import(/* webpackChunkName: "lists-templates" */ './views/quoted-item-lines/list-layout/list-layout.vue')
const ListDetailsQuotedItemLines = () => import(/* webpackChunkName: "lists-templates" */ './views/quoted-item-lines/details/details.vue')
const TemplatesQuotedItemLines = () => import(/* webpackChunkName: "lists-templates" */ './views/quoted-item-lines/templates/templates.vue')
const TemplateEditQuotedItemLines = () => import(/* webpackChunkName: "lists-templates" */ './views/quoted-item-lines/template-edit/template-edit.vue')

const ListingBidQuestions = () => import(/* webpackChunkName: "lists-templates" */ './views/bid-questions/listing/listing.vue')
const ListLayoutBidQuestions = () => import(/* webpackChunkName: "lists-templates" */ './views/bid-questions/layout/layout.vue')
const ListDetailsBidQuestions = () => import(/* webpackChunkName: "lists-templates" */ './views/bid-questions/details/details.vue')
const TemplatesBidQuestions = () => import(/* webpackChunkName: "lists-templates" */ './views/bid-questions/templates/templates.vue')
const TemplateEditBidQuestions = () => import(/* webpackChunkName: "lists-templates" */ './views/bid-questions/template-edit/template-edit.vue')

const ListingCustomFields = () => import(/* webpackChunkName: "lists-templates" */ './views/custom-fields/listing/listing.vue')
const ListDetailsCustomFields = () => import(/* webpackChunkName: "lists-templates" */ './views/custom-fields/details/details.vue')

const ListingTaskWorkflows = () => import(/* webpackChunkName: "lists-templates" */ './views/task-workflows/listing/listing.vue')
const ListDetailsTaskWorkflows = () => import(/* webpackChunkName: "lists-templates" */ './views/task-workflows/details/details.vue')

const ListingDocumentsTemplates = () => import(/* webpackChunkName: "lists-templates" */ './views/documents-templates/listing/listing.vue')
const ListLayoutDocumentsTemplates = () => import(/* webpackChunkName: "lists-templates" */ './views/documents-templates/layout/layout.vue')
const ListDetailsDocumentsTemplates = () => import(/* webpackChunkName: "lists-templates" */ './views/documents-templates/details/details.vue')
const TemplatesDocumentsTemplates = () => import(/* webpackChunkName: "lists-templates" */ './views/documents-templates/templates/templates.vue')
const TemplateEditDocumentsTemplates = () => import(/* webpackChunkName: "lists-templates" */ './views/documents-templates/template-edit/template-edit.vue')

const LetterOfIntentList = () => import(/* webpackChunkName: "lists-templates" */ './views/letter-of-intent/LetterOfIntentList.vue')
const LetterOfIntentDetails = () => import(/* webpackChunkName: "lists-templates" */ './views/letter-of-intent/LetterOfIntentDetails.vue')

const LienWaiverList = () => import(/* webpackChunkName: "lists-templates" */ './views/lien-waivers/LienWaiverList.vue')
const LienWaiverDetails = () => import(/* webpackChunkName: "lists-templates" */ './views/lien-waivers/LienWaiverDetails.vue')

const CurrencyConversion = () => import(/* webpackChunkName: "lists-templates" */ './views/currency-conversion/listing/main.vue')
const CurrencyConversionSingleTemplate = () => import(/* webpackChunkName: "lists-templates" */ './views/currency-conversion/template/Template.vue')

const FinancialApprovalsListing = () => import(/* webpackChunkName: "lists-templates" */ './views/financial-approvals/views/listing/main.vue')
const FinancialApprovalsSingleTemplate = () => import(/* webpackChunkName: "lists-templates" */ './views/financial-approvals/views/single-template/SingleTemplate.vue')

const MeetingMinutesListsView = () => import('./views/meeting-minutes-new/lists/ListsView.vue')
const MeetingMinutesListCore = () => import('./views/meeting-minutes-new/details/ListCore.vue')
const MeetingMinutesListDetails = () => import('./views/meeting-minutes-new/details/sub-views/list-details/ListDetails.vue')
const MeetingMinutesListTemplates = () => import('./views/meeting-minutes-new/details/sub-views/list-templates/ListTemplates.vue')
const MeetingMinutesTemplateDetails = () => import('./views/meeting-minutes-new/details/sub-views/template-details/TemplateDetails.vue')
const AccountingVendorsList = () => import('./views/accounting-vendors/views/List/List.vue')
const AccountingVendorsCompaniesList = () => import('./views/accounting-vendors/views/CompaniesList/CompaniesList.vue')

const AccountingCostCodes = () => import('./views/accounting-cost-codes/view/Main.vue')
const AccountingEntities = () => import('./views/accounting-entities/view/Main.vue')
const ContractDocuments = () => import('./views/contract-documents/view/Main.vue')
const ContractDocumentEditMode = () => import('./views/contract-documents/view/contract-edit-mode/ContractEditMode.vue')

const InsuranceRequirementsTemplates = () => import('./views/insurance-requirements/template-list/TemplateList.vue')
const InsuranceRequirementsTemplateDetails = () => import('./views/insurance-requirements/template-details/TemplateDetails.vue')
const TaskTemplatesMain = () => import('./views/task-templates/TaskTemplatesMain.vue')
const TaskList = () => import('./views/task-templates/views/TaskList/TaskList.vue')
const MeetingsTemplatesMain = () => import('./views/meetings-templates/views/MeetingsTemplatesMain/MeetingsTemplatesMain.vue')
const MeetingsTemplate = () => import('./views/meetings-templates/views/MeetingsTemplate/MeetingsTemplate.vue')

import projectCostCodesRoutes from '@/io-modules/lists-templates/project-cost-codes/routes/routes.ts'
import analyticCostCodesRoute from '@/io-modules/lists-templates/analytic-cost-codes/routes/routes.ts'
import scheduleTemplatesRoute from '@/io-modules/lists-templates/schedule-templates/routes/routes.ts'
import analyticalMilestonesRoute from '@/io-modules/lists-templates/analytical-milestones/routes/routes.ts'

export default [
    {
        path: '/lists-templates',
        name: 'lists-templates',
        component: MainLayout,
        redirect: { name: 'lists-templates-cost-code-groups' },
        children: [
            {
                path: '',
                name: 'lists-templates-tabs',
                component: TabsLayout,
                redirect: { name: 'lists-templates-cost-code-groups' },
                children: [
                    {
                        path: 'codes',
                        name: 'lists-templates-cost-code-groups',
                        component: CostCodeGroups,
                        meta: {
                            title: 'Cost Code Groups'
                        }
                    },
                    {
                        path: 'layout-view',
                        name: 'lists-templates-budget-layout-view',
                        component: BudgetLayout,
                        meta: {
                            title: 'Lists Templates Budget'
                        }
                    },
                    {
                        path: 'integrations',
                        name: 'lists-templates-integrations',
                        component: Integrations,
                        meta: {
                            title: 'Lists Templates Integrations'
                        }
                    },
                    analyticalMilestonesRoute,
                    analyticCostCodesRoute,
                ]
            },
            {
                path: 'layout-view/edit/:_id',
                name: 'lists-templates-budget-layout-view-edit',
                component: BudgetLayoutEdit,
                meta: {
                    title: 'Lists Templates Edit Budget'
                }
            },


            /**
             * Budget Line Items routes
             */
            {
                path: 'budget-line-items/',
                name: 'lists-templates-budget-line-items',
                meta: { title: 'Lists Templates Budget Line Items' },
                component: ListingBudgetLineItems
            },
            {
                path: 'budget-line-items/edit/:id',
                name: 'lists-templates-budget-line-items-edit',
                component: ListLayoutBudgetLineItems,
                redirect: { name: 'lists-templates-budget-line-items-details' },
                children: [
                    {
                        path: 'details',
                        name: 'lists-templates-budget-line-items-details',
                        component: ListDetailsBudgetLineItems,
                        meta: {
                            title: 'Edit Budget Details'
                        }
                    },
                    {
                        path: 'templates',
                        name: 'lists-templates-budget-line-items-templates',
                        component: TemplatesBudgetLineItems,
                        meta: {
                            title: 'Edit Budget Templates'
                        }
                    },
                    {
                        path: 'template/:template',
                        name: 'lists-templates-budget-line-items-template-edit',
                        component: TemplateEditBudgetLineItems,
                        meta: {
                            title: 'Edit Budget Template'
                        }
                    }
                ]
            },


            /**
             * Project Cost Codes routes
             */
            projectCostCodesRoutes,

            /**
             * Quoted Item Lines routes
             */
            {
                path: 'quoted-item-lines/',
                name: 'lists-templates-quoted-item-lines',
                meta: { title: 'Lists Templates Budget Line Items' },
                component: ListingQuotedItemLines
            },
            {
                path: 'quoted-item-lines/edit/:id',
                name: 'lists-templates-quoted-item-lines-edit',
                component: ListLayoutQuotedItemLines,
                redirect: { name: 'lists-templates-quoted-item-lines-details' },
                children: [
                    {
                        path: 'details',
                        name: 'lists-templates-quoted-item-lines-details',
                        component: ListDetailsQuotedItemLines,
                        meta: {
                            title: 'Edit Quoted Item Details'
                        }
                    },
                    {
                        path: 'templates',
                        name: 'lists-templates-quoted-item-lines-templates',
                        component: TemplatesQuotedItemLines,
                        meta: {
                            title: 'Edit Quoted Item Templates'
                        }
                    },
                    {
                        path: 'template/:template',
                        name: 'lists-templates-quoted-item-lines-template-edit',
                        component: TemplateEditQuotedItemLines,
                        meta: {
                            title: 'Edit Quoted Item Template'
                        }
                    }
                ]
            },


            /**
             * Bid questions routes
             */
            {
                path: 'bid-questions/',
                name: 'lists-templates-bid-questions',
                meta: { title: 'Lists Templates Budget Line Items' },
                component: ListingBidQuestions
            },
            {
                path: 'bid-questions/edit/:id',
                name: 'lists-templates-bid-questions-edit',
                component: ListLayoutBidQuestions,
                redirect: { name: 'lists-templates-bid-questions-details' },
                children: [
                    {
                        path: 'list',
                        name: 'lists-templates-bid-questions-details',
                        component: ListDetailsBidQuestions,
                        meta: {
                            title: 'Edit Bid Questions List'
                        }
                    },
                    {
                        path: 'templates',
                        name: 'lists-templates-bid-questions-templates',
                        component: TemplatesBidQuestions,
                        meta: {
                            title: 'Edit Bid Questions Templates'
                        }
                    },
                    {
                        path: 'template/:template',
                        name: 'lists-templates-bid-questions-template-edit',
                        component: TemplateEditBidQuestions,
                        meta: {
                            title: 'Edit Bid Questions Template'
                        }
                    }
                ]
            },


            /**
             * Bid questions routes
             */
            {
                path: 'documents-templates/',
                name: 'invoice-documents-templates',
                meta: { title: 'Lists Templates Budget Line Items' },
                component: ListingDocumentsTemplates
            },
            {
                path: 'documents-templates/edit/:id',
                name: 'invoice-documents-templates-edit',
                component: ListLayoutDocumentsTemplates,
                redirect: { name: 'invoice-documents-templates-details' },
                children: [
                    {
                        path: 'list',
                        name: 'invoice-documents-templates-details',
                        component: ListDetailsDocumentsTemplates,
                        meta: {
                            title: 'Edit Documents Templates List'
                        }
                    },
                    {
                        path: 'templates',
                        name: 'invoice-documents-templates-templates',
                        component: TemplatesDocumentsTemplates,
                        meta: {
                            title: 'Edit Documents Templates'
                        }
                    },
                    {
                        path: 'template/:template',
                        name: 'invoice-documents-templates-template-edit',
                        component: TemplateEditDocumentsTemplates,
                        meta: {
                            title: 'Edit Documents Template'
                        }
                    }
                ]
            },


            /**
             * Custom Attributes routes
             */
            {
                path: 'custom-fields/',
                name: 'lists-templates-custom-fields',
                meta: { title: 'Custom Attributes Templates' },
                component: ListingCustomFields
            },
            {
                path: 'custom-fields/edit/:id',
                name: 'lists-templates-custom-fields-edit',
                component: ListDetailsCustomFields,
                meta: {
                    title: 'Edit Custom Attributes'
                }
            },

            /**
             * Task Workflows routes
             */
            {
                path: 'task-workflows/',
                name: 'lists-templates-task-workflows',
                meta: { title: 'Lists Templates Budget Line Items' },
                component: ListingTaskWorkflows
            },
            {
                path: 'task-workflows/edit/:id',
                name: 'lists-templates-task-workflows-edit',
                component: ListDetailsTaskWorkflows,
                meta: {
                    title: 'Edit Task Workflow'
                }
            },


            /**
             * Meeting Minutes routes
             */
            {
                path: 'meeting-minutes-new',
                name: 'list-templates-meeting-minutes',
                meta: { title: 'Budget Configuration Budget Line Items' },
                component: MeetingMinutesListsView
            },
            {
                path: 'meeting-minutes-new/edit/:id',
                name: 'list-templates-meeting-minutes-edit',
                component: MeetingMinutesListCore,
                redirect: { name: 'list-templates-meeting-minutes-list-details' },
                children: [
                    {
                        path: 'list-details',
                        name: 'list-templates-meeting-minutes-list-details',
                        component: MeetingMinutesListDetails,
                        meta: {
                            title: 'Edit Meeting Minutes List Details'
                        }
                    },
                    {
                        path: 'list-templates',
                        name: 'list-templates-meeting-minutes-edit-templates',
                        component: MeetingMinutesListTemplates,
                        meta: {
                            title: 'Edit Meeting Minutes List Templates'
                        }
                    },
                    {
                        path: 'template/:template',
                        name: 'list-templates-meeting-minutes-master-list',
                        component: MeetingMinutesTemplateDetails,
                        meta: {
                            title: 'Edit Meeting Minutes List Template'
                        }
                    }
                ]
            },


            /**
             * Letter of Intent routes
             */
            {
                path: 'letter-of-intent',
                name: 'letter-of-intent-templates',
                meta: { title: 'Letter Of Intent' },
                component: LetterOfIntentList
            },
            {
                path: 'letter-of-intent/edit/:id',
                name: 'letter-of-intent-details',
                meta: { title: 'Edit Letter Of Intent' },
                component: LetterOfIntentDetails
            },

            /**
             * Lien Waiver Templates routes
             */
            {
                path: 'lien-waiver',
                name: 'lien-waiver-templates',
                meta: { title: 'Lien Waiver Templates' },
                component: LienWaiverList
            },
            {
                path: 'lien-waiver/edit/:id',
                name: 'lien-waiver-details',
                meta: { title: 'Edit Lien Waiver' },
                component: LienWaiverDetails
            },

            /**
             * Currency routes
             */
            {
                path: 'currency-conversion',
                name: 'lists-templates-currency-conversion',
                meta: {
                    title: 'Currency Conversion'
                },
                component: CurrencyConversion
            },
            {
                path: 'currency-conversion/template/:id',
                name: 'currency-conversion-template',
                component: CurrencyConversionSingleTemplate,
                meta: {
                    title: 'Currency Conversion Template'
                }
            },

            /**
             * Financial Approvals
             */

            {
                path: 'financial-approvals/list',
                name: 'financial-approvals-list',
                component: FinancialApprovalsListing,
                meta: {
                    title: 'Financial Approvals List'
                }
            },
            {
                path: 'financial-approvals/list/:id/new',
                name: 'financial-approvals-list-template-new',
                component: FinancialApprovalsSingleTemplate,
                meta: {
                    title: 'Add Financial Approval'
                }
            },
            {
                path: 'financial-approvals/list/:id',
                name: 'financial-approvals-list-template',
                component: FinancialApprovalsSingleTemplate,
                meta: {
                    title: 'Financial Approval Details'
                }
            },
            {
                path: 'accounting-cost-codes',
                name: 'lists-templates-accounting-cost-codes',
                component: AccountingCostCodes,
                meta: {
                    title: 'Accounting Cost Codes'
                }
            },
            {
                path: 'accounting-entities',
                name: 'lists-templates-accounting-entities',
                component: AccountingEntities,
                meta: {
                    title: 'Accounting Entities'
                }
            },
            {
                path: 'contract-documents',
                name: 'contract-documents',
                component: ContractDocuments,
                meta: {
                    title: 'Contract Documents'
                }
            },
            {
                path: 'contract-documents/template/:documentId',
                name: 'contract-document-template',
                component: ContractDocumentEditMode,
                meta: {
                    title: 'Edit Contract Document'
                }
            },
            {
                path: 'accounting-vendors',
                name: 'lists-templates-accounting-vendors',
                redirect: { name: 'lists-templates-accounting-vendors-list' },
                component: MainLayout,
                children: [
                    {
                        path: 'list',
                        name: 'lists-templates-accounting-vendors-list',
                        component: AccountingVendorsList,
                        meta: {
                            title: 'Accounting Vendor List'
                        }
                    },
                    {
                        path: 'companies-list',
                        name: 'lists-templates-accounting-vendors-companies-list',
                        component: AccountingVendorsCompaniesList,
                        meta: {
                            title: 'Accounting Vendors Companies'
                        }
                    }
                ]
            },

            /**
             * Insurance Requirements
             */
            {
                path: 'insurance-requirements',
                name: 'insurance-requirements-templates',
                meta: { title: 'Insurance Requirements Templates' },
                component: InsuranceRequirementsTemplates
            },
            {
                path: 'insurance-requirements/details/:template',
                name: 'insurance-requirements-templates-details',
                component: InsuranceRequirementsTemplateDetails,
                meta: {
                    title: 'Insurance Requirement Template Details'
                }
            },

            /**
             * Task Templates
             */
            {
                path: 'tasks-templates',
                name: 'tasks-templates',
                meta: { title: 'Task Templates' },
                component: TaskTemplatesMain
            },
            {
                path: 'tasks-templates/task-list/:id',
                name: 'tasks-templates-list-view',
                meta: { title: 'Task Templates' },
                component: TaskList
            },
            {
                path: 'tasks-templates/task-list-new',
                name: 'tasks-templates-list-creating-view',
                meta: { title: 'Task Templates' },
                component: TaskList
            },

            /**
             * Meetings Templates
             */
            {
                path: 'meetings-templates',
                name: 'lists-templates-meetings-templates',
                meta: { title: 'Meetings Templates' },
                component: MeetingsTemplatesMain
            },
            {
                path: 'meetings-templates/:id',
                name: 'lists-templates-meetings-template-view',
                meta: { title: 'Meetings Templates' },
                component: MeetingsTemplate
            },

            ...scheduleTemplatesRoute,
        ]
    }
]

