import { defineComponent, PropType } from 'vue'
import SingleApproval from './parts/single-approval/SingleApproval.vue'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'
import Workflow from '@/io-modules/approval-workflows/interfaces/Workflow'
import OverrideApproverModal from '../override-approver-modal/OverrideApproverModal.vue'
import Approver from '../../interfaces/Approver'
import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse.ts'

export default defineComponent({
    name: 'ApprovalWorkflow',
    components: {
        SingleApproval,
        StatusPill,
        OverrideApproverModal,
    },
    props: {
        resourceApprovals: {
            type: Object as PropType<ResourceApprovalsResponse>,
            default: null,
        },
        workflowData: { type: Object as PropType<Workflow>, required: true },
        canApproveOrReject: { type: Boolean, default: false },
        total: { type: Number, default: 0 },
        isTotalVisible: { type: Boolean, required: false, default: true },
        customTitle: { type: String, required: false, default: '' },
    },
    emits: ['approverApproveSelected', 'approverReviewSelected', 'approverRejectSelected','approverOverridden'],
    data () {
        return {
            approvers: [] as any,
            showOverrideApproverModal: false,
            approverData: null,
        }
    },
    computed: {
        isRejected (): boolean {
            return this.workflowData.approvers.some((item: any) => {
                return item.approval?.status === 'rejected'
            })
        },
        allAccepted (): boolean {
            return this.workflowData.approvers.every((item: any) => {
                return item.approval?.status === 'approved'
            })
        },
        approvalStatusPill (): any {
            if (this.isRejected) {
                return {
                    text: 'REJECTED',
                    variant: PillVariant.RED_LIGHT,
                }
            }
            if (this.allAccepted) {
                return {
                    text: 'APPROVED',
                    variant: PillVariant.GREEN_LIGHT,
                }
            }

            return {
                text: `${ this.approvedApprovalsCount }/${ this.approvalsCount } APPROVED`,
                variant: this.approvedApprovalsCount < this.approvalsCount ? PillVariant.BLUE_LIGHT : PillVariant.GREEN_LIGHT,
            }
        },
        approvedApprovalsCount (): number {
            return this.workflowData.approvers.filter((approver: any) => 'approved' === approver.approval?.status).length
        },
        approvalsCount (): number {
            return this.workflowData.approvers.length
        },
        firstRejectedBy (): any {
            return this.workflowData.approvers.find((item: any) => {
                return item?.approval?.status === 'rejected'
            })
        },
        costCodesIds (): number[] {
            return this.workflowData?.cost_codes.map((costCode: any) => costCode.cost_code_id) || []
        },
        totalAmount (): number {
            return this.total
        },
        title (): string {
            return !!this.customTitle ? this.customTitle : this.workflowData.name
        },
        approvalStatus (): string {
            return this.resourceApprovals.status
        }
    },
    async beforeMount () {
        await this.fetchEmployees()
    },
    methods: {
        async fetchEmployees (): Promise<void> {
            this.setLoadingBar(true)

            try {
                this.approvers = []
            } catch (e) {
                this.showNotification('error', e.message)
            } finally {
                this.setLoadingBar(false)
            }
        },

        openOverrideApproverModal (approver: Approver): void {
            this.showOverrideApproverModal = true
            this.approverData = approver
        },

        closeOverrideApproverModal (): void {
            this.showOverrideApproverModal = false
            this.approverData = null
        },
    },
})
