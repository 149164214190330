import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { useBreadcrumbsStore } from '@/store/app/breadcrumbs'

const breadcrumbsMixin = defineComponent({
    computed: {
        ...mapState(useBreadcrumbsStore, [
            'breadcrumbs',
        ]),
    },
    methods: {
        ...mapActions(useBreadcrumbsStore, {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty',
        })
    },
})

export default breadcrumbsMixin
