import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SvgIconComponent',
    props: {
        fillColor: {
            type: String,
            default: 'red'
        },
        toolName: {
            type: String,
            required: true
        },
        strokeWidth: {
            type: String,
            default: '2',
        },
        toolColor: {
            type: String,
            default: '#EF4343',
        },
    },
    computed: {
        paths (): string[] {
            switch (this.toolName) {
            case 'ARROW':
                return ['M1 12L9.94018 3.99542', 'M11.4246 2.47487L9.85557 8.33049L5.56897 4.04388L11.4246 2.47487Z']
            case 'ARC':
                return ['M1.01463 14C1.01463 13.5667 0.13581 1.86667 12 1']
            case 'CLOUD':
                return ['M8 3C5.2938 3 3.1 5.08934 3.1 7.66667C3.1 7.69467 3.10026 7.72261 3.10077 7.75049C1.89272 8.04626 1 9.09051 1 10.3333C1 11.8061 2.2536 13 3.8 13H11.5C13.433 13 15 11.5076 15 9.66667C15 8.24888 14.0706 7.03784 12.7604 6.556C12.2371 4.51488 10.3043 3 8 3Z']
            case 'LINE':
                return ['M0 1.5H12']
            case 'POLYGON':
                return ['M2.51817 6.21885L8 2.23607L13.4818 6.21885L11.388 12.6631H4.61205L2.51817 6.21885Z']
            case 'POLYLINE':
                return ['M1 7L5.78947 2.66667L9.47368 6L15 1']
            case 'TEXT':
                return ['M8 3.5V12.5M8 3.5H4M8 3.5H12']
            }
        },
        svgParameters (): { viewBox: string, width: string, height: string } {
            switch (this.toolName) {
                case 'ARROW':
                    return {
                        viewBox: '0 0 14 13',
                        width: '14',
                        height: '13',
                    }
                case 'ARC':
                    return {
                        viewBox: '0 0 13 15',
                        width: '13',
                        height: '15',
                    }
                case 'CLOUD':
                    return {
                        viewBox: '0 0 16 16',
                        width: '16',
                        height: '16',
                    }
                case 'ELLIPSE':
                    return {
                        viewBox: '0 0 12 12',
                        width: '12',
                        height: '12',
                    }
                case 'LINE':
                    return {
                        viewBox: '0 0 12 3',
                        width: '12',
                        height: '3',
                    }
                case 'POLYGON':
                    return {
                        viewBox: '0 0 16 16',
                        width: '16',
                        height: '16',
                    }
                case 'POLYLINE':
                    return {
                        viewBox: '0 0 16 8',
                        width: '16',
                        height: '8',
                    }
                case 'TEXT':
                    return {
                        viewBox: '0 0 16 16',
                        width: '16',
                        height: '16',
                    }
            }
        }
    }
})
