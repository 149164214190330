import InvoicesTab from '../enums/InvoicesTab'
import ApplicationPackage from '../interfaces/ApplicationPackage'
import ApplicationPackageStatus from '../enums/ApplicationPackageStatus'
import Invoice from '../interfaces/Invoice'
import BillingDateStatus from '../enums/BillingDateStatus'
import LienWaiverStatus from '../enums/lien-waivers/LienWaiverStatus'
import InvoiceStatus from '../enums/InvoiceStatus'
import PaymentStatus from '../enums/PaymentStatus'
import { DotVariant } from '@/components/atoms/status-dot/enums/StatusDotEnums'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'

const statusColorsMixin = {
    methods: {
        tabName (type: InvoicesTab): string {
            switch (type) {
                case InvoicesTab.Received:
                    return 'Vendor Invoices'
                case InvoicesTab.Shared:
                    return 'Shared Invoices'
                case InvoicesTab.My:
                    return 'Your Invoices'
                case InvoicesTab.AppPackages:
                    return 'Application Packages'
                default:
                    return ''
            }
        },
        isInvoiceType (item: Invoice | ApplicationPackage): boolean {
            return undefined !== (item as Invoice).invoice_number
        },
        getStatusPillVariant (item: Invoice | ApplicationPackage): string {
            if (this.isInvoiceType(item)) {
                return this.getInvoiceStatusColor(item.status)
            }
            return this.getApplicationPackageStatusColor(item.status)
        },
        getApplicationPackageStatusColor (status: string): string {
            return {
                [ApplicationPackageStatus.PartiallyApproved]: PillVariant.GRAY_LIGHT,
                [ApplicationPackageStatus.ReviewedAndApproved]: PillVariant.GREEN_LIGHT,
                [ApplicationPackageStatus.SubmittedForFunding]: PillVariant.BLUE_LIGHT,
                [ApplicationPackageStatus.ApprovedForFunding]: PillVariant.GREEN_LIGHT,
                [ApplicationPackageStatus.PartiallySubmittedForFunding]: PillVariant.BLUE_LIGHT,
                [ApplicationPackageStatus.PartiallyRequestedRevision]: PillVariant.YELLOW_LIGHT,
                [ApplicationPackageStatus.PartiallyApprovedForFunding]: PillVariant.GREEN_LIGHT,
                [ApplicationPackageStatus.RequestedRevision]: PillVariant.YELLOW_LIGHT,

                [ApplicationPackageStatus.Draft]: PillVariant.BLUE_LIGHT,
                [ApplicationPackageStatus.SubmittedToClient]: PillVariant.BLUE_LIGHT,
                [ApplicationPackageStatus.RejectedByClient]: PillVariant.RED_LIGHT,
                [ApplicationPackageStatus.ApprovedByClient]: PillVariant.GREEN_LIGHT,
                [ApplicationPackageStatus.InternallyApproved]: PillVariant.GREEN_LIGHT,
                [ApplicationPackageStatus.InternallyRejected]: PillVariant.RED_LIGHT,
                [ApplicationPackageStatus.SubmittedToAccounting]: PillVariant.BLUE_LIGHT,
                [ApplicationPackageStatus.RejectedByAccounting]: PillVariant.RED_LIGHT,
                [ApplicationPackageStatus.PartiallyPaid]: PillVariant.YELLOW_LIGHT,
                [ApplicationPackageStatus.Paid]: PillVariant.GREEN_LIGHT
            }[status] || PillVariant.YELLOW_LIGHT

        },
        getInvoiceStatusColor (status: string): string {
            return {
                [InvoiceStatus.Sent]: PillVariant.BLUE_LIGHT,
                [InvoiceStatus.Pending]: PillVariant.YELLOW_LIGHT,
                [InvoiceStatus.RevisedAndResubmit]: PillVariant.YELLOW_LIGHT,
                [InvoiceStatus.DeclinedToInvoice]: PillVariant.RED_LIGHT,
                [InvoiceStatus.SavedAsVendorSubmission]: PillVariant.YELLOW_LIGHT,
                [InvoiceStatus.UnderReview]: PillVariant.BLUE_LIGHT,
                [InvoiceStatus.RejectedForFunding]: PillVariant.RED_LIGHT,
                [InvoiceStatus.PendingModified]: PillVariant.YELLOW_LIGHT,
                [InvoiceStatus.Rejected]: PillVariant.RED_LIGHT,
                [InvoiceStatus.Approved]: PillVariant.GREEN_LIGHT,
                [InvoiceStatus.SubmittedForFunding]: PillVariant.BLUE_LIGHT,
                [InvoiceStatus.ApprovedForFunding]: PillVariant.GREEN_LIGHT,

                //invoice v3
                [InvoiceStatus.Draft]: PillVariant.BLUE_LIGHT,
                [InvoiceStatus.PendingInternalApproval]: PillVariant.YELLOW_LIGHT,
                [InvoiceStatus.InternallyApproved]: PillVariant.GREEN_LIGHT,
                [InvoiceStatus.InternallyRejected]: PillVariant.RED_LIGHT,
                [InvoiceStatus.SubmittedToClient]: PillVariant.BLUE_LIGHT,
                [InvoiceStatus.RejectedByClient]: PillVariant.RED_LIGHT,
                [InvoiceStatus.RequestedRevision]: PillVariant.YELLOW_LIGHT,
                [InvoiceStatus.RevisionRequested]: PillVariant.BLUE_LIGHT,
                [InvoiceStatus.Voided]: PillVariant.RED_LIGHT,
                [InvoiceStatus.ApprovedByClient]: PillVariant.GREEN_LIGHT,
                [InvoiceStatus.SubmittedToAccounting]: PillVariant.BLUE_LIGHT,
                [InvoiceStatus.RejectedByAccounting]: PillVariant.RED_LIGHT,
                [InvoiceStatus.PartiallyPaid]: PillVariant.YELLOW_LIGHT,
                [InvoiceStatus.Paid]: PillVariant.GREEN_LIGHT,
            }[status] || PillVariant.YELLOW_LIGHT
        },
        getLienWaiverStatusColor (status: string): string {
            return {
                [LienWaiverStatus.NoLienWaiver]: DotVariant.GREY,
                [LienWaiverStatus.NotReceived]: DotVariant.RED,
                [LienWaiverStatus.Draft]: DotVariant.BLUE,
                [LienWaiverStatus.RequestSent]: DotVariant.BLUE,
                [LienWaiverStatus.Sent]: DotVariant.BLUE,
                [LienWaiverStatus.Requested]: DotVariant.YELLOW,
                [LienWaiverStatus.PendingReview]: DotVariant.YELLOW,
                [LienWaiverStatus.RevisionRequested]: DotVariant.YELLOW,
                [LienWaiverStatus.ApprovedConditional]: DotVariant.GREEN,
                [LienWaiverStatus.ApprovedUnconditional]: DotVariant.GREEN,
                [LienWaiverStatus.Attached]: DotVariant.GREEN,
                [LienWaiverStatus.NotAttached]: DotVariant.RED,
                [LienWaiverStatus.AttachedConditional]: DotVariant.GREEN,
                [LienWaiverStatus.AttachedUnconditional]: DotVariant.GREEN,
                [LienWaiverStatus.ReceivedConditional]: DotVariant.GREEN,
                [LienWaiverStatus.ReceivedUnconditional]: DotVariant.GREEN
            }[status] || DotVariant.YELLOW
        },
        getPaymentStatusColor (status: string): string {
            return {
                [PaymentStatus.NotPaid]: DotVariant.RED,
                [PaymentStatus.PartiallyPaid]: DotVariant.YELLOW,
                [PaymentStatus.Paid]: DotVariant.GREEN
            }[status] || DotVariant.YELLOW
        },
        getBillingDateStatusColor (status: string): string {
            return {
                [BillingDateStatus.Overdue]: DotVariant.RED,
                [BillingDateStatus.NearPaymentDate]: DotVariant.YELLOW,
                [BillingDateStatus.NoRisk]: DotVariant.GREEN
            }[status] || DotVariant.YELLOW
        },
    }
}

export default statusColorsMixin
