import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'

import { SetType } from '@/io-modules/drawings/enums/SetType.ts'
import { Set } from '@/io-modules/drawings/interfaces/Set.ts'
import { SetUploadingHistory } from '@/io-modules/drawings/interfaces/SetUploadingHistory.ts'
import { SetStatus } from '@/io-modules/drawings/enums/SetStatus.ts'
import { NewSetForm } from '@/io-modules/drawings/interfaces/NewSetForm.ts'
import { PendingSet } from '@/io-modules/drawings/interfaces/PendingSet.ts'
import { ProcessingDrawingSetResponse } from '@/io-modules/drawings/interfaces/ProcessingDrawingSetResponse.ts'
import { ConfigurationPayload } from '@/io-modules/drawings/interfaces/Ocr.ts'
import { DrawingSet } from '@/io-modules/drawings/interfaces/DrawingSet.ts'
import { DrawingSheetModel } from '@/io-modules/drawings/interfaces/DrawingSheetModel.ts'
import { DrawingSheet } from '@/io-modules/drawings/interfaces/DrawingSheet.ts'
import { ProcessingDrawingSetSheetResponse } from '@/io-modules/drawings/interfaces/ProcessingDrawingSetSheetResponse.ts'
import { Person } from '@/io-modules/drawings/interfaces/Person.ts'

const setsClient = {
    getDrawingsByType: (
        projectId: string,
        type: SetType[],
        archivedSetsIncluded?: boolean,
    ): AxiosPromise<{ items: Set[]; taken: number }> => client.post('/ca/drawing/set/lazy-list/0', {
        project: projectId,
        filters: {
            type,
            ...(!archivedSetsIncluded ? { status: [SetStatus.ACTIVE] } : {}),
        },
    }),

    getUploadingHistory: (id: string): AxiosPromise<{ items: SetUploadingHistory[] }> =>
        client.get(`ca/drawing_set/${ id }/drawing_set_processing/archived`),

    renameSet: (id: string, name: string): AxiosPromise<Set> =>
        client.patch(`/ca/drawing/set/rename/${ id }`, { name }),

    archiveSet: (id: string): AxiosPromise<Set> =>
        client.delete(`/ca/drawing/set/archive/${ id }`),

    unarchiveSet: (id: string): AxiosPromise<Set> =>
        client.patch(`/ca/drawing/set/unarchive/${ id }`),

    uploadNewSet: (id: string, payload: NewSetForm): AxiosPromise<PendingSet> =>
        client.post(`/ca/drawing/set/processing/${ id }`, payload),

    getPendingSet: (id: string): AxiosPromise<PendingSet> =>
        client.get(`/ca/drawing/set/processing/${ id }`),

    getPendingSets: (projectId: string): AxiosPromise<PendingSet[]> =>
        client.get('/ca/drawing/set/processing', { params: { project_id: projectId } }),

    deletePendingSet: (id: string): AxiosPromise<PendingSet> =>
        client.delete(`/ca/drawing/set/processing/${ id }`),

    getProcessingSet: (projectId: string): AxiosPromise<ProcessingDrawingSetResponse> =>
        client.get(`ca/drawing_set_processing/${ projectId }/drawing_processing`),

    processSet: (id: string): AxiosPromise<void> =>
        client.post(`/ca/drawing/set/processing/${ id }/process`),

    configureSet: (id: string, payload: ConfigurationPayload): AxiosPromise<void> =>
        client.post(`/ca/drawing/set/processing/${ id }/ocr/configuration`, payload),

    makeCrossLinks: (id: string): AxiosPromise<void> =>
        client.post(`/ca/drawing/set/crosslink/${ id }`),

    approveDrawingProcessing: (id: string): AxiosPromise<DrawingSet> =>
        client.post(`/ca/drawing/set/processing/${ id }/approve`),

    getDrawingVersion: (versionId: string, payload?: { filters: unknown }): AxiosPromise<DrawingSheetModel> =>
        client.post(`/ca/drawing/version/${ versionId }`, payload),

    updateSharedWith: (setId: string, payload: {
        shared_with_ids: { id: string, is_invited: boolean }[]
    }): AxiosPromise<void> =>
        client.post(`/ca/drawing/set/${ setId }/shared-with`, payload),

    markSheetAsFavorite: (data: { id: string; value: boolean }): Promise<Set> =>
        client.post('/ca/drawing/mark-as-favorite', data),

    unarchiveDrawingSheet: (setId: string): Promise<DrawingSheet> =>
        client.patch(`/ca/drawing/${ setId }/unarchive`),

    archiveDrawingSheet: (setId: string): Promise<DrawingSheet> =>
        client.patch(`/ca/drawing/${ setId }/archive`),

    updateManyIsPublished: (drawingSetProcessingId: string, isPublished: boolean): AxiosPromise<{
        items: ProcessingDrawingSetSheetResponse[]
    }> =>
        client.put(`ca/drawing/set/processing/${ drawingSetProcessingId }/drawings/is-published`, { is_published: isPublished }),

    getDrawingSet: (id: string): AxiosPromise<Set> =>
        client.get(`/ca/drawing/set/${ id }`),

    changeDrawingSetType: (id: string, type: SetType): AxiosPromise<Set> =>
        client.patch(`/ca/drawing/set/mark-type-as/${ id }/type/${ type }`),

    getDrawingSetVisibility: (): AxiosPromise<{ id: string, name: string }[]> =>
        client.get('/ca/drawing/dictionaries/annotations/visibility'),

    getUsersWithAccess: (setId: string, projectId: string): AxiosPromise<Person[]> =>
        client.get(`/ca/drawing/set/${ setId }/users-with-access`, {
            params: {
                project_id: projectId,
            },
        }),
}

export default setsClient
