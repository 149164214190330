import ApprovalsListFilters
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListFilters'

const ApprovalsInboxEmptyFilters: ApprovalsListFilters = {
    type: [],
    company: [],
    approver: [],
    start_date: null,
    end_date: null
}

export default ApprovalsInboxEmptyFilters