<template>
    <div
        v-if="logo"
        :class="['io-company-logo', sizeClassName]"
        :style="getBackgroundCompanyLogo(logo)"
    ></div>
    <div v-else :class="['io-company-logo io-company-logo-placeholder', sizeClassName]"></div>
</template>
 
<script lang="ts" src="./CompanyLogo.ts"></script>
<style lang="scss" src="./CompanyLogo.scss" scoped></style>
