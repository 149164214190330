import { isFeatureFlagEnabled } from '@/modules/projects/routes/feature-flag-status.ts'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import { ROUTE_NAME } from './constants/RouteName.ts'

const DocumentsMainView = () => import('@/io-modules/documents-v2/views/documents-main-view/DocumentsMainView.vue')

const defaultMeta = {
    name: 'DocumentsV2',
    navigationKey: 'documentsV2'
}

export default [
    {
        name: ROUTE_NAME,
        path: '/documents-v2/:virtualFolderId?/:fileId?',
        meta: defaultMeta,
        component: DocumentsMainView,
        async beforeEnter (to, from, next) {
            if (!to.params.virtualFolderId) {
                next({ name: ROUTE_NAME, params: { virtualFolderId: 'home' } })
            }

            next(await isFeatureFlagEnabled(FeatureFlagsConsts.DOCUMENTS_V2, false))
        },
    },
]
