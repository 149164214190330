<template>
    <td>
        <div v-if="status" class="io-status-pill" :class="classes">{{ statusText }}</div>
        <div v-else>-</div>

        <template v-if="showToolTip">
            <IconTooltip :text="$t('This shared contract is not mapped to Your Budget cost codes.')"/>
        </template>
    </td>
</template>

<script lang="ts" src="./StatusPill.ts"></script>
<style lang="scss" src="./StatusPill.scss" scoped></style>
