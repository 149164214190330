import { ApiClient } from '@/api/api'

let client = ApiClient

export default {
    createInvitation (data) {
        return client.realJson('/invitation/create', data)
    },
    invitationRemove (data) {
        let postData = {
            email: data.email,
            mongo: data.mongoId || data.id,
            type: data.type || null,
            model_section: data.section,
            mongoCompany: data._company_mongo,
            mongoEmployee: data._employee_mongo
        }
        return client.post('/invitation/remove', postData)
    },
    getPending (id, type, section = null) {
        const data = {
            type: type,
            section: section
        }
        return client.post(`/get-pending-invitations-for/${id}`, data)
    },
    getAttached (id, type, section = null) {
        const data = {
            type: type,
            section: section
        }
        return client.post(`/get-attached-for/${id}`, data)
    },
    getInvitationsForResource (id, type, section) {
        const data = {
            type,
            model_id: id,
            model_section: section
        }
        return client.post('/get-invitations-for-resource', data)
    },
    getInvited (id, type, section = null) {
        const data = {
            type: type,
            section: section
        }
        return client.post(`/get-connected-invitations-for/${id}`, data)
    },
    getAllInvited (id, type, section = null) {
        const data = {
            id: id,
            type: type,
            section: section
        }
        return client.post(`/all-invited-connected`, data)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error))
    },

    getWorkspacesByEmail (email, types, companyMongoId = null, restrictedAppTypes = null) {
        return client.post('/invitation-email-search', {
            email: email,
            types: types,
            company_mongo_id: companyMongoId,
            restricted_app_types: restrictedAppTypes
        })
    },
    checkExistingInvitation (email, restrictedAppTypes = null) {
        return client.post('/invitation/check-existing', {
            email: email,
            restricted_app_types: restrictedAppTypes
        })
    }
}
