import { AxiosPromise } from 'axios'
import { ApiClientV3 as client } from '@/api/api'
import type { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'

const budgetSiteClient = {
    getSitesByFinanceable (financeableId: string, financeableType: string): AxiosPromise<Omit<ProjectSite, '$isDisabled'>[]> {
        return client.get(`financeable-sites/${ financeableType }/${ financeableId }`)
    },

    getSitesByProject (projectId: string, budgetType?: string): AxiosPromise<Omit<ProjectSite, '$isDisabled'>[]> {
        return client.get(`projects/${ projectId }/financeable-sites`, { params: { budget_type: budgetType } })
    },
}

export default budgetSiteClient
