import { defineComponent } from 'vue'
import getCascadeAnimationDelay from '@/helpers/getCascadeAnimationDelay.ts'
import Table from '@/components/table/Table.vue'
import IOThumbnail from '@/components/atoms/IOThumbnail/IOThumbnail.vue'
import type { HeaderTable } from '@/components/table/HeaderTableInterface.ts'
import type { Sheet } from '@/components/drawing-reference-select/interfaces/Sheet.ts'

export default defineComponent({
    name: 'SheetsListView',
    components: {
        Table,
        IOThumbnail,
    },
    props: {
        sheets: {
            type: Array as () => Sheet[],
            default: () => [],
        },
    },
    emits: ['select'],
    setup () {
        return {
            getCascadeAnimationDelay,
        }
    },
    computed: {
        tableHeaders (): HeaderTable[] {
            return [
                {
                    sort: false,
                },
                {
                    text: this.$t('Number'),
                    value: 'page_label',
                },
                {
                    text: this.$t('Title'),
                    value: 'page_title',
                },
                {
                    text: this.$t('Category'),
                    value: 'page_category',
                },
                {
                    text: this.$t('Issue Date'),
                    value: 'drawing_set_issue_date',
                },
                {
                    text: this.$t('Upload Date'),
                    value: 'updated_at',
                },
                {
                    text: this.$t('Version'),
                    value: 'current_version_number',
                },
            ]
        },
    },
})
