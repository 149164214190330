enum Resource {
    BUDGET_APPROVAL = 'budget_approval',
    BUDGET_CHANGES = 'budget_changes',
    CONTRACT = 'contract',
    INITIAL_CONTRACT = 'initial-contract',
    ORIGINAL_CONTRACT = 'original-contract',
    CHANGE_ORDER = 'change-order',
    AMENDMENT = 'amendment',
    INVOICE = 'invoice',
    APPLICATION_PACKAGE = 'application-package',
    OwnerBudgetChange = 'owner-budget-change',
    MyBudgetChange = 'my-budget-change',
    OwnerBudgetPhase = 'owner-budget-phase',
    MyBudgetPhase = 'my-budget-phase',
}

export default Resource
