<template>
    <PopupModal
        class="io-reject-modal"
        :fullScreen="true"
        :width="600"
        @close="closePopup"
    >
        <template #header>
            <div class="io-popup-modal__icon"><i class="fal fa-times-circle"></i></div>
            <div class="io-popup-modal__title"> {{ $t('Reject Invitation') }} </div>
            <div class="io-popup-modal__close" @click.stop="closePopup"><i class="fal fa-times"></i></div>
        </template>

        <template #default>
            <p class="io-reject-modal__confirmation">{{ $t('Are you sure you want to reject the invitation ?') }}</p>
            <p v-if="customizedMessage" class="io-reject-modal__custom">{{ customizedMessage }}</p>
            <label class="io-reject-modal__label">
                <b>{{ $t('Add Note') }}</b>
            </label>
            <limitedTextarea
                :dynamicHeight="true"
                :maxLength="140"
                :upperCounter="true"
                v-model="reason"
            />
        </template>

        <template #footer>
            <IOButton variant="secondary" @click.stop="closePopup">{{ $t('Cancel') }}</IOButton>
            <IOButton variant="primary" @click.stop="rejectInvitation()">{{ $t('Yes, Reject') }}</IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./RejectModal.ts"></script>
<style lang="scss" src="./RejectModal.scss" scoped></style>
