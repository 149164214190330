const DocumentsMain = () => import(/* webpackChunkName: "documents" */ '@/modules/documents/main/main.vue')
const DocumentDetails = () => import(/* webpackChunkName: "documents" */ '@/modules/documents/details/details.vue')

export default [
    {
        path: '/documents-list/:folderId?',
        name: 'documents-list',
        meta: {
            inviteType: 'document',
            title: 'Documents List'
        },
        component: DocumentsMain,
    },
    {
        path: '/documents-details/:id',
        name: 'documents-details',
        meta: { inviteType: 'document' },
        component: DocumentDetails,
        children: [
            {
                path: ':annotationMode',
                name: 'documents-detail-annotation-mode',
                component: DocumentsMain,
                meta: {
                    inviteType: 'document',
                    title: 'Document Details'
                }
            }
        ]
    }
]
