<template>
    <div class="io-float-menu io-design-system" :class="{ 'io-float-menu--static': static }">
        <slot v-if="!static" />

        <ul class="io-float-menu-actions">
            <li
                v-for="(action, idx) in actions"
                :key="idx"
                class="io-float-menu-actions__item"
                :style="!static && getCascadeAnimationDelay(idx)"
            >
                <Component
                    :is="action.thumbnail ? 'IOThumbnail' : 'div'"
                    v-bind="action.thumbnail"
                >
                    <button
                        v-tooltip.bottom="action.tooltip ? action.tooltip : { visible: false }"
                        :data-testid="action.name"
                        class="io-float-menu-actions__item-btn"
                        @click.stop="$emit(action.name)"
                    >
                        <i :class="action.icon" />
                    </button>
                </Component>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" src="./FloatMenu.ts"></script>
<style scoped lang="scss" src="./FloatMenu.scss"></style>
