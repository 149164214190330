import { defineComponent } from 'vue'
import useLoader from '@/composables/useLoader.ts'
import { mapGetters } from 'vuex'

import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'
import drawingAnnotationsClient from '@/io-modules/drawings/api-clients/drawingAnnotationsClient.ts'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar.ts'

export default defineComponent({
    name: 'AddPunch',
    setup () {
        const { load } = useLoader({ globalSpinner: true })

        return { load }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
            annotationObject: 'getAnnotationObject',
            selectedAnnotation: 'getSelectedAnnotation',
        }),
    },
    watch: {
        annotationObject (): void {
            if (this.annotationObject.type === RightSideBar.PUNCH) {
                this.createAnnotation()
            }
        },
    },
    methods: {
        async createAnnotation (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const postData = {
                    version_id: this.model.drawing.current_version_id,
                    type: RightSideBar.PUNCH,
                    xfdf_raw: this.selectedAnnotation.xfdf,
                    uuid: this.selectedAnnotation.uuid,
                    relation_id: this.annotationObject.id,
                }

                await drawingAnnotationsClient.createPunchAnnotation(postData)
                this.$store.commit('drawingMarkup/SET_ANNOTATION_ADDED', true)
                const { data } = await setsClient.getDrawingVersion(this.model.drawing.current_version_id)
                this.$store.commit('drawingMarkup/SET_MODEL', data)
            })
        },
    },
})
