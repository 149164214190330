<template>
    <modal v-if="showModal === 'company-edit'" :customStyle="customStyle">
        <template #body>
            <company-edit
                :modal-mode="true"
                :modal-data="additionalData"
            />
        </template>
    </modal>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import {mapState} from 'vuex'
    
    const CompanyEdit = defineAsyncComponent(() => import(/* webpackChunkName: "company" */ '@/modules/company/views/edit/edit.vue'))

    export default {
        name: 'add-company',
        components: {
            CompanyEdit
        },
        computed: {
            ...mapState('modal', {
                showModal: state => state.show,
                returnData: state => state.returnData,
                editItemId: state => state.editItemId,
                additionalData: state => state.additionalData
            }),

            customStyle () {
                return {
                    'z-index': '9999 !important'
                }
            }
        }
    }
</script>
