import Accordion from '@/components/accordion/Accordion.vue'
import InvoicesListTable from '@/io-modules/invoices/components/invoices-list/invoices-list-table/InvoicesListTable.vue'
import InvoicesTab from '@/io-modules/invoices/enums/InvoicesTab'
import VendorDetails from '@/io-modules/invoices/interfaces/VendorDetails'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName'
import { defineComponent, nextTick } from 'vue'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { mapState as mapStateVuex } from 'vuex'
import { mapActions, mapState } from 'pinia'
import { HeaderTable } from '@/components/table/HeaderTableInterface'

export default defineComponent({
    name: 'InvoicesVendorsList',
    components: {
        Accordion,
        InvoicesListTable,
    },
    data () {
        return {
            InvoicesTab,
            loading: false,
            loadingData: false,
        }
    },
    computed: {
        ...mapState(invoicesStore, [
            'getInvoicesListHeaders',
            'receivedInvoicesVendors',
            'vendorsInvoices',
            'filtersListingsParams',
        ]),

        ...mapStateVuex('project', ['projectObj']),

        vendors (): VendorDetails[] {
            return this.receivedInvoicesVendors.sort((a, b) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }

                return 0
            })
        },

        invoicesHeaders (): HeaderTable[] {
            return this.getInvoicesListHeaders(InvoicesTab.Received).filter(header => 'vendor' !== header.key)
        },
    },
    watch: {
        filtersListingsParams: {
            async handler (): Promise<void> {
                this.setContentLoaderValue(true)
                await this.fetchReceivedInvoicesVendors(this.projectObj.project_local_id)
                this.setContentLoaderValue(false)
            },
            deep: true,
            immediate: false
        }
    },
    async mounted () {
        await nextTick()
        this.setExpandableList(this.$refs.vendors)
    },
    methods: {
        ...mapActions(invoicesStore, [
            'setExpandableList',
            'collapseAllList',
            'fetchVendorInvoices',
            'setContentLoaderValue',
            'fetchReceivedInvoicesVendors',
            'clearListParams',
        ]),

        showVendorDetails (id: string): void {
            if (id) {
                this.$router.push({ name: InvoiceRouteName.InvoicesReceivedVendorDetails, params: { id } })
            }
        },

        getVendorHeaderPillText (vendor: VendorDetails): string {
            return `${ vendor.invoices_count } ${ vendor.invoices_count > 1 ? this.$t('Invoices') : this.$t('Invoice') }`
        },

        toggleItem (value: boolean, index: number, id: string): void {
            if (value && id) {
                this.collapseAllList(index)
                this.clearListParams()
                this.loadVendorInvoices(id)
            }
        },

        async loadVendorInvoices (id: string): Promise<void> {
            try {
                this.loading = true
                await this.fetchVendorInvoices(this.projectObj.project_local_id, id)
            } catch (error) {
                console.log(error)
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            } finally {
                this.loading = false
            }
        },

        async loadData (vendorId: string): Promise<void> {
            this.loadingData = true
            await this.fetchVendorInvoices(this.projectObj.project_local_id, vendorId)
            this.loadingData = false
        },
    },
})
