<template>
    <SheetsShow
        :categories="[]"
        :defaultPanel="''"
        :additionallyDisabledElements="[
            'ribbonsDropdown',
            'toolsHeader',
            'ribbons',
            'pinOffButton',
            'detailsOnButton',
            'tocOffButton'
        ]"
        :disableExistingAnnotations="true"
        :fromPunchList="true"
    >
        <IOButton variant="secondary" icon="icon-undo" @click="hideDrawingSheet">{{ $t('Back') }}</IOButton>
        <IOButton variant="secondary" @click="cancelDrawingSheet">{{ $t('Cancel') }}</IOButton>
    </SheetsShow>
</template>

<script lang="ts" src="./DrawingSheet.ts"></script>
