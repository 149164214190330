export default {
    refreshTime: null,

    app_types: [],
    app_types_map: {},

    states: [],
    countries: [],
    departments: [],
    countries_phones_codes: [],
    units_materials: [],
    units_budget_item: [],
    sectors: [],
    reimbursable_types: [],
    divisions: [],
    divisions_custom: [],
    types_of_service: [],
    contract_types: [],
    contract_holders: [],
    units_systems: [],
    deliver_methods: [],
    bid_questions_types: [],
    currencies: [],
    currencies_list: [],
    area_types: [],

    custom_field_types: [],
    custom_field_lists_types: [],

    agreement_types: []
}
