<template>
    <PopupModal key="io-popup-select-doc" class="io-popup-select-doc" :width="900" @close="close">
        <template #header>
            <div class="io-popup-modal__icon"><span class="icon-file-text"></span></div>
            <div class="io-popup-modal__title">{{ $t('Select Document') }}</div>
            <div class="io-popup-modal__close" @click="close"><i class="fal fa-times"></i></div>
        </template>

        <template #default>
            <div class="io-popup-select-doc__container">
                <SearchInput
                    :loader="isSearching"
                    :searchVal="searchVal"
                    @searchChanged="(val) => searchVal = val"
                />
                <Breadcrumbs
                    :delegateNavigation="true"
                    :arrayId="filesArrayId"
                    :useRouting="false"
                    @clearSearch="clearSearch"
                    @handleNavigation="onHandleNavigation"
                />

                <div v-if="sendingFiles" class="io-loader-small"></div>
                <div class="io-popup-select-doc__files-holder">
                    <SingleTab
                        :key="modelId + uniqueId()"
                        :allowToSelectFiles="true"
                        :arrayId="filesArrayId"
                        :filesSection="filesSection"
                        :isDocumentsView="true"
                        :isLocalOrder="true"
                        :isProjectView="false"
                        :isSharingFeatureActive="false"
                        :modelId="modelId"
                        :modelType="modelType"
                        :searchKeyword="searchVal"
                        :subSection="subSection"
                        :tabType="singleTabType"
                        :allowedExtensions="allowedExtensions"
                        :delegateNavigation="true"
                        @clearSearch="clearSearch"
                        @handleNavigation="onHandleNavigation"
                    />
                    <div v-if="!documentsFullyLoaded" ref="limitOfScrollableArea"/>
                </div>

                <div v-if="isLoading" class="io-loading-spinner"><span class="icon-loader-2 fa-spin"></span></div>
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="close()">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton variant="primary" :disabled="!hasSelectedFiles" @click="cloneSelectedFiles()">
                {{ $t('Add Selected') }}
            </IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./SelectFilesPopup.ts"></script>
<style lang="scss" src="./SelectFilesPopup.scss" scoped></style>
