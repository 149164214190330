import { defineComponent } from 'vue'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys.ts'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes.ts'
import { InvitationModelTypes } from '@/interfaces/components/invite-modal/InviteModalModelTypes.ts'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import { Contact, InviteModalDoneEvent } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'
import useLoader from '@/composables/useLoader.ts'
import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'

export default defineComponent({
    components: {
        InvitePreview,
    },
    props: {
        drawingSetId: { type: String },
        drawingSetIsPublic: { type: Boolean, required: true },
        employeesList: { type: Array, required: false, default: () => [] },
        sharedWith: {
            type: Array as () => Contact[],
            default: () => [],
        },
    },
    setup () {
        const { loading, load } = useLoader()

        return { loading, load }
    },
    data () {
        return {
            invitationClientKey: InviteModalClientKeys.DRAWING_DRAFT,
            invitationModelTypes: InvitationModelTypes,
            previewTypes: PreviewTypes,
        }
    },
    methods: {
        async onInviteModalDone (event: InviteModalDoneEvent): Promise<void> {
            return this.load(async () => {
                await setsClient.updateSharedWith(this.drawingSetId, {
                    shared_with_ids: [
                        ...event.invited.map((item: Contact) => ({ id: item.id, is_invited: true })),
                        ...event.bookmarked.map((item: Contact) => ({ id: item.id, is_invited: false })),
                    ],
                })
            })
        },
    },
})
