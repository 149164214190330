<template>
    <div class="io-invite-modal__horizontal-full-list io-f-row" data-testId="invite-preview-contact-list-full">
        <template v-for="(contact, index) in selectedContactsList" :key="contact.id">
            <div class="io-single-contact-full">
                <div
                    v-if="contact.id && contact.avatar"
                    :key="index"
                    :class="['io-img-holder', { 'io-img-holder--large': isLargeIcon }]"
                    :style="getBackgroundAvatar(contact.id)"
                />
                <Initials
                    v-else
                    :class="['io-img-holder io-f-column', { 'io-img-holder--large': isLargeIcon, 'io-offsystem-logo': isArchivedOrPendingOrOffSystem(contact) }]"
                    :fullName="`${ contact.firstname } ${ contact.lastname }`"
                />
                <div class="io-single-contact-full__info">
                    <div class="io-single-contact-full__info-top">
                        <div class="io-full-name">
                            {{ contact.firstname }} {{ contact.lastname }}
                        </div>
                        <template v-if="showStatusForSingleContact">
                            <template
                                v-if="selectedContactsList[0].is_bookmarked || selectedContactsList[0].invitation?.status === 'saved'"
                            >
                                <StatusPill
                                    size="small"
                                    variant="yellow-light"
                                    :text="$t('Bookmarked')"
                                />
                            </template>

                            <template v-else>
                                <StatusPill
                                    size="small"
                                    variant="blue-light"
                                    :text="$t('Pending Invitation')"
                                />
                            </template>
                        </template>
                    </div>
                    <div class="io-single-contact-full__info-bottom">
                        <template v-if="showAppTypeForSingleContact">
                            <StatusPill
                                v-if="contact.workspace.app_type"
                                size="small"
                                :variant="getCompanyPillVariant(contact.workspace.app_type)"
                                :text="getCompanyPillText(contact.workspace.app_type)"
                            />
                            <span v-else class="io-small-pill io-grey">
                            {{ $t('Off-System') }}
                        </span>
                        </template>

                        <div class="io-company-name">
                            {{ contact.workspace.name }}
                        </div>
                    </div>
                </div>
                <IOButton
                    variant="link"
                    icon="icon-cross"
                    @click="showInviteModal"
                />
            </div>
        </template>
    </div>
    <div
        v-if="isButtonDisplayed"
        class="io-f-row mt-4"
    >
        <IOButton
            variant="link"
            :size="buttonSize"
            :icon="buttonIcon"
            @click="showInviteModal"
        >
            <span>{{ buttonLabelText }}</span>
        </IOButton>
    </div>
</template>

<script lang="ts" src="./InvitePreviewContactListFull.ts"></script>
<style src="./InvitePreviewContactListFull.scss" lang="scss" scoped></style>
