import { defineComponent, PropType } from 'vue'
import ProgressBar from '@/components/atoms/progress-bar/ProgressBar.vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import { mapState } from 'pinia'
import { projectFundingStore } from '@/io-modules/project-funding/stores/projectFundingStore'
import mathHelpers from '@/helpers/mathHelpers'
import { ProjectFundingAllocatedRows } from '@/io-modules/project-funding/interfaces/ProjectFundingAllocatedRows'
import ProjectFundingProviderFund from '@/io-modules/project-funding/interfaces/ProjectFundingProviderFund'

export default defineComponent({
    name: 'CreateProjectSpecFundRight',
    components: { IOSingleInfoRow, ProgressBar },
    props: {
        fundInfo: {
            type: Object as PropType<ProjectFundingAllocatedRows>,
            default: null
        },
        initialFundInfo: {
            type: Object as PropType<ProjectFundingProviderFund>
        }
    },
    emits: ['fundingPercentFromBudget'],
    data () {
        return {
            progressBar: [
                {
                    value: 0,
                    color: 'var(--success-darker)',
                    show_legend: false,
                },
            ],
        }
    },
    computed: {
        ...mapState(projectFundingStore, [
            'projectFundingSummary',
        ]),
        currentBudget (): number {
            return parseFloat(this.projectFundingSummary?.budget_amount) || 0
        },
        calcTotalFunding (): number {
            if (this.fundInfo) {
                // initialFundInfo - initial info from fund details modal
                // fundInfo - dynamic info from left part of this modal
                // to not double count allocated amount initial amount need to be subtracted
                const totalAllocated = parseFloat(this.projectFundingSummary.funding_amount) - parseFloat(this.initialFundInfo?.allocated_amount ?? 0)
                const allocatedInThisModal = parseFloat(this.fundInfo.allocated_amount)
                return totalAllocated + allocatedInThisModal
            }
            return 0
        },
        calcFundingPercentFromBudget (): number {
            if (this.fundInfo && this.currentBudget > 0) {
                return ((this.calcTotalFunding * 100) / this.currentBudget)
            }
            return 100
        },
        calcPercentOfProjectFunding (): string {
            let persent = 0
            const totalAllocated = parseFloat(this.projectFundingSummary.funding_amount) - parseFloat(this.initialFundInfo?.allocated_amount ?? 0)
            const allocatedInThisModal = parseFloat(this.fundInfo.allocated_amount)

            if (!allocatedInThisModal) {
                persent = 0
            } else {
                persent = mathHelpers.cutPrecision(allocatedInThisModal * 100 / (totalAllocated + allocatedInThisModal), 2)
            }

            return this.$filters.formatPercent(persent, 2)
        },
        calcRemainingBalance (): number {
            return this.calcTotalFunding - parseFloat(this.fundInfo.paid_to_date) || 0
        },
        defineIcon (): string {
            if (this.calcTotalFunding < this.currentBudget) {
                return 'icon-chevron-left'
            } else if (this.calcTotalFunding > this.currentBudget) {
                return 'icon-chevron-right'
            } else {
                return 'icon-equal'
            }
        },
    },
    watch: {
        calcFundingPercentFromBudget (): void {
            this.progressBar[0].value = this.calcFundingPercentFromBudget
            this.$emit('fundingPercentFromBudget',this.calcFundingPercentFromBudget)
        }
    },
})
