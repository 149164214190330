import { defineComponent } from 'vue'

export enum Color {
    BLUE = 'blue',
    GREEN = 'green',
    YELLOW = 'yellow',
    RED = 'red',
}

export enum Size {
    SM = 'small',
    MD = 'medium',
    LG = 'large',
}
export default defineComponent({
    name: 'IOModal',
    props: {
        title: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        color: {
            type: String as () => Color,
            default: Color.BLUE,
            validator: (value: string): boolean => Object.values<string>(Color).includes(value),
        },
        size: {
            type: String as () => Size,
            default: Size.SM,
        },
        width: {
            type: [String, Number],
            default: '',
        },
        height: {
            type: [String, Number],
            default: '',
        },
        zIndex: {
            type: [String, Number],
            default: 10,
        },
        loadingOverlayZIndex: {
            type: [String, Number],
            default: 1,
        },
        persistent: Boolean,
        slideout: Boolean,
        wide: Boolean,
        widest: Boolean,
        loading: Boolean,
        scrollable: Boolean,
        fullscreen: Boolean,
    },
    emits: ['close'],
    mounted () {
        document.addEventListener('keyup', this.closeOnEscapeKey)
    },
    unmounted () {
        document.removeEventListener('keyup', this.closeOnEscapeKey)
    },
    methods: {
        closeOnEscapeKey (e: KeyboardEvent): void {
            e.key === 'Escape' && !this.persistent && this.$emit('close')
        },
    },
})
