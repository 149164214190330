import todoClient from '@/api/todo/todoClient'
import { InvitationModelTypes } from '@/interfaces/components/invite-modal/InviteModalModelTypes'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import { cloneDeep, isObject, toInteger, isNull, isUndefined } from 'lodash'

export const setCategory = function ({ commit, dispatch }, value) {
    let payload = {
        filterName: 'due_date_range',
        filterValue: value.toLowerCase()
    }
    commit('SET_PROJECTS_WITH_TASKS', [])
    commit('SET_CATEGORY', value)
    dispatch('changeTasksFilters', payload)
}

export const tasksByItemType = function ({ commit, dispatch, state }) {
    dispatch('disableInputs', true)
    commit('SET_LOADING', true)
    commit('SET_PROJECTS_WITH_TASKS', [])
    commit('CHANGE_TASKS_FILTERS', { filterName: 'filter_projects', filterValue: [] })

    todoClient.allTasksByItemType(state.search, state.tasks_filters).then((response) => {
        if (response.data && response.data.code === 200) {
            let tasks = response.data.hasOwnProperty('tasks') ? response.data.tasks : []
            if (isObject(tasks)) {
                tasks = Object.values(tasks)
            }

            let newTasks = []
            let projectsList = []
            if (tasks) {
                tasks.forEach((task) => {
                    task.forEach((item) => {
                        newTasks.push(item)
                        item.item.tasksCount = item.tasks_count || 0
                        item.item.type = item.type || null
                        projectsList.push(item.item)
                    })
                })
            }

            commit('SET_PROJECTS_LIST', projectsList)
            commit('SET_PROJECTS_WITH_TASKS', newTasks)
        }
        commit('SET_RENDERED', true)
        commit('SET_LOADING', false)
        dispatch('loadingBar/setLoading', false, { root: true })
        dispatch('disableInputs', false)
    })
}

/**
 * Load elements using elementData filtering
 *
 * @param commit
 * @param dispatch
 * @param state
 */
export const tasksByElement = function ({ commit, dispatch, state }) {
    dispatch('disableInputs', true)
    commit('SET_LOADING', true)
    commit('SET_PROJECTS_WITH_TASKS', [])
    todoClient.tasksByElement(state.elementData).then((response) => {
        if (response.data && response.data.code === 200) {
            let tasks = response.data.hasOwnProperty('tasks') ? response.data.tasks : null
            commit('SET_ELEMENT_TASKS', tasks)
        }
        commit('SET_RENDERED', true)
        commit('SET_LOADING', false)
        dispatch('loadingBar/setLoading', false, { root: true })
        dispatch('disableInputs', false)
    })
}

export const changeTasksFilters = function ({ commit, dispatch }, payload) {
    commit('SET_PROJECTS_WITH_TASKS', [])
    commit('CHANGE_TASKS_FILTERS', payload)
    dispatch('tasksByItemType')
}

/**
 * Change element data for filtering
 *
 * @param commit
 * @param dispatch
 * @param payload
 */
export const changeElement = function ({ commit, dispatch }, payload) {
    commit('CHANGE_ELEMENT_ID', payload)
    commit('CHANGE_ELEMENT_TYPE', payload)
    dispatch('tasksByElement')
}

export const disableInputs = function ({ commit }, value) {
    if (!value) {
        setTimeout(commit('DISABLE_INPUTS', value), 500)
    } else {
        commit('DISABLE_INPUTS', value)
    }
}

export const saveCurrentTask = function ({ commit, dispatch, state }, payload) {
    dispatch('updateTask', {
        task: state.currentTask,
        isLocked: payload ? payload.locked : undefined,
        withNotification: payload ? payload.withNotification : true
    })
}

export const updateTask = function ({ dispatch, commit }, payload) {
    if (payload.task._id) {
        dispatch('setSaving', {
            saving: true,
            saved: false
        })
        dispatch('disableInputs', true)
        let message = {}
        const afterResponse = () => {
            if (message.type === 'error') {
                dispatch('notification/setShow', message, { root: true })
                dispatch('setSaving', { saving: false, saved: false })

                return
            }

            dispatch('setSaving', { saving: true, saved: true })
            dispatch('disableInputs', false)
            dispatch('loadingBar/setLoading', false, { root: true })
            setTimeout(() => {
                dispatch('setSaving', {
                    saving: false,
                    saved: false
                })
            }, 1000)
        }
        payload.task.task_completed = toInteger(payload.task.task_completed)

        let clonedPayload = cloneDeep(payload.task)
        const hasProperty = 'members_ids' in clonedPayload
        if (!hasProperty) {
            clonedPayload.members_ids = clonedPayload.members.map((el) => ({
                id: el.id,
                is_invited: el.invitation.status === 'invited',
            }))
        }

        delete clonedPayload.invited_pending
        delete clonedPayload.invited_people
        delete clonedPayload.invited
        delete clonedPayload.members
        delete clonedPayload.attached_employees_ids

        if (payload.hasOwnProperty('isLocked')) {
            clonedPayload.is_locked = payload.isLocked
        }

        return new Promise((resolve) =>
            todoClient
                .updateTask(clonedPayload._id, clonedPayload)
                .then((response) => {
                    if (response.data && response.data.code === 200) {
                        message.text = response.data.message
                        message.type = 'success'
                        payload.task._is_read_only = response.data.result._is_read_only
                        commit('CHANGE_CURRENT_TASK_PROPERTY', {
                            propertyName: '_status',
                            propertyValue: response.data.result._status
                        })
                        commit('CHANGE_CURRENT_TASK_PROPERTY', {
                            propertyName: 'task_completed_at',
                            propertyValue: response.data.result.task_completed_at
                        })
                        commit('CHANGE_CURRENT_TASK_PROPERTY', {
                            propertyName: 'is_locked',
                            propertyValue: response.data.result.is_locked
                        })
                        commit('CHANGE_CURRENT_TASK_PROPERTY', {
                            propertyName: 'flags',
                            propertyValue: response.data.result.flags
                        })
                        commit('CHANGE_CURRENT_TASK_PROPERTY', {
                            propertyName: '_id',
                            propertyValue: response.data.result._id
                        })
                        commit('CHANGE_CURRENT_TASK_PROPERTY', {
                            propertyName: 'on_item',
                            propertyValue: response.data.result.on_item
                        })
                        commit('CHANGE_CURRENT_TASK_PROPERTY', {
                            propertyName: 'users_ball_in_court_formatted',
                            propertyValue: response.data.extra_data.task_card.users_ball_in_court_formatted
                        })
                        commit('CHANGE_CURRENT_TASK_PROPERTY', {
                            propertyName: 'members',
                            propertyValue: response.data.result.members
                        })

                        dispatch('modal/setRefresh', Date.now(), { root: true })
                        dispatch('modal/setReturnData', { type: 'modal-task', data: response.data }, { root: true })


                        if (payload.withNotification) {
                            dispatch('notification/setShow', message, { root: true })
                        }

                        afterResponse()
                        resolve(response)

                        commit('SET_TASK_SAVED', true)
                    }
                })
                .catch((error) => {
                    message.text = 'Something went wrong. Please try again later.'
                    message.type = 'error'
                    afterResponse()
                    resolve('error')
                })
        )
    }
}


export const createTask = function ({ dispatch, state }, payload) {
    dispatch('setSaving', {
        saving: true,
        saved: false
    })
    dispatch('disableInputs', true)
    let message = {}
    const afterResponse = () => {
        if (message.type === 'error') {
            dispatch('notification/setShow', message, { root: true })
            dispatch('setSaving', { saving: false, saved: false })

            return
        }

        dispatch('setSaving', { saving: true, saved: true })
        dispatch('disableInputs', false)
        dispatch('loadingBar/setLoading', false, { root: true })
        setTimeout(() => {
            dispatch('setSaving', {
                saving: false,
                saved: false
            })
        }, 1000)
    }

    return new Promise((resolve) =>
        todoClient
            .updateTask(0, state.currentTask)
            .then(async (response) => {
                response.data.result.on_item = payload

                dispatch('modal/setRefresh', Date.now(), { root: true })
                dispatch('modal/setReturnData', { type: 'modal-task', data: response.data }, { root: true })

                dispatch(
                    'inviteModal/setInviteModalModel',
                    {
                        key: InviteModalClientKeys.TASK_DETAILS,
                        id: response.data.id,
                        type: InvitationModelTypes.TASK
                    },
                    { root: true }
                )

                await dispatch(
                    'inviteModal/batchInvitations',
                    { key: InviteModalClientKeys.TASK_DETAILS },
                    { root: true }
                )

                afterResponse()
                resolve(response)
            })
            .catch((error) => {
                afterResponse()
                resolve(error)
            })
    )
}

export const setSaving = function ({ commit }, payload) {
    commit('SET_SAVING', payload)
}

export const updateTaskChecklistItem = function ({ dispatch }, payload) {
    if (payload._id) {
        dispatch('disableInputs', true)
        let message = {}
        const afterResponse = () => {
            dispatch('disableInputs', false)
            dispatch('notification/setShow', message, { root: true })
            dispatch('loadingBar/setLoading', false, { root: true })
        }
        payload.task_completed = toInteger(payload.task_completed)
        let checklistPayload = {
            checklist: payload._task_checklist_items
        }
        return new Promise((resolve) =>
            todoClient
                .updateTaskChecklist(payload._id, checklistPayload)
                .then((response) => {
                    if (response.data && response.data.code === 200) {
                        message.text = response.data.message
                        message.type = 'success'
                        dispatch('notification/setShow', message, { root: true })
                        afterResponse()
                        resolve(response)
                    }
                })
                .catch(() => {
                    message.text = 'Error! Try again later.'
                    message.type = 'error'
                    afterResponse()
                    resolve('error')
                })
        )
    }
}

export const updateTaskComplete = function ({ commit }, payload) {
    const clonedPayload = cloneDeep(payload)
    clonedPayload.task_completed = clonedPayload.task_completed ? 0 : 1
    const taskId = clonedPayload._id
    const completed = clonedPayload.task_completed

    if (taskId && !isNull(completed) && !isUndefined(completed)) {
        return todoClient.updateTaskComplete(taskId, completed)
    }
}

export const updateTaskDueDate = function ({ commit }, payload) {
    const taskId = payload._id
    const dueDate = payload.task_due_date
    if (taskId && dueDate) {
        return todoClient.updateTaskDueDate(taskId, dueDate)
    }
}

export const cancelChanges = function ({ commit }) {
    commit('CANCEL_CHANGES')
    commit('SET_EDIT_MODE', false)
}

export const calculateChecklistItemIndex = function ({ dispatch, state }, payload) {
    return state.currentTask._task_checklist_items.findIndex((item) => {
        if (item && payload) {
            return item.index === payload.index
        }
    })
}

export const editChecklistItem = function ({ commit, dispatch }, payload) {
    dispatch('calculateChecklistItemIndex', payload).then((response) => {
        payload.localIndex = response > -1 ? response : null
        commit('EDIT_CHECKLIST_ITEM', payload)
    })
}

export const setKanbanViewMode = function ({ commit }, payload) {
    commit('SET_KANBAN_VIEW_MODE', payload)
}

export const setTasksViewMode = function ({ commit }, payload) {
    commit('SET_TASKS_VIEW_MODE', payload)
}

export const setkanbanShowTags = function ({ commit }, payload) {
    commit('SET_KANBAN_SHOW_TAGS', payload)
}

export const setBoardTemplates = function ({ commit }, payload) {
    commit('SET_BOARD_TEMPLATES', payload)
}


export const addBoardTemplate = function ({ commit }, payload) {
    commit('ADD_BOARD_TEMPLATE', payload)
}

export const getBoardTemplates = function ({ commit }, payload) {
    const projId = payload.projId
    if (projId) {
        return todoClient.getBoardTemplates(projId)
    }
}

export const changeWorkflowTemplate = function ({ commit }, payload) {
    const projId = payload.projId
    const templateId = payload.templateId
    if (projId && templateId) {
        return todoClient.changeWorkflowTemplate(projId, templateId)
    }
}

export const addNewWorkflowTemplate = function ({ commit }, payload) {
    const name = payload.name
    if (name) {
        return todoClient.addNewWorkflowTemplate(name)
    }
}

export const setWorkflowData = function ({ commit }, payload) {
    commit('SET_WORKFLOW_DATA', payload)
}

export const setCurrentTaskLocked = function ({ commit }, payload) {
    commit('SET_CURRENT_TASK_LOCKED', payload)
}

export const setCurrentTaskLoading = function ({ commit }, payload) {
    commit('SET_CURRENT_TASK_LOADING', payload)
}

export const appendToCurrentTask = function ({ commit }, payload) {
    commit('APPEND_CURRENT_TASK_ADDITIONAL_DATA', payload)
}
export const setCurrentTask = function ({ commit }, payload) {
    commit('SET_CURRENT_TASK', payload)
}
export const setTaskFields = function ({ commit }, payload) {
    commit('SET_TASK_FIELDS', payload)
}
export const setExtraData = function ({ commit }, payload) {
    commit('SET_TASK_EXTRA_DATA', payload)
}
export const refreshedTask = function ({ commit }, task) {
    commit('SET_REFRESHED_TASK', task)
}
export const clearCurrentTask = function ({ commit }) {
    commit('SET_CURRENT_TASK_LOADING', true)
    commit('APPEND_CURRENT_TASK_ADDITIONAL_DATA', null)
    commit('RESET_CURRENT_TASK', null)
    commit('SET_TASK_EXTRA_DATA', null)
    commit('SET_TASK_FIELDS', null)
}
