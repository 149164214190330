<template>
    <div class="io-num-field-holder" v-bind:class="{ 'input-group': !isFloated, 'input-addon': !disableAddon }">
        <template v-if="!disabled">
            <div class="input-group-addon" v-if="!disableAddon">{{ signComputed }}</div>
            <cleave v-model="valueLocal"
                    :options="cleaveOptions"
                    :placeholder="showPlaceholder ? placeholder : ''"
                    :autocomplete="autocomplete"
                    :readonly="readonly"
                    :max="max"
                    :disabled="disabled"
                    :class="classes"
                    :name="name"
                    :id="id"
                    ref="inputCleave"
                    @keydown.tab.native="checkTabKeyup"
            ></cleave>
        </template>
        <template v-else-if="sign === '$'">
            {{ $filters.formatCurrencyInt(modelValue) }}
        </template>
        <template v-else-if="sign === '%'">
            {{ $filters.formatCurrencyValueInt(modelValue) }}
        </template>
        <template v-else>
            {{ modelValue }}
        </template>
    </div>
</template>

<script>
    import Cleave from 'vue-cleave-component'
    import { assign, isNull } from 'lodash'

    const allowedNonNumericValues = [
        '-',
        '',
        '0.',
        '-0',
        '-0.'
    ]

    export default {
        name: 'FieldNumericInt',
        components: {
            Cleave
        },
        props: {
            id: {
                type: String,
                default: '',
                required: false
            },
            name: {
                type: String,
                default: '',
                required: false
            },
            classname: {
                type: String,
                default: '',
                required: false
            },
            disableAddon: {
                type: Boolean,
                default: false,
                required: false
            },
            autocomplete: {
                type: String,
                default: '',
                required: false
            },
            sign: {
                type: String,
                default: '$',
                required: false
            },
            modelValue: {
                type: Number,
                required: true
            },
            min: {
                type: Number,
                default: null,
                required: false
            },
            max: {
                type: Number,
                default: null,
                required: false
            },
            readonly: {
                type: Boolean,
                default: false,
                required: false
            },
            options: {
                type: Object,
                default: () => ({}),
                required: false
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false
            },
            placeholder: {
                type: String,
                default: '0.00',
                required: false
            },
            showPlaceholder: {
                type: Boolean,
                default: true,
                required: false
            },
            lockTabClick: {
                type: Boolean,
                default: false,
                required: false
            },
            showRounded: {
                type: Boolean,
                default: false,
                required: false
            },
        },
        data () {
            return {
                valueLocal: null,
                duringUpdate: false,
                cleaveOptions: {
                    creditCard: false,
                    phone: false,
                    phoneRegionCode: 'US',
                    date: false,
                    datePattern: ['m', 'd', 'Y'],
                    time: false,
                    timePattern: ['h', 'm', 's'],
                    timeFormat: '12',
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralDecimalScale: 2,
                    numeralDecimalMark: '.',
                    numeralPositiveOnly: true,
                    stripLeadingZeroes: true,
                    blocks: [],
                    delimiter: ',',
                    prefix: null,
                    numericOnly: true,
                    uppercase: false,
                    lowercase: false,
                    nonNumericAsZero: false
                }
            }
        },

        watch: {
            modelValue (newData, prevData) {
                if (this.duringUpdate) {
                    return false
                }
                this.duringUpdate = true
                this.init()
                this.$nextTick(() => {
                    this.duringUpdate = false
                })
            },

            valueLocal (newData, prevData) {
                if (this.duringUpdate) {
                    return false
                }
                this.duringUpdate = true
                this.valueLocal = this.prepareValue(newData, this.showRounded)
                this.$emit('update:modelValue', this.toInt(this.prepareValue(newData)))
                this.$nextTick(() => {
                    this.duringUpdate = false
                })
            }
        },

        methods: {
            init () {
                if (this.modelValue === null && this.valueLocal === '-') {
                    return false
                } else if (this.modelValue === null) {
                    this.valueLocal = 0
                }

                this.duringUpdate = true
                this.valueLocal = this.prepareValue(this.toFloat(this.modelValue), this.showRounded)
                this.$nextTick(() => {
                    this.duringUpdate = false
                })
            },

            prepareValue (value, cutPrecision = false) {
                if (!this.cleaveOptions.numeralPositiveOnly && '-' === value) {
                    return value
                }

                if (!this.cleaveOptions.numericOnly && allowedNonNumericValues.includes(value)) {
                    return this.cleaveOptions.nonNumericAsZero ? 0 : value
                }

                if (isNaN(parseInt(value))) {
                    return 0
                }

                const precision = this.cleaveOptions.numeralDecimalScale

                if (this.limitMax !== null && value > this.limitMax) {
                    return cutPrecision ? this.cutPrecision(this.limitMax, precision) : this.limitMax
                }

                if (this.limitMin !== null && value < this.limitMin) {
                    return cutPrecision ? this.cutPrecision(this.limitMin, precision) : this.limitMin
                }

                return cutPrecision && false === this.isLastInputZeroOrDot(value) ? this.cutPrecision(value, precision) : value
            },

            isLastInputZeroOrDot (value) {
                return value.toString().substr(-1) === '.' || value.toString().substr(-1) === '0'
            },

            focus () {
                this.$refs.inputCleave.$el.focus()
            },

            checkTabKeyup (e) {
                if (this.lockTabClick) {
                    e.preventDefault()
                }
                this.$emit('onTabClick', e)
            }
        },

        beforeMount () {
            this.cleaveOptions = assign(this.cleaveOptions, this.options)
            this.init()
        },

        computed: {
            isFloated: function () {
                if (this.classname) {
                    if (this.classname.includes('fright') || this.classname.includes('fleft')) {
                        return true
                    }
                }
                return false
            },

            classes () {
                if (!isNull(this.classname) && this.classname !== '') {
                    return this.classname.split(' ')
                } else {
                    return null
                }
            },

            limitMax () {
                if (this.max === undefined || this.max === null) {
                    return null
                }
                const value = Number(this.max)
                if (Number.isNaN(value)) {
                    return null
                }
                return value
            },

            limitMin () {
                if (this.min === undefined || this.min === null) {
                    return null
                }
                const value = Number(this.min)
                if (Number.isNaN(value)) {
                    return null
                }
                return value
            },

            signComputed () {
                return this.sign === '$' ? this.getWorkspaceCurrencySymbol() : this.sign
            }
        }
    }
</script>

<style lang="scss" scoped>
    .io-num-field-holder {
        position: relative;

        &.input-addon {
            input {
                padding-left: 45px !important;
            }
        }
    }

    .input-group-addon {
        padding: 0;
        width: 30px;
        text-align: center;
        background-color: var(--light-bg);
        color: var(--closed);
        display: inline;
        font-weight: 500;
        max-height: 36px;
        line-height: 36px;
        border: 1px solid var(--main-lighten-4);
        border-radius: 4px;
        position: absolute;
    }
</style>
