import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
    props: {
        column: { type: Object, required: true },
        handle: { type: String },
        columnClass: { type: String }
    },
    computed: {
        ...mapState('lazyList', {
            selectedRows: (state) => state.selectedRows
        })
    },
    methods: {
        ...mapActions('lazyList', {
            toggleSelectedRows: 'toggleSelectedRows'
        }),
        addSelectedRow (): void {
            this.toggleSelectedRows(this.handle)
        },
        isHidden (): boolean {
            return this.column?.additional?.hidden || false
        }
    }
})
