<template>
    <div
        v-if="!showContentLoader"
        class="io-invoices-list"
        :class="{ 'io-invoices-list--empty': placeholderVisible || noExecutedMainContract }"
    >
        <Placeholder
            v-if="noExecutedMainContract"
            icon="icon-file-agreement"
            :title="$t('Ready to Invoice Your Client?')"
            :subtitle="$t('Creating a Your Invoice requires an executed main contract. Head to \'\'Project Contracts\'\' to get started.')"
            :buttons-cta="goToContractBtn"
        />
        <Placeholder
            v-else-if="showMainPlaceholder"
            icon="icon-invoice"
            :title="$t('Ready to Create Your First Invoice?')"
            :subtitle="$t('There are currently no invoices created or sent to your clients. Click \'\'Create Invoice\'\' to get started.')"
            :buttons-cta="ctaButtons"
        />

        <div v-else>
            <InvoicesListHeader :title="$t('Your Invoices')" @openManageColumns="$emit('openManageColumns')"/>
            <InvoicesListTable
                :headers="getListHeaders"
                :items="getList"
                :loading-data="loadingData"
                :totals="totals"
                @loadData="loadData"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./MyInvoicesList.ts"></script>
<style lang="scss" src="../InvoicesLists.scss" scoped></style>
