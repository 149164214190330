import { defineComponent } from 'vue'
import SingleComment from './single-comment/SingleComment.vue'
import AddComment from './add-comment/AddComment.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'
import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'
import { mapGetters } from 'vuex'

export default defineComponent({
    components: {
        SingleComment,
        AddComment,
        IOPlaceholder,
    },
    props: {
        searchValue: {
            type: String,
            required: true,
        },
        isResolved: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
            selectedAnnotation: 'getSelectedAnnotation',
        }),
        isAddingAnnotation (): boolean {
            return <boolean>(this.selectedAnnotation &&
                this.selectedAnnotation.xfdf &&
                this.selectedAnnotation.type === RightSideBar.COMMENTS)
        },
        comments (): unknown[] {
            if (this.model.annotations.comment === undefined) {
                return []
            }
            return this.model.annotations.comment.filter((annotation: unknown) => {
                return this.isResolved
                    ? annotation.is_resolved && annotation.comment.comment.toLowerCase().includes(this.searchValue)
                    : annotation.comment.comment.toLowerCase().includes(this.searchValue)
            }).slice().reverse()
        },
    },
    watch: {
        selectedAnnotation: {
            handler (newVal: unknown): void {
                if (newVal?.type === RightSideBar.COMMENTS) {
                    this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', RightSideBar.COMMENTS)
                }
            },
        },
    },
    methods: {
        onScrollToCommentDelay (commentElementId: string): void {
            const waitForMentionListDelay = 50
            setTimeout(() => this.onScrollToComment(commentElementId), waitForMentionListDelay)
        },
        onScrollToComment (commentElementId: string): void {
            const commentElement = document.getElementById(commentElementId)
            const wrapper = this.$refs.commentsWrapper as HTMLElement
            const moveScrollToExposeComment = 200
            wrapper.scrollTo({ top: commentElement.offsetTop - moveScrollToExposeComment, behavior: 'smooth' })
        },
        cancelAnnotation (): void {
            this.$emit('cancel-annotation')
        },
        annotationDeleted (uuid: string): void {
            this.$emit('annotation-deleted', uuid)
        },
        annotationDeselected (): void {
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: '',
                type: RightSideBar.DEFAULT,
            })
        },
    },
})
