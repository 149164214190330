import { defineComponent } from 'vue'
import { cloneDeep } from 'lodash'
import getCascadeAnimationDelay from '@/helpers/getCascadeAnimationDelay.ts'
import type { JobsiteLocation } from '@/components/jobsite-locations-select/interfaces/JobsiteLocations.ts'

export default defineComponent({
    name: 'JobsiteLocationsAccordion',
    props: {
        modelValue: {
            type: Array as () => string[],
            default: () => [],
        },
        jobsiteLocations: {
            type: Array as () => JobsiteLocation[],
            required: true,
        },
        expandedJobsiteLocationsIds: {
            type: Array as () => string[],
            required: true,
        },
        multiple: Boolean,
    },
    emits: ['update:modelValue', 'toggleFolding'],
    setup () {
        return {
            getCascadeAnimationDelay,
        }
    },
    data () {
        return {
            radioValue: '',
            checkboxesValues: cloneDeep(this.modelValue),
        }
    },
    computed: {
        topLevelJobsiteLocations (): boolean {
            const [firstLocation] = this.jobsiteLocations
            return !firstLocation?.level
        },
        combinedModelValue (): string[] {
            return Array.from(new Set(this.modelValue.concat(this.checkboxesValues)))
        },
    },
    watch: {
        checkboxesValues (): void {
            this.$emit('update:modelValue', this.combinedModelValue)
        },
    },
    methods: {
        getOffset (jobsiteLocation: JobsiteLocation): number {
            const topLevelOffset = 40
            const standardOffset = 32

            return jobsiteLocation.level
                ? jobsiteLocation.level * standardOffset + topLevelOffset - standardOffset
                : jobsiteLocation.level * standardOffset
        },
    },
})
