<template>
    <div class="io-cost-codes-dropdown">
        <div
            :class="{ 'is-open': optionsShown }"
            class="io-cost-codes-dropdown__button"
            @click="toggleDropdown"
        >
            <span class="io-dropdown-label">
                <span v-if="customLabel && !selectedCostCodesCount" class="io-dropdown-label__custom">{{customLabel}}</span>
                <template v-if="customLabel && selectedCostCodesCount || !customLabel">
                    <span class="io-dropdown-label__number">{{ selectedCostCodesCount + ' ' }}</span>
                    <span>{{$t('Cost Codes Selected')}}</span>
                </template>
            </span>
        </div>
        <transition name="fade">
            <div v-if="optionsShown" class="io-cost-codes-dropdown__list">
                <div class="io-cost-codes-dropdown__mask" @click="closeDropdown"></div>
                <div class="io-cost-codes-dropdown__content">
                    <div class="io-cost-codes-dropdown__options">
                        <div
                            v-if="!hideRemainingTitle || customToggleAll"
                            :class="{ 'io-minus': someChecked }"
                            class="io-atoms-checkbox io-group-check io-all-cost-codes"
                        >
                            <label>
                                <input :checked="allChecked" type="checkbox" @click="toggleAll"/>
                                <span v-if="!hideRemainingTitle"><span class="io-accent">{{ unassignedCostCodesCount }}</span> {{ $t('Remaining Cost Codes') }}</span>
                                <span v-else>{{$t('All Cost Codes')}}</span>
                            </label>
                        </div>
                        <GroupCheck
                            v-for="category in costCodesCategories"
                            :key="category.id"
                            :category="category"
                            v-model:costCodesSync="costCodes"
                            :lockedCostCodes="lockedCostCodes"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" src="./CostCodesDropdown.ts"></script>
<style lang="scss" src="./CostCodesDropdown.scss"></style>
