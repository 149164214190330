import BudgetVersionItemUpdateRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.BudgetVersionItemUpdateRequestData
import BudgetVersionUpdateRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.BudgetVersionUpdateRequestData
import BudgetVersionItemResponseData = App.Modules.Enterprise.BudgetVersioning.Data.BudgetVersionItemResponseData
import BudgetVersionResponseData = App.Modules.Enterprise.BudgetVersioning.Data.BudgetVersionResponseData
import type BudgetVersionCreateRequestData from '@/modules/projects/modules/apps/common/budget/interfaces/BudgetVersionCreateRequestData'
import BudgetVersionCategoryData = App.Modules.Enterprise.BudgetVersioning.Data.BudgetVersionCategoryResponseData
import type FeasibilityStudyResponseData from '@/modules/projects/modules/apps/common/budget/interfaces/FeasibilityStudyResponseData'
import BudgetVersionLoadTemplateRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.BudgetVersionLoadTemplateRequestData
import FeasibilityStudyLoadTemplateRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.FeasibilityStudyLoadTemplateRequestData
import BudgetVersionLoadVersionRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.BudgetVersionLoadVersionRequestData
import BudgetVersionLoadBudgetRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.BudgetVersionLoadBudgetRequestData
import FeasibilityStudyToggleResponseData = App.Modules.Enterprise.BudgetVersioning.Data.FeasibilityStudyToggleResponseData
import FeasibilityStudyCustomIdsResponseData = App.Modules.Enterprise.BudgetVersioning.Data.FeasibilityStudyCustomIdsResponseData
import FeasibilityStudyOverviewResponseData = App.Modules.Enterprise.BudgetVersioning.Data.FeasibilityStudyOverviewResponseData
import BudgetVersionItemCreateRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.BudgetVersionItemCreateRequestData
import FeasibilityStudyLoadProjectCodesRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.FeasibilityStudyLoadProjectCodesRequestData
import FeasibilityStudyExportRequestData = App.Modules.Enterprise.BudgetVersioning.Data.Internal.FeasibilityStudyExportRequestData
import BudgetVersionTimelineResponseData = App.Modules.Enterprise.BudgetVersioning.Data.BudgetVersionTimelineResponseData
import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'

interface LoadTemplateData extends BudgetVersionLoadTemplateRequestData, FeasibilityStudyLoadProjectCodesRequestData { }

const feasibilityStudyClient = {
    create (projectId: string, budgetType: string): AxiosPromise<FeasibilityStudyResponseData> {
        return client.post('feasibility-studies', { project_id: projectId, budget_type: budgetType })
    },

    createBudgetVersion (
        feasibilityStudyId: string,
        budgetVersion: BudgetVersionCreateRequestData
    ): AxiosPromise<BudgetVersionResponseData> {
        return client.post(`feasibility-studies/${ feasibilityStudyId }/budget-versions`, budgetVersion)
    },

    createBudgetVersionItem (
        feasibilityStudyId: string,
        budgetVersionId: string,
        item: BudgetVersionItemCreateRequestData
    ): AxiosPromise<BudgetVersionItemResponseData> {
        return client.post(`feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }/items`, item)
    },

    get (feasibilityStudyId: number): AxiosPromise<FeasibilityStudyResponseData> {
        return client.get(`feasibility-study/${ feasibilityStudyId }`)
    },

    getByProjectId (
        projectId: string,
        budgetType: string,
        budgetVersionIdThatMustOwn: string
    ): AxiosPromise<{ data: FeasibilityStudyResponseData }> {
        let url = `projects/${ projectId }/feasibility-study?budget_type=${ budgetType }`

        if (budgetVersionIdThatMustOwn) {
            url += `&budget_version_id_that_must_own=${ budgetVersionIdThatMustOwn }`
        }

        return client.get(url)
    },

    getAllByProjectId (projectId: string): AxiosPromise<FeasibilityStudyResponseData[]> {
        return client.get(`projects/${ projectId }/feasibility-studies`)
    },

    getCodes (feasibilityStudyId: string): AxiosPromise<BudgetVersionCategoryData[]> {
        return client.get(`feasibility-studies/${ feasibilityStudyId }/codes`)
    },

    getCodesUsingProject (
        projectId: string,
        feasibilityStudyId: string,
        budgetType: string
    ): AxiosPromise<BudgetVersionCategoryData[]> {
        return client.get(
            `projects/${ projectId }/feasibility-studies/${ feasibilityStudyId }/codes`,
            { params: { budget_type: budgetType } }
        )
    },

    getBudgetVersion (feasibilityStudyId: string, budgetVersionId: string): AxiosPromise<BudgetVersionResponseData> {
        return client.get(`feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }`)
    },

    getSharedBudgetVersion (projectId: string, feasibilityStudyId: string, budgetVersionId: string): AxiosPromise<BudgetVersionResponseData> {
        return client.get(`projects/${ projectId }/feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }`)
    },

    getBudgetVersionTimeline (feasibilityStudyId: string, budgetVersionId: string): AxiosPromise<BudgetVersionTimelineResponseData> {
        return client.get(`feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }/timeline`)
    },

    getSharedBudgetVersionTimeline (projectId: string, feasibilityStudyId: string, budgetVersionId: string): AxiosPromise<BudgetVersionTimelineResponseData> {
        return client.get(`projects/${ projectId }/feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }/timeline`)
    },

    getBudgetVersions (feasibilityStudyId: string): AxiosPromise<BudgetVersionResponseData[]> {
        return client.get(`feasibility-studies/${ feasibilityStudyId }/budget-versions`)
    },

    getBudgetVersionsUsingProject (
        projectId: string,
        feasibilityStudyId: string,
        budgetType: string
    ): AxiosPromise<BudgetVersionResponseData[]> {
        return client.get(
            `projects/${ projectId }/feasibility-studies/${ feasibilityStudyId }/budget-versions`,
            { params: { budget_type: budgetType } }
        )
    },

    getCustomIds (feasibilityStudyId: string): AxiosPromise<FeasibilityStudyCustomIdsResponseData> {
        return client.get(`feasibility-studies/${ feasibilityStudyId }/custom-ids`)
    },

    getOverview (feasibilityStudyId: string): AxiosPromise<FeasibilityStudyOverviewResponseData> {
        return client.get(`feasibility-studies/${ feasibilityStudyId }/overview`)
    },

    loadTemplate (feasibilityStudyId: string, data: FeasibilityStudyLoadTemplateRequestData): AxiosPromise<null> {
        return client.post(
            `feasibility-studies/${ feasibilityStudyId }/load-template`,
            data
        )
    },

    loadTemplateForBudgetVersion (
        feasibilityStudyId: string,
        budgetVersionId: string,
        data: LoadTemplateData
    ): AxiosPromise<null> {
        return client.post(
            `feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }/load-template`,
            data
        )
    },

    loadCodes (
        feasibilityStudyId: string,
        budgetVersionId: string
    ): AxiosPromise<null> {
        return client.post(`feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }/load-codes`)
    },

    loadVersion (
        feasibilityStudyId: string,
        budgetVersionId: string,
        data: BudgetVersionLoadVersionRequestData
    ): AxiosPromise<null> {
        return client.post(
            `feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }/load-version`,
            data
        )
    },

    loadBudget (
        feasibilityStudyId: string,
        budgetVersionId: string,
        data: BudgetVersionLoadBudgetRequestData
    ): AxiosPromise<null> {
        return client.post(
            `feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }/load-budget`,
            data
        )
    },

    updatePositions (feasibilityStudyId: string, positions: Array<{ id: string, position: number }>): AxiosPromise<null> {
        return client.post(`feasibility-studies/${ feasibilityStudyId }/budget-versions/update-positions`, { positions })
    },

    updateBudgetVersion (
        feasibilityStudyId: string,
        budgetVersionId: string,
        budgetVersion: BudgetVersionUpdateRequestData | BudgetVersionResponseData
    ): AxiosPromise<null> {
        return client.put(`feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }`, budgetVersion)
    },

    updateSharedBudgetVersion (
        projectId: string,
        feasibilityStudyId: string,
        budgetVersionId: string,
        budgetVersion: BudgetVersionUpdateRequestData | BudgetVersionResponseData
    ): AxiosPromise<null> {
        return client.put(`projects/${ projectId }/feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }`, budgetVersion)
    },

    updateBudgetVersionItem (
        feasibilityStudyId: string,
        budgetVersionId: string,
        budgetVersionItemId: string,
        item: BudgetVersionItemUpdateRequestData
    ): AxiosPromise<BudgetVersionItemResponseData> {
        return client.put(
            `feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }/items/${ budgetVersionItemId }`,
            item
        )
    },

    deleteBudgetVersion (feasibilityStudyId: string, budgetVersionId: string): AxiosPromise<null> {
        return client.delete(`feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersionId }`)
    },

    deleteCode (
        feasibilityStudyId: string,
        budgetCodeId: number
    ): AxiosPromise<null> {
        return client.delete(`feasibility-studies/${ feasibilityStudyId }/codes/${ budgetCodeId }`)
    },

    setAsMasterBudget (feasibilityStudyId: string, budgetVersion: string): AxiosPromise<null> {
        return client.post(`feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersion }/set-as-master-budget`)
    },

    removeAsMasterBudget (feasibilityStudyId: string, budgetVersion: string): AxiosPromise<null> {
        return client.post(`feasibility-studies/${ feasibilityStudyId }/budget-versions/${ budgetVersion }/remove-as-master-budget`)
    },

    toggleOptions (projectId: string): AxiosPromise<FeasibilityStudyToggleResponseData> {
        return client.get(`projects/${ projectId }/toggle-feasibility-study`)
    },

    getPDF (feasibilityStudyId: string, data: FeasibilityStudyExportRequestData): AxiosPromise<any> {
        return client.post(`/feasibility-studies/${ feasibilityStudyId }/export/pdf`, data, { responseType: 'blob' })
    },

    getExcel (feasibilityStudyId: string, data: FeasibilityStudyExportRequestData): AxiosPromise<any> {
        return client.post(`/feasibility-studies/${ feasibilityStudyId }/export/excel`, data, { responseType: 'blob' })
    },

    getSharedFile (
        typeDocument: string,
        projectId: string,
        feasibilityStudyId: string,
        data: FeasibilityStudyExportRequestData
    ): AxiosPromise<any> {
        return client.post(`projects/${ projectId }/feasibility-studies/${ feasibilityStudyId }/export/${ typeDocument }`, data, { responseType: 'blob' })
    }
}

export default feasibilityStudyClient
