import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import GroupedCCLines from '../../../../interfaces/GroupedCCLines.ts'
import SoVLineItem from '../../../../interfaces/SoVLineItem.ts'

export default defineComponent({
    name: 'LeftNavigation',
    props: {
        phases: {
            type: Array as PropType<GroupedCCLines[] | SoVLineItem[]>,
            default: () => [],
        },
        showLeftNavigation: {
            type: Boolean,
            default: true,
        },
        isCOsInSeparateCategory:  {
            type: Boolean,
            default: true,
        },
        firstCOIndex: {
            type: Number,
            default: 0
        },
        hasChangeOrders: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['scrollToPhase'],
    data () {
        return {
            activePhase: null,
            searchPhase: '',
        }
    },
    computed: {
        ...mapState(invoiceSoVStore, ['visibleLinesFilter', 'amendmentsContractChangeType']),

        filteredPhases (): any[] {
            return this.searchPhase 
                ? this.phases.filter(item => item.name.toLowerCase().includes(this.searchPhase.toLowerCase())) || []
                : this.phases
        },
    },
    watch: {
        filteredPhases (newVal: Array<object>, oldVal: Array<object>): void {
            if (newVal.length && newVal.length !== oldVal.length) {
                this.activePhase = this.filteredPhases[0].contract_wbs_id
            }
        }
    },
    mounted () {
        if (this.filteredPhases.length) {
            this.activePhase = this.filteredPhases[0].contract_wbs_id
        }
    },
    methods: {
        scrollToPhase (phaseId: string): void {
            this.$emit('scrollToPhase', phaseId)
            this.activePhase = phaseId
        },
    }
})