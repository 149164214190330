import ListType from '@/io-modules/invoices/enums/ListType'
import invoicesListsClient from '@/io-modules/invoices/api-clients/invoicesListsClient'
import DataResponse from '@/interfaces/DataResponse'
import ListFilters from '@/io-modules/invoices/interfaces/invoices-list/ListFilters'

export default class GetInvoicesListFiltersStrategy {
    constructor (private readonly projectMongoIdLocal: string,
                 private readonly type: ListType) {}

    getByType (): DataResponse<ListFilters> {
        if (this.type === ListType.AppPackages) {
            return invoicesListsClient.getApplicationPackagesListFiltersV3(this.projectMongoIdLocal)
        } else {
            return invoicesListsClient.getInvoicesListFiltersV3(this.projectMongoIdLocal, this.type)
        }
    }
}
