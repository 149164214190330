import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import useRfiStatus from '@/io-modules/rfis/composables/useRfiStatus'
import PillColor from '@/mixins/PillColor'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'

export default defineComponent({
    props: {
        data: { type: Object }
    },
    mixins: [PillColor],
    setup () {
        const rfiStatuses = useRfiStatus()
        return { rfiStatuses }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            getSelectedExistingAnnotation: 'getSelectedExistingAnnotation'
        }),
        isSelected (): boolean {
            return this.getSelectedExistingAnnotation.uuid === this.data.uuid
        },
    },
    methods: {
        selectAnnotation (): void {
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: this.data.uuid,
                type: RightSideBar.RFI,
            })
        },
    },
})
