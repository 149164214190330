/**
 * @deprecated
 * Use AlertBox instead
 */

import { defineComponent, PropType } from 'vue'
import IOBannerType from './IOBannerType'

export default defineComponent({
    name: 'IOBanner',
    props: {
        text: { type: String, required: true },
        type: { type: String, default: IOBannerType.Info },
        icon: { type: String, default: 'icon-info' },
        callToActionText: { type: String, default: null },
        callToActionIcon: { type: String, default: null },
    },
    computed: {
        getStyle (): string {
            const styles = {
                [IOBannerType.Info]: 'info',
                [IOBannerType.Error]: 'error',
                [IOBannerType.Success]: 'success',
                [IOBannerType.Warning]: 'warning',
                [IOBannerType.Closed]: 'closed',
                [IOBannerType.Neutral]: 'neutral',
                ['default']: 'closed',
            }

            return styles[this.type] || styles['default']
        }
    },
    mounted () {
        if (process.env.NODE_ENV === 'development') {
            console.warn('IOBanner is deprecated. Use AlertBox instead.')
        }
    },
    methods : {
        callToAction (): void {
            this.$emit('callToAction')
        },
    },
})
