<template>
    <tr class="io-lazy-list-fetching-row">
        <td :colspan="columns?.length || columnsLength">
            <p class="io-fetching-text">{{ text }}</p>
        </td>
    </tr>
</template>

<script lang="ts" src="./FetchingRow.ts"></script>
<style lang="scss" src="./FetchingRow.scss"></style>
