<template>
    <div class="io-single-comment">
        <template v-if="!editMode" >
            <div class="io-single-comment__header">
                <div class="io-single-comment__header__left">
                    <Avatar
                        :employeeMongoId="getUserId"
                        :employeeFullName="userFullName"
                        :showFullName="true"
                    />
                    &#x2022;
                    <span class="io-time-label">{{ $filters.parseDateTime(getTimeToDisplay) }}</span>
                </div>
                <ActionDropdown
                    v-if="canEdit && isCommentCreator"
                    data-testid="comment-actions"
                    icon-class="icon-more-vert-2"
                    :icon-tooltip="$t('More')"
                >
                    <template #default>
                        <a
                            class="io-dropdown-item"
                            @click="initEdit"
                        >
                            <span class="icon-edit io-dropdown-item__icon"></span>
                            <span>{{ $t('Edit') }}</span>
                        </a>
                        <a
                            class="io-dropdown-item"
                            @click="initDelete"
                        >
                            <span class="icon-trash io-dropdown-item__icon"></span>
                            <span>{{ $t('Delete') }}</span>
                        </a>
                    </template>
                </ActionDropdown>
            </div>
            <div class="io-single-comment__text" v-html="commentText"></div>
        </template>
        <div v-else class="io-single-comment__input-wrapper">
            <Avatar
                :employeeMongoId="getUserId"
                :employeeFullName="userFullName"
            />

            <Mentions
                ref="mentionsInput"
                v-model="comment[commentField]"
                :customPeopleList="taggableUsers"
                :attachBelow="true"
                :placeholder="$t('Add a Comment...')"
            />
            <div class="io-f-row">
                <div v-tooltip.top="$t('Edit')" class="io-icon-wrapper" @click="updateComment">
                    <span class="icon-check"></span>
                </div>
                <div v-tooltip.top="$t('Close')" class="io-icon-wrapper" @click="closeEditingMode">
                    <span class="icon-cross"></span>
                </div>
            </div>

        </div>
    </div>
</template>

<script lang="ts" src="./SingleComment.ts"></script>
<style lang="scss" src="./SingleComment.scss" scoped></style>
