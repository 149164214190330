import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import StampDropdown from '@/modules/todos/components/stamp-dropdown/StampDropdown.vue'

export default defineComponent({
    name: 'SelectStamp',
    components: {
        PopupModal,
        StampDropdown,
    },
    data () {
        return {
            stamp: {
                code: '',
                color: 'transparent',
                name: '',
                id: '',
            },
        }
    },
    computed: {
        isValid (): boolean {
            return !!this.stamp.name
        },
    },
    methods: {
        deleteStamp (): void {
            this.stamp = {
                code: '',
                color: 'blue',
                name: '',
                id: '',
            }
        },
        close (): void {
            this.$emit('close')
        },
        nextStep (): void {
            this.$emit('next-step', this.stamp)
        },
        selectStamp (stamp: unknown): void {
            this.stamp = {
                code: stamp.code,
                color: stamp.color,
                name: stamp.name,
                id: stamp.id,
            }
        },
    },
})
