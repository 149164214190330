<template>
    <div class="io-single-sheet">
        <div v-progress="!rendered">
            <div v-if="rendered" class="io-single-sheet-wrapper" tabindex="0" @keydown.esc="cancelAddingAnnotation">
                <div v-show="isDrawingShown">
                    <PageHeader
                        :route-back="backBtnLink"
                        :title="title"
                        no-bottom-margin
                    >
                        <template #actions>
                            <IOButton
                                variant="secondary"
                                @click="downloadAlertShown = true"
                            >
                                {{ $t('Download') }}
                            </IOButton>
                        </template>
                    </PageHeader>

                    <div class="io-single-sheet-wrapper__content">
                        <div class="io-web-viewer">
                            <div v-if="model.drawing.is_archived && isWebViewerLoaded" class="io-web-viewer__archived">
                                <i class="icon-info"/>
                                <span>{{ $t('This is an archived version.') }}</span>
                            </div>

                            <div
                                v-show="headerTip && headerTipRendered && !addingAnnotation.status && isLatestVersion && !isArchived"
                                class="io-web-viewer__header-tip"
                            >
                                <i class="icon-mouse"/>
                                <span>{{ $t('Use your right click on the sheet to Add Quick Pin') }}</span>
                                <i class="icon-cross" @click="hideHeaderTip"/>
                            </div>

                            <div v-if="!isLatestVersion" class="io-web-viewer__archived">
                                <i class="icon-info"/>
                                <span>
                                    {{
                                        $t('This is an outdated version of this sheet. To add markups and pins, please see latest version.')
                                    }}
                                    <span class="io-inline-block latest-version-link" @click="drawingVersionSwitch">
                                        <u>
                                            {{
                                                $t('See Latest Version')
                                            }}
                                        </u>
                                    </span>
                                </span>
                            </div>

                            <div
                                v-if="!fromPunchList && addingAnnotation.status"
                                class="io-web-viewer__adding-annotation"
                            >
                                <i class="icon-pin-2"/>
                                <span>{{ $t('Place Reference Pin On the Drawing') }}</span>
                                <i class="icon-cross" @click="cancelAddingAnnotation"/>
                            </div>

                            <WebViewer
                                :key="model.id"
                                ref="viewer"
                                :url="model.file"
                                :toggledElement="panelContent"
                                :disabledElements="disabledElementsWebViewer"
                                :disableExistingAnnotations="disableExistingAnnotations"
                                @document-loaded="documentLoaded"
                                @cancel-adding-annotation="cancelAddingAnnotation"
                                @annotation-changed="annotationChanged"
                            />
                        </div>

                        <RightPanel
                            v-if="isWebViewerLoaded"
                            :isArchivedVersion="!isLatestVersion || isArchived"
                            @cancel-annotation="cancelAnnotation"
                            @annotation-deleted="annotationDeleted"
                            @delete-pdftron-markup="deletePdftronMarkup"
                        />

                        <ConfirmAlert
                            v-if="downloadAlertShown"
                            class="io-confirm-download"
                            acceptButtonText="Yes, download"
                            type="warning"
                            :subtitle="$t('Do you want to download sheet as PDF?')"
                            :cancelButtonText="$t('Cancel')"
                            @discard="downloadAlertShown = false"
                            @confirm="onConfirmDownloadAlert"
                        >
                            <div>
                                <label>{{ $t('Include markups') }}</label>
                                <div class="io-f-row io-switch">
                                    <label>
                                        <input name="include_markups" type="checkbox" v-model.number="includeMarkups"/>
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </ConfirmAlert>

                        <Filters
                            v-if="mentionUsers"
                            :defaultFilters="defaultFilters"
                            v-model="filters"
                            @applyFilters="applyFilters"
                        />

                        <ShareMarkupPopup
                            v-if="getShareMarkupPopupShown"
                            :data="getShareMarkupData"
                            :visibilityEnums="visibilityEnums"
                            @markupVisibilityChanged="getModel"
                        />
                    </div>

                    <PunchItemOperationsSlideout
                        v-if="drawingReference && annotationContent === 'punch'"
                        :defaults="{
                            stamp,
                            drawingReferences: [drawingReference],
                            status: preservedPunchItem.status,
                            dueDate: preservedPunchItem.dueDate,
                            jobsiteLocations: preservedPunchItem.jobsiteLocations,
                        }"
                        @punch-item-saved="punchItemSaved"
                        @close="closePunchItemSlideout(); clearPreservedPunchItem()"
                    />

                    <RfiOperationsSlideout
                        v-if="isRfiSlideoutOpen"
                        :project-id="projectId"
                        :rfi-type="isSubcontractor ? 'SUB_RFI' : 'PROJECT_RFI'"
                        :initial-drawing-references="[drawingReference]"
                        @created="closeRfiSlideout(); annotationAdded()"
                        @close="closeRfiSlideout"
                    />

                    <IOModal v-if="getSelectStampPopup" @close="closeSelectStampDropdown">
                        <template #modal>
                            <StampsIndex class="io-drawings-stamps-index" @select-stamp="chooseSelectStamp"/>
                        </template>
                    </IOModal>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./SheetsShow.ts"></script>
<style lang="scss" src="./SheetsShow.scss" scoped></style>
