import { orderBy } from 'lodash'

export function getPackagesList (state) {
    return orderBy(state.packages, ['number'], ['asc'])
}

export function getRelevantSpecifications (state) {
    return state.relevantSpecifications
}

export const getCollapsedPackagesIds = (state) => {
    return state.collapsedPackagesIds
}

export const getPackagesIds = (state) => {
    return state.packages.map((packageItem) => packageItem.id)
}

export const getActivePackageId = (state) => {
    return state.activePackageId
}

export const getDetailsToggled = (state) => {
    return state.detailsToggled
}

export const getFileInfo = (state) => {
    return state.fileInfo
}

export const getDownloadPopupVisible = (state) => {
    return state.downloadPopupVisible
}

export const getCopySubmittalData = (state) => {
    return state.copySubmittalData
}

export const getCopySubmittalId = (state) => {
    return state.copySubmittalId
}

export const getOpenSubmittalData = (state) => {
    return state.openSubmittalData
}

export const getCreatingNext = (state) => {
    return state.creatingNext
}

export const getIsRequiredForMaterialRelease = (state) => {
    return state.isRequiredForMaterialRelease
}

export const getShowFilters = (state) => {
    return state.showFilters
}

export const getCheckedSubmittalsIds = (state) => {
    return state.checkedSubmittalsIds
}
