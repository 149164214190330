<template>
    <div class="io-button-dropdown">
        <div v-if="iconMode" class="io-button-dropdown__icon-container" @click="toggleMenu">
            <i class="io-button-dropdown__icon" :class="[fontAwesomeClass, iconClass, showMenu ? 'io-menu-active' : '']"></i>
        </div>

        <div v-else class="io-btn" :class="buttonClass" @click="toggleMenu">{{ name }}<span class="icon-chevron-down"></span></div>

        <transition name="dropdown-menu">
            <div v-if="showMenu"
                 class="io-button-dropdown__menu"
                 :class="menuClass"
                 :style="topPosition"
                 @click="handleMenuClick"
            >
                <slot></slot>
            </div>
        </transition>

        <transition name="dropdown-mask">
            <div v-if="showMenu" class="io-button-dropdown__mask" :class="maskClass" @click="toggleMenu"></div>
        </transition>
    </div>
</template>

<script lang="ts" src="./DropdownButton.ts"></script>
<style lang="scss" src="./DropdownButton.scss" scoped></style>
