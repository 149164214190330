<template>
    <td :class="columnClass">
        <div class="io-thumbnail-wrap">
            <i class="icon-eye"></i>
            <div class="io-thumbnail" :class="{'io-last-row': isLastRow}">
                <img v-if="imgSrc()" :src="imgSrc()" alt="thumbnail">
            </div>
        </div>
    </td>
</template>

<script lang="ts" src="./Thumbnail.ts"></script>

<style lang="scss" src="./Thumbnail.scss" scoped/>
