export enum ApplicationPackageHeader {
    AppPackageNumber = 'sequence_number',
    Name = 'name',
    BillingPeriod = 'billing_period',
    Status = 'status',
    NumberOfInvoices = 'number_of_invoices',
    GrossTotalThisPeriod = 'gross_total_this_period',
    GrossBilledToDate = 'gross_billed_to_date',
    Vendors = 'vendors',
}

export default ApplicationPackageHeader
