<template>
    <div ref="container" class="io-design-system io-scrollable">
        <div ref="scrollableContent" class="io-scrollable__content">
            <div
                v-for="(select, index) in selections" :key="select.label"
                :ref="`stepSlideoutItem${ index }`"
                class="io-step-slideout__item"
            >
                <div class="io-step-slideout__header">
                    <h3 class="io-step-slideout__title" data-testid="io-step-slideout__title">
                        {{ select.label }}
                    </h3>
                    <SearchInput
                        v-if="select.isSearchable"
                        :placeholder="$t('Search...')"
                        :width="288"
                        v-model="select.searchKeyword"
                    />
                </div>
                <template v-if="select.hasSlot">
                    <slot name="stepSlideoutItem" v-bind="{ item: select }">
                        Some custom content that is not the radio options.
                        <br/>
                        When implementing this, you will need to add a slot to the Slideout component.
                    </slot>
                </template>
                <template v-else>
                    <div class="io-step-slideout__options">
                        <template v-if="!select.filteredOptions.length">
                            <div class="io-step-slideout__no-options">
                                <h4>{{ $t(`Didn't find what you are looking for?`) }}</h4>
                                <p>{{ $t('Try adjusting your search.') }}</p>
                            </div>
                        </template>
                        <template v-else>
                            <label
                                v-for="option in select.filteredOptions" :key="option.value"
                                class="io-step-slideout__option io-option"
                                :for="`radio-${ option.value }`"
                                data-testid="io-step-slideout__option"
                            >
                                <InfoBox
                                    v-if="option.warningMessage || option.customMessage"
                                    :type="'warning'"
                                >
                                    <template #text>
                                        <span v-if="!option.customMessage" v-html="option.warningMessage"></span>
                                        <slot name="customMessage" v-bind="option"/>
                                    </template>
                                </InfoBox>
                                <span
                                    class="io-step-slideout__option-content"
                                    :class="{ 'io-step-slideout__option-content_disabled': option.isDisabled }"
                                >
                                    <input
                                        :id="`radio-${ option.value }`"
                                        type="radio"
                                        :name="`radio-${ option.value }`"
                                        :value="option.value"
                                        :disabled="option.isDisabled"
                                        v-model="selections[index].selected"
                                        @change="selectOption(option, index)"
                                    />
                                    <template v-if="option.showLogo">
                                        <Avatar
                                            :employeeMongoId="option.avatarId"
                                            :employeeFullName="option.description"
                                            :showFullName="false"
                                            :type="option.isAvatarForCompany ? AvatarType.COMPANY : AvatarType.PERSON"
                                        />
                                    </template>
                                    <span class="io-option__description">
                                        <span class="io-option__main-description">
                                            {{ option.description }}
                                            <template v-if="!!option.statusPills?.length">
                                                <div class="io-option__statuses">
                                                    <template v-for="pill in option.statusPills" :key="pill.text">
                                                        <StatusPill
                                                            :variant="pill.variant"
                                                            :text="pill.text"
                                                            size="small"
                                                        />
                                                    </template>
                                                </div>
                                            </template>
                                        </span>
                                        <span class="io-option__additional-data">
                                            <span class="io-option__sub-descriptions">
                                                <span v-for="desc in option.subDescription" :key="desc"
                                                      class="io-option__sub-description">
                                                    {{ desc }}
                                                </span>
                                            </span>
                                            <template v-if="option.additionalValue">
                                                <span class="io-option__additional-value">
                                                    {{ option.additionalValue }}
                                                </span>
                                            </template>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </template>
                    </div>
                </template>
            </div>
            <div class="additional-block" :style="additionalBlockStyle"></div>
        </div>
    </div>
</template>

<script lang="ts" src="./StepSlideout.ts"></script>
<style lang="scss" src="./StepSlideout.scss" scoped></style>
