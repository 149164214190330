<template>
    <div
        class="io-sections-show hover-darken hover-reveal-trigger"
        :class="{ 'io-sections-show--active': active }"
        @click="$router.replace({ query: { ...$route.query, 'spec-section': section.current_version_id } })"
    >
        <div class="leading-relaxed">{{ section.label }} - {{ section.title }}</div>
    </div>
</template>

<script lang="ts" src="./SectionsShow.ts"></script>
<style lang="scss" src="./SectionsShow.scss" scoped></style>
