import initials from '@/components/new-theme/initials.vue'
import { isObject } from 'lodash'
import { defineComponent, PropType } from 'vue'
import appTypes from '@/base/appTypes'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import TimelineLogs from '@/interfaces/components/timeline/TimelineLogs'

export default defineComponent({
    name: 'Timeline',
    components: {
        initials,
        Avatar,
    },
    props: {
        history: { type: Object, required: false },
        logs: { type: Array as PropType<TimelineLogs[]>, required: false },
        overflow: { type: Boolean, default: false },
        useDisplayVariantWithAvatar: { type: Boolean, required: false, default: false },
    },
    methods: {
        async scrollTop (): Promise<void> {
            await this.$nextTick()
            window.scrollTo(0, 0)
        },
        parseDate (val: string): string {
            return (val) ? this.$filters.parseDateTime(val) : ''
        },
        showContent (content: { property: string, type: string, old_value: string, new_value: string } | string, title: string = ''): string {
            if (isObject(content)) {
                let propertyVal = content.property ? this.$t(content.property) : ''
                let typeVal = content.type ? this.$t(content.type) : ''
                let oldVal = content.old_value ? ' from ' + this.$t(content.old_value) : ''
                let newVal = content.new_value ? ' to ' + this.$t(content.new_value) : ''

                return propertyVal + ' ' + typeVal + oldVal + newVal
            }

            if (title.indexOf('Project') > -1 && content.indexOf('@') > -1) {
                return (content).replace('\\n', '\n')
            }

            return this.$t(content).replace('\\n', '\n')
        },
        showUser (data: any): string {
            if (data && typeof data === 'object' && data.constructor === Object) {
                return data?.user
            }
            return data
        },
        appTypeName (appType: string): string {
            const appTypeValues = {
                [appTypes.TYPE_SC]: 'SUB',
                [appTypes.TYPE_DEV]: 'OWNER REP',
                [appTypes.TYPE_OWNER]: 'OWNER',
                [appTypes.TYPE_AC]: 'DESIGN',
                [appTypes.TYPE_GC]: 'GC'
            }

            return appTypeValues[appType] || 'OWNER REP'
        },
    },
})
