import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import ListsMixin from '@/io-modules/invoices/mixins/ListsMixin'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import { defineComponent } from 'vue'
import InvoiceCreationSlideout from '../../modals/invoice-creation-slideout/InvoiceCreationSlideout.vue'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList.ts'

export default defineComponent({
    name: 'TopbarActions',
    components: {
        DropdownButtonNew,
        ActionDropdown,
        InvoiceCreationSlideout
    },
    props: {
        isOwner: Boolean,
        isOwnerRep: Boolean,
    },
    mixins: [ListsMixin],
    emits: ['createApplicationPackageClick', 'createInvoiceClick', 'openExportModal'],
    computed: {
        selectRouteName (): string {
            return 'project-funding'
        },
    },
    methods: {
        createApplicationPackage (): void {
            this.$emit('createApplicationPackageClick')
        },

        createInvoice (): void {
            this.$emit('createInvoiceClick')
        },
    }
})
