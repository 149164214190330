import { defineComponent } from 'vue'
import Table from '@/components/table/Table'
import FundingSourcesProjectRow
    from '@/io-modules/global-funding/components/funding-sources-project-row/FundingSourcesProjectRow.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import fundingClient from '../../api-clients/fundingClient'
import { FundingSourceFundProjects } from '../../interfaces/GlobalFundingInterfaces.ts'
import useLoader from '@/composables/useLoader.ts'

export default defineComponent({
    name: 'ProjectSpecificFunds',
    components: { FundingSourcesProjectRow, Table },
    props: {
        providerId: {
            type: String,
            required: true,
        },
    },
    setup () {
        const { loading, load } = useLoader()

        return { loading, load }
    },
    data () {
        return {
            relatedProjects: null as FundingSourceFundProjects,
        }
    },
    computed: {
        tableHeaders (): HeaderTable[] {
            const headers: HeaderTable[] = [
                {
                    text: this.$t('Project Name'),
                    value: 'project_name',
                },
                {
                    text: this.$t('Fund Name'),
                    value: 'fund_name',
                },
                {
                    text: this.$t('Allocated to Project'),
                    value: 'allocated_amount',
                    sort: (item) => parseFloat(item),
                    align: 'end',
                },
                {
                    text: this.$t('Paid to Date'),
                    value: 'summary.total_paid_to_date_amount',
                    sort: (item) => parseFloat(item),
                    align: 'end',
                },
                {
                    text: this.$t('Remaining Balance'),
                    value: 'summary.remaining_amount',
                    sort: (item) => parseFloat(item),
                    align: 'end',
                },
            ]
            return headers
        },
    },
    mounted () {
        return this.load(async () => {
            try {
                const response = await fundingClient.getAllRelatedProjects(this.providerId)

                if (response && response.data.items && response.data.items.length) {
                    this.relatedProjects = response.data
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        })
    },
})
