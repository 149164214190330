// @ts-ignore
import store from '@/store/index'
import {AuthDataType} from './Types'

export default class IntercomDataBuilder {
    private readonly authData: AuthDataType
    private readonly env: any = null

    constructor () {
        this.authData = store.getters['appStore/getAuthData']
        this.env = store.getters['appStore/getEnv']
    }

    getIntercomData () {
        const basicUserData = this.basicUserData()
        const basicCompanyData = this.basicCompanyData()

        return {
            ...basicUserData,
            company: {
                ...basicCompanyData,
            }
        }
    }

    get appId (): string {
        switch (this.env) {
        case 'production':
            return 'bdc7iyuh'
        default:
            return 'kyjdo8gr'
        }
    }

    private basicUserData () {
        return {
            'app_id': this.appId,
            'user_id': this.authData.u_mongo,
            'name': `${this.authData.u_firstname} ${this.authData.u_lastname}`,
            'email': this.authData.u_email,
            'userID': this.authData.u_id,
            'phone': this.authData.u_phone,
            'job_title': this.authData.u_title,
            'app': this.authData.app_type_translated,
            'Subscription Type': this.authData.subscription_type,
            'Company_Name': this.authData.u_company_name,
            'User Role': this.authData._e_roles,
            'Account Type': this.authData.u_role_name,
            'EnvType': this.env,
            'office_location': this.authData.location,
            'projects_assigned': this.authData.projects_assigned,
            'Subdomain': this.authData.workspace_subdomain,
            'business_units':  this.authData.business_units,
            'change_orders_number': store.state.intercom.change_orders_number,
            'budget_changes_number': store.state.intercom.budget_changes_number,
            'invoices_number': store.state.intercom.invoices_number,
            'meeting_minutes_number': store.state.intercom.meeting_minutes_number,
            'employee_status': store.state.intercom.employee_status
        }
    }

    private basicCompanyData () {
        return {
            'id': this.authData.u_company_mongo_id,
            'name': this.authData.u_company_name,
            'company_mongo_id': this.authData.u_company_mongo_id,
            'budgets_number': store.state.intercom.budgets_number
        }
    }
}
