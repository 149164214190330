import { defineComponent } from 'vue'

import useLoader from '@/composables/useLoader.ts'
import useNewTabRouteOpener from '@/composables/useNewTabRouteOpener'
import setsClient from '@/io-modules/drawings/api-clients/setsClient'

import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import Table from '@/components/table/Table.vue'

import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { SetUploadingHistory } from '@/io-modules/drawings/interfaces/SetUploadingHistory'

export default defineComponent({
    name: 'SetHistoryLogsIndex',
    components: {
        IOModal,
        Table,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    emits: ['close'],
    setup () {
        const { loading, load } = useLoader()
        const { openNewTabRoute } = useNewTabRouteOpener()

        return { loading, load, openNewTabRoute }
    },
    data () {
        return {
            items: null as SetUploadingHistory[],
        }
    },
    computed: {
        headers (): HeaderTable[] {
            return [
                {
                    text: this.$t('Upload Name'),
                    value: 'upload_name',
                    sort: false,
                },
                {
                    text: this.$t('Uploaded By'),
                    value: 'uploaded_by',
                    sort: false,
                },
                {
                    text: this.$t('Issue Date'),
                    value: 'issue_date',
                    sort: false,
                },
                {
                    text: this.$t('Sheets'),
                    value: 'drawings_count',
                    align: 'end',
                },
            ]
        },
    },
    created () {
        this.getUploadingHistory()
    },
    methods: {
        getUploadingHistory (): Promise<void> {
            return this.load(async () => {
                const { data } = await setsClient.getUploadingHistory(this.id)
                this.items = data.items
            })
        },
    },
})
