import InvoicesListStoreState from '../interfaces/stores/InvoicesListStoreState'
import InvoicesTab from '../enums/InvoicesTab'
import ApplicationPackage from '../interfaces/ApplicationPackage'
import VendorDetails from '../interfaces/VendorDetails'
import FilterInterface from '@/components/ModalFilters/interfaces/FilterInterface'
import ListType from '../enums/ListType'
import invoicesListsClient from '../api-clients/invoicesListsClient'
import initialTableColumnsSettings from '../helpers/initialTableColumnsSettings'
import ManageColumnsSettings from '../interfaces/invoices-list/ManageColumnsSettingss'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { defineStore } from 'pinia'
import Invoice from '../interfaces/Invoice'
import ListFiltersListingParams from '@/io-modules/invoices/interfaces/invoices-list/ListFiltersListingParams'
import manageColumnsHelper from '@/components/manage-columns-modal/helpers/ManageColumnsHelper'
import InvoiceStatus from '../enums/InvoiceStatus'
import GetInvoicesListFiltersStrategy from '@/io-modules/invoices/strategies/GetInvoicesListFiltersStrategy.ts'

export const invoicesStore = defineStore('invoicesList', {
    state: (): InvoicesListStoreState => ({
        showContentLoader: false,
        showSmallLoader: false,
        projectHasMainContractExecuted: null,
        receivedInvoicesTableColumns: [
            ...initialTableColumnsSettings(InvoicesTab.Received)
        ],
        myInvoicesTableColumns: [
            ...initialTableColumnsSettings(InvoicesTab.My)
        ],
        sharedInvoicesTableColumns: [
            ...initialTableColumnsSettings(InvoicesTab.Shared)
        ],
        applicationPackagesTableColumns: [
            ...initialTableColumnsSettings(InvoicesTab.AppPackages)
        ],
        receivedInvoices: [],
        myInvoices: [],
        sharedInvoices: [],
        applicationPackages: [],
        receivedInvoicesVendors: [],
        vendorsInvoices: {},
        expandableList: [],
        vendorsDetails: {},
        filtersListingsParams: new Map(),
        listParams: {
            page: 1,
            limit: 50,
            sort_by: [
                {
                    column: 'invoice_date',
                    sort: 'desc'
                }
            ]
        },
        availableFilters: {}
    }),
    getters: {
        getInvoicesListHeaders: (state: InvoicesListStoreState) => {
            return (type: InvoicesTab): HeaderTable[] => {
                if (!type) {
                    return []
                }

                if (type === InvoicesTab.AppPackages) {
                    return state[`${ type }TableColumns`]
                }

                if (type !== InvoicesTab.My) {
                    if (state[`${ type }TableColumns`].some(item => item.fixed && item.text && item.visible)) {
                        state[`${ type }TableColumns`][0].fixed = true
                        state[`${ type }TableColumns`][0].extraClasses = 'io-fixed-column'
                    } else {
                        state[`${ type }TableColumns`][0].fixed = false
                        state[`${ type }TableColumns`][0].extraClasses = ''
                    }
                }

                return state[`${ type }TableColumns`].filter(item => item.visible).sort((a, b) => a.order - b.order)
            }
        },

        getListScrollableColumns: (state: InvoicesListStoreState) => {
            return (type: InvoicesTab): HeaderTable[] =>
                state[`${ type }TableColumns`]
                .filter(item => !item.fixed && item.text)
                .sort((a, b) => a.order - b.order)
        },

        getListFixedColumns: (state: InvoicesListStoreState) => {
            return (type: InvoicesTab): HeaderTable[] =>
                state[`${ type }TableColumns`]
                .filter(item => item.fixed && item.text)
                .sort((a, b) => a.order - b.order)
        },

        getListItems: (state: InvoicesListStoreState) => {
            return (type: InvoicesTab, vendors: boolean = false, vendorId: string = null): Invoice[] | ApplicationPackage[] | VendorDetails[] => {
                if (InvoicesTab.AppPackages === type) {
                    return state.applicationPackages
                }
                if (InvoicesTab.Received === type && vendors) {
                    return state.receivedInvoicesVendors
                }
                if (InvoicesTab.Received === type && vendorId) {
                    return state.vendorsInvoices[vendorId]
                }

                return state[type]
            }
        },

        hasMainContractExecuted: (state: InvoicesListStoreState) => {
            return state.projectHasMainContractExecuted
        }
    },
    actions: {
        clearListParams (): void {
            this.listParams = {
                page: 1,
                limit: 50,
                sort_by: [
                    {
                        column: 'invoice_date',
                        sort: 'desc'
                    }
                ]
            }
        },

        clearListFilters (): void {
            this.filtersListingsParams = new Map()
        },

        setContentLoaderValue (value: boolean): void {
            this.showContentLoader = value
        },

        setDataLoaderValue (value: boolean): void {
            this.showSmallLoader = value
        },

        setExpandableList (value: Element[]): void {
            this.expandableList = value
        },

        collapseAllList (index: number): void {
            this.expandableList.forEach((item, itemIndex) => {
                if (index !== itemIndex) {
                    item.collapseAccordion()
                }
            })
        },

        async fetchFilters (projectMongoIdLocal: string, listType: ListType): Promise<void> {
            const filtersStrategy = new GetInvoicesListFiltersStrategy(projectMongoIdLocal, listType)
            const response = await filtersStrategy.getByType()
            if (response && response.data) {
                this.availableFilters = response.data
            }
        },

        async fetchReceivedInvoices (projectMongoIdLocal: string): Promise<void> {
            const filters = this.filtersListingsParams.get(ListType.Received) ? JSON.parse(JSON.stringify(this.filtersListingsParams.get(ListType.Received))) : undefined
            if (filters && filters.status && filters.status.includes(InvoiceStatus.SavedAsVendorSubmission)) {
                filters.status.push('pending')
            }

            const response = await invoicesListsClient.getReceivedInvoicesListV3(
                projectMongoIdLocal,
                filters,
                this.listParams,
            )

            if (response?.data) {
                if (1 === this.listParams.page) {
                    this.receivedInvoices = response.data.invoices
                } else {
                    this.receivedInvoices.push(...response.data.invoices)
                }

                if ((!response.data.invoices || !response.data.invoices.length) && this.listParams.page > 1) {
                    this.listParams.page = this.listParams.page - 1
                }
            }
        },

        async fetchReceivedInvoicesVendors (projectMongoIdLocal: string): Promise<void> {
            const response = await invoicesListsClient.getReceivedInvoicesVendorsV3(
                projectMongoIdLocal,
                this.filtersListingsParams.get(ListType.Received)
            )
            if (response && response.data) {
                this.receivedInvoicesVendors = response.data.vendors
            }
        },

        async fetchApplicationPackages (projectMongoIdLocal: string): Promise<void> {
            const response = await invoicesListsClient.getApplicationPackagesListV3(
                projectMongoIdLocal,
                this.filtersListingsParams.get(ListType.AppPackages),
                this.listParams,
            )

            if (response && response.data) {
                if (1 === this.listParams.page) {
                        this.applicationPackages = response.data.application_packages
                } else {
                    this.applicationPackages.push(...response.data.application_packages)
                }

                if ((!response.data.application_packages || !response.data.application_packages.length) && this.listParams.page > 1) {
                    this.listParams.page = this.listParams.page - 1
                }
            }
        },

        async fetchVendorDetails (projectMongoIdLocal: string, vendorId: string): Promise<void> {
            const response = await invoicesListsClient.getVendorDetailsV3(projectMongoIdLocal, vendorId)
            if (response && response.data) {
                this.vendorsDetails[vendorId] = response.data
            }
        },

        async fetchVendorInvoices (projectMongoIdLocal: string, vendorId: string): Promise<void> {
            if (this.vendorsInvoices[vendorId] && 1 === this.listParams.page) {
                const vendorInfo = this.receivedInvoicesVendors.find(item => vendorId === item.id)
                if (
                    vendorInfo
                    && vendorInfo.invoices_count <= this.listParams.limit
                    && vendorInfo.invoices_count === this.vendorsInvoices[vendorId].length
                ) {
                    this.listParams.page = Math.ceil(this.vendorsInvoices[vendorId].length / this.listParams.limit)
                    return
                }
            }

            const filters = this.filtersListingsParams.get(ListType.Received) ? JSON.parse(JSON.stringify(this.filtersListingsParams.get(ListType.Received))) : undefined
            if (filters && filters.status && filters.status.includes(InvoiceStatus.SavedAsVendorSubmission)) {
                filters.status.push('pending')
            }

            const response = await invoicesListsClient.getVendorInvoicesV3(
                projectMongoIdLocal,
                vendorId,
                filters,
                this.listParams
            )

            if (response && response.data) {
                if (!this.vendorsInvoices[vendorId] || 1 === this.listParams.page) {
                    this.vendorsInvoices[vendorId] = response.data.invoices
                } else {
                    this.vendorsInvoices[vendorId].push(...response.data.invoices)
                }

                if ((!response.data.invoices || !response.data.invoices.length) && this.listParams.page > 1) {
                    this.listParams.page = this.listParams.page - 1
                }
            }
        },

        async fetchMyInvoices (projectMongoIdLocal: string): Promise<void> {
            const filters = this.filtersListingsParams.get(ListType.My) ? JSON.parse(JSON.stringify(this.filtersListingsParams.get(ListType.My))) : undefined
            if (filters && filters.status && filters.status.includes(InvoiceStatus.Sent)) {
                filters.status.push('pending')
            }

            const response = await invoicesListsClient.getMyInvoicesListV3(
                projectMongoIdLocal,
                filters,
                this.listParams,
            )

            if (response && response.data) {
                if (1 === this.listParams.page) {
                    this.myInvoices = response.data.invoices
                } else {
                    this.myInvoices.push(...response.data.invoices)
                }

                if ((!response.data.invoices || !response.data.invoices.length) && this.listParams.page > 1) {
                    this.listParams.page = this.listParams.page - 1
                }
            }
        },

        async fetchSharedInvoices (projectMongoIdLocal: string): Promise<void> {
            const filters = this.filtersListingsParams.get(ListType.Shared) ? JSON.parse(JSON.stringify(this.filtersListingsParams.get(ListType.Shared))) : undefined
            if (filters && filters.status && filters.status.includes(InvoiceStatus.SavedAsVendorSubmission)) {
                filters.status.push('pending')
            }

            const response = await invoicesListsClient.getSharedInvoicesListV3(
                projectMongoIdLocal,
                filters,
                this.listParams,
            )

            if (response && response.data) {
                if (1 === this.listParams.page) {
                    this.sharedInvoices = response.data.invoices
                } else {
                    this.sharedInvoices.push(...response.data.invoices)
                }

                if ((!response.data.invoices || !response.data.invoices.length) && this.listParams.page > 1) {
                    this.listParams.page = this.listParams.page - 1
                }
            }
        },

        updateListItems (type: InvoicesTab, vendorId: string, data: Invoice[] | ApplicationPackage[] | VendorDetails[]): void {
            if (InvoicesTab.AppPackages === type) {
                this.applicationPackages = data
            }
            if (InvoicesTab.Received === type && vendorId) {
                return this.vendorsInvoices[vendorId] = data
            }
            if (InvoicesTab.Received === type) {
                return this.receivedInvoices = data
            }
            if (InvoicesTab.Shared === type) {
                return this.sharedInvoices = data
            }
        },

        updateInvoicesColumns (type: InvoicesTab, columnsSettings: ManageColumnsSettings): void {
            if (!columnsSettings || !type) {
                return
            }

            this[`${ type }TableColumns`].map((item: HeaderTable, index: number) => {
                if (columnsSettings[item.key]) {
                    this[`${ type }TableColumns`][index] = { ...item, ...columnsSettings[item.key] }
                }
            })

            manageColumnsHelper.setFixedColumnsClasses(this[`${ type }TableColumns`])
        },

        matchFiltersWithColumnsSettings (filters: FilterInterface[] = [], type: InvoicesTab): FilterInterface[] {
            if (!type || !this[`${ type }TableColumns`]) {
                return filters || []
            }

            const fields = {}
            this[`${ type }TableColumns`].map(item => {
                fields[item.key] = item
            })

            return filters.map(filter => {
                if (fields[filter.field]) {
                    return { ...filter, order: fields[filter.field].order, visible: fields[filter.field].visible }
                } else {
                    return filter
                }
            })
        },

        setFilters (type: ListType, filters: ListFiltersListingParams): void {
            this.filtersListingsParams.set(type, filters)
            this.listParams.page = 1
        },

        setProjectHasMainContractExecuted (value: boolean): void {
            this.projectHasMainContractExecuted = value
        },

        nextListPage (): void {
            this.listParams.page = this.listParams.page + 1
        },

        setSorting (by: string, direction: string): void {
            this.listParams.sort_by[0].column = by
            this.listParams.sort_by[0].sort = direction
            this.listParams.page = 1
        },
    }
})
