import { Ref } from 'vue'
import useSafeLoader from '@/composables/useSafeLoader.ts'
import useNotification from '@/composables/useNotification.ts'
import UnitsClient from '@/io-modules/budget/api-clients/UnitsClient.ts'
import type { AxiosError } from 'axios'
import type Unit from '@/io-modules/budget/interfaces/Unit.ts'

const { showNotification } = useNotification()

type BudgetItemClientType = {
    getByUnitType: (unitType: string) => Promise<Omit<Unit, 'fixed_quantity' | 'default_quantity'>[]>
    getUnitsForFinanceable: (projectLocalId: string, isCalledFromBudgetModule: boolean, budgetOptionId?: string) => Promise<Unit[]>
    loading: Ref<boolean>
}

export default function useUnitsClient (): BudgetItemClientType {
    const { loading, loadWithSpinner } = useSafeLoader()

    async function getByUnitType (
        unitType: string
    ): Promise<Omit<Unit, 'fixed_quantity' | 'default_quantity'>[]> {
        return loadWithSpinner(
            async () => {
                const { data } = await UnitsClient.getByUnitType(unitType)

                return data.units
            },
            (e: AxiosError<{ message: string }>) => showNotification('error', e.response?.data?.message || e.message),
        )
    }

    async function getUnitsForFinanceable (
        projectLocalId: string,
        isCalledFromBudgetModule: boolean,
        budgetOptionId?: string,
    ): Promise<Unit[]> {
        return loadWithSpinner(
            async () => {
                const { data } = await UnitsClient.getUnitsForFinanceable(projectLocalId, isCalledFromBudgetModule, budgetOptionId)

                return data.units
            },
            (e: AxiosError<{ message: string }>) => showNotification('error', e.response?.data?.message || e.message),
        )
    }

    return { getByUnitType, getUnitsForFinanceable, loading }
}
