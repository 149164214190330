<template>
    <div class="io-notification-wrapper" @click="onNotificationWrapperClick">
        <div class="io-notification-content">

            <div class="io-notification-content__title">
                {{ $t('New Message in') }} {{ notificationData.typeText }}
                <b>
                    {{ notificationData.title }}
                </b>
            </div>

            <div class="io-notification-content__text">
                {{ showMessage(notificationData.message) }}
            </div>

            <div class="io-notification-content__requester-info">

                <span class="io-notification-content__requester-info-from">
                    {{ $t('from') }}
                </span>

                {{ notificationData.requesterName }}
            </div>
        </div>
    </div>
</template>

<script src="./Message.ts"></script>
<style lang="scss" src="./Message.scss" scoped></style>
