import { defineComponent } from 'vue'
import dayjs from 'dayjs'

import useLoader from '@/composables/useLoader.ts'
import setsClient from '@/io-modules/drawings/api-clients/setsClient'

import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import SetTypesIndex from '@/io-modules/drawings/components/set-types-index/SetTypesIndex.vue'
import SetFilesCreate from '@/io-modules/drawings/components/set-files-create/SetFilesCreate.vue'

import { SetType } from '@/io-modules/drawings/enums/SetType'
import { Set } from '@/io-modules/drawings/interfaces/Set'
import { Document } from '@/io-modules/drawings/interfaces/Upload'

export default defineComponent({
    name: 'SetsCreate',
    components: {
        IOModal,
        SetTypesIndex,
        SetFilesCreate,
    },
    inject: ['projectId'],
    props: {
        activeSets: {
            type: Array as () => Set[],
            required: true,
        },
    },
    emits: ['added', 'close'],
    setup () {
        const { loading, load } = useLoader()
        return { loading, load }
    },
    data () {
        return {
            selectingTypeStep: true,
            type: SetType.CONSTRUCTION as SetType,

            document: null as Document,
            set: null as Partial<Set>,
            date: dayjs().format('YYYY-MM-DD 12:00:00'),
        }
    },
    computed: {
        activeCurrentSet (): Set {
            return this.activeSets.find((set: Set) => set.type === SetType.CONSTRUCTION)
        },
        activeDraftSets (): Set[] {
            return this.activeSets.filter((set: Set) => set.type === SetType.DRAFT)
        },
        valid (): boolean {
            return Boolean(this.date && this.set && this.document)
        },
    },
    methods: {
        uploadNewSet (): void {
            this.load(async () => {
                await setsClient.uploadNewSet(this.set.id, {
                    project_id: this.projectId as string,
                    type: this.type,
                    temporary_document_id: this.document._id,
                    name: this.set.name,
                    issue_date: this.date,
                })
                this.$emit('added')
                this.$emit('close')
            })
        },
        reset (): void {
            this.document = null
            this.set = null
            this.date = dayjs().format('YYYY-MM-DD 12:00:00')
        },
    },
})
