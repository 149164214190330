<template>
    <div
        class="table-of-contents-item"
        :class="{ 'io-selected': isSelectedSheet }"
        :style="`background-image: url('${data.thumbnail}')`"
        @click="onSelectSheet"
    >
        <div class="table-of-contents-item__footer">
            <div class="table-of-contents-item__page-info">
                <div class="table-of-contents-item__name">{{ sheetName }}</div>

                <div class="table-of-contents-item__category">{{ data.page_category }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./TableOfContentsItem.ts"></script>
<style lang="scss" src="./TableOfContentsItem.scss" scoped></style>
