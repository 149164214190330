// Not using new URLSearchParams(object).toString() -> returns query string with undefined values
// See https://gitlab.com/ingeniousio1/iCON-web-app/-/merge_requests/4864

const stringify = (obj, prefix) => {
    let str = []
    let p

    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let key = prefix ? prefix + "[" + p + "]" : p
            let value = obj[p]

            if (!key) {
                continue
            }

            str.push(encodeKeyValue(key, value))
        }
    }

    return str.filter(Boolean).join('&')
}

const encodeKeyValue = (key, value) => {
    if (value !== null && typeof value === 'object') {
        return stringify(value, key)
    }

    if (value !== undefined) {
        return encodeURIComponent(key) + '=' + encodeValue(value)
    }
}

const encodeValue = (value)  => {
    if(value === null) {
        return ''
    }

    return encodeURIComponent(value)
}


export default { stringify }
