import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InvitationStatus',
    props: {
        data: { type: Object, required: true },
        column: { type: Object, required: true },
        columnClass: { type: String, required: false, default: null }
    },
    computed: {
        accepted (): boolean {
            return this.data.status === 'accepted'
        },
        tooltip (): string | undefined {
            return this.column?.additional?.tooltip
        },
        iconName (): string | undefined {
            return this.column?.additional?.iconName
        },
        additionalIconName (): string | undefined {
            return this.column?.additional?.additionalIconName
        },
        iconClass (): string | undefined {
            return true === this.invited ? this.iconName : this.additionalIconName
        },
        cellClass (): string | undefined {
            let className = this.column?.columnClass

            if (this.data[this.column.field]) {
                className = `${ className } io-active`
            }

            return className
        },
        action (): string {
            if (this.data.status === 'saved') {
                return 'Invite'
            } else {
                return 'Remind'
            }
        },
        mappedValue (): any {
            return {
                'saved': 'Bookmarked',
                'invited': 'Pending Invite',
            }
        }
    },
    methods: {
        emitAction (e: Event): void {
            e.preventDefault()

            const status = this.data.status
            const invitation_id = this.data.invitation_id

            this.$emit('emitIconAction', { field: this.column.field, status, invitation_id })
        },
    }
})
