<template>
    <td :class="className">
        <div v-if="status || isBoolean" class="io-color-dot" :class="[dotColorClassName, textColorClassName, noTooltipClassName]">
            <span v-if="tooltipValue && !tooltipIcon" v-tooltip="tooltipValue"></span>
            <span v-else></span>
            {{ text }}
            <Tooltip v-if="tooltipIcon" :text="tooltipValue" :additionalClassName="tooltipAdditionalClassName"/>
        </div>
    </td>
</template>

<script lang="ts" src="./ColorDot.ts"></script>

<style lang="scss" src="./ColorDot.scss" scoped></style>
