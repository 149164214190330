<template>
    <div class="io-right-panel-details">
        <div class="io-right-panel-details__content">
            <div>
                <label class="io-flex-label">
                    {{ $t('Name') }}
                    <i v-if="canEdit" class="icon-edit" @click="openEditPopup"></i>
                </label>
                <span>{{ model.page_title }}</span>
            </div>

            <div class="io-f-row gap-32-px">
                <div class="io-right-panel-details__category">
                    <label>{{ $t('Category') }}</label>
                    <span>{{ model.page_category_name ? $t(model.page_category_name) : '-' }}</span>
                </div>

                <div>
                    <label>{{ $t('Issue Date') }}</label>
                    <span>{{ $filters.parseDate(model.drawing_set_issue_date) }}</span>
                </div>
            </div>

            <div class="io-f-row gap-32-px">
                <div>
                    <label>{{ $t('Uploaded By') }}</label>
                    <div class="io-uploaded-by">
                        <div class="io-uploaded-by__avatar">
                            <template v-if="model.drawing.created_by_full_name.imageExist">
                                <img :src="getApiUrlAvatar(model.drawing.created_by_full_name.id)" />
                            </template>
                            <template v-else>
                                <Initials :fullName="model.drawing.created_by_full_name.name" />
                            </template>
                        </div>
                        <div class="io-uploaded-by__user-info">
                            <div>{{ model.drawing.created_by_full_name.name }}</div>
                            <div>{{ model.drawing.created_by_full_name.position }}</div>
                        </div>
                    </div>
                </div>

                <div>
                    <label>{{ $t('Upload Date') }}</label>
                    <span>{{ $filters.parseDate(model.created_at) }}</span>
                </div>
            </div>

            <div v-if="isFeatureFlagEnabled">
                <label>{{ $t('Version') }}</label>
                <div>{{ selectedVersionLabel }}</div>
                <div class="io-see-versions" @click="goToHistoryTab">{{ $t('See Versions') }}</div>
            </div>
        </div>

        <EditPopup v-if="editPopupShown" @close="closeEditPopup" />
    </div>
</template>

<script lang="ts" src="./Details.ts"></script>
<style lang="scss" src="./Details.scss" scoped></style>
