<template>
    <div @click="redirectToRoute()" class="io-single-message io-single-message--new">
        <div class="io-single-message__picture-holder">
            <div v-if="true" class="io-single-message__photo"
                 :style="getBackgroundAvatar(item.requesterId)"></div>
            <initials v-else class="io-single-message__initials" fullName="Author Name"></initials>
            <!--<div class="io-single-message__indicator"><span>3</span></div>-->
        </div>
        <div class="io-single-message__content">
            <div class="io-single-message__title-row">
                <div class="io-single-message__title">
                    {{ item.title }}
                    <span class="io-status-pill io-accent">{{ item.counter || 1 }} UNREAD</span>
                </div>
                <div class="io-single-message__time">{{ $filters.agoDate(item.created_at) }}</div>
                <div class="io-single-message__dismiss" @click.stop="markAlertAsRead(item)"><i class="far fa-times"></i>
                </div>
            </div>
            <div class="io-single-message__info-row">
                <div class="io-single-message__text">{{ item.message }}</div>
                <div class="io-single-message__author">{{ $t('by') }} <span>{{ item.requesterName }}</span></div>
            </div>
        </div>
    </div>
</template>
<script>
import notificationMixin from './mixins/notificationMixin'

export default {
    mixins: [notificationMixin],
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    name: 'DiscussionItem',
    methods: {
        redirectToRoute () {
            this.$emit('closeList')
            this.markAlertAsRead(this.item)
            switch (this.item.relatedType) {
            case 'todo':
                this.$router.push({ name: 'todo-view', params: { id: this.item.relatedId } })
                break
            case 'contract':
                this.$router.push({ name: 'contract-edit', params: { id: this.item.relatedId } })
                break
            case 'rfp':
                if (!this.item.additionalParams) {
                    this.$router.push({
                        name: 'rfp-view',
                        params: {
                            id: this.item.relatedId
                        }
                    })
                    break
                }
                if (this.item.additionalParams.to === 'receiver-proposal') {
                    this.$router.push({ name: 'proposal-discussion', params: { id: this.item.additionalParams.id } })
                    break
                }
                if (this.item.additionalParams.to === 'receiver-rfp') {
                    this.$router.push({
                        name: 'rfp-view',
                        params: {
                            id: this.item.additionalParams.id
                        },
                        query: {
                            tab: 'discussion'
                        }
                    })
                    break
                }
                if (this.item.additionalParams.to === 'sender-project') {
                    this.$router.push({
                        name: 'project-bid-requests-view-details',
                        params: {
                            pid: this.item.additionalParams.id,
                            package: this.item.additionalParams.bidRelatedId
                        },
                        query: {
                            sender: this.item.additionalParams.senderId,
                            discussion: 1
                        }
                    })
                    break
                }
                if (this.item.additionalParams.to === 'sender-proposal') {
                    this.$router.push({
                        name: 'proposal-bidrequests-id',
                        params: {
                            id: this.item.additionalParams.id,
                            package: this.item.additionalParams.bidRelatedId
                        },
                        query: {
                            sender: this.item.additionalParams.senderId,
                            discussion: 1
                        }
                    })
                    break
                }
                break
            case 'costliability':
                this.$router.push({ name: 'project-mychangeorder-edit', params: { id: this.item.relatedId } })
                break
            }
        }
    }
}
</script>
