import { defineComponent } from 'vue'
import { omit } from 'lodash'

export default defineComponent({
    name: 'IOTextarea',
    inheritAttrs: false,
    props: {
        modelValue: { type: String, required: true },
        valid: { type: Boolean, default: true },
        errorMessage: { type: String, default: '' },
        counterOutside: Boolean,
        autoHeight: Boolean,
    },
    data () {
        return {
            focused: false,
        }
    },
    computed: {
        currentLength (): number {
            return this.modelValue?.length || 0
        },
        disabled (): boolean {
            return Boolean(this.$attrs.disabled || this.$attrs.disabled === '')
        },
    },
    watch: {
        modelValue (): void {
            this.updateHeight()
        },
    },
    mounted () {
        this.focus() // Retain autofocus in modals
        this.updateHeight()
    },
    methods: {
        omit,
        focus (): void {
            // 'preventScroll' to prevent breaking transition animations
            this.$el.querySelector('[autofocus]')?.focus({ preventScroll: true })
        },
        updateHeight (): void {
            if (this.autoHeight) {
                const { textarea } = this.$refs
                textarea.style.height = 'auto'
                textarea.style.height = textarea.scrollHeight + 'px'
            }
        },
    }
})
