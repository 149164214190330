<template>
    <LoadingScreen :custom-show-loading="loading || dataLoading"/>
    <ApprovalsWrapper
        v-if="!loading"
        ref="approvalsWrapper"
        :approvableResourceId="approvableResourceId"
        :isClientOffSystem="!isClientOnSystem"
        :force-client-approval="shouldForceClientApproval"
        v-model:approvalModalData="approvalModalData"
        @approved="refreshData"
        @rejected="refreshData"
        @clientApproved="refreshData"
        @submitToClient="callSubmitToClientModal"
        @loaded="approvalResourceOnLoad"
    >
        <template #default="{ resourceApprovals }">
            <div
                class="io-invoice-view-wrapper"
                :class="{ 'io-invoice-view-wrapper--sov': isSoVTab }"
            >
                <PageHeader :title="pageHeaderTitle" :route-back="backButtonLink">
                    <template #actions>
                        <TopBarActions
                            ref="invoiceActions"
                            :currentStep="currentStep"
                            :stepsCount="visibleSteps.length"
                            :allStepsCompleted="allStepsCompleted"
                            :resourceApprovals="resourceApprovals"
                            v-model:approvalModalData="approvalModalData"
                            @nextStep="nextStep"
                            @prevStep="prevStep"
                            @cancel="onCancelClick"
                            @invoiceCreated="onInvoiceCreated"
                            @refreshApprovableResourceData="refreshApprovableResourceData"
                        />
                    </template>
                    <template v-if="!applyingFunding" #tabs>
                        <Stepper
                            v-if="creating"
                            :isFloating="false"
                            :availableSteps="visibleSteps"
                            :shadow="false"
                            :no-content="true"
                            v-model="currentStep"
                            @onClickStep="onClickTab"
                        >
                            <template #default>
                                <StepperStep
                                    v-for="(step, index) in visibleSteps"
                                    :key="index"
                                    :step="index"
                                    :valid="step.valid"
                                    :title="step.title"
                                />
                            </template>
                        </Stepper>
                        <Tabs
                            v-else-if="invoice.id"
                            :tabs="invoiceTabs"
                            :active-tab="currentStep"
                            :show-title="false"
                            @click="onClickTab"
                        />
                    </template>
                </PageHeader>
                <InvoiceBanners
                    @revertToDraft="$refs.invoiceActions.initRevertToDraft()"
                    @sendRevision="sendRevisionToVendor"
                />
                <router-view :key="$route.name"
                             :resourceApprovals="resourceApprovals"
                             v-model:approvalModalData="approvalModalData"
                             @refreshApprovableResourceData="refreshApprovableResourceData"
                             @submitToClient="callSubmitToClientModal"
                             @markAsSubmittedToClient="callMarkAsSubmittedToClient"
                />
            </div>
        </template>
    </ApprovalsWrapper>
</template>

<script lang="ts" src="./InvoiceViewTemplate.ts"></script>
<style lang="scss" src="./InvoiceViewTemplate.scss" scoped></style>
