<template>
    <tr v-if="!isFinal" class="io-funding-sources-row" @click="openProject">
        <td>
            {{ project.project_name }}
            <span class="icon-external-link io-funding-sources-row__icon-link"></span>
        </td>
        <td v-if="!isCapitalFund">
            {{ project.fund_name }}
        </td>
        <td class="aright">
            {{ $filters.formatCurrency(project.allocated_amount) }}
        </td>
        <td class="aright">
            {{ $filters.formatCurrency(project.summary.total_paid_to_date_amount) }}
        </td>
        <td class="aright">
            {{ $filters.formatCurrency(project.summary.remaining_amount) }}
        </td>
    </tr>
    <tr v-else class="io-funding-sources-row__total">
        <td>
            {{ $t('Total') }}
        </td>
        <td v-if="!isCapitalFund"></td>
        <td class="aright">
            {{ $filters.formatCurrency(project.allocated_amount) }}
        </td>
        <td class="aright">
            {{ $filters.formatCurrency(project.total_paid_to_date_amount) }}
        </td>
        <td class="aright">
            {{ $filters.formatCurrency(project.remaining_amount) }}
        </td>
    </tr>
</template>

<script lang="ts" src="./FundingSourcesProjectRow.ts"></script>
<style lang="scss" src="./FundingSourcesProjectRow.scss" scoped></style>
