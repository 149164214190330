import { defineComponent } from 'vue'
import IOLabel from '../atoms/IOLabel/IOLabel.vue'
import { LabelSize, LabelType } from '@/constants/LabelConstants'

export default defineComponent({
    name: 'LabelText',
    components: {
        IOLabel,
    },
    props: {
        label: { type: String, required: true },
        labelType: { type: String, default: LabelType.Bold },
        text: { type: String, required: true },
        textSize: { type: String,  default: LabelSize.Small },
    },
})
