<template>
    <div class="io-app-packages-invoices-table">
        <Table
            v-if="invoicesList"
            :headers="tableHeaders"
            :items="invoicesList"
        >
            <template #tbody="{ sortedItems }">
                <tr v-for="invoice in sortedItems" :key="invoice.id">
                    <td>
                        {{ invoice.invoice_type }}
                    </td>
                    <td>
                        {{ invoice.invoice_number }}
                    </td>
                    <td>
                        {{ invoice.invoice_unique_number }}
                    </td>
                    <td>
                        {{ $filters.formatDateOnly(invoice.invoice_date) }}
                    </td>
                    <td>
                        <Avatar
                            size="sm"
                            type="company"
                            :employee-mongo-id="invoice.vendor.id"
                            :employee-full-name="invoice.vendor.name"
                            show-full-name
                        />
                    </td>
                    <td>
                        <StatusPill
                            size="big"
                            :text="invoice.status_text"
                            :variant="getInvoiceStatusColor(invoice.status)"
                        />
                    </td>
                    <td>
                        {{ $filters.formatProjectCurrencyInt(invoice.gross_billed_this_period) }}
                    </td>
                    <td>
                        <IOButton
                            v-if="!isInvoiceAttached(invoice.id)"
                            variant="link"
                            icon="icon-paperclip"
                            @click="attachInvoice(invoice.id)"
                        >
                            {{ $t('Attach') }}
                        </IOButton>
                        <div class="io-app-packages-invoices-table__buttons">
                            <IOButton
                                v-if="isInvoiceAttached(invoice.id)"
                                class="io-app-packages-invoices-table__buttons--default-state"
                                variant="link"
                                icon="icon-check-circle-2-2"
                            >
                                <span class="text-main">{{ $t('Attached') }}</span>
                            </IOButton>
                            <IOButton
                                v-if="isInvoiceAttached(invoice.id)"
                                class="io-app-packages-invoices-table__buttons--hover-state"
                                variant="link"
                                icon="icon-cross text-error-darker mr-2"
                                @click="detachInvoice(invoice.id)"
                            >
                                <span class="text-error-darker">{{ $t('Remove') }}</span>
                            </IOButton>
                        </div>
                    </td>
                </tr>
            </template>
        </Table>
    </div>
</template>

<script lang="ts" src="./AppPackageInvoicesTable.ts"></script>
<style lang="scss" src="./AppPackageInvoicesTable.scss" scoped></style>
