import { defineComponent, VNode } from 'vue'

import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import StampPreview from '@/io-modules/punch-lists/components/stamp-preview/StampPreview.vue'
import StampsIndex from '@/io-modules/punch-lists/components/stamps-index/StampsIndex.vue'

import { Stamp } from '@/io-modules/punch-lists/interfaces/Stamp'

export default defineComponent({
    name: 'StampSelect',
    components: {
        AlertBox,
        StampPreview,
        StampsIndex,
    },
    props: {
        modelValue: {
            type: Object as () => Stamp,
            default: null,
        },
    },
    data () {
        return {
            stampsIndexShown: false,
        }
    },
    computed: {
        hasSlotContent (): boolean {
            return this.$slots.default().some((vnode: VNode) => typeof vnode.type !== 'symbol')
        },
    },
    methods: {
        selectStamp (stamp: Stamp): void {
            this.$emit('update:modelValue', stamp)
            this.stampsIndexShown = false
        },
        toggleStampsIndex (): void {
            this.stampsIndexShown = !this.stampsIndexShown
        },
    },
})
