<template>
    <div class="io-capital-funds">
        <Accordion
            :title="fund.name"
            :show-line="false"
            :open="false"
            icon="icon-chevron-down"
            :rotate-degrees="90"
            rotate-direction="left"
            icon-color="gray"
            @toggle="getFundProjectsData"
        >
            <template #header-right>
                <div class="io-capital-funds__fund__right">
                    <div class="io-capital-funds__fund__value">
                        {{ $t('Fund Amount') }}
                        <span>{{ $filters.formatCurrency(fund.amount) }}</span>
                    </div>
                    <div class="io-capital-funds__fund__value">
                        {{ $t('Unallocated Amount') }}
                        <span>{{ $filters.formatCurrency(fund.summary.total_projects_unallocated_amount) }}</span>
                    </div>
                    <IOButton variant="link" @click.stop="goToFundDetails(fund.id)">
                        {{ $t('View Fund Details') }}
                    </IOButton>
                </div>
            </template>
            <template #default>
                <Table
                    v-if="rows"
                    :headers="tableHeaders"
                    :items="rows"
                >
                    <template #tbody="{ sortedItems }">
                        <FundingSourcesProjectRow
                            v-for="project in sortedItems"
                            :key="project.id"
                            :project="project"
                        />
                        <FundingSourcesProjectRow
                            :project="totals"
                            :is-final="true"
                        />
                    </template>
                </Table>
            </template>
        </Accordion>
    </div>
</template>

<script lang="ts" src="./CapitalFundsAccordion.ts"></script>
<style lang="scss" src="./CapitalFundsAccordion.scss" scoped></style>
