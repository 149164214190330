import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import RequestedDataErrorPlaceholder from '@/io-modules/invoices/components/requested-data-error-placeholder/RequestedDataErrorPlaceholder.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import HorizontalBarChart from '@/components/horizontal-bar-chart/HorizontalBarChart.vue'
import BarChartItem from '@/components/horizontal-bar-chart/interfaces/BarChartItem'
import { BarChartItemColor } from '@/components/horizontal-bar-chart/interfaces/BarChartItemColor'

export default defineComponent ({
    name: 'GrossBillingSummary',
    components: {
        RequestedDataErrorPlaceholder,
        StatusPill,
        HorizontalBarChart,
    },
    data () {
        return {
            loading: false,
            error: false,
        }
    },
    computed: {
        ...mapStateVuex('project', ['projectObj']),

        ...mapState(invoiceViewStore, ['invoiceGrossBillingSummary']),

        grossBilledToDate (): string {
            const value = this.invoiceGrossBillingSummary.gross_billed_to_date_amount || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        grossBilledToDatePercentage (): string {
            const value = this.invoiceGrossBillingSummary.gross_billed_to_date_percent || 0
            return this.$filters.formatPercentInt(value)
        },

        grossInvoiceAmount (): string {
            const value = this.invoiceGrossBillingSummary.gross_invoice_amount || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        grossInvoicePercentage (): string {
            const value = this.invoiceGrossBillingSummary.gross_invoice_percent || 0
            return this.$filters.formatPercentInt(value)
        },

        balanceToComplete (): string {
            const value = this.invoiceGrossBillingSummary.balance_to_complete || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        balanceToCompletePercentage (): string {
            const value = this.invoiceGrossBillingSummary.balance_to_complete_percent || 0
            return this.$filters.formatPercentInt(value)
        },

        barChartItems (): BarChartItem[] {
            const grossBilledToDate = this.invoiceGrossBillingSummary.gross_billed_to_date_percent || 0
            const grossInvoiceAmount = this.invoiceGrossBillingSummary.gross_invoice_percent || 0
            const balanceToComplete = this.invoiceGrossBillingSummary.balance_to_complete_percent || 0

            return [
                {
                    value: grossBilledToDate ? grossBilledToDate / process.env.SCALE_FACTOR : 0,
                    color: BarChartItemColor.GREEN,
                },
                {
                    value: grossInvoiceAmount ? grossInvoiceAmount / process.env.SCALE_FACTOR : 0,
                    color: BarChartItemColor.BLUE,
                },
                {
                    value: balanceToComplete ? balanceToComplete / process.env.SCALE_FACTOR : 0,
                    color: BarChartItemColor.GRAY,
                },
            ]
        },
    },
    mounted () {
        this.fetchData()
    },
    methods: {
        ...mapActions(invoiceViewStore, ['fetchInvoiceGrossBillingSummary']),
        
        async fetchData (): Promise<void> {
            try {
                this.loading = true
                await this.fetchInvoiceGrossBillingSummary(this.projectObj.project_local_id, this.$route.params.id)
                this.loading = false
                this.error = false
            } catch (e) {
                this.error = true
            } finally {
                this.loading = false
            }
        },
    }
})