const AuthRoute = () => import('@/modules/auth/Login.vue')

export default [
    {
        path: '/auth-data',
        name: 'auth-data',
        component: AuthRoute,
        meta: {
            skipAuth: true
        }
    }
]
