export default {
    STEPS: {
        type: 'invoice-type',
        retentionSubtype: 'retention-subtype'
    },

    RETENTION_TYPES: {
        own: 'own',
        vendor: 'vendor'
    }
}
