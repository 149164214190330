import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'
import { AnnotationData } from '@/io-modules/documents/interfaces/AnnotationData'

export default (annotations: AnnotationData[], searchedValue: string, type: AnnotationTypes): AnnotationData[] => {
    if (searchedValue) {
        return annotations.filter((annotation: AnnotationData) =>
            annotation.type.toUpperCase() === type.toUpperCase()
            && annotation.comment.comment_text.toUpperCase().includes(searchedValue.toUpperCase()),
        )
    }

    return annotations.filter((annotation: AnnotationData) => annotation.type.toUpperCase() === type.toUpperCase())
}
