<template>
    <IOModal
        icon="icon-arrow-swap-rec"
        width="560"
        :title="$t('Request Revision')"
        :color="Color.YELLOW"
        @close="closeModal"
    >
        <template #default>
            <div class="io-content-wrapper">
                <div>
                    <h3 class="io-content-wrapper__title">{{ $t('Please provide a brief explanation for why you are requesting a revision') }}</h3>
                    <div class="io-content-wrapper__sub-title">{{ $t('This reason will be visible to the 2nd party.') }}</div>
                </div>

                <slot name="aboveTextarea"/>

                <IOTextarea :placeholder="$t('Enter reason for revision here...')" v-model="reasonText" />
            </div>
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="closeModal">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton :disabled="!reasonText" @click="requestRevision">
                {{ $t('Request Revision') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./RequestRevisionModal.ts"></script>
<style lang="scss" src="./RequestRevisionModal.scss" scoped></style>
