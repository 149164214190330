<template>
    <div>
        <PageHeader
            v-if="pageHeaderShown"
            no-bottom-margin
            :title="drawingSet?.name"
            :route-back="{ name: 'drawings-sets-index' }"
        >
            <template #switches>
                <ToggleSwitch :options="toggleSwitchOptions" v-model="currentSheetsView"/>
            </template>

            <template #actions>
                <SearchInput
                    :placeholder="$t('Search...')"
                    :loader="false"
                    @searchChanged="setSearch"
                />

                <FiltersButton/>

                <ActionDropdown icon-class="icon-more-vert-2" :icon-tooltip="$t('More')">
                    <div
                        v-if="drawingSet?.type === SetType.DRAFT"
                        class="io-single-option"
                        @click="toggleIsConstructionSet(true)"
                    >
                        <i class="icon-toggle-on"/>
                        {{ $t('Mark as Current Set') }}
                    </div>

                    <div
                        v-else-if="drawingSet?.type === SetType.CONSTRUCTION && isCreatedByCurrentUser"
                        class="io-single-option"
                        @click="toggleIsConstructionSet(false)"
                    >
                        <i class="icon-toggle-off"/>
                        {{ $t('Deactivate Current Set') }}
                    </div>

                    <div
                        class="io-single-option"
                        @click="downloadSet"
                    >
                        <i class="icon-file-download"/>
                        {{ $t('Download') }}
                    </div>

                    <div
                        v-if="drawingSet?.status !== SetStatus.ARCHIVED"
                        class="io-single-option"
                        @click="renameModalShown = true"
                    >
                        <i class="icon-edit-3"/>
                        {{ $t('Rename') }}
                    </div>
                </ActionDropdown>

                <IOButton
                    icon="icon-plus"
                    @click="addingNewSetModalShown = true"
                >
                    {{ $t('Add New Drawings') }}
                </IOButton>
            </template>
        </PageHeader>

        <div class="io-project-drawings-sheet">
            <ActionBar
                v-if="showActionBar"
                :items-quantity="selectedRows.length"
                @clear-selections="setSelectedRows([])"
            >
                <template #actions>
                    <IOButton variant="link" size="small" icon="icon-file-download" @click="onDownload(selectedRows)">
                        {{ $t('Download') }}
                    </IOButton>

                    <IOButton variant="link" size="small" icon="icon-edit" @click="showBulkEditModal = true">
                        {{ $t('Edit Category') }}
                    </IOButton>
                </template>
            </ActionBar>

            <div class="io-project-drawings-sheet__header">
                <div class="io-project-drawings-sheet__header-left">
                    <div v-if="drawingSet" class="io-project-drawings-sheet__title">
                        <span
                            v-if="headerStatusPill"
                            class="io-status-pill"
                            :class="headerStatusPill.class"
                        >
                            {{ headerStatusPill.text }}
                        </span>
                    </div>

                    <ShareWith
                        v-if="showSharedWith && drawingSet"
                        :drawing-set-id="drawingSet?.id"
                        :drawing-set-is-public="drawingSet?.is_public"
                        :shared-with="drawingSet?.shared_with"
                        :employees-list="drawingSet?.on_item"
                    />
                </div>

                <div class="io-project-drawings-sheet__header-right">
                    <div class="io-uploading-history" @click="historyModalShown = true">
                        <i class="icon-timer-2"/>
                        <span>{{ $t('Uploading History') }}</span>
                    </div>

                    <div class="io-timeline" @click="timelineModalShown = true">
                        <i class="icon-history"/>
                        <span>{{ $t('Timeline') }}</span>
                    </div>
                </div>
            </div>

            <KeepAlive>
                <SheetsList v-if="currentSheetsView === SheetsView.LIST" @download="onDownload([$event])"/>

                <SheetsGrid v-else/>
            </KeepAlive>

            <RenameSet
                v-if="renameModalShown"
                :id="drawingSet?.id"
                :name="drawingSet?.drawing_set_name"
                @close="onCloseRenameSetPopup"
                @change-name="onConfirmRenameSetPopup"
            />

            <SetsCreate
                v-if="addingNewSetModalShown"
                :active-sets="activeSets"
                @close="addingNewSetModalShown = false"
                @added="$router.push({ name: 'drawings-sets-index' })"
            />

            <BulkEditModal
                v-if="showBulkEditModal"
                :categories="drawingCategories"
                @close="showBulkEditModal = false"
                @save="updateSheetsCategory"
            />

            <SetHistoryLogsIndex
                v-if="historyModalShown"
                :id="setId"
                @close="historyModalShown = false"
            />

            <TimelineModal
                v-if="timelineModalShown"
                :url="timelineRequestUrl"
                @close="timelineModalShown = false"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./SheetsIndex.ts"></script>
<style lang="scss" src="./SheetsIndex.scss" scoped></style>
