<template>
    <PopupModal class="io-popup-create-new-stamp" :width="450" @close="close">

        <template #header>
            <div class="io-popup-modal__icon"><i class="fal fa-exclamation-circle io-info"></i></div>
            <div class="io-popup-modal__title">
                {{
                    editingExistingStamp ? $t('Edit Stamp') : $t('Create New Stamp')
                }}
            </div>
            <div class="io-popup-modal__close" @click="close"><i class="fal fa-times"></i></div>
        </template>

        <template #default>
            <div class="io-popup-create-new-stamp__inputs">
                <div>
                    <label class="io-required">{{ $t('Initials') }}</label>
                    <input
                        class="io-popup-create-new-stamp__initials"
                        type="text"
                        :class="{ 'io-disabled': editingExistingStamp && !editingStampInfo?.can_edit }"
                        :disabled="editingExistingStamp && !editingStampInfo?.can_edit"
                        :maxlength="2"
                        v-model="stamp.code"
                    >
                </div>

                <div>
                    <label class="io-required">{{ $t('Stamp Title') }}</label>
                    <input type="text" :maxlength="30" v-model="stamp.name">
                </div>
            </div>

            <div class="io-popup-create-new-stamp__colors">
                <label>{{ $t('Color') }}</label>
                <div>
                    <div class="io-selected-color">
                        <div :style="{ backgroundColor: selectedColor }"></div>
                    </div>
                    <template v-for="(color, index) in colors" :key="index">
                        <div
                            class="io-single-color"
                            :class="{ 'io-disabled': editingExistingStamp && !editingStampInfo?.can_edit }"
                            :style="{ backgroundColor: color }"
                            @click="setColor(color)"
                        ></div>
                    </template>
                </div>
            </div>

            <div class="io-popup-create-new-stamp__preview">
                <label>{{ $t('Preview') }}</label>
                <div class="io-stamp-preview">
                    <div
                        class="io-stamp-preview__icon"
                        :style="{ borderColor: selectedColor, color: selectedColor }"
                    >
                        {{ $filters.filterEmptyValue(stamp.code) }}
                    </div>

                    <div class="io-stamp-preview__title">{{ $filters.filterEmptyValue(stamp.name) }}</div>
                </div>
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="close">{{ $t('Cancel') }}</IOButton>
            <IOButton v-if="editingExistingStamp" :disabled="!isValid" @click="updateStamp">{{ $t('Save') }}</IOButton>
            <IOButton v-else :disabled="!isValid" @click="addNewStamp">{{ $t('Create Stamp') }}</IOButton>
        </template>

    </PopupModal>
</template>

<script lang="ts" src="./CreateNewStampPopup.ts"></script>
<style lang="scss" src="./CreateNewStampPopup.scss" scoped></style>
