<template>
    <ul class="io-design-system io-progress-track">
        <li
            v-for="n in total"
            :key="n"
            class="io-progress-track__item"
            :class="{ 'io-progress-track__item--active': n <= current }"
        />
    </ul>
</template>

<script lang="ts" src="./ProgressTrack.ts"></script>
<style lang="scss" src="./ProgressTrack.scss" scoped></style>
