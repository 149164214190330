import { defineComponent } from 'vue'
import { useVuelidate } from '@vuelidate/core'

export default defineComponent({
    data () {
        return {
            v$: useVuelidate(),
        }
    },
})
