import FeasibilityStudyState from '@/interfaces/store/FeasibilityStudyState'
import BudgetVersionsSorting from '@/interfaces/modules/budget/versioning/BudgetVersionsSorting'
import UnitOfMeasure from '@/interfaces/UnitOfMeasure'

const feasibilityStudyState: FeasibilityStudyState = {
    feasibilityStudy: null,
    showHiddenBudgetVersions: false,
    budgetVersionsCount: 0,
    hiddenBudgetVersionsCount: 0,
    selectedSortingType: BudgetVersionsSorting.OLD_TO_NEW,
    showTotalPerUnit: false,
    unitOfMeasure: UnitOfMeasure.IMPERIAL,
    unitOfMeasureLoaded: false,
    hasBudgetVersions: false
}

export default feasibilityStudyState
