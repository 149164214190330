import { defineComponent } from 'vue'
import { mapActions } from 'pinia'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import InvoicesTab from '@/io-modules/invoices/enums/InvoicesTab'
import InvoicesListTable from '@/io-modules/invoices/components/invoices-list/invoices-list-table/InvoicesListTable.vue'
import InvoicesListHeader from '@/io-modules/invoices/components/invoices-list/invoices-list-header/InvoicesListHeader.vue'
import ApplicationPackage from '@/io-modules/invoices/interfaces/ApplicationPackage.vue'
import ListsMixin from '@/io-modules/invoices/mixins/ListsMixin'
import AppPackageRouteName from '@/io-modules/invoices/enums/AppPackageRouteName.ts'
import { appPackageViewStore } from '../../stores/appPackageView'
import ConfirmationModalsService from '@/io-modules/invoices/services/ConfirmationModalsService'
import appPackageClient from '../../api-clients/appPackageClient'
import listTypeName from '@/io-modules/invoices/helpers/listTypeName.ts'
import invoicesListsClient from '@/io-modules/invoices/api-clients/invoicesListsClient.ts'

export default defineComponent({
    name: 'ApplicationPackagesList',
    components: {
        InvoicesListTable,
        InvoicesListHeader,
        LoadingScreen,
    },
    mixins: [ListsMixin],
    data () {
        return {
            ctaButtons: [
                {
                    name: this.$t('Create Application Package'),
                    onSubmitName: 'createApplicationPackage'
                },
            ],
            InvoicesTab,
            loading: false,
            loadingData: false,
            totals: null
        }
    },
    mounted () {
        this.loadAppPackages()
    },
    methods: {
        ...mapActions(invoicesStore, [
            'fetchApplicationPackages',
            'setSorting',
            'clearListFilters',
            'clearListParams',
            'fetchFilters'
        ]),

        ...mapActions(appPackageViewStore, [
            'setCreateModeValue',
        ]),

        async loadAppPackages (): Promise<void> {
            try {
                this.setContentLoaderValue(true)
                this.setSorting('billing_period', 'asc')
                await this.fetchFilters(this.projectObj.project_local_id, listTypeName(this.$route.meta.listType))
                await this.fetchApplicationPackages(this.projectObj.project_local_id)

                const { data } = await invoicesListsClient.getAppPackageListTotals(this.projectObj.project_local_id)
                this.totals = data
            } catch (error) {
                console.log(error)
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            } finally {
                this.setContentLoaderValue(false)
            }
        },

        createApplicationPackage (): void {
            this.$router.push({
                name: AppPackageRouteName.AppPackageDetails,
                params: { id: 0 },
            })

            this.setCreateModeValue(true)
        },

        removeAppPackage (item: ApplicationPackage): void {
            this.showPopupAlert(ConfirmationModalsService.deleteAppPackage(() => this.deleteAppPackage(item)))
        },

        async deleteAppPackage (item: ApplicationPackage): void {
            try {
                this.loading = true
                await appPackageClient.deleteApplicationPackage(this.projectObj.project_local_id, item.id)
                await this.fetchApplicationPackages(this.projectObj.project_local_id)
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.loading = false
            }
        },

        async loadData (): Promise<void> {
            this.loadingData = true
            await this.fetchApplicationPackages(this.projectObj.project_local_id)
            this.loadingData = false
        },
    },
})
