import { defineComponent } from 'vue'
import SelectFilesPopup from '@/components/files-new/parts/select-files-popup/SelectFilesPopup.vue'
import { isEmpty, uniqueId } from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import NotificationType from '@/interfaces/layout/NotificationType.ts'

export default defineComponent({
    components: {
        SelectFilesPopup,
    },
    props: {
        addToTemporary: {
            type: Boolean,
            required: false
        },
        arrayId: {
            type: String,
            required: false
        },
        modelType: {
            type: String,
            required: false
        },
        filesSection: {
            type: String,
            required: false,
            default: null
        },
        subSection: {
            type: String,
            required: false,
            default: null
        },
        forceShared: {
            type: Boolean,
            required: false,
            default: false
        },
        modelId: {
            type: [String, Number],
            required: false,
            default: null
        },
        validationOptions: {
            type: Object,
            required: false,
            default: () => ({
                allowedExtensions: [],
                acceptFiles: []
            })
        },
        inDocumentsUpload: {
            type: Boolean,
            required: false,
            default: false
        },
        projectLocalId: {
            type: String,
            required: false,
            default: null
        },
        defaultShare: {
            type: Boolean,
            required: false,
            default: false
        },
        threadId: {
            type: String,
            required: false,
            default: null
        },
        multipleFiles: {
            type: Boolean,
            required: false,
            default: true
        },
        isVisible: {
            type: Boolean,
            required: false,
            default: true
        },
        additionalRequestData: {
            type: Object,
            required: false,
            default: () => ({})
        },
        customTitle: {
            type: String,
            required: false,
        },
        showNotifications: {
            type: Boolean,
            default: false,
        },
        isUploadingIntoSharedFolder: {
            type: Boolean,
            default: false,
        },
        enableTeleport: {
            type: Boolean,
            default: true,
        },
        enableFileExtensionFilteringInFileSelector: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['updateDocuments', 'reloadUploader', 'multipleFilesChange'],
    data () {
        return {
            uploading: false,
            isDraggingOver: false,
            loader: false,
            instance: {},
            isProjectView: false,
            countFiles: 0,
            folderCreated: false,
            selectFilesPopupVisible: false,
            clonedModelId: null,
            buttonId: null,
            showProgress: false,
            isMounted: false
        }
    },
    beforeMount () {
        this.setModelId()
    },
    mounted () {
        if (this.$route.name === 'project-documents-list') {
            this.isProjectView = true
        }

        this.$emit('multipleFilesChange', this.multipleFiles)

        // for storybook preview
        if (this.modelId === 'storybook') {
            this.loader = true
        }

        this.isMounted = true
    },
    computed: {
        ...mapGetters(
            'appStore', ['getAuthData']
        ),
        ...mapState('bidrequest', {
            tabActive: state => state.tabActive
        }),
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        filesToUpload () {
            return this.$store.getters['filesComponent/getUploadingFiles'](this.arrayId)
        },
        acceptedFilesExtensions (): string | undefined {
            return this.validationOptions?.allowedExtensions.length > 0 && this.enableFileExtensionFilteringInFileSelector
                ? this.validationOptions.acceptFiles.join(',')
                : undefined
        }
    },
    methods: {
        uniqueId,
        ...mapActions('filesComponent', {
            removeUploadingFile: 'removeUploadingFile',
        }),
        browseFiles () {
            const browseInput = document.getElementById('io-file-uploader-input-file' + this.arrayId)
            browseInput.click()
        },
        onDragEnter (event) {
            event.preventDefault()
            this.isDraggingOver = true
        },
        onDragLeave (event) {
            event.preventDefault()
            this.isDraggingOver = false
        },
        onDrop (event) {
            event.preventDefault()
            this.isDraggingOver = false
            const fileList = event.dataTransfer.files
            if (fileList.length > 0) {
                if (!this.multipleFiles && fileList.length > 1) {
                    this.showNotification(NotificationType.ERROR, this.$t('Transfer of multiple files is not allowed'))
                    return
                }
                for (let i = 0; i < fileList.length; i++) {
                    const file = fileList[i]
                    if (file) {
                        this.prepareFileToUpload(file)
                    }
                }
                if (!this.uploading) {
                    this.uploadAllFiles()
                }
            }
        },
        onFileChange (event: Event): void {
            if (this.isUploadingIntoSharedFolder) {
                this.validateUploadingIntoSharedFolder(event)

                return
            }

            this.onValidFileChange(event)
        },
        onValidFileChange (event: Event): void {
            const fileList = event.target.files

            if (fileList.length > 0) {
                for (let i = 0; i < fileList.length; i++) {
                    const file = fileList[i]
                    const fileExtension = file.name.split('.').pop().toLowerCase()

                    if (file) {
                        if (this.validationOptions.allowedExtensions.length > 0 && !this.validationOptions.allowedExtensions.includes(fileExtension)) {
                            const allowedExtensionsText = this.validationOptions.allowedExtensions.join(', ').toUpperCase()
                            this.showPopupAlert({
                                title: this.$t('File extension not allowed.'),
                                caption: `${ this.$t('Accepted extensions: ') } ${ allowedExtensionsText }`,
                                icon: 'icon-cross-circle',
                                buttons: [
                                    {
                                        text: this.$t('Close'),
                                        class: 'io-btn-light',
                                        action: null,
                                    },
                                ],
                            })

                            continue
                        }
                        this.prepareFileToUpload(file)
                    }
                }
                if (!this.uploading) {
                    this.uploadAllFiles()
                }
            }
        },

        validateUploadingIntoSharedFolder (event: Event): void {
            this.showPopupAlert({
                title: this.$t('Add file to a shared folder?'),
                caption: this.$t('This will share the file with the same individuals who have access to the folder.'),
                icon: 'fa fa-upload',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: () => {
                            return false
                        }
                    },
                    {
                        text: this.$t('Yes, Add and Share'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.onValidFileChange(event)
                        }
                    }
                ]
            })
        },
        async uploadAllFiles (index = 0) {
            let file = this.filesToUpload[index]

            if (!file) {
                return
            }

            this.uploading = true

            if (this.showNotifications && index === 0) {
                this.showNotification(NotificationType.WARNING, this.$t('We are processing your files. Please wait...'))
            }

            const formData = new FormData()

            formData.append('file', file.file)
            formData.append('fileName', file.name || file.fileName)

            formData.append('section', this.filesSection)
            formData.append('section_uuid', this.subSection)
            formData.append('can_be_shared', this.forceShared || this.defaultShare ? 1 : 0)
            formData.append('model_type', this.modelType)
            formData.append('in_documents_upload', this.inDocumentsUpload || this.isProjectView)
            formData.append('folder_id', this.folderFlags && this.folderFlags._id ? this.folderFlags._id : null)

            for (const [key, value] of Object.entries(this.additionalRequestData)) {
                formData.append(key, value)
            }

            if (this.threadId) {
                formData.append('thread_id', this.threadId)
            }

            if (file.status === 'canceled' || file.status === 'completed') {
                const nextIndex = index + 1
                if (nextIndex < this.filesToUpload.length) {
                    await this.uploadAllFiles(nextIndex)
                }
                return
            }

            try {
                const response = await this.$api.post(this.getEndpoint(), formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: progressEvent => {
                        let progress = (progressEvent.loaded / progressEvent.total) * 100
                        let status = 'uploading'

                        this.changeFileStatus(file, progress - 5, status)
                    }
                })
                response.data.name = file.name
                this.$emit('updateDocuments', response.data)
                this.changeFileStatus(file, 100, 'completed')

                if (this.showNotifications) {
                    this.showNotification(NotificationType.SUCCESS, this.$t('Your file has been uploaded successfully.'))
                }
                this.loader = false
            } catch (error) {
                this.changeFileStatus(file, 0, 'error')
                if (this.showNotifications) {
                    this.showNotification(NotificationType.ERROR, 'Error occurred during loading data.')
                }
            } finally {
                const nextIndex = index + 1
                if (nextIndex < this.filesToUpload.length) {
                    await this.uploadAllFiles(nextIndex)
                }
            }

            this.uploading = false
            this.clearFilesToUpload()
        },
        prepareFileToUpload (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase()

            if (file) {
                if (this.validationOptions.allowedExtensions.length > 0 && !this.validationOptions.allowedExtensions.includes(fileExtension)) {
                    const allowedExtensionsText = this.validationOptions.allowedExtensions.join(', ').toUpperCase()
                    this.showPopupAlert({
                        title: this.$t('File extension not allowed.'),
                        caption: `${ this.$t('Accepted extensions: ') } ${ allowedExtensionsText }`,
                        icon: 'icon-cross-circle',
                        buttons: [
                            {
                                text: this.$t('Close'),
                                class: 'io-btn-light',
                                action: null,
                            },
                        ],
                    })

                    return
                }
            }

            this.$store.dispatch('filesComponent/addUploadingFile', {
                file: file,
                fileName: file.name,
                progress: 0,
                status: 'uploading',
                tempId: this.generateUuid(),
                arrayId: this.arrayId,
            })
        },
        changeFileStatus (file, progress, status) {
            this.$store.dispatch('filesComponent/changeUploadingFileStatus', {
                status: status,
                tempId: file.tempId,
                arrayId: this.arrayId,
                progress: progress,
            })
        },
        clearFilesToUpload () {
            this.$store.dispatch('filesComponent/clearUploadingFiles', { arrayId: this.arrayId })

            const uploaderInputId = `io-file-uploader-input-file${ this.arrayId }`
            const uploader = document.getElementById(uploaderInputId)

            if (uploader) {
                document.getElementById(uploaderInputId).value = ''
            }
        },
        getEndpoint () {
            if (this.addToTemporary) {
                return '/attach-temporary'
            } else if (!isEmpty(this.folderFlags) && this.folderFlags.is_user_created) {
                return `/upload-folder-document/${ this.folderFlags._id }`
            } else if (this.modelType && this.modelId) {
                return `/upload-model-document/${ this.modelType }/${ this.modelId }`
            } else if (this.modelType && this.folderFlags.model_id) {
                return `/upload-model-document/${ this.modelType }/${ this.folderFlags.model_id }`
            } else if (!isEmpty(this.folderFlags) && this.folderFlags._id) {
                return `/upload-folder-document/${ this.folderFlags._id }`
            } else {
                return false
            }
        },
        onUploadFromLibrary () {
            this.showSelectFilesPopup()
        },
        closeSelectFilesPopup () {
            this.$store.dispatch('filesComponent/setFolderFlags', {})
            this.selectFilesPopupVisible = false
        },
        showSelectFilesPopup () {
            this.selectFilesPopupVisible = true
        },
        setModelId () {
            const id = this.modelId ? this.modelId : this.$route.params.id
            this.clonedModelId = (!this.addToTemporary && !this.modelId) ? id : this.modelId
        },
        updateDocuments (value) {
            this.$emit('updateDocuments', value)
        },
    },
    watch: {
        'folderFlags' () {
            // dont realod uploader when selecting from IO library
            if (this.selectFilesPopupVisible) {
                return
            }
            this.$emit('reloadUploader')
        },
        'multipleFiles' (value) {
            this.$store.dispatch('filesComponent/setSelectMultipleFiles', value)
        },
        'modelId' () {
            this.setModelId()
        },
    }
})
