<template>
    <div class="invite-list-container io-invite-list-container">
        <!-- AVATARS VIEW -->
        <template v-if="layout === 'avatars'">
            <template v-if="duringLoadingData">
                <div class="io-users">
                    <div class="io-loader-small io-people-loader"></div>
                </div>
            </template>
            <template v-else-if="!countAllPeople && displayAsignButton">
                <div class="io-users io-not-assigned io-f-row"
                     :class="{'io-disabled': !allowInvite}"
                     @click.stop="showInviteModal">
                    <img src="/new-theme/img/lm-crm-grey-light.svg" alt=""/>
                    <p>{{ $t('Assign') }}</p>
                </div>
            </template>
            <template v-else>
                <div class="io-users io-f-row">
                    <template v-for="(item, index) in listLimited" :key="item._id">
                        <div v-tooltip.top="{ content: avatarRingTooltip, visible: avatarIsVisible(item._id) }"

                             :class="{ 'io-marked-with-ring': getIsMarkedWithRing(item._id) }"
                        >
                            <template v-if="item.isPending">
                                <div
                                    v-tooltip.bottom="{content: item.email, visible: item.hasOwnProperty('email')}"
                                    class="io-pending-avatar"
                                    :class="{'io-inactive': isArchivedEmployee(item)}"
                                    :title="item.email"
                                >
                                </div>
                            </template>

                            <div v-else-if="item.mongoId && item.photo"
                                 v-tooltip.bottom="item.tooltip || (`${item.full_name || fullName(item)} ${isArchivedEmployee(item) ? '(archived)' : ''}`)"
                                 class="io-img-holder"
                                 :class="{'io-inactive': isArchivedEmployee(item), 'io-is-ball-in-court':item.isBallInCourt}"
                                 :style="getBackgroundAvatar(item.photo)"
                            ></div>
                            <div v-else-if="item._id && item.avatar"
                                 v-tooltip.bottom="item.tooltip || (`${item.full_name || fullName(item)} ${isArchivedEmployee(item) ? '(archived)' : ''}`)"
                                 class="io-img-holder"
                                 :class="{'io-inactive': isArchivedEmployee(item), 'io-is-ball-in-court':item.isBallInCourt}"
                                 :style="getBackgroundAvatar(item._id)"
                            ></div>
                            <initials v-else
                                      v-tooltip.bottom="item.tooltip || (`${item.full_name || fullName(item)} ${isArchivedEmployee(item) ? '(archived)' : ''}`)"
                                      class="io-img-holder io-f-column"
                                      :class="{'io-inactive': isArchivedEmployee(item), 'io-is-ball-in-court':item.isBallInCourt}"
                                      :fullName="item.full_name || fullName(item)"
                                      :bgColor="personColor(item)"
                            />
                        </div>
                    </template>
                    <span v-if="countAllPeople > displayLimit" class="io-more-users">
                        +{{ remainingEmployees }}
                    </span>
                    <div v-if="isInviteButtonVisible && allowInvite"
                         class="io-todos-add-user io-f-column io-cursor-pointer" :class="{'io-add-no-space': countAllPeople > displayLimit}">
                        <i class="fas fa-plus io-accent" @click.stop="showInviteModal"></i>
                    </div>
                </div>
            </template>
        </template>

        <template v-else-if="layout === 'employees-list'">
            <div class="io-task-employees">
                <div v-for="company in cloned_companies_employees" class="io-single-company">
                    <div class="io-single-company__top-row">
                        <div class="io-single-company__photo io-company-logo-placeholder" :style="getBackgroundCompanyLogo(company._id)"></div>
                        <div class="io-single-company__name">{{ company.company.name }}</div>
                        <div class="io-status-pill" :class="getPillColor(company.company.app_type)">{{ getPillName(company.company.app_type) }}</div>
                    </div>
                    <div class="io-single-company__employees">
                        <div v-for="employee in company.employees" class="io-single-employee">
                            <div class="io-single-employee__avatar" :class="{ 'io-is-ball-in-court': usersBallInCourt.includes(employee._id) }" @click="$emit('setBallInCourt', employee._id)">
                                <div v-if="employee.avatar" class="io-single-employee__photo" :style="getBackgroundAvatar(employee._id)"></div>
                                <initials v-else-if="employee.firstname && employee.lastname" class="io-single-employee__initials" :fullName="`${employee.firstname} ${employee.lastname}`"/>
                                <initials v-else class="io-single-employee__initials" :fullName="employee.email"/>
                                <div v-if="isUserOnline(employee._id)" v-tooltip.top="`${employee.firstname ? employee.firstname : employee.email} is online`" class="io-single-employee__online-dot"></div>
                            </div>
                            <div class="io-single-employee__content">
                                <div v-if="employee.firstname && employee.lastname" class="io-single-employee__name">{{ employee.firstname }} {{ employee.lastname }}</div>
                                <div v-else class="io-single-employee__name">{{ employee.email }}</div>
                                <div v-if="employee.job_title" class="io-single-employee__role">{{ employee.job_title }}</div>
                            </div>
                            <div class="io-single-employee__remove" @click="showInviteModal"><i class="far fa-times"></i></div>
                        </div>
                    </div>
                </div>
                <div v-if="isEmpty(cloned_companies_employees)" class="io-task-employees__placeholder">{{ $t('No members added yet') }}</div>
            </div>

        </template>

        <!-- LIST LAYOUT -->
        <div v-else-if="layout === 'list'" :class="['io-invite-list', {'io-invite-list-generator': isGenerator}]">
            <div v-if="originator" class="io-f-row io-f-row-center">
                <img src="/new-theme/img/lm-employees-grey.svg" class="io-invite-list-icon"/>
                <p class="io-label">{{ $t('Originator') }}</p>
                <div class="io-data io-f-column">
                    <div class="io-f-row">
                        <div v-if="originator.mongoId && originator.photo" class="io-img-holder io-initials" :style="getBackgroundAvatar(originator.mongoId)"></div>
                        <div v-else-if="originator.isPending" class="io-pending-avatar" :title="originator.email"></div>
                        <initials v-else class="io-img-holder io-f-column" :fullName="fullName(originator)" :bgColor="personColor(originator)"/>
                        <p>{{ originator.fullname }}</p>
                    </div>
                </div>
            </div>

            <div class="io-f-row io-f-row-start">
                <img src="/new-theme/img/lm-employees-grey.svg" :class="['io-invite-list-icon', originator ? 'io-invite-list-icon-hide' : '']"/>
                <p :class="['io-label', {'io-label-center': originator}]">{{ inviteModalOptions && inviteModalOptions.layoutListMainLabel ? inviteModalOptions.layoutListMainLabel : $t('People') }}</p>
                <div class="io-data io-f-column">

                    <!-- ADDED -->
                    <template v-if="countAllPeople && (attachedParsed && attachedParsed.length > 0)">
                        <p class="io-attached-label">{{ $t('Added') }}</p>
                        <div v-for="(item, index) in attachedParsedLimited"
                             :key="index" class="io-f-row">
                            <template v-if="!originator || (originator && item.mongoId !== creatorMongoId)">
                                <div v-if="item.mongoId && item.photo" class="io-img-holder io-initials" :class="{'io-inactive': isArchivedEmployee(item)}"
                                     :style="getBackgroundAvatar(item.mongoId)"></div>
                                <div v-else-if="item.isPending" class="io-pending-avatar" :title="item.email"></div>
                                <initials v-else class="io-img-holder io-f-column" :class="{'io-inactive': isArchivedEmployee(item)}" :fullName="fullName(item)"
                                          :bgColor="personColor(item)"/>
                                <p>{{ item.fullname }} {{ isArchivedEmployee(item) ? '(archived)' : '' }}</p>
                            </template>
                        </div>
                        <p v-if="expandable && attachedParsed.length > expandableLimit && !isExpandable" class="io-show" @click="isExpandable = true">+ Show All</p>
                        <p v-if="expandable && attachedParsed.length > expandableLimit && isExpandable" class="io-show" @click="isExpandable = false">- Show Less</p>
                    </template>

                    <!-- INVITED -->
                    <template v-if="cloned_invited_people && cloned_invited_people.length > 0">
                        <p class="io-attached-label">{{ $t('Connected') }}</p>
                        <div v-for="(item, index) in cloned_invited_people" class="io-f-row">
                            <template v-if="!item.isOriginator">
                                <div :key="index" class="io-f-row">
                                    <div v-if="item._id && (item.avatar || item.photo)" class="io-img-holder io-initials" :class="{'io-inactive': isArchivedEmployee(item)}"
                                         :style="getBackgroundAvatar(item._id)"
                                    ></div>
                                    <initials
                                        v-else
                                        class="io-img-holder io-f-column"
                                        :class="{'io-inactive': isArchivedEmployee(item)}"
                                        :fullName="fullName(item)"
                                    />
                                    <p>{{ item.firstname }} {{ item.lastname }} {{ isArchivedEmployee(item) ? '(archived)' : '' }}</p>
                                </div>
                            </template>
                        </div>
                    </template>

                    <!-- PENDING -->
                    <template v-if="cloned_invited_pending && cloned_invited_pending.length > 0">
                        <p class="io-attached-label">{{ $t('status_pending') }}</p>
                        <div v-for="(item, index) in cloned_invited_pending" :key="item._id" class="io-f-row io-usr-inv">
                            <div class="io-f-row">
                                <div class="io-pending-avatar"></div>
                                <p>{{ item.email }}</p>
                            </div>
                        </div>
                    </template>

                    <IOButton
                        v-if="isInviteButtonVisible"
                        variant="link"
                        size="small"
                        icon="far fa-user-plus io-fa-user-plus"
                        :data-id="id"
                        @click.stop="showInviteModal">
                        {{ buttonText }}
                    </IOButton>

                    <slot v-if="hasEmptyListSlot &&
                        ((attachedParsed && attachedParsed.length === 0) || !attachedParsed) &&
                        ((cloned_invited_pending && cloned_invited_pending.length === 0) || !cloned_invited_pending )&&
                        ((cloned_invited_people && cloned_invited_people.length === 0) || !cloned_invited_people) &&
                        !allowInvite" name="emptyListSlot"></slot>
                </div>
            </div>
        </div>

        <template v-else-if="layout === 'avatars-short'">
            <div class="io-data" style="width: 100%;">
                <div v-if="isInviteButtonVisible && allowInvite" class="io-f-row">
                    <a class="io-btn-secondary-compact" @click.stop="showInviteModal">Add or invite users</a>
                </div>
                <div v-if="countAllPeople" class="io-f-row io-users-row">
                    <template v-if="attachedParsed && attachedParsed.length">
                        <div v-for="(item, index) in attachedParsed" :key="index" class="io-user-row">
                            <div v-if="item.e_mongo && item.e_photo" class="io-img-holder io-initials"
                                 :style="getBackgroundAvatar(item.e_mongo)">
                            </div>
                            <template v-else-if="item.isPending" class="io-user-row"></template>
                            <initials v-else class="io-img-holder io-f-column" :fullName="fullName(item)"
                                      :bgColor="personColor(item)"/>
                            <div class="io-icon-holder io-remove-usr" @click="detachPerson(item)"></div>
                        </div>
                    </template>

                    <!-- PENDING -->
                    <template v-if="cloned_invited_pending && cloned_invited_pending.length > 0">
                        <div v-for="(item, index) in cloned_invited_pending" :key="item._id" class="io-user-row">
                            <div class="io-pending-avatar" :title="item.email"></div>
                            <div class="io-icon-holder io-remove-usr" @click="detachPerson(item)"></div>
                        </div>
                    </template>
                </div>
            </div>
        </template>

        <!-- ONE USER LAYOUT WITH BOX -->
        <template v-else-if="layout === 'button-one'">
            <IOButton v-if="!countAllPeople" variant="link" icon="fas fa-plus" @click="showInviteModal">
                {{ buttonText }}
            </IOButton>

            <div v-else class="io-form-field-holder io-f-column">
                <div class="io-f-row io-usr-inv">
                    <div class="io-f-row io-align-center">
                        <div class="io-invited-name">{{ firstUsernameEmail }}</div>
                        <div v-if="showInviteUserStatus" class="io-status-pill io-green">{{ $t('status_pending') }}</div>
                        <div class="io-remove-invitation" @click="removeAllInvitations()">
                            <i class="fas fa-times-circle"></i>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- LAYOUT WITH BUTTON ONLY -->
        <template v-else-if="layout === 'button-only'">
            <IOButton v-if="isInviteButtonVisible" icon="far fa-user-plus" @click.stop="showInviteModal">
                {{ buttonText }}
            </IOButton>
        </template>

        <!-- LAYOUT FOR CONTRACTS-->
        <template v-else-if="layout === 'contract-manager'">
            <div v-if="!countAllPeople" class="io-btn-mid io-accent" @click.stop="showInviteModal">
                <i :class="inviteButtonIcon"></i>{{ $t('ADD OR INVITE') }}
            </div>
            <div v-else class="io-form-field-holder io-f-column">
                <div class="io-f-row io-usr-inv">
                    <div class="io-f-row io-align-center">
                        <div class="io-invited-name">{{ firstUsernameEmail }}</div>
                        <div v-if="showInviteUserStatus" class="io-status-pill io-green">{{ $t('status_pending') }}</div>
                        <div class="io-remove-invitation" @click="removeAllInvitations()">
                            <i class="fas fa-times-circle"></i>
                        </div>
                    </div>
                </div>
            </div>
        </template>


        <!-- LAYOUT FOR COMMUNICATION-->
        <template v-else-if="layout === 'communication-icon'">
            <div class="io-add-user" @click.stop="showInviteModal"><i class="fas fa-user-plus"></i></div>
        </template>

        <template v-else-if="layout === 'slot'">
            <slot v-if="isInviteButtonVisible" @click.stop="showInviteModal"></slot>
        </template>

        <template v-else-if="layout === 'task-creation'">
            <div v-if="showMembers" class="io-task-employees">
                <div v-for="company in groupedEmployees" class="io-single-company">
                    <div class="io-single-company__top-row">
                        <div class="io-single-company__photo io-company-logo-placeholder" :style="getBackgroundCompanyLogo(company.id)"></div>
                        <div class="io-single-company__name">{{ company.name }}</div>
                        <div class="io-status-pill" :class="getPillColor(company.app_type)">
                            {{ getPillName(company.app_type) }}
                        </div>
                    </div>
                    <div class="io-single-company__employees">
                        <div v-for="employee in company.employees" class="io-single-employee">
                            <div
                                class="io-single-employee__avatar"
                                :class="{ 'io-is-ball-in-court': usersBallInCourt.includes(employee.e_mongo) }"
                                @click="$emit('setBallInCourt', employee.e_mongo)"
                            >
                                <div v-if="employee.avatar" class="io-single-employee__photo"
                                     :style="getBackgroundAvatar(employee.id)"></div>
                                <initials v-else-if="employee.full_name" class="io-single-employee__initials"
                                          :fullName="`${employee.full_name}`"/>
                                <initials v-else class="io-single-employee__initials" :fullName="employee.email"/>
                            </div>
                            <div class="io-single-employee__content">
                                <div v-if="employee.full_name" class="io-single-employee__name">{{
                                    employee.full_name
                                }}
                                </div>
                                <div v-else class="io-single-employee__name">{{ employee.email }}</div>
                                <div v-if="employee.title" class="io-single-employee__role">{{ employee.title }}</div>
                            </div>
                            <div v-if="allowInvite" class="io-single-employee__remove" @click="showInviteModal"><i
                                class="far fa-times"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!showMembers" class="io-task-employees__placeholder">{{ $t('No members added yet') }}</div>
        </template>

        <!-- INVITATION BOX -->
        <InviteModalBanner
            v-if="inviteModal"
            @close="onCloseModal"
        />

        <div v-else-if="allowInvite && inviteModal" class="io-attach-modal">
            <invite-modal
                ref="inviteModal"
                :key="inviteModalKey"
                :itemId="id"
                :attachedParsed="attachedParsed"
                :invited_people="cloned_invited_people"
                :invited_pending="cloned_invited_pending"
                :inviteModalOptions="inviteModalOptions"
                :hideCreator="hideCreator"
                :hideEntriesWithEmail="hideEntriesWithEmail"
                :apiClient="apiClient"
                :invitedMongoIds="invited"
                :userId="parseInt(getAuthData.u_id || -1)"
                :creatorMongoId="creatorMongoId"
                :notDeletedEmployeesIds="notDeletedEmployeesIds"
                :section="section"
                :getAttached="getAttached"
                :hideSaveButton="hideSaveButton"
                :customAction="customAction"
                :type="type"
                :allowToInviteSelf="allowToInviteSelf"
                @close="onCloseModal"
                @invitationsUpdate="invitationsUpdate"
                @addContact="$emit(customAction.actionName)"
                @updateAdded="updateAdded"
            />
        </div>
    </div>
</template>

<script src="./invite-list/invite-list.js"></script>
<style lang="scss" src="./invite-list/invite-list.scss" scoped></style>
