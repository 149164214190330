<template>
    <div class="io-dashboard-card io-tasks">

        <div class="io-tasks-list">
            <div class="io-tasks-list__header">
                <div class="io-dashboard-card__title">{{ $t('Open Tasks') }}</div>
                <router-link v-slot="{ navigate }" :to="{name: 'todo-list'}" custom>
                    <IOButton variant="link" @click="navigate" @keypress.enter="navigate">{{ $t('See All') }}</IOButton>
                </router-link>
            </div>
            <div class="io-tasks-list__content">
                <template v-if="tasks && tasks.length > 0">
                    <router-link v-for="(taskItem, taskIndex) in tasks" v-slot="{ navigate }" :key="taskIndex" :to="{name: 'todo-view', params: {id: taskItem.id}}" custom><div :class="['io-single-item' , getColor(taskItem)]" @click="navigate" @keypress.enter="navigate">
                        <div class="io-single-item__name">{{ $filters.filterEmptyValue(taskItem.name) }}</div>
                        <div class="io-single-item__data">
                            <span v-if="taskItem.due_date" :class="getDueDateClass(taskItem)">Due {{ $filters.formatDateOnly(taskItem.due_date) }}</span>
                            <i v-if="taskItem.due_date && taskItem.type" class="fas fa-circle"></i>
                            <template v-if="taskItem.type">{{ taskItem.type }}</template>
                        </div>
                    </div></router-link>
                </template>
                <div v-else class="io-single-item--empty">
                    {{ loading ? $t('Loading...') : $t('No items') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import moment from 'moment'
    import { get, debounce, isObject } from 'lodash'

    export default {
        name: 'tasks',
        props: {
            data: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        data () {
            return {
                selectedDate: null,
                tasksByDate: null,
                request: null
            }
        },
        computed: {
            ...mapState('loadingBar', {
                loading: state => state.loading
            }),
            tasks () {
                return (this.selectedDate && this.tasksByDate) ? this.tasksByDate : this.data.list
            },
            today () {
                return moment(new Date()).format('MM/DD/Y')
            }
        },
        methods: {
            getTasksByDate: debounce(function () {
                this.$store.dispatch('loadingBar/setLoading', true, { root: true })
                this.request = this.$api.post('tasks-lazy-list/0', {
                    filters: {
                        my: true,
                        due_date_range: 'unfinished',
                        date: this.selectedDate
                    },
                    order: 'DESC',
                    orderBy: 'task_due_date'
                }).then(({ data }) => {
                    this.prepareTasks(get(data, 'items', []))
                    this.$store.dispatch('loadingBar/setLoading', false, { root: true })
                }).catch(() => {
                    this.$store.dispatch('loadingBar/setLoading', false, { root: true })
                })
            }, 500),

            prepareTasks (tasks) {
                this.tasksByDate = tasks.map(item => {
                    return {
                        due_date: item.task_due_date,
                        due_date_simple: null,
                        id: item._id,
                        name: item.task_name,
                        priority: item.task_priority,
                        priority_name: item._task_priority_name,
                        type: item.task_item_name
                    }
                })
            },

            dayFocusout () {
                this.selectedDate = null
            },

            dayClick (event) {
                this.selectedDate = (isObject(event) && event.id) ? event.id : null
            },

            getColor (task) {
                return `io-${ task.priority_name.toLowerCase() }`
            },

            propsDateFormatted (date) {
                return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/Y')
            },

            getDueDateClass (task) {
                const daysLeft = moment(this.propsDateFormatted(task.due_date)).diff(this.today, 'days')

                if (daysLeft < 0) {
                    return 'io-red'
                } else if (daysLeft < 3) {
                    return 'io-yellow,'
                }

                return 'io-green'
            }
        },
        watch: {
            'selectedDate' () {
                if (this.selectedDate) {
                    this.$store.dispatch('loadingBar/setLoading', true, { root: true })
                    this.getTasksByDate()
                }
            }
        }
    }
</script>

<style lang="scss" src="./tasks.scss" scoped></style>
