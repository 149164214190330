import PageHeader from '@/components/page-header/PageHeader.vue'
import TopBarActions from '../../components/app-package-view/top-bar-actions/TopBarActions.vue'
import Stepper from '@/components/stepper/Stepper.vue'
import Tabs from '@/components/atoms/tabs/Tabs.vue'
import StepperStep from '@/components/stepper/stepper-step/StepperStep.vue'
import { defineComponent, nextTick } from 'vue'
import { ItemRouteInterface } from '@/interfaces/components/lazy-list/ItemRoute'
import { mapActions, mapState } from 'pinia'
import { mapGetters as mapGettersVuex, mapState as mapStateVuex } from 'vuex'
import projectObjMixin from '@/modules/projects/mixins/projectObjMixin'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'
import type { Tab as TabInterface } from '@/components/atoms/tabs/TabInterface'
import { RouteLocation } from 'vue-router'
import AppPackageRouteName from '../../enums/AppPackageRouteName'
import InvoiceRouteName from '../../enums/InvoiceRouteName'
import { appPackageViewStore } from '../../stores/appPackageView'
import ApprovalsWrapper from '@/io-modules/approval-workflows/views/approvals-wrapper/ApprovalsWrapper.vue'
import ApprovalModalData from '@/io-modules/approval-workflows/interfaces/ApprovalModalData.ts'
import ApprovalModalFields from '@/io-modules/approval-workflows/interfaces/ApprovalModalFields.ts'
import appPackageClient from '@/io-modules/invoices/api-clients/appPackageClient.ts'
import ApprovalAction from '@/io-modules/approval-workflows/enums/ApprovalAction'
import ApprovalModalType from '@/io-modules/approval-workflows/enums/ApprovalModalType'

export default defineComponent({
    name: 'AppPackageViewTemplate',
    components: {
        ApprovalsWrapper,
        PageHeader,
        TopBarActions,
        Stepper,
        StepperStep,
        Tabs,
        LoadingScreen,
    },
    beforeRouteLeave (to: RouteLocation, _from: RouteLocation, next: Function): void {
        if (!to.name.startsWith('app-package-') && this.isEditingMode) {
            this.showPopupAlert({
                title: this.$t('You have unsaved changes. Would you like to save and leave or leave without saving?'),
                buttons: [
                    {
                        text: this.$t('Leave Without Saving'),
                        class: 'io-btn-light',
                        action: () => next(),
                    },
                    {
                        text: this.$t('Save and Leave'),
                        class: 'io-btn-primary',
                        action: async () => {
                            next()
                        },
                    },
                ],
            })
        } else {
            next()
        }
    },
    mixins: [projectObjMixin, breadcrumbsMixin],
    data () {
        return {
            currentStep: 0,
            loading: false,
            visibleSteps: [],
            approvalModalData: {} as ApprovalModalData,
        }
    },
    computed: {
        ...mapState(appPackageViewStore, [
            'creating',
            'editing',
            'isEditingMode',
            'appPackage',
            'isDetailsFilled',
            'areAttachedInvoices',
            'invoiceIDsSelectedToReject',
            'dataLoading',
        ]),

        ...mapGettersVuex('appStore', ['isOwnerRep', 'isOwner']),

        ...mapStateVuex('project', ['projectObj']),

        pageHeaderTitle (): string {
            if (this.creating) {
                return this.$t('Create Application Package')
            }

            if (this.editing) {
                return this.$t('Edit Application Package')
            }

            return this.appPackage.name || this.$t('Application Package')
        },

        backButtonLink (): object {
            return !this.isEditingMode ? { name: InvoiceRouteName.InvoicesApplicationPackagesList } : null
        },

        allSteps (): TabInterface[] {
            const steps = [
                {
                    title: this.$t('Details'),
                    valid: this.isDetailsFilled && AppPackageRouteName.AppPackageDetails !== this.$route.name,
                    route: { name: AppPackageRouteName.AppPackageDetails },
                    optional: false,
                    visible: true,
                    dot: !this.isDetailsFilled,
                },
                {
                    title: this.$t('Attach Invoices'),
                    valid: this.areAttachedInvoices && AppPackageRouteName.AppPackageAttachInvoices !== this.$route.name,
                    route: { name: AppPackageRouteName.AppPackageAttachInvoices },
                    optional: false,
                    visible: this.isEditingMode,
                    dot: !this.areAttachedInvoices,
                    disabled: !this.isDetailsFilled,
                },
                {
                    title: this.$t('Approvals'),
                    valid: true,
                    route: { name: AppPackageRouteName.AppPackageApprovals },
                    optional: false,
                    visible: !this.isEditingMode,
                },
                {
                    title: this.$t('Timeline'),
                    valid: true,
                    route: { name: AppPackageRouteName.AppPackageTimeline },
                    optional: false,
                    visible: !this.isEditingMode,
                },
            ]

            return steps
        },

        appPackageTabs (): TabInterface[] {
            return this.allSteps.filter(item => item.visible)
        },

        allStepsCompleted (): boolean {
            return !this.appPackageTabs.some(item => item.dot)
        },

        breadcrumbs (): Array<object> {
            if ('0' === this.$route.params.id || this.editing) {
                return []
            }

            return [
                ...this.$route.meta.breadcrumbs || [],
                {
                    text: this.projectObj._project_name || this.projectObj._name || this.$t('Project'),
                    to: { name: 'project-dashboard', params: { pid: this.$route.params.pid } },
                },
                {
                    text: this.isOwnerRep || this.isOwner ? this.$t('Invoicing and Application Packages') : this.$t('Invoicing'),
                    to: {
                        name: InvoiceRouteName.InvoicesApplicationPackagesList,
                        params: { pid: this.$route.params.pid },
                    },
                },
                {
                    text: `${ this.$t('App Package') } ${ this.appPackage.app_package_number || '' }`,
                    to: {
                        name: AppPackageRouteName.AppPackageDetails,
                        params: { pid: this.$route.params.pid, id: this.$route.params.id },
                    },
                },
            ]
        },

        isCreatingRoute (): boolean {
            return this.$route.params.id && '0' == this.$route.params.id
        },

        approvableResourceId (): string {
            return this.isOwnerRep ? this.appPackage.approvable_resource_id : this.appPackage.client_approvable_resource_id
        },
    },
    watch: {
        $route (to: ItemRouteInterface, from: ItemRouteInterface): void {
            if (this.creating) {
                this.visibleSteps[0].valid = this.isDetailsFilled && InvoiceRouteName.InvoiceDetails !== to.name
            } else {
                this.visibleSteps = this.allSteps.filter(item => item.visible)
                const step = this.visibleSteps.findIndex(item => item.route.name === to.name)
                if (this.currentStep !== step) {
                    this.currentStep = step
                }
            }
        },

        currentStep (): void {
            if (this.creating && this.visibleSteps[this.currentStep]?.route) {
                this.$router.push(this.visibleSteps[this.currentStep].route)
            } else if (this.appPackageTabs[this.currentStep]?.route) {
                this.$router.push(this.appPackageTabs[this.currentStep]?.route)
            }
        },

        breadcrumbs (): void {
            this.setBreadcrumbs(this.breadcrumbs)
        },
    },
    async created (): Promise<void> {
        await this.loadInitialData()
    },
    beforeMount () {
        if (this.isCreatingRoute) {
            this.setCreateModeValue(true)
        }
    },
    async mounted () {
        this.setBreadcrumbs(this.breadcrumbs)
    },
    beforeUnmount () {
        this.setBreadcrumbs([])
        this.clearStore()
    },
    methods: {
        ...mapActions(appPackageViewStore, [
            'setCreateModeValue',
            'clearStore',
            'fetchAppPackageAvailableActions',
            'fetchAppPackageDetails',
            'setDataLoading',
            'fetchAppPackageAttachedInvoices',
        ]),
        async loadInitialData (): Promise<void> {
            this.loading = true
            if (!this.projectObj.project_local_id) {
                await this.loadProject()
                await this.$store.dispatch('appStore/setProjectCurrency', { projectId: this.$route.params.pid })
            }

            if (!this.isCreatingRoute && AppPackageRouteName.AppPackageDetails !== this.$route.name) {
                await this.fetchAppPackageDetails(this.projectObj.project_local_id, this.$route.params.id)
                this.fetchAppPackageAttachedInvoices(this.projectObj.project_local_id, this.appPackage.id)
            }
            this.loading = false
            await nextTick()
            this.setupHeaderTabs()
        },

        setupHeaderTabs (): void {
            this.visibleSteps = this.allSteps.filter(item => item.visible)

            if (this.$route.name !== AppPackageRouteName.AppPackageDetails && !this.creating) {
                this.currentStep = this.visibleSteps.findIndex(item => item.route.name === this.$route.name)
            }
        },

        onClickTab (step: number): void {
            if (this.creating && !this.isDetailsFilled) {
                return
            }

            if (step > this.currentStep && this.visibleSteps[this.currentStep]?.optional) {
                this.visibleSteps[this.currentStep].valid = true
            }

            if (step < this.currentStep) {
                this.visibleSteps.forEach((item, index) => {
                    if (index >= step) {
                        item.valid = false
                    }
                })
            }

            this.currentStep = step
        },

        nextStep (): void {
            if (this.visibleSteps[this.currentStep].optional) {
                this.visibleSteps[this.currentStep].valid = true
            }

            this.currentStep = this.currentStep + 1
        },

        prevStep (): void {
            if (this.visibleSteps[this.currentStep].optional) {
                this.visibleSteps[this.currentStep].valid = false
            }

            if (this.visibleSteps[this.currentStep - 1].optional) {
                this.visibleSteps[this.currentStep - 1].valid = false
            }

            this.currentStep = this.currentStep - 1
        },

        async refreshData (): void {
            try {
                this.setDataLoading(true)
                await this.fetchAppPackageAvailableActions(this.projectObj.project_local_id, this.appPackage.id)
                await this.fetchAppPackageDetails(this.projectObj.project_local_id, this.appPackage.id)
                this.fetchAppPackageAttachedInvoices(this.projectObj.project_local_id, this.appPackage.id)
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.setDataLoading(false)
            }
        },

        async handleRejection (rejectionModalData: ApprovalModalFields): Promise<void> {
            try {
                this.setDataLoading(true)
                await appPackageClient.applicationPackageRejectInvoices(this.projectObj.project_local_id, this.$route.params.id,
                    {
                        document_ids: rejectionModalData.filesIds,
                        note: rejectionModalData.note,
                        note_visibility: rejectionModalData.noteVisibility,
                        rejected_invoices_ids: this.invoiceIDsSelectedToReject,
                    })

                this.refreshData()
                this.showNotification('success', this.$t('Application package internally rejected.'))
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.setDataLoading(false)
            }
        },

        callSubmitToClientModal (): void {
            this.$refs.appPackageActions.initSendToClient()
        },

        async refreshApprovableResourceData (): void {
            if (this.$refs.approvalsWrapper) {
                this.setDataLoading(true)
                await this.$refs.approvalsWrapper.refreshData()
                this.setDataLoading(false)
            }
        },

        initRecordClientApproval (): void {
            this.approvalModalData = { action: ApprovalAction.APPROVE, modal: ApprovalModalType.ClientApproval }
        },

        initMarkAsSubmittedToClient (): void {
            this.$refs.appPackageActions.initMarkAsSubmittedToClient()
        },

        initReject (): void {
            this.$refs.appPackageActions.toggleRejectionModal(true)
        },
    },
})
