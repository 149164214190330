import InvoiceRouteName from '../enums/InvoiceRouteName'
import invoicesListsClient from '../api-clients/invoicesListsClient'
import InvoicesTab from '../enums/InvoicesTab'
import listTypeName from '../helpers/listTypeName'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { nextTick } from 'vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import manageColumnsHelper from '@/components/manage-columns-modal/helpers/ManageColumnsHelper'

const tableColumnsMixin = {
    computed: {
        ...mapState(invoicesStore, ['getInvoicesListHeaders']),
        ...mapStateVuex('project', {
            projectObj: state => state.projectObj
        }),
        invoicesType (): InvoicesTab {
            return this.$route.meta.listType
        },
    },
    methods: {
        ...mapActions(invoicesStore, ['updateInvoicesColumns']),

        async updateInvoicesColumnsSettings (): Promise<void> {
            if (InvoicesTab.AppPackages === this.invoicesType) {
                return
            }
            
            await nextTick()
            if (!localStorage.getItem(`${ listTypeName(this.invoicesType) }-manage-columns`) 
                || 'undefined' === localStorage.getItem(`${ listTypeName(this.invoicesType) }-manage-columns`)
            ) {
                try {
                    const { data } = await invoicesListsClient.getManageColumnsSettings(this.projectObj.project_local_id, listTypeName(this.invoicesType))
                    localStorage.setItem(`${ listTypeName(this.invoicesType) }-manage-columns`, JSON.stringify(data))
                } catch (error) {
                    console.log(error)
                }
            }
            if (localStorage.getItem(`${ listTypeName(this.invoicesType) }-manage-columns`)) {
                this.updateInvoicesColumns(this.invoicesType, JSON.parse(localStorage.getItem(`${ listTypeName(this.invoicesType) }-manage-columns`)))
            }
            await nextTick()
            await this.setupInvoicesColumnsLeft()
        },

        async setupInvoicesColumnsLeft (): Promise<void> {
            const fixedColumns = this.getInvoicesListHeaders(this.invoicesType).filter((item: HeaderTable) => {
                if ([InvoiceRouteName.InvoicesReceivedVendorsList, InvoiceRouteName.InvoicesReceivedVandorDetails].includes(this.$route.name)) {
                    return 'vendor' !== item.key && item.fixed && item.visible
                }

                return item.fixed && item.visible
            })

            if (!fixedColumns.length) {
                return
            }

            const columnsSettings = {}
            manageColumnsHelper.setupTableColumnsLeft(fixedColumns)
            fixedColumns.forEach(item => {
                columnsSettings[item.key] = item
            })

            this.updateInvoicesColumns(this.invoicesType, columnsSettings)
            await nextTick()
        }
    }
}

export default tableColumnsMixin