<template>
    <div class="io-invite-modal__search-view">
        <p
            v-html="$t(`You're adding a new contact to the existing company: <strong>{companyDomain}</strong>`, { companyDomain })"
        ></p>
        <p
            v-html="$t(`Select a workspace within <strong>{companyDomain}</strong> for <strong>{searchValue}</strong> to be associated with:`, { companyDomain, searchValue })"
        ></p>

        <template v-for="(item, idx) in matchedDomainsList">
            <ExistingWorkspace
                :idx="idx"
                :item="item"
                :value="item.id"
                v-model="selectedWorkspace"
            />
        </template>

        <form v-if="allowToSave">
            <ActionsView
                :componentKey="componentKey"
                :isPartOfView="true"
            />
        </form>

        <p>
            {{ $t('If you couldn’t find the right workspace, you can reach out to the user to notify the workspace administrator by') }}
            <a href="mailto:admin@ingenious.build">{{ $t('email') }}</a>.
        </p>
    </div>
</template>
<script lang="ts" src="./WorkspaceFound.ts"></script>
