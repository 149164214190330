import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

const filesMixin = defineComponent({
    data () {
        return {
            clickCount: 0,
            clickDelay: 700,
            timer: null as null | number,
        }
    },
    computed: {
        isSelectedForAction () {
            return this.$store.getters['filesComponent/isSelectedForActions']({ id: this.file._id, arrayId: this.arrayId })
        },
        getSelectedFilesForActions () {
            return this.$store.getters['filesComponent/getSelectedFilesForActions'](this.arrayId)
        },
        getLastSelectedForAction () {
            return this.$store.getters['filesComponent/getLastSelectedForAction'](this.arrayId)
        },
        selectedFilesCount (): number {
            return this.getSelectedFilesForActions?.length
        },
        atLeastOneFileSelected (): boolean {
            return !!this.selectedFilesCount
        },
        selectedFilesText (): string {
            const text = this.selectedFilesCount > 1 ? this.$t('Items Selected') : this.$t('Item Selected')
            return `${ this.selectedFilesCount } ${ text }`
        },
    },
    methods: {
        ...mapActions('filesComponent', {
            selectFileForAction: 'selectFileForAction',
            removeSelectedFileForAction: 'removeSelectedFileForAction',
            removeAllSelectedFilesForAction: 'removeAllSelectedFilesForAction',
            setLastSelectedForAction: 'setLastSelectedForAction',
        }),
        async toggleSelectForAction (e: any, isListView = false) {
            if (e.ctrlKey || e.metaKey) {
                if (this.isSelectedForAction) {
                    await this.removeSelectedFileForAction({ file: this.file, arrayId: this.arrayId })
                    return
                }
                await this.setLastSelectedForAction({ index: this.idx, arrayId: this.arrayId })
                await this.selectFileForAction({ file: this.file, arrayId: this.arrayId })

                return
            }

            if (e.shiftKey) {
                if (this.getLastSelectedForAction === null) {
                    await this.setLastSelectedForAction({ index: this.idx, arrayId: this.arrayId })
                    await this.selectFileForAction({ file: this.file, arrayId: this.arrayId })
                    return
                }
                await this.removeAllSelectedFilesForAction(this.arrayId)
                this.$emit('selectFilesOnRange', this.idx)

                return
            }

            if (this.isSelectedForAction) {
                if (this.getSelectedFilesForActions && this.getSelectedFilesForActions.length > 1) {
                    await this.removeAllSelectedFilesForAction(this.arrayId)
                } else {
                    await this.removeSelectedFileForAction({ file: this.file, arrayId: this.arrayId })
                    await this.setLastSelectedForAction({ index: null, arrayId: this.arrayId })
                    return
                }
            } else {
                await this.removeAllSelectedFilesForAction(this.arrayId)
            }
            await this.setLastSelectedForAction({ index: this.idx, arrayId: this.arrayId })
            await this.selectFileForAction({ file: this.file, arrayId: this.arrayId })

            this.clickCount++
            if (this.clickCount === 1) {
                this.timer = setTimeout( () => {
                    this.clickCount = 0
                }, this.clickDelay)
            } else {
                clearTimeout(this.timer)
                this.clickCount = 0

                this.openFileInDocuments()
            }
        },
        openFileInDocuments () {
            if (!this.fromTrash && !this.addToTemporary) {
                let routeUrl = null
                if (this.isProjectView) {
                    routeUrl = this.$router.resolve({name: 'project-documents-show', params: {pid: this.$route.params.pid, id: this.file._id}})
                } else {
                    routeUrl = this.$router.resolve({name: 'documents-show', params: {id: this.file._id}})
                }

                window.open(routeUrl.href, this.filesInNewTab ? '_blank' : '')
            }
        },
        async toggleSelectForListView (e: any) {
            if (e.shiftKey) {
                if (!this.getLastSelectedForAction) {
                    await this.setLastSelectedForAction({ index: this.idx, arrayId: this.arrayId })
                    await this.selectFileForAction({ file: this.file, arrayId: this.arrayId })
                    return
                }

                this.$emit('selectFilesOnRange', this.idx)

                return
            }

            if (this.isSelectedForAction) {
                await this.removeSelectedFileForAction({ file: this.file, arrayId: this.arrayId })
                return
            }
            await this.setLastSelectedForAction({ index: this.idx, arrayId: this.arrayId })
            await this.selectFileForAction({ file: this.file, arrayId: this.arrayId })
        },
        async onItemToTrash (name: string, isBulkDelete: Boolean = false): Promise<void> {
            const question = this.$t('Are you sure you want to delete {name}?', { name })

            this.showPopupAlert({
                header: this.$t('Move To Trash'),
                caption: this.$t('<span class="text-black font-semibold">' + question + '</span>'),
                captionHTML: true,
                icon: 'icon-alert-triangle',
                width: 536,
                height: 240,
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Yes, Delete'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.moveToTrash(isBulkDelete)
                        },
                    },
                ],
            })
        },
        async moveToTrash (isBulkDelete: Boolean = false): void {
            let postData = []
            const files = this.getSelectedFilesForActions

            if (isBulkDelete) {
                files.forEach(file => postData.push(file._id))
            } else {
                postData.push(this.file._id)
            }
            try {
                await this.$api.delete('documents/remove', { params: { files: postData } })

                if (isBulkDelete) {
                    files.forEach(file => this.moveToTrashStorageActions(file))

                    await this.removeAllSelectedFilesForAction(this.arrayId)
                } else {
                    this.moveToTrashStorageActions(this.file)
                }

                this.$forceUpdate()
                this.$emit('refreshData')
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        async moveToTrashStorageActions (file: Object): void {
            if (file.is_temporary_upload) {
                this.$emit('tempFileRemove', file._id)
            }
            this.$emit('fileRemove', file._id)
            await this.$store.dispatch('filesComponent/addTrashedDoc', {
                arrayId: this.arrayId,
                file: file,
            })
            await this.$store.dispatch('filesComponent/removeMyDoc', {
                arrayId: this.arrayId,
                fileId: file._id,
            })
        }
    },
})

export default filesMixin
