export const updateChatType = ({commit}, val) => {
    commit('updateChatType', val)
}

export const updateChatThreadId = ({commit}, val) => {
    commit('updateChatThreadId', val)
}

export const updateChatThreadName = ({commit}, val) => {
    if (val.length === 0) {
        val = 'No task name'
    }
    commit('updateChatThreadName', val)
}

export const updateChatThreadType = ({commit}, val) => {
    commit('updateChatThreadType', val)
}
