<template>
    <div class="io-right-panel" :class="{ 'io-collapsed': panelCollapsed }">
        <div class="io-right-panel__header" :class="{ 'io-collapsed': panelCollapsed }">
            <i class="icon-align-bottom" @click="panelCollapsed = !panelCollapsed"/>
            <ul v-if="!panelCollapsed">
                <li
                    v-for="tab in rightPanelTabs"
                    :class="{ 'io-active': queryTab === tab.tabName }"
                    @click="setPanelContent(tab.tabName)"
                >
                    {{ tab.name }}
                </li>
            </ul>
        </div>

        <div v-if="!panelCollapsed" class="io-right-panel__content">
            <transition name="fade" mode="out-in">
                <Component
                    :is="rightPanelContent"
                    :isArchivedVersion="isArchivedVersion"
                    @annotation-added="annotationAdded"
                    @cancel-annotation="cancelAnnotation"
                    @annotation-deleted="annotationDeleted"
                    @change-panel-content="setPanelContent"
                    @delete-pdftron-markup="deletePdftronMarkup"
                />
            </transition>
        </div>
    </div>
</template>

<script lang="ts" src="./RightPanel.ts"></script>
<style lang="scss" src="./RightPanel.scss" scoped></style>
