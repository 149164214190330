export const setTopics = function ({commit, dispatch}, value) {
    commit('SET_TOPICS', value || {})
}

export const updateTopics = function ({commit, dispatch}, value) {
    commit('UPDATE_TOPICS', value || {})
}

export const searchTopics = function ({commit, dispatch}, value) {
    commit('SEARCH_TOPICS', value || {})
}

export const setActiveDiscussion = function ({commit, dispatch}, value) {
    commit('SET_ACTIVE_DISCUSSION', value || {})
}

export const addDiscussion = function ({commit, dispatch}, value) {
    commit('ADD_DISCUSSION', value || {})
}

export const setPosts = function ({commit, dispatch}, value) {
    commit('SET_POSTS', value || {})
}

export const updatePosts = function ({commit, dispatch}, value) {
    commit('UPDATE_POSTS', value || {})
}

export const searchPosts = function ({commit, dispatch}, value) {
    commit('SEARCH_POSTS', value || {})
}

export const addPost = function ({commit, dispatch}, value) {
    commit('ADD_POST', value || {})
}

export const addingPost = function ({commit, dispatch}, value) {
    commit('ADDING_POST', value || false)
}

export const setPostsLoading = function ({commit, dispatch}, value) {
    commit('SET_POSTS_LOADING', value || {})
}

export const updateEmployees = function ({commit, dispatch}, value) {
    commit('UPDATE_EMPLOYEES', value || {})
}

export const updateMute = function ({commit, dispatch}, value) {
    commit('UPDATE_MUTE', value || [])
}

export const setMessageFocus = function ({commit, dispatch}, value) {
    commit('SET_MESSAGE_FOCUS', value)
}

export const setTopicHover = function ({commit, dispatch}, value) {
    commit('SET_TOPIC_HOVER', value)
}

export const setModal = function ({commit, dispatch}, value) {
    commit('SET_MODAL', value || false)
}
export const resetState = function ({commit, dispatch}) {
    commit('RESET_STATE')
}

export const setProjectEmail = function ({commit, dispatch}, value) {
    commit('SET_PROJECT_EMAIL', value || false)
}