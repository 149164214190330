import { ApiClient as client, ApiClientV2 as clientV2 } from '@/api/api'
import { AxiosPromise } from 'axios'
import { NewCommentPayloadInterface } from '@/io-modules/documents/interfaces/NewCommentPayloadInterface'
import { ProjectDirectoryResponseInterface } from '@/io-modules/documents/interfaces/ProjectDirectoryResponseInterface'

const commentsClient = {
    addComment (documentId: string, postData: NewCommentPayloadInterface): AxiosPromise<void> {
        return client.post(`/document/${ documentId }/annotation/comment`, postData)
    },
    editComment (documentId: string, commentId: string, postData: { comment_text: string, shared_with: string[] }): AxiosPromise<void> {
        return client.patch(`/document/${ documentId }/annotation/${ commentId }/comment`, postData)
    },
    deleteComment (documentId: string, annotationId: string): AxiosPromise<void> {
        return client.delete(`/document/${ documentId }/annotation/${ annotationId }`)
    },
    deleteReply (documentId: string, replyId: string): AxiosPromise<void> {
        return client.delete(`/document/${ documentId }/annotation/${ replyId }/comment`)
    },
    resolveComment (documentId: string, annotationId: string, postData: { is_resolved: boolean }): AxiosPromise<void> {
        return client.patch(`/document/${ documentId }/annotation/${ annotationId }/resolve`, postData)
    },
    replyToComment (documentId: string, annotationId: string, postData: { comment_text: string, shared_with: string[] }): AxiosPromise<void> {
        return client.post(`/document/${ documentId }/annotation/${ annotationId }/comment/reply`, postData)
    },
    getMentionUsers (projectIdLocal: string|string[]): AxiosPromise<ProjectDirectoryResponseInterface> {
        return clientV2.get(`/project-directory-v3/${ projectIdLocal }`)
    }
}

export default commentsClient
