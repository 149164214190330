export enum InvoiceRouteName {
    InvoicesMainView = 'invoices-main',
    InvoicesMyList = 'invoices-my',
    InvoicesReceivedList = 'invoices-received',
    InvoicesReceivedDefaultList = 'received-invoices-default-list',
    InvoicesReceivedVendorsList = 'received-invoices-vendors-list',
    InvoicesReceivedVendorDetails = 'received-invoices-vendors-details',
    InvoicesSharedList = 'invoices-shared',
    InvoicesApplicationPackagesList = 'invoices-app-packages',
    InvoiceView = 'invoice-view',
    InvoiceDetails = 'invoice-details',
    InvoiceSoV = 'invoice-sov',
    InvoiceNonContractedLineItems = 'invoice-non-contracted-line-items',
    InvoiceAttachVendorInvoice = 'invoice-attach-vendor-invoice',
    InvoiceLienWaivers = 'invoice-lien-waivers',
    InvoiceDocuments = 'invoice-documents',
    InvoiceFunding = 'invoice-funding',
    InvoiceApprovals = 'invoice-approvals',
    InvoiceTimeline = 'invoice-timeline',
}

export default InvoiceRouteName