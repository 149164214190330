<template>
    <div
        class="io-design-system io-atoms-radio"
        :class="{
            'io-atoms-radio__checked': checked,
            'io-atoms-radio__invalid': !valid,
            disabled,
        }"
    >
        <label ref="label">
            <input
                :disabled="disabled"
                :name="inputName"
                type="radio"
                :value="radioValue"
                v-model="currentValue"
            />
            <span>{{ labelText }}</span>
        </label>
    </div>
</template>

<script lang="ts" src="./Radio.ts"></script>
<style lang="scss" src="./Radio.scss" scoped />
