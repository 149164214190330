<template>
    <div class="io-single-contact">
        <div
            class="io-single-contact__row"
            :class="{ 'io-removable': canBeRemoved }"
        >
            <div class="io-single-contact__cell io-single-contact__cell--main">
                <div
                    v-if="item.id && item.avatar"
                    class="io-avatar-holder__avatar"
                    :style="getBackgroundAvatar(item.avatar)"
                ></div>
                <Initials
                    v-else
                    :bigAvatar="true"
                    class="io-avatar-holder__initials"
                    :class="{ 'io-offsystem': isOffSystem }"
                    :fullName="`${ item.firstname } ${ item.lastname }`"
                />
                <div class="io-single-contact__details">
                    <div class="io-single-contact__text">
                        <span class="io-single-contact__text io-single-contact__text--dark">{{ item.firstname }} {{ item.lastname }}</span>
                        <span class="io-dot"></span>
                        {{ getCompanyName }}
                        <template v-if="item.is_archived">
                            <span class="io-dot"></span>
                            {{ '(archived)' }}
                        </template>
                    </div>
                    <div class="io-single-contact__text io-single-contact__text--small">
                        <StatusPill
                            v-if="isOnSystemCompany && !item.is_archived"
                            size="normal"
                            :variant="statusClass(companyAppType)"
                            :text="companyAppType"
                        />
                        <span v-else-if="isOnSystemCompany && item.is_archived" class="io-small-pill io-grey">
                            {{ getUpperCaseCompanyType }}
                        </span>
                        <span v-else class="io-small-pill io-grey">
                            {{ $t('Off-System') }}
                        </span>
                        {{ item.email }}
                    </div>
                </div>
            </div>
            <div
                v-if="showActions"
                :class="{'io-lock-select': !allowToSelectItems}"
                class="io-single-contact__cell io-single-contact__cell--align-end"
            >

                <template v-if="savedListType">
                    <template v-if="canBeRemoved">
                        <div
                            class="io-single-contact__action io-cursor-pointer io-single-contact__action--red"
                            @click="removeSelected()"
                        >
                            <i class="icon-cross"></i>
                            {{ $t('Remove') }}
                        </div>
                    </template>

                    <template v-if="item.invitation_status === invitationStatuses.READY_TO_SEND">
                        <p>{{ readyToText }}</p>
                    </template>
                    <template v-else-if="item.invitation_status === invitationStatuses.READY_TO_REMIND">
                        <p>{{ $t('Ready to Remind') }}</p>
                    </template>
                    <template v-else-if="item.invitation_status === invitationStatuses.READY_TO_SAVE">
                        <p>{{ $t('Ready to Bookmark') }}</p>
                    </template>
                    <template v-else-if="item.invitation_status === invitationStatuses.SAVED">
                        <p>{{ $t('Bookmarked') }}</p>
                        <div v-if="!personShowSaveOnlyAction"
                            class="io-single-contact__action io-cursor-pointer io-appColor"
                            @click="inviteSavedItem()"
                        >
                            <i class="icon-send-2"></i>
                            {{ $t('Invite') }}
                        </div>
                    </template>
                    <template v-else-if="item.invitation_status === invitationStatuses.INVITED">
                        <p>{{ $t('Pending Invite') }}</p>
                        <div v-if="allowToRemind" class="io-single-contact__action io-cursor-pointer io-appColor" @click="onRemindClick">
                            <i class="icon-send-2"></i>
                            {{ $t('Remind') }}
                        </div>
                    </template>
                    <template v-else-if="item.invitation_status === invitationStatuses.ACCEPTED">
                        <div class="io-single-contact__action io-single-contact__action--green">
                            <i class="icon-check-circle-3"></i>
                        </div>
                    </template>
                 </template>

                <template v-if="!savedListType">
                    <div v-if="allowToSave || personShowSaveOnlyAction"
                         class="io-single-contact__action io-cursor-pointer io-appColor"
                         @click="saveContact()"
                    >
                        <i class="icon-bookmark"></i>
                        {{ $t('Bookmark') }}
                    </div>
                    <IOButton
                        v-if="!personShowSaveOnlyAction"
                        class="io-single-contact__action io-cursor-pointer io-appColor"
                        variant="secondary"
                        :icon="{ 'icon-plus': !isOffSystem, 'icon-send-2': isOffSystem }"
                        @click="inviteContact()"
                    >
                        <template v-if="!isOffSystem">
                            {{ $t('Add') }}
                        </template>

                        <template v-else>
                            {{ $t('Invite') }}
                        </template>

                    </IOButton>
                </template>
            </div>
        </div>
    </div>
</template>
<script lang="ts" src="./SingleContact.ts"></script>
<style lang="scss" src="./SingleContact.scss" scoped></style>
