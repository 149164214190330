import { isEmpty } from 'lodash'
import { defineComponent } from 'vue'
import { InvitationStatuses } from '@/interfaces/components/invite-modal/InvitationStatuses'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'SingleContact',
    props: {
        item: { type: Object, default: () => ({}) },
        showActions: { type: Boolean, default: true, required: false },
        allowToSelectItems: { type: Boolean, default: true, required: false },
        allowToSave: { type: Boolean, default: true, required: false },
        hideRemoveInvitationButton: { type: Boolean, default: false },
        allowToSaveOnly: { type: Boolean, default: true, required: false },
        personShowSaveOnlyAction: { type: Boolean, required: false, default: false },
        savedListType: { type: Boolean, required: true },
        allowToRemind: { type: Boolean, required: false, default: true }
    },
    emits: [
        'setAddWorkspaceTypeStepSaveAction',
        'invitationRemind',
        'saveContact',
        'setAddWorkspaceTypeStepSendAction',
        'inviteContact',
        'removeSelected',
        'inviteSavedContact',
        'invitationRemind'
    ],
    data () {
        return {
            invitationStatuses: InvitationStatuses
        }
    },
    computed: {
        companyAppType (): string | null | undefined {
            if (this.item.isNewCompany) {
                return null
            }

            const type = this.item.company?.workspace_type

            if (type && type !== 'Off-System') {
                return type
            }

            if (type === 'Off-System') {
                return null
            }

            return this.item.app_type || this.item.appType
        },
        isOnSystemCompany (): boolean {
            return this.companyAppType && (this.item.id || this.item.company_id || this.item.company?.id || this.item.newCompany?.company_name) && !this.item._newAppTypeAdded
        },
        canBeRemoved (): boolean {
            const includesInvitationStatus = Object.values(this.invitationStatuses).includes(this.item.invitation_status)

            if (!this.hideRemoveInvitationButton) {
                return includesInvitationStatus
            }

            const readyToSend = this.item.invitation_status === this.invitationStatuses.READY_TO_SEND

            return includesInvitationStatus && readyToSend
        },
        getCompanyName (): string {
            return !isEmpty(this.item.company_name) ? this.item.company_name : this.item.newCompany?.company_name
        },
        getUpperCaseCompanyType (): string {
            return this.companyAppType ? this.companyAppType.toUpperCase() : ''
        },
        isOffSystem (): boolean {
            return !this.item.is_employee || (this.item.is_employee && (this.item.is_archived || this.item.is_pending))
        },
        readyToText (): TranslateResult {
            return this.isOffSystem ? this.$t('Ready to Send') : this.$t('Ready to Add')
        }
    },
    methods: {
        saveContact (): void {
            if (!this.allowToSelectItems) { return }

            if (!this.isOnSystemCompany && !this.item.is_app_type_set) {
                this.$emit('setAddWorkspaceTypeStepSaveAction', this.item)

                return
            }

            this.$emit('saveContact', this.item.id)
        },
        inviteContact (): void {
            if (!this.allowToSelectItems) { return }

            if (!this.isOnSystemCompany && !this.item.is_app_type_set) {
                this.$emit('setAddWorkspaceTypeStepSendAction', this.item)

                return
            }

            this.$emit('inviteContact', this.item.id)
        },
        removeSelected (): void {
            this.$emit('removeSelected', this.item)
        },
        statusClass (status: string): string {
            const styleClassByState = {
                'sub': 'accent-sc',
                'sc': 'accent-sc',
                'gc': 'accent-gc',
                'ac': 'accent-ac',
                'dev': 'accent-dev',
                'rep': 'accent-dev',
                'design': 'accent-ac',
                'owner': 'accent-dev',
                'investor': 'accent-investor',
                'vc': 'accent-light',
                'genius': 'accent-genius',
                'default': 'accent-light'
            }
            return styleClassByState[status] || styleClassByState['default']
        },
        inviteSavedItem (): void {
            this.$emit('inviteSavedContact', this.item.id)
        },
        onRemindClick (): void {
            this.$emit('invitationRemind', this.item.id)
        }
    }
})
