import { defineComponent } from 'vue'
// @ts-ignore
import modalMixin from '@/mixins/modalMixin'
import ModalFilters from '@/components/ModalFilters/ModalFilters.vue'
import Filters from './parts/filters/Filters.vue'
import { FilterInterface } from '@/interfaces/components/lazy-grid/FilterInterface'
import FetchingColumns from './parts/fetching-columns/FetchingColumns.vue'
import FilterSection from '@/components/lazy-list/parts/filter-section/FilterSection.vue'
import { FilterSectionInterface } from '@/interfaces/components/lazy-list/FilterSectionInterface'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'LazyGrid',
    components: {
        ModalFilters,
        Filters,
        FetchingColumns,
        FilterSection
    },
    props: {
        endpoint: { type: String, required: true },
        filtersSchema: { type: Array as () => FilterInterface[], required: true, default: () => [] },
        filterSections: { type: Array as () => FilterSectionInterface[], required: false, default: null }
    },
    mixins: [modalMixin],
    data () {
        return {
            prevScroll: 0 as number
        }
    },
    computed: {
        ...mapGetters('lazyList', [
            'getRecords',
            'isFetching',
            'isLastPage',
            'sortBy',
            'sortDirection',
            'filters',
            'showFilters',
            'filtersSelected'
        ]),
        records (): any[] {
            return this.getRecords
        },
        countFilters (): number {
            let val = 0

            Object.entries(this.filtersSelected).forEach((item: any) => {
                if (this.filters) {
                    const foundFilter = this.filters.find((filter: any) => {
                        return filter.name === item[0]
                    })

                    if (!foundFilter || !foundFilter.hide_in_sidebar) {
                        if (Array.isArray(item[1])) {
                            val += item[1].length
                            return
                        }

                        if ((typeof item[1] === 'number') || (typeof item[1] === 'string' && item[1].length)) {
                            val += 1
                            return
                        }

                        if (item[1] && item[1].end !== null) {
                            val += 1
                        }
                    }
                }
            })

            return val
        }

    },
    async beforeCreate (): Promise<void> {
        await this.$store.dispatch('lazyList/resetRecords')
    },
    created (): void {
        this.$nextTick(() => {
            const element = document.querySelector('.io-new-list-holder.sticky-wrapper')
            if (element) {
                element.addEventListener('scroll', this.handleScroll)
            }
        })
    },
    beforeMount (): void {
        this.$store.dispatch('lazyList/setConfig', { endpoint: this.endpoint })
    },
    mounted (): void {
        this.setLoadingBar(true)

        try {
            this.$store.dispatch('lazyList/getListData', true)
        } catch (e) {
            this.showNotification('error', e.response.data.message)
        } finally {
            this.setLoadingBar(false)
        }
    },
    beforeUnmount (): void {
        const element = document.querySelector('.io-new-list-holder.sticky-wrapper')
        if (element) {
            element.removeEventListener('scroll', this.handleScroll)
        }
        this.$store.dispatch('lazyList/setSearch', '')
    },
    methods: {
        applyFilters (): void {
            this.$store.dispatch('lazyList/setWithCounters', true)
            this.$store.dispatch('lazyList/getListData', true)
            this.onCloseFilters()
        },
        onCloseFilters (): void {
            this.$store.dispatch('lazyList/setShowFilters', false)
        },
        changeSort (sortBy: string): void {
            this.$store.dispatch('lazyList/changeSortBy', sortBy)
        },
        handleScroll (event: any): void {
            const content = document.querySelector<HTMLElement>('.io-new-list-holder.sticky-wrapper')
            const contentHeight = content ? content.offsetHeight : 0

            const currentScroll = event.target.scrollTop
            const scrollHeight = event.target.scrollHeight

            if (currentScroll > this.prevScroll) {
                this.prevScroll = currentScroll
            } else {
                this.prevScroll = currentScroll
                return
            }

            if (this.isLastPage) {
                return
            }

            // offset 10
            if (currentScroll >= scrollHeight - contentHeight - 10) {
                this.$store.dispatch('lazyList/getListData')
            }
        },
        async onClearAllFilters (): Promise<void> {
            this.$store.dispatch('lazyList/resetFiltersSelected')
        }
    }
})

