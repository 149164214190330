export function getExpandedLocations (state) {
    return state.expandedLocations
}

export function getExpandAll (state) {
    return state.expandAll
}

export function getShowArchived (state) {
    return state.showArchived
}

export function getArchivedLocations (state) {
    return state.archivedLocations
}

export function getAllLocations (state) {
    return state.allLocations
}

export function getSearchValue (state) {
    return state.searchValue
}

export function getIsFound (state) {
    return locationID => state.foundLocations.includes(locationID)
}

export function getSelectedLocations (state) {
    return state.selectedLocations
}

export function getSelectedTargetLocations (state) {
    return state.selectedTargetLocations
}

export function getShowImportPopup (state) {
    return state.showImportPopup
}
