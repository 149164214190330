import { defineComponent } from 'vue'
import { cloneDeep, isNil, debounce } from 'lodash'
import validationMixin from '@/mixins/validationMixin'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import { mapGetters, mapState } from 'vuex'
import CurrencyDropdown from '@/modules/lists-templates/views/currency-conversion/components/currency-dropdown/CurrencyDropdown.vue'
import FieldProposedId from '@/components/fields/fieldProposedId.vue'
import { TranslateResult } from 'vue-i18n'
import ContractType from '@/base/ContractType'
import { requiredIf, maxLength, required } from '@vuelidate/validators'
import InviteModal from '@/components/invite-modal-v3/InviteModalV3.vue'
import { InviteModalDoneEvent } from '@/components/invite-modal-v3/interfaces/InviteModalInterface'
import featureFlagsMixin from "@/mixins/feature-flags/featureFlagsMixin"
import featureFlagsConsts from '@/constants/FeatureFlagsConsts'
import IOBanner from '@/components/atoms/IOBanner/IOBanner'
import buildingAddressMixin from '@/io-modules/buildings/mixins/buildingAddressMixin'
import addressValidationMixin from '@/io-modules/buildings/mixins/addressValidationMixin'

export default defineComponent({
    name: 'StepProjectDetails',
    components: {
        IOBanner,
        InvitePreview,
        CurrencyDropdown,
        FieldProposedId,
        InviteModal,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        externalProject: {
            type: [Object, null],
            required: true,
        },
        proposedId: {
            type: Object,
            required: true,
        },
        client: {
            type: Object,
            required: true,
            default: () => ({})
        },
        isProposal: {
            type: Boolean,
            required: true,
        },
        stateDisabled: {
            type: Boolean,
            required: true,
        },
        isIdExist: {
            type: Boolean,
            required: true,
        },
        canEditAgreementType: {
            type: Boolean,
            required: true,
        },
        isGmpEnabled: {
            type: Boolean,
            required: true,
        },
        isOwner: {
            type: Boolean,
            required: true,
        },
        isDevAppType: {
            type: Boolean,
            required: true,
        },
        isPMApprover: {
            type: Boolean,
        }
    },
    mixins: [validationMixin, featureFlagsMixin, buildingAddressMixin, addressValidationMixin],
    data () {
        return {
            invitationClientKey: InviteModalClientKeys.PROJECT_WIZARD_CLIENT,
            invitationProjectManagerKey: InviteModalClientKeys.PROJECT_WIZARD_MANAGER,
            invitationProjectExecutiveKey: InviteModalClientKeys.PROJECT_WIZARD_EXECUTIVE,
            PreviewTypes: PreviewTypes,
            project: {},
            isClientInviteModalOpened: false,
            isProjectExecutiveInviteModalOpened: false,
            invitedClient: [],
            bookmarkedClient: [],
            invitedProjectManager: [],
            bookmarkedProjectManager: [],
            bookmarkedProjectExecutive: [],
        }
    },
    validations: {
        project: {
            name: {
                required
            },
            agreement_type: {
                required: requiredIf(function (): boolean {
                    return this.canEditAgreementType
                })
            },
            external_id: {
                maxlength: maxLength(140)
            },
            isIdExist: {
                maxlength: maxLength(140)
            },
            project_address2: {
                customMaxLength: function (value): boolean {
                    if (this.v$.project.project_address2.$isEmpty || !value) {
                        return true
                    }
                    return value.length <= 60
                }
            }
        },
    },
    computed: {
        ...mapGetters('common', {
            currencies: 'getCurrenciesList',
        }),
        ...mapGetters('lazyList', {
            isFetching: 'isFetching',
        }),
        ...mapGetters('appStore', [
            'isGeneralContractor',
            'getAuthData',
        ]),

        isNewInvitationFacadeFeatureFlagEnabled (): boolean {
            return this.isFeatureEnabled(featureFlagsConsts.NEW_INVITATION_FACADE, false)
        },

        mainContractTypeLabel (): TranslateResult {
            // @ts-ignore
            if (ContractType.GMP === this.project.main_contract_type) {
                return this.$tc('Initial Contract & Original Contract (GMP)')
            }

            return this.$tc('Lump Sum Contract')
        },
        isGCWorkspace (): boolean {
            return this.isGeneralContractor
        },

        bookmarkedProjectExecutivePersonIds (): Array<string> {
            return [this.getAuthData.contact_person_id]
        },
    },
    watch: {
        project: {
            handler (value: Object): void {
                this.$emit('update:modelValue', value)
            },
            immediate: true,
            deep: true
        },
    },
    beforeMount () {
        this.project = cloneDeep(this.modelValue)
    },
    methods: {
        onSelectedClientChange (value: Object): void {
            this.$emit('onSelectedClientChange', value)
            this.reInitializeGoogleSearchInput()
        },

        reInitializeGoogleSearchInput (): void {
            // @ts-ignore
            this.reInitGoogleAddressSearch(this.project, this.states, 'project_', true, this.countries, 6, 'project_address1', this.postFillAddressCallback)
        },

        onClientInviteModalDone (data: InviteModalDoneEvent): void {
            this.invitedClient = data.invited
            this.bookmarkedClient = data.bookmarked

            let contact = this.invitedClient[0] || this.bookmarkedClient[0]

            this.$emit('onSelectedClientChange', contact ?? null)
            this.reInitializeGoogleSearchInput()
        },

        onProjectExecutiveInviteModalDone (data: InviteModalDoneEvent): void {
            this.bookmarkedProjectExecutive = data.bookmarked

            this.$emit('onUpdateProjectExecutive', this.bookmarkedProjectExecutive ?? null)
        },

        onProjectManagerInviteModalDone (data: InviteModalDoneEvent): void {
            let contact = []

            this.invitedProjectManager = data.invited
            this.bookmarkedProjectManager = data.bookmarked

            contact = this.invitedProjectManager[0] || this.bookmarkedProjectManager[0]

            this.$emit('onChangeUpdateManager', contact ?? [])
            this.reInitializeGoogleSearchInput()
        },

        onUpdateAddressAction (): void {
            this.v$.project.project_address2.$touch()
            this.onUpdateProjectAddress2()
        },
    }
})
