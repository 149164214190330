import { defineComponent, PropType } from 'vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface.ts'
import Table from '@/components/table/Table.vue'
import statusColorMixin from '../../../../../mixins/StatusColorsMixin'
import { mapActions, mapState } from 'pinia'
import { appPackageViewStore } from '@/io-modules/invoices/stores/appPackageView.ts'
import appPackagesTableHeaders from '@/io-modules/invoices/helpers/appPackageTableHeaders.ts'
import { AppPackageTableType } from '@/io-modules/invoices/enums/AppPackageTableType.ts'
import { AttachableInvoice } from '@/io-modules/invoices/api-clients/appPackageClient.ts'

export default defineComponent({
    name: 'AppPackageInvoicesTable',
    components: { Table },
    props: {
        invoicesList: {
            type: Array as PropType<AttachableInvoice[]>
        }
    },
    mixins: [statusColorMixin],
    computed: {
        ...mapState(appPackageViewStore, ['attachedInvoicesIDs']),

        tableHeaders (): HeaderTable[] {
            return appPackagesTableHeaders(AppPackageTableType.InvoicesToAttach)
        },
    },
    methods: {
        ...mapActions(appPackageViewStore, ['setAttachedInvoicesIDs']),
        
        attachInvoice (id: string): void {
            this.setAttachedInvoicesIDs([...this.attachedInvoicesIDs, id])
        },
        isInvoiceAttached (id: string): boolean {
            return this.attachedInvoicesIDs.indexOf(id) > -1
        },
        detachInvoice (id: string): void {
            this.setAttachedInvoicesIDs(this.attachedInvoicesIDs.filter(inv => inv !== id))
        }
    }
})
