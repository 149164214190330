<template>
    <PopupModal :width="451" class="io-popup-edit-sheet" @close="close">
        <template #header>
            <div class="io-popup-modal__title">{{ $t('Edit Sheet') }}</div>
            <div class="io-popup-modal__close" @click="close"><i class="icon-cross" /></div>
        </template>

        <template #default>
            <div class="io-f-row">
                <div>
                    <label>{{ $t('Number') }}</label>
                    <input class="io-disabled" type="text" disabled v-model="updatedPageNumber"/>
                </div>
                <div>
                    <label>{{ $t('Title') }}</label>
                    <input type="text" v-model="updatedPageTitle"/>
                </div>
            </div>


            <label>{{ $t('Category') }}</label>
            <CustomDropdown
                :options="categoriesArray"
                :selectedLabel="selectedCategoryLabel"
                :optionLabel="categoryOptionLabel"
                @select="selectCategory"
            />

        </template>

        <template #footer>
            <IOButton variant="secondary" @click="close">{{ $t('Cancel') }}</IOButton>
            <IOButton :disabled="!updatedPageNumber && !updatedPageTitle" @click="save">{{ $t('Save') }}</IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./EditPopup.ts"></script>
<style lang="scss" src="./EditPopup.scss" scoped></style>
