import { defineComponent } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import TooltipIcon from '@/components/atoms/TooltipIcon/TooltipIcon.vue'

export default defineComponent({
    name: 'AddNoteModal',
    components: { IOModal, TooltipIcon },
    data () {
        return {
            note: '',
            showModal: false,
        }
    },
    methods: {
        toggleModal (): void {
            this.showModal = !this.showModal
        },
        sendNote (): void {
            this.$emit('note', this.note)
            this.toggleModal()
        },
    },
})
