import { defineComponent } from 'vue'
import Filters from '../../parts/filters/Filters.vue'
import { FilterInterface } from '@/interfaces/components/lazy-list/FilterInterface'
import { debounce } from 'lodash'

export default defineComponent({
    components: {
        Filters
    },
    props: {
        filterSchema: { type: Array as () => FilterInterface[], default: () => [] },
        collapse: { type: Boolean, default: true }
    },
    data () {
        return {
            collapseId: `filter-section-collapse-inner-${ this.generateUuid() }`
        }
    },
    watch: {
        'collapse' () {
            this.change()
        }
    },
    mounted () {
        this.change()
    },
    methods: {
        change () {
            const collapseItem = document.getElementById(this.collapseId)

            if (collapseItem !== null) {
                collapseItem.style.maxHeight = collapseItem.scrollHeight + 'px'
                if (this.collapse) {
                    (debounce(this.deleteMaxHeight, 500))(collapseItem)
                } else {
                    (debounce(this.updateMaxHeight, 0))(collapseItem, '0px')
                }
            }
        },
        updateMaxHeight (collapseItem: any, value: any): any {
            collapseItem.style.maxHeight = value
        },
        deleteMaxHeight (collapseItem: any): any {
            if (this.collapse) {
                collapseItem.style.maxHeight = null
            }
        }
    }
})
