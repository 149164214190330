import { defineComponent } from 'vue'
import { ItemRouteInterface } from '@/interfaces/components/lazy-list/ItemRoute'

export default defineComponent({
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    computed: {
        itemRoute (): ItemRouteInterface {
            return {
                name: 'drawings-sheets-show',
                params: {
                    sheetId: this.data.id,
                    versionId: this.data.current_version_id,
                },
            }
        },
    },
})
