import { defineComponent } from 'vue'
import SelectWorkspaceType from '../select-workspace-type/SelectWorkspaceType.vue'

export default defineComponent({
    name: 'UserCompanyNotFound',
    components: {
        SelectWorkspaceType
    },
    props: {
        componentKey: { type: String, required: true }
    },
    computed: {
        searchKeyword () {
            return this.$store.getters['inviteModal/getSearchKeyword'](this.componentKey)
        }
    },
})
