<template>
    <div
        class="io-atoms-checkbox"
        :class="{'io-minus': isIndeterminate}"
    >
        <label>
            <input
                type="checkbox"
                v-model="checked"
                @change="addSelectedRows"
            />
        </label>
    </div>
</template>


<script lang="ts" src="./ServerSideGroupCheckbox.ts"></script>
<style lang="scss" src="./ServerSideGroupCheckbox.scss" scoped></style>
