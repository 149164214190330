<template>
    <div class="io-uploader-custom-component">
        <link href="/js/fine-uploader/fine-uploader-new.css" media="screen" rel="stylesheet" type="text/css"/>
        <div v-if="loader" class="io-single-file-upload-row io-uploading io-attachments-loader">
            <div class="io-single-file-upload-row__loading-bar" :style="{transform: 'scaleX(' + (progressCount / 100) + ')'}"></div>
            <div class="io-single-file-upload-row__loading-progress">{{ progressCount }}%</div>
        </div>
        <div :id="setElementId" class="fileUploaderBox io-uploader-box" style="position: relative;"></div>

        <component :is="'script'" :id="templateName" type="text/template">
            <div class="io-drop-file-box">
                <div class="io-dropbox io-f-column">
                    <div class="qq-uploader-selector qq-uploader io-qq-uploader qq-gallery io-files-uploading" qq-drop-area-text="">

                        <div class="io-drop-files-icon">
                            <i :class="uploaderIcon"></i>
                        </div>
                        <div class="io-drop-files-content">
                            <div class="io-drop-files-header">{{ uploaderTitle }}</div>
                            <div class="io-drop-files-text">{{ $t('Click or drag & drop a file to upload. It only takes a few seconds.') }}</div>
                        </div>

                        <div class="qq-upload-drop-area-selector qq-upload-drop-area" qq-hide-dropzone>
                            <span class="qq-upload-drop-area-text-selector"></span>
                        </div>
                        <div class="qq-upload-buttons">
                            <div :id="`io-upload-from-library-holder-${arrayId}-${filesSection}`" class="qq-upload-from-library-button"></div>
                            <div class="qq-upload-buttons-separator">
                                <p>{{ $t('OR') }}</p>
                            </div>
                            <div class="qq-upload-button-selector qq-upload-button">
                                <div>{{ $t('Upload from computer') }}</div>
                            </div>
                        </div>
                        <span class="qq-drop-processing-selector qq-drop-processing">
                            <span>{{ $t('Processing dropped files...') }}</span>
                            <span class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"></span>
                        </span>

                        <ul class="qq-upload-list-selector qq-upload-list" role="region" aria-live="polite" aria-relevant="additions removals" style="display: none;">
                            <li>
                                <span role="status" class="qq-upload-status-text-selector qq-upload-status-text"></span>
                                <div class="qq-progress-bar-container-selector qq-progress-bar-container">
                                    <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" class="qq-progress-bar-selector qq-progress-bar"></div>
                                </div>
                                <span class="qq-upload-spinner-selector qq-upload-spinner"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </component>

        <SelectFilesPopup
            v-if="selectFilesPopupVisible"
            :key="modelId + uniqueId()"
            :modelType="modelType"
            :modelId="clonedModelId"
            :filesSection="filesSection"
            :subSection="subSection"
            :forceShared="forceShared"
            :defaultShare="defaultShare"
            :multipleFiles="multipleFiles"
            :allowedExtensions="validationOptions.allowedExtensions"
            @close="closeSelectFilesPopup()"
            @updateDocuments="updateDocuments"
        />
    </div>
</template>

<script src="./uploader.js"></script>
<style lang="scss" src="./uploader.scss" scoped></style>
