<template>
    <div
        v-progress="loading"
        class="io-budget-cost-code-select"
        :class="{
            'io-budget-cost-code-select--loading': loading,
            'io-budget-cost-code-select--invalid': !valid,
        }"
        data-testid="budget-cost-code-select"
    >
        <div v-if="costCodeCategories">
            <Multiselect
                :id="id"
                track-by="id"
                searchable
                :internal-search="false"
                :loading="loading"
                :options="costCodeCategoriesOptions"
                :placeholder="$t('Select...')"
                :multiple="enableMultiselect"
                :close-on-select="!enableMultiselect"
                :custom-label="getSearchingLabel"
                :model-value="selectedOption"
                :disabled="disabled"
                @update:model-value="updateValue"
                @search-change="(value: string) => searchKeyword = value"
            >

                <template #beforeList>
                    <div v-if="enableMultiselect" class="io-multiselect-option io-list-header" @click="selectAll">
                        <Checkbox
                            :checkbox-value="true"
                            :model-value="allSelected"
                            :partial-check="someItemsSelected"
                        />
                        {{ $t('Select All') }}
                    </div>
                </template>

                <template #selection="{ values, search, isOpen }">
                    <span v-if="values.length && !search.length" v-show="!isOpen" class="io-multiple-selected-options">
                        <span class="io-selected-amount">{{ Array.isArray(selectedOption) && selectedOption.length }}</span> {{ $t('Cost Codes Selected') }}
                    </span>
                </template>

                <template #singleLabel="{ option }">
                    <div v-if="isNewCostCodeUIFlagEnabled" :class="{ 'multiselect__single--subtitle': option.extended_code_name }">
                        <span v-if="option.extended_code_name">{{ option.extended_code_name }}</span>
                        <span>{{ option.code_name }}</span>
                    </div>

                    <span v-else>{{ option.code_name }}</span>
                </template>

                <template #option="{ option, search }">
                    <div
                        class="io-multiselect-option"
                        :class="{ 'io-category': isOptionCategory(option) }"
                        :style="{ paddingLeft: `${ getDepth(option) * 8 }px` }"
                        @click="onCheckboxClick(option)"
                    >
                        <Checkbox
                            v-if="enableMultiselect"
                            :checkbox-value="option.id"
                            :model-value="option.selected"
                            :partial-check="hasNestedItemsSelected(option)"
                        />

                        <span v-if="search">
                            <template v-for="(part, idx) in getHighlightedText(option, search)" :key="idx">
                                <mark v-if="part.match" class="bg-highlighted">{{ part.text }}</mark>

                                <span v-else>{{ part.text }}</span>
                            </template>
                        </span>

                        <span v-else>{{ option.code_name }}</span>
                    </div>
                </template>

                <template #noResult>{{ $t('No results found') }}</template>

                <template #noOptions>
                    <div class="io-budget-cost-code-select__empty">
                        <b>{{ $t('Set up the Budget to assign a cost code') }}</b>

                        <div v-if="projectBudgetDashboardSummaryRoute">
                            <i18n-t keypath="Go to the {0}">
                                <RouterLink :to="projectBudgetDashboardSummaryRoute" >
                                    {{ $t('Budget module') }}
                                </RouterLink>
                            </i18n-t>
                        </div>
                    </div>
                </template>
            </Multiselect>

            <Transition name="fade-from-bottom">
                <div v-if="!valid && errorMessage" class="io-error-message">{{ errorMessage }}</div>
            </Transition>

        </div>

        <input v-else class="m-0" disabled />
    </div>
</template>

<script lang="ts" src="./BudgetCostCodeSelect.ts"></script>
<style lang="scss" src="./BudgetCostCodeSelect.scss" scoped></style>
