import { defineComponent } from 'vue'

import matchLabelTitle from '@/io-modules/specifications/components/spec-reference-select/utils/matchLabelTitle'
import ascend from '@/io-modules/specifications/components/spec-reference-select/utils/ascend'

import SectionsShow from '@/io-modules/specifications/components/spec-reference-select/parts/sections-show/SectionsShow.vue'
import { Division, Section } from '@/io-modules/specifications/components/spec-reference-select/interfaces/Specification'

export default defineComponent({
    name: 'DivisionsShow',
    components: { SectionsShow },
    props: {
        division: {
            type: Object as () => Division,
            required: true,
        },
        search: {
            type: String,
            default: '',
        },
    },
    computed: {
        expanded (): boolean {
            return this.$route.query['spec-divisions']?.includes(this.division.id)
        },
        sortedSections (): Section[] {
            return this.division.sections.sort(ascend)
        },
        filteredSections (): Section[] {
            return this.search
                ? this.sortedSections.filter((section: Section) => {
                    const matchUserSearch = matchLabelTitle(this.search)

                    const divisionMatched = matchUserSearch(this.division)
                    const sectionMatched = matchUserSearch(section)

                    return divisionMatched || sectionMatched
                })
                : this.sortedSections
        }
    },
    methods: {
        toggle (): void {
            /**
             * Ensure that the query param is an array
             * since it may become a string after hard page refresh
             */
            const divisions = Array.isArray(this.$route.query['spec-divisions'])
                ? this.$route.query['spec-divisions']
                : [this.$route.query['spec-divisions']]

            const specDivisionsQuery = this.expanded
                ? divisions.filter((id: string) => id !== this.division.id)
                : [...divisions, this.division.id]

            this.$router.replace({ query: { ...this.$route.query, 'spec-divisions': specDivisionsQuery } })
        },
    },
})
