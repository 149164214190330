<template>
    <div v-progress="loading" class="flex flex-col gap-32-px bg-white rounded p-16-px">
        <div class="font-semibold">{{ $t('New Stamp') }}</div>

        <div class="io-stamp-preview flex justify-center">
            <IOInput
                maxlength="2"
                :valid="!v$.initials.$error"
                :error-message="loading ? '' : v$.initials.$errors[0]?.$message"
                :style="{ color }"
                v-model="initials"
                @keyup.enter.stop="create"
            />
        </div>

        <div class="px-24-px">
            <IOInput
                autofocus
                maxlength="100"
                :valid="!v$.title.$error"
                :error-message="$t('The field is required', { field: $t('Title') })"
                v-model="title"
                @keyup.enter.stop="create"
            />
        </div>

        <ColorPicker class="justify-center" v-model="color"/>

        <div class="flex justify-end gap-24-px">
            <IOButton
                size="small"
                variant="link"
                class="py-8-px"
                @click.stop="$emit('close')"
            >
                {{ $t('Cancel') }}
            </IOButton>

            <IOButton
                :disabled="!formValid"
                size="small"
                variant="link"
                class="py-8-px"
                @click="create"
            >
                {{ $t('Create') }}
            </IOButton>
        </div>
    </div>
</template>

<script lang="ts" src="./StampCreate.ts"></script>
<style lang="scss" src="./StampCreate.scss" scoped></style>
