<template>
    <div :class="{ 'io-placeholder-content': true, 'no-icon': !displayIcon}">
        <div v-if="displayIcon" class="io-placeholder-content__illustration">
            <span
                class="io-placeholder-content__icon"
                :class="icon"
                :style="{ color: iconColor }"
            ></span>
        </div>
        <div class="io-placeholder-content__title" :class="`io-placeholder-content__title--${textSize}`" v-if="title" v-html="title"></div>
        <div class="io-placeholder-content__subtitle" :class="`io-placeholder-content__subtitle--${textSize}`" v-if="subtitle" v-html="subtitle"></div>
        <div class="io-placeholder-content__buttons" v-if="buttonsCta && buttonsCta.length">
            <template v-for="(button, index) in buttonsCta" :key="index">
                <IOButton
                    :size="size"
                    :id="button.id || null"
                    :class="buttonClass(button, index)"
                    :variant="buttonVariant(button, index)"
                    :icon="button.icon"
                    :disabled="!!button.disabled"
                    @click="handleButtonClick(button)"
                >
                    {{ $t(button.name) }}
                </IOButton>
            </template>
        </div>
        <div class="io-placeholder-content__buttons">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script lang="ts" src="./Placeholder.ts"></script>
<style lang="scss" src="./Placeholder.scss" scoped></style>
