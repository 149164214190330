<template>
    <IOModal
        class="io-approval-modal"
        :width="623"
        :icon="modalIcon"
        :color="modalIconColor"
        :title="modalTitle"
        :scrollable="true"
        :z-index="zIndex"
        @close="$emit('close')"
    >
        <template v-if="step" #header-start>
            <StatusPill class="io-approval-modal__step" variant="blue-light" size="big" :text="step" />
        </template>
        <template #default>
            <div class="io-approval-modal__content">
                <div>
                    <div class="io-approval-modal__content__title">
                        {{ $t('Are you sure you want to {action} the {name}?', { action: action, name: resourceNameInLowerCase }) }}
                    </div>
                    <div class="io-approval-modal__content__sub-title">
                        {{ isManualApproval ? subTitleTextManualApproval : subTitleText }}
                    </div>
                    <AlertBox v-if="isManualApproval && !isApplicationPackage" color="yellow">
                        <template #default>
                            {{ $t('You have not configured an approval process for this module. If you would like to create a formal approval process, you can do so now in') }}
                            <IOButton variant="link" @click="goToProjectSettings">
                                {{ $t('Project Settings') }}
                            </IOButton>
                        </template>
                    </AlertBox>
                    <AlertBox v-if="!isRejection && isLastApprover() && !isApplicationPackage && !isManualApproval" color="yellow">
                        <template #default>
                            {{ $t('You are the last member of this approval sequence. Please confirm you have reviewed and wish to finalize this internal approval process.') }}
                        </template>
                    </AlertBox>
                </div>
                <div>
                    <IOLabel :type="LabelType.BoldMax" :text="$t(`Type ‘${confirmText}’ to confirm`)" :isRequired="true" />
                    <IOInput
                        required
                        :placeholder="$t(`Type ‘${confirmText}’ in Uppercase`)"
                        aria-label="field-confirmation"
                        v-model="confirmInput"
                    />
                </div>
                <div>
                    <IOLabel :type="LabelType.BoldMax" :text="$t('Add Note')" :isRequired="isRejection" />
                    <IOTextarea aria-label="field-note" v-model="note" />
                    <div class="io-approval-modal__content__note-public">
                        <IOLabel :type="LabelType.BoldMax" :text="$t('Note visible to users outside your workspace?')" />
                        <IOSwitcher
                            :title="isNotePublic ? $tc('Yes') : $tc('No')"
                            v-model="isNotePublic"
                        />
                    </div>
                </div>
                <FilesComponent
                    v-if="!hideFiles"
                    :key="filesArrayId"
                    modelType="resource_user_approval_record"
                    :arrayId="filesArrayId"
                    :showShared="false"
                    :forceHideSharedTab="true"
                    :showUploader="true"
                    :hideShareColumn="true"
                    :filesSection="resourceName"
                    :addToTemporary="true"
                    :filesInNewTab="true"
                    :showModalVersion="true"
                    :validationOptions="validationOptions"
                    :enable-file-extension-filtering-in-file-selector="true"
                    @newFileAdded="onFileAdd"
                    @tempFileRemove="onFileRemove"
                />
            </div>
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton
                v-if="showSecondaryButton"
                variant="secondary"
                :disabled="!isValid || isRequestInProcess"
                @click="secondaryButtonClick"
            >
                {{ secondaryButtonText }}
            </IOButton>
            <IOButton
                :disabled="!isValid || isRequestInProcess"
                @click="primaryButtonClick"
            >
                {{ primaryButtonText }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./ApprovalModal.ts"></script>
<style lang="scss" src="./ApprovalModal.scss" scoped></style>
