const ProjectContractsV3 = () => import  ('./views/contracts-list/ContractsList.vue')
const ContractWizard = () => import ('./views/contract-wizard/ContractWizard.vue')
const ContractDetails = () => import ('./views/contract-details/ContractDetails.vue')
const ContractDetailsDetails = () => import('./components/contract-details/details/Details.vue')
const ContractDetailsSovWbs = () => import ('./components/contract-details/sov-wbs/SovWbs.vue')
const ContractDetailsApprovals = () => import('./components/contract-details/approvals/Approvals.vue')
const ContractDetailsTimeline = () => import('./components/contract-details/contract-timeline/ContractTimeline.vue')

import ContractRouteName from '@/io-modules/project-contracts/enums/ContractRouteName'
import ContractDetailsChildrenRouteName from '@/io-modules/project-contracts/enums/ContractDetailsChildrenRouteName'

const defaultMeta = {
    name: 'Project Contracts',
    navigationKey: 'contracts_v3',
    customClass: 'io-project-contracts',
}

export default [
    {
        path: '/project/:pid/contracts-v3',
        name: ContractRouteName.ProjectContractsList,
        component: ProjectContractsV3,
        meta: {
            title: 'Project Contracts',
            ...defaultMeta,
        },
    },
    {
        path: '/project/:pid/contract-v3/create',
        name: ContractRouteName.ContractCreate,
        component: ContractWizard,
        meta: {
            title: 'Add Contract',
            ...defaultMeta,
        },
    },
    {
        path: '/project/:pid/contract-v3/:id/edit',
        name: ContractRouteName.ContractEdit,
        component: ContractWizard,
        meta: {
            title: 'Edit Contract',
            ...defaultMeta,
        },
    },
    {
        path: '/project/:pid/contract-v3/:id',
        name: ContractRouteName.ContractDetails,
        component: ContractDetails,
        redirect: { name: ContractDetailsChildrenRouteName.Details },
        meta: {
            title: 'Contract Details',
            ...defaultMeta,
        },
        children: [
            {
                path: 'details',
                name: ContractDetailsChildrenRouteName.Details,
                component: ContractDetailsDetails,
            },
            {
                path: 'wbs',
                name: ContractDetailsChildrenRouteName.SovWbs,
                component: ContractDetailsSovWbs,
            },
            {
                path: 'approvals',
                name: ContractDetailsChildrenRouteName.Approvals,
                component: ContractDetailsApprovals,
            },
            {
                path: 'timeline',
                name: ContractDetailsChildrenRouteName.Timeline,
                component: ContractDetailsTimeline,
            },
        ],
    },
]
