import { Core } from '@pdftron/webviewer'

export default (annotation: Core.Annotations.TextHighlightAnnotation, documentViewer: Core.DocumentViewer): string => {
    const quads = annotation.getQuads()

    const highlightText = quads
        .map(quad => {
            const selectionStartPoint = { x: quad.x1, y: quad.y1, pageNumber: annotation.PageNumber }
            const selectionEndPoint = { x: quad.x3, y: quad.y3, pageNumber: annotation.PageNumber }

            documentViewer.select(selectionStartPoint, selectionEndPoint)
            return documentViewer.getSelectedText()
        })
        .join('\n')
    documentViewer.clearSelection()

    return highlightText
}
