<template>
    <div>
        <IOButton
            v-if="editable && (multiple || !modelValue.length)"
            v-tooltip="{ content: $t('You must first select a stamp to proceed.'), visible: type === ReferenceType.STAMP && !stamp }"
            variant="link"
            icon="icon-file-draft"
            size="small"
            :disabled="type === ReferenceType.STAMP && !stamp"
            @click="openNewReferenceModalOrSelectSetModal"
        >
            {{ $t('Select Sheet') }}
        </IOButton>

        <Transition name="fade-from-bottom">
            <TransitionGroup
                v-if="modelValue.length"
                tag="ul"
                name="fade-from-bottom"
                class="flex flex-col gap-8-px mt-16-px"
            >
                <li
                    v-for="reference in modelValue"
                    :key="reference.uuid"
                    class="flex justify-between items-center gap-16-px px-16-px py-8-px rounded-lg border border-darker-bg leading-relaxed"
                >
                    <RouterLink
                        v-if="reference.id"
                        class="text-dark-green-lighten-1 text-sm-px truncate"
                        target="_blank"
                        :to="{
                            name: 'drawings-sheets-show',
                            params: {
                                sheetId: reference.drawing.id,
                                versionId: reference.drawing.current_version_id,
                                pid: $route.params.pid,
                            }
                        }"
                    >
                        {{ getReferenceTitle(reference.drawing) }}
                    </RouterLink>

                    <div v-else :title="reference.drawing.page_title" class="text-sm-px truncate">
                        {{ getReferenceTitle(reference.drawing) }}
                    </div>

                    <FloatMenu
                        static
                        :actions="getReferenceActions(reference)"
                        @clear="removeReference(reference)"
                    />
                </li>
            </TransitionGroup>
        </Transition>

        <SelectSetModal
            v-if="selectSetModalShown"
            :sets="currentSets"
            @set-selected="openNewReferenceModal"
            @add-new-drawings="closeModalAndOpenDrawingsList"
            @close="closeSelectSetModal"
        />

        <IOModal
            v-if="newReferenceModalShown"
            fullscreen
            scrollable
            :widest="Boolean(view === View.LIST || selectedSheet)"
            :loading="loading"
            icon="icon-pin-2"
            :title="$t('Select Drawing Reference')"
            @close="closeNewReferenceModal"
        >
            <template #header-start>
                <Transition name="fade-from-bottom" mode="out-in">
                    <IOButton
                        v-if="selectedSheet"
                        icon="icon-undo"
                        variant="link"
                        class="ml-24-px"
                        @click="selectedSheet = null; reference = null"
                    >
                        {{ $t('Back to List') }}
                    </IOButton>

                    <ToggleSwitch v-else variant="white" :options="viewOptions" v-model="view" />
                </Transition>
            </template>

            <template #header-action>
                <Transition name="fade-from-bottom">
                    <div v-if="!selectedSheet" class="io-search">
                        <IOInput
                            :placeholder="$t('Search...')"
                            icon="icon-search"
                            clearable
                            v-model="search"
                        />
                    </div>
                </Transition>

                <IOButton
                    v-if="currentSets?.length > 1"
                    variant="secondary"
                    @click="backToSelectSetModal"
                >
                    {{ $t('Back') }}
                </IOButton>

                <IOButton
                    v-else
                    variant="secondary"
                    @click="closeNewReferenceModal"
                >
                    {{ $t('Close') }}
                </IOButton>

                <IOButton :disabled="!reference" @click="saveDrawingReference(); closeNewReferenceModal()">
                    {{ $t('Save') }}
                </IOButton>
            </template>

            <Transition name="fade-from-bottom">
                <div v-if="sheets?.length" :class="{ 'h-full': selectedSheet }">
                    <SheetWebViewer
                        v-if="selectedSheet"
                        class="h-full"
                        :url="selectedSheet.file"
                        :stamp="stamp"
                        :pin-color="pinColor"
                        :cursor-class="cursorClass"
                        @reference-selected="reference = $event"
                    />

                    <SheetsGridView
                        v-else-if="view === View.GRID"
                        :sheets="filteredSheets"
                        @select="selectedSheet = $event"
                    />

                    <SheetsListView
                        v-else-if="view === View.LIST"
                        :sheets="filteredSheets"
                        @select="selectedSheet = $event"
                    />
                </div>

                <div v-else-if="currentSets && !loading" class="h-full flex flex-col justify-center">
                    <IOPlaceholder :title="$t('No Current Set')" :subtitle="$t('You have not yet added any Current Set')">
                        <IOButton @click="$router.push({ name: 'drawings-sets-index' })">
                            {{ $t('Add New Drawings') }}
                        </IOButton>
                    </IOPlaceholder>
                </div>
            </Transition>
        </IOModal>
    </div>
</template>

<script lang="ts" src="./DrawingReferenceSelect.ts"></script>
<style lang="scss" src="./DrawingReferenceSelect.scss" scoped></style>
