<template>
    <div class="io-filter-section">
        <div v-if="title" class="io-filter-section__title">
            {{ title }}
            <Tooltip v-if="tooltipText" :text="tooltipText" />

            <div v-if="showClear" class="io-filter-section__clear" @click="$emit('clear')">
                <i class="fas fa-times-circle"></i>{{ $t('Clear filter') }}
            </div>

            <div v-if="clearSelection" class="io-filter-section__clear-selection" @click="$emit('clearSelection')">
                {{ $t('Clear') }}
            </div>
        </div>
        <div class="io-filter-section__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterSection',
    props: {
        title: {
            type: String,
            default: null,
        },
        clearSelection: {
            type: Boolean,
            default: false,
        },
        tooltipText: {
            type: String,
            default: null,
        },
        showClear: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss">
.io-filter-section {
    padding: 16px 24px;

    &__title {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.7;
        color: var(--main-lighten-1);
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }

    &__clear {
        margin-left: auto;
        cursor: pointer;
        color: var(--main-lighten-2);
        font-size: 14px;
        font-weight: 400;
        transition: color .2s;

        &:hover {
            color: var(--main);

            i {
                color: var(--main);
            }
        }

        i {
            margin-right: 4px;
            color: var(--closed);
            transition: color .2s;
        }
    }

    &__clear-selection {
        color: var(--surfie-green-darker);
        margin-left: auto;
        cursor: pointer;
        font-size: 12px;
    }

    &__show-more {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.1px;
        color: var(--dark-green-lighten-1);
        cursor: pointer;
        transition: color .2s;
        margin-top: 16px;
        display: flex;
        align-items: center;

        &.io-active {
            i {
                transform: rotate(-180deg);
            }
        }

        &:hover {
            color: var(--dark-green);
        }

        i {
            font-size: 18px;
            margin-right: 12px;
            transition: transform .22s;
        }
    }

    .io-checkbox,
    .io-radio,
    .io-switch {
        margin-bottom: 16px;
    }
}
</style>
