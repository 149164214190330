import { defineComponent, PropType } from 'vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import SingleClientApprovalStatus from '../single-client-approval-status/SingleClientApprovalStatus.vue'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus'
import AvailableActions from '../../interfaces/AvailableActions'

export default defineComponent({
    name: 'ClientApprovalCard',
    components: {
        IOSingleInfoRow,
        SingleClientApprovalStatus,
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        client: {
            type: Object as PropType<{ name: String, id: String }>,
            default: null,
        },
        dateSubmitted: {
            type: String,
            default: '',
        },
        dateApprovedOrRejected: {
            type: String,
            default: '',
        },
        isSubmittedToClient: {
            type: Boolean,
            default: false,
        },
        status: {
            type: String as PropType<ApprovalStatus>,
            default: '',
        },
        actions: {
            type: Array as PropType<AvailableActions[]>,
            default: () => [],
        },

        dateReceived: String,
    },
    computed: {
        submittedToClientDate (): string {
            return this.dateSubmitted ? this.$filters.parseDateTime(this.dateSubmitted) : '-'
        },
    },
})