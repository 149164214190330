import { defineComponent } from 'vue'
import Comments from './comments/Comments.vue'
import RFIs from './rfi/RfiPanel.vue'
import PunchList from './punch/PunchPanel.vue'
import Others from './others/Others.vue'
import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'
import { SelectedExistingAnnotation } from '@/interfaces/modules/projects/drawings/Annotation'
import { mapGetters } from 'vuex'
import { MarkupTab } from '@/io-modules/drawings/interfaces/MarkupTab'
import { SetType } from '@/io-modules/drawings/enums/SetType'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'

export default defineComponent({
    name: 'Markups',
    components: {
        DropdownButtonNew,
        Comments,
        RFIs,
        PunchList,
        Others,
    },
    props: {
        isFeatureFlagEnabled: {
            type: Boolean,
            default: true,
        },
        isArchivedVersion: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            searchValue: '',
            activeTabType: 'comment',
            hoveredTab: {},
            showResolved: false,
        }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
            addingAnnotation: 'getAddingAnnotation',
            annotationContent: 'getAnnotationContent',
            selectedAnnotation: 'getSelectedAnnotation',
            selectedExistingAnnotation: 'getSelectedExistingAnnotation',
        }),
        isDrawingSetConstructionType (): boolean {
            return this.model.drawing_set_type === SetType.CONSTRUCTION
        },
        tabs (): MarkupTab[] {
            return [
                {
                    icon: 'comment-text',
                    name: this.$t('Comments'),
                    componentName: 'Comments',
                    addButtonText: this.$t('Add Comment'),
                    type: 'comment',
                    annotation_types: ['comment'],
                },
                {
                    icon: 'comment-2-text',
                    name: this.$t('RFIs'),
                    componentName: 'RFIs',
                    addButtonText: this.$t('Add RFI'),
                    type: 'rfi',
                    annotation_types: ['rfi', 'rfi_v2', 'rfi_v2_solution'],
                },
                {
                    icon: 'pin-2',
                    name: this.$t('Punch Items'),
                    componentName: 'PunchList',
                    addButtonText: this.$t('Add Punch Item'),
                    type: 'punch',
                    annotation_types: ['punch', 'punch_v2'],
                },
                {
                    icon: 'box',
                    name: this.$t('Others'),
                    componentName: 'Others',
                    addButtonText: null,
                    type: 'pdftron',
                    annotation_types: ['pdftron'],
                },
            ]
        },
        filteredTabs (): MarkupTab[] {
            return this.isDrawingSetConstructionType
                ? this.tabs
                : this.tabs.filter((tab: MarkupTab) => tab.type !== 'rfi')
        },
        activeTab (): MarkupTab {
            return this.tabs.find((tab: MarkupTab) => tab.type === this.activeTabType)
        },
    },
    watch: {
        selectedExistingAnnotation: {
            handler (newVal: SelectedExistingAnnotation): void {
                if (newVal.type !== '') {
                    this.activeTabType = this.tabs.find(tab => tab.annotation_types.includes(newVal.type))?.type
                        ?? this.tabs[0].type
                }
            },
            immediate: true,
        },
        annotationContent: {
            handler (activeTabType: string): void {
                this.activeTabType = this.tabs.find(tab => tab.annotation_types.includes(activeTabType)).type
            },
        },
        addingAnnotation: {
            handler (): void {
                this.activeTabType = this.tabs.find(tab => tab.annotation_types.includes(this.annotationContent)).type
            },
        },
        activeTabType: {
            handler (): void {
                this.toggleResolvedComments(false)
            },
        },
    },
    async mounted (): Promise<void> {
        if (!this.isFeatureFlagEnabled) {
            this.tabs = this.tabs.filter(tab => tab.type !== 'others')
        }
        if (this.$route.query?.comment_id) {
            await this.selectComment(this.$route.query.comment_id)
        }

        this.activeTabType = this.tabs.find((tab: MarkupTab) => tab.type === 'comment').type
    },
    methods: {
        startAddingAnnotation (annotationType: string): void {
            if (annotationType === 'punch') {
                this.$store.commit('drawingMarkup/SET_SELECT_STAMP_POPUP', true)
                return
            }
            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                status: true,
                type: annotationType,
            })
        },
        addButtonText (activeTab: string): string {
            return this.tabs.filter(tab => tab.componentName === activeTab)[0].addButtonText
        },
        selectComment (commentId: string | string[]): void {
            this.$store.commit('drawingMarkup/SET_PANEL_CONTENT', RightSideBar.MARKUPS)
            this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', 'drawing')
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: commentId,
                type: RightSideBar.COMMENTS,
            })
        },
        cancelAnnotation (): void {
            this.$emit('cancel-annotation')
        },
        annotationDeleted (uuid: string): void {
            this.$emit('annotation-deleted', uuid)
        },
        deletePdftronMarkup (id: string): void {
            this.$emit('delete-pdftron-markup', id)
        },
        changeActiveTab (tab: MarkupTab): void {
            this.activeTabType = tab.type
            this.$store.commit('drawingMarkup/SET_ANNOTATION_CONTENT', tab.type)
        },
        toggleResolvedComments (value: boolean): void {
            this.showResolved = value
        },
    },
})
