<template>
    <div
        v-if="status === ApprovalStatus.APPROVED"
        class="io-single-approval-status io-single-approval-status--approved"
    >
        <i class="icon-check-circle-2-2"></i>
        <div class="io-single-approval-status__holder">
            {{ $t('Approved by Client on {date}', { date: $filters.parseDateTime(date) }) }}
        </div>
    </div>
    <div
        v-else-if="status === ApprovalStatus.REJECTED"
        class="io-single-approval-status io-single-approval-status--rejected"
    >
        <i class="icon-cross-circle-2"></i>
        <div class="io-single-approval-status__holder">
            {{ $t('Rejected by Client on {date}', { date: $filters.parseDateTime(date) }) }}
        </div>
    </div>
    <div
        v-else-if="status === 'voided'"
        class="io-single-approval-status io-single-approval-status--rejected"
    >
        <i class="icon-cross-circle-2"></i>
        <div class="io-single-approval-status__holder">
            {{ $t('Voided by Client on {date}', { date: $filters.parseDateTime(date) }) }}
        </div>
    </div>
    <div
        v-else-if="status === 'revision_requested'"
        class="io-single-approval-status io-single-approval-status--warning"
    >
        <i class="icon-arrow-swap-rec-2"></i>
        <div class="io-single-approval-status__holder">
            {{ $t('Client requested revision on {date}', { date: $filters.parseDateTime(date) }) }}
        </div>
    </div>
    <div
        v-else
        class="io-single-approval-status io-single-approval-status--pending"
    >
        <i class="icon-time-2"></i>
        <div class="io-single-approval-status__holder">
            {{ $tc('Pending client approval') }}
            <!-- <IOButton
                variant="link"
                size="small"
                icon="icon-send-2"
                @click="handleSendReminder"
            >{{ $t('Send Reminder') }}</IOButton> -->
        </div>
    </div>
</template>

<script lang="ts" src="./SingleClientApprovalStatus.ts"></script>
<style lang="scss" src="./SingleClientApprovalStatus.scss" scoped></style>
