import { defineComponent } from 'vue'

const nonNegativeInteger = (value: number): boolean => Number.isInteger(value) && value >= 0

export default defineComponent({
    name: 'ProgressTrack',
    props: {
        current: {
            type: Number,
            required: true,
            validator: nonNegativeInteger,
        },
        total: {
            type: Number,
            required: true,
            validator: nonNegativeInteger,
        },
    },
})
