import { defineComponent } from 'vue'
import validationMixin from '@/mixins/validationMixin'
import { required } from '@vuelidate/validators'

import PopupModal from '@/components/popup-modal/PopupModal.vue'

export default defineComponent({
    name: 'DocumentRenameModal',
    components: {
        PopupModal,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    mixins: [validationMixin],
    emits: ['close', 'save'],
    data () {
        return {
            input: null,
            extension: null,
        }
    },
    computed: {
        fileNameWithExtension (): string {
            return `${ this.input }.${ this.extension }`
        }
    },
    created () {
        const fileNameParts = this.name.split('.')
        this.extension = fileNameParts.pop()
        this.input = fileNameParts.join('.')
    },
    validations (): object {
        return {
            input: { required },
        }
    },
})
