import { isEmpty } from 'lodash'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew'
import documentMixin from '../../mixins/DocumentMixin'
import ArchiveFolderMixin from '../../../../../mixins/ArchiveFolderMixin'
import { mapState as mapStateVuex, mapGetters as mapGettersVuex } from 'vuex'
import DocumentsModelType from '../../../../../../../constants/Documents/DocumentsModelType'

export default {
    name: 'SingleFolder',
    components: {
        DropdownButtonNew
    },
    mixins: [documentMixin, ArchiveFolderMixin],
    props: {
        folder: {
            type: Object,
            required: false
        },
        idx: {
            type: Number,
            required: false
        },
        arrayId: {
            type: String,
            required: true
        },
        view: {
            type: String,
            required: false
        },
        isSearchResult: {
            type: Boolean,
            required: false,
            default: false
        },
        isProjectView: {
            type: Boolean,
            required: false,
            default: false
        },
        hasFiles: {
            type: Boolean,
            required: false
        },
        isDocumentsView: {
            type: Boolean,
            required: false,
            default: false
        },
        fromTrash: {
            type: Boolean,
            required: true,
            default: false
        },
        isSharingFeatureActive: {
            type: Boolean,
            required: false,
            default: true
        },
        allowToSelectFiles: {
            type: Boolean,
            require: false,
            default: false
        },
        delegateNavigation: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            newFolderName: '',
            renameOptionClassName: 'js-rename',
            isCalculatingSize: true,
        }
    },
    computed: {
        ...mapGettersVuex('appStore', ['getAuthData']),
        ...mapStateVuex('filesComponent', {
            foldersBreadcrumbs: (state) => state.foldersBreadcrumbs,
        }),
        folders () {
            return this.$store.getters['filesComponent/getFolders'](this.arrayId)
        },
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        folderIconClass () {
            if (this.folder.file_type && this.folder.file_type === 'file') {
                return ''
            }

            return this.userCreatedFolder ? 'io-custom-folder' : 'io-system-folder'
        },
        isConstructionDoc () {
            return this.folder.model_type === 'construction_documents'
        },
        userCreatedFolder () {
            return this.folder.is_user_created
        },
        fileType () {
            return this.folder.file_type && this.folder.file_type === 'file'
        },
        folderNameNotChanged () {
            return this.folder.name === this.newFolderName
        },
        isVirtualFolder () {
            return this.folder._is_shared_with_others || this.folder._is_shared_with_me || this.folder._is_trash || this.folder._is_model_folder
        },
        isDeleted () {
            return this.folder.deleted_at
        },
        folderBelongsToCurrentUser () {
            return this.getAuthData.u_mongo === this.folder.creator_mongo_id
        },
        folderBelongsToCurrentWorkspace () {
            return this.getAuthData.company_mongo_id === this.folder.company_mongo_id
        },
        isJobsiteFolder () {
            return this.folderFlags.model_type === DocumentsModelType.JOBSITE_LOCATION
        },
    },
    methods: {
        changeFolderRoute (middleClick) {
            const folderId = this.folder._id ? this.folder._id : null
            const name = this.isProjectView ? 'project-documents-list' : 'documents-list'

            if (true === middleClick) {
                const link = this.$router.resolve({ name, params: { folderId: folderId } }).href

                window.open(link, '_blank')
                return
            }

            this.$router.push({ name, params: { folderId: folderId } })
        },
        async fetchFolders () {
            this.$store.dispatch('filesComponent/setLoading', true)

            // clear current files farray
            this.$store.dispatch('filesComponent/clearDocsArr', {
                arrayId: this.arrayId,
                files: []
            }, { root: true })

            let params = {}

            params.folder_id = this.folder._id ? this.folder._id : null
            params.folder_type = this.folder.folder_type

            // let paramFolderId = params.folder_id

            try {
                let response = {}
                response = await this.$api.get('/documents/folder/content', { params: params })

                const data = response.data

                this.$store.dispatch('filesComponent/setFolders', {
                    arrayId: this.arrayId,
                    folders: data.folders
                })

                this.$store.dispatch('filesComponent/setFolderFlags', data.current_folder)

                if (data.files && !isEmpty(data.files)) {
                    this.$store.dispatch('filesComponent/setMyDocs', {
                        arrayId: this.arrayId,
                        files: data.files.own
                    }, { root: true })
                    this.$store.dispatch('filesComponent/setSharedDocs', {
                        arrayId: this.arrayId,
                        files: data.files.shared
                    }, { root: true })
                    this.$store.dispatch('filesComponent/setTrashedDocs', {
                        arrayId: this.arrayId,
                        files: data.files.trashed
                    }, { root: true })
                }

                this.$store.dispatch('filesComponent/addBreadcrumb', { ...params, name: this.folder.name })

                this.$store.dispatch('filesComponent/setSearchLocal', false)
                this.$store.dispatch('filesComponent/setIsSearching', false)
                this.$store.dispatch('filesComponent/setLoading', false)
            } catch (e) {
                this.consoleError(e)
                this.showNotification('error', this.$t('Error occurred during loading project data.'))
                this.$store.dispatch('filesComponent/setLoading', false)
            }
        },
        async loadFolders (middleClick = false) {
            if (this.delegateNavigation) {
                this.$emit('handleNavigation', this.folder._id)
                return
            }

            if (this.allowToSelectFiles) {
                this.$store.dispatch('filesComponent/setIsSearching', true)
                this.fetchFolders()
                this.$emit('clearFilters')
                return
            }

            // if search result is file redirect to file details
            if (this.isSearchResult && this.fileType) {
                if (this.isProjectView) {
                    return this.$router.push({
                        name: 'project-documents-details',
                        params: { pid: this.$route.params.pid, id: this.folder._id }
                    })
                } else {
                    return this.$router.push({ name: 'documents-details', params: { id: this.folder._id } })
                }
            }

            if (this.isDocumentsView) {
                this.changeFolderRoute(middleClick)

                return
            }

            await this.fetchFolders()
        },
        onClickRenameFolder () {
            this.newFolderName = this.folder.name
            this.editMode = true

            this.$nextTick(() => {
                this.$refs.inputName.focus()
            })
        },
        async onClickHardDeleteFolder () {
            const popupOptions = {
                title: this.$t('Delete Folder'),
                caption: this.$t('Are you sure you want to delete this folder and its content?. This action cannot be undone.'),
                icon: 'far fa-trash',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Remove Folder'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.hardDeleteFolder()
                        }
                    }
                ]
            }

            this.showPopupAlert(popupOptions)
        },
        async onClickRestoreFolder () {
            const popupOptions = {
                title: this.$t('Restore Folder'),
                caption: this.$t('Are you sure you want to restore this folder? This will move the folder to its original location.'),
                icon: 'far fa-archive',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Restore Folder'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.restoreDeletedFolder()
                        }
                    }
                ]
            }

            this.showPopupAlert(popupOptions)
        },
        async hardDeleteFolder () {
            try {
                await this.$api.delete(`/folders/${ this.folder._id }/hard-delete`)
                this.$store.dispatch('storage/setRecalculateUsedStorage')
                this.$store.dispatch('filesComponent/removeMyFolder', {
                    arrayId: this.arrayId,
                    folderId: this.folder._id
                })
                this.$emit('refreshData')
            } catch (error) {
                this.showNotification('error', error.message)
            }
        },
        async restoreDeletedFolder () {
            try {
                await this.$api.patch(`/folders/${ this.folder._id }/restore`)
                this.$store.dispatch('storage/setRecalculateUsedStorage')
                this.$store.dispatch('filesComponent/removeMyFolder', {
                    arrayId: this.arrayId,
                    folderId: this.folder._id
                })
                this.$emit('refreshData')
            } catch (error) {
                this.showNotification('error', error.message)
            }
        },
        async saveEditedData () {
            if (!this.newFolderName.length) {
                this.showNotification('error', 'Invalid folder name.')
                return
            }

            if (this.folderNameNotChanged) {
                this.closeEditMode()
            }

            this.setLoadingBar(true)
            const requestData = { folder_name: this.newFolderName }

            try {
                const { data } = await this.$api.post('/documents/folder/' + this.folder._id + '/rename', requestData)
                this.folder.name = this.newFolderName
                this.folder.updated_at = data.updated_at

                this.closeEditMode()
            } catch (error) {
                const folderExistMessage = error.response.data?.errors?.folder_name[0] || null
                this.showNotification('error', folderExistMessage ?? error.response.data.message, error)
            } finally {
                this.setLoadingBar(false)
            }
        },
        clickOutsideRow (event) {
            if (!this.editMode || this.cancelEditModeModalShown) {
                return
            }

            if (event.target.classList.contains(this.renameOptionClassName) || event.target.parentElement.classList.contains(this.renameOptionClassName)) {
                return
            }

            const el = this.$refs[`row-${ this.folder._id }`]

            if (!(el === event.target || el.contains(event.target))) {
                this.cancelEditing(this.folderNameNotChanged)
            }
        },
        async toggleImportant () {
            if (this.allowToSelectFiles) {
                return
            }

            const setFlag = this.folder.flag === 1 ? 0 : 1

            const val = await this.toggleFavorite(setFlag, this.folder._id, 'folder')

            if (val) {
                this.folder.flag = setFlag
            }
        },
        /**
         * It works but there is a performance issue.
         * We are discussing with the product team to find the best solution to implement.
         */
        async getFolderSize () {
            try {
                const { data } = await this.$api.get(`/documents/get-folder-size/${ this.folder._id }`)
                this.folder.folder_size = data
            } catch (error) {
                this.folder.folder_size = 0
            } finally {
                this.isCalculatingSize = false
            }
        },
        onClickShareFolder () {
            this.$store.dispatch('filesComponent/setSharingResourceObject', this.folder)
            this.$store.dispatch('filesComponent/setSharingResourceWay', 'direct-invite')
        },
        onClickShareFolderViaDistributionTemplate () {
            this.$store.dispatch('filesComponent/setSharingResourceObject', this.folder)
            this.$store.dispatch('filesComponent/setSharingResourceWay', 'distribution-template')
        }
    }
}
