import Table from '@/components/table/Table.vue'
import InvoiceTableRow from './invoice-table-row/InvoiceTableRow.vue'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import Invoice from '@/io-modules/invoices/interfaces/Invoice'
import ApplicationPackage from '@/io-modules/invoices/interfaces/ApplicationPackage'
import TableColumnsMixin from '../../../mixins/TableColumnsMixin'
import ListsMixin from '@/io-modules/invoices/mixins/ListsMixin'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { defineComponent, nextTick, PropType } from 'vue'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { mapActions } from 'pinia'
import ListingTotals from '../../../interfaces/invoices-list/ListingTotals.ts'
import FeatureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'

export default defineComponent({
    name: 'InvoicesListTable',
    components: {
        Table,
        InvoiceTableRow,
        LoadingScreen,
    },
    props: {
        headers: {
            type: Array as PropType<HeaderTable[]>,
        },
        items: {
            type: Array as PropType<Invoice[]>,
        },
        sortBy: {
            type: String,
            default: 'id',
        },
        hidePlaceholder: {
            type: Boolean,
            default: false
        },
        defineTableHeight: {
            type: Boolean,
            default: true
        },
        lazyLoading: {
            type: Boolean,
            default: true,
        },
        loadingData: {
            type: Boolean,
            default: false
        },
        tableHeight: {
            type: String,
            default: ''
        },
        manageColumns: {
            type: Boolean,
            default: true,
        },
        totals: {
            type: Object as PropType<ListingTotals>
        }
    },
    mixins: [TableColumnsMixin, ListsMixin, FeatureFlagsMixin],
    emits: ['onRemoveAppPackage', 'loadData'],
    data () {
        return {
            tableTop: 168,
            timeoutId: null,
            prevScrollTop: null,
        }
    },
    computed: {
        mappedTotals (): object {
            return {
                type: this.$t('Total'),
                sequence_number: this.$t('Total'),
                gross_billed_this_period: this.totals.gross_this_period,
                net_billed_this_period: this.totals.net_this_period,
                gross_total_this_period: this.totals.gross_this_period
            }
        },

        isFFTotalsOn (): boolean {
            return this.isFeatureEnabled(FeatureFlagsConsts.INVOICES_LIST_TOTALS, false)
        }
    },
    watch: {
        items: {
            handler (): void {
                this.setupTableHeight()
            },
            deep: true

        },
        headers: {
            handler (): void {
                this.setupTableHeight()
            },
            deep: true
        },
        isLoading (value: boolean): void {
            if (!value) {
                this.setupTableHeight()
            } else {
                this.prevScrollTop = 0
                this.$refs.tableWrapper.scrollTop = 0
            }
         },
    },
    async mounted () {
        if (this.manageColumns) {
            await this.updateInvoicesColumnsSettings()
            await nextTick()
            this.setupInvoicesColumnsLeft()
        }

        if (this.lazyLoading && this.$refs.tableWrapper) {
            this.setupTableHeight()
            window.addEventListener('resize', this.setupTableHeight)
            this.$refs.tableWrapper.addEventListener('scroll', this.handleScroll)
        }
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.setupTableHeight)
        if (this.lazyLoading && this.$refs.tableWrapper) {
            this.$refs.tableWrapper.removeEventListener('scroll', this.handleScroll)
        }
    },
    methods: {
        ...mapActions(invoicesStore, ['nextListPage', 'setSorting']),

        onRemoveAppPackage (item: ApplicationPackage): void {
            this.$emit('onRemoveAppPackage', item)
        },

        async setupTableHeight (): Promise<void> {
            await this.$nextTick()
            if (this.tableHeight && this.$refs.tableWrapper) {
                this.$refs.tableWrapper.style.maxHeight = this.tableHeight
                return
            }

            if (this.defineTableHeight && this.$refs.tableWrapper) {
                const pageHeader = document.querySelector('.io-page-header')
                this.tableTop = this.$refs.tableWrapper?.offsetTop
                const tableWrapperHeight = window.innerHeight - this.tableTop - pageHeader.clientHeight - 32
                if (this.$refs.tableWrapper.scrollHeight + 30 > tableWrapperHeight && this.$refs.tableWrapper.style) {
                    this.$refs.tableWrapper.style.height = `${ tableWrapperHeight }px`
                }
            }
        },

        handleScroll (event: Event): void {
            clearTimeout(this.timeoutId)
            this.timeoutId = setTimeout(() => {
                if (
                    event.target.scrollTop > 0 &&
                    Math.ceil(event.target.scrollTop) !== Math.ceil(this.prevScrollTop) &&
                    event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1
                ) {
                    this.nextListPage()
                    this.$emit('loadData')
                }

                this.prevScrollTop = Math.ceil(event.target.scrollTop)
            }, 300)
        },

        onSortChange (sort: object): void {
            this.setSorting(sort.by, sort.direction)
            this.$emit('loadData')
        },
    }
})
