export const setMyDocs = function ({ commit, dispatch }, value) {
    commit('SET_MY_DOCUMENTS', value)
}

export const clearDocsArr = function ({ commit, dispatch }, value) {
    commit('CLEAR_DOCS_ARR', value)
}

export const removeFilesComponent = function ({ commit, dispatch }, value) {
    commit('REMOVE_FILES_COMPONENT', value)
}

export const removeAllFilesComponent = function ({ commit, dispatch }) {
    commit('REMOVE_ALL_FILES_COMPONENT')
}
export const addMyDoc = function ({ commit, dispatch }, value) {
    commit('ADD_MY_DOCUMENT', value)
}

export const removeMyDoc = function ({ commit, dispatch }, value) {
    commit('REMOVE_MY_DOCUMENT', value)
}

export const setSharedDocs = function ({ commit, dispatch }, value) {
    commit('SET_SHARED_DOCUMENTS', value)
}

export const addSharedDoc = function ({ commit, dispatch }, value) {
    commit('ADD_SHARED_DOCUMENT', value)
}

export const removeSharedDoc = function ({ commit, dispatch }, value) {
    commit('REMOVE_SHARED_DOCUMENT', value)
}

export const setTrashedDocs = function ({ commit, dispatch }, value) {
    commit('SET_TRASHED_DOCUMENTS', value)
}

export const addTrashedDoc = function ({ commit, dispatch }, value) {
    commit('ADD_TRASHED_DOCUMENT', value)
}

export const restoreTrashedDoc = function ({ commit, dispatch }, value) {
    commit('RESTORE_TRASHED_DOCUMENT', value)
}

export const hardRemoveDoc = function ({ commit, dispatch }, value) {
    commit('HARD_REMOVE_DOC', value)
}

export const hardRemoveMyDoc = function ({ commit, dispatch }, value) {
    commit('HARD_REMOVE_MYDOC', value)
}

export const setFileHover = function ({ commit, dispatch }, value) {
    commit('SET_FILE_HOVER', value)
}

export const setFolders = function ({ commit, dispatch }, value) {
    commit('SET_FOLDERS', value)
}

export const addFolder = function ({ commit, dispatch }, value) {
    commit('ADD_FOLDER', value)
}

export const setFolderFlags = function ({ commit, dispatch }, value) {
    commit('SET_FOLDER_FLAGS', value)
}

export const setFolderName = function ({ commit, dispatch }, value) {
    commit('SET_FOLDER_NAME', value)
}

export const setSearchPaths = function ({ commit, dispatch }, value) {
    commit('SET_SEARCH_PATHS', value)
}

export const setSearchVal = function ({ commit, dispatch }, value) {
    commit('SET_SEARCH_VAL', value)
}

export const setIsSearching = function ({ commit, dispatch }, value) {
    commit('SET_IS_SEARCHING', value)
}


export const setSearchLocal = function ({ commit, dispatch }, value) {
    commit('SET_SEARCH_LOCAL', value)
}

export const setViewType = function ({ commit, dispatch }, value) {
    commit('SET_VIEW_TYPE', value)
}

export const clearBreadcrumbs = function ({ commit, dispatch }, value) {
    commit('CLEAR_BREADCRUMB', value)
}

export const setFolderBreadcrumb = function ({ commit, dispatch }, value) {
    commit('SET_SEARCH_BREADCRUMB', value)
}

export const setSyncAllSharedFiles = function ({ commit, dispatch }, value) {
    commit('SET_SYNC_ALL_SHARED_FILES', value)
}

export const addBreadcrumb = function ({ commit, dispatch }, value) {
    commit('ADD_BREADCRUMB', value)
}

export const removeBreadcrumb = function ({ commit, dispatch }, value) {
    commit('REMOVE_BREADCRUMB', value)
}

export const setLoading = function ({ commit, dispatch }, value) {
    commit('SET_LOADING', value)
}

export const removeMyFolder = function ({ commit, dispatch }, value) {
    commit('REMOVE_MY_FOLDER', value)
}

export const setUploaderEnabled = function ({ commit, dispatch }, value) {
    commit('SET_UPLOADER_ENABLED', value)
}

export const setShowAddFolderModal = function ({ commit, dispatch }, value) {
    commit('SET_SHOW_ADD_FOLDER_MODAL', value)
}

export const setCreateNewFolderInProjectPhotos = function ({ commit, dispatch }, value) {
    commit('CREATE_NEW_FOLDER_IN_PROJECT_PHOTOS', value)
}

export const setOpenUploadDocumentDialog = function ({ commit, dispatch }, value = 0) {
    commit('SET_OPEN_UPLOAD_DOCUMENT_DIALOG', value)
}
export const setSharingResourceObject = function ({ commit, dispatch }, value) {
    commit('SET_SHARING_RESOURCE_OBJECT', value || null)
}

export const setSharingResourceWay = function ({ commit, dispatch }, value) {
    commit('SET_SHARING_RESOURCE_WAY', value || null)
}

export const updateSharingResourceInvitationsIds = function ({ commit, dispatch }, value) {
    commit('UPDATE_SHARING_RESOURCE_INVITATIONS_IDS', value)
}

export const setProjectPhotosFolder = function ({ commit, dispatch }, folder) {
    commit('SET_PROJECT_PHOTOS_FOLDER', folder)
}

export const setCustomColumns = function ({ commit }, value) {
    commit('SET_CUSTOM_COLUMNS', value)
}

export const selectFile = function ({ commit }, value) {
    commit('SELECT_FILE', value)
}

export const setupActionsObject = function ({ commit }, value) {
    commit('SETUP_ACTIONS_OBJECT', value)
}

export const selectFileForAction = function ({ commit }, value) {
    commit('SELECT_FILE_FOR_ACTION', value)
}

export const setLastSelectedForAction = function ({ commit }, value) {
    commit('SET_LAST_SELECTED_FOR_ACTION', value)
}

export const removeSelectedFileForAction = function ({ commit }, value) {
    commit('REMOVE_SELECTED_FILE_FOR_ACTION', value)
}

export const removeAllSelectedFilesForAction = function ({ commit }, value) {
    commit('REMOVE_ALL_SELECTED_FILES_FOR_ACTION', value)
}

export const clearSelectedFiles = function ({ commit }) {
    commit('CLEAR_SELECTED_FILES')
}

export const setClonedDocumentId = function ({ commit }, value) {
    commit('SET_CLONED_DOCUMENT_ID', value)
}

export const setSelectMultipleFiles = function ({ commit }, value) {
    commit('SET_SELECT_MULTIPLE_FILES', value)
}

export const setRenameFolderModalShown = function ({ commit }, value) {
    commit('SET_SHOW_RENAME_FOLDER_MODAL', value)
}

export const addUploadingFile = function ({ commit }, data) {
    commit('ADD_UPLOADING_FILE', data)
}

export const clearUploadingFiles = function ({ commit }, data) {
    commit('CLEAR_UPLOADING_FILES', data)
}

export const changeUploadingFileStatus = function ({ commit }, value) {
    commit('CHANGE_UPLOADING_FILE_STATUS', value)
}
