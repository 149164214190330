<template>
    <div class="io-left-navigation" :class="{ 'io-left-navigation--hidden': !showLeftNavigation }">
        <search-input 
            :startLength="1"
            :placeholder="`${$t('Search')}...`" 
            :loader="false"
            v-model="searchPhase"
        />
        <div v-if="filteredPhases.length" class="io-navigation-items">
            <template v-for="(phase, index) in filteredPhases" :key="index">
                <div 
                    v-if="isCOsInSeparateCategory && hasChangeOrders && 0 === index && index !== firstCOIndex"
                    class="io-navigation-items__section"
                >
                    {{ $t('Original Scope') }}
                </div>
                <div v-if="isCOsInSeparateCategory && hasChangeOrders && index === firstCOIndex" class="io-navigation-items__section">
                    {{ amendmentsContractChangeType ? $t('Executed Amendments') : $t('Executed COs') }}
                </div>

                <div 
                    class="io-navigation-items__item" 
                    :class="{'io-navigation-items__item--active': activePhase === phase.contract_wbs_id}" 
                    @click.prevent="scrollToPhase(phase.contract_wbs_id)"
                >
                    {{ phase.name }}
                </div>
            </template>
        </div>
        <div v-else class="io-no-phases-block">
            <span class="icon-search"></span>
            <span class="io-no-phases-block__title">
                {{ $t('No Results Found') }}
            </span>
            <span class="io-no-phases-block__subtitle">
                {{ $t('Try adjusting your search to find what you are looking for.') }}
            </span>
        </div>
    </div>
</template>

<script lang="ts" src="./LeftNavigation.ts"></script>
<style lang="scss" src="./LeftNavigation.scss" scoped></style>