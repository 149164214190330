<template>
    <Popup class="io-add-payapp io-docs-preview-popup" zindex="25" :width="500" @close="$emit('close')">
        <template #header>
            <div class="file-uploader-item__header">
                <div class="file-uploader-item__header__filename">
                    <div class="file-uploader-item__header__filename__extension">{{ extension }}</div>
                    <div class="file-uploader-item__header__filename__name">{{ fileName }}</div>
                </div>
            </div>
            <div class="io-popup-close" @click.stop="$emit('close')"><i class="icon-cross file-uploader-item__header__cross"></i></div>
        </template>

        <template #default>
            <div class="file-uploader-item">
                <div class="grid grid-cols-12 gap-9">
                    <div class="col-span-12 items-center flex justify-center file-uploader-item__picture">
                        <template v-if="imageError || !file.thumbnail">
                            <div class="file-uploader-item__icon-holder">
                                <span class="icon-file"></span>
                            </div>
                        </template>
                        <img v-else :src="file.thumbnail" @error="imageLoadError" />
                    </div>



                    <div class="col-span-6 left">
                        <span class="file-uploader-item__title uppercase">{{ $t('added by') }}</span>
                        <div class="file-uploader-item__author">
                            <Avatar
                                :employeeMongoId="file.creator_mongo_id"
                                :employeeFullName="file.creator_full_name"
                                size="24"
                            />
                            <span class="file-uploader-item__author__name">{{ file.creator_full_name }}
                                <span class="file-uploader-item__author__company">{{ file.company_name }}</span>
                        </span>
                        </div>
                    </div>

                    <div class="col-span-6 right" v-if="documentData.on_item && documentData.on_item.length > 0">
                        <span class="file-uploader-item__title uppercase">{{ $t('shared with members') }}</span>

                        <div class="file-uploader-item__members">
                            <TileStack>
                                <Avatar
                                    v-for="user in documentData.on_item"
                                    :key="user.id"
                                    :employee-mongo-id="user.id"
                                    :employee-full-name="user.firstname + ' ' + user.lastname"
                                    :size="24"
                                    v-tooltip.top="user.firstname + ' ' + user.lastname"
                                />
                            </TileStack>
                        </div>
                    </div>

                    <div class="col-span-12 file-uploader-item__mid-details">
                        <span class="file-uploader-item__title">File Details</span>
                    </div>

                    <div class="col-span-6 left file-uploader-item__details details__left">
                        <span>Type</span>
                        <span>Size</span>
                        <span>Created</span>
                        <span>Uploaded</span>
                    </div>

                    <div class="col-span-6 right file-uploader-item__details details__right">
                        <span class="uppercase file-uploader-item__title">{{ extension }}</span>
                        <span class="uppercase file-uploader-item__title">{{ $filters.fileSize(file.file_size) }}</span>
                        <span class="uppercase file-uploader-item__title">{{
                                $filters.parseDateTime(file.created_at)
                            }}</span>
                        <span class="uppercase file-uploader-item__title">{{
                                $filters.parseDateTime(file.created_at)
                            }}</span>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="io-popup-buttons">
                <a class="io-btn io-btn-light" @click.stop="$emit('close')">Close</a>
            </div>
        </template>
    </Popup>
</template>
<script src="./InfoPopup.ts"/>
<style lang="scss" src="./InfoPopup.scss" scoped/>
