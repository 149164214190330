import { defineComponent, PropType } from "vue";
import Button from '@/interfaces/components/placeholder/Button'

export default defineComponent({
    props: {
        actionsRowButtons: {
            type: Array as PropType<Button[]>,
        },
        colspan: {
            type: Number,
        },
    },
})