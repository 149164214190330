import { RouteLocationRaw } from 'vue-router'
import { defineStore } from 'pinia'

export type Breadcrumb = {
    text: string,
    to?: RouteLocationRaw,
    projId?: string | string[],
}

export type Replacement = {
    find: string,
    replace: Breadcrumb,
}

export const useBreadcrumbsStore = defineStore('breadcrumbs', {
    state: () => ({
        breadcrumbs: [] as Breadcrumb[],
    }),
    actions: {
        set (breadcrumbs: Breadcrumb[]): void {
            this.breadcrumbs = breadcrumbs
        },
        push (breadcrumb: Breadcrumb): void {
            this.breadcrumbs.push(breadcrumb)
        },
        pop (): void {
            this.breadcrumbs.pop()
        },
        replace (replacement: Replacement): void {
            const index = this.breadcrumbs.findIndex((breadcrumb) => {
                return breadcrumb.text === replacement.find
            })

            if (index !== -1) {
                this.breadcrumbs.splice(index, 1, replacement.replace)
            }
        },
        empty (): void {
            this.breadcrumbs = []
        },
    },
})
