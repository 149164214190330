import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import SinglePunch from './single-punch/SinglePunch.vue'
import AddPunch from './add-punch/AddPunch.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'

export default defineComponent({
    components: {
        SinglePunch,
        AddPunch,
        IOPlaceholder,
    },
    props: {
        searchValue: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
            selectedAnnotation: 'getSelectedAnnotation',
        }),
        isAddingAnnotation (): boolean {
            return Boolean(this.selectedAnnotation &&
                this.selectedAnnotation.xfdf &&
                this.selectedAnnotation.type === RightSideBar.PUNCH)
        },
        punches (): unknown[] {
            return this.model.annotations.punch_v2
                .filter((annotation) => annotation.punch_v2.title.toLowerCase().includes(this.searchValue))
                .reverse()
        },
    },
    watch: {
        selectedAnnotation: {
            handler (newVal: unknown): void {
                if (newVal?.type === RightSideBar.PUNCH) {
                    this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', RightSideBar.PUNCH)
                }
            },
        },
    },
    methods: {
        getPunchNumber (punchId: string): number {
            return this.model.annotations?.punch.findIndex((annotation: unknown) => annotation.id === punchId) + 1
        },
        annotationDeselected (): void {
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: '',
                type: RightSideBar.DEFAULT,
            })
        },
    },
})
