import Resource from '@/io-modules/approval-workflows/enums/Resource'
import i18n from '@/base/i18n'
import VueI18n from 'vue-i18n'
import { PopupConfig } from '@/layouts/offcanvas/popup-alert/PopupAlert'

class SubmitToClientConfirmationService {
    static i18n: typeof VueI18n = i18n

    public static getPopupAlertData (resourceType: Resource, callback: () => void, cancelCallback: () => void): PopupConfig {
        return {
            title: this.getTitle(resourceType),
            caption: this.getCaption(resourceType),
            icon: 'icon-send-2',
            sessionKey: this.getSessionKey(resourceType),
            disabledInSessionCallback: callback,
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.getPrimaryButtonText(resourceType),
                    class: 'io-btn-primary',
                    saveDisabledInSession: true,
                    loaderOnAction: false,
                    action: callback,
                },
            ],
        }
    }

    private static getTitle (resourceType: Resource): string {
        return this.i18n.global.t('Are you sure you want to submit this resource to the client?', {
            resource: this.getResourceName(resourceType),
        }).toString()
    }

    private static getCaption (resourceType: Resource): string {
        return this.i18n.global.t('Selecting “action” will notify the client that this resource is ready for their review and approval.', {
            action: this.getPrimaryButtonText(resourceType),
            resource: this.getResourceName(resourceType),
        }).toString()
    }

    private static getSessionKey (resourceType: Resource): string {
        return {
            [Resource.BUDGET_APPROVAL]: 'budget-submit-to-client',
            [Resource.BUDGET_CHANGES]: 'budget-change-submit-to-client',
            [Resource.CONTRACT]: 'contract-submit-to-client',
            [Resource.CHANGE_ORDER]: 'change-order-submit-to-client',
            [Resource.INVOICE]: 'invoice-submit-for-funding',
        }[resourceType]
    }

    private static getResourceName (resourceType: Resource): string {
        return {
            [Resource.BUDGET_APPROVAL]: this.i18n.global.tc('Budget'),
            [Resource.BUDGET_CHANGES]: this.i18n.global.tc('Budget Change'),
            [Resource.CONTRACT]: this.i18n.global.tc('Contract'),
            [Resource.CHANGE_ORDER]: this.i18n.global.tc('Change Order'),
            [Resource.INVOICE]: this.i18n.global.tc('Invoice'),
        }[resourceType]
    }

    private static getPrimaryButtonText (resourceType: Resource): string {
        if (Resource.INVOICE === resourceType) {
            return this.i18n.global.tc('Yes, Submit for Funding')
        }

        return this.i18n.global.tc('Yes, Submit to Client')
    }
}

export default SubmitToClientConfirmationService
