<template>
    <filterSection :title="title" :tooltipText="tooltipText">
        <div v-click-outside="closeDropdown" class="io-filter-custom-multiselect" :class="{ 'io-active': dropdownExpanded }">
            <div class="io-filter-custom-multiselect__input" :class="{ 'io-disabled': disabled }" @click="toggleDropdown">
                <input ref="searchInput" type="text" class="io-filter-custom-multiselect__search" v-model="searchKeyword" @click.stop/>
                <div class="io-filter-custom-multiselect__text"><i class="fal fa-search"></i> {{ selectedOptions }}</div>
                <i class="fas fa-angle-down"></i>
            </div>

            <transition name="pill-dropdown-fade">
                <div v-if="dropdownExpanded" class="io-filter-custom-multiselect__options">
                    <div v-if="!filteredOptions.length" class="io-no-items">{{ $t('Oops! No elements.') }}</div>

                    <template v-else>
                        <div v-for="(option, key, index) in filteredOptions" :key="option.id" class="io-option">
                            <Checkbox
                                :key="key"
                                labelText=""
                                :checkboxValue="option.id"
                                :inputName="index"
                                v-model="checkboxesValue"
                            />
                            <div v-if="usersVariant" class="io-user" @click="selectOption(option.id)">
                                <Avatar
                                    :employeeMongoId="option.id"
                                    :employeeFullName="option.full_name"
                                    :showFullName="false"
                                    size="sm"
                                    :type="avatarType"
                                />
                                <div class="io-user__data">
                                    <div class="io-user__name">{{ option[labelName] }}</div>
                                    <div v-if="option.company_name" class="io-user__position">{{ option.company_name }}</div>
                                </div>
                            </div>
                            <div v-else class="io-option__name">
                                {{ option.name }}
                            </div>
                        </div>
                    </template>
                </div>
            </transition>
        </div>
    </filterSection>
</template>

<script lang="ts" src="./FilterCustomMultiselectBic.ts"></script>
<style lang="scss" src="./FilterCustomMultiselectBic.scss" scoped></style>
