import Popup from '@/components/popup/Popup.vue'
import cachedListMixin from '@/mixins/cachedListMixin'
import { mapGetters, mapState } from 'vuex'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import moment from 'moment'
import stepInit from './parts/init.vue'
import InvoiceSurveyMixin from '@/modules/projects/modules/apps/common/pay-apps/mixins/InvoiceSurveyMixin'

export default {
    components: {
        Popup
    },
    data () {
        return {
            loading: true,
            loadingButton: false,
            activeStepIndex: 0
        }
    },

    mixins: [cachedListMixin, isLoggedMixin, InvoiceSurveyMixin],
    computed: {
        ...mapGetters('payapps', {
            isInvoiceMy: 'modalAddIsInvoiceMy',
            isInvoiceVendor: 'modalAddIsInvoiceVendor',
            isInvoiceNonContracted: 'modalAddIsInvoiceNonContracted'
        }),

        ...mapState('project', {
            projectObj: state => state.projectObj
        }),
        ...mapState('payapps', {
            invoiceType: (state) => state.modalInvoice.data.type,
            invoiceData: (state) => state.modalInvoice.data,
            payapp: (state) => state.payApp,
            periods: (state) => state.periods
        }),

        steps () {
            const steps = []
            steps.push({
                id: 'init',
                component: stepInit,
                validation: true,
                allowNextStep: this.appAvail([this.appTypes.TYPE_DEV, this.appTypes.TYPE_OWNER])
            })
            return steps
        },

        /**
         *
         * @return {number}
         */
        availableStepsLength () {
            return this.steps.length
        },

        /**
         * Get current step
         */
        activeStep () {
            return this.steps[this.activeStepIndex]
        },

        stepIsLast () {
            return this.activeStepIndex === this.availableStepsLength - 1
        },

        stepIsFirst () {
            return this.activeStepIndex === 0
        },

        /**
         * Contract id for API request
         * @return {*}
         */
        contractId () {
            return this.modeVendor ? this.invoiceData.contract_id : this.projectObj._contract_id
        },

        /**
         *
         * @return {boolean}
         */
        modeVendor () {
            return !this.isInvoiceMy
        }
    },
    methods: {
        /**
         * @returns {Promise<boolean>}
         */
        async validateForm () {
            const passesValidation = await this.$refs[this.activeStep.id].validate()

            if (!passesValidation) {
                this.showNotification('error', 'Please fill all required fields.')
                return false
            }

            if (this.invoiceData.start_date > this.invoiceData.end_date) {
                this.showNotification('error', 'Start Date is after End Date')
                return false
            }

            return true
        },
        /**
         * Go to next/prev step
         */
        async goStepNext () {
            if (!this.stepIsLast) {
                if (this.activeStep.validation) {
                    const passesValidation = await this.validateForm()

                    if (!passesValidation) {
                        return false
                    }
                }
                this.activeStepIndex += 1
            }
        },
        goStepPrev () {
            if (!this.stepIsFirst) {
                this.activeStepIndex -= 1
            }
        },

        /**
         *
         * @return {Promise<void>}
         */
        async init () {
            this.loading = true
            const result = await this.$store.dispatch('payapps/getNewModalInvoiceData', this.projectObj)
            await this.$store.dispatch('payapps/loadProviders')
            if (result !== true) {
                this.showNotification('error', 'Error occurred during loading data', result)
            } else {
                this.loading = false
            }
        },

        /**
         *
         * @return {Promise<void>}
         */
        async addInvoice () {
            const passesValidation = await this.validateForm()

            if (!passesValidation) {
                return false
            }

            this.loadingButton = true

            try {
                const requestData = this.getRequestData()
                const { data } = await this.$api.realJson('/payapps/pay-app/add', requestData)
                this.$store.dispatch('notification/setShow', {
                    type: 'success',
                    text: 'Added'
                })
                this.loadingButton = false
                this.closeModal()
                if (this.$route.meta.newInvoices) {
                    this.$emit('invoiceAdded', requestData)
                }
                this.$router.push({ name: 'payapps-invoice-details', params: { id: data.created.pay_app_id } })

                setTimeout(this.initDelightedSurvey,120000)

            } catch (exception) {
                let message = 'Error occurred during saving'

                if (exception.response !== undefined && exception.response.status === 422) {
                    message = Object
                        .values(exception.response.data.errors)
                        .join(' ')
                }

                this.showNotification('error', message, exception)
                this.loadingButton = false
            }
        },

        /**
         * Prepare data for request
         * @return {{end_date: string, billing_period: (default.methods.billing_period.id|null), provider_invoice_id: (default.methods.invoice_id|null), invoice_date: string, start_date: string}}
         */
        getRequestData () {
            return {
                type: this.invoiceData.type,
                sub_type: this.invoiceData.subType,
                project: this.projectObj.id,
                id: this.contractId,
                value: this.invoiceData.value,
                ...Boolean(this.invoiceData.budget_type) ? {
                    budget_type: this.invoiceData.budget_type
                } : [],
                provider_invoice_id: this.invoiceData.invoice_id,
                vendor: this.invoiceData.vendor,
                category: this.invoiceData.category,
                invoice_date: this.invoiceData.invoiceDate
                    ? moment(this.invoiceData.invoiceDate).format('YYYY-MM-DD')
                    : null,
                start_date: this.invoiceData.start_date
                    ? moment(this.invoiceData.start_date).format('YYYY-MM-DD')
                    : null,
                end_date: this.invoiceData.end_date
                    ? moment(this.invoiceData.end_date).format('YYYY-MM-DD')
                    : null,
                billing_period: this.invoiceData.billing_period,
                invoice_expense_type: this.invoiceData.invoice_expense_type,
            }
        },


        /**
         * Clode modal
         */
        closeModal () {
            this.$store.dispatch('payapps/modalInvoiceDisable')
        }
    },

    beforeMount () {
        this.init()
    }
}
