/**
 * @deprecated
 * use IOTextarea instead
 */

import { defineComponent } from 'vue'
import IOLabel from '@/components/atoms/IOLabel/IOLabel.vue'

export default defineComponent({
    name: 'LimitedTextarea',

    components: {
        IOLabel,
    },

    props: {
        maxLength: { type: Number },
        editable: { type: Boolean, default: true },
        name: { type: String, default: '' },
        classname: { type: String, default: '' },
        placeholder: { type: String, default: '' },
        error: { type: Boolean, default: false },
        upperCounter: { type: Boolean, default: false },
        modelValue: { type: String, default: '' },
        dynamicHeight: { type: Boolean, default: false },
        height: { type: String },
        label: { type: String, default: undefined },
        labelType: { type: String, default: undefined },
        required: { type: Boolean, default: false },
        id: { type: String, default: undefined },
    },

    data () {
        return {
            focused: false as boolean
        }
    },

    computed: {
        currentLength (): string | number {
            return this.modelValue?.length ?? 0
        },
        value: {
            get (): string {
                return this.modelValue
            },
            set (value: string): void {
                this.updateValue(value)
            }
        },
    },

    mounted () {
        this.updateValue(this.value)
    },

    methods: {
        setFocused (focused: boolean): void {
            this.focused = focused
        },
        updateValue (value: string): void {
            const { textarea } = this.$refs

            this.$emit('update:modelValue', value)

            if (value) {
                if (this.dynamicHeight) {
                    textarea.style.height = this.height || '50px'
                    textarea.style.height = textarea.scrollHeight + 'px'
                }
            }

            if (!value && this.dynamicHeight) {
                textarea.style.height = this.height || '50px'
            }
        },
    }
})
