<template>
    <div
        class="io-design-system io-search-input-container"
        v-if="
            (total > 0 && searchKeyword.length === 0) ||
                searchKeyword.length > 0 ||
                show
        "
        :style="searchInputWidth"
    >
        <input
            ref="searchInputNode"
            type="text"
            class="io-search-input-container__input"
            :class="{ 'io-search-input-container__input--icon-left': 'left' === iconPosition }"
            :placeholder="computedPlaceholder"
            :disabled="searchDisabled"
            v-model.trim="searchKeyword"
            @input="searchChanged"
        />
        <i
            class="icon-search io-search-input-container__icon-search"
            :class="iconPosition"
            :style="`color: ${iconColor}`"
            v-if="searchKeyword.length === 0"
        ></i>
        <i
            class="icon-cross io-search-input-container__icon-clear"
            :class="iconPosition"
            :style="`color: ${iconColor}`"
            v-show="searchKeyword.length > 0 && !showLoader"
            @click="clearInput"
        ></i>
        <i
            class="fas fa-spin fa-spinner io-icon-spin"

            v-show="loader && searchKeyword.length > 0 && showLoader"
            :class="iconPosition, {
                'io-is-fetching': searchKeyword.length > 0 && showLoader
            }"
        ></i>
    </div>
</template>

<script lang="ts" src="./SearchInput.ts"></script>

<style lang="scss" src="./SearchInput.scss" scoped></style>
