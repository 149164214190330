<template>
    <PopupModal
        :customClass="'io-invite-modal__popup io-' + this.activeStep"
        :width="modalWidth"
        :fullScreen="isFullScreenModal"
        :zIndex="modalZIndex"
    >
        <template #header>
            <div class="io-popup-modal__icon">
                <i class="icon-user-plus"></i>
            </div>
            <div class="io-popup-modal__title">
                {{ modalTitle }}
            </div>
            <div
                class="io-popup-modal__close"
                @click.stop="onClosePopup()"
            >
                <i class="icon-cross"></i>
            </div>
        </template>

        <template #default>
            <div
                class="io-invite-modal__content-listing"
                :class="{ 'io-not-allow-info-section': !stepIsNotForAddingData }"
            >
                <div
                    v-show="showSearchInput"
                    class="io-invite-modal__search-wrapper"
                >
                    <SearchInputNew
                        ref="searchInput"
                        :searchVal="search"
                        :placeholder="searchPlaceholder"
                        :loader="false"
                        :searchDisabled="isLoading"
                        @searchChanged="onSearchChange"
                    />
                </div>

                <template v-if="showSelectedContactAlert">
                    <div class="io-invite-modal__alert-box">
                        <AlertBox color="yellow" icon="icon-info">
                            <p>
                                {{ $t('You have already selected') }}
                                <b>{{ selectedContactName }}</b>
                                {{ $t('for this item.') }}
                            </p>
                            <p>{{ $t('Selecting another person will automatically deselect') }} <b>{{ selectedContactName }}</b>.</p>
                        </AlertBox>
                    </div>
                </template>

                <template v-if="activeStep === StepsList.ACTIONS">
                    <ActionsView
                        :componentKey="componentKey"
                        :allowToSave="allowToSave"
                    />
                </template>

                <template v-else-if="activeStep === StepsList.ADD_CONTACT_DETAILS">
                    <AddContactDetails :componentKey="componentKey" :allowedClientsTypes="allowedClientsTypes" />
                </template>

                <template v-else>
                    <div class="io-invite-modal__content" :class="{ 'io-selecting-type': isAddWorkspaceTypeStep }">
                        <template v-if="activeStep === StepsList.MATCHED_ACCOUNTS">
                            <ContactFound
                                :componentKey="componentKey"
                                :isAddingNewContact="isAddingNewContact"
                            />
                        </template>

                        <template v-else-if="activeStep === StepsList.MATCHED_DOMAINS">
                            <WorkspaceFound
                                :allowToSave="allowToSave"
                                :componentKey="componentKey"
                            />
                        </template>

                        <template v-else-if="activeStep === StepsList.NOT_FOUND && isSearchValidEmail && isAddingAllowed">
                            <UserCompanyNotFound :componentKey="componentKey"/>
                        </template>

                        <template v-else-if="activeStep === StepsList.ADD_WORKSPACE_TYPE">
                            <WorkspaceTypeOffSystemCompany
                                :componentKey="componentKey"
                                :preselectedWorkspaceType="preselectedWorkspaceType"
                            />
                        </template>

                        <template v-else-if="activeStep === StepsList.SELECT_WORKSPACE_TYPE">
                            <WorkspaceTypeForTheNewContact
                                :componentKey="componentKey"
                                :preselectedWorkspaceType="preselectedWorkspaceType"
                            />
                        </template>

                        <template v-else>
                            <div
                                v-if="selectedContactsList && selectedContactsList.length"
                                class="io-invite-modal__section"
                            >
                                <Accordion
                                    icon="icon-chevrons-down"
                                    :iconColor="'accent'"
                                    :title="onItemAccordionTitle"
                                    :open="true"
                                    @toggle-open="onOnItemAccordionToggle"
                                >
                                    <template #header-right>
                                        <p>{{ selectedItemsLabel }}</p>
                                        <StatusPill
                                            :text="selectedContactsList.length.toString()"
                                            :variant="'info'"
                                        />
                                    </template>
                                    <template #default>
                                        <SingleContact
                                            v-for="(item, index) in selectedContactsList"
                                            :key="index + item.id"
                                            :item="item"
                                            :showActions="!isAddingNewContact"
                                            :hideRemoveInvitationButton="hideRemoveInvitationButton"
                                            :personShowSaveOnlyAction="personShowSaveOnlyAction"
                                            :savedListType="true"
                                            :allow-to-remind="allowToRemind"
                                            @saveContact="onSaveContact"
                                            @inviteContact="onInviteContact"
                                            @removeSelected="onRemoveSelected"
                                            @inviteSavedContact="onInviteSavedContact"
                                            @invitationRemind="onInvitationRemind"
                                        />
                                    </template>
                                </Accordion>
                            </div>

                            <Tabs
                                v-if="isTabsActive"
                                placement="right"
                                :tabs="contactsListsTabs"
                                :active-tab="contactsListsSelectedTab"
                                @click="onContactsListsTabChange"
                            />

                            <div
                                v-if="showPlaceholder"
                                class="io-invite-modal__placeholder"
                            >
                                <div class="io-invite-modal__illustration">
                                    <i class="io-invite-modal__icon far fa-search"/>
                                </div>

                                <div class="io-invite-modal__placeholder-title">
                                    {{ placeholderTitle }}
                                </div>

                                <div class="io-invite-modal__placeholder-text">
                                    {{ placeholderSubTitle }}
                                </div>

                                <div
                                    v-if="placeholderAdditionalText"
                                    class="io-invite-modal__placeholder-text"
                                >
                                    {{ placeholderAdditionalText }}
                                </div>
                            </div>

                            <template v-else-if="myContactsList && myContactsList.length">
                                <div class="io-invite-modal__section">
                                    <div v-if="isAnExistingContact" class="io-invite-modal__section-header io-info-bg">
                                        <p>{{ $t('This Contact is already on your contact list.') }}</p>
                                    </div>

                                    <div
                                        v-if="isSingleTabModal"
                                        class="io-invite-modal__section-header"
                                    >
                                        <p>{{ contactsListLabel }}</p>

                                    </div>

                                    <template v-for="(item, index) in myContactsList" :key="index">
                                        <SingleContact
                                            :item="item"
                                            :showActions="!isAddingNewContact"
                                            :allowToSelectItems="allowToSelectItems"
                                            :allowToSave="allowToSave"
                                            :allowToSaveOnly="allowToSaveOnly"
                                            :personShowSaveOnlyAction="personShowSaveOnlyAction"
                                            :savedListType="false"
                                            :allow-to-remind="allowToRemind"
                                            @saveContact="onSaveContact"
                                            @inviteContact="onInviteContact"
                                            @setAddWorkspaceTypeStepSaveAction="onSetAddWorkspaceTypeStepSaveAction"
                                            @setAddWorkspaceTypeStepSendAction="onSetAddWorkspaceTypeStepSendAction"
                                        />
                                    </template>

                                    <div v-if="isSingleContactInfoVisible" class="io-invite-modal__section-info">
                                        <div class="io-invite-modal__section-info-title">
                                            {{ $t('Didn\'t find who you are looking for?') }}
                                        </div>
                                        <div class="io-invite-modal__section-info-text">
                                            {{ $t('Please get in touch with your Workspace Administrator.') }}
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-if="isLoading">
                                <p class="io-invite-modal__fetching">Fetching data...</p>
                            </template>
                        </template>
                    </div>
                </template>
            </div>
            <div
                v-if="stepIsNotForAddingData"
                class="io-invite-modal__content-info"
            >
                <div class="io-main-header">
                    {{ $t('Manage your contacts with three simple actions:') }}
                </div>
                <div class="io-invite-modal__text-section">
                    <div class="io-inner-header">
                        {{ $t('Add') }}
                        <StatusPill
                            v-if="personShowSaveOnlyAction"
                            :text="disabledText"
                            :variant="'gray-light'"
                            size="small"
                        />

                    </div>
                    <div class="io-inner-text">{{ $t('Collaborate with existing platform users by adding them to your item, enabling seamless teamwork.') }}</div>
                </div>
                <div class="io-invite-modal__text-section">
                    <div class="io-inner-header">
                        {{ $t('Invite') }}
                        <StatusPill
                            v-if="personShowSaveOnlyAction"
                            :text="disabledText"
                            :variant="'gray-light'"
                            size="small"
                        />
                    </div>
                    <div class="io-inner-text">{{ $t('Invite non-users to join a free INGENIOUS.BUILD workspace and collaborate on projects and specific items you assign them too.') }}</div>
                </div>
                <div  class="io-invite-modal__text-section">
                    <div class="io-inner-header">
                        {{ $t('Bookmark') }}
                        <StatusPill
                            v-if="!(allowToSave || personShowSaveOnlyAction)"
                            :text="disabledText"
                            :variant="'gray-light'"
                            size="small"
                        />
                    </div>
                    <div class="io-inner-text">{{ $t('Privately bookmark contacts for personal reference without notifying the users.') }}</div>
                </div>
            </div>
        </template>
        <template #footer>
            <template v-if="showSearchInput">
                <IOButton variant="secondary" @click="onClosePopup()">{{ $t('Cancel') }}</IOButton>
            </template>

            <IOButton
                v-if="activeStep === StepsList.MATCHED_DOMAINS"
                :disabled="!isWorkspaceSelected"
                @click="setAddContactStep()"
            >
                {{ $t('Next') }}
            </IOButton>

            <template v-else-if="activeStep === StepsList.NOT_FOUND && isAddingAllowed">
                <IOButton
                    v-if="allowToSave && !personShowSaveOnlyAction"
                    :disabled="!isSearchValidEmail"
                    @click="setActionsStep()"
                >
                    {{ $t('Next') }}
                </IOButton>

                <IOButton
                    v-else
                    :disabled="!isSearchValidEmail"
                    @click="setAddContactStep()"
                >
                    {{ $t('Next') }}
                </IOButton>
            </template>

            <template v-else-if="activeStep === StepsList.ADD_CONTACT_DETAILS">
                <IOButton variant="secondary" @click="onBackClickFromAddContactDetailsStep()">{{ $t('Back') }}</IOButton>
                <IOButton :disabled="!isFormValid" @click="actionOnClick()">{{ actionText }}</IOButton>
            </template>

            <template v-else-if="activeStep === StepsList.SELECT_WORKSPACE_TYPE">
                <IOButton variant="secondary" @click="setAddContactStep()">{{ $t('Back') }}</IOButton>
                <IOButton :disabled="!isWorkspaceTypeSelected" @click="onSelectWorkspaceTypeNextClick()">
                    {{ actionText }}
                </IOButton>
            </template>

            <template v-else-if="activeStep === StepsList.ACTIONS">
                <IOButton variant="secondary" @click="onBackClickFromActionsStep()">{{ $t('Back') }}</IOButton>

                <IOButton @click="setAddContactStep()">{{ $t('Next') }}</IOButton>
            </template>

            <template v-else-if="activeStep === StepsList.MATCHED_ACCOUNTS">
                <template v-if="selectedAction === ContactActions.SEND_INVITE">
                    <IOButton @click="onInviteAddedContact()">{{ $t('Send Invitation') }}</IOButton>
                </template>

                <template v-else>
                    <IOButton @click="onSaveAddedContact()">{{ $t('Save') }}</IOButton>
                </template>
            </template>

            <template v-else-if="activeStep === StepsList.ADD_WORKSPACE_TYPE">
                <IOButton variant="secondary" @click="removeSelectedContactAndWorkspaceType()">{{ $t('Back') }}</IOButton>
                <IOButton :disabled="!isWorkspaceTypeSelected" @click="saveWorkspaceTypeForOffSystemCompany()">{{ $t('Next') }}</IOButton>
            </template>

            <template v-else-if="activeStep === StepsList.SAVE_TO_BIDDERS_LIST">
                <IOButton :disabled="isSaveChangesButtonDisabled" @click="submit()">{{ $t('Submit to bidders') }}</IOButton>
            </template>

            <IOButton v-else :disabled="isSaveChangesButtonDisabled" @click="submit()">{{ $t('Save changes') }}</IOButton>

        </template>
    </PopupModal>
</template>

<script lang="ts" src="./InviteModal.ts"></script>
<style src="./InviteModal.scss" lang="scss" scoped></style>
