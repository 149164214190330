<template>
    <div
        class="file-uploader-item"
        :class="{ 'io-selected': isSelectedForAction, 'io-is-inside-modal': showModalVersion }"
        :ref="`item-html-${idx}`"
        @click="toggleSelectForAction"
    >
        <div class="file-uploader-item__header">
            <div class="file-uploader-item__header__filename" @click="toggleSelectForAction">
                <div v-tooltip.bottom="{ content: file.file_name, visible: file.file_name.length > 21 }" class="file-uploader-item__header__filename__name">{{ textToShow }}</div>
            </div>
            <div v-click-outside="hideMenu" class="file-uploader-item__header__actions">
                <i v-tooltip.bottom="{ content: $t('More actions'), visible: !isMenuVisible }" class="icon-more-vert-2" @click.stop="toggleMenu"></i>
                <div :id="'id' + idx" class="file-uploader-item__header__actions__menu" :class="{ 'visible' : isMenuVisible }">
                    <div v-if="!fromTrash && !addToTemporary" @click.stop="openFileInDocuments"><i class="icon-file"></i>{{ $t('Open in Documents') }}</div>
                    <div @click.stop="toggleInfoPopup"><i class="icon-info"></i>{{ $t('Info') }}</div>
                    <a :href="downloadLink()" target="_blank" @click.stop><i class="icon-file-download"></i>{{ $t('Download') }}</a>
                    <template v-if="!toSync && editable">
                        <template v-if="addToTemporary">
                            <div v-if="isAvailableEditableOption('important')" @click.stop="toggleImportant">
                                <i v-if="file.flag === 0" class="far fa-star"></i>
                                <i v-else class="fas fa-star"></i>
                                {{ $t('Important') }}
                            </div>
                            <div v-if="file.is_shareable && !forceShared && isAvailableEditableOption('share')">
                                <i class="far fa-share-alt"></i>
                                {{ $t('Share') }}
                                <div class="io-switch">
                                    <label>
                                        <input name="test" type="checkbox" :checked="file.can_be_shared" @click.stop="onShareChange()"/>
                                        <span class="io-no-text"></span>
                                    </label>
                                </div>
                            </div>
                        </template>
                        <div @click.stop="onItemToTrash(file.file_name)"><i class="icon-trash-2"></i>{{ $t('Delete') }}</div>
                    </template>
                </div>
            </div>
        </div>
        <div class="file-uploader-item__picture">
            <img v-if="file.thumbnail" :src="file.thumbnail" :alt="file.file_name"/>
            <span v-else class="icon-file"></span>
        </div>
        <div class="file-uploader-item__author">
            <Avatar
                :employeeMongoId="file.creator_mongo_id"
                :employeeFullName="file.creator_full_name"
                size="sm"
            />
            <span class="file-uploader-creator">{{ $t('by') }} {{ file.creator_full_name }}</span>

            <Teleport to=".io-main-wrapper">
                <InfoPopup
                    v-if="InfoPopupShown"
                    :file="file"
                    @close="toggleInfoPopup"
                />
            </Teleport>
        </div>
    </div>
</template>

<script src="./FileUploaderGridItem.ts"/>
<style lang="scss" src="./FileUploaderGridItem.scss" scoped/>
