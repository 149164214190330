<template>
	<div class="modal-wrapper" v-if="visible">
		<div class="modal-form">
			<edit-form forcedSource="contact/modal" forcedId="0"></edit-form>
		</div>
	</div>
</template>


<script>
	import { defineAsyncComponent } from 'vue'

    const EditForm = defineAsyncComponent(() => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue'))

    export default {
        mixins: [],
        components: {
            EditForm
        },
        props: {
            visible: {
                default: true
            },
            modal: {
                default: true
            },
            closeOnClickModal: {
                default: true
            }
        }
    }
</script>


<style lang="scss" scoped>
	.modal-wrapper {
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10000;
		.modal-form {
			background: #fff;
			border: 1px solid #ddd;
			padding: 20px;
			position: absolute;
			top: 50%;
			width: 50%;
			min-height: 350px;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 10001;
			.page-title {
				margin: 0 0 24px 0 !important;
				padding: 0 0 0 0 !important;
			}
		}
	}
</style>
