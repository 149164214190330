import { defineComponent, PropType } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import SoVLineItem from '@/io-modules/invoices/interfaces/SoVLineItem'
import Comments from '@/components/comments/Comments.vue'
import SoVTableColumnsMixin from '@/io-modules/invoices/mixins/sov-wbs/SoVTableColumnsMixin.ts'
import CellType from '@/components/table/CellType.ts'
import { debounce } from 'lodash'
import { HeaderTable } from '@/components/table/HeaderTableInterface.ts'

export default defineComponent({
    name: 'AdjustmentsModalRow',
    components: {
        IOModal,
        IOSingleInfoRow,
        Comments,
    },
    props: {
      item: {

      }
    },
    mixins: [SoVTableColumnsMixin],
    data () {
        return {
            CellType
        }
    },
    computed: {
        /**
         * Invoice: 5. Request Revision:
         * Use SoVTableColumnsMixin to calculate values as they have hidden values what should be taken into account for calculations
         */
        columnValues (): HeaderTable[] {
            return [
                {
                    name: this.$t('% of Completed Work'),
                    field: 'percent_of_completed_work',
                    valueType: CellType.InputNumInt,
                    adjust: {
                        showPercentage: true
                    },
                    customValue: (item: SoVLineItem): string => {
                        return this.$filters.formatPercentInt(item.percent_of_completed_work)
                    },
                    placeholder: '0',
                    onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                        item.percent_of_completed_work = value
                        this.updateCurrentApplication(item, value)
                        this.updateRetention(item)
                    }, 500),
                    minValue: 0,
                    maxValue: 100,
                    sign: '%',
                    inputOptions: {
                        numeralPositiveOnly: true
                    }
                },
                {
                    name: this.$t('Current Application'),
                    field: 'current_application',
                    valueType: CellType.InputNumInt,
                    adjust: {
                        showPercentage: true
                    },
                    customValue: (item: SoVLineItem): string | number => {
                        return this.getSumOfTasksValues(item, 'current_application', 'formatProjectCurrencyInt')
                    },
                    onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                        item.current_application = value
                        this.initTaskPercents(item)
                        this.updateRetention(item)
                    }, 500),
                    minValue: (item: SoVLineItem): number => {
                        return this.minAmountToCompleteCurrentApplication(item)
                    },
                    maxValue: (item: SoVLineItem): number => {
                        return this.maxAmountToCompleteCurrentApplication(item)
                    },
                },
                {
                    name: `${ this.$t('Current Retention Percent') } (%)`,
                    valueType: CellType.InputNumInt,
                    field: 'current_retention_percent',
                    customValue: (item: SoVLineItem): string | number => {
                        return this.$filters.formatPercentInt(item.current_retention_percent)
                    },
                    sign: '%',
                    onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                        item[field] = value
                        this.updateRetention(item)
                    }, 500),
                },
                {
                    name: `${ this.$t('Total Retention Amount') } (${ this.getWorkspaceCurrencySymbol() })`,
                    field: 'total_retention_amount',
                    valueType: CellType.InputNumInt,
                    customValue: (item: SoVLineItem): string | number => {
                        return this.getSumOfTasksValues(item, 'total_retention_amount', 'formatProjectCurrencyInt')
                    },
                    onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                        const maxValue = this.maxRetentionTotal(item)
                        item.total_retention_amount = value
                        item.total_retention_percent = this.intGetPercent(item.total_retention_amount, this.totalCompletedStoredAmount(item))
                        item.retention_released = maxValue - item.total_retention_amount
                    }, 500),
                    minValue: (item: SoVLineItem): number => {
                        if (-1 === Math.sign(this.maxRetentionTotal(item))) {
                            return this.maxRetentionTotal(item) / process.env.SCALE_FACTOR
                        } else {
                            return 0
                        }
                    },
                    maxValue: (item: SoVLineItem): number => {
                        if (-1 === Math.sign(this.maxRetentionTotal(item))) {
                            return 0
                        } else {
                            return this.maxRetentionTotal(item) / process.env.SCALE_FACTOR
                        }
                    },
                },
                {
                    name: `${ this.$t('Total Retention Percent') } (%)`,
                    field: 'total_retention_percent',
                    valueType: CellType.InputNumInt,
                    customValue: (item: SoVLineItem): string | number => {
                        return this.$filters.formatPercentInt(item.total_retention_percent)
                    },
                    onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                        item.total_retention_percent = value
                        item.total_retention_amount = ((value / process.env.SCALE_FACTOR)/100) * this.totalCompletedStoredAmount(item)
                        this.retentionReleased(item)
                    }, 500),
                    sign: '%',
                    placeholder: '0',
                    minValue: (item: SoVLineItem): number => {
                        return 0
                    },
                    maxValue: (item: SoVLineItem): number => {
                        return 100 * (this.previousRetentionAmount(item) + this.calculateRetentionAmount(item) + this.materialStoredRetentionAmount(item)) / this.totalCompletedStoredAmount(item)
                    },
                },
                {
                    name: this.$t('Net Current Application'),
                    field: 'net_current_application',
                    customValue: (item: SoVLineItem): string | number => {
                        return this.$filters.formatProjectCurrencyInt(this.netCurrentApplication(item))
                    },
                    editable: (): boolean => {
                        return false
                    },
                }
            ]
        }
    },
    methods: {
        updateValue (header: HeaderTable, data: number | string): void {
            if (header.onValueChange instanceof Function) {
                header.onValueChange(this.item, header.field, data)
            } else {
                this.item[header.field] = data
            }
        },

        cellModelValue (header: HeaderTable): string {
            if (header.field instanceof Function) {
                return this.item[header.field(this.item)]
            }

            return this.item[header.field]
        },

        minValue (header: HeaderTable): number {
            if (header.minValue) {
                return header.minValue instanceof Function ? header.minValue(this.item) : header.minValue
            }

            return null
        },

        maxValue (header: HeaderTable): number {
            if (header.maxValue) {
                return header.maxValue instanceof Function ? header.maxValue(this.item) : header.maxValue
            }

            return null
        },
    }
})
