<template>
    <td class="io-cell-dropdown" @click.stop="onClickDropdown">
        <DropdownButtonNew v-if="newInterface && data" :iconMode="true" fontAwesomeClass="icon-more-vert-2">
            <template v-for="(action, index) in filteredActions" :key="index">
                <div class="io-single-option" @click="emitAction(action.action)">
                    <i :class="action.icon"></i>
                    <span>{{ action.label }}</span>
                </div>
            </template>
        </DropdownButtonNew>

        <DropdownButton v-else-if="data" :iconMode="true" fontAwesomeClass="icon-more-vert-2">
            <template v-for="(action, index) in filteredActions" :key="index">
                <div class="io-single-option" @click="emitAction(action.action)">
                    <i :class="action.icon"></i>
                    <span>{{ action.label }}</span>
                </div>
            </template>
        </DropdownButton>
    </td>
</template>

<script lang="ts" src="./DropdownCell.ts"></script>

<style lang="scss" src="./DropdownCell.scss" scoped/>
