import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'
import { ScheduleTicketsListInterface } from '@/io-modules/lists-templates/schedule-templates/interfaces/ScheduleTicketsListInterface'
import { ScheduleTicketsListRequestDataInterface } from '@/io-modules/lists-templates/schedule-templates/interfaces/ScheduleTicketsListRequestDataInterface'
import { ScheduleTicketInterface } from '@/io-modules/lists-templates/schedule-templates/interfaces/ScheduleTicketInterface'

const scheduleTicketsTemplatesListClient = {
    createScheduleTicketsList (data: ScheduleTicketsListRequestDataInterface): AxiosPromise<ScheduleTicketsListInterface>  {
        return client.post('/schedule-tickets-templates-list', data)
    },
    fetchScheduleTicketsList (listId: string): AxiosPromise<ScheduleTicketsListInterface>  {
        return client.get(`/schedule-tickets-templates-list/${ listId }`)
    },
    updateScheduleTicketsList (listId: string, data: ScheduleTicketsListRequestDataInterface): AxiosPromise<ScheduleTicketInterface>  {
        return client.put(`/schedule-tickets-templates-list/${ listId }`, data)
    },
}

export default scheduleTicketsTemplatesListClient
