<template>
    <div class="io-select-app-package">
        <IOLabel size="medium" type="bold-max" :text="$t('Application Package')" />
        <DropdownButton
            v-if="isEditingMode && invoiceIsDraft"
            v-tooltip.top="{
                content: $t('You don\'t have any application packages created.'),
                visible: !appPackages?.length
            }"
            buttonClass="io-custom-dropdown"
            name=""
            :disabled="!appPackages?.length"
        >
            <template #selectedItem>
                <span v-if="modelValue" class="io-f-row io-f-center">
                    <StatusPill variant="closed-bg" :text="modelValue.sequence_number"/>
                    ({{ getAppPackagePeriod(modelValue) }})
                </span>
                <span v-else></span>
            </template>
            <template v-if="appPackages?.length" #default>
                <div
                    v-for="item in appPackages"
                    :key="item.id"
                    class="io-dropdown-option"
                    @click="selectAppPackage(item)"
                >
                    <StatusPill variant="closed-bg" :text="item.sequence_number"/>
                    ({{ getAppPackagePeriod(item) }})
                </div>
            </template>
        </DropdownButton>
        <div v-else-if="modelValue" class="io-dropdown-option io-option-view">
            <StatusPill variant="closed-bg" :text="modelValue.sequence_number"/>
            {{ (getAppPackagePeriod(modelValue)) }}
        </div>
        <div v-else class="io-option-view">-</div>
    </div>
</template>

<script lang="ts" src="./SelectAppPackage.ts"></script>
<style lang="scss" src="./SelectAppPackage.scss" scoped></style>
