<template>
    <EditMyInvoiceDetails v-if="isEditingMode && isMyInvoice" />
    <EditVendorInvoiceDetails v-if="isEditingMode && isVendorInvoice" />
    <EditNoNContractedInvoiceDetails v-if="isEditingMode && isNonContractedInvoice" />
    
    <div v-if="!isEditingMode && '0' != $route.params.id" class="io-invoice-details-info">
        <InvoiceDetailsInfo />
        <GrossBillingSummary v-if="invoice?.id && !isNonContractedInvoice" />
        <FinancialPanel v-if="invoice?.id && !isNonContractedInvoice" />
        <ContractSummary v-if="invoice?.id && !isNonContractedInvoice" />
    </div>
</template>

<script lang="ts" src="./InvoiceDetails.ts"></script>
<style lang="scss" src="./InvoiceDetails.scss" scoped></style>