<template>
    <ul class="io-grid-view">
        <li
            v-for="(sheet, idx) in sheets"
            :key="sheet.id"
            :style="getCascadeAnimationDelay(idx)"
            class="io-grid-item animate-pop-from-bottom"
            @click="$emit('select', sheet)"
        >
            <div class="io-grid-item__preview" :style="{ backgroundImage: `url(${ sheet.thumbnail })` }" />

            <div class="grow p-16-px bg-light-bg leading-relaxed">
                <div v-tooltip="sheet.page_title" class="text-12-px truncate">{{ sheet.page_title }}</div>

                <div class="text-xs-px text-main-lighten-3">{{ sheet.page_category }}</div>
            </div>
        </li>
    </ul>
</template>

<script lang="ts" src="./SheetsGridView.ts"></script>
<style lang="scss" src="./SheetsGridView.scss" scoped></style>
