<template>
    <transition name="popup" duration="220" appear>
        <div :id="popupID" class="io-popup-component-holder">
            <div class="io-popup io-popup-main" :style="styles">
                <div class="io-popup-header" v-if="hasHeaderSlot">
                    <slot name="header"></slot>
                </div>
                <div class="io-popup-content" :class="{'io-no-padding': !contentPadding}">
                    <slot></slot>
                </div>
                <div class="io-buttons io-f-row io-popup-footer" v-if="hasFooterSlot">
                    <slot name="footer"></slot>
                </div>
            </div>
            <div class="io-popup-outside-mask" @click.stop="closeClickOutside" :style="{'z-index': `${zindex - 1} !important`}"></div>
        </div>
    </transition>
</template>
<script src="./Popup.js"></script>
<style lang="scss" src="./Popup.scss"></style>
