<template>
    <IOModal
        color="yellow"
        :width="650"
        :title="$t('Revision Requested')"
        icon="icon-arrow-swap-rec"
    >
        <template #default>
            <div class="io-invoice-revision-note">
                {{ $t('Revision has been requested by') }}
                <span class="io-invoice-revision-note__company">{{ revisionInfo.created_by }}</span>
                {{ $t('at') }} {{ getTime }} {{ $t('on') }} {{ getDate }}.
            </div>
            <div class="io-invoice-revision-note__note">
                <span>{{ $t('Note') }}</span>
                <p>{{ revisionInfo.note }}</p>
            </div>
        </template>
        <template #footer>
            <IOButton
                variant="secondary"
                @click="$emit('close')"
            >
                {{ $t('Close') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./InvoiceRevisionNoteModal.ts"></script>
<style lang="scss" src="./InvoiceRevisionNoteModal.scss" scoped></style>
