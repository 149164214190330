import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TableSortIcon',
    props: {
        name: { type: String, required: true, default: '' },
        sortBy: { type: String, required: true, default: '' },
        sortDir: { type: String, required: true, default: '' }
    }
})
