<template>
    <filterSection :title="title">
        <template #default>
            <field-date-time
                :editable="true"
                :options="options"
                :show-icon="showIcon"
                v-model="dateValue"
            />
        </template>
    </filterSection>
</template>
<script>

import filterSection from './filterSection.vue'
import fieldDateTime from '@/components/fields/fieldDateTime.vue'
import dateTimeHelper from '@/helpers/dateTime'

export default {
    name: 'FilterDateTimePicker',
    emit: [ 'update:modelValue' ],
    components: {
        filterSection,
        fieldDateTime
    },
    props: {
        title: {
            type: String,
            default: null,
        },
        modelValue: {
            type: [String, Object],
            default: () => ({ start: null, end: null }),
        },
        options: {
            type: Object,
            default: () => ({ formatted: dateTimeHelper.getDateFormat(), 'only-date': true }),
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        dateValue: {
            get () {
                return this.modelValue
            },
            set (val) {
                this.$emit('update:modelValue', val)
            }
        }
    }
}
</script>
