import { defineComponent } from 'vue'
import PageHeader from '@/components/page-header/PageHeader.vue'
import CapitalFundInfo from '@/io-modules/global-funding/components/capital-fund-info/CapitalFundInfo.vue'
import FundingSourcesSummary
    from '@/io-modules/global-funding/components/funding-sources-summary/FundingSourcesSummary.vue'
import {
    FundingSourceFundProjects,
    FundingSourcesFund,
    FundingSourcesProvider,
    FundingSourcesFundSummary, FundingSourceTimeline,
} from '../../interfaces/GlobalFundingInterfaces.ts'
import fundingClient from '../../api-clients/fundingClient'
import Avatar from '@/components/atoms/Avatar/Avatar'
import Table from '@/components/table/Table'
import FundingSourcesProjectRow
    from '@/io-modules/global-funding/components/funding-sources-project-row/FundingSourcesProjectRow'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { mapActions, mapState } from 'pinia'
import { globalFundingStore } from '../../stores/globalFundingStore'
import AddNewCapitalFundModal
    from '@/io-modules/global-funding/components/modals/add-new-capital-fund/AddNewCapitalFundModal.vue'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'
import { Breadcrumb } from '@/store/app/breadcrumbs'
import FundingSourcesTimeline
    from '@/io-modules/global-funding/components/funding-sources-timeline/FundingSourcesTimeline.vue'
import TimelineLogs from '@/interfaces/components/timeline/TimelineLogs'
import { MenuItemsCapitalFunds } from '../../enums/GlobalFundingEnums'
import useLoader from '@/composables/useLoader.ts'

export default defineComponent({
    name: 'CapitalFundDetails',
    components: {
        FundingSourcesTimeline,
        AddNewCapitalFundModal,
        FundingSourcesProjectRow,
        Table,
        Avatar,
        PageHeader,
        CapitalFundInfo,
        FundingSourcesSummary,
    },
    mixins: [breadcrumbsMixin],
    setup () {
        const { loading, load } = useLoader()

        return { loading, load }
    },
    data () {
        return {
            fundId: null,
            fundInfo: null as FundingSourcesFund,
            fundProvider: null as FundingSourcesProvider,
            fundProjects: null as FundingSourceFundProjects,
            fundSummary: null as FundingSourcesFundSummary,
            fundTimeline: null as TimelineLogs[],
            activeTab: MenuItemsCapitalFunds.FUND_PROJECTS,
            menuItemsEnum: MenuItemsCapitalFunds,
            menuItems: [
                {
                    name: this.$t('Projects'),
                    number: MenuItemsCapitalFunds.FUND_PROJECTS,
                },
                {
                    name: this.$t('Timeline'),
                    number: MenuItemsCapitalFunds.FUND_TIMELINE,
                },
            ],
        }
    },
    computed: {
        ...mapState(globalFundingStore, [
            'addNewCapitalFundModal',
        ]),
        tableHeaders (): HeaderTable[] {
            const headers: HeaderTable[] = [
                {
                    text: this.$t('Project Name'),
                    value: 'project_name',
                },
                {
                    text: this.$t('Allocated to Project'),
                    value: 'allocated_amount',
                    align: 'end',
                    sort: (item) => parseFloat(item),
                },
                {
                    text: this.$t('Paid to Date'),
                    value: 'summary.total_paid_to_date_amount',
                    align: 'end',
                    sort: (item) => parseFloat(item),
                },
                {
                    text: this.$t('Remaining Balance'),
                    value: 'summary.remaining_amount',
                    align: 'end',
                    sort: (item) => parseFloat(item),
                },
            ]
            return headers
        },
        breadcrumbs (): Breadcrumb[] {
            if (!this.$route.meta.breadcrumbs) {
                return []
            }
            const breadcrumbs: Breadcrumb[] = this.$route.meta.breadcrumbs as Breadcrumb[]
            return [
                ...breadcrumbs,
                {
                    text: this.fundProvider?.provider.name || '-',
                    to: { name: 'funding-sources-provider-details', params: { id: this.fundProvider?.id } },
                },
                {
                    text: this.fundInfo?.name || '-',
                    to: { name: 'funding-sources-fund-details', params: { id: this.fundId } },
                },
            ]
        },
    },
    watch: {
        breadcrumbs (): void {
            this.setBreadcrumbs(this.breadcrumbs)
        },
    },
    beforeMount () {
        this.fundId = this.$route.params.id
    },
    async mounted () {
        const fundInfoResponse = await fundingClient.getCapitalFundInfo(this.fundId)
        if (fundInfoResponse && fundInfoResponse.data) {
            this.fundInfo = fundInfoResponse.data
        }

        const fundProviderResponse = await fundingClient.getCapitalFundProviderInfo(this.fundId)
        if (fundProviderResponse && fundProviderResponse.data) {
            this.fundProvider = fundProviderResponse.data
        }

        this.load(async () => {
            const fundProjectsResponse = await fundingClient.getCapitalFundProjects(this.fundId)
            if (fundProjectsResponse && fundProjectsResponse.data) {
                this.fundProjects = fundProjectsResponse.data
            }
        })

        await this.requestSummary()
        this.fundTimeline = await this.getFundTimeline()

        this.setBreadcrumbs(this.breadcrumbs)
    },
    unmounted () {
        this.setBreadcrumbs([])
    },
    methods: {
        ...mapActions(globalFundingStore, [
            'setAddNewCapitalFundModal',
        ]),
        changeActiveTab (tab: MenuItemsCapitalFunds): void {
            this.activeTab = tab
        },
        toggleShowNewFundModal (): void {
            this.setAddNewCapitalFundModal({
                show: !this.addNewCapitalFundModal.show,
                editMode: true,
            })
        },
        async requestSummary (): Promise<void> {
            try {
                const response = await fundingClient.getCapitalFundSummary(this.fundId)
                if (response && response.data) {
                    this.fundSummary = response.data
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        async updateFundInfo (changedInfo: FundingSourcesFund): Promise<void> {
            this.fundInfo = changedInfo
            await this.requestSummary()
            this.fundTimeline = await this.getFundTimeline()
        },
        async getFundTimeline (): Promise<TimelineLogs[]> {
            try {
                const fundTimelineResponse = await fundingClient.getCapitalFundTimeline(this.fundId)
                if (fundTimelineResponse && fundTimelineResponse.data) {
                    return fundTimelineResponse.data.items.map((event: FundingSourceTimeline) => ({
                        id: event.id,
                        employee_mongo_id: event.user.id,
                        user: `${ event.user.firstname } ${ event.user.lastname }`,
                        company_name: event.user.company_name,
                        company_type: event.user.company_app_type,
                        created_at: event.created_at,
                        content: event.content.replace(/\n/g, '<br>'),
                    }))
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        showModalOnDelete (): void {
            return this.showPopupAlert({
                title: this.$t('Are you sure you want to delete this fund?'),
                caption: this.$t('This action cannot be undone.'),
                icon: 'icon-trash',
                iconColor: 'var(--error-darker)',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Yes, Delete Fund'),
                        class: 'io-btn-primary',
                        saveDisabledInSession: true,
                        action: this.removeFund,
                    },
                ],
            })
        },
        async removeFund (): Promise<void> {
            try {
                await fundingClient.removeCapitalFund(this.fundId)

                await this.showNotification('success', this.$t('Capital Fund has been removed!'))

                setTimeout(() => {
                    this.$router.push({
                        name: 'funding-sources-provider-details',
                        params: {
                            id: this.fundProvider?.id,
                        },
                    })
                }, 1000)
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
    },
})
