import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'StepSelectContract',
    components: {
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        contracts: {
            type: Array,
            default: () => [],
        },
    },
    data () {
        return {
            contract: null,
        }
    },
    watch: {
        contract: {
            handler (value: Object): void {
                this.$emit('update:modelValue', value)
            },
            immediate: true,
            deep: true
        }
    },
    mounted () {
        this.contract = cloneDeep(this.modelValue)
    },
})
