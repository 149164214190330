import { defineComponent } from 'vue'
import { ReplyInterface } from '@/io-modules/documents/interfaces/ReplyInterface'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import { mapState } from 'pinia'
import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import MentionMixin from '@/io-modules/documents/mixins/MentionMixin'

export default defineComponent({
    name: 'Reply',
    components: { DropdownButtonNew },
    props: {
        replyData: {
            type: Object as () => ReplyInterface,
            required: true
        },
        isParentCommentArchived: {
            type: Boolean
        },
    },
    mixins: [MentionMixin],
    data () {
        return {
            editMode: false,
            editedReply: this.replyData.comment_text
        }
    },
    computed: {
        ...mapState(viewDocumentStore, [
            'counters',
            'uniqueMentionUsers'
        ]),
    },
    methods: {
        onDeleteComment (): void {
            this.$emit('delete-reply', { id: this.replyData.id, isReply: true })
        },
        cancelEditing (): void {
            this.editMode = false
            this.editedReply = this.replyData.comment_text
        },
        saveReply (): void {
            this.$emit('save-reply', { comment_text: this.editedReply, id: this.replyData.id, shared_with: this.shared_with })
            this.editMode = false
        }
    }
})
