<template>
    <PopupModal customClass="io-popup-manual-approval" :width="width" :showLoader="loading" :fullScreen="fullScreen" :scrollable="true">

        <template #header>
            <div class="io-popup-modal__icon">
                <i class="icon-check-circle-2"></i>
            </div>
            <div class="io-popup-modal__title">{{ approveTitleText }} {{ $t(resourceName) }}</div>
            <div class="io-popup-modal__close" @click="closePopup">
                <i class="fal fa-times"></i>
            </div>
        </template>

        <template #default>
            <div class="io-popup-manual-approval__content">
                <template v-if="isNewVersion">
                    <div class="io-popup-modal__title">
                        {{ $t('Are you sure you want to approve this Contract?') }}
                    </div>
                    <div class="io-popup-modal__caption">
                        {{
                            $t('Approving this Contract will finalize the approval process and allow you to send it to your client.')
                        }}
                    </div>
                </template>

                <p v-else-if="descriptionText && !showPhasesControls">{{ $t(descriptionText) }}</p>

                <div class="io-communicate io-f-row io-warning">
                    <div class="io-left io-f-column">
                        <i class="fas fa-exclamation-circle"></i>
                    </div>
                    <div class="io-right-text">
                        <p>
                            {{ $t('You have not configured an approval process for this module.') }}
                            {{ $t('If you would like to create a formal approval process, you can do so now in') }}
                            <router-link :to="{ name: projectSettingsRouting }">
                                {{ $t('Project Settings') }}.
                            </router-link>
                        </p>
                    </div>
                </div>

                <template v-if="showPhasesControls">
                    <FormInputColumn
                        :label="$t('Approval Name')"
                        :required="true"
                        :custom-content="true"
                    >
                        <input v-model.trim="name"/>
                    </FormInputColumn>

                    <PhaseTypeRadio :resource="resourceName" :is-workflow-sequenced="true" v-model="type"/>
                </template>

                <ApprovalTypeRadio
                    :approver-assignment-type="userApproverAssignmentType"
                    :isNewVersion="isNewVersion"
                    @change="approvalTypeChanged"
                />

                <FormInputColumn
                    :label="$t(`Type 'YES' to confirm {resourceName} Approval`, { resourceName: $t(resourceName) })"
                    :customContent="true"
                    :required="true"
                >
                    <input type="text" :placeholder="$t('Type “YES” in Uppercase')" v-model.trim="confirmText"/>
                </FormInputColumn>

                <FormInputColumn :label="$t('Add Note')" :customContent="true" :required="!isNewVersion">
                    <textarea v-model="note"></textarea>
                </FormInputColumn>

                <FormInputColumn
                    :label="$tc('Note visible to users outside your workspace?')"
                    :customContent="true"
                >
                    <IOSwitcher
                        :title="isNotePublic ? $tc('Yes') : $tc('No')"
                        v-model="isNotePublic"
                    />
                </FormInputColumn>

                <FilesComponent
                    :key="filesArrayId"
                    :arrayId="filesArrayId"
                    :showShared="false"
                    :forceHideSharedTab="true"
                    :showUploader="true"
                    :hideShareColumn="true"
                    modelType="resource_manual_approval_record"
                    :filesSection="activeResource"
                    :addToTemporary="true"
                    :modelId="resourceId"
                    :filesInNewTab="true"
                    :showModalVersion="true"
                    @newFileAdded="onFileAdd"
                    @tempFileRemove="onFileRemove"
                />
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="closePopup">{{ $t('Cancel') }}</IOButton>

            <template v-if="isSendToClientEnabledCheck">
                <IOButton variant="secondary" :disabled="!isValid" @click="approve">
                    {{ approveAndHoldText }}
                </IOButton>
                <IOButton :disabled="!isValid" @click="approveAndSubmitToClient">
                    {{ approveAndSubmitToClientText }}
                </IOButton>
            </template>
            <template v-else>
                <IOButton :disabled="!isValid" @click="approve">
                    {{ approveActionText }}
                </IOButton>
            </template>
        </template>

    </PopupModal>
</template>

<script lang="ts" src="./PopupManualApproval.ts"></script>
<style lang="scss" src="./PopupManualApproval.scss" scoped></style>
