<template>
    <div class="io-invoice-lien-waivers">
        <div class="io-invoice-lien-waivers__header">
            <div class="io-invoice-lien-waivers__header-title">{{ $t('Lien Waivers') }}</div>
            <IOButton v-if="!invoiceLienWaivers.length && isEditingMode" variant="link" icon="icon-paperclip" @click="openAttachModal">{{ $t('Attach Lien Waivers') }}</IOButton>
        </div>
        <div class="io-invoice-lien-waivers__content">
            <div v-if="!invoiceLienWaivers.length" class="io-invoice-lien-waivers__placeholder">
                {{ $t('No attached lien waivers') }}
            </div>
            <div v-else>
                <LienWaiversHeader @openAttachModal="openAttachModal" />
                <LienWaiversTable @edit="editItem" @previewFile="setPreviewFile" />
            </div>
        </div>
        <AttachLienWaiverModal v-if="showAttachModal" :itemToEdit="itemToEdit" @updateItem="updateItem" @close="closeAttachModal" />
        <FilePreview v-if="filePreview" :file="filePreview" @close="filePreview=null" />
    </div>
</template>

<script lang="ts" src="./LienWaivers.ts"></script>
<style lang="scss" src="./LienWaivers.scss" scoped></style>