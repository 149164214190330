export enum StepsList {
    INITIAL,
    MATCHED_DOMAINS,
    MATCHED_ACCOUNTS,
    ADD_CONTACT_DETAILS,
    ACTIONS,
    NOT_FOUND,
    ADD_WORKSPACE_TYPE = 6,
    SELECT_WORKSPACE_TYPE,
    SAVE_TO_BIDDERS_LIST
}
