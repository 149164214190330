const RfpProposalView = () => import(/* webpackChunkName: "rfp-proposal" */ '@/io-modules/rfp-proposal/views/RfpProposal.vue')
const RfpProposalDetailView = () => import(/* webpackChunkName: "rfp-proposal" */ '@/io-modules/rfp-proposal/views/rfp-proposal-detail/RfpProposalDetail.vue')
const FormDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue')
const RfpProposalBudgetFeeEdit = () => import(/* webpackChunkName: "rfp-proposal" */ '@/io-modules/rfp-proposal/views/rfp-proposal-edit/proposal-budget-fee/ProposalBudgetFeeEdit.vue')
const ProposalGeneralInformationEdit = () => import(/* webpackChunkName: "ProposalGeneralInformationEdit" */ '@/io-modules/rfp-proposal/views/rfp-proposal-edit/proposal-general-information/ProposalGeneralInformationEdit.vue')
const ProposalQuestionsAndAnswersEdit = () => import(/* webpackChunkName: "ProposalQuestionsAndAnswersEdit" */ '@/io-modules/rfp-proposal/views/rfp-proposal-edit/proposal-questions-and-answers/ProposalQuestionsAndAnswersEdit.vue')
const ProposalExhibitsEdit = () => import(/* webpackChunkName: "ProposalExhibitsEdit" */ '@/io-modules/rfp-proposal/views/rfp-proposal-edit/proposal-exhibits/ProposalExhibitsEdit.vue')
const ProposalScopeOfWorkEdit = () => import(/* webpackChunkName: "ProposalScopeOfWorkEdit" */ '@/io-modules/rfp-proposal/views/rfp-proposal-edit/proposal-scope-of-work/ProposalScopeOfWorkEdit.vue')
const ProposalInsuranceEdit = () => import(/* webpackChunkName: "ProposalExhibitsEdit" */ '@/io-modules/rfp-proposal/views/rfp-proposal-edit/proposal-insurance/ProposalInsuranceEdit.vue')
const RfpWizard = () => import(/* webpackChunkName: "RfpWizard" */ '@/io-modules/rfp-proposal/views/rfp-wizard/RfpWizard.vue')
const ProposalPreview = () => import(/* webpackChunkName: "ProposalPreview" */ '@/io-modules/rfp-proposal/views/proposal-preview/ProposalPreview.vue')
const ProposalAddressRevision = () => import(/* webpackChunkName: "ProposalAddressRevision" */ '@/io-modules/rfp-proposal/views/proposal-address-revision/ProposalAddressRevision.vue')

export default [
    {
        path: '/rfp-proposal/rfp-list',
        name: 'rfp-list-new',
        component: RfpProposalView,
        meta: {
            title: 'RFPs List'
        }
    },
    {
        path: '/rfp-proposal/proposal-list',
        name: 'proposals-list-new',
        component: RfpProposalView,
        meta: {
            title: 'Proposal List'
        }
    },
    {
        path: '/rfp-proposal/edit/:id',
        name: 'rfp-edit-new',
        component: FormDefault,
        meta: {
            schema: 'rfp-proposal',
            inviteType: 'rfp',
            title: 'RFPs edit'
        }
    },
    {
        path: '/rfp-proposal/rfp/view/:id',
        name: 'rfp-view-new',
        component: RfpProposalDetailView,
        meta: {
            title: 'General Bid Information'
        },
    },
    {
        path: '/rfp-proposal/proposal/view/:id',
        name: 'proposal-view-new',
        component: RfpProposalDetailView,
        meta: {
            title: 'Proposal'
        },
    },
    {
        path: '/rfp-proposal/proposal/general-information/edit/:id',
        name: 'proposal-general-information-edit',
        component: ProposalGeneralInformationEdit,
        meta: {
            title: 'Edit Company Overview'
        },
    },
    {
        path: '/rfp-proposal/proposal/budget-fee/edit/:id',
        name: 'proposal-budget-fee-edit',
        component: RfpProposalBudgetFeeEdit,
        meta: {
            title: 'Edit Budget / Fee'
        },
    },
    {
        path: '/rfp-proposal/proposal/questions-and-answers/edit/:id',
        name: 'proposal-questions-and-answers-edit',
        component: ProposalQuestionsAndAnswersEdit,
        meta: {
            title: 'Edit Q&A'
        },
    },
    {
        path: '/rfp-proposal/proposal/exhibits/edit/:id',
        name: 'proposal-exhibits-edit',
        component: ProposalExhibitsEdit,
        meta: {
            title: 'Proposal Edit Exhibits'
        },
    },
    {
        path: '/rfp-proposal/proposal/scope-of-work/edit/:id',
        name: 'proposal-scope-of-work-edit',
        component: ProposalScopeOfWorkEdit,
        meta: {
            title: 'Proposal Edit Services Scope of Work'
        },
    },
    {
        path: '/rfp-proposal/proposal/insurance/edit/:id',
        name: 'proposal-insurance-edit',
        component: ProposalInsuranceEdit,
        meta: {
            title: 'Edit Insurance Requirements'
        },
    },
    {
        path: '/rfp/wizard/add',
        name: 'rfp-wizard-add',
        component: RfpWizard,
        meta: {
            title: 'Rfp Wizard'
        },
    },
    {
        path: '/rfp/wizard/:id',
        name: 'rfp-wizard-edit',
        component: RfpWizard,
        meta: {
            title: 'Rfp Wizard'
        },
    },
    {
        path: '/proposal/preview/:id/:from?',
        name: 'proposal-preview-new',
        component: ProposalPreview,
        meta: {
            title: 'Proposal Preview'
        },
    },
    {
        path: '/proposal/address-revision/:id',
        name: 'proposal-address-revision',
        component: ProposalAddressRevision,
        meta: {
            title: 'Proposal Address Revision',
        },
    },
]
