import UnitOfMeasure from '@/interfaces/UnitOfMeasure.ts'

export enum UnitsOfMeasure {
    LUMP_SUM = 'lump-sum',
    HOURS = 'hours',
    EACH = 'each',
    ALLOWANCE = 'allowance',
    PERCENT = 'percent',
    UNIT_PERCENT_OF = 'unit-percent-of',
    // Imperial
    SQUARE_FEET = 'square-feet',
    GROSS_SQUARE_FEET = 'gross-square-feet',
    RENTABLE_SQUARE_FEET = 'rentable-square-feet',
    USABLE_SQUARE_FEET = 'useable-square-feet',
    LINEAR_FEET = 'linear-feet',
    YARDS = 'yards',
    CYDS = 'cyds', //cubic-yards
    TONS = 'tons',
    // Metric
    SQUARE_METRES = 'square-metres',
    GROSS_SQUARE_METRES = 'gross-square-metres',
    RENTABLE_SQUARE_METRES = 'rentable-square-metres',
    USABLE_SQUARE_METRES = 'usable-square-metres',
    METRES = 'metres',
    LINEAR_METRES = 'linear-metres',
    CUBIC_METRES = 'cubic-metres',
    METRIC_TONNES = 'metric-tonnes',
}

export function getNumericDefinition (unit: UnitsOfMeasure): number|null {
    return {
        [UnitsOfMeasure.EACH]: 3,
        [UnitsOfMeasure.LUMP_SUM]: 5,
        [UnitsOfMeasure.TONS]: 6,
        [UnitsOfMeasure.ALLOWANCE]: 15,
        [UnitsOfMeasure.PERCENT]: 10,
        [UnitsOfMeasure.UNIT_PERCENT_OF]: 16,
        [UnitsOfMeasure.HOURS]: 50,
        [UnitsOfMeasure.SQUARE_FEET]: 1,
        [UnitsOfMeasure.YARDS]: 4,
        [UnitsOfMeasure.LINEAR_FEET]: 7,
        [UnitsOfMeasure.CYDS]: 8,
        [UnitsOfMeasure.GROSS_SQUARE_FEET]: 20,
        [UnitsOfMeasure.RENTABLE_SQUARE_FEET]: 30,
        [UnitsOfMeasure.USABLE_SQUARE_FEET]: 40,
        [UnitsOfMeasure.CUBIC_FEET_PER_MINUTE]: 60,
        [UnitsOfMeasure.SQUARE_METRES]: 1,
        [UnitsOfMeasure.METRES]: 4,
        [UnitsOfMeasure.LINEAR_METRES]: 7,
        [UnitsOfMeasure.CMS]: 8,
        [UnitsOfMeasure.GROSS_SQUARE_METRES]: 20,
        [UnitsOfMeasure.RENTABLE_SQUARE_METRES]: 30,
        [UnitsOfMeasure.USABLE_SQUARE_METRES]: 40,
        [UnitsOfMeasure.CUBIC_METRES_PER_MINUTE]: 60,
    }[unit] ?? null
}

export function fromNumericDefinition (value: number, unitType: UnitTypeEnum = UnitOfMeasure.IMPERIAL): UnitsOfMeasure {
    if ([3, 5, 6, 9, 10, 16, 50].includes(value)) {
        return {
            3: UnitsOfMeasure.EACH,
            5: UnitsOfMeasure.LUMP_SUM,
            6: UnitsOfMeasure.TONS,
            15: UnitsOfMeasure.ALLOWANCE,
            10: UnitsOfMeasure.PERCENT,
            16: UnitsOfMeasure.UNIT_PERCENT_OF,
            50: UnitsOfMeasure.HOURS,
        }[value]
    }

    return unitType === UnitOfMeasure.IMPERIAL
        ? (() => {
            return {
                1: UnitsOfMeasure.SQUARE_FEET,
                4: UnitsOfMeasure.METRES,
                7: UnitsOfMeasure.LINEAR_FEET,
                8: UnitsOfMeasure.CYDS,
                20: UnitsOfMeasure.GROSS_SQUARE_FEET,
                30: UnitsOfMeasure.RENTABLE_SQUARE_FEET,
                40: UnitsOfMeasure.USABLE_SQUARE_FEET,
                60: UnitsOfMeasure.CUBIC_FEET_PER_MINUTE,
            }[value]
        })()
        : (() => {
            return {
                [1]: UnitsOfMeasure.SQUARE_METRES,
                [4]: UnitsOfMeasure.METRES,
                [7]: UnitsOfMeasure.LINEAR_METRES,
                [8]: UnitsOfMeasure.CUBIC_METRES,
                [20]: UnitsOfMeasure.GROSS_SQUARE_METRES,
                [30]: UnitsOfMeasure.RENTABLE_SQUARE_METRES,
                [40]: UnitsOfMeasure.USABLE_SQUARE_METRES,
                [60]: UnitsOfMeasure.CUBIC_METRES_PER_MINUTE,
            }[value]
        })()
}

export default UnitsOfMeasure
