<template>
    <div v-if="displayInfoBox" class="io-communicate io-f-row" :class="'io-' + type">
        <div v-if="displayIcon" class="io-left io-f-column">
            <i :class="icon"></i>
        </div>
        <div class="io-right-text">
            <h4 v-if="title">{{ title }}</h4>

            <p v-if="hasTextSlot">
                <slot name="text"/>
            </p>

            <p v-else-if="text" v-html="text"></p>

            <template v-if="hasButtonsSlot">
                <div class="io-separator"></div>
                <div class="io-btns-row io-f-row io-f-start">
                    <slot name="buttons"></slot>
                </div>
            </template>
        </div>
        <div v-if="dismissible" class="io-close-icon" @click="hideInfoBox()">
            <i class="icon-cross"></i>
        </div>
    </div>
</template>

<script src="./InfoBox.js"></script>
<style src="./InfoBox.scss" lang="scss" scoped></style>
