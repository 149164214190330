import { defineComponent } from 'vue'
import { LimitType } from '@/interfaces/modules/projects/financial-approvals/LimitType'

export default defineComponent({
    name: 'CurrencyDropdown',
    props: {
        width: { type: Number, default: 100 },
        options: { required: true },
        placeholder: { type: String, default: '' },
        selectedByDefault: { type: Object as () => LimitType },
        disabled: { type: Boolean },
    },
    data () {
        return {
            optionsShown: false as boolean,
            selectedItem: { label: '', value: null } as LimitType,
        }
    },
    computed: {
        dropdownWidth (): string {
            return `width: ${ this.width }px`
        }
    },
    watch: {
        disabled (): void {
            this.selectedItem = { label: 'No Limit', value: 'no_limit' }
        },
        selectedByDefault (): void {
            this.selectedItem = this.selectedByDefault
        }
    },
    beforeMount () {
        this.selectedItem = this.selectedByDefault
    },
    methods: {
        onToggleDropdown (): void {
            if (!this.disabled) {
                this.optionsShown = !this.optionsShown
            }
        },

        onClickOption (val: LimitType): void {
            this.$emit('select', val)

            this.selectedItem = val
            this.optionsShown = false
        },

        closeDropdown (): void {
            this.optionsShown = false
        },
    }
})
