<template>
    <div class="io-lazy-grid">
        <div class="io-new-list-holder sticky-wrapper" style="overflow: auto; padding: 0;">
            <div class="io-lazy-grid__content sticky-grid-wrapper">
                <template v-if="records && records.length">
                    <div class="io-lazy-grid__list">
                        <template v-for="(data, index) in records" :key="index">
                            <div
                                :data="data">
                                <slot name="lazy-item" :data="data" :itemRoute="itemRoute"></slot>
                            </div>
                        </template>
                    </div>

                    <FetchingColumns v-if="isFetching" text="Fetching data..." />
                </template>

                <template v-else-if="isFetching">
                    <FetchingColumns text="Fetching data..." />
                </template>

                <template v-else>
                    <FetchingColumns text="No records found" />
                </template>
            </div>

            <ModalFilters v-show="showFilters" :countFilters="countFilters" @apply="applyFilters" @closeModal="onCloseFilters">
                <FilterSection v-if="filterSections" :filterSections="filterSections"/>
                <Filters :filtersSchema="filtersSchema" />

                <template #buttons>
                    <div class="io-btn-secondary" @click="onClearAllFilters">Clear All</div>
                </template>
            </ModalFilters>
        </div>
    </div>
</template>

<script lang="ts" src="./LazyGrid.ts"></script>
<style lang="scss" src="./LazyGrid.scss" scoped></style>
