import FundingSources from '@/io-modules/global-funding/views/main.vue'
import FundingSourceProvidersList from '@/io-modules/global-funding/views/providers/ProvidersList.vue'
import FundingSourceProviderDetails from '@/io-modules/global-funding/views/provider-details/ProviderDetails.vue'
import FundingSourceFundDetails from '@/io-modules/global-funding/views/capital-fund-details/CapitalFundDetails.vue'

const fundingSourcesBreadcrumbs = {
    breadcrumbs: [
        {
            text: 'Funding Sources',
            to: { name: 'funding-sources-providers' },
        },
    ],
}

export default [
    {
        path: '/funding-sources',
        name: 'funding-sources',
        component: FundingSources,
        redirect: { name: 'funding-sources-providers' },
        children: [
            {
                path: 'list',
                name: 'funding-sources-providers',
                component: FundingSourceProvidersList,
                meta: {
                    title: 'Funding Source List',
                    ...fundingSourcesBreadcrumbs,
                },
            },
            {
                path: 'list/:id',
                name: 'funding-sources-provider-details',
                component: FundingSourceProviderDetails,
                meta: {
                    title: 'Funding Source Provider Details',
                    ...fundingSourcesBreadcrumbs,
                },
            },
            {
                path: 'fund/:id',
                name: 'funding-sources-fund-details',
                component: FundingSourceFundDetails,
                meta: {
                    title: 'Funding Source Capital Fund Details',
                    ...fundingSourcesBreadcrumbs,
                },
            },
        ],
    },
]
