import { defineComponent } from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import Draggable from 'vuedraggable'
import Dropdown from '../dropdown/Dropdown.vue'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import { Employee } from '@/interfaces/modules/projects/financial-approvals/Employee'
import { Approver } from '@/interfaces/modules/projects/financial-approvals/Approver'
import { LimitType } from '@/interfaces/modules/projects/financial-approvals/LimitType'
import Initials from '@/components/new-theme/initials.vue'
import { isEmpty } from 'lodash'
import { ApproverType } from '@/interfaces/modules/projects/financial-approvals/ApproverType'
import { Role } from '@/interfaces/modules/projects/financial-approvals/Role'

export default defineComponent({
    name: 'SingleApprover',
    components: {
        Draggable,
        Dropdown,
        CustomMultiselect,
        Initials
    },
    props: {
        approver: { type: Object as () => Approver },
        index: { type: Number },
        approvalSequence: { type: Boolean },
        approvalLimit: { type: Boolean },
        employees: { type: Array as () => Employee[] },
        roles: { type: Array as () => Role[] },
        edit: { type: Boolean, required: true },
        approvers: { type: Array as () => Approver[], required: true },
        customUserSelect: { type: Boolean, required: false, default: false },
        defaultWorkflow: { type: Boolean, required: false, default: false },
    },
    data () {
        return {
            limitOptions: [
                { label: 'Less than', value: 'less_than' },
                { label: 'Greater than', value: 'greater_than' },
                { label: 'No limit', value: 'no_limit' }
            ] as LimitType[],
        }
    },
    computed: {
        isRoleTypeMultiselect (): boolean {
            return this.approver?.template?.approver_type === ApproverType.ROLE
        },

        employeeMultiselectOptions (): Employee[] {
            return this.isRoleTypeMultiselect ? this.approver.approvers_to_assign as Employee[] : this.employees
        },

        disableMultiselect (): boolean {
            return isEmpty(this.employeeMultiselectOptions)
        },

        roleName (): string | undefined {
            return this.approver?.role_name
        },

        optionsTitle (): string | undefined {
            if (!this.isRoleTypeMultiselect) {
                return
            }
            return `Select ${ this.roleName }`
        },

        multiselectSettings (): any {
            return {
                textPlaceholder: this.isRoleTypeMultiselect ? this.roleName : 'Choose Approver',
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: true,
                searchPropertyNames: ['name']
            }
        },

        canDelete (): boolean {
            return !this.approvalDisabled && this.approvers.length > 1 && this.edit
        },

        activeEmployee (): Employee | undefined {
            return this.employees.find((employee: Employee) => employee.id === this.approver.user_id)
        },

        approvalDisabled (): boolean {
            if (!this.defaultWorkflow) {
                return false
            }
            return this.approvers.length === 1
        },

        defaultSelection (): LimitType | undefined {
            const defaultOption = this.limitOptions.find(limitType => limitType.value === this.approver.limit_type)

            if (defaultOption) {
                return defaultOption
            }

            return this.limitOptions.find((limitType: LimitType) => limitType.value === 'no_limit')
        },

        showLimitValueInput (): boolean {
            return !!this.approver.limit_type && this.approver.limit_type !== 'no_limit' && this.approvalLimit && this.edit
        }

    },
    watch: {
        approvers: {
            handler (value: Approver[]): void {
                if (this.defaultWorkflow && this.approvers.length === 1) {
                    this.selectLimitType(this.limitOptions[2])
                }
            },
            immediate: true,
            deep: true
        }
    },

    methods: {
        selectLimitType (e: any): void {
            this.approver.limit_type = e.value
        },

        onChangeEmployee (employee: Employee): void {
            this.approver.is_unassigned = false
            this.approver.user_id = employee.id
            this.$emit('select-employee')
        },

        deleteApprover (): void {
            this.$emit('delete-approver')
        },
    }
})
