import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    props: {
        data: { type: Object, required: true, default: null },
        column: { type: Object as () => ColumnInterface, required: true, default: null },
    },
    methods: {
        getText (): string {
            const field = this.column.field

            if (!this.data[field]) {
                return '-'
            }

            if (typeof this.data[field] === 'object') {
                let addressData = []

                !!this.data[field].address_line1 && addressData.push(this.data[field].address_line1)
                !!this.data[field].address_line2 && addressData.push(this.data[field].address_line2)
                !!this.data[field].city && addressData.push(this.data[field].city)
                !!this.data[field].state && addressData.push(this.data[field].state)
                !!this.data[field].zip && addressData.push(this.data[field].zip)
                !!this.data[field].country && addressData.push(this.data[field].country)

                return addressData.join(', ').trim()
            }

            return this.data[field]
        }
    }
})
