<template>
    <div
        class="io-design-system io-switcher"
        :class="{
            'io-switcher--disabled': disabled,
            'io-switcher--flipped': flipped,
            'io-switcher--slider': slider,
        }"
    >
        <slot />

        <label class="io-switcher__label">
            <input
                class="io-switcher__input"
                type="checkbox"
                :disabled="disabled"
                :checked="modelValue"
                @input="$emit('update:modelValue', $event.target.checked)"
            />

            <span v-if="slider" class="io-switcher__slider" />

            <span v-if="title" class="io-switcher__title">{{ title }}</span>
        </label>
    </div>
</template>

<script lang="ts" src="./IOSwitcher.ts"></script>
<style lang="scss" src="./IOSwitcher.scss" scoped></style>
