<template>
    <div class="io-project-drawings-sheet-list">
        <LazyList
            key="drawing-sheets-lazy-list"
            :endpoint="endpoint"
            :columns="columns"
            :filterSections="filterSections"
            :itemRoute="itemRoute"
            :noReroute="noReroute"
            :pillsColorFunction="pillsColorFunction"
            :pillsTextFunction="pillsTextFunction"
            :table-row-class-function="getRowClass"
            @emitIconAction="emitIconAction"
            @tableRowClick="drawingSheetSelected"
            @actionSelect="actionSelect"
        />

        <EditPopup
            v-if="editSheetPopupShown"
            onSheetList
            :updatingSheetVersionId="updatingSheetVersionId"
            :updatingSheetDrawingId="updatingSheetDrawingId"
            @data-saved="refreshList"
            @close="closeEditPopup"
        />
    </div>
</template>

<script lang="ts" src="./SheetsList.ts"></script>
<style lang="scss" src="./SheetsList.scss" scoped></style>
