<template>
    <div
        class="io-design-system io-tabs-component"
        :class="{'io-tabs-component--right': placement === 'right', 'io-tabs-component--vertical': isVertical}"
    >
        <div v-if="showTitle" class="io-tabs-component__title">
            <slot>
                {{ title }}
            </slot>
        </div>

        <div ref="tabsContainer" class="io-tabs-component__tabs">
            <Tab
                v-for="tab in listTabs"
                ref="tabs"
                :key="`tab_${ tab.index }`"
                v-tooltip="tab?.tooltip ?? { visible: false }"
                :tab="tab"
                :active-tab="activeTab"
                :translate="translate"
                :isVertical="isVertical"
                :must-compare-only-key-for-active="mustCompareOnlyKeyForActive"
                @click="onClick(tab.index, tab.disabled)"
                @remove="onRemove(tab.index)"
            />
            <DropdownButtonNew
                v-if="showViewMore"
                class="io-tabs-component__more"
                :name="$t('More')"
                :class="{ 'active': isDropdownActive }"
            >
                <Tab
                    v-for="tab in hiddenTabs"
                    :key="`hidden_tab_${ tab.index }`"
                    :tab="tab"
                    :active-tab="activeTab"
                    :translate="translate"
                    :must-compare-only-key-for-active="mustCompareOnlyKeyForActive"
                    @click="onClick(tab.index, tab.disabled)"
                />
            </DropdownButtonNew>
        </div>
    </div>
</template>

<script lang="ts" src="./Tabs.ts"></script>
<style lang="scss" src="./Tabs.scss" scoped></style>
