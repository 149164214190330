import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import loadingBarMixin from '@/mixins/loadingBarMixin.ts'
import DrawingSheetsFilter from '@/io-modules/drawings/mixins/DrawingSheetsFilter.ts'

import EditPopup from '@/io-modules/drawings/components/edit-popup/EditPopup.vue'
import LazyList from '@/components/lazy-list/LazyList.vue'

import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'

import { Actions, ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface.ts'
import { ItemRouteInterface } from '@/interfaces/components/lazy-list/ItemRoute.ts'
import { Set } from '@/io-modules/drawings/interfaces/Set.ts'
import { DrawingSheet } from '@/io-modules/drawings/interfaces/DrawingSheet.ts'
import { SetStatus } from '@/io-modules/drawings/enums/SetStatus.ts'


export default defineComponent({
    name: 'SheetsList',
    components: {
        EditPopup,
        LazyList,
    },
    mixins: [DrawingSheetsFilter, loadingBarMixin],
    data () {
        return {
            editSheetPopupShown: false,
            editingSheetData: {
                id: '',
            },
            currentViewValue: 0,
            addNewVersion: false,
            itemRoute: {
                name: 'drawings-sheets-show',
                params: {
                    sheetId: 'id',
                    versionId: 'current_version_id',
                },
            } as ItemRouteInterface,
            updatingSheetVersionId: null,
            updatingSheetDrawingId: null,
            searchLocal: '',
        }
    },
    computed: {
        ...mapGetters('lazyList', {
            search: 'search',
            hasFetchedOnce: 'hasFetchedOnce',
        }),
        ...mapGetters('listingFilter', {
            getActiveSearch: 'getActiveSearch',
        }),
        ...mapGetters('lazyList', {
            getRecords: 'getRecords',
            search: 'search',
        }),
        ...mapGetters('drawingsV2', {
            activeDrawingSet: 'getActiveDrawingSet',
        }),
        endpoint (): string {
            return `/ca/drawing/lazy-list/${ this.activeDrawingSet ?? this.$route.params.setId }`
        },
        noReroute (): boolean {
            return this.activeDrawingSet !== null
        },
        dropdownActions (): Actions[] {
            return [
                {
                    label: this.$t('Download'),
                    icon: 'icon-file-download',
                    action: 'download',
                },
                {
                    label: this.$t('Edit'),
                    icon: 'icon-edit',
                    action: 'edit',
                },
            ]
        },
        columns (): ColumnInterface[] {
            return [
                {
                    field: '',
                    label: '',
                    type: 'checkbox',
                    columnClass: 'acenter io-col-checkbox',
                    additional: {
                        sortHidden: true,
                    },
                },
                {
                    field: 'thumbnail',
                    label: '',
                    type: 'thumbnail',
                    columnClass: 'io-col-thumbnail',
                    additional: {
                        sortHidden: true,
                    },
                },
                {
                    field: 'is_favorite',
                    label: '',
                    type: 'icon-action',
                    columnClass: 'io-col-favorite',
                    additional: {
                        iconName: 'icon-star',
                        activeIconName: 'icon-star-2',
                        sortHidden: true,
                    },
                },
                {
                    field: 'page_label',
                    label: this.$t('Number'),
                    type: 'text',
                    columnClass: 'io-col-number aleft',
                },
                {
                    field: 'page_title',
                    label: this.$t('Title'),
                    type: 'text',
                    columnClass: 'io-col-title',
                },
                {
                    field: 'status',
                    label: null,
                    type: 'text',
                    columnClass: 'io-col-archived',
                    additional: {
                        id: 'status',
                        sortHidden: true,
                        additionalPill: 'status',
                        additionalPillText: this.$tc('Archived'),
                        additionalPillVariant: 'gray-light',
                        ignoreValue: true,
                        conditionToShow: (sheet: DrawingSheet): boolean =>
                            sheet.status === SetStatus.ARCHIVED,
                    },
                },
                {
                    field: 'page_category',
                    label: this.$t('Category'),
                    type: 'text',
                    columnClass: 'io-category-title',
                    additional: {
                        isTranslation: true,
                    },
                },
                {
                    field: 'drawing_set_issue_date',
                    label: this.$t('Issue Date'),
                    type: 'date',
                    columnClass: 'io-col-created',
                    additional: {
                        dateFormat: 'MM/DD/YYYY',
                    },
                },
                {
                    field: 'created_at',
                    label: this.$t('Upload Date'),
                    type: 'date',
                    columnClass: 'io-col-last-updated',
                    additional: {
                        dateFormat: 'MM/DD/YYYY',
                    },
                },
                {
                    field: 'current_version_number',
                    label: this.$t('Version'),
                    type: 'text',
                    columnClass: 'io-col-version aright',
                },
                {
                    field: 'pins_count',
                    label: this.$t('Annotations'),
                    type: 'pill',
                    columnClass: 'io-col-pill',
                    additional: {
                        id: 'pins_count',
                        header: 'pins_count',
                        sortHidden: true,
                    },
                },
                {
                    field: '',
                    label: '',
                    columnClass: 'aleft io-col-dropdown',
                    type: 'dropdown-new',
                    additional: {
                        actions: [
                            {
                                label: this.$t('Download'),
                                icon: 'icon-file-download',
                                action: 'download',
                            },
                            {
                                label: this.$t('Edit'),
                                icon: 'icon-edit',
                                action: 'edit',
                                showAction: (sheet: DrawingSheet): boolean =>
                                    sheet.status !== SetStatus.ARCHIVED,
                            },
                            {
                                label: this.$t('Archive'),
                                icon: 'icon-archive',
                                action: 'archive',
                                showAction: (sheet: DrawingSheet): boolean =>
                                    sheet.status !== SetStatus.ARCHIVED,
                            },
                            {
                                label: this.$t('Unarchive'),
                                icon: 'icon-undo-2',
                                action: 'unarchive',
                                showAction: (sheet: DrawingSheet): boolean =>
                                    sheet.status === SetStatus.ARCHIVED,
                            },
                            {
                                label: this.$t('Delete'),
                                icon: 'icon-trash',
                                action: 'delete',
                                showAction: () => false,
                            },
                        ],
                        sortHidden: true,
                    },
                },
            ]
        },
    },
    watch: {
        async getActiveSearch (): Promise<void> {
            this.searchLocal = this.getActiveSearch

            await this.$store.dispatch('listingFilter/setSearch', this.searchLocal)

            if (this.searchLocal !== this.search) {
                await this.$store.dispatch('lazyList/setSearch', this.searchLocal)
            }
        },
        hasFetchedOnce (): void {
            this.setActiveSheetsFilter()
        },
    },
    async beforeMount () {
        await this.$store.dispatch('lazyList/resetState')
        await this.$store.dispatch('lazyList/setWithFilters', true)
        await this.$store.dispatch('lazyList/setWithCounters', true)
        await this.$store.dispatch('listingFilter/setSearch', this.searchLocal)
        await this.setActiveSheetsFilter()

        this.searchLocal = this.getActiveSearch
    },
    async beforeUnmount () {
        await this.$store.dispatch('lazyList/setSelectedRows', [])
    },
    methods: {
        ...mapActions('lazyList', {
            setWithFilters: 'setWithFilters',
        }),
        pillsTextFunction (quantity: number): string {
            if (quantity === 0) {
                return this.$t('NO')
            }

            return this.$tc('PIN(S)', quantity, { quantity })
        },
        pillsColorFunction (value: number): string {
            return value === 0 ? 'io-grey' : 'io-blue'
        },
        async emitIconAction (data: { field: string, id: string }): Promise<void> {
            switch (data.field) {
            case 'is_favorite':
                await this.changeFavorite(data.id)
                break
            case 'edit':
                this.editData(data.id)
                break
            }
        },
        async changeFavorite (id: string): Promise<void> {
            return this.load(async (): Promise<void> => {
                const el = this.getRecords.find((record: Set) => record.id === id)
                const data = {
                    id: id,
                    value: !el.is_favorite,
                }
                await setsClient.markSheetAsFavorite(data)
                await this.$store.dispatch('lazyList/getListData', true)
            })
        },
        editData (id: string): void {
            this.editingSheetData = this.getRecords.find((record: Set) => record.id === id)
            this.editSheetPopupShown = true
        },
        actionSelect (action: { name: string, id: string, data: DrawingSheet }): void {
            switch (action.name) {
            case 'edit':
                this.handleEditAction(action.data.current_version_id, action.data.id)
                break
            case 'archive':
                this.archiveSheetPopup(action.data)
                break
            case 'unarchive':
                this.unarchiveSheetPopup(action.data)
                break
            case 'download':
                this.$emit('download', action.id)
                break
            }
        },
        handleEditAction (versionId: string, drawingId: string): void {
            this.updatingSheetVersionId = versionId
            this.updatingSheetDrawingId = drawingId
            this.editSheetPopupShown = true
        },
        closeEditPopup (): void {
            this.editSheetPopupShown = false
            this.updatingSheetVersionId = null
            this.updatingSheetDrawingId = null
        },
        async refreshList (): Promise<void> {
            await this.$store.dispatch('lazyList/getListData', true)
        },
        archiveSheetPopup (sheet: DrawingSheet): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to archive {name}?', { name: sheet.page_title }),
                caption: this.$t('This will mark {name} as Archived.', { name: sheet.page_title }),
                disableOutsideClose: false,
                icon: 'icon-archive',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                    },
                    {
                        text: this.$t('Yes, Archive Sheet'),
                        class: 'io-btn-primary',
                        action: async (): Promise<void> => {
                            await this.archiveSheet(sheet.id)
                            this.showNotification('info', this.$t('Sheet has been archived.'))
                            await this.$store.dispatch('lazyList/getListData', true)
                        },
                    },
                ],
            })
        },
        unarchiveSheetPopup (sheet: DrawingSheet): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to un-archive {name}?', { name: sheet.page_title }),
                caption: this.$t('This will mark {name} as Un-archived.', { name: sheet.page_title }),
                disableOutsideClose: false,
                icon: 'icon-undo-2',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                    },
                    {
                        text: this.$t('Yes, Un-archive Sheet'),
                        class: 'io-btn-primary',
                        action: async (): Promise<void> => {
                            await this.unarchiveSheet(sheet.id)
                            this.showNotification('info', this.$t('Sheet has been unarchived.'))
                            await this.$store.dispatch('lazyList/getListData', true)
                        },
                    },
                ],
            })
        },
        async archiveSheet (setId: string): Promise<void> {
            return this.load(async (): Promise<DrawingSheet> => setsClient.archiveDrawingSheet(setId))
        },
        async unarchiveSheet (setId: string): Promise<void> {
            return this.load(async (): Promise<DrawingSheet> => setsClient.unarchiveDrawingSheet(setId))
        },
        getRowClass (sheet: DrawingSheet): string {
            return sheet.status === SetStatus.ARCHIVED ? 'io-table-row-archived' : ''
        },
        async setActiveSheetsFilter (): Promise<void> {
            return this.$store.dispatch('lazyList/setSingleFilter', {
                field: 'status',
                value: [SetStatus.ACTIVE],
            })
        },
        async drawingSheetSelected (selectedSheet: DrawingSheet): Promise<void> {
            this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SHEET', {
                id: selectedSheet.id,
                version: selectedSheet.current_version_id,
                fullName: selectedSheet.drawing_set_name + ' - ' + selectedSheet.page_label + ' ' + selectedSheet.page_title,
            })
            this.$store.commit('drawingsV2/SET_DRAWING_SHEETS_SHOWN', false)
            this.$store.commit('drawingsV2/SET_DRAWING_SHEET_SHOWN', true)
        },
    },
})
