<template>
    <button
        ref="tab"
        type="button"
        :class="{
            'active': isActive,
            'disabled': tab.disabled,
            'hidden': tab.isHidden,
            'io-tab-component': !isVertical,
            'io-tab-component--vertical': isVertical
        }"
        @click="onClick(tab.index, tab.disabled)"
    >
        <template v-if="!isVertical">
            <StatusDot v-if="tab.dot" :variant="tab.dotColor || DotVariant.WARNING"/>
        </template>
        <span class="io-tab-component__title" :class="{ 'active': isActive }">{{ title }}</span>
        <span
            v-if="tab.pill"
            class="io-tab-component__pill"
        >
            {{ tab.pill }}
        </span>
        <template v-if="isVertical">
            <StatusDot v-if="tab.dot" :variant="tab.dotColor || DotVariant.WARNING"/>
        </template>

        <span v-if="tab.isValid" class="icon-check"></span>
        <span v-if="tab.hasWarning" class="icon-alert-triangle-2"></span>
        <span v-if="tab.can_remove" class="icon-trash" @click.stop="onClickRemoveTab"></span>
    </button>
</template>

<script lang="ts" src="./Tab.ts"></script>
<style lang="scss" src="./Tab.scss" scoped></style>
