import store from '@/store'
import i18n from '@/base/i18n'
const { t } = i18n.global

export type Type = 'success' | 'error' | 'warning' | 'info'

const showConsoleError = (exception: unknown): void => {
    const exceptionLoggable = Boolean(exception && process.env.NODE_ENV !== 'production')
    exceptionLoggable && console.error('Ingenious Error Log', exception)
}

export default () => {
    const showNotification = (type: Type, message: string, exception?: unknown, html?: string): void => {
        const text = html || message || t('Error. Something went wrong!')

        store.dispatch('notification/setShow', { type, text, html })
        showConsoleError(exception)
    }

    return { showNotification }
}
