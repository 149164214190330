import qs from '@/helpers/qs'
import { AxiosPromise } from 'axios'

import { ApiClient, ApiClientV3 } from '@/api/api.js'

import Rules from '@/io-modules/project-contracts/interfaces/Rules.ts'
import Summary from '@/io-modules/project-contracts/interfaces/Summary.ts'
import WbsGroup from '@/io-modules/project-contracts/interfaces/WbsGroup.ts'
import Configuration from '@/io-modules/project-contracts/interfaces/Configuration.ts'
import { TimelineLog } from '@/io-modules/project-contracts/interfaces/TimelineLog.ts'
import MainContractId from '@/io-modules/project-contracts/interfaces/MainContractId.ts'
import ContractChanges from '@/io-modules/project-contracts/interfaces/ContractChanges.ts'
import ContractGMPStatus from '@/io-modules/project-contracts/interfaces/ContractGMPStatus.ts'
import { InsuranceDetails } from '@/io-modules/project-contracts/interfaces/InsuranceDetails.ts'
import { ListingRequestParams } from '@/io-modules/project-contracts/interfaces/RequestParams.ts'
import { ContractDocument, ContractDocuments } from '@/io-modules/project-contracts/interfaces/ContractDocuments.ts'
import { LienWaiversData } from '@/io-modules/project-contracts/interfaces/LienWaivers.ts'
import { ListContract } from '@/io-modules/contracts/interfaces/ListContract.ts'
import WbsType from '@/io-modules/project-contracts/enums/WbsType.ts'
import ContractStatusAction from '@/io-modules/project-contracts/enums/ContractStatusAction.ts'
import { IsValid } from '@/io-modules/project-contracts/interfaces/UniqueId.ts'
import { ExecuteSlideoutData } from '@/interfaces/modules/projects/contract-changes/ContractChange.ts'
import ContractReportParams from '@/io-modules/project-contracts/interfaces/ContractReportParams.ts'
import ChangeStatusParams from '@/io-modules/project-contracts/interfaces/ChangeStatusParams.ts'
import ContractAgreementType from '@/io-modules/project-contracts/enums/ContractAgreementType.ts'

const contractClient = {
    create (data: object): AxiosPromise<string> {
        return ApiClientV3.post('/contracts', data)
    },

    createFromBidRequest (bidRequestId: string, invite: boolean): AxiosPromise<string> {
        return ApiClientV3.post(`/bid-requests/${ bidRequestId }/create-contract`, { invite })
    },

    changeStatus (contractId: string, status: ContractStatusAction, params?: ChangeStatusParams): AxiosPromise<void> {
        return ApiClientV3.post(`/contracts/${ contractId }/status/${ status }`, { parameters: params })
    },

    getGMPStatus (contractId: string): AxiosPromise<ContractGMPStatus> {
        return ApiClientV3.get(`contracts/${ contractId }/gmp-status`)
    },

    getContracts (params: ListingRequestParams): AxiosPromise<{ items: ListContract[] }> {
        return ApiClientV3.get('/contracts', {
            params,
            paramsSerializer: params => qs.stringify(params),
        })
    },

    getContractSummary (contractId: string): AxiosPromise<Summary> {
        return ApiClientV3.get(`/contracts/${ contractId }/summary`)
    },

    getExecutedAmendment (contractId: string): AxiosPromise<ExecuteSlideoutData> {
        return ApiClient.get(`/contract-changes/final-amendment-details/${ contractId }`)
    },

    save (contractId: string, data: object): AxiosPromise<Summary> {
        return ApiClientV3.put(`/contracts/${ contractId }`, data)
    },

    getContractConfiguration (contractId: string): AxiosPromise<Configuration> {
        return ApiClientV3.get(`/contracts/${ contractId }/configuration`)
    },

    getMainContractAgreementType (globalProjectId: string): AxiosPromise<{ agreement_type: ContractAgreementType }> {
        return ApiClientV3.get('/contracts/create-configuration', {
            params: { project_id: globalProjectId },
        })
    },

    getContractDocuments (contractId: string): AxiosPromise<ContractDocuments> {
        return ApiClientV3.get(`/contracts/${ contractId }/documents`)
    },

    getLienWaivers (contractId: string): AxiosPromise<LienWaiversData> {
        return ApiClientV3.get(`/contracts/${ contractId }/lien-waivers`)
    },

    deleteDocument (contractId: string, documentId: string): AxiosPromise<void> {
        return ApiClientV3.delete(`/contracts/${ contractId }/documents/${ documentId }`)
    },

    getInsuranceDetails (contractId: string): AxiosPromise<InsuranceDetails> {
        return ApiClientV3.get(`/contracts/${ contractId }/insurance-requirements`)
    },

    getAttachments (contractId: string): AxiosPromise<ContractDocument[]> {
        return ApiClientV3.get(`/contracts/${ contractId }/attachments`)
    },

    getRules (contractId: string): AxiosPromise<Rules> {
        return ApiClientV3.get(`/contracts/${ contractId }/rules`)
    },

    getSovWbs (contractId: string, type: WbsType): AxiosPromise<WbsGroup> {
        return ApiClientV3.get(`/contracts/${ contractId }/wbs/${ type }`)
    },

    getContractChanges (contractId: string): AxiosPromise<ContractChanges> {
        return ApiClientV3.get(`/contracts/${ contractId }/contract-changes`)
    },

    approveSovWbs (contractId: string): AxiosPromise<void> {
        return ApiClientV3.post(`/contracts/${ contractId }/wbs/original/approve`)
    },

    approveSovWbsAsVendor (contractId: string): AxiosPromise<void> {
        return ApiClientV3.post(`/contracts/${ contractId }/wbs/original/approve-on-behalf-of-vendor`)
    },

    approveSovWbsAsClient (contractId: string): AxiosPromise<void> {
        return ApiClientV3.post(`/contracts/${ contractId }/wbs/original/approve-on-behalf-of-client`)
    },

    getMainContractInfo (projectId: string): AxiosPromise<MainContractId> {
        return ApiClientV3.get('/contracts/main', {
            params: { project_id: projectId },
        })
    },

    getTimeline (contractId: string): AxiosPromise<TimelineLog[]> {
        return ApiClientV3.get(`/contracts/${ contractId }/timeline`)
    },

    validateUniqueId (uniqueId: string, projectId: string): AxiosPromise<IsValid> {
        return ApiClientV3.post(`/contracts/${ projectId }/validate-unique-id`, { unique_id: uniqueId })
    },

    inviteContact (contractId: string): AxiosPromise<void> {
        return ApiClientV3.post(`/contracts/${ contractId }/invite-to-collaborate`)
    },

    getContractReport (contractId: string, params: ContractReportParams): AxiosPromise<Blob> {
        return ApiClientV3.get(`/contracts/${ contractId }/export`, {
            params,
            responseType: 'blob',
        })
    },

    getApprovableResourceId (contractId: string): AxiosPromise<string> {
        return ApiClientV3.get(`/contracts/${ contractId }/approvable-resource`)
    },

    delete (contractId: string): AxiosPromise<string> {
        return ApiClientV3.delete(`/contracts/${ contractId }`)
    },
}

export default contractClient
