<template>
    <div>
        <div
            class="io-divisions-show hover-darken"
            :class="expanded ? 'bg-white' : 'bg-lighten-bg-2'"
            @click="toggle"
        >
            <div class="flex items-center gap-16-px">
                <i
                    class="icon-chevron-right text-main-lighten-3 text-lg-px trans-all"
                    :class="{ 'rotate-90': expanded }"
                />

                <span>{{ division.label }} - {{ division.title }}</span>
            </div>
        </div>

        <div v-if="expanded">
            <SectionsShow
                v-for="section in filteredSections"
                :key="section.id"
                :section="section"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./DivisionsShow.ts"></script>
<style lang="scss" src="./DivisionsShow.scss" scoped></style>
