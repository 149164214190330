import client from '@/api/invite-modal/inviteModalClient'
import { StepsList } from '@/interfaces/components/invite-modal/StepsList'
import { debounce } from 'lodash'
import inviteModalClient from '@/api/invite-modal/inviteModalClient'

export const fetchContacts = async function ({ commit }, key) {
    // const data = await client.fetchContacts()
    commit('SET_RENDERED', { value: true, key })
}

export const searchCompanies = debounce(async ({ commit }, payload) => {
    const { data } = await client.searchCompanies(payload)
    commit('CLEAR_COMPANIES_LIST')
    commit('SET_COMPANIES_LIST', { data })
}, 500)

export const fetchRecords = debounce(async ({ commit, state }, data) => {
    if (!data.key) {
        return
    }

    commit('SET_LOADING', { value: true, key: data.key })

    if (!data.isInitial) {
        if (data.search === state.inviteModalData[data.key].search && !data.isTabSwitch) {
            commit('SET_OFFSET', { key: data.key, value: state.inviteModalData[data.key].offset + state.inviteModalData[data.key].takeRecords })
        } else {
            commit('SET_SEARCH', data)
            commit('CLEAR_SEARCH_RESULTS', { key: data.key })
            commit('SET_OFFSET', { key: data.key, value: 0 })
            commit('SET_LAST_PAGE', { key: data.key, value: false })
            commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.INITIAL })
            commit('RESET_SELECTED_WORKSPACE', { key: data.key })
            commit('RESET_SELECTED_CONTACT', { key: data.key })
            commit('RESET_SELECTED_WORKSPACE_TYPE', { key: data.key })
        }
    } else {
        commit('SET_SEARCH', data)
    }

    await client.searchForContact(
        data.search,
        state.inviteModalData[data.key].offset,
        data.filters,
        data.resourceId,
        state.inviteModalData[data.key].selectedContactsToAction.model_section,
        state.inviteModalData[data.key].selectedContactsToAction.model_type
    ).then(( response ) => {
        const onItemContactsIds = state.inviteModalData[data.key].selectedContacts.map((contact) => {
            return contact.id
        })

        if (state.inviteModalData[data.key].isCachedDataLoadRequired && response.data.matched_my_contacts.length) {
            const contacts = []

            response.data.matched_my_contacts.forEach((contact) => {
                !onItemContactsIds.includes(contact.id) && contacts.push(contact)
            })

            response.data.matched_my_contacts = contacts
        }

        if (response.data.matched_my_contacts.length) {
            response.data.matched_my_contacts = response.data.matched_my_contacts.filter(contact => !onItemContactsIds.includes(contact.id))
        }

        commit('UPDATE_MY_CONTACTS', { key: data.key, data: response.data.matched_my_contacts })

        if (response.data.matched_my_contacts.length < state.inviteModalData[data.key].takeRecords) {
            commit('SET_LAST_PAGE', { key: data.key, value: true })
        }

        if (response.data.on_item.length && data.isInitial) {
            const newOnItem = response.data.on_item.filter(contact => !onItemContactsIds.includes(contact.id))
            commit('SET_SELECTED_CONTACTS', { key: data.key, data: newOnItem })
        }

        commit('SET_MATCHED_ACCOUNTS', { key: data.key, data: response.data.matched_accounts })
        commit('SET_MATCHED_DOMAINS', { key: data.key, data: response.data.matched_domains })

        if (response.data.matched_my_contacts && response.data.matched_my_contacts.length) {
            commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.INITIAL })
        }

        if (response.data.matched_accounts && response.data.matched_accounts.length) {
            commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.MATCHED_ACCOUNTS })
        }

        if (response.data.matched_domains && response.data.matched_domains.length) {
            commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.MATCHED_DOMAINS })
        }

        if (
            !response.data.matched_my_contacts.length &&
            !response.data.matched_accounts.length &&
            !response.data.matched_domains.length &&
            data.search !== data.currentUserEmail &&
            data.search &&
            !data.showEmployeesOnly
        ) {
            commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.NOT_FOUND })
        }

        if (
            !response.data.matched_accounts.length &&
            !response.data.matched_domains.length &&
            data.search === data.currentUserEmail
        ) {
            commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.INITIAL })
        }

        if (
            !response.data.matched_my_contacts.length &&
            !response.data.matched_accounts.length &&
            !response.data.matched_domains.length &&
            response.data.other_records_exist
        ) {
            commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.INITIAL })
        }

        if(data.key === 'add-bidder') {
            commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.SAVE_TO_BIDDERS_LIST })
        }

        commit('SET_IS_OTHER_RECORDS_EXIST', { key: data.key, value: response.data.other_records_exist })

    }).catch(( error) => {
        throw error
    }).finally(() => {
        commit('SET_LOADING', { value: false, key: data.key })
        commit('SET_IS_TABS_VISIBLE', { value: true, key: data.key })
    })
}, 500)

export const saveContact = async function ({ commit }, data) {
    commit('SAVE_CONTACT', data)
}

export const saveOrInviteNewContact = async function ({ commit, dispatch }, data) {
    commit('SAVE_OR_INVITE_NEW_CONTACT', data)
    commit('SET_ACTIVE_STEP', { key: data.key, value: StepsList.INITIAL })
    commit('SET_CONTACT_FORM_DATA', { key: data.key, data: null })
    commit('SET_SEARCH', { key: data.key, search: '' })
}

export const inviteContact = async function ({ commit }, data) {
    commit('INVITE_CONTACT', data)
}

export const removeSelected = async function ({ commit }, data) {
    commit('REMOVE_SELECTED', data)
}

export const inviteSavedContact = async function ({ commit }, data) {
    commit('INVITE_SAVED', data)
}

export const addRemovedContact = async function ({ commit }, data) {
    commit('ADD_REMOVED_CONTACT', data)
}

export const removeContactToSave = async function ({ commit }, data) {
    commit('REMOVE_CONTACT_TO_SAVE', data)
}

export const removeContactToInvite = async function ({ commit }, data) {
    commit('REMOVE_CONTACT_TO_INVITE', data)
}

export const removeContactToRemind = async function ({ commit }, data) {
    commit('REMOVE_CONTACT_TO_REMIND', data)
}

export const invitationRemind = async function ({ commit }, data) {
    commit('INVITATION_REMIND', data)
}

export const setInviteModalModel = function ({ commit }, data) {
    commit('SET_MODEL', data)
}

export const setPersonOnItem = function ({ commit }, data) {
    commit('SET_PERSON_ON_ITEM', data)
}

export const clearPersonsOnItem = function ({ commit }, data) {
    commit('CLEAR_PERSONS_ON_ITEM', data)
}

export const resetSelectedContactsToAction = function ({ commit }, data) {
    commit('RESET_SELECTED_CONTACTS_TO_ACTION', data)
}

export const batchInvitations = async function ({ state, dispatch }, data) {
    if (
        !state.inviteModalData[data.key].selectedContactsToAction?.invite.length
        && !state.inviteModalData[data.key].selectedContactsToAction?.save.length
        && !state.inviteModalData[data.key].selectedContactsToAction?.remind.length
        && !state.inviteModalData[data.key].selectedContactsToAction?.remove.length
    ) {
        return
    }

    try {
        await inviteModalClient.batchInvitations(state.inviteModalData[data.key].selectedContactsToAction)
    } catch (error) {
        const errorData = error.response.data
        const failedContacts = []

        errorData.failed.invites.length && errorData.failed.invites.forEach(contact => failedContacts.push(contact.email))
        errorData.failed.saves.length && errorData.failed.saves.forEach(contact => failedContacts.push(contact.email))
        errorData.failed.saves.length && errorData.failed.removes.forEach(contact => failedContacts.push(contact.email))
        errorData.failed.saves.length && errorData.failed.reminds.forEach(contact => failedContacts.push(contact.email))

        if (failedContacts.length) {
            dispatch(
                'notification/setShow',
                {
                    text: `${ this.$t('invite_invitation_action_could_not_be_completed') } ${ failedContacts.join(', ') }`,
                    type: 'error'
                },
                { root: true })
        }
    }
}
