export function fileExtension (fileName: string): string {
    const index = fileName.lastIndexOf('.')

    if (index === -1) {
        return ''
    }

    return fileName.slice(index + 1)
}

export const resolve = async (fns, ctx): Promise<void> => {
    for (const fn of fns) {
        Array.isArray(fn)
            ? await Promise.all(fn.map((fn) => fn.call(ctx)))
            : await fn.call(ctx)
    }
}

export const getPillName = (appType: string) => {
    if (appType === 'AC' || appType === 'ARCH' || appType === 'DESIGN') {
        return 'DESIGN'
    }

    if (appType === 'SC' || appType === 'SUB') {
        return 'SUB'
    }

    return appType.toUpperCase()
}

/**
 * The common usage is to add optional elements to an array,
 * encapsulating checking for the condition with a ternary operator.
 *
 * Examples:
 * [ 'foo', 'bar', ...addIf(true, 'baz') ] // ['foo', 'bar', 'baz']
 * [ 'foo', 'bar', ...addIf(false, 'baz') ] // ['foo', 'bar']
 */
export const addIf = <T> (condition: unknown, value: T): T[] => condition ? [value] : []

/**
 * If the value is in the array, remove it, otherwise add it.
 *
 * Examples:
 * toggle('foo', ['foo', 'bar']) // ['bar']
 * toggle('baz', ['foo', 'bar']) // ['foo', 'bar', 'baz']
 */
export const toggle = <T> (value: T, values: T[]): T[] | number =>
    values.includes(value)
        ? values.splice(values.indexOf(value), 1)
        : values.push(value)
