<template>
    <div class="io-single-workflow">
        <div class="io-single-workflow__header">
            <div class="io-single-workflow__header-left">
                <div>{{ $t(workflowName) }}</div>
                <BudgetCostCodeSelect
                    v-if="edit && costCodesEnabled && allowCostCodeSelection"
                    :key="projectIdLocal"
                    class="io-budget-cost-code-select"
                    :project-local-id="projectIdLocal"
                    :budget-type="isOwnerRep ? BudgetType.OWNER_BUDGET : BudgetType.MY_BUDGET"
                    :enableMultiselect="true"
                    v-model="selectedCostCodes"
                />
            </div>

            <div v-if="edit && allowTemplates" class="io-single-workflow__header-right">
                <div v-if="templateApplied" class="io-success">
                    <span class="icon-check-circle io-success__icon"></span>
                    <span>{{ $t('Template Applied') }}</span>
                </div>

                <IOButton
                    v-else
                    variant="link"
                    size="small"
                    icon="icon-cloud-upload"
                    @click="handleApplyTemplate"
                >
                    {{ $t('Apply Template') }}
                </IOButton>

                <IOButton
                    v-if="removeEnable"
                    variant="link"
                    size="small"
                    icon="icon-trash"
                    @click="onClickRemoveWorkflow"
                >
                    {{ $t('Remove Workflow') }}
                </IOButton>
            </div>

            <div v-else-if="costCodesEnabled" class="io-cost-codes-label io-clickable" @click="openAssignedCostCodesPopup">
                <span>{{ $t('Cost Codes') }}: </span>
                <span class="io-cost-codes-label__on">{{ costCodesCount }}</span>
            </div>

            <div v-else class="io-cost-codes-label">
                <span>{{ $t('Cost Codes') }}: </span>
                <span>{{ $t('Off') }}</span>
            </div>

        </div>

        <AlertBox v-if="unassignedApproversCount" color="yellow" icon="fas fa-exclamation-circle">
            <p v-html="alertBoxText"></p>
        </AlertBox>

        <div class="io-single-workflow__content">
            <table class="io-single-workflow-table">
                <thead class="io-single-workflow-table__head">
                    <tr>
                        <th>
                            <div v-if="edit && allowSequencing" class="io-switch">
                                <label>
                                    <input name="approval_sequence" type="checkbox" v-model="data.sequence_enabled"/>
                                    <span>{{ $t('Approval Sequence') }}</span>
                                </label>
                            </div>
                            <span v-else>{{ $t('Approval Sequence') }}</span>
                        </th>
                        <th>
                            <div class="io-f-row">
                                <span>{{ $t('Employee/Role') }}</span>
                                <span v-if="unassignedApproversCount" class="io-unassigned">
                                    <span>{{ unassignedApproversCount }}</span> {{ $t('Unassigned Role') }}
                                </span>
                            </div>
                        </th>
                        <th>
                            <span>{{ $t('Type') }}</span>
                        </th>
                        <th>
                            <div v-if="edit && allowLimitEdit" class="io-switch">
                                <label>
                                    <input name="approval_limit" type="checkbox" v-model="data.limit_enabled"/>
                                    <span>{{ $t('Approval Limit') }}</span>
                                </label>
                            </div>
                            <span v-else>{{ $t('Approval Limit') }}</span>
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <template v-if="individualResourceView">
                    <template v-for="(approver, index) in data.approvers" :key="index">
                        <SingleApproverV2
                            v-if="isStatusApprovedOrRejected(approver.approval?.status)"
                            :approval-limit="data.limit_enabled"
                            :approval-sequence="data.sequence_enabled"
                            :approver="approver"
                            :approvers="data.approvers"
                            :default-workflow="defaultWorkflow"
                            :edit="false"
                            :employees="availableEmployees(approver)"
                            :index="index"
                            :allow-limit-edit="allowLimitEdit"
                            :allow-drag="false"
                            :show-approval-note="true"
                            @select-employee="setNotSelectedEmployees"
                            @delete-approver="deleteApprover(index)"
                        />
                    </template>

                    <Draggable :animation="300" :list="data.approvers" handle=".fa-grip-vertical" tag="tbody" :item-key="item => data.approvers.indexOf(item)">
                        <template #item="{ element: approver, index }" >
                            <SingleApproverV2
                                v-if="!isStatusApprovedOrRejected(approver.approval?.status)"
                                :approval-limit="data.limit_enabled"
                                :approval-sequence="data.sequence_enabled"
                                :approver="approver"
                                :approvers="data.approvers"
                                :default-workflow="defaultWorkflow"
                                :edit="approver.can_edit"
                                :employees="availableEmployees(approver)"
                                :index="index"
                                :allow-limit-edit="allowLimitEdit"
                                :allow-drag="true"
                                :show-approval-note="false"
                                @select-employee="setNotSelectedEmployees"
                                @delete-approver="deleteApprover(index)"
                            />
                        </template>
                    </Draggable>
                </template>
                <template v-else>
                    <Draggable :animation="300" :list="data.approvers" handle=".fa-grip-vertical" tag="tbody" :item-key="item => data.approvers.indexOf(item)">
                        <template #item="{ element: approver, index }" >
                            <SingleApproverV2
                                v-if="!isStatusApprovedOrRejected(approver.approval?.status)"
                                :approval-limit="data.limit_enabled"
                                :approval-sequence="data.sequence_enabled"
                                :approver="approver"
                                :approvers="data.approvers"
                                :default-workflow="defaultWorkflow"
                                :edit="edit"
                                :employees="availableEmployees(approver)"
                                :index="index"
                                :allow-limit-edit="allowLimitEdit"
                                :show-approval-note="false"
                                :allow-drag="false"
                                @select-employee="setNotSelectedEmployees"
                                @delete-approver="deleteApprover(index)"
                            />
                        </template>
                    </Draggable>
                </template>
            </table>
            <div v-if="edit" class="io-add-approver" @click="addApprover">{{ $t('Add Approver') }}</div>
        </div>

        <WorkflowTemplatesPopup
            v-if="showWorkflowTemplatesPopup"
            @close="closeWorkflowTemplatesPopup"
            @setTemplate="applyTemplate"
        />

        <AssignedCostCodesPopup
            v-if="showAssignedCostCodesPopup"
            :assignedCostCodesCategories="assignedCostCodesCategories"
            :workflowName="workflowName"
            @close="closeAssignedCostCodesPopup"
        />

    </div>
</template>

<script lang="ts" src="./SingleWorkflow.ts"></script>
<style lang="scss" src="./SingleWorkflow.scss"></style>
