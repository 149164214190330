export enum Status {
    NOT_STARTED = 'NOT_STARTED',
    IN_PROGRESS = 'IN_PROGRESS',
    UNDER_REVIEW = 'UNDER_REVIEW',
    COMPLETED = 'COMPLETED',
    ARCHIVED = 'ARCHIVED',
}

export enum RouteOperation {
    CREATE = 'create',
    EDIT = 'edit',
}
