export function SET_BUDGETS_NUMBER (state, value) {
    state.budgets_number = value
}

export function SET_CHANGE_ORDERS_NUMBER (state, value) {
    state.change_orders_number = value
}

export function SET_BUDGET_CHANGES_NUMBER (state, value) {
    state.budget_changes_number = value
}

export function SET_INVOICES_NUMBER (state, value) {
    state.invoices_number = value
}

export function SET_MEETING_MINUTES_NUMBER (state, value) {
    state.meeting_minutes_number = value
}

export function SET_EMPLOYEE_STATUS (state, value) {
    state.employee_status = value
}
