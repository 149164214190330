import { defineComponent } from 'vue'
import FilesComponent from '@/components/files-new/files.vue'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import moment from 'moment'
import PopupMixin from '@/mixins/financial-approvals/PopupMixin'
import dateTimeHelper from '@/helpers/dateTime'
import { mapMutations } from 'vuex'

export default defineComponent({
    name: 'PopupClientApproval',
    components: {
        FilesComponent,
        FieldDateTime,
    },
    mixins: [PopupMixin],
    data () {
        return {
            approved_by: '' as string,
            approved_date: null as string | null,
            note: '' as string,
            filesIds: [] as string[],
            confirmText: '' as string,
            datepickerOptions: {
                'only-date': true,
                format: 'YYYY-MM-DD',
                formatted: dateTimeHelper.getDateFormat(),
            },
        }
    },
    computed: {
        isValid (): boolean {
            return (this.confirmText === 'CONFIRM' || this.confirmText === this.$t('CONFIRM'))
                && !!this.approved_date && !!this.approved_by
        },
    },
    beforeMount (): void {
        this.approved_date = moment().format('YYYY-MM-DD 12:00:00')
        this.$store.dispatch('chatWindow/setChatWindowComponent', false)
    },
    beforeUnmount (): void {
        this.$store.dispatch('chatWindow/setChatWindowComponent', true)
    },
    unmounted() {
        this.$store.dispatch('filesComponent/removeAllFilesComponent')
    },
    methods: {
        ...mapMutations('projectBudget',{
            setApprovedFromClient: 'SET_APPROVED_FROM_CLIENT',
        }),
        onFileAdd (file: any): void {
            this.filesIds.push(file._id)
        },
        onFileRemove (fileId: string): void {
            this.filesIds = this.filesIds.filter((id: string) => id !== fileId)
        },
        async recordClientApproval (): Promise<void> {
            try {
                this.loading = true

                await this.$api.post('/resource-client-approval', {
                    approvable_resource_name: this.activeResource,
                    resource_id: this.resourceId,
                    approved_by: this.approved_by,
                    approved_date: moment(this.approved_date).format('YYYY-MM-DD'),
                    note: this.note,
                    temporary_attached_documents: this.filesIds,
                })

                this.closePopup()
                this.showNotification('success', this.$t('Client Approval has been recorded.'))
                this.refreshResources()
                this.setApprovedFromClient(true)
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during recording client approval.'))
            } finally {
                this.loading = false
            }
        },
    },
})
