<template>
    <IOModal
        class="io-record-client-approval"
        width="647"
        icon="icon-thumbs-up"
        :title="$t('Record Client Approval')"
        :scrollable="true"
        :z-index="zIndex"
        @close="$emit('close')"
    >
        <template #default>
            <div class="io-record-client-approval__content">
                <div class="io-record-client-approval__content__info">
                    <div class="io-record-client-approval__content__info-item">
                        <IOLabel :type="LabelType.BoldMax" :text="$t('Approved by')" :isRequired="true" />
                        <IOInput aria-label="field-approver-name" v-model="approvedBy" />
                    </div>
                    <div class="io-record-client-approval__content__info-item">
                        <IOLabel :type="LabelType.BoldMax" :text="$t('Date Approved')" :isRequired="true" />
                        <FieldDateTime :options="datepickerOptions" :showIcon="true" v-model="date"/>
                    </div>
                </div>
                <div>
                    <IOLabel :type="LabelType.BoldMax" :text="$t('Type ‘CONFIRM’ to confirm Client approval and add a note.')" :isRequired="true" />
                    <IOInput
                        :placeholder="$t('Type ‘CONFIRM’ in Uppercase')"
                        aria-label="field-confirmation"
                        v-model="confirmInput"
                    />
                </div>
                <div>
                    <IOLabel :type="LabelType.BoldMax" :text="$t('Add Note')" />
                    <IOTextarea required aria-label="field-note" v-model="note" />
                </div>
                <FilesComponent
                    v-if="!hideFiles"
                    :key="filesArrayId"
                    modelType="resource_client_approval_record"
                    :arrayId="filesArrayId"
                    :showShared="false"
                    :forceHideSharedTab="true"
                    :showUploader="true"
                    :hideShareColumn="true"
                    :filesSection="resourceName"
                    :addToTemporary="true"
                    :filesInNewTab="true"
                    :showModalVersion="true"
                    :validationOptions="validationOptions"
                    :enable-file-extension-filtering-in-file-selector="true"
                    @newFileAdded="onFileAdd"
                    @tempFileRemove="onFileRemove"
                />
            </div>
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton :disabled="!isValid" @click="recordClientApproval">
                {{ $t('Record Client Approval') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./RecordClientApprovalModal.ts"></script>
<style lang="scss" src="./RecordClientApprovalModal.scss" scoped></style>
