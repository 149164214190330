<template>
    <div class="io-navbar-tile" v-click-outside="closeListOutside">

        <transition name="mask">
            <div class="io-navbar-tile__mask" v-if="showList" @click="toggleShowList"></div>
        </transition>

        <div class="io-navbar-tile__content" @click="toggleShowList">
            <div class="io-navbar-tile__icon"><i class="far fa-user-plus"></i></div>
            <div class="io-navbar-tile__dot" v-if="newInviteEvent">{{ items.length }}</div>
        </div>

        <transition name="notifications-menu">
            <div class="io-notifications-menu" v-if="showList" :class="{'io-empty': !items.length}">
                <div class="io-notifications-menu__header">
                    <div class="io-notifications-menu__title">{{ $t('Project Invitations') }}</div>
                    <div class="io-notifications-menu__indicator">{{ items.length }}</div>
                    <div class="io-notifications-menu__mark-read" @click="markAllAsRead('invitation')">
                        {{ $t('Mark all as read') }}
                    </div>
                </div>

                <template v-if="items && items.length">
                    <div class="io-notifications-menu__content">

                        <template v-for="item in items" :key="item._id">
                            <InvitationITem
                                            :item="item"
                                            :during-action="duringAction"
                                            @toggleAction="toggleAction"
                                            @reject="onReject"
                                            @closeList="closeList"
                            ></InvitationITem>
                        </template>

                    </div>
                </template>

                <template v-else>
                    <div class="io-messages-placeholder">
                        <div class="io-messages-placeholder__illustration">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 width="132" height="127" viewBox="0 0 132 127">
                                <defs>
                                    <path id="zo360qf0aa"
                                          d="M25.079 68.319c2.843 2.843 3.643 6.956 2.399 10.514 3.717-1.668 8.235-.975 11.288 2.077 3.135 3.136 3.781 7.818 1.937 11.591 3.492-1.066 7.443-.218 10.205 2.544 2.958 2.957 3.72 7.278 2.29 10.939 3.326-1.278 7.24-.575 9.924 2.108 3.635 3.635 3.635 9.53 0 13.165l-2.279 2.279c-3.635 3.635-9.53 3.635-13.165 0-2.642-2.643-3.364-6.479-2.165-9.773-3.693 1.521-8.1.78-11.101-2.222-2.942-2.942-3.712-7.234-2.31-10.882-3.62 1.401-7.884.64-10.805-2.28-3.053-3.053-3.746-7.572-2.079-11.29-3.556 1.245-7.669.445-10.512-2.398-3.956-3.956-3.956-10.37 0-14.325l2.047-2.047c3.956-3.956 10.37-3.956 14.326 0zM95.536.84c1.121-1.12 2.937-1.12 4.058 0l31.565 31.565c1.12 1.12 1.12 2.937 0 4.058l-4.898 4.898v9.931c-.214 5.2-1.966 10.218-5.036 14.42l-22.42-22.517c-4.092 2.75-8.918 4.204-13.849 4.172-5.929.066-11.674-2.06-16.132-5.969L47.758 54.76c-2.824 1.908-6.462 2.129-9.496.577-3.034-1.552-4.984-4.63-5.09-8.037-.04-2.694 1.04-5.285 2.985-7.15L63.093 13.2c4.775-4.775 11.25-7.459 18.004-7.46h9.541z"/>
                                </defs>
                                <g fill="none" fill-rule="evenodd">
                                    <g fill-rule="nonzero">
                                        <g>
                                            <g>
                                                <path fill="var(--accent)"
                                                      d="M122.746 67.228L68.906 13.2c-4.774-4.775-11.25-7.459-18.003-7.46h-9.541L36.464.84c-1.121-1.12-2.937-1.12-4.058 0L.841 32.407c-1.12 1.12-1.12 2.937 0 4.058l4.898 4.898v9.931c0 10.523 8.815 19.04 8.815 19.04l47.27 51.606c3.357 3.384 8.4 4.455 12.841 2.727 4.442-1.727 7.436-5.925 7.623-10.687 3.536 2.794 8.526 2.801 12.07.02 3.546-2.783 4.724-7.632 2.851-11.73 4.25 1.553 9.011.571 12.3-2.536 3.288-3.108 4.536-7.807 3.225-12.137 5.098.681 10.062-1.97 12.331-6.587 2.27-4.616 1.337-10.165-2.316-13.787l-.003.006z"
                                                      transform="translate(-782 -181) translate(726 80) translate(56 101)"/>
                                                <g transform="translate(-782 -181) translate(726 80) translate(56 101)">
                                                    <use fill="#FFF" xlink:href="#zo360qf0aa"/>
                                                    <use fill="var(--accent)" fill-opacity=".2"
                                                         xlink:href="#zo360qf0aa"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="io-messages-placeholder__content">
                            <div class="io-messages-placeholder__title">{{ $t('No Invitations') }}</div>
                            <div class="io-messages-placeholder__caption">
                                {{ $t('top_bar_no_invitations_message') }}
                            </div>
                        </div>
                    </div>
                </template>

            </div>
        </transition>

    </div>
</template>

<script src="./invitations.js"></script>
