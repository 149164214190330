import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'

import { DrawingSheet } from '@/io-modules/drawings/interfaces/DrawingSheet.ts'
import { SetStatus } from '@/io-modules/drawings/enums/SetStatus.ts'
import { ProcessingDrawingSetSheetResponse } from '@/io-modules/drawings/interfaces/ProcessingDrawingSetSheetResponse.ts'
import { ProcessingDrawingSetListItem } from '@/io-modules/drawings/interfaces/ProcessingDrawingSetListItem.ts'
import { ConfigurationPayload } from '@/io-modules/drawings/interfaces/Ocr.ts'
import { DrawingSheetModel, DrawingSheetPayload } from '@/io-modules/drawings/interfaces/DrawingSheetModel.ts'

const sheetsClient = {
    getSheets: (setId: string, status: SetStatus[] = []): AxiosPromise<{
        items: DrawingSheet[],
        taken: number
    }> =>
        client.post(`/ca/drawing/lazy-list/${ setId }/0`, { filters: { status } }),

    getProcessingSheet: (id: string): AxiosPromise<ProcessingDrawingSetSheetResponse> =>
        client.get(`/ca/drawing/processing/${ id }`),

    updateProcessingSheet: (id: string, payload: {
        page_label: string,
        page_title: string,
        page_category: string,
        is_published: boolean
    }): AxiosPromise<ProcessingDrawingSetSheetResponse> =>
        client.patch(`/ca/drawing/processing/${ id }`, payload),

    getProcessingSetListSheets: (projectId: string): AxiosPromise<{ items: ProcessingDrawingSetListItem[] }> =>
        client.get(`/ca/drawing/processing/list/${ projectId }`),

    applyToAll: (id: string): AxiosPromise<void> =>
        client.post(`/ca/drawing/processing/${ id }/ocr/configuration/apply_to_other`),

    configureSheet: (id: string, payload: ConfigurationPayload): AxiosPromise<void> =>
        client.post(`/ca/drawing/processing/${ id }/ocr/configuration`, payload),

    getVersionSheet: (id: string): AxiosPromise<DrawingSheetModel> =>
        client.post(`ca/drawing/version/${ id }`),

    updateDrawingSheet: (payload: DrawingSheetPayload): AxiosPromise<void> =>
        client.post('/ca/drawing/save-sheet', payload),

    updateManySheetsCategory: (ids: string[], category: string): Promise<void> =>
        client.post('/ca/drawing/update-category', {
            ids: ids,
            category: category,
        }),

    getDrawingSheets: (id: string): AxiosPromise<{ items: DrawingSheet[] }> =>
        client.post(`ca/drawing/get-list/${ id }`),
}

export default sheetsClient
