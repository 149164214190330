<template>
    <PopupModal :width="730" class="io-popup-workflow-templates" @close="close">

        <template #header>
            <div class="io-popup-modal__title">{{ $t('Select Template') }}</div>
            <div class="io-popup-modal__close" @click="close"><i class="fal fa-times"></i></div>
        </template>

        <template #headerContent>
            <SearchInput
                :loader="false"
                :startLength="0"
                :timeout="0"
                :placeholder="`${ $t('Search') }...`"
                @searchChanged="onSearchChanged"
            />
        </template>


        <template v-if="!isEmpty(filteredTemplates)">
            <div
                v-for="template in filteredTemplates"
                :class="{ 'io-active': template._id === activeTemplateId }"
                class="io-single-workflow-template"
                @click="setActiveTemplate(template._id)"
            >
                <div class="io-single-workflow-template__icon">
                    <i class="far fa-list-alt"></i>
                </div>
                <div class="io-single-workflow-template__content">
                    <div class="io-f-row">
                        <div class="io-single-workflow-template__name">
                            {{ template.name }}
                        </div>
                        <div class="io-single-workflow-template__date">
                            {{ $t('Updated at') }} {{ $filters.parseDate(template.updated_at) }}
                        </div>
                    </div>
                    <div v-if="template.description" class="io-single-workflow-template__description">
                        {{ $filters.filterEmptyValue(template.description) }}
                    </div>
                </div>
            </div>
        </template>

        <Placeholder
            v-else-if="!loading"
            icon="icon-list-unordered-2-rec"
            :subtitle="$t('Make sure you have typed the correct list name.')"
            :title="$t('No results found')"
        />



        <template #footer>
            <IOButton variant="secondary" @click="close">{{ $t('Cancel') }}</IOButton>
            <IOButton :disabled="!activeTemplate" @click="setTemplate">{{ $t('Select') }}</IOButton>
        </template>

    </PopupModal>
</template>

<script lang="ts" src="./WorkflowTemplatesPopup.ts"></script>
<style lang="scss" src="./WorkflowTemplatesPopup.scss"></style>
