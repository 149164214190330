import { defineComponent } from 'vue'
import { debounce, isNil, orderBy } from 'lodash'
import TableRow from './parts/table-row/TableRow.vue'
import ModalMixin from '../../mixins/modalMixin'
import ModalFilters from '@/components/ModalFilters/ModalFilters.vue'
import FetchingRow from '@/components/lazy-list/parts/fetching-row/FetchingRow.vue'
import FilterSection from '@/components/lazy-list/parts/filter-section/FilterSection.vue'
import GroupCheckbox from '@/components/lazy-list/parts/group-checkbox/GroupCheckbox.vue'
import ServerSideGroupCheckbox
    from '@/components/lazy-list/parts/server-side-group-checkbox/ServerSideGroupCheckbox.vue'
import InvitationButtonGroup from '@/components/lazy-list/parts/group-invitation-button/InvitationButtonGroup.vue'
import Filters from './parts/filters/Filters.vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import { ItemRouteInterface } from '@/interfaces/components/lazy-list/ItemRoute'
import { FilterInterface } from '@/interfaces/components/lazy-list/FilterInterface'
import { FilterSectionInterface } from '@/interfaces/components/lazy-list/FilterSectionInterface'
import { mapActions, mapGetters } from 'vuex'
import { LazyListFiltersHelper } from '@/components/lazy-list/helpers/LazyListFiltersHelper'
import { FilterValue } from '@/components/lazy-list/types/FilterValue.ts'
import { parseQueryObject, parseSearchParamsString, stringifyFilters } from '@/components/lazy-list/helpers/filters.ts'
import ManageColumnsModal from '@/components/manage-columns-modal/ManageColumnsModal.vue'
import ColumnSettings from '@/components/manage-columns-modal/interfaces/ColumnSettings'

export default defineComponent({
    components: {
        TableRow,
        ModalFilters,
        Filters,
        FetchingRow,
        FilterSection,
        GroupCheckbox,
        ManageColumnsModal,
        ServerSideGroupCheckbox,
        InvitationButtonGroup
    },
    props: {
        endpoint: { type: String, required: true },
        apiVersion: { type: Number, default: 1 },
        filtersEndpoint: { type: String, default: null },
        columns: {
            type: Array as () => ColumnInterface[],
            required: true,
            default: () => [],
        },
        manageColumnsList: {
            type: Array as () => ColumnInterface[],
            required: false,
            default: () => [],
        },
        method: { type: String, default: null },
        dataPropertyName: { type: String, default: 'items' },
        filtersSchema: { type: Array as () => FilterInterface[], default: () => [] },
        filterSections: {
            type: Array as () => FilterSectionInterface[],
            default: null,
        },
        itemRoute: {
            type: [ Object as () => ItemRouteInterface, null ],
            default: null,
        },
        pillsColorFunction: { type: Function, default: (value) => value },
        isDisabled: { type: Function, default: () => false },
        filterPillsColorFunction: { type: Function, default: null },
        dotsColorFunction: { type: Function, default: null },
        customStatusTextFunction: { type: Function, default: null },
        noReroute: { type: Boolean, default: false },
        manageColumns: { type: Boolean, default: false },
        pillsTextFunction: { type: Function, default: null },
        showIconToolTip: { type: Function, default: null },
        mockupRecords: { type: Array as () => any[] },
        fetchedRecords: { type: Array as () => any[] },
        selectedRows: { type: Array as () => any[], default: () => [] },
        tableRowClass: { type: String, default: '' },
        tableRowClassFunction: {
            type: Function, default: () => {
            },
        },
        customClassFunction: { type: Function, default: () => [] },
        fluidHeader: Boolean,
        showFetchedRecords: { type: Boolean, default: false },
        dataModifier: { type: Function, default: (value: any) => value },
        initialSelectedRowByRadioId: { type: Number, default: null },
        isSecondLazyListOnSameView: { type: Boolean, default: false },
        isLoadAllRecords: { type: Boolean, default: false },
        isEnableFrontEndSorting: { type: Boolean, default: false },
        isStrictWidth: { type: Boolean, default: false },
        allowToExpandRow: { type: Boolean, default: false },
        showCustomComponentWhenNoRecords: { type: Boolean, default: false },
        shouldFetchFiltersEveryOpenModal: { type: Boolean, default: true },
        disableBookmarking: { type: Boolean, default: false },
        filtersStorageKey: { type: String, default: null },
    },
    mixins: [ModalMixin],
    data () {
        return {
            prevScroll: 0,
            data: [] as any[],
            selectedRowByRadioId: null,
            showManageColumnsModal: false,
            expandedRows: [],
        }
    },

    computed: {
        ...mapGetters('lazyList', {
            getRecords: 'getRecords',
            isFetching: 'isFetching',
            isLastPage: 'isLastPage',
            sortBy: 'sortBy',
            sortDirection: 'sortDirection',
            filters: 'filters',
            showFilters: 'showFilters',
            filtersSelected: 'filtersSelected',
            selectedFiltersOverload: 'selectedFiltersOverload',
            isEditMode: 'isEditMode',
            getEditedRecords: 'getEditedRecords',
            exceptionResponse: 'exceptionResponse',
            isFetchAllRecords:'isFetchAllRecords',
            isFrontEndSortingEnabled:'isFrontEndSortingEnabled',
            filtersApplied: 'filtersApplied',
        }),
        records (): any[] {
            if (this.showFetchedRecords) {
                return this.fetchedRecords
            }

            if (this.isEditMode) {
                return this.getEditedRecords
            }

            const records = this.dataModifier(this.getRecords)

            return this.isFrontEndSortingEnabled
                ? orderBy(records, [this.sortBy], this.sortDirection.toLowerCase())
                : records
        },
        countFilters (): number {
            return LazyListFiltersHelper.countFilters(this.filtersSelected, this.filters)
        },
        fixedColumns (): ColumnSettings[] {
            return this.manageColumnsList.filter((col: ColumnSettings) => col.fixed)
        },
        scrollableColumns (): ColumnSettings[] {
            return this.manageColumnsList.filter((col: ColumnSettings) => !col.fixed)
        },
        hasFixedColumns (): boolean {
            return !!this.fixedColumns?.length
        }
    },

    watch: {
        exceptionResponse (): void {
            if (this.exceptionResponse?.code) {
                this.errorHandleNoRedirect(this.exceptionResponse)
            }
        },
        showFilters (newValue: boolean, oldValue: boolean): void {
            if (!oldValue && newValue && (!this.filters?.length || this.shouldFetchFiltersEveryOpenModal)) {
                this.getFiltersData()
            }
        },
        filtersApplied: {
            handler () {
                this.expandedRows = []
            },
            deep: true
        },
    },

    async created () {
        this.selectedRowByRadioId = this.initialSelectedRowByRadioId
        if (this.$route.query?.filters && !this.filtersStorageKey) {
            const filters = JSON.parse(this.$route.query?.filters)
            filters.forEach((filter: Object) => {
                const [ field, value ] = Object.entries(filter)[0]
                this.$store.dispatch('lazyList/setSingleFilter', { field, value: value.split(',') })
            })
        }

        if (this.isLoadAllRecords) {
            await this.$store.dispatch('lazyList/setIsFetchAllRecords', true)
        }

        if (this.isEnableFrontEndSorting) {
            await this.$store.dispatch('lazyList/setIsFrontEndSortingEnabled', true)
        }

        this.isLoadAllRecords
            ? await this.fetchAllRecords()
            : this.$nextTick(() => {
                const element = this.$refs.scrollableArea
                if (element) {
                    element.addEventListener('scroll', this.handleScroll)
                }

                if (!this.isSecondLazyListOnSameView) {
                    window.visualViewport.addEventListener('resize', this.fetchAdditionalRecords)
                }
            })
    },
    async beforeMount (): Promise<void> {
        await this.restoreFilters()
        this.$store.dispatch('lazyList/setConfig', {
            endpoint: this.endpoint,
            filtersEndpoint: this.filtersEndpoint,
            method: this.method,
            dataPropertyName: this.dataPropertyName,
            apiVersion: this.apiVersion,
        })
    },
    mounted () {
        this.setLoadingBar(true)
        try {
            this.$store.dispatch('lazyList/getListData', true)
        } catch (e) {
            this.showNotification('error', e.response.data.message)
        } finally {
            this.setLoadingBar(false)
        }

        this.setFixedTable()
    },
    updated () {
        if (!this.isSecondLazyListOnSameView && !this.showFetchedRecords && !this.isFetchAllRecords) {
            this.fetchAdditionalRecords()
        }
    },
    beforeUnmount () {
        const element = this.$refs.scrollableArea
        if (element) {
            element.removeEventListener('scroll', this.handleScroll)
        }

        this.$store.dispatch('lazyList/setIsServerDefaultFiltersDisabled', false)
        this.$store.dispatch('lazyList/resetSearch')
        this.$store.dispatch('lazyList/resetSortBy')
        this.$store.dispatch('lazyList/setConfig', {
            endpoint: null,
            method: null,
        })
        this.$store.dispatch('lazyList/resetFiltersSelected')
    },
    methods: {
        ...mapActions('lazyList', [
            'getFiltersData',
        ]),
        clearSavedFilters (): void {
            if (!this.filtersStorageKey) {
                return
            }
            sessionStorage.removeItem(this.filtersStorageKey)
            this.$router.replace({ query: {} })
        },
        async saveFilters (): Promise<void> {
            if (!this.filtersStorageKey) {
                return
            }

            const buildFilters = {}

            if (this.filters) {
                for (let i = 0; i < this.filters.length; i++) {
                    const filter = this.filters[i]
                    if (filter?.settings?.mapIds) {
                        if (this.filtersSelected[filter.name].length) {
                            buildFilters[filter.name] = this.filtersSelected[filter.name].map((item) => item.id)
                        }
                    }
                }
            }

            let filtersToStore = { ...this.filtersSelected }

            if (buildFilters) {
                filtersToStore = {
                    ...filtersToStore,
                    ...buildFilters
                }

                await this.$store.dispatch('lazyList/setSelectedFiltersOverloadData', buildFilters)
            }

            const filtersString = stringifyFilters(filtersToStore)
            await this.$router.replace(`${this.$route.path}?${filtersString}`)
            sessionStorage.setItem(this.filtersStorageKey, filtersString)
        },
        isRouteIncludesFilters (): boolean {
            if (!Object.keys(this.$route.query).length) {
                return
            }
            return Object.keys(parseQueryObject(this.$route.query)).some((key: string): boolean => (
                this.filtersSchema.some((filter: FilterInterface): boolean => filter.field === key)
            ))
        },
        isSessionStorageIncludesFilters (): boolean {
            return !!sessionStorage.getItem(this.filtersStorageKey)
        },
        async setFilters (filters: Record<string, FilterValue>): Promise<void> {
            for (const [key, value] of Object.entries(filters)) {
                await this.$store.dispatch('lazyList/setSingleFilter', { field: key, value: value })
            }
        },
        async restoreFilters (): Promise<void> {
            if (!this.filtersStorageKey) {
                return
            }

            if (this.isRouteIncludesFilters()) {
                await this.$store.dispatch('lazyList/setIsServerDefaultFiltersDisabled', true)
                await this.setFilters(parseQueryObject(this.$route.query))
                return
            }

            if (this.isSessionStorageIncludesFilters()) {
                await this.$store.dispatch('lazyList/setIsServerDefaultFiltersDisabled', true)
                const searchParams = sessionStorage.getItem(this.filtersStorageKey)
                await this.$router.replace(`${this.$route.path}?${searchParams}`)
                await this.setFilters(parseSearchParamsString(searchParams))
            }
        },
        actionSelect (action: object): void {
            this.$emit('actionSelect', action)
        },
        cursorStyle (col: any): { cursor: string } {
            return {
                cursor:
                    col.additional && col.additional.sortHidden
                        ? 'default'
                        : 'pointer',
            }
        },
        emitIconAction (action: object): void {
            this.$emit('emitIconAction', action)
        },
        changeFavorite (id: number): void {
            this.$emit('changeFavorite', id)
        },
        sortHidden (col: any): boolean {
            return col.additional?.sortHidden
                ? col.additional?.sortHidden
                : false
        },
        async applyFilters (): Promise<void> {
            await this.saveFilters()
            this.$store.dispatch('lazyList/setWithCounters', true)
            this.$store.dispatch('lazyList/getListData', true)
            this.onCloseFilters()
        },
        radioClick (value: any): void {
            this.selectedRowByRadioId = value.id || value._id
            this.$emit('radioClick', value)
        },
        tableRowClick (value: any): void {
            this.$emit('tableRowClick', value)
        },
        onCloseFilters (): void {
            this.$store.dispatch('lazyList/setShowFilters', false)
            this.$store.dispatch('modal/setShow', false, { root: true })
        },
        async changeSort (col: any): Promise<void> {
            if (col.additional?.sortHidden || this.isEditMode) {
                return
            }

            await this.$store.dispatch(
                'lazyList/changeSortDirection',
                this.sortDirection === 'ASC' ? 'DESC' : 'ASC',
            )
            await this.$store.dispatch(
                'lazyList/changeSortBy',
                col.additional?.sortByCustomField ?? col.field,
            )

            if (!this.isFrontEndSortingEnabled) {
                await this.$store.dispatch('lazyList/getListData', true)
            }
        },
        isColumnVisible (col: ColumnInterface): boolean {
            if (col.additional?.visible === false) {
                return false
            }

            if (!this.isEditMode) {
                return !col.additional?.hiddenInStaticMode
            }

            return true
        },
        handleScroll: debounce(function (this: any, event: any): void {
            if (this.isFetching) { return }

            const table = this.$refs.scrollableArea
            const tableHeight = table ? table.offsetHeight : 0

            const currentScroll = event.target.scrollTop
            const scrollHeight = event.target.scrollHeight

            if (currentScroll > this.prevScroll) {
                this.prevScroll = currentScroll
            } else {
                this.prevScroll = currentScroll
                return
            }

            if (this.isLastPage) {
                return
            }

            // offset 10
            if (currentScroll >= scrollHeight - tableHeight - 10) {
                this.$store.dispatch('lazyList/getListData')
            }
        }, 300),
        async onClearAllFilters (): Promise<void> {
            this.clearSavedFilters()
            this.$store.dispatch('lazyList/resetFiltersSelected')
        },
        iconAction (id: string): void {
            this.$emit('iconAction', id)
        },
        onInviteButtonClick (id: string): void {
            this.$emit('inviteButtonClick', id)
        },
        onInvitationUpdate (): void {
            this.$emit('invitationUpdate')
        },
        onInviteButtonNewClick (data: any): void {
            this.$emit('inviteButtonNewClick', data)
        },
        fetchAdditionalRecords (): void {
            const wrapper = this.$refs.scrollableArea
            const row = document.querySelector('.io-project-data-item')
            const isLastPage = this.$store.getters['lazyList/isLastPage']

            if (null !== wrapper && null !== row && false === isLastPage) {
                const recordsHeight = this.getRecords.length * row.clientHeight

                if (recordsHeight < wrapper.clientHeight) {
                    this.$store.dispatch('lazyList/getListData')
                }
            }
        },
        async fetchAllRecords (): Promise<void> {
            await this.$store.dispatch('lazyList/getListData')
        },

        onToggleExpandTableRow (index: number): void {
            const isExpanded = this.expandedRows.find((id: number) => id === index)

            if (!isNil(isExpanded)) {
                this.expandedRows = this.expandedRows.filter((id: string) => id !== index)
            } else {
                this.expandedRows.push(index)
            }

            this.$emit('expandRow', index)
        },
        toggleManageColumnsModal (show: boolean): void {
            this.showManageColumnsModal = show
        },
        saveManageColumnsSettings (e): void {
            console.log('e', e)
        },
        setFixedTable (): void {
            if (!this.manageColumns) {
                return
            }
            const wrapper = document.querySelector('.io-main-container > div')

            if (this.hasFixedColumns) {
                wrapper.classList.add('io-scroll-horizontal')
            } else {
                wrapper.classList.remove('io-scroll-horizontal')
            }
        }
    },
})
