import { defineStore } from 'pinia'
import InvoicesRevisionStoreState from '@/io-modules/invoices/interfaces/stores/InvoicesRevisionStoreState.ts'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV.ts'
import SoVLineItem from '@/io-modules/invoices/interfaces/SoVLineItem.ts'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView.ts'
import reviseResubmitClient, {
    RevisionRequestClientRequestedData, RevisionRequestComment,
    RevisionRequestData, RevisionRequestItem,
    RevisionRequestVendorAppliedData,
} from '../api-clients/reviseResubmitClient'
import { keyBy } from 'lodash'
import InvoiceStatus from '@/io-modules/invoices/enums/InvoiceStatus.ts'
import SoVLineItemField from '@/io-modules/invoices/enums/SovLineItemField.ts'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode.ts'

export const invoiceRevisionStore = defineStore('invoiceRevision', {
    state: (): InvoicesRevisionStoreState => ({
        revisionLoaded: false,
        revisionRequest: null,
        invoiceId: null,
        projectGlobalId: null,
        revisionRequestPromise: null,
        requestRevisionV2Enabled: false,
        adjustmentsUpdatesCount: 0,
        fieldsUsedInRevisionRequest: [
            SoVLineItemField.CurrentApplication,
            SoVLineItemField.MaterialsStored,
            SoVLineItemField.CurrentRetentionAmount,
            SoVLineItemField.MaterialsStoredRetention,
            SoVLineItemField.RetentionReleased,
            SoVLineItemField.CurrentRetentionPercent
        ]
    }),
    getters: {
        shouldLoadRevision (): boolean {
            const invoiceStore = invoiceViewStore()

            return [InvoiceStatus.RequestedRevision, InvoiceStatus.RevisionRequested,
                InvoiceStatus.InternallyRejected, InvoiceStatus.PendingInternalApproval].includes(invoiceStore.invoice.status)
        },

        //Checks if client or vendor need(ed) to perform any action on the item
        //Should return true also for those items where the user didn't yet perform any action
        needAttention (): Function {
            return (item: SoVLineItem) => {
                const invoiceView = invoiceViewStore()

                if (!item.adjustments) {
                    item.needAttention = false
                    return false
                }

                if (invoiceView.isClient) {
                    /// When I'm the client, I need to attend to all vendor's adjustments
                    item.needAttention = null !== (item.adjustments?.vendor_applied?.current_application ?? null)

                } else if (invoiceView.isVendor) {
                    /// When I'm vendor, I need to attend to all client's revision requests
                    item.needAttention = null !== (item.adjustments?.client_requested?.current_application ?? null)
                }

                return item.needAttention
            }
        },

        isItemRevisedByVendor (): Function {
            return (item: SoVLineItem) => {

                if (!item.needAttention) {
                    return false
                }

                //We treat item as revised if any of the vendor's adjustments is applied
                item.revisedByVendor = null !== (item.adjustments?.vendor_applied?.current_application ?? null)

                return item.revisedByVendor
            }
        },

        isItemRevisedByClient (): Function {
            return (item: SoVLineItem) => {

                if (!item.needAttention) {
                    return false
                }

                //We treat item as revised if any of the vendor's adjustments is applied
                item.revisedByClient = null !== (item.adjustments?.client_requested?.current_application ?? null)

                return item.revisedByClient
            }
        },

        isItemAdjustedByClient (): Function {
            return (item: SoVLineItem) => {
                if (!item.adjustments) {
                    return false
                }

                const adjustmentFromClientSideExists = null !== (item.adjustments?.client_requested?.current_application ?? null)
                if (!adjustmentFromClientSideExists) {
                    return false
                }

                if (this.isItemAcceptedByClient(item)) {
                    return false
                }

                return true
            }
        },

        isItemAcceptedByClient (): Function {
            return (item: SoVLineItem) => {

                if (!item.needAttention) {
                    return false
                }

                //We treat item as accepted if all clients requests matches what vendor applied
                item.acceptedByClient = this.fieldsUsedInRevisionRequest.every(
                    field => item.adjustments?.client_requested?.[field] == item.adjustments?.vendor_applied?.[field]
                )

                return item.acceptedByClient
            }
        },

        isItemAcceptedByVendor (): Function {
            return (item: SoVLineItem) => {

                if (!item.needAttention) {
                    return false
                }

                if (null === (item.adjustments?.client_requested?.current_application ?? null)) {
                    //If this item was not requested by client, then it cannot be accepted by vendor
                    return false
                }

                //We treat item as accepted if all clients requests matches what vendor applied
                item.acceptedByVendor = this.fieldsUsedInRevisionRequest.every(
                    field => item.adjustments?.client_requested?.[field] == item.adjustments?.vendor_applied?.[field]
                )

                return item.acceptedByVendor
            }
        },

        clientAdjustedItemsTotal (): number {
            const invoiceSovStore = invoiceSoVStore()
            this.adjustmentsUpdatesCount
            const calculatedNumberOfClientAdjustedLines = (lineItems: SoVLineItem[]): number => {
                let totalAdjustedItems = 0
                lineItems.forEach(item => {

                    if (invoiceSovStore.hasSubTasksItem(item)) {
                        totalAdjustedItems += calculatedNumberOfClientAdjustedLines(item.children)
                    } else {
                        if (this.isItemAdjustedByClient(item)) {
                            totalAdjustedItems++
                        }
                    }
                })
                return totalAdjustedItems
            }

            if (SoVTableViewMode.COsInSeparateCategory === invoiceSovStore.tableViewMode) {
                return calculatedNumberOfClientAdjustedLines(invoiceSovStore.contractWBS) + calculatedNumberOfClientAdjustedLines(invoiceSovStore.changeOrdersWBS)
            } else {
                return calculatedNumberOfClientAdjustedLines(invoiceSovStore.changeOrderWithinOriginalScope)
            }

        },

        /**
         * Count the number of items that need attention before user can proceed with submitting the invoice to approval
         */
        needAttentionItemsTotal (): number {
            const invoiceSovStore = invoiceSoVStore()
            this.adjustmentsUpdatesCount

            const calculatedNumberOfNeedAttentionLines = (lineItems: SoVLineItem[]): number => {
                let totalAdjustedItems = 0
                lineItems.forEach(item => {

                    if (invoiceSovStore.hasSubTasksItem(item)) {
                        totalAdjustedItems += calculatedNumberOfNeedAttentionLines(item.children)
                    } else {
                        if (this.needAttention(item)) {
                            totalAdjustedItems++
                        }
                    }
                })
                return totalAdjustedItems
            }

            if (SoVTableViewMode.COsInSeparateCategory === invoiceSovStore.tableViewMode) {
                return calculatedNumberOfNeedAttentionLines(invoiceSovStore.contractWBS) + calculatedNumberOfNeedAttentionLines(invoiceSovStore.changeOrdersWBS)
            } else {
                return calculatedNumberOfNeedAttentionLines(invoiceSovStore.changeOrderWithinOriginalScope)
            }
        },

        needAttentionItemsCompleted (): number {
            this.adjustmentsUpdatesCount

            const invoiceSovStore = invoiceSoVStore()
            const invoiceView = invoiceViewStore()

            const recursiveCount = (item) => {
                let count = 0
                if (invoiceSovStore.hasSubTasksItem(item)) {
                    item.children.forEach(child => count += recursiveCount(child))
                } else {
                    if (!item.needAttention) { return 0 }
                    if (invoiceView.isClient) {
                        if (this.isItemAcceptedByClient(item)) {
                            count++
                        }
                    } else if (invoiceView.isVendor) {
                        if (this.isItemRevisedByVendor(item)) {
                            count++
                        }
                    }
                }
                return count
            }
            let count = 0

            if (SoVTableViewMode.COsInSeparateCategory === invoiceSovStore.tableViewMode) {
                count = invoiceSovStore.contractWBS.reduce((count, item) => count + recursiveCount(item), count)
                count = invoiceSovStore.changeOrdersWBS.reduce((count, item) => count + recursiveCount(item), count)
            } else {
                count = invoiceSovStore.changeOrderWithinOriginalScope.reduce((count, item) => count + recursiveCount(item), count)
            }

            return count
        },

        revisionCompleted (): boolean {
            return this.needAttentionItemsTotal <= this.needAttentionItemsCompleted
        },

        clientMustRevise (): boolean {
            const invoiceView = invoiceViewStore()
            return invoiceView.isClient && this.clientAdjustedItemsTotal > 0
        }
    },
    actions: {
        async fetchRevisionRequest (projectGlobalId: string, invoiceId: string): Promise<RevisionRequestData> {
            const result = await reviseResubmitClient.getRevisionRequest(projectGlobalId, invoiceId)
            this.revisionRequest = result.data
            this.projectGlobalId = projectGlobalId
            this.invoiceId = invoiceId
        },

        toLoadAdjustmentsIntoWbsItems (items: SoVLineItem[]): void {
            if (!this.revisionRequest?.adjustments?.length) {
                const clearAdjustments = (item) => {
                    item.adjustments = null

                    if (item.children && item.children.length > 0) {
                        item.children.forEach(clearAdjustments)
                    }
                }

                items.forEach(clearAdjustments)

                return
            }

            const sovStore = invoiceSoVStore()
            const adjustmentsByContractWbsId = keyBy(this.revisionRequest.adjustments, a => a.contract_wbs_id)

            const applyAdjustments = (item) => {
                let contractWbsIdCopy = sovStore.getRealContractWbsId(item)

                const adjustment = adjustmentsByContractWbsId[contractWbsIdCopy]
                item.adjustments = adjustment || null
                this.recalculateAdjustedItem(item)

                if (item.children && item.children.length > 0) {
                    item.children.forEach(applyAdjustments)
                }
            }

            items.forEach(applyAdjustments)
        },

        recalculateAdjustedItem (item: SoVLineItem): void {
            const invoiceSovStore = invoiceSoVStore()

            item.needAttention = this.needAttention(item)
            item.revisedByVendor = this.isItemRevisedByVendor(item)
            item.revisedByClient = this.isItemRevisedByClient(item)
            item.acceptedByClient = this.isItemAcceptedByClient(item)
            item.acceptedByVendor = this.isItemAcceptedByVendor(item)

            if (invoiceSovStore.hasSubTasksItem(item)) {
                item.children?.forEach(child => this.recalculateAdjustedItem(child))
            }
            this.adjustmentsUpdatesCount++
        },

        async loadAdjustmentsIntoWbsItems (projectGlobalId: string, invoiceId: string): Promise<void> {
            this.revisionLoaded = false
            await this.fetchRevisionRequest(projectGlobalId, invoiceId)

            const sovStore = invoiceSoVStore()

            this.toLoadAdjustmentsIntoWbsItems(sovStore.contractWBS)
            this.toLoadAdjustmentsIntoWbsItems(sovStore.changeOrdersWBS)
            this.toLoadAdjustmentsIntoWbsItems(sovStore.changeOrderWithinOriginalScope)

            this.revisionLoaded = true
        },

        async saveAdjustedItemAsClient (item:SoVLineItem, data: RevisionRequestClientRequestedData, comments: RevisionRequestComment[]): Promise<void> {

            const contractWbsId = invoiceSoVStore().getRealContractWbsId(item)

            if (this.areSubmittedValuesEqual(item, data) && null === item.adjustments?.vendor_applied) {
                //If client requested and submitted are equal, and there is no vendor adjustments then we can remove this position from the revision request
                delete item.adjustments
                await this.removeSingleRevisedItem(contractWbsId)
            } else {
                let adjustments = this.revisionRequest.adjustments.find(item => item.contract_wbs_id === contractWbsId)
                adjustments.client_requested = data
                adjustments.comments = comments
                await this.saveSingleRevisedItem(contractWbsId, adjustments)
            }

            const invoiceView = invoiceViewStore()
            await this.loadAdjustmentsIntoWbsItems(this.projectGlobalId, invoiceView.invoice.id)
        },

        areSubmittedValuesEqual (item: SoVLineItem, equalTo: RevisionRequestClientRequestedData): boolean {
            return this.fieldsUsedInRevisionRequest.every(
                field => item[field] == equalTo[field]
            )
        },

        async saveAdjustedItemAsVendor (item:SoVLineItem, data: RevisionRequestVendorAppliedData, comments: RevisionRequestComment[]): Promise<void> {
            const sovStore = invoiceSoVStore()

            const contractWbsId = sovStore.getRealContractWbsId(item)

            const adjustment = this.revisionRequest.adjustments.find(item => item.contract_wbs_id === contractWbsId)
            if (!adjustment) {
                throw new Error('Adjustment not found')
            }
            adjustment.vendor_applied = data
            adjustment.comments = comments

            this.fieldsUsedInRevisionRequest.forEach(field => {
                item[field] = data[field]
            })

            this.toLoadAdjustmentsIntoWbsItems(sovStore.contractWBS)
            this.toLoadAdjustmentsIntoWbsItems(sovStore.changeOrdersWBS)
            this.toLoadAdjustmentsIntoWbsItems(sovStore.changeOrderWithinOriginalScope)
        },

        async saveSingleRevisedItem (contractWbsId: string, itemData: RevisionRequestItem): Promise<void> {
            try {
                await reviseResubmitClient.saveSingleRevisedItem(
                    this.projectGlobalId,
                    this.invoiceId,
                    contractWbsId,
                    itemData
                )
            } catch (e) {
                console.error(e)
            }
        },

        async removeSingleRevisedItem (contractWbsId: string): Promise<void> {
            try {
                await reviseResubmitClient.removeSingleRevisedItem(
                    this.projectGlobalId,
                    this.invoiceId,
                    contractWbsId
                )

                const invoiceView = invoiceViewStore()
                await this.loadAdjustmentsIntoWbsItems(this.projectGlobalId, invoiceView.invoice.id)
            } catch (e) {
                console.error(e)
            }
        },

        async clearAllAdjustments (): Promise<void> {
            try {
                await reviseResubmitClient.clearAllRevisions(this.projectGlobalId, this.invoiceId)
                await this.loadAdjustmentsIntoWbsItems(this.projectGlobalId, this.invoiceId)
            } catch (e) {
                console.log(e)
            }
        }
    }
})
