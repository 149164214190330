import {mapState} from 'vuex'
import SingleNotification from './parts/single-notification.vue'
import SingleAlert from './parts/single-alert.vue'

export default {
    name: 'notices',
    components: {
        SingleNotification,
        SingleAlert
    },
    computed: {
        ...mapState('notices', {
            noticesList: state => state.notices
        }),
        ...mapState('notices', {
            alertsList: state => state.alerts
        })
    }
}