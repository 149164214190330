import { defineComponent } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import Table from '@/components/table/Table.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'

import { HeaderTable } from '@/components/table/HeaderTableInterface.ts'
import type { Person } from '@/components/drawing-reference-select/interfaces/Person.ts'
import type { Set } from '@/components/drawing-reference-select/interfaces/Set.ts'
import { cloneDeep } from 'lodash'

export default defineComponent({
    name: 'SelectSetModal',
    components: { Table, IOPlaceholder, IOModal },
    props: {
        loading: Boolean,
        sets: {
            type: Array as () => Set[],
            required: true
        },
    },
    emits: [
        'close',
        'setSelected',
        'addNewDrawings'
    ],
    data () {
        return {
            selectedSet: cloneDeep(this.sets.length ? this.sets[0] : null) as Set,
        }
    },
    computed: {
        tableHeaders (): HeaderTable[] {
            return [
                {
                    text: this.$t('Set Name'),
                    value: 'name'
                },
                {
                    text: this.$t('Uploaded By'),
                    value: 'created_by_full_name',
                    sort: (item: Person) => item.name,
                },
                {
                    text: this.$t('Issue Date'),
                    value: 'issue_date',
                },
                {
                    text: this.$t('Sheets'),
                    value: 'drawings_count',
                },
            ]
        },
    },
    methods: {
        close (): void {
            this.$emit('close')
        },
    }
})
