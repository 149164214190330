import SingleTabNew from './parts/tab-new/tab.vue'
import { mapState } from 'vuex'
import LazyLoad from './parts/lazy-load/LazyLoad.vue'
import projectObjMixin from '@/modules/projects/mixins/projectObjMixin.js'
import IOButton from '../atoms/IOButton/IOButton'
import ToggleSwitch from '@/components/atoms/ToggleSwitch/ToggleSwitch.vue'
import FileUploader from './parts/file-uploader/FileUploader.vue'
import filesMixin from '@/components/files-new/mixins/filesMixin.ts'
import { isEmpty, uniqueId, cloneDeep } from 'lodash'
import FilesTabs from './parts/files-tabs/FilesTabs.vue'
import { FilesUploaderVariantEnum } from '@/components/files-new/enums/FilesUploaderVariantEnum.ts'
import { FilesTabEnum } from './enums/FilesTabEnum.ts'
import { FilesUploaderViewTypeEnum } from './enums/FilesUploaderViewTypeEnum.ts'

export default {
    mixins: [ projectObjMixin, filesMixin ],
    name: 'Files',
    components: {
        FilesTabs,
        IOButton,
        SingleTabNew,
        LazyLoad,
        ToggleSwitch,
        FileUploader,
    },
    props: {
        arrayId: {
            type: String,
            required: true
        },
        filesSection: {
            type: String,
            required: false,
            default: null
        },
        subSection: {
            type: String,
            required: false,
            default: null
        },
        showFiles: {
            type: Boolean,
            required: false,
            default: true
        },
        showSearch: {
            type: Boolean,
            required: false,
            default: true
        },
        addToTemporary: {
            type: Boolean,
            required: false
        },
        modelType: {
            type: String,
            required: true
        },
        showUploader: {
            type: Boolean,
            required: false,
            default: true
        },
        forceShared: {
            type: Boolean,
            required: false,
            default: false
        },
        filesInNewTab: {
            type: Boolean,
            required: false,
            default: false
        },
        hideShareColumn: {
            type: Boolean,
            required: false,
            default: false
        },
        activeTab: {
            type: Number,
            required: false,
            default: 0
        },
        initialTab: {
            type: Number,
            required: false,
            default: null
        },
        modelId: {
            type: [String, Number],
            required: false,
            default: null
        },
        view: {
            type: String,
            required: false
        },
        validationOptions: {
            type: Object,
            required: false,
            default: () => ({
                allowedExtensions: [],
                acceptFiles: []
            })
        },
        isDocumentsView: {
            type: Boolean,
            required: false,
            default: false
        },
        inDocumentsUpload: {
            type: Boolean,
            required: false,
            default: false
        },
        isSearchView: {
            type: Boolean,
            required: false,
            default: false
        },
        isProjectView: {
            type: Boolean,
            required: false,
            default: false
        },
        isTrashedView: {
            type: Boolean,
            required: false,
            default: false
        },
        projectLocalId: {
            type: String,
            required: false,
            default: null
        },
        forceHideSharedTab: {
            type: Boolean,
            required: false,
            default: false
        },
        showShared: {
            type: Boolean,
            required: false,
            default: false
        },
        lockHotkeys: {
            type: Boolean,
            required: false,
            default: false
        },
        defaultShare: {
            type: Boolean,
            required: false,
            default: false
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        threadId: {
            type: String,
            required: false,
            default: null
        },
        modeSingleFile: {
            type: Boolean,
            required: false,
            default: false
        },
        colorVariantLight: {
            type: Boolean,
            required: false,
            default: false
        },
        allTabsVisible: {
            type: Boolean,
            required: false,
            default: false
        },
        customRouteId: {
            type: String,
            required: false,
            default: null
        },
        editableActions: {
            type: Array,
            required: false,
            default: () => []
        },
        showImportantToggle: {
            type: Boolean,
            required: false,
            default: true
        },
        showModalVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        multipleFiles: {
            type: Boolean,
            required: false,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        noFilesFiltering: {
            type: Boolean,
            required: false,
            default: false
        },
        showViewToggle: {
            type: Boolean,
            default: true,
        },
        variant: {
            type: String,
            default: FilesUploaderVariantEnum.NORMAL,
        },
        enableFileExtensionFilteringInFileSelector: {
            type: Boolean,
            default: false,
        },
        hasRequiredSign: {
            type: Boolean,
            default: false,
        }
    },
    data () {
        return {
            activeHeaderTab: this.activeTab,
            searchKeyword: '',
            clonedModelId: null,
            uploaderKey: this.arrayId ? this.arrayId : uniqueId(),
            applySorting: true,
            lazyLoadKey: 0,
            selectedView: FilesUploaderViewTypeEnum.GRID,
            addButtonDropdownVisible: false,
            isAddingFile: false
        }
    },
    computed: {
        ...mapState('filesComponent', {
            my_documents: state => state.my_documents,
            shared_documents: state => state.shared_documents,
            trash_documents: state => state.trash_documents,
            syncAllSharedFiles: state => state.syncAllSharedFiles
        }),
        ...mapState('main', {
            routerHistory: (state) => state.routerHistory
        }),
        ...mapState('appStore', {
            authData: state => state.authData
        }),
        isGridView () {
            return this.selectedView === FilesUploaderViewTypeEnum.GRID
        },
        isSkinnyVariant () {
            return this.variant === FilesUploaderVariantEnum.SKINNY
        },
        my_documents_files () {
            return this.filterFiles(this.$store.getters['filesComponent/getMyFiles'](this.arrayId))
        },
        shared_documents_files () {
            return this.filterFiles(this.$store.getters['filesComponent/getSharedFiles'](this.arrayId))
        },
        trash_documents_files () {
            return this.filterFiles(this.$store.getters['filesComponent/getTrashedFiles'](this.arrayId))
        },
        inProgressFiles () {
            return this.filterFiles(this.$store.getters['filesComponent/getUploadingFilesInProgress'](this.arrayId))
        },
        hasSharedFilesToSync () {
            return !!(this.shared_documents_files.find((file) => file.can_be_shared))
        },
        allowAutoSync () {
            return this.folderFlags._sync_all_shared_files || this.syncAllSharedFiles
        },
        countShared () {
            let filesToShare = this.shared_documents_files.filter(item => item.can_be_shared)
            return filesToShare.length
        },
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        showTabs () {
            return !isEmpty(this.folderFlags) ? this.folderFlags._is_model_folder || this.folderFlags.model_type === 'construction_documents' : true
        },
        showTrashTab () {
            return this.showTabs || (this.folderFlags && this.folderFlags._is_trash)
        },
        showSharedTab () {
            return !this.forceHideSharedTab && (this.showTabs || (this.folderFlags && (this.folderFlags._is_shared_with_me || this.folderFlags._is_shared_with_others)))
        },
        toggleSwitchOptions () {
            return {
                items: {
                    style: 'text',
                    delay: 0,
                    preSelected: this.selectedView,
                    disabled: false,
                    labels: [
                        { name: FilesUploaderViewTypeEnum.LIST, text: `<i class="icon-list-unordered-2"></i> ${ this.$t('List') }` },
                        { name: FilesUploaderViewTypeEnum.GRID, text: `<i class="icon-grid-13"></i> ${ this.$t('Grid') }` },
                    ],
                },
            }
        },
        tabs () {
            const tabsArr = [
                {
                    id: FilesTabEnum.MY_FILES,
                    name: this.$t('My Files'),
                    show: this.showFiles,
                    count: this.my_documents_files.length
                },
                {
                    id: FilesTabEnum.SHARED_WITH_ME,
                    name: this.$t('Shared with me'),
                    show: this.isTabVisible(1),
                    count: this.countShared
                },
                {
                    id: FilesTabEnum.TRASH,
                    name: this.$t('Trash'),
                    show: false,
                    count: this.trash_documents_files.length
                }
            ]
            return tabsArr.filter(item => {
                return item.show
            })
        },
        createNewFolderInProjectPhotos () {
            return this.$store.getters['filesComponent/getCreateNewFolderInProjectPhotos']
        },
        endpoint () {
            return `/projects-photos/documents/getLazyList/${ this.projectObj.project_global_id }`
        },
        isPhotoFolderRoot () {
            if (!this.folderFlags) {
                return false
            }
            return this.folderFlags.folder_type === 'main_model' && this.folderFlags.model_type === 'project_photos_new'
        },
        singleTabProps () {
            const props = {}
            if (this.activeHeaderTab === 0) {
                props.key = 'mydocstab' + this.arrayId
                props.type = 'myFiles'
            }

            if (this.activeHeaderTab === 1) {
                props.key = 'shareddocstab' + this.arrayId
                props.type = 'sharedFiles'
                props.sync = true
            }

            if (this.activeHeaderTab === 2) {
                props.key = 'trasheddocstab' + this.arrayId
                props.type = 'trashedFiles'
                props.fromTrash = true
            }
            return props
        },
        routeId () {
            return this.customRouteId !== null ? this.customRouteId : this.$route.params.id
        },
        isMyFilesTab () {
            return this.activeHeaderTab === 0
        },
        singleUploadAndFilesAdded () {
            return !this.multipleFiles && (this.my_documents_files?.length || this.inProgressFiles.length || this.isAddingFile)
        },
        hideAddFilesDropdown () {
            return !this.isMyFilesTab || this.singleUploadAndFilesAdded
        },
        isBulkDeleteDisplayed () {
            return this.getSelectedFilesForActions.some(file => file._can_delete === true)
        }
    },
    methods: {
        async handleAutoSync () {
            if (this.hasSharedFilesToSync && this.allowAutoSync) {
                const notice = {
                    id: 'notice_file_sync',
                    title: 'Syncing files',
                    icon: 'fas fa-sync-alt fa-spin',
                    timer: 5000
                }
                this.showNotice(notice)
                await this.syncAllSharedDocumentFiles()
            }
        },
        async syncAllSharedDocumentFiles () {
            const modelIdTemp = this.modelId || this.routeId
            const filesToSync = this.shared_documents_files.reduce((filesToSync, file) => {
                if (file.can_be_shared) {filesToSync.push(file._id)}
                return filesToSync
            }, [])
            try {
                await this.$api.post(`/copy-shared-document-all/${ this.modelType }/${ modelIdTemp }`, { params: { files: filesToSync } })
                this.shared_documents_files.forEach((file) => {
                    file._is_on_sync_queue = true
                })
                this.$forceUpdate()
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
        syncSharedStatusUpdate (data) {
            const syncedFile = this.shared_documents_files.find(file => {
                return file._id === data.fileId
            })
            console.log('synced shared file', syncedFile, data)
            if (syncedFile !== undefined) {
                if (data.status === 'success') {
                    // move file to My Files Tab
                    let toMyFiles = cloneDeep(syncedFile)
                    toMyFiles._shared_to_my_document_id = false
                    toMyFiles._id = data.fileCopyId
                    toMyFiles.can_be_shared = true
                    toMyFiles._can_delete = true
                    toMyFiles._can_rename = true

                    this.$store.dispatch('filesComponent/addMyDoc', {
                        arrayId: this.arrayId,
                        file: toMyFiles
                    })

                    // update file on Shared with me Tab
                    syncedFile._is_on_sync_queue = false
                    syncedFile._shared_to_my_document_id = true
                    syncedFile.can_be_shared = false
                } else if (data.status === 'failed') {
                    syncedFile.sync_failed = true
                } else if (data.status === 'already-synced') {
                    syncedFile.can_be_shared = false
                }
            }
        },
        onDocumentAddNewUploader (doc) {
            this.isAddingFile = true
            // dont glitch upload from the library modal when selecting first file
            setTimeout(() => {
                this.onDocumentAdd(doc)
                this.isAddingFile = false
            }, 200)
        },
        onDocumentAdd (doc) {
            if (doc._isFromLibrary) {
                doc.document.creator_full_name = this.authData.u_firstname + ' ' + this.authData.u_lastname
            }
            if (doc._isFromLibrary && this.filesSection) {
                doc.document.section = this.filesSection
            } else if (doc._isFromLibrary && !this.isDocumentsView) {
                doc.document.section = 'default'
            }

            if (this.modeSingleFile) {
                const files = this.$store.getters['filesComponent/getMyFiles'](this.arrayId)
                files.filter(file => file.is_temporary_upload && file.section === this.filesSection)
                    .forEach(file => {
                        this.$store.dispatch('filesComponent/hardRemoveMyDoc', {
                            arrayId: this.arrayId,
                            fileId: file._id
                        })
                    })
            }
            this.$store.dispatch('filesComponent/addMyDoc', {
                arrayId: this.arrayId,
                file: doc.document
            })
            this.$emit('newFileAdded', doc)
        },
        onForceRefresh () {
            this.$forceUpdate()
            this.$emit('forceUpdate')
        },
        tempFileRemoved (fileId) {
            this.$emit('tempFileRemove', fileId)
        },
        fileRemoved (fileId) {
            this.$emit('fileRemove', fileId)
        },
        clearSearch () {
            this.$emit('clearSearch')
            this.searchKeyword = ''
        },
        setModelId () {
            const id = this.modelId ? this.modelId : this.routeId
            this.clonedModelId = (!this.addToTemporary && !this.modelId) ? id : this.modelId
        },
        reloadUploader () {
            this.uploaderKey = uniqueId()
        },
        isDocumentsRelatedRoute (routeName) {
            return [
                'documents-details',
                'project-documents-details',
                'project-documents-list',
                'documents-list'
            ].includes(routeName)
        },
        setActiveView () {
            if (this.isSkinnyVariant) {
                this.selectedView = FilesUploaderViewTypeEnum.LIST
            }
        },
        setActiveTab () {
            if (this.initialTab !== null) {
                this.activeHeaderTab = this.initialTab
                this.initialTab = null
                return
            }

            if (this.folderFlags._is_trash) {
                this.activeHeaderTab = 2
            } else if (this.folderFlags._is_shared_with_others || this.folderFlags._is_shared_with_me) {
                this.activeHeaderTab = 1
            } else {
                this.activeHeaderTab = 0
            }
        },
        isTabVisible (id) {
            if (this.allTabsVisible) {
                return true
            }

            if (id === 1) {
                return this.showSharedTab && !this.addToTemporary
            }

            if (id === 2) {
                return this.showTrashTab && !this.addToTemporary
            }
        },
        toggleView (option) {
            this.selectedView = option.value
        },
        recordsFetched (records) {
            if (!this.isPhotoFolderRoot) {
                return
            }
            const files = records.filter(f => f.is_folder === false)
            const folders = records.filter(f => f.is_folder === true)

            this.$store.dispatch('filesComponent/setMyDocs', {
                arrayId: this.arrayId,
                files: files
            })

            this.$store.dispatch('filesComponent/setFolders', {
                arrayId: this.arrayId,
                folders: folders
            })
            this.applySorting = false
            this.$forceUpdate()
        },
        filterFiles (files) {
            if (this.noFilesFiltering) {
                return files
            }
            if (this.filesSection && !this.subSection) {
                return files.filter(item => (item.section || null) === this.filesSection)
            }
            if (this.filesSection && this.subSection) {
                return files.filter(
                    item =>
                        (item.section || null) === this.filesSection &&
                        ((item.section_uuid || null) === this.subSection || item.folder_mongo_id)
                )
            }
            if (!this.isDocumentsView) {
                return files.filter(item => (item.section || 'default') === 'default')
            }
            return files
        },
        clearFilesSelection () {
            this.removeAllSelectedFilesForAction(this.arrayId)
        },
        showAddButtonDropdown () {
            if (this.hideAddFilesDropdown) {
                return
            }
            this.addButtonDropdownVisible = true
        },
        hideAddButtonDropdown () {
            this.addButtonDropdownVisible = false
        },
        bulkDownload () {
            let endpoint = this.$api.getApi2RequestUrl('/documents/download')
            const files = this.getSelectedFilesForActions

            const filesQuery = files.map((value, index) => {
                return 'files[' + index + ']=' + value._id
            }).join('&')

            window.open(`${ endpoint }?${ filesQuery }`, '_blank', 'noopener')

            this.clearFilesSelection()
        },
        async bulkDelete () {
            await this.onItemToTrash(this.$t('Selected files').toLowerCase(), true)
        },
        onDrop (event) {
            this.$refs.ioFileUploader.onDrop(event)
        }
    },
    beforeMount () {
        if (!this.my_documents || !this.my_documents[this.arrayId]) {
            this.$store.dispatch('filesComponent/setMyDocs', {
                arrayId: this.arrayId,
                files: []
            })
        }
        this.setModelId()
        this.setActiveTab()
        this.setActiveView()
    },
    watch: {
        'activeHeaderTab' (value) {
            this.searchKeyword = ''
            if (value !== 0) {
                this.$store.dispatch('filesComponent/setCreateNewFolderInProjectPhotos', false)
            }
        },
        'modelId' () {
            this.setModelId()
        },
        '$route' () {
            this.setModelId()
        },
        'folderFlags' () {
            this.setActiveTab()

            if (this.isPhotoFolderRoot) {
                this.lazyLoadKey += 1
                this.$store.dispatch('filesComponent/setMyDocs', {
                    arrayId: this.arrayId,
                    files: []
                })

                this.$store.dispatch('filesComponent/setFolders', {
                    arrayId: this.arrayId,
                    folders: []
                })
            }
        },
        'hasSharedFilesToSync': {
            handler (newVal) {
                if (newVal) {
                    this.handleAutoSync()
                } else {
                    this.hideNotice('notice_file_sync')
                }
            },
            immediate: true
        },
        'createNewFolderInProjectPhotos': {
            handler (value) {
                if (value) {
                    this.activeHeaderTab = 0
                }
            }
        },
        'searchKeyword' (value) {
            this.lazyLoadKey += 1

            if (this.isPhotoFolderRoot) {
                this.$store.dispatch('lazyList/setSearch', value)
            }
        }
    },
    created () {
        this.$nextTick(() => {
            this.$echo.private('WorkspaceChannel.' + this.authData.company_mongo_id)
                .listen('Document.Events.ModelShareDocumentEvent', this.syncSharedStatusUpdate)
            this.$store.dispatch('filesComponent/setupActionsObject', this.arrayId)
        })
    },
    unmounted () {
        this.$echo.private('WorkspaceChannel.' + this.authData.company_mongo_id)
            .stopListening('Document.Events.ModelShareDocumentEvent')
    },
    beforeUnmount () {
        if (this.isDocumentsView) {
            if (this.routerHistory && this.routerHistory.routerTo) {
                if (!this.isDocumentsRelatedRoute(this.routerHistory.routerTo.name)) {
                    this.$store.dispatch('filesComponent/setFolderFlags', {})
                    this.$store.dispatch('filesComponent/clearBreadcrumbs', [])
                    this.$store.dispatch('filesComponent/setSearchPaths', [])
                    this.$store.dispatch('filesComponent/setSearchVal', '')
                    this.$store.dispatch('filesComponent/clearDocsArr', {
                        arrayId: this.arrayId,
                        files: []
                    }, { root: true })
                }
            }
        }

        this.$store.dispatch('filesComponent/setFolderFlags', {})
        this.$store.dispatch('filesComponent/setProjectPhotosFolder', null)
    }
}
