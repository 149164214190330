import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import SearchInputNew from '@/components/search-input/SearchInput.vue'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'
import useCollapseButton from '@/components/jobsite-locations/composables/collapseComposable.ts'
import AsidePlaceholder from '@/components/jobsite-locations/components/aside-placeholder/AsidePlaceholder.vue'
import SelectedJobsiteLocations
    from '@/components/jobsite-locations/components/selected-jobsite-locations/SelectedJobsiteLocations.vue'
import JobsiteLocationsAccordion from '@/components/jobsite-locations/components/jobsite-locations-accordion/JobsiteLocationsAccordion.vue'
import { mapState, mapStores } from 'pinia'
import { jobsiteLocationsStore } from '@/components/jobsite-locations/store/jobsiteLocationsStore.ts'
import {
    buildTreeWithSearchResults,
    buildSelectedLocationsPaths,
} from '@/components/jobsite-locations/helpers'
import { JobsiteLocationsInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsInterface.ts'
import IOSwitcher from '@/components/atoms/IOSwitcher/IOSwitcher.vue'
import { mapGetters as mapVuexGetters } from 'vuex'


export default defineComponent({
    name: 'JobsiteLocationsModal',
    components: {
        JobsiteLocationsAccordion,
        SelectedJobsiteLocations,
        AsidePlaceholder,
        IOButton,
        PopupModal,
        SearchInputNew,
        IOSwitcher,

    },
    props: {
        selectMultiple: { type: Boolean, default: true },
        projectId: { type: [Boolean, null], default: null },
    },
    emits: ['close', 'save'],
    setup () {
        const { collapseButtonText, collapseButtonTextIcon, onClickCollapseButton } = useCollapseButton()

        return {
            collapseButtonText,
            collapseButtonTextIcon,
            onClickCollapseButton,
        }
    },
    data () {
        return {
            searchVal: '',
            isShowArchived: false,
            isListUpdated: false,
            isFetching: false,
        }
    },
    computed: {
        ...mapStores(jobsiteLocationsStore),
        ...mapState(jobsiteLocationsStore, [
            'jobsiteLocations',
            'selectedJobsiteLocationsIds',
            'selectedTreeJobsiteLocationsIds'
        ]),

        ...mapVuexGetters('project', ['projectCompanyGlobalId']),

        selectedLocationsPath (): JobsiteLocationsInterface[][] {
            return buildSelectedLocationsPaths(this.selectedJobsiteLocationsIds, this.jobsiteLocations)
        },
        disabledTreesParentsIds (): string[] {
            const parentsIds = this.jobsiteLocations.map((jobsiteLocation: JobsiteLocationsInterface) => jobsiteLocation.id)
            const notSelectedParent = parentsIds.filter((id: string) => !this.selectedTreeJobsiteLocationsIds.includes(id))

            if (!this.selectedTreeJobsiteLocationsIds.length) {
                return []
            }

            return this.selectMultiple ? [] : notSelectedParent
        },

        filteredLocations (): JobsiteLocationsInterface[] {
            return buildTreeWithSearchResults(this.jobsiteLocations, this.searchVal, this.selectedJobsiteLocationsIds, !this.isShowArchived)
        }
    },
    watch: {
        'filteredLocations': {
            handler (): void {
                this.isListUpdated = true

                setTimeout(() => {
                    this.isListUpdated = false
                }, 10)
            },
            deep: true,
        },

    },
   async beforeMount (): Promise<void> {
        await this.fetchLocations()
    },
    methods: {
        async fetchLocations (): Promise<void> {
            if (this.jobsiteLocations?.length) {
                return
            }

            this.isFetching = true

            try {
                this.setLoadingBar(true)
                await this.jobsiteLocationsStore.fetchJobsiteLocations(this.projectId || this.projectCompanyGlobalId)
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.setLoadingBar(false)
                this.isFetching = false
            }
        },

        onClose (): void {
            this.$emit('close')
        },
        onSave (): void {
            this.$emit('save')
        },
        onSearchValueChange (value: string): void {
            this.searchVal = value

            this.jobsiteLocationsStore.clearSelectedTreeLocationsIds()
        },
    },
})
