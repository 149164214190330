import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CustomDropdown',
    props: {
        options: {
            type: Array as () => Array<any>,
            required: true
        },
        optionLabel: {
            type: Function,
            required: true
        },
        selectedLabel: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dropdownExpanded: false
        }
    },
    methods: {
        toggleDropdown (): void {
            if (!this.disabled) {
                this.dropdownExpanded = !this.dropdownExpanded
            }
        },
        closeDropdown (): void {
            this.dropdownExpanded = false
        },
        select (option: any): void {
            this.$emit('select', option)
        }
    }
})
