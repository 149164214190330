<template>
    <div class="io-cost-codes-group">
        <div
            v-tooltip.bottom.start="{ content: 'Used in another sequence', delay: 0, visible: hasDisabledCode }"
            :class="{ 'io-minus': someChecked || hasDisabledCode, 'io-disabled': hasDisabledCode }"
            class="io-atoms-checkbox io-group-check"
        >
            <label>
                <input :checked="allChecked" :disabled="hasDisabledCode" type="checkbox" @click="toggleAll"/>
                <span>{{ category.code_name_formatted }}</span>
            </label>
        </div>
        <div
            v-for="code in category.codes"
            :key="code.id"
            v-tooltip.bottom.start="{ content: 'Used in another sequence', delay: 0, visible: isDisabled(code.id) }"
            :class="{ 'io-disabled': isDisabled(code.id)}"
            class="io-atoms-checkbox io-option"
        >
            <label>
                <input
                    :checked="isChecked(code.id) || isDisabled(code.id)"
                    :disabled="isDisabled(code.id)"
                    type="checkbox"
                    @click="toggleCode(code.id)"
                />
                <span>{{ code.code_name }}</span>
            </label>
        </div>
    </div>
</template>

<script lang="ts" src="./GroupCheck.ts"></script>
