const FundingSourceMainLayout = () => import(/* webpackChunkName: "funding-source" */ './views/layout/main.vue')
const FundingSourceProviders = () => import(/* webpackChunkName: "funding-source" */ './views/providers/providers.vue')
const FundingSourceProviderSources = () => import(/* webpackChunkName: "funding-source" */ './views/funding-sources/funding-sources.vue')
const FundingSourceDetails = () => import(/* webpackChunkName: "funding-source" */ './views/details/details.vue')
const FundingSourceCreate = () => import(/* webpackChunkName: "funding-source" */ './views/create/create.vue')

const basicBreadcrumbs = {
    breadcrumbs: [
        {
            text: 'Funding Sources',
            to: { name: 'funding-source-providers' },
        }
    ]
}

export default [
    {
        path: '/funding-source',
        name: 'funding-source',
        component: FundingSourceMainLayout,
        redirect: { name: 'funding-source-providers' },
        children: [
            {
                path: 'list',
                name: 'funding-source-providers',
                async beforeEnter (to, from, next) {
                    next({ name: 'funding-sources-providers' })
                },
                component: FundingSourceProviders,
                meta: {
                    title: 'Funding Source List'
                }
            },
            {
                path: 'list/:id',
                name: 'funding-source-provider-sources',
                component: FundingSourceProviderSources,
                meta: {
                    title: 'Funding Source List',
                    ...basicBreadcrumbs,
                }
            },
            {
                path: 'list/:id/create',
                name: 'funding-source-create',
                component: FundingSourceCreate,
                meta: {
                    title: 'Create Funding Source',
                    ...basicBreadcrumbs,
                }
            },
            {
                path: 'view/:id',
                name: 'funding-source-details',
                component: FundingSourceDetails,
                meta: {
                    title: 'Funding Source Details',
                    ...basicBreadcrumbs,
                }
            }
        ]
    }
]
