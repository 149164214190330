<template>
    <div id="io-top-nav">
        <div :class="['io-container', showNotifications ? 'io-notification' : '']">
            <div class="io-navbar-right" :class="{'io-app-logo-expanded': hoverLeftMenu || lockLeftMenu}">
                <transition name="io-menu-mask">
                    <div v-if="topBarActive" class="io-menu-mask" @click="closeTopBarMenu()"></div>
                </transition>
                <div class="io-actions" :class="{ 'io-active': topBarActive === 'element-1'}" @click="openTopBarMenu('element-1')">
                    <div class="io-icon">
                        <img src="/new-theme/img/actions.svg" alt=""/>
                    </div>
                    <p>Actions</p>
                </div>

                <div class="io-tiles-wrapper">

                    <Invitations :newInviteEvent="newInviteEvent"
                                 :items="invitationsArr"
                                 @clearNotifications="markAllAsRead"
                                 @clearDot="onClearDot" />

                    <Discussions :newChatEvent="newChatEvent"
                                 :items="chat.items"
                                 @clearNotifications="markAllAsRead"
                                 @clearDot="onClearDot" />

                    <Notifications :newNotificationEvent="newNotificationEvent"
                                   :items="alerts.items"
                                   @clearNotifications="markAllAsRead"
                                   @clearDot="onClearDot" />

                    <Profile/>

                </div>

                <ul class="io-actions-menu" :class="{ 'io-active': topBarActive === 'element-1'}">
                    <template v-for="(item, index) in orderBy(actions, ['order'])">
                        <li v-if="item.condition" :key="index">
                            <a href="javascript:" @click.stop="executeMethod(item.onClick, item.onClickParam)">
                                <div class="io-img-holder">
                                    <img :src="item.icon" alt=""/>
                                </div>
                                <p>{{ item.label }}</p>
                            </a>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script src="./top-bar.js"></script>
<style lang="scss" src="./top-bar.scss"></style>
