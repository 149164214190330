import ClientApprovalSetting from '@/interfaces/modules/projects/financial-approvals/ClientApprovalSetting'
import FinancialApprovalsState from '@/interfaces/store/FinancialsApprovalsState'
import ApprovalsInboxEmptyFilters from '@/constants/ApprovalsInboxEmptyFilters'
import { cloneDeep } from 'lodash'

import ApprovableResourceSubtype from '@/interfaces/modules/projects/financial-approvals/ApprovableResourceSubtype'

const financialApprovalsState: FinancialApprovalsState = {
    hasConfiguredWorkflows: false,
    companyProjectResourceId: null,
    approvalSubmissionCompanyId: null,
    resourceId: null,
    activeResource: null,
    approvalWorkflows: [],
    internalApproval: null,
    manualApproval: null,
    manualRejection: null,
    clientApproval: null,
    fullApproval: null,
    fullRejection: null,
    internalRejection: null,
    submittedForApproval: null,
    currentChangeOrder: null,
    currentAmendment: null,
    currentContract: null,
    approvalsLoaded: false,
    phases: [],
    phase: null,
    isApprovalBlockedByExpiredInsurance: false,
    isSubmittingBlockedByExpiredInsurance: false,
    clientApprovalSetting: ClientApprovalSetting.RECORD_APPROVAL,
    externalClientApproval: null,
    approvableResourceSubtype: ApprovableResourceSubtype.MY_BUDGET,
    approvalsInboxTabsCount: {
        user_pending: 0,
        company_pending: 0,
        company_rejected: 0
    },
    approvalsInboxSearch: '',
    approvalsInboxUserPendingFilters: cloneDeep(ApprovalsInboxEmptyFilters),
    approvalsInboxCompanyPendingFilters: cloneDeep(ApprovalsInboxEmptyFilters),
    approvalsInboxCompanyRejectedFilters: cloneDeep(ApprovalsInboxEmptyFilters),
    singleApprovalActionSelectCallback: null,
    costCodesEnabled: false,
    isFromOtherParty: false,
}

export default financialApprovalsState
