<template>
    <Placeholder
        v-if="(!invoicesToDisplay || !invoicesToDisplay.length) && !dataLoading"
        icon="icon-invoice"
        :title="$t('Looks like there aren\'t any approved vendor invoices to attach right now')"
        :subtitle="$t('Once a vendor invoice is approved and isn\'t attached elsewhere, you will see it here!')"
    />
    <InvoicesListTable
        v-else-if="!dataLoading"
        :headers="vendorsInvoicesHeaders"
        :items="invoicesToDisplay"
        :loading-data="loadingData"
        :manageColumns="false"
        @loadData="loadData"
    />
</template>

<script lang="ts" src="./AttachVendorInvoices.ts"></script>
<style lang="scss" src="./AttachVendorInvoices.scss" scoped></style>
