import { mapState, mapActions } from 'pinia'
import { defineComponent, PropType } from 'vue'
import AppPackageRouteName from '../../../enums/AppPackageRouteName'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import { appPackageViewStore } from '../../../stores/appPackageView'
import { mapState as mapStateVuex, mapGetters as mapGettersVuex } from 'vuex'
import ActionData from '@/io-modules/invoices/interfaces/ActionData'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName'
import ConfirmationModalsService from '../../../services/ConfirmationModalsService'
import MarkAsPaidModal from '../modals/mark-as-paid/MarkAsPaidModal.vue'
import AppPackageAvailableActions from '../../../enums/AppPackageAvailableActions.ts'
import appPackageClient from '../../../api-clients/appPackageClient'
import ApplicationPackageStatus from '@/io-modules/invoices/enums/ApplicationPackageStatus.ts'
import ApprovalConfirmationModalService
    from '@/io-modules/approval-workflows/services/SimpleConfirmationModalsService.ts'
import Resource from '@/io-modules/approval-workflows/enums/Resource.ts'
import InternalRejectionModal from '../modals/internal-rejection/InternalRejectionModal.vue'
import ApprovalAction from '@/io-modules/approval-workflows/enums/ApprovalAction'
import ApprovalModalData from '@/io-modules/approval-workflows/interfaces/ApprovalModalData'
import ApprovalModalType from '@/io-modules/approval-workflows/enums/ApprovalModalType'

export default defineComponent({
    name: 'TopBarActions',
    components: {
        MarkAsPaidModal,
        ActionDropdown,
        InternalRejectionModal
    },
    props: {
        currentStep: {
            type: Number,
            default: 0,
        },
        stepsCount: {
            type: Number,
            default: 0,
        },
        allStepsCompleted: {
            type: Boolean,
            default: true,
        },
        approvalModalData: {
            type: Object as PropType<ApprovalModalData>,
            default: () => ({}),
        },
    },
    emits: [
        'nextStep',
        'prevStep',
        'update:approvalModalData',
    ],
    expose: ['saveAsDraft', 'initRevertToDraft', 'initMarkAsSubmittedToClient', 'initSendToClient', 'toggleRejectionModal'],
    data () {
        return {
            AppPackageRouteName,
            loading: false,
            showMarkAsPaidModal: false,
            showRejectionModal: false
        }
    },
    computed: {
        ...mapState(appPackageViewStore, [
            'isDetailsFilled',
            'creating',
            'editing',
            'isEditingMode',
            'areAttachedInvoices',
            'appPackage',
            'availableActions',
            'isDraft',
            'attachedInvoicesList',
            'attachedInvoicesIDs',
            'someAttachedInvoicesInternallyNotApproved',
        ]),

        ...mapStateVuex('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id,
        }),

        isLastStep (): boolean {
            return this.currentStep + 1 === this.stepsCount
        },

        canSaveAsDraft (): boolean {
            return this.creating
        },

        dropdownActions (): ActionData[] {
            return [
                {
                    iconClass: 'icon-cross',
                    text: this.$t('Reject'),
                    onClick: () => this.toggleRejectionModal(true),
                    visible: this.isActionAvailable(AppPackageAvailableActions.Reject),
                },
                {
                    iconClass: 'icon-edit',
                    text: this.$t('Edit'),
                    onClick: () => this.setEditModeValue(true),
                    visible: this.allStepsCompleted && this.isDraft,
                },
                {
                    iconClass: 'icon-user-check',
                    text:  this.$t('Record Client Approval'),
                    onClick: () => this.initRecordClientApproval(),
                    disabled: !this.attachedInvoicesList?.length || this.someAttachedInvoicesInternallyNotApproved,
                    visible: this.isActionAvailable(AppPackageAvailableActions.RecordClientApproval) && this.isActionAvailable(AppPackageAvailableActions.MarkAsSubmittedToClient),
                },
                {
                    iconClass: 'icon-trash',
                    text: this.$t('Delete'),
                    onClick: () => this.initDelete(),
                    visible: this.isDraft,
                },
                {
                    iconClass: 'icon-rotate-ccw',
                    text:  this.$t('Revert to Draft'),
                    onClick: () => this.initRevertToDraft(),
                    visible: this.isActionAvailable(AppPackageAvailableActions.RevertToDraft),
                },
                {
                    iconClass: 'icon-rotate-ccw',
                    text:  this.$t('Revert to Pending'),
                    onClick: () => this.initRevertToPending(),
                    visible: this.isActionAvailable(AppPackageAvailableActions.RevertToPending),
                },
            ]
        },

        primaryActions (): ActionData[] {
            return [
                {
                    text: this.$t('Attach Invoice'),
                    onClick: () => this.goToAttachInvoicesStep(),
                    visible: !this.areAttachedInvoices && this.isDetailsFilled && !this.isEditingMode,
                },
                {
                    text: this.$t('Complete Package Details'),
                    onClick: () => this.initEditMode(),
                    visible: (!this.allStepsCompleted || !this.isDetailsFilled) && !this.isEditingMode,
                },
                {
                    text: this.$t('Create'),
                    onClick: () => this.initCreate(),
                    disabled: !this.creationDataCompleted,
                    visible: this.creating && this.isLastStep,
                },
                {
                    text: this.$t('Save'),
                    onClick: () => this.saveAsDraft(),
                    visible: this.editing,
                },
                {
                    text:  this.$t('Mark as Paid'),
                    icon: 'icon-dollar-circle',
                    onClick: () => this.toggleMarkAsPaidModal(),
                    visible: this.isActionAvailable(AppPackageAvailableActions.MarkAsPaid),
                },
                {
                    text:  this.$t('Approve'),
                    onClick: () => this.initApprove(),
                    visible: this.isActionAvailable(AppPackageAvailableActions.Approve) && !this.isEditingMode,
                },
                {
                    text:  this.$t('Submit to Client'),
                    icon: 'icon-send-2',
                    onClick: () => this.initSendToClient(),
                    disabled: !this.attachedInvoicesList?.length || this.someAttachedInvoicesInternallyNotApproved,
                    visible: this.isActionAvailable(AppPackageAvailableActions.SubmitToClient) && !this.isEditingMode,
                },
                {
                    text:  this.$t('Record Client Approval'),
                    onClick: () => this.initRecordClientApproval(),
                    disabled: !this.attachedInvoicesList?.length || this.someAttachedInvoicesInternallyNotApproved,
                    visible: this.isActionAvailable(AppPackageAvailableActions.RecordClientApproval) && !this.isEditingMode && !this.isActionAvailable(AppPackageAvailableActions.MarkAsSubmittedToClient),
                },
                {
                    text:  this.$t('Mark as Submitted to Client'),
                    disabled: !this.attachedInvoicesList?.length || this.someAttachedInvoicesInternallyNotApproved,
                    onClick: () => this.initMarkAsSubmittedToClient(),
                    visible: this.isActionAvailable(AppPackageAvailableActions.MarkAsSubmittedToClient),
                },
                {
                    text:  this.$t('Submit to Accounting'),
                    onClick: () => this.initSubmitToAccounting(),
                    visible: this.isActionAvailable(AppPackageAvailableActions.SubmitToAccounting),
                },
            ]
        },

        availableDropdownActions (): ActionData[] {
            return this.dropdownActions.filter(action => action.visible)
        },

        availablePrimaryActions (): ActionData[] {
            return this.primaryActions.filter(action => action.visible)
        },

        creationDataCompleted (): boolean {
            return this.isDetailsFilled && this.areAttachedInvoices
        },
    },

    async mounted () {
        if (this.creating) {
            await this.setInitialAppPackageData(this.projectLocalID)
        }
    },

    async created () {
        try {
            await this.fetchAppPackageAvailableActions(this.projectLocalID, this.$route.params.id)
        } catch (e) {
            console.log(e)
        }
    },
    methods: {
        ...mapActions(appPackageViewStore, [
            'setEditModeValue',
            'setCreateModeValue',
            'createAppPackage',
            'updateAppPackage',
            'fetchAppPackageAvailableActions',
            'fetchAppPackageDetails',
            'setDataLoading',
            'fetchAppPackageAttachedInvoices',
            'setInitialAppPackageData'
        ]),

        goToNextTab (): void {
            this.$emit('nextStep')
        },

        goToPrevTab (): void {
            this.$emit('prevStep')
        },

        cancelClick (): void {
            if (this.creating) {
                this.$router.push({
                    name: InvoiceRouteName.InvoicesApplicationPackagesList,
                    params: { pid: this.$route.params.pid },
                })
            } else {
                this.setEditModeValue(false)
                this.$router.push({ name: AppPackageRouteName.AppPackageDetails })
            }
        },

        async saveAsDraft (): Promise<void> {
            if (this.creating) {
                this.errorHandler(async () => {
                    const createdId = await this.createAppPackage(this.projectLocalID)
                    this.setCreateModeValue(false)
                    this.$route.params.id = createdId
                    this.fetchAppPackageAvailableActions(this.projectLocalID, this.appPackage.id)
                    this.$router.push({ name: AppPackageRouteName.AppPackageDetails, params: { id: createdId } })
                })
            } else if (this.editing) {
                this.errorHandler(async () => {
                    await this.updateAppPackage(this.projectLocalID, this.$route.params.id)
                    this.fetchAppPackageAvailableActions(this.projectLocalID, this.appPackage.id)
                    this.setEditModeValue(false)
                    this.$router.push({ name: AppPackageRouteName.AppPackageDetails })
                })
            }
        },

        initEditMode (): void {
            this.setEditModeValue(true)
        },

        goToAttachInvoicesStep (): void {
            this.initEditMode()
            this.$router.push({ name: AppPackageRouteName.AppPackageAttachInvoices, params: { id: this.appPackage.id } })
        },

        async initCreate (): Promise<void> {
            this.errorHandler(async () => {
                const createdId = await this.createAppPackage(this.projectLocalID)
                this.setCreateModeValue(false)
                this.fetchAppPackageAvailableActions(this.projectLocalID, createdId)
                this.$router.push({ name: AppPackageRouteName.AppPackageDetails, params: { id: createdId } })
            })
        },

        initDelete (): void {
            this.showPopupAlert(ConfirmationModalsService.deleteAppPackage(this.deleteAppPackage))
        },

        async deleteAppPackage (): Promise<void> {
            this.errorHandler(async () => {
                await appPackageClient.deleteApplicationPackage(this.projectLocalID, this.appPackage.id)
                this.$router.push({
                    name: InvoiceRouteName.InvoicesApplicationPackagesList,
                    params: { pid: this.$route.params.pid, id: '' },
                })
            })
        },

        isActionAvailable (actionName: string): boolean {
          return Boolean(this.availableActions?.find(action => action.name === actionName)?.available)
        },

        toggleMarkAsPaidModal (): void {
            this.showMarkAsPaidModal = !this.showMarkAsPaidModal
        },

        toggleRejectionModal (value: boolean): void {
            this.showRejectionModal = value
        },

        initSendToClient (): void {
            this.showPopupAlert(ApprovalConfirmationModalService.submitToClient(Resource.APPLICATION_PACKAGE, this.submitToClient))
        },

        async submitToClient (): Promise<void> {
            this.errorHandler(async () => {
                await appPackageClient.submitToClient(this.projectLocalID, this.appPackage.id)
                await this.updateDetails()
            })
        },

        initRecordClientApproval (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.APPROVE, modal: ApprovalModalType.ClientApproval })
        },

        initMarkAsSubmittedToClient (): void {
            this.showPopupAlert(ApprovalConfirmationModalService.markAsSubmittedToClient(Resource.APPLICATION_PACKAGE, this.markAsSubmittedToClient))
        },

        async markAsSubmittedToClient (): void {
            this.errorHandler(async () => {
                await appPackageClient.markAsSubmittedToClient(this.projectLocalID, this.appPackage.id)
                await this.updateDetails()
            })
        },

        initApprove (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.APPROVE, modal: ApprovalModalType.UserApproval })
        },

        async initRevertToDraft (): void {
            this.errorHandler(async () => {
                await appPackageClient.revertToDraft(this.projectLocalID, this.appPackage.id)
                await this.updateDetails()
            })
        },

        async initRevertToPending (): void {
            this.errorHandler(async () => {
                await appPackageClient.revertToPending(this.projectLocalID, this.appPackage.id)
                await this.updateDetails()
            })
        },

        async updateDetails (): void {
            this.$emit('refreshApprovableResourceData')
            await this.fetchAppPackageAvailableActions(this.projectLocalID, this.appPackage.id)
            await this.fetchAppPackageDetails(this.projectLocalID, this.appPackage.id)
            this.fetchAppPackageAttachedInvoices(this.projectLocalID, this.appPackage.id)
        },

        async errorHandler (actions: () => void): void {
            try {
                this.setDataLoading(true)
                await actions()
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.setDataLoading(false)
            }
        },

        async exportToPdf (): void {
            this.errorHandler(async () => {
                await appPackageClient.getAppPackagePDF(this.projectLocalID, this.$route.params.id)
                this.showNotification('success', this.$t('Please wait while we prepare the document. You will receive a notification when the document is ready to export.'))
            })
        },

        initSubmitToAccounting (): void {
            this.showPopupAlert(ConfirmationModalsService.submitToAccountingAppPackage(this.submitToAccounting))
        },

        submitToAccounting (): void {
            this.errorHandler(async () => {
                await appPackageClient.submitToAccounting(this.projectLocalID, this.$route.params.id)
                await this.updateDetails()
                this.showNotification('success', this.$t('Application Package submitted to accounting'))
            })
        },
    },
})
