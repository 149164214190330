<template>
    <div class="io-reply">
        <div class="io-reply__info">
            <Avatar
                :companyName="replyData.created_by.position"
                :employeeMongoId="replyData.created_by.id"
                :employeeFullName="replyData.created_by.name"
                showFullName
            />

            <div class="flex">
                <div class="io-date">
                    {{ $filters.formatDate(replyData.created_at) }}
                </div>

                <DropdownButtonNew v-if="!isParentCommentArchived" icon-mode fontAwesomeClass="icon-more-vert-2">
                    <div class="io-single-option" @click="editMode = true">
                        <i class="icon-edit"/>
                        {{ $t('Edit') }}
                    </div>
                    <div class="io-single-option" @click="onDeleteComment">
                        <i class="icon-trash"/>
                        {{ $t('Delete') }}
                    </div>
                </DropdownButtonNew>
            </div>
        </div>

        <div v-if="editMode" class="io-edit-wrapper">
            <Mentions
                :placeholder="$t('Add Reply...')"
                :attached_employees_ids="uniqueMentionUsers"
                :customPeopleList="uniqueMentionUsers"
                :attachBelow="true"
                :getByMongo="true"
                :value="editedReply"
                v-model="editedReply"
                @update="onUpdateReply"
            />

            <div class="io-buttons">
                <IOButton variant="secondary" size="small" @click="cancelEditing">{{ $t('Cancel') }}</IOButton>
                <IOButton size="small" :disabled="!editedReply.length" @click="saveReply">{{ $t('Save') }}</IOButton>
            </div>
        </div>

        <div v-else class="io-reply__text" v-html="replyData.comment_text"></div>
    </div>
</template>

<script lang="ts" src="./Reply.ts"></script>
<style lang="scss" src="./Reply.scss" scoped></style>
