import { ApiClient as client } from '../api'
import { PersonInterface } from '@/io-modules/submittals/interfaces/PersonInterface.ts'

export default {
    fetchPackages (projectID: string): Promise<void> {
        return client.get(`/ca/submittal/${ projectID }/packages`)
    },
    deletePackage (projectId: string, packageId: string): Promise<void> {
        return client.delete(`ca/submittal/${ projectId }/packages/${ packageId }`)
    },
    fetchSubmittals (projectID: string, queryString: string): Promise<void> {
        return client.get(`/ca/submittal/${ projectID }/submittals?${ queryString }`)
    },
    fetchSubmittal (projectId: string, submittalId: string): Promise<any> {
        return client.get(`/ca/submittal/${ projectId }/submittals/${ submittalId }`)
    },
    fetchRelevantSpecifications (projectID: string): Promise<void> {
        return client.get(`/ca/submittal/${ projectID }/dictionaries/specification`)
    },
    attachEmployees (id: string, data: PersonInterface): Promise<void> {
        return client.patch(`/ca/submittal/${ id }/attach-employees`, data)
    },
    attachEmployee (id: string, employeeId: string, data: PersonInterface): Promise<void> {
        return client.post(`/ca/submittal/${ id }/attach-employee/${ employeeId }`, data)
    },
    detachEmployee (id: string, employeeId: string, data: string): Promise<void> {
        return client.post(`/ca/submittal/${ id }/detach-employee/${ employeeId }`, data)
    },
    fetchSubmittalTimeline (projectId: string, submittalId: string): Promise<any> {
        return client.get(`/ca/submittal/${ projectId }/submittals/${ submittalId }/timeline`)
    },
    getFileInfo (params: { projectId: string, submittalId: string, fileId: string }): Promise<void> {
        return client.get(`ca/submittal/${ params.projectId }/submittals/${ params.submittalId }/attachments/${ params.fileId }`)
    },
    distributeSubmittal (projectId: string, submittalId: string): Promise<void> {
        return client.put(`/ca/submittal/${ projectId }/submittals/${ submittalId }/distribute`)
    },
    changeToDraft (projectId: string, submittalId: string): Promise<void> {
        return client.patch(`ca/submittal/${ projectId }/submittals/${ submittalId }/return-to-draft`)
    },
    deleteSubmittal (projectId: string, submittalId: string): Promise<void> {
        return client.delete(`ca/submittal/${ projectId }/submittals/${ submittalId }`)
    },
}
