import projectWizard from '@/layouts/offcanvas/project-wizard/main.vue'

const projectWizardMixin = {
    data () {
        return {
            isProjectWizard: false
        }
    },
    props: {
        startProjectWizard: {
            type: Boolean,
            required: false
        }
    },
    components: {
        projectWizard
    },
    methods: {
        openProjectWizard () {
            this.isProjectWizard = true
            document.querySelector('html').classList.add('io-no-overflow')
        },
        closeProjectWizard () {
            this.isProjectWizard = false
            document.querySelector('html').classList.remove('io-no-overflow')
        }
    },

    created: function () {
        if (this.startProjectWizard) {
            this.openProjectWizard()
        }
    }
}

export default projectWizardMixin
