<template>
    <div
        data-testid="io-placeholder"
        class="io-design-system io-placeholder"
        :class="[{ 'io-placeholder--frame': frame }, `io-placeholder--${ size }`]"
    >
        <div class="io-placeholder__content">
            <div v-if="icon" class="io-placeholder__illustration">
                <i
                    class="io-placeholder__icon"
                    :class="[icon, { 'io-placeholder__icon--lite': lite }]"
                />
            </div>

            <slot v-if="$slots.title" name="title" />

            <div
                v-else-if="title"
                class="io-placeholder__title"
            >
                {{ title }}
            </div>

            <slot v-if="$slots.subtitle" name="subtitle" />

            <div
                v-else-if="subtitle"
                class="io-placeholder__subtitle"
            >
                {{ subtitle }}
            </div>
        </div>
        <div v-if="$slots.default" class="io-placeholder__body">
            <slot />
        </div>
    </div>
</template>

<script lang="ts" src="./IOPlaceholder.ts"></script>
<style lang="scss" src="./IOPlaceholder.scss" scoped></style>
