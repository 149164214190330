<template>
    <div class="io-aside-placeholder" data-testid="aside-placeholder">
        <div class="io-aside-placeholder__icon">
            <span class="icon-pin"></span>
        </div>

        <div class="io-aside-placeholder__text">
            <div class="io-aside-placeholder__text-title">{{ $t('Nothing Here, Yet!') }}</div>
            <div class="io-aside-placeholder__text-subtitle">{{ $t('Start by selecting users to collaborate on projects or specific items you assign them to.') }}</div>
        </div>
    </div>
</template>

<script lang="ts" src="./AsidePlaceholder.ts"></script>
<style lang="scss" src="./AsidePlaceholder.scss" scoped></style>
