import { defineComponent } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs.vue'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin.ts'

export default defineComponent({
    name: 'PageHeader',
    components: {
        Breadcrumbs,
    },
    props: {
        routeBack: {
            type: [String, Object as () => RouteLocationRaw],
            required: false,
            default: '',
        },
        title: {
            type: String,
            default: '',
        },
        noBottomMargin: {
            type: Boolean,
            default: false,
        },
        teleportDisabled: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
            required: false,
            default: '',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [breadcrumbsMixin],
    emits: ['clickBack'],
    computed: {
        hasTabsSlot (): boolean {
            return !!this.$slots.tabs
        },
    },
})
