import defaultState from './state'
import { cloneDeep, isObject, isArray } from 'lodash'

export function SET_PERIOD (state, value) {
    state.period = (isObject(value)) ? value : null
}

export function SET_PERIODS (state, value) {
    state.periods = (isArray(value)) ? value : []
}

export function SET_PROVIDERS (state, value) {
    state.providerList = (isArray(value)) ? value : []
}

export function SET_LINES_VISIBLE_BY (state, value) {
    state['linesVisibleBy'] = value
}

export function SET_ATTENDERS (state, value) {
    state.attenders.to = (isArray(value.to)) ? value.to : []
    state.attenders.from = (isArray(value.from)) ? value.from : []
}

/**
 *
 * @param state
 * @param value
 * @constructor
 */
export function SET_CATEGORIES (state, value) {
    state.categories = (isArray(value)) ? value : []
}

export function SET_ACTIVE_FUNDING (state, value) {
    state.payApp.basic['funding_source'] = value
}

export function SET_HASSETTINGS (state, value) {
    state.has_billing_settings = value
}

export function SET_INVOICE_AMOUNT (state, value) {
    state.payApp.balance.pay_app_amount = value
}

export function SET_INVOICE_AMOUNT_NET (state, value) {
    state.payApp.balance.pay_app_amount_net = value
}

export function SET_CALCULATED_TOTAL (state, data) {
    state.calculatedTotals[data.field] = data.value
}

/**
 * Set amount and fix balance
 * @param state
 * @param value
 * @constructor
 */
export function SET_RETENTION_AMOUNT (state, value) {
    state.payApp.balance.retention_amount = value
}

/**
 * Set adjustment value in realtime
 * @param state
 * @param value
 * @constructor
 */
export function SET_ADJUSTMENT_VALUE (state, value) {
    state.payApp.balance.pay_app_adjustment = value
    // state.payApp.balance.balance_to_finish = state.payApp.balance.balance_to_finish_original - state.payApp.balance.pay_app_amount - value
}

export function SET_FUNDING_SOURCES (state, value) {
    state.fundingSources = (isArray(value)) ? value : []
}



export function ADD_PERIODS (state, value) {
    state.periods.unshift(...value)
}

export function SET_PAYAPP_ID (state, value) {
    state.payApp.id = value
}

/**
 * Set tasks
 * @param state
 * @param value
 * @constructor
 */
export function SET_ADJUSTED_TASKS (state, value) {
    state.numAdjustedTasks = value
}

/**
 * Set tasks
 * @param state
 * @param value
 * @constructor
 */
export function SET_NEED_ATTENTION_TASKS (state, value) {
    state.numNeedAttentionTasks = value
}

export function SET_SAVE_FLAG (state) {
    state.payApp.saveFlag = state.payApp.saveFlag + 1
}

export function SET_PAYAPP (state, value) {
    state.payApp.finance = value.finance || null
    state.payApp.balance = value.balance || null
    state.payApp.basic = value.basic || null
    state.payApp.specific = value.specific || null
    state.payApp.actions = value.actions || []
    state.payApp.payments = value.payments || []
    state.payApp.change_orders = value.change_orders || []
    state.payApp.attenders = value.attenders || []
    state.payApp.contact = value.contact || null
    state.payApp.quoted_items = value.quoted_items || null
    state.payApp.status = value.status || null
    state.payApp.injected = value.injected || null
    state.payApp.possible_statuses = value.possible_statuses || null
    state.payApp.payment_status = value.payment_status || null
    state.payApp.flags = value.flags || {}
    state.payApp.attached_vendor_invoices = value.attached_vendor_invoices.rows || []
    state.payApp.attached_vendor_totals = value.attached_vendor_invoices.total || {}
    state.payApp.submitted_invoice_notification_email = value.submitted_invoice_notification_email || null
}


export function SET_SPECIFIC (state, value) {
    Object.keys(value).forEach(key => {
        state.payApp.specific[key] = value[key]
    })
}


export function UPDATE_PAYAPP_BASIC (state, value) {
    state.payApp.basic = value || null
}

export function SET_PAYAPP_ENCOUNTERED_ERROR_ON_INVOICE_SUBMISSION (state, value) {
    state.payApp.encountered_error_on_invoice_submission = value
}

export function REMOVE_PERIODS (state, value) {
    state.periods = state.periods.filter(period => {
        return period._id !== value.id
    })
}

export function SET_CONTRACTORS (state, value) {
    Object.keys(value).length ? state.contractors = value : state.contractors = []
}

export function SET_ROUTER (state, value) {
    state.payAppRouter['routerTo'] = value.routerTo
    state.payAppRouter['routerFrom'] = value.routerFrom
}

export function SET_CONTRACTOR (state, value) {
    state.contractor = value
}

export function SET_LOADED (state, type) {
    state.loaded[type] = true
}

/**
 * Marks that periods were loaded for a specific project
 */
export function SET_LOADED_PERIODS (state, projectId) {
    state.loaded.periods = projectId.toString()
}

export function UNSET_LOADED (state, type) {
    state.loaded[type] = false
}

export function SET_MODAL (state, value) {
    state.modal[value.modal] = (value.data) ? value.data : value.show
}

export function SET_SHOW_PAYAPP_TABS (state, value) {
    state.showPayappTabs = value
}

/**
 * Add config data
 * @param state
 * @param config
 * @constructor
 */
export function ENABLE_MODAL_INVOICE (state, config) {
    state.modalInvoice.enabled = true
    for (let prop in config) {
        if (typeof state.modalInvoice.data[prop] !== 'undefined') {
            state.modalInvoice.data[prop] = config[prop]
        }
    }
}

/**
 * Add config data
 * @param state
 * @param config
 * @constructor
 */
export function ENABLE_MODAL_PACKAGE_PAYMENTS (state, config) {
    state.modalPackagePayments.enabled = true
    for (let prop in config) {
        if (typeof state.modalPackagePayments.data[prop] !== 'undefined') {
            state.modalPackagePayments.data[prop] = config[prop]
        }
    }
}

/**
 * Add config data
 * @param state
 * @param config
 * @constructor
 */
export function MODAL_INVOICE_PAYMENTS_ENABLE (state, config) {
    state.modalInvoicePayments.enabled = true
    for (let prop in config) {
        if (typeof state.modalInvoicePayments[prop] !== 'undefined') {
            state.modalInvoicePayments[prop] = config[prop]
        }
    }
}

/**
 *
 * @param state
 * @constructor
 */
export function MODAL_INVOICE_PAYMENTS_RESET (state) {
    state.modalInvoicePayments = cloneDeep(defaultState.modalInvoicePayments)
}


export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

/**
 *
 * Set package details
 * @export
 * @param {*} state
 * @param {*} value
 */
export function SET_PACKAGE_DETAILS (state, value) {
    state.periodDetails.invoice_providers = value.invoice_providers
    state.periodDetails.basic_data = value.basic_data
    state.periodDetails.invoices_data = value.invoices_data
    state.periodDetails.no_payapps_for_contracts = value.no_payapps_for_contracts
    state.periodDetails.items = value.items
    state.periodDetails.project_totals = value.project_totals
    state.periodDetails.widgets_visibility = value.widgets_visibility
    state.periodDetails.actions = value.available_actions
}

export function UPDATE_PACKAGE_DETAILS (state, value) {
    state.periodDetails.basic_data.status = value.statusId
    state.periodDetails.basic_data.status_name = value.statusName
    state.periodDetails.actions = value.actions
    state.periodDetails['items'] = value.items
}

export function UPDATE_PACKAGE_STATUS (state, value) {
    state.periodDetails.basic_data.status = value.statusId
    state.periodDetails.basic_data.status_name = value.statusName
    state.periodDetails.actions = value.actions
}

/**
 *
 * @param state
 * @param value
 * @constructor
 */
export function SET_MODAL_INVOICE_CONTRACT_DATA (state, value) {
    state.modalInvoice.contractData = value
}

/**
 *
 * @param state
 * @param value
 * @constructor
 */
export function SET_MODAL_INVOICE_VENDORS (state, value) {
    state.modalInvoice.vendors = value
}

/**
 *
 * @param state
 * @constructor
 */
export function RESET_MODAL_INVOICE (state) {
    state.modalInvoice = cloneDeep(defaultState.modalInvoice)
}

/**
 *
 * @param state
 * @constructor
 */
export function RESET_MODAL_PACKAGE_PAYMENTS (state) {
    state.modalPackagePayments = cloneDeep(defaultState.modalPackagePayments)
}

/**
 *
 * @param state
 * @constructor
 */
export function RESET_MODAL_SOURCES (state) {
    state.modalSources = cloneDeep(defaultState.modalSources)
}

/**
 *
 * @param state
 * @param value
 * @constructor
 */
export function SET_MODAL_SOURCES_PROJECT_SOURCES (state, value) {
    state.modalSources.sourcesProject = (isArray(value)) ? value : []
    state.modalSources.loading = false
}

/**
 *
 * @param state
 * @param data
 * @constructor
 */
export function MODAL_SOURCES_SET_DATA (state, data) {
    for (let prop in data) {
        if (typeof state.modalSources[prop] !== 'undefined') {
            state.modalSources[prop] = data[prop]
        }
    }
}

export function SET_SHOW_POPUP_MANUAL_APPROVAL (state, value) {
    state.showPopupManualApproval = value
}

export function SET_SHOW_POPUP_MANUAL_REJECTION (state, value) {
    state.showPopupManualRejection = value
}

export function SET_SHOW_POPUP_CLIENT_APPROVAL (state, value) {
    state.showPopupClientApproval = value
}

export function SET_SHOW_POPUP_USER_APPROVAL (state, value) {
    state.showPopupUserApproval = value
}

export function SET_SHOW_POPUP_USER_REJECTION (state, value) {
    state.showPopupUserRejection = value
}

export function SET_DEPENDENTS (state, value) {
    state.dependents = value
}

export function SET_REVISION_POPUP (state, value) {
    state.revisionPopup = value
}

export function SET_RETURN_TO_DRAFT_POPUP (state, value) {
    state.returnToDraftPopup = value
}

export function SET_ADJUSTMENT_RECALCULATION_COMPLETED (state, value) {
    state.adjustmentRecalculationCompleted = value
}

export function SET_PURCHASE_ORDER (state, value) {
    state.payApp.basic['purchase_order'] = value
}

export function SET_BANNER_ACCOUNTING_MESSAGES (state, value) {
    state.bannerAccountingMessages = value
}

export function SET_TABLE_VIEW_MODE (state, value) {
    state.tableViewMode = value
}

export function SET_COLUMNS_VIEW_MODE (state, value) {
    state.columnsViewMode = value
}

export function SET_CAN_SAVE_SELECTED_SOURCES (state, value) {
    state.canSaveFundingSources = value
}
