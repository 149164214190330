<template>
    <div class="row">
        <div class="col-md-12">
            <div class='form-toolbar io-btns-form' :data-progress="inProgress || null" :class="'io-btns-n-' + buttons.length">
                <template v-for='(button, index) in buttons' :key="index">

                    <a v-if="fieldVisible(button, viewMode) && button.isModal === true && button.isAdditional === true"
                       :id='button.exec'
                       :key="'btn1' + index"
                       :to="button.url + $route.params.id + '?modal=' + (button.isModalParam ? button.isModalParam : 1)"
                       :href="button.url + $route.params.id +'/external/'+ button.additional +'?modal=' + (button.isModalParam ? button.isModalParam : 1)"
                       class="io-btn-primary-compact io-btn-loader"
                       :class="button.class  + ' b1'"
                    >
                        <p class="io-btn-text">{{ $t(button.buttonText) }}</p>
                    </a>

                    <a v-else-if="fieldVisible(button, viewMode) && button.isModal === true && button.isAdditional !== true"
                       :id='button.exec'
                       :key="'btn2' + index"
                       :to="button.url + $route.params.id + '?modal=' + (button.isModalParam ? button.isModalParam : 1)"
                       :href="button.url + $route.params.id + '?modal=' + (button.isModalParam ? button.isModalParam : 1)"
                       class="io-btn-primary-compact io-btn-loader"
                       :class="button.class  + ' b1'"
                    >
                        <p class="io-btn-text">{{ $t(button.buttonText) }}</p>
                    </a>

                    <IOButton
                        v-else-if="fieldVisible(button, viewMode)"
                        :id='button.exec'
                        :key="'btn3' + index"
                        class="io-btn-loader"
                        size="small"
                        :exec='button.exec'
                        :name='button.inputName'
                        :disabled='button.disabled'
                        :class="[{
                            'io-is-loading' : loadingSpinner.status > 0 && button.id === loadingSpinner.button.uniqueID,
                            'io-is-loaded' : loadingSpinner.status > 1 && button.id === loadingSpinner.button.uniqueID,
                            'io-loading-success' : loadingSpinner.status === 3 && button.id === loadingSpinner.button.uniqueID,
                            'io-loading-error' : loadingSpinner.status === 4 && button.id === loadingSpinner.button.uniqueID
                        }, button.class]"
                        @click='click(button.exec, $event, index)'
                    >
                        <p class="io-btn-text">{{ $t(button.buttonText) }}</p>
                        <div id="io-loader-1" class="io-loader">
                            <p class="io-text-load"></p>
                        </div>
                    </IOButton>

                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { isFunction, isNil, uniqueId } from 'lodash'

    export default {
        props: {
            buttons: Array,
            viewMode: String,
        },
        mixins: [],
        data () {
            return {
                inProgress: false
            }
        },
        computed: {
            loadingSpinner () {
                return this.$store.getters['loadingBar/getLoadingSpinnerStatus']
            }
        },
        mounted: function () {
            for (let i = 0; i < this.buttons.length; i++) {
                this.buttons[i].disabled = false
                this.buttons[i].inProgress = false
            }
        },
        methods: {
            fieldVisible (button, viewMode) {
                if (isFunction(button.visible)) {
                    return button.visible.call(this, this.$parent.model, button, this) && ((viewMode === 'view' && button.isView) || (viewMode === 'edit' && button.isEdit))
                }
                if (isNil(button.visible)) {
                    return true
                }
                return button.visible && ((viewMode === 'view' && button.isView) || (viewMode === 'edit' && button.isEdit))
            },
            click (cmd, event, index) {
                if (this.loadingSpinner.status > 0) {
                    return true
                }
                if (window.history.length !== 1 && window.history.length !== 0) {
                    if (cmd === 'vueBack') {
                        this.$router.go(-1)
                        return true
                    }
                }
                let destID = 0
                if (this.$route.params.id !== undefined) {
                    destID = this.$route.params.id
                }
                if (this.buttons[index].exec === cmd) {
                    this.buttons[index].id = uniqueId('button_')
                    let payload = {
                        exec: cmd,
                        execBack: this.buttons[index].execBack,
                        execBackModal: this.buttons[index].execBackModal,
                        execAfterSubmit: this.buttons[index].execAfterSubmit,
                        execBackUrl: this.buttons[index].execBackUrl,
                        keepId: this.buttons[index].keepId,
                        id: destID,
                        index: index,
                        uniqueID: this.buttons[index].id,
                        addNext: this.buttons[index].addNext // ex. add next timesheet entry
                    }

                    // exec command
                    if (isFunction(this.buttons[index].onSubmit)) {
                        this.inProgress = true
                        this.buttons[index].onSubmit(payload)
                        return true
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .io-btns-form {
        .io-btn-loader {
            padding: 7px 23px !important;
        }
    }
</style>
