import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'

export default {
    namespaced: true,
    namespace: 'cached/',
    state () {
        return {
            lists: {
                employees: [],
                contacts: [],
                proposals: [],
                projects: [],
                tasksgeneral: [],
                materials: []
            },
            lastModified: {
                employees: null,
                contacts: null,
                proposals: null,
                projects: null,
                tasksgeneral: null,
                materials: null
            },
            cacheInfo: {
                employees: null,
                contact: null,
                proposals: null,
                projects: null,
                tasksgeneral: null,
                materials: null
            },
            listsNames: {
                employees: 'Employees',
                contacts: 'Contacts',
                proposals: 'Proposals',
                projects: 'Projects',
                tasksgeneral: 'General',
                materials: 'Materials',
                persons: 'Persons'
            },
            /**
             * To store pending promises
             */
            promises: {}
        }
    },
    actions,
    getters,
    mutations
}
