<template>
    <td class="io-cell-icon-action" :class="cellClass">
        <template v-if="!isHidden()">
            <div v-if="!disableAction" @click.stop="emitAction">
                <i v-if="tooltip" class="fas" :class="iconName" v-tooltip.bottom="{ content: tooltip, visible: tooltip }"></i>

                <i v-else class="fas" :class="iconClass"></i>
            </div>
        </template>
    </td>
</template>

<script lang="ts" src="./Archive.ts"></script>
<style lang="scss" src="./Archive.scss" scoped></style>
