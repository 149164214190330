import { defineComponent, PropType } from 'vue'
import DropdownButton from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import dayjs from 'dayjs'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { mapState } from 'pinia'
import type { DraftAppPackage } from '@/io-modules/invoices/api-clients/invoiceCreationClient.ts'

export default defineComponent({
    name: 'SelectAppPackage',
    components: {
        DropdownButton,
    },
    props: {
        modelValue: {
            type: Object as PropType<DraftAppPackage>,
            default: null,
        },
        appPackages: {
            type: Object as PropType<DraftAppPackage[]>,
            default: []
        }
    },
    emits: ['update:modelValue'],
    computed: {
        ...mapState(invoiceViewStore, ['isEditingMode', 'invoiceIsDraft']),
    },
    methods: {
        getAppPackagePeriod (item: DraftAppPackage): string {
            return item.start_date && item.end_date
                        ? `${ dayjs(item.start_date).format('MM/DD/YYYY') } — ${ dayjs(item.end_date).format('MM/DD/YYYY') }`
                        : '-'
        },

        selectAppPackage (item: DraftAppPackage): void {
            this.$emit('update:modelValue', item)
        }
    },
})
