<template>
    <div class="io-comments-box">
        <IOLabel
            v-if="showLabel"
            data-testid="label"
            :size="LabelSize.Large"
            :type="LabelType.BoldMax"
            :text="$t('Comments')"
        />
        <div v-if="canAddComment" class="io-comments-box__input-wrapper" data-testid="comment-input">
            <Avatar
                :employeeMongoId="authData.u_mongo"
                :employeeFullName="userFullName"
            />
            <div class="io-comments-box__input">
                <Mentions
                    ref="mentionsInput"
                    v-model="comment"
                    :customPeopleList="taggableUsers"
                    :attachBelow="true"
                    :placeholder="$t('Add a Comment...')"
                    @onSubmit="addComment"
                />
                <span class="io-send-button" :class="{ active: comment.length }">
                    <i class="icon-send" @click="addComment"></i>
                </span>
            </div>
        </div>

        <div v-if="comments.length" class="io-comments-box__filters" data-testid="radio-buttons">
            <Radio
                :radio-value="CommentsSort.NewFirst"
                :inputName="CommentsSort.NewFirst"
                :labelText="$t('Newest first')"
                v-model="sortType"
            />
            <Radio
                :radio-value="CommentsSort.OldFirst"
                :inputName="CommentsSort.OldFirst"
                :labelText="$t('Oldest first')"
                v-model="sortType"
            />
        </div>
        <SingleComment
            v-for="(item, index) in filteredComments"
            :key="index"
            :comment="item"
            :canEdit="canEdit"
            :commentField="commentField"
            :dateFiled="dateFiled"
            :userIdField="userIdField"
            :userFirstNameField="userFirstNameField"
            :userLastNameField="userLastNameField"
            :show-creation-time="showCreationTime"
            @updateComment="(data) => $emit('updateComment', data)"
            @deleteComment="(data) => $emit('deleteComment', data)"
        />

        <div v-if="!comments.length" class="io-placeholder" data-testid="no-comments-placeholder">
            <p class="io-placeholder__title">{{ $t('No Comments') }}</p>
            <p v-if="canAddComment" class="io-placeholder__sub-title">{{ noCommentsPlaceholder || $t('You may add a new comment by typing it above.') }}</p>
        </div>
    </div>
</template>

<script lang="ts" src="./Comments.ts"></script>
<style lang="scss" src="./Comments.scss" scoped></style>
