<template>
    <td class="io-invite-members" @click.stop>
        <InviteList
            :itemId="data._id"
            :allowInvite="data.can_invite_members"
            :attached_employees_ids="data.attached_employees_ids"
            :invited_people="data.invited_people"
            :invited="data.invited"
            :invited_pending="data.invited_pending"
            :apiClient="todoApiClient"
            :isTypeTask="true"
            :creatorMongoId="data.task_create_mongo"
            :avatarRingChecker="getAdditionalFunction"
            :avatarRingTooltip="tooltipValue"
        />
    </td>
</template>

<script lang="ts" src="./InviteMembers.ts"></script>
<style lang="scss" src="./InviteMembers.scss" scoped></style>
