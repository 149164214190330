export default {
    alerts: {
        items: [],
        more: false
    },
    chat: {
        items: [],
        more: false
    }
}
