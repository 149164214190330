import defaultState from './state'
import { cloneDeep } from 'lodash'

export function SET_TOPICS (state, value) {
    state.topics.push(...value.data)
    state.topicsCloned.push(...value.data)
    state.topicsPage = value.current_page
    state.topicsPageLast = value.last_page
}

export function UPDATE_TOPICS (state, value) {
    state.topics.push(...value.data)
    state.topicsTotal = value.total
    state.topicsPage = value.current_page
    state.topicsPageLast = value.last_page
}

export function SEARCH_TOPICS (state, value) {
    state.topics = [...value.data]
    state.topicsPage = value.current_page
    state.topicsPageLast = value.last_page
}

export function SET_ACTIVE_DISCUSSION (state, value) {
    state.discussion = value.discussion
    state.posts = value.posts
    if (value.discussion) state.mutedBy = value.discussion.hasOwnProperty('muted_by') ? value.discussion.muted_by : []
}

export function ADD_DISCUSSION (state, value) {
    state.topics.unshift(value)
    state.topicsCloned.unshift(value)
    state.discussion = value
    state.muted_by = value.hasOwnProperty('muted_by') ? value.muted_by : []
}

export function SET_POSTS (state, value) {
    state.posts = value.data
    state.postsCloned = cloneDeep(state.posts)
    state.posts.reverse()
    state.postsPage = value.current_page
    state.postsTotal = value.total
}

export function UPDATE_POSTS (state, value) {
    state.posts.unshift(...value.data.reverse())
    state.postsPage = value.current_page
}

export function SEARCH_POSTS (state, value) {
    state.posts = [...value.data]
    state.postsPage = value.current_page
}

export function ADD_POST (state, value) {
    state.postsTotal++
    state.posts.push(value)
    state.postsCloned = cloneDeep(state.posts)
}

export function ADDING_POST (state, value) {
    state.addingPost = value
}

export function SET_POSTS_LOADING (state, value) {
    state.postsLoading = value
}

export function UPDATE_EMPLOYEES (state, value) {
    state.discussion.attached_employees_ids = value
}

export function UPDATE_MUTE (state, value) {
    state.mutedBy = value
}

export function SET_MESSAGE_FOCUS (state, value) {
    state.messageFocus = value
}

export function SET_TOPIC_HOVER (state, value) {
    state.topicHover = value
}

export function SET_MODAL (state, value) {
    state.modal[value.modal] = value.show
}

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export const SET_PROJECT_EMAIL = (state, val) => {
    state.projectEmail = val
}
