/** @deprecated
 * Switch to '@/composables/useLoader.ts'
 */

/**
 * Wraps promise with try-catch block and sets reactive loading state to true before the call and to false after.
 * You can set optional error handler function as second argument if you want to handle errors yourself.
 *
 * Basic usage:
 * const { loading, load } = useSafeLoader()
 * load(async () => someApiCall())
 * load(async () => someApiCall(), (e) => console.log(e))
 *
 * Usage with global spinner animation:
 * const { loadingSpinner, loadWithSpinner } = useSafeLoader()
 * loadWithSpinner(async () => someApiCall())
 * loadWithSpinner(async () => someApiCall(), (e) => console.log(e))
 */

import { ref, Ref } from 'vue'

import store from '@/store'

export type Loading = { loading: Ref<boolean>, load: Function }
export type LoadingWithSpinner = { loadingSpinner: boolean, loadWithSpinner: Function }

export default (): Loading & LoadingWithSpinner => {
    const loading = ref(false)
    const loadingSpinner = store.getters.getLoadingStatus

    async function load<T> (fn: Function, handleError?: Function): Promise<T> {
        try {
            loading.value = true
            return await fn()
        } catch (e: unknown) {
            handleError?.(e)
        } finally {
            loading.value = false
        }
    }

    async function loadWithSpinner<T> (fn: Function, handleError?: Function): Promise<T> {
        try {
            loading.value = true
            store.dispatch('loadingBar/setLoading', true)
            return await fn()
        } catch (e) {
            return handleError?.(e)
        } finally {
            loading.value = false
            store.dispatch('loadingBar/setLoading', false)
        }
    }

    return {
        loading,
        load,

        loadingSpinner,
        loadWithSpinner,
    }
}
