import { defineComponent } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import PaymentMethod from '@/interfaces/PaymentMethod'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import dateTimeHelper from '@/helpers/dateTime'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import { mapState, mapActions } from 'pinia'
import DropdownButton from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import invoiceActionsClient from '@/io-modules/invoices/api-clients/invoiceActionsClient.ts'
import { mapState as mapStateVuex } from 'vuex'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView.ts'
import PaymentInfo from '@/io-modules/invoices/interfaces/PaymentInfo.ts'
import MarkAsPaidBody from '@/io-modules/invoices/components/mark-as-paid-body/MarkAsPaidBody.vue'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName.ts'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'



export default defineComponent({
    name: 'MarkAsPaidModal',
    components: {
        IOModal,
        FieldDateTime,
        DropdownButton,
        MarkAsPaidBody
    },
    data () {
        return {
            open: false,
            PaymentMethod,
            datepickerOptions: {
                'only-date': true,
                noClearButton: true,
                format: 'YYYY-MM-DD',
                formatted: dateTimeHelper.getDateFormat(),
            },
            paymentInfo: null
        }
    },
    computed: {
        ...mapState(invoiceSoVStore, ['netInvoiceValue']),

        ...mapState(invoiceViewStore, ['invoice']),

        ...mapStateVuex('project', {
            projectID: (state: any) => state.projectObj.project_local_id,
            projectGlobalId: (state: any) => state.projectObj.project_global_id,
        }),

        allRequiredFieldsCompleted (): boolean {
            return Boolean(this.paymentInfo?.amount && this.paymentInfo?.method && this.paymentInfo?.date && this.paymentInfo?.referenceNumber)
        },

        invoiceNet (): number {
            return this.netInvoiceValue - this.invoice.paid_amount || 0
        },

        paymentMethods (): object[] {
            return [
                {
                    id: PaymentMethod.ACH,
                    name: this.$t('ACH'),
                },
                {
                    id: PaymentMethod.Check,
                    name: this.$t('Check'),
                },
                {
                    id: PaymentMethod.CreditCard,
                    name: this.$t('Credit Card'),
                },
            ]
        },
    },
    methods: {
        ...mapActions(invoiceViewStore, [
            'updateInvoiceDetailsActions',
            'fetchInvoiceFinancialInfo',
            'fetchInvoiceDetails',
        ]),

        show (): void {
          this.open = true
        },

        closeModal (): void {
            this.paymentInfo = null
            this.open = false
        },

        async markAsPaid (): Promise<void> {
            const paymentInfo = {
                method: this.paymentInfo.method.id,
                date: this.paymentInfo.date,
                paid: this.paymentInfo.amount,
                number: this.paymentInfo.referenceNumber
            }

            this.closeModal()
            this.setLoadingBar(true)

            try {
                await invoiceActionsClient.markAsPaidInvoice(this.projectID, this.$route.params.id, paymentInfo)
                await this.updateInvoiceDetailsActions(this.projectID, this.$route.name, this.$route.params.id)
                if (InvoiceRouteName.InvoiceDetails === this.$route.name) {
                    this.fetchInvoiceDetails(this.projectID, this.$route.params.id)
                    this.fetchInvoiceFinancialInfo(this.projectID, this.$route.params.id)
                }
                this.showNotification('success', this.$t('Invoice has been successfully Paid.'))
            } catch (e) {
                console.log(e)
                this.showNotification('error', this.$t('Error occurred during marking Invoice as Paid.'))
            }

            this.setLoadingBar(false)
        }
    }
})
