<template>
    <IOModal
        width="850"
        slideout
        wide
        scrollable
        persistent
        :loading="punchItemLoading"
        :title="operation.title"
        @close="close"
    >
        <template #header-end>
            <IOButton
                v-if="!id"
                :disabled="!payloadValid"
                variant="secondary"
                @click="createAndAddNext"
            >
                {{ $t('Create and Add Next') }}
            </IOButton>

            <ActionDropdown
                v-if="id"
                class="cursor-pointer"
                :show-menu="false"
                icon-class="icon-history"
                :icon-tooltip="$t('Timeline')"
                @click="timelineModalShown = true"
            />

            <IOButton :disabled="!payloadValid" @click="operation.submit">{{ operation.action }}</IOButton>
        </template>

        <div>
            <div class="grid grid-cols-12 gap-9 items-start">
                <div class="grid grid-cols-12 gap-9 col-span-8">
                    <div class="col-span-12 flex gap-9">
                        <div>
                            <IOLabel is-required>{{ $t('Stamp') }}</IOLabel>

                            <StampPreview
                                v-if="defaults.stamp"
                                data-testid="stamp-preview"
                                disabled
                                :color="stamp.color"
                                :initials="stamp.initials"
                                size="lg"
                                button-mode
                            />

                            <StampSelect
                                v-else
                                :key="stamp?.id"
                                v-slot="stampSelectProps"
                                v-model="stamp"
                            >
                                <StampChangeDialog
                                    v-if="drawingReferences.length"
                                    @cancel="stampSelectProps?.toggleStampsIndex"
                                    @confirm="drawingReferences = []"
                                />
                            </StampSelect>
                        </div>

                        <div class="grow">
                            <IOLabel for="title" is-required>{{ $t('Title') }}</IOLabel>

                            <div class="relative">
                                <IOInput
                                    id="title"
                                    ref="titleInput"
                                    autofocus
                                    clearable
                                    maxlength="200"
                                    :loading="existingTitlesForAutofillLoading"
                                    :valid="!v$.title.$error"
                                    :autocomplete-items="existingTitlesForAutofill"
                                    v-model="v$.title.$model"
                                    @update:model-value="searchExistingTitlesForAutofill"
                                    @blur="v$.title.$touch()"
                                    @keyup.enter.stop="operation.submit"
                                    @autocomplete="autocomplete"
                                >
                                    <template #autocomplete="{ item: { title } }">{{ title }}</template>
                                </IOInput>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-6">
                        <IOLabel>{{ $t('Status') }}</IOLabel>

                        <Multiselect
                            track-by="id"
                            data-testid="status-multiselect"
                            :searchable="false"
                            :options="statusOptions"
                            :allow-empty="false"
                            preselect-first
                            v-model="status"
                        >
                            <template #singleLabel="{ option: { title, pillVariant } }">
                                <StatusPill :text="title" :variant="pillVariant"/>
                            </template>

                            <template #option="{ option: { title, pillVariant } }">
                                <StatusPill :text="title" :variant="pillVariant"/>
                            </template>
                        </Multiselect>
                    </div>

                    <div class="col-span-6">
                        <IOLabel>{{ $t('Due Date') }}</IOLabel>

                        <FieldDateTime
                            data-testid="due-date-picker"
                            class="w-full"
                            :options="datePickerSettings"
                            v-model="dueDate"
                        />
                    </div>

                    <div class="col-span-12">
                        <IOLabel for="comment">{{ $t('Comment') }}</IOLabel>

                        <IOTextarea
                            id="comment"
                            maxlength="300"
                            v-model="comment"
                        />
                    </div>
                    <div v-if="isJobsiteLocationsFFEnabled" class="col-span-12">
                        <JobsiteLocationsPreview
                            v-if="!punchItemLoading"
                            :locationsPaths="jobsiteLocations"
                            v-model="jobsiteLocationsIds"
                        />
                    </div>

                    <div class="col-span-6">
                        <IOLabel>{{ $t('Punch List') }}</IOLabel>

                        <div v-progress="punchListsLoading">
                            <Transition name="fade-from-top">
                                <Multiselect
                                    v-if="punchLists"
                                    track-by="id"
                                    data-testid="punch-list-multiselect"
                                    :options="activePunchLists"
                                    :placeholder="$t('Select')"
                                    v-model="punchList"
                                >
                                    <template #singleLabel="{ option: { label } }">
                                        <div>{{ label }}</div>
                                    </template>

                                    <template #option="{ option: { label } }">
                                        <div>{{ label }}</div>
                                    </template>
                                </Multiselect>
                            </Transition>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col col-span-4 gap-9">
                    <div>
                        <IOLabel>{{ $t('Originator') }}</IOLabel>

                        <Transition name="fade-from-top">
                            <Avatar
                                v-if="originator"
                                :employee-mongo-id="originator.id"
                                :employee-full-name="originator.name"
                                show-full-name
                                class="mt-12-px"
                            />
                        </Transition>
                    </div>

                    <div class="mt-4-px">
                        <IOLabel>{{ $t('Members') }}</IOLabel>

                        <div v-progress="projectDirectoryCompaniesLoading">
                            <Transition name="fade-from-top">
                                <Multiselect
                                    v-if="projectDirectoryCompanies"
                                    :options="projectDirectoryCompanies"
                                    multiple
                                    group-values="persons"
                                    group-label="name"
                                    :close-on-select="false"
                                    :placeholder="$t('Select')"
                                    :selected-label="$t('Selected')"
                                    track-by="id"
                                    label="name"
                                    group-select
                                    v-model="members"
                                >
                                    <template #option="{ option, index }">
                                        <div v-if="option.$isLabel" class="flex gap-8-px items-center">
                                            <IOLabel class="mb-0" :text="option.$groupLabel"/>
                                        </div>

                                        <Avatar
                                            v-else
                                            :employee-mongo-id="option.id"
                                            :employee-full-name="option.name"
                                            :company-name="getCompanyName(option.id)"
                                            size="sm"
                                            show-full-name
                                        />
                                    </template>

                                    <template #selection="{ values, isOpen }">
                                        <div v-if="!isOpen && values.length" class="flex items-center h-full text-sm">
                                            {{ values.length }} {{ $t('Selected') }}
                                        </div>
                                    </template>
                                </Multiselect>
                            </Transition>
                        </div>
                    </div>

                    <div>
                        <IOLabel>{{ $t('Ball in Court') }}</IOLabel>

                        <div v-progress="projectDirectoryCompaniesLoading">
                            <Transition name="fade-from-top">
                                <Multiselect
                                    v-if="projectDirectoryCompanies"
                                    :options="projectDirectoryCompanies"
                                    group-values="persons"
                                    group-label="name"
                                    :placeholder="$t('Select')"
                                    track-by="id"
                                    label="name"
                                    :selected-label="$t('Selected')"
                                    v-model="ballInCourtMember"
                                >
                                    <template #option="{ option, index }">

                                        <div v-if="option.$isLabel" class="flex gap-8-px items-center">
                                            <IOLabel class="mb-0" :text="option.$groupLabel"/>

                                            <StatusPill
                                                :variant="getAppTypeStatusPillVariant(option.$groupLabel)"
                                                :text="option.$groupLabel?.text"
                                            />
                                        </div>

                                        <Avatar
                                            v-else
                                            :employee-mongo-id="option.id"
                                            :employee-full-name="option.name"
                                            :company-name="getCompanyName(option.id)"
                                            size="sm"
                                            show-full-name
                                        />

                                    </template>

                                    <template #singleLabel="{ option: { id, name } }">
                                        <Avatar
                                            class="mt-1.5"
                                            :employee-mongo-id="id"
                                            :employee-full-name="name"
                                            size="sm"
                                            show-full-name
                                        />
                                    </template>
                                </Multiselect>
                            </Transition>
                        </div>
                    </div>

                    <div>
                        <IOLabel>{{ $t('Drawing Reference') }}</IOLabel>

                        <DrawingReferenceSelect
                            v-if="!punchItemLoading"
                            class="mt-12-px"
                            :editable="!defaults.drawingReferences?.length"
                            :stamp="stamp"
                            cursor-class="io-adding-punch"
                            v-model="drawingReferences"
                        />
                    </div>

                    <div v-if="!isJobsiteLocationsFFEnabled">
                        <IOLabel>{{ $t('Jobsite Locations') }}</IOLabel>

                        <JobsiteLocationsSelect
                            v-if="!punchItemLoading"
                            class="mt-12-px"
                            editable
                            multiple
                            v-model="jobsiteLocations"
                        />
                    </div>
                </div>
            </div>

            <FileUploaderWithTable
                class="flex flex-col"
                v-model:files="files"
            />

            <TimelineModal
                v-if="timelineModalShown"
                :url="timelineRequestUrl"
                @close="timelineModalShown = false"
            />
        </div>
    </IOModal>
</template>


<script lang="ts" src="./PunchItemOperationsSlideout.ts"></script>
<style lang="scss" src="./PunchItemOperationsSlideout.scss" scoped></style>
