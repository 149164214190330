export const updateChatType = (state, val) => {
    state.chatType = val
}

export const updateChatThreadId = (state, val) => {
    state.chatThreadId = val
}

export const updateChatThreadName = (state, val) => {
    state.chatThreadName = val
}

export const updateChatThreadType = (state, val) => {
    state.chatThreadType = val
}
