import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'
import storybook from './storybook'

export default {
    namespaced: true,
    namespace: 'filesComponent/',
    state () {
        return {
            storybook,

            my_documents: {},
            shared_documents: {},
            trash_documents: {},
            folders: {},
            fileHover: '',
            foldersBreadcrumbs: [],
            folderFlags: {},
            syncAllSharedFiles: false,
            searchPathsVal: '',
            isSearching: false,
            searchLocal: false,
            searchPaths: [],
            loading: 0,
            viewType: 'list',
            uploaderEnabled: false,
            showAddFolderModal: false,
            createNewFolderInProjectPhotos: false,
            openUploadDocumentDialog: false,
            sharingResourceObject: false,
            sharingResourceWay: 'direct-invite',
            projectPhotosFolder: null,
            customColumns: [],
            selectedFiles: [],
            selectedFilesForActions: {},
            lastSelectedFileForAction: {},
            selectMultipleFiles: true,
            isRenameFolderModalShown: false,
            uploadingFilesList: {},
        }
    },
    actions,
    mutations,
    getters
}
