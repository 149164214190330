import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    props: {
        data: { type: Object as () => any, required: true, default: null },
        column: { type: Object as () => ColumnInterface, required: true, default: null }
    },
    methods: {
        getHeader () {
            const field = this.column.field

            const headerField = this.column?.additional?.header

            if (!this.data[field]) {
                return '-'
            }

            // @ts-ignore
            return this.data[field][headerField]
        },
        getSubHeader () {
            const field = this.column.field
            const subHeaderField = this.column?.additional?.subheader

            if (!this.data[field]) {
                return ''
            }

            // @ts-ignore
            return this.data[field][subHeaderField]
        }
    }
})
