import InvoicesListTable from '@/io-modules/invoices/components/invoices-list/invoices-list-table/InvoicesListTable.vue'
import ListsMixin from '@/io-modules/invoices/mixins/ListsMixin'
import { defineComponent } from 'vue'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { mapActions } from 'pinia'
import ListingName from '@/io-modules/invoices/enums/ListingName.ts'

export default defineComponent({
    name: 'InvoicesDefaultList',
    components: {
        InvoicesListTable,
    },
    mixins: [ListsMixin],
    data () {
        return {
            loading: false,
            totals: null
        }
    },
    methods: {
        ...mapActions(invoicesStore, ['fetchReceivedInvoices']),
        async loadData (): Promise<void> {
            this.loading = true
            await this.fetchReceivedInvoices(this.projectObj.project_local_id)
            this.totals = await this.fetchInvoiceTotals(ListingName.Received)
            this.loading = false
        }
    }
})
