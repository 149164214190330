import { mapState } from 'vuex'
import fieldProposedId from '../fields/fieldProposedId.vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn.vue'
import CustomMultiSelect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import { defineComponent } from 'vue'
import CompanyContactDropdown from '@/elements/CompanyContactDropdown/CompanyContactDropdown.vue'
import type { BidRequestContact } from '@/io-modules/bid-requests/interfaces'
import type SharedWorkspaceData from '@/elements/CompanyContactDropdown/interfaces/SharedWorkspaceData'
import proposalClient from '@/io-modules/rfp-proposal/api-clients/ProposalClient.ts'

export default defineComponent({
    name: 'PopupNewProposal',

    components: {
        fieldProposedId,
        PopupModal,
        FormInputColumn,
        CustomMultiSelect,
        CompanyContactDropdown,
    },

    data () {
        return {
            proposedId: null,
            duringCreating: false,
            loaded: false,
            name: '',
            selectedCompany: {} as SharedWorkspaceData,
            selectedContact: {} as BidRequestContact || null,
            clientAppTypes: [] as string[],
        }
    },

    computed: {
        ...mapState('modal', {
            returnData: (state) => state.returnData
        }),

        selectedCompanyId (): string | null {
            return this.selectedCompany?.id ?? null
        },

        selectedCompanyName (): string {
            return this.selectedCompany.name ?? ''
        },

        btnCreateClassName (): string {
            return this.isValid ? 'io-btn-primary' : 'io-btn-disabled'
        },

        isValid (): boolean {
            return this.name && this.selectedCompanyId && this.selectedContact && this.selectedContact.id && this.proposedId && this.proposedId.value
        },
    },

    created () {
        this.getInitialData()
    },

    methods: {
        onSelectedCompany (selectedCompany: SharedWorkspaceData): void {
            this.selectedCompany = selectedCompany
            this.onSelectedContact(null)
        },

        onSelectedContact (selectedContact: BidRequestContact): void {
            this.selectedContact = selectedContact
        },

        async getInitialData (): Promise<void> {
            this.$store.dispatch('loadingBar/setLoading', true, { root: true })
            try {
                await Promise.all([
                    this.getProposedId(),
                ])
                this.loaded = true
            } catch (exception) {
                this.showNotification('error', 'Error occurred during loading clients list.', exception)
            }
            this.$store.dispatch('loadingBar/setLoading', false, { root: true })
        },

        async getProposedId (): Promise<void> {
            try {
                const { data } = await this.$api.get('/proposal/get-next-id')
                this.proposedId = data.id
                this.clientAppTypes = data.clientAppTypes
            } catch (exception) {
                this.errorHandleNoRedirect(exception)
            }
        },

        closePopup (): void {
            this.$emit('close')
        },

        async create (): Promise<boolean | void> {
            if (!this.isValid) {
                return false
            }

            try {
                this.duringCreating = true
                this.setLoadingBar(true)
                this.closePopup()

                const { data } = await proposalClient.create( {
                    name: this.name,
                    id: this.proposedId,
                    client_id: this.selectedCompanyId,
                    contact_id: this.selectedContact.id
                })
                this.showNotification('success', 'Proposal has been created.')
                this.$router.push({
                    name: 'proposal-view-new',
                    params: {
                        id: data.id,
                    },
                    hash: '#general-information',
                })
            } catch (exception) {
                if (exception.response && exception.response.data.message === 'ALREADY_USED_ID') {
                    this.showNotification('error', 'This ID is already used.', exception)
                } else {
                    this.showNotification('error', 'Error occurred during creating proposal.', exception)
                }
            } finally {
                this.setLoadingBar(false)
                this.duringCreating = false
            }
        },

        onChangeName (value): void {
            this.name = value
        },
    }
})
