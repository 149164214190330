<template>
    <RightModal
        class="io-mark-as-paid-modal"
        width="760px"
        @close="$emit('close')"
    >
        <template #header>
            <div class="io-mark-as-paid-modal__header">
                <span class="io-mark-as-paid-modal__title">{{ $t('Mark as Paid Invoices') }}</span>
                <div class="io-mark-as-paid-modal__actions">
                    <IOButton @click="payInvoices">
                        {{ $t('Save') }}
                    </IOButton>
                    <span class="icon-cross" @click="$emit('close')"/>
                </div>
            </div>
        </template>
        <template #default>
            <div v-if="invoicesToPay" class="io-mark-as-paid-modal__body">
                <div class="io-mark-as-paid-modal__invoices">
                    <div 
                        v-for="invoice in invoicesToPay"
                        :key="invoice.id"
                        class="io-mark-as-paid-modal__invoice"
                        :class="{ 'io-mark-as-paid-modal__invoice--is-active': invoice.id === selectedInvoice?.id }"
                        @click="changeSelectedInvoice(invoice)"
                    >
                        <div class="io-mark-as-paid-modal__invoice__info">
                            <div>{{ invoice.invoice_number }}</div>
                            <div>{{ $filters.formatCurrencyInt(invoice.net_invoice_value) }}</div>
                        </div>
                        <span v-if="isPaymentInfoFilled(invoice.id)" class="icon-check-circle-1-2"></span>
                    </div>
                </div>
                <div v-if="selectedInvoice" class="io-mark-as-paid-modal__payment">
                    <div class="io-mark-as-paid-modal__current-invoice">
                        <Avatar
                            :show-full-name="true"
                            type="company"
                            :employee-mongo-id="selectedInvoice.vendor.id"
                            :employee-full-name="selectedInvoice.vendor.name"
                        />
                        <span>{{ selectedInvoice.invoice_number }}</span>
                        <span class="icon-external-link" @click="navigateToInvoice(selectedInvoice.id)"/>
                        <StatusPill v-if="isPaymentInfoFilled(selectedInvoice.id)"
                                    variant="green-light"
                                    :text="$t('Paid')"
                        />
                    </div>
                    <ContentTwoSidesTemplate
                        class="io-mark-as-paid-modal__payment__body"
                        iconClass="icon-info"
                        :title="$t('Information')">
                        <template #leftSide>
                            <MarkAsPaidBody
                                :key="selectedInvoice.id"
                                :net-invoice-value="getSelectedInvoiceAmount(selectedInvoice)"
                                v-model="filledPaymentInfo[selectedInvoice.id]"
                                @update:modelValue="paymentInfo => setFilledInfo(selectedInvoice.id, paymentInfo)"
                            />
                        </template>
                        <template #rightSide>
                            <IOSingleInfoRow
                                :label="$t('Net Current Application')"
                            >
                                <template #info>
                                    <span>{{ $filters.formatCurrencyInt(selectedInvoice.net_invoice_value) }}</span>
                                </template>
                            </IOSingleInfoRow>
                            <IOSingleInfoRow
                                :label="$t('Current Retention Amount')"
                            >
                                <template #info>
                                    {{ $filters.formatCurrencyInt(selectedInvoice.current_retention) }}
                                </template>
                            </IOSingleInfoRow>
                            <IOSingleInfoRow
                                :label="$t('Gross Current Application')"
                            >
                                <template #info>
                                    {{ $filters.formatCurrencyInt(selectedInvoice.gross_invoice_value) }}
                                </template>
                            </IOSingleInfoRow>
                        </template>
                    </ContentTwoSidesTemplate>
                </div>
            </div>
        </template>
    </RightModal>
</template>

<script lang="ts" src="./MarkAsPaidModal.ts"></script>
<style lang="scss" src="./MarkAsPaidModal.scss" scoped></style>
