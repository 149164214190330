import { defineComponent } from 'vue'
import { Workspace } from '@/interfaces/components/invite-modal/Workspace'

export default defineComponent({
    name: 'ExistingWorkspace',
    props: {
        idx: { type: Number, required: true },
        item: { type: Object as () => Workspace, required: true },
        value: { type: String, default: undefined },
        modelValue: { default: '' }
    },
    computed: {
        isChecked () {
            return this.modelValue == this.value
        }
    },
    mounted () {
        // @ts-ignore
        this.preselectWorkspace()
    },
    methods: {

        getAddress(item): string | null {
            let address = ''
            let places = [item.address_line1, item.city, item.state_name, item.zip]

            places.forEach((place: string | null) => {
                if (place) {
                    address += `${place}, `
                }
            })

            return address;
        },
        preselectWorkspace () {
            if (this.idx === 0) {
                this.$emit('update:modelValue', this.value)

                const input = document.querySelector(`.io-existing-workspace-${this.idx}`) as HTMLElement

                if (input) {
                    input.click()
                }
            }
        },
        statusClass (status: string): string {
            const styleClassByState = {
                'sub': 'accent-sc',
                'sc': 'accent-sc',
                'gc': 'accent-gc',
                'ac': 'accent-ac',
                'dev': 'accent-dev',
                'owner': 'accent-dev',
                'rep': 'accent-dev',
                'design': 'accent-ac',
                'investor': 'accent-investor',
                'vc': 'accent-light',
                'genius': 'accent-genius',
                'default': 'accent-light'
            }
            return styleClassByState[status] || styleClassByState['default']
        }
    }
})
