<template>
    <div
        class="io-design-system io-input-component"
        :style="$attrs.style"
        :class="[
            $attrs.class,
            {
                'io-disabled': disabled,
                'io-focused': focused,
                'io-invalid': !valid,
                'io-no-number-spinners': noNumberSpinners,
            },
        ]"
    >
        <div class="io-input-component__content">
            <div v-if="icon" class="io-input-component__icon" @click="$emit('iconClick')">
                <i :class="icon" />
            </div>

            <div v-if="prefix" class="io-input-component__prefix">{{ prefix }}</div>
            <template v-if="showSearch">
                <input
                    type="text"
                    class="io-input-component__input"
                    :disabled="disabled"
                    v-model="searchValue"
                    @focus="searchFocus()"
                    @blur="searchFocusOut()"
                />
            </template>
            <input
                v-show="!showSearch"
                v-bind="omit($attrs, ['class', 'style'])"
                ref="IOInput"
                :class="'io-input-component__input' + ' ' + additionalInputClass"
                data-testid="io-input"
                :type="$attrs.type || 'text'"
                v-model="currentValue"
                @focus="focused = true"
                @blur="focused = false"
                @keydown="onKeyDown"
                @keyup.enter="onKeyupEnter"
            />

            <div v-if="loading || clearable" class="io-input-component__actions">
                <transition name="fade">
                    <i
                        v-if="loading"
                        class="io-input-component__actions-icon io-input-component__actions-icon--loading icon-loader"
                    />
                </transition>

                <transition name="fade" mode="in-out">
                    <i
                        v-if="clearable && currentValue && !disabled"
                        class="io-input-component__actions-icon io-input-component__actions-icon--clear icon-cross"
                        @click.stop="clear"
                    />
                </transition>
            </div>

            <div v-else-if="filteredItems?.length" class="io-input-component__actions">
                <i
                    class="io-input-component__actions-icon io-input-component__actions-icon--chevron icon-chevron-down"
                    :class="{ 'io-focused': focused }"
                />
            </div>

            <div v-if="suffix" class="io-input-component__suffix">{{ suffix }}</div>

            <transition name="fade-from-bottom">
                <ul v-if="filteredItems?.length && focused && !disabled" class="io-input-component__autocomplete">
                    <li
                        v-for="(item, idx) in filteredItems"
                        :key="idx"
                        class="io-input-component__autocomplete-item"
                        @click="emitAutocomplete(item)"
                    >
                        <slot name="autocomplete" :item="item" />
                    </li>
                </ul>
            </transition>
        </div>

        <transition name="fade-from-top">
            <p v-if="showErrorMessage" class="io-input-component__error-message">{{ errorMessage }}</p>
        </transition>
    </div>
</template>

<script lang="ts" src="./IOInput.ts"></script>
<style lang="scss" src="./IOInput.scss" scoped></style>
