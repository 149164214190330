<template>
    <div class="io-capital-funds-details">
        <div class="io-capital-funds-details__header">{{ $t('Details') }}</div>
        <div class="io-capital-funds-details__columns">
            <div class="io-capital-funds-details__column">
                <IOSingleInfoRow :label="$t('Fund Name')" :info="info.name"/>
                <IOSingleInfoRow :label="$t('Start Date')" :info="info.start_date_formatted"/>
                <IOSingleInfoRow :label="$t('Provider')">
                    <template #info>
                        <Avatar
                            type="company"
                            :employeeMongoId="provider.provider.id"
                            :employeeFullName="provider.provider.name"
                            showFullName
                            size="sm"
                        />
                    </template>
                </IOSingleInfoRow>
            </div>
            <div class="io-capital-funds-details__column">
                <IOSingleInfoRow :label="$t('Account Number')" :info="info.account_number"/>
                <IOSingleInfoRow :label="$t('Expiration Date')" :info="info.expiration_date_formatted"/>
                <IOSingleInfoRow :label="$t('Contact Person')">
                    <template #info>
                        <Avatar
                            :employeeMongoId="info.contact.id"
                            :employeeFullName="contactFullName"
                            showFullName
                            size="sm"
                        />
                    </template>
                </IOSingleInfoRow>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./CapitalFundInfo.ts"></script>
<style lang="scss" src="./CapitalFundInfo.scss" scoped></style>
