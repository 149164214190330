import ListFilters from '@/io-modules/invoices/interfaces/invoices-list/ListFilters'
import ListFiltersListingParams from '@/io-modules/invoices/interfaces/invoices-list/ListFiltersListingParams'

export default class FiltersMapper {
    public static toListingParams (filters: ListFilters): ListFiltersListingParams {
        return {
            type: filters.type,
            invoice_date: filters.invoice_date,
            lien_waiver_status: filters.lien_waiver_status,
            ball_in_court: filters.ball_in_court?.map(user => user.id),
            vendor: filters.vendor?.map(user => user.id),
            payment_status: filters.payment_status,
            billing_date: filters.billing_date,
            billing_period: filters.billing_period,
            status: filters.status,
        }
    }
}
