import { AxiosPromise } from 'axios'
import { ApiClientV3 as client } from '@/api/api'
import { PdfExportType } from '@/interfaces/modules/projects/contract/PdfExportType.ts'
import { SharedStatus } from '@/io-modules/budget/enums/SharedStatus.ts'
import type BasicResponse from '@/interfaces/BasicResponse.ts'
import type Budget from '@/io-modules/budget/interfaces/Budget.ts'
import type BudgetStatus from '@/io-modules/budget/interfaces/BudgetStatus.ts'
import type BudgetStatusInfo from '@/io-modules/budget/interfaces/BudgetStatusInfo.ts'
import type BudgetSettings from '@/io-modules/budget/interfaces/BudgetSettings.ts'
import type AttachListToBudgetForm from '@/io-modules/budget/interfaces/AttachListToBudgetForm.ts'
import type SingleBudget from '@/io-modules/budget/interfaces/SingleBudget.ts'
import type BudgetTimelineEvent from '@/io-modules/budget/interfaces/BudgetTimelineEvent.ts'
import type BudgetExportValidationData from '@/io-modules/budget/interfaces/BudgetExportValidationData.ts'
import type SharedCostCodeListForBudget from '@/io-modules/budget/interfaces/SharedCostCodeListForBudget.ts'

const budgetClient = {
    attachList (id: string, form: AttachListToBudgetForm): AxiosPromise<BasicResponse> {
        return client.post(`budgets/${ id }/attach-list`, form)
    },

    create (projectId: string, budgetType: string): AxiosPromise<BasicResponse<Budget>> {
        return client.post(`project/${ projectId }/budget`, { budget_type: budgetType })
    },

    find (id: string): AxiosPromise<BasicResponse<SingleBudget>> {
        return client.get(`budgets/${ id }`)
    },

    findBudgetSharedStatus (id: string): AxiosPromise<{ shared_status: SharedStatus }> {
        return client.get(`budgets/${ id }/shared-status`)
    },

    share (id: string): AxiosPromise<BasicResponse> {
        return client.post(`budgets/${ id }/share`)
    },

    getStatus (projectLocalId: string, budgetType: string | null): AxiosPromise<BudgetStatus> {
        return client.get('budgets/status', { params: { project_local_id: projectLocalId, budget_type: budgetType } })
    },

    getBudgetStatus (projectLocalId: string): AxiosPromise<BudgetStatusInfo> {
        return client.get(`projects/${ projectLocalId }/budget-status`)
    },

    // projectId: project.project_local_id
    get (projectId: string): AxiosPromise<BasicResponse<Budget[]>> {
        return client.get(`project/${ projectId }/budget-selector`)
    },

    getSettings (projectId: string): AxiosPromise<BasicResponse<BudgetSettings>> {
        return client.get(`projects/${ projectId }/budget-settings`)
    },

    getExportValidationData (projectId: string): AxiosPromise<BasicResponse<BudgetExportValidationData>> {
        return client.get(`project/${ projectId }/budget-export-validation`)
    },

    getTimelineEvents (id: string): AxiosPromise<BasicResponse<BudgetTimelineEvent[]>> {
        return client.get(`budgets/${ id }/timeline-events`)
    },

    getSharedListWithNoNotification (id: string): AxiosPromise<BasicResponse<SharedCostCodeListForBudget>> {
        return client.get(`budgets/${ id }/shared-cost-code-list`)
    },

    buildExcelExportUrl (budgetId: string, type: PdfExportType, targetCurrency: string, rate: number): string {
        return client.getApi2RequestUrl(`/budgets/${ budgetId }/excel/export?${ getExportParams(type, targetCurrency, rate) }`)
    },

    buildBudgetPdfExportUrl (budgetId: string, type: PdfExportType, pdfType: string, removeEmptyCodes: boolean, targetCurrency: string, rate: number): string {
        return client.getApi2RequestUrl(`/budgets/${ budgetId }/export/pdf/budget?${ getPdfExportParams(type, pdfType, removeEmptyCodes, targetCurrency, rate) }`)
    },

    buildBudgetAcrExportUrl (budgetId: string, type: PdfExportType, pdfType: string, removeEmptyCodes: boolean, targetCurrency: string, rate: number): string {
        return client.getApi2RequestUrl(`/budgets/${ budgetId }/export/pdf/acr?${ getPdfExportParams(type, pdfType, removeEmptyCodes, targetCurrency, rate) }`)
    },

    buildBudgetAuthorizationsExportUrl (budgetId: string, type: PdfExportType, pdfType: string, removeEmptyCodes: boolean, targetCurrency: string, rate: number): string {
        return client.getApi2RequestUrl(`/budgets/${ budgetId }/export/pdf/authorizations?${ getPdfExportParams(type, pdfType, removeEmptyCodes, targetCurrency, rate) }`)
    },

    clearBudgetCostCodes (id: string): AxiosPromise<BasicResponse> {
        return client.put(`budgets/${ id }/clear-cost-codes`)
    },
}

function getExportParams (type: PdfExportType, targetCurrency: string, rate: number): string {
    const unit = {
        [PdfExportType.USEABLE]: 'usable',
        [PdfExportType.NO_DESIGNATED_AREA]: 'no-designated-area',
    }[type] ?? type

    return new URLSearchParams({
        area_unit: unit,
        target_currency: targetCurrency,
        rate: rate.toString(),
    }).toString()
}

function getPdfExportParams (type: PdfExportType, pdfType: string, removeEmptyCodes: boolean, targetCurrency: string, rate: number): string {
    const unit = {
        [PdfExportType.USEABLE]: 'usable',
        [PdfExportType.NO_DESIGNATED_AREA]: 'no-designated-area',
    }[type] ?? type

    return new URLSearchParams({
        area_unit: unit,
        pdf_type: pdfType,
        remove_empty_codes: removeEmptyCodes.toString(),
        target_currency: targetCurrency,
        rate: rate.toString(),
    }).toString()
}

export default budgetClient
