<template>
    <div class="io-design-system io-action-bar">
        <div class="io-action-bar__content">
            <div class="io-action-bar__text">
                <strong>{{ itemsQuantity }}</strong> {{ $tc('Item Selected | Items Selected', itemsQuantity, { itemsQuantity }) }}
            </div>

            <IOButton variant="link" size="small" @click="$emit('clear-selections')">
                {{ $t('Clear Selections') }}
            </IOButton>
        </div>

        <div class="io-action-bar__actions">
            <slot name="actions" />
        </div>
    </div>
</template>


<script lang="ts" src="./ActionBar.ts"></script>
<style lang="scss" src="./ActionBar.scss" scoped></style>
