<template>
<div class="io-workspace-radio" data-testid="workspace-radio">
    <div class="io-radio-container" :class="{ 'io-selected': isSelected }">
        <div class="io-radio-form">
            <input
                class="io-radio-input"
                :disabled="disabled"
                :name="inputName"
                type="radio"
                :value="radioValue"
                v-model="currentValue"
            />
            <div v-if="workspace.logo"
                 class="io-single-option io-option-avatar"
                 :style="getBackgroundCompanyLogo(workspace.logo)">
            </div>
            <initials
                v-else
                :bigAvatar="true"
                :fullName="workspace.name"
                class="io-single-option__workspace-logo"
                :class="{ 'io-offsystem-logo': !workspace.is_on_system }"
            />
        </div>

        <div class="io-label-container">

            <div>
                <div class="io-radio-label">
                    <span class="io-radio-label__workspace-name">{{ workspace.name }}</span>
                    <span class="io-single-contact__contact-details-pill">
                        <StatusPill
                            :text="workspace.app_type"
                            :variant="workspaceClass"
                        />
                    </span>
                </div>
                <div v-if="workspace.address" class="io-label-subtext">
                    {{ workspace.address?.address_line1 }}, {{ workspace.address?.city }} {{ workspace.address?.state }} {{ workspace.address?.zip }}
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script lang="ts" src="./WorkspaceRadio.ts"></script>
<style lang="scss" src="./WorkspaceRadio.scss" scoped></style>
