import { defineComponent } from 'vue'
import SearchInput from '@/components/search-input/SearchInput.vue'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'
import RightModal from '@/components/RightModal/RightModal.vue'
import Stepper from '@/components/stepper/Stepper.vue'
import StepperStep from '@/components/stepper/stepper-step/StepperStep.vue'
import CapitalProviderFundsList from '../../capital-provider-funds-row/CapitalProviderFundsList.vue'
import Placeholder from '@/components/placeholder/Placeholder.vue'
import ProjectFundingProviderFunds from '@/io-modules/project-funding/interfaces/ProjectFundingProviderFunds'
import AllocateFundsTable from '../../allocated-funds-table/AllocateFundsTable.vue'
import projectFundingClient from '@/io-modules/project-funding/api-clients/projectFundingClient'
import modalOnClose from '../../../helpers/modalOnClose'
import { ProjectFundingAllocatedRows } from '@/io-modules/project-funding/interfaces/ProjectFundingAllocatedRows'
import { mapState } from 'vuex'
import { mapState as piniaMapState, mapActions } from 'pinia'
import { projectFundingStore } from '@/io-modules/project-funding/stores/projectFundingStore'
import useLoader from '@/composables/useLoader.ts'
import { globalFundingStore } from '@/io-modules/global-funding/stores/globalFundingStore'

export default defineComponent({
    name: 'SelectCapitalFundModal',
    components: {
        AllocateFundsTable,
        Placeholder,
        CapitalProviderFundsList,
        StepperStep,
        Stepper,
        SearchInput,
        IOButton,
        RightModal,
    },
    mixins: [modalOnClose],
    emits: ['close'],
    setup () {
        const { loading, load } = useLoader()

        return { loading, load }
    },
    data () {
        return {
            numberSelected: 0,
            step: 0,
            listProvidersFunds: null as ProjectFundingProviderFunds[],
            providerFundsSearch: null as ProjectFundingProviderFunds[],
            isSearch: false,
            selectedFunds: null as ProjectFundingAllocatedRows[],
            allocationToFunds: null as ProjectFundingAllocatedRows[],
            canAddFund: false,
        }
    },
    computed: {
        ...mapState('project', {
            projectObj: state => state.projectObj,
        }),
        ...piniaMapState(globalFundingStore, [
            'fundCreatedFromProject'
        ]),
    },
    watch: {
        fundCreatedFromProject (): void {
            this.requestExistedCapitalFunds()
        }
    },
    async created () {
        await this.requestExistedCapitalFunds()
    },
    methods: {
        ...mapActions(projectFundingStore,[
            'setDataUpdation'
        ]),
        ...mapActions(globalFundingStore,[
            'setFundCreatedFromProject'
        ]),
        async requestExistedCapitalFunds (): Promise<void> {
            return this.load(async () => {
                const requestData = await projectFundingClient.getProjectProviderFunds(this.projectObj.project_local_id)
                if (requestData && requestData.data.items && requestData.data.items.length) {
                    this.listProvidersFunds = requestData.data.items
                }
            })
        },
        howToClose (): void {
            this.numberSelected > 0 ? this.showModalOnClose() : this.$emit('close')
        },
        updateNumberSelected (value: number): void {
            this.numberSelected = value
        },
        onSearchChange (value: string): void {
            if (value.length === 0) {
                this.providerFundsSearch = this.listProvidersFunds
                this.isSearch = false
            }
            if (value.length) {
                setTimeout(async () => {
                    const filteredArray = await projectFundingClient.getProjectProviderFunds(this.projectObj.project_local_id, value)
                    if (filteredArray && filteredArray.data.items.length) {
                        this.providerFundsSearch = filteredArray.data.items
                    } else {
                        this.providerFundsSearch = null
                    }

                    this.isSearch = true
                }, 300)
            }
        },
        onEndFundSelection (selected: ProjectFundingAllocatedRows[]): void {
            this.selectedFunds = selected
        },
        onEditAllocationToFund (allocation: ProjectFundingAllocatedRows[]): void {
            this.allocationToFunds = allocation
        },
        async addCapitalFunds (): Promise<void> {
            await projectFundingClient.setAllocationToFund(this.projectObj.project_local_id, this.allocationToFunds)
            this.$emit('close')
            this.setDataUpdation(true)
            this.showNotification('success', this.$t('Funds have been added!'))
        },
        changeCanAddFund (value: boolean): void {
            this.canAddFund = value
        },
    },
})
