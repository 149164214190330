const commonMixin = {
    methods: {
        hasSubtasks (item: object): boolean {
            return item.children && item.children.length
        },

        getSumOfTasksValues (item: object, field: string, filter?: string): string | number {
            if (!item.children || !item.children.length) {
                const value = item[field] || 0
                if (filter) {
                    return this.$filters[filter](value)
                } else {
                    return value
                }
            } else {
                let sum = 0
                item.children.forEach(task => {
                    sum += task[field] || 0
                })
                if (item[field] !== sum) {
                    item[field] = sum
                }  
                
                return filter ? this.$filters[filter](sum) : sum
            }
        },
    }
}

export default commonMixin