import { defineComponent } from 'vue'
import ProjectFundingDetails from '../views/project-funding-details/ProjectFundingDetails.vue'
import FieldNumeric from '@/components/fields/fieldNumeric.vue'
export default defineComponent({
    name: 'ProjectFundingMain',
    components: {
        ProjectFundingDetails,
        FieldNumeric
    }
})
