import { ApiClient as client } from '../api'

export default {
    getSubmittalTypes (projectID) {
        return client.get(`/ca/submittal/${ projectID }/project-settings/submittals-types`)
    },
    saveSubmittalTypes (projectID, submittalTypes) {
        return client.put(`/ca/submittal/${ projectID }/project-settings/submittals-types`, { items: submittalTypes })
    },
    getScheduleSettings (projectID) {
        return client.get(`/ca/submittal/${ projectID }/project-settings/schedule-settings`)
    },
    saveScheduleSettings (projectID, scheduleSettings) {
        return client.put(`/ca/submittal/${ projectID }/project-settings/schedule-settings`, scheduleSettings)
    },
}
