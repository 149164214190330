<template>
    <input
        v-if="editable"
        autocomplete="off"
        type="text"
        :disabled="true"
        :name="name"
        :placeholder="placeholder"
        :class="[classname]"
        v-model="currentValue"
    />
    <span v-else>{{ modelValue.formatted }}</span>
</template>

<script>
    export default {
        name: 'FieldProposedId',
        props: {
            modelValue: {
                type: Object,
                required: true,
                default: () => ({})
            },
            editable: {
                type: Boolean,
                default: true,
                required: false
            },
            name: {
                type: String,
                default: '',
                required: false
            },
            classname: {
                type: String,
                default: '',
                required: false
            }
        },
        data () {
            return {
                placeholder: '',
                pattern: '',
                originalValue: null,
                value: null,
            }
        },

        computed: {
            currentValue: {
                get () {
                    return this.modelValue.formatted
                },

                set (newData) {
                    let result = this.pattern.exec(newData)
                    this.prepareNumber(result)
                    if (!newData) {
                        this.value.value = this.originalValue
                        this.value.custom = false
                    }
                    this.$emit('update:modelValue', this.value)
                }
            }
        },

        watch: {
            modelValue () {
                this.value = { ...this.modelValue }
            }
        },

        created () {
            this.value = { ...this.modelValue }
            this.placeholder = this.modelValue.formatted.toString()
            if (this.editable && typeof this.modelValue.original === 'undefined') {
                this.value.original = this.placeholder
                this.pattern = '^' + this.value.mask.replace('<IONUM>', '(\\d*)') + '$'
                this.pattern = new RegExp(this.pattern, 'i')
                this.originalValue = parseInt(this.modelValue.value)
                this.value.custom = false
            }
        },

        methods: {
            prepareNumber (result) {
                let number = null
                let isCustom = true

                if (result && result[1]) {
                    let newNumber = parseInt(result[1])
                    if (newNumber) {
                        number = newNumber
                        isCustom = false
                    }
                }
                this.value.value = number
                this.value.custom = isCustom
            }
        }
    }
</script>
