const FormDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue')
const EmployeeList = () => import(/* webpackChunkName: "form-generator" */ './views/list/EmployeeList.vue')
const EditEmployee = () => import(/* webpackChunkName: "employees" */ './views/edit/EditEmployee.vue')
const EmployeeView = () => import(/* webpackChunkName: "form-generator" */ './views/view/EmployeeView.vue')

export default [
    {
        path: '/employee/list',
        name: 'employee-list',
        component: EmployeeList,
        meta: {
            schema: 'employee',
            customClass: 'io-employee-list-v2',
            title: 'Employees List'
        }
    },
    {
        path: '/employee/edit/:id',
        name: 'employee-edit',
        component: EditEmployee,
        meta: {
            schema: 'employee',
            title: 'Edit Employee'
        }
    },
    {
        path: '/employee/edit',
        name: 'employee-new',
        component: EditEmployee,
        meta: {
            schema: 'employee',
            title: 'Add New Employee'
        }
    },
    {
        path: '/employee/view/:id',
        name: 'employee-view',
        component: EmployeeView,
        meta: {
            schema: 'employee',
            title: 'Employee Details'
        }
    },
    {
        path: '/employee/import',
        name: 'employee-import',
        component: FormDefault,
        meta: {
            schema: 'employee/import',
            title: 'Import Employee'
        }
    }
]
