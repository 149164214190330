<template>
    <tr
        v-if="(item.visible !== undefined && item.visible) || true"
        :id="item[itemIdentifier]"
        class="io-expandable-table-row"
        :class="getRowClasses"
    >
        <template v-for="(header, headerIndex) in headers" :key="headerIndex">
            <td
                :class="`${getClasses(header)} ${setSmallPadding(header) ? 'io-small-padding' : ''} ${cellErrorMessage(header) ? 'io-value-error' : ''} ${invisible(header) ? 'io-cell-invisible' : ''}`"
                :style="header.extraStyles"
                :colspan="colspan(header)"
                :hidden="hidden(header)"
            >
                <div class="io-expandable-table-row__cell">
                    <div v-if="0 === headerIndex && levelIndex" class="io-prefix" :style="indentationStyles">
                        <span
                            class="io-cell-icon-left icon-chevron-right"
                            :class="{ 'io-icon-hidden': !hasNestedRows, 'io-expanded': isExpanded }"
                        ></span>
                        <span v-if="header.editable(item) && levelPrefix(header)">
                            {{ levelPrefix(header) }}
                        </span>
                    </div>

                    <template v-if="showLeftIcon(header)">
                        <span
                            v-for="(icon, iconIndex) in header.leftIcons(item)"
                            :key="iconIndex"
                            :class="`io-cell-icon-left ${icon}`"
                        ></span>
                    </template>
                    <field-numeric-int
                        v-if="header.editable(item) && CellType.InputNumInt === cellValueType(header)"
                        :id="`${ headerIndex }-${ item[itemIdentifier] }`"
                        :modelValue="cellModelValue(header)"
                        :max="maxValue(header)"
                        :min="minValue(header)"
                        :placeholder="placeholder(header, item)"
                        :options="fieldNumericIntOptions(header)"
                        :showRounded="true"
                        :sign="sign(header)"
                        :disableAddon="disableSign(header)"
                        @update:modelValue="data=>updateValue(header, data)"
                        @click.stop="emitInputClick(item)"
                    />
                    <field-numeric
                        v-else-if="header.editable(item) && CellType.InputNum === cellValueType(header)"
                        :modelValue="cellModelValue(header)"
                        :max="maxValue(header)"
                        :min="minValue(header)"
                        :placeholder="placeholder(header, item)"
                        :options="{ numeralPositiveOnly: false, numericOnly: false }"
                        :showRounded="true"
                        :sign="sign(header)"
                        :disableAddon="disableSign(header)"
                        :numberCharsLimit="numberCharsLimit(header)"
                        @update:modelValue="data=>updateValue(header, data)"
                        @click.stop="emitInputClick(item)"
                    />
                    <IOInput
                        v-else-if="header.editable(item) && CellType.Input === cellValueType(header) && (!!header.prefix || !!header.suffix)"
                        type="number"
                        :min="minValue(header)"
                        :max="maxValue(header)"
                        :modelValue="cellModelValue(header)"
                        :placeholder="placeholder(header, item)"
                        :prefix="!disableSign(header) && header.prefix"
                        :suffix="!disableSign(header) && header.suffix"
                        @update:modelValue="data=>updateValue(header, data)"
                        @click.stop="emitInputClick(item)"
                    />
                    <IOInput
                        v-else-if="header.editable(item) && CellType.Input === cellValueType(header)"
                        :modelValue="cellModelValue(header)"
                        :placeholder="placeholder(header, item)"
                        @update:modelValue="data=>updateValue(header, data)"
                        @click.stop="emitInputClick(item)"
                    />
                    <StatusDot
                        v-else-if="CellType.StatusDot === cellValueType(header)"
                        :variant="cellValue(header).color"
                        :text="levelPrefix(header) ? `${levelPrefix(header)} ${cellValue(header).text}` : cellValue(header).text"
                        :tooltip="cellValue(header).tooltip ? { text: cellValue(header).tooltip, top: true, end: true } : null"
                    />
                    <template v-else-if="CellType.Avatar === cellValueType(header)">
                        <div class="io-avatar-cell">
                            <Avatar
                                :employeeMongoId="headerAvatar(header).id"
                                :employeeFullName="headerAvatar(header).name"
                                :showFullName="true"
                                :type="headerAvatar(header).type"
                                size="sm"
                            />
                            <StatusPill
                                v-if="headerAvatar(header).status"
                                :variant="headerAvatar(header).status"
                                :text="headerAvatar(header).statusText"
                            />
                        </div>
                    </template>
                    <template v-else-if="CellType.TextStatusPill === cellValueType(header)">
                        {{ textStatusPill(header).text }}
                        <StatusPill
                            :variant="textStatusPill(header).status"
                            :text="textStatusPill(header).statusText"
                        />
                    </template>
                    <template v-else-if="header.editable(item) && CellType.Dropdown === cellValueType(header)">
                        <multiselect
                            :modelValue="cellModelValue(header)"
                            :placeholder="placeholder(header, item)"
                            :options="multiselectOptions(header)"
                            :group-label="multiselectSettings(header)['group-label']"
                            :group-values="multiselectSettings(header)['group-values']"
                            :label="multiselectSettings(header)['label']"
                            :track-by="multiselectSettings(header)['track-by']"
                            :max-height="multiselectSettings(header)['max-height']"
                            :allow-empty="false"
                            :searchable="true"
                            :taggable="multiselectSettings(header)['taggable']"
                            :tag-placeholder="multiselectSettings(header)['tag-placeholder']"
                            @tag="addTag(header, $event)"
                            @update:modelValue="data => updateValue(header, data)"
                            @search-change="handleMultiselectSearchChange"
                            @click.stop
                        >
                            <template #caret="{ toggle }">
                                <div class="multiselect__select" @mousedown.prevent.stop="toggle()">
                                    <span class="icon-chevron-down"></span>
                                </div>
                            </template>

                            <template
                                v-if="multiselectSettings(header)['is-type-and-add-enabled']"
                                #noOptions
                            >
                                <div class="io-multiselect__placeholder io-multiselect__before-list">
                                    {{ $t('Type and press') }} <span class="icon-arrow-corner-cw-lb-rec"></span>
                                    {{ $t('to add new') }}
                                </div>
                            </template>
                            <template
                                v-if="multiselectSettings(header)['is-type-and-add-enabled'] && !this.multiselectSearchValue.length && !!multiselectOptions(header)?.length"
                                #beforeList
                            >
                                <div class="io-multiselect__placeholder io-multiselect__before-list">
                                    {{ $t('Type and press') }} <span class="icon-arrow-corner-cw-lb-rec"></span>
                                    {{ $t('to add new') }}
                                </div>
                            </template>
                            <template
                                v-if="multiselectSettings(header)['is-type-and-add-enabled']"
                                #option="props"
                            >
                                <div class="io-option" :class="{ 'io-new': props.option.isTag }">
                                    <template v-if="props.option.isTag">
                                        <div class="io-option--new">
                                            <TruncatedText
                                                :lines-limit="2"
                                                :text="props.option.label"
                                            />

                                            <StatusPill :text="$t('New')" variant="blue-light"/>
                                        </div>

                                        <div class="io-option--placeholder io-multiselect__placeholder">
                                            {{ $t('Press') }} <span class="icon-arrow-corner-cw-lb-rec"></span>
                                            {{ $t('to add new') }}
                                        </div>
                                    </template>

                                    <template v-else>
                                        <TruncatedText
                                            :lines-limit="2"
                                            :text="props.option[multiselectSettings(header)['label']]"
                                        />
                                    </template>
                                </div>
                            </template>

                            <template
                                #singleLabel="{ option }"
                            >
                                <div class="flex items-center gap-4-px">
                                    <TruncatedText
                                        :lines-limit="2"
                                        :text="option[multiselectSettings(header)['label']]"
                                    />

                                    <StatusPill
                                        v-if="!!multiselectSettings(header)['selected-pill']"
                                        variant="blue-light"
                                        :text="multiselectSettings(header)['selected-pill']"
                                    />
                                </div>
                            </template>
                        </multiselect>
                    </template>
                    <template v-else-if="CellType.Actions === cellValueType(header)">
                        <template v-for="(action, actionIndex) in headerActions(header)" :key="actionIndex">
                            <div class="io-action-item">
                                <div v-if="'text' === action.variant">
                                    <span v-if="action.icon" class="io-action-item__icon" :class="action.icon"></span>
                                    <span class="io-action-item__text">{{ action.text }}</span>
                                </div>
                                <IOButton
                                    v-else
                                    size="small"
                                    :variant="action.variant"
                                    :icon="action.icon"
                                    @click.stop="action.onClick(item, header)"
                                >
                                    {{ action.text }}
                                </IOButton>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="CellType.CostCodesDropdown === cellValueType(header)">
                        <BudgetCostCodeSelect
                            v-if="itemInEditId === item[itemIdentifier] || !haveALotOfItems"
                            :model-value="cellModelValue(header)"
                            v-bind="costCodeSelectSettings(header)"
                            @update:modelValue="data => updateValue(header, data)"
                            @click.stop
                        />
                        <div
                            v-else-if="item[itemIdentifier] !== itemInEditId && haveALotOfItems"
                            class="io-cost-code-value"
                        >
                            {{ cellCostCodeValue(header) }}
                            <IOButton
                                size="small"
                                icon="icon-edit"
                                :variant="ButtonVariant.LINK"
                                @click.stop="handleClickEditItem(item)"
                            />
                        </div>
                    </template>
                    <span v-else>
                        {{ cellValue(header) }}
                        <div v-if="cellSubValue(header)" class="io-cell-sub-value">
                            {{ cellSubValue(header) }}
                        </div>
                    </span>
                    <template v-if="hasPostfixAction(header)">
                        <template v-if="(postfixAction(header) instanceof Array)">
                            <template v-for="(action, actionIndex) in postfixAction(header)" :key="actionIndex">
                                <template v-if="action.type === 'button'">
                                    <IOButton
                                        size="small"
                                        class="io-postfix-action"
                                        :variant="action.variant"
                                        :icon="action.icon"
                                        :disabled="action.isDisabled"
                                        v-tooltip.top="{ content: action.tooltip, visible: action.isDisabled}"
                                        @click.stop="action.onClick(item, header)"
                                    >
                                        {{ action.text }}
                                    </IOButton>
                                </template>
                                <template v-if="action.type === 'dropdown'">
                                    <DropdownButtonMultilayer v-bind="action" @click.stop/>
                                </template>
                            </template>
                        </template>
                        <template v-else>
                            <IOButton
                                size="small"
                                class="io-postfix-action"
                                :variant="postfixAction(header).variant"
                                :icon="postfixAction(header).icon"
                                @click.stop="postfixAction(header).onClick(item, header)"
                            >
                                {{ postfixAction(header).text }}
                            </IOButton>
                        </template>
                    </template>
                    <template v-if="showRightIcon(header)">
                        <span
                            v-for="(icon, iconIndex) in header.rightIcons(item)"
                            :key="iconIndex"
                            :class="`io-cell-icon-right ${icon}`"
                        ></span>
                    </template>
                    <span
                        v-if="showLink(header)"
                        class="io-cell-icon-right icon-external-link"
                        @click="openLink(header.link(item))"
                    ></span>
                    <Tooltip v-if="itemInfo(header, item)" :text="itemInfo(header, item)"/>
                </div>
                <div
                    v-if="cellErrorMessage(header)"
                    class="io-cell-error-message"
                >
                    {{ cellErrorMessage(header) }}
                </div>
            </td>
        </template>
    </tr>
</template>

<script lang="ts" src="./TableRow.ts"></script>
<style lang="scss" src="./TableRow.scss" scoped></style>
