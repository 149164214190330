<template>
    <ul>
        <template v-for="(processedItem, index) of items" :key="getItemKey(processedItem)">
            <li
                v-if="isItemVisible(processedItem) && !getItemProp(processedItem, 'separator')"
                data-testid="tiered-menu-sub-item"
                :id="getItemId(processedItem)"
                :style="getItemProp(processedItem, 'style')"
                :class="getItemClass(processedItem)"
                role="menuitem"
                :aria-label="getItemLabel(processedItem)"
                :aria-disabled="isItemDisabled(processedItem) || undefined"
                :aria-expanded="isItemGroup(processedItem) ? isItemActive(processedItem) : undefined"
                :aria-haspopup="isItemGroup(processedItem) && !getItemProp(processedItem, 'to') ? 'menu' : undefined"
                :aria-level="level + 1"
                :aria-setsize="getAriaSetSize()"
                :aria-posinset="getAriaPosInset(index)"
            >
                <div
                    class="io-menuitem-content"
                    @click="onItemClick($event, processedItem)"
                    @mouseenter="onItemMouseEnter($event, processedItem)"
                >
                    <template v-if="!templates.item">
                        <router-link
                            v-if="getItemProp(processedItem, 'to') && !isItemDisabled(processedItem)"
                            v-slot="{ navigate, href, isActive, isExactActive }"
                            :to="getItemProp(processedItem, 'to')"
                            custom
                        >
                            <a
                                :href="href"
                                :class="getItemActionClass(processedItem, { isActive, isExactActive })"
                                tabindex="-1"
                                aria-hidden="true"
                                @click="onItemActionClick($event, navigate)"
                            >
                                <component
                                    v-if="templates.itemicon"
                                    :is="templates.itemicon"
                                    :item="processedItem.item"
                                    :class="getItemIconClass(processedItem)"
                                />
                                <span
                                    v-else-if="getItemProp(processedItem, 'icon')"
                                    :class="getItemIconClass(processedItem)"
                                />
                                <span class="io-menuitem-text">{{ getItemLabel(processedItem) }}</span>
                            </a>
                        </router-link>
                        <a
                            v-else
                            :href="getItemProp(processedItem, 'url')"
                            :class="getItemActionClass(processedItem)"
                            :target="getItemProp(processedItem, 'target')"
                            tabindex="-1"
                            aria-hidden="true"
                        >
                            <component
                                v-if="templates.itemicon"
                                :is="templates.itemicon"
                                :item="processedItem.item"
                                :class="getItemIconClass(processedItem)"
                            />
                            <span
                                v-else-if="getItemProp(processedItem, 'icon')"
                                :class="getItemIconClass(processedItem)"
                            />
                            <span
                                class="io-menuitem-text"
                            >
                                {{ getItemLabel(processedItem) }}
                            </span>
                            <template v-if="getItemProp(processedItem, 'items')">
                                <component
                                    v-if="templates.submenuicon"
                                    :is="templates.submenuicon"
                                    class="io-submenu-icon"
                                    :active="isItemActive(processedItem)"
                                />
                                <i
                                    v-else
                                    class="io-submenu-icon icon-chevron-right"
                                />
                            </template>
                        </a>
                    </template>
                    <component v-else :is="templates.item" :item="processedItem.item"/>
                </div>
                <TieredMenuSub
                    v-if="isItemVisible(processedItem) && isItemGroup(processedItem)"
                    :id="getItemId(processedItem) + '_list'"
                    role="menu"
                    class="io-submenu-list"
                    :menuId="menuId"
                    :focusedItemId="focusedItemId"
                    :items="processedItem.items"
                    :templates="templates"
                    :activeItemPath="activeItemPath"
                    :exact="exact"
                    :level="level + 1"
                    @item-click="$emit('item-click', $event)"
                    @item-mouseenter="$emit('item-mouseenter', $event)"
                />
            </li>
            <li
                v-if="isItemVisible(processedItem) && getItemProp(processedItem, 'separator')"
                :id="getItemId(processedItem)"
                :style="getItemProp(processedItem, 'style')"
                :class="getSeparatorItemClass(processedItem)"
                role="separator"
            />
        </template>
    </ul>
</template>

<script lang="ts" src="./TieredMenuSub.ts"/>
