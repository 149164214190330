import { defineComponent } from 'vue'
import { ApiClient as client } from '@/api/api'
import useLoader from '@/composables/useLoader.ts'
import Timeline from '@/components/timeline/timeline.vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import type TimelineLogs from '@/interfaces/components/timeline/TimelineLogs.ts'

export default defineComponent({
    name: 'TimelineModal',
    components: {
        IOModal,
        Timeline,
    },

    props: {
        url: {
            type: String,
            required: true,
        },
    },

    emits: ['close'],

    setup () {
        const { loading, load } = useLoader()

        return { loading, load }
    },

    data () {
        return {
            timelineLogs: null as TimelineLogs[],
        }
    },

    created () {
        this.getTimeline()
    },

    methods: {
        getTimeline (): Promise<void> {
            return this.load(async () => {
                const { data } = await client.get(this.url)
                this.timelineLogs = data as TimelineLogs[]
            })
        },
    },
})
