<template>
    <div
        class="io-design-system io-textarea"
        :class="[
            $attrs.class,
            {
                'io-textarea--disabled': disabled,
                'io-textarea--focused': focused,
                'io-textarea--invalid': !valid,
                'io-textarea--auto-height': autoHeight,
                'io-textarea--limited': $attrs.maxlength,
                'io-textarea--counter-outside': counterOutside,
            },
        ]"
    >
        <div class="io-textarea__field">
            <div
                v-if="$attrs.maxlength"
                class="io-textarea__bar"
                :style="{ transform: `scaleX(${ currentLength / $attrs.maxlength })` }"
                role="progressbar"
            />

            <div v-if="$attrs.maxlength" class="io-textarea__counter">
                {{ currentLength }}/{{ $attrs.maxlength }} {{ $t('Characters') }}
            </div>

            <textarea
                ref="textarea"
                v-bind="omit($attrs, ['class'])"
                class="io-textarea__input"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                @focus="focused = true"
                @blur="focused = false"
            />
        </div>

        <Transition name="fade-from-top">
            <p
                v-if="errorMessage && !valid"
                class="io-error-message"
                role="alert"
            >
                {{ errorMessage }}
            </p>
        </Transition>
    </div>
</template>

<script lang="ts" src="./IOTextarea.ts"></script>
<style lang="scss" src="./IOTextarea.scss" scoped></style>
