import { ContractStage } from '@/interfaces/modules/contract/ContractStage'
import {
    TableViewMode
} from '@/modules/projects/modules/apps/common/pay-apps/views/line-items/schedule-of-values/enums/TableViewMode'

export default {
    activeTab: null,
    gmpContractTab: null,
    needReload: false,
    modalContract: false,
    modalContractStage: ContractStage.ORIGINAL,
    inProject: null,
    retentionRulesOptions: [],
    costCodes: [],
    costCodesProject: [],
    costTypes: [],
    changeOrders: [],
    amendments: {},
    invoices: [],
    vueRefreshTasks: 0,
    creatingContractFromTemplatesMode: false,
    addingDocumentTemplatesToContractMode: false,
    creatingContractData: {},
    selectedContractTemplatesIds: [],
    disabledContractTemplatesIds: [],

    previousRetentionRules: [],

    contract: null,
    mainContract: {},
    mainContractResource: null,
    units: [],
    divisions: [],

    clientsInternal: [],
    allowedClientsTypes: [],

    modalSelectSovLine: false,
    modalSelectSovLineData: null,
    quotedItemsList: [],
    companies_map: {},

    invitationData: {
        type: 'onsystem',
        internal: {
            company_id: null,
            contact_id: null
        },
        invited_pending: []
    },

    validationErrors: {
        quotedItems: {
            costCodes: [],
            names: []
        },
        sov: {
            descriptions: []
        }
    },

    wizardModal: {
        type: 'upload',
        to: null,
        value: null,
        id: null,
        date_initation: null,
        date_efective: null,
        statusSov: null
    },
    showPopupClientApproval: false,
    showPopupUserRejection: false,
    showPopupUserApproval: false,
    showPopupManualApproval: false,
    showPopupManualRejection: false,

    replacingInsurance: null,
    replacedInsurance: null,
    showInsuranceReplaceModal: false,
    showContractTypeSelectorModal: false,
    isSynced: false,

    tableViewMode: TableViewMode.COsInSeparateCategory,

    // wbs toggles
    isDisplayUnitsEnabledInitial: false,
    isDisplayRetentionEnabledInitial: true,
    isDisplayUnitsEnabled: false,
    isDisplayRetentionEnabled: true,
    isRequireSoVApprovalEnabled: true,
    isRequireUpdateSoVApprovalEnabled: true,

    // new wbs structure
    wbsItems: [],
}
