import { defineComponent, PropType } from 'vue'
import Table from '@/components/table/Table.vue'
import Accordion from '@/components/accordion/Accordion.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import Placeholder from '@/components/placeholder/Placeholder.vue'
import { PlaceholderButton } from '@/io-modules/bid-requests/interfaces'
import ProjectFundingProviderFunds from '../../interfaces/ProjectFundingProviderFunds'
import { ProjectFundingAllocatedRows } from '@/io-modules/project-funding/interfaces/ProjectFundingAllocatedRows'
import AddNewCapitalFundModal from '@/io-modules/global-funding/components/modals/add-new-capital-fund/AddNewCapitalFundModal.vue'
import { globalFundingStore } from '@/io-modules/global-funding/stores/globalFundingStore'
import { mapActions, mapState } from 'pinia'

export default defineComponent({
    name: 'CapitalProviderFundsList',
    components: { AddNewCapitalFundModal, Placeholder, Accordion, Table },
    props: {
        loading: {
            type: Boolean,
        },
        providerFundsList: {
            required: true,
            type: Object as PropType<ProjectFundingProviderFunds[]>,
            default: () => []
        },
        isSearch: {
            default: false,
        },
    },
    emits: ['selectedFunds', 'numberSelected'],
    data () {
        return {
            providerFundsListEdited: [] as ProjectFundingProviderFunds[],
            selectedFunds: null,
        }
    },
    computed: {
        ...mapState(globalFundingStore, [
            'addNewCapitalFundModal',
            'fundCreatedFromProject'
        ]),
        tableHeaders (): HeaderTable[] {
            const headers: HeaderTable[] = [
                {
                    text: this.$t('Fund Name'),
                    value: 'name',
                },
                {
                    text: this.$t('Start Date'),
                    value: 'start_date',
                    sortDates: true
                },
                {
                    text: this.$t('Expiration Date'),
                    value: 'expiration_date',
                    sortDates: true
                },
                {
                    text: this.$t('Total Fund Amount'),
                    value: 'amount',
                    sort: (item) => parseFloat(item),
                    align: 'end',
                },
                {
                    text: this.$t('Remaining Unallocated Funds'),
                    value: 'unallocated_amount',
                    sort: (item) => parseFloat(item),
                    align: 'end',
                },
            ]
            return headers
        },
        buttonsCta (): Array<PlaceholderButton> {
            return [
                {
                    name: this.$tc('Create New Program Fund'),
                    customAction: 'toggleAddNewCapitalFundModal',
                },
            ]
        },
    },
    watch: {
        providerFundsList: {
            handler (): void {
                this.addCheckboxValue()
            },
            deep: true,
        },
        providerFundsListEdited: {
            handler (): void {
                this.collectSelectedFunds()
            },
            deep: true,
        },
    },
    mounted () {
        this.addCheckboxValue()
    },
    unmounted () {
        this.setFundCreatedFromProject(false)
    },
    methods: {
        ...mapActions(globalFundingStore, [
            'setAddNewCapitalFundModal',
            'setFundCreatedFromProject'
        ]),
        toggleAddNewCapitalFundModal (): void {
            this.selectedFunds = null
            this.setFundCreatedFromProject(false)
            this.setAddNewCapitalFundModal({
                show: !this.addNewCapitalFundModal.show,
                editMode: false,
            })
        },
        addCheckboxValue (): void {
            if (this.providerFundsList && this.providerFundsList.length) {
                this.providerFundsListEdited = this.providerFundsList.map((provider: ProjectFundingProviderFunds, index: number) => {
                    const updatedProvider: ProjectFundingProviderFunds = {
                        ...provider,
                        provider: { ...provider.provider, isCheckAll: false },
                    }

                    updatedProvider.provider.funds = provider.provider.funds.map((fund, fundIndex) => {
                        if (this.fundCreatedFromProject && index === 0 && fundIndex === 0) {
                            return {
                                ...fund,
                                isSelected: true,
                            }
                        } else {
                            return {
                                ...fund,
                                isSelected: false,
                            }
                        }
                    })

                    return updatedProvider
                })
            }
        },
        toggleCheckAllFunds (id: string): void {
            const selFund = this.providerFundsListEdited.find(provider => provider.id === id)
            if (selFund.provider.isCheckAll === true) {
                selFund.provider.funds.forEach(fund => {
                    fund.isSelected = false

                    selFund.provider.isCheckAll = false
                })
            } else {
                selFund.provider.funds.forEach(fund => {
                    fund.isSelected = true

                    selFund.provider.isCheckAll = true
                })
            }
        },
        collectSelectedFunds (): void {
            const selected: ProjectFundingAllocatedRows[] = []

            this.providerFundsListEdited.forEach((provider: ProjectFundingProviderFunds) => {
                provider.provider.funds.forEach((fund) => {
                    if (fund.isSelected) {
                        selected.push({
                            provider_name: provider.provider.name,
                            provider_id: provider.provider.id,
                            fund_id: fund.id,
                            fund_name: fund.name,
                            unallocated_amount: fund.summary.total_projects_unallocated_amount,
                            paid_to_date: fund.summary.total_paid_to_date_amount
                        })
                    }
                })
            })

            this.selectedFunds = selected

            this.$emit('numberSelected', this.selectedFunds?.length || 0)
            this.$emit('selectedFunds', this.selectedFunds)
        },
        headerCheckboxClass (id: string): string {
            const selFund = this.providerFundsListEdited.find(provider => provider.id === id)
            if (selFund) {
                if (selFund.provider.funds.some(fund => fund.isSelected)) {
                    selFund.provider.isCheckAll = true
                    return 'io-minus'
                }
            }
            selFund.provider.isCheckAll = false
            return ''
        },
        openIfCreatedNewCapFund (id: string): boolean {
            if (this.fundCreatedFromProject) {
                const selFund = this.providerFundsListEdited.find(provider => provider.id === id)
                return selFund.provider.funds.some(fund => fund.isSelected)
            }
            return false
        },
        goToFundPage (fundId: string): void {
            const route = this.$router.resolve({
                name: 'funding-sources-fund-details',
                params: {
                    id: fundId,
                },
            })
            window.open(route.href, '_blank', 'noopener')
        },
    },
})
