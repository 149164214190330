import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import TableOfContentsItem from './table-of-contents-item/TableOfContentsItem.vue'
import sheetsClient from '@/io-modules/drawings/api-clients/sheetsClient.ts'

export default defineComponent({
    name: 'TableOfContents',
    components: {
        TableOfContentsItem,
    },
    data () {
        return {
            data: [],
            searchValue: '' as string,
        }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
        }),
        filteredData (): unknown[] {
            return this.data.filter((sheet: unknown) => {
                return !sheet.is_archived && (sheet.page_label.toLowerCase().includes(this.searchValue) ||
                    sheet.page_title.toLowerCase().includes(this.searchValue))
            })
        },
    },
    async mounted (): Promise<void> {
        await this.fetchData()
    },
    methods: {
        close (): void {
            this.$emit('close')
        },
        onChangeSearchValue (val: string): void {
            this.searchValue = val
        },
        async fetchData (): Promise<void> {
            this.setLoadingBar(true)

            try {
                const { data } = await sheetsClient.getDrawingSheets(this.model.drawing.drawing_set_id)
                this.data = data.items
            } catch (e) {
                this.showNotification('error', e)
            } finally {
                this.setLoadingBar(false)
            }
        },
    },
})
