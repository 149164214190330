import * as actions from './actions'
import * as mutations from './mutations'

export default {
    namespaced: true,
    namespace: 'popupAlert/',
    state () {
        return {
            popup: null
        }
    },
    actions,
    mutations
}