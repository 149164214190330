<template>
    <td>
        <div class="io-avatar-cell">
            <CompanyLogo
                v-if="value.avatarType === AvatarType.COMPANY"
                :logo="value.id"
                :size="LogoSize.MEDIUM"
            />

            <Avatar
                v-else
                :employee-mongo-id="value.id"
                :employee-full-name="value.name"
                :show-full-name="true"
                :type="value.avatarType"
            />

            <template v-if="value.avatarType === AvatarType.COMPANY">
                {{ value.name }}
            </template>

            <StatusPill
                v-if="isShowStatusPill"
                :variant="column.getPillVariant(item)"
                :text="column.getPillText(item)"
            />
        </div>
    </td>
</template>

<script lang="ts" src="./AvatarCell.ts"></script>
<style lang="scss" src="./AvatarCell.scss" scoped></style>
