import submittalsClient from '../../../api/project-settings-submittals/submittalsClient'

export const fetchSubmittalTypes = async function ({ commit }, projectID) {
    const { data } = await submittalsClient.getSubmittalTypes(projectID)
    commit('SET_TYPES', data)
}

export const saveSubmittalTypes = async function ({ commit }, { projectID, submittalTypes }) {
    const { data } = await submittalsClient.saveSubmittalTypes(projectID, submittalTypes)
    commit('SET_TYPES', data)
}

export const fetchScheduleSettings = async function ({ commit }, projectID) {
    const { data } = await submittalsClient.getScheduleSettings(projectID)
    commit('SET_SCHEDULE_SETTINGS', data)
}

export const saveScheduleSettings = async function ({ commit }, { projectID, scheduleSettings }) {
    await submittalsClient.saveScheduleSettings(projectID, scheduleSettings)
}
