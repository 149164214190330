<template>
    <RightModal customClass="io-right-modal--journal" @escDown="setEscPressed(true)" @escUp="setEscPressed(false)">
        <div class="io-journal-modal">

            <div class="io-journal-modal__column-left">
                <div class="io-date-box">
                    <field-date-time
                            v-model="selectedDate"
                            :editable="true"
                            :options="dateTimeOptions"
                    />
                </div>

                <div class="io-journal-list">
                    <template v-if="rendered && filteredItems.length > 0">
                        <template v-for="item in filteredItems" :key="item.id">
                            <div class="io-single-item"
                                 :class="{'io-active': activeId === item.id}" @click="setActiveId(item.id)">
                                <div class="io-single-item__top-row">
                                    <div class="io-single-item__time">
                                        {{ $filters.parseDate(item.date) }}
                                    </div>
                                    <div class="io-single-item__icon" :style="{color: item._health_tag[0].color}">
                                        <i class="fas" :class="getHealthIcon(item._health_tag[0].value)"></i>
                                    </div>
                                </div>
                                <div class="io-single-item__title">{{ item.title }}</div>
                                <div class="io-single-item__content">{{ item.text }}</div>
                                <div class="io-single-item__mask"></div>
                            </div>
                        </template>
                    </template>
                    <template v-else-if="rendered">
                        <div class="io-journal-placeholder">
                            <div class="io-journal-placeholder__icon"><i class="far fa-search"></i></div>
                            <div class="io-journal-placeholder__title">No items found</div>
                            <div class="io-journal-placeholder__text">Try entering different details</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="io-journal-list__loader-box"><div class="io-loader-small"></div></div>
                    </template>
                </div>
            </div>

            <div class="io-journal-modal__column-center">

                <div class="io-search-box" v-click-outside="hideShortcuts">
                    <div class="io-search-box__search-icon"><i class="fas fa-search"></i></div>
                    <input type="text" v-model="search" placeholder="Search..." class="io-search-box__input">
                    <div v-if="false" class="io-search-box__controls">
                        <div class="io-search-box__result"><span class="io-amount">1</span> of <span class="io-all">4</span></div>
                        <div class="io-search-box__arrow io-left"><i class="fas fa-chevron-up"></i></div>
                        <div class="io-search-box__arrow io-right"><i class="fas fa-chevron-down"></i></div>
                        <div class="io-search-box__cancel"><i class="fal fa-times"></i></div>
                    </div>
                    <div class="io-search-box__shortcuts-icon" :class="{'io-active': showShortcuts}" @click="toggleShortcuts">
                        <i class="fas fa-keyboard"></i>
                    </div>
                    <transition name="shortcuts-appear">
                        <div v-if="showShortcuts" class="io-shortcuts">
                            <div class="io-shortcuts__header">Suggested Shortcuts</div>
                            <div class="io-shortcuts__row">
                                <div class="io-shortcuts__name">Show Previous Note</div>
                                <div class="io-shortcuts__key io-icon"><i class="fas fa-caret-up"></i></div>
                            </div>
                            <div class="io-shortcuts__row">
                                <div class="io-shortcuts__name">Show Next Note</div>
                                <div class="io-shortcuts__key io-icon"><i class="fas fa-caret-down"></i></div>
                            </div>
                            <div class="io-shortcuts__row">
                                <div class="io-shortcuts__name">Go to Previous Day</div>
                                <div class="io-shortcuts__key">shift</div>
                                <div class="io-shortcuts__key io-icon"><i class="fas fa-caret-left"></i></div>
                            </div>
                            <div class="io-shortcuts__row">
                                <div class="io-shortcuts__name">Go to Next Day</div>
                                <div class="io-shortcuts__key">shift</div>
                                <div class="io-shortcuts__key io-icon"><i class="fas fa-caret-right"></i></div>
                            </div>
                        </div>
                    </transition>
                </div>

                <div class="io-journal-modal__journal-wrapper">

                    <div v-if="rendered && activeItem" class="io-current-journal">
                        <div class="io-current-journal__top-row">
                            <div class="io-current-journal__pill" :style="{backgroundColor: activeItem._health_tag[0].color}">
                                <i class="fas" :class="getHealthIcon(activeItem._health_tag[0].value)"></i>
                                {{ $t(activeItem._health_tag[0].name) }}
                            </div>
                            <div class="io-current-journal__redirect-icon" @click="redirectToItem"><i class="far fa-external-link"></i></div>
                        </div>
                        <div class="io-current-journal__date">
                            {{ $filters.parseDate(activeItem.date) }}
                        </div>
                        <div class="io-current-journal__title">{{ activeItem.title }}</div>
                        <div class="io-current-journal__text">{{ activeItem.text }}</div>
<!--                        <div class="io-current-journal__tags">-->
<!--                            <div class="io-current-journal__tag">C&W</div>-->
<!--                            <div class="io-current-journal__tag">Project Journal</div>-->
<!--                            <div class="io-current-journal__tag">Review</div>-->
<!--                        </div>-->
                    </div>

                    <div v-else class="io-journal-placeholder">
                        <div class="io-journal-placeholder__icon"><i class="far fa-calendar-times"></i></div>
                        <div class="io-journal-placeholder__title">No item selected</div>
                        <div class="io-journal-placeholder__text">Choose item from the list</div>
                        <div class="io-btn-primary-compact" @click="redirectToNew">Add new entry</div>
                    </div>

                </div>

            </div>

            <div class="io-journal-modal__column-right">
                <div class="io-right-modal__close" :class="{'io-active': escPressed}" @click="closeModal"><i class="fal fa-times"></i></div>
            </div>

        </div>

    </RightModal>
</template>

<script src="./ModalJournal.js"></script>
<style lang="scss" src="./ModalJournal.scss" scoped></style>

<style lang="scss">
    .io-right-modal--journal {
        .io-right-modal__content {
            width: 1080px;
        }
    }
    .io-journal-modal {
        .io-date-box {
            .io-input-date-holder {
                width: 100% !important;
            }
            .field-input {
                margin: 0 !important;
                height: 100% !important;
                width: 100% !important;
                border: none !important;
                padding: 0 !important;
                font-size: 14px !important;
                font-weight: 600 !important;
                color: var(--text-subheaders) !important;
            }
        }
    }
</style>
