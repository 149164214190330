import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import DropdownButton from '@/components/atoms/DropdownButton/DropdownButton.vue'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import { isFunction } from 'lodash'

export default defineComponent({
    components: {
        DropdownButton,
        DropdownButtonNew
    },
    props: {
        data: { type: Object, required: true, default: null },
        column: { type: Object as () => ColumnInterface, required: true, default: null },
        actions: { type: Array, required: true, default: null },
        newInterface: { type: Boolean, default: false },
    },
    computed: {
        filteredActions () {
            return this.actions.filter((item: any) => {
                if (isFunction(item.showAction)) {
                    return item.showAction(this.data)
                }
                return true
            })
        }
    },
    methods: {
        onClickDropdown (e: Event) {
            e.preventDefault()
        },
        emitAction (action: string): void {
            this.$emit('actionSelect', { name: action, id: this.data.id, data: this.data })
        }
    }
})
