import { ConnectedOverlayScrollHandler, DomHandler, ObjectUtils, UniqueComponentId, ZIndexUtils } from './parts/utils'
import TieredMenuSub from '@/components/atoms/DropdownButtonMultilayer/parts/TieredMenu/parts/TieredMenuSub.vue'
import { isEmpty } from 'lodash'

export interface Item {
    item: Item,
    items: Item[]
    index: number,
    level: number,
    key: string,
    parent: string,
    parentKey: string,
}

export interface focusedItemInfo {
    index: number,
    level?: number,
    parentKey: string
}

export default {
    name: 'TieredMenu',
    inheritAttrs: false,
    emits: ['focus', 'blur', 'before-show', 'before-hide', 'hide', 'show'],
    props: {
        popup: {
            type: Boolean,
            default: false,
        },
        model: {
            type: Array,
            default: () => [],
        },
        appendTo: {
            type: String,
            default: 'body',
        },
        autoZIndex: {
            type: Boolean,
            default: true,
        },
        baseZIndex: {
            type: Number,
            default: 0,
        },
        exact: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tabindex: {
            type: Number,
            default: 0,
        },
        ariaLabelledby: {
            type: String,
            default: null,
        },
        ariaLabel: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            id: this.$attrs.id,
            focused: false,
            focusedItemInfo: { index: -1, level: 0, parentKey: '' } as focusedItemInfo,
            activeItemPath: [],
            visible: !this.popup,
            dirty: false,
            outsideClickListener: null as (event) => void,
            scrollHandler: null as ConnectedOverlayScrollHandler,
            resizeListener: null as Object,
            target: null as HTMLElement,
            container: null as HTMLElement,
            menubar: null,
            searchTimeout: null as number,
            searchValue: null as string,
            relatedTarget: null as HTMLElement,
        }
    },
    watch: {
        '$attrs.id' (newValue): void {
            this.id = newValue || UniqueComponentId()
        },
        activeItemPath (newPath): void {
            if (!this.popup) {
                if (!isEmpty(newPath)) {
                    this.bindOutsideClickListener()
                    this.bindResizeListener()
                } else {
                    this.unbindOutsideClickListener()
                    this.unbindResizeListener()
                }
            }
        },
    },
    mounted (): void {
        this.id = this.id || UniqueComponentId()
    },
    beforeUnmount (): void {
        this.unbindOutsideClickListener()
        this.unbindResizeListener()

        if (this.scrollHandler) {
            this.scrollHandler.destroy()
            this.scrollHandler = null
        }

        if (this.container && this.autoZIndex) {
            ZIndexUtils.clear(this.container)
        }

        this.target = null
        this.container = null
    },
    methods: {
        getItemProp (item: Item, name: string): string {
            return item ? ObjectUtils.getItemValue(item[name]) : undefined
        },
        getItemLabel (item: Item): string {
            return this.getItemProp(item, 'label')
        },
        isItemDisabled (item: Item): string {
            return this.getItemProp(item, 'disabled')
        },
        isItemGroup (item: Item): boolean {
            return !isEmpty(this.getItemProp(item, 'items'))
        },
        isItemSeparator (item: Item): string {
            return this.getItemProp(item, 'separator')
        },
        isProcessedItemGroup (processedItem: Item): boolean {
            return processedItem && !isEmpty(processedItem.items)
        },
        toggle (event: Event): void {
            this.visible ? this.hide(event, true) : this.show(event)
        },
        show (event: Event, isFocus?: boolean): void {
            if (this.popup) {
                this.$emit('before-show')
                this.visible = true
                this.target = (this.target || event.currentTarget)
                this.relatedTarget = event.relatedTarget || null
            }

            this.focusedItemInfo = { index: this.findFirstFocusedItemIndex(), level: 0, parentKey: '' }

            isFocus && DomHandler.focus(this.menubar)
        },
        hide (event?: Event, isFocus?: boolean): void {
            if (this.popup) {
                this.$emit('before-hide')
                this.visible = false
            }

            this.activeItemPath = []
            this.focusedItemInfo = { index: -1, level: 0, parentKey: '' }

            isFocus && DomHandler.focus(this.relatedTarget || this.target || this.menubar)
            this.dirty = false
        },
        onFocus (event: Event): void {
            this.focused = true
            this.focusedItemInfo = this.focusedItemInfo.index !== -1 ? this.focusedItemInfo : {
                index: this.findFirstFocusedItemIndex(),
                level: 0,
                parentKey: '',
            }

            this.$emit('focus', event)
        },
        onBlur (event: Event): void {
            this.focused = false
            this.focusedItemInfo = { index: -1, level: 0, parentKey: '' }
            this.searchValue = ''
            this.dirty = false
            this.$emit('blur', event)
        },
        onKeyDown (event: KeyboardEvent): void {
            if (this.disabled) {
                event.preventDefault()

                return
            }

            switch (event.code) {
            case 'ArrowDown':
                this.onArrowDownKey(event)
                break

            case 'ArrowUp':
                this.onArrowUpKey(event)
                break

            case 'ArrowLeft':
                this.onArrowLeftKey(event)
                break

            case 'ArrowRight':
                this.onArrowRightKey(event)
                break

            case 'Home':
                this.onHomeKey(event)
                break

            case 'End':
                this.onEndKey(event)
                break

            case 'Space':
                this.onSpaceKey(event)
                break

            case 'Enter':
                this.onEnterKey(event)
                break

            case 'Escape':
                this.onEscapeKey(event)
                break

            case 'Tab':
                this.onTabKey(event)
                break
            }
        },
        onItemChange (event: any): void {
            const { processedItem, isFocus } = event

            if (isEmpty(processedItem)) {
                return
            }

            const { index, key, level, parentKey, items } = processedItem
            const grouped = !isEmpty(items)

            const activeItemPath = this.activeItemPath.filter((p) => p.parentKey !== parentKey && p.parentKey !== key)

            grouped && activeItemPath.push(processedItem)

            this.focusedItemInfo = { index, level, parentKey }
            this.activeItemPath = activeItemPath

            grouped && (this.dirty = true)
            isFocus && DomHandler.focus(this.menubar)
        },
        onItemClick (event: Event): void {
            const { originalEvent, processedItem } = event
            const grouped = this.isProcessedItemGroup(processedItem)
            const root = isEmpty(processedItem.parent)
            const selected = this.isSelected(processedItem)

            if (selected) {
                const { index, key, level, parentKey } = processedItem

                this.activeItemPath = this.activeItemPath.filter((p) => key !== p.key && key.startsWith(p.key))
                this.focusedItemInfo = { index, level, parentKey }

                this.dirty = !root
                DomHandler.focus(this.menubar)
            } else {
                if (grouped) {
                    this.onItemChange(event)
                } else {
                    const rootProcessedItem = root ? processedItem : this.activeItemPath.find((p) => p.parentKey === '')

                    this.hide(originalEvent)
                    this.changeFocusedItemIndex(originalEvent, rootProcessedItem ? rootProcessedItem.index : -1)

                    DomHandler.focus(this.menubar)
                }
            }
        },
        onItemMouseEnter (event: Event): void {
            if (this.dirty) {
                this.onItemChange(event)
            }
        },
        onArrowDownKey (event: Event): void {
            const itemIndex = this.focusedItemInfo.index !== -1 ? this.findNextItemIndex(this.focusedItemInfo.index) : this.findFirstFocusedItemIndex()

            this.changeFocusedItemIndex(event, itemIndex)
            event.preventDefault()
        },
        onArrowUpKey (event: Event): void {
            if (event.altKey) {
                if (this.focusedItemInfo.index !== -1) {
                    const processedItem = this.visibleItems[this.focusedItemInfo.index]
                    const grouped = this.isProcessedItemGroup(processedItem)

                    !grouped && this.onItemChange({ originalEvent: event, processedItem })
                }

                this.popup && this.hide(event, true)
                event.preventDefault()
            } else {
                const itemIndex = this.focusedItemInfo.index !== -1 ? this.findPrevItemIndex(this.focusedItemInfo.index) : this.findLastFocusedItemIndex()

                this.changeFocusedItemIndex(event, itemIndex)
                event.preventDefault()
            }
        },
        onArrowLeftKey (event: Event): void {
            const processedItem = this.visibleItems[this.focusedItemInfo.index]
            const parentItem = this.activeItemPath.find((p) => p.key === processedItem.parentKey)
            const root = isEmpty(processedItem.parent)

            if (!root) {
                this.focusedItemInfo = { index: -1, parentKey: parentItem ? parentItem.parentKey : '' }
                this.searchValue = ''
                this.onArrowDownKey(event)
            }

            this.activeItemPath = this.activeItemPath.filter((p) => p.parentKey !== this.focusedItemInfo.parentKey)

            event.preventDefault()
        },
        onArrowRightKey (event: Event): void {
            const processedItem = this.visibleItems[this.focusedItemInfo.index]
            const grouped = this.isProcessedItemGroup(processedItem)

            if (grouped) {
                this.onItemChange({ originalEvent: event, processedItem })
                this.focusedItemInfo = { index: -1, parentKey: processedItem.key }
                this.searchValue = ''
                this.onArrowDownKey(event)
            }

            event.preventDefault()
        },
        onHomeKey (event: Event): void {
            this.changeFocusedItemIndex(event, this.findFirstItemIndex())
            event.preventDefault()
        },
        onEndKey (event: Event): void {
            this.changeFocusedItemIndex(event, this.findLastItemIndex())
            event.preventDefault()
        },
        onEnterKey (event: Event): void {
            if (this.focusedItemInfo.index !== -1) {
                const element = DomHandler.findSingle(this.menubar, `li[id="${ `${ this.focusedItemId }` }"]`)
                const anchorElement = element && DomHandler.findSingle(element, '.io-menuitem-link')

                anchorElement ? anchorElement.click() : element && element.click()

                if (!this.popup) {
                    const processedItem = this.visibleItems[this.focusedItemInfo.index]
                    const grouped = this.isProcessedItemGroup(processedItem)

                    !grouped && (this.focusedItemInfo.index = this.findFirstFocusedItemIndex())
                }
            }

            event.preventDefault()
        },
        onSpaceKey (event: Event): void {
            this.onEnterKey(event)
        },
        onEscapeKey (event: Event): void {
            this.hide(event, true)
            !this.popup && (this.focusedItemInfo.index = this.findFirstFocusedItemIndex())

            event.preventDefault()
        },
        onTabKey (event: Event): void {
            if (this.focusedItemInfo.index !== -1) {
                const processedItem = this.visibleItems[this.focusedItemInfo.index]
                const grouped = this.isProcessedItemGroup(processedItem)

                !grouped && this.onItemChange({ originalEvent: event, processedItem })
            }

            this.hide()
        },
        onEnter (el: HTMLElement): void {
            if (this.autoZIndex) {
                ZIndexUtils.set('menu', el, this.baseZIndex)
            }

            this.alignOverlay()
            DomHandler.focus(this.menubar)
            this.scrollInView()
        },
        onAfterEnter (): void {
            this.bindOutsideClickListener()
            this.bindScrollListener()
            this.bindResizeListener()

            this.$emit('show')
        },
        onLeave (): void {
            this.unbindOutsideClickListener()
            this.unbindScrollListener()
            this.unbindResizeListener()

            this.$emit('hide')
            this.container = null
            this.dirty = false
        },
        onAfterLeave (el: HTMLElement): void {
            if (this.autoZIndex) {
                ZIndexUtils.clear(el)
            }
        },
        alignOverlay (): void {
            this.container.style.minWidth = DomHandler.getOuterWidth(this.target) + 'px'
            DomHandler.absolutePosition(this.container, this.target)
        },
        bindOutsideClickListener (): void {
            if (!this.outsideClickListener) {
                this.outsideClickListener = (event) => {
                    const isOutsideContainer = this.container && !this.container.contains(event.target)
                    const isOutsideTarget = this.popup ? !(this.target && (this.target === event.target || this.target.contains(event.target))) : true

                    if (isOutsideContainer && isOutsideTarget) {
                        this.hide()
                    }
                }

                document.addEventListener('click', this.outsideClickListener)
            }
        },
        unbindOutsideClickListener (): void {
            if (this.outsideClickListener) {
                document.removeEventListener('click', this.outsideClickListener)
                this.outsideClickListener = null
            }
        },
        bindScrollListener (): void {
            if (!this.scrollHandler) {
                this.scrollHandler = new ConnectedOverlayScrollHandler(this.target, (event) => {
                    this.hide(event, true)
                })
            }

            this.scrollHandler.bindScrollListener()
        },
        unbindScrollListener (): void {
            if (this.scrollHandler) {
                this.scrollHandler.unbindScrollListener()
            }
        },
        bindResizeListener (): void {
            if (!this.resizeListener) {
                this.resizeListener = (event) => {
                    if (!DomHandler.isTouchDevice()) {
                        this.hide(event, true)
                    }
                }

                window.addEventListener('resize', this.resizeListener)
            }
        },
        unbindResizeListener (): void {
            if (this.resizeListener) {
                window.removeEventListener('resize', this.resizeListener)
                this.resizeListener = null
            }
        },
        isValidItem (processedItem: Item): boolean {
            return !!processedItem && !this.isItemDisabled(processedItem.item) && !this.isItemSeparator(processedItem.item)
        },
        isValidSelectedItem (processedItem: Item): boolean {
            return this.isValidItem(processedItem) && this.isSelected(processedItem)
        },
        isSelected (processedItem: Item): boolean {
            return this.activeItemPath.some((p) => p.key === processedItem.key)
        },
        findFirstItemIndex (): number {
            return this.visibleItems.findIndex((processedItem) => this.isValidItem(processedItem))
        },
        findLastItemIndex (): number {
            return ObjectUtils.findLastIndex(this.visibleItems, (processedItem) => this.isValidItem(processedItem))
        },
        findNextItemIndex (index: number): number {
            const matchedItemIndex = index < this.visibleItems.length - 1 ? this.visibleItems.slice(index + 1).findIndex((processedItem) => this.isValidItem(processedItem)) : -1

            return matchedItemIndex > -1 ? matchedItemIndex + index + 1 : index
        },
        findPrevItemIndex (index: number): number {
            const matchedItemIndex = index > 0 ? ObjectUtils.findLastIndex(this.visibleItems.slice(0, index), (processedItem) => this.isValidItem(processedItem)) : -1

            return matchedItemIndex > -1 ? matchedItemIndex : index
        },
        findSelectedItemIndex (): number {
            return this.visibleItems.findIndex((processedItem) => this.isValidSelectedItem(processedItem))
        },
        findFirstFocusedItemIndex (): number {
            const selectedIndex = this.findSelectedItemIndex()

            return selectedIndex < 0 ? this.findFirstItemIndex() : selectedIndex
        },
        findLastFocusedItemIndex (): number {
            const selectedIndex = this.findSelectedItemIndex()

            return selectedIndex < 0 ? this.findLastItemIndex() : selectedIndex
        },
        changeFocusedItemIndex (event: Event, index: number): void {
            if (this.focusedItemInfo.index !== index) {
                this.focusedItemInfo.index = index
                this.scrollInView()
            }
        },
        scrollInView (index: number = -1): void {
            const id = index !== -1 ? `${ this.id }_${ index }` : this.focusedItemId
            const element = DomHandler.findSingle(this.menubar, `li[id="${ id }"]`)

            if (element) {
                element.scrollIntoView && element.scrollIntoView({ block: 'nearest', inline: 'start' })
            }
        },
        createProcessedItems (items: Item[], level: number = 0, parent: Object = {}, parentKey: string = ''): Item[] {
            const processedItems = []

            items &&
            items.forEach((item, index) => {
                const key = (parentKey !== '' ? parentKey + '_' : '') + index
                const newItem = {
                    item,
                    index,
                    level,
                    key,
                    parent,
                    parentKey,
                }

                newItem['items'] = this.createProcessedItems(item.items, level + 1, newItem, key)
                processedItems.push(newItem)
            })
            return processedItems
        },
        containerRef (el: HTMLElement): void {
            this.container = el
        },
        menubarRef (el: HTMLElement): void {
            this.menubar = el ? el.$el : undefined
        },
    },
    computed: {
        mounted (): boolean {
            return !!(typeof window !== 'undefined' && window.document && window.document.createElement)
        },
        containerClass () {
            return [
                'io-tieredmenu p-component',
                {
                    'io-tieredmenu-overlay': this.popup,
                },
            ]
        },
        processedItems (): Item[] {
            return this.createProcessedItems(this.model || [])
        },
        visibleItems () {
            const processedItem = this.activeItemPath.find((p) => p.key === this.focusedItemInfo.parentKey)

            return processedItem ? processedItem.items : this.processedItems
        },
        focusedItemId (): string {
            return this.focusedItemInfo.index !== -1 ? `${ this.id }${ !isEmpty(this.focusedItemInfo.parentKey) ? '_' + this.focusedItemInfo.parentKey : '' }_${ this.focusedItemInfo.index }` : null
        },
    },
    components: {
        TieredMenuSub,
    },
}
