export const setIsAbleCollapseSoV = ({ commit }, value) => {
    commit('SET_IS_ABLE_TO_COLLAPSE_SOV', value)
}
export const setCollapseSoV = ({ commit }, value) => {
    commit('SET_COLLAPSE_SOV', value)
}

export const setExpandSoV = ({ commit }, value) => {
    commit('SET_EXPAND_SOV', value)
}

export const setRowsToExpand = ({ commit }, value) => {
    commit('SET_ROWS_TO_EXPAND', value)
}

export const setAllRowsToExpand = ({ commit }, value) => {
    commit('SET_ALL_ROWS_TO_EXPAND', value)
}
