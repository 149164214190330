import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
    name: 'CollapseExpandButton',
    computed: {
        ...mapState('financesCommon', ['isAbleToCollapseSoV']),
    },
    props: {
        forceExpand: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        forceExpand:  {
            handler (value: boolean): void {
                if (value && !this.isAbleToCollapseSoV) {
                    this.setExpandSoV(true)
                }
            },
            immediate: true,
        },
    },
    beforeUnmount () {
        this.setRowsToExpand([])
        this.setIsAbleCollapseSoV(false)
    },
    methods: {
        ...mapActions('financesCommon', [
            'setCollapseSoV',
            'setExpandSoV',
            'setRowsToExpand',
            'setIsAbleCollapseSoV',
        ]),
        
        toggleExpandSov (): void {
            if (this.isAbleToCollapseSoV) {
                this.setCollapseSoV(true)
            } else {
                this.setExpandSoV(true)
            }
        },
    }
})
