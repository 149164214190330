<template>
    <div data-testid="schedule-impact">
        <IOLabel>{{ $t('Schedule Impact') }}</IOLabel>

        <div class="flex gap-3">
            <Radio
                v-for="option in impactOptions"
                :key="option.type"
                :label-text="option.label"
                input-name="schedule-impact"
                :radio-value="option.type"
                v-model="value.occurred"
            />
        </div>

        <div
            v-if="value.occurred === ImpactType.YES"
            class="flex gap-3 mt-7"
        >
            <IOInput
                type="number"
                :disabled="value.type === ScheduleImpactType.TBD"
                no-number-spinners
                :error-message="errorMessage"
                :valid="!v$.modelValue.value.$errors.length"
                v-model="value.value"
            />

            <Radio
                v-for="scheduleOption in scheduleOptions"
                :key="scheduleOption.type"
                class="mt-12-px"
                :label-text="scheduleOption.label"
                :radio-value="scheduleOption.type"
                v-model="value.type"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./ScheduleImpact.ts"></script>
<style scoped lang="scss" src="./ScheduleImpact.scss"></style>
