import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'
import { RightPanelTab } from '@/io-modules/drawings/enums/RightPanelTab'

export default defineComponent({
    name: 'HistoryTab',
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
        }),
    },
    methods: {
        isCurrentVersion (versionNumber: number): boolean {
            return this.model.drawing.current_version_number === versionNumber
        },
        isSelected (versionId: string): boolean {
            return this.$route.params.versionId === versionId
        },
        statusPillVariant (versionNumber: number): PillVariant {
            return this.isCurrentVersion(versionNumber) ? PillVariant.BLUE_LIGHT : PillVariant.GRAY_LIGHT
        },
        statusPillText (versionNumber: number): string {
            return this.isCurrentVersion(versionNumber) ? this.$t('Current Version') : this.$t('Old Version')
        },
        changeVersion (versionId: string): void {
            if (!this.isSelected(versionId)) {
                this.$router.replace({
                    name: 'drawings-sheets-show',
                    params: {
                        sheetId: this.$route.params.sheetId,
                        pid: this.$route.params.pid,
                        versionId,
                    },
                    query: {
                        tab: RightPanelTab.HISTORY,
                    },
                }).then(() => {
                    this.$router.go(0)
                })
            }
        },
        compareVersions (): void {
            this.$router.push({
                name: 'drawings-compare-sheets-show',
                params: {
                    pid: this.$route.params.pid,
                    sheetId: this.model.drawing.current_version_id,
                },
            })
        },
    },
})
