<template>
    <div class="io-env-banner">

        <div class="io-env-banner__icon">
            T
        </div>
        <div class="io-env-banner__text">
            {{ $t('TRAINING ENVIRONMENT') }}
        </div>
    </div>
</template>

<script src="./EnvBanner.ts"></script>
<style lang="scss" src="./EnvBanner.scss" scoped></style>
