<template>
    <div class="io-history-tab">
        <div
            v-for="drawingVersion in model.drawing.versions"
            class="io-single-version"
            :class="{ 'io-selected': isSelected(drawingVersion.id) }"
            @click="changeVersion(drawingVersion.id)"
        >
            <div class="io-single-version__header">
                <div>
                    <i class="icon-folder"/>
                    <span>{{ drawingVersion.drawing_set_upload_name }}</span>
                </div>
                <div>{{ $filters.parseDateTime(drawingVersion.created_at) }}</div>
            </div>

            <StatusPill
                :variant="statusPillVariant(drawingVersion.version)"
                :text="statusPillText(drawingVersion.version)"
            />

            <div v-if="drawingVersion.created_by_full_name" class="io-uploaded-by">
                <div class="io-uploaded-by__avatar">
                    <img
                        v-if="drawingVersion.created_by_full_name.image_exists"
                        :src="getApiUrlAvatar(drawingVersion.created_by_full_name.id)"
                        :alt="drawingVersion.created_by_full_name.name"
                    />
                    <Initials
                        v-else
                        :fullName="drawingVersion.created_by_full_name.name"
                    />
                </div>
                <div class="io-uploaded-by__user-info">
                    <div>{{ drawingVersion.created_by_full_name.name }}</div>
                    <div>{{ drawingVersion.created_by_full_name.position }}</div>
                </div>
            </div>
        </div>

        <div class="io-history-tab__footer">
            <IOButton
                v-if="model.drawing.versions.length < 2"
                v-tooltip.top="$t('You must have more than one version to compare sheets')"
                disabled
                @click="compareVersions"
            >
                {{ $t('Compare Versions') }}
            </IOButton>

            <IOButton v-else @click="compareVersions">
                {{ $t('Compare Versions') }}
            </IOButton>
        </div>
    </div>
</template>

<script lang="ts" src="./History.ts"></script>
<style lang="scss" src="./History.scss" scoped></style>
