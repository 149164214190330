import { defineComponent } from 'vue'
import { FilterSectionInterface } from '@/interfaces/components/lazy-list/FilterSectionInterface'

export default defineComponent({
    name: 'DrawingSheetsFilterMixin',
    computed: {
        filterSections (): FilterSectionInterface[] {
            return [
                {
                    label: this.$t('Listing Filters'),
                    collapse: true,
                    collapsible: false,
                    filters: [
                        {
                            field: 'is_favorite',
                            label: '',
                            type: 'checkbox',
                        },
                        {
                            field: 'status',
                            label: this.$t('Status'),
                            type: 'checkbox',
                            translate: true,
                        },
                        {
                            field: 'page_category_name',
                            label: this.$t('Category'),
                            type: 'multiselect',
                            mapValueBy: 'id',
                        },
                        {
                            field: 'updated_at',
                            label: this.$t('Last Updated'),
                            type: 'date',
                        },
                    ],
                },
            ] as FilterSectionInterface[]
        },
    },
})
