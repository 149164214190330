import { defineComponent } from 'vue'
import Timeline from '@/components/timeline/timeline.vue'
import TimelineLogs from '@/interfaces/components/timeline/TimelineLogs'
import invoiceViewClient from '@/io-modules/invoices/api-clients/invoiceViewClient'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { mapState, mapActions } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import TimelineItem from '@/io-modules/invoices/interfaces/TimelineItem'

export default defineComponent({
    name: 'InvoiceTimeline',
    components: {
        Timeline,
    },
    data () {
        return {
            timelineData : [] as TimelineItem[]
        }
    },
    computed: {
        ...mapState(invoiceViewStore, ['invoice']),

        ...mapStateVuex('project', ['projectObj']),

        timelineLogs (): TimelineLogs[] {
            return this.timelineData.map((item, index) => {
                return {
                    id: index.toString(),
                    employee_mongo_id: item.creator?.id || '',
                    user: item.creator?.name || '',
                    company_name: item.creator?.role || '',
                    content: this.$t(item.message),
                    company_type: item.creator?.workspace_type,
                    created_at: item.created_at,
                }
            })
        },
    },
    async mounted () {
        try {
            this.setDataLoadingValue(true)
            const { data } = await invoiceViewClient.getInvoiceTimeline(this.projectObj.project_local_id, this.invoice.id)
            this.timelineData = data
        } catch (error) {
            this.errorHandleNoRedirect(error)
        } finally {
            this.setDataLoadingValue(false)
        }
    },
    methods: {
        ...mapActions(invoiceViewStore, ['setDataLoadingValue']),
    },
})