import { ApiClient as client } from '@/api/api'
import {
    BidRequest,
    BidRequestProjectList,
    BidReminder,
    BidRequestResponseData,
    ManualCompanyResponse,
    BidManagerResponseData,
    BidSubContractorResponse,
    FilterBidRequestPostData,
    BidRequestUnsealData,
    Quote,
    Proposal,
    Contact
} from '@/io-modules/bid-requests/interfaces'
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument'
import type BidElementToRevision from '../interfaces/BidElementToRevision'
import BidBidInBidSend from '@/io-modules/rfp-proposal/interfaces/BidBidInBidSend'
import ProjectTempInBidObj from '@/io-modules/rfp-proposal/interfaces/ProjectTempInBidObj'
import qs from '@/helpers/qs'
import { SitesTags } from '@/io-modules/rfp-proposal/interfaces/SitesTags.ts'

type ClientResponse<T = any> = Partial<AxiosResponse<T>> & {
    error?: AxiosError
}

const bidRequestClient = {
    saveBidRequest (postData: BidRequest): AxiosPromise<BidRequest> {
        return client.post('/bid-request-wizard', postData)
    },

    async updateBidRequest (id: string, postData: BidRequest): Promise<ClientResponse<BidRequest>> {
        try {
            return await client.put(`/bid-request-wizard/${ id }`, postData)
        } catch (error) {
            return { error }
        }
    },

    async copyProjectCurrencyToBidPackageCurrency (id: string): AxiosPromise<{ message: string }> {
        return await client.post(`/bid-request-wizard/${ id }/copy-project-currency`)
    },

    getBidRequest (id: string): AxiosPromise<BidRequestResponseData> {
        if (!id) {
            throw new Error('No Bidrequest ID Provided!')
        }
        return client.get(`/bid-request-wizard/${ id }`)
    },

    deleteBidRequest (id: string): AxiosPromise<{}> {
        return client.delete(`/bid-request/${ id }`)
    },

    updateBidRequestSealData (id: string, postData: BidRequestUnsealData): AxiosPromise<BidRequest> {
        return client.put(`/bid-request/${ id }/update-seal-data`, postData)
    },

    saveBidReminder (bidReminder: BidReminder): AxiosPromise<BidReminder> {
        return client.post('bid-reminder', bidReminder)
    },

    deleteBidReminder (reminderId: string): AxiosPromise<void> {
        return client.delete(`bid-reminder/${ reminderId }`)
    },

    updateBidManagers (bidRequestId: string, managers: Array<number | string>): AxiosPromise<BidManagerResponseData> {
        return client.put(`/bid-request/${ bidRequestId }/managers`, {
            managers
        })
    },

    getPreviousBidders (projectId: string): AxiosPromise<{ previous_bidders: string[] }> {
        return client.get(`/project/${ projectId }/bid-request-previous-bidders`)
    },

    sendBidderInvitations (bidRequestId: string, bidders: any): AxiosPromise<void> {
        return client.post(`/bid-request/${ bidRequestId }/invite/bid-sends`, { bidders })
    },

    sendBiddersList (bidRequestId: string, biddersListData: Array<string>): AxiosPromise<void> {
        return client.post(`/bid-request/${ bidRequestId }/bidder-lists`, biddersListData)
    },

    sendRevision (bidRequestId: string): AxiosPromise<any> {
        return client.post(`/bid-request/${ bidRequestId }/revision`)
    },

    loadListProject (elementId: string, filterData?: FilterBidRequestPostData): AxiosPromise<BidRequestProjectList> {
        if (!elementId) {
            throw new Error('No Project ID Provided!')
        }
        return client.post(`/project/${ elementId }/bid-request-list`, filterData ?? {})
    },

    loadListProposal (elementId: string, filterData?: FilterBidRequestPostData): AxiosPromise<any> {
        if (!elementId) {
            throw new Error('No Proposal ID Provided!')
        }
        return client.get(`/proposal/${ elementId }/bid-request`)
    },

    getCompaniesForBid (id: string): AxiosPromise<ManualCompanyResponse> {
        if (!id) {
            throw new Error('No Bidrequest ID Provided!')
        }
        return client.get(`/bid-request/${ id }/company`)
    },

    updateCompanyEmail (companyId: number, updateData: { email: string }): AxiosPromise<{}> {
        return client.post(`company/update-email/${ companyId }`, updateData)
    },

    bidHide (bidRequestId: string, bidSendsId: string, data: any): AxiosPromise<any> {
        return client.patch(`/bid-request/${ bidRequestId }/bid-sends/${ bidSendsId }/hidden`, data)
    },

    getVendors (bidRequestId: string, bidRequestTo: string): AxiosPromise<BidSubContractorResponse> {
        return client.get(`bid-request/${ bidRequestId }/subcontractor/${ bidRequestTo }`)
    },

    getVendorsLazyList (bidRequestId: string, bidRequestTo: string, page: number = 0, filters: object): AxiosPromise<BidSubContractorResponse> {
        if (!bidRequestId) {
            throw new Error('No Bid Request ID Provided!')
        }

        return client.get(`bid-request/${ bidRequestId }/subcontractor/${ bidRequestTo }/lazy-list/${ page }`, {
            params: { ...filters },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    updateCard (data: Object): AxiosPromise<BidReminder> {
        return client.post('quote/updateCard', data)
    },

    updateToManual (id: string): AxiosPromise<{ data: BidBidInBidSend }> {
        return  client.post('/quote/switch-to-manual', { id })
    },

    disqualifyBid (modelId: string, bidId: string, reason: string): AxiosPromise<BidRequestResponseData> {
        return client.patch(`/bid-request/${ modelId }/bid-sends/${ bidId }/disqualify`, { reason })
    },

    deleteCard (data: { id: string, bidSend: string }): AxiosPromise<BidRequestResponseData> {
        return client.post('/quote-manual/delete', data)
    },

    deleteManual (data: { id: string, bidSend: string }): AxiosPromise<BidRequestResponseData> {
        return client.post('/quote-manual/delete', data)
    },

    saveManual (data: { id: string, quote: Proposal }): AxiosPromise<BidRequestResponseData> {
        return client.post('/quote-manual', data)
    },

    updateManual (data: { id: string, quote: Proposal }): AxiosPromise<BidRequestResponseData> {
        return client.put(`/quote-manual/${ data.id }`, data)
    },

    uploadFile (data: Object, proposalQuote: string): AxiosPromise<{ document: SingleModelDocument }> {
        return client.post(`upload-model-document/proposals/${ proposalQuote }`, data)
    },

    createFolder (proposalQuote: string): AxiosPromise<{ code: number, success: boolean }> {
        return client.post(`create-model-folder/proposals/${ proposalQuote }`)
    },

    deleteFile (file: string): AxiosPromise<{ code: number, success: boolean }> {
        return client.delete('documents/remove', { params: { files: [ file ] } })
    },

    requestRevision (modelId: string, bidId: string, quoteId: string, elements: BidElementToRevision[]): AxiosPromise<{ bid: Quote }> {
        return client.post(`/bid-request/${ modelId }/bid-sends/${ bidId }${ quoteId ? `/quote/${ quoteId }` : '' }/revision`, { elements })
    },

    rescindInvitation (bidRequestId: string, bidSendsId: string, isInvitation: boolean): AxiosPromise<{ sent: boolean, message: string }> {
        return client.get(`/bid-request/${ bidRequestId }/bid-sends/${ bidSendsId }/rescind-invitation`, { params: { is_invitation: isInvitation } })
    },

    resendInvitation (bidRequestId: string, bidSendsId: string, isInvitation: boolean): AxiosPromise<{ sent: boolean, message: string }> {
        return client.get(`/bid-request/${ bidRequestId }/bid-sends/${ bidSendsId }/resend-invitation`, { params: { is_invitation: isInvitation } })
    },

    reverseRescindInvitation (bidRequestId: string, bidSendsId: string, isInvitation: boolean): AxiosPromise<{ sent: boolean, message: string }> {
        return client.get(`/bid-request/${ bidRequestId }/bid-sends/${ bidSendsId }/reverse-rescind-invitation`, { params: { is_invitation: isInvitation } })
    },

    getContacts (): AxiosPromise<{ list: Contact[] }>{
        return client.post('cached-list/contacts')
    },

    notifyAdmin (bidRequestId: string, bidSendsId: string): AxiosPromise<{ sent: boolean, message: string }> {
        return client.post(`/bid-request/${ bidRequestId }/bid-sends/${ bidSendsId }/notify-admin`)
    },

    getProjectSettings (pid: string): AxiosPromise<{ result: ProjectTempInBidObj }> {
        return client.get(`/project-settings/save/${ pid }`)
    },

    getSitesTags (bidRequestId: string): AxiosPromise<SitesTags[]> {
        return client.get(`/bid-packages/${ bidRequestId }/budget_cost_codes/tags`)
    }
}

export default bidRequestClient

export { bidRequestClient }
