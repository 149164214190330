import { defineComponent, PropType, nextTick } from 'vue'
import ListHeader from '../list-header/ListHeader.vue'
import SoVTableColumnsMixin from '@/io-modules/invoices/mixins/sov-wbs/SoVTableColumnsMixin'
import ExpandableTable from '@/components/expandable-table/ExpandableTable.vue'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
import { mapActions, mapState } from 'pinia'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import SoVTableType from '../../../../enums/SoVTableType.ts'
import ReviseResubmitMixin from '@/io-modules/invoices/mixins/sov-wbs/ReviseResubmitMixin.ts'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView.ts'

export default defineComponent({
    name: 'QuotedItemsList',
    components: {
        ListHeader,
        ExpandableTable,
    },
    props: {
        phases: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        netCurrentApplicationValue: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: ''
        },
        link: { type: String, default: '' },
        params: {
            type: Object,
            default: () => ({})
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        onlyFooter: {
            type: Boolean,
            default: false
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        hasExecutedCOs: {
            type: Boolean,
            default: false
        },
        showLeftNavigation: {
            type: Boolean,
            default: true,
        },
        scrollLeft: {
            type: Number,
            default: 0
        },
        tableType: String as PropType<SoVTableType>,
        id: String,
    },
    mixins: [SoVTableColumnsMixin, ReviseResubmitMixin],
    emits: ['update:scrollLeft'],
    computed: {
        ...mapState(invoiceSoVStore, ['columnsViewMode', 'tableViewMode']),
        ...mapState(invoiceViewStore, ['invoice', 'isMyInvoice'])
    },
    watch: {
        scrollLeft (value: number): void {
            this.$refs.table.setTableScrollLeft(value)
        },

        async tableViewMode (value: SoVTableViewMode): Promise<void> {
            if (SoVTableType.OriginalScope == this.tableType && SoVTableViewMode.COsWithinOriginalScope === value) {
                await nextTick()
                this.phases.forEach(item => {
                    this.goToTheDepth(item, this.updateParentPercents)
                })
            }
        },
    },
    mounted () {
        this.$watch(
            '$refs.table.scrollLeft',
            (value: number): void => {
                this.$emit('update:scrollLeft', value)
            }
        )

        this.setSovRecalculating(true)
        this.phases.forEach(item => {
            this.goToTheDepth(item, this.updateParentPercents)
        })
        this.setSovRecalculating(false)
    },
    methods: {
        ...mapActions(invoiceSoVStore, ['setSovRecalculating']),
        clickedInput (itemId: string, item: object): void {
            this.decideOnModalOnInputClick(itemId, item)
        }
    }
})
