<template>
    <div
        v-if="isInfoPanelVisible"
        class="io-info-panel"
        data-testid="info-panel"
    >
        <div class="io-info-panel-heading">
            <div class="io-info-panel-heading__title">
                <i class="io-info-panel-heading__icon icon-megaphone"></i>
                <span class="io-info-panel-heading__title_text">
                    {{ $t('How does it work?') }}
                </span>
            </div>
            <div v-if="isExpanded" class="io-info-panel-heading__content_expanded">
                <a class="io-info-panel-heading__link toggle" @click="collapse">
                    <span class="icon-cross"></span>
                </a>
            </div>
            <div v-else class="io-info-panel-heading__content_collapsed">
                <span class="io-info-panel-heading__hint-text">
                    {{ $t('Add your contacts. Invite to collaborate or bookmark...') }}
                </span>
                <a class="io-info-panel-heading__link toggle" @click="expand">
                    <span>{{ $t('Learn more') }}</span>
                </a>
            </div>
        </div>
        <div v-if="isExpanded" class="io-info-panel-content">
            <p v-if="!disableInviting" class="io-info-panel-content__line" >
                <span class="io-info-panel-content__line_title">{{ $t('Invite') }}:</span> {{ $t("the user will receive an invite to collaborate on projects or specific items. If they're not on the platform yet they'll get an invitation to create a free account first.") }}
            </p>
            <p v-if="!disableBookmarking" class="io-info-panel-content__line">
                <span class="io-info-panel-content__line_title">{{ $t('Bookmark') }}:</span> {{ $t("the user will be privately saved for your personal reference and won’t be notified in any way. No collaboration involved yet.") }}
            </p>

            <p class="io-info-panel-content__line">
                <span class="io-info-panel-content__line_title">{{ $t(employeesOnly ? "Can’t find an employee?" : "Can't find your contact?") }}:</span>&nbsp;
                {{
                    $t(
                        employeesOnly
                            ? "You can add them in the Employees module in the menu on the left-hand side of your screen."
                            : "Add them by entering their email in the search box below. Choose later to invite or bookmark."
                    )
                }}
            </p>

        </div>
        <div v-if="isExpanded" class="io-info-panel-footer">
            <a @click="hideInfoPanel(componentKey)">{{ $t('Got it!') }}</a>
        </div>
    </div>
</template>

<script lang="ts" src="./InfoPanel.ts"></script>
<style src="./InfoPanel.scss" lang="scss" scoped></style>
