import { defineComponent } from 'vue'
import ActionRadioRow from '../../parts/ActionRadioRow.vue'
import { ContactActions } from '@/interfaces/components/invite-modal/ContactActions'
import { mapMutations } from 'vuex'

export default defineComponent({
    name: 'ActionsView',
    components: {
        ActionRadioRow
    },
    props: {
        componentKey: { type: String, required: true },
        isPartOfView: { type: Boolean, required: false, default: false }
    },
    data () {
        return {
            ContactActions: ContactActions,
            selectedAction: null as ContactActions
        }
    },
    computed: {
        isAddingNewContact (): boolean {
            return this.componentKey === 'contact-create-client'
        }
    },
    watch: {
        'selectedAction' (value) {
            this.setSelectedAction({ key: this.componentKey, value })
        }
    },
    mounted () {
        if (this.isAddingNewContact) {
            this.selectedAction = ContactActions.SAVE
        }
    },
    methods: {
        ...mapMutations('inviteModal', {
            setSelectedAction: 'SET_SELECTED_ACTION'
        })
    }
})
