export const getDivisions = (state) => {
    return state.divisions
}

export const getCollapsedDivisions = (state) => {
    return state.collapsedDivisions
}

export const getProcessingSets = (state) => {
    return state.processingSets
}

export const getPublishedSets = (state) => {
    return state.publishedSets
}

export const getActiveSection = (state) => {
    return state.activeSection
}

export const checkedSections = (state) => {
    return state.checkedSections
}

export const isDisabledBtn = (state) => {
    return state.isDisabledBtn
}

export const getMentionUsers = (state) => {
    return state.mentionUsers
}

export const getAnnotationList = state => state.annotationList

