<template>
    <div
        v-if="workflowData"
        class="io-approval-workflow"
    >
        <div class="io-approval-workflow__wrapper">
            <div class="io-approval-workflow__header">
                <div class="io-approval-workflow__header-left">
                    <h3>{{ title }}</h3>

                    <template v-if="isTotalVisible">
                        <template v-if="workflowData.cost_codes && workflowData.cost_codes.length">
                            <div class="io-approval-workflow__total">
                                {{ $t('Cost Codes') }}: <span class="io-cost-codes">{{ workflowData.cost_codes.length }}</span>
                            </div>

                            <div class="io-approval-workflow__total">
                                {{ $t('Cost Code Total') }}: <span>{{ $filters.formatCurrency(totalAmount) }}</span>
                            </div>
                        </template>
                        <div
                            v-else
                            class="io-approval-workflow__total"
                        >
                            {{ $t('Total') }}: <span>{{ $filters.formatCurrency(totalAmount) }}</span>
                        </div>
                    </template>

                    <StatusPill :variant="approvalStatusPill.variant" :text="approvalStatusPill.text" />
                </div>
            </div>

            <table class="io-approval-table">
                <thead>
                    <tr>
                        <th class="io-approval-table__sequence">{{ $t('Sequence') }}</th>
                        <th class="io-approval-table__approver">{{ $t('Approver') }}</th>
                        <th class="io-approval-table__type">{{ $tc('Type') }}</th>
                        <th class="io-approval-table__limit">{{ $t('Approval Limit') }}</th>
                        <th class="io-approval-table__note">{{ $tc('Note') }}</th>
                        <th class="io-approval-table__files">{{ $tc('Files') }}</th>
                        <th class="io-approval-table__status"></th>
                    </tr>
                </thead>

                <tbody>
                    <SingleApproval
                        v-for="(approver, index) in workflowData.approvers"
                        :key="index + ' approval'"
                        :approverData="approver"
                        :show-sequence-position="workflowData.is_sequence_enabled"
                        :show-approval-limit="workflowData.is_limit_enabled"
                        :approval-status="approvalStatus"
                        @approverApproveSelected="data => $emit('approverApproveSelected', data)"
                        @approverRejectSelected="data => $emit('approverRejectSelected', data)"
                        @approverReviewSelected="data => $emit('approverReviewSelected', data)"
                        @openOverrideApproverModal="openOverrideApproverModal(approver)"
                    />
                </tbody>
            </table>

        </div>
        <OverrideApproverModal
            v-if="showOverrideApproverModal"
            :approvableResourceId="resourceApprovals.id"
            :workflowData="workflowData"
            :approver="approverData"
            @approverOverridden="$emit('approverOverridden')"
            @close="closeOverrideApproverModal"
        />
    </div>
</template>

<script lang="ts" src="./ApprovalWorkflow.ts"></script>
<style lang="scss" src="./ApprovalWorkflow.scss" scoped></style>
