<template>
    <CustomMultiselect
        v-model="value"
        :options="approvalTypeOptions"
        :statusEnable="false"
        :settings="{ closeAfterSelect: true }"
    >
        <template #header="{ activeOption }">
            <div v-if="activeOption" class="io-multiselect__name">
                {{ activeOption.label }}
            </div>
        </template>
        <template #option="{ option }">
            {{ option.label }}
        </template>
    </CustomMultiselect>
</template>

<script lang="ts" src="./AssignmentTypeSelector.ts"></script>
<style lang="scss" src="./AssignmentTypeSelector.scss" scoped></style>
