import { defineComponent } from 'vue'
import { omit } from 'lodash'

export default defineComponent({
    name: 'IOInput',
    inheritAttrs: false,
    props: {
        icon: { type: String, default: '' },
        prefix: { type: String, default: '' },
        suffix: { type: String, default: '' },
        valid: { type: Boolean, default: true },
        errorMessage: { type: String, default: '' },
        additionalInputClass: { type: String, default: '' },
        modelValue: { type: [String, Number] },
        defaultValue: { type: [String, Number], default: '' },
        autocompleteItems: { type: Array as () => unknown[], default: () => [] },
        noNumberSpinners: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        showSearch: { type: Boolean, default: false },
        addNewEnabled: { type: Boolean, default: false },
    },
    emits: ['update:modelValue', 'autocomplete', 'iconClick', 'onKeyupEnter', 'onKeyDown'],
    data () {
        return {
            focused: false,
            searchValue: '',
            searchCopy: '',
        }
    },
    computed: {
        currentValue: {
            get (): string | number {
                return this.modelValue
            },
            set (value: string): void {
                if (this.showSearch) {
                    this.searchValue = value
                }
                this.$emit('update:modelValue', value)
            },
        },
        showErrorMessage (): boolean {
            return Boolean(!this.valid && this.errorMessage && !this.disabled)
        },
        disabled (): boolean {
            return Boolean(this.$attrs.disabled || this.$attrs.disabled === '')
        },
        filteredItems (): any[] {
            return this.searchValue ? this.autocompleteItems.filter(item => item.name.toLowerCase().includes(this.searchValue.toLowerCase())) : this.autocompleteItems
        },
    },
    watch: {
        modelValue: {
            handler (): void {
                if (this.addNewEnabled) {
                    this.searchValue = this.currentValue
                }
            },
            immediate: true,
            deep: true
        },
    },
    beforeMount () {
        if (this.defaultValue) {
            this.currentValue = this.defaultValue
        }
    },
    mounted () {
        this.focus() // Retain autofocus in modals
    },
    methods: {
        omit,
        focus (): void {
            // 'preventScroll' to prevent breaking transition animations
            if (this.$el.querySelector !== undefined) {
                this.$el.querySelector('[autofocus]')?.focus({ preventScroll: true })
            }
        },
        clear (): void {
            this.$emit('update:modelValue', '')
            this.focus()
        },
        emitAutocomplete (item: any) {
            this.$emit('autocomplete', item)

            if (this.showSearch) {
                this.searchValue = item.name
            }
        },
        searchFocus () {
            this.focused = true

            if (!this.addNewEnabled) {
                this.searchCopy = this.searchValue.repeat(1)
                this.searchValue = ''
            }
        },
        searchFocusOut () {
            this.focused = false

            if (this.addNewEnabled) {
                this.$emit('update:modelValue', this.searchValue)

                return
            }

            this.searchValue = this.searchCopy.repeat(1)
        },
        onKeyupEnter (): void {
            this.$emit('onKeyupEnter')
        },
        onKeyDown ($event): void {
            this.$emit('onKeyDown', $event)
        },
    },
})
