import { defineComponent, PropType } from 'vue'
import Card from '@/components/card/Card.vue'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import Table from '@/components/table/Table.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import {
    ProjectFundingAllocatedRows,
    ProjectFundingDataForSending,
} from '@/io-modules/project-funding/interfaces/ProjectFundingAllocatedRows'
import { mapState } from 'vuex'
import mathHelpers from '@/helpers/mathHelpers'
import AttachFileModal from '../modals/attach-file/AttachFileModal.vue'
import AddNoteModal from '../modals/add-note/AddNoteModal.vue'
import FieldNumeric from '@/components/fields/fieldNumeric.vue'
import modalOnDelete from '../../helpers/modalOnDelete'
import { mapState as piniaMapState, mapActions as piniaMapActions } from 'pinia'
import { projectFundingStore } from '@/io-modules/project-funding/stores/projectFundingStore'
import FinancialTableCalculator from '@/components/financial-table-calculator/FinancialTableCalculator.vue'

export default defineComponent({
    name: 'AllocateFundsTable',
    components: { FinancialTableCalculator, FieldNumeric, AddNoteModal, Table, Avatar, Card, AttachFileModal },
    props: {
        allocatedFunds: {
            required: true,
            type: Array as PropType<ProjectFundingAllocatedRows[]>,
        },
    },
    mixins: [modalOnDelete],
    emits: ['allocation', 'canAddFund'],
    data () {
        return {
            allocatedFundsFilled: null as ProjectFundingAllocatedRows[],
            fundAllocateFrom: { lines: [] } as ProjectFundingAllocatedRows,
            aboveLimit: false,
            allocationModalTitles: {
                table: 'Allocation to Project',
                allocatedSum: 'Allocation to Project'
            }
        }
    },
    computed: {
        ...mapState('project', {
            projectObj: (state: any) => state.projectObj,
        }),
        ...piniaMapState(projectFundingStore,[
            'projectFundingSummary',
            'isAllocationModalOpened'
        ]),
        tableHeaders (): HeaderTable[] {
            const headers: HeaderTable[] = [
                {
                    text: this.$t('Fund Name'),
                    value: 'fund_name',
                },
                {
                    text: this.$t('Funding Source Company'),
                    value: 'provider_name',
                },
                {
                    text: this.$t('Remaining Unallocated Funds'),
                    value: 'unallocated_amount',
                    sort: (item) => parseFloat(item),
                    align: 'end',
                },
                {
                    text: this.$t('Allocation to Project'),
                    value: 'allocated_amount',
                    align: 'center',
                },
                {
                    text: `% ${ this.$t('of Total Project Funding') }`,
                    value: 'percent',
                    align: 'end',
                },
                {
                    text: this.$t('Paid to Date'),
                    value: 'unallocated_amount',
                    align: 'end',
                },
                {
                    text: this.$t('Note'),
                    sort: false,
                    align: 'end',
                },
                {
                    text: this.$t('Attachment'),
                    sort: false,
                    align: 'end',
                },
                {
                    text: '',
                    sort: false,
                },
            ]
            return headers
        },
        calcTotalFunding (): number {
            return mathHelpers.calculateTotalByField(this.allocatedFundsFilled, 'allocated_amount')
        },
    },
    watch: {
        allocatedFunds:  {
            handler (): void {
                this.allocatedFundsFilled = [...this.allocatedFunds]
            },
            deep: true
        },
    },
    methods: {
        ...piniaMapActions(projectFundingStore, [
            'setShowAllocationModal'
        ]),
        toggleAllocationModal (): void {
            this.setShowAllocationModal(!this.isAllocationModalOpened)
        },
        // called then user adds info in fields
        collectData (fundId: string, field: string, data: any): void {
            const fundToUpdate = this.allocatedFundsFilled.find((fund) => {
                return fund.fund_id === fundId
            })

            if (fundToUpdate) {
                fundToUpdate[field] = data
            }

            this.$emit('allocation', this.collectDataForSending())
            this.$emit('canAddFund', this.canAddFund())
        },
        calcPercentOfProjectFunding (fundId: string, allocation: string): number {
            let persent = 0
            const totalAllocated = parseFloat(this.projectFundingSummary.funding_amount)
            const allocatedInThisModal = this.calcTotalFunding
            const currentAllocation = parseFloat(allocation)

            if (!currentAllocation) {
                persent = 0
            } else {
                persent = mathHelpers.cutPrecision(currentAllocation * 100 / (totalAllocated + allocatedInThisModal), 2)
            }
            this.collectData(fundId, 'percent', persent)

            return persent
        },
        isAboveLimit (unall: string, all: number): boolean {
            if (all) {
                const limit = all > parseFloat(unall)
                this.aboveLimit = limit
                return limit
            }
            return false
        },
        canAddFund (): boolean {
            return this.allocatedFundsFilled.every(fund => fund.allocated_amount) && !this.aboveLimit
        },
        showModalOnDeleteWrapper (fundId: string): void {
            this.showModalOnDelete(() => this.allocatedFundsFilled = this.allocatedFundsFilled.filter(fund => fund.fund_id !== fundId))
            this.$emit('canAddFund', this.canAddFund())
        },
        // data for backend
        collectDataForSending (): ProjectFundingDataForSending {
            const allocation: ProjectFundingAllocatedRows[] = this.allocatedFundsFilled.map((elem): ProjectFundingAllocatedRows => {
                return {
                    fund_id: elem.fund_id,
                    project_id: this.projectObj.project_local_id,
                    allocated_amount: elem.allocated_amount ? elem.allocated_amount.toString() : '0',
                    note: elem.note || '',
                    lines: elem.lines ? elem.lines.map(line => {
                        return {
                            ...line,
                            total: line.total.toString(),
                            unit_amount: line.unit_amount.toString(),
                            unit_quantity: line.unit_quantity.toString(),
                        }
                    }) : [],
                    temporary_attached_documents: elem.temporary_attached_documents || [],
                }
            })

            return { items: allocation }
        },
    },
})
