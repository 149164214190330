<template>
    <div
        ref="scrollable"
        class="io-simple-lazy-list"
    >
        <Table
            v-if="!!items.length || isLoading"
            :headers="columns"
            :items="items"
            :is-sorting-disabled="isFrontendSortingDisabled"
            @sort-change="handleTableSort"
        >
            <template #tbody="{ sortedItems }">
                <SimpleLazyListRow
                    v-for="item in sortedItems"
                    :key="item.id"
                    :item-route="itemRoute"
                    :item="item"
                    :columns="columns"
                />
            </template>

            <template #tfooter>
                <tr v-show="!isLastPage" ref="sentinel">
                    <td :colspan="columns.length">
                        <div
                            v-if="isShowFetchingItems"
                            class="io-simple-lazy-list__load-more"
                        >
                            <i class="icon-loader-2 fa-spin"/>

                            {{ $t('Fetching items...') }}
                        </div>
                    </td>
                </tr>
            </template>
        </Table>

        <slot v-else name="no-items">
            <IOPlaceholder
                v-if="!isLoading && !items.length"
                icon="icon-inbox"
                :title="$t('No items found')"
            />
        </slot>

        <ModalFilters
            v-show="isFiltersModalShown"
            :countFilters="countFilters"
            @apply="applyFilters"
            @closeModal="onCloseFilters"
            @clearFilters="onClearAllFilters"
        >
            <template #default>
                <Filters
                    v-if="filtersSchema"
                    :filtersSchema="filtersSchema"
                    :customPillsColorFunction="filterPillsColorFunction"
                    :filters="filters"
                    v-model:filters-selected="selectedFilters"
                />
            </template>

            <template #buttons>
                <IOButton variant="secondary" @click="onClearAllFilters">{{ $t('Clear all') }}</IOButton>
            </template>
        </ModalFilters>
    </div>
</template>

<script lang="ts" src="./SimpleLazyList.ts"></script>
<style lang="scss" src="./SimpleLazyList.scss" scoped></style>
