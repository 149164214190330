import { defineComponent } from 'vue'
import InvoicesListHeader from '@/io-modules/invoices/components/invoices-list/invoices-list-header/InvoicesListHeader'
import InvoicesListTable from '@/io-modules/invoices/components/invoices-list/invoices-list-table/InvoicesListTable'
import InvoicesTab from '@/io-modules/invoices/enums/InvoicesTab'
import ListsMixin from '@/io-modules/invoices/mixins/ListsMixin'
import { mapActions, mapState } from 'pinia'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import ContractRouteName from '@/io-modules/project-contracts/enums/ContractRouteName'
import ListingName from '@/io-modules/invoices/enums/ListingName.ts'
import listTypeName from '@/io-modules/invoices/helpers/listTypeName.ts'

export default defineComponent({
    name: 'MyInvoicesList',
    components: {
        InvoicesListHeader,
        InvoicesListTable
    },
    mixins: [ListsMixin],
    emits: ['createInvoiceClick'],
    data () {
        return {
            ctaButtons: [
                {
                    name: this.$t('Create Invoice'),
                    onSubmitName: 'createInvoice'
                },
            ],
            goToContractBtn: [
                {
                    name: this.$t('Go to Project Contracts'),
                    onSubmitName: 'goToContract'
                }
            ],
            InvoicesTab,
            loadingData: false,
            totals: null
        }
    },
    computed: {
        ...mapState(invoicesStore, ['projectHasMainContractExecuted']),

        projectHasExecutedContract (): boolean {
            return this.projectHasMainContractExecuted
        },

        noExecutedMainContract (): boolean {
            return null !== this.projectHasExecutedContract && !this.projectHasExecutedContract
        },
    },
    watch: {
        projectHasMainContractExecuted (newValue: boolean): void {
            if (newValue) {
                this.onProjectHasExecutedContract()
            }
        }
    },
    async mounted () {
        await this.fetchFilters(this.projectObj.project_local_id, listTypeName(this.$route.meta.listType))

        if (this.projectHasExecutedContract) {
            this.onProjectHasExecutedContract()
        }
    },
    methods: {
        ...mapActions(invoicesStore, ['fetchMyInvoices', 'clearListFilters', 'clearListParams', 'fetchFilters']),

        async onProjectHasExecutedContract (): Promise<void> {
            try {
                this.setContentLoaderValue(true)
                await this.fetchMyInvoices(this.projectObj.project_local_id)
                this.totals = await this.fetchInvoiceTotals(ListingName.My)
            } catch (error) {
                console.log(error)
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            } finally {
                this.setContentLoaderValue(false)
            }
        },

        createInvoice (): void {
            this.$emit('createInvoiceClick')
        },

        async loadData (): Promise<void> {
            this.loadingData = true
            await this.fetchMyInvoices(this.projectObj.project_local_id)
            this.loadingData = false
        },

        goToContract (): void {
            this.$router.push({ name: ContractRouteName.ProjectContractsList, params: { pid: this.projectObj.id } })
        },
    },
})
