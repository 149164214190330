const Auth = () => import(/* webpackChunkName: "HealthCheck" */ '@/io-modules/auth/views/auth/Auth.vue')
const SetPassword = () => import(/* webpackChunkName: "HealthCheck" */ '@/io-modules/auth/views/set-password/SetPassword.vue')
const ForgotPassword = () => import(/* webpackChunkName: "HealthCheck" */ '@/io-modules/auth/views/forgot-password/ForgotPassword.vue')

export default [
    {
        path: '/login/:workspace?',
        name: 'login',
        component: Auth,
        meta: {
            skipAuth: true
        }
    },
    {
        path: '/set-password/:token?',
        name: 'set-password',
        component: SetPassword,
        meta: {
            skipAuth: true
        }
    },
    {
        path: '/forgot-password/:email?',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            skipAuth: true
        }
    }
]
