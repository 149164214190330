export function ADDING_SOURCE (state, value) {
    state.addingSource = value
}

export function SET_PROVIDER_ID (state, value) {
    state.providerId = value
}

export function SET_PROVIDER_NAME (state, value) {
    state.providerName = value
}

export function RESET_PROVIDER (state) {
    state.providerId = null
    state.providerName = null
}
