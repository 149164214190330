import { defineComponent, nextTick } from 'vue'
import { mapActions as mapActionsVuex } from 'vuex'
import { mapActions, mapState } from 'pinia'
import DropdownButton from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
import CollapseExpandButton from '@/components/expandable-table/parts/table-expand-collapse-button/CollapseExpandButton.vue'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import SimpleOption from '@/io-modules/invoices/interfaces/SimpleOption'
import InvoiceStatus from '@/io-modules/invoices/enums/InvoiceStatus'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'

export default defineComponent({
    name: 'TopActions',
    components: {
        CollapseExpandButton,
        DropdownButton,
    },
    props: {
        showLeftNavigation: {
            type: Boolean,
            default: true
        },
        hasVisibleLines: {
            type: Boolean,
            default: false,
        },
        scrollLeft: {
            type: Number,
            default: 0
        },
        toggleLeftNavAvailable: {
            type: Boolean,
            default: true,
        },
    },
    emits: [
        'update:showLeftNavigation',
        'toggleFullScreen',
        'update:scrollLeft',
    ],
    data () {
        return {
            SoVTableViewMode,
            statusesApplication: [
                InvoiceStatus.PendingInternalApproval
            ],
            statusesAdjustment: [
                InvoiceStatus.RequestedRevision
            ],
            linesViewModeOptions: {
                all: {
                    id: SoVTableViewMode.AllLines,
                    name: this.$t('All Lines')
                },
                application: {
                    id: SoVTableViewMode.LinesBilledThisPeriod,
                    name: this.$t('Lines Billed This Period')
                },
                adjustment: {
                    id: SoVTableViewMode.LinesWithAdjustments,
                    name: this.$t('Lines With Adjustments')
                },
            },
            linesViewMode: null,
            coViewModeOptions: {
                originalScope: {
                    id: SoVTableViewMode.COsWithinOriginalScope,
                    name: this.$t('CCs Within Original Scope')
                },
                separateCategory: {
                    id: SoVTableViewMode.COsInSeparateCategory,
                    name: this.$t('CCs In Separate Category')
                },
            },
            coViewMode: null,
            columnsViewModeOptions: {
                all: {
                    id: SoVTableViewMode.AllColumns,
                    name: this.$t('All Column View')
                },
                basic: {
                    id: SoVTableViewMode.BasicColumns,
                    name: this.$t('Basic Column View')
                },
            },
            columnsViewMode: null,
            forceExpand: false,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, ['creating', 'invoice', 'invoiceIsDraft', 'isMyInvoice', 'dataLoading', 'isRetentionInvoice']),

        ...mapState(invoiceSoVStore, ['invoiceWBSLoaded']),

        ...mapState(invoiceRevisionStore, ['needAttentionItemsTotal']),

        linesViewModeOptionsArray (): SimpleOption[] {
            return Object.values(this.linesViewModeOptions)
        },

        coViewModeOptionsArray (): SimpleOption[] {
            return Object.values(this.coViewModeOptions)
        },

        columnsViewModeOptionsArray (): SimpleOption[] {
            return Object.values(this.columnsViewModeOptions)
        },
    },
    watch: {
        linesViewMode (value: SimpleOption): void {
            this.setVisibleLinesFilter(value.id)
            // setTimeout(() => {
            //     if (this.hasVisibleLines) {
            //         if (value.id === SoVTableViewMode.AllLines) {
            //             this.setCollapseSoV(true)
            //         } else {
            //             this.setExpandSoV(true)
            //         }
            //     }
            // }, 600)
        },

        columnsViewMode (value: SimpleOption): void {
            this.setColumnsViewMode(value.id)
        },

        coViewMode (value: SimpleOption): void {
            this.setTableViewMode(value.id)
        },

        async hasVisibleLines (): Promise<void> {
            this.expandAllItems()
        },

        async invoiceWBSLoaded (): Promise<void> {
            this.expandAllItems()
        },
    },
    mounted () {
        this.setDefaultViews()
        if (this.isRetentionInvoice) {
            this.moveToRetentionSection()
        }

        this.expandAllItems()
    },
    methods: {
        ...mapActions(invoiceSoVStore, [
            'setVisibleLinesFilter',
            'setColumnsViewMode',
            'setTableViewMode',
        ]),

        ...mapActionsVuex('financesCommon', [
            'setCollapseSoV',
            'setExpandSoV',
        ]),

        async expandAllItems (): Promise<void> {
            if (this.invoiceWBSLoaded && this.hasVisibleLines) {
                await nextTick()
                this.forceExpand = true
            }
        },

        setDefaultViews (): void {
            this.linesViewMode = this.linesViewModeOptions.all

            if (this.statusesApplication.includes(this.invoice.status)) {
                this.linesViewMode = this.linesViewModeOptions.application
            }
            // } else if (this.statusesAdjustment.includes(this.invoice.status) && this.needAttentionItemsTotal) {
            //     this.linesViewMode = this.linesViewModeOptions.adjustment
            // }

            this.columnsViewMode = this.columnsViewModeOptions.basic
            this.coViewMode = this.coViewModeOptions.originalScope
        },

        setViewMode (type: string, mode: SimpleOption): void {
            this[type] = mode
        },

        toggleLeftNavigation (): void {
            this.$emit('update:showLeftNavigation', !this.showLeftNavigation)
        },

        moveToRetentionSection (): void {
            this.columnsViewMode = this.columnsViewModeOptions.all
            setTimeout(() => this.$emit('update:scrollLeft', 1825), 150)
        },
    }
})
