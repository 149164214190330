import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Checkbox',
    props: {
        labelText: { type: String, required: false, default: '' },
        inputName: { type: String, default: '' },
        required: { type: Boolean, default: false, required: false },
        modelValue: { type: [Boolean, Array<boolean>], required: true },
        checkboxValue: { required: false },
        classSpan: { type: String, required: false },
        disabled: { type: Boolean, required: false, default: false },
        noValueValidation: { type: Boolean, default: false },
        partialCheck: { type: Boolean, required: false, default: false },
        withSlot: { type: Boolean, required: false, default: false },
        noMargins: { type: Boolean, default: false },
    },
    computed: {
        value: {
            get (): boolean | Array<boolean> {
                return this.modelValue
            },
            set (val: boolean | Array<boolean>): void {
                this.$emit('update:modelValue', val)
            },
        },
        isChecked (): boolean {
            return this.noValueValidation
                ? !!this.value
                : this.value && Array.isArray(this.value) && this.value.includes(this.checkboxValue)
        },
    },
})
