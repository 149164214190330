import { defineComponent, DefineComponent } from 'vue'
import type { Tab as TabInterface } from './TabInterface'
import DropdownButtonNew from '../DropdownButtonNew/DropdownButtonNew.vue'
import Tab from './parts/Tab.vue'
import { mapState } from 'vuex'

export default defineComponent({
    name: 'Tabs',
    components: {
        DropdownButtonNew,
        Tab,
    },
    props: {
        placement: { type: String, default: 'left' },
        title: { type: String, default: '' },
        tabs: { type: Array as () => TabInterface[], default: () => [] },
        activeTab: { type: [ String, Number ] },
        showTitle: { type: Boolean, default: true },
        translate: { type: Boolean, default: false },
        isVertical: { type: Boolean, default: false },
        allowShowViewMore: { type: Boolean, default: true },
        mustCompareOnlyKeyForActive: { type: Boolean, default: false },
    },
    emits: ['click', 'clickWithAnyState', 'remove'],
    data () {
        return {
            tabsElements: [] as HTMLElement[],
            tabsContainerElement: null as HTMLElement,
            hiddenTabsElements: [] as HTMLElement[],
        }
    },
    computed: {
        ...mapState('main', {
            openLeftMenuStatus: state => state.openLeftMenu,
        }),
        showViewMore (): boolean {
            return this.allowShowViewMore ? !!this.hiddenTabsElements.length : false
        },
        listTabs (): TabInterface[] {
            return this.tabs.map((tab, index) => ({
                ...tab, index
            }))
        },
        hiddenTabs (): TabInterface[] {
            return this.listTabs.slice(-this.hiddenTabsElements.length)
        },
        isDropdownActive (): boolean {
            return !!~this.hiddenTabs.findIndex(tab => tab.index === this.activeTab)
        },
    },
    watch: {
        openLeftMenuStatus (): void {
            this.updateTabsVisibility()
        }
    },
    mounted (): void {
        this.initializeTabsVisibility()
    },
    unmounted (): void {
        window.removeEventListener('resize', this.updateTabsVisibility)
    },
    methods: {
        onClick (index: number, tabDisabled: boolean = false): void {
            this.$emit('clickWithAnyState', index)

            if (tabDisabled) {
                return
            }

            this.$emit('click', index)
        },
        onRemove (index: number): void {
            this.$emit('remove', index)
        },

        initializeTabsVisibility (): void {
            this.$nextTick(() => {
                this.tabsContainerElement = this.$refs.tabsContainer as HTMLElement
                this.tabsElements = (this.$refs.tabs as DefineComponent[]).map((tab) => tab.$refs.tab as HTMLElement)
                window.addEventListener('resize', this.updateTabsVisibility)
                this.updateTabsVisibility()
            })
        },

        updateTabsVisibility (): void {
            if (this.isVertical) {
                return
            }
            const containerTabsWidth = this.tabsContainerElement?.clientWidth || 0
            const viewMoreWidth = this.hiddenTabsElements.length > 0
                ? this.tabsContainerElement.querySelector('.io-tabs-component__more')?.clientWidth || 0
                : 0

            let totalTabsWidth = viewMoreWidth

            this.hiddenTabsElements.forEach(tab => this.toggleTab(tab, 'block'))
            this.hiddenTabsElements = []

            this.tabsElements.forEach(tab => {
                totalTabsWidth += tab.offsetWidth

                if (totalTabsWidth > containerTabsWidth) {
                    this.toggleTab(tab, 'none')
                    this.hiddenTabsElements.push(tab)
                }
            })
        },

        toggleTab (tab: HTMLElement, property: string): void {
            tab.style.display = property
        },
    }
})
