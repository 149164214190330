<template>
    <div class="io-project-summary__nav">
        <a
            v-for="item in navElements"
            :key="item.text"
            :class="{'active': item.id === activeElement}"
            class="io-project-summary__nav-item"
            @click.prevent="setActiveElement(item)"
        >
            {{ $t(item.text) }}
        </a>
    </div>
</template>
<script lang="ts" src="./ProjectSummaryNav.ts"></script>
<style lang="scss" src="./ProjectSummaryNav.scss" scoped></style>
