<template>
    <div class="io-app-packages-invoices-filters">
        <div class="io-app-packages-invoices-filters__header">
            <span class="io-app-packages-invoices-filters__title">{{ $t('Filters') }}</span>
            <IOButton
                variant="link"
                :disabled="!isSelectedFilter"
                @click="clearFilters"
            >
                <span class="">{{ $t('Clear All') }}</span>
            </IOButton>
        </div>
        <div class="io-app-packages-invoices-filters__body">
            <div v-for="filter in invoicesFilters" :key="filter.field">
                <FilterCheckboxes
                    v-if="FilterType.Checkbox === filter.type"
                    :title="filter.label"
                    :options="filterOptions[filter.field]"
                    :max-length="10"
                    v-model="selectedFilters[filter.field]"
                />

                <FilterDateTimePicker
                    v-if="FilterType.Date === filter.type"
                    :key="filter.type"
                    :title="filter.label"
                    :options="datePickerOptions"
                    :show-icon="true"
                    v-model="selectedFilters[filter.field]"
                />

                <div v-if="FilterType.Multiselect === filter.type" class="io-app-packages-invoices-filters__vendor-filter">
                    <IOLabel :text="filter.label" :type="LabelType.BoldMax"/>
                    <MultiselectCheckbox
                        :data="filterOptions[filter.field]"
                        :disabled="!filterOptions[filter.field].length"
                        :settings="multiselectOptions"
                        v-model="selectedFilters[filter.field]"
                    >
                        <template #header="{ activeOptions, config }">
                            <div v-if="activeOptions" class="io-option io-option--active">
                                <div class=".io-option__title io-option-title--active">
                                    {{ activeOptions }}
                                </div>
                            </div>
                            <div v-else class="io-placeholder">{{ config.textPlaceholder }}</div>
                        </template>
                        <template #option="{ option }">
                            <Avatar
                                size="sm"
                                type="company"
                                :employee-mongo-id="option.logo || option.id"
                                :employee-full-name="option.name"
                                show-full-name
                            />
                        </template>
                    </MultiselectCheckbox>
                </div>

                <FilterStatuses
                    v-if="FilterType.Status === filter.type"
                    :title="filter.label"
                    :options="filterOptions[filter.field]"
                    :pills-color-function="pillsColorFunction"
                    :hide-select-all-status="true"
                    v-model="selectedFilters[filter.field]"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./AppPackageInvoicesFilters.ts"></script>
<style lang="scss" src="./AppPackageInvoicesFilters.scss" scoped></style>
