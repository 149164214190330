import * as actions from './actions'
import * as getters from './getters'

export default {
    namespaced: true,
    namespace: 'listingFilter/',
    state () {
        return {
            filters: {},
            pagination: false,
            page: false,
            column: false,
            search: '',
            isSearching: false
        }
    },
    actions,
    getters,
    mutations: {
        SET_FILTERS (state, payload) {
            state.filters[payload.filter] = payload.settings
        },
        SET_PAGE (state, value) {
            state.page = value
        },
        SET_COLUMN (state, value) {
            state.column = value
        },
        SET_PAGINATION (state, value) {
            state.pagination = value
        },
        SET_SEARCH (state, value) {
            state.search = value
        },
        RESET_FILTER (state, value) {
            delete state.filters[value]
        },
        RESET_FILTERS (state) {
            state.filters = {}
        },
        SET_ISSEARCHING (state, value) {
            state.isSearching = value
        }
    }
}
