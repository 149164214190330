import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys.ts'
import appTypes from '@/base/appTypes.ts'
import { defineComponent, PropType } from 'vue'
import { cloneDeep, uniqBy } from 'lodash'
import { InvitationStatuses } from '@/interfaces/components/invite-modal/InvitationStatuses.ts'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes.ts'
import commonContractMixin from '@/io-modules/project-contracts/mixins/commonContractMixin.ts'
import InviteModalV3 from '@/components/invite-modal-v3/InviteModalV3.vue'
import { inviteModalStore } from '@/components/invite-modal-v3/store/inviteModalStore.ts'
import { mapActions, mapState } from 'pinia'
import {
    Contact as ContactV2,
    InviteModalDonePayload,
} from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'
import { InviteModalTabs } from '@/components/invite-modal-v3/enums/InviteModalTabs.ts'
import InvitePreviewVerticalList
    from '@/components/invite-modal-v3/components/invite-preview-vertical-list/InvitePreviewVerticalList.vue'
import InvitePreviewHorizontalList
    from '@/components/invite-modal-v3/components/invite-preview-horizontal-list/InvitePreviewHorizontalList.vue'
import InvitePreviewContactListFull
    from '@/components/invite-modal-v3/components/invite-preview-contact-list-full/InvitePreviewContactListFull.vue'
import { ButtonSize } from '@/components/atoms/IOButton/IOButton.ts'

export default defineComponent({
    components: {
        InviteModalV3,
        InvitePreviewVerticalList,
        InvitePreviewHorizontalList,
        InvitePreviewContactListFull,
    },
    mixins: [commonContractMixin],
    props: {
        // Invite Modal V3 configurations...
        componentKey: { type: String, required: true },
        preselectedWorkspaceType: { type: String, default: '' },
        resourceId: { type: String, required: false, default: '' },
        modelType: { type: [String, null], required: true },
        section: { type: String, required: false, default: null },
        modalZIndex: { type: Number, required: false },
        customModalTitle: { type: String, required: false, default: '' },
        selectMultiple: { type: Boolean, required: false, default: true },
        showEmployeesOnly: { type: Boolean, required: false, default: false },
        initialSearchValue: { type: String, required: false, default: '' },
        allowedClientsTypes: { type: Array as PropType<appTypes[]>, default: null },
        allowedCompaniesIds: { type: Array as PropType<string[]>, required: false, default: null }, // allow to show contacts from selected companies, works only with "showContactsOnly: true"
        excludedCompaniesIds: { type: Array as PropType<string[]>, required: false, default: null },
        invitationName: { type: String, required: false },
        projectGlobalId: { type: String, required: false, default: null },
        filterByProjectGlobalId: { type: Boolean, required: false, default: false },
        bookmarkedContactPersons: {
            type: Array as () => Array<ContactV2>,
            required: false,
            default: () => [],
        },
        invitedContactPersons: {
            type: Array as () => Array<ContactV2>,
            required: false,
            default: () => [],
        },
        bookmarkedContactPersonIds: {
            type: Array as () => Array<string>,
            required: false,
            default: () => [],
        },
        invitedContactPersonIds: {
            type: Array as () => Array<string>,
            required: false,
            default: () => [],
        },
        categories: {
            type: Array as () => Array<InviteModalTabs>,
            required: false,
            default: () => [],
        },
        disableBookmarking: { type: Boolean, default: false },
        disableInviting: { type: Boolean, default: false },
        disableInviteRemoval: { type: Boolean, required: false, default: false },
        disableBookmarkRemoval: { type: Boolean, required: false, default: false },
        // dont allow to click "save" button without selected contacts
        requireSelectionToSave: {
            type: Boolean,
            required: false,
            default: false,
        },
        saveButtonCustomTitle: { type: String, required: false },
        searchInputCustomLabel: { type: String, required: false, default: null },
        includeCurrentUserOnSearchResults: { type: Boolean, required: false, default: false },

        // Preview type configurations...
        previewType: { type: Number as () => PreviewTypes, required: true },
        displayLimit: { type: Number, required: false, default: 3 },
        isInviteAllowed: { type: Boolean, required: false, default: true },
        usersBallInCourt: { type: Array as PropType<string[]>, required: false, default: () => [] },
        avatarRingChecker: { type: [Object, Function], required: false, default: null },
        avatarRingTooltip: { type: String, required: false, default: null },
        buttonLabelText: { type: String, default: '' },
        buttonIcon: { type: String, default: 'icon-plus' },
        buttonSize: { type: String as () => ButtonSize, default: ButtonSize.NORMAL },
        showSingleContactAvatar: { type: Boolean, default: false },
        showStatusForSingleContact: { type: Boolean, default: true },
        showAppTypeForSingleContact: { type: Boolean, default: false },
        isPreviewRemoveItemDisabled: { type: Boolean, default: false },
    },
    emits: [
        'close',
        'removeSelected',
        'setBallInCourt',
        'inviteModalDone',
        'setInitialBookmarkedList',
        'setInitialInvitedList',
    ],
    data () {
        return {
            PreviewTypes: PreviewTypes,
            InvitationStatuses: InvitationStatuses,
            showInviteModal: false,

            // Invite Modal V3
            invitedContactsList: [],
            bookmarkedContactsList: [],
        }
    },
    computed: {
        ...mapState(inviteModalStore, {
            invitedContacts (store) {
                this.invitedContactsList = store.invitedContacts(this.componentKey)
                return this.invitedContactsList
            },
            bookmarkedContacts (store) {
                this.bookmarkedContactsList = store.bookmarkedContacts(this.componentKey)
                return this.bookmarkedContactsList
            },
        }),
        currentUser () {
            return this.$store.getters['appStore/getAuthData']
        },
        selectedContactsList (): ContactV2[] {
            return [].concat(this.invitedContactsList).concat(this.bookmarkedContactsList)
        },
        selectedContactFullName (): string {
            if (!this.selectMultiple && this.selectedContactsList && this.selectedContactsList.length) {
                const selectedContact = this.selectedContactsList[0]

                return selectedContact.firstname ? `${ selectedContact.firstname } ${ selectedContact.lastname }` : selectedContact.company_name
            }
            return ''
        },
        firstSelectedContact (): string | null {
            const id = this.selectedContactsList[0].id
            return id ? id.toString() : null
        },
        isButtonDisplayed (): boolean {
            if (!this.selectMultiple) {
                return this.isInviteAllowed && this.selectedContactsList.length === 0
            }

            return this.isInviteAllowed
        },
        isLargeIcon (): boolean {
            return !this.selectMultiple
        },
    },
    watch: {
        bookmarkedContactPersons: {
            handler (newValue: ContactV2[]): void {
                this.bookmarkedContactsList = cloneDeep(newValue)
            },
            immediate: true,
            deep: true,
        },
        invitedContactPersons: {
            handler (newValue: ContactV2[]): void {
                this.invitedContactsList = cloneDeep(newValue)
            },
            immediate: true,
            deep: true,
        },
    },
    beforeMount () {
        if (this.componentKey === InviteModalClientKeys.PROJECT_WIZARD_EXECUTIVE) {
            this.setCurrentUserAsSelectedProjectExecutive()
        }
    },
    methods: {
        ...mapActions(inviteModalStore, [
            'setInitialInvitedList',
            'removeFromSelectedContacts',
        ]),
        setCurrentUserAsSelectedProjectExecutive (): void {
            const contact = {
                app_type: this.currentUser.app_type,
                company_id: this.currentUser.u_company_mongo_id,
                company_name: this.currentUser.u_company_name,
                email: this.currentUser.u_email,
                employee_id: this.currentUser.u_id,
                firstname: this.currentUser.u_firstname,
                id: this.currentUser.u_mongo,
                invitation_status: 'readyToSend',
                is_employee: true,
                is_pending: false,
                lastname: this.currentUser.u_lastname,
                company: null,
            } as Contact

            this.selectedContactsList.push(contact)
        },
        removeSelectedContactFromModal (contact: ContactV2): void {
            this.removeFromSelectedContacts(contact, this.componentKey)

            this.$emit('removeSelected', contact)
            this.$forceUpdate()
        },

        onSetInitialBookmarkedList (bookmarkedList: Array<ContactV2>): void {
            this.$emit('setInitialBookmarkedList', bookmarkedList)
        },

        onSetInitialInvitedList (invitedList: Array<ContactV2>): void {
            this.$emit('setInitialInvitedList', invitedList)
        },

        onCloseInviteModal (): void {
            this.showInviteModal = false

            this.$emit('close')
        },
        openInviteModal (): void {
            this.showInviteModal = true
        },
        isBallInCourt (id): boolean {
            return this.usersBallInCourt.includes(id)
        },
        onAvatarClick (id): void {
            this.$emit('setBallInCourt', id)
        },
        isArchivedOrPendingOrOffSystem (user): boolean {
            return !user.is_employee || (user.is_employee && user.is_archived) || (user.is_employee && user.is_pending)
        },
        onInviteModalDone (data: InviteModalDonePayload): void {
            this.invitedContactsList = data.invited
            this.bookmarkedContactsList = data.bookmarked

            this.$emit('inviteModalDone', data)
            this.onCloseInviteModal()
        },
    },
})
