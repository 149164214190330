<template>
    <AlertBox v-if="invoiceInternallyRejected && isSender" color="red" icon="icon-cross-circle">
        <template #default>
            {{ internallyRejectedInvoiceText }}
            <IOButton variant="link" size="small" @click="$emit('revertToDraft')">
                {{ $t('Revert to Draft') }}
            </IOButton>
        </template>
    </AlertBox>
    <AlertBox v-if="invoiceApprovedThroughAppPackage" color="yellow">
        <template #default>
            {{ $t('Invoice was approved through the application package') }}
            <IOButton variant="link" size="small" @click="goToAppPackage">
                {{ $t('View Application Package') }}
            </IOButton>
        </template>
    </AlertBox>
    <AlertBox v-if="invoiceRejectedThroughAppPackage" color="red" icon="icon-cross-circle">
        <template #default>
            {{ $t('Invoice was rejected through the application package') }}
            <IOButton variant="link" size="small" @click="goToAppPackage">
                {{ $t('View Application Package') }}
            </IOButton>
        </template>
    </AlertBox>
    <AlertBox v-if="invoiceVoided" color="red" icon="icon-cross-circle">
        <template #default>
            {{ $t('This invoice has been voided.') }}
            <IOButton v-if="voidInfo && voidInfo.note" variant="link" size="small" @click="toggleNotesModal">
                {{ $t('Show Note') }}
            </IOButton>
        </template>
    </AlertBox>

    <AlertBox v-if="invoiceRejectedByAccounting" color="red" icon="icon-cross-circle">
        <template #default>
            {{ $t('Invoice rejected by accounting, please revise & resubmit for approval.') }}
        </template>
    </AlertBox>

    <!--  Invoice: 7. Request Revision: Banners  -->

    <AlertBox v-if="isRequestedRevision && !revisionProvided && canAddressRevision && !needAttentionItemsTotal" color="yellow">
        <template #default>
            {{ $t('{clientName} has requested a revision of your invoice with note.', { clientName }) }}
            <span class="io-invoice-banners__link"
                  @click="toggleRevisionNoteModal"
            >
                {{ $t('Show Note') }}
            </span>
        </template>
    </AlertBox>

    <AlertBox v-if="showRevisionRequestedBanner">
        <template #default>
            {{ $t('You requested a revision from the vendor on behalf of the client. ') }}
            <IOButton variant="link" size="small" @click="toggleRevisionNoteModal">
                {{ $t('View Note') }}
            </IOButton>
        </template>
    </AlertBox>

    <template v-if="areActionsColumnsVisible && (needAttentionItemsTotal || clientAdjustedItemsTotal)">
        <AlertBox v-if="canClientReviseInvoice && !vendorRevisedRevisions || (vendorRevisedRevisions && clientAdjustedItemsTotal) " color="bg">
            <template #default>
                <div class="io-invoice-banners__content">
                    <div>
                        {{ clientAdjustedItemsTotal }} {{ $t('SoV Lines Adjusted') }}
                        {{ $t('You adjusted some SoV Lines. To send them to the vendor for revision click') }}
                        <IOButton variant="link" size="small" @click="$emit('sendRevision')">
                            {{ $t('Request Revision') }}
                        </IOButton>
                    </div>
                    <IOButton variant="link" size="small" @click="clearAllRevisionUpdates">
                        {{ $t('Clear All Your Updates') }}
                    </IOButton>
                </div>
            </template>
        </AlertBox>

        <LineProgress
            v-else-if="needAttentionItemsTotal && isVendorInvoice && (invIsNotApproved || needAttentionItemsTotal < needAttentionItemsCompleted) || (needAttentionItemsTotal && isMyInvoice)"
            :total-number="needAttentionItemsTotal"
            :completed-number="needAttentionItemsCompleted"
            :label="$t('Addressed SoV Lines')"
            icon="icon-info"
            class="io-invoice-banners__progress"
        >
            <template #content>
                <template v-if="needAttentionItemsTotal && needAttentionItemsTotal > needAttentionItemsCompleted">
                    <span v-if="isMyInvoice" class="io-invoice-banners__text">
                        {{ $t('The client requested revisions for highlighted SoV / WBS. Please address them before resubmitting.') }}
                    </span>

                    <span v-if="isVendorInvoice" class="io-invoice-banners__text">
                        {{ $t('Vendor adjusted one or more lines after your revisions. Please address them before resubmitting.') }}
                    </span>

                    <IOButton v-if="!isSoVTab" variant="link" size="small" @click="goToSoVTab">
                        {{ $t('Go to SoV / WBS Tab ') }}
                    </IOButton>
                </template>
                <template v-else-if="needAttentionItemsTotal && needAttentionItemsTotal == needAttentionItemsCompleted">
                    <span class="io-invoice-banners__text">
                        {{ $t('All highlighted SoV / WBS have been addressed.') }}
                    </span>
                </template>
            </template>
        </LineProgress>
    </template>

    <AlertBox v-else-if="requestRevisionText" color="red" icon="icon-cross-circle">
        <template #default>
            {{ requestRevisionText }}
            <IOButton variant="link" size="small" @click="toggleRequestRevisionModal">
                {{ $t('Request Revision') }}
            </IOButton>

            <template v-if="invoiceRevisionFeature">
                {{ ' ' }}{{ $t('or make necessary adjustments in the SoV lines.') }}
                <IOButton v-if="!isSoVTab" variant="link" size="small" @click="goToSoVTab">
                    {{ $t('Go to SoV / WBS Tab ') }}
                </IOButton>
            </template>
        </template>
    </AlertBox>

    <AlertBox v-if="showAccountingErrors" color="red" icon="icon-cross-circle">
        <template #default>
            <div v-if="multipleAccountingErrors">
                {{ $t('Some stuff needs your attention:') }}
                <ul>
                    <li v-for="(error, index) in accountingErrors.errors" :key="index">
                        {{ error.banner_message }}
                        <router-link :to="{name: error.banner_url_name, params: error.banner_url_params}">
                            {{ error.banner_link_text ?? $t('Learn More') }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div v-for="(error, index) in accountingErrors.errors" v-else :key="index">
                {{ error.banner_message }}
                <router-link :to="{name: error.banner_url_name, params: error.banner_url_params}">
                    {{ error.banner_link_text ?? $t('Learn More') }}
                </router-link>
            </div>
        </template>
    </AlertBox>

    <AlertBox v-if="showFillInvAmountWithTaxBanner" color="yellow">
        <template #default>
            {{ $t('Before proceeding with the approval please') }}
            <IOButton variant="link" size="small" @click="toggleInvAmountWithTaxModal">
                {{ $t('Fill Out the Invoice Amount Including Tax') }}
            </IOButton>
        </template>
    </AlertBox>

    <InvoiceRevisionNoteModal
        v-if="showRevisionNoteModal"
        :revision-info="getRevisionInfo"
        @close="toggleRevisionNoteModal"
    />

    <NotesModal
        v-if="showNotesModal"
        modal-icon-color="red"
        modal-icon="icon-cross-rec"
        :modal-title="$t('Invoice Voided')"
        :title="$t('This invoice was voided by')"
        :date="voidInfo.date"
        :note="voidInfo.note"
        :added-by="voidInfo.voided_by"
        @close="toggleNotesModal"
    />

    <InvoiceAmountWithTaxModal
        v-if="showInvAmountWithTaxModal"
        :value-with-tax="invAmountWithTax"
        @filledAmount="updateInvAmountWithTax"
        @close="toggleInvAmountWithTaxModal"
    />
</template>

<script lang="ts" src="./InvoiceBanners.ts"></script>
<style lang="scss" src="./InvoiceBanners.scss" scoped></style>
