import { ApiClient as client } from '@/api/api'
import ValidateBuildingAddressRequestInterface
    from '@/io-modules/buildings/interfaces/ValidateBuildingAddressRequestInterface.ts'
import ValidateBuildingAddressResponseInterface
    from '@/io-modules/buildings/interfaces/ValidateBuildingAddressResponseInterface.ts'
import { AxiosPromise } from 'axios'
import BuildingInterface from '@/io-modules/buildings/interfaces/BuildingInterface.ts'
import SaveBuildingResponseInterface from '@/io-modules/buildings/interfaces/SaveBuildingResponseInterface.ts'
import BuildingSubsiteInterface from '@/io-modules/buildings/interfaces/BuildingSubsiteInterface.ts'
import BuildingSubsitesInterface from '@/io-modules/buildings/interfaces/BuildingSubsitesInterface.ts'
import TenantsInterface from '@/io-modules/buildings/interfaces/TenantsInterface.ts'
import SaveTenantFormDataInterface from '@/io-modules/buildings/interfaces/SaveTenantFormDataInterface.ts'
import TenantDetailsInterface from '@/io-modules/buildings/interfaces/TenantDetailsInterface.ts'
import ManageTenantSubsitesRequestInterface
    from '@/io-modules/buildings/interfaces/ManageTenantSubsitesRequestInterface.ts'
import SubsiteDetailsInterface from '@/io-modules/buildings/interfaces/SubsiteDetailsInterface.ts'
import SubsiteDetailsRequestInterface
    from '@/io-modules/buildings/interfaces/requests/SubsiteDetailsRequestInterface.ts'
import SaveBuildingRequestPayloadInterface
    from '@/io-modules/buildings/interfaces/SaveBuildingRequestPayloadInterface.ts'
import { BuildingFilesDataInterface } from '@/io-modules/buildings/interfaces/BuildingFileInterface.ts'
import TimelineLogs from '@/interfaces/components/timeline/TimelineLogs.ts'
import { SubsiteFilesInterface } from '@/io-modules/buildings/interfaces/SubsiteFilesInterface.ts'
import { SubsitesPayloadRequestInterface } from '@/io-modules/buildings/interfaces/requests/SubsitesPayloadRequestInterface.ts'
import { CreateTenantRequestPayloadInterface } from '@/io-modules/buildings/interfaces/requests/CreateTenantRequestPayloadInterface.ts'
import ProjectSummaryResponseInterface from '@/io-modules/buildings/interfaces/ProjectSummaryResponseInterface.ts'
import SaveBuildingFormDataInterface from '@/io-modules/buildings/interfaces/SaveBuildingFormDataInterface.ts'
import { BuildingImportResponseInterface } from '@/io-modules/buildings/interfaces/BuildingImportResponseInterface.ts'
import { BuildingsExportPayloadInterface } from '@/io-modules/buildings/interfaces/BuildingsExportPayloadInterface.ts'

const buildingsClient = {
    createBuilding (building: SaveBuildingRequestPayloadInterface | SaveBuildingFormDataInterface): AxiosPromise<SaveBuildingResponseInterface> {
        return client.post('/buildings', building)
    },
    validateBuildingAddress (data: ValidateBuildingAddressRequestInterface): AxiosPromise<ValidateBuildingAddressResponseInterface> {
        return client.post('/buildings-validate-address', data)
    },
    fetchBuildingDetails (id: string): AxiosPromise<BuildingInterface> {
        return client.get(`/buildings/${ id }/details`)
    },
    editBuilding (buildingId: string, building: SaveBuildingRequestPayloadInterface | SaveBuildingFormDataInterface): AxiosPromise<SaveBuildingResponseInterface> {
        return client.put(`/buildings/${ buildingId }`, building)
    },
    createSubsite (buildingId: string, address: string): AxiosPromise<BuildingSubsiteInterface> {
        return client.post(`/buildings/${ buildingId }/subsites`, { address_2: address })
    },
    fetchSubsites (buildingId: string, payload: SubsitesPayloadRequestInterface): AxiosPromise<BuildingSubsitesInterface> {
        return client.post(`/buildings/${ buildingId }/subsites/list`, payload)
    },
    fetchTenants (buildingId: string): AxiosPromise<TenantsInterface> {
        return client.post(`/buildings/${ buildingId }/tenants/list`)
    },
    createTenant (buildingId: string, payload: CreateTenantRequestPayloadInterface): AxiosPromise<void> {
        return client.post(`/buildings/${ buildingId }/tenants`, payload)
    },
    fetchTenantDetails (buildingId: string, tenantId: string): AxiosPromise<TenantDetailsInterface> {
        return client.get(`/buildings/${ buildingId }/tenants/${ tenantId }/details`)
    },
    updateTenant (buildingId: string, tenantId: string, tenant: SaveTenantFormDataInterface): AxiosPromise<TenantDetailsInterface> {
        return client.put(`/buildings/${ buildingId }/tenants/${ tenantId }`, tenant)
    },
    removeTenant (buildingId: string, tenantId: string): AxiosPromise<void> {
        return client.delete(`/buildings/${ buildingId }/tenants/${ tenantId }`)
    },
    manageTenantSubsites (buildingId: string, tenantId: string, data: ManageTenantSubsitesRequestInterface): AxiosPromise<void> {
        return client.post(`/buildings/${ buildingId }/tenants/${ tenantId }/subsites`, data)
    },
    fetchProjectSummary (buildingId: string): AxiosPromise<ProjectSummaryResponseInterface> {
        return client.get(`/buildings/${ buildingId }/projects-summary`)
    },
    fetchSubsiteProjectSummary (buildingId: string, subsiteId: string): AxiosPromise<ProjectSummaryResponseInterface> {
        return client.get(`/buildings/${ buildingId }/subsites/${ subsiteId }/projects-summary`)
    },
    fetchSubsiteDetails (buildingId: string, subsiteId: string): AxiosPromise<SubsiteDetailsInterface> {
        return client.get(`/buildings/${ buildingId }/subsites/${ subsiteId }`)
    },
    updateSubsiteDetails (buildingId: string, subsiteId: string, data: SubsiteDetailsRequestInterface): AxiosPromise<SubsiteDetailsInterface> {
        return client.put(`/buildings/${ buildingId }/subsites/${ subsiteId }`, data)
    },
    fetchBuildingFiles (buildingId: string): AxiosPromise<BuildingFilesDataInterface> {
        return client.get(`/buildings/${ buildingId }/attachments`)
    },
    archiveBuilding (buildingId: string): AxiosPromise<void> {
        return client.post(`/buildings/${ buildingId }/archive`)
    },
    unarchiveBuilding (buildingId: string): AxiosPromise<void> {
        return client.post(`/buildings/${ buildingId }/unarchive`)
    },
    archiveSubsite (buildingId: string, subsiteId: string): AxiosPromise<void> {
        return client.post(`/buildings/${ buildingId }/subsites/${ subsiteId }/archive`)
    },
    unarchiveSubsite (buildingId: string, subsiteId: string): AxiosPromise<void> {
        return client.post(`/buildings/${ buildingId }/subsites/${ subsiteId }/unarchive`)
    },
    fetchBuildingTimeline (buildingId: string): AxiosPromise<TimelineLogs[]> {
        return client.get(`/buildings/${ buildingId }/timeline`)
    },
    fetchSubsiteFiles (buildingId: string, subsiteId: string): AxiosPromise<SubsiteFilesInterface> {
        return client.get(`/buildings/${ buildingId }/subsites/${ subsiteId }/attachments`)
    },
    fetchSubsiteTimeline (buildingId: string, subsiteId: string): AxiosPromise<TimelineLogs[]> {
        return client.get(`/buildings/${ buildingId }/subsites/${ subsiteId }/timeline`)
    },
    importBuilding (payload: { file_id: string }): AxiosPromise<BuildingImportResponseInterface> {
        return client.post('/buildings/import-excel', payload)
    },
    exportBuildings (payload: BuildingsExportPayloadInterface): AxiosPromise<Blob> {
        return client.post('buildings/export-excel', payload, { responseType: 'blob' })
    }
}

export default buildingsClient
