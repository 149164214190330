import { BallInCourtInterface } from '@/modules/projects/modules/apps/common/submittals-v2/interfaces/FileInterface.ts'
import { AttachedContractChange, SoVWBSItem, Source, StatusTimelineItem } from './ChangeOrderData.ts'
import ItemStatus from './ItemStatus.ts'
import appTypes from '@/base/appTypes.ts'
import ContractHolderType from '@/io-modules/project-contracts/enums/ContractHolderType.ts'
import { ScheduleImpactStatuses } from '@/modules/projects/modules/apps/common/contract-changes/change-orders/enums/ScheduleImpactStatuses.ts'
import { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'

export type SecondPartyContact = {
    id: string;
    name: string;
    company: string;
    first_name: string;
    last_name: string;
    app_type: appTypes;
    invitation_status: string;
    invitation_status_text: string;
    is_on_system: boolean;
}

export enum ContractChangeStageType {
    CHANGE_ORDER = 'change-order',
    AMENDMENT = 'amendment',
}

export  type Notification = {
    note: string;
    created_at: string;
    created_by_name: string;
    created_by_company_name: string;
}

type ContractChange = {
    id: string;
    unique_id: string;
    generated_id: string;
    name: string;
    status: ItemStatus;
    status_text: string;
    project_local_id: string;
    contract_id: string;
    status_timeline_items: StatusTimelineItem[];
    ball_in_court: Partial<BallInCourtInterface>[];
    second_party_id: string;
    second_party_contact: SecondPartyContact,
    type: string;
    type_text: string;
    schedule_impact_status: ScheduleImpactStatuses;
    schedule_impact_unit: string | null;
    schedule_impact_value: string | null;
    jobsite_location_ids: string[]
    contract_change_sources: Source[],
    document_ids: string[];
    attached_contract_changes: {
        change_orders: AttachedContractChange[];
        amendments: AttachedContractChange[];
    };
    items: SoVWBSItem[];
    description: string | null;
    total: number
    date: string | null;
    created_at: string;
    stage_type: ContractChangeStageType;
    edit_action: boolean;
    approvable_resource_id: string;
    creation_mode: ContractChangeCreationMode;
    agreement_document_ids: string[];
    notification: Notification;
    vendor: Person;
    is_digital: boolean;
    is_agreement_requested: boolean;
    is_shared: boolean;
    is_revision_addressed: boolean;
    project_sites: ProjectSite[];
}

export enum ContractChangeCreationMode {
    STANDARD = 'standard',
    FAST_TRACK = 'fast-track',
}

export type ContractChangeListItem = {
    id: string;
    generated_id: string;
    unique_id: string | null;
    contract_id: string;
    is_shared: boolean;
    title: string;
    document_date: string;
    type: string;
    status: ItemStatus;
    status_text: string;
    total: number;
    vendor: Person | null;
    ball_in_court: Partial<BallInCourtInterface>[];
    isSelected?: boolean;
    contractHolderType: ContractHolderType;
    contractHolderAppType: appTypes;
}

export type ContractChangeListResponse = {
    page: number,
    last_page: number,
    total: number,
    items: ContractChangeListItem[]
}

export interface Person {
    company_id: string;
    name: string;
    unique_id: string;
}

export interface NoteModalData {
    note: string;
    dateTime: string;
    person: string;
    title: string;
    icon: string;
    iconColor: string;
    description: string;
}

export interface ExecuteSlideoutData {
    id: string;
    is_agreement_requested: boolean;
    is_digital: boolean;
    status: string;
    is_my: boolean;
}

export default ContractChange
