<template>
    <div class="io-right-panel-markups">
        <div class="io-markups-header">
            <div class="io-markups-header__top">
                <SearchInput
                    :placeholder="$t('Search for markups...')"
                    :loader="false"
                    :startLength="1"
                    :width="260"
                    @searchChanged="searchValue = $event"
                />
                <IOButton
                    v-if="tabsDetails[markupsActiveTab].addButtonText"
                    variant="link"
                    icon="icon-comment-plus"
                    @click="onAddAnnotationClick"
                >
                    {{ tabsDetails[markupsActiveTab].addButtonText }}
                </IOButton>
            </div>
            <div class="io-markups-header__bottom">
                <ul>
                    <li
                        v-for="tab in tabs"
                        :key="tab"
                        :class="{ 'io-active': markupsActiveTab === tab }"
                        @mouseenter="hoveredTab = tab"
                        @mouseleave="hoveredTab = null"
                        @click="markupsActiveTab = tab"
                    >
                        <i :class="`icon-${ tabsDetails[tab].icon }`"/>
                        <span
                            class="io-tab-name"
                            :class="{ 'io-tab-name--active': markupsActiveTab === tab || hoveredTab === tab  }"
                        >
                            {{ tabsDetails[tab].title }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="io-right-panel-markups__content">
            <Transition name="fade" mode="out-in">
                <Component
                    :is="tabsDetails[markupsActiveTab].component"
                    :searched-value="searchValue"
                    @update-annotations="updateAnnotations"
                    @cancel-adding-annotation="cancelAddingAnnotation"
                    @select-annotation="selectAnnotation"
                    @delete-basic-annotation="deleteBasicAnnotation"
                />
            </Transition>
        </div>
    </div>
</template>

<script lang="ts" src="./MarkupsTab.ts"></script>
<style lang="scss" src="./MarkupsTab.scss" scoped></style>
