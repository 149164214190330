import { defineComponent, ref, toRaw, inject } from 'vue'

import budgetCostCodeClient from '@/components/budget-cost-code-select/BudgetCostCodeClient.ts'
import useLoader from '@/composables/useLoader.ts'
import { addIf } from '@/helpers/util.ts'
import { filterBudgetCostCodesCategories } from '@/io-modules/budget/utils/CategoriesUtilities.ts'

import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'
import type { Category, CostCode } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
    name: 'BudgetCostCodeSelect',

    props: {
        modelValue: {
            type: [String, Array],
            default: null,
        },

        budgetId: {
            type: String,
            default: '',
        },

        budgetType: {
            type: String as () => BudgetType,
            default: '',
        },

        projectLocalId: {
            type: String,
            default: '',
        },

        // Used for getting filtered cost codes by site
        siteId: {
            type: [String, null],
            default: null,
        },

        additionalFirstOption: {
            type: [Object as () => CostCode, Boolean],
            default: null,
        },

        errorMessage: {
            type: String,
            default: '',
        },

        valid: {
            type: Boolean,
            default: true,
        },

        providedCostCodeCategories: {
            type: Array as () => Category[],
            default: null,
        },

        enableMultiselect: Boolean,
        disabled: Boolean,
        id: String,
    },

    emits: ['update:modelValue'],

    setup () {
        const { loading, load } = useLoader()

        return {
            isNewCostCodeUIFlagEnabled: inject<boolean>('isNewCostCodeUIFlagEnabled', false),

            loading,
            load,

            searchKeyword: ref(''),
            costCodeCategories: ref<Category[]>(null),
            allSelected: ref(false),
        }
    },

    computed: {
        costCodeCategoriesOptions (): (Category | CostCode)[] {
            return [
                ...addIf(this.additionalFirstOption, this.additionalFirstOption),
                ...this.filteredCostCodeCategories
                    ?.flatMap(this.flattenCostCodeCategories)
                    .map(this.setCostCodeCategoryOptionSelectable)
                || [],
            ]
        },

        filteredCostCodeCategories (): Category[] {
            return this.searchKeyword
                ? filterBudgetCostCodesCategories<Category[]>(this.costCodeCategories, this.searchKeyword)
                : this.costCodeCategories
        },

        selectedOption (): CostCode | CostCode[] | null {
            const modelValue = this.modelValue as string | CostCode[]

            if (Array.isArray(modelValue)) {
                return this.costCodeCategoriesOptions.filter(item => modelValue.some(value => value.id === item.id)) as CostCode[]
            }

            return this.costCodeCategoriesOptions.find((item: Category | CostCode) =>
                item.id === this.modelValue) as CostCode || null
        },

        someItemsSelected (): boolean {
            const selectedItems = this.costCodeCategoriesOptions.filter(item => item.selected)

            return selectedItems.length && selectedItems.length !== this.costCodeCategoriesOptions.length
        },

        projectBudgetDashboardSummaryRoute (): RouteLocationRaw | undefined {
            if (!this.$route.params.pid) {
                return
            }

            return {
                name: 'project-budget-dashboard-summary',
                params: { budgetType: this.budgetType, budgetId: this.budgetId },
            }
        },
    },

    watch: {
        providedCostCodeCategories (): void {
            if (this.providedCostCodeCategories) {
                this.costCodeCategories = structuredClone(toRaw(this.providedCostCodeCategories))
                this.initSelectedOptions()
            }
        },

        siteId (): void {
            !this.providedCostCodeCategories && this.getCostCodes()
        },
    },

    created () {
        !this.providedCostCodeCategories && this.getCostCodes()
    },

    mounted () {
        if (this.providedCostCodeCategories) {
            this.costCodeCategories = structuredClone(toRaw(this.providedCostCodeCategories))
            this.initSelectedOptions()
        }
    },

    methods: {
        initSelectedOptions (): void {
            if (!this.enableMultiselect || !this.selectedOption) {
                return
            }

            if (Array.isArray(this.selectedOption) && !this.selectedOption.length) {
                return
            }

            this.costCodeCategoriesOptions.forEach(option => {
                if (Array.isArray(this.selectedOption) && this.selectedOption.some(item => item.id === option.id)) {
                    option.selected = true
                    this.updateParentOptions(option)
                }
            })
            this.allSelected = this.costCodeCategoriesOptions.every(item => item.selected)
        },

        getCostCodes (): void {
            this.budgetId
                ? this.getBudgetCostCodes()
                : this.budgetType && this.projectLocalId && this.getProjectCostCodes()
        },

        getBudgetCostCodes (): Promise<void> {
            return this.load(async () => {
                const { data } = await budgetCostCodeClient.getBudgetCostCodes(
                    this.budgetId, { siteId: this.siteId }
                )

                this.costCodeCategories = data.data

                this.initSelectedOptions()
            })
        },

        getProjectCostCodes (): Promise<void> {
            return this.load(async () => {
                const { data } = await budgetCostCodeClient.getProjectCostCodes(
                    this.projectLocalId, { budgetType: this.budgetType, siteId: this.siteId }
                )

                this.costCodeCategories = data.data

                this.initSelectedOptions()
            })
        },

        flattenCostCodeCategories (category: Category): (Category | CostCode)[] {
            return [
                category,
                ...category.subcategories?.flatMap(this.flattenCostCodeCategories) || [],
                ...(category.budget_cost_codes || [])
            ]
        },

        getDepth (initialCostCodeCategory: Category | CostCode, depth: number = 0): number {
            if (initialCostCodeCategory.id === this.additionalFirstOption?.id) {
                return 0
            }

            const costCodeType = 'budget_cost_code_category_id' in initialCostCodeCategory

            if (costCodeType) {
                const parentCategory = this.costCodeCategoriesOptions.find((costCodeCategory: Category | CostCode) =>
                    costCodeCategory.id === (initialCostCodeCategory as CostCode).budget_cost_code_category_id)

                return this.getDepth(parentCategory, depth + 1)
            }

            const parentCategory = this.costCodeCategoriesOptions.find((costCodeCategory: Category | CostCode) =>
                costCodeCategory.id === (initialCostCodeCategory as Category).parent_id)

            return parentCategory
                ? this.getDepth(parentCategory, depth + 1)
                : depth
        },

        isOptionUnselectable (option: Category | CostCode): boolean {
            if ('budget_cost_codes' in option) {
                return Boolean(
                    option.is_project_phase || option.subcategories?.length || option.budget_cost_codes?.length
                )
            }

            return false
        },

        isOptionCategory (option: Category): boolean {
            return Boolean(
                option.is_project_phase || option.subcategories?.length || option.budget_cost_codes?.length
            )
        },

        updateValue ($event: Event): void {
            if (!this.enableMultiselect) {
                this.$emit('update:modelValue', $event?.id || '', $event)
            }
        },

        hasNestedItemsSelected (option: Category): boolean {
            if (option.subcategories?.length) {
                const nestedItems = this.costCodeCategoriesOptions.filter(item => item.parent_id === option.id)
                const nestedItemsSelected = nestedItems.filter(item => item.selected)

                if (nestedItemsSelected.length) {
                    return nestedItemsSelected.length !== nestedItems.length
                } else {
                    for (const item of nestedItems) {
                        return this.hasNestedItemsSelected(item)
                    }
                }
            }

            if (option.budget_cost_codes?.length) {
                const nestedItems = this.costCodeCategoriesOptions.filter(item => item.budget_cost_code_category_id === option.id)
                const nestedItemsSelected = nestedItems.filter(item => item.selected)

                if (nestedItemsSelected.length) {
                    return nestedItemsSelected.length !== nestedItems.length
                } else {
                    for (const item of nestedItems) {
                        return this.hasNestedItemsSelected(item)
                    }
                }
            }

            return false
        },

        onCheckboxClick (option: Category): void {
            if (!this.enableMultiselect) {
                return
            }

            option.selected = !option.selected
            this.updateParentOptions(option)
            this.updateNestedOptions(option)
            this.allSelected = this.costCodeCategoriesOptions.every(item => item.selected)

            const costCodes = this.costCodeCategoriesOptions.filter(item => item.selected && item.budget_cost_code_category_id)

            this.$emit('update:modelValue', costCodes)
        },

        updateNestedOptions (option: Category): void {
            if (option.subcategories?.length) {
                this.costCodeCategoriesOptions.forEach(item => {
                    if (item.parent_id === option.id) {
                        item.selected = option.selected
                        this.updateNestedOptions(item)
                    }
                })
            }

            if (option.budget_cost_codes?.length) {
                this.costCodeCategoriesOptions.forEach(item => {
                    if (item.budget_cost_code_category_id === option.id) {
                        item.selected = option.selected
                        this.updateNestedOptions(item)
                    }
                })
            }
        },

        updateParentOptions (option: Category): void {
            if (option.parent_id) {
                this.costCodeCategoriesOptions.forEach(item => {
                    if (item.id === option.parent_id) {
                        item.selected = item.subcategories.every(subcategory => subcategory.selected)
                        this.updateParentOptions(item)
                    }
                })
            }

            if (option.budget_cost_code_category_id) {
                this.costCodeCategoriesOptions.forEach(item => {
                    if (item.id === option.budget_cost_code_category_id) {
                        item.selected = item.budget_cost_codes.every(costCode => costCode.selected)
                        this.updateParentOptions(item)
                    }
                })
            }
        },

        selectAll (): void {
            this.allSelected = !this.allSelected
            const costCodes = []
            this.costCodeCategoriesOptions.forEach(item => {
                item.selected = this.allSelected
                if (item.selected && 'budget_cost_code_category_id' in item && item.budget_cost_code_category_id) {
                    costCodes.push(item)
                }
            })

            this.$emit('update:modelValue', costCodes)
        },

        getSearchingLabel (option: Category | CostCode): string {
            return !this.isOptionUnselectable(option)
                && 'budget_cost_code_category_id' in option
                && option.code_name
        },

        getHighlightedText (option: Category | CostCode, searchKeyword: string): { text: string, match: boolean }[] {
            if (!searchKeyword) {
                return
            }

            const escapedSearchKeyword = searchKeyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
            const regex = new RegExp(`(${ escapedSearchKeyword })`, 'gi')
            const parts = option.code_name.split(regex)

            return parts.map(part => ({
                text: part,
                match: regex.test(part),
            }))
        },

        /**
         * The undocumented Multiselect option key `$isDisabled` is being used
         * to make the option unselectable via mouse and keyboard.
         * Otherwise, users may still select the option with keyboard arrow keys.
         * https://github.com/shentao/vue-multiselect/issues/176#issuecomment-307638151
         */
        setCostCodeCategoryOptionSelectable (costCodeCategoriesOption: Category | CostCode): Category | CostCode {
            return {
                ...costCodeCategoriesOption,
                $isDisabled: !this.enableMultiselect && this.isOptionUnselectable(costCodeCategoriesOption),
                selected: false,
            } as Category | CostCode
        },
    },
})
