import { createRouter, createWebHistory } from 'vue-router'

let routes = []

let loadDirectoryRoutes = (modules) => {
    for (const module in modules) {
        for (let i = 0; i < modules[module].length; i++) {
            routes.push(modules[module][i])
        }
    }
}

loadDirectoryRoutes(import.meta.glob('./modules/**/routes.js',  { import: 'default', eager: true }))
loadDirectoryRoutes(import.meta.glob('./io-modules/**/routes.@(js|ts)',  { import: 'default', eager: true }))

export default createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'io-is-active',
})
