const Register = () => import('./Register.vue')
const RegisterRejectInvitation = () => import('./RegisterRejectInvitation.vue')

export default [
    {
        path: '/register/',
        name: 'register',
        component: Register,
        meta: {
            skipAuth: true
        }
    },
    {
        path: '/register/reject-invitation/:invitationCode?',
        name: 'reject-invitation',
        component: RegisterRejectInvitation,
        meta: {
            skipAuth: true
        }
    }
]
