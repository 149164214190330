import { defineComponent } from 'vue'
import LazyGrid from '@/io-modules/drawings/components/lazy-grid/LazyGrid.vue'
import LazyGridItem from '@/io-modules/drawings/components/lazy-grid-item/LazyGridItem.vue'
import DrawingSheetsFilter from '@/io-modules/drawings/mixins/DrawingSheetsFilter'
import { mapGetters } from 'vuex'

export default defineComponent({
    components: {
        LazyGrid,
        LazyGridItem,
    },
    mixins: [DrawingSheetsFilter],
    data () {
        return {
            searchLocal: '',
            currentViewValue: 1,
        }
    },
    computed: {
        ...mapGetters('lazyList', {
            search: 'search',
        }),
        ...mapGetters('listingFilter', {
            getActiveSearch: 'getActiveSearch',
        }),
        ...mapGetters('drawingsV2', {
            getCurrentView: 'getCurrentView',
            activeDrawingSet: 'getActiveDrawingSet',
        }),
        endpoint (): string {
            return `/ca/drawing/lazy-list/${ this.activeDrawingSet ?? this.$route.params.setId }`
        },
    },
    watch: {
        async getActiveSearch (): Promise<void> {
            if (this.getCurrentView !== this.currentViewValue) {
                return
            }

            this.searchLocal = this.getActiveSearch

            await this.$store.dispatch('listingFilter/setSearch', this.searchLocal)

            if (this.searchLocal !== this.search) {
                await this.$store.dispatch('lazyList/setSearch', this.searchLocal)
            }
        },
    },
    async beforeMount () {
        await this.$store.dispatch('lazyList/resetEndpointParams')
        await this.$store.dispatch('lazyList/setWithFilters', true)
        await this.$store.dispatch('lazyList/setWithCounters', true)
    },
    activated (): void {
        this.$store.dispatch('lazyList/setShowFilters', false)
        this.$store.dispatch('listingFilter/setSearch', this.searchLocal)

        this.searchLocal = this.getActiveSearch
    },
})
