import menuLeftClient from '@/layouts/parts/menu-left/api-clients/menuLeftClient'
import { ProjectNavigationItem } from '@/layouts/parts/menu-left/interfaces/ProjectNavigationInterface'

export const updateProjectNavigation = async function ({ commit }, payload)  {
    const { data } = await menuLeftClient.getProjectNavigationItems(payload.projectId)

    data.forEach((item: ProjectNavigationItem, index: number) => {
        data[index]['isOpened'] = true
    })

    commit('SET_PROJECT_NAVIGATION_ITEMS', data)
}
