import { defineComponent } from 'vue'
import DocumentFileViewer from '@/io-modules/documents/components/document-file-viewer/DocumentFileViewer'
import AlertBox from '@/components/atoms/AlertBox/AlertBox'
import RightPanel from '@/io-modules/documents/components/right-panel/RightPanel'
import documentsClient from '@/io-modules/documents/api-clients/documentsClient'
import DocumentInterface from '@/io-modules/documents/interfaces/DocumentInterface'
import { mapActions, mapState } from 'pinia'
import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'
import { rightPanelTabsStore } from '@/io-modules/documents/store/rightPanelStore'
import DocumentRenameModal from '@/io-modules/documents/components/document-rename-modal/DocumentRenameModal.vue'
import DocumentDownloadModal from '@/io-modules/documents/components/document-download-modal/DocumentDownloadModal.vue'
import { RouteLocationRaw } from 'vue-router'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'
import { mapGetters as mapGettersVuex, mapState as mapStateVuex } from 'vuex'
import { capitalize, cloneDeep, uniqBy } from 'lodash'
import DocumentsModelType from '@/constants/Documents/DocumentsModelType'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes.ts'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys.ts'
import synchronizeInvitationMixin from '@/components/invite-modal-v3/mixins/synchronizeInvitationMixin.ts'
import { InvitationModelTypes } from '@/interfaces/components/invite-modal/InviteModalModelTypes.ts'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import { InviteModalDonePayload } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'
import { AxiosError } from 'axios'

export default defineComponent({
    name: 'DocumentsShow',
    components: {
        InvitePreview,
        RightPanel,
        AlertBox,
        DocumentFileViewer,
        DropdownButtonNew,
        DocumentRenameModal,
        DocumentDownloadModal,
    },
    provide () {
        return {
            pid: this.pid,
            id: this.id,
        }
    },
    props: {
        pid: {
            type: String,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        viewOnly: {
            type: Boolean,
            default: false,
        },
        noHeader: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [breadcrumbsMixin, synchronizeInvitationMixin],
    data () {
        return {
            documentUrl: null,
            document: null as DocumentInterface,
            disabledElementsByDefault: [
                'stickyToolGroupButton',
                'freeHandToolGroupButton',
                'freeHandHighlightToolGroupButton',
                'signatureToolGroupButton',
                'toolbarGroup-Edit',
                'notesPanel',
                'toggleNotesButton',
                'dropdown-item-toolbarGroup-Forms',
                'toolbarGroup-Forms'
            ],
            renameModalShow: false,
            downloadModalShow: false,
            previousRoute: {},
            isOrignalFileFolderForbidden: false,
            invitationClientKey: InviteModalClientKeys.DOCUMENT_FILE,
            previewTypes: PreviewTypes.HIDDEN,
            modelType: InvitationModelTypes.DOCUMENT,
        }
    },
    computed: {
        ...mapGettersVuex('appStore', ['getAuthData']),
        ...mapState(viewDocumentStore, [
            'typeOfAnnotationBeingAdded',
            'isAnnotationBeingAdded',
            'isAnnotationInputVisible'
        ]),
        ...mapStateVuex('filesComponent', {
            foldersBreadcrumbs: (state) => state.foldersBreadcrumbs,
        }),
        disabledElements (): Array<string> {
            const additionalDisabledElements = !this.viewOnly ? [] : [
                'toolsHeader',
                'toolbarGroup-View',
                'toolbarGroup-Shapes',
                'toolbarGroup-Edit',
                'toolbarGroup-Insert',
                'toolbarGroup-Annotate',
                'toolbarGroup-FillAndSign',
                'toolbarGroup-Measure',
            ]

            return [...this.disabledElementsByDefault, ...additionalDisabledElements]
        },
        pageTitle (): string {
            return this.document?.file_name || ''
        },
        showAlertBox (): boolean {
            return !localStorage.getItem('documents-alert-box') && !this.viewOnly
        },
        isCommentBeingAdded (): boolean {
            return this.isAnnotationBeingAdded
                && this.typeOfAnnotationBeingAdded === AnnotationTypes.COMMENT
                && !this.isAnnotationInputVisible
        },
        routeBack (): RouteLocationRaw {
            if (!this.document) {
                return ''
            }

            const folderId = this.isOrignalFileFolderForbidden ? null : this.document.folder_mongo_id

            return this.isProjectDocumentView
                ? {
                    name: 'project-documents-list',
                    params: { pid: this.pid, folderId },
                }
                : { name: 'documents-list', params: { folderId } }
        },
        handleBackClick (): Function | undefined {
            // add here route name if you need to go back to previous page and not to docs page
            const routeNamesToCheck = [ 'payapps', 'contract-v3' ]

            return routeNamesToCheck.some(routeName => {
                return window.history?.state?.back?.includes(routeName) || this.$router.options.history.state.back?.includes(routeName)
            }) ? this.$router.back : undefined
        },
        isProjectDocumentView (): boolean {
            return this.$route.name === 'project-documents-show'
        },
        fileBelongsToCurrentWorkspace (): boolean {
            return this.getAuthData.company_mongo_id === this.document.company_mongo_id
        },
        fileBelongsToCurrentUser (): boolean {
            return this.getAuthData.u_mongo === this.document.creator_mongo_id
        },
        isJobsiteDocument (): boolean {
            return this.document?.model_type === DocumentsModelType.JOBSITE_LOCATION
        },
        fileExtension (): string {
            return this.document?.file_name.split('.').pop()
        }
    },
    async beforeMount (): Promise<void> {
        this.documentUrl = this.$api.getApi2RequestUrl(`/document/${ this.id }/preview`)
        await this.getDocument()
        await this.getDocumentAnnotations(true)

        await this.initFolderData()
        this.initDocumentBreadcrumbs()
    },
    methods: {
        ...mapActions(viewDocumentStore, {
            triggerAddingAnnotationEvent: 'triggerAddingAnnotationEvent',
            setAnnotations: 'setAnnotations',
            setCounters: 'setCounters'
        }),
        ...mapActions(rightPanelTabsStore, {
            setMarkupsActiveTabByAnnotationType: 'setMarkupsActiveTabByAnnotationType',
        }),

        goBack (): void {
            this.$route.name === 'project-documents-show' ?
                this.$router.push({
                    name: 'project-documents-list',
                    params: { pid: this.pid, folderId: this.document.folder_mongo_id },
                }) :
                this.$router.push({ name: 'documents-list', params: { folderId: this.document.folder_mongo_id } })
        },
        async getDocument (): Promise<void> {
            this.setLoadingBar(true)

            try {
                const { data: { data } } = await documentsClient.fetchDocument(this.id)
                this.document = data
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async getDocumentAnnotations (initialRender: boolean = false): Promise<void> {
            this.setLoadingBar(true)

            try {
                const { data } = await documentsClient.fetchDocumentAnnotations(this.id)
                this.setAnnotations(data.items)
                this.setCounters(data.counters)
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
                if (!initialRender) {
                    this.deselectAnnotations()
                }
            }
        },
        async rename (name: string): Promise<void> {
            this.setLoadingBar(true)

            try {
                await documentsClient.renameDocument(this.id, name)
                this.document.file_name = name
                this.renameModalShow = false
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        download (data: { includeMarkups: boolean, id: string }): void {
            const url = `/document/${ data.id }/download?with_annotations=${ Number(data.includeMarkups) }`
            window.open(this.$api.getApi2RequestUrl(url), '_blank', 'noopener')
            this.downloadModalShow = false
        },
        deselectAnnotations (): void {
            // @ts-ignore
            this.$refs.documentFileViewer.deselectAllAnnotations()
        },
        enableAddingAnnotationMode (annotationType: AnnotationTypes): void {
            this.triggerAddingAnnotationEvent(annotationType)

            this.setMarkupsActiveTabByAnnotationType(annotationType)
        },
        rememberChoice (): void {
            localStorage.setItem('documents-alert-box', 'true')
        },
        selectAnnotation (uuid: string): void {
            // @ts-ignore
            this.$refs.documentFileViewer.selectAnnotation(uuid)
        },
        isFolderForbidden (error: AxiosError): boolean {
            return [404, 403].includes(error.response?.status)
        },
        async deleteBasicAnnotation (data: { document_id: string, annotation_id: string }): Promise<void> {
            // @ts-ignore
            await this.$refs.documentFileViewer.deleteBasicAnnotation(data.document_id, data.annotation_id)
            await this.getDocumentAnnotations()
        },
        async initFolderData (): Promise<void> {
            try {
                const { data } = await documentsClient.fetchDocumentFolder(
                    this.document.folder_mongo_id,
                    {
                        take: 0, //take 0 to get breadcrumbs only
                    }
                )

                if (data.breadcrumb?.length) {
                    await this.$store.dispatch('filesComponent/setFolderBreadcrumb', data.breadcrumb)
                }
            } catch (error) {
                if (this.isFolderForbidden(error)) {
                    this.isOrignalFileFolderForbidden = true
                }
            }
        },
        initDocumentBreadcrumbs (): void {
            const isFromContractsV3 = window.history?.state?.back?.includes('contract-v3') || this.$router.options.history.state.back?.includes('contract-v3')

            if (this.noHeader || isFromContractsV3) {
                return
            }

            let routeName = 'documents-list'
            let params = {}
            let breadcrumbs = uniqBy(cloneDeep(this.foldersBreadcrumbs), 'name')
            if (this.isProjectDocumentView) {
                routeName = 'project-documents-list'
                params = { pid: this.pid }
                breadcrumbs = breadcrumbs.slice(1)
            }

            breadcrumbs = breadcrumbs.map((item: { name: string, folder_id: string }, index: number) => {
                const text = item.name?.replace('trash', 'Archive')
                return {
                    text: index === 0 ? capitalize(text) : text,
                    to: { name: routeName, params: { ...params, folderId: item.folder_id } }
                }
            })

            this.setBreadcrumbs([
                ...breadcrumbs,
                {
                    text: this.document.file_name,
                    to: {
                        name: routeName,
                        params: { folderId: this.document.folder_mongo_id },
                    },
                },
            ])
        },

        onClickSharingAndPermissions (): void {
            this.$refs.invitePreview.openInviteModal()
        },

        async updateInviteModalData (payload: InviteModalDonePayload): Promise<void> {
            try {
                this.setLoadingBar(true)
                await this.synchronizeInvitation(
                    payload,
                    'You have been invited to collaborate on a Document',
                    null
                )
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
    },
})
