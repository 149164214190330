/**
 * @component
 * @name StepSlideout
 * @description
 * StepSlideout component is used to display a list of options that can be selected by the user.
 *
 * It used in the step-by-step flow.
 *
 * You can specify the options that will be displayed in the slideout by passing the selectOptions prop.
 *
 * If you need custom content in the step of StepSlideout, you can use the hasSlot flag for the item and provide the template for that item.
 *
 * @example
 * <StepSlideout :selectOptions="selectOptions" @flowCompleted="onFlowCompleted" />
 * or with slot
 * <StepSlideout :selectOptions="selectOptions" @flowCompleted="onFlowCompleted">
 *     <template #stepSlideoutItem>
 *         Your content
 *     </template>
 * </StepSlideout>
 */
import { defineComponent,CSSProperties } from 'vue'
import { cloneDeep } from 'lodash'
import OptionsGroup, { OptionsGroupItem, RadioOption } from '@/components/step-slideout/interfaces/OptionsGroup.ts'
import { TStepSlideoutData } from './interfaces/TStepSlideoutData'
import { AvatarType } from '@/constants/AvatarConstants'
import InfoBox from '@/components/info-box/InfoBox.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'

export default defineComponent({
    name: 'StepSlideout',
    components: {
        InfoBox,
        StatusPill,
    },
    props: {
        selectOptions: {
            type: Object as () => OptionsGroup,
            required: true,
        },
    },
    emits: {
        flowCompleted: (selections: OptionsGroup[], isPartially: boolean = false) => !!selections.length,
        flowRestarted: () => true,
    },
    setup () {
        return { AvatarType }
    },
    data (): TStepSlideoutData {
        return {
            selections: [] as OptionsGroupItem[],
            currentStep: 0 as number,
            isRendered: false,
            flowCompleted: false,
        }
    },
    computed: {
        additionalBlockHeight (): number {
            if (!(this.$refs[`stepSlideoutItem${ this.currentStep }`] as HTMLElement[])?.length) {
                return 0
            }
            const currentStepSlideoutItem = this.$refs[`stepSlideoutItem${ this.currentStep }`][0] as HTMLElement
            const currentStepSlideoutItemHeight = currentStepSlideoutItem.offsetHeight
            return (this.$refs.scrollableContent as HTMLElement).offsetHeight - currentStepSlideoutItemHeight - 16
        },
        additionalBlockStyle (): CSSProperties {
            if (this.$refs[`stepSlideoutItem${ this.currentStep }`] === undefined) {
                return {}
            }
            return {
                height: `${ this.additionalBlockHeight }px`,
            }
        },
        filteredOptions (): RadioOption[] {
            return
        },
    },
    watch: {
        async isRendered (): Promise<void> {
            await this.$nextTick(() => {
                const container = this.$refs.scrollableContent as HTMLElement
                const lastElem = container.lastElementChild as HTMLElement
                if (lastElem) {
                    lastElem.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                    })
                }
            })
        },
        selectOptions (): void {
            this.selections = []
            if (this.selectOptions) {
                this.selections.push(new OptionsGroupItem(cloneDeep(this.selectOptions)))
            }
        },
    },
    mounted () {
        if (this.selectOptions) {
            this.selections.push(new OptionsGroupItem(cloneDeep(this.selectOptions)))
        }
        this.isRendered = true
    },
    methods: {
        async selectOption (option: RadioOption, index: number): Promise<void> {
            this.isRendered = false
            if (this.flowCompleted) {
                this.flowCompleted = false
                this.$emit('flowRestarted')
            }
            let newIndex = index
            if (index < this.currentStep) {
                this.selections.splice(index + 1, this.selections.length - index - 1)
                newIndex = index
            }
            if (option.nextStep && !option.canComplete) {
                this.selections.push(new OptionsGroupItem(cloneDeep({
                    ...option.nextStep,
                    key: option.value,
                })))
                newIndex += 1
            }
            await this.$nextTick(() => {
                this.currentStep = newIndex
                this.isRendered = true
                if (option.nextStep === undefined || option.canComplete) {
                    this.$emit('flowCompleted', this.selections, false)
                    this.flowCompleted = true
                }
                if (option.nextStep && option.nextStep.hasSlot) {
                    this.$emit('flowCompleted', this.selections, true)
                    this.flowCompleted = true
                }
            })
        },
    },
})
