import PopupModal from '@/components/popup-modal/PopupModal.vue'
import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue'
import { Tab } from '@/components/atoms/tabs/TabInterface'
import { defineComponent, PropType } from 'vue'
import { mapState as mapStateVuex } from 'vuex'
import qs from '@/helpers/qs'

export default defineComponent({
    name: 'ExportToExcelModal',
    components: {
        PopupModal,
        Checkbox,
    },
    props: {
        tabs: Array as PropType<Tab[]>
    },
    emits: ['close'],
    data () {
        return {
            items: [],
        }
    },
    computed: {
        ...mapStateVuex('project', ['projectObj']),

        hasCheckedItems (): boolean {
            return this.items.some(item => item.checked)
        },
    },
    mounted () {
        this.items = this.tabs.map(item => {
            return { ...item, checked: false }
        })
    },
    methods: {
        exportToExcel (): void {
            const types = this.items.filter(item => item.checked).map(item => item.key)
            window.open(`${ this.apiUrl }/invoices/${ this.projectObj.project_local_id }/export?${ qs.stringify({ types }) }`)
            this.$emit('close')
        },
    }
})