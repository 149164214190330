import { defineComponent } from 'vue'
import { mapState } from 'vuex'

import { ProjectNavigationItem, ProjectNavigationChildItem } from '../../interfaces/ProjectNavigationInterface'

export default defineComponent({
    name: 'ProjectNavigation',
    props: {
        projectSearchValue: { type: String, required: true }
    },
    data () {
        return {
            defaultSectionKeyToExpand: 'project_financials'
        }
    },
    computed: {
        ...mapState('main', {
            openLeftMenuStatus: (state: any) => state.openLeftMenu
        }),
        ...mapState('project', {
            projectObj: (state: any) => state.projectObj
        }),
        ...mapState('projectNavigation', {
            menuItems: (state: any) => state.menuItems
        }),
        currentProjectId (): string {
            //@ts-ignore
            return this.$route.params.pid
        },
        projectSearchValueLowerCase (): string {
            return this.projectSearchValue.toLowerCase()
        },
        isSearchInProgress (): boolean {
            return !!this.projectSearchValue.length
        },
        isEmptyMenuItemsList (): boolean {
            const items: ProjectNavigationItem[] = [ ...this.menuItems ]

            const filteredItems = items.length && items.filter((item: ProjectNavigationItem) => {
                if (!item.children) {
                    return this.isSingleItemVisible(item)
                }

                return this.isItemVisible(item)
            })

            return !!this.menuItems.length && !filteredItems?.length
        }
    },
    watch: {
        'isSearchInProgress' (value) {
            value ? this.onAllSubmenusToggle(true) : this.setActiveMenuItem()
        },
        '$route.path' () {
            this.setActiveMenuItem()
        },
        async 'currentProjectId' (value, oldValue) {
            if (!value) {
                return
            }

            if (!!oldValue) {
                await this.updateProjectNavigation()
            }
        },
    },
    beforeMount () {
        this.getProjectNavigation()

        this.openLeftMenuStatus && this.$store.dispatch('main/setIsLeftMenuWider', true)
    },
    beforeUnmount () {
        this.$store.dispatch('main/setIsLeftMenuWider', false)
    },
    methods: {
        async updateProjectNavigation (): Promise<void> {
            await this.$store.dispatch('projectNavigation/updateProjectNavigation', {
                projectId: this.currentProjectId
            })
        },
        async getProjectNavigation (): Promise<void> {
            await this.updateProjectNavigation()

            this.setActiveMenuItem()
        },
        onSetMenuItemOpened (index: number): void {
            const currentItem: ProjectNavigationItem = this.menuItems[index]
            const isOpened = currentItem.isOpened

            this.menuItems[index]['isOpened'] = !isOpened
        },
        onAllSubmenusToggle (isOpened: boolean): void {
            this.menuItems.forEach((item: ProjectNavigationItem, index: number) => {
                this.menuItems[index]['isOpened'] = isOpened
            })
        },
        setActiveMenuItem (): void {
            this.menuItems.forEach((item: ProjectNavigationItem, index: number) => {
                const isOpened = !!item.children?.length
                    && item.children.some((childItem: ProjectNavigationChildItem) => {
                        return this.isSubItemActive(childItem.key)
                    })

                // only mark as opened never hide
                if (false !== isOpened) {
                    this.menuItems[index]['isOpened'] = isOpened
                }
            })
        },
        isSubItemActive (key: string): boolean {
            return key === this.$route.meta.navigationKey || (key === 'project_business_summary' ? 'project_summary' === this.$route.meta.navigationKey : false)
        },
        isSingleItemVisible (item: ProjectNavigationItem): boolean {
            return !item.children && this.$t(item.title).toLowerCase().includes(this.projectSearchValueLowerCase)
        },
        isSubItemVisible (childItem: ProjectNavigationChildItem): boolean {
            return this.$t(childItem.title).toLowerCase().includes(this.projectSearchValueLowerCase)
        },
        isItemVisible (item: ProjectNavigationItem): boolean {
            if (!item.children) {
                return false
            }

            const childrenItemsFiltered: ProjectNavigationChildItem[] = item.children.filter((childItem: ProjectNavigationChildItem) => {
                return this.isSubItemVisible(childItem)
            })

            return !!childrenItemsFiltered.length
        }
    }
})
