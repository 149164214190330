<template>
    <div class="io-selected-jobsite-locations" :class="{ 'io-is-modal-view': isModalView }" data-testid="selected-jobsite-locations">
        <div
            v-for="(location, groupKey) in locationsGroupTree"
            :key="groupKey"
            class="io-jobsite-location"
            data-testid="jobsite-location"
        >
            <div class="io-jobsite-location__main-location">
                <div class="io-main-location__icon"><span class="icon-pin-3"></span></div>
                <div class="io-main-location__text">{{ location.title }}</div>

                <IOButton v-if="isEditable" variant="plain-icon" size="small" icon="icon-cross" @click="removeAllJobsiteLocations(location)"/>

                <IOButton
                    v-if="isSkinnyMode"
                    variant="link"
                    icon-position="right"
                    size="small"
                    :icon="collapsedRowsIds.includes(location.id) ? 'icon-chevron-down' : 'icon-chevron-up'"
                    @click="toggleJobsiteLocations(location.id)"
                >
                    {{ collapsedRowsIds.includes(location.id) ? $t('Show more') : $t('Show less') }}
                </IOButton>
            </div>

            <Transition name="fade-from-top">
                <div v-if="!collapsedRowsIds.includes(location.id) && !!location.children?.length">
                    <template
                        v-for="(children, index) in location.children"
                        :key="index"
                    >
                        <div
                            v-if="!!children?.length"
                            :class="{'io-last-child': location.children.length - 1 === index }"
                            class="io-jobsite-location__paths"
                        >
                            <div
                                v-for="(child, index) in children"
                                :key="child.id"
                                :class="{'io-path--last': index === children.length - 1}"
                                class="io-path"
                            >
                                <span>
                                    {{ child.title }}
                                    <template v-if="child.status === JobsiteLocationStatusEnum.ARCHIVED">({{ $t('Archived') }})</template>
                                </span>

                                <IOButton v-if="isEditable && children.length - 1 === index" variant="plain-icon" size="small" icon="icon-cross" @click="removeJobsiteLocation(child.id)"/>
                            </div>
                        </div>
                    </template>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script lang="ts" src="./SelectedJobsiteLocations.ts"></script>
<style lang="scss" src="./SelectedJobsiteLocations.scss" scoped></style>
