import { defineComponent, PropType } from 'vue'
import { invoiceViewStore } from '../../../../stores/invoiceView'
import { mapState } from 'pinia'
import RightModal from '@/components/RightModal/RightModal.vue'
import FilesComponent from '@/components/files-new/files.vue'
import ContentTwoSidesTemplate from '@/components/content-two-sides-template/ContentTwoSidesTemplate.vue'
import LienWaiverPaymentType from '../../../../enums/lien-waivers/LienWaiverPaymentType'
import LienWaiverType from '../../../../enums/lien-waivers/LienWaiverType'
import LienWaiver from '../../../../interfaces/lien-waivers/LienWaiver'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import documentMixin from '@/modules/documents/details/mixins/document'

export default defineComponent({
    name: 'AttachLienWaiverModal',
    components: {
        AlertBox,
        RightModal,
        ContentTwoSidesTemplate,
        FilesComponent,
    },
    props: {
        itemToEdit: {
            type: Object as PropType<LienWaiver>,
            default: null,
        },
    },
    mixins: [documentMixin],
    emits: ['close', 'updateItem'],
    data () {
        return {
            LienWaiverPaymentType,
            LienWaiverType,
            amount: 0,
            type: '',
            paymentType: '',
            file: null,
            filesArrayId: 'lien_waiver_documents'
        }
    },
    computed: {
        ...mapState(invoiceViewStore, ['invoice', 'isMyInvoice', 'isVendorInvoice', 'invoiceLienWaivers','lienWaiverTemplate']),

        isValidForm (): boolean {
            return this.amount && this.type && this.paymentType && this.file
        },

        clientName (): string {
            return this.isMyInvoice ? this.invoice?.client_approval?.client?.name : this.isVendorInvoice ? this.invoice?.creator?.name : ''
        }
    },
    beforeMount (): void {
        const files = this.itemToEdit && this.itemToEdit.attached_document_ids ? this.itemToEdit.attached_document_ids.map(item => {
            return { ...item.document, ...item }
        }) : null
        this.$store.dispatch('filesComponent/setMyDocs', {
            arrayId: this.filesArrayId,
            files: this.itemToEdit ? files || this.itemToEdit.attached_documents : [],
        })
    },
    created () {
        if (this.itemToEdit) {
            this.amount = this.itemToEdit.amount
            this.paymentType = this.itemToEdit.payment_type
            this.type = this.itemToEdit.type
            this.file = this.itemToEdit.attached_documents?.length ? this.itemToEdit.attached_documents[0] : this.itemToEdit.attached_document_ids[0]
        }
    },
    methods: {
        onFileAdd (file: any): void {
            this.file = file
        },
        onFileRemove (fileId: string): void {
            this.file = null
        },
        attach (): void {
            const data = {
                type: this.type,
                payment_type: this.paymentType,
                amount: Number(this.amount).toFixed(2),
                attached_document_ids: [this.file],
                status: 'attached',
            }
            if (!this.itemToEdit) {
                this.invoiceLienWaivers.push(data)
            } else {
                this.$emit('updateItem', data)
            }

            this.$emit('close')
        },

        downloadLienWaiverTemplate (): void {
            try {
                this.downloadDocument(this.lienWaiverTemplate)
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        }
    }
})
