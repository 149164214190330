import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'

export default defineComponent({
    components: {
        PopupModal,
    },
    props: {
        editingExistingStamp: { type: Boolean, required: false, default: false },
        editingStampInfo: { type: Object as () => any, required: false, default: null },
        projectId: { type: String, default: null },
    },
    data () {
        return {
            stamp: {
                code: '',
                name: '',
            },
            colors: [
                '#60D094',
                '#1952E1',
                '#FB6A00',
                '#EF4343',
                '#8D43EF',
                '#1484DE',
                '#0C767E',
            ],
            selectedColor: '#1952E1',
        }
    },
    computed: {
        isValid (): boolean {
            return !!this.stamp.code && !!this.stamp.name
        },
    },
    beforeMount () {
        if (this.editingExistingStamp) {
            this.stamp.name = this.editingStampInfo.name
            this.stamp.code = this.editingStampInfo.code
            this.selectedColor = this.editingStampInfo.color
        }
    },
    methods: {
        setColor (color: string): void {
            if (this.editingExistingStamp && !this.editingStampInfo?.can_edit) {
                return
            }
            this.selectedColor = color
        },
        close (): void {
            this.$emit('close')
        },
        async addNewStamp (): Promise<void> {
            this.setLoadingBar(true)

            try {
                const postData = {
                    project_id: this.$route.params.pid,
                    name: this.stamp.name,
                    code: this.stamp.code.toUpperCase(),
                    color: this.selectedColor,
                }

                await this.$api.post(`stamps/save/${ this.$route.params.pid ?? this.projectId }`, postData)
                this.showNotification('success', this.$t('Stamp added'))
            } catch (e) {
                this.showNotification('error', this.$t('Error updating stamp'))
            } finally {
                this.setLoadingBar(false)
                this.close()
            }

        },
        async updateStamp (): Promise<void> {
            this.setLoadingBar(true)

            try {
                const postData = {
                    project_id: this.$route.params.pid,
                    name: this.stamp.name,
                    code: this.stamp.code.toUpperCase(),
                    color: this.selectedColor,
                    id: this.editingStampInfo.id,
                }

                await this.$api.post(`stamps/update/${ this.$route.params.pid ?? this.projectId }`, postData)
                this.showNotification('success', this.$t('Stamp info updated'))
                this.close()
            } catch (e) {
                this.showNotification('error', this.$t('Error updating stamp'))
            } finally {
                this.setLoadingBar(false)
            }
        },
    },
})
