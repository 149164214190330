<template>
    <IOModal
        class="io-lien-waiver-preview"
        fullscreen
        widest
    >
        <template #header-start>
            <div class="io-header-start">
                <div
                    class="io-header-back"
                    @click="$emit('close')"
                >
                    <span class="icon-chevron-left"></span>
                </div>
                <div class="io-header-title">{{ $t('Lien Waiver') }}</div>
            </div>
        </template>
        <template #header-action>
            <ActionDropdown :show-menu="false" :icon-tooltip="$t('Download')" @click="downloadFile" />
        </template>
        <div class="io-document-wrapper">
            <DocumentsShow
                :id="documentId"
                :pid="projectId"
                :view-only="true"
                no-header
            />
        </div>
    </IOModal>
</template>

<script lang="ts" src="./FilePreview.ts"></script>
<style lang="scss" src="./FilePreview.scss" scoped></style>