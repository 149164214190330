<template>
    <ul class="flex flex-col" :class="{ 'gap-24-px': topLevelJobsiteLocations }">
        <li
            v-for="(jobsiteLocation, idx) in jobsiteLocations"
            class="animate-pop-from-bottom"
            :class="{
                'io-shadow rounded overflow-hidden': !jobsiteLocation.level,
                'border-t border-light-bg': !topLevelJobsiteLocations
            }"
            :style="getCascadeAnimationDelay(idx)"
        >
            <div
                class="io-accordion-trigger flex items-center bg-white pr-24-px"
                :class="{ 'cursor-pointer': jobsiteLocation.children?.length }"
                @click="$emit('toggleFolding', jobsiteLocation.id)"
            >
                <div v-if="multiple" @click.stop>
                    <Checkbox
                        input-name="jobsite-location"
                        :checkbox-value="jobsiteLocation.id"
                        v-model="checkboxesValues"
                        @update:model-value="$emit('update:modelValue', $event)"
                    />
                </div>

                <Radio
                    v-else
                    input-name="jobsite-location"
                    :radio-value="jobsiteLocation.id"
                    v-model="radioValue"
                    @click.stop
                    @update:model-value="$emit('update:modelValue', [radioValue])"
                />

                <div class="flex items-center" :style="{ marginLeft: `${ getOffset(jobsiteLocation) }px` }">
                    <i
                        v-if="jobsiteLocation.children.length"
                        class="icon-chevron-down text-main-lighten-3 trans-all"
                        :class="[
                            jobsiteLocation.level ? 'text-lg-px' : 'text-2xl-px',
                            { 'io-rotate-180': expandedJobsiteLocationsIds.includes(jobsiteLocation.id) },
                        ]"
                    />

                    <div
                        :class="{
                            'text-sm-px': jobsiteLocation.level,
                            'font-medium ml-16-px': jobsiteLocation.children?.length
                        }"
                    >
                        {{ jobsiteLocation.title }}
                    </div>
                </div>

            </div>

            <JobsiteLocationsAccordion
                v-if="jobsiteLocation.children.length && expandedJobsiteLocationsIds.includes(jobsiteLocation.id)"
                :jobsite-locations="jobsiteLocation.children"
                :expanded-jobsite-locations-ids="expandedJobsiteLocationsIds"
                :model-value="modelValue"
                :multiple="multiple"
                @toggle-folding="$emit('toggleFolding', $event)"
                @update:model-value="$emit('update:modelValue', $event)"
            />
        </li>
    </ul>
</template>

<script lang="ts" src="./JobsiteLocationsAccordion.ts"></script>
<style lang="scss" src="./JobsiteLocationsAccordion.scss" scoped></style>
