import { defineComponent } from 'vue'

import { SimpleLazyListTextHeader } from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'
import { PillSize, PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'

export default defineComponent({
    name: 'TextCell',
    props: {
        item: {
            type: Object,
            required: true,
        },
        column: {
            type: Object as () => SimpleLazyListTextHeader<unknown>,
            required: true,
        },
    },
    computed: {
        value (): string {
            if (this.column.customValue) {
                return this.column.customValue(this.item)
            }

            return this.item[this.column.value]
        },
        pillVariant (): PillVariant {
            if(this.column.getPillVariant) {
                return this.column.getPillVariant(this.item)
            }

            return PillVariant.GRAY_LIGHT
        },
        pillText (): string {
            if (this.column.getPillText) {
                return this.column.getPillText(this.item)
            }

            return ''
        },
        pillSize (): PillSize {
            if (this.column.getPillSize) {
                if (typeof this.column.getPillSize === 'function') {
                    return this.column.getPillSize(this.item)
                }

                return this.column.getPillSize
            }
        },
        justifyClass (): `justify-${ SimpleLazyListTextHeader<unknown>['justifyItems'] }` {
            return `justify-${ this.column.justifyItems }` as const
        }
    },
})
