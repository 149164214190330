export enum appTypes {
    TYPE_AC = 'ac',
    TYPE_SC = 'sc',
    TYPE_GC = 'gc',
    TYPE_DEV = 'dev',
    TYPE_OWNER = 'owner',
    TYPE_INVESTOR = 'investor',
    TYPE_GENIUS = 'genius',
    TYPE_BASIC = 'basic',
    TYPE_REP = 'rep',
    TYPE_DESIGN = 'design',
    TYPE_SUB = 'sub',
    TYPE_OFF_SYSTEM = 'off-system'
}

export default appTypes
