import submittalsV2Client from '../../../api/submittals-v2/submittalsV2Client'

export const fetchPackages = async function ({ commit }, options) {
    const { data } = await submittalsV2Client.fetchSubmittals(options.projectId, options.queryString)
    commit('SET_PACKAGES', data)
}

export const fetchRelevantSpecifications = async function ({ commit }, projectID) {
    const { data } = await submittalsV2Client.fetchRelevantSpecifications(projectID)
    data.map(division => {
        division.display_name = `${ division.label }: ${ division.title }`

        division.sections.map(section => {
            section.display_name = `${ section.label }: ${ section.title }`
        })
    })
    commit('SET_RELEVANT_SPECIFICATIONS', data)
}

export const getFileInfo = async function ({ commit }, params) {
    const { data } = await submittalsV2Client.getFileInfo(params)
    commit('SET_FILE_INFO', data)
}
