import defaultState from './state'
import { cloneDeep } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export function SET_MODEL (state, value) {
    state.model = value
}

export function SET_ADDRESSES (state, value) {
    state.addresses = value
}

export function SET_IMPORTANT (state, value) {
    state.model.res_important = value
}

export function SET_LOGO (state, value) {
    state.model.res_photo = value
}

export function SET_LOGO_TEMPORARY (state, value) {
    state.model.avatar = value
}

export function ADD_ADDRESS (state) {
    const index = state.addresses.length

    state.addresses.push({
        id: crypto.randomUUID(),
        address_line1: '',
        address_line2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        label: '',
        office_phone_country_code: '',
        office_phone_number: '',
        office_phone_ext: '',
        fax_country_code: '',
        fax_number: '',
        is_main: false
    })
}

export function SET_NOTES (state, value) {
    state.notes = value
}

export function ADD_NOTE (state, note) {
    state.notes.push(note)
}

export function SET_FORM (state, value) {
    state.form = value
}

export function SET_CUSTOM_INDUSTRIES (state, value) {
    state.industries = value
}

export function ADD_CUSTOM_INDUSTRY (state, value) {
    if (typeof value === 'object' && value.id && value.name) {
        state.industries.push(value)
    }
}

export function UPDATE_CUSTOM_INDUSTRY (state, value) {
    if (typeof value === 'object' && value.id && value.name) {
        state.industries = state.industries.map(industry => {
            if (industry.id === value.id) {
                return { ...industry, ...value }
            }

            return industry
        })
    }
}

export function REMOVE_CUSTOM_INDUSTRY (state, value) {
    state.industries = state.industries.filter(industry => industry.id !== value.id)
}
