import { defineComponent, nextTick } from 'vue'
import TopActions from '@/io-modules/invoices/components/invoice-view/sov-wbs/top-actions/TopActions.vue'
import LeftNavigation from '@/io-modules/invoices/components/invoice-view/sov-wbs/left-navigation/LeftNavigation.vue'
import QuotedItemsList from '@/io-modules/invoices/components/invoice-view/sov-wbs/quoted-items-list/QuotedItemsList.vue'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
// @ts-ignore
import fullScreenMixin from '@/mixins/fullScreenMixin'
// @ts-ignore
import isLoggedMixin from '@/mixins/isLoggedMixin'
import { mapActions as mapActionsVuex, mapState as mapStateVuex } from 'vuex'
import { mapState, mapActions } from 'pinia'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import AdjustmentsModal from '@/io-modules/invoices/components/invoice-view/sov-wbs/adjustments-modal/AdjustmentsModal.vue'
import SoVLineItem from '@/io-modules/invoices/interfaces/SoVLineItem'
import SoVLineItemField from '@/io-modules/invoices/enums/SovLineItemField'
import { RouteLocation } from 'vue-router'
import { ContractChangeItemRoutesNames } from '@/modules/projects/modules/apps/common/contract-changes/interfaces/RoutesNames.ts'
import SoVTableType from '../../../enums/SoVTableType.ts'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import FeatureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'
import SitesNavigation from '../../../components/invoice-view/sites-navigation/SitesNavigation.vue'
import SiteItem from '../../../interfaces/SiteItem.ts'
import GroupedCCLines from '../../../interfaces/GroupedCCLines.ts'
import SoVCommonMixin from '@/io-modules/invoices/mixins/sov-wbs/SoVCommonMixin.ts'
import RevisionModal from '@/io-modules/invoices/components/invoice-view/sov-wbs/revision-modal/RevisionModal.vue'
import ReviseResubmitMixin from '@/io-modules/invoices/mixins/sov-wbs/ReviseResubmitMixin.ts'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'
import CommentItem from '@/components/comments/interfaces/CommentItem.ts'

export default defineComponent({
    components: {
        TopActions,
        LeftNavigation,
        QuotedItemsList,
        AdjustmentsModal,
        LoadingScreen,
        SitesNavigation,
        RevisionModal
    },
    // @TODO W6 - find better solution to send separated items to the BE with correct values after working with the table where COs within original scope
    beforeRouteLeave (to: RouteLocation, _from: RouteLocation, next: Function): void {
        if (to.name.startsWith('invoice-') && this.isEditingMode) {
            this.setTableViewMode(SoVTableViewMode.COsInSeparateCategory)
            setTimeout(() => {
                next()
            }, 500)
        } else {
            next()
        }
    },
    mixins: [isLoggedMixin, fullScreenMixin, FeatureFlagsMixin, SoVCommonMixin, ReviseResubmitMixin],
    data () {
        return {
            searchPhase: '',
            showLeftNavigation: true,
            scrollLeft: 0,
            SoVTableViewMode,
            modalAdjust: false,
            adjustItem: null,
            headerHeight: 0,
            loading: false,
            ContractChangeItemRoutesNames,
            renderTable: false,
            SoVTableType,
            activeSiteId: '',
        }
    },
    computed: {
        ...mapState(invoiceViewStore, ['isEditingMode', 'invoice', 'creating', 'editing', 'clientRequestedRevision', 'isMyInvoice']),

        ...mapState(invoiceSoVStore, [
            'tableViewMode',
            'columnsViewMode',
            'visibleLinesFilter',
            'contractWBS',
            'changeOrdersWBS',
            'changeOrderWithinOriginalScope',
            'netInvoiceValue',
            'groupedByContractChangeWBS',
            'amendmentsContractChangeType',
            'wbsSites',
        ]),

        ...mapState(invoiceRevisionStore, [
            'fieldsUsedInRevisionRequest'
        ]),

        ...mapStateVuex('project', ['projectObj']),

        siteNetInvoiceValueOriginalScope (): number {
            return this.siteOriginalScopeQIs.reduce((sum, item) => sum + item.net_current_application, 0)
        },

        basicColumnsView (): boolean {
            return SoVTableViewMode.BasicColumns === this.columnsViewMode
        },

        isCOsInSeparateCategory (): boolean {
            return SoVTableViewMode.COsInSeparateCategory === this.tableViewMode
        },

        originalScopeQIs (): SoVLineItem[] {
            if (this.isCOsInSeparateCategory) {
                return this.contractWBS.filter(item => item.visible || undefined === item.visible)
            } else {
                return this.changeOrderWithinOriginalScope.filter(item => item.visible || undefined === item.visible)
            }
        },

        siteOriginalScopeQIs (): SoVLineItem {
            return this.originalScopeQIs.filter(item => this.hasSites && item.site_id === this.activeSiteId || !this.hasSites)
        },

        executedCOsQIs (): SoVLineItem[] {
            return this.changeOrdersWBS.filter(item => item.visible || undefined === item.visible)
        },

        siteExecutedCOsQIs (): SoVLineItem[] {
            return this.executedCOsQIs.filter(item => this.hasSites && item.site_id === this.activeSiteId || !this.hasSites)
        },

        groupedExecutedCCs (): GroupedCCLines[] {
            return this.groupedByContractChangeWBS(this.activeSiteId)
        },

        hasVisibleLines (): boolean {
            if (this.isCOsInSeparateCategory) {
                return Boolean([
                    ...this.originalScopeQIs,
                    ...this.executedCOsQIs
                ].length) && this.renderTable
            } else {
                return Boolean(this.originalScopeQIs.length) && this.renderTable
            }
        },

        cssVars (): object {
            const firstHeaderTop = 0
            const secondHeaderTop = 16
            return {
                '--left-navigation': this.showLeftNavigation ? '202px' : '0px',
                '--first-header-top': `${ firstHeaderTop }px`,
                '--second-header-top': `${ secondHeaderTop }px`,
                '--table-height': this.creating ? '69.5vh' : '64vh',
                '--table-overflow': 'auto',
                '--header-height': this.isFullScreenMode ? '0px' : `${ this.headerHeight }px`,
            }
        },

        placeholderTitle (): string {
            return SoVTableViewMode.LinesBilledThisPeriod === this.visibleLinesFilter
                    ? this.$tc('No lines were billed during this period.')
                    : this.$tc('No lines were adjusted')
        },

        hasSites (): boolean {
            return !!this.wbsSites && this.wbsSites.length
        },

        sitesFFEnabled (): boolean {
            return this.isFeatureEnabled(FeatureFlagsConsts.BUDGET_V3_SITES, false)
        },

        getActiveSiteTitle (): string {
            return this.wbsSites.find(item => this.activeSiteId === item.project_site_id)?.title || ''
        },
    },
    watch: {
        hasVisibleLines (value: boolean | string | number): void {
            this.showLeftNavigation = Boolean(value)
        },

        tableViewMode (newValue: string, oldValue: string): void {
            if (SoVTableViewMode.COsInSeparateCategory === newValue && oldValue) {
                this.separateCOsFromOriginalScope()
                this.setVisibleItems()
            }

            if (SoVTableViewMode.COsWithinOriginalScope === newValue) {
                this.groupCOsWithinOriginalScope()
                this.setVisibleItems()
            }

            this.setItemsNeedAttention()
        },

        visibleLinesFilter (newVal: string, oldVal: string): void {
            if (!oldVal) {
                return
            }

            this.setVisibleItems()
        },

        async editing (): Promise<void> {
            if (this.editing) {
                setTimeout(() => this.setHeaderHeight(), 300)

                return
            }
            this.renderTable = false

            await this.fetchData()
            this.setupData()
        },

        netInvoiceValue (): void {
            if (this.isEditingMode) {
                this.wbsSites.forEach(site => {
                    if (this.activeSiteId === site.project_site_id) {
                        this.setupSiteCompleted(site)
                    }
                })
            }
        },
    },
    async mounted (): Promise<void> {
        if (!this.contractWBS.length) {
            await this.fetchData()
        }
        this.setupData()
    },
    beforeUnmount (): void {
        if (!this.isEditingMode) {
            this.separateCOsFromOriginalScope()
        }

        window.removeEventListener('resize', this.setHeaderHeight)
    },
    methods: {
        ...mapActionsVuex('financesCommon', [
            'setRowsToExpand',
            'setIsAbleCollapseSoV',
        ]),

        ...mapActions(invoiceSoVStore, [
            'fetchInvoiceWBS',
            'groupCOsWithinOriginalScope',
            'separateCOsFromOriginalScope',
            'setTableViewMode',
            'contractChangeLinkParams',
            'hasSubTasksItem'
        ]),

        ...mapActions(invoiceViewStore, ['setDataLoadingValue']),

        ...mapActions(invoiceRevisionStore, ['loadAdjustmentsIntoWbsItems']),

        async fetchData (): Promise<void> {
            this.setDataLoadingValue(true)
            this.loading = true

            await this.fetchInvoiceWBS(this.projectObj.project_local_id, !this.creating ? this.$route.params.id : '')

            if (!this.creating && !!this.$route.params.id) {
                await this.loadAdjustmentsIntoWbsItems(this.projectObj.project_global_id, this.$route.params.id)
            }

            this.loading = false
            this.setDataLoadingValue(false)
        },

        async setupData (): Promise<void> {
            this.setDataLoadingValue(true)
            await nextTick()

            if (SoVTableViewMode.COsWithinOriginalScope === this.tableViewMode) {
                this.groupCOsWithinOriginalScope()
            }

            if (this.hasSites) {
                this.activeSiteId = this.wbsSites[0].project_site_id
                this.wbsSites.forEach(site => {
                    this.setupSiteCompleted(site)
                })
            }

            await nextTick()
            this.setHeaderHeight()
            window.addEventListener('resize', this.setHeaderHeight)
            setTimeout(() => {
                this.renderTable = true
                this.setDataLoadingValue(false)
            }, 300)

            this.setItemsNeedAttention()
            //this.setVisibleItems()
        },

        setupSiteCompleted (site: SiteItem): void {
            const originalScopeNet = this.originalScopeQIs.filter(item => item.site_id === site.project_site_id).some(item => this.getSumOfTasksValues(item, 'current_application') || this.getSumOfTasksValues(item, 'retention_released'))
            const executedCOsNet = this.groupedByContractChangeWBS(site.project_site_id).some(contractChange => contractChange.quoted_items.some(item => this.getSumOfTasksValues(item, 'current_application') || this.getSumOfTasksValues(item, 'retention_released')))
            site.completed = this.isCOsInSeparateCategory ? originalScopeNet || executedCOsNet : originalScopeNet
        },

        siteNetInvoiceValueCo (items: SoVLineItem[]): number {
            return items.reduce((sum, item) => sum + item.net_current_application, 0)
        },

        setHeaderHeight (): void {
            this.headerHeight = document.querySelector('.io-page-header-holder').clientHeight
            if (window.innerWidth < 1300) {
                this.showLeftNavigation = false
            }
        },

        calculateProgressBar (complete: number, required: number): number {
            return (complete / required) * 100
        },

        setLineVisible (item: SoVLineItem): boolean {
            let result = true
            if (SoVTableViewMode.LinesBilledThisPeriod === this.visibleLinesFilter) {
                result = Boolean(this.calculateVisibleApplication(item))
            } else if (SoVTableViewMode.LinesWithAdjustments === this.visibleLinesFilter) {
                result = Boolean(this.calculateVisibleAdjustment(item))
            }

            item.visible = result

            return result
        },

        hasNestedValue (item: SoVLineItem, field: string): boolean {
            if (this.hasSubtasks(item)) {
                item.visible = item.children?.some(task => this.hasNestedValue(task, field))
            } else {
                item.visible = Boolean(item[field])
            }

            return item.visible
        },

        calculateVisibleApplication (item: SoVLineItem): boolean {
            if (item[SoVLineItemField.CurrentApplication]) {
                return true
            }

            return this.hasNestedValue(item, SoVLineItemField.CurrentApplication)
        },

        calculateVisibleAdjustment (item: SoVLineItem): boolean {
            return this.hasNestedValue(item, SoVLineItemField.Adjustments)
        },

        setVisibleItems (): void {
            if (this.isCOsInSeparateCategory) {
                this.contractWBS.forEach(quotedItem => {
                    quotedItem.visible = this.setLineVisible(quotedItem)
                })
                this.changeOrdersWBS.forEach(quotedItem => {
                    quotedItem.visible = this.setLineVisible(quotedItem)
                })
            } else {
                this.changeOrderWithinOriginalScope.forEach(quotedItem => {
                    quotedItem.visible = this.setLineVisible(quotedItem)
                })
            }

            if (this.visibleLinesFilter === SoVTableViewMode.LinesBilledThisPeriod || this.visibleLinesFilter === SoVTableViewMode.LinesWithAdjustments) {
                const firstVisible = document.getElementsByClassName('io-expandable-table-row')[0]
                if (firstVisible) {
                    firstVisible.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
            }
        },

        scrollToPhase (phaseId: string): void {
            const phase = document.getElementById(phaseId)
            if (phase) {
                phase.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        },

        toggleFullScreen (): void {
            this.handleFullScreenChange('io-invoice-sov-wbs')
        },

        modalAdjustToggle (): void {
            this.modalAdjust = false
            this.adjustItem = null
        },

        showSiteWBS (id: string): void {
            this.activeSiteId = id
        },

        /**
         * Invoices: 4. Request Revision: Replace row in the table with requested for revision value
         * Save initial value in submitted
         * Save adjusted value in adjusted
         */
        async applyAdjustedItem (item: SoVLineItem, comments: CommentItem[]): Promise<void> {

            const contractWbsId = this.getRealContractWbsId(item)
            const items = this.getItemsForContractWbsId(contractWbsId).filter(item => false === this.hasSubTasksItem(item))
            if (contractWbsId !== item.contract_wbs_id) {
                items.push(...this.getItemsForContractWbsId(item.contract_wbs_id).filter(item => false === this.hasSubTasksItem(item)))
            }

            items.forEach(originalItem => {
                for (const field of this.fieldsUsedInRevisionRequest) {
                    originalItem[field] = item[field]
                }
                originalItem.adjustments = item.adjustments
                originalItem.acceptedByClient = item.acceptedByClient
                originalItem.revisedByVendor = item.revisedByVendor
                originalItem.revisedByClient = item.revisedByClient
                originalItem.acceptedByVendor = item.acceptedByVendor
                originalItem.needAttention = item.needAttention
                originalItem.comments = comments
            })
        },

        //To highlight revision items
        setItemsNeedAttention (): void {
            if (this.isRevisionStatus) {
                if (this.isCOsInSeparateCategory) {
                    this.originalScopeQIs.forEach(item => this.setupAttentionClass(item))
                    this.executedCOsQIs.forEach(item => this.setupAttentionClass(item))
                } else {
                    this.originalScopeQIs.forEach(item => this.setupAttentionClass(item))
                }
            }
        }
    }
})
