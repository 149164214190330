import { defineComponent, PropType } from 'vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { ExistingWorkspace } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'

export default defineComponent({
    name: 'WorkspaceRadio',
    components: {
        StatusPill
    },
    props: {
        workspace: { type: Object as PropType<ExistingWorkspace>, required: true },
        inputName: { type: String, required: true },
        modelValue: { required: false },
        radioValue: { required: true },
        disabled: { type: Boolean, default: false },
    },
    emits: ['input', 'update:modelValue'],
    data () {
        return {
            currentValue: null,
        }
    },
    computed: {
        isSelected(): boolean {
            return this.modelValue === this.radioValue
        },
        workspaceClass (): string {
            if (!this.workspace.app_type) {
                return 'gray-light'
            }

            return this.getAppTypeStatusPillClass(this.workspace.app_type)
        },
    },
    watch: {
        currentValue (): void {
            this.$emit('update:modelValue', this.currentValue)
        },
        modelValue (): void {
            this.currentValue = this.modelValue
        },
    },
    mounted () {
        this.currentValue = this.modelValue
    },
})
