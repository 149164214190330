import iconLibrary from '@/base/iconLibrary.js'
import { isArray, get } from 'lodash'

export function modalAddIsInvoiceMy (state) {
    return state.modalInvoice.data.type === 'my' || (state.modalInvoice.data.type === 'retention' && state.modalInvoice.data.subType === 'own')
}

export function modalAddIsInvoiceRetention (state) {
    return state.modalInvoice.data.type === 'retention'
}


export function modalAddIsInvoiceVendor (state) {
    return state.modalInvoice.data.type === 'contracted' || (state.modalInvoice.data.type === 'retention' && state.modalInvoice.data.subType === 'vendor')
}

export function modalAddIsInvoiceNonContracted (state) {
    return state.modalInvoice.data.type === 'non-contracted'
}

/**
 * Get period active actions
 * @param state
 * @return {number}
 */
export function getPeriodActions (state) {
    return state.periodDetails.actions
}

export function countAllInvoices (state) {
    if (!state.periodDetails.items) {
        return 0
    }
    const withoutFunding = state.periodDetails.items.rows.without_funding_source ? state.periodDetails.items.rows.without_funding_source.invoices.length : 0
    let withFunding = 0
    for (const prop in state.periodDetails.items.rows.with_funding_source) {
        withFunding += state.periodDetails.items.rows.with_funding_source[prop].invoices.length || 0
    }
    return withoutFunding + withFunding
}

export function countApprovedInvoices (state) {
    if (!state.periodDetails || !state.periodDetails.items || !state.periodDetails.items.rows) {
        return 0
    }

    let withoutFunding = 0
    let approvedStatuses = ['status_approved', 'status_submitted_for_funding', 'status_approved_for_funding']
    if (state.periodDetails.items.rows.without_funding_source) {
        state.periodDetails.items.rows.without_funding_source.invoices.forEach((item) => {
            if (approvedStatuses.includes(item.status_marker)) {
                withoutFunding++
            }
        })
    }
    let withFunding = 0
    if (state.periodDetails.items.rows.with_funding_source) {
        for (const prop in state.periodDetails.items.rows.with_funding_source) {
            state.periodDetails.items.rows.with_funding_source[prop].invoices.forEach((item) => {
                if (approvedStatuses.includes(item.status_marker)) {
                    withFunding++
                }
            })
        }
    }
    return withoutFunding + withFunding
}

/**
 * Get sum sources from invoice
 * @param state
 * @return {number}
 */
export function getFundingSourcesSum (state) {
    let sum = 0
    if (!state.payApp.basic || !isArray(state.payApp.basic.funding_source)) {
        return sum
    }
    state.payApp.basic.funding_source.forEach(source => {
        sum += source.invoice_covered_amount
    })
    return sum
}

/**
 * Get covered percentage
 * @param state
 * @param getters
 * @return {number}
 */
export function getFundingCovered (state, getters) {
    if (!state.payApp.balance) {
        return 0
    }

    if (state.payApp.balance.pay_app_amount_net === 0 && getters.getFundingSourcesSum === 0) {
        // Allows approving invoices of $ 0
        return 1
    }

    const payAppAmountNet = iconLibrary.methods.intCutPrecision(state.payApp.balance.pay_app_amount_net)
    const fundingSourcesSum = iconLibrary.methods.intCutPrecision(getters.getFundingSourcesSum)

    return payAppAmountNet ? (fundingSourcesSum / payAppAmountNet) : 0
}

export function invoiceValueMatchSoV (state) {
    if (!state.payApp.basic.original_amount) {
        return true
    }

    const payAppAmount = iconLibrary.methods.intCutPrecision(state.payApp.balance.pay_app_amount)
    const originalAmount = iconLibrary.methods.intCutPrecision(state.payApp.basic.original_amount)

    return payAppAmount === originalAmount
}

export function getPeriods (state, getters) {
    return state.periods
}

export function getCategories (state, getters) {
    return state.categories
}

/**
 * Get selected sources sum
 * @param state
 * @param getters
 * @return {number}
 */
export function getModalSourcesSelectedSum (state, getters) {
    let sum = 0
    state.modalSources.sourcesSelected.forEach(source => {
        sum += parseInt(source.invoice_covered_amount) || 0
    })
    return sum
}

/**
 * List of already used sources
 * @param state
 * @param getters
 * @return {number}
 */
export function getModalSourcesSelectedIds (state, getters) {
    return state.modalSources.sourcesSelected.map(source => {
        return source.funding_source_project_id
    })
}

/**
 * Remaining amount
 * @param state
 * @param getters
 * @return {number}
 */
export function getModalSourcesRemainingAmount (state, getters) {
    return parseInt(state.modalSources.invoiceAmount - getters.getModalSourcesSelectedSum) || 0
}


export function getInjectedForTask (state) {
    return (taskId) => {
        return get(state.payApp, ['injected', taskId], [])
    }
}

export function getExtractedPhases (state) {
    if (!state.payApp.quoted_items || !Array.isArray(state.payApp.quoted_items.phases)) {
        return []
    }

    const extractedPhases = state.payApp.quoted_items.phases.filter(phase => {
        return !phase.isAlternate || phase.enabled
    })

    let numIndex = 0
    let lastChangeOrder = null

    extractedPhases.forEach((phase, index, phases) => {
        if (typeof phase.changeOrder === 'undefined' || lastChangeOrder !== null) {
            numIndex++
        }

        if (phase.changeOrder !== lastChangeOrder) {
            lastChangeOrder = phase.changeOrder
        }

        phases[index].numNeedAttentionTasks = 0
        phases[index].numIndex = numIndex
    })


    return extractedPhases
}

export function getPayAppCurrentStatus (state) {
    return state.payApp.status
}

export function hasPurchaseOrderAssociate (state) {
    return state.payApp.basic?.purchase_order ? true : false
}

export function getPayAppPurchaseOrder (state) {
    return state.payApp.basic?.purchase_order
}

export function getPayAppContractId (state) {
    return state.payApp.basic?.contract_id
}

export function isVendorInvoice (state) {
    return false === state.payApp.flags.is_non_contracted
        && false === state.payApp.flags.is_retention_invoice
        && false === state.payApp.flags.is_my_retention_invoice
        && true === state.payApp.flags.is_receiver
}

export function isRequiredPurchaseOrder (state) {
    return state.payApp.basic?.require_purchase_order
}

export function canBelongToAPurchaseOrder (state) {
    return state.payApp.basic?.can_belong_to_a_purchase_order
}

export function isInvoiceLoaded (state) {
    return state.loaded?.invoice
}

/**
 * Checks if billing periods are already loaded for specified project
 * @param state
 * @returns {(string) => boolean}
 */
export function areBillingPeriodsLoadedForProject (state) {
    return projectId => state.loaded?.periods === projectId.toString()
}

export function isNonContractedInvoice (state) {
    return true === state.payApp.flags.is_non_contracted
}
