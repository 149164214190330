const MainLayout = () => import(/* webpackChunkName: "lists-templates" */ '@/modules/lists-templates/main.vue')
const IntegrationSyncHistory = () => import(/* webpackChunkName: "integration-sync-history" */ './views/integration-sync-history-list/IntegrationSyncHistoryList.vue')

export default [
    {
        path: '/lists-templates/integration-sync-history',
        name: 'lists-templates-integration-sync-history',
        redirect: { name: 'lists-templates-integration-sync-history-list' },
        component: MainLayout,
        children: [
            {
                path: 'list',
                name: 'lists-templates-integration-sync-history-list',
                component: IntegrationSyncHistory,
                meta: {
                    title: 'Integration Sync History'
                }
            },
        ]
    },

]
