<template>
    <div class="io-lien-waivers-table">
        <Table
            :headers="tableHeaders"
            :items="invoiceLienWaivers"
        >
            <template #tbody="{ sortedItems }">
                <tr 
                    v-for="(item, itemIndex) in sortedItems"
                    :key="`item-${ itemIndex }`"
                    @click="openFilePreview(item)"
                >
                    <td
                        v-for="(header, headerIndex) in tableHeaders"
                        :key="`header-${ headerIndex }`"
                        :class="header.valueAlign"
                    >
                        <StatusDot
                            v-if="CellType.StatusDot === header.valueType"
                            :variant="DotVariant.SUCESS_DARKER"
                            :text="header.customValue(item)"
                            :tooltip="header.tooltip ? { text: header.tooltip(item), top: true, start: true } : null"
                        />
                        <span v-if="CellType.Text === header.valueType">{{ header.customValue(item) }}</span>
                        <span v-if="CellType.Actions === header.valueType" class="io-column-actions">
                            <template v-for="(button, index) in header.customValue(item)" :key="index">
                                <IOButton
                                    v-if="undefined !== button.isDisplayed ? button.isDisplayed : true"
                                    v-tooltip.top="{
                                        content: button.tooltip?.text,
                                        visible: undefined !== button.tooltip?.text
                                    }"
                                    :variant="button.variant"
                                    :icon="button.icon"
                                    @click.stop="button.onClick(item, itemIndex)"
                                >
                                    {{ button.text }}
                                </IOButton>
                            </template>
                        </span>
                    </td>
                </tr>
            </template>
        </Table>
    </div>
</template>

<script lang="ts" src="./LienWaiversTable.ts"></script>
<style lang="scss" src="./LienWaiversTable.scss" scoped></style>