import { getProjectAccess } from './access.js'
import { getAccessByAppType } from '@/helpers/access.js'
import { ACCESS } from '@/modules/projects/modules/apps/common/project-settings/constants.js'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource'
import { isFeatureFlagEnabled } from './feature-flag-status'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
import appTypes from '@/base/appTypes.ts'

const Main = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/main.vue')
const ProjectInformation = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/ProjectInformation.vue')
const ProjectAttributes = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/project-attributes/ProjectAttributes.vue')
const ProjectAttributesOwner = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/project-attributes-owner/ProjectAttributesOwner.vue')
const ProjectInformationEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/ProjectInformationEdit.vue')
const FundingSources = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/funding-sources/funding-sources.vue')
const FundingSourceNew = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/FundingSource/New.vue')
const FundingSourceEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/FundingSource/edit/Main.vue')
const ProjectHours = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/ProjectHours.vue')
const ProjectScheduleSettings = () => import(/* webpackChunkName: "project-settings" */ '@/io-modules/project-schedules/components/project-settings/ProjectSettingsView.vue')
const ProjectHoursEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/ProjectHoursEdit.vue')
const Rfp = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/Rfp.vue')
const RfpEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/RfpEdit.vue')
const ProjectSettingsRfiShow = () => import(/* webpackChunkName: "project-settings" */ '@/io-modules/rfis/views/project-settings-rfi-show/ProjectSettingsRfiShow.vue')
const ChangeOrder = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/ChangeOrder.vue')
const ChangeOrderEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/ChangeOrderEdit.vue')
const Location = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/Location.vue')
const LocationEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/LocationEdit.vue')
const DistributionTemplates = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/distribution-templates/DistributionTemplates.vue')
const JobsiteLocations = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/jobsite-locations/JobsiteLocations.vue')
const DailyReports = () => import(/* webpackChunkName: "project-settings" */ '@/io-modules/daily-reports/components/project-settings/ProjectSettings.vue')
const PunchLists = () => import(/* webpackChunkName: "project-settings" */ '@/io-modules/punch-lists/views/project-settings-stamps-index/ProjectSettingsStampsIndex.vue')
const ProjectIntegration = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/integrations/main.vue')
const SettingBudget = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/budget/main.vue')
const ProjectSiteLocations = () => import(/* webpackChunkName: "project-settings" */ '@/io-modules/project-settings-project-site-locations/views/main/Main.vue')

const FinancialApprovalsGeneral = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/financial-approvals/views/general-settings/main.vue')
const FinancialApprovalsGeneralEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/financial-approvals/views/general-settings/edit/main.vue')

const FinancialApprovals = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/financial-approvals/views/view/main.vue')
const FinancialApprovalsEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/financial-approvals/views/edit/main.vue')

const BillingSettingsInvoices = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/pay-apps/tabs/tab-4/view/main.vue')
const BillingSettingsInvoicesEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/pay-apps/tabs/tab-4/edit/main.vue')
const BillingSettingsBillingPeriod = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/pay-apps/tabs/tab-1/view/main.vue')
const BillingSettingsBillingPeriodEdit = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/pay-apps/tabs/tab-1/edit/main.vue')
const BillingSettingsLienWaiver = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/pay-apps/tabs/tab-2/main.vue')
const BillingSettingsLienWaiverVendor = () => import(/* webpackChunkName: "project-settings" */ '@/modules/projects/modules/apps/common/project-settings/parts/pay-apps/tabs/tab-3/view/main.vue')

const SubmittalsGeneralShow = () => import(/* webpackChunkName: "project-settings-submittals-show" */ '@/modules/projects/modules/apps/common/project-settings/parts/submittals/general/show/main.vue')
const SubmittalsGeneralEdit = () => import(/* webpackChunkName: "project-settings-submittals-edit" */ '@/modules/projects/modules/apps/common/project-settings/parts/submittals/general/edit/main.vue')

const SubmittalsSchedulingShow = () => import(/* webpackChunkName: "project-settings-submittals-show" */ '@/modules/projects/modules/apps/common/project-settings/parts/submittals/scheduling/show/main.vue')
const SubmittalsSchedulingEdit = () => import(/* webpackChunkName: "project-settings-submittals-edit" */ '@/modules/projects/modules/apps/common/project-settings/parts/submittals/scheduling/edit/main.vue')

const metaInfo = {
    name: 'Project Settings',
    customRouteName: 'project-settings',
    navigationKey: 'project_settings',
}

const appType = localStorage.getItem('appType')

export default {
    // project settings
    path: '/project/:pid/project-settings',
    name: 'project-settings',
    component: Main,
    redirect: { name: 'project-settings-project-information-view' },
    meta: metaInfo,
    children: [
        {
            path: 'project-information',
            name: 'project-settings-project-information-view',
            components:
                {
                    rightContent: ProjectInformation,
                },
            meta: metaInfo,
        },
        {
            path: 'project-information/edit',
            name: 'project-settings-project-information-edit',
            components:
                {
                    rightContent: ProjectInformationEdit,
                },
            meta: metaInfo,
        },
        {
            path: 'project-attributes',
            name: 'project-settings-project-attributes',
            components:
                {
                    rightContent: ProjectAttributes,
                },
            meta: metaInfo,
        },
        {
            path: 'project-attributes-owner',
            name: 'project-settings-project-attributes-owner',
            components:
                {
                    rightContent: ProjectAttributesOwner,
                },
            meta: metaInfo,
        },
        {
            path: 'funding-source',
            name: 'project-settings-funding-source-list',
            components:
                {
                    rightContent: FundingSources,
                },
            meta: metaInfo,
            async beforeEnter (to, from, next) {
                const result = await getAccessByAppType(...arguments, ACCESS.FUNDING_SOURCE)
                return result
            },
        },
        {
            path: 'funding-source/create',
            name: 'project-settings-funding-source-create',
            components:
                {
                    rightContent: FundingSourceNew,
                },
            meta: metaInfo,
            async beforeEnter (to, from, next) {
                const result = await getAccessByAppType(...arguments, ACCESS.FUNDING_SOURCE)
                return result
            },
        },
        {
            path: 'funding-source/create/:id',
            name: 'project-settings-funding-source-create-selected',
            components:
                {
                    rightContent: FundingSourceNew,
                },
            meta: metaInfo,
            async beforeEnter (to, from, next) {
                const result = await getAccessByAppType(...arguments, ACCESS.FUNDING_SOURCE)
                return result
            },
        },
        {
            path: 'funding-source/edit/:id',
            name: 'project-settings-funding-source-edit',
            components:
                {
                    rightContent: FundingSourceEdit,
                },
            meta: metaInfo,
            async beforeEnter (to, from, next) {
                const result = await getAccessByAppType(...arguments, ACCESS.FUNDING_SOURCE)
                return result
            },
        },
        {
            path: 'project-schedule-view',
            name: 'project-settings-project-schedule-view',
            components:
                {
                    rightContent: ProjectScheduleSettings,
                },
            meta: metaInfo,
        },
        {
            path: 'project-hours',
            name: 'project-settings-project-hours-view',
            components:
                {
                    rightContent: ProjectHours,
                },
            meta: metaInfo,
        },
        {
            path: 'project-hours/edit',
            name: 'project-settings-project-hours-edit',
            components:
                {
                    rightContent: ProjectHoursEdit,
                },
            meta: metaInfo,
        },
        {
            path: 'project-site-locations',
            name: 'project-settings-project-site-locations-view',
            components: {
                rightContent: ProjectSiteLocations
            },
            meta: metaInfo,
        },
        {
            path: 'settings-budget',
            name: 'project-settings-budget-view',
            components: { rightContent: SettingBudget },
            meta: metaInfo,
        },
        {
            path: 'settings-budget/edit',
            name: 'project-settings-budget-edit',
            components: { rightContent: SettingBudget },
            meta: metaInfo,
        },
        {
            path: 'rfp',
            name: 'project-settings-rfp-view',
            components:
                {
                    rightContent: Rfp,
                },
            meta: metaInfo,
        },
        {
            path: 'rfp/edit',
            name: 'project-settings-rfp-edit',
            components:
                {
                    rightContent: RfpEdit,
                },
            meta: metaInfo,
        },
        {
            path: 'submittals',
            name: 'project-settings-submittals',
            redirect: {
                name: 'project-settings-submittals-general-show',
            },
            meta: {
                ...metaInfo,
            },
            children: [
                {
                    path: 'general',
                    name: 'project-settings-submittals-general-show',
                    meta: {
                        ...metaInfo,
                    },
                    components: {
                        rightContent: SubmittalsGeneralShow,
                    },
                },
                {
                    path: 'general/edit',
                    name: 'project-settings-submittals-general-edit',
                    meta: {
                        ...metaInfo,
                    },
                    components: {
                        rightContent: SubmittalsGeneralEdit,
                    },
                },
                {
                    path: 'scheduling',
                    name: 'project-settings-submittals-scheduling-show',
                    meta: {
                        ...metaInfo,
                    },
                    components: {
                        rightContent: SubmittalsSchedulingShow,
                    },
                },
                {
                    path: 'scheduling/edit',
                    name: 'project-settings-submittals-scheduling-edit',
                    meta: {
                        ...metaInfo,
                    },
                    components: {
                        rightContent: SubmittalsSchedulingEdit,
                    },
                },
            ],
        },
        {
            path: 'rfi-v3',
            name: 'project-settings-rfi-show',
            components: {
                rightContent: ProjectSettingsRfiShow,
            },
            meta: metaInfo,
        },
        {
            path: 'distribution-templates',
            name: 'project-settings-distribution-templates-view',
            components:
                {
                    rightContent: DistributionTemplates,
                },
            meta: metaInfo,
        },
        {
            path: 'daily-reports',
            name: 'project-settings-daily-reports',
            components:
                {
                    rightContent: DailyReports,
                },
            meta: metaInfo,
            beforeEnter (to, from, next) {
                to.meta.fromDailyReports = from.name === 'daily-reports-create'
                return next()
            },
        },
        {
            path: 'punch-lists',
            name: 'project-settings-punch-lists',
            components: {
                rightContent: PunchLists,
            },
            meta: metaInfo,
        },
        {
            path: 'financial-approvals',
            name: 'project-settings-financial-approvals',
            meta: metaInfo,
            redirect: {
                name: appType === appTypes.TYPE_DEV
                    ? 'project-settings-financial-approvals-general'
                    : 'project-settings-financial-approvals-budget-approval',
            },
            children: [
                {
                    path: 'general',
                    name: 'project-settings-financial-approvals-general',
                    async beforeEnter (_to, _from, next) {
                        if (appType === appTypes.TYPE_DEV) {
                            return next()
                        }

                        return next({ name: 'project-settings-financial-approvals-budget-approval' })
                    },
                    components:
                        {
                            rightContent: FinancialApprovalsGeneral,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: null,
                    },
                },
                {
                    path: 'general/edit',
                    name: 'project-settings-financial-approvals-general-edit',
                    components:
                        {
                            rightContent: FinancialApprovalsGeneralEdit,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: null,
                    },
                },
                {
                    path: 'budget-approval',
                    name: 'project-settings-financial-approvals-budget-approval',
                    components:
                        {
                            rightContent: FinancialApprovals,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.BUDGET_APPROVAL,
                    },
                },
                {
                    path: 'budget-approval/edit',
                    name: 'project-settings-financial-approvals-budget-approval-edit',
                    components:
                        {
                            rightContent: FinancialApprovalsEdit,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.BUDGET_APPROVAL,
                    },
                },
                {
                    path: 'budget-changes',
                    name: 'project-settings-financial-approvals-budget-changes',
                    components:
                        {
                            rightContent: FinancialApprovals,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.BUDGET_CHANGES,
                    },
                },
                {
                    path: 'budget-changes/edit',
                    name: 'project-settings-financial-approvals-budget-changes-edit',
                    components:
                        {
                            rightContent: FinancialApprovalsEdit,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.BUDGET_CHANGES,
                    },
                },
                {
                    path: 'change-orders',
                    name: 'project-settings-financial-approvals-change-orders',
                    components:
                        {
                            rightContent: FinancialApprovals,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.CHANGE_ORDERS,
                    },
                },
                {
                    path: 'change-orders/edit',
                    name: 'project-settings-financial-approvals-change-orders-edit',
                    components:
                        {
                            rightContent: FinancialApprovalsEdit,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.CHANGE_ORDERS,
                    },
                },
                {
                    path: 'amendments',
                    name: 'project-settings-financial-approvals-amendments',
                    components:
                        {
                            rightContent: FinancialApprovals,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.AMENDMENTS,
                    },
                },
                {
                    path: 'amendments/edit',
                    name: 'project-settings-financial-approvals-amendments-edit',
                    components:
                        {
                            rightContent: FinancialApprovalsEdit,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.AMENDMENTS,
                    },
                },
                {
                    path: 'initial-contracts',
                    name: 'project-settings-financial-approvals-initial-contracts',
                    components:
                        {
                            rightContent: FinancialApprovals,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.INITIAL_CONTRACTS,
                    },
                },
                {
                    path: 'initial-contracts/edit',
                    name: 'project-settings-financial-approvals-initial-contracts-edit',
                    components:
                        {
                            rightContent: FinancialApprovalsEdit,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.INITIAL_CONTRACTS,
                    },
                },
                {
                    path: 'contracts',
                    name: 'project-settings-financial-approvals-contracts',
                    components:
                        {
                            rightContent: FinancialApprovals,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.CONTRACTS,
                    },
                },
                {
                    path: 'contracts/edit',
                    name: 'project-settings-financial-approvals-contracts-edit',
                    components:
                        {
                            rightContent: FinancialApprovalsEdit,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.CONTRACTS,
                    },
                },
                {
                    path: 'invoices',
                    name: 'project-settings-financial-approvals-invoices',
                    components:
                        {
                            rightContent: FinancialApprovals,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.INVOICES,
                    },
                },
                {
                    path: 'invoices/edit',
                    name: 'project-settings-financial-approvals-invoices-edit',
                    components:
                        {
                            rightContent: FinancialApprovalsEdit,
                        },
                    meta: {
                        ...metaInfo,
                        customRouteName: 'project-settings',
                        resource: Resource.INVOICES,
                    },
                },
            ],
        },
        {
            path: 'change-orders/edit',
            name: 'project-settings-change-orders-edit',
            components:
                {
                    rightContent: ChangeOrderEdit,
                },
            meta: metaInfo,
        },
        {
            path: 'pay-apps',
            name: 'project-settings-pay-apps',
            redirect: { name: 'project-settings-pay-apps-billing-period-view' },
            meta: { ...metaInfo, ...{ customRouteName: 'project-settings' } },
            children: [
                {
                    path: 'billing-period',
                    name: 'project-settings-pay-apps-billing-period-view',
                    components:
                        {
                            rightContent: BillingSettingsBillingPeriod,
                        },
                    meta: { ...metaInfo, ...{ customRouteName: 'project-settings' } },
                },
                {
                    path: 'billing-period/edit',
                    name: 'project-settings-pay-apps-billing-period-edit',
                    components:
                        {
                            rightContent: BillingSettingsBillingPeriodEdit,
                        },
                    meta: { ...metaInfo, ...{ customRouteName: 'project-settings' } },
                },
                {
                    path: 'documents',
                    name: 'project-settings-pay-apps-documents-view',
                    components:
                        {
                            rightContent: BillingSettingsLienWaiver,
                        },
                    meta: { ...metaInfo, ...{ customRouteName: 'project-settings' } },
                },
                {
                    path: 'documents-vendor',
                    name: 'project-settings-pay-apps-documents-vendor',
                    meta: { ...metaInfo, ...{ customRouteName: 'project-settings' } },
                    components:
                        {
                            rightContent: BillingSettingsLienWaiverVendor,
                        },
                    children: [
                        {
                            path: 'edit',
                            name: 'project-settings-pay-apps-documents-vendor-edit',
                            meta: { ...metaInfo, ...{ customRouteName: 'project-settings' } },
                        },
                    ],
                },
                {
                    path: 'invoices-settings',
                    name: 'project-settings-pay-apps-invoices-settings-view',
                    meta: { ...metaInfo, ...{ customRouteName: 'project-settings' } },
                    components: {
                        rightContent: BillingSettingsInvoices,
                    },
                    async beforeEnter (_to, _from, next) {
                        if (await isFeatureFlagEnabled(FeatureFlagsConsts.INSURANCE_REQUIREMENTS, false)) {
                            return next()
                        }
                        return next({ name: 'project-settings-pay-apps-billing-period-view' })
                    },
                },
                {
                    path: 'invoices-settings/edit',
                    name: 'project-settings-pay-apps-invoices-settings-edit',
                    meta: { ...metaInfo, ...{ customRouteName: 'project-settings' } },
                    components: {
                        rightContent: BillingSettingsInvoicesEdit,
                    },
                },

            ],
        },
        {
            path: 'jobsite-locations',
            name: 'project-settings-jobsite-locations',
            components: {
                rightContent: JobsiteLocations,
            },
            meta: metaInfo,
            redirect: { name: 'project-settings-project-site-locations-view' },
        },
        {
            path: 'location',
            name: 'project-settings-location-view',
            components:
                {
                    rightContent: Location,
                },
            meta: metaInfo,
        },
        {
            path: 'location/edit',
            name: 'project-settings-location-edit',
            components:
                {
                    rightContent: LocationEdit,
                },
            meta: metaInfo,
        },
        {
            path: 'progress-reports',
            name: 'project-settings-progress-reports-view',
            meta: { ...metaInfo, ...{ customRouteName: 'project-settings/parts/progress-reports/view' } },
            async beforeEnter (to, from, next) {
                const result = await getAccessByAppType(...arguments, ACCESS.PROGRESS_REPORTS)
                return result
            },
        },
        {
            path: 'progress-reports/edit',
            name: 'project-settings-progress-reports-edit',
            meta: { ...metaInfo, ...{ customRouteName: 'project-settings/parts/progress-reports/edit' } },
            async beforeEnter (to, from, next) {
                const result = await getAccessByAppType(...arguments, ACCESS.PROGRESS_REPORTS)
                return result
            },
        },
        {
            path: 'integrations',
            name: 'project-settings-integrations-view',
            components:
                {
                    rightContent: ProjectIntegration,
                },
            meta: metaInfo,
        },
        {
            path: 'integrations/edit',
            name: 'project-settings-integrations-edit',
            components:
                {
                    rightContent: ProjectIntegration,
                },
            meta: metaInfo,
        },

    ],
    async beforeEnter (to, from, next) {
        const result = await getProjectAccess(...arguments)
        return result
    },
}
