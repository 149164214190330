<template>
    <table v-if="this.data.show">
        <thead>
            <tr>
                <th class="io-aleft">EMPLOYEE</th>
                <th class="io-aleft">CURRENT UNION RATE</th>
                <th class="io-aleft">CURRENT PAY RATE</th>
                <th class="io-aleft">NEW UNION RATE</th>
                <th class="io-acenter">DECISION</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, itemIndex) in data.employees" :key="itemIndex">
                <td class="io-aleft">{{item.name}}</td>
                <td class="io-aleft">
                    {{ $filters.formatNumberUSD(item.rate_old) }}
                </td>
                <td class="io-aleft">
                    {{ $filters.formatNumberUSD(item.rate) }}
                </td>
                <td class="io-aleft">
                    {{ $filters.formatNumberUSD(item.rate_new) }}
                </td>
                <td class="io-acenter">
                    <div class="io-btns-decision-rate-change-wrapper">
                        <a class="io-btn-primary io-btn-decision-rate-change" :class="getStatusClass(item.id, 1)" v-on:click="decision(item.id, 1)">
                            KEEP DIFFERENCE {{ $filters.formatNumberUSD(item.rate_diff) }}
                        </a>
                        <a class="io-btn-primary io-btn-decision-rate-change" :class="getStatusClass(item.id, 2)" v-on:click="decision(item.id, 2)">
                            ACCEPT NEW UNION RATE {{ $filters.formatNumberUSD(item.rate_new) }}
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        mixins: [],
        data () {
            return {
                employees: [],
                show: false
            }
        },
        props: {
            widget: {
                type: String,
                required: true
            },
            data: {
                type: Object
            }
        },
        created: function () {},
        methods: {
            decision: function (employeeId, decisionId) {
                this.data.employees[employeeId]['decision'] = decisionId
            },
            getStatusClass: function (employeeId, decisionId) {
                if (this.data.employees[employeeId]['decision'] === decisionId) {
                    return 'button app-colored'
                } else {
                    return 'button'
                }
            }
        }
    }
</script>

<style scoped>
    .io-btn-decision-rate-change {
        max-width: unset !important;
    }
    .io-btn-decision-rate-change:last-of-type {
        margin-left: 16px !important;
    }
    .io-btns-decision-rate-change-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .io-aleft {
        text-align: left !important;
    }
    .io-acenter {
        text-align: center !important;
    }
</style>
