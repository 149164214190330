<template>
    <IOModal
        icon="icon-cross-rec" 
        width="536"
        :color="Color.RED"
        @close="closeModal"
    >
        <template #default>
            <div class="io-content-wrapper">
                <div>
                    <h3 class="io-content-wrapper__title">{{ $t('Are you sure you want to void this invocie?') }}</h3>
                    <div class="io-content-wrapper__sub-title">{{ $t('This action cannot be undone.') }}</div>
                </div>
                <div>
                    <IOLabel :text="$t('Note')" :is-required="true" />
                    <IOTextarea v-model="reasonText" />
                </div>
            </div>
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="closeModal">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton :disabled="!reasonText" @click="requestRevision">
                {{ $t('Yes, Void Invoice') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./VoidInvoice.ts"></script>
<style lang="scss" src="./VoidInvoice.scss" scoped></style>