import { defineComponent } from 'vue'
export default defineComponent({
    name: 'IOSingleInfoItem',
    props: {
        label: {
            type: String
        },
        info: {
            type: String,
        }
    }
})
