import { defineComponent } from 'vue'
import { ApprovalSubmissionParams } from '@/interfaces/modules/projects/financial-approvals/ApprovalSubmissionParams'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource'
import { PayAppStatus } from '@/interfaces/modules/projects/pay-apps/PayAppStatus'
import { TranslateResult } from 'vue-i18n'
import lienWaiverMixin from '@/modules/projects/modules/apps/common/pay-apps/mixins/lienWaiverMixin'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SingleApprovalAction from '@/interfaces/modules/projects/financial-approvals/SingleApprovalAction'

export default defineComponent({
    mixins: [lienWaiverMixin],
    computed: {
        ...mapState('payapps', {
            payApp: (state) => state.payApp,
            numAdjustedTasks: (state) => state.numAdjustedTasks,
            showPopupUserApproval: (state) => state.showPopupUserApproval,
            showPopupUserRejection: (state) => state.showPopupUserRejection,
            showPopupClientApproval: (state) => state.showPopupClientApproval,
            showPopupManualApproval: (state) => state.showPopupManualApproval,
            showPopupManualRejection: (state) => state.showPopupManualRejection
        }),
        ...mapState('project', {
            projectObj: (state) => state.projectObj
        }),
        ...mapGetters('payapps', {
            getFundingCovered: 'getFundingCovered',
        }),
        ...mapGetters('appStore', {
            isAppTypeOwner: 'isOwner',
            isAppTypeOwnerRep: 'isOwnerRep',
        }),
        ...mapGetters('financialApprovals', {
            isFullyApproved: 'isFullyApproved',
            isFullyRejected: 'isFullyRejected',
            isRejectedByApprover: 'isRejectedByApprover',
            isApprovedManually: 'isApprovedManually',
            noWorkflowSequenced: 'noWorkflowSequenced',
            isApprovedInternally: 'isApprovedInternally',
            allWorkflowsSequenced: 'allWorkflowsSequenced',
            isSubmittedForApproval: 'isSubmittedForApproval',
            noApprovalProcessInPlace: 'noApprovalProcessInPlace',
        }),
        submitForApprovalText (): TranslateResult {
            if (this.allWorkflowsSequenced) {
                return this.$t('Selecting ‘Submit’ will notify the first approver within the selected workflow that this Invoice is ready for their review and approval.')
            }
            if (this.noWorkflowSequenced) {
                return this.$t('Selecting ‘Submit’ will notify all approvers within the selected workflow that this Invoice is ready for their review and approval.')
            }

            return this.$t('Selecting ‘Submit’ will notify the relevant approvers within the selected workflow that this Invoice is ready for their review and approval.')
        },
        approvalSubmissionsRequestData (): ApprovalSubmissionParams {
            return {
                resource_id: this.$route.params.id,
                project_company_id: this.projectObj.project_local_id,
                approvable_resource_name: Resource.INVOICES
            }
        },
        isPendingRevision (): boolean {
            return PayAppStatus.REVISION_REQUESTED === this.payApp.status
                || PayAppStatus.REVISED_RESUBMIT === this.payApp.status
        },
        isFundingCovered (): boolean {
            if (this.isAppTypeOwnerRep || this.isAppTypeOwner) {
                this.calculateLineItems()

                // 1000000 is 100 (%) * 10.000, we convert it to decimal int representation
                return this.toInt(this.multiply([this.getFundingCovered, 100])) >= 1000000
            }

            return true // other workspaces have no need to apply funding
        },
    },
    methods: {
        ...mapActions('payapps', {
            calculateLineItems: 'calculateLineItems',
            setShowPopupUserApproval: 'setShowPopupUserApproval',
            setShowPopupUserRejection: 'setShowPopupUserRejection',
            setShowPopupClientApproval: 'setShowPopupClientApproval',
            setShowPopupManualApproval: 'setShowPopupManualApproval',
            setShowPopupManualRejection: 'setShowPopupManualRejection',
            loadPayApp: 'loadPayApp'
        }),
        ...mapActions('financialApprovals', {
            getApprovalSubmissions: 'getApprovalSubmissions'
        }),
        ...mapMutations('financialApprovals', {
            setApprovalsLoaded: 'SET_APPROVALS_LOADED'
        }),
        async fetchData (): Promise<void> {
            this.setApprovalsLoaded(false)
            await this.loadPayApp({ id: this.$route.params.id, pid: this.$route.params.pid })
            await this.getApprovalSubmissions(this.approvalSubmissionsRequestData)
            this.calculateLineItems()
            this.setApprovalsLoaded(true)
            this.getLienWaiverSettingsMixin()
        },
        async resetApprovalWorkflow (): Promise<void> {
            try {
                if (this.isSubmittedForApproval) {
                    await this.$api.delete('/approval-submissions', { params: this.approvalSubmissionsRequestData })
                }

                if (this.isApprovedManually) {
                    await this.$api.delete('/resource-manual-approval', { params: this.approvalSubmissionsRequestData })
                }

                if (!this.isSubmittedForApproval && this.isFullyRejected) {
                    await this.$api.delete('/resource-manual-rejection', { params: this.approvalSubmissionsRequestData })
                }

                this.fetchData()
            } catch (e) {
                this.errorHandle(e)
            }
        },
        handleResetApprovalWorkflow (): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to reset the approval workflow?'),
                caption: this.$t('Resetting the approval workflow will require a modification and re-submission for approval.'),
                icon: 'far fa-undo-alt',
                buttons: [
                    {
                        text: 'Cancel',
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: 'Yes, Reset Approval Workflow',
                        class: 'io-btn-primary',
                        action: async () => {
                            await this.resetApprovalWorkflow()
                        }
                    }
                ]
            })
        },
        handleSingleApprovalAction (action: SingleApprovalAction): void {
            if (SingleApprovalAction.APPROVED === action && !this.isFundingCovered) {
                this.showPopupAlert({
                    icon: 'icon-alert-triangle text-warning-darker',
                    title: this.$tc('The invoice is not fully funded. Please apply funding first to approve the invoice.'),
                    buttons: [
                        {
                            text: this.$tc('Cancel'),
                            class: 'io-btn-light',
                            action: null
                        },
                        {
                            text: this.$tc('Go to Funding Tab'),
                            class: 'io-btn-primary',
                            action: async () => {
                                this.$router.push({ name: 'payapps-invoice-funding' })
                            }
                        }
                    ]
                })

                return
            }

            ({
                [SingleApprovalAction.APPROVED]: this.setShowPopupUserApproval,
                [SingleApprovalAction.REJECTED]: this.setShowPopupUserRejection,
            })[action](true)
        }
    }
})
