const AdminArea = () => import('./views/AdminArea.vue')
const ChangeOrderRevert = () => import('./views/revert-tool/parts/ChangeOrderRevert.vue')
const RevertFinalAmendment = () => import('./views/revert-tool/parts/AmendmentRevert/Final/RevertFinalAmendment.vue')
const RevertTool = () => import('./views/revert-tool/RevertTool.vue')
const ProjectBudgetReset = () => import('./views/revert-tool/parts/ProjectBudgetReset.vue')
const ImportTool = () => import('./views/import-tool/ImportTool.vue')
const ImportList = () => import('./views/import-list/ImportList.vue')
const ImportDetails = () => import('./views/import-details/ImportDetails.vue')
const ContractRevert = () => import('./views/revert-tool/parts/ContractRevert.vue')
const RevertGmpContract = () => import('./views/revert-tool/parts/GmpRevert/RevertGmpContract.vue')
const ContractSovRevert = () => import('./views/revert-tool/parts/ContractSovRevert.vue')
const InvoiceRevert = () => import('./views/revert-tool/parts/InvoiceRevert.vue')
const ProjectCostCodesClear = () => import('./views/revert-tool/parts/ProjectCostCodesClear')
const BudgetApproval = () => import('./views/revert-tool/parts/BudgetApproval.vue')
const CheckDataMigration = () => import('./views/check-data-migration/CheckDataMigration.vue')
const ValidateDataMigrations = () => import('./views/validate-data-migrations/ValidateDataMigrations.vue')
const RfiRevert = () => import('./views/revert-tool/parts/RfiRevert.vue')
const SubmittalRevert = () => import('./views/revert-tool/parts/SubmittalRevert.vue')


export default [
    {
        path: '/admin-area',
        name: 'admin-area',
        component: AdminArea,
        children: [
            {
                path: 'revert-tool',
                name: 'admin-area-revert-tool',
                component: RevertTool,
                meta: {
                    type: 'Revert Tool',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'revert-change-order',
                name: 'admin-area-revert-change-order',
                component: ChangeOrderRevert,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert Change Order',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'revert-intermediate-amendment',
                name: 'admin-area-revert-intermediate-amendment',
                component: ChangeOrderRevert,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert Intermediate Amendment',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'revert-final-amendment',
                name: 'admin-area-revert-final-amendment',
                component: RevertFinalAmendment,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert Final Amendment and Contract',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'revert-contract',
                name: 'admin-area-revert-contract',
                component: ContractRevert,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert Contract',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'revert-gmp-contract',
                name: 'admin-area-revert-gmp-contract',
                component: RevertGmpContract,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert GMP Contract',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'revert-contract-sov',
                name: 'admin-area-revert-contract-sov',
                component: ContractSovRevert,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert Contract Sov',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'revert-budget',
                name: 'admin-area-revert-budget',
                component: ProjectBudgetReset,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Reset Budget',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'import-list',
                name: 'admin-area-import-tool-list',
                component: ImportList,
                meta: {
                    type: 'Import Tool List',
                    title: 'Import List',
                    typeName: 'Import List',
                },
            },
            {
                path: 'import-tool/:id',
                name: 'admin-area-import-tool-with-id',
                component: ImportTool,
                meta: {
                    type: 'Import Tool',
                    title: 'Import Tool',
                    typeName: 'Import Tool',
                },
            },
            {
                path: 'import-details/:id',
                name: 'admin-area-import-details',
                component: ImportDetails,
                meta: {
                    type: 'Import Details',
                    title: 'Import Details',
                },
            },
            {
                path: 'revert-invoice',
                name: 'admin-area-revert-invoice',
                component: InvoiceRevert,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert Invoice',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'clear-project-cost-codes',
                name: 'admin-area-clear-project-cost-codes',
                component: ProjectCostCodesClear,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Clear Project Cost Codes',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'budget-approval',
                name: 'admin-area-budget-approval',
                component: BudgetApproval,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert Budget',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'check-data-migration',
                name: 'check-data-migration',
                component: CheckDataMigration,
                meta: {
                    type: 'Check Data Migration',
                    typeName: 'Check Data Migration',
                    title: 'Check Data Migration'
                },
            },
            {
                path: 'validate-data-migrations',
                name: 'validate-data-migrations',
                component: ValidateDataMigrations,
                meta: {
                    type: 'Validate Data Migration',
                    typeName: 'Validate Data Migration',
                    title: 'Validate Data Migration'
                },
            },
            {
                path: 'revert-rfi-to-draft',
                name: 'admin-area-revert-rfi-to-draft',
                component: RfiRevert,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert RFI to draft',
                    title: 'Revert Tool'
                }
            },
            {
                path: 'revert-submittal-to-draft',
                name: 'admin-area-revert-submittal-to-draft',
                component: SubmittalRevert,
                meta: {
                    type: 'Revert Tool',
                    typeName: 'Revert Submittal to draft',
                    title: 'Revert Tool'
                }
            },
        ]
    }
]
