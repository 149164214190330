<template>
    <div class="io-right-panel" :class="{ 'io-collapsed': collapsed, 'io-comment-cursor': cursor === 'COMMENT' }">
        <div class="io-right-panel__header" :class="{ 'io-collapsed': collapsed }">
            <i class="icon-align-bottom" @click="collapsed = !collapsed"/>
            <ul v-if="!collapsed">
                <li
                    v-for="tab in tabs"
                    :class="{ 'io-active': rightPanelActiveTab === tab.component }"
                    @click="rightPanelActiveTab = tab.component"
                >
                    {{ tab.title }}
                </li>
            </ul>
        </div>

        <div v-if="!collapsed" class="io-right-panel__content">
            <TransitionGroup name="fade-from-top" mode="out-in">
                <DetailsTab
                    v-if="isDetailsTabActive"
                    key="1"
                    :document="document"
                />

                <MarkupsTab
                    v-if="isMarkupsTabActive"
                    key="2"
                    :document="document"
                    @cancel-adding-annotation="cancelAddingAnnotation"
                    @update-annotations="updateAnnotations"
                    @select-annotation="selectAnnotation"
                    @delete-basic-annotation="deleteBasicAnnotation"
                />
            </TransitionGroup>
        </div>
    </div>
</template>

<script lang="ts" src="./RightPanel.ts"></script>
<style lang="scss" src="./RightPanel.scss" scoped></style>
