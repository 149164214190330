<template>
    <ActionDropdown v-if="!isEditingMode" :show-menu="false" :icon-tooltip="$t('Export to PDF')" @click="exportToPdf" />
    <ActionDropdown
        v-if="!isEditingMode && availableDropdownActions.length"
        icon-class="icon-more-vert-2"
        :icon-tooltip="$t('More')"
    >
        <template #default>
            <a
                v-for="(action, index) in availableDropdownActions"
                :key="index"
                v-tooltip.bottom="{ content: action.tooltip, visible: !!action.tooltip }"
                :class="{ 'io-dropdown-item--disabled': action.disabled }"
                class="io-dropdown-item"
                @click="action.onClick()"
            >
                <span v-if="action.iconClass" class="io-dropdown-item__icon" :class="[action.iconClass]"></span>
                <span>{{ action.text }}</span>
            </a>
        </template>
    </ActionDropdown>
    <IOButton v-if="isEditingMode" variant="secondary" @click="cancelClick">
        {{ $t('Cancel') }}
    </IOButton>
    <IOButton v-if="canSaveAsDraft" variant="secondary" :disabled="loading" @click="saveAsDraft">
        {{ $t('Save as Draft') }}
    </IOButton>
    <IOButton
        v-if="AppPackageRouteName.AppPackageDetails !== $route.name && creating"
        variant="secondary"
        @click="goToPrevTab"
    >
        {{ $t('Previous Step') }}
    </IOButton>
    <IOButton
        v-if="AppPackageRouteName.AppPackageDetails == $route.name && creating"
        :disabled="!isDetailsFilled"
        @click="goToNextTab"
    >
        {{ $t('Next Step') }}
    </IOButton>
    <IOButton
        v-for="(button, key) in availablePrimaryActions"
        :key="`primary-action-${key}`"
        :icon="button.icon"
        :disabled="button.disabled"
        @click="button.onClick()"
    >
        {{ button.text }}
    </IOButton>

    <MarkAsPaidModal v-if="showMarkAsPaidModal" @close="toggleMarkAsPaidModal" @refreshData="updateDetails"/>
    <InternalRejectionModal 
        v-if="showRejectionModal"
        @update:approvalModalData="data => $emit('update:approvalModalData', data)"
        @close="toggleRejectionModal(false)"
    />
</template>

<script lang="ts" src="./TopBarActions.ts"></script>
<style lang="scss" src="./TopBarActions.scss" scoped></style>
