<template>
    <modal customClass="io-full bills-modal">
        <template #body>
            <template v-if="modalView === '1'">
                <edit-form
                    forcedSource="bills"
                    :forcedId="forcedId"
                    :forceModalClose="true"
                    :forcedData="forcedData"
                    :forceTriggerRefresh="true"
                />
            </template>
            <template v-else-if="modalView === '2'">
                <router-view />
            </template>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/new-theme/modal.vue'
import { defineAsyncComponent } from 'vue'

const EditForm = defineAsyncComponent(() => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue'))

export default {
    components: {modal, EditForm},
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            value: 0,
            modalView: '0'
        }
    },
    mounted () {
        this.modalView = $('body').attr('data-modalView')
    }
}
</script>

<style lang="scss">
    .bills-modal {
        width: 100% !important;
        left: 0 !important;
        top: 0 !important;

        .ac .multiselect__input, .sc .multiselect__input, .gc .multiselect__input, .dev .multiselect__input, .ins .multiselect__input, .ac .multiselect__single, .sc .multiselect__single, .gc .multiselect__single, .dev .multiselect__single, .ins .multiselect__single {
            border: 0;
        }

        .multiselect .multiselect__input {
            border: 0;
        }

        .input-group {
            position: relative;
        }

        .input-group-addon {
            position: absolute;
            width: 20px;
            left: 0;
            z-index: 999;
        }
    }

    .ui-widget-content .form-button {
        padding-top: 0 !important;
    }
</style>
