<template>
    <IOModal
        :title="operation.title"
        :loading="loading || dictionariesLoading || rfiLoading"
        loading-overlay-z-index="2"
        width="1100"
        slideout
        scrollable
        wide
        @close="close"
    >
        <template #header-end>
            <IOButton
                v-if="!isReSubmitMode"
                variant="secondary"
                :disabled="v$.$invalid"
                @click="submitOperation(operation.action)"
            >
                {{ saveAsDraftButtonTitle }}
            </IOButton>

            <IOButton
                :disabled="v$.$invalid"
                @click="submitOperation(operation.action, true)"
            >
                {{ submitButtonTitle }}
            </IOButton>
        </template>

        <div class="io-inputs-wrapper grid grid-cols-12 gap-24-px items-start">
            <AlertBox v-if="revisedRfiId" class="col-span-12" dismissible color="yellow">
                {{ $t('The new RFI will link to the previous RFI.') }}
            </AlertBox>

            <div class="grid grid-cols-12 gap-24-px col-span-8">
                <div class="col-span-12">
                    <IOLabel is-required for="title">{{ $t('Title') }}</IOLabel>

                    <IOInput
                        id="title"
                        autofocus
                        maxlength="200"
                        :valid="!v$.title.$error"
                        :error-message="$t('The {field} field is required', { field: $t('Title') })"
                        v-model="title"
                    />
                </div>

                <div class="col-span-6 relative z-2">
                    <IOLabel>{{ $t('Responsible Contractor') }}</IOLabel>

                    <Multiselect
                        v-if="companies"
                        data-testid="responsible-contractor-multiselect"
                        track-by="id"
                        label="name"
                        :options="companies"
                        allow-empty
                        :close-on-select="false"
                        multiple
                        v-model="responsibleContractors"
                    >
                        <template #singleLabel="props">
                            <Avatar
                                :employee-mongo-id="props.option.id"
                                :employee-full-name="props.option.name"
                                :app-type="props.option.app_type"
                                size="sm"
                                class="h-full"
                                show-full-name
                            />
                        </template>

                        <template #option="props">
                            <Avatar
                                :employee-mongo-id="props.option.id"
                                :employee-full-name="props.option.name"
                                :app-type="props.option.app_type"
                                size="sm"
                                class="h-full"
                                type="company"
                                show-full-name
                            />
                        </template>

                        <template #selection="{ values, search }">
                            <span v-if="values.length > 1 && search.length === 0" class="multiselect__single">
                                {{
                                    $tc('One Contractor Selected | {count} Contractors Selected', values.length, { count: values.length })
                                }}
                            </span>

                            <Avatar
                                v-else-if="values.length === 1 && search.length === 0"
                                :employee-mongo-id="values[0]?.id"
                                :employee-full-name="values[0]?.name"
                                :app-type="values[0]?.app_type"
                                size="sm"
                                type="company"
                                class="h-full"
                                show-full-name
                            />
                        </template>
                    </Multiselect>
                </div>

                <div class="col-span-6">
                    <div class="flex items-baseline">
                        <IOLabel is-required>
                            {{ $t('RFI Manager') }}
                        </IOLabel>

                        <TooltipIcon
                            :text="$t('RFI Manager is the person who manages the full RFI process by submitting an item for review and response to the Official Design Reviewer, then closing and distributing to the Impacted Parties.')"
                        />
                    </div>

                    <InvitePreview
                        show-app-type-for-single-contact
                        data-testid="rfi-manager-invite-preview"
                        component-key="rfi-manager"
                        :preview-type="PreviewTypes.CONTACT_LIST_FULL"
                        :button-size="ButtonSize.SMALL"
                        :select-multiple="false"
                        :show-status-for-single-contact="false"
                        :button-label-text="$t('Assign')"
                        button-icon="icon-user-plus"
                        :invited-contact-persons="rfiManager ? [rfiManager] : []"
                        disable-bookmarking
                        show-contacts-only
                        @invite-modal-done="updateRfiManager"
                    />
                </div>

                <div v-if="!isSubcontractor" class="col-span-6">
                    <div class="flex items-baseline">
                        <IOLabel is-required>{{ $t('Official Design Reviewer') }}</IOLabel>

                        <div class="io-margin-top-minus">
                            <TooltipIcon
                                :text="$t('Official Reviewer is the user who is responsible for providing an Official Solution. They can also pass the Ball in Court to other users such as consultants or owners to provide additional comments or direction.  Official Reviewers are typically the architect of record on the project.')"
                            />
                        </div>
                    </div>

                    <InvitePreview
                        show-app-type-for-single-contact
                        data-testid="rfi-official-reviewer-invite-preview"
                        component-key="rfi-official-reviewer"
                        :preview-type="PreviewTypes.CONTACT_LIST_FULL"
                        :button-size="ButtonSize.SMALL"
                        :select-multiple="false"
                        :show-status-for-single-contact="false"
                        :button-label-text="$t('Assign')"
                        button-icon="icon-user-plus"
                        :invited-contact-persons="officialReviewer ? [officialReviewer] : []"
                        disable-bookmarking
                        show-contacts-only
                        @invite-modal-done="updateOfficialReviewer"
                    />
                </div>

                <div v-if="!isSubcontractor" class="col-span-6">
                    <div class="flex items-baseline">
                        <IOLabel>{{ $t('Additional Design Reviewers') }}</IOLabel>

                        <div class="io-margin-top-minus">
                            <TooltipIcon
                                :text="$t('Additional Design Reviewers are typically design consultants or engineers that a user wants to  notify and be involved in a design review simultaneously with the submission to the Official Design Reviewer.')"
                            />
                        </div>
                    </div>

                    <InvitePreview
                        show-app-type-for-single-contact
                        :allowed-clients-types="additionalReviewersAllowedClientTypes"
                        data-testid="rfi-additional-reviewers-invite-preview"
                        component-key="rfi-additional-reviewers"
                        :preview-type="PreviewTypes.HORIZONTAL_LIST"
                        :button-size="ButtonSize.SMALL"
                        :show-status-for-single-contact="false"
                        :button-label-text="$t('Assign')"
                        button-icon="icon-user-plus"
                        :invited-contact-persons="additionalReviewers"
                        disable-bookmarking
                        show-contacts-only
                        @invite-modal-done="updateAdditionalReviewers"
                    />
                </div>

                <div class="col-span-6">
                    <IOLabel>{{ $t('Impacted Parties') }}</IOLabel>

                    <InvitePreview
                        show-app-type-for-single-contact
                        data-testid="impacted-parties-invite-preview"
                        component-key="impacted-parties"
                        :preview-type="PreviewTypes.HORIZONTAL_LIST"
                        :button-size="ButtonSize.SMALL"
                        :show-status-for-single-contact="false"
                        :button-label-text="$t('Assign')"
                        button-icon="icon-user-plus"
                        :invited-contact-persons="impactedParties"
                        disable-bookmarking
                        show-contacts-only
                        @invite-modal-done="updateImpactedParties"
                    />
                </div>

                <div class="col-span-6">
                    <IOLabel>{{ $t('Priority') }}</IOLabel>

                    <Multiselect
                        data-testid="priority-multiselect"
                        track-by="id"
                        class="io-priority-multiselect"
                        :searchable="false"
                        :options="priorityOptions"
                        :allow-empty="false"
                        preselect-first
                        v-model="priority"
                    >
                        <template #singleLabel="{ option: { name, variant } }">
                            <StatusPill :text="name" :variant="variant"/>
                        </template>

                        <template #option="{ option: { name, variant } }">
                            <StatusPill :text="name" :variant="variant"/>
                        </template>
                    </Multiselect>
                </div>

                <div class="col-span-6">
                    <IOLabel>{{ $t('Due Date') }}</IOLabel>

                    <FieldDateTime
                        data-testid="due-date-picker"
                        class="w-full"
                        :options="datePickerSettings"
                        v-model="dueDate"
                    />
                </div>

                <div v-if="false" class="col-span-6">
                    <IOLabel>{{ $t('Tags') }}</IOLabel>

                    <Multiselect
                        track-by="name"
                        label="name"
                        searchable
                        taggable
                        multiple
                        class="io-multiselect--title"
                        :options="tags"
                        :placeholder="$t('Write tag and hit ”Enter”')"
                        v-model="tags"
                        @tag="tags.push({ id: null, name: $event })"
                    >
                        <template #noOptions>
                            {{ $t('List is empty') }}
                        </template>
                    </Multiselect>
                </div>

                <div class="col-span-12">
                    <IOLabel is-required for="question">{{ $t('Question') }}</IOLabel>

                    <IOTextarea
                        id="question"
                        :valid="!v$.question.$error"
                        auto-height
                        v-model="question"
                    />

                    <Files
                        class="mt-16-px"
                        array-id="question-files"
                        :model-id="rfiId"
                        model-type="rfi_v3"
                        color-variant-light
                        :add-to-temporary="!isEditMode"
                        :show-search="false"
                        force-shared
                        files-in-new-tab
                        show-shared
                        :variant="FilesUploaderVariantEnum.SKINNY"
                        @new-file-added="onDocumentAdded"
                        @temp-file-remove="onDocumentRemoved"
                    />
                </div>

                <div class="col-span-12">
                    <IOSwitcher :title="$t('Propose Solution')" v-model="isSolutionProposed"/>
                </div>

                <div v-if="isSolutionProposed" class="col-span-12">
                    <IOLabel is-required for="solution">{{ $t('Solution') }}</IOLabel>

                    <IOTextarea
                        id="solution"
                        :valid="!v$.initialSolution.text.$error"
                        auto-height
                        v-model="initialSolution.text"
                    />

                    <Files
                        class="mt-16-px"
                        array-id="solution-files"
                        color-variant-light
                        :add-to-temporary="!isEditMode || isReviewMode"
                        :show-search="false"
                        force-shared
                        files-in-new-tab
                        show-shared
                        :variant="FilesUploaderVariantEnum.SKINNY"
                        @new-file-added="onSolutionDocumentAdded"
                        @temp-file-remove="onSolutionDocumentRemoved"
                    />

                    <div class="d-flex mt-7 gap-7">
                        <div class="flex-1">
                            <CostImpact v-model="initialSolution.costImpact"/>
                        </div>

                        <div class="flex-1">
                            <ScheduleImpact v-model="initialSolution.scheduleImpact"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-12 col-span-4 gap-24-px">
                <div class="col-span-12">
                    <IOLabel>{{ $t('Status Change after Submission') }}</IOLabel>

                    <StatusChange
                        data-testid="status-change"
                        class="py-8-px"
                        :statuses="statuses"
                    />
                </div>

                <div class="col-span-12">
                    <IOLabel>{{ $t('Specification Reference') }}</IOLabel>

                    <SpecReferenceSelect
                        class="mt-12-px"
                        :editable="!initialSpecReference"
                        v-model="specReference"
                    />
                </div>

                <div class="col-span-12">
                    <IOLabel>{{ $t('Drawing Reference') }}</IOLabel>

                    <DrawingReferenceSelect
                        class="mt-12-px"
                        :editable="!initialDrawingReferences?.length"
                        pin-color="#FB6A00"
                        cursor-class="io-adding-rfi"
                        type="pin"
                        v-model="drawingReferences"
                    />
                </div>

                <div v-if="false" class="col-span-12">
                    <IOLabel>{{ $t('Jobsite Locations') }}</IOLabel>

                    <JobsiteLocationsSelect
                        class="mt-12-px"
                        editable
                        multiple
                        v-model="jobsiteLocations"
                    />
                </div>
            </div>
        </div>
    </IOModal>
</template>

<script lang="ts" src="./RfiOperationsSlideout.ts"></script>
<style lang="scss" src="./RfiOperationsSlideout.scss" scoped></style>
