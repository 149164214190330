const BidManualManagement = () => import(/* webpackChunkName: "BidManualManagement" */ '@/io-modules/bid-requests/views/bid-manual-management/BidManualManagement.vue')

export default [
    {
        path: '/project/:pid/bid-manual-management/:package/:action/:quoteId?',
        name: 'bid-manual-management',
        component: BidManualManagement,
        meta: {
            title: 'Bid Manual Management',
        }
    },
]
