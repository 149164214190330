<template>
    <td>
        <div v-if="value" class="io-holder-cell">
            <CompanyLogo
                :logo="value.id"
                :size="LogoSize.MEDIUM"
            />

            <div class="io-holder-cell__info">
                <span
                    v-if="nameLabel"
                    class="io-holder-cell__info-name"
                >
                    {{ nameLabel }}
                </span>

                <span
                    v-if="value.type"
                    class="io-holder-cell__info-role"
                >
                    {{ value.type }}
                </span>
            </div>
        </div>
        <div v-else>
            <span>-</span>
        </div>
    </td>
</template>

<script lang="ts" src="./HolderCell.ts"></script>
<style lang="scss" src="./HolderCell.scss" scoped></style>
