<template>
    <div class="io-supporting-documents">
        <LienWaivers v-if="!isNonContractedInvoice" />
        <FilesComponent
            :key="filesArrayId"
            model-type="invoices"
            :model-id="modelId"
            :title="$t('Supporting Documents')"
            :show-search="true"
            :array-id="filesArrayId"
            :show-shared="false"
            :force-hide-shared-tab="true"
            :show-uploader="isEditingMode"
            :hide-share-column="true"
            :add-to-temporary="isEditingMode"
            :editable="isEditingMode"
            :files-in-new-tab="true"
            :all-tabs-visible="true"
            @newFileAdded="onFileAdd"
            @tempFileRemove="onFileRemove"
        />
    </div>
</template>

<script lang="ts" src="./SupportingDocuments.ts"></script>
<style lang="scss" src="./SupportingDocuments.scss" scoped></style>