export enum ApplicationPackageStatus {
    ReviewedAndApproved = 'reviewed_and_approved',
    PartiallySubmittedForFunding = 'partially_submitted_for_funding',
    SubmittedForFunding = 'submitted_for_funding',
    PartiallyRequestedRevision = 'partially_requested_revision',
    RequestedRevision = 'requested_revision',
    PartiallyApproved = 'partially_approved',
    PartiallyApprovedForFunding = 'partially_approved_for_funding',
    ApprovedForFunding = 'approved_for_funding',

    //V3
    Draft = 'draft',
    SubmittedToClient = 'submitted_to_client',
    RejectedByClient = 'rejected_by_client',
    ApprovedByClient = 'approved_by_client',
    SubmittedToAccounting = 'submitted_to_accounting',
    RejectedByAccounting = 'rejected_by_accounting',
    PartiallyPaid = 'partially_paid',
    Paid = 'paid',

    //Statuses for owner
    PendingInternalApproval = 'pending_internal_approval',
    InternallyRejected = 'internally_rejected',
    InternallyApproved = 'internally_approved',
}

export default ApplicationPackageStatus
