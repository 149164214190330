import * as actions from './actions'
import * as mutations from './mutations'

export default {
    namespaced: true,
    namespace: 'notification/',
    state () {
        return {
            show: false,
            timeoutsList: [],
            banners: [],
            content: [],
            budgetNotification: {
                id: null,
                budget_type: null,
                project_id: null,
                company_id: null
            }
        }
    },
    actions,
    mutations
}
