const SettingsFolderStructure = () => import(/* webpackChunkName: "settings" */ '@/views/settings/folder-structure/folder-structure.vue')
const SettingsTrustedDomains = () => import(/* webpackChunkName: "settings" */ '@/views/settings/trusted-domains/TrustedDomains.vue')
const TrustedDomainsAcceptScreen = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/trusteddomains/accept-screen/AcceptScreen.vue')
const TrustedDomainsRejectScreen = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/trusteddomains/reject-screen/RejectScreen.vue')

const SettingsTrustedInvitations = () => import(/* webpackChunkName: "settings" */ '@/views/settings/trusted-domains-invitations.vue')
const InvitationFromEmail = () => import(/* webpackChunkName: "settings" */ '@/views/settings/invitation-from-email.vue')
const SettingsProjects = () => import(/* webpackChunkName: "settings" */ '@/views/settings/projects.vue')

const Exports = () => import(/* webpackChunkName: "settings" */ '@/views/settings/exports.vue')
const CompanyInformation = () => import(/* webpackChunkName: "settings" */ '@/views/settings/company-information.vue')
const ListDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/standard-listing-view.vue')
const FormDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue')
const ViewDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/view.vue')
const ProjectsWorkingHours = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/projects/working-hours/view.vue')
const ProjectPhases = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/projects/project-phases/ProjectPhases.vue')
const ProjectTypes = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/projects/project-types/ProjectTypes.vue')
const SectorIndustry = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/projects/sector-industry/SectorIndustry.vue')
const ProjectsWorkingHoursEdit = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/projects/working-hours/edit.vue')
const ProjectsCustomAttributesSharing = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/projects/custom-attributes-sharing/CustomAttributesSharing.vue')

const ProjectKanban = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/project-kanban/list')
const Settings = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/layout/settings.vue')
const BusinessUnits = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/business-units/BusinessUnits.vue')
const Dictionary = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/dictionary/Dictionary.vue')
const EmailNotification = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/emailnotifications/main.vue')
const SettingsPaymentDetails = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/payment-details/payment-details.vue')
const SettingsApi = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/api/main.vue')
const SettingsApiList = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/api/views/list/main.vue')
const OfficeLocations = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/office-locations/OfficeLocations.vue')
const IntegrationMarketplace = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/integration-marketplace/IntegrationMarketplace.vue')
const WorkspaceMarketplace = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/integration-marketplace/views/workspace/IntegrationMarketplaceWorkspace.vue')
const PersonalMarketplace = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/integration-marketplace/views/personal/IntegrationMarketplacePersonal.vue')
const DailyReportsCompanySettings = () => import(/* webpackChunkName: "settings" */ '@/io-modules/daily-reports/components/company-settings/CompanySettings.vue')
const PasswordManagementReset = () => import('@/modules/settings/password-management/password-reset/PasswordManagementReset.vue')
const PasswordManagementPolicy = () => import('@/modules/settings/password-management/policy/PasswordManagementPolicy.vue')
const SingleSignOn = () => import('@/modules/settings/password-management/sso/SingleSignOn.vue')
const Meetings = () => import('@/modules/settings/meetings/Meetings.vue')
const OAuthApps = () => import(/* webpackChunkName: "settings" */ '@/modules/settings/oauth-apps/OAuthApps.vue')
const MeetingsMinutes = () => import('@/modules/settings/meetings/components/tabs/tab-minutes/TabMinutes.vue')
const IntegrationsShow = () => import('@/modules/settings/integrations-show/IntegrationsShow.vue')

import { isFeatureFlagEnabled } from '@/modules/projects/routes/feature-flag-status'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'

export default [
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        redirect: { name: 'settings-information' },
        children: [
            {
                path: 'trusteddomains',
                name: 'trusted-domains',
                component: SettingsTrustedDomains,
                meta: {
                    title: 'Settings - Trusted Domains',
                },
            },
            {
                path: 'trusted-domain/confirm/:id',
                name: 'confirm-trusted-domain',
                component: TrustedDomainsAcceptScreen,
                meta: {
                    title: 'Settings - Confirm Trusted Domain',
                },
            },
            {
                path: 'trusted-domain/reject/:id',
                name: 'reject-trusted-domain',
                component: TrustedDomainsRejectScreen,
                meta: {
                    title: 'Settings - Reject Trusted Domain',
                },
            },
            {
                path: 'payment-details',
                name: 'settings-payment-details',
                component: SettingsPaymentDetails,
                meta: {
                    schema: 'settings/payment-details',
                    title: 'Settings - Payment Details',
                },
            },
            {
                path: 'approveinvitationfromemail/:id',
                name: 'accept-invite',
                component: InvitationFromEmail,
                meta: {
                    schema: 'settings/acceptInvite',
                    title: 'Settings - Approve Invitation',
                },
                redirect: { name: 'trusted-domains-invitations' },
            },
            {
                path: 'denyinvitationfromemail/:id',
                name: 'deny-invite',
                component: InvitationFromEmail,
                meta: {
                    schema: 'settings/denyInvite',
                    title: 'Settings - Deny Invitation',
                },
                redirect: { name: 'trusted-domains-invitations' },
            },
            {
                path: 'invitations',
                name: 'trusted-domains-invitations',
                component: SettingsTrustedInvitations,
                meta: {
                    schema: 'settings/trusteddomains/invitations',
                    title: 'Settings - Trusted Domain Invitations',
                },
            },
            {
                path: 'api',
                name: 'settings-api',
                component: SettingsApi,
                children: [
                    {
                        path: 'list',
                        name: 'settings-api-list',
                        component: SettingsApiList,
                        meta: {
                            title: 'Settings - API',
                        },
                    },
                ],
            },
            {
                path: 'oauth',
                name: 'settings-oauth',
                component: OAuthApps,
                meta: {
                    title: 'Settings - OAuth Applications',
                },
            },
            {
                path: 'folders',
                name: 'folder-structure',
                component: SettingsFolderStructure,
                meta: {
                    schema: 'settings/folders',
                    title: 'Settings - Folder',
                },
            },
            {
                path: 'business-units',
                name: 'settings-business-units',
                component: BusinessUnits,
                meta: {
                    title: 'Settings - Business Units',
                },
            },
            {
                path: 'meetings',
                name: 'settings-meetings',
                component: Meetings,
                meta: {
                    title: 'Settings - Meetings',
                },
                redirect: { name: 'settings-meetings-minutes' },
                children: [
                    {
                        path: 'minutes',
                        name: 'settings-meetings-minutes',
                        component: MeetingsMinutes,
                        meta: { title: 'Settings - Meetings Minutes' },
                    },
                ],
            },
            {
                path: 'office-locations',
                name: 'settings-office-locations',
                component: OfficeLocations,
                meta: {
                    title: 'Settings - Office Locations',
                },
            },
            {
                path: 'dictionary',
                name: 'settings-dictionary',
                component: Dictionary,
                meta: {
                    title: 'Settings - Dictionary',
                },
            },
            {
                path: 'folders/edit/:id',
                name: 'folder-structure-edit',
                component: FormDefault,
                meta: {
                    schema: 'settings/folders',
                    title: 'Settings - Edit Folder',
                },
            },
            {
                path: 'folders/delete/:id',
                name: 'folder-structure-delete',
                component: FormDefault,
                meta: {
                    schema: 'settings/folders',
                    title: 'Settings - Delete Folder',
                },
            },
            {
                path: 'company',
                name: 'settings-information',
                redirect: { name: 'settings-information-general' },
                component: CompanyInformation,
                children: [
                    {
                        path: 'general',
                        name: 'settings-information-general',
                        component: ViewDefault,
                        meta: {
                            schema: 'settings/company-information/general',
                            title: 'Settings - Company Information',
                        },
                    },
                    {
                        path: 'general/edit',
                        name: 'settings-information-general-edit',
                        component: FormDefault,
                        meta: {
                            schema: 'settings/company-information/general',
                            title: 'Settings - Edit Company Information',
                        },
                    },
                ],
            },

            // Preferences
            {
                path: 'preferences',
                name: 'settings-preferences',
                component: ViewDefault,
                meta: {
                    schema: 'settings/preferences',
                    title: 'Settings - Preferences',
                },
            },
            {
                path: 'preferences/edit',
                name: 'settings-preferences-edit',
                component: FormDefault,
                meta: {
                    schema: 'settings/preferences',
                    title: 'Settings - Edit Preferences',
                },
            },
            {
                path: 'project-kanban',
                name: 'settings-project-kanban',
                component: ProjectKanban,
                meta: {
                    title: 'Settings - Project Kanban',
                },
            },
            {
                path: 'project-kanban/edit',
                name: 'settings-project-kanban-new',
                component: FormDefault,
                meta: {
                    schema: 'settings/project-kanban/column',
                    title: 'Settings - Add Project Kanban',
                },
            },
            {
                path: 'project-kanban/edit/:id',
                name: 'settings-project-kanban-edit',
                meta: {
                    schema: 'settings/project-kanban/column',
                    title: 'Settings - Edit Project Kanban',
                },
                component: FormDefault,
            },
            {
                path: 'emailnotifications',
                name: 'settings-emailnotifications',
                component: EmailNotification,
                meta: {
                    schema: 'settings/emailnotifications',
                    title: 'Settings - Email Notifications',
                },
            },
            {
                path: 'stamps',
                name: 'settings-stamps',
                meta: {
                    schema: 'settings/stamps',
                    title: 'Settings - Stamps',
                },
                component: ListDefault,
            },
            {
                path: 'stamps-edit',
                name: 'settings-stamps-add',
                component: FormDefault,
                meta: {
                    schema: 'settings/stamps',
                    title: 'Settings - Add Stamps',
                },
            },
            {
                path: 'stamps-edit/:id',
                name: 'settings-stamps-edit',
                component: FormDefault,
                meta: {
                    schema: 'settings/stamps',
                    title: 'Settings - Edit Stamp',
                },
            },
            {
                path: 'exports',
                name: 'settings-exports',
                redirect: { name: 'settings-exports-general' },
                component: Exports,
                children: [
                    {
                        path: 'general',
                        name: 'settings-exports-general',
                        component: ViewDefault,
                        meta: {
                            schema: 'settings/exports/general',
                            title: 'Settings - Exports General',
                        },
                    },
                    {
                        path: 'general/edit',
                        name: 'settings-exports-general-edit',
                        component: FormDefault,
                        meta: {
                            schema: 'settings/exports/general',
                            title: 'Settings - Edit Exports General',
                        },
                    },
                    {
                        path: 'proposal',
                        name: 'settings-exports-proposal',
                        component: ViewDefault,
                        meta: {
                            schema: 'settings/exports/proposal',
                            title: 'Settings - Exports Proposal',
                        },
                    },
                    {
                        path: 'proposal/edit',
                        name: 'settings-exports-proposal-edit',
                        component: FormDefault,
                        meta: {
                            schema: 'settings/exports/proposal',
                            title: 'Settings - Edit Exports Proposal',
                        },
                    },
                ],
            },
            {
                path: 'projects',
                name: 'settings-projects',
                redirect: { name: 'settings-projects-working-hours' },
                component: SettingsProjects,
                children: [
                    {
                        path: 'working-hours',
                        name: 'settings-projects-working-hours',
                        component: ProjectsWorkingHours,
                        meta: {
                            title: 'Settings - Projects Working Hours',
                        },
                    },
                    {
                        path: 'project-phases',
                        name: 'settings-project-phases',
                        component: ProjectPhases,
                        meta: {
                            title: 'Settings - Project Phases',
                        },
                    },
                    {
                        path: 'project-types',
                        name: 'settings-project-types',
                        component: ProjectTypes,
                        meta: {
                            title: 'Settings - Project Types',
                        },
                    },
                    {
                        path: 'sector-industry',
                        name: 'settings-sector-industry',
                        component: SectorIndustry,
                        meta: {
                            title: 'Settings - Sector / Industry',
                        },
                    },
                    {

                        path: 'edit-working-hours',
                        name: 'settings-projects-working-hours-edit',
                        component: ProjectsWorkingHoursEdit,
                        meta: {
                            title: 'Settings - Edit Projects Working Hours',
                        },
                    },
                    {
                        path: 'progress-report',
                        name: 'settings-projects-progress-report',
                        component: ViewDefault,
                        meta: {
                            schema: 'settings/projects/progress-report',
                            title: 'Settings - Projects Progress Report',
                        },
                    },
                    {
                        path: 'progress-report/edit',
                        name: 'settings-projects-progress-report-edit',
                        component: FormDefault,
                        meta: {
                            schema: 'settings/projects/progress-report',
                            title: 'Settings - Edit Projects Progress Report',
                        },
                    },
                    {
                        path: 'project-journal',
                        name: 'settings-projects-project-journal',
                        component: ViewDefault,
                        meta: {
                            schema: 'settings/projects/project-journal',
                            title: 'Settings - Project Journal',
                        },
                        beforeEnter (to, from, next) {
                            next({ name: 'settings-projects' })
                        },
                    },
                    {
                        path: 'project-journal/edit',
                        name: 'settings-projects-project-journal-edit',
                        component: FormDefault,
                        meta: {
                            schema: 'settings/projects/project-journal',
                            title: 'Settings - Edit Project Journal',
                        },
                        beforeEnter (to, from, next) {
                            next({ name: 'settings-projects' })
                        },
                    },
                    {
                        path: 'custom-attributes-sharing',
                        name: 'settings-projects-custom-attributes-sharing',
                        component: ProjectsCustomAttributesSharing,
                        meta: {
                            ignoreHideLoading: true,
                            gnoreHideNotification: true,
                            title: 'Settings - Custom Attributes',
                        },
                    },
                    {
                        path: 'custom-attributes-sharing/edit',
                        name: 'settings-projects-custom-attributes-sharing-edit',
                        component: ProjectsCustomAttributesSharing,
                        meta: {
                            ignoreHideLoading: true,
                            gnoreHideNotification: true,
                            title: 'Settings - Edit Custom Attributes',
                        },
                    },
                ],
            },
            {
                path: 'integration-marketplace',
                name: 'settings-integration-marketplace',
                redirect: { name: 'settings-integration-marketplace-workspace' },
                component: IntegrationMarketplace,
                props: { type: 'workspace' },
                children: [
                    {
                        path: 'workspace',
                        name: 'settings-integration-marketplace-workspace',
                        component: WorkspaceMarketplace,
                        meta: {
                            title: 'Settings - Integration Marketplace',
                        },
                    },
                ],
            },
            {
                path: 'integrations',
                name: 'company-settings-integrations',
                component: IntegrationsShow,
                props: true,
                meta: {
                    title: 'Settings - Integration Hub',
                },
            },
            {
                path: 'daily-reports',
                name: 'daily-reports-company-settings',
                component: DailyReportsCompanySettings,
                meta: {
                    title: 'Settings - Daily Reports',
                },
                beforeEnter (to, from, next) {
                    to.meta.dailyReportsOriginRoute = from.name === 'daily-reports-create' ? from : null
                    return next()
                },
            },
            {
                path: 'password-management/policy',
                name: 'password-management-company-settings',
                component: PasswordManagementPolicy,
                meta: {
                    title: 'Settings - Password Management',
                },
            },
            {
                path: 'password-management/password-reset',
                name: 'password-reset-company-settings',
                component: PasswordManagementReset,
                meta: {
                    title: 'Settings - Password Management',
                },
            },
            {
                path: 'password-management/sso',
                name: 'sso-company-settings',
                component: SingleSignOn,
                meta: {
                    title: 'Settings - SSO',
                },
            },
        ],
    },
]
