<template>
    <div class="io-others-tab" :class="{ 'io-white-background': !otherMarkups.length }">
        <div v-if="otherMarkups.length" v-click-outside="annotationDeselected">
            <SingleMarkup
                v-for="markup in otherMarkups"
                :key="markup.id"
                :index="markup.index"
                :data="markup"
                @delete-pdftron-markup="deletePdftronMarkup"
                @annotation-deleted="annotationDeleted"
            />
        </div>

        <IOPlaceholder
            v-else
            :subtitle="$t('There are no annotations on this drawing yet.')"
            iconColor="var(--main-lighten-2)"
            class="bg-white"
        />
    </div>
</template>

<script lang="ts" src="./Others.ts"></script>
<style lang="scss" src="./Others.scss" scoped></style>
