import fineUploader from 'fine-uploader'
import { mapGetters, mapState } from 'vuex'
import SelectFilesPopup from '@/components/files-new/parts/select-files-popup/SelectFilesPopup.vue'
import { isEmpty } from 'lodash'

export default {
    components: {
        SelectFilesPopup
    },
    props: {
        'model': {},
        'addToTemporary': {},
        'arrayId': {},
        'modelType': {},
        'filesSection': {},
        'subSection': {},
        'forceShared': {},
        'modelId': {},
        'validationOptions': {},
        'inDocumentsUpload': {},
        'projectLocalId': {},
        'defaultShare': {
            type: Boolean,
            required: false,
            default: false,
        },
        'threadId': {},
        'multipleFiles': {
            type: Boolean,
            required: false,
            default: true
        },
        'additionalRequestData': {
            type: Object,
            required: false,
            default: () => ({})
        },
        customIcon: {
            type: String,
            default: ''
        },
        customTitle: {
            type: String,
            default: ''
        },
    },
    data () {
        return {
            progressCount: 0,
            loader: false,
            instance: {},
            isProjectView: false,
            countFiles: 0,
            folderCreated: false,
            selectFilesPopupVisible: false,
            clonedModelId: null,
            buttonId: null,
        }
    },
    beforeMount () {
        this.setModelId()
    },
    mounted () {
        if (this.$route.name === 'project-documents-list') {
            this.isProjectView = true
        }

        if (document.getElementById(this.setElementId)) {
            this.init()
        }

        this.buttonId = `io-upload-from-library-${ this.arrayId }-${ this.filesSection }`
        const uploadFromLibHtml = `<div id="${ this.buttonId }">${ this.$t('Select from Ingenious Library') }</div>`

        document.getElementById(`io-upload-from-library-holder-${ this.arrayId }-${ this.filesSection }`).innerHTML = uploadFromLibHtml
        document.getElementById(this.buttonId).addEventListener('click', this.onUploadFromLibrary)

        this.$emit('multipleFilesChange', this.multipleFiles)

        // for storybook preview
        if (this.modelId === 'storybook') {
            this.loader = true
            this.progressCount = 50
        }
    },
    beforeUnmount () {
        const buttonElement = document.getElementById(this.buttonId)
        if (buttonElement) {
            buttonElement.removeEventListener('click', this.onUploadFromLibrary)
        }

        // destroy drag and drop listeners
        this.instance?._dnd?.dispose()
    },
    computed: {
        ...mapGetters(
            'appStore', ['getAuthData']
        ),
        ...mapState('bidrequest', {
            tabActive: state => state.tabActive
        }),
        setElementId () {
            return 'fileUpload-' + this.arrayId + '-' + this.filesSection + '-' + this.subSection
        },
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        templateName () {
            return 'qq-template-base-' + this.generateUuid()
        },
        uploaderIcon () {
            return this.customIcon.length > 0 ? this.customIcon : 'far fa-file-import'
        },
        uploaderTitle () {
            return this.customTitle.length > 0 ? this.customTitle : this.$t('Import file')
        }
    },
    methods: {
        init () {
            let params = {
                section: this.filesSection,
                section_uuid: this.subSection,
                can_be_shared: this.forceShared || this.defaultShare ? 1 : 0,
                model_type: this.modelType,
                in_documents_upload: this.inDocumentsUpload || this.isProjectView,
                folder_id: this.folderFlags && this.folderFlags._id ? this.folderFlags._id : null,
                ...this.additionalRequestData
            }
            if (this.threadId) {
                params.thread_id = this.threadId
            }
            this.instance = new fineUploader.FineUploader({
                element: document.getElementById(this.setElementId),
                multiple: this.multipleFiles,
                template: this.templateName,
                request: {
                    endpoint: this.getEndpoint(),
                    filenameParam: 'fileName',
                    inputName: 'file',
                    params: params
                },
                cors: {
                    expected: true,
                    sendCredentials: true,
                },
                thumbnails: {
                    placeholders: {
                        waitingPath: '/js/fine-uploader/placeholders/waiting-generic.png',
                        notAvailablePath: '/js/fine-uploader/placeholders/not_available-generic.png'
                    }
                },
                deleteFile: {
                    enabled: true,
                    method: 'POST',
                    endpoint: '/files/uploadremove'
                },
                autoUpload: this.addToTemporary || this.inDocumentsUpload,
                validation: {
                    stopOnFirstInvalidFile: false,
                    acceptFiles: this.validationOptions?.acceptFiles,
                },
                callbacks: {
                    onValidate: (data) => {
                        let extension = data.name.split('.').pop().toLowerCase()
                        if (this.validationOptions.allowedExtensions.length > 0) {
                            if (this.validationOptions.allowedExtensions.includes(extension)) {
                                return true
                            } else {
                                const allowedExtensionsText = this.validationOptions.allowedExtensions.join(', ').toUpperCase()
                                this.showPopupAlert({
                                    title: this.$t('File extension not allowed.'),
                                    caption: `${ this.$t('Accepted extensions: ') } ${ allowedExtensionsText }`,
                                    icon: 'icon-cross-circle',
                                    buttons: [
                                        {
                                            text: this.$t('Close'),
                                            class: 'io-btn-light',
                                            action: null,
                                        },
                                    ],
                                })

                                return false
                            }
                        }
                        if (data.size === 0) {
                            this.showPopupAlert({
                                title: this.$t('Empty File'),
                                caption: data.name + ' ' + this.$t('is empty.'),
                                icon: 'icon-alert-triangle',
                                buttons: [
                                    {
                                        text: this.$t('Close'),
                                        class: 'io-btn-light',
                                        action: null,
                                    },
                                ],
                            })

                            return false
                        }
                        return true
                    },
                    onError: (id, name, errorReason) => {
                        this.loader = false
                        // @fixme find out why xhr.status always return 0
                        if (errorReason.toLocaleLowerCase() === 'unauthorized') {
                            this.showNotification('error', this.$t('Your session has expired. Refresh the page and upload your file again'))
                            return
                        }
                        this.showNotification('error', fineUploader.format('Error on file number {} - {}.  Reason: {}', id, name, errorReason))
                    },
                    onComplete: (id, name, response) => {
                        if (response.code !== 200) {
                            return
                        }
                        response.name = name
                        this.$emit('updateDocuments', response)
                        this.showNotification('success', this.$t('Your file has been uploaded successfully.'))
                        this.loader = false
                    },
                    onAllComplete: (succeeded, failed) => {
                        if (
                            succeeded.length !== 0
                            && this.modelType === 'bidrequests'
                            && 'project-bid-requests-wizard' !== this.$route.name
                        ) {
                            this.postFilesInfo(this.instance.getUploads().filter((value, index) => succeeded.includes(index)), this.tabActive.id)
                        }
                        this.loader = false
                        this.progressCount = 0
                    },
                    onTotalProgress: (uploadedBytes, totalBytes) => {
                        this.progressCount = ((uploadedBytes / totalBytes) * 100).toFixed(0)
                        if (this.progressCount === '100') {
                            this.showNotification('warning', this.$t('We are processing your files. Please wait...'))
                        }
                    },
                    onUpload: (data) => {
                        this.loader = true
                    },
                    onStatusChange: (id, oldStatus, newStatus) => {
                        if (id > this.countFiles) {
                            this.countFiles = id
                        }
                        if (!this.inDocumentsUpload && !this.addToTemporary) {
                            if (id === 0 && newStatus === 'submitting') {
                                this.createFolder()
                            } else if (this.folderCreated && id === this.countFiles && newStatus === 'submitted') {
                                this.createFolder()
                            }
                        }
                    }
                }
            })
        },
        async createFolder () {
            try {
                const data = await this.$api.post(`create-model-folder/${ this.modelType }/${ this.modelId }`, this.additionalRequestData)
                if (data.data.success) {
                    this.folderCreated = true
                    this.sendUpload()
                }
            } catch (e) {
                this.consoleError(e)
                this.showNotification('error', 'Error occurred during loading data.')
            }
        },
        sendUpload () {
            if (this.folderCreated) {
                this.instance.uploadStoredFiles()
            }
        },
        postFilesInfo (data, tabId) {
            try {
                this.$api.post(`/bid-request/${ this.modelId }/files-uploaded-thread-message`, { files_names: data.map((item) => item.name), tab_id: tabId })
            } catch (e) {
                this.consoleError(e)
                this.showNotification('error', 'Error occurred saving bid request discussion message.')
            }
        },
        getEndpoint () {
            if (this.addToTemporary) {
                return `${ this.apiUrl }/attach-temporary`
            } else if (!isEmpty(this.folderFlags) && this.folderFlags.is_user_created) {
                return `${ this.apiUrl }/upload-folder-document/${ this.folderFlags._id }`
            } else if (this.modelType && this.modelId) {
                return `${ this.apiUrl }/upload-model-document/${ this.modelType }/${ this.modelId }`
            } else if (this.modelType && this.folderFlags.model_id) {
                return `${ this.apiUrl }/upload-model-document/${ this.modelType }/${ this.folderFlags.model_id }`
            } else if (!isEmpty(this.folderFlags) && this.folderFlags._id) {
                return `${ this.apiUrl }/upload-folder-document/${ this.folderFlags._id }`
            } else {
                return false
            }
        },
        onUploadFromLibrary () {
            this.showSelectFilesPopup()
        },
        closeSelectFilesPopup () {
            this.$store.dispatch('filesComponent/setFolderFlags', {})
            this.selectFilesPopupVisible = false
        },
        showSelectFilesPopup () {
            this.selectFilesPopupVisible = true
        },
        setModelId () {
            const id = this.modelId ? this.modelId : this.routeId
            this.clonedModelId = (!this.addToTemporary && !this.modelId) ? id : this.modelId
        },
        updateDocuments (value) {
            this.$emit('updateDocuments', value)
        }
    },
    watch: {
        'folderFlags' () {
            // dont realod uploader when selecting from IO library
            if (this.selectFilesPopupVisible) {
                return
            }
            this.$emit('reloadUploader')
        },
        'multipleFiles' (value) {
            this.$store.dispatch('filesComponent/setSelectMultipleFiles', value)
        },
        'modelId' () {
            this.setModelId()
        }
    }
}
