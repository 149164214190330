export const getModel = (state) => {
    return state.model
}
export const getAnnotationAdded = (state) => {
    return state.annotationAdded
}
export const getSelectedAnnotation = (state) => {
    return state.selectedAnnotation
}
export const getAddingAnnotation = (state) => {
    return state.addingAnnotation
}
export const getPanelContent = (state) => {
    return state.panelContent
}
export const getSelectedExistingAnnotation = (state) => {
    return state.selectedExistingAnnotation
}
export const getMentionUsers = (state) => {
    return state.mentionUsers
}
export const getActiveComponent = (state) => {
    return state.activeComponent
}
export const getAnnotationObject = (state) => {
    return state.annotationObject
}
export const getAnnotationContent = (state) => {
    return state.annotationContent
}
export const getManualAnnotation = (state) => {
    return state.manualAnnotation
}
export const getDrawingPin = (state) => {
    return state.drawingPin
}
export const getSelectedStamp = (state) => {
    return state.selectedStamp
}
export const getSelectStampPopup = (state) => {
    return state.selectStampPopup
}
export const getSelectSheetPopup = (state) => {
    return state.selectSheetPopup
}
export const getSelectSheetPopupSkipDrawing = (state) => {
    return state.selectSheetPopupSkipDrawing
}
export const getLinksImported = (state) => {
    return state.linksImported
}
