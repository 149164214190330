<template>
    <div class="io-set-types-index flex flex-col gap-2">
        <IOLabel>{{ $t('Select Type of the Set') }}</IOLabel>

        <ul class="flex flex-col gap-4">
            <li
                v-for="type in types"
                :key="type.value"
                class="io-type-option hover-darken prevent-select"
                :class="{ 'io-type-option--selected': modelValue === type.value }"
                @click="$emit('update:modelValue', type.value)"
                @dblclick="$emit('next')"
            >
                <Radio
                    input-name="type"
                    :radio-value="type.value"
                    :modelValue="modelValue"
                />

                <i :class="type.icon" class="io-type-option__icon" />

                <div>
                    <h2 class="text-lg text-main-lighten-1 font-semibold m-0">{{ type.title }}</h2>

                    <p class="text-sm text-main-lighten-2 leading-normal">{{ type.description }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>


<script lang="ts" src="./SetTypesIndex.ts"></script>
<style lang="scss" src="./SetTypesIndex.scss" scoped></style>
