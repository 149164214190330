import { defineComponent } from 'vue'
import { ImpactType, ScheduleImpactType } from '@/io-modules/rfis/enums/Rfi'
import { ScheduleImpact } from '@/io-modules/rfis/interfaces/Rfi'
import { helpers, requiredIf } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default defineComponent({
    name: 'ScheduleImpact',
    props: {
        modelValue: {
            type: Object as () => ScheduleImpact,
            required: true
        }
    },
    emits: ['update:modelValue'],
    setup () {
        return {
            ImpactType,
            ScheduleImpactType,
            v$: useVuelidate()
        }
    },
    computed: {
        value: {
            get (): ScheduleImpact {
                return this.modelValue
            },
            set (value: ScheduleImpact): void {
                this.$emit('update:modelValue', value)
            }
        },
        impactOptions (): { type: ImpactType, label: string }[] {
            return [
                {
                    type: ImpactType.TBD,
                    label: this.$t('TBD')
                },
                {
                    type: ImpactType.NO,
                    label: this.$t('No')
                },
                {
                    type: ImpactType.YES,
                    label: this.$t('Yes')
                }
            ]
        },
        scheduleOptions (): { type: ScheduleImpactType, label: string }[] {
            return [
                {
                    type: ScheduleImpactType.TBD,
                    label: this.$t('TBD')
                },
                {
                    type: ScheduleImpactType.DAYS,
                    label: this.$t('Days')
                },
                {
                    type: ScheduleImpactType.WEEKS,
                    label: this.$t('Weeks')
                },
            ]
        },
        errorMessage (): string {
            const [error] = this.v$.$errors

            return error?.$message as string
        }
    },
    validations () {
        return {
            modelValue: {
                value: {
                    required: helpers.withMessage(this.$t('Can’t be empty.'), requiredIf(() => {
                        return this.modelValue.occurred === ImpactType.YES && this.modelValue.type !== ScheduleImpactType.TBD
                    })),
                    $autoDirty: true
                }
            }
        }
    },
})
