import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IOSwitcher',
    props: {
        modelValue: { type: Boolean, required: true },
        title: { type: String, default: '' },
        slider: { type: Boolean, default: true },
        flipped: Boolean,
        disabled: Boolean,
    },
    emits: ['update:modelValue'],
})
