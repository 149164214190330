export enum ContractStatus {
    DRAFT = 'draft',
    PENDING_INTERNAL_APPROVAL = 'pending-internal-approval',
    PENDING_REP_APPROVAL = 'pending-rep-approval',
    INTERNALLY_REJECTED = 'internally-rejected',
    INTERNALLY_APPROVED = 'internally-approved',
    SUBMITTED_TO_CLIENT = 'submitted-to-client',
    REJECTED_BY_CLIENT = 'rejected-by-client',
    REJECTED = 'rejected',
    APPROVED = 'approved',
    APPROVED_BY_CLIENT = 'approved-by-client',
    INITIAL_CONTRACT_EXECUTION = 'initial-execution',
    ORIGINAL_CONTRACT_EXECUTION = 'original-execution',
    EXECUTED = 'executed',
    FINAL_AMENDMENT_APPROVED = 'final-amendment-approved',
}

export default ContractStatus
