import { defineComponent, PropType } from 'vue'
import TableRow from './table-row/TableRow.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'

export default defineComponent({
    components: {
        TableRow,
    },
    props: {
        headers: {
            type: Array as PropType<HeaderTable[]>,
            default: () => [],
        },
        item: {
            type: Object,
            default: () => ({}),
        },
        nestedRowsIndicator: {
            type: String,
            default: '',
        },
        levelIndex: { type: [Number, String] },
        parentLevelIndex: { type: [Number, String] },
        parentItem: {
            type: Object,
            default: () => ({}),
        },
        index: { type: Number },
        topShadow: {
            type: Boolean,
            default: false,
        },
        bottomShadow: {
            type: Boolean,
            default: false,
        },
        classes: { type: String, default: '' },
        rowsToExpand: {
            type: Array,
            default: () => [],
        },
        itemIdentifier: String,
    },
    emits: ['expandRowId', 'clickEditItem', 'inputClicked'],
    data () {
        return {
            itemInjects: [],
        }

    },
    computed: {
        hasNestedRows (): boolean {
            return this.item[this.nestedRowsIndicator] && this.item[this.nestedRowsIndicator].length
        },

        isExpanded (): boolean {
            return this.rowsToExpand.includes(this.item[this.itemIdentifier])
        },
    },
    watch: {
        headers (newVal: HeaderTable[], prevVal: HeaderTable[]): void {
            if (!prevVal.length && newVal.length) {
                this.getInjectedItems()
            }
        },
    },
    mounted () {
        if (!this.hasNestedRows) {
            this.getInjectedItems()
        }
    },
    methods: {
        getInjectedItems (): void {
            const itemInjects = this.headers.find(item => item.getInjects instanceof Function)
            this.itemInjects = itemInjects?.getInjects(this.item) || []
        },
        collectedNestedItemsIds (items: Array<object>, nestedItemIds: Array<string>): void {
            items.forEach(item => {
                nestedItemIds.push(item[this.itemIdentifier])
                if (item[this.nestedRowsIndicator] && item[this.nestedRowsIndicator].length) {
                    this.collectedNestedItemsIds(item[this.nestedRowsIndicator], nestedItemIds)
                }
            })
        },

        toggleRow (): void {
            if (this.hasNestedRows) {
                let nestedItemIds = []
                this.collectedNestedItemsIds(this.item[this.nestedRowsIndicator], nestedItemIds)
                this.$emit('expandRowId', [this.item[this.itemIdentifier], ...nestedItemIds])
            } else {
                this.$emit('expandRowId', [this.item[this.itemIdentifier]])
            }
        },

        sendRowsToExpand (data: string): void {
            this.$emit('expandRowId', data)
        },

        handleClickEditItem (itemId: string): void {
            this.$emit('clickEditItem', itemId)
        },
        emitInputClick (itemId: string, item: object): void {
            this.$emit('inputClicked', itemId, item)
        }
    },
})
