<template>
    <PopupModal width="480" class="io-document-download-modal" full-screen @close="$emit('close')">
        <template #header>
            <div class="io-popup-modal__icon"><i class="icon-file-download"/></div>
            <div class="io-popup-modal__title">{{ $t('Download') }}</div>
            <div class="io-popup-modal__close" @click="$emit('close')"><i class="icon-cross"/></div>
        </template>

        <h3 class="io-document-download-modal__title">{{ $t('Do you want to download?') }}</h3>
        <p class="io-document-download-modal__subtitle">{{ $t('Include markups') }}</p>

        <IOSwitcher :title="statusText" v-model="includeMarkups" />

        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">{{ $t('Cancel') }}</IOButton>
            <IOButton @click="$emit('download', { includeMarkups, id })">{{ $t('Download') }}</IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./DocumentDownloadModal.ts"></script>
<style lang="scss" src="./DocumentDownloadModal.scss" scoped></style>
