export default {
    topics: [],
    topicsCloned: [],
    topicsPage: null,
    topicsPageLast: null,
    discussion: null,
    posts: null,
    postsCloned: null,
    postsTotal: null,
    postsPage: null,
    postsLoading: true,
    addingPost: false,
    messageFocus: false,
    mutedBy: [],
    topicHover: '',
    projectEmail: false,
    modal: {
        newDiscussion: false
    },
    type: Object.freeze({
        PROJECT: 0
    })
}
