import { defineComponent } from 'vue'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'

export default defineComponent({
    props: {
        breadcrumb: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    mixins: [breadcrumbsMixin],
    computed: {
        isCurrentItem (): boolean {
            return this.index === this.breadcrumbs.length - 1
        },
        textToShow (): string {
            return this.breadcrumb.text?.length >= 30 ? this.breadcrumb.text.slice(0, 27) + '...' : this.breadcrumb.text
        },
        showTooltip (): boolean {
            return !!this.breadcrumb.projId || this.breadcrumb.text?.length >= 30
        },
        tooltipText (): string {
            return this.showTooltip ? `${ this.breadcrumb?.projId ?? '' } ` + this.breadcrumb.text : ''
        },
    },
})
