<template>
    <Filters
        class="filter-section-collapse" :id="collapseId"
        :class="{'filter-section-collapse--collapse': collapse}"
        :filtersSchema="filterSchema"
    />
</template>

<script lang="ts" src="./FilterSectionCollapse.ts"></script>
<style lang="scss" src="./FilterSectionCollapse.scss" scoped></style>
