<template>
    <tr
        v-if="file && isConstructionFileVisible"
        :key="file._id"
        :ref="`row-${file._id}`"
        v-click-outside="clickOutsideRow"
        class="io-single-file-row"
        :class="file._shared_to_my_document_id ? 'io-file-synced' : ''"
    >
        <td class="io-select-file-cell">
            <label v-if="!editMode">
                <input
                    v-if="allowToSelectFiles"
                    type="checkbox"
                    :checked="isSelected"
                    :value="file._id"
                    :disabled="disableSelectInput"
                    @change="selectFile(file)"
                />
            </label>
        </td>

        <td>
            <div class="io-name">
                <div class="io-document-type">
                    <i class="icon-file" :class="{ 'io-inherited': file.inherited_access }"></i>
                </div>

                <i v-if="!fromTrash && !isDeleted" class="icon-star" :class="{ 'io-active': file.flag }" @click="toggleImportant"></i>

                <template v-if="allowToSelectFiles">
                    <div class="io-name io-cursor-pointer" @click="selectFile(file)">{{ file.file_name }}</div>
                </template>
                <template v-else>
                    <div v-if="!editMode" class="io-name">
                        <a v-if="toSync" target="_blank" :href="previewUrl">{{ file.file_name }}</a>
                        <template v-else-if="fromTrash || isDeleted">{{ file.file_name }}</template>
                        <router-link
                            v-else-if="isProjectView"
                            :to="{ name:'project-documents-show',
                            params: { pid: $route.params.pid, id: file._id } }"
                        >
                            {{ file.file_name }}
                        </router-link>
                        <router-link v-else :to="{ name: 'documents-show', params: { id: file._id } }">
                            {{ file.file_name }}
                        </router-link>
                    </div>

                    <input v-else ref="inputName" autofocus v-model="newFileName"/>
                </template>
            </div>
        </td>

        <td class="io-size">
            <template v-if="!editMode">{{ $filters.fileSize(file.file_size) }}</template>
        </td>

        <td>
            <div v-if="!editMode" class="io-added-by">
                <!--                <div v-if="file.creator_photo" class="io-photo" :style="getBackgroundAvatar(file.creator_photo)"></div>-->

                <Initials :fullName="file.creator_full_name" />

                <div class="io-user-name">{{ file.creator_full_name }}</div>
            </div>
        </td>

        <td>
            {{ $filters.parseDate(file.updated_at) }}
        </td>

        <td v-if="!allowToSelectFiles" class="aright">
            <DropdownButtonNew v-if="isActionButtonShown" :iconMode="true" fontAwesomeClass="fas fa-ellipsis-v">
                <template v-if="fromTrash || isDeleted">
                    <div class="io-single-option" @click="onRestoreItem">
                        <i class="fas fa-archive"></i>
                        <span>{{ $t('Yes, restore') }}</span>
                    </div>

                    <div class="io-single-option" @click="onRemoveItem">
                        <i class="far fa-trash-alt"></i>
                        <span>{{ $t('Delete') }}</span>
                    </div>
                </template>

                <template v-else>
                    <template v-if="!toSync">
                        <div v-if="file._can_rename" class="io-single-option js-rename" @click="onClickRenameFile">
                            <i class="far fa-pen"></i><span>{{ $t('Rename') }}</span>
                        </div>

                        <div v-if="fileBelongsToCurrentUser" class="io-single-option io-nohover" @click="onClickShareFile">
                            <i class="far fa-share-alt"></i>
                            <span>{{ $t('Share') }}</span>
                        </div>

                        <div v-if="folderFlags._can_publish" class="io-single-option" @click="onItemPublish">
                            <i class="far fa-share-all"></i>
                            <span v-if="file.is_published">{{ $t('Unpublish') }}</span>
                            <span v-else>{{ $t('Publish') }}</span>
                        </div>

                        <div v-if="file._can_delete"  class="io-single-option" @click="onItemToTrash">
                            <i class="far fa-archive"></i>
                            <span>{{ $t('Archive') }}</span>
                        </div>
                    </template>
                </template>
            </DropdownButtonNew>

            <div v-else-if="editMode" class="io-edit-mode-btns-actions">
                <div class="io-icon-action" @click="saveEditedData"><i class="fal fa-check"></i></div>
                <div class="io-icon-action" @click="cancelEditing(fileNameNotChanged)"><i class="fal fa-times"></i></div>
            </div>

            <InvitePreview
                ref="invitePreview"
                :key="invitationClientKey"
                :componentKey="invitationClientKey"
                :previewType="PreviewTypes.HIDDEN"
                :selectMultiple="true"
                :employeesList="file.on_item"
                :resourceId="file._id"
                :filterOptions="inviteModalOptionsClient"
                :saveFromModal="true"
                :disableBookmarking="true"
                :projectGlobalId="projectMongoId"
                :modelType="InvitationModelTypes.DOCUMENT"
                :buttonLabelText="$t('Add, Invite, or Bookmark for later')"
                :customProjectMongoId="projectMongoId"
                @inviteModalDone="updateInviteModalData"
            />
        </td>
    </tr>
</template>

<script src="./single-file.js"></script>
<style lang="scss" scoped src="./single-file.scss"></style>
