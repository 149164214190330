import { ApiClient as client, ApiClientV3 as clientV3 } from '@/api/api'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType'

export default {
    getBudget (id, budgetType) {
        return client.get(`project-budget/get/${id}`, { params: { type: budgetType } })
    },
    getFundingSources (id) {
        return client.get(`/funding-sources-attached-to-project?id=${id}`)
    },
    getSharedBudget (newId, sourceId, budgetType = BudgetType.OWNER_BUDGET.toString(), isSnapshot = null, budgetId = null) {
        return client.get(`project-budget/share/get/${newId}/${sourceId}`, {
            params: {
                budget_type: budgetType,
                is_snapshot: isSnapshot,
                budget_id: budgetId
            }
        })
    },
    shareSetDefault (projectId, projectSharedId) {
        return client.put(`project-budget/share/set-default/${projectId}/${projectSharedId}`)
    },
    shareFeasibilityStudyWithOwner(budgetId, shareFeasibilityValue) {
        return client.put(
            `/budgets/${budgetId}/share-feasibility-budget`,
            { 'share_feasibility_budget_with_owner': shareFeasibilityValue }
        )
    },
    recalculateBudget (id) {
        return client.get(`project-budget/recalculate/${id}`)
    },
    saveItem (data) {
        return client.post(`project-budget-item/save`, data)
    },
    getChanges (projectId, params) {
        return client.get(`project-budget-history/get/${projectId}`, { params })
    },
    saveCurrencyRates (projectId, rates) {
        return client.put(`project-budget/multi-currency/${projectId}`, {
            rates: rates
        })
    },
    deleteCurrencyRate (projectId, rateId) {
        return client.delete(`project-budget/multi-currency/${projectId}/rate/${rateId}`)
    },
    getConfiguration () {
        return client.get('project-budget/configuration')
    },
    getConfigurationForProject (projectId, budgetType) {
        return client.get(`project-budget/configuration-project/${projectId}`, { params: { type: budgetType } })
    },
    getBudgetColumns () {
        return clientV3.get('budgets/budget-columns')
    },
}
