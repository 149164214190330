import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'

import menuLeftClient from './api-clients/menuLeftClient'

import { MenuItem, MenuSubItem, AppType } from './interfaces/MenuLeftInterface'

import ItemLink from './parts/ItemLink.vue'
import MenuLock from './parts/MenuLock.vue'
import Profile from './parts/profile/Profile.vue'
import NotificationsCenter from './parts/notifications-center/NotificationsCenter.vue'
import ProjectBreadcrumbs from './parts/project-navigation/project-breadcrumbs/ProjectBreadcrumbs.vue'
import ProjectSearch from './parts/project-navigation/project-search/ProjectSearch.vue'
import ProjectNavigation from './parts/project-navigation/ProjectNavigation.vue'
import EnvBanner from './parts/env-banner/EnvBanner.vue'

export default defineComponent({
    name: 'MenuLeft',
    emits: ['global/leftMenuObserver'],
    components: {
        ItemLink,
        MenuLock,
        Profile,
        NotificationsCenter,
        ProjectBreadcrumbs,
        ProjectSearch,
        ProjectNavigation,
        EnvBanner
    },
    data () {
        return {
            menuItems: [] as MenuItem[],
            menuItemsBottom: [] as MenuItem[],
            isShowProfileMenuVisible: false,
            isNotificationsCenterOpened: false,
            notificationsCounter: 0,
            projectSearchValue: '',
            isMenuMiddleShadowVisible: true
        }
    },
    computed: {
        ...mapState('appStore', {
            authData: (state: any) => state.authData
        }),
        currentAppType (): AppType {
            const appTypes = {
                dev: {
                    logo: process.env.CDN_URL + 'new-theme/img/owner-rep-logo.svg',
                    text: this.$t('rep')
                },
                design: {
                    logo: process.env.CDN_URL + 'new-theme/img/designer-arch-logo.svg',
                    text: this.$t('Design')
                },
                gc: {
                    logo: process.env.CDN_URL + 'new-theme/img/general-contractor.svg',
                    text: this.$t('GC')
                },
                sub: {
                    logo: process.env.CDN_URL + 'new-theme/img/subcontractor-logo.svg',
                    text: this.$t('Sub')
                },
                owner: {
                    logo: process.env.CDN_URL + 'new-theme/img/owner-developer-logo.svg',
                    text: this.$t('Owner')
                }
            }

            //@ts-ignore
            if (this.authData.app_type === this.appTypes.TYPE_DESIGN || this.authData.app_type === this.appTypes.TYPE_AC) {
                return appTypes.design
            }

            //@ts-ignore
            if (this.authData.app_type === this.appTypes.TYPE_SUB || this.authData.app_type === this.appTypes.TYPE_SC) {
                return appTypes.sub
            }

            return appTypes[this.authData.app_type]
        },
        isProjectPage (): boolean {
            return this.$route.path.split('/')[1] === 'project' && !!this.$route.params.pid
        },
        isProjectNavigationActive (): boolean {
            return this.isProjectPage
        },
        isTrainingEnv (): boolean {
            return window.location.host.split('.')[2] === 'training'
        }
    },
    watch: {
        'isProjectNavigationActive' () {
            this.onProjectSearchValueSet('')
        },
        '$route' () {
            this.onSetShowProfileMenuVisible(false)
        }
    },
    beforeMount () {
        this.init()
    },
    created () {
        this.notificationsSubscribe()
    },
    methods: {
        ...mapActions('main', ['setTaskModalOpenStatus']),
        openTaskModal (): void {
            this.closeNotificationCenter()
            //@ts-ignore
            this.setTaskModalOpenStatus(true)
            this.$store.dispatch('modal/setShow', 'modal-task', { root: true })
        },

        closeNotificationCenter(): void {
            if (this.isNotificationsCenterOpened) {
                this.isNotificationsCenterOpened = false
            }

        },
        async init (): Promise<void> {
            try {
                const { data } = await menuLeftClient.getMenu()

                data.items.forEach((item: MenuItem) => {
                    if (item.subitems?.length && this.isItemActive(item.subitems)) {
                        item.isOpened = true
                    }
                })

                this.menuItems = data.items
                this.menuItemsBottom = data.itemsBottom

                this.menuItemsBottom?.forEach((item: MenuItem) => {
                    if (item.title === 'Notifications') {
                        this.notificationsCounter = item.counter
                    }
                })

            } catch (error) {
                this.errorHandle(error)
            }
        },
        onSetMenuItemOpened (index: number): void {
            const currentItem = this.menuItems[index]
            const wasOpened = currentItem.isOpened

            this.onAllSubmenusClose()

            if (!wasOpened && currentItem.subitems?.length) {
                this.menuItems[index]['isOpened'] = true
            }

            if (this.isNotificationsCenterOpened) {
                this.onSetShowProfileMenuVisible(false)
            }
        },
        onAllSubmenusClose (): void {
            this.menuItems.forEach((item: MenuItem, index: number) => {
                this.menuItems[index]['isOpened'] = false
            })
        },
        isItemActive (subItems: MenuSubItem[], index: number = 0): boolean {
            let isActive = false

            if (!subItems?.length) {
                const mainUrl = this.menuItems[index].url
                // @ts-ignore
                const currentPath = this.$route.path
                const pageType = mainUrl.split('/')[1] + '/'

                return currentPath === mainUrl || currentPath.includes(pageType)
            }

            subItems.forEach((item: MenuSubItem) => {
                if (this.isSubItemActive(item.url)) {
                    isActive = true
                }
            })

            return isActive
        },
        isSubItemActive (url: string): boolean {
            let index = (url.split('/').length > 2) ? url.split('/').length - 2 : 1
            return url.split('/')[index] === this.$route.path.split('/')[index]
                   && url.split('/')[index] !== ''
        },
        onSetShowProfileMenuVisible (isVisible: boolean): void {
            this.isShowProfileMenuVisible = isVisible
        },
        toggleNotificationsCenterOpened (): void {
            this.isNotificationsCenterOpened = !this.isNotificationsCenterOpened

            this.onSetShowProfileMenuVisible(!!this.isNotificationsCenterOpened)
        },
        isBottomItemActive (itemTitle: string): boolean {
            const items = {
                Notifications: this.isNotificationsCenterOpened
            }

            return items[itemTitle]
        },
        handleBottomItemClick (itemTitle: string): void {
            const actions = {
                Notifications: this.toggleNotificationsCenterOpened
            }

            const action = actions[itemTitle]

            action && action()
        },
        updateNotificationsCounter (data): void {
            const counter = data.allNotificationsCount
            const notificationsIndex = this.menuItemsBottom.findIndex(item => item.title === 'Notifications')

            this.menuItemsBottom[notificationsIndex]['counter'] = counter
            this.notificationsCounter = counter
        },
        notificationsSubscribe (): void {
            //@ts-ignore
            this.$echo.private('UserChannel.' + this.authData.u_mongo)
                .listen('Notification.Event.Notification', this.updateNotificationsCounter)
                .listen('Notification.Event.RemoveNotifications', this.updateNotificationsCounter)
        },
        onExitProjectClick (): void {
            this.$router.push({ name: 'dashboard-statistics' })
        },
        onProjectSearchValueSet (value: string): void {
            this.projectSearchValue = value
        },
        setIsMenuMiddleShadowVisibleDebounced: debounce(async function (this: any)  {
            const $element = this.$refs.listContainerNode

            this.isMenuMiddleShadowVisible = Math.abs($element.scrollHeight - $element.scrollTop - $element.clientHeight) > 1
        }, 50),
        onMenuListContainerScroll (): void {
            this.setIsMenuMiddleShadowVisibleDebounced()
        }
    }
})
