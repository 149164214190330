<template>
    <IOModal
        class="io-adjustments-modal"
        icon="icon-edit" 
        width="650"
        :title="modalTitle"
        :scrollable="true"
        @close="closeModal"
    >
        <template #default>
            <div class="io-content-wrapper">
                <section>
                    <IOLabel :size="LabelSize.Large" :type="LabelType.BoldMax" :text="$t('Submitted')" />
                    <div class="io-section-content">
                        <IOSingleInfoRow 
                            :label="$t('% of Completed Work')" 
                            :info="$filters.formatPercentInt(getSubmittedValue(SoVLineItemField.PercentOfCompletedWork))"
                        />
                        <IOSingleInfoRow 
                            :label="$t('Current application')" 
                            :info="$filters.formatProjectCurrencyInt(getSubmittedValue(SoVLineItemField.CurrentApplication))"
                        />
                        <IOSingleInfoRow 
                            :label="$t('Materials stored')" 
                            :info="$filters.formatProjectCurrencyInt(getSubmittedValue(SoVLineItemField.MaterialsStored))" 
                        />
                    </div>
                </section>
                <section>
                    <IOLabel :size="LabelSize.Large" :type="LabelType.BoldMax" :text="$t('Adjusted')" />
                    <div class="io-section-content">
                        <div v-if="isEditable">
                            <IOLabel :text="$t('% of Completed Work')" />
                            <field-numeric-int
                                :id="SoVLineItemField.PercentOfCompletedWork"
                                class="io-percents-input"
                                :min="0"
                                :options="{ numeralPositiveOnly: false, numericOnly: false }"
                                :showRounded="true"
                                :sign="'%'"
                                v-model="percentOfCompletedWork"
                                @update:modelValue="inputPercent"
                            />
                        </div>
                        <IOSingleInfoRow 
                            v-else 
                            :label="$t('% of Completed Work')" 
                            :info="$filters.formatPercentInt(getAdjustedValue(SoVLineItemField.PercentOfCompletedWork))"
                        />
                        <div v-if="isEditable">
                            <IOLabel :text="$t('Current application')" />
                            <field-numeric-int
                                :id="SoVLineItemField.CurrentApplication"
                                class="io-currency-input"
                                :options="{ numeralPositiveOnly: false, numericOnly: false }"
                                :showRounded="true"
                                :sign="'$'"
                                v-model="currentApplication"
                                @update:modelValue="inputCurrentValue"
                            />
                        </div>
                        <IOSingleInfoRow 
                            v-else 
                            :label="$t('Current application')" 
                            :info="$filters.formatProjectCurrencyInt(getAdjustedValue(SoVLineItemField.CurrentApplication))"
                        />
                        <IOSingleInfoRow 
                            :class="{ 'io-label-align': isEditable }"
                            :label="$t('Materials Stored Offsite')" 
                            :info="$filters.formatProjectCurrencyInt(getAdjustedValue(SoVLineItemField.MaterialsStored))"
                        />
                    </div>
                </section>
                <section>
                    <IOLabel :size="LabelSize.Large" :type="LabelType.BoldMax" :text="$t('Difference')" />
                    <div class="io-section-content">
                        <IOSingleInfoRow 
                            :label="$t('% of Completed Work')" 
                            :info="$filters.formatPercentInt(getDifferenceValue(SoVLineItemField.PercentOfCompletedWork))"
                        />
                        <IOSingleInfoRow 
                            :label="$t('Current application')" 
                            :info="$filters.formatProjectCurrencyInt(getDifferenceValue(SoVLineItemField.CurrentApplication))"
                        />
                        <IOSingleInfoRow 
                            :label="$t('Current application')" 
                            :info="$filters.formatProjectCurrencyInt(getDifferenceValue(SoVLineItemField.MaterialsStored))"
                        />
                    </div>
                </section>
            </div>
            <Comments 
                :comments="comments" 
                :can-add-comment="isEditable" 
                :taggableUsers="taggableUsers" 
                :canEdit="true"
                @addComment="addComment"
            />
        </template>
        <template #footer>
            <IOButton v-if="!isEditable && !isRevised && !isAccepted" variant="secondary" @click="reviseAdjustments">
                {{ $t('Revise Adjustment') }}
            </IOButton>
            <IOButton v-else variant="secondary" @click="closeModal">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton v-if="!isEditable && !isRevised && !isAccepted" @click="acceptAdjustments">
                {{ $t('Accept Adjustment') }}
            </IOButton>
            <IOButton v-else-if="!isRevised && !isAccepted" @click="revise">
                {{ $t('Revise') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./AdjustmentsModal.ts"></script>
<style lang="scss" src="./AdjustmentsModal.scss" scoped></style>