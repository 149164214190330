const defaultMeta = {
    title: 'Approvals Inbox',
    requiresAuth: true,
    navigationKey: 'approvals_inbox_v3',
    ignoreProjectLayout: true,
    dontShowMenu: true,
}

export default {
    path: 'approvals-inbox',
    redirect: { name: 'approvals-inbox-v3-my' },
    children: [
        {
            path: 'my',
            name: 'approvals-inbox-v3-my',
            component: () => import('@/io-modules/approvals-inbox/views/approvals-inbox-view/ApprovalsInboxView.vue'),
            meta: defaultMeta,
        },
        {
            path: 'all',
            name: 'approvals-inbox-v3-all',
            component: () => import('@/io-modules/approvals-inbox/views/approvals-inbox-view/ApprovalsInboxView.vue'),
            meta: defaultMeta,
        },
        {
            path: 'rejected',
            name: 'approvals-inbox-v3-rejected',
            component: () => import('@/io-modules/approvals-inbox/views/approvals-inbox-view/ApprovalsInboxView.vue'),
            meta: defaultMeta,
        },
    ]
}

