import { defineComponent } from 'vue'
import { FilesTabInterface } from '@/components/files-new/interfaces/FilesTabInterface.ts'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'
export default defineComponent({
    name: 'FilesTabs',
    components: {
        StatusPill
    },
    props: {
        activeHeaderTab: { type: Number, default: 0 },
        isSkinnyVariant: { type: Boolean, default: false },
        tabs: { type: Array as () => FilesTabInterface[], default: [] },
    },
    emits: ['changeActiveHeaderTab'],
    methods: {
        pillVariant (id: number): PillVariant {
            return this.activeHeaderTab === id ? PillVariant.MAIN : PillVariant.MAIN_LIGHT
        },
    }
})
