import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InvitationButtonGroup',
    props: {
        data: { type: Object, required: true },
    },
    methods: {
        onInviteClick (): void {
            this.$emit('inviteButtonClick', this.data)
        }
    }
})
