import { defineComponent } from 'vue'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'

export default defineComponent({
    methods: {
        rfiPillColor (id: number) {
            switch (id) {
            case 10:
                return 'io-green'
            case 30:
                return 'io-red'
            case 1000:
                return 'io-blue'
            case 2000:
                return 'io-green'
            case 3000:
                return 'io-green'
            case 4000:
                return 'io-grey'
            case 20:
            default:
                return 'io-yellow'
            }
        },
        submittalHealthColor (status: string): PillVariant {
            switch (status) {
            case 'On Time':
                return PillVariant.GREEN_LIGHT
            case 'At Risk':
                return PillVariant.YELLOW_LIGHT
            case 'Behind Schedule':
                return PillVariant.RED_LIGHT
            default:
                return PillVariant.GRAY_LIGHT
            }
        },
        submittalSecondaryStatusVariant (secondaryStatus: string): string {
            switch (secondaryStatus) {
            case 'submittal_v2.submittal.secondary_status.previously_rejected':
                return 'error'
            case 'submittal_v2.submittal.secondary_status.revised_and_resubmited':
                return 'warning'
            case 'submittal_v2.submittal.secondary_status.distributed':
                return 'success'
            case 'submittal_v2.submittal.secondary_status.approved_as_noted':
                return 'warning-darker'
            case 'submittal_v2.submittal.secondary_status.none':
                return ''
            default:
                return 'closed'
            }
        },
        submittalPillColor (status: string): string {
            switch (status) {
            case 'submittal_v2.submittal.status.draft':
                return 'blue-light'
            case 'submittal_v2.submittal.status.approved':
                return 'green-light'
            case 'submittal_v2.submittal.status.in_progress':
                return 'blue-light'
            case 'submittal_v2.submittal.status.under_review':
                return 'orange-light'
            case 'submittal_v2.submittal.status.archived':
                return 'gray-light'
            default:
                return ''
            }
        },
        submittalPillText (status: string): string {
            switch (status) {
            case 'submittal_v2.submittal.status.draft':
                return 'DRAFT'
            case 'submittal_v2.submittal.status.approved':
                return 'APPROVED'
            case 'submittal_v2.submittal.status.in_progress':
                return 'IN PROGRESS'
            case 'submittal_v2.submittal.status.under_review':
                return 'UNDER REVIEW'
            default:
                return ''
            }
        }
    }
})
