<template>
    <at :members="attachedPeople" name-key="name" :class="{ 'io-below': attachBelow, 'atwho-wrap--field-text': inputVariant }">
        <template #item="{ item }">
            <div class="item" :data-id="item.id" @click="selectItem">
                <Avatar
                    v-if="inputVariant && item.id"
                    class="my-2"
                    show-full-name
                    size="sm"
                    :employee-mongo-id="item.id"
                    :employee-full-name="item.name"
                />

                <template v-else>
                    <initials
                        :fullName="item.name"
                        :bgColor="personColor(item)"
                    />

                    <div class="item-info">
                        <p v-text="item.name" />
                        <span v-text="item.role" />
                    </div>
                </template>
            </div>
        </template>

        <template #embeddedItem="item">
            <!--https://github.com/fritx/vue-at#custom-tags-->
            <span>
                <span v-if="item.current.id" :data-id="item.current.id" class="io-mention">
                    @{{ item.current.name }}
                </span>
            </span>
        </template>

        <template #default>
            <div
                ref="mentionsField"
                class="grow"
                :class="['io-mention-field', {'io-mention-field__disabled': !contentEditable}]"
                :contenteditable="contentEditable"
                :placeholder="placeholderText"
                @blur="onBlur"
                @focus="onFocus"
                @keyup="onInput"
                @keydown.enter="onEnter"
                @keypress="onKeyPress"
                @input="onInput"
                @paste="sanitizeText"
                @keydown="onKeydown"
                v-html="content"
            />

            <span
                v-if="inputVariant"
                class="flex ml-2 cursor-pointer"
                :class="{ active: modelValue.length }"
                @click="onSubmit"
            >
                <i class="icon icon-send-2 text-lg"></i>
            </span>
        </template>
    </at>
</template>

<script src="./mentions.js"></script>
<style src="./mentions.scss" lang="scss"></style>
