import { defineComponent } from 'vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn'
import LimitedTextarea from '@/components/atoms/LimitedTextarea/LimitedTextarea'
import ScheduleTicket from '@/io-modules/lists-templates/schedule-templates/components/schedule-ticket/ScheduleTicket'
import { defaultScheduleTicket } from '@/io-modules/lists-templates/schedule-templates/consts/defaultScheduleTicket'
import { ScheduleTicketsListInterface } from '@/io-modules/lists-templates/schedule-templates/interfaces/ScheduleTicketsListInterface'
import { ScheduleTicketInterface } from '@/io-modules/lists-templates/schedule-templates/interfaces/ScheduleTicketInterface'
import { cloneDeep } from 'lodash'
import Draggable from 'vuedraggable'
import { scheduleListDragOptions } from '@/io-modules/lists-templates/schedule-templates/consts/scheduleListDragOptions'
import NotificationType from '@/interfaces/layout/NotificationType'
import { defaultScheduleTicketsListModel } from '@/io-modules/lists-templates/schedule-templates/consts/defaultScheduleTicketsListModel'
import scheduleTicketsTemplatesListClient from '@/io-modules/lists-templates/schedule-templates/api-clients/ScheduleTicketsTemplatesListClient.ts'
import { ScheduleTicketsListRequestDataInterface } from '@/io-modules/lists-templates/schedule-templates/interfaces/ScheduleTicketsListRequestDataInterface'
import { ScheduleTicketTypeEnum } from '@/io-modules/lists-templates/schedule-templates/enums/ScheduleTicketTypeEnum'

export default defineComponent({
    name: 'ScheduleTicketsList',
    components: { ScheduleTicket, LimitedTextarea, FormInputColumn, Draggable },
    data () {
        return {
            dragOptions: scheduleListDragOptions,
            model: null as null | ScheduleTicketsListInterface,
            isLoaded: false,
            ScheduleTicketTypeEnum,
        }
    },
    computed: {
        isSaveEnabled (): boolean {
            return this.isLoaded && this.isListNameProvided
        },
        isListNameProvided (): boolean {
            return this.model?.name?.length > 0
        },
        getScheduleTicketsList (): HTMLDivElement {
            return this.$refs['schedule-tickets-list'] as HTMLDivElement
        },
        isCreateMode (): boolean {
            return this.$route.params.id === '0'
        },
        preparedModelForCreateMethod (): ScheduleTicketsListRequestDataInterface {
            const { can_edit, tickets, ...rest } = cloneDeep(this.model)

            return {
                ...rest,
                tickets: tickets
                    .filter((scheduleTicket: ScheduleTicketInterface) => !!scheduleTicket.name.length)
                    .map((scheduleTicket: ScheduleTicketInterface, index: number) => {
                        delete scheduleTicket.id
                        delete scheduleTicket.is_not_created
                        scheduleTicket.order = index + 1

                        return scheduleTicket
                    })
            }
        },
        preparedModelForUpdateMethod (): ScheduleTicketsListRequestDataInterface {
            const { can_edit, tickets, ...rest } = cloneDeep(this.model)

            return {
                ...rest,
                tickets: tickets
                    .filter((scheduleTicket: ScheduleTicketInterface) => !!scheduleTicket.name.length)
                    .map((scheduleTicket: ScheduleTicketInterface, index: number) => {
                        scheduleTicket.order = index + 1

                        if (scheduleTicket.is_not_created) {
                            delete scheduleTicket.id
                            delete scheduleTicket.is_not_created
                        }

                        return scheduleTicket
                    })
            }
        }
    },
    async mounted (): Promise<void> {
        if (this.isCreateMode) {
            this.setModel(defaultScheduleTicketsListModel)
        } else {
            await this.fetchScheduleTicketsList()
        }
    },
    methods: {
        setModel (data: ScheduleTicketsListInterface): void {
            this.model = cloneDeep(data)

            this.isLoaded = true
        },
        async addNewScheduleTicket (type: ScheduleTicketTypeEnum): Promise<void> {
            const scheduleTicket = {
                ...cloneDeep(defaultScheduleTicket),
                id: this.generateUuid(),
                order: this.model.tickets.length + 1,
                type
            } as ScheduleTicketInterface

            this.model.tickets.push(scheduleTicket)
            await this.focusOnScheduleTicketInput(scheduleTicket.id)
            await this.scrollToBottom()
        },
        async focusOnScheduleTicketInput (scheduleTicketId: string): Promise<void> {
            await this.$nextTick(() => {
                const scheduleTicketElement = document.querySelector(`.io-schedule-ticket-${ scheduleTicketId }`)
                scheduleTicketElement.querySelector('input').focus()
            })
        },
        async scrollToBottom ():  Promise<void> {
            await this.$nextTick(() => {
                window.scrollTo({
                    top: this.getScheduleTicketsList?.scrollHeight + 120,
                    behavior: 'smooth'
                })
            })
        },

        async onChangeListName (val: string): Promise<void> {
            this.model.name = val
        },
        onChangeOrdering (): void {
            this.model.tickets = this.model.tickets.map((scheduleTicket: ScheduleTicketInterface, index: number) => {
                if (scheduleTicket.order !== index) {
                    scheduleTicket.order = index
                }

                return scheduleTicket
            })
        },
        async onUpdateScheduleTicket (updatedScheduleTicket: ScheduleTicketInterface): Promise<void> {
            this.updateScheduleTicketInList(updatedScheduleTicket)
        },
        async onCreateScheduleTicket (data: { scheduleTicket: ScheduleTicketInterface, enterKey: boolean }): Promise<void> {
            this.updateScheduleTicketInList(data.scheduleTicket)

            if (data.enterKey) {
                await this.addNewScheduleTicket(data.scheduleTicket.type)
            }
        },
        updateScheduleTicketInList (updatedScheduleTicket: ScheduleTicketInterface): void {
            this.model.tickets = this.model.tickets.map((scheduleTicket: ScheduleTicketInterface) => {
                if (scheduleTicket.id === updatedScheduleTicket.id) {
                    delete updatedScheduleTicket.is_new

                    return updatedScheduleTicket
                }

                return scheduleTicket
            })
        },
        onRejectScheduleTicketChanges (updatedScheduleTicket: ScheduleTicketInterface): void {
            if (updatedScheduleTicket.is_new) {
                this.model.tickets = this.model.tickets.filter((scheduleTicket: ScheduleTicketInterface) => scheduleTicket.id !== updatedScheduleTicket.id)
            }
        },
        onDeleteScheduleTicket (scheduleTicket: ScheduleTicketInterface): void {
            this.model.tickets = this.model.tickets.filter((scheduleTicketElement: ScheduleTicketInterface) => scheduleTicketElement.id !== scheduleTicket.id)
        },
        async moveToTemplatesView (): Promise<void> {
            await this.$router.push({ name: 'lists-templates-schedule-templates' })
        },
        async onClickSaveScheduleTicketsList (): Promise<void> {
            if (this.isCreateMode) {
                await this.createNewScheduleTicketsList()
            } else {
                await this.updateScheduleTicketsList()
            }
        },
        async createNewScheduleTicketsList (): Promise<void> {
            this.setLoadingBar(true)

            try {
                await scheduleTicketsTemplatesListClient.createScheduleTicketsList(this.preparedModelForCreateMethod)

                await this.moveToTemplatesView()
            } catch (error) {
                this.showNotification(NotificationType.ERROR, error.response.data.message, error)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async updateScheduleTicketsList (): Promise<void> {
            this.setLoadingBar(true)

            try {
                await scheduleTicketsTemplatesListClient.updateScheduleTicketsList(this.model.id, this.preparedModelForUpdateMethod)

                await this.moveToTemplatesView()
            } catch (error) {
                this.showNotification(NotificationType.ERROR, error.response.data.message, error)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async fetchScheduleTicketsList (): Promise<void> {
            this.setLoadingBar(true)

            try {
                const { data } = await scheduleTicketsTemplatesListClient.fetchScheduleTicketsList(this.$route.params.id as string)

                this.setModel(data)
            } catch (error) {
                this.showNotification(NotificationType.ERROR, error.response.data.message, error)
            } finally {
                this.setLoadingBar(false)
            }
        },
    }
})
