import { isEmpty } from 'lodash'

export default {
    name: 'SingleFolder',
    props: {
        folder: {
            type: Object,
            required: false,
        },
        idx: {
            type: Number,
            required: false,
        },
        arrayId: {
            type: String,
            required: true,
        },
        view: {
            type: String,
            required: false,
        },
        isBreadcrumb: {
            type: Boolean,
            required: false,
        },
        isSearchResult: {
            type: Boolean,
            required: false,
            default: false,
        },
        isProjectView: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasFiles: {
            type: Boolean,
            required: false,
        },
        isDocumentsView: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCreateMode: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data () {
        return {}
    },
    computed: {
        folders () {
            return this.$store.getters['filesComponent/getFolders'](this.arrayId)
        },
        foldersBreadcrumbs () {
            return this.$store.getters['filesComponent/getFoldersBreadcrumbs']
        },
        foldersSearchPaths () {
            return this.$store.getters['filesComponent/getFoldersPaths']
        },
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        folderIconClasses () {
            return [{ 'io-is-user-folder': this.folder.is_user_created }, { 'io-is-public': this.isConstructionDoc }]
        },
        isConstructionDoc () {
            return this.folder.model_type === 'construction_documents'
        },
        isOfFileType () {
            return this.folder?.file_type && this.folder?.file_type === 'file'
        },
    },
    methods: {
        exitCreationMode () {
            this.$store.dispatch('filesComponent/setCreateNewFolderInProjectPhotos', false)
        },
        async addFolder () {
            if (!this.folder.name) {
                return
            }
            let requestData = {
                folder_name: this.folder.name,
                parent_id: this.folderFlags._id ? this.folderFlags._id : null,
                project_local_id: this.folderFlags.project_local_id ? this.folderFlags.project_local_id : null,
            }
            try {
                const data = await this.$api.post('documents/folder/save', requestData)
                this.showNotification('success', 'Folder has been added.')
                this.$store.dispatch('filesComponent/addFolder', {
                    arrayId: this.arrayId,
                    folder: data.data.folder,
                })
                this.$store.dispatch('filesComponent/setCreateNewFolderInProjectPhotos', false)
                this.folder.name = ''
            } catch (e) {
                this.errorHandle(e)
            }
        },
        changeFolderRoute () {
            let folderId = ''

            // if breadcrumbs go 1 path back
            if (this.isBreadcrumb) {
                folderId = this.folderFlags && this.folderFlags.parent_id ? this.folderFlags.parent_id : null
            } else {
                folderId = this.folder._id ? this.folder._id : null
            }

            if (this.isProjectView) {
                this.$router.push({ name: 'project-documents-list', params: { folderId: folderId } })
            } else {
                this.$router.push({ name: 'documents-list', params: { folderId: folderId } })
            }
        },
        async loadFolders () {
            if (this.isCreateMode) {
                return
            }
            // if search result is file redirect to file details
            if (this.isSearchResult && this.folder.file_type === 'file') {
                if (this.isProjectView) {
                    return this.$router.push({
                        name: 'project-documents-details',
                        params: { pid: this.$route.params.pid, id: this.folder._id },
                    })
                } else {
                    return this.$router.push({ name: 'documents-details', params: { id: this.folder._id } })
                }
            }

            if (this.isDocumentsView) {
                this.changeFolderRoute()
            } else {
                this.$store.dispatch('filesComponent/setLoading', true)

                // clear current files farray
                this.$store.dispatch(
                    'filesComponent/clearDocsArr',
                    {
                        arrayId: this.arrayId,
                        files: [],
                    },
                    { root: true }
                )

                let params = {}

                // if breadcrumbs go 1 path back
                if (this.isBreadcrumb) {
                    params.folder_id =
                        this.folderFlags && this.folderFlags.parent_id ? this.folderFlags.parent_id : null
                    params.folder_type = ''
                } else {
                    params.folder_id = this.folder._id ? this.folder._id : null
                    params.folder_type = this.folder.folder_type
                }

                let projectPhotosFolder = this.$store.getters['filesComponent/getProjectPhotosFolder']
                if (projectPhotosFolder && params.folder_id === projectPhotosFolder._id) {
                    this.$store.dispatch('filesComponent/removeBreadcrumb', false)
                    this.$store.dispatch('filesComponent/setFolderFlags', projectPhotosFolder)
                    return
                }

                try {
                    let response = {}
                    response = await this.$api.get('/documents/folder/content', { params: params })

                    const data = response.data

                    this.$store.dispatch('filesComponent/setFolders', {
                        arrayId: this.arrayId,
                        folders: data.folders,
                    })

                    this.$store.dispatch('filesComponent/setFolderFlags', data.current_folder)

                    if (data.files && !isEmpty(data.files)) {
                        this.$store.dispatch(
                            'filesComponent/setMyDocs',
                            {
                                arrayId: this.arrayId,
                                files: data.files.own,
                            },
                            { root: true }
                        )
                        this.$store.dispatch(
                            'filesComponent/setSharedDocs',
                            {
                                arrayId: this.arrayId,
                                files: data.files.shared,
                            },
                            { root: true }
                        )
                        this.$store.dispatch(
                            'filesComponent/setTrashedDocs',
                            {
                                arrayId: this.arrayId,
                                files: data.files.trashed,
                            },
                            { root: true }
                        )
                    }
                    // remove last record from memory if breadcrumb
                    if (this.isBreadcrumb) {
                        this.$store.dispatch('filesComponent/removeBreadcrumb', false)
                    } else {
                        this.$store.dispatch('filesComponent/addBreadcrumb', { ...params, name: this.folder.name })
                    }

                    this.$store.dispatch('filesComponent/setSearchLocal', false)
                    this.$store.dispatch('filesComponent/setIsSearching', false)
                    this.$emit('clearFilters')
                    this.$store.dispatch('filesComponent/setLoading', false)
                } catch (e) {
                    this.consoleError(e)
                    this.showNotification('error', this.$t('Error occurred during loading project data.'))
                    this.$store.dispatch('filesComponent/setLoading', false)
                }
            }
        },
        async checkArchivingOption () {
            try {
                const { data } = await this.$api.get(`/folders/${ this.folder._id }/has-shared-document`)
                const folderHasSharedFiles = data.folder_has_shared_document

                folderHasSharedFiles
                    ? this.showPopupAlert({
                          title: this.$t('Archive Folder'),
                          caption: this.$t('This folder contains shared files and cannot be removed.'),
                          icon: 'icon-alert-triangle',
                          buttons: [
                              {
                                  text: this.$t('Close'),
                                  class: 'io-btn-primary',
                                  action: null,
                              },
                          ],
                      })
                    : this.showPopupAlert({
                          title: this.$t('Archive Folder'),
                          caption: this.$t('If you remove this folder the files contained within will be archived. Would you like to continue?'),
                          icon: 'icon-alert-triangle',
                          buttons: [
                              {
                                  text: this.$t('Cancel'),
                                  class: 'io-btn-light',
                                  action: null,
                              },
                              {
                                  text: this.$t('Yes, Archive'),
                                  class: 'io-btn-primary',
                                  action: () => this.archive(),
                              },
                          ],
                      })
            } catch (e) {
                this.errorHandle(e)
            }
        },
        async archive () {
            try {
                await this.$api.post(`/documents/folder/${ this.folder._id }/delete`)
                await this.$store.dispatch('filesComponent/removeMyFolder', {
                    arrayId: this.arrayId,
                    folderId: this.folder._id
                })
            } catch (e) {
                this.errorHandle(e)
            }
        },
    },
    mounted () {
        if (this.isCreateMode) {
            this.$nextTick(() => {
                this.$refs.inputName.focus()
            })
        }
    },
}
