export function SET_SHOW (state, payload) {
    state.show = true
    state.content.unshift({
        ...payload
    })
}

export function SET_CLOSE (state) {
    state.show = false
}

export function RESET_DATA (state) {
    state.content = []
}

export function ADD_BANNER (state, banner) {
    state.banners.push(banner)
}

export function REMOVE_BANNER (state, name) {
    const index = state.banners.findIndex((banner) => {
        return banner.name === name
    })
    if (index > -1) {
        state.banners.splice(index, 1)
    }
}

export function CLEAR_TIMEOUT (state, bannerId) {
    const index = state.timeoutsList.findIndex(timeout => timeout.bannerId === bannerId)
    if (index > -1) {
        clearTimeout(state.timeoutsList[index].timeout)
        state.timeoutsList.splice(index, 1)
    }
}

export function SET_TIMEOUT (state, timeoutObj) {
    state.timeoutsList.push(timeoutObj)
}

export function DELETE_SINGLE_BANNER (state, payload) {
    state.content.splice(payload, 1)
}

export function DELETE_BANNER_BY_ID (state, bannerId) {
    const index = state.content.findIndex(banner => banner.id === bannerId)
    if (index > -1) {
        state.content.splice(index, 1)
    }
}

export function SET_BUDGET_NOTIFICATION (state, payload) {
    state.budgetNotification = payload
}
