import { ApiClient } from '@/api/api'

let client = ApiClient

export default {
    attachEmployee (id, employeeId, data) {
        return client.post('documents/share-document', {
            document_id: id,
            employee_id: employeeId,
            data: data
        })
    },
    detachEmployee (id, employeeId, data) {
        return client.post('documents/unshare-document', {
            document_id: id,
            employee_id: employeeId,
            data: data
        })
    },
    deleteDocument (documentId) {
        return client.delete('documents/remove', { params:
            {
                files: [ documentId],
            }
        })
    },
    getDocumentForPreview (documentId) {
        return client.get(`/document/${ documentId }/preview`)
    },
    getDocumentData (documentId) {
        return client.get(`/document/${ documentId }`)
    },
}
