import { defineComponent } from 'vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import RequestedDataErrorPlaceholder from '@/io-modules/invoices/components/requested-data-error-placeholder/RequestedDataErrorPlaceholder.vue'

export default defineComponent ({
    name: 'FinancialPanel',
    components: {
        IOSingleInfoRow,
        RequestedDataErrorPlaceholder,
    },
    data () {
        return {
            loading: false,
            error: false,
        }
    },
    computed: {
        ...mapStateVuex('project', ['projectObj']),

        ...mapState(invoiceViewStore, ['invoiceFinancialInfo']),

        grossBilledToDate (): string {
            const value = this.invoiceFinancialInfo.billed_to_date?.gross_percentage || 0
            return this.$filters.formatPercentInt(value)
        },

        grossTotal (): string {
            const value = this.invoiceFinancialInfo.billed_to_date?.gross_total || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        netTotal (): string {
            const value = this.invoiceFinancialInfo.billed_to_date?.net_total || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        retentionPercentage (): string {
            const value = this.invoiceFinancialInfo.retention_summary?.total_retention_percent?.current || 0
            return `${ value }%`
        },

        totalRetention (): string {
            const value = this.invoiceFinancialInfo.retention_summary?.total_retention_amount || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        paidToDatePercentage (): string {
            const value = this.invoiceFinancialInfo.paid_to_date?.percentage || 0
            return this.$filters.formatPercentInt(value)
        },

        paidToDateAmount (): string {
            const value = this.invoiceFinancialInfo.paid_to_date?.total
            return this.$filters.formatProjectCurrencyInt(value)
        },

        lienWaiversConditional (): string {
            const value = this.invoiceFinancialInfo.lien_waiver?.conditional
            return this.$filters.formatProjectCurrencyInt(value)
        },

        lienWaiversUnconditional (): string {
            const value = this.invoiceFinancialInfo.lien_waiver?.unconditional
            return this.$filters.formatProjectCurrencyInt(value)
        },

        isRetentionModified (): boolean {
            return this.invoiceFinancialInfo.retention_summary?.retention_modified
        },

        totalRetentionPercentage (): string {
            const current = this.invoiceFinancialInfo.retention_summary?.total_retention_percent?.current || 0
            const previous = this.invoiceFinancialInfo.retention_summary?.total_retention_percent?.previous || 0

            if (previous === current) {
                return `${ current }%`
            }

            return `${ previous }% -> ${ current }%`
        },

        currentRetentionPercentage (): string {
            return `${ this.invoiceFinancialInfo.retention_summary?.current_retention_percent }%`
        },

        retentionReleased (): string {
            const value = this.invoiceFinancialInfo.retention_summary?.retention_released || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },
    },

    mounted () {
        this.fetchData()
    },

    methods: {
        ...mapActions(invoiceViewStore, ['fetchInvoiceFinancialInfo']),

        async fetchData (): Promise<void> {
            try {
                this.loading = true
                await this.fetchInvoiceFinancialInfo(this.projectObj.project_local_id, this.$route.params.id)
                this.loading = false
                this.error = false
            } catch (e) {
                this.error = true
            } finally {
                this.loading = false
            }
        },
    },
})
