import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { bidRequestStore } from '@/io-modules/bid-requests/store/bidRequestStore'
import { mapState as mapVuexState } from 'vuex'
import validationMixin from '@/mixins/validationMixin'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import projectWizardClient from '@/api/project-wizard/projectWizardClient.js'
import bidRequestClient from '@/io-modules/bid-requests/api-clients/bidRequestClient'
import { Contact, ModalReturnData } from '@/io-modules/bid-requests/interfaces'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'PopupCompany',
    components: {
        PopupModal,
    },
    props: {
        companyFromProp: {
            type: Object,
            required: false,
            default: null,
        },
    },
    mixins: [validationMixin],
    data () {
        return {
            contacts: [] as Contact[],
            selectedContact: null,
            companyEmail: '',
            saving: false,
            addNewContact: {
                id: 'addNew',
                name: this.$t('--- Add New').toString(),
            },
            company: null,
        }
    },

    computed: {
        ...mapState(bidRequestStore, {
            companyFromStore: (store) => store.popup.company,
        }),

        ...mapVuexState('modal', {
            returnData: (state) => state.returnData
        }),

        optionsContacts (): Array<Contact> {
            if (this.company?.isGlobal) {
                return []
            }
            const list = this.contacts.filter((contact) => {
                return null !== contact.email
            })
            list.push(this.addNewContact)
            return list
        },

        submitText (): TranslateResult {
            return this.saving ? this.$t('Loading') : this.$t('Save')
        },
    },

    watch: {
        returnData: async function (returnData: ModalReturnData): Promise<void> {
            if (typeof returnData.response === 'undefined') {
                return
            }
            const response = returnData.response
            if ('contact' === response?.module) {
                const newId = parseInt(response.id.toString())
                await this.setContacts()
                const newElement = this.contacts.find((element) => {
                    return parseInt(element.id.toString()) === newId
                })
                if (newElement) {
                    this.selectedContact = newElement
                    this.selectContact(newElement)
                }
            }
        },
    },

    mounted (): void {
        if (this.companyFromProp) {
            this.company = this.companyFromProp
        } else {
            this.company = this.companyFromStore
        }

        this.setContacts()
    },

    methods: {
        ...mapActions(bidRequestStore, ['setPopup']),

        closePopup (): void {
            this.setPopup({ type: 'company', data: null })
            this.$emit('close')
        },

        async setContacts (): Promise<void> {
            const { data } = await projectWizardClient.loadCompanyContactsWithEmails(this.company.resource_id)
            this.contacts = data
        },

        selectContact (contact: Contact): void {
            if (contact.id !== this.addNewContact.id) {
                this.companyEmail = contact.email
                return
            }

            this.openInviteModal()

            this.closePopup()

            this.$nextTick(() => {
                this.removeContact()
                this.selectedContact = null
            })
        },

        openInviteModal (): void {
            this.$emit('openInviteModal')
        },

        removeContact (): void {
            this.companyEmail = null
        },

        async add (): Promise<boolean | void> {
            if (this.saving || !this.companyEmail) {
                return false
            }

            try {
                this.saving = true
                await bidRequestClient.updateCompanyEmail(this.company.resource_id, {
                    email: this.companyEmail
                })
                this.showNotification('success', this.$t('Email has been saved'))
                this.company.email = this.companyEmail
                this.$emit('updateLocalEmail', this.company)
                this.closePopup()
            } catch (exception) {
                this.showNotification('error', this.$t('Error occurred during saving company email.'), exception)
            }

            this.saving = false
        },
    }
})
