import { ref, computed, Ref } from 'vue'
import useLoader from '@/composables/useLoader.ts'
import projectDirectoryClient from '@/io-modules/rfis/api-clients/projectDirectoryClient.ts'
import projectSettingsClient from '@/io-modules/rfis/api-clients/projectSettingsClient.ts'
import drawingsClient from '@/io-modules/rfis/api-clients/drawingsClient.ts'
import { Company, Person } from '@/io-modules/rfis/interfaces/ProjectDirectory.ts'
import { ProjectSettings } from '@/io-modules/rfis/interfaces/ProjectSettings.ts'
import { DrawingSetOption } from '@/io-modules/rfis/interfaces/Rfi.ts'

export default (projectId: string) => {
    const { loading, load } = useLoader()

    const companies: Ref<Company[]> = ref(null)
    const officialReviewers: Ref<Person[]> = ref(null)
    const managers: Ref<Person[]> = ref(null)
    const allEmployees: Ref<Person[]> = ref(null)
    const projectSettings: Ref<ProjectSettings> = ref(null)
    const drawingSets: Ref<DrawingSetOption> = ref(null)

    const dictionariesLoaded = computed(() =>
        [companies.value, officialReviewers.value, managers.value, allEmployees.value, projectSettings.value, drawingSets.value]
        .every(Boolean))

    const dictionaries = computed(() => dictionariesLoaded.value
        ? {
            companies: companies.value,
            officialReviewers: officialReviewers.value,
            managers: managers.value,
            allEmployees: allEmployees.value,
            projectSettings: projectSettings.value,
            drawingSets: drawingSets.value,
        }
        : null)

    const getCompanies = async (): Promise<void> => {
        const { data } = await projectDirectoryClient.getCompanies(projectId)
        companies.value = data.items
    }

    const getOfficialReviewers = async (): Promise<void> => {
        const { data } = await projectDirectoryClient.getRfiOfficialReviewers(projectId)
        officialReviewers.value = data.items
    }

    const getManagers = async (): Promise<void> => {
        const { data } = await projectDirectoryClient.getRfiManagers(projectId)
        managers.value = data.items
    }

    const getPersons = async (): Promise<void> => {
        const { data } = await projectDirectoryClient.getPersons(projectId)
        allEmployees.value = data.items
    }

    const getProjectSettings = async (): Promise<void> => {
        const { data } = await projectSettingsClient.getSettings(projectId)
        projectSettings.value = data
    }

    const getDrawingSets = async (): Promise<void> => {
        const { data } = await drawingsClient.getDrawingSets(projectId)
        drawingSets.value = data.items
    }

    const getDictionaries = (): Promise<void> =>
        load(async (): Promise<void> => {
            await Promise.all([
                getCompanies(),
                getOfficialReviewers(),
                getManagers(),
                getPersons(),
                getProjectSettings(),
                getDrawingSets(),
            ])
        })

    return {
        getDictionaries,
        loading,
        dictionaries,

        companies,
        officialReviewers,
        managers,
        allEmployees,
        projectSettings,
        drawingSets,
    }
}
