import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import ProjectFundingCommonItems from '@/io-modules/project-funding/interfaces/ProjectFundingCommonItems'
import ProjectFundingRules from '@/io-modules/project-funding/interfaces/ProjectFundingRules'
import ProjectFundingFundName from '@/io-modules/project-funding/interfaces/ProjectFundingFundName'
import { CostCodes } from '@/io-modules/project-funding/interfaces/ProjectFundingCostCodes'

export default {
    getProjectRules (projectId: string): AxiosPromise<ProjectFundingCommonItems<ProjectFundingRules[]>> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        return client.get(`/funds-projects-rules/${ projectId }/list`)
    },

    getProjectRulesFunds (projectId: string): AxiosPromise<ProjectFundingCommonItems<ProjectFundingFundName[]>> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        return client.get(`/funds-without-projects-rules/${ projectId }`)
    },

    getCostCodes (projectId: string): AxiosPromise<CostCodes[]> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        return client.get(`funds-projects/${ projectId }/cost-codes?type=owner_budget`)
    },

    getSingleProjectRule (projectId: string, ruleId: string): AxiosPromise<ProjectFundingCommonItems<ProjectFundingRules>> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        if (!ruleId) {
            return Promise.reject(new Error('No rule ID provided'))
        }
        return client.get(`/funds-projects-rules/${ ruleId }`)
    },

    createSingleProjectRule (projectId: string, rule: ProjectFundingRules): AxiosPromise<ProjectFundingRules> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        if (!rule) {
            return Promise.reject(new Error('No rule data provided'))
        }
        return client.post(`/funds-projects-rules/${ projectId }`, rule)
    },

    editSingleProjectRule (ruleId: string, rule: ProjectFundingRules): AxiosPromise<ProjectFundingRules> {
        if (!ruleId) {
            return Promise.reject(new Error('No rule ID provided'))
        }
        if (!rule) {
            return Promise.reject(new Error('No rule data provided'))
        }
        return client.put(`/funds-projects-rules/${ ruleId }`, rule)
    },

    specifyRulesOrder (projectId: string, order: string[]): AxiosPromise {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        if (!order) {
            return Promise.reject(new Error('No order provided'))
        }
        return client.post(`/funds-projects-rules/${ projectId }/position`, { rule_ids: order })
    },

    deleteSingleProjectRule (ruleId: string): AxiosPromise {
        if (!ruleId) {
            return Promise.reject(new Error('No rule ID provided'))
        }
        return client.delete(`/funds-projects-rules/${ ruleId }`)
    },
}
