import { defineComponent } from 'vue'

import {
    AvatarCellCustomValueReturnType,
    SimpleLazyListAvatarHeader,
} from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'
import { AvatarType } from '@/constants/AvatarConstants.ts'
import LogoSize from '@/io-modules/bid-requests/components/company-logo/enums/LogoSize.ts'

import CompanyLogo from '@/io-modules/bid-requests/components/company-logo/CompanyLogo.vue'

export default defineComponent({
    name: 'AvatarCell',
    components: {
        CompanyLogo,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        column: {
            type: Object as () => SimpleLazyListAvatarHeader<unknown>,
            required: true,
        },
    },
    setup () {
        return {
            LogoSize,
            AvatarType,
        }
    },
    computed: {
        value (): AvatarCellCustomValueReturnType<unknown> {
            if (this.column.customValue) {
                return this.column.customValue(this.item)
            }

            return this.item[this.column.value]
        },
        isShowStatusPill (): boolean {
            return !!this.value
        }
    },
})
