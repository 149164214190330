import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'

const routes = {
    path: 'budget',
    name: 'project-budget',
    redirect: { name: 'project-budget-dashboard-summary' },
    children: [
        {
            path: 'creation/:budgetType?',
            name: 'project-budget-creation',
            component: () => import('@/io-modules/budget/views/budget-creation/BudgetCreation.vue'),
            meta: {
                name: 'Budget Creation',
                title: 'Budget Creation',
                ignoreProjectLayout: true,
                navigationKey: 'master_project_budget',
            },
            props: true,
        },
        {
            path: 'option/create/:budgetType?',
            name: 'budget-option-create',
            component: () => import('@/io-modules/budget/views/budget-option-create/BudgetOptionCreate.vue'),
            props: ({ params }) => ({ budgetType: extractBudgetType(params.budgetType) }),
            meta: {
                title: 'Budget Create',
                name: 'Budget Create',
                ignoreProjectLayout: true,
            },
        },
        {
            path: 'option/:optionId/edit/:budgetType?',
            name: 'budget-option-edit',
            component: () => import('@/io-modules/budget/views/budget-option-edit/BudgetOptionEdit.vue'),
            props: ({ params }) => ({ budgetType: extractBudgetType(params.budgetType) }),
            meta: {
                title: 'Edit Budget',
                name: 'Edit Budget',
                ignoreProjectLayout: true,
                showNotificationsForRoutes: ['project-budget-dashboard-summary', 'project-budget-dashboard-feasibility'],
            },
        },
        {
            path: 'option/:optionId/duplicate/:budgetType?',
            name: 'budget-option-duplicate',
            component: () => import('@/io-modules/budget/views/budget-option-duplicate/BudgetOptionDuplicate.vue'),
            props: ({ params }) => ({ budgetType: extractBudgetType(params.budgetType) }),
            meta: {
                title: 'Duplicate Budget',
                name: 'Duplicate Budget',
                ignoreProjectLayout: true,
            },
        },
        {
            path: ':budgetType(my_budget|owner_budget)?/:budgetId?',
            redirect: { name: 'project-budget-dashboard-summary' },
        },
        {
            path: ':budgetType(my_budget|owner_budget)?/:budgetId?/dashboard',
            component: () => import('@/io-modules/budget/views/budget-dashboard/BudgetDashboard.vue'),
            redirect: { name: 'project-budget-dashboard-summary' },
            meta: {
                ignoreProjectLayout: true,
                navigationKey: 'master_project_budget',
            },
            props: true,
            children: [
                {
                    path: 'summary',
                    name: 'project-budget-dashboard-summary',
                    component: () => import('@/io-modules/budget/views/budget-dashboard/budget-dashboard-summary/BudgetDashboardSummary.vue'),
                    meta: {
                        name: 'Budget Summary',
                        title: 'Budget Summary',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                        resourceView: 'budget-dashboard',
                        showNotificationsForRoutes: ['project-budget-dashboard-feasibility'],
                    },
                    props: true,
                },
                {
                    path: 'budget-snapshot/:budgetSnapshotId',
                    name: 'project-budget-dashboard-budget-snapshot-summary',
                    component: () => import('@/io-modules/budget/views/budget-dashboard/budget-dashboard-summary/BudgetDashboardSummary.vue'),
                    meta: {
                        name: 'Budget Snapshot',
                        title: 'Budget Snapshot',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                        resourceView: 'budget-dashboard',
                    },
                    props: true,
                },
                {
                    path: 'approvals/:budgetSnapshotId',
                    name: 'project-budget-dashboard-approvals',
                    component: () => import('@/io-modules/budget/views/budget-dashboard/budget-dashboard-budget-snapshot-view/BudgetDashboardBudgetSnapshotView.vue'),
                    meta: {
                        name: 'Budget Approvals View',
                        title: 'Budget Approvals View',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                        resourceView: 'budget-dashboard',
                    },
                    props: true,
                },
                {
                    path: 'snapshots',
                    name: 'project-budget-dashboard-snapshots',
                    component: () => import('@/io-modules/budget/views/budget-dashboard/budget-dashboard-snapshots/BudgetDashboardSnapshots.vue'),
                    meta: {
                        name: 'Budget Snapshots',
                        title: 'Budget Snapshots',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                        resourceView: 'budget-dashboard',
                    },
                    props: true,
                },
                {
                    path: 'timeline',
                    name: 'project-budget-dashboard-timeline',
                    component: () => import('@/io-modules/budget/views/budget-dashboard/budget-dashboard-timeline/BudgetDashboardTimeline.vue'),
                    meta: {
                        name: 'Budget Timeline',
                        title: 'Budget Timeline',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                        resourceView: 'budget-dashboard',
                    },
                    props: true,
                },
                {
                    path: 'changes',
                    name: 'project-budget-dashboard-changes',
                    component: () => import('@/io-modules/budget-changes/views/budget-changes-listing/BudgetChangesListing.vue'),
                    meta: {
                        name: 'Budget Changes',
                        title: 'Budget Changes',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                    },
                    props: true,
                },
                {
                    path: 'feasibility',
                    name: 'project-budget-dashboard-feasibility',
                    component: () => import('@/io-modules/budget/views/budget-dashboard/budget-dashboard-feasibility/BudgetDashboardFeasibility.vue'),
                    meta: {
                        name: 'Budget Feasibility',
                        title: 'Budget Feasibility',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                    },
                    props: true,
                },
            ],
        },
        {
            path: ':budgetId/feasibility/option/:budgetOptionId',
            component: () => import('@/io-modules/budget/views/budget-option-info/BudgetOptionInfo.vue'),
            redirect: { name: 'project-budget-feasibility-option-files' },
            name: 'project-budget-feasibility-option',
            props: true,
            children: [
                {
                    path: 'files',
                    name: 'project-budget-feasibility-option-files',
                    component: () => import('@/io-modules/budget/views/budget-option-info/budget-option-files/BudgetOptionFiles.vue'),
                    meta: {
                        name: 'Budget Option Files',
                        title: 'Budget Option Files',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                    },
                    props: true,
                },
                {
                    path: 'timeline',
                    name: 'project-budget-feasibility-option-timeline',
                    component: () => import('@/io-modules/budget/views/budget-option-info/budget-option-timeline/BudgetOptionTimeline.vue'),
                    meta: {
                        name: 'Budget Option Timeline',
                        title: 'Budget Option Timeline',
                        ignoreProjectLayout: true,
                        navigationKey: 'master_project_budget',
                    },
                    props: true,
                },
            ],
        },
    ],
}

const budgetBackwardCompatibilityRoutes = {
    path: 'budget/v3',
    redirect: { name: 'project-budget-dashboard-summary' },
    children: [
        {
            path: ':catchAll(.*)',
            redirect: { name: 'project-budget-dashboard-summary' },
        },
    ],
}

function extractBudgetType (value) {
    return Object.values(BudgetType).includes(value) ? value : BudgetType.MY_BUDGET
}

export default routes

export { budgetBackwardCompatibilityRoutes }
