import defaultState from './state'
import { cloneDeep } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export function SET_MODEL (state, value) {
    state.model = value
}

export function SET_FULL_MODEL (state, value) {
    state.fullModel = value
}

export function SET_BUDGET_SETTINGS (state, value) {
    state.budgetSettings = value
}

export function SET_SHOW_ADD_NEW_WORKFLOW_POPUP (state, value) {
    state.showAddNewWorkflowPopup = value
}

export function SET_ATTRIBUTES_OWNER_REMOTE_PROJECT_ID (state, value) {
    state.attributesOwnerRemoteProjectId = value
}
