import { defineComponent } from 'vue'
import { mapMutations, mapGetters } from 'vuex'
import SingleWorkspace from '../../parts/SingleWorkspace.vue'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import type { WorkspaceType } from '@/interfaces/components/invite-modal/WorkspaceType'

export default defineComponent({
    name: 'SelectWorkspaceType',
    components: {
        SingleWorkspace,
    },
    props: {
        componentKey: { type: String, required: true },
        preselectedWorkspaceType: { type: String, default: '' },
    },
    data () {
        return {
            selectedWorkspaceType: null,
            ownerAndRepWorkspacesList: [
                {
                    id: '1',
                    name: 'Owner / Developer',
                    color: '#C8D4F5',
                    icon: 'ownerdev-color.svg',
                    shortName: 'owner',
                    description: this.$t('Companies or individuals who act as the client and/or developer on the project.')
                },
                {
                    id: '2',
                    name: 'Owner’s Rep',
                    color: '#C8D4F5',
                    icon: 'owner-color.svg',
                    shortName: 'dev',
                    description: this.$t('apptype_description_dev')
                }
            ] as WorkspaceType[],
            otherWorkspacesList: [
                {
                    id: '3',
                    name: 'Design',
                    color: '#DED1F8',
                    icon: 'arch-color.svg',
                    shortName: 'ac',
                    description: this.$t('apptype_description_ac')
                },
                {
                    id: '4',
                    name: 'General Contractor',
                    color: '#F5D9CE',
                    icon: 'gc-color.svg',
                    shortName: 'gc',
                    description: this.$t('apptype_description_gc')
                },
                {
                    id: '5',
                    name: 'Subcontractor',
                    color: '#F5E5D0',
                    icon: 'sub-color.svg',
                    shortName: 'sc',
                    description: this.$t('Companies that provide labor or services for construction operations.')
                }
            ] as WorkspaceType[]
        }
    },
    computed: {
        ...mapGetters(
            'appStore', ['isGeneralContractor', 'isOwnerRep', 'isArchitect']
        ),
        isOwnerRepOrGcOrDesign (): boolean {
            return this.isGeneralContractor || this.isOwnerRep || this.isArchitect
        },
        workspacesList(): WorkspaceType[] {
            return (this.isOwnerRepOrGcOrDesign && this.componentKey === InviteModalClientKeys.PROJECT_WIZARD_CLIENT)
                ? this.ownerAndRepWorkspacesList
                : this.allWorkspacesList
        },
        allWorkspacesList (): WorkspaceType[] {
            return [...this.ownerAndRepWorkspacesList, ...this.otherWorkspacesList]
        },
        preselectedWorkspace (): WorkspaceType | null {
            if (!this.preselectedWorkspaceType) {
                return null
            }

            return this.allWorkspacesList.find(item => item.shortName === this.preselectedWorkspaceType)
        }
    },
    watch: {
        selectedWorkspaceType (value): void {
            // @ts-ignore
            this.setSelectedWorkspaceType({ key: this.componentKey, value })
        }
    },
    created () {
        this.resetSelectedWorkspaceType({ key: this.componentKey })

        if (this.preselectedWorkspaceType) {
            this.setSelectedWorkspaceType({ key: this.componentKey, value: this.preselectedWorkspaceType })
        }
    },
    methods: {
        ...mapMutations('inviteModal', {
            setSelectedWorkspaceType: 'SET_SELECTED_WORKSPACE_TYPE',
            resetSelectedWorkspaceType: 'RESET_SELECTED_WORKSPACE_TYPE'
        })
    },
})
