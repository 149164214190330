import { defineComponent } from 'vue'

import FloatMenu from '@/components/atoms/FloatMenu/FloatMenu.vue'

import { JobsiteLocation } from '@/components/jobsite-locations-select/interfaces/JobsiteLocations'

export default defineComponent({
    name: 'JobsiteLocationsAccordion',
    components: {
        FloatMenu,
    },
    props: {
        jobsiteLocationsPaths: {
            type: Array as () => JobsiteLocation[][],
            required: true,
        },
        editable: Boolean,
    },
    emits: ['remove'],
    methods: {
        getSelectedJobsiteLocationTitle (jobsiteLocationPath: JobsiteLocation[]): string {
            return jobsiteLocationPath[jobsiteLocationPath.length - 1]?.title
        },
        getJobsiteLocationPathTitles (jobsiteLocationPath: JobsiteLocation[]): string {
            return jobsiteLocationPath.map((jobsiteLocation: JobsiteLocation) => jobsiteLocation.title).join(' › ')
        },
    },
})
