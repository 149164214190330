import { createApp } from 'vue'
import Draggable from 'vuedraggable'
import jQuery from 'jquery'
import moment from 'moment'

//To compatibility Webpack - Vite
Object.assign(window, { $: jQuery, jQuery })
Object.assign(window, { moment: moment })

const app = createApp({
    components: {
        Draggable
    },
    data () {
        return {
        }
    },
})

app.config.compilerOptions.whitespace = 'condense'

export { app }
