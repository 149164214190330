<template>
    <div ref="schedule-tickets-list" class="io-schedule-tickets-list">
        <PageHeader :title="$t('Schedule List')">
            <template v-if="isLoaded" #actions>
                <template v-if="model.can_edit">
                    <IOButton variant="secondary" @click="moveToTemplatesView">{{ $t('Cancel') }}</IOButton>
                    <IOButton :disabled="!isSaveEnabled" @click="onClickSaveScheduleTicketsList">{{ $t('Save') }}</IOButton>
                </template>

                <IOButton v-else variant="secondary" @click="moveToTemplatesView">{{ $t('Close') }}</IOButton>
            </template>
        </PageHeader>

        <div v-if="isLoaded" class="io-container">
            <div class="io-container__top">
                <template v-if="!model.can_edit">
                    <div class="io-form-label-row-vertical io-list-name">
                        <label class="io-form-column-label">{{ $t('List Name') }}</label>
                        <div class="io-form-column-text">{{ model.name }}</div>
                    </div>

                    <div class="io-form-label-row-vertical io-list-description">
                        <label class="io-form-column-label">{{ $t('List Description') }}</label>
                        <div class="io-form-column-text">{{ model.description }}</div>
                    </div>
                </template>

                <template v-else>
                    <FormInputColumn
                        className="io-list-name"
                        :label="$t('List Name')"
                        :required="true"
                        :content="model.name"
                        @change="onChangeListName"
                    />

                    <FormInputColumn
                        className="io-list-description"
                        :label="$t('List Description')"
                        :customContent="true"
                    >
                        <LimitedTextarea
                            :maxLength="144"
                            :dynamicHeight="true"
                            :upperCounter="true"
                            v-model.trim="model.description"
                        />
                    </FormInputColumn>
                </template>
            </div>

            <div class="io-list-header">
                <span>{{ $t('Schedule Items') }}</span>
                <div v-if="model.can_edit" class="io-action-buttons">
                    <IOButton
                        variant="link"
                        icon="icon-plus"
                        size="small"
                        @click="addNewScheduleTicket(ScheduleTicketTypeEnum.TASK)">
                        {{ $t('Add an Activity') }}
                    </IOButton>
                    <IOButton
                        variant="link"
                        icon="icon-plus"
                        size="small"
                        @click="addNewScheduleTicket(ScheduleTicketTypeEnum.MILESTONE)"
                    >
                        {{ $t('Add a Milestone') }}
                    </IOButton>
                </div>
            </div>

            <div class="io-container__center">
                <div class="io-schedule-section__list">
                    <Draggable
                        v-bind="dragOptions"
                        group="schedule-tickets"
                        class="io-schedule-ticket"
                        draggable=".io-schedule-ticket"
                        handle=".icon-drag"
                        itemKey="schedule-tickets"
                        v-model="model.tickets"
                        @end="onChangeOrdering"
                    >
                        <template #item="{ element: scheduleTicket, index }">
                            <ScheduleTicket
                                :key="index"
                                :scheduleTicket="scheduleTicket"
                                :canEdit="model.can_edit"
                                @updateScheduleTicket="onUpdateScheduleTicket"
                                @createScheduleTicket="onCreateScheduleTicket"
                                @rejectChanges="onRejectScheduleTicketChanges"
                                @deleteScheduleTicket="onDeleteScheduleTicket"
                            />
                        </template>
                    </Draggable>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./ScheduleTicketsList.ts"/>
<style lang="scss" src="./ScheduleTicketsList.scss" scoped/>
