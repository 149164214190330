<template>
    <div v-if="fundDetails">
        <RightModal
            class="io-modal-fund-details"
            @close="closeModal"
            @clickOutside="closeModal"
        >
            <template #header>
                <div class="io-modal-fund-details__header">
                    <div class="io-modal-fund-details__header__top">
                        <div class="io-modal-fund-details__header__title">{{ $t('Fund Details') }}</div>
                        <div class="io-modal-fund-details__header__actions">
                            <IOButton
                                v-if="!fundDetails.is_shared"
                                variant="secondary"
                                @click="removeFundModals()"
                            >
                                {{ fundDetails.type === fundTypes.CAPITAL ? $t('Remove') : $t('Delete') }}
                            </IOButton>
                            <IOButton
                                v-if="!fundDetails.is_shared"
                                variant="secondary"
                                @click="toggleEditModal"
                            >
                                {{ $t('Edit') }}
                            </IOButton>
                            <span class="icon-cross" @click="closeModal"/>
                        </div>
                    </div>
                    <div class="io-modal-fund-details__header__tabs io-section-tabs">
                        <template v-for="tab in menuItems" :key="tab.name">
                            <div
                                class="io-single-tab"
                                :class="{'io-is-active': activeTab === tab.number}"
                                @click.prevent="changeActiveTab(tab.number)"
                            >
                                {{ tab.name }}
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template #default>
                <div v-if="activeTab === menuItemsEnum.DETAILS" class="io-modal-fund-details__wrapper">
                    <div class="io-modal-fund-details__info">
                        <div class="io-modal-fund-details__info__header">
                            <div class="io-modal-fund-details__info__title">
                                {{ fundDetails.fund.name }}

                                <StatusPill
                                    v-if="fundDetails.is_shared"
                                    size="big"
                                    :text="$t('Shared Fund')"
                                    :variant="pillVariant.INFO_BG"
                                />
                            </div>
                            <IOButton v-if="fundDetails.type === fundTypes.CAPITAL"
                                      :size="buttonSize.SMALL"
                                      variant="link"
                                      icon="icon-external-link"
                                      icon-position="right"
                                      @click="goToGlobalFund(fundDetails.fund.id)"
                            >
                                {{ $t('See Global Details') }}
                            </IOButton>
                        </div>
                        <div class="io-modal-fund-details__info__fields">
                            <div class="io-modal-fund-details__info__text-fields">
                                <IOSingleInfoRow :label="$t('Fund Type')">
                                    <template #info>
                                        <StatusPill
                                            size="small"
                                            :text="fundDetails.type === fundTypes.CAPITAL ? $t('Capital') : $t('Project Specific')"
                                            :variant="fundDetails.type === fundTypes.CAPITAL ? pillVariant.SUCESS_BG : pillVariant.INFO_BG"
                                        />
                                    </template>
                                </IOSingleInfoRow>
                                <IOSingleInfoRow :label="$t('Start Date')" :info="fundDetails.fund.start_date_formatted"
                                />
                                <IOSingleInfoRow :label="$t('Provider')">
                                    <template #info>
                                        <Avatar
                                            type="company"
                                            :employeeMongoId="fundDetails.provider.id"
                                            :employeeFullName="fundDetails.provider.name"
                                            showFullName
                                            size="sm"
                                        />
                                    </template>
                                </IOSingleInfoRow>
                            </div>
                            <div class="io-modal-fund-details__info__text-fields">
                                <IOSingleInfoRow :label="$t('Account Number')" :info="fundDetails.fund.account_number"
                                />
                                <IOSingleInfoRow :label="$t('Expiration Date')"
                                                 :info="fundDetails.fund.expiration_date_formatted"
                                />
                                <IOSingleInfoRow :label="$t('Contact Person')">
                                    <template #info>
                                        <Avatar
                                            :employeeMongoId="fundDetails.fund.contact.id"
                                            :employeeFullName="`${ fundDetails.fund.contact.firstname } ${ fundDetails.fund.contact.lastname }`"
                                            showFullName
                                            size="sm"
                                        />
                                    </template>
                                </IOSingleInfoRow>
                            </div>
                            <div class="io-modal-fund-details__info__number-fields">
                                <IOSingleInfoRow v-if="fundDetails.type === fundTypes.CAPITAL"
                                                 :label="$t('Total Fund Amount')"
                                                 :info="$filters.formatCurrency(fundDetails.summary.total_fund_amount)"
                                />
                                <div class="io-modal-fund-details__info__allocation">
                                    <IOSingleInfoRow :label="$t('Allocation to Project')"
                                                     :info="$filters.formatCurrency(fundDetails.allocated_amount)"
                                    />

                                    <FinancialTableCalculator
                                        v-if="fundDetails.lines && fundDetails.lines.length"
                                        :initial-filled-lines="fundDetails.lines"
                                        :titles="allocationModalTitles"
                                        :can-edit="false"
                                    />
                                </div>
                                <IOSingleInfoRow :label="$t('% of Total Project Funding').toLowerCase()"
                                                 :info="$filters.formatPercent(fundDetails.summary.funding_factor, 2)"
                                />
                                <IOSingleInfoRow :label="$t('Paid to Date')"
                                                 :info="$filters.formatCurrency(fundDetails.summary.total_paid_to_date_amount)"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="io-modal-fund-details__info">
                        <IOSingleInfoRow :label="$t('Note from Last Edit')"
                                         :info="fundDetails.note"
                        />
                    </div>
                    <div class="io-modal-fund-details__info">
                        <div class="io-modal-fund-details__file-header">
                            <span>{{ $t('Files') }}</span>
                            <StatusPill
                                size="small"
                                :text="fundDetails.documents ? fundDetails.documents.length : 0"
                                :variant="pillVariant.INFO_BG"
                            />
                        </div>
                        <SimpleFileListFromProp
                            modelType="funds"
                            :documents="filesLines"
                            :itemId="fundDetails.id"
                            :editable="false"
                            showCreatorName
                            showUpdateDate
                            showDownloadButton
                        />
                    </div>
                </div>
                <div v-if="activeTab === menuItemsEnum.TIMELINE" class="io-modal-fund-details__timeline">
                    <Timeline :logs="timelineHistory"/>
                </div>
            </template>
        </RightModal>

        <ManageProjectSpecFundModal v-if="showEditModal"
                                    :fund-info-for-edit="fundDetails"
                                    @fundChanged="getInfo"
                                    @closeModal="toggleEditModal"/>
    </div>
</template>

<script lang="ts" src="./FundDetailsModal.ts"></script>
<style lang="scss" src="./FundDetailsModal.scss" scoped></style>
