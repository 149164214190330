<template>
    <div class="io-single-notification">
        <div class="io-single-notification__content" :class="{'io-single-notification__content--clickable': notice.routeLink}" @click="redirect">
            <div class="io-single-notification__icon" :class="notice.colorClass"><i :class="notice.icon"></i></div>
            <div class="io-single-notification__info">
                <div class="io-single-notification__title" v-if="notice.title" :class="{'io-single-notification__title--short': notice.cutTitle}">{{ notice.title }}</div>
                <div class="io-single-notification__caption" v-if="notice.caption" :class="{'io-single-notification__caption--short': notice.cutCaption}">{{ notice.caption }}</div>
            </div>
        </div>
        <div class="io-single-notification__dismiss" v-if="!notice.hideDismissButton" @click="dismissNotice">Dismiss</div>
    </div>
</template>

<script src="./single-notification.js"></script>
<style lang="scss" src="./single-notification.scss" scoped></style>