import { isFeatureFlagEnabled } from '@/modules/projects/routes/feature-flag-status'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
import { NavigationGuardNext, RouteLocationRaw } from 'vue-router'

const ReportingDashboardsView = () => import('@/io-modules/reporting/views/reporting-dashboards-view/ReportingDashboardsView.vue')
const ReportingDashboardView = () => import('@/io-modules/reporting/views/reporting-dashboard-view/ReportingDashboardView.vue')

const isFFEnabled = async (): Promise<boolean> => {
    const [isReportingModuleFFEnabled, isReportingModuleRolesFFEnabled] = await Promise.all([
        isFeatureFlagEnabled(FeatureFlagsConsts.REPORTING_MODULE, false),
        isFeatureFlagEnabled(FeatureFlagsConsts.REPORTING_MODULE_ROLES, false),
    ])
    return isReportingModuleFFEnabled || isReportingModuleRolesFFEnabled
}

export default [
    {
        path: '/reporting',
        name: 'reporting',
        component: ReportingDashboardsView,
        meta: { title: 'Reporting' },
        async beforeEnter (to: RouteLocationRaw, from: RouteLocationRaw, next: NavigationGuardNext): Promise<void> {
            if (await isFFEnabled()) {
                next()
            } else {
                next({ name: 'dashboard' })
            }
        }
    },
    {
        path: '/reporting/:id',
        name: 'reporting-dashboard',
        component: ReportingDashboardView,
        meta: { title: 'Reporting Dashboard' },
        async beforeEnter (to: RouteLocationRaw, from: RouteLocationRaw, next: NavigationGuardNext): Promise<void> {
            if (await isFFEnabled()) {
                next()
            } else {
                next({ name: 'dashboard' })
            }
        }
    }
]
