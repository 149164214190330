<template>
    <div class="io-files-flex-column">
        <!-- uploaded items -->
        <div v-if="showUploadInfo" class="io-files-holder io-f-row io-uploaded-files-wrapper" :style="classFiles">
            <div v-for="(file, index) in files" :key="index" class="io-single-photo">
                <template v-if="fileNotLoaded(file)">
                    <div class="io-photo-holder io-photo-loading">
                        <div id="io-loader-1" class="io-loader"></div>
                    </div>
                </template>

                <div class="io-photo-descr">
                    <div v-if="file.f_uploader" class="io-f-row io-label-row">
                        <div class="io-f-row">
                            <i class="fas fa-user"></i>
                            <div class="io-label">{{ $t('Added by:') }}</div>
                        </div>
                        <p>{{ file.f_uploader }}</p>
                    </div>
                    <div class="io-f-row io-label-row">
                        <div class="io-f-row">
                            <i class="fas fa-clock"></i>
                            <div class="io-label">{{ $t('Last update:') }}</div>
                        </div>
                        <p>{{ $filters.formatDate(file.f_create_date) }}</p>
                    </div>
                    <div class="io-f-row io-label-row">
                        <div class="io-f-row">
                            <i class="fas fa-clock"></i>
                            <div class="io-label">{{ $t('File name:') }}</div>
                        </div>
                        <p>{{ file.f_name }}</p>
                    </div>

                    <div v-if="!disabled || allowDeleteFile(file)" class="io-f-row io-edit io-file-actions-row">
                        <a v-if="!disabled" @click="downloadFile(file)">
                            <i class="fas fa-download"></i>{{ $t('Download') }}
                        </a>
                        <a v-if="allowDeleteFile(file)" :title="$t('Delete')" class="io-attachment-delete" @click="deleteFileItem(file._id)">
                            <i class="fas fa-trash"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- SINGLE IMAGE VERSION - ONLY TITLE -->
        <div v-if="singleUploadMode && !singleUploadModeAllowReplace" :style="classFiles">
            <template v-for="(file, index) in files" :key="index" class="io-single-photo">
                <a :href="getApiUrlFile(file._id)" target="_blank">
                    <i class="fas fa-eye"></i>
                    <span v-if="singleCustomName">{{ singleCustomName }}</span>
                    <span v-else>{{file.f_name }}</span>
                </a>
            </template>
        </div>


        <template v-if="allowUpload && possibleUpload">
            <link href="/js/fine-uploader/fine-uploader-new.css" media="screen" rel="stylesheet" type="text/css"/>
            <!-- if instant upload enabled -->
            <div class="io-files">

                <item v-for="(file, index) in readyFiles" :key="index" :file="file"
                      @download-item="downloadFile"
                      @remove-item="deleteFile" />

            </div>
            <!-- upload box to display -->
            <div v-show="(!disabled && (filesDuringLoading === 0 && showLoading)) || (!showLoading)"
                 class="fileUploaderBox io-uploader-box"
                 :style="getCustomStyles">
            </div>

            <!-- fineuploader template -->
            <component :is="'script'"  :id="`qq-template-base-${uid}`" type="text/template">
                <div class="io-drop-file-box">
                    <div class="io-dropbox io-f-column">
                        <div class="io-f-row io-fine-uploader-main-wrapper io-fine-uploader-main-wrapper">
                            <div class="qq-uploader-selector qq-uploader io-qq-uploader qq-gallery" qq-drop-area-text="Drop files here">
                                <div class="qq-total-progress-bar-container-selector qq-total-progress-bar-container">
                                    <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" class="qq-total-progress-bar-selector qq-progress-bar qq-total-progress-bar"></div>
                                </div>
                                <div class="qq-upload-drop-area-selector qq-upload-drop-area" qq-hide-dropzone>
                                    <span class="qq-upload-drop-area-text-selector"></span>
                                </div>
                                <div class="io-uploader-accept-files">
                                    {{ $filters.uploaderAcceptableFiles(validationOptions.acceptFiles, ', ') }}
                                </div>
                                <div class="qq-upload-button-selector qq-upload-button">
                                    <div>{{ uploadButtonText }}</div>
                                </div>
                                <span class="qq-drop-processing-selector qq-drop-processing">
                                    <span>{{ $t('Processing dropped files...') }}</span>
                                    <span class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"></span>
                                </span>

                                <ul class="qq-upload-list-selector qq-upload-list" role="region" aria-live="polite" aria-relevant="additions removals" style="display: none;">
                                    <li>
                                        <span role="status" class="qq-upload-status-text-selector qq-upload-status-text"></span>
                                        <div class="qq-progress-bar-container-selector qq-progress-bar-container">
                                            <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" class="qq-progress-bar-selector qq-progress-bar"></div>
                                        </div>
                                        <span class="qq-upload-spinner-selector qq-upload-spinner"></span>
                                        <div class="qq-thumbnail-wrapper">
                                            <img class="qq-thumbnail-selector" qq-max-size="120" qq-server-scale/>
                                        </div>
                                        <button type="button" class="qq-upload-cancel-selector qq-upload-cancel">{{ $t('X') }}</button>
                                        <button type="button" class="qq-upload-retry-selector qq-upload-retry">
                                            {{ $t('Retry') }}
                                        </button>

                                        <div class="qq-file-info" style="display: none">
                                            <div class="qq-file-name">
                                                <span class="qq-upload-file-selector qq-upload-file"></span>
                                                <span class="qq-edit-filename-icon-selector qq-edit-filename-icon" :aria-label="$t('Edit filename')"></span>
                                            </div>
                                            <input class="qq-edit-filename-selector qq-edit-filename" tabindex="0" type="text"/>
                                            <span class="qq-upload-size-selector qq-upload-size"></span>
                                            <button type="button" class="qq-btn qq-upload-delete-selector qq-upload-delete">
                                                {{ $t('Remove') }}
                                            </button>
                                            <button type="button" class="qq-btn qq-upload-pause-selector qq-upload-pause">
                                                {{ $t('Pause') }}
                                            </button>
                                            <button type="button" class="qq-btn qq-upload-continue-selector qq-upload-continue">
                                                {{ $t('Continue') }}
                                            </button>
                                        </div>
                                    </li>
                                </ul>

                                <dialog class="qq-alert-dialog-selector">
                                    <div class="qq-dialog-message-selector"></div>
                                    <div class="qq-dialog-buttons">
                                        <button type="button" class="qq-cancel-button-selector form-button">{{ $t('Close') }}</button>
                                    </div>
                                </dialog>

                                <dialog class="qq-confirm-dialog-selector">
                                    <div class="qq-dialog-message-selector"></div>
                                    <div class="qq-dialog-buttons">
                                        <button type="button" class="qq-cancel-button-selector form-button">{{ $t('No') }}</button>
                                        <button type="button" class="qq-ok-button-selector form-button">{{ $t('Yes') }}</button>
                                    </div>
                                </dialog>

                                <dialog class="qq-prompt-dialog-selector">
                                    <div class="qq-dialog-message-selector"></div>
                                    <input type="text"/>
                                    <div class="qq-dialog-buttons">
                                        <button type="button" class="qq-cancel-button-selector form-button">{{ $t('Cancel') }}</button>
                                        <button type="button" class="qq-ok-button-selector form-button">{{ $t('Ok') }}</button>
                                    </div>
                                </dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </component>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import item from './item'
    import fineUploader from 'fine-uploader'
    import { eventBus } from '@/mixins/eventBus'
    import { isObject, merge } from 'lodash'
    import { SweetAlert } from '@/plugins/sweet-alert/index.js'

    export default {
        components: {
            item
        },
        props: {
            identifier: {
                type: String,
                required: false,
                default: ''
            },
            endpoint: {
                type: String,
                required: false,
                default: ''
            },
            customEndpoint: {
                type: String,
                required: false,
                default: ''
            },
            originatorItemId: {
                type: String,
                required: false,
                default: ''
            },
            forceDisplay: {
                type: Boolean,
                required: false,
                default: false
            },
            uploaderButtonText: {
                type: String,
                required: false
            },
            allowUpload: {
                type: Boolean,
                required: false,
                default: true
            },
            // If enabled, we will allow to upload only one file
            singleUploadMode: {
                type: Boolean,
                required: false,
                default: false
            },
            singleUploadModeAllowReplace: {
                type: Boolean,
                required: false,
                default: false
            },
            // Custom text to display in single mode - instead of filename
            singleCustomName: {
                type: String,
                required: false,
                default: null
            },

            showUploadInfo: {
                type: Boolean,
                required: false,
                default: true
            },
            showLoading: {
                type: Boolean,
                required: false,
                default: true
            },
            additionalParams: {
                type: Object,
                required: false,
                default: () => ({})
            },
            mode: {
                type: String,
                required: false,
                default: 'shared'
            },
            value: {
                type: Array,
                default: () => [],
                required: true
            },
            multipleMode: {
                type: Boolean,
                required: false,
                default: true
            },
            autoUpload: {
                type: Boolean,
                required: false,
                default: true
            },
            readyFiles: {
                type: Array,
                required: false,
                default: () => []
            },
            filesArray: {
                type: Array,
                required: false,
                default: () => []
            },
            validationOptions: {
                type: Object,
                required: false,
                default: () => ({
                    allowedExtensions: [],
                    acceptFiles: []
                })
            },
            // To store new files and also all data for loading existing files
            files: {
                type: Array,
                required: false,
                default: () => []
            },
            // Endpoint URL to run on enable instant mode
            instantEndpoint: {
                type: String,
                required: false,
                default: ''
            },
            // Endpoint URL to run on enable instant mode
            instantParams: {
                type: Object,
                required: false,
                default: () => ({})
            },
            filesOnBottom: {
                type: Boolean,
                required: false,
                default: false
            },

            /**
             * This option block to add new files
             */
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },

            /**
             * File param name used to send to API
             */
            inputName: {
                type: String,
                required: false,
                default: 'qqfile'
            },

            /**
             * Send file extension to API
             */
            sendFileExtension: {
                type: Boolean,
                required: false,
                default: false
            },
            /**
             * Allow remove
             */
            allowRemove: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        mixins: [SweetAlert],
        data () {
            return {
                filesDuringLoading: 0,
                uid: crypto.randomUUID(),
                instance: null
            }
        },
        computed: {
            ...mapGetters(
                'appStore', ['getAuthData']
            ),
            /**
             * Check if we can still upload
             * Multi mode or single without file
             */
            possibleUpload () {
                // for multi or allow replace of single file
                if ((!this.singleUploadMode) || (this.singleUploadMode && this.singleUploadModeAllowReplace)) {
                    return true
                    // single file in files empty allow
                } else {
                    return !this.files.length
                }
            },

            classFiles () {
                return 'order:' + (this.filesOnBottom ? 2 : 1)
            },

            getCustomStyles () {
                const style = {}
                style.order = (this.filesOnBottom ? 1 : 2)

                if (this.forceDisplay) {
                    style.display = 'block !important'
                }
                return style
            },

            uploadButtonText () {
                if (this.uploaderButtonText) {
                    return this.uploaderButtonText
                }

                if (this.singleUploadMode) {
                    return this.$t('Select file')
                }

                return this.$t('Select files')
            },
        },
        // Watch and fix indicator
        watch: {
            filesDuringLoading: function (newData) {
                if (newData < 0) {
                    this.filesDuringLoading = 0
                }
            }
        },
        mounted () {
            this.init()

            // change placeholder if single upload mode is enabled
            if (this.singleUploadMode) {
                this.$nextTick(() => {
                    $(this.$el).find('.io-fine-uploader-main-wrapper').find('.io-qq-uploader').attr('qq-drop-area-text', this.$t('Drop file here'))
                })
            }

            this.$nextTick(() => {
                $(this.$el).find('.io-fine-uploader-main-wrapper').find('.io-qq-uploader').attr('qq-drop-area-text', this.$t('Drop files here'))
            })

            eventBus.$on('uploader/removeFileFromQueue', this.removeFileFromQueue)
            eventBus.$on('uploader/uploadFilesInQueue', this.uploadFilesInQueue)
            eventBus.$on('uploader/setOriginatorItemId', this.setOriginatorItemId)
            eventBus.$on('uploader/addFiles', this.addFiles)
        },
        beforeUnmount () {
            eventBus.$off('uploader/removeFileFromQueue')
            eventBus.$off('uploader/uploadFilesInQueue')
            eventBus.$off('uploader/setOriginatorItemId')
            eventBus.$off('uploader/addFiles')
        },
        methods: {
            getFileDataType (file) {
                if (file.f_extension === 'pdf' || file.f_type === 'application/pdf') {
                    return 'iframe'
                }
                return ''
            },

            allowDeleteFile (file) {
                if (typeof file === 'object' && this.allowRemove) {
                    if (file.is_owner !== undefined) {
                        return file.is_owner
                    }
                }

                return this.allowUpload && !this.disabled
            },
            // Set file as loaded when is ready
            setLoaded (file) {
                file.loaded = true
                this.$forceUpdate()
            },
            getFileExtension (filename) {
                let ext = /^.+\.([^.]+)$/.exec(filename)
                return ext == null ? '' : ext[1].toLowerCase()
            },
            downloadFile (file) {
            },
            deleteFileItem: function (id, index) {
                const messages = {
                    text: this.$t('Are you sure you want to remove this file?'),
                    title: this.$t('Remove this file?')
                }
                this.confirm(() => {
                    this.$emit('onBeforeDelete')
                    $.get('/files/remove/' + id, function (response) {
                        this.files.splice(this.files.map(function (e) {
                            return e._id
                        }).indexOf(id), 1)
                        this.$emit('onDelete', id)
                    }.bind(this)).then((result) => {
                        // reinit fineuploader
                        if (this.singleUploadMode && this.files.length === 0) {
                            this.init()
                        }
                    })
                }, messages)
            },
            deleteFile: function (id) {
                this.$emit('onBeforeDelete')
                $.get('/files/remove/' + id, function (response) {
                    this.readyFiles.splice(this.readyFiles.map(function (e) {
                        return e.f_id
                    }).indexOf(id), 1)
                    this.$emit('onDelete', id)
                }.bind(this))
            },
            setEndpoint () {
                let appName = (window.location.host).split('.')[0]
                let appType = localStorage.getItem('appType')
                // if custom endpoint is not specified
                if (this.customEndpoint === '') {
                    this.endpoint = process.env.API_URL + `${ appName }/${ appType }/file/upload`
                    return this.endpoint
                } else {
                    return process.env.API_URL + `${ appName }/${ appType }/` + this.customEndpoint
                }
            },
            isMultipleUploadModeAllowed () {
                let multipleMode = this.multipleMode
                if (this.singleUploadMode) {
                    multipleMode = false
                }
                return multipleMode
            },
            init () {
                if (!this.allowUpload) {
                    console.info(this.$t('Upload not allowed'))
                    return false
                }

                let endpointUrl = this.setEndpoint()
                let multipleMode = this.isMultipleUploadModeAllowed()

                // create fineuploader instance
                this.instance = new fineUploader.FineUploader({
                    element: this.$el.querySelector('.fileUploaderBox'),
                    multiple: multipleMode,
                    template: `qq-template-base-${ this.uid }`,
                    request: {
                        endpoint: endpointUrl,
                        inputName: this.inputName
                    },
                    thumbnails: {
                        placeholders: {
                            waitingPath: '/js/fine-uploader/placeholders/waiting-generic.png',
                            notAvailablePath: '/js/fine-uploader/placeholders/not_available-generic.png'
                        }
                    },
                    debug: false,
                    cors: {
                        expected: true,
                        sendCredentials: true,
                    },
                    deleteFile: {
                        enabled: true,
                        method: 'POST',
                        endpoint: '/files/uploadremove'
                    },
                    autoUpload: this.autoUpload,
                    validation: this.validationOptions,
                    callbacks: {
                        onValidate: (data) => {
                            let extension = data.name.split('.').pop().toLowerCase()
                            if (this.validationOptions.allowedExtensions.length > 0) {
                                if (this.validationOptions.allowedExtensions.includes(extension)) {
                                    return true
                                } else {
                                    this.showPopupAlert({
                                        title: this.$t('File extension not allowed.'),
                                        caption: `${ this.$t('Accepted extensions: ') } ${ this.validationOptions.acceptFiles }`,
                                        icon: 'icon-alert-triangle',
                                        buttons: [
                                            {
                                                text: this.$t('Close'),
                                                class: 'io-btn-light',
                                                action: null,
                                            },
                                        ],
                                    })

                                    return false
                                }
                            }
                            return true
                        },
                        onError: (id, name, errorReason, xhrOrXdr) => {
                            // console.error(fineUploader.format('Error on file number {} - {}.  Reason: {}', id, name, errorReason))
                            this.toggleLoading(false)
                            this.filesDuringLoading--
                            this.$emit('onError', {
                                id: id,
                                name: name,
                                errorReason: errorReason,
                                xhrOrXdr: xhrOrXdr
                            })
                        },
                        onComplete: (id, name, response) => {
                            this.toggleLoading(false)
                            this.filesDuringLoading--

                            let pack = {
                                uploaderId: id, // id in uploader (local)
                                itemId: response.targetItemId, // item id to which file has been attached
                                originatorItemId: response.originatorItemId, // custom uuid related to element which triggered an upload
                                uploadTypeName: response.uploadTypeName,
                                id: response.id, // id in files_upload
                                f_id: response.fid, // real file id
                                f_name: response.name,
                                f_create_user_id: this.getAuthData.u_id,
                                f_create_user: this.getAuthData.u_firstname + ' ' + this.getAuthData.u_lastname
                            }

                            this.filesArray.push(pack)
                            this.value.push(pack.id)
                            this.files.push(response.file)
                            this.$emit('onCompleteFile', response.file)
                            this.$forceUpdate()

                            this.filesDuringLoading--
                            this.emitRefresh({ id: id, name: name, pack: pack }, 'onComplete')
                        },
                        onCancel: (id, name) => {
                            this.filesDuringLoading--
                            this.toggleLoading(false)
                            this.emitRefresh({ id: id, name: name }, 'onCancel')
                        },
                        onSubmit: (id, name) => {
                            this.emitRefresh({ id: id, name: name }, 'onSubmit')
                            if (this.sendFileExtension) {
                                const extension = this.getFileExtension(name)
                                this.setCustomUploaderParams({ file_type: extension })
                            }
                        },
                        onUpload: () => {
                            this.toggleLoading(true)
                            this.filesDuringLoading++
                            this.$emit('onUpload')
                        },
                        onAllComplete: () => {
                            this.$emit('onAllComplete')
                        },
                        onProgress: (id, name, uploadedBytes, totalBytes) => {
                            this.$emit('onProgress', {
                                id: id,
                                name: name,
                                uploadedBytes: uploadedBytes,
                                totalBytes: totalBytes,
                                percentage: Math.round((uploadedBytes / totalBytes) * 100)
                            })
                        }
                    }
                })

                // set custom params
                this.setCustomUploaderParams()

                // some fix to missing button label after uploader reinit
                if (this.singleUploadMode) {
                    this.$nextTick(() => {
                        $(this.$el).find('.io-fine-uploader-main-wrapper').find('.io-qq-uploader .qq-upload-button div:first').text(
                            this.$t('Select file')
                        )
                    })
                }
            },
            setCustomUploaderParams (otherParams = null) {
                const otherParamsObject = isObject(otherParams) ? otherParams : {}
                const otherParamsFinal = Object.assign({}, otherParamsObject)

                this.instance.setParams(merge({ mode: this.mode }, this.additionalParams, otherParamsFinal))
                this.instance.setDeleteFileParams({ mode: this.mode })
            },
            addFiles (files) {
                this.instance.addFiles(files)
            },
            toggleLoading (flag) {
                if (this.showLoading) {
                    this.$store.dispatch('loadingBar/setLoading', flag, { root: true })
                }
            },
            removeFileFromQueue (id) {
                this.instance.cancel(id)
            },
            uploadFilesInQueue () {
                this.instance.uploadStoredFiles()
            },
            setOriginatorItemId (data) {
                if (typeof data === 'object') {
                    if (data.identifier === this.identifier) {
                        if (data.originatorItemId !== undefined) {
                            // set props
                            this.originatorItemId = data.originatorItemId
                            // set origintor item id (ex. project, progress report)
                            this.additionalParams.originatorItemId = this.originatorItemId
                        }
                        // set custom item id (useful when using one uploader for many separate items)
                        if (data.item_id !== undefined) {
                            this.additionalParams.item_id = data.item_id
                        }
                        // set upload type id
                        if (data.uploadTypeId !== undefined) {
                            this.additionalParams.item_type = data.uploadTypeId
                        }
                        // set upload type name
                        if (data.uploadTypeName !== undefined) {
                            this.additionalParams.uploadTypeName = data.uploadTypeName
                        }
                        // set project id
                        if (data.projectId !== undefined) {
                            this.additionalParams.projectId = data.projectId
                        }
                        //
                        this.setCustomUploaderParams()
                    }
                }
            },
            emitRefresh (data, event) {
                this.$nextTick(() => {
                    this.$emit(event, {
                        id: data.id,
                        name: data.name,
                        additionalParams: this.additionalParams,
                        pack: data.pack,
                        items: this.instance.getUploads({ status: [
                            fineUploader.status.SUBMITTED,
                            fineUploader.status.SUBMITTING,
                            fineUploader.status.QUEUED,
                            fineUploader.status.UPLOADING,
                            fineUploader.status.UPLOAD_FINALIZING,
                            fineUploader.status.UPLOAD_RETRYING,
                            fineUploader.status.UPLOAD_SUCCESSFUL
                        ] })
                    })
                })
            },
            fileNotLoaded (file) {
                return file && !file.loaded
            }
        }
    }
</script>

<style type="text/css" lang="scss" scoped>
.io-files-flex-column {
    display: flex;
    flex-direction: column;
}

    .io-uploaded-files-wrapper {
        .io-attachment-delete i:hover {
            color: #f26666 !important;
        }
        .io-file-actions-row {
            justify-content: space-between !important;
        }
        .io-photo-descr * {
            font-size: 12px !important;
        }
    }
    .io-uploader-accept-files {
        position: absolute;
        text-align: center;
        bottom: 10px;
        width: 98%;
        margin-top: -10px;
        color: #9598a5 !important;
        font-size: 12px;
    }
</style>
