import { defineComponent } from 'vue'
import { AnnotationData } from '@/io-modules/documents/interfaces/AnnotationData'
import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'
import { mapState } from 'pinia'
import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import SingleMarkup from '@/io-modules/documents/components/right-panel/markups-tab/others/single-markup/SingleMarkup.vue'
import getFilteredItems from '@/io-modules/documents/composables/getFilteredItems'

export default defineComponent({
    name: 'Others',
    components: {
        SingleMarkup
    },
    props: {
        searchedValue: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState(viewDocumentStore, [
            'annotations'
        ]),
        otherMarkups (): AnnotationData[] {
            return getFilteredItems(this.annotations, this.searchedValue, AnnotationTypes.BASIC)
        },
    },
    methods: {
        selectAnnotation (id: string): void {
            this.$emit('select-annotation', id)
        },
        updateAnnotations (): void {
            this.$emit('update-annotations')
        },
        deleteBasicAnnotation (data: { document_id: string, annotation_id: string }): void {
            this.$emit('delete-basic-annotation', data)
        },
    }
})
