import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'

export default defineComponent({
    name: 'InvitationButton',
    props: {
        column: { type: Object, required: true }
    },
    computed: {
        ...mapGetters('lazyList', {
            getRecords: 'getRecords'
        }),
        notAllInvitesAccepted (): boolean {
            if (isEmpty(this.getRecords)) {
                return false
            }

            return this.getRecords.some(invitation => invitation.status !== 'accepted')
        }
    },
    methods: {
        onInviteAllClick (): void {
            this.$emit('inviteAll')
        }
    }
})
