import { ApiClient } from '@/api/api'
let client = ApiClient

export default {
    get (companyId) {
        return client.get(`company/get/${companyId}`)
    },
    save (data) {
        return client.realJson(`company/save`, data)
    },
    saveV2 (data) {
        return client.realJson(`company/save/v2`, data)
    },
    savePaymentDetails (data) {
        return client.realJson(`company/save-payment-details`, data)
    },
    invite (companyId) {
        return client.get(`company/invite/${companyId}`)
    },
    inviteCancel (companyId) {
        return client.get(`company/invite-cancel/${companyId}`)
    },
    deleteCompany (companyId) {
        return client.get(`company/delete/${companyId}`)
    },
    saveLogo (companyId, fileId) {
        return client.realJson(`company/save-logo`, {
            id: companyId,
            logo: [fileId]
        })
    },
    toggleImportant (companyId) {
        return client.post(`company/toggle-important/${companyId}`)
    },
    addNote (noteData) {
        return client.post(`/company-notes`, noteData)
    },
    searchCompanies (search) {
        return client.realJson('/company/search/v2', { search })
    },
}
