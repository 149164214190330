<template>
    <ClientApprovalCard
        v-if="showClientApprovalCard"
        :client="invoice.client_approval.client"
        :status="invoice.client_approval.status"
        :actions="clientCardActions"
        :showSecondaryButton="true"
        :dateSubmitted="invoice.client_approval.submitted_to_client"
        :isSubmittedToClient="invoice.client_approval.submitted_to_client"
        :dateApprovedOrRejected="invoice.client_approval.status_date"
    />
    <ResourceApprovals
        :hide-approval-workflow="hideApprovalWorkflow"
        :resourceApprovals="resourceApprovals"
        :total="netInvoiceValueWithoutFactor"
        @approverApproveSelected="initApproveModal"
        @approverRejectSelected="initRejectModal"
        @approverReviewSelected="initReviewModal"
        @approverOverridden="$emit('refreshApprovableResourceData')"
    />
</template>

<script lang="ts" src="./Approvals.ts"></script>
<style lang="scss" src="./Approvals.scss" scoped></style>
