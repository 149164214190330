import { defineStore } from 'pinia'
import { CommentsStoreStateInterface } from '@/io-modules/documents/interfaces/CommentsStoreStateInterface'

export const commentsStore = defineStore('comments', {
    state: (): CommentsStoreStateInterface => ({
        xfdf: null,
        comment_text: null,
        shared_with: [],
        uuid: null
    }),
    actions: {
        setCommentData (key: string, value: unknown): void {
            this[key] = value
        }
    }
})
