<template>
    <IOModal
        width="480"
        class="io-bulk-edit-modal"
        :title="$t('Edit Category')"
        icon="icon-edit"
        @close="$emit('close')"
    >
        <IOLabel type="bold-max" is-required>{{ $t('Category') }}</IOLabel>

        <Multiselect
            track-by="value"
            label="label"
            :options="categories"
            :searchable="true"
            :placeholder="$t('Choose Category')"
            v-model="selectedCategory"
        />

        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">{{ $t('Cancel') }}</IOButton>

            <IOButton :disabled="!selectedCategory" @click="$emit('save', selectedCategory)">
                {{ $t('Save') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./BulkEditModal.ts"></script>
