<template>
    <div class="io-punches-tab">
        <Placeholder
            :subtitle="$t('There are no Punch Items on this drawing yet. Click Add Punch Item to add one.')"
            iconColor="var(--main-lighten-2)"
            icon="icon-pin-2"
        />
    </div>
</template>

<script lang="ts" src="./Punches.ts"></script>
<style lang="scss" src="./Punches.scss" scoped></style>
