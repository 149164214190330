import multiSelect from 'vue-multiselect'
import { debounce, get, isArray, orderBy } from 'lodash'

export default {
    name: 'smart-tags',
    props: {
        modelValue: {
            required: true,
            type: Array
        },
        editable: {
            required: false,
            type: Boolean,
            default: false
        },
        pattern: {
            required: true,
            type: String
        },

        simpleArray: {
            default: false,
            type: Boolean
        },

        uppercased: {
            default: false,
            type: Boolean
        },
        placeholder: {
            required: false,
            type: String,
            default: 'Type to search or add tag'
        }
    },
    components: {
        multiSelect
    },
    data () {
        return {
            loading: false,
            tags: [],
            options: [],
            autoCompleteItems: [],
            defaultTagClass: 'io-ti-tag'
        }
    },
    mounted () {
        this.prepareTags()
        this.prepareOptions()
    },
    methods: {
        clearTags () {
            this.tags = []
        },
        prepareTags () {
            if (this.modelValue && this.modelValue.length > 0) {
                let preparedTags = []
                for (let i = 0; i < this.modelValue.length; i++) {
                    const item = this.modelValue[i]

                    preparedTags.push(
                        this.simpleArray
                            ? this.prepareTagSimpleArray(item)
                            : this.prepareTagDefault(item)
                    )
                }

                this.tags = orderBy(preparedTags, ['is_system'], ['desc'])
                this.emitTags()
            }
        },
        prepareOptions () {
            if (this.options && this.options.length > 0) {
                let preparedTags = []
                for (let i = 0; i < this.options.length; i++) {
                    const item = this.options[i]

                    preparedTags.push(
                        this.simpleArray
                            ? this.prepareTagSimpleArray(item)
                            : this.prepareTagDefault(item)
                    )
                }

                this.options = orderBy(preparedTags, ['is_system'], ['desc'])
            }
        },

        prepareTagDefault(item) {
            if (item.name && item.name.length > 0) {
                let classes = `${this.defaultTagClass} `
                classes = classes.concat(item.is_system ? 'io-ti-is-system' : '')

                return {
                    name: item.name,
                    text: item.name,
                    is_system: item.is_system,
                    classes: classes
                }
            }
        },
        prepareTagSimpleArray(item) {
            return {
                name: item,
                text: item,
                is_system: false,
                classes: ''
            }
        },
        searchable (query) {

            if (query.length < 2) {
                return
            }
            if (!this.pattern) {
                console.error('Pattern is required!')
                return
            }

            this.getAutoCompleteOptions(query)

        },
        getAutoCompleteOptions: debounce(function (query) {
            if (query && query.length >= 2) {
                this.loading = true
                let requestData = { pattern: query, search: this.pattern }
                this.$api.post('/tags/search', requestData).then(response => {
                    const autoCompleteTags = get(response.data, 'tags', [])
                    this.handleAutoCompleteOptions(autoCompleteTags)

                    this.loading = false
                }).catch(error => {
                    console.warn(error)
                    this.loading = false
                })
            }
        }, 900),

        handleAutoCompleteOptions (tags) {
            let result = []

            if (tags && isArray(tags)) {
                for (let i = 0; i < tags.length; i++) {
                    const tagName = get(tags[i], 'name', false)
                    const tagExists = result.includes(tagName)
                    if (tagName && !tagExists) {

                        if(!this.options.find(option => option.text == tagName)) {
                            this.options.push({
                                name: tagName,
                                text: tagName
                            })
                        }
                        result.push({text: tagName})
                    }
                }
            }

        },


        addTag (newTag) {
            if (newTag.length < 2 || this.loading) return
            if (this.loading) return

            let tagObj = {}

            if (this.uppercased) {
                tagObj.text = newTag.toUpperCase()
            }
            tagObj.classes = this.defaultTagClass
            tagObj.name = newTag
            tagObj.text = newTag.trim()

            this.tags.push(tagObj)
            this.options.push(tagObj)
        },

        emitTags () {
            if (this.simpleArray) {
                let simpleArray = []
                this.tags.forEach(tag => {
                    simpleArray.push(tag.text)
                })
                this.$emit('update:modelValue', simpleArray)
            } else {
                this.$emit('update:modelValue', this.tags)
            }
        }
    },

    watch: {
        'tags': {
            handler () {
                this.emitTags()
            },
            deep: true
        }
    }
}
