import Tabs from '@/components/atoms/tabs/Tabs.vue'
import TopbarActions from '../../components/invoices-list/topbar-actions/TopbarActions.vue'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import PageHeader from '@/components/page-header/PageHeader.vue'
import ExportToExcelModal from '../../components/invoices-list/export-to-excel-modal/ExportToExcelModal.vue'
import InvoiceRouteName from '../../enums/InvoiceRouteName'
import projectObjMixin from '@/modules/projects/mixins/projectObjMixin'
import AddInvoiceOptions from '@/modules/projects/modules/apps/common/pay-apps/views/parts/modals/add-invoice-options.vue'
import AddInvoice from '@/modules/projects/modules/apps/common/pay-apps/views/parts/modals/add-invoice/add-invoice.vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import ManageColumnsModal from '@/components/manage-columns-modal/ManageColumnsModal.vue'
import ListFiltersModal from '../../components/invoices-list/list-filters-modal/ListFiltersModal.vue'
import TableColumnsMixin from '../../mixins/TableColumnsMixin'
import invoicesListsClient from '../../api-clients/invoicesListsClient'
import { Tab } from '@/components/atoms/tabs/TabInterface'
import { defineComponent, nextTick } from 'vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { mapState, mapActions } from 'pinia'
import { mapGetters as mapGettersVuex, mapState as mapStateVuex } from 'vuex'
import { invoicesStore } from '../../stores/invoicesList'
import ListType from '../../enums/ListType'
import InvoicesTab from '../../enums/InvoicesTab'
import ListFilters from '../../interfaces/invoices-list/ListFilters'
import UpdateInvoicesListStrategy from '@/io-modules/invoices/strategies/UpdateInvoicesListStrategy'
import FiltersMapper from '@/io-modules/invoices/mappers/FiltersMapper'
import ListingName from '../../enums/ListingName'
import listTypeName from '../../helpers/listTypeName'
import MultiSwitch from '@/components/atoms/MultiSwitch/MultiSwitch.vue'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'
import InvoiceCreationSlideout from '../../components/modals/invoice-creation-slideout/InvoiceCreationSlideout.vue'
import InvoiceHeader from '../../enums/InvoiceHeader'
import ColumnSettings from '@/components/manage-columns-modal/interfaces/ColumnSettings'

export default defineComponent({
    name: 'ProjectInvoicesTemplate',
    components: {
        Tabs,
        TopbarActions,
        AddInvoiceOptions,
        AddInvoice,
        AlertBox,
        ManageColumnsModal,
        ListFiltersModal,
        ExportToExcelModal,
        LoadingScreen,
        PageHeader,
        InvoiceCreationSlideout,
        MultiSwitch,
    },
    mixins: [projectObjMixin, TableColumnsMixin, breadcrumbsMixin],
    data () {
        return {
            currentRouteTab: this.$route.meta.tab,
            loaded: false,
            vendorsList: [],
            showManageColumns: false,
            showTableCorrectnessInfo: false,
            showExportModal: false,
            defaultValues: Object.freeze({
                start: 1,
                end: 32,
                open: 20,
                due: 25,
                month_selection: 1
            }),
            model: {},
            showCreateInvoiceModal: false,
            preSelectedContractId: null as string | null,
        }
    },
    computed: {
        ...mapState(invoicesStore, [
            'showContentLoader',
            'getListFixedColumns',
            'getListScrollableColumns',
            'applicationPackages',
            'filtersListingsParams',
            'vendorsDetails',
        ]),

        ...mapStateVuex('project', ['projectObj']),

        ...mapGettersVuex('appStore', ['isOwner', 'isOwnerRep']),

        ...mapStateVuex('payapps', {
            modalInvoiceEnabled: (state) => state.modalInvoice.enabled,
            showInvoiceOptions: (state) => state.modal.addInvoiceOptions,
            has_billing_settings: (state) => state.has_billing_settings,
        }),

        fixedColumnsList (): ColumnSettings[] {
            const list = this.getListFixedColumns(this.invoicesType)
            if (!this.isOwner && !this.isOwnerRep) {
                const index = list.findIndex(item => InvoiceHeader.ApplicationPackageNumber === item.key)
                if (index > -1) {
                    list.splice(index, 1)
                }
            }

            return list
        },

        scrollableColumnsList (): ColumnSettings[] {
            const list = this.getListScrollableColumns(this.invoicesType)
            if (!this.isOwner && !this.isOwnerRep) {
                const index = list.findIndex(item => InvoiceHeader.ApplicationPackageNumber === item.key)
                if (index > -1) {
                    list.splice(index, 1)
                }
            }

            return list
        },

        invoicesTabs (): Tab[] {
            return [
                ...(this.isOwner || this.isOwnerRep) ? [{ key: ListingName.AppPackages, title: this.$tc('Application Packages'), name: InvoiceRouteName.InvoicesApplicationPackagesList }] : [],
                { key: ListingName.Received, title: this.$tc('Vendor Invoices'), name: InvoiceRouteName.InvoicesReceivedDefaultList },
                ...(this.isOwner) ? [{ key: ListingName.Shared, title: this.$tc('Shared Invoices'), name: InvoiceRouteName.InvoicesSharedList }] : [],
                ...(!this.isOwner) ? [{ key: ListingName.My, title: this.$tc('Your Invoices'), name: InvoiceRouteName.InvoicesMyList }] : [],
            ]
        },

        listType (): ListType {
            return listTypeName(this.$route.meta.listType)
        },

        updateInvoicesListStrategy (): UpdateInvoicesListStrategy {
            return new UpdateInvoicesListStrategy(this.projectObj.project_local_id)
        },

        getVendorName (): string {
            if (InvoiceRouteName.InvoicesReceivedVendorDetails !== this.$route.name) {
                return ''
            }

            return this.vendorsDetails[this.$route.params.id] ? this.vendorsDetails[this.$route.params.id].vendor.name || '' : ''
        },

        invoicesNavigationTitle (): string {
            return this.isOwner || this.isOwnerRep ? this.$tc('Invoicing and Application Packages') : this.$tc('Invoicing')
        },

        pageHeaderTitle (): string {
            return this.getVendorName ? `${ this.getVendorName } Details` : this.invoicesNavigationTitle
        },

        breadcrumbs (): Array<object> {
            return [
                ...this.$route.meta.breadcrumbs || [],
                {
                    text: this.projectObj._project_name || this.projectObj._name || 'Project',
                    to: { name: 'project-dashboard', params: { pid: this.$route.params.pid } }
                },
                {
                    text: this.invoicesNavigationTitle,
                    to: { name: InvoiceRouteName.InvoicesReceivedList, params: { pid: this.$route.params.pid } }
                },
                ...(InvoiceRouteName.InvoicesReceivedVendorDetails === this.$route.name && this.getVendorName) ? [{
                    text: `${ this.getVendorName } Details`,
                    to: { name: InvoiceRouteName.InvoicesReceivedVendorDetails, params: { pid: this.$route.params.pid, id: this.$route.params.id } }
                }] : []
            ]
        },
    },
    watch: {
        $route (): void {
            this.currentRouteTab = this.$route.meta.tab
            this.clearListParams()
            this.setBreadcrumbs(this.breadcrumbs)
        },

        breadcrumbs (): void {
            this.setBreadcrumbs(this.breadcrumbs)
        },
    },
    async mounted () {
        await this.loadProject()
        await this.$store.dispatch('appStore/setProjectCurrency', { projectId: this.$route.params.pid })

        this.loaded = true

        this.checkTableFixedColumnsInView()
        window.addEventListener('resize', this.setupTableFixedColumns)
        this.setBreadcrumbs(this.breadcrumbs)

        if (this.$route.query && this.$route.query['contract-id']) {
            this.preSelectedContractId = this.$route.query['contract-id'] as string

            this.createInvoice()
        }
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.setupTableFixedColumns)
        this.emptyBreadcrumbs()
    },
    methods: {
        ...mapActions(invoicesStore, [
            'setContentLoaderValue',
            'fetchReceivedInvoices',
            'fetchMyInvoices',
            'fetchSharedInvoices',
            'fetchApplicationPackages',
            'setDataLoaderValue',
            'updateListItems',
            'setFilters',
            'clearListParams',
            'fetchReceivedInvoicesVendors',
        ]),

        switchTab (index: number): void {
            this.$router.push({ name: this.invoicesTabs[index].name })
        },

        createInvoice (): void {
            this.showCreateInvoiceModal = true
        },

        openManageColumns (): void {
            this.showManageColumns = true
        },

        closeManageColumns (): void {
            this.showManageColumns = false
        },

        async saveManageColumnsSettings (value: HeaderTable[]): Promise<void> {
            const objectSettings = {}
            value.forEach(item => {
                objectSettings[item.key] = {
                    id: item.id,
                    order: item.order,
                    visible: item.visible,
                    fixed: item.fixed,
                    key: item.key
                }
            })
            localStorage.setItem(`${ this.listType }-manage-columns`, JSON.stringify(objectSettings))

            try {
                await invoicesListsClient.setManageColumnsSettings(
                    this.projectObj.project_local_id,
                    this.listType,
                    Object.values(objectSettings),
                )
            } catch(error) {
                console.log(error)
            }

            await this.updateInvoicesColumnsSettings()
            await nextTick()
            this.setupInvoicesColumnsLeft()
            this.closeManageColumns()
            this.checkTableFixedColumnsInView()
        },

        checkTableFixedColumnsInView (): void {
            const fixedColumnElements = document.querySelectorAll('th.io-fixed-column')

            if (fixedColumnElements.length && fixedColumnElements.length > 3) {
                const lastFixedElement = fixedColumnElements[fixedColumnElements.length - 1]
                const lastFixedElementWidth = lastFixedElement.getBoundingClientRect().width
                const lastFixedElementLeft = lastFixedElement.getBoundingClientRect().left

                this.showTableCorrectnessInfo = lastFixedElementWidth + lastFixedElementLeft >= window.innerWidth - 20
            }
        },

        setupTableFixedColumns (): void {
            this.checkTableFixedColumnsInView()
            this.setupInvoicesColumnsLeft()
        },

        async updateLists (): Promise<void> {
            this.setDataLoaderValue(true)
            if (this.$route.name === InvoiceRouteName.InvoicesReceivedVendorsList) {
                await this.fetchReceivedInvoicesVendors(this.projectObj.project_local_id)
            } else {
                await this.updateInvoicesListStrategy.updateByType(this.listType, this.$route.params.id)
            }
            this.setDataLoaderValue(false)
        },

        handleInvoiceAdded (data: object): void {
            if (ListType.My === data.type && InvoiceRouteName.InvoicesMyList !== this.$route.name) {
                this.$router.push({ name: InvoiceRouteName.InvoicesMyList })
                return
            }

            if (ListType.My !== data.type && InvoiceRouteName.InvoicesReceivedDefaultList !== this.$route.name) {
                this.$router.push({ name: InvoiceRouteName.InvoicesReceivedDefaultList })
                return
            }

            this.updateLists()
        },

        setDefaultValues (): void {
            const isNewModel = [
                this.model.client_start_day,
                this.model.client_end_day,
                this.model.client_billing_start_day,
                this.model.client_billing_end_day,
                this.model.start_day,
                this.model.end_day,
                this.model.billing_start_day,
                this.model.billing_end_day
            ].every(item => (item || null) === null)

            if (isNewModel) {
                this.model.client_start_day = this.defaultValues.start
                this.model.client_end_day = this.defaultValues.end
                this.model.client_billing_start_day = this.defaultValues.open
                this.model.client_billing_end_day = this.defaultValues.due
                this.model.start_day = this.defaultValues.start
                this.model.end_day = this.defaultValues.end
                this.model.billing_start_day = this.defaultValues.open
                this.model.billing_end_day = this.defaultValues.due
                this.model.client_month_selection = this.defaultValues.month_selection
                this.model.month_selection = this.defaultValues.month_selection
            }
        },

        saveFilters (filters: ListFilters): void {
            const mappedFilters = FiltersMapper.toListingParams(filters)
            this.setFilters(this.listType, mappedFilters)

            localStorage.setItem(`${ this.$route.meta.listType }FiltersV3`, JSON.stringify(filters))

            if (InvoiceRouteName.InvoicesReceivedVendorsList !== this.$route.name) {
                this.updateLists()
            }
        }
    }
})
