import { defineComponent } from 'vue'

enum SwitchSize {
    NORMAL = 'normal',
    SMALL = 'small'
}

interface label {
    name: string,
    text: string,
    icon?: string
}

export enum SwitchVariant {
    PRIMARY = 'primary',
    WHITE = 'white'
}

export interface OptionsType {
    items: {
        style?: string,
        delay?: number,
        preSelected?: string,
        disabled?: boolean,
        labels: label[]
    }
}

type SwitchClass<T extends string> = `io-${ T }`

export default defineComponent({
    name: 'ToggleSwitch',
    props: {
        options: { type: Object as () => OptionsType },
        modelValue: { type: String },
        name: { type: String },
        disabled: { type: Boolean },
        group: { type: String, default: '' },
        selectedItem: { default: 'unknown' },
        size: { type: String as () => SwitchSize, default: SwitchSize.NORMAL },
        customClasses: { type: Array as () => String[], default: () => [] },
        variant: {
            type: String,
            default: SwitchVariant.PRIMARY
        }
    },
    data () {
        return {
            selected: false as boolean,
            defaultOptions: {
                items: {
                    labels: {}
                }
            } as any,
            selectedItemCopy: null as string
        }
    },
    computed: {
        sizeClassName (): SwitchClass<SwitchSize> {
            return `io-${ this.size }` as SwitchClass<SwitchSize>
        },
        className (): string {
            return [this.sizeClassName, ...this.customClasses].join(' ')
        }
    },
    watch: {
        modelValue (value: any): void {
            this.selectedItemCopy = value
        },
        options (value: any): void {
            if (value !== null && value !== undefined) {
                this.mergeDefaultOptionsWithProp(value)
            }
        },
        selectedItem (): void {
            this.selectedItemCopy = this.selectedItem
        }
    },
    beforeMount () {
        this.selectedItemCopy = this.selectedItem
    },
    mounted () {
        if (this.options !== null && this.options !== undefined) {
            this.mergeDefaultOptionsWithProp(this.options)
        }
        if (this.defaultOptions.items.preSelected !== 'unknown') {
            this.selectedItemCopy = this.defaultOptions.items.preSelected
            this.$emit('update:modelValue', this.selectedItemCopy)
        } else if (this.modelValue) {
            this.selectedItemCopy = this.modelValue
            this.$emit('update:modelValue', this.selectedItemCopy)
        }
    },
    methods: {
        checkMode (event: any, mode: any) {
            if (mode !== undefined) {
                this.changeView(mode)
            } else {
                this.toggle(event)
            }
        },

        iconClass (label: label) {
            return !label.text && label.icon ? 'toggle-switch__label--icons' : ''
        },

        toggle (event: any) {
            if (!this.defaultOptions.items.disabled && this.selectedItemCopy !== event.target.id.replace(this.group, '')) {
                this.selected = true
                this.selectedItemCopy = event.target.id.replace(this.group, '')
                this.$emit('selected', this.selected)
                this.$emit('update:modelValue', this.selectedItemCopy)
                this.$emit('change', {
                    value: event.target.id.replace(this.group, ''),
                    srcEvent: event
                })
            }
        },

        mergeDefaultOptionsWithProp (options: any) {
            this.defaultOptions = options
        },

        changeView (mode: any) {
            this.$emit('changeView', mode)
            this.selectedItemCopy = mode
        }
    }
})
