import { cloneDeep } from 'lodash'

/**
 * Get materials with states anmes
 * @param state
 * @return array
 */
export function getMaterialsWithStates (state) {
    const list = cloneDeep(state.lists.materials)

    list.forEach(material => {
        material.nameWithState = material.m_name
        if (material.state && material.state.name) {
            material.nameWithState += ` (${material.state.name})`
        }
    })

    return list.sort((a, b) => {
        return a.nameWithState.localeCompare(b.nameWithState, 'en', { sensitivity: 'base' })
    })
}

export function getContactsList (state) {
    return state.lists.contacts
}

export function getEmployeeList (state) {
    return state.lists.employees
}
