<template>
    <div class="io-line-progress">
        <div class="io-line-progress__label">
            <span v-if="icon" class="io-line-progress__icon" :class="icon"/>
            <span>{{ completedNumber }}/{{ totalNumber }}</span>
            {{ label }}
            <slot name="content"/>
        </div>

        <ProgressTrack v-show="totalNumber > 1" :current="completedNumber" :total="totalNumber" />
    </div>
</template>

<script lang="ts" src="./LineProgress.ts"></script>
<style lang="scss" src="./LineProgress.scss" scoped></style>
