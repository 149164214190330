import { defineComponent } from 'vue'
import LienWaiversTable from './lien-waivers-table/LienWaiversTable.vue'
import LienWaiversHeader from './lien-waivers-header/LienWaiversHeader.vue'
import { invoiceViewStore } from '../../../stores/invoiceView'
import { mapState } from 'pinia'
import AttachLienWaiverModal from './attach-lien-waiver-modal/AttachLienWaiverModal.vue'
import LienWaiver from '../../../interfaces/lien-waivers/LienWaiver'
import FilePreview from './file-preview/FilePreview.vue'
import { FileInterface } from '../../../../../interfaces/components/files-new/FileInterface'

export default defineComponent({
    name: 'LienWaivers',
    components: {
        LienWaiversTable,
        LienWaiversHeader,
        AttachLienWaiverModal,
        FilePreview,
    },
    data () {
        return {
            showAttachModal: false,
            itemToEdit: null,
            itemToEditIndex: -1,
            filePreview: null,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, ['invoiceLienWaivers', 'isEditingMode']),
    },
    methods: {
        openAttachModal (): void {
            this.showAttachModal = true
        },

        closeAttachModal (): void {
            this.showAttachModal = false
            this.itemToEdit = null
            this.itemToEditIndex = -1
        },

        editItem (data : { item: LienWaiver, itemIndex: number }): void {
            this.itemToEdit = data.item
            this.itemToEditIndex = data.itemIndex
            this.openAttachModal()
        },

        updateItem (data: LienWaiver): void {
            this.invoiceLienWaivers[this.itemToEditIndex] = { ...this.invoiceLienWaivers[this.itemToEditIndex], ...data }
        },

        setPreviewFile (data: FileInterface): void {
            this.filePreview = data
        },
    },
})