export enum PillVariant {
    ACCENT = 'accent',
    ACCENT_LIGHT = 'accent-light',
    YELLOW = 'yellow',
    YELLOW_LIGHT = 'yellow-light',
    GREEN = 'green',
    GREEN_LIGHT = 'green-light',
    GREEN_MEDIUM = 'green-medium',
    GREEN_DARK = 'green-dark',
    RED = 'red',
    RED_LIGHT = 'red-light',
    BLUE = 'blue',
    BLUE_LIGHT = 'blue-light',
    GRAY = 'gray',
    GRAY_LIGHT = 'gray-light',
    ORANGE = 'orange',
    ORANGE_LIGHT = 'orange-light',
    PURPLE = 'purple',
    PURPLE_LIGHT = 'purple-light',

    FILTERS_COUNTER = 'filters-counter',

    SUCESS = 'success',
    SUCESS_DARKER = 'success-darker',
    SUCESS_LIGHTEN = 'success-lighten',
    SUCESS_BG = 'success-bg',

    WARNING = 'warning',
    WARNING_DARKER = 'warning-darker',
    WARNING_LIGHTEN = 'warning-lighten',
    WARNING_BG = 'warning-bg',

    ERROR = 'error',
    ERROR_DARKER = 'error-darker',
    ERROR_LIGTEN = 'error-lighten',
    ERROR_BG = 'error-bg',

    INFO = 'info',
    INFO_DARKER = 'info-darker',
    INFO_MEDIUM = 'info-medium',
    INFO_LIGHTEN = 'info-lighten',
    INFO_BG = 'info-bg',

    CLOSED = 'closed',
    CLOSED_DARKER = 'closed-darker',
    CLOSED_LIGHTEN = 'closed-lighten',
    CLOSED_BG = 'closed-bg',

    MAIN = 'main',
    MAIN_LIGHT = 'main-light',

    WORKSPACE_AC = 'accent-ac',
    WORKSPACE_DEV = 'accent-dev',
    WORKSPACE_GC = 'accent-gc',
    WORKSPACE_REP = 'accent-dev',
    WORKSPACE_OWNER = 'accent-dev',
    WORKSPACE_SC = 'accent-sc',
}

export enum PillSize {
    NORMAL = 'normal',
    BIG = 'big'
}
