import { defineComponent } from 'vue'
import EmptyDescription from '../empty-description/EmptyDescription.vue'
export default defineComponent({
    components: {
        EmptyDescription
    },
    props: {
        scope: { type: String, required: true, default: '' }
    },
    data () {
        return {
            projectScopeCopy: ''
        }
    },
    beforeMount () {
        this.projectScopeCopy = this.scope
    },
})
