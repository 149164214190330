<template>
    <div class="io-financial-panel">
        <div v-if="!error && !loading" class="io-financial-panel__item">
            <div class="io-financial-panel__item-title">
                {{ $t('Billed to Date') }}
            </div>
            <div class="io-financial-panel__item-content">
                <IOSingleInfoRow :label="$t('% of Completed Work')" :info="grossBilledToDate" />
                <div class="io-two-columns">
                    <IOSingleInfoRow :label="$t('Gross Total Completed & Stored Amount')" :info="grossTotal" />
                    <IOSingleInfoRow :label="$t('Net Total Completed & Stored Amount')" :info="netTotal" />
                </div>
            </div>
        </div>
        <RequestedDataErrorPlaceholder v-else :title="$t('Billed to Date')" :loading="loading" @reload="fetchData" />
        <div v-if="!error && !loading" class="io-financial-panel__item" :class="{ 'io-financial-panel__item--modified': isRetentionModified }">
            <div class="io-financial-panel__item-title">
                {{ $t('Retention Summary') }}
                <StatusPill v-if="isRetentionModified" variant="yellow-light" size="big" :text="$t('Retention Modified')"  />
            </div>
            <div class="io-financial-panel__item-content">
                <div class="io-two-columns">
                    <IOSingleInfoRow :label="$t('Total Retention %')" :info="totalRetentionPercentage" />
                    <IOSingleInfoRow :label="$t('Current Retention %')" :info="currentRetentionPercentage" />
                </div>
                <div class="io-two-columns">
                    <IOSingleInfoRow :label="$t('Total Retention Amount')" :info="totalRetention" />
                    <IOSingleInfoRow :label="$t('Retention Released this Period')" :info="retentionReleased" />
                </div>
            </div>
        </div>
        <RequestedDataErrorPlaceholder v-else :title="$t('Retention Summary')" :loading="loading" @reload="fetchData" />
        <div v-if="!error && !loading" class="io-financial-panel__item">
            <div class="io-financial-panel__item-title">
                {{ $t('Paid to Date') }}
            </div>
            <div class="io-financial-panel__item-content">
                <IOSingleInfoRow :label="$t('Paid to Date Percentage')" :info="paidToDatePercentage" />
                <IOSingleInfoRow :label="$t('Paid to Date Amount')" :info="paidToDateAmount" />
            </div>
        </div>
        <RequestedDataErrorPlaceholder v-else :title="$t('Paid to Date')" :loading="loading" @reload="fetchData" />
        <div v-if="!error && !loading" class="io-financial-panel__item">
            <div class="io-financial-panel__item-title">
                {{ $t('Lien Waivers') }}
            </div>
            <div class="io-financial-panel__item-content">
                <IOSingleInfoRow :label="$t('Conditional')" :info="lienWaiversConditional" />
                <IOSingleInfoRow :label="$t('Unconditional')" :info="lienWaiversUnconditional" />
            </div>
        </div>
        <RequestedDataErrorPlaceholder v-else :title="$t('Lien Waivers')" :loading="loading" @reload="fetchData" />
    </div>
</template>

<script lang="ts" src="./FinancialPanel.ts"></script>
<style lang="scss" src="./FinancialPanel.scss" scoped></style>
