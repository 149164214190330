import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { get } from 'lodash'
import appTypes from '@/base/appTypes'

export default defineComponent({
    components: {
        StatusPill,
    },
    props: {
        data: { type: Object as () => any, required: true, default: null },
        column: { type: Object as () => ColumnInterface, required: true, default: null }
    },
    data () {
        return {
            imageExist: true,
            small: false,
            isHeaderHtml: false
        }
    },
    computed: {
        additionalPillText (): string | undefined {
            if (this.column?.additional?.getPillText instanceof Function) {
                return this.column?.additional?.getPillText(this.data)
            }

            return this.column?.additional?.additionalPillText
        },
        additionalPillVariant (): string | undefined {
            if (this.column?.additional?.getPillVariant instanceof Function) {
                return this.column?.additional?.getPillVariant(this.data)
            }

            return this.column?.additional?.additionalPillVariant
        },
        tooltipValue (): string {
            const tooltipField = get(this.column, ['additional', 'tooltip'], null)
            if (typeof tooltipField === 'function') {
                return tooltipField(this.data)
            }
            return ''
        },
        isMarkedWithRing (): boolean {
            const additionalHTMLFunction = get(this.column, ['additional', 'additionalHTMLFunction'], null)

            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.data)
            }

            return false
        },
        isOffSystem (): boolean {
            if (this.data.hasOwnProperty('workspace_type')) {
                return 'Off-System' === this.data['workspace_type']
            }

            if (this.data.hasOwnProperty('company_client')) {
                return !this.data['company_client']?.onsystem
            }

            if (
                this.data.hasOwnProperty('workspace_types')
                || this.data.hasOwnProperty('account_type')
            ) {
                const field = this.column.field

                if (field === 'contact') {
                    return !this.data[field]?.onsystem
                } else if (field === 'company') {
                    return !this.data['company']['onsystem']
                } else {
                    return 'Off-System' === this.data['workspace_types']
                }
            }

            if (this.data.hasOwnProperty('company')) {
                return !this.data['company']?.onsystem
            }

            if (this.column.field === 'client') {
                const element = this.data[this.column.field]

                return element?.type?.toLowerCase() === appTypes.TYPE_OFF_SYSTEM || !element?.is_on_system
            }

            return false
        },
        isArchived (): boolean {
            const field = this.column.field

            return !this.data[field] ? false : this.data[field]['is_archived']
        },
        isOffSystemOrArchived (): boolean {
            return this.isOffSystem || this.isArchived
        },
        elementExist (): boolean {
            const field = this.column.field
            const dataField = this.data[field]

            return !!dataField
        },
        hasAdditionalPill (): boolean {
            const field = this.column.field
            const pill = this.column?.additional?.additionalPill
            const hasPill = Boolean(this.data[pill] || this.data[field][pill])

            return hasPill
        }
    },
    created () {
        const field = this.column.field
        const dataField = this.data[field]
        const avatarField = this.column?.additional?.img

        if (dataField?.hasOwnProperty('imageExist')) {
            this.imageExist = dataField.imageExist
        }

        if (dataField?.hasOwnProperty('image_exists')) {
            this.imageExist = dataField.image_exists
        }

        if (dataField?.hasOwnProperty('avatar_exists')) {
            this.imageExist = dataField.avatar_exists
        }

        if (['logo', 'avatar', 'creator_photo'].includes(avatarField)) {
            const avatarFieldProp = dataField?.hasOwnProperty(avatarField)

            if (!dataField) {
                this.imageExist = false
            }

            if (dataField && !dataField[avatarField]) {
                this.imageExist = false
            }

            if (
                avatarFieldProp
                && (!dataField[avatarField] || '0' === dataField[avatarField])
            ) {
                this.imageExist = false
            }
        }

        if (dataField?.type?.toLowerCase() === appTypes.TYPE_OFF_SYSTEM) {
            this.imageExist = false
        }

        this.small = this.column?.additional?.small ?? false
        this.isHeaderHtml = this.column?.additional?.isHeaderHtml ?? false
    },
    methods: {
        getAvatarId () {
            const field = this.column.field
            const avatarField = this.column?.additional?.img

            if (!this.data[field]) {
                return 'background-image: url(/images/silhouette.png)'
            }

            if (this.column.additional?.avatarType === 'company') {
                // @ts-ignore
                return this.getBackgroundCompanyLogo(this.data[field][avatarField])
            }

            if (this.column.additional?.avatarType === 'avatar') {
                // @ts-ignore
                return this.getBackgroundAvatar(this.data[field][avatarField])
            }

            if (this.column.additional?.avatarType === 'svgIcon') {
                // @ts-ignore
                return this.getBackgroundSvgIcon(this.data[field][avatarField])
            }

            if (this.column.additional?.avatarType === 'thumb') {
                // @ts-ignore
                return this.getBackgroundThumb(this.data[field][avatarField])
            }

            if (this.column.additional?.avatarType === 'url') {
                // @ts-ignore
                return `background-image: url(${ this.data[field][avatarField] })`
            }
        },
        getHeader () {
            const field = this.column.field

            const headerField = this.column?.additional?.header

            if (this.column?.additional?.getHeader instanceof Function) {
                return this.$t(this.column?.additional?.getHeader(this.data))
            }

            if (!this.data[field]) {
                return '-'
            }

            let header = this.data[field][headerField]

            if (this.isArchived) {
                return this.$t(header) + ' ' + this.$t('contact is archived')
            }

            return header
        },
        getSubHeader () {
            const field = this.column.field
            const subHeaderField = this.column?.additional?.subheader

            if (this.column?.additional?.getSubHeader instanceof Function) {
                return this.column?.additional?.getSubHeader(this.data)
            }

            if (!this.data[field]) {
                return ''
            }

            // @ts-ignore
            return this.data[field][subHeaderField]
        },
    }
})
