enum TicketDetailsPanelTabEnum {
    GENERAL = 'general',
    CONSTRAINTS = 'constraints',
    CONNECTED_ITEMS = 'connected_items',
    COMMENTS = 'comments',
    ATTACHMENTS = 'attachments',
    HISTORY = 'history'
}

export default TicketDetailsPanelTabEnum
