<template>
    <Teleport
        to=".io-page-header-holder"
        :disabled="teleportDisabled"
    >
        <div
            v-progress="loading"
            class="io-design-system io-page-header"
            role="header"
            :class="{
                'io-with-tabs': $slots.tabs,
                'io-no-bottom-margin': noBottomMargin,
                [customClass]: customClass,
            }"
        >
            <div class="io-page-header__top-row">
                <div class="io-page-header__title">
                    <router-link
                        v-if="routeBack"
                        :to="routeBack"
                        class="io-page-header__back"
                        @click="$emit('clickBack')"
                    >
                        <span class="icon-chevron-left" />
                    </router-link>

                    <p>{{ title }}</p>

                    <div class="new-user-pilot-container" />
                </div>

                <div v-if="$slots.switches" class="io-page-header__switches">
                    <slot name="switches" />
                </div>

                <div v-if="$slots.actions" class="io-page-header__actions">
                    <slot name="actions" />
                </div>
            </div>

            <transition name="fade">
                <div v-if="breadcrumbs.length" class="io-page-header__breadcrumbs">
                    <Breadcrumbs />
                </div>
            </transition>

            <div class="io-page-header__bottom-row">
                <div v-if="$slots.tabs" class="io-page-header__tabs">
                    <slot name="tabs" />
                </div>

                <div v-if="$slots['extra-actions']" class="io-page-header__extra-actions">
                    <slot name="extra-actions" />
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts" src="./PageHeader.ts"></script>
<style lang="scss" src="./PageHeader.scss" scoped></style>
