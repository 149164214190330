<template>
    <div class="employeeBox io-f-row-invite io-member-row"
         :class="{ 'io-member-row-last': isLastRow, 'io-usr-added': item.isAdded }"
         v-on:click.stop="!item.isAdded ? $emit('attachPerson') : $emit('detachPerson')">
        <div v-if="!item.photo && item.isPending" class="io-pending-avatar"></div>
        <div v-else-if="item.photo && false === photoHasError" class="inviteUserAvatar">
            <img :src="getAvatar(item.photo)" loading="lazy" @error="photoHasError = true" />
        </div>
        <initials
            v-else-if="!item.photo || true === photoHasError"
            class="io-img-holder io-f-column"
            :fullName="item.fullname"
            :bgColor="personColor(item)"
            :bigAvatar="true"
        />

        <div class="io-name-column">
            <div class="io-name">
                <div v-tooltip.top="{content: fullNameAndCompanyName, visible: fullNameAndCompanyName.length > 1}" class="io-tooltip-container">
                    {{ limitedNameAndCompany}}
                </div>
                <span v-if="isArchivedEmployee" class="io-archived">Archived</span>
                <span v-if="item.app_type" class="io-name-apptype" :class="appTypeClean">{{ appType }}</span>
                <span v-if="item.typeList && item.typeList === 'employee' && item.isPending" :class="currentAppType">Pending</span>
            </div>

            <template v-if="!item.isPending">
                <div class="io-email">{{ item.email }}</div>
            </template>
        </div>

        <div v-if="item.loading" class="io-loader-small"></div>
        <div v-else>
            <a v-if="item.isAdded">
                <span v-if="!item.isPending" class="io-added-text">Added</span>
                <img src="/new-theme/img/added-icon.svg"/>
            </a>
            <a v-else>
                <img src="/new-theme/img/add-grey.svg"/>
            </a>
        </div>
    </div>
</template>

<script>
    import { clone, get, isUndefined, trim } from 'lodash'
    import initials from '@/components/new-theme/initials.vue'

    export default {
        name: 'boxPerson',
        components: {
            initials,
        },
        props: {
            item: {
                required: true,
                type: Object
            },
            isLastRow: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data () {
            return {
                fullNameAndCompanyName: '',
                photoHasError: false,
            }
        },

        computed: {
            appType () {
                if (this.item.app_type === this.appTypes.TYPE_SC) {return 'SUB'}
                else if (this.item.app_type === this.appTypes.TYPE_AC) {return 'DESIGN'}
                else if (this.item.app_type === this.appTypes.TYPE_GC) {return 'GC'}
                else if (this.item.app_type === this.appTypes.TYPE_OWNER) {return 'OWNER'}
                else if (this.item.app_type === this.appTypes.TYPE_DEV) {return 'OWNER REP'}
            },

            currentAppType () {
                return 'io-' + localStorage.getItem('appType')
            },

            appTypeClean () {
                let type = this.item.app_type
                if (isUndefined(type)) {
                    return ''
                }
                return 'io-' + type
            },

            limitedNameAndCompany () {
                let fullName = trim(get(this.item, 'fullname', null))
                let companyName = trim(get(this.item, 'company_name', null))
                this.fullNameAndCompanyName = ''

                if (!fullName) {
                    return 'No name'
                }

                if (companyName && !isEqual(fullName, companyName) && !fullName.includes(companyName)) {
                    let limited = false
                    let clonedFullName = clone(fullName)
                    let clonedCompanyName = clone(companyName)

                    if (clonedFullName.length > 25) {
                        clonedFullName = `${ clonedFullName.substr(0, 22) }...`
                        limited = true
                    }

                    if (clonedCompanyName.length > 25) {
                        clonedCompanyName = `${ clonedCompanyName.substr(0, 22) }...`
                        limited = true
                    }

                    if (limited) {
                        this.fullNameAndCompanyName = `${ fullName } - ${ companyName }`
                    }

                    return `${ clonedFullName } - ${ clonedCompanyName }`
                }

                if (fullName.length > 50) {
                    this.fullNameAndCompanyName = fullName
                    fullName = `${ fullName.substr(0, 47) }...`
                }

                return fullName
            },
            isArchivedEmployee () {
                if (!this.item.e_active && this.item.typeList === 'employee') {return true}
                return false
            }
        },

        methods: {
            getAvatar (photo) {
                return photo ? this.getApiUrlAvatar(photo) : '/images/silhouette.png'
            }
        }
    }
</script>

<style>
    .inviteUserAvatar {
        width: 40px !important;
        height: 40px !important;
        margin-right: 16px !important;
    }

    .inviteUserAvatar img {
        border-radius: 50% !important;
        width: 100% !important;
        height: 100% !important;
    }

    .io-tooltip-container {
        display: inline-block;
    }
</style>
