<template>
<div class="io-perCirc-outer">
    <RadialProgressBarVue 
        :diameter="diameter" 
        :completed-steps="completedSteps" 
        :total-steps="totalSteps" 
        :start-color="startColor"
        :stop-color="stopColor"
        :inner-stroke-color="innerStrokeColorMutable"
        :stroke-width="strokeWidth"
        :animate-speed="animateSpeed"
        :fps="fps"
        :timing-func="timingFunc"
    >
        <div class="io-perCirc-inner" v-if="budgetUsed">
            <strong class="io-perCirc-inner-value">{{ $filters.formatPercentIfNotString(completedSteps) }}</strong>
            <template v-if="innerText">
                <br>
                <small class="io-perCirc-inner-label">budget used</small>
            </template>
        </div>
    </RadialProgressBarVue>
</div>
</template>

<script>
import RadialProgressBarVue from 'vue3-radial-progress'
export default {
    name: 'RadialProgressBar',
    components: {
        RadialProgressBarVue
    },
    props: {
        diameter: {
            type: Number,
            required: false,
            default: 165
        },
        totalSteps: {
            type: Number,
            required: false,
            default: 100
        },
        completedSteps: {
            type: Number,
            required: false,
            default: 0
        },
        startColor: {
            type: String,
            required: false,
            default: '#8d43ef'
        },
        stopColor: {
            type: String,
            required: false,
            default: '#8d43ef'
        },
        innerStrokeColor: {
            type: String,
            required: false,
            default: '#e7eaf3'
        },
        strokeWidth: {
            type: Number,
            required: false,
            default: 15
        },
        animateSpeed: {
            type: Number,
            required: false,
            default: 1000
        },
        fps: {
            type: Number,
            required: false,
            default: 60
        },
        timingFunc: {
            type: String,
            required: false,
            default: 'linear'
        },
        budgetUsed: {
            type: Boolean,
            required: false,
            default: true
        },
        innerText: {
            type: String,
            required: false,
            default: 'budget used'
        },
        overBudgetColor: {
            type: String,
            required: false,
            default: '#f26666'
        }
    },
    data () {
        return {
            completedSteps: 0
        }
    },
    computed: {
        innerStrokeColorMutable () {
            // assign initial value
            let value = this.innerStrokeColor
            // change color of background stroke to red when percentage value is over 100%
            if (typeof this.completedSteps === 'number') {
                if (this.completedSteps > 100) {
                    value = this.overBudgetColor
                }
            }
            return value
        }
    }
}
</script>

<style type="less">
  .io-perCirc-inner-value, .io-perCirc-inner-label {
    float: left;
    width: 100%;
    text-align: center;
  }
</style>
