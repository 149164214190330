import i18n from '@/base/i18n'
import ApprovalType from '@/interfaces/modules/projects/financial-approvals/ApprovalType'
import Resource from '../enums/Resource'

export default class ApprovalTextService {
    static getApprovedAtText (type: ApprovalType): string {
        return {
            [ApprovalType.APPROVED]: i18n.global.tc('Approved'),
            [ApprovalType.APPROVED_AS_NOTED]: i18n.global.tc('Approved as Noted'),
            [ApprovalType.REVIEWED]: i18n.global.tc('Reviewed'),
            [ApprovalType.REVIEWED_AS_NOTED]: i18n.global.tc('Reviewed as Noted'),
        }[type]
    }

    static getApproveTitleText (type: ApprovalType): string {
        return {
            [ApprovalType.APPROVED]: i18n.global.tc('Approve'),
            [ApprovalType.APPROVED_AS_NOTED]: i18n.global.tc('Approve'),
            [ApprovalType.REVIEWED]: i18n.global.tc('Review'),
            [ApprovalType.REVIEWED_AS_NOTED]: i18n.global.tc('Review'),
        }[type]
    }

    static getResourceName (resource: Resource): string {
        return {
            [Resource.AMENDMENT]: i18n.global.tc('Amendment'),
            [Resource.CHANGE_ORDER]: i18n.global.tc('Change order'),
            [Resource.INVOICE]: i18n.global.tc('Invoice'),
            [Resource.INITIAL_CONTRACT]: i18n.global.tc('Initial contract'),
            [Resource.ORIGINAL_CONTRACT]: i18n.global.tc('Original contract'),
            [Resource.CONTRACT]: i18n.global.tc('Contract'),
            [Resource.BUDGET_APPROVAL]: i18n.global.tc('Budget'),
            [Resource.OwnerBudgetPhase]: i18n.global.tc('Budget'),
            [Resource.MyBudgetPhase]: i18n.global.tc('Budget'),
            [Resource.BUDGET_CHANGES]: i18n.global.tc('Budget changes'),
            [Resource.OwnerBudgetChange]: i18n.global.tc('Budget Change'),
            [Resource.MyBudgetChange]: i18n.global.tc('Budget Change'),
            [Resource.APPLICATION_PACKAGE]: i18n.global.tc('Application Package'),
        }[resource]
    }
}
