<template>
    <IOModal
        :width="451"
        color="blue"
        icon="icon-edit"
        :title="$t('Edit Invoice Amount Including Tax')"
    >
        <template #default>
            <div class="io-form__item">
                <IOLabel :text="$t('Invoice Amount Including Tax')" :is-required="true" />
                <field-numeric-int :options="{numeralPositiveOnly: true, numericOnly: true}"
                                   sign="$"
                                   v-model="amountWithTax"
                />
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="close">{{ $t('Cancel') }}</IOButton>
            <IOButton :disabled="!amountWithTax || '-' === amountWithTax" @click="save">
                {{ $t('Save') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./InvoiceAmountWithTaxModal.ts"></script>
<style lang="scss" src="./InvoiceAmountWithTaxModal.scss" scoped></style>
