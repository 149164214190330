import InvoiceRouteName from './enums/InvoiceRouteName'
import ProjectInvoicesTemplate from './layouts/project-invoices-template/ProjectInvoicesTemplate.vue'
import MyInvoicesList from './views/invoices-lists/my-invoices-list/MyInvoicesList.vue'
import ReceivedInvoicesList from './views/invoices-lists/received-invoices-list/ReceivedInvoicesList.vue'
import InvoicesVendorList from '@/io-modules/invoices/views/invoices-lists/received-invoices-list/invoices-vendors-list/InvoicesVendorsList.vue'
import InvoicesDefaultList from './views/invoices-lists/received-invoices-list/invoices-default-list/InvoicesDefaultList.vue'
import VendorDetails from './views/invoices-lists/vendor-details-view/VendorDetailsView.vue'
import ApplicationPackagesList from './views/application-packages-list/ApplicationPackagesList.vue'
import SharedInvoicesList from './views/invoices-lists/shared-invoices-list/SharedInvoicesList.vue'
import InvoicesTab from '@/io-modules/invoices/enums/InvoicesTab'
import ListingName from './enums/ListingName'
import InvoiceViewTemplate from './layouts/invoice-view-template/InvoiceViewTemplate.vue'
import InvoiceDetails from './views/invoice-views/invoice-details/InvoiceDetails.vue'
import InvoiceSoV from './views/invoice-views/invoice-sov-wbs/ScheduleOfValues.vue'
import AttachVendorInvoice from './views/invoice-views/attach-vendor-invoices/AttachVendorInvoices.vue'
import Funding from './views/invoice-views/funding/Funding.vue'
import Approvals from './views/invoice-views/approvals/Approvals.vue'
import InvoiceTimeline from './views/invoice-views/invoice-timeline/InvoiceTimeline.vue'
import NonContractedLineItems from '@/io-modules/invoices/views/invoice-views/non-contacted-line-items/NonContractedLineItems.vue'
import SupportingDocuments from './views/invoice-views/supporting-documents/SupportingDocuments.vue'
import AppPackageRouteName from './enums/AppPackageRouteName'
import AppPackageViewTemplate from './layouts/app-package-view-template/AppPackageViewTemplate.vue'
import AppPackageDetailsView from './views/application-packages-view/app-package-details-view/AppPackageDetailsView.vue'
import AppPackageAttachInvoices from '@/io-modules/invoices/components/app-package-view/create-edit/attach-invoices/AppPackageAttachInvoices.vue'
import AppPackageTimeline from './views/application-packages-view/app-package-timeline/AppPackageTimeline.vue'
import AppPackageApprovals from './views/application-packages-view/app-package-approvals/AppPackageApprovals.vue'

const payAppListBreadcrumbs = [
    {
        text: 'Projects',
        to: { name: 'project-list' }
    },
]

const defaultMeta = {
    name: 'Invoicing & Pay Applications',
    newInvoices: true,
    navigationKey: 'invoices',
    customClass: 'io-invoices-listing',
}

const invoicesListMeta = {
    ...defaultMeta,
    list: true,
    breadcrumbs: payAppListBreadcrumbs,
}

const appPackagesListMeta = {
    ...invoicesListMeta,
    appPackagesList: true,
}

export default [
    {
        path: '/project/:pid/invoices',
        name: InvoiceRouteName.InvoicesMainView,
        component: ProjectInvoicesTemplate,
        meta: defaultMeta,
        redirect: { name: InvoiceRouteName.InvoicesReceivedDefaultList },
        children: [
            {
                path: '/project/:pid/invoices/my',
                component: MyInvoicesList,
                name: InvoiceRouteName.InvoicesMyList,
                meta: { ...invoicesListMeta, listType: InvoicesTab.My, tab: ListingName.My, breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoices/received',
                component: ReceivedInvoicesList,
                name: InvoiceRouteName.InvoicesReceivedList,
                meta: invoicesListMeta,
                redirect: { name: InvoiceRouteName.InvoicesReceivedDefaultList },
                children: [
                    {
                        path: '/project/:pid/invoices/received/default',
                        component: InvoicesDefaultList,
                        name: InvoiceRouteName.InvoicesReceivedDefaultList,
                        meta: { ...invoicesListMeta, listType: InvoicesTab.Received, tab: ListingName.Received },
                    },
                    {
                        path: '/project/:pid/invoices/received/vendors',
                        component: InvoicesVendorList,
                        name: InvoiceRouteName.InvoicesReceivedVendorsList,
                        meta: { ...invoicesListMeta, listType: InvoicesTab.Received, tab: ListingName.Received },
                    },
                ]
            },
            {
                path: '/project/:pid/invoices/received/vendors/:id',
                component: VendorDetails,
                name: InvoiceRouteName.InvoicesReceivedVendorDetails,
                meta: {
                    ...defaultMeta,
                    back: InvoiceRouteName.InvoicesReceivedVendorsList,
                    listType: InvoicesTab.Received,
                    breadcrumbs: payAppListBreadcrumbs,
                }
            },
            {
                path: '/project/:pid/invoices/shared',
                component: SharedInvoicesList,
                name: InvoiceRouteName.InvoicesSharedList,
                meta: { ...invoicesListMeta, listType: InvoicesTab.Shared, tab: ListingName.Shared },
            },
            {
                path: '/project/:pid/invoices/app-packages',
                component: ApplicationPackagesList,
                name: InvoiceRouteName.InvoicesApplicationPackagesList,
                meta: { ...appPackagesListMeta, listType: InvoicesTab.AppPackages, tab: ListingName.AppPackages },
            },
        ]
    },
    {
        path: '/project/:pid/invoice/:id',
        name: InvoiceRouteName.InvoiceView,
        component: InvoiceViewTemplate,
        meta: defaultMeta,
        redirect: { name: InvoiceRouteName.InvoiceDetails },
        children: [
            {
                path: '/project/:pid/invoice/:id/details',
                name: InvoiceRouteName.InvoiceDetails,
                component: InvoiceDetails,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoice/:id/sov',
                name: InvoiceRouteName.InvoiceSoV,
                component: InvoiceSoV,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoice/:id/line-items',
                name: InvoiceRouteName.InvoiceNonContractedLineItems,
                component: NonContractedLineItems,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoice/:id/vendor-invoices',
                name: InvoiceRouteName.InvoiceAttachVendorInvoice,
                component: AttachVendorInvoice,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoice/:id/lien-waivers',
                name: InvoiceRouteName.InvoiceLienWaivers,
                component: SupportingDocuments,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoice/:id/documents',
                name: InvoiceRouteName.InvoiceDocuments,
                component: SupportingDocuments,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoice/:id/funding',
                name: InvoiceRouteName.InvoiceFunding,
                component: Funding,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoice/:id/approvals',
                name: InvoiceRouteName.InvoiceApprovals,
                component: Approvals,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/invoice/:id/timeline',
                name: InvoiceRouteName.InvoiceTimeline,
                component: InvoiceTimeline,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
        ]
    },
    {
        path: '/project/:pid/app-package/:id',
        name: AppPackageRouteName.AppPackageView,
        component: AppPackageViewTemplate,
        meta: defaultMeta,
        redirect: { name: AppPackageRouteName.AppPackageDetails },
        children: [
            {
                path: '/project/:pid/app-package/:id/details',
                name: AppPackageRouteName.AppPackageDetails,
                component: AppPackageDetailsView,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/app-package/:id/attach-invoices',
                name: AppPackageRouteName.AppPackageAttachInvoices,
                component: AppPackageAttachInvoices,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/app-package/:id/approvals',
                name: AppPackageRouteName.AppPackageApprovals,
                component: AppPackageApprovals,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
            {
                path: '/project/:pid/app-package/:id/timeline',
                name: AppPackageRouteName.AppPackageTimeline,
                component: AppPackageTimeline,
                meta: { breadcrumbs: payAppListBreadcrumbs },
            },
        ]
    }
]
