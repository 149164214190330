import { computed } from 'vue'

import i18n from '@/base/i18n'

import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'
import ContractStatus from '@/io-modules/project-contracts/enums/ContractStatus.ts'

export type ContractStatusInfo = { [key in ContractStatus]: { text: string, variant: PillVariant } }

const { t } = i18n.global

export default () => computed((): ContractStatusInfo => ({
    [ContractStatus.DRAFT]: {
        text: t('Draft'),
        variant: PillVariant.BLUE_LIGHT,
    },
    [ContractStatus.PENDING_INTERNAL_APPROVAL]: {
        text: t('Pending Internal Approval'),
        variant: PillVariant.YELLOW_LIGHT,
    },
    [ContractStatus.PENDING_REP_APPROVAL]: {
        text: t('Pending Rep Approval'),
        variant: PillVariant.YELLOW_LIGHT,
    },
    [ContractStatus.INTERNALLY_REJECTED]: {
        text: t('Internally Rejected'),
        variant: PillVariant.RED_LIGHT,
    },
    [ContractStatus.INTERNALLY_APPROVED]: {
        text: t('Internally Approved'),
        variant: PillVariant.GREEN_LIGHT,
    },
    [ContractStatus.SUBMITTED_TO_CLIENT]: {
        text: t('Submitted to Client'),
        variant: PillVariant.BLUE_LIGHT,
    },
    [ContractStatus.REJECTED_BY_CLIENT]: {
        text: t('Rejected by Client'),
        variant: PillVariant.RED_LIGHT,
    },
    [ContractStatus.REJECTED]: {
        text: t('Rejected'),
        variant: PillVariant.RED_LIGHT,
    },
    [ContractStatus.APPROVED]: {
        text: t('Approved'),
        variant: PillVariant.GREEN_LIGHT,
    },
    [ContractStatus.APPROVED_BY_CLIENT]: {
        text: t('Approved by Client'),
        variant: PillVariant.GREEN_LIGHT,
    },
    [ContractStatus.INITIAL_CONTRACT_EXECUTION]: {
        text: t('Initial contract Execution'),
        variant: PillVariant.GREEN_LIGHT,
    },
    [ContractStatus.ORIGINAL_CONTRACT_EXECUTION]: {
        text: t('Original contract Execution'),
        variant: PillVariant.GREEN_LIGHT,
    },
    [ContractStatus.EXECUTED]: {
        text: t('Executed'),
        variant: PillVariant.GREEN_LIGHT,
    },
    [ContractStatus.FINAL_AMENDMENT_APPROVED]: {
        text: t('Final Amendment Approved'),
        variant: PillVariant.YELLOW_LIGHT,
    },
}))
