import VendorDetails from '@/io-modules/invoices/interfaces/VendorDetails'
import ProgressBar from '@/components/atoms/progress-bar/ProgressBar.vue'
import { ProgressBarItemInterface } from '@/components/atoms/progress-bar/ProgressBarItemInterface'
import { defineComponent } from 'vue'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { mapState } from 'pinia'

export default defineComponent({
    name: 'VendorDetails',
    components: { 
        ProgressBar,
    },
    computed: {
        ...mapState(invoicesStore, ['vendorsDetails']),

        vendorId (): string {
            return this.$route.params.id
        },

        vendorDetails (): VendorDetails {
            return this.vendorsDetails[this.vendorId] || {}
        },
        
        progressBarItems (): ProgressBarItemInterface[] {
            return [
                {
                    value: this.vendorDetails.billed_percentage,
                    color: 'var(--accent)'
                },
                {
                    value: 100 - this.vendorDetails.billed_percentage,
                    color: 'var(--closed-bg)'
                },
            ]
        },
    },
    methods: {
        goToContract (): void {
            if (this.vendorDetails.contract_id) {
                const routeData = this.$router.resolve({ name: 'contract-details', params: { id: this.vendorDetails.contract_id } })
                window.open(routeData.href, '_blank')
            }
        },
    }
})