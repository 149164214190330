import { defineComponent } from 'vue'
import InvoicesListHeader from '@/io-modules/invoices/components/invoices-list/invoices-list-header/InvoicesListHeader'
import InvoicesListTable from '@/io-modules/invoices/components/invoices-list/invoices-list-table/InvoicesListTable'
import InvoicesTab from '@/io-modules/invoices/enums/InvoicesTab'
import ListsMixin from '@/io-modules/invoices/mixins/ListsMixin'
import { mapActions } from 'pinia'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import ListingName from '@/io-modules/invoices/enums/ListingName.ts'
export default defineComponent({
    name: 'SharedInvoicesList',
    components: {
        InvoicesListHeader,
        InvoicesListTable
    },
    mixins: [ListsMixin],
    data () {
        return {
            InvoicesTab,
            loadingData: false,
            totals: null
        }
    },
    async mounted () {
        try {
            this.setContentLoaderValue(true)
            await this.fetchSharedInvoices(this.projectObj.project_local_id)
            this.totals = await this.fetchInvoiceTotals(ListingName.Shared)
        } catch (error) {
            console.log(error)
            this.showNotification('error', this.$t('Error occurred during loading data.'))
        } finally {
            this.setContentLoaderValue(false)
        }
    },
    methods: {
        ...mapActions(invoicesStore, ['fetchSharedInvoices']),

        async loadData (): Promise<void> {
            this.loadingData = true
            await this.fetchSharedInvoices(this.projectObj.project_local_id)
            this.loadingData = false
        },
    },
})
