export const setDivisions = function ({ commit, dispatch }, data) {
    commit('SET_DIVISIONS', data)
}

export const setCollapsedDivisions = function ({ commit, dispatch }, data) {
    commit('SET_COLLAPSED_DIVISIONS', data)
}

export const setProcessingSets = function ({ commit, dispatch }, data) {
    commit('SET_PROCESSING_SETS', data)
}

export const setPublishedSets = function ({ commit, dispatch }, data) {
    commit('SET_PUBLISHED_SETS', data)
}

export const setFilters = function ({ commit, dispatch }, data) {
    commit('SET_FILTERS', data)
}

export const setShowFilters = function ({ commit, dispatch }, value) {
    commit('SET_SHOW_FILTERS', value)
}

export const setSingleFilter = function ({ commit, dispatch }, data) {
    commit('SET_SINGLE_FILTER', data)
}

export const setFiltersSelected = function ({ commit, dispatch }, data) {
    commit('SET_FILTERS_SELECTED', data)
}

export const resetFiltersSelected = function ({ commit, dispatch }) {
    commit('RESET_FILTERS_SELECTED')
}

export const setCheckedSections = function ({ commit, dispatch }, value) {
    commit('SET_CHECKED_SECTIONS', value)
}

export const setIsDisabledBtn = function ({ commit, dispatch }, value) {
    commit('SET_IS_DISABLED_BTN', value)
}

export const setActiveSection = function ({ commit, dispatch }, data) {
    commit('SET_ACTIVE_SECTION', data)
}
