<template>
    <modal v-if="showModal === 'success'">
        <template #body>
            <div class="io-content io-modal-success">
                <img src="/new-theme/img/success-icon-big.svg">
                <div class="io-msg io-2">{{action}} has been saved</div>
            </div>
        </template>
    </modal>
</template>

<script>
    import modalMixinn from '@/mixins/modalMixin'

    export default {
        name: 'success',
        data () {
            return {
            }
        },
        mixins: [modalMixinn],
        components: {
        },
        watch: {
            'showModal' () {
                if (this.showModal === 'success') {
                    setTimeout(() => {
                        this.closeModal()
                    }, 2000)
                }
            }
        },
        computed: {
            action () {
                return (this.$store.getters['modal/getReturnData'].msg) ? this.$store.getters['modal/getReturnData'].msg : 'Your Form'
            }
        },
        mounted: function () {
        },
        created () {
        },
        methods: {
        }
    }
</script>