import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'

export default defineComponent({
    name: 'ListHeader',
    props: {
        title: { type: String, default: '' },
        link: { type: String, default: '' },
        valueLabel: { type: String, default: '' },
        value: { type: Number, default: 0 },
        valueFilter: { type: String, default: 'formatProjectCurrencyInt' },
        params: {
            type: Object,
            default: () => ({})
        },
        showLeftNavigation: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            SoVTableViewMode,
        }
    },
    computed: {
        ...mapState(invoiceSoVStore, ['columnsViewMode']),
    },
    methods: {
        goToLink (): void {
            if (this.link) {
                const routeData = this.$router.resolve({ name: this.link, params: { ...this.params } })
                window.open(routeData.href, '_blank')
            }
        },
    }
})