import { computed, Ref, ref, watch } from 'vue'

import i18n from '@/base/i18n'
import { isBoolean } from 'lodash'
import { jobsiteLocationsStore } from '@/components/jobsite-locations/store/jobsiteLocationsStore.ts'
const { t } = i18n.global

type CollapseButtonType = {
    isCollapsed: Ref<boolean>,
    collapseButtonText: Ref<string>
    collapseButtonTextIcon: Ref<string>
    collapseButtonIcon: Ref<string>
    onClickCollapseButton: (all: boolean, value?: boolean) => void
}

export default function useCollapseButton (): CollapseButtonType {
    const locationsStore = jobsiteLocationsStore()
    const isCollapsed = ref(false)
    const collapseButtonText = computed((): string => isCollapsed.value ? t('Expand All'): t('Collapse All'))
    const collapseButtonTextIcon = computed((): string => isCollapsed.value ? 'icon-chevrons-down' : 'icon-chevrons-up')
    const collapseButtonIcon = computed((): string => isCollapsed.value ? 'icon-chevron-down' : 'icon-chevron-up')

    function onClickCollapseButton (all: boolean, value?: boolean): void {
        if (isBoolean(value)) {
            isCollapsed.value = value

            return
        }

        isCollapsed.value = !isCollapsed.value

        if (all) {
            locationsStore.isAllLocationsCollapsed = isCollapsed.value
        }
    }

    return {
        isCollapsed,
        collapseButtonText,
        collapseButtonTextIcon,
        collapseButtonIcon,
        onClickCollapseButton,
    }
}
