import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'DropdownButtonNew',
    props: {
        name: { type: [String, Object as () => TranslateResult], default: 'Actions' },
        menuAlign: { type: String, default: 'right' },
        buttonClass: { type: String, default: 'io-btn-primary' },
        showMask: { type: Boolean, default: false },
        separated: { type: Boolean, default: false },
        isInTopBar: { type: Boolean, default: false },
        closeOnClick: { type: Boolean, default: true },
        iconMode: { type: Boolean, default: false },
        fontAwesomeClass: { type: String, default: 'fas fa-ellipsis-h' },
        disabled: { type: Boolean, default: false },
        attachAbove: { type: Boolean, default: false },
        fontAwesomeByLabelClass: { type: String, default: '' },
        anronIconClass: { type: String, default: '' },
        tooltipText: { type: String, default: '' },
        inHeader: { type: Boolean, default: false },
        lazyLoadingMenu: { type: Boolean, default: false },
    },
    emits: ['loadOptions'],
    data () {
        return {
            showMenu: false as boolean,
            forceAttachAbove: false as boolean,
            prevScrollTop: 0,
            timeoutId: null,
        }
    },
    computed: {
        menuClass (): string[] {
            const classes = []
            switch (this.menuAlign) {
            case 'left':
                classes.push('io-left')
                break
            case 'right':
                classes.push('io-right')
                break
            default:
                classes.push('io-center')
                break
            }
            if (!this.showMask) {
                classes.push('io-shadow')
            }
            if (this.separated) {
                classes.push('io-separated')
            }
            if (this.attachAbove || this.forceAttachAbove) {
                classes.push('io-above')
            }
            return classes
        },
        topPosition (): string {
            return this.iconMode
                ? 'top: 25px'
                : 'top: 40px'
        },
        maskClass (): string {
            return this.showMask ? 'io-show-mask' : ''
        },
        showTooltip (): boolean {
            return !this.showMenu && !!this.tooltipText?.length
        },
    },
    watch: {
        showMenu (newVal) {
            if (newVal) {
                const documentHeight = document.documentElement.clientHeight
                this.forceAttachAbove = false
                this.$nextTick(() => {
                    const dropdownElement = this.$el.querySelector('.io-button-dropdown__menu')
                    const dropdownElementTop = dropdownElement?.getBoundingClientRect().top || 0
                    const dropdownHeight = dropdownElement?.clientHeight || 0

                    if ((dropdownElementTop + dropdownHeight) > documentHeight) {
                        this.forceAttachAbove = true
                    }

                    if (this.lazyLoadingMenu && this.$refs.dropdownMenu) {
                        this.$refs.dropdownMenu.addEventListener('scroll', this.handleScroll)
                    }
                })
                
            }
        }
    },
    methods: {
        ...mapActions('main', ['setTopBarInFront']),
        handleScroll (event: Event): void {
            clearTimeout(this.timeoutId)
            this.timeoutId = setTimeout(() => {
                if (
                    event.target.scrollTop > 0 
                    && Math.ceil(event.target.scrollTop) !== Math.ceil(this.prevScrollTop)
                    && event.target.offsetHeight + event.target.scrollTop + 4 >= event.target.scrollHeight
                ) {
                    this.$emit('loadOptions')
                }
                this.prevScrollTop = Math.ceil(event.target.scrollTop)
            }, 300)
        },
        toggleMenu (): void {
            if (this.disabled) {
                return
            }
            if (!this.isInTopBar) {
                this.showMenu = !this.showMenu
            } else {
                if (!this.showMenu) {
                    this.setTopBarInFront(true)
                    setTimeout(() => { this.showMenu = !this.showMenu }, 40)
                } else {
                    this.showMenu = !this.showMenu
                    setTimeout(() => { this.setTopBarInFront(false) }, 300)
                }
            }
        },
        handleMenuClick (): void {
            if (this.closeOnClick) {
                this.toggleMenu()
            }
        }
    }
})
