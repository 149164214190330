<template>
    <ModalFilters
        v-show="showFilters"
        :countFilters="countFilters"
        :showClearFiltersButton="true"
        @apply="applyFilters"
        @clearFilters="clearAllFilters"
        @closeModal="closeModal"
    >
        <FilterCheckboxes
            key="pin_type"
            class="io-filters-inputs__single-input"
            :title="$t('Pin Type')"
            field="pin_type"
            :options="pinTypes"
            :maxLength="10"
            :translate="true"
            :dynamicClass="true"
            v-model="modelValue.types"
        />

        <FilterCheckboxes
            key="pin_type"
            class="io-filters-inputs__single-input"
            :title="$t('Status')"
            field="pin_status"
            :options="model.enum.status.values"
            :maxLength="10"
            :translate="true"
            :dynamicClass="true"
            v-model="modelValue.statuses"
        />

        <FilterCheckboxes
            key="pin_type"
            class="io-filters-inputs__single-input"
            :title="$t('drawing.annotations.visibility.title')"
            field="pin_visibility"
            :options="model.enum.visibility"
            :maxLength="10"
            :translate="true"
            :dynamicClass="true"
            v-model="modelValue.visibility"
        />

        <FilterDateTimePicker
            key="due_date"
            :options="{ formatted: 'MM/DD/YYYY', range: true, placeholder: 'MM/DD/YYYY' }"
            :title="$t('Creation Date')"
            placeholder=""
            v-model="modelValue.created_at"
        />

        <MultiselectFilter
            :key="1"
            :options="mentionUsers"
            :settings="{'multiple': true, 'close-on-select': false}"
            :title="$t('Created By')"
            v-model="modelValue.created_by"
        />

        <MultiselectFilter
            :key="2"
            :options="mentionUsers"
            :settings="{'multiple': true, 'close-on-select': false}"
            :title="$t('Assigned To')"
            v-model="modelValue.assigned_to"
        />
    </ModalFilters>
</template>

<script lang="ts" src="./Filters.ts"></script>
