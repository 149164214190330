<template>
    <LoadingScreen :custom-show-loading="loading"/>
    <div
        v-if="!loading"
        id="io-invoice-sov-wbs"
        class="io-invoice-sov-wbs"
        :class="{
            'io-fullscreen-mode': isFullScreenMode,
        }"
        :style="cssVars"
    >
        <div class="io-quoted-items-layout">
            <SitesNavigation
                v-if="sitesFFEnabled && hasSites"
                completed-icon="icon-dollar-circle-2"
                :sites="wbsSites"
                :show-header="false"
                @scrollToItem="showSiteWBS"
            />

            <LeftNavigation
                v-else
                :phases="isCOsInSeparateCategory ? [...originalScopeQIs, ...groupedExecutedCCs] : originalScopeQIs"
                :showLeftNavigation="showLeftNavigation"
                :isCOsInSeparateCategory="isCOsInSeparateCategory"
                :firstCOIndex="originalScopeQIs.length"
                :hasChangeOrders="Boolean(executedCOsQIs.length)"
                @scrollToPhase="scrollToPhase"
            />

            <div class="io-right-content" :class="{ 'io-right-content--sites': sitesFFEnabled && hasSites }">
                <div class="io-top-actions-wrapper">
                    <TopActions
                        :key="activeSiteId"
                        :hasVisibleLines="hasVisibleLines"
                        :toggleLeftNavAvailable="!sitesFFEnabled || !hasSites"
                        v-model:showLeftNavigation="showLeftNavigation"
                        v-model:scrollLeft="scrollLeft"
                        @toggleFullScreen="toggleFullScreen"
                    />
                </div>

                <div v-if="sitesFFEnabled && hasSites" class="io-sov-wbs-list__header">
                    <span>{{ getActiveSiteTitle }}</span>
                </div>
                <div v-if="hasVisibleLines" class="io-sov-wbs-list" :class="{ 'io-sov-wbs-list--sites': sitesFFEnabled && hasSites }">
                    <QuotedItemsList
                        v-if="originalScopeQIs.length"
                        :id="''"
                        :phases="sitesFFEnabled && hasSites ? siteOriginalScopeQIs : originalScopeQIs"
                        :netCurrentApplicationValue="sitesFFEnabled && hasSites ? siteNetInvoiceValueOriginalScope : netInvoiceValue"
                        :title="isCOsInSeparateCategory ? $t('Original Scope') : $t('Net Current Application')"
                        :hasExecutedCOs="!!executedCOsQIs.length"
                        :showLeftNavigation="showLeftNavigation"
                        :table-type="SoVTableType.OriginalScope"
                        v-model:scrollLeft="scrollLeft"
                        v-model:modalAdjust="modalAdjust"
                        v-model:adjustItem="adjustItem"
                    />
                    <template v-if="isCOsInSeparateCategory && executedCOsQIs.length">
                        <div class="io-list-title">
                            {{ amendmentsContractChangeType ? $t('Executed Amendments') : $t('Executed Change Orders') }}
                        </div>
                        <template v-for="co in groupedExecutedCCs" :key="co.change_order_id">
                            <QuotedItemsList
                                :id="co.change_order_id"
                                :link="ContractChangeItemRoutesNames.ITEM_SUMMARY"
                                :params = "contractChangeLinkParams(co)"
                                :title="co.name"
                                :phases="co.quoted_items"
                                :netCurrentApplicationValue="sitesFFEnabled && hasSites ? siteNetInvoiceValueCo(co.quoted_items) : netInvoiceValue"
                                :hasExecutedCOs="!!executedCOsQIs.length"
                                :showLeftNavigation="showLeftNavigation"
                                :table-type="SoVTableType.COntractChange"
                                v-model:scrollLeft="scrollLeft"
                                v-model:modalAdjust="modalAdjust"
                                v-model:adjustItem="adjustItem"
                            />
                        </template>
                        <QuotedItemsList
                            :id="''"
                            :showHeader="false"
                            :phases="[...originalScopeQIs, ...executedCOsQIs]"
                            :onlyFooter="true"
                            :showLeftNavigation="showLeftNavigation"
                            v-model:scrollLeft="scrollLeft"
                        />
                    </template>
                </div>

                <Placeholder
                    v-else-if="visibleLinesFilter && SoVTableViewMode.AllLines!==visibleLinesFilter"
                    icon="icon-cash"
                    :title="placeholderTitle"
                />

                <RevisionModal
                    v-if="modalAdjust"
                    :item="adjustItem"
                    @adjustedItem="applyAdjustedItem"
                    @close="modalAdjustToggle"
                />
            </div>
        </div>
    </div>
    <div v-if="!loading && hasSites" class="io-sov-line-items-total">
        <span>{{ $t('All Sites Net Current Application') }}</span>
        <span>{{ $filters.formatProjectCurrencyInt(netInvoiceValue) }}</span>
    </div>
</template>

<script lang="ts" src="./ScheduleOfValues.ts"></script>
<style lang="scss" src="./ScheduleOfValues.scss" scoped></style>
