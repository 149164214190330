import { defineComponent } from 'vue'
import { RouteLocationNamedRaw } from 'vue-router'

import { SimpleLazyListHeader } from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'
import FilterInterface from '@/components/ModalFilters/interfaces/FilterInterface.ts'
import { ApiClientV3 } from '@/api/api.js'
import qs from '@/helpers/qs.js'
import modalMixin from '@/mixins/modalMixin.ts'

import Table from '@/components/table/Table.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'
import SimpleLazyListRow
    from '@/io-modules/contracts/components/simple-lazy-list/parts/simple-lazy-list-row/SimpleLazyListRow.vue'
import ModalFilters from '@/components/ModalFilters/ModalFilters.vue'
import Filters from '@/io-modules/contracts/components/simple-lazy-list/parts/filters/Filters.vue'

export default defineComponent({
    name: 'SimpleLazyList',
    components: {
        Filters,
        ModalFilters,
        Table,
        IOPlaceholder,
        SimpleLazyListRow,
    },
    inject: ['searchTerm'],
    props: {
        columns: {
            type: Array as () => Array<SimpleLazyListHeader<unknown>>,
            required: true,
        },
        endpoint: {
            type: String,
            required: true,
        },
        filtersEndpoint: {
            type: String,
            required: false,
        },
        filtersSchema: {
            type: Object as () => FilterInterface[],
            required: true,
        },
        itemRoute: {
            type: Object as () => RouteLocationNamedRaw,
            required: true,
        },
        filterPillsColorFunction: {
            type: Function,
            required: true,
        },
        additionalParams: {
            type: Object,
            required: false,
        },
        mapFiltersCallback: {
            type: Function as () => (filters: Record<string, unknown>) => Record<string, unknown>,
            required: false,
        },
        isFrontendSortingDisabled: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [modalMixin],
    data () {
        return {
            items: [] as unknown[],
            isLoading: false,
            isFiltersLoading: false,
            perPage: 20,
            lastLoadedItemsCount: 20,
            sort: {
                by: 'id',
                direction: 'asc',
            },
            selectedFilters: {} as Record<string, unknown[]>,
            filters: {} as Record<string, unknown>,
            intersectionObserver: null as IntersectionObserver,
        }
    },
    computed: {
        requestParams (): Record<string, unknown> {
            return {
                per_page: this.perPage,
                page: Math.ceil(this.items.length / this.perPage) + 1,
                sort_by: this.sort.by,
                sort_direction: this.sort.direction,
                filters: this.mapFiltersCallback
                    ? this.mapFiltersCallback(this.selectedFilters)
                    : this.selectedFilters,
                search: this.searchTerm,
                ...this.additionalParams,
            }
        },
        isLastPage (): boolean {
            return this.lastLoadedItemsCount < this.perPage
        },
        isShowFetchingItems (): boolean {
            return !this.isLastPage || this.isLoading
        },
        isFiltersModalShown (): boolean {
            return this.showModal === 'modal-filters'
        },
        countFilters (): number {
            // very simplified counting of selected filters, needs to be improved for different types of filters
            return Object.values(this.selectedFilters)
                .reduce((acc, filter) => acc + (filter as string[]).length, 0)
        },
    },
    watch: {
        sort: {
            handler (): void {
                this.resetAndRefetchItems()
            },
            deep: true,
        },
        searchTerm (): void {
            this.resetAndRefetchItems()
        },
    },
    async mounted () {
        await Promise.all([
            this.fetchItems(),
            this.fetchFilters(),
        ])

        this.createObserver()
    },
    beforeUnmount () {
        this.destroyObserver()
    },
    methods: {
        createObserver (): void {
            this.intersectionObserver = new IntersectionObserver(
                this.handleIntersect,
                {
                    root: this.$refs.scrollable,
                    rootMargin: '0px',
                    threshold: 0.2,
                },
            )

            const sentinel = this.$refs.sentinel
            if (sentinel) {
                this.intersectionObserver.observe(sentinel)
            }
        },
        destroyObserver (): void {
            if (this.intersectionObserver) {
                this.intersectionObserver.disconnect()
            }
        },
        handleIntersect (entries: IntersectionObserverEntry[]): void {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.fetchItems()
                }
            })
        },
        resetParams (): void {
            this.lastLoadedItemsCount = this.perPage
            this.items = []
        },
        resetAndRefetchItems (): void {
            this.resetParams()
            this.fetchItems()
        },
        async fetchFilters (): Promise<void> {
            if (!this.filtersEndpoint) {
                return
            }

            try {
                this.isFiltersLoading = true
                const { filters } = await ApiClientV3.get(this.filtersEndpoint)
                    .then(response => response.data)

                this.filters = filters
            } catch (error) {
                this.showNotification('error', error.message)
            } finally {
                this.isFiltersLoading = false
            }
        },
        async fetchItems (): Promise<void> {
            if (this.isLoading || this.isLastPage) {
                return
            }

            try {
                this.isLoading = true
                const newItems = await ApiClientV3.get(this.endpoint, {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params),
                })
                    .then(response => response.data.items)

                this.lastLoadedItemsCount = newItems.length
                this.items = [...this.items, ...newItems]
            } catch (error) {
                this.showNotification('error', error.message)
            } finally {
                this.isLoading = false
            }
        },
        handleTableSort (sort: { by: string, direction: 'asc' | 'desc' }): void {
            this.sort.by = sort.by
            this.sort.direction = sort.direction
        },
        onCloseFilters (): void {
            this.toggleModal('modal-filters')
        },
        resetFilters (): void {
            for (const key in this.selectedFilters) {
                this.selectedFilters[key] = []
            }
        },
        applyFilters (): Promise<void> {
            this.resetParams()

            return this.fetchItems()
        },
        onClearAllFilters (): Promise<void> {
            this.resetFilters()

            return this.fetchItems()
        },
    },
})
