<template>
    <table class="io-design-system io-table-component" :aria-label="label">
        <slot name="colgroup" />

        <thead>
            <tr>
                <slot name="headers" />
                <th v-if="showSelect" class="io-table-component__select-header">
                    <span v-if="showCheckbox" class="checkbox-column checkbox-column--check-all">
                        <input
                            name="all"
                            type="checkbox"
                            :checked="checkboxValue"
                            :class="checkAllClass"
                            @change="selectAll"
                        />
                    </span>
                </th>

                <th
                    v-for="(header, hid) in headers"
                    :key="`header_${ hid }`"
                    :class="getHeaderClasses(header)"
                    :style="header.extraStyles"
                    @click="onSort(header)"
                >
                    <span v-html="header.text" />

                    <i
                        v-if="header.sort !== false"
                        class="io-table-component__sort-icon"
                        :class="getSortIconClasses(header)"
                    />

                    <span v-if="header.asterisk" class="io-table-component__asterisk">*</span>

                    <template v-if="header.description">
                        <Tooltip :text="header.description"/>
                    </template>
                </th>
            </tr>
        </thead>

        <slot v-if="omitTbody" name="tbody" :sortedItems="sortedItems"/>

        <tbody v-else>
            <slot name="tbody" :sortedItems="sortedItems" />
        </tbody>

        <tfoot>
            <slot name="tfooter" />
        </tfoot>
    </table>
</template>

<script lang="ts" src="./Table.ts"></script>
<style lang="scss" src="./Table.scss" scoped></style>
