<template>
    <div class="io-jobsite-locations-tree" :class="{ 'io-row': topLevelJobsiteLocations }" data-testid="jobsite-locations-tree">
        <div class="io-location" :class="{ 'io-parent': topLevelJobsiteLocations }" :style="indent">
            <div class="io-location__content">
                <IOButton v-if="hasChildren" variant="plain-icon" size="small" :icon="collapseButtonIcon" @click="onClickCollapseButton(false)" />

                <Checkbox
                    input-name="jobsite-location"
                    :checkbox-value="jobsiteLocation.id"
                    :disabled="isCheckboxDisabled"
                    :data-testid="`jobsite-location-${ jobsiteLocation.id }`"
                    v-model="selectedTreeIds"
                    @change="changeSelectedLocations"
                />

                <div class="io-location__details" :class="{'io-is-archived': isArchived }">
                    {{ jobsiteLocation.title }}
                    <template v-if="isArchived">({{ $t('Archived') }})</template>
                </div>

                <StatusPill v-if="topLevelJobsiteLocations" :text="statusPillText" :variant="statusPillVariant"/>
            </div>
        </div>

        <div v-show="isNestedListShown">
            <JobsiteLocationsAccordion
                v-for="(child, idx) in jobsiteLocation.children"
                :key="idx"
                :is-disabled="isDisabled"
                :jobsite-location="child"
                :depth="depth + 1"
                :parentId="jobsiteLocation.id"
                :is-list-updated="isListUpdated"
                @update-tree="updateTree(jobsiteLocation.id)"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./JobsiteLocationsAccordion.ts"></script>
<style lang="scss" src="./JobsiteLocationsAccordion.scss" scoped></style>
