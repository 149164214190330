<template>
    <div class="io-notification-wrapper" @click="onNotificationWrapperClick">
        <div class="io-notification-content">

            <div class="io-notification-content__title">
                <span v-if="!notificationData.message">
                    {{ $t('You have been assigned') }} {{ notificationData.typeText }} {{ $t('on') }}
                    <b>
                        {{ notificationData.title }}
                    </b>
                </span>
                <span v-else v-html="notificationData.message"></span>
            </div>
            <div
                v-if="notificationData.requesterName"
                class="io-notification-content__requester-info"
            >
                <span class="io-notification-content__requester-info-from">
                    {{ $t('by') }}
                </span>

                {{ notificationData.requesterName }}
            </div>

        </div>
    </div>
</template>

<script src="./General.ts"></script>
<style lang="scss" src="./General.scss" scoped></style>
