<template>
    <div class="io-input-holder io-select-invoice-paid-by">
        <div v-for="company in companies" class="io-value io-f-row io-f-start" :key="company.mongo_id">
            <input
                type="radio"
                :value="company.value"
                :checked="company.value === selectedValue"
                @input="handleInput"
            />
            <Avatar
                :type="AvatarType.COMPANY"
                :employeeMongoId="company.mongo_id"
                :employeeFullName="company.name"
            />
            {{ company.name }}
            <StatusPill size="big" variant="accent-light" :text="company.type" />
        </div>
    </div>
</template>

<script lang="ts" src="./InvoicePaidBySelector.ts"></script>
<style lang="scss" src="./InvoicePaidBySelector.scss" scoped></style>
