import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import ApprovalsListItem from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListItem'
import ApprovalsListFiltersResponseData
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListFiltersResponseData'
import ApprovalsListFilters
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListFilters'
import ApprovalCompany from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalCompany'
import {
    ApprovalsListItemApprover
} from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListItemApprover'
import ApprovalsListItemStatus
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListItemStatus'
import ApprovalsListItemScope
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListItemScope'
import ApprovalsInboxTabsCount
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsInboxTabsCount'
import ApprovableResourceSubtype from '@/interfaces/modules/projects/financial-approvals/ApprovableResourceSubtype'
import ApplyToOthersResource from '@/interfaces/modules/projects/financial-approvals/ApplyToOthersResource'
import { CostCodeCategory } from '@/interfaces/modules/projects/financial-approvals/CostCodeCategory.ts'
import { Employee } from '@/interfaces/modules/projects/financial-approvals/Employee.ts'
import {
    ExternalClientApprovalDetailsData
} from '@/interfaces/modules/projects/financial-approvals/ExternalClientApprovalDetailsData.ts'

const mapFilters = (filters: ApprovalsListFilters) => ({
    ...filters,
    company: filters.company.map((vendor: ApprovalCompany) => vendor.id),
    approver: filters.approver.map((approver: ApprovalsListItemApprover) => approver.id)
})

const financialApprovalsClient = {
    deleteManualApproval (params: {approvable_resource_name: string, resource_id: string}): AxiosPromise {
        return client.delete('/resource-manual-approval', { params })
    },
    applyToOthers (companyResourceId: string, syncInstances: boolean, resources: Array<ApplyToOthersResource>): AxiosPromise<void> {
        return client.post(`company-project-approvable-resources/${ companyResourceId }/apply-to-others`, {
            sync_instances: syncInstances,
            resources
        })
    },
    getExternalClientApprovalDetails (resourceName: string, resourceId: string): AxiosPromise<{ data: ExternalClientApprovalDetailsData[] }> {
        return client.get(`external-client-approval/details?approvable_resource_name=${ resourceName }&resource_id=${ resourceId }`)
    },
    createExternalClientApproval (resourceName: string, resourceId: string): AxiosPromise<void> {
        return client.post(`external-client-approval?approvable_resource_name=${ resourceName }&resource_id=${ resourceId }`)
    },
    getApprovalsList (
        projectId: string,
        filters: ApprovalsListFilters,
        status: ApprovalsListItemStatus,
        scope: ApprovalsListItemScope,
        search: string
    ): AxiosPromise<ApprovalsListItem[]> {
        return client.get(`/projects/${ projectId }/approvals`, {
            params: {
                filters: JSON.stringify(mapFilters(filters)),
                status,
                scope,
                search
            }
        })
    },
    getApprovalsListFilters (
        projectId: string,
        status: ApprovalsListItemStatus,
        scope: ApprovalsListItemScope
    ): AxiosPromise<ApprovalsListFiltersResponseData> {
        return client.get(`/projects/${ projectId }/approvals/filters`, {
            params: { scope, status }
        })
    },
    getApprovalsListCategoriesCount (
        projectId: string,
        userPendingFilters: ApprovalsListFilters,
        companyPendingFilters: ApprovalsListFilters,
        companyRejectedFilters: ApprovalsListFilters,
        search: string
    ): AxiosPromise<ApprovalsInboxTabsCount> {
        return client.get(`/projects/${ projectId }/approvals/categories-count`, {
            params: {
                user_pending_filters: JSON.stringify(mapFilters(userPendingFilters)),
                company_pending_filters: JSON.stringify(mapFilters(companyPendingFilters)),
                company_rejected_filters: JSON.stringify(mapFilters(companyRejectedFilters)),
                search
            }
        })
    },
    getEligibleUsers (companyProjectResourceId: string): AxiosPromise<Employee[]> {
        return client.get(`company-project-approvable-resources/${ companyProjectResourceId }/eligible-users`)
    },
    /**
     * Gets eligible cost codes for budget
     * @param projectCompanyId if not provided, function will return global cost codes
     * @param resourceSubtype required to find proper budget relation when projectCompanyId is provided
     */
    getEligibleCostCodes (projectCompanyId: string | null, resourceSubtype: ApprovableResourceSubtype): AxiosPromise<CostCodeCategory[]> {
        return client.get('/approval-workflows/eligible-cost-codes', {
            params: {
                project_company_id: projectCompanyId,
                approvable_resource_subtype: resourceSubtype,
            },
        })
    },
}

export default financialApprovalsClient
