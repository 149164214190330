<template>
    <div class="io-right-panel-inner" :class="{ 'io-white-background': !rfis.length }">
        <div class="io-right-panel-inner__content">
            <IOPlaceholder
                v-if="!rfis.length"
                :subtitle="$t('There are no RFIs on this drawing yet. Click Add RFI to add one.')"
                icon="icon-comment-2-text"
                class="bg-white"
            />

            <div v-else v-click-outside="annotationDeselected">
                <SingleRfi
                    v-for="rfi in rfis"
                    :key="rfi.id"
                    :data="rfi"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./RfiPanel.ts"></script>
<style lang="scss" src="./RfiPanel.scss" scoped></style>
