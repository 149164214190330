<template>
    <div class="io-navbar-tile" v-click-outside="closeProfile">

        <transition name="mask">
            <div class="io-navbar-tile__mask" v-if="showProfile" @click="toggleShowProfile"></div>
        </transition>

        <div class="io-navbar-tile__content" @click="toggleShowProfile">
            <div class="io-navbar-tile__icon"><i class="far fa-user"></i></div>
        </div>

        <transition name="profile-menu">
            <div class="io-profile-menu" v-if="showProfile">
                <div class="io-profile-menu__header">
                    <div v-if="authData.u_photo" class="io-profile-menu__photo" :style="getBackgroundAvatar(authData.u_photo)"></div>
                    <initials v-else-if="authData.u_id"
                              class="io-profile-menu__initials"
                              :fullName="authData.u_firstname + ' ' + authData.u_lastname"
                              :id="authData.u_id"
                    ></initials>
                    <div class="io-profile-menu__name">{{ authData.u_firstname }} {{ authData.u_lastname }}</div>
                    <div class="io-profile-menu__email">{{ authData.u_email }}</div>
                </div>
                <div class="io-profile-menu__links">
                    <router-link v-slot="{ navigate }" :to="{name: 'account-edit'}" custom>
                        <div class="io-profile-menu__link" @click="() => { closeProfile(); navigate() }">
                        {{ $t('My Account') }}
                    </div></router-link>
                    <router-link v-slot="{ navigate }" :to="{name: 'settings-information'}" v-if="isAccessAllowed('settings')"><div class="io-profile-menu__link" @click="() => { closeProfile(); navigate() }">
                        {{ $t('Company Settings') }}
                    </div></router-link>
                </div>
                <div class="io-profile-storage">
                    <div class="io-profile-storage__content">
                        <DonutChart
                                :value="storage.usedStoragePercent"
                                :size="44"
                                :thickness="4"
                                :color="storage.chartColor"
                                showValue="true"
                        ></DonutChart>
                        <div class="io-profile-storage__text">
                            <div class="io-profile-storage__title">{{ $t('Storage used') }}</div>
                            <div class="io-profile-storage__info">
                                {{ $filters.fileSize(storage.usedStorage) }} {{ $t('out of') }} {{ $filters.fileSize(storage.totalStorage) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="io-profile-menu__footer">
                    <div
                        class="io-profile-menu__logout"
                        @click="logout"
                    >
                        {{ $t('Logout') }}
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script src="./profile.js"></script>
