import { defineComponent, PropType } from 'vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn.vue'
import ApproverAssignmentType from '@/interfaces/modules/projects/financial-approvals/ApproverAssignmentType'
import ApprovalType from '@/interfaces/modules/projects/financial-approvals/ApprovalType'

export default defineComponent({
    name: 'ApprovalTypeRadio',
    components: {
        FormInputColumn
    },
    props: {
        approverAssignmentType: {
            type: Object as PropType<ApproverAssignmentType>,
            required: true
        },
        isNewVersion: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            ApproverAssignmentType,
            ApprovalType,
            typeValue: null,
        }
    },
    computed: {
        type: {
            get (): string {
                return this.typeValue
            },
            set (value: string): void {
                this.typeValue = value

                this.$emit('change', value)
            }
        }
    },
})
