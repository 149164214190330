import commonCLient from '@/api/common/common_client.js'

export const resetState = function ({ commit, dispatch }) {
    commit('RESET_STATE')
}

export const setCountries = function ({ commit, dispatch }, value) {
    commit('SET_COUNTRIES', value || [])
}

export const setAppTypes = function ({ commit, dispatch }, value) {
    commit('SET_APP_TYPES', value || [])
}

export const setAppTypesMap = function ({ commit, dispatch }, value) {
    commit('SET_APP_TYPES_MAP', value || {})
}

export const setCountiesPhonesCodes = function ({ commit, dispatch }, value) {
    commit('SET_COUNTRIES_PHONES_CODES', value || [])
}

export const setUnitsMaterials = function ({ commit, dispatch }, value) {
    commit('SET_UNITS_MATERIALS', value || [])
}

export const setReimbursableTypes = function ({ commit, dispatch }, value) {
    commit('SET_REIMBURSABLE_TYPES', value || [])
}

export const setDepartments = function ({ commit, dispatch }, value) {
    commit('SET_DEPARTMENTS', value || [])
}


export const setUnitsSystems = function ({ commit, dispatch }, value) {
    commit('SET_UNITS_SYSTEMS', value || [])
}

export const setUnitsBudgetItem = function ({ commit, dispatch }, value) {
    commit('SET_UNITS_BUDGET_ITEM', value || [])
}

export const setSectors = function ({ commit, dispatch }, value) {
    commit('SET_SECTORS', value || [])
}

export const setStates = function ({ commit, dispatch }, value) {
    commit('SET_STATES', value || [])
}

export const setDivisions = function ({ commit, dispatch }, value) {
    commit('SET_DIVISIONS', value || [])
}

export const setDivisionsCustom = function ({ commit, dispatch }, value) {
    commit('SET_DIVISIONS_CUSTOM', value || [])
}

export const addDivisionCustom = function ({ commit, dispatch }, value) {
    commit('ADD_DIVISION_CUSTOM', value || [])
}

export const setTypesOfService = function ({ commit, dispatch }, value) {
    commit('SET_TYPES_OF_SERVICE', value || [])
}

export const setContractTypes = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT_TYPES', value || [])
}

export const setContractHolders = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACT_HOLDERS', value || [])
}

export const setDeliverMethods = function ({ commit, dispatch }, value) {
    commit('SET_DELIVER_METHODS', value || [])
}

export const setCustomFieldTypes = function ({ commit, dispatch }, value) {
    commit('SET_CUSTOM_FIELD_TYPES', value || [])
}

export const setCustomFieldListsTypes = function ({ commit, dispatch }, value) {
    commit('SET_CUSTOM_FIELD_LISTS_TYPES', value || [])
}

export const setAgreementTypes = function ({ commit, dispatch }, value) {
    commit('SET_AGREEMENT_TYPES', value || [])
}

export const load = async function ({ commit, dispatch, state }, forceRefresh = false) {
    if (!forceRefresh) {
        const dateCurrent = new Date().getTime()
        const dateMax = dateCurrent - (3600 * 24)
        if (state.refreshTime && state.refreshTime > dateMax) {
            return true
        }
    }

    try {
        const { data } = await commonCLient.load()
        commit('SET_APP_TYPES', data.app_types)
        commit('SET_APP_TYPES_MAP', data.app_types_map)
        commit('SET_COUNTRIES', data.countries)
        commit('SET_COUNTRIES_PHONES_CODES', data.countries_phones_codes)
        commit('SET_UNITS_MATERIALS', data.units_materials)
        commit('SET_UNITS_BUDGET_ITEM', data.units_budget_item)
        commit('SET_SECTORS', data.sectors)
        commit('SET_STATES', data.states)
        commit('SET_DIVISIONS', data.divisions)
        commit('SET_DIVISIONS_CUSTOM', data.divisions_custom)
        commit('SET_TYPES_OF_SERVICE', data.types_of_service)
        commit('SET_CONTRACT_TYPES', data.contract_types)
        commit('SET_CONTRACT_HOLDERS', data.contract_holders)
        commit('SET_DELIVER_METHODS', data.deliver_methods)
        commit('SET_REIMBURSABLE_TYPES', data.reimbursable_types)
        commit('SET_UNITS_SYSTEMS', data.units_systems)
        commit('SET_BID_QUESTIONS_TYPES', data.bid_questions_types)
        commit('SET_CURRENCIES', data.currencies)
        commit('SET_CURRENCIES_LIST', data.currencies_list)
        commit('SET_DEPARTMENTS', data.departments)

        commit('SET_CUSTOM_FIELD_TYPES', data.custom_field_types)
        commit('SET_CUSTOM_FIELD_LISTS_TYPES', data.custom_field_lists_types)

        commit('SET_AREA_TYPES', data.area_types)
        commit('SET_REFRESH_TIME', new Date().getTime())

        commit('SET_AGREEMENT_TYPES', data.agreement_types ?? [])

        return true
    } catch (exception) {
        return exception
    }
}
