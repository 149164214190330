<template>
    <td class="io-cell-dropdown">
        <div class="io-pill-dropdown" :class="{ 'io-active': dropdownExpanded }" @click="toggleDropdown" v-click-outside="closeDropdown">
            <div class="io-pill-dropdown__text io-status-pill" :class="selectedOptionClassName">{{ selectedOptionText }}</div>

            <div class="io-pill-dropdown__icon"><i class="fas fa-angle-down"></i></div>

            <transition name="pill-dropdown-fade">
                <div v-if="dropdownExpanded" class="io-pill-dropdown__options">
                    <div class="io-pill-dropdown__section">
                        <template v-for="option in options" :key="option.value">
                            <div
                                 class="io-pill-dropdown__option"
                                 @click="select(option)">
                                <div class="io-status-pill" :class="option.class">{{ option.name }}</div>
                            </div>
                        </template>
                    </div>
                </div>
            </transition>
        </div>
    </td>
</template>

<script lang="ts" src="./DropdownStatusPill.ts"></script>
<style lang="scss" src="./DropdownStatusPill.scss" scoped></style>
