<template>
    <IOModal
        :color="modalIconColor"
        :width="650"
        :title="modalTitle"
        :icon="modalIcon"
    >
        <template #default>
            <div class="io-notes">
                {{ title }}
                <span v-if="addedBy" class="io-notes__company">{{ addedBy }}</span>
                <span v-if="date">{{ $t('at') }} {{ getTime }} {{ $t('on') }} {{ getDate }}.</span>
            </div>
            <div class="io-notes__note">
                <span>{{ $t('Note') }}</span>
                <p>{{ note }}</p>
            </div>
        </template>
        <template #footer>
            <IOButton
                variant="secondary"
                @click="$emit('close')"
            >
                {{ $t('Close') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./NotesModal.ts"></script>
<style lang="scss" src="./NotesModal.scss" scoped></style>
