export function ADD_NOTICE (state, notice) {
    state.notices.push(notice)
    state.audio.pause()
    state.audio.currentTime = 0
    state.audio.play()
}

export function REMOVE_NOTICE (state, noticeId) {
    state.notices = state.notices.filter(item => {
        return item.id !== noticeId
    })
}

export function ADD_ALERT (state, alert) {
    state.alerts.push(alert)
    state.audio.pause()
    state.audio.currentTime = 0
    state.audio.play()
}

export function REMOVE_ALERT (state, alertId) {
    state.alerts = state.alerts.filter(item => {
        return item.id !== alertId
    })
}