import moment from 'moment'

export function ADD_CHECKED_ELEMENT (state, element) {
    state.checkedElements.push(element)
}
export function DELETE_CHECKED_ELEMENT (state, element) {
    let index = state.checkedElements.findIndex(x => x === element)
    if (index > -1) {
        state.checkedElements.splice(index, 1)
    }
}
export function DELETE_ALL_CHECKED_ELEMENTS (state) {
    state.checkedElements = []
}
export function CLEAR_ALL_ITEMS_HOLDERS (state) {
    state.recentlyAdded = []
    state.folders = []
    state.files = []
    state.projects = []
}
export function SET_DISPLAY_MODE (state, mode) {
    state.displayMode = mode
}
export function SET_RECENTLY_ADDED (state, elements) {
    state.recentlyAdded = elements
}
export function SET_FOLDERS (state, elements) {
    state.folders = elements
}
export function SET_FILES (state, elements) {
    state.files = elements
}
export function SET_PROJECTS (state, elements) {
    state.projects = elements
}
export function ADD_ITEM_TO_PATH_STACK (state, element) {
    state.pathStack.push(element)
}
export function REDUCE_PATH_STACK_TO_ELEMENT_INDEX (state, pathElementIndex) {
    state.pathStack = state.pathStack.filter((item, itemIndex) => {
        return pathElementIndex >= itemIndex
    })
}
export function SET_SEARCH_INPUT_VALUE (state, value) {
    state.searchInputValue = value
}
export function SET_SORT_BY (state, value) {
    state.sortByLabel = value
}
export function SET_SORT_BY_DIRECTION (state, value) {
    state.sortByDirection = value
}
export function SET_ACTIVE_DISPLAY_FILTER_ID (state, value) {
    state.activeDisplayFilterId = value
}
export function SET_FAB_BUTTON_DISPLAY_FLAG (state, value) {
    state.enableFab = value
}
export function SET_ACTIVE_FILTER (state, value) {
    state.activeFilter = value
}
export function UPDATE_ITEM_NAME_BY_INDEX (state, value) {
    if (value.itemTypeName === 'file') {
        if (state.files[value.index]) {
            state.files[value.index].file_name = value.name
        }
    }
}
export function DELETE_ITEM_BY_ID (state, value) {
    if (value.itemTypeName === 'file') {
        // "recentlyAdded" items holder
        let fileIndex = state.recentlyAdded.findIndex(x => x._id === value.itemId)
        if (fileIndex > -1) {
            state.recentlyAdded.splice(fileIndex, 1)
        }
        // "files" items holder
        let fileIndex2 = state.files.findIndex(x2 => x2._id === value.itemId)
        if (fileIndex2 > -1) {
            state.files.splice(fileIndex2, 1)
        }
    } else if (value.itemTypeName === 'folder') {
        // "folders" items holder
        let folderIndex = state.folders.findIndex(x => x._id === value.itemId)
        if (folderIndex > -1) {
            state.folders.splice(folderIndex, 1)
        }
    }
}
export function APPEND_ITEM_TO_HOLDER (state, value) {
    if (value.itemTypeName === 'file') {
        if (value.itemData) {
            // add to "files" holder
            state.files.push(value.itemData)

            // manage "recentlyAdded" holder
            // if holder contains less items than limit then just prepend item
            if (state.recentlyAdded.length < state.recentlyAddedLimit) {
                state.recentlyAdded.unshift(value.itemData)
            // otherwise remove first item then prepend new
            } else {
                // state.recentlyAdded.shift()
                state.recentlyAdded.unshift(value.itemData)
            }
        }
    }
}
export function SET_SPACE_USAGE (state, value) {
    // spaceUsage: {used: 0, total: 0, usedPercent: 0}, // holds space usage data
    if (typeof value.used === 'number' && typeof value.total === 'number') {
        value.total = value.total === 0 ? value.used : value.total
        let usedPercent = ((value.used / value.total) * 100).toFixed(0)
        
        // update data
        state.spaceUsage = {
            used: value.used,
            total: value.total,
            usedPercent: usedPercent
        }
    }
}
export function SET_REQUEST_RUNNING (state, value) {
    if (state.requestsRunning[value.name] !== undefined) {
        state.requestsRunning[value.name] = value.state
    }
}
export function SET_UPLOADER_BOX_DISPLAY_FLAG (state, value) {
    state.enableUploadBox = value
}
export function ENABLE_RELOCATE_BOX (state, value) {
    state.enableRelocateBox = value
}
export function SET_RELOCATE_BOX_MODE (state, value) {
    state.relocateBoxMode = value
}
export function SET_RELOCATE_BOX_ITEM (state, item) {
    state.relocateBoxItem = item
}
export function SET_CURRENT_PREVIEW_DOCUMENT_ID (state, documentId) {
    state.currentPreviewDocumentId = documentId
}
export function SET_DATE_FORMAT (state, value) {
    state.dateMode = value
}
export function UPDATE_ELEMENT_PROPERTIES (state, payload) {
    if (payload.itemId && typeof payload.properties === 'object') {
        if (payload.itemTypeName === 'file') {
            // set update date
            payload.properties.updated_at = moment.utc().format('YYYY-MM-DD HH:mm:ss')
    
            // find element (file) in "files"
            let fileIndex = state.files.findIndex(x => x._id === payload.itemId)
            if (fileIndex > -1) {
                // get file
                let file = state.files[fileIndex]
                // update file properties
                const keys = Object.keys(payload.properties)
                for (const key of keys) {
                    if (file[key] !== undefined) {
                        file[key] = payload.properties[key]
                    }
                }
                // update file in vuex store
                state.files[fileIndex] = file
            }

            // find element (file) in "recently added"
            let fileIndex2 = state.recentlyAdded.findIndex(x => x._id === payload.itemId)
            if (fileIndex2 > -1) {
                // get file
                let file = state.recentlyAdded[fileIndex2]
                // update file properties
                const keys = Object.keys(payload.properties)
                for (const key of keys) {
                    if (file[key] !== undefined) {
                        file[key] = payload.properties[key]
                    }
                }
                // update file in vuex store
                state.recentlyAdded[fileIndex] = file
            }
        } else if (payload.itemTypeName === 'folder') {
            // ...
        }
    }
}
export function SET_INVITE_MODAL_ITEM_ID (state, id) {
    state.inviteModalItemId = id
}
export function SET_IN_PROJECT_CONFIG (state, projectObj) {
    let config = {
        id: projectObj.project_local_id,
        name: projectObj.formatted_id + ': ' + projectObj._project_name,
        type: 'project'
    }
    state.inProjectConfig = config
}

