import i18n from '@/base/i18n'

/**
 * Get phases
 *
 * @param state
 * @return {[]}
 */
export function getContractPhases ( state ) {
    let phases = []

    state.contractPhases?.forEach(( phase ) => {
        let costCode = phase.costCode ? phase.costCode : null
        let phaseItem = { id: phase.id, name: phase.name, costCode: costCode, tasks: phase.tasks }
        phases.push(phaseItem)
    })

    phases.push({ id: 'new', name: `- ${ i18n.global.t('Add New Scope') }` })
    return phases
}
