export default {
    currentView: 0,
    filters: [],
    showFilters: false,
    filtersSelected: {},
    drawingSheetsShown: false,
    drawingSetsShown: false,
    parentFormShown: true,
    activeDrawingSet: null,
    activeDrawingSheet: null,
    drawingSheetShown: false,
    shareMarkupPopupShown: false,
    shareMarkupData: [],
    drawingVersionSwitched: false
}
