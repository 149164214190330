<template>
    <div
        :id="id"
        ref="table"
        class="io-table-wrapper"
        :class="{ 'io-only-footer': onlyFooter, 'io-only-actions-row': onlyActionsRow }"
        tabindex="0"
    >
        <table class="io-expandable-table">
            <thead v-if="!onlyFooter && !onlyActionsRow">
                <tr v-if="groups && groups.length">
                    <template
                        v-for="group in groups"
                        :key="`group-${ group.id }`"
                    >
                        <th
                            :colspan="getGroupColspan(group)"
                            class="io-group-header"
                            :class="group.classes"
                        >
                            <div>{{ group.name }}</div>
                        </th>
                    </template>
                </tr>
                <tr v-if="tableHeaders.length">
                    <template v-for="(column, index) in tableHeaders" :key="`column-${ index }`">
                        <th
                            v-if="column.visible"
                            class="io-table-header"
                            :class="`${ column.classes } ${ showColumnSubText ? 'io-border-none' : '' } ${ showColumnSubText ? '' : 'io-no-subtext' }`"
                            :style="[column.extraStyles, column.headerStyles]"
                        >
                            <div class="io-table-header__text">
                                {{ column.name }}
                                <Tooltip v-if="column.headerInfo" :text="column.headerInfo"/>
                            </div>
                            <div v-if="hasHeaderSubValue(column)" class="io-table-header-sub-text">
                                {{ headerSubValue(column) }}
                            </div>
                        </th>
                    </template>
                </tr>
                <tr v-if="showColumnSubText">
                    <template v-for="(column, index) in tableHeaders" :key="`column-${ index }`">
                        <th
                            v-if="column.visible"
                            class="io-table-header-sub-text io-table-header-sub-text--row"
                            :class="column.classes"
                            :style="column.extraStyles"
                        >
                            {{ column.subText }}
                        </th>
                    </template>
                </tr>
            </thead>

            <tbody v-if="!onlyFooter && !onlyActionsRow">
                <template v-for="(item, index) in items" :key="item[itemIdentifier]">
                    <TableRowContainer
                        classes="io-visible-row"
                        :item="item"
                        :headers="tableHeaders"
                        :nestedRowsIndicator="nestedRowsIndicator"
                        :levelIndex="index + 1"
                        :index="index"
                        :rowsToExpand="rowsToExpand"
                        :itemIdentifier="itemIdentifier"
                        @expandRowId="updateRowsToExpand"
                        @click-edit-item="handleClickEditItem"
                        @inputClicked="emitInputClick"
                    />
                </template>
            </tbody>

            <tbody v-if="showActionsRow && !onlyFooter">
                <TableRowActions
                    :actions-row-buttons="actionsRowButtons"
                    :colspan="headers.length"
                    @addQuotedItem="$emit('addQuotedItem')"
                    @applyRetentionToAll="$emit('applyRetentionToAll')"
                    @selectMySoVLine="$emit('selectMySoVLine')"
                    @removeMySoVLine="$emit('removeMySoVLine')"
                    @addAlternateItem="$emit('addAlternateItem')"
                    @loadFromTemplate="$emit('loadFromTemplate')"
                />
            </tbody>

            <tfoot v-if="showFooter && !onlyActionsRow">
                <tr class="io-expandable-table__footer-row">
                    <template v-for="column in tableHeaders" :key="column.id">
                        <td
                            :class="[column.classes, {'io-no-subtext': !hasFooterSubValue(column)}]"
                            :style="column.extraStyles"
                        >
                            <div class="io-expandable-table__footer-row-cell">
                                <template v-if="hasFooterLeftIcons(column)">
                                    <span
                                        v-for="(icon, iconIndex) in column.footerLeftIcons()"
                                        :key="iconIndex"
                                        :class="`io-cell-icon-left ${icon}`"
                                    ></span>
                                </template>
                                <span>
                                    {{ footerValue(column) }}
                                    <div
                                        v-if="hasFooterSubValue(column)"
                                        class="io-expandable-table__footer-row-subvalue"
                                    >
                                        {{ footerSubValue(column) }}
                                    </div>
                                </span>
                            </div>
                        </td>
                    </template>
                </tr>
            </tfoot>
        </table>

        <div v-if="showScrollArrows && !onlyFooter" class="io-table-wrapper__arrows">
            <button
                v-if="!isAtStart"
                :style="{ left: leftArrowPosition, top: topArrowPosition }"
                class="io-table-wrapper__arrow io-table-wrapper__arrow-left"
                @click="doScrollLeft"
            >
                <span class="icon-arrow-left"/>
            </button>
            <button
                v-if="!isAtEnd"
                :style="{ left: rightArrowPosition, top: topArrowPosition }"
                class="io-table-wrapper__arrow io-table-wrapper__arrow-right"
                @click="doScrollRight"
            >
                <span class="icon-arrow-right"/>
            </button>
        </div>
    </div>
</template>

<script lang="ts" src="./ExpandableTable.ts"></script>
<style lang="scss" src="./ExpandableTable.scss" scoped></style>
<style>
th {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: var(--main-lighten-1);
    border-bottom: none !important;
}
</style>
