<template>
    <div class="io-choose-contract-boxes-center">
        <div class="io-project-wizard-nofile">
            {{ $t('You need to select existing contract') }}
        </div>
        <form>
            <div class="io-form-group io-form-validation">
                <div class="io-column-field io-column-validation io-column-validation-field">
                    <multiselect
                        track-by="id"
                        label="name"
                        :options="contracts"
                        :allow-empty="false"
                        :searchable="true"
                        :placeholder="$t('Please Select')"
                        v-model="contract"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts" src="./StepSelectContract.ts"></script>

<style lang="scss" src="./StepSelectContract.scss" scoped></style>
