import { defineComponent } from 'vue'
import GroupCheck from './group-check/GroupCheck.vue'
import { Code } from '@/interfaces/modules/projects/financial-approvals/Code'
import { CostCodeCategory } from '@/interfaces/modules/projects/financial-approvals/CostCodeCategory'

export default defineComponent({
    name: 'CostCodesDropdown',
    components: {
        GroupCheck
    },
    props: {
        modelValue: { type: Array as () => Code[] },
        costCodesCategories: { type: Array as () => CostCodeCategory[] },
        lockedCostCodes: { type: Array as () => Code[] },
        unassignedCostCodes: { type: Array as () => Code[] },
        hideRemainingTitle: { type: Boolean, required: true, default: false },
        customLabel: { type: String },
        customToggleAll: { type: Boolean, default: false }
    },
    data () {
        return {
            optionsShown: false as boolean,
            unassignedCostCodesList: null
        }
    },
    computed: {
        costCodes: {
            get (): Code[] {
                return this.modelValue
            },
            set (value: any): void {
                this.$emit('update:modelValue', value)
            },
        },
        allChecked (): boolean {
            return this.unassignedCostCodesList.length === 0
        },
        noneChecked (): boolean {
            return this.costCodes.length === 0
        },
        someChecked (): boolean {
            return !this.allChecked && !this.noneChecked
        },
        selectedCostCodesCount (): number {
            return this.costCodes?.length ?? 0
        },
        unassignedCostCodesCount (): number {
            return this.unassignedCostCodesList?.length ?? 0
        },
        findUnnasignedCostCodes (): Code[] {
            const allCategoryIds = this.costCodesCategories.flatMap(category =>
                category.codes.map(code => code.id),
            )

            if (!this.costCodes.length) {
                return allCategoryIds.map(id => ({ cost_code_id: id }))
            } else {
                return []
            }
        },
    },
    watch: {
        costCodes: {
            handler (): void {
                this.unassignedCostCodesList = this.unassignedCostCodes || this.findUnnasignedCostCodes
            },
            deep: true
        },
        unassignedCostCodes (): void {
            this.unassignedCostCodesList = this.unassignedCostCodes
        }

    },
    beforeMount (): void {
        this.costCodes ??= []
        this.unassignedCostCodesList = this.unassignedCostCodes || this.findUnnasignedCostCodes
    },
    methods: {
        toggleAll (): void {
            if (this.allChecked) {
                this.costCodes = []
            } else {
                this.costCodes.push(...this.unassignedCostCodesList)
            }
        },

        toggleDropdown (): void {
            this.optionsShown = !this.optionsShown
        },

        closeDropdown (): void {
            this.optionsShown = false
        }

    }
})
