<template>
    <div v-if="rendered" class="io-filters">
        <div class="io-single-filter">
            <template v-for="(filter, index) in filtersSchema" :key="index">
                <div class="io-single-category">

                    <template v-if="filter.type === 'checkbox'">
                        <FilterCheckboxes
                            :title="filter.label"
                            :options="findFilterOptions(filter.field)"
                            :maxLength="6"
                            :returnNumber="true"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'status'">
                        <FilterStatuses
                            :title="filter.label"
                            :options="findFilterOptions(filter.field)"
                            :translate="true"
                            :pillsColorFunction="pillsColorFunction"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'color-dot'">
                        <FilterColorDot
                            :title="filter.label"
                            :options="findFilterOptions(filter.field)"
                            :translate="true"
                            :colorDotFunction="colorDotFunction"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'date'">
                        <filterDateTimePicker
                            :title="filter.label"
                            :options="{ formatted: 'MM/DD/YYYY', 'only-date': true, range: true }"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'multiselect'">
                        <FilterMultiselect
                            :title="filter.label"
                            :options="findFilterOptions(filter.field)"
                            :settings="{multiple: true}"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>

                    <template v-else-if="filter.type === 'tree_multi'">
                        <FilterTree
                            :title="filter.label"
                            :options="findFilterOptions(filter.field)"
                            v-model="filtersSelected[filter.field]"
                        />
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" src="./Filters.ts"></script>

<style lang="scss" src="./Filters.scss" scoped/>
