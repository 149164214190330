import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'
import type SharedWorkspaceData from '@/elements/CompanyContactDropdown/interfaces/SharedWorkspaceData'
import type { BidRequestContact } from '@/io-modules/bid-requests/interfaces'

export default {
    getAllOffSystemCompaniesFilterByNameAndAppTypes (
        search: string,
        appTypes: string[]
    ): AxiosPromise<SharedWorkspaceData[]> {
        return client.get('/companies/off-system', { params: { search, appTypes } })
    },

    getAllContactsByCompany (companyId: number): AxiosPromise<BidRequestContact[]> {
        return client.get(`/contacts/company/${ companyId }`)
    }
}
