import { defineStore } from 'pinia'
import StateInterface from '@/io-modules/documents/interfaces/StateInterface'
import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'
import { AnnotationData } from '@/io-modules/documents/interfaces/AnnotationData'
import { MentionUserInterface } from '@/io-modules/documents/interfaces/MentionUserInterface'

export const viewDocumentStore = defineStore('viewDocument', {
    state: (): StateInterface => ({
        cursor: AnnotationTypes.DEFAULT,
        isAnnotationBeingAdded: false,
        typeOfAnnotationBeingAdded: AnnotationTypes.BASIC,
        selectedAnnotationId: null,
        isAnnotationInputVisible: false,
        annotations: [],
        counters: {
            basic: null,
            comment: null
        },
        uniqueMentionUsers: []
    }),
    getters: {
        selectedAnnotationIsTemporary (): boolean {
            return !this.annotations.some((annotation: AnnotationData) => annotation.uuid === this.selectedAnnotationId)
        }
    },
    actions: {
        triggerAddingAnnotationEvent (annotationType: AnnotationTypes): void {
            this.setCursor(annotationType)
            this.isAnnotationBeingAdded = true
            this.typeOfAnnotationBeingAdded = annotationType
        },
        endAddingAnnotation (): void {
            this.setCursor(AnnotationTypes.DEFAULT)
            this.isAnnotationBeingAdded = false
            this.typeOfAnnotationBeingAdded = AnnotationTypes.BASIC
            this.isAnnotationInputVisible = false
        },
        setCursor (cursorType: AnnotationTypes): void {
            this.cursor = cursorType
        },
        changeCursorToDefault (): void {
            this.cursor = AnnotationTypes.BASIC
        },
        setSelectedAnnotationId (annotationId: string): void {
            this.selectedAnnotationId = annotationId
        },
        clearSelectedAnnotationId (): void {
            this.selectedAnnotationId = null
        },
        showAnnotationInput (): void {
            this.isAnnotationInputVisible = true
        },
        setAnnotations (annotations: AnnotationData[]): void {
            this.annotations = annotations
        },
        setCounters (counters: { basic: number, comment: number }): void {
            this.counters = counters
        },
        setUniqueMentionUsers (users: MentionUserInterface[]): void {
            this.uniqueMentionUsers = users
        }
    }
})
