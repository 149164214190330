<template>
    <div>
        <Uploader
            v-if="!isViewMode"
            array-id="0"
            :add-to-temporary="true"
            :model-type="modelType"
            :validation-options="validationOptions"
            @updateDocuments="fileAdded"
        />

        <FilesTable
            v-if="files.length"
            :is-view-mode="isViewMode"
            :files="files"
            @preview="preview"
            @edit="openRenameModal"
            @delete="remove"
        />

        <RenameFileModal
            v-if="renameModalShown"
            :file="fileToRename"
            @close="closeRenameModal"
            @rename="rename"
        />
    </div>
</template>

<script lang="ts" src="./FileUploaderWithTable.ts"></script>
