import { defineComponent } from 'vue'
import filterDateTimePicker from '@/components/ModalFilters/filters/filterDateTimePicker.vue'
import FilterCheckboxes from '@/components/ModalFilters/filters/filterCheckboxes.vue'
import ToggleSwitchFilter from '@/components/lazy-list/parts/toggle-switch-filter/ToggleSwitchFilter.vue'
import FilterStatuses from '@/components/ModalFilters/filters/filterStatuses.vue'
import FilterMultiselect from '@/components/ModalFilters/filters/filterMultiselect.vue'
import FilterTree from '@/components/ModalFilters/filters/filterTree.vue'
import FilterColorDot from '@/components/ModalFilters/filters/filterColorDot.vue'
import FilterRadio from '@/components/ModalFilters/filters/filterRadio.vue'
import FilterTags from '@/components/ModalFilters/filters/filterTags.vue'
import FilterRadioPill from '@/components/ModalFilters/filters/filterRadioPill.vue'
import FilterCheckboxesAll from '@/components/ModalFilters/filters/filterCheckboxesAll.vue'
import { FilterInterface } from '@/interfaces/components/lazy-list/FilterInterface'
import { find } from 'lodash'
import { mapGetters } from 'vuex'
import FilterCustomMultiselectBic
    from '@/components/lazy-list/parts/filter-custom-multiselect-bic/FilterCustomMultiselectBic.vue'
import ModalMixin from '@/mixins/modalMixin'

export default defineComponent({
    components: {
        filterDateTimePicker,
        FilterCheckboxes,
        FilterMultiselect,
        FilterStatuses,
        FilterTree,
        FilterColorDot,
        ToggleSwitchFilter,
        FilterRadio,
        FilterTags,
        FilterRadioPill,
        FilterCheckboxesAll,
        FilterCustomMultiselectBic
    },
    props: {
        filtersSchema: { type: Array, default: null, required: true },
        customPillsColorFunction: { type: Function, default: null },
    },
    mixins: [ModalMixin],
    data () {
        return {
            isOpen: false,
            rendered: false,
            isFiltersToggled: {}
        }
    },
    computed: {
        ...mapGetters('lazyList', {
            filters: 'filters',
            filtersSelected: 'filtersSelected'
        }),
        areFiltersEmpty (): boolean {
            return !Object.values(this.filters).some(filter => filter.options && filter.options.length > 0)
        }
    },
    created () {
        this.filtersSchema.forEach((filter: any) => {
            let defaultType = ['radio', 'radio-pill'].includes(filter.type) ? '' : []

            if (this.filtersSelected[filter.field] === false) {
                defaultType = false
            }

            this.$store.dispatch('lazyList/setSingleFilter', {
                field: filter.field,
                value: this.filtersSelected[filter.field] || defaultType
            })
        })
        this.rendered = true
    },
    methods: {
        toggleFilter (key: any): void {
            this.isFiltersToggled[key] = true
        },
        getHideSelectAllStatus (filter: FilterInterface): boolean {
            return filter?.additional?.hideSelectAllStatus ?? false
        },
        getHideSelectAllCheckboxes (filter: FilterInterface): boolean {
            return filter?.additional?.hideSelectAllCheckboxes ?? true
        },
        findFilterOptions (filter: FilterInterface) {
            if (filter.customOptionsEnable) {
                return filter.customOptions ? filter.customOptions : []
            }

            const filtersList = find(this.filters, { name: filter.field })

            if (
                filtersList &&
                filtersList.options &&
                filtersList.options.length <= 7
            ) {
                this.isFiltersToggled[filter.field] = true
            }
            return filtersList ? filtersList.options : []
        },
        normalizer (node: any) {
            return {
                id: node._id,
                label: node.name,
                children: node.children
            }
        },
        isFilterVisible (filter: FilterInterface): boolean {
            return filter?.visible !== false
        },
        pillsColorFunction (item: string, key: string): string {
            if (this.customPillsColorFunction) {
                return this.customPillsColorFunction(item, key)
            }

            switch (item) {
                case 'Completed':
                case 'contract.status_executed':
                case 'Low':
                case 'Investor':
                case 'Genius':
                case 'investor':
                case 'genius':
                case 'Importing':
                    return 'io-green'
                case 'In Progress':
                case 'contract.status_draft':
                case 'Scheduled':
                case 'DRAFT':
                    return 'io-blue'
                case 'Owner\'s Rep':
                case 'rep':
                case 'dev':
                case 'owner':
                case 'Owner':
                    return 'accent-dev'
                case 'On Hold':
                case 'contract.status_requested':
                case 'contract.status_under_review':
                case 'Feasibility':
                case 'CONSTRUCTION':
                case 'Medium':
                case 'sc':
                case 'Created':
                    return 'io-yellow'
                case 'gc':
                    return 'io-orange'
                case 'ac':
                    return 'io-purple'
                case 'Cancelled':
                case 'contract.status_declined':
                case 'Not Started':
                case 'High':
                case 'Vendor':
                case 'Failed':
                case 'Rolling Back':
                    return 'io-red'
                case 'contract.status_partial_signed':
                case 'General Contractor':
                    return 'io-orange'
                case 'ARCHIVED':
                case 'Active':
                    return 'io-green'
                case 'Pending':
                    return 'io-blue'
                case 'Archived':
                case 'External Pending':
                    return 'io-grey'
                case 'Disabled':
                    return 'io-red'
                case 'design':
                case 'Designer':
                    return 'io-purple'
                default:
                    return 'io-yellow'
            }
        },
        colorDotFunction (item: string): string {
            switch (item) {
                case 'contract.sov_status_pending':
                case 'contract.sov_status_submitted':
                case 'loi.status_pending_signature':
                case 'Pending Signature':
                    return 'io-yellow'
                case 'loi.status_pending_client_approval':
                case 'Pending Client Approval':
                    return 'io-yellow'
                case 'contract.sov_status_rejected':
                case 'contract.sov_status_taken_by_sender':
                case 'loi.status_voided':
                case 'loi.status_rejected':
                case 'Voided':
                case 'Rejected':
                    return 'io-red'
                case 'contract.sov_status_approved':
                case 'loi.status_signed':
                case 'Signed':
                    return 'io-green'
                case 'contract.sov_status_draft':
                case 'Draft':
                    return 'io-blue'
                case 'loi.status_na':
                case 'N/A':
                    return 'io-grey'
                default:
                    return 'io-grey'
            }
        }
    }
})
