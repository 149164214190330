import PageHeader from '@/components/page-header/PageHeader.vue'
import { defineComponent } from 'vue'
import fundingClient from '../../api-clients/fundingClient'
import FundingSourcesSummary from '@/io-modules/global-funding/components/funding-sources-summary/FundingSourcesSummary.vue'
import Avatar from '@/components/atoms/Avatar/Avatar'
import TabCapitalFunds from '@/io-modules/global-funding/components/tab-capital-funds/TabCapitalFunds.vue'
import TabProjectSpecificFunds from '@/io-modules/global-funding/components/tab-project-specific-funds/TabProjectSpecificFunds.vue'
import { FundingSourcesProvider } from '../../interfaces/GlobalFundingInterfaces.ts'
import AddNewCapitalFundModal from '@/io-modules/global-funding/components/modals/add-new-capital-fund/AddNewCapitalFundModal.vue'
import { mapActions, mapState } from 'pinia'
import { globalFundingStore } from '../../stores/globalFundingStore'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'
import { Breadcrumb } from '@/store/app/breadcrumbs'
import { MenuItems } from '../../enums/GlobalFundingEnums'
import { FundingSourcesFundSummary } from '../../interfaces/GlobalFundingInterfaces.ts'

export default defineComponent({
    name: 'ProviderDetails',
    components: {
        AddNewCapitalFundModal,
        TabProjectSpecificFunds,
        TabCapitalFunds,
        Avatar,
        FundingSourcesSummary,
        PageHeader,
        ActionDropdown,
    },
    mixins: [breadcrumbsMixin],
    data () {
        return {
            providerId: null,
            providerInfo: ({} as FundingSourcesProvider | null)?.provider || null,
            providerSummary: null as FundingSourcesFundSummary,
            activeTab: MenuItems.CAPITAL_FUNDS,
            menuItemsEnum: MenuItems,
            menuItems: [
                {
                    name: this.$t('Program Funds'),
                    number: MenuItems.CAPITAL_FUNDS,
                },
                {
                    name: this.$t('Project Specific Funds'),
                    number: MenuItems.PROJECT_SPECIFIC_FUNDS,
                },
            ],
        }
    },
    computed: {
        ...mapState(globalFundingStore, [
            'addNewCapitalFundModal'
        ]),
        providerName (): string {
            return this.providerInfo ? this.providerInfo.name : ''
        },

        breadcrumbs (): Breadcrumb[] {
            if (!this.$route.meta.breadcrumbs) {
                return []
            }
            const breadcrumbs: Breadcrumb[] = this.$route.meta.breadcrumbs as Breadcrumb[]
            return [
                ...breadcrumbs,
                {
                    text: this.providerName || '-',
                    to: { name: 'funding-sources-provider-details', params: { id: this.providerId } },
                },
            ]
        },
    },
    watch: {
        breadcrumbs (): void {
            this.setBreadcrumbs(this.breadcrumbs)
        },
    },
    beforeMount () {
        this.providerId = this.$route.params.id
    },
    async mounted () {
        try {
            const response = await fundingClient.getProviderInfo(this.providerId)
            if (response && response.data) {
                this.providerId = response.data.id
                this.providerInfo = response.data.provider
            }

            const responseSummary= await fundingClient.getProviderDetailsSummary(this.providerId)
            if (responseSummary && responseSummary.data) {
                this.providerSummary = responseSummary.data
            }
        } catch (e) {
            this.errorHandleNoRedirect(e)
        }

        this.setBreadcrumbs(this.breadcrumbs)
    },
    unmounted () {
        this.setBreadcrumbs([])
    },
    methods: {
        ...mapActions(globalFundingStore, [
            'setAddNewCapitalFundModal'
        ]),
        changeActiveTab (tab: MenuItems): void {
            this.activeTab = tab
        },
        toggleShowNewFundModal (): void {
            this.setAddNewCapitalFundModal({
                show: !this.addNewCapitalFundModal.show,
                editMode: false,
            })
        },
        async exportPdf (): Promise<void> {
            try {
                await window.open(
                    `${ this.$api.getApi2RequestUrl(`/funds-providers/${ this.providerId }/projects/pdf/`) }`,
                    '_blank',
                    'noopener',
                )
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
    },
})
