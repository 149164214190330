import { defineComponent, PropType } from 'vue'
import BudgetSiteSelect from '@/components/budget-site-select/BudgetSiteSelect.vue'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'
import { mapState as mapStateVuex } from 'vuex'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import type { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'

export default defineComponent({
    name: 'SelectProjectSiteModal',
    components: {
        AlertBox,
        IOModal,
        BudgetSiteSelect
    },
    props: {
        budgetType: {
            type: String as PropType<BudgetType>,
            default: BudgetType.MY_BUDGET,
        },
        preselectedSites: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    data () {
        return {
            sitesSelected: null as ProjectSite[]
        }
    },
    computed: {
        ...mapStateVuex('project', {
            projectID: (state: any) => state.projectObj.project_global_id
        }),
    },
    methods: {
        goToBudget (): void {
            this.$router.push({
                name: 'project-budget-dashboard-summary'
            })
        },

        selectSites (): void {
            this.$emit('sitesSelected', this.sitesSelected)

            this.$emit('close')
        }
    }
})
