import { uniqueId } from 'lodash'

export const setShow = function ({commit, dispatch}, payload) {
    payload.id = uniqueId('notification')

    commit('SET_SHOW', payload)
    if (!payload.preventHide) {
        dispatch('prepareToClose', payload.id)
    }
}

export const prepareToClose = function ({commit}, bannerId) {
    commit('CLEAR_TIMEOUT', bannerId)
    const timeoutHandle = setTimeout(() => {
        commit('DELETE_BANNER_BY_ID', bannerId)
        commit('CLEAR_TIMEOUT', bannerId)
    }, 6000)
    commit('SET_TIMEOUT', {
        bannerId: bannerId,
        timeout: timeoutHandle
    })
}

export const setClose = function ({commit}) {
    commit('SET_CLOSE')
    commit('RESET_DATA')
}

export const deleteSingleBanner = function ({commit, dispatch}, payload) {
    commit('DELETE_SINGLE_BANNER', payload)
}

export const addBanner = function ({commit, dispatch}, banner) {
    commit('REMOVE_BANNER', banner.name)
    commit('ADD_BANNER', banner)
}

export const removeBanner = function ({commit, dispatch}, name) {
    commit('REMOVE_BANNER', name)
}

export const setBudgetNotification = function ({commit, dispatch}, payload) {
    commit('SET_BUDGET_NOTIFICATION', payload)
}
