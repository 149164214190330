import { defineComponent } from 'vue'
import AddressSubsiteInterface from '@/io-modules/buildings/interfaces/AddressSubsiteInterface.ts'
import { mapState } from 'vuex'
import ValidateBuildingAddressRequestInterface
    from '@/io-modules/buildings/interfaces/ValidateBuildingAddressRequestInterface.ts'
import { debounce } from 'lodash'
import SaveBuildingFormDataInterface from '@/io-modules/buildings/interfaces/SaveBuildingFormDataInterface.ts'

const addressValidationMixin = defineComponent({
    data () {
        return {
            isAddressChanging: false,
            isLoading: false,
            postFillAddressCallback: null as null | Function,
            clonedProjectAddress1: null as string | null,
            selectedAddress2: null as null | AddressSubsiteInterface,
        }
    },
    computed: {
        ...mapState('common', {
            states: state => state.states,
            countries: state => state.countries,
        }),

        validateAddressRequestData (): ValidateBuildingAddressRequestInterface {
            return {
                country: this.project.project_country,
                state: this.project.project_state_id,
                city: this.project.project_city,
                address: this.project.project_address1,
                zip: this.project.project_zip,
            }
        },

        isAddressSelected (): boolean {
            return !!this.project.project_address1.length
        },
        isAddress2Valid (): boolean {
            if (this.project.project_address2) {
                return this.project.project_address2.length <= 60
            }

            return true
        },
        isCitySelected (): boolean {
            return !!this.project.project_city?.length
        },
        isCountrySelected (): boolean {
            return !!this.project.project_country?.id.length
        },
        isAddresValidationBannerShown (): boolean {
            return !this.isAddressValidationDisabledForCurrentWorkspace && !this.isLoading && this.addressVerified && !this.isAddressChanging
        },
        projectAddressValidationBannerData (): { text: string, icon: string, type: string } {
            if (this.createdWithSameAddressBuildingId) {
                return {
                    text: this.$t('This address corresponds to an existing building, therefore this project will be associated with the {title} building.', { title: this.createdWithSameAddressBuildingTitle || `"${ this.clonedProjectAddress1 }"` }),
                    icon: 'icon-check-circle-2',
                    type: 'success',
                }
            }

            return {
                text: this.$t('This is a new address in our database, therefore we will create a new building associated with this project.'),
                icon: 'icon-info',
                type: 'info',
            }
        },
        projectAddress2FoundInSubsiteList (): AddressSubsiteInterface | null {
            return this.createdWithSameAddressBuildingSubsites.find((subsite: AddressSubsiteInterface) => subsite.name === this.project.project_address2)
        },
        isStateDisabled (): boolean {
            return this.project?.project_country?.id !== 'US'
        },
    },
    watch: {
        addressVerified (): void {
            if (this.addressVerified) {
                this.clonedProjectAddress1 = this.project.project_address1
            }
        },
    },
    mounted (): Promise<void> {
        this.postFillAddressCallback = async (addressData: SaveBuildingFormDataInterface) => {
            this.onChangingAddress()

            await this.$nextTick(async () => {
                this.project.project_state_id = addressData.project_state_id?.id ? addressData.project_state_id : null
                await this.onAddressValueChanged()
            })
        }

        this.initGoogleAddressInput()
    },
    methods: {
        onProjectAddressValueChanged (): void {
            debounce(async () => {
                if (!this.isAddressChanging) {
                    await this.onAddressValueChanged()
                }
            }, 500)()
        },
        async onCountryValueChanged (): Promise<void> {
            if (this.project.project_country.id !== 'US') {
                this.project.project_state_id = null
            }

            await this.onAddressValueChanged()
        },
        initGoogleAddressInput (): void {
            // @ts-ignore
            this.initGoogleAddressSearch(this.project, this.states, 'project_', true, this.countries, 6, 'project_address1', this.postFillAddressCallback)
        },

        onSelectProjectAddress2 (val: AddressSubsiteInterface): void {
            this.project.project_address2 = val.name
            this.project.subsite_id = val.id

            this.selectedAddress2 = val
        },
        onUpdateProjectAddress2 (): void {
            this.setProjectAddress2()
        },
        setProjectAddress2 (): void {
            this.project.subsite_id = this.projectAddress2FoundInSubsiteList?.id || null
            this.selectedAddress2 = this.projectAddress2FoundInSubsiteList || null
        },
    }
})

export default addressValidationMixin
