import { defineComponent } from 'vue'

import { SimpleLazyListTextIconHeader } from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'

export default defineComponent({
    name: 'TextIconCell',
    props: {
        item: {
            type: Object,
            required: true,
        },
        column: {
            type: Object as () => SimpleLazyListTextIconHeader<unknown>,
            required: true,
        },
    },
    computed: {
        icon (): string {
            const icon = this.column.customValue
                ? this.column.customValue(this.item).icon
                : this.column.icon
            return typeof icon === 'function' ? icon(this.item) : icon
        },
        label (): string {
            const label = this.column.customValue
                ? this.column.customValue(this.item).label
                : this.column.label
            return typeof label === 'function' ? label(this.item) : label
        },
        iconColorClass (): string {
            const iconColorClass = this.column.customValue
                ? this.column.customValue(this.item).iconColorClass
                : this.column.iconColorClass
            return typeof iconColorClass === 'function' ? iconColorClass(this.item) : iconColorClass
        },
        iconBackgroundColorClass (): string {
            const iconBackgroundColorClass = this.column.customValue
                ? this.column.customValue(this.item).iconBackgroundColorClass
                : this.column.iconBackgroundColorClass
            return typeof iconBackgroundColorClass === 'function'
                ? iconBackgroundColorClass(this.item)
                : iconBackgroundColorClass
        },
        extraClasses (): string {
            if (typeof this.column.extraClasses === 'function') {
                return this.column.extraClasses(this.item)
            }

            return this.column.extraClasses || ''
        },
    },
})
