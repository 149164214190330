<template>
    <label class="io-workspace-radio-row" :class="{ 'io-selected': isChecked, 'io-disabled': isDisabled }">
        <div class="io-workspace-radio-row__main">
            <input
                type="radio"
                name="contact"
                class="io-workspace-radio-row__input"
                :value="item.shortName"
                :class="`io-workspace-type-${ item.id }`"
                :checked="isChecked"
                v-model="currentValue"
            />
            <div
                class="io-workspace-icon-holder"
                :style="`background-color: ${ item.color }`"
            >
                <img :src="`/new-theme/img/${ item.icon }`" alt="">
            </div>
        </div>
        <div class="io-workspace-radio-row__text">
            <p class="io-name">{{ item.name }}</p>
            <p class="io-description">{{ item.description }}</p>
        </div>
    </label>
</template>
<script lang="ts" src="./SingleWorkspace.ts"></script>
<style lang="scss" src="./SingleWorkspace.scss" scoped></style>
