<template>
    <div
        class="io-stamp-dropdown"
        v-click-outside="closeDropdown"
        :class="{ 'io-active': dropdownExpanded, 'io-editable': isEditable }"
    >
        <div class="io-stamp-dropdown__text" @click="toggleDropdown">
            <div class="io-stamp">
                <div class="io-stamp__icon" :style="{ color: selected.color }">{{ selected.code || 'NA' }}</div>
                <div class="io-stamp__name">
                    <div class="io-name">{{ selected.name }}</div>
                </div>
            </div>

            <div v-if="dropdownExpanded" class="io-stamp__input">
                <i class="fal fa-plus" @click.stop="$refs.stampInput.focus()"></i>
                <input
                    class="io-stamp-dropdown__search"
                    :placeholder="$t('Search...')"
                    ref="stampInput"
                    type="text"
                    v-model="searchValue"
                    @click.stop
                >
            </div>
        </div>

        <div class="io-stamp-dropdown__icon" @click="toggleDropdown">
            <i v-if="selected.id && !dropdownExpanded" class="fal fa-times" @click="deleteStamp"></i>
            <i v-else class="fas fa-angle-down"></i>
        </div>

        <transition name="pill-dropdown-fade">
            <div v-if="dropdownExpanded" class="io-stamp-dropdown__options">
                <div class="io-stamp-dropdown__section">
                    <div
                        v-for="(option, index) in filteredStampsList"
                        class="io-stamp-dropdown__option"
                        :class="{'io-hovering': hoveringOptionIndex === index}"
                        :key="option.id"
                        @click="select(option)"
                        @mouseout="setHoveringOptionIndex(null)"
                        @mouseover="setHoveringOptionIndex(index)"
                    >
                        <div class="io-stamp">
                            <div class="io-stamp__icon" :style="{ color: option.color }">{{ option.code || 'NA' }}</div>
                            <div class="io-stamp__name">
                                <div class="io-name">{{ option.name }}</div>
                            </div>
                            <div class="io-stamp__edit">
                                <i class="fal fa-edit" @click.stop="editStamp(option)"></i>
                            </div>
                        </div>
                    </div>

                    <div class="io-stamp-dropdown__option io-create-new-stamp" @click="createNewStamp">
                        <div class="io-create-new-stamp__content">
                            <i class="fal fa-plus"></i>
                            <span>{{ $t('Create New Stamp') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <CreateNewStampPopup
            v-if="createNewStampPopupShown"
            :editingExistingStamp="editingExistingStamp"
            :editingStampInfo="editingStampInfo"
            :projectId="projectId"
            @close="closeNewStampPopup"
        />
    </div>
</template>

<script lang="ts" src="./StampDropdown.ts"></script>
<style lang="scss" src="./StampDropdown.scss"></style>
