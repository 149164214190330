import { defineComponent } from 'vue'
import buildingsClient from '@/io-modules/buildings/api-clients/buildingsClient.ts'
import NotificationType from '@/interfaces/layout/NotificationType.ts'
import BuildingSubsiteInterface from '@/io-modules/buildings/interfaces/BuildingSubsiteInterface.ts'
import AddressSubsiteInterface from '@/io-modules/buildings/interfaces/AddressSubsiteInterface.ts'
import ValidateBuildingAddressResponseInterface
    from '@/io-modules/buildings/interfaces/ValidateBuildingAddressResponseInterface.ts'
import { mapGetters } from 'vuex'
import { disabledWorkspacesListForBuildingsFeature } from '@/io-modules/buildings/conts/disabledWorkspacesListForBuildingsFeature.ts'

const buildingAddressMixin = defineComponent({
    data () {
        return {
            isChecking: false,
            isAddressChanging: false,
            addressVerified: false,
            createdWithSameAddressBuildingId: null as null | string,
            createdWithSameAddressBuildingTitle: null as null | string,
            createdWithSameAddressBuildingSubsites: [] as [] | AddressSubsiteInterface[],
            createdWithSameAddressBuildingCanBeViewed: false as boolean,
        }
    },
    computed: {
        ...mapGetters('appStore', ['getAuthData']),

        isPossibleToValidateAddress (): boolean {
            return this.isValid && !this.isChecking
        },
        isValid (): boolean {
            return this.isAddressSelected && this.isCitySelected && this.isCountrySelected && this.isAddress2Valid
        },
        isAddressValidationDisabledForCurrentWorkspace (): boolean {
            return disabledWorkspacesListForBuildingsFeature.includes(this.getAuthData?.app_type)
        },
    },
    methods: {
        onChangingAddress (): void {
            this.isAddressChanging = true
        },
        async onAddressValueChanged (): Promise<void> {
            if (this.isAddressValidationDisabledForCurrentWorkspace) {
                this.addressVerified = true

                return
            }

            if (!this.isChecking) {
                this.addressVerified = false
            }

            if (!this.isPossibleToValidateAddress) {
                this.isAddressChanging = false

                return
            }

            this.isChecking = true
            this.isAddressChanging = true

            await this.validateBuildingAddress()
        },

        async validateBuildingAddress (): Promise<void> {
            this.addressVerified = false
            this.isLoading = true

            try {
                const { data } = await buildingsClient.validateBuildingAddress(this.validateAddressRequestData)
                this.addressVerified = true
                this.createdWithSameAddressBuildingId = data.building_id
                this.createdWithSameAddressBuildingTitle = data.title
                this.createdWithSameAddressBuildingCanBeViewed = data.can_view

                this.prepareSubsitesList(data)
            } catch (error) {
                this.showNotification(NotificationType.ERROR, error.response.data.message, error)
            } finally {
                setTimeout(() => {
                    this.isLoading = false
                    this.isChecking = false
                    this.isAddressChanging = false
                }, 500)
            }
        },
        prepareSubsitesList (data: ValidateBuildingAddressResponseInterface): void {
            this.createdWithSameAddressBuildingSubsites = data.subsites.map((subsite: BuildingSubsiteInterface) => {
                return {
                    id: subsite.id,
                    name: subsite.address_2,
                    owner_company_id: subsite.owner_company_id

                }
            })
        }
    }
})

export default buildingAddressMixin
