import { defineComponent, PropType } from 'vue'
import commonContractMixin from '@/io-modules/project-contracts/mixins/commonContractMixin.ts'
import useContractStatus from '@/io-modules/project-contracts/composables/useContractStatus.ts'
import filtersSchema from '@/io-modules/contracts/helpers/filtersSchema.ts'
import { ListContract } from '@/io-modules/contracts/interfaces/ListContract'
import ContractCategory from '@/io-modules/contracts/enums/ContractCategory.ts'
import ContractRouteName from '@/io-modules/project-contracts/enums/ContractRouteName.ts'
import ContractHolderType from '@/io-modules/project-contracts/enums/ContractHolderType.ts'
import ContractAgreementType from '@/io-modules/project-contracts/enums/ContractAgreementType.ts'
import {
    SimpleLazyListHeader,
} from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'
import ContractType from '@/base/ContractType.ts'
import { PillSize, PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'
import type Currency from '@/interfaces/Currency.ts'
import { SimpleLazyListCellType } from '@/io-modules/contracts/components/simple-lazy-list/enums/SimpleLazyListCellType.ts'
import { AvatarType } from '@/constants/AvatarConstants.ts'

import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'
import SimpleLazyList from '@/io-modules/contracts/components/simple-lazy-list/SimpleLazyList.vue'

export default defineComponent({
    name: 'ContractsListTable',
    components: {
        StatusPill,
        IOPlaceholder,
        SimpleLazyList,
    },
    inject: ['searchTerm'],
    props: {
        contractCategory: {
            type: String as PropType<ContractCategory>,
            required: true,
        },
    },
    mixins: [commonContractMixin],
    setup () {
        const contractStatus = useContractStatus()

        return {
            filtersSchema,
            contractStatus,
        }
    },
    computed: {
        currenciesList (): Currency[] {
            return this.$store.state.common.currencies_list
        },
        lazyListProps (): object {
            return {
                endpoint: 'contracts',
                filtersEndpoint: 'contracts/filters',
                key: 'contracts-v3',
                itemRoute: {
                    name: ContractRouteName.ContractDetails,
                    params: { pid: 'project_id', id: 'id' },
                },
            }
        },
        additionalParams (): Record<string, unknown> {
            return {
                category: this.contractCategory,
            }
        },
        columns (): SimpleLazyListHeader<ListContract>[] {
            switch (this.contractCategory) {
            case ContractCategory.MAIN_CONTRACT:
                return this.mainContractsColumns
            case ContractCategory.VENDOR_CONTRACT:
                return this.vendorContractsColumns
            }
        },
        mainContractsColumns (): SimpleLazyListHeader<ListContract>[] {
            return [
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Generated ID'),
                    value: 'generated_id',
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Project Name'),
                    value: 'project_name',
                    getPillText: (data) => data.is_shared ? this.$tc('Shared') : undefined,
                    getPillVariant: (data) => data.is_shared ? PillVariant.BLUE_LIGHT : undefined,
                },
                {
                    valueType: SimpleLazyListCellType.Avatar,
                    text: this.$t('Contract with'),
                    value: 'with',
                    getPillText: (data) => this.getCompanyPillText(data?.with?.workspace_type),
                    getPillVariant: (data) => this.getCompanyPillVariant(data?.with?.workspace_type),
                    customValue: (data) => ({
                        id: data.with?.id,
                        name: data.with?.name,
                        avatarType: AvatarType.COMPANY,
                    }),
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Unique ID'),
                    value: 'unique_id',
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Contract Name'),
                    value: 'name',
                },
                {
                    valueType: SimpleLazyListCellType.TextIcon,
                    text: this.$t('Agreement Type'),
                    value: 'holder',
                    icon: (data) => this.getAgreementTypeIcon(data?.agreement_type),
                    label: (data) => this.getAgreementTypeTitle(data?.agreement_type),
                    extraClasses: (data): string => this.getAgreementTypeExtraClasses(data?.agreement_type) as string,
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Contract Type'),
                    value: 'type',
                    customValue: (item) => {
                        return item.type === ContractType.STANDARD
                            ? this.$t('Single Stage Agreement')
                            : this.$t('Multi-Stage Agreement')
                    },
                },
                {
                    valueType: SimpleLazyListCellType.StatusPill,
                    text: this.$t('Status'),
                    value: 'status',
                    getPillText: (data) => data.status_text,
                    getPillVariant: (data) => this.getContractStatusPill(data.status),
                    getPillSize: PillSize.BIG,
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Contract Value'),
                    value: 'value',
                    customValue: this.formatContractTotalValue,
                    align: 'end',
                    justifyItems: 'end',
                },
            ]
        },
        vendorContractsColumns (): SimpleLazyListHeader<ListContract>[] {
            return [
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Generated ID'),
                    value: 'generated_id',
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Project Name'),
                    value: 'project_name',
                },
                {
                    valueType: SimpleLazyListCellType.Avatar,
                    text: this.$t('Contract with'),
                    value: 'with',
                    getPillText: (data) => this.getCompanyPillText(data?.with?.workspace_type),
                    getPillVariant: (data) => this.getCompanyPillVariant(data?.with?.workspace_type),
                    customValue: (data) => ({
                        id: data.with?.id,
                        name: data.with?.name,
                        avatarType: AvatarType.COMPANY,
                    }),
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Unique ID'),
                    value: 'unique_id',
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Contract Name'),
                    value: 'name',
                },
                {
                    valueType: SimpleLazyListCellType.Holder,
                    text: this.$t('Contract Holder'),
                    value: 'holder',
                    customValue: this.getHolderCustomValueForRow,
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Contract Type'),
                    value: 'type',
                    customValue: (item) => {
                        return item.type === ContractType.STANDARD
                            ? this.$t('Single Stage Agreement')
                            : this.$t('Multi-Stage Agreement')
                    },
                },
                {
                    valueType: SimpleLazyListCellType.StatusPill,
                    text: this.$t('Status'),
                    value: 'status',
                    getPillText: (data) => data.status_text,
                    getPillVariant: (data) => this.getContractStatusPill(data.status),
                    getPillSize: PillSize.BIG,
                },
                {
                    valueType: SimpleLazyListCellType.Text,
                    text: this.$t('Contract Value'),
                    value: 'value',
                    customValue: this.formatContractTotalValue,
                    align: 'end',
                    justifyItems: 'end',
                },
            ]
        },
    },
    methods: {
        getFilterContractStatusColorClass (status: string, key: string): string {
            return 'io-' + this.contractStatus[key]?.variant
        },
        getContractStatusColorClass (status: string): string {
            return 'io-' + this.contractStatus[status]?.variant
        },
        getContractStatusPill (status: string): PillVariant {
            return this.contractStatus[status]?.variant
        },
        getContractStatusText (status: string): string {
            return this.contractStatus[status]?.text
        },
        getAgreementTypeIcon (type: ContractAgreementType): string {
            const item = this.agreementTypeOptions.find(item => item.value === type)

            return item?.icon || ''
        },
        getAgreementTypeTitle (type: ContractAgreementType): string {
            const item = this.agreementTypeOptions.find(item => item.value === type)

            return item?.title || ''
        },
        getAgreementTypeExtraClasses (type: ContractAgreementType): string {
            const mapColorClasses = {
                [ContractAgreementType.AGENCY]: 'io-agency',
                [ContractAgreementType.PRINCIPAL]: 'io-principal',
                [ContractAgreementType.ADVISORY]: 'io-advisory',
                [ContractAgreementType.UNDETERMINED]: 'io-undetermined',
            }

            return mapColorClasses[type] || ''
        },
        getContractHolderTitle (data: ListContract): string {
            const preposition = data.holder?.type === ContractHolderType.AGENCY ? this.$t('with') : this.$t('as')
            const holder = data.holder?.name || '-'

            return holder + ' ' + preposition
        },
        getContractHolderRole (data: ListContract): string {
            return data.holder?.type === ContractHolderType.AGENCY ? this.$t('Agent') : this.$t('Principal')
        },
        pillsColorFunction (value: number): string {
            const option = this.statusOptions.find((option: {
                id: number,
                name: string,
                class: string
            }) => option.id === value)

            return option ? option.class : ''
        },
        formatContractTotalValue (contract: ListContract): string {
            const currency: Currency = this.currenciesList.find((item: Currency) => {
                return item.id === contract.currency
            })

            return this.$filters.formatByProvidedCurrency(contract.value, currency)
        },
    },
})
