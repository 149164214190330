export enum RequestPackagesRoutes {
    MyRequestPackages = 'my-request-packages',
    MyRequestPackagesItem = 'my-request-packages-item',
    MyRequestPackagesItemSummary = 'my-request-packages-item-summary',
    MyRequestPackagesItemSupportingDocuments = 'my-request-packages-item-supporting-documents',
    MyRequestPackagesItemReferences = 'my-request-packages-item-references',
    MyRequestPackagesItemRequests = 'my-request-packages-item-requests',
    MyRequestPackagesItemTimeline = 'my-request-packages-item-timeline',
    ReceivedRequests = 'received-requests',
    ReceivedRequestPackagesItem = 'received-request-packages-item',
    ReceivedRequestPackagesItemSummary = 'received-request-packages-item-summary',
    ReceivedRequestPackagesItemTimeline = 'received-request-packages-item-timeline',
}

export enum ContractChangeItemPaths {
    SUMMARY = 'summary',
    ATTACHED_CONTRACT_CHANGES = 'attached-contract-changes',
    SUPPORTING_DOCUMENTS = 'supporting-documents',
    EXECUTED_AGREEMENT = 'executed-agreement',
    REFERENCES = 'references',
    SOV_WBS = 'sov-wbs',
    APPROVALS = 'approvals',
    TIMELINE = 'timeline',
}

export enum ContractChangeItemRoutesNames {
    ITEM = 'contract-change-item',
    ITEM_SUMMARY = 'contract-change-item-summary',
    ITEM_ATTACHED_CONTRACT_CHANGES = 'contract-change-item-attached-contract-changes',
    ITEM_SUPPORTING_DOCUMENTS = 'contract-change-item-supporting-documents',
    ITEM_EXECUTED_AGREEMENT = 'contract-change-item-executed-agreement',
    ITEM_REFERENCES = 'contract-change-item-references',
    ITEM_SOV_WBS = 'contract-change-item-sov-wbs',
    ITEM_APPROVALS = 'contract-change-item-approvals',
    ITEM_TIMELINE = 'contract-change-item-timeline',
}

export enum ContractChangesCategoryRoutes {
    CONTRACT_CHANGES = 'contract-changes',
    CHANGE_ORDERS = 'change-orders',
    AMENDMENTS = 'amendments',
    MY_CHANGE_ORDERS = 'my-change-orders',
    RECEIVED_CHANGE_ORDERS = 'received-change-orders',
    MY_AMENDMENTS = 'my-amendments',
    RECEIVED_AMENDMENTS = 'received-amendments',
    CONTRACT_CHANGE_REQUESTS = 'contract-change-requests',
    MY_REQUEST_PACKAGES = 'my-request-packages',
    RECEIVED_REQUESTS = 'received-requests',
}
