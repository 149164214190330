import { defineComponent, PropType } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import DropdownButton from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import Approver from '../../interfaces/Approver'
import ApprovalsClient from '@/io-modules/approval-workflows/api-clients/ApprovalsClient.ts'
import { mapState as mapStateVuex } from 'vuex'
import { EligibleUser } from '@/io-modules/approval-workflows/interfaces/EligibleApprovers.ts'
import Workflow from '@/io-modules/approval-workflows/interfaces/Workflow'

export default defineComponent({
    name: 'OverrideApproverModal',
    components: {
        IOModal,
        AlertBox,
        DropdownButton,
    },
    props: {
        approvableResourceId: {
            type: String,
            required: true
        },
        approver: {
            type: Object as PropType<Approver>,
            required: true,
        },
        workflowData: { type: Object as PropType<Workflow>, required: true },
    },
    emits: ['close', 'approverOverridden'],
    data () {
        return {
            selectedUser: null,
            searchUser: '',
            eligibleApprovers: [] as EligibleUser[]
        }
    },
    computed: {
        ...mapStateVuex('project', {
            projectGlobalID: (state: any) => state.projectObj.project_global_id,
        }),

        approversNotInWorkflow (): Approver[] {

        },

        filteredUsers (): Approver[] {
            if (this.searchUser) {
                return this.eligibleApprovers.filter(item => item.full_name.toLowerCase().includes(this.searchUser.toLowerCase()))
            } else {
                return this.eligibleApprovers
            }
        },
    },
    async created () {
        await this.getEligibleApprovers()
    },
    methods: {
        closeModal (): void {
            this.$emit('close')
        },

        async getEligibleApprovers (): Promise<void> {
            try {
                const { data } = await ApprovalsClient.getEligibleApprovers(this.projectGlobalID)
                this.eligibleApprovers = data.flatMap(workspace => workspace.persons).filter(item => item.id !== this.approver.contact_person_id && !this.workflowData?.approvers.some(approver => approver.contact_person_id === item.id ))
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },

        async overrideApprover (): Promise<void> {
            try {
                await ApprovalsClient.overrideApprover(this.approvableResourceId, this.approver.id, this.selectedUser.id)
                this.closeModal()
                this.$emit('approverOverridden')
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },

        selectUser (user: Approver): void {
            this.selectedUser = user
        },
    }
})
