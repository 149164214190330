import { toRaw } from 'vue'
import { cloneDeep } from 'lodash'
import type { BudgetCostCodeCategory, BudgetCostCode } from '@/io-modules/budget/interfaces/SingleBudget.ts'

export const findCostCodeCategory = (categories: BudgetCostCodeCategory[], id: string): BudgetCostCodeCategory | undefined => {
    for (const category of categories) {

        if (category.id === id) {
            return category
        }

        if (category.subcategories) {
            const subcategory = findCostCodeCategory(category.subcategories, id)

            if (subcategory) {
                return subcategory
            }
        }
    }
}

export const removeCostCodeCategory = (categories: BudgetCostCodeCategory[], id: string): BudgetCostCodeCategory[] => {
    return categories.filter(category => {
        if (category.id === id) {
            return false
        }

        if (category.subcategories) {
            category.subcategories = removeCostCodeCategory(category.subcategories, id)
        }

        return true
    })
}

export const deepFilterCategoriesByColumnId = (category: BudgetCostCodeCategory, hiddenColumns: string[]) => {
    const isColumnVisible = column => !hiddenColumns.includes(column.column_id)

    const filterSections = (sections) => sections
        .filter(section => section.budget_columns.some(isColumnVisible))
        .map(section => ({
            ...section,
            budget_columns: section.budget_columns.filter(isColumnVisible),
        }))

    return {
        ...category,
        budget_cost_codes: category.budget_cost_codes.map(costCode => ({
            ...costCode,
            budget_sections: filterSections(costCode.budget_sections),
        })),
        budget_sections: filterSections(category.budget_sections),
        subcategories: category.subcategories?.map((subcategory) => deepFilterCategoriesByColumnId(subcategory, hiddenColumns)),
    }
}

// TODO: fix types
export const deepFilterCategoriesByOption = (category: any, predicate: (option: any) => boolean) => {
    return {
        ...category,
        budget_cost_codes: category.budget_cost_codes?.map(
            costCode => ({
                ...costCode,
                budget_options: costCode.budget_options.filter(predicate),
            })
        ),
        subcategories: category.subcategories?.map(
            (subcategory) => deepFilterCategoriesByOption(subcategory, predicate)
        ),
        budget_options: category.budget_options?.filter(predicate),
    }
}

export const flattenSubcategories = <T extends { subcategories?: T[] }> (category: T): T[] =>
    [category, ...category.subcategories?.flatMap(flattenSubcategories) || []]

export const filterBudgetCostCodesCategories = <T = BudgetCostCodeCategory[]> (categories: T, query: string): T => {
    if (!query) {
        return categories
    }

    const flattenItems = (item: BudgetCostCodeCategory | BudgetCostCode): (BudgetCostCodeCategory | BudgetCostCode)[] =>
        [
            item,
            ...(('subcategories' in item && item.subcategories?.length) ? item.subcategories.flatMap(flattenItems) : []),
            ...(('budget_cost_codes' in item && item.budget_cost_codes?.length) ? item.budget_cost_codes : []),
        ]

    const matchSearch = (str: string): boolean => str.toLowerCase().includes(query.toLowerCase())

    const matchItem = (item: BudgetCostCodeCategory | BudgetCostCode): boolean => {
        const contractorNames = 'budget_contracts' in item
            ? item.budget_contracts.map(({ company_name }) => company_name)
            : []

        return [item.code || '', item.name, ...contractorNames].some(matchSearch)
    }

    const itemId = (item: BudgetCostCodeCategory | BudgetCostCode): string =>
        ('budget_cost_code_id' in item && item.budget_cost_code_id)
        || ('id' in item && item.id)

    const childrenContainMatchedIds = (item: BudgetCostCodeCategory, matchedItemsIds: string[], itemHasCostCodes: boolean): boolean => {
        const includeMatchedId = (id: string): boolean => matchedItemsIds.includes(id)
        const children = itemHasCostCodes
            ? item.budget_cost_codes
            : item.subcategories

        return children
            ?.flatMap(flattenItems)
            .map(itemId)
            .some(includeMatchedId)
    }


    const filterItems = (items: (BudgetCostCodeCategory | BudgetCostCode)[], matchedItemsIds: string[]): BudgetCostCodeCategory[] => items.filter(item => {
        const id = itemId(item)
        const itemIsCostCode = 'budget_cost_code_id' in item
        const itemHasCostCodes = 'budget_cost_codes' in item && item.budget_cost_codes?.length > 0
        const itemHasSubcategories = 'subcategories' in item && item.subcategories?.length > 0
        const itemChildrenKey = itemHasCostCodes && 'budget_cost_codes' || itemHasSubcategories && 'subcategories'
        const childrenHaveMatchedIds = !itemIsCostCode
            && childrenContainMatchedIds(item as BudgetCostCodeCategory, matchedItemsIds, itemHasCostCodes)
        const itemKept = matchedItemsIds.includes(id) || childrenHaveMatchedIds

        childrenHaveMatchedIds
            && itemChildrenKey
            && (item[itemChildrenKey] = filterItems(item[itemChildrenKey], matchedItemsIds))

        return itemKept
    }) as BudgetCostCodeCategory[] // Only categories can be top items

    const matchedItemsIds: string[] = categories
        .flatMap(flattenItems)
        .filter(matchItem)
        .map(itemId)

    const clonedCategories = cloneDeep(toRaw(categories))

    return filterItems(clonedCategories, matchedItemsIds)
}

export const findCostCodesWithOutAnalyticCostCode = (categories: BudgetCostCodeCategory[]): BudgetCostCode[] => {
    const findCostCodes = (category: BudgetCostCodeCategory): BudgetCostCode[] => {
        const costCodes = category.budget_cost_codes.filter(costCode => !costCode.analytic_cost_code_id)

        return [
            ...costCodes,
            ...category.subcategories.flatMap(findCostCodes),
        ]
    }

    return categories.flatMap(findCostCodes)
}

export const findCategoriesWithOutAnalyticCostCode = (categories: BudgetCostCodeCategory[]): string[] => {
    const findCategory = (category: BudgetCostCodeCategory): BudgetCostCodeCategory[] => {
        const costCodes = category.budget_cost_codes.filter(costCode => !costCode.analytic_cost_code_id)

        return [
            ...(costCodes.length ? [category] : []),
            ...category.subcategories.flatMap(findCategory),
        ]
    }

    return categories.flatMap(findCategory).map(category => category.id)
}
