<template>
    <div
        v-if="rendered || forceRender"
        class="io-design-system io-avatar-holder"
        :class="[
            `io-avatar-holder--${ size }`,
            { 'io-avatar-holder--ring': markWithRing },
            { 'io-avatar-holder--external': type === AvatarType.EXTERNAL_COMPANY },
        ]"
        data-testid="avatar-component"
    >
        <div v-tooltip="{ content: tooltip, visible: showTooltip }" class="io-avatar-holder__pic">
            <Initials
                v-if="showEmployeeInitials || forceShowEmployeeInitials"
                v-tooltip="{ content: tooltip, visible: showTooltip }"
                class="io-avatar-holder__photo"
                :fullName="fullName"
                :bg-color="bgColor"
                :initials="initials"
                data-testid="initials-component"
            />

            <img
                v-else
                class="io-avatar-holder__photo"
                :src="photoUrl"
                :alt="fullName"
                data-testid="avatar-image"
                @error="showEmployeeInitials = true"
            />
            <img
                v-if="checkMark"
                src="/new-theme/img/icon-check-with-bg.svg"
                class="io-avatar-holder__check"
                alt="check"
            />
        </div>

        <div v-if="showFullName" class="io-avatar-holder__name">
            <div class="io-avatar-holder__fullname-holder" data-testid="avatar-employee-name">
                <div class="io-avatar-holder__fullname" :title="fullName">
                    {{ fullName }}
                </div>

                <StatusPill
                    v-if="appType"
                    class="io-avatar-holder__app-type"
                    :text="appType"
                    :variant="getAppTypeStatusPillVariant(appType)"
                />
            </div>

            <div
                v-if="companyName"
                class="io-avatar-holder__company io-avatar-holder__fullname"
                data-testid="avatar-company-name"
            >
                {{ companyName }}
            </div>

            <slot />
        </div>

        <slot name="additionalData"/>
    </div>
</template>

<script lang="ts" src="./Avatar.ts"></script>
<style scoped lang="scss" src="./Avatar.scss"></style>
