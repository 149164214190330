<template>
    <div class="io-lazy-grid-item" @click="changeRoute(itemRoute)">
        <div v-if="data.thumbnail && data.thumbnail.length" class="io-lazy-grid-item__thumbnail" :style="`background-image: url(' ${data.thumbnail} ')`"></div>
        <div v-else class="io-lazy-grid-item__thumbnail"></div>


        <div v-if="data.pins_count" class="io-status-pill io-blue">{{ data.pins_count }} {{ $tc('pin | pins', data.pins_count) }}</div>
        <div v-else-if="data.archived" class="io-status-pill io-grey">{{ $t('Archived') }}</div>


        <div class="io-lazy-grid-item__footer">
            <div class="io-lazy-grid-item__footer-left">
                <h4>{{ data.page_label }} {{ data.page_title }}</h4>
                <div>{{ $filters.formatDateOnly(data.updated_at) }}</div>
            </div>

            <div class="io-lazy-grid-item__footer-right">
                <div v-if="data.current_version_number">V: {{ data.current_version_number }}</div>
                <div v-if="data.page_category">{{ $t(data.page_category) }}</div>
                <div v-else>-</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./LazyGridItem.ts"></script>
<style lang="scss" src="./LazyGridItem.scss" scoped />
