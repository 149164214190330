import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import SheetsShow from '@/io-modules/drawings/components/sheets-show/SheetsShow.vue'

import { SelectedAnnotation } from '@/interfaces/modules/projects/drawings/Annotation'
import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'
import { eventBus } from '@/mixins/eventBus'

export default defineComponent({
    name: 'DrawingSheet',
    components: {
        SheetsShow,
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            selectedAnnotation: 'getSelectedAnnotation',
            drawingPin: 'getDrawingPin',
        }),
        ...mapGetters('drawingsV2', {
            activeDrawingSheet: 'getActiveDrawingSheet',
        }),
    },
    watch: {
        selectedAnnotation: {
            handler (newVal: SelectedAnnotation): void {
                if (newVal.uuid) {
                    if (this.drawingPin.xfdf === undefined) {
                        this.showNotification('success', this.$t('Pin added to a drawing'))
                    } else if (this.drawingPin.xfdf !== this.selectedAnnotation.xfdf) {
                        this.showNotification('success', 'Pin changed on a drawing')
                    }

                    const drawingPin = {
                        name: this.activeDrawingSheet.fullName,
                        id: this.activeDrawingSheet.id,
                        version_id: this.activeDrawingSheet.version,
                        xfdf: this.selectedAnnotation.xfdf,
                        uuid: this.selectedAnnotation.uuid,
                    }

                    this.$store.commit('drawingMarkup/SET_DRAWING_PIN', drawingPin)
                    eventBus.$emit('modal/set-reference-sheet', drawingPin)
                    this.cancelDrawingSheet()
                }
            },
        },
    },
    methods: {
        async cancelDrawingSheet (): Promise<void> {
            this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SET', null)
            this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SHEET', {})
            this.$store.commit('drawingsV2/SET_PARENT_FORM_SHOWN', true)
            this.$store.commit('drawingsV2/SET_DRAWING_SHEET_SHOWN', false)
            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                status: false,
                type: RightSideBar.DEFAULT,
            })
            await this.$store.dispatch('modal/setHidden', true)
            this.$store.commit('drawingMarkup/SET_SELECT_SHEET_POPUP', false)
        },

        hideDrawingSheet (): void {
            this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SHEET', {})
            this.$store.commit('drawingsV2/SET_DRAWING_SHEET_SHOWN', false)
            this.$store.commit('drawingsV2/SET_DRAWING_SHEETS_SHOWN', true)
        },
    },
})
