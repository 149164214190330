<template>
    <FilterSection :title="title" class="io-filter-radio-pill">
        <template #default>
            <template v-for="(item, key, index) in preparedOptions" :key="key">
                <Radio
                    v-if="index < maxLength"
                    v-model="radioValue"
                    :labelText="prepareName(item)"
                    :radioValue="key"
                    :inputName="id"
                    :class="`io-${item.toLowerCase()}`"
                />
            </template>
        </template>
    </FilterSection>
</template>

<script>
import FilterSection from './filterSection.vue'
import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue'
export default {
    name: 'FilterRadioPill',
    emit: [ 'update:modelValue' ],
    components: {
        FilterSection,
        Checkbox
    },
    props: {
        maxLength: {
            required: false,
            default: 3
        },
        returnNumber: {
            required: false,
            default: false
        },
        options: {
            required: true,
            type: Object
        },
        pillsColorFunction: {
            required: true,
            type: Function
        },
        title: {
            type: String,
            required: true
        },
        translate: {
            required: false,
            default: false
        },
        modelValue: {
            required: true,
            type: [String, Array]
        },
    },
    computed: {
        radioValue: {
            get () {
                return this.modelValue
            },
            set (val) {
                this.$emit('update:modelValue', this.returnNumber ? parseInt(val) : val)
            }
        },
        id () {
            return this.generateUuid()
        },
        preparedOptions () {
            if (Array.isArray(this.options)) {
                return this.options.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {})
            }
            return this.options
        }
    },
    methods: {
        prepareName (name) {
            if (this.translate) {
                return this.$t(name)
            }
            return name
        }
    }
}
</script>

<style lang="scss">
.io-filter-radio-pill {
    .io-filter-section__content {
        display: flex;
        flex-wrap: wrap;

        .io-atoms-radio {
            margin: 0 8px 8px 0;

            label {
                margin: 0 !important;
                background-color: rgba(149, 152, 165, 0.2);
                color: #9598a5;
                font-weight: 600 !important;
                align-items: center;
                text-transform: uppercase;
                border-radius: 10px;
                padding: 2px 8px;
                justify-content: center;
                height: auto;
                font-size: 10px !important;
                line-height: 16px;
                display: inline-block !important;

                input {
                    display: none;
                }
            }

            &__checked {
                &.io-grey-strong {
                    label {
                        background-color: #7B7E8E;
                        color: #fff;
                    }
                }

                &.io-medium {
                    label {
                        background-color: rgba(237, 178, 8, 0.2);
                        color: #edb208;
                    }
                }

                &.io-low {
                    label {
                        color: #60d094;
                        background-color: rgba(96, 208, 148, 0.2);
                    }
                }

                &.io-high {
                    label {
                        background-color: #EF4343;
                        color: #fff;
                    }
                }
            }
        }
    }
}
</style>
