import * as actions from './actions'
import * as mutations from './mutations'
import defaultState from './state'

export default {
    namespaced: true,
    namespace: 'budgetVersioning/',
    state () {
        return { ...defaultState }
    },
    actions,
    mutations
}
