import i18n from '@/base/i18n'
import FilterInterface from '@/components/ModalFilters/interfaces/FilterInterface.ts'
import FilterType from '@/components/ModalFilters/interfaces/FilterType.ts'

const filtersSchema: FilterInterface[] = [
    {
        field: 'status',
        type: FilterType.Status,
        label: i18n.global.tc('Status'),
        additional: {
            hideSelectAllStatus: true,
        }
    },
    {
        field: 'type',
        type: FilterType.Checkbox,
        label: i18n.global.tc('Contract Type'),
        additional: {
            hideSelectAllCheckboxes: true,
        }
    },
]

export default filtersSchema
