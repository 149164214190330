export default {
    packages: [],
    collapsedPackagesIds: [],
    relevantSpecifications: [],
    activePackageId: '',
    detailsToggled: true,
    fileInfo: {},
    downloadPopupVisible: false,
    copySubmittalData: {},
    copySubmittalId: '',
    openSubmittalData: {},
    creatingNext: false,
    isRequiredForMaterialRelease: true,
    showFilters: false,
    timelineNote: '',
    checkedSubmittalsIds: [],
}
