import { defineComponent } from 'vue'
import { Contact, InviteModalDonePayload } from '@/components/invite-modal-v3/interfaces/InviteModalInterface'
import inviteModalClient from '@/components/invite-modal-v3/api-clients/inviteModalClient'
import {
    SynchronizeInvitationRequestInterface,
    SynchronizeInvitationResponseInterface,
} from '@/components/invite-modal-v3/interfaces/SynchronizeInvitationInterface'
import { AxiosPromise } from 'axios'

export default defineComponent({
    methods: {
        getOnlyIds(contacts: Contact[] = []): Pick<Contact, 'id'>[] {
            return contacts.map((contact: Contact): Pick<Contact, 'id'> => ({ id: contact.id }))
        },
        synchronizeInvitation(
            payload: InviteModalDonePayload,
            invitationName: string,
            projectGlobalId: string = null,
        ): AxiosPromise<SynchronizeInvitationResponseInterface> {
            const requestData: SynchronizeInvitationRequestInterface = {
                invited: this.getOnlyIds(payload.invited),
                bookmarked: this.getOnlyIds(payload.bookmarked),
                resource_id: payload.resource_id,
                resource_type: payload.resource_type,
                project_global_id: payload.project_global_id ?? projectGlobalId,
                invitation_name: invitationName,
            }

            return inviteModalClient.synchronizeContacts(requestData)
        }
    }
})
