<template>
    <div
        v-show="isVisible"
        class="io-file-uploader"
        :class="{'io-dragging-over': isDraggingOver}"
        @drop="onDrop"
        @dragover.prevent
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
    >
        <label class="io-file-uploader__input">
            <input
                :id="'io-file-uploader-input-file' + arrayId"
                type="file"
                :multiple="multipleFiles"
                :accept="acceptedFilesExtensions"
                @change="onFileChange"
            />
            Upload File
        </label>
        <div class="io-file-uploader__content">
            <span class="io-file-uploader__icon icon-cloud-upload"></span>
            <div class="io-file-uploader__text">
                <h3>{{ customTitle ? customTitle : $t('Drop Files Here') }}</h3>
                <p>{{ $t('You can also') }} <span @click="browseFiles()">{{ $t('browse files') }}</span> {{ $t('or') }} <span @click="onUploadFromLibrary()">{{ $t('upload from the library') }}</span></p>
            </div>
        </div>

        <Teleport v-if="enableTeleport && isMounted" :to="`#teleport-actions-${arrayId}`">
            <div class="actions-wrapper">
                <span @click="browseFiles">{{ $t('Browse Files') }}</span>
                <span @click="onUploadFromLibrary">{{ $t('Upload from The Library') }}</span>
            </div>
        </Teleport>
    </div>

    <SelectFilesPopup
        v-if="selectFilesPopupVisible"
        :key="modelId + uniqueId()"
        :modelType="modelType"
        :modelId="clonedModelId"
        :filesSection="filesSection"
        :subSection="subSection"
        :forceShared="forceShared"
        :defaultShare="defaultShare"
        :multipleFiles="multipleFiles"
        :allowedExtensions="validationOptions.allowedExtensions"
        @close="closeSelectFilesPopup()"
        @updateDocuments="updateDocuments"
    />
</template>

<script src="./FileUploader.ts" lang="ts"></script>
<style src="./FileUploader.scss" lang="scss" scoped></style>
