<template>
    <PopupModal v-if="lastActivityModalOpened" class="io-auth-modal">
        <template #header>
            <div class="io-auth-modal__header">
                <span class="icon-alert-triangle io-auth-modal__alert"/>
                <span class="icon-cross io-auth-modal__close"/>
            </div>
        </template>
        <div class="io-auth-modal__content">
            <div class="io-auth-modal__title">{{ $t('Your Session is About to Expire!') }}</div>
            <div class="io-auth-modal__text">
                <div>{{ $t('You will be logged out in') }}
                    <span class="io-auth-modal__timer">{{ sessionRemainingTime }}.</span>
                </div>
                <div>{{ $t('Do you want to stay signed in?') }}</div>
            </div>
        </div>
        <template #footer>
            <IOButton variant="secondary" @click="logout">{{ $t('Log Out') }}</IOButton>
            <IOButton @click="closeModal">{{ $t('Yes, Stay Signed In') }}</IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts">
    import PopupModal from '@/components/popup-modal/PopupModal.vue'
    import isLoggedMixin from '@/mixins/isLoggedMixin'
    import dayjs from 'dayjs'
    import client from '@/api/base/baseClient'

    const sessionRefreshCheckInterval = 1000 * 60 * 5 // 5 minutes

    type TimeValue = {
        time: string | number,
        value: string | number
    }
    export default {
        name: 'authExpModal',
        components: { PopupModal },
        mixins: [isLoggedMixin],
        data () {
            return {
                lastActivityModalOpened: false,
                lastActivityTime: 0,
                maxInactiveTime: 150,
                audioAlert: new Audio(process.env.CDN_URL + 'new-theme/assets/notification_sound_2.mp3'),
                hasPlayedAudio: false,
                expTimeValue: { time: null, value: null } as TimeValue,
                sessionRemainingTime: ''
            }
        },

        mounted () {
            this.maxInactiveTime = parseInt(localStorage.getItem('maxInactiveTime'))
            this.lastActivityTime = parseInt(localStorage.getItem('lastActivityTime'))
            this.lastActivityModalOpened = !!localStorage.getItem('lastActivityModalOpened')

            window.addEventListener('localStorageChange', this.handleStorageEvent)
            window.addEventListener('storage', this.handleStorageEvent)
            setInterval(() => {
                this.checkRemainingTime()
            }, 1000)
            setInterval(() => {
                this.sessionRefresh()
            }, sessionRefreshCheckInterval)
        },

        unmounted () {
            window.removeEventListener('localStorageChange', this.handleStorageEvent)
            window.removeEventListener('storage', this.handleStorageEvent)
        },

        methods: {
            checkRemainingTime (): void {
                const lastActiveSecondAgo = dayjs().unix() - this.lastActivityTime
                const timeToLogout = this.maxInactiveTime - lastActiveSecondAgo

                Object.assign(this.expTimeValue, this.timeToLogOut(timeToLogout))

                // Expired session, just logout
                if (timeToLogout <= 0) {
                    this.logout()
                    return
                }

                // No action needed
                if (timeToLogout > 120) {
                    return
                }

                // Modal already opened
                if (this.lastActivityModalOpened) {
                    if (timeToLogout < 30 && !this.hasPlayedAudio) {
                        this.audioAlert.play()
                        this.hasPlayedAudio = true
                    }
                    return
                }

                // Time to open modal
                this.lastActivityModalOpened = true
                localStorage.setItem('lastActivityModalOpened', this.lastActivityModalOpened)
            },

            /**
             * Check if we need to refresh session
             */
            async sessionRefresh (): void {
                if (localStorage.getItem('isRefreshing')) {
                    return
                }

                const now = dayjs().unix()
                const refreshInterval = parseInt(localStorage.getItem('refreshInterval'))
                const lastRefreshTime = parseInt(localStorage.getItem('lastRefreshTime'))
                if (now - lastRefreshTime < refreshInterval) {
                    return
                }

                localStorage.setItem('isRefreshing', true)
                try {
                    await client.sessionRefresh()
                    localStorage.removeItem('isRefreshing')
                } catch (e) {
                    this.logout()
                }
            },

            handleStorageEvent (e): void {
                if (e.key === 'maxInactiveTime') {
                    this.maxInactiveTime = parseInt(e.newValue)
                } else if (e.key === 'lastActivityTime') {
                    this.lastActivityTime = parseInt(e.newValue)
                } else if (e.key === 'lastActivityModalOpened') {
                    this.lastActivityModalOpened = !!e.newValue
                }
            },

            closeModal () {
                this.lastActivityTime = dayjs().unix().toString()
                localStorage.setItem('lastActivityTime', this.lastActivityTime)
                localStorage.removeItem('lastActivityModalOpened')
                this.lastActivityModalOpened = false
            },

            timeToLogOut (remainingTime: number): TimeValue {
                const minutes = Math.floor(remainingTime / 60)
                const seconds = (remainingTime % 60)

                this.sessionRemainingTime = this.$filters.remainingTime(remainingTime)

                if (minutes > 0) {
                    if (remainingTime >= 90) {
                        return {  time: 2, value: this.$t('Minutes').toLowerCase() }
                    } else {
                        return { time: 1, value: this.$t('Minute').toLowerCase() }
                    }
                } else {
                    if (seconds === 1) {
                        return { time: 1, value: this.$t('second') }
                    } else {
                        return { time: `${ seconds }`, value: this.$t('Seconds').toLowerCase() }
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .io-auth-modal {
        :deep(.io-popup-modal__wrapper) {
            width: 536px;
        }
        &__header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__alert {
            color: var(--warning-darker);
            font-size: 20px;

        }

        &__close {
            font-size: 20px;
            color: var(--main-lighten-4);
        }
        &__content {
            padding-left: 32px;
        }
        &__title {
            font-size: 16px;
            font-weight: 600;
            color: var(--main-lighten-1);
            margin-bottom: 24px;
        }
        &__text {
            font-size: 14px;
            font-weight: 500;
            color: var(--main-lighten-2);
            line-height: 24px;
        }
        &__timer {
            font-weight: 600;
            color: var(--accent);
        }
    }
</style>
