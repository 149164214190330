import { cloneDeep } from 'lodash'
import { JobsiteLocationsInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsInterface.ts'
import { JobsiteLocationsTreeInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsTreeInterface.ts'
import JobsiteLocationStatusEnum from '@/components/jobsite-locations/enums/JobsiteLocationStatusEnum.ts'

export function buildSelectedLocationsPaths (selectedJobsiteLocationsIds: string[], jobsiteLocations: JobsiteLocationsInterface[]): JobsiteLocationsInterface[][] {
    return selectedJobsiteLocationsIds.map((locationId: string) => getSelectedLocationPath(cloneDeep(jobsiteLocations), locationId))
}

export function getSelectedLocationPath (tree: JobsiteLocationsInterface[], id: string, path: JobsiteLocationsInterface[] = []): JobsiteLocationsInterface[] {
    return tree.reduce((acc: JobsiteLocationsInterface[], jobsiteLocation: JobsiteLocationsInterface) => {
        if (acc) {
            return acc
        }
        if (jobsiteLocation.id === id) {
            return [...path, jobsiteLocation]
        }

        return getSelectedLocationPath(jobsiteLocation.children, id, [...path, jobsiteLocation])
    }, null)
}

export function buildSelectedLocationsGroupTree (paths: [JobsiteLocationsInterface[]]): JobsiteLocationsTreeInterface[] {
    const preparedTree: JobsiteLocationsTreeInterface[] = []

    paths.forEach((groupJobsiteLocation: JobsiteLocationsInterface[]) => {
        if (!groupJobsiteLocation) {
            return
        }

        const parent = cloneDeep(groupJobsiteLocation[0])

        if (!parent) {
            return
        }

        const parentExists = preparedTree.some((location: JobsiteLocationsTreeInterface) => location.id === parent.id)
        const children = groupJobsiteLocation.filter((location: JobsiteLocationsInterface) => parent.id !== location.id)

        if (!parentExists) {
            parent.children = children.length ? [children] : []
            preparedTree.push(parent)
        } else {
            const existingParent = preparedTree.find((location: JobsiteLocationsTreeInterface) => location.id === parent.id)

            if (existingParent) {
                existingParent.children = existingParent.children || []
                existingParent.children.push(children)
            }
        }
    })

    return preparedTree
}
export function isLocationArchived (location: JobsiteLocationsInterface): boolean {
    return location.status === JobsiteLocationStatusEnum.ARCHIVED
}

export function buildTreeWithSearchResults(tree: JobsiteLocationsInterface[], searchValue: string, selectedJobsiteLocationsIds: string[], checkStatus: boolean ): JobsiteLocationsInterface[] {
    const results = []

    for (const node of tree) {
        const childrenResults = node.children
            ? buildTreeWithSearchResults(node.children, searchValue, selectedJobsiteLocationsIds, checkStatus)
            : []

        const containsNameFragment = node.title.toLowerCase().includes(searchValue.toLowerCase())

        if (containsNameFragment && (!checkStatus || !isLocationArchived(node))) {
            results.push({
                ...node,
                children: childrenResults.length > 0 ? childrenResults : []
            })
        } else if (selectedJobsiteLocationsIds.includes(node.id) ||
            (checkStatus && childrenResults.some(child => isLocationArchived(child) && selectedJobsiteLocationsIds.includes(child.id)))) {
            results.push({
                ...node,
                children: childrenResults
            })
        } else if (childrenResults.length > 0) {
            results.push({
                ...node,
                children: childrenResults
            })
        }
    }

    return results
}
