export enum LienWaiverStatus {
    NotReceived = 'not_received',
    NoLienWaiver = 'no_lien_waiver',
    Draft = 'draft',
    RequestSent = 'request_sent',
    Sent = 'sent',
    Requested = 'requested',
    Attached = 'attached',
    NotAttached = 'not_attached',
    PendingReview = 'pending_review',
    RevisionRequested = 'revision_requested',
    ApprovedConditional = 'approved_conditional',
    ApprovedUnconditional = 'approved_unconditional',
    AttachedConditional = 'attached_conditional',
    AttachedUnconditional = 'attached_unconditional',
    ReceivedConditional = 'received_conditional',
    ReceivedUnconditional = 'received_unconditional',
}

export default LienWaiverStatus
