import { defineComponent, PropType } from 'vue'
import BarChartItem from './interfaces/BarChartItem'

export default defineComponent ({
    name: 'HorizontalBarChart',
    props: {
        items: {
            type: Array as PropType<BarChartItem[]>,
            default: () => [],
        },
    },
    methods: {
        getItemWidth (item: BarChartItem): string {
            return `${item.value}%`
        },

        getItemColor (item: BarChartItem): string {
            return `io-${item.color}`
        },
    }
})