import { defineComponent } from 'vue'
import { invoiceViewStore } from '../../../../stores/invoiceView'
import { mapState } from 'pinia'

export default defineComponent({
    name: 'LienWaiversHeader',
    emits: ['openAttachModal'],
    computed: {
        ...mapState(invoiceViewStore, ['isMyInvoice', 'isEditingMode']),
    }
})