import moment from 'moment'

const holidayMixin = {
    data () {
        return {
            colorSchemeMap: {
                'basic': 'hlc-item hlc-basic',
                'custom': 'hlc-item hlc-custom',
                'blackout': 'hlc-item hlc-blackout'
            }
        }
    },
    methods: {
        setHolidayAttribute (date, name) {
            date = moment(date).format('YYYY-MM-DD')
            return this.getHoliday(date, name)
        },
        getHoliday (date, name) {
            let self = this
            let attr = ''
            if (self.holidays) {
                self.holidays.forEach(function (item, index) {
                    if (item.date === date && attr === '') {
                        attr = item[name]
                        // set color class of holiday
                        if (name === 'type') {
                            attr = self.colorSchemeMap[attr]
                        }
                    }
                })
            }
            return attr
        },
        setColorScheme (data) {
            let styles = '.hlc-basic, .hlc-basic * { background-color: ' + data.basic + '!important; }'
            styles += '.hlc-custom, .hlc-custom * { background-color: ' + data.custom + '!important; }'
            styles += '.hlc-blackout, .hlc-blackout * { background-color: ' + data.blackout + '!important; }'
            this.$nextTick(() => {
                if (document.getElementById('holidaysColorScheme')) {
                    document.getElementById('holidaysColorScheme').remove()
                }
                let style = document.createElement('style')
                style.type = 'text/css'
                style.rel = 'stylesheet'
                style.id = 'holidaysColorScheme'
                style.appendChild(document.createTextNode(styles))
                document.getElementsByTagName('head')[0].appendChild(style)
            })
        }
    }
}

export default holidayMixin
