const CompanyList = () => import(/* webpackChunkName: "company" */ './list/CompanyList.vue')
const EditCompany = () => import(/* webpackChunkName: "company" */ './views/edit/EditCompany.vue')
const CompanyDetails = () => import(/* webpackChunkName: "company" */ './details/CompanyDetails.vue')

export default [
    {
        path: '/company/list',
        name: 'company-list',
        component: CompanyList,
        meta: {
            schema: 'company',
            title: 'Company List'
        }
    },
    {
        path: '/company/edit/:id',
        name: 'company-edit',
        component: EditCompany,
        meta: {
            title: 'Edit Company'
        }
    },
    {
        path: '/company/view/:id',
        name: 'company-details',
        component: CompanyDetails,
        meta: {
            title: 'Company Details'
        }
    }
]
