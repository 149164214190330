export enum StatusStyle {
    RedLight = 'red-light',
    BlueLight = 'blue-light',
    YellowLight = 'yellow-light',
    GrayLight = 'gray-light',
    GreenLight = 'green-light',
    OrangeLight ='orange-light',
    PurpleLight = 'purple-light',
    GoldLight = 'gold-light',
    Info = 'info',
    Closed = 'closed'
}
