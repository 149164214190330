import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import SheetsIndex from '@/io-modules/drawings/components/sheets-index/SheetsIndex.vue'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'

export default defineComponent({
    name: 'DrawingSheets',
    components: {
        SheetsIndex,
    },
    data () {
        return {
            isSheetsV2View: true,
        }
    },
    computed: {
        ...mapGetters('drawingsV2', {
            currentDrawingsView: 'getCurrentView',
        }),
    },
    methods: {
        async cancelDrawingSheets (): Promise<void> {
            this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SET', null)
            this.$store.commit('drawingsV2/SET_PARENT_FORM_SHOWN', true)
            this.$store.commit('drawingsV2/SET_DRAWING_SHEETS_SHOWN', false)
            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                status: false,
                type: RightSideBar.DEFAULT,
            })
            await this.$store.dispatch('modal/setHidden', true)
            this.$store.commit('drawingMarkup/SET_SELECT_SHEET_POPUP', false)
        },
        hideDrawingSheets (): void {
            this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SET', null)
            this.$store.commit('drawingsV2/SET_DRAWING_SHEETS_SHOWN', false)
            this.$store.commit('drawingsV2/SET_DRAWING_SETS_SHOWN', true)
        },
    },
})
