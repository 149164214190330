import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import { FilterInterface } from '@/interfaces/components/lazy-list/FilterInterface'
import { mapGetters as mapGettersVuex } from 'vuex'
import { ScheduleTemplatesLazyListInterface } from '@/io-modules/lists-templates/schedule-templates/interfaces/ScheduleTemplatesLazyListInterface'

const scheduleTemplatesLazyListMixin = defineComponent({
    data () {
        return {
            endpoint: 'schedule-tickets-templates-lazy-list',
            itemRoute: {
                name: null
            },
            readyToRender: false,
        }
    },
    computed: {
        ...mapGettersVuex('lazyList', [
            'isFetching'
        ]),
        filtersSchema (): FilterInterface[] {
            return []
        },
        columns ():  ColumnInterface[] {
            return [
                {
                    field: 'name',
                    label: this.$t('List Name'),
                    type: 'text',
                    columnClass: 'io-column-name',
                },
                {
                    field: 'tasks_count',
                    label: this.$t('# Activities'),
                    type: 'text',
                    columnClass: 'io-column-items-count',
                },
                {
                    field: 'milestones_count',
                    label: this.$t('# Milestones'),
                    type: 'text',
                    columnClass: 'io-column-items-count',
                },
                {
                    field: 'updated',
                    label: this.$t('Update date'),
                    type: 'date',
                    columnClass: 'io-column-date',
                },

                {
                    field: 'creator',
                    label: this.$t('Created By'),
                    type: 'row',
                    columnClass: 'io-column-originator',
                    additional: {
                        img: 'id',
                        header: 'name',
                        avatarType: 'avatar'
                    }
                },
                {
                    field: 'can_edit',
                    label: '',
                    type: 'icon-action',
                    columnClass: 'io-column-action-clone aright',
                    additional: {
                        iconName: 'icon-copy',
                        sortHidden: true,
                    },
                },
                {
                    field: 'can_edit',
                    label: '',
                    type: 'icon-action',
                    columnClass: 'io-column-action-trash aright',
                    additional: {
                        iconName: 'icon-trash',
                        sortHidden: true,
                    },
                },
            ]
        },
    },

    watch: {
        async isFetching (newValue: boolean, oldValue: boolean): Promise<void> {
            if (!this.readyToRender && !newValue && oldValue) {
                this.setLoadingBar(false)
                await this.resetLazyList()
            }
        },
    },

    async created (): Promise<void> {
        await this.isFetching
            ? this.setLoadingBar(true)
            : await this.resetLazyList()
    },
    methods: {
        async resetLazyList (): Promise<void> {
            await this.$store.dispatch('lazyList/resetEndpointParams')
            await this.$store.dispatch('lazyList/setWithCounters', false)
            await this.$store.dispatch('lazyList/setWithFilters', true)
            await this.$store.dispatch('lazyList/resetState')

            this.readyToRender = true
        },
        async emitIconAction (data: { field: string, id: string }): Promise<void> {
            switch (data.field) {
            case 'clone':
                await this.onCloneScheduleList(data.id)
                break
            case 'delete':
                await this.onDeleteScheduleList(data.id)
                break
            }
        },
        async onCloneScheduleList (id: string): Promise<void> {
        },
        async onDeleteScheduleList (id: string): Promise<void> {
        },

        async onClickScheduleList (list: ScheduleTemplatesLazyListInterface): Promise<void> {
            await this.$router.push({ name: 'lists-templates-schedule-templates-view', params: { id: list.id } })
        },
    }
})

export default scheduleTemplatesLazyListMixin
