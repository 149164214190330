import ModalFilters from '@/components/ModalFilters/ModalFilters'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import FilterDateTimePicker from '@/components/ModalFilters/filters/filterDateTimePicker.vue'
import MultiselectCheckbox from '@/components/multiselect-checkbox/MultiselectCheckbox'
import FilterCheckboxes from '@/components/ModalFilters/filters/filterCheckboxes.vue'
import FilterStatuses from '@/components/ModalFilters/filters/filterStatuses.vue'
import FilterColorDot from '@/components/ModalFilters/filters/filterColorDot.vue'
import MultiselectCheckboxInputData from '@/components/multiselect-checkbox/interfaces/MultiselectCheckboxInputData'
import FilterInterface from '@/components/ModalFilters/interfaces/FilterInterface'
import invoicesFiltersDefaultValues from './helpers/invoicesFiltersDefaultValues'
import appPackagesFiltersDefaultValues from './helpers/appPackagesFiltersDefaultValues'
import InvoicesTab from '../../../enums/InvoicesTab'
import InvoiceRouteName from '../../../enums/InvoiceRouteName'
import ListFilters from '../../../interfaces/invoices-list/ListFilters'
import FilterType from '@/components/ModalFilters/interfaces/FilterType'
import modalMixin from '@/mixins/modalMixin'
import statusColorsMixin from '../../../mixins/StatusColorsMixin'
import ListType from '../../../enums/ListType'
import FiltersMapper from '@/io-modules/invoices/mappers/FiltersMapper'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { mapActions as mapActionsVuex, mapState as mapStateVuex } from 'vuex'
import { mapActions, mapState } from 'pinia'
import { MultiselectOptionsInterface } from '@/interfaces/components/custom-multiselect/MultiselectOptionsInterface'
import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import GetInvoicesListFiltersStrategy from '@/io-modules/invoices/strategies/GetInvoicesListFiltersStrategy'
import listTypeName from '../../../helpers/listTypeName'
import filtersOptions from './helpers/filtersOptions.ts'
import invoicesFiltersSchema from './helpers/invoicesFiltersSchema'
import appPackagesFiltersSchema from './helpers/appPackagesFiltersSchema'
import appPackagesFiltersSchemaV3 from './helpers/appPackagesFiltersSchemaV3.ts'
import InvoiceHeader from '@/io-modules/invoices/enums/InvoiceHeader'
import { isBoolean } from 'lodash'

export default defineComponent({
    name: 'ListsFilterModal',
    components: {
        ModalFilters,
        AlertBox,
        FilterDateTimePicker,
        MultiselectCheckbox,
        FilterCheckboxes,
        FilterStatuses,
        FilterColorDot,
    },
    mixins: [modalMixin, statusColorsMixin],
    emits: ['save-filters'],
    data () {
        return {
            initialFilters: {} as ListFilters,
            filters: {} as ListFilters,
            datePickerOptions: {
                formatted: 'MM/DD/YYYY',
                range: true,
                placeholder: this.$t('Select Date Range'),
                'only-date': true
            },
            multiselectOptions:  {
                textPlaceholder: '',
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: false
            } as MultiselectOptionsInterface,
            filterOptions: {
                ball_in_court: [],
                vendor: [],
            },
            type: [] as MultiselectCheckboxInputData<string>[],
            invoice_status: [] as MultiselectCheckboxInputData<string>[],
            lien_waiver_status: [] as MultiselectCheckboxInputData<string>[],
            payment_status: [] as MultiselectCheckboxInputData<string>[],
            billing_date: [] as MultiselectCheckboxInputData<string>[],
            filtersSchema: [],
            FilterType,
            optionsFromBE: null
        }
    },
    computed: {
        ...mapState(invoicesStore, ['getInvoicesListHeaders', 'availableFilters']),

        ...mapStateVuex('project', ['projectObj']),

        showFilters (): boolean {
            const showFilter = this.showModal === 'modal-filters'
            showFilter
                ? document.documentElement.style.overflow = 'hidden'
                : document.documentElement.style.overflow = 'auto'

            return showFilter
        },

        countFilters (): number {
            const initialValue = 0
            const filters = {}
            this.filtersSchema.map(item => {
                if (item.visible || this.isAppPackagesList) {
                    filters[item.field] = this.filters[item.field]
                }
            })

            return Object.values(filters).reduce((accumulator: number, item) =>  accumulator + this.calculateCountFilter(item), initialValue)
        },

        defaultFiltersValues (): ListFilters {
            if (this.isAppPackagesList) {
                return appPackagesFiltersDefaultValues
            }

            if (this.$route.meta.listType !== InvoicesTab.My && this.$route.name !== InvoiceRouteName.InvoicesReceivedVendorDetails) {
                return { ...invoicesFiltersDefaultValues, vendor: [] }
            }

            return invoicesFiltersDefaultValues
        },

        filtersList (): FilterInterface[] {
            if (this.isAppPackagesList) {
                return this.filtersSchema
            }

            return this.filtersSchema.filter(item => item.visible).sort((a, b) => a.order - b.order)
        },

        infoText (): TranslateResult {
            return this.$t('These filters affect only the {name} tab.', { name: this.$t(this.tabName(this.filtersType)) })
        },

        filtersType (): InvoicesTab {
            return this.$route.meta.listType
        },

        isAppPackagesList (): boolean {
            return InvoiceRouteName.InvoicesApplicationPackagesList === this.$route.name
        },

        listHeaders (): HeaderTable[] {
            return this.getInvoicesListHeaders(this.filtersType)
        },

        listType (): ListType {
            return listTypeName(this.filtersType)
        },
    },
    watch: {
        countFilters (value: number): void {
            this.setFiltersCounter(value)
        },

        showFilters (value: boolean): void {
            if (value) {
                this.setupFiltersSchema()
                if (Object.keys(this.filters).length) {
                    this.initialFilters = { ...this.filters }
                }
            }
        },

        availableFilters: {
            handler (): void {
                this.setupFilters()
            },
            deep: true,
            immediate: false
        },

        listHeaders (): void {
            this.setupFiltersSchema()
        },

        defaultFiltersValues (): void {
            this.setupFiltersSchema()
        },
    },
    methods: {
        ...mapActionsVuex('filtersModal', ['setFiltersCounter']),

        ...mapActions(invoicesStore, ['matchFiltersWithColumnsSettings', 'setFilters', 'setProjectHasMainContractExecuted', 'setContentLoaderValue']),

        setupFilters (): void {
            this.getFilterData()
            this.setupFiltersSchema()
            this.setFiltersCounter(this.countFilters)
            this.applyFilters()
        },

        setupFiltersSchema (): void {
            const schema = this.adaptFiltersSchema(this.optionsFromBE, filtersOptions)
            this.filtersSchema = this.matchFiltersWithColumnsSettings(schema, this.filtersType)
        },

        adaptFiltersSchema (filterData: ListFilters[], filtersSchema: FilterInterface[]): FilterInterface[]  {
            if (!filterData) {
                return
            }

            const updatedFilters = filtersSchema.filter(filter =>
                filter.field in filterData && (!Array.isArray(filterData[filter.field]) || filterData[filter.field].length > 0)
            ).map(filter => {
                if (Array.isArray(filterData[filter.field]) && filterData[filter.field].length > 0) {
                    return {
                        ...filter,
                        customOptions: filterData[filter.field].map(option => ({
                            name: option.name,
                            id: option.status
                        }))
                    }
                } else {
                    return filter
                }
            })

            return updatedFilters.filter(filter => filter.field !== 'ball_in_court')
        },

        getFilterData (): void {
            if (this.projectObj.project_local_id && this.availableFilters) {
                const savedFilters = JSON.parse(localStorage.getItem(`${ this.filtersType }FiltersV3`))

                if (savedFilters) {
                    this.filters = { ...this.defaultFiltersValues, ...savedFilters }
                } else {
                    this.filters = { ...this.defaultFiltersValues }
                }

                this.optionsFromBE = this.availableFilters
                if (isBoolean(this.availableFilters.has_executed_main_contract)) {
                    this.setProjectHasMainContractExecuted(this.availableFilters.has_executed_main_contract)
                }

                const vendors = this.availableFilters.vendor
                const selectedVendors = {}
                if (this.filters.vendor && this.filters.vendor.length && vendors) {
                    const existingVendors = vendors.map(vendor => vendor.id)
                    this.filters.vendor = this.filters.vendor.filter(item => existingVendors.includes(item.id))

                    this.filters.vendor.forEach(item => {
                        selectedVendors[item.id] = item
                    })
                }

                let allVendors = []
                if (vendors) {
                    allVendors = vendors.map(item => {
                        return { ...item, checked: !!selectedVendors[item.id] }
                    })
                }

                const ballInCourt = this.availableFilters.ball_in_court
                const selectedBallInCourt = {}
                if (this.filters.ball_in_court && this.filters.ball_in_court.length && ballInCourt) {
                    const existingBallInCourt = ballInCourt.map(vendor => vendor.id)
                    this.filters.ball_in_court = this.filters.ball_in_court.filter(item => existingBallInCourt.includes(item.id))

                    this.filters.ball_in_court.forEach(item => {
                        selectedVendors[item.id] = item
                    })
                }
                const allBallInCourt = this.availableFilters.ball_in_court && this.availableFilters.ball_in_court.map(item => {
                    return { ...item, checked: !!selectedBallInCourt[item.id] }
                }) || []

                this.filterOptions =
                    {
                        ball_in_court: allBallInCourt,
                        vendor: allVendors
                    }

                this.updateFilters()
                this.initialFilters = { ...this.filters }
            }
        },

        updateFilters (): void {
            const filters = Object.fromEntries(
                Object.entries(this.filters).filter(
                    ([key, val]) => this.calculateCountFilter(val))
                )

            this.setFilters(this.listType, FiltersMapper.toListingParams(filters))
        },

        applyFilters (): void {
            this.filtersSchema.map(item => {
                if (!item.visible) {
                    this.filters[item.field] = this.defaultFiltersValues[item.field]
                }
            })
            this.initialFilters = { ...this.filters }
        },

        saveFilters (): void {
            this.$emit('save-filters', Object.fromEntries(
                Object.entries(this.filters).filter(
                    ([key, val]) => this.calculateCountFilter(val))
                )
            )
        },

        calculateCountFilter (item: string | Object | Array<any>): number {
            if(Array.isArray(item)) {
                return item.filter(item => item).length
            }
            if (item instanceof Object) {
                return Object.values(item).some(item => !!item) ? 1 : 0
            }
            if (item) {
                return 1
            }

            return 0
        },

        clearFilters (): void {
            this.filters = { ...this.defaultFiltersValues }
            this.applyFilters()
        },

        clearChanges (): void {
            this.filters = { ...this.initialFilters }
        },

        pillsColorFunction (_: string, key: string): string {
            if (key && this.isAppPackagesList) {
                return `io-${ this.getApplicationPackageStatusColor(key) }`
            }

            return `io-${ this.getInvoiceStatusColor(key) }`
        },

        colorDotFunction (_: string, key: string, field: string): string {
            return {
                lien_waiver_status: `io-${ this.getLienWaiverStatusColor(key) }`,
                payment_status: `io-${ this.getPaymentStatusColor(key) }`,
                billing_date: `io-${ this.getBillingDateStatusColor(key) }`,
            }[field]
        },
    }
})
