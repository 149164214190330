import SingleFile from '../single-file-new/single-file.vue'
import SingleFolder from '../single-folder/single-folder.vue'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isEmpty, get, cloneDeep, uniqueId } from 'lodash'
import FileUploaderGridItem from '../file-uploader-grid-item/FileUploaderGridItem.vue'
import filesMixin from '@/components/files-new/mixins/filesMixin.ts'
import UploadingGridItem from '../uploading-grid-item/UploadingGridItem.vue'
import UploadingListItem from '../uploading-list-item/UploadingListItem.vue'
import { FilesUploaderVariantEnum } from '../../enums/FilesUploaderVariantEnum.ts'

export default {
    name: 'tabFiles',
    components: {
        FileUploaderGridItem,
        SingleFile,
        SingleFolder,
        UploadingGridItem,
        UploadingListItem,
    },
    props: {
        toSync: {
            type: Boolean,
            required: true,
            default: false
        },
        fromTrash: {
            type: Boolean,
            required: true,
            default: false
        },
        arrayId: {
            type: String,
            required: true
        },
        searchKeyword: {
            type: String,
            required: false,
            default: ''
        },
        tabType: {
            type: String,
            required: true
        },
        addToTemporary: {
            type: Boolean,
            required: false
        },
        filesInNewTab: {
            type: Boolean,
            required: false,
            default: false
        },
        showShared: {
            type: Boolean,
            required: false
        },
        hideShareColumn: {
            type: Boolean,
            required: false
        },
        forceShared: {
            type: Boolean,
            required: false
        },
        modelId: {
            type: [String, Number],
            required: false
        },
        filesSection: {
            type: Array,
            required: false
        },
        modelType: {
            type: String,
            required: true
        },
        isProjectView: {
            type: Boolean,
            required: false,
            default: false
        },
        lockHotkeys: {
            type: Boolean,
            required: false,
            default: false
        },
        isDocumentsView: {
            type: Boolean,
            required: false,
            default: false
        },
        isGridView: {
            type: Boolean,
            required: false,
            default: true
        },
        editable: {
            type: Boolean,
            required: true
        },
        subSection: {
            type: Array,
            required: false
        },
        colorVariantLight: {
            type: Boolean,
            required: false,
            default: false
        },
        showNewFolderForm: {
            type: Boolean,
            require: false,
            default: false
        },
        applySorting: {
            type: Boolean,
            require: false,
            default: true
        },
        editableActions: {
            type: Array,
            required: false,
            default: () => []
        },
        showModalVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        showImportantToggle: {
            type: Boolean,
            required: false,
            default: true
        },
        multipleFiles: {
            type: Boolean,
            required: false,
            default: true
        },
        noFilesFiltering: {
            type: Boolean,
            required: false,
            default: false
        },
        variant: {
            type: String,
            default: FilesUploaderVariantEnum.NORMAL
        },
    },
    mixins: [isLoggedMixin, filesMixin],
    data () {
        return {
            sortBy: 'file_name',
            sortDir: 'asc',
            filteredFiles: [],
            sortedFiles: [],
            breadCrumbFolderKey: 'breadcrumb_folder',
            newFolder: {},
            isEmpty,
            mainCheckbox: false,
            selectionBoxStart: { x: 0, y: 0 },
            selectionBoxEnd: { x: 0, y: 0 },
            isSelecting: false,
            isDraggingOver: false,
        }
    },
    computed: {
        ...mapState('filesComponent', {
            customColumns: state => state.customColumns
        }),
        isSkinnyVariant () {
            return this.variant === FilesUploaderVariantEnum.SKINNY
        },
        authData: function () {
            let data = this.getAuthData
            if (data) {
                this.loaded = true
            }
            return data
        },
        allFilesSelected () {
            return this.getSelectedFilesForActions?.length === this.sortedArray.length
        },
        partialSelectedCheck () {
            if (this.getSelectedFilesForActions?.length === 0) {
                this.mainCheckbox = false
            }
            return this.getSelectedFilesForActions?.length > 0 && this.getSelectedFilesForActions?.length < this.sortedArray?.length
        },
        isCheckboxVisible () {
            return this.isSkinnyVariant ? this.my_documents_files?.length : true
        },
        my_documents_files () {
            let files = []
            if (this.toSync) {
                files = this.$store.getters['filesComponent/getSharedFiles'](this.arrayId)
            } else if (this.fromTrash) {
                files = this.$store.getters['filesComponent/getTrashedFiles'](this.arrayId)
            } else {
                files = this.$store.getters['filesComponent/getMyFiles'](this.arrayId)
            }
            if (this.noFilesFiltering) {
                return files
            }
            if (this.filesSection && !this.subSection) {
                return files.filter(item => get(item, 'section', null) === this.filesSection)
            } else if (this.filesSection && this.subSection) {
                return files.filter(
                    item =>
                        (item.section || null) === this.filesSection &&
                        ((item.section_uuid || null) === this.subSection || item.folder_mongo_id)
                )
            } else if (!this.isDocumentsView) {
                return files.filter(item => get(item, 'section', 'default') === 'default')
            }
            return files
        },
        foldersBreadcrumbs () {
            return this.$store.getters['filesComponent/getFoldersBreadcrumbs']
        },
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        foldersSearchPaths () {
            return this.$store.getters['filesComponent/getFoldersPaths']
        },
        searchPathVal () {
            return this.$store.getters['filesComponent/getSearchVal']
        },
        isSearching () {
            return this.$store.getters['filesComponent/getSearchState']
        },
        showFiles () {
            return (this.folderFlags && this.folderFlags.files_in_folder_allowed) || (this.sortedArray && this.sortedArray.length) || (this.uploadingFiles && this.uploadingFiles.length)
        },
        isFolderView () {
            return !isEmpty(this.folderFlags) ? !this.folderFlags.files_in_folder_allowed : false
        },
        filteredRequests () {
            if (!this.my_documents_files && !this.my_documents_files.length) {
                return []
            }
            let searchArrTemp = this.my_documents_files.filter(request => {
                if (!request) {
                    return false
                }
                return request.file_name.toLowerCase().includes(this.searchKeyword.toLowerCase())
            })
            return searchArrTemp
        },
        sortedArray () {
            if (this.filteredRequests && this.filteredRequests.length) {
                if (!this.applySorting) {
                    return this.filteredRequests
                }
                // sort
                if (!this.sortBy || this.sortBy === 'file_name') {
                    if (this.sortDir === 'asc') {
                        return this.filteredRequests.sort((a, b) => a.file_name.toLowerCase() > b.file_name.toLowerCase() ? 1 : -1)
                    }
                    return this.filteredRequests.sort((a, b) => a.file_name.toLowerCase() > b.file_name.toLowerCase() ? -1 : 1)
                } else if (this.sortBy === 'file_size') {
                    if (this.sortDir === 'asc') {
                        return this.filteredRequests.sort((a, b) => a.file_size > b.file_size ? 1 : -1)
                    }
                    return this.filteredRequests.sort((a, b) => a.file_size > b.file_size ? -1 : 1)
                } else if (this.sortBy === 'creator_full_name') {
                    if (this.sortDir === 'asc') {
                        return this.filteredRequests.sort((a, b) => (a.creator_full_name.toLowerCase() > b.creator_full_name.toLowerCase()) ? 1 : -1)
                    }
                    return this.filteredRequests.sort((a, b) => (a.creator_full_name.toLowerCase() > b.creator_full_name.toLowerCase()) ? -1 : 1)
                } else if (this.sortBy === 'updated_at') {
                    if (this.sortDir === 'asc') {
                        return this.filteredRequests.sort((a, b) => new Date(a.updated_at) > new Date(b.updated_at) ? 1 : -1)
                    }
                    return this.filteredRequests.sort((a, b) => new Date(a.updated_at) > new Date(b.updated_at) ? -1 : 1)
                } else if (this.sortBy === 'can_be_shared') {
                    if (this.sortDir === 'asc') {
                        return this.filteredRequests.sort((a, b) => (a.can_be_shared > b.can_be_shared) ? 1 : -1)
                    }
                    return this.filteredRequests.sort((a, b) => (a.can_be_shared > b.can_be_shared) ? -1 : 1)
                } else {
                    if (this.sortDir === 'asc') {
                        return this.filteredRequests.sort((a, b) => (a[this.sortBy].toString().toLowerCase() > b[this.sortBy].toString().toLowerCase()) ? 1 : -1)
                    }
                    return this.filteredRequests.sort((a, b) => (a[this.sortBy].toString().toLowerCase() > b[this.sortBy].toString().toLowerCase()) ? -1 : 1)
                }
            }
            return []
        },
        folders () {
            return this.$store.getters['filesComponent/getFolders'](this.arrayId)
        },
        filteredFolders () {
            if (!this.folders && !this.folders.length) {
                return []
            }
            let searchArrTemp = this.folders.filter(folder => {
                return folder.name ? folder.name.toLowerCase().includes(this.searchKeyword.toLowerCase()) : false
            })
            return searchArrTemp
        },
        sortedFolders () {
            if (this.filteredFolders && this.filteredFolders.length) {
                if (!this.applySorting) {
                    return this.filteredFolders
                }

                if (this.sortBy !== 'file_name') {
                    return this.filteredFolders
                }
                // sort folders
                if (this.sortDir === 'asc') {
                    return this.filteredFolders.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
                }
                return this.filteredFolders.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1)
            }
            return []
        },
        hasFiles () {
            return !!(this.sortedArray && this.sortedArray.length)
        },
        hasFilesToSync () {
            return !!(this.sortedArray.find((file) => file.can_be_shared))
        },
        lockBreadcrumbs () {
            return this.folderFlags && (this.folderFlags.folder_type === 'root' || (this.folderFlags.model_type === 'main_project_folder' && this.$route.name !== 'documents-list'))
        },
        uploadingFiles () {
            return this.$store.getters['filesComponent/getUploadingFilesInProgress'](this.arrayId)
        },
        showListViewTable () {
            return this.uploadingFiles.length || this.sortedFolders.length || this.sortedArray.length
        },
        selectionBoxStyles () {
            const width = Math.abs(this.selectionBoxEnd.x - this.selectionBoxStart.x)
            const height = Math.abs(this.selectionBoxEnd.y - this.selectionBoxStart.y)
            const left = Math.min(this.selectionBoxStart.x, this.selectionBoxEnd.x)
            const top = Math.min(this.selectionBoxStart.y, this.selectionBoxEnd.y)
            return {
                width: `${ width }px`,
                height: `${ height }px`,
                left: `${ left }px`,
                top: `${ top }px`,
            }
        },
        uploadLocked () {
            return !this.multipleFiles && this.sortedArray && this.sortedArray.length
        }
    },
    methods: {
        changeSort (val) {
            if (val === this.sortBy) {
                if (this.sortDir === 'asc') {
                    this.sortDir = 'dsc'
                } else {
                    this.sortDir = 'asc'
                }
            }
            this.sortBy = val
            this.applySorting = true
        },
        onDataRefresh () {
            this.$forceUpdate()
            this.$emit('forceRefresh')
        },
        onTempRemove (fileId) {
            this.$forceUpdate()
            this.$emit('tempFileRemove', fileId)
        },
        onRemove (fileId) {
            this.$forceUpdate()
            this.$emit('fileRemove', fileId)
        },
        clearSearch () {
            this.$emit('clearSearch')
        },
        async handleSyncAll () {
            this.showPopupAlert({
                title: this.$t('Sync All files'),
                caption: this.$t('Do you want to sync all files?'),
                icon: 'icon-alert-triangle',
                buttons: [
                    {
                        text: this.$t('No'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Yes'),
                        action: this.syncAll,
                    },
                ],
            })
        },
        async syncAll () {
            const filesToSync = []
            this.sortedArray.forEach((file) => {
                if (file.can_be_shared) {
                    filesToSync.push(file._id)
                }
            })
            await this.syncFile(filesToSync)
        },
        async syncFile (filesToSync) {
            let modelIdTemp = this.modelId || this.$route.params.id
            try {
                await this.$api.post(`/copy-shared-document-all/${ this.modelType }/${ modelIdTemp }`, { params: { files: filesToSync } })
                this.sortedArray.forEach((file) => {
                    file._is_on_sync_queue = true
                })
                this.showNotification('success', this.$t('Processing file'))
                this.onDataRefresh()
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
        syncStatusUpdate (data) {
            const syncedFile = this.sortedArray.find(file => {
                return file._id === data.fileId
            })

            if (syncedFile !== undefined) {
                if (data.status === 'success') {
                    // move file to My Files Tab
                    let toMyFiles = cloneDeep(syncedFile)
                    toMyFiles._shared_to_my_document_id = false
                    toMyFiles._id = data.fileCopyId
                    toMyFiles.can_be_shared = true
                    toMyFiles._can_delete = true
                    toMyFiles._can_rename = true

                    this.$store.dispatch('filesComponent/addMyDoc', {
                        arrayId: this.arrayId,
                        file: toMyFiles
                    })

                    // update file on Shared with me Tab
                    syncedFile._is_on_sync_queue = false
                    syncedFile._shared_to_my_document_id = true
                    syncedFile.can_be_shared = false
                } else if (data.status === 'failed') {
                    syncedFile.sync_failed = true
                } else if (data.status === 'already-synced') {
                    syncedFile.can_be_shared = false
                }
            }
        },
        async onSelectFilesRange (idx) {
            if (!this.isGridView) {
                return
            }
            let start = 0
            let end = 0

            if (this.getLastSelectedForAction > idx) {
                start = idx
                end = this.getLastSelectedForAction
            } else {
                start = this.getLastSelectedForAction
                end = idx
            }

            await this.removeAllSelectedFilesForAction()

            for (let i = start; i<=end; i++) {
                await this.selectFileForAction({ file: this.sortedArray[i], arrayId: this.arrayId })
            }
        },
        handleMouseDown (event) {
            if (!this.isGridView) {
                return
            }
            this.selectionBoxStart.x = event.clientX
            this.selectionBoxStart.y = event.clientY
            this.selectionBoxEnd.x = event.clientX
            this.selectionBoxEnd.y = event.clientY
            this.isSelecting = true
            if (!event.ctrlKey) {
                this.removeAllSelectedFilesForAction(this.arrayId)
            }
            window.addEventListener('mouseup', this.handleGlobalMouseUp)
        },
        handleMouseMove (event) {
            if (!this.isGridView) {
                return
            }
            if (this.isSelecting) {
                this.selectionBoxEnd.x = event.clientX
                this.selectionBoxEnd.y = event.clientY

                // Calculate selected items
                const selectedRect = this.getSelectionRectangle()
                this.calculateSelectedItems(selectedRect)
            }
        },
        handleMouseUp () {
            if (!this.isGridView) {
                return
            }
            this.isSelecting = false
            window.removeEventListener('mouseup', this.handleGlobalMouseUp())
        },
        handleGlobalMouseUp () {
            if (!this.isGridView) {
                return
            }
            this.isSelecting = false
        },
        getSelectionRectangle () {
            if (!this.isGridView) {
                return
            }
            const left = Math.min(this.selectionBoxStart.x, this.selectionBoxEnd.x)
            const top = Math.min(this.selectionBoxStart.y, this.selectionBoxEnd.y)
            const width = Math.abs(this.selectionBoxEnd.x - this.selectionBoxStart.x)
            const height = Math.abs(this.selectionBoxEnd.y - this.selectionBoxStart.y)
            return { left, top, width, height }
        },
        calculateSelectedItems (selectedRect) {
            if (!this.isGridView) {
                return
            }
            return this.sortedArray.forEach((item, index) => {
                const itemRect = this.getItemRectangle(index)
                if (this.rectanglesIntersect(selectedRect, itemRect)) {
                    this.selectFileForAction({ file: this.sortedArray[index], arrayId: this.arrayId })
                } else {
                    this.removeSelectedFileForAction({ file: this.sortedArray[index], arrayId: this.arrayId })
                }
            })
        },
        getItemRectangle (index) {
            if (!this.isGridView) {
                return
            }
            const element = this.$refs[`item-${ index }`][0].$refs[`item-html-${ index }`]
            const rect = element.getBoundingClientRect()
            return {
                left: rect.left,
                top: rect.top,
                width: rect.width,
                height: rect.height,
            }
        },
        rectanglesIntersect (rect1, rect2) {
            if (!this.isGridView) {
                return
            }
            return (
                rect1.left < rect2.left + rect2.width &&
                rect1.left + rect1.width > rect2.left &&
                rect1.top < rect2.top + rect2.height &&
                rect1.top + rect1.height > rect2.top
            )
        },
        onDragEnter (event) {
            event.preventDefault()
            this.isDraggingOver = true
        },
        onDragLeave (event) {
            event.preventDefault()
            this.isDraggingOver = false
        },
        onDrop (event) {
            event.preventDefault()
            this.isDraggingOver = false
            this.$emit('onDrop', event)
        },
        onHideAllDropdowns () {
            if (this.sortedArray && this.sortedArray.length) {
                for (let i = 0; i < this.sortedArray.length; i++) {
                    this.$refs[`item-${ i }`][0].hideMenu()
                }
            }
        }
    },
    watch: {
        files () {
            this.onDataRefresh()
        },
        'foldersBreadcrumbs' () {
            this.breadCrumbFolderKey = uniqueId()
        },
        allFilesSelected (val) {
            if (val) {
                this.mainCheckbox = true
            }

            if (!this.getSelectedFilesForActions?.length) {
                this.mainCheckbox = false
            }
        },
        partialSelectedCheck (val) {
            this.mainCheckbox = val
        },
        mainCheckbox (val) {
            if (this.partialSelectedCheck && !val || !this.partialSelectedCheck && !val) {
                this.removeAllSelectedFilesForAction(this.arrayId)
            }

            if (!this.partialSelectedCheck && val) {
                this.sortedArray.forEach((file) => {
                    this.selectFileForAction({ file, arrayId: this.arrayId })
                })
            }
        },
        getSelectedFilesForActions (val) {
            if (val.length === 0) {
                this.mainCheckbox = false
            }
        }
    },
    created () {
        this.$nextTick(() => {
            this.$echo.private('WorkspaceChannel.' + this.authData.company_mongo_id)
                .listen('Document.Events.ModelShareDocumentEvent', this.syncStatusUpdate)
        })
    },
    unmounted () {
        window.removeEventListener('mouseup', this.handleGlobalMouseUp)
        this.$echo.private('WorkspaceChannel.' + this.authData.company_mongo_id)
            .stopListening('Document.Events.ModelShareDocumentEvent')
    }
}
