<template>
    <td class="io-cell-company">
        <div v-if="elementExist" class="io-f-row">
            <div
                v-if="imageExist"
                v-tooltip="{ content: tooltipValue, visible: !!tooltipValue?.length }"
                class="io-company-logo"
                :class="{ 'io-company-logo--small': small, 'io-marked-with-ring': isMarkedWithRing }"
                :style="getAvatarId()">
            </div>
            <initials
                v-else
                v-tooltip="{ content: tooltipValue, visible: !!tooltipValue?.length }"
                class="io-company-logo"
                :class="{ 'io-company-logo--small': small,  'io-marked-with-ring': isMarkedWithRing, 'io-offsystem-logo': isOffSystemOrArchived }"
                :full-name="getHeader()"
            />

            <div class="io-company-data">
                <template v-if="isHeaderHtml">
                    <span v-html="getHeader()"></span>
                </template>
                <template v-else>
                    <span>{{ getHeader() }}</span>
                </template>

                <span class="io-subheader">{{ getSubHeader() }}</span>
            </div>

            <StatusPill
                v-if="hasAdditionalPill"
                :variant="additionalPillVariant"
                :text="additionalPillText"
            />
        </div>
        <div v-else>-</div>
    </td>
</template>

<script lang="ts" src="./ImageWithNameTableCell.ts"></script>

<style lang="scss" src="./ImageWithNameTableCell.scss" scoped/>
