import IOButton from '@/components/atoms/IOButton/IOButton'
import LazyList from '@/components/lazy-list/LazyList.vue'
import PopupModal from '@/components/popup-modal/PopupModal'
import purchaseOrdersClient from '@/api/project-purchase-orders/purchaseOrdersClient'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import { defineComponent } from 'vue'
import { FilterInterface } from '@/interfaces/components/lazy-list/FilterInterface'
import { mapGetters } from 'vuex'
import { PurchaseOrder } from '@/interfaces/modules/projects/purchase-orders/PurchaseOrderInterface'
import { TypeActionReview } from '@/interfaces/modules/projects/lien-waiver'

export default defineComponent({
    name: 'PurchaseOrdersPopupComponent',
    components: {
        PopupModal,
        IOButton,
        LazyList
    },
    props: {
        typeConfirmation: { type: String, required: true },
    },
    data () {
        return {
            isLoading: false,
            searchLocal: '',
            purchaseOrder: {} as PurchaseOrder,
            disabledSelectButton: true,
            columns: [
                {
                    field: 'id',
                    label: '',
                    type: 'radio',
                    columnClass: 'aright',
                },
                {
                    field: 'generated_id',
                    label: 'ID',
                    columnClass: 'aleft',
                    type: 'text',
                },
                {
                    field: 'name',
                    label: 'Purchase Order Name',
                    columnClass: 'aleft',
                    type: 'text',
                    additional: {
                        additionalHTMLFunction: this.getIconActionText,
                    },
                },
                {
                    field: 'amount',
                    label: 'Amount',
                    type: 'text',
                    columnClass: 'aright',
                    filter: 'formatCurrency',
                },
                {
                    field: 'billed_against_to_date',
                    label: 'Billed Against',
                    type: 'text',
                    columnClass: 'aright',
                    filter: 'formatCurrency',
                },
            ] as ColumnInterface[],
            filtersSchema: [] as FilterInterface[],
        }
    },

    computed: {
        ...mapGetters('project', ['projectCompanyMongoId']),
        ...mapGetters('payapps', ['getPayAppContractId', 'isNonContractedInvoice']),
        isRequiredNote (): boolean {
            return TypeActionReview.RequestRevision === this.typeConfirmation
        },
        getEndpointWithParams (): string {
            return purchaseOrdersClient.lazyListEndpoint
                + `?project_company_id=${ this.projectCompanyMongoId }&contract_id=${ this.getPayAppContractId ?? '' }`
        }
    },

    watch: {
        searchLocal (newVal: string): void {
            this.$store.dispatch('lazyList/setConfig', {
                endpoint: this.getEndpointWithParams,
                method: 'get',
                dataPropertyName: 'data'
            })
            this.$store.dispatch('lazyList/setSearch', newVal)
        }
    },

    methods: {
        onCreateNewPurchaseOrder (): void {
            this.$router.push({ name: 'purchase-order-create' })
        },

        close (response: boolean = false): void {
            this.$emit('close', response)
        },

        getIconActionText (data: PurchaseOrder): string {
            const href = this.$router.resolve({
                name: 'project-purchase-order',
                params: { 'id': data.id }
            }).href

            return `<a class="icon-color" href="${ href }" target="_blank"><i class="icon-external-link"></i></a>`
        },

        radioClick (value: PurchaseOrder): void {
            this.disabledSelectButton = !value

            if (value) {
                this.purchaseOrder = value
            }
        },

        async onSelect (): Promise<void> {
            try {
                this.isLoading = true
                const { data } = await purchaseOrdersClient.associatePurchaseOrder(
                    this.purchaseOrder.id,
                    this.$route.params.id
                )

                this.showNotification('success', data.message)
                this.$emit('selected', this.purchaseOrder)
                this.close()
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.isLoading = false
            }
        }
    },
})
