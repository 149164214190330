export enum CellType {
    Text = 'text',
    Image = 'image',
    Html = 'html',
    StatusPill = 'status-pill',
    StatusDot = 'status-dot',
    Avatar = 'avatar',
    Actions = 'actions',
    Icon = 'icon',
    Input = 'input',
    Select = 'select',
    InputNum = 'input-num',
    InputNumInt = 'input-num-int',
    Dropdown = 'dropdown',
    TextStatusPill = 'text-status-pill',
    CostCodesDropdown = 'cost-codes-dropdown',
}

export default CellType
