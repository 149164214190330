<template>
    <IOButton
        class="io-collapse-expand-button"
        :class="{'io-collapse-expand-button--collapse': isAbleToCollapseSoV}"
        variant="link"
        icon="icon-chevrons-down"
        @click="toggleExpandSov"
    >
        {{ isAbleToCollapseSoV ? $t('Collapse All') : $t('Expand All') }}
    </IOButton>
</template>

<script lang="ts" src="./CollapseExpandButton.ts"></script>
<style lang="scss" src="./CollapseExpandButton.scss" scoped></style>
