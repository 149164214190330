import { defineComponent } from 'vue'
import SvgIconComponent from './svg-icon-component/SvgIconComponent.vue'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import { AnnotationData } from '@/io-modules/documents/interfaces/AnnotationData'
import { mapState } from 'pinia'
import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'SingleMarkup',
    components: {
        DropdownButtonNew,
        SvgIconComponent
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        data: {
            type: Object as () => AnnotationData,
            required: true
        }
    },
    data () {
        return {
            svgIconTypes: ['ARROW', 'ARC', 'LINE', 'POLYLINE', 'POLYGON', 'ELLIPSE', 'CLOUD', 'TEXT', 'RECTANGLE']
        }
    },
    computed: {
        ...mapState(viewDocumentStore, [
            'selectedAnnotationId'
        ]),
        isSelected (): boolean {
            return this.selectedAnnotationId === this.data.uuid
        },
        getToolIcon (): string {
            const mappedIcons = {
                MEASUREMENT: 'icon-ruler',
                STAMP: 'icon-image-3'
            }

            return mappedIcons[this.data?.tool_name] || null
        },
        headerLabelText (): TranslateResult {
            const mappedTools = {
                MEASUREMENT: this.$t('Measurement No:'),
                TEXT: this.$t('Text No:'),
            }

            return mappedTools[this.data?.tool_name] || this.$t('Shape No:')
        }
    },
    methods: {
        openSharingSettingsPopup (): void {
            return
        },
        onDeleteMarkup (): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to delete this annotation?'),
                caption: this.$t('This action cannot be undone.'),
                icon: 'icon-trash',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Confirm'),
                        class: 'io-btn-primary',
                        action: () => this.deleteBasicAnnotation()
                    }
                ]
            })
        },
        deleteBasicAnnotation (): void {
            this.$emit('delete-basic-annotation', { document_id: this.data.document_id, annotation_id: this.data.id })
        },
        selectMarkup (): void {
            this.$emit('select-annotation', this.data.uuid)
        },
        updateAnnotations (): void {
            this.$emit('update-annotations')
        },
    },
})
