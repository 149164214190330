import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType'
import BudgetTypeLabel from '../../../interfaces/modules/projects/modules/common/budget/LabelBudgetType'
import { cloneDeep, isArray, isObject } from 'lodash'

const budgetConvertableFields = [
    'budget_projected',
    'budget_current',
    'budget_pending',
    'budget_original',
    'budget_approved',
    '_approved_contract_changes',
    '_balance_to_complete',
    '_budget_approved',
    '_budget_current',
    '_budget_original',
    '_budget_pending',
    '_budget_projected',
    '_current_application',
    '_current_retention_amount',
    '_material_stored_offsite',
    '_material_stored_retention_amount',
    '_net_balance_to_complete_amount',
    '_net_current_application',
    '_net_previous_amount',
    '_net_total_completed_stored_amount',
    '_original_contract_amount',
    '_over_under_budget',
    '_paid_amount',
    '_paid_percent',
    '_pending_contract_amount',
    '_pending_contract_changes',
    '_pending_non_contacted_cost',
    '_pending_payments',
    '_previous_application',
    '_previous_retention_amount',
    '_total_anticipated_cost',
    '_total_committed_cost',
    '_total_completed_stored_amount',
    '_total_retention_amount',
    '_total_uncommitted_cost',
    '_unpaid_amount'
]

const baseCurrencyId = 'base-currency'

export function getProjectCostCodesTemplates (state, getters) {
    const templates = []
    state.project_cost_codes_lists.forEach(list => {
        list.templates.forEach(template => {
            templates.push(template)
        })
    })
    return templates
}

export function getCostCodesOwnerOptions (state) {
    const options = []
    state.project_cost_codes_list?.categories.forEach((category) => {
        const codes = category.codes.filter(code => !code.$isDisabled)
        if (codes.length) {
            let newOption = cloneDeep(category)
            newOption.codes = codes
            options.push(newOption)
        }
    })
    return options
}

export function getBudgetLineItemTemplates (state) {
    const templates = []
    state.budget_line_item_lists.forEach(list => {
        list.templates.forEach(template => {
            templates.push(template)
        })
    })
    return templates
}

export function isOurBudget (state) {
    return (!state.companySharedId || state.companySharedId === state.companyId)
}

export function isBudgetLoaded (state) {
    return !!state.loaded
}

export function sharedBudgetCompanyName (state) {
    const budget = state.sharedBudgets.find(budget => {
        return budget.company_id === state.companySharedId
    })
    return budget ? budget.company_name : ''
}

export function getBidQuestionsTemplates (state) {
    const templates = []
    state.bid_questions_lists.forEach(list => {
        list.templates.forEach(template => {
            templates.push(template)
        })
    })
    return templates
}

export function getQuotedItemLinesListSettings (state) {
    return (listId) => {
        return state.quoted_item_lines_lists_settings.find(settings => {
            return settings.list_id === listId
        })
    }
}

export function isItemExpanded (state) {
    return (itemId) => {
        return state.expandedItems.includes(itemId)
    }
}

export function getQuotedItemLinesListTemplates (state) {
    return (listId) => {
        return state.quoted_item_lines_templates.filter(template => {
            return template.list_id === listId
        })
    }
}

export function getSelectedCurrencyRates (state) {
    let selectedRates = []

    state.conversionsTemplates.forEach(template => {
        if (!Array.isArray(template.currencies_pairs)) {
            return
        }

        const pairs = template.currencies_pairs.filter(pair => {
            return state.currencySelectedRates.includes(pair.id)
        }).map(pair => {
            pair = cloneDeep(pair)
            if (pair.source !== state.currencyBase) {
                pair.target = pair.source
                pair.rate = 1 / pair.rate
            }
            pair.template_name = template.name
            return pair
        })
        if (pairs.length) {
            selectedRates = [...selectedRates, ...pairs]
        }
    })

    return selectedRates
}


export function getCurrencyRatesForSelection (state, getters) {
    return [{
        id: baseCurrencyId,
        source: state.currencyBase,
        target: state.currencyBase,
        rate: 1,
        template_name: null
    }, ...getters.getSelectedCurrencyRates]
}

/**
 * @returns CostCodeCategoryConverted[]
 */
export function getItemsMulticurrency (state) {
    if (!state.currencySelectedConversion) {
        return state.items
    }

    return convertItems(state.items, state.currencySelectedConversion.rate)
}

export function getSnapshotItemsMulticurrency (state, getters, rootState, rootGetters) {
    const items = rootGetters['financialApprovals/phaseSnapshot']?.items || []

    if (!state.currencySelectedConversion) {
        return items
    }

    return convertItems(items, state.currencySelectedConversion.rate)
}


export function getTotalsMulticurrency (state) {
    if (!state.currencySelectedConversion) {
        return state.totals
    }

    return convertTotals(state.totals, state.currencySelectedConversion.rate)
}

export function getSnapshotTotalsMulticurrency (state, getters, rootState, rootGetters) {
    const totals = rootGetters['financialApprovals/phaseSnapshot']?.totals || []

    if (!state.currencySelectedConversion) {
        return totals
    }

    return convertTotals(totals, state.currencySelectedConversion.rate)
}


export function getChangesMulticurrency (state) {
    if (!state.currencySelectedConversion) {
        return state.changes
    }

    const changes = cloneDeep(state.changes)
    changes.forEach(change => {
        if (isArray(change.lines)) {
            change.lines.forEach(line => {
                line.amount *= state.currencySelectedConversion.rate
                line.fromBudget *= state.currencySelectedConversion.rate
                line.toBudget *= state.currencySelectedConversion.rate
            })
        }
    })
    return changes
}

export function getProjectMongoMulticurrency (state) {
    if (!state.currencySelectedConversion) {
        return state.projectMongoLocal
    }

    const projectMongoLocal = cloneDeep(state.projectMongoLocal)
    budgetItemConvertMulticurrency(projectMongoLocal.budget, state.currencySelectedConversion.rate)
    return projectMongoLocal
}

export function isBudgetApprovedAsFinal (state, getters) {
    return getters.getCurrentBudget?.has_approved_final_phase
}

export function isBudgetChangedSinceLastPhase (state, getters) {
    return getters.getCurrentBudget?.is_changed_since_last_phase
}

export function getAttachedFundingSourcesMulticurrency (state) {
    if (!state.currencySelectedConversion) {
        return state.attachedFundingSources
    }

    const rate = state.currencySelectedConversion.rate * process.env.SCALE_FACTOR
    const sources = cloneDeep(state.attachedFundingSources)
    sources.forEach(source => {
        source.contributed_amount *= rate
        source.covered_amount *= rate
        source.remaining_to_cover_amount *= rate
        source.remaining_to_pay_amount *= rate
    })
    return sources
}

export function getSnapshotAttachedFundingSourcesMulticurrency (state, getters, rootState, rootGetters) {
    const sources = rootGetters['financialApprovals/phaseSnapshot']?.sources || []

    if (!state.currencySelectedConversion) {
        return sources
    }

    const rate = state.currencySelectedConversion.rate * process.env.SCALE_FACTOR

    return sources.map(source => ({
        contributed_amount: source.contributed_amount * rate,
        covered_amount: source.covered_amount * rate,
        remaining_to_cover_amount: source.remaining_to_cover_amount * rate,
        remaining_to_pay_amount: source.remaining_to_pay_amount * rate
    }))
}

export function getAttachedFundingSourcesContributedSum (state, getters) {
    let sum = 0
    getters.getAttachedFundingSourcesMulticurrency.forEach(source => {
        sum += Number(source.contributed_amount) || 0
    })
    return sum
}

export function getSnapshotAttachedFundingSourcesContributedSum (state, getters) {
    return getters
        .getSnapshotAttachedFundingSourcesMulticurrency
        .reduce((sum, source) => sum + Number(source.contributed_amount) || 0, 0)
}

function budgetItemConvertMulticurrency (item, rate) {
    budgetConvertableFields.forEach(field => {
        if (typeof item[field] !== 'undefined') {
            item[field] *= rate
        }
    })

    if (isArray(item.elements)) {
        item.elements.forEach(element => {
            budgetItemConvertMulticurrency(element, rate)
        })
    }
    if (isObject(item.bids)) {
        for (let objectKey in item.bids) {
            budgetItemConvertMulticurrency(item.bids[objectKey], rate)
        }
    }
}

export function isBaseCurrencySelected (state) {
    return !state.currencySelectedConversion || state.currencySelectedConversion.id === baseCurrencyId
}

export function isMyBudget (state) {
    return state.budgetType === BudgetType.MY_BUDGET
}

export function isOwnerBudget (state) {
    return state.budgetType === BudgetType.OWNER_BUDGET
}

export function idBudget (state) {
    return state.projectMongoLocal?.[state.budgetType]?._id
}

export function getAllBudgetIds (state) {
    return [BudgetType.MY_BUDGET, BudgetType.OWNER_BUDGET]
        .map(budgetType => state.projectMongoLocal?.[budgetType]?._id)
        .filter(id => id !== undefined)
}

export function mainContract (state) {
    return state.budgetType === BudgetType.MY_BUDGET
        ? state.projectMongoLocal?.mainContract
        : null
}

export function budgetTypeValue (state) {
    return state.budgetType
}

export function budgetIconTitleColumn (state) {
    return state.budgetIconTitleColumn
}

export function isEmptybudgetIconTitleColumn (state) {
    return state.budgetIconTitleColumn.title === null
}

export function isMirrorBudgetOwnerSelected (state) {
    return state.budgetIconTitleColumn.title === BudgetTypeLabel.OWNER_BUDGET
}

export function getActiveInsuranceRequirementTemplates (state) {
    if (!state.insuranceRequirementsTemplates) {
        return []
    }
    return state.insuranceRequirementsTemplates.filter(template => !template.is_archived)
}

export function isNewestPhaseRejected (state, getters) {
    return !!getters.getCurrentBudget?.is_newest_phase_rejected
}

export function canSubmitForPhase (state, getters) {
    return !!getters.getCurrentBudget?.can_submit_for_phase
}

export function isPhaseApprovalInProgress (state, getters) {
    return !!getters.getCurrentBudget?.is_phase_approval_in_progress
}

export function hasPendingPhase (state, getters) {
    return !!getters.getCurrentBudget?.has_pending_phase
}

export function hasApprovedFinalPhase (state, getters) {
    return !!getters.getCurrentBudget?.has_approved_final_phase
}

export function isPhaseSubmittedToClient (state, getters) {
    return !!getters.getCurrentBudget?.is_phase_submitted_to_client
}

export function isApprovedNotByPhase (state, getters) {
    return !!getters.getCurrentBudget?.is_approved_not_by_phase
}

export function getCurrentBudget (state) {
    return null === state.budgetTypeSharedSelected ? state.projectMongoLocal[state.budgetType] : state.projectMongoLocal.budget
}

function convertItems (items, rate) {
    const convertedItems = cloneDeep(items)

    convertedItems.forEach(item => {
        budgetItemConvertMulticurrency(item, rate)
    })

    return convertedItems
}

function convertTotals (totals, rate) {
    const totalsConverted = cloneDeep(totals)

    budgetItemConvertMulticurrency(totalsConverted, rate)

    return totalsConverted
}

export function getAccountingIntegrations (state) {
    return state.integrations.filter(int => int.type === 'Accounting')
}
