<template>
    <div>
        <template v-for="(filterSection, index) in filterSections" :key="index">
            <section v-if="filterSection.collapsible" class="filter-section">
                <h4
                    class="filter-section__header"
                    :class="{'filter-section__header--collapse': filterSection.collapse}"
                    @click="filterSection.collapse = !filterSection.collapse"
                >
                    {{ $t(filterSection.label) }}
                </h4>

                <FilterSectionCollapse
                    :filterSchema="filterSection.filters"
                    class="filter-section__content"
                    :collapse="filterSection.collapse"
                />
            </section>
            <section v-else class="filter-section">
                <FilterSectionCollapse
                    :filter-schema="filterSection.filters"
                    class="filter-section__content"
                />
            </section>
        </template>
    </div>
</template>

<script lang="ts" src="./FilterSection.ts"></script>
<style lang="scss" src="./FilterSection.scss" scoped></style>
