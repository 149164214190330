<template>
    <div class="io-project-wizard-last-step">
        <form class="io-form-column">
            <div v-if="!isOwner">
                <h4 class="io-column-label">{{ $t('Billing Type') }}</h4>
                <div class="io-choose-contract-types">
                    <template v-for="typeItem in contractTypes" :key="typeItem.id">
                        <Radio
                            input-name="contractTypes"
                            :radio-value="typeItem.id"
                            :label-text="$tc(typeItem.type)"
                            v-model="project.type"
                        />
                    </template>
                </div>
            </div>
    
            <div v-if="project.type === '2'" class="io-form-input-group" :class="{ 'io-v-error': isProjectValueInvalid }">
                <h4 v-if="getAuthData.app_type === appTypes.TYPE_SC || getAuthData.app_type === appTypes.TYPE_GC"
                    class="io-column-label">{{ $t('Guaranteed Maximum Price') }}</h4>
                <h4 v-else class="io-column-label">{{ $t('Not To Exceed Price') }}</h4>
                <FieldNumeric v-model="project.gmp" />
                <span v-if="isProjectValueInvalid" class="isProjectValueInvalid">
                    {{ $t('Contract Value must not be greater than Not to Exceed Price') }}
                </span>
            </div>
            <div 
                v-if="project.type === '1' || project.phases.length"
                class="io-form-input-group"
                :class="{ 'io-v-error': !contractValueValid }"
            >
                <h4 class="io-column-label">{{ $t('Contract Total') }}</h4>
                <span 
                    v-if="canAddPhases || isProposal"
                    class="aright"
                    :class="{ 'isProjectValueInvalid': isProjectValueInvalid }"
                >
                    {{ $filters.formatCurrency(project.value) }}
                </span>
                <FieldNumeric v-else v-model="project.value" />
            </div>
    
    
            <div v-if="isProposal && hasAlternatePhases" class="io-form-input-group">
                <h4 class="io-column-label">{{ $t('Select Alternate Phases') }}</h4>
                <table>
                    <template v-for="(phase, phaseIndex) in project.phases" :key="phaseIndex">
                        <tr v-if="phase.alternateTo">
                            <td><input type="checkbox" v-model="phase.enabled" @change="$emit('recalculateContractValue', $event)" /></td>
                            <td>{{ phase.name }}</td>
                            <td class="aright">{{ $filters.formatCurrency(phase.price) }}</td>
                        </tr>
                    </template>
                </table>
            </div>
    
            <div v-else-if="canAddPhases" class="io-form-input-group">
                <h4 class="io-column-label">{{ $t('Work Breakdown (Phases)') }}</h4>
                <table class="io-project-wizard-phases">
                    <tr v-for="(phase, phaseIndex) in project.phases" :key="phase.id">
                        <td class="io-wizard-phase-id">#{{ phaseIndex + 1 }}</td>
                        <td style="width:60%"><input type="text" :placeholder="$t('Phase Name')" v-model="phase.name" /></td>
                        <td>
                            <FieldNumeric
                                v-if="!isProposal"
                                v-model="phase.price"
                                @update:modelValue="$emit('recalculateContractValue', $event)"
                            />
                        </td>
                        <td class="aright">
                            <a v-if="phaseIndex" @click.stop="$emit('removePhase', phase.id)">
                                <i class="icon-trash"></i>
                            </a>
                        </td>
                    </tr>
                </table>
            </div>
        </form>
    </div>
</template>

<script lang="ts" src="./StepFeesAndPhases.ts"></script>

<style lang="scss" src="./StepFeesAndPhases.scss" scoped></style>
