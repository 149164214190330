const ProjectProvider = () => import(/* webpackChunkName: "project-provider" */ '@/layouts/project-provider/ProjectProvider.vue')
const ScheduleRouteView = () => import(/* webpackChunkName: "schedule-route-view" */ '@/io-modules/project-schedules/views/schedule-route-view/ScheduleRouteView.vue')
import { ScheduleRouteNameEnum } from '@/io-modules/project-schedules/enums/ScheduleRouteNameEnum.ts'

export default [
    {
        path: '/project/:pid',
        component: ProjectProvider,
        children: [
            {
                path: 'schedule-v2',
                name: ScheduleRouteNameEnum.Schedules,
                component: ScheduleRouteView,
                meta: {
                    name: 'Schedules',
                    inviteType: 'task',
                    title: 'Project Schedule',
                    navigationKey: 'schedule-v2',
                },
                children: [
                    {
                        path: ':id/gantt/:mode?',
                        name: ScheduleRouteNameEnum.ScheduleGantt,
                        meta: {
                            name: 'Schedule',
                            inviteType: 'task',
                            title: 'Project Schedule Gantt',
                            navigationKey: 'schedule-v2',
                            resourceView: 'schedule-v2',
                        },
                    }
                ]
            }
        ]
    },
]
