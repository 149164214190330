import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew'
import documentMixin from '@/modules/documents/main/parts/files-list/mixins/DocumentMixin'
import { mapGetters } from 'vuex'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import { InvitationModelTypes } from '@/interfaces/components/invite-modal/InviteModalModelTypes'
import { mapState as mapStateVuex } from 'vuex'
import { FolderType } from '@/modules/documents/enums/FolderType'
import DocumentsModelType from '@/constants/Documents/DocumentsModelType'
import synchronizeInvitationMixin
    from '@/components/invite-modal-v3/mixins/synchronizeInvitationMixin'
import featureFlagsConsts from '@/constants/FeatureFlagsConsts'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import documentsClient from '@/io-modules/documents/api-clients/documentsClient'

export default {
    name: 'SingleFile',
    components: {
        DropdownButtonNew,
        InvitePreview,
    },
    mixins: [documentMixin, synchronizeInvitationMixin, featureFlagsMixin],
    props: {
        file: {
            type: Object,
            required: true
        },
        toSync: {
            type: Boolean,
            required: false,
            default: false
        },
        idx: {
            type: Number,
            required: true
        },
        fromTrash: {
            type: Boolean,
            required: false,
            default: false
        },
        arrayId: {
            type: String,
            required: true
        },
        modelId: {
            type: String,
            required: false
        },
        modelType: {
            type: String,
            required: false
        },
        isProjectView: {
            type: Boolean,
            required: false,
            default: false
        },
        allowToSelectFiles: {
            type: Boolean,
            require: false,
            default: false
        },
        allowedExtensions: {
            default: () => [],
            type: Array,
        },
    },
    data () {
        return {
            syncProgress: false,
            newFileName: '',
            fileExtension: '',
            renameOptionClassName: 'js-rename',
            invitationClientKey: InviteModalClientKeys.DOCUMENT_FILE,
            PreviewTypes: PreviewTypes,
            inviteModalOptionsClient: {
                showEmployees: true,
                showContacts: true
            },
            InvitationModelTypes: InvitationModelTypes,
        }
    },
    computed: {
        ...mapGetters('appStore', ['getAuthData']),
        inviteModalV3FeatureIsEnabled () {
            return this.isFeatureEnabled(featureFlagsConsts.NEW_INVITATION_FACADE, false)
        },
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        selectedFiles () {
            return this.$store.getters['filesComponent/getSelectedFiles']
        },
        isInProjectFolder () {
            return this.folderFlags.folder_type === FolderType.PROJECT
        },
        isSelected () {
            return this.selectedFiles.findIndex((singleFile) => singleFile._id === this.file._id) > -1
        },
        disableSelectInput () {
            if (this.allowedExtensions?.length && !this.allowedExtensions.includes(this.ext)) {
                return true
            }
            return !this.$store.getters['filesComponent/getSelectMultipleFiles'] && this.selectedFiles.length > 0 && !this.isSelected
        },
        isConstructionDoc () {
            return this.folderFlags.model_type === 'construction_documents'
        },
        isConstructionFileVisible () {
            return this.isConstructionDoc ? this.file.is_published : true
        },
        previewUrl () {
            let modelIdTemp = this.modelId || this.$route.params.id
            let appName = (window.location.host).split('.')[0]
            let appType = localStorage.getItem('appType')

            let modelType = this.modelType

            if (!modelType || !modelIdTemp) {
                modelIdTemp = this.file.model_id ?? this.file.shared_from_document_mongo_id
                modelType = this.file.model_type ?? 'shared_document'

            }

            return process.env.API_URL + `${ appName }/${ appType }/copy-shared-document/${ modelType }/${ modelIdTemp }/${ this.file._id }?view=true`
        },
        ext () {
            let itemFileExtension = ''
            if (this.file.file_name.indexOf('.') !== -1) {
                itemFileExtension = this.file.file_name.split('.').pop()
            }

            return itemFileExtension
        },
        isEditable () {
            return !( ['payapps'].includes(this.modelType) || ['doc', 'docx'].includes(this.ext))
        },
        newFullFileName () {
            return `${ this.newFileName }.${ this.fileExtension }`
        },
        fileNameNotChanged () {
            return this.file.file_name === this.newFullFileName
        },
        isDeleted () {
            return this.file.deleted_at
        },
        fileBelongsToCurrentUser () {
            return this.getAuthData.u_mongo === this.file.creator_mongo_id
        },
        fileBelongsToCurrentWorkspace () {
            return this.getAuthData.company_mongo_id === this.file.company_mongo_id
        },
        isSharedDocument () {
            if (!this.file.attached_employees_mongo_ids) {
                return false
            }

            return this.file.attached_employees_mongo_ids.length > 1
        },
        projectMongoId () {
            return this.folderFlags?.project_global_id ?? null
        },
        isActionButtonShown () {
            if (this.editMode) {
                return false
            }

            if (this.fromTrash || this.isDeleted) {
                return true
            }

            if (!this.toSync) {
                return this.file._can_rename || this.fileBelongsToCurrentUser || this.folderFlags._can_publish || this.file._can_delete
            }

            return false
        },
        isJobsiteDocumnent () {
            return this.file.model_type === DocumentsModelType.JOBSITE_LOCATION
        },
    },
    methods: {
        downloadLinkClick () {
            if (this.toSync || this.fromTrash) {
                return false
            }

            document.querySelector('.io-download' + this.file._id).click()
        },
        downloadLink () {
            const endpoint = this.$api.getApi2RequestUrl('/documents/download')

            return `${ endpoint }?files[0]=${ this.file._id }`
        },
        async onShareChange () {
            const shared = this.file.can_be_shared ? 0 : 1

            try {
                this.setLoadingBar(true)
                const { data } = await this.$api.post('/document-can-be-shared/' + this.file._id, { can_be_shared: shared })

                this.file.can_be_shared = data.can_be_shared
                const message = data.can_be_shared ?  this.$t('Share of this file has been activated.') : this.$t('Share of this file has been disabled')

                this.showNotification('success', message)
                this.$forceUpdate()
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            } finally {
                this.setLoadingBar(false)
            }
        },
        async syncFile () {
            this.syncProgress = true
            let modelIdTemp = this.modelId || this.$route.params.id

            try {
                this.setLoadingBar(true)
                const { data } = await this.$api.get(`/copy-shared-document/${ this.modelType }/${ modelIdTemp }/${ this.file._id }`)

                this.showNotification('success', this.$t('Processing file'))
                this.syncProgress = false
                this.$store.dispatch('filesComponent/addMyDoc', {
                    arrayId: this.arrayId,
                    file: data.document
                })
                this.file._shared_to_my_document_id = true
                this.file.can_be_shared = false
                this.$emit('refreshData')
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
                this.syncProgress = false
            } finally {
                this.setLoadingBar(false)
            }
        },
        async onItemToTrash () {
            const popupOptions = {
                title: this.$t('Archive File'),
                caption: this.$t('This file will be archived, are you sure?'),
                icon: 'fas fa-archive',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, archive'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.moveToTrash()
                        }
                    }
                ]
            }

            if (this.isSharedDocument) {
                popupOptions.caption = this.$t('This is a shared file and cannot be removed.')
                popupOptions.buttons = [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    }
                ]
            }

            this.showPopupAlert(popupOptions)
        },
        async moveToTrash () {
            if (this.file.shared_to && this.file.shared_to.length) {
                this.showNotification('error', this.$t('Shared documents can not be deleted'))

                return
            }

            let postData = []
            postData.push(this.file._id)

            try {
                this.setLoadingBar(true)
                await this.$api.delete('documents/remove', { params: { files: postData } })
                this.$store.dispatch('filesComponent/addTrashedDoc', {
                    arrayId: this.arrayId,
                    file: this.file
                })
                this.$store.dispatch('filesComponent/hardRemoveMyDoc', {
                    arrayId: this.arrayId,
                    fileId: this.file._id
                })
                this.$emit('refreshData')
            } catch (e) {
                this.showNotification('error', e.message)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async onRemoveItem () {
            this.showPopupAlert({
                title: this.$t('Delete File'),
                caption: this.$t('Are you sure you want to delete this item?. This action cannot be undone.'),
                icon: 'far fa-trash',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Delete Item'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.hardRemoveDoc()
                        }
                    }
                ]
            })
        },
        async removeTempItem () {
            this.showPopupAlert({
                title: this.$t('Delete File'),
                caption: this.$t('File will be permanently deleted, are you sure?'),
                icon: 'far fa-trash',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Remove'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.hardRemoveTempDoc()
                        }
                    }
                ]
            })
        },
        async hardRemoveDoc () {
            let postData = []
            postData.push(this.file._id)

            try {
                this.setLoadingBar(true)
                await this.$api.delete('documents/hard-delete', { params: { files: postData } })

                const action = this.fromTrash ? 'filesComponent/hardRemoveDoc' : 'filesComponent/removeMyDoc'
                this.$store.dispatch(action, { arrayId: this.arrayId, fileId: this.file._id })
                this.$emit('refreshData')
                // @remind emit event when delete a folder - not implemented yet (INGIO-10218)
                this.$store.dispatch('storage/setRecalculateUsedStorage')
            } catch (e) {
                this.showNotification('error', e.message)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async hardRemoveTempDoc () {
            let postData = []
            postData.push(this.file._id)

            try {
                this.setLoadingBar(true)
                await this.$api.delete('documents/hard-delete', { params: { files: postData } })

                this.$store.dispatch('filesComponent/hardRemoveMyDoc', { arrayId: this.arrayId, fileId: this.file._id })
                this.$emit('refreshData')
                this.$emit('tempFileRemove', this.file._id)
                this.$store.dispatch('storage/setRecalculateUsedStorage')
            } catch (e) {
                this.showNotification('error', e.message)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async onRestoreItem () {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to restore this item?'),
                caption: this.$t('This will move this item to its original location.'),
                icon: 'far fa-archive',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Restore Item'),
                        class: 'io-btn-primary',
                        action: async () => {
                            await this.restoreItem()
                        }
                    }
                ]
            })
        },
        async restoreItem () {
            let postData = []
            postData.push(this.file._id)

            try {
                this.setLoadingBar(true)
                await this.$api.put('documents/restore', { files: postData })

                this.showNotification('success', this.$t('File has been restored'))
                const action = this.fromTrash ? 'filesComponent/restoreTrashedDoc' : 'filesComponent/removeMyDoc'
                this.$store.dispatch(action, { arrayId: this.arrayId, fileId: this.file._id })
                this.$emit('refreshData')
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            } finally {
                this.setLoadingBar(false)
            }
        },
        async onItemPublish () {
            this.showPopupAlert({
                title: this.file.is_published ? this.$t('Unpublish File'): this.$t('Publish File'),
                caption: this.file.is_published ? this.$t('This file will be unpublished from everyone in project. Are you sure?'): this.$t('This file will be published to everyone in project. You can find it in Construction Documents folder. Are you sure?'),
                icon: 'far fa-exclamation-triangle',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.file.is_published ? this.$t('Unpublish') : this.$t('Publish'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.changePublishItem()
                        }
                    }
                ]
            })
        },
        async changePublishItem () {
            try {
                this.setLoadingBar(true)
                await this.$api.post(`document/publish/${ this.file._id }`)

                this.file.is_published = !this.file.is_published

                const message = this.file.is_published ? this.$t('File has been unpublished') : this.$t('File has been published')

                this.showNotification('success', message)
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            } finally {
                this.setLoadingBar(false)
            }
        },
        async toggleImportant () {
            if (this.allowToSelectFiles) {
                return
            }

            const setFlag = this.file.flag === 1 ? 0 : 1

            const val = await this.toggleFavorite(setFlag, this.file._id, 'file')

            if (val) {
                this.file.flag = setFlag
            }
        },
        onClickRenameFile () {
            const index = this.file.file_name.lastIndexOf('.')

            this.newFileName = this.file.file_name.slice(0, index)
            this.fileExtension = this.file.file_name.slice(index + 1)
            this.editMode = true

            this.$nextTick(() => {
                this.$refs.inputName.focus()
            })
        },
        async saveEditedData () {
            if (!this.newFileName.length) {
                this.showNotification('error', 'Invalid file name.')
                return
            }

            if (this.fileNameNotChanged) {
                this.closeEditMode()
            }

            this.setLoadingBar(true)
            const requestData = { file_name: this.newFullFileName }

            try {
                const { data } = await this.$api.post('/document/' + this.file._id + '/rename', requestData)
                this.file.file_name = this.newFullFileName
                this.file.updated_at = data.updated_at

                this.closeEditMode()
            } catch (error) {
                this.showNotification('error', error.response.data.message, error)
            } finally {
                this.setLoadingBar(false)
            }
        },
        clickOutsideRow (event) {
            if (!this.editMode || this.cancelEditModeModalShown) {
                return
            }

            if (event.target.classList.contains(this.renameOptionClassName) || event.target.parentElement.classList.contains(this.renameOptionClassName)) {
                return
            }

            const el = this.$refs[`row-${ this.file._id }`]

            if (!(el === event.target || el.contains(event.target))) {
                this.cancelEditing(this.fileNameNotChanged)
            }
        },
        closePreview () {
            this.showPreviewPopup = false
        },
        async onClickShareFile () {
            this.$refs.invitePreview.openInviteModal()
        },
        onClickShareFileViaDistributionTemplate () {
            this.$store.dispatch('filesComponent/setSharingResourceObject', this.file)
            this.$store.dispatch('filesComponent/setSharingResourceWay', 'distribution-template')
        },
        selectFile (file) {
            if (this.disableSelectInput) {
                return
            }
            this.$store.dispatch('filesComponent/selectFile', file)
        },
        async updateInviteModalData (payload) {
            try {
                this.setLoadingBar(true)
                await this.synchronizeInvitation(
                    payload,
                    'You have been invited to collaborate on a Document',
                    null
                )
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
    }
}
