import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import FieldNumeric from '@/components/fields/fieldNumeric.vue'
import { cloneDeep } from 'lodash'

export default defineComponent({
    name: 'StepFeesAndPhases',
    components: {
        FieldNumeric,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
        isOwner: {
            type: Boolean,
            required: true,
        },
        isProposal: {
            type: Boolean,
            required: true,
        },
        canAddPhases: {
            type: Boolean,
            required: true,
        },
        contractValueValid: {
            type: Boolean,
            required: true,
        },
        hasAlternatePhases: {
            type: Boolean,
            required: true,
        },
        isProjectValueInvalid: {
            type: Boolean,
            required: true,
        },
    },
    data () {
        return {
            project: {},
        }
    },
    computed: {
        ...mapGetters('appStore', ['getAuthData']),
        contractTypes (): Array<Object> {
            const appType = this.getAuthData.app_type

            return [
                {
                    id: '1',
                    type: this.$t('project_wizard_contract_type_1') },
                {
                    id: '2',
                    type: appType === this.appTypes.TYPE_GC || appType === this.appTypes.TYPE_SC ? this.$t('project_wizard_contract_type_3') : this.$t('project_wizard_contract_type_2')
                }
            ]
        },
    },
    watch: {
        project: {
            handler (value: Object): void {
                this.$emit('update:modelValue', value)
            },
            immediate: true,
            deep: true
        },
    },
    beforeMount () {
        this.project = cloneDeep(this.modelValue)
    },
})
