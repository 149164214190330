<template>
    <div class="io-notification">

        <transition name="clear-all">
            <div class="io-clear-all" v-if="content.length > 2" @click="clearAll">
                <span>Clear All</span>
                <i class="fal fa-times"></i>
            </div>
        </transition>

        <transition-group name="notification" tag="div">
            <template v-for="(notification, index) in content.slice(0, 3)" :key="notification.id">
                <div
                    class="io-top-notification"
                    :style="customStyle(index)"
                >
                    <div class="container">
                        <div class="text">
                            <div class="icon" v-html="icon(index)"></div>
                            <p v-if="notification.html" v-html="$t(notification.text)"></p>
                            <p v-else>{{ $t(notification.text) || '' }}</p>
                        </div>
                        <div class="close-icon" @click="close(index)">
                            <i class="fas fa-times"></i>
                        </div>
                    </div>
                </div>
            </template>
        </transition-group>

    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import NotificationType from '@/interfaces/layout/NotificationType'

    export default {
        name: 'Notification',
        computed: {
            ...mapState('notification', {
                show: state => state.show,
                content: state => state.content
            })
        },
        methods: {
            close (index) {
                this.$store.dispatch('notification/deleteSingleBanner', index)
            },
            customStyle (index) {
                let result

                switch (this.content[index].type) {
                case NotificationType.INFORMATION:
                    result = 'D5E6F9'
                    break
                case NotificationType.SUCCESS:
                    result = 'E1F6EA'
                    break
                case NotificationType.WARNING:
                    result = 'FCF0D5'
                    break
                case NotificationType.ERROR:
                    result = 'FDE1E0'
                    break
                default:
                    result = 'D5E6F9'
                    break
                }

                return `background-color: #${result}; top: ${56 * (index + 1)}px`
            },
            icon (index) {
                let result

                switch (this.content[index].type) {
                case 'information':
                    result = '<i style="color: #1384DE" class="fas fa-info-circle"></i>'
                    break
                case 'success':
                    result = '<i style="color: #2F9D62" class="fas fa-check-circle"></i>'
                    break
                case 'warning':
                    result = '<i style="color: #C98402" class="fas fa-exclamation-circle"></i>'
                    break
                case 'error':
                    result = '<i style="color: #EF4343" class="fas fa-times-circle"></i>'
                    break
                default:
                    result = '<i style="color: #1384DE" class="fas fa-info-circle"></i>'
                    break
                }

                return result
            },
            clearAll () {
                this.$store.dispatch('notification/setClose')
            }
        },
        watch: {
            // move div to another target (example: use in schedule planning full screen mode)
            show () {
                if (this.content.target && this.show) {
                    setTimeout(() => {
                        $('.io-top-notification').prependTo(this.content.target)
                    }, 200)
                }
            }
        }
    }
</script>

<style lang="scss">
    .io-top-notification {
        position: fixed;
        z-index: 10009;
        width: 770px;
        border-radius: 4px;
        padding: 16px 18px;
        left: calc(50% - 353px);
        box-shadow: 0 16px 24px rgba(26, 26, 26, 0.08);
        transition: all .24s;

        &.notification-enter-active {
            transition: all .24s, transform .24s cubic-bezier(.45, 0, .4, 2), opacity .22s;
        }

        &.notification-leave-active {
            transition: all .2s, transform .2s, opacity .18s;
        }

        &.notification-enter, &.notification-enter-from {
            transform: translateY(-10px) scale(.96);
            opacity: 0;
        }

        &.notification-leave-to {
            transform: scale(.82);
            opacity: 0;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .text {
            display: flex;
            color: var(--main);
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;

            a {
                color: var(--main);
                text-decoration: underline;

                &.warning {
                    color: var(--warning-darker);
                    text-decoration: none;
                }

                &:hover,
                &:active,
                &:focus {
                    color: var(--main);
                }
            }

            .icon {
                display: flex;
                padding-right: 9.5px;
                align-items: center;

                i {
                    font-weight: 300;
                    font-size: 16px;
                }
            }
        }

        .notification-link {
            color: white;
            text-decoration: underline;
            text-transform: lowercase;
            font-weight: 600;
        }

        .close-icon {
            cursor: pointer;
            display: flex;
            padding-left: 9.5px;

            i {
                font-weight: 300;
                font-size: 16px;
                color: var(--main-lighten-2);
            }
        }
    }

    .io-clear-all {
        position: fixed;
        z-index: 2500;
        top: 16px;
        left: calc(50% + 262px);
        padding: 8px 16px;
        background-color: #fff;
        border-radius: 4px;
        height: 32px;
        font-size: 12px;
        line-height: 16px;
        color: var(--dark-green-lighten-1);
        cursor: pointer;
        margin-left: 4px;

        span {
            margin-right: 8px;
        }

        &.clear-all-enter-active {
            transition: transform .24s, opacity .22s;
        }

        &.clear-all-leave-active {
            transition: transform .2s, opacity .18s;
        }

        &.clear-all-enter, &.clear-all-enter-from {
            transform: scale(.6);
            opacity: 0;
        }

        &.clear-all-leave-to {
            transform: scale(.6);
            opacity: 0;
        }
    }
</style>
