<template>
    <input
        v-if="editable"
        autocomplete="off"
        type="text"
        :class="[classname, {'io-error': error}]"
        :name="name"
        :placeholder="placeholder"
        v-model="inputValue"
    />
    <span v-else>{{ $filters.filterEmptyValue(inputValue) }}</span>
</template>

<script>
    export default {
        name: 'FieldInput',
        props: {
            modelValue: {
                type: String,
                required: true,
                default: null,
            },
            editable: {
                type: Boolean,
                default: true,
                required: false,
            },
            name: {
                type: String,
                default: '',
                required: false,
            },
            classname: {
                type: String,
                default: '',
                required: false,
            },
            placeholder: {
                type: String,
                default: '',
                required: false,
            },
            error: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        data () {
            return {}
        },

        computed: {
            inputValue: {
                get () {
                    return this.modelValue
                },
                set (value) {
                    this.$emit('update:modelValue', value)
                },
            },
        },
    }
</script>
