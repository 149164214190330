<template>
    <div v-if="show" class="io-mobile-splash-screen">
        <div class="io-mobile-splash-screen__content">
            <img class="io-mobile-splash-screen__illustration" src="/new-theme/img/mobile-splash-screen-illustration.svg"/>
            <div class="io-mobile-splash-screen__title">
                {{ $t('It looks like you are using a browser from a mobile device') }}
            </div>
            <div class="io-mobile-splash-screen__caption">
                {{ $t('The INGENIOUS.BUILD platform is not optimized for mobile device browsers. Please use a desktop for the best user experience.') }}
            </div>
        </div>
        <div class="io-mobile-splash-screen__button" @click="dismiss">Continue anyway</div>
    </div>
</template>

<script lang="ts" src="./MobileSplashScreen.ts"></script>
<style lang="scss" src="./MobileSplashScreen.scss" scoped></style>
