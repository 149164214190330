import { defineComponent } from 'vue'

export enum Size {
    SM = 'sm',
    MD = 'md',
}

export default defineComponent({
    name: 'IOPlaceholder',
    props: {
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        lite: {
            type: Boolean,
            default: false,
        },
        frame: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String as () => Size,
            default: Size.MD,
        },
    },
})
