<template>
    <Teleport to=".io-main-wrapper">
        <div
            role="dialog"
            class="io-modal-atom io-design-system"
            :class="[
                $attrs.class,
                `io-modal-atom--${ color }`,
                `io-modal-atom--size-${ size }`,
                { 'io-modal-atom--slideout': slideout },
                { 'io-modal-atom--wide': wide },
                { 'io-modal-atom--widest': widest },
                { 'io-modal-atom--scrollable': scrollable },
                { 'io-modal-atom--fullscreen': fullscreen },
            ]"
            :style="{ zIndex }"
        >
            <Transition name="fade" appear>
                <div class="io-modal-atom-overlay" @click="!persistent && $emit('close')" />
            </Transition>

            <Transition :name="slideout ? 'fade-slide-from-right' : 'fade-zoom-in'" appear>
                <div
                    v-progress:[loadingOverlayZIndex]="loading"
                    class="io-modal-atom-content"
                    :style="!fullscreen && {
                        width: `${ width }px`,
                        height: `${ height }px`,
                    }"
                >
                    <slot v-if="$slots.modal" name="modal" />

                    <template v-else>
                        <header class="io-modal-atom-header">
                            <div class="io-modal-atom-header__top">
                                <div class="io-modal-atom-header__start">
                                    <i
                                        v-if="icon"
                                        class="io-modal-atom-header__icon"
                                        :class="icon"
                                    />

                                    <strong v-if="title" class="io-modal-atom-header__title">{{ title }}</strong>

                                    <slot name="header-start" />
                                </div>

                                <div v-if="$slots['header-end']" class="io-modal-atom-header__end">
                                    <slot name="header-end" />
                                </div>

                                <div class="io-modal-atom-header__action">
                                    <slot v-if="$slots['header-action']" name="header-action" />

                                    <button
                                        v-else
                                        class="io-modal-atom-header__close"
                                        type="button"
                                        @click="$emit('close')"
                                    >
                                        <i class="icon-cross" />
                                    </button>
                                </div>
                            </div>

                            <div class="io-modal-atom-header__bottom">
                                <slot v-if="$slots['header-bottom']" name="header-bottom" />
                            </div>
                        </header>

                        <div class="io-modal-atom-body"><slot /></div>

                        <footer v-if="$slots.footer" class="io-modal-atom-footer">
                            <slot name="footer" />
                        </footer>
                    </template>
                </div>
            </Transition>
        </div>
    </Teleport>
</template>

<script lang="ts" src="./IOModal.ts"></script>
<style lang="scss" src="./IOModal.scss" scoped></style>
