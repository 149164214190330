export const LazyListFiltersHelper = {
    countFilters (filtersSelected: Array<any>, filters: Array<any> = []): number {
        let val = 0

        Object.entries(filtersSelected).forEach((item: any) => {
            if (filters) {
                const foundFilter = filters.find((filter: any) => {
                    return filter.name === item[0]
                })

                if (foundFilter) {
                    if (foundFilter.hide_in_sidebar) {
                        return
                    }

                    if (Array.isArray(item[1])) {
                        val += item[1].length
                        return
                    }

                    if (
                        typeof item[1] === 'number' ||
                        (typeof item[1] === 'string' && item[1].length)
                    ) {
                        val += 1
                        return
                    }

                    if (item[1] && item[1].end !== null) {
                        val += 1
                    }
                }
            }
        })

        return val
    }
}
