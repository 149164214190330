<template>
    <template v-if="!loading">
        <LineProgress
            v-if="isEditingMode && sitesAvailable && selectedSitesNumber"
            :label="$t('Sites completed')"
            :completed-number="completedSitesNumber"
            :total-number="selectedSitesNumber"
        />

        <div v-if="(isEditingMode || nonContractedLineItems.length) && sitesAvailable" class="io-line-items-total">
            <span>{{ $t('All Sites Total') }}</span>
            <span>{{ $filters.formatProjectCurrencyValue(lineItemsTotal) }}</span>
        </div>

        <div class="io-line-items" :style="cssVars">
            <Placeholder
                v-if="!isEditingMode && !nonContractedLineItems.length"
                icon="icon-invoice"
                :title="$t('Line Items not added')"
                :buttons-cta="ctaButtons"
            />

            <template v-else>
                <div class="io-line-items__wrapper" :class="{ 'io-line-items__wrapper--editing': isEditingMode }">
                    <template v-if="sitesAvailable">
                        <SitesNavigation
                            :sites="selectedSites"
                            :show-header="true"
                            @scrollToItem="scrollToItem"
                            @editSites="showSelectSitesModal=true"
                        />
                        <Placeholder
                            v-if="!selectedSites && isEditingMode"
                            :display-icon="false"
                            :title="$t('Before filling in line items select the sites that should be associated with this invoice')"
                            :subtitle="$t('This will help establish the structure for billing.')"
                            :buttons-cta="ctaButtonsSites"
                        />
                        <div v-else class="io-line-items__sites-list">
                            <div v-for="site in selectedSites" :id="site.id" :key="site.project_site_id" class="io-line-items__sites-list__item">
                                <div :id="site.project_site_id" class="io-line-items__sites-list__header">
                                    <span>{{ site.title }}</span>

                                    <IOButton
                                        v-if="canApplyToOtherSites"
                                        variant="link"
                                        @click="toggleShowApplyToOtherSites(lineItemsGroupedBySites[site?.project_site_id]?.lineItems || [])"
                                    >
                                        {{ $t('Apply to other sites') }}
                                    </IOButton>
                                </div>
                                <div class="io-line-items__sites-list__content">
                                    <LineItemsTable
                                        :key="site.project_site_id"
                                        :lineItems="lineItemsGroupedBySites[site?.project_site_id]?.lineItems || []"
                                        :site-id="site.project_site_id"
                                        @addNewRow="addNewRow(site.project_site_id)"
                                        @removeItem="index => removeLineItem(index, site.project_site_id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else class="io-line-items__list">
                        <LineItemsTable
                            :lineItems="nonContractedLineItems"
                            @addNewRow="addNewRow"
                            @removeItem="removeLineItem"
                        />
                    </div>
                </div>
            </template>

            <SelectProjectSiteModal
                v-if="showSelectSitesModal"
                :budget-type="invoice.paid_by"
                :preselected-sites="selectedSitesIds"
                @close="showSelectSitesModal=false"
                @sitesSelected="selectSites"
            />

            <AffectOtherSites
                v-if="showApplyToOtherSitesModal"
                :project-id="projectObj.project_global_id"
                :budget-type="invoice.paid_by"
                :preselected-site-ids="[siteToApplyFrom[0].site_id]"
                @selectedSites="applyToOtherSites"
                @close="toggleShowApplyToOtherSites"
            />
        </div>
    </template>
</template>

<script lang="ts" src="./NonContractedLineItems.ts"></script>
<style lang="scss" src="./NonContractedLineItems.scss" scoped></style>
