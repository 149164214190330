<template>
    <div class="io-smart-tags">
        <template v-if="editable">
            <div class="io-loader-small" v-if="loading"></div>
              <multiselect
                    v-model="tags"
                    :options="options"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    @search-change="searchable"
                    @tag="addTag"
                    :tag-placeholder="$t('Add this as new tag')"
                    :placeholder="$t(placeholder)"
                    label="text"
                    track-by="text"
                >
               
            </multiSelect>
        </template>
        <div v-else class="io-tags-view">
            <template v-if="tags && tags.length > 0">
                <template v-for="(item, index) in tags" :key="index">
                    <div :class="[defaultTagClass, 'ti-tag ti-valid']">
                        <span class="ti-content">{{ item.text }}</span>
                    </div>
                </template>
            </template>
            <template v-else>
                <span>-</span>
            </template>
        </div>
    </div>
</template>

<script src="./SmartTags.js"></script>
<style src="./SmartTags.scss" lang="scss" scoped></style>
