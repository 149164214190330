<template>
    <div class="io-breadcrumbs-holder">
        <template v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb.name">
            <SingleBreadcrumb
                :breadcrumb="breadcrumb"
                :index="index"
            />
        </template>
    </div>
</template>
<script src="./Breadcrumbs.ts" lang="ts"></script>
<style src="./Breadcrumbs.scss" lang="scss" scoped></style>
