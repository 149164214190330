import { ApiClient as client, ApiClientV2 as clientV2 } from '@/api/api'
import { AxiosPromise } from 'axios'
import {
    UpdateGlobalWorkspaceAppTypePayload,
    FetchCompaniesPayload,
    CreateContactPayload,
    SearchData,
    Contact,
    fetchAssignedContactsByResourcePayload,
    fetchContactsDataPayload,
} from '@/components/invite-modal-v3/interfaces/InviteModalInterface'
import { fetchContactsPayload, fetchContactsByIdsPayload } from '@/components/invite-modal-v3/interfaces/InviteModalInterface'
import {
    SynchronizeInvitationRequestInterface,
    SynchronizeInvitationResponseInterface
} from '@/components/invite-modal-v3/interfaces/SynchronizeInvitationInterface'

const inviteModalClient = {
    fetchContactsByIds (payload: fetchContactsByIdsPayload): AxiosPromise<Contact[]> {
        return client.post('/invitation/search-by-contact-person-ids', {
            contact_person_ids: payload.contactPersonIds,
            resource_id: payload.resourceId,
            resource_type: payload.resourceType,
            project_global_id: payload.projectGlobalId
        })
    },

    fetchContactsData (payload: fetchContactsDataPayload): AxiosPromise<Contact[]> {
        return client.get('/contacts/search', { params: {
            filters: { ...payload.filters },
            pagination: { ...payload.pagination },
        }})
    },

    fetchAssignedContactsByResource (payload: fetchAssignedContactsByResourcePayload): AxiosPromise<Contact[]> {
        return client.get(`/contacts/resource/${payload.resourceId}/resource-type/${payload.resourceType}/contacts-assigned`)
    },

    fetchCompanies (payload: FetchCompaniesPayload): AxiosPromise<SearchData> {
        return client.post('/invitation/search-companies', {
            search: payload.search,
            modal: payload.modal,
        })
    },

    fetchExistingWorkspaces (email: string): Object {
        return client.get(`/contacts/${email}/possible-workspaces`)
    },

    fetchCountries (): Object {
        return client.get('country')
    },

    fetchStates (): Object {
        return client.get('state')
    },

    createContact (payload: CreateContactPayload) {
        return client.post('/contacts', payload)
    },

    updateGlobalWorkspaceAppType (payload: UpdateGlobalWorkspaceAppTypePayload) {
        return client.put(`/app-type/${payload.global_workspace_id}`, {
            app_type: payload.app_type
        })
    },

    synchronizeContacts (payload: SynchronizeInvitationRequestInterface): AxiosPromise<SynchronizeInvitationResponseInterface> {
        return clientV2.put('/invitations/synchronize', payload)
    },
}

export default inviteModalClient
