import RetentionSummaryMixin from './RetentionSummaryMixin'

const netValuesWorkCompleteMixin = {
    mixins: [RetentionSummaryMixin],
    methods: {
        netPreviousAmount (item: object): number {
            return this.sub([item.previous_applications, item.previous_retention_amount])
        },

        netCurrentApplication (item: object): number {
            if (this.hasSubtasks(item)) {
                item.net_current_application = this.getSumOfTasksValues(item, 'net_current_application')
            } else {
                const value = (this.sub([
                    this.add([item.current_application, item.materials_stored, item.retention_released]),
                    this.getSumOfTasksValues(item, 'current_retention_amount'), 
                    this.getSumOfTasksValues(item, 'materials_stored_retention_amount')])) || 0
                item.net_current_application = value
            }

            return item.net_current_application
        },

        netTotalCompletedStoredAmount (item: object): number {
            return this.add([this.netPreviousAmount(item), item.net_current_application]) || 0
        },

        netTotalCompletedStoredPercents (item: object): number {
            return this.applyPrecision(
                this.netTotalCompletedStoredAmount(item) / item.scheduled_value,
                4,
            ) * 100 * process.env.SCALE_FACTOR
        },

        netBalanceToCompleteAmount (item: object): number {
            return this.sub([item.scheduled_value, this.netTotalCompletedStoredAmount(item)]) || 0
        },

        netBalanceToCompletePercents (item: object): number {
            return (this.netBalanceToCompleteAmount(item) / item.scheduled_value) * 100 * process.env.SCALE_FACTOR
        },
    },
}

export default netValuesWorkCompleteMixin
