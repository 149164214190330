<template>
    <PopupModal
        v-if="loaded"
        class="io-popup-new-proposal"
        :disableOutsideClose="true"
        :width="451"
        @close="closePopup"
    >

        <template #header>
            <div class="io-popup-modal__icon"><i class="far fa-user-plus"></i></div>
            <div class="io-popup-modal__title">{{ $t('Create New Proposal') }}</div>
            <div class="io-popup-modal__close" @click="closePopup"><i class="fal fa-times"></i></div>
        </template>

        <template #default>
            <FormInputColumn :label="$t('Internal ID')" :customContent="true" :required="true">
                <field-proposed-id
                    v-if="proposedId"
                    v-model="proposedId"/>
            </FormInputColumn>

            <FormInputColumn
                ref="name"
                :label="$t('Project Name')"
                :required="true"
                :placeholder="$t('Enter Project Name')"
                :content="name"
                :disabled="loading"
                @change="onChangeName"/>

            <CompanyContactDropdown
                :company="selectedCompany"
                :contact="selectedContact"
                :client-app-types="clientAppTypes"
                :companyLabel="$t('Client Company')"
                :contactLabel="$t('Client Contact')"
                @onSelectedCompany="onSelectedCompany"
                @onSelectedContact="onSelectedContact"
            >
            </CompanyContactDropdown>
        </template>

        <template #footer>
            <div class="io-btn-light" @click="closePopup">{{ $t('Cancel') }}</div>

            <div v-if="!duringCreating" :class="btnCreateClassName" @click="create">{{ $t('Create Proposal') }}</div>
        </template>
    </PopupModal>
</template>

<script src="./popup-new-proposal.ts"></script>
<style lang="scss" src="./popup-new-proposal.scss" scoped></style>
