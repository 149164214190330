<template>
    <div
        class="io-single-punch-item shadow"
        :class="{'io-selected': isSelected, 'io-top-most': isTopMost }"
        @click.stop="selectAnnotation"
    >
        <div class="io-single-punch-item__header">
            <div class="io-left-side">
                <i class="icon-playlist-plus"/>

                <span>{{ $t('Punch Item') }}: <span class="io-punch-title">{{ punch.punch_name }}</span></span>
            </div>

            <i class="icon-edit" @click="editPunchItem"/>
        </div>

        <div class="io-status-pill" :class="getPunchPillColor(punch.punch_status)">
            {{ $filters.filterEmptyValue(punch.punch_status_text) }}
        </div>

        <div class="io-single-punch-item__footer">
            <div class="io-left-side">
                <Avatar
                    size="24"
                    :employee-mongo-dd="data.created_by_full_name.id"
                    :employee-full-name="data.created_by_full_name.name"
                    :company-name="data.created_by_full_name.position"
                    show-full-name
                />
            </div>

            <div class="io-right-side">
                <div>{{ $t('Created') }} {{ $filters.parseDate(punch.created_at) }}</div>
                <div>{{ $t('Due') }} {{ $filters.parseDate(punch.punch_due_date) }}</div>
            </div>
        </div>

        <PunchItemOperationsSlideout
            v-if="punchItemEditSlideoutShown"
            :defaults="{ id: data.punch_v2.id }"
            @close="punchItemEditSlideoutShown = false"
            @punch-item-saved="closePunchItemSlideout"
        />

        <div class="io-single-punch-item__description">{{ punch.punch_description }}</div>
    </div>
</template>

<script lang="ts" src="./SinglePunch.ts"></script>
<style lang="scss" src="./SinglePunch.scss" scoped></style>
