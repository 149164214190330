export default {
    expandedLocations: [],
    expandAll: false,
    showArchived: true,
    archivedLocations: [],
    allLocations: [],
    searchValue: '',
    foundLocations: [],
    showImportPopup: false,
    selectedLocations: [],
    selectedTargetLocations: []
}
