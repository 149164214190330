import { defineComponent, PropType } from 'vue'
import FinancialInfoTile from '@/components/financial-info-tile/FinancialInfoTile.vue'
import ProjectFundingSummaryInt from '../../interfaces/ProjectFundingSummary'
import { mapState } from 'vuex'

export default defineComponent({
    name: 'ProjectFundingSummary',
    components: { FinancialInfoTile },
    props: {
        summary: {
            type: null as PropType<ProjectFundingSummaryInt>,
        },
    },
    computed: {
        ...mapState('project', {
            projectID: (state: any) => state.projectObj.project_local_id
        }),
    },
    methods: {
        goToProjectBudget (): void {
            this.$router.push({
                name: 'project-budget-dashboard-summary',
                state: {
                    pid: this.projectID
                }
            })
        },
    }

})
