import LienWaiverSettings from '@/api/lien-waiver-settings/LienWaiverSettings'
/**
 * Load lien waiver settings
 *
 * @param {Object} commit
 * @param {Object} state
 * @param {String} payAppId
 * @return {Promise<boolean|*>}
 */
export const loadLienWaiverSettings = async ({ commit, state }, payAppId) => {
    try {
        const { data: { data } } = await LienWaiverSettings.getLienWaiverSettings(payAppId)
        commit('SET_LIEN_WAIVER_SETTINGS', data)
        return true
    } catch (exception) {
        return exception
    }
}
