import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { paths } from '@/interfaces/api/apidefinitions'
import MarkAsPaidInvoiceInterface from '@/io-modules/invoices/interfaces/requests/MarkAsPaidInvoice.ts'

export type RevertToDraftList = paths['v1/{subdomain}/{apptype}/invoices/{projectLocalId}/invoice/{invoice_id}/revert-to-draft-invoices-list']['get']['responses']['200']['content']['application/json']
export type SubmitToClient = paths['v1/{subdomain}/{apptype}/invoices/{projectLocalId}/invoice/{invoice_id}/submit-to-client']['get']['responses']['200']['content']['application/json']
export type MarkAsPaidInvoice = paths['v1/{subdomain}/{apptype}/invoices/{projectLocalId}/invoice/{invoice_id}/mark-as-paid']['post']['responses']['200']['content']['application/json']

export default {
    revertToDraft (projectMongoIdLocal: string, invoiceId: string, isServiceAccount: boolean = false): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        const forceParam = isServiceAccount ? '?force=true' : ''

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/revert-to-draft${ forceParam }`)
    },

    revertToDraftInvoicesList (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<RevertToDraftList> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/revert-to-draft-invoices-list`)
    },

    submitToClientInvoice (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<SubmitToClient> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/submit-to-client`)
    },

    markAsSubmittedToClientInvoice (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/mark-as-submitted-to-client`)
    },

    markAsPaidInvoice (projectMongoIdLocal: string, invoiceId: string, data: MarkAsPaidInvoiceInterface): AxiosPromise<MarkAsPaidInvoice> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        if (!data) {
            throw new Error('No Data Provided!')
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/mark-as-paid`, data)
    },

    requestRevision (projectMongoIdLocal: string, invoiceId: string, note: string|null): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/request-revision`, { note })
    },

    voidInvoice (projectMongoIdLocal: string, invoiceId: string, note: string = ''): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/void`, { voided_note: note })
    },

    markAsPendingInternalApproval (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/mark-as-pending-internal-approval`)
    },

    submitToAccounting (projectMongoIdLocal: string, invoiceId: string, note: string = ''): void {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/export-invoice`, {
            invoice_id: invoiceId,
            note,
        })
    },

    resetApprovalWorkflow (projectMongoIdLocal: string, invoiceId: string): void {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/reset`)
    }
}
