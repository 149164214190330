import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import Dropdown from '../dropdown/Dropdown.vue'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import { Employee } from '@/interfaces/modules/projects/financial-approvals/Employee'
import { Approver } from '@/interfaces/modules/projects/financial-approvals/Approver'
import { LimitType } from '@/interfaces/modules/projects/financial-approvals/LimitType'
import Initials from '@/components/new-theme/initials.vue'
import { ApproverType } from '@/interfaces/modules/projects/financial-approvals/ApproverType'
import { Role } from '@/interfaces/modules/projects/financial-approvals/Role'
import ApproverSelector from '@/components/financial-approvals/approver-selector/ApproverSelector.vue'
import AssignmentTypeSelector from '@/components/financial-approvals/assignment-type-selector/AssignmentTypeSelector.vue'
import { ApproverAssignmentOption } from '@/components/financial-approvals/assignment-type-selector/AssignmentTypeSelector'
import ApproverAssignmentType from '@/interfaces/modules/projects/financial-approvals/ApproverAssignmentType'
import SingleApprovalStatus from '@/io-modules/approval-workflows/components/single-approval-status/SingleApprovalStatus.vue'
import ApprovalTextService from '@/io-modules/approval-workflows/services/ApprovalTextService'
import moment from 'moment'

export default defineComponent({
    name: 'SingleApprover2',
    components: {
        AssignmentTypeSelector,
        Draggable,
        Dropdown,
        CustomMultiselect,
        ApproverSelector,
        Initials,
        SingleApprovalStatus,
    },
    props: {
        approver: { type: Object as () => Approver },
        index: { type: Number },
        approvalSequence: { type: Boolean },
        approvalLimit: { type: Boolean },
        employees: { type: Array as () => Employee[] },
        roles: { type: Array as () => Role[] },
        edit: { type: Boolean, required: true },
        approvers: { type: Array as () => Approver[], required: true },
        customUserSelect: { type: Boolean, required: false, default: false },
        defaultWorkflow: { type: Boolean, required: false, default: false },
        allowLimitEdit: { type: Boolean, required: false, default: true },
        allowDrag: { type: Boolean, required: false, default: true },
        showApprovalNote: { type: Boolean, required: false, default: false },
    },
    data () {
        return {
            limitOptions: [
                { label: this.$tc('Less than'), value: 'less_than' },
                { label: this.$tc('Greater than'), value: 'greater_than' },
                { label: this.$tc('No limit'), value: 'no_limit' }
            ] as LimitType[],
            moment,
        }
    },
    computed: {
        approvedAtText (): string {
            return this.approver.approval
                ? ApprovalTextService.getApprovedAtText(this.approver.approval.type)
                : ''
        },
        isRoleTypeMultiselect (): boolean {
            return [ApproverType.ROLE, ApproverType.EXTERNAL_ARCHITECTS_TYPE].includes(
                this.approver?.template?.approver_type
            )
        },

        canDelete (): boolean {
            return !this.approvalDisabled && this.approvers.length > 1 && this.edit
        },

        activeEmployee (): Employee | undefined {
            return this.employees.find((employee: Employee) => employee.id === this.approver.user_id)
        },

        approvalDisabled (): boolean {
            if (!this.defaultWorkflow) {
                return false
            }

            return this.approvers.length === 1
        },

        defaultSelection (): LimitType | undefined {
            const defaultOption = this.limitOptions.find(limitType => limitType.value === this.approver.limit_type)

            if (defaultOption) {
                return defaultOption
            }

            return this.limitOptions.find((limitType: LimitType) => limitType.value === 'no_limit')
        },

        showLimitValueInput (): boolean {
            return !!this.approver.limit_type && this.approver.limit_type !== 'no_limit' && this.approvalLimit && this.edit
        },

        assignmentTypeText (): string {
            return ApproverAssignmentType.APPROVER === this.approver.assignment_type
                ? this.$tc('Approver')
                : this.$tc('Reviewer')
        },
    },
    watch: {
        approvers: {
            handler (value: Approver[]): void {
                if (this.defaultWorkflow && this.approvers.length === 1) {
                    this.selectLimitType(this.limitOptions[2])
                }
            },
            immediate: true,
            deep: true
        }
    },

    methods: {
        selectLimitType (e: any): void {
            this.approver.limit_type = e.value
        },

        selectType (option: ApproverAssignmentOption): void {
            this.approver.assignment_type = option.value
        },

        onChangeEmployee (employee: Employee): void {
            this.approver.is_unassigned = false
            this.approver.user_id = employee.id
            this.$emit('select-employee')
        },

        deleteApprover (): void {
            this.$emit('delete-approver')
        },
    }
})
