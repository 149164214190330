/**
 * @deprecated
 * Use ToggleSwitch instead
 */

import { defineComponent } from 'vue'

export enum ButtonVariant {
    PRIMARY = 'primary',
    WHITE = 'white'
}

export interface SwitchOptionItem {
    key: string,
    label: string,
    icon?: string,
    [propName: string]: any
}

export default defineComponent({
    name: 'MultiSwitch',
    props: {
        options: Array as () => SwitchOptionItem[],
        modelValue: String,
        variant: {
            type: String,
            default: ButtonVariant.PRIMARY
        }
    },
    computed: {
        activeClass (): string {
            return `io-multi-switch__option--active-${ this.variant }`
        }
     },
    methods: {
        isOptionSelected (option: SwitchOptionItem): boolean {
            return option.key === this.modelValue
        },
        selectOption (option: SwitchOptionItem): void {
            this.$emit('update:modelValue', option.key)
        },
        iconClass (option: SwitchOptionItem): string {
            const activeClass = this.isOptionSelected(option)
                ? 'io-multi-switch__icon-active'
                : ''
            return `${ option?.icon } ${ activeClass }`
        },
    }
})
