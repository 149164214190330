import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    props: {
        text: { type: String, default: '' },
        columns: { type: Array as () => ColumnInterface[], default: null, required: true },
        columnsLength: { type: Number, default: null },
    }
})
