<template>
    <div class="flex flex-col">
        <Transition name="fade-from-bottom">
            <ActionBar
                v-if="checkedFilesIds.length"
                :items-quantity="checkedFilesIds.length"
                @clear-selections="checkedFilesIds = []"
            >
                <template #actions>
                    <IOButton
                        v-for="action in bulkActions"
                        :key="action.id"
                        variant="link"
                        size="small"
                        :icon="action.icon"
                        @click="doBulkAction(action)"
                    >
                        {{ action.title }}
                    </IOButton>
                </template>
            </ActionBar>
        </Transition>

        <Table
            show-select
            :headers="headers"
            :items="files"
            :checkbox-value="allFilesSelected"
            :check-all-class="checkedFilesIds.length && !allFilesSelected ? 'io-minus' : null"
            @all-items-selected="selectAllFiles"
        >
            <template #tbody="{ sortedItems }">
                <tr
                    v-for="(item, i) in sortedItems"
                    :key="`item_${ i }`"
                    class="hover-darken animate-pop-from-bottom text-sm text-main-lighten-1 cursor-pointer"
                    :style="getCascadeAnimationDelay(i)"
                    @click="$emit('preview', item.id)"
                >
                    <td @click.stop>
                        <Checkbox
                            :checkbox-value="item.id"
                            v-model="checkedFilesIds"
                        />
                    </td>
                    <td>
                        <i class="icon-file mr-2" />
                        {{ item.name }}
                    </td>
                    <td>
                        {{ $filters.fileSize(item.fileSize) }}
                    </td>
                    <td v-if="!isViewMode">
                        <div class="flex justify-end gap-3">
                            <IOButton variant="link" icon="icon-edit" @click.stop="$emit('edit', item.id)" />
                            <IOButton variant="link" icon="icon-trash" @click.stop="$emit('delete', item.id)" />
                        </div>
                    </td>
                </tr>
            </template>
        </Table>
    </div>

</template>

<script lang="ts" src="./FilesTable.ts"></script>
