import { defineComponent } from 'vue'

export interface InnerChart {
    width: string;
    height: string;
    minWidth: string;
    minHeight: string;
    backgroundColor: string;
}
export interface OuterChart {
    width: string;
    height: string;
    backgroundImage: string;
}

export default defineComponent({
    name: 'DonutChart',
    props: {
        value: { type: Number, default: 0 },
        size: { type: Number, default: 46 },
        thickness: { type: Number, default: 8 },
        color: { type: String, default: '#2bd2c9' },
        trackColor: { type: String, default: 'var(--box-darken-background)' },
        backgroundColor: { type: String, default: 'var(--box-background)' },
        showValue: { type: Boolean, default: false },
        negativeVal: { type: Boolean, default: false }
    },
    computed: {
        innerChart (): InnerChart {
            const size = this.size as number
            const thickness = this.thickness as number

            return {
                width: `${ size - 2 * thickness }px`,
                height: `${ size - 2 * thickness }px`,
                minWidth: `${ size - 2 * thickness }px`,
                minHeight: `${ size - 2 * thickness }px`,
                backgroundColor: this.backgroundColor
            }
        },
        outerChart (): OuterChart {
            let end: number = this.value as number

            if (end < 0) {
                end = 0
            } else if (end > 100) {
                end = 100
            }

            const value: number = (end * 360) / 100
            let backgroundImage: string

            if (value <= 180) {
                backgroundImage = `linear-gradient(${ 90 + value }deg, transparent 50%, ${ this.trackColor } 50%), linear-gradient(90deg, ${ this.trackColor } 50%, ${ this.color } 50%)`
            } else {
                backgroundImage = `linear-gradient(${ value - 90 }deg, transparent 50%, ${ this.color } 50%), linear-gradient(90deg, ${ this.trackColor } 50%, ${ this.color } 50%)`
            }

            return {
                width: `${ this.size }px`,
                height: `${ this.size }px`,
                backgroundImage
            }
        }
    }
})
