import { defineComponent } from 'vue'
import SelectWorkspaceType from '../select-workspace-type/SelectWorkspaceType.vue'

export default defineComponent({
    name: 'WorkspaceTypeOffSystemCompany',
    components: {
        SelectWorkspaceType,
    },
    props: {
        componentKey: { type: String, required: true },
        preselectedWorkspaceType: { type: String, default: '' },
    },
    computed: {
        selectedContact () {
            return this.$store.getters['inviteModal/getSelectedContact'](this.componentKey)
        }
    },
})
