import { defineComponent } from 'vue'
import { isEmpty } from 'lodash'

import useLoader from '@/composables/useLoader.ts'
import jobsiteLocationsSelectClient from '@/components/jobsite-locations-select/api-clients/jobsiteLocationsSelectClient'

import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'
import JobsiteLocationsAccordion from '@/components/jobsite-locations-select/components/jobsite-locations-accordion/JobsiteLocationsAccordion.vue'
import SelectedJobsiteLocations
    from '@/components/jobsite-locations-select/components/selected-jobsite-locations/SelectedJobsiteLocations.vue'

import { JobsiteLocation } from '@/components/jobsite-locations-select/interfaces/JobsiteLocations'

export default defineComponent({
    name: 'JobsiteLocationsSelect',
    components: {
        IOModal,
        IOPlaceholder,
        JobsiteLocationsAccordion,
        SelectedJobsiteLocations,
    },
    props: {
        modelValue: {
            type: Array as () => JobsiteLocation[][],
            required: true,
        },
        editable: Boolean,
        multiple: Boolean,
    },
    emits: ['update:modelValue'],
    setup () {
        const { loading, load } = useLoader()

        return { loading, load }
    },
    data () {
        return {
            search: '',
            newLocationModalShown: false,

            jobsiteLocations: null as JobsiteLocation[],
            expandedJobsiteLocationsIds: [],

            selectedJobsiteLocationsIds: [] as string[],
        }
    },
    computed: {
        projectId (): string {
            return this.$store.getters['project/projectCompanyMongoId']
        },
        flattenedJobsiteLocations (): JobsiteLocation[] {
            return this.flattenJobsiteLocations(this.activeJobsiteLocations)
        },
        parentJobsiteLocationsIds (): string[] {
            return this.flattenedJobsiteLocations
                .filter((jobsiteLocation: JobsiteLocation) => jobsiteLocation.children.length)
                .map((jobsiteLocation: JobsiteLocation) => jobsiteLocation.id)
        },
        allExpanded (): boolean {
            return this.expandedJobsiteLocationsIds.length === this.parentJobsiteLocationsIds.length
        },
        filteredJobsiteLocations (): JobsiteLocation[] {
            return this.flattenedJobsiteLocations
                .filter((jobsiteLocation: JobsiteLocation) => jobsiteLocation.title.toLowerCase().includes(this.search.toLowerCase()))
                .map((jobsiteLocation: JobsiteLocation) => ({ ...jobsiteLocation, level: 0, children: [] }))
        },
        selectedJobsiteLocationsPaths (): JobsiteLocation[][] {
            return this.selectedJobsiteLocationsIds.map((locationId: string) => this.getJobsiteLocationPath(this.activeJobsiteLocations, locationId))
        },
        activeJobsiteLocations (): JobsiteLocation[] {
            return this.getActiveJobsiteLocations(this.jobsiteLocations)
        },
    },
    methods: {
        isEmpty,
        openNewJobsiteLocationModal (): void {
            this.newLocationModalShown = true
            this.getJobsiteLocations()
            this.preselectJobsiteLocations()
        },
        closeNewJobsiteLocationModal (): void {
            this.newLocationModalShown = false
            this.jobsiteLocations = null
            this.expandedJobsiteLocationsIds = []
            this.selectedJobsiteLocationsIds = []
        },
        getJobsiteLocations (): Promise<void> {
            return this.load(async () => {
                const { data } = await jobsiteLocationsSelectClient.getJobsiteLocations(this.projectId)
                this.jobsiteLocations = data.items
            })
        },
        toggleFolding (id: string): void {
            this.expandedJobsiteLocationsIds.includes(id)
                ? this.expandedJobsiteLocationsIds.splice(this.expandedJobsiteLocationsIds.indexOf(id), 1)
                : this.expandedJobsiteLocationsIds.push(id)
        },
        getJobsiteLocationPath (jobsiteLocations: JobsiteLocation[], id: string, path: JobsiteLocation[] = []): JobsiteLocation[] {
            return jobsiteLocations.reduce((acc: JobsiteLocation[], jobsiteLocation: JobsiteLocation) => {
                if (acc) {
                    return acc // just return if it's already found before
                }
                if (jobsiteLocation.id === id) {
                    return [...path, jobsiteLocation] // found here
                }

                return this.getJobsiteLocationPath(jobsiteLocation.children, id, [...path, jobsiteLocation]) // search deeper
            }, null)
        },
        flattenJobsiteLocations (jobsiteLocations: JobsiteLocation[]): JobsiteLocation[] {
            return jobsiteLocations.flatMap((jobsiteLocation) => {
                const children = jobsiteLocation.children?.length ? this.flattenJobsiteLocations(jobsiteLocation.children) : []
                return [jobsiteLocation, ...children]
            })
        },
        removeJobsiteLocationPath (jobsiteLocationPathToRemove: JobsiteLocation[]): void {
            const filteredJobsiteLocationsPaths = this.modelValue
                .filter((jobsiteLocationPath: JobsiteLocation[]) => jobsiteLocationPath !== jobsiteLocationPathToRemove)
            this.$emit('update:modelValue', filteredJobsiteLocationsPaths)
        },
        getActiveJobsiteLocations (jobsiteLocations: JobsiteLocation[]): JobsiteLocation[] {
            return jobsiteLocations.filter((jobsiteLocation: JobsiteLocation) => {
                jobsiteLocation.children = this.getActiveJobsiteLocations(jobsiteLocation.children)
                return jobsiteLocation.status !== 'jobsite_location.status.archived'
            })
        },
        preselectJobsiteLocations (): void {
            this.selectedJobsiteLocationsIds = this.modelValue.map((jobsiteLocation: JobsiteLocation[]) => jobsiteLocation[jobsiteLocation.length - 1]?.id)
        },
    },
})
