<template>
    <modal v-if="showModal === 'add-next'">
        <template #body>
            <div class="io-content io-modal-success">
                <img src="/new-theme/img/success-icon-big.svg">
                <div class="io-msg io-2">{{ getConfirmationText(modal) }}</div>
                <div class="io-msg-text io-3">Would you like to create next item?</div>
                <div class="io-f-row io-buttons-bottom">
                    <button type="button" id="submit" class="form-button io-btn-primary" @click="next()">Yes</button>
                    <button type="button" id="cancel" class="form-button io-btn-secondary" @click="closeModal()">No</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
    import modalMixinn from '@/mixins/modalMixin'

    export default {
        name: 'addNext',
        data () {
            return {}
        },
        components: {},
        watch: {},
        computed: {
            modal () {
                return this.$store.getters['modal/getReturnData']
            }
        },
        mixins: [modalMixinn],
        methods: {
            closeModal () {
                if ((!this.modal.cancel) || (this.modal.response && this.modal.response.noRedirect)) {
                    return this.$store.dispatch('modal/setShow', false, {root: true})
                }
                this.$router.push(this.modal.cancel)
                // if on same route force close
                if (this.$route.path === this.modal.cancel) {
                    this.$store.dispatch('modal/setShow', false, {root: true})
                }
            },
            getConfirmationText (modal) {
                // default 
                let text = 'Your ' + modal.type + ' form has been sent.'
                // overwrite text from response if available
                if (modal.response && modal.response.addNextMessage) {
                    text = modal.response.addNextMessage
                }
                return text
            },
            next () {
                this.$store.dispatch('modal/setShow', this.modal.type, {root: true})
            }
        }
    }
</script>