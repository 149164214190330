import { defineComponent } from 'vue'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import { mapState } from 'vuex'
import { mapActions as mapPiniaAction } from 'pinia'
import { useProjectChecklistStore } from '@/modules/projects/store/projectChecklistStore.ts'

export default defineComponent({
    components: {
        ActionDropdown
    },
    props: {
        title: { type: String, required: true },
        icon: { type: String, required: true },
        routeName: { type: String, required: true },
        routeParams: { type: Object, required: false },
        isCompleted: { type: Number, required: false, default: 0 },
        isNextAction: { type: Boolean, required: false, default: false },
        type: { type: String, required: true },
        learnMoreUrl: { type: String, required: true },
    },
    computed: {
        ...mapState('project', {
            projectObj: state => state.projectObj
        }),
    },
    methods: {
        ...mapPiniaAction(useProjectChecklistStore, [
            'completeChecklistItem',
            'hideChecklistItem',
        ]),
        redirect (): void {
            let params = {
                pid: this.$route.params.pid
            }

            if (this.routeParams) {
                params = {
                    ...params,
                    ...this.routeParams,
                }
            }

            this.$router.push({ name: this.routeName, params: { pid: this.$route.params.pid } })
            this.$emit('closeModal')
        },
        async undoneChecklistItem (): void {
            await this.completeChecklistItem(this.type, this.projectObj.project_local_id, 0)
        },
        async removeFromChecklist (): void {
            await this.hideChecklistItem(this.type, this.projectObj.project_local_id)
        },
        async doneChecklistItem (): void {
            await this.completeChecklistItem(this.type, this.projectObj.project_local_id)
        }
    }
})
