import { defineComponent } from 'vue'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import cachedListMixin from '@/mixins/cachedListMixin.js'
import { get } from 'lodash'
import { Contact, InviteModalDonePayload } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'
import { ApiClientV2 as client } from '@/api/api.js'
import useLoader from '@/composables/useLoader.ts'

export default defineComponent({
    name: 'InviteContacts',
    components: {
        InvitePreview,
    },
    props: {
        data: { type: Object, required: true },
        column: { type: Object, required: true },
        disableBookmarking: { type: Boolean, default: false },
    },
    mixins: [
        cachedListMixin,
    ],
    setup () {
        const { loading, load } = useLoader()

        return {
            loading,
            load,
        }
    },
    computed: {
        getAdditionalFunction (): (arg: string) => boolean | null {
            const additionalHTMLFunction = get(this.column, ['additional', 'additionalHTMLFunction'], null)

            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.data)
            }

            return null
        },
        tooltipValue (): string {
            const tooltipField = get(this.column, ['additional', 'tooltip'], null)

            if (typeof tooltipField === 'function') {
                return tooltipField(this.data)
            }

            return ''
        },
        invitedContacts (): Contact[] {
            return this.data.members.filter((member: Contact) => member.invitation.status === 'invited')
        },
        bookmarkedContacts (): Contact[] {
            return this.data.members.filter((member: Contact) => member.invitation.status === 'bookmarked')
        },
    },
    methods: {
        async handleSaveContacts (data: InviteModalDonePayload): Promise<void> {
            return this.load(async () => {
                await client.put('invitations/synchronize', data)
            })

        },
    },
})
