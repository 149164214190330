import { ref, computed, Ref } from 'vue'

import setsClient from '@/io-modules/drawings/api-clients/setsClient'
import useLoader from '@/composables/useLoader.ts'

import { PendingSet } from '@/io-modules/drawings/interfaces/PendingSet'
import { Set } from '@/io-modules/drawings/interfaces/Set'
import { SetType } from '@/io-modules/drawings/enums/SetType.ts'

export default (projectId: string) => {
    const pendingSets: Ref<PendingSet[]> = ref(null)
    const currentSets: Ref<Set[]> = ref(null)
    const draftSets: Ref<Set[]> = ref(null)

    const activeSets = computed(() =>
        [...currentSets.value, ...draftSets.value]
            .filter((set: Set) => !set.is_archived))

    const { loading: pendingSetsLoading, load: loadPendingSets } = useLoader()
    const { loading: currentSetsLoading, load: loadCurrentSets } = useLoader()
    const { loading: draftSetsLoading, load: loadDraftSets } = useLoader()

    const getPendingSets = (): Promise<void> => loadPendingSets(async () => {
        const { data } = await setsClient.getPendingSets(projectId)
        pendingSets.value = data
    })

    const getCurrentSets = (archivedSetsIncluded?: boolean): Promise<void> => loadCurrentSets(async () => {
        const { data } = await setsClient.getDrawingsByType(
            projectId,
            [SetType.CONSTRUCTION],
            archivedSetsIncluded,
        )
        currentSets.value = data.items
    })

    const getDraftSets = (archivedSetsIncluded?: boolean): Promise<void> => loadDraftSets(async () => {
        const { data } = await setsClient.getDrawingsByType(
            projectId,
            [SetType.DRAFT],
            archivedSetsIncluded,
        )
        draftSets.value = data.items
    })

    return {
        pendingSets,
        currentSets,
        draftSets,
        activeSets,

        pendingSetsLoading,
        currentSetsLoading,
        draftSetsLoading,

        getPendingSets,
        getCurrentSets,
        getDraftSets,

        // these are used upon archiving/unarchiving sets
        loadCurrentSets,
        loadDraftSets,
    }
}
