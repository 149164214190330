import { defineComponent } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import ChooseItemRadioCard from '@/components/choose-item-radio-card/ChooseItemRadioCard.vue'
import { ItemData } from '@/components/choose-item-radio-card/interfaces/ItemData'
import SelectCapitalFundModal from '../select-capital-fund/SelectCapitalFundModal.vue'
import ManageProjectSpecFundModal from '@/io-modules/project-funding/components/modals/manage-project-specific-fund/ManageProjectSpecFundModal.vue'

export default defineComponent({
    name: 'SelectFundTypeModal',
    components: { ManageProjectSpecFundModal, SelectCapitalFundModal, ChooseItemRadioCard, IOModal },
    emits: ['closeModal'],
    data () {
        return {
            selectedFundType: null,
            showCapitalFundsModal: false,
            showProjectSpecFundsModal: false,
        }
    },
    computed: {
        fundTypes (): ItemData[] {
            return [
                {
                    id: 'project-specific-funds',
                    title: this.$t('Project Specific Fund'),
                    description: this.$t('Select Project Specific if the fund is only associated to this project.'),
                },
                {
                    id: 'project-capital-funds',
                    title: this.$t('Program Fund(s)'),
                    description: this.$t('Select Program Fund when the fund is tied to a larger program fund and you wish to select an existing provider or create a new fund for an existing provider.'),
                },
            ]
        },
    },
    methods: {
        closeModal (): void {
            this.$emit('closeModal')
        },
        goToTheNextStep (): void {
            this.selectedFundType === 'project-capital-funds' ? this.toggleCapitalFundsModal() : this.toggleProjectSpecFundsModal()
        },
        toggleCapitalFundsModal (): void {
            this.showCapitalFundsModal = !this.showCapitalFundsModal
            if (!this.showCapitalFundsModal) {
                this.closeModal()
            }
        },
        toggleProjectSpecFundsModal (): void {
            this.showProjectSpecFundsModal = !this.showProjectSpecFundsModal
            if (!this.showProjectSpecFundsModal) {
                this.closeModal()
            }
        },
    },
})
