import { defineComponent } from 'vue'
import FilesComponent from '@/components/files-new/files.vue'
import LienWaivers from '../../../components/invoice-view/lien-waivers/LienWaivers.vue'
import { mapState, mapActions } from 'pinia'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView.ts'

export default defineComponent({
    name: 'SupportingDocuments',
    components: {
        FilesComponent,
        LienWaivers,
    },
    data () {
        return {
            files: [],
            filesArrayId: 'invoice_documents'
        }
    },
    computed: {
        ...mapState(invoiceViewStore, [
            'attachedTemporaryDocuments',
            'invoice',
            'isNonContractedInvoice',
            'isEditingMode', 
            'creating',
        ]),

        modelId (): string {
            return this.creating ? 'invoices' : this.invoice.id
        },
    },
    mounted () {
        this.$store.dispatch('filesComponent/setMyDocs', {
            arrayId: this.filesArrayId,
            files: [...this.attachedTemporaryDocuments] || []
        })

        this.files = [...this.attachedTemporaryDocuments] || []
    },
    methods: {
        ...mapActions(invoiceViewStore, ['setAttachedTemporaryDocuments']),
        onFileAdd (file: any): void {
            this.files.push({ ...file.document, ...file })
            this.setAttachedTemporaryDocuments(this.files)
        },
        onFileRemove (fileId: string): void {
            this.files = this.files.filter((file: string) => file.id !== fileId && file._id !== fileId)
            this.setAttachedTemporaryDocuments(this.files)
        },
    },
})
