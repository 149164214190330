export const setMentionUsers = function ({ commit }, users) {
    commit('SET_MENTION_USERS', mapUsersForMention(users))
}

export const setMentionUsersForMyCompany = function ({ commit }, users) {
    commit('SET_MENTION_USERS_FOR_MY_COMPANY', mapUsersForMention(users))
}

export const setMentionUsersForOtherPartyCompany = function ({ commit }, users) {
    commit('SET_MENTION_USERS_FOR_OTHER_PARTY_COMPANY', mapUsersForMention(users))
}

function mapUsersForMention (users) {
    const mappedUsers = []
    users.forEach(user => {
        mappedUsers.push({
            id: user.employee_hash_mongo_id,
            imageExist: user.photo,
            email: user.email,
            isShared: false,
            name: user.name,
            role: user.position
        })
    })
    return mappedUsers
}