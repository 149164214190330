import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InvitationButtonGroupNew',
    props: {
        data: { type: Object, required: true }
    },
    methods: {
        onInviteClick (): void {
            this.$emit('inviteButtonNewClick', this.data)
        }
    }
})
