import { defineComponent } from 'vue'
import AppPackageInvoicesTable from './app-package-invoices-table/AppPackageInvoicesTable.vue'
import AppPackageInvoicesFilters from './app-package-invoices-filters/AppPackageInvoicesFilters.vue'
import { mapActions, mapState } from 'pinia'
import { appPackageViewStore } from '@/io-modules/invoices/stores/appPackageView.ts'
import { mapState as mapStateVuex } from 'vuex'

export default defineComponent({
    name: 'AppPackageAttachInvoices',
    components: { AppPackageInvoicesFilters, AppPackageInvoicesTable },
    data () {
        return {
            loading: false,
            filtersApplied: false,
        }
    },
    computed: {
        ...mapState(appPackageViewStore, ['attachableInvoices']),
        ...mapStateVuex('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id,
        }),
    },
    mounted () {
        this.fetchAttachedInvoices()
    },
    created () {
    },
    methods: {
        ...mapActions(appPackageViewStore, [
            'fetchAttachableInvoices',
            'setDataLoading',
            'setEditModeValue',
        ]),
        async fetchAttachedInvoices (filters: object = null): Promise<void> {
            try {
                if (this.$route.params.id !== '0') {
                    this.setEditModeValue(true)
                }
                
                if (!filters) {
                    this.loading = true
                } else {
                    this.filtersApplied = true
                }
                
                this.setDataLoading(true)
                let applicationPackageId = this.$route.params.id
                if (this.$route.params.id === '0') {
                    applicationPackageId = null
                }
                await this.fetchAttachableInvoices(this.projectLocalID, applicationPackageId, filters)
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.loading = false
                this.setDataLoading(false)
            }
        },
    }
})
