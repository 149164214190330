<template>
    <div>
        <div
            class="io-table-calculator__toggle"
            data-testid="io-table-calculator__toggle"
            @click="toggleCalculatorModal(true)"
        >
            <span v-if="initialFilledLines && initialFilledLines.length"
                  class="io-table-calculator__counter">
                {{ initialFilledLines.length < 9 ? initialFilledLines.length : '9+' }}
            </span>
            <span class="icon-pos io-table-calculator__icon"/>
        </div>


        <IOModal
            v-if="showTable"
            icon="icon-cash"
            :title="$t(titles.table)"
            width="1120"
            :z-index="49"
            @close="toggleCalculatorModal(false)"
        >
            <template #default>
                <table class="io-table-calculator" data-testid="io-table-calculator__table">
                    <thead>
                        <tr>
                            <th v-if="canEdit"></th>
                            <th class="acenter">{{ $t('ID') }}</th>
                            <th class="aleft">{{ $t('Description') }}<span v-if="canEdit">*</span></th>
                            <th class="aleft">{{ $t('Unit of Measure') }}<span v-if="canEdit">*</span></th>
                            <th class="aleft">{{ $t('Qty') }}<span v-if="canEdit">*</span></th>
                            <th class="aright">{{ $t('Unit Price') }}<span v-if="canEdit">*</span></th>
                            <th class="aright">{{ $t('Total') }}<span v-if="canEdit">*</span></th>
                            <th v-if="canEdit"></th>
                        </tr>
                    </thead>

                    <tbody v-if="!canEdit" class="io-table-calculator__static">
                        <tr v-for="(line, id) in adjustedLines">
                            <td class="acenter">
                                {{ id + 1 }}
                            </td>
                            <td>
                                {{ line.description }}
                            </td>
                            <td>
                                {{ line.unit_type.name }}
                            </td>
                            <td>
                                {{ parseFloat(line.unit_quantity) }}
                            </td>
                            <td class="aright">
                                {{ $filters.formatCurrency(line.unit_amount) }}
                            </td>
                            <td class="aright">
                                {{$filters.formatCurrency(line.total) }}
                            </td>
                        </tr>
                    </tbody>
                    <Draggable
                        v-if="adjustedLines && adjustedLines.length && canEdit"
                        tag="tbody"
                        handle=".icon-drag"
                        item-key="id"
                        v-model="adjustedLines">
                        <template #item="{ element: line, index }">
                            <tr>
                                <td>
                                    <span class="icon-drag io-table-calculator__icon"/>
                                </td>
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    <FieldInput
                                        aria-label="description-input"
                                        v-model="line.description"
                                    />
                                </td>
                                <td>
                                    <Multiselect
                                        v-if="units"
                                        id="unit-input"
                                        aria-label="unit-input"
                                        name="unit-input"
                                        track-by="id"
                                        label="name"
                                        :options="units"
                                        :allow-empty="false"
                                        :searchable="true"
                                        placeholder=""
                                        v-model="line.unit_type"
                                        @select="unitSelect(line.id)"
                                    />
                                </td>
                                <td>
                                    <FieldInput
                                        aria-label="quantity-input"
                                        v-model="line.unit_quantity"
                                    />
                                </td>
                                <td>
                                    <FieldNumeric
                                        data-testid="amount-input"
                                        aria-label="amount-input"
                                        :options="{ numeralPositiveOnly: false, numericOnly: false }"
                                        v-model="line.unit_amount"
                                    />
                                </td>
                                <td data-testid="total-amount" class="aright">
                                    {{ $filters.formatCurrency(calcTotal(line.id, line.unit_quantity, line.unit_amount)) }}
                                </td>
                                <td>
                                    <span
                                        class="icon-trash io-table-calculator__icon"
                                        @click="deleteLine(line.id)"
                                    />
                                </td>
                            </tr>
                        </template>
                    </Draggable>
                </table>

                <div v-if="canEdit" class="io-table-calculator__add-line">
                    <IOButton variant="link" icon="icon-plus" @click="addRow">
                        {{ $t('Add New Line') }}
                    </IOButton>
                </div>
                <div class="io-table-calculator__add-line"
                     :style="{ paddingRight: !canEdit ? '10px' : '48px' }"
                >
                    <div class="io-table-calculator__funding">
                        <span>{{ $t(titles.allocatedSum) }}</span>
                        <span>
                            {{ $filters.formatCurrency(totalAllocated) }}
                        </span>
                    </div>
                    <div class="io-table-calculator__add-info">
                        <slot name="bottomAdditionalInfo"/>
                    </div>
                </div>
            </template>
            <template #footer>
                <IOButton variant="secondary" @click="toggleCalculatorModal(false)">
                    {{ !canEdit ? $t('Close') : $t('Cancel') }}
                </IOButton>
                <IOButton v-if="canEdit" :disabled="!canSave" @click="saveLines">
                    {{ $t('Save') }}
                </IOButton>
            </template>
        </IOModal>
    </div>
</template>

<script lang="ts" src="./FinancialTableCalculator.ts"></script>
<style lang="scss" src="./FinancialTableCalculator.scss" scoped></style>
