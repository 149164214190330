<template>
    <div class="io-initial-view" data-testid="initial-view">
        <div
            v-if="!contactsList.length && !search"
            class="io-no-contacts-placeholder"
            data-test-id="no-contacts-placeholder"
        >
            <div class="io-no-contacts-placeholder__icon">
                <i :class="placeholder.icon"></i>
            </div>
            <div class="io-no-contacts-placeholder__content">
                <div class="io-no-contacts-placeholder__content-heading" v-html="placeholder.heading"></div>
                <div class="io-no-contacts-placeholder__content-subtext" v-html="placeholder.subtext"></div>
            </div>
        </div>
        <div
            v-else-if="!contactsList.length && search"
            class="io-no-contacts-placeholder"
            data-test-id="no-contacts-placeholder"
        >
            <div class="io-no-contacts-placeholder__icon">
                <i :class="emptySearchPlaceholder.icon"></i>
            </div>
            <div class="io-no-contacts-placeholder__content">
                <div class="io-no-contacts-placeholder__content-heading" v-html="emptySearchPlaceholder.heading"></div>
                <div class="io-no-contacts-placeholder__content-subtext" v-html="emptySearchPlaceholder.subtext"></div>
            </div>
        </div>

        <DynamicScroller
            v-else
            :items="contactsList"
            :min-item-size="63"
            page-mode
            class="scroller"
        >
            <template v-slot="{ item, index, active }">
                <DynamicScrollerItem
                    :item="item"
                    :active="active"
                    :data-index="index"
                >
                    <SingleContact
                        :componentKey="componentKey"
                        :key="`single-contact-${index}`"
                        :contact="item"
                        :isActionsVisible="true"
                        :disable-inviting="disableInviting"
                        :disable-bookmarking="disableBookmarking"
                        :disable-removing="disableRemoving"
                        :disable-replacing="disableReplacing"
                        :disable-multiple="disableMultiple"
                        :disable-actions-on-current-workspace-contact="disableActionsOnCurrentWorkspaceContact"
                    />
                </DynamicScrollerItem>
            </template>
        </DynamicScroller>
        <div
            v-if="isPaginating"
            class="io-initial-view__loading"
        >
            {{ $t('Fetching data...') }}
        </div>
    </div>
</template>

<script lang="ts" src="./InitialView.ts"></script>
<style src="./InitialView.scss" lang="scss" scoped></style>
