import { ANALYTICAL_MILESTONES_TAB_ROUTE_NAME } from '@/io-modules/lists-templates/analytical-milestones/constants/analyticalMilestonesTabRouteName.ts'

const routes = {
    path: 'analytical-milestones',
    name: ANALYTICAL_MILESTONES_TAB_ROUTE_NAME,
    component: () => import('@/io-modules/lists-templates/analytical-milestones/views/list/AnalyticalMilestonesListView.vue'),
    meta: {
        title: 'Lists Templates Analytical Milestones'
    }
}

export default routes
