<template>
    <div class="io-empty-description">
        <span class="icon-list-ordered-rec"></span>
        <div class="io-content-wrapper">
            <p>{{ $t('Nothing Here, Yet!') }}</p>
            <p class="io-description">{{ description }}</p>
            <IOButton
                variant="secondary"
                icon="icon-external-link"
                size="small"
                @click="handleRedirect()"
            >
                {{ $t('Go to Project Settings') }}
            </IOButton>
        </div>
    </div>
</template>
<script lang="ts" src="./EmptyDescription.ts"></script>
<style lang="scss" src="./EmptyDescription.scss" scoped></style>
