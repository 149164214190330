<template>
    <div class="io-funding-add-note">
        <IOButton v-if="!note.length" size="small" variant="link" icon="icon-plus" @click="toggleModal">
            {{ $t('Add Note') }}
        </IOButton>
        <IOButton v-else variant="link" size="small" @click="toggleModal">
            {{ $t('View Note') }}
        </IOButton>

        <IOModal
            v-if="showModal"
            icon="icon-plus-rec"
            :title="$t('Add Note')"
            :z-index="50"
            :width="450"
            @close="toggleModal"
        >
            <template #default>
                <div class="io-funding-add-note__body">
                    <div class="io-funding-add-note__note">
                        {{ $t('Note') }}
                        <TooltipIcon :text="$t('This note will be shown on the fund timeline.')"/>
                    </div>
                    <div class="io-funding-add-note__textarea">
                        <textarea v-model="note"/>
                    </div>
                </div>
            </template>
            <template #footer>
                <IOButton variant="secondary" @click="toggleModal">
                    {{ $t('Cancel') }}
                </IOButton>
                <IOButton :disabled="!note.length" @click="sendNote">
                    {{ $t('Save') }}
                </IOButton>
            </template>
        </IOModal>
    </div>
</template>

<script lang="ts" src="./AddNoteModal.ts"></script>
<style lang="scss" src="./AddNoteModal.scss" scoped></style>
