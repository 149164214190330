import {getProjectAccess} from './access.js'

const Main = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/package-details/main.vue')
const PackageDetails = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/package-details/views/details/package-details.vue')
const PackageTimeline = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/package-details/views/timeline/package-timeline.vue')
const PackageExport = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/package-details/views/export/package-export.vue')

const defaultMeta = {
    name: 'Invoicing & Pay Applications', 
    customRouteName: 'project-payapps', 
    navigationKey: 'payapps', 
    customClass: 'io-payapps', 
    dontShowMenu: true,
    breadcrumbs: [
        {
            text: 'Projects',
            to: { name: 'project-list'}
        },
    ],
}
export default {
    // project settings
    path: '/project/:pid/payapps/period/:id',
    name: 'payapps-billing-details',
    component: Main,
    redirect: {name: 'payapps-billing-details-details'},
    meta: {name: 'Invoicing & Pay Applications', navigationKey: 'payapps', customClass: 'io-payapps', dontShowMenu: true},
    children: [
        {
            path: 'details',
            name: 'payapps-billing-details-details',
            components:
                {
                    contentComponent: PackageDetails
                },
            meta: defaultMeta
        },
        {
            path: 'timeline',
            name: 'payapps-billing-details-timeline',
            components:
                {
                    contentComponent: PackageTimeline
                },
            meta: defaultMeta
        },
        {
            path: 'export',
            name: 'payapps-billing-details-export',
            components:
                {
                    contentComponent: PackageExport
                },
            meta: defaultMeta
        }
    ],
    async beforeEnter (to, from, next) {
        const result = await getProjectAccess(...arguments)
        return result
    }
}
