<template>
    <div v-if="!isLoading" class="io-proj-spec-fund">
        <IOModal
            icon="icon-cash"
            :title="fundInfoForEdit ? $t('Edit Fund') : $t('Add Project Specific Fund')"
            widest
            scrollable
            :z-index="48"
            width="975"
            @close="closeModal"
        >
            <template #default>
                <div class="io-proj-spec-fund__content">
                    <ContentTwoSidesTemplate
                        iconClass="icon-info"
                        :title="$t('Information')"
                        right-width="auto"
                    >
                        <template #rightSideActions>
                            <IOLabel size="medium" type="bold-max"
                                     :text="$filters.formatPercent(fundingPercentFromBudget)"/>
                        </template>
                        <template #leftSide>
                            <div class="io-proj-spec-fund__fields">
                                <div class="io-proj-spec-fund__field">
                                    <IOSingleInfoRow
                                        v-if="isForEditCapFund"
                                        :label="$t('Fund Name')"
                                        :info="projSpecFundData.name"
                                    />
                                    <FormGroup
                                        v-else
                                        :label="$t('Fund Name')"
                                        :label-type="LabelType.BoldMax"
                                        is-required
                                        v-model="projSpecFundData.name"
                                    />

                                    <FormInputColumn
                                        :customContent="true"
                                        :label="$t('Provider')"
                                        :required="true"
                                    >
                                        <Avatar
                                            v-if="isForEditCapFund"
                                            type="company"
                                            size="sm"
                                            :employeeMongoId="projSpecFundData.provider.id"
                                            :employeeFullName="projSpecFundData.provider.name"
                                            show-full-name
                                        />
                                        <InvitePreview
                                            v-else
                                            :key="invitationClientKey"
                                            class="io-proj-spec-fund__invite"
                                            :componentKey="invitationClientKey"
                                            :model-type="invitationClientKey"
                                            :previewType="PreviewTypes.BUTTON_SECONDARY"
                                            :allowedClientsTypes="[appTypes.TYPE_OWNER]"
                                            :preselected-workspace-type="appTypes.TYPE_OWNER"
                                            :disable-inviting="true"
                                            :selectMultiple="false"
                                            :modalZIndex="100"
                                            :buttonLabelText="$t('Select')"
                                            :is-adding-allowed="false"
                                            :categories="[InviteModalTabs.ALL, InviteModalTabs.EMPLOYEES, InviteModalTabs.CONTACTS]"
                                            :bookmarkedContactPersons="projSpecFundData?.contact ? [projSpecFundData.contact] : []"
                                            include-current-user-on-search-results
                                            :show-single-contact-avatar="true"
                                            :show-status-for-single-contact="false"
                                            @inviteModalDone="onSelectProvider"
                                        />
                                    </FormInputColumn>
                                </div>
                                <div class="io-proj-spec-fund__field">
                                    <IOSingleInfoRow
                                        v-if="isForEditCapFund"
                                        :label="$t('Account Number')"
                                        :info="projSpecFundData.account_number"
                                    />
                                    <FormGroup
                                        v-else
                                        :label="$t('Account Number')"
                                        :label-type="LabelType.BoldMax"
                                        v-model="projSpecFundData.account_number"
                                    />

                                    <FormInputColumn
                                        :customContent="true"
                                        :label="$t('Allocation to Project')"
                                        :required="true"
                                    >
                                        <div class="io-proj-spec-fund__allocation">
                                            <FieldNumeric
                                                :valid="isValidAllocation"
                                                :error-message="errorMessage"
                                                :show-placeholder="false"
                                                :options="{ numeralPositiveOnly: false }"
                                                v-model="projSpecFundData.allocated_amount"
                                                @input="clearLinesOnInput"
                                            />

                                            <FinancialTableCalculator
                                                :initial-filled-lines="allocationTableData.lines"
                                                :titles="allocationModalTitles"
                                                @filledLines="allocation => saveAllocatedLines(allocation, 'lines')"
                                                @totalAllocated="total => saveAllocatedLines(total, 'allocated_amount')"
                                            >
                                                <template #bottomAdditionalInfo>
                                                    <div class="io-proj-spec-fund__allocation__budget">
                                                        <span>{{ $t('Current Budget') }}</span>
                                                        <span>{{ $filters.formatCurrency(budget) }}</span>
                                                    </div>
                                                </template>
                                            </FinancialTableCalculator>
                                        </div>
                                    </FormInputColumn>
                                </div>
                                <div class="io-proj-spec-fund__field">
                                    <IOSingleInfoRow
                                        v-if="isForEditCapFund"
                                        :label="$t('Start Date')"
                                        :info="fundInfoForEdit.fund.start_date_formatted"
                                    />
                                    <FormInputColumn
                                        v-else
                                        :customContent="true"
                                        :label="$t('Start Date')"
                                    >
                                        <FieldDateTime
                                            :showIcon="true"
                                            :options="dateOptions"
                                            v-model="projSpecFundData.start_date"
                                        />
                                    </FormInputColumn>

                                    <IOSingleInfoRow
                                        v-if="isForEditCapFund"
                                        :label="$t('Expiration Date')"
                                        :info="fundInfoForEdit.fund.expiration_date_formatted"
                                    />
                                    <FormInputColumn
                                        v-else
                                        :customContent="true"
                                        :label="$t('Expiration Date')"
                                    >
                                        <FieldDateTime
                                            :showIcon="true"
                                            :options="dateOptions"
                                            v-model="projSpecFundData.expiration_date"
                                        />
                                    </FormInputColumn>
                                </div>
                                <div class="io-proj-spec-fund__note">
                                    <FormInputColumn
                                        :customContent="true"
                                        :label="$t('Note')"
                                        :tooltip-enable="true"
                                        :tooltip-text="$t('This note will be shown on the fund timeline.')"
                                    >
                                        <div class="io-proj-spec-fund__textarea">
                                            <LimitedTextarea height="40px" classname="io-proj-spec-fund__textarea-field"
                                                             dynamic-height v-model="projSpecFundData.note"/>
                                        </div>
                                    </FormInputColumn>
                                </div>
                                <div>
                                    <FormInputColumn
                                        :customContent="true"
                                        :label="$t('Attachments')"
                                    >
                                        <FilesComponent
                                            :key="docUnicId"
                                            :arrayId="fundInfoForEdit ? projSpecFundData.id : docUnicId"
                                            :modelId="fundInfoForEdit ? projSpecFundData.id : docUnicId"
                                            :showShared="false"
                                            :forceHideSharedTab="true"
                                            :showUploader="true"
                                            :hideShareColumn="true"
                                            model-type="funding_sources_project_funds"
                                            :addToTemporary="true"
                                            :showModalVersion="true"
                                            @newFileAdded="onFileAdd"
                                            @tempFileRemove="onFileRemove"
                                            @constantFileRemove="onFileRemove"
                                        />
                                    </FormInputColumn>
                                </div>
                            </div>
                        </template>
                        <template #rightSide>
                            <CreateProjectSpecFundRight
                                :initial-fund-info="fundInfoForEdit"
                                :fund-info="infoData"
                                @funding-percent-from-budget="saveFundingPercentFromBudget"/>
                        </template>
                    </ContentTwoSidesTemplate>
                </div>
            </template>
            <template #footer>
                <IOButton variant="secondary" @click="closeModal">
                    {{ $t('Cancel') }}
                </IOButton>
                <IOButton :disabled="!allowToCreate" @click="createNewCapitalFund">
                    {{ this.fundInfoForEdit ? $t('Save') : $t('Add New Fund') }}
                </IOButton>
            </template>
        </IOModal>
    </div>
</template>

<script lang="ts" src="./ManageProjectSpecFundModal.ts"></script>
<style lang="scss" src="./ManageProjectSpecFundModal.scss" scoped></style>
