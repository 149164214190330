<template>
    <filterSection
        :title="title"
        :clearSelection="clearSelection"
        @clearSelection="onClearSelection"
    >
        <template #default>
            <multiselect
                v-bind="preparedSettings"
                v-model="selectedOption"
                :placeholder="$t(placeholder)"
                :options="options"
                :loading="isLoading"
                :allow-empty="true"
                :searchable="true"
                :multiple="multiple"
                :clear-on-select="false"
                :close-on-select="closeOnSelect"
                :preserve-search="preserveSearch"
                :show-no-results="showNoResults"
                :disabled="disabled"
                :group-values="groupValues"
                :group-label="groupLabel"
                @search-change="onSearchChange"
                @close="onClose"
            >
                <template #selection="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values.length === 1 && search.length === 0">
                        {{ values.length }} {{ selectedLabel }}
                    </span>
                    <span v-else-if="values.length > 0" class="multiselect__single">
                        {{ values.length }} {{ selectedLabel }}
                    </span>
                </template>

                <template #noResult>
                    <slot name="noResult"></slot>
                </template>

                <template #noOptions>
                    <slot name="noOptions"></slot>
                </template>

                <template #afterList>
                    <slot name="afterList"></slot>
                </template>
            </multiSelect>
        </template>
    </filterSection>
</template>

<script>
import filterSection from './filterSection.vue'
import multiSelect from 'vue-multiselect'

export default {
    name: 'FilterMultiselect',
    emit: ['update:modelValue', 'searchChange', 'close'],
    components: {
        filterSection,
        multiSelect
    },
    props: {
        title: {
            type: String,
            required: true
        },
        modelValue: {
            default: null,
            type: [String, Array]
        },
        options: {
            required: true,
            type: Array
        },
        settings: {
            type: Object,
            required: false,
            default: () => ({})
        },
        selectedLang: {
            type: String,
            required: false,
            default: null
        },
        selectedLangPlural: {
            type: String,
            required: false,
            default: null
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        clearSelection: {
            type: Boolean,
            required: false,
            default: false
        },
        preserveSearch: {
            type: Boolean,
            required: false,
            default: false
        },
        showNoResults: {
            type: Boolean,
            required: false,
            default: true
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Please Select'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            default: true
        },
        closeOnSelect: {
            type: Boolean,
            default: false
        },
        groupValues: {
            type: String,
            required: false,
            default: ''
        },
        groupLabel: {
            type: String,
            required: false,
            default: ''
        },
        mapValueBy: {
            type: String,
        }
    },
    data () {
        return {
            defaultSettings: {
                'track-by': 'id',
                'label': 'name'
            }
        }
    },
    computed: {
        selectedLabel () {
            if (this.selectedLangPlural) {
                return this.modelValue.length > 1 ? this.selectedLangPlural : this.selectedLang
            }

            return this.selectedLang || this.$t('Selected')
        },

        selectedOption: {
            get () {
                return this.modelValue
            },
            set (val) {
                // Map selected values so they can meet BE requirements
                this.$emit('update:modelValue', this.mapValueBy ? val.map(item =>  {
                    return item[this.mapValueBy] ? item[this.mapValueBy] : item
                }) : val)
            }
        },

        preparedSettings () {
            return Object.assign(this.defaultSettings, this.settings)
        }
    },
    methods: {
        onSearchChange (value) {
            this.$emit('searchChange', value)
        },
        onClose () {
            this.$emit('close')
        },
        onClearSelection () {
            this.selectedOption = []
        },
    }
}
</script>
