import { mapState } from 'vuex'

export default {
    name: 'Template',
    props: {
        template: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapState('projectBudget', {
            view_only: state => state.view_only
        }),

        routeEdit () {
            return {
                name: 'lists-templates-bid-questions-template-edit',
                params: {
                    id: this.template.list_id,
                    template: this.template._id
                }
            }
        }
    },

    methods: {
        async archive () {
            if (this.view_only) {
                return false
            }
            const {value: confirm} = await window.swal({
                title: this.$t('Archiving Template'),
                text: this.$t('This template will be archived, are you sure?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Archive'),
                cancelButtonText: this.$t('Cancel')
            })

            if (confirm) {
                try {
                    await this.$api.post('bid-question-template/archive', {_id: this.template._id})
                    this.template['archived'] = true
                    this.showNotification('success', this.$t('Template has been archived.'))
                } catch (e) {
                    this.showNotification('error', this.$t('Error occurred during archiving template.'), e)
                }
            }
        },


        async deleteElement () {
            if (this.view_only) {
                return false
            }
            const {value: confirm} = await window.swal({
                title: this.$t('Deleting Template'),
                text: this.$t('This template will be deleted. This operation cannot be undone. Are you sure?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Delete'),
                cancelButtonText: this.$t('Cancel')
            })

            if (confirm) {
                try {
                    await this.$api.delete(`bid-question-template/delete/${this.template._id}`)
                    this.$emit('deleteElement', this.template._id)
                    this.showNotification('success', this.$t('Template has been deleted.'))
                } catch (e) {
                    this.showNotification('error', this.$t('Error occurred during deleting template.'), e)
                }
            }
        },


        async activate () {
            if (this.view_only) {
                return false
            }
            const {value: confirm} = await window.swal({
                title: this.$t('Activate Template'),
                text: this.$t('This template will be activated, are you sure?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Activate'),
                cancelButtonText: this.$t('Cancel')
            })

            if (confirm) {
                try {
                    await this.$api.post('bid-question-template/activate', {_id: this.template._id})
                    this.template['archived'] = false
                    this.showNotification('success', this.$t('Template has been activated.'))
                } catch (e) {
                    this.showNotification('error', this.$t('Error occurred during activating template.'), e)
                }
            }
        }
    }
}
