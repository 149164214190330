import {ApiClient} from '@/api/api'

let client = ApiClient

export default {
    tasksByItemType (search, tasksFilters) {
        const configuration = {
            params: {
                search: search,
                tasks_filters: tasksFilters,
                get_all_tasks: 1,
                get_list_counters: 1
            }
        }
        return client.get(`tasks-by-item-type`, configuration)
    },
    allTasksByItemType (search, tasksFilters) {
        const configuration = {
            params: {
                search: search,
                tasks_filters: tasksFilters,
                get_all_tasks: 1,
                get_list_counters: 1
            }
        }
        return client.get(`all-tasks-by-item-type`, configuration)
    },
    tasksByElement (elementData) {
        return client.get(`tasks-for/${elementData.type}/${elementData.id}`)
    },
    taskDetails (id, params) {
        return client.get(`task/${ id }`, { params })
    },
    tasksCounters (data) {
        const configuration = {
            params: data
        }
        return client.get('/tasks-counters/by-due-date-type', configuration)
    },
    employees () {
        return client.get('employees/names-list')
    },
    deleteTask (id) {
        return client.delete(`/task/${id}`)
    },
    attachEmployees (id, data) {
        return client.patch(`/task/${id}/attach-employees`, data)
    },
    attachEmployee (id, employeeId, data) {
        return client.post(`/task/${id}/attach-employee/${employeeId}`, data)
    },
    detachEmployee (id, employeeId, data) {
        return client.post(`/task/${id}/detach-employee/${employeeId}`, data)
    },
    updateTask (id, data) {
        return client.realJson(`/task/${id}`, data)
    },
    updateTaskChecklist (id, data) {
        return client.post(`/task/${id}/checklist`, data)
    },
    updateTaskComplete (id, completed) {
        const data = {completed}
        return client.post(`/task/${id}/complete`, data)
    },
    updateTaskDueDate (id, dueDate) {
        const data = {task_due_date: dueDate}
        return client.post(`/task/${id}/due-date`, data)
    },
    getStatisticsModalData () {
        return client.get('tasks-counters/my-progress')
    },
    tasksLazyList (skip, search, filters, sortBy, sortByDirection) {
        const configuration = {
            search: search,
            tasks_filters: filters,
            order: sortByDirection,
            orderBy: sortBy
        }
        return client.post(`tasks-lazy-list/${skip}`, configuration)
    },
    addLog (id, type, filename) {
        const data = {type: type, filename: filename}
        return client.post(`/task/${id}/add-log`, data)
    },
    addNewWorkflowTemplate (name) {
        const configuration = {
            name: name
        }
        return client.post(`tasks-workflow-template`, configuration)
    },
    assignBallInCourt (taskId, userId) {
        return client.put(`/task/${ taskId }/assign-ball-in-court/${ userId }`)
    },
    unassignBallInCourt (taskId, userId) {
        return client.put(`/task/${ taskId }/unassign-ball-in-court/${ userId }`)
    },
    removeTask (taskId) {
        return client.delete(`/tasks/${ taskId }`)
    },
    createTaskFromTaskTemplates (template_ids, project_id) {
        return client.post(`/tasks/create-from-templates`, { template_ids, project_id })
    },
    createTaskFromTaskListTemplates (list_ids, project_id) {
        return client.post(`/tasks/create-from-lists`, { list_ids, project_id })
    },
    getChangeOrderAvailableOptions (projectId) {
        return client.get(`/task/can-create-change-order/${ projectId }`)
    },
    availableProjects (page, search) {
        return client.post(`/tasks/available-related-items/${ page }`, { search })
    }
}
