<template>
    <PopupModal :width="820">
        <template #header>
            <div class="io-popup-modal__icon">
                <i class="fal fa-plus-square"></i>
            </div>
            <div class="io-popup-modal__title">
                {{ $t('Select Purchase Order')}}
            </div>
            <div class="io-popup-modal__close" @click="close(false)">
                <i class="fal fa-times"></i>
            </div>
        </template>

        <template #default>
            <div class="io-content">
                <SearchInput
                    :loader="false"
                    :startLength="1"
                    @searchChanged="searchLocal = $event"
                />

                <LazyList
                    key="purchase_orders"
                    :endpoint="getEndpointWithParams"
                    table-row-class="io-project-purchase-orders-row"
                    dataPropertyName="data"
                    method="get"
                    :columns="columns"
                    :filtersSchema="filtersSchema"
                    :noReroute="true"
                    @radioClick="radioClick"
                />
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="close(false)">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton v-if="isNonContractedInvoice" variant="secondary" @click="onCreateNewPurchaseOrder()">
                {{ $t('Create Non-Contracted Purchase Order') }}
            </IOButton>
            <IOButton :disabled="disabledSelectButton" @click="onSelect()">
                <i v-if="isLoading" class="far fa-spin fa-spinner-third"></i>
                {{ $t('Select') }}
            </IOButton>
        </template>

    </PopupModal>
</template>

<script lang="ts" src="./PurchaseOrdersPopup.ts"></script>
<style lang="scss" src="./PurchaseOrdersPopup.scss" scoped></style>
