<template>
    <div class="io-browser-lock">
        <div class="io-browser-lock-container">
            <div class="io-browser-lock-content">
                <div class="io-img-holder">
                    <img src="/new-theme/img/io-browser-lock.png" alt="Ingenious">
                </div>
                <div class="io-box-inside">
                    <p>Looks like you’re using Internet Explorer Browser, which doesn’t support the features that make our platform great. To get full out of IngenousIO please use the following browser:</p>
                    <a href="https://www.google.pl/chrome/" target="_blank" class="io-browser io-f-row io-f-start">
                        <img src="/new-theme/img/browser-chrome.svg" alt="Chrome img">
                        <p>Google Chrome</p>
                    </a>
                </div>
                <p class="io-copy">Best, IngeniousIO Team</p>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'ScreenLock'
    }
</script>
<style lang="scss" scoped>
    .io-browser-lock {
        background-color: #e7eaf3;
        width: 100%;
        height: 100vh;
        padding: 24px;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000;

        .io-copy {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            letter-spacing: normal;
            text-align: center;
            color: #9598a5;
            margin-top: 24px;
        }

        .io-browser-lock-content {
            text-align: center;
            width: 619px;
            margin: auto;
            position: absolute;
            top: 32%;
            left: 0;
            right: 0;
        }

        .io-browser-lock-container {
            background-color: #fff;
            height: 100%;
            border-radius: 10px;
        }

        .io-img-holder {
            width: 257px;
            margin: auto;
            margin-bottom: 32px;
        }

        .io-box-inside {
            border-radius: 5px;
            border: solid 1px #e7eaf3;
            padding: 24px;

            p {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: #63666e;
                text-align: center;
            }
        }

        .io-browser {
            margin-top: 24px;
            justify-content: center;

            &:hover {
                p {
                    color: var(--accent);
                }
            }

            p {
                margin-left: 16px;
                margin-right: 16px;
            }
        }
    }
</style>
