import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import InfoPopup from '../info-popup/InfoPopup'
import filesMixin from '@/components/files-new/mixins/filesMixin.ts'

export default {
    name: 'FileUploaderGridItem',
    components: { Avatar, InfoPopup },
    props: {
        idx: {
            type: Number,
            required: true
        },
        file: {
            type: Object,
            required: true
        },
        toSync: {
            type: Boolean,
            required: false,
            default: false
        },
        fromTrash: {
            type: Boolean,
            required: false,
            default: false
        },
        arrayId: {
            type: String,
            required: true
        },
        addToTemporary: {
            type: Boolean,
            required: false
        },
        showShared: {
            type: Boolean,
            required: false
        },
        filesInNewTab: {
            type: Boolean,
            required: false,
            default: false
        },
        hideShareColumn: {
            type: Boolean,
            required: false
        },
        forceShared: {
            type: Boolean,
            required: false
        },
        modelId: {
            type: [String, Number],
            required: false
        },
        modelType: {
            type: String,
            required: true
        },
        lockHotkeys: {
            type: Boolean,
            required: false,
            default: false
        },
        isProjectView: {
            type: Boolean,
            required: false,
            default: false
        },
        editable: {
            type: Boolean,
            required: true
        },
        editableActions: {
            type: Array,
            required: false,
            default: () => []
        },
        showModalVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        showImportantToggle: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: [ 'hideAllDropdowns' ],
    mixins: [ filesMixin ],
    data () {
        return {
            isMenuVisible: false as Boolean,
            InfoPopupShown: false as Boolean
        }
    },
    computed: {
        extension (): String {
            return this.file.file_name.split('.').pop()
        },
        textToShow (): string {
            const name = this.file.file_name
            if (name.length > 21) {
                return name.slice(0, 15) + '...' + this.extension
            }

            return this.file.file_name
        },
    },
    methods: {
        toggleMenu (): void {
            if (this.isMenuVisible) {
                this.hideMenu()

                return
            }

            this.$emit('hideAllDropdowns')

            const menu = document.querySelector('#id' + this.idx)
            const filesContainerRect = document.querySelector('.io-files-module').getBoundingClientRect()
            const spaceToRight = menu.getBoundingClientRect().right - filesContainerRect.right

            if (spaceToRight > 0) {
                menu.style.right = '0'
            }

            this.isMenuVisible = true
        },
        hideMenu (): void {
            this.isMenuVisible = false
        },
        toggleInfoPopup (): void {
            this.InfoPopupShown = !this.InfoPopupShown
        },
        downloadLink () {
            const endpoint = this.$api.getApi2RequestUrl('/documents/download')
            return `${ endpoint }?files[0]=${ this.file._id }`
        },
        isAvailableEditableOption (action) {
            if (!this.editableActions.length) {
                return true
            }
            return this.editableActions.includes(action)
        },
        async toggleImportant () {
            let setFlag
            if (this.file.flag === 1) {
                setFlag = 0
            } else {
                setFlag = 1
            }
            try {
                await this.$api.post(`document/${ this.file._id }/file/flag/${ setFlag }`)
                this.file.flag = setFlag
                this.showNotification('success', this.$t('Success'))
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
        async onShareChange () {
            let shared
            this.file.can_be_shared ? shared = 0 : shared = 1

            try {
                const { data } = await this.$api.post('/document-can-be-shared/' + this.file._id, { can_be_shared: shared })
                this.file.can_be_shared = data.can_be_shared
                if (data.can_be_shared) {
                    this.showNotification('success', this.$t('Share of this file has been activated.'))
                } else {
                    this.showNotification('success', this.$t('Share of this file has been disabled'))
                }
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
    }
}
