import ExternalClientApprovalResponseData = App.Modules.Enterprise.ApprovalWorkflow.Data.ExternalClientApprovalResponseData
import FinancialApprovalsState from '@/interfaces/store/FinancialsApprovalsState'
import ApprovalPhaseData from '@/interfaces/modules/projects/financial-approvals/ApprovalPhaseData'
import FinancialApprovalsMutations from '@/constants/store/financial-approvals/FinancialApprovalsMutations'
import ApprovalPhasePreviewData from '@/interfaces/modules/projects/financial-approvals/ApprovalPhasePreviewData'
import ClientApprovalSetting from '@/interfaces/modules/projects/financial-approvals/ClientApprovalSetting'
import ApprovalsInboxTabsCount
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsInboxTabsCount'
import ApprovalsListFilters
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListFilters'
import ApprovableResourceSubtype from '@/interfaces/modules/projects/financial-approvals/ApprovableResourceSubtype'
import SingleApprovalAction from '@/interfaces/modules/projects/financial-approvals/SingleApprovalAction'

export default {
    SET_HAS_CONFIGURED_WORKFLOWS (state: FinancialApprovalsState, value: boolean): void {
        state.hasConfiguredWorkflows = value
    },

    SET_RESOURCE_ID (state: FinancialApprovalsState, value: string): void {
        state.resourceId = value
    },

    SET_ACTIVE_RESOURCE (state: FinancialApprovalsState, value: string): void {
        state.activeResource = value
    },

    SET_APPROVAL_SUBMISSION_ID (state: FinancialApprovalsState, value: string): void {
        state.approvalSubmissionId = value
    },

    SET_COMPANY_PROJECT_RESOURCE_ID (state: FinancialApprovalsState, value: string): void {
        state.companyProjectResourceId = value
    },

    SET_APPROVAL_SUBMISSION_COMPANY_ID (state: FinancialApprovalsState, value: string): void {
        state.approvalSubmissionCompanyId = value
    },

    SET_APPROVAL_WORKFLOWS (state: FinancialApprovalsState, value: any[]): void {
        state.approvalWorkflows = value
    },

    SET_INTERNAL_APPROVAL (state: FinancialApprovalsState, value: any): void {
        state.internalApproval = value
    },

    SET_MANUAL_APPROVAL (state: FinancialApprovalsState, value: any): void {
        state.manualApproval = value
    },

    SET_MANUAL_REJECTION (state: FinancialApprovalsState, value: any): void {
        state.manualRejection = value
    },

    SET_CLIENT_REJECTION (state: FinancialApprovalsState, value: any): void {
        state.clientRejection = value
    },

    SET_CLIENT_APPROVAL (state: FinancialApprovalsState, value: any): void {
        state.clientApproval = value
    },

    SET_FULL_APPROVAL (state: FinancialApprovalsState, value: any): void {
        state.fullApproval = value
    },

    SET_FULL_REJECTION (state: FinancialApprovalsState, value: any): void {
        state.fullRejection = value
    },

    SET_INTERNAL_REJECTION (state: FinancialApprovalsState, value: any): void {
        state.internalRejection = value
    },

    SET_SUBMITTED_FOR_APPROVAL (state: FinancialApprovalsState, value: any): void {
        state.submittedForApproval = value
    },

    SET_CLIENT_APPROVAL_SETTING (state: FinancialApprovalsState, value: ClientApprovalSetting): void {
        state.clientApprovalSetting = value
    },

    SET_EXTERNAL_CLIENT_APPROVAL (state: FinancialApprovalsState, value: ExternalClientApprovalResponseData): void {
        state.externalClientApproval = value
    },

    SET_CURRENT_CHANGE_ORDER (state: FinancialApprovalsState, value: any): void {
        state.currentChangeOrder = value
    },

    SET_CURRENT_AMENDMENT (state: FinancialApprovalsState, value: any): void {
        state.currentAmendment = value
    },

    SET_ATTACHED_CHANGE_ORDERS (state: FinancialApprovalsState, value: any): void {
        if (!state.currentChangeOrder) {
            return
        }

        state.currentChangeOrder = { ...state.currentChangeOrder, co_attached_change_orders: value }
    },

    SET_ATTACHED_AMENDMENTS (state: FinancialApprovalsState, value: any): void {
        if (!state.currentAmendment) {
            return
        }

        state.currentChangeOrder = { ...state.currentAmendment, co_attached_change_orders: value }
    },

    SET_CURRENT_CONTRACT (state: FinancialApprovalsState, value: any): void {
        state.currentContract = value
    },

    SET_APPROVALS_LOADED (state: FinancialApprovalsState, value: boolean): void {
        state.approvalsLoaded = value
    },

    [FinancialApprovalsMutations.SET_PHASE] (state: FinancialApprovalsState, phase: ApprovalPhasePreviewData): void {
        state.phase = phase
    },

    [FinancialApprovalsMutations.SET_PHASES] (state: FinancialApprovalsState, phases: ApprovalPhaseData[]): void {
        state.phases = phases
    },

    SET_INVOICE_APPROVAL_BLOCK (state: FinancialApprovalsState, value: boolean): void {
        state.isApprovalBlockedByExpiredInsurance = value
    },

    SET_INVOICE_SUBMITING_BLOCK (state: FinancialApprovalsState, value: boolean): void {
        state.isSubmittingBlockedByExpiredInsurance = value
    },

    SET_IS_INVOICE_CONTRACT_HOLDER (state: FinancialApprovalsState, value: boolean): void {
        state.isInvoiceContractHolder = value
    },

    SET_EXPIRED_INSURANCE_CONSEQUENCE (state: FinancialApprovalsState, value: Number): void {
        state.expiredInsuranceConsequence = value
    },

    SET_APPROVABLE_RESOURCE_SUBTYPE (state: FinancialApprovalsState, value: ApprovableResourceSubtype | null): void {
        state.approvableResourceSubtype = value
    },

    SET_APPROVALS_INBOX_TABS_COUNT (state: FinancialApprovalsState, value: ApprovalsInboxTabsCount): void {
        state.approvalsInboxTabsCount = value
    },

    SET_APPROVALS_INBOX_SEARCH (state: FinancialApprovalsState, value: string): void {
        state.approvalsInboxSearch = value
    },

    SET_APPROVALS_INBOX_USER_PENDING_FILTERS (state: FinancialApprovalsState, filters: ApprovalsListFilters): void {
        state.approvalsInboxUserPendingFilters = filters
    },

    SET_APPROVALS_INBOX_COMPANY_PENDING_FILTERS (state: FinancialApprovalsState, filters: ApprovalsListFilters): void {
        state.approvalsInboxCompanyPendingFilters = filters
    },

    SET_APPROVALS_INBOX_COMPANY_REJECTED_FILTERS (state: FinancialApprovalsState, filters: ApprovalsListFilters): void {
        state.approvalsInboxCompanyRejectedFilters = filters
    },

    SET_SINGLE_APPROVAL_ACTION_SELECT_CALLBACK (state: FinancialApprovalsState, callback: (action: SingleApprovalAction) => void): void {
        state.singleApprovalActionSelectCallback = callback
    },

    SET_COST_CODES_ENABLED (state: FinancialApprovalsState, value: boolean): void {
        state.costCodesEnabled = value
    },

    SET_IS_FROM_OTHER_PARTY (state: FinancialApprovalsState, value: boolean): void {
        state.isFromOtherParty = value
    },
}
