import { defineComponent } from 'vue'
import ActionDropdownMenu from '@/components/action-dropdown-menu/ActionDropdownMenu.vue'

export default defineComponent({
    components: {
        ActionDropdownMenu,
    },
    props: {
        showMenu: {
            type: Boolean,
            default: true,
        },
        iconTooltip: {
            type: String,
            default: ''
        },
        iconClass: {
            type: String,
            default: 'icon-export',
        },
        menuClass: {
            type: String,
            default: '',
        },
        testId: {
            type: String,
            default: 'action-dropdown-icon',
        },
        menuTestId: {
            type: String,
            default: 'action-dropdown-menu',
        },
        disabled: Boolean,
    },
    emits: [ 'export', 'import', 'opened', 'closed', 'click' ],
    data () {
        return {
            isOpen: false,
            vcoConfig: {
                handler: this.hideMenu,
                capture: true
            }
        }
    },
    computed: {
        showTooltip (): boolean {
            return !this.isOpen
        },
    },
    watch: {
        isOpen (val: boolean): void {
            if (val) {
                return this.$emit('opened')
            }
            return this.$emit('closed')
        }
    },
    methods: {
        onExport (): void {
            this.$emit('export')
            this.hideMenu()
        },
        onImport (): void {
            this.$emit('import')
            this.hideMenu()
        },
        hideMenu (): void {
            this.isOpen = false
        },
        onIconClick (): void {
            if (this.disabled) {
                return
            }
            if (this.showMenu) {
                this.isOpen = !this.isOpen
            }
            this.$emit('click')
        },
    }
})
