import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { isEmpty, omit } from 'lodash'

import useLoader from '@/composables/useLoader.ts'
import drawingAnnotationsClient from '@/io-modules/drawings/api-clients/drawingAnnotationsClient.ts'
import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'
import PunchStampGenerator from '@/io-modules/drawings/mixins/PunchStampGenerator.ts'

import WebViewer from '@/components/web-viewer/WebViewer.vue'

import { RightPanelTab } from '@/io-modules/drawings/enums/RightPanelTab.ts'
import { SetType } from '@/io-modules/drawings/enums/SetType.ts'
import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar.ts'
import type { Core } from '@pdftron/webviewer'
import type { Stamp } from '@/modules/todos/components/stamp-dropdown/StampDropdown.ts'
import type { Annotation, SelectedExistingAnnotation } from '@/interfaces/modules/projects/drawings/Annotation.ts'
import type { DrawingAnnotationInterface } from '@/interfaces/modules/projects/drawings-v2/DrawingAnnotationInterface.ts'
import type { PunchItemDrawingAnnotation } from '@/io-modules/drawings/interfaces/PunchItemDrawingAnnotation.ts'
import type { Link } from '@/io-modules/drawings/interfaces/Link.ts'

export default defineComponent({
    name: 'SheetWebViewer',
    extends: WebViewer,
    props: {
        disableExistingAnnotations: Boolean,
    },
    mixins: [PunchStampGenerator],
    emits: ['annotationChanged', 'cancelAddingAnnotation'],
    setup () {
        const { load } = useLoader({ globalSpinner: true })
        return { load }
    },
    data () {
        return {
            selectedAnnotationObj: null,
            eventsEnabled: true,
            targetedAnnotations: [],
        }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            addingAnnotation: 'getAddingAnnotation',
            model: 'getModel',
            selectedExistingAnnotation: 'getSelectedExistingAnnotation',
            selectedAnnotation: 'getSelectedAnnotation',
            manualAnnotation: 'getManualAnnotation',
            selectedStamp: 'getSelectedStamp',
            getLinksImported: 'getLinksImported',
        }),
        ...mapGetters('drawingsV2', {
            getShareMarkupData: 'getShareMarkupData',
        }),
        ...mapGetters('appStore', {
            getAuthData: 'getAuthData',
        }),
        annotations (): { [key in string]: PunchItemDrawingAnnotation[] } {
            return omit(this.model.annotations, 'punch')
        },
        canChangeVisibility (): boolean {
            return !this.targetedAnnotations.find((annotation: DrawingAnnotationInterface) => annotation.can_change_visibility === false)
        },
        isDrawingSetConstructionType (): boolean {
            return this.model.drawing_set_type === SetType.CONSTRUCTION
        },
        linksUuids (): string[] {
            return this.model.links.map((link: Link) => link.uuid)
        },
    },
    watch: {
        addingAnnotation: {
            handler (newVal: Annotation, oldVal: Annotation): void {
                this.toggleAnnotationClass(newVal)

                if (oldVal.type === RightSideBar.PUNCH && !newVal.type) {
                    this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', RightSideBar.PUNCH)
                }
            },
        },
        selectedExistingAnnotation: {
            handler (newVal: SelectedExistingAnnotation, oldVal: SelectedExistingAnnotation): void {
                const { annotationManager } = this.instance.Core

                if (newVal.skipEvent) {
                    if (newVal.uuid) {
                        this.$store.commit('drawingMarkup/SET_ANNOTATION_CONTENT', newVal.type)
                        this.$store.commit('drawingMarkup/SET_PANEL_CONTENT', RightSideBar.MARKUPS)
                    }
                    return
                }

                if (newVal.uuid && newVal.uuid !== oldVal.uuid) {
                    annotationManager.deselectAllAnnotations()
                    annotationManager.selectAnnotation(annotationManager.getAnnotationById(newVal.uuid))
                }

                if (newVal.uuid === '' && oldVal.uuid !== undefined && oldVal.uuid !== '') {
                    annotationManager.deselectAnnotation(annotationManager.getAnnotationById(oldVal.uuid))
                }
            },
        },
        selectedAnnotation: {
            handler (newVal: unknown, oldVal: unknown): void {
                if (oldVal.type === RightSideBar.PUNCH && !newVal.type) {
                    this.hideAnnotation(oldVal.uuid)
                }
            },
        },
        'selectedStamp.code': {
            async handler (_: Stamp, oldVal: Stamp): Promise<void> {
                if (!oldVal) {
                    return
                }

                const { annotationManager } = this.instance.Core

                const blob = new Blob(
                    [this.generateAnnotationIconSvg(this.selectedStamp.color, this.selectedStamp.code, RightSideBar.PUNCH)],
                    { type: 'image/svg+xml' },
                )

                const dataUrl = await new Promise<string>((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = _e => resolve(reader.result as string)
                    reader.onerror = _e => reject(reader.error)
                    reader.onabort = _e => reject(new Error('Read aborted'))
                    reader.readAsDataURL(blob)
                })

                const annotationBeingAdded = await annotationManager.getAnnotationsList()[annotationManager.getAnnotationsList().length - 1]
                await annotationBeingAdded.setImageData(dataUrl)
                await annotationManager.redrawAnnotation(annotationBeingAdded)

                const updatedXfdf = await annotationManager.exportAnnotationCommand()

                this.$store.commit('drawingMarkup/SET_SELECTED_ANNOTATION', {
                    xfdf: updatedXfdf,
                    uuid: this.selectedAnnotation.uuid,
                    type: this.selectedAnnotation.type,
                    f: this.selectedAnnotation.f,
                })
            },
        },
        loaded (isDocumentLoaded: boolean): void {
            if (isDocumentLoaded) {
                this.setDefaultTool()
            }
        },
    },
    beforeUnmount () {
        this.$store.commit('drawingMarkup/SET_SELECTED_STAMP', {})
    },
    methods: {
        setDefaultTool (): void {
            const { docViewer } = this.instance
            const stampTool = docViewer.getTool('AnnotationSelectTool')
            docViewer.setToolMode(stampTool)
        },
        setCurrentUser (): void {
            const { annotationManager } = this.instance.Core
            annotationManager.setCurrentUser(`${ this.getAuthData.u_firstname } ${ this.getAuthData.u_lastname }`)
        },
        addRotateButtons (): void {
            this.instance.UI.setHeaderItems((header: unknown) => {
                const toolButton = header.get('selectToolButton')
                toolButton.insertAfter({
                    type: 'actionButton',
                    img: 'ic_rotate_right_black_24px',
                    onClick: () => {
                        this.instance.UI.rotateClockwise()
                    },
                    dataElement: 'rotateClockwise',
                    title: 'Rotate Clockwise',
                })
                const rotateClockwise = header.get('rotateClockwise')
                rotateClockwise.insertAfter({
                    type: 'actionButton',
                    img: 'ic_rotate_left_black_24px',
                    onClick: () => {
                        this.instance.UI.rotateCounterClockwise()
                    },
                    dataElement: 'rotateCounterClockwise',
                    title: 'Rotate Counterclockwise',
                })
            })
        },
        async toggleAnnotationClass (val: Annotation): Promise<void> {
            const iframeDoc = this.instance.UI.iframeWindow.document
            const docContainer = iframeDoc.querySelector('[data-element="documentContainer"]')
            const headerToolsContainer = iframeDoc.querySelector('[data-element="toolsHeader"]')

            docContainer.classList.value = docContainer.classList.value.replace(/io-adding-\w+/g, '')
            headerToolsContainer.classList.value = headerToolsContainer.classList.value.replace(/io-adding-\w+/g, '')

            if (val.status) {
                docContainer.classList.add('io-adding-' + val.type)
                headerToolsContainer.classList.add('io-adding-' + val.type)
                await this.setStamp(val.type)
            }
        },
        addPdftronMarkupEvents (): void {
            const { documentViewer, annotationManager } = this.instance.Core

            /**
             * We need to delete all injected external links annotations
             * before generating our own since they end up conflicting with each other,
             * breaking the functionality of the links
             */
            documentViewer.addEventListener('annotationsLoaded', () => {
                this.disableEvents()

                const externalLinkAnnotations = annotationManager
                    .getAnnotationsList()
                    .filter(this.externalLinkAnnotation)

                annotationManager.deleteAnnotations(externalLinkAnnotations)

                this.enableEvents()
            })

            annotationManager.addEventListener(
                'annotationChanged',
                async (annotations: Core.Annotations.MarkupAnnotation[], action: string, info: Core.AnnotationManager.AnnotationChangedInfoObject,
                ): Promise<void> => {
                    if (!this.eventsEnabled) {
                        return
                    }

                    const [firstAnnotation] = annotations

                    let targetedAnnotation = {
                        id: '',
                    }

                    for (const key in this.annotations) {
                        for (const index in this.annotations[key]) {
                            if (this.annotations[key][index].uuid === firstAnnotation.Id && this.manualAnnotation.uuid !== firstAnnotation.Id) {
                                targetedAnnotation = this.annotations[key][index]
                            }
                        }
                    }

                    if (action === 'modify') {
                        const xfdf = await annotationManager.exportAnnotationCommand()
                        if (targetedAnnotation.id !== '') {
                            await this.modifyPdftronMarkup(targetedAnnotation.id, xfdf)
                        } else {
                            this.$store.commit('drawingMarkup/SET_SELECTED_ANNOTATION', {
                                xfdf: xfdf,
                                uuid: firstAnnotation.Id,
                                type: this.selectedAnnotation.type,
                            })
                        }

                        return
                    }

                    if (action === 'add' && !info?.imported) {
                        firstAnnotation.Subject = this.addingAnnotation.status ? 'PinIO' : 'NotIO'
                        firstAnnotation.NoResize = this.addingAnnotation.status
                        firstAnnotation.NoDelete = this.addingAnnotation.status
                        firstAnnotation.IsHoverable = false

                        if (this.addingAnnotation.status) {
                            firstAnnotation.disableRotationControl()
                            firstAnnotation.X = firstAnnotation.X + 16
                            firstAnnotation.Y = firstAnnotation.Y + 16

                            const xfdf = await annotationManager.exportAnnotationCommand()
                            this.$store.commit('drawingMarkup/SET_SELECTED_ANNOTATION', {
                                xfdf: xfdf,
                                uuid: firstAnnotation.Id,
                                type: this.addingAnnotation.type,
                                f: 3,
                            })
                            this.$emit('annotationChanged', {
                                xfdf,
                                uuid: firstAnnotation.Id,
                                type: this.addingAnnotation.type,
                            })

                            if (this.addingAnnotation.type === 'rfi') {
                                this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', 'rfi')
                            }

                            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                                status: false,
                                type: RightSideBar.DEFAULT,
                            })

                        } else if (this.getLinksImported) {
                            const xfdf = await annotationManager.exportAnnotationCommand()
                            await this.addPdftronMarkup(firstAnnotation.Id, xfdf, firstAnnotation.ToolName, firstAnnotation.Color)
                            return
                        }
                    }

                    if (action === 'delete' && isEmpty(this.addingAnnotation)) {
                        await this.deletePdftronMarkup(targetedAnnotation.id)
                        return
                    }
                })
        },
        async getModel (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const { data } = await setsClient.getDrawingVersion(this.model.drawing.current_version_id)
                this.$store.commit('drawingMarkup/SET_MODEL', data)
            })
        },
        async deletePdftronMarkup (id: string): Promise<void> {
            this.load(async (): Promise<void> => {
                await drawingAnnotationsClient.deleteAnnotation(id)
                await this.getModel()
            })
        },
        async addPdftronMarkup (uuid: string, xfdf: string, toolName: string, color?: {
            R: number,
            G: number,
            B: number,
            A: number
        }): Promise<void> {
            return this.load(async (): Promise<void> => {
                await drawingAnnotationsClient.createAnnotation({
                    version_id: this.model.drawing.current_version_id,
                    type: 'pdftron',
                    xfdf_raw: xfdf,
                    uuid: uuid,
                    tool_name: this.mapPdftronAnnotationTool(toolName) ?? null,
                    tool_color: color ? `rgba(${ color?.R }, ${ color?.G }, ${ color?.B }, ${ color?.A })` : null,
                })
                await this.getModel()
            })
        },
        async modifyPdftronMarkup (id: string, xfdf: string): Promise<void> {
            return this.load(async (): Promise<void> => {
                await drawingAnnotationsClient.updateAnnotation(id, {
                    xfdf_raw: xfdf,
                })
                await this.getModel()
            })
        },
        getAnnotationIconDetails (type: string): unknown {
            switch (type) {
            case RightSideBar.PUNCH:
                return {
                    color: this.selectedStamp.color,
                    text: this.selectedStamp.code,
                }
            case RightSideBar.RFI:
                return {
                    color: '#FB6A00',
                    text: this.annotations.rfi?.length ? this.annotations.rfi?.length + 1 : 1,
                }
            case RightSideBar.COMMENTS:
                return {
                    color: '#1952E1',
                    text: this.annotations.comment?.length ? this.annotations.comment?.length + 1 : 1,
                }
            }
        },
        async setStamp (type: string): Promise<void> {
            const { docViewer } = this.instance

            const stampTool = docViewer.getTool('AnnotationCreateRubberStamp')

            stampTool.setStandardStamps([this.generateIconBlobUrl(
                this.getAnnotationIconDetails(type).color,
                this.getAnnotationIconDetails(type).text,
                type,
            )])

            docViewer.setToolMode(stampTool)
            const defaultStamps = await stampTool.getStandardStampAnnotations()
            // @ts-ignore
            await stampTool.setRubberStamp(defaultStamps[0])
        },
        removeStamp (): void {
            const { documentViewer } = this.instance.Core
            const textSelectTool = documentViewer.getTool('TextSelect')

            documentViewer.setToolMode(textSelectTool)
        },
        addAnnotationSelectEvents (): void {
            const { annotationManager } = this.instance.Core

            annotationManager.addEventListener('annotationSelected', async (annotations: Core.Annotations.Annotation, action: string) => {

                if (!this.eventsEnabled) {
                    return
                }

                let isNewAnnotation = true
                let annotationType = this.addingAnnotation.type

                for (const key in this.annotations) {
                    if (key === 'pdftron') {
                        for (const index in this.annotations[key]) {
                            for (const i in annotations) {
                                if (this.annotations[key][index].uuid === annotations[i].Id && this.manualAnnotation.uuid !== annotations[i].Id) {
                                    if (action === 'selected') {
                                        if (!this.targetedAnnotations.find(item => item.uuid == annotations[i].Id)) {
                                            this.targetedAnnotations.push(this.annotations[key][index])
                                        }
                                    } else {
                                        this.targetedAnnotations = []
                                    }
                                }
                            }
                        }
                    }

                    const annotationFound = this.annotations[key].some((annotation: unknown) => {
                        return annotation.uuid === annotations[0].Id
                    })

                    if (annotationFound) {
                        isNewAnnotation = false
                        annotationType = key === 'punch_v2' ? RightSideBar.PUNCH : key
                    }
                }

                if (action === 'selected') {
                    if (annotations[0].Subject === 'PinIO') {
                        annotations[0].disableRotationControl()
                        this.instance.UI.disableElements(['annotationPopup'])
                    }

                    if (annotations[0].Subject === 'NotIO') {
                        const annotationPopupItems = this.instance.UI.annotationPopup.getItems()
                        const lastItem = annotationPopupItems[annotationPopupItems.length - 1]
                        const hasShareButton = annotationPopupItems.find(item => item.dataElement === 'annotationShareButton')

                        if (!hasShareButton) {
                            this.instance.UI.annotationPopup.add([{
                                dataElement: 'annotationShareButton',
                                type: 'actionButton',
                                img: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share-2"><circle fill="none" cx="18" cy="5" r="3"></circle><circle fill="none" cx="6" cy="12" r="3"></circle><circle fill="none" cx="18" cy="19" r="3"></circle><line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line><line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line></svg>',
                                className: 'io-annotation-share',
                                skipEvent: false,
                            }], lastItem.dataElement)
                        }
                    }

                    if (!isNewAnnotation && (this.selectedExistingAnnotation.type !== annotationType || this.selectedExistingAnnotation.uuid !== annotations[0].Id)) {
                        this.$store.commit('drawingMarkup/SET_PANEL_CONTENT', annotationType)
                        this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                            uuid: annotations[0].Id,
                            type: annotationType,
                            skipEvent: true,
                        })
                    }

                    if (this.canChangeVisibility) {
                        this.instance.UI.updateElement('annotationShareButton', {
                            title: this.$t('drawing.annotations.visibility.change'),
                            onClick: () => {
                                this.$store.commit('drawingsV2/SET_SHARE_MARKUP_POPUP_SHOWN', true)
                                this.$store.commit('drawingsV2/SET_SHARE_MARKUP_DATA', this.targetedAnnotations)
                            },
                        })
                    } else {
                        this.instance.UI.updateElement('annotationShareButton', {
                            className: 'io-annotation-share io-disabled',
                            title: this.$t('Visibility settings can only be edited by Markup Author or Workspace Admin'),
                            onClick: () => {
                                return false
                            },
                        })
                    }

                    return
                }

                if (action === 'deselected' && annotations[0].Subject === 'PinIO') {
                    this.instance.UI.enableElements(['annotationPopup'])
                    this.$store.commit('drawingMarkup/CLEAR_SELECTED_ANNOTATION')

                    if (this.selectedExistingAnnotation.uuid === annotations[0].Id) {
                        this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                            uuid: '',
                            type: RightSideBar.DEFAULT,
                            skipEvent: true,
                        })
                    }

                    if (isNewAnnotation) {
                        this.hideAnnotation(annotations[0].Id)
                    }
                }
            })
        },
        hideAnnotation (uuid: string): void {
            const { annotationManager } = this.instance.Core
            const annotationToHide = annotationManager.getAnnotationById(uuid)

            annotationManager.hideAnnotation(annotationToHide)
        },
        addEscapeEvent (): void {
            const { documentViewer } = this.instance.Core

            documentViewer.addEventListener('keyDown', async (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    this.$emit('cancelAddingAnnotation')
                }
            })
        },
        deselectAllAnnotations (): void {
            this.instance.Core.annotationManager.deselectAllAnnotations()
        },
        mapPdftronAnnotationTool (toolName: string): string {
            const mappedTools = [
                {
                    value: 'TEXT',
                    tools: [
                        'AnnotationCreateTextHighlight',
                        'AnnotationCreateTextUnderline',
                        'AnnotationCreateTextStrikeout',
                        'AnnotationCreateTextSquiggly',
                        'AnnotationCreateFreeText',
                    ],
                },
                {
                    value: 'ELLIPSE',
                    tools: ['AnnotationCreateEllipse'],
                },
                {
                    value: 'ARC',
                    tools: ['AnnotationCreateArc'],
                },
                {
                    value: 'CLOUD',
                    tools: ['AnnotationCreatePolygonCloud'],
                },
                {
                    value: 'POLYGON',
                    tools: ['AnnotationCreatePolygon'],
                },
                {
                    value: 'POLYLINE',
                    tools: ['AnnotationCreatePolyline'],
                },
                {
                    value: 'LINE',
                    tools: ['AnnotationCreateLine'],
                },
                {
                    value: 'ARROW',
                    tools: ['AnnotationCreateArrow'],
                },
                {
                    value: 'MEASUREMENT',
                    tools: [
                        'AnnotationCreateDistanceMeasurement',
                        'AnnotationCreateArcMeasurement',
                        'AnnotationCreateEllipseMeasurement',
                        'AnnotationCreatePerimeterMeasurement',
                        'AnnotationCreateCountMeasurement',
                        'AnnotationCreateCloudyRectangularAreaMeasurement',
                        'AnnotationCreateRectangularAreaMeasurement',
                    ],
                },
                {
                    value: 'STAMP',
                    tools: [
                        'AnnotationCreateDateFreeText',
                        'AnnotationCreateRubberStamp',
                        'AnnotationCreateFreeHand',
                        'AnnotationCreateFileAttachment',
                        'AnnotationCreateStamp',
                    ],
                },
            ]


            mappedTools.forEach(tool => {
                const newTools = []
                tool.tools.forEach(toolName => {
                    newTools.push(toolName)
                    for (let i = 1; i <= 4; i++) {
                        const versionedToolName = `${ toolName }${ i }`
                        newTools.push(versionedToolName)
                    }
                })
                tool.tools = newTools
            })

            return mappedTools.find(tool => tool.tools.includes(toolName))?.value ?? null
        },
        importAnnotations (redrawAnnotations: boolean): void {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { annotationManager, Annotations, Actions } = this.instance.Core

            this.$store.commit('drawingMarkup/SET_LINKS_IMPORTED', false)
            this.model.links.forEach(async (link: Link) => {
                const newLink = new Annotations.Link({
                    Subject: `${ link.target_version_label }: ${ link.target_version_title }`,
                })
                const importingAnnotation = await annotationManager.importAnnotations(link.xfdf)
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { PageNumber, X, Y, Height, Width } = importingAnnotation[0]
                Object.assign(newLink, { PageNumber, X, Y, Height, Width })

                newLink.StrokeColor = new Annotations.Color(0, 165, 228)
                newLink.StrokeStyle = 'none'
                newLink.backgroundColor = new Annotations.Color(0, 165, 228, 0.1)
                newLink.StrokeThickness = 1
                newLink.NoMove = true
                // add padding to annotation box
                newLink.setWidth(newLink.getWidth() + 4)
                newLink.setHeight(newLink.getHeight() + 4)
                const previousRect = newLink.getRect()
                const generatedRect = new this.instance.Core.Math.Rect(
                    previousRect.x1 - 2,
                    previousRect.y1 - 2,
                    previousRect.x2 - 2,
                    previousRect.y2 - 2,
                )
                newLink.setRect(generatedRect)

                // generate and add a drawing sheet URL to annotation
                const route = this.$router.resolve({
                    name: 'drawings-sheets-show',
                    params: {
                        sheetId: this.$store.getters['drawingMarkup/getModel']?.drawing.id,
                        versionId: link.target_version_id,
                    },
                })
                const targetURI = new URL(route.href, window.location.origin).href
                newLink.addAction('U', new Actions.URI({
                        uri: targetURI,
                    }),
                )

                annotationManager.addAnnotation(newLink)
                annotationManager.groupAnnotations(importingAnnotation[0], [newLink])
            })

            for (const key in this.annotations) {
                this.annotations[key].forEach(async (annotation: unknown) => {
                    if (this.manualAnnotation.uuid !== annotation.uuid) {
                        if (this.disableExistingAnnotations && annotation.uuid !== this.manualAnnotation.uuid) {
                            annotation.xfdf_raw = annotation.xfdf_raw.replaceAll('flags="print"', 'flags="print,readonly"')
                        }

                        annotation.xfdf_raw = annotation.xfdf_raw.replace(/<apref[^>]*\/>/g, '')

                        await annotationManager.importAnnotationCommand(annotation.xfdf_raw)
                    }
                })
            }

            if (this.manualAnnotation.xfdf) {
                annotationManager.importAnnotationCommand(this.manualAnnotation.xfdf)
            }

            if (redrawAnnotations) {
                annotationManager.selectAnnotations(annotationManager.getAnnotationsList())
                annotationManager.deselectAllAnnotations()
            }

            if (this.manualAnnotation.uuid) {
                annotationManager.selectAnnotation(annotationManager.getAnnotationById(this.manualAnnotation.uuid))
            }
        },
        hideAllAnnotations (): void {
            const { annotationManager } = this.instance.Core

            const currentAnnotations = annotationManager.getAnnotationsList()
            annotationManager.hideAnnotations(currentAnnotations)
        },
        disableEvents (): void {
            this.eventsEnabled = false
        },
        enableEvents (): void {
            this.eventsEnabled = true
        },
        generateDivElement (text: string, className: string, annotationType: string): HTMLDivElement {
            const divElement = document.createElement('div')
            divElement.classList.add('io-context-popup-item')
            divElement.classList.add(className)
            divElement.innerText = text
            divElement.onclick = () => {
                this.$store.commit('drawingMarkup/SET_ANNOTATION_CONTENT', annotationType)
                this.$store.commit('drawingMarkup/SET_PANEL_CONTENT', RightPanelTab.MARKUPS)

                if (annotationType === RightSideBar.PUNCH) {
                    this.$store.commit('drawingMarkup/SET_SELECT_STAMP_POPUP', true)
                    return
                }

                this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                    status: true,
                    type: annotationType,
                })
            }

            return divElement
        },
        replaceContextMenuPopup (): void {
            this.instance.UI.contextMenuPopup.update([
                {
                    type: 'customElement',
                    render: () => this.generateDivElement(this.$t('Add Comment'), 'io-comment', RightSideBar.COMMENTS),
                },
                this.isDrawingSetConstructionType && {
                    type: 'customElement',
                    render: () => this.generateDivElement(this.$t('Add RFI'), 'io-rfi', RightSideBar.RFI),
                },
                {
                    type: 'customElement',
                    render: () => this.generateDivElement(this.$t('Add Punch Item'), 'io-punch-item', RightSideBar.PUNCH),
                },
            ])
        },
        addHoverInfo (): void {
            this.instance.UI.setAnnotationContentOverlayHandler(annotation => {
                const div = document.createElement('div')
                div.appendChild(document.createTextNode(`${ annotation.Subject }`))
                return div
            })
        },
        externalLinkAnnotation (annotation: Core.Annotations.Annotation): boolean {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { Annotations } = this.instance.Core
            const externalLink = !this.linksUuids.includes(annotation.InReplyTo)

            return annotation instanceof Annotations.Link && externalLink
        },
    },
})
