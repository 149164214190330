import { mapState } from 'vuex'
import { toString } from 'lodash'
import Banner from './parts/banner.vue'

export default {
    name: 'TopBanners',
    components: {
        Banner
    },

    data () {
        return {
        }
    },

    props: {
        context: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        ...mapState('notification', {
            banners: state => state.banners
        }),

        /**
         * Get active banners
         */
        activeBanners () {
            return this.banners.filter(banner => {
                return this.isBannerActive(banner)
            })
        }
    },
    methods: {
        /**
         * Check if banner is active
         */
        isBannerActive (banner) {
            if (banner.context && banner.context !== this.context) {
                return false
            }
            if (banner.route) {
                if (banner.routeIncludes) {
                    if (!this.$route.name.includes(banner.route)) {
                        return false
                    }
                } else {
                    if (this.$route.name !== banner.route) {
                        return false
                    }
                }
            }
            if (banner.path) {
                if (banner.pathIncludes) {
                    if (!this.$route.path.includes(banner.path)) {
                        return false
                    }
                } else {
                    if (this.$route.path !== banner.path) {
                        return false
                    }
                }
            }

            if (banner.requiredParams) {
                for (let param in banner.requiredParams) {
                    if (banner.requiredParams[param] === undefined) {
                        return false
                    }
                    if (toString(this.$route.params[param]) !== toString(banner.requiredParams[param])) {
                        return false
                    }
                }
            }
            return true
        }
    },
}
