<template>
    <div 
        class="io-donut-chart" 
        :style="outerChart" 
        :class="{ 'io-completed': value === 100 }"
        data-testid="donut-chart"
    >
        <div class="io-donut-chart__inner" :style="innerChart" data-testid="donut-chart-inner"></div>
        <div 
            v-if="showValue" 
            class="io-donut-chart__value"  
            :class="{ 'io-red': negativeVal }"
            data-testid="donut-chart-value-wrapper"
        >
            <span data-testid="donut-chart-value">{{ (negativeVal ? '-' + value : value) + '%' }}</span>
        </div>
    </div>
</template>

<script lang="ts" src="./DonutChart.ts"></script>
<style lang="scss" src="./DonutChart.scss" scoped></style>
