<template>
    <div class="io-form-group">
        <IOLabel
            :text="label" 
            :is-required="isRequired && !readonly"
            :type="labelType"
            :for="label"
            :class="{ 'io-label--readonly': readonly }"
        />

        <p v-if="readonly" class="text-sm mt-5">
            {{ value }}
        </p>

        <IOInput
            v-else
            :id="label"
            ref="inputRef"
            :placeholder="placeholder"
            :valid="valid"
            :error-message="errorMessage"
            :disabled="disabled"
            :maxlength="maxLength"
            :type="inputType"
            :autocomplete-items="autocompleteItems"
            v-model="value"
        />
    </div>
</template>

<script lang="ts" src="./FormGroup.ts"></script>
<style lang="scss" src="./FormGroup.scss" scoped></style>
