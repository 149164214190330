import { defineStore } from 'pinia'

import {
    Contact,
    FetchCompaniesPayload,
    fetchContactsByIdsPayload,
    CreateContactPayload,
    UpdateGlobalWorkspaceAppTypePayload,
    fetchContactsDataPayload,
} from '@/components/invite-modal-v3/interfaces/InviteModalInterface'

import defaultState from './stateObject.ts'
import { cloneDeep, uniqBy } from 'lodash'

import inviteModalClient from '../api-clients/inviteModalClient'
export const inviteModalStore = defineStore('inviteModal', {
    state: () => ({
        inviteModalData: {}
    }),

    getters: {
        assignedResourceId: (state) => {
            return (key) => state.inviteModalData[key]?.resourceId
        },
        assignedResourceType: (state) => {
            return (key) => state.inviteModalData[key]?.resourceType
        },
        assignedRole: (state) => {
            return (key) => state.inviteModalData[key]?.role
        },
        assignedProjectGlobalId: (state) => {
            return (key) => state.inviteModalData[key]?.projectGlobalId
        },
        isSearchValidEmail: (state) => {
            const regex = /\S+@\S+\.\S+/

            return (key) => regex.test(state.inviteModalData[key]?.search ?? '')
        },
        isSearchDisabled: (state) => {
            return (key) => state.inviteModalData[key]?.isSearchDisabled ?? false
        },
        isLoading: (state) => {
            return (key) => state.inviteModalData[key]?.isLoading ?? false
        },
        isClosing: (state) => {
            return (key) => state.inviteModalData[key]?.isClosing ?? false
        },
        invitedContacts: (state) => {
            return (key) => state.inviteModalData[key]?.invitedContacts ?? []
        },
        bookmarkedContacts: (state) => {
            return (key) => state.inviteModalData[key]?.bookmarkedContacts ?? []
        },
        removedContacts: (state) => {
            return (key) => state.inviteModalData[key]?.removedContacts ?? []
        },
        replacedContacts: (state) => {
            return (key) => state.inviteModalData[key]?.replacedContacts ?? []
        },
        addedToInvited: (state) => {
            return (key) => state.inviteModalData[key]?.addedToInvited ?? []
        },
        addedToBookmarked: (state) => {
            return (key) => state.inviteModalData[key]?.addedToBookmarked ?? []
        },
        removedFromInvited: (state) => {
            return (key) => state.inviteModalData[key]?.removedFromInvited ?? []
        },
        removedFromBookmarked: (state) => {
            return (key) => state.inviteModalData[key]?.removedFromBookmarked ?? []
        },
        contactsList: (state) => {
            return (key) => state.inviteModalData[key]?.contactsList ?? []
        },
        search: (state) => {
            return (key) => state.inviteModalData[key]?.search ?? ''
        },
        contact: (state) => {
            return (key) => state.inviteModalData[key]?.contact ?? {}
        },
        isContactFormValid: (state) => {
            return (key) => state.inviteModalData[key]?.isContactFormValid ?? false
        },
        isInfoPanelVisible: (state) => {
            return (key) => state.inviteModalData[key]?.isInfoPanelVisible ?? true
        },
        isIntentionallyAddingContact: (state) => {
            return (key) => state.inviteModalData[key]?.isIntentionallyAddingContact ?? false
        },
        isUpdatingWorkspaceType: (state) => {
            return (key) => state.inviteModalData[key]?.isUpdatingWorkspaceType ?? false
        },
        isFormVisible: (state) => {
            return (key) => state.inviteModalData[key]?.isFormVisible ?? false
        },
        existingWorkspaces: (state) => {
            return (key) => state.inviteModalData[key]?.existingWorkspaces ?? []
        },
        companiesList: (state) => {
            return (key) => state.inviteModalData[key]?.companiesList ?? []
        },
        countriesList: (state) => {
            return (key) => state.inviteModalData[key]?.countriesList ?? []
        },
        statesList: (state) => {
            return (key) => state.inviteModalData[key]?.statesList ?? []
        },
        isPaginating: (state) => {
            return (key) => state.inviteModalData[key]?.isPaginating ?? false
        },
        skip: (state) => {
            return (key) => state.inviteModalData[key]?.skip ?? 0
        },
        take: (state) => {
            return (key) => state.inviteModalData[key]?.take ?? 30
        },
        lastTaken: (state) => {
            return (key) => state.inviteModalData[key]?.lastTaken ?? null
        },
        isFetchingExistingWorkspaces: (state) => {
            return (key) => state.inviteModalData[key]?.isFetchingExistingWorkspaces ?? false
        },
    },

    actions: {
        setResource (
            key: string,
            id: string|number,
            type: string,
            projectGlobalId: string|null,
            filterByProjectGlobalId: boolean = false
        ): void {
            this.inviteModalData[key].resourceId = id
            this.inviteModalData[key].resourceType = type
            this.inviteModalData[key].projectGlobalId = projectGlobalId
            this.inviteModalData[key].filterByProjectGlobalId = filterByProjectGlobalId
        },

        setRole (key: string, role: string): void {
            this.inviteModalData[key].role = role
        },

        setStateObject (key: string): void {
            this.inviteModalData[key] = cloneDeep(defaultState)
        },

        async fetchCountries (key: string): void {
            const { data } = await inviteModalClient.fetchCountries();

            this.inviteModalData[key].countriesList = data
        },

        async fetchStates (key: string): void {
            const { data } = await inviteModalClient.fetchStates()

            this.inviteModalData[key].statesList = data
        },

        async fetchContactsByIds (payload: fetchContactsByIdsPayload, key: string): Promise<Contact[]> {
            const { data } = await inviteModalClient.fetchContactsByIds({
                resourceId: this.inviteModalData[key].resourceId,
                resourceType: this.inviteModalData[key].resourceType,
                projectGlobalId: this.inviteModalData[key].filterByProjectGlobalId ? this.inviteModalData[key].projectGlobalId : null,
                contactPersonIds: payload.contactPersonIds,
            })

            return data
        },

        async fetchContactsData (payload: fetchContactsDataPayload, key: string): Promise<Contact[]> {
            try {
                const { data } = await inviteModalClient.fetchContactsData({
                    filters: { ...payload.filters },
                    pagination: { ...payload.pagination },
                }) as { items: Contact[] }

                if (key) {
                    this.setLastTaken(data.pagination?.taken ?? 0, key)
                }

                return data.items
            } catch (error) {
                throw error
            } finally {
                if (key) {
                    this.inviteModalData[key].isLoading = false
                    this.inviteModalData[key].isPaginating = false
                }
            }
        },

        async fetchAssignedContactsByResource (key: string): Promise<Contact[]> {
            if (!this.inviteModalData[key].resourceId || !this.inviteModalData[key].resourceType) {
                return []
            }
            const { data } = await inviteModalClient.fetchAssignedContactsByResource({
                resourceId: this.inviteModalData[key].resourceId,
                resourceType: this.inviteModalData[key].resourceType,
            }) as Contact[]

            return data
        },

        async fetchCompanies (payload: FetchCompaniesPayload, key: string): Promise<void> {
            try {
                const { data } = await inviteModalClient.fetchCompanies({
                    search: payload.search,
                    modal: payload.modal,
                })

                this.inviteModalData[key].companiesList = data
            }
            catch (error) {
                throw error
            }
        },

        async fetchExistingWorkspaces(email: string, key: string): Object {
            try {
                const { data } = await inviteModalClient.fetchExistingWorkspaces(email)

                this.inviteModalData[key].existingWorkspaces = data.data
            }
            catch (error) {
                throw error
            }
            finally {
                this.inviteModalData[key].isFetchingExistingWorkspaces = false
            }
        },

        async createContact (payload: CreateContactPayload, key: string): Contact {
            try {
                const { data } = await inviteModalClient.createContact(payload)

                return data
            }
            catch (error) {
                throw error
            }
        },

        async updateGlobalWorkspaceAppType(payload: UpdateGlobalWorkspaceAppTypePayload, key: string): Object {
            try {
                const { data } = await inviteModalClient.updateGlobalWorkspaceAppType(payload)

                return data
            }
            catch (error) {
                throw error
            }
        },

        resetState (key: string): void {
            this.inviteModalData[key].contactsList = []
            this.inviteModalData[key].search = ''
            this.inviteModalData[key].skip = 1
            this.inviteModalData[key].isClosing = false
            this.inviteModalData[key].isIntentionallyAddingContact = false
        },

        setContact (contact: Contact, key: string): void {
            this.inviteModalData[key].contact = {
                id: contact.id,
                email: contact.email,
                first_name: contact.firstname,
                last_name: contact.lastname,
                is_new_company: false,
                workspace: {
                    id: contact.workspace.id,
                    name: contact.workspace.name,
                    app_type: contact.workspace.app_type,
                }
            }
        },

        resetContact (key: string): void {
            this.inviteModalData[key].contact = {
                email: null,
                first_name: null,
                last_name: null,
                action_type: null,
                is_new_company: false,
                workspace: {
                    id: null,
                    name: null,
                    app_type: null,
                    division_id: null,
                    address_line1: null,
                    address_line2: null,
                    zip: null,
                    city: null,
                    state_id: null,
                    country_id: null,
                }
            }
        },

        setSearch (searchValue: string, key: string): void {
            this.inviteModalData[key].search = searchValue

            if (! searchValue) {
                this.resetFormVisibility(key)
            }
        },

        setLoading (isLoading: boolean, key: string): void {
            this.inviteModalData[key].isLoading = isLoading
        },

        setPaginating (isPaginating: boolean, key: string): void {
            this.inviteModalData[key].isPaginating = isPaginating
        },

        setLastTaken (lastTaken: number, key: string): void {
            this.inviteModalData[key].lastTaken = lastTaken
        },

        setFetchingExistingWorkspaces (isFetchingExistingWorkspaces: boolean, key: string): void {
            this.inviteModalData[key].isFetchingExistingWorkspaces = isFetchingExistingWorkspaces
        },

        setClosing (isClosing: boolean, key: string): void {
            this.inviteModalData[key].isClosing = isClosing
        },

        setSearchDisabled (isSearchDisabled: boolean, key: string): void {
            this.inviteModalData[key].isSearchDisabled = isSearchDisabled
        },

        setContactsList (payload: Array<Contact>, key: string): void {
            this.inviteModalData[key].contactsList.push(...payload)

            this.shouldShowForm(key)
        },

        setInitialInvitedList (contactPersons: Array<Contact>, key: string): void {
            this.inviteModalData[key].initialInvitedContacts = contactPersons
        },

        setInitialBookmarkedList (contactPersons: Array<Contact>, key: string): void {
            this.inviteModalData[key].initialBookmarkedContacts = contactPersons
        },

        setBookmarkedList (contactPersons: Array<Contact>, key: string): void {
            this.inviteModalData[key].bookmarkedContacts = uniqBy(contactPersons, 'id')
        },

        setInvitedList (contactPersons: Array<Contact>, key: string): void {
            this.inviteModalData[key].invitedContacts = uniqBy(contactPersons, 'id')
        },

        setRemovedList (contactPersons: Array<Contact>, key: string): void {
            this.inviteModalData[key].removedContacts = uniqBy(contactPersons, 'id')
        },

        setReplacedList (contactPersons: Array<Contact>, key: string): void {
            this.inviteModalData[key].replacedContacts = uniqBy(contactPersons, 'id')
        },

        clearSelectedPersons (key: string): void {
            this.inviteModalData[key].invitedContacts = []
            this.inviteModalData[key].bookmarkedContacts = []
            this.inviteModalData[key].removedContacts = []
            this.inviteModalData[key].replacedContacts = []
        },

        setRemovedFromInvitedList (contactPersons: Array<Contact>, key: string): void {
            this.inviteModalData[key].removedFromInvited = contactPersons
        },

        setRemovedFromBookmarkedList (contactPersons: Array<Contact>, key: string): void {
            this.inviteModalData[key].removedFromBookmarked = contactPersons
        },

        pushInvitedContact (contact: Contact, key: string): void {
            this.inviteModalData[key].invitedContacts.push(contact)

            if (this.inviteModalData[key].initialInvitedContacts.find(item => item.id === contact.id)) {
                this.inviteModalData[key].addedToInvited.push(contact)
            }

            this.inviteModalData[key].removedFromInvited = this.inviteModalData[key].removedFromInvited.filter(item => item.id !== contact.id)
        },

        pushBookmarkedContact (contact: Contact, key: string): void {
            this.inviteModalData[key].bookmarkedContacts.push(contact)

            if (this.inviteModalData[key].initialBookmarkedContacts.find(item => item.id === contact.id)) {
                this.inviteModalData[key].addedToBookmarked.push(contact)
            }

            this.inviteModalData[key].removedFromBookmarked = this.inviteModalData[key].removedFromBookmarked.filter(item => item.id !== contact.id)
        },

        pushRemovedContact (contact: Contact, key: string): void {
            this.inviteModalData[key].removedContacts.push(contact)
        },

        pushReplacedContact (contact: Contact, key: string): void {
            this.inviteModalData[key].replacedContacts.push(contact)
        },

        removeFromSelectedContacts (contact: Contact, key: string): void {
            this.inviteModalData[key].invitedContacts = this.inviteModalData[key].invitedContacts.filter(item => item.id !== contact.id)
            this.inviteModalData[key].bookmarkedContacts = this.inviteModalData[key].bookmarkedContacts.filter(item => item.id !== contact.id)
            this.inviteModalData[key].removedContacts = this.inviteModalData[key].removedContacts.filter(item => item.id !== contact.id)
            this.inviteModalData[key].replacedContacts = this.inviteModalData[key].replacedContacts.filter(item => item.id !== contact.id)

            if (this.inviteModalData[key].initialInvitedContacts.find(item => item.id === contact.id)) {
                this.inviteModalData[key].removedFromInvited.push(contact)
            }

            if (this.inviteModalData[key].initialBookmarkedContacts.find(item => item.id === contact.id)) {
                this.inviteModalData[key].removedFromBookmarked.push(contact)
            }
        },

        setIsFormValid (isValid: boolean, key: string): void {
            this.inviteModalData[key].isContactFormValid = isValid
        },

        hideInfoPanel (key: string): void {
            this.inviteModalData[key].isInfoPanelVisible = false
        },

        setStoreStringField (fieldName: string, fieldValue: string, key: string): void {
            this.inviteModalData[key][fieldName] = fieldValue
        },

        setIntentionallyAddingContact (value: boolean, key: string): void {
            this.inviteModalData[key].isIntentionallyAddingContact = value

            if (value === true) {
                this.shouldShowForm(key)
            }
        },

        setUpdatingWorkspaceType (value: boolean, key: string): void {
            this.inviteModalData[key].isUpdatingWorkspaceType = value

            if (value === true) {
                this.shouldShowForm(key)
            }
        },

        setNextRecordsSkip (key: string): void {
            this.inviteModalData[key].skip = this.inviteModalData[key].contactsList?.length || 0
        },

        resetRecordsSkip (key: string): void {
            this.inviteModalData[key].skip = 0
        },

        resetExistingWorkspaces (key: string): void {
            this.inviteModalData[key].existingWorkspaces = []
        },

        resetContactsList (key: string): void {
            this.inviteModalData[key].contactsList = []
        },

        resetFormVisibility (key: string): void {
            this.inviteModalData[key].isFormVisible = false
        },

        shouldShowForm (key: string) : void {
            this.inviteModalData[key].isFormVisible = false

            if (true === this.inviteModalData[key].isUpdatingWorkspaceType) {
                this.inviteModalData[key].isFormVisible = true
                return
            }

            if (this.isSearchValidEmail(key) && this.inviteModalData[key].contactsList?.length === 0) {
                this.inviteModalData[key].isFormVisible = true
                return
            }

            if (this.isSearchValidEmail(key) && this.inviteModalData[key].contactsList?.length > 0 && this.inviteModalData[key].isIntentionallyAddingContact) {
                this.inviteModalData[key].isFormVisible = true
                return
            }
        },
    }
})
