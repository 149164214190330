<template>
    <div class="main-loader-holder">
        <div class="main-loader-holder_loader" v-show="showLoader">
            <img class="main-loader-holder_loader_icon" src="/new-theme/img/loading_icon_logo.svg" alt="loader">
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showLoader: false
        }
    },
    name: 'MainLoader',
    mounted() {
        setTimeout(() => {
            this.showLoader = true
        }, 1000)
    }
}
</script>

<style scoped lang="scss">
.main-loader-holder {
    position: fixed;
    z-index: 32;
    width: 100%;
    height: 100vh;

    &_loader {
        display: block;
        position: absolute;
        top: 40%;
        left: 50%;
        height: 32px;
        width: 32px;
        margin: -25px 0 0 -25px;
        -webkit-animation: spin_loader 1.2s ease-in-out infinite;
        animation: spin_loader 1.2s ease-in-out infinite;

        &_icon {
            height: 100%;
            width: 100%;
        }
    }
}
</style>
