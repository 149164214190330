import { defineComponent } from 'vue'

export default defineComponent({
    name: 'PopupModal',
    props: {
        width: { type: [Number, String], default: 700 },
        zIndex: { type: Number },
        disableOutsideClose: { type: Boolean },
        disableEscClose: { type: Boolean },
        customId: { type: String },
        customClass: { type: String },
        fullScreen: { type: Boolean, default: true },
        showLoader: { type: Boolean },
        scrollable: { type: Boolean }
    },
    emits: ['close'],
    computed: {
        hasHeaderSlot (): boolean {
            return !!this.$slots.header
        },
        hasHeaderContentSlot (): boolean {
            return !!this.$slots.headerContent
        },
        hasFooterSlot (): boolean {
            return !!this.$slots.footer
        },
        contentStyles (): object {
            if (!this.fullScreen) {
                return {
                    height: '100%',
                    width: 'calc(100% - var(--left-menu-width))',
                    zIndex: this.zIndex ?? 9
                }
            } else {
                return {
                    height: '100%',
                    width: '100%',
                    zIndex: this.zIndex ?? 25
                }
            }
        },
        modalStyles (): object | null {
            if (!(/\d/.test(this.width.toString()))) {
                return { width: this.width }
            }
            return this.width ? { width: `${ this.width }px` } : null
        }
    },
    beforeMount () {
        document.addEventListener('keyup', this.handleKeyUp)
        document.body.classList.add('modal-open');
        if (true === this.fullScreen) {
            const menuLeftElement = document.getElementsByClassName('io-menu-left')[0];
            if (menuLeftElement) {
                menuLeftElement.classList.add('modal-open');
            }
        }
    },
    beforeUnmount () {
        document.removeEventListener('keyup', this.handleKeyUp)
        document.body.classList.remove('modal-open')
        if (true === this.fullScreen) {
            const menuLeftElement = document.getElementsByClassName('io-menu-left')[0];
            if (menuLeftElement) {
                menuLeftElement.classList.remove('modal-open');
            }
        }
    },
    methods: {
        handleClickOutside (): void {
            if (!this.disableOutsideClose) {
                this.close()
            }
        },
        handleKeyUp (e: KeyboardEvent): void {
            if (!this.disableEscClose && e.key === 'Escape') {
                this.close()
            }
        },
        close (): void {
            this.$emit('close')
        }
    }
})

