import { defineComponent } from 'vue'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import { mapGetters } from 'vuex'
import useLoader from '@/composables/useLoader.ts'
import drawingAnnotationsClient from '@/io-modules/drawings/api-clients/drawingAnnotationsClient.ts'
import Mentions from '@/components/mentions/mentions.vue'
import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'

export default defineComponent({
    components: {
        Mentions,
        DropdownButtonNew,
    },
    inject: ['mentionUsers'],
    props: {
        data: { type: Object },
        comment: { type: Object },
        lastReplyId: { type: String },
    },
    setup () {
        const { load } = useLoader({ globalSpinner: true })
        return { load }
    },
    data () {
        return {
            commentCollapsed: true,
            editMode: false,
            editedComment: '',
            mentionsIds: [] as string[],
        }
    },
    computed: {
        ...mapGetters('appStore', ['getAuthData']),
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
        }),
        isCreator (): boolean {
            return this.getAuthData.u_mongo === this.data.created_by_full_name.id
        },
        isLongReply (): boolean {
            const div = document.createElement('div')
            div.innerHTML = this.data.comment
            return div.innerText.length > 250
        },
        isLastReply (): boolean {
            return this.data.id === this.lastReplyId
        },
    },
    methods: {
        toggleCollapse (): void {
            this.commentCollapsed = !this.commentCollapsed
        },
        openEditMode (): void {
            this.editMode = true
            this.editedComment = this.data.comment
        },
        cancelEditMode (): void {
            this.editMode = false
            this.editedComment = ''
        },
        async saveComment (): Promise<void> {
            return this.load(async (): Promise<void> => {
                await drawingAnnotationsClient.updateCommentAnnotation({
                    id: this.data.id,
                    comment: this.editedComment,
                    shared_with: this.mentionsIds,
                })
                this.data.comment = this.editedComment
                this.cancelEditMode()
            })
        },
        async deleteReply (): Promise<void> {
            return this.load(async (): Promise<void> => {
                await drawingAnnotationsClient.deleteCommentAnnotation(this.data.id)
                this.showNotification('success', this.$t('Comment deleted'))
                const { data } = await setsClient.getDrawingVersion(this.model.drawing.current_version_id)
                this.$store.commit('drawingMarkup/SET_MODEL', data)
            })
        },
    },
})
