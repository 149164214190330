import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { PurchaseOrder } from '@/interfaces/modules/projects/purchase-orders/PurchaseOrderInterface'

const purchaseOrdersClient = {
    lazyListEndpoint: 'purchase-orders/lazy-list',

    get (purchaseOrderId: string): AxiosPromise {
        return client.get(`purchase-orders/${ purchaseOrderId }`)
    },

    post (payload: PurchaseOrder): AxiosPromise {
        return client.post('purchase-orders', payload)
    },

    delete (purchaseOrderId: string): AxiosPromise {
        return client.delete(`purchase-orders/${ purchaseOrderId }/`)
    },

    put (purchaseOrderId: string, payload: PurchaseOrder): AxiosPromise {
        return client.put(`purchase-orders/${ purchaseOrderId }`, payload)
    },

    associatePurchaseOrder (purchaseOrderId: string, invoiceId: string): AxiosPromise {
        return client.post(`purchase-orders/${ purchaseOrderId }/invoices/${ invoiceId }/associate`)
    },

    disassociatePurchaseOrder (invoiceId: string): AxiosPromise {
        return client.post(`purchase-orders/invoices/${ invoiceId }/disassociate`)
    },

    submitToAccounting (payload: { purchase_order_id: string }): AxiosPromise {
        return client.post('purchase-orders/submit-to-accounting', payload)
    },
}

export default purchaseOrdersClient
