import { defineComponent } from 'vue'

import dateTimeHelper from '@/helpers/dateTime'

import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import Files from '@/components/files-new/files.vue'

import { SetType } from '@/io-modules/drawings/enums/SetType'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { Document, Upload } from '@/io-modules/drawings/interfaces/Upload'
import { Set } from '@/io-modules/drawings/interfaces/Set'

export default defineComponent({
    name: 'SetFilesCreate',
    components: {
        AlertBox,
        Files,
    },
    props: {
        activeCurrentSet: {
            type: Object as () => Set,
            default: null,
        },
        type: {
            type: String as () => SetType,
            required: true,
        },
        activeDraftSets: {
            type: Array as () => Set[],
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        set: {
            type: Object as () => Partial<Set>,
            default: null,
        },
        document: {
            type: Object as () => Document,
            default: null,
        },
    },
    emits: ['update:date', 'update:set', 'update:document'],
    setup () {
        const validationUploaderOptions = { allowedExtensions: ['pdf'] }
        const uploaderArrayId = crypto.randomUUID()

        return { validationUploaderOptions, uploaderArrayId }
    },
    computed: {
        headers (): HeaderTable[] {
            return [
                {
                    text: this.$t('Filename'),
                    sort: false,
                },
                {
                    text: this.$t('File Size'),
                    sort: false,
                },
                {
                    sort: false,
                },
            ]
        },
        datePickerSettings (): { formatted: string, noClearButton: boolean, 'only-date': boolean } {
            return {
                formatted: dateTimeHelper.getDateFormat(),
                noClearButton: true,
                'only-date': true,
            }
        },
        currentSetTypeSelected (): boolean {
            return this.type === SetType.CONSTRUCTION
        },
    },
    created () {
        this.currentSetTypeSelected
        && this.activeCurrentSet
        && this.$emit('update:set', this.activeCurrentSet)
    },
    methods: {
        newFileAdded (upload: Upload): void {
            this.$emit('update:document', upload.document)
        },
        removeFile (): void {
            this.$emit('update:document', null)
        },
    },
})
