import { defineComponent } from 'vue'
import { throttle } from 'lodash'

import getCascadeAnimationDelay from '@/helpers/getCascadeAnimationDelay.ts'
import useLoader from '@/composables/useLoader.ts'
import punchStampsClient from '@/io-modules/punch-lists/api-clients/punchStampsClient'

import StampPreview from '@/io-modules/punch-lists/components/stamp-preview/StampPreview.vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import StampCreate from '@/io-modules/punch-lists/components/stamp-create/StampCreate.vue'

import { Stamp } from '@/io-modules/punch-lists/interfaces/Stamp'

export default defineComponent({
    name: 'StampsIndex',
    components: {
        StampCreate,
        AlertBox,
        StampPreview
    },
    props: {
        modelValue: {
            type: Object as () => Stamp,
            default: null,
        },
    },
    emits: ['selectStamp'],
    setup () {
        const { loading, load } = useLoader()

        return {
            loading, load,
            getCascadeAnimationDelay,
        }
    },
    data () {
        return {
            search: '',
            stamps: null as Stamp[],
            stampCreateShown: false,
        }
    },
    computed: {
        projectId (): string {
            return this.$store.getters['project/projectCompanyMongoId']
        },
    },
    watch: {
        search: {
            handler (): void {
                this.searchStamps()
            },
            immediate: true,
        },
    },
    created () {
        this.searchStamps = throttle(this.searchStamps, 1000)
    },
    methods: {
        async searchStamps (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const { data } = await punchStampsClient.searchPunchStamps(this.projectId, { search: this.search })
                this.stamps = data
            })
        },
        closeStampCreate (): void {
            this.search = ''
            this.stampCreateShown = false
        },
    },
})
