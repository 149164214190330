import axios from 'axios'
import { isObject, cloneDeep } from 'lodash'

function getBaseAPI2URL (apiVersion = 1) {
    if (process.env.STORYBOOK) {
        return process.env.API_URL
    }
    // API2 FOR PUBLIC PATHS
    if (['register', 'register/reject-invitation'].includes(window.location.pathname.slice(1))) {
        const protocol = window.location.protocol
        const subDomain = window.location.host.split('.')[0]
        const hostPieces = window.location.host.split('.')
        const domain = hostPieces.length > 2 ? hostPieces.slice(1).join('.') : hostPieces.join('.')

        return `${ protocol }//${ subDomain }.${ domain }/api/`
    }

    const appName = (window.location.host).split('.')[0]
    const appType = localStorage.getItem('appType')
    const apiUrl = localStorage.getItem('apiUrl')

    let baseUrl =  (apiUrl ? apiUrl : '')  + '/api/v1/'
    baseUrl = baseUrl.replace('/v1/', `/v${ apiVersion }/`)

    return baseUrl + `${ appName }/${ appType }`
}

function createApiClient (apiVersion = 1) {
    return axios.create({
        withCredentials: true,
        baseURL: getBaseAPI2URL(apiVersion),
        validateStatus: status => status >= 200 && status < 300,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
}

function handleBlobError (error) {
    if ([409, 422].includes(error.response?.status)) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.onload = function () {
                const text = reader.result

                try {
                    const json = JSON.parse(text)

                    error.message = json.message || error.message

                    reject(error)
                } catch (e) {
                    reject(error)
                }
            }

            reader.readAsText(error.response.data)
        })
    }

    return Promise.reject(error)
}

export class ApiClientCore {
    constructor (apiVersion = 1) {
        this.apiVersion = apiVersion
        this.client = createApiClient(this.apiVersion)
    }

    /**
     * Prepares default configuration for a request
     * @param {AxiosRequestConfig} requestConfig
     * @returns {AxiosRequestConfig}
     */
    prepareConfig (requestConfig) {
        const newConfig = isObject(requestConfig) ? cloneDeep(requestConfig) : {}
        if (newConfig.headers === undefined) {
            newConfig.headers = {}
        }
        if (newConfig.headers['Content-Type'] === undefined) {
            newConfig.headers['Content-Type'] = 'application/json'
        }
        return newConfig
    }
    /**
     * GET method request
     * @param {string} url
     * @param {AxiosRequestConfig} conf you can set HTTP headers here
     * @returns {Promise<AxiosResponse<any>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     */
    get (url, conf = {}) {
        return this.client.get(url, this.prepareConfig(conf))
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    }
    /**
     * DELETE method request (i.e. request for removal of some resource on backend)
     * @param {string} url
     * @param {AxiosRequestConfig} conf you can set HTTP headers here
     * @returns {Promise<AxiosResponse<any>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     */
    delete (url, conf = {}) {
        return this.client.delete(url, this.prepareConfig(conf))
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    }
    /**
     * HEAD method request (request for only headers and status code, when we don't need any data in response, i.e. for checking if resource exists)
     * @param {string} url
     * @param {AxiosRequestConfig} conf you can set HTTP headers here
     * @returns {Promise<AxiosResponse<any>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     */
    head (url, conf = {}) {
        return this.client.head(url, this.prepareConfig(conf))
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    }
    /**
     * OPTIONS method request (request for only headers and status code, no data, used for getting CORS response headers)
     * @param {string} url
     * @param {AxiosRequestConfig} conf you can set HTTP headers here
     * @returns {Promise<AxiosResponse<any>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     */
    options (url, conf = {}) {
        return this.client.options(url, this.prepareConfig(conf))
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    }
    /**
     * POST method request (i.e. request for saving of some resource, performing some action on backend)
     * Unless changed, Content-Type header will be set to application/json
     * @param {string} url
     * @param {any} data data to be sent to backend
     * @param {AxiosRequestConfig} conf you can set HTTP headers here
     * @returns {Promise<AxiosResponse<any>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     */
    post (url, data = {}, conf = {}) {
        return this.client.post(url, data, this.prepareConfig(conf))
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    }

    postWithBlobResponse (url, data = {}, conf = {}) {
        const requestConfig = this.prepareConfig(conf)

        requestConfig.responseType = 'blob'

        return this.client.post(url, data, requestConfig)
            .then(response => Promise.resolve(response))
            .catch(error => handleBlobError(error))
    }

    /**
     * PUT method request (i.e. request for replacement or creation of some new resource on backend)
     * Unless changed, Content-Type header will be set to application/json
     * @param {string} url
     * @param {any} data data to be sent to backend
     * @param {AxiosRequestConfig} conf you can set HTTP headers here
     * @returns {Promise<AxiosResponse<any>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     */
    put (url, data = {}, conf = {}) {
        return this.client.put(url, data, this.prepareConfig(conf))
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    }
    /**
     * PATCH method request (i.e. request for partial updating some resource on backend)
     * Unless changed, Content-Type header will be set to application/json
     * @param {string} url
     * @param {any} data data to be sent to backend
     * @param {AxiosRequestConfig} conf you can set HTTP headers here
     * @returns {Promise<AxiosResponse<any>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     */
    patch (url, data = {}, conf = {}) {
        return this.client.patch(url, data, this.prepareConfig(conf))
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    }
}
