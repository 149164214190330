<template>
    <div class="io-contract-summary" :class="{ 'io-contract-summary--card': !error && !loading }">
        <div v-if="!error && !loading">
            <div class="io-contract-summary__title">
                {{ $t('Contract Summary') }}
            </div>
            <div class="io-contract-summary__content">
                <IOSingleInfoRow :label="$t('Contract Name')" :info="grossBilledToDate">
                    <template #info>
                        <IOButton variant="link" icon="icon-external-link" @click="goToContract">
                            {{ invoiceContractSummary.name }}
                        </IOButton>
                    </template>
                </IOSingleInfoRow>
                <IOSingleInfoRow :label="invoiceContractSummary.is_vendor_invoice ? $t('Vendor') : $t('Client')">
                    <template #info>
                        <Avatar
                            size="sm"
                            :show-full-name="true"
                            :employee-mongo-id="invoiceContractSummary.client?.id"
                            :employee-full-name="invoiceContractSummary.client?.name || '-'"
                        />
                    </template>
                </IOSingleInfoRow>
                <IOSingleInfoRow :label="invoiceContractSummary.is_vendor_invoice ? $t('Vendor Contact') :$t('Contact Person')">
                    <template #info>
                        <Avatar
                            size="sm"
                            :show-full-name="true"
                            :employee-mongo-id="invoiceContractSummary.contact?.id"
                            :employee-full-name="invoiceContractSummary.contact?.name || '-'"
                            :companyName="invoiceContractSummary.contact?.role || ''"
                        />
                    </template>
                </IOSingleInfoRow>
                <IOSingleInfoRow :label="$t('Original Contract Amount')" :info="originalContractAmount"/>
                <IOSingleInfoRow :label="$t('Executed Change Orders')" :info="executedChangeOrders"/>
                <IOSingleInfoRow :label="$t('Adjusted Contract Amount')" :info="adjustedContractAmount"/>
            </div>
        </div>
        <RequestedDataErrorPlaceholder v-else :title="$t('Contract Summary')" :loading="loading" @reload="fetchData" />
    </div>
</template>

<script lang="ts" src="./ContractSummary.ts"></script>
<style lang="scss" src="./ContractSummary.scss" scoped></style>
