import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'
import LazyList from '@/components/lazy-list/LazyList.vue'
import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'
import { ItemRouteInterface } from '@/interfaces/components/lazy-grid/ItemRoute'
import { FilterInterface } from '@/interfaces/components/lazy-list/FilterInterface'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    name: 'DrawingSets',
    components: {
        LazyList,
    },
    data () {
        return {
            itemRoute: {
                name: 'drawings-sheets-index',
                params: {
                    setId: 'id',
                },
            } as ItemRouteInterface,
            filtersSchema: [
                {
                    field: 'favorite',
                    label: '',
                    type: 'checkbox',
                },
                {
                    field: 'type_with_archive',
                    label: 'Type',
                    type: 'status',
                    additional: {
                        hideSelectAllStatus: true,
                    },
                },
                {
                    field: 'created_by_full_name',
                    label: 'Uploaded By',
                    selectedTitle: 'user selected',
                    type: 'multiselect',
                },
                {
                    field: 'date',
                    label: 'Upload date',
                    type: 'date',
                },
            ] as FilterInterface[],
            columns: [
                {
                    field: 'name',
                    label: 'Upload name',
                    type: 'text-icon',
                    columnClass: 'io-col-name',
                    additional: {
                        iconClass: 'icon-folder-2',
                        favorite: true,
                    },
                },
                {
                    field: 'is_crosslink_in_progress',
                    label: '',
                    type: 'color-dot',
                    columnClass: 'io-col-crosslink',
                    additional: {
                        id: 'is_crosslink_in_progress',
                        header: 'is_crosslink_in_progress',
                        tooltip: this.$t('drawing.crosslink.pill.tooltip'),
                        isBoolean: 'is_crosslink_in_progress',
                        isTranslation: false,
                        sortHidden: true,
                        booleanStatusText: this.$t('drawing.crosslink.pill.text'),
                        tooltipIcon: true,
                    },
                },
                {
                    field: 'type_with_archive',
                    label: 'Type',
                    type: 'pill',
                    columnClass: 'io-col-type',
                    additional: {
                        id: 'type_with_archive',
                        header: 'type_with_archive',
                        isTranslation: true,
                    },
                },
                {
                    field: 'created_by_full_name',
                    label: 'Uploaded by',
                    type: 'row',
                    columnClass: 'io-col-createdBy',
                    additional: {
                        img: 'id',
                        header: 'name',
                        avatarType: 'avatar',
                        subheader: 'position',
                        small: true,
                    },
                },
                {
                    field: 'created_at',
                    label: 'Upload Date',
                    type: 'date',
                    columnClass: 'io-col-created',
                    additional: {
                        dateFormat: 'MM/DD/YYYY',
                    },
                },
                {
                    field: 'drawings_count',
                    label: 'Sheets',
                    columnClass: 'aright io-col-count',
                    type: 'text',
                },
            ] as ColumnInterface[],
            endpoint: '/ca/drawing/set/lazy-list',
        }
    },
    computed: {
        ...mapState('project', ['projectObj']),
        ...mapState('todo', ['taskProjectMongoId']),
        ...mapGetters('listingFilter', ['getActiveSearch']),
    },
    watch: {
        getActiveSearch: {
            async handler (): Promise<void> {
                await this.$store.dispatch('lazyList/setSearch', this.getActiveSearch)
            },
        },
    },
    async beforeMount (): Promise<void> {
        this.setLoadingBar(true)
        await this.$store.dispatch('lazyList/resetState')
        let projectId = this.projectObj.project_local_id ? this.projectObj.project_local_id : this.taskProjectMongoId
        await this.$store.dispatch('lazyList/setEndpointParams', { project: projectId })
        this.setLoadingBar(false)
    },
    methods: {
        drawingSetSelected (selectedSet: any): void {
            this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SET', selectedSet.id)
            this.$store.commit('drawingsV2/SET_DRAWING_SETS_SHOWN', false)
            this.$store.commit('drawingsV2/SET_DRAWING_SHEETS_SHOWN', true)
        },
        async cancelDrawingSets (): Promise<void> {
            this.$store.commit('drawingsV2/SET_PARENT_FORM_SHOWN', true)
            this.$store.commit('drawingsV2/SET_DRAWING_SETS_SHOWN', false)
            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                status: false,
                type: RightSideBar.DEFAULT,
            })
            await this.$store.dispatch('modal/setHidden', true)
            this.$store.commit('drawingMarkup/SET_SELECT_SHEET_POPUP', false)
        },
        pillsColorFunction (name: string): string {
            switch (name.toLowerCase()) {
            case 'drawing_set.type.draft':
                return 'io-blue'
            case 'drawing_set.type.construction':
                return 'io-yellow'
            default:
                return 'io-grey'
            }
        },
        dotsColorFunction (item: string | boolean): string {
            switch (item) {
            case 'contract.sov_status_pending':
            case 'contract.sov_status_submitted':
            case 'loi.status_pending_signature':
            case 'Pending signature':
                return 'io-yellow'
            case 'contract.sov_status_rejected':
            case 'contract.sov_status_taken_by_sender':
            case 'loi.status_voided':
            case 'loi.status_rejected':
            case 'Voided':
            case 'Rejected':
                return 'io-red'
            case 'contract.sov_status_approved':
            case 'loi.status_signed':
            case 'Signed':
            case 'Done':
                return 'io-green'
            case 'contract.sov_status_draft':
            case 'loi.status_draft':
            case 'Draft':
            case true:
            case false:
                return 'io-blue'
            case 'contract.sov_status_na':
            case 'loi.status_na':
            case 'N/A':
                return 'io-grey'
            default:
                return 'io-grey'
            }
        },
        async changeFavorite (id: number): Promise<void> {
            const item = this.getRecords.find((item: any) => item.id === id)

            const endpoint = item.favorite ? 'remove-from-favorite' : 'add-to-favorite'

            const response = await this.$api.patch(`/ca/drawing/set/${ endpoint }/${ id }`)

            if (response.status === 200) {
                item.favorite = !item.favorite
            }
        },
    },
})
