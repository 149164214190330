import { defineComponent } from 'vue'
import invoiceViewClient from '@/io-modules/invoices/api-clients/invoiceViewClient'
import { mapState as mapStateVuex } from 'vuex'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'

export default defineComponent({
    name: 'InvoiceAmountWithTaxModal',
    components: {
        IOModal
    },
    props: {
        valueWithTax: {
            type: Number,
            required: false
        },
    },
    data () {
        return {
            amountWithTax: this.valueWithTax ?? 0,
        }
    },
    computed: {
        ...mapStateVuex('project', {
            projectID: (state: any) => state.projectObj.project_local_id
        })
    },
    methods: {
        async save (): Promise<void> {
            try {
                await invoiceViewClient.setInvWithTaxValue(this.projectID, this.$route.params.id, this.amountWithTax)

                this.$emit('filledAmount', this.amountWithTax)
                this.close()
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        close (): void {
            this.$emit('close')
        },
    }
})
