import defaultState from './state'
import { cloneDeep } from 'lodash'

/**
 *
 * @param state
 * @constructor
 */
export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

/**
 *
 * @param state
 * @param config
 * @constructor
 */
export function SET_CONFIG (state, config) {
    if (config.projectId) {
        state.projectId = config.projectId
    }
    if (config.contractId) {
        state.contractId = config.contractId
    }
}

/**
 *
 * @param state
 * @param value
 * @constructor
 */
export function SET_COST_CODES_SOV (state, value) {
    state.costCodesSov = value
}

/**
 *
 * @param state
 * @param value
 * @constructor
 */
export function SET_COST_CODES_PROJECT (state, value) {
    state.costCodesProject = value
}

/**
 *
 * @param state
 * @param value
 * @constructor
 */
export function SET_COST_CODES (state, value) {
    state.costCodes = value
}

/**
 *
 * @param state
 * @param value
 * @constructor
 */
export function SET_CONTRACT_PHASES (state, value) {
    state.contractPhases = value
}


export function SET_FILTERS (state, value) {
    state.coListFilters = value
}
