import { defineComponent } from 'vue'
import MentionMixin from '@/io-modules/documents/mixins/MentionMixin'

import { commentsStore } from '@/io-modules/documents/store/commentsStore'
import { mapActions, mapState } from 'pinia'
import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'

export default defineComponent({
    mixins: [MentionMixin],
    data () {
        return {
            comment_text: '',
        }
    },
    computed: {
        ...mapState(viewDocumentStore, [
            'counters',
            'uniqueMentionUsers'
        ]),
        commentNumber (): number {
            return this.counters?.comment + 1 || 1
        }
    },
    methods: {
        ...mapActions(commentsStore, [
            'setCommentData'
        ]),
        cancel (): void {
            this.$emit('cancel-adding-annotation')
        },
        postAnnotation (): void {
            this.setCommentData('comment_text', this.comment_text)
            this.setCommentData('shared_with', this.shared_with)

            this.$emit('add-comment')
        }
    }
})
