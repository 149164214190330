import * as getters from './getters'
import { ApiClient } from '@/api/api'
import { eventBus } from '@/mixins/eventBus'
import FeatureFlagsClient from '@/api/feature-flags/featureFlagsClient'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType'
import appTypes from '@/base/appTypes.ts'

let client = ApiClient


export default {
    namespaced: true,
    namespace: 'appStore/',
    state () {
        return {
            auth: false,
            backURL: '',
            authAccess: {},
            authData: {},
            postData: {},
            refreshData: false,
            env: '',
            fullstoryOrgId: null,
            is_ephemeral: null,
            // Store ajax promise
            promise: null,
            // project currency
            projectCurrency: {
                projectId: null,
                currency: {},
            },
            datetimePickerStartWeekOn: 1,
            featureFlags: {},
        }
    },
    actions: {
        async loadFeatureFlags ({ commit, getters }) {
            if (!!Object.entries(getters.getFeatureFlags).length) {
                return
            }

            const { data } = await FeatureFlagsClient.getFeatureFlags()
            commit('SET_FEATURE_FLAGS', data)
        },
        /**
         *
         * @param commit
         * @param state
         * @param rootGetters
         * @param dispatch
         * @param forceRefresh
         * @return {boolean|*}
         */
        loadAuthData ({ commit, state, rootGetters, dispatch }, forceRefresh = false) {
            if (state.promise) {
                return state.promise
            }

            if (state.auth && !forceRefresh) {
                return true
            }

            let promise = client.getAuthData().then(function (response) {

                moment.updateLocale('en', { week: { dow: response.data.start_week_on } })

                if (null === rootGetters['projectBudget/budgetTypeValue']) {
                    const budgetType = appTypes.TYPE_DEV === response.data.app_type
                        ? BudgetType.OWNER_BUDGET
                        : BudgetType.MY_BUDGET

                    dispatch('projectBudget/setBudgetType', budgetType, { root: true })
                }

                commit('SET_AUTH_DATA', response.data)
                commit('SET_AUTH_ACCESS', response.access)
                commit('SET_ENV', response.env)
                commit('SET_IS_EPHEMERAL', response.is_ephemeral)
                commit('SET_FULLSTORY_ORG_ID', response.fullstory_org_id)
                commit('SET_AUTH_STATUS', true)
                commit('SET_PROMISE', false)
                commit('SET_DATETIMEPICKER_START_WEEK_ON', response.data.start_week_on)
                eventBus.$emit('appStore/authDataReady', true)

                document.querySelector('body').setAttribute('data-env', response.env)
            }).catch((error) => {
                console.error(error)
            }).then((result) => {
            })

            commit('SET_PROMISE', promise)
            return promise
        },

        /**
         *
         * @param dispatch
         * @return {*}
         */
        refreshAuthData ({ dispatch }) {
            return dispatch('loadAuthData', true)
        },
        setAuthStatus ({ commit }, value) {
            commit('SET_AUTH_STATUS', value)
        },
        setAuthData ({ commit }, payload) {
            commit('SET_AUTH_DATA', payload)
        },
        setAuthAccess ({ commit }, payload) {
            commit('SET_AUTH_ACCESS', payload)
        },
        setBackUrl ({ commit }, payload) {
            commit('SET_BACK_URL', payload)
        },
        setRefreshData ({ commit }, payload) {
            commit('SET_REFRESH_DATA', payload)
        },
        setPostData ({ commit }, value) {
            commit('SET_POST_DATA', value)
        },
        setEnv ({ commit }, value) {
            commit('SET_ENV', value)
        },
        setUserSettingFlag ({ commit }, value) {
            commit('SET_USER_SETTING_FLAG', value)
        },
        async setProjectCurrency ({ commit, rootState }, value) {
            if (value.multiCurrency) {
                commit('SET_PROJECT_CURRENCY', { currency: value.multiCurrency })
            }

            if (value.forceReset) {
                window.useProjectCurrency = false
                value.currency = {}
                commit('SET_PROJECT_CURRENCY', value)
            }

            if (value.projectId) {
                window.useProjectCurrency = true

                // check project exists in cached list
                const projectsCached = rootState.cached.lists['projects']
                let index = projectsCached.findIndex(x => String(x._id) === String(value.projectId))
                if (index !== -1) {
                    value.currency = projectsCached[index]._currency
                    commit('SET_PROJECT_CURRENCY', value)
                    return true
                }

                // flood prevention of request for the same project
                if (window.requestedCurrencyForProjectId !== value.projectId) {
                    window.requestedCurrencyForProjectId = value.projectId

                    // if not make a request
                    await client.get('/project/' + value.projectId + '/currency').then((response) => {
                        value.currency = response.data.currency
                        commit('SET_PROJECT_CURRENCY', value)
                        window.requestedCurrencyForProjectId = null
                    })
                }
            }
        },
        setCustomCurrency ({ commit }, value) {
            window.useProjectCurrency = true
            commit('SET_PROJECT_CURRENCY', value)
        },
        setDefaultWorkspaceCurrency ({ commit }, value) {
            window.useProjectCurrency = false
        },
        async loadAndSetCustomCurrency ({ commit }, value) {
            if (value.currencyId) {
                window.useProjectCurrency = true
                await client.get('/currency/' + value.currencyId).then((response) => {
                    value.currency = response.data.currency
                    commit('SET_PROJECT_CURRENCY', value)
                })
            }
        },
    },
    getters,
    mutations: {
        SET_AUTH_STATUS (state, value) {
            state.auth = value
        },
        SET_AUTH_DATA (state, payload) {
            state.authData = payload
        },
        SET_AUTH_ACCESS (state, payload) {
            state.authAccess = payload
        },
        SET_BACK_URL (state, payload) {
            state.backURL = payload
        },
        SET_ENV (state, value) {
            state.env = value
        },
        SET_FULLSTORY_ORG_ID (state, value) {
            state.fullstoryOrgId = value
        },
        SET_IS_EPHEMERAL (state, value) {
            state.is_ephemeral = value
        },
        SET_REFRESH_DATA (state, payload) {
            state.refreshData = payload
        },
        SET_POST_DATA (state, values) {
            for (let key in values) {
                state.postData[key] = values[key]
            }
        },
        SET_PROMISE (state, value) {
            state.promise = value
        },
        SET_PROJECT_CURRENCY (state, value) {
            state.projectCurrency = value
        },
        SET_USER_SETTING_FLAG (state, data) {
            state.authData.user_settings_flags[data.name].value = data.value
            client.realJson('/user/settings/' + data.id, { value: data.value })
        },
        SET_DATETIMEPICKER_START_WEEK_ON (state, value) {
            state.datetimePickerStartWeekOn = value
        },
        SET_FEATURE_FLAGS (state, value) {
            state.featureFlags = value
        },
    },
}
