import { defineComponent, PropType } from 'vue'
import AppType from '@/base/appTypes'
import { mapState } from 'vuex'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import { AvatarType } from '@/constants/AvatarConstants'

interface Company {
    mongo_id: string
    name: string
    type: AppType
    value: string
}

export default defineComponent({
    name: 'InvoicePaidBySelector',
    components: {
        Avatar
    },
    props: {
        companies: { type: Object as PropType<Company[]>, required: true },
        defaultValue: { type: String, required: true }
    },
    data () {
        return {
            AvatarType,
            selectedValue: this.defaultValue
        }
    },
    computed: {
        ...mapState('appStore', ['authData']),

        ...mapState('project', {
            projectObj: (state: any) => state.projectObj
        })
    },
    methods: {
        handleInput ({ target }: { target: HTMLInputElement }): void {
            this.selectedValue = target.value
            this.$emit('selectedValueChanged', this.selectedValue)
        }
    }
})
