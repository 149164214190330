import defaultState from './state'
import { cloneDeep } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export function SET_PROJECT_ID (state, value) {
    state.projectId = value
}

export function SET_ID (state, value) {
    state.id = value
}

export function SET_SHARING_CUSTOM_FIELDS_STATUS (state, value) {
    state.sharingCustomFieldsStatus = value
}

export function SET_PROJECT (state, value) {
    state.projectObj = value
}

export function SET_MODEL(state, value) {
    state.model = value
}

export function SET_COST_CODES (state, value) {
    state.costCodes = value
}

export function SET_COST_TYPES (state, value) {
    state.costTypes = value
}

export function SET_PROJECT_REQUIRED_FIELDS_INFO (state, value) {
    state.projectHasRequiredFields = value
}







