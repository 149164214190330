import { defineComponent } from 'vue'
import { uniqueId } from 'lodash'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'TooltipIcon',
    props: {
        text: { type: [String, Object as () => TranslateResult] },
        fixed: { type: Boolean, default: false },
        visible: { type: Boolean, default: true },
        htmlContent: { type: String, default: undefined },
        elementId: { type: String, default: null },
        additionalClassName: { type: String, required: false, default: '' },
    },
    data () {
        return {
            tooltipHtmlId: '' as string,
        }
    },
    computed: {
        htmlId (): string {
            return this.elementId ?? this.tooltipHtmlId
        },
    },
    beforeMount () {
        this.tooltipHtmlId = uniqueId('tooltipHtml')
    },
})
