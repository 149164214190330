import { mapState, mapActions } from 'pinia'
import { defineComponent, PropType, nextTick } from 'vue'
import InvoiceRouteName from '../../../enums/InvoiceRouteName'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import { invoiceViewStore } from '../../../stores/invoiceView'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import { mapState as mapStateVuex, mapGetters as mapGettersVuex } from 'vuex'
import invoiceCreationClient from '@/io-modules/invoices/api-clients/invoiceCreationClient'
import InvoiceActions from '@/io-modules/invoices/enums/InvoiceActions'
import InvoicesToRevertList from '../../modals/invoices-to-revert-list/InvoicesToRevertList.vue'
import ActionData from '@/io-modules/invoices/interfaces/ActionData'
import InvoiceStatus from '@/io-modules/invoices/enums/InvoiceStatus'
import MarkAsPaidModal from '../../modals/mark-as-paid-modal/MarkAsPaidModal.vue'
import ConfirmationModalsService from '@/io-modules/invoices/services/ConfirmationModalsService'
import ApprovalConfirmationModalService from '@/io-modules/approval-workflows/services/SimpleConfirmationModalsService'
import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse'
import Resource from '@/io-modules/approval-workflows/enums/Resource'
import ApprovalModalType from '@/io-modules/approval-workflows/enums/ApprovalModalType'
import ApprovalAction from '@/io-modules/approval-workflows/enums/ApprovalAction'
import ApprovalModalData from '@/io-modules/approval-workflows/interfaces/ApprovalModalData'
import invoiceViewClient from '@/io-modules/invoices/api-clients/invoiceViewClient'
import ApprovalsClient from '@/io-modules/approval-workflows/api-clients/ApprovalsClient'
import invoiceActionsClient from '@/io-modules/invoices/api-clients/invoiceActionsClient'
import ApprovalModalService from '../../../../approval-workflows/services/ApprovalModalService'
import RequestRevisionModal from '@/io-modules/approval-workflows/components/request-revision-modal/RequestRevisionModal.vue'
import VoidInvoice from '../../modals/void-invoice/VoidInvoice.vue'
import appTypes from '@/base/appTypes.ts'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
import RemoveCoverPageModal from '@/io-modules/invoices/components/modals/remove-cover-page-modal/RemoveCoverPageModal.vue'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'
import featureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'
import isLoggedMixin from '@/mixins/isLoggedMixin.js'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import reviseResubmitMixin from '@/io-modules/invoices/mixins/sov-wbs/ReviseResubmitMixin.ts'

export default defineComponent({
    name: 'TopBarActions',
    components: {
        AlertBox,
        RemoveCoverPageModal,
        ActionDropdown,
        InvoicesToRevertList,
        MarkAsPaidModal,
        RequestRevisionModal,
        VoidInvoice,
    },
    props: {
        currentStep: {
            type: Number,
            default: 0,
        },
        stepsCount: {
            type: Number,
            default: 0,
        },
        allStepsCompleted: {
            type: Boolean,
            default: true,
        },
        resourceApprovals: {
            type: Object as PropType<ResourceApprovalsResponse>,
            default: null,
        },
        approvalModalData: {
            type: Object as PropType<ApprovalModalData>,
            default: () => ({}),
        },
    },
    mixins: [featureFlagsMixin, isLoggedMixin, reviseResubmitMixin],
    emits: [
        'nextStep',
        'prevStep',
        'openExportModal',
        'invoiceCreated',
        'update:approvalModalData',
        'refreshApprovableResourceData',
        'resetApprovalWorkflow'
    ],
    expose: ['saveAsDraft', 'initRevertToDraft', 'initSendToClient', 'initRequestRevision', 'initMarkAsSubmittedToClient', 'decideOnRevisionFlow'],
    data () {
        return {
            InvoiceRouteName,
            loading: false,
            InvoiceStatus,
            revertToDraftInvoices: [],
            confirmVoid: false,
            showConfirmRemoveCoverPageModal: false,
            waitForInvoiceData: false,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, [
            'isDetailsFilled',
            'creating',
            'isEqualAppliedFundToInvoiceValue',
            'editing',
            'isEditingMode',
            'invoiceDetailsIsChanged',
            'invoice',
            'isClientOnSystem',
            'isMyInvoice',
            'isSender',
            'isNonContractedInvoice',
            'invoiceIsDraft',
            'availableActions',
            'dataLoading',
            'revisionProvided',
            'showRequestRevisionModal',
            'canAddressRevision',
            'canRequestRevision',
            'attachedTemporaryDocuments',
            'isProvidedManually',
            'appliedFundingSources',
            'applyingFunding',
            'uniqueNumberIsValid',
            'initialInvoiceData',
            'invoiceInternallyRejected',
            'invoiceRejectedByClient',
            'invAmountWithTax'
        ]),

        ...mapState(invoiceSoVStore, ['isNonContractedLineItemsCompleted']),

        ...mapState(invoiceRevisionStore, ['revisionCompleted', 'needAttentionItemsTotal', 'shouldLoadRevision', 'clientAdjustedItemsTotal', 'clientMustRevise']),
        ...mapStateVuex('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id,
            projectGlobalId: (state: any) => state.projectObj.project_global_id
        }),

        ...mapStateVuex('appStore', ['authData']),

        ...mapGettersVuex('appStore', ['isOwnerRep', 'isOwner']),

        isFirstStep (): boolean {
            return 0 === this.currentStep
        },

        nextStepButtonDisabled (): boolean {
            if (this.isNonContractedInvoice && !this.isFirstStep && !this.isNonContractedLineItemsCompleted) {
                return true
            }

            return !this.isDetailsFilled
        },

        invoiceId (): string {
            return this.$route.params.id
        },

        isActiveApprover (): boolean {
            return ApprovalModalService.isActiveApprover(this.resourceApprovals, this.authData.u_mongo)
        },

        isActiveReviewer (): boolean {
            return ApprovalModalService.isActiveReviewer(this.resourceApprovals, this.authData.u_mongo)
        },

        isLastStepActive (): boolean {
            return this.currentStep === this.stepsCount - 1
        },

        isSubmitApproveActionPrimary (): boolean {
            if (this.clientMustRevise) {
                return false
            }
            if (this.canAddressRevision && !this.revisionProvided || (this.canApplyFunding && (this.isEditingMode || this.fundingIsNotProvided)) || !this.revisionCompleted) {
                return false
            }

            return !this.invoiceIsDraft || [InvoiceStatus.Draft, undefined].includes(this.invoice.status)
                    && ((this.creating && this.isLastStepActive) || this.editing || (!this.isEditingMode && this.allStepsCompleted))
                    || this.canAddressRevision && this.revisionProvided
        },

        canApprove (): boolean {
            return this.invoiceIsDraft && this.availableActions.some(item => InvoiceActions.Approve === item.name) ||
                this.availableActions.some(item => InvoiceActions.Approve === item.name && item.available)
        },

        canSubmitForInternalApproval (): boolean {
            return this.availableActions.some(item => InvoiceActions.SubmitForInternalApproval === item.name)
        },

        canSubmitToClient (): boolean {
            return this.availableActions.some(item => InvoiceActions.SubmitToClient === item.name && item.available)
        },

        canVoidInvoice (): boolean {
            return this.availableActions.some(item => InvoiceActions.Void === item.name && item.available)
        },

        canMarkAsPaid (): boolean {
          return this.availableActions.some(item => InvoiceActions.MarkAsPaid === item.name && item.available)
        },

        canMarkAsPendingInternalApproval (): boolean {
            return this.availableActions.some(item => InvoiceActions.MarkAsPendingInternalApproval === item.name && item.available)
        },

        canResetApprovalWorkflow (): boolean {
          return this.availableActions.some(item => InvoiceActions.ResetApprovalWorkflow === item.name && item.available)
        },

        revertToDraftIsPrimary (): boolean {
            const availableItems = this.availableActions.filter(item => item.available)
            const hasRevertToDraft = availableItems.some(item => item.name === InvoiceActions.RevertToDraft)
            const hasOnlyValidActions = availableItems.every(item =>
                item.name === InvoiceActions.SaveAsDraft || item.name === InvoiceActions.RevertToDraft
            )

            return hasRevertToDraft && hasOnlyValidActions
        },

        canApplyFunding (): boolean {
            if (!this.revisionCompleted || this.clientMustRevise) {
                return false
            }

            if (!this.dataLoading) {
                return (this.isOwnerRep || this.isOwner)
                    && InvoiceStatus.PendingInternalApproval === this.invoice.status
                    && 'receiver' === this.invoice.role
                    && this.invoice.creator.workspace_type !== appTypes.TYPE_DEV
            }
        },

        fundingIsNotProvided (): boolean {
          return this.canApplyFunding && !this.isEqualAppliedFundToInvoiceValue
        },

        dropdownActions (): ActionData[] {
            return [
                {
                    iconClass: 'icon-arrow-right-rec',
                    text:  this.$t('Mark as Pending Internal Approval'),
                    onClick: () => this.initMarkAsPendingInternalApproval(),
                    visible: this.canMarkAsPendingInternalApproval,
                    disabled: !this.allStepsCompleted  || this.fundingIsNotProvided,
                    tooltip: this.$t('Please fill out all the required information.'),
                },
                {
                    iconClass: 'icon-edit',
                    text:  this.$t('Edit Invoice'),
                    onClick: () => this.setEditModeValue(true),
                    visible: this.allStepsCompleted && InvoiceStatus.Draft === this.invoice.status,
                },
                {
                    iconClass: 'icon-send-2',
                    text:  this.$t('Submit for Internal Approval'),
                    onClick: () => this.submitForInternalApproval(),
                    disabled: !this.allStepsCompleted || !this.revisionCompleted || this.clientMustRevise,
                    visible: (!this.allStepsCompleted || (!this.revisionProvided && this.canAddressRevision)) && this.canSubmitForInternalApproval && !this.canSave,
                    tooltip: this.$t('Please fill out all the required information.'),
                },
                {
                    iconClass: 'icon-check',
                    text:  this.$t('Approve'),
                    onClick: () => this.approveInvoice(),
                    disabled: !this.allStepsCompleted || this.fundingIsNotProvided || this.hasToFillInvWithTaxAmount,
                    visible: (!this.allStepsCompleted || (!this.revisionProvided && this.canAddressRevision) || this.allStepsCompleted && this.fundingIsNotProvided) && this.canApprove && !this.canSave,
                    tooltip: this.$t('Please fill out all the required information.'),
                },
                {
                    iconClass: 'icon-trash',
                    text:  this.$t('Delete'),
                    onClick: () => this.initDeleteInvoice(),
                    visible: InvoiceStatus.Draft === this.invoice.status,
                },
                {
                    iconClass: 'icon-user-check',
                    text:  this.$t('Record Client Approval'),
                    onClick: () => this.initRecordClientApproval(),
                    visible: this.availableActions.some(item => InvoiceActions.RecordClientApproval === item.name && item.available) && InvoiceStatus.InternallyApproved === this.invoice.status,
                },
                {
                    iconClass: 'icon-arrow-swap',
                    text:  this.$t('Request Revision'),
                    onClick: () => this.toggleRequestRevisionModal(),
                    visible: this.canRequestRevision && !this.invoiceInternallyRejected && !this.invoiceRejectedByClient && this.revisionCompleted,
                },
                {
                    iconClass: 'icon-edit',
                    text:  this.$t('Address Revision'),
                    onClick: () => this.initEditMode(),
                    visible: this.canAddressRevision && this.revisionProvided && !this.isEditingMode,
                },
                {
                    iconClass: 'icon-cross',
                    text:  this.$t('Reject'),
                    onClick: () => this.$emit('update:approvalModalData', { action: ApprovalAction.REJECT, modal: ApprovalModalType.UserApproval }),
                    visible: [InvoiceStatus.PendingInternalApproval].includes(this.invoice.status) && this.canApprove,
                },
                {
                    iconClass: this.canRevertAsServiceAcc ? 'icon-alert-triangle-2' : 'icon-rotate-ccw',
                    text:  this.$t('Revert to Draft'),
                    tooltip: this.revertToDraftTooptip,
                    disabled: this.existPaidInvoiceAfterCurrent,
                    onClick: () => this.initRevertToDraft(),
                    visible: this.availableActions.some(item => InvoiceActions.RevertToDraft === item.name && item.available) && !this.revertToDraftIsPrimary || this.canRevertAsServiceAcc,
                },
                {
                    iconClass: 'icon-cross-rec',
                    text:  this.$t('Void Invoice'),
                    visible: this.canVoidInvoice,
                    onClick: () => this.confirmVoid = true,
                },
                {
                    iconClass: 'icon-rotate-ccw',
                    text:  this.$t('Reset Approval Workflow'),
                    visible: this.canResetApprovalWorkflow,
                    onClick: () => this.initResetApprovalWorkflow()
                },
            ]
        },

        primaryActions (): ActionData[] {
            return [
                {
                    text:  this.$t('Complete Invoice Details'),
                    onClick: () => this.initEditMode(),
                    visible: !this.allStepsCompleted && !this.isEditingMode && this.invoice.id && !this.dataLoading && this.invoiceIsDraft,
                },
                {
                    text:  this.$t('Submit for Internal Approval'),
                    onClick: () => this.submitForInternalApproval(),
                    visible: this.canSubmitForInternalApproval && this.isSubmitApproveActionPrimary
                        || (this.canAddressRevision && this.revisionProvided && !this.isEditingMode && !this.canApprove),
                    disabled: !this.allStepsCompleted || this.loading  || this.dataLoading || !this.revisionCompleted,
                    tooltip: !this.revisionCompleted ?
                        this.$t('Invoice cannot be submitted for approval:') + ' ' +
                        this.$t('All highlighted SoV / WBS must be addressed before resubmitting.')
                        : null
                },
                {
                    text:  this.$t('Approve'),
                    onClick: () => this.approveInvoice(),
                    visible: (this.canApprove && this.isSubmitApproveActionPrimary) && !this.isActiveReviewer,
                    disabled: !this.allStepsCompleted || this.loading  || this.dataLoading || this.hasToFillInvWithTaxAmount,
                },
                {
                    text:  this.$t('Review'),
                    onClick: () => this.reviewInvoice(),
                    visible: this.canApprove && this.isSubmitApproveActionPrimary && this.isActiveReviewer,
                    disabled: false,
                },
                {
                    text:  this.$t('Revert to Draft'), //shown as primary only if its only one available action
                    onClick: () => this.initRevertToDraft(),
                    visible: this.revertToDraftIsPrimary
                },
                {
                    text:  this.$t('Mark as Paid'),
                    icon: 'icon-dollar-circle',
                    onClick: () => this.initMarkAsPaid(),
                    visible: this.canMarkAsPaid,
                    disabled: !this.allStepsCompleted || this.loading
                },
                {
                    text:  this.$t('Submit to Client'),
                    icon: 'icon-send-2',
                    onClick: () => this.initSendToClient(),
                    visible: this.canSubmitToClient,
                    disabled: this.loading,
                },
                {
                    text:  this.$t('Mark as Submitted to Client'),
                    onClick: () => this.initMarkAsSubmittedToClient(),
                    visible: this.availableActions.some(item => InvoiceActions.MarkAsSubmittedToClient === item.name && item.available) && InvoiceStatus.InternallyApproved === this.invoice.status,
                },
                {
                    text:  this.$t('Record Client Approval'),
                    onClick: () => this.initRecordClientApproval(),
                    visible: this.availableActions.some(item => InvoiceActions.RecordClientApproval === item.name && item.available) && InvoiceStatus.InternallyApproved !== this.invoice.status,
                },
                {
                    text:  this.$t('Address Revision'),
                    onClick: () => this.initEditMode(),
                    visible: this.canAddressRevision && !this.revisionProvided && !this.isEditingMode,
                },
                {
                    text:  this.$t('Request Revision'),
                    onClick: () => this.decideOnRevisionFlow(),
                    visible: this.canRequestRevision && (
                        (!this.canApprove && this.isActiveReviewer) ||
                        this.invoiceInternallyRejected || this.invoiceRejectedByClient ||
                        !this.revisionCompleted || this.clientMustRevise
                    ),
                },
                {
                    text:  this.$t('Submit to Accounting'),
                    onClick: () => this.initSubmitToAccounting(),
                    visible: this.availableActions.some(item => InvoiceActions.SubmitToAccounting === item.name && item.available),
                },
                {
                    text: this.$t('Apply Funding'),
                    onClick: async () => this.isEditingMode ? await this.applyFundingCloseEdit() : this.goToFunding(),
                    visible: this.isEditingMode ? this.canApplyFunding : this.canApplyFunding && this.fundingIsNotProvided,
                    disabled: this.isEditingMode && this.fundingIsNotProvided
                },
            ]
        },

        availableDropdownActions (): ActionData[] {
            return this.dropdownActions.filter(action => action.visible)
        },

        availablePrimaryActions (): ActionData[] {
            return this.primaryActions.filter(action => action.visible)
        },

        existCreatedInvoicesAfterCurrent (): boolean {
            return this.revertToDraftInvoices.length
        },

        existPaidInvoiceAfterCurrent (): boolean {
            return false
        },

        canSave (): boolean { //after revision
            return this.canAddressRevision && this.isEditingMode
        },

        hasToFillInvWithTaxAmount (): boolean {
            return this.isOwner && this.isFeatureEnabled(featureFlagsConsts.INVOICE_TAX_VALUE_FIELD, false) && !this.invAmountWithTax && !this.creating
        },

        canRevertAsServiceAcc (): boolean {
            return !this.availableActions.some(item => InvoiceActions.RevertToDraft === item.name && item.available) && this.isServiceAccount()
                    && InvoiceStatus.Draft !== this.invoice.status
        },

        revertToDraftTooptip (): string {
            if (this.canRevertAsServiceAcc) {
                return 'For this state of invoice you can revert to draft only as a Service Account'
            } else if (this.existPaidInvoiceAfterCurrent) {
                return this.$t('You cannot revert this invoice to draft, as there are invoices that have been paid after the date of this invoice.')
            }

            return null
        },

        isSoVTab (): boolean {
            return InvoiceRouteName.InvoiceSoV === this.$route.name
        },

        isRevisionFeatureEnabled (): boolean {
            return this.isFeatureEnabled(featureFlagsConsts.INVOICES_REVISION_REQUEST_V2, false)
        }
    },
    watch: {
        'resourceApprovals.id' (value: string): boolean {
            if (value && this.waitForInvoiceData) {
                this.waitForInvoiceData = false
                this.showPopupAlert(ApprovalConfirmationModalService.submitForInternalApproval(Resource.INVOICE, this.resourceApprovals?.workflows, this.confirmSubmit))
            }
        },
    },
    async mounted () {
        if (this.creating || this.invoiceId == 0) {
            this.setAvailableActions(this.projectLocalID)
        } else {
            this.setAvailableActions(this.projectLocalID, this.invoiceId)
        }
    },
    methods: {
        ...mapActions(invoiceViewStore, [
            'saveInvoice',
            'applyFunding',
            'setEditModeValue',
            'clearAllInvoiceStoreData',
            'fetchInvoiceDetails',
            'updateInvoiceDetailsActions',
            'setAvailableActions',
            'setDataLoadingValue',
            'fetchInvoiceFundingSources',
            'fetchVendorsInvoices',
            'setRevisionProvided',
            'toggleRequestRevisionModal',
            'setAttachedTemporaryDocuments',
            'setApplyingFunding',
            'setUniqueNumberValid',
        ]),

        ...mapActions(invoiceRevisionStore, ['loadAdjustmentsIntoWbsItems']),

        ...mapActions(invoiceSoVStore, ['setTableViewMode']),

        initMarkAsPendingInternalApproval (): void {
            this.showPopupAlert(ConfirmationModalsService.markAsPendingInternalApproval(this.markAsPendingInternalApproval))
        },

        async checkUniqueId (): void {
            const uniqueIdIsChanged = this.invoice.id ? this.initialInvoiceData.invoice_unique_number !== this.invoice.invoice_unique_number : true
            if (this.invoiceIsDraft && this.invoice.invoice_unique_number && uniqueIdIsChanged) {
                try {
                    await invoiceCreationClient.checkInvoiceUniqueID(this.projectLocalID, this.invoice.sub_type, this.invoice.invoice_unique_number, this.invoice.vendor?.contact_workspace_id || this.invoice.vendor?.id)
                } catch (error) {
                    this.setUniqueNumberValid(false)
                    this.errorHandleNoRedirect(error)
                }
            }
        },

        async saveInvoiceCloseEdit (): Promise<void> {
            await this.checkUniqueId()
            if (this.uniqueNumberIsValid) {
                await this.separateSoVBeforeSaving()

                await this.saveInvoice(this.projectLocalID, this.invoiceId)
                this.setEditModeValue(false)
            }
        },

        async applyFundingCloseEdit (): Promise<void> {
            await this.applyFunding(this.projectLocalID, this.invoiceId)
            this.setApplyingFunding(false)
            this.setEditModeValue(false)
            this.onInvoiceSaved()
        },
        async onInvoiceSaved (): Promise<void> {
            this.setDataLoadingValue(true)
            this.updateInvoiceDetailsActions(this.projectLocalID, this.$route.name, this.invoiceId)

            if (this.isRevisionFeatureEnabled && this.shouldLoadRevision) {
                this.loadAdjustmentsIntoWbsItems(this.projectGlobalId, this.invoiceId)
            }

            await this.fetchInvoiceDetails(this.projectLocalID, this.invoiceId)
            this.$emit('refreshApprovableResourceData')
        },

        goToInvoiceList (): void {
            this.clearAllInvoiceStoreData()
            this.$router.push({ name: InvoiceRouteName.InvoicesReceivedDefaultList })
        },

        goToNextTab (): void {
            this.$emit('nextStep')
        },

        goToPrevTab (): void {
            this.$emit('prevStep')
        },

        async errorHandler (actions: () => void): void {
            try {
                this.setDataLoadingValue(true)
                await actions()
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.setDataLoadingValue(false)
            }
        },

        async submitForInternalApproval (): void {
            if (this.allStepsCompleted) {
                await this.saveBeforeSubmit()
                if (!this.resourceApprovals?.id) {
                    this.waitForInvoiceData = true
                } else {
                    this.showPopupAlert(ApprovalConfirmationModalService.submitForInternalApproval(Resource.INVOICE, this.resourceApprovals?.workflows, this.confirmSubmit))
                }
            }
        },

        async confirmSubmit (): Promise<void> {
            this.errorHandler(async () => {
                this.loading = true
                await ApprovalsClient.submitForApproval(this.resourceApprovals?.id)
                await this.onInvoiceSaved()
                this.loading = false
                this.showNotification('success', this.$t('Invoice has been successfully Submitted for Approval.'))

                if (InvoiceRouteName.InvoiceDetails !== this.$route.name) {
                    this.$router.push({ name: InvoiceRouteName.InvoiceDetails })
                }
            })
        },

        async cancelClick (): Promise<void> {
            if (this.applyingFunding) {
                this.setApplyingFunding(false)
            }

            if (!this.editing && this.invoiceDetailsIsChanged) {
                this.showPopupAlert(ConfirmationModalsService.leaveWithoutChanges(this.cancelConfirmed))
            } else {
                this.setEditModeValue(false)
                if (InvoiceRouteName.InvoiceFunding === this.$route.name) {
                    this.setDataLoadingValue(true)
                    await this.fetchInvoiceFundingSources(this.invoice.id, this.projectLocalID)
                    this.setDataLoadingValue(false)
                }
            }

            this.attachedTemporaryDocuments.forEach(item => {
                this.$store.dispatch('filesComponent/removeMyDoc', {
                    arrayId: 'invoice_documents',
                    fileId: item._id,
                })
            })

            this.setAttachedTemporaryDocuments([])

            this.$store.dispatch('filesComponent/setMyDocs', {
                arrayId: 'invoice_documents',
                files: [...this.initialInvoiceData.invoice_attachments || []],
            })
        },

        cancelConfirmed (): void {
            if (this.editing) {
                this.setEditModeValue(false)
            } else {
                this.clearAllInvoiceStoreData()
                this.$router.push({ name: InvoiceRouteName.InvoicesReceivedDefaultList })
            }
        },

        async saveAsDraft (routeToInvoiceView: boolean = true): Promise<void> {
            this.loading = true
            try {
                if (this.creating) {
                    await this.checkUniqueId()
                    if (this.uniqueNumberIsValid) {
                        await this.separateSoVBeforeSaving()
                        this.setDataLoadingValue(true)
                        const invoiceId = await this.saveInvoice(this.projectLocalID)
                        this.clearAllInvoiceStoreData()
                        if (true === routeToInvoiceView) {
                            this.$emit('invoiceCreated')
                            this.$route.params.id = invoiceId
                            this.$router.replace({ name: InvoiceRouteName.InvoiceView, params: { id: invoiceId } })
                            await this.updateInvoiceDetailsActions(this.projectLocalID, this.$route.name, invoiceId)
                        }

                        this.setDataLoadingValue(false)
                    }
                } else {
                    await this.saveInvoiceCloseEdit()
                    if (this.uniqueNumberIsValid) {
                        this.showNotification('success', this.$t('Invoice has been successfully Saved as Draft.'))
                        await this.onInvoiceSaved()
                    }
                }
                if (!this.isOwnerRep && !this.isOwner && this.isMyInvoice) {
                    await this.fetchVendorsInvoices(this.projectLocalID)
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.loading = false
            }
        },

        initEditMode (): void {
            this.setEditModeValue(true)
        },

        goToFunding (): void {
          this.$router.push({ name: InvoiceRouteName.InvoiceFunding })
          this.initEditMode()
          if (this.canApplyFunding) {
              this.setApplyingFunding(true)
          }
        },

        goToSOV (): void {
            this.$router.push({ name: InvoiceRouteName.InvoiceSoV })
            this.toggleRequestRevisionModal()
        },

        async initRevertToDraft (): Promise<void> {
            this.showPopupAlert(ApprovalConfirmationModalService.revertToDraft(this.confirmRevertToDraft))
            if (!this.isNonContractedInvoice) {
                const { data } = await invoiceActionsClient.revertToDraftInvoicesList(this.projectLocalID, this.invoiceId)
                this.revertToDraftInvoicesList = data
            }
        },

        initResetApprovalWorkflow (): void {
            this.showPopupAlert(ApprovalConfirmationModalService.resetApprovalWorkflow(this.resetApprovalWorkflow))
        },

        async voidInvoice (note: string = ''): Promise<void> {
            try {
                this.setDataLoadingValue(true)
                await invoiceActionsClient.voidInvoice(this.projectLocalID, this.invoiceId, note)
                this.confirmVoid = false
                await this.onInvoiceSaved()
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.setDataLoadingValue(false)
            }
        },

        async markAsPendingInternalApproval (): Promise<void> {
            if (this.invoiceIsDraft || this.revisionProvided) {
                await this.saveBeforeSubmit()
            }
            await nextTick()
            try {
                this.setDataLoadingValue(true)
                await invoiceActionsClient.markAsPendingInternalApproval(this.projectLocalID, this.$route.params.id)
                await this.onInvoiceSaved()
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.setDataLoadingValue(false)
            }
        },

        confirmRevertToDraft (): void {
            if (this.existCreatedInvoicesAfterCurrent) {
                setTimeout(() => this.showPopupAlert({
                    title: this.$t('Are you sure you want to revert to draft?'),
                    caption: this.$refs.revertInvoices.$el.outerHTML,
                    captionHTML: true,
                    icon: 'icon-undo-rec',
                    iconColor: 'var(--warning-darker)',
                    width: 800,
                    customClass: 'io-custom-popup-alert-invoice',
                    buttons: [
                        {
                            text: this.$t('Cancel'),
                            class: 'io-btn-light',
                            action: null
                        },
                        {
                            text: this.$t('Yes, Revert to Draft'),
                            class: 'io-btn-primary',
                            action: () => this.revertToDraft()
                        }
                    ]
                }), 0)
            } else {
                this.revertToDraft()
            }
        },

        async resetApprovalWorkflow (): Promise<void> {
            try {
                await invoiceActionsClient.resetApprovalWorkflow(this.projectLocalID, this.invoiceId)
                await this.onInvoiceSaved()
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },

        async revertToDraft (): Promise<void> {
            this.setDataLoadingValue(true)

            await invoiceActionsClient.revertToDraft(
                this.projectLocalID,
                this.invoiceId,
                this.canRevertAsServiceAcc ? true : undefined
            )

            this.onInvoiceSaved()
        },

        async approveInvoice (): Promise<void> {
            if (this.invoiceIsDraft || this.revisionProvided) {
                try {
                    await this.saveBeforeSubmit()
                } catch (error) {
                    this.errorHandleNoRedirect(error)

                    return
                }
            }

            this.$emit('update:approvalModalData', { action: ApprovalAction.APPROVE, modal: ApprovalModalType.UserApproval })
        },
        async reviewInvoice (): Promise<void> {
            this.$emit('update:approvalModalData', { action: ApprovalAction.REVIEW, modal: ApprovalModalType.UserApproval })
        },

        initDeleteInvoice (): void {
            this.showPopupAlert(ConfirmationModalsService.deleteInvoice(this.deleteInvoice))
        },

        async deleteInvoice (): Promise<void> {
            try {
                await invoiceViewClient.deleteInvoice(this.projectLocalID, this.invoice.id)
                this.$router.push({ name: InvoiceRouteName.InvoicesReceivedDefaultList })
            } catch (error) {
                this.errorHandleNoRedirect(error)
            }
        },

        async initMarkAsPaid (): Promise<void> {
            if (this.isEditingMode && this.invoiceIsDraft) {
                await this.saveBeforeSubmit()
            }

            this.$refs.markAsPaidModal.show()
        },

        initSendToClient (): void {
            this.showPopupAlert(ApprovalConfirmationModalService.submitToClient(Resource.INVOICE, this.submitToClient))
            this.setRevisionProvided(false)
        },

        async submitToClient (): Promise<void> {
            this.setDataLoadingValue(true)
            this.loading = true
            await invoiceActionsClient.submitToClientInvoice(this.projectLocalID, this.invoiceId)
            this.loading = false
            this.onInvoiceSaved()
            this.setDataLoadingValue(false)
        },

        initMarkAsSubmittedToClient (): void {
            this.showPopupAlert(ApprovalConfirmationModalService.markAsSubmittedToClient(Resource.INVOICE, this.markAsSubmittedToClient))
        },

        async markAsSubmittedToClient (): Promise<void> {
            this.setDataLoadingValue(true)
            await invoiceActionsClient.markAsSubmittedToClientInvoice(this.projectLocalID, this.invoiceId)
            this.onInvoiceSaved()
            this.setDataLoadingValue(false)
        },

        initRecordClientApproval (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.APPROVE, modal: ApprovalModalType.ClientApproval })
        },

        async separateSoVBeforeSaving (): Promise<void> {
            if (!this.isNonContractedInvoice && InvoiceRouteName.InvoiceSoV === this.$route.name) {
                this.setTableViewMode(SoVTableViewMode.COsInSeparateCategory)
                await nextTick()
            }
        },

        async saveBeforeSubmit (): Promise<void> {
            if (this.creating) {
                await this.checkUniqueId()
                if (this.uniqueNumberIsValid) {
                    await this.separateSoVBeforeSaving()

                    const invoiceId = await this.saveInvoice(this.projectLocalID)
                    this.$emit('invoiceCreated')
                    this.clearAllInvoiceStoreData()
                    this.$route.params.id = invoiceId
                    this.$router.replace({ name: InvoiceRouteName.InvoiceView, params: { id: invoiceId } })
                }

                await nextTick()
            } else {
                await this.saveInvoiceCloseEdit()
                await nextTick()
            }
        },

        async saveAfterProvidedRevision (): Promise<void> {
            await this.saveInvoiceCloseEdit()
            this.setRevisionProvided(true)
        },

        async decideOnRevisionFlow (): Promise<void> {
            if (this.needAttentionItemsTotal || (this.canClientReviseInvoice && this.clientAdjustedItemsTotal)) {
                this.showPopupAlert({
                    title: this.$t('Are you sure you want to request revision from the vendor on this invoice?'),
                    caption: this.$t('This will send back this invoice to the vendor with adjusted SoV / WBS that they will need to address.'),
                    icon: 'icon-arrow-swap',
                    iconColor: 'var(--warning-darker)',
                    width: 535,
                    buttons: [
                        {
                            text: this.$t('Cancel'),
                            class: 'io-btn-light',
                            action: null
                        },
                        {
                            text: this.$t('Yes, Request Revision'),
                            class: 'io-btn-primary',
                            action: () => this.initRequestRevision()
                        }
                    ]
                })
            } else {
                this.toggleRequestRevisionModal()
            }
        },

        async initRequestRevision (note: string|null = null): Promise<void> {
            try {
                await invoiceActionsClient.requestRevision(this.projectLocalID, this.invoiceId, note)
                this.showNotification('success', this.$t('Revision was successfully requested'))
                await this.onInvoiceSaved()
            } catch (e) {
                this.errorHandleNoRedirect(e)
            } finally {
                this.setDataLoadingValue(false)
            }
        },
        exportToPdfRemoveCoverPage (): void {
            if (this.isNonContractedInvoice) {
                this.showConfirmRemoveCoverPageModal = false
                this.exportToPdf(false)

                return
            }
            if (this.isFeatureEnabled(featureFlagsConsts.INVOICES_EXPORT_REMOVE_COVER_PAGE, false))
            {
                this.showConfirmRemoveCoverPageModal = true
                return
            }

            this.exportToPdf(false)
        },
        initExportToPdf (): void {
            this.showPopupAlert({
                title: this.$t('Only PDF, JPEG and PNG file types that are attached to the invoice will be exported to the system generated PDF of this invoice.'),
                caption: this.$t('All other file types will not be included.'),
                icon: 'icon-alert-triangle',
                iconColor: 'var(--warning-darker)',
                sessionKey: 'invoice-export',
                disabledInSessionCallback: this.exportToPdfRemoveCoverPage,
                width: 572,
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        loaderOnAction: false,
                        action: null,
                    },
                    {
                        text: this.$t('Continue'),
                        class: 'io-btn-primary',
                        saveDisabledInSession: true,
                        loaderOnAction: true,
                        action: this.exportToPdfRemoveCoverPage,
                    },
                ]
            })
        },

        async exportToPdf (removeCoverPage: number): Promise<void> {
            window.open(`${ this.apiUrl }/invoices/${ this.projectLocalID }/invoice/${ this.invoiceId }/pdf?remove_cover_page=${ removeCoverPage }`)
        },

        initSubmitToAccounting (): void {
            this.showPopupAlert(ConfirmationModalsService.submitToAccountingInvoice(this.submitToAccounting))
        },

        async submitToAccounting (): Promise<void> {
            try {
                this.setDataLoadingValue(true)
                await invoiceActionsClient.submitToAccounting(this.projectLocalID, this.invoiceId)
                this.showNotification('success', this.$t('Invoice submitted to accounting'))
                await this.onInvoiceSaved()
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.setDataLoadingValue(false)
            }
        },
    }
})
