import { defineComponent } from 'vue'
import useLoader from '@/composables/useLoader.ts'
import client from '@/components/budget-site-select/client.ts'
import { FinanceableType } from '@/components/budget-site-select/domain/FinanceableType.ts'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'
import type { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'

export default defineComponent({
    name: 'BudgetSiteSelect',

    props: {
        financeableId: {
            type: String,
            default: null,
        },

        financeableType: {
            type: String as () => FinanceableType,
            default: null,
            validator (value: FinanceableType): boolean {
                return [FinanceableType.Budgets, FinanceableType.FeasibilityStudies].includes(value)
            },
        },

        // project_global_id
        projectId: {
            type: String,
            default: null,
        },

        budgetType: {
            type: String as () => BudgetType,
            default: null,
            validator (value: BudgetType): boolean {
                return [BudgetType.OWNER_BUDGET, BudgetType.MY_BUDGET].includes(value)
            },
        },

        preselectedSiteIds: {
            type: Array as () => string[],
            default: () => [],
        },

        labelText: {
            type: String,
            required: false,
        },

        providedSites: {
            type: Array as () => ProjectSite[],
            default: () => [],
        },

        hasLabel: {
            type: Boolean,
            default: true,
        },

        canUncheckPreselectedSites: Boolean,
        multiple: Boolean,
    },

    emits: ['update:modelValue'],

    setup () {
        const { loading: loadingSites, load: loadSites } = useLoader()

        return {
            loadingSites,
            loadSites,
        }
    },

    data () {
        return {
            sites: null as ProjectSite | ProjectSite[],
            options: [] as ProjectSite[],
        }
    },

    watch: {
        providedSites: {
            handler(): void {
                this.getProjectSites()
            },
            deep: true,
        },
        preselectedSiteIds: {
            handler(): void {
                this.getProjectSites()
            },
            deep: true,
        }
    },

    computed: {
        loading (): boolean {
            return this.loadingSites
        },

        allSelected (): boolean {
            return Array.isArray(this.sites)
                ? this.options?.filter((site) => !site.$isDisabled).length === this.sites?.length
                : false
        },

        someItemsSelected (): boolean {
            const sites = this.sites

            if (!this.preselectedSiteIds?.length || !Array.isArray(sites)) {
                return false
            }

            return this.canUncheckPreselectedSites
                ? !!sites?.length
                : sites?.length < this.options?.filter((site) => !site.$isDisabled).length
        },

        placeholder (): string {
            return Array.isArray(this.sites) && this.sites?.length
                ? `${ this.sites.length } ${ this.$t('Project site locations selected') }`
                : this.$t('Select...')
        },
    },

    beforeCreate (): void {
        if (this.providedSites.length) {
            return
        }

        if (this.financeableId && !this.financeableType) {
            throw new Error('financeableType is required')
        }

        if (this.financeableType && !this.financeableId) {
            throw new Error('financeableId is required')
        }
    },

    mounted () {
        this.getProjectSites()
    },

    methods: {
        selectAll (): void {
            this.sites = this.allSelected
                ? []
                : this.options?.filter((site) => !site.$isDisabled)

            this.$emit('update:modelValue', this.sites)
        },

        async getProjectSites (): Promise<void> {
            await this.loadSites(async (showApiClientNotification): Promise<void> => {
                const { data } = !this.providedSites.length
                    ? await showApiClientNotification(
                        this.projectId
                            ? client.getSitesByProject(this.projectId, this.budgetType)
                            : client.getSitesByFinanceable(this.financeableId, this.financeableType),
                    )
                    : { data: this.providedSites }

                this.options = data.map((site: ProjectSite) => ({
                    ...site,
                    $isDisabled: !this.canUncheckPreselectedSites && this.preselectedSiteIds.includes(site.project_site_id),
                }) as ProjectSite)

                // fill sites with preselected sites
                if (this.multiple) {
                    this.sites = this.options.filter((site) => this.preselectedSiteIds.includes(site.project_site_id) && this.canUncheckPreselectedSites)

                    this.$emit('update:modelValue', this.sites)
                } else {
                    this.sites = this.options.find(
                        ({ project_site_id }) => this.preselectedSiteIds.includes(project_site_id)
                    )
                }
            })
        },
    },
})
