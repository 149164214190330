import { defineComponent, PropType } from 'vue'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import { isEmpty } from 'lodash'
import { Approver } from '@/interfaces/modules/projects/financial-approvals/Approver'
import { Employee } from '@/interfaces/modules/projects/financial-approvals/Employee'
import Initials from '@/components/new-theme/initials.vue'
import { mapGetters } from 'vuex'

interface MultiselectSettings {
    textPlaceholder: string
    enableAddNew: boolean
    closeAfterSelect: boolean
    enableSearch: boolean
    searchPropertyNames: Array<string>
}

export default defineComponent({
    name: 'ApproverSelector',

    components: {
        CustomMultiselect,
        Initials,
    },

    props: {
        approver: { type: Object as PropType<Approver> },
        employees: { type: Object as PropType<Array<Approver>> },
        activeEmployee: { type: Object as PropType<Employee> },
        isRoleTypeMultiselect: { type: Boolean },
    },

    computed: {
        value: {
            get () {
                return this.activeEmployee
            },
            set (value) {
                this.$emit('update:modelValue', value)
            },
        },

        disableMultiselect (): boolean {
            return isEmpty(this.employeeMultiselectOptions)
        },

        employeeMultiselectOptions (): any {
            const options = []
            const companySet = new Set()

            const list = this.isRoleTypeMultiselect ? this.approver.approvers_to_assign : this.employees

            list.forEach((employee: Employee): void => {
                if (!companySet.has(employee.company_name)) {
                    companySet.add(employee.company_name)
                    options.push({
                        name: employee.company_name,
                        type: employee.company_type,
                        $isLabel: true,
                    })
                }

                options.push(employee)
            })

            return options
        },

        optionsTitle (): string | undefined {
            if (!this.isRoleTypeMultiselect) {
                return
            }

            return `${this.$tc('Select')} ${this.roleName}`
        },

        roleName (): string {
            return this.approver?.role_name ?? this.$tc('External Architect / Engineer')
        },

        multiselectSettings (): MultiselectSettings {
            return {
                textPlaceholder: this.isRoleTypeMultiselect ? this.roleName : this.$tc('Choose Approver'),
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: true,
                searchPropertyNames: ['name'],
            }
        },
    },
})
