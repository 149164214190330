import dayjs from 'dayjs'


export const addBusinessDays = (dateString: string, days: number, format: string = 'YYYY-MM-DD'): string => {
    let count = 0
    let date = dayjs(dateString, format)
    const weekends = [0, 6]
    while (count < days) {
        date = date.add(1, 'day')
        if (weekends.includes(date.day())) {
            continue
        }
        count++
    }
    return date.format(format)
}
