import { mapActions, mapGetters } from 'vuex'
import { get } from 'lodash'
import { getAuthUrl } from '@/helpers/urls'
import { setLocalStorage } from '@/base/localStorageChange'
import dayjs from 'dayjs'

const isLoggedMixin = {
    mounted () {
        const appType = localStorage.getItem('appType')
        const currentUrl = window.location.pathname

        setLocalStorage('lastActivityTime', dayjs().unix().toString())
        setLocalStorage('lastRefreshTime', dayjs().unix().toString())

        if (!appType && currentUrl !== '/auth-data') {
            if (this.isAuthFreeRoute()) {
                return
            }
            this.logout()
            return
        }

        this.$store.dispatch('appStore/loadAuthData')
    },
    computed: {
        ...mapGetters('appStore', ['getAuthStatus', 'getAuthData', 'getAuthAccess', 'getEnv']),
    },
    methods: {
        ...mapActions('appStore', ['setAuthStatus', 'setAuthData', 'setEnv']),
        appAvail (appList) {
            const getAuth = this.getAuthData
            const appType = getAuth['app_type']
            const length = appList.length
            for (let i = 0; i < length; i++) {
                if (appList[i] === appType) {
                    return true
                }
            }
            return false
        },
        isAuthFreeRoute () {
            return (!this.$route.name || this.$route.meta.skipAuth)
        },
        logout () {
            localStorage.clear()
            window.location.href = `${ getAuthUrl() }/logout`
        },
        upgradeRequired () {
            return this.getAuthData.subscription_type === 'OpenCA' || this.getAuthData.subscription_type === 'OpenCA+'
        },
        isServiceAccount () {
            return this.getAuthData?.is_service_account ?? undefined
        },
        isOwner () {
            this.checkAppTypeExistence()
            return this.getAuthData.app_type === 'owner'
        },
        isAdmin () {
            return ['IT Administrator', 'Administrator'].includes(this.getAuthData.account_type)
        },
        isOwnerRep () {
            this.checkAppTypeExistence()
            return this.getAuthData.app_type === this.appTypes.TYPE_DEV
        },
        usesBudget () {
            this.checkAppTypeExistence()
            return this.getAuthData.app_type === this.appTypes.TYPE_DEV || this.getAuthData.app_type === this.appTypes.TYPE_OWNER || this.getAuthData.app_type === this.appTypes.TYPE_GC
        },
        getCurrentApp () {
            this.checkAppTypeExistence()
            return this.getAuthData.app_type
        },
        checkAppTypeExistence () {
            if (!this.getAuthData.app_type) {
                throw new Error('Wrong current app type')
            }
        },
        isAccessAllowed (element) {
            return this.getAuthAccess[element]
        },
        getCompanyMongoId () {
            const x = this.getAuthData
            return x['company_mongo_id']
        },
        isRole (roleList) {
            const tableRole = {

                ROLE_ADMIN: 1,
                ROLE_SALES_EXECUTIVE: 2,
                ROLE_PROJECT_MANAGER: 3,
                ROLE_ACCOUNTING: 4,
                ROLE_ARCHITECT: 5,
                ROLE_PRINCIPAL: 6,
                ROLE_CUSTOM: 10,
                ROLE_EMPLOYEE: 100,
                ROLE_NEW_EMLOYEE: 105,
                ROLE_ENGINEER: 110,
                ROLE_FOREMAN: 120,
                ROLE_SHOP_FOREMAN: 130,
                ROLE_SUPERVISOR: 140,
                ROLE_PROJECT_EXECUTIVE: 200,
            }
            const roleType = this.getAuthData['u_role_id']
            const length = roleList.length
            for (let i = 0; i < length; i++) {
                if (parseInt(tableRole[roleList[i]]) === parseInt(roleType)) {
                    return true
                }
            }
            return false
        },
        getValueByAppType (object) {
            const appType = this.getCurrentApp()
            return get(object, appType, object.default)
        },
        getSubdomain () {
            return this.getAuthData.workspace_subdomain
        },
    },
}

export default isLoggedMixin
