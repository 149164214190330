export function ADD_ALERT (state, alert) {
    state.alerts.push(alert)
}

export function REMOVE_ALERT (state, alertId) {
    state.alerts = state.alerts.filter(item => {
        return item._id !== alertId
    })
}

export function REMOVE_All_ALERTS (state) {
    state.alerts = []
}

export function REMOVE_ALERTS_BY_TYPE (state, type) {
    state.alerts = state.alerts.filter(item => {
        return item.type !== type
    })
}
export function SET_DURING_ACTION (state, value) {
    state.isDuringAction = value
}
