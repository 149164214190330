const ListDefault = () => import('./InvitationList.vue')
const InvitationAccept = () => import('./Components/InvitationAccept/InvitationAccept.vue')
const InvitationDetails = () => import('./Components/invitation-details/InvitationDetails.vue')

export default [
    {
        path: '/invitations/list',
        name: 'invitations-list',
        component: ListDefault,
        meta: {
            schema: 'invitations',
            title: 'Invitations List'
        }
    },
    {
        path: '/invitation/view/:id',
        name: 'invitation-view',
        component: InvitationDetails,
        meta: {
            schema: 'invitations',
            title: 'Invitation Details'
        }
    },
    {
        path: '/invitation-accept/:id',
        name: 'invitation-accept',
        component: InvitationAccept,
        meta: {
            schema: 'invitations',
            title: 'Accept Invitation'
        }
    }
]
