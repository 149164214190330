<template>
    <div :id="commentId" class="io-add-comment">
        <div class="io-add-comment__header">
            <span>{{ $t('Add Comment No. {number}', { number: commentNumber }) }}</span>
            <Tooltip :text="$t('This Comment will be visible to everyone who has access to this drawing set.')"/>
        </div>

        <Mentions
            :key="`add-comment-${ model.id }`"
            :attached_employees_ids="mentionUsers"
            :custom-people-list="mentionUsers"
            :attach-below="true"
            v-model="comment"
            @trigger-mention="triggerMention"
            @update-mentions="mentionsIds = $event"
        />

        <div class="io-add-comment__footer">
            <IOButton variant="secondary" size="small" @click="cancel">{{ $t('Cancel') }}</IOButton>
            <IOButton size="small" :disabled="!comment" @click="createAnnotation">{{ $t('Post') }}</IOButton>
        </div>
    </div>
</template>

<script lang="ts" src="./AddComment.ts"></script>
<style lang="scss" src="./AddComment.scss" scoped></style>
