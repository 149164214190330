import { isUndefined, isArray, clone, head, last, toString, uniq, filter, find, isEmpty, get, uniqueId, toArray, cloneDeep } from 'lodash'
import invitationClient from '@/api/invitation/invitationClient'
import { mapState } from 'vuex'
import isLoggedMixin from '../../../mixins/isLoggedMixin'

const lists = {
    data () {
        return {
            list: [],
            attachedParsedEmails: [],
            addedCustomEmails: [],
            companyMongoId: ''
        }
    },
    mixins: [ isLoggedMixin ],
    computed: {
        ...mapState('cached', {
            listEmployees: (state) => state.lists.employees,
            listContacts: (state) => state.lists.contacts
        }),

        appsTypes () {
            let apps = [
                { id: this.appTypes.TYPE_OWNER, type: 'Owner / Developer' },
                { id: this.appTypes.TYPE_DEV, type: 'Owner\'s Representative' },
                { id: this.appTypes.TYPE_AC, type: 'Architect / Engineers' },
                { id: this.appTypes.TYPE_GC, type: 'General Contractor' },
                { id: this.appTypes.TYPE_SC, type: 'Subcontractor' }
            ]

            return apps.filter((listItem) => {
                if (!this.restrictedAppTypes.length) {
                    return true
                }
                return (this.restrictedAppTypes.includes(listItem['id']) &&
                    listItem['id'] !== this.selectedUser.company_app
                )
            })
        },

        /**
         * Get class for modal screen
         *
         * @return {boolean}
         */
        shortenScreen () {
            if (this.loading) {
                return false
            }
            if (this.modalScreen !== 1 && this.modalScreen !== 5) {
                return true
            }
            if (this.modalScreen === 1 && this.filteredList && !this.filteredList.length) {
                return true
            }
            if (this.modalScreen === 5) {
                if (this.isEmail && !this.listWorkspaces.email.length && !this.listWorkspaces.trusted.length) {
                    return true
                }
            }
            return false
        },

        /**
         * Number or results from workspaces
         *
         * @return {number}
         */
        workspacesNumResults () {
            if (this.isEmail) {
                return this.listWorkspaces.email.length + this.listWorkspaces.trusted.length
            } else {
                return 0
            }
        },

        /**
         * Used emails list
         *
         * @return {[]}
         */
        usedEmails () {
            let usedEmails = []
            let numElements = this.added.length
            for (let i = 0; i < numElements; i++) {
                if (this.added[i].email) {
                    usedEmails.push(this.added[i].email)
                }
            }

            numElements = this.addedCustom.length
            for (let i = 0; i < numElements; i++) {
                if (this.addedCustom[i].email) {
                    usedEmails.push(this.addedCustom[i].email)
                }
            }

            numElements = this.invited.length
            for (let i = 0; i < numElements; i++) {
                if (this.invited[i].email) {
                    usedEmails.push(this.invited[i].email)
                }
            }

            numElements = this.cloned_invited_pending.length
            for (let i = 0; i < numElements; i++) {
                if (this.cloned_invited_pending[i].email) {
                    usedEmails.push(this.cloned_invited_pending[i].email)
                }
            }

            return usedEmails
        },

        filteredList () {
            if (this.searchEmployeeCleaned.length > 0) {
                let added = this.added.filter((listItem) => {
                    if (listItem.hasOwnProperty('fullsearch') && listItem.fullsearch !== 'undefined') {
                        return listItem.fullsearch.trim().toLowerCase().indexOf(this.searchEmployeeCleaned) !== -1
                    }
                })
                this.setFirstAdded(added)

                let addedCustom = this.addedCustom.filter((listItem) => {
                    if (listItem.hasOwnProperty('fullsearch') && listItem.fullsearch !== 'undefined') {
                        return listItem.fullsearch.trim().toLowerCase().indexOf(this.searchEmployeeCleaned) !== -1
                    }
                })
                this.setFirstAddedCustom(addedCustom)

                let invited = this.invited.filter((listItem) => {
                    return (
                        !isUndefined(listItem.fullsearch) && listItem.fullsearch.includes(this.searchEmployeeCleaned)
                    )
                })
                this.setFirstInvited(invited)

                let pendings = []
                if (this.showPendings && this.cloned_invited_pending) {
                    pendings = this.cloned_invited_pending.filter((listItem) => {
                        return (
                            !isUndefined(listItem.fullsearch) &&
                            listItem.fullsearch.includes(this.searchEmployeeCleaned)
                        )
                    })
                    this.setFirstPending(pendings)
                }

                let employees = this.employees.filter((listItem) => {
                    return (
                        !isUndefined(listItem.fullsearch) && listItem.fullsearch.includes(this.searchEmployeeCleaned)
                    )
                })
                this.setFirstEmployee(employees)

                let customList = []
                if (this.customList) {
                    customList = this.customList.concat(this.addedCustom).filter((listItem) => {
                        return (
                            !isUndefined(listItem.fullsearch) &&
                            listItem.fullsearch.includes(this.searchEmployeeCleaned)
                        )
                    })
                    this.setFirstCustom(customList)
                }

                if (this.showContacts) {
                    let contacts = this.contacts.filter((listItem) => {
                        return (
                            !isUndefined(listItem.fullsearch) &&
                            listItem.fullsearch.includes(this.searchEmployeeCleaned)
                        )
                    })
                    this.setFirstContact(contacts)
                    return added.concat(invited, pendings, employees, contacts, customList)
                } else {
                    return added.concat(invited, pendings, employees, customList)
                }
            } else {
                this.setFirstAdded(this.added)

                this.setFirstAddedCustom(this.addedCustom)

                this.setFirstInvited(this.invited)
                if (this.showPendings) {
                    this.setFirstPending(this.cloned_invited_pending)
                }

                this.setFirstEmployee(this.employees)

                if (this.customList) {
                    this.setFirstCustom(this.customList)
                }

                if (this.showContacts) {
                    this.setFirstContact(this.contacts)
                }
                this.buildList()
                return this.list
            }
        }
    },

    methods: {
        buildList () {
            this.list = []
            this.list.push(...this.added)
            this.list.push(...this.addedCustom)
            this.list.push(...this.invited)
            this.list.push(...this.cloned_invited_pending)

            if (this.showEmployees) {
                this.list.push(...this.employees)
            }
            if (this.showContacts) {
                this.list.push(...this.contacts)
            }

            if (this.customList) {
                this.list.push(...this.customList)
            }
        },

        buildSearchString (obj) {
            let availableItems = ['fullname', 'email', '_company_name']
            let items = []

            availableItems.forEach((item) => {
                if (!isUndefined(obj[item]) && !isNull(obj[item]) && obj[item] !== '') {
                    items.push(toString(obj[item]).trim())
                }
            })
            return uniq(items).filter(Boolean).join(' ').trim().toLowerCase()
        },

        /**
         * Some shit methods to make modal better looking
         * We should do this in other way and remove this crap, and also crap for "is last item"
         *
         * @param arr
         */
        setFirstAdded (arr) {
            const first = head(arr)
            if (first !== undefined) {
                this.addedFirst = first.key
                const last = last(arr)
                this.addedLast = last.key
            } else {
                this.addedFirst = 0
                this.addedLast = 0
            }
        },
        setFirstInvited (arr) {
            const first = head(arr)
            if (first !== undefined) {
                this.invitedFirst = first.key
                const last = last(arr)
                this.invitedLast = last.key
            } else {
                this.invitedFirst = 0
                this.invitedLast = 0
            }
        },
        setFirstEmployee (arr) {
            const first = head(arr)
            if (first !== undefined) {
                this.employeesFirst = first.key
                const last = last(arr)
                this.employeesLast = last.key
            } else {
                this.employeesFirst = 0
                this.employeesLast = 0
            }
        },
        setFirstContact (arr) {
            const first = head(arr.filter(item => !item.custom1))
            if (first !== undefined) {
                this.contactsFirst = first.key
                const last = last(arr.filter(item => !item.custom1))
                this.contactsLast = last.key
            } else {
                this.contactsFirst = 0
                this.contactsLast = 0
            }
        },
        setFirstPending (arr) {
            const first = head(arr)
            if (first !== undefined) {
                this.pendingsFirst = first.key
                const last = last(arr)
                this.pendingsLast = last.key
            } else {
                this.pendingsFirst = 0
                this.pendingsLast = 0
            }
        },
        setFirstCustom (arr) {
            const first = head(arr)
            if (first !== undefined) {
                this.customListFirst = first.key
                const last = last(arr)
                this.customListLast = last.key
            } else {
                this.customListFirst = 0
                this.customListLast = 0
            }
        },
        setFirstAddedCustom (arr) {
            const first = head(arr)
            if (first !== undefined) {
                this.addedCustomFirst = first.key
                const last = last(arr)
                this.addedCustomLast = last.key
            } else {
                this.addedCustomFirst = 0
                this.addedCustomLast = 0
            }
        },

        async getWorkspacesByEmail () {
            this.listWorkspaces.email = []
            this.listWorkspaces.trusted = []

            this.loading = true

            if (!this.allowedClientsTypes && this.forcedAppType) {
                this.allowedClientsTypes = []
                this.allowedClientsTypes.push(this.forcedAppType)
                this.restrictedAppTypes.push(this.forcedAppType)
            }

            let { data } = await invitationClient.getWorkspacesByEmail(
                this.selectedUser.email,
                this.allowedClientsTypes,
                this.restrictedCompanyMongo,
                this.restrictedAppTypes
            )
            data.emails.forEach((company) => {
                this.parseCompany(company, 'email')
            })
            data.accounts.forEach((company) => {
                this.parseCompany(company, 'trusted')
            })

            if (this.forcedAppType && this.restrictedAppTypes.includes(this.forcedAppType)) {
                this.restrictedAppTypes = this.restrictedAppTypes.filter(item => item !== this.forcedAppType)
            }

            this.loading = false
        },

        /**
         * Prepare company data
         *
         * @param company
         * @param type
         * @return {boolean|{mongoId: *, workspace: boolean, address: string, apptype: *, isAdded: boolean, fullsearch: string, logo: *, subdomain: *, _id: *, id: *, fullname: *, email: *}}
         */
        parseCompany (company, type = null) {
            const mongoId = toString(company._id)
            let address = []
            let addressParts = []

            if (company.c_addr1) {
                addressParts.push(company.c_addr1)
            }
            if (company.c_addr2) {
                addressParts.push(company.c_addr1)
            }
            addressParts = addressParts.join(' ')
            address.push(addressParts)

            if (company.c_city) {
                address.push(company.c_city)
            }
            if (company.c_state_name) {
                address.push(company.c_state_name)
            }

            const item = {
                mongoId: mongoId,
                _id: mongoId,
                id: mongoId,
                key: mongoId,
                workspace: true,
                workspaceType: type,
                company_name: company.c_company_name,
                address: address.join(', '),
                photo: company.employee_photo || company.c_logo,
                type: 4,
                typeList: 'company',
                app_type: company.c_app_type,
                employee_mongo: company.employee_mongo_id,
                company_mongo: mongoId,
                contact: company.employee_full_name || '',
                subdomain: company.c_subdomain,
                fullsearch: this.buildSearchString(company),
                isAdded: false
            }
            item.fullname = [company.employee_full_name, company.c_company_name].filter(Boolean).join(' - ')
            item.email = item.fullname

            if (item.fullname) {
                this.listWorkspaces[type].push(item)
                return item
            }
        },

        /**
         * Add new element to list
         *
         * @param element
         * @param type
         */
        parseElement (element, type = null) {
            if (!type || !element) {
                return false
            }

            let item = null
            if (type === 'employee') {
                item = this.parseEmployee(element)
            } else if (type === 'contact') {
                this.companyMongoId = this.getCompanyMongoId()
                item = this.parseContact(element)
            } else if (type === 'connected') {
                item = this.parseConnected(element)
            } else if (type === 'pending') {
                item = this.parsePending(element)
            }

            if (!item) {
                return false
            }
            if (!item.fullsearch) {
                item.fullsearch = this.buildSearchString(item)
            }

            return item
        },

        /**
         * Parse employee data
         *
         * @param item
         * @return {null}
         */
        parseEmployee (item = null) {
            let result = null
            if (this.restrictEmployeeTypes.length) {
                if (!this.isAccountType(item.e_role_id, this.restrictEmployeeTypes)) {
                    return result
                }
            }

            // skip employee record if provided email in "hideEntriesWithEmail" match
            if (!isEmpty(this.hideEntriesWithEmail) && item.e_email === this.hideEntriesWithEmail) {
                return result
            }

            // Skip creator if needed
            if (this.hideCreator && item.e_mongo && item.e_mongo === this.creatorMongoId) {
                return result
            }

            if (item.email && !this.usedEmails.includes(item.email)) {
                if (isArray(this.allowedEmployees)) {
                    if (this.allowedEmployees.indexOf(item.id, 0) === -1) {
                        return result
                    }

                    if (this.employees.find(employee => employee.email === item.email)) {
                        return result
                    }
                }

                let isAdded = this.attachedParsedEmails.includes(item.email)
                result = {
                    id: item.id,
                    key: item.key,
                    mongoId: item.mongoId,
                    typeList: item.typeList,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    fullname: item.fullname,
                    photo: item.photo,
                    email: item.email,
                    employee_mongo: item.mongoId,
                    isAdded: isAdded,
                    isPending: item.isPending,
                    isFromMyCompany: item._is_from_my_company || item.isFromMyCompany || false,
                    e_active: item.e_active
                }
                isAdded ? this.added.push(result) : this.employees.push(result)
            }

            return result
        },

        /**
         * Parse contact
         *
         * @param item
         * @return {null}
         */
        parseContact (item = null) {
            let result = null
            if (item._company_id && item.email && !this.usedEmails.includes(item.email) && item._company_mongo !== this.companyMongoId) {
                // Restrict types
                if (this.allowedClientsTypes && isArray(this.allowedClientsTypes)) {
                    if (this.allowedClientsTypes.indexOf(item._company_type) === -1 && this.allowedClientsTypes.indexOf(item._company_additional_type) === -1) {
                        return false
                    }
                }

                // skip contact record if provided email in "hideEntriesWithEmail" match
                if (!isEmpty(this.hideEntriesWithEmail) && item.contact_email === this.hideEntriesWithEmail) {
                    return result
                }

                // Skip creator if needed
                if (this.hideCreator && item.contact_mongo && item.contact_mongo === this.creatorMongoId) {
                    return result
                }

                // Company restrictions
                if (this.restrictedCompanyId !== null && item._company_id !== this.restrictedCompanyId) {
                    return false
                }
                if (this.restrictedCompanyMongo !== null && item._company_mongo !== this.restrictedCompanyMongo) {
                    return false
                }

                const isPending =
                    this.cloned_invited_pending.find((a) => {
                        return a.email === item.email
                    }) !== undefined
                if (isPending) {
                    return false
                }

                const isAdded =
                    this.cloned_invited_people.find((a) => {
                        return a.email === item.email
                    }) !== undefined

                result = {
                    mongoId: item.id,
                    id: item.id,
                    key: item.key,
                    typeList: item.typeList,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    fullname: item.fullname,
                    photo: item.photo,
                    email: item.email,
                    company_id: item._company_id,
                    company_name: item._company_name,
                    company_mongo: item._company_mongo,
                    employee_mongo: item.contact_mongo,
                    app_type: item._company_app_type,
                    _is_owner: item._is_owner,
                    isAdded: isAdded,
                    custom1: get(item, 'custom1', null),
                    e_active: item.e_active
                }

                isAdded ? this.added.push(result) : this.contacts.push(result)
            }

            return result
        },

        /**
         * Parse connected item
         *
         * @param item
         * @return {null}
         */
        parseConnected (item = null) {
            let result = null
            const email = item.email

            if (email && !this.usedEmails.includes(email)) {
                result = {
                    _id: item._id,
                    id: item._id || item.id,
                    key: 'invited' + item._id || item.id,
                    mongoId: toString(item._id || item.id),
                    typeList: 'invited',
                    firstname: item.firstname,
                    lastname: item.lastname,
                    fullname: (item.firstname + ' ' + item.lastname).trim(),
                    photo: item.avatar || item.photo
                        ? (item._id || item.id)
                        : null,
                    email: item.email,
                    company_name: item.eh_company_name || item.company_name,
                    employee_mongo: item._id || item.id,
                    app_type: item.company_app_type || item.app_type,
                    isAdded: true
                }

                if (result.fullname) {
                    this.cloned_invited_mongo_ids.push(item._id)
                    this.invited.push(result)
                }
            }

            return result
        },

        /**
         * Parse invited item
         *
         * @param item
         * @return {null}
         */
        parsePending (item = null) {
            let result = null
            if (item && item.email && !this.usedEmails.includes(item.email)) {
                let itemId = item._id || item.mongoId
                const isExternal = Boolean(!item.internal)

                result = {
                    id: itemId,
                    key: 'pending' + (itemId || uniqueId()),
                    mongoId: itemId,
                    type: this.$route.meta.inviteType,
                    typeList: 'pending',
                    fullname: item.email,
                    photo: null,
                    email: item.email,
                    isAdded: true,
                    isPending: true,
                    isExternal: isExternal,
                    internal: !isExternal,
                    app_type: item.app_type,
                    company_mongo: item.receiver_company_mongo_id,
                    employee_mongo: item.receiver_employee_hash_mongo_id,
                    additionalData: item.additionalData,

                    // data for Meeting Minutes
                    _id: get(item, '_id', ''),
                    company_name: get(item, 'company_name', ''),
                    firstname: get(item, 'firstname', ''),
                    lastname: get(item, 'lastname', ''),
                    // email: get(item, 'email', ''),
                    avatar: get(item, 'avatar', ''),
                    job_title: get(item, 'job_title', ''),
                    cell_phone: get(item, 'cell_phone', '')
                }

                this.cloned_invited_pending.push(result)
            }

            return result
        },

        /**
         * Prepare invitations
         * If not provide by props, try to get from API
         *
         * @return {Promise<void>}
         */
        async getInvited (force = false) {
            if (!this.canSendInvitation && !this.forceGetInvited) {
                return false
            }

            this.cloned_invited_people = []
            if (!this.showEmployees && !this.showContacts) {
                return
            }

            this.cloned_invited_mongo_ids = []
            if (isArray(this.invitedMongoIds)) {
                this.cloned_invited_mongo_ids = clone(this.invitedMongoIds)
            }

            if (force || !isArray(this.invited_people)) {
                let response = await invitationClient.getInvited(this.itemId, this.$route.meta.inviteType, this.section)
                if (response.status === 200 && response.data.invitations) {
                    this.cloned_invited_people = response.data.invitations
                }
            } else if (isArray(this.invited_people)) {
                this.cloned_invited_people = clone(this.invited_people)
            }

            this.cloned_invited_people.forEach((value) => {
                this.parseElement(value, 'connected')
            })
        },

        /**
         * Prepare employees list
         *
         * @return {Promise<boolean>}
         */
        async getEmployees () {
            this.employees = []
            let employees = []
            let listEmployees = this.listEmployees
            if (!this.showEmployees) {
                return false
            }

            if (this.getFromCustomEndpoint) {
                await this.$api.get(this.inviteModalOptions.customEndpoint)
                    .then(({ data }) => {
                        listEmployees = toArray(data.result)
                    })
            }

            this.attachedParsedEmails = this.attachedParsed.map((a) => a.e_email)
            listEmployees = listEmployees.filter(item => {
                return item.e_active || (!item.e_active && this.attachedParsedEmails.includes(item.email))
            })

            if (this.useEmployeesMongoIds) {
                this.attachedParsed.forEach(item => {
                    const index = listEmployees.findIndex(employee => employee.mongoId === item.mongoId)

                    index !== -1
                        ? listEmployees[index] = Object.assign(listEmployees[index], item)
                        : listEmployees.push(item)
                })
            }

            employees = cloneDeep(listEmployees)

            let elementsNum = employees.length
            for (let i = 0; i < elementsNum; i++) {
                this.parseElement(employees[i], 'employee')
            }
        },

        /**
         * Prepare contacts list
         *
         * @return {Promise<boolean>}
         */
        async getContacts () {
            this.contacts = []
            if (!this.showContacts) {
                return false
            }
            let listContacts = this.listContacts
            if (this.getFromCustomEndpoint) {
                await this.$api.get(this.inviteModalOptions.customEndpoint)
                    .then(({ data }) => {
                        listContacts = this.customEndpointMerge
                            ? [...data.data, ...listContacts]
                            : data.data
                    })
            }

            let elementsNum = listContacts.length
            for (let i = 0; i < elementsNum; i++) {
                this.parseElement(listContacts[i], 'contact')
            }
        },

        /**
         * Prepare pending acceptation list
         *
         * @return {Promise<void>}
         */
        async getPendings (force = false) {
            if (!this.showPendings) {
                return
            }

            this.cloned_invited_pending = []
            if (!this.showEmployees && !this.showContacts) {
                return
            }

            if (this.itemId && (force || !isArray(this.invited_pending))) {
                const { data } = await invitationClient.getPending(this.itemId, this.$route.meta.inviteType, this.section)
                if (data && data.invitations) {
                    data.invitations.forEach((value) => {
                        this.parseElement(value, 'pending')
                    })
                }
            } else if (isArray(this.invited_pending)) {
                this.invited_pending.forEach((pending) => {
                    this.parseElement(pending, 'pending')
                })
            }
        },

        /**
         * Prepare custom list data
         *
         * @param item
         * @return {null}
         */
        prepareCustomList (item = null) {
            if (this.showCustomList) {
                this.addedCustomEmails = this.addedCustom.map((a) => a.email)
                this.customList = []
                this.addedCustom = []

                const list = cloneDeep(this.customListSource)
                if (list) {
                    for (let i = 0; i < list.length; i++) {
                        let item = list[i]
                        const isAdded = this.addedCustomEmails.includes(item.email)
                        item.isAdded = isAdded
                        isAdded ? this.addedCustom.push(item) : this.customList.push(item)
                    }
                }
            }
        }
    }
}

export default lists
