import { app } from '@/app'
import { nextTick } from 'vue'
import { VueMaskDirective } from 'v-mask'
import store from '@/store/index'
import router from './router.js'
import Main from '@/layouts/main'
import iconLibrary from '@/base/iconLibrary.js'
import './helpers/icon.js'
import Echo from './echo'
import Tooltip from 'vue-directive-tooltip'
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'
// @ts-ignore
import { ApiClient, ApiClientV2 } from '@/api/api'
// @ts-ignore
import i18n from '@/base/i18n'
import globalComponents from './globalComponents.js'
import * as FullStory from '@fullstory/browser'
// @ts-ignore
import { createPinia } from 'pinia'
import filtersNew from '@/helpers/filtersNew'
import progress from '@/directives/progress'
import vClickOutside from 'click-outside-vue3'
import { getAuthUrl } from '@/helpers/urls'
import { webCredentialStore } from '@/io-modules/web-credential/store/webCredentialStore.ts'
import { WebCredentialEnum } from '@/io-modules/web-credential/enums/WebCredentialEnum.ts'
import * as Sentry from '@sentry/vue'
import { ingeniousTelemetry } from '@/telemetry/provider.js'

// Load credentials
await webCredentialStore.loadWebCredentials()

// Ingenious OpenTelemetry
ingeniousTelemetry.init(webCredentialStore)
ingeniousTelemetry.instrumentRouter(router)

const pinia = createPinia()

app.mixin(iconLibrary)

const sentryDsn = webCredentialStore.findWebCredential(WebCredentialEnum.SENTRY_DSN)

if (sentryDsn) {
    Sentry.init({
        app,
        dsn: sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],

        environment: webCredentialStore.findWebCredential(WebCredentialEnum.MAIN_DOMAIN),
        tracesSampleRate: parseFloat(webCredentialStore.findWebCredential(WebCredentialEnum.SENTRY_TRACES_SAMPLE_RATE)) || 0,
        tracePropagationTargets: [webCredentialStore.findWebCredential(WebCredentialEnum.SENTRY_ALLOWED_URL) + '/.*', /^\/api\//],
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
    })
    Sentry.setTag('region', webCredentialStore.findWebCredential(WebCredentialEnum.REGION))
}

app.use(store)
app.use(router)
app.use(pinia)
app.use(i18n)
app.use(globalComponents)

app.config.globalProperties.$moduleSchemas = import.meta.glob('./*modules/**/schema.js')
app.config.globalProperties.$api = ApiClient
app.config.globalProperties.$api2 = ApiClientV2
app.config.globalProperties.$echo = Echo
app.config.globalProperties.$tracer = ingeniousTelemetry.getTracer()

app.component('main-layout', Main)

app.config.globalProperties.$FullStory = FullStory
app.config.globalProperties.$filters = filtersNew

// Enable dev tools
if (process.env.NODE_ENV === 'development') {
    app.config.performance = true
    // app.config.warnHandler = () => null
} else {
    // app.config.debug = false
    app.config.warnHandler = () => null
}

/* Plugins */
const vMaskV2 = VueMaskDirective
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
}

app.directive('mask', vMaskV3)
app.directive('progress', progress)
app.use(vClickOutside)
app.use(Tooltip)

if (
    process.env.ACCESSIBILITY_AUDITING &&
    !window.location.host.includes('ingenious.build') &&
    !window.location.host.includes('ingenious.training')
) {
    import('vue-axe').then((VueAxe) => {
        app.use(VueAxe.default)
        app.component('VueAxePopup', VueAxe.VueAxePopup)
        app.mount('#vue')
    })
} else {
    app.mount('#vue')
}

let shouldRefreshLater = false

async function checkAppVersion (): void {
    // Disable commitHash check on Vite HMR
    if (import.meta?.hot) {
        return
    }

    const response = await fetch(
        process.env.CDN_URL + `assets/version.json?_=${ Math.floor(Date.now() / 60000) }`,
        {
            headers: {
                'X-CDN-NOCACHE': 1
            }
        }
    )

    if (response.status === 200) {
        const responseData = await response.json()
        if (responseData.commitHash !== process.env.BUILD_VERSION.commitHash) {
            shouldRefreshLater = true
        }
    }
}

const checkVersionInterval = setInterval(() => {
    checkAppVersion()
}, 60000)

/**
 * Redirect browser if route doesnt exists
 */

router.beforeEach((to, from, next) => {
    if (shouldRefreshLater) {
        window.location.href = to.path
        return
    }

    if (to.meta.skipAuth) {
        next()
        return
    }
    if (!localStorage.getItem('appType')) {
        const pathname = window.location.pathname
        if ('/' === pathname || pathname.indexOf('logout') > -1) {
            window.location.href = `${ getAuthUrl() }/login`

            next(false)
            return
        }

        const base64CurrentUrl = btoa(window.location.href)
        window.location.href = `${ getAuthUrl() }/login?currentUrl=` + base64CurrentUrl

        next(false)
        return
    }
    // reset flag about forced currency
    // exception for proposals, employee timesheets and in project views
    if (
        (from.matched.length === 0 || to.matched.length === 0) ||
        (
            (from.matched[0].name !== 'proposalvue' && to.matched[0].name !== 'proposalvue') &&
            (to.matched[0].path !== '/project/:pid')
        )
    ) {
        window.useProjectCurrency = false
    }

    window.VueFromPath = from // used on my timesheet

    if (to.name === '404') {

        window.location.href = to.path
    } else {
        if (to.name !== 'project-bid-requests' && to.name !== from.name) {
            store.dispatch('filtersModal/setFiltersCounter', 0)
        }
        next()
    }
})

/**
 * Change the page title based on meta title or default.
 */
router.afterEach((to, from) => {
    nextTick(() => {
        document.title = to.meta && to.meta.title && `${ i18n.global.t(to.meta.title) } - INGENIOUS.BUILD` || 'INGENIOUS.BUILD'
    })
})
