import { ApiClient, ApiClientV2, ApiClientV3 } from '@/api/api'
import axios from 'axios'

let client = ApiClient
let client2 = ApiClientV2
let client3 = ApiClientV3

let source

export default {
    getRecords ( data, apiVersion = 1 ) {
        if (source) {
            source.cancel()
        }

        source = axios.CancelToken.source()

        let apiClient = client

        if (apiVersion === 2) {
            apiClient = client2
        }

        if (apiVersion === 3) {
            apiClient = client3
        }

        if (data.params.method) {
            return apiClient[data.params.method](`${ data.endpoint }`, {
                cancelToken: source.token,
                params: {
                    ...data.params,
                    offset: data.limit,
                },
            })
        }

        const endpoint = data.isFetchAllRecords
            ? `${ data.endpoint }`
            : `${ data.endpoint }/${ data.limit }`

        return apiClient.realJson(endpoint, {
            ...data.params
        }, {
            cancelToken: source.token
        })
    },

    getFiltersRecords (endpoint, params, apiVersion = 1) {
        let apiClient = client

        if (apiVersion === 2) {
            apiClient = client2
        }

        if (apiVersion === 3) {
            apiClient = client3
        }

        return apiClient.get(endpoint, { params })
    }
}
