import { defineComponent, PropType } from 'vue'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import ApproverAssignmentType from '@/interfaces/modules/projects/financial-approvals/ApproverAssignmentType'
import ApprovalTypeOption from '@/interfaces/modules/projects/financial-approvals/ApprovalTypeOption'

export interface ApproverAssignmentOption {
    label: string
    value: ApproverAssignmentType
}

export default defineComponent({
    name: 'AssignmentTypeSelector',
    components: {
        CustomMultiselect,
    },
    props: {
        defaultOptionValue: { type: String as PropType<ApproverAssignmentType> },
    },
    data () {
        return {
            approvalTypeOptions: [
                { label: this.$tc('Approver'), value: ApproverAssignmentType.APPROVER },
                { label: this.$tc('Reviewer'), value: ApproverAssignmentType.REVIEWER },
            ] as ApprovalTypeOption[],
        }
    },
    computed: {
        value: {
            get (): ApproverAssignmentOption {
                const option = this.approvalTypeOptions.find(option => this.defaultOptionValue === option.value)

                if (Boolean(option)) {
                    return option
                }

                return this.approvalTypeOptions.find(option => ApproverAssignmentType.APPROVER === option.value)
            },
            set (value) {
                this.$emit('changeOption', value)
            },
        },
    },
})
