import { sortBy } from 'lodash'
import { defineComponent } from 'vue'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import ContactRadio from '@/components/invite-modal-v3/components/contact-radio/ContactRadio.vue'
import { mapActions, mapState } from 'pinia'
import { inviteModalStore } from '@/components/invite-modal-v3/store/inviteModalStore'
import SingleCompanyType from '@/components/invite-modal-v3/components/single-company-type/SingleCompanyType.vue'
import SearchInputNew from '@/components/search-input/SearchInput.vue'
import SingleCompany from '@/components/invite-modal-v3/components/single-company/SingleCompany.vue'
import type appTypes from '@/base/appTypes'
import WorkspaceRadio from '@/components/invite-modal-v3/components/workspace-radio/WorkspaceRadio.vue'
import { Country, State } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'

export default defineComponent({
    name: 'AddContactDetails',
    components: {
        CustomMultiselect,
        ContactRadio,
        SingleCompanyType,
        SingleCompany,
        SearchInputNew,
        WorkspaceRadio
    },
    props: {
        componentKey: { type: String, required: true },
        allowedClientsTypes: { type: Array as PropType<appTypes[]>, default: null },
        preselectedWorkspaceType: { type: String, default: null },
        disableInviting: { type: Boolean, required: false, default: false },
        disableBookmarking: { type: Boolean, required: false, default: false },
    },
    emits: ['companyTypeToggled'],
    data () {
        return {
            selectedCountry: null,
            selectedState: null,
            selectedWorkspaceType: null,
            selectedExistingWorkspace: null,
            selectedExistingWorkspaceId: null,
            companyTypes: [
                {
                    name: this.$t('Design'),
                    description: this.$t('Design services on a project related to architecture, interiors, engineering, and other consultant services.'),
                    iconClass: 'icon-design',
                    iconSvg: '/new-theme/img/company-type-design.svg',
                    type: 'ac',
                },
                {
                    name: this.$t('Owner / Developer'),
                    description: this.$t('Companies or individuals who act as the client and/or developer on the project.'),
                    iconClass: 'icon-dev',
                    iconSvg: '/new-theme/img/company-type-owner.svg',
                    type: 'owner',
                },
                {
                    name: this.$t('Owner’s Representative'),
                    description: this.$t('Companies hired by an owner to manage and execute a design and construction project on their behalf.'),
                    iconClass: 'icon-or',
                    iconSvg: '/new-theme/img/company-type-owners-rep.svg',
                    type: 'dev',
                },
                {
                    name: this.$t('General Contractor'),
                    description: this.$t('Companies that manage and oversee the daily on site construction project.'),
                    iconClass: 'icon-gc',
                    iconSvg: '/new-theme/img/company-type-gc.svg',
                    type: 'gc',
                },
                {
                    name: this.$t('Subcontractor'),
                    description: this.$t('Companies that provide labor or services for construction operations.'),
                    iconClass: 'icon-sc',
                    iconSvg: '/new-theme/img/company-type-sc.svg',
                    type: 'sc',
                },
                {
                    name: this.$t('Other'),
                    description: this.$t('Companies with specialized roles not listed above.'),
                    iconClass: 'icon-other',
                    iconSvg: '/new-theme/img/question-grey.svg',
                    type: null,
                },
            ]
        }
    },
    computed: {
        ...mapState(inviteModalStore, {
            search (store) {
                return store.search(this.componentKey)
            },
            isSearchValidEmail (store) {
                return store.isSearchValidEmail(this.componentKey)
            },
            isIntentionallyAddingContact (store) {
                return store.isIntentionallyAddingContact(this.componentKey)
            },
            isUpdatingWorkspaceType (store) {
                return store.isUpdatingWorkspaceType(this.componentKey)
            },
            isFetchingExistingWorkspaces (store) {
                return store.isFetchingExistingWorkspaces(this.componentKey)
            },
            contact (store) {
                return store.contact(this.componentKey)
            },
            existingWorkspaces (store) {
                return store.existingWorkspaces(this.componentKey)
            },
            countriesList (store) {
                return store.countriesList(this.componentKey)
            },
            statesList (store) {
                return store.statesList(this.componentKey)
            },
        }),

        countries (): Array<Country> {
            return sortBy(this.countriesList.map((country) => ({ id: country.id, code: country.iso2, name: country.name })), 'name')
        },

        states (): Array<State> {
            let states = this.statesList

            if (this.selectedCountry && Object.keys(this.selectedCountry).length) {
                states = states.filter(state => state.country_id === this.selectedCountry.id)
            }

            return sortBy(states.map((state) => (
                {
                    id: state.state_id,
                    code: state.state_code,
                    name: state.state_name,
                    country_id: state.country_id
                }
            )), 'name')
        },

        workspaceTypeOptions (): Array {
            let companyTypes = this.companyTypes;

            if (this.allowedClientsTypes?.length) {
                companyTypes = companyTypes.filter(
                    companyType => this.allowedClientsTypes.includes(companyType.type)
                )
            }

            if (this.isUpdatingWorkspaceType) {
                companyTypes = companyTypes.filter(companyType => null !== companyType.type)
            }

            return companyTypes
        },

        workspaceTypeSettings (): object {
            return {
                textPlaceholder: 'Select workspace',
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: true,
                searchPropertyNames: ['name']
            }
        },

        isFormValid (): boolean {
            return (this.isUpdatingWorkspaceType ? true : this.validateEmail(this.contact.email))
                && this.contact.first_name
                && this.contact.last_name
                && this.otherFieldsAreValid()
        },

        isZipCodeValid (): boolean {
            const regex = /^[^-!$%^&*@()_+|~=`\\#{}\[\]:";'<>?,.\/a-z\s][A-Z0-9\.\-\s]{3,9}$/;
            return !this.contact.workspace.zip.length || regex.test(this.contact.workspace.zip)
        },

        emailDomain (): string|null {
            if (!this.isSearchValidEmail) {
                return;
            }

            let email = this.search
            let domain = email.substring(email.lastIndexOf("@") + 1)

            return domain.split('.').slice(-2).join('.')
        },

        shouldCreateNewCompany(): boolean {
            return this.existingWorkspaces.length === 0
        },

        shouldDisableStateSelection (): boolean {
            if (null === this.selectedCountry) {
                return false
            }

            return ! ['US', 'CA'].includes(this.selectedCountry.code)
        }
    },
    watch: {
        search (value) {
            this.resetSelections()
            this.resetContact(this.componentKey)

            if (this.validateEmail(value)) {
                this.contact.email = value
                this.fetchExistingWorkspaces(this.search, this.componentKey)
            }
        },

        isFormValid (value) {
            this.setIsFormValid(value, this.componentKey)
        },

        'contact.first_name' (value) {
            if (value) {
                this.setSearchDisabled(true, this.componentKey)
            }
        },

        'contact.last_name' (value) {
            if (value) {
                this.setSearchDisabled(true, this.componentKey)
            }
        },

        'contact.workspace': {
            deep: true,
            handler (value) {
                let anyAttributeHasValue = Object.values(value).some(attribute => attribute)

                if (anyAttributeHasValue) {
                    this.setSearchDisabled(true, this.componentKey)
                }
            }
        },

        shouldCreateNewCompany: {
            deep: true,
            handler (value) {
                this.contact.is_new_company = value
            }
        },

        selectedCountry: {
            deep: true,
            handler (value) {
                this.contact.workspace.country_id = value?.code || null
            }
        },

        selectedState: {
            deep: true,
            handler (value) {
                this.contact.workspace.state_id = value?.id || null
            }
        },

        selectedExistingWorkspaceId (value) {
            this.contact.workspace.id = value

            if (value === null) {
                this.selectedExistingWorkspace = null
            } else {
                this.selectedExistingWorkspace = this.existingWorkspaces.find(workspace => workspace.id === value)
            }
        },

        shouldDisableStateSelection (value) {
            if (true === value) {
                this.selectedState = null
            }
        },
    },
    mounted () {
        if (!this.isUpdatingWorkspaceType) {
            this.resetExistingWorkspaces(this.componentKey)
            this.setFetchingExistingWorkspaces(true, this.componentKey)
            this.fetchExistingWorkspaces(this.search, this.componentKey)
        }

        this.preselectWorkspaceType()

        this.initializeGoogleAddressSearch()
    },

    beforeUnmount () {
        this.setIntentionallyAddingContact(false, this.componentKey)
        this.setSearchDisabled(false, this.componentKey)
        this.setUpdatingWorkspaceType(false, this.componentKey)
    },

    methods: {
        ...mapActions(inviteModalStore, [
            'pushInvitedContact',
            'pushBookmarkedContact',
            'fetchCompanies',
            'setIsFormValid',
            'fetchExistingWorkspaces',
            'resetExistingWorkspaces',
            'setIntentionallyAddingContact',
            'setFetchingExistingWorkspaces',
            'setSearchDisabled',
            'setUpdatingWorkspaceType',
            'resetContact',
        ]),

        initializeGoogleAddressSearch (): void {
            const postFillAddressCallback = (addressData): void => {
                this.$nextTick(() => {
                    this.contact.workspace.address_line1 = addressData.address1
                    this.contact.workspace.address_line2 = addressData.address2 || null
                    this.contact.workspace.city = addressData.city
                    this.contact.workspace.zip = addressData.zip

                    if (addressData.state_id) {
                        this.selectedCountry = this.countries.find(country => addressData.state_id.country_id === country.id) || null
                        this.selectedState = this.states.find(state => addressData.state_id.code === state.code) || null
                    }
                })
            }

            const countryIndex: number = 6

            this.initGoogleAddressSearch(
                { address1: this.contact.workspace.address_line1 },
                this.states,
                '',
                true,
                this.countries,
                countryIndex,
                'address1',
                postFillAddressCallback
            )
        },

        onCompanyTypeToggle (state: boolean): void {
            this.$emit('companyTypeToggled', state)
        },

        onSelectCompanyType (type: any): void {
            this.selectedWorkspaceType = type
            this.contact.workspace.app_type = type?.type || null
        },

        validateEmail (email: string): boolean {
            const regex = /\S+@\S+\.\S+/
            return regex.test(email)
        },

        otherFieldsAreValid (): boolean {
            if (this.isUpdatingWorkspaceType) {
                return this.selectedWorkspaceType && this.contact.workspace.app_type !== null
            }

            if (false === this.contact.is_new_company) {
                return !! this.contact.workspace.id
            }

            return this.contact.workspace.name?.length > 2
                && this.contact.workspace.address_line1
                && this.contact.workspace.country_id
                && this.contact.workspace.city
                && this.contact.workspace.zip
                && this.selectedWorkspaceType
                && this.isZipCodeValid
        },

        resetSelections(): void {
            this.selectedState = null
            this.selectedCountry = null
            this.selectedWorkspaceType = null
            this.selectedExistingWorkspace = null
            this.selectedExistingWorkspaceId = null
        },

        preselectWorkspaceType(): void {
            let type = null;

            if (this.preselectedWorkspaceType) {
                type = this.workspaceTypeOptions.find(item => item.type === this.preselectedWorkspaceType)
            } else if (this.workspaceTypeOptions.length === 1) {
                type = this.workspaceTypeOptions[0]
            }

            this.onSelectCompanyType(type)
        }
    }
})
