import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { paths } from '@/interfaces/api/apidefinitions'
import CommentItem from '@/components/comments/interfaces/CommentItem.ts'

export type RevisionRequestData = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_id}/invoice/{invoice_id}/revision']['get']['responses']['200']['content']['application/json']

export type RevisionRequestItem = RevisionRequestData['adjustments'][0]
export type RevisionRequestClientRequestedData = RevisionRequestItem['client_requested']
export type RevisionRequestVendorAppliedData = RevisionRequestItem['vendor_applied']
export type RevisionRequestOriginalData = RevisionRequestItem['original']
export type RevisionRequestComment = RevisionRequestItem['comments'][0]

export default {
    getRevisionRequest (projectGlobalId: string, invoiceId: string): AxiosPromise<RevisionRequestData> {
        if (!projectGlobalId) {
            throw new Error('No Project Global Id Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice Id Provided!')
        }

        return client.get(`/invoices/${ projectGlobalId }/invoice/${ invoiceId }/revision`)
    },

    saveSingleRevisedItem (projectGlobalId: string, invoiceId: string, wbsItemId: string, data: RevisionRequestItem): AxiosPromise<RevisionRequestItem> {
        if (!projectGlobalId) {
            throw new Error('No Project Global Id Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice Id Provided!')
        }
        if (!wbsItemId) {
            throw new Error('No Contract WBS Id Provided!')
        }

        return client.patch(`/invoices/${ projectGlobalId }/invoice/${ invoiceId }/revision/item/${ wbsItemId }`, data)
    },

    removeSingleRevisedItem (projectGlobalId: string, invoiceId: string, wbsItemId: string): AxiosPromise<void> {
        if (!projectGlobalId) {
            throw new Error('No Project Global Id Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice Id Provided!')
        }
        if (!wbsItemId) {
            throw new Error('No Contract WBS Id Provided!')
        }

        return client.delete(`/invoices/${ projectGlobalId }/invoice/${ invoiceId }/revision/item/${ wbsItemId }`)
    },

    clearAllRevisions (projectGlobalId: string, invoiceId: string): AxiosPromise<void> {
        if (!projectGlobalId) {
            throw new Error('No Project Global Id Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice Id Provided!')
        }

        return client.post(`/invoices/${ projectGlobalId }/invoice/${ invoiceId }/revision/clear`)
    },

    getRevisionComments (projectGlobalId: string, invoiceId: string, wbsItemId: string): AxiosPromise<void> {
        if (!projectGlobalId) {
            throw new Error('No Project Global Id Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice Id Provided!')
        }
        if (!wbsItemId) {
            throw new Error('No Contract WBS Id Provided!')
        }

        return client.get(`/invoices/${ projectGlobalId }/invoice/${ invoiceId }/revision/comments/${ wbsItemId }`)
    },

    saveRevisionComments (projectGlobalId: string, invoiceId: string, wbsItemId: string, comments: CommentItem[]): AxiosPromise<void> {
        if (!projectGlobalId) {
            throw new Error('No Project Global Id Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice Id Provided!')
        }
        if (!wbsItemId) {
            throw new Error('No Contract WBS Id Provided!')
        }

        return client.post(`/invoices/${ projectGlobalId }/invoice/${ invoiceId }/revision/comments/${ wbsItemId }`, { comments: comments })
    }
}
