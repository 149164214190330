import data from '@/components/invite-modal/data.json'
// @ts-ignore
import { ApiClient } from '@/api/api'
import { ContactData, ContactToAction, GroupedCompaniesContacts } from '@/interfaces/components/invite-modal/Contact'
let client = ApiClient

export default {
    fetchContacts () {
        return data
    },
    searchForContact (
        search: string,
        skip: string,
        filters?: object,
        resourceId?: string,
        section?: string,
        modelType?: string
    ) {
        return client.post('/invitation/search', {
            search,
            skip,
            filters,
            resource_id: resourceId,
            resource_type: modelType,
            model_section: section,
        })
    },
    searchCompanies (payload: object) {
        return client.post('/invitation/search-companies', payload)
    },
    fetchCompanyTypes () {
        return client.get('/company/types')
    },
    fetchCompanyTypesV2 () {
        return client.get('/company/types/v2')
    },
    saveContact (data: ContactData) {
        return client.post('/invitation/save', data)
    },
    inviteContact (data: ContactData) {
        return client.post('/invitation/invite', data)
    },
    remindContact (data: ContactData) {
        return client.post('/invitation/remind', data)
    },
    removeContact (data: ContactData) {
        return client.post('/invitation/remove', data)
    },
    batchInvitations (data: ContactToAction) {
        return client.post('/invitation/batch', data)
    }
}
