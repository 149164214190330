<template>
    <div class="io-banners" v-if="activeBanners.length">
        <slot name="banners">
            <div id="io-bottom-banners">
                <template v-for="(banner, index) in activeBanners" :key="banner.name">
                    <banner
                            :banner="banner"
                    ></banner>
                </template>
            </div>
        </slot>
    </div>
</template>
<script src="./main.js"></script>
<style type="text/css" lang="scss" src="./main.scss" scoped />
