import jobsiteLocationsClient from '../../../api/project-settings-jobsite-locations/jobsiteLocationsClient'

export const fetchLocations = async function ({commit}, projectID) {
    const { data } = await jobsiteLocationsClient.getLocations(projectID)
    commit('SET_ALL_LOCATIONS', data.items)
}

export const updateLocation = async function ({commit, dispatch}, obj) {
    await jobsiteLocationsClient.updateLocation(obj.projectID, obj.location)
    dispatch('fetchLocations', obj.projectID)
}

export const addParentLocation = function ({commit}, location) {
    commit('SET_PARENT_LOCATION', location)
}

export const toggleSelectedLocations = function ({commit, dispatch}, value) {
    commit('TOGGLE_SELECTED_LOCATIONS', value)
}

export const removeSelectedLocation  = function ({commit, dispatch}, value) {
    commit('REMOVE_SELECTED_LOCATION', value)
}

export const addSelectedLocation = function  ({commit, dispatch}, value) {
    commit('ADD_SELECTED_LOCATION', value)
}

export const removeSelectedLocations  = function ({commit, dispatch}, value) {
    commit('REMOVE_SELECTED_LOCATIONS', value)
}

export const addSelectedLocations = function  ({commit, dispatch}, value) {
    commit('ADD_SELECTED_LOCATIONS', value)
}

export const setSelectedLocations = function ({ commit, dispatch }, data) {
    commit('SET_SELECTED_LOCATIONS', data)
}

export const setSelectedTargetLocations = function ({ commit, dispatch }, data) {
    commit('SET_SELECTED_TARGET_LOCATIONS', data)
}
