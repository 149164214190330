import * as actions from './actions'
import * as getters from './getters'
import { cloneDeep } from 'lodash'

const loadingModal = {
    enabled: false,
    data: {
        title: '',
        text: ''
    }
}

export default {
    namespaced: true,
    namespace: 'modal/',
    state () {
        return {
            show: false,
            refresh: false,
            refresh_resource: null,
            returnData: {
                type: null,
                data: null,
                response: null
            },
            key: null,
            editItemId: 0,
            additionalData: null,
            modalLoading: cloneDeep(loadingModal),
            modalHidden: false
        }
    },
    actions,
    getters,
    mutations: {
        SET_SHOW (state, value) {
            state.show = value
        },
        SET_HIDDEN (state, value) {
            state.modalHidden = value
        },
        SET_REFRESH (state, value) {
            state.refresh = value
        },
        SET_REFRESH_RESOURCE (state, value) {
            state.refresh_resource = value
        },
        SET_RETURN_DATA (state, value) {
            state.returnData = value
        },
        SET_EDIT_ITEM_ID (state, value) {
            state.editItemId = value
        },
        SET_ADDITIONAL_DATA (state, value) {
            state.additionalData = value
        },
        REFRESH_KEY (state) {
            state.key = crypto.randomUUID()
        },
        RESET_ADDITIONAL_DATA (state) {
            state.additionalData = null
        },
        SET_MODAL_LOADING (state, value) {
            state.modalLoading.enabled = true
            state.modalLoading.data.title = value.title || ''
            state.modalLoading.data.text = value.text || ''
        },
        RESET_MODAL_LOADING (state) {
            state.modalLoading = cloneDeep(loadingModal)
        }
    }
}
