<template>
    <div class="io-thumbnail-atom io-design-system" data-testid="io-thumbnail-component">
        <slot />

        <div
            v-if="!error"
            class="io-thumbnail-atom__pic"
            :class="[
                `io-thumbnail-atom__pic--${ position }`,
                `io-thumbnail-atom__pic--${ align }`,
                { 'io-thumbnail-atom__pic--shadow': shadow },
                { 'io-thumbnail-atom__pic--loading': !loaded },
            ]"
            :style="{ ...offsetMarginStyle, zIndex, padding: `${ frame }px` }"
            data-testid="preview"
        >
            <img
                :src="url"
                alt="thumbnail"
                class="io-thumbnail-atom__img"
                :style="{ width: `${ width }px`, height: `${ height }px` }"
                data-testid="image"
                @error="error = $event"
                @load="loaded = true"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./IOThumbnail.ts"></script>
<style lang="scss" src="./IOThumbnail.scss" scoped></style>
