enum AccountingIntegrationType {
    BUDGET = 'budget',
    BUDGET_CHANGE = 'budget_change',
    CHANGE_ORDER = 'change_order',
    CONTRACT = 'contracts',
    PAYAPPS = 'payapps',
    PROJECT = 'project',
}

export default AccountingIntegrationType
