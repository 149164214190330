<template>
    <div v-if="isInvoiceMy">
        <div class="io-popup-section">
            <div class="io-f-row">
                <div class="io-label">Client</div>
                <p class="io-value">{{ contractData.client }}</p>
            </div>
        </div>
        <div class="io-popup-section">
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Invoice ID
                </div>
                <div class="io-input-holder" :class="{ 'io-validation-error': v$.invoiceData.invoice_id.$error }">
                    <input type="text"
                           v-model="invoiceData.invoice_id">
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Invoice Date</div>
                <div class="io-input-holder">
                    <FieldDateTime :id="'invoice_date'"
                                   :options="invoiceDateOptions"
                                   :class="{ 'io-validation-error': v$.invoiceData.invoiceDate.$error }"
                                   v-model="invoiceData.invoiceDate"
                    ></FieldDateTime>
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Start Date</div>
                <div class="io-input-holder">
                    <FieldDateTime :id="'addInvoice-start_date'"
                                   :class="{ 'io-validation-error': v$.invoiceData.start_date.$error }"
                                   :options="invoiceDateOptions"
                                   v-model="invoiceData.start_date"/>
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">End Date</div>
                <div class="io-input-holder">
                    <FieldDateTime :id="'addInvoice-end_date'"
                                   :class="{ 'io-validation-error': v$.invoiceData.end_date.$error }"
                                   :options="invoiceDateOptions"
                                   v-model="invoiceData.end_date"/>
                </div>
            </div>
        </div>
        <div class="io-popup-section">
            <div class="io-f-row" v-if="appAvail([appTypes.TYPE_DEV, appTypes.TYPE_OWNER])">
                <div class="io-label">{{ getValueByAppType({default: 'Billing Period', dev: 'Application Package'}) }}
                </div>
                <div class="io-input-holder">
                    <multiselect :options="optionsPeriods"
                                 :searchable="true"
                                 :taggable="false"
                                 :disabled="disabled.selectedPeriod"
                                 label="name"
                                 placeholder="-Select-"
                                 track-by="id"
                                 v-model="selectedPeriod"
                                 @select="periodSelect"
                                 @remove="periodRemove"
                    >
                        <template #singleLabel="props"><span><span class="io-small-pill">{{ props.option.number }}</span>{{ props.option.name }}</span>
                        </template>
                        <template #option="props">
                            <span>
                                <span class="io-small-pill">{{ props.option.number }}</span>
                                {{ props.option.name }}
                            </span>
                        </template>
                    </multiselect>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="isInvoiceVendor">
        <div class="io-popup-section">
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Select Vendor</div>
                <div class="io-input-holder" :class="{ 'io-validation-error': v$.invoiceData.contract_id.$error }">
                    <custom-dropdown :options="optionsVendors"
                                     :isGrouped="false"
                                     :allowInvite="false"
                                     :showSearch="true"
                                     :modeCompanies="true"
                                     :editable="!disabled.selectedVendor"
                                     :defaultItem="selectedVendor"
                                     placeholderSearch="Search..."
                                     @select="vendorSelect"
                                     @remove="vendorRemove"
                    />
                </div>
            </div>
        </div>
        <div class="io-popup-section">
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Vendor Invoice ID
                </div>
                <div class="io-input-holder" :class="{ 'io-validation-error': v$.invoiceData.invoice_id.$error }">
                    <input type="text"
                           v-model="invoiceData.invoice_id">
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">
                    <template v-if="isInvoiceRetention">
                        Retention Invoice Value
                        <Tooltip :text="$t('Enter the value of the retention reduction (net invoice amount) as the gross amount is unchanged for a retention only invoice.')" />
                    </template>
                    <template v-else>Gross Invoice Value</template>
                </div>
                <div class="io-input-holder" :class="{ 'io-validation-error': v$.invoiceData.value.$error }">
                    <field-numeric-int
                        :options="optionsValue"
                        classname="io-small w20 fright"
                        v-model="invoiceData.value"
                    />
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Invoice Date</div>
                <div class="io-input-holder">
                    <FieldDateTime :id="'invoice_date'"
                                     :options="invoiceDateOptions"
                                     :class="{ 'io-validation-error': v$.invoiceData.invoiceDate.$error }"
                                     v-model="invoiceData.invoiceDate"
                    />
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Start Date</div>
                <div class="io-input-holder">
                    <FieldDateTime :id="'addInvoice-start_date'"
                                     :class="{ 'io-validation-error': v$.invoiceData.start_date.$error }"
                                     :options="invoiceDateOptions"
                                     v-model="invoiceData.start_date"
                    />
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">End Date</div>
                <div class="io-input-holder">
                    <FieldDateTime :id="'addInvoice-end_date'"
                                     :class="{ 'io-validation-error': v$.invoiceData.end_date.$error }"
                                     :options="invoiceDateOptions"
                                     v-model="invoiceData.end_date"
                    />
                </div>
            </div>
        </div>
        <div class="io-popup-section">
            <div v-if="appAvail([appTypes.TYPE_DEV, appTypes.TYPE_OWNER])" class="io-f-row">
                <div class="io-label">{{ getValueByAppType({default: 'Billing Period', dev: 'Application Package'}) }}
                </div>
                <div class="io-input-holder">
                    <multiselect :options="optionsPeriods"
                                 :searchable="true"
                                 :taggable="false"
                                 :disabled="disabled.selectedPeriod"
                                 label="name"
                                 placeholder="-Select-"
                                 track-by="id"
                                 v-model="selectedPeriod"
                                 @select="periodSelect"
                                 @remove="periodRemove"
                    >
                        <template #singleLabel="props"><span><span class="io-small-pill">{{ props.option.number }}</span>{{ props.option.name }}</span>
                        </template>
                        <template #option="props">
                            <span>
                                <span class="io-small-pill">{{ props.option.number }}</span>
                                {{ props.option.name }}
                            </span>
                        </template>
                    </multiselect>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="isInvoiceNonContracted">
        <div class="io-popup-section">
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Company</div>
                <div class="io-input-holder" :class="{ 'io-validation-error': v$.invoiceData.vendor.$error }">
                    <multiselect
                                 :preselect-first="false"
                                 :options="optionsProviders"
                                 group-label="groupName"
                                 :group-select="false"
                                 group-values="groupList"
                                 placeholder="Please Select"
                                 @select="selectVendorNonContracted"
                                 label="name"
                                 track-by="id"
                                 v-model="invoiceData.vendor"
                    />
                </div>
            </div>
        </div>
        <div class="io-popup-section">
            <div v-if="isInvoiceExternalApprovalEnabled" class="io-f-row">
                <div class="io-label io-required-indicator">{{ $t('Invoice Paid By') }}</div>
                <InvoicePaidBySelector
                    :companies="invoicePaidByCompanies"
                    :defaultValue="invoiceData.budget_type"
                    @selectedValueChanged="onInvoicePaidBySelected"
                />
            </div>

            <div class="io-f-row">
                <div class="io-label io-required-indicator">Invoice No.</div>
                <div class="io-input-holder" :class="{ 'io-validation-error': v$.invoiceData.invoice_id.$error }">
                    <input type="text"
                           v-model="invoiceData.invoice_id">
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Category</div>
                <div class="io-input-holder" :class="{ 'io-validation-error': v$.invoiceData.category.$error }">
                    <multiselect
                                 :preselect-first="false"
                                 :options="categories"
                                 placeholder="Please Select"
                                 label="name"
                                 track-by="id"
                                 v-model="invoiceData.category"
                    />
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Invoice Date</div>
                <div class="io-input-holder">
                    <FieldDateTime :id="'invoice_date'"
                                     :options="invoiceDateOptions"
                                     :class="{ 'io-validation-error': v$.invoiceData.invoiceDate.$error }"
                                     v-model="invoiceData.invoiceDate"
                    />
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Due Date</div>
                <div class="io-input-holder">
                    <FieldDateTime :id="'addInvoice-end_date'"
                                     :class="{ 'io-validation-error': v$.invoiceData.end_date.$error }"
                                     :options="invoiceDateOptions"
                                     v-model="invoiceData.end_date"
                    />
                </div>
            </div>
            <div class="io-f-row">
                <div class="io-label io-required-indicator">Gross Invoice Value</div>
                <div class="io-input-holder" :class="{ 'io-validation-error': v$.invoiceData.value.$error }">
                    <field-numeric-int
                                       :options="{numeralPositiveOnly: false, numericOnly: false}"
                                       classname="io-small w20 fright"
                                       v-model="invoiceData.value"
                    />
                </div>
            </div>
        </div>
        <div class="io-popup-section">
            <div v-if="appAvail([appTypes.TYPE_DEV, appTypes.TYPE_OWNER])" class="io-f-row">
                <div class="io-label">{{ getValueByAppType({default: 'Billing Period', dev: 'Application Package'}) }}
                </div>
                <div class="io-input-holder">
                    <multiselect :options="optionsPeriods"
                                 :searchable="true"
                                 :taggable="false"
                                 :disabled="disabled.selectedPeriod"
                                 label="name"
                                 placeholder="-Select-"
                                 track-by="id"
                                 v-model="selectedPeriod"
                                 @select="periodSelect"
                                 @remove="periodRemove"
                    >
                        <template #singleLabel="props"><span><span class="io-small-pill">{{ props.option.number }}</span>{{ props.option.name }}</span>
                        </template>
                        <template #option="props">
                            <span>
                                <span class="io-small-pill">{{ props.option.number }}</span>
                                {{ props.option.name }}
                            </span>
                        </template>
                    </multiselect>
                </div>
            </div>
        </div>
    </div>
    <div v-else></div>

    <div v-if="integrationProjectSettings.hasExpenseTypeOption" class="io-popup-section">
        <div  class="io-f-row">
            <div class="io-label">{{ $t('Yardi Expense Type') }}
            </div>
            <div class="io-input-holder">
                <multiselect
                    :options="currentProjectSettings.expense_types_list"
                    :searchable="true"
                    :taggable="false"
                    label="name"
                    :placeholder="'-' + $t('Select') + '-'"
                    track-by="id"
                    v-model="selectedInvoiceExpenseType"
                    @select="invoiceExpenseTypeSelect"
                    @remove="invoiceExpenseTypeRemove"
                />
            </div>
        </div>
    </div>

</template>
<script src="./init.js"></script>
