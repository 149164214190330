import { defineComponent, PropType } from 'vue'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import ApprovalNoteData from '@/interfaces/modules/projects/financial-approvals/ApprovalNoteData'
import ApproverAssignmentType from '@/interfaces/modules/projects/financial-approvals/ApproverAssignmentType'
import SingleApprovalStatus from '@/io-modules/approval-workflows/components/single-approval-status/SingleApprovalStatus.vue'
import ApprovalTextService from '@/io-modules/approval-workflows/services/ApprovalTextService'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus'
import SingleApprovalAction from '@/interfaces/modules/projects/financial-approvals/SingleApprovalAction'
import moment from 'moment'
import Approver from '@/io-modules/approval-workflows/interfaces/Approver'
import ApprovalConfirmationModalService from '@/io-modules/approval-workflows/services/SimpleConfirmationModalsService'
import ResourceApprovalsStatus from '@/io-modules/approval-workflows/enums/ResourceApprovalsStatus.ts'

export default defineComponent({
    name: 'SingleApproval',
    components: {
        Avatar,
        SingleApprovalStatus,
    },
    props: {
        approverData: {
            type: Object as PropType<Approver>,
            required: true
        },
        showSequencePosition: { type: Boolean, default: false },
        showApprovalLimit: { type: Boolean, default: false },
        approvalStatus: { type: String, default: '' }
    },
    emits: ['approverApproveSelected', 'approverReviewSelected', 'approverRejectSelected', 'openOverrideApproverModal'],
    data () {
        return {
            ApproverAssignmentType,
            ApprovalStatus,
            SingleApprovalAction,
            moment
        }
    },
    computed: {
        approvalStatusDefined (): boolean {
            return this.isSubmittedForApproval && this.approverData.approval !== null
        },
        isSubmittedForApproval (): boolean {
            return false
        },
        isAdmin (): boolean {
            return false
        },
        approvalNoteData (): ApprovalNoteData | null {
            if (!this.approverData.approval) {
                return null
            }

            return {
                note: this.approverData.approval.note,
                approved_by: this.approverData.full_name,
                approved_date: this.approverData.approval.voted_at,
                status: this.approverData.approval.status,
                files: this.approverData.approval?.files ?? [],
            }
        },
        isExternalApprover (): boolean {
            return false
        },
        approvedAtText (): string {
            return ApprovalTextService.getApprovedAtText(this.approverData.approval.status)
        },
        approveTitleText (): string {
            return ApprovalTextService.getApproveTitleText(this.approverData.approval.status)
        },
        approvalType (): string {
            switch (this.approverData.limit_type) {
            case 'less_than' :
                return 'Less than'
            case 'greater_than' :
                return 'Greater than'
            case 'no_limit' :
                return ''
            default :
                return ''
            }
        },
        approveActionText (): string {
            return ApproverAssignmentType.APPROVER === this.approverData.assignment_type
                ? this.$tc('Approve')
                : this.$tc('Mark as Reviewed')
        },
        waitingForApproval (): boolean {
            return this.approvalStatus === ResourceApprovalsStatus.SubmittedForApproval
        }
    },
    methods: {
        async sendReminder (): Promise<any> {
            this.setLoadingBar(true)
            try {
                await this.$api.post(`/approval-workflows/instanced-approvers/${ this.approverData.id }/reminder`)
                this.showNotification('success', 'You successfully sent a reminder to this approver!')
            } catch (e) {
                this.showNotification('error', e.response.data.message)
            } finally {
                this.setLoadingBar(false)
            }
        },
        openPopupOverrideApprover (): void {
            this.$emit('openOverrideApproverModal')
        },
        reject (): void {
            this.$emit('approverRejectSelected', this.approverData)
        },
        approve (): void {
            if (ApproverAssignmentType.REVIEWER === this.approverData.assignment_type) {
                this.$emit('approverReviewSelected', this.approverData)
            } else {
                this.$emit('approverApproveSelected', this.approverData)
            }
        },
        handleSendReminder (): void {
            this.showPopupAlert(ApprovalConfirmationModalService.sendReminder(this.sendReminder))
        },
    },
})
