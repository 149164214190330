<template>
    <Teleport v-if="mounted" :to="appendTo">
        <transition
            name="io-connected-overlay"
            @enter="onEnter"
            @after-enter="onAfterEnter"
            @leave="onLeave"
            @after-leave="onAfterLeave"
        >
            <div
                v-if="visible"
                :ref="containerRef"
                :id="id"
                :class="containerClass"
                data-testid="tiered-menu-wrapper"
                v-bind="{ ...$attrs }"
            >
                <TieredMenuSub
                    :ref="menubarRef"
                    :id="id + '_list'"
                    class="io-tieredmenu-root-list"
                    :tabindex="!disabled ? tabindex : -1"
                    role="menubar"
                    :aria-label="ariaLabel"
                    :aria-labelledby="ariaLabelledby"
                    :aria-disabled="disabled || undefined"
                    aria-orientation="vertical"
                    :aria-activedescendant="focused ? focusedItemId : undefined"
                    :menuId="id"
                    :focusedItemId="focused ? focusedItemId : undefined"
                    :items="processedItems"
                    :templates="$slots"
                    :activeItemPath="activeItemPath"
                    :exact="exact"
                    :level="0"
                    @focus="onFocus"
                    @blur="onBlur"
                    @keydown="onKeyDown"
                    @item-click="onItemClick"
                    @item-mouseenter="onItemMouseEnter"
                />
            </div>
        </transition>
    </Teleport>
</template>

<script lang="ts" src="./TieredMenu.ts"></script>
<style lang="scss" src="./TieredMenu.scss"/>
