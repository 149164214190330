import { defineComponent } from 'vue'
import InvoicesListTable from '@/io-modules/invoices/components/invoices-list/invoices-list-table/InvoicesListTable.vue'
import CellType from '@/components/table/CellType'
import InvoiceHeader from '@/io-modules/invoices/enums/InvoiceHeader'
import Invoice from '@/io-modules/invoices/interfaces/Invoice'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import dayjs from 'dayjs'

export default defineComponent({
    name: 'InvoicesToRevertList',
    components: {
        InvoicesListTable,
    },
    props: {
        invoices: {
            type: Array as PropType<Invoice[]>
        },
    },
    computed: {
        invoiceHeaders (): HeaderTable[] {
           return [
                {
                    key: InvoiceHeader.InvoiceNumber,
                    text: this.$t('Generated ID'),
                    value: InvoiceHeader.InvoiceNumber,
                    valueType: CellType.Text,
                    sort: false,
                },
                {
                    key: InvoiceHeader.UniqueId,
                    text: this.$t('Custom ID'),
                    value: InvoiceHeader.UniqueId,
                    valueType: CellType.Text,
                    sort: false,
                },
                {
                    key: InvoiceHeader.InvoiceDate,
                    text: this.$t('Invoice Date'),
                    valueType: CellType.Text,
                    value: InvoiceHeader.InvoiceDate,
                    customValue: (item: Invoice): string => {
                        return dayjs(item.invoice_date).format('DD MMM YYYY')
                    },
                    sort: false,
                },
                {
                    key: InvoiceHeader.Status,
                    text: this.$t('Status'),
                    value: InvoiceHeader.Status,
                    valueType: CellType.StatusPill,
                    sort: false,
                },
                {
                    key: InvoiceHeader.GrossBilledThisPeriod,
                    text: this.$t('Gross Billed This Period'),
                    value: InvoiceHeader.GrossBilledThisPeriod,
                    valueType: CellType.Text,
                    filter: 'formatProjectCurrencyInt',
                    valueAlign: 'aright',
                    extraStyles: 'width: 160px;',
                    sort: false,
                },
            ]
        },
    }
})
