import { ContractHolderType } from '@/io-modules/bid-requests/enums'
import { ContractApprovalStatus } from '@/interfaces/modules/contract/ContractApprovalStatus'
import { ContractStatus } from '@/interfaces/modules/contract/ContractStatus'
import { ContractDocumentStatus } from '@/constants/ContractDocumentStatus'
import ContractType from '@/base/ContractType'
import {
    TableViewMode
} from '@/modules/projects/modules/apps/common/pay-apps/views/line-items/schedule-of-values/enums/TableViewMode'

export function isPrincipalContractHolder (state) {
    return state.contract.contract_holder === ContractHolderType.PRINCIPAL
}

export function isAdvisoryOrAgencyContractHolder (state) {
    return state.contract.contract_holder === ContractHolderType.ADVISORY
        || state.contract.contract_holder === ContractHolderType.AGENCY
}

export function isMainContract (state) {
    return state.contract.isReceiver
}

export function isContractShared (state) {
    return state.contract?.isShared
}

export function approvalAllowsModification (state) {
    return ![
        ContractApprovalStatus.INTERNAL_APPROVAL,
        ContractApprovalStatus.APPROVED,
        ContractApprovalStatus.CLIENT_APPROVAL,
    ].includes(state.contract.approvalStatus)
}

export function isContractApproved (state) {
    return ContractApprovalStatus.APPROVED === state.contract.approvalStatus
}

export function isContractApprovalDraft (state) {
    return ContractApprovalStatus.DRAFT === state.contract.approvalStatus
}

export function isContractStatusPartiallySigned (state) {
    return ContractStatus.PARTIALLY_SIGNED === state.contract.status
}

export function isContractInitialStatusPartiallySigned (state) {
    return ContractStatus.PARTIALLY_SIGNED === state.contract.initialContractStatus
}

export function isContractStatusReadyForSignature (state) {
    return ContractStatus.READY_FOR_SIGNATURE === state.contract.status
}

export function isContractApprovalStatusApproved (state) {
    return ContractApprovalStatus.APPROVED === state.contract.approvalStatus
}

export function isEveryDocumentReadyForApproval (state) {
    if (!state.contract.contractDocuments?.length) {
        return false
    }

    return areDocumentsReady(state.contract.contractDocuments ?? [])
}

export function isEveryInitialDocumentReadyForApproval (state) {
    if (!state.contract.initialContractDocuments?.length) {
        return false
    }

    return areDocumentsReady(state.contract.initialContractDocuments ?? [])
}

function areDocumentsReady (documents) {
    return (documents ?? []).every((document) => {
        return document.status === document.vendor_status && document.status === ContractDocumentStatus.READY_FOR_APPROVAL
    })
}

export function isEveryDocumentWaitingSignatureFromChild (state) {
    if (!state.contract.contractDocuments?.length) {
        return false
    }

    return areDocumentsWaitingSignatureFromChild(state.contract.contractDocuments ?? [])
}

export function isEveryInitialDocumentWaitingSignatureFromChild (state) {
    if (!state.contract.initialContractDocuments?.length) {
        return false
    }

    return areDocumentsWaitingSignatureFromChild(state.contract.initialContractDocuments ?? [])
}

function areDocumentsWaitingSignatureFromChild (documents) {
    return (documents).every((document) => {
        return document.vendor_status === ContractDocumentStatus.PENDING_SIGNATURE
    })
}

export function isEveryDocumentSignedByChild (state) {
    if (!state.contract.contractDocuments?.length) {
        return false
    }

    return areDocumentsSignedByChild(state.contract.contractDocuments ?? [])
}

export function isEveryInitialDocumentSignedByChild (state) {
    if (!state.contract.initialContractDocuments?.length) {
        return false
    }

    return areDocumentsSignedByChild(state.contract.initialContractDocuments ?? [])
}

function areDocumentsSignedByChild (documents) {
    return (documents).every((document) => {
        return document.vendor_status === ContractDocumentStatus.SIGNED
    })
}

export function isContractStatusExecuted (state) {
    return ContractStatus.EXECUTED === state.contract.status
}

export function isContractSynced (state) {
    return state.isSynced
}

export function insuranceRequirements (state) {
    return state.contract.insurance_requirements
}

export function quotedItems (state) {
    return state.contract.phases
}

export function initialQuotedItems (state) {
    return state.contract.initial_phases || []
}

export function isContractInProject (state) {
    return Boolean(state.contract?.project && state.contract?.project.id)
}

export function contractValueAmount (state) {
    return state.contract.value
}

export function wbsPayload ( state, getters ) {
    return {
        phases: state.wbsItems,
        updatedRequired: state.isRequireUpdateSoVApprovalEnabled,
        approvalRequired: state.isRequireSoVApprovalEnabled,
        displayUnits: getters.isEditable ? state.isDisplayUnitsEnabled : state.isDisplayUnitsEnabledInitial,
        displayRetention: getters.isEditable ? state.isDisplayRetentionEnabled : state.isDisplayRetentionEnabledInitial,
        status: 200, // TODO: remove status after BE issue is fixed
    }
}

export function wbsItemsMain ( state ) {
    return state.wbsItems.filter(( item ) => !item.isAlternate).sort(( a, b ) => a.number - b.number)
}

export function wbsItemsMainTotalValue ( state, getters ) {
    let summaryValue = 0

    getters.wbsItemsMain.forEach(item => summaryValue += Number(item.value))

    return summaryValue
}

export function wbsItemsMainTotalRetention ( state, getters ) {
    let summaryRetention = 0

    getters.wbsItemsMain.forEach(item => summaryRetention += Number(item.value) * Number(item.retention))

    return summaryRetention / getters.wbsItemsMainTotalValue
}

export function wbsItemsAlternate ( state ) {
    return state.wbsItems.filter(( item ) => item.isAlternate)
}

export function wbsItemsAlternateTotalValue ( state, getters ) {
    let summaryValue = 0

    getters.wbsItemsAlternate.forEach(item => summaryValue += Number(item.value))

    return summaryValue
}

export function wbsItemsAlternateTotalRetention ( state, getters ) {
    let summaryRetention = 0

    getters.wbsItemsAlternate.forEach(item => summaryRetention += Number(item.value) * Number(item.retention))

    return summaryRetention / getters.wbsItemsAlternateTotalValue
}

export function originalScopeQIs ( state, getters ) {
    return getters.wbsItemsMain.filter(( item ) => !Boolean(item.changeOrder))
}

export function executedCOsQIs ( state, getters ) {
    return getters.wbsItemsMain.filter((item => Boolean(item.changeOrder)))
}

export function hasExecutedCOs ( state, getters ) {
    return getters.executedCOsQIs.length > 0
}

export function isGMPContract ( state ) {
    return ContractType.GMP === state.contract.contract_type
}

export function isCOsInSeparateCategory ( state ) {
    return TableViewMode.COsInSeparateCategory === state.tableViewMode
}

export function isEditable ( state, getters ) {
    if (state.contract.isShared) {
        return false
    }

    if (!getters.approvalAllowsModification) {
        return false
    }

    if (state.contract.isLocal && !state.contract.isSovApproved && !state.contract.isDeclined) {
        return true
    }

    return state.contract.canEditSettings && state.contract.isEditableSov && !state.contract.isExecuted
}