const ProjectProvider = () => import('@/layouts/project-provider/ProjectProvider.vue')
const SubmittalsCreate = () => import('@/io-modules/submittals/views/submittals-create/SubmittalsCreate.vue')
const SubmittalsShow = () => import('@/io-modules/submittals/views/submittals-show/SubmittalsShow.vue')
const SubmittalsIndex = () => import('@/io-modules/submittals/views/submittals-index/SubmittalsIndex.vue')

const CreateSubmittalOld = () => import('@/io-modules/submittals/components/create/CreateSubmittal.vue')
const SubmittalEditOld = () => import('@/io-modules/submittals/components/edit/SubmittalEdit.vue')
const SubmittalDetailsOld = () => import('@/io-modules/submittals/components/viewer/parts/details/Details.vue')
const SubmittalFilesOld = () => import('@/io-modules/submittals/components/viewer/parts/files/Files.vue')
const SubmittalViewOld = () => import('@/io-modules/submittals/components/viewer/Viewer.vue')
const SubmittalFileViewerOld = () => import('@/io-modules/submittals/components/submittal-file-viewer/SubmittalFileViewer.vue')
const SubmittalLogBuilder = () => import('@/io-modules/submittals/views/log-builder-show/LogBuilderShow.vue')

export default [
    {
        path: '/project/:pid',
        component: ProjectProvider,
        props: true,
        children: [
            {
                path: 'submittals',
                name: 'submittals-index',
                meta: {
                    title: 'Project Submittals',
                    navigationKey: 'submittals',
                },
                component: SubmittalsIndex,
            },
            {
                path: 'submittals-enhanced/create',
                name: 'submittals-create',
                component: SubmittalsCreate,
                props: true,
                meta: {
                    title: 'Create Submittal',
                    navigationKey: 'submittals',
                    showNotificationsForRoutes: ['submittals', 'submittals-viewer-details', 'submittals-show'],
                },
            },
            {
                path: 'submittals/create',
                name: 'create-submittal',
                meta: { title: 'Project Add Submittal', navigationKey: 'submittals' },
                component: CreateSubmittalOld,
            },
            {
                path: 'submittals-enhanced/:submittalId/edit',
                name: 'submittals-edit',
                component: SubmittalsCreate,
                props: true,
                meta: {
                    title: 'Edit Submittal',
                    navigationKey: 'submittals',
                    showNotificationsForRoutes: ['submittals-viewer-details'],
                },
            },
            {
                path: 'submittals/:id/edit',
                name: 'submittal-edit',
                meta: { title: 'Project Edit Submittal', navigationKey: 'submittals' },
                component: SubmittalEditOld,
            },
            {
                path: 'submittals-enhanced/:submittalId',
                name: 'submittals-show',
                component: SubmittalsShow,
                props: true,
                meta: {
                    title: 'View Submittal',
                    navigationKey: 'submittals',
                },
            },
            {
                path: 'submittals/viewer/:id',
                name: 'submittals-viewer',
                component: SubmittalViewOld,
                meta: { navigationKey: 'submittals' },
                redirect: {
                    name: 'submittals-viewer-details',
                },
                children: [
                    {
                        path: 'details',
                        name: 'submittals-viewer-details',
                        meta: {
                            name: 'Submittals',
                            title: 'Submittal Details',
                            ignoreProjectLayout: true,
                            navigationKey: 'submittals',

                        },
                        component: SubmittalDetailsOld,
                    },
                    {
                        path: 'files',
                        name: 'submittals-viewer-files',
                        meta: {
                            name: 'Submittals',
                            title: 'Submittal Files',
                            ignoreProjectLayout: true,
                            navigationKey: 'submittals',
                        },
                        component: SubmittalFilesOld,
                    },
                    {
                        path: 'file/:fileId',
                        name: 'submittals-file-viewer',
                        meta: {
                            name: 'Submittals',
                            title: 'Submittal File',
                            ignoreProjectLayout: true,
                            navigationKey: 'submittals',
                        },
                        component: SubmittalFileViewerOld,
                    },
                ],
            },
            {
                path: 'submittals/log-builder',
                name: 'submittal-log-builder',
                meta: { navigationKey: 'submittals' },
                component: SubmittalLogBuilder,
            },
        ],
    },
]
