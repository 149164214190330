import { defineComponent } from 'vue'
import Popup from '@/components/popup/Popup.vue'

export default defineComponent({
    name: 'ConfirmAlert',
    components: {
        Popup
    },
    props: {
        type: { type: String, default: '' },
        headerTitle: { type: String, default: '' },
        title: { type: String, default: '' },
        subtitle: { type: String, default: '' },
        cancelButtonText: { type: String, default: '' },
        acceptButtonText: { type: String, default: '' },
        headerClass: { type: String, default: '' },
        loading: Boolean,
    },
    emits: ['discard', 'confirm'],
    methods: {
        onDiscard (): void {
            if (this.loading) {
                return
            }

            this.$emit('discard')
        },
        onConfirm (): void {
            if (this.loading) {
                return
            }

            this.$emit('confirm')
        }
    }
})

