<template>
    <div class="io-main-wrapper" :class="mainWrapperClasses">
        <div class="io-page-header-holder"></div>
        <template v-if="isAuthPage">
            <router-view/>
        </template>
        <template v-else-if="authStatus">
            <template v-if="customViewMode">
                <router-view/>
            </template>
            <template v-else>
                <notification/>
                <div class="io-content">
                    <menu-left ref="leftMenu"/>
                    <div class="io-main-container">
                        <loading-screen/>
                        <router-view :key="id"/>
                        <TopBanners/>
                    </div>
                </div>
                <!-- here place all offcanvas elements -->
                <ChatWindow v-if="showChatWindowComponent"/>
                <company-note v-if="showModal === 'company-note'"/>
                <company-contact-edit v-if="showModal === 'company-contact-edit'" />
                <ToDo v-if="showModal === 'to-do'"/>
                <ModalTaskForm v-if="showModal === 'modal-task'" />
                <LetterOfIntentModal v-if="showModal === 'letter-of-intent'"/>
                <ApprovalWorkflowEditModal v-if="showModal === 'edit-approval-workflow'"/>
                <JournalModal/>
                <ModalLoading v-if="modalLoadingEnabled"/>
                <AddNext/>
                <Success/>
                <Error/>
                <BrowserLock v-if="isIE"/>
                <Notices/>
                <PopupAlert/>
                <AddCompany/>
                <EmployeeDateTimePopup/>
                <NotificationToastr />
                <MobileSplashScreen/>
                <AuthExpModal/>
                <ProjectChecklist
                    v-if="showChecklistPopup"
                />
            </template>
        </template>
        <div v-else>
            <loading-main :customShowLoading="true"/>
        </div>
        <DrawingPunch v-if="showModal === 'modal-task'"/>
        <vue-axe-popup />
    </div>
</template>
<script>
    import { defineAsyncComponent } from 'vue'
    import { get, isArray, debounce } from 'lodash'

    import LoadingScreen from '@/components/new-theme/loading-screen'
    import MobileSplashScreen from '@/layouts/offcanvas/mobile-splash-screen/MobileSplashScreen.vue'
    import BrowserLock from '@/elements/BrowserLock.vue'
    import LoadingMain from '@/components/new-theme/loader-main'
    import TopBar from '@/layouts/parts/top-bar/top-bar.vue'
    import TopBanners from '@/layouts/parts/top-banners/main.vue'
    import MenuLeft from '@/layouts/parts/menu-left/MenuLeft.vue'
    import AddCompany from '@/layouts/offcanvas/add-company.vue'
    import isLoggedMixin from '@/mixins/isLoggedMixin'
    import JournalModal from '@/layouts/offcanvas/journal-modal'
    import AddNext from '@/layouts/offcanvas/add-next'
    import Notification from '@/layouts/parts/notification'
    import Success from '@/layouts/offcanvas/success'
    import Notices from '@/layouts/offcanvas/notices/notices.vue'
    import PopupAlert from '@/layouts/offcanvas/popup-alert/PopupAlert'
    import Error from '@/layouts/offcanvas/error'
    import NotificationToastr from '@/components/notification-toastr/NotificationToastr'
    import { mapActions, mapGetters, mapState } from 'vuex'
    import { mapActions as mapPiniaAction, mapState as mapPiniaState } from 'pinia'
    import { setLocalStorage } from '@/base/localStorageChange'
    import { Userpilot } from 'userpilot'
    import '@fancyapps/fancybox/dist/jquery.fancybox.min.css'
    import IntercomDataBuilder from './segment-analytics/IntercomDataBuilder.ts'
    import DrawingPunch from '@/components/drawings/embed/punch/DrawingPunch.vue'
    import dayjs from 'dayjs'

    import * as Sentry from '@sentry/vue'
    import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'
    import ApprovalWorkflowEditModal
        from '@/io-modules/approval-workflows/components/approval-workflow-edit-modal/ApprovalWorkflowEditModal.vue'
    import { integrationsStore } from '@/io-modules/integrations/store/integrationsStore'

    import AuthExpModal from '@/layouts/offcanvas/authExpModal.vue'
    import ProjectChecklist from '@/modules/projects/layout/parts/project-checklist/ProjectChecklist.vue'
    import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
    import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
    import { useProjectChecklistStore } from '@/modules/projects/store/projectChecklistStore'
    import { webCredentialStore } from '@/io-modules/web-credential/store/webCredentialStore'
    import { WebCredentialEnum } from '@/io-modules/web-credential/enums/WebCredentialEnum.ts'

    const ChatWindow = defineAsyncComponent(() => import(/* webpackChunkName: "communication" */ './offcanvas/chat-window/ChatWindow.vue'))
    const CompanyNote = defineAsyncComponent(() => import(/* webpackChunkName: "company" */ '@/layouts/offcanvas/company-note'))
    const CompanyContactEdit = defineAsyncComponent(() => import(/* webpackChunkName: "company" */ '@/layouts/offcanvas/company-contact-edit'))

    const EmployeeDateTimePopup = defineAsyncComponent(() => import(/* webpackChunkName: "employees" */ '@/modules/employee/parts/popup-datetime/main.vue'))
    const ModalTaskForm = defineAsyncComponent(() => import(/* webpackChunkName: "todos" */ '@/modules/todos/ModalTaskForm/ModalTaskForm.vue'))
    const ToDo = defineAsyncComponent(() => import(/* webpackChunkName: "todos" */ '@/layouts/offcanvas/to-do'))

    const LetterOfIntentModal = defineAsyncComponent(() => import(/* webpackChunkName: "contracts" */ '@/layouts/offcanvas/letter-of-intent-modal/LetterOfIntentModal.vue'))

    const ModalLoading = defineAsyncComponent(() => import('./offcanvas/modal-loading/modal-loading.vue'))

    export default {
        name: 'MainLayout',
        components: {
            AuthExpModal,
            'loading-screen': LoadingScreen,
            'loading-main': LoadingMain,
            'top-bar': TopBar,
            'menu-left': MenuLeft,
            'company-note': CompanyNote,
            'company-contact-edit': CompanyContactEdit,
            ToDo,
            ModalTaskForm,
            LetterOfIntentModal,
            ChatWindow,
            MobileSplashScreen,
            JournalModal,
            ProjectChecklist,
            TopBanners,
            AddNext,
            'notification': Notification,
            Success,
            Error,
            Notices,
            PopupAlert,
            NotificationToastr,
            BrowserLock,
            AddCompany,
            EmployeeDateTimePopup,
            ModalLoading,
            DrawingPunch,
            ApprovalWorkflowEditModal,
        },
        mixins: [isLoggedMixin, breadcrumbsMixin, featureFlagsMixin],
        data () {
            return {
                id: 0,
            }
        },
        watch: {
            $route (to, from) {
                const changingViewForSameResource = to.meta?.resourceView && (to.meta?.resourceView === from.meta?.resourceView)
                if (to.name !== from.name && !changingViewForSameResource) {
                    this.emptyBreadcrumbs()
                }
                const showNotificationsForRoutes = get(from, 'meta.showNotificationsForRoutes', [])

                if (this.getEnv !== 'localhost') {
                    Userpilot.reload()
                }
                /**
                 * In general the notifications are disabled when a route is changed.
                 * To enable a notification, add the destination route name to "showLoaderForRoutes" array,
                 * to your starting route meta config (it's called "from")
                 */

                if (from.name !== null && (!isArray(showNotificationsForRoutes) || !showNotificationsForRoutes.includes(to.name))) {
                    this.$store.dispatch('notification/setClose')
                }

                // Redirect to settings if not initialized
                let authData = this.$store.getters['appStore/getAuthData']
                if (typeof authData !== 'undefined' && typeof authData.initialize !== 'undefined' && !authData.initialize) {
                    let allowedRoutes = [
                        'settings-information',
                        'get-menu',
                        'get-menu-counters'
                    ]
                    if (!allowedRoutes.includes(to.name)) {
                        window.location.href = '/settings/company/edit'
                    }
                }

                /*
                Reset Filter And Data When Change Module
                 */
                if (to.meta.schema !== from.meta.schema && get(to, 'meta.filtersName', false) !== get(from, 'meta.filtersName', true)) {
                    this.$store.dispatch('listingFilter/setPage', 1, { root: true })
                    this.$store.dispatch('listingFilter/setColumn', {}, { root: true })
                    this.$store.dispatch('listingFilter/setSearch', '', { root: true })
                    this.$store.dispatch('listingFilter/resetFilters', { root: true })
                }

                /*
                LEFT MENU COUNTER
                 */
                // this.$refs.leftMenu.getCounters()

                /*
                CLOSE MODAL
                 */
                if (this.showModal !== 'modal-task') { // don't close modal for embedded drawings content
                    this.$store.dispatch('modal/setShow', false, { root: true })
                }

                // set router history
                this.$store.dispatch('main/setRouter', {
                    routerTo: to,
                    routerFrom: from
                }, { root: true })
                // SAVE ROUTES Payapps
                if (!from.name?.includes('payapps-invoice')) {
                    this.$store.dispatch('payapps/setRouter', {
                        routerTo: to,
                        routerFrom: from
                    }, { root: true })
                }
                if ((from.name === to.name && from.params.id !== to.params.id) || 'notification' === to?.query?.from) {
                    this.id = this.$route.params.id || 0
                }

                if (this.loadChecklistItems) {
                    this.getProjectChecklist()
                }
            },
            'authStatus' () {
                if (this.authStatus) {
                    this.includeSegmentAnalytics()
                    this.initTranslations()
                    this.initSentry()
                    this.includeFullStory()
                    this.includeGoogleMapsApi()
                    this.initUserPilot()
                }
            },
            'loadChecklistItems' (val) {
                if (val) {
                    this.getProjectChecklist()
                }
            }
        },
        computed: {
            ...mapState('modal', {
                showModal: state => state.show
            }),

            ...mapState('notification', {
                notificationShown: state => state.show
            }),
            ...mapState('main', {
                openLeftMenuStatus: state => state.openLeftMenu,
                isLeftMenuWider: state => state.isLeftMenuWider,
                pageTemplate: state => state.pageTemplate
            }),
            ...mapState('modal', {
                modalLoadingEnabled: state => state.modalLoading.enabled
            }),
            ...mapState('chatWindow', {
                showChatWindowComponent: state => state.showChatWindowComponent
            }),
            ...mapGetters('appStore', ['getAuthData', 'getEnv', 'getFullstoryOrgId']),

            ...mapState('projectBudget', {
                budgetType: state => state.budgetType
            }),
            ...mapState('project', {
                projectObj: state => state.projectObj
            }),
            ...mapPiniaState(useProjectChecklistStore, [
                'checklistElementsData',
            ]),
            ...mapPiniaState(useProjectChecklistStore, {
                getChecklistProjectId (store) {
                    return store.getChecklistProjectId
                }
            }),
            customViewMode () {
                const views = ['login-v2']
                return views.includes(this.$route.name)
            },
            mainWrapperClasses () {
                const routingClasses = [`io-${ this.$route.name }`, this.$route.meta.customClass]
                const conditionalClasses = {
                    'io-open-left-menu': this.openLeftMenuStatus,
                    'io-close-left-menu': !this.openLeftMenuStatus,
                    'io-left-menu-wider': this.isLeftMenuWider,
                    'io-notification': this.notificationShown,
                    'io-page-template-mode': this.pageTemplate
                }

                return [routingClasses, conditionalClasses]
            },
            authStatus () {
                return this.$store.getters['appStore/getAuthStatus']
            },
            isIE () {
                let ua = navigator.userAgent
                let isIe = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
                return isIe
            },
            showChecklistPopup () {
                const showPopupRoutesList = [
                    'project-settings',
                    'project-dashboard',
                ]

                const showChecklistOnRoute = showPopupRoutesList.some(element => this.$route.href?.includes(element))

                return this.isProjectView && showChecklistOnRoute && this.showNewProjectWizard && this.checklistElementsData.some((item) => item.completed === 0)
            },
            loadChecklistItems () {
                return this.isNewProject && this.showProjectChecklistWidget && this.projectObj?.project_local_id
            },
            showProjectChecklistWidget () {
                return this.isFeatureEnabled(FeatureFlagsConsts.PROJECT_CHECKLIST_WIDGET, false)
            },
            showNewProjectWizard () {
                return this.isFeatureEnabled(FeatureFlagsConsts.PROJECT_CREATION_NEW_WIZARD, false)
            },
            isProjectView () {
                return !!this.$route.params.pid
            },
            isNewProject () {
                return this.isProjectView && this.projectObj && !this.projectObj.old_id
            },
            isAuthPage () {
                const currentPath = window.location.pathname
                const authPagesList = [
                    'auth-data',
                    'login',
                    'set-password',
                    'forgot-password',
                    'register',
                    'health'
                ]

                return authPagesList.includes(currentPath.split('/')[1])
            }
        },
        methods: {
            ...mapActions('appStore', ['loadFeatureFlags']),
            ...mapPiniaAction(integrationsStore, ['getIntegrations']),
            ...mapPiniaAction(useProjectChecklistStore, [
                'updateCompletedItems',
            ]),

            refreshBrowser () {
                location.reload(true)
            },
            includeFullStory () {
                if (this.getEnv === 'localhost' || this.getEnv === 'dev' || !this.getFullstoryOrgId) {
                    return
                }
                this.$FullStory.init({ orgId: this.getFullstoryOrgId })
                this.$FullStory.identify(this.getAuthData.u_mongo, {
                    env: this.getEnv,
                    app_type: `${ this.getAuthData.app_type_translated }`,
                    workspace_subdomain: `${ this.getAuthData.workspace_subdomain }`,
                    user_service_account: `${ this.getAuthData.user_service_account }`,
                    account_type: `${ this.getAuthData.account_type }`,
                    displayName: `${ this.getAuthData.u_firstname } ${ this.getAuthData.u_lastname }`,
                    email: this.getAuthData.u_email,
                    business_units: this.getAuthData.business_units_labels,
                    office_location: this.getAuthData.office_location_label
                })
            },

            initSentry () {
                Sentry.setUser({
                    id: this.getAuthData.u_mongo,
                    email: this.getAuthData.u_email,
                    ip_address: this.getAuthData.ip
                })
                Sentry.setContext('workspace', {
                    id: this.getAuthData.company_mongo_id,
                    name: this.getAuthData.u_company_name,
                    subdomain: this.getAuthData.workspace_subdomain
                })
            },

            initUserPilot () {
                if (this.getEnv === 'localhost') {
                    return
                }

                const token = this.getEnv === 'production'
                    ? 'NX-f42880cb'
                    : 'STG-NX-f42880cb'

                Userpilot.initialize(token)

                Userpilot.identify(
                    this.getAuthData.u_mongo,
                    {
                        name: `${ this.getAuthData.u_firstname } ${ this.getAuthData.u_lastname }`,
                        email: this.getAuthData.u_email,
                        company: {
                            id: this.getAuthData.workspace_subdomain ?? null,
                        }
                    }
                )
            },

            includeGoogleMapsApi () {
                const googleMapsApiKey = webCredentialStore.findWebCredential(WebCredentialEnum.GOOGLE_MAPS_API_KEY)
                if (!googleMapsApiKey) {
                    return
                }

                const googleMaps = document.createElement('script')
                googleMaps.type = 'text/javascript'
                googleMaps.async = true
                googleMaps.src = 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsApiKey + '&libraries=places&language=en'

                const firstScript = document.getElementsByTagName('script')[0]
                firstScript.parentNode.insertBefore(googleMaps, firstScript)
            },

            async includeSegmentAnalytics () {
                const availableEnvs = ['production', 'dev', 'staging']

                if (!availableEnvs.includes(this.getEnv)) {
                    return
                }

                await this.loadIntercomData()

                Intercom('boot',
                         (new IntercomDataBuilder()).getIntercomData()
                )
            },
            async loadIntercomData () {
                await this.$store.dispatch('intercom/loadIntercomCompanyData')
                await this.$store.dispatch('intercom/loadIntercomUserData')
            },

            async translationsLoad (locale) {
                try {
                    const response = await this.$api.get('locale/json')
                    this.$i18n.setLocaleMessage(locale, response.data)
                    this.$i18n.locale = locale
                } catch (error) {
                    console.error(`Failed to load translations: ${ error.message }`)
                    return {}
                }
            },

            async getProjectChecklist () {
                if (!this.projectObj?.project_local_id) {
                    return
                }

                if (this.getChecklistProjectId && this.getChecklistProjectId === this.projectObj.project_local_id) {
                    return
                }

                await this.$api.get(`/project-checklist-items/${ this.projectObj.project_local_id }`)
                    .then((response) => {
                        this.updateCompletedItems(response.data, this.projectObj.project_local_id)
                    })
                    .catch((error) => {
                        this.errorHandle(error)
                    })
            },

            async initTranslations () {
                await this.translationsLoad(this.getAuthData.user_language)
            },

            setLastActivityTime: debounce(function () {
                if (!localStorage.getItem('lastActivityModalOpened')) {
                    setLocalStorage('lastActivityTime', dayjs().unix().toString())
                }
            }, 100)
        },
        created () {
            if (!this.isAuthPage) {
                this.$store.dispatch('common/load')
                this.getIntegrations()
            }
        },
        unmounted () {
            window.removeEventListener('mousemove', this.setLastActivityTime)
            window.removeEventListener('mousedown', this.setLastActivityTime)
            window.removeEventListener('keypress', this.setLastActivityTime)
            window.removeEventListener('ontouchstart', this.setLastActivityTime)
            window.removeEventListener('onclick', this.setLastActivityTime)
            window.removeEventListener('scroll', this.setLastActivityTime)
        },
        beforeMount () {
            const appType = localStorage.getItem('appType')

            if (appType) {
                document.querySelector('body').classList.add(appType)
                document.querySelector('body').setAttribute('data-apptype', appType)
            }
        },
        async mounted () {
            if ('auth-data' === this.$route.name) {
                return
            }

            await this.$nextTick()
            if (localStorage.getItem('appType')) {
                this.loadFeatureFlags()
            }

            import('@fancyapps/fancybox')

            // Special trick for vue views to launch modals in fancybox
            jQuery(document).on('click', '.vuefancybox', function () {
                if (jQuery(this).hasClass('vuefancybox')) {
                    let width = $(this).attr('width')
                    width = (!width) ? '80%' : width + 'px'
                    let height = $(this).attr('height')
                    height = (!height) ? '80%' : height + 'px'
                    let maxWidth = $(this).attr('maxwidth')
                    maxWidth = (!maxWidth) ? '1000px' : maxWidth + 'px'
                    let buttonUrl = $(this).attr('href')
                    if (jQuery(this).hasClass('fancybox-set') === false) {
                        jQuery(this).attr('href', buttonUrl)
                        jQuery(this).addClass('fancybox-set')
                        jQuery(this).fancybox({
                            type: 'iframe',
                            width: width,
                            height: height,
                            'max-width': maxWidth,
                            slideShow: false,
                            href: buttonUrl,
                            autoDimensions: false,
                            scrolling: 'auto',
                            autoScale: true,
                            closeBtn: true,
                            helpers: {
                                overlay: {
                                    locked: true,
                                    closeClick: false
                                }
                            },
                            beforeLoad: function () {
                                $('body').css({ 'overflow-y': 'hidden' })
                            },
                            afterClose: function () {
                                $('body').css({ 'overflow-y': 'visible' })
                            }
                        }).click()
                        return false
                    }
                }
            })

            window.addEventListener('mousemove', this.setLastActivityTime)
            window.addEventListener('mousedown', this.setLastActivityTime)
            window.addEventListener('keypress', this.setLastActivityTime)
            window.addEventListener('ontouchstart', this.setLastActivityTime)
            window.addEventListener('onclick', this.setLastActivityTime)
            window.addEventListener('scroll', this.setLastActivityTime)

            if (this.loadChecklistItems) {
                await this.getProjectChecklist()
            }
        },
    }
</script>

<style src="@/styles/style-main.css"></style>
<style src="@/styles/colors-variables.css"></style>
<style src="@/styles/additional.css"></style>
<style src="../../public/assets/css/icons/iconly.min.css"></style>

<style lang="scss">
    @import "../styles/style.scss";

    .io-page-header-holder {
        position: sticky;
        left: 225px;
        z-index: 3;
        top: 0;
        width: calc(100% - var(--left-menu-width));

        .io-left-menu-wider & {
            left: 248px;
        }
    }

    .io-main-wrapper.io-dashboard-statistics {
        padding-top: 0!important;
    }

    .io-main-wrapper.io-invoices-listing {
        padding-top: 0!important;
    }

    .io-main-wrapper.io-payapps {
        padding-top: 0!important;
    }

    .io-main-wrapper.io-approvals-module {
        padding-top: 0!important;
    }

    .io-main-wrapper {
        &.io-project-documents-list,
        &.io-documents-list {
            .loader-circles {
                position: fixed;
            }
        }
    }
    .va-popup {
        right: 80px !important;
        z-index: 10 !important;
        .va-btn {
            opacity: .7;
            &:hover {
                opacity: 1;
            }
        }

    }
</style>
