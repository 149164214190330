const AgaveAuthroization = () => import(/* webpackChunkName: "AgaveAuthroization" */ '@/io-modules/agave-auth/components/AgaveAuthorization.vue')
export default [
    {
        path: '/integration-auth/:sourceSystem/:linkToken/:v2?',
        name: 'agave-auth',
        component: AgaveAuthroization,
        meta: {
            skipAuth: true
        }
    },
]
