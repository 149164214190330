<template>
    <ul class="io-design-system io-color-picker">
        <li
            v-for="color of colors"
            :key="color"
            class="io-color-picker__item"
            :class="{ 'io-selected': modelValue === color }"
            @click="$emit('update:modelValue', color)"
        >
            <div :style="{ backgroundColor: color }" />
        </li>
    </ul>
</template>

<script lang="ts" src="./ColorPicker.ts"></script>
<style scoped lang="scss" src="./ColorPicker.scss"></style>
