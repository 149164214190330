import defaultState from './state'
import { cloneDeep, clone, findIndex } from 'lodash'

export function RESET_STATE (state) {
    const budgetType = state.budgetType
    const budgetIconTitleColumn = state.budgetIconTitleColumn

    Object.assign(state, cloneDeep(defaultState))

    // cannot change value of budgetType
    state.budgetType = budgetType
    state.budgetIconTitleColumn = budgetIconTitleColumn
}

export function SET_PROJECT (state, value) {
    state.projectObj = value
}

export function SET_HISTORY (state, value) {
    state.history = value
}

export function SET_PROJECT_MONGO (state, value) {
    state.projectMongoLocal = value
}

export function SET_PROJECT_MONGO_BUDGET (state, value) {
    state.projectMongoLocal.budget = value
}

export function SET_COMPANY_ID (state, companyId) {
    state.companyId = companyId
}

export function SET_COMPANY_SHARED_ID (state, companyId) {
    state.companySharedId = companyId
}

export function SET_PROJECT_MONGO_LOCAL_ID (state, projectId) {
    state.projectMongoLocalId = projectId
}

export function SET_SHARED_BUDGETS (state, value) {
    state.sharedBudgets = value
}

export function SET_VIEW_ONLY (state, value) {
    state.view_only = value
}

export function SET_BASIC (state, value) {
    state.basic = value
}

export function SET_SETTINGS (state, value) {
    state.settings = value
}

export function SET_TOTALS (state, value) {
    state['totals'] = value
}

export function SET_PROPOSAL (state, value) {
    state.proposalObj = value
}

export function SET_COST_CODES_PROJECT (state, value) {
    state.costCodesProject = value || []
}

export function SET_ITEMS (state, value) {
    let copyItems = clone(state.items)
    state.items = []

    for (let x in value) {
        // Preserve current expanded status
        let indexCat = findIndex(copyItems,
            (category) => { return category.id === value[x].id })
        if (indexCat > -1 && copyItems[indexCat].expanded) {
            value[x].expanded = true
            value[x].elements.forEach((element) => {
                let indexItem = findIndex(copyItems[indexCat].elements,
                    (code) => { return code.code === element.code })
                if (indexItem > -1 &&
                    copyItems[indexCat].elements[indexItem].expanded) {
                    element.expanded = true
                }
            })
        }
        state.items.push(value[x])
    }
}

export function SET_CODES (state, value) {
    state.codes = value || {}
}

export function SET_UNITS (state, value) {
    state.units = value || []
}

export function SET_CHANGE_REASON_CATEGORIES (state, value) {
    state.change_reason_categories = value || {}
}

export function SET_PROJECT_COST_CODES_LIST (state, value) {
    state.project_cost_codes_list = value || null
}

export function SET_REP_PROJECT_COST_CODES_LIST (state, value) {
    state.rep_project_cost_codes_list = value || null
}

export function SET_SHARED_PROJECT_COST_CODES_LIST (state, value) {
    state.shared_project_cost_codes_lists = value || null
}

export function SET_LOADED (state, value) {
    state.loaded = value
}

export function SET_CHANGES_LOADED (state, value) {
    state.changesLoaded = value
}

export function SET_ACTIVE_COLUMNS (state, value) {
    state.activeColumns = value || []
}

export function SET_CHANGES (state, value) {
    state.changes = value || []
}

export function SET_CHANGES_PARSED_IDS (state, value) {
    state.changesParsedIds = (value || []).map((item) => {
        return item._id
    })
}

export function SET_CURRENT_CHANGE (state, value) {
    state.currentChange = value
}

export function SET_CHANGE_STATUSES (state, value) {
    state.changeStatuses = value || []
}

export function SET_CHANGES_FILTERS (state, value) {
    state.changesFilters = value
}

export function SET_IS_SERVICE_ACCOUNT (state, value) {
    state.is_service_account = value || null
}

export function SET_CAN_EDIT_SPECIAL_FUNCTIONS (state) {
    state.can_edit_special_functions = Boolean(
        state.is_service_account && !state.view_only)
}

// Budget configuration
export function SET_LAYOUTS (state, value) {
    state.layouts = value || []
}

export function SET_PROJECT_COST_CODES_LISTS (state, value) {
    state.project_cost_codes_lists = value || []
}

export function SET_PROJECT_COST_CODES_LISTS_USED (state, value) {
    state.project_cost_codes_lists_used = value || []
}

export function SET_ANALYTIC_COST_CODES (state, value) {
    state.analytic_cost_codes = value || []
}

export function SET_ITEM_VENDOR_LIST (state, items) {
    state.itemVendorList = items
}

export function SET_CLIENT_CODES (state, value) {
    state.clientCodes = value || []
}

export function SET_COST_CODES (state, value) {
    state.costCodes = value || []
}

export function SET_EXTERNAL_ACCOUNTING_COST_CODES (state, value) {
    state.external_accounting_cost_codes = value || []
}

export function SET_EXTERNAL_ACCOUNTING_COST_CODE_CATEGORIES (state, value) {
    state.external_accounting_cost__code_categories_with_codes = value || []
}

export function SET_INTEGRATIONS (state, value) {
    state.integrations = value || []
}

export function SET_COST_CODES_HAVE_ACCOUNTING_CODES (state, value) {
    state.allCostCodesHaveAccountingCodes = value
}

export function SET_BUDGET_COST_CODE_TEMPLATE_ID (state, value) {
    state.budgetCostCodeTemplateId = value
}

export function SET_LOADED_CONFIGURATION (state, value) {
    state.loadedConfiguration = value || []
}

export function SET_LOADED_LISTS (state, value) {
    state.loadedLists = value || []
}

export function SAVE_LAYOUT (state, value) {
    let index = findIndex(state.layouts,
        function (o) { return o._id === value._id })
    if (index > -1) {
        state.layouts[index] = value
    } else {
        state.layouts.push(value)
    }
}

export function SET_MODAL_ANTICIPATED (state, value) {
    state.modalAnticipated.item = value.item || null
    state.modalAnticipated.type = value.type || null
}

export function SET_BUDGET_LINE_ITEM_LISTS (state, value) {
    state['budget_line_item_lists'] = value
}

export function SET_QUOTED_ITEM_LINES_LISTS (state, value) {
    state['quoted_item_lines_lists'] = value
}

export function SET_CUSTOM_FIELDS_LISTS (state, value) {
    state['custom_fields_lists'] = value
}

export function SET_MEETING_MINUTES_LISTS (state, value) {
    state['meeting_minutes_lists'] = value
}

export function SET_MEETING_MINUTES_TEMPLATES (state, value) {
    state['meeting_minutes_templates'] = value
}

export function SET_CURRENCY_TEMPLATES (state, value) {
    state['conversionsTemplates'] = value
}

export function SET_CURRENCY_BASE (state, value) {
    state['currencyBase'] = value
}

export function SET_CURRENCY_SELECTED_RATES (state, value) {
    state['currencySelectedRates'] = value
}

export function ITEM_EXPAND_TOGGLE (state, itemId) {
    const index = state.expandedItems.findIndex(item => item === itemId)
    if (index > -1) {
        state.expandedItems.splice(index, 1)
    } else {
        state.expandedItems.push(itemId)
    }
}

export function ITEM_ADD_TO_EXPANDED (state, itemId) {
    if (!state.expandedItems.includes(itemId)) {
        state.expandedItems.push(itemId)
    }
}

export function EXPANDED_ITEMS_RESET (state, value) {
    state['expandedItems'] = []
}

export function DELETE_CURRENCY_CONVERSION (state, deletedRateId) {
    state.currencySelectedRates = state.currencySelectedRates.filter(
        rateId => rateId !== deletedRateId
    )
    if (state.currencySelectedConversion && state.currencySelectedConversion.id === deletedRateId) {
        state['currencySelectedConversion'] = null
    }
}

export function SET_CURRENCY_SELECTED_CONVERSION (state, value) {
    state['currencySelectedConversion'] = value
}

export function SET_CURRENCY_TEMPLATE (state, value) {
    let currencyTemplateIndex = state.conversionsTemplates.findIndex(
        x => x.id === value.id)
    if (currencyTemplateIndex > -1) {
        let currencyTemplate = state.conversionsTemplates[currencyTemplateIndex]
        currencyTemplate.template_name = value.template_name
        currencyTemplate.last_updated.date = value.updated_at
        currencyTemplate.last_updated.author_name = value.updater_user_name
        state.conversionsTemplates[currencyTemplateIndex] = currencyTemplate
    }
}

export function SET_CURRENCIES (state, value) {
    state['currencies'] = value
}

export function SET_ATTACHED_FUNDING_SOURCES (state, value) {
    state['attachedFundingSources'] = value
}

export function SET_TASK_WORKFLOWS_LISTS (state, value) {
    state['task_workflows_lists'] = value
}

export function SET_DICTIONARIES (state, value) {
    state['dictionaries'] = value || []
}

export function SET_QUOTED_ITEM_LINES_LISTS_SETTINGS (state, value) {
    state['quoted_item_lines_lists_settings'] = value
}

export function SET_QUOTED_ITEM_LINES_TEMPLATES (state, value) {
    state['quoted_item_lines_templates'] = value
}

export function SET_RFP_TO_TYPES (state, value) {
    state['rfp_to_types'] = value
}

export function SET_BID_QUESTIONS_LISTS (state, value) {
    state['bid_questions_lists'] = value
}
export function SET_MEETING_TEMPLATES_COUNT (state, value) {
    state['meeting_templates_count'] = value
}

export function SET_INSPECTION_TEMPLATES_COUNT (state, value) {
    state['inspections_templates_count'] = value
}

export function SET_PUNCH_STAMPS_TEMPLATES_COUNT (state, value) {
    state.punch_stamps_templates_count = value
}

export function SET_PROJECT_SCHEDULE_MILESTONES_LISTS_COUNT (state, value) {
    state.project_schedules_milestones_lists_count = value
}

export function SET_LETTER_OF_INTENT_TEMPLATES (state, value) {
    state.letter_of_intent_templates = value || []
}

export function SET_APPROVAL_WORKFLOWS_TEMPLATES (state, value) {
    state.approval_workflow_templates = value || []
}

export function SET_LIEN_WAIVER_TEMPLATES (state, value) {
    state.lien_waiver_templates = value || []
}

export function SET_BUDGET_TYPE (state, value) {
    state.budgetType = value
}

export function SET_BUDGET_ICON_TITLE_COLUMN (state, value) {
    state.budgetIconTitleColumn.title = value.title || null
    state.budgetIconTitleColumn.logo = value.logo || null
}

export function SET_BUDGET_TYPE_SHARED_SELECTED (state, value) {
    state.budgetTypeSharedSelected = value
}

export function SET_SHARED_PHASE (state, value) {
    state.sharedPhase = value
}

export function SET_IS_SINGLE_MASTER_BUDGET_MODE (state, value) {
    state.isSingleMasterBudgetMode = value
}

export function SET_CONTRACT_DOCUMENT_TEMPLATES (state, value) {
    state.contract_document_templates = value
}

export function SET_ACCOUNTING_ENTITIES_COUNT (state, value) {
    state.accounting_entities_count = value
}

export function SET_ACCOUNTING_COST_CODES_COUNT (state, value) {
    state.accounting_cost_codes_count = value
}

export function SET_ACCOUNTING_VENDORS_COUNT (state, value) {
    state.accounting_vendors_count = value
}

export function SET_INTEGRATION_SYNC_HISTORY_COUNT (state, value) {
    state.integration_sync_history_count = value
}

/**
 * Insurance Requirements
 */
export function SET_INSURANCE_REQUIREMENTS_TEMPLATES (state, value) {
    state.insuranceRequirementsTemplates = value
}

export function REMOVE_INSURANCE_REQUIREMENTS_TEMPLATE (state, value) {
    const index = state.insuranceRequirementsTemplates.findIndex(template => template.id === value.id)

    if (index > -1) {
        state.insuranceRequirementsTemplates.splice(index, 1)
    }
}

export function UPDATE_INSURANCE_REQUIREMENTS_TEMPLATE (state, value) {
    const index = state.insuranceRequirementsTemplates.findIndex(template => template.id === value.id)
    if (index > -1) {
        let insuranceRequirementsTemplate = state.insuranceRequirementsTemplates[index]
        insuranceRequirementsTemplate.type = value.type
        insuranceRequirementsTemplate.name = value.name
        insuranceRequirementsTemplate.description = value.description
        insuranceRequirementsTemplate.is_archived = value.is_archived
        insuranceRequirementsTemplate.last_updated = value.last_updated
        state.insuranceRequirementsTemplates[index] = insuranceRequirementsTemplate
    }
}

export function SET_SHOW_REJECTED_BUDGET_PHASES (state, value) {
    state.showRejectedBudgetPhases = value
}

export function SET_PHASES_VIEW_TYPE (state, type) {
    state.phasesViewType = type
}

export function SET_BUDGET_CATEGORIES_COLUMNS (state, value) {
    state.budgetCategoryColumns = value || []
}

export function SET_BUDGET_SUBCATEGORIES_COLUMNS (state, value) {
    state.budgetSubcategoryColumns = value || []
}

export function SET_BUDGET_COLUMNS (state, value) {
    state.budgetColumns = value || []
}

export function SET_SHARED_BUDGET_CODE_TEMPLATE (state, value) {
    state.sharedBudgetCodeTemplate = value || []
}

/**
 * Task Templates
 */
export function SET_COMPANY_TASK_TEMPLATES_COUNT (state, value) {
    state.company_task_templates_count = value
}

export function SET_COMPANY_TASK_TEMPLATE_LISTS_COUNT (state, value) {
    state.company_task_template_lists_count = value
}

export function SET_APPROVED_FROM_CLIENT (state, value) {
    state.approved_from_client = value
}

export function SET_CURRENT_CHANGE_ACCOUNTING_MESSAGES (state, value) {
    state.currentChangeAccountingMessages = value
}

export function SET_CURRENT_CHANGE_CAN_BE_EXPORTED_TO_ACCOUNTING (state, value) {
    state.currentChangeCanBeExportedToAccounting = value
}
