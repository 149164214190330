import { defineComponent, PropType } from 'vue'
import IOInput from '@/components/atoms/IOInput/IOInput.vue'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import { MultiselectOptionsInterface } from '@/interfaces/components/custom-multiselect/MultiselectOptionsInterface'

export default defineComponent({
    name: 'DoubleInput',
    components: { CustomMultiselect, IOInput },
    props: {
        dropdownOptions: {
            type: Array as PropType<Record<'name' | 'value', string>[]>,
        },
        defaultSelectedItem: {
            type: Object as PropType<Record<'name' | 'value', string>>,
        },
        initialInputValue: {
            type: [String, Number],
            default: null,
        },
        multiselectOptions: {
            type: Object as PropType<MultiselectOptionsInterface>,
        },
    },
    emits: ['rightValue', 'leftValue'],
    data () {
        return {
            selected: null,
            inputValue: null,
        }
    },
    mounted () {
        this.selected = this.defaultSelectedItem
        this.inputValue = this.initialInputValue
        this.emitRightValue(this.selected)
    },
    methods: {
        emitRightValue (value: any): void {
            this.selected = value
            this.$emit('rightValue', value)
        },
        emitLeftValue (value: string): void {
            this.$emit('leftValue', value)
        },
    },
})
