import Echo from 'laravel-echo'
// import socketio from 'socket.io-client'
import Pusher from 'pusher-js'
import { getSoketiDomain } from '@/helpers/urls'

// dirty but https://docs.soketi.app/getting-started/client-configuration/laravel-echo
// @ts-ignore
window.Pusher = Pusher

const subdomain = (window.location.host).split('.')[0]
const apptype = localStorage.getItem('appType')

const options = window.location.hash === '#socketio' ? {
    namespace: 'App.Modules',
    broadcaster: 'socket.io',
    host: 'location.hostname',
    path: '/server/broadcast',
    // client: socketio,
    transports: ['websocket', 'polling'],
    auth: {
        headers: {
            subdomain: subdomain,
            apptype: apptype
        }
    },
    query: {
    },
    transportOptions: {
        polling: {
            extraHeaders: {
                subdomain: subdomain,
                apptype: apptype
            }
        }
    }
} : {
    namespace: 'App.Modules',
    broadcaster: 'pusher',
    authEndpoint: '/api/broadcasting/auth',
    cluster: 'cluster',
    key: 'api',
    wsHost: getSoketiDomain(),
    wsPort: 80,
    wssPort: 443,
    forceTLS: location.protocol === 'https:',
    encrypted: true,
    disableStats: true,
    enabledTransports: location.protocol === 'https:' ? ['wss', 'ws'] : ['ws'],
    auth: {
        headers: {
            subdomain: subdomain,
            apptype: apptype
        }
    },
}

const echo = new Echo(options)

if (options.broadcaster === 'socket.io') {
    //failover to lp - soketi we only have ws / wss
    echo.connector.socket.on('reconnect_attempt', attempt => {
        console.warn('Echo.reconnect_attempt %i', attempt)
        if (attempt > 3) {
            console.warn('Echo.fallback to polling %i', attempt, options)
            echo.connector.options.transports = ['polling', 'websocket']
        }
    })
}

export default echo
