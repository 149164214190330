<template>
    <div class="io-file-row io-f-row" >
        <div class="io-file-left io-f-row">
            <div class="io-img-holder io-f-column">
                <img :src="$parent.getFilePathThumb(file)" alt=""/>
            </div>
            <p>{{ file.f_name }}</p>
        </div>
        <div class="io-file-right io-f-row">
            <a class="io-file-more io-f-column" @click="toggleMenu">
                <img src="/new-theme/img/more-dots.svg" alt=""/>
            </a>
            <div v-if="showMenu" class="io-todo-submenu" :class="{'io-active': showMenu}">
                <div class="io-f-row io-more">{{ $t('More') }} <img src="/new-theme/img/more-dots.svg" @click="closeMenu"/></div>

                <div class="io-f-row io-edit"
                     data-fancybox="details-attachments"
                     :data-type="file.f_extension === 'pdf' ? 'iframe' : ''"
                     :href="$parent.getFilePathPreview(file)" @click="closeMenu">
                    <i class="far fa-eye"></i>
                    {{ $t('Preview') }}
                </div>
                <div class="io-f-row io-edit" @click="$emit('download-item', file), closeMenu">
                    <i class="fas fa-download"></i>
                    {{ $t('Download') }}
                </div>
                <div class="io-f-row io-delete" @click="$emit('remove-item', file.f_id)">
                    <i class="far fa-trash-alt"></i>
                    {{ $t('Delete') }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'file'
        ],
        data () {
            return {
                showMenu: false
            }
        },
        methods: {
            toggleMenu () {
                this.showMenu = !this.showMenu
            },
            closeMenu: function () {
                this.showMenu = false
            }
        }
    }
</script>
