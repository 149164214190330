<template>
    <div
        v-if="status === ApprovalStatus.APPROVED"
        class="io-single-approval-status io-single-approval-status--approved"
    >
        <i class="icon-check-circle-2-2"></i>
        <div class="io-single-approval-status__holder">
            <p>{{ approvedAtText }}</p>
            <span>{{ $filters.parseDateTime(date) }}</span>
        </div>
    </div>
    <div
        v-else-if="status === ApprovalStatus.REJECTED"
        class="io-single-approval-status io-single-approval-status--rejected"
    >
        <i class="icon-cross-circle-2"></i>
        <div class="io-single-approval-status__holder">
            <p>{{ $tc('status_rejected') }}</p>
            <span>{{ $filters.parseDateTime(date) }}</span>
        </div>
    </div>
</template>

<script lang="ts" src="./SingleApprovalStatus.ts"></script>
<style lang="scss" src="./SingleApprovalStatus.scss" scoped></style>
