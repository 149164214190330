<template>
    <div v-if="!disabled" class="io-dismissible-banner">
        <div class="io-dismissible-banner__header">
            <span class="io-dismissible-banner__header-icon" :class="`icon-${ headerIcon }`"/>
            <span class="io-dismissible-banner__header-title">
                {{ header }}
            </span>
            <div v-if="!showText" class="io-dismissible-banner__text-piece">
                <p>
                    {{ text[0] }}
                </p>
                <IOButton
                    variant="link"
                    size="small"
                    @click="toggleText(true)"
                >
                    {{ $t('Learn more') }}
                </IOButton>
            </div>
        </div>
        <div v-if="showText" class="io-dismissible-banner__body">
            <p v-for="(string, key) in text" :key="key">
                {{ $t(string) }}
            </p>
        </div>
        <div v-if="showText" class="io-dismissible-banner__actions">
            <Checkbox
                :labelText="$t('Don\'t show this again in this session')"
                v-model="makeDismissible"
            />
            <IOButton
                variant="link"
                size="small"
                font-weight="bold"
                @click="toggleText(false)"
            >
                {{ $t('Got it!') }}
            </IOButton>
        </div>
    </div>
</template>

<script lang="ts" src="./DismissibleBanner.ts"></script>
<style lang="scss" src="./DismissibleBanner.scss" scoped></style>
