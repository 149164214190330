import { defineComponent } from 'vue'
import { mapStores } from 'pinia'
import { jobsiteLocationsStore } from '@/components/jobsite-locations/store/jobsiteLocationsStore.ts'
import { JobsiteLocationsInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsInterface.ts'
import { JobsiteLocationsTreeInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsTreeInterface.ts'
import {
    buildSelectedLocationsGroupTree,
} from '@/components/jobsite-locations/helpers'
import JobsiteLocationStatusEnum from '@/components/jobsite-locations/enums/JobsiteLocationStatusEnum.ts'

export default defineComponent({
    name: 'SelectedJobsiteLocations',
    props: {
        isEditable: { type: Boolean, default: true },
        isSkinnyMode: { type: Boolean, default: true },
        isModalView: { type: Boolean, default: false },
        selectedPaths: { type: Array as () => [JobsiteLocationsInterface[]], default: [] }
    },
    emits: ['update-selected-list'],
    data () {
        return {
            collapsedRowsIds: [],
            JobsiteLocationStatusEnum,
        }
    },
    computed: {
        ...mapStores(jobsiteLocationsStore),

        locationsGroupTree (): JobsiteLocationsTreeInterface[] {
            return buildSelectedLocationsGroupTree(this.selectedPaths)
        },
    },
    mounted (): void {
        if (this.isSkinnyMode) {
            this.collapseRows()
        }
    },
    methods: {
        collapseRows (): void {
            this.collapsedRowsIds = []

            this.locationsGroupTree.forEach((location: JobsiteLocationsTreeInterface) =>  {
                this.collapsedRowsIds.push(location.id)
            })
        },
        toggleJobsiteLocations (id: string): void {
            if (this.collapsedRowsIds.includes(id)) {
                this.collapsedRowsIds = this.collapsedRowsIds.filter((rowId: string) => rowId !== id )
            } else {
                this.collapsedRowsIds.push(id)
            }
        },
        removeJobsiteLocation (id: string): void {
            this.jobsiteLocationsStore.removeSelectedLocationId(id)

            const updatedData = this.selectedPaths.filter((path: JobsiteLocationsInterface[]) => !path.some((location: JobsiteLocationsInterface) => location.id === id))

            this.$emit('update-selected-list', updatedData)
        },
        removeAllJobsiteLocations (location: JobsiteLocationsTreeInterface): void {
            this.jobsiteLocationsStore.removeSelectedLocationId(location.id)

            location.children?.forEach((treeList: JobsiteLocationsInterface[]) => {
                treeList?.forEach((child: JobsiteLocationsInterface) => {
                    this.jobsiteLocationsStore.removeSelectedLocationId(child.id)
                })
            })

            const updatedData = this.selectedPaths.filter((path: JobsiteLocationsInterface[]) =>  path[0].id !== location.id)

            this.$emit('update-selected-list', updatedData)
        }
    },
})
