<template>
    <div class="io-contact-radio" data-testid="contact-radio" @click="onClick">
        <div class="io-radio-container" :class="{ 'io-selected': isSelected }">
            <div class="io-radio-form">
                <input
                    class="io-radio-input"
                    :disabled="disabled"
                    :name="inputName"
                    type="radio"
                    :value="radioValue"
                    v-model="currentValue"
                />
                <i class="io-radio-icon" :class="{ 'io-radio-icon-selected': isSelected }, iconClass"></i>
            </div>

            <span class="io-label-container">
                <span class="io-radio-label">{{ labelText }}</span>
                <span class="io-inner-label">{{ description }}</span>
            </span>

        </div>
    </div>
</template>

<script lang="ts" src="./ContactRadio.ts"></script>
<style lang="scss" src="./ContactRadio.scss" scoped></style>
