import { defineComponent } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import FilesComponent from '@/components/files-new/files.vue'

export default defineComponent({
    name: 'AttachFileModal',
    components: { IOModal, FilesComponent },
    props: {
        rowId: {
            type: String
        },
    },
    data () {
        return {
            filesIds: [] as string[],
            showUploader: false,
        }
    },
    methods: {
        toggleUploader (): void {
            this.showUploader = !this.showUploader
        },
        onFileAdd (file: any): void {
            this.filesIds.push(file._id)
        },
        onFileRemove (fileId: string): void {
            this.filesIds = this.filesIds.filter((id: string) => id !== fileId)
        },
        sendFilesIds (): void {
            this.$emit('files', this.filesIds)
            this.toggleUploader()
        }
    }
})
