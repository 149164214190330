import { defineComponent } from 'vue'
import Mentions from '@/components/mentions/mentions.vue'

export default defineComponent({
    components: {
        Mentions
    },
    data () {
        return {
            shared_with: []
        }
    },
    methods: {
        onUpdateReply (element: HTMLDivElement, targetArray: string = 'shared_with'): void {
            const nodeList = element.querySelectorAll('.io-mention')
            let idsArray: string[] = []
            nodeList.forEach((mention: Element) => {
                idsArray.push(mention.getAttribute('data-id'))
            })
            this[targetArray] = idsArray
        }
    }
})
