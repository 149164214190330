import { defineComponent } from 'vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import { appPackageViewStore } from '../../../../stores/appPackageView'
import RequestedDataErrorPlaceholder from '@/io-modules/invoices/components/requested-data-error-placeholder/RequestedDataErrorPlaceholder.vue'

export default defineComponent ({
    name: 'FinancialPanel',
    components: {
        IOSingleInfoRow,
        RequestedDataErrorPlaceholder,
    },
    data () {
        return {
            loading: false,
            error: false,
        }
    },
    computed: {
        ...mapStateVuex('project', ['projectObj']),

        ...mapState(appPackageViewStore, ['appPackageFinancialInfo']),

        projectTotal (): string {
            const value = this.appPackageFinancialInfo?.contract_value || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        includedInPackage (): string {
            const value = this.appPackageFinancialInfo?.included_in_package || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        grossTotal (): string {
            const value = this.appPackageFinancialInfo?.gross_billed_to_date || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        netTotal (): string {
            const value = this.appPackageFinancialInfo?.net_billed_to_date || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        paidToDatePercent (): string {
            const value = this.appPackageFinancialInfo?.paid_to_date?.percent || 0
            return this.$filters.formatPercentInt(value)
        },

        paidToDateAmount (): string {
            const value = this.appPackageFinancialInfo?.paid_to_date?.amount
            return this.$filters.formatProjectCurrencyInt(value)
        },
    },

    mounted () {
        this.fetchData()
    },

    methods: {
        ...mapActions(appPackageViewStore, ['fetchAppPackageFinancialInfo']),

        async fetchData (): Promise<void> {
            try {
                this.loading = true
                await this.fetchAppPackageFinancialInfo(this.projectObj.project_local_id, this.$route.params.id)
                this.loading = false
                this.error = false
            } catch (e) {
                this.error = true
            } finally {
                this.loading = false
            }
        },
    },
})
