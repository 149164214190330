import { defineComponent, PropType } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import dayjs from 'dayjs'
import { LabelType } from '@/constants/LabelConstants'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import dateTimeHelper from '@/helpers/dateTime'
import IOTextarea from '@/components/atoms/IOTextarea/IOTextarea.vue'
import FilesComponent from '@/components/files-new/files.vue'
import Resource from '@/io-modules/approval-workflows/enums/Resource'
import ApprovalsClient from '@/io-modules/approval-workflows/api-clients/ApprovalsClient'

export default defineComponent({
    name: 'RecordClientApprovalModal',
    components: {
        IOModal,
        FieldDateTime,
        IOTextarea,
        FilesComponent,
    },
    props: {
        approvableResourceId: String,
        resourceName: {
            type: String as PropType<Resource>,
            default: '',
        },
        hideFiles: {
            type: Boolean,
            required: false,
            default: false,
        },
        zIndex: {
            type: Number,
            required: false,
            default: 10,
        },
    },
    emits: ['close', 'clientApproved'],
    data () {
        return {
            approvedBy: '',
            date: dayjs(new Date()).format('YYYY-MM-DD'),
            confirmInput: '',
            LabelType,
            datepickerOptions: {
                'only-date': true,
                noClearButton: true,
                format: 'YYYY-MM-DD',
                formatted: dateTimeHelper.getDateFormat(),
            },
            filesIds: [],
            note: '',
            validationOptions: {
                allowedExtensions: ['pdf', 'doc', 'docx', 'txt'],
                acceptFiles: ['.pdf', '.doc', '.docx', '.txt'],
            }
        }
    },
    computed: {
        isValid (): boolean {
            return 'CONFIRM' === this.confirmInput && !!this.approvedBy.length
        },

        filesArrayId (): string {
            return `client_approval_${ this.approvableResourceId }`
        },
    },
    beforeMount (): void {
        this.$store.dispatch('filesComponent/setMyDocs', {
            arrayId: this.filesArrayId,
            files: []
        })
    },
    methods: {
        async recordClientApproval (): Promise<void> {
            this.setLoadingBar(true)

            try {
                await ApprovalsClient.recordClientApproval(this.approvableResourceId, {
                    approvable_resource_id: this.approvableResourceId,
                    approved_by: this.approvedBy,
                    approved_at: this.date,
                    note: this.note,
                    document_ids: this.filesIds,
                })

                this.$emit('clientApproved')
                this.$emit('close')
            } catch (error) {
                this.errorHandle(error)
            } finally {
                this.setLoadingBar(false)
            }
        },

        onFileAdd (file: any): void {
            this.filesIds.push(file._id)
        },

        onFileRemove (fileId: string): void {
            this.filesIds = this.filesIds.filter((id: string) => id !== fileId)
        },
    },
})
