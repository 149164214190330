import { defineComponent } from 'vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    props: {
        data: { type: Object as () => any, required: true, default: null },
        column: { type: Object as () => ColumnInterface, required: true, default: null },
        columnClass: { type: String, default: null },
        isLastRow: { type: Boolean }
    },
    methods: {
        imgSrc () {
            const field = this.column.field

            if (!this.data[field]) {
                return false
            }

            // @ts-ignore
            return this.data[field]
        }
    }
})
