export enum AppPackageAvailableActions {
    MarkAsPaid = 'mark_as_paid',
    SaveAsDraft = 'save_as_draft',
    Approve = 'approve',
    RevertToDraft = 'revert_to_draft',
    RevertToPending = 'revert_to_pending',
    SubmitToClient = 'submit_to_client',
    RecordClientApproval = 'record_client_approval',
    Reject = 'reject',
    Delete = 'delete',
    Edit = 'edit',
    SubmitToAccounting = 'submit_to_accounting',
    MarkAsSubmittedToClient = 'mark_as_submitted_to_client',
}

export default AppPackageAvailableActions
