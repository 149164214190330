<template>
    <PopupModal
        v-if="showPopup"
        :width="popup.width"
        :zIndex="zIndex"
        :customClass="`io-popup-alert ${popup.customClass}`"
        :fullScreen="popup.fullScreen"
        :show-loader="loader"
        @close="handleClose"
    >
        <template #header>
            <div v-if="popup.icon" :class="popup.iconClass" :style="{ color: popup.iconColor }" class="io-popup-modal__icon"><i :class="popup.icon"></i></div>
            <div v-if="popup.header" class="io-popup-modal__title">{{ popup.header }}</div>
            <div class="io-popup-modal__close" @click="closePopup"><i class="icon-cross"/></div>
        </template>
        <div class="io-popup-alert__content">
            <div class="io-popup-alert__title">{{ popup.title }}</div>
            <div v-if="!popup.captionHTML" class="io-popup-alert__caption">{{ popup.caption }}</div>
            <div v-else class="io-popup-alert__caption" v-html="popup.caption"></div>
            <div v-if="popup.alert" class="io-popup-alert__alert-box">
                <AlertBox
                    :icon="popup.alert.icon"
                    :color="popup.alert.color"
                    :dismissible="false"
                >
                    {{ popup.alert.content }}
                    <IOButton v-if="popup.alert.buttonText" variant="link" size="small" @click="handleAlertButtonClick(popup.alert)">{{ popup.alert.buttonText }}</IOButton>
                </AlertBox>
            </div>
            <div v-if="popup.radioOptions" class="io-popup-alert__radio">
                <template v-for="(option, index) in Object.keys(popup.radioOptions)" :key="index">
                    <div class="item">
                        <input
                            :id="`popup-radio-${ index }`"
                            name="popup-radio"
                            type="radio"
                            :value="option"
                            v-model="selectedRadio"
                        />
                        <label :for="`popup-radio-${ index }`">{{ popup.radioOptions[option] }}</label>
                    </div>
                </template>
            </div>
            <div v-if="popup.checkBox" class="io-popup-alert__checkbox">
                <div class="item">
                    <span
                        v-if="checkBoxErrorVisible"
                        class="io-popup-alert__checkbox-error"
                    >
                        {{ $tc('You need to mark the confirmation.') }}
                    </span>
                    <div>
                        <input
                            id="popup-checkbox"
                            name="popup-checkbox"
                            type="checkbox"
                            v-model="checkBoxChecked"
                        />
                        <label for="popup-checkbox">{{ popup.checkBox}}</label>
                    </div>
                </div>
            </div>
            <div v-if="popup.input" class="io-popup-alert__input">
                <div class="item">
                    <span
                        v-if="inputErrorVisible"
                        class="io-popup-alert__input-error"
                    >
                        {{ $tc('This field is required.') }}
                    </span>
                    <div>
                        <template v-if="'textarea' === popup.inputType">
                            <FormInputColumn
                                :label="popup.input"
                                :custom-content="true"
                                :required="popup.inputRequired"
                            >
                                <textarea
                                    id="popup-input"
                                    name="popup-input"
                                    v-model="inputValue"
                                />
                            </FormInputColumn>
                        </template>
                        <template v-else>
                            <label for="popup-input">{{ popup.input }}</label>
                            <input
                                id="popup-input"
                                name="popup-input"
                                :type="popup.inputType"
                                v-model="inputValue"
                            />
                        </template>
                    </div>
                </div>
            </div>
            <div v-if="popup.sessionKey" class="io-popup-alert__disable-in-session">
                <input type="checkbox" name="disable-in-session" v-model="disableInSession" />
                <span>{{ $tc(`Don't show this message again in this session.`) }}</span>
            </div>
        </div>
        <template #footer>
            <div
                v-for="(button, index) in popup.buttons"
                :id="button.id || null"
                :key="index"
                class="io-btn"
                :class="{
                    [get(button, 'class', 'io-btn-primary')]: true,
                    'io-btn-disabled': button.validateInput && popup.inputRequired && !inputValue,
                }"
                @click="handleButtonClick(button, index)"
            >
                <i v-if="button.icon" :class="button.icon"></i>
                {{ $t(button.text) }}
            </div>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./PopupAlert.ts"></script>
<style lang="scss" src="./PopupAlert.scss" scoped></style>
