const ProjectProvider = () => import(/* webpackChunkName: "project-provider" */ '@/layouts/project-provider/ProjectProvider.vue')

const InspectionTemplatesView = () => import(/* webpackChunkName: "lists-templates-inspections" */ '@/io-modules/inspections/views/inspection-templates/InspectionTemplatesView.vue')
const InspectionTemplatesCreateView = () => import(/* webpackChunkName: "lists-templates-inspections-create" */ '@/io-modules/inspections/views/inspection-templates-create/InspectionTemplatesCreateView.vue')
const InspectionTemplatesViewer = () => import(/* webpackChunkName: "lists-templates-inspections-view" */ '@/io-modules/inspections/views/inspection-templates-viewer/InspectionTemplatesViewer.vue')
const InspectionCreateView = () => import(/* webpackChunkName: "inspection-create" */ '@/io-modules/inspections/views/inspection-create/InspectionCreateView.vue')
const InspectionStartView = () => import(/* webpackChunkName: "inspection-start" */ '@/io-modules/inspections/views/inspection-start/InspectionStartView.vue')
const InspectionsListView = () => import(/* webpackChunkName: "inspections-list" */ '@/io-modules/inspections/views/inspections-list/InspectionsListView.vue')
const InspectionView = () => import(/* webpackChunkName: "inspection-view" */ '@/io-modules/inspections/views/inspection/InspectionView.vue')
const InspectionViewDetails = () => import(/* webpackChunkName: "inspection-view-details" */ '@/io-modules/inspections/views/inspection/details/DetailsView.vue')
const InspectionViewFiles = () => import(/* webpackChunkName: "inspection-view-files" */ '@/io-modules/inspections/views/inspection/files/FilesView.vue')

export default [
    {
        path: '/lists-templates/inspections',
        name: 'lists-templates-inspections',
        component: InspectionTemplatesView,
        meta: {
            title: 'Inspection Templates',
            navigationKey: 'inspections-new',
        },
    },
    {
        path: '/lists-templates/inspections/create',
        name: 'lists-templates-inspections-create',
        component: InspectionTemplatesCreateView,
        meta: {
            title: 'Create New Template',
            showNotificationsForRoutes: ['lists-templates-inspections'],
            navigationKey: 'inspections-new',
        },
    },
    {
        path: '/lists-templates/inspections/:id',
        name: 'lists-templates-inspections-view',
        component: InspectionTemplatesViewer,
        props: true,
    },
    {
        path: '/lists-templates/inspections/:id/edit',
        name: 'lists-templates-inspections-edit',
        component: InspectionTemplatesCreateView,
        meta: {
            title: 'Edit Inspection Template',
            navigationKey: 'inspections-new',
        },
    },
    {
        path: '/project/:pid/inspections-new',
        component: ProjectProvider,
        children: [
            {
                path: '',
                name: 'inspections-list',
                component: InspectionsListView,
                meta: {
                    title: 'Inspections',
                    navigationKey: 'inspections-new',
                },
                props: route => ({ status: route.query.status }),
                beforeEnter: (to, _, next) => {
                    const statusValid = ['pending', 'completed'].includes(to.query.status)
                    const pendingRoute = {
                        replace: true,
                        name: 'inspections-list',
                        query: { status: 'pending' },
                        params: { pid: to.params.pid },
                    }

                    return statusValid
                        ? next()
                        : next(pendingRoute)
                },
            },
            {
                path: 'create',
                name: 'inspection-create',
                component: InspectionCreateView,
                meta: {
                    title: 'Create Inspection',
                    showNotificationsForRoutes: ['inspections-list'],
                    navigationKey: 'inspections-new',
                },
            },
            {
                path: ':id/start',
                name: 'inspections-start',
                component: InspectionStartView,
                meta: {
                    title: 'Start Inspection',
                    showNotificationsForRoutes: ['inspections-list'],
                    navigationKey: 'inspections-new',
                },
            },
            {
                path: ':id',
                name: 'inspection-new-view',
                component: InspectionView,
                props: true,
                meta: {
                    navigationKey: 'inspections-new',
                },
                redirect: { name: 'inspection-view-details' },
                children: [
                    {
                        path: 'details',
                        name: 'inspection-view-details',
                        meta: {
                            title: 'Inspections',
                            navigationKey: 'inspections-new',
                            showNotificationsForRoutes: ['inspection-view-details'],
                        },
                        component: InspectionViewDetails,
                    },
                    {
                        path: 'files',
                        name: 'inspection-view-files',
                        meta: {
                            title: 'Inspections',
                            navigationKey: 'inspections-new',
                        },
                        component: InspectionViewFiles,
                    },
                ],
            },
            {
                path: 'edit/:id',
                name: 'inspection-new-edit',
                component: InspectionCreateView,
                meta: {
                    title: 'Edit Inspection',
                    navigationKey: 'inspections-new',
                },
            },
        ],
    },
]
