import { defineComponent } from 'vue'
import WebViewer from '@/components/web-viewer/WebViewer.vue'
import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'
import PinSvgGenerator from '@/io-modules/documents/mixins/PinSvgGenerator'
import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import { commentsStore } from '@/io-modules/documents/store/commentsStore'
import { mapActions, mapState } from 'pinia'
import { Core } from '@pdftron/webviewer'
import { TranslateResult } from 'vue-i18n'
import { AnnotationData } from '@/io-modules/documents/interfaces/AnnotationData'
import basicAnnotationsClient from '@/io-modules/documents/api-clients/basicAnnotationsClient'
import {
    NewBasicAnnotationPayloadInterface,
} from '@/io-modules/documents/interfaces/NewBasicAnnotationPayloadInterface'

export default defineComponent({
    name: 'DocumentFileViewer',
    extends: WebViewer,
    props: {
        documentId: {
            type: String,
            required: true,
        },
    },
    mixins: [PinSvgGenerator],
    emits: ['updateAnnotations', 'triggerAddingAnnotationEvent', 'documentLoaded'],
    data () {
        return {
            deselectEventDisabled: false,
        }
    },
    computed: {
        ...mapState(viewDocumentStore, [
            'isAnnotationBeingAdded',
            'typeOfAnnotationBeingAdded',
            'selectedAnnotationIsTemporary',
            'annotations',
            'counters',
        ]),
    },
    watch: {
        isAnnotationBeingAdded: {
            handler (newVal: boolean): void {
                if (newVal) {
                    this.startAddingAnnotation(AnnotationTypes.COMMENT)
                    return
                }
            },
        },
        loaded: {
            async handler (): Promise<void> {
                this.initializeViewerEvents()
                this.replaceContextMenuPopupOptions()
                await this.importAnnotations()
            },
        },
    },
    methods: {
        ...mapActions(viewDocumentStore, [
            'endAddingAnnotation',
            'changeCursorToDefault',
            'setSelectedAnnotationId',
            'clearSelectedAnnotationId',
            'triggerAddingAnnotationEvent',
            'showAnnotationInput',
        ]),
        ...mapActions(commentsStore, [
            'setCommentData',
        ]),
        async startAddingAnnotation (type: AnnotationTypes): Promise<void> {
            // This method generates an SVG to be set as chosen TOOL in Apryse WebViewer so that the cursor changes,
            // and you can place the stamp on the PDF canvas
            // @ts-ignore
            const stampTool = this.instance.docViewer.getTool('AnnotationCreateRubberStamp')
            const getAnnotationIconDetails = (type: AnnotationTypes): { color: string, text: string } => {
                switch (type) {
                    case AnnotationTypes.COMMENT:
                        return {
                            color: '#1952E1',
                            text: `${ this.counters?.comment + 1 || 1 }`,
                        }
                }
            }

            await this.toggleAnnotationClass(true, type)

            stampTool.setStandardStamps([this.generateSvgBlobUrl(
                getAnnotationIconDetails(type).color,
                getAnnotationIconDetails(type).text,
                type,
            )])

            // @ts-ignore
            this.instance.docViewer.setToolMode(stampTool)
            const defaultStamps = await stampTool.getStandardStampAnnotations()
            // @ts-ignore
            await stampTool.setRubberStamp(defaultStamps[0])
        },
        initializeViewerEvents (): void {
            // @ts-ignore
            const { annotationManager, documentViewer } = this.instance.Core

            // This event listener is for all changes to the pdf regarding adding/deleting/modifying annotations
            annotationManager.addEventListener('annotationChanged', async (annotations: Core.Annotations.Annotation, action: string, info: {
                imported: boolean
            }): Promise<void> => {
                let annotation = annotations[0]

                if (action === 'modify' && !info.imported) {
                    // This block is for modifying a basic annotation like line, rectangle, measurement etc.
                    const xfdf = await annotationManager.exportAnnotationCommand()
                    const annotationInternalId = this.annotations.find((item: AnnotationData) => item.uuid === annotation.Id).id
                    const postData = {
                        xfdf: xfdf,
                    }

                    await this.modifyBasicAnnotation(this.documentId, annotationInternalId, postData)
                }

                if (action === 'add' && !info?.imported) {
                    await this.toggleAnnotationClass(false)
                    this.configureAnnotation(annotation)

                    // xfdf consists of all the data needed for Annotation to be displayed properly on the PDF Canvas
                    const xfdf = await annotationManager.exportAnnotationCommand()

                    // This block is for adding a basic annotation like line, rectangle, measurement etc.
                    if (annotation.Subject === 'Native-Apryse-Markup') {
                        const postData = {
                            uuid: annotation.Id,
                            type: AnnotationTypes.BASIC.toUpperCase(),
                            xfdf: xfdf,
                            tool_name: this.mapPdftronAnnotationTool(annotation.ToolName),
                            tool_color: `rgba(${ annotation.Color?.R }, ${ annotation.Color?.G }, ${ annotation.Color?.B }, ${ annotation.Color?.A })`,
                        }

                        await this.addBasicAnnotation(this.documentId, postData)
                    }

                    // For other types of annotation (currently only COMMENT supported) use below
                    this.setCommentData('xfdf', xfdf)
                    this.setCommentData('uuid', annotation.Id)
                    this.changeCursorToDefault()

                    if (this.typeOfAnnotationBeingAdded === AnnotationTypes.COMMENT) {
                        this.showAnnotationInput()
                    }
                }

                if (action === 'delete') {
                    // This block is for deleting a basic annotation like line, rectangle, measurement etc.
                    if (annotation.Subject === 'Native-Apryse-Markup' && !this.selectedAnnotationIsTemporary) {
                        const annotationInternalId = this.annotations.find((item: AnnotationData) => item.uuid === annotation.Id).id

                        await this.deleteBasicAnnotation(this.documentId, annotationInternalId)
                        this.$emit('updateAnnotations')
                    }
                }
            })

            // This event is regarding selecting/deselecting annotations on the pdf canvas
            annotationManager.addEventListener('annotationSelected', async (annotations: Core.Annotations.Annotation, action: string): Promise<void> => {
                const annotation = annotations[0]

                if (action === 'selected') {
                    // Hides or shows the context menu on annotation select based on type of annotation being selected
                    // @ts-ignore
                    this.instance.UI[['IO-Markup', 'Draft'].includes(annotation.Subject) ? 'disableElements' : 'enableElements'](['annotationPopup'])

                    this.setSelectedAnnotationId(annotation.Id)
                }

                if (action === 'deselected' && !this.deselectEventDisabled) {
                    // This is here in order for the annotation to be deleted once the user deselects it during the process of adding it (changes his mind)
                    // This only exists for annotations other than basic apryse annotations because those are multistep
                    if (this.selectedAnnotationIsTemporary) {
                        annotationManager.deleteAnnotation(annotation)
                        this.endAddingAnnotation()
                    }

                    this.clearSelectedAnnotationId()
                }
            })

            documentViewer.addEventListener('toolModeUpdated', (newToolObject) => {
                this.deselectEventDisabled = this.toolHasDisabledEvents(newToolObject.name)
            })
        },
        // Certain apryse basic markup tools have a multistep process that consists an action of "deselect"
        // which can disrupt the process of adding it by removing the annotation from canvas ->> (see if (action === 'deselected'))
        // In order to be able to add this type of markup we need to disable the "deselected" event for them
        toolHasDisabledEvents (toolName: string): boolean {
            const listOfTools = ['AnnotationCreateFreeText', 'AnnotationCreateCallout', 'AnnotationCreateDateFreeText']
            return listOfTools.includes(toolName)
        },
        async addBasicAnnotation (documentId: string, postData: NewBasicAnnotationPayloadInterface): Promise<void> {
            this.setLoadingBar(true)

            try {
                await basicAnnotationsClient.addBasicAnnotation(documentId, postData)
                this.$emit('updateAnnotations')
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async modifyBasicAnnotation (documentId: string, annotationId: string, postData: {
            xfdf: string
        }): Promise<void> {
            this.setLoadingBar(true)

            try {
                await basicAnnotationsClient.modifyBasicAnnotation(documentId, annotationId, postData)
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async deleteBasicAnnotation (documentId: string, annotationId: string): Promise<void> {
            this.setLoadingBar(true)

            try {
                await basicAnnotationsClient.deleteBasicAnnotation(documentId, annotationId)
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        deselectAllAnnotations (): void {
            // @ts-ignore
            this.instance.Core.annotationManager.deselectAllAnnotations()
        },
        replaceContextMenuPopupOptions (): void {
            const generateContextMenuPopupOption = (name: TranslateResult, className: string, annotationType: AnnotationTypes): HTMLDivElement => {
                const divElement: HTMLDivElement = document.createElement('div')
                divElement.classList.add('io-context-popup-item')
                divElement.classList.add(className)
                divElement.innerText = name.toString()
                divElement.onclick = (): void => this.$emit('triggerAddingAnnotationEvent', annotationType)
                return divElement
            }

            // @ts-ignore
            this.instance.UI.contextMenuPopup.update([
                {
                    type: 'customElement',
                    render: () => generateContextMenuPopupOption(this.$t('Add Comment'), 'io-comment', AnnotationTypes.COMMENT),
                },
            ])
        },
        configureAnnotation (annotation: Core.Annotations.Annotation): void {
            // Prepare annotation values to be displayed properly and to not be able to change the SVG of added Annotation
            annotation.Subject = this.typeOfAnnotationBeingAdded !== AnnotationTypes.BASIC ? 'IO-Markup' : 'Native-Apryse-Markup'
            annotation.NoResize = this.typeOfAnnotationBeingAdded !== AnnotationTypes.BASIC
            annotation.Rotation = 0
            // offset the annotation so that it points to the correct place. by default the center of the annotation is placed where you click.
            if (annotation.Subject === 'IO-Markup') {
                annotation.X = annotation.X + 16
                annotation.Y = annotation.Y + 16
            }
            annotation.disableRotationControl()
        },
        importAnnotations (): void {
            //@ts-ignore
            const { annotationManager } = this.instance.Core

            this.annotations.forEach(async (annotation: AnnotationData) => {
                await annotationManager.importAnnotationCommand(annotation.xfdf)
            })
        },
        selectAnnotation (uuid: string): void {
            this.deselectAllAnnotations()
            //@ts-ignore
            const { annotationManager } = this.instance.Core

            const annotationToBeSelected = annotationManager.getAnnotationById(uuid)
            annotationManager.selectAnnotation(annotationToBeSelected)
        },
        mapPdftronAnnotationTool (toolName: string): string {
            const mappedTools = [
                {
                    value: 'TEXT',
                    tools: [
                        'AnnotationCreateTextHighlight',
                        'AnnotationCreateTextUnderline',
                        'AnnotationCreateTextStrikeout',
                        'AnnotationCreateTextSquiggly',
                        'AnnotationCreateFreeText',
                    ],
                },
                {
                    value: 'ELLIPSE',
                    tools: ['AnnotationCreateEllipse'],
                },
                {
                    value: 'ARC',
                    tools: ['AnnotationCreateArc'],
                },
                {
                    value: 'CLOUD',
                    tools: ['AnnotationCreatePolygonCloud'],
                },
                {
                    value: 'POLYGON',
                    tools: ['AnnotationCreatePolygon'],
                },
                {
                    value: 'RECTANGLE',
                    tools: ['AnnotationCreateRectangle'],
                },
                {
                    value: 'POLYLINE',
                    tools: ['AnnotationCreatePolyline'],
                },
                {
                    value: 'LINE',
                    tools: ['AnnotationCreateLine'],
                },
                {
                    value: 'ARROW',
                    tools: ['AnnotationCreateArrow'],
                },
                {
                    value: 'MEASUREMENT',
                    tools: [
                        'AnnotationCreateDistanceMeasurement',
                        'AnnotationCreateArcMeasurement',
                        'AnnotationCreateEllipseMeasurement',
                        'AnnotationCreatePerimeterMeasurement',
                        'AnnotationCreateCountMeasurement',
                        'AnnotationCreateCloudyRectangularAreaMeasurement',
                        'AnnotationCreateRectangularAreaMeasurement',
                    ],
                },
                {
                    value: 'STAMP',
                    tools: [
                        'AnnotationCreateDateFreeText',
                        'AnnotationCreateRubberStamp',
                        'AnnotationCreateFreeHand',
                        'AnnotationCreateFileAttachment',
                        'AnnotationCreateStamp',
                    ],
                },
            ]


            mappedTools.forEach((tool: { value: string, tools: string[] }) => {
                const newTools = []
                tool.tools.forEach(toolName => {
                    newTools.push(toolName)
                    for (let i = 1; i <= 4; i++) {
                        const versionedToolName = `${ toolName }${ i }`
                        newTools.push(versionedToolName)
                    }
                })
                tool.tools = newTools
            })

            return mappedTools.find(tool => tool.tools.includes(toolName))?.value ?? null
        },
        async toggleAnnotationClass (enable: boolean, val?: AnnotationTypes): Promise<void> {
            // @ts-ignore
            const iframeDoc = this.instance.UI.iframeWindow.document
            const docContainer = iframeDoc.querySelector('[data-element="documentContainer"]')
            const headerToolsContainer = iframeDoc.querySelector('[data-element="toolsHeader"]')

            if (!enable) {
                docContainer.classList.value = docContainer.classList.value.replace(/io-adding-\w+/g, '')
                headerToolsContainer.classList.value = headerToolsContainer.classList.value.replace(/io-adding-\w+/g, '')
                return
            }

            if (val) {
                docContainer.classList.add('io-adding-' + val)
                headerToolsContainer.classList.add('io-adding-' + val)
            }
        },
    },
})
