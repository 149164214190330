<template>
    <div class="io-modal-container" v-on:keyup.esc="goBack()">
        <div class="io-mask" @click.stop="goBack(true)"></div>
        <div :id="(!shortenScreen) ? 'io-memberSelection' : 'io-memberSelectionSend'"
             class="io-invite-member-select">
            <div id="searchBar" class="io-invite-member-search io-f-row-invite">
                <div class="io-f-row-invite">
                    <div v-if="modalScreen > 0" class="io-arrow-left-icon" v-on:click.stop="goBack()"></div>
                    <div v-else class="io-search-icon"></div>

                    <input type="text"
                           :disabled="modalScreen !== 1"
                           :class="{ 'io-notEmail' : !filteredList && !isEmail }"
                           :placeholder="$t('Search contacts by email or name, or invite new user by email')"
                           ref="searchEmployeeRef"
                           v-model="searchEmployee"/>
                </div>
                <IOButton
                    v-if="showSaveButton"
                    variant="secondary"
                    :disabled="disableSaveBtn"
                    @click="goBack(true)"
                >
                    {{ $t('Save') }}
                </IOButton>
            </div>
            <div class="io-emplo-holder" :class="{'io-emplo-holder-send': shortenScreen }">
                <!-- DEFAULT LIST -->
                <template v-if="!loading && filteredList && filteredList.length && modalScreen === 1">
                    <div class="io-invite-modal-scroll-box">

                        <DynamicScroller
                            class="scroller"
                            :items="filteredList"
                            :min-item-size="16"
                        >
                            <template v-slot="{ item, index, active }">
                                <DynamicScrollerItem
                                    :item="item"
                                    :active="active"
                                    :data-index="index"
                                >
                                    <div v-if="item.key === addedFirst || (addedFirst === 0 && item.key === addedCustomFirst)" class="io-label-row io-label-header io-added">{{ $t('Added') }}</div>
                                    <div v-else-if="item.key === invitedFirst" class="io-label-row io-label-header">{{ $t('Connected') }}</div>
                                    <div v-else-if="item.key === pendingsFirst" class="io-label-row io-label-header">{{ $t('status_pending') }}</div>
                                    <div v-else-if="item.key === employeesFirst" class="io-label-row io-label-header">{{ $t('Employees') }}</div>
                                    <div v-else-if="item.key === contactsFirst || item.key === customListFirst" class="io-label-row io-label-header">{{ customContactsLabel }}</div>
                                    <div v-else-if="checkIsCustomFirst(item)" class="io-label-row io-label-header">{{ item.custom1 }}</div>
                                    <item-box
                                        v-if="item"
                                        :item="item"
                                        :key="item.key"
                                        :isLastRow="isLastRow(item)"
                                        @attachPerson="attachPerson(item)"
                                        @detachPerson="detachPerson(item)"
                                    />

                                </DynamicScrollerItem>
                            </template>
                        </DynamicScroller>
                    </div>
                </template>

                <!-- NO RESULTS INFORMATION / INVITE BY EMAIL -->
                <template v-else-if="!loading && filteredList && !filteredList.length && modalScreen === 1">
                    <template v-if="isSelfMail">
                        <div class="employeeBox io-f-row-invite io-invite-email">
                            <div>It is not possible to send an invitation to yourself</div>
                        </div>
                    </template>
                    <template v-else-if="disableInvitation">
                        <div class="employeeBox io-f-row-invite io-invite-email">
                            <div>No match found in existing workspace</div>
                        </div>
                    </template>
                    <template v-else-if="!isEmail">
                        <div v-if="showEmployees || showContacts" class="employeeBox io-f-row-invite io-invite-email">
                            <div>No match found in existing workspace contacts</div>
                        </div>
                        <div class="io-f-row-invite io-invite-email-img">
                            <div class="io-invite-email-bg"></div>
                            <div class="io-this-person">
                                Enter the full email addresses to send an invitation!
                            </div>
                        </div>
                    </template>
                    <div v-else-if="showEmployees || showContacts" class="io-f-row-invite io-choose-send">
                        <div class="io-choose-send-title">
                            No match found in existing workspace contacts
                            <br/>
                            Select an option below to proceed with sending an invitation to <span>{{ searchEmployee }}</span>
                        </div>
                        <div class="io-choose-send-boxes">
                            <div class="io-choose-send-box">
                                <div class="io-choose-send-icon io-choose-send-icon-first"></div>
                                <div class="io-choose-send-type">Employee (Internal)</div>
                                <div class="io-choose-send-desc">
                                    <template v-if="showEmployees && !invalidTrustedDomain">
                                        This will allow the invited user to join your company workspace, granting access
                                        to the shared data and project information of the workspace.
                                        <br/>
                                        This option should be used for employees only.
                                        <span>Please Note: Once employee has accepted invite and registered account the workspace admin will be notified and charged per user at the time of registration.</span>
                                    </template>
                                    <template v-else-if="invalidTrustedDomain">
                                        Only addresses from trusted domains can be invited as internal employees.
                                    </template>
                                    <template v-else>
                                        Only Contacts are eligible to be invited here.
                                    </template>
                                </div>

                                <div v-if="!loading && !invalidTrustedDomain && showEmployees" v-on:click.stop="selectInternal(), sendInvite()" class="io-btn-primary in-invite-modal">Send an Invite</div>
                            </div>
                            <div class="io-choose-send-box">
                                <div class="io-choose-send-icon io-choose-send-icon-second"></div>
                                <div class="io-choose-send-type">Contact (External)</div>
                                <div class="io-choose-send-desc">
                                    <template v-if="showContacts">
                                        This will allow the invited user to accept an invitation to collaborate on this
                                        project. Once accepted, you will be able to share data that you choose on this
                                        project to the invited user’s private workspace.
                                        <br/>
                                        The invited user will not have access to any data you choose not to share.
                                    </template>
                                    <template v-else>
                                        Only Employees are eligible to be invited here.
                                    </template>
                                </div>
                                <div v-if="!loading && showContacts" v-on:click.stop="selectExternal()" class="io-btn-primary">Send an Invite</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="io-f-row-invite io-choose-send">
                        <div class="io-choose-send-boxes">
                            <div class="io-choose-send-box">
                                <div class="io-choose-send-icon io-choose-send-icon-second"></div>
                                <div class="io-choose-send-type">Contact (External)</div>
                                <div class="io-choose-send-desc">
                                    This will allow the invited user to accept an invitation to collaborate on this
                                    project. Once accepted, you will be able to share data that you choose on this
                                    project to the invited user’s private workspace.
                                    <br/>
                                    The invited user will not have access to any data you choose not to share.
                                </div>
                                <div v-if="!loading" v-on:click.stop="selectExternal()" class="io-btn-primary">Send an Invite</div>
                            </div>
                        </div>
                    </div>
                </template>

                <!-- SEARCHING WORKSPACES -->
                <template v-else-if="!loading && modalScreen === 5">
                    <div v-if="!loading && !isEmail" class="employeeBox io-no-company">
                        <img src="/new-theme/img/empty-placeholder.svg">
                        <p>Type email to start searching and see results</p>
                    </div>
                    <div v-else-if="!loading && isEmail && !workspacesNumResults" class="employeeBox io-no-company">
                        <img src="/new-theme/img/empty-placeholder.svg">
                        <p>
                            Looks like there are no existing IngeniousIO user accounts
                            <br />or workspaces linked to the email
                        </p>
                        <p><strong>{{ searchEmployee }}</strong></p>
                        <p><br /></p>

                        <p>Proceed with sending an invitation to</p>
                        <p><strong>{{ searchEmployee }}</strong></p>
                        <div v-on:click.stop="addNewWorkspace()" class="io-btn-primary io-btn-center">Send an Invite</div>
                    </div>

                    <!-- RESULTS -->
                    <template v-if="!loading">
                        <template v-if="isEmail && listWorkspaces.email.length">
                            <div class="io-label-row">Existing User Account(s) found for {{ searchEmployee }}.
                                <br /><small>Select an option below to invite the existing user account to connect and collaborate.</small>
                            </div>
                            <div class="io-invite-modal-scroll-box">
                                <template v-for="item in listWorkspaces.email" :key="item.key">
                                    <div>
                                        <company-box v-if="item"
                                                     :item="item"
                                                     :isLastRow="isLastRow(item)"
                                                     @attachPerson="attachPerson(item)"
                                                     @detachPerson="detachPerson(item)"
                                        ></company-box>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <template v-if="isEmail && listWorkspaces.trusted.length">
                            <div class="io-label-row">
                                No Existing User Account(s) found for {{ searchEmployee }}.
                                <br />Existing Workspace(s) found for email domain {{ searchDomain }}.
                                <br /><small>Select an option below to invite this user to join an existing workspace and connect.</small>
                            </div>
                            <div class="io-invite-modal-scroll-box">
                                <template v-for="item in listWorkspaces.trusted" :key="item.key">
                                    <div>
                                        <company-box v-if="item"
                                                     :item="item"
                                                     :isLastRow="isLastRow(item)"
                                                     @attachPerson="attachPerson(item)"
                                                     @detachPerson="detachPerson(item)"
                                        ></company-box>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                </template>


                <!-- REMOVING EXISTING INVITATION -->
                <template v-else-if="!loading && modalScreen === 2">
                    <div class="io-f-row-invite io-choose-invite-send io-choose-invite-sendinfo">
                        <p>Do you really want to remove invitation to this email?</p>
                        <p><strong>{{ userToRemove.email }}</strong></p>
                        <p>Invitation has been already sent and user received notification.</p>
                    </div>
                    <div class="employeeBox io-f-row-invite io-choose-invite-send">
                        <div v-on:click.stop="invitationRemove()" class="io-btn-primary io-btn-add-next">Remove</div>
                        <div @click="goBack()" class="io-btn-secondary">Cancel</div>
                    </div>
                </template>


                <!-- CREATING COMPLETELY NEW WORKSPACE -->
                <template v-else-if="!loading && isEmail && modalScreen === 3">
                    <div class="io-f-row-invite io-choose-external">
                        <div class="io-choose-external-title">External Invitation</div>
                        <div class="io-choose-external-subtitle">Send an Invite</div>
                        <div class="io-choose-external-options">
                            <template v-for="typeItem in appsTypes" :key="typeItem.id">
                                <div class="io-choose-external-option"
                                     :class="{ 'io-choose-external-option-active': typeItem.id === selectedUser.company_app }"
                                     v-on:click.stop="setAppType(typeItem.id)">
                                    <div class="io-choose-external-radio"><span></span></div>
                                    <div class="io-choose-external-option-text">{{ typeItem.type }}</div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div v-if="isEmail" class="employeeBox io-f-row-invite io-choose-external-send">
                        <div
                            v-if="!loading"
                            class="io-btn-primary in-invite-modal"
                            :class="{'io-btn-disabled': selectedUser.company_app === null}"
                            @click.stop="sendInvite()"
                        >
                            Send an Invite
                        </div>
                    </div>
                </template>


                <!-- AFTER SENT INVITATION -->
                <template v-else-if="!loading && modalScreen === 4">
                    <div class="io-f-row-invite io-choose-invite-send io-choose-invite-sendinfo">
                        <div class="io-choose-invite-send-icon"></div>
                        <div class="io-choose-invite-send-title">You’re done!</div>
                        <div class="io-choose-invite-send-subtitle">
                            An email invitation has been sent to
                            <template v-if="!selectedUser.isExternal">{{ selectedUser.email }}</template>
                            <template v-else>{{ searchEmployee }}</template>

                            <template v-if="selectedUser.isExternal">
                                to collaborate on project-specific information you've shared.
                            </template>
                            <template v-else>
                                collaborate in your workspace.
                            </template>
                        </div>
                    </div>
                    <div class="employeeBox io-f-row-invite io-choose-invite-send">
                        <div v-if="!oneOnly" v-on:click.stop="sendNext()" class="io-btn-primary io-btn-add-next">Add
                            Next
                        </div>
                        <div @click="goBack(true)" class="io-btn-secondary">Close</div>
                    </div>
                </template>


                <!-- LOADING -->
                <div v-else-if="loading" class="io-loader io-loading-screen io-member-selection-loading" id="io-loader-1"></div>
            </div>

            <div class="io-btn-invite-action" @click="$parent.$emit(customAction.actionName)" v-if="customAction">
                <p><i class="fas fa-plus-circle"></i> {{ customAction.btnText }}</p>
            </div>
        </div>
    </div>
</template>
<script src="./invite-modal/invite-modal.js"></script>
<style lang="scss" scoped>
    * {
        will-change: height, contents;
        transition: inherit;
        perspective: inherit;
        backface-visibility: hidden;
    }

    .io-btn-invite-action {
        text-align: center;
        padding: 12px;
        background-color: rgba(var(--accent-rgb), 0.1);
        transition: opacity .3s;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }

        p {
            color: var(--accent);
        }

        i {
            margin: 0 6px;
        }
    }

    .io-invite-modal-scroll-box {
        overflow-y: auto;
        height: 480px;
    }

    .scroller {
        height: 100%;
    }
</style>
