<template>
    <div v-click-outside="hideMenu" class="io-multiselect" :class="{'io-active': showMenu}">
        <div class="io-multiselect__content" :class="{ 'io-active': showMenu, 'io-disabled': disabled}"  @click.stop="toggleMenu" >
            <slot name="header" :activeOptions="getActiveLabel" :config="config"></slot>
            <div class="io-multiselect__icon"><i class="icon-chevron-right"></i></div>
        </div>
        <transition name="multiselect-menu">
            <div v-if="showMenu" class="io-multiselect__menu">
                <div class="io-multiselect__list-container">
                    <div class="io-multiselect__list">
                        <div v-if="selectAll" class="io-single-option io-single-option--title" @click.stop="changeCheckAll">
                            <input
                                type="checkbox"
                                v-model="isCheckAll"
                            />
                            {{ selectAll }}
                        </div>
                        <div v-for="item in data">
                            <div v-if="item.options" class="io-multiselect__section">
                                <div class="io-single-option io-single-option__title" @click.stop="onClickSection(item.options, !item.checked, item)">
                                    <input v-if="multiple" type="checkbox" v-model="item.checked" />
                                    <slot name="title" :title="item.title"></slot>
                                </div>
                                <div v-for="option in item.options" class="io-single-option io-single-option__content" @click.stop="onClickItemFromSection(item, option)">
                                    <input v-if="multiple" type="checkbox" v-model="option.checked" />
                                    <slot name="option" :option="option"></slot>
                                </div>
                            </div>
                            <div v-else class="io-single-option" @click.stop="changeCheckOption(item)">
                                <input v-if="multiple" type="checkbox" v-model="item.checked" />
                                <slot name="option" :option="item"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" src="./MultiselectCheckbox.ts"></script>
<style lang="scss" src="./MultiselectCheckbox.scss" scoped></style>