<template>
    <td>
        <div v-if="status === 'Importing' || status === 'Finalizing'"
             class="io-status-pill io-status-pill-loading"
             :style="{ '--progress': `${loadingPercentage}%` }"
        >
            {{ $t(statusText) }}
            <i class="fas fa-spin fa-spinner"></i>
        </div>
        <div v-else-if="status === 'Rolling Back'"
             class="io-status-pill io-status-pill-loading-rollingback"
             :style="{ '--progress': `${loadingPercentage}%` }"
        >
            {{ $t(statusText) }}
            <i class="fas fa-spin fa-spinner"></i>
        </div>
        <div v-else-if="status" class="io-status-pill" :class="classes">{{ $t(statusText) }}</div>
        <div v-else>-</div>

        <template v-if="showToolTip">
            <IconTooltip :text="$t('This shared contract is not mapped to Your Budget cost codes.')"/>
        </template>
    </td>
</template>

<script lang="ts" src="./StatusLoading.ts"></script>
<style lang="scss" src="./StatusLoading.scss" scoped></style>
