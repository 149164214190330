import { orderBy } from 'lodash'
import type SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument'
import TableSortIcon from '@/components/table-sort-icon/TableSortIcon.vue'
import { defineComponent, PropType } from 'vue'
import SingleFile from '@/components/simple-file-list/parts/single-file/SingleFile.vue'
import DeletePopupAlertAttributesInterface
    from '@/components/simple-file-list/interfaces/DeletePopupAlertAttributesInterface'

export default defineComponent({
    name: 'SimpleFileListFromProp',
    components: {
        TableSortIcon,
        SingleFile,
    },
    props: {
        itemId: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        toSync: {
            type: Boolean,
            default: false,
        },
        documents: {
            type: Array as () => SingleModelDocument[],
            default: () => [],
        },
        modelType: {
            type: String,
            required: true,
        },
        showOptionSync: {
            type: Boolean,
            default: false,
        },
        showPreviewButton: {
            type: Boolean,
            default: false,
        },
        showDownloadButton: {
            type: Boolean,
            default: false,
        },
        showDeleteButton: {
            type: Boolean,
            default: false,
        },
        clickableRows: {
            type: Boolean,
            default: true,
        },
        showUpdateDate: {
            type: Boolean,
            default: false,
        },
        showCreatorName: {
            type: Boolean,
            default: false,
        },
        showCreatorAvatar: {
            type: Boolean,
            default: false
        },
        showSharedYesNo: {
            type: Boolean,
            default: false,
        },
        deletePopupAlertAttributes: {
            type: Object as PropType<DeletePopupAlertAttributesInterface>,
            required: false,
        },
    },
    data () {
        return {
            sortBy: '',
            sortDir: 'asc' as SortDirectionType,
        }
    },
    computed: {
        sortedDocuments (): SingleModelDocument[] {
            return orderBy(this.documents, this.sortBy, this.sortDir)
        },
        countColumns (): number {
            const totalColumns = 6
            const hiddenColumns = [this.showCreatorName, this.showUpdateDate, this.showSharedYesNo].filter((column) => column === false).length
            return totalColumns - hiddenColumns
        },
    },
    methods: {
        changeSort (key: string): void {
            if (key === this.sortBy) {
                this.sortDir = 'asc' === this.sortDir ? 'desc' : 'asc'
            }
            this.sortBy = key
        },
    }
})
