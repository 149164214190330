import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import LimitedTextarea from '@/components/atoms/LimitedTextarea/LimitedTextarea.vue'

export default defineComponent({
    components: {
        PopupModal,
        LimitedTextarea
    },
    data () {
        return {
            reason: '',
            customizedMessage: ''
        }
    },
    methods: {
        closePopup (): void {
            this.$emit('close')
        },
        rejectInvitation (): void {
            this.$emit('rejectInvitation', { reason: this.reason })
        }
    }
})

