export default {
    // Config
    projectId: null,
    contractId: null,

    costCodes: [],
    costCodesSov: [],
    costCodesProject: [],
    contractPhases: [],

    coListFilters: null
}
