import { defineComponent } from 'vue'
import AddComment from './add-comment/AddComment.vue'
import SingleComment from './single-comment/SingleComment.vue'

import { mapState, mapActions } from 'pinia'
import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import { commentsStore } from '@/io-modules/documents/store/commentsStore'
import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'
import commentsClient from '@/io-modules/documents/api-clients/commentsClient'
import { AnnotationData } from '@/io-modules/documents/interfaces/AnnotationData'
import getFilteredItems from '@/io-modules/documents/composables/getFilteredItems'

export default defineComponent({
    name: 'Comments',
    components: {
        AddComment,
        SingleComment
    },
    inject: ['pid', 'id'],
    props: {
        searchedValue: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapState(viewDocumentStore, [
            'typeOfAnnotationBeingAdded',
            'isAnnotationBeingAdded',
            'isAnnotationInputVisible',
            'annotations',
            'uniqueMentionUsers'
        ]),
        ...mapState(commentsStore, [
            'xfdf',
            'comment_text',
            'shared_with',
            'uuid'
        ]),
        projectIdLocal (): string {
            return this.$store.getters['project/projectCompanyMongoId']
        },
        isAddCommentInputVisible (): boolean {
            return this.isAnnotationBeingAdded
                && this.typeOfAnnotationBeingAdded === AnnotationTypes.COMMENT
                && this.isAnnotationInputVisible
        },
        comments (): AnnotationData[] {
            return getFilteredItems(this.annotations, this.searchedValue, AnnotationTypes.COMMENT)
        },
    },
    async mounted (): Promise<void> {
        if (this.pid) {
            await this.getPeopleList()
        }
    },
    methods: {
        ...mapActions(viewDocumentStore, [
            'endAddingAnnotation',
            'setUniqueMentionUsers'
        ]),
        cancelAddingAnnotation (): void {
            this.$emit('cancel-adding-annotation')
        },
        async getPeopleList (): Promise<void> {
            this.setLoadingBar(true)

            try {
                const { data } = await commentsClient.getMentionUsers(this.projectIdLocal)

                const uniqueMentionUsers = []

                Object.values(data).forEach(category => {
                    category.forEach(company => {
                        company.persons.forEach(person => {
                            // Check if the person already exists in the uniqueMentionUsers array
                            const existingPerson = uniqueMentionUsers.find(p => p.id === person.id)
                            if (!existingPerson) {
                                uniqueMentionUsers.push(person)
                            }
                        })
                    })
                })

                this.setUniqueMentionUsers(uniqueMentionUsers)
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        async addComment (): Promise<void> {
            this.setLoadingBar(true)

            const postData = {
                xfdf: this.xfdf,
                comment_text: this.comment_text,
                shared_with: this.shared_with,
                uuid: this.uuid,
                type: AnnotationTypes.COMMENT.toUpperCase()
            }

            try {
                await commentsClient.addComment(this.id, postData)
                this.updateAnnotations()
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
                this.endAddingAnnotation()
            }
        },
        updateAnnotations (): void {
            this.$emit('update-annotations')
        },
        selectAnnotation (uuid: string): void {
            this.$emit('select-annotation', uuid)
        },
        isLastComment (index: number): boolean {
            return index === this.comments.length - 1
        }
    }
})
