/** Component is deprecated */
export default {
    name: 'Popup',
    data: () => ({
        opened: false
    }),
    props: {
        width: {
            type: [String, Number],
            required: false,
            default: null
        },
        zindex: {
            type: Number,
            required: false,
            default: 10,
        },
        top: {
            type: [ Number, null ],
            required: false,
            default: null
        },
        contentPadding: {
            type: Boolean,
            required: false,
            default: true
        },
        disableOutsideClose: {
            type: Boolean,
            required: false,
            default: false
        },
        popupID: {
            type: String,
            required: false,
            default: 'IOGenericPopup'
        }
    },

    computed: {
        hasHeaderSlot () {
            return !!this.$slots.header
        },
        hasFooterSlot () {
            return !!this.$slots.footer
        },

        /**
         *
         * @return {string}
         */
        widthCalculated () {
            if (!isNaN(this.width)) {
                return `${ this.width }px`
            }
            return this.width
        },

        styles () {
            return {
                'width': `${ this.widthCalculated } !important`,
                'z-index': `${ this.zindex } !important`,
                'top': `${ this.top }px !important`
            }
        }
    },

    methods: {
        closeClickOutside () {
            if (this.disableOutsideClose) {
                return false
            }
            this.$emit('close')
        },
        handleKeyUp (e) {
            if (e.keyCode === 27) {
                this.$emit('close')
            }
        }
    },

    beforeMount () {
        document.addEventListener('keyup', this.handleKeyUp)

        if (document.getElementsByClassName('io-menu-left')[0]) {
            document.getElementsByClassName('io-menu-left')[0].classList.add('modal-open')
        }
    },

    beforeUnmount () {
        document.removeEventListener('keyup', this.handleKeyUp)

        if (document.getElementsByClassName('io-menu-left')[0]) {
            document.getElementsByClassName('io-menu-left')[0].classList.remove('modal-open')
        }
    }
}
