export enum ChangeOrderStatus {
    NOT_ASSIGNED = 0,
    DRAFT = 100,
    PENDING_INTERNAL_APPROVAL = 125,
    REJECTED_INTERNALLY = 130,
    REJECTED_BY_CLIENT = 135,
    APPROVED_INTERNALLY = 140,
    REQUEST_SENT = 150,
    REQUEST_RECEIVED = 170,
    REQUEST_DECLINED = 180,
    REQUEST_IN_PROGRESS = 190,
    SUBMITTED_TO_CONTRACTOR = 200,
    SUBMITTED_TO_ARCHITECT = 250,
    SUBMITTED_TO_OWNER = 280,
    SUBMITTED = 290,
    PENDING = 300,
    PENDING_CONTRACT = 310,
    SUBMITTED_FOR_REVIEW = 400,
    APPROVED = 500,
    DENIED = 700,
    REVIEW_RESUBMIT = 800,
    FORWARD_TO_CLIENT = 850,
    APPROVE_FORWARDED = 860,
    DENY_FORWARDED = 870,
    EXECUTED = 900,
    OUTSTANDING = 1000,
    REVISE_RESUBMIT = 70
}
