<template>
    <tr class="io-table-row__actions">
        <td class="io-table-row__actions-cell" :colspan="colspan">
            <div class="actions-wrapper">
                <template v-for="(button, index) in actionsRowButtons" :key="index">
                    <IOButton
                        :variant="button.variant"
                        :icon="button.icon"
                        @click.stop="$emit(button.onSubmitName, item)"
                    >
                        {{ button.name }}
                    </IOButton>
                </template>
            </div>
        </td>
    </tr>
</template>

<script lang="ts" src="./TableRowActions.ts"></script>

<style lang="scss" scoped src="./TableRowActions.scss"></style>