import { defineComponent } from 'vue'
import Files from '@/components/files-new/files.vue'

export default defineComponent({
    name: 'StepUploadContract',
    components: {
        Files,
    },
    props: {
        modelValue: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
    data () {
        return {
            validationOptions: {
                allowedExtensions: ['pdf', 'doc', 'docx', 'txt'],
                acceptFiles: ['.pdf', '.doc', '.docx', '.txt'],
            },
            arrayId: 'StepUploadContract',
            contract: null,
        }
    },
    beforeMount ()  {
        this.contract = this.modelValue
        this.$store.dispatch('filesComponent/setMyDocs', {
            arrayId: this.arrayId,
            files: []
        })
    },
    methods: {
        async onFileChange (data: any): Promise<void> {
            try {
                this.contract.files = this.$store.getters['filesComponent/getMyFiles'](this.arrayId)
                this.$emit('onUploadComplete', data.document)
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
    }
})
