import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'

const featureFlagsMixin = defineComponent({
    computed: {
        ...mapState('appStore', ['featureFlags'])
    },
    methods: {
        isFeatureEnabled (name: FeatureFlagsConsts, defaultValue: boolean): boolean {
            return this.featureFlags?.hasOwnProperty(name) ? this.featureFlags[name] : defaultValue
        }
    }
})

export default featureFlagsMixin
