import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        data: { type: Object as () => any, required: true, default: null }
    },
    computed: {
        stampStyles (): { color: string, borderColor: string } {
            return {
                color: this.data?.color,
                borderColor: this.data?.color
            }
        }
    },
    methods: {
        parseStampName (fullName: string, maxNameLength: number): string {
            return (fullName.length > maxNameLength) ? `${fullName.substr(0, maxNameLength)}...` : fullName
        }
    }
})
