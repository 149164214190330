import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'
import ResourceApprovalsActions from '../interfaces/ResourceApprovalsActions'
import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse'
import ApprovableResourceType from '@/io-modules/approval-workflows/enums/ApprovableResourceType'
import ApprovalNoteVisibility from '@/io-modules/approval-workflows/enums/ApprovalNoteVisibility'
import { MyWorkspaceEligibleUsers } from '@/io-modules/approval-workflows/interfaces/EligibleApprovers.ts'
import { HasApprovalsWorkflowsResponse } from '@/io-modules/approval-workflows/interfaces/HasApprovalsWorkflowsResponse.ts'

class ApprovalsClient {
    public static async getAvailableActions (approvableResourceId: string): AxiosPromise<ResourceApprovalsActions> {
        if (!approvableResourceId) {
            throw new Error('No Approvable Resource ID Provided!')
        }

        return await client.get(`financial-approvals/approvable-resources/${ approvableResourceId }/available-actions`)
    }

    public static async getWorkflows (
        projectLocalId: string,
        resourceType: ApprovableResourceType,
    ): AxiosPromise<ResourceApprovalsResponse> {
        return await client.get(`projects/${ projectLocalId }/financial-approvals/workflows-groups/${ resourceType }`)
    }

    public static async getApprovableResourceId (
        resourceId: string,
        resourceType: ApprovableResourceType,
    ): AxiosPromise<ResourceApprovalsResponse> {
        return await client.get('approvable-resource-ids', {
            params: {
                resource_id: resourceId,
                resource_type: resourceType
            }
        })
    }

    public static async getApprovableResource (
        approvableResourceId: string,
    ): AxiosPromise<ResourceApprovalsResponse> {
        return await client.get(`/financial-approvals/approvable-resources/${ approvableResourceId }`)
    }

    public static async refreshApprovableResourceData (
        approvableResourceId: string,
    ): AxiosPromise<ResourceApprovalsResponse> {
        return await client.get(`/financial-approvals/approvable-resources/${ approvableResourceId }`, { cache: false })
    }

    public static async submitForApproval (approvableResourceId: string): AxiosPromise<null> {
        if (!approvableResourceId) {
            throw new Error('No Approvable Resource ID Provided!')
        }

        return await client.post(`financial-approvals/approvable-resources/${ approvableResourceId }/submit-for-approval`)
    }

    public static async recordManualApproval (
        approvableResourceId: string,
        note: string,
        noteVisibility: ApprovalNoteVisibility,
        documentIds: string[],
    ): AxiosPromise<null> {
        if (!approvableResourceId) {
            throw new Error('No Approvable Resource ID Provided!')
        }

        return await client.post(`financial-approvals/approvable-resources/${ approvableResourceId }/record-manual-approval`, {
            note,
            note_visibility: noteVisibility,
            document_ids: documentIds,
        })
    }

    public static async recordClientApproval (approvableResourceId: string, data: object): AxiosPromise<null> {
        if (!approvableResourceId) {
            throw new Error('No Approvable Resource ID Provided!')
        }

        return await client.post(`/financial-approvals/approvable-resources/${ approvableResourceId }/client-approval-records`, data)
    }

    public static async recordManualRejection (
        approvableResourceId: string,
        note: string,
        noteVisibility: ApprovalNoteVisibility,
        documentIds: string[],
    ): AxiosPromise<null> {
        if (!approvableResourceId) {
            throw new Error('No Approvable Resource ID Provided!')
        }

        return await client.post(`financial-approvals/approvable-resources/${ approvableResourceId }/record-manual-rejection`, {
            note,
            note_visibility: noteVisibility,
            document_ids: documentIds,
        })
    }

    public static async recordUserApproval (
        approvableResourceId: string,
        approverId: string,
        note: string,
        noteVisibility: ApprovalNoteVisibility,
        documentIds: string[],
    ): AxiosPromise<null> {
        if (!approvableResourceId) {
            throw new Error('No Approvable Resource ID Provided!')
        }
        if (!approverId) {
            throw new Error('No Approver ID Provided!')
        }

        return await client.post(`financial-approvals/approvable-resources/${ approvableResourceId }/approvers/${ approverId }/approvals`, {
            note,
            note_visibility: noteVisibility,
            document_ids: documentIds,
        })
    }

    public static async recordUserRejection (
        approvableResourceId: string,
        approverId: string,
        note: string,
        noteVisibility: ApprovalNoteVisibility,
        documentIds: string[],
    ): AxiosPromise<null> {
        if (!approvableResourceId) {
            throw new Error('No Approvable Resource ID Provided!')
        }
        if (!approverId) {
            throw new Error('No Approver ID Provided!')
        }

        return await client.post(`financial-approvals/approvable-resources/${ approvableResourceId }/approvers/${ approverId }/rejections`, {
            note,
            note_visibility: noteVisibility,
            document_ids: documentIds,
        })
    }

    public static async getEligibleApprovers (
        projectGlobalId: string,
    ): AxiosPromise<MyWorkspaceEligibleUsers[]> {
        if (!projectGlobalId) {
            throw new Error('No Project Global ID Provided!')
        }

        return await client.get(`projects/${ projectGlobalId }/financial-approvals/persons-eligible-to-workflow`)
    }

    public static async overrideApprover (
        approvableResourceId: string,
        approverId: string, // the approver who we want to override
        overriderId: string // the approver we want to override with
    ): AxiosPromise<null> {
        if (!approvableResourceId) {
            throw new Error('No Approvable Resource ID Provided!')
        }
        if (!approverId) {
            throw new Error('No Approver ID Provided!')
        }
        if (!overriderId) {
            throw new Error('No Overrider ID Provided!')
        }

        return await client.post(`financial-approvals/approvable-resources/${ approvableResourceId }/approvers/${ approverId }/override`, {
            contact_person_id: overriderId
        })
    }

    public static async getWorkflowsGroup (
        projectGlobalId: string,
        resourceType: ApprovableResourceType,
    ): AxiosPromise<ResourceApprovalsResponse> {
        return await client.get(`projects/${ projectGlobalId }/financial-approvals/workflows-groups/${ resourceType }`)
    }

    public static async createWorkflowsGroup (
        projectGlobalId: string,
        resourceType: ApprovableResourceType,
        data: object,
    ): AxiosPromise<null> {
        return await client.post(`projects/${ projectGlobalId }/financial-approvals/workflows-groups/${ resourceType }`, data)
    }

    public static async saveWorkflowsGroup (
        workflowsGroupId: string,
        data: object,
    ): AxiosPromise<null> {
        return await client.put(`/financial-approvals/workflows-groups/${ workflowsGroupId }`, data)
    }

    public static async deleteWorkflowsGroup (
        workflowsGroupId: string,
    ): AxiosPromise<null> {
        return await client.delete(`/financial-approvals/workflows-groups/${ workflowsGroupId }`)
    }

    public static async applyToOthers (
        workflowsGroupId: string,
        resources: Array<string>,
    ): AxiosPromise<null> {
        return await client.post(`/financial-approvals/workflows-groups/${ workflowsGroupId }/apply-to-others`, {
            resources
        })
    }

    public static async hasWorkflowsByResourceType (
        projectGlobalId: string,
        resourceType: ApprovableResourceType,
    ): AxiosPromise<HasApprovalsWorkflowsResponse> {
        return await client.get(`projects/${ projectGlobalId }/financial-approvals/has-workflows/${ resourceType }`)
    }
}

export default ApprovalsClient
