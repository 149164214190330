<template>
    <VueCtkDateTimePicker
        v-if="authDataReady && !isDisabled"
        :id="identifier"
        :auto-close="hideConfirmButton"
        :custom-shortcuts="customRanges"
        :disabled-dates="disabledDates"
        :first-day-of-week="customValues.startWeekOn"
        :format="dateFormat"
        :formatted="dateFormatted"
        :inline="displayInline"
        :locale="dateLocale"
        :no-button-now="hideButtonNow"
        :no-shortcuts="!displayRanges"
        :no-value-to-custom-elem="noValueToCustomElem"
        :only-date="onlyDate"
        :output-format="dateFormatOutput"
        :overlay="displayOverlay"
        :range="rangePicker"
        :shortcut="defaultShortcut"
        v-model="value"
        @formatted-value="datePickerValueFormatted"
        @input="datePickerValue"
        @is-shown="datePickerShow"
        @validate="acceptDateButton"
    >
        <template v-slot="{ toggleDatePicker }">
            <input
                placeholder="Filter by Date"
                readonly="true"
                type="text"
                :class="{
                    'io-ctk-custom-input io-popup-input io-input': true,
                    'io-error-custom': isError
                }"
                :value="formattedValue"
                @focus="toggleDatePicker(true)"
                @input="$emit('update:modelValue', $event.target.value)"
            />
        </template>
    </VueCtkDateTimePicker>
</template>

<script>
    import { clone } from 'lodash'
    import moment from 'moment'
    import { eventBus } from '@/mixins/eventBus'
    import isLoggedMixin from '@/mixins/isLoggedMixin'
    import datepickerMixin from '@/mixins/datepickerMixin'

    export default {
        props: {
            identifier: {
                type: String,
                required: false,
                default: 'DateTimePicker'
            },
            defaultShortcut: {
                type: String,
                required: false,
                default: 'thisWeek'
            },
            hideConfirmButton: {
                type: Boolean,
                required: false,
                default: false
            },
            onlyDate: {
                type: Boolean,
                required: false,
                default: false
            },
            allowEmpty: {
                type: Boolean,
                required: false,
                default: false
            },
            noClearButton: {
                type: Boolean,
                required: false,
                default: true
            },
            displayOverlay: {
                type: Boolean,
                required: false,
                default: true
            },
            dateFormat: {
                type: String,
                required: false,
                default: 'YYYY-MM-DD hh:mm a'
            },
            dateFormatOutput: {
                type: String,
                required: false,
                default: null
            },
            dateFormatted: {
                type: String,
                required: false,
                default: 'llll'
            },
            rangePicker: {
                type: Boolean,
                required: false,
                default: false
            },
            noValueToCustomElem: {
                type: Boolean,
                required: false,
                default: false
            },
            hideButtonNow: {
                type: Boolean,
                required: false,
                default: false
            },
            dateLocale: {
                type: String,
                required: false,
                default: 'en-ca'
            },
            displayInline: {
                type: Boolean,
                required: false,
                default: false
            },
            displayRanges: {
                type: Boolean,
                required: false,
                default: false
            },
            startWeekOn: {
                type: Number,
                required: false,
                default: 0
            },
            disabledDates: {
                type: Array,
                required: false,
                default: () => []
            },
            customRanges: {
                type: Array,
                required: false,
                default: () => [
                    { label: 'Today', key: 'today', value: 'day' },
                    { label: 'Yesterday', key: 'yesterday', value: '-day' },

                    {
                        label: 'This Week',
                        key: 'thisWeek',
                        value: () => {
                            return {
                                start: moment().startOf('week'),
                                end: moment().endOf('week')
                            }
                        }
                    },
                    {
                        label: 'Last Week',
                        key: 'lastWeek',
                        value: () => {
                            return {
                                start: moment().startOf('week').subtract(1, 'week'),
                                end: moment().endOf('week').subtract(1, 'week')
                            }
                        }
                    },

                    // {label: '', key: 'thisWeek', value: 'isoWeek'},
                    // {label: '', key: 'lastWeek', value: '-isoWeek'},

                    { label: 'This Month', key: 'thisMonth', value: 'month' },
                    { label: 'Last Month', key: 'lastMonth', value: '-month' },
                    { label: 'This Year', key: 'thisYear', value: 'year' },
                    { label: 'Last Year', key: 'lastYear', value: '-year' }
                ]
            },
            // model value options
            dateFormatModel: {
                type: Object,
                required: false,
                default: () => ({
                    datetime: 'YYYY-MM-DD HH:mm:ss',
                    date: 'YYYY-MM-DD'
                })
            },
            useDateFormatModel: {
                type: Boolean,
                required: false,
                default: false
            },
            // custom input options
            dateFormatInput: {
                type: String,
                required: false,
                default: 'MM/DD/YYYY'
            },
            // error input
            isError: {
                type: Boolean,
                required: false,
                default: false
            },
            autoUpdateCustomInput: {
                type: Boolean,
                required: false,
                default: false
            },
            // correct position by jquery.css(...
            correctPosition: {
                type: String,
                required: false,
                default: ''
            },
            // custom initial value
            valueInitial: {
                type: Object,
                required: false,
                default: () => ({})
            },
            // desired display format for custom input .io-ctk-custom-input
            valueInput: {
                type: String,
                required: false,
                default: ''
            },
            // if used as a filter
            widget: {
                type: String,
                required: false,
                default: ''
            },
            label: {
                type: String,
                required: false,
                default: ''
            },
            // store flag
            isSchemaFilter: {
                type: Boolean,
                required: false,
                default: false
            },
            // hide picker entirely option (ex. for some app type)
            isDisabled: {
                type: Boolean,
                required: false,
                default: false
            },
            autoRefresh: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        mixins: [isLoggedMixin, datepickerMixin],
        data () {
            return {
                value: '', // main value --> could be "String" or "Object" when date range
                valueFormatted: '', // ctk auto-formatted value (default input, picker popup header)
                authDataReady: true,
                datepickerReady: false,
                datePickerFullyInitialized: false,
                buttonClickedType: '',
                customValues: {
                    startWeekOn: this.startWeekOn,
                },
                // for schema filters
                items: {
                    settings: {
                        label: this.label // from props
                    },
                    options: [],
                    selected: [],
                    selectedLabels: [],
                    selectedLabelsMerged: '',
                    selectedRangesUTC: []
                }
            }
        },
        computed: {
            formattedValue () {
                if (!this.value?.start && !this.value?.end) {
                    return this.$t('Select date & time')
                }
                return `${ this.value.start ?? '' }-${ this.value.end ?? '' }`
            },
        },
        watch: {
            datepickerReady: function (flag) {
                if (flag) {
                    this.$nextTick(() => {
                        if (!this.allowEmpty) {
                            // set initial value for daterange[this week], for single date[today]
                            this.setInitialValue()
                            // update custom input
                            this.updateCustomInputValue()
                            // emit event
                            this.customInputModified()
                        }

                        eventBus.$emit('datePickerFullyInitialized', { id: this.identifier, value: this.valueInput })
                        setTimeout(() => {
                            this.datePickerFullyInitialized = true
                        }, 50)
                    })
                }
            },
            value: function (value) {
                // if confirm button disabled or single picker and "NOW" button is not disabled
                if ((this.hideConfirmButton) || (!this.hideButtonNow && !this.rangePicker)) {
                    this.acceptDateButton()
                }
            },
        },
        mounted () {
            this.$nextTick(() => {
                // when using component on the listing generator
                // used to detect filter removal and apply change on the frontend by clearing input with date range
                eventBus.$on('refresh-filters', this.onRefreshFilters)

                // update value formatted (used in situations when custom input .io-ctk-custom-input from component can't be displayed [gantt popup])
                eventBus.$on('setDatePickerValueFormatted', (data) => {
                    this.valueFormatted = data
                })

                // update value (used in situations when custom input .io-ctk-custom-input from component can't be displayed [gantt popup])
                eventBus.$on('setDatePickerValue', (data) => {
                    // update only specified datepicker
                    if (data.id !== undefined) {
                        if (data.id !== this.identifier) {
                            return false
                        }
                    }
                    // single datepicker
                    if (this.rangePicker === false) {
                        if (typeof data === 'string') {
                            this.value = data
                        }
                    } else {
                        // range datepicker
                        if (typeof data === 'object') {
                            this.value = data
                        }
                    }
                })

                // if start week on is available
                if (this.getAuthData.start_week_on !== undefined) {
                    this.onAuthDataReady()
                }

                // auth data ready - set real start week on and rerender component
                eventBus.$on('appStore/authDataReady', (flag) => {
                    if (flag) {
                        this.onAuthDataReady()
                    }
                })
            })
        },
        created () {
            // used from here --> src/filters/schemaFilters.js (ex. dateFilter)
            if (this.widget !== '') {
                this.isSchemaFilter = this.noValueToCustomElem = this.autoUpdateCustomInput = true
                this.initSchemaFilter()
            }
        },
        beforeUnmount () {
            eventBus.$off('appStore/authDataReady')
            eventBus.$off('setDatePickerValue')
            eventBus.$off('setDatePickerValueFormatted')
            eventBus.$off('refresh-filters')
        },
        methods: {
            datePickerValue (value, co1, co2) {
                // (used in situations when custom input .io-ctk-custom-input from component can't be displayed [gantt popup])
                eventBus.$emit('datePickerValue', value)
            },
            datePickerValueFormatted (value) {
                this.valueFormatted = value
                // (used in situations when custom input .io-ctk-custom-input from component can't be displayed [gantt popup])
                eventBus.$emit('datePickerValueFormatted', value)
            },
            prepareValueFormatted () {
                if (this.rangePicker && this.value && this.value.start && this.value.end) {
                    return moment(this.value.start).format(this.dateFormatted) + ' - ' + moment(this.value.end).format(this.dateFormatted)
                } else if (this.value) {
                    return moment(this.value).format(this.dateFormatted)
                }
            },
            acceptDateButton () {
                // update custom input
                this.updateCustomInputValue()

                // data
                let data = {
                    value: this.value,
                    valueFormatted: this.prepareValueFormatted(),
                    valueInput: this.valueInput,
                    id: this.identifier
                }

                // (used in situations when custom input .io-ctk-custom-input from component can't be displayed [gantt popup])
                if (this.checkDateIsPickedCorrectly()) {
                    eventBus.$emit('datePickerValueConfirm', data)
                }

                // emit event
                this.customInputModified()

                // schema filter - update
                this.setSchemaFilterValue()
            },
            customInputModified () {
                // default display format mm/dd/yyyy
                let updateDateRangeInput = this.valueInput

                // using model format yyyy-mm-dd hh:mm:ss
                if (this.useDateFormatModel) {
                    // output format
                    let updateDateRangeInputOutputFormat = this.onlyDate ? this.dateFormatModel.date : this.dateFormatModel.datetime
                    // single
                    if (this.rangePicker === false) {
                        updateDateRangeInput = moment(this.value).format(updateDateRangeInputOutputFormat)
                    // range
                    } else {
                        updateDateRangeInput = {
                            start: moment(this.value.start).format(updateDateRangeInputOutputFormat),
                            // set start date as end date when end date was not selected by the user
                            end: moment(this.value.end ? this.value.end : this.value.start).format(updateDateRangeInputOutputFormat)
                        }
                    }
                }

                //
                this.$emit('updateDateRangeInput', updateDateRangeInput)

                //
                if (this.datePickerFullyInitialized) {
                    this.$emit('inputValueChanged', updateDateRangeInput)
                    this.$emit('valueChanged', this.value)
                }
            },
            checkDateIsPickedCorrectly () {
                let result = false
                // single
                if (this.rangePicker === false) {
                    result = this.value !== '' && this.value !== null
                } else {
                    // range
                    result = this.value.start !== null && this.value.end !== null && this.value.start !== '' && this.value.end !== ''
                }
                return result
            },
            updateCustomInputValue () {
                // format value by dateFormatInput
                if (this.autoUpdateCustomInput !== false) {
                    // single
                    if (this.rangePicker === false) {
                        this.$emit('update:modelValue', moment(this.value).format(this.dateFormatInput))
                    } else {
                        // range
                        if (this.value.start !== null && this.value.end !== null) {
                            // if start and end available
                            this.$emit('update:modelValue', moment(this.value.start).format(this.dateFormatInput) + ' - ' + moment(this.value.end).format(this.dateFormatInput))
                        } else if (this.value.start !== null && this.value.end === null) {
                            // if user just selects start date then set end date same as start date
                            this.$emit('update:modelValue', moment(this.value.start).format(this.dateFormatInput) + ' - ' + moment(this.value.start).format(this.dateFormatInput))
                        } else {
                            this.$emit('update:modelValue', '')
                        }
                    }
                }
            },
            datePickerShow () {
                // parse value from input and set value
                this.setInternalValue()

                // css correction
                if (this.correctPosition !== '') {
                    let correctionValues = this.correctPosition.split('_')
                    $(this.$el).find('.datetimepicker').css(correctionValues[0], correctionValues[1])
                }

                // emit event
                this.$emit('datePickerShow')
            },
            setInternalValue () {
                if (this.autoUpdateCustomInput !== false && this.valueInput !== '' && this.valueInput !== null) {
                    // single
                    if (this.rangePicker === false) {
                        this.value = moment(this.valueInput).format(this.dateFormat)
                    } else {
                        // range
                        let dateRangeValueInput = this.valueInput.split('-')
                        if (dateRangeValueInput[0].trim() !== '' && dateRangeValueInput[1].trim() !== '') {
                            this.value = {}
                            this.value.start = moment(dateRangeValueInput[0].trim()).format(this.dateFormat)
                            this.value.end = moment(dateRangeValueInput[1].trim()).format(this.dateFormat)
                        }
                    }
                }
            },
            setInitialValue () {
                if (this.autoUpdateCustomInput !== false) {
                    // single
                    if (this.rangePicker === false) {
                        // from input
                        if (this.valueInput !== '' && this.valueInput !== null) {
                            this.value = moment(this.valueInput).format(this.dateFormat)
                        // from initial value
                        } else {
                            // custom initial value
                            if (this.valueInitial && this.valueInitial.start) {
                                this.value = moment(this.valueInitial.start).format(this.dateFormat)
                            // default
                            } else {
                                this.value = moment().format(this.dateFormat)
                            }
                        }
                    } else {
                        // range
                        this.value = {}

                        // from input
                        if (this.valueInput !== '' && this.valueInput !== null) {
                            let dateRangeValueInput = this.valueInput.split('-')
                            if (dateRangeValueInput[0].trim() !== '' && dateRangeValueInput[1].trim() !== '') {
                                this.value.start = moment(dateRangeValueInput[0].trim()).format(this.dateFormat)
                                this.value.end = moment(dateRangeValueInput[1].trim()).format(this.dateFormat)
                            }
                        // from initial value
                        } else {
                            // custom initial value
                            if (this.valueInitial && this.valueInitial.start && this.valueInitial.end) {
                                this.value.start = moment(this.valueInitial.start).format(this.dateFormat)
                                this.value.end = moment(this.valueInitial.end).format(this.dateFormat)
                            // default
                            } else {
                                this.value.start = moment().startOf('week').format(this.dateFormat)
                                this.value.end = moment(moment().startOf('week')).add(1, 'week').subtract(1, 'day').format(this.dateFormat)
                            }
                        }
                    }
                }
            },
            onAuthDataReady () {
                this.authDataReady = false

                this.$nextTick(() => {
                    this.customValues.startWeekOn = this.getAuthData.start_week_on
                    // apply preselection
                    this.preselectSchemaFilter()
                    // apply datepicker ready flags
                    this.authDataReady = true
                    this.datepickerReady = true
                })
            },
            initSchemaFilter () {
                let model = this.$parent

                // apply disabled flag is available
                if (model['filters'][this.widget].isDisabled !== undefined) {
                    this.isDisabled = model['filters'][this.widget].isDisabled
                }

                // set filter config from schema (ex. src/modules/employee-schedule/schema.js)
                let configObj = this.$parent.config.listingFiltersConfig
                if (configObj !== undefined && configObj[this.widget] !== undefined) {
                    Object.keys(configObj[this.widget]).forEach((key) => {
                        // set props value
                        this[key] = configObj[this.widget][key]
                    })
                }
            },
            getUnixTimestampsByInputValue () {
                let timestamps = []

                // single
                if (this.rangePicker === false && this.valueInput !== '' && this.valueInput !== null) {
                    timestamps[0] = timestamps[1] = this.getUnixTimestampUTC(this.valueInput)
                } else {
                    // range
                    if (this.valueInput !== '' && this.valueInput !== null) {
                        let dateRangeValueInput = this.valueInput.split('-')
                        if (dateRangeValueInput[0].trim() !== '' && dateRangeValueInput[1].trim() !== '') {
                            timestamps[0] = this.getUnixTimestampUTC(dateRangeValueInput[0].trim())
                            timestamps[1] = this.getUnixTimestampUTC(dateRangeValueInput[1].trim())
                        }
                    }
                }

                // return timestamps UTC of a date
                return timestamps
            },
            onRefreshFilters () {
                let filters = this.$parent.getActiveFilters()
                if (this.allowEmpty && (filters[this.widget] === undefined || !filters[this.widget])) {
                    this.$emit('update:modelValue', '')
                }
            },
            // apply filter data --> update parent & store in vuex
            applyFilterData (dateTimestamps) {
                let dateStart = this.getUnixTimestampUTC(dateTimestamps[0])
                let dateEnd = this.getUnixTimestampUTC(dateTimestamps[1])

                // create pack UTC timestamps
                let dateTimestampsUTC = [dateStart, dateEnd]

                // clone items
                let items = clone(this.items)

                // reset values
                items.selected = []
                items.selectedLabels = []
                items.selectedLabelsMerged = ''
                items.selectedRangesUTC = []

                // assign new values
                // timestamps
                items.selected.push(dateTimestamps[0])
                items.selected.push(dateTimestamps[1])
                // timestamps
                items.selectedLabels.push(dateTimestamps[0])
                items.selectedLabels.push(dateTimestamps[1])
                // string date
                items.selectedLabelsMerged = this.valueInput
                // timestamps UTC
                items.selectedRangesUTC.push(dateStart)
                items.selectedRangesUTC.push(dateEnd)

                // set filters triggered
                this.$parent.filtersTriggered[this.widget] = items
                // hack that trigger render on parent
                this.$parent.preselected = ''

                // if pagination available
                if (this.$parent.pagination !== undefined) {
                    this.$parent.pagination.current_page = 1
                }

                // store in vuex applied filter
                let payload = {
                    filter: this.widget, // name of filter
                    settings: dateTimestampsUTC // filter data
                }

                // set filters
                this.$store.dispatch('listingFilter/setFilters', payload, { root: true })
                // set page
                this.$store.dispatch('listingFilter/setPage', 1, { root: true })

                // emit refresh filters
                eventBus.$emit('refresh-filters')

                // if auto refresh enabled emit to reload parent view data
                if (this.autoRefresh !== false) {
                    this.$emit('dateRangeFilterChanged', this)
                }
            },
            setSchemaFilterValue () {
                // if not schema filter do nothing
                if (!this.isSchemaFilter) {return false}

                // when user accept date(s) selection
                // create timestamps by input value
                let dateTimestamps = this.getUnixTimestampsByInputValue()
                if (dateTimestamps.length > 0) {
                    this.applyFilterData(dateTimestamps)
                }
            },
            preselectSchemaFilter () {
                // manage preselection
                if (!this.isSchemaFilter) {return false}

                let model = this.$parent
                let filters = this.$parent.getActiveFilters()
                let dateStart, dateEnd

                // get from filters if availble
                if (filters[this.widget]) {
                    dateStart = this.prepareTimestamp(filters[this.widget][0])
                    dateEnd = this.prepareTimestamp(filters[this.widget][1])
                // apply preselect if availble
                } else if (model['filters'][this.widget].preselect !== undefined && model['filters'][this.widget].preselect.length === 2) {
                    dateStart = this.prepareTimestamp(model['filters'][this.widget].preselect[0])
                    dateEnd = this.prepareTimestamp(model['filters'][this.widget].preselect[1])
                }

                // set input value
                this.setInputValueByTimestamps(dateStart, dateEnd)
                // set internal value by input value
                this.setInternalValue()
                // store in vuex filter data
                this.setSchemaFilterValue()
            },
            setInputValueByTimestamps (timestampStartDate, timestampEndDate) {
                // timestampStartDate, timestampEndDate - value from server
                // convert to timestamp for JS --> timestamp * 1000
                timestampStartDate = this.prepareTimestamp(timestampStartDate)
                timestampEndDate = this.prepareTimestamp(timestampEndDate)

                // single
                if (this.rangePicker === false) {
                    if (timestampStartDate) {
                        this.$emit('update:modelValue', moment.utc(new Date(timestampStartDate * 1000).toUTCString()).format(this.dateFormatInput))
                    }
                // range
                } else {
                    if (timestampStartDate && timestampEndDate) {
                        this.$emit('update:modelValue',
                                   moment.utc(new Date(timestampStartDate * 1000).toUTCString()).format(this.dateFormatInput) +
                                       ' - ' +
                                       moment.utc(new Date(timestampEndDate * 1000).toUTCString()).format(this.dateFormatInput),
                        )
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '@/styles/mixins/scrollbar.scss';
    .date-time-picker .time-picker-overlay {
        z-index: 99999 !important;
    }

    .date-time-picker .datetimepicker {
        z-index: 999999 !important;
    }

    .io-ctk-custom-input {
        background-position: 95% center;
        background-size: 16px;
        background-repeat: no-repeat;
        display: block;
        background-image: url(/new-theme/img/calendar-input.svg);
    }

    .io-ctk-custom-input.io-error-custom {
        border: 1px solid red !important;
    }

    .datepicker {
        .datepicker-buttons-container {
            border-top-color: transparent !important;
            background-color: var(--white) !important;
        }

        .datepicker-buttons-container .datepicker-button svg {
            fill: var(--dark-green-lighten-1) !important;
        }

        .datepicker-buttons-container .datepicker-button .datepicker-button-effect {
            background: var(--dark-green-lighten-1) !important;
        }

        span, button {
            font-family: 'Inter', sans-serif !important;
        }
        &-controls {
            .datepicker-container-label {
                & > span {
                    .custom-button {
                        &:hover {
                            .custom-button-effect {
                                background: var(--dark-green-lighten-1) !important;
                                color: var(--white) !important;
                                opacity: 1;
                            }
                        }

                        &-content {
                            color: var(--main) !important;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .week-days {
            font-weight: 600;
        }

        .month-container {
            & span {
                font-weight: 500 !important;

                .datepicker-days {
                    & button {
                        font-weight: 500;
                    }
                    .datepicker-day {
                        &:hover {
                            background: transparent;
                            border: none !important;
                        }

                        &-text {
                            z-index: 7;
                        }

                        .datepicker-today {
                            border: 1px solid var(--dark-green-lighten-1) !important;
                            background: transparent;
                        }

                        &-effect {
                            opacity: 1 !important;
                            background: var(--dark-green-lighten-1) !important;
                        }

                        &.between {
                            & > span {
                                opacity: 1 !important;
                                color: var(--main) !important;
                                background: var(--lighten-bg-1) !important;
                            }
                        }

                        &.first, &.last, &.selected {
                            & > span {
                                color: var(--white) !important;
                                background: var(--dark-green-lighten-1) !important;
                                z-index: 6;
                                border-radius: 4px !important;
                            }
                        }
                    }
                }
            }
        }

        &-container-label {
            & > span {
                .custom-button {
                    &-content {
                        & > span {
                            color: var(--white) !important;
                        }
                    }
                }
            }
        }

        .shortcuts-container {
            background: var(--lighten-bg-1);
            border-right-color: transparent !important;
            margin-right: 4px;
            @include scrollbar(false);

            .custom-button {
                min-height: 30px !important;
                height: auto !important;
                background: transparent !important;
                border: none !important;

                &:hover {
                    span {
                        color: var(--main-lighten-1) !important;
                    }
                }

                &.is-selected {
                    .custom-button-content {
                        & > span {
                            color: var(--main-lighten-1) !important;
                        }
                    }
                }

                &-effect {
                    min-height: 30px !important;
                    height: auto !important;
                    background-color: var(--white) !important;
                    padding: 8px 16px !important;
                    width: 100px !important;
                    max-width: 100% !important;
                    border-radius: 4px !important;
                    margin: 0 auto;
                }

                &-content {
                    & > span {
                        color: var(--main-lighten-2-5);
                    }
                }
            }

            .custom-button:last-of-type {
                margin-bottom: 0 !important;
            }
        }

        .year-month-selector {
            z-index: 10;

            .custom-button {
                &.with-border {
                    border: none !important;
                }

                &.is-selected {
                    & > span {
                        color: var(--white) !important;
                        background: var(--dark-green-lighten-1) !important;
                    }
                }

                & > span {
                    color: var(--main) !important;
                }

                &-effect {
                    background: var(--dark-green-lighten-1) !important;
                }

                &:hover {
                    .custom-button-effect {
                        background: var(--dark-green-lighten-1) !important;
                        color: var(--white) !important;
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>
