import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'

export default defineComponent({
    name: 'InviteModalBanner',
    components: {
        PopupModal
    },
    methods: {
        onClosePopupClick (): void {
            this.$emit('close')
        }
    }
})
