import { defineStore } from 'pinia'
import InvoiceViewStoreState from '../interfaces/stores/InvoiceViewStoreState'
import { InvoiceSubType } from '@/io-modules/invoices/enums/InvoiceSubType'
import Invoice from '../interfaces/Invoice'
import dayjs from 'dayjs'
import invoiceCreationClient, {
    CreateMyInvoiceRequest,
    CreateNonContractedInvoiceRequest,
    CreateVendorInvoiceRequest, UpdateMyInvoiceRequest,
    UpdateNonContractedInvoiceRequest,
    UpdateVendorInvoiceRequest,
} from '../api-clients/invoiceCreationClient'
import { invoiceSoVStore } from './invoiceSoV'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
import invoiceViewClient, {
    MyInvoiceDetails,
    NonContractedInvoiceDetails, VendorInvoiceDetails,
} from '@/io-modules/invoices/api-clients/invoiceViewClient'
import InvoiceStatus from '../enums/InvoiceStatus'
import CostCode from '../interfaces/requests/FundingCostCode'
import InvoiceType from '../enums/InvoiceType'
import invoiceEditingClient, { ApplyFundingRequest } from '@/io-modules/invoices/api-clients/invoiceEditingClient.ts'
import { ExecutedContracts } from '@/io-modules/invoices/api-clients/invoiceCreationClient.ts'
import InvoiceActions from '@/io-modules/invoices/enums/InvoiceActions.ts'
import type { Category } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'
import { AxiosPromise } from 'axios'
import i18n from '@/base/i18n'
import type { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'
import type { DraftAppPackage } from '@/io-modules/invoices/api-clients/invoiceCreationClient.ts'
import InvoiceSoV from '@/io-modules/invoices/interfaces/responses/InvoiceSoV.ts'
import SoVLineItem from '@/io-modules/invoices/interfaces/SoVLineItem.ts'

export const invoiceViewStore = defineStore('invoiceView', {
    state: (): InvoiceViewStoreState => ({
        dataLoading: false,
        editing: false,
        creating: null,
        invoice: {
            invoice_date: '',
            start_date: '',
            end_date: '',
            description: '',
        } as MyInvoiceDetails|NonContractedInvoiceDetails|VendorInvoiceDetails,
        initialInvoiceData: {} as MyInvoiceDetails|NonContractedInvoiceDetails|VendorInvoiceDetails,
        invoiceFinancialInfo: {},
        invoiceGrossBillingSummary: {},
        invoiceContractSummary: {},
        vendorsInvoices: [],
        fundingSources: [],
        appliedFundingSources: [],
        attachedTemporaryDocuments: [],
        projectBudgetCostCodes: [],
        projectBudgetCostCodesSites: {},
        availableActions: [],
        invoiceLienWaivers: [],
        attachedInvoices: [],
        revisionProvided: false,
        showRequestRevisionModal: false,
        activeAccountingIntegration: null,
        clientApprovalSetting: '',
        accountingErrors: null,
        applyingFunding: false, //process of applying funding for received on-system vendor invoice
        uniqueNumberIsValid: true,
        invAmountWithTax: null,
        sites: null,
        draftAppPackages: null,
        lienWaiverTemplate: null
    }),
    getters: {
        hasAccountingIntegration (state: InvoiceViewStoreState): boolean {
            return state.activeAccountingIntegration && state.activeAccountingIntegration.id
        },

        isYardiIntegration (state: InvoiceViewStoreState): boolean {
            return state.hasAccountingIntegration && state.activeAccountingIntegration?.name?.toLowerCase().includes('yardi')
        },

        isRetentionInvoice (state: InvoiceViewStoreState): boolean {
            return state.hasAccountingIntegration && state.invoice.retention_invoice
        },

        uniqueNumberMaxCharacters (state: InvoiceViewStoreState): number {
            return state.isYardiIntegration ? 15 : 50
        },

        uniqueNumberCounter (state: InvoiceViewStoreState): string {
            return `${ state.invoice.invoice_unique_number?.length || 0 }/${ state.uniqueNumberMaxCharacters } ${ i18n.global.t('characters') }`
        },

        getContractedInvoiceDetails (state: InvoiceViewStoreState): MyInvoiceDetails|null {
            if (state.invoice?.type === InvoiceType.Contracted) {
                return state.invoice
            } else {
                return null
            }
        },

        getVendorInvoiceDetails (state: InvoiceViewStoreState): VendorInvoiceDetails|null {
            if (state.invoice?.type === InvoiceType.Contracted && state.invoice?.sub_type === InvoiceSubType.VendorInvoice) {
                return state.invoice
            } else {
                return null
            }
        },
        getNonContractedInvoiceDetails (state: InvoiceViewStoreState): NonContractedInvoiceDetails|null {
            if (state.invoice?.type === InvoiceType.NonContracted) {
                return state.invoice
            } else {
                return null
            }
        },

        isMyInvoiceDetailsFilled (state: InvoiceViewStoreState): boolean {
            return Boolean(state.invoice.invoice_date && state.invoice.start_date && state.invoice.end_date)
        },

        isVendorDetailsFilled (state: InvoiceViewStoreState): boolean {
            return Boolean(state.invoice.vendor
                && state.invoice.start_date
                && state.invoice.end_date)
        },

        isNonContractedDetailsFilled (state: InvoiceViewStoreState): boolean {
            return Boolean(state.invoice.vendor?.id
                && state.invoice.end_date
                && state.invoice.invoice_date
                && state.invoice.paid_by
                && state.invoice.category_name)
        },

        isDetailsFilled (state: InvoiceViewStoreState): boolean {
            if (!state.creating && !state.invoice.id) {
                return true
            }

            if (this.isMyInvoice) {
                return this.isMyInvoiceDetailsFilled
            }

            if (this.isVendorInvoice) {
                return this.isVendorDetailsFilled
            }

            if (this.isNonContractedInvoice) {
                return this.isNonContractedDetailsFilled
            }

            return true
        },

        hasVendorInvoices (state: InvoiceViewStoreState): boolean {
            return Boolean(state.vendorsInvoices?.length || state.invoice?.attached_invoices?.length)
        },

        percentsOfInvoiceApplied (state: InvoiceViewStoreState): number {
            const invoiceSoV = invoiceSoVStore()
            if (state.appliedFundingAmount && invoiceSoV.netInvoiceValue) {
                return (state.appliedFundingAmount / invoiceSoV.netInvoiceValue) * 100
            }
            return 0
        },

        isEqualAppliedFundToInvoiceValue (state: InvoiceViewStoreState): boolean {
            const invoiceSoV = invoiceSoVStore()

            if (invoiceSoV.contractWBS.length || invoiceSoV.changeOrdersWBS.length || invoiceSoV.nonContractedLineItems.length) {
                return state.appliedFundingAmount === invoiceSoV.netInvoiceValue
            } else if (state.invoice.id) {
                return state.appliedFundingAmount === state.invoice.net_invoice_amount
            } else {
                return true
            }
        },

        appliedFundingAmount (state: InvoiceViewStoreState): number {
            return state.appliedFundingSources.filter(item => item.id).reduce((accumulator, currentValue) => accumulator + currentValue.covered_amount, 0)
        },

        getInvoiceType (state: InvoiceViewStoreState): string {
            return state.creating || state.invoice.type
        },

        isEditingMode (state: InvoiceViewStoreState): boolean {
            return Boolean(state.creating || state.editing)
        },

        invoiceDetailsIsChanged (state: InvoiceViewStoreState): boolean {
            const invoiceSoV = invoiceSoVStore()

            if (this.isMyInvoice) {
                return this.myInvoiceDetailsIsChanged || invoiceSoV.sovHasChanges
            }

            if (this.isVendorInvoice) {
                return this.vendorInvoiceDetailsIsChanged || invoiceSoV.sovHasChanges
            }

            if (this.isNonContractedInvoice) {
                return this.nonContractedInvoiceDetailsIsChanged || invoiceSoV.sovHasChanges
            }

            return false
        },

        myInvoiceDetailsIsChanged (state: InvoiceViewStoreState): boolean {
            return (
                state.invoice.invoice_date !== state.initialInvoiceData.invoice_date
                || state.invoice.invoice_unique_number !== state.initialInvoiceData.invoice_unique_number
                || state.invoice.start_date !== state.initialInvoiceData.start_date
                || state.invoice.end_date !== state.initialInvoiceData.end_date
            )
        },

        vendorInvoiceDetailsIsChanged (state: InvoiceViewStoreState): boolean {
            return (
                state.invoice.vendor?.id !== state.initialInvoiceData.vendor?.id
                || state.invoice.invoice_unique_number !== state.initialInvoiceData.invoice_unique_number
                || state.invoice.start_date !== state.initialInvoiceData.start_date
                || state.invoice.end_date !== state.initialInvoiceData.end_date
            )
        },

        nonContractedInvoiceDetailsIsChanged (state: InvoiceViewStoreState): boolean {
            return (
                state.invoice.vendor?.id !== state.initialInvoiceData.vendor?.id
                || state.invoice.invoice_unique_number !== state.initialInvoiceData.invoice_unique_number
                || state.invoice.invoice_date !== state.initialInvoiceData.invoice_date
                || state.invoice.end_date !== state.initialInvoiceData.end_date
                || state.invoice.start_date !== state.initialInvoiceData.start_date
                || state.invoice.paid_by !== state.initialInvoiceData.paid_by
                || state.invoice.category_name !== state.initialInvoiceData.category_name
            )
        },

        isClientOnSystem (state: InvoiceViewStoreState): boolean {
            return state.invoice.client_approval?.client ? !state.invoice.client_approval.client.off_system : state.invoice.on_system
        },

        clientRequestedRevision (state: InvoiceViewStoreState): boolean {
            return InvoiceStatus.RequestedRevision === state.invoice.status && InvoiceSubType.MyInvoice === state.invoice.sub_type
        },

        invoiceInternallyRejected (state: InvoiceViewStoreState): boolean {
            return InvoiceStatus.InternallyRejected === state.invoice.status
        },

        invoiceRejectedByClient (state: InvoiceViewStoreState): boolean {
            return InvoiceStatus.RejectedByClient === state.invoice.status
        },

        invoiceIsApproved (state: InvoiceViewStoreState): boolean {
            return [
                InvoiceStatus.ApprovedByClient,
                InvoiceStatus.SubmittedToAccounting,
                InvoiceStatus.RejectedByAccounting,
                InvoiceStatus.PartiallyPaid,
                InvoiceStatus.Paid
            ].includes(state.invoice.status)
        },

        invoiceIsDraft (state: InvoiceViewStoreState): boolean {
            return InvoiceStatus.Draft === state.invoice.status || state.creating
        },

        isMyInvoice (state: InvoiceViewStoreState): boolean {
            return InvoiceSubType.MyInvoice === state.invoice.sub_type || InvoiceSubType.MyInvoice === state.creating
        },

        isVendorInvoice (state: InvoiceViewStoreState): boolean {
            return InvoiceSubType.VendorInvoice === state.invoice.sub_type || InvoiceSubType.VendorInvoice === state.creating
        },

        isNonContractedInvoice (state: InvoiceViewStoreState): boolean {
            return InvoiceSubType.NonContractedInvoice === state.invoice.sub_type || InvoiceSubType.NonContractedInvoice === state.creating
        },

        isProvidedManually (state: InvoiceViewStoreState): boolean {
            return state.invoice.is_provided_manually
        },

        getInvoiceContractId (state: InvoiceViewStoreState): string {
            return state.invoice.contract_id || ''
        },

        getCostCodes (): CostCode[] {
            const invoiceSoV = invoiceSoVStore()
            if (this.isNonContractedInvoice) {
                return invoiceSoV.getLineItemsCostCodes
            }

            return invoiceSoV.getSoVCostCodes
        },

        canAddressRevision (): boolean {
            return this.availableActions.some(item => InvoiceActions.AddressRevision === item.name && item.available)
        },

        canRequestRevision (): boolean {
            return this.availableActions.some(item => InvoiceActions.RequestRevision === item.name && item.available)
                && !this.isEditingMode
        },

        invIsNotApproved (state: InvoiceViewStoreState): boolean {
            return [
                InvoiceStatus.Draft,
                InvoiceStatus.PendingInternalApproval
            ].includes(state.invoice.status)
        },

        isClient (state: InvoiceViewStoreState): boolean {
            return ['receiver', 'receiver-sender', 'sender-receiver'].includes(state.invoice.role)
        },

        isVendor (state: InvoiceViewStoreState): boolean {
            return ['sender', 'receiver-sender', 'sender-receiver'].includes(state.invoice.role)
        },

        adjustedLinesCount (): number {
            const invoiceSoV = invoiceSoVStore()

            const withRevision = invoiceSoV.contractWBS.filter(item => item.adjustments)
            const coWithRevision = invoiceSoV.changeOrdersWBS.filter(item => item.adjustments)

            return withRevision.length + coWithRevision.length
        }
    },
    actions: {
        setDataLoadingValue (value: boolean = false): void {
            this.dataLoading = value
        },

        setCreationType (type: InvoiceSubType) : void {
            this.creating = type
        },

        setEditModeValue (value: boolean): void {
            this.editing = value
        },

        setInvoiceNumber (number: string): void {
            this.invoice.invoice_number = number
        },

        setAttachedTemporaryDocuments (attachedTemporaryDocuments: object[]): void {
            this.attachedTemporaryDocuments = [...attachedTemporaryDocuments]
        },

        async fetchInvoiceDetails (projectId: string, invoiceId: string): Promise<void> {
            const { data } = await invoiceViewClient.getInvoiceDetails(projectId, invoiceId)
            if (data) {
                this.invoice = data
                this.initialInvoiceData = structuredClone(data)
                this.setAttachedTemporaryDocuments(data.invoice_attachments)
                if (InvoiceType.NonContracted === this.invoice.type) {
                    const invoiceSoV = invoiceSoVStore()
                    const nonContractedLineItems = data.general_invoiceable_items?.map(item => {
                        return  { ...item, total: item.unit_price * (item.quantity/process.env.SCALE_FACTOR) }
                    }).sort((a, b) => a.order - b.order)
                    invoiceSoV.nonContractedLineItems = nonContractedLineItems
                    invoiceSoV.initialNonContractedLineItems = nonContractedLineItems
                    this.sites = data.sites?.map(item => ({ project_site_id: item.id, title: item.name }))
                } else {
                    this.invoiceLienWaivers = data.lien_waivers
                }
            }
        },

        async fetchInvoiceFinancialInfo (projectId: string, invoiceId: string): Promise<void> {
            const { data } = await invoiceViewClient.getInvoiceFinancialInfo(projectId, invoiceId)
            if (data) {
                this.invoiceFinancialInfo = data
            }
        },

        async fetchInvoiceGrossBillingSummary (projectId: string, invoiceId: string): Promise<void> {
            const { data } = await invoiceViewClient.getInvoiceGrossBillingSummary(projectId, invoiceId)
            if (data) {
                this.invoiceGrossBillingSummary = data
            }
        },

        async fetchInvoiceContractSummary (projectId: string, invoiceId: string): Promise<void> {
            const { data } = await invoiceViewClient.getInvoiceContractSummary(projectId, invoiceId)
            if (data) {
                this.invoiceContractSummary = data
            }
        },

        async fetchApplicableFundingSources (projectId: string, invoiceId: string): Promise<void> {
            if (this.fundingSources.length) {
                return
            }
            const invoiceSoV = invoiceSoVStore()
            const netInvoiceValue = invoiceSoV.netInvoiceValue
            if (0 === netInvoiceValue) {
                return
            }
            const { data } = await invoiceViewClient.getApplicableFundingSources(projectId, netInvoiceValue, this.getCostCodes, invoiceId)

            if (data) {
                this.fundingSources = data
            }
        },

        async fetchInvoiceFundingSources (invoiceId: string, projectLocalId: string): Promise<void> {
            this.appliedFundingSources = []
            const { data } = await invoiceViewClient.getInvoiceFundingSources(invoiceId, projectLocalId)

            if (data) {
                this.appliedFundingSources = data
            }
        },

        setInvoiceContractId (id: string): void {
            this.invoice.contract_id = id
        },

        setInvoiceClientData (data: object): void {
            this.invoice.client = data
        },

        setInvoiceDateToday (): void {
            this.invoice.invoice_date = dayjs(new Date()).format('YYYY-MM-DD')
        },

        async fetchVendorsInvoices (projectMongoIdLocal: string): Promise<void> {
            const { data } = await invoiceCreationClient.getAttachableVendorInvoices(projectMongoIdLocal)
            this.vendorsInvoices = data
        },

        setVendorInvoices (data: Invoice[]): void {
            this.vendorsInvoices = data.map(item => {
                return { ...item, attached: false }
            })
        },

        async loadEditingDetails (projectLocalID: string): Promise<void> {
            if (this.isMyInvoice) {
                const { data } = await invoiceEditingClient.getMyInvoiceEditingData(projectLocalID, this.invoice.id)
                if (data) {
                    if (data.attach_invoice_available && data.vendor_invoices && data.vendor_invoices.length) {
                        this.setVendorInvoices(data.vendor_invoices)
                    }

                    this.setAppPackages(data.application_packages)
                    this.setLienWaiverTemplate(data)
                }
            } else if (this.isVendorInvoice) {
                const { data } = await invoiceEditingClient.getVendorInvoiceEditingData(projectLocalID, this.invoice.id)
                if (data) {
                    this.setAppPackages(data.application_packages)
                    this.setLienWaiverTemplate(data)
                }
            } else if (this.isNonContractedInvoice) {
                const { data } = await invoiceEditingClient.getNonContractedInvoiceEditingData(projectLocalID, this.invoice.id)
                if (data) {
                    this.setAppPackages(data.application_packages)
                }
            }
        },

        clearAllInvoiceStoreData (): void {
            this.fundingSources = []
            this.creating = null
            this.editing = false
            this.invoice = {
                invoice_date: '',
                start_date: '',
                end_date: '',
                description: '',
            }
            this.fundingSources = []
            this.appliedFundingSources = []
            this.invoiceLienWaivers = []
            const invoiceSoV = invoiceSoVStore()
            invoiceSoV.clearSoVStore()
            this.clientApprovalSetting = ''
            this.projectBudgetCostCodes = []
            this.attachedTemporaryDocuments = []
            this.accountingErrors = null
            this.activeAccountingIntegration = null
            this.availableActions = []
            this.sites = null
            this.draftAppPackages = null
            this.lienWaiverTemplate = null
        },

        setProjectBudgetCostCodes (data: Category[], siteId: string): void {
            if (siteId) {
                this.projectBudgetCostCodesSites[siteId] = data
            } else {
                this.projectBudgetCostCodes = data
            }
        },

        async saveInvoice (projectMongoIdLocal: string, invoiceId: string = ''): Promise<string | void> {
            if(this.getInvoiceType === InvoiceSubType.NonContractedInvoice) {
                return await this.saveNonContractedInvoice(projectMongoIdLocal, invoiceId)
            } else {
                return await this.saveContractedInvoice(projectMongoIdLocal, invoiceId)
            }
        },
        /** Saves contracted invoices */
        async saveContractedInvoice (projectMongoIdLocal: string, invoiceId: string = ''): Promise<string | void> {
            if (InvoiceType.Contracted === this.invoice.type) {
                if (InvoiceSubType.MyInvoice === this.invoice.sub_type) {
                    return await this.saveContractedMyInvoice(projectMongoIdLocal, invoiceId)
                } else if (InvoiceSubType.VendorInvoice === this.invoice.sub_type) {
                    return await this.saveContractedVendorInvoice(projectMongoIdLocal, invoiceId)
                }
            } else {
                throw Error('Invalid invoice type')
            }
        },

        /** Applies funding, used by owners and reps */
        async applyFunding (projectMongoIdLocal: string, invoiceId: string): AxiosPromise {
            const request: ApplyFundingRequest = {
                funds: this.appliedFundingSources.filter(item => item.id)
            }
            return await invoiceEditingClient.applyFunding(projectMongoIdLocal, invoiceId, request)
        },

        /** Saves my invoice */
        async saveContractedMyInvoice (projectMongoIdLocal: string, invoiceId: string = ''): Promise<string | void> {
            if (InvoiceType.Contracted === this.invoice.type && InvoiceSubType.MyInvoice === this.invoice.sub_type) {
                const invoiceSoV = invoiceSoVStore()
                if (SoVTableViewMode.COsWithinOriginalScope === invoiceSoV.tableViewMode) {
                    invoiceSoV.separateCOsFromOriginalScope()
                }

                let wbsToSave = invoiceSoV.invoiceWBSLoaded ? this.processWbsToSave([...invoiceSoV.contractWBS, ...invoiceSoV.changeOrdersWBS]) : null
                if (invoiceId) {
                    const invoiceData: UpdateMyInvoiceRequest = {
                        type: this.invoice.type,
                        sub_type: this.invoice.sub_type,
                        start_date: this.invoice.start_date,
                        end_date: this.invoice.end_date,
                        invoice_unique_number: this.invoice.invoice_unique_number,
                        invoice_date: this.invoice.invoice_date,
                        description: this.invoice.description,
                        wbs: wbsToSave,
                        attached_temporary_document_ids: this.attachedTemporaryDocuments.map(item => item.id || item._id),
                        funds: this.appliedFundingSources.filter(item => item.id),
                        attached_invoices: this.attachedInvoices,
                        contract_id: this.invoice.contract_id,
                        lien_waivers: this.invoiceLienWaivers.map(item => {
                            if (item.attached_document_ids) {
                                return { ...item, attached_document_ids: item.attached_document_ids?.map(file => file.id || file._id) }
                            } else {
                                return item
                            }
                        }),
                        application_package_id: this.invoice?.application_package?.id
                    }
                    await invoiceEditingClient.saveMyInvoice(projectMongoIdLocal, invoiceData, invoiceId)
                } else {
                    const invoiceData: CreateMyInvoiceRequest = {
                        type: this.invoice.type,
                        sub_type: this.invoice.sub_type,
                        start_date: this.invoice.start_date,
                        end_date: this.invoice.end_date,
                        invoice_unique_number: this.invoice.invoice_unique_number,
                        invoice_date: this.invoice.invoice_date,
                        contract_id: this.invoice.contract_id,
                        status: this.invoice.status,
                        description: this.invoice.description,
                        wbs: wbsToSave,
                        attached_temporary_document_ids: this.attachedTemporaryDocuments.map(item => item.id || item._id),
                        funds: this.appliedFundingSources.filter(item => item.id),
                        vendor_id: this.invoice.vendor?.id,
                        lien_waivers: this.invoiceLienWaivers.map(item => {
                            if (item.attached_document_ids) {
                                return { ...item, attached_document_ids: item.attached_document_ids?.map(file => file.id || file._id) }
                            } else {
                                return item
                            }
                        }),
                        attached_invoices: this.attachedInvoices,
                        application_package_id: this.invoice?.application_package?.id
                    }

                    if (this.hasAccountingIntegration) {
                        invoiceData.retention_invoice = this.invoice.retention_invoice
                    }
                    const { data } = await invoiceCreationClient.createMyInvoice(projectMongoIdLocal, invoiceData)

                    return data.id
                }
            } else {
                throw Error('Invalid invoice type')
            }
        },

        processWbsToSave (wbs: SoVLineItem[]|null): SoVLineItem[] {
            if (!wbs) {
                return wbs
            }
            return wbs.map(item => {
                if (item.children) {

                }
                return {
                    ...item,
                    revised_by_vendor: item.revisedByVendor,
                    children: this.processWbsToSave(item.children)
                }
            })
        },

        /** Saves vendor invoice  */
        async saveContractedVendorInvoice (projectMongoIdLocal: string, invoiceId: string = ''): Promise<string | void> {
            if ( InvoiceSubType.VendorInvoice === this.invoice.sub_type) {
                const invoice = this.invoice as VendorInvoiceDetails
                const invoiceSoV = invoiceSoVStore()
                if (SoVTableViewMode.COsWithinOriginalScope === invoiceSoV.tableViewMode) {
                    invoiceSoV.separateCOsFromOriginalScope()
                }

                let wbsToSave = invoiceSoV.invoiceWBSLoaded ? [...invoiceSoV.contractWBS, ...invoiceSoV.changeOrdersWBS] : null
                if (invoiceId) {
                    const invoiceData: UpdateVendorInvoiceRequest = {
                        type: invoice.type,
                        sub_type: invoice.sub_type,
                        start_date: invoice.start_date,
                        end_date: invoice.end_date,
                        invoice_unique_number: this.invoice.invoice_unique_number,
                        invoice_date: invoice.invoice_date,
                        description: this.invoice.description,
                        wbs: wbsToSave,
                        attached_temporary_document_ids: this.attachedTemporaryDocuments.map(item => item.id || item._id),
                        funds: this.appliedFundingSources.filter(item => item.id),
                        lien_waivers: this.invoiceLienWaivers.map(item => {
                            if (item.attached_document_ids) {
                                return { ...item, attached_document_ids: item.attached_document_ids?.map(file => file.id || file._id) }
                            } else {
                                return item
                            }
                        }),
                        application_package_id: this.invoice?.application_package?.id
                    }
                    await invoiceEditingClient.saveVendorInvoice(projectMongoIdLocal, invoiceData, invoiceId)
                } else {
                    const invoiceData: CreateVendorInvoiceRequest = {
                        type: invoice.type,
                        sub_type: invoice.sub_type,
                        start_date: invoice.start_date,
                        end_date: invoice.end_date,
                        invoice_unique_number: this.invoice.invoice_unique_number,
                        invoice_date: invoice.invoice_date,
                        contract_id: invoice.contract_id,
                        status: invoice.status,
                        description: this.invoice.description,
                        wbs: wbsToSave,
                        attached_temporary_document_ids: this.attachedTemporaryDocuments.map(item => item.id || item._id),
                        funds: this.appliedFundingSources.filter(item => item.id),
                        vendor_id: invoice.vendor?.id,
                        lien_waivers: this.invoiceLienWaivers.map(item => {
                            if (item.attached_document_ids) {
                                return { ...item, attached_document_ids: item.attached_document_ids?.map(file => file.id || file._id) }
                            } else {
                                return item
                            }
                        }),
                        application_package_id: this.invoice?.application_package?.id
                    }
                    if (this.hasAccountingIntegration) {
                        invoiceData.retention_invoice = this.invoice.retention_invoice
                    }
                    const { data } = await invoiceCreationClient.createVendorInvoice(projectMongoIdLocal, invoiceData)
                    return data.id
                }
            } else {
                throw Error('Invalid invoice type')
            }
        },
        async saveNonContractedInvoice (projectMongoIdLocal: string, invoiceId: string = ''): Promise<string | void> {
            const invoiceSoV = invoiceSoVStore()

            if (InvoiceType.NonContracted === this.invoice.type) {
                const siteIds = this.sites?.map(item => item.project_site_id)
                const lineItems = invoiceSoV.nonContractedLineItems.filter((item) => item.description && (siteIds && siteIds.includes(item.site_id) || !siteIds))
                    .map((item, index) => {
                        const data = {
                            quantity: item.quantity,
                            unit_price: item.unit_price,
                            cost_code_id: item.cost_code_id || item.cost_code?.id,
                            description: item.description,
                            site_id: item.site_id,
                            order: index + 1,
                        }

                        if (item.id) {
                            data.id = item.id
                        }

                        return data
                    }).sort((a, b) => a.order - b.order) || []

                if (invoiceId) {
                    const invoiceData: UpdateNonContractedInvoiceRequest = {
                        start_date: this.invoice.start_date,
                        end_date: this.invoice.end_date,
                        invoice_unique_number: this.invoice.invoice_unique_number,
                        invoice_date: this.invoice.invoice_date,
                        vendor_id: this.invoice.vendor?.id,
                        paid_by: this.invoice.paid_by,
                        category_name: this.invoice.category_name,
                        description: this.invoice.description,
                        site_ids: siteIds,
                        attached_temporary_document_ids: this.attachedTemporaryDocuments.map(item => item.id || item._id),
                        funds: this.appliedFundingSources.filter(item => item.id),
                        general_invoiceable_items: lineItems,
                        application_package_id: this.invoice?.application_package?.id
                    }

                    await invoiceEditingClient.saveNonContractedInvoice(projectMongoIdLocal, invoiceData, invoiceId)
                } else {
                    const invoiceData: CreateNonContractedInvoiceRequest = {
                        type: this.invoice.type,
                        sub_type: this.invoice.sub_type,
                        start_date: this.invoice.start_date,
                        end_date: this.invoice.end_date,
                        invoice_unique_number: this.invoice.invoice_unique_number,
                        status: this.invoice.status,
                        invoice_date: this.invoice.invoice_date,
                        vendor_id: this.invoice.vendor?.id,
                        paid_by: this.invoice.paid_by,
                        category_name: this.invoice.category_name,
                        description: this.invoice.description,
                        site_ids: siteIds,
                        attached_temporary_document_ids: this.attachedTemporaryDocuments.map(item => item.id || item._id),
                        funds: this.appliedFundingSources.filter(item => item.id),
                        general_invoiceable_items: lineItems,
                        application_package_id: this.invoice?.application_package?.id
                    }

                    const { data } = await invoiceCreationClient.createNonContractedInvoice(projectMongoIdLocal, invoiceData)
                    return data.id
                }
            } else {
                throw Error('Invalid invoice type')
            }
        },
        setSelectedForCreationContract (contract: ExecutedContracts): void {
            if (!contract) {
                return
            }

            this.invoice.vendor = contract
            this.invoice.contract_id = contract.id
        },

        async setAvailableActions (projectMongoIdLocal: string, invoiceId: string = undefined): Promise<void> {
            const { data } = await invoiceEditingClient.getInvoiceAvailableActions(projectMongoIdLocal, invoiceId)
            this.availableActions = data
        },

        async checkSubmitToAccounting (projectMongoIdLocal: string, invoiceId: string = undefined): Promise<void> {
            if (this.invoice.id) {
                const { data } = await invoiceViewClient.canSubmitToAccountingInfo(projectMongoIdLocal, invoiceId)
                this.accountingErrors = data
            }
        },

        async updateInvoiceDetailsActions (projectMongoIdLocal: string, routeName: string, invoiceId: string): Promise<void> {
            await this.setAvailableActions(projectMongoIdLocal, invoiceId)
        },

        setAttachedInvoices (invoicesIDs: string[]): void {
            this.attachedInvoices = invoicesIDs
        },
        setRevisionProvided (value: boolean): void {
            this.revisionProvided = value
        },
        toggleRequestRevisionModal (): void {
            this.showRequestRevisionModal = !this.showRequestRevisionModal
        },
        async getActiveAccountingIntegration (projectLocalID: string): void {
            const { data } = await invoiceViewClient.getAccountingIntegration()
            this.activeAccountingIntegration = data
            if (this.hasAccountingIntegration && !this.invoice.id) {
                this.invoice.retention_invoice = false
            }
        },
        setClientApprovalsSetting (value: string): void {
            this.clientApprovalSetting = value
        },

        setApplyingFunding (value: boolean): void {
            this.applyingFunding = value
        },

        setUniqueNumberValid (value: boolean): void {
            this.uniqueNumberIsValid = value
        },

        setInvAmountWithTax (value: number): void {
            this.invAmountWithTax = value
        },

        setSites (sites: ProjectSite[]): void {
            this.sites = sites
        },

        setAppPackages (appPackages: DraftAppPackage[]): void {
            this.draftAppPackages = appPackages
        },

        setLienWaiverTemplate (data: object): void {
            if (data.contract_lien_waiver_document_ids && Object.values(data.contract_lien_waiver_document_ids).length > 0 && this.invoice.contract_id) {
                this.lienWaiverTemplate = data.contract_lien_waiver_document_ids[this.invoice.contract_id]
            }
        }
    },
})
