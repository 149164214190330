export default {
    loaded: false,
    project_cost_codes_list: null,
    rep_project_cost_codes_list: null,
    companyId: null,
    companySharedId: null,
    projectMongoLocalId: null,

    projectObj: {},
    projectMongoLocal: {},
    basic: {},
    settings: {},
    proposalObj: {},
    items: [],
    codes: {},
    categories: [],
    history: [],
    activeColumns: [],
    sharedBudgets: [],
    view_only: true,
    expandedItems: [],
    sharedBudgetCodeTemplate: {},

    // Budget multi-currency
    currencyBase: '',
    currencySelectedRates: [],
    currencySelectedConversion: null,
    attachedFundingSources: [],

    // budget changes
    changes: [],
    changesParsedIds: [],
    currentChange: null,
    currentChangeAccountingMessages: [],
    currentChangeCanBeExportedToAccounting: false,
    changeStatuses: [],
    change_reason_categories: [],
    changesLoaded: false,

    changesFilters: null,
    is_service_account: null,
    can_edit_special_functions: false,

    // Budget configuration
    layouts: [],
    units: [],
    clientCodes: [],
    costCodes: [],
    costCodesProject: [],

    external_accounting_cost__code_categories_with_codes: [],
    external_accounting_cost_codes: [],
    accounting_cost_codes_count: null,
    accounting_entities_count: null,
    analytic_cost_codes: [],

    integrations: [],
    allCostCodesHaveAccountingCodes: true,
    budgetCostCodeTemplateId: null,

    loadedConfiguration: false,
    loadedLists: false,

    /**
     * Budget line items
     */
    shared_project_cost_codes_lists: [],
    project_cost_codes_lists: [],
    project_cost_codes_lists_used: [],
    budget_line_item_lists: [],
    bid_questions_lists: [],

    quoted_item_lines_lists: [],
    quoted_item_lines_lists_settings: [],
    quoted_item_lines_templates: [],

    custom_fields_lists: [],
    meeting_minutes_lists: [],
    meeting_minutes_templates: [],
    task_workflows_lists: [],
    conversionsTemplates: [],
    currencies: [],
    dictionaries: [],

    modalAnticipated: {
        item: null,
        type: null
    },

    rfp_to_types: [],

    // Item data
    itemVendorList: [],

    letter_of_intent_templates: [],
    approval_workflow_templates: [],
    lien_waiver_templates: [],
    contract_document_templates: [],

    budgetType: null,

    budgetIconTitleColumn: {
        title: null,
        icon: null
    },

    budgetTypeSharedSelected: null,
    isSingleMasterBudgetMode: false,
    accounting_vendors_count: null,
    sync_history_count: null,

    /**
     * Insurance Requirements
     */
    insuranceRequirementsTemplates: [],

    /**
     * Budget Columns
     */
    budgetCategoryColumns: [],
    budgetSubcategoryColumns: [],
    budgetColumns: [],

    showRejectedBudgetPhases: true,
    phasesViewType: 'list',

    /**
     * Task Templates
     */
    company_task_template_lists_count: null,
    company_task_templates_count: null,

    sharedPhase: null,

    /**
     * Meeting Templates
     */
    meeting_templates_count: null,

    /**
     * Inspections Templates
     */
    inspections_templates_count: null,

    /**
     * Punch Stamps Templates
     */
    punch_stamps_templates_count: null,

    approved_from_client : false,
}
