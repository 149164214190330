export function getMainDomain (): string {
    const hostname = window.location.hostname
    const parts = hostname.split('.')
    const toSlice = (parts.length - 1) * -1
    return parts.slice(toSlice).join('.')
}

export function getAuthDomain (): string {
    return 'auth.' + getMainDomain()
}

export function getAuthUrl (): string {
    return '//' + getAuthDomain()
}

export function getSoketiDomain (): string {
    return 'io-broadcast.' + getMainDomain()
}
