import { defineComponent, PropType } from 'vue'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus'

export default defineComponent({
    name: 'SingleApprovalStatus',
    props: {
        status: { type: Object as PropType<ApprovalStatus>, required: true },
        date: { type: String, required: true },
        approvedAtText: { type: String, required: true },
        disableViewNote: { type: Boolean, required: false, default: false },
    },
    data () {
        return {
            ApprovalStatus,
        }
    },
    methods: {
        handleViewNote (): void {
            this.$emit('viewNoteClick')
        }
    },
})
