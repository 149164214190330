import { get } from 'lodash'
import initials from '@/components/new-theme/initials'
import DonutChart from '@/components/atoms/DonutChart/DonutChart.vue'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import { mapGetters } from 'vuex'
import { getAuthUrl } from '@/helpers/urls'

export default {
    name: 'profile',
    components: {
        initials,
        DonutChart
    },
    mixins: [isLoggedMixin],
    data () {
        return {
            showProfile: false,
            storageInfo: null
        }
    },
    computed: {
        ...mapGetters('storage', [
            'getRecalculateUsedStorage'
        ]),
        ...mapGetters('appStore', [
            'getAuthData'
        ]),
        authData () {
            return this.getAuthData
        },
        storage () {
            const usedStorage = get(this.storageInfo, 'used', 0)
            const totalStorage = get(this.storageInfo, 'total', 0)
            const usedStoragePercent = Math.round((usedStorage / totalStorage) * 100)
            const chartColor = usedStoragePercent > 70 ? 'var(--error-lighten)' : 'var(--accent)'

            return { usedStorage, totalStorage, usedStoragePercent, chartColor }
        }
    },
    watch: {
        getRecalculateUsedStorage () {
            this.getStorageInfo()
        }
    },
    methods: {
        toggleShowProfile () {
            this.showProfile = !this.showProfile
        },
        closeProfile () {
            this.showProfile = false
        },
        async logout () {
            try {
                await this.$api.post('/logout', {})
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'), null)
            }

            localStorage.removeItem('contactsList')
            localStorage.removeItem('employeesList')
            localStorage.removeItem('projectsList')
            localStorage.removeItem('proposalsList')
            localStorage.removeItem('tasksgeneralList')
            localStorage.removeItem('appType')
            const appName = (window.location.host).split('.')[0]
            window.location.href = `${ getAuthUrl() }/logout/${ appName }`
        },
        async getStorageInfo () {
            const { data } = await this.$api.get('cloud-space-info')
            this.storageInfo = data
        }
    },
    beforeMount () {
        this.getStorageInfo()
    }
}
