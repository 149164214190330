import store from '@/store/index'
import financialApprovalsClient from '@/api/financial-approvals/financialApprovalsClient'

export default class EligibleUsersService {
    static async getSortedUsers (companyProjectResourceId: string): Promise<any> {
        const { data } = await financialApprovalsClient.getEligibleUsers(companyProjectResourceId)

        data.sort((a, b): number => {
            if (a.company_name === store.state.appStore.authData.u_company_name) {
                return -1
            }

            if (b.company_name === store.state.appStore.authData.u_company_name) {
                return 1
            }

            return a.company_name.localeCompare(b.company_name)
        })

        return data
    }
}