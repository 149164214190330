<template>
    <tr
        class="io-project-data-item"
        :class="[
            tableRowClass,
            {
                'io-is-row-active': isRowSelectedByRadio,
                'io-clickable-table-row': rowIsClickable,
                'io-is-disabled': isDisabled
            },
            ...customClassFunction(tableRow),
        ]"
        @click.middle.exact="onMiddleClick()"
        @click="onTableRowClick"
    >
        <template v-for="(column, index) in columns" :key="index">
            <td v-if="column.type === 'expand-column-icon'" class="io-column-icon-expand">
                <div v-if="getColumnAdditionalHTML(column)" class="io-expand-row" @click.stop="onClickToggleRow">
                    <span :class="isRowExpaneded ? 'icon-chevron-down': 'icon-chevron-right'"></span>
                </div>
            </td>
            <template v-if="columnIsVisibleInSelectedMode(column)">
                <template v-if="column.type === 'text'">
                    <td :style="{ maxWidth: calculateColumnsWidth() }" :class="column.columnClass">
                        <template v-if="isColumnEditable(column)">
                            <input :value="tableRow[column.field]" v-on:change.focus="() => { tableRow[column.field] = $event.target.value }"/>
                        </template>

                        <template v-else>
                            {{ filterField(column) }}
                            <span v-if="getColumnAdditionalHTML(column)" v-html="getColumnAdditionalHTML(column)" @click="() => onAdditionalHTMLClicked(column.field)"></span>
                            <GeneralStatusPill
                                v-if="additionalPill(column)"
                                :variant="column.additional.additionalPillVariant"
                                :text="column.additional.additionalPillText"
                                :key="column.field + generateUuid()"
                            />
                        </template>
                    </td>
                </template>

                <template v-if="column.type === 'date'">
                    <td :style="{ maxWidth: calculateColumnsWidth() }" :class="column.columnClass">
                        <template v-if="isColumnEditable(column)">
                            <field-date-time
                                v-model="tableRow[column.field]"
                                :editable="true"
                                :options="{ formatted: dateFormat(column), 'only-date': true, beforeUpdate: column.additional?.beforeUpdate?.(tableRow) }"
                            />
                        </template>

                        <template v-else>
                            {{ formatDate(column) }}
                        </template>
                    </td>
                </template>

                <template v-if="column.type === 'row'">
                    <ImageWithNameTableCell
                        :data="tableRow"
                        :column="column"
                        :key="column.field + generateUuid()"
                    />
                </template>

                <template v-if="column.type === 'text-icon'">
                    <TextIcon
                        :data="tableRow"
                        :column="column"
                        :key="column.field + generateUuid()"
                        @emitIconAction="emitIconAction"
                        @change-favorite="changeFavorite"
                    />
                </template>

                <template v-if="column.type === 'text-column'">
                    <TextColumn
                        :data="tableRow"
                        :column="column"
                        :key="column.field + generateUuid()"
                    />
                </template>

                <template v-if="column.type === 'icon-action'">
                    <IconAction
                        :data="tableRow"
                        :column="column"
                        :columnClass="column.columnClass"
                        :key="column.field + generateUuid()"
                        @emitIconAction="emitIconAction"
                    />
                </template>

                <template v-if="column.type === 'thumbnail'">
                    <Thumbnail
                        :data="tableRow"
                        :columnClass="column.columnClass"
                        :column="column"
                        :isLastRow="isLastRow"
                        :key="column.field + generateUuid()"
                    />
                </template>

                <template v-if="column.type === 'dropdown' || column.type === 'dropdown-new'">
                    <DropdownCell
                        :data="tableRow"
                        :column="column"
                        :key="column.field + generateUuid()"
                        :actions="column.additional.actions"
                        :new-interface="column.type === 'dropdown-new'"
                        @actionSelect="actionSelect"
                    />
                </template>

                <template v-if="column.type === 'pill'">
                    <template v-if="isColumnEditable(column)">
                        <DropdownStatusPill
                            :status="tableRow[column.field]"
                            :key="column.field + generateUuid()"
                            :options="column.additional.enabledOptions"
                            @update="(val) => { tableRow[column.field] = val }"
                        />
                    </template>

                    <template v-else>
                        <StatusPill
                            :status="tableRow[column.additional.header]"
                            :statusId="tableRow[column.additional.id]"
                            :tableRow="tableRow"
                            :column="column"
                            :customStatusText="column.additional.customStatusText"
                            :customStatusTextFunction="customStatusTextFunction"
                            :isTranslation="column.additional.isTranslation"
                            :key="column.field + generateUuid()"
                            :pillsColorFunction="pillsColorFunction"
                            :pillsTextFunction="pillsTextFunction"
                            :defaultClasses="column.additional.defaultClasses"
                            :showIconToolTip="showIconToolTip"
                        />
                    </template>
                </template>

                <template v-if="column.type === 'color-dot'">
                    <ColorDot
                        :column="column"
                        :className="column.columnClass"
                        :tableRow="tableRow"
                        :status="tableRow[column.additional.header]"
                        :isBoolean="tableRow[column.additional.isBoolean]"
                        :key="column.field + generateUuid()"
                        :isTranslation="column.additional.isTranslation"
                        :isDate="column.additional.isDate"
                        :dotsColorFunction="dotsColorFunction"
                    />
                </template>

                <template v-if="column.type === 'invite-members'">
                    <InviteMembers
                        :data="tableRow"
                        :column="column"
                        :key="column.field + generateUuid()"
                    />
                </template>

                <template v-if="column.type === 'stamp'">
                    <Stamp
                        :data="tableRow[column.field]"
                        :key="column.field + generateUuid()"
                    />
                </template>

                <template v-if="column.type === 'tags'">
                    <Tags
                        :data="tableRow[column.field]"
                        :key="column.field + generateUuid()"
                        :isTagObject="!!column.additional.header"
                        :additionalData="column.additional"
                    />
                </template>

                <template v-if="column.type === 'employee-list'">
                    <EmployeeList
                        :data="tableRow[column.field]"
                        :key="column.field + generateUuid()"
                    />
                </template>

                <template v-if="column.type === 'archive'">
                    <Archive
                        :data="tableRow"
                        :column="column"
                        :columnClass="column.columnClass"
                        :key="column.field + generateUuid()"
                        @emitIconAction="emitIconAction"
                    />
                </template>

                <template v-if="column.type === 'invitation-status'">
                    <InvitationStatus
                        :data="tableRow"
                        :column="column"
                        :columnClass="column.columnClass"
                        :key="column.field + generateUuid()"
                        @emitIconAction="emitIconAction"
                    />
                </template>
            </template>

            <template v-if="column.type === 'checkbox'">
                <Checkbox
                    :column="column"
                    :handle="tableRow.id"
                    :columnClass="column.columnClass"
                />
            </template>

            <template v-if="column.type === 'server-side-checkbox'">
                <ServerSideCheckbox
                    :column="column"
                    :data="tableRow"
                    :handle="tableRow.id"
                    :columnClass="column.columnClass"
                />
            </template>

            <template v-if="'radio' === column.type">
                <td :style="{ maxWidth: calculateColumnsWidth() }" :class="column.columnClass">
                    <Radio
                        :radioValue="column.field"
                        :inputName="column.field"
                        :value="isRowSelectedByRadio ? column.field : undefined"
                        v-model="rowSelectedByRadio"
                       @update:modelValue="onRadioClick"
                    />
                </td>
            </template>

            <template v-if="column.type === 'address'">
                <AddressColumn
                    :data="tableRow"
                    :column="column"
                    :key="column.field + generateUuid()"
                />
            </template>
            <template v-if="column.type === 'status-loading'">
                <StatusLoading
                    :status="tableRow[column.additional.status]"
                    :percentage="tableRow[column.additional.percentage]"
                    :column="column"
                    :key="column.field + generateUuid()"
                    :statusId="tableRow[column.additional.id]"
                    :tableRow="tableRow"
                    :customStatusText="column.additional.customStatusText"
                    :customStatusTextFunction="customStatusTextFunction"
                    :isTranslation="column.additional.isTranslation"
                    :pillsColorFunction="pillsColorFunction"
                    :pillsTextFunction="pillsTextFunction"
                    :defaultClasses="column.additional.defaultClasses"
                    :showIconToolTip="showIconToolTip"
                />
            </template>

            <template v-if="column.type === 'invitation-button'">
                <InvitationButton
                    :data="tableRow"
                    @inviteButtonClick="onInviteButtonClick"
                />
            </template>

            <template v-if="column.type === 'invite-contacts'">
                <InviteContacts
                    :data="tableRow"
                    :column="column"
                    :key="column.additional.invitationClientKey + generateUuid()"
                    :disable-bookmarking="disableBookmarking"
                />
            </template>

            <template v-if="column.type === 'single-contact'">
                <SingleContact :data="tableRow"/>
            </template>

            <template v-if="column.type === 'invitation-button-new'">
                <InvitationButtonNew
                    :data="tableRow"
                    @inviteButtonNewClick="onInviteButtonNewClick"
                />
            </template>
        </template>
    </tr>
</template>

<script lang="ts" src="./TableRow.ts"></script>
<style lang="scss" src="./TableRow.scss"></style>
