import { defineComponent, PropType } from 'vue'
import NonContractedLineItem from '@/io-modules/invoices/interfaces/NonContractedLineItem'
import { mapState } from 'pinia'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import BudgetCostCodeSelect from '@/components/budget-cost-code-select/BudgetCostCodeSelect.vue'
import type { CostCode, Category } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'

export default defineComponent({
    name: 'LineItemRow',
    components: {
        BudgetCostCodeSelect,
    },
    props: {
        item: {
            type: Object as PropType<NonContractedLineItem>,
            default: null
        },
        providedCostCodes: Object as PropType<Category[]>,
    },
    emits: ['removeItem'],
    computed: {
        ...mapState(invoiceViewStore, ['projectBudgetCostCodes', 'isEditingMode', 'dataLoading']),

        costCodeName (): string {
            return this.item.cost_code ? `${ this.item.cost_code.code || '' }: ${ this.item.cost_code.name }` : ''
        },

        lineQuantity (): number {
            return this.item.quantity/process.env.SCALE_FACTOR
        },
    },
    watch: {
        isEditingMode (): void {
            if (this.item.cost_code) {
                this.item.cost_code_id = this.item.cost_code.id
            }
        },
    },
    mounted () {
        if (this.item.cost_code) {
            this.item.cost_code_id = this.item.cost_code.id
        }
    },
    methods: {
        updateCostCode (id: string, data: CostCode): void {
            this.item.cost_code = data
            this.item.cost_code_id = id
        },

        updateQuantity (value: number): void {
            this.item.quantity = value
            this.item.total = (value/process.env.SCALE_FACTOR) * this.item.unit_price
        },

        updateCostValue (value: number): void {
            this.item.unit_price = value
            this.item.total = value * (this.item.quantity/process.env.SCALE_FACTOR)
        },
    },
})
