import Popup from '@/components/popup/Popup.vue'
import { mapGetters, mapState } from 'vuex'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import payAppDefinitions from '../../../Definitions/payAppDefinitions'

export default {
    name: 'ModalAddInvoiceOptions',
    components: {
        Popup
    },
    data: () => ({
        currentStep: null,
        selectedInvoice: null,
        selectedInvoiceSubType: null
    }),
    props: ['vendorsList'],
    mixins: [isLoggedMixin],

    created () {
        this.currentStep = this.STEPS.type
    },

    methods: {
        closeModal () {
            this.$store.dispatch('payapps/setModal', {
                modal: 'addInvoiceOptions',
                show: false
            })
        },

        goToNextStep () {
            if (this.selectedInvoice === 'retention' && this.currentStep === this.STEPS.type) {
                this.currentStep = this.STEPS.retentionSubtype
            }
        },

        goToPrevStep () {
            if (this.selectedInvoice === 'retention' && this.currentStep === this.STEPS.retentionSubtype) {
                this.currentStep = this.STEPS.type
            }
        },

        addMyInvoiceNew (modeVendor) {
            this.closeModal()
            this.$store.dispatch('payapps/modalInvoiceEnable', {
                type: modeVendor,
                subType: this.selectedInvoice === 'retention' ? this.selectedInvoiceSubType : null
            })
        },
        addInvoice () {
            if (this.selectedInvoice) {
                this.addMyInvoiceNew(this.selectedInvoice)
            }
        },
        selectInvoice (type) {
            this.selectedInvoice = type
        }
    },
    computed: {
        STEPS () {
            return payAppDefinitions.STEPS
        },

        RETENTION_TYPES () {
            return payAppDefinitions.RETENTION_TYPES
        },

        ...mapState('project', {
            projectObj: state => state.projectObj
        }),

        ...mapGetters('project', {
            projectHasContractExecuted: 'projectHasContract',
            projectHasInitialContractExecuted: 'projectHasInitialContractExecuted',
        }),

        showMyInvoiceOption () {
            return this.projectHasContractExecuted || this.projectHasInitialContractExecuted
        },

        showRetentionInvoiceOption () {
            return this.projectHasContractExecuted || Object.keys(this.vendorsList).length
        },
    }
}
