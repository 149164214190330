<template>
    <div
        v-if="editable"
        class="io-design-system input-group datetime icon-datetime io-input-date-holder"
    >
        <VueCtkDateTimePicker
            :id="id || pickerKey"
            ref="dateTimePicker"
            :key="pickerKey"
            :auto-close="settings['auto-close']"
            :disabled="disabled"
            :disabled-dates="settings['disabled-dates']"
            :first-day-of-week="startWeekDay"
            :format="settings.format"
            :formatted="settings.formatted"
            :inline="inline"
            :label="settings.placeholder"
            :max-date="settings['max-date']"
            :min-date="settings['min-date']"
            :minute-interval="minuteInterval"
            :name="name"
            :no-button-now="settings['no-button-now']"
            :no-header="settings['no-header']"
            :no-shortcuts="settings['no-shortcuts']"
            :no-value-to-custom-elem="settings['no-value-to-custom-elem']"
            :noClearButton="settings['noClearButton']"
            :only-date="settings['only-date']"
            :only-time="settings['only-time']"
            :overlay="settings['overlay']"
            :position="settings.position"
            :range="settings.range"
            :tabindex="tabindex"
            :locale="authData.user_language || 'en'"
            v-model="dateValue"
            @update:modelValue="onChangeDateValue"
            @is-hidden="datePickerHidden"
            @is-shown="datePickerShow"
            @validate="validate"
        >
            <template
                v-if="settings['no-value-to-custom-elem']"
                #default
            >
                <slot></slot>
            </template>
        </VueCtkDateTimePicker>
        <span v-if="showIcon && (!dateFieldValue || settings.noClearButton)">
            <i class="icon icon-calendar"></i>
        </span>
    </div>
    <p v-else-if="isParagraph">{{ staticDateValueFormatted }}</p>
    <span v-else>{{ staticDateValueFormatted }}</span>
</template>

<script lang="ts">
    import { defineComponent, nextTick } from 'vue'
    import { mapGetters } from 'vuex'
    import moment from 'moment'
    import { defaults, isFunction, isObject } from 'lodash'
    import { DateFormatConstans } from '@/constants/DateFormatConstans'
    import dateTimeHelper from '@/helpers/dateTime'

    interface RangeValue {
        start: string,
        end: string,
    }

    export default defineComponent({
        props: {
            id: {
                type: String,
                required: false,
                default: null,
            },
            modelValue: {
                type: [String, Object as () => RangeValue],
                required: false,
                default: null,
            },
            default: {
                type: String,
                required: false,
                default: null,
            },
            name: {
                type: String,
                required: false,
                default: null,
            },
            isParagraph: {
                type: Boolean,
                required: false,
                default: false,
            },
            editable: {
                type: Boolean,
                required: false,
                default: true,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            inline: {
                type: Boolean,
                required: false,
                default: false,
            },
            options: {
                type: Object,
                required: false,
                default: () => ({}),
            },

            tabindex: {
                type: Number,
                required: false,
                default: 0,
            },
            scrollIntoView: {
                type: Boolean,
                required: false,
                default: true,
            },
            enableOverflow: {
                type: Boolean,
                default: true,
            },
            showIcon: {
                type: Boolean,
                default: false,
            },
            keepDateOriginal: {
                type: Boolean,
                default: false,
            },
            useClientTimezone: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['update:modelValue', 'input', 'datePickerToggled', 'datePickerHidden', 'validate'],
        data () {
            return {
                pickerKey: null,
                duringUpdate: false,
                minuteInterval: 15,
                dateValue: null as RangeValue | string,
                pickerName: null,
                firstDayOfWeekMap: {
                    0: 7,
                    6: 6,
                    1: 1,
                },
                userTimezoneInMinute: moment().utcOffset(),
            }
        },
        methods: {
            async init () {
                if (this.modelValue === null && this.default !== null) {
                    this.modelValue = this.default
                }

                if (this.modelValue === null && this.settings.range) {
                    this.$emit('update:modelValue', {
                        start: null,
                        end: null,
                    })
                }

                if (this.options.minuteInterval) {
                    this.minuteInterval = parseInt(this.options.minuteInterval)
                }
                await nextTick()
                this.setDateValueBasedOnInputFormat()
            },
            setDateValueBasedOnInputFormat () {
                if (moment(this.modelValue, DateFormatConstans.API_FULL_DATE_FORMAT, true).isValid() || moment(this.modelValue, DateFormatConstans.API_ONLY_DATE_FORMAT, true).isValid()) {
                    if (this.settings.range) {
                        this.dateValue = {
                            start: this.convertDateToUserTimezone(this.modelValue.start)?.format(this.settings.format) ?? null,
                            end: this.convertDateToUserTimezone(this.modelValue.end)?.format(this.settings.format) ?? null,
                        }
                    } else {
                        this.dateValue = this.convertDateToUserTimezone(this.modelValue)?.format(this.settings.format) ?? null
                    }
                } else {
                    if (this.settings.range) {
                        this.dateValue = {
                            start: this.convertDateToUserTimezone(moment(this.modelValue.start)?.utc())?.format(this.settings.format) ?? null,
                            end: this.convertDateToUserTimezone(moment(this.modelValue.end)?.utc())?.format(this.settings.format) ?? null,
                        }
                    } else {
                        this.dateValue = this.convertDateToUserTimezone(moment(this.modelValue)?.utc())?.format(this.settings.format) ?? null
                    }
                }
            },
            onChangeDateValue (newDate) {
                if (!this.editable) {
                    return
                }

                let value = {
                    start: undefined,
                    end: undefined,
                } as string | RangeValue

                if (!newDate) {
                    value = this.emptyValue
                } else {
                    if (this.settings.range && isObject(newDate)) {
                        value = {
                            start: this.parseForReturn(newDate.start),
                            end: this.parseForReturn(newDate.end),
                        }
                    } else {
                        value = this.parseForReturn(newDate)
                    }
                }

                /**
                 * this.options.beforeUpdate should return a function returning a function as follows
                 * (tableRow) => (oldValue, newValue) => void
                 */
                if (this.options.beforeUpdate && isFunction(this.options.beforeUpdate)) {
                    /**
                     * @param previousValue
                     * @param newValue
                     * @param onRejectChange
                     */
                    this.options.beforeUpdate(this.modelValue, value, () => {
                        this.dateValue = this.modelValue
                    })

                    return
                }

                this.$emit('update:modelValue', value)
            },
            validate () {
                this.$emit('validate')
            },
            datePickerShow () {
                // to prevent datepicker being partially visible/covered
                if (this.enableOverflow) {
                    document.getElementsByTagName('body')[0].classList.add('io-overflow-visible')
                }

                if (this.scrollIntoView) {
                    this.$nextTick(() => {
                        if (this.$el.getElementsByClassName('datepicker').length > 0) {
                            this.$el.getElementsByClassName('datepicker')[0].scrollIntoView(
                                { behavior: 'smooth', block: 'center', inline: 'center' },
                            )
                        }
                    })
                }
                this.$emit('datePickerToggled', true)
            },
            datePickerHidden () {
                if (this.enableOverflow) {
                    document.getElementsByTagName('body')[0].classList.remove('io-overflow-visible')
                }

                this.$emit('datePickerHidden')
                this.$emit('datePickerToggled', false)
            },

            forceOpen () {
                this.$el.querySelector('.field-input').click()
            },

            setDefaultDateForTimeOnly (date) {
                if (!date) {
                    return null
                }
                return moment().set({
                    hour: moment(date, DateFormatConstans.TIME_ONLY_FORMAT).get('hour'),
                    minute: moment(date, DateFormatConstans.TIME_ONLY_FORMAT).get('minute'),
                    seconds: 0,
                }).utc().format(DateFormatConstans.API_FULL_DATE_FORMAT)
            },

            /**
             * Format date to return
             */
            parseForReturn (date?: string): null | string {
                if (!date) {
                    return null
                }

                if (this.keepDateOriginal) {
                    return date
                }
                if (this.settings['only-time']) {
                    return this.setDefaultDateForTimeOnly(date)
                }
                let value = null
                let momentObj = moment(date)
                if (momentObj.isValid()) {
                    // Set to UTC only if we set date AND time
                    // Otherwise, only set 12:00 to avoid day shifting
                    if (this.settings['only-date']) {
                        momentObj.set({ hour: 12, minute: 0 })
                    } else {
                        momentObj = momentObj.utc()
                    }
                    value = momentObj
                }
                return value?.format(this.settings.format)
            },
            convertDateToUserTimezone (date) {
                if (!moment(date).isValid()) {
                    return null
                }

                if (!this.useClientTimezone) {
                    return moment(date).utc()
                }

                return moment(date).utc().add(this.userTimezoneInMinute, 'minutes')
            },
        },
        computed: {
            ...mapGetters('appStore', {
                authData: 'getAuthData',
            }),
            settings () {
                let data = defaults(this.options || {}, {
                    'format': DateFormatConstans.API_FULL_DATE_FORMAT,
                    'formatted': dateTimeHelper.getDateFormat(),
                    'placeholder': `${ this.$t('Select Date') }...`,
                    'auto-close': false,
                    'only-date': true,
                    'range': false,
                    'only-time': false,
                    'noClearButton': false,
                    'disabled-dates': [],
                    'no-button-now': false,
                    'no-header': false,
                    'overlay': false,
                    'no-shortcuts': false,
                })

                // for single date picker enforce some settings
                if (data['only-date']) {
                    data['auto-close'] = true
                    data['no-button-now'] = true
                    data['no-header'] = true
                }

                if (data['only-time']) {
                    data.format = DateFormatConstans.TIME_ONLY_FORMAT
                }

                if (data['only-date']) {
                    data.format = DateFormatConstans.API_DATE_ONLY_FORMAT
                }

                if (!data['only-date'] && !data['only-time']) {
                    data.format = DateFormatConstans.API_FULL_DATE_FORMAT
                }

                let momentObj = moment.utc(data['min-date'], data.format)
                if (momentObj.isValid) {
                    data['min-date'] = momentObj.local().format(data.format)
                }
                return data
            },
            startWeekDay () {
                if (this.authData) {
                    return this.firstDayOfWeekMap[this.authData.start_week_on]
                } else {
                    return 1
                }
            },
            dateFieldValue () {
                return this.settings.range ? this.dateValue?.start : this.dateValue
            },
            emptyValue () {
                return this.settings.range ? { start: null, end: null } : null
            },
            staticDateValueFormatted () {
                if (!moment(this.dateValue).isValid()) {
                    return '-'
                }
                if (this.settings['only-date']) {
                    return moment(this.dateValue).format(this.settings.formatted)
                }
                if (this.settings['only-time']) {
                    return moment(this.dateValue).format(DateFormatConstans.TIME_ONLY_FORMAT)
                }
                return this.dateValue
            },
        },
        watch: {
            modelValue (newValue, oldValue) { //only for display for component
                if (newValue === 'Invalid Date' || !newValue) {
                    this.dateValue = this.emptyValue
                    return
                }
                if (this.settings.range) {
                    if (newValue?.start !== oldValue?.start && newValue?.end !== oldValue?.end) {
                        this.dateValue = {
                            start: this.convertDateToUserTimezone(newValue.start)?.format(this.settings.format),
                            end: this.convertDateToUserTimezone(newValue.end)?.format(this.settings.format),
                        }
                    }
                } else {
                    if (newValue !== oldValue) {
                        this.setDateValueBasedOnInputFormat()
                    }
                }
            },
        },

        /**
         * Get some settings and set date from API (in UTC) to local time
         */
        created () {
            this.init()

            // Set key for id
            this.pickerKey = crypto.randomUUID()
        },
    })
</script>

<style scoped>
    .io-overflow-visible {
        overflow: visible !important;
    }

    /* when button bar will be empty then empty space will not be displayed */
    .datepicker-buttons-container {
        padding: 0 !important;
    }

    .datepicker-buttons-container .datepicker-button {
        margin: 5px !important;
    }

    .icon {
        color: var(--main-lighten-3);
        position: absolute;
        top: 12px;
        right: 12px;
    }

    :deep(.date-time-picker) .field-input {
        font-size: 12px;
    }
</style>
