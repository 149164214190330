<template>
    <label
        class="io-design-system io-label"
        :class="`io-label--${ size } io-label--${ type }`"
    >
        <slot />

        {{ text }}

        <span v-if="isRequired" class="io-label__required" />

        <slot name="suffix" />
    </label>
</template>

<script lang="ts" src="./IOLabel.ts"></script>
<style lang="scss" src="./IOLabel.scss" scoped></style>
