<template>
    <div class="io-client-approval-card">
        <div class="io-client-approval-card__title">{{ title ? title : $t('Client Approval') }}</div>
        <div class="io-client-approval-card__content">
            <div class="io-client-approval-card__content-left">
                <IOSingleInfoRow
                    v-if="client"
                    :label="isSubmittedToClient ? $t('Submitted to') : $t('Client')"
                >
                    <template #info>
                        <Avatar
                            :show-full-name="true"
                            :employee-mongo-id="client.id"
                            :employee-full-name="client.name"
                        />
                    </template>
                </IOSingleInfoRow>
                <IOSingleInfoRow v-if="dateReceived" :label="$t('Date Received')" :info="dateReceived" />
                <IOSingleInfoRow v-else-if="isSubmittedToClient" :label="$t('Date Submitted')" :info="submittedToClientDate" />
            </div>
            <div class="io-client-approval-card__content-right">
                <IOButton 
                    v-for="(action, index) in actions" 
                    :key="index" :variant="action.variant" 
                    :icon="action.icon" 
                    @click="action.onClick"
                >
                    {{ action.text }}
                </IOButton>
                <SingleClientApprovalStatus 
                    v-if="isSubmittedToClient && !actions?.length" 
                    :approverId="client?.id" 
                    :date="dateApprovedOrRejected" 
                    :status="status"
                />
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./ClientApprovalCard.ts"></script>
<style lang="scss" src="./ClientApprovalCard.scss" scoped></style>