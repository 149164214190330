<template>
    <TransitionGroup
        tag="ul"
        name="fade-from-bottom"
        class="flex flex-col gap-8-px mt-16-px"
    >
        <li
            v-for="(jobsiteLocationPath, idx) in jobsiteLocationsPaths"
            :key="idx"
            class="flex flex-col gap-12-px px-16-px py-8-px rounded-lg border border-darker-bg"
        >
            <div class="flex justify-between items-center gap-16-px leading-relaxed">
                <div :title="getSelectedJobsiteLocationTitle(jobsiteLocationPath)" class="text-sm-px truncate">
                    {{ getSelectedJobsiteLocationTitle(jobsiteLocationPath) }}
                </div>

                <FloatMenu v-if="editable" static @clear="$emit('remove', jobsiteLocationPath)" />
            </div>

            <div v-if="jobsiteLocationPath.length > 1" class="text-main-lighten-3 text-sm-px leading-normal">
                {{ getJobsiteLocationPathTitles(jobsiteLocationPath) }}
            </div>
        </li>
    </TransitionGroup>
</template>

<script lang="ts" src="./SelectedJobsiteLocations.ts"></script>
<style lang="scss" src="./SelectedJobsiteLocations.scss" scoped></style>
