export default {
    name: 'InfoBox',
    props: {
        // available types: information, success, warning, error
        type: {
            type: String,
            required: false,
            default: 'information'
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        text: {
            type: String,
            required: false,
            default: ''
        },
        icon: {
            type: String,
            required: false,
            default: 'fas fa-info-circle'
        },
        displayIcon: {
            type: Boolean,
            required: false,
            default: true
        },
        dismissible: { 
            type: Boolean, 
            default: false 
        }
    },
    data () {
        return {
            displayInfoBox: true
        }
    },
    computed: {
        hasButtonsSlot () {
            return !!this.$slots.buttons
        },
        hasTextSlot () {
            return !!this.$slots.text
        }
    },
    methods: {
        hideInfoBox () {
            this.displayInfoBox = false
        }
    }
}
