import { defineComponent } from 'vue'

import { resolve } from '@/helpers/util'

/**
 * Use this mixin to load data using global loading bar animation
 * Argument promises load sequentially, but promises inside array load in parallel, e.g.:
 * this.load(promise1, [promise2, promise3], promise4)
 * promise1 -> promise2, promise3 -> promise4
 */
export default defineComponent({
    computed: {
        loading (): boolean {
            return this.$store.getters['loadingBar/getLoadingStatus']
        },
    },
    methods: {
        async load (...fns: Function[] | Function[][]): Promise<void> {
            this.setLoadingBar(true)
            try {
                await resolve(fns, this)
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
    },
})
