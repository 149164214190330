const Abc = () => import(/* webpackChunkName: "abc" */ './components/Abc.vue')

export default [
    {
        path: '/abc',
        name: 'abc-example',
        component: Abc,
        meta: {
            title: 'Abc Module'
        }
    }
]
