export enum InvoiceActions {
    SaveAsDraft = 'save_as_draft',
    SubmitForInternalApproval = 'submit_for_internal_approval',
    Approve = 'approve',
    SubmitToClient = 'submit_to_client',
    MarkAsSubmittedToClient = 'mark_as_submitted_to_client',
    RecordClientApproval = 'record_client_approval',
    MarkAsPaid = 'mark_as_paid',
    AddressRevision = 'address_revision',
    RequestRevision = 'request_revision',
    RevertToDraft = 'revert_to_draft',
    SubmitToAccounting = 'submit_to_accounting',
    MarkAsPendingInternalApproval = 'mark_as_pending_internal_approval',
    Void = 'void',
    ResetApprovalWorkflow = 'reset_approval_workflow'
}

export default InvoiceActions
