import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource.ts'
import ApprovableResourceSubtype from '@/interfaces/modules/projects/financial-approvals/ApprovableResourceSubtype.ts'
import { AxiosPromise } from 'axios'
import { Workflow } from '@/interfaces/modules/projects/financial-approvals/Workflow.ts'
import { ApprovableResource } from '@/interfaces/modules/projects/financial-approvals/ApprovableResource.ts'
import { ApiClient as client } from '@/api/api'
import { WorkflowInUse } from '@/interfaces/modules/projects/financial-approvals/WorkflowInUse.ts'

const financialApprovalWorkflowsClient = {

    /**
     * Gets approvable resource workflows for specified project company and approvable resources type and subtype
     * @param projectMongoLocalId
     * @param resourceType - type of approvable resource, known also as resourceName
     * @param subtype
     * @throws {AxiosError<ApiErrorResponse>} in case any error happens
     * @throws {string} in case of missing required parameters
     */
    getApprovalWorkflows (projectMongoLocalId: string, resourceType: Resource, subtype: ApprovableResourceSubtype): AxiosPromise<Workflow[]> {
        return client.get('/approval-workflows', {
            params: {
                project_company_id: projectMongoLocalId,
                approvable_resource_name: resourceType,
                approvable_resource_subtype: subtype
            }
        })
    },
    updateWorkflows (resourceId: string, workflows: Workflow[], costCodesEnabled: boolean, syncInstances: boolean, approvableResourceSubtype: ApprovableResourceSubtype): AxiosPromise {
        const data = {
            workflows: workflows,
            cost_codes_enabled: costCodesEnabled,
            sync_instances: syncInstances,
            approvable_resource_subtype: approvableResourceSubtype ?? undefined
        }
        return client.put(`company-project-approvable-resources/${ resourceId }`, data)
    },
    /**
     * Gets approvable resource data for specified project company and approvable resources type and subtype
     * The data contains of workflows, their assigned cost codes and pending approval submissions count for that specific resource type
     * Used in project settings view
     * @param projectCompanyId
     * @param resourceType - type of approvable resource, known also as resourceName
     * @param resourceSubType
     * @throws {AxiosError<ApiErrorResponse>} in case any error happens
     * @throws {string} in case of missing required parameters
     */
    async getApprovableResourcesTypeData (projectCompanyId: string, resourceType: Resource, resourceSubType?: ApprovableResourceSubtype): Promise<ApprovableResource> {
        if(!projectCompanyId) {
            throw Error('projectCompanyId is required in financialApprovalWorkflowsClient.getApprovableResourcesTypeData')
        }
        if(!resourceType) {
            throw Error('resourceType is required in financialApprovalWorkflowsClient.getApprovableResourcesTypeData')
        }

        const result = await client.get('company-project-approvable-resources/show', {
            params: {
                project_company_id: projectCompanyId,
                approvable_resource_name: resourceType,
                approvable_resource_subtype: resourceSubType
            }
        })
        return result.data as ApprovableResource
    },
    getWorkflowsInUse (projectCompanyId: string): AxiosPromise<WorkflowInUse[]> {
        return client.get(`projects-companies/${ projectCompanyId }/approval-workflows-in-use`)
    },
}
export default financialApprovalWorkflowsClient
