<template>
    <div :key="animationKey" class="io-status-change">
        <template v-for="(item, index) in statuses" :key="index">
            <StatusPill
                v-if="item.text && item.size && item.variant"
                :text="item.text"
                :variant="item.variant"
                :size="item.size"
                class="io-status-change__pill"
                :style="getCascadeAnimationDelay(index, 0.2)"
            />

            <i
                v-if="index !== statuses.length - 1"
                class="io-status-change__icon"
                :class="[directionIcon.class, `io-status-change-icon--${ directionIcon.size }`]"
                :style="{
                    color: `var(${ directionIcon.color })`,
                    animationDelay: `${ index * 0.2 + 0.1 }s`,
                }"
            />
        </template>
    </div>
</template>

<script lang="ts" src="./StatusChange.ts"></script>
<style scoped lang="scss" src="./StatusChange.scss"></style>
