import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default defineComponent({
    name: 'delightedSurveyMixin',
    data () {
        return {
            subscriptionTypes: {
                'OpenCA': 'Basic',
                'PRO': 'Enterprise',
                'OpenCA+': 'Pro'
            }
        }
    },
    computed: {
        ...mapGetters('appStore', {
            authData: 'getAuthData',
        }),
        isUserEmailFromIngenious (): boolean {
            return this.authData.u_email.includes('@ingenious.build')
        },
        isTrainingEnv (): boolean {
            return window.location.host.split('.')[2] === 'training'
        },
    },

    beforeMount (): void {
        this.initDelightedSurvey()
    },
    methods: {
        initDelightedSurvey (): void {
            if (!this.isProduction) {
                return
            }

            if (this.isTrainingEnv) {
                return
            }

            if (this.isUserEmailFromIngenious) {
                return
            }

            // show only after 30 days of account creation
            if (dayjs().diff(this.authData.first_logged_at, 'day') >= 30) {
                delighted.survey({
                    name: `${ this.authData.u_firstname } ${ this.authData.u_lastname }`,
                    email: this.authData.u_email,
                    locale: this.authData.user_language ?? 'en',
                    initialDelay: 2628000,
                    recurringPeriod: 2628000,
                    createdAt: this.authData.first_logged_at,
                    properties: {
                        workspaceName: this.authData.workspace_subdomain,
                        workspaceType: this.authData.app_type,
                        platform: 'Web',
                        subscriptionType: this.subscriptionTypes[this.authData.subscription_type],
                        locationToShow: this.$route.meta?.title
                    }
                })
            }
        },
    }
})
