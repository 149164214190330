import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ContentTwoSidesTemplate',
    props: {
        title: { type: String, default: '' },
        iconClass: { type: String, default: '' },
        iconColor: { type: String, default: 'var(--info)' },
        rightWidth: { type: String }
    },
    computed: {
        leftWidth (): string {
            if (!this.rightWidth) {
                return ''
            }

            return `calc(100% - ${ this.rightWidth })`
        }
    }
})
