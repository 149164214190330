import { defineComponent, PropType } from 'vue'
import ApprovalModal from '../../components/approval-modal/ApprovalModal.vue'
import RecordClientApprovalModal from '../../components/record-client-approval-modal/RecordClientApprovalModal.vue'
import Resource from '../../enums/Resource'
import ApprovalModalData from '../../interfaces/ApprovalModalData'
import ApprovalsClient from '@/io-modules/approval-workflows/api-clients/ApprovalsClient'
import ApprovalModalType from '../../enums/ApprovalModalType'
import ApprovalModalFields from '@/io-modules/approval-workflows/interfaces/ApprovalModalFields.ts'

export default defineComponent({
    name: 'ApprovalsWrapper',
    components: {
        ApprovalModal,
        RecordClientApprovalModal,
    },
    props: {
        approvableResourceId: String,
        approvalModalData: {
            type: Object as PropType<ApprovalModalData>,
            default: () =>({})
        },
        hideFiles: {
            type: Boolean,
            required: false,
            default: false,
        },
        isClientOffSystem: {
            type: Boolean,
            required: false,
            default: false,
        },
        zIndex: {
            type: Number,
            required: false,
            default: 10,
        },
        forceClientApproval: { //in case you need Client Approval no matter settings
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['approved', 'rejected', 'submitToClient', 'clientApproved', 'update:approvalModalData', 'loaded'],
    expose: ['refreshData'],
    data () {
        return  {
            resourceApprovals: null,
        }
    },
    computed: {
        showApprovalModal (): boolean {
            return this.resourceApprovals && ApprovalModalType.UserApproval === this.approvalModalData.modal
        },

        showRecordClientApprovalModal (): boolean {
            return this.resourceApprovals && ApprovalModalType.ClientApproval === this.approvalModalData.modal
        },

        resourceName (): Resource {
            return this.resourceApprovals?.resource_type
        },
    },
    watch: {
        async approvableResourceId (): Promise<void> {
            await this.refreshData()
        }
    },
    async beforeMount () {
        await this.fetchApprovalWorkflowsData()
    },
    methods: {
        async fetchApprovalWorkflowsData (): Promise<void> {
            if (!this.resourceApprovals && this.approvableResourceId) {
                const response = await ApprovalsClient.getApprovableResource(
                    this.approvableResourceId,
                )

                this.resourceApprovals = response.data
                this.$emit('loaded', this.resourceApprovals)
            }
        },

        closeModal (): void {
            this.$emit('update:approvalModalData', { ...this.approvalModalData, modal: '' })
        },

        openClientApprovalModal (): void {
            this.$emit('update:approvalModalData', { ...this.approvalModalData, modal: ApprovalModalType.ClientApproval })
        },

        async refreshData (): Promise<void> {
            if (this.approvableResourceId) {
                const response = await ApprovalsClient.refreshApprovableResourceData(
                    this.approvableResourceId,
                )

                this.resourceApprovals = response.data
                this.$emit('loaded', this.resourceApprovals)
            }
        },

        approved (isFinalAction: boolean): void {
            this.refreshData()
            this.$emit('approved', isFinalAction)
        },

        rejected (rejectionModalData: ApprovalModalFields): void {
            this.refreshData()
            this.$emit('rejected', rejectionModalData)
        },

        submitToClient (): void {
            this.$emit('submitToClient')
        },

        clientApproved (): void {
            this.refreshData()
            this.$emit('clientApproved')
        }
    }
})
