import dateTimeHelper from '@/helpers/dateTime'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import TicketDetailsPanelTabEnum
    from '@/io-modules/project-schedules/enums/TicketDetailsPanelTabEnum'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
import { isEmpty, get } from 'lodash'

export default {
    mixins: [featureFlagsMixin],
    methods: {
        async markAlertAsRead (alert) {
            await this.$api.post('/notifications/mark-as-read/' + alert._id)
            await this.$store.dispatch('notificationToastr/removeAlert', alert._id)
        },
        async markAllAsRead (type) {
            await this.$api.post('/notifications/mark-all-as-read/' + type)
            await this.$store.dispatch('notificationToastr/removeAlertsByType', type)
        },
        async markAsReadByRelatedId (relatedId) {
            await this.$api.post('/notifications/mark-as-read-by-related-id/' + relatedId)
        },

        prepareNotificationMessage (item) {
            let message = item.message
            if (!isEmpty(item.messageVars)) {
                item.messageVars.forEach((messageVar) => {
                    let value = messageVar.value

                    if (messageVar.type === 'date') {
                        value = this.$filters.parseDate(value)
                    }

                    message = message.replace(messageVar.name, value)
                })
            }
            return message
        },

        prepareDateFormat (format) {
            // remap format from PHP to Moment.js or set default MM/DD/Y
            if (format === 'm/d/Y h:i A') {
                return dateTimeHelper.getDateTimeFormat()
            } else if (format === 'm/d/Y [at] h:i A') {
                return dateTimeHelper.getDateTimeFormat()
            } else {
                return dateTimeHelper.getDateFormat()
            }
        },

        redirectToNotificationResource (item) {
            let newRoute = null
            let replaceCurrentRoute = false

            switch (item.relatedType) {
            case 'todo':
                // do not redirect to non-existing/non-accessible resource
                if (item.actionType !== 'todo-employee-removed') {
                    newRoute = { name: 'todo-view', params: { id: item.relatedId } }
                }
                break
            case 'insurance-requirement':
                newRoute = { name: 'contract-edit', params: { id: item.contractId } }
                break
            case 'contract-document-mention':
                newRoute = {
                    name: 'contract-document-created-view-only',
                    params: {
                        id: item.additionalParams.contract_id,
                        pid: item.additionalParams.project_id,
                        documentId: item.additionalParams.document_id,
                    },
                    query: {
                        annotationId: item.relatedId,
                    },
                }
                break
            case 'contract':
                if (item.additionalParams && item.additionalParams.redirectionUrl) {
                    window.location.href = item.additionalParams.redirectionUrl
                    return
                }

                newRoute = { name: 'contract-edit', params: { id: item.relatedId } }
                break
            case 'amendment':
            case 'changeorder':
                newRoute = { params: { id: item.relatedId, pid: item.projectId } }
                const isAmendment = 'amendment' === item.relatedType

                if (item.messageData && item.messageData.is_my_type && item.messageData.is_sender) {
                    newRoute.name = isAmendment ? 'amendments-view-received' : 'changeorders-view-received'
                } else if (item.messageData && item.messageData.is_my_type && !item.messageData.is_sender) {
                    newRoute.name = isAmendment ? 'amendments-view-my' : 'changeorders-view-my'
                } else {
                    newRoute.name = isAmendment ? 'amendments-view-received' : 'changeorders-view-received'
                }
                break
            case 'changeorderrequest':
                newRoute = {
                    name: 'changeorders-view-request-details',
                    params: { id: item.relatedId, pid: item.projectId },
                }
                break
            case 'amendmentrequest':
                newRoute = {
                    name: 'amendments-view-request-details',
                    params: { id: item.relatedId, pid: item.projectId },
                }
                break
            case 'project':
                newRoute = {
                    name: 'project-bid-requests-view-bid-leveling',
                    params: { package: item.relatedId, pid: item.additionalParams.relatedTypeId },
                }
                break
            case 'proposal':
                if (item.actionType === 'revision-request') {
                    newRoute = {
                        name: 'proposal-edit',
                        params: { id: item.relatedId },
                    }
                } else {
                    // Redirection from this notification will be enabled again when the new view
                    // to replace proposal-bidrequests-id is ready
                    // newRoute = {
                    //     name: 'proposal-bidrequests-id',
                    //     params: { id: item.relatedTypeId, package: item.relatedId }
                    // }
                    break
                }
                break
            case 'rfp':
                const rfpViewName = 'rfp-view-new'

                if (!item.additionalParams || !item.additionalParams.to) {
                    newRoute = {
                        name: rfpViewName,
                        params: {
                            id: item.relatedId,
                        },
                        query: {
                            from: 'notification',
                        },
                        hash: '#summary',
                    }
                    break
                }
                if (item.additionalParams.to === 'receiver-proposal') {
                    newRoute = { name: 'proposal-view-new', params: { id: item.additionalParams.id } }
                    break
                }
                if (item.additionalParams.to === 'receiver-rfp') {
                    newRoute = {
                        name: rfpViewName,
                        params: {
                            id: item.additionalParams.id,
                        },
                        query: {
                            tab: 'discussion',
                        },
                    }
                    break
                }
                if (item.additionalParams.to === 'sender-project') {
                    newRoute = {
                        name: 'project-bid-requests-view-bid-leveling',
                        params: {
                            pid: item.additionalParams.id,
                            package: item.relatedId,
                        },
                        query: {
                            sender: item.additionalParams.senderId,
                        },
                    }
                    break
                }
                if (item.additionalParams.to === 'sender-proposal') {
                    // Redirection from this notification will be enabled again when the new view
                    // to replace proposal-bidrequests-id is ready
                    // newRoute = {
                    //     name: 'proposal-bidrequests-id',
                    //     params: {
                    //         id: item.additionalParams.id,
                    //         package: item.relatedId
                    //     },
                    //     query: {
                    //         sender: item.additionalParams.senderId
                    //     }
                    // }
                    break
                }
                break
            case 'invitation':
                newRoute = { name: 'invitation-view', params: { id: item.relatedId } }
                break
            case 'payapp':
                if (item.additionalParams && item.additionalParams.redirectionUrl) {
                    window.location.href = item.additionalParams.redirectionUrl
                    return
                }
                newRoute = { name: 'payapps-invoice-details', params: { id: item.relatedId, pid: item.projectId } }
                break
            case 'funding-package':
                newRoute = {
                    name: 'payapps-billing-details-details',
                    params: { id: item.relatedId, pid: item.projectId },
                }
                break
            case 'submittal':
                newRoute = {
                    name: 'project-submittal-edit',
                    params: { id: item.relatedId, pid: item.projectId },
                }
                break
            case 'submittal-v2':
                if (item.actionType === 'submittal-list-export-ready' || item.actionType === 'submittal-list-export-failed') {
                    newRoute = {
                        name: 'submittals-index',
                        params: { pid: get(item.additionalParams, 'projectId') },
                    }
                    break
                }
                if (this.isFeatureEnabled(FeatureFlagsConsts.SUBMITTAL_ENHANCEMENTS, false)) {
                    newRoute = {
                        name: 'submittals-show',
                        params: { pid: get(item.additionalParams, 'projectId'), submittalId: item.relatedId },
                        query: { 'sidebar-tab': 'details' },
                    }
                    break
                }
                newRoute = {
                    name: 'submittals-viewer-details',
                    params: { id: item.relatedId, pid: get(item.additionalParams, 'projectId') },
                }
                break
            case 'punch-item':
                const actionTypes = {
                    'punch-item-ball-in-court-updated': {
                        name: 'punch-items-index',
                        params: { punchItemId: item.relatedId, pid: item.additionalParams.projectId },
                    },
                    'punch-item-status-updated': {
                        name: 'punch-items-index',
                        params: { punchItemId: item.relatedId, pid: item.additionalParams.projectId },
                    },
                    'punch-list-open': {
                        name: 'punch-items-index',
                        params: { punchListId: item.relatedId, pid: item.additionalParams.projectId },
                    },
                    'punch-list-closed': {
                        name: 'punch-items-index',
                        params: { punchListId: item.relatedId, pid: item.additionalParams.projectId },
                    },
                }

                newRoute = actionTypes[item.actionType]
                break
            case 'rfi-v2':
                newRoute = {
                    name: 'rfis-show',
                    params: { rfiId: item.relatedId, pid: item.additionalParams.projectId },
                    query: {
                        'sidebar-tab': 'details',
                    },
                }
                break
            case 'project-budget-dashboard-summary':
                this.$store.dispatch('notification/setBudgetNotification', item.additionalParams)
                localStorage.setItem('budgetNotification', JSON.stringify(item.additionalParams))
                newRoute = {
                    name: 'project-budget-dashboard-summary',
                    params: { pid: item.relatedId, pid2: '' },
                }
                break
            case 'project-budget-summary':
                this.$store.dispatch('notification/setBudgetNotification', item.additionalParams)
                localStorage.setItem('budgetNotification', JSON.stringify(item.additionalParams))
                newRoute = {
                    name: 'project-budget-dashboard-summary',
                    params: { pid: item.additionalParams.projectId, budgetId: item.additionalParams.budgetId },
                    query: { 'commentableId': item.additionalParams?.commentableId },
                }
                break
            case 'budget-dashboard':
                this.$store.dispatch('notification/setBudgetNotification', item.additionalParams)
                localStorage.setItem('budgetNotification', JSON.stringify(item.additionalParams))
                newRoute = {
                    name: 'project-budget-dashboard-summary',
                    params: { budgetId: item.relatedId, pid: item.additionalParams.shared_to_mysql_project_id },
                }
                break
            case 'feasibility-study-dashboard':
                this.$store.dispatch('notification/setBudgetNotification', item.additionalParams)
                localStorage.setItem('budgetNotification', JSON.stringify(item.additionalParams))
                newRoute = {
                    name: 'project-budget-dashboard-feasibility',
                    params: { budgetId: item.additionalParams.budget_id, pid: item.additionalParams.shared_to_mysql_project_id },
                }
                break
            case 'rfi':
                newRoute = { name: 'project-rfis-v2-summary', params: { id: item.relatedId, pid: item.projectId } }
                break
            case 'invitation-model':
                if (item.customUrl) {
                    window.location.href = item.customUrl
                }
                break
            case 'meeting_minutes':
                if (item.customUrl) {
                    window.location.href = item.customUrl
                }
                break
            case 'approval-process':
                if (item.additionalParams && item.additionalParams.url) {
                    window.location.href = item.additionalParams.url
                }
                break
            case 'drawing-set':
                newRoute = {
                    name: 'drawings-sheets-index',
                    params: { setId: item.relatedId, pid: item.additionalParams.pid },
                }
                break
            case 'inspection':
                newRoute = {
                    name: 'inspection-new-view',
                    params: { id: item.relatedId, pid: item.additionalParams.projectId },
                }
                break
            case 'drawing-set-comment':
                newRoute = {
                    name: 'drawings-sheets-show',
                    params: {
                        pid: item.additionalParams.pid,
                        sheetId: item.additionalParams.drawing_set_id,
                        versionId: item.additionalParams.drawing_version_id,
                    },
                    query: {
                        comment_id: item.additionalParams.annotation_uuid,
                    },
                }
                break
            case 'lien-waiver-requested':
                newRoute = {
                    name: 'payapps-invoice-documents',
                    params: { id: item.additionalParams.id, pid: item.relatedId },
                }
                break
            case 'lien-waiver-sent':
                newRoute = {
                    name: 'payapps-invoice-documents',
                    params: { id: item.additionalParams.id, pid: item.relatedId },
                }
                break
            case 'lien-waiver-approved':
                newRoute = {
                    name: 'payapps-invoice-documents',
                    params: { id: item.additionalParams.id, pid: item.relatedId },
                }
                break
            case 'lien-waiver-requires-revision':
                newRoute = {
                    name: 'payapps-invoice-documents',
                    params: { id: item.additionalParams.id, pid: item.relatedId },
                }
                break
            case 'specification-section-comment':
                if (item.actionType === 'section-version-tag-user') {
                    newRoute = {
                        name: 'specifications-show',
                        params: {
                            pid: item.additionalParams.pid,
                            id: item.additionalParams.section_version_id,
                        },
                        query: {
                            markup_id: item.additionalParams.markup_id,
                            'sidebar-tab': 'details',
                        },
                    }
                }
                break
            case'contract-document-change-ball-in-court':
                newRoute = { name: 'contract-edit', params: { id: item.additionalParams.contract_id } }
                break
            case 'task':
                if (item.actionType === 'task-assigned-bic') {
                    newRoute = { name: 'todo-edit', params: { id: item.relatedId } }
                }
                break
            case 'project-schedule':
                newRoute = {
                    name: 'schedule-v2-gantt',
                    params: {
                        id: item.additionalParams.schedule_id,
                        pid: item.additionalParams.project_id,
                    },
                    query: {
                        ticketId: item.additionalParams.ticket_id,
                    },
                }
                if (item.actionType === 'project-schedule-ticket-mentioned-in-comment') {
                    newRoute.query.tab = TicketDetailsPanelTabEnum.COMMENTS
                }
                if (newRoute.query.ticketId && newRoute.query.ticketId !== this.$route.query.ticketId) {
                    replaceCurrentRoute = true
                }
                break
            case 'specification':
                if (item.actionType === 'specification-draft-published') {
                    newRoute = {
                        name: 'specifications-narratives',
                        params: {
                            pid: item.additionalParams.projectId,
                            id: item.relatedId,
                        },
                    }
                }
                break
            case 'cash-flow':
                newRoute = {
                    name: 'cash-flow-and-forecasting-shared',
                    params: {
                        pid: item.projectId,
                        cashFlowId: item.relatedId,
                    },
                }
                break
            case 'admin-area-data-importer':
                newRoute = {
                    name: 'admin-area-import-tool-with-id',
                    params: {
                        id: item.additionalParams.id,
                    },
                }
                break
            case 'approvable-resource':
                if (item.additionalParams && item.additionalParams.redirectionUrl) {
                    window.location.href = item.additionalParams.redirectionUrl
                    return
                }
                break

            case 'contract-change':
                if (item.additionalParams && item.additionalParams.redirectionUrl) {
                    window.location.href = item.additionalParams.redirectionUrl

                    return
                }
                break

            case 'contract-change-request':
                if (item.additionalParams && item.additionalParams.redirectionUrl) {
                    window.location.href = item.additionalParams.redirectionUrl
                    return
                }
                break
            }

            // do nothing if newRoute is not set
            if (newRoute === null) {
                return
            }

            if (replaceCurrentRoute) {
                this.$router.replace(newRoute)
                return
            }

            const resolved = this.$router.resolve(newRoute)
            // reload because current route is the same as provided
            if (resolved.path === this.$route.path) {
                window.location.reload()
                // redirect
            } else if (resolved.path.includes('budget/summary') && this.$route.path.includes('budget/options')) {
                window.location.assign(resolved.path)
            } else {
                this.$router.push(newRoute)
            }
        },
    },
}
