import { defineComponent } from 'vue'
import { mapGetters as mapGettersVuex, mapState as mapStateVuex } from 'vuex'
import { isEmpty } from 'lodash'

export default defineComponent({
    props: {
        data: { type: Object as () => any, required: false },
        isProjectView: { type: Boolean, required: false, default: false },
        useRouting: { type: Boolean, required: false, default: true },
        delegateNavigation: { type: Boolean, default: false, },
        arrayId: { type: String, required: false }
    },
    computed: {
        ...mapStateVuex('filesComponent', {
            foldersBreadcrumbs: (state) => state.foldersBreadcrumbs,
            folderFlags: (state) => state.folderFlags,
        }),

        breadcrumbs (): any {
            return this.foldersBreadcrumbs
        },

        isGoBackEnabled (): boolean {
            return this.foldersBreadcrumbs?.length > 1 && this.isProjectView
        },
    },
    methods: {
        async goBack (): Promise<void> {
            const prevFolder = this.foldersBreadcrumbs[this.foldersBreadcrumbs.length - 2]

            await this.$router.push({ name: this.$route.name, params: { folderId: prevFolder.folder_id } })
        },
        loadFolders (breadcrumb: any): void {
            if (this.delegateNavigation) {
                this.$emit('handleNavigation', breadcrumb.folder_id)
                return
            }

            if (!this.useRouting) {
                this.fetchFolders(breadcrumb)
                return
            }
            const name = this.isProjectView ? 'project-documents-list' : 'documents-list'

            this.$router.push({ name, params: { folderId: breadcrumb.folder_id } })
        },
        async fetchFolders (breadcrumb: any): Promise<void> {
            this.$store.dispatch('filesComponent/setLoading', true)

            this.$store.dispatch('filesComponent/clearDocsArr', {
                arrayId: this.arrayId,
                files: []
            }, { root: true })

            let params = {
                folder_id: breadcrumb.folder_id
            }

            try {
                let response = {}
                response = await this.$api.get('/documents/folder/content', { params: params })

                const data = response.data

                this.$store.dispatch('filesComponent/setFolders', {
                    arrayId: this.arrayId,
                    folders: data.folders
                })

                this.$store.dispatch('filesComponent/setFolderFlags', data.current_folder)

                if (data.files && !isEmpty(data.files)) {
                    this.$store.dispatch('filesComponent/setMyDocs', {
                        arrayId: this.arrayId,
                        files: data.files.own
                    }, { root: true })
                }

                if (data.breadcrumb && data.breadcrumb.length) {
                    this.$store.dispatch('filesComponent/setFolderBreadcrumb', data.breadcrumb)
                }

                this.$store.dispatch('filesComponent/setSearchLocal', false)
                this.$store.dispatch('filesComponent/setIsSearching', false)
                this.$emit('clearSearch')
                this.$store.dispatch('filesComponent/setLoading', false)
            } catch (e) {
                this.consoleError(e)
                this.$store.dispatch('filesComponent/setLoading', false)
            }
        }
    }
})
