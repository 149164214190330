<template>
    <div class="io-single-folder-row" @click="loadFolders">
        <div class="io-single-folder-row__cell io-single-folder-row__cell--top">

            <template v-if="isBreadcrumb">
                <div class="io-single-folder-row__icon"><i class="fas fa-reply"></i></div>
                <div class="io-single-folder-row__text io-bold">{{ $t('Go Back') }}</div>
            </template>

            <template v-else-if="isSearchResult">
                <div v-if="folder.file_type && folder.file_type === 'file'" class="io-single-folder-row__icon">
                    <i class="fad fa-file"></i>
                </div>
                <div v-else class="io-single-folder-row__icon" :class="folderIconClasses">
                    <i class="fad fa-folder"></i>
                </div>
                <div class="io-single-folder-row__text io-single-folder-row__text--name io-bold">{{ folder.path }}</div>
            </template>

            <template v-else>
                <div class="io-single-folder-row__icon" :class="folderIconClasses">
                    <i class="fad fa-folder"></i>
                </div>
                <div v-if="isCreateMode" class="io-single-folder-row__new-folder-name">
                    <input ref="inputName" autofocus v-model="folder.name"/>
                </div>
                <div v-else class="io-single-folder-row__text io-single-folder-row__text--name io-bold">{{ folder.name }}</div>
            </template>

        </div>
        <div class="io-single-folder-row__cell">
            <!--placeholder-->
        </div>
        <div class="io-single-folder-row__cell io-single-folder-row__cell--author">
            <!--placeholder-->
        </div>
        <div class="io-single-folder-row__cell io-single-folder-row__cell--date">
        </div>
        <div class="io-single-folder-row__cell io-f-center">
            <!--placeholder-->
        </div>
        <div class="io-single-folder-row__box io-f-center">
            <!--placeholder-->
        </div>
        <div class="io-single-folder-row__box io-single-folder-row__box--menu io-f-center">
            <div
                v-if="isCreateMode"
                class="io-edit-mode-btns-actions"
            >
                <div
                    class="io-icon-action"
                    @click="addFolder"
                >
                    <i class="icon-check text-lg text-success-darker" />
                </div>

                <div
                    class="io-icon-action"
                    @click="exitCreationMode"
                >
                    <i class="icon-cross text-lg text-error-darker" />
                </div>
            </div>

            <div
                v-else-if="isSearchResult && isOfFileType"
                class="io-single-folder-row__text io-bold"
            >
                {{ $filters.fileSize(folder.file_size) }}
            </div>

            <IOButton
                v-else-if="!isBreadcrumb && !isOfFileType"
                v-tooltip.top="$t('Archive')"
                variant="link"
                @click.stop="checkArchivingOption"
            >
                <i class="icon-archive text-lg text-main-lighten-3" />
            </IOButton>
        </div>
    </div>
</template>
<script src="./single-folder.js"></script>
<style lang="scss" src="./single-folder.scss" scoped></style>
