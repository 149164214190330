import BillingSettingsClient from "@/api/project-settings-billing/billingSettingsClient"
import InvoicesSettings from "@/interfaces/modules/projects/settings/invoices-settings/Settings"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useBillingStore = defineStore('project-settings-billing', () => {
    const invoicesSettings = ref(null as InvoicesSettings | null)

    async function getInvoicesSettings(projectCompanyId: string): Promise<InvoicesSettings | { error: any }> {
        if (invoicesSettings.value) {
            return invoicesSettings.value
        }

        try {
            const res = await BillingSettingsClient.getInvoicesSettings(projectCompanyId)
            invoicesSettings.value = res.data.data
            return res.data.data
        } catch (error) {
            return { error }
        }
    }

    function storeInvoicesSettings(settings: InvoicesSettings): void {
        invoicesSettings.value = settings
    }

    return { invoicesSettings, getInvoicesSettings, storeInvoicesSettings }
})
