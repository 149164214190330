import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'
import { Status, Type } from '@/components/drawing-reference-select/enums/Set.ts'
import type { Set } from '@/components/drawing-reference-select/interfaces/Set.ts'
import type { Sheet } from '@/components/drawing-reference-select/interfaces/Sheet.ts'

const drawingReferenceSelectClient = {
    getDrawings: (projectId: string): AxiosPromise<{ items: Set[]; taken: number }> =>
        client.post('/ca/drawing/set/lazy-list/0', {
            project: projectId,
            filters: {
                type: [Type.CONSTRUCTION],
                status: [Status.ACTIVE],
            },
        }),

    getSheets: (setId: string): AxiosPromise<{ items: Sheet[], taken: number }> =>
        client.post(`/ca/drawing/get-list/${ setId }`),
}

export default drawingReferenceSelectClient
