import { toggle } from '@/helpers/util.ts'

export function SET_PACKAGES (state, items) {
    state.packages = items
}

export function SET_RELEVANT_SPECIFICATIONS (state, items) {
    state.relevantSpecifications = items
}

export function SET_COLLAPSED_PACKAGES_IDS (state, items) {
    state.collapsedPackagesIds = items
}

export function SET_ACTIVE_PACKAGE_ID (state, data) {
    state.activePackageId = data
}

export function SET_DETAILS_VISIBILITY (state, data) {
    state.detailsToggled = data
}

export function SET_FILE_INFO (state, data) {
    state.fileInfo = data
}

export function TOGGLE_DOWNLOAD_POPUP (state, data) {
    state.downloadPopupVisible = data
}

export function SET_COPY_SUBMITTAL_DATA (state, data) {
    state.copySubmittalData = data
}

export function SET_COPY_SUBMITTAL_ID (state, data) {
    state.copySubmittalId = data
}

export function SET_OPEN_SUBMITTAL_DATA (state, data) {
    state.openSubmittalData = data
}

export function SET_CREATING_NEXT (state, data) {
    state.creatingNext = data
}

export function SET_IS_REQUIRED_FOR_MATERIAL_RELEASE (state, data) {
    state.isRequiredForMaterialRelease = data
}

export function SET_SHOW_FILTERS (state, data) {
    state.showFilters = data
}

export function TOGGLE_CHECKED_SUBMITTAL_ID (state, submittalId) {
    toggle(submittalId, state.checkedSubmittalsIds)
}

export function CHECK_MULTIPLE_SUBMITTAL_IDS (state, submittalIds) {
    const allCheckedSubmittalIds = [...state.checkedSubmittalsIds, ...submittalIds]
    state.checkedSubmittalsIds = allCheckedSubmittalIds.filter((value, index) => allCheckedSubmittalIds.indexOf(value) === index)
}

export function RESET_CHECKED_SUBMITTALS_IDS (state) {
    state.checkedSubmittalsIds = []
}
