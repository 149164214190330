import { defineComponent, PropType } from 'vue'

import { Notification } from '@/layouts/parts/menu-left/interfaces/MenuLeftInterface'

import Invitation from './invitation/Invitation'
import Message from './message/Message'
import General from './general/General'

export default defineComponent({
    name: 'Notification',
    props: {
        notificationData: { type: Object as PropType<Notification>, required: true }
    },
    components: {
        Invitation,
        Message,
        General
    },
    methods: {
        onNotificationsCenterClose (): void {
            this.$emit('notificationCenterClose')
        },
        onNotificationUpdate(data: Notification) {
            this.$emit('updateNotification', data)
        },
        formatDate (date) {
            const [firstWord, ...rest] = date.split(' ')

            if (!isNaN(firstWord)) {
                return `${ firstWord } ${ this.$t(rest.join(' ')) }`
            } else {
                return this.$t(date)
            }
        },
    }
})
