<template>
    <StampCreate
        v-if="stampCreateShown"
        :initial-title="search"
        @stamp-created="$emit('selectStamp', $event)"
        @close="closeStampCreate"
    />

    <div v-else v-progress="loading && !stamps">
        <header class="p-16-px">
            <h2 class="text-base-px font-semibold mb-16-px">{{ $t('Choose Stamp') }}</h2>

            <IOInput
                :placeholder="$t('Search or type new')"
                icon="icon-search"
                clearable
                :loading="loading"
                :disabled="!stamps"
                v-model="search"
            />
        </header>

        <ul class="io-stamps-index">
            <li
                v-for="(stamp, idx) of stamps"
                :key="stamp.id"
                class="io-stamps-index__item hover-darken animate-pop-from-bottom"
                :style="getCascadeAnimationDelay(idx)"
                @click="$emit('selectStamp', stamp)"
            >
                <StampPreview
                    :color="stamp.color"
                    :initials="stamp.initials"
                    :title="stamp.title"
                    size="sm"
                />

                <span v-if="stamp.is_recently_used" class="text-xs-px text-main-lighten-4">
                    {{ $t('Recently used') }}
                </span>
            </li>
        </ul>

        <div class="px-16-px">
            <IOButton
                v-if="stamps && !stamps.length"
                icon="icon-plus"
                variant="row"
                size="small"
                @click="stampCreateShown = true"
            >
                {{ $t('Add New Stamp') }}
            </IOButton>
        </div>

        <footer class="p-16-px">
            <AlertBox>
                <p>{{ $t('To see more Stamp options') }}</p>

                <RouterLink :to="{ name: 'project-settings-punch-lists' }" target="_blank">
                    {{ $t('Go to Project Settings') }}
                </RouterLink>
            </AlertBox>
        </footer>
    </div>
</template>

<script lang="ts" src="./StampsIndex.ts"></script>
<style scoped lang="scss" src="./StampsIndex.scss"></style>
