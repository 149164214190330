import { defineStore } from 'pinia'
import { ApiClient } from '@/api/api'
import ContractRouteName from '@/io-modules/project-contracts/enums/ContractRouteName.ts'
import { useRoute } from 'vue-router'

let client = ApiClient
const route = useRoute()

export interface ProjectChecklistItem {
    completed: number,
    type: string,
    learnMoreUrl: string,
    isHidden: boolean,
    id?: number,
    title?: string,
    icon?: string,
    hidden: boolean,
    routeName?: string,
    routeParams?: any,
}

export interface ChecklistItemUpdateData {
    completed: number,
    projectId: string,
    type: string,
    hidden?: boolean,
}

export const useProjectChecklistStore = defineStore('projectChecklist', {
    state: () => ({
        defaultElementsData: [
            {
                id: 0,
                title: 'Set up Internal Financial Approvals',
                type: 'internal_financial_approvals',
                icon: 'icon-gear',
                routeName: 'project-settings-financial-approvals',
                completed: 0,
                hidden: false,
                learnMoreUrl: 'https://intercom.help/ingeniousbuild/en/articles/5905790-configure-project-settings-financial-approval-workflows'
            },
            {
                id: 1,
                title: 'Set up Project Schedule & Milestones',
                type: 'project_schedule_milestones',
                icon: 'icon-calendar-event',
                routeName: 'schedule-v2',
                completed: 0,
                hidden: false,
                learnMoreUrl: 'https://intercom.help/ingeniousbuild/en/articles/8279077-create-new-schedule',
            },
            {
                id: 2,
                title: 'Set Up Project Budget',
                type: 'my_budget_cost_codes',
                icon: 'icon-gear',
                routeName: 'project-budget-creation',
                completed: 0,
                hidden: false,
                learnMoreUrl: 'https://intercom.help/ingeniousbuild/en/articles/9308608-creating-the-master-project-budget',
            },
            {
                id: 3,
                title: 'Set up Executed Contract',
                type: 'executed_contract',
                icon: 'icon-file-agreement',
                routeName: ContractRouteName.ProjectContractsList,
                completed: 0,
                hidden: false,
                learnMoreUrl: 'https://intercom.help/ingeniousbuild/en/articles/3700292-creating-a-vendor-contract',
            },
            {
                id: 4,
                title: 'Schedule Reoccurring Meetings',
                type: 'schedule_reoccuring_meetings',
                icon: 'icon-calendar-person',
                routeName: 'meetings',
                completed: 0,
                hidden: false,
                learnMoreUrl: 'https://intercom.help/ingeniousbuild/en/articles/8180100-create-a-meeting',
            },
        ] as ProjectChecklistItem[],
        checklistElementsData: [],
        projectId: null as string,
    }),
    actions: {
        updateCompletedItems (checklistItems: ProjectChecklistItem[], projectId: string): void {
            this.checklistElementsData = JSON.parse(JSON.stringify(this.defaultElementsData))
            this.projectId = projectId
            const itemsToRemove = [] as string[]

            checklistItems.forEach((item: ProjectChecklistItem) => {
                const element = this.findElementByType(item.type)

                if (element) {
                    element.completed = item.completed
                }

                if (item.hidden) {
                    itemsToRemove.push(item.type)
                }
            })

            itemsToRemove.forEach((item: string) => {
                this.removeItemFromList(item)
            })
        },
        updateSingleItem (item: ChecklistItemUpdateData): void {
            if (this.projectId !== item.projectId) {
                return
            }

            if (item.hidden) {
                this.removeItemFromList(item.type)
                return
            }
            const found = this.findElementByType(item.type)

            if (found) {
                found.completed = item.completed
            }
        },
        async completeChecklistItem (type, projectId, completed = 1): Promise<void> {
            const found = this.findElementByType(type)

            if (found && found.completed && completed !== 0) {
                return
            }

            await client.put(`/project-checklist-item/${projectId}/${type}/${completed}`).then(() => {
                this.updateSingleItem({ type, projectId, completed })
            })
        },
        findElementByType (type: string): ProjectChecklistItem {
            return this.checklistElementsData.find(element => element.type === type)
        },
        async hideChecklistItem (type: string, projectId: string): Promise<void> {
            await client.put(`/project-checklist-item-hide/${projectId}/${type}`).then(() => {
                const itemIdx = this.checklistElementsData.findIndex(element => element.type === type)

                if (itemIdx > -1) {
                    this.checklistElementsData.splice(itemIdx, 1)
                }
            })
        },
        removeItemFromList (type: string) {
            const index = this.checklistElementsData.findIndex((item: ProjectChecklistItem) => item.type === type)

            this.checklistElementsData.splice(index, 1)
        }
    },
    getters: {
        getChecklistProjectId (state) {
            return state.projectId
        }
    }
})
