import { defineComponent } from 'vue'

const modalOnClose = defineComponent({
    methods: {
        showModalOnClose (): void {
            return this.showPopupAlert({
                title: this.$t('Are you sure you want to leave?'),
                caption: this.$t('You have some unsaved changes. If you leave now, your changes will be lost.'),
                buttons: [
                    {
                        text: this.$t('Stay here'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Leave without saving'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.$emit('close')
                        },
                    },
                ],
            })
        },
    },
})

export default modalOnClose
