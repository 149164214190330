export enum LabelType {
    Normal = 'normal',
    Bold = 'bold',
    BoldMax = 'bold-max'
}

export enum LabelSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}
