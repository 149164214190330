<template>
    <div>
        <IOButton
            v-if="editable && (multiple || !modelValue.length)"
            variant="link"
            icon="icon-pin-3"
            size="small"
            @click="openNewJobsiteLocationModal"
        >
            {{ $t('Select Jobsite Locations') }}
        </IOButton>

        <Transition name="fade-from-bottom">
            <SelectedJobsiteLocations
                v-if="modelValue.length"
                :editable="editable"
                :jobsite-locations-paths="modelValue"
                @remove="removeJobsiteLocationPath"
            />
        </Transition>

        <IOModal
            v-if="newLocationModalShown"
            scrollable
            widest
            :loading="loading"
            icon="icon-pin"
            width="800"
            height="600"
            :title="$t('Jobsite Locations')"
            @close="closeNewJobsiteLocationModal"
        >
            <Transition name="fade-from-bottom">
                <div v-if="jobsiteLocations?.length" class="flex flex-col h-full">
                    <div class="flex items-center gap-32-px px-64-px py-32-px">
                        <div class="grow">
                            <IOInput
                                :placeholder="$t('Search...')"
                                icon="icon-search"
                                clearable
                                v-model="search"
                            />
                        </div>

                        <Transition name="fade-from-left">
                            <div v-if="!search">
                                <IOButton
                                    v-if="allExpanded"
                                    icon="icon-chevrons-up"
                                    variant="link"
                                    size="small"
                                    @click="expandedJobsiteLocationsIds = []"
                                >
                                    {{ $t('Collapse All') }}
                                </IOButton>

                                <IOButton
                                    v-else
                                    icon="icon-chevrons-down"
                                    variant="link"
                                    size="small"
                                    @click="expandedJobsiteLocationsIds = [...parentJobsiteLocationsIds]"
                                >
                                    {{ $t('Expand All') }}
                                </IOButton>
                            </div>
                        </Transition>
                    </div>

                    <div class="grow px-64-px py-32-px bg-lighten-bg-1">
                        <JobsiteLocationsAccordion
                            :jobsite-locations="search ? filteredJobsiteLocations : activeJobsiteLocations"
                            :expanded-jobsite-locations-ids="expandedJobsiteLocationsIds"
                            :multiple="multiple"
                            v-model="selectedJobsiteLocationsIds"
                            @toggle-folding="toggleFolding"
                        />

                        <IOPlaceholder
                            v-if="search && !filteredJobsiteLocations.length"
                            icon="icon-pin"
                            lite
                            :frame="false"
                        >
                            <template #title>
                                <div class="flex justify-center font-medium text-xl-px text-main-lighten-1">
                                    {{ $t('No Results Found') }}
                                </div>
                            </template>

                            <IOButton size="small" @click="search = ''">{{ $t('Clear filter') }}</IOButton>
                        </IOPlaceholder>
                    </div>
                </div>

                <div v-else-if="!loading" class="h-full flex flex-col justify-center">
                    <IOPlaceholder
                        :frame="false"
                        :title="$t('No Jobsite Locations Found')"
                        :subtitle="$t('You can add jobsite locations in Project Settings')"
                        icon="icon-pin"
                    >
                        <IOButton @click="$router.push({ name: 'project-settings-project-site-locations-view' })">
                            {{ $t('Go to Project Settings') }}
                        </IOButton>
                    </IOPlaceholder>
                </div>
            </Transition>

            <template #footer>
                <IOButton variant="secondary" @click="closeNewJobsiteLocationModal">{{ $t('Cancel') }}</IOButton>

                <IOButton
                    :disabled="isEmpty(selectedJobsiteLocationsIds)"
                    @click="$emit('update:modelValue', selectedJobsiteLocationsPaths); closeNewJobsiteLocationModal()"
                >
                    {{ $t('Select') }}
                </IOButton>
            </template>
        </IOModal>
    </div>
</template>

<script lang="ts" src="./JobsiteLocationsSelect.ts"></script>
<style lang="scss" src="./JobsiteLocationsSelect.scss" scoped></style>
