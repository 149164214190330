export const showCurrentInvoiceWithoutUnconditionalAttachedLWBanner = (state) => {
    return state.displayBannerCurrentInvoiceWithoutUnconditionalAttachedLW
}

export const showPreviousInvoiceWithoutUnconditionalAttachedLWBanner = (state) => {
    return state.displayBannerPreviousInvoiceWithoutUnconditionalAttachedLW
}

export const showForInvoiceSubmissionBanner = (state) => {
    return state.displayBannerForInvoiceSubmission
}

export const showForMarkAsPaidBanner = (state) => {
    return state.displayBannerForMarkAsPaid
}

export const getPreviousInvoiceId = (state) => {
    return state.previousInvoiceId
}
