<template>
    <div>
        <PageHeader :title="$t('Open the sheet where you want to add Reference pin.')">
            <template #actions>
                <SearchInput/>

                <FiltersButton class="io-project-drawings__filter-button"/>

                <IOButton variant="secondary" @click="cancelDrawingSheets">{{ $t('Cancel') }}</IOButton>
            </template>
        </PageHeader>

        <SheetsIndex
            :pid="$route.params.pid"
            :showSharedWith="false"
            :backToSetEvent="true"
            @back-to-set="hideDrawingSheets"
        />
    </div>
</template>

<script lang="ts" src="./DrawingSheets.ts"></script>
<style lang="scss" src="./DrawingSheets.scss" scoped></style>
