const TasksLayout = () => import('./views/layout/TasksLayout.vue')
const View = () => import('./views/view/view.vue')
const ListNew = () => import('./views/list-new/list.vue')
const ToDoListV2 = () => import('./views/list-new/parts/rightColumn/v2/rightColumn.vue')
const ToDoListV3 = () => import('./views/list-new/parts/rightColumn/v3/rightColumn.vue')
const TasksListing = () => import('./views/tasks-listing/TasksListing.vue')

export default [
    {
        path: '/task',
        name: 'todo',
        component: TasksLayout,
        redirect: { name: 'todo-edit' },
        children: [
            {
                path: 'listing',
                name: 'tasks-listing',
                component: TasksListing,
                meta: {
                    inviteType: 'task',
                    title: 'Tasks List',
                },
            },
            {
                path: 'list',
                name: 'todo-list',
                component: ListNew,
                redirect: { name: 'tasks-listing' },
                children: [
                    {
                        path: 'v2',
                        name: 'todo-list-v2',
                        component: ToDoListV2,
                        meta: {
                            schema: 'todos',
                            filtersName: 'todos',
                            inviteType: 'task',
                            title: 'Tasks List - Projects',
                        },
                    },
                    {
                        path: 'v3',
                        name: 'todo-list-v3',
                        component: ToDoListV3,
                        meta: {
                            schema: 'todos',
                            filtersName: 'todos',
                            inviteType: 'task',
                            title: 'Tasks List - Kanban',
                        },
                    },
                ],
            },
            {
                path: 'edit/:id',
                name: 'todo-edit',
                component: View,
                meta: {
                    schema: 'todos',
                    filtersName: 'todos',
                    edit: true,
                    inviteType: 'task',
                    title: 'Edit Task',
                },
            },
            {
                path: 'view/:id',
                name: 'todo-view',
                component: View,
                meta: {
                    schema: 'todos',
                    filtersName: 'todos',
                    edit: true,
                    inviteType: 'task',
                    title: 'Task Details',
                },
            },
        ],
    },
]
