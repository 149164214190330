<template>
    <div class="io-rfis-tab">
        <Placeholder
            :subtitle="$t('There are no RFIs on this drawing yet. Click Add RFI to add one.')"
            iconColor="var(--main-lighten-2)"
            icon="icon-comment-2-text"
        />
    </div>
</template>

<script lang="ts" src="./Rfis.ts"></script>
<style lang="scss" src="./Rfis.scss" scoped></style>
