import store from '@/store/index'
import ProjectSettings from './routes/project-settings.js'
import PayAppsRouter from './routes/payapps.js'
import { getProjectAccess } from './routes/access.js'
import RfisRouter from '@/io-modules/rfis/routes.js'
import appTypes from '@/base/appTypes.ts'
import ApprovalsListItemStatus
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListItemStatus.ts'
import ApprovalsListItemScope
    from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsListItemScope.ts'
import ApprovalsInboxTab from '@/interfaces/modules/projects/financial-approvals/approvals-inbox/ApprovalsInboxTab'
import { isFeatureFlagEnabled } from '@/modules/projects/routes/feature-flag-status'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'

import budgetRoutes, { budgetBackwardCompatibilityRoutes } from '@/io-modules/budget/routes/routes.js'
import budgetChangesRoutes from '@/io-modules/budget-changes/routes/routes.ts'
import contractChangesRoutes from '@/modules/projects/modules/apps/common/contract-changes/routes.ts'
import approvalsInboxV3Routes from '@/io-modules/approvals-inbox/routes.ts'

const Forecasting = () => import(/* webpackChunkName: "Forecasting" */ '@/modules/projects/modules/apps/common/forecasting/Forecasting.vue')

const ApprovalsInbox = () => import(/* webpackChunkName: "approvals-inbox" */ '@/modules/projects/modules/apps/common/approvals-inbox/ApprovalsInbox.vue')

const Main = () => import(/* webpackChunkName: "projects" */ '@/modules/projects/modules/apps/common/main')
const MainLayout = () => import(/* webpackChunkName: "projects" */ '@/modules/projects/layout/main.vue')

const EditDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue')
const ViewDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/view.vue')
const ListDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/list.vue')

const ProjectList = () => import(/* webpackChunkName: "projects" */ '@/modules/projects/views/list/List.vue')
const ProjectWizard = () => import(/* webpackChunkName: "projects" */ '@/modules/projects/views/project-wizard/ProjectWizard.vue')

// progress reports
const ProgressReportMainGC = () => import(/* webpackChunkName: "progress-reports" */ '@/modules/projects/modules/apps/gc/progress-report-edit/main.vue')
const ProgressReportMainDEV = () => import(/* webpackChunkName: "progress-reports" */ '@/modules/projects/modules/apps/dev/progress-report-edit/main.vue')
const ProgressReportMainAC = () => import(/* webpackChunkName: "progress-reports" */ '@/modules/projects/modules/apps/ac/progress-report-edit/main.vue')
const ProgressReportMainSC = () => import(/* webpackChunkName: "progress-reports" */ '@/modules/projects/modules/apps/sc/progress-report-edit/main.vue')

// project import
const ProjectImport = () => import(/* webpackChunkName: "projects" */ '@/modules/projects/modules/apps/common/project-import/main.vue')

const ProjectDashboardV2 = () => import(/* webpackChunkName: "projects" */ '@/io-modules/project-dashboard/main.vue')

// pay apps views
const LienWaiverManagement = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/lien-waiver-management/LienWaiverManagement.vue')
const LienWaiverReview = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/lien-waiver-review/LienWaiverReview.vue')
const PayApps = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/list.vue')
const PayAppsMyPayApps = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/parts/myPayapps.vue')
const PayAppsBillingPeriod = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/parts/tab-billing-period.vue')
const PayAppsBillingPeriodNew = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/package-listing/package-listing.vue')
const PayAppsContractors = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/parts/tab-contractors.vue')
const PayAppsContractorDetails = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/contractor-details/contractor-details.vue')
const PayAppsAllInvoices = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/parts/all-invoices.vue')
const InvoiceMain = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/InvoiceMain.vue')
const InvoiceDetails = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/details/Details.vue')
const InvoiceFunding = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/funding/Funding.vue')
const InvoiceLineItems = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/line-items/LineItems.vue')
const InvoiceDocuments = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/parts/tab-documents/documents.vue')
const InvoiceAttached = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/parts/tabs-box/attached.vue')
const InvoiceTimeline = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/parts/tabs-box/TabsBoxTimeline.vue')
const InvoiceApprovalProcess = () => import(/* webpackChunkName: "payapps" */ '@/modules/projects/modules/apps/common/pay-apps/views/approvals-process/ApprovalProcess.vue')

// documents
const DocumentsList = () => import(/* webpackChunkName: "documents" */ '@/modules/documents/main/main.vue')
const DocumentDetails = () => import(/* webpackChunkName: "documents" */ '@/modules/documents/details/details.vue')

const RedesignedBidRequestsComponent = () => import(/* webpackChunkName: "bid-requests" */ '@/io-modules/bid-requests/views/listing/BidPackageList.vue')
const BidRequestCreateWizardComponent = () => import(/* webpackChunkName: "bid-requests-create" */ '@/io-modules/bid-requests/views/wizard/BidRequestWizard.vue')
const BidRequestDetailsViewComponent = () => import(/* webpackChunkName: "project-bid-requests-view-details" */ '@/io-modules/bid-requests/views/bid-package-details/BidPackageDetails.vue')
const BidRequestBidLevelingViewComponent = () => import(/* webpackChunkName: "project-bid-requests-view-bid-leveling" */ '@/io-modules/bid-requests/views/bid-package-bid-leveling/BidPackageBidLeveling.vue')
const BidRequestTimelineViewComponent = () => import(/* webpackChunkName: "project-bid-requests-view-timeline" */ '@/io-modules/bid-requests/views/bid-package-timeline/BidPackageTimeline.vue')

// project journal
const ProjectJournalMain = () => import(/* webpackChunkName: "projects" */ '@/modules/projects/modules/apps/common/project-journal/main.vue')

// purchase orders
const PurchaseOrdersList = () => import(/* webpackChunkName: "project-purchase-orders-list" */ '@/modules/projects/modules/apps/common/purchase-orders/list/PurchaseOrdersList.vue')
const PurchaseOrderDetail = () => import(/* webpackChunkName: "project-purchase-order-detail" */ '@/modules/projects/modules/apps/common/purchase-orders/detail/PurchaseOrderDetail.vue')
const PurchaseOrderCreate = () => import(/* webpackChunkName: "project-purchase-order-creation" */ '@/modules/projects/modules/apps/common/purchase-orders/create/PurchaseOrderCreate.vue')

const DirectCosts = () => import(/* webpackChunkName: "direct cost" */ '@/modules/projects/modules/apps/common/direct-costs/DirectCosts.vue')
const DirectCostsDuplicate = () => import(/* webpackChunkName: "direct cost duplicate" */ '@/modules/projects/modules/apps/common/direct-costs/views/duplicate/DirectCostDuplicateView.vue')
const DirectCostDetailsView = () => import(/* webpackChunkName: "project-direct-costs-details" */ '@/modules/projects/modules/apps/common/direct-costs/views/details/DirectCostDetailsView.vue')
const DirectCostAddView = () => import(/* webpackChunkName: "project-direct-costs-add" */ '@/modules/projects/modules/apps/common/direct-costs/views/add/DirectCostAddView.vue')
const DirectCostEditView = () => import(/* webpackChunkName: "project-direct-costs-edit" */ '@/modules/projects/modules/apps/common/direct-costs/views/edit/DirectCostEditView.vue')

// get app type
const appType = localStorage.getItem('appType')

// progress report map
// main components by app type
const progressReportsMap = {}
progressReportsMap[appTypes.TYPE_GC] = ProgressReportMainGC
progressReportsMap[appTypes.TYPE_SC] = ProgressReportMainSC
progressReportsMap[appTypes.TYPE_AC] = ProgressReportMainAC
progressReportsMap[appTypes.TYPE_DEV] = ProgressReportMainDEV
progressReportsMap[appTypes.TYPE_OWNER] = ProgressReportMainDEV
progressReportsMap[appTypes.TYPE_INVESTOR] = ProgressReportMainDEV
progressReportsMap[appTypes.TYPE_GENIUS] = ProgressReportMainDEV

// set main component for progress reports by app type
const progressReportMainComponentType = progressReportsMap[appType] === undefined ? 'Main' : 'Custom'

async function getAuthDataProject () {
    return new Promise((resolve) => {
        if (Object.keys(store.state.appStore.authData).length === 0) {
            const unwatch = store.watch(
                () => store.state.appStore.authData,
                (value) => {
                    unwatch()
                    resolve(value)
                },
            )
        } else {
            resolve(store.state.appStore.authData)
        }
    })
}

async function checkOpenCaAccess (to, from, next) {
    // to prevent endless loop
    if (to.name === 'project-no-access') {
        return next()
    }

    const authData = await getAuthDataProject()
    if (authData.is_open_ca || authData.is_open_ca_plus) {
        return next({ name: 'project-no-access', params: { pid: to.params.pid } })
    }
    return next()
}

// routes
const routes = []
// main project children routes container
const projectChildren = [
    budgetBackwardCompatibilityRoutes,
    budgetRoutes,
    budgetChangesRoutes,
    approvalsInboxV3Routes,
]

// ///////////////////////////////////////
// prepare routes config for export //////
// ///////////////////////////////////////

// listing v2
routes.push({
    path: '/project/list',
    name: 'project-list',
    component: ProjectList,
    meta: {
        title: 'Projects List',
    },
    async beforeEnter (to, from, next) {
        await isFeatureFlagEnabled(FeatureFlagsConsts.STICKY_FILTERS, false)
        next()
    },
})

routes.push({
    path: '/project-add',
    name: 'project-add',
    component: ProjectWizard,
    async beforeEnter (to, from, next) {
        if (await isFeatureFlagEnabled(FeatureFlagsConsts.PROJECT_CREATION_NEW_WIZARD, false)) {
            next()
        } else {
            next({ name: 'project-list' })
        }
    },
    meta: {
        title: 'Add Project',
    },
})

// project import
routes.push({
    path: '/project/import',
    name: 'project-import',
    component: ProjectImport,
    meta: { schema: 'projects', title: 'Import Projects' },
})


// progress report add custom main component if available
if (progressReportMainComponentType === 'Custom') {
    routes.push({
        path: '/project/:pid/progress-report-edit/:id?',
        name: 'progress-report-edit',
        component: progressReportsMap[appType],
        meta: { name: 'Progress Report', new: false, view: false, edit: true, title: 'Edit Progress Report' },
        async beforeEnter (to, from, next) {
            const result = await getProjectAccess(...arguments)
            return result
        },
    })
    routes.push({
        path: '/project/:pid/progress-report-new',
        name: 'progress-report-new',
        component: progressReportsMap[appType],
        meta: { name: 'Progress Report', new: true, view: false, edit: false, title: 'Add Progress Report' },
        async beforeEnter (to, from, next) {
            const result = await getProjectAccess(...arguments)
            return result
        },
    })
    routes.push({
        path: '/project/:pid/progress-report-view/:id?',
        name: 'progress-report-view',
        component: progressReportsMap[appType],
        meta: { name: 'Progress Report', new: false, view: true, edit: false, title: 'Progress Report Details' },
        async beforeEnter (to, from, next) {
            const result = await getProjectAccess(...arguments)
            return result
        },
    })
}

// /////////////////////////////
// / Project children routes ///
// ////////////////////////////

// progress report listing
projectChildren.push({
    path: 'progress-reports',
    name: 'progress-reports',
    component: ListDefault,
    meta: {
        name: 'Progress Reports',
        schema: 'projects/modules/apps/' + appType + '/progress-reports',
        search: true,
        title: 'Project - Progress Reports',
    },
})

// progress report add custom main component if available
if (progressReportMainComponentType === 'Main') {
    projectChildren.push({
        path: 'progress-report-edit/:id?',
        name: 'progress-report-edit',
        component: Main,
        meta: { name: 'Progress Report', new: false, view: false, edit: true, title: 'Project - Edit Progress Report' },
    })
    projectChildren.push({
        path: 'progress-report-new/:id?',
        name: 'progress-report-new',
        component: Main,
        meta: { name: 'Progress Report', new: true, view: false, edit: false, title: 'Project - Add Progress Report' },
    })
    projectChildren.push({
        path: 'progress-report-view/:id?',
        name: 'progress-report-view',
        component: Main,
        meta: {
            name: 'Progress Report',
            new: false,
            view: true,
            edit: false,
            title: 'Project - Progress Report Details',
        },
    })
}

const payAppsMeta = {
    name: 'Invoicing & Pay Applications',
    search: false,
    beta: false,
    navigationKey: 'payapps',
    dontShowMenu: true,
    customClass: 'io-payapps',
    breadcrumbs: [
        {
            text: 'Projects',
            to: { name: 'project-list' },
        },
    ],
}

// pay apps
projectChildren.push({
    path: 'payapps',
    component: PayApps,
    children: [
        {
            path: '/project/:pid/payapps',
            name: 'payapps-list',
            component: PayAppsMyPayApps,
            meta: { ...payAppsMeta, title: 'Payapps - List' },
        },
        {
            path: ':id/lien-waiver/:lienWaiverId/:action',
            name: 'lien-waiver-management',
            component: LienWaiverManagement,
            meta: { ...payAppsMeta, title: 'Lien Waiver Management' },
        },
        {
            path: ':id/lien-waiver-review/:lienWaiverId/:action',
            name: 'lien-waiver-review',
            component: LienWaiverReview,
            meta: { ...payAppsMeta, title: 'Lien Waiver Review' },
        },
        {
            path: ':id/lien-waiver-view/:lienWaiverId/:invoice/:action',
            name: 'lien-waiver-view',
            component: LienWaiverReview,
            meta: { ...payAppsMeta, title: 'Lien Waiver Review' },
        },
        {
            path: 'all-invoices',
            name: 'payapps-all-invoices',
            component: PayAppsAllInvoices,
            meta: { ...payAppsMeta, title: 'Payapps - All Invoices' },
        },
        {
            path: 'periods',
            name: 'payapps-billing-periods',
            component: PayAppsBillingPeriod,
            meta: { ...payAppsMeta, title: 'Payapps - Billing Periods' },
        },
        {
            path: 'periods-new',
            name: 'payapps-billing-periods-new',
            component: PayAppsBillingPeriodNew,
            meta: { ...payAppsMeta, title: 'Payapps - Billing Periods' },
        },
        {
            path: 'contractors',
            name: 'payapps-contractors',
            component: PayAppsContractors,
            meta: { ...payAppsMeta, title: 'Payapps - Contractors' },
        },
        {
            path: 'contractor/:id',
            name: 'payapps-contractor-details',
            component: PayAppsContractorDetails,
            meta: { ...payAppsMeta, title: 'Payapps - Contractor Details' },
        },
        {
            path: ':id',
            name: 'payapps-invoice',
            component: InvoiceMain,
            redirect: { name: 'payapps-invoice-details' },
            meta: payAppsMeta,
            children: [
                {
                    path: 'details',
                    name: 'payapps-invoice-details',
                    component: InvoiceDetails,
                    meta: { ...payAppsMeta, title: 'Payapps - Invoice Details' },
                },
                {
                    path: 'sov',
                    name: 'payapps-invoice-sov',
                    component: InvoiceLineItems,
                    meta: { ...payAppsMeta, title: 'Payapps - Invoice SOV' },
                },
                {
                    path: 'attached',
                    name: 'payapps-invoice-attached',
                    component: InvoiceAttached,
                    meta: { ...payAppsMeta, title: 'Payapps - Invoice Attached' },
                    children: [
                        {
                            path: ':attachid/details',
                            name: 'payapps-invoice-attached-open',
                            component: InvoiceMain,
                            redirect: { name: 'payapps-invoice-details' },
                            meta: payAppsMeta,
                        },
                    ],
                },
                {
                    path: 'documents',
                    name: 'payapps-invoice-documents',
                    component: InvoiceDocuments,
                    meta: { ...payAppsMeta, title: 'Payapps - Invoice Documentats' },
                },
                {
                    path: 'funding',
                    name: 'payapps-invoice-funding',
                    component: InvoiceFunding,
                    meta: { ...payAppsMeta, title: 'Payapps - Invoice Funding' },
                },
                {
                    path: 'approval-process',
                    name: 'payapps-invoice-approval-process',
                    component: InvoiceApprovalProcess,
                    meta: { ...payAppsMeta, title: 'Payapps - Approval Process' },
                },
                {
                    path: 'timeline',
                    name: 'payapps-invoice-timeline',
                    component: InvoiceTimeline,
                    meta: { ...payAppsMeta, title: 'Payapps - Invoice Timeline' },
                },
            ],
        },
        {
            path: ':id/attached/:attachid',
            name: 'payapps-attached-invoice',
            component: InvoiceMain,
            meta: payAppsMeta,
            children: [
                {
                    path: 'details',
                    name: 'payapps-attached-invoice-details',
                    component: InvoiceDetails,
                    meta: { ...payAppsMeta, title: 'Payapps - Attached Invoice Details' },
                },
                {
                    path: 'sov',
                    name: 'payapps-attached-invoice-sov',
                    component: InvoiceLineItems,
                    meta: { ...payAppsMeta, title: 'Payapps - Attached Invoice SOV' },
                },
                {
                    path: 'documents',
                    name: 'payapps-attached-invoice-documents',
                    component: InvoiceDocuments,
                    meta: { ...payAppsMeta, title: 'Payapps - Attached Invoice Documents' },
                },
                {
                    path: 'funding',
                    name: 'payapps-attached-invoice-funding',
                    component: InvoiceFunding,
                    meta: { ...payAppsMeta, title: 'Payapps - Attached Invoice Funding' },
                },
                {
                    path: 'timeline',
                    name: 'payapps-attached-invoice-timeline',
                    component: InvoiceTimeline,
                    meta: { ...payAppsMeta, title: 'Payapps - Attached Invoice Timeline' },
                },
            ],
        },
        {
            path: 'payapp-non-contracted/:id',
            name: 'payapps-non-contracted-invoice-details',
            component: InvoiceMain,
            meta: { ...payAppsMeta, title: 'Payapps - Non Contracted Invoice Details' },
        },
    ],
})

// project dashboard
projectChildren.push({
    path: 'project-dashboard-v2',
    redirect: { name: 'project-dashboard' },
})

// project dashboard V2
projectChildren.push({
    path: 'project-dashboard',
    name: 'project-dashboard',
    component: ProjectDashboardV2,
    meta: {
        name: 'Project Dashboard V2',
        ignoreProjectLayout: true,
        title: 'Project Dashboard',
        navigationKey: 'project_dashboard_v2',
    },
})

// project journal
projectChildren.push({
    path: 'project-journal/:id?',
    name: 'project-journal',
    component: ProjectJournalMain,
    meta: {
        name: 'Project Journal',
        customRouteName: 'project-journal',
        ignoreProjectLayout: true,
        title: 'Project Journal',
        navigationKey: 'project_journal',
    },
})

projectChildren.push({
    path: 'cash-flow-and-forecasting/shared/:cashFlowId/:tabName?',
    name: 'cash-flow-and-forecasting-shared',
    component: Forecasting,
    meta: {
        name: 'Cash Flow',
        ignoreProjectLayout: true,
        title: 'Cash Flow and Forecasting',
        shared: true,
        navigationKey: 'project_forecasting',
    },
})

projectChildren.push({
    path: 'cash-flow-and-forecasting/shared/:cashFlowId/cost-code/:costCodeId?',
    name: 'cash-flow-and-forecasting-cost-code-view-shared',
    component: Forecasting,
    meta: {
        name: 'Cash Flow',
        ignoreProjectLayout: true,
        title: 'Cash Flow and Forecasting',
        shared: true,
        navigationKey: 'project_forecasting',
    },
})

projectChildren.push({
    path: 'cash-flow-and-forecasting/:cashFlowId?/:tabName?',
    name: 'cash-flow-and-forecasting',
    component: Forecasting,
    meta: {
        name: 'Cash Flow',
        ignoreProjectLayout: true,
        title: 'Cash Flow and Forecasting',
        shared: false,
        navigationKey: 'project_forecasting',
    },
})

projectChildren.push({
    path: 'cash-flow-and-forecasting/:cashFlowId?/cost-code/:costCodeId?',
    name: 'cash-flow-and-forecasting-cost-code-view',
    component: Forecasting,
    meta: {
        name: 'Cash Flow',
        ignoreProjectLayout: true,
        title: 'Cash Flow and Forecasting',
        shared: false,
        navigationKey: 'project_forecasting',
    },
})

projectChildren.push({
    path: 'approvals',
    name: 'approvals-inbox',
    component: ApprovalsInbox,
    meta: {
        name: 'Approvals',
        ignoreProjectLayout: false,
        title: 'Approvals Inbox',
        status: ApprovalsListItemStatus.PENDING,
        scope: ApprovalsListItemScope.USER,
        tab: ApprovalsInboxTab.USER_PENDING,
        navigationKey: 'approvals_inbox',
        customClass: 'io-approvals-module',
        dontShowMenu: true,
    },
})

projectChildren.push({
    path: 'approvals-company-pending',
    name: 'approvals-inbox-company-pending',
    component: ApprovalsInbox,
    meta: {
        name: 'Approvals',
        ignoreProjectLayout: false,
        title: 'Approvals Inbox - Company Pending',
        status: ApprovalsListItemStatus.PENDING,
        scope: ApprovalsListItemScope.COMPANY,
        tab: ApprovalsInboxTab.COMPANY_PENDING,
        navigationKey: 'approvals_inbox',
        customClass: 'io-approvals-module',
        dontShowMenu: true,
    },
})

projectChildren.push({
    path: 'approvals-company-rejected',
    name: 'approvals-inbox-company-rejected',
    component: ApprovalsInbox,
    meta: {
        name: 'Approvals',
        ignoreProjectLayout: false,
        title: 'Approvals Inbox - Rejected',
        status: ApprovalsListItemStatus.REJECTED,
        scope: ApprovalsListItemScope.COMPANY,
        tab: ApprovalsInboxTab.COMPANY_REJECTED,
        navigationKey: 'approvals_inbox',
        customClass: 'io-approvals-module',
        dontShowMenu: true,
    },
})

projectChildren.push({
    path: 'project-cost-codes',
    name: 'project-cost-codes',
    component: Main,
    meta: { name: 'Project Cost Codes', title: 'Project Cost Codes', navigationKey: 'projectbudgetcodes' },
})

// direct costs
projectChildren.push({
    path: 'direct-costs',
    children: [
        {
            path: '',
            name: 'project-direct-costs',
            component: DirectCosts,
            meta: {
                name: 'Direct Costs',
                title: 'Direct Costs',
                navigationKey: 'direct_costs',
                ignoreProjectLayout: true,
            },
        },
        {
            path: 'add',
            name: 'project-direct-costs-add',
            component: DirectCostAddView,
            meta: {
                name: 'Add Direct Costs',
                title: 'Add Direct Costs',
                navigationKey: 'direct_costs',
                ignoreProjectLayout: true,
            },
        },
        {
            path: ':id',
            name: 'project-direct-costs-details',
            component: DirectCostDetailsView,
            meta: {
                name: 'Direct Cost Details',
                title: 'Direct Cost Details',
                navigationKey: 'direct_costs',
                ignoreProjectLayout: true,
            },
        },
        {
            path: ':id/copy',
            name: 'project-direct-costs-copy',
            component: DirectCostsDuplicate,
            meta: {
                name: 'Copy Direct Cost',
                title: 'Duplicate Direct Cost',
                navigationKey: 'direct_costs',
                ignoreProjectLayout: true,
            },
        },
        {
            path: ':id/edit',
            name: 'project-direct-costs-edit',
            component: DirectCostEditView,
            meta: {
                name: 'Edit Direct Cost',
                title: 'Edit Direct Cost',
                navigationKey: 'direct_costs',
                ignoreProjectLayout: true,
            },
            props: true,
        },
    ],
})

// project qualifications
projectChildren.push({
    path: 'qualifications',
    name: 'qualifications',
    component: Main,
    meta: { name: 'My Project Scope & Exclusions', title: 'Project Qualifications' },
})
// project proposal details
projectChildren.push({
    path: 'proposal-details',
    name: 'proposal-details',
    component: Main,
    meta: { name: 'My Proposal Details', title: 'Project Proposal Details', navigationKey: 'proposal_details' },
})

// project bid requests
projectChildren.push({
    path: '/project/:pid/bid-requests/:type?',
    name: 'project-bid-requests',
    component: RedesignedBidRequestsComponent,
    meta: { name: 'Bid Packages', title: 'Bid Packages List', inviteType: 'rfp', navigationKey: 'bidrequests' },
})

projectChildren.push({
    path: '/project/:pid/bid-requests-shared/:type?',
    name: 'project-bid-requests-shared',
    component: RedesignedBidRequestsComponent,
    meta: { name: 'Bid Packages', title: 'Bid Packages List Shared', inviteType: 'rfp', navigationKey: 'bidrequests' },
})

routes.push({
    path: '/project/:pid/bid-requests-create',
    name: 'project-bid-requests-wizard',
    component: BidRequestCreateWizardComponent,
    meta: { name: 'Bid Packages', title: 'Bid Packages Wizard', inviteType: 'rfp', navigationKey: 'bidrequests' },
})

routes.push({
    path: '/project/:pid/bid-requests/:package/edit/:tab?',
    name: 'project-bid-requests-wizard-edit',
    component: BidRequestCreateWizardComponent,
    meta: { name: 'Bid Packages', title: 'Bid Packages Wizard', inviteType: 'rfp', navigationKey: 'bidrequests' },
})

projectChildren.push({
    path: '/project/:pid/bid-requests/:package/details',
    name: 'project-bid-requests-view-details',
    component: BidRequestDetailsViewComponent,
    meta: { name: 'Bid Packages', title: 'Bid Package Details', inviteType: 'rfp', navigationKey: 'bidrequests' },
})

projectChildren.push({
    path: '/project/:pid/bid-requests/:package/bid-leveling',
    name: 'project-bid-requests-view-bid-leveling',
    component: BidRequestBidLevelingViewComponent,
    meta: { name: 'Bid Packages', title: 'Bid Package Bid Leveling', inviteType: 'rfp', navigationKey: 'bidrequests' },
})

projectChildren.push({
    path: '/project/:pid/bid-requests/:package/timeline',
    name: 'project-bid-requests-view-timeline',
    component: BidRequestTimelineViewComponent,
    meta: { name: 'Bid Packages', title: 'Bid Package Timeline', inviteType: 'rfp', navigationKey: 'bidrequests' },
})

// project todos
projectChildren.push({
    path: 'tasks',
    name: 'project-todos',
    component: Main,
    meta: { name: 'Tasks', inviteType: 'task', title: 'Project Tasks', navigationKey: 'todos' },
})
projectChildren.push({
    path: 'tasks-kanban',
    name: 'project-kanban',
    component: Main,
    meta: { name: 'Tasks', inviteType: 'task', title: 'Project Tasks Kanban', navigationKey: 'todos' },
})

//  project rfi view
projectChildren.push({
    path: 'rfi/view/:id',
    name: 'project-rfi-view',
    component: Main,
    meta: {
        name: 'RFIs',
        viewMode: true,
        customRouteName: 'rfis-details',
        beta: false,
        title: 'Project RFI Details',
        navigationKey: 'rfi',
    },
})
// project rfi edit
projectChildren.push({
    path: 'rfi/edit/:id',
    name: 'project-rfi-edit',
    component: Main,
    meta: {
        name: 'RFIs',
        editMode: true,
        inviteType: 'rfi',
        customRouteName: 'rfis-details',
        beta: false,
        title: 'Project Edit RFI',
        navigationKey: 'rfi',
    },
})

projectChildren.push(...contractChangesRoutes)

// project communication - temporarily hide
/*projectChildren.push({
    path: 'email',
    name: 'project-email',
    component: Communication,
    props: {
        showNavbar: false
    },
    meta: {
        name: 'Email',
        customRouteName: 'communication',
        title: 'Project Communication',
        communicationOptions: {
            detailsRouteName: 'project-email-details',
            showNavbar: false,
            showProjectEmail: true
        }
    },
    children: [
        {
            path: ':id',
            name: 'project-email-details',
            component: CommunicationDetails,
            meta: {
                name: 'Email',
                customRouteName: 'communication',
                schema: 'communication',
                title: 'Project Communication',
                communicationOptions: {
                    detailsRouteName: 'project-email-details',
                    showNavbar: false,
                    showProjectEmail: true
                }
            }
        }
    ]
})*/

// bills
projectChildren.push({
    path: 'bills',
    name: 'project-bills',
    component: ListDefault,
    meta: { name: 'Project bills', schema: 'bills/projects', search: true, title: 'Project Bills' },
})

// project RFI listing
projectChildren.push({
    path: 'rfi',
    name: 'project-rfi',
    component: ListDefault,
    meta: {
        name: 'RFIs',
        schema: 'projects/modules/apps/common/project-rfi',
        search: true,
        beta: false,
        title: 'Project RFIs List',
        navigationKey: 'rfi',
    },
})

// project no access
projectChildren.push({
    path: 'project-access',
    name: 'project-no-access',
    component: Main,
    meta: { customRouteName: 'project-no-access', title: 'Project No Access' },
})

// project documents details
routes.push({
    path: '/project/:pid/documents-details/:id',
    name: 'project-documents-details',
    meta: { name: 'Documents', inviteType: 'document', title: 'Project Documents Details', navigationKey: 'documents' },
    component: DocumentDetails,
    children: [
        {
            path: ':annotationMode',
            name: 'project-documents-details-annotation-mode',
            component: DocumentsList,
            meta: {
                name: 'Documents',
                inviteType: 'document',
                title: 'Project Documents Details',
                navigationKey: 'documents',
            },
        },
    ],
    async beforeEnter (to, from, next) {
        const result = await getProjectAccess(...arguments)
        return result
    },
})

// project documents listing
projectChildren.push({
    path: 'documents-list/:folderId?',
    name: 'project-documents-list',
    meta: {
        name: 'Documents',
        inviteType: 'document',
        projectView: true,
        title: 'Project Documents List',
        navigationKey: 'documents',
    },
    component: DocumentsList,
})

// purchase orders
projectChildren.push({
    path: 'purchase-orders',
    name: 'project-purchase-orders',
    component: PurchaseOrdersList,
    meta: { name: 'Purchase Orders', title: 'Purchase Orders List' },
    async beforeEnter (to, from, next) {
        return await checkOpenCaAccess(...arguments)
    },
})
// purchase order
projectChildren.push({
    path: 'purchase-orders/:id/details',
    name: 'project-purchase-order',
    component: PurchaseOrderDetail,
    meta: { name: 'Purchase Orders', title: 'Purchase Orders Details' },
    async beforeEnter (to, from, next) {
        return await checkOpenCaAccess(...arguments)
    },
})
// purchase order create
projectChildren.push({
    path: 'purchase-orders/create',
    name: 'purchase-order-create',
    component: PurchaseOrderCreate,
    meta: {
        name: 'PurchaseOrderCreate',
        edit: false,
        title: 'Add Purchase Order',
        dontShowMenu: true,
    },
    async beforeEnter (to, from, next) {
        return await checkOpenCaAccess(...arguments)
    },
})

// purchase order edit
projectChildren.push({
    path: 'purchase-orders/:id/edit',
    name: 'purchase-order-edit',
    component: PurchaseOrderCreate,
    meta: {
        name: 'PurchaseOrderEdit',
        edit: true,
        title: 'Edit Purchase Order',
        dontShowMenu: true,
    },
    async beforeEnter (to, from, next) {
        return await checkOpenCaAccess(...arguments)
    },
})


// main project
routes.push({
    path: '/project/:pid',
    name: 'project',
    component: MainLayout,
    redirect: { name: appType === appTypes.TYPE_DEV || appType === appTypes.TYPE_OWNER ? 'project-dashboard' : 'project-summary' },
    // redirect: {name: 'project-summary'},
    children: projectChildren,
    meta: {
        title: 'Project Details',
    },
    async beforeEnter (to, from, next) {
        return await getProjectAccess(...arguments)
    },
})

// project directories new
routes.push({
    path: '/project/:pid/project-directory',
    name: 'project-directory',
    component: Main,
    meta: {
        name: 'Project Team / Directory',
        inviteType: 'project_directory',
        title: 'Project Directory',
        navigationKey: 'project_connections',
    },
    async beforeEnter (to, from, next) {
        const result = await getProjectAccess(...arguments)
        return result
    },
})

routes.push({
    path: '/project/:pid/project-directory/timeline',
    name: 'project-directory-timeline',
    component: Main,
    meta: {
        name: 'Project Directory',
        inviteType: 'project_directory',
        title: 'Project Directory Timeline',
        customRouteName: 'project-directory',
        navigationKey: 'project_connections',
    },
    async beforeEnter (to, from, next) {
        const result = await getProjectAccess(...arguments)
        return result
    },
})

// project directories new
routes.push({
    path: '/project/:pid/photos',
    name: 'photos',
    component: Main,
    meta: {
        name: 'Project Photos',
        inviteType: 'document',
        title: 'Project Photos',
        navigationKey: 'photos',
    },
    async beforeEnter (to, from, next) {
        const result = await getProjectAccess(...arguments)
        return result
    },
})

routes.push(ProjectSettings)
routes.push(PayAppsRouter)
routes.push(RfisRouter)
export default routes
