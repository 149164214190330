<template>
    <div class="io-single-annotation shadow" :class="{ 'io-selected': isSelected }" @click.stop="selectAnnotation">
        <div class="flex flex-col gap-16-px">
            <div class="flex flex-col gap-8-px">
                <div class="flex justify-between items-center">
                    <div class="flex items-center">
                        <div class="flex items-center text-sm-px">
                            <i class="icon-comment-2-text text-main-lighten-3 mr-8-px text-lg-px"/>

                            <span class="text-main-lighten-3 mr-4-px">{{ $t('RFI ID') }}:</span>

                            <span class="font-semibold">{{ data.rfi_v2.internal_id }}</span>
                        </div>
                    </div>

                    <RouterLink class="flex" target="_blank"
                                :to="{ name: 'rfis-show', params: { rfiId: data.rfi_v2.id }, query: { 'sidebar-tab': 'details' } }">
                        <i class="icon-external-link text-main-lighten-3 ml-8-px text-lg-px"/>
                    </RouterLink>
                </div>

                <StatusPill
                    class="self-start"
                    :text="rfiStatuses[data.rfi_v2.status].text"
                    :variant="rfiStatuses[data.rfi_v2.status].pillVariant"
                />
            </div>

            <hr class="hr m-0 bg-darker-bg"/>

            <div class="flex justify-between items-center">
                <Avatar
                    :employee-mongo-id="data.created_by_full_name.id"
                    :employee-full-name="data.created_by_full_name.name"
                    :company-name="data.created_by_full_name.position"
                    size="sm"
                    show-full-name
                />

                <div class="text-sm-px text-main-lighten-3">{{ $filters.formatDate(data.created_at) }}</div>
            </div>

            <div class="flex flex-col gap-16-px text-sm-px">
                <div class="font-semibold">{{ data.rfi_v2.title }}</div>

                <p>{{ data.rfi_v2.question }}</p>
            </div>

        </div>
    </div>
</template>

<script lang="ts" src="./SingleRfi.ts"></script>
