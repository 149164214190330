import { defineComponent } from 'vue'
import RightModal from '@/components/RightModal/RightModal.vue'
import SingleWorkflow
    from '@/modules/projects/modules/apps/common/project-settings/parts/financial-approvals/parts/single-workflow/SingleWorkflow.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import EligibleUsersService from '../../services/EligibleUsersService'
import ApprovalSubmissionUpdateWorkflow from '../../interfaces/ApprovalSubmissionUpdateWorkflow'
import financialApprovalsClient from '@/api/financial-approvals/financialApprovalsClient'
import ApprovalSubmissionStatus from '../../enums/ApprovalSubmissionStatus'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import ApprovalWorkflowsRouterService from '../../services/ApprovalWorkflowsRouterService'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource'
import { CostCodeCategory } from '@/interfaces/modules/projects/financial-approvals/CostCodeCategory'
import financialApprovalSubmissionClient from '@/api/financial-approvals/financialApprovalSubmissionClient.ts'

export default defineComponent({
    name: 'ApprovalWorkflowEditModal',
    components: {
        SingleWorkflow,
        RightModal,
        AlertBox,
    },
    data () {
        return {
            employees: [],
            isSavingInProgress: false,
            submissionId: '',
            workflows: [],
            costCodesCategories: [],
            costCodesEnabled: false,
        }
    },
    computed: {
        ...mapGetters('appStore', {
            getAuthData: 'getAuthData',
        }),

        ...mapState('financialApprovals', {
            approvalWorkflows: (state: any): any[] => state.approvalWorkflows,
            existingSubmissionId: (state: any): string => state.approvalSubmissionId,
            existingSubmissionCostCodesEnabled: (state: any): boolean => state.costCodesEnabled,
            companyProjectResourceId: (state: any): string => state.companyProjectResourceId,
            resourceId: (state: any): string => state.resourceId,
            activeResource: (state: any): string => state.activeResource,
            approvableResourceSubtype: (state: any) => state.approvableResourceSubtype,
        }),

        ...mapState('project', {
            projectObj: (state: any) => state.projectObj,
        }),

        isWorkflowDataValid (): boolean {
            return this.workflows.every(workflow => {
                return workflow.approvers.every(approver => Boolean(approver.user_id))
            })
        },

        isSaveEnabled (): boolean {
            return !this.isUnchanged && this.isWorkflowDataValid
        },

        isUnchanged (): boolean {
            return !this.isWorkflowDataValid
                || JSON.stringify(this.workflows) === JSON.stringify(this.approvalWorkflows)
        }
    },
    async created () {
        this.submissionId = this.existingSubmissionId
        this.costCodesEnabled = this.existingSubmissionCostCodesEnabled

        if (!this.submissionId) {
            const { data: { _id, cost_codes_enabled } } = await financialApprovalSubmissionClient.createApprovalSubmission({
                project_company_id: this.projectObj.project_local_id,
                approvable_resource_name: this.activeResource,
                resource_id: this.resourceId,
                status: ApprovalSubmissionStatus.DRAFT,
            })

            this.submissionId = _id
            this.costCodesEnabled = cost_codes_enabled

            await this.$store.dispatch('financialApprovals/getApprovalSubmissions', {
                resource_id: this.resourceId,
                project_company_id: this.projectObj.project_local_id,
                approvable_resource_name: this.activeResource,
            })
        }

        this.workflows = JSON.parse(JSON.stringify(this.approvalWorkflows))
    },
    async mounted () {
        this.employees = await EligibleUsersService.getSortedUsers(this.companyProjectResourceId)

        if (this.costCodesEnabled) {
            this.costCodesCategories = await this.fetchCostCodes()
        }
    },
    methods: {
        ...mapActions('financialApprovals', {
            getApprovalSubmissions: 'getApprovalSubmissions'
        }),

        closeModal (): void {
            this.$store.dispatch('modal/setShow', false)
        },

        handleCloseModal (): void {
            if (!this.isUnchanged) {
                this.showLeavingConfirmation(this.closeModal)

                return
            }

            this.closeModal()
        },

        async handleSave (): Promise<void> {
            if (await this.saveWorkflows()) {
                this.closeModal()
            }
        },

        handleGoToProjectSettings (): void {
            if (!this.isUnchanged) {
                this.showLeavingConfirmation(this.goToProjectSettings)

                return
            }

            this.goToProjectSettings()
        },

        goToProjectSettings (): void {
            this.$router.push({
                name: ApprovalWorkflowsRouterService.getProjectSettingsRouteName(
                    this.activeResource as Resource
                )
            })
        },

        async saveWorkflows (): Promise<boolean> {
            try {
                this.isSavingInProgress = true

                await financialApprovalSubmissionClient.updateSubmission(
                    this.submissionId,
                    this.mapWorkflowsForUpdateRequest()
                )

                await this.$store.dispatch('financialApprovals/getApprovalSubmissions', {
                    resource_id: this.resourceId,
                    project_company_id: this.projectObj.project_local_id,
                    approvable_resource_name: this.activeResource,
                })

                this.isSavingInProgress = false

                return true
            } catch (e) {
                this.showNotification('error', e.response.data.message)

                return false
            }
        },

        mapWorkflowsForUpdateRequest (): Array<ApprovalSubmissionUpdateWorkflow> {
            return this.workflows.map(workflow => ({
                id: workflow._id,
                limit_enabled: workflow.limit_enabled,
                approvers: workflow.approvers.map(approver => ({
                    id: approver._id,
                    assignment_type: approver.assignment_type,
                    user_id: approver.user_id,
                    limit_type: approver.limit_type,
                    limit_value: null !== approver.limit_value ? String(approver.limit_value) : null,
                    sequence_value: approver.sequence_value,
                })),
            }))
        },

        showLeavingConfirmation (leaveAction: () => void): void {
            this.showPopupAlert({
                title: this.$tc('You have unsaved changes. Would you like to save and leave or leave without saving?'),
                icon: 'icon-alert-triangle',
                buttons: [
                    {
                        text: this.$t('Leave Without Saving'),
                        class: 'io-btn-light',
                        action: leaveAction,
                    },
                    {
                        text: this.$t('Save and Leave'),
                        class: 'io-btn-primary',
                        action: () => this.saveWorkflows().then(leaveAction),
                    }
                ]
            })
        },

        async fetchCostCodes (): Promise<CostCodeCategory[]> {
            try {
                const { data } = await financialApprovalsClient.getEligibleCostCodes(this.projectObj.project_local_id, this.approvableResourceSubtype)

                return data as CostCodeCategory[]
            } catch (e) {
                this.showNotification('error', e.response.data.message)
            }

            return []
        },
    },
})
