<template>
    <div class="io-vendor-view">
        <LoadingScreen :custom-show-loading="showSmallLoader"/>
        <div v-if="!showSmallLoader && !showContentLoader">
            <VendorDetails/>
            <div 
                class="io-invoices-list" 
                :class="{'io-invoices-list--empty': !vendorsInvoices[this.vendorId] || !vendorsInvoices[this.vendorId].length}"
            >
                <InvoicesListHeader
                    :title="$t('Invoices')" 
                    @openManageColumns="$emit('openManageColumns')"
                />
                <InvoicesListTable 
                    :headers="invoicesHeaders" 
                    :items="invoicesItems"
                    :loading-data="loadingData"
                    @loadData="loadData"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./VendorDetailsView.ts"></script>
<style lang="scss" src="./VendorDetailsView.scss" scoped></style>
<style lang="scss" src="../../invoices-lists/InvoicesLists.scss" scoped></style>