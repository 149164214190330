<template>
    <div v-if="drawingsShown" class="io-select-reference-sheet-modal">
        <DrawingSets v-if="drawingSetsShown"/>

        <DrawingSheets v-if="drawingSheetsShown"/>

        <DrawingSheet v-if="drawingSheetShown"/>
    </div>
</template>

<script lang="ts" src="./DrawingPunch.ts"></script>
<style lang="scss" src="./DrawingPunch.scss" scoped></style>
