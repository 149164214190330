import { WbsQuotedItem } from '@/io-modules/project-contracts/interfaces/WbsItem.ts'

export default (wbsItems: WbsQuotedItem[]) => {
    return wbsItems.reduce((acc, item) => {
        if (!item.name || !item.costCode) {
            acc[item.projectSiteId] = false

            return acc
        }

        if (item.tasks.length) {
            acc = {
                ...item.tasks.reduce((taskAcc, task) => {
                    if (!task.description) {
                        taskAcc[item.projectSiteId] = false

                        return taskAcc
                    }

                    taskAcc[item.projectSiteId] = true

                    return taskAcc
                }, acc),
            }
        }

        acc[item.projectSiteId] = true

        return acc
    }, {})
}
