import { ApiClient } from '@/api/api'

let client = ApiClient

export default {
    postNewBatch (data) {
        let postData = {
            'name': data.name,
            'budget_code_template_id': data.selectedBudgetCodeTemplate?.id,
            'with_client': data.withClient,
            'client_id': data.selectedClient?.res_id,
            'client_mongo_id': data.selectedClient?.res_mongo_id
        }
        return client.post(`/data-importer`, postData)
    },
    patchBatch (data) {
        let patchData = {
            'id' : data.id,
            'name': data.name,
            'budget_code_template_id': data.selectedBudgetCodeTemplate?.id,
            'with_client': data.withClient,
            'client_id': data.selectedClient?.res_id,
            'client_mongo_id': data.selectedClient?.res_mongo_id
        }
        return client.patch(`/data-importer/${data.id}`, patchData)
    },
    deleteBatch (id) {
        return client.get(`/data-importer/delete/${id}`)
    },
    getBatch (id) {
        return client.get(`/data-importer/${id}`)
    },
    getDromoConfiguration (batchId = "") {
        return client.get(`/data-importer/dromo/${batchId}`)
    },
    getImportConfiguration () {
        return client.get(`/data-importer/configuration`)
    },
    getUploadErrors(batchId){
        return client.get(`/data-importer/${batchId}/upload-errors`)
    },
    postModelCache (batchId, model, data) {
        return client.post(`/data-importer/${batchId}/${model.toLowerCase()}`, data)
    },
    processImportData (id) {
        return client.post(`/data-importer/${id}/process-queue`)
    },
    postRetryModelCache (batchId, model, data) {
        return client.post(`/data-importer/${batchId}/${model.toLowerCase()}/retry-step`, data)
    },
    postModelValidation (batchId, model, data) {
        return client.post(`/data-importer/${batchId}/${model.toLowerCase()}/validate`, data)
    }
}
