import mathHelpers from '@/helpers/mathHelpers.ts'

// Extracted from iconLibrary
export function formatPercent (total: any, decimal: number = 0, withLeadingZero: boolean = false): string {
    if (typeof total === 'object') {
        total = 0
    }

    let prefix = ''

    if (withLeadingZero && String(total).length === 1) {
        prefix += '0'
    }

    return prefix + mathHelpers.cutPrecision(total, decimal) + '%'
}

export function formatPercentIfNotString (value: any): string {
    if (typeof value !== 'string') {
        return formatPercent(value, 2)
    } else {
        return value // ex. N/A
    }
}

export function formatPercentAsDecimal (value: any): string {
    if (typeof value !== 'string') {
        return formatPercent(value, 2)
    } else {
        return value // ex. N/A
    }
}

export function formatPercentWithDecimalIfNotInteger (value: any): string {
    const percent = formatPercent(value, 2)

    if (percent.endsWith('.00')) {
        return percent.slice(0, -3)
    }

    return percent
}