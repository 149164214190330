import { defineComponent } from 'vue'
import Status from '@/interfaces/components/accordion/Status'
import StatusDot from '@/components/atoms/status-dot/StatusDot.vue'
import StatusDotInterface from '@/interfaces/components/card/StatusDot'

export default defineComponent({
    name: 'Card',
    components: {
        StatusDot
    },
    props: {
        icon: { type: String, default: null },
        title: { type: String, default: null },
        status: { type: Object as () => Status, default: null },
        statusDot: { type: Object as () => StatusDotInterface, default: null },
        showLine: { type: Boolean, default: true },
        showHeader: { type: Boolean, default: true },
    },
})
