import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import InfoPopup from '../info-popup/InfoPopup'
import filesMixin from '@/components/files-new/mixins/filesMixin.ts'
import { FilesUploaderVariantEnum } from '../../enums/FilesUploaderVariantEnum.ts'

export default {
    name: 'SingleFile',
    components: { Avatar, InfoPopup },
    props: {
        file: {
            type: Object,
            required: true
        },
        toSync: {
            type: Boolean,
            required: false,
            default: false
        },
        idx: {
            type: Number,
            required: true
        },
        fromTrash: {
            type: Boolean,
            required: false,
            default: false
        },
        arrayId: {
            type: String,
            required: true
        },
        addToTemporary: {
            type: Boolean,
            required: false
        },
        showShared: {
            type: Boolean,
            required: false
        },
        filesInNewTab: {
            type: Boolean,
            required: false,
            default: false
        },
        hideShareColumn: {
            type: Boolean,
            required: false
        },
        forceShared: {
            type: Boolean,
            required: false
        },
        modelId: {
            type: [String, Number],
            required: false
        },
        modelType: {
            type: String,
            required: true
        },
        lockHotkeys: {
            type: Boolean,
            required: false,
            default: false
        },
        isProjectView: {
            type: Boolean,
            required: false,
            default: false
        },
        editable: {
            type: Boolean,
            required: true
        },
        editableActions: {
            type: Array,
            required: false,
            default: () => []
        },
        showModalVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        showImportantToggle: {
            type: Boolean,
            required: false,
            default: true
        },
        variant: {
            type: String,
            default: FilesUploaderVariantEnum.NORMAL
        },
    },
    mixins: [ filesMixin ],
    data () {
        return {
            showFileDropdown: false,
            syncProgress: false,
            dropdownShowAbove: false,
            storybookPreview: false,
            selected: false,
            InfoPopupShown: false,
        }
    },
    computed: {
        ...mapState('filesComponent', {
            fileHover: state => state.fileHover,
            customColumns: state => state.customColumns
        }),
        isSkinnyVariant () {
            return this.variant === FilesUploaderVariantEnum.SKINNY
        },
        fileIcon () {
            switch (this.file.file_mime_type) {
            case 'application/pdf':
                return 'fa-file-pdf'
            case 'image/jpeg':
            case 'image/bmp':
            case 'image/png':
            case 'image/gif':
                return 'fa-file-image'
            case 'application/zip':
                return 'fa-file-archive'
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return 'fa-file-excel'
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return 'fa-file-word'
            default:
                return 'fa-file-alt'
            }
        },
        isHovered () {
            return this.fileHover === this.file._id
        },
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        isConstructionDoc () {
            return this.folderFlags.model_type === 'construction_documents'
        },
        isConstructionFileVisible () {
            return this.isConstructionDoc ? this.file.is_published : true
        },
        previewUrl () {
            const modelIdTemp = this.modelId || this.$route.params.id
            let appName = (window.location.host).split('.')[0]
            let appType = localStorage.getItem('appType')

            return process.env.API_URL + `${ appName }/${ appType }/copy-shared-document/${ this.modelType }/${ modelIdTemp }/${ this.file._id }?view=true`
            // return `https://iogc.ingenious5dev.com/api/v1/iogc/gc/copy-shared-document/${this.modelType}/${modelIdTemp}/${this.file._id}?view=true&token=${token}`
        },
        ext () {
            let itemFileExtension = ''
            if (this.file.file_name.indexOf('.') !== -1) {
                itemFileExtension = this.file.file_name.split('.').pop()
            }

            return itemFileExtension
        },
        isEditable () {
            let modules = ['payapps']
            let exts = ['doc', 'docx']
            if (modules.includes(this.modelType) || exts.includes(this.ext)) {
                return false
            }

            return true
        },
        classNames () {
            return this.file.file_type ? `io-fa-${ this.file.file_type }` : 'fa-star'
        },
        importantTooltip () {
            return this.file.file_type ? this.$t(`${ this.file.file_type }_file_viewer_import_tooltip`) : ''
        }
    },
    watch: {
        isSelectedForAction (val) {
            this.selected = val
        }
    },
    methods: {
        isAvailableEditableOption (action) {
            if (isEmpty(this.editableActions)) {
                return true
            }
            return this.editableActions.includes(action)
        },
        toggleFileDropdown () {
            const domRect = document.querySelector('.io-menu-btn.io-files-button-' + this.idx).getBoundingClientRect()
            const filesContainer = document.querySelector('.io-files-module').getBoundingClientRect()
            const spaceToTop = domRect.top - filesContainer.top

            this.dropdownShowAbove = spaceToTop > 202
            this.showFileDropdown = !this.showFileDropdown
        },
        closeFileDropdown () {
            this.showFileDropdown = false
        },
        downloadLinkClick () {
            if (this.toSync || this.fromTrash) {
                return false
            }
            document.querySelector('.io-download' + this.file._id).click()
        },
        downloadLink () {
            const endpoint = this.$api.getApi2RequestUrl('/documents/download')
            return `${ endpoint }?files[0]=${ this.file._id }`
        },
        async onShareChange (key) {
            if (key) {
                // can share only "my files"
                if (this.fromTrash || this.toSync || !this.file.is_shareable || this.forceShared) {
                    return false
                }
            }
            let shared
            this.file.can_be_shared ? shared = 0 : shared = 1

            try {
                const { data } = await this.$api.post('/document-can-be-shared/' + this.file._id, { can_be_shared: shared })
                this.file.can_be_shared = data.can_be_shared
                if (data.can_be_shared) {
                    this.showNotification('success', this.$t('Share of this file has been activated.'))
                } else {
                    this.showNotification('success', this.$t('Share of this file has been disabled'))
                }
                this.$forceUpdate()
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
        async syncFile () {
            this.syncProgress = true
            let modelIdTemp = this.modelId || this.$route.params.id
            try {
                const { data } = await this.$api.get(`/copy-shared-document/${ this.modelType }/${ modelIdTemp }/${ this.file._id }`)
                this.showNotification('success', this.$t('Processing file'))
                this.syncProgress = false
                this.$store.dispatch('filesComponent/addMyDoc', {
                    arrayId: this.arrayId,
                    file: data.document
                })
                this.file._shared_to_my_document_id = true
                this.file.can_be_shared = false
                this.$emit('refreshData')
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
                this.syncProgress = false
            }
        },
        async removeTempItem () {
            this.showPopupAlert({
                title: this.$t('Delete File'),
                caption: this.$t('File will be permanently deleted, are you sure?'),
                icon: 'far fa-trash',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Remove'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.hardRemoveTempDoc()
                        }
                    }
                ]
            })
        },
        async hardRemoveDoc () {
            let postData = []
            postData.push(this.file._id)
            try {
                await this.$api.delete('documents/hard-delete', { params: { files: postData } })
                this.$store.dispatch('filesComponent/hardRemoveDoc', {
                    arrayId: this.arrayId,
                    fileId: this.file._id
                })
                this.$emit('refreshData')
                this.$emit('fileRemove', this.file._id)
            } catch (e) {
                this.showNotification('error', e.message)
            }
        },
        async hardRemoveTempDoc () {
            let postData = []
            postData.push(this.file._id)
            try {
                await this.$api.delete('documents/hard-delete', { params: { files: postData } })
                this.$store.dispatch('filesComponent/hardRemoveMyDoc', {
                    arrayId: this.arrayId,
                    fileId: this.file._id
                })
                this.$emit('refreshData')
                this.$emit('tempFileRemove', this.file._id)
            } catch (e) {
                this.showNotification('error', e.message)
            }
        },
        async onRestoreItem () {
            this.showPopupAlert({
                title: this.$t('Restore File'),
                caption: this.$t('This file will be restored, are you sure?'),
                icon: 'far fa-exclamation-triangle',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, restore'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.restoreItem()
                        }
                    }
                ]
            })
        },
        async restoreItem () {
            let postData = []
            postData.push(this.file._id)
            try {
                await this.$api.put('documents/restore', { files: postData })
                this.showNotification('success', this.$t('File has been restored'))
                this.$store.dispatch('filesComponent/addMyDoc', {
                    arrayId: this.arrayId,
                    file: this.file
                })
                this.$store.dispatch('filesComponent/restoreTrashedDoc', {
                    arrayId: this.arrayId,
                    fileId: this.file._id
                })
                this.$emit('refreshData')
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
        async onItemPublish (key) {
            if (key) {
                // can star only "my files"
                if (!this.isHovered || this.fromTrash || this.toSync) {
                    return false
                }
            }
            if (this.file.is_published === true) {
                this.showPopupAlert({
                    title: this.$t('Unpublish File'),
                    caption: this.$t('This file will be unpublished from everyone in project. Are you sure?'),
                    icon: 'far fa-exclamation-triangle',
                    buttons: [
                        {
                            text: this.$t('Cancel'),
                            class: 'io-btn-light',
                            action: null
                        },
                        {
                            text: this.$t('Unpublish'),
                            class: 'io-btn-primary',
                            action: () => {
                                this.changePublishItem()
                            }
                        }
                    ]
                })
            } else {
                this.showPopupAlert({
                    title: this.$t('Publish File'),
                    caption: this.$t('This file will be published to everyone in project. You can find it in Construction Documents folder. Are you sure?'),
                    icon: 'far fa-exclamation-triangle',
                    buttons: [
                        {
                            text: this.$t('Cancel'),
                            class: 'io-btn-light',
                            action: null
                        },
                        {
                            text: this.$t('Publish'),
                            class: 'io-btn-primary',
                            action: () => {
                                this.changePublishItem()
                            }
                        }
                    ]
                })
            }
        },
        async changePublishItem () {
            try {
                await this.$api.post(`document/publish/${ this.file._id }`)

                if (this.file.is_published === true) {
                    this.file.is_published = false
                    this.showNotification('success', this.$t('File has been unpublished'))
                } else {
                    this.file.is_published = true
                    this.showNotification('success', this.$t('File has been published'))
                }
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
        async toggleImportant (key) {
            if (key) {
                // can star only "my files"
                if (!this.isHovered || this.fromTrash || this.toSync) {
                    return false
                }
            }
            let setFlag
            if (this.file.flag === 1) {
                setFlag = 0
            } else {
                setFlag = 1
            }
            try {
                await this.$api.post(`document/${ this.file._id }/file/flag/${ setFlag }`)
                this.file.flag = setFlag
                this.showNotification('success', this.$t('Success'))
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
        hoverStart () {
            this.$store.dispatch('filesComponent/setFileHover', this.file._id)
        },
        hoverReset () {
            this.$store.dispatch('filesComponent/setFileHover', '')
        },
        keyHandle (e) {
            if (this.lockHotkeys) {
                return false
            }
            if (this.fileHover === this.file._id) {
                if (e.keyCode === 83) {
                    this.toggleImportant('key')
                }
                if (e.keyCode === 65) {
                    this.onShareChange('key')
                }
                if (e.keyCode === 68) {
                    this.downloadLinkClick()
                }
                if (e.keyCode === 69) {
                    this.onItemToTrash('key')
                }
            }
        },
        closePreview () {
            this.showPreviewPopup = false
        },
        toggleInfoPopup () {
            this.InfoPopupShown = !this.InfoPopupShown
        },
        async onChangeSelected (event) {
            if (event) {
                this.toggleSelectForListView(event)
            }
        }
    },
    created () {
        window.addEventListener('keyup', this.keyHandle)
    },

    beforeMount () {
        if (this.modelId === 'storybook') {
            this.showFileDropdown = true
            this.storybookPreview = true
        }

         if (this.isSelectedForAction) {
            this.selected = true
        }
    },

    beforeUnmount () {
        window.removeEventListener('keyup', this.keyHandle)
    }
}
