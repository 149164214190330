import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AlertBox',
    props: {
        color: { type: String, default: 'blue' },
        icon: { type: String, default: 'icon-info' },
        dismissible: { type: Boolean, default: false },
        shadow: { type: Boolean, default: false },
    },
    data () {
        return {
            displayAlert: true,
        }
    },
    methods: {
        hideAlert (): void {
            this.displayAlert = false
            this.$emit('hide')
        },
    }
})
