export default {
    isSidebarOpen: false,
    sidebarActiveTab: 0,
    selectedMarkupTypes: ['all'],
    isAddingAnnotation: false,
    addingAnnotationType: null,
    addedTask: null,
    documentVersionId: null,
    removingAnnotation: null,
    addedAnnotationId: null,
    annotationData: {},
    selectedAnnotationId: '',
    mentionUsers: [],
    myCompanyMentionUsers: [],
    otherPartyCompanyMentionUsers: []
}
