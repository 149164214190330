import { AxiosPromise } from 'axios'
import { ApiClient as client } from '@/api/api'
import {
    CreateAnnotationPayload,
    CreateCommentAnnotationPayload,
    DrawingAnnotationInterface,
} from '@/interfaces/modules/projects/drawings-v2/DrawingAnnotationInterface.ts'

export default {
    changeManyAnnotationsVisibility: (payload: {
        ids: string[],
        visibility: string
    }): AxiosPromise<DrawingAnnotationInterface> =>
        client.put('/ca/drawing/annotations/visibility', payload),

    changeSingleAnnotationVisibility: (payload: {
        id: string,
        visibility: string
    }): AxiosPromise<DrawingAnnotationInterface[]> =>
        client.put(`/ca/drawing/annotation/${ payload.id }/visibility`, payload),

    createPunchAnnotation: (payload: CreateAnnotationPayload): AxiosPromise<void> =>
        client.post('/ca/drawing/annotation/create-punch', payload),

    createCommentAnnotation: (payload: CreateCommentAnnotationPayload): AxiosPromise<void> =>
        client.post('/ca/drawing/annotation/create-comment', payload),

    updateCommentAnnotation: (payload: { id: string, comment: string, shared_with: string[] }): AxiosPromise<void> =>
        client.post('/ca/drawing/annotation/update-comment', payload),

    deleteCommentAnnotation: (id: string): AxiosPromise<void> =>
        client.delete(`ca/drawing/annotation/comment/${ id }/delete`),

    replyToComment: (payload: { id: string, comment: string, shared_with: string[] }): AxiosPromise<void> =>
        client.post('ca/drawing/annotation/reply-comment', payload),

    resolve: (annotationId: string, payload: { is_resolved: boolean }): AxiosPromise<void> =>
        client.post(`/ca/drawing/annotation/${ annotationId }/resolve`, payload),

    createAnnotation: (payload: CreateAnnotationPayload): AxiosPromise<void> =>
        client.post('ca/drawing/annotation/create', payload),

    deleteAnnotation: (id: string): AxiosPromise<void> =>
        client.delete(`ca/drawing/annotation/${ id }/delete`),

    updateAnnotation: (id: string, payload: Partial<CreateAnnotationPayload>): AxiosPromise<void> =>
        client.post(`ca/drawing/annotation/${ id }/update`, payload),
}
