import { defineComponent } from 'vue'
import Tooltip from '@/components/atoms/TooltipIcon/TooltipIcon.vue'
import type { HeaderTable } from '@/components/table/HeaderTableInterface'
import dayjs from 'dayjs'

export type SortData<T = string> = { by: T; direction: SortDirection }
export type SortDirection = 'asc' | 'desc'

export default defineComponent({
    name: 'Table',
    components: {
        Tooltip,
    },
    props: {
        headers: { type: Array as () => Array<HeaderTable>, required: true },
        sortBy: { type: String, default: 'id' },
        sortDirection: { type: String as () => SortDirection, default: 'asc' },
        items: { type: Array as () => Array<any>, required: true },
        showSelect: { type: Boolean, default: false },
        showCheckbox: { type: Boolean, default: true },
        checkboxValue: { type: Boolean, default: false },
        checkAllClass: { type: String, default: '' },
        omitTbody: { type: Boolean, default: false },
        label: { type: String, default: '' },
        hasNewSortingIcons: { type: Boolean, default: false },
        isSortingDisabled: { type: Boolean, default: false }
    },
    emits: ['sortChange', 'allItemsSelected'],
    data () {
        return {
            sort: {
                by: this.sortBy,
                direction: this.sortDirection,
            } satisfies SortData,
        }
    },
    computed: {
        sortedItems (): Array<object> {
            if (this.isSortingDisabled) {
                return this.items
            }

            const header = this.headers.find((header: HeaderTable) => this.arrayWrap(header.value)?.includes(this.sort.by))
            if (!header) {
                return this.items
            }
            const sortFunction = header.sort
            const sortDates = header.sortDates

            return [...this.items].sort((a: any, b: any) => {

                let aProp = this.getValueByPath(a, this.sort.by)
                let bProp = this.getValueByPath(b, this.sort.by)

                if (typeof sortFunction === 'function') {
                    aProp = sortFunction(aProp)
                    bProp = sortFunction(bProp)
                }

                if (sortDates) {
                    const date1 = dayjs(aProp, { format: 'D MMM YYYY' })
                    const date2 = dayjs(bProp, { format: 'D MMM YYYY' })

                    return this.sort.direction === 'asc'
                        ? date1.diff(date2)
                        : date2.diff(date1)
                }

                if ('number' === typeof aProp && 'number' === typeof bProp) {
                    return 'asc' === this.sort.direction
                        ? aProp - bProp
                        : bProp - aProp
                }

                if (this.sort.direction === 'asc') {
                    return String(aProp).localeCompare(String(bProp))
                }

                return String(bProp).localeCompare(String(aProp))
            })
        },
    },
    methods: {
        arrayWrap (value: string | string[]): Array<any> {
            return Array.isArray(value) ? value : [value]
        },
        getHeaderClasses (header: HeaderTable): Array<any> {
            const sizeClassName = `align-${ header.align || 'start' }`
            const sortEnabled = header.sort !== false && 'cursor-pointer'
            return [sizeClassName, sortEnabled, header.extraClasses].filter(Boolean)
        },
        getSortIconClasses (header: HeaderTable): Array<any> {
            if (this.hasNewSortingIcons) {
                if (!this.arrayWrap(header.value)?.includes(this.sort.by)) {
                    return ['icon-sort']
                }
                if (this.sort.direction === 'desc') {
                    return ['icon-chevron-down']
                }
                if (this.sort.direction === 'asc') {
                    return ['icon-chevron-up']
                }
            }

            if (!this.arrayWrap(header.value)?.includes(this.sort.by)) {
                return ['fas fa-sort']
            }
            if (this.sort.direction === 'desc') {
                return ['fas fa-sort-down', 'active']
            }
            if (this.sort.direction === 'asc') {
                return ['fas fa-sort-up', 'active']
            }

            return []
        },
        onSort (header: HeaderTable): void {
            if (header.sort === false) {
                return
            }
            if (typeof header.value !== 'string') {
                let index = header.value.findIndex((val: string) => val === this.sort.by)
                if (index === -1) {
                    this.sort.direction = 'asc'
                    index = 0
                } else if (this.sort.direction === 'desc') {
                    index = index + 1 === header.value.length ? 0 : index + 1
                    this.sort.direction = 'asc'
                } else {
                    this.sort.direction = 'desc'
                }
                this.sort.by = header.value[index]
            } else {
                if (this.sort.by === header.value) {
                    this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc'
                } else {
                    this.sort.direction = 'asc'
                }
                this.sort.by = header.value
            }
            this.$emit('sortChange', this.sort)
        },
        selectAll (e: any): void {
            // Event emitted when select header checkbox is selected or deselected
            this.$emit('allItemsSelected', e.target.checked)
        },
        getValueByPath (obj: Object, path: string): any { //function to access value for sorting if you have more than 1 nested field you need to sort by
            return path.split('.').reduce((currentObj, key) => (currentObj || {})[key], obj)
        },
    },
})
