import Vue, { PropType, defineComponent } from 'vue'
import CustomMultiselect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import { NewCompany, EmptyCompanyAddress } from '@/interfaces/components/invite-modal/NewCompany'
import client from '@/api/invite-modal/inviteModalClient'
import { mapActions, mapMutations, mapState } from 'vuex'
import type appTypes from '@/base/appTypes'

export default defineComponent({
    name: 'AddContactDetails',
    components: {
        CustomMultiselect
    },
    props: {
        componentKey: { type: String, required: true },
        allowedClientsTypes: { type: Array as PropType<appTypes[]>, default: null },
    },
    data () {
        return {
            model: {
                email: '',
                company: {},
                firstName: '',
                lastName: '',
                isNewCompany: false,
                newCompany: this.emptyCompany()
            },
            companySearch: ''
        }
    },
    computed: {
        ...mapState('common', {
            countries: (state: any) => state.countries,
            states: (state: any) => state.states
        }),
        selectedWorkspace () {
            return this.$store.getters['inviteModal/getSelectedWorkspace'](this.componentKey)
        },
        contactFormData () {
            return this.$store.getters['inviteModal/getContactFormData'](this.componentKey)
        },
        isWorkspacePreselected (): boolean {
            return !!(this.selectedWorkspace && Object.keys(this.selectedWorkspace).length)
        },
        searchEmail () {
            return this.$store.getters['inviteModal/getSearchKeyword'](this.componentKey)
        },
        companiesList () {
            return this.$store.getters['inviteModal/getCompaniesList']
        },
        multiselectSettings () {
            return {
                textPlaceholder: 'Type company name',
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: true,
                searchPropertyNames: ['name']
            }
        },
        selectCompanyDisabled (): boolean {
            return !!this.isWorkspacePreselected
        },
        emailPreselected (): boolean {
            return this.validateEmail(this.searchEmail)
        },
        isFormValid (): boolean {
            return this.validateEmail(this.model.email) &&
                this.model.firstName.length >= 2 &&
                this.model.lastName.length >= 2 &&
                this.otherFieldsAreValid()
        },
        companyDoesNotExist (): boolean {
            return this.companySearch.length > 2 && !this.companiesList.length
        },
        isStateSelectDisabled (): boolean {
            return this.model.newCompany.address?.country?.id !== 'US'
        },
        isCompanySearchAvailable (): boolean {
            return this.companySearch.length > 2
        },
        modelCompany (): null | object {
            return this.isCompanyModelNotEmpty
                ? this.model.company
                : null
        },
        isCompanyModelNotEmpty (): boolean {
            // @ts-ignore
            return this.model.company?.id || this.model.company?.name
        },
        isZipCodeValid (): boolean {
            const regex = /^[^-!$%^&*@()_+|~=`\\#{}\[\]:";'<>?,.\/a-z\s][A-Z0-9\.\-\s]{3,9}$/;
            return !this.model.newCompany.address.zip.length || regex.test(this.model.newCompany.address.zip)
        }
    },
    mounted () {
        this.setInitialData()
    },
    watch: {
        'isFormValid' (value) {
            this.setIsValid({ key: this.componentKey, value })
        },
        model: {
            deep: true,
            handler () {
                this.setContactFormData({ key: this.componentKey, data: this.model })

                if (!this.isWorkspaceSelected) {
                    this.shouldSetWorkspaceType()
                        ? this.setSelectedWorkspaceType({
                            key: this.componentKey,
                            value: this.model.company.shared_workspace_type
                        })
                        : this.resetSelectedWorkspaceType({ key: this.componentKey })
                }
            }
        },
        'model.newCompany.company_name' (newVal) {
            if (newVal === '' && this.model.isNewCompany === true) {
                this.clearNewCompanyData()
            }
        }
    },
    methods: {
        ...mapActions('inviteModal', ['searchCompanies']),
        ...mapMutations('inviteModal', {
            setSelectedWorkspaceType: 'SET_SELECTED_WORKSPACE_TYPE',
            resetSelectedWorkspaceType: 'RESET_SELECTED_WORKSPACE_TYPE',
            setIsValid: 'SET_IS_VALID',
            setContactFormData: 'SET_CONTACT_FORM_DATA'
        }),
        isWorkspaceSelected (): boolean {
            const selectedWorkspace = this.$store.getters['inviteModal/getSelectedWorkspace'](this.componentKey)
            return selectedWorkspace && typeof selectedWorkspace === 'object' && Object.keys(selectedWorkspace).length > 0
        },
        shouldSetWorkspaceType (): boolean {
            return false === this.model.isNewCompany
                && false === this.model.company?.off_system
                && this.model.company?.shared_workspace_type.length
        },
        setInitialData () {
            if (this.contactFormData) {
                this.model = this.contactFormData

                if (this.isWorkspacePreselected) {
                    this.model.company = {
                        id: this.selectedWorkspace.id,
                        name: this.selectedWorkspace.company_name,
                        logo: this.selectedWorkspace.logo
                    }

                    return
                }

                this.model.company = {
                    id: null,
                    name: null
                }

                return
            }

            if (this.isWorkspacePreselected) {
                this.model.company = {
                    id: this.selectedWorkspace.id,
                    name: this.selectedWorkspace.company_name,
                    logo: this.selectedWorkspace.logo
                }
            }

            if (this.emailPreselected) {
                this.model.email = this.searchEmail
            }
        },
        onLastNameInput(event) {
            if (event.key === 'Tab' && this.$refs.customMultiSelect) {
                event.preventDefault()
                this.$refs.customMultiSelect.toggleMenu()
            }
        },
        validateEmail (email: string): boolean {
            const regex = /\S+@\S+\.\S+/
            return regex.test(email)
        },
        onSelectCompany (company: any): void {
            if (this.model.company?.id === company?.id) {
                this.model.company = {}

                return
            }
            this.model.company = company
        },
        updateAddress (): void {
            this.$emit('update:modelValue', this.model.newCompany.address)
        },
        updateCountry (): void {
            this.model.newCompany.address.state = {}

            this.updateAddress()
        },
        async onCreateCompany (): Promise<void> {
            const postFillAddressCallback = (addressData): void => {
                this.model.newCompany.address.state = (addressData.state_id && this.model.newCompany.address) ? addressData.state_id : {}

                this.$nextTick(() => {
                    this.model.newCompany.address.address_line1 = addressData.address1
                    this.updateAddress()
                })
            }

            const countryIndex: number = 6

            this.initGoogleAddressSearch (
                this.model.newCompany.address,
                this.states,
                '',
                true,
                this.countries,
                countryIndex,
                'address1',
                postFillAddressCallback
            )

            this.model.isNewCompany = true
            this.model.newCompany.company_name = this.companySearch
        },
        onSearchInput (search: string): void {
            this.companySearch = search

            this.isCompanySearchAvailable && this.searchCompanies({
                search: search,
                modal: this.componentKey,
                allowed_app_types: this.allowedClientsTypes,
            })
        },
        clearNewCompanyData (): void {
            this.model.isNewCompany = false
            this.companySearch = ''
            this.model.newCompany = this.emptyCompany()
            this.model.newCompany.address = this.emptyAddress()
        },
        otherFieldsAreValid (): boolean {
            if (false === this.model.isNewCompany) {
                return this.isCompanyModelNotEmpty
            }

            return this.model.newCompany.company_name.length > 2
                && this.model.newCompany.address instanceof Object
                && this.model.newCompany.address.address_line1.length > 2
                && this.model.newCompany.address.country.id !== undefined
                && this.model.newCompany.address.country.name !== undefined
                && this.model.newCompany.address.city.length > 2
                && this.isZipCodeValid
        },
        emptyCompany (): NewCompany {
            return {
                company_name: '',
                address: this.emptyAddress()
            }
        },
        emptyAddress (): EmptyCompanyAddress {
            return {
                is_main: true,
                address1: '',
                address2: '',
                address_line1: '',
                address_line2: '',
                city: '',
                country: {},
                state: {},
                state_id: {},
                zip: ''
            }
        },
        getCompanyAddress (address): string {
            const fields = [
                'address_line1',
                'address_line2',
                'city',
                'state',
                'zip',
                'country'
            ]

            let addressData = []
            for (const field of fields) {
                !!address[field] && addressData.push(address[field])
            }

            return addressData.join(', ').trim()
        }
    }
})
