const FormDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue')
const MainLayout = () => import('@/modules/account/layout/main')
export default [
    {
        path: '/user',
        name: 'account',
        redirect: {name: 'account-edit'},
        component: MainLayout,
        children: [
            {
                path: '/user/account',
                name: 'account-edit',
                component: FormDefault,
                meta: {
                    schema: 'account',
                    showLoader: false,
                    title: 'Edit My Account'
                }
            }
        ]
    }
]
