import { defineComponent } from 'vue'
import CreateNewStampPopup from './create-new-stamp-popup/CreateNewStampPopup.vue'

export interface Stamp {
    code: string,
    color: string,
    id: number,
    name: string
}

export default defineComponent({
    components: {
        CreateNewStampPopup,
    },
    props: {
        selected: { type: Object as () => Stamp, default: null },
        statusType: { type: Boolean, default: false },
        isEditable: { type: Boolean, default: true },
        projectId: { type: String, default: null },
    },
    data () {
        return {
            dropdownExpanded: false,
            stamps: [] as any[],
            hoveringOptionIndex: null as number | null,
            createNewStampPopupShown: false,
            editingExistingStamp: false,
            editingStampInfo: null,
            searchValue: '' as string,
        }
    },
    computed: {
        selectedOptionText (): string {
            return this.selected.name
        },
        filteredStampsList (): Stamp[] {
            return this.stamps.filter((stamp: any) => {
                return stamp.name.toLowerCase().includes(this.searchValue) ||
                    stamp.code.toLowerCase().includes(this.searchValue)
            })
        },
    },
    async beforeMount () {
        await this.getStampList()
    },
    methods: {
        toggleDropdown (): void {
            if (!this.isEditable) {
                return
            }

            this.dropdownExpanded = !this.dropdownExpanded
        },
        setHoveringOptionIndex (index: number | null): void {
            this.hoveringOptionIndex = index
        },
        createNewStamp (): void {
            this.createNewStampPopupShown = true
        },
        editStamp (val: any): void {
            this.editingStampInfo = val
            this.editingExistingStamp = true
            this.createNewStampPopupShown = true
        },
        async closeNewStampPopup (): Promise<void> {
            this.editingExistingStamp = false
            this.createNewStampPopupShown = false
            this.editingStampInfo = null
            this.dropdownExpanded = false

            await this.getStampList()
        },
        closeDropdown (): void {
            this.dropdownExpanded = false
        },
        select (option: Stamp): void {
            this.$emit('select', option)
            this.closeDropdown()
        },
        async getStampList (): Promise<void> {
            this.setLoadingBar(true)

            try {
                const { data } = await this.$api.get(`stamps/get/${ this.$route.params.pid ?? this.projectId }`)
                this.stamps = data.stamps
            } catch (e) {
                this.showNotification('error', e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        deleteStamp () {
            this.$emit('delete-stamp')
        },
    },
})
