import { defineComponent,PropType } from 'vue'
import { appPackageViewStore } from '../../../stores/appPackageView'
import { mapState, mapActions } from 'pinia'
import { mapGetters as mapGettersVuex } from 'vuex'
import ClientApprovalCard from '../../../../approval-workflows/components/client-approval-card/ClientApprovalCard.vue'
import ActionData from '@/io-modules/invoices/interfaces/ActionData'
import ApprovalModalType from '@/io-modules/approval-workflows/enums/ApprovalModalType'
import ApprovalAction from '@/io-modules/approval-workflows/enums/ApprovalAction'
import ApprovalModalData from '@/io-modules/approval-workflows/interfaces/ApprovalModalData'
import AppPackageAvailableActions from '../../../enums/AppPackageAvailableActions'
import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus'
import ApplicationPackageStatus from '../../../enums/ApplicationPackageStatus'
import ApprovalsClient from '@/io-modules/approval-workflows/api-clients/ApprovalsClient'

export default defineComponent({
    name: 'AppPackageApprovals',
    components: {
        ClientApprovalCard,
    },
    props: {
        resourceApprovals: {
            type: Object as PropType<ResourceApprovalsResponse>,
            default: null,
        },
        approvalModalData: {
            type: Object as PropType<ApprovalModalData>,
            default: () => ({}),
        },
    },
    emits: ['submitToClient', 'markAsSubmittedToClient', 'update:approvalModalData', 'initReject'],
    data () {
        return {
            ApprovalStatus,
            clientApprovableResourceData: null,
        }
    },
    computed: {
        ...mapState(appPackageViewStore, ['appPackage', 'availableActions']),

        ...mapGettersVuex('appStore', ['isOwnerRep', 'isOwner']),

        repApprovalActions (): ActionData[] {
            const actions = [
                {
                    icon: 'icon-send-2',
                    text: this.$t('Submit to Client'),
                    visible: this.isActionAvailable(AppPackageAvailableActions.SubmitToClient),
                    onClick: () => this.$emit('submitToClient'),
                },
                {
                    text: this.$t('Record Client Approval'),
                    variant: !this.isActionAvailable(AppPackageAvailableActions.MarkAsSubmittedToClient) ? 'primary' : 'secondary',
                    visible: this.isActionAvailable(AppPackageAvailableActions.RecordClientApproval),
                    onClick: () => this.initClientApproval()
                },
                {
                    text: this.$t('Mark as Submitted to Client'),
                    visible: this.isActionAvailable(AppPackageAvailableActions.MarkAsSubmittedToClient),
                    onClick: () => this.$emit('markAsSubmittedToClient')
                },
            ]

            return actions.filter(item => item.visible)
        },

        ownerApprovalActions (): ActionData[] {
            const actions = [
                {
                    text: this.$t('Reject'),
                    variant: 'secondary',
                    visible: this.isActionAvailable(AppPackageAvailableActions.Reject),
                    onClick: () => this.$emit('initReject')
                },
                {
                    text: this.$t('Approve'),
                    visible: this.isActionAvailable(AppPackageAvailableActions.Approve),
                    onClick: () => this.initApprove()
                },
            ]

            return actions.filter(item => item.visible)
        },

        ownerApprovalDate (): string {
            return this.resourceApprovals?.workflows?.length ? this.resourceApprovals.workflows[0].approvers[0].approval.updated_at : ''
        },

        clientApprovalDate (): string {
            return this.clientApprovableResourceData?.workflows?.length ? this.clientApprovableResourceData.workflows[0].approvers[0].approval.updated_at : ''
        },

        isClientOffSystem (): boolean {
            return !!this.appPackage.client_approval?.client?.off_system
        },

        needClientApprovalData (): boolean {
            return !this.isClientOffSystem && this.appPackage.client_approvable_resource_id && [ApplicationPackageStatus.RejectedByClient, ApplicationPackageStatus.ApprovedByClient].includes(this.appPackage.status)
        },
    },
    watch: {
        async appPackage (): void {
            if (this.appPackage.id && this.needClientApprovalData && !this.clientApprovableResourceData) {
                const response = await ApprovalsClient.getApprovableResource(
                    this.appPackage.client_approvable_resource_id,
                )

                this.clientApprovableResourceData = response.data
            }
        }
    },
    async mounted () {
        if (this.needClientApprovalData && !this.clientApprovableResourceData) {
            const response = await ApprovalsClient.getApprovableResource(
                this.appPackage.client_approvable_resource_id,
            )

            this.clientApprovableResourceData = response.data
        }
    },
    methods: {
        initClientApproval (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.APPROVE, modal: ApprovalModalType.ClientApproval })
        },

        initApprove (): void {
            this.$emit('update:approvalModalData', { action: ApprovalAction.APPROVE, modal: ApprovalModalType.UserApproval })
        },

        isActionAvailable (actionName: string): boolean {
            return Boolean(this.availableActions?.find(action => action.name === actionName)?.available)
        },
    },
})
