<template>
    <PopupModal customClass="io-popup-share-markup" :width="535" @close="onDiscard">
        <template #header>
            <span class="io-header-icon"></span>
            <div class="io-popup-modal__close" @click="onDiscard"><i class="icon-cross" /></div>
        </template>

        <template #default>
            <label class="io-visibility__label">
                {{ $t('drawing.annotations.visibility.title') }}
                <TooltipIcon :text="$t('drawing.annotations.visibility.tooltip')" />
            </label>
            <div class="io-visibility__radios">
                <Radio v-for="(enumItem, index) in visibilityEnums" :key="index" :labelText="$t(enumItem.name)" :radioValue="enumItem.id" v-model="markupVisibility"/>
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="onDiscard">{{ $t('Cancel') }}</IOButton>
            <IOButton variant="primary" :disabled="!visibilityChanged" @click="onChange">{{ $t('Share') }}</IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./ShareMarkupPopup.ts"></script>
<style lang="scss" src="./ShareMarkupPopup.scss" scoped></style>
