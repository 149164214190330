<template>
    <div>
        <PopupModal v-if="loaded" :width='960'>
            <template #header>
                <div class='io-popup-modal__icon'><i class='icon-elements'></i></div>
                <div class='io-popup-modal__title'>
                    {{ modalTitleText }}
                    <span v-if="stepCount" class="io-pill">{{ currentStep }} {{ $t('of') }} {{ stepCount }}</span>
                </div>
                <div class='io-popup-modal__close' @click='onClickCloseModal'>
                    <i class='icon-cross'></i>
                </div>
            </template>

            <template #default>
                <div id="io-project-wizard-modal">
                    <StepSelectContractType
                        v-if="step === 1"
                        :showManualOption="showManualOption"
                        @upload-contract="goToUploadContract"
                        @skip-contract="stepSkipContract"
                    />

                    <StepSelectContract
                        v-if="step === 11"
                        :contracts="contracts"
                        v-model="selectionContract"
                    />

                    <StepUploadContract
                        v-if="step === 12"
                        v-model="contract"
                        @onUploadComplete="onUploadComplete"
                    />

                    <StepProjectDetails
                        v-if="step === 2"
                        :isProposal="isProposal"
                        :stateDisabled="stateDisabled"
                        :isIdExist="isIdExist"
                        :client="client"
                        :externalProject="externalProject"
                        :canEditAgreementType="canEditAgreementType"
                        :isGmpEnabled="isGmpEnabled"
                        :isOwner="isOwner"
                        :isDevAppType="isDevAppType"
                        :proposedId="proposedId"
                        :is-p-m-approver="isPMApprover"
                        v-model="project"
                        @proposed-id="$event => proposedId = $event"
                        @selectCurrency="selectCurrency"
                        @clearExternalProject="clearExternalProject"
                        @openCloseExternalJobModal="openCloseExternalJobModal"
                        @openAgreementTypePopup="openAgreementTypePopup"
                        @setMainContractTypePopup="setMainContractTypePopup"
                        @setAgreementType="setAgreementType"
                        @setMainContractType="setMainContractType"
                        @onChangeUpdateManager="onChangeUpdateManager"
                        @resetManager="resetManager"
                        @onUpdateProjectExecutive="onUpdateProjectExecutive"
                        @resetProjectExecutive="resetProjectExecutive"
                        @onSelectedClientChange="onSelectedClientChange"
                        @removeSelectedClient="removeSelectedClient"
                        @setPMAsApprover="setPMAsApprover"
                    />

                    <StepFeesAndPhases
                        v-if="step === 3"
                        :isOwner="isOwner"
                        :isProposal="isProposal"
                        :canAddPhases="canAddPhases"
                        :contractValueValid="contractValueValid"
                        :hasAlternatePhases="hasAlternatePhases"
                        :isProjectValueInvalid="isProjectValueInvalid"
                        v-model="project"
                        @removePhase="removePhase"
                        @setProjectType="setProjectType"
                        @recalculateContractValue="recalculateContractValue"
                    />

                    <AgreementType
                        v-if="showAgreementTypePopup"
                        class="io-project-agreement-type"
                        v-model="project.agreement_type"
                        @setAgreementType="setAgreementType"
                        @close="closeAgreementTypePopup"
                    />

                    <PopupModal v-if="showMainContractTypePopup" class="io-select-main-contract" :width='600'>
                        <template #header>
                            <div class='io-popup-modal__title'>
                                {{ $t('Select Contract Type') }}
                            </div>
                            <div class='io-popup-modal__close' @click='showMainContractTypePopup = false'>
                                <i class='icon-cross'></i>
                            </div>
                        </template>
                    </PopupModal>

                    <PopupCompany
                        v-if="showPopupCompany"
                        :companyFromProp="companyForPopup"
                        @close="togglePopupCompany"
                        @updateLocalEmail="updateLocalEmail"
                    />

                    <ViewModal v-if="showModal === 'contact'">
                        <template #body>
                            <ViewEditForm
                                forcedSource="contact/modal"
                                :forcedId="0"
                                :forcedData="getForcedDataContact"
                                :forceModalClose="true"
                                :forceTriggerRefresh="true"
                            />
                        </template>
                    </ViewModal>
                </div>
            </template>

            <template #footer>
                <div  v-if="step == 11" class="io-modal-footer io-row">
                    <div class="io-buttons">
                        <IOButton
                            icon="icon-chevron-left"
                            variant="secondary"
                            @click.stop="setStep(1)"
                        >
                            {{ $t('Back') }}
                        </IOButton>

                        <IOButton
                            :disabled="!selectionContract?.id"
                            @click.stop="setStep(2)"
                        >
                            {{ $t('Next Step') }}
                        </IOButton>
                    </div>
                </div>

                <div  v-if="step == 12" class="io-modal-footer io-row">
                    <div v-if="contract.files.length > 0" class="io-disclaimer">
                        <label class="io-checkbox-container">
                            <input type="checkbox" required v-model="contract.executeConfirm"/>
                            <span class="io-checkbox-text" :class="{'io-checkbox-text-error': contract.executeError}">{{ $t('I hereby confirm that I have reviewed this document and the contract is fully executed.') }}</span>
                            <span class="io-checkmark"></span>
                        </label>
                    </div>

                    <div class="io-buttons">
                        <IOButton
                            icon="icon-chevron-left"
                            variant="secondary"
                            @click.stop="setStep(1)"
                        >
                            {{ $t('Back') }}
                        </IOButton>

                        <IOButton
                            :icon="uploading ? 'icon-loader-2 fa-spin' : ''"
                            :disabled="!contract?.executeConfirm"
                            @click.stop="validateManual(2)"
                        >
                            {{ $t('Next Step') }}
                        </IOButton>
                    </div>
                </div>

                <div  v-if="step == 2" class="io-modal-footer">
                    <div v-if="hasProposal" class="io-checkbox-container io-checkbox-container-proposal io-disclaimer">
                        <span class="io-checkbox-text">
                            {{ $t('Starting a project will make the Proposal view-only.') }}
                            <br />{{ $t('project_wizard_proposal_confirmation_2') }}
                        </span>
                    </div>

                    <div class="io-buttons">
                        <IOButton
                            v-if="!skipContract && showManualOption"
                            icon="icon-chevron-left"
                            variant="secondary"
                            @click.stop="setStep(1)"
                        >
                            {{ $t('Back') }}
                        </IOButton>

                        <!-- start new project -->
                        <IOButton
                            v-if="contract.type === 'skip' || isProposal"
                            :icon="sending ? 'icon-loader-2 fa-spin' : ''"
                            :disabled="sending || !contractValueValid"
                            @click.stop="onStartNewProject"
                        >
                            {{ $t('Start Project') }}
                        </IOButton>

                        <!-- go last step -->
                        <IOButton
                            v-else
                            @click.stop="goToLast(false)"
                        >
                            {{ $t('Next Step') }}
                        </IOButton>
                    </div>
                </div>

                <div  v-if="step == 3" class="io-modal-footer io-row">
                    <div class="io-buttons">
                        <IOButton
                            icon="icon-chevron-left"
                            variant="secondary"
                            @click.stop="setStep(2)"
                        >
                            {{ $t('Back') }}
                        </IOButton>

                        <IOButton
                            icon="icon-plus"
                            :disabled="!canAddPhases"
                            @click.stop="addPhase"
                        >
                            {{ $t('Add Phase') }}
                        </IOButton>

                        <IOButton
                            :icon="sending ? 'icon-loader-2 fa-spin' : ''"
                            :disabled="sending || !contractValueValid"
                            @click.stop="saveFromContract"
                        >
                            {{ $t('Start Project') }}
                        </IOButton>
                    </div>
                </div>
            </template>
        </PopupModal>
    </div>
</template>

<script lang="ts" src="./main.ts"></script>
<style lang="scss" src="./project-wizard.scss" scoped></style>
