<template>
    <ModalJournal v-if="isShow"/>
</template>

<script>
    import modalMixin from '@/mixins/modalMixin'
    import ModalJournal from '@/layouts/offcanvas/project-wizard/parts/ModalJournal/ModalJournal.vue'
    export default {
        components: {
            ModalJournal
        },
        mixins: [modalMixin],
        data () {
            return {
            }
        },
        computed: {
            isShow () {
                return this.showModal === 'modal-journal'
            }
        }
    }
</script>
