<template>
    <IOModal
        :title="$t('Rename file')"
        width="480"
        icon="icon-edit"
        @close="$emit('close')"
    >

        <IOInput v-model="name" />

        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">{{ $t('Cancel') }}</IOButton>
            <IOButton :disabled="!name.length" @click="rename">{{ $t('Rename') }}</IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./RenameFileModal.ts"></script>
