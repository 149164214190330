<template>
    <filterSection :title="title">
        <template #default>
            <template v-if="showAllSelectedCheckBox">
                <Checkbox
                    key='all'
                    :labelText="$t('All Statuses')"
                    v-model="allSelected"
                    :checkboxValue="true"
                    inputName="all"
                    @click="handleSelectAll"
                />
            </template>
            <template v-for="(item, key) in preparedOptions" :key="key">
                <Checkbox
                    :labelText="prepareName(item)"
                    v-model="checkboxesValue"
                    :checkboxValue="prepareValue(key)"
                    :inputName="key"
                    :classSpan="`io-color-dot ${colorDotFunction(item, key)}`"
                />
            </template>
        </template>
    </filterSection>
</template>

<script>
    import filterSection from './filterSection.vue'
    import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue'
    export default {
        name: 'filterStatuses',
        emit: [ 'update:modelValue' ],
        components: {
            filterSection,
            Checkbox
        },
        data () {
            return {}
        },
        props: {
            title: {
                type: String,
                required: true
            },
            modelValue: {
                required: true,
                type: Array
            },
            options: {
                required: true,
                type: Object
            },
            translate: {
                required: false,
                default: false
            },
            colorDotFunction: {
                required: true,
                type: Function
            },
            showAllSelectedCheckBox: {
                type: Boolean,
                required: false,
                default: true
            },
        },
        computed: {
            checkboxesValue: {
                get () {
                    return this.modelValue
                },
                set (val) {
                    this.$emit('update:modelValue', val)
                }
            },
            preparedOptions () {
                if (Array.isArray(this.options)) {
                    return this.options.reduce((acc, { id, name }) => ({...acc, [id]: name}), {})
                }
                return this.options
            },
            allCheckboxesValues () {
                if (Array.isArray(this.options)) {
                    return this.options.reduce((acc, { id }) => [...acc, id], [])
                }
                return this.options
            },
            allSelected () {
                return [this.modelValue?.length === this.options.length]
            }
        },
        methods: {
            prepareName (name) {
                if (this.translate) {
                    return this.$t(name)
                }
                return name
            },
            handleSelectAll () {
                if (this.allSelected.includes(true)) {
                    this.checkboxesValue = []
                } else {
                    this.checkboxesValue = this.allCheckboxesValues
                }
            },
            prepareValue (value) {
                let localValue = parseInt(value)

                if (isNaN(localValue)) {
                    return value
                }

                return localValue
            }
        }
    }
</script>

<style lang="scss">
    .io-atoms-checkbox {
        .io-color-dot {
            font-style: normal;
            font-size: 12px;
            line-height: 16px;
            align-items: center;
            border-radius: 10px;
            padding: 0 8px 0 15px;
            justify-content: center;
            height: auto;
            display: inline-block;
            position: relative;
            font-weight: 400;

            &::before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                position: absolute;
                left: 0;
                display: block;
                top: 4px;
            }

            &.io-yellow {
                &:before {
                    background-color: var(--warning);
                }
            }

            &.io-red {
                &:before {
                    background-color: var(--error-darker);
                }
            }

            &.io-grey {
                &:before {
                    background-color: var(--closed-darker);
                }
            }

            &.io-blue {
                &:before {
                    background-color: var(--info);
                }
            }

            &.io-green {
                &:before {
                    background-color: var(--success-darker);
                }
            }

            &.io-orange {
                &:before {
                    background-color: var(--warning);
                }
            }
        }
    }
</style>
