export enum FilterType {
    Radio = 'radio',
    Checkbox = 'checkbox',
    Multiselect = 'multiselect',
    Date = 'date',
    Status = 'status',
    TreeMulti = 'tree-multi',
    ColorDot = 'color-dot',
    ToggleSwitch = 'toggle-switch',
    Tags = 'tags',
    RadioPill = 'radio-pill',
    CustomMultiselectBic = 'custom-multiselect-bic',
}

export default FilterType