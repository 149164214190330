<template>
    <IOModal
        :loading="loading"
        :title="$t('Timeline')"
        scrollable
        width="700"
        icon="icon-history"
        @close="$emit('close')"
    >
        <Transition name="fade-from-bottom">
            <Timeline
                v-if="timelineLogs"
                :logs="timelineLogs"
            />
        </Transition>

        <template #footer>
            <slot>
                <IOButton variant="secondary" @click="$emit('close')">{{ $t('Close') }}</IOButton>
            </slot>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./TimelineModal.ts"></script>
<style lang="scss" src="./TimelineModal.scss" scoped></style>
