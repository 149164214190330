import { defineComponent } from 'vue'
import { mapState as mapStateVuex, mapGetters as mapGettersVuex } from 'vuex'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { mapActions, mapState } from 'pinia'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import dayjs from 'dayjs'
import SingleColumnTimeline from '@/components/single-column-timeline/SingleColumnTimeline.vue'
import SingleColumnTimelineItem from '@/interfaces/components/single-column-timeline/SingleColumnTimelineItem'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import RequestedDataErrorPlaceholder from '../../../requested-data-error-placeholder/RequestedDataErrorPlaceholder.vue'
import { InvoiceSubType } from '@/io-modules/invoices/enums/InvoiceSubType'
import InvoiceStatus from '@/io-modules/invoices/enums/InvoiceStatus'
import statusColorsMixin from '@/io-modules/invoices/mixins/StatusColorsMixin'
import appPackageClient from '../../../../api-clients/appPackageClient'
import AppPackageRouteName from '@/io-modules/invoices/enums/AppPackageRouteName'
import { ClientApprovalSetting } from '../../../../../approval-workflows/enums/ClientApprovalSetting'
import InvoiceAmountWithTaxModal from '../../../modals/invoice-amount-with-tax-modal/InvoiceAmountWithTaxModal.vue'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'
import featureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV.ts'
import reviseResubmitMixin from '@/io-modules/invoices/mixins/sov-wbs/ReviseResubmitMixin.ts'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'

export default defineComponent({
    name: 'InvoiceDetails',
    components: {
        IOSingleInfoRow,
        SingleColumnTimeline,
        StatusPill,
        RequestedDataErrorPlaceholder,
        InvoiceAmountWithTaxModal,
    },
    mixins: [statusColorsMixin, featureFlagsMixin, reviseResubmitMixin],
    data () {
        return {
            loading: false,
            error: false,
            appPackageData: null,
            showInvAmountWithTaxModal: false,
        }
    },
    computed: {
        ...mapStateVuex('project', ['projectObj']),

        ...mapGettersVuex('appStore', ['isOwner', 'isOwnerRep']),

        ...mapState(invoiceViewStore, [
            'invoice',
            'isNonContractedInvoice',
            'isVendorInvoice',
            'isMyInvoice',
            'isSender',
            'clientApprovalSetting',
            'applyingFunding',
            'hasAccountingIntegration',
            'accountingErrors',
            'invoiceIsDraft',
            'isClientOnSystem',
            'invIsNotApproved',
            'invAmountWithTax'
        ]),

        ...mapState(invoiceRevisionStore, ['shouldLoadRevision']),

        ...mapStateVuex('appStore', {
            authData: (state: any) => state.authData
        }),

        invoiceDetailsTitle (): string {
            if (InvoiceSubType.MyInvoice === this.invoice.sub_type) {
                return this.$tc('Your Invoice Details')
            }

            if (InvoiceSubType.VendorInvoice === this.invoice.sub_type) {
                return this.$tc('Vendor Invoice Details')
            }

            if (InvoiceSubType.NonContractedInvoice === this.invoice.sub_type) {
                return this.$tc('Non-Contracted Invoice Details')
            }

            return this.$tc('Invoice Details')
        },

        projectName (): string {
            return this.invoice.project_name || this.projectObj._project_name || this.projectObj._name || '-'
        },

        isReceivedInvoice (): boolean {
            return !this.invoice.is_provided_manually
        },

        netInvoiceAmount (): string {
            return this.$filters.formatProjectCurrencyInt(this.invoice.net_invoice_amount)
        },

        currentRetentionAmount (): string {
            return this.$filters.formatProjectCurrencyInt(this.invoice.current_retention_amount)
        },

        grossInvoiceAmount (): string {
            return this.$filters.formatProjectCurrencyInt(this.invoice.gross_invoice_amount)
        },

        invoiceDate (): string {
            return this.invoice.invoice_date ? dayjs(this.invoice.invoice_date).format('DD MMM YYYY') : '-'
        },

        invoiceStartEndDate (): string {
            return this.invoice.start_date ? `${ dayjs(this.invoice.start_date).format('DD MMM YYYY') } - ${ dayjs(this.invoice.end_date).format('DD MMM YYYY') }` : '-'
        },

        invoiceDueDate (): string {
            return this.invoice.end_date ? dayjs(this.invoice.end_date).format('DD MMM YYYY') : '-'
        },

        invAmountWithTaxValue (): string {
            return this.invAmountWithTax ? this.$filters.formatCurrencyInt(this.invAmountWithTax) : '-'
        },

        internalApprovalStatus (): boolean {
            return [
                InvoiceStatus.PendingInternalApproval,
                InvoiceStatus.InternallyApproved,
                InvoiceStatus.InternallyRejected
            ].includes(this.invoice.status)
        },

        clientApprovalStatus (): boolean {
            return [
                InvoiceStatus.RejectedByClient,
                InvoiceStatus.ApprovedByClient,
                InvoiceStatus.RequestedRevision,
                InvoiceStatus.Voided,
                InvoiceStatus.SubmittedToClient,
            ].includes(this.invoice.status)
        },

        accountingStatus (): boolean {
            return [
                InvoiceStatus.SubmitToAccounting,
                InvoiceStatus.RejectedByAccounting,
                InvoiceStatus.ApprovedByAccounting,
            ].includes(this.invoice.status)
        },

        paidStatus (): boolean {
            return [
                InvoiceStatus.Paid,
                InvoiceStatus.PartiallyPaid,
            ].includes(this.invoice.status)
        },

        ownerRepInvHasClientApproval (): boolean {
            return this.isOwnerRep && ((this.isMyInvoice && this.isClientOnSystem) || (!this.isMyInvoice && ClientApprovalSetting.DISABLED !== this.clientApprovalSetting))
        },

        timelineItems (): SingleColumnTimelineItem[] {
            const timeline = [
                {
                    title: this.$t('Draft'),
                    description: '',
                    isActive: true,
                    isCurrent: true,
                    visible: this.isMyInvoice || this.isSender,
                },
                {
                    title: this.$t('Owners Rep Approval'),
                    description: '',
                    isActive: true,
                    isCurrent: false,
                    visible: this.invoice.is_shared && 'observer' === this.invoice.role && this.isOwner,
                },
                {
                    title: this.$t('Internal Approval'),
                    description: '',
                    isActive: this.internalApprovalStatus || this.clientApprovalStatus || this.accountingStatus || this.paidStatus,
                    isCurrent: false,
                    visible: true,
                },
                {
                    title: this.$t('Client Approval'),
                    description: '',
                    isActive: this.clientApprovalStatus || this.accountingStatus || this.paidStatus,
                    isCurrent: false,
                    visible: !this.isOwner && (this.ownerRepInvHasClientApproval || (!this.isOwnerRep && this.isClientOnSystem)),
                },
                {
                    title: this.$t('Accounting'),
                    description: '',
                    isActive: this.accountingStatus || this.paidStatus,
                    isCurrent: false,
                    visible: this.hasAccountingIntegration && this.accountingErrors?.shouldBeSubmitted,
                },
                {
                    title: this.$t('Paid'),
                    description: '',
                    isActive: this.paidStatus,
                    isCurrent: false,
                    visible: true,
                },
            ]

            return timeline.filter(item => item.visible)
        },

        invoiceWithTaxFieldFeature (): boolean {
            return this.isFeatureEnabled(featureFlagsConsts.INVOICE_TAX_VALUE_FIELD, false)
        }
    },
    async mounted () {
        await this.fetchData()
        if (!this.paidStatus && !this.accountingStatus && !this.invoiceIsDraft) {
            this.checkSubmitToAccounting(this.projectObj.project_local_id, this.$route.params.id)
        }

        if (this.invoice.application_package_id) {
            const { data } = await appPackageClient.getApplicationPackageDetails(this.projectObj.project_local_id, this.invoice.application_package_id)
            this.appPackageData = data
        }

        this.setInvAmountWithTax(null)
        if (this.invoice.current_tax_value) {
            this.setInvAmountWithTax(this.invoice.current_tax_value)
        }
    },
    methods: {
        ...mapActions(invoiceViewStore,
            [
                'fetchInvoiceDetails',
                'fetchInvoiceFundingSources',
                'setDataLoadingValue',
                'checkSubmitToAccounting',
                'setInvAmountWithTax',
            ]),

        ...mapActions(invoiceSoVStore, ['fetchInvoiceWBS']),

        ...mapActions(invoiceRevisionStore, ['loadAdjustmentsIntoWbsItems']),

        gotToAppPackage (): void {},

        async fetchData (): Promise<void> {
            try {
                this.setDataLoadingValue(true)
                this.loading = true

                await this.fetchInvoiceDetails(this.projectObj.project_local_id, this.$route.params.id)
                if (this.isOwnerRep || this.isOwner) {
                    await this.fetchInvoiceFundingSources(this.$route.params.id, this.projectObj.project_local_id)
                }

                //if revision we need to load WBS on details step
                if (this.isFeatureEnabled(FeatureFlagsConsts.INVOICES_REVISION_REQUEST_V2, false) && this.shouldLoadRevision) {
                    await this.fetchInvoiceWBS(this.projectObj.project_local_id, this.$route.params.id, this.isFeatureEnabled(FeatureFlagsConsts.INVOICES_REVISION_REQUEST_V2, false))
                    await this.loadAdjustmentsIntoWbsItems(this.projectObj.project_global_id, this.$route.params.id)
                }

                this.loading = false
                this.setDataLoadingValue(false)
                this.error = false
            } catch (e) {
                console.log(e)
                this.error = true
                this.errorHandleNoRedirect(e)
            } finally {
                this.loading = false
                this.setDataLoadingValue(false)
            }
        },

        goToAppPackage (): void {
            const routeData = this.$router.resolve({
                name: AppPackageRouteName.AppPackageDetails,
                params: { id: this.appPackageData.id }
            })
            window.open(routeData.href, '_blank')
        },

        getBallInCourt (): object {
            if (this.invoice.ball_in_court && this.invoice.ball_in_court.length > 0) {
                return this.invoice.ball_in_court[0]
            }

            return {
                id: '',
                name: '-',
            }
        },

        toggleInvAmountWithTaxModal (): void {
            this.showInvAmountWithTaxModal = !this.showInvAmountWithTaxModal
        },

        updateInvAmountWithTax (value: number): void {
            this.setInvAmountWithTax(value)
        },
    },
})
