<template>
    <div
        class="io-notification-center"
        :class="{ 'io-notification-center_opened': isOpened }"
    >
        <div class="io-notification-center__header">
            <div class="io-notification-center__header-title">
                {{ $t('Notifications') }}

                <span
                    v-if="unreadNotifications"
                    class="io-notification-center__header-title-counter"
                >
                    {{ unreadNotifications }}
                </span>
            </div>
            <div class="io-notification-center__header-right-side">
                <IOButton
                    variant="link"
                    :disabled="!unreadNotifications"
                    class="io-notification-center__mark-all-as-read-btn"
                    @click="onMarkAllAsReadClick"
                >
                    {{ $t('Mark all as read') }}
                </IOButton>
                <div class="io-notification-center__header-unread">
                    {{ $t('Unread only') }}
                    <IOSwitcher
                        :model-value="isUnreadOnly"
                        @update:model-value="onUnreadOnlyChange"
                    />
                </div>
                <i
                    class="io-notification-center__close icon-cross"
                    @click="onNotificationsCenterClose"
                ></i>
            </div>
        </div>

        <Tabs
            placement="right"
            :tabs="notificationsTabs"
            :active-tab="notificationsSelectedTab"
            @click="onNotificationsTabChange"
        />

        <SearchInput
            :placeholder="$t('Search...')"
            :loader="false"
            @searchChanged="onSearchChange"
        />

        <div
            class="io-notification-center__items-container"
        >
            <div
                v-if="isPlaceholderVisible"
                class="io-notification-center__placeholder"
            >
                <div
                    class="io-notification-center__placeholder-icon"
                    :class="{ [placeholderConfig.icon]: true }"
                ></div>

                <div class="io-notification-center__placeholder-title">
                    {{ placeholderConfig.title }}
                </div>

                <div class="io-notification-center__placeholder-text">
                    {{ $t('Looks like you are up-to-date') }}
                </div>
            </div>

            <div
                v-else
                ref="notificationsListScroll"
                class="io-notification-center__list"
            >
                <Notification
                    v-for="(notification, index) in notificationsList"
                    :key="`notification-${ index }`"
                    :notificationData="notification"
                    @notificationCenterClose="onNotificationsCenterClose"
                    @updateNotification="onNotificationUpdate"
                />
            </div>

            <div
                v-if="isLoading"
                class="io-notification-center__loading"
            >
                {{ $t('Fetching data...') }}
            </div>
        </div>
    </div>
</template>

<script src="./NotificationsCenter.ts"></script>
<style lang="scss" src="./NotificationsCenter.scss" scoped></style>
