import { UnitOfMeasure, WbsPhase, WbsQuotedItem } from '@/io-modules/project-contracts/interfaces/WbsItem.ts'
import { Phase, QuotedItem, Task } from '@/io-modules/project-contracts/interfaces/WbsGroup.ts'
import UnitsOfMeasure from '@/constants/UnitsOfMeasure.ts'
import { InsuranceRequirement } from '@/interfaces/modules/list-templates/InsuranceRequirementsInterface.ts'
import { PayloadRequirement } from '@/io-modules/project-contracts/interfaces/ContractWizardPayload.ts'

export const formatNumberToString = (value: number): string => {
    return value ? String(value) : '0.00'
}
export const formatUnit = (value: UnitOfMeasure): string => {
    return value.id || UnitsOfMeasure.LUMP_SUM
}
export const formatDate = (textDate: string | null) => textDate && textDate.length ? textDate.substring(0, 10) : null
export const formatRequirement = (requirement: InsuranceRequirement): PayloadRequirement => {
    const {
        id,
        name,
        parent_id,
        status,
        min_value,
        insurance_value,
        children,
        effective_date,
        expiration_date,
        temporary_attached_documents,
    } = requirement

    return {
        id,
        name,
        parent_id,
        status: status || 0,
        effective_date: formatDate(effective_date),
        expiration_date: formatDate(expiration_date),
        min_value: formatNumberToString(min_value),
        insurance_value: formatNumberToString(insurance_value),
        children: children ? children.map(formatRequirement) : [],
        temporary_attached_documents: temporary_attached_documents || [],
    }
}
export const formatQuotedItem = (quotedItem: WbsQuotedItem): QuotedItem => {
    return {
        id: quotedItem.isNew ? undefined : quotedItem.id,
        name: quotedItem.name,
        number: quotedItem.number,
        value: formatNumberToString(quotedItem.value),
        cost_code_id: quotedItem.costCode,
        enabled: quotedItem.enabled,
        is_alternate: quotedItem.isAlternate,
        retention: quotedItem.retention || 0,
        contract_quoted_item_id: quotedItem.sovLine?.id || null,
        contract_change_id: quotedItem.changeOrder,
        lvl: quotedItem.lvl,
        unit: formatUnit(quotedItem.unit),
        unit_price: formatNumberToString(quotedItem.unitPrice),
        quantity: formatNumberToString(quotedItem.qty),
        phases: quotedItem.tasks?.map(formatPhase) || [],
        project_site_id: quotedItem.projectSiteId,
    }
}
export const formatPhase = (phase: WbsPhase): Phase => {
    return {
        id: phase.isNew ? undefined : phase.id,
        number: phase.number,
        cost_code_id: phase.costCodeId,
        cost_type: phase.costType,
        description: phase.description,
        value: formatNumberToString(phase.value),
        retention: phase.retention || 0,
        number_global: phase.number_global,
        lvl: phase.lvl,
        unit: formatUnit(phase.unit),
        unit_price: formatNumberToString(phase.unitPrice),
        quantity: formatNumberToString(phase.qty),
        tasks: phase.tasks?.map(formatTask) || [],
    }
}
export const formatTask = (task: WbsPhase): Task => {
    return {
        id: task.isNew ? undefined : task.id,
        number: task.number,
        cost_code_id: task.costCodeId,
        cost_type: task.costType,
        description: task.description,
        value: formatNumberToString(task.value),
        retention: task.retention || 0,
        number_global: task.number_global,
        lvl: task.lvl,
        unit: formatUnit(task.unit),
        unit_price: formatNumberToString(task.unitPrice),
        quantity: formatNumberToString(task.qty),
    }
}
