<template>
    <form>
        <div class="io-form-group io-form-validation io-form-input-group-horizontal" :class="{ 'io-v-error': isIdExist }">
            <label class="io-column-label">{{ $t('Generated ID') }}
                <Tooltip :text="$t('Project ID not editable')" />
            </label>

            <div v-if="!isProposal" class="io-column-field io-column-validation-field">
                <div class="io-input-group">
                    <FieldProposedId name="proposedId" classname="form-conimasktrol" v-model="proposedId" />
                </div>
                <div v-if="isIdExist" class="io-error-msg"><span>{{ $t('ID already exists. Choose a different number')
                }}</span>
                </div>
            </div>
            <div v-else class="io-column-field">
                {{ proposedId.formatted }}
            </div>
        </div>
        <div class="io-form-group io-form-validation" :class="{ 'io-v-error': v$.project.external_id.$error }">
            <label class="io-column-label">{{ $t('Custom ID') }}
                <Tooltip :text="$t('Populate this field for your company-specific Project ID')" />
            </label>
            <div class="io-column-field">
                <input class="io-column-field form-control" type="text" maxlength="140" v-model="project.external_id" />
            </div>
        </div>
        <div class="io-form-group io-form-validation" :class="{ 'io-v-error': v$.project.name.$error }">
            <label class="io-column-label required">{{ $t('Project Name') }}</label>
            <div class="io-column-field io-column-validation-field">
                <input class="io-column-field form-control" type="text" v-model="project.name" />
            </div>
        </div>

        <template v-if="canEditAgreementType">
            <div class="io-form-group io-form-validation">
                <label class="io-column-label required">{{ $t('Agreement Type') }}</label>
                <template v-if="'' === project.agreement_type">
                    <IOButton variant="link" icon="icon-plus" @click="$emit('openAgreementTypePopup')">
                        {{ $t('Select') }}
                    </IOButton>
                </template>

                <div v-else class="io-agreement-type-selected-container">
                    <div class="io-agreement-type-icon" :class="`io-${project.agreement_type}`" />
                    <div class="io-agreement-type-selected">
                        {{ project.agreement_type }}
                    </div>
                    <i class="icon-cross" @click="$emit('setAgreementType', '')" />
                </div>
            </div>
        </template>

        <template v-if="isGmpEnabled && isGCWorkspace">
            <div class="io-form-group io-form-validation">
                <label class="io-column-label required">{{ $t('Main Contract Type') }}</label>
                <template v-if="null === project.main_contract_type">
                    <IOButton variant="link" icon="icon-plus" @click="$emit('setMainContractTypePopup', true)">
                        {{ $t('Select') }}
                    </IOButton>
                </template>

                <div v-else class="io-main_contract-type-selected-container">
                    <div class="io-main_contract-type-selected">
                        {{ mainContractTypeLabel }}
                    </div>
                    <i class="icon-cross" @click="$emit('setMainContractType', null)" />
                </div>
            </div>
        </template>

        <div class="io-form-group">
            <label class="io-column-label">{{ $t('Assign PM as Default Approver') }}
                <Tooltip :text="$t('Having a default approver will notify that individual if digitally received change orders or invoices are pending their approval. \n'+
'Having a default approver is not essential if you are managing off system contracts.')"/>
            </label>
            <IOSwitcher :title="isPMApprover ? $t('Yes') : $t('No')" @update:model-value="$emit('setPMAsApprover')"/>
        </div>

        <template v-if="!isOwner">
            <div class="io-form-group">
                <label class="io-column-label" :class="{ required: !isProposal }">{{ $t('Add or Invite Client') }}</label>
                <div v-if="!isProposal" class="io-column-field io-column-validation-field io-invitation-cell">
                    <InvitePreview
                        :key="invitationClientKey"
                        :componentKey="invitationClientKey"
                        :previewType="PreviewTypes.BUTTON_SECONDARY"
                        :selectMultiple="false"
                        :modalZIndex="100"
                        :modelType="null"
                        :invitedContactPersons="invitedClient"
                        :bookmarkedContactPersons="bookmarkedClient"
                        :buttonLabelText="$t('Add, Invite, or Bookmark for later')"
                        :showContactsOnly="true"
                        @change="onSelectedClientChange"
                        @removeSelected="$event => $emit('removeSelectedClient', $event)"
                        @inviteModalDone="onClientInviteModalDone"
                        @close="reInitializeGoogleSearchInput"
                    />
                </div>
                <div v-else class="io-column-field">
                    {{ client.name }}
                </div>
            </div>

            <div class="io-form-group">
                <label class="io-column-label required">{{ $t('Base Currency') }}</label>
                <div class="io-column-field io-column-validation-field io-invitation-cell">
                    <CurrencyDropdown
                        placeholder="Change Currency"
                        :options="currencies"
                        :value="project.currency"
                        @select="$event => $emit('selectCurrency', $event)"
                    />
                </div>
            </div>
        </template>


        <div class="io-form-seperator"></div>

        <div class="io-form-group">
            <label class="io-column-biglabel">{{ $t('Project Site Address') }}</label>
        </div>

        <template v-if="isAddresValidationBannerShown">
            <IOBanner
                :text="projectAddressValidationBannerData.text"
                :type="projectAddressValidationBannerData.type"
                :icon="projectAddressValidationBannerData.icon"
            />
        </template>

        <div v-if="isLoading" id="io-loader-1" class="io-loader"></div>

        <div v-if="!isProposal || project.project_address1" class="io-form-group">
            <label class="io-column-label">{{ $t('Address 1') }}</label>
            <div class="io-input-field">
                <input
                    v-if="!isProposal"
                    type="text"
                    class="io-column-field form-control address1 project_address1"
                    name="project_address1"
                    v-model="project.project_address1"
                    @change="onProjectAddressValueChanged"
                />
                <span v-else>{{ project.project_address1 }}</span>
            </div>
        </div>

        <div v-if="!isProposal || project.project_address2" class="io-form-group">
            <label class="io-column-label">{{ $t('Address 2') }}</label>
            <div class="io-input-field">
                <template v-if="!isProposal">
                    <IOInput
                        type="text"
                        aria-labelledby="name"
                        maxLength="60"
                        :showSearch="true"
                        :addNewEnabled="true"
                        :autocompleteItems="createdWithSameAddressBuildingSubsites"
                        v-model="project.project_address2"
                        @update:modelValue="onUpdateAddressAction"
                        @autocomplete="onSelectProjectAddress2"
                    >
                        <template #autocomplete="{ item }">
                            <span class="icon-pin"></span> {{ item.name }}
                        </template>
                    </IOInput>
                    <div v-if="v$.project.project_address2.$error" class="io-error-content">
                        <span>{{ $t('Max length exceeded (60 characters)') }}</span>
                    </div>
                </template>
                <span v-else>{{ project.project_address2 }}</span>
            </div>
        </div>
        <div v-if="!isProposal || project.project_city" class="io-form-group">
            <label class="io-column-label">{{ $t('City') }}</label>
            <div class="io-input-field">
                <input
                    v-if="!isProposal"
                    class="io-column-field form-control project_city"
                    type="text"
                    name="project_city"
                    v-model="project.project_city"
                    @change="onAddressValueChanged"
                />
                <span v-else>{{ project.project_city }}</span>
            </div>
        </div>

        <div v-if="!isProposal || project.project_state_id" class="io-form-group">
            <label class="io-column-label">{{ $t('State') }}</label>
            <div class="io-input-field">
                <multiselect
                    v-if="!isProposal"
                    track-by="id"
                    label="name"
                    name="project_state_id" :searchable="true"
                    :disabled="stateDisabled ?? null"
                    :options="states"
                    :allow-empty="null"
                    :class="{ 'project_state_id': true }"
                    :placeholder="$t('Please Select')"
                    v-model="project.project_state_id"
                    @select="onAddressValueChanged"
                />
                <span v-else>{{ project.project_state_id.name }}</span>
            </div>
        </div>


        <div v-if="!isProposal || project.project_zip" class="io-form-group">
            <label class="io-column-label">{{ $t('ZIP Code') }}</label>
            <div class="io-input-field">
                <input
                    v-if="!isProposal"
                    type="text"
                    class="io-column-field form-control project_zip"
                    name="project_zip"
                    v-model="project.project_zip"
                    @change="onAddressValueChanged"
                />
                <span v-else>{{ project.project_zip }}</span>
            </div>
        </div>

        <div v-if="!isProposal || project.project_country" class="io-form-group">
            <label class="io-column-label">{{ $t('Country') }}</label>
            <div class="io-input-field">
                <multiselect
                    v-if="!isProposal"
                    track-by="id"
                    label="name"
                    :options="countries"
                    :allow-empty="false"
                    :class="{ 'project_country': true }"
                    name="project_country"
                    :searchable="true"
                    v-model="project.project_country"
                    @select="onCountryValueChanged"
                />
                <span v-else-if="project.project_country">{{ project.project_country.name }}</span>
            </div>
        </div>

        <div v-if="isDevAppType" class="io-form-group">
            <label class="io-column-label">{{ $t('Project Executive') }}</label>
            <div class="io-column-field io-invitation-cell">
                <InvitePreview
                    :key="invitationProjectExecutiveKey"
                    :componentKey="invitationProjectExecutiveKey"
                    :previewType="PreviewTypes.BUTTON_SECONDARY"
                    :selectMultiple="false"
                    :modelType="null"
                    :customModalTitle="$t('Select Employee')"
                    :showEmployeesOnly="true"
                    :modalZIndex="100"
                    :buttonLabelText="$t('Add Project Executive')"
                    :disableInviting="true"
                    :bookmarkedContactPersonIds="bookmarkedProjectExecutivePersonIds"
                    @updateOnItemContactsList="$event => $emit('onUpdateProjectExecutive', $event)"
                    @removeSelected="$event => ('resetProjectExecutive', $event)"
                    @inviteModalDone="onProjectExecutiveInviteModalDone"
                    @close="reInitializeGoogleSearchInput"
                />
            </div>
        </div>

        <div class="io-form-group">
            <label class="io-column-label">{{ $t('Project Manager') }}</label>
            <div class="io-column-field io-invitation-cell">
                <InvitePreview
                    :key="invitationProjectManagerKey"
                    :componentKey="invitationProjectManagerKey"
                    :previewType="PreviewTypes.BUTTON_SECONDARY"
                    :selectMultiple="false"
                    :modelType="null"
                    :customModalTitle="$t('Select Employee')"
                    :showEmployeesOnly="true"
                    :modalZIndex="100"
                    :invitedContactPersons="invitedProjectManager"
                    :bookmarkedContactPersons="bookmarkedProjectManager"
                    :buttonLabelText="$t('Add Project Manager')"
                    @updateOnItemContactsList="$event => $emit('onChangeUpdateManager', $event)"
                    @removeSelected="$event => $emit('resetManager', $event)"
                    @inviteModalDone="onProjectManagerInviteModalDone"
                    @close="reInitializeGoogleSearchInput"
                />
            </div>
        </div>
    </form>
</template>

<script lang="ts" src="./StepProjectDetails.ts"></script>

<style lang="scss" src="./StepProjectDetails.scss" scoped></style>
