import feasibilityStudyClient from '@/api/projectBudget/feasibilityStudyClient'
import projectClient from '@/api/project/projectClient'
import UnitOfMeasure from '@/interfaces/UnitOfMeasure'
import type FeasibilityStudyResponseData from '@/modules/projects/modules/apps/common/budget/interfaces/FeasibilityStudyResponseData'
import type { Commit, Dispatch } from 'vuex'

export const fetchFeasibilityStudy = async function (
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    value: { projectId: string, budgetType: string, budgetVersionIdThatMustOwn: string }
): Promise<void> {
    try {
        const { data } = await feasibilityStudyClient.getByProjectId(
            value.projectId,
            value.budgetType,
            value.budgetVersionIdThatMustOwn
        )

        if (data?.data.id) {
            dispatch('notification/removeBanner', 'project-budget-warning', { root: true })

            setFeasibilityStudy({ commit }, data.data)
        }
    } catch (e) {
        setFeasibilityStudy({ commit }, null)
    }
}

export const fetchFeasibilityStudyOverview = async function (
    { commit }: { commit: Commit },
    feasibilityStudyId: string
): Promise<void> {
    const { data } = await feasibilityStudyClient.getOverview(feasibilityStudyId)

    commit('SET_BUDGET_VERSIONS_COUNT', data.total_versions)
}

export const fetchUnitOfMeasure = async function ({ commit }: any, projectId: string): Promise<void> {
    const { data } = await projectClient.getSummary(projectId)

    const unitId = data.project?.unit_of_measure?.id || 1
    commit('SET_UNIT_OF_MEASURE', 1 === unitId ? UnitOfMeasure.IMPERIAL : UnitOfMeasure.METRIC)
    commit('SET_UNIT_OF_MEASURE_AS_LOADED')
}

export const setFeasibilityStudy = function (
    { commit }: { commit: Commit },
    feasibilityStudy: FeasibilityStudyResponseData
): void {
    if (!feasibilityStudy) {
        commit('SET_BUDGET_VERSIONS_COUNT', 0)

        commit('SET_HIDDEN_BUDGET_VERSIONS_COUNT', 0)

        commit('SET_FEASIBILITY_STUDY', null)

        return
    }

    commit('SET_BUDGET_VERSIONS_COUNT', feasibilityStudy.total_versions)

    commit('SET_HIDDEN_BUDGET_VERSIONS_COUNT', feasibilityStudy.hidden_versions)

    commit('SET_FEASIBILITY_STUDY', feasibilityStudy)
}
