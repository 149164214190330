<template>
    <PopupModal :width="540" class="io-assigned-cost-codes-popup" @close="close">

        <template #header>
            <div class="io-popup-modal__icon"><i class="far fa-info-circle"></i></div>
            <div class="io-popup-modal__title">{{ workflowName }} {{ $t('Cost Codes') }}</div>
            <div class="io-popup-modal__close" @click="close"><i class="fal fa-times"></i></div>
        </template>

        <template #default>
            <div v-for="category in assignedCostCodesCategories" class="io-assigned-cost-codes-popup__group">
                <div class="io-assigned-cost-codes-popup__category">
                    {{ category.code_name }}
                </div>
            </div>
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="close">{{ $t('OK') }}</IOButton>
        </template>

    </PopupModal>
</template>

<script lang="ts" src="./AssignedCostCodesPopup.ts"></script>
<style lang="scss" scoped src="./AssignedCostCodesPopup.scss"></style>
