<template>
    <div class="io-content-two-sides-template">
        <div class="io-content-two-sides-template__left" :style="{ width: leftWidth }">
            <slot name="leftSide"></slot>
        </div>
        <div class="io-content-two-sides-template__right" :style="{ width: rightWidth }">
            <div v-if="iconClass || title" class="io-content-two-sides-template__title io-f-row io-f-between io-align-center">
                <div class="io-f-row io-f-start io-align-center">
                    <span
                        v-if="iconClass"
                        class="io-content-two-sides-template__right__icon"
                        data-testid="icon"
                        :class="iconClass"
                        :style="{ color: iconColor }"
                    ></span>
                    <span v-if="title" class="io-content-two-sides-template__right__title">{{ title }}</span>
                </div>
                <slot name="rightSideActions"></slot>
            </div>
            <slot name="rightSide"></slot>
        </div>
    </div>
</template>

<script lang="ts" src="./ContentTwoSidesTemplate.ts"></script>
<style lang="scss" src="./ContentTwoSidesTemplate.scss" scoped></style>
