<template>
    <div class="io-single-markup shadow" :class="{'io-selected': isSelected }" @click.stop="selectAnnotation">
        <div class="io-single-markup__header">
            <div class="io-left-side">
                <SvgIconComponent
                    v-if="svgIconTypes.includes(data.tool_name)"
                    :toolName="data.tool_name"
                    :toolColor="data.tool_color"
                />

                <i v-else :class="getToolIcon"/>

                <span class="io-shape-number">
                    {{ $t('Shape No:') }}
                    <span class="io-number">
                        {{ index + 1 }}
                    </span>
                </span>
            </div>

            <div class="io-right-side">
                <span>{{ $filters.parseDateTime(data.created_at) }}</span>

                <DropdownButtonNew :icon-mode="true" font-awesome-class="icon-more-vert-2">
                    <div class="io-single-option" @click="openSharingSettingsPopup">{{ $t('Edit') }}</div>
                    <div class="io-single-option" @click="deleteMarkup">{{ $t('Delete') }}</div>
                </DropdownButtonNew>
            </div>
        </div>

        <div class="io-markup-author">
            <div class="io-markup-author__avatar">
                <img
                    v-if="data.created_by_full_name.imageExist"
                    :src="getApiUrlAvatar(data.created_by_full_name.id)"
                />
                <Initials
                    v-else
                    :fullName="data.created_by_full_name.name"
                />
            </div>
            <div class="io-markup-author__user-info">
                <div>{{ data.created_by_full_name.name }}</div>
                <div>{{ data.created_by_full_name.position }}</div>
            </div>
        </div>

        <div class="io-markup-visibility">
            <label>{{ $t('Markup Visibility') }}</label>
            <div class="io-markup-visibility__wrapper">
                <template v-if="data.visibility === 'workspace_only'">
                    <i class="icon-monitor"/>
                    <span>{{ $t('Workspace Only') }}</span>
                </template>

                <template v-else>
                    <i class="icon-users"/>
                    <span>{{ $t('Project Directory') }}</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./SingleMarkup.ts"></script>
<style lang="scss" src="./SingleMarkup.scss" scoped></style>
