<template>
    <form enctype="multipart/form-data">
        <div v-if="!contract.files.length" class="io-project-wizard-nofile">
            {{
                $t(
                    'Drag and drop your contract attachment or click upload to select the file from your computer'
                )
            }}
        </div>
        <div class="io-project-wizard-list io-project-wizard-list-uploader">
            <div class="io-uploader-container">
                <Files
                    :addToTemporary="true"
                    :inDocumentsUpload="false"
                    :subSection="null"
                    :arrayId="arrayId"
                    :forceShared="true"
                    :validationOptions="validationOptions"
                    @newFileAdded="onFileChange"
                />
            </div>
        </div>
    </form>
</template>

<script lang="ts" src="./StepUploadContract.ts"></script>

<style lang="scss" src="./StepUploadContract.scss" scoped></style>
