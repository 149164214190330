import { defineComponent, PropType, nextTick, computed } from 'vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import ColumnsGroup from './interfaces/ColumnsGroup'
import TableRowContainer from './parts/table-row-container/TableRowContainer.vue'
import manageColumnsHelper from '../manage-columns-modal/helpers/ManageColumnsHelper'
import { mapActions, mapState } from 'vuex'
import Button from '@/interfaces/components/placeholder/Button'
import TableRowActions from '@/components/expandable-table/parts/table-row-actions/TableRowActions'

export default defineComponent({
    name: 'ExpandableTable',
    components: {
        TableRowActions,
        TableRowContainer,
    },
    provide () {
        return {
            itemInEditId: computed(() => this.itemInEditId),
            haveALotOfItems: computed(() => this.haveALotOfItems),
        }
    },
    props: {
        headers: {
            type: Array as PropType<HeaderTable[]>,
            default: () => [],
        },
        groups: {
            type: Array as PropType<ColumnsGroup[]>,
            default: null,
        },
        items: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        showColumnSubText: {
            type: Boolean,
            default: false,
        },
        nestedRowsIndicator: {
            type: String,
            default: 'tasks',
        },
        showFooter: {
            type: Boolean,
            default: false,
        },
        onlyFooter: {
            type: Boolean,
            default: false,
        },
        showActionsRow: {
            type: Boolean,
            default: false,
        },
        actionsRowButtons: {
            type: Array as PropType<Button[]>,
            default: () => [],
        },
        onlyActionsRow: {
            type: Boolean,
            default: false,
        },
        showScrollArrows: {
            type: Boolean,
            default: false,
        },
        itemIdentifier: {
            type: String,
            default: 'id',
        },
        id: {
            type: String,
            default: '',
        },
    },
    emits: ['addQuotedItem', 'loadFromTemplate', 'inputClicked'],
    data () {
        return {
            tableHeaders: [],
            scrollLeft: 0,
            collectedIDs: [],
            isAtStart: true,
            isAtEnd: false,
            table: null,
            visibleWidth: 0,
            visibleHeight: 0,
            scrollTop: 0,
            itemInEditId: null as string | null,
        }
    },
    computed: {
        ...mapState('financesCommon', ['collapseAllSoV', 'expandAllSoV', 'rowsToExpand', 'allRowsToExpand']),

        leftArrowPosition (): string {
            return `${ this.scrollLeft + 575 }px`
        },
        rightArrowPosition (): string {
            if (this.table) {
                return `${ this.scrollLeft + this.visibleWidth - 50 }px`
            }
        },
        topArrowPosition (): string {
            if (this.table) {
                return `${ this.scrollTop + this.visibleHeight/2 + 20 }px`
            }
        },
        haveALotOfItems (): boolean {
            return this.items.length > 50
        }
    },
    watch: {
        headers (value: HeaderTable[], prevValue: HeaderTable[]): void {
            if (!this.groups && value.length !== prevValue.length) {
                this.setupColumns()

                this.updateButtons()
                this.isAtEnd = true //from all to basic columns
                this.isAtStart = true
            }
        },

        groups (value: ColumnsGroup[], prevValue: ColumnsGroup[]): void {
            if (value) {
                this.setupColumns()

                this.updateButtons()
                this.isAtEnd = false //from basic to all columns
            }
        },

        rowsToExpand: {
            handler (newExpandedRows: Array<string>): void {
                if (newExpandedRows.length > 0) {
                    this.setIsAbleCollapseSoV(true)
                } else {
                    this.setIsAbleCollapseSoV(false)
                }
            },
            deep: true,
        },
        collapseAllSoV: {
            handler (): void {
                this.manageRowsToExpand()
            },
        },
        expandAllSoV: {
            handler (): void {
                this.manageRowsToExpand()
            },
            immediate: true
        },
        items: {
            handler (): void {
                this.collectIDsToExpand()

                this.setAllRowsToExpand([...new Set([...this.allRowsToExpand, ...this.collectedIDs])])
            },
            deep: true,
        },
    },
    mounted () {
        this.collectIDsToExpand()
        this.setAllRowsToExpand([...new Set([...this.allRowsToExpand, ...this.collectedIDs])])

        this.setupColumns()

        this.table = this.$refs.table
        if (this.table) {
            this.table.addEventListener('scroll', this.handleScroll)
            window.addEventListener('keydown', this.handleKeydown)
            window.addEventListener('resize', this.updateButtons)
            this.updateButtons()
        }
    },
    beforeUnmount () {
        if (this.table) {
            window.removeEventListener('resize', this.updateButtons)
            window.removeEventListener('keydown', this.handleScroll)
            this.table.removeEventListener('scroll', this.handleScroll)
        }
    },
    methods: {
        ...mapActions('financesCommon', ['setIsAbleCollapseSoV', 'setCollapseSoV', 'setExpandSoV', 'setRowsToExpand', 'setAllRowsToExpand']),

        handleScroll (event: Event): void {
            this.scrollLeft = event.target.scrollLeft
            this.scrollTop = event.target.scrollTop

            this.updateButtons()
        },

        setTableScrollLeft (value: number): void {
            this.table.scrollLeft = value
        },

        getGroupColspan (group: ColumnsGroup): number {
            return group.columns.filter(item => item.visible).length
        },

        async setupColumns (): Promise<void> {
            await nextTick()
            if (this.groups && this.groups.length) {
                let headers = []
                this.groups.forEach(group => {
                    headers.push(...group.columns.filter(column => column.visible))
                })

                this.tableHeaders = [...headers]
            } else {
                this.tableHeaders = [...this.headers]
            }
            this.setupFixedColumns()
        },

        async setupFixedColumns (): Promise<void> {
            await nextTick()
            const fixedColumns = this.tableHeaders.filter(item => item.fixed && item.visible)
            if (fixedColumns && fixedColumns.length) {
                manageColumnsHelper.setupTableColumnsLeft(fixedColumns, '.io-table-header.io-fixed-column')
                this.tableHeaders = [...fixedColumns, ...this.tableHeaders.filter(item => !item.fixed || !item.visible)]
            }
        },

        collectAllIDs (obj: Array<any>, idArray: Array<any>): void {
            if (!obj || typeof obj !== 'object') {
                return
            }

            if (this.itemIdentifier in obj && !idArray.includes(obj[this.itemIdentifier])) {
                idArray.push(obj[this.itemIdentifier])
            }

            if (Array.isArray(obj[this.nestedRowsIndicator])) {
                obj[this.nestedRowsIndicator].forEach(task => {
                    this.collectAllIDs(task, idArray)
                })
            }
        },

        collectIDsToExpand (): void {
            this.items.forEach(obj => {
                this.collectAllIDs(obj, this.collectedIDs)
            })
        },

        updateRowsToExpand (rows: string[]): void {
            if (this.rowsToExpand.includes(rows[0])) {
                this.setRowsToExpand(this.rowsToExpand.filter(item => !rows.includes(item)))
            } else {
                this.setRowsToExpand([...this.rowsToExpand, rows[0]])
            }
        },

        manageRowsToExpand (): void {
            if (this.collapseAllSoV) {
                this.setRowsToExpand([])

                this.setCollapseSoV(false)
            } else if (this.expandAllSoV) {
                this.setRowsToExpand(this.allRowsToExpand)

                this.setExpandSoV(false)
            }
        },

        hasHeaderSubValue (header: HeaderTable): boolean {
            return this.headerSubValue(header).length > 0
        },

        headerSubValue (header: HeaderTable): string {
            if (header.subHeaderText instanceof Function) {
                return header.subHeaderText(this.items) || ''
            }

            return header.subHeaderText || ''
        },

        footerValue (header: HeaderTable): string {
            if (header.footerValue instanceof Function) {
                return header.footerValue(this.items) || ''
            }

            return header.footerValue || ''
        },

        hasFooterSubValue (header: HeaderTable): boolean {
            return this.footerSubValue(header).length > 0
        },

        footerSubValue (header: HeaderTable): string {
            if (header.footerSubValue instanceof Function) {
                return header.footerSubValue(this.items) || ''
            }

            return header.footerSubValue || ''
        },

        hasFooterLeftIcons (header: HeaderTable): boolean {
            if (header.footerLeftIcons instanceof Function) {
                return header.footerLeftIcons()
            }

            return header.footerLeftIcons
        },

        updateButtons (): void {
            const table = this.table
            if (table) {
                this.isAtStart = table.scrollLeft === 0
                this.isAtEnd = table.scrollLeft + table.offsetWidth + 50 >= table.scrollWidth
                this.visibleWidth = table.clientWidth
                this.visibleHeight = table.clientHeight
            }
        },

        doScrollLeft (): void {
            const table = this.table
            table.scrollLeft -= table.offsetWidth / 2
            this.updateButtons()
        },

        doScrollRight (): void {
            const table = this.table
            table.scrollLeft += table.offsetWidth / 2
            this.updateButtons()
        },

        handleKeydown (event: KeyboardEvent): void {
            switch (event.key) {
            case 'ArrowLeft':
                this.doScrollLeft()
                break
            case 'ArrowRight':
                this.doScrollRight()
                break
            }
        },

        handleClickEditItem (itemId: string): void {
            this.itemInEditId = itemId
        },

        emitInputClick (itemId: string, item: object): void {
            this.$emit('inputClicked', itemId, item)
        }
    },
})
