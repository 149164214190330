<template>
    <img
        class="io-icon-tooltip"
        v-tooltip.top="text"
        src="/new-theme/img/icon-warning.svg"
        alt="Icon Warning"
    />
</template>

<script lang="ts" src="./IconTooltip.ts"></script>
<style lang="scss" src="./IconTooltip.scss" scoped></style>
