<template>
    <div :class="`io-form-label-row-vertical ${ className }`">
        <label
            class="io-form-column-label"
            :class="{ 'io-required': required }"
            :for="label"
        >
            <span>
                {{ label }}
                <span v-if="optional" class="optional"> (optional)</span>
            </span>

            <span v-if="tooltipEnable">
                <slot name="tooltip">
                    <Tooltip :text="tooltipText" />
                </slot>
            </span>
        </label>

        <template v-if="!staticText">
            <template v-if="!customContent">
                <input
                    :id="label"
                    ref="input"
                    class="io-form-column-input"
                    :class="{ 'io-error': !isValid, 'io-disabled': disabled }"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :required="required"
                    :type="type"
                    :maxLength="maxLength"
                    v-model="value"
                    @change="$emit('onChange', value)"
                    @keyup="$emit('change', value)"
                    @keyup.enter="$emit('enter')"
                />

                <span v-if="!isValid && errorText" class="io-error-value">{{ errorText }}</span>
            </template>

            <template v-if="customContent">
                <slot></slot>
                <span v-if="!isValid && errorText" class="io-error-value">{{ errorText }}</span>
            </template>
        </template>

        <p class="io-static-text">{{ staticText }}</p>
    </div>
</template>

<script lang="ts" src="./FormInputColumn.ts"></script>
<style lang="scss" src="./FormInputColumn.scss" scoped></style>
