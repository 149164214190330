export default {
    importCounters: {},
    importFailedRecords: {},
    importCompletedRecords: {},
    importDuplicatedCounter: {},
    dromoConfiguration: {},
    importConfiguration: {},
    dataImporter: null,
    clients: []
}
