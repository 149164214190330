<template>
    <div class="io-project-funding-rules">
        <AlertBox dismissible>
            {{ $t('Set up rules to allocate funding for invoices') }}
        </AlertBox>
        <div v-if="projectRules.length && costCodes" class="io-project-funding-rules__rules">
            <div class="io-project-funding-rules__header">
                {{ $t('Rules') }}
            </div>
            <div class="io-project-funding-rules__body">
                <Draggable
                    handle=".icon-drag"
                    item-key="id"
                    v-model="projectRules"
                    @change="specifyRulesOrder"
                >
                    <template #item="{ element: rule }">
                        <div class="io-project-funding-rules__rule"
                             :class="{'io-project-funding-rules__rule__warning': !rule.is_applicable && !rule.creating}"
                        >
                            <div class="io-project-funding-rules__fields">
                                <span class="icon-drag io-project-funding-rules__icon"/>

                                <span v-if="!rule.is_applicable && !rule.creating"
                                      v-tooltip.top="$t('The total % for selected cost codes is over 100%. The rule won’t be applied.')"
                                      class="icon-alert-triangle-2 io-project-funding-rules__icon-alert"
                                />

                                <p :class="{'io-project-funding-rules__text': rule.creating}">
                                    {{ $t('Automatically fund invoices for') }}
                                </p>

                                <p v-if="!rule.creating" class="io-project-funding-rules__value">
                                    {{ rule.unit_type === 'percent' ? $filters.formatPercent(rule.unit_value) : $filters.formatCurrency(rule.unit_value) }}
                                </p>

                                <DoubleInput v-else
                                             :dropdown-options="dropdownOptions"
                                             :initial-input-value="parseFloat(rule.unit_value)"
                                             :default-selected-item="dropdownOptions[0]"
                                             :multiselect-options="multiselectOptions"
                                             @leftValue="value => updateRuleField(rule.id, 'unit_value', value)"
                                             @rightValue="value => updateRuleField(rule.id, 'unit_type', value)"
                                />

                                <p :class="{'io-project-funding-rules__text': rule.creating}">{{ $t('of the total amount from') }}</p>

                                <p v-if="!rule.creating" class="io-project-funding-rules__value">
                                    {{ rule.for_fund.name }}
                                </p>

                                <div v-else class="io-project-funding-rules__funds">
                                    <CustomMultiselect
                                        :settings="multiselectOptions"
                                        :options="fundsForRules"
                                        :addingNewInProcess="false"
                                        :statusEnable="false"
                                        :addNewBtnDisabled="false"
                                        icon="icon-chevron-down"
                                        v-model="rule.for_fund"
                                    >
                                        <template #header="{ activeOption }">
                                            <div v-if="activeOption">
                                                {{ activeOption.name }}
                                            </div>
                                            <div v-else class="io-project-funding-rules__placeholder">
                                                {{$t('Select fund')}}
                                            </div>
                                        </template>
                                        <template #option="{ option }">
                                            {{ option.name }}
                                        </template>
                                    </CustomMultiselect>
                                </div>

                                <p :class="{'io-project-funding-rules__text': rule.creating}">{{ $t('to') }}</p>

                                <p v-if="!rule.creating" class="io-project-funding-rules__value">
                                    {{ getCostCodesNames(rule) }}
                                </p>

                                <div v-else class="io-project-funding-rules__cost-codes">
                                    <CostCodesDropdown
                                        :hide-remaining-title="true"
                                        :cost-codes-categories="costCodes"
                                        :custom-label="$t('Select cost code(s)')"
                                        custom-toggle-all
                                        v-model="rule.cost_code_ids"
                                    />
                                </div>
                            </div>
                            <div class="io-project-funding-rules__actions">
                                <template v-if="!rule.creating">
                                    <span class="icon-edit" @click="goToEditMode(rule.id)"/>
                                    <span class="icon-trash" @click="removeRule(rule.id)"/>
                                </template>
                                <template v-else>
                                    <IOButton
                                        variant="link"
                                        icon="icon-check"
                                        :disabled="!canCreateRule(rule.id)"
                                        @click="manageRule(rule.id)"
                                    />
                                    <span class="icon-cross" @click="deleteRule(rule.id)"/>
                                </template>
                            </div>
                        </div>
                    </template>
                </Draggable>
                <div class="io-project-funding-rules__add">
                    <IOButton variant="link" icon="icon-plus" @click="addRule">
                        {{ $t('Add Rule') }}
                    </IOButton>
                </div>
            </div>
        </div>
        <Placeholder
            v-if="loaded && !projectRules.length"
            class="io-project-funding-rules__splash"
            icon="icon-ruler"
            :title="$t('No Rules Added')"
            :subtitle="$t('Click the button below to add your first rule.')"
            :buttonsCta="buttonsCta"
            @addRule="addRule"
        />
    </div>
</template>

<script lang="ts" src="./ProjectFundingRules.ts"></script>
<style lang="scss" src="./ProjectFundingRules.scss" scoped></style>
