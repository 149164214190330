<template>
    <transition name="popup" duration="220" appear>
        <div class="io-design-system io-popup-modal" :id="customId" :class="customClass" :style="contentStyles" @click.stop>
            <div class="io-popup-modal__mask" @click="handleClickOutside"></div>
            <div class="io-popup-modal__wrapper" :style="modalStyles">
                <div class="io-popup-modal__header">
                    <div v-if="hasHeaderSlot" class="io-popup-modal__header-row">
                        <slot name="header"></slot>
                    </div>
                    <div v-if="hasHeaderContentSlot" class="io-popup-modal__header-content">
                        <slot name="headerContent"></slot>
                    </div>
                </div>
                <div class="io-popup-modal__content" :class="{ 'io-popup-modal__content--scrollable': scrollable }">
                    <slot></slot>
                    <transition name="loader-mask">
                        <div v-if="showLoader" class="io-popup-modal__loader-mask">
                            <div class="io-loader-small"></div>
                        </div>
                    </transition>
                </div>
                <div v-if="hasFooterSlot" class="io-popup-modal__footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="./PopupModal.ts"></script>
<style lang="scss" src="./PopupModal.scss"></style>
