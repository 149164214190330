<template>
    <div class="io-project-navigation">
        <div
            v-if="isEmptyMenuItemsList"
            class="io-project-navigation__placeholder"
        >
            <div class="icon-comment-2-cancel io-project-navigation__placeholder-icon"></div>
            <div class="io-project-navigation__placeholder-title">
                {{ $t('No Results Found') }}
            </div>
            <div class="io-project-navigation__placeholder-text">
                {{ $t('Please try selecting a different term and try again.') }}
            </div>
        </div>
        <ul v-else
            class="io-project-navigation__list"
        >

            <template v-for="(item, index) in menuItems" :key="`project-navigation-item-${ index }`">
                <li class="io-project-navigation__list-item">
                    <div
                        v-if="isSingleItemVisible(item)"
                        :key="`project-navigation-sub-item-${ index }`"
                        class="io-project-navigation__sub-list-item"
                        :class="{ 'io-project-navigation__sub-list-item_active': isSubItemActive(item.key) }"
                    >

                        <router-link
                            class="io-project-navigation__sub-list-item-link"
                            :class="{ 'io-project-navigation__sub-list-item-link_active': isSubItemActive(item.key) }"
                            :to="item.url"
                        >
                            <i
                                :class="{
                                    [item.icon]: !isSubItemActive(item.key),
                                    [item.iconActive]: isSubItemActive(item.key)
                                }"
                                class="io-project-navigation__sub-list-item-icon"
                            ></i>

                            <span class="io-project-navigation__sub-list-item-text">
                                {{ $t(item.title) }}
                            </span>
                        </router-link>

                    </div>

                    <template v-else-if="isItemVisible(item)">
                        <div
                            class="io-project-navigation__list-item-title"
                            @click="onSetMenuItemOpened(index)"
                        >
                            <span class="io-project-navigation__list-item-title-text">
                                {{ $t(item.title) }}
                            </span>
                            <i
                                class="icon-chevron-right io-project-navigation__list-item-title-icon"
                                :class="{ 'io-project-navigation__list-item-title-icon_opened': item.isOpened }"
                            ></i>
                        </div>

                        <ul
                            :class="{ 'io-project-navigation__sub-list_opened': item.isOpened }"
                            class="io-project-navigation__sub-list"
                        >
                            <template v-for="(subItem, subItemIndex) in item.children">
                                <li
                                    v-if="item.isOpened && isSubItemVisible(subItem)"
                                    :key="`project-navigation-sub-item-${ subItemIndex }`"
                                    class="io-project-navigation__sub-list-item"
                                    :class="{ 'io-project-navigation__sub-list-item_active': isSubItemActive(subItem.key) }"
                                >
                                    <router-link
                                        class="io-project-navigation__sub-list-item-link"
                                        :class="{ 'io-project-navigation__sub-list-item-link_active': isSubItemActive(subItem.key) }"
                                        :to="subItem.url"
                                    >
                                        <i
                                            class="io-project-navigation__sub-list-item-icon"
                                            :class="{
                                                [subItem.icon]: !isSubItemActive(subItem.key),
                                                [subItem.iconActive]: isSubItemActive(subItem.key)
                                            }"
                                        ></i>

                                        <span
                                            class="io-project-navigation__sub-list-item-text"
                                            :class="{ 'io-project-navigation__sub-list-item-text_short': subItem.is_beta }"
                                        >
                                            {{ $t(subItem.title) }}
                                        </span>

                                        <span
                                            v-if="subItem.is_beta"
                                            class="io-project-navigation__sub-list-item-beta"
                                        >
                                            {{ $t('Beta') }}
                                        </span>

                                    </router-link>
                                </li>
                            </template>
                        </ul>
                    </template>

                </li>
            </template>
        </ul>
    </div>
</template>

<script src="./ProjectNavigation.ts"></script>
<style lang="scss" src="./ProjectNavigation.scss" scoped></style>
