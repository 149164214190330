function setLocalStorage (key, value) {
    localStorage.setItem(key, value)

    const event = new Event('localStorageChange')
    event.key = key
    event.newValue = value
    window.dispatchEvent(event)
}

export { setLocalStorage }
