import { defineComponent, Ref } from 'vue'
import { ImpactType } from '@/io-modules/rfis/enums/Rfi'
import { CostImpact } from '@/io-modules/rfis/interfaces/Rfi'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required, requiredIf } from '@vuelidate/validators'

export default defineComponent({
    name: 'CostImpact',
    props: {
        modelValue: {
            type: Object as () => CostImpact,
            required: true
        }
    },
    emits: ['update:modelValue'],
    setup () {
        return {
            ImpactType,
            v$: useVuelidate()
        }
    },
    computed: {
        value: {
            get (): CostImpact {
                return this.modelValue
            },
            set (value: CostImpact): void {
                this.$emit('update:modelValue', value)
            }
        },
        options (): { type: ImpactType, label: string }[] {
            return [
                {
                    type: ImpactType.TBD,
                    label: this.$t('TBD')
                },
                {
                    type: ImpactType.NO,
                    label: this.$t('No')
                },
                {
                    type: ImpactType.YES,
                    label: this.$t('Yes')
                }
            ]
        },
        errorMessage (): string {
            const [error] = this.v$.$errors

            return error?.$message as string
        }
    },
    validations () {
        return {
            modelValue: {
                value: {
                    required: helpers.withMessage(this.$t('Can’t be empty.'), requiredIf(() => {
                        return this.modelValue.occurred === ImpactType.YES && !this.modelValue.isValueTbd
                    })),
                    $autoDirty: true
                }
            }
        }
    },
})
