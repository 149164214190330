import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'

export interface StatusPill {
    text: string;
    variant: PillVariant;
}

export interface RadioOption {
    description: string;
    subDescription: string[];
    value: string;
    nextStep?: OptionsGroup;
    canComplete?: boolean;
    warningMessage?: string;
    customMessage?: boolean;
    isDisabled?: boolean;
    avatarId?: string;
    showLogo?: boolean;
    isAvatarForCompany?: boolean;
    statusPills?: StatusPill[];
    additionalValue?: string;
    additionalData?: Record<string, unknown>;
}

export default interface OptionsGroup {
    label: string;
    key: string;
    options?: RadioOption[];
    selected?: string;
    hasSlot?: boolean;
    additionalData?: Record<string, unknown>;
    nextStep?: OptionsGroup;
    isSearchable?: boolean;
}

export class OptionsGroupItem implements OptionsGroup {
    label: string
    key: string
    options?: RadioOption[]
    selected?: string
    hasSlot?: boolean
    additionalData?: Record<string, unknown>
    nextStep?: OptionsGroup
    isSearchable?: boolean
    searchKeyword?: string

    get filteredOptions (): RadioOption[] {
        if (this.searchKeyword) {
            return this.options?.filter(option =>
                option.description.toLowerCase().trim().includes(this.searchKeyword.toLowerCase().trim()) ||
                option.subDescription.some(subDescription => subDescription.toLowerCase().trim().includes(this.searchKeyword.toLowerCase().trim()))
            ) ?? []
        }
        return this.options ?? []
    }

    constructor (dto: OptionsGroup) {
        this.label = dto.label
        this.key = dto.key
        this.options = dto.options
        this.selected = dto.selected
        this.hasSlot = dto.hasSlot
        this.additionalData = dto.additionalData
        this.nextStep = dto.nextStep
        this.isSearchable = dto.isSearchable
        this.searchKeyword = ''
    }
}
