import appTypes from '@/base/appTypes.ts'
const appType = localStorage.getItem('appType')

const getAccessByAppType = async (to, from, next, appTypesAccessArray = [appTypes.TYPE_AC, appTypes.TYPE_GC, appTypes.TYPE_INVESTOR, appTypes.TYPE_GENIUS, appTypes.TYPE_DEV, appTypes.TYPE_OWNER, appTypes.TYPE_SC]) => {
    if (appTypesAccessArray.includes(appType)) {
        return next()
    }

    // redirect to dashboard
    return next({name: 'dashboard'})
}
export {getAccessByAppType}
