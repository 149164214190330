<template>
    <div class="io-invite-modal__horizontal-list io-f-row" data-testId="invite-preview-horizontal-list">
        <template v-for="contact in limitedContactsList" :key="contact.id">
            <div
                v-tooltip.top="{ content: avatarRingTooltip, visible: isAvatarRingTooltipVisible && isMarkedWithRing(contact) }"
                class="io-single-contact"
                :class="{ 'io-ball-in-court': isBallInCourt(contact) }"
            >
                <template v-if="isContactInvited(contact)">
                    <div
                        :key="contact.id"
                        v-tooltip.bottom="{ content: contact.email, visible: contact.hasOwnProperty('email') }"
                        class="io-pending-avatar"
                        :class="{ 'io-inactive': isArchivedEmployee(contact) }"
                        :title="contact.email"
                    ></div>
                </template>
                <div
                    v-else-if="contact.id && contact.avatar"
                    :key="contact.id"
                    v-tooltip.bottom="`${ contact.firstname } ${ contact.lastname } ${ isArchivedEmployee(contact) ? '(archived)' : '' }`"
                    class="io-img-holder"
                    :class="{ 'io-inactive': isArchivedEmployee(contact) }"
                    :style="getBackgroundAvatar(contact.id)"
                ></div>

                <Initials
                    v-else
                    :key="contact.id"
                    v-tooltip.bottom="`${ contact.firstname } ${ contact.lastname } ${ isArchivedEmployee(contact) ? '(archived)' : '' }`"
                    class="io-img-holder io-f-column"
                    :class="{ 'io-inactive': isArchivedEmployee(contact), 'io-offsystem-logo': isArchivedOrPendingOrOffSystem(contact) }"
                    :fullName="`${ contact.firstname } ${ contact.lastname }`"
                />
            </div>
        </template>
        <span v-if="isAdditionalContactsItemsVisible" class="io-more-users">
            +{{ remainingContacts }}
        </span>
        <div
            v-if="isInviteAllowed"
            class="io-todos-add-user io-f-column io-cursor-pointer"
            :class="{ 'io-add-no-space': isAdditionalContactsVisible }"
            @click="showInviteModal"
        >
            <i class="icon-plus"></i>
        </div>
    </div>
</template>

<script lang="ts" src="./InvitePreviewHorizontalList.ts"></script>
<style src="./InvitePreviewHorizontalList.scss" lang="scss" scoped></style>
