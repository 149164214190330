import { defineStore } from 'pinia'
import { invoiceViewStore } from './invoiceView'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
import InvoiceSovStoreState from '../interfaces/stores/InvoiceSoVStoreState'
import InvoiceStatus from '../enums/InvoiceStatus'
import SoVLineItem from '../interfaces/SoVLineItem'
import CostCode from '../interfaces/requests/FundingCostCode'
import invoiceEditingClient from '@/io-modules/invoices/api-clients/invoiceEditingClient.ts'
import i18n from '@/base/i18n'
import { ContractChangeItemRoutesNames, ContractChangesCategoryRoutes } from '@/modules/projects/modules/apps/common/contract-changes/interfaces/RoutesNames.ts'
import { ContractChangeStageType } from '@/interfaces/modules/projects/contract-changes/ContractChange.ts'
import GroupedCCLines from '../interfaces/GroupedCCLines.ts'

export const invoiceSoVStore = defineStore('invoiceSoV', {
    state: (): InvoiceSovStoreState => ({
        changeOrderWithinOriginalScope: [],
        initialChangeOrderWithinOriginalScope: [],
        contractWBS: [],
        changeOrdersWBS: [],
        initialContractWBS: [],
        initialChangeOrderWBS: [],
        tableViewMode: null,
        columnsViewMode: null,
        visibleLinesFilter: null,
        nonContractedLineItems: [],
        initialNonContractedLineItems: [],
        updateStep: 0,
        invoiceWBSLoaded: false,
        invoiceWBSRecalculating: false,
        contractChangeType: ContractChangeStageType.CHANGE_ORDER,
        wbsSites: []
    }),
    getters: {
        nonContractedLineItemsTotal (state: InvoiceSovStoreState): number {
            return state.nonContractedLineItems.reduce((prevValue, currItem) => prevValue + currItem.total, 0)
        },

        isNonContractedLineItemsCompleted (state: InvoiceSovStoreState): boolean {
            return state.nonContractedLineItems.length && state.nonContractedLineItems.every(item => item.description && (item.cost_code_id || item.cost_code?.id) && item.quantity && item.unit_price)
        },

        initialCurrentApplicationTotal (state: InvoiceSovStoreState): number {
            const invoiceView = invoiceViewStore()

            if (invoiceView.isNonContractedInvoice) {
                return state.initialNonContractedLineItems.reduce((prevValue, currItem) => prevValue + currItem.total, 0) * process.env.SCALE_FACTOR
            }

            if (SoVTableViewMode.COsInSeparateCategory === state.tableViewMode) {
                const contractWBSSum = state.initialContractWBS.reduce((sum: number, phase: object) => sum + phase.current_application, 0)
                const changeOrdersWBSSum = state.initialChangeOrderWBS.reduce((sum: number, phase: object) => sum + phase.current_application, 0)
                return contractWBSSum + changeOrdersWBSSum
            } else {
                return state.initialChangeOrderWithinOriginalScope.reduce((sum: number, phase: object) => sum + phase.current_application, 0)
            }
        },

        netInvoiceValue (state: InvoiceSovStoreState): number {
            if (!state.contractWBS.length && !state.changeOrdersWBS.length) {
                const invoiceView = invoiceViewStore()

                return this.nonContractedLineItemsTotal || invoiceView.invoice.net_invoice_amount || 0
            }

            if (SoVTableViewMode.COsWithinOriginalScope === state.tableViewMode) {
                return state.changeOrderWithinOriginalScope.reduce((sum: number, phase: object) => sum + this.netCurrentApplication(phase) || 0, 0)
            } else {
                const contractWBSSum = state.contractWBS.reduce((sum: number, phase: object) => sum + this.netCurrentApplication(phase)|| 0, 0)
                const changeOrdersWBSSum = state.changeOrdersWBS.reduce((sum: number, phase: object) => sum + this.netCurrentApplication(phase) || 0, 0)
                return contractWBSSum + changeOrdersWBSSum            }
        },

        netInvoiceValueWithoutFactor (): number {
            return this.netInvoiceValue / process.env.SCALE_FACTOR
        },

        sovHasChanges (): boolean {
            const invoiceView = invoiceViewStore()

            return this.netInvoiceValue !== invoiceView.invoice.net_current_application
        },

        groupedByContractChangeWBS (state: InvoiceSovStoreState): GroupedCCLines[] {
            return (activeSiteId: id): GroupedCCLines[] => {
                const groupedExecutedCOs = []
                state.changeOrdersWBS.forEach(item => {
                    if ((item.visible || undefined === item.visible) && (activeSiteId && activeSiteId === item.site_id || !activeSiteId)) {
                        const index = groupedExecutedCOs.findIndex(qi => qi.change_order_id === item.change_order_id)
                        if (index >= 0) {
                            groupedExecutedCOs[index].quoted_items.push(item)
                        } else {
                            groupedExecutedCOs.push(
                                {
                                    quoted_items: [item],
                                    change_order_number: item.change_order_number,
                                    change_order_id: item.change_order_id,
                                    contract_wbs_id: item.change_order_id,
                                    name: `${ state.contractChangePrefix }: ${ item.change_order_number } - ${ item.change_order_name }`,
                                }
                            )
                        }
                    }
                })

                return groupedExecutedCOs
            }
        },

        hasLinesWithAdjustments (state: InvoiceSovStoreState): boolean {
            function hasAdjustments (item: SoVLineItem): boolean {
                if (this.hasSubTasksItem(item)) {
                    return item.children.some(child => hasAdjustments(child))
                }

                return Boolean(item.adjustments)
            }

            return state.initialContractWBS.some(item => hasAdjustments(item)) || state.initialChangeOrderWBS.some(item => hasAdjustments(item))
        },

        getLineItemsCostCodes (state: InvoiceSovStoreState): CostCode[] {
            return state.nonContractedLineItems.map(item => {
                return {
                    amount: item.total,
                    id: item.cost_code?.id || item.cost_code || ''
                }
            }).filter(item => item.id)
        },

        getSoVCostCodes (state: InvoiceSovStoreState): CostCode[] {
            const costCodes = []
            state.contractWBS.forEach(item => {
                costCodes.push({
                    amount: item.net_current_application || 0,
                    id: item.cost_code_id || ''
                })
            })
            state.changeOrdersWBS.forEach(item => {
                costCodes.push({
                    amount: item.net_current_application || 0,
                    id: item.cost_code_id || ''
                })
            })

            return costCodes.filter(item => item.id)
        },

        amendmentsContractChangeType (state: InvoiceSovStoreState): boolean {
            return ContractChangeStageType.AMENDMENT === state.contractChangeType
        },

        contractChangeRouteCategory (state: InvoiceSovStoreState): boolean {
            return state.amendmentsContractChangeType ? ContractChangesCategoryRoutes.AMENDMENTS : ContractChangesCategoryRoutes.CHANGE_ORDERS
        },

        contractChangePrefix (state: InvoiceSovStoreState): string {
            return state.amendmentsContractChangeType ? i18n.global.t('Amendment') : i18n.global.t('CO')
        },
    },
    actions: {
        clearSoVStore (): void {
            this.changeOrderWithinOriginalScope = []
            this.contractWBS = []
            this.changeOrdersWBS = []
            this.initialContractWBS = []
            this.initialChangeOrderWBS = []
            this.invoiceWBSLoaded = false
            this.tableViewMode = null
            this.columnsViewMode = null
            this.visibleLinesFilter = null
            this.nonContractedLineItems = []
            this.initialNonContractedLineItems = []
            this.wbsSites = []
        },

        /**
         * Gives the ability to the user to show request revision adjustments, associated status and buttons
         */
        areActionsColumnsVisible (requestRevisionV2Enabled: boolean): boolean {

            if (false === requestRevisionV2Enabled) {
                return false
            }

            const invoiceView = invoiceViewStore()

            //Only client and vendor can see request revision actions
            if (!invoiceView.isClient && !invoiceView.isVendor) {
                return false
            }

            //if we are the client and it has been Internally Rejected
            if (InvoiceStatus.InternallyRejected === invoiceView.invoice.status && invoiceView.isClient) {
                return true
            }

            //if revision requested, pending internal approval, approved or rejected by client
            if ([InvoiceStatus.RequestedRevision, InvoiceStatus.RevisionRequested, InvoiceStatus.PendingInternalApproval,
                InvoiceStatus.RejectedByClient, InvoiceStatus.InternallyApproved].includes(invoiceView.invoice.status)) {
                return true
            }

            //When we are the vendor and we are submitting the invoice to the client
            if (invoiceView.isVendor && InvoiceStatus.SubmittedToClient === invoiceView.invoice.status) {
                return true
            }

            return false
        },

        netCurrentApplication (item: SoVLineItem): number {
            const a = this.getSumOfTasksValues(item, 'current_application') + this.getSumOfTasksValues(item, 'materials_stored') + this.getSumOfTasksValues(item, 'retention_released')
            return a - this.getSumOfTasksValues(item, 'current_retention_amount') - this.getSumOfTasksValues(item, 'materials_stored_retention_amount')
        },

        getSumOfTasksValues (item: object, field: string): number {
            if (!item.children || !item.children.length) {
                return item[field] || 0
            } else {
                let sum = 0
                item.children.forEach(task => {
                    sum += task[field] || 0
                })

                return sum
            }
        },

        async fetchInvoiceWBS (projectMongoIdLocal: string, invoiceId: string): Promise<void> {
            this.invoiceWBSLoaded = false
            const invoiceView = invoiceViewStore()
            const { data } = await invoiceEditingClient.getInvoiceSoV(projectMongoIdLocal, invoiceView.invoice.contract_id, invoiceId)

            this.contractWBS = data.contract_wbs || []
            this.initialContractWBS = data.contract_wbs || []
            this.changeOrdersWBS = data.change_order_wbs || []

            if (this.changeOrdersWBS.length) {
                this.contractChangeType = this.changeOrdersWBS[0].contract_change_type || ContractChangeStageType.CHANGE_ORDER
            }
            this.initialChangeOrderWBS = data.change_order_wbs || []
            this.wbsSites = data.sites?.map(item => ({ project_site_id: item.id, title: item.name }))

            this.invoiceWBSLoaded = true
        },

        setTableViewMode (value: SoVTableViewMode): void {
            this.tableViewMode = value
        },

        setColumnsViewMode (value: SoVTableViewMode): void {
            this.columnsViewMode = value
        },

        setVisibleLinesFilter (value: SoVTableViewMode): void {
            this.visibleLinesFilter = value
        },

        hasSubTasksItem (item: SoVLineItem): boolean {
            return Boolean(item.children && item.children.length)
        },

        phasesAddOriginalScopeItem (): SoVLineItem[] {
            //add "Original Scope" lines
            return this.contractWBS.map(qi => {
                return {
                    ...qi,
                    children: this.hasSubTasksItem(qi) ? qi.children.map(phase => {
                        // add Original Scope Line between phase and task when we have wbs2/wbs3
                        return {
                            ...phase,
                            children: [
                                {
                                    ...phase,
                                    originalPhase: { ...phase },
                                    contract_wbs_id: `original-scope-${ phase.contract_wbs_id }`,
                                    name: i18n.global.t('Original Scope'),
                                    children: this.hasSubTasksItem(phase) ? phase.children.map(task => {
                                        return { ...task, originalScopeParent: `original-scope-${ phase.contract_wbs_id }` }
                                    }) : null,
                                    quoted_item_id: qi.contract_wbs_id,
                                    phase_id: phase.contract_wbs_id,
                                }
                            ]
                        }
                    }) : [
                         // add Original Scope Line when we have only wbs1
                        {
                            ...qi,
                            originalPhase: { ...qi },
                            contract_wbs_id: `original-scope-${ qi.contract_wbs_id }`,
                            name: i18n.global.t('Original Scope'),
                            children: null,
                            quoted_item_id: qi.contract_wbs_id,
                            phase_id: null,
                        }
                    ]
                }
            })
        },

        getRealContractWbsId (item: SoVLineItem): string {
            if (item.originalPhase) {
                return this.getRealContractWbsId(item.originalPhase)
            } else {
                return item.contract_wbs_id
            }
        },

        newScopeItemForOriginalScope (item: SoVLineItem): SoVLineItem {
            return {
                ...item,
                children: this.hasSubTasksItem(item) ? item.children.map(phase => {
                    // wbs2/wbs3
                    return {
                        ...phase,
                        children: [
                            {
                                ...phase,
                                originalPhase: { ...phase },
                                contract_wbs_id: `co-in-original-scope-${ phase.contract_wbs_id }-${ item.change_order_id }`,
                                name: `${ this.contractChangePrefix }: ${ item.change_order_number } - ${ phase.change_order_name }`,
                                link: { name: ContractChangeItemRoutesNames.ITEM_SUMMARY, params: this.contractChangeLinkParams(phase) },
                                children: this.hasSubTasksItem(phase) ? phase.children.map(task => {
                                    return { ...task, originalScopeParent: `co-in-original-scope-${ phase.contract_wbs_id }-${ item.change_order_id }` }
                                }) : null,
                                phase_id: phase.contract_wbs_id,
                                quoted_item_id: item.contract_wbs_id,
                                change_order_id: item.change_order_id,
                                change_order_name: item.change_order_name,
                            }
                        ]
                    }
                }) : [
                    // wbs1 only
                    {
                        ...item,
                        originalPhase: { ...item },
                        contract_wbs_id: `co-in-original-scope-${ item.contract_wbs_id }`,
                        name: `${ this.contractChangePrefix }: ${ item.change_order_number } - ${ item.change_order_name }`,
                        link: { name: ContractChangeItemRoutesNames.ITEM_SUMMARY, params: this.contractChangeLinkParams(item) },
                        children: null,
                        phase_id: null,
                        quoted_item_id: item.contract_wbs_id,
                        change_order_id: item.change_order_id,
                        change_order_name: item.change_order_name,
                    }
                ]
            }
        },

        groupCOsWithinOriginalScope (): void {
            const originalScopeQIsWithCOS = JSON.parse(JSON.stringify(this.phasesAddOriginalScopeItem()))

            //group COs with new scope quoted items
            this.changeOrdersWBS.filter(item => !item.contract_change_relates_to_id).forEach(coQuotedItem => {
                originalScopeQIsWithCOS.push(this.newScopeItemForOriginalScope(coQuotedItem))
            })

            //Group COs with original scope quoted item or new scope QI from previous COs
            this.changeOrdersWBS.filter(item => item.contract_change_relates_to_id).forEach(coQuotedItem => {
                const originalScopeQuotedItemIndex = originalScopeQIsWithCOS.findIndex(qi => qi.contract_wbs_id === coQuotedItem.contract_change_relates_to_id)

                if (-1 !== originalScopeQuotedItemIndex) {
                    originalScopeQIsWithCOS[originalScopeQuotedItemIndex].children = this.mapCOWithinOriginalScopeQuotedItem(originalScopeQIsWithCOS[originalScopeQuotedItemIndex], coQuotedItem)
                } else {
                    // if relations are found neither in original scope nor in COs
                    originalScopeQIsWithCOS.push(this.newScopeItemForOriginalScope(coQuotedItem))
                }
            })

            this.changeOrderWithinOriginalScope = originalScopeQIsWithCOS
            if (0 === this.updateStep) {
                this.initialChangeOrderWithinOriginalScope = originalScopeQIsWithCOS
            }
            this.updateStep++
        },

        mapCOWithinOriginalScopeQuotedItem (originalScopeQuotedItem: SoVLineItem, coQuotedItem: SoVLineItem): SoVLineItem[] {
            if (!originalScopeQuotedItem || !coQuotedItem) {
                return null
            }

            if (this.hasSubTasksItem(coQuotedItem)) {
                // has wbs2/wbs3
                const originalScopePhases = {}

                if (this.hasSubTasksItem(originalScopeQuotedItem)) {
                    originalScopeQuotedItem.children.forEach(phase => {
                        originalScopePhases[phase.name] = phase
                    })
                }

                coQuotedItem.children.forEach(coPhase => {

                    const coOriginalScopeId = `co-in-original-scope-${ originalScopePhases[coPhase.name] ? originalScopePhases[coPhase.name].contract_wbs_id : coPhase.contract_wbs_id }-${ coPhase.change_order_id }`

                    const coItem = {
                        ...coPhase,
                        originalPhase: { ...coPhase },
                        name: `${ this.contractChangePrefix }: ${ coQuotedItem.change_order_number } - ${ coPhase.change_order_name }`,
                        link: { name: ContractChangeItemRoutesNames.ITEM_SUMMARY, params: this.contractChangeLinkParams(coPhase) },
                        children: this.hasSubTasksItem(coPhase) ? coPhase.children.map(task => {
                            return {
                                ...task,
                                originalScopeParent: coOriginalScopeId,
                                quoted_item_id: originalScopeQuotedItem.contract_wbs_id,
                                phase_id: originalScopePhases[coPhase.name] ? originalScopePhases[coPhase.name].contract_wbs_id : coPhase.contract_wbs_id,
                            }
                        }) : null,
                        quoted_item_id: originalScopeQuotedItem.contract_wbs_id,
                        change_order_id: coQuotedItem.change_order_id,
                        change_order_name: coQuotedItem.change_order_name,
                        phase_id: originalScopePhases[coPhase.name] ? originalScopePhases[coPhase.name].contract_wbs_id : coPhase.contract_wbs_id,
                        contract_wbs_id: coOriginalScopeId,
                    }

                    if (originalScopePhases[coPhase.name]) {

                        if (!originalScopePhases[coPhase.name].children) {
                            originalScopePhases[coPhase.name].children = []
                        }

                        originalScopePhases[coPhase.name].children.push(coItem)
                    } else {
                        originalScopePhases[coPhase.name] = {
                            ...coPhase,
                            children: [coItem]
                        }
                    }
                })

                return Object.values(originalScopePhases)
            } else {
                // has wbs1 only
                return [
                    ...originalScopeQuotedItem.children,
                    {
                        ...coQuotedItem,
                        originalPhase: { ...coQuotedItem },
                        contract_wbs_id: `co-in-original-scope-${ coQuotedItem.contract_wbs_id }`,
                        name: `${ this.contractChangePrefix }: ${ coQuotedItem.change_order_number } - ${ coQuotedItem.change_order_name }`,
                        link: { name: ContractChangeItemRoutesNames.ITEM_SUMMARY, params: this.contractChangeLinkParams(coQuotedItem) },
                        children: null,
                        quoted_item_id: originalScopeQuotedItem.contract_wbs_id,
                        phase_id: null,
                        change_order_id: coQuotedItem.change_order_id,
                        change_order_name: coQuotedItem.change_order_name,
                    }
                ]
            }
        },

        /**
         * @private
         * Part of mechanism to separate COs from original scope
         */
        addChildToExtractedCoQuotedItem (visibleQuotedItem: SoVLineItem, originalQuotedItem: SoVLineItem, executedCoItems: SoVLineItem[], child: SoVLineItem): void {
            let resultQuotedItem = executedCoItems.find(
                item => originalQuotedItem.id ? item.id === originalQuotedItem.id : item.contract_wbs_id === originalQuotedItem.contract_wbs_id,
            )
            if (undefined === resultQuotedItem) {
                resultQuotedItem = {
                    ...visibleQuotedItem,
                    change_order_id: originalQuotedItem.change_order_id,
                    contract_wbs_id: originalQuotedItem.contract_wbs_id,
                    id: originalQuotedItem.id,
                    change_order_number: originalQuotedItem.change_order_number,
                    contract_change_relates_to_id: originalQuotedItem.contract_change_relates_to_id,
                    scheduled_value: originalQuotedItem.scheduled_value,
                    children: [],
                }
                executedCoItems.push(resultQuotedItem)
            }
            resultQuotedItem.children.push(child)
        },

        getItemsForContractWbsId (contractWbsId: string): SoVLineItem[] {
            const innerFindItem  = function _findItem (items: SoVLineItem[]): SoVLineItem[] {
                const result = []
                for (const item of items) {
                    if (item.contract_wbs_id === contractWbsId) {
                        result.push(item)
                        continue
                    }
                    if (item.children && item.children.length > 0) {
                        const found = _findItem(item.children)
                        if (found.length) {
                            result.push(...found)
                        }
                    }
                }
                return result
            }
            return [
                ...innerFindItem(this.contractWBS),
                ...innerFindItem(this.changeOrdersWBS),
                ...innerFindItem(this.changeOrderWithinOriginalScope)
            ]
        },

        getLeafItems (items: SoVLineItem[]): SoVLineItem[] {
            const result = []
            for (const item of items) {
                if (item.children && item.children.length > 0) {
                    result.push(...this.getLeafItems(item.children))
                } else {
                    result.push(item)
                }
            }
            return result
        },

        extractCOsFromOriginalScope (): SoVLineItem[] {
            const executedCOsItems = []
            this.changeOrdersWBS.forEach(qi => {
                const quotedItem = this.changeOrderWithinOriginalScope.find(item =>
                    item.change_order_id &&
                    (item.contract_wbs_id === qi.contract_wbs_id || item.contract_wbs_id === qi.contract_change_relates_to_id)
                )

                // new scope item
                if (quotedItem) {
                    quotedItem.children.forEach(phase => {
                        // wbs1 only
                        if (!this.hasSubTasksItem(phase)) {
                            if (phase.change_order_id === qi.change_order_id) {
                                executedCOsItems.push({
                                    ...phase,
                                    contract_wbs_id: qi.contract_wbs_id,
                                    change_order_id: qi.change_order_id,
                                    change_order_name: qi.change_order_name,
                                    change_order_number: qi.change_order_number,
                                    contract_change_relates_to_id: qi.contract_change_relates_to_id,
                                    name: qi.name,
                                    link: null,
                                    quoted_item_id: null,
                                })
                            }
                        } else {
                            // wbs 2, wbs 3
                            let co = null
                            phase.children?.forEach(item => {
                                if (item.change_order_id === qi.change_order_id) {
                                    co = item
                                }
                            })

                            if (co) {
                                this.addChildToExtractedCoQuotedItem(quotedItem, qi, executedCOsItems, {
                                    ...co,
                                    quoted_item_id: co?.originalPhase?.quoted_item_id ?? null,
                                    phase_id: null,
                                    link: null,
                                    contract_wbs_id: co?.originalPhase?.contract_wbs_id ?? null,
                                    change_order_id: co?.originalPhase?.change_order_id ?? null,
                                    change_order_name: co?.originalPhase?.change_order_name ?? null,
                                    change_order_number: co?.originalPhase?.change_order_number ?? null,
                                    contract_change_relates_to_id: co?.originalPhase?.contract_change_relates_to_id ?? null,
                                    name: co?.originalPhase?.name ?? null,
                                })
                            }
                        }
                    })
                } else {
                    // item in original scope
                    const originalScopeQI = this.changeOrderWithinOriginalScope.find(item =>
                        item.contract_wbs_id === qi.contract_change_relates_to_id
                    )

                    if (originalScopeQI) {
                        if (this.hasSubTasksItem(qi)) {
                            executedCOsItems.push({
                                ...originalScopeQI,
                                id: qi.id,
                                children: this.hasSubTasksItem(qi) ? qi.children.map(phase => {
                                    const phaseInOriginalScope = originalScopeQI.children?.find(item => item.name === phase.name)
                                    const phaseCO = phaseInOriginalScope?.children?.find(item =>
                                        `co-in-original-scope-${ phaseInOriginalScope?.contract_wbs_id }-${ qi.change_order_id }` === item.contract_wbs_id
                                    )

                                    return {
                                        ...phaseCO,
                                        contract_wbs_id: phase.contract_wbs_id,
                                        name: phase.name,
                                        quoted_item_id: phase.quoted_item_id,
                                        phase_id: null,
                                        link: null,
                                        children: this.hasSubTasksItem(phaseCO) ? phaseCO?.children?.map(task => ({
                                            ...task,
                                            quoted_item_id: phase.quoted_item_id
                                        })) : null,
                                    }
                                }) : null,
                                contract_wbs_id: qi.contract_wbs_id,
                                change_order_id: qi.change_order_id,
                                change_order_name: qi.change_order_name,
                                change_order_number: qi.change_order_number,
                                contract_change_relates_to_id: qi.contract_change_relates_to_id,
                                name: qi.name,
                            })
                        } else {
                            // wbs 1 only
                            const coItem = originalScopeQI?.children?.find(coQI =>
                                `co-in-original-scope-${ qi.contract_wbs_id }` === coQI.contract_wbs_id
                            )

                            if (coItem) {
                                executedCOsItems.push({
                                    ...coItem,
                                    id: coItem?.originalPhase?.id ?? null,
                                    contract_wbs_id: coItem?.originalPhase?.contract_wbs_id ?? null,
                                    change_order_id: coItem?.originalPhase?.change_order_id ?? null,
                                    change_order_name: coItem?.originalPhase?.change_order_name ?? null,
                                    change_order_number: coItem?.originalPhase?.change_order_number ?? null,
                                    contract_change_relates_to_id: coItem?.originalPhase?.contract_change_relates_to_id ?? null,
                                    name: coItem?.originalPhase?.name ?? null,
                                    link: null,
                                })
                            }
                        }
                    }
                }
            })

            return executedCOsItems
        },

        extractOriginalScopeLine (item: SoVLineItem): SoVLineItem {
            const lineItem = item.children.find(line => `original-scope-${ item.contract_wbs_id }` === line.contract_wbs_id)

            if (lineItem) {
                return {
                    ...lineItem,
                    contract_wbs_id: lineItem.originalPhase.contract_wbs_id,
                    name: lineItem.originalPhase.name,
                    children: lineItem.children,
                    phase_id: lineItem.originalPhase.phase_id,
                    quoted_item_id: lineItem.originalPhase.quoted_item_id,
                }
            }
        },

        extractOriginalScopeQIs (): SoVLineItem[] {
            // get line items without nested Original Scope lines
            return this.changeOrderWithinOriginalScope.filter((qi => !Boolean(qi.change_order_id))).map(qi => {
                const singleWBS1 = this.extractOriginalScopeLine(qi)
                if (singleWBS1) {
                    return singleWBS1
                } else {
                    return {
                        ...qi,
                        children: this.hasSubTasksItem(qi) ? qi.children.filter(phase => !phase.change_order_id).map(phase => {
                            return this.extractOriginalScopeLine(phase)
                        }) : null
                    }
                }
            })
        },

        separateCOsFromOriginalScope (): void {
            this.changeOrdersWBS = this.extractCOsFromOriginalScope()
            this.contractWBS = this.extractOriginalScopeQIs()
        },

        contractChangeLinkParams (contractChange: SoVLineItem): object {
            return { id: contractChange.change_order_id, contractChangeType: this.contractChangeRouteCategory, category: this.contractChangeCategory(contractChange) }
        },

        contractChangeCategory (contractChange: SoVLineItem): string {
            const invoiceView = invoiceViewStore()
            return invoiceView.isMyInvoice ? `my-${ this.contractChangeRouteCategory }` : `received-${ this.contractChangeRouteCategory }`
        },

        setSovRecalculating (sovRecalculating: boolean): void {
            this.invoiceWBSRecalculating = sovRecalculating
        }
    }
})
