import { defineComponent } from 'vue'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import { AnnotationData } from '@/io-modules/documents/interfaces/AnnotationData'
import { mapState } from 'pinia'
import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import commentsClient from '@/io-modules/documents/api-clients/commentsClient'
import { AnnotationStatus } from '@/io-modules/documents/enums/AnnotationStatus'
import Reply from './reply/Reply.vue'
import { ReplyInterface } from '@/io-modules/documents/interfaces/ReplyInterface'
import MentionMixin from '@/io-modules/documents/mixins/MentionMixin'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'SingleComment',
    components: {
        DropdownButtonNew,
        Reply
    },
    props: {
        data: {
            type: Object as () => AnnotationData,
            required: true
        },
        isLastComment: {
            type: Boolean
        },
    },
    mixins: [MentionMixin],
    data () {
        return {
            editedComment: this.data.comment.comment_text,
            editMode: false,
            editedSharedWith: [],
            isAddingReply: false,
            replyText: null,
            replySharedWith: [],
            allRepliesVisible: false
        }
    },
    computed: {
        ...mapState(viewDocumentStore, [
            'selectedAnnotationId',
            'uniqueMentionUsers'
        ]),
        isCommentSelected (): boolean {
            return this.selectedAnnotationId === this.data.uuid
        },
        isCommentArchived (): boolean {
            return this.data.status === AnnotationStatus.ARCHIVED
        },
        repliesVisibilityText (): TranslateResult {
            return this.allRepliesVisible ? this.$t('Hide replies') : this.$t('Show all replies')
        },
        replies (): ReplyInterface[] {
            return this.allRepliesVisible ? this.data.comment.replies : [this.data.comment.replies.at(-1)]
        },
    },
    methods: {
        onDeleteComment (id: string, isReply?: boolean): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to delete this comment?'),
                caption: this.$t('This action cannot be undone.'),
                icon: 'icon-trash',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Confirm'),
                        class: 'io-btn-primary',
                        action: async () => this.deleteComment(id, isReply)
                    }
                ]
            })
        },
        async deleteComment (id: string, isReply?: boolean): Promise<void> {
            this.setLoadingBar(true)

            try {
                isReply
                    ? await commentsClient.deleteReply(this.data.document_id, id)
                    : await commentsClient.deleteComment(this.data.document_id, id)
                this.updateAnnotations()
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        selectComment (): void {
            this.$emit('select-annotation', this.data.uuid)
        },
        cancelEditing (): void {
            this.editMode = false
            this.editedComment = this.data.comment.comment_text
        },
        async saveComment (reply?: { comment_text: string, id: string, shared_with: string[] }): Promise<void> {
            this.setLoadingBar(true)

            const postData = {
                comment_text: reply?.comment_text ?? this.editedComment,
                shared_with: reply?.shared_with ?? this.editedSharedWith
            }

            try {
                await commentsClient.editComment(this.data.document_id, reply?.id ?? this.data.comment.id, postData)
                this.updateAnnotations()
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
                this.editMode = false
            }
        },
        async resolveComment (): Promise<void> {
            this.setLoadingBar(true)

            const postData = {
                is_resolved: !this.isCommentArchived
            }

            try {
                await commentsClient.resolveComment(this.data.document_id, this.data.id, postData)
                this.updateAnnotations()
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        updateAnnotations (): void {
            this.$emit('update-annotations')
        },
        turnOnReplyMode (): void {
            if (!this.isCommentArchived) {
                this.isAddingReply = true
            }
        },
        turnOffReplyMode (): void {
            this.isAddingReply = false
            this.replyText = null
        },
        async addReply (): Promise<void> {
            this.setLoadingBar(true)

            const postData = {
                comment_text: this.replyText,
                shared_with: this.replySharedWith
            }

            try {
                await commentsClient.replyToComment(this.data.document_id, this.data.comment.id, postData)
                this.updateAnnotations()
                this.turnOffReplyMode()
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        toggleRepliesVisibility (): void {
            this.allRepliesVisible = !this.allRepliesVisible
        },
        turnOnEditMode (): void {
            this.editMode = true
        }
    }
})
