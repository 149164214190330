enum IOBannerType {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
    Closed = 'closed',
    Neutral = 'neutral',
}

export default IOBannerType
