import { defineComponent, inject, ComputedRef } from 'vue'
import { mapActions, mapGetters } from 'vuex'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'

import useLoader from '@/composables/useLoader.ts'
import useSets from '@/io-modules/drawings/composables/useSets.ts'
import sheetsClient from '@/io-modules/drawings/api-clients/sheetsClient.ts'
import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'

import ToggleSwitch from '@/components/atoms/ToggleSwitch/ToggleSwitch.vue'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import ActionBar from '@/components/action-bar/ActionBar.vue'
import InviteList from '@/layouts/offcanvas/invite-list.vue'
import ModalFilters from '@/components/ModalFilters/ModalFilters.vue'
import SheetsList from '@/io-modules/drawings/components/sheets-list/SheetsList.vue'
import SheetsGrid from '@/io-modules/drawings/components/sheets-grid/SheetsGrid.vue'
import ShareWith from '@/io-modules/drawings/components/share-with/ShareWith.vue'
import RenameSet from '@/io-modules/drawings/components/rename-set/RenameSet.vue'
import SetsCreate from '@/io-modules/drawings/components/sets-create/SetsCreate.vue'
import SetHistoryLogsIndex from '@/io-modules/drawings/components/set-history-logs-index/SetHistoryLogsIndex.vue'
import BulkEditModal from '@/io-modules/drawings/components/bulk-edit-modal/BulkEditModal.vue'
import TimelineModal from '@/components/timeline-modal/TimelineModal.vue'

import { SetType } from '@/io-modules/drawings/enums/SetType.ts'
import { SetStatus } from '@/io-modules/drawings/enums/SetStatus.ts'
import { SheetsView } from '@/io-modules/drawings/enums/SheetsView.ts'
import { OptionsType } from '@/components/atoms/ToggleSwitch/ToggleSwitch.ts'
import { Set } from '@/io-modules/drawings/interfaces/Set.ts'
import { Breadcrumb } from '@/store/app/breadcrumbs.ts'

export default defineComponent({
    name: 'SheetsIndex',
    components: {
        SetHistoryLogsIndex,
        DropdownButtonNew,
        ToggleSwitch,
        SheetsList,
        SheetsGrid,
        ModalFilters,
        RenameSet,
        InviteList,
        ShareWith,
        ActionBar,
        BulkEditModal,
        SearchInput,
        SetsCreate,
        TimelineModal,
    },
    props: {
        pid: {
            type: String,
            required: true,
        },
        setId: {
            type: String,
            required: true,
        },
        showSharedWith: {
            type: Boolean,
            default: true,
        },
        showStat: Boolean,
        backToSetEvent: Boolean,
    },
    mixins: [breadcrumbsMixin],
    setup () {
        const projectId = inject<ComputedRef<string>>('projectId')
        const projectName = inject<ComputedRef<string>>('projectName')
        const projectFormattedId = inject<ComputedRef<string>>('projectFormattedId')

        const { load: loadDrawingSet } = useLoader()
        const {
            activeSets,
            getCurrentSets, getDraftSets,
        } = useSets(projectId.value)

        return {
            projectId, projectName, projectFormattedId,

            loadDrawingSet,
            SheetsView, SetType, SetStatus,

            activeSets,
            getCurrentSets, getDraftSets,
        }
    },
    data () {
        return {
            drawingSet: null as Set,
            showBulkEditModal: false,
            historyModalShown: false,
            timelineModalShown: false,
            selectedSheetsIds: [] as string[],
            addingNewSetModalShown: false,
            currentSheetsView: SheetsView.LIST as SheetsView,
            renameModalShown: false,
        }
    },
    computed: {
        ...mapGetters('project', {
            projectObj: 'projectObj',
            projectName: 'projectName',
        }),
        ...mapGetters('drawingsV2', {
            activeDrawingSet: 'getActiveDrawingSet',
            drawingSetsShown: 'getDrawingSetsShown',
            drawingSheetsShown: 'getDrawingSheetsShown',
            drawingSheetShown: 'getDrawingSheetShown',
        }),
        ...mapGetters('lazyList', {
            selectedRows: 'getSelectedRows',
            records: 'getRecords',
        }),
        ...mapGetters('appStore', {
            authData: 'getAuthData',
        }),
        isCreatedByCurrentUser (): boolean {
            return this.drawingSet?.created_by_full_name.id === this.authData.u_mongo
        },
        toggleSwitchOptions (): OptionsType {
            return {
                items: {
                    style: 'text',
                    delay: 0.4,
                    preSelected: SheetsView.LIST,
                    disabled: false,
                    labels: [
                        {
                            name: SheetsView.LIST,
                            text: this.$t('List'),
                            icon: 'icon-list-unordered',
                        },
                        {
                            name: SheetsView.GRID,
                            text: this.$t('Grid'),
                            icon: 'icon-elements',
                        },
                    ],
                },
            }
        },
        pageHeaderShown (): boolean {
            return !this.drawingSetsShown && !this.drawingSheetsShown && !this.drawingSheetShown
        },
        showActionBar (): boolean {
            return this.currentSheetsView === SheetsView.LIST && this.selectedRows.length
        },
        headerStatusPill (): { text: string, class: string } {
            switch (this.drawingSet?.type_with_archive) {
            case 'DRAFT':
                return {
                    text: this.$t('project_drawings.status.draft'),
                    class: 'io-grey',
                }
            case 'ARCHIVED':
                return {
                    text: this.$t('project_drawings.status.archived'),
                    class: 'io-grey',
                }
            case 'ACTIVE':
                return {
                    text: this.$t('project_drawings.status.active'),
                    class: 'io-blue',
                }
            case 'PUBLISHED':
                return {
                    text: this.$t('project_drawings.status.active'),
                    class: 'io-green',
                }
            }
        },
        projectLocalId (): string {
            return this.projectObj.project_local_id
        },
        drawingCategories (): { label: string, value: string }[] {
            const categories = []

            if (this.records[0]) {
                for (const [key, value] of Object.entries(this.records[0].enum.page_category.values)) {
                    categories.push({
                        label: this.$t(value as string),
                        value: key,
                    })
                }
            }

            return categories
        },
        breadcrumbs (): Breadcrumb[] {
            return [
                {
                    text: this.$t('Projects'),
                    to: { name: 'project-list' },
                },
                {
                    text: this.projectName,
                    projId: this.projectFormattedId,
                    to: { name: 'project-dashboard' },
                },
                {
                    text: this.$t('Drawings'),
                    to: { name: 'drawings-sets-index' },
                },
                {
                    text: this.drawingSet?.name || '',
                },
            ]
        },
        timelineRequestUrl (): string {
            return `/ca/drawing/set/${ this.setId }/timeline`
        },
    },
    created () {
        this.getDrawingSet()
        this.getCurrentSets()
        this.getDraftSets()

        this.setBreadcrumbs(this.breadcrumbs)
    },
    methods: {
        ...mapActions('lazyList', {
            setSelectedRows: 'setSelectedRows',
            getListData: 'getListData',
            setSearch: 'setSearch',
        }),
        getDrawingSet (): Promise<void> {
            return this.loadDrawingSet(async (): Promise<void> => {
                const { data } = await setsClient.getDrawingSet(this.activeDrawingSet ?? this.setId)
                this.drawingSet = data
                this.setBreadcrumbs(this.breadcrumbs)
            })
        },
        toggleIsConstructionSet (value: boolean): void {
            const title = value
                ? this.$t('Are you sure you want to mark this as Current Set?')
                : this.$t('Are you sure you want to Deactivate Current Set?')

            const caption = value
                ? this.$t('This set will start to be visible to the entire project.')
                : this.$t('This set will stop being visible to everybody in the project. Instead, only the Uploader will be able to see it. The Uploader will be able to share it with additional individuals if needed.')

            const buttonText = value
                ? this.$t('Yes, Mark as Current Set')
                : this.$t('Yes, Deactivate Current Set')

            const buttons = [
                {
                    text: this.$t('Cancel'),
                    class: 'io-btn-light',
                    action: null,
                },
                {
                    text: buttonText,
                    class: 'io-btn-primary',
                    action: async () => {
                        await this.setConstructionSetType(value ? SetType.CONSTRUCTION : SetType.DRAFT)
                    },
                },
            ]

            this.showPopupAlert({
                title,
                caption,
                buttons,
            })
        },
        async setConstructionSetType (value: SetType): Promise<void> {
            return this.loadDrawingSet(async (): Promise<void> => {
                const { data } = await setsClient.changeDrawingSetType(this.setId, value)
                this.drawingSet = data
            })
        },
        onClearAllFilters (): void {
            this.$store.commit('drawingsV2/RESET_FILTERS_SELECTED')
        },
        onCloseRenameSetPopup (): void {
            this.renameModalShown = false
        },
        async onConfirmRenameSetPopup (data: { id: string, name: string }): Promise<void> {
            return this.loadDrawingSet(async (): Promise<void> => {
                await setsClient.renameSet(data.id, data.name)

                this.drawingSet.name = data.name
                this.onCloseRenameSetPopup()
            })
        },
        async updateSheetsCategory (category: { label: string, value: string }): Promise<void> {
            this.setLoadingBar(true)

            try {
                await sheetsClient.updateManySheetsCategory(this.selectedRows, category.value)
                await this.getListData(true)
                await this.setSelectedRows([])
                this.showBulkEditModal = false
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        onDownload (ids: string[]): void {
            this.selectedSheetsIds = ids
            this.showDownloadConfirmation()
        },
        async downloadSelectedSheets (): Promise<void> {
            this.setLoadingBar(true)

            try {
                const queryString = this.selectedSheetsIds.map((id: string) => `ids[]=${ id }`).join('&')
                const url = `/ca/drawing/download?${ queryString }`
                window.open(this.$api.getApi2RequestUrl(url), '_blank', 'noopener')
                await this.setSelectedRows([])
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        downloadSet (): void {
            const url = this.$api.getApi2RequestUrl(`/ca/drawing/set/download/${ this.drawingSet?.id }`)
            window.open(url, '_blank', 'noopener')
        },
        showDownloadConfirmation (): void {
            this.showPopupAlert({
                width: 480,
                title: this.$t('Do you want to download?'),
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                    },
                    {
                        text: this.$t('Yes, Download'),
                        class: 'io-btn-primary',
                        action: () => this.downloadSelectedSheets(),
                    },
                ],
            })
        },
    },
})
