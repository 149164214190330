<template>
    <div
        ref="wrapper"
        class="io-button-dropdown"
        data-testid="dropdown-multilayer"
        :class="{ [buttonColor]: true }"
    >
        <IOButton
            aria-haspopup="true"
            class="io-more-button"
            :variant="ButtonVariant.SECONDARY"
            :icon-position="ButtonIconPosition.RIGHT"
            :size="ButtonSize.SMALL"
            v-bind="buttonAttributes"
            :aria-controls="id"
            :icon="buttonIcon"
            data-testid="button-dropdown-multilayer"
            @click="toggleMoreMenu"
        >
            {{ buttonLabel }}
        </IOButton>
        <TieredMenu
            :id="id"
            ref="menu"
            class="io-button-dropdown--list"
            :class="{ left }"
            :model="items"
            :popup="true"
            @show="setShow(true)"
            @hide="setShow(false)"
        />
    </div>
</template>

<script lang="ts" src="./DropdownButtonMultilayer.ts"/>
<style lang="scss" src="./DropdownButtonMultilayerGlobal.scss"/>
