import { defineComponent } from 'vue'
import { mapActions, mapState } from 'vuex'
import AddProviderModal from '@/io-modules/global-funding/components/modals/add-new-provider/AddProviderModal.vue'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import LazyList from '@/components/lazy-list/LazyList.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import { mapActions as mapPiniaActions } from 'pinia'
import { globalFundingStore } from '@/io-modules/global-funding/stores/globalFundingStore'

export default defineComponent({
    name: 'ProvidersList',
    components: {
        LazyList,
        AddProviderModal,
        SearchInput,
        ActionDropdown,
    },
    data () {
        return {
            endpoint: '/funds-providers',
            itemRoute: {
                name: 'funding-sources-provider-details',
                params: {
                    id: 'id',
                    from_list: true,
                },
            },
            searchLocal: '',
            showProviderModal: false,
        }
    },
    computed: {
        ...mapState('lazyList', {
            fundProviders: 'dataList',
        }),
        columns (): Array<ColumnInterface> {
            let tableColumns: Array<ColumnInterface> = [
                {
                    field: 'provider',
                    label: this.$t('Provider'),
                    type: 'row',
                    additional: {
                        img: 'logo',
                        header: 'name',
                        avatarType: 'company',
                    }
                },
                {
                    field: 'summary',
                    label: `# ${ this.$t('of Funds') }`,
                    columnClass: 'acenter',
                    type: 'text',
                    additional: {
                        subfield: 'number_of_funds',
                        sortByCustomField: 'number_of_funds'
                    },
                },
                {
                    field: 'summary',
                    label: this.$t('Total Amount of Funds'),
                    columnClass: 'aright',
                    type: 'text',
                    filter: 'formatCurrency',
                    additional: {
                        subfield: 'total_funds_amount',
                        sortByCustomField: 'total_funds_amount'
                    },
                },
                {
                    field: 'summary',
                    label: this.$t('Amount Allocated to Projects'),
                    columnClass: 'aright',
                    type: 'text',
                    filter: 'formatCurrency',
                    additional: {
                        subfield: 'total_projects_allocated_amount',
                        sortByCustomField: 'total_projects_allocated_amount'
                    },
                },
                {
                    field: 'summary',
                    label: this.$t('Amount Unallocated to Projects'),
                    columnClass: 'aright',
                    type: 'text',
                    filter: 'formatCurrency',
                    additional: {
                        subfield: 'total_projects_unallocated_amount',
                        sortByCustomField: 'total_projects_unallocated_amount'
                    },
                },
                {
                    field: 'summary',
                    label: this.$t('Paid to Date'),
                    columnClass: 'aright',
                    type: 'text',
                    filter: 'formatCurrency',
                    additional: {
                        subfield: 'total_paid_to_date_amount',
                        sortByCustomField: 'total_paid_to_date_amount'
                    },
                },
            ]

            return tableColumns
        },
    },
    watch: {
        searchLocal (newVal: string): void {
            this.setSearch(newVal)
        },
        fundProviders: {
            handler (): void {
                this.setProvidersList(this.fundProviders)
            },
            deep: true
        }
    },
    methods: {
        ...mapActions('lazyList', ['setSearch']),
        ...mapPiniaActions(globalFundingStore, ['setProvidersList']),
        async exportPdf (): Promise<void> {
            try {
                await window.open(
                    `${ this.$api.getApi2RequestUrl('/funds-providers/pdf/') }`,
                    '_blank',
                    'noopener',
                )
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        toggleShowProviderModal (): void {
            this.showProviderModal = !this.showProviderModal
        },
    },
})
