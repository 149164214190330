<template>
    <div class="io-horizontal-bar-chart">
        <div 
            v-for="(item, index) in items" 
            :key="index" 
            class="io-horizontal-bar-chart__item"
            :data-testid="`bar-chart-item-${index + 1}`"
            :class="[!item.value ? 'io-horizontal-bar-chart__item--hidden' : '', getItemColor(item)]"
            :style="{ width: getItemWidth(item) }" 
        ></div>
    </div>
</template>

<script lang="ts" src="./HorizontalBarChart.ts"></script>
<style lang="scss" src="./HorizontalBarChart.scss" scoped></style>