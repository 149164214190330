import isLoggedMixin from '@/mixins/isLoggedMixin.js'
import SingleFile from '../single-file/single-file.vue'
import SingleFolder from '../single-folder/single-folder.vue'
import TableSortIcon from '@/components/table-sort-icon/TableSortIcon.vue'
import { cloneDeep, get, isEmpty, orderBy, uniqueId } from 'lodash'
import { defineComponent } from 'vue'
import type SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument'
import { mapGetters } from 'vuex'
import Placeholder from '@/components/placeholder/Placeholder'
import Uploader from '@/modules/documents/main/parts/files-list/parts/uploader/uploader.vue'
import { TranslateResult } from 'vue-i18n'

type orderType = {
    orderBy: string,
    orderDir: 'desc' | 'asc'
}

export default defineComponent({
    name: 'tabFiles',
    components: {
        Placeholder,
        Uploader,
        SingleFile,
        SingleFolder,
        TableSortIcon
    },
    props: {
        validationOptions: {
            type: Object,
            required: false,
            default: () => ({
                allowedExtensions: [],
                acceptFiles: []
            }),
        },
        projectLocalId: {
            type: String,
            required: false,
            default: null
        },
        uploaderEnabled: {
            type: Boolean,
            required: false,
            default: false
        },
        inDocumentsUpload: {
            type: Boolean,
            required: false,
            default: false
        },
        isSharedFolder: {
            type: Boolean,
            required: false,
            default: false
        },
        toSync: {
            type: Boolean,
            required: false,
            default: false
        },
        fromTrash: {
            type: Boolean,
            required: false,
            default: false
        },
        arrayId: {
            type: String,
            required: true
        },
        searchKeyword: {
            type: String,
            required: false,
            default: ''
        },
        tabType: {
            type: String,
            required: true
        },
        modelId: {
            type: String,
            required: false
        },
        filesSection: {
            type: Array,
            required: false
        },
        modelType: {
            type: String,
            required: false
        },
        isProjectView: {
            type: Boolean,
            required: false,
            default: false
        },
        isDocumentsView: {
            type: Boolean,
            required: false,
            default: false
        },
        subSection: {
            type: Array,
            required: false
        },
        isSharingFeatureActive: {
            type: Boolean,
            require: false,
            default: true
        },
        allowToSelectFiles: {
            type: Boolean,
            require: false,
            default: false
        },
        order: {
            type: Object as () => orderType,
            default: () => ({
                orderBy: 'file_name',
                orderDir: 'asc',
            }),
        },
        isLocalOrder: {
            type: Boolean,
            require: false,
            default: false
        },
        allowedExtensions: {
            default: () => [],
            type: Array<string>
        },
        delegateNavigation: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [isLoggedMixin],
    data () {
        return {
            filteredFiles: [],
            breadCrumbFolderKey: 'breadcrumb_folder',
            allSelected: false,
            showShared: null,
            hideShareColumn: null,
            addToTemporary: false,
            lockHotkeys: null,
            forceShared: null,
            orderLocal: {
                orderBy: 'file_name',
                orderDir: 'asc'
            } as orderType
        }
    },
    computed: {
        ...mapGetters('filesComponent', {
            isLoading: 'getLoading',
            folderFlags: 'getFolderFlags',
            selectedFiles: 'getSelectedFiles',
            foldersBreadcrumbs: 'getFoldersBreadcrumbs',
            isSearching: 'getSearchState',
        }),
        authData: function (): Object {
            let data = this.getAuthData
            if (data) {
                this.loaded = true
            }
            return data
        },
        hasFoldersOrDocuments (): boolean {
            return !isEmpty(this.unfilteredFolders) || !isEmpty(this.my_documents_files)
        },
        my_documents_files (): Array<SingleModelDocument> {
            let files = []
            if (this.toSync) {
                files = this.$store.getters['filesComponent/getSharedFiles'](this.arrayId)
            } else if (this.fromTrash) {
                files = this.$store.getters['filesComponent/getTrashedFiles'](this.arrayId)
            } else {
                files = this.$store.getters['filesComponent/getMyFiles'](this.arrayId)
            }

            if (this.allowToSelectFiles) {
                return files
            }

            if (this.filesSection && !this.subSection) {
                return files.filter(item => get(item, 'section', null) === this.filesSection)
            } else if (this.filesSection && this.subSection) {
                return files.filter(item => get(item, 'section', null) === this.filesSection && get(item, 'section_uuid', null) === this.subSection)
            } else if (!this.isDocumentsView) {
                return files.filter(item => get(item, 'section', 'default') === 'default')
            }

            return files
        },
        showFiles (): number {
            return this.folderFlags?.files_in_folder_allowed || this.filteredRequests?.length
        },
        isFolderView (): boolean {
            return !isEmpty(this.folderFlags) ? !this.folderFlags.files_in_folder_allowed : false
        },
        filteredRequests (): Array<SingleModelDocument> {
            if (!this.my_documents_files && !this.my_documents_files.length) {
                return []
            }
            let searchArrTemp = this.my_documents_files.filter(request => {
                if (!request) {
                    return false
                }
                return request.file_name.toLowerCase().includes(this.searchKeyword.toLowerCase())
            })
            if (this.isLocalOrder) {
                return orderBy(searchArrTemp, this.computedOrder.orderBy, this.computedOrder.orderDir)
            }
            return searchArrTemp
        },
        computedOrder (): Object {
            if (this.isLocalOrder) {
                return this.orderLocal
            }
            return this.order
        },
        unfilteredFolders (): Array<any> {
            return this.$store.getters['filesComponent/getFolders'](this.arrayId)
        },
        folders (): Array<any> {
            const tempFolders = this.$store.getters['filesComponent/getFolders'](this.arrayId).filter(folder => folder.name.toLowerCase().includes(this.searchKeyword.toLowerCase()))
            if (this.isLocalOrder) {
                return orderBy(tempFolders, this.computedOrder.orderBy.replace('file_', ''), this.computedOrder.orderDir)
            }
            return tempFolders
        },
        hasFiles (): boolean {
            return !!this.filteredRequests?.length
        },
        hasFilesToSync (): boolean {
            return !!(this.filteredRequests.find((file) => file.can_be_shared))
        },
        lockBreadcrumbs (): boolean {
            return this.folderFlags && (this.folderFlags.folder_type === 'root' || (this.folderFlags.model_type === 'main_project_folder' && this.$route.name !== 'documents-list'))
        },

        breadcrumbEnabled (): boolean {
            if (this.foldersBreadcrumbs?.length && !isEmpty(this.folderFlags) && this.folderFlags._id && !this.lockBreadcrumbs) {
                return this.foldersBreadcrumbs[this.foldersBreadcrumbs.length - 1].folder_id && this.foldersBreadcrumbs[this.foldersBreadcrumbs.length - 1]._id !== this.folderFlags._id
            }

            return false
        },
    },
    watch: {
        files (): void {
            this.onDataRefresh()
        },
        'foldersBreadcrumbs' (): void {
            this.breadCrumbFolderKey = uniqueId()
        }
    },
    created () {
        this.$nextTick(() => {
            this.$echo.private('WorkspaceChannel.' + this.authData.company_mongo_id)
                .listen('Document.Events.ModelShareDocumentEvent', this.syncStatusUpdate)
        })
    },
    unmounted () {
        this.$echo.private('WorkspaceChannel.' + this.authData.company_mongo_id)
            .stopListening('Document.Events.ModelShareDocumentEvent')
    },
    methods: {
        reloadUploader (): void {
            this.uploaderKey = uniqueId()
        },
        handleSelectAll (): void {

        },
        changeSort (orderBy: string): void {
            let orderDir = this.computedOrder.orderDir


            if (this.computedOrder.orderBy === orderBy) {
                orderDir = this.computedOrder.orderDir === 'asc' ? 'desc' : 'asc'
            }

            if (this.computedOrder.orderBy === '') {
                orderDir = 'asc'
            }

            if (this.isLocalOrder) {
                this.orderLocal = {
                    orderBy,
                    orderDir
                }
                return
            }

            this.$emit('order', {
                orderBy,
                orderDir
            })
        },
        onDataRefresh (): void {
            this.$forceUpdate()
            this.$emit('forceRefresh')
        },
        onTempRemove (fileId: string): void {
            this.$forceUpdate()
            this.$emit('tempFileRemove', fileId)
        },
        clearSearch (): void {
            // prevent content flashing
            setTimeout(() => {
                this.$emit('clearSearch')
            }, 500)
        },
        async handleSyncAll (): Promise<void> {
            this.showPopupAlert({
                title: 'Sync All files',
                caption: 'Do you want to sync all files?',
                icon: 'far fa-exclamation-triangle',
                buttons: [
                    {
                        text: 'No',
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: 'Yes',
                        class: 'io-btn-primary',
                        action: async () => {
                            await this.syncAll()
                        }
                    }
                ]
            })
        },
        async syncAll (): Promise<void> {
            const filesToSync = []
            this.filteredRequests.forEach((file) => {
                if (file.can_be_shared) {
                    filesToSync.push(file._id)
                }
            })
            await this.syncFile(filesToSync)
        },
        async syncFile (filesToSync: any): Promise<void> {
            if (!this.modelType) {
                return
            }
            let modelIdTemp = this.modelId || this.$route.params.id
            try {
                await this.$api.post(`/copy-shared-document-all/${ this.modelType }/${ modelIdTemp }`, { params: { files: filesToSync } })
                this.filteredRequests.forEach((file) => {
                    file._is_on_sync_queue = true
                })
                this.showNotification('success', this.$t('Processing file'))
                this.onDataRefresh()
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            }
        },
        syncStatusUpdate (data: any): void {
            const syncedFile = this.filteredRequests.find(file => {
                return file._id === data.fileId
            })
            if (syncedFile !== undefined) {
                if (data.status === 'success') {
                    // move file to My Files Tab
                    let toMyFiles = cloneDeep(syncedFile)
                    toMyFiles._shared_to_my_document_id = false
                    toMyFiles._id = data.fileCopyId
                    toMyFiles.can_be_shared = true
                    toMyFiles._can_delete = true
                    toMyFiles._can_rename = true

                    this.$store.dispatch('filesComponent/addMyDoc', {
                        arrayId: this.arrayId,
                        file: toMyFiles
                    })

                    // update file on Shared with me Tab
                    syncedFile._is_on_sync_queue = false
                    syncedFile._shared_to_my_document_id = true
                    syncedFile.can_be_shared = false
                } else if (data.status === 'failed') {
                    syncedFile.sync_failed = true
                } else if (data.status === 'already-synced') {
                    syncedFile.can_be_shared = false
                }
            }
        },
        async fetchFolders (folder: Object): Promise<void>{
            this.$store.dispatch('filesComponent/setLoading', true)

            // clear current files farray
            this.$store.dispatch('filesComponent/clearDocsArr', {
                arrayId: this.arrayId,
                files: []
            }, { root: true })

            let params = {}

            params.folder_id = folder.folder_id

            try {
                const { data } = await this.$api.get('/documents/folder/content', { params: params })

                this.$store.dispatch('filesComponent/setFolders', {
                    arrayId: this.arrayId,
                    folders: data.folders
                })

                this.$store.dispatch('filesComponent/setFolderFlags', data.current_folder)

                if (data.files && !isEmpty(data.files)) {
                    this.$store.dispatch('filesComponent/setMyDocs', {
                        arrayId: this.arrayId,
                        files: data.files.own
                    }, { root: true })
                }

                if (data.breadcrumb && data.breadcrumb.length) {
                    this.$store.dispatch('filesComponent/setFolderBreadcrumb', data.breadcrumb)
                }


                this.$store.dispatch('filesComponent/setSearchLocal', false)
                this.$store.dispatch('filesComponent/setIsSearching', false)
                this.$emit('clearFilters')
                this.$store.dispatch('filesComponent/setLoading', false)
            } catch (e) {
                this.consoleError(e)
                this.$store.dispatch('filesComponent/setLoading', false)
            }
        },
    }
})
