import { defineComponent } from 'vue'
import MarkAsPaidBody from '@/io-modules/invoices/components/mark-as-paid-body/MarkAsPaidBody.vue'
import RightModal from '@/components/RightModal/RightModal.vue'
import ContentTwoSidesTemplate from '@/components/content-two-sides-template/ContentTwoSidesTemplate.vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import PaymentInfo from '@/io-modules/invoices/interfaces/PaymentInfo.ts'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import AppPackagesInvoicesToPay from '@/io-modules/invoices/interfaces/app-packages/AppPackagesInvoicesToPay.ts'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName.ts'
import appPackageClient from '@/io-modules/invoices/api-clients/appPackageClient.ts'
import invoiceActionsClient from '../../../../api-clients/invoiceActionsClient'
import { appPackageViewStore } from '../../../../stores/appPackageView'
import { mapState as mapStateVuex } from 'vuex'
import { mapActions } from 'pinia'

export default defineComponent({
    name: 'MarkAsPaidModal',
    components: { IOSingleInfoRow, ContentTwoSidesTemplate, MarkAsPaidBody, RightModal, StatusPill },
    emits: ['close', 'refreshData'],
    data () {
        return {
            invoicesToPay: null,
            selectedInvoice: null,
            filledPaymentInfo: {}
        }
    },
    computed: {
        ...mapStateVuex('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id,
        }),
    },
    async created () {
        await this.getInvoicesList()
    },
    methods: {
        ...mapActions(appPackageViewStore, [
            'setDataLoading',
        ]),

        getSelectedInvoiceAmount (selectedInvoice: object): number {
            if (this.filledPaymentInfo[selectedInvoice.id] && this.filledPaymentInfo[selectedInvoice.id].amount) {
                return this.filledPaymentInfo[selectedInvoice.id].amount
            }

            return selectedInvoice.net_invoice_value - (selectedInvoice.paid_amount || 0)
        },

        async getInvoicesList (): Promise<void> {
            try {
                const { data } = await appPackageClient.getApplicationPackageMarkAsPaidList(this.projectLocalID, this.$route.params.id)
                this.invoicesToPay = data
                this.selectedInvoice = this.invoicesToPay[0]
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },

        navigateToInvoice (invId: string): void {
            this.$router.push({ name: InvoiceRouteName.InvoiceView, params: { id: invId } })
        },

        changeSelectedInvoice (invoice: AppPackagesInvoicesToPay): void {
            this.selectedInvoice = invoice
        },

        isPaymentInfoFilled (invId: string): boolean {
            const inv = this.filledPaymentInfo[invId]

            if (inv) {
                return inv.amount && inv.date && inv.method && inv.referenceNumber
            }

            return false
        },

        setFilledInfo (invId: string, paymentInfo: PaymentInfo): void {
            this.filledPaymentInfo[invId] = paymentInfo
            this.filledPaymentInfo = { ...this.filledPaymentInfo }
        },

        async payInvoices (): void {
            try {
                this.setDataLoading(true)
                const listOfRequests = []
                for (const id in this.filledPaymentInfo) {
                    if (this.filledPaymentInfo[id].amount && this.filledPaymentInfo[id].method && this.filledPaymentInfo[id].referenceNumber) {
                        const paymentInfo = {
                            method: this.filledPaymentInfo[id].method.id,
                            date: this.filledPaymentInfo[id].date,
                            paid: this.filledPaymentInfo[id].amount,
                            number: this.filledPaymentInfo[id].referenceNumber
                        }
                        listOfRequests.push(invoiceActionsClient.markAsPaidInvoice(this.projectLocalID, id, paymentInfo))
                    }
                }
                Promise.allSettled(listOfRequests).then((result) => {
                    const error = result.some(item => 'rejected' === item.status)
                    if (!error) {
                        this.showNotification('success', this.$t('Successfully marked as paid'))
                        this.$emit('close')
                        this.$emit('refreshData')
                    } else {
                        result.forEach(item => {
                            if ('rejected' === item.status) {
                                this.showNotification('error', item.response?.data?.message || item.message)
                            }
                        })
                    }
                })
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.setDataLoading(false)
            }
        },
    }
})
