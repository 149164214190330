<template>
    <div>
        <Card v-if="!loading && projectFunds && projectFunds.length" class="io-project-funds-list" :title="$t('Funds')"
              :show-line="false">
            <!--            <template #HeaderRightContent>-->
            <!--                <IOButton variant="link" size="small" icon="icon-edit">-->
            <!--                    {{ $t('Bulk Edit') }}-->
            <!--                </IOButton>-->
            <!--            </template>-->

            <template #Body>
                <Table
                    :headers="setTableHeaders(HeaderType.LIST, isOwner)"
                    :items="projectFunds"
                >
                    <template #tbody="{ sortedItems }">
                        <tr v-for="fund in sortedItems" @click="toggleFundDetails(fund.fund_id)">
                            <td v-if="isOwner">
                                <StatusPill
                                    v-if="fund.is_shared"
                                    size="big"
                                    :text="$t('Shared')"
                                    :variant="pillVariant.INFO_BG"
                                />
                            </td>
                            <td>
                                <span class="io-project-funds-list__fund-name">
                                    {{ fund.fund_name }}
                                    <span
                                        v-if="!fund.fund_has_started && fund.fund_start_date"
                                        v-tooltip.top="`${ $t('This fund will be available on')} ${ fund.fund_start_date }.`"
                                        class="icon-alert-triangle-2 io-project-funds-list__icon-alert"
                                    />
                                </span>
                            </td>
                            <td>
                                <StatusPill
                                    size="big"
                                    :text="fund.fund_type === fundTypes.CAPITAL ? $t('Program Fund') : $t('Project Specific')"
                                    :variant="fund.fund_type === fundTypes.CAPITAL ? pillVariant.SUCESS_BG : pillVariant.INFO_BG"
                                />
                            </td>
                            <td>
                                <Avatar
                                    type="company"
                                    :employeeMongoId="fund.provider.id"
                                    :employeeFullName="fund.provider.name"
                                    size="sm"
                                    showFullName
                                />
                            </td>
                            <td class="aright">
                                {{ $filters.formatCurrency(fund.fund_amount) }}
                            </td>
                            <td class="aright">
                                {{ $filters.formatCurrency(fund.allocated_amount) }}
                            </td>
                            <td class="aright">
                                {{ `${fund.funding_factor}  %` }}
                            </td>
                            <td class="aright">
                                {{ $filters.formatCurrency(fund.paid_to_date_amount) }}
                            </td>
                            <td class="aright" @click.stop>
                                <span
                                    v-if="!fund.is_shared"
                                    class="io-project-funds-list__icon-delete"
                                    :class="[fund.fund_type === fundTypes.CAPITAL ? 'icon-cross' : 'icon-trash',
                                             {'io-project-funds-list__icon-delete--disabled': fund.has_resources}]"
                                    @click="!fund.has_resources ? removeFundModals(fund.fund_type, fund.fund_id) : undefined"
                                >
                                    <span
                                        v-if="fund.has_resources"
                                        v-tooltip.top="`${ $t('You cannot remove a Fund if it has invoices billed against it.') }`"
                                        class="io-project-funds-list__icon-delete__tooltip"
                                    />
                                </span>
                            </td>
                        </tr>
                    </template>
                </Table>
            </template>
        </Card>
        <Placeholder
            v-if="!loading && projectFunds && !projectFunds.length"
            class="io-project-funds-list__splash"
            icon="icon-cash"
            :title="$t('No Funds Added')"
            :subtitle="$t('You may add Funds to the project by clicking the button below.')"
            :buttonsCta="buttonsCta"
            @addFund = "addFund"
        />
        <FundDetailsModal v-if="showFundDetails" :fund-id="idOpenedFund" @close="toggleFundDetails"/>
    </div>
</template>

<script lang="ts" src="./ProjectFundingFundsList.ts"></script>
<style lang="scss" src="./ProjectFundingFundsList.scss" scoped></style>
