import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'

import Avatar from '@/components/atoms/Avatar/Avatar.vue'

import DocumentInterface from '@/io-modules/documents/interfaces/DocumentInterface'
import CreatedBy from '@/io-modules/documents/interfaces/CreatedByInterface'

export default defineComponent({
    name: 'DetailsTab',
    components: {
        Avatar,
    },
    props: {
        document: {
            type: Object as () => DocumentInterface,
            required: true,
        },
    },
    computed: {
        details (): Array<{ title: TranslateResult, value: string | CreatedBy }> {
            return [
                {
                    title: this.$t('File name'),
                    value: this.document.file_name,
                },
                {
                    title: this.$t('File Size'),
                    value: this.$filters.fileSize(this.document.file_size),
                },
                {
                    title: this.$t('Folder'),
                    value: this.convertToTitleCase(this.document.model_type),
                },
                {
                    title: this.$t('Uploaded'),
                    value: this.$filters.parseDateTime(this.document.created_at),
                },
                {
                    title: this.$t('Added By'),
                    value: this.document.created_by,
                },
                {
                    title: this.$t('Photo Taken'),
                    value: this.document.photo_taken ? this.$filters.parseDateTime(this.document.photo_taken) : null,
                }
            ].filter(({ value }) => value)
        }
    },
    methods: {
        convertToTitleCase (input: string): string {
            if (!input) {
                return null
            }

            let words = input.split('_')
            words = words.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            return words.join(' ')
        }
    }
})
