<template>
    <PopupModal
        :customClass="'io-invite-modal__popup'"
        :width="720"
    >
        <template #header>
            <div class="io-popup-modal__icon">
                <i class="icon-rocket"></i>
            </div>
            <div class="io-popup-modal__title">
                {{ $t('Coming soon') }}
            </div>
            <div
                class="io-popup-modal__close"
                @click.stop="onClosePopupClick"
            >
                <i class="icon-cross"></i>
            </div>
        </template>

        <template #default>
            <Placeholder
                :title="$t('New Invite Functionality Coming Soon')"
                :subtitle="$t('We are transitioning to a new, more seamless invitation feature for you')"
                icon="icon-rocket"
            />
        </template>

        <template #footer>
            <IOButton variant="secondary" @click="onClosePopupClick">
                {{ $t('Cancel') }}
            </IOButton>
        </template>
    </PopupModal>
</template>

<script src="./InviteModalBanner.ts"></script>
<style src="./InviteModalBanner.scss" lang="scss" scoped></style>
