<template>
    <div class="io-dashboard-card io-info-card" :class="{'io-clickable': true}" @click="navigateToRoute">
        <div class="io-info-card__icon" :class="cardData.colorClass"><i class="fas" :class="cardData.iconClass"></i></div>
        <div>
            <div class="io-info-card__title">{{ cardData.title }}</div>
            <div class="io-info-card__caption">{{ cardData.caption }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'info-card',
        props: {
            type: {
                type: String,
                required: true
            },
        },
        computed: {
            cardData () {
                switch (this.type) {
                //  31
                case 'risk_items':
                    return {
                        title: this.$t('Risk Items'),
                        caption: this.$t('Open').toUpperCase(),
                        iconClass: 'fa-list-ul',
                        colorClass: 'io-orange',
                        route: {
                            name: 'tasks-listing',
                            query: {
                                view: 'assigned_to_me',
                                type: 'risk'
                            }
                        }
                    }
                case 'general_tasks':
                    return {
                        title: this.$t('General Tasks'),
                        caption: this.$t('Open').toUpperCase(),
                        iconClass: 'fa-people-arrows',
                        colorClass: 'io-blue',
                        route: {
                            name: 'tasks-listing',
                            query: {
                                view: 'assigned_to_me',
                                type: 'general',
                                status: 'unfinished'
                            },
                        }
                    }
                case 'contracts':
                    return {
                        title: this.$t('Contracts'),
                        caption: this.$t('In Progress').toUpperCase(),
                        iconClass: 'fa-file-contract',
                        colorClass: 'io-teal',
                        route: {
                            name: 'main-contracts',
                            // Status = Draft, Pending, Partially Signed, Signed
                            params: {
                                'statusFilter': [100, 200, 250, 350]
                            }
                        }
                    }
                case 'projects':
                    return {
                        title: this.$t('Projects'),
                        caption: this.$t('Active').toUpperCase(),
                        iconClass: 'fa-th-large',
                        colorClass: 'io-crimson',
                        route: {
                            name: 'project-list',
                            params: {
                                'statusFilters': [100, 200, 250, 550]
                            }
                        }
                    }
                }
            }
        },
        methods: {
            navigateToRoute () {
                if (this.cardData.route && this.cardData.route.name) {
                    this.$router.push({
                        name: this.cardData.route.name,
                        params: this.cardData.route.params,
                        query: this.cardData.route.query
                    })
                }
            }
        }
    }
</script>

<style lang="scss" src="./info-card.scss" scoped></style>
