import InvoicesListTable from '@/io-modules/invoices/components/invoices-list/invoices-list-table/InvoicesListTable.vue'
import InvoicesListHeader from '@/io-modules/invoices/components/invoices-list/invoices-list-header/InvoicesListHeader.vue'
import VendorDetails from '@/io-modules/invoices/components/invoices-list/vendor-details/VendorDetails.vue'
import InvoicesTab from '@/io-modules/invoices/enums/InvoicesTab'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import Invoice from '@/io-modules/invoices/interfaces/Invoice'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { defineComponent } from 'vue'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'

export default defineComponent({
    name: 'VendorDetailsView',
    components: {
        InvoicesListTable,
        LoadingScreen,
        InvoicesListHeader,
        VendorDetails,
    },
    emits: ['openManageColumns'],
    data () {
        return {
            InvoicesTab,
            loadingData: false,
        }
    },
    computed: {
        ...mapStateVuex('project', ['projectObj']),

        ...mapState(invoicesStore, [
            'getInvoicesListHeaders',
            'vendorsInvoices',
            'showSmallLoader',
            'showContentLoader',
        ]),

        vendorId (): string {
            return this.$route.params.id
        },

        invoicesHeaders (): HeaderTable[] {
            return this.getInvoicesListHeaders(InvoicesTab.Received).filter(header => 'vendor' !== header.key)
        },

        invoicesItems (): Invoice[] {
            return this.vendorsInvoices[this.vendorId] || []
        },
    },
    async mounted () {
        if (this.vendorId) {
            this.setDataLoaderValue(true)
            await Promise.allSettled([
                this.fetchVendorInvoices(this.projectObj.project_local_id, this.vendorId),
                this.fetchVendorDetails(this.projectObj.project_local_id, this.vendorId)
            ])
            this.setDataLoaderValue(false)
        }
    },
    methods: {
        ...mapActions(invoicesStore, ['fetchVendorInvoices', 'fetchVendorDetails', 'setDataLoaderValue']),

        async loadData (): Promise<void> {
            this.loadingData = true
            await this.fetchVendorInvoices(this.projectObj.project_local_id, this.vendorId)
            this.loadingData = false
        },
    },
})
