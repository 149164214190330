<template>
    <InvoicesListTable
        :headers="getListHeaders"
        :items="getList"
        :loading-data="loading"
        :totals="totals"
        @loadData="loadData"
    />
</template>

<script lang="ts" src="./InvoicesDefaultList.ts"></script>
