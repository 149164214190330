import { defineComponent } from 'vue'
import { mapActions } from 'vuex'

export default defineComponent({
    name: 'DropdownButton',
    props: {
        name: { type: String, default: 'Actions' },
        menuAlign: { type: String, default: 'right' },
        buttonClass: { type: String, default: 'io-btn-primary' },
        iconClass: { type: String, default: 'text-base' },
        showMask: { type: Boolean, default: false },
        separated: { type: Boolean, default: false },
        isInTopBar: { type: Boolean, default: false },
        closeOnClick: { type: Boolean, default: true },
        iconMode: { type: Boolean, default: false },
        fontAwesomeClass: { type: String, default: 'fas fa-ellipsis-h' }
    },
    data () {
        return {
            showMenu: false,
        }
    },
    computed: {
        menuClass (): string[] {
            const classes = []
            switch (this.menuAlign) {
            case 'left':
                classes.push('io-left')
                break
            case 'right':
                classes.push('io-right')
                break
            default:
                classes.push('io-center')
                break
            }
            if (!this.showMask) {
                classes.push('io-shadow')
            }
            if (this.separated) {
                classes.push('io-separated')
            }
            return classes
        },
        topPosition (): string {
            return this.iconMode
                ? 'top: 25px'
                : 'top: 40px'
        },
        maskClass (): string {
            return this.showMask ? 'io-show-mask' : ''
        }
    },
    methods: {
        ...mapActions('main', ['setTopBarInFront']),
        toggleMenu (): void {
            if (!this.isInTopBar) {
                this.showMenu = !this.showMenu
            } else {
                if (!this.showMenu) {
                    this.setTopBarInFront(true)
                    setTimeout(() => { this.showMenu = !this.showMenu }, 40)
                } else {
                    this.showMenu = !this.showMenu
                    setTimeout(() => { this.setTopBarInFront(false) }, 300)
                }
            }
        },
        handleMenuClick (): void {
            if (this.closeOnClick) {
                this.toggleMenu()
            }
        }
    }
})
