<template>
    <FormInputColumn
        :label="$tc('Type')"
        :custom-content="true"
        :required="!isNewVersion"
    >
        <div v-if="ApproverAssignmentType.APPROVER === approverAssignmentType">
            <Radio
                :radio-value="ApprovalType.APPROVED"
                input-name="approval-type"
                :label-text="$tc('Approved')"
                v-model="type"
            />

            <Radio
                :radio-value="ApprovalType.APPROVED_AS_NOTED"
                input-name="approval-type"
                :label-text="$tc('Approved as Noted')"
                v-model="type"
            />
        </div>
        <div v-else>
            <Radio
                :radio-value="ApprovalType.REVIEWED"
                input-name="approval-type"
                :label-text="$tc('Reviewed')"
                v-model="type"
            />

            <Radio
                :radio-value="ApprovalType.REVIEWED_AS_NOTED"
                input-name="approval-type"
                :label-text="$tc('Reviewed as Noted')"
                v-model="type"
            />
        </div>
    </FormInputColumn>
</template>

<script src="./ApprovalTypeRadio.ts" lang="ts"></script>
<style src="./ApprovalTypeRadio.scss" lang="scss" scoped></style>
