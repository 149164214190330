<template>
    <button
        class="io-design-system io-btn-component"
        data-testid="io-button-component"
        :type="type"
        :class="`io-btn-component--${ variant } io-btn-component--${ size } io-btn-component--${ fontWeight }`"
        :aria-label="computeAriaLabel"
    >
        <i
            v-if="icon && (iconPosition === ButtonIconPosition.LEFT || iconPosition === ButtonIconPosition.BOTH)"
            class="io-btn-component__icon io-btn-component__icon--left"
            :class="leftIcon || icon"
            data-testid="icon-left"
        />

        <slot />

        <i
            v-if="icon && (iconPosition === ButtonIconPosition.RIGHT || iconPosition === ButtonIconPosition.BOTH)"
            class="io-btn-component__icon io-btn-component__icon--right"
            :class="rightIcon || icon"
            data-testid="icon-right"
        />
    </button>
</template>

<script lang="ts" src="./IOButton.ts"></script>
<style lang="scss" src="./IOButton.scss" scoped></style>
