import companyClient from '../../../api/company/companyClient'
import { cloneDeep } from 'lodash'

export const resetState = function ({ commit, dispatch }) {
    commit('RESET_STATE')
}

export const get = async ({ commit, dispatch }, companyId) => {
    try {
        const response = await companyClient.get(companyId)
        const data = response.data
        commit('SET_MODEL', data.model)
        commit('SET_NOTES', data.notes)
        commit('SET_ADDRESSES', data.addresses)
        commit('SET_FORM', data.form)
        commit('SET_CUSTOM_INDUSTRIES', data.form.industries)
        return response
    } catch (exception) {
        throw exception
    }
}

export const deleteCompany = async ({ state, dispatch }, companyId) => {
    try {
        return await companyClient.deleteCompany(companyId)
    } catch (exception) {
        throw exception
    }
}

export const invite = async ({ state, dispatch }, companyId) => {
    try {
        await companyClient.invite(companyId)
        return dispatch('get', state.model.id)
    } catch (exception) {
        throw exception
    }
}

export const inviteCancel = async ({ state, dispatch }, companyId) => {
    try {
        await companyClient.inviteCancel(companyId)
        return dispatch('get', state.model.id)
    } catch (exception) {
        throw exception
    }
}

export const save = async ({ state, dispatch }) => {
    try {
        const postData = cloneDeep(state.model)
        postData.addresses = cloneDeep(state.addresses)

        const response = await companyClient.save(postData)

        if (response.data.model && response.data.model.id) {
            return dispatch('get', response.data.model.id)
        }
    } catch (exception) {
        throw exception
    }
}

export const saveV2 = async ({ state, dispatch }) => {
    try {
        const postData = cloneDeep(state.model)
        postData.addresses = cloneDeep(state.addresses)

        const response = await companyClient.saveV2(postData)

        if (response.data.model && response.data.model.id) {
            return dispatch('get', response.data.model.id)
        }
    } catch (exception) {
        throw exception
    }
}

export const savePaymentDetails = async ({ state }) => {
    try {
        await companyClient.savePaymentDetails(state.model)
    } catch (exception) {
        throw exception
    }
}


export const addAddress = async ({ commit }, fileId) => {
    commit('ADD_ADDRESS')
}


export const saveLogo = async ({ state, commit }, data) => {
    commit('SET_LOGO', data.photo)
}

export const addNote = async ({ commit, dispatch }, noteData) => {
    try {
        const { data } = await companyClient.addNote(noteData)
        commit('ADD_NOTE', data.data)
        return true
    } catch (exception) {
        throw exception
    }
}

export const toggleImportant = async ({ commit, dispatch, state }) => {
    try {
        const { data } = await companyClient.toggleImportant(state.model.id)
        commit('SET_IMPORTANT', data.status)
        return true
    } catch (exception) {
        throw exception
    }
}

export const addCustomIndustry = function ({ commit, dispatch }, value) {
    commit('ADD_CUSTOM_INDUSTRY', value || [])
}

export const updateCustomIndustry = function ({ commit, dispatch }, value) {
    commit('UPDATE_CUSTOM_INDUSTRY', value || [])
}

export const removeCustomIndustry = function ({ commit, dispatch }, value) {
    commit('REMOVE_CUSTOM_INDUSTRY', value || [])
}
