<template>
    <div v-if="file && isConstructionFileVisible"
         class="io-single-file-row"
         :class="{ 'io-file-synced': file._shared_to_my_document_id, 'io-is-inside-modal': showModalVersion, 'io-variant-skinny': isSkinnyVariant }"
         @mouseover="hoverStart"
         @mouseleave="hoverReset"
    >
        <div class="io-single-file-row__cell">
            <Checkbox
                v-model="selected"
                column="checkAll"
                @click="onChangeSelected($event)"
            />
        </div>
        <div class="io-single-file-row__cell io-single-file-row__cell--top">
            <div v-if="toSync" class="io-single-file-row__text io-single-file-row__text--name io-bold">
                <a target="_blank" :href="previewUrl">
                    {{ file.file_name }}
                </a>
            </div>
            <div v-else-if="fromTrash" class="io-single-file-row__text io-single-file-row__text--name io-bold">{{ file.file_name }}</div>
            <div v-else-if="addToTemporary" class="io-single-file-row__text io-single-file-row__text--name io-bold">{{ file.file_name }}</div>
            <router-link v-else-if="isProjectView" :target="filesInNewTab ? '_blank' : ''" :to="{name: 'project-documents-show', params: {pid: $route.params.pid, id: file._id}}" class="io-single-file-row__text io-bold">{{ file.file_name }}</router-link>
            <router-link v-else :target="filesInNewTab ? '_blank' : ''" :to="{ name: 'documents-show', params: { id: file._id || file.id }}" class="io-single-file-row__text io-bold">
                <div class="extension">{{ this.ext }}</div>
                <div class="io-single-file-row__text--name">{{ file.file_name }}</div>
            </router-link>

            <div v-if="file.is_new" class="io-status-pill io-blue">{{ $t('New') }}</div>
        </div>

        <div v-if="!showModalVersion" class="io-single-file-row__cell io-single-file-row__cell--author">
            <Avatar
                :size="isSkinnyVariant ? 'sm' : 'md'"
                :employeeMongoId="file.creator_mongo_id"
                :employeeFullName="file.creator_full_name"
                :showFullName="true"
                :companyName="isSkinnyVariant? '': file.company_name"
            />
        </div>

        <div class="io-single-file-row__cell io-single-file-row__cell--date">
            <div class="io-single-file-row__text">{{ $filters.formatDate(file.created_at) }}</div>
        </div>
        <div v-if="!showModalVersion" class="io-single-file-row__cell">
            <div class="io-single-file-row__text">{{ $filters.fileSize(file.file_size) }}</div>
        </div>
        <div v-click-outside="closeFileDropdown" class="io-single-file-row__box io-single-file-row__box--menu io-f-center">
            <div class="io-btn-primary-compact io-menu-btn" :class="'io-files-button-' + idx" @click="toggleFileDropdown"><i class="icon-more-vert-2"></i></div>
            <div v-if="showFileDropdown" class="io-single-file-row__dropdown" :class="dropdownShowAbove ? 'io-show-above' : ''">
                <div v-if="!fromTrash && !addToTemporary" class="io-single-dropdown-option" @click.stop="openFileInDocuments">
                    <div class="io-single-dropdown-option__icon">
                        <i class="icon-file"></i>
                    </div>
                    <div class="io-single-dropdown-option__text">{{ $t('Open in Documents') }}</div>
                </div>

                <template v-if="isAvailableEditableOption('preview') && !fromTrash">
                    <div  @click="toggleInfoPopup" class="io-single-dropdown-option">
                        <div class="io-single-dropdown-option__icon"><i class="icon-info"></i></div>
                        <div class="io-single-dropdown-option__text">{{ $t('Info') }}</div>
                    </div>
                </template>

                <a v-if="!fromTrash" :href="downloadLink()" target="_blank" class="io-single-dropdown-option">
                    <div class="io-single-dropdown-option__icon"><i class="icon-file-download"></i></div>
                    <div class="io-single-dropdown-option__text">{{ $t('Download') }}</div>
                </a>

                <template v-if="!toSync && editable">
                    <template v-if="fromTrash">
                        <div v-if="isAvailableEditableOption('restore')" class="io-single-dropdown-option" @click="onRestoreItem">
                            <div class="io-single-dropdown-option__icon"><i class="far fa-undo-alt"></i></div>
                            <div class="io-single-dropdown-option__text">{{ $t('Yes, restore') }}</div>
                        </div>
                        <div v-if="isAvailableEditableOption('delete')" class="io-single-dropdown-option" @click="removeItem">
                            <div class="io-single-dropdown-option__icon"><i class="far fa-trash-alt"></i></div>
                            <div class="io-single-dropdown-option__text">{{ $t('Delete') }}</div>
                        </div>
                    </template>
                    <!--if we are adding a new file to temporary location, for example new task modal-->
                    <template v-else-if="addToTemporary">
                        <div v-if="isAvailableEditableOption('important')" class="io-single-dropdown-option" @click="toggleImportant">
                            <div class="io-single-dropdown-option__icon">
                                <i v-if="file.flag === 0" class="far fa-star"></i>
                                <i v-else class="fas fa-star"></i>
                            </div>
                            <div v-if="file.flag === 0" class="io-single-dropdown-option__text">{{ $t('Important') }}</div>
                            <div v-else class="io-single-dropdown-option__text">{{ $t('Important') }}</div>
                        </div>
                        <div v-if="file.is_shareable && !forceShared && isAvailableEditableOption('share')" class="io-single-dropdown-option io-nohover">
                            <div class="io-single-dropdown-option__icon"><i class="far fa-share-alt"></i></div>
                            <div class="io-single-dropdown-option__text">{{ $t('Share') }}</div>
                            <div class="io-switch">
                                <label>
                                    <input name="test" type="checkbox" :checked="file.can_be_shared" @click="onShareChange()"/>
                                    <span class="io-no-text"></span>
                                </label>
                            </div>
                        </div>
                        <div v-if="isAvailableEditableOption('delete')" class="io-single-dropdown-option" @click="onItemToTrash(file.file_name)">
                            <div class="io-single-dropdown-option__icon"><i class="icon-trash-2"></i></div>
                            <div class="io-single-dropdown-option__text">{{ $t('Delete') }}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            v-if="file._can_delete && isAvailableEditableOption('delete')"
                            class="io-single-dropdown-option"
                            @click="onItemToTrash(file.file_name)"
                        >
                            <div class="io-single-dropdown-option__icon"><i class="icon-trash-2"></i></div>
                            <div class="io-single-dropdown-option__text">{{ $t('Delete') }}</div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
    <InfoPopup
        v-if="InfoPopupShown"
        :file="file"
        @close="toggleInfoPopup"
    />
</template>
<script src="./single-file.js"></script>
<style lang="scss" scoped src="./single-file.scss"></style>
