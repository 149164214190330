import ApprovalPhasesResourceRequestData
    from '@/interfaces/modules/projects/financial-approvals/ApprovalPhasesResourceRequestData.ts'
import { AxiosPromise } from 'axios'
import ApprovalPhaseData from '@/interfaces/modules/projects/financial-approvals/ApprovalPhaseData.ts'
import ApprovalPhaseExportRequest
    from '@/interfaces/modules/projects/financial-approvals/ApprovalPhaseExportRequest.ts'
import ApprovalPhasePreviewData from '@/interfaces/modules/projects/financial-approvals/ApprovalPhasePreviewData.ts'
import ApprovalPhaseDeleteNewestRequestData
    from '@/interfaces/modules/projects/financial-approvals/ApprovalPhaseDeleteNewestRequestData.ts'
import ApprovalPhaseCostCodeCategoryResponseData
    from '@/interfaces/modules/projects/financial-approvals/ApprovalPhaseCostCodeCategoryResponseData.ts'
import { ApiClient as client } from '@/api/api'

const financialApprovalPhasesClient = {
    getApprovalPhasesList (request: ApprovalPhasesResourceRequestData): AxiosPromise<ApprovalPhaseData[]> {
        return client.get('/approval-phases', { params: request })
    },
    getSharedApprovalPhasesList (request: ApprovalPhasesResourceRequestData): AxiosPromise<ApprovalPhaseData[]> {
        return client.get('/shared-approval-phases', { params: request })
    },
    getPDF (data: ApprovalPhaseExportRequest): AxiosPromise<BlobPart> {
        return client.post('/approval-phases/export/pdf', data, { responseType: 'blob' })
    },
    getExcel (data: ApprovalPhaseExportRequest): AxiosPromise<BlobPart> {
        return client.post('/approval-phases/export/excel', data, { responseType: 'blob' })
    },
    getSharedPDF (data: ApprovalPhaseExportRequest): AxiosPromise<BlobPart> {
        return client.post('/shared-approval-phases/export/pdf', data, { responseType: 'blob' })
    },
    getSharedExcel (data: ApprovalPhaseExportRequest): AxiosPromise<BlobPart> {
        return client.post('/shared-approval-phases/export/excel', data, { responseType: 'blob' })
    },
    getSingleApprovalPhase (phaseId: string): AxiosPromise<ApprovalPhasePreviewData> {
        return client.get(`/approval-phases/${ phaseId }`)
    },
    getSharedSingleApprovalPhase (phaseId: string): AxiosPromise<ApprovalPhasePreviewData> {
        return client.get(`/shared-approval-phases/${ phaseId }`)
    },
    revertToApprovedPhase (request: ApprovalPhasesResourceRequestData): AxiosPromise<void> {
        return client.post('/approval-phases/revert', request)
    },
    deleteNewestPhase (params: ApprovalPhaseDeleteNewestRequestData): AxiosPromise<void> {
        return client.delete('/approval-phases/delete-newest', { params })
    },
    getApprovalPhasesCategories (request: ApprovalPhasesResourceRequestData): AxiosPromise<ApprovalPhaseCostCodeCategoryResponseData[]> {
        return client.get('/approval-phases/codes', { params: request })
    },
}

export default financialApprovalPhasesClient
