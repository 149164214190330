import ProjectFundingMain from '@/io-modules/project-funding/views/ProjectFundingMain.vue'

const defaultMeta = {
    name: 'Project Funding',
    navigationKey: 'project_funding'
}
export default [
    {
        path: '/project/:pid/project-funding',
        name: 'project-funding',
        component: ProjectFundingMain,
        meta: defaultMeta
    }
]
