<template>
    <tr class="io-single-approver">
        <td class="io-drag">
            <i v-if="edit" class="fas fa-grip-vertical"></i>
            <span v-if="approvalSequence">{{ moment.localeData().ordinal(index + 1) }}</span>
            <span v-else>-</span>
        </td>

        <td class="io-employee-role">
            <template v-if="edit">
                <CustomMultiselect
                    v-tooltip.bottom="{ content: $tc('Please assign a project team member to this role in My Project Summary'), visible: disableMultiselect }"
                    :addNewBtnDisabled="false"
                    :addingNewInProcess="false"
                    :disabled="disableMultiselect"
                    :options="employeeMultiselectOptions"
                    :optionsTitle="optionsTitle"
                    :settings="multiselectSettings"
                    :statusEnable="false"
                    :value="activeEmployee"
                    @changeOption="onChangeEmployee"
                >
                    <template #header="{ activeOption, config }">
                        <div v-if="activeOption" class="io-multiselect__name">
                            <Initials
                                :bigAvatar="true"
                                :fullName="activeOption.name"
                                class="io-single-option__logo"
                            />
                            {{ activeOption.name }}
                        </div>

                        <div v-else class="io-multiselect__name io-placeholder"><span class="io-avatars"><i class="fas fa-plus"></i></span>{{ config.textPlaceholder }}</div>
                    </template>

                    <template #option="{ option }">
                        <Initials
                            :bigAvatar="true"
                            :fullName="option.name"
                            class="io-single-option__logo"
                        />
                        <div class="io-single-option__text-wrapper">
                            <div class="io-single-option__title-row">
                                <div class="io-single-option__title">{{ option.name }}</div>
                            </div>
                        </div>
                    </template>
                </CustomMultiselect>
            </template>

            <div v-else-if="activeEmployee" class="io-f-row io-f-start io-employee-logo">
                <Initials
                    :bigAvatar="true"
                    :fullName="activeEmployee.name"
                    class="io-single-option__logo"
                />
                <div>
                    <span>{{ activeEmployee.name }}</span>
                </div>
            </div>

            <div v-else-if="approver.employee" class="io-f-row io-f-start io-employee-logo">
                <Initials
                    :bigAvatar="true"
                    :fullName="approver.employee.full_name"
                    class="io-single-option__logo"
                />
                <div>
                    <span>{{ approver.employee.full_name }}</span>
                </div>
            </div>
        </td>

        <td class="io-approval-limit">
            <template v-if="edit">
                <Dropdown
                    v-if="approvalLimit"
                    v-tooltip.bottom="{ content: 'You must have at least one unconstrained member of this approval workflow', visible: approvalDisabled }"
                    :class="{'io-no-limit': approvalDisabled}"
                    :disabled="approvalDisabled"
                    :options="limitOptions"
                    :selectedByDefault="defaultSelection"
                    :width="136"
                    placeholder="Limit type"
                    @select="selectLimitType"
                />
                <span v-else>-</span>
            </template>

            <template v-else-if="approvalLimit">
                <span>{{ defaultSelection.label }}</span>
                <span v-if="approver.limit_type !== 'no_limit' && approver.limit_value">{{ $filters.formatCurrency(approver.limit_value) }}</span>
            </template>
        </td>

        <td class="io-limit-input">
            <FieldNumeric
                v-if="showLimitValueInput"
                :options="{ numeralPositiveOnly: true, numericOnly: false }"
                v-model="approver.limit_value"
            />
        </td>

        <td></td>

        <td class="io-remove aright">
            <template v-if="canDelete">
                <i class="far fa-trash-alt" @click="deleteApprover"></i>
            </template>
        </td>

    </tr>
</template>

<script lang="ts" src="./SingleApprover.ts"></script>
<style lang="scss" src="./SingleApprover.scss"></style>
