import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'

export default {
    namespaced: true,
    namespace: 'letterOfIntent/',
    state () {
        return {
            firstDraftProvider: null,
            proceedMode: null,
            letterOfIntentFormat: null,
            isSigned: false,
            document: null,
            selectedTemplate: null,
            description: '',

            clientApprovedBy: '',
            clientApprovedAt: null,
            clientApprovedNote: '',
            confirmText: '',

            sendViaMail: false,
            vendorRecipients: [],
            emailBody: '',

            isOffSystem: true,
            letterId: null,
            templateId: null,

            reviseMode: false,
            viewOnlyMode: false,
            showLoader: false
        }
    },
    actions,
    mutations,
    getters
}
