import { defineComponent } from 'vue'
import Popup from '@/components/popup/Popup.vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn.vue'

export default defineComponent({
    components: {
        Popup,
        FormInputColumn
    },
    props: {
        id: {
            type: String,
            required: true,
            default: 0
        },
        name: {
            type: String,
            required: true,
            default: ''
        },
    },
    data () {
        return {
            title: ''
        }
    },
    computed: {
        isValid (): boolean {
            return !!this.title
        }
    },
    methods: {
        submitForm (): void {
            if (!this.isValid) {
                return
            }

            this.$emit('change-name', { id: this.id, name: this.title })
        },
        onChangeTitle (value: string): void {
            this.title = value
        },
        onClose (): void {
            this.$emit('close')
        },
    },
})
