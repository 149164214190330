import { defineComponent } from 'vue'
import SingleMarkup from './single-markup/SingleMarkup.vue'
import { PdftronMarkup } from '@/io-modules/drawings/interfaces/PdftronMarkup'
import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'
import { mapGetters } from 'vuex'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'

export default defineComponent({
    name: 'Others',
    components: {
        SingleMarkup,
        IOPlaceholder,
    },
    props: {
        searchValue: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
            addingAnnotation: 'getAddingAnnotation',
            selectedAnnotation: 'getSelectedAnnotation',
            activeComponent: 'getActiveComponent',
        }),
        isAddingAnnotation (): boolean {
            return <boolean>(this.selectedAnnotation &&
                this.selectedAnnotation.xfdf &&
                this.selectedAnnotation.type === RightSideBar.MARKUPS)
        },
        otherMarkups (): PdftronMarkup[] {
            if (this.model.annotations.pdftron === undefined) {
                return []
            }
            const reversedArray = this.model.annotations.pdftron.slice().reverse()
            for (let i = 0; i < reversedArray.length; i++) {
                reversedArray[i].index = reversedArray.length - 1 - i
            }
            return reversedArray
        },
    },
    watch: {
        selectedAnnotation: {
            handler (newVal: unknown): void {
                if (newVal?.type === RightSideBar.MARKUPS) {
                    this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', RightSideBar.MARKUPS)
                }
            },
        },
    },
    methods: {
        deletePdftronMarkup (val: string): void {
            this.$emit('delete-pdftron-markup', val)
        },
        annotationDeleted (uuid: string): void {
            this.$emit('annotation-deleted', uuid)
        },
        annotationDeselected (): void {
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: '',
                type: RightSideBar.DEFAULT,
            })
        },
    },
})
