import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
    props: {
        totalRows: { type: Number },
        records: { type: Array as () => Array<{id: string}>, required: true, default: () => [] }
    },
    data () {
        return {
            checked: false
        }
    },
    computed: {
        ...mapGetters('lazyList', {
            selectedRows: 'getSelectedRows'
        }),
        isIndeterminate (): boolean {
            return this.selectedRows.length !== 0 && this.selectedRows.length !== this.totalRows && !this.checked
        }
    },
    watch: {
        selectedRows (currentValue: Array<string>): void {
            this.checked = currentValue.length === this.totalRows
        }
    },
    methods: {
        ...mapActions('lazyList', {
            setSelectedRows: 'setSelectedRows',
            setHasAllRowsSelected: 'setHasAllRowsSelected'
        }),
        async addSelectedRows (): Promise<void> {
            if (this.selectedRows === this.totalRows) {
                return
            }
            await this.setSelectedRows(this.checked ? this.records.map((item: {id: string}) => item.id) : [])
            await this.setHasAllRowsSelected(this.checked)
        },
    }
})
