import ImportToolClient from '@/api/admin-import-tool/ImportToolClient'

export const getDromoConfiguration = function ({ commit, dispatch, state }, value) {
    return ImportToolClient.getDromoConfiguration(value).then((response) => {
        commit('SET_DROMO_CONFIGURATION', response.data.models)
    })
}

export const getImporter = async function ({ commit, dispatch, state }, value = null) {
    let responseImporter = await ImportToolClient.getBatch(value)

    const {
        name,
        status,
        budget_code_template_id,
        client_mongo_id,
        id,
        counter,
        completed_records,
        duplicated_counter,
        failed_records,
        import_error_message,
        start_date,
        end_date,
        percentage,
    } = responseImporter?.data

   commit('SET_IMPORT_COUNTERS', counter)

   commit('SET_IMPORT_COMPLETED_RECORDS', completed_records)

   commit('SET_IMPORT_FAILED_RECORDS', failed_records)

   commit('SET_IMPORT_DUPLICATED_COUNTER', duplicated_counter)

   commit('SET_DATA_IMPORTER', { name, status, budget_code_template_id, client_mongo_id, id, import_error_message, start_date, end_date, percentage })
}

export const getImportConfiguration = function ({ commit, dispatch, state }, value) {
    return ImportToolClient.getImportConfiguration(value).then((response) => {
        commit('SET_IMPORT_CONFIGURATION', response.data.templates)
        commit('SET_IMPORT_CLIENTS', response.data.clients)
    })
}

export const updateImportStatus = function ({ commit }, data) {
    commit('UPDATE_IMPORT_STATUS_COMPLETION', data)
}

export const updateImportPercentage = function ({ commit }, data) {
    commit('UPDATE_IMPORTING_PERCENTAGE', data)
}
