<template>
    <div
        class="io-filters-circle"
        :class="{ 'io-filters-circle--disabled': disabled }"
        @click.stop="openFilters"
    >
        <span
            v-tooltip="{ content: $t(tooltipText), delay: { show: 2000, hide: 500 }}"
            class="io-filters-circle__icon icon-filter-2"
        >
        </span>

        <div v-if="computedFiltersCounter > 0" class="io-filters-circle__indicator">
            {{ computedFiltersCounter }}
        </div>
    </div>
</template>

<script lang="ts" src="./FiltersButton.ts"></script>
<style lang="scss" src="./FiltersButton.scss" scoped></style>
