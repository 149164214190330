import ContentTwoSidesTemplate from '@/components/content-two-sides-template/ContentTwoSidesTemplate.vue'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import dateTimeHelper from '@/helpers/dateTime'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import invoiceCreationClient from '@/io-modules/invoices/api-clients/invoiceCreationClient'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import DropdownButton from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import {
    VendorInvoiceDetails,
} from '@/io-modules/invoices/api-clients/invoiceViewClient.ts'
import InvoiceType from '@/io-modules/invoices/enums/InvoiceType.ts'
import { InvoiceSubType } from '@/io-modules/invoices/enums/InvoiceSubType.ts'
import Radio from '@/components/atoms/Radio/Radio.vue'
import IOTextarea from '@/components/atoms/IOTextarea/IOTextarea.vue'

export default defineComponent({
    name: 'EditVendorInvoiceDetails',
    components: {
        ContentTwoSidesTemplate,
        FieldDateTime,
        LoadingScreen,
        DropdownButton,
        Radio,
        IOTextarea,
    },
    data () {
        return {
            datepickerOptions: {
                'only-date': true,
                noClearButton: true,
                format: 'YYYY-MM-DD',
                formatted: dateTimeHelper.getDateFormat(),
            },
            loading: false,
            searchVendor: '',
        }
    },
    computed: {
        ...mapState(invoiceViewStore, {
            invoice: state => state.invoice as VendorInvoiceDetails,
            editing: state => state.editing,
            creating: state => state.creating,
            dataLoading: state => state.dataLoading,
            hasAccountingIntegration: state => state.hasAccountingIntegration,
            uniqueNumberCounter: state => state.uniqueNumberCounter,
            uniqueNumberMaxCharacters: state => state.uniqueNumberMaxCharacters,
            uniqueNumberIsValid: state => state.uniqueNumberIsValid
        }),
        ...mapStateVuex('project', ['projectObj']),
        ...mapStateVuex('appStore', ['getAuthData']),

        dateRange: {
            get (): object {
                return {
                    start: this.invoice.start_date,
                    end: this.invoice.end_date,
                }
            },
            set (value: object): void {
                if (value.start !== this.invoice.start_date || value.end !== this.invoice.end_date) {
                    this.invoice.start_date = value.start
                    this.invoice.end_date = value.end
                }
            }
        }
    },
    watch: {
        'invoice.invoice_unique_number' (): void {
            this.setUniqueNumberValid(true)
        },
    },
    async created (): Promise<void> {
        if (!this.invoice.invoice_date) {
            this.setInvoiceDateToday()
        }

        if (!this.editing) {
            await this.onNewVendorInvoiceCreating()
        }
    },
    methods: {
        ...mapActions(invoiceViewStore, [
            'setInvoiceDateToday',
            'setInvoiceContractId',
            'setDataLoadingValue',
            'setUniqueNumberValid',
            'setAppPackages',
            'setLienWaiverTemplate'
        ]),

        async onNewVendorInvoiceCreating (): Promise<void> {
            this.setDataLoadingValue(true)
            const { data } = await invoiceCreationClient.getVendorInvoiceCreationData(this.projectObj.project_local_id)
            this.setDataLoadingValue(false)

            if (data) {
                this.setAppPackages(data.application_packages)

                this.setLienWaiverTemplate(data)
            }

            this.invoice.type = InvoiceType.Contracted
            this.invoice.sub_type = InvoiceSubType.VendorInvoice
        }
    },
})
