<template>
    <ModalFilters
        v-if="showFilters"
        :countFilters="countFilters"
        @apply="saveFilters"
        @clearFilters="clearFilters"
        @closeModal="closeModal"
        @clearChanges="clearChanges"
        @clickOutside="clearChanges"
    >
        <template #default>
            <div class="io-filters">
                <AlertBox icon="icon-info">
                    {{ infoText }}
                </AlertBox>
                <div v-for="filter in filtersList" :key="filter.field" class="io-filters__item">
                    <IOLabel :text="filter.label" />
                    <div class="io-tooltip-wrapper">
                        <MultiselectCheckbox
                            v-if="FilterType.Multiselect === filter.type"
                            :data="filterOptions[filter.field]"
                            :disabled="!filterOptions[filter.field].length"
                            :settings="multiselectOptions"
                            v-model="filters[filter.field]"
                        >
                            <template #header="{ activeOptions, config }">
                                <div v-if="activeOptions" class="io-option io-option--active">
                                    <div class=".io-option__title io-option-title--active">
                                        {{ activeOptions }}
                                    </div>
                                </div>
                                <div v-else class="io-placeholder">{{ config.textPlaceholder }}</div>
                            </template>
                            <template #option="{ option }">
                                <Avatar
                                    size="sm"
                                    :employee-mongo-id="option.id"
                                    :employee-full-name="option.name"
                                    :show-full-name="true"
                                    :company-name="option.companyName || null"
                                />
                            </template>
                        </MultiselectCheckbox>
                        <div v-if="filter.tooltip && !filterOptions[filter.field].length" class="io-tooltip">
                            {{ filter.tooltip }}
                        </div>
                    </div>

                    <FilterCheckboxes
                        v-if="FilterType.Checkbox === filter.type"
                        :title="filter.label"
                        :options="filter.customOptions"
                        :max-length="10"
                        v-model="filters[filter.field]"
                    />

                    <FilterColorDot
                        v-if="FilterType.ColorDot === filter.type"
                        :title="filter.label"
                        :options="filter.customOptions"
                        :translate="true"
                        :color-dot-function="(...data) => colorDotFunction(...data, filter.field)"
                        :show-all-selected-check-box="false"
                        v-model="filters[filter.field]"
                    />

                    <FilterStatuses
                        v-if="FilterType.Status === filter.type"
                        :title="filter.label"
                        :options="filter.customOptions"
                        :pills-color-function="pillsColorFunction"
                        :hide-select-all-status="true"
                        v-model="filters[filter.field]"
                    />

                    <FilterDateTimePicker
                        v-if="FilterType.Date === filter.type"
                        :key="filter.type"
                        :options="datePickerOptions"
                        :show-icon="true"
                        v-model="filters[filter.field]"
                    />
                </div>
            </div>
        </template>
    </ModalFilters>
</template>

<script lang="ts" src="./ListFiltersModal.ts"></script>
<style lang="scss" src="./ListFiltersModal.scss" scoped></style>
