import * as actions from './actions'
import * as mutations from './mutations'
import state from './state'

export default {
    namespaced: true,
    namespace: 'projectNavigation/',
    state,
    actions,
    mutations
}
