import InvoiceHeader from '../enums/InvoiceHeader'
import Invoice from '../interfaces/Invoice'
import InvoicesTab from '../enums/InvoicesTab'
import i18n from '@/base/i18n'
import CellType from '@/components/table/CellType'
import BillingDateStatus from '../enums/BillingDateStatus'
import ApplicationPackageHeader from '../enums/ApplicationPackageHeader'
import ApplicationPackage from '../interfaces/ApplicationPackage'
import dayjs from 'dayjs'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { TranslateResult } from 'vue-i18n'

export default function initialTableColumnsSettings (type: InvoicesTab): HeaderTable[] {
    if (InvoicesTab.AppPackages === type) {
        return [
            {
                text: '#',
                value: ApplicationPackageHeader.AppPackageNumber,
                valueType: CellType.Text,
                sort: true,
                extraStyles: 'width: 64px;',
            },
            {
                text: i18n.global.t('Name'),
                value: ApplicationPackageHeader.Name,
                valueType: CellType.Text,
                sort: true,
                extraStyles: 'min-width: 165px;',
            },
            {
                text: i18n.global.t('Billing Period'),
                value: ApplicationPackageHeader.BillingPeriod,
                valueType: CellType.Text,
                customValue: (item: ApplicationPackage): string => {
                    return item.billing_start_date && item.billing_end_date
                        ? `${ dayjs(item.billing_start_date).format('MM/DD/YYYY') } — ${ dayjs(item.billing_end_date).format('MM/DD/YYYY') }`
                        : '-'
                },
                sort: true,
            },
            {
                text: i18n.global.t('Status'),
                value: ApplicationPackageHeader.Status,
                valueType: CellType.StatusPill,
                sort: true,
            },
            {
                text: i18n.global.t('# of Invoices'),
                value: ApplicationPackageHeader.NumberOfInvoices,
                valueType: CellType.Text,
                sort: false,
                align: 'end',
                valueAlign: 'aright',
            },
            {
                text: i18n.global.t('Gross Total this Period'),
                value: ApplicationPackageHeader.GrossTotalThisPeriod,
                valueType: CellType.Text,
                filter: 'formatProjectCurrencyInt',
                sort: false,
                align: 'end',
                valueAlign: 'aright',
            },
            {
                text: i18n.global.t('Gross Billed to Date'),
                value: ApplicationPackageHeader.GrossBilledToDate,
                valueType: CellType.Text,
                filter: 'formatProjectCurrencyInt',
                sort: true,
                align: 'end',
                valueAlign: 'aright',
            },
            {
                text: i18n.global.t('Vendors'),
                value: ApplicationPackageHeader.Vendors,
                valueType: CellType.Avatar,
                sort: false,
            },
            {
                text: '',
                valueType: CellType.Actions,
                customValue: (item: ApplicationPackage) => {
                    return [
                        {
                            variant: 'link',
                            icon: 'icon-trash',
                            tooltip: { text: i18n.global.t('Delete') },
                            isDisplayed: item.can_remove,
                            onClick: 'onRemoveAppPackage'
                        }
                    ]
                },
                sort: false,
                extraStyles: 'width: 80px;',
            }
        ]
    }

    let basicInvoiceColumns: HeaderTable[] = [
        {
            key: InvoiceHeader.Type,
            text: i18n.global.t('Invoice Type'),
            valueType: CellType.Text,
            value: InvoiceHeader.Type,
            customValue: (item: Invoice): TranslateResult => {
                return i18n.global.t(item.type)
            },
            visible: true,
            order: 1,
            fixed: false,
        },
        {
            key: InvoiceHeader.InvoiceNumber,
            text: i18n.global.t('Generated ID'),
            value: InvoiceHeader.InvoiceNumber,
            valueType: CellType.Text,
            visible: true,
            order: 2,
            fixed: false,
        },
        {
            key: InvoiceHeader.UniqueId,
            text: i18n.global.t('Custom ID'),
            value: InvoiceHeader.UniqueId,
            valueType: CellType.Text,
            visible: true,
            order: 3,
            fixed: false,
        },
        {
            key: InvoiceHeader.ApplicationPackageNumber,
            text: i18n.global.t('Application Package #'),
            value: InvoiceHeader.ApplicationPackageNumber,
            valueType: CellType.Text,
            valueAlign: 'acenter',
            extraStyles: 'width: 165px;',
            visible: true,
            order: 4,
            fixed: false,
        },
        {
            key: InvoiceHeader.InvoiceDate,
            text: i18n.global.t('Invoice Date'),
            valueType: CellType.Text,
            value: InvoiceHeader.InvoiceDate,
            customValue: (item: Invoice): string => {
                return dayjs(item.invoice_date).format('DD MMM YYYY')
            },
            visible: true,
            order: 5,
            fixed: false,
            sortDates: true
        },
        {
            key: InvoiceHeader.Status,
            text: i18n.global.t('Invoice Status'),
            value: InvoiceHeader.Status,
            valueType: CellType.StatusPill,
            visible: true,
            order: 6,
            fixed: false,
        },
        {
            key: InvoiceHeader.LienWaiverStatus,
            text: i18n.global.t('Lien Waiver Status'),
            value: InvoiceHeader.LienWaiverStatus,
            valueType: CellType.StatusDot,
            sort: false,
            visible: true,
            order: 7,
            fixed: false,
        },
        {
            key: InvoiceHeader.BallInCourt,
            text: i18n.global.t('Ball in Court'),
            value: InvoiceHeader.BallInCourt,
            valueType: CellType.Avatar,
            sort: false,
            visible: true,
            order: 8,
            fixed: false,
        },
        {
            key: InvoiceHeader.GrossBilledThisPeriod,
            text: i18n.global.t('Gross Billed This Period'),
            value: InvoiceHeader.GrossBilledThisPeriod,
            valueType: CellType.Text,
            filter: 'formatProjectCurrencyInt',
            valueAlign: 'aright',
            extraStyles: 'width: 174px;',
            visible: true,
            order: 9,
            fixed: false,
        },
        {
            key: InvoiceHeader.NetBilledThisPeriod,
            text: i18n.global.t('Net Billed This Period'),
            value: InvoiceHeader.NetBilledThisPeriod,
            valueType: CellType.Text,
            filter: 'formatProjectCurrencyInt',
            valueAlign: 'aright',
            extraStyles: 'width: 160px;',
            visible: true,
            order: 10,
            fixed: false,
        },
        {
            key: InvoiceHeader.PaymentStatus,
            text: i18n.global.t('Payment Status'),
            value: InvoiceHeader.PaymentStatus,
            valueType: CellType.StatusDot,
            visible: true,
            order: 11,
            fixed: false,
        },
        {
            key: InvoiceHeader.BilledToDate,
            text: i18n.global.t('Billed to Date'),
            value: InvoiceHeader.BilledToDate,
            valueType: CellType.Text,
            filter: 'formatProjectCurrencyInt',
            valueAlign: 'aright',
            extraStyles: 'width: 114px;',
            visible: true,
            order: 12,
            fixed: false,
        },
        {
            key: InvoiceHeader.BillingDate,
            text: i18n.global.t('Billing Date'),
            value: InvoiceHeader.BillingDate,
            valueType: CellType.StatusDot,
            customValue: (item: Invoice): string => {
                return item.billing_date ? dayjs(item.billing_date).format('MM/DD/YYYY') : '-'
            },
            tooltip: (item: Invoice): TranslateResult | boolean => {
                return {
                    [BillingDateStatus.Overdue]: i18n.global.t('Billing Date that is past due'),
                    [BillingDateStatus.NoRisk]: i18n.global.t('Billing Date that is further than 5 work days away'),
                    [BillingDateStatus.NearPaymentDate]: i18n.global.t('Billing Date that is between 1 - 4 work days away')
                }[item.billing_date_status]
            },
            sort: false,
            visible: true,
            order: 13,
            fixed: false,
        },
    ]

    if (InvoicesTab.Received === type || InvoicesTab.Shared === type) {
        basicInvoiceColumns.unshift({
            key: 'off_on_system',
            text: '',
            valueType: CellType.Image,
            customValue: (item: Invoice): object => {
                return {
                    src: `/img/avatar-${ item.is_provided_manually ? 'gray' : 'colored' }.svg`,
                    tooltip: item.is_provided_manually ? i18n.global.t('Provided Manually') : i18n.global.t('Provided via System')
                }
            },
            sort: false,
            visible: true,
            order: 0,
            fixed: false,
        })

        const index = basicInvoiceColumns.findIndex(item => InvoiceHeader.ApplicationPackageNumber === item.key)

        basicInvoiceColumns.splice(index, 0, {
            key: InvoiceHeader.Vendor,
            text: i18n.global.t('Vendor'),
            value: InvoiceHeader.Vendor,
            valueType: CellType.Avatar,
            manageInfo: InvoicesTab.Received === type && i18n.global.tc('This column won\'t show up in the "Grouped by Vendor" and "Vendor Details" views.'),
            sort: false,
            visible: true,
            order: index + 1,
            fixed: false,
            extraStyles: 'min-width: 160px;',
        })
    }

    return basicInvoiceColumns
}
