import { defineComponent, PropType } from 'vue'
import FilterStatuses from '@/components/ModalFilters/filters/filterStatuses.vue'
import FilterCheckboxes from '@/components/ModalFilters/filters/filterCheckboxes.vue'
import FilterDateTimePicker from '@/components/ModalFilters/filters/filterDateTimePicker.vue'
import MultiselectCheckbox from '@/components/multiselect-checkbox/MultiselectCheckbox.vue'
import StatusColorsMixin from '@/io-modules/invoices/mixins/StatusColorsMixin.ts'
import InvoiceHeader from '@/io-modules/invoices/enums/InvoiceHeader.ts'
import FilterType from '@/components/ModalFilters/interfaces/FilterType.ts'
import SimpleOption from '@/io-modules/invoices/interfaces/SimpleOption.ts'
import { receivedInvoicesStatuses } from '@/io-modules/invoices/components/invoices-list/list-filters-modal/helpers/receivedInvoicesStatuses.ts'
import { MultiselectOptionsInterface } from '@/interfaces/components/custom-multiselect/MultiselectOptionsInterface.ts'
import { LabelType } from '@/constants/LabelConstants.ts'
import AppPackageInvoice from '@/io-modules/invoices/interfaces/app-packages/AppPackageInvoice.ts'
import appPackageClient from '../../../../../api-clients/appPackageClient'
import { mapState } from 'pinia'
import { appPackageViewStore } from '@/io-modules/invoices/stores/appPackageView.ts'
import ListFiltersListingParams from '@/io-modules/invoices/interfaces/invoices-list/ListFiltersListingParams'

export default defineComponent({
    name: 'AppPackageInvoicesFilters',
    components: {
        MultiselectCheckbox,
        FilterDateTimePicker,
        FilterCheckboxes,
        FilterStatuses,
    },
    props: {
        invoicesList: {
            type: Array as PropType<AppPackageInvoice[]>
        },
        projectLocalID: {
            type: String,
            default: '',
        },
    },
    mixins: [StatusColorsMixin],
    emits: ['fetchData'],
    data () {
        return {
            FilterType,
            LabelType,
            datePickerOptions: {
                formatted: 'MM/DD/YYYY',
                range: true,
                placeholder: this.$t('Select Date Range'),
                'only-date': true,
            },
            multiselectOptions: {
                textPlaceholder: '',
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: false,
            } as MultiselectOptionsInterface,
            selectedFilters: {
                type: [],
                invoice_date: null,
                vendor: [],
                status: [],
            } as ListFiltersListingParams,
            invoicesFilters: [
                {
                    field: InvoiceHeader.Type,
                    type: FilterType.Checkbox,
                    label: this.$t('Invoice Type'),
                },
                {
                    field: InvoiceHeader.InvoiceDate,
                    type: FilterType.Date,
                    label: this.$t('Invoice Date'),
                },
                {
                    field: InvoiceHeader.Vendor,
                    type: FilterType.Multiselect,
                    label: this.$t('Vendor'),
                },
                {
                    field: InvoiceHeader.Status,
                    type: FilterType.Status,
                    label: this.$t('Invoice Status'),
                },
            ],
            filterOptions: {
                vendor: [],
                status: [],
                type: [
                    {
                        id: 'contracted',
                        name: this.$t('Contracted'),
                    },
                    {
                        id: 'non-contracted',
                        name: this.$t('Non-Contracted'),
                    }
                ]
            },
        }
    },
    computed: {
        ...mapState(appPackageViewStore, ['appPackage']),

        isSelectedFilter (): boolean {
            return Object.values(this.selectedFilters).some(value => {
                if (Array.isArray(value)) {
                    return value.length > 0
                }

                return value && !!(value.start || value.end)
            })
        }
    },
    watch: {
        selectedFilters: {
            handler (): void {
                let filters = {}
                if (this.selectedFilters.vendor.length) {
                    filters.vendor = this.selectedFilters.vendor.map(vendor => vendor.id)
                }
                if (this.selectedFilters.type.length) {
                    filters.type = this.selectedFilters.type
                }
                if (this.selectedFilters.invoice_date?.start) {
                    filters.invoice_date = this.selectedFilters.invoice_date
                }
                if (this.selectedFilters.status.length) {
                    filters.status = this.selectedFilters.status
                }
                this.$emit('fetchData', Object.keys(filters).length ? filters : null)
            },
            deep: true
        }
    },
    async mounted () {
        try {
            const { data } = await appPackageClient.getApplicationPackageAttachableInvoicesFilters(this.projectLocalID, this.appPackage.id)
            this.filterOptions.vendor = data?.vendor || []
            this.filterOptions.status = data?.status.map(item => {
                return { name: item.name, id: item.status }
            }) || []
        } catch (error) {
            this.errorHandleNoRedirect(error)
        }
    },
    methods: {
        pillsColorFunction (_: string, key: string): string {
            return `io-${ this.getInvoiceStatusColor(key) }`
        },
        clearFilters (): void {
            Object.keys(this.selectedFilters).forEach(key => {
                this.selectedFilters[key] = []
                this.selectedFilters.invoice_date = null
            })
        }
    },
})
