<template>
	<div class="io-export-logo-preview-component">
		<!--<div class="io-export-logo-preview-component_header">{{ $t('Logo Preview') }}</div>-->

        <div class="io-export-logo-preview-component_inputs-wrapper">
            <CustomDropdown
                :options="ratioOptions"
                :selectedLabel="ratioSelectedLabel"
                :optionLabel="dropDownOptionLabel"
                :disabled="disableImageSizeModifyControls"
                @select="onSelectRatio"
            />

            <IOInput
                prefix="W"
                type="number"
                :disabled="disableImageSizeModifyControls"
                v-model="imageWidth"
            />

            <IOInput
                prefix="H"
                type="number"
                :disabled="disableImageSizeModifyControls"
                v-model="imageHeight"
            />
        </div>

        <div class="io-export-logo-preview-component_image-wrapper">
            <img id="exportLogoImage" :src="srcImage" @load="onImgLoad" @error="onImgLoadError" />
            <canvas id="exportLogoImageCanvas" :width="imageWidth" :height="imageHeight"></canvas>
        </div>
    </div>
</template>

<script>
    import CustomDropdown from '@/components/custom-dropdown/CustomDropdown'

    export default {
        components: {
            CustomDropdown
        },
        props: {
            model: {
                name: Object,
                required: true
            },
            srcImageForced: {
                name: Boolean,
                default: false,
                required: false
            },
            srcImageForcedValue: {
                name: String,
                required: false
            },
            disableImageSizeModifyControls: {
                name: Boolean,
                default: false,
                required: false
            }
        },
        data () {
            return {
                originalWidth: 0,
                originalHeight: 0,
                imageWidth: 0,
                imageHeight: 0,
                ratio: 1,
                initialLoad: true
            }
        },
        computed: {
            ratioSelectedLabel () {
                let index = this.ratioOptions.findIndex(item => item.id === this.ratio)
                return this.ratioOptions[index].name
            },
            ratioOptions () {
                return [
                    { id: 0.25, name: '0.25x' },
                    { id: 0.5, name: '0.5x' },
                    { id: 0.75, name: '0.75x' },
                    { id: 1, name: '1x' },
                    { id: 1.5, name: '1.5x' },
                    { id: 2, name: '2x' },
                    { id: 3, name: '3x' },
                    { id: 4, name: '4x' },
                    { id: 5, name: '5x' }
                ]
            },
            srcImage () {
                if (this.srcImageForced) {
                    return this.srcImageForcedValue
                }
                return this.logoId ? this.getApiUrlCompanyLogoExport(this.logoId) : this.defaultLogo
            },
            logoId () {
                return this.model?.company_logo_doc
            },
            defaultLogo () {
                return process.env.CDN_URL + 'images/default_logo_export.png'
            }
        },

        watch: {
            imageWidth () {
                this.drawLogo()
                this.model.export_width_logo = this.imageWidth
                this.model.export_ratio_logo = this.ratio
            },
            imageHeight () {
                this.drawLogo()
                this.model.export_height_logo = this.imageHeight
                this.model.export_ratio_logo = this.ratio
            }
        },

        methods: {
            resizeImageOnInitialLoad () {
                if (
                    String(this.imageWidth) !== String(this.model.export_width_logo) ||
                    String(this.imageHeight) !== String(this.model.export_height_logo)
                ) {
                    this.imageWidth = this.model.export_width_logo
                    this.imageHeight = this.model.export_height_logo
                }
            },
            drawLogo () {
                let img = document.getElementById('exportLogoImage')

                let image = new Image(this.imageWidth, this.imageHeight)
                image.src = img.src

                const canvas = document.getElementById('exportLogoImageCanvas')
                const ctx = canvas.getContext('2d')

                image.onload = () => {
                    ctx.clearRect(0, 0, canvas.width, canvas.height)
                    ctx.drawImage(image, 0, 0, this.imageWidth, this.imageHeight)
                }
            },
            onSelectRatio (option) {
                this.ratio = option.id

                this.imageWidth = Math.floor(this.originalWidth * option.id)
                this.imageHeight = Math.floor(this.originalHeight * option.id)
            },
            dropDownOptionLabel (option)  {
                return option.name
            },
            onImgLoad (event) {
                let image = document.getElementById('exportLogoImage')
                this.imageWidth = image.naturalWidth
                this.imageHeight = image.naturalHeight

                this.originalWidth = this.imageWidth
                this.originalHeight = this.imageHeight

                if (this.initialLoad) {
                    this.initialLoad = false
                    this.resizeImageOnInitialLoad()
                }

                this.drawLogo()
            },
            onImgLoadError (event) {
                this.imageWidth = 0
                this.imageHeight = 0

                this.originalWidth = this.imageWidth
                this.originalHeight = this.imageHeight

                this.drawLogo()
            }
        }
    }
</script>

<style lang="scss" scoped>
	.io-export-logo-preview-component {
        background-color: var(--box-background);
        width: 100%;

        &_header {
            border-bottom: 1px solid var(--darker-bg);
            padding: 24px;
            color: var(--main);
            font-weight: 500;
        }

        &_image-wrapper {
            display: flex;
            justify-content: center;
            padding: 120px 0px;

            #exportLogoImage {
                display: none;
            }
        }

        &_inputs-wrapper {
            /*padding: 8px 24px;*/
            padding: 8px 0px;
            /*border-bottom: 1px solid var(--darker-bg);*/
            display: flex;

            .io-input-component {
                width: 140px;
                margin-right: 16px;
            }

            .io-custom-dropdown {
                width: 70px;
                margin-right: 24px;
            }
        }
    }
</style>

<style lang="scss">
    .io-export-logo-preview-component .io-input-component input {
        width: 100% !important;
    }
</style>
