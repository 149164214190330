import { defineComponent, PropType } from 'vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import { FundingSourcesFund, FundingSourcesProvider } from '../../interfaces/GlobalFundingInterfaces.ts'

export default defineComponent({
    name: 'CapitalFundInfo',
    components: { IOSingleInfoRow, Avatar },
    props: {
        info: {
            type: Object as PropType<FundingSourcesFund>,
            required: true,
        },
        provider: {
            type: Object as PropType<FundingSourcesProvider>,
            required: true,
        },
    },
    computed: {
        contactFullName (): string {
            return `${ this.info.contact.firstname } ${ this.info.contact.lastname }`
        },
    },
})
