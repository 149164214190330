import InvoicesListHeader from '@/io-modules/invoices/components/invoices-list/invoices-list-header/InvoicesListHeader.vue'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import ListsMixin from '@/io-modules/invoices/mixins/ListsMixin'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName'
import { ItemRouteInterface } from '@/interfaces/components/lazy-list/ItemRoute'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import listTypeName from '@/io-modules/invoices/helpers/listTypeName.ts'

export default defineComponent({
    name: 'ReceivedInvoicesList',
    components: {
        InvoicesListHeader,
        LoadingScreen,
    },
    mixins: [ListsMixin],
    emits: ['createInvoiceClick'],
    data () {
        return {
            ctaButtons: [
                {
                    name: this.$t('Create Vendor Invoice'),
                    onSubmitName: 'createInvoice'
                },
            ],
            loading: false,
        }
    },
    computed : {
        defaultList (): boolean {
            return InvoiceRouteName.InvoicesReceivedDefaultList === this.$route.name
        },

        vendorsList (): boolean {
            return InvoiceRouteName.InvoicesReceivedVendorsList === this.$route.name
        },
    },
    watch: {
        $route (to: ItemRouteInterface, from: ItemRouteInterface): void {
            const showInnerLoader = from.name === InvoiceRouteName.InvoicesReceivedVendorsList || from.name === InvoiceRouteName.InvoicesReceivedDefaultList
            this.fetchData(showInnerLoader)
        },
    },
    mounted () {
        this.fetchData(false)
    },
    methods: {
        ...mapActions(invoicesStore, [
            'fetchReceivedInvoices',
            'fetchReceivedInvoicesVendors',
            'clearListParams',
            'clearListFilters',
            'fetchFilters'
        ]),

        createInvoice (): void {
            this.$emit('createInvoiceClick')
        },

        async fetchData (showInnerLoader: boolean = false): Promise<void> {
            try {
                if (showInnerLoader) {
                    this.loading = true
                } else {
                    this.setContentLoaderValue(true)
                }

                await this.fetchFilters(this.projectObj.project_local_id, listTypeName(this.$route.meta.listType))

                if (this.defaultList) {
                    await this.fetchReceivedInvoices(this.projectObj.project_local_id)
                }
                if (this.vendorsList) {
                    await this.fetchReceivedInvoicesVendors(this.projectObj.project_local_id)
                }

            } catch (error) {
                console.log(error)
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            } finally {
                if (showInnerLoader) {
                    this.loading = false
                } else {
                    this.setContentLoaderValue(false)
                }
            }
        },
    },
})
