<template>
    <div class="io-dashboard-card io-ranking-card">
        <div class="io-ranking-card__header">
            <div class="io-dashboard-card__title">{{ $t('Weekly Productivity Ranking') }}</div>
        </div>
        <div class="io-ranking-card__content">
            <div class="io-ranking-item io-gold">
                <div class="io-ranking-item__icon"><i class="fad fa-trophy-alt"></i></div>
                <div class="io-ranking-item__photo"></div>
                <div class="io-ranking-item__name">{{ get(data, 'first.full_name', '-') }}</div>
                <div class="io-ranking-item__tasks">{{ getTaskCountTranslation(get(data, 'first.score', 0)) }}</div>
            </div>
            <div class="io-ranking-item io-silver">
                <div class="io-ranking-item__icon"><i class="fad fa-trophy"></i></div>
                <div class="io-ranking-item__photo"></div>
                <div class="io-ranking-item__name">{{ get(data, 'second.full_name', '-') }}</div>
                <div class="io-ranking-item__tasks">{{ getTaskCountTranslation(get(data, 'second.score', 0)) }}</div>
            </div>
            <div class="io-ranking-item io-bronze">
                <div class="io-ranking-item__icon"><i class="fad fa-trophy"></i></div>
                <div class="io-ranking-item__photo"></div>
                <div class="io-ranking-item__name">{{ get(data, 'third.full_name', '-') }}</div>
                <div class="io-ranking-item__tasks">{{ getTaskCountTranslation(get(data, 'third.score', 0)) }}</div>
            </div>
        </div>
        <div class="io-ranking-card__footer">
            <div class="io-your-stats">
                <div class="io-your-stats__position">{{ productivityPosition }}</div>
                <div class="io-your-stats__name">{{ $t('Your Stats') }}</div>
                <div class="io-your-stats__tasks">{{ getTaskCountTranslation(get(data, 'logged_user.score', 0)) }}</div>
            </div>
            <router-link
                v-slot="{ navigate }"
                :to="{name: 'todo-list'}"
                custom
            >
                <div
                    class="io-btn-primary-compact io-btn-full-width"
                    @click="navigate"
                    @keypress.enter="navigate"
                >
                    {{ $t('See Your Tasks') }}
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import { get } from 'lodash'

    export default {
        name: 'productivity-ranking',
        props: {
            data: {
                type: Object,
                required: true,
                default: () => ({})
            }
        },
        setup () {
            return { get }
        },
        data () {
            return {
                activeOption: 0,
                options: ['Tasks', 'Issues', 'Option 3'],
                dropdownShown: false
            }
        },
        computed: {
            productivityPosition () {
                const position = get(this.data, 'logged_user.position', null)
                if (get(this.data, 'logged_user.position', null)) {
                    return `${ position }${ this.getOrdinalSuffix(position) }`
                }

                return '-'
            }
        },
        methods: {
            getTaskCountTranslation (count) {
                return this.$t('No Task | {count} Task | {count} Tasks', count, { count })
            },
            toggleShowDropdown () {
                this.dropdownShown = !this.dropdownShown
            },
            closeDropdown () {
                this.dropdownShown = false
            },
            setActiveSection (index) {
                this.activeOption = index
                this.closeDropdown()
            },
            getOrdinalSuffix (number) {
                let suffix = ''
                const tmp = (number % 100)

                if (tmp >= 4 && tmp <= 20) {
                    return 'th'
                }

                switch (number % 10) {
                    case 1:
                        suffix = 'st'
                        break
                    case 2:
                        suffix = 'nd'
                        break
                    case 3:
                        suffix = 'rd'
                        break
                    default:
                        suffix = 'th'
                        break
                }

                return suffix
            }
        }
    }
</script>

<style lang="scss" src="./productivity-ranking.scss" scoped></style>
