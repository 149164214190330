<template>
    <div class="io-wysiwyg-container">
        <div v-if="options.length" class="cboth">
            <Multiselect
                track-by="id"
                label="name"
                :options="options"
                :allow-empty="false"
                searchable
                :placeholder="$t('Select Template')"
                v-model="selectedOption"
            />
            <br/>
        </div>

        <div
            v-if="symbolCounter"
            class="io-form-length"
        >
            {{ symbolsLength }}

            <template v-if="maxLength">
                / {{ maxLength }}
            </template>

            {{ $t('Characters') }}
        </div>

        <div :class="['tinymce-container',  'io-tinymce-container', customClass]">
            <textarea :id="fieldId" ref="textarea"></textarea>
        </div>
    </div>
</template>

<script lang="ts">
    import tinymce from 'tinymce'
    import 'tinymce/models/dom'
    import 'tinymce/icons/default'
    import 'tinymce/themes/silver/theme'
    import 'tinymce/skins/ui/oxide/skin.css'
    import 'tinymce/plugins/autoresize'
    import 'tinymce/plugins/autolink'
    import 'tinymce/plugins/link'
    import 'tinymce/plugins/lists'
    import 'tinymce/plugins/wordcount'
    import { debounce } from 'lodash'
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'FieldWysiwyg',
        props: {
            id: {
                type: String,
                default: '',
            },
            modelValue: {
                type: [ String, null ],
                required: true
            },
            selection: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Array as () => Array<unknown>,
                default: () => [],
            },
            settings: {
                type: Object as () => Record<string, unknown>,
                default: () => ({}),
            },
            customClass: {
                type: String,
                default: '',
            },
            tinyMceContentStyle: {
                type: String,
                default: '',
            },
            maxLength: {
                type: Number,
                default: null,
            },
            symbolCounter: {
                type: Boolean,
                default: false,
            },
            height: {
                type: Number,
                default: 300,
            },
            minHeight: {
                type: Number,
                default: 300,
            },
            maxHeight: {
                type: Number,
                default: null,
            }
        },
        emits: [
            'update:modelValue',
            'length',
        ],
        data () {
            return {
                fieldId: '',
                editor: null,
                selectedOption: {},
                symbolsLength: 0
            }
        },
        computed: {
            value: {
                get (): string {
                    return this.modelValue
                },
                set (value: string): void {
                    this.$emit('update:modelValue', value)
                }
            },
        },
        // Watch changes from cleave and emit to provide two-way binding
        watch: {
            selectedOption: function (value) {
                if (value !== null) {
                    this.editor.setContent(value.text)
                }
            },
            symbolsLength (value) {
                this.$emit('length', value)
            }
        },

        created () {
            if (this.id !== '') {
                this.fieldId = this.id
            } else {
                this.fieldId = 'wysiwyg' + crypto.randomUUID()
            }

            this.initEditor = debounce(this.initEditorMethod, 100)
        },

        deactivated () {
            this.destroyEditor()
        },

        activated () {
            this.initEditor()
        },

        mounted () {
            this.initEditor()
        },

        beforeUnmount () {
            this.destroyEditor()
        },
        methods: {
            setContent (content): void {
                this.editor.setContent(content)
            },
            focus (): void {
                tinymce.activeEditor.focus()
                tinymce.activeEditor.selection.select(tinymce.activeEditor.getBody(), true)
                tinymce.activeEditor.selection.collapse(false)
            },
            initEditorMethod (): void {
                $(`#${ this.fieldId }`).show()

                tinymce.init({
                    theme: 'silver',
                    init_instance_callback: (editor) => {
                        this.editor = editor
                        if (this.value) {
                            editor.setContent(this.value)
                        }
                        editor.on('KeyUp', (e) => {
                            this.$emit('update:modelValue', editor.getContent())
                            this.symbolsLength = editor.plugins.wordcount.body.getCharacterCount()
                        })
                        editor.on('Change', (e) => {
                            this.$emit('update:modelValue', editor.getContent())
                        })
                    },
                    selector: `#${ this.fieldId }`,
                    menubar: false,
                    statusbar: false,
                    skin: false,
                    content_css: process.env.CDN_URL +  'assets/css/new/wysiwyg.css',
                    branding: false,
                    fix_list_elements: true,
                    plugins: 'autolink link lists autoresize wordcount',
                    toolbar: this.settings?.hideToolbar ? false : 'bold italic | fontsize | removeformat | alignleft aligncenter alignright | bullist numlist | link',
                    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
                    link_context_toolbar: true,
                    autoresize_bottom_margin: 0,
                    height: this.height,
                    min_height: this.minHeight,
                    max_height: this.maxHeight,
                    readonly: !!this.settings?.readonly,
                    browser_spellcheck: true,
                    setup: (editor) => {
                        editor.on('init', () => {
                            this.symbolsLength = editor.plugins.wordcount.body.getCharacterCount()
                        })
                        if (this.maxLength) {
                            editor.on('keydown', (event) => {
                                const allowedKeys = [8, 37, 38, 39, 40, 46] // backspace, delete and cursor keys
                                const  numChars = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount()

                                if (allowedKeys.includes(event.keyCode)) {
                                    return true
                                }

                                if (numChars >= this.maxLength) {
                                    event.preventDefault()
                                    event.stopPropagation()

                                    return false
                                }
                            })
                        }
                    }
                })
            },
            destroyEditor (): void {
                if (this.editor) {
                    this.editor.destroy()
                }
            }
        }
    })
</script>
