export default {
    files: [
        {
            '_id': '64a55d351e395d65d20dafd2',
            'modified_by_mongo_id': '5f97e3b96f91b9161d437834',
            'creator_mongo_id': '5f97e3b96f91b9161d437834',
            'creator_full_name': 'Service Account',
            'company_mongo_id': '5dc054b40bbd0a4461036a43',
            'company_name': 'OWNER REP Staging',
            'project_mongo_id_local': null,
            'project_mongo_id_global': null,
            'folder_mongo_id': null,
            'deleted_at': null,
            'file_name': 'bat.png',
            'file_size': 4812,
            'file_mime_type': 'image\/png',
            'file_storage_uuid': '926007ef-7aff-5e25-a362-5a92a5052273',
            'file_type': null,
            'favorited_by': [],
            'current_version': '926007ef-7aff-5e25-a362-5a92a5052273',
            'annotations': {
                '926007ef-7aff-5e25-a362-5a92a5052273': []
            },
            'shared_from': null,
            'shared_to': null,
            'is_temporary_upload': true,
            'can_be_shared': true,
            'is_shareable': true,
            'is_published': true,
            'document_publish_id': null,
            'versions': {
                '926007ef-7aff-5e25-a362-5a92a5052273': {
                    'file_name': 'bat.png',
                    'file_size': 4812,
                    'file_mime_type': 'image\/png'
                }
            },
            'model_type': 'tasks',
            'model_id': null,
            'section': 'default',
            'section_name': null,
            'section_uuid': null,
            'path': '\/bat.png',
            'updated_at': '2023-07-05T12:08:26.549000Z',
            'created_at': '2023-07-05T12:08:21.812000Z',
            '_id': '64a55d351e395d65d20dafd2',
            'during_updating_time': null,
            'company_attached_employees': {
                '5dc054b40bbd0a4461036a43': [
                    33
                ]
            },
            'attached_employees_mongo_ids': [
                '5f97e3b96f91b9161d437834'
            ],
            'document_public_annotations_mongo_id': '64a55d3a1e395d65d20dafd3',
            'flag': 0,
            'attached_employees_ids': [
                {
                    '_id': 33,
                    'type': 3
                }
            ],
            'invited_mongo_ids': [],
            'created_by': {
                '_id': '5f97e3b96f91b9161d437834',
                'firstname': 'Service',
                'lastname': 'Account',
                'email': 'service+ownrepstg@ingenious.build',
                'sql_employee_id': 33
            },
            'is_new': false,
            'is_read_only': false,
            '_is_on_sync_queue': false,
            '_can_rename': true,
            '_can_delete': true,
            'parent': null,
            'author': {
                '_id': '5f97e3b96f91b9161d437834',
                'firstname': 'Service',
                'lastname': 'Account',
                'email': 'service+ownrepstg@ingenious.build',
                'sql_employee_id': 33
            }
        }
    ]
}
