import { PropType, defineComponent } from 'vue'
import { LabelSize, LabelType } from '@/constants/LabelConstants'
import SingleComment from './parts/single-comment/SingleComment.vue'
import CommentsSort from './interfaces/CommentsSort'
import CommentItem from './interfaces/CommentItem'
import Mentions from '@/components/mentions/mentions.vue'
import TagUserInfo from '@/components/mentions/interfaces/TagUserInfo'
import { mapState } from 'vuex'

export default defineComponent({
    name: 'Comments',
    components: {
        SingleComment,
        Mentions,
    },
    props: {
        canAddComment: { type: Boolean, default: true },
        showLabel: { type: Boolean, default: true },
        comments: { type: Array as PropType<CommentItem[]>, default: null },
        commentField: { type: String, default: 'comment' },
        dateFiled: { type: String, default: 'created_at' },
        userIdField: { type: String, default: 'creator.id' },
        userFirstNameField: { type: String, default: 'creator.first_name' },
        userLastNameField: { type: String, default: 'creator.last_name' },
        noCommentsPlaceholder: { type: String, default: '' },
        canEdit: { type: Boolean, default: false },
        taggableUsers: { type: Array as PropType<TagUserInfo[]>, default: () => [] },
        showCreationTime: { type: Boolean, default: false }
    },
    emits: ['updateComment', 'deleteComment'],
    data () {
        return {
            LabelSize,
            LabelType,
            CommentsSort,
            comment: '',
            sortType: CommentsSort.NewFirst,
        }
    },
    computed: {
        ...mapState('appStore', {
            authData: (state: any) => state.authData
        }),

        userFullName (): string {
            return `${ this.authData.u_firstname } ${ this.authData.u_lastname }`
        },

        filteredComments (): CommentItem[] {
            if (this.comments.length < 2) {
                return this.comments
            }

            if (CommentsSort.NewFirst === this.sortType) {
                return this.comments.sort((a, b) => new Date(b[this.dateFiled]).getTime() - new Date(a[this.dateFiled]).getTime())
            } else {
                return this.comments.sort((a, b) => new Date(a[this.dateFiled]).getTime() - new Date(b[this.dateFiled]).getTime())
            }
        },
    },
    methods: {
        resetMentionsInput (): void {
            this.comment = ''

            this.$nextTick(() => {
                this.$refs.mentionsInput.resetField()
            })
        },

        addComment (): void {
            this.$emit('addComment', {
                comment: this.comment,
            })
            this.resetMentionsInput()
        },
    }
})
