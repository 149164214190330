<template>
    <div class="io-tile-stack io-design-system" :class="`io-tile-stack--${ size }`">
        <ul class="io-tile-stack__list" @click="$emit('stackClick')">
            <li
                v-for="(item, idx) in renderedItems"
                :key="idx"
                class="io-tile-stack__item"
                :style="{ zIndex: hoveredIndexes.has(idx) ? renderedItems.length : renderedItems.length - idx }"
                @mouseover="hoveredIndexes.add(idx)"
                @mouseleave="hoveredIndexes.delete(idx)"
                @click="$emit('itemClick', items.indexOf(item))"
            >
                <Component :is="item" />
            </li>

            <li
                v-if="counter && limit && items.length > limit"
                class="io-tile-stack__item io-tile-stack__item--count"
                @click="$emit('counterClick', remainingCount)"
            >
                +{{ remainingCount }}
            </li>
        </ul>

        <i
            v-if="addable"
            class="io-icon-stitch icon-plus"
            :class="`io-icon-stitch--${ size }`"
            @click="$emit('addClick')"
        />
    </div>
</template>

<script lang="ts" src="./TileStack.ts"></script>
<style scoped lang="scss" src="./TileStack.scss"></style>
