import { defineComponent } from 'vue'
import getCascadeAnimationDelay from '@/helpers/getCascadeAnimationDelay.ts'
import type { DirectionIcon, Status } from '@/components/status-change/interfaces/StatusChange.ts'

export default defineComponent({
    name: 'StatusChange',
    props: {
        statuses: {
            type: Array as () => Status[],
            required: true,
        },
        directionIcon: {
            type: Object as () => DirectionIcon,
            default: () => ({
                class: 'icon-arrow-right',
                size: 'normal',
                color: '--main-lighten-4',
            })
        },
        animate: Boolean,
    },
    setup () {
        return {
            getCascadeAnimationDelay,
        }
    },
    data () {
        return {
            animationKey: Math.random(),
        }
    },
    watch: {
        statuses: {
            handler (): void {
                if (this.animate) {
                    this.animationKey = Math.random()
                }
            },
            deep: true,
        },
    },
})
