import BidCompareByType from '../enums/BidCompareByType'
import bidRequestClient from '@/io-modules/bid-requests/api-clients/bidRequestClient'
import dayjs from 'dayjs'
import defaultState from './bidRequestStoreDefaultState'
import type { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { BidPackageStatus } from '@/io-modules/bid-requests/enums'
import {
    BidRequest,
    BidRequestFilterData,
    BidRequestListItem,
    BidRequestProjectList,
    BidRequestQuestion,
    BidRequestQuotedItem,
    BidRequestResponseData,
    BidRequestRevision,
    BidRequestStoreState,
    BudgetCategory,
    CostCode,
    CostCodeCategory,
    FilterBidRequestPostData,
    LoadListOptions,
    NewResourceCompany,
    Proposal,
    ProposalSection,
    Quote,
    QuoteCallback,
    ResourceCompany,
    BiddersListCompany,
    BiddersListContact,
    BidderCompany,
    } from '../interfaces'
import { cloneDeep, maxBy, minBy } from 'lodash'
import { ContractHolderType } from '@/io-modules/bid-requests/enums/ContractHolderType.ts'
import { defineStore } from 'pinia'
import type SelectOption from '../interfaces/SelectOption'
import type SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument'
import { computeMainCost, computeAlternateCost } from '@/io-modules/bid-requests/utils/computeCostCodes'
import CompanyType from '../enums/CompanyType'
import { BidFormSiteItems } from '@/io-modules/bid-requests/interfaces/BidFormSiteItems.ts'
import BidFormItemsTypes from '@/io-modules/bid-requests/enums/BidFormItemsTypes.ts'

type ClientResponse<T = any> = Partial<AxiosResponse<T>> & {
    error?: AxiosError
}

const updateableFields = [
    '_id',
    'bid_status_id',
    'bid_status_name',
    'bid_managers',
    'bid_managers_details',
    'bid_managers_as_contacts',
    'bid_reminders',
    'bid_walk_through_reminders',
    'bid_intent_to_bid_reminders',
    'bid_contract_type',
    'model_documents',
    'insurance_requirements',
    'is_editable',
    'is_shared',
    'bid_unit_of_measure',
    'bid_sector',
    'bid_address1',
    'bid_address2',
    'bid_state_id',
    'bid_city',
    'bid_zip',
    'bid_country',
    'bid_project_description'
]

export const bidRequestStore = defineStore('bidRequestStore', {
    state: (): BidRequestStoreState => structuredClone(defaultState),
    getters: {
        getBidsLength: (state: BidRequestStoreState): number => state.quotes.length,
        getSortedBids: (state: BidRequestStoreState): Quote[] => {
            const bids = cloneDeep(state.quotes)
            bids.sort((bidA, bidB) => {
                const defaultTotal = 'DESC' === state.bidsOrderBy.type ? -Infinity : Infinity
                const totalA = bidA?.quote?.quote_price ?? defaultTotal
                const totalB = bidB?.quote?.quote_price ?? defaultTotal
                return totalA - totalB
            })

            return 'DESC' === state.bidsOrderBy.type ? bids.reverse() : bids
        },
        getSortedAndFilteredBids (state: BidRequestStoreState): Quote[] {
            if (state.showHiddenBids) {
                return this.getSortedBids
            }

            return this.getSortedBids.filter(bid => !this.hiddenBids.includes(bid._id))
        },
        isPackageContracted: (state: BidRequestStoreState): boolean => state.model && Boolean(state.model.contract_id),
        isPackageDraft: (state: BidRequestStoreState): boolean => Boolean(state.model._id) && BidPackageStatus.Draft === state.model.bid_status_name,
        isBidInProject: (state: BidRequestStoreState): boolean => state.model && Boolean(state.model.bid_project_id),
        canSendToVendors: (state: BidRequestStoreState): boolean => {
            if (!state.model) {
                return false
            }
            // No bidding deadline, allow to throw validations error on next steps
            if (!state.model.bid_bidding_deadline) {
                return true
            }
            return !state.model.is_contract_pending &&
                !state.model.is_contract_executed &&
                dayjs(state.model.bid_bidding_deadline).isAfter(dayjs(new Date()))
        },
        compareByQuotedItemsSelected (state: BidRequestStoreState): boolean {
            return state.bidsComparedBy.type === BidCompareByType.QUOTED_ITEMS
        },
        compareByQASelected (state: BidRequestStoreState): boolean {
            return state.bidsComparedBy.type === BidCompareByType.QA
        },
        getElementsNeedRevision (state: BidRequestStoreState): Array<BidRequestQuotedItem | BidRequestQuestion> {
            // @ts-ignore Hard to fix this editor error because we are merging two arrays of different types.
            return this.getQuotedItemsThatNeedRevision.concat(this.getQuestionsThatNeedRevision)
        },
        getQuotedItemsThatNeedRevision (state: BidRequestStoreState): BidRequestQuotedItem[] {
            if (false === Array.isArray(state.model.bid_quoted_items)) {
                return []
            }

            return state.model.bid_quoted_items.filter(item => item.revision_need)
        },
        getQuestionsThatNeedRevision (state: BidRequestStoreState): BidRequestQuestion[] {
            if (false === Array.isArray(state.model.bid_questions)) {
                return []
            }

            return state.model.bid_questions.filter(item => item.revision_need)
        },
        getRevisionInfoBoxState (state: BidRequestStoreState): boolean {
            return this.getElementsNeedRevision.length > 0 && !state.model.is_shared && !this.isPackageDraft
        },
        getUsedVendorsIds: (state: BidRequestStoreState): Array<string> => {
            const userVendorsIds = []
            state.quotes.forEach(quote => {
                if (quote.to) {
                    userVendorsIds.push(quote.to)
                }

                const companyMongoId = quote?.quote?.company?.res_mongo_id
                if (companyMongoId) {
                    userVendorsIds.push(companyMongoId)
                }

                const companyId = quote?.quote?.company?.res_id
                if (companyId) {
                    userVendorsIds.push(companyId)
                }
            })
            return userVendorsIds
        },
        /** Return proposals received on-system. */
        getSubmittedQuotes: (state: BidRequestStoreState): Array<Quote> => {
            return state.quotes.filter(quote => {
                return Boolean(quote.quote)
                    && false === Boolean(quote.is_internal_estimate)
                    // quote.quote?.price?.main is 0 for manual bids and the internal estimate.
                    && true === Boolean(quote.quote?.price?.main)
            })
        },
        /** Return proposals received on-system and manual bids. */
        getSubmittedAndManualQuotes: (state: BidRequestStoreState): Quote[] => {
            return state.quotes.filter(quote => {
                return Boolean(quote.quote)
                    && false === Boolean(quote.is_internal_estimate)
                    && 0 !== quote.quote.quote_price
            })
        },
        getCardPriceLow (state: BidRequestStoreState): string | null {
            const result = minBy(this.getSubmittedAndManualQuotes, (quote) => {
                return computeMainCost(quote, this.phasesMain) + computeAlternateCost(quote, this.phasesAlternate)
            })
            return result ? result._id : null
        },
        getCardPriceHigh (state: BidRequestStoreState): string | null {
            const result = maxBy(this.getSubmittedAndManualQuotes, (quote) => {
                return computeMainCost(quote, this.phasesMain) + computeAlternateCost(quote, this.phasesAlternate)
            })
            return result ? result._id : null
        },
        getBids: (state: BidRequestStoreState): Array<Quote> => state.quotes,
        getInternalEstimate (state: BidRequestStoreState): Quote | null {
            if (!state.quotes.length) {
                return null
            }
            return state.quotes.find(bid => {
                return bid.is_internal_estimate
            })
        },
        getQuoteRevisions (state: BidRequestStoreState): QuoteCallback  {
            return (quoteId: string): Array<BidRequestRevision> => state.revisions.filter(revision => revision.quote_id === quoteId)
        },
        hasQuotes: (state: BidRequestStoreState): boolean => Boolean(state.quotes) && Boolean(state.quotes.length),
        hiddenBids: (state: BidRequestStoreState): string[] => state?.model?.cards_hidden ?? [],
        phasesMain: (state: BidRequestStoreState): BidRequestQuotedItem[] => {
            if (!state.model || false === Array.isArray(state.model.bid_quoted_items)) {
                return []
            }

            return state.model.bid_quoted_items.filter((phase: BidRequestQuotedItem) => phase.type == 0)
        },

        phasesAlternate: (state: BidRequestStoreState): BidRequestQuotedItem[] => {
            if (!state.model || false === Array.isArray(state.model.bid_quoted_items)) {
                return []
            }

            return state.model.bid_quoted_items.filter((phase: BidRequestQuotedItem) => phase.type == 1)
        },

        allPhases (state: BidRequestStoreState): BidRequestQuotedItem[] {
            return state.model.bid_quoted_items
        },
        allSections (state: BidRequestStoreState): ProposalSection[] {
            const allQuoteItems: Proposal[] = state.quotes.map((item: Quote) => item.quote).filter(Boolean)
            return allQuoteItems.map((item: Proposal) => item.sections).flat()
        },

        getQuoteItemsAdded (): ProposalSection[] {
            const uuidAllPhases: string[] = this.allPhases?.map((item: BidRequestQuotedItem) => item.uuid) || []

            return this.allSections
                .filter((item: ProposalSection) => !!item)
                .filter((item: ProposalSection) => !uuidAllPhases.includes(item.phase))
        },

        getBudgetCategories (state: BidRequestStoreState): Array<BudgetCategory> {
            let categories = []
            if (!state.model || false === Array.isArray(state.model.bid_quoted_items)) {
                return categories
            }

            const costCodesFlatten = this.getCostCodesFlatten
            state.model.bid_quoted_items.forEach(item => {
                if (!item.costCode) {
                    return
                }

                const codeCategory = item?.costCode?.code_category ?? null
                let category: BudgetCategory = categories.find(element => {
                    return element.code_category === codeCategory
                })
                if (!category) {
                    const itemOrigin = costCodesFlatten.find(itemOrigin => {
                        return itemOrigin.code_category === codeCategory
                    })

                    category = {
                        budget: itemOrigin ? itemOrigin.budget : item.costCode.budget,
                        code_category: item.costCode.code_category,
                        code_name: item.costCode.code_name,
                        phases: []
                    }
                    categories.push(category)
                }

                const phaseIndex = category.phases.findIndex(phase => {
                    return phase.uuid === item.uuid
                })
                if (-1 === phaseIndex) {
                    category.phases.push(item)
                }
            })
            return categories
        },
        getCostCodesOptions: (state: BidRequestStoreState): CostCodeCategory[] => {
            const options = []
            if (!state.costCodes) {
                return options
            }
            state.costCodes.forEach(category => {
                const codes = category.codes.filter(code => !code.$isDisabled)
                if (codes.length) {
                    options.push({
                        ...cloneDeep(category),
                        codes
                    })
                }
            })
            return options
        },
        getCostCodesFlatten: (state: BidRequestStoreState): CostCode[] => {
            let options = []
            state.costCodes.forEach((category) => {
                options = [...options, ...category.codes]
            })
            return options
        },
        isPrincipalContractHolder: (state: BidRequestStoreState): boolean => state.model.bid_contract_holder === ContractHolderType.PRINCIPAL,
        hasQuestion: (state: BidRequestStoreState): boolean => {
            return Boolean(state.model.bid_questions) && Boolean(state.model.bid_questions.length)
        },
        isBidRequestEditable: (state: BidRequestStoreState): boolean => {
            if (!state.model._id || state.model.is_editable) {
                return true
            }

            return !Boolean(state.model.is_shared)
                && !Boolean(state.model.is_contract_pending)
                && !Boolean(state.model.is_contract_executed)
        },
        hasBids (): boolean {
            return this.getBidsLength > 0
        },
        isShowModal (state: BidRequestStoreState): boolean {
            return state.showModal
        },
        bidDueDateHasExpired (state: BidRequestStoreState): boolean {
            return state.model.is_expired
        },
        questions (state: BidRequestStoreState): BidRequestQuestion[] {
            return state.model.bid_questions || []
        },
        biddersListWorkspaceDataIds (state: BidRequestStoreState): string[] {
            return state.biddersList?.map((company: BiddersListCompany): string => company.workspace_company_id) || []
        },
        biddersListPersonDataIds (state: BidRequestStoreState): string[] {
            return state.biddersList?.flatMap((company: BiddersListCompany) =>
                company.bidders_list_contacts.map((contact: BiddersListContact) => contact.contact_person_id) || []
            )
        },
        hasContract (state: BidRequestStoreState): boolean {
            return Boolean(state.model.is_contract_pending)
                || Boolean(state.model.is_contract_executed)
        },
        isBidBiddingDeadlineBeforeNow (state: BidRequestStoreState): boolean {
            return state.model.bid_bidding_deadline
                ? dayjs(state.model.bid_bidding_deadline).isBefore(dayjs(new Date()))
                : false
        },
    },
    actions: {
        resetState (): void {
            this.$reset()
        },

        getUnfilledFields (isRep: string): string[] {
            const fields = [
                { name: 'bid_request_name', value: !!this.model?.bid_request_name },
                { name: 'bid_job_start_date', value: !!this.model?.bid_job_start_date },
                { name: 'bid_bidding_deadline', value: !!this.model?.bid_bidding_deadline }
            ]

            const unfilledFields = fields
                .filter(field => !field.value)
                .map(field => field.name)

            if (!this.model?.bid_quoted_items?.length) {
                unfilledFields.push('bid_quoted_items')
            }

            if (isRep && !this.model?.bid_contract_holder) {
                unfilledFields.push('bid_contract_holder')
            }

            if (unfilledFields && unfilledFields.length) {
                this.setUnfilledFields(true)
            } else {
                this.setUnfilledFields(false)
            }

            return unfilledFields
        },

        setUnfilledFields (value: boolean): void {
          this.areUnfilledFields = value
        },

        setScopeWork (data: string): void {
            if (!data) {
                return
            }
            this.model.bid_project_description = data.replace(/\n/g, '<br />')
        },

        changeStatusModal (status: boolean): void {
            this.showModal = status
        },
        setBidPackageSearchString (searchString: string): void {
            this.searchString = searchString
        },
        toggleBidPackageRecipientTypePopup (): void {
            this.recipientTypePopup = !this.recipientTypePopup
        },
        setListLoaded (listLoadedValue: boolean): void {
            this.listLoaded = listLoadedValue
        },
        setQuotes (quotes: Array<Quote>): void {
            this.quotes = quotes
        },
        getQuotes (): Quote[] {
            return this.quotes
        },
        setCompanies (companies: Array<ResourceCompany | NewResourceCompany>): void {
            this.companies = companies
        },
        updateQuote (updatedQuote: Quote): void {
            const quoteIndex = this.quotes.findIndex(quote => {
                return quote._id === updatedQuote._id
            })

            if (quoteIndex > -1) {
                this.quotes[quoteIndex] = updatedQuote
            } else {
                this.quotes.push(updatedQuote)
            }
        },
        setList (list: Array<BidRequestListItem>, initialValue: boolean = false): void {
            this.list = list || []
            if (false === initialValue) {
                this.listLoaded = true
            }
        },
        setRevisionGlobalFlag (value: boolean): void {
            this.revision_global_sending = value
        },
        cleanGlobalRevisions (): void {
            this.getElementsNeedRevision.forEach(item => {
                item['revision_need'] = false
                item['revision_sent'] = true
            })
        },
        setElement (value: { id: string | number, type: string }): void {
            this.elementId = value.id || null
            this.elementType = value.type || null
        },
        setProposedQuotedItem (value: any): void {
            this.proposedItem = value
        },
        setProposedBid (value: any): void {
            this.proposedBid = value
        },
        setManualBid (value: Quote): void {
            this.openedManualBidNew = false
            this.manualBid = cloneDeep(value)
        },
        setModalRevision (newData: any): void {
            for (let property in newData) {
                if (typeof this.modalRevision[property] !== 'undefined') {
                    this.modalRevision[property] = newData[property]
                }
            }
        },
        setPopup (value: any): void {
            if (value && value.type) {
                this.popup[value.type] = value.data
            }
        },
        addManualBid (value: any): void {
            const newBid = cloneDeep(value.bid)
            this.quotes.push(newBid)
            this.openedManualBidNew = true
            setTimeout(() => {
                this.manualBid = cloneDeep(newBid)
            }, 50)
        },
        removeManualBid (bidId: string): void {
            const bidIndex = this.quotes.findIndex(quote => {
                return quote._id === bidId
            })
            if (bidIndex > -1) {
                this.quotes.splice(bidIndex, 1)
            }
        },
        holdBid (bid: Quote): void {
            this.quotes.forEach(quote => {
                if (quote.quote) {
                    quote.quote['quote_holded'] = (quote.to === bid.to) ? 1 : 0
                }
            })
        },
        async bidHide (bidSendsId: string): Promise<boolean> {
            const hide = !this.model.cards_hidden.includes(bidSendsId)
            const postData = {
                id: this.model._id,
                card: bidSendsId,
                hide
            }
            this.hideBid(postData)

            try {
                await bidRequestClient.bidHide(this.model._id, bidSendsId, { hide })
                return true
            } catch (exception) {
                return exception
            }
        },
        hideBid (data: any): void {
            if (false === Array.isArray(this.model.cards_hidden)) {
                this.model['cards_hidden'] = []
            }

            if (data.hide) {
                this.model.cards_hidden.push(data.card)
            } else {
                this.model['cards_hidden'] = this.model.cards_hidden.filter(e => e !== data.card)

                const bid = this.quotes.find(item => {
                    return item._id === data.card
                })
                if (bid) {
                    bid['disqualified'] = false
                }
            }
        },
        async setPreviousBidders (projectId: string): Promise<void> {
            if (true === this.isPreviousBiddersSet) {
                return
            }
            const { data } = await bidRequestClient.getPreviousBidders(projectId)
            this.previousBidders = data?.previous_bidders ?? []
            this.isPreviousBiddersSet = true
        },
        updateModel (modelUpdated: BidRequest): void {
            this.resetModel()
            this.wizardLocationFields = true
            for (let prop in modelUpdated) {
                this.model[prop] = modelUpdated[prop]
            }
            setTimeout(() => { this.wizardLocationFields = false }, 1000)
        },
        setModel (model: BidRequest): void {
            this.model = model
        },
        resetModel (): void {
            for (let prop in this.model) {
                this.model[prop] = null
            }
        },
        updateModelSpecificFields (modelUpdated: BidRequest): void {
            updateableFields.forEach(prop => {
                this.model[prop] = modelUpdated[prop]
            })
         },
        setModelDraftStatus (saveAsDraft: boolean): void {
            this.model['save_as_draft'] = saveAsDraft
        },
        setBidSpecialFields (value: boolean): void {
            this.wizardBidManagers = value
            this.wizardReferencesFiles = value
            this.wizardInsuranceRequirements = value
        },
        setWizardLocation (val: boolean): void {
            this.wizardLocationFields = val
        },
        setQuotedItemsList (categories: Array<any>): void {
            const elements = []
            if (categories.length) {
                categories.forEach(category => {
                    if (!category.items || false === Array.isArray(category.items)) {
                        return false
                    }
                    category.items.forEach(code => {
                        elements.push(code)
                    })
                })
            }
            this.quotedItemsList = elements
        },
        changeBidsOrderBy (value: SelectOption): void {
            this.bidsOrderBy = { ...value }
        },
        changeBidsComparedBy (value: SelectOption): void {
            this.bidsComparedBy = { ...value }
        },
        setQuotedItem (newItem: BidRequestQuotedItem): void {
            let currentIndex = this.model.bid_quoted_items.findIndex(item => {
                return item.uuid === newItem.uuid
            })
            if (currentIndex > -1) {
                this.model.bid_quoted_items[currentIndex] = newItem
            } else {
                this.model.bid_quoted_items.push(newItem)
            }
        },
        setQuestion (newItem: BidRequestQuestion): void {
            let currentIndex = this.model.bid_questions.findIndex(item => {
                return newItem.id && item.id === newItem.id
            })
            if (currentIndex > -1) {
                this.model.bid_questions[currentIndex] = newItem
            } else {
                this.model.bid_questions.push(newItem)
            }
        },
        viewBid (value: any): void {
            this.viewBid = value
        },
        setBidPackage (value: string | null): void {
            if (value !== this.activeBidPackage) {
                this.setQuotedItemsList([])
            }
            this.activeBidPackage = value || null
        },
        refreshKey (value: string | null = null): void {
            if (!value) {
                value = this.activeBidPackage
            }

            this.activeBidPackage = null
            setTimeout(() => {
                this.activeBidPackage = value
            }, 150)
        },
        async loadList (options?: LoadListOptions, filterData?: FilterBidRequestPostData): Promise<void> {
            if (!options) {
                options = {
                    id: this.elementId,
                    type: this.elementType
                }
            }

            let promise: AxiosPromise<any | BidRequestProjectList>

            if ('proposal' === options.type) {
                promise = bidRequestClient.loadListProposal(options.id.toString(), filterData)
            } else {
                promise = bidRequestClient.loadListProject(options.id, filterData)
            }

            await promise.then(response => {
                if (response && response.data) {
                    this.setList(response.data.feed)
                    this.setFiltersData(response.data.filters_data)
                }
            })
        },

        setFiltersData (filterData: BidRequestFilterData): void {
            this.filtersData.managers = filterData.managers ?? []
            this.filtersData.bid_request_to = filterData.bid_request_to ?? []
            this.filtersData.statuses = filterData.statuses ?? []
            this.filtersData.quote_awarded_to = filterData.quote_awarded_to ?? []
        },

        setFiltersPostData (filtersPostData: FilterBidRequestPostData): void {
            this.filtersPostData = filtersPostData
        },

        tabLastSet (value: string | null): void {
            this.tabLast = value
        },
        tabActiveSet (value: string | null): void {
            this.tabActive = value
        },
        tabActiveNameSet (value: string | null): void {
            this.tabActiveName = value
        },
        setActiveFilesTab (value: string | null): void {
            this.activeFilesTab = value
        },
        setDiscussionBid (value: string | null): void {
            this.discussion_bid = value
        },
        async getBidRequest (bidId: string | null): Promise<ClientResponse<BidRequestResponseData>> {
            try {
                const res = await bidRequestClient.getBidRequest(bidId)
                this.model = res.data.model
                this.quotes = res.data.quotes
                this.hasSites = res.data.has_sites
                this.revisions = res.data.revisions
                this.timelineEvents = res.data.timeline_events
                this.biddersList = res.data.bidders_list

                this.areBidsLoaded = true
                return res
            } catch (error) {
                return { error }
            }
        },
        async getCompaniesForBidRequest (bidId: string | null): Promise<boolean> {
            try {
                const { data } = await bidRequestClient.getCompaniesForBid(bidId)
                this.companies = data.feed
                return true
            } catch (exception) {
                return exception
            }
        },
        async getContacts (): Promise<boolean> {
            try {
                const { data } = await bidRequestClient.getContacts()
                this.contacts = data.list
                return true
            } catch (exception) {
                return exception
            }
        },
        setDefaultBiddersFilters (): void {
            this.biddersFilters['searchKeyword'] = ''
            this.biddersFilters['selectedCountry'] = null
            this.biddersFilters['selectedDivisions'] = []
            this.biddersFilters['selectedCities'] = []
            this.biddersFilters['selectedStates'] = []
            this.biddersFilters['localContacts'] = true
            this.biddersFilters['globalContacts'] = true
            this.biddersFilters['sort'] = null
            this.biddersFilters['source'] = CompanyType.WORKSPACE_COMPANY
            this.biddersFilters['selectedAppTypes'] = []
        },
        setBidReminder (data: any, field: string = 'bid_reminders'): void {
            const currentIndex = this.model[field].findIndex(item => {
                return item._id === data._id
            })

            if (currentIndex > -1) {
                this.model[field][currentIndex] = data
            } else {
                this.model[field].push(data)
            }
        },
        removeBidReminder (bidReminderId: string): void {
            const bidReminderIndex = this.model.bid_reminders.findIndex(item => {
                return item.id === bidReminderId
            })

            if (bidReminderIndex > -1) {
                this.model.bid_reminders.splice(bidReminderIndex, 1)
            }
        },
        removeAllBidReminder (): void {
            this.model.bid_reminders = []
        },
        updateManagers (value: any): void {
            this.model.bid_managers = value
        },
        toggleShowHiddenBids (): void {
            this.showHiddenBids = !this.showHiddenBids
        },

        changeAlternateItemStatus (index: number, uuid: string): void {
            const toggleAlternate = (items: BidFormSiteItems[]) => {
                items.find(elem => {
                    if (elem.budget_cost_codes?.length) {
                        const alternate = elem.budget_cost_codes.find(item => {
                            return item.section_uuid === uuid
                        })

                        if (alternate) {
                            alternate.enabled = !alternate.enabled
                        }
                    } else if (elem.sub_categories?.length) {
                        toggleAlternate(elem.sub_categories)
                    }
                })
            }

            if (uuid) {
                toggleAlternate(this.sitesPhasesAlternate)
                const toggled = this.phasesAlternate.find(elem => elem.uuid === uuid)
                if (toggled) {
                    toggled.enabled = !toggled.enabled
                }
            } else {
                this.phasesAlternate[index].enabled = !this.phasesAlternate[index].enabled
            }
        },

        setBidManual (id: string): void {
            const quote: Proposal = {
                _id: crypto.randomUUID() as string,
                quote_bid_id: id,
                sections: [],
                quote_price: 0,
                answers: [],
                _is_new : true,
            } as Proposal
            const bid: Quote = {
                quote: quote,
            } as Quote
            const files = {
                model_documents: {
                    files: [],
                }
            }
            Object.assign(bid.quote, files)
            this.manualBid = bid
        },

        deleteBidManual (): void {
                this.manualBid = null
        },

        setInternalEstimate (id: string): void {
            const quote: Proposal = {
                _id: '',
                quote_bid_id: id,
                quote_name: 'Internal Estimate',
                sections: [],
                quote_price: 0,
                is_internal_estimate: true
            } as Proposal
            const bid: Quote = {
                bid: id,
                is_manual: true,
                is_internal_estimate: true,
                quote: quote,
            } as Quote
            const files = {
                model_documents: {
                    files: [],
                }
            }
            Object.assign(bid.quote, files)
            this.manualBid = bid
        },

        getIndexQuoteById (id: string): number {
            return this.quotes.findIndex((quote: Quote) => id === quote._id)
        },

        initListMarkedReviseQuoteItem (bidId: string, markedForRevision: string[]): void {
            const index = this.getIndexQuoteById(bidId)
            if (-1 === index) {
                return
            }
            this.quotes[index]['markedForRevision'] = markedForRevision
            this.quotes[index]['markedRevise'] = []
        },

        toggleMarkReviseQuoteItem (bidId: string, uuid: string): void {
            const index = this.getIndexQuoteById(bidId)
            if (this.quotes[index].markedRevise.includes(uuid)) {
                this.quotes[index].markedRevise = this.quotes[index].markedRevise.filter(item => item !== uuid)
                return
            }
            this.quotes[index].markedRevise = [...this.quotes[index].markedRevise, uuid]
        },

        isQuoteMarkedRevise (bidId: string, uuid: string): boolean {
            const index = this.getIndexQuoteById(bidId)
            if (-1 === index) {
                return false
            }
            return this.quotes[index].markedRevise.includes(uuid)
        },

        existQuoteMarkedToRevise (bidId: string): boolean {
            const index = this.getIndexQuoteById(bidId)
            if(!this.quotes[index]?.markedRevise) {
                return false
            }
            return !!this.quotes[index].markedRevise.length
        },

        async updateBidPackage (): Promise<void> {
            try {
                await bidRequestClient.updateBidRequest(this.model._id, this.model)
            } catch (error) {
                return error
            }
        },

        setDocument (document: SingleModelDocument): void {
            this.model.model_documents.files.push(document)
        },

        removeDocument (documentId: string): void {
            const index = this.model.model_documents.files
            .findIndex((document: SingleModelDocument) => document._id === documentId)

            if (-1 !== index) {
                this.model.model_documents.files.splice(index, 1)
            }
        },

        /** This is helpful to update the quoted items of the model in the store before updating the bid package. */
        setQuotedItemsThatNeedRevision (quotedItems: BidRequestQuotedItem[], reason: string): void {
            quotedItems.forEach((quotedItem: BidRequestQuotedItem) => {
                quotedItem.revision_need = true

                quotedItem.revision_comment = reason

                this.setQuotedItem(quotedItem)
            })
        },

        /** This is helpful to update the questions of the model in the store before updating the bid package. */
        setQuestionsThatNeedRevision (questions: BidRequestQuestion[], reason: string): void {
            questions.forEach((question: BidRequestQuestion) => {
                question.revision_need = true

                question.revision_comment = reason

                this.setQuestion(question)
            })
        },

        clearResources (): void {
            this.resources = []
        },

        addResource (item: BidderCompany): void {
            this.resources.push(item)
        },

        manageInviteBiddersModal (value: boolean): void {
            this.openInviteBidderModal = value
        },

        setBidItemsTypes (sitesPhasesMain: BidFormSiteItems[], sitesPhasesAlternate: BidFormSiteItems[], sitesPhasesProposed: BidFormSiteItems[] = []): void {
            this.sitesPhasesMain = sitesPhasesMain
            this.sitesPhasesAlternate = sitesPhasesAlternate
            this.sitesPhasesProposed = sitesPhasesProposed
        },

        setHiddenLinesIds (ids: string[], itemType: BidFormItemsTypes): void {
            switch (itemType) {
                case BidFormItemsTypes.Main:
                    this.closedMain = ids
                    break
                case BidFormItemsTypes.Alternate:
                    this.closedAlternates = ids
                    break
                case BidFormItemsTypes.Proposed:
                    this.closedProposed = ids
            }
        }
    }
})
