<template>
    <div
        ref="commentsWrapper"
        class="io-right-panel-inner"
        :class="{ 'io-white-background': !comments.length && !isAddingAnnotation }"
    >
        <div class="io-right-panel-inner__content">
            <AddComment
                v-if="isAddingAnnotation"
                :selected-annotation="selectedAnnotation"
                :model="model"
                @cancel-annotation="cancelAnnotation"
                @scroll-to-comment="onScrollToComment"
                @scroll-to-comment-delay="onScrollToCommentDelay"
            />

            <IOPlaceholder
                v-else-if="!comments.length"
                :subtitle="$t('There are no Comments on this drawing yet. Click Add Comment to add one.')"
                icon="icon-comment-2"
                class="bg-white"
            />

            <div v-if="comments.length" v-click-outside="annotationDeselected">
                <SingleComment
                    v-for="comment in comments"
                    :key="comment.id"
                    :data="comment"
                    :is-resolved="isResolved"
                    @annotation-deleted="annotationDeleted"
                    @scroll-to-comment="onScrollToComment"
                    @scroll-to-comment-delay="onScrollToCommentDelay"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./Comments.ts"></script>
<style lang="scss" src="./Comments.scss" scoped></style>
