export enum ContractRouteName {
    ProjectContractsList = 'project-contracts-v3',
    ContractCreate = 'contract-v3-create',
    ContractDocuments = 'contract-v3-documents',
    ContractDetails = 'contract-v3-details',
    ContractEdit = 'contract-v3-edit',
    ProjectBidRequestDetails = 'project-bid-requests-view-details',
}

export default ContractRouteName
