<template>
    <div class="io-funding-attach-modal">
        <IOButton v-if="!filesIds.length" size="small" variant="link" icon="icon-paperclip" @click="toggleUploader">
            {{ $t('Attach File') }}
        </IOButton>
        <IOButton v-else variant="link" size="small" @click="toggleUploader">
            {{ `${filesIds.length} ${$t(`${filesIds.length === 1 ? 'File' : 'Files'}`)}` }}
        </IOButton>

        <IOModal
            v-if="showUploader"
            icon="icon-paperclip-rec"
            :title="$t('Attach File')"
            :z-index="49"
            @close="toggleUploader"
        >
            <template #default>
                <div class="io-funding-attach-modal__uploader">
                    <FilesComponent
                        :key="rowId"
                        :array-id="rowId"
                        :showShared="false"
                        :forceHideSharedTab="true"
                        :showUploader="true"
                        :hideShareColumn="true"
                        model-type="funding_sources_project_funds"
                        :addToTemporary="true"
                        :showModalVersion="true"
                        @newFileAdded="onFileAdd"
                        @tempFileRemove="onFileRemove"
                    />
                </div>
            </template>
            <template #footer>
                <IOButton variant="secondary" @click="toggleUploader">
                    {{ $t('Cancel') }}
                </IOButton>
                <IOButton :disabled="!filesIds.length" @click="sendFilesIds">
                    {{ $t('Save') }}
                </IOButton>
            </template>
        </IOModal>
    </div>
</template>

<script lang="ts" src="./AttachFileModal.ts"></script>
<style lang="scss" src="./AttachFileModal.scss" scoped></style>
