import { defineComponent } from 'vue'
// @ts-ignore
import InviteList from '@/layouts/offcanvas/invite-list.vue'
// @ts-ignore
import todoClient from '@/api/todo/todoClient'
import { get } from 'lodash'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    components: {
        InviteList
    },
    props: {
        data: { type: Object, required: true },
        column: { type: Object as () => ColumnInterface, required: false, default: null }
    },
    computed: {
        todoApiClient (): any {
            return todoClient
        },
        getAdditionalFunction (): (arg: string) => boolean | null {
            const additionalHTMLFunction = get(this.column, ['additional', 'additionalHTMLFunction'], null)

            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.data)
            }

            return null
        },
        tooltipValue (): string {
            const tooltipField = get(this.column, ['additional', 'tooltip'], null)
            if (typeof tooltipField === 'function') {
                return tooltipField(this.data)
            }
            return ''
        },
    }
})
