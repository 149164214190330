import { defineComponent } from 'vue'
import EditPopup from './edit-popup/EditPopup.vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    components: {
        EditPopup,
    },
    props: {
        isFeatureFlagEnabled: {
            type: Boolean,
            default: true,
        },
        isArchivedVersion: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            editPopupShown: false,
        }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
        }),
        selectedVersionLabel (): string {
            const selectedVersion = this.model.drawing.versions.find(version => version.id === this.model.drawing.current_version_id)
            return `${ this.$t('Version') } ${ selectedVersion.version } - ${ this.model.drawing_set_name }`
        },
        isNewestVersion (): boolean {
            return this.model.drawing.current_version_id === this.$route.params.versionId
        },
        canEdit (): boolean {
            return this.isNewestVersion && !this.isArchivedVersion
        },
    },
    methods: {
        openEditPopup (): void {
            this.editPopupShown = true
        },
        closeEditPopup (): void {
            this.editPopupShown = false
        },
        close (): void {
            this.$emit('close')
        },
        goToHistoryTab (): void {
            this.$emit('change-panel-content', 'history')
        },
    },
})
