
export function TOGGLE_EXPAND_ALL (state, obj) {
    if (obj.expandAll) {
        obj.allLocationsID.forEach(item => {
            if (!state.expandedLocations.includes(item)) {
                state.expandedLocations.push(item)
            }
        })
    } else {
        state.expandedLocations = []
    }
}

export function COLLAPSE_CHILDREN (state, childrenIds) {
    childrenIds.forEach(childId => {
        const index = state.expandedLocations.findIndex(id => id === childId)

        if (index !== -1) {
            state.expandedLocations.splice(index, 1)
        }
    })
}

export function TOGGLE_EXPANDED_LOCATION (state, location) {
    const index = state.expandedLocations.findIndex(id => id === location.id)
    if (index === -1) {
        state.expandedLocations.push(location.id)
    } else {
        state.expandedLocations.splice(index, 1)
    }
}

export function REMOVE_EXPANDED_LOCATION (state, locationID) {
    const index = state.expandedLocations.findIndex(id => id === locationID)
    if (index !== -1) {
        state.expandedLocations.splice(index, 1)
    }
}

export function ADD_EXPANDED_LOCATION (state, location) {
    const index = state.expandedLocations.findIndex(id => id === location.id)
    if (index === -1) {
        state.expandedLocations.push(location.id)
    }
}

export function TOGGLE_SHOW_ARCHIVED (state, status) {
    state.showArchived = status
}

export function SET_ARCHIVED_LOCATION_IDS (state, locations) {
    state.archivedLocations = locations
}

export function REMOVE_ARCHIVE_LOCATIONS (state, locationIds) {
    locationIds.forEach(locationID => {
        const index = state.archivedLocations.findIndex(id => id === locationID)

        if (index !== -1) {
            state.archivedLocations.splice(index, 1)
        }
    })
}

export function ADD_ARCHIVE_LOCATIONS (state, locationIds) {
    locationIds.forEach(locationID => {
        const index = state.archivedLocations.findIndex(id => id === locationID)

        if (index === -1) {
            state.archivedLocations.push(locationID)
        }
    })
}

export function SET_ALL_LOCATIONS (state, locations) {
    state.allLocations = locations
}

export function SET_PARENT_LOCATION (state, location) {
    state.allLocations.push(location)
}

export function REMOVE_PARENT_LOCATION (state, location) {
    const index = state.allLocations.findIndex(item => item?.tempId === location.tempId)

    if (index !== -1) {
        state.allLocations.splice(index, 1)
    }
}

export function SET_SEARCH_VALUE (state, searchValue) {
    state.searchValue = searchValue
}

export function SET_EXPAND_ALL (state, value) {
    state.expandAll = value
}

export function ADD_FOUND_LOCATION (state, locationID) {
    if (state.foundLocations.includes(locationID)) {
        return
    }

    state.foundLocations.push(locationID)
}

export function RESET_FOUND_LOCATIONS (state) {
    state.foundLocations = []
}

export function TOGGLE_SHOW_IMPORT_POPUP (state) {
    state.showImportPopup = !state.showImportPopup
}

export function TOGGLE_SELECTED_LOCATIONS (state, value) {
    const rowIndex = state.selectedLocations.findIndex(row => row === value)
    if (rowIndex === -1) {
        state.selectedLocations.push(value)
    } else {
        state.selectedLocations.splice(rowIndex, 1)
    }
}

export function REMOVE_SELECTED_LOCATION (state, value) {
    const rowIndex = state.selectedLocations.findIndex(row => row === value)
    if (rowIndex !== -1) {
        state.selectedLocations.splice(rowIndex, 1)
    }
}

export function ADD_SELECTED_LOCATION (state, value) {
    const rowIndex = state.selectedLocations.findIndex(row => row === value)
    if (rowIndex === -1) {
        state.selectedLocations.push(value)
    }
}

export function REMOVE_SELECTED_LOCATIONS (state, value) {
    value.forEach((locationId) => {
        const rowIndex = state.selectedLocations.findIndex(row => row === locationId)
        if (rowIndex !== -1) {
            state.selectedLocations.splice(rowIndex, 1)
        }
    })
}

export function ADD_SELECTED_LOCATIONS (state, value) {
    value.forEach((locationId) => {
        const rowIndex = state.selectedLocations.findIndex(row => row === locationId)
        if (rowIndex === -1) {
            state.selectedLocations.push(locationId)
        }
    })
}

export function SET_SELECTED_LOCATIONS (state, data) {
    state.selectedLocations = data
}

export function SET_SELECTED_TARGET_LOCATIONS (state, data) {
    state.selectedTargetLocations = data
}
