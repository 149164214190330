import { defineComponent, PropType, nextTick } from 'vue'
import { mapState } from 'pinia'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import CellType from '@/components/table/CellType'
import ColumnGroup from '@/components/expandable-table/interfaces/ColumnsGroup'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
import { TranslateResult } from 'vue-i18n'
import { debounce } from 'lodash'
import InvoiceApprovalProcessMixin from '@/mixins/invoices/InvoiceApprovalProcessMixin'
import { invoiceSoVStore } from '../../stores/invoiceSoV'
import { invoiceViewStore } from '../../stores/invoiceView'
import ReviseResubmitMixin from './ReviseResubmitMixin'
import SoVTableTotalsMixin from './SoVTableTotalsMixin'
import NetValuesWorkCOmpleteMixin from './NetValuesWorkCompleteMixin'
import SoVLineItem from '../../interfaces/SoVLineItem'

export default defineComponent({
    props: {
        phases: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        onlyFooter: {
            type: Boolean,
            default: false
        },
    },
    mixins: [
        isLoggedMixin,
        InvoiceApprovalProcessMixin,
        ReviseResubmitMixin,
        SoVTableTotalsMixin,
        NetValuesWorkCOmpleteMixin,
    ],
    data () {
        return {
            initItems: [],
        }
    },
    computed: {
        ...mapState(invoiceSoVStore, [
            'columnsViewMode',
            'tableViewMode',
            'visibleLinesFilter',
        ]),

        ...mapState(invoiceViewStore, [
            'isEditingMode',
            'isMyInvoice',
            'clientRequestedRevision',
            'invoiceIsDraft',
            'isRetentionInvoice',
            'hasAccountingIntegration',
            'canAddressRevision'
        ]),

        maxCompletedPercent (): number {
            return this.toInt(100)
        },

        footerDescription (): string {
            return (SoVTableViewMode.COsInSeparateCategory === this.tableViewMode
                && !this.onlyFooter
                && this.hasExecutedCOs
                ? this.$t('Subtotal')
                : this.$t('Total')
            )
        },

        basicColumnsView (): boolean {
            return SoVTableViewMode.BasicColumns === this.columnsViewMode
        },

        allColumnsView (): boolean {
            return SoVTableViewMode.AllColumns === this.columnsViewMode
        },

        tableGroups (): ColumnGroup[] {
            return [
                {
                    id: 'main',
                    name: this.$t('Contract Summary'),
                    classes: 'io-fixed-column io-fixed-column--last',
                    columns: [
                        {
                            name: this.$t('Description'),
                            customValue: (item: SoVLineItem): string | object => {
                                return item.name
                            },
                            valueType: CellType.Text,
                            classes: 'io-fixed-column io-align-left',
                            visible: true,
                            extraStyles: 'min-width: 380px; width: 380px;',
                            fixed: true,
                            footerValue: this.footerDescription,
                            onInit: (item: SoVLineItem, levelPrefix: string): void => this.onInit(item, levelPrefix),
                            injectValue: (item: SoVLineItem): string => {
                                return item.payapp_invoice_id || ''
                            },
                            link: (item: SoVLineItem): object | boolean => {
                                return item.link
                            },
                            leftIcons: (item: object): Array<string> => {
                                return []
                            },
                            rightIcons: (item: object): Array<string> => {
                                return []
                            },
                            editable: () => {
                                return false
                            },
                            showLevelPrefix: (item: SoVLineItem): boolean => {
                                return item.contract_wbs_id && !item.contract_wbs_id.includes('original-scope')
                            },
                            customLevelPrefix: (itemIndex: number, parentItem: SoVLineItem, parentLevelIndex: string): string => {
                                if (parentItem && parentItem.contract_wbs_id && parentItem.contract_wbs_id.includes('original-scope')) {
                                    const levels = parentLevelIndex.split('.')
                                    levels.pop()
                                    return `${ levels.join('.') }.${ itemIndex + 1 } - `
                                }

                                return ''
                            },
                        },
                        {
                            name: this.$t('Scheduled Value'),
                            customValue: (item: SoVLineItem): string | number => {
                                return this.getSumOfTasksValues(item, 'scheduled_value', 'formatProjectCurrencyInt')
                            },
                            classes: this.scheduleValueColumnClasses,
                            visible: true,
                            fixed: true,
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalScheduleValue),
                            editable: () => {
                                return false
                            },
                        },
                        {
                            ...this.actionsColumn,
                            visible: this.requestRevisionActionsColumnVisible,
                            editable: (): boolean => {
                                return false
                            },
                        }
                    ],
                },
                {
                    id: 'gross',
                    name: this.$t('Gross Work Complete'),
                    columns: [
                        {
                            name: this.$t('Previous Applications'),
                            field: 'previous_applications',
                            visible: true,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-purple-2) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.getSumOfTasksValues(item, 'previous_applications', 'formatProjectCurrencyInt')
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalPreviousApplication),
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: this.$t('% of Completed Work'),
                            field: 'percent_of_completed_work',
                            valueType: CellType.InputNumInt,
                            adjust: {
                                showPercentage: true
                            },
                            visible: true,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-purple-2) !important;',
                            customValue: (item: SoVLineItem): string => {
                                return this.$filters.formatPercentInt(item.percent_of_completed_work)
                            },
                            footerValue: '',
                            editable: (item: SoVLineItem): boolean => {
                                return this.isEditableGrossSectionItem(item, 'percent_of_completed_work')
                            },
                            placeholder: '0',
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                item.percent_of_completed_work = value
                                this.updateCurrentApplication(item, value)
                                this.updateRetention(item)
                                if (this.hasSubtasks(item)) {
                                    item.children.forEach(subTask => {
                                        this.updateNestedItems(subTask, field, item.percent_of_completed_work)
                                    })
                                }
                                this.onFieldChanged(item, field)
                            }, 500),
                            minValue: 0,
                            maxValue: 100,
                            sign: '%',
                            inputOptions: {
                                numeralPositiveOnly: true
                            }
                        },
                        {
                            name: '',
                            field: '',
                            valueType: CellType.Text,
                            classes: 'io-additional-action-column io-no-padding',
                            hasPostfixAction: (item: SoVLineItem): boolean => {
                                return this.isEditableGrossSectionItem(item, 'percent_of_completed_work')
                            },
                            postfixAction: (item: SoVLineItem): object => {
                                return {
                                    type: 'button',
                                    variant: 'link',
                                    text: this.$t('Apply to All'),
                                    onClick: (item, header) => {
                                        this.phases.forEach(qi => {
                                            qi.percent_of_completed_work = item.percent_of_completed_work

                                            qi.children?.forEach(phase => {
                                                this.updateNestedItems(phase, 'percent_of_completed_work', item.percent_of_completed_work)
                                            })
                                        })
                                    },
                                }
                            },
                            editable: (item: SoVLineItem): boolean => {
                                return false
                            },
                            visible: this.invoiceIsDraft && this.isEditingMode,
                            headerStyles: 'border-bottom: 1px solid var(--budget-purple-2) !important;',
                            customValue: (item: SoVLineItem): string => {
                                return ''
                            },
                            footerValue: '',
                        },
                        {
                            name: this.$t('Current Application'),
                            field: 'current_application',
                            valueType: CellType.InputNumInt,
                            adjust: {
                                showPercentage: true
                            },
                            visible: true,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-purple-2) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.getSumOfTasksValues(item, 'current_application', 'formatProjectCurrencyInt')
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalCurrentApplication),
                            editable: (item: SoVLineItem): boolean => {
                                return this.isEditableGrossSectionItem(item)
                            },
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                item.current_application = value
                                this.initTaskPercents(item)
                                this.updateRetention(item)
                                this.onFieldChanged(item, 'percent_of_completed_work')
                            }, 500),
                            minValue: (item: SoVLineItem): number => {
                                return this.minAmountToCompleteCurrentApplication(item)
                            },
                            maxValue: (item: SoVLineItem): number => {
                                return this.maxAmountToCompleteCurrentApplication(item)
                            },
                        },
                        {
                            name: this.$t('Material Stored Offsite'),
                            field: 'materials_stored',
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-purple-2) !important;',
                            valueType: CellType.InputNumInt,
                            customValue: (item: SoVLineItem): string | number => {
                                return this.getSumOfTasksValues(item, 'materials_stored', 'formatProjectCurrencyInt')
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalMaterialStoredOffsite),
                            editable: (item: SoVLineItem): boolean => {
                                return this.isEditableGrossSectionItem(item)
                            },
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                if (item.materials_stored !== value) {
                                    item.materials_stored = value
                                    this.initTaskPercents(item)
                                    this.updateRetention(item)
                                    this.onFieldChanged(item, 'percent_of_completed_work')
                                }
                            }, 500),
                            minValue: (item: SoVLineItem): number => {
                                return this.minAmountToCompleteMaterialsOffsite(item)
                            },
                            maxValue: (item: SoVLineItem): number => {
                                return this.maxAmountToCompleteMaterialsOffsite(item)
                            },
                        },
                        {
                            name: `${ this.$t('Total Completed & Stored Amount') } (${ this.getWorkspaceCurrencySymbol() })`,
                            field: 'total_completed_stored_amount',
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-purple-2) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatProjectCurrencyInt(this.totalCompletedStoredAmount(item))
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalCompletedAndStoredAmount),
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: `${ this.$t('Total Completed & Stored Percent') } (%)`,
                            field: 'total_completed_stored_percent',
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-purple-2) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatPercentInt(this.totalCompletedStoredPercent(item))
                            },
                            footerValue: '',
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: this.$t('Balance to Complete'),
                            field: 'balance_to_complete',
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-purple-2) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatProjectCurrencyInt(this.balanceToComplete(item))
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalBalanceToComplete),
                            editable: (): boolean => {
                                return false
                            },
                        },
                    ]
                },
                {
                    id: 'retention',
                    name: this.$t('Retention Summary'),
                    columns: [
                        {
                            name: `${ this.$t('Previous Retention Amount') } (${ this.getWorkspaceCurrencySymbol() })`,
                            field: 'previous_retention_amount',
                            visible: this.allColumnsView,
                            classes: 'io-border-left io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.getSumOfTasksValues(item, 'previous_retention_amount', 'formatProjectCurrencyInt')
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalPreviousRetentionAmount),
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: `${ this.$t('Previous Retention Percent') } (%)`,
                            field: 'previous_retention_percent',
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatPercentInt(this.previousRetentionPercent(item))
                            },
                            footerValue: '',
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: `${ this.$t('Current Retention Amount') } (${ this.getWorkspaceCurrencySymbol() })`,
                            valueType: CellType.InputNumInt,
                            field: 'current_retention_amount',
                            visible: true,
                            classes: this.allColumnsView ? 'io-align-right' : 'io-border-left io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.getSumOfTasksValues(item, 'current_retention_amount', 'formatProjectCurrencyInt')
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalCurrentRetentionAmount),
                            editable: (item: SoVLineItem): boolean => {
                                return this.allColumnsView && this.isEditable(item)
                            },
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                item[field] = value
                                this.updateRetention(item, true, true)
                                this.onFieldChanged(item, 'current_retention_percent')
                            }, 500),
                            minValue: (item: SoVLineItem): number => {
                                if (-1 === Math.sign(item.current_application)) {
                                    return item.current_application / process.env.SCALE_FACTOR
                                } else {
                                    return 0
                                }
                            },
                            maxValue: (item: SoVLineItem): number => {
                                if (-1 === Math.sign(item.current_application)) {
                                    return 0
                                } else {
                                    return item.current_application / process.env.SCALE_FACTOR
                                }
                            }
                        },
                        {
                            name: `${ this.$t('Current Retention Percent') } (%)`,
                            valueType: CellType.InputNumInt,
                            field: 'current_retention_percent',
                            visible: true,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatPercentInt(item.current_retention_percent)
                            },
                            footerValue: '',
                            editable: (item: SoVLineItem): boolean => {
                                return this.allColumnsView && this.isEditable(item)
                            },
                            sign: '%',
                            minValue: 0,
                            maxValue: 100,
                            headerInfo: this.isEditingMode ? this.$t('Adjust the current retention to update retention rules for current and future applications.') : null,
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                item[field] = value
                                this.updateRetention(item)
                                this.onFieldChanged(item, 'current_retention_percent')
                            }, 500),
                        },
                        {
                            name: '',
                            field: '',
                            valueType: CellType.Text,
                            classes: 'io-additional-action-column io-no-padding',
                            hasPostfixAction: (item: SoVLineItem): boolean => {
                                return this.isEditableRetentionSectionItem(item) && this.allColumnsView
                            },
                            postfixAction: (item: SoVLineItem): object => {
                                return {
                                    type: 'button',
                                    variant: 'link',
                                    text: this.$t('Apply to All'),
                                    onClick: (item, header) => {
                                        this.phases.forEach(qi => {
                                            qi.current_retention_percent = item.current_retention_percent
                                            qi.children?.forEach(phase => {
                                                this.updateNestedItems(phase, 'current_retention_percent', item.current_retention_percent)
                                            })
                                        })
                                    },
                                }
                            },
                            editable: (item: SoVLineItem): boolean => {
                                return false
                            },
                            visible: this.invoiceIsDraft && this.isEditingMode && this.allColumnsView,
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string => {
                                return ''
                            },
                            footerValue: '',
                        },
                        {
                            name: `${ this.$t('Current Material Stored Retention Amount') } (${ this.getWorkspaceCurrencySymbol() })`,
                            field: 'materials_stored_retention_amount',
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string => {
                                return this.getSumOfTasksValues(item, 'materials_stored_retention_amount', 'formatProjectCurrencyInt')
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalMaterialStoreRetentionAmount),
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                item[field] = value
                                this.updateRetention(item, true, false)
                                this.onFieldChanged(item, 'materials_stored_retention_amount')
                            }, 500),
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: `${ this.$t('Current Material Stored Retention Percent') } (%)`,
                            valueType: CellType.InputNumInt,
                            visible: this.allColumnsView,
                            field: 'materials_stored_retention',
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            footerValue: '',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatPercentInt(item.materials_stored_retention || 0)
                            },
                            editable: (item: SoVLineItem): boolean => {
                                return this.isEditable(item)
                            },
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                item[field] = value
                                this.updateRetention(item)
                                this.onFieldChanged(item, 'materials_stored_retention')
                            }, 500),
                            sign: '%',
                        },
                        {
                            name: '',
                            field: '',
                            valueType: CellType.Text,
                            classes: 'io-additional-action-column io-no-padding',
                            hasPostfixAction: (item: SoVLineItem): boolean => {
                                return this.isEditableRetentionSectionItem(item) && this.allColumnsView
                            },
                            postfixAction: (item: SoVLineItem): object => {
                                return {
                                    type: 'button',
                                    variant: 'link',
                                    text: this.$t('Apply to All'),
                                    onClick: (item, header) => {
                                        this.phases.forEach(qi => {
                                            qi.materials_stored_retention = item.materials_stored_retention
                                            qi.children?.forEach(phase => {
                                                this.updateNestedItems(phase, 'materials_stored_retention', item.materials_stored_retention)
                                            })
                                        })
                                    },
                                }
                            },
                            editable: (item: SoVLineItem): boolean => {
                                return false
                            },
                            visible: this.invoiceIsDraft && this.isEditingMode && this.allColumnsView,
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string => {
                                return ''
                            },
                            footerValue: '',
                        },
                        {
                            name: `${ this.$t('Total Retention Amount') } (${ this.getWorkspaceCurrencySymbol() })`,
                            field: 'total_retention_amount',
                            valueType: CellType.InputNumInt,
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.getSumOfTasksValues(item, 'total_retention_amount', 'formatProjectCurrencyInt')
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalRetentionAmount),
                            editable: (item: SoVLineItem): boolean => {
                                return  this.isEditableRetentionSectionItem(item)
                            },
                            headerInfo: this.isEditingMode ? this.$t('Release retention on the current application by adjusting the total retention columns.') : null,
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                const maxValue = this.maxRetentionTotal(item)
                                item.total_retention_amount = value
                                item.total_retention_percent = this.intGetPercent(item.total_retention_amount, this.totalCompletedStoredAmount(item))
                                item.retention_released = maxValue - item.total_retention_amount
                            }, 500),
                            minValue: (item: SoVLineItem): number => {
                                if (-1 === Math.sign(this.maxRetentionTotal(item))) {
                                    return this.maxRetentionTotal(item) / process.env.SCALE_FACTOR
                                } else {
                                    return 0
                                }
                            },
                            maxValue: (item: SoVLineItem): number => {
                                if (-1 === Math.sign(this.maxRetentionTotal(item))) {
                                    return 0
                                } else {
                                    return this.maxRetentionTotal(item) / process.env.SCALE_FACTOR
                                }
                            },
                        },
                        {
                            name: `${ this.$t('Total Retention Percent') } (%)`,
                            field: 'total_retention_percent',
                            valueType: CellType.InputNumInt,
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                if (this.hasSubtasks(item)) {
                                    item.total_retention_percent = this.intGetPercent(this.getSumOfTasksValues(item, 'total_retention_amount'), this.totalCompletedStoredAmount(item))
                                }

                                return this.$filters.formatPercentInt(item.total_retention_percent)
                            },
                            footerValue: '',
                            editable: (item: SoVLineItem): boolean => {
                                return this.isEditableRetentionSectionItem(item)
                            },
                            headerInfo: this.isEditingMode ? this.$t('Release retention on the current application by adjusting the total retention columns.') : null,
                            onValueChange: debounce((item: SoVLineItem, field: string, value: number): void => {
                                item.total_retention_percent = value
                                item.total_retention_amount = Math.min(((value / process.env.SCALE_FACTOR)/100) * this.totalCompletedStoredAmount(item), this.maxRetentionTotal(item))
                                this.retentionReleased(item)
                            }, 500),
                            cellInfo: (item: SoVLineItem): TranslateResult => {
                                return item.retention_updated ? this.$t('The Retention % was modified on the last invoice and the current retention will be calculated with the updated value') : ''
                            },
                            sign: '%',
                            placeholder: '0',
                            minValue: (item: SoVLineItem): number => {
                                return 0
                            },
                            maxValue: (item: SoVLineItem): number => {
                                //Max retention % must be rounded UP to the nearest 100th, otherwise BUGS will EAT YA!
                                return Math.ceil(10000 * ((this.previousRetentionAmount(item) + item.current_retention_amount + item.materials_stored_retention_amount) / this.totalCompletedStoredAmount(item))) / 100
                            },
                        },
                        {
                            name: '',
                            field: '',
                            valueType: CellType.Text,
                            classes: 'io-additional-action-column io-no-padding',
                            hasPostfixAction: (item: SoVLineItem): boolean => {
                                return this.isEditableRetentionSectionItem(item) && this.allColumnsView
                            },
                            postfixAction: (item: SoVLineItem): object => {
                                return {
                                    type: 'button',
                                    variant: 'link',
                                    text: this.$t('Apply to All'),
                                    onClick: (item, header) => {
                                        this.phases.forEach(qi => {
                                            qi.total_retention_percent = item.total_retention_percent
                                            qi.children?.forEach(phase => {
                                                this.updateNestedItems(phase, 'total_retention_percent', item.total_retention_percent)
                                            })
                                        })
                                    },
                                }
                            },
                            editable: (item: SoVLineItem): boolean => {
                                return false
                            },
                            visible: this.invoiceIsDraft && this.isEditingMode && this.allColumnsView,
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string => {
                                return ''
                            },
                            footerValue: '',
                        },
                        {
                            name: `${ this.$t('Retention Released this Period') } (${ this.getWorkspaceCurrencySymbol() })`,
                            field: 'retention_released',
                            valueType: CellType.Text,
                            visible: this.allColumnsView,
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--dark-purple) !important;',
                            customValue: (item: SoVLineItem): string | number => {
                                if (this.hasSubtasks(item)) {
                                    item.retention_released = this.getSumOfTasksValues(item, 'retention_released')
                                }

                                return this.$filters.formatProjectCurrencyInt(item.retention_released)
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalRetentionReleased),
                            editable: (item: SoVLineItem): boolean => {
                                return false
                            },
                        },
                    ]
                },
                {
                    id: 'netPayments',
                    name: this.$t('Net Value Work Complete'),
                    columns: [
                        {
                            name: this.$t('Net Previous Amount'),
                            field: 'net_previous_amount',
                            classes: 'io-border-left io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-orange-2) !important;',
                            visible: this.allColumnsView,
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatProjectCurrencyInt(this.netPreviousAmount(item))
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalNetPreviousAmount),
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: this.$t('Net Current Application'),
                            field: 'net_current_application',
                            classes: this.allColumnsView ? 'io-align-right' : 'io-border-left io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-orange-2) !important;',
                            visible: true,
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatProjectCurrencyInt(this.netCurrentApplication(item))
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalNetCurrentApplication),
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: `${ this.$t('Net Total Completed & Stored Amount') } (${ this.getWorkspaceCurrencySymbol() })`,
                            field: 'net_total_completed_stored_amount',
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-orange-2) !important;',
                            visible: true,
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatProjectCurrencyInt(this.netTotalCompletedStoredAmount(item))
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.netTotalCompletedAndStored),
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: `${ this.$t('Net Total Completed & Stored Percent') } (%)`,
                            field: 'net_total_completed_stored_percent',
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-orange-2) !important;',
                            visible: this.allColumnsView,
                            footerValue: '',
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatPercentInt(this.netTotalCompletedStoredPercents(item))
                            },
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: `${ this.$t('Net Balance to Complete') } (${ this.getWorkspaceCurrencySymbol() })`,
                            field: 'net_balance_to_complete_amount',
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-orange-2) !important;',
                            visible: this.allColumnsView,
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatProjectCurrencyInt(this.netBalanceToCompleteAmount(item))
                            },
                            footerValue: this.$filters.formatProjectCurrencyInt(this.totalNetBalanceTCompleteAmount),
                            editable: (): boolean => {
                                return false
                            },
                        },
                        {
                            name: `${ this.$t('Net Balance to Complete Percent') } (%)`,
                            field: 'net_balance_to_complete_percent',
                            classes: 'io-align-right',
                            headerStyles: 'border-bottom: 1px solid var(--budget-orange-2) !important;',
                            visible: this.allColumnsView,
                            customValue: (item: SoVLineItem): string | number => {
                                return this.$filters.formatPercentInt(this.netBalanceToCompletePercents(item))
                            },
                            footerValue: '',
                            editable: (): boolean => {
                                return false
                            },
                        },
                    ]
                }
            ]
        },
    },
    methods: {
        goToTheDepth (item: SoVLineItem, callback: Function): void {
            if (this.hasSubtasks(item)) {
                item.children.forEach(child => {
                    this.goToTheDepth(child, callback)
                })
            } else {
                callback(item)
            }
        },

        updateParentPercents (item: SoVLineItem): void {
            if (!this.hasSubtasks(item)) {
                if (this.isLastChild(item)) {
                    this.onFieldChanged(item, 'percent_of_completed_work')
                    this.onFieldChanged(item, 'current_retention_percent')
                    this.onFieldChanged(item, 'materials_stored_retention')
                }
                item.total_retention_percent = this.intGetPercent(this.getSumOfTasksValues(item, 'total_retention_amount'), this.totalCompletedStoredAmount(item))
            }
        },

        isEditable (item: SoVLineItem, field: string = ''): boolean {
            if (!this.isEditingMode || !this.invoiceIsDraft && !this.canAddressRevision) {
                return false
            }

            if (this.hasSubtasks(item) && 'percent_of_completed_work' !== field) {
                return false
            }

            return true
        },

        isEditableRetentionSectionItem (item: SoVLineItem, field: string = ''): boolean {
            return (this.isEditable(item, field) && !this.hasAccountingIntegration) || (this.isEditable(item, field) && this.hasAccountingIntegration && this.isRetentionInvoice)
        },

        isEditableGrossSectionItem (item: SoVLineItem, field: string = ''): boolean {
            return (this.isEditable(item, field) && !this.hasAccountingIntegration) || (this.isEditable(item, field) && this.hasAccountingIntegration && !this.isRetentionInvoice)
        },

        onInit (item: SoVLineItem, levelPrefix: string): void {
            item.numberFull = levelPrefix.slice(0, -2)
        },

        isLastChild (item: SoVLineItem): boolean {
            if (!item.quoted_item_id) {
                return false
            }

            let lastChild = false

            this.phases.forEach(quotedItem => {
                if (null === quotedItem.children) {
                    return
                }
                if (quotedItem.contract_wbs_id === item.quoted_item_id) {
                    if (SoVTableViewMode.COsWithinOriginalScope === this.tableViewMode && item.originalScopeParent && item.originalScopeParent.includes('original-scope')) {
                        quotedItem.children.forEach(phase => {
                            if (phase.contract_wbs_id === item.phase_id) {
                                phase.children.forEach(task => {
                                    if (task.contract_wbs_id === item.originalScopeParent) {
                                        lastChild = task.children[task.children.length - 1].contract_wbs_id === item.contract_wbs_id
                                    }
                                })
                            }
                        })
                    } else if (item.phase_id) {
                        quotedItem.children.forEach(phase => {
                            if (phase.contract_wbs_id === item.phase_id) {
                                lastChild = phase.children[phase.children.length - 1].contract_wbs_id === item.contract_wbs_id
                            }
                        })
                    } else {
                        lastChild = quotedItem.children[quotedItem.children.length - 1].contract_wbs_id === item.contract_wbs_id
                    }
                }
            })

            return lastChild
        },

        previousApplication (item: SoVLineItem): number {
            return this.getSumOfTasksValues(item, 'previous_applications')
        },

        currentApplication (item: SoVLineItem): number {
            return this.getSumOfTasksValues(item, 'current_application')
        },

        totalCompletedStoredPercent (item: SoVLineItem): number {
            const value = this.intGetPercent(
                item.total_completed_stored_amount,
                item.scheduled_value,
            )
            return value
        },

        totalCompletedStoredAmount (item: SoVLineItem): number {
            item.total_completed_stored_amount = item.previous_applications + item.materials_stored + item.current_application
            return item.previous_applications + item.materials_stored + item.current_application
        },

        balanceToComplete (item: SoVLineItem): void {
            return item.scheduled_value - item.total_completed_stored_amount
        },

        updateCurrentApplication (item: SoVLineItem, value: number): void {
            const percentValue = this.intMultiplyPercent(
                item.scheduled_value,
                value,
            )
            const currentApp = this.sub([percentValue, item.previous_applications, item.materials_stored])
            item.current_application = Math.round(currentApp / 100) * 100
        },

        updateRetention (item: SoVLineItem, updatePercent: boolean = false, currentRetention: boolean = true): void {
            if (updatePercent) {
                if (currentRetention) {
                    item.current_retention_percent = this.calculateRetentionPercent(item)
                } else {
                    item.materials_stored_retention = this.materialStoredRetentionPercent(item)
                }
            } else {
                if (currentRetention) {
                    item.current_retention_amount = item.current_retention_percent ? this.calculateRetentionAmount(item) : 0
                } else {
                    item.materials_stored_retention_amount = item.materials_stored_retention ? this.materialStoredRetentionAmount(item) : 0
                }
            }


            if (0 !== this.maxRetentionTotal(item)) {
                const totalRetention = this.getSumOfTasksValues(item, 'previous_retention_amount') + item.current_retention_amount + item.materials_stored_retention_amount
                if (item.retention_released && totalRetention < item.retention_released) {
                    item.retention_released = totalRetention
                }
                const value = totalRetention - item.retention_released
                item.total_retention_amount = value || 0
                item.total_retention_percent = this.intGetPercent(value, item.previous_applications + item.materials_stored + item.current_application)
            }
        },

        // update % of completed work
        initTaskPercents (item: SoVLineItem): void {
            let value = 0
            if (0 === item.scheduled_value) {
                value = 0
            } else {
                value = ((item.previous_applications + this.getSumOfTasksValues(item, 'current_application') + this.getSumOfTasksValues(item, 'materials_stored'))/item.scheduled_value) * 100
            }
            if (item.percent_of_completed_work !== value * process.env.SCALE_FACTOR) {
                item.percent_of_completed_work = value * process.env.SCALE_FACTOR
            }
        },

        updateCurrentRetentionPercents (item: SoVLineItem): void {
            if (this.hasSubtasks(item)) {
                if (this.getSumOfTasksValues(item, 'current_application') && this.getSumOfTasksValues(item, 'current_retention_amount')) {
                    const value = ((this.getSumOfTasksValues(item, 'current_retention_amount') / this.getSumOfTasksValues(item, 'current_application')) * 100) * process.env.SCALE_FACTOR
                    item.current_retention_percent = Math.round(value / 100) * 100
                } else {
                    const value = item.children.reduce((acc, obj) => acc + obj.current_retention_percent, 0)/item.children.length
                    item.current_retention_percent = Math.round(value / 100) * 100
                }
            }
        },

        updateMaterialsStoredRetentionPercents (item: SoVLineItem): void {
            if (this.hasSubtasks(item)) {
                if (this.getSumOfTasksValues(item, 'materials_stored') && this.getSumOfTasksValues(item, 'materials_stored_retention_amount')) {
                    const value = ((this.getSumOfTasksValues(item, 'materials_stored_retention_amount') / this.getSumOfTasksValues(item, 'materials_stored')) * 100) * process.env.SCALE_FACTOR
                    item.materials_stored_retention = Math.round(value / 100) * 100
                } else {
                    const value = item.children.reduce((acc, obj) => acc + obj.materials_stored_retention, 0)/item.children.length
                    item.materials_stored_retention = Math.round(value / 100) * 100
                }
            }
        },

        updateNestedItems (item: SoVLineItem, field: string, value: number): void {
            if (item.hasOwnProperty(field) && item[field] !== value) {
                item[field] = value
                if ('percent_of_completed_work' === field) {
                    this.updateCurrentApplication(item, value)
                }
                this.updateRetention(item)
            }

            if (item.hasOwnProperty('children') && Array.isArray(item['children'])) {
                for (const nestedItem of item['children']) {
                    this.updateNestedItems(nestedItem, field, value)
                }
            }
        },

        updateItemField (item: SoVLineItem, field: string): void {
            if ('percent_of_completed_work' === field) {
                this.initTaskPercents(item)
            }

            if ('current_retention_percent' === field) {
                this.updateCurrentRetentionPercents(item)
            }

            if ('materials_stored_retention' === field) {
                this.updateMaterialsStoredRetentionPercents(item)
            }
        },

        updateParentItems (item: SoVLineItem, quotedItem: SoVLineItem, field: string): void {
            if (item.phase_id) {
                quotedItem.children?.forEach(phase => {
                    if (phase.contract_wbs_id === item.phase_id) {
                        if (SoVTableViewMode.COsWithinOriginalScope === this.tableViewMode && item.originalScopeParent && item.originalScopeParent.includes('original-scope')) {
                            phase.children?.forEach(task => {
                                if (task.contract_wbs_id === item.originalScopeParent) {
                                    this.updateItemField(task, field)
                                }
                            })
                        }
                    }

                    setTimeout(() => {
                        if (phase.contract_wbs_id === item.phase_id) {
                            this.updateItemField(phase, field)
                        }
                    }, 50)
                })
            }
        },

        onFieldChanged (item: SoVLineItem, field: string): void {
            if (item.adjustments) {
                this.onAdjustedItemValueChangedInInputs(item)
            }
            this.updateParent(item, field)
        },

        updateParent (item: SoVLineItem, field: string): void {
            if (!item.quoted_item_id) {
                return
            }

            this.phases.forEach(quotedItem => {
                if (quotedItem.contract_wbs_id === item.quoted_item_id) {
                    this.updateParentItems(item, quotedItem, field)

                    setTimeout(() => {
                        this.updateItemField(quotedItem, field)
                    }, 150)

                }
            })
        },

        minAmountToCompleteMaterialsOffsite (item: SoVLineItem): number {
            if (-1 === Math.sign(item.scheduled_value)) {
                return this.toFloat(this.sub([item.scheduled_value, item.previous_applications, item.current_application]))
            } else {
                return -1 * this.toFloat(item.previous_applications + item.current_application)
            }
        },

        maxAmountToCompleteMaterialsOffsite (item: SoVLineItem): number {
            if (-1 === Math.sign(item.scheduled_value)) {
                return Math.sign(item.scheduled_value) * this.toFloat(item.previous_applications + item.current_application)
            } else {
                return this.toFloat(this.sub([item.scheduled_value, item.previous_applications, item.current_application]))
            }
        },

        minAmountToCompleteCurrentApplication (item: SoVLineItem): number {
            if (-1 === Math.sign(item.scheduled_value)) {
                return this.toFloat(this.sub([item.scheduled_value, item.previous_applications, item.materials_stored]))
            } else {
                return -1 * this.toFloat(item.previous_applications + item.materials_stored)
            }
        },

        maxAmountToCompleteCurrentApplication (item: SoVLineItem): number {
            if (-1 === Math.sign(item.scheduled_value)) {
                return Math.sign(item.scheduled_value) * this.toFloat(item.previous_applications + item.materials_stored)
            } else {
                if (item.previous_applications > item.scheduled_value) {
                    return 0
                }

                return this.toFloat(this.sub([item.scheduled_value, item.previous_applications, item.materials_stored]))
            }
        },
    }
})
