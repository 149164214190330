import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { mapActions, mapState, mapWritableState } from 'pinia'

import DetailsTab from '@/io-modules/documents/components/right-panel/details-tab/DetailsTab.vue'
import MarkupsTab from '@/io-modules/documents/components/right-panel/markups-tab/MarkupsTab.vue'

import DocumentInterface from '@/io-modules/documents/interfaces/DocumentInterface'

import { viewDocumentStore } from '@/io-modules/documents/store/viewDocumentStore'
import { rightPanelTabsStore } from '@/io-modules/documents/store/rightPanelStore'


export default defineComponent({
    components: {
        DetailsTab,
        MarkupsTab
    },
    props: {
        document: {
            type: Object as () => DocumentInterface,
            required: true,
        },
        viewOnly: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            collapsed: false,
        }
    },
    computed: {
        ...mapState(viewDocumentStore, [
            'cursor'
        ]),
        ...mapState(rightPanelTabsStore, [
            'isDetailsTabActive',
            'isMarkupsTabActive'
        ]),
        ...mapWritableState(rightPanelTabsStore, [
            'rightPanelActiveTab'
        ]),
        tabs (): Array<{ title: TranslateResult, component: string }> {
            const defaultTabs = [
                {
                    title: this.$t('Details'),
                    component: 'DetailsTab'
                },
            ]

            const optionalTabs = this.viewOnly ? [] : [
                {
                    title: this.$t('Markups'),
                    component: 'MarkupsTab'
                },
            ]

            return [...defaultTabs, ...optionalTabs]
        },
    },
    beforeMount (): void {
        this.endAddingAnnotation()
    },
    methods: {
        ...mapActions(viewDocumentStore, [
            'endAddingAnnotation',
        ]),
        cancelAddingAnnotation (): void {
            this.$emit('cancel-adding-annotation')
        },
        updateAnnotations (): void {
            this.$emit('update-annotations')
        },
        selectAnnotation (uuid: string): void {
            this.$emit('select-annotation', uuid)
        },
        deleteBasicAnnotation (data: { document_id: string, annotation_id: string }): void {
            this.$emit('delete-basic-annotation', data)
        },
    }
})
