<template>
    <div class="io-single-markup" :class="{ 'io-selected': isSelected }" @click="selectMarkup">
        <div class="io-single-markup__header">
            <div class="io-left-side">
                <SvgIconComponent
                    v-if="svgIconTypes.includes(data.tool_name)"
                    :toolName="data.tool_name"
                    :toolColor="data.tool_color"
                />

                <i v-else :class="getToolIcon"/>

                <span class="io-shape-number">
                    {{ headerLabelText }}
                    <span class="io-number">
                        {{ data.internal_id }}
                    </span>
                </span>
            </div>

            <div class="io-right-side">
                <span>{{ $filters.parseDateTime(data.created_at) }}</span>
                <!--
                <DropdownButtonNew :icon-mode="true" font-awesome-class="icon-more-vert-2">
                    <div class="io-single-option" @click="onDeleteMarkup">{{ $t('Delete') }}</div>
                </DropdownButtonNew>
                -->
                <i class="icon-trash" @click="onDeleteMarkup"/>
            </div>
        </div>

        <div class="io-markup-author">
            <Avatar
                :companyName="data.created_by.position"
                :employeeMongoId="data.created_by.id"
                :employeeFullName="data.created_by.name"
                showFullName
            />
        </div>
    </div>
</template>

<script lang="ts" src="./SingleMarkup.ts"></script>
<style lang="scss" src="./SingleMarkup.scss" scoped></style>
