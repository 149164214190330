const DirectCostListView = () => import(/* webpackChunkName: "DirectCostListView" */ '@/io-modules/direct-costs/views/list/DirectCostListView.vue')
const DirectCostAddView = () => import(/* webpackChunkName: "DirectCostAddView" */ '@/io-modules/direct-costs/views/add/DirectCostAddView.vue')
const DirectCostDuplicateView = () => import(/* webpackChunkName: "directDuplicateView" */ '@/io-modules/direct-costs/views/duplicate/DirectCostDuplicateView.vue')
const DirectCostDetailsView = () => import(/* webpackChunkName: "directDuplicateView" */ '@/io-modules/direct-costs/views/details/DirectCostDetailsView.vue')
const DirectCostEditView = () => import(/* webpackChunkName: "DirectCostAddView" */ '@/io-modules/direct-costs/views/edit/DirectCostEditView.vue')

export default [
    {
        path: '/direct-costs/list',
        name: 'direct-costs-list',
        component: DirectCostListView,
        meta: {
            title: 'Direct Costs List'
        }
    },
    {
        path: '/direct-costs/add',
        name: 'direct-costs-add',
        component: DirectCostAddView,
    },
    {
        path: '/direct-costs/:id',
        name: 'direct-costs-details',
        component: DirectCostDetailsView,
    },
    {
        path: '/direct-costs/:id/edit',
        name: 'direct-costs-edit',
        component: DirectCostEditView,
        props: true,
    },
    {
        path: '/direct-costs/:id/copy',
        name: 'direct-costs-copy',
        component: DirectCostDuplicateView,
        meta: {
            name: 'Copy Direct Cost',
            title: 'Duplicate Direct Cost'
        }
    },
]
