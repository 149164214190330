import { isBoolean, isEmpty } from 'lodash'
import SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import DeletePopupAlertAttributesInterface
    from '@/components/simple-file-list/interfaces/DeletePopupAlertAttributesInterface'

export default defineComponent({
    name: 'SingleFile',
    props: {
        file: {
            type: Object as () => SingleModelDocument,
            required: true
        },
        arrayId: {
            type: String,
            required: true
        },
        toSync: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        modelType: {
            type: String,
            required: false
        },
        showPreviewButton: {
            type: Boolean,
            default: false
        },
        showDownloadButton: {
            type: Boolean,
            default: false
        },
        showDeleteButton: {
            type: Boolean,
            default: false
        },
        showFileSize: {
            type: Boolean,
            default: true
        },
        clickable: {
            type: Boolean,
            default: true
        },
        isShared: {
            type: Boolean,
            default: false
        },
        showUpdateDate: {
            type: Boolean,
            default: false
        },
        showCreatorName: {
            type: Boolean,
            default: false
        },
        showCreatorAvatar: {
            type: Boolean,
            default: false
        },
        showSharedYesNo: {
            type: Boolean,
            default: false,
        },
        deletePopupAlertAttributes: {
            type: Object as () => DeletePopupAlertAttributesInterface,
            required: false,
        },
    },
    data () {
        return {
            isEditMode: false as boolean,
            newFileName: '' as string,
            isSynchronizing: false as boolean,
        }
    },
    computed: {
        ...mapGetters('appStore', ['getAuthData']),
        fileExtension (): string {
            const index = this.file.file_name.lastIndexOf('.')

            if (index === -1) {
                return ''
            }

            return this.file.file_name.slice(index + 1)
        },
        fullEditedFileName (): string {
            if (isEmpty(this.fileExtension)) {
                return this.newFileName
            }
            return `${ this.newFileName }.${ this.fileExtension }`
        },
        rowClickable (): boolean {
            return !this.isEditMode && this.clickable
        },
        downloadUrl (): string {
            const endpoint = this.$api.getApi2RequestUrl('/documents/download')
            return `${ endpoint }?files[0]=${ this.preferedId }`
        },
        isDocumentSharedWithMyWorkspace (): boolean {
            return (this.file.shared_to_companies_mongo_ids ?? []).includes(this.getAuthData.company_mongo_id)
        },
        preferedId (): string {
            return this.isShared || this.file.isShared ? this.file._shared_to_my_document_id : this.file._id
        }
    },
    methods: {
        onClickEdit (): void {
            const index = this.file.file_name.lastIndexOf('.')
            this.newFileName = this.file.file_name.slice(0, index)

            this.isEditMode = true
            this.$nextTick(() => {
                this.$refs.inputName.$el.querySelector('input').focus()
            })
        },
        async onClickSave (): Promise<void> {
            try {
                await this.$api.post(`/document/${ this.preferedId }/rename`, {
                    file_name: this.fullEditedFileName
                })

                this.file.file_name = this.fullEditedFileName
                this.isEditMode = false
            } catch (error) {
                this.showNotification('error', error.response.data.message, error)
            }
        },
        onClickCancel (): void {
            this.isEditMode = false
        },
        onClickDelete (): void {
            this.showPopupAlert({
                title: this.deletePopupAlertAttributes?.title ? this.deletePopupAlertAttributes.title : this.$t('Delete File'),
                caption: this.deletePopupAlertAttributes?.caption ? this.deletePopupAlertAttributes.caption : this.$t('This file will be moved to the trash, are you sure?'),
                icon: this.deletePopupAlertAttributes?.icon ? this.deletePopupAlertAttributes.icon : 'far fa-archive',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Yes, Delete'),
                        class: 'io-btn-primary',
                        action: async (): Promise<void> => {
                            await this.delete()
                        },
                    },
                ],
            })
        },
        async delete (): Promise<void> {
            try {
                await this.$api.delete('documents/remove', {
                    params:
                    {
                        files: [this.file._id]
                    }
                })
                this.$store.dispatch('filesComponent/removeMyDoc', {
                    arrayId: this.arrayId,
                    fileId: this.file._id
                })
                this.$emit('deleted', this.file)
            } catch (e) {
                this.showNotification('error', e.response.data.message ?? e.message)
            }
        },
        async onPreview (): Promise<void> {

            await this.validateSyncFile()

            const resolvedRoute = this.$router.resolve({
                name: 'documents-details',
                params: {
                    id: this.preferedId
                }
            })
            window.open(resolvedRoute.href, '_blank', 'noopener')
        },
        onRowClickPreview (): void {
            const previewDisabled = isBoolean(this.file.preview_disabled) && this.file.preview_disabled

            if (this.clickable && !previewDisabled && !this.isEditMode) {
                this.onPreview()
            }
        },
        async validateSyncFile (): Promise<void> {
            if (this.isShared && !this.isDocumentSharedWithMyWorkspace) {
                this.setLoadingBar(true)
                await this.syncFile()
                this.setLoadingBar(false)
            }
        },
        async onClickDownload (): Promise<void> {
            await this.validateSyncFile()

            const linkElement = document.createElement('a')
            linkElement.setAttribute('type', 'hidden')
            document.body.appendChild(linkElement)
            linkElement.href = this.downloadUrl
            linkElement.click()
            document.body.removeChild(linkElement)
        },
        async syncFile (): Promise<void> {
            let modelId = this.arrayId || this.$route.params.id
            if (this.isSynchronizing) {
                return
            }
            try {
                this.isSynchronizing = true
                const { data } = await this.$api.get(`/copy-shared-document/${ this.modelType }/${ modelId }/${ this.file._id }`)
                this.$store.dispatch('filesComponent/addMyDoc', {
                    arrayId: this.arrayId,
                    file: data.document
                })
                this.file['_shared_to_my_document_id'] = data.document._id
                this.file['shared_to_companies_mongo_ids'] = [data.document.company_mongo_id]
                this.$emit('synchronizedFile')
            } catch (e) {
                this.showNotification('error', this.$t('Error occurred during loading data.'))
            } finally {
                this.isSynchronizing = false
            }
        },
    }
})
