import { defineComponent } from 'vue'
import { LocationQueryRaw } from 'vue-router'

export type Section = {
    name: string
    title: string
    icon: string
}

export type Tab = {
    name: string
    title: string
    sections?: Section[]
}

export enum RouteQuery {
    TAB = 'sidebar-tab',
    SECTION = 'sidebar-section',
}

export default defineComponent({
    name: 'IOSidebar',

    props: {
        tabs: {
            type: Array as () => Tab[],
            required: true,
        },

        width: {
            type: [Number, String],
            default: null,
        },

        queryTabName: {
            type: String,
            default: RouteQuery.TAB,
        },

        querySectionName: {
            type: String,
            default: RouteQuery.SECTION,
        },

        collapsible: Boolean,
        preserveExternalRouteQueries: Boolean,
    },

    setup () {
        return { RouteQuery }
    },

    data () {
        return {
            query: {} as LocationQueryRaw,
            hoveredSectionName: '',
        }
    },

    computed: {
        activeTab (): Tab {
            return this.tabs.find((tab: Tab) =>
                tab.name === this.$route.query[this.queryTabName])
        },

        activeSection (): Section {
            return this.activeTab?.sections?.find((section: Section) =>
                section.name === this.$route.query[this.querySectionName])
        },

        collapsed (): boolean {
            return !this.$route.query[this.queryTabName]
        },
    },

    mounted () {
        /**
         * In case something went wrong with URL and the sidebar is not showing,
         * we should show the first tab and section by default.
         */
        !this.collapsible && !this.activeSection && this.show()
    },

    methods: {
        show (): void {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    ...{
                        [this.queryTabName]: this.query[this.queryTabName] || this.tabs[0].name,
                        [this.querySectionName]: this.query[this.querySectionName] || this.tabs[0].sections?.[0]?.name,
                    },
                },
            })
            this.query = {}
        },

        hide (): void {
            this.query = {
                [this.queryTabName]: this.$route.query[this.queryTabName],
                [this.querySectionName]: this.$route.query[this.querySectionName],
            }

            this.$router.replace({
                query: {
                    ...this.$route.query,
                    [this.queryTabName]: undefined,
                    [this.querySectionName]: undefined,
                },
            })
        },
    },
})
