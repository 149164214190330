<template>
    <transition name="right-modal" duration="400" appear>
        <div class="io-right-modal" :class="customClass">

            <div class="io-right-modal__mask" @click="handleMaskClick"></div>

            <div class="io-right-modal__content" :style="getContentStyle">

                <slot name="header"></slot>
                <div v-if="showDivider" class="io-right-modal__divider"/>
                <slot></slot>
                <slot name="footer"></slot>

            </div>
        </div>
    </transition>
</template>

<script src="./RightModal.js"></script>
<style lang="scss" src="./RightModal.scss" scoped></style>
