<template>
    <div class="io-single-alert">
        <div class="io-single-alert__header">
            <div class="io-single-alert__icon" :class="alert.colorClass"><i class="fas" :class="alert.icon"></i></div>
            <div class="io-single-alert__title" v-if="alert.title">{{ alert.title }}</div>
        </div>
        <div class="io-single-alert__caption" v-if="alert.caption">{{ alert.caption }}</div>
        <div class="io-single-alert__buttons">
            <template v-for="(button, index) in alert.buttons" :key="index">
                <div class="io-btn" :class="button.class" @click="buttonClick(button)">
                    <i v-if="button.icon" :class="button.icon"></i>
                    {{ button.text }}
                </div>
            </template>
        </div>
        <div v-if="alert.showDismissButton" class="io-single-alert__dismiss" @click="dismissAlert"><i class="fal fa-times"></i></div>
    </div>
</template>

<script src="./single-alert.js"></script>
<style lang="scss" src="./single-alert.scss" scoped></style>
