<template>
    <div>
        <div class="io-design-system io-card">
            <div v-if="showHeader" class="io-card__header">
                <div class="left-content">
                    <span v-if="icon" :class="icon"/>
                    <h3 v-html="title" />
                    <StatusPill v-if="status" :variant="status.variant" :text="status.text"/>
                    <StatusDot v-if="statusDot" :variant="statusDot.variant" :text="statusDot.text"/>
                </div>
                <div class="right-content">
                    <slot name="HeaderRightContent"></slot>
                </div>
            </div>
        </div>
        <hr v-if="showLine" class="line"/>
        <div class="io-card__body">
            <slot name="Body"></slot>
        </div>
    </div>
</template>

<script lang="ts" src="./Card.ts"></script>
<style lang="scss" src="./Card.scss" scoped></style>
