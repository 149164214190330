<template>
    <PopupModal
        class="io-popup-project-agreement-type"
        :disableOutsideClose="true"
        :id="'projectAgreementType'"
        :width="660"
        :zIndex="10000"
    >
        <template #header>
            <div class="io-popup-modal__title">
                {{$t('agreement_type_header_popup')}}
            </div>
            <div
                class="io-popup-modal__close"
                @click.stop="closePopup"
            >
                <i class="icon-cross"></i>
            </div>
        </template>

        <template #default>
            <div class="io-content-agreement-type">
                <span class="io-title">
                    {{$t('agreement_type_title')}}
                </span>

                <div
                    v-for="(item, index) in agreementTypes"
                    :key="index"
                    class="io-single-agreement"
                    :class="{ 'io-agreement-selected': item.id === agreementTypeInternal }"
                    @click="selectAgreementType(item.id)"
                >
                    <Radio
                        input-name="agreementType"
                        :radio-value="item.id"
                        v-model="agreementTypeInternal"
                    />
                    <div class="io-single-agreement__icon" :class="`io-${item.id}`"></div>
                    <div class="io-single-agreement__content">
                        <div class="io-single-agreement__name">
                            {{ $t(item.title) }}
                        </div>
                        <div class="io-single-agreement__description">
                            {{ $t(item.description) }}
                        </div>
                    </div>
                </div>
            </div>
        </template>


        <template #footer>
            <IOButton
                variant="secondary"
                @click="closePopup"
            >
                {{$t('Back')}}
            </IOButton>
            <IOButton
                :disabled="isDisabled"
                @click="setAgreementType()"
            >
                {{$t('Choose')}}
            </IOButton>
        </template>
    </PopupModal>
</template>


<script lang="ts" src="./main.ts"></script>
<style lang="scss" src="./main.scss" scoped/>
