import { defineComponent } from 'vue'
import Tooltip from 'vue-directive-tooltip'
import getCascadeAnimationDelay from '@/helpers/getCascadeAnimationDelay.ts'
import IOThumbnail from '@/components/atoms/IOThumbnail/IOThumbnail.vue'
import type { Props } from '@/components/atoms/IOThumbnail/IOThumbnail.ts'

export type Action = {
    icon: string
    name: string
    tooltip?: string
    thumbnail?: Props
}

export default defineComponent({
    name: 'FloatMenu',
    directives: {
        Tooltip,
    },
    components: {
        IOThumbnail,
    },
    props: {
        actions: {
            type: Array as () => Action[],
            default: (): Action[] => [
                {
                    icon: 'icon-cross',
                    name: 'clear',
                },
            ]
        },
        static: Boolean,
    },
    setup () {
        return {
            getCascadeAnimationDelay,
        }
    },
})
