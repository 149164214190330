<template>
    <div class="io-add-comment">
        <div class="io-add-comment__header">
            <i class="icon-comment-text"/>
            <span class="io-comment-number">
                {{ $t('Comment no') + ': ' }}
                <span class="io-comment-number__id">{{ commentNumber }}</span>
            </span>
            <Tooltip :text="$t('This Comment will be visible to everyone who has access to this document file.')"/>
        </div>

        <Mentions
            :key="`add-comment-${ commentNumber }`"
            :placeholder="$t('Add Comment...')"
            :attached_employees_ids="uniqueMentionUsers"
            :customPeopleList="uniqueMentionUsers"
            :attachBelow="true"
            :getByMongo="true"
            :value="comment_text"
            v-model="comment_text"
            @update="onUpdateReply"
        />

        <div class="io-add-comment__footer">
            <IOButton variant="secondary" size="small" @click="cancel">{{ $t('Cancel') }}</IOButton>
            <IOButton size="small" :disabled="!comment_text" @click="postAnnotation">{{ $t('Post') }}</IOButton>
        </div>
    </div>
</template>

<script lang="ts" src="./AddComment.ts"></script>
<style lang="scss" src="./AddComment.scss" scoped></style>
