import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
    props: {
        totalRows: { type: Number },
        isDisabled: { type: Function, default: () => false },
        records: { type: Array as () => Array<{id: string}>, required: true, default: () => [] },
    },
    data () {
        return {
            checked: false,
        }
    },
    computed: {
        ...mapGetters('lazyList', {
            selectedRows: 'getSelectedRows',
            hasAllRowsSelected: 'hasAllRowsSelected',
        }),
        isIndeterminate (): boolean {
            return this.selectedRows.length > 0
                && !this.hasAllRowsSelected
        }
    },
    watch: {
        selectedRows (currentValue: Array<string>): void {
            this.checked = currentValue.length === this.totalRows
        }
    },
    methods: {
        ...mapActions('lazyList', {
            setSelectedRows: 'setSelectedRows',
            setHasAllRowsSelected: 'setHasAllRowsSelected',
            setShouldSelectAllRows: 'setShouldSelectAllRows',
        }),
        async addSelectedRows (): Promise<void> {
            if (this.selectedRows === this.totalRows) {
                return
            }

            await this.setShouldSelectAllRows(this.checked)
            await this.setSelectedRows(this.checked ? this.records.map((item: {id: string}) => {
                if (!this.isDisabled(item)) {
                    return item.id
                }
            }) : [])
            await this.setHasAllRowsSelected(this.checked)
        },
    },
})
