import defaultState from './state'
import { cloneDeep, findIndex } from 'lodash'

export function SET_CONFIG (state, data) {
    if (state.endpoint) {
        state['prevEndpoint'] = state.endpoint
    }
    if (typeof data.endpoint === 'undefined') {
        data.endpoint = state.prevEndpoint
    }
    if (data.filtersEndpoint) {
        state['withFilters'] = false
        state.filtersEndpoint = data.filtersEndpoint
    }
    state['endpoint'] = data.endpoint
    state['apiVersion'] = data.apiVersion
    state['takeItems'] = data.takeItems || state.takeItems
    state['dataPropertyName'] = data.dataPropertyName || state.dataPropertyName
    state.endpointParams['method'] = data.method
}

export function SET_LOADING (state, value) {
    state['isLoading'] = value
}

export function SET_SORT_BY (state, value) {
    state['sortBy'] = value
}

export function SET_SORT_DIRECTION (state, value) {
    if (value) {
        state['sortDirection'] = value
    }
}

export function RESET_ENDPOINT_PARAMS (state) {
    state['endpointParams'] = {}
}

export function SET_SKIPPED (state, value) {
    state['skip'] = value
}

export function SET_SEARCH (state, value) {
    state['search'] = value
}

export function SET_LIMIT (state, value) {
    state['limit'] = value
}

export function SET_TAKE_ITEMS (state, value) {
    state.takeItems = value
}

export function SET_PER_PAGE (state, value) {
    state.perPage = value
}

export function SET_LAST_PAGE (state, value) {
    state['isLastPage'] = value
}

export function RESET_RECORDS (state, data) {
    state['dataList'] = data
}

export function SET_COUNTERS (state, data) {
    state['counters'] = data
}

export function SET_RECORDS (state, data) {
    if (state.dataList.length) {
        state.dataList.push(...data)
    } else {
        state['dataList'] = data
    }
}

export function ADD_RECORD (state, data) {
    state.dataList.unshift(data)
}

export function SET_IS_SERVER_DEFAULT_FILTERS_DISABLED (state, data) {
    state.isServerDefaultFiltersDisabled = data
}

export function SET_FILTERS (state, data) {
    state['filters'] = data
}

export function SET_SINGLE_FILTER (state, data) {
    state.filtersSelected[data.field] = cloneDeep(state.selectedFiltersOverload?.[data.field]) ?? data.value
}

export function SET_FILTERS_SELECTED (state, data) {
    state['filtersSelected'] = data
}

export function RESET_FILTERS_SELECTED (state) {
    const filtersSelected = cloneDeep(state.filtersSelected)
    for (const key in filtersSelected) {
        filtersSelected[key] = []
    }
    state['filtersSelected'] = filtersSelected
}

export function SET_SHOW_FILTERS (state, value) {
    state['showFilters'] = value
}

export function SET_WITH_FILTERS (state, value) {
    state['withFilters'] = value
}

export function SET_WITH_COUNTERS (state, value) {
    state['withCounters'] = value
}

export function SET_ENDPOINT_PARAMS (state, value) {
    for (let key in value) {
        state.endpointParams[key] = value[key]
    }
}

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep({ ...defaultState, selectedFiltersOverload: state.selectedFiltersOverload }))
}

export function RENAME_RECORD (state, data) {
    let index = findIndex(state.dataList, (o) => o.id === data.id)
    if (index > -1) {
        state.dataList[index].name = data.name
    }
}

export function SET_REQUEST_PARAMS (state, value) {
    state['requestParams'] = value
}

export function SET_EDIT_MODE (state, value) {
    state['isEditMode'] = value
}

export function SET_EDITED_RECORDS (state, data) {
    state['editedDatalist'] = cloneDeep(data)
}

export function SET_EDITED_RECORDS_CLONE (state, data) {
    state['editedDatalistClone'] = cloneDeep(data)
}

export function SET_RECORDS_AFTER_SAVE (state) {
    state['dataList'] = cloneDeep(state.editedDatalist)
}
export function SET_ADDITIONAL_OPTIONS (state, data) {
    state['additionalOptions'] = data
}
export function SET_CAN_EDIT (state, value) {
    state.canEdit = value
}
export function SET_APPLIED_FILTERS (state, data) {
    state.appliedFilters = data
}

export function TOGGLE_SELECTED_ROWS (state, value) {
    const rowIndex = state.selectedRows.findIndex(row => {
        return row === value
    })
    if (rowIndex === -1) {
        state.selectedRows.push(value)
    } else {
        state.selectedRows.splice(rowIndex, 1)
    }
}

export function SET_SELECTED_ROWS (state, data) {
    state.selectedRows = data
}

export function SET_REMOVED_ITEM_ID (state, id) {
    state.removedItemId = id
}

export function DELETE_RECORD (state, id) {
    const index = state.dataList.findIndex(item => item.id === id)
    if (index !== -1) {
        state.dataList.splice(index, 1)
    }
}

export function SET_RECORD (state, data) {
    const index = state.dataList.findIndex(item => item.id === data.id)
    state.dataList[index] = data
}

export function SET_HAS_FETCHED_ONCE (state, value) {
    state.hasFetchedOnce =  value
}

export function SET_ERROR_EXCEPTION (state, exception) {
    state.exception = exception
}

export function UPDATE_IMPORT_STATUS_COMPLETION (state, payload) {
    let foundItem = state.dataList.find((item) => item.id === payload.data_importer_id)

    if (foundItem) {
        foundItem.status = payload.status
    }
}

export function UPDATE_IMPORTING_PERCENTAGE (state, payload) {
    let foundItem = state.dataList.find((item) => item.id === payload.data_importer_id)

    if (foundItem) {
        foundItem.percentage = payload.percentage
    }
}

export function SET_HAS_ALL_ROWS_SELECTED (state, value) {
    state.hasAllRowsSelected = value
}

export function SET_ADDITIONAL_QUERY_PARAMS (state, payload) {
    state.additionalQueryParams = payload
}

export function SET_SHOULD_SELECT_ALL_ROWS (state, value) {
    state.shouldSelectAllRows = value
}

export function SET_IS_FETCH_ALL_RECORDS (state, value) {
    state.isFetchAllRecords = value
}

export function SET_IS_FRONTEND_SORTING_ENABLED (state, value) {
    state.isFrontEndSortingEnabled = value
}

export function SET_SELECTED_FILTERS_OVERLOAD_DATA (state, value) {
    state.selectedFiltersOverload = value
}

export function SET_SINGLE_FILTER_OVERLOAD_DATA (state, field, value) {
    if (!state.selectedFiltersOverload) {
        state.selectedFiltersOverload = {}
    }
    state.selectedFiltersOverload[field] = value
}
