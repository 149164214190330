<template>
    <div v-if="vendorDetails.vendor" class="io-vendor-details">
        <div class="io-vendor-details__card">
            <h3>{{ $t('Vendor Details') }}</h3>
            <div class="io-main-vendor-info">
                <div>
                    <div class="io-vendor-info">
                        <div>
                            <label>{{ $t('Vendor Name') }}</label>
                            <Avatar
                                size="sm"
                                type="company"
                                :show-full-name="true"
                                :employee-mongo-id="vendorDetails.vendor.id"
                                :employee-full-name="vendorDetails.vendor.name"
                            />
                        </div>
                        <div>
                            <label>{{ $t('Vendor Contact Person') }}</label>
                            <Avatar
                                size="sm"
                                type="company"
                                :company-name="vendorDetails.contact_person.role"
                                :show-full-name="true"
                                :employee-mongo-id="vendorDetails.contact_person.id"
                                :employee-full-name="vendorDetails.contact_person.full_name"
                            />
                        </div>
                    </div>
                    <div class="io-progress-bar-wrapper">
                        <label>{{ $t('Percent Billed') }}</label>
                        <div class="io-f-row io-f-start">
                            <ProgressBar variant="small" :items="progressBarItems" :showLegend="false" :useItemValueAsRemains="true" />
                            <span class="io-percents-label">{{ $filters.formatPercent(vendorDetails.billed_percentage) }}</span>
                        </div>
                    </div>
                </div>
                <div class="io-contract-info">
                    <div class="io-separator"></div>
                    <label>{{ $t('Current Contract Amount') }}</label>
                    <div class="io-value">{{ $filters.formatProjectCurrencyInt(vendorDetails.current_contract_amount) }}</div>
                    <IOButton variant="link" :disabled="!vendorDetails.contract_id" @click="goToContract">
                        {{ $t('View Contract') }}
                        <span class="icon-external-link"></span>
                    </IOButton>
                </div>
            </div>
        </div>
        <div class="io-vendor-details__card">
            <h4>{{ $t('Billed to Date') }}</h4>
            <div class="io-value-group">
                <label>{{ $t('Gross Total') }}</label>
                <div class="io-value">{{ $filters.formatProjectCurrencyInt(vendorDetails.billed_to_date_gross_total) }}</div>
            </div>
            <div>
                <label>{{ $t('Net Total') }}</label>
                <div class="io-value">{{ $filters.formatProjectCurrencyInt(vendorDetails.billed_to_date_net_total) }}</div>
            </div>
        </div>
        <div class="io-vendor-details__card">
            <h4>{{ $t('Retention Summary') }}</h4>
            <div class="io-value-group">
                <label>{{ $t('Total Retention') }}</label>
                <div class="io-value">{{ $filters.formatProjectCurrencyInt(vendorDetails.total_retention) }}</div>
            </div>
            <div>
                <label>{{ $t('Retention') }} %</label>
                <div class="io-value">{{ vendorDetails.retention_percentage }}%</div>
            </div>
        </div>
        <div class="io-vendor-details__card">
            <h4>{{ $t('Payments') }}</h4>
            <div class="io-value-group">
                <label>{{ $t('Paid To Date') }}</label>
                <div class="io-value">{{ $filters.formatProjectCurrencyInt(vendorDetails.paid_to_date) }}</div>
            </div>
            <div>
                <label>% {{ $t('Paid') }}</label>
                <div class="io-value">{{ vendorDetails.paid_percentage }}%</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./VendorDetails.ts"></script>
<style lang="scss" src="./VendorDetails.scss" scoped></style>