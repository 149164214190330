<template>
    <FormInputColumn :customContent="true" :required="true" :label="$t(companyLabel)">
        <CustomMultiSelect
            :addingNewInProcess="false"
            :addNewBtnDisabled="true"
            :options="companies"
            :settings="companyMultiSelectSettings"
            :statusEnable="false"
            :modelValue="company"
            @changeOption="onSelectedCompany"
            @search="getAllOffSystemCompaniesFilteredByName"
        >
            <template v-slot:header="{ activeOption, config }">
                <div v-if="activeOption" class="io-multiselect__name">
                    <initials
                        :bigAvatar="true"
                        :fullName="activeOption.name"
                        class="io-single-option__logo io-off-system-logo"
                    />
                    {{ activeOption.name }}
                </div>

                <div v-else class="io-multiselect__name io-placeholder">
                    {{ config.textPlaceholder }}
                </div>
            </template>

            <template v-slot:option="{ option }">
                <initials
                    :bigAvatar="true"
                    :fullName="option.name"
                    class="io-single-option__logo io-off-system-logo"
                />
                <div class="io-single-option__text-wrapper">
                    <div class="io-single-option__title-row">
                        <div class="io-single-option__title">
                            {{ option.name }}
                        </div>
                    </div>
                    <div class="io-single-option__company-row">
                        <div class="io-single-option__company-row__pill">
                            <span class="io-small-pill io-grey">
                                {{ option.app_type }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </CustomMultiSelect>
    </FormInputColumn>

    <FormInputColumn :customContent="true" :required="true" :label="$t(contactLabel)">
        <CustomMultiSelect
            :addingNewInProcess="false"
            :addNewBtnDisabled="true"
            :options="contacts"
            :settings="contactMultiSelectSettings"
            :statusEnable="false"
            :disabled="!company"
            :modelValue="contact"
            @changeOption="onSelectedContact"
        >
            <template v-slot:header="{ activeOption, config }">
                <div v-if="activeOption" class="io-multiselect__name">
                    <initials
                        :bigAvatar="true"
                        :fullName="activeOption.name"
                        class="io-single-option__logo io-off-system-logo"
                    />
                    {{ activeOption.name }}
                </div>

                <div v-else class="io-multiselect__name io-placeholder">
                    {{ config.textPlaceholder }}
                </div>
            </template>

            <template v-slot:option="{ option }">
                <initials
                    :bigAvatar="true"
                    :fullName="option.name"
                    class="io-single-option__logo io-off-system-logo"
                />
                <div class="io-single-option__text-wrapper">
                    <div class="io-single-option__title-row">
                        <div class="io-single-option__title">
                            {{ option.name }}
                        </div>
                    </div>
                </div>
            </template>
        </CustomMultiSelect>
    </FormInputColumn>
</template>

<script lang="ts" src="./CompanyContactDropdown.ts"></script>
<style lang="scss" src="./CompanyContactDropdown.scss" scoped />
