import defaultState from './stateObj'
import { cloneDeep, isEmpty } from 'lodash'
import { ContactActions } from '@/interfaces/components/invite-modal/ContactActions'
import { getInviteContactData } from './getters'
import { InvitationStatuses } from '@/interfaces/components/invite-modal/InvitationStatuses'

export function SET_STATE_OBJECT (state, value) {
    const defaultValue = cloneDeep(defaultState)

    if (state.inviteModalData[value]?.isCachedDataLoadRequired) {
        defaultValue.isCachedDataLoadRequired = true
        defaultValue.groupedCompaniesContactsList = state.inviteModalData[value].groupedCompaniesContactsList
        defaultValue.onItemContactsList = state.inviteModalData[value].onItemContactsList
        defaultValue.selectedContacts = state.inviteModalData[value].selectedContacts
        defaultValue.selectedContactsToAction = state.inviteModalData[value].selectedContactsToAction
    }

    state.inviteModalData[value] = defaultValue
}

export function CLEAR_STATE_OBJECT (state, value) {
    delete state.inviteModalData[value]
}

export function SET_SELECT_MULTIPLE (state, data) {
    state.inviteModalData[data.key].selectMultiple = data.value
}

export function SET_MY_CONTACTS (state, data) {
    state.inviteModalData[data.key].matchedMyContacts = data.data
}

export function UPDATE_MY_CONTACTS (state, data) {
    state.inviteModalData[data.key].matchedMyContacts.push(...data.data)
    state.inviteModalData[data.key].matchedMyContactsClone.push(...data.data)
}

export function SET_MATCHED_ACCOUNTS (state, data) {
    state.inviteModalData[data.key].matchedAccounts = data.data
}

export function SET_MATCHED_DOMAINS (state, data) {
    state.inviteModalData[data.key].matchedDomains = data.data
}

export function CLEAR_COMPANIES_LIST (state) {
    state.companiesList = []
}

export function SET_COMPANIES_LIST (state, data) {
    state.companiesList = data.data
}

export function CLEAR_SEARCH_RESULTS (state, data) {
    if (state.inviteModalData[data.key]) {
        state.inviteModalData[data.key].matchedMyContacts = []
        state.inviteModalData[data.key].matchedDomains = []
        state.inviteModalData[data.key].matchedAccounts = []
        state.inviteModalData[data.key].matchedMyContactsClone = []
    }
}

export function CLEAR_SELECTED_CONTACTS_TO_ACTION (state, value) {
    if (!state.inviteModalData[value]?.isCachedDataLoadRequired) {
        const defaultValue = cloneDeep(defaultState)
        state.inviteModalData[value].selectedContactsToAction = defaultValue.selectedContactsToAction
    }
}

export function RESET_SELECTED_CONTACTS_TO_ACTION (state, value) {
    const defaultValue = cloneDeep(defaultState)
    state.inviteModalData[value].selectedContactsToAction = defaultValue.selectedContactsToAction
}

export function SET_SELECTED_WORKSPACE (state, data) {
    const workspace = state.inviteModalData[data.key].matchedDomains.find((workspace) => workspace.id === data.value)
    state.inviteModalData[data.key].selectedWorkspace = workspace
}

export function RESET_SELECTED_WORKSPACE (state, data) {
    state.inviteModalData[data.key].selectedWorkspace = []
}

export function SET_SELECTED_CONTACTS (state, data) {
    state.inviteModalData[data.key].selectedContacts.push(...data.data)
}

export function REPLACE_SELECTED_CONTACTS (state, data) {
    state.inviteModalData[data.key].selectedContacts = cloneDeep(data.data)
}

export function SET_SELECTED_CONTACT (state, data) {
    const contact = state.inviteModalData[data.key].matchedAccounts.find((contact) => contact.id === data.value)
    state.inviteModalData[data.key].selectedContact = contact
}

export function SET_PERSON_ON_ITEM (state, data) {
    state.inviteModalData[data.key].selectedContacts.push(data.contact)
}

export function CLEAR_PERSONS_ON_ITEM (state, data) {
    if (state.inviteModalData[data.key] && state.inviteModalData[data.key].selectedContacts) {
        state.inviteModalData[data.key].selectedContacts = []
    }
}

export function RESET_SELECTED_CONTACT (state, data) {
    state.inviteModalData[data.key].selectedContact = null
}

export function SET_SELECTED_WORKSPACE_TYPE (state, data) {
    state.inviteModalData[data.key].selectedWorkspaceType = data.value
}

export function RESET_SELECTED_WORKSPACE_TYPE (state, data) {
    state.inviteModalData[data.key].selectedWorkspaceType = null
}

export function SET_RENDERED (state, data) {
    state.inviteModalData[data.key].rendered = data.value
}

export function SET_IS_VALID (state, data) {
    state.inviteModalData[data.key].isFormValid = data.value
}

export function SET_CONTACT_FORM_DATA (state, data) {
    state.inviteModalData[data.key].contactFormData = data.data
}

export function SET_SELECTED_ACTION (state, data) {
    state.inviteModalData[data.key].selectedAction = data.value
}

export function SET_LOADING (state, data) {
    state.inviteModalData[data.key].loading = data.value
}

export function SET_IS_TABS_VISIBLE (state, data) {
    state.inviteModalData[data.key].isTabsVisible = data.value
}

export function SET_ACTIVE_STEP (state, data) {
    state.inviteModalData[data.key].activeStep = data.value
}

export function SET_SEARCH (state, data) {
    state.inviteModalData[data.key].search = data.search
}

export function SET_OFFSET (state, data) {
    state.inviteModalData[data.key].offset = data.value
}

export function SET_LAST_PAGE (state, data) {
    state.inviteModalData[data.key].isLastPage = data.value
}

export function SAVE_CONTACT (state, data) {
    const contactIdx = state.inviteModalData[data.key].matchedMyContacts.findIndex((contact) => contact.id === data.id)
    const contact = state.inviteModalData[data.key].matchedMyContacts[contactIdx]
    contact.save_only = true
    contact.invitation_status = InvitationStatuses.READY_TO_SAVE
    contact._newAdded = true
    contact._addedFromList = true

    state.inviteModalData[data.key].selectedContacts.push(contact)
    state.inviteModalData[data.key].selectedContactsToAction.save.push(getInviteContactData(contact))

    state.inviteModalData[data.key].matchedMyContacts.splice(contactIdx, 1)
}

export function SAVE_OR_INVITE_NEW_CONTACT (state, data) {
    let contact = null
    let contactType = ''

    if (state.inviteModalData[data.key].selectedWorkspace?.id) {
        contactType = 'workspace'
        contact = state.inviteModalData[data.key].selectedWorkspace
    }

    if (state.inviteModalData[data.key].selectedContact && !contact) {
        contactType = 'contact'
        contact = state.inviteModalData[data.key].selectedContact
    }

    if (state.inviteModalData[data.key].selectedWorkspaceType && !contact) {
        contactType = 'workspace'
        contact = state.inviteModalData[data.key].contactFormData
        contact.appType = state.inviteModalData[data.key].selectedWorkspaceType
    }

    if (contact) {
        let contactData = {
            id: null,
            email: contact.email,
            first_name: null,
            last_name: null,
            company: {
                address: {
                    address_line1: null,
                    address_line2: null,
                    city: null,
                    country: null,
                    label: null,
                    state: null,
                    zip: null,
                },
                name: null,
                type: null,
                id: null,
                app_type: state.inviteModalData[data.key].selectedWorkspaceType ? state.inviteModalData[data.key].selectedWorkspaceType : contact.appType,
                receiver_workspace_id: null,
            }
        }

        if (!isEmpty(contact.company && contact.company.id)) {// new Contact new Company or choose from dropdown
            contactData.company.id = contact.company.id
            contactData.company.name = contact.company.name
            contactData.first_name = contact.firstName
            contactData.last_name = contact.lastName
            contactData.company.app_type = contact.company.shared_workspace_type ? contact.company.shared_workspace_type : contact.appType
            contactData.company.receiver_workspace_id = contact.company.workspace_id
        } else if (contact.id && contactType === 'contact') {// On System Employee, which is not in current workspace
            contactData = getInviteContactData(contact)
        } else if (contact.company_name) {// Invitation VIA Trusted Domain Project/Task
            contactData.company.id = contact.id
            contactData.company.name = contact.company_name
            contactData.first_name = state.inviteModalData[data.key].contactFormData.firstName
            contactData.last_name = state.inviteModalData[data.key].contactFormData.lastName
            contactData.email = state.inviteModalData[data.key].contactFormData.email
            contactData.company.app_type = contact.app_type
            contactData.company.receiver_workspace_id = contact.id
        } else {// totally new Contact New Company
            contactData.first_name = contact.firstName
            contactData.last_name = contact.lastName
            contactData.company.address.address_line1 = contact.newCompany.address.address_line1
            contactData.company.address.address_line2 = contact.newCompany.address.address_line2
            contactData.company.address.city = contact.newCompany.address.city
            contactData.company.address.country = contact.newCompany.address.country.id
            contactData.company.address.label = contact.newCompany.address.label
            contactData.company.address.state = contact.newCompany.address.state?.code
                ? contact.newCompany.address.state.code
                : null
            contactData.company.address.zip = contact.newCompany.address.zip
            contactData.company.name = contact.newCompany.company_name
        }

        if (data.action === ContactActions.SAVE) {
            contact.invitation_status = 'readyToSave'
            contact.save_only = true

            state.inviteModalData[data.key].selectedContactsToAction.save.push(contactData)
        }

        if (data.action === ContactActions.SEND_INVITE) {
            contact.invitation_status = 'readyToSend'
            contact._toSend = true

            state.inviteModalData[data.key].selectedContactsToAction.invite.push(contactData)
        }

        contact._newAdded = true
        contact._addedFromList = true

        if (contactType === 'workspace') {
            contact.firstname = state.inviteModalData[data.key].contactFormData.firstName
            contact.lastname = state.inviteModalData[data.key].contactFormData.lastName
            contact.email = state.inviteModalData[data.key].contactFormData.email
            contact.company_id = contact.id
            contact.id = null

            if (!contact.company_name) {
                contact.company_name = !state.inviteModalData[data.key].contactFormData.newCompany.isNewCompany
                    ? state.inviteModalData[data.key].contactFormData.company?.name
                    : null
            }

            state.inviteModalData[data.key].selectedWorkspace = null
        }

        if (contactType === 'contact') {
            state.inviteModalData[data.key].selectedContact = null
        }

        if (!state.inviteModalData[data.key].selectMultiple) {
            state.inviteModalData[data.key].selectedContacts.splice(0, 1)
        }

        state.inviteModalData[data.key].selectedContacts.push(contact)
    }
}

export function INVITE_CONTACT (state, data) {
    const contactIdx = state.inviteModalData[data.key].matchedMyContacts.findIndex((contact) => contact.id === data.id)
    const contact = state.inviteModalData[data.key].matchedMyContacts[contactIdx]
    contact.invitation_status = 'readyToSend'
    contact._newAdded = true
    contact._addedFromList = true

    state.inviteModalData[data.key].selectedContacts.push(contact)
    state.inviteModalData[data.key].selectedContactsToAction.invite.push(getInviteContactData(contact))

    if (contact.is_app_type_set || contact.app_type) {
        state.inviteModalData[data.key].matchedMyContacts.splice(contactIdx, 1)
    }
}

export function INVITE_SAVED (state, data) {
    const contactIdx = state.inviteModalData[data.key].selectedContacts.findIndex((contact) => contact.id === data.id)
    const contact = cloneDeep(state.inviteModalData[data.key].selectedContacts[contactIdx])
    contact.invitation_status = 'readyToSend'
    contact.save_only = false

    state.inviteModalData[data.key].selectedContactsToAction.invite.push(getInviteContactData(contact))
    state.inviteModalData[data.key].selectedContacts.splice(contactIdx, 1, contact)
}

export function REMOVE_SELECTED (state, data) {
    const contactIdx = state.inviteModalData[data.key].selectedContacts.findIndex((contact) => contact.id === data.id)
    const contact = state.inviteModalData[data.key].selectedContacts[contactIdx]
    contact._toSend = false
    contact.save_only = false
    contact._accepted = false
    contact._pending = false
    contact._newAdded = false

    contact.invitation_status = null

    if (contact._newAppTypeAdded) {
        contact.app_type = null
        contact._newAppTypeAdded = false
    }

    if (contact._addedFromList) {
        /**
         * Previously we pushed removed contact to the back of the list, but we want to put it at same position were it was
         */
        const originalIndex = state.inviteModalData[data.key].matchedMyContactsClone.findIndex((contact) => contact.id === data.id)
        const indexInContactsList = state.inviteModalData[data.key].matchedMyContacts.findIndex((contact) => contact.id === data.id)

        if (indexInContactsList === -1 && originalIndex > -1) {
            state.inviteModalData[data.key].matchedMyContacts.splice(originalIndex, 0, contact)
        }
    }
    state.inviteModalData[data.key].selectedContacts.splice(contactIdx, 1)
}

export function REMOVE_ALL_SELECTED (state, key) {
    const objectToUpdate = state.inviteModalData[key]
    if (objectToUpdate) {
        state.inviteModalData[key].selectedContacts = []
    }
}

export function ADD_REMOVED_CONTACT (state, data) {
    state.inviteModalData[data.key].selectedContactsToAction.remove.push(getInviteContactData(data.contact))
}

export function REMOVE_CONTACT_TO_SAVE (state, data) {
    const contactIdx = state.inviteModalData[data.key].selectedContactsToAction.save.findIndex((contact) => contact.id === data.id)

    state.inviteModalData[data.key].selectedContactsToAction.save.splice(contactIdx, 1)
}

export function REMOVE_CONTACT_TO_INVITE (state, data) {
    const contactIdx = state.inviteModalData[data.key].selectedContactsToAction.invite.findIndex((contact) => contact.id === data.id)

    state.inviteModalData[data.key].selectedContactsToAction.invite.splice(contactIdx, 1)
}

export function REMOVE_CONTACT_TO_REMIND (state, data) {
    const contactIdx = state.inviteModalData[data.key].selectedContactsToAction.remind.findIndex((contact) => contact.id === data.id)

    state.inviteModalData[data.key].selectedContactsToAction.remind.splice(contactIdx, 1)
}

export function INVITATION_REMIND (state, data) {
    const contactIdx = state.inviteModalData[data.key].selectedContacts.findIndex((contact) => contact.id === data.id)
    const contact = state.inviteModalData[data.key].selectedContacts[contactIdx]

    contact.invitation_status = 'readyToRemind'

    state.inviteModalData[data.key].selectedContactsToAction.remind.push(getInviteContactData(contact))
}

export function SET_GROUPED_COMPANIES_CONTACTS_LIST (state, data) {
    state.inviteModalData[data.key].groupedCompaniesContactsList = data.value
}

export function RESET_GROUPED_COMPANIES_CONTACTS_LIST (state, key) {
    state.inviteModalData[key].groupedCompaniesContactsList = []
}

export function SET_ON_ITEM_CONTACTS_LIST (state, data) {
    state.inviteModalData[data.key].onItemContactsList = data.value
}

export function RESET_ON_ITEM_CONTACTS_LIST (state, key) {
    const objectToUpdate = state.inviteModalData[key]
    if (objectToUpdate) {
        state.inviteModalData[key].onItemContactsList = []
    }
}

export function SET_CACHED_DATA_LOAD_REQUIRED (state, data) {
    state.inviteModalData[data.key].isCachedDataLoadRequired = data.value
}

export function SET_MODEL (state, data) {
    state.inviteModalData[data.key].selectedContactsToAction.model_id = data.id
    state.inviteModalData[data.key].selectedContactsToAction.model_type = data.type
}

export function SET_SECTION (state, data) {
    state.inviteModalData[data.key].selectedContactsToAction.model_section = data.value
}

export function SET_SKIP_NOTIFICATIONS (state, data) {
    state.inviteModalData[data.key].selectedContactsToAction.skip_notifications = data.value
}

export function SET_SELECTED_CONTACT_WORKSPACE_TYPE (state, data) {
    const selectedWorkspaceType = state.inviteModalData[data.key].selectedWorkspaceType
    const selectedContact = state.inviteModalData[data.key].selectedContact
    const contactSendIdx = state.inviteModalData[data.key].selectedContactsToAction.invite.findIndex((item) => item.id === data.value.id)
    const contactSaveIdx = state.inviteModalData[data.key].selectedContactsToAction.save.findIndex((item) => item.id === data.value.id)
    let contactSend = state.inviteModalData[data.key].selectedContactsToAction.invite[contactSendIdx]
    let contactSave = state.inviteModalData[data.key].selectedContactsToAction.save[contactSaveIdx]

    selectedContact.app_type = selectedWorkspaceType
    selectedContact._newAppTypeAdded = true

    if (contactSend) {
        contactSend.app_type = selectedWorkspaceType
    }

    if (contactSave) {
        contactSave.app_type = selectedWorkspaceType
    }

    const contactIdx = state.inviteModalData[data.key].matchedMyContacts.findIndex((contact) => contact.id === selectedContact.id)
    state.inviteModalData[data.key].matchedMyContacts.splice(contactIdx, 1)
}

export function SET_SELECTED_CONTACT_FROM_SELECTED_CONTACTS (state, data) {
    state.inviteModalData[data.key].selectedContact = data.value
}

export function SET_IS_OTHER_RECORDS_EXIST (state, data) {
    state.inviteModalData[data.key].isOtherRecordsExist = data.value
}

export function SET_SELECTED_ADDED_CONTACTS_PROJECT_DIRECTORY (state, data) {
    state.inviteModalData[data.key].selectedAddedContactsProjectDirectory = data.value
}
