import { defineComponent } from 'vue'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import PageHeader from '@/components/page-header/PageHeader.vue'
import { RouteLocationRaw } from 'vue-router'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import projectFundingClient from '../../api-clients/projectFundingClient'
import ProjectFundingSummaryInt from '../../interfaces/ProjectFundingSummary'
import ProjectFundingFundsList from '../../components/project-funding-funds-list/ProjectFundingFundsList.vue'
import ProjectFundingFund from '../../interfaces/ProjectFundingFund'
import projectObjMixin from '@/modules/projects/mixins/projectObjMixin'
import { FundExportSortTypes, MenuItems } from '../../enums/ProjectFundingEnums'
import ProjectFundingSummary from '../../components/project-funding-summary/ProjectFundingSummary.vue'
import SelectFundTypeModal from '../../components/modals/select-fund-type/SelectFundTypeModal.vue'
import { mapActions as piniaMapActions, mapState as piniaMapState } from 'pinia'
import { mapState as vuexMapState } from 'vuex'
import { projectFundingStore } from '../../stores/projectFundingStore'
import useLoader from '@/composables/useLoader.ts'
import ProjectFundingRules from '@/io-modules/project-funding/components/project-funding-rules/ProjectFundingRules.vue'
import Timeline from '@/components/timeline/timeline.vue'
import TimelineLogs from '@/interfaces/components/timeline/TimelineLogs'
import ProjectFundingTimeline from '@/io-modules/project-funding/interfaces/ProjectFundingTimeline'
import FundsExportTableModal from '@/io-modules/project-funding/components/modals/export-table/FundsExportTableModal.vue'

export default defineComponent({
    name: 'ProjectFundingDetails',
    components: {
        FundsExportTableModal,
        ProjectFundingRules,
        SelectFundTypeModal,
        ProjectFundingSummary,
        ProjectFundingFundsList,
        SearchInput,
        PageHeader,
        ActionDropdown,
        Timeline,
    },
    mixins: [breadcrumbsMixin, projectObjMixin],
    setup () {
        const { loading, load } = useLoader()

        return { loading, load }
    },
    data () {
        return {
            fundsList: null as ProjectFundingFund[],
            projectFundingSummary: null as ProjectFundingSummaryInt,
            activeTab: MenuItems.APPLIED_FUNDS,
            menuItemsEnum: MenuItems,
            addFundModal: false,
            timelineHistory: [] as TimelineLogs[],
            menuItems: [
                {
                    name: this.$t('Applied Funds'),
                    number: MenuItems.APPLIED_FUNDS,
                },
                // {
                //     name: this.$t('Funding Rules'),
                //     number: MenuItems.FUNDING_RULES,
                // },
                {
                    name: this.$t('Timeline'),
                    number: MenuItems.TIMELINE,
                },
            ],
            exportModal: {
                open: false,
                sortType: FundExportSortTypes.PACKAGE
            },
            FundExportSortTypes
        }
    },
    computed: {
        ...vuexMapState('project', {
            projectObj: (state: any) => state.projectObj,
        }),
        ...piniaMapState(projectFundingStore, [
            'needToUpdateData',
        ]),
        routeBack (): RouteLocationRaw {
            return {
                name: 'project-dashboard',
                params: {
                    pid: this.$route.params.pid,
                },
            }
        },
    },
    watch: {
        async needToUpdateData (): Promise<void> {
            if (this.needToUpdateData) {
                await this.getPageData()
                this.setDataUpdation(false)
            }
        },
    },
    async created () {
        await this.loadProject()
        this.setLocalBreadcrumbs()
        await this.getPageData()
        await this.$store.dispatch('appStore/setProjectCurrency', { projectId: this.$route.params.pid })
    },
    unmounted () {
        this.emptyBreadcrumbs()
    },
    methods: {
        ...piniaMapActions(projectFundingStore, [
            'setDataUpdation',
            'setProjectFundingSummary',
        ]),
        changeActiveTab (tab: MenuItems): void {
            this.activeTab = tab
        },
        onSearchChange (search: string): void {
            this.getProjectFunds(search)
        },
        addFund (): void {
            this.addFundModal = !this.addFundModal
        },
        async getPageData (): Promise<void> {
            return this.load(async () => {
                await Promise.all([this.getProjectFunds(), this.getProjectSummary(), this.getFundTimeline()])
            })
        },
        async getProjectFunds (search: string = ''): Promise<void> {
            const fundsResponse = await projectFundingClient.getProjectFundingFunds(this.projectObj.project_local_id, search)
            if (fundsResponse && fundsResponse.data) {
                this.fundsList = fundsResponse.data.items
            }
        },
        async getProjectSummary (): Promise<void> {
            const summaryResponse = await projectFundingClient.getProjectFundingSummary(this.projectObj.project_local_id)
            if (summaryResponse && summaryResponse.data) {
                this.projectFundingSummary = summaryResponse.data
                this.setProjectFundingSummary(summaryResponse.data)
            }
        },
        async getFundTimeline (): Promise<void> {
            try {
                const fundTimelineResponse = await projectFundingClient.getProjectFundingTimeline(this.projectObj.project_local_id)
                if (fundTimelineResponse && fundTimelineResponse.data) {
                    this.timelineHistory = fundTimelineResponse.data.items.map((event: ProjectFundingTimeline) => ({
                        id: event.id,
                        employee_mongo_id: event.user.id,
                        user: `${ event.user.firstname } ${ event.user.lastname }`,
                        company_name: event.user.company_name,
                        company_type: event.user.company_app_type,
                        created_at: event.created_at,
                        content: event.content.replace(/\n/g, '<br>'),
                    }))
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
        toggleExportModal (open: boolean, type: FundExportSortTypes = FundExportSortTypes.PACKAGE): void {
            this.exportModal.open = open
            this.exportModal.sortType = type
        },
        setLocalBreadcrumbs (): void {
            this.setBreadcrumbs([
                {
                    text: this.$t('Projects'),
                    to: { name: 'project-list' },
                },
                {
                    text: this.projectObj._project_name || this.projectObj._name || this.$t('Project'),
                    to: {
                        name: 'project-dashboard',
                        pid: this.$route.params.pid,
                    },
                    projId: this.projectObj?.external_id || this.projectObj?.formatted_id || ' ',
                },
                {
                    text: this.$t('Funding'),
                },
            ])
        },
    },
})
