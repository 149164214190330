export enum BidPackageStatus {
    Draft = 'Draft',
    InProgress = 'In Progress',
    Sent = 'RFP Sent',
    TentativelyAccepted = 'Tentatively Accepted',
    Approved = 'Approved',
    PendingContract = 'Pending Contract',
    ExecutedContract = 'Executed Contract',
    OutstandingContract = 'Project w/outstanding Contract',
    Cancelled = 'Cancelled',
}
