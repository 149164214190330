import { defineComponent } from 'vue'
import Table from '@/components/table/Table.vue'
import DropdownButton from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import FundingSourceInterface from '@/io-modules/invoices/interfaces/FundingSource'
import FundingSource from '@/io-modules/invoices/enums/FundingSource'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import CellType from '@/components/table/CellType'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import { mapState } from 'pinia'

export default defineComponent({
    name: 'FundingSourcesTable',
    components: {
        Table,
        DropdownButton,
    },
    props: {
        init: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            CellType,
            randomID: 0
        }
    },
    computed: {
        ...mapState(invoiceViewStore, [
            'fundingSources',
            'isEditingMode',
            'appliedFundingSources',
            'appliedFundingAmount'
        ]),

        ...mapState(invoiceSoVStore, ['netInvoiceValue']),

        getAvailableFundingSources (): FundingSourceInterface[] {
            const fundingSourcesIds = this.appliedFundingSources.map(item => item.id)
            return this.fundingSources.filter(item => !fundingSourcesIds.includes(item.id))
        },

        getAppliedFundingSources (): FundingSourceInterface[] {
            if (!this.isEditingMode && !this.appliedFundingSources.length) {
                return [{ id: 0 }]
            }

            return this.appliedFundingSources
        },

        headers (): HeaderTable[] {
            const headers: HeaderTable[] = [
                {
                    text: this.$t('Funding Source Name'),
                    valueType: CellType.Dropdown,
                    value: 'name',
                    customValue: (item: FundingSourceInterface): string => {
                        if (!item.name) {
                            return '-'
                        }

                        return `${ item.name } - ${ this.$filters.formatProjectCurrencyInt(item.unallocated_amount) }`
                    },
                },
                {
                    text: this.$t('Applied Amount'),
                    valueType: CellType.InputNumInt,
                    field: FundingSource.Applied,
                    value: 'covered_amount',
                    customValue: (item: FundingSourceInterface): string => {
                        if (!item.name) {
                            return '-'
                        }

                        return this.$filters.formatProjectCurrencyInt(item.covered_amount)
                    },
                    sign: '$',
                    align: 'end',
                    maxValue: (item: FundingSourceInterface) => {
                        const maxAmount = !item.unallocated_amount || item.unallocated_amount >= this.netInvoiceValue ? this.netInvoiceValue : Math.max(0, item.unallocated_amount)
                        return -1 === Math.sign(this.netInvoiceValue) ? 0 : maxAmount / process.env.SCALE_FACTOR
                    },
                    minValue: (item: FundingSourceInterface) => {
                        const maxAmount = !item.unallocated_amount || item.unallocated_amount >= this.netInvoiceValue ? this.netInvoiceValue : Math.max(0, item.unallocated_amount)
                        return -1 === Math.sign(this.netInvoiceValue) ? maxAmount / process.env.SCALE_FACTOR : 0
                    },
                    onValueChange: (item: FundingSourceInterface, field: string, data: number): void => {
                        if (this.init) {
                            return
                        }

                        item.covered_amount = data > this.netInvoiceAmount ? this.netInvoiceAmount : data
                        item.covered_factor = (item.covered_amount / this.netInvoiceValue) * 100 * process.env.SCALE_FACTOR
                    },
                },
                {
                    text: this.$t('% of Invoice'),
                    valueType: CellType.InputNumInt,
                    field: FundingSource.PercentOfInvoice,
                    value: 'covered_factor',
                    customValue: (item: FundingSourceInterface): string => {
                        if (!item.name) {
                            return '-'
                        }

                        return this.$filters.formatPercentInt(item.covered_factor)
                    },
                    sign: '%',
                    align: 'end',
                    maxValue: (item: FundingSourceInterface) => {
                        return 100
                    },
                    minValue: (item: FundingSourceInterface) => {
                        return 0
                    },
                    onValueChange: (item: FundingSourceInterface, field: string, data: number): void => {
                        if (this.init) {
                            return
                        }

                        const applied = ((data * this.netInvoiceValue) / 100) / process.env.SCALE_FACTOR
                        if (applied > item.unallocated_amount && item.id) {
                            setTimeout(() => { item.covered_factor = (item.unallocated_amount / this.netInvoiceValue) * 100 * process.env.SCALE_FACTOR }, 400)
                            item.covered_amount = item.unallocated_amount
                        } else {
                            item.covered_factor = data
                            item.covered_amount = applied
                        }
                    },
                },
                {
                    text: this.$t('Fund Remaining Balance'),
                    valueType: CellType.Text,
                    value: 'unallocated_amount',
                    customValue: (item: FundingSourceInterface): string => {
                        if (item.id) {
                            const remainingBalance = item.unallocated_amount - item.covered_amount
                            return this.$filters.formatProjectCurrencyInt(remainingBalance)
                        } else {
                            return this.$filters.formatProjectCurrencyInt(0)
                        }
                    },
                    align: 'end',
                }
            ]

            if (this.isEditingMode) {
                headers.push(
                    {
                        text: '',
                        valueType: CellType.Actions,
                        customValue: (): object[] => {
                            return [
                                {
                                    variant: 'link',
                                    icon: 'icon-trash',
                                    tooltip: { text: this.$t('Delete') },
                                    onClick: (item: FundingSourceInterface, index: number): void => this.deleteFundingSource(item, index),
                                },
                            ]
                        },
                        sort: false,
                        align: 'end',
                    }
                )
            }

            return headers
        },
    },
    mounted () {
        this.randomID += 1
    },
    methods: {
        deleteFundingSource (item: FundingSourceInterface, index: number): void {
            if (this.appliedFundingSources[index] && this.appliedFundingSources[index].id === item.id) {
                this.appliedFundingSources.splice(index, 1)
            }
        },

        selectFundingSource (item: FundingSourceInterface, index: number): void {
            item.covered_amount = this.appliedFundingSources[index].covered_amount > item.unallocated_amount ? Math.max(0, item.unallocated_amount) : this.appliedFundingSources[index].covered_amount
            item.covered_factor = Math.abs(item.covered_amount / this.netInvoiceValue) * 100 * process.env.SCALE_FACTOR
            this.appliedFundingSources[index] = item
        },

        updateValue (header: HeaderTable, item: FundingSourceInterface,  data: number | string): void {
            if (header.onValueChange instanceof Function) {
                header.onValueChange(item, header.field, data)
            } else {
                item[header.field] = data
            }
        },

        goToFunds (): void {
            this.$router.push({
                name: 'project-funding'
            })
        }
    },
})
