import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { cloneDeep, throttle } from 'lodash'
import dayjs from 'dayjs'

import appTypes from '@/base/appTypes.ts'
import punchItemsClient from '@/io-modules/punch-lists/api-clients/punchItemsClient.ts'
import punchListsClient from '@/io-modules/punch-lists/api-clients/punchListsClient.ts'
import useLoader from '@/composables/useLoader.ts'
import usePunchItemStatus from '@/io-modules/punch-lists/composables/usePunchItemStatus.ts'
import dateTimeHelper from '@/helpers/dateTime.ts'
import getAppTypeStatusPillVariant from '@/helpers/getAppTypeStatusPillVariant.ts'

import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import StampSelect from '@/io-modules/punch-lists/components/stamp-select/StampSelect.vue'
import DrawingReferenceSelect from '@/components/drawing-reference-select/DrawingReferenceSelect.vue'
import JobsiteLocationsSelect from '@/components/jobsite-locations-select/JobsiteLocationsSelect.vue'
import StampPreview from '@/io-modules/punch-lists/components/stamp-preview/StampPreview.vue'
import FileUploaderWithTable from '@/components/file-uploader-with-table/FileUploaderWithTable.vue'
import StampChangeDialog from '@/io-modules/punch-lists/components/stamp-change-dialog/StampChangeDialog.vue'
import IOTextarea from '@/components/atoms/IOTextarea/IOTextarea.vue'
import TimelineModal from '@/components/timeline-modal/TimelineModal.vue'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'

import { Status as PunchListStatus } from '@/io-modules/punch-lists/enums/PunchList.ts'
import { Status as PunchItemStatus } from '@/io-modules/punch-lists/enums/PunchItem.ts'
import { MenuPunchList } from '@/io-modules/punch-lists/interfaces/PunchList.ts'
import {
    AutocompleteItem,
    InitialData,
    PunchItem,
    PunchItemDocument,
    PunchItemPayload,
    PunchListOption,
    StatusOption,
} from '@/io-modules/punch-lists/interfaces/PunchItem.ts'
import { Company, Person } from '@/io-modules/punch-lists/interfaces/ProjectDirectory.ts'
import { Reference } from '@/components/drawing-reference-select/interfaces/Sheet.ts'
import { JobsiteLocation } from '@/components/jobsite-locations-select/interfaces/JobsiteLocations.ts'
import { FileInterface } from '@/components/file-uploader-with-table/interfaces/File.ts'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import FeatureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'
import JobsiteLocationsPreview from '@/components/jobsite-locations/JobsiteLocationsPreview.vue'

export default defineComponent({
    name: 'PunchItemOperationsSlideout',

    components: {
        JobsiteLocationsPreview,
        ActionDropdown,
        IOModal,
        DrawingReferenceSelect,
        JobsiteLocationsSelect,
        StampSelect,
        StampPreview,
        FileUploaderWithTable,
        StampChangeDialog,
        IOTextarea,
        TimelineModal,
    },

    props: {
        defaults: {
            type: Object as () => InitialData,
            default: () => ({}),
        },

        preservingFields: {
            type: Array as () => string[],
            default: [],
        },
    },
    mixins: [FeatureFlagsMixin],

    emits: ['punchItemSaved', 'close'],

    setup () {
        const { loading: projectDirectoryCompaniesLoading, load: loadProjectDirectoryCompanies } = useLoader()
        const { loading: punchListsLoading, load: loadPunchLists } = useLoader()
        const { loading: punchItemLoading, load: loadPunchItem } = useLoader()
        const { loading: existingTitlesForAutofillLoading, load: loadExistingTitlesForAutofill } = useLoader()

        return {
            projectDirectoryCompaniesLoading, loadProjectDirectoryCompanies,
            punchListsLoading, loadPunchLists,
            punchItemLoading, loadPunchItem,
            existingTitlesForAutofillLoading, loadExistingTitlesForAutofill,
            v$: useVuelidate(),
            punchItemStatusInfo: usePunchItemStatus(),
            getAppTypeStatusPillVariant,
        }
    },

    data () {
        const initialData: InitialData = this.getInitialData()

        return {
            projectDirectoryCompanies: initialData.projectDirectoryCompanies,
            punchLists: initialData.punchLists,
            punchItemToEdit: null as PunchItem,

            id: initialData.id,

            stamp: initialData.stamp,
            title: initialData.title,
            existingTitlesForAutofill: null as AutocompleteItem[],
            status: initialData.status,
            dueDate: initialData.dueDate,
            comment: initialData.comment,
            punchList: initialData.punchList,

            originator: initialData.originator,
            members: initialData.members,
            ballInCourtMember: initialData.ballInCourtMember,
            drawingReferences: initialData.drawingReferences,
            jobsiteLocations: initialData.jobsiteLocations,
            jobsiteLocationsIds: [],
            files: initialData.files,
            timelineModalShown: false,
        }
    },

    computed: {
        ...mapGetters('appStore', { authData: 'getAuthData' }),
        ...mapGetters('project', { projectId: 'projectCompanyMongoId' }),


        isJobsiteLocationsFFEnabled (): boolean {
            return this.isFeatureEnabled(FeatureFlagsConsts.JOBSITE_LOCATIONS_MODAL_V3, false)
        },

        statusOptions (): StatusOption[] {
            return this.getStatusOptions() // cached as computed to avoid method call on every component update
        },

        operation (): { title: string, action: string, submit: Function } {
            return this.id
                ? {
                    title: this.$t('Edit Punch Item'),
                    action: this.$t('Save'),
                    submit: async (): Promise<void> => {
                        await this.editPunchItem()
                        this.$emit('close')
                    },
                }
                : {
                    title: this.$t('Create Punch Item'),
                    action: this.$t('Create'),
                    submit: async (): Promise<void> => {
                        await this.createPunchItem()
                        this.$emit('close')
                    },
                }
        },

        datePickerSettings (): { formatted: string, noClearButton: boolean, 'only-date': boolean } {
            return {
                formatted: dateTimeHelper.getDateFormat(),
                noClearButton: true,
                'only-date': true,
            }
        },

        activePunchLists (): PunchListOption[] {
            return this.punchLists
                ?.filter((punchList: MenuPunchList) => punchList.status === PunchListStatus.OPEN)
                .map((punchList: MenuPunchList) => ({
                    id: punchList.id,
                    label: punchList.name,
                    status: punchList.status,
                }))
        },

        punchItemPayload (): PunchItemPayload {
            const payload =  {
                id: this.id,
                stamp_id: this.stamp?.id,
                title: this.title,
                status: (this.status as StatusOption).id,
                due_date: this.dueDate,
                comment: this.comment,
                punch_list_id: (this.punchList as PunchListOption)?.id || null,
                tags: [],
                document_ids: this.files.map((file: FileInterface) => file.id),
                members_ids: this.members.map((member: Person) => member.id),
                ball_in_court_id: this.ballInCourtMember?.id || null,
                jobsite_locations_ids: [],
                drawing_annotation: this.drawingReferencePayload,
            }
            if (this.isJobsiteLocationsFFEnabled) {
                payload.jobsite_locations_ids = this.jobsiteLocationsIds
            } else {
                payload.jobsite_locations_ids = this.jobsiteLocations.map((jobsiteLocationPath: JobsiteLocation[]) => jobsiteLocationPath[jobsiteLocationPath.length - 1]?.id)
            }

            return payload
        },

        drawingReferencePayload (): Partial<Reference> {
            const [drawingReference] = this.drawingReferences

            return drawingReference
                ? {
                    id: drawingReference.id,
                    version_id: drawingReference.drawing.current_version_id,
                    xfdf: drawingReference.xfdf,
                    uuid: drawingReference.uuid,
                }
                : null
        },

        payloadValid (): boolean {
            return [!this.v$.$invalid, this.title, this.stamp].every(Boolean)
        },

        timelineRequestUrl (): string {
            return `/ca/punch-item/${ this.projectId }/punch-lists/punch-items/${ this.id }/timeline`
        },
    },

    created () {
        this.searchExistingTitlesForAutofill = throttle(this.searchExistingTitlesForAutofill, 1000)

        this.getProjectDirectoryCompanies()
        this.preparePunchLists()
        this.id
            ? this.getPunchItem()
            : this.setLoggedInUserAsOriginator()
    },

    methods: {
        getInitialData (): InitialData {
            const initialData: InitialData = {
                projectDirectoryCompanies: null,
                punchLists: null,
                id: '',
                stamp: null,
                title: '',
                status: null,
                dueDate: dayjs().format('YYYY-MM-DD 12:00:00'),
                comment: '',
                punchList: null,
                originator: null,
                members: [],
                ballInCourtMember: null,
                drawingReferences: [],
                jobsiteLocations: [],
                files: [],
            }
            const setDefinedDefaultValue = (key: string) => this.defaults[key] && (initialData[key] = this.defaults[key])

            Object
                .keys(this.defaults)
                .forEach(setDefinedDefaultValue)
            initialData.status = initialData.status && this.getStatusOption(initialData.status as PunchItemStatus)

            return cloneDeep(initialData)
        },

        getProjectDirectoryCompanies (): Promise<void> {
            if (this.projectDirectoryCompanies) {
                return
            }

            return this.loadProjectDirectoryCompanies(async (): Promise<void> => {
                const { data } = await punchItemsClient.getProjectDirectoryCompanies(this.projectId)
                this.projectDirectoryCompanies = data.items
            })
        },

        getPunchLists (): Promise<void> {
            return this.loadPunchLists(async (): Promise<void> => {
                const { data } = await punchListsClient.getPunchLists(this.projectId)
                this.punchLists = data
            })
        },

        getPunchItem (): Promise<void> {
            return this.loadPunchItem(async (): Promise<void> => {
                const { data } = await punchItemsClient.getPunchItem(this.projectId, this.id)
                this.punchItemToEdit = data

                this.setPunchItemToEdit()
                this.setPunchList()
            })
        },

        createPunchItem (queued: boolean = false): Promise<void> {
            return this.payloadValid && this.loadPunchItem(async (): Promise<void> => {
                const { data } = await punchItemsClient.createPunchItem(this.projectId, this.punchItemPayload)
                this.$emit('punchItemSaved', data, queued)
            })
        },

        editPunchItem (): Promise<void> {
            return this.payloadValid && this.loadPunchItem(async (): Promise<void> => {
                const { data } = await punchItemsClient.editPunchItem(this.projectId, this.punchItemPayload)
                this.$emit('punchItemSaved', data)
            })
        },

        async preparePunchLists (): Promise<void> {
            !this.punchLists && await this.getPunchLists()
            this.punchList && this.setPunchList()
        },

        setPunchItemToEdit (): void {
            this.id = this.punchItemToEdit.id
            this.stamp = this.punchItemToEdit.stamp
            this.title = this.punchItemToEdit.title
            this.status = this.getStatusOption(this.punchItemToEdit.status)
            this.dueDate = this.punchItemToEdit.due_date
            this.comment = this.punchItemToEdit.comment || ''
            this.originator = this.punchItemToEdit.created_by
            this.members = this.punchItemToEdit.members
            this.ballInCourtMember = this.punchItemToEdit.ball_in_court
            this.jobsiteLocations = this.punchItemToEdit.jobsite_locations
            this.punchList = this.punchItemToEdit.punch_list_id
            this.drawingReferences = this.punchItemToEdit.drawing_annotation ? [this.punchItemToEdit.drawing_annotation] : []
            this.files = this.punchItemToEdit.documents.map((document: PunchItemDocument) => ({
                id: document.id,
                name: document.file_name,
                fileSize: document.file_size,
            }))
        },

        setPunchList (): void {
            this.punchList = this.activePunchLists?.find((punchList: PunchListOption) => punchList.id === this.punchList)
        },

        setLoggedInUserAsOriginator (): void {
            this.originator = {
                id: this.authData.u_mongo,
                name: `${ this.authData.u_firstname } ${ this.authData.u_lastname }`,
                image_exists: Boolean(this.authData.u_photo),
                position: this.authData.u_role_name,
            }
        },

        searchExistingTitlesForAutofill (title: string): void {
            if (title.length < 3) {
                return
            }
            this.loadExistingTitlesForAutofill(async (): Promise<void> => {
                const { data } = await punchItemsClient.getPunchItemTitles(this.projectId, { search_string: title })
                this.existingTitlesForAutofill = data.items
            })
        },

        autocomplete (autocompleteItem: AutocompleteItem): void {
            this.title = autocompleteItem.title

            // overwrite only if it wasn't provided by default
            !this.getInitialData().stamp && (this.stamp = autocompleteItem.stamp)
        },

        async createAndAddNext (): Promise<void> {
            await this.createPunchItem(true)
            this.reset()

            ;(this.$refs.titleInput as HTMLInputElement).focus()
        },

        reset (): void {
            const resetValues = this.getInitialData()
            Object
                .keys(resetValues)
                .forEach((key: string) => this.preservingFields.includes(key) || (this[key] = resetValues[key]))
        },

        getStatusOption (status: PunchItemStatus): StatusOption {
            return this
                .getStatusOptions()
                .find((statusOption: StatusOption) => statusOption.id === status)
        },

        getStatusOptions (): StatusOption[] {
            return Object
                .keys(this.punchItemStatusInfo)
                .map((status: string) => ({
                    ...this.punchItemStatusInfo[<PunchItemStatus>status],
                    id: <PunchItemStatus>status,
                }))
        },

        getCompanyName (personId: string): string {
            return this.projectDirectoryCompanies
                .find((company: Company) => company.persons
                    .map((person: Person) => person.id)
                    .includes(personId)).name
        },

        getCompanyAppType (companyName: string): string {
            return this.projectDirectoryCompanies.find((company: Company) =>
                company.name === companyName).app_type || appTypes.TYPE_OFF_SYSTEM
        },

        close (): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to exit?'),
                caption: this.$t('There are unsaved changes. If you exit now, your changes will be lost.'),
                icon: 'icon-alert-triangle',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Exit'),
                        class: 'io-btn-primary',
                        action: (): void => {
                            this.$emit('close')
                        },
                    },
                ],
            })
        },
    },

    validations () {
        return {
            title: { required },
        }
    },
})
