<template>
    <div class="io-others-tab">
        <template v-if="otherMarkups.length">
            <SingleMarkup
                v-for="markup in otherMarkups"
                :key="'other-' + markup.id"
                :index="2"
                :data="markup"
                @select-annotation="selectAnnotation"
                @update-annotations="updateAnnotations"
                @delete-basic-annotation="deleteBasicAnnotation"
            />
        </template>

        <Placeholder
            v-else
            :subtitle="$t('There are no annotations on this drawing yet.')"
            iconColor="var(--main-lighten-2)"
            icon="icon-book"
        />
    </div>
</template>

<script lang="ts" src="./Others.ts"></script>
<style lang="scss" src="./Others.scss" scoped></style>
