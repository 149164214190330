import { defineComponent, PropType } from 'vue'

import { Notification } from '@/layouts/parts/menu-left/interfaces/MenuLeftInterface'
import menuLeftClient from '@/layouts/parts/menu-left/api-clients/menuLeftClient'
import notificationMixin from '@/layouts/parts/top-bar/mixins/notificationMixin.js'

export default defineComponent({
    name: 'General',
    props: {
        notificationData: { type: Object as PropType<Notification>, required: true }
    },
    mixins: [notificationMixin],
    methods: {
        onNotificationWrapperClick (): void {
            if (this.notificationData.isUnread) {
                this.markAsRead()
            }

            this.$emit('notificationCenterClose')
            this.redirectToNotificationResource(this.notificationData)
        },
        redirectToRoute (rawLocation: object): void {
            const routeData = this.$router.resolve(rawLocation)

            if (routeData.href !== this.$route.path) {
                this.$router.push(rawLocation)
            }
        },
        async markAsRead (): Promise<void> {
            try {
                await menuLeftClient.markSingleNotificationsAsRead(this.notificationData)
            } catch (error) {
                if (this.notificationData?.actionType === 'project-listing-export-ready') {
                    this.errorHandle(error, true)
                } else {
                    this.errorHandle(error)
                }
            }
        },
    },
})
