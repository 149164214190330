import { defineComponent } from 'vue'
import { PillSize, PillVariant } from './enums/StatusPillEnums'

export default defineComponent({
    name: 'StatusPill',
    props: {
        variant: { type: String as () => PillVariant, default: '' },
        size: { type: String as () => PillSize, default: PillSize.NORMAL },
        text: { type: [String, Number], required: true },
    },

    computed: {
        sizeClassName (): string {
            return `io-${ this.size }`
        },
        variantClassName (): string {
            return this.variant.includes('io-')
                ? this.variant
                : `io-${ this.variant }`
        },
        pillClasses (): string[] {
            return [this.sizeClassName, this.variantClassName]
        },
        formattedText (): string | number {
            if (typeof this.text !== 'string') {
                return this.text
            }

            switch (this.text.toLowerCase()) {
                case 'gc':
                    return 'GC'
                case 'sc':
                case 'sub':
                    return 'Sub'
                case 'design':
                case 'ac':
                    return 'Design'
                case 'rep':
                case 'dev':
                    return 'Rep'
                case 'owner':
                    return 'Owner'
                case 'off-system':
                    return 'Off-system'
                case 'us':
                    return 'US'
                case 'europe':
                    return 'Europe'
                case 'eu':
                    return 'Europe'
                case 'ksa':
                    return 'KSA'
                default:
                    return this.text
            }
        }
    }
})
