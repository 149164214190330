import { defineComponent, PropType } from 'vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import { mapState } from 'vuex'
import { paths } from '@/interfaces/api/apidefinitions'
export type ProjectSummary = paths['api/v1/{subdomain}/{apptype}/project-summary/{id}']['get']['responses']['200']['content']['application/json']
import { defineComponent, PropType } from 'vue'
import { InvitationModelTypes } from '@/interfaces/components/invite-modal/InviteModalModelTypes.ts'
import { mapState } from 'vuex'
import { Contact } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'
import inviteModalClient from '@/components/invite-modal-v3/api-clients/inviteModalClient.ts'

export default defineComponent({
    components: {
        IOSingleInfoRow,
    },
    props: {
        summaryData: { type: Object as PropType<ProjectSummary>, required: true }
    },
    data () {
        return {
            selectedProjectExecutive: null as Contact | null,
            selectedProjectManager: null as Contact | null,
            rendered: false,
        }
    },
    computed: {
        ...mapState('project', {
            projectObj: (state: any) => state.projectObj,
        }),
        projectName (): string {
            return this.projectObj?.name || this.projectObj?._project_name || this.projectObj?._name || '-'
        },
        projectUniqueId (): string {
            return this.projectObj?.external_id || '-'
        },
        inviteModalResourceId (): string {
            return this.projectObj.project_local_id ?? null
        },
    },
    async beforeMount (): Promise<void> {
        this.setLoadingBar(true)
        const projectExecutive: Contact = await this.fetchAssignedContactsByResource(InvitationModelTypes.PROJECT_EXECUTIVE)
        const projectManager: Contact = await this.fetchAssignedContactsByResource(InvitationModelTypes.PROJECT_MANAGER)

        if (projectExecutive) {
            this.selectedProjectExecutive = { ...projectExecutive }
        }

        if (projectManager) {
            this.selectedProjectManager = { ...projectManager }
        }

        this.rendered = true
        this.setLoadingBar(false)
    },
    methods: {
        async fetchAssignedContactsByResource (resourceType: string): Promise<Contact> {
            const { data } = await inviteModalClient.fetchAssignedContactsByResource({
                resourceId: this.inviteModalResourceId,
                resourceType: resourceType,
            }) as Contact[]

            return data.items[0]
        },
        goToProjectDirectory (): void {
            this.$router.push({ name: 'project-directory', params: { pid: this.$route.params.pid } })
        }
    }
})
