<template>
    <label class="io-action-radio-row" :class="{ 'io-selected': isChecked }">
        <input
            type="radio"
            name="contact"
            class="io-action-radio-row__input"
            :value="value"
            :class="`io-action-radio-${ idx }`"
            :checked="isChecked"
            v-model="currentValue"
            @change="$emit('change', $event.target.value)"
        />
        <div class="io-single-action">
            <div class="io-single-action__row">
                <div class="io-single-action__cell io-single-action__cell--main">
                    <div class="io-single-action__icon">
                        <img v-if="idx === 0" src="/new-theme/img/company-logo-color.png">
                        <img v-else src="/new-theme/img/company-logo-2.svg">
                    </div>
                    <div class="io-single-action__details">
                        <div class="io-single-action__text">
                            <span class="io-single-action__text io-single-action__text--dark">{{ title }}</span>
                        </div>
                        <div class="io-single-action__text io-single-action__text--small">
                            {{ description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </label>
</template>
<script lang="ts" src="./ActionRadioRow.ts"></script>
<style lang="scss" src="./ActionRadioRow.scss" scoped></style>
