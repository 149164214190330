import { defineComponent, PropType } from 'vue'

import { Notification } from '@/layouts/parts/menu-left/interfaces/MenuLeftInterface'
import menuLeftClient from '@/layouts/parts/menu-left/api-clients/menuLeftClient'
import getRawLocation from '@/layouts/parts/menu-left/mappers/RouteMapper'

export default defineComponent({
    name: 'Message',
    props: {
        notificationData: { type: Object as PropType<Notification>, required: true },
    },
    methods: {
        onNotificationWrapperClick (): void {
            if (this.notificationData.isUnread) {
                this.markAsRead()
            }

            const rawLocation = getRawLocation(this.notificationData)

            if (rawLocation) {
                this.redirectToRoute(rawLocation)
                this.$emit('notificationCenterClose')
            }
        },
        redirectToRoute (rawLocation: object): void {
            const routeData = this.$router.resolve(rawLocation)

            if (routeData.href !== this.$route.path) {
                this.$router.push(rawLocation)
            }
        },
        async markAsRead (): Promise<void> {
            try {
                await menuLeftClient.markSingleNotificationsAsRead(this.notificationData)
            } catch (error) {
                this.errorHandle(error)
            }
        },
        showMessage(message) {
            let translatedMessage = message;
            translatedMessage = translatedMessage.replace(/wrote/g, this.$t('wrote'));

            return translatedMessage;
        }
    },
})
