<template>
    <IOModal
        icon="icon-cross-circle"
        color="red"
        :title="$t('Reject Application Package')"
        :z-index="49"
        @close="$emit('close')"
    >
        <template #header-start>
            <StatusPill class="io-app-package-rejection__step" variant="blue-light" size="big" :text="selectedFromInvoices" />
        </template>
        <template #default>
            <AlertBox
                class="io-app-package-rejection__banner"
                icon="icon-info"
            >
                {{ $t('Select invoices affected by the application package rejection. This will update their statutes appropriately to inform the 2nd party.') }}
            </AlertBox>
            <Table
                v-if="invoices.length"
                :headers="tableHeaders"
                :items="invoices"
                show-select
                :check-all-class="headerCheckboxClass"
                :checkbox-value="areAllItemsSelected"
                @all-items-selected="areAllItemsSelected ? addCheckboxValue(false) : addCheckboxValue(true)"
            >
                <template #tbody="{ sortedItems }">
                    <tr v-for="invoice in sortedItems" :key="invoice.id">
                        <td class="aleft">
                            <div class="io-app-package-rejection__checkbox">
                                <Checkbox v-model="invoice.isSelected"/>
                            </div>
                        </td>
                        <td>
                            {{ invoice.invoice_number }}
                        </td>
                        <td>
                            {{ invoice.vendor_invoice_id }}
                        </td>
                        <td>
                            {{ invoice.invoice_date }}
                        </td>
                        <td>
                            <Avatar
                                type="company"
                                :employee-mongo-id="invoice.vendor.id"
                                :employee-full-name="invoice.vendor.name"
                                show-full-name
                            />
                        </td>
                        <td class="aright">
                            {{ $filters.formatProjectCurrencyInt(invoice.gross_billed_this_period) }}
                        </td>
                        <td class="aright">
                            {{ $filters.formatProjectCurrencyInt(invoice.net_billed_this_period) }}
                        </td>
                    </tr>
                </template>
            </Table>
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton :disabled="!collectSelectedInvoices.length" @click="submitForInternalRejection">
                {{ $t('Next Step') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./InternalRejectionModal.ts"></script>
<style lang="scss" src="./InternalRejectionModal.scss" scoped></style>
