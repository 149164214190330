<template>
    <div
        v-click-outside="closeProfile"
        class="io-profile-container"
        @click="$emit('clicked')"
    >

        <transition name="mask">
            <div
                v-if="showProfile"
                class="io-profile-tile__mask"
                :class="{ 'io-profile-tile__mask_visible': showProfile }"
                @click="toggleShowProfile"
            ></div>
        </transition>

        <div class="io-profile-container__menu" @click="toggleShowProfile">

            <div
                v-if="authData.u_photo"
                class="io-profile-menu__photo"
                :style="getBackgroundAvatar(authData.u_photo)"
            ></div>
            <initials v-else-if="authData.u_id"
                      class="io-profile-menu__initials"
                      :fullName="authData.u_firstname + ' ' + authData.u_lastname"
                      :id="authData.u_id"
            ></initials>

            <div class="io-profile-menu__title-items">
                <div class="io-profile-menu__name">{{ authData.u_firstname }} {{ authData.u_lastname }}</div>
                <div
                    v-if="authData.u_role_name"
                    class="io-profile-menu__title"
                >
                    {{ authData.u_role_name }}
                </div>
            </div>
        </div>


        <transition name="profile-submenu">
            <div
                v-if="showProfile"
                class="io-profile-submenu"
                :class="{ 'io-profile-submenu_visible': showProfile }"
            >
                <div class="io-profile-submenu__list">

                    <div class="io-profile-submenu__links">
                        <router-link v-slot="{ navigate }"
                                     :to="{name: 'account-edit'}"
                                     custom
                        >
                            <div class="io-profile-menu__link" @click="closeProfile(); navigate()" @keypress.enter="closeProfile(); navigate()">
                                <i class="icon-user"></i> &nbsp; {{ $t('My Account') }}
                            </div>
                        </router-link>
                        <router-link v-if="isAccessAllowed('settings')" v-slot="{ navigate }" :to="{name: 'settings-information'}" custom>
                            <div class="io-profile-menu__link" @click="closeProfile(); navigate()" @keypress.enter="closeProfile(); navigate()">
                                <i class="icon-gear"></i> &nbsp; {{ $t('Company Settings') }}
                            </div>
                        </router-link>
                    </div>

                    <div class="io-profile-storage">
                        <div class="io-profile-storage__text">
                            {{ $t('Workspace Storage') }}
                        </div>

                        <div class="io-profile-storage__bar">
                            <div
                                class="io-profile-storage__bar-progress"
                                :style="{ 'width': `${ storage.usedStoragePercent }%` }"
                            ></div>
                        </div>

                        <div class="io-profile-storage__text">
                                <span class="io-profile-storage__text--used">{{ $filters.fileSize(storage.usedStorage) }}</span>
                                <span class="lowercase">&nbsp;{{ $t('Used of') }}&nbsp;</span>
                                <span>{{ $filters.fileSize(storage.totalStorage) }}</span>
                        </div>
                    </div>

                    <div class="io-profile-submenu__footer io-profile-submenu__links">
                        <div
                            class="io-profile-submenu__logout io-profile-menu__link"
                            @click="logout"
                        >
                            {{ $t('Log Out') }}
                        </div>
                    </div>

                </div>
            </div>
        </transition>
    </div>
</template>

<script src="./Profile.ts">
</script>
<style lang="scss" src="./Profile.scss" scoped></style>
