export function projectHasContract (state) {
    return Boolean(state.projectObj._contract_executed)
    // return Boolean(state.projectObj._contract_executed) && Boolean(state.projectObj._contract_sov_ready)
}

export function projectHasInitialContractExecuted (state) {
    return Boolean(state.projectObj._contract_initially_executed)
}

export function isMainContractBetweenReps (state) {
    return state.projectObj._main_contract_between_2_reps ?? false
}

export function hasBudgetItems (state) {
    return state.projectObj._has_budget_items ?? false
}

export function projectCompanyMongoId (state) {
    return state.projectObj.project_local_id
}

export function projectCompanyGlobalId (state) {
    return state.projectObj.project_global_id
}

export function projectFormattedId (state) {
    return state.projectObj.external_id || state.projectObj.formatted_id || ''
}

export function projectUuid (state) {
    return state.projectObj._id
}

export function projectObj (state) {
    return state.projectObj
}

export function projectName (state) {
    return state.projectObj._project_name || ''
}

export function workSpaceType (state) {
    return state.model.prcom_type
}
