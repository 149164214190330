import { defineStore } from 'pinia'
import AppPackageStoreState from '../interfaces/stores/AppPackageStoreState'
import appPackageClient from '../api-clients/appPackageClient'
import dayjs from 'dayjs'
import AppPackageDetails from '@/io-modules/invoices/interfaces/app-packages/AppPackageDetails.ts'
import AppPackageFinancialInfo from '@/io-modules/invoices/interfaces/app-packages/AppPackageFinancialInfo.ts'
import ListFiltersListingParams from '@/io-modules/invoices/interfaces/invoices-list/ListFiltersListingParams'
import { DefaultBillingPeriod } from '@/interfaces/BillingSettings.ts'

export const appPackageViewStore = defineStore('appPackageView', {
    state: (): AppPackageStoreState => ({
        creating: false,
        editing: false,
        dataLoading: false,
        appPackage: {
            id: '0',
            name: null,
            status: null,
            billing_period_start_date: null,
            billing_period_end_date: null,
            billing_period_open_date: null,
            billing_period_due_date: null,
            gross_total_this_period: null,
            net_total_this_period: null
        } as AppPackageDetails,
        availableActions: null,
        attachedInvoicesIDs: [], //ids of invoices user attached on creation step
        attachedInvoices: null, //invoices from BE for appPackage details page
        attachedInvoicesList: [],
        attachableInvoices: null, //invoices from BE for attach invoices step
        appPackageFinancialInfo: {} as AppPackageFinancialInfo,
        invoiceIDsSelectedToReject: null,
        billingSettings: null
    }),
    getters: {
        isEditingMode (state: AppPackageStoreState): boolean {
            return state.creating || state.editing
        },
        isDetailsFilled (state: AppPackageStoreState): boolean {
            return Boolean(this.appPackage?.name && this.appPackage?.billing_period_start_date && this.appPackage?.billing_period_end_date && this.appPackage?.billing_period_open_date && this.appPackage?.billing_period_due_date)
        },
        areAttachedInvoices (state: AppPackageStoreState): boolean {
            return !!(state.attachedInvoicesIDs && state.attachedInvoicesIDs.length)
        },
        appPackageFullData (state: AppPackageStoreState): AppPackageDetails & { attached_invoices: Array<string> } {
            return { ...state.appPackage, attached_invoice_ids: state.attachedInvoicesIDs }
        },
        dataForSending (): AppPackageDetails & { attached_invoice_ids: Array<string> } {
            return Object.keys(this.appPackageFullData).reduce((acc, key) => {
                if (key.includes('date') && this.appPackageFullData[key] !== null) {
                    acc[key] = dayjs(this.appPackageFullData[key]).format('YYYY-MM-DD')
                } else {
                    acc[key] = this.appPackageFullData[key]
                }
                return acc
            }, {})
        },
        isDraft (state: AppPackageStoreState): boolean {
            return state.appPackage.status === 'draft' || state.creating
        },
        someAttachedInvoicesInternallyNotApproved (): boolean {
            return this.attachedInvoices?.number_of_invoices_internally_approved !== this.attachedInvoices?.number_of_invoices
        },
    },
    actions: {
        setCreateModeValue (value: boolean): void {
            this.creating = value
        },
        setEditModeValue (value: boolean): void {
            this.editing = value
        },
        setAttachedInvoicesIDs (value: Array<string>): void {
            this.attachedInvoicesIDs = value
        },

        setDataLoading (value: boolean): void {
            this.dataLoading = value
        },

        clearStore (): void {
            this.creating = false
            this.editing = false
            this.dataLoading = false
            this.appPackage = {
                id: '0',
                name: null,
                status: null,
                billing_period_start_date: null,
                billing_period_end_date: null,
                billing_period_open_date: null,
                billing_period_due_date: null,
                gross_total_this_period: null,
                net_total_this_period: null
            }
            this.attachedInvoicesIDs = []
            this.appPackageFinancialInfo = null
            this.attachedInvoices = null
            this.attachedInvoicesList = []
            this.billingSettings = null
            this.invoiceIDsSelectedToReject = null
            this.attachableInvoices = null
            this.availableActions = null
        },

        async fetchAppPackageAvailableActions (projectId: string, appPackageID: string): Promise<void> {
            const { data } = await appPackageClient.getApplicationPackageAvailableActions(projectId, appPackageID)
            this.availableActions = data
        },

        async fetchAppPackageDetails (projectId: string, appPackageID: string): Promise<void> {
            const { data } = await appPackageClient.getApplicationPackageDetails(projectId, appPackageID)
            this.appPackage = data
        },

        async fetchAppPackageFinancialInfo (projectID: string, appPackageID: string): Promise<void> {
            const { data } = await appPackageClient.getApplicationPackageContractData(projectID, appPackageID)
            this.appPackageFinancialInfo = data
        },

        async setInitialAppPackageData (projectID: string): Promise<void> {
            const { data } = await appPackageClient.getApplicationPackageCreationDetails(projectID)
            this.appPackage.name = data.nextApplicationPackageName
        },

        async fetchAppPackageAttachedInvoices (projectID: string, appPackageID: string): Promise<void> {
            const { data } = await appPackageClient.getApplicationPackageAttachedInvoices(projectID, appPackageID)
            this.attachedInvoices = data
            this.attachedInvoicesList = []

            const invoiceIds = {}
            data.attached_invoices_per_fund.forEach(
                fund => fund.attached_invoices.forEach(
                    invoice => {
                        invoiceIds[invoice.id] = true
                        this.attachedInvoicesList.push(invoice)
                    }
                )
            )
            this.attachedInvoicesIDs = Object.keys(invoiceIds)
        },

        async createAppPackage (projectID: string): Promise<string> {
            const { data } = await appPackageClient.createApplicationPackage(projectID, this.dataForSending)
            return data.id
        },

        async updateAppPackage (projectID: string, appPackageID: string): Promise<void> {
            await appPackageClient.updateApplicationPackage(projectID, appPackageID, this.dataForSending)
        },

        updateInvoiceIDsSelectedToReject (ids: Array<string>): void {
            this.invoiceIDsSelectedToReject = ids
        },

        async fetchAttachableInvoices (projectID: string, appPackageID: string|null, filters: ListFiltersListingParams = null): Promise<void> {
            const { data } = await appPackageClient.getApplicationPackageAttachableInvoices(projectID, appPackageID, filters)
            this.attachableInvoices = data.invoices
        },

        setBillingSettings (data: DefaultBillingPeriod): void {
            this.billingSettings = data
        },

        setBillingSettingsDates (): void {
            const currentYear = dayjs().year()
            const currentMonth = this.billingSettings.client_month_selection === 2
                ? dayjs().add(1, 'month').month()
                : dayjs().month()

            const formatDate = (day) => {
                if (day === 0) {
                    return null
                }

                if (day === 32) {
                    return dayjs().year(currentYear).month(currentMonth).endOf('month').format('YYYY-MM-DD')
                }
                return dayjs().year(currentYear).month(currentMonth).date(day).format('YYYY-MM-DD')
            }

            this.appPackage.billing_period_open_date = formatDate(this.billingSettings?.client_billing_start_day)
            this.appPackage.billing_period_due_date = formatDate(this.billingSettings?.client_billing_end_day)
        }
    },
})
