import SingleOption from './parts/SingleOption.vue'
import initials from '@/components/new-theme/initials.vue'

export default {
    name: 'CustomDropdown',
    components: {
        SingleOption,
        initials,
    },
    props: {
        options: {
            required: true,
            type: Array,
            default: () => [],
        },
        defaultItem: {
            required: false,
            type: Object,
            default: null,
        },
        editable: {
            required: false,
            type: Boolean,
            default: true
        },
        validationError: {
            required: false,
            type: Boolean,
            default: false
        },
        allowInvite: {
            required: false,
            type: Boolean,
            default: true
        },
        showSearch: {
            required: false,
            type: Boolean,
            default: false
        },
        placeholder: {
            required: false,
            type: String,
            default: 'Select option'
        },
        isGrouped: {
            required: false,
            type: Boolean,
            default: true
        },
        placeholderSearch: {
            required: false,
            type: String,
            default: 'Search in projects'
        },
        showDescription: {
            required: false,
            type: Boolean,
            default: true
        },
        /**
         * In that mode we uses logos instead of avatars
         */
        modeCompanies: {
            required: false,
            type: Boolean,
            default: false
        },
        addNewLabel: {
            required: false,
            type: String,
            default: 'Invite Contact'
        },
        forceSelectedValue: {
            required: false,
            type: Object,
            default: () => ({})
        },
        showAvatar: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        filteredValues () {
            if (this.options.length) {
                const result = []

                this.options.forEach(group => {
                    const groupResult = group.groupList.filter(item => {
                        if (item.name) {
                            return item.name.toLowerCase().includes(this.search.toLowerCase())
                        }
                    })
                    if (groupResult.length) {
                        result.push({
                            groupList: groupResult,
                            groupName: group.groupName
                        })
                    }
                })

                return result
            }
        },

        /**
         * Filtered options
         * @return {[]|T[]|*[]}
         */
        filteredOptions () {
            if (!this.options.length) {
                return []
            }
            const searchKeyword = this.prepareKeyword(this.search)
            if (!searchKeyword) {
                return this.options
            }
            return this.options.filter(option => {
                return option.name && option.name.toLowerCase().includes(searchKeyword)
            })
        },
        description () {
            return this.selectedItem.description ? this.selectedItem.description : this.getName(this.selectedItem.name, 1)
        }
    },
    data () {
        return {
            isCollapsed: true,
            selectedItem: null,
            search: '',
            newContact: {
                id: 'new',
                name: this.addNewLabel,
            },
        }
    },
    methods: {
        resetSelected () {
            this.selectedItem = null
            this.$emit('resetSelected')
            this.$emit('remove')
        },
        menuCollapsed () {
            this.isCollapsed = !this.isCollapsed
            if (this.isCollapsed && this.showSearch) {
                this.$nextTick(() => {
                    this.$refs.searchInput.focus()
                })
            }
        },

        /**
         * After select item
         * @param item
         */
        selectOption (item) {
            this.selectedItem = item
            this.$emit('onSelectContact', this.selectedItem)
            this.$emit('select', this.selectedItem)
            this.isCollapsed = true
        },

        addNew () {
            this.$emit('inviteContact', this.newContact)
            this.isCollapsed = true
        },
        hideMenu () {
            this.isCollapsed = true
        },

        /**
         * Get name data
         *
         * @param item
         * @param index
         * @return {string|*}
         */
        getName (item, index = 0) {
            let text = item && item.length ? item.split('-')[index] : null
            if (text) {
                if (text.trim()) {
                    return text
                } else {
                    return '-'
                }
            } else {
                return '-'
            }
        },

        /**
         * Get single image for element
         * @param item
         * @return {{'background-image': string}}
         */
        getImage (item) {
            if (!item.image) {
                return ''
            }
            let url = ''
            if (this.modeCompanies) {
                url = this.getApiUrlCompanyLogo(item.image)
            } else {
                url = this.getApiUrlAvatar(item.image)
            }
            return {'background-image': `url(${url}`}
        }
    },
    watch: {
        forceSelectedValue (newVal, oldVal) {
            this.selectOption(newVal)
        }
    },
    beforeMount () {
        if (this.defaultItem && this.defaultItem['id']) {
            this.selectedItem = this.defaultItem
        } else {
            this.selectedItem = null
        }
    }
}
