import ContractsList from '@/io-modules/contracts/views/contracts-list/ContractsList.vue'
import ContractRouteName from '@/io-modules/contracts/enums/ContractRouteName.ts'
import ContractListChildrenRouteName from '@/io-modules/contracts/enums/ContractListChildrenRouteName.ts'

const defaultMeta = {
    name: 'Contracts',
    navigationKey: 'contracts_v3',
    customClass: 'io-contracts',
}

export default [
    {
        path: '/contracts-v3',
        name: ContractRouteName.ContractsList,
        component: ContractsList,
        redirect: { name: ContractListChildrenRouteName.Main },
        meta: {
            title: 'Contracts',
            ...defaultMeta,
        },
        children: [
            {
                path: ContractListChildrenRouteName.Main,
                name: ContractListChildrenRouteName.Main,
            },
            {
                path: ContractListChildrenRouteName.Vendor,
                name: ContractListChildrenRouteName.Vendor,
            }
        ]
    },
]
