import { defineComponent } from 'vue'
import IconTooltip from '../icon-tooltip/IconTooltip.vue'
import VueI18n from 'vue-i18n'
import { get, isFunction, isNull } from 'lodash'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'

export default defineComponent({
    components: {
        IconTooltip,
    },
    props: {
        status: { type: [Number, String, null], required: true, default: null },
        statusId: { type: [Number, String], default: null },
        pillsColorFunction: { type: Function, default: null },
        isTranslation: { type: Boolean, default: false },
        tableRow: { type: Object, default: null },
        column: { type: Object as () => ColumnInterface, required: true, default: null },
        customStatusText: { type: Boolean, default: null },
        customStatusTextFunction: { type: Function, default: null },
        pillsTextFunction: { type: Function, default: false },
        defaultClasses: { type: String, default: null },
        showIconToolTip: { type: Function, default: null },
    },
    computed: {
        statusText (): string | VueI18n.LocaleMessages {
            const additionalHTMLFunction = get(this.column, ['additional', 'additionalHTMLFunction'], null)
            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.tableRow)
            }

            if (isFunction(this.pillsTextFunction) && this.statusId) {
                if (this.isTranslation) {
                    return this.$t(this.pillsTextFunction(this.statusId))
                }

                return this.pillsTextFunction(this.statusId)
            }

            if (this.status) {
                if (this.status.toLowerCase() === 'gc') {
                    return 'GC'
                }
                if (this.status.toLowerCase() === 'sub') {
                    return 'Sub'
                }
                if (this.status.toLowerCase() === 'design') {
                    return 'Design'
                }
                if (this.status.toLowerCase() === 'rep') {
                    return 'Rep'
                }
                if (this.status.toLowerCase() === 'owner' || this.status.toLowerCase() === 'owner\'s rep') {
                    return 'Owner'
                }
                if (this.status.toLowerCase() === 'off-system') {
                    return 'Off-system'
                }
            }

            if (this.isTranslation) {
                return this.$t(this.status)
            }

            if (this.customStatusText) {
                return this.customStatusTextFunction(this.statusId)
            }

            return this.status
        },
        classes (): string | null {
            if (this.column.pillsColorFunction && !isNull(this.statusId)) {
                return this.column.pillsColorFunction(this.statusId)
            }

            if (this.defaultClasses) {
                return this.defaultClasses
            }

            if (this.pillsColorFunction && !isNull(this.statusId)) {
                return this.pillsColorFunction(this.statusId)
            }

            return null
        },
        showToolTip (): boolean {
            return this.showIconToolTip ? this.showIconToolTip(this.status) : false
        }
    }
})
