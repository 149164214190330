import { defineComponent } from 'vue'
import { SetType } from '@/io-modules/drawings/enums/SetType'

export default defineComponent({
    name: 'SetTypesIndex',
    props: {
        modelValue: {
            type: String as () => SetType,
            required: true,
        },
    },
    emits: ['update:modelValue', 'next'],
    computed: {
        types (): { value: SetType, icon: string, title: string, description: string }[] {
            return [
                {
                    value: SetType.CONSTRUCTION,
                    icon: 'icon-file-check',
                    title: this.$t('Current Set'),
                    description: this.$t('This set will be visible for every member on the Project Directory, and is best used as the official Contract Documents used to build a project.'),
                },
                {
                    value: SetType.DRAFT,
                    icon: 'icon-file-draft',
                    title: this.$t('Draft Set'),
                    description: this.$t('This set will only be visible to people that you share it with, and are best used for sets that are still in progress or not yet finalized.'),
                },
            ]
        },
    },
})
