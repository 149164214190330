import { defineStore } from 'pinia'
import { FundingSourceFundProjects, FundingSourcesProvider } from '../interfaces/GlobalFundingInterfaces.ts'

export const globalFundingStore = defineStore('globalFunding', {
    state: ()=> ({
        providersList: null as FundingSourcesProvider[],
        addNewCapitalFundModal: { show: false, editMode: false },
        fundCreatedFromProject: false,
        toggledOpenFund: null as { fundId: string } & FundingSourceFundProjects
    }),
    actions: {
        setProvidersList (value: FundingSourcesProvider[]): void {
            this.providersList = value
        },
        setAddNewCapitalFundModal (value: { show: boolean, editMode: boolean }): void {
            this.addNewCapitalFundModal = value
        },
        setFundCreatedFromProject (value: boolean): void {
            this.fundCreatedFromProject = value
        },
        setToggledOpenFund (fundId: string, fundProjects: FundingSourceFundProjects): void {
            this.toggledOpenFund = { fundId, ...fundProjects }
        }
    }
})
