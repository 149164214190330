import { defineComponent, PropType } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import dayjs from 'dayjs'

export default defineComponent({
    name: 'NotesModal',
    components: { IOModal },
    props: {
        modalIcon: String,
        modalIconColor: String,
        modalTitle: String,
        note: String,
        date: String,
        addedBy: String,
        title: String,
    },
    emits: ['close'],
    computed: {
        getDate (): string {
            return dayjs(this.date).format('DD MMM YYYY')
        },
        getTime (): string {
            return dayjs(this.date).format('h:mm a')
        },
    }
})
