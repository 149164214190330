import { defineComponent } from 'vue'
import { FileInterface } from '@/io-modules/rfis/interfaces/Rfi'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'

export default defineComponent({
    name: 'RenameFileModal',
    components: {
        IOModal
    },
    props: {
        file: {
            type: Object as () => FileInterface,
            required: true
        }
    },
    emits: ['rename', 'close'],
    data () {
        return {
            name: this.file.name as string,
            extension: '' as string
        }
    },
    beforeMount () {
        let splitName = this.file.name.split('.')
        this.extension = splitName?.pop()
        this.name = splitName?.join('.')
    },
    methods: {
        close (): void {
            this.$emit('close')
        },
        rename (): void {
            this.$emit('rename', {
                fileId: this.file.id,
                fileName: `${ this.name }.${ this.extension }`,
            })
        }
    }
})
