import { defineComponent } from 'vue'

import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'MenuLock',
    props: {
        isProjectNavigationActive: { type: Boolean, required: true }
    },
    data () {
        return {
            isLocked: false
        }
    },
    computed: {
        tooltipText (): TranslateResult {
            return this.isLocked
                ? this.$t('Unlock from expanded position')
                : this.$t('Lock in expanded position')
        }
    },
    beforeMount () {
        this.init()
    },
    methods: {
        init (): void {
            if (localStorage.getItem('clicked') === 'true') {
                this.onToggleMenuClick()
            }
        },
        onToggleMenuClick (): void {
            this.$store.dispatch('main/setLeftMenuStatus', !this.isLocked)
            this.isLocked = !this.isLocked

            if (!this.isLocked) {
                this.$emit('setShowProfileMenuVisible', false)

                this.$store.dispatch('main/setIsLeftMenuWider', false)
            } else if (this.isProjectNavigationActive) {
                this.$store.dispatch('main/setIsLeftMenuWider', true)
            }

            localStorage.setItem('clicked', this.isLocked.toString())
        }
    }
})
