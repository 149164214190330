<template>
    <td
        @click.stop
    >
        <span
            class="io-invite-button io-appColor"
            @click="onInviteClick()"
        >
            <template v-if="data.off_system">
                <i class="icon-send-2"></i>
                {{ $t('Invite') }}
            </template>

            <template v-else>
                <i class="icon-plus"></i>
                {{ $t('Add') }}
            </template>
        </span>

        {{ $t('Bookmarked') }}
    </td>
</template>

<script lang="ts" src="./InvitationButtonNew.ts"></script>
<style lang="scss" src="./InvitationButtonNew.scss" scoped></style>
