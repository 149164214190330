import { defineComponent } from 'vue'
import ProgressTrack from '@/components/atoms/ProgressTrack/ProgressTrack.vue'

export default defineComponent({
    name: 'LineProgress',
    components: {
        ProgressTrack,
    },
    props: {
        label: String,
        completedNumber: Number,
        totalNumber: Number,
        icon: String
    },
})
