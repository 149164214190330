import projectBudget from '@/api/projectBudget/projectBudgetClient'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType'
import { state } from '@prismatic-io/marketplace'

export const resetState = function ({ commit, dispatch }) {
    commit('RESET_STATE')
}

export const setProject = function ({ commit, dispatch }, value) {
    commit('SET_PROJECT', value || {})
}

export const setActiveColumns = function ({ commit, dispatch }, value) {
    commit('SET_ACTIVE_COLUMNS', value || [])
}

export const setModalAnticipated = function ({ commit, dispatch }, value) {
    commit('SET_MODAL_ANTICIPATED', value || {})
}

export const setChangesFilters = function ({ commit, dispatch }, value) {
    commit('SET_CHANGES_FILTERS', value || [])
}

export const saveLayout = function ({ commit, dispatch }, value) {
    commit('SAVE_LAYOUT', value || {})
}

export const setItemVendorList = function ({ commit, dispatch }, list) {
    commit('SET_ITEM_VENDOR_LIST', list || [])
}

export const setChangesParsedIds = function ({ commit, dispatch }, changes) {
    commit('SET_CHANGES_PARSED_IDS', changes || [])
}

export const setBudgetType = function ({commit, dispatch}, value) {
    commit('SET_BUDGET_TYPE', value || null)
}

/**
 *
 * @param commit
 * @param dispatch
 * @param projectId
 * @return {*}
 */
export const recalculateBudget = function ({ commit, dispatch }, projectId) {
    return projectBudget.recalculateBudget(projectId)
}

/**
 *
 * @param { commit, rootGetters } vuex
 * @param { projectId, budgetType } params
 * @return {*}
 */
export const getBudget = async function ({ commit, rootGetters }, { projectId, budgetType = null }) {
    budgetType = budgetType ?? getBudgetType(rootGetters['appStore/isOwnerRep'])

    if (!projectId) {
        return
    }
    return projectBudget.getBudget(projectId, budgetType).then(response => {
        if (response) {
            commit('SET_COMPANY_ID', response.data.companyId)
            commit('SET_PROJECT_MONGO_LOCAL_ID', response.data.projectMongoLocal._id)
            commit('SET_PROJECT_MONGO', response.data.projectMongoLocal)
            commit('SET_SHARED_BUDGETS', response.data.shared_budgets)
            commit('SET_HISTORY', response.data.history)
            commit('SET_BASIC', response.data.basic)
            commit('SET_SETTINGS', response.data.settings)
            commit('SET_CURRENCY_BASE', response.data.currency)
            commit('SET_CURRENCY_SELECTED_RATES', response.data.currencyRates)
            commit('SET_TOTALS', response.data.totals)
            commit('SET_PROPOSAL', response.data.proposalObj)
            commit('SET_ITEMS', response.data.items)
            commit('SET_COST_CODES_PROJECT', response.data.costCodesProject)
            commit('SET_COST_CODES_HAVE_ACCOUNTING_CODES', response.data.allCostCodesHaveAccountingCodes)
            commit('SET_BUDGET_COST_CODE_TEMPLATE_ID', response.data.budgetCostCodeTemplateId)
            commit('SET_LOADED', true)
        }
    })
}



export const getAttachedFundingSources = async function ({ commit, dispatch, state }, projectId) {
    return projectBudget.getFundingSources(projectId).then(response => {
        if (response) {
            commit('SET_ATTACHED_FUNDING_SOURCES', response.data.data)
        }
    })
}


export const shareSetDefault = async function ({ commit, dispatch, state }, sharedProjectId) {
    try {
        await projectBudget.shareSetDefault(state.projectMongoLocal._id, sharedProjectId)
        return dispatch('getSharedBudget', sharedProjectId)
    } catch (exception) {
        throw (exception)
    }
}

export const toggleItemExpand = async function ({ commit }, itemId) {
    commit('ITEM_EXPAND_TOGGLE', itemId)
}

export const addItemToExpanded = async function ({ commit }, itemId) {
    commit('ITEM_ADD_TO_EXPANDED', itemId)
}

export const expandedItemsReset = async function ({ commit }) {
    commit('EXPANDED_ITEMS_RESET')
}


export const setCurrencySelectedRates = function ({ commit, state }, selectedRates) {
    commit('SET_CURRENCY_SELECTED_RATES', selectedRates)
    projectBudget.saveCurrencyRates(state.projectMongoLocal._id, selectedRates)
}

export const setCurrencySelectedConversion = function ({ commit }, conversion) {
    commit('SET_CURRENCY_SELECTED_CONVERSION', conversion)
}

export const deleteCurrencyConversion = function ({ state, commit }, rateId) {
    commit('DELETE_CURRENCY_CONVERSION', rateId)
    projectBudget.deleteCurrencyRate(state.projectMongoLocal._id, rateId)
}


export const getSharedBudget = async function (
    { commit, dispatch, state },
    { projectId, budgetType = BudgetType.OWNER_BUDGET, isSnapshot = false, budgetId = null }
) {
    if (!projectId) {
        return
    }
    try {
        const { data } = await projectBudget.getSharedBudget(
            projectId,
            state.projectMongoLocal._id,
            budgetType,
            isSnapshot,
            budgetId
        )

        commit('SET_PROJECT_MONGO_LOCAL_ID', projectId)
        commit('SET_PROJECT_MONGO_BUDGET', data.budget)
        commit('SET_ITEMS', data.items)
        commit('SET_TOTALS', data.totals)
        commit('SET_HISTORY', data.history)
        commit('SET_BASIC', data.basic)
        commit('SET_CHANGES', data.changes)
        commit('SET_COMPANY_SHARED_ID', data.companyId)
        commit('SET_BUDGET_TYPE_SHARED_SELECTED', budgetType)
        commit('SET_SHARED_PHASE', data.phase)
    } catch (exception) {
        throw (exception)
    }
}


export const setLoaded = function ({ commit, dispatch }, value) {
    commit('SET_LOADED', value)
}


export const getChanges = function ({ commit, dispatch, state }, params) {
    const projectId = state.projectMongoLocal?.project_mysql_id

    if (!projectId) return

    return projectBudget.getChanges(projectId, params).then(response => {
        if (response) {
            commit('SET_CHANGES', response.data.feed)
            commit('SET_CHANGE_STATUSES', response.data.statuses)
            commit('SET_CHANGES_LOADED', true)
        }
    })
}

export const setCurrentChange = function ({ commit, dispatch }, value) {
    commit('SET_CURRENT_CHANGE', value)
}

export const setCompanySharedId = function ({ commit, dispatch }, value) {
    commit('SET_COMPANY_SHARED_ID', value || null)
}

/**
 * Budget configuration getter
 *
 * @param commit
 * @param dispatch
 * @param state
 * @param projectId
 * @return {*}
 */
export const getConfiguration = function ({ commit, dispatch, state }) {
    return projectBudget.getConfiguration().then(response => {
        if (response && response.data) {
            commit('SET_LAYOUTS', response.data.layouts)
            commit('SET_ANALYTIC_COST_CODES', response.data.analytic_cost_codes)
            commit('SET_VIEW_ONLY', response.data.view_only)
            commit('SET_COST_CODES', response.data.costCodes)
            commit('SET_CLIENT_CODES', response.data.clientCodes)

            commit('SET_SHARED_PROJECT_COST_CODES_LIST', response.data.shared_budget_templates)
            commit('SET_PROJECT_COST_CODES_LISTS', response.data.project_cost_codes_lists)
            commit('SET_PROJECT_COST_CODES_LISTS_USED', response.data.project_cost_codes_lists_used)
            commit('SET_COST_CODES_HAVE_ACCOUNTING_CODES', response.data.allCostCodesHaveAccountingCodes)
            commit('SET_BUDGET_COST_CODE_TEMPLATE_ID', response.data.budgetCostCodeTemplateId)
            commit('SET_BUDGET_LINE_ITEM_LISTS', response.data.budget_line_item_lists)

            commit('SET_BID_QUESTIONS_LISTS', response.data.bid_questions_lists)
            commit('SET_MEETING_TEMPLATES_COUNT', response.data.meeting_templates_count)

            commit('SET_INSPECTION_TEMPLATES_COUNT', response.data.inspections_templates_count)

            commit('SET_PUNCH_STAMPS_TEMPLATES_COUNT', response.data.punch_stamps_templates_count)
            commit('SET_PROJECT_SCHEDULE_MILESTONES_LISTS_COUNT', response.data.project_schedules_milestones_lists_count)

            commit('SET_QUOTED_ITEM_LINES_LISTS', response.data.quoted_item_lines_lists)
            commit('SET_QUOTED_ITEM_LINES_LISTS_SETTINGS', response.data.quoted_item_lines_lists_settings)
            commit('SET_QUOTED_ITEM_LINES_TEMPLATES', response.data.quoted_item_lines_templates)

            commit('SET_CUSTOM_FIELDS_LISTS', response.data.custom_fields_lists)
            commit('SET_MEETING_MINUTES_LISTS', response.data.meeting_minutes_lists)
            commit('SET_MEETING_MINUTES_TEMPLATES', response.data.meeting_minutes_templates)
            commit('SET_CURRENCY_TEMPLATES', response.data.currency_templates)
            commit('SET_CURRENCIES', response.data.currencies)
            commit('SET_TASK_WORKFLOWS_LISTS', []) // TODO: get workflows from api
            commit('SET_DICTIONARIES', response.data.dictionaries)
            commit('SET_IS_SERVICE_ACCOUNT', response.data.is_service_account)

            commit('SET_UNITS', response.data.units)
            commit('SET_RFP_TO_TYPES', response.data.rfp_to_types)
            commit('SET_LOADED_CONFIGURATION', true)
            commit('SET_LOADED_LISTS', true)

            commit('SET_CAN_EDIT_SPECIAL_FUNCTIONS')

            commit('SET_LETTER_OF_INTENT_TEMPLATES', response.data.letter_of_intent_templates)
            commit('SET_APPROVAL_WORKFLOWS_TEMPLATES', response.data.approval_workflow_templates)
            commit('SET_LIEN_WAIVER_TEMPLATES', response.data.lien_waiver_templates)
            commit('SET_ACCOUNTING_VENDORS_COUNT', response.data.accounting_vendors_count)
            commit('SET_ACCOUNTING_ENTITIES_COUNT', response.data.accounting_entities_count)
            commit('SET_ACCOUNTING_COST_CODES_COUNT', response.data.accounting_cost_codes_count)
            commit('SET_INTEGRATION_SYNC_HISTORY_COUNT', response.data.integration_sync_history_count)
            commit('SET_CONTRACT_DOCUMENT_TEMPLATES', response.data.contract_document_templates)
            commit('SET_EXTERNAL_ACCOUNTING_COST_CODES', stripExternalCodesFromCategory(response.data.external_accounting_cost_code_categories))
            commit('SET_EXTERNAL_ACCOUNTING_COST_CODE_CATEGORIES', response.data.external_accounting_cost_code_categories || [])
            commit('SET_INTEGRATIONS', response.data.integrations || [])

            commit('SET_INSURANCE_REQUIREMENTS_TEMPLATES', response.data.insurance_requirements_templates || [])

            commit('SET_COMPANY_TASK_TEMPLATE_LISTS_COUNT', response.data.company_task_template_lists_count)
            commit('SET_COMPANY_TASK_TEMPLATES_COUNT', response.data.company_task_templates_count)
        }
    })
}
const stripExternalCodesFromCategory = (categories) => {
    let codes = []

    if (undefined === categories) {
        return codes
    }

    if (Array.isArray(categories) && categories.length >= 1) {
        codes = categories.map((cat) => cat.codes).flat()
    } else {
        codes = categories.codes
    }
    return codes
}
export const setApprovalWorkflowTemplates = function ({ commit, dispatch }, value) {
    commit('SET_APPROVAL_WORKFLOWS_TEMPLATES', value || [])
}

export const setLienWaiverTemplates = function ({ commit, dispatch }, value) {
    commit('SET_LIEN_WAIVER_TEMPLATES', value || [])
}

/**
 * Budget configuration getter
 *
 * @param commit
 * @param dispatch
 * @param state
 * @param projectId
 * @return {*}
 */
export const getConfigurationForProject = function ({ commit, rootGetters }, { projectId, budgetType = null }) {
    if (!projectId) {
        return
    }

    budgetType = budgetType ?? getBudgetType(rootGetters['appStore/isOwnerRep'])

    return projectBudget.getConfigurationForProject(projectId, budgetType).then(response => {
        if (response && response.data) {
            commit('SET_LAYOUTS', response.data.layouts)
            commit('SET_CHANGE_REASON_CATEGORIES', response.data.change_reason_categories)
            commit('SET_PROJECT_COST_CODES_LIST', response.data.project_cost_codes_list)
            commit('SET_REP_PROJECT_COST_CODES_LIST', response.data.rep_project_cost_codes_list)

            commit('SET_PROJECT_COST_CODES_LISTS', response.data.project_cost_codes_lists)
            commit('SET_INTEGRATIONS', response.data.integrations)
            commit('SET_BUDGET_LINE_ITEM_LISTS', response.data.budget_line_item_lists)
            commit('SET_CURRENCY_TEMPLATES', response.data.currencies_templates)
            commit('SET_UNITS', response.data.units)
            commit('SET_INSURANCE_REQUIREMENTS_TEMPLATES', response.data.insurance_requirements_templates || [])
            commit('SET_BID_QUESTIONS_LISTS', response.data.bid_questions_lists)
            commit('SET_QUOTED_ITEM_LINES_LISTS', response.data.quoted_item_lines_lists)
            commit('SET_QUOTED_ITEM_LINES_LISTS_SETTINGS', response.data.quoted_item_lines_lists_settings)
            commit('SET_QUOTED_ITEM_LINES_TEMPLATES', response.data.quoted_item_lines_templates)
            commit('SET_LOADED_LISTS', true)
        }
    })
}


export const getBudgetColumns = function ({ commit, dispatch, rootGetters }, { projectId, budgetType = null, companyId = null }) {
    if (!projectId) {
        return
    }

    budgetType = budgetType ?? getBudgetType(rootGetters['appStore/isOwnerRep'])

    dispatch('getAllBudgetColumns', { projectId, budgetType, companyId })
}

export const getAllBudgetColumns = function ({ commit, rootGetters }) {
    return projectBudget.getBudgetColumns().then(response => {
        if (response && response.data) {
            commit('SET_BUDGET_COLUMNS', response.data)
        }
    })
}

export const setBudgetIconTitleColumn = function ({commit, dispatch}, value) {
    commit('SET_BUDGET_ICON_TITLE_COLUMN', value || {})
}

export const setSharedBudgetCodeTemplate = function ({ commit }, value) {
    commit('SET_SHARED_BUDGET_CODE_TEMPLATE', value || {})
}

/**
 * Insurance Requirements
 */
export const setInsuranceRequirementsTemplates = function ({commit, dispatch}, value) {
    commit('SET_INSURANCE_REQUIREMENTS_TEMPLATES', value || [])
}

/**
 * Task Templates
 */
export const setCompanyTaskTemplates = function ({ commit, dispatch }, value) {
    commit('SET_COMPANY_TASK_TEMPLATES_COUNT', value || [])
}

export const setCompanyTaskTemplatesLists = function ({ commit, dispatch }, value) {
    commit('SET_COMPANY_TASK_TEMPLATE_LISTS_COUNT', value || [])
}

const getBudgetType = (isOwnerRep) => {
    return true === isOwnerRep ? BudgetType.OWNER_BUDGET : BudgetType.MY_BUDGET
}

export const saveCurrentChangeAccountingMessages = ({ commit, dispatch }, value) => {
    commit('SET_CURRENT_CHANGE_ACCOUNTING_MESSAGES', value || [])
}

export const setCurrentChangeCanBeExportedToAccounting = ({ commit, dispatch}, value) => {
    commit('SET_CURRENT_CHANGE_CAN_BE_EXPORTED_TO_ACCOUNTING', value || false)
}

export const setProjectCostCodesList = function ({ commit }, value) {
    commit('SET_PROJECT_COST_CODES_LIST', value)
}
