<template>
    <div class="io-single-company-type" data-testid="single-company-type">
        <div class="io-company-type-container">
            <div class="io-icon-container">
                <div class="io-type-icon-box" :class="iconClass">
                    <img class="io-type-icon" alt="" :src="iconSvg" />
                </div>
            </div>

            <span class="io-label-container">
                <span class="io-type-label">{{ label }}</span>
                <span class="io-inner-label" v-if="!hideDescription">{{ description }}</span>
            </span>

        </div>
    </div>
</template>

<script lang="ts" src="./SingleCompanyType.ts"></script>
<style lang="scss" src="./SingleCompanyType.scss" scoped></style>
