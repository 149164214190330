<template>
    <div v-if="!error && !loading" class="io-details-wrapper">
        <div class="io-info-card--white">
            <div class="io-details-wrapper__title">
                <span>{{ $t('Application Package Details') }}</span>
                <div>
                    <StatusDot v-if="!isDetailsFilled" variant="warning"/>
                </div>
            </div>
            <div class="io-info-wrapper">
                <div class="io-info-wrapper__left">
                    <div>
                        <IOSingleInfoRow :label="$t('Billing Period')" :info="billingPeriod" />
                        <IOSingleInfoRow :label="$t('Due Date')" :info="dueDate" />
                    </div>
                    <div>
                        <IOSingleInfoRow :label="$t('Billing Period Open Date')" :info="billingPeriodOpenDate" />
                    </div>
                </div>
                <div class="io-info-wrapper__right">
                    <IOSingleInfoRow class="io-gross-amount" :label="$t('Gross Billed Within the App Package')" :info="grossBilled" />
                    <IOSingleInfoRow :label="$t('Net Billed Within the App Package')" :info="netBilled" />
                </div>
            </div>
        </div>
        <div class="io-info-card--grey">
            <div class="io-timeline-header">
                <span class="io-timeline-title">{{ $t('Status') }}</span>
                <StatusPill size="big" :variant="getApplicationPackageStatusColor(appPackage.status)" :text="appPackage.status_text"/>
            </div>
            <SingleColumnTimeline :timeline-items="timelineItems"/>
        </div>
    </div>
    <div v-if="error || loading" class="io-details-wrapper__placeholder">
        <RequestedDataErrorPlaceholder :title="$t('Application Package Details')" :loading="loading" @reload="fetchData" />
    </div>
</template>

<script lang="ts" src="./DetailsSection.ts"></script>
<style lang="scss" src="./DetailsSection.scss" scoped></style>
