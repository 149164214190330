import { defineComponent, PropType } from 'vue'
import CommentItem from '../../interfaces/CommentItem'
import dayjs from 'dayjs'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import TagUserInfo from '@/components/mentions/interfaces/TagUserInfo'
import Mentions from '@/components/mentions/mentions.vue'
import { mapState as mapStateVuex } from 'vuex'

export default defineComponent({
    name: 'SingleComment',
    components: {
        ActionDropdown,
        Mentions,
    },
    props: {
        comment: { type: Object as PropType<CommentItem>, default: null },
        canEdit: { type: Boolean, default: false },
        commentField: { type: String, default: 'comment' },
        dateFiled: { type: String, default: 'created_at' },
        userIdField: { type: String, default: 'creator.id' },
        userFirstNameField: { type: String, default: 'creator.first_name' },
        userLastNameField: { type: String, default: 'creator.last_name' },
        taggableUsers: { type: Array as PropType<TagUserInfo[]>, default: () => [] },
        showCreationTime: { type: Boolean, default: false }
    },
    emits: ['updateComment', 'deleteComment'],
    data () {
        return {
            editMode: false,
        }
    },
    computed: {
        ...mapStateVuex('appStore', {
            authData: (state: any) => state.authData
        }),

        getCreatedTime (): string {
            return this.comment[this.dateFiled]
        },

        getTimeToDisplay (): string {
            if (!this.getCreatedTime) {
                return ''
            }

            return this.showCreationTime ? dayjs(this.getCreatedTime).format('YYYY-MM-DD HH:mm:ss') : dayjs(this.getCreatedTime).fromNow()
        },

        commentText (): string {
            return this.comment[this.commentField]
        },

        getUserId (): string {
            const filed = this.userIdField.split('.')
            if (1 === filed.length) {
                return this.comment[this.userIdField]
            } else {
                return this.comment[filed[0]][filed[1]]
            }
        },

        getUserFirstName (): string {
            const filed = this.userFirstNameField.split('.')
            if (1 === filed.length) {
                return this.comment[this.userFirstNameField]
            } else {
                return this.comment[filed[0]][filed[1]]
            }
        },

        getUserLastName (): string {
            const filed = this.userLastNameField.split('.')
            if (1 === filed.length) {
                return this.comment[this.userLastNameField]
            } else {
                return this.comment[filed[0]][filed[1]]
            }
        },

        userFullName (): string {
            return `${this.getUserFirstName} ${this.getUserLastName}`
        },

        isCommentCreator (): boolean {
            return this.authData.u_mongo === this.comment.creator.id
        }
    },

    methods: {
        initEdit (): void {
            this.editMode = true
        },

        closeEditingMode (): void {
            this.editMode = false
        },

        updateComment (): void {
            this.$emit('updateComment', this.comment)
            this.closeEditingMode()
        },

        initDelete (): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to delete this comment?'),
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Delete'),
                        class: 'io-btn-primary',
                        action: () => this.deleteComment()
                    }
                ]
            })
        },

        deleteComment (): void {
            this.$emit('deleteComment', this.comment)
            this.closeEditingMode()
        },
    }
})
