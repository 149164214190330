import { findIndex, isArray } from 'lodash'

export function SET_MY_DOCUMENTS (state, value) {
    state.my_documents[value.arrayId] = value.files
}

export function CLEAR_DOCS_ARR (state, value) {
    state.my_documents[value.arrayId] = value.files
    state.shared_documents[value.arrayId] = value.files
    state.trash_documents[value.arrayId] = value.files
}

export function REMOVE_FILES_COMPONENT (state, value) {
    delete state.my_documents[value]
    delete state.shared_documents[value]
    delete state.trash_documents[value]
}

export function REMOVE_ALL_FILES_COMPONENT (state) {
    state.my_documents = {}
}

export function ADD_MY_DOCUMENT (state, value) {
    if (value.file._shared_to_my_document_id) {
        const foundOrigin = state.my_documents.find(document => document.current_version === value.file.current_version)

        if (foundOrigin) {
            return
        }
    }

    state.my_documents[value.arrayId].push(value.file)
}

export function REMOVE_MY_DOCUMENT (state, value) {
    let index = findIndex(state.my_documents[value.arrayId], function (o) {
        return o._id === value.fileId || o.id === value.fileId
    })
    if (index > -1) {
        state.my_documents[value.arrayId].splice(index, 1)
    }
}

export function HARD_REMOVE_MYDOC (state, value) {
    let index = findIndex(state.my_documents[value.arrayId], function (o) {
        return o._id === value.fileId
    })
    if (index > -1) {
        state.my_documents[value.arrayId].splice(index, 1)
    }
}

export function SET_SHARED_DOCUMENTS (state, value) {
    state.shared_documents[value.arrayId] = value.files
}

export function ADD_SHARED_DOCUMENT (state, value) {
    if (!isArray(state.shared_documents[value.arrayId])) {
        state.shared_documents[value.arrayId] = []
    }
    state.shared_documents[value.arrayId].push(value.file)
}

export function REMOVE_SHARED_DOCUMENT (state, value) {
    let index = findIndex(state.shared_documents[value.arrayId], function (o) {
        return o._id === value.fileId
    })
    if (index > -1) {
        state.shared_documents[value.arrayId].splice(index, 1)
    }
}

export function SET_TRASHED_DOCUMENTS (state, value) {
    state.trash_documents[value.arrayId] = value.files
}

export function ADD_TRASHED_DOCUMENT (state, value) {
    if (!isArray(state.trash_documents[value.arrayId])) {
        state.trash_documents[value.arrayId] = []
    }
    state.trash_documents[value.arrayId].push(value.file)
}

export function RESTORE_TRASHED_DOCUMENT (state, value) {
    let index = findIndex(state.trash_documents[value.arrayId], function (o) {
        return o._id === value.fileId
    })
    if (index > -1) {
        state.trash_documents[value.arrayId].splice(index, 1)
    }
}

export function HARD_REMOVE_DOC (state, value) {
    let index = findIndex(state.trash_documents[value.arrayId], function (o) {
        return o._id === value.fileId
    })
    if (index > -1) {
        state.trash_documents[value.arrayId].splice(index, 1)
    }
}

export function SET_FILE_HOVER (state, value) {
    state.fileHover = value
}

export function SET_FOLDERS (state, value) {
    state.folders[value.arrayId] = value.folders
}

export function ADD_FOLDER (state, value) {
    state.folders[value.arrayId].unshift(value.folder)
}

export function SET_FOLDER_FLAGS (state, value) {
    state.folderFlags = value
}

export function SET_FOLDER_NAME (state, value) {
    state.folderFlags.name = value
}

export function SET_SEARCH_PATHS (state, value) {
    state.searchPaths = value
}

export function SET_SEARCH_VAL (state, value) {
    state.searchPathsVal = value
}

export function SET_IS_SEARCHING (state, value) {
    state.isSearching = value
}

export function SET_SEARCH_LOCAL (state, value) {
    state.searchLocal = value
}

export function SET_VIEW_TYPE (state, value) {
    state.viewType = value
}

export function CLEAR_BREADCRUMB (state, value) {
    state.foldersBreadcrumbs = value
}

export function ADD_BREADCRUMB (state, value) {
    state.foldersBreadcrumbs.push(value)
}

export function SET_SYNC_ALL_SHARED_FILES (state, value) {
    state.syncAllSharedFiles = value
}

export function SET_SEARCH_BREADCRUMB (state, value) {
    state.foldersBreadcrumbs.splice(1, state.foldersBreadcrumbs.length)
    value.forEach((item) => {
        state.foldersBreadcrumbs.push(item)
    })
}

export function REMOVE_BREADCRUMB (state, value) {
    if (value || value === 0) {
        state.foldersBreadcrumbs.splice(value + 1, state.foldersBreadcrumbs.length)
    } else {
        state.foldersBreadcrumbs.pop()
    }
}

export function SET_LOADING (state, value) {
    if (value) {
        state.loading += 1
    } else {
        state.loading -= 1
    }
}

export function REMOVE_MY_FOLDER (state, value) {
    let index = findIndex(
        state.folders[value.arrayId],
        function (folder) {
            return folder._id === value.folderId
        }
    )
    if (index > -1) {
        state.folders[value.arrayId].splice(index, 1)
    }
}

export function SET_UPLOADER_ENABLED (state, value) {
    state.uploaderEnabled = value
}

export function SET_SHOW_ADD_FOLDER_MODAL (state, value) {
    state.showAddFolderModal = value
}

export function CREATE_NEW_FOLDER_IN_PROJECT_PHOTOS (state, value) {
    state.createNewFolderInProjectPhotos = value
}

export function SET_OPEN_UPLOAD_DOCUMENT_DIALOG (state, value) {
    state.openUploadDocumentDialog = !state.openUploadDocumentDialog
}

export function SET_SHARING_RESOURCE_OBJECT (state, value) {
    state.sharingResourceObject = value
}

export function SET_SHARING_RESOURCE_WAY (state, value) {
    state.sharingResourceWay = value
}

export function UPDATE_SHARING_RESOURCE_INVITATIONS_IDS (state, value) {
    state.sharingResourceObject.attached_employees_ids = value
}

export function SET_PROJECT_PHOTOS_FOLDER (state, folderObject) {
    state.projectPhotosFolder = folderObject
}

export function SET_CUSTOM_COLUMNS (state, value) {
    state.customColumns = value
}

export function SELECT_FILE (state, file) {
    const index = state.selectedFiles.findIndex(singleFile => singleFile._id === file._id)

    if (index === -1) {
        state.selectedFiles.push(file)
    } else {
        state.selectedFiles.splice(index, 1)
    }
}

export function SETUP_ACTIONS_OBJECT (state, arrayId) {
    if (!(arrayId in state.selectedFilesForActions)) {
        state.selectedFilesForActions[arrayId] = []
    }

    if (!(arrayId in state.lastSelectedFileForAction)) {
        state.lastSelectedFileForAction[arrayId] = null
    }
}

export function SELECT_FILE_FOR_ACTION (state, data) {
    const index = state.selectedFilesForActions[data.arrayId].findIndex(singleFile => singleFile._id === data.file._id)

    if (index === -1) {
        state.selectedFilesForActions[data.arrayId].push(data.file)
    }
}

export function SET_LAST_SELECTED_FOR_ACTION (state, data) {
    state.lastSelectedFileForAction[data.arrayId] = data.index
}

export function REMOVE_SELECTED_FILE_FOR_ACTION (state, data) {
    const index = state.selectedFilesForActions[data.arrayId].findIndex(singleFile => singleFile._id === data.file._id)

    if (index !== -1) {
        state.selectedFilesForActions[data.arrayId].splice(index, 1)
    }
}

export function REMOVE_ALL_SELECTED_FILES_FOR_ACTION (state, arrayId) {
    state.selectedFilesForActions[arrayId] = []
}

export function CLEAR_SELECTED_FILES (state) {
    state.selectedFiles = []
}

export function SET_SELECT_MULTIPLE_FILES (state, value) {
    state.selectMultipleFiles = value
}

export function SET_CLONED_DOCUMENT_ID (state, data) {
    const index = state.selectedFiles.findIndex(singleFile => singleFile._id === data._id)

    if (index > -1) {
        state.selectedFiles[index].document_id = data.document_id
    }
}

export function SET_SHOW_RENAME_FOLDER_MODAL (state, value) {
    state.isRenameFolderModalShown = value
}

export function ADD_UPLOADING_FILE (state, data) {
    if (!state.uploadingFilesList.hasOwnProperty(data.arrayId)) {
        state.uploadingFilesList[data.arrayId] = []
    }
    state.uploadingFilesList[data.arrayId].push(data)
}

export function CLEAR_UPLOADING_FILES (state, data) {
    delete state.uploadingFilesList[data.arrayId]
}

export function CHANGE_UPLOADING_FILE_STATUS (state, data) {
    const index = state.uploadingFilesList[data.arrayId].findIndex(file => file.tempId === data.tempId)

    if (index > -1) {
        const fileFound = state.uploadingFilesList[data.arrayId][index]
        fileFound.status = data.status
        fileFound.progress = data.progress
    }
}
