import { defineComponent } from 'vue'
import Details from './tabs/details/Details.vue'
import TableOfContents from './tabs/table-of-contents/TableOfContents.vue'
import Markups from './tabs/markups/Markups.vue'
import History from './tabs/history/History.vue'
import { mapGetters } from 'vuex'
import FeatureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import { RightPanelTab } from '@/io-modules/drawings/enums/RightPanelTab'

export default defineComponent({
    components: {
        Details,
        TableOfContents,
        Markups,
        History,
    },
    props: {
        isArchivedVersion: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [FeatureFlagsMixin],
    data () {
        return {
            rightPanelContent: 'TableOfContents',
            panelCollapsed: false,
        }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            panelContent: 'getPanelContent',
        }),
        rightPanelTabs (): { name: string, componentName: string, tabName: RightPanelTab }[] {
            return [
                {
                    name: this.$t('Table of Contents'),
                    componentName: 'TableOfContents',
                    tabName: RightPanelTab.TABLE_OF_CONTENTS,
                },
                {
                    name: this.$t('Details'),
                    componentName: 'Details',
                    tabName: RightPanelTab.DETAILS,
                },
                {
                    name: this.$t('Markups'),
                    componentName: 'Markups',
                    tabName: RightPanelTab.MARKUPS,
                },
                {
                    name: this.$t('History'),
                    componentName: 'History',
                    tabName: RightPanelTab.HISTORY,
                },
            ]
        },
        queryTab (): string {
            return this.$route.query?.tab as string || RightPanelTab.TABLE_OF_CONTENTS
        },
    },
    watch: {
        panelContent: {
            handler (): void {
                this.setPanelContent(this.panelContent)
            },
        },
    },
    beforeMount () {
        const selectedTab = this.rightPanelTabs.find(item => item.tabName === this.queryTab)
        this.setPanelContent(selectedTab.tabName)
    },
    methods: {
        setPanelContent (tabName: string): void {
            this.panelCollapsed = false
            const selectedTab = this.rightPanelTabs.find(item => item.tabName === tabName)
            this.rightPanelContent = selectedTab.componentName
            this.$router.replace({ query: { tab: selectedTab.tabName } })
        },
        deletePdftronMarkup (id: string): void {
            this.$emit('delete-pdftron-markup', id)
        },
        cancelAnnotation (): void {
            this.$emit('cancel-annotation')
        },
        annotationAdded (): void {
            this.$emit('annotation-added')
        },
        annotationDeleted (uuid: string): void {
            this.$emit('annotation-deleted', uuid)
        },
    },
})
