import { defineComponent, PropType } from 'vue'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn.vue'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes'
import { Contact } from '@/interfaces/components/invite-modal/Contact'
import FieldNumericInt from '@/components/fields/fieldNumericInt.vue'
import { LabelType } from '@/constants/LabelConstants'
import dateTimeHelper from '@/helpers/dateTime'
import { mapMutations } from 'vuex'
import { mapActions, mapState } from 'pinia'
import { globalFundingStore } from '../../../stores/globalFundingStore'
import { FundingSourcesContact, FundingSourcesFund } from '../../../interfaces/GlobalFundingInterfaces.ts'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import fundingClient from '../../../api-clients/fundingClient'
import dayjs from 'dayjs'
import appTypes from '@/base/appTypes'
import { AxiosError } from 'axios'
import { InviteModalTabs } from '@/components/invite-modal-v3/enums/InviteModalTabs'

export default defineComponent({
    name: 'AddNewCapitalFundModal',
    components: { FieldDateTime, FieldNumericInt, FormInputColumn, IOModal, InvitePreview },
    props: {
        providerId: {
            type: String,
        },
        contactInfo: {
            type: Object as PropType<FundingSourcesContact>,
        },
        fundId: {
            type: String,
        },
        providerCompanyId: {
            type: String,
        },
        createFromProject: {
            type: Boolean,
        },
    },
    emits: ['close', 'fundEdited', 'fundCreated'],
    data () {
        return {
            invitationClientKey: InviteModalClientKeys.PROJECT_FUNDING_SOURCES_CAPITAL_FUNDS,
            PreviewTypes: PreviewTypes,
            LabelType: LabelType,
            fundContactInfo: null as FundingSourcesContact,
            capitalFundData: {
                name: null,
                account_number: null,
                amount: null,
                start_date: null,
                expiration_date: null,
                contact_id: null,
            } as FundingSourcesFund,
            dateOptions: {
                formatted: dateTimeHelper.getDateFormat(),
                format: 'YYYY-MM-DD',
                'only-date': true,
            },
            appTypes: appTypes,
            datesErrorMessage: 'The expiration date must be a date after start date.',
            InviteModalTabs: InviteModalTabs
        }
    },
    computed: {
        ...mapState(globalFundingStore, [
            'addNewCapitalFundModal',
            'providersList',
        ]),
        allowToCreate (): boolean {
            return (!!(this.capitalFundData.name && this.capitalFundData.amount && this.capitalFundData.contact_id))
        },
        headerTitle (): string {
            return this.addNewCapitalFundModal.editMode ? this.$t('Edit Fund') : this.$t('Create New Program Fund')
        },
    },
    async mounted () {
        if (this.addNewCapitalFundModal.editMode) { //depends on whether the fund is being created or edited: created - props, edited - request
            try {
                const fundInfoResponse = await fundingClient.getCapitalFundInfo(this.fundId)

                this.fundContactInfo = fundInfoResponse.data.contact
                if (fundInfoResponse && fundInfoResponse.data) {
                    this.capitalFundData = {
                        name: fundInfoResponse.data.name,
                        account_number: fundInfoResponse.data.account_number,
                        amount: fundInfoResponse.data.amount,
                        contact_id: fundInfoResponse.data.contact_id,
                        start_date: fundInfoResponse.data.start_date ? fundInfoResponse.data.start_date + ' ' + '12:00:00' : null,
                        expiration_date: fundInfoResponse.data.expiration_date ? fundInfoResponse.data.expiration_date + ' ' + '12:00:00' : null,
                    }
                }
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        } else {
            this.fundContactInfo = this.contactInfo
        }

        // set preselected contact for "create new fund" modal
        if (!this.createFromProject) {
            this.setStateObj(this.invitationClientKey)
            this.setSelectedContacts({ key: this.invitationClientKey, data: [this.fundContactInfo] })

            if (this.fundContactInfo) {
                this.capitalFundData.contact_id = this.fundContactInfo.id //because contact is preselected
            }
        }
    },
    unmounted () {
        this.$store.dispatch('inviteModal/clearPersonsOnItem', { key: this.invitationClientKey })
    },
    methods: {
        ...mapMutations('inviteModal', {
            setSelectedContacts: 'SET_SELECTED_CONTACTS',
            setStateObj: 'SET_STATE_OBJECT',
        }),
        ...mapActions(globalFundingStore, [
            'setAddNewCapitalFundModal',
            'setFundCreatedFromProject',
        ]),
        toggleShowNewFundModal (): void {
            this.setAddNewCapitalFundModal({
                show: !this.addNewCapitalFundModal.show,
                editMode: true,
            })
        },
        onSelectProvider (contact: Contact): void {
            if (contact) {
                const selectedContact = contact.bookmarked[0]

                this.capitalFundData.contact_id = selectedContact.id
            }
        },
        async createNewCapitalFund (): Promise<void> {
            const request = {
                ...this.capitalFundData,
                amount: this.capitalFundData.amount ? this.capitalFundData.amount.toString() : '0',
                start_date: this.modifyDate(this.capitalFundData.start_date) || null,
                expiration_date: this.modifyDate(this.capitalFundData.expiration_date) || null,
            }

            let createdFund
            if (this.createFromProject) {
                try {
                    createdFund = await fundingClient.createCapFundFromProject(request)

                    this.showNotification('success', this.$t('New Capital Fund has been created!'))
                    this.$emit('fundCreated', createdFund)

                    this.setFundCreatedFromProject(true)
                    this.setAddNewCapitalFundModal({ show: false, editMode: false })
                } catch (e) {
                    this.handleCreationError(e)
                }
            } else {
                try {
                    createdFund = await fundingClient.createCapFund(this.providerId, request)

                    this.$router.push({
                        name: 'funding-sources-fund-details',
                        params: { id: createdFund.data.id },
                    })

                    this.setAddNewCapitalFundModal({ show: false, editMode: false })
                } catch (e) {
                    this.handleCreationError(e)
                }
            }
        },
        handleCreationError (e: AxiosError<Error>): void {
            if (e.response && e.response.data.message === this.datesErrorMessage) {
                this.showNotification('error', this.$t(this.datesErrorMessage))
            } else {
                this.errorHandleNoRedirect(e)
            }
        },
        async editNewCapitalFund (): Promise<void> {
            const request = {
                ...this.capitalFundData,
                start_date: this.modifyDate(this.capitalFundData.start_date) || null,
                expiration_date: this.modifyDate(this.capitalFundData.expiration_date) || null,
            }

            try {
                const response = await fundingClient.editCapitalFund(this.providerId, this.fundId, request)
                this.toggleShowNewFundModal()
                this.$emit('fundEdited', response.data)
            } catch (e) {
                if (e.response.data.message === this.datesErrorMessage) {
                    this.showNotification('error', this.$t(this.datesErrorMessage))
                }
            }
        },
        clearProvider (): void {
            this.capitalFundData.contact_id = null
        },
        modifyDate (date: string): string {
            if (!date) {
                return
            }
            return dayjs(date).format('YYYY-MM-DD ')
        },
        closeModal (): void {
            this.$emit('close')
        },
    },
})
