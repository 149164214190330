/**
 * This is a swiss knife to safely load and manage loading state for asynchronous operations.
 * Optionally integrating with a global spinner and handling errors with notifications.
 * Enabling basic API client notifications shows green success and red error notification banners.
 *
 * Examples:
 *
 * 1. Basic usage:
 * const { loading, load } = useLoader()
 * load(async () => { ... })
 *
 * 2. Using global spinner animation loading and success/error automatic API client notifications:
 * const { loading, load } = useLoader({ globalSpinner: true })
 * load(async (showApiClientNotification) => {
 *     await showApiClientNotification(someApiClientCall())
 *     //...
 * })
 *
 * 3. Custom error handling and error only API client notifications:
 * load(async (showApiClientNotification) => {
 *     await showApiClientNotification(someApiClientCall(), { success: false })
 *     ...
 * }, (error) => { ... })
 */

import { ref, computed } from 'vue'
import { AxiosPromise, AxiosError, isAxiosError } from 'axios'
import { useStore } from 'vuex'
import useNotification from '@/composables/useNotification.ts'

export type LoaderOptions = {
    globalSpinner?: boolean
}
export type ApiClientNotificationOptions = {
    success?: boolean
    error?: boolean
}
export type ShowApiClientNotification = <V> (
    apiClientCall: AxiosPromise<V>,
    options?: ApiClientNotificationOptions,
) => AxiosPromise<V>;

const { showNotification } = useNotification()
const loaderDefaultOptions: LoaderOptions = { globalSpinner: false }
const apiClientNotificationDefaultOptions: ApiClientNotificationOptions = { success: true, error: true }

const showApiClientNotification: ShowApiClientNotification = async <T> (
    apiClientCall: AxiosPromise<T>,
    options: ApiClientNotificationOptions = {},
): AxiosPromise<T> => {
    const finalOptions: ApiClientNotificationOptions = { ...apiClientNotificationDefaultOptions, ...options }
    try {
        const response = await apiClientCall
        const text = response?.data?.message
        finalOptions.success && text && showNotification('success', text)

        return response
    } catch (error: AxiosError) {
        const text = error.response?.data?.message || error.message
        finalOptions.error && text && showNotification('error', text)

        throw error
    }
}

export default (options: LoaderOptions = {}) => {
    const finalOptions: LoaderOptions = { ...loaderDefaultOptions, ...options }
    const localLoading = ref(false)
    const store = useStore()

    const setLoading = (value: boolean) => finalOptions.globalSpinner
        ? store.dispatch('loadingBar/setLoading', value)
        : (localLoading.value = value)

    const load = async <T> (
        fn: (showApiClientNotification: ShowApiClientNotification) => Promise<T>,
        handleError?: (error: AxiosError) => void,
    ): Promise<T> => {
        try {
            setLoading(true)
            return await fn(showApiClientNotification)
        } catch (error: AxiosError) {
            handleError?.(error)

            if (!isAxiosError(error)) { throw new Error(error) }

            !store.getters['appStore/isProduction'] && console.error(error)

        } finally {
            setLoading(false)
        }
    }

    const loading = computed(() => finalOptions.globalSpinner
        ? store.getters['loadingBar/getLoadingStatus'] as boolean
        : localLoading.value)

    return {
        loading,
        load,
    }
}
