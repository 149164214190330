import { FolderType } from '../../../modules/documents/enums/FolderType'

/**
 * Get files by id
 * @param state
 * @return array
 */
export function getMyFiles (state) {
    return idx => {
        // storybook case force some files to show
        if (idx === 'storybook') {
            state.my_documents[idx] = state.storybook.files
        }

        return state.my_documents[idx] ? state.my_documents[idx] : []
    }
}

export function getFileById (state) {
    return (documentId, modelId) => {
        if (!state.my_documents[modelId]) {
            return null
        }
        return state.my_documents[modelId].find(document => {
            return document._id === documentId
        })
    }
}

/**
 * Get files by id
 * @param state
 * @return array
 */
export function getSharedFiles (state) {
    return idx => {
        return state.shared_documents[idx] ? state.shared_documents[idx] : []
    }
}

/**
 * Get files by section id
 * @param state
 * @return array
 */
export function getSharedFilesBySection (state) {
    return sectionId => {
        let files = []
        for (let modelId in state.shared_documents) {
            files = files.concat(state.shared_documents[modelId].filter(document => {
                return document.section === sectionId
            }))
        }
        return files
    }
}

/**
 * Get files by id
 * @param state
 * @return array
 */
export function getTrashedFiles (state) {
    return idx => {
        return state.trash_documents[idx] ? state.trash_documents[idx] : []
    }
}

/**
 * Get folders by id
 * @param state
 * @return array
 */
export function getFolders (state) {
    return idx => {
        return state.folders[idx] ? state.folders[idx] : []
    }
}

/**
 * Get folders path
 * @param state
 * @return array
 */
export function getFoldersBreadcrumbs (state) {
    return state.foldersBreadcrumbs
}

/**
 * Return folders from search
 * @param state
 * @return array
 */
export function getFoldersPaths (state) {
    return state.searchPaths
}

/**
 * Return search input
 * @param state
 * @return array
 */
export function getSearchVal (state) {
    return state.searchPathsVal
}


/**
 * Return search local
 * @param state
 * @return array
 */
export function getSearchLocal (state) {
    return state.searchLocal
}

/**
 * Return is searching
 * @param state
 * @return array
 */
export function getSearchState (state) {
    return state.isSearching
}

/**
 * Get folder flags
 * @param state
 * @return array
 */
export function getFolderFlags (state) {
    return state.folderFlags
}


/**
 * Get loading state
 * @param state
 * @return boolean
 */
export function getLoading (state) {
    return state.loading
}

/**
 * Get show add folder modal
 *
 * @param state
 * @return boolean
 */
export function getShowAddFolderModal (state) {
    return state.showAddFolderModal
}

/**
 * Get create new Fflder in project photos
 *
 * @param state
 * @return boolean
 */
export function getCreateNewFolderInProjectPhotos (state) {
    return state.createNewFolderInProjectPhotos
}

/**
 * Get open upload document dialog
 *
 * @param state
 * @return boolean
 */
export function getOpenUploadDocumentDialog (state) {
    return state.openUploadDocumentDialog
}

/**
 * Get loading state
 * @param state
 * @return Object
 */
export function getSharingResourceObject (state) {
    return state.sharingResourceObject
}

/**
 * Get loading state
 * @param state
 * @return string
 */
export function getSharingResourceWay (state) {
    return state.sharingResourceWay
}

export function getProjectPhotosFolder (state) {
    return state.projectPhotosFolder
}

export function getCustomColumns (state) {
    return state.customColumns
}

export function getSelectedFiles (state) {
    return state.selectedFiles
}

export function getSelectedFilesForActions (state) {
    return arrayId => {
        return state.selectedFilesForActions[arrayId]
    }
}

export function isSelectedForActions (state) {
    return data => {
        return state.selectedFilesForActions[data.arrayId] ? state.selectedFilesForActions[data.arrayId].findIndex(singleFile => singleFile._id === data.id) > -1 : false
    }
}

export function getLastSelectedForAction (state) {
    return arrayId => {
        return state.lastSelectedFileForAction[arrayId]
    }
}

export function getSelectMultipleFiles (state) {
    return state.selectMultipleFiles
}
export function hasSelectedFiles (state) {
    return state.selectedFiles.length > 0
}

export function viewType (state) {
    return state.viewType
}
export function uploaderEnabled (state) {
    return state.uploaderEnabled
}
export function isSystemFolder (state) {
    return state.folderFlags.is_system
}
export function isFolderTypeProject (state) {
    return state.folderFlags.folder_type === FolderType.PROJECT
}

export function getIsRenameFolderModalShown (state) {
    return state.isRenameFolderModalShown
}

export function getUploadingFiles (state) {
    return arrayId => {
        return state.uploadingFilesList[arrayId] ?? []
    }
}

export function getUploadingFilesInProgress (state) {
    return arrayId => {
        return state.uploadingFilesList[arrayId] && state.uploadingFilesList[arrayId].length ? state.uploadingFilesList[arrayId].filter(file => file.status === 'uploading') : []
    }
}

