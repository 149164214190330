export const addAlert = ({ commit }, data) => {
    commit('ADD_ALERT', data)
}

export const removeAlert = ({ commit }, alertId) => {
    commit('REMOVE_ALERT', alertId)
}

export const removeAllAlerts = ({ commit }) => {
    commit('REMOVE_All_ALERTS')
}

export const removeAlertsByType = ({ commit }, type) => {
    commit('REMOVE_ALERTS_BY_TYPE', type)
}

export const setDuringAction = ({ commit }, value) => {
    commit('SET_DURING_ACTION', value)
}
