import { ApiClient as client } from '@/api/auth'
import { AxiosPromise } from 'axios'

const webCredentialClient = {
    getWebCredentials (id: string): AxiosPromise<string> {
        return client.get('/web-credentials')
    }
}

export default webCredentialClient
