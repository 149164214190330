<template>
    <PopupModal :width="600" class="io-provider-modal" @close="closeModal">
        <template #header>
            <div class="io-provider-modal__left">
                <span class="io-provider-modal__icon icon-cash"/>
                <span class="io-provider-modal__title">{{ $t('Add New Provider') }}</span>
            </div>
            <span class="io-provider-modal__icon-cross icon-cross" @click="closeModal"/>
        </template>
        <template #default>
            <div class="io-provider-modal__label">{{ $t('Provider') }}<span>*</span></div>
            <div class="io-provider-modal__body">
                <InvitePreview
                    :key="invitationClientKey"
                    :componentKey="invitationClientKey"
                    :model-type="invitationClientKey"
                    :previewType="PreviewTypes.BUTTON_SECONDARY"
                    :selectMultiple="false"
                    :modalZIndex="100"
                    :buttonLabelText="$t('Select')"
                    :allowedClientsTypes="[appTypes.TYPE_OWNER]"
                    :preselected-workspace-type="appTypes.TYPE_OWNER"
                    :disable-inviting="true"
                    :categories="[InviteModalTabs.ALL, InviteModalTabs.EMPLOYEES, InviteModalTabs.CONTACTS]"
                    :is-adding-allowed="false"
                    include-current-user-on-search-results
                    :show-single-contact-avatar="true"
                    :show-status-for-single-contact="false"
                    @inviteModalDone="onSelectProvider"
                />
            </div>
            <div v-if="showAlert" class="io-provider-modal__alert">
                <AlertBox>
                    <p>
                        {{ $t(`The selected contact is already a registered funding provider. Clicking "Add Provider" will open
                        their existing details page.`) }}
                    </p>
                </AlertBox>
            </div>
        </template>
        <template #footer>
            <IOButton
                variant="secondary"
                size="normal"
                default="Button"
                @click="closeModal"
            >
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton
                variant="primary"
                size="normal"
                default="Button"
                :disabled="!selectedProvider"
                @click="sendProvider()"
            >
                {{ $t('Add Provider') }}
            </IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./AddProviderModal.ts"></script>
<style lang="scss" src="./AddProviderModal.scss" scoped></style>
