<template>
    <div @click="redirectToRoute()" class="io-single-message io-single-message--new">
        <div class="io-single-message__picture-holder">
            <div class="io-single-message__icon"><i class="fas" :class="item.icon"></i></div>
        </div>
        <div class="io-single-message__content">
            <div class="io-single-message__title-row">
                <div class="io-single-message__title" v-html="prepareNotificationMessage(item)"></div>
                <!-- <div class="io-status-pill io-accent">RFP</div>-->
                <div
                    v-if="item.action && item.action.show"
                    class="io-single-message__call-to-action"
                >
                    <router-link
                        :to="item.action.url"
                        class="io-accent"
                    >
                        {{ item.action.text }}
                    </router-link>
                </div>
                <div
                    v-else
                    class="io-single-message__dismiss io-accent"
                    @click.stop="markAlertAsRead(item)"
                >
                    Mark read
                </div>
            </div>
            <div class="io-single-message__info-row">
                <div class="io-single-message__text">{{ $filters.agoDate(item.created_at) }}</div>
                <div class="io-single-message__author" v-if="item.requesterName">{{ $t('by') }} <span>{{ item.requesterName }}</span></div>
            </div>
        </div>
    </div>
</template>
<script>
import notificationMixin from './mixins/notificationMixin'

export default {
    name: 'Notification Item',
    mixins: [notificationMixin],
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    methods: {
        redirectToRoute () {
            this.$emit('closeList')
            this.markAlertAsRead(this.item)
            this.redirectToNotificationResource(this.item)
        }
    }
}
</script>
