import { ApiClient as client, ApiClientBase } from '@/api/api'
import { AxiosPromise } from 'axios'
import InvoiceDetails from '../interfaces/responses/InvoiceDetails'
import FinancialInfo from '../interfaces/responses/FinancialInfo'
import GrossBillingSummary from '../interfaces/responses/GrossBillingSummary'
import ContractSummary from '../interfaces/responses/ContractSummary'
import FundingSource from '../interfaces/FundingSource'
import CostCode from '../interfaces/requests/FundingCostCode'
import { components, paths } from '@/interfaces/api/apidefinitions'
import TimelineItem from '../interfaces/TimelineItem'

export type GeneralInvoiceDetailsResponse = paths['v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/{invoice_id}']['get']['responses']['200']['content']['application/json'];
export type BasicInvoiceDetails = components['schemas']['BasicInvoiceDetails'];
export type MyInvoiceDetails = components['schemas']['ContractedInvoiceDetails'];
export type VendorInvoiceDetails = components['schemas']['VendorInvoiceDetails'];
export type NonContractedInvoiceDetails = components['schemas']['NonContractedInvoiceDetails'];
export default {
    getInvoiceDetails (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<GeneralInvoiceDetailsResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }`)
    },

    getInvoiceGrossBillingSummary (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<GrossBillingSummary> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/gross-billing-summary`)
    },

    getInvoiceFinancialInfo (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<FinancialInfo> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/financial-info`)
    },

    getInvoiceContractSummary (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<ContractSummary> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/contract-summary`)
    },

    getApplicableFundingSources (projectMongoIdLocal: string, totalAmount: number, costCodes: CostCode[], invoiceId: string): AxiosPromise<FundingSource> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`invoices/${ projectMongoIdLocal }/applicable-funds`, {
            params: {
                total_amount: totalAmount,
                project_local_id: projectMongoIdLocal,
                cost_codes: costCodes,
                invoice_id: invoiceId
            }
        })
    },

    getInvoiceFundingSources (invoiceId: string, projectMongoIdLocal: string): AxiosPromise<FundingSource> {
        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/funds`)
    },

    deleteInvoice (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.delete(`invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }`)
    },

    getInvoiceTimeline (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<TimelineItem[]> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/timeline`)
    },

    getAccountingIntegration (): AxiosPromise<object> {
        return ApiClientBase.get('/api/v1/marketplace/integrations/accounting/active')
    },

    canSubmitToAccountingInfo (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<object> {
        return client.get(`invoices/${ projectMongoIdLocal }/${ invoiceId }/can-submit`)
    },

    setInvWithTaxValue (projectMongoIdLocal: string, invoiceId: string, value: number): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.post(`invoices/${ projectMongoIdLocal }/invoice/${ invoiceId }/current-tax-value`, { current_tax_value: value })
    }
}
