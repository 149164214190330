<template>
    <div
        class="io-invoices-list io-app-packages-list"
        :class="{ 'io-invoices-list--empty': placeholderVisible }"
    >
        <Placeholder
            v-if="showMainPlaceholder"
            icon="icon-folder-slash"
            :title="$t('Ready to Build an Application Package?')"
            :subtitle="$t('Combine invoices for billing periods into easy-to-review application packages for client approval and funding.')"
            :buttons-cta="ctaButtons"
        />
        <div v-else>
            <InvoicesListHeader v-if="getList.length" :title="$t('Application Packages')" :has-manage-columns="false"/>
            <InvoicesListTable
                :headers="getListHeaders"
                :items="getList"
                :loading-data="loadingData"
                :manageColumns="false"
                :totals="totals"
                @onRemoveAppPackage="removeAppPackage"
                @loadData="loadData"
            />
            <LoadingScreen :custom-show-loading="loading || showContentLoader"/>
        </div>
    </div>
</template>

<script lang="ts" src="./ApplicationPackagesList.ts"></script>
<style lang="scss" src="../invoices-lists/InvoicesLists.scss" scoped></style>
<style lang="scss" src="./ApplicationPackagesList.scss" scoped></style>
