import RightModal from '@/components/RightModal/RightModal.vue'
import { defineComponent } from 'vue'
import InvoiceType from '@/io-modules/invoices/enums/InvoiceType.ts'
import InvoiceCreationSteps from '@/io-modules/invoices/enums/InvoiceCreationSteps.ts'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'
import invoiceCreationClient, { ExecutedContracts } from '@/io-modules/invoices/api-clients/invoiceCreationClient.ts'
import { mapState as mapStateVuex, mapGetters as mapGettersVuex } from 'vuex'
import { mapActions } from 'pinia'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView.ts'
import { appPackageViewStore } from '@/io-modules/invoices/stores/appPackageView.ts'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName.ts'
import { InvoiceSubType } from '@/io-modules/invoices/enums/InvoiceSubType.ts'
import StepSlideout from '@/components/step-slideout/StepSlideout.vue'
import iconLibrary from '@/base/iconLibrary.js'
import AppTypes from '@/base/appTypes.ts'
import OptionsGroup, { OptionsGroupItem } from '@/components/step-slideout/interfaces/OptionsGroup.ts'
import AppPackageRouteName from '@/io-modules/invoices/enums/AppPackageRouteName.ts'
import ContractRouteName from '@/io-modules/project-contracts/enums/ContractRouteName'

export default defineComponent({
    name: 'InvoiceCreationSlideout',
    components: {
        StepSlideout,
        RightModal
    },
    props: {
        preSelectedContractId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data () {
        return {
            InvoiceType,
            InvoiceCreationSteps,
            PillVariant,
            ContractRouteName,
            loaded: false,
            selectedInvoiceType: null as InvoiceType,
            step: InvoiceCreationSteps.SelectInvoiceType,
            executedContracts: null as ExecutedContracts & { title?: string; description?: string }[],
            availableTypes: null as InvoiceType[],
            selectionCompleted: false,
            selections: [] as OptionsGroupItem[],
        }
    },
    computed: {
        ...mapStateVuex('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id,
        }),

        ...mapGettersVuex('appStore', ['isOwner', 'isOwnerRep']),

        nonContractedTypeAvailable (): boolean {
            return this.availableTypes.includes(InvoiceType.NonContracted)
        },

        appPackagesInvoiceTypeAvailable (): boolean {
            return this.isOwner || this.isOwnerRep
        },

        nonContractedIsSelected (): boolean {
            return this.selections[0].selected === InvoiceType.NonContracted
        },

        contractedIsSelected (): boolean {
            return this.selections[0].selected === InvoiceType.Contracted
        },

        appPackageIsSelected (): boolean {
            return this.selections[0].selected === InvoiceType.AppPackages
        },

        selectedContract (): ExecutedContracts {
            const contractId = this.preSelectedContractId ? this.preSelectedContractId : this.selections[1]?.selected

            return this.executedContracts.find(contract => contract.id === contractId)
        },

        contractType (): InvoiceSubType {
            if (this.nonContractedIsSelected) {
                return InvoiceSubType.NonContractedInvoice
            } else if (this.contractedIsSelected) {
                return this.selectedContract?.is_main ? InvoiceSubType.MyInvoice : InvoiceSubType.VendorInvoice
            }
        },

        disableNextStep (): boolean {
            return !this.selections.length || this.selections.length === 2 && !this.selections[1]?.selected
        },

        selectOptions (): OptionsGroup {
            return {
                label: this.$t('Select Type'),
                key: InvoiceCreationSteps.SelectInvoiceType,
                options: [
                    {
                        description: this.$t('Contracted Invoice'),
                        subDescription: [this.$t('Invoice to an executed contract.')],
                        value: InvoiceType.Contracted,
                        customMessage: !this.executedContracts.length,
                        isDisabled: !this.executedContracts.length,
                        nextStep: !this.preSelectedContractId ? {
                            label: this.$t('Select executed contract'),
                            key: InvoiceCreationSteps.SelectContract,
                            isSearchable: true,
                            options: this.executedContracts.length ? this.executedContracts.map(contract => ({
                                description: contract.name,
                                subDescription: [contract.contract_name],
                                value: contract.id,
                                avatarId: contract.logo,
                                showLogo: true,
                                isAvatarForCompany: true,
                                additionalValue: this.calculateContractAmount(contract.contract_amount),
                                statusPills: [
                                    ...([iconLibrary.methods.getAppTypeNameColor(contract.app_type || AppTypes.TYPE_OFF_SYSTEM)]),
                                    ...(contract.is_main ? [{ variant: PillVariant.INFO_BG, text: this.$t('Main Contract') }] : []),
                                ],
                                canComplete: true
                            })) : undefined,
                        } : undefined,
                    },
                    ...this.nonContractedTypeAvailable ? [
                        {
                            description: this.$t('Non-Contracted Invoice'),
                            subDescription: [this.$t('Invoice for services without a contract, such as utilities or other miscellaneous charges.')],
                            value: InvoiceType.NonContracted,
                            canComplete: true
                        }
                    ] : [],
                    ... this.appPackagesInvoiceTypeAvailable ? [
                        {
                            description: this.$t('Application Package'),
                            subDescription: [this.$t('Create to send a collection of invoices for client approval.')],
                            value: InvoiceType.AppPackages,
                            canComplete: true
                        }
                    ] : [],
                ]
            }
        },

    },
    async beforeMount () {
        const { data } = await invoiceCreationClient.getInvoiceCreationOptions(this.projectLocalID)

        if (data) {
            this.availableTypes = data.available_types
            this.executedContracts = data.executed_contracts.sort((a, b) => {
                if (a.is_main !== b.is_main) {
                    return a.is_main ? -1 : 1
                }

                return a.name.localeCompare(b.name)
            })
        }

        this.loaded = true
        this.clearAllInvoiceStoreData()
    },
    methods: {
        ...mapActions(invoiceViewStore, ['setCreationType', 'setSelectedForCreationContract', 'clearAllInvoiceStoreData']),
        ...mapActions(appPackageViewStore, ['setCreateModeValue']),

        calculateContractAmount (value: number): string {
            return this.$filters.formatCurrency(value / process.env.SCALE_FACTOR)
        },

        defineNextStep (): void {
            this.setCreationType(this.contractType)
            this.setSelectedForCreationContract(this.selectedContract)

            const invoiceCreationOptions = {
                invoiceType: this.contractType,
                selectedContract: this.selectedContract
            }

            sessionStorage.setItem('invoiceCreationOptions', JSON.stringify(invoiceCreationOptions))

            if (this.nonContractedIsSelected || this.contractedIsSelected) {
                this.$router.push({
                    name: InvoiceRouteName.InvoiceDetails,
                    params: { id: 0 },
                    query: { type: this.contractType },
                })

            } else if (this.appPackageIsSelected) {
                this.$router.push({
                    name: AppPackageRouteName.AppPackageDetails,
                    params: { id: 0 },
                })

                this.setCreateModeValue(true)
                this.$emit('close')
            }
        },

        handleFlowCompleted (selections: OptionsGroup[], isPartially: boolean): void {
            this.selections = selections
            this.selectionCompleted = !isPartially
        }
    },
})
