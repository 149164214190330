<template>
    <div class="io-share-with">
        <span>{{ $t('Shared With:') }}</span>

        <template v-if="drawingSetIsPublic">{{ $t('Project') }}</template>

        <template v-else>
            <InvitePreview
                :key="invitationClientKey"
                :component-key="invitationClientKey"
                :preview-type="previewTypes.HORIZONTAL_LIST"
                :invited-contact-persons="sharedWith"
                :select-multiple="true"
                :resource-id="drawingSetId"
                :is-locked-for-user="false"
                :save-from-modal="true"
                :model-type="invitationModelTypes.DRAWING_SET"
                :employees-list="employeesList"
                @invite-modal-done="onInviteModalDone"
            />
        </template>

    </div>
</template>

<script lang="ts" src="./ShareWith.ts"></script>
<style lang="scss" src="./ShareWith.scss" scoped></style>
