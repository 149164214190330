<template>
    <IOModal
        slideout
        scrollable
        widest
        width="850"
        :loading="loading"
        :title="$t('Uploading History')"
        icon="icon-timer-2"
        class="io-set-history-logs-index"
        @close="$emit('close')"
    >
        <Table v-if="items?.length" :headers="headers" :items="items">
            <template #tbody="{ sortedItems }">
                <template v-for="item in sortedItems" :key="item.id">
                    <tr
                        class="hover-darken cursor-pointer"
                        @click="openNewTabRoute({ name: 'drawings-history-sheets-index', params: { setId: item.id } })"
                    >
                        <td>
                            <div class="flex items-center gap-4">
                                <i class="icon-external-link text-main-lighten-3 text-lg-px" />

                                <i class="icon-folder-upload-2 text-info-lighten text-lg-px" />

                                <div>{{ item.name }}</div>
                            </div>
                        </td>

                        <td>
                            <Avatar
                                :employee-mongo-id="item.uploaded_by.id"
                                :employee-full-name="item.uploaded_by.name"
                                :company-name="item.uploaded_by.position"
                                size="sm"
                                show-full-name
                            />
                        </td>

                        <td>{{ $filters.formatDate(item.issue_date) }}</td>

                        <td class="text-right">{{ item.drawings_count }}</td>
                    </tr>
                </template>
            </template>
        </Table>
    </IOModal>
</template>

<script lang="ts" src="./SetHistoryLogsIndex.ts"></script>
