<template>
    <div class="io-project-checklist" :class="{ 'is-active': showModal }">
        <div class="io-project-checklist__button-holder">
            <i class="icon-rocket"></i>
            <div class="io-project-checklist__button-toggle" @click="showModal = !showModal">{{ completedItems }}/{{ checklistElementsData.length }} {{ $t('Completed') }}</div>
        </div>
        <transition name="fade">
            <div v-if="showModal" class="io-project-checklist__modal-holder">
                <div class="io-project-checklist__header">
                    <p>{{ $t('Project Checklist') }}</p>
                    <i class="icon-chevron-down" @click="closeModal"></i>
                </div>
                <div class="io-project-checklist__content">
                    <h1>{{ $t('Continue Setting Up') }} <br>{{ $t('Your Project') }}</h1>
                    <p class="io-project-checklist__description"><span>{{ $t('Need help?') }}</span> {{ $t('Click Learn More to see the tutorials to get your project set up.') }}</p>

                    <div class="io-project-checklist__progress-bar">
                        <div class="io-project-checklist__progress-holder">
                            <ProgressBar
                                variant="small"
                                :items="progressBarData"
                                :showLegend="false"
                            />
                        </div>
                        <div class="io-project-checklist__progress-status">{{ completedItemsPercentage }}% {{ $t('Completed') }}</div>
                    </div>

                    <div class="io-project-checklist__items-holder">
                        <template v-for="item in checklistElementsData" :key="item.type">
                            <SingleChecklistItem
                                :icon="item.icon"
                                :isCompleted="item.completed"
                                :isNextAction="isNextForAction.type === item.type"
                                :title="item.title"
                                :routeName="item.routeName"
                                :learnMoreUrl="item.learnMoreUrl"
                                :routeParams="item.routeParams"
                                :type="item.type"
                                @closeModal="closeModal"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script lang="ts" src="./ProjectChecklist.ts"></script>
<style lang="scss" src="./ProjectChecklist.scss" scoped></style>
