const ProjectExporter = () => import(/* webpackChunkName: "dataExporter" */ '@/io-modules/data-exporter/components/project-exporter/ProjectExporter.vue')
const ProjectExporterList = () => import(/* webpackChunkName: "dataExporter" */ '@/io-modules/data-exporter/components/project-exporter-list/ProjectExporterList.vue')
const ProjectExporterView = () => import(/* webpackChunkName: "ProjectExporterView" */ '@/io-modules/data-exporter/views/project-exporter-view/ProjectExporterView.vue')

import { isFeatureFlagEnabled } from '@/modules/projects/routes/feature-flag-status'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'

export default [
    {
        path: '/project-exporter',
        name: 'project-exporter',
        component: ProjectExporter,
        meta: {
            title: 'Project Exporter'
        },
        async beforeEnter (to, from, next) {
            if (!await isFeatureFlagEnabled(FeatureFlagsConsts.REPORTING_EXPORTER, false)) {
                next()
            } else {
                next({ name: 'project-exporter-dashboard' })
            }
        }
    },
    {
        path: '/project-exporter-dashboard',
        name: 'project-exporter-dashboard',
        component: ProjectExporterView,
        meta: {
            title: 'Project Exporter'
        },
        async beforeEnter (to, from, next) {
            if (await isFeatureFlagEnabled(FeatureFlagsConsts.REPORTING_EXPORTER, false)) {
                next()
            } else {
                next({ name: 'project-exporter' })
            }
        }
    },
    {
        path: '/project-exporter-list',
        name: 'project-exporter-list',
        component: ProjectExporterList,
        meta: {
            title: 'Project Exporter List'
        },
        async beforeEnter (to, from, next) {
            if (!await isFeatureFlagEnabled(FeatureFlagsConsts.REPORTING_EXPORTER, false)) {
                next()
            } else {
                next({ name: 'project-exporter-dashboard' })
            }
        }
    },
]
