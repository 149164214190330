<template>
    <div class="io-dashboard-container">
        <IOBanner
            v-if="passwordExpirationStatus && updatedPasswordExpirationStatus"
            :text="resetPasswordText"
            type="warning"
            icon="icon-info"
            class="io-dashboard-password-reminder"
            :callToActionText="resetPasswordButtonText"
            @callToAction="goToPasswordChange"
        />

        <PageHeader :title="$t('Dashboard')">
            <template #actions>
                <IOButton
                    icon="icon-plus"
                    variant="primary"
                    :disabled="!canStartProject"
                    v-tooltip.bottom="{
                        content: cantStartProjectMessage,
                        visible: !canStartProject,
                    }"
                    @click="onProjectWizardOpen">{{ $t('Add New Project') }}</IOButton>
                <IOButton v-if="showProposalButton"  variant="primary" @click="togglePopupNewProposal">{{ $t('Create New Proposal') }}</IOButton>
            </template>
        </PageHeader>

        <div class="io-dashboard-container__section">
            <InfoCard type="risk_items"/>
            <InfoCard type="general_tasks"/>
            <InfoCard type="contracts"/>
            <InfoCard type="projects"/>
        </div>

        <div class="io-dashboard-container__section">
            <projects-list :data="projectsData"/>
            <tasks :data="tasksData"/>
        </div>

        <div class="io-dashboard-container__section">
            <task-statistics :data="taskStatisticsData"/>
            <productivity-ranking :data="productivityRankingData"/>
        </div>

        <InviteModal v-if="showInviteModal" @close="showInviteModal = false"/>

        <PopupNewProposal v-if="showPopupNewProposal" @close="togglePopupNewProposal"/>

        <project-wizard v-if="isProjectWizard" @close="closeProjectWizard"/>
    </div>
</template>

<script src="./dashboard.js"></script>
<style lang="scss" src="./dashboard.scss"></style>
