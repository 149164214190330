import { defineComponent } from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { getSelectedRows } from '../../../../store/component/lazy-list/getters'

export default defineComponent({
    props: {
        column: { type: Object, required: true },
        data: { type: Object, required: true },
        handle: { type: String },
        columnClass: { type: String },
    },
    computed: {
        ...mapState('lazyList', {
            selectedRows: (state) => state.selectedRows,
        }),
        isDisabled (): boolean {
            return this.column?.additional?.disabled(this.data)
        },
    },
    methods: {
        ...mapActions('lazyList', {
            toggleSelectedRows: 'toggleSelectedRows',
            setHasAllRowsSelected: 'setHasAllRowsSelected',
        }),
        addSelectedRow (): void {
            this.toggleSelectedRows(this.handle)

            if (!this.selectedRows.includes(this.handle)) {
                this.setHasAllRowsSelected(false)
            }
        },
        isHidden (): boolean {
            return this.column?.additional?.hidden || false
        },
    },
})
