<template>
    <div class="io-project-information-wrapper">
        <template v-if="rendered">
            <h3>{{ $t('Project Information') }}</h3>
            <IOSingleInfoRow :label="$t('Project Name')" :info="projectName"/>
            <IOSingleInfoRow :label="$t('Generated Project ID')" :info="projectObj.formatted_id"/>
            <IOSingleInfoRow :label="$t('Custom ID')" :info="projectUniqueId"/>
            <IOSingleInfoRow :label="$t('Project Type')" :info="summaryData.project.projectTitle"/>
            <IOSingleInfoRow :label="$t('Primary Tag')">
                <template #info>
                    <template v-if="summaryData.project.primary_tag && summaryData.project.primary_tag.length">
                        <template v-for="(tag, index) in summaryData.project.primary_tag" :key="index">
                            <StatusPill size="big" variant="gray-light" :text="tag"/>
                        </template>
                    </template>
                    <template v-else>-</template>
                </template>
            </IOSingleInfoRow>
            <IOSingleInfoRow :label="$t('Secondary Tag')">
                <template #info>
                    <div class="io-secondary-tags">
                        <template v-if="summaryData.project.tags && summaryData.project.tags.length">
                            <template v-for="(tag, index) in summaryData.project.tags" :key="index">
                                <StatusPill size="big" variant="gray-light" :text="tag"/>
                            </template>
                        </template>
                        <template v-else>-</template>
                    </div>
                </template>
            </IOSingleInfoRow>
            <IOSingleInfoRow :label="$t('Lead Project Executive')">
                <template #info>
                    <template v-if="selectedProjectExecutive && selectedProjectExecutive.id">
                        <div class="io-single-contact-full">
                            <div
                                v-if="selectedProjectExecutive && selectedProjectExecutive.id && selectedProjectExecutive.avatar"
                                :key="selectedProjectExecutive.id"
                                :class="['io-img-holder', 'io-img-holder--large']"
                                :style="getBackgroundAvatar(selectedProjectExecutive.id)"
                            />
                            <Initials
                                v-else
                                :bigAvatar="true"
                                :class="['io-img-holder io-f-column', 'io-img-holder--large']"
                                :fullName="`${ selectedProjectExecutive.firstname } ${ selectedProjectExecutive.lastname }`"
                            />

                            <div class="io-single-contact-full__info">
                                <div class="io-single-contact-full__info-top">
                                    <div class="io-full-name">
                                        {{ selectedProjectExecutive.firstname }} {{ selectedProjectExecutive.lastname }}
                                    </div>
                                </div>
                                <div class="io-single-contact-full__info-bottom">
                                    <div class="io-company-name">
                                        {{ selectedProjectExecutive?.role ?? '-' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <IOButton
                            variant="link"
                            class="redirect__link-btn"
                            type="submit"
                            icon="icon-external-link"
                            @click="goToProjectDirectory()"
                        >
                            {{ $t('Go to Project Team / Directory') }}
                        </IOButton>
                    </template>
                </template>
            </IOSingleInfoRow>
            <IOSingleInfoRow :label="$t('Lead Project Manager')">
                <template #info>
                    <template v-if="selectedProjectManager && selectedProjectManager.id">
                        <div class="io-single-contact-full">
                            <div
                                v-if="selectedProjectManager.id && selectedProjectManager.avatar"
                                :key="selectedProjectManager.id"
                                :class="['io-img-holder', 'io-img-holder--large']"
                                :style="getBackgroundAvatar(selectedProjectManager.id)"
                            />
                            <Initials
                                v-else
                                :bigAvatar="true"
                                :class="['io-img-holder io-f-column', 'io-img-holder--large']"
                                :fullName="`${ selectedProjectManager.firstname } ${ selectedProjectManager.lastname }`"
                            />

                            <div class="io-single-contact-full__info">
                                <div class="io-single-contact-full__info-top">
                                    <div class="io-full-name">
                                        {{ selectedProjectManager.firstname }} {{ selectedProjectManager.lastname }}
                                    </div>
                                </div>
                                <div class="io-single-contact-full__info-bottom">
                                    <div class="io-company-name">
                                        {{ selectedProjectManager?.role ?? '-' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <IOButton
                            variant="link"
                            class="redirect__link-btn"
                            type="submit"
                            icon="icon-external-link"
                            @click="goToProjectDirectory()"
                        >
                            {{ $t('Go to Project Team / Directory') }}
                        </IOButton>
                    </template>
                </template>
            </IOSingleInfoRow>
        </template>
    </div>
</template>
<script lang="ts" src="./ProjectInformation.ts"></script>
<style lang="scss" src="./ProjectInformation.scss" scoped></style>
