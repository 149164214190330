import defaultState from './state'
import { cloneDeep } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export function SET_DROMO_CONFIGURATION (state, value) {
    state.dromoConfiguration = value
}

export function SET_DATA_IMPORTER (state, value) {
    state.dataImporter = value
}

export function SET_IMPORT_CONFIGURATION (state, value) {
    state.importConfiguration = value
}

export function SET_IMPORT_COUNTERS (state, value) {
    state.importCounters = value
}

export function SET_IMPORT_FAILED_RECORDS (state, value) {
    state.importFailedRecords = value
}

export function SET_IMPORT_DUPLICATED_COUNTER (state, value) {
    state.importDuplicatedCounter = value
}

export function SET_IMPORT_COMPLETED_RECORDS (state, value) {
    state.importCompletedRecords = value
}

export function SET_IMPORT_START_DATE (state, value) {
    state.importStartDate = value
}

export function UPDATE_MODEL_FAILED_RECORDS (state, value) {
    state.importFailedRecords[value.modelName] = value.failedRecords
}

export function UPDATE_MODEL_COUNTER (state, value) {
    state.importCounters[value.modelName] = value.counter
}

export function UPDATE_MODEL_DUPLICATED_COUNTER (state, value) {
    state.importDuplicatedCounter[value.modelName] = value.counter
}

export function SET_IMPORT_CLIENTS (state, value) {
    state.clients = value
}

export function UPDATE_IMPORT_STATUS_COMPLETION (state, payload) {
    if (state.dataImporter.id === payload.data.data_importer_id) {
        state.dataImporter.status = payload.data.status
    }
}

export function UPDATE_IMPORTING_PERCENTAGE (state, payload) {
    if (state.dataImporter.id === payload.data.data_importer_id) {
        state.dataImporter.percentage = payload.data.percentage
    }
}
