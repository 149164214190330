import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { cloneDeep, isArray, isUndefined } from 'lodash'
import cachedListMixin from '@/mixins/cachedListMixin'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import CustomDropdown from '@/components/atoms/CustomDropdown/CustomDropdown.vue'
import { required, requiredIf } from '@vuelidate/validators'
import validationMixin from '@/mixins/validationMixin'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import InvoicePaidBySelector from '../../../invoice-paid-by-selector/InvoicePaidBySelector.vue'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType'
import dateTimeHelper from '@/helpers/dateTime'
import { mapState as mapPiniaState } from 'pinia'
import { integrationsStore } from '@/io-modules/integrations/store/integrationsStore'

export default {
    components: {
        CustomDropdown,
        Avatar,
        InvoicePaidBySelector
    },
    data () {
        return {
            selectedVendor: null,
            selectedPeriod: null,
            disabled: {
                selectedPeriod: false,
                selectedVendor: false
            },
            BudgetType,
            selectedInvoiceExpenseType: null,
        }
    },
    mixins: [cachedListMixin, isLoggedMixin, validationMixin, featureFlagsMixin],
    computed: {
        ...mapState('payapps', {
            payapp: (state) => state.payApp,
            categories: (state) => state.categories,
            vendors: (state) => state.modalInvoice.vendors,
            contractData: (state) => state.modalInvoice.contractData,
            invoiceData: (state) => state.modalInvoice.data,
            periods: (state) => state.periods,
            providerList: state => state.providerList
        }),
        ...mapPiniaState(
            integrationsStore, [
                'currentProjectSettings',
                'integrationProjectSettings',
            ],
        ),


        ...mapGetters('payapps', {
            isInvoiceRetention: 'modalAddIsInvoiceRetention',
            isInvoiceMy: 'modalAddIsInvoiceMy',
            isInvoiceVendor: 'modalAddIsInvoiceVendor',
            isInvoiceNonContracted: 'modalAddIsInvoiceNonContracted'
        }),

        ...mapGetters('appStore', {
            isOwnerRep: 'isOwnerRep',
            isOwner: 'isOwner',
        }),

        ...mapState('appStore', ['authData']),

        ...mapState('project', {
            projectObj: state => state.projectObj
        }),

        ...mapState('modal', {
            returnData: state => state.returnData
        }),

        isOwnerOrOwnerRep () {
            return this.isOwner || this.isOwnerRep
        },

        optionsValue () {
            return {
                numeralPositiveOnly: false,
                numericOnly: false
            }
        },

        invoiceDateOptions () {
            return {
                'formatted': dateTimeHelper.getDateFormat()
            }
        },

        optionsVendors () {
            const vendors = []
            for (let x in this.vendors) {
                vendors.push({
                    id: this.vendors[x].contractId,
                    image: this.vendors[x].contractorId,
                    name: this.vendors[x].contractorName,
                    description: this.vendors[x].contractName
                })
            }
            return vendors
        },

        optionsProviders () {
            let providers = cloneDeep(this.providerList)
            //providers.unshift(addNewGroup)
            return providers
        },

        /**
         * Options for periods
         * @return {null}
         */
        optionsPeriods () {
            const periods = []
            if (!isArray(this.periods)) {
                return periods
            }
            this.periods.forEach(period => {
                if (period.submitted_at || !period.is_open) {
                    return false
                }
                period.id = period._id
                period.number = `${ period.sequent_number }`
                period.name = this.$filters.parseDate(period.start_date)
                period.name += ' - '
                period.name += this.$filters.parseDate(period.end_date)

                periods.push(period)
            })
            return periods
        },

        isInvoiceExternalApprovalEnabled () {
            return this.isOwnerRep
        },

        invoicePaidByCompanies () {
            return [
                {
                    mongo_id: this.projectObj._res_mongo_id,
                    name: this.projectObj._res_name,
                    type: this.$tc('Owner'),
                    value: BudgetType.OWNER_BUDGET
                },
                {
                    mongo_id: this.authData.company_mongo_id,
                    name: this.authData.u_company_name,
                    type: this.$tc('rep'),
                    value: BudgetType.MY_BUDGET
                }
            ]
        }
    },

    watch: {
        returnData: async function (returnData) {
            console.log(returnData)
            if (isUndefined(returnData.response)) {
                return false
            }
            const response = returnData.response
            if (response.module && response.module === 'company') {
                const newId = parseInt(response.model.id)
                await this.$store.dispatch('payapps/loadProviders')
                this.providerList.forEach(providerGroup => {
                    providerGroup.groupList.forEach(provider => {
                        if (provider.id === newId) {
                            this.invoiceData['vendor'] = provider
                        }
                    })
                })
            }
        }
    },

    /**
     * Define all validations on forms
     */
    validations: {
        invoiceData: {
            contract_id: {
                required: requiredIf(function () {
                    return this.isInvoiceVendor
                })
            },
            invoiceDate: { required },
            start_date: {
                required: requiredIf(function () {
                    return !this.isInvoiceNonContracted
                })
            },
            end_date: { required },
            vendor: {
                required: requiredIf(function () {
                    return this.isInvoiceNonContracted
                })
            },
            budget_type: {
                required: requiredIf(function () {
                    return this.isInvoiceExternalApprovalEnabled
                        && this.isInvoiceNonContracted
                })
            },
            invoice_id: { required },
            value: {
                required: requiredIf(function () {
                    return !this.isInvoiceMy
                })
            },
            category: {
                required: requiredIf(function () {
                    return this.isInvoiceNonContracted
                })
            }
        }
    },

    methods: {
        async validate () {
            await this.v$.invoiceData.$touch()
            return !this.v$.invoiceData.$error
        },

        /**
         *
         * @param selection
         */
        vendorSelect (selection) {
            this.invoiceData.contract_id = selection.id
        },
        vendorRemove () {
            this.invoiceData.contract_id = null
        },
        periodSelect (selection) {
            this.invoiceData.billing_period = selection.id
        },
        periodRemove () {
            this.invoiceData.billing_period = null
        },
        invoiceExpenseTypeSelect (selection) {
            this.invoiceData.invoice_expense_type = selection.id
        },
        invoiceExpenseTypeRemove () {
            this.invoiceData.invoice_expense_type = null
        },

        selectVendorNonContracted (newData) {
            if (newData && newData.id === 'addNew') {
                this.showAddNewCompanyBox()
            }
        },

        /**
         * Init data
         */
        init () {
            this.invoiceData.invoice_id = this.contractData.next_invoice_id

            if (this.invoiceData.billing_period) {
                this.selectedPeriod = this.periods.find(period => {
                    return period._id === this.invoiceData.billing_period
                })
                if (this.selectedPeriod) {
                    this.disabled.selectedPeriod = true
                }
            }
            if (this.invoiceData.contract_id) {
                this.selectedVendor = this.optionsVendors.find(vendor => {
                    return vendor.id === this.invoiceData.contract_id
                })
                if (this.selectedVendor) {
                    this.disabled.selectedVendor = true
                }
            }

            if (this.isInvoiceNonContracted) {
                this.invoiceData.budget_type = BudgetType.MY_BUDGET
            }

            if (!this.invoiceData.invoiceDate) {
                this.invoiceData.invoiceDate = moment.utc().local().format('YYYY-MM-DD HH:mm:ss')
            }

            if (this.currentProjectSettings.project_expense_type) {
                this.invoiceData.invoice_expense_type = this.currentProjectSettings.project_expense_type.id
                this.selectedInvoiceExpenseType = this.currentProjectSettings.project_expense_type
            }
        },

        showAddNewCompanyBox () {
            this.invoiceData.vendor = null
            this.$store.dispatch('modal/setShow', 'company-edit', { root: true })
        },

        onInvoicePaidBySelected (value) {
            this.invoiceData.budget_type = value
        }
    },

    beforeMount () {
        this.init()
    }
}
