<template>
    <div>
        <Card class="io-allocated-funds-list" :title="$t('Funds')" :show-line="false">
            <template #Body>
                <Table
                    v-if="allocatedFundsFilled && allocatedFundsFilled.length"
                    :headers="tableHeaders"
                    :items="allocatedFundsFilled"
                >
                    <template #tbody="{ sortedItems }">
                        <tr v-for="fund in sortedItems" :key="fund.fund_id">
                            <td>
                                {{ fund.fund_name }}
                            </td>
                            <td>
                                <Avatar
                                    type="company"
                                    :employeeMongoId="fund.provider_id"
                                    :employeeFullName="fund.provider_name"
                                    size="sm"
                                    showFullName
                                />
                            </td>
                            <td class="aright">
                                {{ $filters.formatCurrency(fund.unallocated_amount) }}
                            </td>
                            <td class="acenter">
                                <div class="io-allocated-funds-list__allocation">
                                    <FieldNumeric
                                        :valid="!isAboveLimit(fund.unallocated_amount, fund.allocated_amount)"
                                        :error-message="$t('Cannot be higher than the remaining unallocated funds.')"
                                        v-model="fund.allocated_amount"
                                        @input="collectData(fund.fund_id, 'lines', [])"
                                        @update:modelValue="value => collectData(fund.fund_id, 'allocated_amount', value)"
                                    />
                                    <FinancialTableCalculator
                                        :initial-filled-lines="fund.lines ?? []"
                                        :titles="allocationModalTitles"
                                        @filledLines="allocation => collectData(fund.fund_id, 'lines', allocation)"
                                        @totalAllocated="total => collectData(fund.fund_id, 'allocated_amount', total)"
                                    >
                                        <template #bottomAdditionalInfo>
                                            <div class="io-allocated-funds-list__allocation__budget">
                                                <span>{{ $t('Current Budget') }}</span>
                                                <span>{{ $filters.formatCurrency(budget) }}</span>
                                            </div>
                                        </template>
                                    </FinancialTableCalculator>
                                </div>
                            </td>
                            <td class="aright">
                                {{ fund.allocated_amount ? `${calcPercentOfProjectFunding(fund.fund_id, fund.allocated_amount)}  %` : '-' }}
                            </td>
                            <td class="aright">
                                {{ fund.paid_to_date ? $filters.formatCurrency(fund.paid_to_date) : '-' }}
                            </td>
                            <td class="aright">
                                <AddNoteModal @note="note => collectData(fund.fund_id,'note', note)"/>
                            </td>
                            <td class="aright">
                                <AttachFileModal
                                    :row-id="fund.fund_id"
                                    @files="files => collectData(fund.fund_id,'temporary_attached_documents', files)"/>
                            </td>
                            <td class="aright">
                                <span class="icon-cross io-allocated-funds-list__icon-delete"
                                      @click="showModalOnDeleteWrapper(fund.fund_id)"
                                />
                            </td>
                        </tr>
                        <tr class="io-allocated-funds-list__total">
                            <td>
                                <div class="io-allocated-funds-list__total__funding">{{ $t('Total Funding') }}</div>
                                <div class="io-allocated-funds-list__total__budget">{{ $t('Current Budget') }}</div>
                            </td>
                            <td/>
                            <td/>
                            <td class="aright">
                                <div class="io-allocated-funds-list__total__funding">
                                    {{ $filters.formatCurrency(calcTotalFunding) }}
                                </div>
                                <div class="io-allocated-funds-list__total__budget">
                                    {{ $filters.formatCurrency(projectFundingSummary.budget_amount || 0) }}
                                </div>
                            </td>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                    </template>
                </Table>
            </template>
        </Card>
    </div>
</template>

<script lang="ts" src="./AllocateFundsTable.ts"></script>
<style lang="scss" src="./AllocateFundsTable.scss" scoped></style>
