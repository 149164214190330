import initials from '@/components/new-theme/initials.vue'

export default {
    name: 'SingleOption',
    components: {
        initials
    },
    props: {
        item: {
            required: true,
            type: Object
        },
        showDescription: {
            required: false,
            type: Boolean,
            default: true
        },
        image: {
            required: false,
            type: String,
            default: ''
        }
    },

    computed: {

        /**
         * Get description data
         * @return {*}
         */
        description () {
            return this.item.description ? this.item.description : this.getName(1)
        }
    },

    methods: {
        select () {
            this.$emit('select', this.item)
        },

        /**
         * Get name data
         *
         * @param index
         * @return {string|*}
         */
        getName (index = 0) {
            let text = this.item.name && this.item.name.length ? this.item.name.split('-')[index] : null
            if (text) {
                if (text.trim()) {
                    return text
                } else {
                    return '-'
                }
            } else {
                return '-'
            }
        }
    }
}
