<template>
    <div v-if="showLoading" class="loader-circles">
        <img class="loader-circles_icon" src="/new-theme/img/loading_icon_logo.svg" alt="loader"/>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import { isUndefined } from 'lodash'

    export default {
        name: 'LoaderScreen',
        props: [ 'customShowLoading' ],
        data () {
            return {
                show: true,
            }
        },
        computed: {
            ...mapState('loadingBar', {
                visible: state => state.loading,
            }),
            ...mapState('modal', {
                showModal: state => state.show,
            }),
            showLoading () {
                if (!isUndefined(this.customShowLoading)) {
                    return this.customShowLoading
                } else if (!isUndefined(this.$route.meta.showLoader)) {
                    return this.$route.meta.showLoader
                }
                // HIDE LOADING
                if (isUndefined(this.$route.meta.ignoreHideLoading)
                    && this.$route.fullPath.includes('project')
                    && !this.$route.name?.includes('project-list')
                    && !isUndefined(this.$route.meta.ignoreProrjectLayout)) {
                    return false
                }

                // SHOW LOADING
                return this.visible && this.showModal === false
            },
        },
    }
</script>
<style lang="scss" scoped>
    .loader-circles {
        display: block;
        position: absolute;
        top: 40vh;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10002;
        height: 32px;
        width: 32px;
        -webkit-animation: spin_loader 1.2s ease-in-out infinite;
        animation: spin_loader 1.2s ease-in-out infinite;

        &_icon {
            height: 100%;
            width: 100%;
        }
    }
</style>
