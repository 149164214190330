<template>
    <div>
        <RightModal
            class="io-modal-capital-funds"
            @close="howToClose()"
            @clickOutside="howToClose()"
        >
            <template #header>
                <div class="io-modal-capital-funds__header">
                    <div class="io-modal-capital-funds__header__top">
                        <div class="io-modal-capital-funds__header__title">{{ $t('Add Program Fund(s)') }}</div>
                        <div class="io-modal-capital-funds__header__actions">
                            <template v-if="step === 0">
                                <SearchInput
                                    :placeholder="$t('Search...')"
                                    :loader="false"
                                    :width="288"
                                    @searchChanged="onSearchChange"
                                />
                                <IOButton
                                    :disabled="numberSelected === 0"
                                    @click="step = 1"
                                >
                                    {{ `${$t('Next Step')} (${numberSelected} ${$t('Selected')})` }}
                                </IOButton>
                            </template>
                            <template v-else>
                                <IOButton
                                    variant="secondary"
                                    @click="step = 0"
                                >
                                    {{ $t('Previous Step') }}
                                </IOButton>
                                <IOButton
                                    :disabled="!canAddFund"
                                    @click="addCapitalFunds"
                                >
                                    {{ $t('Add Program Fund(s)') }}
                                </IOButton>
                            </template>
                            <span class="icon-cross" @click="howToClose()"/>
                        </div>
                    </div>
                </div>
            </template>
            <template #default>
                <div class="io-modal-capital-funds__bottom">
                    <Stepper
                        :is-floating="false"
                        :shadow="false"
                        v-model="step"
                    >
                        <StepperStep
                            :step="0"
                            :valid="step === 1"
                            :disabled="step === 1"
                            :title="$t('Select Program Funds')"
                        >
                            <CapitalProviderFundsList
                                :loading="loading"
                                :provider-funds-list="isSearch ? providerFundsSearch : listProvidersFunds"
                                :is-search="isSearch"
                                @numberSelected="updateNumberSelected"
                                @selected-funds="onEndFundSelection"
                            />
                        </StepperStep>
                        <StepperStep
                            :step="1"
                            :valid="false"
                            :disabled="step === 0"
                            :title="$t('Allocate Funds')"
                        >
                            <AllocateFundsTable
                                v-if="selectedFunds"
                                :allocated-funds="selectedFunds"
                                @allocation="onEditAllocationToFund"
                                @can-add-fund="value => changeCanAddFund(value)"/>
                        </StepperStep>
                    </Stepper>
                </div>
            </template>
        </RightModal>
    </div>
</template>

<script lang="ts" src="./SelectCapitalFundModal.ts"></script>
<style lang="scss" src="./SelectCapitalFundModal.scss" scoped></style>
