import { defineComponent, PropType } from 'vue'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus'
import ApprovalConfirmationModalService from '@/io-modules/approval-workflows/services/SimpleConfirmationModalsService'

export default defineComponent({
    name: 'SingleApprovalStatus',
    props: {
        status: { type: String as PropType<ApprovalStatus>, required: true },
        date: { type: String, required: true },
        disableViewNote: { type: Boolean, required: false, default: false },
        approverId: {
            type: String,
            default: false,
        },
    },
    data () {
        return {
            ApprovalStatus,
        }
    },
    methods: {
        handleViewNote (): void {
            this.$emit('viewNoteClick')
        },

        handleSendReminder (): void {
            this.showPopupAlert(ApprovalConfirmationModalService.sendReminder(this.sendReminder))
        },

        async sendReminder (): Promise<any> {
            this.setLoadingBar(true)
            try {
                await this.$api.post(`/approval-workflows/instanced-approvers/${ this.approverId }/reminder`)
                this.showNotification('success', 'You successfully sent a reminder to this approver!')
            } catch (e) {
                this.showNotification('error', e.response.data.message)
            } finally {
                this.setLoadingBar(false)
            }
        },
    },
})
