import { defineComponent } from 'vue'
import IconTooltip from '../icon-tooltip/IconTooltip.vue'
import VueI18n from 'vue-i18n'
import { get, isFunction } from 'lodash'
import { ColumnInterface } from '@/interfaces/components/lazy-list/ColumnInterface'
import { mapActions } from 'vuex'

export default defineComponent({
    components: {
        IconTooltip
    },
    props: {
        status: { type: String, required: true, default: null },
        percentage: { type: Number, required: true, default: 0 },
        statusId: { type: Number, default: null },
        pillsColorFunction: { type: Function, default: null },
        isTranslation: { type: Boolean, default: false },
        tableRow: { type: Object, default: () => ({}) },
        column: { type: Object as () => ColumnInterface, required: true, default: null },
        customStatusText: { type: Boolean, default: null },
        customStatusTextFunction: { type: Function, default: null },
        pillsTextFunction: { type: Function, default: false },
        defaultClasses: { type: String, default: null },
        showIconToolTip: { type: Function, default: null },
    },
    computed: {
        loadingPercentage (): any {
            return this.tableRow.percentage ?? 0
        },
        statusText (): string | VueI18n.LocaleMessages {
            const additionalHTMLFunction = get(this.column, ['additional', 'additionalHTMLFunction'], null)
            if (typeof additionalHTMLFunction === 'function') {
                return additionalHTMLFunction(this.tableRow)
            }

            if (isFunction(this.pillsTextFunction) && this.statusId) {
                return this.pillsTextFunction(this.statusId)
            }

            if (this.isTranslation) {
                return this.$t(this.status)
            }

            if (this.customStatusText) {
                return this.customStatusTextFunction(this.statusId)
            }

            return this.status
        },
        classes (): string | null {
            if (this.defaultClasses) {
                return this.defaultClasses
            }

            if (this.pillsColorFunction && this.statusId) {
                return this.pillsColorFunction(this.statusId)
            }

            return null
        },
        showToolTip (): boolean {
            return this.showIconToolTip ? this.showIconToolTip(this.status) : false
        }
    },
    watch: {
        'tableRow.percentage': function (newVal, oldVal) {
            console.log('data.percentage changed from', oldVal, 'to', newVal)
            this.tableRow.percentage = newVal
        }
    },
    created () {
        this.setDefaultPercentage()
    },
    methods: {
        ...mapActions('lazyList', {
            updateImportCompletionStatus: 'updateImportCompletionStatus'
        }),
        setDefaultPercentage (): void {
            if (this.tableRow && !this.tableRow.percentage) {
                this.tableRow['percentage'] = 0
            }
        }
    }
})
