import { ApiClient } from '@/api/api'
const client = ApiClient

export default {
    getSettings (projectCompanyId) {
        return client.get(`project-budget/${ projectCompanyId }/settings`)
    },
    saveSettings (projectCompanyId, data) {
        return client.put(`project-budget/${ projectCompanyId }/settings`, data)
    }
}
