import { cloneDeep } from 'lodash'

export function SET_VIEW (state, val) {
    state['currentView'] = val
}
export function SET_SHOW_FILTERS (state, value) {
    state['showFilters'] = value
}
export function RESET_FILTERS_SELECTED (state) {
    const filtersSelected = cloneDeep(state.filtersSelected)
    for (const key in filtersSelected) {
        filtersSelected[key] = null
    }
    state['filtersSelected'] = filtersSelected
}
export function SET_PARENT_FORM_SHOWN (state, data) {
    state['parentFormShown'] = data
}
export function SET_DRAWING_SETS_SHOWN (state, data) {
    state['drawingSetsShown'] = data
}
export function SET_DRAWING_SHEETS_SHOWN (state, data) {
    state['drawingSheetsShown'] = data
}
export function SET_ACTIVE_DRAWING_SET (state, data) {
    state['activeDrawingSet'] = data
}
export function SET_ACTIVE_DRAWING_SHEET (state, data) {
    state['activeDrawingSheet'] = data
}
export function SET_DRAWING_SHEET_SHOWN (state, data) {
    state['drawingSheetShown'] = data
}
export function SET_SHARE_MARKUP_POPUP_SHOWN (state, data) {
    state['shareMarkupPopupShown'] = data
}
export function SET_SHARE_MARKUP_DATA (state, data) {
    state['shareMarkupData'] = data
}
export function SET_DRAWING_VERSION_SWITCHED (state, data) {
    state['drawingVersionSwitched'] = data
}
