import type ProjectObj from '@/interfaces/store/project/ProjectObj'

export default {
    projectId: null,
    id: null,

    projectObj: {} as ProjectObj,
    model: {},
    costCodes: [],
    costTypes: [],

    projectHasRequiredFields: true,
    sharingCustomFieldsStatus: false
}
