<template>
    <modal v-if="showModal === 'error'">
        <template #body>
            <div class="io-content io-modal-success">
                <img src="/new-theme/img/error-icon-big.svg">
                <div class="io-msg io-2">Oh no! Something went wrong</div>
            </div>
        </template>
    </modal>
</template>

<script>
    import modalMixinn from '@/mixins/modalMixin'

    export default {
        name: 'error',
        data () {
            return {
            }
        },
        mixins: [modalMixinn],
        components: {
        },
        watch: {
            'showModal' () {
                if (this.showModal === 'error') {
                    let self = this
                    setTimeout(() => {
                        self.closeModal()
                    }, 2000)
                }
            }
        },
        computed: {
        },
        methods: {
        }
    }
</script>