import { defineComponent } from 'vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { mapActions, mapState } from 'pinia'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName'
import InvoiceStatus from '@/io-modules/invoices/enums/InvoiceStatus.ts'
import InvoiceRevisionNoteModal from '../../modals/invoice-revision-note-modal/InvoiceRevisionNoteModal.vue'
import InvoiceRevision from '@/io-modules/invoices/interfaces/InvoiceRevision.ts'
import AppPackageRouteName from '@/io-modules/invoices/enums/AppPackageRouteName'
import NotesModal from '../../modals/notes-modal/NotesModal.vue'
import InvoiceAmountWithTaxModal from '../../modals/invoice-amount-with-tax-modal/InvoiceAmountWithTaxModal.vue'
import { mapGetters as mapGettersVuex } from 'vuex'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'
import featureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import reviseResubmitMixin from '@/io-modules/invoices/mixins/sov-wbs/ReviseResubmitMixin.ts'
import LineProgress from '../../../components/invoice-view/line-progress/LineProgress.vue'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV.ts'

export default defineComponent({
    components: {
        AlertBox,
        InvoiceRevisionNoteModal,
        NotesModal,
        InvoiceAmountWithTaxModal,
        LineProgress
    },
    mixins: [featureFlagsMixin, reviseResubmitMixin],
    emits: ['revertToDraft', 'sendRevision'],
    data () {
        return {
            showRevisionNoteModal: false,
            showNotesModal: false,
            showInvAmountWithTaxModal: false,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, [
            'invoice',
            'creating',
            'clientRequestedRevision',
            'invoiceInternallyRejected',
            'invoiceRejectedByClient',
            'isSender',
            'revisionProvided',
            'canRequestRevision',
            'canAddressRevision',
            'accountingErrors',
            'isEditingMode',
            'invAmountWithTax',
            'invIsNotApproved',
            'adjustedLinesCount'
        ]),

        ...mapState(invoiceRevisionStore, ['needAttentionItemsTotal', 'clientAdjustedItemsTotal', 'needAttentionItemsCompleted', 'revisionRequest']),

        ...mapActions(invoiceSoVStore, ['areActionsColumnsVisible']),

        ...mapGettersVuex('appStore', ['isOwner']),

        internallyRejectedInvoiceText (): string {
            return this.$t('This Invoice has been internally rejected. Please revert to draft, make the necessary modifications, and resubmit for approval.')
        },

        isSoVTab (): boolean {
            return InvoiceRouteName.InvoiceSoV === this.$route.name
        },

        isRevisionRequested (): boolean {
            return this.invoice?.status === InvoiceStatus.RevisionRequested
        },

        isRequestedRevision (): boolean {
            return this.invoice?.status === InvoiceStatus.RequestedRevision
        },

        clientName (): string {
            return this.invoice?.client_requested_revision?.created_by || ''
        },

        getRevisionInfo (): InvoiceRevision {
            return this.invoice?.client_requested_revision || null
        },

        requestRevisionText (): string {
            if (this.invoiceInternallyRejected && this.canRequestRevision) {
                return this.$t('This invoice has been internally rejected. To request revision from the vendor, please click the')
            } else if (this.invoiceRejectedByClient && this.canRequestRevision) {
                return this.$t('Invoice was rejected by client.  To request revision from the vendor, please click the')
            } else {
                return null
            }
        },

        showRevisionRequestedBanner (): boolean {
            return this.$route.name === InvoiceRouteName.InvoiceApprovals && this.isRevisionRequested && !this.canAddressRevision
        },

        invoiceApprovedThroughAppPackage (): boolean {
            return this.invoice.client_approval?.approval_is_from_application_package && ('approved' === this.invoice.client_approval?.status || InvoiceStatus.ApprovedByClient === this.invoice.status)
        },

        invoiceRejectedThroughAppPackage (): boolean {
            return this.invoice.client_approval?.approval_is_from_application_package && ('rejected' === this.invoice.client_approval?.status || InvoiceStatus.RejectedByClient === this.invoice.status)
        },

        invoiceVoided (): boolean {
            return InvoiceStatus.Voided === this.invoice.status
        },

        invoiceRejectedByAccounting (): boolean {
            return InvoiceStatus.RejectedByAccounting === this.invoice.status
        },

        voidInfo (): object {
            return this.invoice.void_info
        },

        showAccountingErrors (): boolean {
            return !!(this.accountingErrors?.shouldBeSubmitted && this.accountingErrors?.errors?.length && !this.isEditingMode)
        },

        multipleAccountingErrors (): boolean {
            return !!(this.accountingErrors?.errors?.length > 1)
        },

        showFillInvAmountWithTaxBanner (): boolean {
            return this.isOwner && this.invoiceWithTaxFieldFeature && !this.invAmountWithTax && !this.creating
        },

        invoiceWithTaxFieldFeature (): boolean {
            return this.isFeatureEnabled(featureFlagsConsts.INVOICE_TAX_VALUE_FIELD, false)
        },

        invoiceRevisionFeature (): boolean {
            return this.isFeatureEnabled(featureFlagsConsts.INVOICES_REVISION, false) &&
                   this.canClientReviseInvoice
        },
    },
    methods: {
        ...mapActions(invoiceViewStore, ['toggleRequestRevisionModal', 'setInvAmountWithTax']),
        ...mapActions(invoiceRevisionStore, ['clearAllAdjustments']),

        goToSoVTab (): void {
            this.$router.push({ name: InvoiceRouteName.InvoiceSoV })
        },

        toggleRevisionNoteModal (): void {
            this.showRevisionNoteModal = !this.showRevisionNoteModal
        },

        goToAppPackage (): void {
            const routeData = this.$router.resolve({ name: AppPackageRouteName.AppPackageDetails, params: { id: this.invoice.application_package_id } })
            window.open(routeData.href, '_blank')
        },

        toggleNotesModal (): void {
            this.showNotesModal = !this.showNotesModal
        },

        toggleInvAmountWithTaxModal (): void {
            this.showInvAmountWithTaxModal = !this.showInvAmountWithTaxModal
        },

        updateInvAmountWithTax (value: number): void {
            this.setInvAmountWithTax(value)
        },
        clearAllRevisionUpdates (): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to clear all your updates to SoV / WBS on this Invoice?'),
                caption: this.$t('This action cannot be undone.'),
                icon: 'icon-alert-triangle',
                iconColor: 'var(--warning-darker)',
                width: 535,
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Clear All Your Updates'),
                        class: 'io-btn-primary',
                        action: async () => await this.clearAllAdjustments()
                    }
                ]
            })
        }
    },
})
