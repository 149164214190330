import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'
import { inviteModalStore } from '@/components/invite-modal-v3/store/inviteModalStore'
import SingleContact from '@/components/invite-modal-v3/components/single-contact/SingleContact.vue'
import { InviteModalTabs } from '@/components/invite-modal-v3/enums/InviteModalTabs'
import { SplashScreen } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default defineComponent({
    name: 'InitialView',

    components: {
        SingleContact,
        DynamicScroller,
        DynamicScrollerItem,
    },

    props: {
        currentTabKey: { type: String, required: true, default: InviteModalTabs.ALL },
        componentKey: { type: String, required: true },
        disableInviting: { type: Boolean, required: false, default: false },
        disableBookmarking: { type: Boolean, required: false, default: false },
        disableMultiple: { type: Boolean, required: false, default: false },
        disableRemoving: { type: Boolean, required: false, default: true },
        disableReplacing: { type: Boolean, required: false, default: true },
        onlySearchWithValidEmail: { type: Boolean, required: false, default: false },
        disableActionsOnCurrentWorkspaceContact: { type: Boolean, required: false, default: false },
        isModalEmployeesOnly: { type: Boolean, required: false, default: false },
        splashScreen: { type: Object as PropType<SplashScreen>, required: false, default: null },
        emptySearchSplashScreen: { type: Object as PropType<SplashScreen>, required: false, default: null },
    },

    computed: {
        ...mapState(inviteModalStore, {
            contactsList (store) {
                return store.contactsList(this.componentKey)
            },
            search (store) {
                return store.search(this.componentKey)
            },
            isSearchValidEmail (store) {
                return store.isSearchValidEmail(this.componentKey)
            },
            isPaginating (store) {
                return store.isPaginating(this.componentKey)
            },
        }),

        placeholder (): SplashScreen {
            let placeholder = {
                icon: 'icon-user-plus',
                heading: this.$t('No contacts, yet!'),
                subtext: this.$t('Enter the email of the person you want to add in the box above.')
            }

            if (null !== this.splashScreen) {
                return placeholder = { ...placeholder, ...this.splashScreen }
            }

            switch (this.currentTabKey) {
                case InviteModalTabs.DIRECTORY:
                    placeholder = {
                        icon: 'icon-users',
                        heading: this.$t('No directory, yet!'),
                        subtext: this.$t(`Looks like you haven't setup your ${ this.projectDirectoryLink } yet.`)
                    }
                    break
                case InviteModalTabs.EMPLOYEES:
                    placeholder = {
                        icon: 'icon-users',
                        heading: this.$t('No employees, yet!'),
                        subtext: this.$t(`You haven't added any employees yet. You can do so in the ${ this.employeesLink() } module.`)
                    }
                    break
                case InviteModalTabs.CONTACTS:
                    placeholder.icon = 'icon-email'
                    break
                default:
                    return placeholder
            }

            return placeholder
        },

        emptySearchPlaceholder (): SplashScreen {
            if (this.onlySearchWithValidEmail) {
                return this.placeholder
            }

            let placeholder = {
                icon: 'icon-search',
                heading: this.$t('User not found.'),
                subtext: this.$t('Try a different name or check spelling. To add a new user, enter their email in the box and follow the steps to provide their details.')
            }

            if (null !== this.emptySearchSplashScreen) {
                return placeholder = { ...placeholder, ...this.emptySearchSplashScreen }
            }

            if (this.isModalEmployeesOnly) {
                placeholder.heading = this.$t('Not seeing the User you\'re looking for?')
                placeholder.subtext = this.$t(`${ this.employeesLink('Add a new Employee here') }`)
                return placeholder
            }

            switch (this.currentTabKey) {
                case InviteModalTabs.DIRECTORY:
                    placeholder.heading = this.$t('User not found on directory list.')
                    break
                case InviteModalTabs.EMPLOYEES:
                    placeholder.heading = this.$t('User not found on employees list.')
                    break
                case InviteModalTabs.CONTACTS:
                    placeholder.heading = this.$t('User not found on contacts list.')
                    break
                default:
                    return placeholder
            }

            return placeholder
        },

        projectDirectoryLink (): string {
            return '<span>Project Directory</span>'
        },
    },

    methods: {
        employeesLink (linkText: string = 'Employees'): string {
            return `<a href="/employee/list" class="io-no-contacts-placeholder__content-subtext-link" target="_blank">${ linkText }</a>`
        }
    }
})
