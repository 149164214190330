export enum StatusLienWaiver {
    NotReceived = 'not_received',
    PreDraft = 'pre_draft',
    Draft = 'draft',
    Sent = 'sent',
    Attached = 'attached',
    Review = 'Pending Review',
    Approved = 'approved',
    UnderReview = 'under_review',
    RevisionRequest = 'Revision Requested',
    RequestRevision = 'Requested Revision ',
    RevisionRequested = 'revision_requested',
    PendingReview = 'pending_review',
    Requested = 'requested',
    RequestSent = 'request_sent',
    ApprovedUnconditional = 'approved_unconditional',
    ApprovedConditional = 'approved_conditional',
}
