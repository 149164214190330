import { defineComponent, PropType } from 'vue'

export enum ButtonVariant {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    LINK = 'link',
    ROW = 'row',
    ICON = 'icon',
    'PLAIN-ICON' = 'plain-icon',
}

export enum ButtonSize {
    NORMAL = 'normal',
    SMALL = 'small'
}

export enum ButtonIconPosition {
    LEFT = 'left',
    RIGHT = 'right',
    BOTH = 'both',
}

export enum ButtonFontWeight {
    THIN = 'thin',
    MEDIUM = 'medium',
    BOLD = 'bold'
}

export default defineComponent({
    name: 'IOButton',

    props: {
        variant: { default: ButtonVariant.PRIMARY },
        size: { default: ButtonSize.NORMAL },
        icon: { type: String, default: '' },
        leftIcon: { type: String, default: '' },
        rightIcon: { type: String, default: '' },
        iconPosition: { type: String as () => ButtonIconPosition, default: ButtonIconPosition.LEFT },
        type: { type: String as () => 'button' | 'submit' | 'reset', default: 'button' },
        ariaLabel: { type: String, default: undefined },
        fontWeight: { type: String as PropType<ButtonFontWeight>, default: ButtonFontWeight.MEDIUM }
    },

    setup () {
        return { ButtonIconPosition }
    },

    computed: {
        computeAriaLabel (): string | undefined {
            if (this.ariaLabel) {
                return this.ariaLabel
            }

            return this.variant === ButtonVariant.ICON && this.icon ? this.icon : undefined
        },
    },
})
