<template>
    <div class="io-dashboard-card io-projects-list">
        <div class="io-projects-list__header">
            <div class="io-dashboard-card__title">{{ $t('Recent Projects') }}</div>
            <router-link v-slot="{ navigate }" :to="{name: 'project-list'}" custom>
                <IOButton variant="link" @click="navigate" @keypress.enter="navigate">{{ $t('See All') }}</IOButton>
            </router-link>
        </div>
        <div class="io-projects-list__content">
            <template v-if="data && data.list && data.list.length > 0">
                <template v-for="(projectItem, projectIndex) in data.list">
                    <template v-if="projectItem.name && projectItem.id">
                        <router-link v-slot="{ navigate }" :key="projectIndex" :to="{name: 'project-dashboard', params: {pid: projectItem.id}}" custom><div class="io-single-project" @click="navigate" @keypress.enter="navigate">
                            <div class="io-single-project__logo-container">
                                <DonutChart v-if="projectItem.progress"
                                            :value="projectItem.progress"
                                            :size="42"
                                            :thickness="2"
                                            :animated="false"
                                            color="var(--accent)"
                                            trackColor="var(--box-secondary)"
                                            backgroundColor="var(--box-secondary)"
                                />
                                <div v-if="projectItem.resPhoto" class="io-single-project__logo io-company-logo-placeholder" :style="getBackgroundCompanyLogo(projectItem.resPhoto)"></div>
                                <div v-else class="io-single-project__logo io-company-logo-placeholder"></div>
                            </div>
                            <div>
                                <div class="io-single-project__title">{{ projectItem.name }}</div>
                                <div class="io-single-project__caption">{{ projectItem.client }}</div>
                            </div>
                        </div></router-link>
                    </template>
                </template>
            </template>
            <div v-else class="io-single-project__logo-container--empty">
                {{ loading ? $t('Loading...') : $t('No items') }}
            </div>
        </div>
    </div>
</template>

<script>
    import DonutChart from '@/components/atoms/DonutChart/DonutChart.vue'
    import { mapState } from 'vuex'

    export default {
        name: 'projects-list',
        components: {
            DonutChart,
        },
        props: {
            data: {
                type: Array,
                require: true,
                default: () => []
            }
        },
        computed: {
            ...mapState('loadingBar', {
                loading: state => state.loading
            })
        },
    }
</script>

<style lang="scss" src="./projects-list.scss" scoped></style>
