import { defineComponent } from 'vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn.vue'
import CustomMultiSelect from '@/components/atoms/CustomMultiselect/CustomMultiselect.vue'
import type SharedWorkspaceData from '@/elements/CompanyContactDropdown/interfaces/SharedWorkspaceData'
import wizardDetailClient from '@/io-modules/bid-requests/api-clients/wizardDetailClient'
import type { BidRequestContact } from '@/io-modules/bid-requests/interfaces'


export default defineComponent({
    name: 'CompanyContactDropdown',

    components: {
        FormInputColumn,
        CustomMultiSelect,
    },

    props: {
        company: { type: Object as SharedWorkspaceData, required: true },
        contact: { type: Object as BidRequestContact, required: true },
        clientAppTypes: { type: Array<string>, required: false, default: () => [] },
        companyLabel: { type: String, required: false, default: 'Client' },
        contactLabel: { type: String, required: false, default: 'Contact Person' },
    },

    data () {
        return {
            companies: [] as SharedWorkspaceData[],
            contacts: [] as BidRequestContact[],
            companyMultiSelectSettings: {
                textPlaceholder: 'Type to filter...',
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: true,
            },
            contactMultiSelectSettings:  {
                textPlaceholder: '',
                enableAddNew: false,
                closeAfterSelect: true,
                enableSearch: false,
            },
        }
    },

    async mounted () {
        await this.getAllOffSystemCompaniesFilteredByName(this.company?.name ?? '')

        if (this.company?.id) {
            await this.getAllContactsByCompany(this.company.id)
        }
    },

    methods: {
        async onSelectedCompany (selectedCompany: SharedWorkspaceData): Promise<void> {
            this.$emit('onSelectedCompany', selectedCompany)

            this.getAllContactsByCompany(selectedCompany.id)
        },

        onSelectedContact (selectedContact: BidRequestContact): void {
            this.$emit('onSelectedContact', selectedContact);
        },

        async getAllOffSystemCompaniesFilteredByName (search: string): Promise<void> {
            try {
                const { data } = await wizardDetailClient.getAllOffSystemCompaniesFilterByNameAndAppTypes(
                    search,
                    this.clientAppTypes
                )
                this.companies = data
            } catch (error) {
                this.companies = []
                this.showNotification('error', this.$t('Error occurred when getting the clients.'), error)
            }
        },

        async getAllContactsByCompany (companyId: number): void {
            try {
                const { data } = await wizardDetailClient.getAllContactsByCompany(companyId)
                this.contacts = data.map(contact => ({
                    id: contact.id || contact.contact_id,
                    email: contact.email || contact.contact_email,
                    shared_persons_data_id: contact.id || contact.contact_person_id,
                    name: contact.name || contact.full_name
                }))
            } catch (error: Error) {
                this.contacts = []
                this.showNotification('error', this.$t('An error occurred while retrieving the contacts.'), error)
            }
        },
    }

})
