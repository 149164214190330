<template>
    <td>
        <template v-if="data && data.name">
            <div class="io-stamp-cell">
                <div class="io-stamp-cell__stamp" :style="stampStyles">{{ data.code }}</div>
                <div class="io-stamp-cell__label">{{ parseStampName(data.name, 24) }}</div>
            </div>
        </template>

        <template v-else>-</template>
    </td>
</template>

<script lang="ts" src="./Stamp.ts"></script>
<style lang="scss" src="./Stamp.scss" scoped></style>
