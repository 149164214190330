<template>
    <td class="io-cell-text-icon">
        <div v-if="data">
            <i
                :class="getIconClass"
                @click.stop="emitAction"
            />

            <i
                v-if="favouriteDefined"
                :class="favouriteIconClass"
                @click.stop="emitAction"
            />

            <span>{{ getText }}</span>
        </div>
    </td>
</template>

<script lang="ts" src="./TextIcon.ts"></script>

<style lang="scss" src="./TextIcon.scss" scoped/>
