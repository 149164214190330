import { defineComponent, PropType } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import { FundExportSortTypes, HeaderType, fundExportSortTypesReadable } from '@/io-modules/project-funding/enums/ProjectFundingEnums'
import Table from '@/components/table/Table.vue'
import setTableHeaders from '@/io-modules/project-funding/helpers/setTableHeaders'
import ProjectFundingFund from '@/io-modules/project-funding/interfaces/ProjectFundingFund'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import projectFundingClient from '@/io-modules/project-funding/api-clients/projectFundingClient'
import { mapState } from 'vuex'

export default defineComponent({
    name: 'FundsExportTableModal',
    components: { AlertBox, Avatar, StatusPill, Table, IOModal },
    props: {
        sortType: {
            type: String as PropType<FundExportSortTypes>,
            default: FundExportSortTypes.PACKAGE,
        },
        projectFunds: {
            required: true,
            type: Array as PropType<ProjectFundingFund[]>,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            HeaderType,
            fundExportSortTypesReadable
        }
    },
    computed: {
        ...mapState('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id
        }),
        headerCheckboxClass (): string {
            if (this.projectFunds.every(fund => fund.isSelected)) {
                return 'io-check'
            } else if (this.projectFunds.some(fund => fund.isSelected)) {
                return 'io-minus'
            }
            else {
                return ''
            }
        },
        areAllItemsSelected (): boolean {
            return this.projectFunds.every(fund => fund.isSelected)
        }
    },
    mounted () {
        this.addCheckboxValue(false)
    },
    methods: {
        setTableHeaders,
        addCheckboxValue (value: boolean): void {
            if (this.projectFunds && this.projectFunds.length) {
                this.projectFunds.forEach((fund: ProjectFundingFund) => {
                    fund.isSelected = value
                })
            }
        },
        async exportPdf (): Promise<void> {
            const selFundsIds = this.projectFunds
                .filter(fund => fund.isSelected)
                .map(fund => fund.fund_id)

            const linkToPdf = projectFundingClient.exportProjectFunds(this.projectLocalID, selFundsIds, this.sortType)

            try {
                window.open(
                    linkToPdf,
                    '_blank',
                    'noopener',
                )
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        }
    },
})
