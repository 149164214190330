<template>
    <div class="io-funding-sources">
        <PageHeader :title="$t('Funding Sources')">
            <template #actions>
                <div class="io-actions-icon">
                    <SearchInput
                        :placeholder="$t('Search...')"
                        :loader="false"
                        @searchChanged="searchLocal = $event"
                    />

                    <ActionDropdown :show-menu="false" :icon-tooltip="$t('Export to PDF')" @click="exportPdf()" />
                </div>

                <IOButton
                    @click="toggleShowProviderModal"
                >
                    {{ $t('Add New Provider') }}
                </IOButton>

            </template>
        </PageHeader>
        <LazyList
            key="funding-sources-global-lazy-list"
            :endpoint="endpoint"
            :itemRoute="itemRoute"
            :columns="columns"
            :is-second-lazy-list-on-same-view="true"
            method="get"
        />
        <AddProviderModal v-if="showProviderModal" @close="toggleShowProviderModal"/>
    </div>
</template>

<script src="./ProvidersList.ts"></script>

<style src="./ProvidersList.scss" lang="scss" scoped></style>
