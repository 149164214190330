import i18n from '@/base/i18n.js'
import cachedListMixin from '@/mixins/cachedListMixin'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import { mapGetters, mapState } from 'vuex'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import { mapActions } from 'pinia'
import { integrationsStore } from '@/io-modules/integrations/store/integrationsStore'

const excludeBannerLocations = [
    'project-site-locations',
    'project/:pid/punch',
    'project/:pid/rfis',
    'project/:pid/specifications',
    'project/:pid/submittals',
    'project/:pid/drawings',
    'project/:pid/daily-reports',
    'project/:pid/tasks',
    'project/:pid/tasks-kanban',
]
const projectObjMixin = {
    mixins: [cachedListMixin, isLoggedMixin, featureFlagsMixin],
    data: () => ({
        loaded: false,
        FeatureFlagsConsts,
    }),
    computed: {
        ...mapGetters(
            'appStore', [
                'appUsesBudget'
            ]
        ),
        ...mapState('project', {
            projectObj: (state) => state.projectObj
        }),
        ...mapState('appStore', {
            authData: state => state.authData
        }),
        ...mapState('budgetVersioning', {
            feasibilityStudy: state => state.feasibilityStudy
        }),
        insuranceRequirementsFeatureFlagIsEnabled () {
            return this.isFeatureEnabled(FeatureFlagsConsts.INSURANCE_REQUIREMENTS, false)
        }
    },

    methods: {
        ...mapActions(integrationsStore, ['setProjectSettings']),
        /**
         *  SET PROJECT OBJECTS FROM CACHED LIST
         * @returns {Promise<void>}
         */
        async loadProject () {
            this.loaded = false
            const pObj = await this.getOne('projects', this.$route.params.pid)
            if (pObj !== -1) {
                await this.$store.dispatch('project/setProject', pObj)
            }
            if (this.$route.params?.pid) {
                this.setProjectSettings(this.$route.params.pid)
            }
            if (this.$route.matched.some(el => excludeBannerLocations.some(loc => el.path.includes(loc)))) {
                this.$store.dispatch('notification/removeBanner', 'project-budget-warning')
            }

            this.loaded = true
        },

        removeBanner () {
            this.$store.dispatch('notification/removeBanner', 'project-budget-warning')
        }
    },

    watch: {
        /**
         * Observe project id
         */
        '$route' (to, from) {
            const idFrom = (from.params && from.params.pid) ? from.params.pid : null
            const idTo = (to.params && to.params.pid) ? to.params.pid : null
            if (idFrom && idTo && idFrom !== idTo) {
                this.loadProject()
            }
        }
    }
}

export default projectObjMixin
