import appTypes from '@/base/appTypes.ts'
import subscriptionTypes from '@/base/subscriptionTypes.ts'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType'
import { get } from 'lodash'

export function getAuthStatus (state) {
    return state.auth
}

export function getAuthData (state) {
    return state.authData
}

export function appIsArch (state) {
    return state.authData.app_type === appTypes.TYPE_AC
}

export function appIsOwner (state) {
    return state.authData.app_type === appTypes.TYPE_OWNER
}

export function getAuthAccess (state) {
    return state.authAccess
}

export function getPostData (state) {
    return state.postData
}

export function getBackUrl (state) {
    return state.backURL
}
export function refreshData (state) {
    return state.refreshData
}

export function getEnv (state) {
    return state.env
}

export function getFullstoryOrgId (state) {
    return state.fullstoryOrgId
}

export function isProduction (state) {
    return 'production' === state.env
}

export function isEphemeral (state) {
    return state.is_ephemeral
}

export function getProjectCurrency (state) {
    return state.projectCurrency
}

export function isPro (state) {
    return get(state, ['authData', 'subscription_type'], null) === subscriptionTypes.PRO
}
export function isEnterprise (state) {
    return get(state, ['authData', 'subscription_type'], null) === subscriptionTypes.PRO
}

export function isOwnerRep (state) {
    return get(state, ['authData', 'app_type'], null) === appTypes.TYPE_DEV
}

export function defaultBudgetTypeByWorkspace (state, getters) {
    return true === getters.isOwnerRep
        ? BudgetType.OWNER_BUDGET
        : BudgetType.MY_BUDGET
}

export function isOwner (state) {
    return get(state, ['authData', 'app_type'], null) === appTypes.TYPE_OWNER
}

export function isArchitect (state) {
    return get(state, ['authData', 'app_type'], null) === appTypes.TYPE_AC
}

export function isGeneralContractor (state) {
    return get(state, ['authData', 'app_type'], null) === appTypes.TYPE_GC
}

export function isSubcontractor (state) {
    return get(state, ['authData', 'app_type'], null) === appTypes.TYPE_SC
}

export function appUsesBudget (state, getters) {
    if (!getters.isPro) {
        return false
    }

    const appType = get(state, ['authData', 'app_type'], null)

    return [
        appTypes.TYPE_OWNER,
        appTypes.TYPE_DEV,
        appTypes.TYPE_GC,
        appTypes.TYPE_SC,
        appTypes.TYPE_AC,
    ].includes(appType)
}

export function isAdmin (state, getters) {
    return ['IT Administrator', 'Administrator'].includes(getters.getAuthData.account_type)
}

export function getDateFormat (state) {
    return state.authData.date_format
}

export function getTimeFormat (state) {
    return state.authData.time_format
}

export function getFeatureFlags (state) {
    return state.featureFlags
}
