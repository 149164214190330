<template>
    <div class="io-single-option" data-testid="drop-down-option" @click="select(item)">
        <div v-if="image.length"
             class="io-initials"
             :style="image"
        ></div>
        <initials v-else :fullName="item.name"/>

        <div class="io-single-option__content">
            <div class="io-name">{{ getName() }}</div>
            <div v-if="showDescription && description" class="io-info">{{ description }}</div>
        </div>

    </div>
</template>
<script src="./SingleOption.js"></script>

<style scoped lang="scss">
    .io-single-option {
        margin: 0 !important;
        cursor: pointer;
        padding: 6px 16px;
        transition: background-color .25s;
        display: flex;
        align-items: center;

        .io-initials {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 32px;
            width: 32px;
            height: 32px;
            border-radius: 12px;
            font-size: 12px;
            margin-right: 16px !important;
            background-size: cover
        }

        &__content {
            .io-name {
                font-size: 14px !important;
                font-weight: 400 !important;
                line-height: 1.6 !important;
                color: #33373c !important;
                margin: 0;
            }
            .io-info {
                font-size: 12px;
                font-weight: 400;
                line-height: 1.2;
                color: #63666e;
            }
        }

        &:hover {
            background-color: rgba(var(--accent-rgb), .1);
        }
    }
</style>
