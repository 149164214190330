export const getActiveColumn = (state) => {
    return state.column
}

export const getActivePage = (state) => {
    return state.page
}

export const getActiveSearch = (state) => {
    return state.search
}

export const getActivePagination = (state) => {
    return state.pagination
}

export const getActiveFilters = (state) => {
    return state.filters
}
export const getIsSearching = (state) => {
    return state.isSearching
}
