<template>
    <div class="io-custom-dropdown" :class="{ 'io-active': dropdownExpanded, 'io-disabled': disabled }" @click="toggleDropdown" v-click-outside="closeDropdown">
        <div class="io-custom-dropdown__text">{{ selectedLabel }}</div>

        <div class="io-custom-dropdown__icon"><i class="fas fa-angle-down"></i></div>

        <transition name="pill-dropdown-fade">
            <div v-if="dropdownExpanded" class="io-custom-dropdown__options">
                <template v-for="(option, index) in options" :key="index">
                    <div
                         class="io-custom-dropdown__option"
                         @click="select(option)"
                    >
                        <div>{{ optionLabel(option) }}</div>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" src="./CustomDropdown.ts"></script>
<style lang="scss" src="./CustomDropdown.scss" scoped></style>
