<template>
    <div class="flex flex-col gap-16-px p-16-px">
        <header class="flex items-center gap-8-px">
            <i class="icon-alert-triangle text-2xl-px text-warning-darker" />

            <span class="font-semibold">{{ $t('Are you sure?') }}</span>
        </header>

        <div class="text-main-lighten-2 leading-relaxed font-medium">
            {{ $t('Changing the stamp will remove the added references.') }}
        </div>

        <footer class="flex justify-end gap-24-px px-8-px">
            <IOButton
                variant="link"
                size="small"
                class="py-8-px"
                @click="$emit('cancel')"
            >
                {{ $t('Cancel') }}
            </IOButton>

            <IOButton
                variant="link"
                size="small"
                class="py-8-px"
                @click="$emit('confirm')"
            >
                {{ $t('Change') }}
            </IOButton>
        </footer>
    </div>
</template>

<script lang="ts" src="./StampChangeDialog.ts"></script>
