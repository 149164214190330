import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Radio',
    props: {
        labelText: { type: String },
        inputName: { type: String, required: true },
        modelValue: { required: false },
        radioValue: { required: true },
        disabled: { type: Boolean, default: false },
        valid: { type: Boolean, default: true },
    },
    emits: ['input', 'update:modelValue'],
    data () {
        return {
            currentValue: null,
        }
    },
    computed: {
        checked (): boolean {
            return `${ this.currentValue }` === `${ this.radioValue }`
        },
    },
    watch: {
        currentValue (): void {
            this.$emit('update:modelValue', this.currentValue)
        },
        modelValue (): void {
            this.currentValue = this.modelValue
        },
    },
    mounted () {
        this.currentValue = this.modelValue
    },
})
