import { defineComponent } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { mapGetters, mapState } from 'vuex'
import { mapState as mapStatePinia } from 'pinia'
import { contractStore } from '@/io-modules/project-contracts/stores/contractStore.ts'
import { contractWizardStore } from '@/io-modules/project-contracts/stores/contractWizardStore.ts'
import AppType from '@/base/appTypes.ts'
import ContractType from '@/base/ContractType.ts'
import UnitsOfMeasure from '@/constants/UnitsOfMeasure.ts'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource.ts'
import { UnitOfMeasure } from '@/io-modules/project-contracts/interfaces/WbsItem.ts'
import ContractTypeOption from '@/io-modules/project-contracts/interfaces/ContractTypeOption.ts'
import AgreementTypeOption from '@/io-modules/project-contracts/interfaces/AgreementTypeOption.ts'
import ContractHolderOption from '@/io-modules/project-contracts/interfaces/ContractHolderOption.ts'
import ContractStatus from '@/io-modules/project-contracts/enums/ContractStatus.ts'
import ContractSovStatus from '@/io-modules/project-contracts/enums/ContractSovStatus.ts'
import ContractRouteName from '@/io-modules/project-contracts/enums/ContractRouteName.ts'
import ContractHolderType from '@/io-modules/project-contracts/enums/ContractHolderType.ts'
import ContractAgreementType from '@/io-modules/project-contracts/enums/ContractAgreementType.ts'
import { ListContract } from '@/io-modules/contracts/interfaces/ListContract.ts'
import { HolderCellCustomValueReturnType } from '@/io-modules/contracts/components/simple-lazy-list/interfaces/SimpleLazyListHeader.ts'
import { AvatarType } from '@/constants/AvatarConstants.ts'
import WbsType from '@/io-modules/project-contracts/enums/WbsType.ts'

export default defineComponent({
    computed: {
        ...mapState('project', {
            projectObj: (state: any) => state.projectObj,
        }),
        ...mapGetters('appStore', [
            'getAuthData',
            'isOwnerRep',
            'isOwner',
        ]),
        ...mapStatePinia(contractStore, [
            'summary',
            'contractGMPStatus',
            'budgetStatusInfo',
            'wbsType',
        ]),
        ...mapStatePinia(contractWizardStore, [
            'type',
            'status',
            'sovStatus',
            'holder',
            'isClient',
            'isVendor',
        ]),
        isContractEdit (): boolean {
            return this.$route.name === ContractRouteName.ContractEdit
        },
        isContractCreation (): boolean {
            return this.$route.name === ContractRouteName.ContractCreate
        },
        isContractView (): boolean {
            return this.$route.name === ContractRouteName.ContractDetails
        },
        hasApprovedFinalAmendment (): boolean {
            return this.contractGMPStatus?.final_amendment_id && this.contractGMPStatus?.final_amendment_approved
        },
        isSovApproved (): boolean {
            if (this.isContractCreation || this.isContractEdit) {
                return ContractSovStatus.APPROVED === this.sovStatus
            }

            return ContractSovStatus.APPROVED === this.summary?.vendor_wbs_status
        },
        isDraft (): boolean {
            if (this.isContractCreation) {
                return true
            }

            if (this.isContractEdit) {
                return ContractStatus.DRAFT === this.status
            }

            return ContractStatus.DRAFT === this.summary?.contract_status.status
        },
        isPendingInternalApproval (): boolean {
            return ContractStatus.PENDING_INTERNAL_APPROVAL === this.summary?.contract_status.status
        },
        isPendingRepApproval (): boolean {
            return ContractStatus.PENDING_REP_APPROVAL === this.summary?.contract_status.status
        },
        isInternallyApproved (): boolean {
            return ContractStatus.INTERNALLY_APPROVED === this.summary?.contract_status.status
        },
        isInitialContractExecuted (): boolean {
            if (this.isContractEdit) {
                return ContractStatus.INITIAL_CONTRACT_EXECUTION === this.status
            }

            return ContractStatus.INITIAL_CONTRACT_EXECUTION === this.summary?.contract_status.status
        },
        isOriginalContractExecuted (): boolean {
            if (this.isContractEdit) {
                return this.isGMPContract && ContractStatus.EXECUTED === this.status
            }

            return this.isGMPContract && ContractStatus.EXECUTED === this.summary?.contract_status.status
        },
        isExecuted (): boolean {
            if (this.isContractEdit) {
                return ContractStatus.EXECUTED === this.status
            }

            return ContractStatus.EXECUTED === this.summary?.contract_status.status
        },
        isRejectedByClient (): boolean {
            return ContractStatus.REJECTED_BY_CLIENT === this.summary?.contract_status.status
        },
        isSubmittedToClient (): boolean {
            return ContractStatus.SUBMITTED_TO_CLIENT === this.summary?.contract_status.status
        },
        isSovWbsApprovedByClient (): boolean {
            return ContractSovStatus.APPROVED === this.summary?.client_wbs_status
        },
        isSovWbsApprovedByVendor (): boolean {
            return ContractSovStatus.APPROVED === this.summary?.vendor_wbs_status
        },
        isSovTypeSwitchStateInitial (): boolean {
            return this.wbsType === WbsType.Initial
        },
        isSovTypeSwitchStateOriginal (): boolean {
            return this.wbsType === WbsType.Original
        },
        isGMPContract (): boolean {
            if (this.isContractCreation || this.isContractEdit) {
                return ContractType.GMP === this.type
            }

            return ContractType.GMP === this.summary?.type
        },
        isCurrentUserClient (): boolean {
            if (this.isContractEdit) {
                return this.isClient
            }

            return this.summary?.is_client
        },
        isCurrentUserVendor (): boolean {
            if (this.isContractEdit) {
                return this.isVendor
            }

            return this.summary?.is_vendor
        },
        approvableResourceName (): Resource {
            if (!this.isGMPContract) {
                return Resource.CONTRACTS
            }

            return this.isInitialContractExecuted ? Resource.CONTRACTS : Resource.INITIAL_CONTRACTS
        },
        unitsOfMeasure (): UnitOfMeasure[] {
            return [
                {
                    id: UnitsOfMeasure.LUMP_SUM,
                    name: this.$t('Lump Sum'),
                },
                {
                    id: UnitsOfMeasure.HOURS,
                    name: this.$t('Hours'),
                },
                {
                    id: UnitsOfMeasure.EACH,
                    name: this.$t('Each'),
                },
                {
                    id: UnitsOfMeasure.ALLOWANCE,
                    name: this.$t('Allowance'),
                },
                {
                    id: UnitsOfMeasure.PERCENT,
                    name: this.$t('Percent'),
                },
                {
                    id: UnitsOfMeasure.SQUARE_FEET,
                    name: this.$t('Square Feet'),
                },
                {
                    id: UnitsOfMeasure.GROSS_SQUARE_FEET,
                    name: this.$t('Gross Square Feet'),
                },
                {
                    id: UnitsOfMeasure.RENTABLE_SQUARE_FEET,
                    name: this.$t('Rentable Square Feet'),
                },
                {
                    id: UnitsOfMeasure.USABLE_SQUARE_FEET,
                    name: this.$t('Usable Square Feet'),
                },
                {
                    id: UnitsOfMeasure.LINEAR_FEET,
                    name: this.$t('Linear Feet'),
                },
                {
                    id: UnitsOfMeasure.YARDS,
                    name: this.$t('Yards'),
                },
                {
                    id: UnitsOfMeasure.CYDS,
                    name: this.$t('Cubic Yards'),
                },
                {
                    id: UnitsOfMeasure.TONS,
                    name: this.$t('Tons'),
                },
                {
                    id: UnitsOfMeasure.SQUARE_METRES,
                    name: this.$t('Square Metres'),
                },
                {
                    id: UnitsOfMeasure.GROSS_SQUARE_METRES,
                    name: this.$t('Gross Square Metres'),
                },
                {
                    id: UnitsOfMeasure.RENTABLE_SQUARE_METRES,
                    name: this.$t('Rentable Square Metres'),
                },
                {
                    id: UnitsOfMeasure.USABLE_SQUARE_METRES,
                    name: this.$t('Usable Square Metres'),
                },
                {
                    id: UnitsOfMeasure.METRES,
                    name: this.$t('Metres'),
                },
                {
                    id: UnitsOfMeasure.LINEAR_METRES,
                    name: this.$t('Linear Metres'),
                },
                {
                    id: UnitsOfMeasure.CUBIC_METRES,
                    name: this.$t('Cubic Metres'),
                },
                {
                    id: UnitsOfMeasure.METRIC_TONNES,
                    name: this.$t('Metric Tonnes'),
                },
            ]
        },
        agreementTypeOptions (): AgreementTypeOption[] {
            return [
                {
                    value: ContractAgreementType.ADVISORY,
                    icon: 'icon-presentation-line',
                    title: this.$t('Advisory'),
                },
                {
                    value: ContractAgreementType.AGENCY,
                    icon: 'icon-briefcase',
                    title: this.$t('Agent'),
                },
                {
                    value: ContractAgreementType.PRINCIPAL,
                    icon: 'icon-cash',
                    title: this.$t('Principal'),
                },
                {
                    value: ContractAgreementType.UNDETERMINED,
                    icon: 'icon-time',
                    title: this.$t('Undetermined'),
                },
            ]
        },
        companyNameOwnersRep (): string {
            if (this.isContractView) {
                return this.summary?.contract_holder.name
            }

            return this.getAuthData.u_company_name ?? '-'
        },
        companyNameOwner (): string {
            if (this.isContractView) {
                return this.summary?.contract_holder.name
            }

            return this.projectObj._res_name ?? '-'
        },
        contractHolderOptions (): ContractHolderOption[] {
            return [
                {
                    value: ContractHolderType.AGENCY,
                    logo: this.projectObj._res_id,
                    title: `${ this.companyNameOwner } ${ this.$t('with') }`,
                    role: `${ this.$t('Agent') }`,
                    isDisabled: false,
                },
                {
                    value: ContractHolderType.ADVISORY,
                    logo: this.projectObj._res_id,
                    title: `${ this.companyNameOwner } ${ this.$t('as') }`,
                    role: `${ this.$t('Principal') }`,
                    isDisabled: false,
                },
                {
                    value: ContractHolderType.PRINCIPAL,
                    logo: this.getAuthData.company_mongo_id,
                    title: `${ this.companyNameOwnersRep } ${ this.$t('as') }`,
                    role: `${ this.$t('Principal') }`,
                    isDisabled: this.isOwnerRep && !this.budgetStatusInfo?.has_my_budget,
                },
            ]
        },
        contractTypeOptions (): ContractTypeOption[] {
            return [
                {
                    value: ContractType.STANDARD,
                    title: this.$t('Standard Contract Agreement (Single Stage Agreement)'),
                },
                {
                    value: ContractType.GMP,
                    title: this.$t('Non-Standard Agreement (Multi-Stage Agreement/GMP)'),
                },
            ]
        },
    },
    methods: {
        getContractStatusColor (status: string): PillVariant {
            return {
                [ContractStatus.DRAFT]: PillVariant.BLUE_LIGHT,
                [ContractStatus.PENDING_INTERNAL_APPROVAL]: PillVariant.YELLOW_LIGHT,
                [ContractStatus.PENDING_REP_APPROVAL]: PillVariant.YELLOW_LIGHT,
                [ContractStatus.INTERNALLY_REJECTED]: PillVariant.RED_LIGHT,
                [ContractStatus.INTERNALLY_APPROVED]: PillVariant.GREEN_LIGHT,
                [ContractStatus.SUBMITTED_TO_CLIENT]: PillVariant.BLUE_LIGHT,
                [ContractStatus.REJECTED_BY_CLIENT]: PillVariant.RED_LIGHT,
                [ContractStatus.APPROVED]: PillVariant.GREEN_LIGHT,
                [ContractStatus.APPROVED_BY_CLIENT]: PillVariant.GREEN_LIGHT,
                [ContractStatus.INITIAL_CONTRACT_EXECUTION]: PillVariant.GREEN_LIGHT,
                [ContractStatus.EXECUTED]: PillVariant.GREEN_LIGHT,
            }[status] || PillVariant.GRAY_LIGHT
        },
        getContractStatusText (status: string): TranslateResult {
            return {
                [ContractStatus.DRAFT]: this.$t('Draft'),
                [ContractStatus.PENDING_INTERNAL_APPROVAL]: this.$t('Pending Internal Approval'),
                [ContractStatus.PENDING_REP_APPROVAL]: this.$t('Pending Rep Approval'),
                [ContractStatus.INTERNALLY_REJECTED]: this.$t('Internally Rejected'),
                [ContractStatus.INTERNALLY_APPROVED]: this.$t('Internally Approved'),
                [ContractStatus.SUBMITTED_TO_CLIENT]: this.$t('Submitted to Client'),
                [ContractStatus.REJECTED_BY_CLIENT]: this.$t('Rejected by Client'),
                [ContractStatus.APPROVED]: this.$t('Approved'),
                [ContractStatus.APPROVED_BY_CLIENT]: this.$t('Approved by Client'),
                [ContractStatus.INITIAL_CONTRACT_EXECUTION]: this.$t('Initial contract Execution'),
                [ContractStatus.EXECUTED]: this.$t('Executed'),
            }[status]
        },
        getSovStatusColor (status: ContractSovStatus): PillVariant {
            return {
                [ContractSovStatus.NOT_APPROVED]: PillVariant.YELLOW_LIGHT,
                [ContractSovStatus.APPROVED]: PillVariant.GREEN_LIGHT,
            }[status] || PillVariant.GRAY_LIGHT
        },
        getSovStatusText (status: ContractSovStatus): TranslateResult {
            return {
                [ContractSovStatus.NOT_APPROVED]: this.$t('Not Approved'),
                [ContractSovStatus.APPROVED]: this.$t('Approved'),
            }[status]
        },
        getCompanyPillVariant (type: string): PillVariant {
            return (type ? `${ this.getPillColor(type) }-light` : 'io-gray-light') as PillVariant
        },
        getCompanyPillText (type: string): string {
            if (type === AppType.TYPE_AC || type === AppType.TYPE_DESIGN) {
                return this.$t('Design')
            }

            if (type === AppType.TYPE_DEV || type === AppType.TYPE_REP) {
                return this.$t('Rep')
            }

            if (type === AppType.TYPE_SC || type === AppType.TYPE_SUB) {
                return this.$t('Sub')
            }

            if (type === AppType.TYPE_GC) {
                return this.$t('GC')
            }

            if (type === AppType.TYPE_OFF_SYSTEM) {
                return this.$t('Off-System')
            }

            return type
        },
        getAppTypeName (appType: AppType): string {
            if (AppType.TYPE_OWNER === appType) {
                return this.$t('Owner')
            }

            if ([AppType.TYPE_DEV, AppType.TYPE_REP].includes(appType)) {
                return this.$t('apptype_name_dev')
            }

            if ([AppType.TYPE_AC, AppType.TYPE_DESIGN].includes(appType)) {
                return this.$t('apptype_name_ac')
            }

            if ([AppType.TYPE_SC, AppType.TYPE_SUB].includes(appType)) {
                return this.$t('apptype_name_sc')
            }

            if ([AppType.TYPE_BASIC, AppType.TYPE_GC].includes(appType)) {
                return this.$t(`apptype_name_${ appType }`)
            }
        },
        getUnitOfMeasureName (id: string): string {
            const unit = this.unitsOfMeasure.find(item => id === item.id)

            return unit?.name
        },
        getHolderCustomValueForRow (item: ListContract): HolderCellCustomValueReturnType<ListContract> {
            const holder = [
                {
                    value: ContractHolderType.AGENCY,
                    logo: item.holder?.id,
                    title: [item.holder?.name, this.$t('with')],
                    role: this.$t('Agent'),
                },
                {
                    value: ContractHolderType.ADVISORY,
                    logo: item.holder?.id,
                    title: [item.holder?.name, this.$t('as')],
                    role: this.$t('Principal'),
                },
                {
                    value: ContractHolderType.PRINCIPAL,
                    logo: item.holder?.id,
                    title: [item.holder?.name, this.$t('as')],
                    role: this.$t('Principal'),
                },
            ]
                .find((option) => item.holder?.type === option.value)

            return holder ? {
                id: holder.logo,
                name: holder.title,
                type: holder.role,
                avatarType: AvatarType.COMPANY,
            } : {
                name: null,
                id: null,
                type: this.$t('Undetermined'),
                avatarType: AvatarType.COMPANY,
            }
        },
    },
})
