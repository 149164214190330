<template>
    <IOModal
        v-if="open"
        class="io-mark-as-paid-modal"
        icon="icon-dollar-circle"
        width="451"
        :title="$t('Mark as Paid')"
        @close="closeModal"
    >
        <template #default>
            <MarkAsPaidBody
                :net-invoice-value="invoiceNet"
                v-model="paymentInfo"
            />
        </template>
        <template #footer>
            <IOButton variant="secondary" @click="closeModal">
                {{ $t('Cancel') }}
            </IOButton>
            <IOButton :disabled="!allRequiredFieldsCompleted" @click="markAsPaid">
                {{ $t('Mark as Paid') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./MarkAsPaidModal.ts"></script>
<style lang="scss" src="./MarkAsPaidModal.scss" scoped></style>
