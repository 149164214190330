<template>
    <LoadingScreen :custom-show-loading="loading"/>
    <ContentTwoSidesTemplate v-if="!loading" class="io-content-template" iconClass="icon-info" :title="$t('Information')">
        <template #leftSide>
            <div class="io-form" :class="{ 'io-form--loading': dataLoading }">
                <div v-if="hasAccountingIntegration && creating" class="io-form__item io-form__item-type">
                    <IOLabel :text="$t('Invoice Type')" :is-required="true" />
                    <Radio
                        :radio-value="false"
                        input-name="billingInvoice"
                        :label-text="$t('Billing Invoice')"
                        v-model="invoice.retention_invoice"
                    />
                    <Radio
                        :radio-value="true"
                        input-name="retentionInvoice"
                        :label-text="$t('Retention Invoice')"
                        v-model="invoice.retention_invoice"
                    />
                </div>
                <div v-if="hasAccountingIntegration && editing" class="io-form__item io-form__item-type">
                    <IOLabel :text="$t('Invoice Type')" :is-required="true" />
                    <div class="io-value">{{ invoice.retention_invoice ? $t('Retention Invoice') : $t('Billing Invoice') }}</div>
                </div>
                <div class="io-form__item io-form__item-id">
                    <div class="io-label-wrapper">
                        <IOLabel :text="$t('Custom ID')" />
                        <span class="io-label-wrapper__counter">{{ uniqueNumberCounter }}</span>
                    </div>

                    <IOInput
                        :maxlength="uniqueNumberMaxCharacters"
                        :error-message="$t('ID already in use')"
                        :valid="uniqueNumberIsValid"
                        v-model="invoice.invoice_unique_number"
                    />
                </div>
                <div class="io-form__item io-form__item-date">
                    <IOLabel :text="$t('Invoice Date')" :is-required="true" />
                    <FieldDateTime :options="datepickerOptions" :showIcon="true" v-model="invoice.invoice_date"/>
                </div>
                <div class="io-form__item io-form__item-date-range">
                    <IOLabel :text="$t('Start & End Date')" :is-required="true" />
                    <FieldDateTime :options="{ ...datepickerOptions, range: true, placeholder: $t('Select Date Range') }" :showIcon="true" v-model="dateRange"/>
                </div>
                <div class="io-form__item io-form__item-description">
                    <IOLabel :text="$t('Description')" />
                    <IOTextarea :autoHeight="true" v-model="invoice.description" />
                </div>
            </div>
        </template>
        <template #rightSide>
            <section>
                <IOLabel :text="$t('Client')" />
                <Avatar
                    type="company"
                    :employee-mongo-id="clientInfo.id"
                    :employee-full-name="clientInfo.name"
                    :show-full-name="true"
                />
            </section>
            <hr class="io-divider" />
            <section>
                <IOLabel :text="$t('Custom ID')" />
                <div class="io-value">{{ $t('Your company\'s specific invoice ID, such as your accounting invoice number or any other referenced invoice number.') }}</div>
            </section>
            <section>
                <IOLabel :text="$t('Invoice Date')" />
                <div class="io-value">{{ $t('The date the invoice is being submitted.') }}</div>
            </section>
            <section>
                <IOLabel :text="$t('Start & End Date')" />
                <div class="io-value">{{ $t('The service dates for this invoice.') }}</div>
            </section>
        </template>
    </ContentTwoSidesTemplate>
</template>

<script lang="ts" src="./EditMyInvoiceDetails.ts"></script>
<style lang="scss" src="./EditMyInvoiceDetails.scss" scoped></style>
