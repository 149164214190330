
import { app } from '@/app'
import i18n from '@/base/i18n.js'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker3'
import 'vue-ctk-date-time-picker3/dist/vue-ctk-date-time-picker3.css'
import Multiselect from 'vue-multiselect'

import Popup from './components/popup/Popup.vue'
import ComponentTooltip from './components/atoms/TooltipIcon/TooltipIcon.vue'
import FieldDateTime from './components/fields/fieldDateTime.vue'
import FieldNumeric from './components/fields/fieldNumeric.vue'
import FieldNumericInt from './components/fields/fieldNumericInt.vue'
import FieldWysiwyg from './components/fields/fieldWysiwyg.vue'
import FieldUploader from './components/files/files'
import FilesUploader from './components/files-new/files.vue'
import InfoBox from './components/info-box/InfoBox.vue'
import Initials from './components/new-theme/initials.vue'
import SearchInput from './components/search-input/SearchInput.vue'
import FiltersButton from '@/components/filters-button/FiltersButton.vue'

import CtkDateTimePicker from './elements/CtkDateTimePicker.vue'
import Placeholder from './components/placeholder/Placeholder.vue'
import ModalLayout from './elements/modalLayout.vue'
import RadialProgressBar from './elements/RadialProgressBar.vue'
import Checkbox from '@/components/atoms/Checkbox/Checkbox.vue'
import Radio from '@/components/atoms/Radio/Radio.vue'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'
import IOInput from '@/components/atoms/IOInput/IOInput.vue'
import modal from '@/components/new-theme/modal.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import StatusDot from '@/components/atoms/status-dot/StatusDot.vue'
import IOLabel from '@/components/atoms/IOLabel/IOLabel.vue'
import IOSwitcher from '@/components/atoms/IOSwitcher/IOSwitcher.vue'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import PageHeader from '@/components/page-header/PageHeader.vue'
import FormGroup from '@/components/form-group/FormGroup.vue'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'

// Multiselect and remove limit
Multiselect.mixins[0].props.optionsLimit.default = 999999
Multiselect.props.selectLabel.default = ''
Multiselect.props.selectGroupLabel.default = i18n.global.t('Press enter to select group')
Multiselect.props.deselectLabel.default = ''
Multiselect.mixins[0].props.placeholder.default = i18n.global.t('Select option')

export default {
    install (app) {
        // Components registration
        app.component('Popup', Popup)
        app.component('CtkDateTimePicker', CtkDateTimePicker)
        app.component('FieldDateTime', FieldDateTime)
        app.component('FieldNumeric', FieldNumeric)
        app.component('FieldNumericInt', FieldNumericInt)
        app.component('FieldUploader', FieldUploader)
        app.component('FieldWysiwyg', FieldWysiwyg)
        app.component('FilesUploader', FilesUploader)
        app.component('InfoBox', InfoBox)
        app.component('Initials', Initials)
        app.component('Tooltip', ComponentTooltip)
        app.component('Placeholder', Placeholder)
        app.component('Multiselect', Multiselect)
        app.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
        app.component('ModalLayout', ModalLayout)
        app.component('Checkbox', Checkbox)
        app.component('Radio', Radio)
        app.component('IOInput', IOInput)
        app.component('IOButton', IOButton)
        app.component('modal', modal)
        app.component('SearchInput', SearchInput)
        app.component('FiltersButton', FiltersButton)
        app.component('StatusPill', StatusPill)
        app.component('StatusDot', StatusDot)
        app.component('IOLabel', IOLabel)
        app.component('Avatar', Avatar)
        app.component('IOSwitcher', IOSwitcher)
        app.component('RadialProgressBar', RadialProgressBar)
        app.component('PageHeader', PageHeader)
        app.component('FormGroup', FormGroup)
        app.component('ActionDropdown', ActionDropdown)
    }
}


