export enum PayAppStatus {
    APPROVED = 'status_approved',
    DRAFT = 'status_draft',
    PENDING = 'status_pending',
    PENDING_MODIFIED = 'status_pending_modified',
    PENDING_SUBMITTED = 'status_pending_submitted',
    REVISED_RESUBMIT = 'status_revised_resubmit',
    REVISION_REQUESTED = 'status_revision_requested',
    REJECTED = 'status_rejected',
    SUBMITTED_FOR_FUNDING = 'status_submitted_for_funding',
    REJECTED_FOR_FUNDING = 'status_rejected_for_funding',
    APPROVED_FOR_FUNDING = 'status_approved_for_funding',
    SUBMITTED_TO_ACCOUNTING = 'status_submitted_to_accounting',
    REJECTED_BY_ACCOUNTING = 'status_rejected_by_accounting'
}
