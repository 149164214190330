<template>
    <td class="io-cell-icon-action" :class="cellClass">
        
            <span v-if="!accepted"> {{ mappedValue[data.status] }} </span>
            <i v-if="tooltip" class="fas" :class="iconName" v-tooltip.top="{ content: tooltip, visible: tooltip }"></i>
            <i v-else class="fa" :class="iconClass"></i>
            <span @click.stop="emitAction" v-if="!accepted" class="io-action"> {{ action }} </span>
        
    </td>
</template>

<script lang="ts" src="./InvitationStatus.ts"></script>
<style lang="scss" src="./InvitationStatus.scss" scoped></style>
