import { defineComponent } from 'vue'

const uploadingItemMixin = defineComponent({
    computed: {
        progressItem (): [{ color: string, show_legend: boolean, value: string }] {
            return [
                {
                    color: 'var(--accent)',
                    show_legend: true,
                    value: this.item.progress,
                }
            ]
        },
        extension (): string {
            const nameArray = this.item.fileName.split('.')
            return nameArray[1]
        },
        textToShow (): string {
            const name = this.item.fileName
            if (name && name.length > 21) {
                return name.slice(0, 15) + '...' + this.extension
            }

            return this.item.fileName
        },
        showCancelButton (): boolean {
            return this.item.progress < 95
        }
    },
    methods: {
        removeFileFromQueue (): void {
            this.$store.dispatch('filesComponent/changeUploadingFileStatus', {
                progress: 0,
                status: 'canceled',
                tempId: this.item.tempId,
                arrayId: this.item.arrayId,
            })
        }
    },
})

export default uploadingItemMixin
