import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'

import { MenuPunchList, PunchList, PunchListPayload } from '@/io-modules/punch-lists/interfaces/PunchList.ts'
import { Status as PunchListStatus } from '@/io-modules/punch-lists/enums/PunchList.ts'

export default {
    getPunchLists: (projectId: string, params: { statuses?: PunchListStatus[] } = {}): AxiosPromise<MenuPunchList[]> =>
        client.get(`/ca/punch-item/${ projectId }/punch-lists`,  { params }),

    createPunchList: (projectId: string, payload: PunchListPayload): AxiosPromise<PunchList> =>
        client.post(`/ca/punch-item/${ projectId }/punch-lists`, payload),

    editPunchList: (projectId: string, payload: PunchListPayload): AxiosPromise<PunchList> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/${ payload.id }`, payload),

    getPunchList: (projectId: string, id: string): AxiosPromise<PunchList> =>
        client.get(`/ca/punch-item/${ projectId }/punch-lists/${ id }`),

    closePunchList: (projectId: string, id: string): AxiosPromise<PunchList> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/${ id }/close`),

    openPunchList: (projectId: string, id: string): AxiosPromise<PunchList> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/${ id }/open`),

    archivePunchList: (projectId: string, id: string): AxiosPromise<PunchList> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/${ id }/archive`),

    unarchivePunchList: (projectId: string, id: string): AxiosPromise<PunchList> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/${ id }/unarchive`),
}
