import { ApiClient } from '@/api/api'

let client = ApiClient

export default {
    getNotesList (id) {
        return client.get(`projects-notes/${id}`)
    },
    deleteNote (id) {
        return client.get(`projects-notes/delete/${id}`)
    },
    getProjectRequiredFieldsInfo (id) {
        return client.get(`/custom-field-data/check-project/${id}`)
    },

    getSummary (id) {
        return client.get(`project-summary/${id}`)
    },

    getTemplatesByType (type) {
        let url = ''

        switch (type) {
            case 'customAttributesTemplate':
                url = 'custom-attributes-templates'
                break
            case 'projectCostCodesOwnerBudgetTemplate':
                url = 'cost-codes-templates'
                break
            case 'projectCostCodesMyBudgetTemplate':
                url = 'cost-codes-templates'
                break
            case 'tasksTemplate':
                url = 'tasks-templates'
                break
            case 'currencyConversionTableTemplate':
                url = 'currency-conversion-templates'
                break
        }

        return client.get(`/project/${ url }`)
    },

    delete (id) {
        return client.delete(`/project/${ id }`)
    },
    
}
