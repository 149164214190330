import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'

import punchPillColorMixin from '@/mixins/punches/punchPillColorMixin.ts'

import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import PunchItemOperationsSlideout
    from '@/io-modules/punch-lists/components/punch-item-operations-slideout/PunchItemOperationsSlideout.vue'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar.ts'
import { PunchItemDrawingAnnotation } from '@/io-modules/drawings/interfaces/PunchItemDrawingAnnotation.ts'
import { PunchItemV1 } from '@/io-modules/drawings/interfaces/PunchItem.ts'

export default defineComponent({
    name: 'Single Punch',
    components: {
        Avatar,
        PunchItemOperationsSlideout,
    },
    props: {
        punchNumber: { type: Number },
        isTopMost: { type: Boolean },
        data: {
            type: Object as () => PunchItemDrawingAnnotation,
            required: true,
        },
    },
    mixins: [punchPillColorMixin],
    data () {
        return {
            annotationType: RightSideBar.PUNCH,
            punchItemEditSlideoutShown: false,
        }
    },
    computed: {
        ...mapGetters('modal', {
            getShow: 'getShow',
        }),
        ...mapState('todo', {
            taskSaved: 'taskSaved',
        }),
        ...mapGetters('drawingMarkup', {
            getSelectedExistingAnnotation: 'getSelectedExistingAnnotation',
        }),
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
        }),
        isSelected (): boolean {
            const typeMatched = this.data.type === 'punch_v2'
                ? this.getSelectedExistingAnnotation.type === 'punch'
                : this.getSelectedExistingAnnotation.type === this.data.type
            return this.getSelectedExistingAnnotation.uuid === this.data.uuid && typeMatched
        },
        punchItemV2ToV1Statuses (): { [key: string]: { code: number, title: string } } {
            return {
                NOT_STARTED: {
                    code: 100,
                    title: this.$t('Not Started'),
                },
                IN_PROGRESS: {
                    code: 200,
                    title: this.$t('In Progress'),
                },
                UNDER_REVIEW: {
                    code: 250,
                    title: this.$t('Under Review'),
                },
                COMPLETED: {
                    code: 300,
                    title: this.$t('Completed'),
                },
                ARCHIVED: {
                    code: 400,
                    title: this.$t('Archived'),
                },
            }
        },
        punch (): Partial<PunchItemV1> {
            return {
                punch_name: this.data.punch_v2.title,
                punch_status: this.punchItemV2ToV1Statuses[this.data.punch_v2.status]?.code,
                punch_status_text: this.punchItemV2ToV1Statuses[this.data.punch_v2.status]?.title,
                punch_description: this.data.punch_v2.comment,
                created_at: this.data.punch_v2.created_at,
                punch_due_date: this.data.punch_v2.due_date,
            }
        },
    },
    watch: {
        getShow: {
            handler (newVal: string | boolean): void {
                if (!newVal) {
                    let query = Object.assign({}, this.$route.query)
                    delete query.taskId
                    this.$router.replace({ query })
                    this.setLoadingBar(true)
                }
            },
        },
        taskSaved: {
            async handler (newVal: boolean): Promise<void> {
                if (newVal) {
                    const { data } = await setsClient.getDrawingVersion(this.model.drawing.current_version_id)
                    this.$store.commit('drawingMarkup/SET_MODEL', data)
                }

                this.$store.commit('todo/SET_TASK_SAVED', false)
                this.setLoadingBar(false)
            },
        },
    },
    methods: {
        async editPunchItem (): Promise<void> {
            this.punchItemEditSlideoutShown = true
        },
        async openTaskModal (): Promise<void> {
            if (this.$route.query.taskId) {
                await this.$store.dispatch('modal/setAdditionalData', {})
                await this.$store.dispatch('modal/setShow', 'modal-task')
            }
        },
        selectAnnotation (): void {
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: this.data.uuid,
                type: this.annotationType ?? this.data.type,
            })
        },
        closePunchItemSlideout (): void {
            this.punchItemEditSlideoutShown = false
            this.$store.commit('drawingMarkup/SET_ANNOTATION_ADDED', true)
        },
    },
})
