import { isFeatureFlagEnabled } from '@/modules/projects/routes/feature-flag-status.ts'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import { NavigationGuardNext, RouteLocationRaw } from 'vue-router'
const DashboardView = () => import('@/io-modules/dashboard/views/dashboard-view/DashboardView.vue')

const defaultMeta = {
    title: 'Dashboard',
    name: 'Dashboard',
    navigationKey: 'Dashboard'
}

export default [
    {
        name: 'dashboard-v2',
        path: '/dashboard-v2',
        meta: defaultMeta,
        component: DashboardView,
        async beforeEnter (to: RouteLocationRaw, from: RouteLocationRaw, next: NavigationGuardNext): Promise<void> {
            if (await isFeatureFlagEnabled(FeatureFlagsConsts.DASHBOARD_V2, false)) {
                next()
            } else {
                next({ name: 'dashboard-statistics' })
            }
        }
    },
]
