import { defineStore } from 'pinia'
import {
    CurrentInvoiceSettings,
    CurrentProjectSettings, IntegrationAccountingSyncResponse,
    IntegrationDetailsResponse,
    IntegrationInterface,
} from '@/io-modules/integrations/interfaces/IntegrationInterface'
import { AxiosResponse } from 'axios'
import integrationClient from '@/api/integrations/integrationsClient'
import AccountingIntegrationType from '@/io-modules/integrations/enums/AccountingIntegrationType'
import integrationsClient from '@/api/integrations/integrationsClient'

export const integrationsStore = defineStore('integrationsStore', {
    state: (): IntegrationInterface => ({
        integrations: {},
        accountingModels: [],
        currentProjectSettings: {},
        currentInvoiceSettings: {},
        accountingContractDefinitions: {
            syncMainContract: false,
        },
        integrationProjectSettings: {
            externalProject: false,
            externalGeneralLedger: false,
            externalBusinessUnit: false,
            autoSyncChangeOrder: false,
            autoSyncContracts: false,
            autoSyncBudgets: false,
        },
        integrationBanners: {
            [AccountingIntegrationType.BUDGET]: [],
            [AccountingIntegrationType.BUDGET_CHANGE]: [],
            [AccountingIntegrationType.CHANGE_ORDER]: [],
            [AccountingIntegrationType.CONTRACT]: [],
            [AccountingIntegrationType.PAYAPPS]: [],
            [AccountingIntegrationType.PROJECT]: [],
        },
    }),
    getters: {
        hasAccountingIntegration: (state: IntegrationInterface): boolean => 'accounting' in state.integrations && state.integrations.accounting !== null,
        currentAccountingIntegration: (state: IntegrationInterface): string|null => 'accounting' in state.integrations && state.integrations.accounting,
        hasActiveIntegration: (state: IntegrationInterface): boolean => Object.values(state.integrations).filter(Boolean).length > 0,
        isYardiEnabled: (state: IntegrationInterface): boolean =>
            'accounting' in state.integrations
            && state.integrations.accounting !== null
            && state.integrations.accounting.toLowerCase() === 'yardi',
        isYardiVendorInvoiceEnabled: (state: IntegrationInterface): boolean =>
            'accounting' in state.integrations
            && state.integrations.accounting !== null
            && (state.integrations.accounting.toLowerCase() === 'yardi vendor invoicing'
            || state.integrations.accounting.toLowerCase() === 'yardi legacy'),
        hasProjectValidationBanners: (state) => {
            const projectBanners = state.integrationBanners[AccountingIntegrationType.PROJECT]

            return Array.isArray(projectBanners) && projectBanners.length > 0
        },
        isAccountingBannerHidden: (state): boolean => {
            return state.currentProjectSettings?.is_project_has_linked_fields ?? true
        },
    },
    actions: {
        async getIntegrations (): Promise<AxiosResponse<IntegrationDetailsResponse>> {
            const response = await integrationClient.get()
            const data = response.data

            this.integrations = data.integrations
            this.accountingModels = data.accountingModels
            this.integrationProjectSettings = data.projectSettings
            this.accountingContractDefinitions = data.contractDefinitions

            return response
        },
        async setProjectSettings (projectId: string): Promise<CurrentProjectSettings> {
            const { data } = await integrationClient.getProjectSettings(projectId)
            this.currentProjectSettings = data
            return data
        },
        async setInvoiceSettings (invoiceId: string): Promise<CurrentInvoiceSettings> {
            const { data } = await integrationClient.getInvoiceSettings(invoiceId)
            this.currentInvoiceSettings = data
            return data
        },
        checkIfModelInAccountingIntegration (modelName: string): boolean {
            const modelExist = this.accountingModels.find((model) => {
                return model === modelName
            })

            return modelExist ? true : false
        },
        async getIntegrationBanners (
            model_id: string,
            model_name: AccountingIntegrationType,
        ): Promise<void> {
            const payload = {
                model_id,
                model_name,
            }

            const { data } = await integrationsClient.accountingValidateRecord(payload)
            this.integrationBanners[model_name] = data?.messages || []

        },
    }
})
