<template>
    <div class="io-set-files-create">
        <AlertBox
            v-if="!document"
            color="yellow"
            icon="icon-alert-circle"
        >
            {{ $t('All previously made annotations will be cleared after uploading is complete.') }}
        </AlertBox>

        <Files
            class="my-32-px"
            model-type="drawings"
            add-to-temporary
            force-shared
            files-in-new-tab
            hide-share-column
            :array-id="uploaderArrayId"
            :multiple-files="false"
            :show-search="false"
            :validation-options="validationUploaderOptions"
            @new-file-added="newFileAdded"
            @temp-file-remove="removeFile"
        />

        <div class="grid grid-cols-12 gap-7 mt-12">
            <div class="col-span-8">
                <IOLabel is-required :text="$t('Set Name')">
                    <template #suffix>
                        <Tooltip
                            class="ml-1"
                            :text="$t('This typically describes the Construction phase that the current set is for')"
                        />
                    </template>
                </IOLabel>

                <IOInput
                    v-if="currentSetTypeSelected && activeCurrentSet"
                    disabled
                    :modelValue="activeCurrentSet.name"
                />

                <Multiselect
                    v-else
                    track-by="id"
                    label="name"
                    class="io-multiselect--title"
                    :options="activeDraftSets"
                    :allow-empty="false"
                    taggable
                    :placeholder="$t('Select existing or type new set name...')"
                    :model-value="set"
                    @update:model-value="$emit('update:set', $event)"
                    @tag="$emit('update:set', { id: '', name: $event })"
                />
            </div>

            <div class="col-span-4">
                <IOLabel :text="$t('Issue Date')" is-required>
                    <template #suffix>
                        <Tooltip
                            class="ml-1"
                            :text="$t('Date the drawing set was issued by responsible party.')"
                        />
                    </template>
                </IOLabel>

                <FieldDateTime
                    class="w-full"
                    :options="datePickerSettings"
                    :model-value="date"
                    @update:model-value="$emit('update:date', $event)"
                />
            </div>
        </div>
    </div>
</template>


<script lang="ts" src="./SetFilesCreate.ts"></script>
<style lang="scss" src="./SetFilesCreate.scss" scoped></style>
