import { PropType } from 'vue'
import SoVLineItem from '../../interfaces/SoVLineItem'

const tableTotalsMixin = {
    phases: {
        type: Array as PropType<any[]>,
        default: () => [],
    },
    computed: {
        totalScheduleValue (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.scheduled_value, 0)
        },

        totalPreviousApplication (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.previous_applications, 0)
        },

        totalCurrentApplication (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.current_application, 0)
        },

        totalMaterialStoredOffsite (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.materials_stored, 0)
        },

        totalCompletedAndStoredAmount (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.total_completed_stored_amount, 0)
        },

        totalBalanceToComplete (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + (phase.scheduled_value - phase.total_completed_stored_amount), 0)
        },

        totalPreviousRetentionAmount (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.previous_retention_amount, 0)
        },

        totalCurrentRetentionAmount (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.current_retention_amount, 0)
        },

        totalMaterialStoreRetentionAmount (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.materials_stored_retention_amount, 0)
        },

        totalRetentionAmount (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.total_retention_amount, 0)
        },

        totalRetentionReleased (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.retention_released, 0)
        },

        totalNetPreviousAmount (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + this.netPreviousAmount(phase), 0)
        },

        totalNetCurrentApplication (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + phase.net_current_application, 0)
        },

        netTotalCompletedAndStored (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + this.netTotalCompletedStoredAmount(phase), 0)
        },

        totalNetBalanceTCompleteAmount (): number {
            return this.phases.reduce((sum: number, phase: SoVLineItem) => sum + this.netBalanceToCompleteAmount(phase), 0)
        },
    },
}

export default tableTotalsMixin