import Dashboard from '@/modules/dashboard-statistics/dashboard.vue'
import { isFeatureFlagEnabled } from '../projects/routes/feature-flag-status'
import FeatureFlagsConsts from '../../constants/FeatureFlagsConsts'

export default [
    {
        path: '/',
        name: 'main',
        redirect: { name: 'dashboard-statistics' }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        redirect: { name: 'main' }
    },
    {
        path: '/dashboard-statistics',
        name: 'dashboard-statistics',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        },
        async beforeEnter (to, from, next) {
            if (await isFeatureFlagEnabled(FeatureFlagsConsts.DASHBOARD_V2, false)) {
                next({ name: 'dashboard-v2' })
            } else {
                next()
            }
        }
    }
]
