import { defineComponent } from 'vue'
import EditMyInvoiceDetails from '@/io-modules/invoices/components/invoice-view/invoice-details/edit-invoice-details/edit-my-invoice-details/EditMyInvoiceDetails.vue'
import EditVendorInvoiceDetails from '@/io-modules/invoices/components/invoice-view/invoice-details/edit-invoice-details/edit-vendor-invoice-details/EditVendorInvoiceDetails.vue'
import EditNoNContractedInvoiceDetails from '@/io-modules/invoices/components/invoice-view/invoice-details/edit-invoice-details/edit-non-contracted-invoice-details/EditNoNContractedInvoiceDetails.vue'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { mapActions, mapState } from 'pinia'
import InvoiceDetailsInfo
    from '@/io-modules/invoices/components/invoice-view/invoice-details/invoice-details/InvoiceDetails.vue'
import FinancialPanel
    from '@/io-modules/invoices/components/invoice-view/invoice-details/financial-panel/FinancialPanel.vue'
import GrossBillingSummary
    from '@/io-modules/invoices/components/invoice-view/invoice-details/gross-billing-summary/GrossBillingSummary.vue'
import ContractSummary
    from '@/io-modules/invoices/components/invoice-view/invoice-details/contract-summary/ContractSummary.vue'
import { InvoiceSubType } from '@/io-modules/invoices/enums/InvoiceSubType.ts'

export default defineComponent({
    name: 'InvoiceDetails',
    components: {
        EditMyInvoiceDetails,
        EditVendorInvoiceDetails,
        InvoiceDetailsInfo,
        FinancialPanel,
        GrossBillingSummary,
        ContractSummary,
        EditNoNContractedInvoiceDetails,
    },
    computed: {
        ...mapState(invoiceViewStore, ['isEditingMode', 'isMyInvoice', 'isVendorInvoice', 'isNonContractedInvoice', 'invoice']),

        invoiceTypeDefined (): boolean {
            return this.isVendorInvoice || this.isNonContractedInvoice || this.isMyInvoice
        }
    },

    created () {
        if (this.$route.params.id == '0' && !this.invoiceTypeDefined) {
            const creationOptions = JSON.parse(sessionStorage.getItem('invoiceCreationOptions'))

            if (creationOptions) {
                this.setCreationType(creationOptions.invoiceType)
                if (creationOptions.invoiceType !== InvoiceSubType.NonContractedInvoice) {
                    this.setSelectedForCreationContract(creationOptions.selectedContract)
                }
            }
        }
    },

    methods: {
        ...mapActions(invoiceViewStore, ['setCreationType', 'setSelectedForCreationContract']),
    }
})
