import { isArray } from 'lodash'

export default {
    name: 'Banner',
    data () {
        return {
        }
    },

    props: {
        banner: {
            type: Object,
            required: true,
            default: null
        }
    },

    computed: {
        classes () {
            return isArray(this.banner.classes) ? this.banner.classes : null
        }
    },

    methods: {
        dismiss () {
            this.$store.dispatch('notification/removeBanner', this.banner.name)
        }
    },
}
