import projectClient from '@/api/project/projectClient'
import { isObject } from 'lodash'

export const resetState = function ({commit, dispatch}) {
    commit('RESET_STATE')
}

export const resetStateDashboard = function ({commit, dispatch}) {
    commit('RESET_STATE_DASHBOARD')
}

export const setProjectId = function ({commit, dispatch}, value) {
    commit('SET_PROJECT_ID', value || {})
}

export const setId = function ({commit, dispatch}, value) {
    commit('SET_ID', value)
}

export const setProject = function ({commit, dispatch}, value) {
    commit('SET_PROJECT', value || {})
}

export const setCostCodes = function ({commit, dispatch}, value) {
    commit('SET_COST_CODES', value || [])
}


export const setCostTypes = function ({commit, dispatch}, value) {
    commit('SET_COST_TYPES', value || [])
}

export const setSharingCustomFieldsStatus = function ({commit, dispatch}, value) {
    commit('SET_SHARING_CUSTOM_FIELDS_STATUS', value || false)
}


export const setProjectRequiredFieldsInfo = async function ({commit, dispatch}, value) {
    commit('SET_PROJECT_REQUIRED_FIELDS_INFO', value)
}

export const getProjectRequiredFieldsInfo = async function ({commit, dispatch}, id) {
    const { data } = await projectClient.getProjectRequiredFieldsInfo(id)

    commit('SET_PROJECT_REQUIRED_FIELDS_INFO', data.project_has_required_values)
}
