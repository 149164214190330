import { mapState } from 'vuex'
import { findIndex, toString, isEmpty } from 'lodash'

function getCachedStringData (items, id) {
    id = toString(id)
    let index = findIndex(items, (o) => toString(o._id) === id)
    if (index > -1) {
        return items[index]
    }
    return index
}


const cachedListMixin = {
    computed: {
        ...mapState('cached', {
            lists: (state) => state.lists
        })
    },

    methods: {
        /**
         * GET ONE RECORD FROM LOCAL STORAGE e.g (employees, projects, proposals)
         * @param type
         * @param id
         * @returns {Promise<null>}
         */
        async getOne (type, id) {
            await this.$store.dispatch('cached/getListOne', { type: type, id: id })
            return getCachedStringData(this.lists[type], id)
        },

        async getOneForced (type, id) {
            await this.$store.dispatch('cached/getListOne', { type: type, id: id, forced: true })
            return getCachedStringData(this.lists[type], id)
        },

        /**
         * GET ONE RECORD FROM LOCAL STORAGE BY EMAIL e.g (employees, projects, proposals)
         * @param type
         * @param email
         * @returns {Promise<null>}
         */
        async getOneByEmail (type, email) {
            await this.$store.dispatch('cached/getList', type)
            email = toString(email)
            let index = findIndex(this.lists[type], (o) => toString(o.email) === email)
            if (index > -1) {
                return this.lists[type][index]
            }
            return index
        },

        /**
         * Finds contact in cache list that is attached to some company
         * @param email
         * @returns {Promise<null>}
         */
        async findAttachedToCompanyContactByEmail (email) {
            await this.$store.dispatch('cached/getList', 'contacts')
            email = toString(email)
            let index = findIndex(this.lists['contacts'], (o) => toString(o.email) === email && !isEmpty(o.contact_mongo))
            if (index > -1) {
                return this.lists['contacts'][index]
            }
            return index
        },

        /**
         * GET ONE RECORD FROM LOCAL STORAGE BY custom element e.g (employees, projects, proposals)
         * @param type
         * @param by
         * @param id
         * @returns {Promise<null>}
         */
        async getOneBy (type, by, id) {
            await this.$store.dispatch('cached/getList', type)
            let index = findIndex(this.lists[type], (o) => o[by] === id)
            if (index > -1) {
                return this.lists[type][index]
            }
            return index
        },

        /**
         * Refresh project
         * @param id
         * @return {Promise<void>}
         */
        async cachedSetProject (id) {
            const projectObj = await this.getOne('projects', id)
            await this.$store.dispatch('project/setProject', projectObj)
        }
    }
}

export default cachedListMixin
