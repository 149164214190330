import { defineComponent } from 'vue'
import { appPackageViewStore } from '../../../stores/appPackageView'
import { mapState } from 'pinia'
import AppPackageCreateEdit from '../../../components/app-package-view/create-edit/edit-details/AppPackageCreateEdit.vue'
import DetailsSection from '@/io-modules/invoices/components/app-package-view/app-package-details/details-section/DetailsSection.vue'
import FinancialPanel from '@/io-modules/invoices/components/app-package-view/app-package-details/financial-panel/FinancialPanel.vue'
import AttachedInvoicesTable from '../../../components/app-package-view/app-package-details/attached-invoices-table/AttachedInvoicesTable.vue'
import ProgressBar from '@/components/atoms/progress-bar/ProgressBar.vue'
import { ProgressBarItemInterface } from '@/components/atoms/progress-bar/ProgressBarItemInterface'

export default defineComponent({
    name: 'AppPackageDetails',
    components: {
        AppPackageCreateEdit,
        DetailsSection,
        FinancialPanel,
        AttachedInvoicesTable,
        ProgressBar,
    },
    computed: {
        ...mapState(appPackageViewStore, ['isEditingMode', 'appPackage', 'attachedInvoices', 'isDraft']),
        
        progressBarData (): ProgressBarItemInterface[] {
            return [
                {
                    value: this.calculateProgressBar(this.attachedInvoices.number_of_invoices_internally_approved, this.attachedInvoices.number_of_invoices),
                    color: 'var(--accent)',
                }
            ]
        },

        numberOfInvoices (): number {
            return this.attachedInvoices ? Number(this.attachedInvoices.number_of_invoices) : 0
        },
    },

    methods: {
        calculateProgressBar (complete: number, required: number): number {
            return (complete / required) * 100
        },
    }
})
