<template>
    <popup class="io-add-invoice-dev io-change-order-modal" :width="600">
        <template #header>
            <h1 v-if="currentStep === STEPS.retentionSubtype">Add Retention Invoice</h1>
            <h1 v-else>Add Invoice</h1>
            <div class="io-popup-close" @click.prevent="closeModal"><i class="fas fa-times"></i></div>
        </template>
        <template #default>
            <template v-if="loading">
                <div class="io-loader-small"></div>
            </template>
            <template v-else>
                <div class="io-change-orders">
                    <template v-if="currentStep === STEPS.type">
                        <div
                            v-if="showMyInvoiceOption"
                            class="io-select-type io-f-column"
                            :class="{ 'io-selected' : selectedInvoice === 'my'}"
                            @click="selectInvoice('my')"
                        >
                            <div class="io-f-row io-type-row">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30"><g data-v-776cc5f8="" fill="none" fill-rule="evenodd"><path data-v-776cc5f8="" fill="#E6EBF7" d="M0 30V0l4 4 4-4 4 4 4-4 4 4 4-4v30l-4-4-4 4-4-4-4 4-4-4z" class="io-refresh-bg"/> <path data-v-776cc5f8="" fill="#9598A5" d="M14.732 11.304c0 1.26-1.123 2.27-2.463 2.158-1.188-.099-2.059-1.128-2.059-2.27v-.642c0-1.143.871-2.171 2.059-2.27 1.34-.112 2.463.897 2.463 2.157v.867zM13.66 15.468l-.755.866a.582.582 0 0 1-.867 0l-.756-.866a1.148 1.148 0 0 0-1.292-.31c-2.016.841-3.476 2.676-3.705 4.86a.557.557 0 0 0 .566.603h11.241a.557.557 0 0 0 .565-.603c-.229-2.184-1.689-4.019-3.705-4.86a1.148 1.148 0 0 0-1.291.31z" class="io-icon-inside-fill"></path></g></svg>
                                <div class="io-text-type">
                                    <h3>My Invoice</h3>
                                    <p>Add an invoice based on your contract to your client.</p>
                                </div>
                                <div class="io-f-row io-row-btns">
                                    <div class="io-arrow-holder"><i class="io-arrow-icon io-black fas fa-long-arrow-alt-up"></i></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.keys(vendorsList).length" class="io-select-type io-f-column" :class="{ 'io-selected' : selectedInvoice === 'contracted'}" @click="selectInvoice('contracted')">
                            <div class="io-f-row io-type-row">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30"><g data-v-776cc5f8="" fill="none" fill-rule="evenodd"><path data-v-776cc5f8="" fill="#E6EBF7" d="M0 30V0l4 4 4-4 4 4 4-4 4 4 4-4v30l-4-4-4 4-4-4-4 4-4-4z" class="io-refresh-bg"/> <path data-v-776cc5f8="" fill="#9598A5" d="M14.732 11.304c0 1.26-1.123 2.27-2.463 2.158-1.188-.099-2.059-1.128-2.059-2.27v-.642c0-1.143.871-2.171 2.059-2.27 1.34-.112 2.463.897 2.463 2.157v.867zM13.66 15.468l-.755.866a.582.582 0 0 1-.867 0l-.756-.866a1.148 1.148 0 0 0-1.292-.31c-2.016.841-3.476 2.676-3.705 4.86a.557.557 0 0 0 .566.603h11.241a.557.557 0 0 0 .565-.603c-.229-2.184-1.689-4.019-3.705-4.86a1.148 1.148 0 0 0-1.291.31z" class="io-icon-inside-fill"></path></g></svg>
                                <div class="io-text-type">
                                    <h3 v-if="appAvail([appTypes.TYPE_DEV, appTypes.TYPE_AC, appTypes.TYPE_OWNER])">Vendor Invoice</h3>
                                    <h3 v-else>Subcontractor Invoice</h3>
                                    <p v-if="appAvail([appTypes.TYPE_DEV, appTypes.TYPE_AC, appTypes.TYPE_OWNER])">Add an invoice on behalf of a vendor that is based on the contract you have with that vendor.</p>
                                    <p v-else>Add an invoice on behalf of a subcontractor that is based on the contract you have with that subcontractor.</p>
                                </div>
                                <div class="io-f-row io-row-btns">
                                    <div class="io-arrow-holder"><i class="io-arrow-icon io-black fas fa-long-arrow-alt-up"></i></div>
                                </div>
                            </div>
                        </div>
                        <div class="io-select-type io-f-column" :class="{ 'io-selected' : selectedInvoice === 'non-contracted'}" @click="selectInvoice('non-contracted')">
                            <div class="io-f-row io-type-row">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30"><g data-v-776cc5f8="" fill="none" fill-rule="evenodd"><path data-v-776cc5f8="" fill="#E6EBF7" d="M0 30V0l4 4 4-4 4 4 4-4 4 4 4-4v30l-4-4-4 4-4-4-4 4-4-4z" class="io-refresh-bg"/> <path data-v-776cc5f8="" fill="#9598A5" d="M14.732 11.304c0 1.26-1.123 2.27-2.463 2.158-1.188-.099-2.059-1.128-2.059-2.27v-.642c0-1.143.871-2.171 2.059-2.27 1.34-.112 2.463.897 2.463 2.157v.867zM13.66 15.468l-.755.866a.582.582 0 0 1-.867 0l-.756-.866a1.148 1.148 0 0 0-1.292-.31c-2.016.841-3.476 2.676-3.705 4.86a.557.557 0 0 0 .566.603h11.241a.557.557 0 0 0 .565-.603c-.229-2.184-1.689-4.019-3.705-4.86a1.148 1.148 0 0 0-1.291.31z" class="io-icon-inside-fill"></path></g></svg>
                                <div class="io-text-type">
                                    <h3>Non-Contracted Invoice</h3>
                                    <p>Add an invoice for a vendor or service you do not have a contract with. Example: Utilities, Internet, misc charges...</p>
                                </div>
                                <div class="io-f-row io-row-btns">
                                    <div class="io-arrow-holder"><i class="io-arrow-icon io-black fas fa-long-arrow-alt-up"></i></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showRetentionInvoiceOption" class="io-select-type io-f-column" :class="{ 'io-selected' : selectedInvoice === 'retention'}" @click="selectInvoice('retention')">
                            <div class="io-f-row io-type-row">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30"><g data-v-776cc5f8="" fill="none" fill-rule="evenodd"><path data-v-776cc5f8="" fill="#E6EBF7" d="M0 30V0l4 4 4-4 4 4 4-4 4 4 4-4v30l-4-4-4 4-4-4-4 4-4-4z" class="io-refresh-bg"/> <path data-v-776cc5f8="" fill="#9598A5" d="M14.732 11.304c0 1.26-1.123 2.27-2.463 2.158-1.188-.099-2.059-1.128-2.059-2.27v-.642c0-1.143.871-2.171 2.059-2.27 1.34-.112 2.463.897 2.463 2.157v.867zM13.66 15.468l-.755.866a.582.582 0 0 1-.867 0l-.756-.866a1.148 1.148 0 0 0-1.292-.31c-2.016.841-3.476 2.676-3.705 4.86a.557.557 0 0 0 .566.603h11.241a.557.557 0 0 0 .565-.603c-.229-2.184-1.689-4.019-3.705-4.86a1.148 1.148 0 0 0-1.291.31z" class="io-icon-inside-fill"></path></g></svg>
                                <div class="io-text-type">
                                    <h3>Retention Invoice</h3>
                                    <p>Add a retention invoice for, a) your contract to your client, or b) on behalf of a vendor, that shall solely reduce the retention value of the contract. NOTE: This will change the default contract retention % on all subsequent invoices.</p>
                                </div>
                                <div class="io-f-row io-row-btns">
                                    <div class="io-arrow-holder"><i class="io-arrow-icon io-black fas fa-long-arrow-alt-up"></i></div>
                                </div>
                            </div>
                        </div>
                    </template>


                    <template v-else-if="currentStep === STEPS.retentionSubtype">
                        <div v-if="projectHasContractExecuted" class="io-select-type io-f-column" :class="{ 'io-selected' : selectedInvoiceSubType === RETENTION_TYPES.own}" @click="selectedInvoiceSubType = RETENTION_TYPES.own">
                            <div class="io-f-row io-type-row">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30"><g data-v-776cc5f8="" fill="none" fill-rule="evenodd"><path data-v-776cc5f8="" fill="#E6EBF7" d="M0 30V0l4 4 4-4 4 4 4-4 4 4 4-4v30l-4-4-4 4-4-4-4 4-4-4z" class="io-refresh-bg"/> <path data-v-776cc5f8="" fill="#9598A5" d="M14.732 11.304c0 1.26-1.123 2.27-2.463 2.158-1.188-.099-2.059-1.128-2.059-2.27v-.642c0-1.143.871-2.171 2.059-2.27 1.34-.112 2.463.897 2.463 2.157v.867zM13.66 15.468l-.755.866a.582.582 0 0 1-.867 0l-.756-.866a1.148 1.148 0 0 0-1.292-.31c-2.016.841-3.476 2.676-3.705 4.86a.557.557 0 0 0 .566.603h11.241a.557.557 0 0 0 .565-.603c-.229-2.184-1.689-4.019-3.705-4.86a1.148 1.148 0 0 0-1.291.31z" class="io-icon-inside-fill"></path></g></svg>
                                <div class="io-text-type">
                                    <h3>My Retention Invoice</h3>
                                    <p>Add an invoice based on your contract to your client.</p>
                                </div>
                                <div class="io-f-row io-row-btns">
                                    <div class="io-arrow-holder"><i class="io-arrow-icon io-black fas fa-long-arrow-alt-up"></i></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="Object.keys(vendorsList).length" class="io-select-type io-f-column" :class="{ 'io-selected' : selectedInvoiceSubType === RETENTION_TYPES.vendor}" @click="selectedInvoiceSubType = RETENTION_TYPES.vendor">
                            <div class="io-f-row io-type-row">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30"><g data-v-776cc5f8="" fill="none" fill-rule="evenodd"><path data-v-776cc5f8="" fill="#E6EBF7" d="M0 30V0l4 4 4-4 4 4 4-4 4 4 4-4v30l-4-4-4 4-4-4-4 4-4-4z" class="io-refresh-bg"/> <path data-v-776cc5f8="" fill="#9598A5" d="M14.732 11.304c0 1.26-1.123 2.27-2.463 2.158-1.188-.099-2.059-1.128-2.059-2.27v-.642c0-1.143.871-2.171 2.059-2.27 1.34-.112 2.463.897 2.463 2.157v.867zM13.66 15.468l-.755.866a.582.582 0 0 1-.867 0l-.756-.866a1.148 1.148 0 0 0-1.292-.31c-2.016.841-3.476 2.676-3.705 4.86a.557.557 0 0 0 .566.603h11.241a.557.557 0 0 0 .565-.603c-.229-2.184-1.689-4.019-3.705-4.86a1.148 1.148 0 0 0-1.291.31z" class="io-icon-inside-fill"></path></g></svg>
                                <div class="io-text-type">
                                    <h3>Vendor Retention Invoice</h3>
                                    <p>Add an invoice on behalf of a subcontractor that is based on the contract you have with that vendor.</p>
                                </div>
                                <div class="io-f-row io-row-btns">
                                    <div class="io-arrow-holder"><i class="io-arrow-icon io-black fas fa-long-arrow-alt-up"></i></div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </template>
        <template #footer>
            <div class="io-add-invoice-footer">
                <a v-if="loadingButton" class="io-btn io-btn-primary"><div class="io-loader-small"></div></a>
                <a v-if="selectedInvoice === 'retention' && currentStep === STEPS.retentionSubtype" class="io-btn io-btn-secondary-compact" @click="goToPrevStep">Back</a>
                <a v-else class="io-btn io-btn-secondary-compact" @click.prevent="closeModal">Cancel</a>

                <template v-if="!loadingButton">
                    <a v-if="selectedInvoice === 'retention' && currentStep === STEPS.type" class="io-btn io-btn-primary" @click="goToNextStep">Next Step</a>
                    <a v-else class="io-btn io-btn-primary" @click="addInvoice">Next Step</a>
                </template>
            </div>
        </template>
    </popup>
</template>

<script src="./add-invoice-options.js"></script>
<style lang="scss" scoped>
    .io-select-type {
        border: 1px solid #f0f3ff;
        width: calc(100% - 8px);
        margin-left: auto;
        margin-right: auto;
        padding: 16px;
        margin-bottom: 20px;
        align-items: flex-start;
        min-height: 152px;
        justify-content: center;
        position: relative;
        transition: all .3s ease-in-out;
        cursor: pointer;

        h3 {
            margin-bottom: 0 !important;
            font-size: 17px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: -0.3px;
            color: #1c1c1c;
            padding-left: 0;
            margin-top: 7px;
        }

        .io-type-row {
            align-items: flex-start;
        }

        svg {
            flex-shrink: 0;
        }

        p {
            font-size: 14px;
            line-height: 1.57;
            color: #797d85;
            padding: 16px 0;
            padding-top: 5px;
            padding-bottom: 0;
            width: 510px;
        }

        .io-text-type {
            max-width: 430px;
            margin-left: 16px;
        }

        .io-row-btns {
            position: absolute;
            right: 24px;
            top: 40%;
            left: auto;
            width: 40px;

            .io-arrow-holder {
                width: 40px;
                height: 40px;
                border: solid 1px #e7f3f3;
                border-radius: 20px;
                line-height: 40px;
                text-align: center;
                background-color: rgba(231, 243, 243, 0.4);
            }
        }
    }
    .io-selected,
    .io-select-type:hover {
        box-shadow: 0 0 3px 0 var(--accent);
        transform: translateY(-5px);
        opacity: 1;
    }
    .io-add-invoice-footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        & a:not(:last-child) {
            margin-right: 16px;
        }
    }
</style>
