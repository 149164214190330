import PayAppClient from '@/api/payapp/payappClient'
import iconLibrary from '@/base/iconLibrary.js'
import { isArray } from 'lodash'

export const resetState = function ({ commit, dispatch }) {
    commit('RESET_STATE')
}

export const setPeriod = function ({ commit, dispatch }, value) {
    commit('SET_PERIOD', value || {})
}

export const setPeriods = function ({ commit, dispatch }, value) {
    commit('SET_PERIODS', value || {})
}

export const setProviders = function ({ commit, dispatch }, value) {
    commit('SET_PROVIDERS', value || {})
}

export const setAttenders = function ({ commit, dispatch }, value) {
    commit('SET_ATTENDERS', value || {})
}

export const setActiveFunding = function ({ commit, dispatch }, value) {
    commit('SET_ACTIVE_FUNDING', value || {})
}

export const setHassettings = function ({ commit, dispatch }, value) {
    commit('SET_HASSETTINGS', value || {})
}

export const setLinesVisibleBy = function ({ commit, dispatch }, value) {
    commit('SET_LINES_VISIBLE_BY', value || {})
}

export const setTableViewMode = function ({ commit, dispatch }, value) {
    commit('SET_TABLE_VIEW_MODE', value || {})
}

export const setColumnsViewMode = function ({ commit, dispatch }, value) {
    commit('SET_COLUMNS_VIEW_MODE', value || {})
}

export const setFundingSources = function ({ commit, dispatch }, value) {
    commit('SET_FUNDING_SOURCES', value || [])
}

export const removePeriods = function ({ commit, dispatch }, value) {
    commit('REMOVE_PERIODS', value || {})
}

export const addPeriods = function ({ commit, dispatch }, value) {
    commit('ADD_PERIODS', value || {})
}

export const setPayapp = function ({ commit, dispatch }, value) {
    commit('SET_PAYAPP', value || {})
}

export const setSpecific = function ({ commit, dispatch }, value) {
    commit('SET_SPECIFIC', value || {})
}




export const updatePayappBasic = function ({ commit, dispatch }, value) {
    commit('UPDATE_PAYAPP_BASIC', value || {})
}

export const setPayappEncounteredErrorOnInvoiceSubmission = function ({ commit, dispatch }, value) {
    commit('SET_PAYAPP_ENCOUNTERED_ERROR_ON_INVOICE_SUBMISSION', value)
}

export const setPayappId = function ({ commit, dispatch }, value) {
    commit('SET_PAYAPP_ID', value)
}

export const updatePhase = function ({ commit, dispatch }, value) {
    commit('UPDATE_PHASE', value || {})
}

export const setContractors = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACTORS', value || {})
}

export const setRouter = function ({ commit, dispatch }, value) {
    commit('SET_ROUTER', value || {})
}

export const setContractor = function ({ commit, dispatch }, value) {
    commit('SET_CONTRACTOR', value || {})
}

export const setModal = function ({ commit, dispatch }, value) {
    commit('SET_MODAL', value || false)
}

export const setShowPayappTabs = function ({ commit, dispatch }, value) {
    commit('SET_SHOW_PAYAPP_TABS', value || false)
}

export const setLoaded = function ({ commit, dispatch }, value) {
    commit('SET_LOADED', value)
}

export const setInvoiceAmount = function ({ commit, dispatch }, value) {
    commit('SET_INVOICE_AMOUNT', value)
}

export const setInvoiceAmountNet = function ({ commit, dispatch }, value) {
    commit('SET_INVOICE_AMOUNT_NET', value)
}

export const setCalculatedTotal = function ({ commit, dispatch }, value) {
    commit('SET_CALCULATED_TOTAL', value)
}

export const calculatePhases = function ({ commit }, phases) {
    const calculatedValues = {
        current_application: 0,
        previous_application: 0,
        material_stored_offsite: 0,
        total_completed_stored_amount: 0,
        total_retention_amount: 0,
        invoice_retention_amount: 0,
        net_current_application: 0,
        total_payments_incl_process_amount: 0
    }

    function sumUpNestedItems (task) {
        calculatedValues.current_application = iconLibrary.methods.add([
            calculatedValues.current_application,
            task._current_application
        ])
        calculatedValues.previous_application = iconLibrary.methods.add([
            calculatedValues.previous_application,
            task._previous_application
        ])
        calculatedValues.material_stored_offsite = iconLibrary.methods.add([
            calculatedValues.material_stored_offsite,
            task._material_stored_offsite
        ])

        if (task.tasks && task.tasks.length) {
            calculatedValues.total_completed_stored_amount = iconLibrary.methods.add([
                calculatedValues.total_completed_stored_amount,
                task._total_completed_stored_amount
            ])
        } else {
            calculatedValues.total_completed_stored_amount = iconLibrary.methods.add([
                calculatedValues.total_completed_stored_amount,
                task._previous_application,
                task._material_stored_offsite,
                task._current_application
            ])
        }

        calculatedValues.total_retention_amount = iconLibrary.methods.add([
            calculatedValues.total_retention_amount,
            task._total_retention_amount
        ])
        calculatedValues.invoice_retention_amount = iconLibrary.methods.add([
            calculatedValues.invoice_retention_amount,
            task._current_retention_amount * -1
        ])
        calculatedValues.net_current_application = iconLibrary.methods.add([
            calculatedValues.net_current_application,
            task._net_current_application && (!task._current_application && !task._material_stored_offsite && !task._total_retention_amount && !task._retention_invoice_amount) ? 0 : task._net_current_application
        ])
        calculatedValues.total_payments_incl_process_amount = iconLibrary.methods.add([
            calculatedValues.total_payments_incl_process_amount,
            task._net_current_application,
            iconLibrary.methods.sub([task._previous_application, task._previous_retention_amount])
        ])
    }

    phases.forEach(phase => {
        phase.tasks.forEach(task => {
            if (task.tasks && task.tasks.length) {
                task.tasks.forEach(subTask => sumUpNestedItems(subTask))
            } else {
                sumUpNestedItems(task)
            }
        })
    })

    Object.entries(calculatedValues).forEach(([key, value]) => {
        commit('SET_CALCULATED_TOTAL', { field: key, value: value })
    })
}

/**
 *
 * @param commit
 * @param dispatch
 * @param value
 */
export const setRetentionAmount = function ({ commit, dispatch }, value) {
    commit('SET_RETENTION_AMOUNT', value)
}

export const unsetLoaded = function ({ commit, dispatch }, value) {
    commit('UNSET_LOADED', value)
}

export const loadPayApp = async ({ commit, dispatch }, obj) => {
    try {
        const { data } = await PayAppClient.getPayApp(obj.id, obj.pid)
        commit('SET_PAYAPP', data)
        commit('SET_PAYAPP_ID', obj.id)
        return true
    } catch (exception) {
        return false
    }
}

/**
 * Load billing periods
 *
 * @param commit
 * @param state
 * @param projectId
 * @return {Promise<boolean|*>}
 */
export const loadPeriods = async ({ commit, state }, projectId) => {
    commit('UNSET_LOADED', 'periods')
    try {
        const { data } = await PayAppClient.loadPeriods(projectId)
        commit('SET_PERIODS', data.feed)
        commit('SET_CATEGORIES', data.shared.categories)
        commit('SET_HASSETTINGS', data.has_settings)
        commit('SET_LOADED_PERIODS', projectId)
        return true
    } catch (exception) {
        return exception
    }
}


/**
 * Load project funding sources for modal
 * @param commit
 * @param state
 * @param projectMongoLocalId
 * @return {Promise<boolean|*>}
 */
export const getProjectFundingSources = async ({ commit, state }, projectMongoLocalId) => {
    try {
        const { data } = await PayAppClient.loadProjectFundingSources(projectMongoLocalId)
        commit('SET_MODAL_SOURCES_PROJECT_SOURCES', data.data)
        return true
    } catch (exception) {
        return exception
    }
}


/**
 * Save payapp detaisl
 *
 * @param commit
 * @param state
 * @param projectId
 * @return {Promise<boolean|*>}
 */
export const savePayappBasic = async ({ commit, state }, model) => {
    try {
        const { data } = await PayAppClient.savePayappBasic(state.payApp.id, model)
        commit('UPDATE_PAYAPP_BASIC', data.basic)
        return true
    } catch (exception) {
        return exception
    }
}



/**
 * Save payapp with action
 * @param commit
 * @param state
 * @param action
 * @return {Promise<boolean|*>}
 */
export const savePayapp = async ({ commit, state }, action) => {
    try {
        await PayAppClient.savePayapp(state.payApp, action)
        commit('SET_SAVE_FLAG')
        return true
    } catch (exception) {
        return exception
    }
}

/**
 * Helper to validate task
 *
 * @param task
 * @return {{result: boolean, message: string}}
 */
function validateTask (task) {
    // Q > H
    if (task.scheduleValue < 0 && task._total_completed_stored_amount < task.scheduleValue) {
        return {
            result: false,
            id: task.id,
            message: `${task.description}: Total Completed & Stored Amount can not be lower than Scheduled Value`
        }
    } else if (task.scheduleValue >= 0 && task._total_completed_stored_amount > task.scheduleValue) {
        return {
            result: false,
            id: task.id,
            message: `${task.description}: Total Completed & Stored Amount can not be higher than Scheduled Value`
        }
    } else if (task.scheduleValue < 0 && task._total_completed_stored_amount > 0) {
        return {
            result: false,
            id: task.id,
            message: `${task.description}: Total Completed & Stored Amount can not be higher than Scheduled Value`
        }
    }

    if (isArray(task.tasks)) {
        for (let subTaskId in task.tasks) {
            const subTask = task.tasks[subTaskId]

            const validation = validateTask(subTask)
            if (!validation.result) {
                return validation
            }
        }
    }

    return {
        result: true
    }
}


/**
 * Validate phases and tasks
 * @param commit
 * @param state
 * @return {{result: boolean}|boolean}
 */
export const validatePhases = function ({ commit, state }) {
    if (!state.payApp.quoted_items || !isArray(state.payApp.quoted_items.phases)) {
        return {
            result: true
        }
    }

    for (let phaseId in state.payApp.quoted_items.phases) {
        const phase = state.payApp.quoted_items.phases[phaseId]
        for (let taskId in phase.tasks) {
            const result = validateTask(phase.tasks[taskId])
            if (!result.result) {
                return result
            }
        }
    }

    return {
        result: true
    }
}


/**
 * Calculate tasks needed our attention
 * Without decision / not approved
 *
 * @param commit
 * @param state
 * @param action
 * @return {Promise<boolean|*>}
 */
export const recalculateAdjustedTasks = async ({ commit, state }) => {
    let numTasks = 0
    let numTasksNeedAttention = 0
    let amountAdjusted = 0

    const isSender = state.payApp.flags.is_sender_current
    const isReceiver = state.payApp.flags.is_receiver_current

    const checkTask = function (task) {
        const adjust = task.adjust
        // Not adjustable, skip
        if (!adjust) {
            return false
        }
        // If sender make decision
        if (isSender && (adjust.accepted.sender || adjust.revised.sender)) {
            return false
        }
        // If receiver still want changes
        if (isReceiver && adjust.revised.receiver) {
            return true
        }
        // If receiver make decision or approved by sender
        if (isReceiver && (adjust.accepted.receiver || adjust.accepted.sender)) {
            return false
        }
        return true
    }

    const calculateNumAttention = (item, phase) => {
        if (checkTask(item)) {
            phase.numAdjustedTasks++
        }
        if (item.needAttention) {
            phase.numNeedAttentionTasks++
        }
        if (item.adjust) {
            amountAdjusted += parseInt(item.adjust_value) || 0
        }
    }

    // Calculate by all tasks
    state.payApp.quoted_items.phases.forEach(phase => {
        phase.numNeedAttentionTasks = 0
        phase.numAdjustedTasks = 0

        phase.tasks.forEach(task => {
            if (isArray(task.tasks) && task.tasks.length) {
                task.tasks.forEach(subTask => {
                    if (isArray(subTask.tasks) && subTask.tasks.length) {
                        task.tasks.forEach(item => {
                            calculateNumAttention(item, phase)
                        })
                    } else {
                        calculateNumAttention(subTask, phase)
                    }
                })
            } else {
                calculateNumAttention(task, phase)
            }
        })

        numTasks += phase.numAdjustedTasks
        numTasksNeedAttention += phase.numNeedAttentionTasks
    })

    commit('SET_ADJUSTED_TASKS', numTasks)
    commit('SET_NEED_ATTENTION_TASKS', numTasksNeedAttention)
    commit('SET_ADJUSTMENT_VALUE', amountAdjusted)
    commit('SET_ADJUSTMENT_RECALCULATION_COMPLETED', true)
}

export const updatePhases = ({ dispatch, state, getters }, data) => {
    state.payApp.quoted_items.phases.forEach((item, index) =>
        {
            const phase = data.find(phase => phase.id === item.id)
            if (phase) {
                state.payApp.quoted_items.phases[index] = phase
            }
        }
    )
}

export const calculateLineItems = ({ dispatch, state, getters }) => {
    if (state.payApp.flags.is_non_contracted) {
        const sum = state.payApp.specific.general_invoicable_items.reduce((sum, item) => sum + item.total, 0)

        dispatch('setCalculatedTotal', {
            field: 'net_current_application',
            value: sum
        })
        dispatch('setInvoiceAmount', sum)
        dispatch('setInvoiceAmountNet', sum)

        return
    }

    dispatch('calculatePhases', getters.getExtractedPhases)
    dispatch('setInvoiceAmount', state.payApp.flags.is_retention_invoice
        ? state.calculatedTotals.invoice_retention_amount
        : state.calculatedTotals.current_application + state.calculatedTotals.material_stored_offsite)

    dispatch('setInvoiceAmountNet', state.payApp.flags.is_retention_invoice
        ? state.calculatedTotals.invoice_retention_amount
        : state.calculatedTotals.net_current_application)


    dispatch('setRetentionAmount', state.calculatedTotals.total_retention_amount)
}

export const setPackageDetails = function ({ commit, dispatch }, value) {
    commit('SET_PACKAGE_DETAILS', value || {})
}

export const updatePackageDetails = function ({ commit, dispatch }, value) {
    commit('UPDATE_PACKAGE_DETAILS', value || {})
}

export const updatePackageStatus = function ({ commit, dispatch }, value) {
    commit('UPDATE_PACKAGE_STATUS', value || {})
}

/**
 *
 * @param commit
 * @param dispatch
 * @param value
 */
export const setModalInvoiceContractData = function ({ commit, dispatch }, value) {
    commit('SET_MODAL_INVOICE_CONTRACT_DATA', value || {})
}

/**
 *
 * @param commit
 * @param dispatch
 */
export const resetModalInvoice = function ({ commit, dispatch }) {
    commit('RESET_MODAL_INVOICE')
}

/**
 *
 * @param commit
 * @param dispatch
 */
export const resetModalPackagePayments = function ({ commit, dispatch }) {
    commit('RESET_MODAL_PACKAGE_PAYMENTS')
}

/**
 *
 * @param commit
 * @param dispatch
 */
export const resetModalSources = function ({ commit, dispatch }) {
    commit('RESET_MODAL_SOURCES')
}

/**
 *
 * @param commit
 * @param config
 */
export const modalInvoiceEnable = function ({ commit }, config = {}) {
    commit('RESET_MODAL_INVOICE')
    commit('ENABLE_MODAL_INVOICE', config)
}


/**
 *
 * @param commit
 * @param config
 */
export const modalInvoicePaymentsEnable = function ({ commit }, config = {}) {
    commit('MODAL_INVOICE_PAYMENTS_RESET')
    commit('MODAL_INVOICE_PAYMENTS_ENABLE', config)
}


/**
 *
 * @param commit
 * @param dispatch
 */
export const modalInvoicePaymentsReset = function ({ commit, dispatch }) {
    commit('MODAL_INVOICE_PAYMENTS_RESET')
}



/**
 *
 * @param commit
 * @param config
 */
export const modalPackagePaymentsEnable = function ({ commit }, config = {}) {
    commit('RESET_MODAL_PACKAGE_PAYMENTS')
    commit('ENABLE_MODAL_PACKAGE_PAYMENTS', config)
}

/**
 *
 * @param commit
 * @param isVendor
 */
export const modalInvoiceDisable = function ({ commit }) {
    commit('RESET_MODAL_INVOICE')
}

/**
 *
 * @param commit
 * @param isVendor
 */
export const modalPackagePaymentsDisable = function ({ commit }) {
    commit('RESET_MODAL_PACKAGE_PAYMENTS')
}

/**
 *
 * @param commit
 * @param isVendor
 */
export const modalSourcesSetData = function ({ commit }, data) {
    commit('MODAL_SOURCES_SET_DATA', data)
}



/**
 *
 * @param commit
 * @param isVendor
 */
export const loadProviders = async function ({ commit }) {
    try {
        const { data } = await PayAppClient.getProviderList()
        commit('SET_PROVIDERS', data.feed)
        return true
    } catch (exception) {
        return exception
    }
}


/**
 *
 * @param commit
 * @param state
 * @param projectObj
 * @return {Promise<boolean|*>}
 */
export const getNewModalInvoiceData = async ({ commit, getters }, projectObj) => {
    try {
        if (getters.modalAddIsInvoiceMy) {
            const { data } = await PayAppClient.invoiceGetContract(projectObj._contract_id)
            commit('SET_MODAL_INVOICE_CONTRACT_DATA', data.contract)
        } else {
            const { data } = await PayAppClient.invoiceGetVendors(projectObj._id)
            commit('SET_MODAL_INVOICE_VENDORS', data.feed)
        }
        return true
    } catch (exception) {
        return exception
    }
}

export const setShowPopupManualApproval = ({ commit }, value) => {
    commit('SET_SHOW_POPUP_MANUAL_APPROVAL', value)
}

export const setShowPopupManualRejection = ({ commit }, value) => {
    commit('SET_SHOW_POPUP_MANUAL_REJECTION', value)
}

export const setShowPopupClientApproval = ({ commit }, value) => {
    commit('SET_SHOW_POPUP_CLIENT_APPROVAL', value)
}

export const setShowPopupUserApproval = ({ commit }, value) => {
    commit('SET_SHOW_POPUP_USER_APPROVAL', value)
}

export const setShowPopupUserRejection = ({ commit }, value) => {
    commit('SET_SHOW_POPUP_USER_REJECTION', value)
}

export const setDependents = ({ commit }, value) => {
    commit('SET_DEPENDENTS', value)
}

export const showRevisionPopup = ({ commit }, value) => {
    commit('SET_REVISION_POPUP', {
        enabled: true,
        type: value.type,
        action: value.action
    })
}

export const closeRevisionPopup = ({ commit }) => {
    commit('SET_REVISION_POPUP', {
        enabled: false,
        type: null,
        action: null
    })
}

export const showReturnToDraftPopup = ({ commit }, action) => {
    commit('SET_RETURN_TO_DRAFT_POPUP', {
        enabled: true,
        action
    })
}

export const closeReturnToDraftPopup = ({ commit }) => {
    commit('SET_RETURN_TO_DRAFT_POPUP', {
        enabled: false,
        action: null
    })
}

export const setPurchaseOrder = ({ commit }, value) => {
    commit('SET_PURCHASE_ORDER', value)
}

export const setBannerAccountingMessages = ({ commit }, value) => {
    commit('SET_BANNER_ACCOUNTING_MESSAGES', value)
}

export const setCanSaveFundingSources = ({ commit }, value) => {
    commit('SET_CAN_SAVE_SELECTED_SOURCES', value)
}
