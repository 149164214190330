<template>
    <ClientApprovalCard
        v-if="isOwnerRep && appPackage.client_approval"
        :client="appPackage.client_approval?.client" 
        :is-client-off-system="isClientOffSystem" 
        :title="$t('Client Approval')"
        :is-submitted-to-client="appPackage.client_approval?.submitted_to_client"
        :date-submitted="appPackage.client_approval?.submitted_to_client"
        :actions="repApprovalActions"
        :date-approved-or-rejected="clientApprovalDate"
        :status="appPackage.client_approval?.status"
    />
    <ClientApprovalCard
        v-else-if="isOwner && appPackage.date_received"
        :title="$t('Approval')"
        :actions="ownerApprovalActions"
        :date-received="$filters.parseDate(appPackage.date_received)"
    >
        <template #default>
            <div
                v-if="resourceApprovals?.status === ApprovalStatus.APPROVED"
                class="io-single-approval-status io-single-approval-status--approved"
            >
                <i class="icon-check-circle-2-2"></i>
                <div class="io-single-approval-status__holder">
                    {{ $t('Approved at {date}', { date: $filters.parseDateTime(ownerApprovalDate) }) }}
                </div>
            </div>
            <div
                v-else-if="resourceApprovals?.status === ApprovalStatus.REJECTED"
                class="io-single-approval-status io-single-approval-status--rejected"
            >
                <i class="icon-cross-circle-2"></i>
                <div class="io-single-approval-status__holder">
                    {{ $t('Rejected at {date}', { date: $filters.parseDateTime(ownerApprovalDate) }) }}
                </div>
            </div>
        </template>
    </ClientApprovalCard>
</template>

<script lang="ts" src="./AppPackageApprovals.ts"></script>
<style lang="scss" src="./AppPackageApprovals.scss" scoped></style>