import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'

import { StampPayload, Stamp } from '@/io-modules/punch-lists/interfaces/Stamp'

export default {
    getPunchStamps: (projectId: string): AxiosPromise<Array<Stamp>> =>
        client.get(`/ca/punch-item/${ projectId }/project-punch-stamps`),

    savePunchStamps: (projectId: string, payload: StampPayload): AxiosPromise<Array<Stamp>> =>
        client.post(`/ca/punch-item/${ projectId }/project-punch-stamps`, payload),

    searchPunchStamps: (projectId: string, payload: { search?: string } = {}): AxiosPromise<Array<Stamp>> =>
        client.post(`/ca/punch-item/${ projectId }/project-punch-stamps/search`, payload),
}
