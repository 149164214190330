<template>
    <Table :headers="tableHeaders" :items="sheets">
        <template #tbody="{ sortedItems }">
            <tr
                v-for="(sheet, idx) in sortedItems"
                :key="sheet.id"
                class="hover-darken animate-pop-from-bottom text-main-lighten-1 cursor-pointer"
                :style="getCascadeAnimationDelay(idx)"
                @click="$emit('select', sheet)"
            >
                <td class="w-0">
                    <div class="flex gap-8-px">
                        <IOThumbnail :url="sheet.thumbnail" position="right" height="50">
                            <i class="icon-eye text-main-lighten-3 text-base-px" />
                        </IOThumbnail>

                        <i
                            class="text-base-px"
                            :class="sheet.is_favorite ? 'icon-star-2 text-warning' : 'icon-star text-main-lighten-3'"/>
                    </div>
                </td>

                <td class="w-0">{{ sheet.page_label}}</td>

                <td class="w-2-4">{{ sheet.page_title}}</td>

                <td>
                    <span v-if="sheet.page_category">{{ sheet.page_category }}</span>

                    <span v-else class="text-main-lighten-3">{{ $t('Not specified') }}</span>
                </td>

                <td class="whitespace-nowrap">{{ $filters.formatDateOnly(sheet.drawing_set_issue_date) }}</td>

                <td class="whitespace-nowrap">{{ $filters.formatDateOnly(sheet.updated_at) }}</td>

                <td class="w-0 text-right">{{ sheet.current_version_number }}</td>
            </tr>
        </template>
    </Table>
</template>

<script lang="ts" src="./SheetsListView.ts"></script>
<style lang="scss" src="./SheetsListView.scss" scoped></style>
