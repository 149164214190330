import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { paths } from '@/interfaces/api/apidefinitions'
import qs from '@/helpers/qs'
import ListFiltersListingParams from '@/io-modules/invoices/interfaces/invoices-list/ListFiltersListingParams'

export type CreateApplicationPackageRequest = paths['/api/v1/{subdomain}/{apptype}/application-packages/{project_local_id}/']['post']['requestBody']['content']['application/json']
export type UpdateApplicationPackageRequest = paths['/api/v1/{subdomain}/{apptype}/application-packages/{project_local_id}/{application_package_id}']['put']['requestBody']['content']['application/json']

export type AttachableInvoicesList = paths['/api/v1/{subdomain}/{apptype}/application-packages/{project_local_id}/invoices-attachable/{application_package_id}']['get']['responses']['200']['content']['application/json']
export type AttachableInvoice = paths['/api/v1/{subdomain}/{apptype}/application-packages/{project_local_id}/invoices-attachable/{application_package_id}']['get']['responses']['200']['content']['application/json']['invoices']
export type AttachedInvoicesResponse = paths['/api/v1/{subdomain}/{apptype}/application-packages/data/attached-invoices/{project_local_id}/{application_package_id}']['get']['responses']['200']['content']['application/json']
export type RejectApplicationRequest = paths['v1/{subdomain}/{apptype}/application-packages/{project_local_id}/{application_package_id}/reject']['post']['requestBody']['content']['application/json']
export type ContractValueSummaryResponse = paths['/api/v1/{subdomain}/{apptype}/application-packages/data/contract-value-summary/{project_local_id}/{application_package_id}']['get']['responses']['200']['content']['application/json']
export type ApplicationPackageCreationDetails = paths['/api/v1/{subdomain}/{apptype}/application-packages/{project_local_id}/creation']['get']['responses']['200']['content']['application/json']

export default {
    createApplicationPackage (projectMongoIdLocal: string, data: CreateApplicationPackageRequest): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.post(`/application-packages/${ projectMongoIdLocal }`, data)
    },

    updateApplicationPackage (projectMongoIdLocal: string, appPackageId: string, data: UpdateApplicationPackageRequest): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Application Package ID Provided!')
        }

        return client.put(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }`, data)
    },

    getApplicationPackageAvailableActions (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.get(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/available-actions`)
    },

    getApplicationPackageDetails (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.get(`/application-packages/data/details/${ projectMongoIdLocal }/${ appPackageId }`)
    },

    getApplicationPackageContractData (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<ContractValueSummaryResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.get(`/application-packages/data/contract-value-summary/${ projectMongoIdLocal }/${ appPackageId }`)
    },

    getApplicationPackageAttachedInvoices (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<AttachedInvoicesResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.get(`/application-packages/data/attached-invoices/${ projectMongoIdLocal }/${ appPackageId }`)
    },

    deleteApplicationPackage (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.delete(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }`)
    },

    getApplicationPackageMarkAsPaidList (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/${ appPackageId }/mark-as-paid-list`)
    },

    applicationPackageMarkAsPaid (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.post()
    },

    submitToClient (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.post(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/submit-to-client`)
    },

    submitToAccounting (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.post(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/submit-to-accounting`)
    },

    markAsSubmittedToClient (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.post(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/mark-as-submitted-to-client`)
    },

    revertToDraft (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.post(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/revert-to-draft`)
    },

    revertToPending (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.post(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/revert-to-pending`)
    },

    getApplicationPackageAttachableInvoices (projectMongoIdLocal: string, appPackageId: string|null, filters: ListFiltersListingParams = null): AxiosPromise<AttachableInvoicesList> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (null !== appPackageId) {
            return client.get(`/application-packages/${ projectMongoIdLocal }/invoices-attachable/${ appPackageId }`, {
                params: { filters },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            })
        } else {
            return client.get(`/application-packages/${ projectMongoIdLocal }/invoices-attachable`, {
                params: { filters },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }
            })
        }
    },

    getApplicationPackageAttachableInvoicesFilters (projectMongoIdLocal: string, appPackageId: string|null): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }
        if (null !== appPackageId) {
            return client.get(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/filter-values`)
        } else {
            return client.get(`/application-packages/${ projectMongoIdLocal }/filter-values`)
        }
    },

    getApplicationPackageCreationDetails (projectMongoIdLocal: string): AxiosPromise<ApplicationPackageCreationDetails> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/application-packages/${ projectMongoIdLocal }/creation`)
    },

    applicationPackageRejectInvoices (projectMongoIdLocal: string, appPackageId: string, data: RejectApplicationRequest): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.post(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/reject`, data)
    },

    getAppPackageTimeline (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.get(`/application-packages/${ projectMongoIdLocal }/${ appPackageId }/timeline`)
    },

    getAppPackagePDF (projectMongoIdLocal: string, appPackageId: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!appPackageId) {
            throw new Error('No Package ID Provided!')
        }

        return client.get(`/application-packages/${ projectMongoIdLocal }/application-package/${ appPackageId }/pdf`)
    },
}
