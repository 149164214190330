/**
 * Shows an indeterminate progress bar animation on the element.
 * Useful to indicate that something is being loaded.
 */

const classes = {
    overlay: 'io-progress-directive-overlay',
    track: 'io-progress-directive-track',
    thumb: 'io-progress-directive-thumb',
}

const style = document.createElement('style')
style.innerHTML = `
    .${ classes.overlay } {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.4) 0%,
            rgba(255, 255, 255, 0.8) 25%,
            rgba(255, 255, 255, 0.4) 50%,
            rgba(255, 255, 255, 0.8) 75%,
            rgba(255, 255, 255, 0.4) 100%
        );
        background-size: 300% 300%;
        animation: float 4s infinite linear;
    }

    .${ classes.track } {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: var(--darker-bg);
        overflow: hidden;
    }

    .${ classes.thumb } {
        width: 100%;
        height: 100%;
        background-color: var(--darken-green);
        animation: indeterminate 1s infinite linear;
        transform-origin: 0 50%;
    }

    @keyframes indeterminate {
        0% { transform: translateX(0) scaleX(0); }
        40% { transform: translateX(0) scaleX(0.4); }
        100% { transform: translateX(100%) scaleX(0.5); }
    }

    @keyframes float {
        0% { background-position: 0 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0 50%; }
    }
`

document.head.appendChild(style)

const show = (el: HTMLElement, zIndex: string): void => {
    const overlayZIndex = parseInt(zIndex) || 1
    const trackZIndex = overlayZIndex + 1
    const positionValid = ['absolute', 'fixed', 'relative', 'sticky'].includes(getComputedStyle(el).position)
    !positionValid && (el.style.position = 'relative')

    const overlay = document.createElement('div')
    overlay.classList.add(classes.overlay)
    overlay.style.zIndex = overlayZIndex.toString()

    const track = document.createElement('div')
    track.classList.add(classes.track)
    track.style.zIndex = trackZIndex.toString()

    const thumb = document.createElement('div')
    thumb.classList.add(classes.thumb)

    track.appendChild(thumb)

    el.appendChild(overlay)
    el.appendChild(track)
}

const hide = (el: HTMLElement): void => {
    el.style.position = ''
    const overlay = el.querySelector(`:scope > .${ classes.overlay }`)
    const track = el.querySelector(`:scope > .${ classes.track }`)

    overlay?.remove()
    track?.remove()
}

export default (el: HTMLElement, { value, oldValue, arg }): void => value !== oldValue
    ? value
        ? show(el, arg)
        : hide(el)
    : undefined
