<template>
    <div class="io-simple-file-list">
        <table>
            <thead>
                <tr>
                    <th class="aleft io-column-file-name" @click="changeSort('file_name')">
                        <span>{{ $t("File Name") }}</span>
                        <TableSortIcon :key="1" name="file_name" :sortBy="sortBy" :sortDir="sortDir"/>
                    </th>

                    <th class="aleft" @click="changeSort('file_size')">
                        <span>{{ $t("File Size") }}</span>
                        <TableSortIcon :key="2" name="file_size" :sortBy="sortBy" :sortDir="sortDir"/>
                    </th>

                    <th v-if="showCreatorName" class="aleft" @click="changeSort('creator_full_name')">
                        <span>{{ $t("Added by") }}</span>
                        <TableSortIcon :key="3" name="creator_full_name" :sortBy="sortBy" :sortDir="sortDir"/>
                    </th>

                    <th v-if="showUpdateDate" class="aleft" @click="changeSort('updated_at')">
                        <span>{{ $t("Last update") }}</span>
                        <TableSortIcon :key="4" name="updated_at" :sortBy="sortBy" :sortDir="sortDir"/>
                    </th>

                    <th v-if="showSharedYesNo" class="aleft">
                        <span>{{ $t("Shared") }}</span>
                    </th>

                    <th class="io-column-actions"></th>
                </tr>
            </thead>
            <tbody v-if="!sortedDocuments.length">
                <tr>
                    <td :colspan="countColumns" class="io-simple-file-list__no_record">{{ $t('No records found') }}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <SingleFile
                    v-for="file in sortedDocuments"
                    :key="file._id"
                    :arrayId="itemId"
                    :editable="editable"
                    :file="file"
                    :toSync="toSync"
                    :modelType="modelType"
                    :showPreviewButton="showPreviewButton"
                    :showDownloadButton="showDownloadButton"
                    :showDeleteButton="showDeleteButton"
                    :showSharedYesNo="showSharedYesNo"
                    :clickable="clickableRows"
                    :isShared="file.isShared"
                    :showCreatorName="showCreatorName"
                    :showCreatorAvatar="showCreatorAvatar"
                    :showUpdateDate="showUpdateDate"
                    :deletePopupAlertAttributes="deletePopupAlertAttributes"
                    @deleted="$emit('deleted', $event)"
                    @synchronizedFile="$emit('synchronizedFile')"
                />
            </tbody>
        </table>
    </div>
</template>

<script lang="ts" src="./SimpleFileListFromProps.ts"></script>
<style lang="scss" src="./SimpleFIleListFromProps.scss" scoped></style>
