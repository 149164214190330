import * as actions from './actions'
import * as mutations from './mutations'

export default {
    namespaced: true,
    namespace: 'notices/',
    state () {
        return {
            audio: new Audio(process.env.CDN_URL + 'new-theme/assets/notification_sound_2.mp3'),
            notices: [],
            alerts: []
        }
    },
    actions,
    mutations
}
