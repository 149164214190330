export default {
    loaded: false,
    showAddContactsPopup: false,
    
    /**
     * Invitation data
     */
    invited_people: null,
    invited_pending: null,
    invited: null
}