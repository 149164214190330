import { defineComponent } from 'vue'
import ProgressBar from '@/components/atoms/progress-bar/ProgressBar.vue'
import uploadingItemMixin from '../../mixins/uploadingItemMixin.ts'
export default defineComponent({
    components: {
        ProgressBar
    },
    mixins: [ uploadingItemMixin ],
    props: {
        item: {
            type: Object,
            required: true,
        },
        showModalVersion: {
            type: Boolean,
            required: false,
            default: false
        },
    },
})
