<template>
    <IOModal
        class="io-adjustments-modal"
        icon="icon-edit"
        width="1200"
        :title="modalTitle"
        :scrollable="true"
        @close="closeModal"
    >
        <template #default>
            <div class="io-content-wrapper">
                <section>
                    <IOLabel :size="LabelSize.Large" :type="LabelType.BoldMax" :text="$t('Originally Submitted')" />
                    <div class="io-section-content">
                        <IOSingleInfoRow
                            :label="$t('% of Completed Work')"
                            :info="$filters.formatPercentInt(getOriginallySubmittedValue(SoVLineItemField.PercentOfCompletedWork))"
                        />
                        <IOSingleInfoRow
                            :label="$t('Current application')"
                            :info="$filters.formatProjectCurrencyInt(getOriginallySubmittedValue(SoVLineItemField.CurrentApplication))"
                        />

                        <IOSingleInfoRow
                            :label="`${ $t('Current Retention Percent') } (%)`"
                            :info="$filters.formatPercentInt(getOriginallySubmittedValue(SoVLineItemField.CurrentRetentionPercent))"
                        />
                        <IOSingleInfoRow
                            :label="`${ $t('Total Retention Amount') } ($)`"
                            :info="$filters.formatProjectCurrencyInt(getOriginallySubmittedValue(SoVLineItemField.TotalRetentionAmount))"
                        />
                        <IOSingleInfoRow
                            :label="`${ $t('Total Retention Percent') } (%)`"
                            :info="$filters.formatPercentInt(getOriginallySubmittedValue(SoVLineItemField.TotalRetentionPercent))"
                        />
                        <IOSingleInfoRow
                            :label="$t('Net Current application')"
                            :info="$filters.formatProjectCurrencyInt(getOriginallySubmittedValue(SoVLineItemField.NetCurrentApplication))"
                        />
                    </div>
                </section>

                <section v-if="adjusted">
                    <div class="io-adjustments-modal__section-header">
                        <IOLabel :size="LabelSize.Large" :type="LabelType.BoldMax" :text="$t('Adjusted')" />
                        <IOButton
                            v-if="isEditable"
                            variant="link"
                            size="small"
                            @click="clearRowUpdates"
                        >
                            {{ $t('Clear Your Updates') }}
                        </IOButton>
                    </div>

                    <Table
                        v-if="isEditable"
                        :headers="tableHeaders"
                    >
                        <template #tbody>
                            <RevisionModalRow
                                :item="adjusted"
                            />
                        </template>
                    </Table>

                    <div v-else class="io-section-content">
                        <IOSingleInfoRow
                            :label="$t('% of Completed Work')"
                            :info="$filters.formatPercentInt(getAdjustedValue(SoVLineItemField.PercentOfCompletedWork))"
                        />
                        <IOSingleInfoRow
                            :label="$t('Current application')"
                            :info="$filters.formatProjectCurrencyInt(getAdjustedValue(SoVLineItemField.CurrentApplication))"
                        />

                        <IOSingleInfoRow
                            :label="`${ $t('Current Retention Percent') } (%)`"
                            :info="$filters.formatPercentInt(getAdjustedValue(SoVLineItemField.CurrentRetentionPercent))"
                        />
                        <IOSingleInfoRow
                            :label="`${ $t('Total Retention Amount') } ($)`"
                            :info="$filters.formatProjectCurrencyInt(getAdjustedValue(SoVLineItemField.TotalRetentionAmount))"
                        />
                        <IOSingleInfoRow
                            :label="`${ $t('Total Retention Percent') } (%)`"
                            :info="$filters.formatPercentInt(getAdjustedValue(SoVLineItemField.TotalRetentionPercent))"
                        />
                        <IOSingleInfoRow
                            :label="$t('Net Current application')"
                            :info="$filters.formatProjectCurrencyInt(getAdjustedValue(SoVLineItemField.NetCurrentApplication))"
                        />
                    </div>
                </section>

                <section>
                    <IOLabel :size="LabelSize.Large" :type="LabelType.BoldMax" :text="$t('Difference')" />
                    <div class="io-section-content">
                        <IOSingleInfoRow
                            :label="$t('% of Completed Work')"
                            :info="$filters.formatPercentInt(getDifferenceValue(SoVLineItemField.PercentOfCompletedWork))"
                        />
                        <IOSingleInfoRow
                            :label="$t('Current application')"
                            :info="$filters.formatProjectCurrencyInt(getDifferenceValue(SoVLineItemField.CurrentApplication))"
                        />
                        <IOSingleInfoRow
                            :label="`${ $t('Current Retention Percent') } (%)`"
                            :info="$filters.formatPercentInt(getDifferenceValue(SoVLineItemField.CurrentRetentionPercent))"
                        />
                        <IOSingleInfoRow
                            :label="`${ $t('Total Retention Amount') } ($)`"
                            :info="$filters.formatProjectCurrencyInt(getDifferenceValue(SoVLineItemField.TotalRetentionAmount))"
                        />
                        <IOSingleInfoRow
                            :label="`${ $t('Total Retention Percent') } (%)`"
                            :info="$filters.formatPercentInt(getDifferenceValue(SoVLineItemField.TotalRetentionPercent))"
                        />
                        <IOSingleInfoRow
                            :label="$t('Net Current application')"
                            :info="$filters.formatProjectCurrencyInt(getDifferenceValue(SoVLineItemField.NetCurrentApplication))"
                        />
                    </div>
                </section>
            </div>
            <Comments
                :comments="comments"
                can-add-comment
                :taggableUsers="taggableUsers"
                showCreationTime
                can-edit
                @add-comment="addComment"
                @delete-comment="deleteComment"
                @update-comment="updateComment"
            />
        </template>
        <template #footer>
            <template v-if="isNewItem && ((isClient && canClientReviseInvoice) || (isVendor && isRevisionStatus))">
                <IOButton variant="secondary" @click="closeModal">
                    {{ $t('Cancel') }}
                </IOButton>
                <IOButton @click="applyAdjustment">
                    {{ $t('Adjust') }}
                </IOButton>
            </template>

            <template v-else-if="!isEditable && ((isVendor && isRevisionStatus) || (isClient && resolvedRevised(item) && invIsNotApproved))">
                <IOButton variant="secondary" @click="reviseAdjustments">
                    {{ $t('Revise Adjustment') }}
                </IOButton>
                <IOButton v-if="!(isVendor && isCreatedByVendor)" @click="acceptAdjustments">
                    {{ $t('Accept Adjustment') }}
                </IOButton>
            </template>

            <template v-else-if="isEditable">
                <IOButton variant="secondary" @click="closeModal">
                    {{ $t('Cancel') }}
                </IOButton>
                <IOButton @click="applyAdjustment">
                    {{ $t('Save') }}
                </IOButton>
            </template>

            <IOButton v-else variant="secondary" @click="closeModal">
                {{ $t('Close') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./RevisionModal.ts"></script>
<style lang="scss" src="./RevisionModal.scss" scoped></style>
