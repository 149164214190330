<template>
    <RouterLink
        v-if="isRouterLink"
        class="io-menu-left__list-link"
        :class="{
            'io-menu-left__list-link_sub-item': isSubItem,
            'io-menu-left__list-link_sub-item_active': isSubItemActive
        }"
        :to="itemData.url"
        @click.native="onSetMenuItemOpenedClick"
    >
        <i
            v-if="!isSubItem"
            class="io-menu-left__list-item-icon"
            :class="iconClass"
        />

        <span class="io-menu-left__list-item-text">
            {{ $t(itemData.title) }}

            <span v-if="itemData.is_beta" class="io-menu-left__list-item-beta">{{ $t('Beta') }}</span>
        </span>
    </RouterLink>

    <span
        v-else
        class="io-menu-left__list-link"
        :class="{
            'io-menu-left__list-link_sub-item': isSubItem,
            'io-menu-left__list-link_sub-item_active': isSubItemActive
        }"
        @click="onSetMenuItemOpenedClick"
    >
        <i
            v-if="!isSubItem"
            class="io-menu-left__list-item-icon"
            :class="iconClass"
        />

        <span class="io-menu-left__list-item-text">
            {{ $t(itemData.title) }}
        </span>
    </span>
</template>

<script src="./ItemLink.ts"></script>
<style lang="scss" src="./ItemLink.scss" scoped></style>
