export const getMyContactsList = (state) => {
    return key => {
        return state.inviteModalData[key].matchedMyContacts
    }
}

export const getMatchedDomainsList = (state) => {
    return key => {
        return state.inviteModalData[key].matchedDomains
    }
}

export const getMatchedAccountsList = (state) => {
    return key => {
        return state.inviteModalData[key].matchedAccounts
    }
}

export const getSearchKeyword = (state) => {
    return key => {
        return state.inviteModalData[key] ? state.inviteModalData[key].search : ''
    }
}

export const getSearchDomain = (state) => {
    return key => {
        return state.inviteModalData[key].search.split('@')[1]
    }
}

export const isSearchValidEmail = (state) => {
    return key => {
        const regex = /\S+@\S+\.\S+/
        return regex.test(state.inviteModalData[key].search)
    }
}

export const getSelectedContactsList = (state) => {
    return key => {
        return state.inviteModalData[key] ? state.inviteModalData[key].selectedContacts : []
    }
}

export const getGroupedCompaniesContactsList = (state) => {
    return key => {
        return state.inviteModalData[key] ? state.inviteModalData[key].groupedCompaniesContactsList : {}
    }
}

export const getSelectedContact = (state) => {
    return key => {
        return state.inviteModalData[key].selectedContact
    }
}

export const getSelectedWorkspace = (state) => {
    return key => {
        return state.inviteModalData[key].selectedWorkspace
    }
}

export const getSelectedWorkspaceType = (state) => {
    return key => {
        return state.inviteModalData[key]?.selectedWorkspaceType || ''
    }
}

export const getCompaniesList = (state) => {
    return state.companiesList
}

export const getContactFormData = (state) => {
    return key => {
        return state.inviteModalData[key].contactFormData
    }
}

export const getSelectedAction = (state) => {
    return key => {
        return state.inviteModalData[key].selectedAction
    }
}

export const getIsFormValid = (state) => {
    return key => {
        return state.inviteModalData[key].isFormValid
    }
}

export const getIsRendered = (state) => {
    return key => {
        return state.inviteModalData[key].rendered
    }
}

export const getIsLoading = (state) => {
    return key => {
        return state.inviteModalData[key].loading
    }
}

export const getIsTabsVisible = (state) => {
    return key => {
        return state.inviteModalData[key].isTabsVisible
    }
}

export const getActiveStep = (state) => {
    return key => {
        return state.inviteModalData[key].activeStep
    }
}

export const getIsLastPage = (state) => {
    return key => {
        return state.inviteModalData[key].isLastPage
    }
}

export const getOnItemContactsList = (state) => {
    return key => {
        return state.inviteModalData[key]?.onItemContactsList
    }
}

export const getSelectedContactsToAction = (state) => {
    return key => {
        return state.inviteModalData[key]?.selectedContactsToAction
    }
}

export const getInviteModalData = (state) => {
    return key => {
        return state.inviteModalData[key]
    }
}

export const isOtherRecordsExist = (state) => {
    return key => {
        return state.inviteModalData[key].isOtherRecordsExist
    }
}

export const getInviteContactData = (state) => {
    return {
        id: state.id,
        email: state.email,
        company: {
            id: state?.company_id,
            name: state?.company_name
        },
        first_name: null,
        last_name: null,
        app_type: null
    }
}

export const getSelectedAddedContactsProjectDirectory = (state) => {
    return key => {
        return state.inviteModalData[key]?.selectedAddedContactsProjectDirectory ?? {}
    }
}
