export default {
    endpoint: '',
    prevEndpoint: '',
    endpointParams: {},
    apiVersion: 1,
    dataList: [],
    editedDatalist: [],
    editedDatalistClone: [],
    skip: 0,
    limit: 0,
    perPage: 10,
    page: 1,
    takeItems: 50,
    sortBy: '',
    sortDirection: '',
    search: '',
    filters: [],
    filtersSelected: {},
    columns: [],
    isLastPage: false,
    isLoading: false,
    showFilters: false,
    withCounters: true,
    withFilters: true,
    counters: [],
    selectedRows: [],
    isEditMode: false,
    requestParams: {},
    additionalOptions: null,
    canEdit: null,
    appliedFilters: {},
    dataPropertyName: 'items',
    removedItemId: null,
    hasFetchedOnce: null,
    exception: false,
    additionalQueryParams: {},
    hasAllRowsSelected: false,
    shouldSelectAllRows: false,
    isFetchAllRecords: false,
    isFrontEndSortingEnabled: false,
    selectedFiltersOverload: null,
    isServerDefaultFiltersDisabled: false,
}
