<template>
    <div class="io-projects-list-table">
        <div v-if="manageColumns" class="io-table-manage-columns">
            <IOButton
                class="manage-columns"
                icon="icon-grid-14"
                size="small"
                variant="link"
                @click="toggleManageColumnsModal(true)"
            >
                {{ $t('Manage Columns') }}
            </IOButton>

            <ManageColumnsModal
                v-if="showManageColumnsModal"
                :fixed-columns="fixedColumns"
                :scrollable-columns="scrollableColumns"
                @close="toggleManageColumnsModal(false)"
                @save-settings="saveManageColumnsSettings"
            />
        </div>
        <div class="io-new-list-holder sticky-wrapper" ref="scrollableArea" style="overflow: auto; padding: 0;">
            <table class="io-table-content sticky-table-wrapper">
                <thead>
                    <tr>
                        <template v-for="col in columns" :key="col.field">
                            <th
                                v-if="isColumnVisible(col)"

                                :class="[col.columnClass ? col.columnClass : '', { 'io-th-sm': !fluidHeader }]"
                                :style="{ cursor: col.additional && col.additional.sortHidden ? 'default' : 'pointer' }"
                                @click="changeSort(col)"
                            >
                                <template v-if="col.type === 'checkbox'">
                                    <GroupCheckbox
                                        :records="records"
                                        :total-rows="records.length"
                                    />
                                </template>
                                <template v-if="col.type === 'server-side-checkbox'">
                                    <ServerSideGroupCheckbox
                                        :records="records"
                                        :isDisabled="isDisabled"
                                        :total-rows="records.length"
                                    />
                                </template>
                                <template v-if="col.type === 'invitation-button'">
                                    <InvitationButtonGroup
                                        :column="col"
                                        @inviteAll="$emit('inviteAllClick')"
                                    />
                                </template>
                                <template v-else-if="col.htmlLabel">
                                    <span v-html="col.htmlLabel"></span>
                                </template>
                                <template v-else>
                                    {{ col.label }}
                                </template>

                                <i v-if="col.tooltipText" v-tooltip.top="col.tooltipText" class="icon-question-2 io-table-column-title-help-icon"></i>

                                <template v-if="!sortHidden(col)">
                                    <template v-if="sortBy === col.field || col.additional && col.additional.sortByCustomField === sortBy">
                                        <template v-if="sortDirection === 'ASC'">
                                            <i class="fas fa-sort-down"></i>
                                        </template>
                                        <template  v-else>
                                            <i class="fas fa-sort-up"></i>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-sort"></i>
                                    </template>
                                </template>
                            </th>
                        </template>
                    </tr>
                </thead>

                <tbody>
                    <template v-if="records && records.length">
                        <template v-for="(row, index) in records" :key="index">
                            <TableRow
                                :itemRoute="itemRoute"
                                :isLastRow="index === records.length - 1"
                                :pillsColorFunction="pillsColorFunction"
                                :pillsTextFunction="pillsTextFunction"
                                :dotsColorFunction="dotsColorFunction"
                                :customStatusTextFunction="customStatusTextFunction"
                                :showIconToolTip="showIconToolTip"
                                :columns="columns"
                                :isDisabled="isDisabled(row)"
                                :isRowSelectedByRadio="selectedRowByRadioId === row?.id || selectedRowByRadioId === row?._id"
                                :tableRow="row"
                                :tableRowClass="tableRowClass"
                                :noReroute="noReroute"
                                :selectedRows="selectedRows"
                                :isEditMode="isEditMode"
                                :customClassFunction="customClassFunction"
                                :class="tableRowClassFunction(row)"
                                :isStrictWidth="isStrictWidth"
                                :disableBookmarking="disableBookmarking"
                                @actionSelect="actionSelect"
                                @emitIconAction="emitIconAction"
                                @changeFavorite="changeFavorite"
                                @tableRowClick="$emit('tableRowClick', $event)"
                                @radioClick="radioClick"
                                @inviteButtonClick="onInviteButtonClick"
                                @invitationUpdate="onInvitationUpdate"
                                @inviteButtonNewClick="onInviteButtonNewClick"
                                @additionalHTMLClicked="$emit('additionalHTMLClicked', $event)"
                                @toggle-expand-table-row="onToggleExpandTableRow(index)"
                            />

                            <slot v-if="allowToExpandRow && expandedRows.includes(index)" v-bind:item="row" />
                        </template>
                        <FetchingRow v-show="isFetching" :columns="columns" :text="$t('Fetching data') + '...'" />
                    </template>
                    <template v-else-if="isFetching">
                        <FetchingRow :columns="columns" :text="$t('Fetching data') + '...'" />
                    </template>
                    <template v-else>
                        <FetchingRow v-if="!showCustomComponentWhenNoRecords" :columns="columns" :text="$t('No records found')" />
                        <slot v-else name="custom-component-when-no-records"/>
                    </template>
                </tbody>
            </table>
        </div>
        <ModalFilters
            v-show="showFilters"
            :countFilters="countFilters"
            @apply="applyFilters"
            @closeModal="onCloseFilters"
            @clearFilters="onClearAllFilters"
        >
            <template #default>
                <FilterSection v-if="filterSections" :filterSections="filterSections" />
                <Filters
                    v-if="filtersSchema"
                    :filtersSchema="filtersSchema"
                    :customPillsColorFunction="filterPillsColorFunction"
                />
            </template>

            <template #buttons>
                <IOButton variant="secondary" @click="onClearAllFilters">{{ $t('Clear all') }}</IOButton>
            </template>
        </ModalFilters>
    </div>
</template>

<script lang="ts" src="./LazyList.ts"></script>
<style lang="scss" src="./LazyList.scss"/>
