import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse'
import Approver from '@/io-modules/approval-workflows/interfaces/Approver'
import ApproverAssignmentType from '@/interfaces/modules/projects/financial-approvals/ApproverAssignmentType'

export default class ApprovalModalService {

    public static isManualApproval (resourceApprovals: ResourceApprovalsResponse): boolean {
        return !resourceApprovals?.workflows?.length
    }

    private static currentApproverData (resourceApprovals: ResourceApprovalsResponse, userMongoId: string): Approver {
        if (!resourceApprovals) {
            return null
        }

        let approver = null
        resourceApprovals.workflows.forEach(workflow => {
            if (!approver) {
                approver = workflow.approvers.find(approver => approver.contact_person_id === userMongoId)
            }
        })

        return approver
    }

    private static isActiveReviewer (resourceApprovals: ResourceApprovalsResponse, userMongoId: string): Approver {
        const currentApprover = this.currentApproverData(resourceApprovals, userMongoId)

        if (!currentApprover) {
            return false
        }

        return currentApprover.actions?.approve && ApproverAssignmentType.REVIEWER === currentApprover.assignment_type
    }


    private static isActiveApprover (resourceApprovals: ResourceApprovalsResponse, userMongoId: string): Approver {
        const currentApprover = this.currentApproverData(resourceApprovals, userMongoId)

        if (!currentApprover) {
            return false
        }

        return currentApprover.actions?.approve && ApproverAssignmentType.APPROVER === currentApprover.assignment_type
    }

    public static isLastApprover (resourceApprovals: ResourceApprovalsResponse, userMongoId: string): boolean {
        if (null === resourceApprovals) {
            return false
        }

        const currentApprover = this.currentApproverData(resourceApprovals, userMongoId)
        if (!currentApprover || currentApprover.approval) {
            return false
        }

        let approversCount = 0
        let waitingApprovals = 0
        let approverData = null
        resourceApprovals.workflows.forEach(workflow => {
            approversCount += workflow.approvers.length
            workflow.approvers.forEach(approver => {
                if (null === approver.approval) {
                    waitingApprovals += 1
                    approverData = approver
                }
            })
        })

        return approversCount >= 1 && 1 === waitingApprovals && approverData.contact_person_id === currentApprover.contact_person_id
    }
}

