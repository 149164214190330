<template>
    <div @click="redirectToRoute()" class="io-single-message io-single-message--new">
        <div class="io-single-message__picture-holder">
            <div class="io-single-message__icon"><i class="fas" :class="item.icon"></i></div>
        </div>
        <div class="io-single-message__content">
            <div class="io-single-message__title-row">
                <div class="io-single-message__title">{{ item.message }}</div>
            </div>
            <div class="io-single-message__info-row">
                <div class="io-single-message__text">{{ $filters.agoDate(item.created_at) }}</div>
                <div class="io-single-message__buttons">
                    <template v-if="loading">
                        <a class="io-notification-btn">
                            <div class="io-loader-small"></div>
                        </a>
                    </template>
                    <template v-else-if="success"></template>
                    <template v-else>
                        <div class="io-accent io-notification-btn" @click.stop="acceptInvite">{{ $t('Accept') }}</div>
                        <div class="io-accent io-notification-btn" @click.stop="redirectToRoute">{{ $t('View') }}</div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import notificationMixin from './mixins/notificationMixin'
    export default {
        name: 'InvitationItem',
        mixins: [notificationMixin],
        props: {
            item: {
                type: Object,
                required: true
            },
            duringAction: {
                type: Boolean,
                required: true
            }
        },
        data () {
            return {
                loading: false,
                success: false
            }
        },

        methods: {
            async acceptInvite () {
                if (this.duringAction) {
                    return false
                }
                this.$emit('toggleAction')

                this.loading = true
                try {
                    const {data} = await this.$api.post(`/invitation/accept/${this.item.relatedId}`)
                    await this.$store.dispatch('cached/refreshList', 'projects')
                    this.showNotification('success', data.msg)
                    this.$emit('closeList', true)
                    this.$router.push({path: data.custom_url})
                } catch (error) {
                    this.showNotification('error', error.response.data.message, error)
                    this.loading = false
                    this.$emit('toggleAction')
                } finally {
                    this.markAlertAsRead(this.item)
                }
            },
            async rejectInvite () {
                this.loading = true
                try {
                    const {data} = await this.$api.post(`/invitation/reject/${this.item.relatedId}`)
                    this.$emit('reject', this.item._id)
                    this.showNotification('success', data.msg)
                    this.loading = false
                } catch (error) {
                    this.showNotification('error', error.response.data.message, error)
                    this.loading = false
                }
            },

            redirectToRoute () {
                this.$emit('closeList', false)
                this.markAlertAsRead(this.item)
                this.$router.push({name: 'invitation-view', params: {id: this.item.relatedId}})
            }
        }
    }
</script>
<style lang="scss" scoped>
    .io-btn-loader-compact {
        position: relative;
        min-width: 60px;

        .io-loader-small {
            left: 0;
            right: 0;
        }
    }
</style>
