import store from '@/store/index'

const dateTimeHelper = {
    getDateFormat (): string {
        return store.getters['appStore/getDateFormat']
    },
    getDateTimeFormat (): string {
        return store.getters['appStore/getDateFormat'] + ' ' + store.getters['appStore/getTimeFormat']
    },
    getTimeFormat (): string {
        return store.getters['appStore/getTimeFormat']
    }
}

export default dateTimeHelper

export { dateTimeHelper }
