<template>
    <div class="io-top-actions io-top-action--nav-active">
        <div class="io-top-actions__items">
            <div v-if="toggleLeftNavAvailable" class="io-icon-btn" @click="toggleLeftNavigation">
                <span class="icon-align-bottom" :class="{ 'icon-align-bottom--rotate': !showLeftNavigation }"></span>
            </div>
            <DropdownButton
                v-if="columnsViewMode"
                buttonClass="io-custom-btn"
                :name="columnsViewMode.name"
            >
                <div
                    v-for="item in columnsViewModeOptionsArray"
                    :key="item.id"
                    class="io-dropdown-option"
                    @click="setViewMode('columnsViewMode', item)"
                >
                    {{ item.name }}
                </div>
            </DropdownButton>
            <DropdownButton
                v-if="coViewMode"
                buttonClass="io-custom-btn"
                :name="coViewMode.name"
            >
                <div
                    v-for="item in coViewModeOptionsArray"
                    :key="item.id"
                    class="io-dropdown-option"
                    @click="setViewMode('coViewMode', item)"
                >
                    {{ item.name }}
                </div>
            </DropdownButton>
            <DropdownButton
                v-if="linesViewMode"
                buttonClass="io-custom-btn"
                :name="linesViewMode.name"
            >
                <div
                    v-for="item in linesViewModeOptionsArray"
                    :key="item.id"
                    class="io-dropdown-option"
                    @click="setViewMode('linesViewMode', item)"
                >
                    {{ item.name }}
                </div>
            </DropdownButton>

            <IOButton v-if="!needAttentionItemsTotal" variant="link" @click="moveToRetentionSection">
                {{ $t('Modify / Release Retention') }}
            </IOButton>
        </div>
        <div class="io-top-actions__items">
            <CollapseExpandButton :forceExpand="forceExpand" class="io-top-actions__collapse"/>
            <div class="io-icon-btn" @click="$emit('toggleFullScreen')">
                <span class="icon-arrows-expand"></span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./TopActions.ts"></script>
<style lang="scss" src="./TopActions.scss" scoped></style>
