import { defineComponent } from 'vue'
import ListHeader from '@/io-modules/invoices/components/invoice-view/sov-wbs/list-header/ListHeader.vue'
import LineItemsTable from '@/io-modules/invoices/components/invoice-view/non-contracted-line-items/line-items-table/LineItemsTable.vue'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import SelectProjectSiteModal from '../../../components/invoice-view/select-project-site-modal/SelectProjectSiteModal.vue'
import SiteItem from '../../../interfaces/SiteItem.ts'
import SitesNavigation from '../../../components/invoice-view/sites-navigation/SitesNavigation.vue'
import LineProgress from '../../../components/invoice-view/line-progress/LineProgress.vue'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import FeatureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'
import type { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'
import budgetClient from '@/io-modules/budget/api-clients/BudgetClient.ts'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType'
import NonContractedLineItem from '@/io-modules/invoices/interfaces/NonContractedLineItem'
import AffectOtherSites from '@/components/budget-site-select/affect-other-sites/AffectOtherSites.vue'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'

export default defineComponent({
    name: 'NonContractedLineItems',
    components: {
        ListHeader,
        LineItemsTable,
        SelectProjectSiteModal,
        SitesNavigation,
        LineProgress,
        AffectOtherSites
    },
    mixins: [FeatureFlagsMixin],
    data () {
        return {
            ctaButtons: [
                {
                    name: this.$t('Complete Invoice Details'),
                    onSubmitName: 'completeInvoiceDetails'
                },
            ],
            ctaButtonsSites: [
                {
                    name: this.$t('Select Sites'),
                    onSubmitName: 'showSelectSites'
                },
            ],
            showSelectSitesModal: false,
            showApplyToOtherSitesModal: false,
            siteToApplyFrom: null,
            budgetHasSites: false,
            loading: false,
            headerHeight: 0,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, ['isEditingMode', 'creating', 'invoice', 'sites', 'dataLoading', 'editing']),

        ...mapState(invoiceSoVStore, [ 'nonContractedLineItemsTotal']),
        ...mapWritableState(invoiceSoVStore, ['nonContractedLineItems']),

        ...mapStateVuex('project', ['projectObj']),

        lineItemsTotal (): number {
            return this.nonContractedLineItemsTotal / process.env.SCALE_FACTOR
        },

        sitesFFEnabled (): boolean {
            return this.isFeatureEnabled(FeatureFlagsConsts.BUDGET_V3_SITES, false)
        },

        sitesAvailable (): boolean {
            return this.sitesFFEnabled && this.budgetHasSites
        },

        selectedSites (): SiteItem[] | null {
            const sites = this.sites?.map(item => {
                const siteLineItems = this.lineItemsGroupedBySites[item.project_site_id]
                item.completed = siteLineItems ? siteLineItems.lineItems.every(item => item.description && (item.cost_code_id || item.cost_code?.id) && item.quantity && item.unit_price) : false

                return item
            })

            return sites
        },

        selectedSitesIds (): string[] {
            return this.selectedSites?.map(item => item.project_site_id)
        },

        selectedSitesNumber (): number {
            return this.selectedSites?.length || 0
        },

        completedSitesNumber (): number {
            return this.selectedSites.reduce((sum, item) => sum + Number(item.completed), 0)
        },

        lineItemsGroupedBySites (): { [key: string]: NonContractedLineItem[] } {
            const sitesLineItems = {}
            this.nonContractedLineItems.forEach(item => {
                if (item.site_id) {
                    if (!sitesLineItems[item.site_id]) {
                        sitesLineItems[item.site_id] = {
                            id: item.site_id,
                            lineItems: [item],
                        }
                    } else {
                        sitesLineItems[item.site_id].lineItems.push(item)
                    }
                }
            })

            return sitesLineItems
        },

        cssVars (): object {
            return {
                '--header-height': `${ this.headerHeight }px`,
            }
        },

        canApplyToOtherSites (): boolean {
            return (this.editing || this.creating) && this.selectedSites?.length > 1
        }
    },
    watch: {
        editing (): void {
            setTimeout(this.setHeaderHeight, 50)
        }
    },
    async created () {
        this.setDataLoadingValue(true)
        this.loading = true

        if (!this.invoice.id && !this.creating) {
            await this.fetchInvoiceDetails(this.projectObj.project_local_id, this.$route.params.id)
        }

        if (this.sitesFFEnabled) {
            const { data } = await budgetClient.getBudgetStatus(this.projectObj.project_local_id)
            this.budgetHasSites = BudgetType.MY_BUDGET === this.invoice.paid_by ? data.data.has_my_budget_sites : data.data.has_owner_budget_sites
        }

        this.setDataLoadingValue(false)
        this.loading = false
    },
    mounted () {
        this.setHeaderHeight()
    },
    methods: {
        ...mapActions(invoiceViewStore, ['setEditModeValue', 'fetchInvoiceDetails', 'setSites', 'setDataLoadingValue']),

        setHeaderHeight (): void {
            this.headerHeight = document.querySelector('.io-page-header-holder').clientHeight
        },

        addNewRow (siteId: string): void {
            this.nonContractedLineItems.push({
                id: '',
                description: '',
                cost_code: null,
                cost_code_id: '',
                quantity: null,
                unit_price: null,
                total: 0,
                order: this.nonContractedLineItems.length + 1,
                site_id: siteId,
            })
        },

        removeLineItem (lineIndex: number, siteId: string): void {
            if (!siteId) {
                this.nonContractedLineItems.splice(lineIndex, 1)
            } else {
                const indexes = []

                this.nonContractedLineItems.forEach((item, index) => {
                    if (item.site_id === siteId) {
                        indexes.push(index)
                    }
                })
                if (Number.isInteger(indexes[lineIndex])) {
                    this.nonContractedLineItems.splice(indexes[lineIndex], 1)
                }
            }
        },

        completeInvoiceDetails (): void {
            this.setEditModeValue(true)
        },

        showSelectSites (): void {
            this.showSelectSitesModal = true
        },

        toggleShowApplyToOtherSites (siteID: string =  null): void {
          this.showApplyToOtherSitesModal = !this.showApplyToOtherSitesModal
          this.siteToApplyFrom = siteID
        },

        selectSites (sites: ProjectSite[]): void {
            this.setSites(sites)
        },

        scrollToItem (id: string): void {
            const site = document.getElementById(id)
            if (site) {
                site.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        },

        // Applies each line item for each selected site
        applyToOtherSites (sites: ProjectSite[]): void {
            const siteIDsApplyTo = sites.map(site => site.project_site_id)

            this.nonContractedLineItems = this.nonContractedLineItems.filter(item => !siteIDsApplyTo.includes(item.site_id))

            this.siteToApplyFrom.forEach(item => {
                siteIDsApplyTo.forEach(siteID => {
                    this.nonContractedLineItems.push({ ...item, site_id: siteID, cost_code: null, cost_code_id: null })
                })
            })

            this.toggleShowApplyToOtherSites()
        }
    }
})
