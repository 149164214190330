import moment from 'moment'
import filters from '@/helpers/filters'
import errorMixin from '@/mixins/errorMixin.js'
import store from '@/store/index.js'
import { mapGetters } from 'vuex'
import appTypes from '@/base/appTypes.ts'
import regionTypes from '@/base/regionTypes.ts'
import dateTimeHelper from '@/helpers/dateTime'
import { get, isUndefined, isString, isArray, isObject, cloneDeep, isNull, isEmpty, uniqueId } from 'lodash'
import { getPillName } from '@/helpers/util'
import { getCurrencyCountryFlag } from '@/helpers/currencyCountryFlag'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'
import i18n from '@/base/i18n.js'
import { formatMoney } from '@/helpers/currencyFormatters'
import { default as getAppTypeStatusPillClass } from '@/helpers/getAppTypeStatusPillVariant.ts'

const ObjectIdTest = /^[a-f\d]{24}$/i

export default {
    filters: filters,
    mixins: [ errorMixin ],
    data () {
        return {
            moment,
        }
    },
    computed: {
        ...mapGetters('appStore', [ 'getEnv' ]),
        accountTypes () {
            return {
                ROLE_ADMIN: 1,
                ROLE_SALES_EXECUTIVE: 2,
                ROLE_PROJECT_MANAGER: 3,
                ROLE_ACCOUNTING: 4,
                ROLE_ARCHITECT: 5,
                ROLE_PRINCIPAL: 6,
                ROLE_CUSTOM: 10,
                ROLE_EMPLOYEE: 100,
                ROLE_NEW_EMLOYEE: 105,
                ROLE_ENGINEER: 110,
                ROLE_FOREMAN: 120,
                ROLE_SHOP_FOREMAN: 130,
                ROLE_SUPERVISOR: 140,
                ROLE_PROJECT_EXECUTIVE: 200
            }
        },

        apiUrl () {
            return get(this.$api, ['client', 'defaults', 'baseURL'], '')
        },

        apiV2Url () {
            return get(this.$api2, ['client', 'defaults', 'baseURL'], '')
        },

        appTypes () {
            return appTypes
        },

        regionTypes () {
            return regionTypes
        },

        envIsDev () {
            return process.env.NODE_ENV !== 'production'
        },

        isNotProd () {
            return (
                this.getEnv === 'staging' ||
                this.getEnv === 'dev' ||
                this.getEnv === 'localhost'
            )
        },

        isProduction () {
            return this.getEnv === 'production'
        },

        isStaging () {
            return this.getEnv === 'staging'
        },

        getAppTypes () {
            return [
                {
                    id: appTypes.TYPE_OWNER,
                    name: this.$t('Owner'),
                },
                {
                    id: appTypes.TYPE_DEV,
                    name: this.$t('Rep'),
                },
                {
                    id: appTypes.TYPE_GC,
                    name: this.$t('GC'),
                },
                {
                    id: appTypes.TYPE_AC,
                    name: this.$t('Design'),
                },
                {
                    id: appTypes.TYPE_SC,
                    name: this.$t('Sub'),
                },
                {
                    id: appTypes.TYPE_OFF_SYSTEM,
                    name: this.$t('Off-system'),
                },
            ]
        },
    },

    methods: {
        get,
        isUndefined,
        isString,
        isArray,
        isObject,
        isNull,
        isEmpty,
        uniqueId,

        /**
         * Check if type id is on types list
         *
         * @param typeId
         * @param allowedTypes
         */
        isAccountType ( typeId, allowedTypes = [] ) {
            let allowedTypesIds = []
            allowedTypes.forEach(( type ) => {
                if (isUndefined(this.accountTypes[type])) {
                    return false
                }
                allowedTypesIds.push(this.accountTypes[type])
            })

            return allowedTypesIds.indexOf(parseInt(typeId)) > -1
        },

        getAppTypeStatusPillClass,

        getAppTypeNameById (type) {
            if (!type) {
                return ''
            }

            switch (type.toLowerCase()) {
                case appTypes.TYPE_AC:
                    return i18n.global.t('Design')
                case appTypes.TYPE_DEV:
                    return i18n.global.t('Rep')
                case appTypes.TYPE_GC:
                    return i18n.global.t('GC')
                case appTypes.TYPE_DESIGN:
                    return i18n.global.t('Design')
                case appTypes.TYPE_REP:
                    return i18n.global.t('Rep')
                case appTypes.TYPE_OWNER:
                    return i18n.global.t('Owner')
                case appTypes.TYPE_SUB:
                    return i18n.global.t('Sub')
                case appTypes.TYPE_SC:
                    return i18n.global.t('Sub')
                case appTypes.TYPE_OFF_SYSTEM:
                    return i18n.global.t('Off-system')
                default:
                    return ''
            }
        },

        getAppTypeNameColor (type) {
            const variant = this.getAppTypeStatusPillClass(type)
            const text = this.getAppTypeNameById(type)

            return { variant, text }
        },

        isValidMongoId (mongoId) {
            return isString(mongoId) && mongoId.match(/^[0-9a-fA-F]{24}$/)
        },

        getUnitOfMeasureSign (unitOfMeasureId) {
            return unitOfMeasureId === 1 ? 'ft²' : 'm²'
        },
        getUnitOfMeasureShortName (unitOfMeasureId) {
            return i18n.global.t(unitOfMeasureId === 1 ? 'Sqft' : 'Sq m')
        },

        activeSubscriptionOpenCA () {
            return store.getters['appStore/getAuthData'].subscription_type === 'OpenCA'
        },

        activeSubscriptionOpenCAPlus () {
            return store.getters['appStore/getAuthData'].subscription_type === 'OpenCA+'
        },

        getCurrencyCountryFlag (currencyIsoCode) {
            return getCurrencyCountryFlag(currencyIsoCode)
        },

        getActiveCurrencyId () {
            if (window.useProjectCurrency === true) {
                return this.getProjectCurrency().id
            }
            return this.getWorkspaceCurrency().id
        },

        getWorkspaceCurrency () {
            return store.getters['appStore/getAuthData'].workspace_currency
        },
        getProjectCurrency () {
            return store.getters['appStore/getProjectCurrency'].currency
        },
        getWorkspaceCurrencySymbol () {
            // special case for project related views/modules
            if (window.useProjectCurrency === true) {
                return this.getProjectCurrencySymbol()
            }

            return this.getWorkspaceCurrency().symbol
        },
        getProjectCurrencySymbol () {
            return this.getProjectCurrency().symbol
        },
        getProjectFormatterCurrency (value, precision = 2, convertFromInt = false) {
            if (convertFromInt) {
                value = this.toFloat(value)
            }
            if (precision !== null) {
                value = value ? this.cutPrecision(value, precision) : null
            }

            let projectCurrency = this.getProjectCurrency()

            return formatMoney(projectCurrency, value, precision)
        },
        getFormatterCurrency (value, precision = 2, convertFromInt = false) {
            // special case for project related views/modules
            if (window.useProjectCurrency === true) {
                return this.getProjectFormatterCurrency(...arguments)
            }
            if (convertFromInt) {
                value = this.toFloat(value)
            }
            if (precision !== null) {
                value = value ? this.cutPrecision(value, precision) : null
            }

            let workspaceCurrency = this.getWorkspaceCurrency()

            return formatMoney(workspaceCurrency, value, precision)
        },
        getFormatterLocale (value, precision = 2, convertFromInt = false, strictPrecision = false) {
            if (convertFromInt) {
                value = this.toFloat(value)
            }
            if (precision !== null) {
                value = value ? this.applyPrecision(value, precision, strictPrecision) : null
                //value = value ? Number(value).toFixed(precision) : null
            }

            let workspaceCurrency = this.getWorkspaceCurrency()
            let formatter = new Intl.NumberFormat(workspaceCurrency.locale)
            return formatter.format(value)
        },

        formatCurrencyValue (total) {
            return parseFloat(total)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
                .toString()
        },
        convertToFloat (number) {
            if (number === undefined || number === null || number ==='') {
                return 0
            }
            let val = parseFloat(number.toString().replace(/,/g, ''))
            return !isNaN(val) ? val : 0
        },
        inArray (needle, haystack) {
            let length = haystack.length
            for (let i = 0; i < length; i++) {
                if (haystack[i] === needle) {
                    return true
                }
            }
            return false
        },
        // this is global function that calculates columns vertically
        calculateTotalColumn (
            field, model, sectionId, storage, discount = 0,
            discountType = '%') {
            let amount = 0
            if (model[field.inputName].sections[sectionId] !== undefined) {
                let node = model[field.inputName].sections[sectionId]
                for (let i = 0; i < node.length; i++) {
                    if (node[i][storage] !== undefined) {
                        let value = this.convertToFloat(node[i][storage])
                        amount += value
                    }
                }
            }
            if (discount > 0) {
                if (discountType === '$') {
                    amount = amount - discount
                } else {
                    discount = discount / 100
                    amount = amount * (1 - discount)
                }
            }
            return amount
        },
        // this is global function that calculates columns vertically
        calculateTotalCustomColumn (
            field, model, sectionId, storage, discount = 0,
            discountType = '%') {
            let amount = 0
            if (model[field.inputName].custom[sectionId] !== undefined) {
                let node = model[field.inputName].custom[sectionId]
                for (let i = 0; i < node.length; i++) {
                    if (node[i][storage] !== undefined) {
                        let value = this.convertToFloat(node[i][storage])
                        amount += value
                    }
                }
            }

            if (discount > 0) {
                if (discountType === '$') {
                    amount = amount - discount
                } else {
                    discount = discount / 100
                    amount = amount * (1 - discount)
                }
            }

            // check if value should be converted from int to float
            if (field.convertIntToFloat) {
                amount = this.$filters.formatCurrencyValueInt(amount)
            }

            return amount
        },

        handleApiClientException (exception) {
            if (exception.response) {
                if (exception.response.status === 404) {
                    this.$router.push({ name: '404' })
                    return true
                } else if (exception.response.status === 403) {
                    this.$router.push({ name: '403' })
                    return true
                }
            }
            return false
        },

        copyToClipboard (text) {
            navigator.clipboard.writeText(text).then(() => {
                this.showNotification('success', this.$t('Copied to the Clipboard'))
            })
        },

        addComboGoogleAddressSearch (fieldModel, fieldModelType, countryIndex) {
            setTimeout(() => {
                let addresses1 = window.parent.$('.address1')

                let num = addresses1.length
                let obj = addresses1[num - 1]
                let adrObj = ''
                if (fieldModelType === 'c_address') {
                    adrObj = this.model.c_address[num - 1]
                } else if (fieldModelType === 'res_address') {
                    adrObj = this.model.res_address[num - 1]
                }

                let states
                let countries
                for (let i in this.fields) {
                    let field = this.fields[i]

                    if (field['inputName'] === 'c_address') {
                        states = this.fields[i]['pack'][3].values
                        countries = this.fields[i]['pack'][5].values
                        break
                    }
                    if (field['inputName'] === 'res_address') {
                        states = this.fields[i]['pack'][3].values
                        countries = this.fields[i]['pack'][5].values
                        break
                    }
                }

                let autocomplete = new window.google.maps.places.Autocomplete(obj)

                window.google.maps.event.addListener(autocomplete,
                    'place_changed', () => {
                        let place = autocomplete.getPlace()
                        let components = place.address_components

                        let component
                        let street1 = ''
                        let street2 = ''

                        let country = ''

                        let subLocalities = []
                        if (isArray(components)) {
                            for (let i = 0; i < components.length; i++) {
                                component = components[i]
                                let type = component.types[0]

                                if (type === 'country') {
                                    country = component.short_name
                                }
                            }
                            for (i = 0; i < components.length; i++) {
                                component = components[i]
                                type = component.types[0]

                                if ([
                                        'sublocality_level_1',
                                        'sublocality_level_2',
                                        'sublocality_level_3',
                                        'sublocality_level_4'
                                    ].includes(type) &&
                                    component.long_name
                                ) {
                                    subLocalities.push(component.long_name)
                                }
                                if (type === 'route') {
                                    street1 = component.long_name
                                }
                                if (type === 'street_number') {
                                    street2 = component.long_name
                                }

                                if (type === 'postal_town') {
                                    adrObj['param3'] = component.long_name
                                }

                                if (type === 'locality') {
                                    adrObj['param3'] = component.long_name
                                }

                                if (type === 'postal_code') {
                                    adrObj['param5'] = component.long_name
                                }
                                if ((type === 'country') &&
                                    !isUndefined(countries)) {
                                        let objCountry = this.getCountryIdByIso(component.short_name, countries)
                                    adrObj['param' + countryIndex] = objCountry[0]
                                }
                                if (type === 'administrative_area_level_1') {
                                    if (country === 'PR') {
                                        component.long_name = 'Puerto Rico'
                                    }

                                    let obj = this.getStateIdByName(component.long_name, states)
                                    if (obj[0] !== 0) {
                                        adrObj['param4'] = obj[0]
                                    } else {
                                        adrObj['param4'] = { id: 0, name: '' }
                                    }

                                    if (!get(adrObj, 'param3')) {
                                        adrObj['param3'] = component.long_name
                                    }
                                }
                            }
                        }
                        adrObj['param1'] = street2 ? street2 + ' ' + street1 : street1

                        if (subLocalities.length) {
                            adrObj['param1'] = subLocalities.join(',')
                        }
                    })
            }, 100)
        },

        reInitGoogleAddressSearch (adrObj, states, objPrefix, stateMultiSelect, countries, countryIndex = 6, inputClass = 'address1', callback) {
            const input = $(`.${ inputClass }.alreadyGoogled`)

            if (input[0]) {
                input[0].classList.remove('alreadyGoogled')

                this.initGoogleAddressSearch(adrObj, states, objPrefix, stateMultiSelect, countries, countryIndex, inputClass, callback)
            }
        },

        initGoogleAddressSearch (
            adrObj,
            states,
            objPrefix,
            stateMultiSelect = false,
            countries,
            countryIndex = 6,
            inputClass = 'address1',
            callback
        ) {
            setTimeout(() => {
                let input = $(`.${ inputClass }:not(.alreadyGoogled)`)

                if (input === null) {
                    input = document.getElementById(`${ inputClass }client`)
                    let autocomplete = new window.google.maps.places.Autocomplete(input)

                    window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
                        let place = autocomplete.getPlace()
                        let components = place.address_components
                        this.parseGoogleResponse(
                            components,
                            adrObj,
                            states,
                            objPrefix,
                            null,
                            stateMultiSelect,
                            callback
                        )
                    })
                } else {
                    for (let index = 0; index < input.length; index++) {
                        input.addClass('alreadyGoogled')
                        let autocomplete = new window.parent.google.maps.places.Autocomplete(input[index])

                        window.parent.google.maps.event.addListener(autocomplete, 'place_changed', () => {
                                let place = autocomplete.getPlace()

                                let components = place.address_components

                                this.parseGoogleResponse(
                                    components,
                                    adrObj,
                                    states,
                                    objPrefix,
                                    index,
                                    stateMultiSelect,
                                    countries,
                                    countryIndex,
                                    callback
                                )
                            })
                    }
                }

                if (input.parents('.io-popup-modal__content').length) {
                    input.on('keyup', function () {
                        const pacContainer = $('.pac-container')
                        const parent = input.parent()
                        const shiftHeight = parent.height() - parseInt(input.css('margin-bottom'))

                        parent.css('position', 'relative')
                        input.after(pacContainer)
                        pacContainer.attr(
                            'style',
                            'left: 0 !important; top: ' + shiftHeight + 'px !important; width: ' + input.css('width')
                        )
                    })
                }

                this.removeBrowserAutofill(adrObj, objPrefix, input)
            }, 500)
        },

        initSimpleGoogleAddressSearch (input, callback) {
            let autocomplete = new window.google.maps.places.Autocomplete(input)
            window.parent.google.maps.event.addListener(autocomplete,
                'place_changed', () => {
                    callback(input.value)
                })
        },
        /**
         * Try to kill browser autofill for addresses
         * We must do that, because for example Chrome ignores autocomplete="off"
         *
         * @param adrObjParam
         * @param objPrefix
         */
        removeBrowserAutofill (adrObjParam, objPrefix, input) {
            if (input && input[0]) {
                let prefix = input[0].name.replace('address1', '')
                $(`[name="${ prefix }address1`).attr('autocomplete', crypto.randomUUID())
                $(`[name="${ prefix }address2`).attr('autocomplete', crypto.randomUUID())
                $(`[name="${ prefix }city`).attr('autocomplete', crypto.randomUUID())
                $(`[name="${ prefix }state_id`).attr('autocomplete', crypto.randomUUID())
                $(`[name="${ prefix }zip`).attr('autocomplete', crypto.randomUUID())
            }

            let param1 = 'param1'
            let param2 = 'param2'
            let param3 = 'param3'
            let param4 = 'param4'
            let param5 = 'param5'

            if ((!isArray(adrObjParam) && isObject(adrObjParam)) || !adrObjParam) {
                param1 = objPrefix + 'address1'
                param2 = objPrefix + 'address2'
                param3 = objPrefix + 'city'
                param4 = objPrefix + 'state_id'
                param5 = objPrefix + 'zip'
            } else if (isArray(adrObjParam) && !objPrefix) {
                param1 = 'address1'
                param2 = 'address2'
                param3 = 'city'
                param4 = 'state_id'
                param5 = 'zip'
            }

            setTimeout(() => {
                $('.' + param1).attr('autocomplete', crypto.randomUUID())
                $('.' + param2).attr('autocomplete', crypto.randomUUID())
                $('.' + param3).attr('autocomplete', crypto.randomUUID())
                $('.' + param4).attr('autocomplete', crypto.randomUUID())
                $('.' + param5).attr('autocomplete', crypto.randomUUID())
            }, 150)
        },

        parseGoogleResponse (
            components,
            adrObjParam,
            states,
            objPrefix, index,
            stateMultiSelect = false,
            countries,
            countryIndex = 6,
            callback
        ) {
            index = index || 0

            let param1 = 'param1'
            let param2 = 'param2'
            let param3 = 'param3'
            let param4 = 'param4'
            let param5 = 'param5'
            let param6 = 'param' + countryIndex

            let adrObj = ''

            if (isArray(adrObjParam)) {
                adrObj = adrObjParam[index]
            } else if (isObject(adrObjParam)) {
                adrObj = adrObjParam
            } else {
                adrObj = this.model
            }

            if ((!isArray(adrObjParam) && isObject(adrObjParam)) || !adrObjParam) {
                param1 = objPrefix + 'address1'
                param2 = objPrefix + 'address2'
                param3 = objPrefix + 'city'
                param4 = objPrefix + 'state_id'
                param5 = objPrefix + 'zip'
                param6 = objPrefix + 'country'
            }
            adrObj[param1] = ''
            adrObj[param2] = ''
            adrObj[param3] = ''
            adrObj[param4] = ''
            adrObj[param5] = ''
            adrObj[param6] = ''

            let component = null
            let street1 = ''
            let street2 = ''
            let country = ''
            let subLocalities = []

            if (isArray(components)) {
                for (let i = 0; i < components.length; i++) {
                    component = components[i]
                    let type = component.types[0]

                    if (type === 'country') {
                        country = component.short_name
                    }
                }
                for (let i = 0; i < components.length; i++) {
                    component = components[i]
                    let type = component.types[0]

                    if ([
                            'sublocality_level_1',
                            'sublocality_level_2',
                            'sublocality_level_3',
                            'sublocality_level_4'
                        ].includes(type) &&
                        component.long_name
                    ) {
                        subLocalities.push(component.long_name)
                    }

                    if (type === 'route') {
                        street1 = component.long_name
                        subLocalities.push(component.long_name)
                    }

                    if (type === 'street_number') {
                        street2 = component.long_name
                        subLocalities.push(component.long_name)
                    }

                    if (type === 'postal_town') {
                        adrObj[param3] = component.long_name
                    }

                    if (type === 'locality') {
                        adrObj[param3] = component.long_name
                    }

                    if (type === 'postal_code') {
                        adrObj[param5] = component.long_name
                    }

                    if ((type === 'country') && !isUndefined(countries)) {
                        let objCountry = this.getCountryIdByIso(component.short_name, countries)
                        if (adrObjParam || stateMultiSelect) {
                            adrObj[param6] = objCountry[0]
                        } else {
                            adrObj[param6] = objCountry[1]
                        }
                    }

                    if (type === 'administrative_area_level_1') {
                        if (country === 'PR') {
                            component.long_name = 'Puerto Rico'
                        }

                        let obj = this.getStateIdByName(component.long_name, states)
                        if (adrObjParam || stateMultiSelect) {
                            adrObj[param4] = obj[0]
                        } else {
                            adrObj[param4] = obj[1]
                        }

                        if (!get(adrObj, param3)) {
                            adrObj[param3] = component.long_name
                        }
                    }

                    callback && callback(adrObj)
                }
            }

            adrObj[param1] = street2 ? street2 + ' ' + street1 : street1
            if (subLocalities.length && street1 === '') {
                adrObj[param1] = subLocalities.join(' ')
            }
        },
        getStateIdByName (name, states) {
            let obj = 0
            let id = 0

            for (let i = 0; i < states.length; i++) {
                let nameOption = states[i].name
                if (name === nameOption) {
                    obj = states[i]
                    id = states[i].id
                }
            }

            return [obj, id]
        },
        getCountryIdByIso (iso2, countries) {
            let obj = 0
            let id = 0

            for (let i = 0; i < countries.length; i++) {
                let idOption = countries[i].id
                if (iso2 === idOption) {
                    obj = countries[i]
                    id = countries[i].id
                }
            }

            return [obj, id]
        },
        /**
         *
         * @param value
         * @param displayFormat
         * @return {*}
         */
        dateToDisplay (value, displayFormat = dateTimeHelper.getDateFormat()) {
            let momentObj = moment.utc(value, 'YYYY-MM-DD HH:mm:ss')
            if (momentObj.isValid()) {
                return momentObj.local().format(displayFormat)
            } else {
                return null
            }
        },

        /**
         *
         * @param value
         * @param inputFormat
         * @return {*}
         */
        dateToStore (value, inputFormat = 'YYYY-MM-DD') {
            let momentObj = moment(value, inputFormat)
            if (momentObj.isValid()) {
                return momentObj.utc().format('YYYY-MM-DD HH:mm:ss')
            } else {
                return null
            }
        },

        dateFormatHasTime (format = '') {
            if (!isString(format)) {
                return false
            }
            return (format.includes('h') || format.includes('H'))
        },

        calculateDuration (x, y) {
            const dateStart = isObject(x) ? x.date : x
            const dateEnd = isObject(y) ? y.date : y
            const x1 = moment.utc(dateStart).local()
            const x2 = moment.utc(dateEnd).local()
            const start = moment(x1, 'YYYY-MM-DD')
            const end = moment(x2, 'YYYY-MM-DD')

            return Math.ceil(moment.duration(end.diff(start)).asDays())
        },

        /**
         * Global notification helper
         *
         * @param {NotificationType | string} type
         * @param message
         * @param exception
         * @param html
         */
        showNotification (type, message, exception = null, html) {
            const text = message || this.$t('Error. Something went wrong!')
            this.$store.dispatch('notification/setShow', {
                type,
                text: html ? html : text,
                html,
            })
            if (exception) {
                this.consoleError(exception)
            }
        },

        setLoadingBar (show) {
            this.$store.dispatch('loadingBar/setLoading', show, { root: true })
        },

        showNotice (notice) {
            this.$store.dispatch('notices/addNotice', {
                id: get(notice, 'id', uniqueId('notice_')),
                title: get(notice, 'title', null),
                caption: get(notice, 'caption', null),
                colorClass: get(notice, 'colorClass', 'io-accent'),
                icon: get(notice, 'icon', 'fas fa-check-circle'),
                routeLink: get(notice, 'routeLink', null),
                cutTitle: get(notice, 'cutTitle', true),
                cutCaption: get(notice, 'cutCaption', true),
                timer: get(notice, 'timer', 6000),
                hideDismissButton: get(notice, 'hideDismissButton', false),
                openNewTab: get(notice, 'openNewTab', false)
            })
        },

        hideNotice (noticeId) {
            this.$store.dispatch('notices/removeNotice', noticeId)
        },

        showAlert (alert) {
            const defaultButton = {
                text: 'Ok',
                class: 'io-btn-primary-compact',
                icon: null,
                action: null,
                closeAlert: true
            }
            this.$store.dispatch('notices/addAlert', {
                id: get(alert, 'id', uniqueId('alert_')),
                title: get(alert, 'title', null),
                caption: get(alert, 'caption', null),
                colorClass: get(alert, 'colorClass', 'io-accent'),
                icon: get(alert, 'icon', 'fas fa-exclamation-triangle'),
                showDismissButton: get(alert, 'showDismissButton', false),
                buttons: get(alert, 'buttons', [defaultButton])
            })
        },

        hideAlert (alertId) {
            this.$store.dispatch('notices/removeAlert', alertId)
        },

        showPopupAlert (popup) {
            const disabledPopups = JSON.parse(sessionStorage.getItem('disabledPopups') ?? '[]')

            if (disabledPopups.includes(popup?.sessionKey)) {
                return popup?.disabledInSessionCallback()
            }

            this.$store.dispatch('popupAlert/setPopupAlert', {
                icon: get(popup, 'icon', 'icon-alert-triangle'),
                customClass: get(popup, 'customClass', null),
                header: get(popup, 'header', null),
                width: get(popup, 'width', 540),
                title: get(popup, 'title', null),
                caption: get(popup, 'caption', null),
                captionHTML: get(popup, 'captionHTML', false),
                alert: get(popup, 'alert', null),
                buttons: get(popup, 'buttons', []),
                disableOutsideClose: get(popup, 'disableOutsideClose', true),
                onClose: get(popup, 'onClose', null),
                fullScreen: get(popup, 'fullScreen', null),
                radioOptions: get(popup, 'radioOptions', null),
                zIndex: get(popup, 'zIndex', null),
                iconClass: get(popup, 'iconClass', null),
                iconColor: get(popup, 'iconColor', null),
                sessionKey: get(popup, 'sessionKey', null),
                checkBox: get(popup, 'checkBox', null),
                input: get(popup, 'input', null),
                inputRequired: get(popup, 'inputRequired', false),
                inputType: get(popup, 'inputType', 'text'),
            })
        },

        consoleError (...params) {
            if (this.envIsDev) {
                console.error('Ingenious Error Log', params)
            }
        },
        consoleInfo (...params) {
            if (this.envIsDev) {
                console.info('Ingenious Info Log', params)
            }
        },
        consoleWarn (...params) {
            if (this.envIsDev) {
                console.warn('Ingenious Warn Log', params)
            }
        },
        consoleLog (...params) {
            if (this.envIsDev) {
                console.log('Ingenious Log', params)
            }
        },

        /**
         * Prepare keyword for search
         * @param value
         * @return {string}
         */
        prepareKeyword (value) {
            value = value || ''
            if (!value) {
                value = ''
            } else {
                value = value.toString()
            }
            return value.toLowerCase().trim()
        },

        /**
         * Object id test
         * @param param
         * @return {boolean}
         */
        isValidObjectId (param) {
            return ObjectIdTest.test(param)
        },

        /**
         * Prepare number to storage
         * @param value
         * @param precision
         * @param strictPrecision
         * @return {number}
         */
        toInt (value, precision = 2, strictPrecision = false) {
            if (value === null || (isString(value) && value.trim() === '')) {
                return null
            }
            value = strictPrecision
                ? Number(value * process.env.SCALE_FACTOR)
                : Number(Math.round(value * process.env.SCALE_FACTOR)) || 0
            return this.applyPrecision(value, precision, strictPrecision)
        },

        /**
         * Change big int to display value
         * @param value
         * @return {number}
         */
        toFloat (value) {
            if (value === null) {
                return null
            }
            return (Number(value / process.env.SCALE_FACTOR) || 0)
        },

        /**
         * Multiple two integers values
         * @param value
         * @param value2
         * @param precision
         * @return {number}
         */
        intMultiply (value, value2, precision = 2) {
            return this.intCutPrecision(
                this.toFloat(value * value2),
                precision
            )
        },

        /**
         * Multiple value by markup
         * @param value
         * @param markup
         * @param precision
         * @return {number}
         */
        intMultiplyMarkup (value, markup, precision = 2) {
            if (value === null) {
                return null
            }

            value = Number(value) || 0
            markup += this.toInt(1)

            return this.intCutPrecision(
                this.toFloat(value * markup),
                precision
            )
        },

        /**
         * Divide two integers values
         * @param value
         * @param value2
         * @param precision
         * @return {number}
         */
        intDivide (value, value2, precision = 2) {
            value = Number(value) || 0
            value2 = Number(value2) || 0

            return this.intCutPrecision(
                this.toInt(value / value2),
                precision
            )
        },

        /**
         * Multiple two integers values using second as percent value
         * @param value
         * @param percent
         * @param precision
         * @param strictPrecision
         * @return {number}
         */
        intMultiplyPercent (value, percent, precision = 2, strictPrecision = false) {
            value = Number(value) || 0
            percent = Number(percent) || 0

            return this.intCutPrecision(
                this.toFloat(value * percent) / 100,
                precision,
                strictPrecision
            )
        },

        /**
         * Cut precision if necessary - for example - 104567 to 104500
         * @param value
         * @param precision
         * @param strictPrecision
         * @return {number}
         */
        intCutPrecision (value, precision = 2, strictPrecision = false) {
            if (precision < 4) {
                const multiplier = Math.pow(10, 4 - precision)
                value = strictPrecision
                    ? this.strictPrecision(value / multiplier, precision)
                    : (value / multiplier).toFixed()
                value = (Number(value) || 0) * multiplier
            }
            return value
        },

        cutPrecision (value, precision = 2) {
            value = Number(value) || 0
            if (Number.isInteger(value)) {
                return value
            }

            precision = Math.pow(10, precision)
            return Math.sign(value) * Math.round(Math.abs(value) * precision) / precision
        },

        strictPrecision (value, precision = 2) {
            value = Number(value) || 0
            if (Number.isInteger(value)) {
                return value
            }
            const strValue = value.toString()
            return Number(strValue.substr(0, strValue.indexOf('.') + precision + 1))
        },

        applyPrecision (value, precision, strictPrecision = false) {
            return strictPrecision
                ? this.strictPrecision(value, precision)
                : this.cutPrecision(value, precision)
        },

        /**
         * Compare two values and get percent (first/second)
         * @param value
         * @param value2
         * @param precision
         * @param strictPrecision
         * @return {number}
         */
        intGetPercent (value, value2, precision = 2, strictPrecision = false) {
            if (value2 === 0) {
                return 0
            }

            return this.toInt((value / value2) * 100, precision, strictPrecision)
        },

        /**
         * Get markup between two values
         * @param value
         * @param value2
         * @return {number}
         */
        intGetMarkup (value, value2) {
            if (value2 === 0) {
                return 0
            }
            return this.toInt((value / value2 - 1) * 100)
        },

        /**
         * Radians to degrees
         * @param radians
         * @return {number}
         */
        rad2deg (radians) {
            return radians * (180 / Math.PI)
        },

        /**
         * Degrees to radians
         * @param degrees
         * @return {number}
         */
        deg2rad (degrees) {
            return degrees * (Math.PI / 180)
        },

        /**
         * Change big int to display value
         * @param value
         * @return {number}
         */
        dateFromMongo (object) {
            const momentObj = moment(parseInt(object.$date.$numberLong))
            return momentObj.format('YYYY-MM-DD HH:mm:ss')
        },

        generateUuid () {
            return crypto.randomUUID()
        },

        /**
         * Get uuid and set for object if required
         *
         * @param object
         * @param name
         * @return {*}
         */
        getUuid (object = null, name = 'id') {
            if (typeof object === 'object') {
                if (!object[name]) {
                    object[name] = this.generateUuid()
                }
                return object[name]
            } else {
                return null
            }
        },

        setFiles (id, model) {
            this.$store.dispatch('filesComponent/setMyDocs', {
                arrayId: id,
                files: model.model_documents ? (model.model_documents.files || []) : []
            }, { root: true })
            this.$store.dispatch('filesComponent/setSharedDocs', {
                arrayId: id,
                files: model.model_documents ? (model.model_documents.allowed || []) : []
            }, { root: true })
            this.$store.dispatch('filesComponent/setTrashedDocs', {
                arrayId: id,
                files: model.model_documents ? (model.model_documents.trash || []) : []
            }, { root: true })
            this.$store.dispatch('filesComponent/setFolders', {
                arrayId: id,
                folders: model.model_documents ? (model.model_documents.folders || []) : []
            })
            this.$store.dispatch(
                'filesComponent/setSyncAllSharedFiles',
                get(model, 'model_documents._settings_sync_all_shared_files', false)
            )
        },

        scrollToAttentionElement (elementId) {
            const element = document.getElementById(elementId)
            if (element !== null) {
                window.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: element.offsetTop
                })
            }
        },

        getDocumentPreviewUrl (fileId) {
            return this.$router.resolve({
                name: 'documents-details',
                params: { id: fileId }
            }).href
        },

        getApiUrlAvatar (userId) {
            return `${ this.apiUrl }/file/avatar/${ userId }`
        },

        getApiUrlCompanyLogo (id) {
            return `${ this.apiUrl }/file/logo/${ id }`
        },

        getApiUrlCompanyLogoExport (id) {
            return `${ this.apiUrl }/file/logo/${ id }/export`
        },

        getApiUrlProjectLogoExport (id) {
            return `${ this.apiUrl }/file/logo/${ id }/project`
        },

        /**
         *
         * @param mongoId: string | null
         * @param mysqlId: string | number | null
         * @returns {string|null}
         */
        getBackgroundCompanyLogoByIds (mongoId, mysqlId) {
            if (this.isValidMongoId(mongoId)) {
                return this.getBackgroundCompanyLogo(mongoId)
            }

            if (mysqlId) {
                return this.getBackgroundCompanyLogo(mysqlId)
            }
        },

        getBackgroundCompanyLogo (companyId) {
            return 'background-image: url(' + this.getApiUrlCompanyLogo(companyId) + ')'
        },

        getBackgroundThumb (fileId) {
            return 'background-image: url(' + this.getApiUrlThumb(fileId) + ')'
        },

        getApiUrlThumb (fileId) {
            return fileId ? `${ this.apiUrl }/file/thumb/${ fileId }` : '/images/silhouette.png'
        },

        getBackgroundAvatar (userId) {
            return 'background-image: url(' + this.getApiUrlAvatar(userId) + ')'
        },

        getBackgroundSvgIcon (iconName) {
            return `background: rgba(var(--accent-dev-rgb), .1) url('/new-theme/img/${ iconName }.svg') center no-repeat`
        },

        scrollToElement (selector, block = 'center', behavior = 'smooth') {
            const item = document.querySelector(selector)
            if (item !== null) {
                item.scrollIntoView({ behavior, block })
            }
        },

        scrollToElementWithOffset (selector, offset, behavior = 'smooth') {
            const element = document.querySelector(selector)
            if (element !== null) {
                const position = element.getBoundingClientRect().top + window.pageYOffset + offset
                window.scrollTo({ top: position, behavior })
            }
        },

        async wait (ms) {
            return new Promise(resolve => {
                setTimeout(resolve, ms)
            })
        },

        getAccentPillColor (appType) {
            switch (appType.toLowerCase()) {
                case this.appTypes.TYPE_GC:
                    return 'io-accent-gc'
                case this.appTypes.TYPE_AC:
                case this.appTypes.TYPE_DESIGN:
                    return 'io-accent-ac'
                case this.appTypes.TYPE_SC:
                case this.appTypes.TYPE_SUB:
                    return 'io-accent-sc'
                case this.appTypes.TYPE_OWNER:
                case this.appTypes.TYPE_DEV:
                case this.appTypes.TYPE_REP:
                default:
                    return 'io-accent-dev'
            }
        },

        getPillColor (appType) {
            switch (appType.toLowerCase()) {
            case this.appTypes.TYPE_OFF_SYSTEM:
                return 'io-grey'
            case this.appTypes.TYPE_GC:
                return 'io-orange'
            case this.appTypes.TYPE_AC:
            case this.appTypes.TYPE_DESIGN:
                return 'io-purple'
            case this.appTypes.TYPE_SC:
            case this.appTypes.TYPE_SUB:
                return 'io-yellow'
            case this.appTypes.TYPE_INVESTOR:
                return 'io-green'
            case this.appTypes.TYPE_OWNER:
            case this.appTypes.TYPE_DEV:
            case this.appTypes.TYPE_REP:
            default:
                return 'io-blue'
            }
        },

        getPillName (appType) {
            return getPillName(appType)
        },


        /**
         * Codes with categories names
         * @param codesOrigin
         * @return {*}
         */
        buildCodesWithCategoryNames (codesOrigin) {
            const codes = cloneDeep(codesOrigin)
            codes.forEach(category => {
                category.codes.forEach(code => {
                    if (!code.code_name_with_category) {
                        code.code_name_with_category = `${ category.code_name } - ${ code.code_name }`
                    }
                })
            })
            return codes
        },

        /**
         * add n numbers with decimal precision, example: sum([2,3,5.45], 1) = 2+3+5.45 = 10.4
         * @param {number[]} values
         * @param {number} precision
         * @returns {number}
         */
        add (values, precision = 2) {
            const total = values.reduce((previousValue, currentValue) => {
                return (Number(previousValue) || 0) + (Number(currentValue) || 0)
            })
            return this.cutPrecision(total, precision)
        },

        /**
         * sub n numbers with decimal precision, example: sum([150,30,20]) = 150-30-20 = 100
         * @param {number[]} values
         * @param {number} precision
         * @returns {number}
         */
        sub (values, precision = 2) {
            const total = values.reduce((previousValue, currentValue) => {
                return (Number(previousValue) || 0) - (Number(currentValue) || 0)
            })
            return this.cutPrecision(total, precision)
        },

        /**
         *
         * @param {number} dividend
         * @param {number} divisor
         * @param {number} precision
         * @returns {*|number}
         */
        divide (dividend, divisor, precision = 2) {
            return this.cutPrecision((dividend / divisor), precision)
        },

        /**
         * multiply n numbers with decimal precision
         * @param {number[]} values
         * @param {number} precision
         * @returns {*|number}
         */
        multiply (values, precision = 2) {
            const total = values.reduce((previousValue, currentValue) => {
                return (Number(previousValue) || 0) * (Number(currentValue) || 0)
            })
            return this.cutPrecision(total, precision)
        },

        /**
         *
         * @param {number} value
         * @param {number} percent
         * @param {number} precision
         * @returns {*|number}
         */
        getPercent (value, percent, precision = 4) {
            // value * (percent/100)
            return this.multiply([value, (this.divide(percent, 100, precision))], precision)
        },

        changeRoute (route) {
            this.$router.push(route)
        },

        isAdmin () {
            return ['IT Administrator', 'Administrator'].includes(store.getters['appStore/getAuthData'].account_type)
        },

        hexToRgb (hexColor) {
            return hexColor.replace(
                /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
                (m, r, g, b) => '#' + r + r + g + g + b + b
            )
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16))
        },

        toQueryString (obj, prefix='') {
            let str = []

            for (let item in obj) {
                if (obj.hasOwnProperty(item)) {
                    let key = prefix ? `${ prefix }[${ item }]` : item
                    let value = obj[item]

                    if (!key) {
                        continue
                    }

                    if (isNull(value) || isEmpty(value)) {
                        continue
                    }
                    if (isObject(value)) {
                        value = this.toQueryString(value, prefix ? `${ prefix }[${ item }]` : item)
                        str.push(value)
                    } else {
                        str.push(`${ key }=${ value }`)
                    }
                }
            }

            return str.filter(Boolean).join('&')
        }
    }
}
