export enum Priorities {
    HIGH = 'HIGH_PRIORITY',
    MEDIUM = 'MEDIUM_PRIORITY',
    LOW = 'LOW_PRIORITY'
}

export enum RFITypes {
    PROJECT_RFI = 'PROJECT_RFI',
    SUB_RFI = 'SUB_RFI'
}

export enum ScheduleImpactType {
    DAYS = 'DAYS',
    WEEKS = 'WEEKS',
    TBD = 'TBD'
}

export enum ImpactType {
    TBD = 'TBD',
    YES = 'YES',
    NO = 'NO'
}

export enum RfiStatus {
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    RESPONDED = 'RESPONDED',
    REVIEWED = 'REVIEWED',
    CONVERTED_TO_PROJECT_RFI = 'CONVERTED_TO_PROJECT_RFI',
    DESIGN_REVIEW = 'DESIGN_REVIEW',
    RFI_CLARIFICATION = 'RFI_CLARIFICATION',
    SOLUTION_CLARIFICATION = 'SOLUTION_CLARIFICATION',
    CLOSED = 'CLOSED',
    ARCHIVED = 'ARCHIVED'
}

export enum Operations {
    CREATE = 'create',
    EDIT = 'edit',
}

export enum SidebarTab {
    DETAILS = 'details',
    REFERENCES = 'references',
    VERSIONS = 'versions',
    HISTORY = 'history',
}

export enum SidebarSection {
    DRAWINGS = 'drawings',
    SPECIFICATIONS = 'specifications',
}

export enum ClarificationModalType {
    QUESTION = 'QUESTION',
    SOLUTION = 'SOLUTION'
}

export enum RevisionCreateType {
    TRANSFER = 'TRANSFER',
    NEW = 'NEW'
}
