import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { cloneDeep } from 'lodash'
import dayjs from 'dayjs'

import appTypes from '@/base/appTypes.ts'
import useLoader from '@/composables/useLoader.ts'
import useRfiDictionaries from '@/io-modules/rfis/composables/useRfiDictionaries.ts'
import useRfiStatus from '@/io-modules/rfis/composables/useRfiStatus.ts'
import { addBusinessDays } from '@/helpers/addBusinessDays.ts'
import dateTimeHelper from '@/helpers/dateTime.ts'
import rfiClient from '@/io-modules/rfis/api-clients/rfiClient.ts'

import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOSwitcher from '@/components/atoms/IOSwitcher/IOSwitcher.vue'
import DrawingReferenceSelect from '@/components/drawing-reference-select/DrawingReferenceSelect.vue'
import CostImpact from '@/io-modules/rfis/components/cost-impact/CostImpact.vue'
import ScheduleImpact from '@/io-modules/rfis/components/schedule-impact/ScheduleImpact.vue'
import StatusChange from '@/components/status-change/StatusChange.vue'
import TooltipIcon from '@/components/atoms/TooltipIcon/TooltipIcon.vue'
import SpecReferenceSelect from '@/io-modules/specifications/components/spec-reference-select/SpecReferenceSelect.vue'
import JobsiteLocationsSelect from '@/components/jobsite-locations-select/JobsiteLocationsSelect.vue'
import IOTextarea from '@/components/atoms/IOTextarea/IOTextarea.vue'
import Files from '@/components/files-new/files.vue'
import InvitePreview from '@/components/invite-modal-v3/InvitePreview.vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'

import { PillSize, PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'
import { ButtonSize } from '@/components/atoms/IOButton/IOButton.ts'
import { FilesUploaderVariantEnum } from '@/components/files-new/enums/FilesUploaderVariantEnum.ts'
import { PreviewTypes } from '@/interfaces/components/invite-modal/PreviewTypes.ts'
import {
    ImpactType,
    Priorities,
    RFITypes,
    RfiStatus,
    ScheduleImpactType,
    RevisionCreateType,
} from '@/io-modules/rfis/enums/Rfi.ts'
import type SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument.ts'
import type {
    DrawingAnnotationPayload,
    FileInterface,
    PriorityOption,
    Rfi,
    RfiDocument,
    RfiOperationsModal,
    RfiOperationsModalActions,
    RfiPayload,
    Solution,
    SpecificationAnnotation,
    Dictionaries,
} from '@/io-modules/rfis/interfaces/Rfi.ts'
import type { Company, Person } from '@/io-modules/rfis/interfaces/ProjectDirectory.ts'
import type { Reference as DrawingReference } from '@/components/drawing-reference-select/interfaces/Sheet.ts'
import type { ProjectSettings } from '@/io-modules/rfis/interfaces/ProjectSettings.ts'
import type { Status } from '@/components/status-change/interfaces/StatusChange.ts'
import type { Reference as SpecReference } from '@/io-modules/specifications/components/spec-reference-select/interfaces/Specification.ts'
import type { JobsiteLocation } from '@/components/jobsite-locations-select/interfaces/JobsiteLocations.ts'
import type {
    Contact as ContactV2,
    InviteModalDonePayload,
} from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'


const WEEK_DAYS = 7

export default defineComponent({
    name: 'RfiOperationsSlideout',
    components: {
        InvitePreview,
        Files,
        JobsiteLocationsSelect,
        StatusChange,
        TooltipIcon,
        IOSwitcher,
        IOModal,
        DrawingReferenceSelect,
        CostImpact,
        ScheduleImpact,
        SpecReferenceSelect,
        IOTextarea,
        AlertBox,
    },
    props: {
        dictionaries: {
            type: Object as () => Dictionaries,
            default: null,
        },
        rfiType: {
            type: String as () => RFITypes,
            default: RFITypes.PROJECT_RFI,
        },
        projectId: {
            type: String,
            required: true,
        },
        rfi: {
            type: Object as () => Rfi,
            default: null,
        },
        initialDrawingReferences: {
            type: Array as () => DrawingReference[],
            default: () => [],
        },
        initialSpecReference: {
            type: Object as () => SpecReference,
            default: null,
        },
        revisionCreateType: {
            type: String as () => RevisionCreateType,
            default: '',
        },
        revisedRfiId: {
            type: String,
            default: '',
        },
        isReviewMode: Boolean,
        isReSubmitMode: Boolean,
        loading: Boolean,
    },
    emits: ['close', 'created', 'updated', 'convertedToProjectRfi', 'resubmitted'],
    setup (props: { projectId: string }) {
        const rfiStatuses = useRfiStatus()
        const { loading: rfiLoading, load: loadRfi } = useLoader()
        const {
            getDictionaries, loading: dictionariesLoading,
            companies, officialReviewers, managers, allEmployees, projectSettings,
        } = useRfiDictionaries(props.projectId)
        const additionalReviewersAllowedClientTypes = Object
            .values(appTypes)
            .filter((appType: appTypes) => appType !== appTypes.TYPE_SUB)

        return {
            getDictionaries, dictionariesLoading,
            companies, officialReviewers, managers, allEmployees, projectSettings,
            RFITypes, appTypes, PreviewTypes, ButtonSize,
            rfiLoading, loadRfi,
            rfiStatuses,
            additionalReviewersAllowedClientTypes,
            v$: useVuelidate(),
        }
    },
    data () {
        return {
            title: '',
            priority: null as PriorityOption,
            dueDate: dayjs().format('YYYY-MM-DD 12:00:00'),
            question: '',
            responsibleContractors: [] as Company[],
            isSolutionProposed: false,
            initialSolution: {
                text: '',
                costImpact: {
                    occurred: ImpactType.TBD as ImpactType,
                    value: null as number | string,
                    isValueTbd: false,
                },
                scheduleImpact: {
                    occurred: ImpactType.TBD as ImpactType,
                    value: null as number | string,
                    type: ScheduleImpactType.DAYS as ScheduleImpactType,
                },
                documents_ids: [] as string[],
            },
            officialReviewer: null as Person | ContactV2,
            additionalReviewers: [] as Person[] | ContactV2[],
            rfiManager: null as Person | ContactV2,
            impactedParties: [] as Person[] | ContactV2[],
            rfiId: null as string,
            documents_ids: [] as string[],
            drawingReferences: cloneDeep(this.initialDrawingReferences) as DrawingReference[],
            specReference: cloneDeep(this.initialSpecReference) as SpecReference | SpecificationAnnotation,
            jobsiteLocations: [] as JobsiteLocation[][],
            tags: [],
            FilesUploaderVariantEnum,
        }
    },
    computed: {
        ...mapGetters('appStore', ['isSubcontractor']),
        currentUserId (): string {
            return this.$store.getters['appStore/getAuthData'].u_mongo
        },
        priorityOptions (): PriorityOption[] {
            return [
                {
                    id: Priorities.HIGH,
                    name: this.$t('High Priority'),
                    variant: PillVariant.RED_LIGHT,
                },
                {
                    id: Priorities.MEDIUM,
                    name: this.$t('Medium Priority'),
                    variant: PillVariant.YELLOW_LIGHT,
                },
                {
                    id: Priorities.LOW,
                    name: this.$t('Low Priority'),
                    variant: PillVariant.GREEN_LIGHT,
                },
            ]
        },
        datePickerSettings (): { formatted: string, noClearButton: boolean, 'only-date': boolean } {
            return {
                formatted: dateTimeHelper.getDateFormat(),
                noClearButton: true,
                'only-date': true,
            }
        },
        ballInCourtPayload (): { ball_in_court_id: string } | null {
            if (this.rfiType === RFITypes.PROJECT_RFI || !this.isSubcontractor) {
                return { ball_in_court_id: this.officialReviewer?.id || null }
            }

            if (this.rfiType === RFITypes.SUB_RFI) {
                return { ball_in_court_id: this.rfiManager?.id || null }
            }

            return null
        },
        rfiTypePayload (): RFITypes {
            return this.rfiType === RFITypes.SUB_RFI && this.isReviewMode
                ? RFITypes.PROJECT_RFI
                : this.rfiType
        },
        rfiPayload (): RfiPayload {
            return {
                title: this.title,
                type: this.rfiTypePayload,
                due_date: this.dueDate,
                question: this.question,
                priority: this.priority?.id,
                drawing_annotation: this.drawingReferencePayload,
                ball_in_court_id: this.rfi?.ball_in_court?.id ?? this.currentUserId,
                manager_id: this.rfiManager?.id,
                official_reviewer_id: this.rfiTypePayload === RFITypes.PROJECT_RFI ? this.officialReviewer?.id : null,
                additional_reviewers_ids: this.additionalReviewers.map((item: Person | ContactV2) => item.id),
                responsible_contractors_ids: this.responsibleContractors.map((item: Company) => item.id),
                impacted_parties_ids: this.impactedParties.map((item: Person | ContactV2) => item.id),
                documents_ids: this.documents_ids,
                sub_rfi_id: this.isReviewMode ? this.rfiId : null,
                ...(this.isSolutionProposed && {
                    initial_solution: {
                        text: this.initialSolution.text,
                        cost_impact: this.initialSolution.costImpact.occurred,
                        cost_impact_value: this.initialSolution.costImpact.occurred !== ImpactType.YES
                            ? null
                            : this.initialSolution.costImpact.isValueTbd ? null : this.initialSolution.costImpact.value,
                        schedule_impact: this.initialSolution.scheduleImpact.occurred,
                        schedule_impact_value: this.initialSolution.scheduleImpact.occurred !== ImpactType.YES
                            ? null
                            : this.initialSolution.scheduleImpact.type === ScheduleImpactType.TBD ? null : this.initialSolution.scheduleImpact.value,
                        schedule_impact_type: this.initialSolution.scheduleImpact.occurred !== ImpactType.YES
                            ? null
                            : this.initialSolution.scheduleImpact.type,
                        documents_ids: this.initialSolution.documents_ids,
                    },
                }),
                ...(this.specReference && {
                    specification_annotation: {
                        id: this.isReviewMode ? null : this.specReference?.id,
                        version_id: this.specReference.version_id,
                        xfdf: this.specReference.xfdf,
                        uuid: this.specReference.uuid,
                        text: this.specReference.text,
                    },
                }),
                revised_rfi_id: this.revisedRfiId,
            }
        },
        drawingReferencePayload (): DrawingAnnotationPayload {
            const [drawingReference] = this.drawingReferences

            return drawingReference
                ? {
                    id: this.isReviewMode ? null : drawingReference.id,
                    version_id: drawingReference.drawing.current_version_id,
                    xfdf: drawingReference.xfdf,
                    uuid: drawingReference.uuid,
                }
                : null
        },
        operation (): RfiOperationsModal {
            const convertToProjectRfi: RfiOperationsModal = {
                title: this.rfiType === RFITypes.SUB_RFI
                    ? this.$t('Convert to Project RFI')
                    : this.$t('Review RFI'),
                action: 'create',
                event: 'convertedToProjectRfi',
            }

            const edit: RfiOperationsModal = {
                title: this.$t('Edit RFI'),
                action: 'update',
                event: 'updated',
            }

            const create: RfiOperationsModal = {
                title: this.$t('Create RFI'),
                action: 'create',
                event: 'created',
            }

            const resubmit: RfiOperationsModal = {
                title: this.$t('Re-submit RFI'),
                action: 'update',
                event: 'resubmitted',
            }

            if (this.isEditMode) {
                if (this.isReviewMode) {
                    return convertToProjectRfi
                }

                if (this.isReSubmitMode) {
                    return resubmit
                }

                return edit
            }

            return create
        },
        isEditMode (): boolean {
            return Boolean(this.rfi) && !this.revisedRfiId
        },
        statuses (): Status[] {
            const current: Status = {
                text: this.rfiStatuses[this.revisedRfiId ? RfiStatus.DRAFT : this.rfi?.status || RfiStatus.DRAFT]?.text,
                variant: this.rfiStatuses[this.revisedRfiId ? RfiStatus.DRAFT : this.rfi?.status || RfiStatus.DRAFT]?.pillVariant,
                size: PillSize.BIG,
            }
            const submitted: Status = {
                text: this.rfiStatuses[RfiStatus.SUBMITTED]?.text,
                variant: this.rfiStatuses[RfiStatus.SUBMITTED]?.pillVariant,
                size: PillSize.BIG,
            }
            const designReview: Status = {
                text: this.rfiStatuses[RfiStatus.DESIGN_REVIEW]?.text,
                variant: this.rfiStatuses[RfiStatus.DESIGN_REVIEW]?.pillVariant,
                size: PillSize.BIG,
            }

            return [
                current,
                this.isReviewMode || this.rfiType === RFITypes.PROJECT_RFI
                    ? designReview
                    : submitted,
            ]
        },
        saveAsDraftButtonTitle (): string {
            return this.isReviewMode
                ? this.$t('Save as Project RFI in Draft')
                : this.$t('Save as Draft')
        },
        submitButtonTitle (): string {
            return this.isReviewMode
                ? this.$t('Submit to Official Reviewer')
                : this.$t('Submit')
        },
    },
    async created () {
        this.$store.dispatch('filesComponent/removeFilesComponent', 'question-files')
        this.$store.dispatch('filesComponent/removeFilesComponent', 'solution-files')

        await this.setDictionaries()

        this.isEditMode || this.revisionCreateType === RevisionCreateType.TRANSFER
            ? this.setRfiData(this.rfi)
            : await this.clearFiles()

        this.setDefaultResponsibleContractors()
    },
    unmounted () {
        this.$store.dispatch('filesComponent/removeFilesComponent', 'question-files')
        this.$store.dispatch('filesComponent/removeFilesComponent', 'solution-files')
    },
    methods: {
        async clearFiles (): Promise<void> {
            await this.$store.dispatch('filesComponent/setMyDocs', {
                arrayId: 'question-files',
                files: []
            })
            await this.$store.dispatch('filesComponent/setMyDocs', {
                arrayId: 'solution-files',
                files: []
            })
        },
        mapProjectSettingsToRfi (projectSettings: ProjectSettings): void {
            if (this.isEditMode) {
                return
            }

            this.officialReviewer = projectSettings.reviewer
            this.rfiManager = projectSettings.manager
            this.impactedParties = projectSettings.impacted_parties
            this.dueDate = this.calculateDueDate(projectSettings)
        },
        calculateDueDate (projectSettings: ProjectSettings): string {
            const {
                reviewer_review_duration,
                reviewer_review_duration_unit,
                manager_review_duration,
                manager_review_duration_unit,
            } = projectSettings

            const days = this.rfiType === RFITypes.PROJECT_RFI
                ? reviewer_review_duration_unit === ScheduleImpactType.DAYS ? reviewer_review_duration : reviewer_review_duration * WEEK_DAYS
                : manager_review_duration_unit === ScheduleImpactType.DAYS ? manager_review_duration : manager_review_duration * WEEK_DAYS

            return addBusinessDays(this.dueDate, days, 'YYYY-MM-DD 12:00:00')
        },
        close (): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to exit?'),
                caption: this.$t('There are unsaved changes. If you exit now, your changes will be lost.'),
                icon: 'icon-alert-triangle',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Exit'),
                        class: 'io-btn-primary',
                        action: (): void => {
                            this.$emit('close')
                        },
                    },
                ],
            })
        },
        async submitOperation (action: RfiOperationsModalActions, isSubmit: boolean = false): Promise<void> {
            action === 'create'
                ? await this.createRfi()
                : await this.updateRfi()

            isSubmit && await this.submit()

            this.$emit(this.operation.event, this.rfiId)
        },
        submit (): Promise<void> {
            return this.loadRfi(async () => {
                await rfiClient.submitRfi(this.projectId, this.rfiId, this.ballInCourtPayload)
            })
        },
        createRfi (): Promise<void> {
            return this.loadRfi(async () => {
                const { data } = await rfiClient.createRfi(this.projectId, this.rfiPayload)
                this.rfiId = data.id
            })
        },
        setRfiData (rfi: Rfi): void {
            const initialSolution = this.isReviewMode
                ? null
                : rfi.solutions.find((item: Solution) => item.is_initial)

            this.rfiId = rfi.id
            this.title = rfi.title
            this.priority = this.priorityOptions.find((item: PriorityOption) => item.id === rfi.priority)
            this.dueDate = rfi.due_date
            this.question = rfi.question
            this.isSolutionProposed = !!rfi.initial_solution
            this.responsibleContractors = rfi.responsible_contractors
            this.rfiManager = rfi.manager
            this.initialSolution.text = initialSolution?.text
            this.isSolutionProposed = !!initialSolution?.text
            this.initialSolution.costImpact.occurred = initialSolution?.project_impact.cost_impact || ImpactType.TBD
            this.initialSolution.costImpact.value = initialSolution?.project_impact?.cost_impact_value || null
            this.initialSolution.costImpact.isValueTbd = this.initialSolution.costImpact.occurred === ImpactType.TBD
            this.initialSolution.scheduleImpact.occurred = initialSolution?.project_impact.schedule_impact || ImpactType.TBD
            this.initialSolution.scheduleImpact.value = initialSolution?.project_impact.schedule_impact_value || null
            this.initialSolution.scheduleImpact.type = initialSolution?.project_impact.schedule_impact_type || ScheduleImpactType.DAYS
            this.initialSolution.documents_ids = initialSolution?.documents.map((document: RfiDocument) => document.id) || []
            initialSolution?.documents && this.$store.dispatch('filesComponent/setMyDocs', {
                arrayId: 'solution-files',
                files: [...initialSolution.documents],
            })
            this.impactedParties = rfi.impacted_parties
            this.officialReviewer = rfi.official_reviewer
            this.additionalReviewers = rfi.additional_reviewers
            this.drawingReferences = rfi.drawing_annotation ? [rfi.drawing_annotation] : []
            this.specReference = rfi.specification_annotation
            this.documents_ids = rfi.documents.map((document: RfiDocument) => document.id)
            rfi.documents && this.$store.dispatch('filesComponent/setMyDocs', {
                arrayId: 'question-files',
                files: [...rfi.documents],
            })
        },
        updateRfi (): Promise<void> {
            return this.loadRfi(async () => {
                await rfiClient.updateRfi(this.projectId, this.rfiId, this.rfiPayload)
            })
        },
        setDefaultResponsibleContractors (): void {
            this.responsibleContractors = this.companies.filter((item: Company) =>
                item.persons.some((person: Person) => person.id === this.currentUserId),
            )
        },
        onDocumentAdded ({ document }: { document: SingleModelDocument }): void {
            this.documents_ids.push(document._id)
        },
        onDocumentRemoved (file: FileInterface): void {
            this.documents_ids = this.documents_ids.filter((fileId: string) => fileId !== file.id)
        },
        updateRfiManager (data: InviteModalDonePayload): void {
            [this.rfiManager] = data.invited
        },
        onSolutionDocumentAdded ({ document }: { document: SingleModelDocument }): void {
            this.initialSolution.documents_ids.push(document._id)
        },
        onSolutionDocumentRemoved (fileId: string): void {
            this.initialSolution.documents_ids = this.initialSolution.documents_ids.filter((id: string) => id !== fileId)
        },
        updateOfficialReviewer (data: InviteModalDonePayload): void {
            [this.officialReviewer] = data.invited
        },
        updateAdditionalReviewers (data: InviteModalDonePayload): void {
            this.additionalReviewers = data.invited
        },
        updateImpactedParties (data: InviteModalDonePayload): void {
            this.impactedParties = data.invited
        },
        async setDictionaries (): Promise<void> {
            const setProvidedDictionary = (key: string): void => this[key] = this.dictionaries[key]

            this.dictionaries
                ? Object
                    .keys(this.dictionaries)
                    .forEach(setProvidedDictionary)
                : await this.getDictionaries()

            this.mapProjectSettingsToRfi(this.projectSettings)
        },
    },
    validations () {
        return {
            title: { required, $autoDirty: true },
            question: { required, $autoDirty: true },
            initialSolution: {
                text: {
                    required: requiredIf(() => {
                        return this.isSolutionProposed
                    }),
                    $autoDirty: true,
                },
            },
            officialReviewer: {
                required: requiredIf(() => {
                    return this.rfiType === RFITypes.PROJECT_RFI || !this.isSubcontractor
                }),
                $autoDirty: true,
            },
            rfiManager: { required, $autoDirty: true },
        }
    },
})
