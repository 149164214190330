import { defineComponent } from 'vue'
import SingleContact from './SingleContact.vue'

export default defineComponent({
    components: {
        SingleContact
    },
    emits: ['change', 'update:modelValue'],
    props: {
        componentKey: { type: String, required: true },
        idx: { type: Number, required: true },
        title: { type: String, default: '' },
        description: { type: String, default: '' },
        value: { type: String, default: undefined },
        modelValue: { default: ''}
    },
    data () {
        return {
            currentValue: null,
        }
    },
    computed: {
        isChecked () {
            return this.currentValue == this.value
        }
    },
    watch: {
        currentValue () {
            this.$emit('update:modelValue', this.currentValue)
        },
        modelValue () {
            this.currentValue = this.modelValue
        },
    },
    mounted () {
        this.currentValue = this.modelValue
        // @ts-ignore
        this.preselectAction()
    },
    methods: {
       preselectAction () {
           if (this.idx === 0) {
               this.$emit('update:modelValue', this.value)

               const input = document.querySelector(`.io-action-radio-${this.idx}`) as HTMLElement

               if (input) {
                   input.click()
               }
           }
       }
    }
})
