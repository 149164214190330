import { FilterValue } from '../types/FilterValue.ts'
import { LocationQuery } from 'vue-router'

const parseBooleanString = (value: string): boolean => {
    return value === 'true'
}

const parseStringValue = (value: string): string | number => {
    return !isNaN(Number(value)) ? Number(value) : value
}

const isBooleanString = (value: string): boolean => {
    return ['false', 'true'].includes(value)
}

const parseFilterKey = (key: string): null | { mainKey: string, subKey: string | null } => {
    const match = key.match(/^(\w+)\[(\w*)\]$/)
    if (!match) {
        return null
    }
    return {
        mainKey: match[1],
        subKey: match[2] || null
    }
}

const parseArrayString = (value: string): (string | number)[] => {
    return value.split(',').map(parseStringValue)
}

export const parseQueryObject = (query: LocationQuery): Record<string, FilterValue> => {
    return Object
        .entries(query)
        .reduce((filters: Record<string, FilterValue>, [filterKey, filterValue]: [string, typeof query[string]]): Record<string, FilterValue> => {
            if (Array.isArray(filterValue)) {
                filters[filterKey] = filterValue.map(parseStringValue)
                return filters
            }

            const decodedFilterValue = decodeURIComponent(filterValue)
            const parsedKey = parseFilterKey(filterKey)
            if (parsedKey) {
                if (!parsedKey.subKey) {
                    filters[parsedKey.mainKey] = parseArrayString(decodedFilterValue)
                    return filters
                }

                if (!filters[parsedKey.mainKey]) {
                    filters[parsedKey.mainKey] = {}
                }
                filters[parsedKey.mainKey][parsedKey.subKey] = decodedFilterValue
                return filters
            }

            if (isBooleanString(decodedFilterValue)) {
                filters[filterKey] = parseBooleanString(decodedFilterValue)
                return filters
            }

            filters[filterKey] = decodedFilterValue
            return filters
        }, {})
}

export const parseSearchParamsString = (searchParams: string): Record<string, FilterValue> => {
    const queryObject = Object.fromEntries(new URLSearchParams(searchParams))
    return parseQueryObject(queryObject)
}

const stringifyFilterOptions = (options: (string | number)[]): string => {
    return options.map(String).join(',')
}

function* generateStringFilter(filterKey: string, filterValue: string): Generator<[string, string]> {
    if (filterValue) {
        yield [filterKey, filterValue];
    }
}

function* generateBooleanFilter(filterKey: string, filterValue: boolean): Generator<[string, string]> {
    yield [filterKey, String(filterValue)];
}

function* generateArrayFilter(filterKey: string, filterValue: (string | number)[]): Generator<[string, string]> {
    yield [`${filterKey}[]`, stringifyFilterOptions(filterValue)];
}

function* generateObjectFilter(filterKey: string, filterValue: Record<string, string>): Generator<[string, string]> {
    for (const fieldName of Object.keys(filterValue)) {
        if (filterValue[fieldName] && typeof filterValue[fieldName] === 'string') {
            yield [`${filterKey}[${fieldName}]`, filterValue[fieldName]];
        }
    }
}

function* generateFilters (filters: Record<string, FilterValue>): Generator<[string, string]> {
    for (const [filterKey, filterValue] of Object.entries(filters)) {
        switch (typeof filterValue) {
        case 'string':
            yield* generateStringFilter(filterKey, filterValue)
            break
        case 'boolean':
            yield* generateBooleanFilter(filterKey, filterValue)
            break
        case 'object':
            if (Array.isArray(filterValue) && !!filterValue.length) {
                yield* generateArrayFilter(filterKey, filterValue)
                break
            }
            if (filterValue !== null) {
                yield* generateObjectFilter(filterKey, filterValue as Record<string, string>)
            }
            break
        }
    }
}

export const stringifyFilters = (filters: Record<string, FilterValue>): string => {
    const searchParams = new URLSearchParams()

    for (const [key, value] of generateFilters(filters)) {
        searchParams.append(key, encodeURIComponent(value))
    }

    return searchParams.toString()
}
