import { defineComponent, ExtractPublicPropTypes } from 'vue'

export enum Position {
    TOP = 'top',
    RIGHT = 'right',
    BOTTOM = 'bottom',
    LEFT = 'left',
}

export enum Align {
    START = 'start',
    CENTER = 'center',
    END = 'end',
}

const props = {
    url: {
        type: String,
        required: true,
    },
    width: {
        type: [Number, String],
        default: '',
    },
    height: {
        type: [Number, String],
        default: '',
    },
    position: {
        type: String,
        default: Position.BOTTOM,
    },
    align: {
        type: String,
        default: Align.CENTER,
    },
    offset: {
        type: [Number, String],
        default: 16,
    },
    frame: {
        type: [Number, String],
        default: 0,
    },
    zIndex: {
        type: [String, Number],
        default: 2,
    },
    shadow: Boolean,
}

export type Props = ExtractPublicPropTypes<typeof props>

export default defineComponent({
    name: 'IOThumbnail',
    props,
    data () {
        return {
            loaded: false,
            error: null,
        }
    },
    computed: {
        offsetMarginStyle (): Record<string, string> {
            const margins = {
                [Position.TOP]: 'marginBottom',
                [Position.RIGHT]: 'marginLeft',
                [Position.BOTTOM]: 'marginTop',
                [Position.LEFT]: 'marginRight',
            }

            return { [margins[this.position]]: `${ this.offset }px` }
        }
    },
})
