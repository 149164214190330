<template>
    <div class="io-design-system io-status-timeline">
        <div
            v-for="(item, index) in timelineItems"
            :key="index"
            class="io-status-timeline__status io-f-row"
            :class="{
                'io-status-timeline__status--active': item.isActive,
                'io-status-timeline__status--current': item.isCurrent,
            }"
        >
            <div class="io-status-timeline__point"></div>
            <div class="io-status-timeline__right">
                <div class="io-status-timeline__text">{{ item.title }}</div>
                <div class="io-status-timeline__caption">{{ item.description }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./SingleColumnTimeline.ts"></script>
<style lang="scss" src="./SingleColumnTimeline.scss" scoped></style>
