export function SET_TYPES (state, types) {
    state.submittalTypes = types
}

export function SET_TYPE_TITLE (state, type) {
    state.submittalTypes.forEach((item, index) => {
        if (index !== type.index) {
            return
        }

        item.title = type.title
    })
}

export function TOGGLE_REQUIRED (state, data) {
    state.submittalTypes.forEach((item, index) => {
        if (index !== data.id) {
            return
        }

        item.is_required_for_material_release = data.isRequired
    })
}

export function REMOVE_TYPE (state, id) {
    const maxIndex = state.submittalTypes.length - 1

    if (maxIndex < id) {
        return
    }

    state.submittalTypes.splice(id, 1)
}

export function ADD_TYPE (state, value) {
    state.submittalTypes.push(value)
}

export function SET_SCHEDULE_SETTINGS (state, scheduleSettings) {
    state.scheduleSettings = scheduleSettings
}
