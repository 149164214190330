<template>
    <div v-if="summary" class="io-project-funding-summary">
        <FinancialInfoTile
            :title="$t('Total Funding Source Amount')"
            icon-path="/new-theme/img/founding.svg"
            class="io-financial-info-tile--default"
            :value="$filters.formatCurrency(summary.funding_amount)"
        />
        <FinancialInfoTile
            title="Current Budget"
            :icon-span="{name: 'icon-cash', backgroundColor: '#00E8DC'}"
            class="io-financial-info-tile"
            :value="$filters.formatCurrency(summary.budget_amount)"
            @clicked="goToProjectBudget"
        >
            <template #description>
                {{ $t("Funding Variance to Current Budget") }} ({{ ($t("C")) }}) {{ $filters.formatCurrency(summary.funding_budget_amount) }}
            </template>
        </FinancialInfoTile>
        <FinancialInfoTile
            :title="$t('Total Anticipated Cost')"
            :icon-span="{name: 'icon-chart-line', backgroundColor: '#EDB208'}"
            class="io-financial-info-tile"
            :value="$filters.formatCurrency(summary.anticipated_amount)"
            @clicked="goToProjectBudget"
        >
            <template #description>
                {{ $t("Funding Variance to Total Anticipated") }} ({{ $t("M") }}) {{ $filters.formatCurrency(summary.funding_anticipated_amount) }}
            </template>
        </FinancialInfoTile>
        <FinancialInfoTile
            :title="$t('Billed to Date')"
            :icon-span="{name: 'icon-wallet', backgroundColor: '#288681'}"
            class="io-financial-info-tile"
            :value="$filters.formatCurrency(summary.billed_amount)"
            @clicked="goToProjectBudget"
        >
            <template #description>
                {{ $t("Funding Variance to Billed To Date") }} ({{ $t("S") }}) {{ $filters.formatCurrency(summary.funding_billed_amount) }}
            </template>
        </FinancialInfoTile>
    </div>
</template>


<script lang="ts" src="./ProjectFundingSummary.ts"></script>
<style lang="scss" src="./ProjectFundingSummary.scss" scoped></style>
