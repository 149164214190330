import { defineComponent , PropType } from 'vue'

import menuLeftClient from '@/layouts/parts/menu-left/api-clients/menuLeftClient'

import { Notification } from '@/layouts/parts/menu-left/interfaces/MenuLeftInterface'

import RejectModal from './reject-modal/RejectModal.vue'

export default defineComponent({
    name: 'Invitation',
    props: {
        notificationData: { type: Object as PropType<Notification>, required: true }
    },
    components: {
        RejectModal
    },
    data () {
        return {
            isLoading: false,
            isRejectInvitationModalVisible: false
        }
    },
    computed: {
        isInvitationControlsVisible (): boolean {
            return this.notificationData.additionalParams.invitationStatus === 'invited'
        },
        isInvitationAccepted (): boolean {
            return this.notificationData.additionalParams.invitationStatus === 'accepted'
        },
        isInvitationRejected (): boolean {
            return this.notificationData.additionalParams.invitationStatus === 'rejected'
        },
        isInvitationStatusVisible (): boolean {
            return this.notificationData.additionalParams.invitationStatus === 'accepted'
                   || this.notificationData.additionalParams.invitationStatus === 'rejected'
        }
    },
    methods: {
        async onAcceptInvitationClick (): Promise<void> {
            this.setIsLoading(true)

            try {
                const { data } = await menuLeftClient.acceptInvitation(this.notificationData.relatedId)
                const currentNotificationData: Notification = { ...this.notificationData }

                currentNotificationData.isUnread = false
                currentNotificationData.additionalParams.invitationStatus = 'accepted'
                currentNotificationData.additionalParams.gotoUrl = data.custom_url

                this.$emit('updateNotification', currentNotificationData)
            } catch (error) {
                this.errorHandle(error)
            } finally {
                this.setIsLoading(false)
            }
        },
        async onInvitationReject (payload): Promise<void> {
            this.setIsLoading(true)

            try {
                await menuLeftClient.rejectInvitation({
                    invitationId: this.notificationData.relatedId,
                    reason: payload.reason
                })

                const currentNotificationData: Notification = { ...this.notificationData }

                currentNotificationData.isUnread = false
                currentNotificationData.additionalParams.invitationStatus = 'rejected'

                this.$emit('updateNotification', currentNotificationData)

                this.showNotification(
                    'warning',
                    this.$t(
                        'Invitation to %{title} has been rejected!',
                        {
                            title: this.notificationData.additionalParams.invitationElementName
                        }
                    )
                )

            } catch (error) {
                this.errorHandle(error)
            } finally {
                this.setIsLoading(false)
                this.toggleRejectModalVisibility()
            }
        },
        onGoToClick (event: Event): void {
            event.stopPropagation()

            if (this.$route.path !== this.notificationData.additionalParams.gotoUrl) {
                this.$router.push({ path: this.notificationData.additionalParams.gotoUrl })
            }

            this.$emit('notificationCenterClose')
        },
        onNotificationWrapperClick (event: Event): void {
            //@ts-ignore
            if (this.isLoading || event.target.className === 'io-popup-modal__mask') {
                return
            }

            if (this.notificationData.isUnread) {
                this.markAsRead()
            }

            const notificationUrl = `/invitation/view/${ this.notificationData.relatedId }`

            if (this.$route.path !== notificationUrl) {
                this.$router.push(notificationUrl)
            }

            this.$emit('notificationCenterClose')
        },
        async markAsRead (): Promise<void> {
            try {
                await menuLeftClient.markSingleNotificationsAsRead(this.notificationData)
            } catch (error) {
                this.errorHandle(error)
            }
        },
        setIsLoading (isLoading: boolean): void {
            this.isLoading = isLoading
        },
        toggleRejectModalVisibility (): void {
            this.isRejectInvitationModalVisible = !this.isRejectInvitationModalVisible
        }
    }
})
