<template>
    <div class="io-project-summary__wrapper">
        <PageHeader :title="$t('Project Summary')" />
        <div v-if="rendered" class="io-project-summary__row">
            <ProjectSummaryNav />
            <div class="io-project-summary__middle-col">
                <ProjectScope
                    :scope="summaryData.project.scope"
                />
                <ProjectExclusions
                    :exclusions="summaryData.project.exclusions"
                />
            </div>
            <ProjectInformation :summaryData="summaryData" />
        </div>
    </div>
</template>
<script lang="ts" src="./ProjectSummary.ts"></script>
<style lang="scss" src="./ProjectSummary.scss" scoped></style>
