import client from '@/api/lazy-list/LazyListClient'
import defaultState from './state'
import { cloneDeep, debounce, get } from 'lodash'

export const setConfig = function ({ commit, dispatch }, data) {
    commit('SET_CONFIG', data)
}

export const getListData = debounce(async ({ commit, state, dispatch }, resetState) => {
    if (resetState) {
        commit('RESET_RECORDS', [])
        commit('SET_LAST_PAGE', defaultState.isLastPage)
        commit('SET_SKIPPED', defaultState.skip)
        commit('SET_LIMIT', defaultState.limit)
    }

    if (!state.endpoint) {
        return
    }

    commit('SET_LOADING', true)

    if (state.filters) {
        state.filters.forEach((filter) => {
            if (filter?.settings?.mapIds) {
                if (!state.selectedFiltersOverload) {
                    state.selectedFiltersOverload = {}
                }
                state.selectedFiltersOverload[filter.name] = state.filtersSelected[filter.name].map((item) => item.id)
            }
        })
    }

    const params = {
        search: state.search,
        order: state.sortDirection,
        orderBy: state.sortBy, // This should not be here but it might be a breaking change.
        order_by: state.sortBy,
        page: state.page,
        per_page: state.perPage,
        filters: { ...state.filtersSelected, ...state.additionalQueryParams, ...(state.selectedFiltersOverload ?? {}) },
        with_counters: state.withCounters,
        with_filters: state.withFilters,
        ...state.endpointParams
    }

    if (state.isFetchAllRecords) {
        delete params.page
        delete params.per_page
    }

    commit('SET_REQUEST_PARAMS', params)

    const requestParams = {
        endpoint: state.endpoint,
        limit: state.limit,
        isFetchAllRecords: state.isFetchAllRecords,
        params,
    }

    try {
        const { data } = await client.getRecords(requestParams, state.apiVersion)
        if (data) {
            if (resetState) {
                commit('RESET_RECORDS', [])
            }

            commit('SET_RECORDS', get(data, state.dataPropertyName ?? 'items'))
            commit('SET_LIMIT', state.limit + data.taken)
            commit('SET_ADDITIONAL_OPTIONS', data.options)
            commit('SET_CAN_EDIT', data.can_edit)
            commit('SET_APPLIED_FILTERS', cloneDeep(state.filtersSelected))
            if (get(data, state.dataPropertyName).length < state.takeItems) {
                commit('SET_LAST_PAGE', true)
            }
        }

        if (data.filters && data.filters.length) {
            dispatch('prepareAndSetFilters', data.filters)
        }

        commit('SET_ERROR_EXCEPTION', defaultState.exception)

        if (data.counters) {
            commit('SET_COUNTERS', data.counters)
        }

        if (state.hasAllRowsSelected && state.shouldSelectAllRows) {
            commit('SET_SELECTED_ROWS', state.dataList.map(item => item.id))
        }

        // in fields where we map IDs of objects, we need to convert them back to objects and set them as the multiselect value
        // because sticky filters can store only strings/numbers
        if (state.filters && state.withFilters) {
            state.filters.forEach((filter) => {
                if (filter?.settings?.mapIds) {
                    if (state.filtersSelected[filter.name] && state.filtersSelected[filter.name].length) {
                        const tempArray = []
                        state.filtersSelected[filter.name].forEach((option) => {
                            if (typeof option === 'string' || typeof option === 'number') {
                                tempArray.push(filter.options.find((el) => el.id === option))
                            }
                        })

                        state.filtersSelected[filter.name] = tempArray
                    }
                }
            })
        }


        return true
    } catch (exception) {
        commit('SET_ERROR_EXCEPTION', exception)
        return exception
    } finally {
        dispatch('setWithFilters', false)
        dispatch('setWithCounters', false)

        commit('SET_LOADING', false)

        if (!state.hasFetchedOnce) {
            commit('SET_HAS_FETCHED_ONCE', true)
        }
    }
}, 50)

export const getFiltersData = async function ({ commit, dispatch, state }) {
    const { filtersEndpoint: endpoint } = state

    if (!endpoint) {
        return null
    }

    const params = {
        search: state.search,
        order: state.sortDirection,
        orderBy: state.sortBy,
        page: state.page,
        per_page: state.perPage,
        filters: { ...state.filtersSelected, ...state.additionalQueryParams, ...(state.selectedFiltersOverload ?? {}) },
        with_counters: state.withCounters,
        with_filters: state.withFilters,
        ...state.endpointParams
    }

    const { data } = await client.getFiltersRecords(endpoint, params, state.apiVersion)

    if (data) {
        dispatch('prepareAndSetFilters', data.filters)
    }
}

export const setIsServerDefaultFiltersDisabled = function ({ commit }, value) {
    commit('SET_IS_SERVER_DEFAULT_FILTERS_DISABLED', value)
}

export const setSearch = function ({ commit, dispatch }, value) {
    commit('SET_SEARCH', value)
    clearTimeout(this.delayedSearch)
    this.delayedSearch = setTimeout(() => {
        dispatch('setWithCounters', true)
        dispatch('getListData', true)
    }, 200)
}

export const resetSearch = ({ commit }) => {
    commit('SET_SEARCH', '')
}

export const resetSortBy = ({ commit }) => {
    commit('SET_SORT_BY', '')
    commit('SET_SORT_DIRECTION', '')
}

export const changeSortBy = function ({ commit, state, dispatch }, value) {
    commit('SET_SORT_BY', value)
}

export const changeSortDirection = function ({ commit, dispatch }, value = null) {
    commit('SET_SORT_DIRECTION', value)
}

export const setFilters = function ({ commit, dispatch }, data) {
    commit('SET_FILTERS', data)
}

export const prepareAndSetFilters = function ({ commit, dispatch, state }, filters = []) {
    if (!state.isServerDefaultFiltersDisabled) {
        filters.forEach(filter => {
            dispatch('setSingleFilter', {
                field: filter.name,
                value: filter.default
            })
        })
    }
    commit('SET_FILTERS', filters)
}

export const setFiltersSelected = function ({ commit, dispatch }, data) {
    commit('SET_FILTERS_SELECTED', data)
    dispatch('setWithCounters', true)
}

export const setTakeItems = function ({ commit, state, dispatch }, value) {
    commit('SET_TAKE_ITEMS', value)
}
export const setPerPage = function ({ commit, state, dispatch }, value) {
    commit('SET_PER_PAGE', value)
}

export const resetFiltersSelected = function ({ commit, dispatch }) {
    commit('RESET_FILTERS_SELECTED')
    dispatch('setWithCounters', true)
}

export const setSingleFilter = function ({ commit, dispatch }, data) {
    commit('SET_SINGLE_FILTER', data)
}

export const setShowFilters = function ({ commit, dispatch }, value) {
    commit('SET_SHOW_FILTERS', value)
}

export const setWithFilters = function ({ commit, dispatch }, value) {
    commit('SET_WITH_FILTERS', value)
}

export const setWithCounters = function ({ commit, dispatch }, value) {
    commit('SET_WITH_COUNTERS', value)
}

export const setEndpointParams = function ({ commit, dispatch }, value) {
    commit('SET_ENDPOINT_PARAMS', value)
}

export const resetState = function ({ commit }) {
    commit('RESET_STATE')
}

export const resetEndpointParams = function ({ commit }) {
    commit('RESET_ENDPOINT_PARAMS')
}

export const resetRecords = function ({ commit }) {
    commit('RESET_RECORDS', [])
}

export const replaceRecords = function ({ commit }, data) {
    commit('RESET_RECORDS', data)
}

export const setRecords = function ({ commit }, data) {
    commit('RESET_RECORDS', [])
    commit('SET_RECORDS', data)
}

export const renameRecord = function ({ commit }, data) {
    commit('RENAME_RECORD', data)
}
export const addRecord = function ({ commit, dispatch }, value) {
    commit('ADD_RECORD', value)
}
export const toggleSelectedRows = function ({commit, dispatch}, value) {
    commit('TOGGLE_SELECTED_ROWS', value)
}
export const setSelectedRows = function ({commit, dispatch}, data) {
    commit('SET_SELECTED_ROWS', data)
}

export const setEditMode = function ({ commit, state, dispatch }, data) {
    commit('SET_EDIT_MODE', data.enabled)

    if (data.afterSave) {
        commit('SET_RECORDS_AFTER_SAVE')
    }

    commit('SET_EDITED_RECORDS', state.dataList)

    if (data.withClone) {
        commit('SET_EDITED_RECORDS_CLONE', state.dataList)
    }
}

export const setEditedRecords = function ({ commit }, data) {
    commit('SET_EDITED_RECORDS', data)
}

export const setEditedRecordsClone = function ({ commit }, data) {
    commit('SET_EDITED_RECORDS_CLONE', data)
}

export const deleteRecord = function ({ commit }, data) {
    commit('DELETE_RECORD', data)
}

export const setRecord = function ({commit, dispatch}, data) {
    commit('SET_RECORD', data)
}

export const updateImportStatus = function ({ commit }, data) {
    commit('UPDATE_IMPORT_STATUS_COMPLETION', data)
}

export const updateImportingPercentage = function ({ commit }, data) {
    commit('UPDATE_IMPORTING_PERCENTAGE', data)
}

export const setAdditionalQueryParams = function ({ commit }, data) {
    commit('SET_ADDITIONAL_QUERY_PARAMS', data)
}

export const setShouldSelectAllRows = function ({ commit }, data) {
    commit('SET_SHOULD_SELECT_ALL_ROWS', data)
}

export const setHasAllRowsSelected = function ({ commit }, data) {
    commit('SET_HAS_ALL_ROWS_SELECTED', data)
}

export const setIsFetchAllRecords = function ({ commit }, data) {
    commit('SET_IS_FETCH_ALL_RECORDS', Boolean(data))
}

export const setIsFrontEndSortingEnabled = function ( { commit }, data ) {
    commit('SET_IS_FRONTEND_SORTING_ENABLED', Boolean(data))
}

export const setSelectedFiltersOverloadData = function ( { commit }, data ) {
    commit('SET_SELECTED_FILTERS_OVERLOAD_DATA', data)
}

export const setLoading = function ( { commit }, data ) {
    commit('SET_LOADING', data)
}


