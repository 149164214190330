import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import CreateInvoiceResponse from '../interfaces/responses/CreateInvoiceResponse.ts'
import ClientInfo from '../interfaces/responses/ClientInfo'
import { components, paths } from '@/interfaces/api/apidefinitions'
import { InvoiceSubType } from '@/io-modules/invoices/enums/InvoiceSubType.ts'

export type InvoiceCreationOptions = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice-type-choices']['get']['responses']['200']['content']['application/json']
export type ExecutedContracts = components['schemas']['InvoiceTypeChoicesCreationDetails']['executed_contracts']

export type NonContractedInvoiceCreationDataResponse = paths['v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/creation/non-contracted']['get']['responses']['200']['content']['application/json']
export type VendorInvoiceCreationDataResponse = paths['v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/creation/vendor']['get']['responses']['200']['content']['application/json']
export type MyInvoiceCreationDataResponse = paths['v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/creation/my']['get']['responses']['200']['content']['application/json']

export type CreateNonContractedInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/non-contracted']['post']['requestBody']['content']['application/json']
export type CreateMyInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/my']['post']['requestBody']['content']['application/json']
export type CreateVendorInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/vendor']['post']['requestBody']['content']['application/json']

export type UpdateMyInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/my/{invoice_id}']['put']['requestBody']['content']['application/json']
export type UpdateVendorInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/vendor/{invoice_id}']['put']['requestBody']['content']['application/json']
export type UpdateNonContractedInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/non-contracted/{invoice_id}']['put']['requestBody']['content']['application/json']

export type UpdateWbsItem = components['schemas']['UpdateQuotedItemRequestData']
export type DraftAppPackage = components['schemas']['ApplicationPackageData']

export default {
    getInvoiceCreationTypes (projectMongoIdLocal: string): AxiosPromise<string[]> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/available-types`)
    },

    getInvoiceCreationOptions (projectMongoIdLocal: string): AxiosPromise<InvoiceCreationOptions> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice-type-choices`)
    },

    /**
     * Gets data required to initiate invoice creation wizard
     * @param projectMongoIdLocal
     */
    getMyInvoiceCreationData (projectMongoIdLocal: string): AxiosPromise<MyInvoiceCreationDataResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/creation/my`)
    },

    /**
     * Gets data required to initiate invoice creation wizard
     * @param projectMongoIdLocal
     */
    getNonContractedInvoiceCreationData (projectMongoIdLocal: string): AxiosPromise<NonContractedInvoiceCreationDataResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/creation/non-contracted`)
    },

    /**
     * Gets data required to initiate invoice creation wizard
     * @param projectMongoIdLocal
     */
    getVendorInvoiceCreationData (projectMongoIdLocal: string): AxiosPromise<VendorInvoiceCreationDataResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/creation/vendor`)
    },
    getInvoiceClientInfo (projectMongoIdLocal: string): AxiosPromise<ClientInfo> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/projects/${ projectMongoIdLocal }/main-contract/client`)
    },

    /**
     * This endpoint must be used to create all invoices except non-contracted
     * @param projectMongoIdLocal
     * @param data
     */
    createMyInvoice (projectMongoIdLocal: string, data: CreateMyInvoiceRequest): AxiosPromise<CreateInvoiceResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/invoice/my`, data)
    },
    /**
     * This endpoint must be used to create all invoices except non-contracted
     * @param projectMongoIdLocal
     * @param data
     */
    createVendorInvoice (projectMongoIdLocal: string, data: CreateVendorInvoiceRequest): AxiosPromise<CreateInvoiceResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/invoice/vendor`, data)
    },

    /** This endpoint must be used to create non-contracted invoices, as they have different input data */
    createNonContractedInvoice (projectMongoIdLocal: string, data: CreateNonContractedInvoiceRequest): AxiosPromise<CreateInvoiceResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/invoice/non-contracted`, data)
    },

    /**
     * Gets attachable vendor invoices for GC/Sub/Des
     * @param projectMongoIdLocal
     */
    getAttachableVendorInvoices (projectMongoIdLocal: string): AxiosPromise<void> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/projects/${ projectMongoIdLocal }/invoices/attachable`)
    },

    checkInvoiceUniqueID (projectMongoIdLocal: string, invoiceSubType: InvoiceSubType, uniqueID: string = '', vendor_id: string): AxiosPromise<boolean> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (InvoiceSubType.VendorInvoice === invoiceSubType) {
            return client.post(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceSubType }/check-unique-number`, { number: uniqueID, vendor_id })
        }

        return client.post(`/invoices/${ projectMongoIdLocal }/invoice/${ invoiceSubType }/check-unique-number`, { number: uniqueID })
    },
}
