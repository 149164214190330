import { mapState as mapStateVuex } from 'vuex'
import { mapState } from 'pinia'
import { invoiceViewStore } from '../../stores/invoiceView'
import SoVCommonMixin from './SoVCommonMixin'

const retentionSummaryMixin = {
    mixins: [SoVCommonMixin],
    computed: {
        ...mapStateVuex('payapps', {
            flags: (state) => state.payApp.flags,
        }),

        ...mapState(invoiceViewStore, ['isRetentionInvoice']),
    },
    methods: {
        totalCompletedStoredAmount (item: object): number {
            return this.getSumOfTasksValues(item, 'previous_applications') + this.getSumOfTasksValues(item, 'materials_stored') + this.getSumOfTasksValues(item, 'current_application')
        },

        previousRetentionAmount (item: object): number {
            return this.getSumOfTasksValues(item, 'previous_retention_amount')
        },

        previousRetentionPercent (item: object): number {
            if (!this.previousRetentionAmount(item)) {
                return 0
            }

            const value = (this.previousRetentionAmount(item) / this.getSumOfTasksValues(item, 'previous_applications')) * 100  * process.env.SCALE_FACTOR
            return Math.round(value / 100) * 100
        },

        currentAppRetention (item: object): number {
            return this.divide(item.current_retention_percent || 0, 100, 4) || 0
        },

        materialsStoredRetention (item: object): number {
            return this.divide(item.materials_stored_retention || 0, 100, 4) || 0
        },

        calculateRetentionAmount (item: object): number {
            const value = this.intMultiply(this.currentAppRetention(item), this.getSumOfTasksValues(item, 'current_application'), 3)
            return Math.round(value / 100) * 100
        },

        calculateRetentionPercent (item: object): number {
            const sumOfTasks = this.getSumOfTasksValues(item, 'current_application') || 0
            const value = sumOfTasks
                ? this.divide((item.current_retention_amount || 0) * 100 * process.env.SCALE_FACTOR, sumOfTasks, 2)
                : 0
            return Math.round(value / 100) * 100
        },

        materialStoredRetentionPercent (item: object): number {
            const sumOfTasks = this.getSumOfTasksValues(item, 'materials_stored') || 0
            const value = sumOfTasks
                ? this.divide((item.materials_stored_retention_amount || 0) * 100 * process.env.SCALE_FACTOR, sumOfTasks, 2)
                : 0
            return Math.round(value / 100) * 100
        },

        materialStoredRetentionAmount (item: object): number {
            const value = this.intMultiply(this.materialsStoredRetention(item), this.getSumOfTasksValues(item, 'materials_stored'), 3)
            return Math.round(value / 100) * 100
        },

        maxRetentionTotal (item: object): void {
            return this.previousRetentionAmount(item) + item.current_retention_amount + item.materials_stored_retention_amount
        },

        retentionReleased (item: object): number {
            const value = this.previousRetentionAmount(item) + item.current_retention_amount + item.materials_stored_retention_amount - item.total_retention_amount
            item.retention_released = Math.round(value / 100) * 100
        },
    }
}

export default retentionSummaryMixin
