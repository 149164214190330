import PageHeader from '@/components/page-header/PageHeader.vue'
import TopBarActions from '../../components/invoice-view/top-bar-actions/TopBarActions.vue'
import Stepper from '@/components/stepper/Stepper.vue'
import Tabs from '@/components/atoms/tabs/Tabs.vue'
import StepperStep from '@/components/stepper/stepper-step/StepperStep.vue'
import { defineComponent, nextTick } from 'vue'
import { ItemRouteInterface } from '@/interfaces/components/lazy-list/ItemRoute'
import InvoiceRouteName from '../../enums/InvoiceRouteName'
import { invoiceViewStore } from '../../stores/invoiceView'
import { invoiceSoVStore } from '../../stores/invoiceSoV'
import { mapActions, mapState } from 'pinia'
import { mapGetters as mapGettersVuex, mapState as mapStateVuex, mapActions as mapActionsVuex, mapMutations as mapMutationsVuex } from 'vuex'
import { InvoiceSubType } from '../../enums/InvoiceSubType'
import projectObjMixin from '@/modules/projects/mixins/projectObjMixin'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin'
import type { Tab as TabInterface } from '@/components/atoms/tabs/TabInterface'
import { ApprovalSubmissionParams } from '@/interfaces/modules/projects/financial-approvals/ApprovalSubmissionParams'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource'
import PopupManualApproval from '@/components/financial-approvals/popup-manual-approval/PopupManualApproval.vue'
import { RouteLocation } from 'vue-router'
import InvoiceBanners from '../../components/invoice-view/invoice-banners/InvoiceBanners.vue'
import PopupClientApproval from '@/components/financial-approvals/popup-client-approval/PopupClientApproval.vue'
import ApprovalsWrapper from '@/io-modules/approval-workflows/views/approvals-wrapper/ApprovalsWrapper.vue'
import ApprovalModalData from '@/io-modules/approval-workflows/interfaces/ApprovalModalData'
import ResourceApprovalsResponse from '@/io-modules/approval-workflows/interfaces/ResourceApprovalsResponse'
import InvoiceActions from '@/io-modules/invoices/enums/InvoiceActions.ts'
import InvoiceType from '@/io-modules/invoices/enums/InvoiceType.ts'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import FeatureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'

export default defineComponent({
    name: 'InvoiceViewTemplate',
    components: {
        PageHeader,
        TopBarActions,
        Stepper,
        StepperStep,
        Tabs,
        LoadingScreen,
        PopupManualApproval,
        InvoiceBanners,
        PopupClientApproval,
        ApprovalsWrapper,
    },
    beforeRouteLeave (to: RouteLocation, _from: RouteLocation, next: Function): void {
        if (this.applyingFunding) {
            this.setApplyingFunding(false)
            next()
            return
        }

        if (!to.name.startsWith('invoice-') && this.isEditingMode) {
            this.showPopupAlert({
                title: this.$t('You have unsaved changes. Would you like to save and leave or leave without saving?'),
                buttons: [
                    {
                        text: this.$t('Leave Without Saving'),
                        class: 'io-btn-light',
                        action: () => next()
                    },
                    {
                        text: this.$t('Save and Leave'),
                        class: 'io-btn-primary',
                        action: async () => {
                            await this.$refs.invoiceActions.saveAsDraft(false)

                            if (to.name) {
                                this.$router.push({
                                    name: to.name
                                })
                            }

                            next()
                        }
                    }
                ]
            })
        } else {
            next()
        }
    },
    mixins: [projectObjMixin, breadcrumbsMixin, FeatureFlagsMixin],
    data () {
        return {
            currentStep: 0,
            loading: false,
            visibleSteps: [],
            showPopupManualApproval: false,
            showPopupClientApproval: false,
            approvalModalData: {} as ApprovalModalData,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, [
            'creating',
            'editing',
            'isDetailsFilled',
            'invoice',
            'isEditingMode',
            'isEqualAppliedFundToInvoiceValue',
            'isNonContractedInvoice',
            'dataLoading',
            'invoiceIsDraft',
            'isClientOnSystem',
            'isMyInvoice',
            'applyingFunding',
            'availableActions'
        ]),

        ...mapState(invoiceSoVStore, ['isNonContractedLineItemsCompleted', 'netInvoiceValue', 'invoiceWBSLoaded']),

        ...mapState(invoiceRevisionStore, ['revisionLoaded', 'shouldLoadRevision']),

        ...mapGettersVuex('appStore', ['isOwnerRep', 'isOwner']),

        ...mapStateVuex('project', ['projectObj']),

        projectLocalID (): string {
            return this.projectObj?.project_local_id
        },

        projectGlobalId (): string {
            return this.projectObj?.project_global_id
        },

        invoiceId (): string {
            return this.invoice.id || this.$route.params.id
        },

        pageHeaderTitle (): string {
            if (this.creating) {
                return this.getCreationTitleByType(this.creating) || this.$t('Create Invoice')
            }

            if (this.editing) {
                return this.getEditingTitleByType(this.invoice.sub_type) || this.$t('Edit Invoice')
            }

            return `${ this.$t('Invoice') } ${ this.invoice.invoice_number || '' }`
        },

        backButtonLink (): object {
            return !this.isEditingMode ? { name: InvoiceRouteName.InvoicesMyList } : null
        },

        approvableResourceId (): string {
            return this.invoice.approvable_resource_id
        },

        allSteps (): TabInterface[] {
            const steps = [
                {
                    title: this.$t('Details'),
                    valid: this.isDetailsFilled && InvoiceRouteName.InvoiceDetails !== this.$route.name,
                    route: { name: InvoiceRouteName.InvoiceDetails },
                    optional: false,
                    visible: true,
                    dot: !this.isDetailsFilled && this.invoiceIsDraft,
                },
                {
                    title: this.$t('Line Items'),
                    valid: this.isNonContractedLineItemsCompleted,
                    route: { name: InvoiceRouteName.InvoiceNonContractedLineItems },
                    optional: false,
                    visible: this.isNonContractedInvoice,
                    dot: !this.isNonContractedLineItemsCompleted && this.invoiceIsDraft,
                    disabled: this.creating && !this.isDetailsFilled,
                },
                {
                    title: this.isEditingMode ? this.$t('Attach Vendor Invoices') : this.$t('Vendor Invoices'),
                    subtitle: this.$t('Optional').toUpperCase(),
                    valid: false,
                    route: { name: InvoiceRouteName.InvoiceAttachVendorInvoice },
                    optional: true,
                    visible: !this.isOwnerRep && !this.isOwner && this.isMyInvoice && (this.isEditingMode || this.invoice.attached_invoices?.length),
                    disabled: this.creating && !this.isDetailsFilled,
                },
                {
                    title: this.$t('SoV / WBS'),
                    subtitle: this.$t('Optional').toUpperCase(),
                    valid: false,
                    route: { name: InvoiceRouteName.InvoiceSoV },
                    optional: true,
                    visible: !this.isNonContractedInvoice,
                    dot: 0 === this.netInvoiceValue && this.invoiceIsDraft,
                    disabled: this.creating && !this.isDetailsFilled,
                },
                {
                    title: this.$t('Lien Waivers & Supporting Documents'),
                    subtitle: this.$t('Optional').toUpperCase(),
                    valid: false,
                    route: { name: InvoiceRouteName.InvoiceLienWaivers },
                    optional: true,
                    visible: !this.isNonContractedInvoice,
                    disabled: this.creating && !this.isDetailsFilled,
                },
                {
                    title: this.$t('Supporting Documents'),
                    subtitle: this.$t('Optional').toUpperCase(),
                    valid: false,
                    route: { name: InvoiceRouteName.InvoiceDocuments },
                    optional: true,
                    visible: this.isNonContractedInvoice,
                    disabled: this.creating && (!this.isDetailsFilled || !this.isNonContractedLineItemsCompleted),
                },
                {
                    title: this.$t('Funding'),
                    valid: false,
                    route: { name: InvoiceRouteName.InvoiceFunding },
                    optional: false,
                    visible: this.isOwnerRep || this.isOwner,
                    dot: !this.isEqualAppliedFundToInvoiceValue && this.invoiceIsDraft,
                    disabled: this.creating && (!this.isDetailsFilled || !this.isNonContractedLineItemsCompleted && this.isNonContractedInvoice),
                },
                {
                    title: this.$t('Approvals'),
                    valid: false,
                    route: { name: InvoiceRouteName.InvoiceApprovals },
                    optional: false,
                    visible: !this.isEditingMode,
                },
                {
                    title: this.$t('Timeline'),
                    valid: false,
                    route: { name: InvoiceRouteName.InvoiceTimeline },
                    optional: false,
                    visible: !this.isEditingMode,
                },
            ]

            return steps
        },

        invoiceTabs (): TabInterface[] {
            return this.allSteps.filter(item => item.visible).map((item, index) => {
                return { ...item, title: `${ index + 1 }. ${ item.title }` }
            })
        },

        isSoVTab (): boolean {
            return InvoiceRouteName.InvoiceSoV === this.$route.name
        },

        isFundingTab (): boolean {
            return InvoiceRouteName.InvoiceFunding === this.$route.name
        },

        isDetailsTab (): boolean {
            return InvoiceRouteName.InvoiceDetails === this.$route.name
        },

        allStepsCompleted (): boolean {
            return !this.invoiceTabs.some(item => item.dot)
        },

        breadcrumbs (): Array<object> {
            if ('0' === this.$route.params.id || this.editing) {
                return []
            }

            return [
                ...this.$route.meta.breadcrumbs || [],
                {
                    text: this.projectObj._project_name || this.projectObj._name || this.$t('Project'),
                    to: { name: 'project-dashboard', params: { pid: this.$route.params.pid } }
                },
                {
                    text: this.isOwner || this.isOwnerRep ? this.$t('Invoicing and Application Packages') : this.$t('Invoicing'),
                    to: { name: InvoiceRouteName.InvoicesReceivedList, params: { pid: this.$route.params.pid } }
                },
                {
                    text: this.invoice.invoice_number || '',
                    to: { name: InvoiceRouteName.InvoiceDetails, params: { pid: this.$route.params.pid, id: this.$route.params.id } }
                },
            ]
        },

        approvalSubmissionsRequestData (): ApprovalSubmissionParams {
            return {
                resource_id: this.$route.params.id,
                project_company_id: this.projectLocalID,
                approvable_resource_name: Resource.INVOICES
            }
        },

        isCreatingInvoiceRoute (): boolean {
            return this.$route.params.id && '0' == this.$route.params.id
        },

        shouldForceClientApproval (): boolean { // for my invoices we should send to the client no matter settings if the client is on-system
            return this.availableActions.some(item => InvoiceActions.Approve === item.name && item.available) && InvoiceType.Contracted === this.invoice.type && InvoiceSubType.MyInvoice === this.invoice.sub_type && this.isClientOnSystem
        }
    },
    watch: {
        $route (to: ItemRouteInterface, from: ItemRouteInterface): void {
            if (this.creating) {
                this.visibleSteps[0].valid = this.isDetailsFilled && InvoiceRouteName.InvoiceDetails !== to.name
                if (this.isNonContractedInvoice) {
                    this.visibleSteps[1].valid = this.isNonContractedLineItemsCompleted && InvoiceRouteName.InvoiceDetails !== to.name
                }
            } else {
                this.visibleSteps = this.allSteps.filter(item => item.visible)
                const step = this.visibleSteps.findIndex(item => item.route.name === to.name)
                if (this.currentStep !== step) {
                    this.currentStep = step
                }
            }
        },

        currentStep (): void {
            if (this.creating && this.visibleSteps[this.currentStep]?.route) {
                this.$router.push(this.visibleSteps[this.currentStep].route)
            } else if (this.invoiceTabs[this.currentStep]?.route) {
                this.$router.push(this.invoiceTabs[this.currentStep]?.route)
            }
        },

        breadcrumbs (): void {
            this.setBreadcrumbs(this.breadcrumbs)
        },

        async editing (): Promise<void> {
            if (this.editing && [InvoiceRouteName.InvoiceApprovals, InvoiceRouteName.InvoiceTimeline].includes(this.$route.name) && this.invoiceIsDraft) {
                this.$router.push({ name: InvoiceRouteName.InvoiceDetails })
            }

            if (!this.isEditingMode && InvoiceRouteName.InvoiceAttachVendorInvoice === this.$route.name) {
                this.$router.push({ name: InvoiceRouteName.InvoiceDetails })
            }

            if (this.editing && !(this.isNonContractedInvoice && this.$route.name === InvoiceRouteName.InvoiceDetails)) {
                this.setLoadingBar(true)
                await this.loadEditingDetails(this.projectLocalID)
                this.setLoadingBar(false)
            }

            if (this.isMyInvoice && InvoiceRouteName.InvoiceSoV === this.$route.name) {
                this.visibleSteps = this.allSteps.filter(item => item.visible)
                const step = this.visibleSteps.findIndex(item => item.route.name === this.$route.name)
                if (this.currentStep !== step) {
                    this.currentStep = step
                }
            }
        },
    },
    async created (): Promise<void> {
        this.clearSoVStore()

        if (this.isCreatingInvoiceRoute) {
            this.handleInvoiceCreation()
        }

        if (this.isCreatingInvoiceRoute && this.$route.query['contract-id']) {
            this.setSelectedForCreationContract({ id: this.$route.query['contract-id'], name: this.$route.query['contract-name'] || '', contact_workspace_id: this.$route.query['contact-workspace-id'] || '' })
        }

        await this.loadInitialData()
    },
    async mounted () {
        this.setBreadcrumbs(this.breadcrumbs)
    },
    beforeUnmount () {
        this.clearAllInvoiceStoreData()
        this.clearSoVStore()
        this.setBreadcrumbs([])
        sessionStorage.removeItem('invoiceCreationOptions')
    },
    methods: {
        ...mapActions(invoiceViewStore, [
            'setCreationType',
            'fetchVendorsInvoices',
            'clearAllInvoiceStoreData',
            'setEditModeValue',
            'fetchInvoiceDetails',
            'fetchInvoiceFundingSources',
            'updateInvoiceDetailsActions',
            'setDataLoadingValue',
            'getActiveAccountingIntegration',
            'setClientApprovalsSetting',
            'setSelectedForCreationContract',
            'setApplyingFunding',
            'checkSubmitToAccounting',
            'loadEditingDetails'
        ]),

        ...mapActions(invoiceSoVStore, ['clearSoVStore', 'fetchInvoiceWBS']),

        ...mapActions(invoiceRevisionStore, ['loadAdjustmentsIntoWbsItems']),

        approvalResourceOnLoad (resourceApprovals: ResourceApprovalsResponse): void {
            this.setClientApprovalsSetting(resourceApprovals.client_approval_setting)
        },

        async loadInitialData (): Promise<void> {
            try {
                this.loading = true
                if (!this.projectLocalID) {
                    await this.loadProject()
                    await this.$store.dispatch('appStore/setProjectCurrency', { projectId: this.$route.params.pid })
                }

                this.getActiveAccountingIntegration(this.projectLocalID)

                if (!this.isCreatingInvoiceRoute && InvoiceRouteName.InvoiceDetails !== this.$route.name) {
                    await this.fetchInvoiceDetails(this.projectLocalID, this.$route.params.id)
                    this.checkSubmitToAccounting(this.projectLocalID, this.$route.params.id)

                    if (this.isFeatureEnabled(FeatureFlagsConsts.INVOICES_REVISION_REQUEST_V2, false) && !this.isNonContractedInvoice && !this.revisionLoaded && this.shouldLoadRevision) {
                        if (!this.invoiceWBSLoaded) {
                            await this.fetchInvoiceWBS(this.projectLocalID, this.$route.params.id)
                        }
                        await this.loadAdjustmentsIntoWbsItems(this.projectGlobalId, this.$route.params.id)
                    }
                }

                if (!this.isOwnerRep && !this.isOwner && this.isMyInvoice) {
                    await this.fetchVendorsInvoices(this.projectLocalID)
                }

                if ((this.isOwnerRep || this.isOwner) && !this.isCreatingInvoiceRoute && !this.isFundingTab && !this.isDetailsTab) {
                    await this.fetchInvoiceFundingSources(this.invoice.id, this.projectLocalID)
                }

                this.loading = false
            } catch (error) {
                console.log(error)
                this.errorHandleNoRedirect(error)
            } finally {
                this.loading = false
                await nextTick()
                this.setupHeaderTabs()
            }
        },

        setupHeaderTabs (): void {
            this.visibleSteps = this.allSteps.filter(item => item.visible)

            if (this.$route.name !== InvoiceRouteName.InvoiceDetails && !this.creating) {
                this.currentStep = this.visibleSteps.findIndex(item => item.route.name === this.$route.name)
            }
        },

        onClickTab (step: number): void {
            if (this.creating && (!this.isDetailsFilled || !this.isNonContractedLineItemsCompleted && this.isNonContractedInvoice && step > 1)) {
                return
            }

            if (step > this.currentStep && this.visibleSteps[this.currentStep]?.optional) {
                this.visibleSteps[this.currentStep].valid = true
            }

            if (step < this.currentStep) {
                this.visibleSteps.forEach((item, index) => {
                    if (index >= step) {
                        item.valid = false
                    }
                })
            }

            this.currentStep = step
        },

        getCreationTitleByType (type: InvoiceSubType): string {
            return {
                [InvoiceSubType.MyInvoice]: this.$t('Create Your Invoice'),
                [InvoiceSubType.VendorInvoice]: this.$t('Create Vendor Invoice'),
                [InvoiceSubType.NonContractedInvoice]: this.$t('Create Non-Contracted Invoice'),
            }[type]
        },

        getEditingTitleByType (type: InvoiceSubType): string {
            if (this.applyingFunding) {
                return this.$t('Apply Funding')
            }

            return {
                [InvoiceSubType.MyInvoice]: this.$t('Edit Your Invoice'),
                [InvoiceSubType.VendorInvoice]: this.$t('Edit Vendor Invoice'),
                [InvoiceSubType.NonContractedInvoice]: this.$t('Edit Non-Contracted Invoice'),
            }[type]
        },

        nextStep (): void {
            if (this.visibleSteps[this.currentStep].optional) {
                this.visibleSteps[this.currentStep].valid = true
            }

            this.currentStep = this.currentStep + 1
        },

        prevStep (): void {
            if (this.visibleSteps[this.currentStep].optional) {
                this.visibleSteps[this.currentStep].valid = false
            }

            if (this.visibleSteps[this.currentStep - 1].optional) {
                this.visibleSteps[this.currentStep - 1].valid = false
            }

            this.currentStep = this.currentStep - 1
        },

        onInvoiceCreated (): void {
            this.currentStep = 0
            this.setupHeaderTabs()
        },

        async refreshData (): Promise<void> {
            this.setDataLoadingValue(true)
            this.updateInvoiceDetailsActions(this.projectLocalID, this.$route.name, this.invoiceId)

            await this.fetchInvoiceDetails(this.projectLocalID, this.invoiceId)
            this.setDataLoadingValue(false)
            this.getActiveAccountingIntegration(this.projectLocalID)
            this.checkSubmitToAccounting(this.projectLocalID, this.$route.params.id)
        },

        async refreshApprovableResourceData (): Promise<void> {
            if (this.$refs.approvalsWrapper) {
                this.setDataLoadingValue(true)
                await this.$refs.approvalsWrapper.refreshData()
                this.setDataLoadingValue(false)
            }
        },

        callSubmitToClientModal (): void {
            this.$refs.invoiceActions.initSendToClient()
        },

        callMarkAsSubmittedToClient (): void {
            this.$refs.invoiceActions.initMarkAsSubmittedToClient()
        },

        handleInvoiceCreation (): void {
            if (this.$route.query.type) {
                this.setCreationType(this.$route.query.type)
            } else {
                const creationOptions = JSON.parse(sessionStorage.getItem('invoiceCreationOptions'))

                if (creationOptions) {
                    this.setCreationType(creationOptions.invoiceType)
                    if (creationOptions.invoiceType !== InvoiceSubType.NonContractedInvoice) {
                        this.setSelectedForCreationContract(creationOptions.selectedContract)
                    }
                }

                this.onInvoiceCreated()
                this.$router.push({ name: InvoiceRouteName.InvoiceDetails })
            }
        },

        async sendRevisionToVendor (): Promise<void> {
            /**
             * Invoice: 6. Request Revision:
             * Send revision to vendor (from banner)
             */

            await this.$refs.invoiceActions.decideOnRevisionFlow()
        }
    }
})
