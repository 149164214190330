import { defineComponent, nextTick } from 'vue'
import useCollapseButton from '@/components/jobsite-locations/composables/collapseComposable.ts'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { mapState, mapStores } from 'pinia'
import { jobsiteLocationsStore } from '@/components/jobsite-locations/store/jobsiteLocationsStore.ts'
import { cloneDeep } from 'lodash'
import { JobsiteLocationsInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsInterface.ts'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums.ts'
import JobsiteLocationTypeEnum from '@/components/jobsite-locations/enums/JobsiteLocationTypeEnum.ts'
import JobsiteLocationStatusEnum from '@/components/jobsite-locations/enums/JobsiteLocationStatusEnum.ts'

export default defineComponent({
    name: 'JobsiteLocationsAccordion',
    props: {
        depth: { type: Number, default: 0 },
        parentId: { type: String, default: null },
        isListUpdated: { type: Boolean, default: false },
        isDisabled: { type: Boolean, default: false },
        jobsiteLocation: { type: Object as () => JobsiteLocationsInterface, required: true },
    },
    emits: ['update-tree'],
    components: {
        StatusPill
    },
    setup () {
        const { isCollapsed, collapseButtonIcon, onClickCollapseButton } = useCollapseButton()

        return {
            isCollapsed,
            collapseButtonIcon,
            onClickCollapseButton,
        }
    },
    data () {
        return {
            selectedTreeIds: [] as string[],
        }
    },
    computed: {
        ...mapStores(jobsiteLocationsStore),
        ...mapState(jobsiteLocationsStore, [
            'selectedJobsiteLocationsIds',
            'selectedTreeJobsiteLocationsIds',
            'isAllLocationsCollapsed'
        ]),
        isCheckboxDisabled (): boolean {
            return this.isDisabled ? this.isDisabled : this.isLocationArchived
        },
        isLocationArchived (): boolean {
            return this.jobsiteLocation.status === JobsiteLocationStatusEnum.ARCHIVED
        },
        indent (): object {
            return { paddingLeft: `${ (this.depth * 20) + 40 }px` }
        },
        topLevelJobsiteLocations (): boolean {
            return this.depth === 0
        },
        isSelectedJobsiteLocationInTree (): boolean {
            return this.selectedTreeJobsiteLocationsIds.includes(this.jobsiteLocation.id)
        },
        isSelectedJobsiteLocation (): boolean {
            return this.selectedJobsiteLocationsIds.includes(this.jobsiteLocation.id)
        },
        hasChildren (): boolean {
            return !!this.jobsiteLocation?.children?.length
        },
        isNestedListShown (): boolean {
            return this.hasChildren && !this.isCollapsed
        },
        isBuildingType (): boolean {
            return this.jobsiteLocation.type === JobsiteLocationTypeEnum.BUILDING
        },
        statusPillVariant (): PillVariant {
            return this.isBuildingType ? 'io-blue-light' : 'io-grey-light'
        },
        statusPillText (): PillVariant {
            return this.isBuildingType ? this.$t('Building') : this.$t('Subsite')
        },
        isArchived (): boolean {
            return this.jobsiteLocation.status === JobsiteLocationStatusEnum.ARCHIVED
        },
    },
    mounted (): void {
        this.jobsiteLocationsStore.clearSelectedTreeLocationsIds()

        nextTick(() => {
            this.onChangeSelectedJobsiteLocationsIdsList()
        })
    },
    watch: {
        'selectedJobsiteLocationsIds': {
            handler (): void {
                this.jobsiteLocationsStore.clearSelectedTreeLocationsIds()

                nextTick(() => {
                    this.onChangeSelectedJobsiteLocationsIdsList()
                })
            },
            deep: true,
            flush: 'post'
        },
        'selectedTreeJobsiteLocationsIds': {
            handler (): void {
                this.selectedTreeIds = cloneDeep(this.selectedTreeJobsiteLocationsIds)

            }, deep: true
        },
        'isListUpdated': {
            handler (): void {
                if (this.isListUpdated) {
                    nextTick(() => {
                        this.onChangeSelectedJobsiteLocationsIdsList()
                    })
                }
            },
            deep: true,
        },
        isAllLocationsCollapsed (): void {
            this.onClickCollapseButton(false, this.isAllLocationsCollapsed)
        },
    },
    methods: {
        onChangeSelectedJobsiteLocationsIdsList (): void {
            if (this.selectedJobsiteLocationsIds.includes(this.jobsiteLocation.id)) {

                this.jobsiteLocationsStore.addSelectedTreeLocationId(this.jobsiteLocation.id)
                this.$emit('update-tree')
            }
        },
        updateTree (parentId: string): void {
            if (!parentId) {
                return
            }

            this.jobsiteLocationsStore.addSelectedTreeLocationId(parentId)

            if (this.selectedJobsiteLocationsIds.includes(this.jobsiteLocation.id)) {
                this.jobsiteLocationsStore.removeSelectedLocationId(this.jobsiteLocation.id)
            }

            this.$emit('update-tree')
        },
        removeSelectedChildrenIds(children: JobsiteLocationsInterface[]): void {
            children.forEach((child: JobsiteLocationsInterface) => {
                this.jobsiteLocationsStore.removeSelectedLocationId(child.id)

                if (!child.children) {
                    return
                }

                this.removeSelectedChildrenIds(child.children)
            })
        },
        changeSelectedLocations (): void {
            if (this.isSelectedJobsiteLocationInTree && !this.isSelectedJobsiteLocation) {
                if (this.parentId) {
                    this.jobsiteLocationsStore.addSelectedLocationId(this.parentId)
                }

                this.removeSelectedChildrenIds(this.jobsiteLocation.children)
                return
            }

            if (!this.selectedJobsiteLocationsIds.includes(this.jobsiteLocation.id)) {
                this.jobsiteLocationsStore.addSelectedLocationId(this.jobsiteLocation.id)
            } else {
                this.jobsiteLocationsStore.removeSelectedLocationId(this.jobsiteLocation.id)
            }
        },
    }
})
