<template>
    <ListHeader
        v-if="showHeader"
        :title="title"
        :value-label="$t('Net Current Application')"
        :value="netCurrentApplicationValue"
        :link="link"
        :params="params"
        :showLeftNavigation="showLeftNavigation"
    />
    <ExpandableTable
        :id="id"
        ref="table"
        nestedRowsIndicator="children"
        itemIdentifier="contract_wbs_id"
        :groups="tableGroups"
        :items="phases"
        :showFooter="showFooter"
        :onlyFooter="onlyFooter"
        :showScrollArrows="true"
        @inputClicked="clickedInput"
    />
</template>

<script lang="ts" src="./QuotedItemsList.ts"></script>
<style lang="scss" src="./QuotedItemsList.scss" scoped></style>
