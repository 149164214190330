import { orderBy } from 'lodash'

export function getCurrenciesList (state) {
    return state.currencies.map((item) => item.id)
}

export function getDivisionsCustom (state) {
    return orderBy(state.divisions_custom, ['name'])
}

export function getAgreementTypes (state) {
    return state.agreement_types
}

export function getCurrencyDetails (state) {
    return idx => {
        return state.currencies_list.find(currency => currency.id === idx)
    }
}
