<template>
    <div
        class="io-design-system io-status-dot-component"
        :class="{ 'io-status-dot-component--tooltip': tooltip }"
    >
        <span
            class="io-status-dot-component__dot"
            :class="dotClasses"
        ></span>
        <div
            v-if="tooltip"
            :class="{ 
                'io-tooltip':  tooltip.top,
                'io-tooltip--top-start': tooltip.top && tooltip.start,
                'io-tooltip--top-end': tooltip.top && tooltip.end,
            }"
        >
            {{ tooltip.text }}
        </div>
        <span class="io-status-dot-component__text">{{ text }}</span>
    </div>
</template>

<script lang="ts" src="./StatusDot.ts"></script>
<style lang="scss" src="./StatusDot.scss" scoped></style>
