import { defineComponent } from 'vue'
import BudgetSiteSelect from '../BudgetSiteSelect.vue'
import IOModal from '../../atoms/IOModal/IOModal.vue'
import { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'

export default defineComponent({
    name: 'AffectOtherSites',
    components: {
        BudgetSiteSelect,
        IOModal
    },

    props: {
        applyToOtherSites: {
            type: Boolean,
            default: true
        },
        projectId: {
            type: String,
            default: null,
        },
        budgetType: {
            type: String as () => BudgetType,
            default: null,
            validator (value: BudgetType): boolean {
                return [BudgetType.OWNER_BUDGET, BudgetType.MY_BUDGET].includes(value)
            },
        },
        preselectedSiteIds: {
            type: Array as () => string[],
            default: () => [],
        },
        preselectedSites: {
            type: Array as () => ProjectSite[],
            default: () => [],
        },
    },

    data () {
        return {
            selectedSites: []
        }
    },

    computed: {
        title (): string {
            return this.applyToOtherSites ? this.$t('Apply to other sites') : this.$t('Load from Other Site')
        },
        sitesSelectorLabel (): string {
            return this.applyToOtherSites ? this.$t('Select the sites you want to apply these line items to')
                    : this.$t('Select the site you want to load line items from')
        },
        buttonTitle (): string {
            return this.applyToOtherSites ? this.$t('Apply') : this.$t('Load')
        }
    },

    methods: {
        emitSelected (): void {
            this.$emit('selectedSites', this.selectedSites)
        }
    }
})
