const HealthCheck = () => import(/* webpackChunkName: "HealthCheck" */ '@/io-modules/health-check/views/health-check/HealthCheck.vue')
export default [
    {
        path: '/health',
        name: 'health-check',
        component: HealthCheck,
        meta: {
            skipAuth: true
        }
    },
]
