import ProjectSummary from '@/io-modules/project-summary/views/ProjectSummary.vue'

const defaultMeta = {
    name: 'Project Summary',
    navigationKey: 'project_summary'
}
export default [
    {
        path: '/project/:pid/project-summary',
        name: 'project-summary',
        component: ProjectSummary,
        meta: defaultMeta,
    }
]
