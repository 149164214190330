<template>
    <ListHeader
        value-filter="formatProjectCurrencyValue"
        :showLeftNavigation="false" 
        :title="$t('Current Application')" 
        :value="lineItemsTotal"
        :value-label="$t('Current Application')"
    />
    <Table
        class="io-line-items-table"
        :class="{ 'io-line-items-table--editing': isEditingMode }"
        sort-by="description"
        :headers="headers"
        :items="lineItems"
        omit-tbody
        @sortChange="onSortChange"
    >
        <template #tbody>
            <Draggable
                tag="tbody"
                group="items"
                :class="`io-non-contracted-items-list ${ isEditingMode ? 'io-non-contracted-items-list--editing' : '' }`"
                :list="lineItems"
                :item-key="item => `${ lineItems.indexOf(item) }-line-item`"
            >
                <template #item="{ element: lineItem, index }" >
                    <LineItemRow
                        :item="lineItem"
                        :headers="headers"
                        :provided-cost-codes="providedCostCodes"
                        @removeItem="$emit('removeItem', index)"
                    />
                </template>
                <template v-if="isEditingMode" #footer>
                    <tr class="io-add-row-action">
                        <td :colspan="isEditingMode ? 7 : 5">
                            <div v-if="isEditingMode" class="io-add-line-item-btn" @click="$emit('addNewRow')">
                                <IOButton variant="link" icon="icon-plus" @click.stop="$emit('addNewRow')"><span>{{ $t('Add Next Row') }}</span></IOButton>
                            </div>
                        </td>
                    </tr>
                </template>
            </Draggable>
        </template>
        <template #tfooter>
            <tr>
                <td v-if="isEditingMode"></td>
                <td></td>
                <td></td>
                <td></td>
                <td><span>{{ $t('Total') }}</span></td>
                <td><span>{{ $filters.formatProjectCurrencyValue(lineItemsTotal) }}</span></td>
                <td v-if="isEditingMode"></td>
            </tr>
        </template>
    </Table>
</template>

<script lang="ts" src="./LineItemsTable.ts"></script>
<style lang="scss" src="./LineItemsTable.scss" scoped></style>
