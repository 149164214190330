<template>
    <Teleport to="body">
        <Popup
            class="io-design-system io-popup-confirm-alert"
            :width="536"
            :class="type"
            data-testid="confirm-alert"
            @close="onDiscard"
        >
            <template #header>
                <div class="io-popup-header__title">
                    <span :class="headerClass"></span>
                </div>

                <div
                    class="io-popup-header__close"
                    data-testid="confirm-alert-close"
                    @click="onDiscard"
                >
                    <i class="icon-cross"></i>
                </div>
            </template>

            <template #default>
                <p class="io-popup-content__title" data-testid="confirm-alert-title">{{ title }}</p>

                <p
                    v-if="subtitle"
                    class="io-popup-content__subtitle"
                    data-testid="confirm-alert-subtitle"
                >
                    {{ subtitle }}
                </p>
                <slot></slot>
            </template>

            <template #footer>
                <div class="io-f-row io-buttons-row">
                    <div
                        class="io-btn io-btn-light"
                        data-testid="confirm-alert-cancel-button"
                        @click="onDiscard"
                    >
                        {{ cancelButtonText }}
                    </div>

                    <div
                        class="io-btn io-btn-primary"
                        data-testid="confirm-alert-accept-button"
                        @click="onConfirm"
                    >
                        {{ acceptButtonText }}
                    </div>
                </div>
            </template>
        </Popup>
    </Teleport>
</template>

<script lang="ts" src="./ConfirmAlert.ts"></script>
<style lang="scss" src="./ConfirmAlert.scss" scoped></style>
