<template>
    <div class="io-capital-funds">
        <Placeholder
            v-if="!capitalFunds"
            :title="$t('No Program Funds within this Provider')"
            :subtitle="$t('Click the button below to add the first fund associated with this provider.')"
            icon="icon-cash"
            :buttons-cta="createNewCapitalFundCta"
            @createNewCapitalFund="toggleShowNewFundModal"
        />
        <template v-else>
            <template v-for="fund in capitalFunds">
                <CapitalFundsAccordion :fund="fund"/>
            </template>
        </template>
    </div>
</template>

<script lang="ts" src="./TabCapitalFunds.ts"></script>
<style lang="scss" src="./TabCapitalFunds.scss" scoped></style>
