import CashFlowViewTab from '@/interfaces/modules/projects/modules/forecasting/CashFlowViewTab'
import ShowCostCodesEnum from '@/interfaces/modules/projects/modules/forecasting/ShowCostCodes'
import ActualForecastEnum from '@/interfaces/modules/projects/modules/forecasting/ActualForecastEnum'
import CostTypeEnum from '@/interfaces/modules/projects/modules/forecasting/CostTypeEnum'
import ForecastingStore from '@/interfaces/modules/projects/modules/forecasting/ForecastingStore'

const getDefaultState = () => {
    return {
        search: '',
        collapsed: 'true' === localStorage.getItem('cashFlowTableCollapse') ?? false,
        allCheckboxes: false,
        categoryCheckboxes: [],
        itemsCheckboxes: [],
        cashFlowPeriods: [],
        cashFlow: null,
        cashFlowOptions: [],
        currentCashFlowOption: {},
        itemsFiltered: [],
        visibleCostCodeIds: [],
        summaryVisibleCostCodeIds: [],
        summaryRange: { start: null, end: null },
        summaryActualForecast: [ActualForecastEnum.ACTUAL, ActualForecastEnum.FORECAST],
        summaryCostTypes: [CostTypeEnum.MONTHLY, CostTypeEnum.CUMULATIVE],
        curveColumnMenuOpen: false,
        rangeColumnMenuOpen: false,
        costCodeForecastedBalances: {},
        itemsActual: {},
        subItemsActual: {},
        editing: false,
        loading: true,
        costCodeViewId: null,
        costCodeItemActual: {},
        currentViewTab: CashFlowViewTab.MODELLING,
        viewModeOnly: false,
        filtersVisible: false,
        summaryFiltersVisible: false,
        hideZeroValues: false,
        showCostCodeCommitmentMode: ShowCostCodesEnum.ALL_COST_CODES as ShowCostCodesEnum,
        sites: [],
        activeSiteId: null,
    }
}

export default {...getDefaultState()} as ForecastingStore

export { getDefaultState }
