import ListType from '@/io-modules/invoices/enums/ListType'
import { AxiosPromise } from 'axios'
import InvoicesListResponse from '@/io-modules/invoices/interfaces/responses/InvoicesListResponse'
import { invoicesStore as useInvoicesStore } from '@/io-modules/invoices/stores/invoicesList'

export default class UpdateInvoicesListStrategy {
    private readonly invoicesStore

    constructor (private readonly projectMongoIdLocal: string) {
        this.invoicesStore = useInvoicesStore()
    }

    updateByType (type: ListType, vendorId: string): AxiosPromise<InvoicesListResponse> {
        if (!type) {
            return
        }
        
        return {
            [ListType.Received]: vendorId ? this.invoicesStore.fetchVendorInvoices : this.invoicesStore.fetchReceivedInvoices,
            [ListType.My]: this.invoicesStore.fetchMyInvoices,
            [ListType.Shared]: this.invoicesStore.fetchSharedInvoices,
            [ListType.AppPackages]: this.invoicesStore.fetchApplicationPackages,
        }[type](this.projectMongoIdLocal, vendorId)
    }
}
