import { defineComponent } from 'vue'
// @ts-ignore
import cached from '@/store/component/cached-list/index'
// @ts-ignore
import cachedListMixin from '@/mixins/cachedListMixin'
import { mapState } from 'vuex'

export default defineComponent({
    props: {
        data: { type: Array, required: true, default: null }
    },
    mixins: [cachedListMixin],
    data () {
        return {
            parsedData: []
        }
    },
    computed: {
        ...mapState('cached', {
            lists: (state) => state.lists
        }),
        parsedDataShortened (): any {
            return this.parsedData.length > 4 ? this.parsedData.slice(0, 3) : this.parsedData
        },
        remainingEmployeesNames (): string[] {
            return this.parsedData
                .filter((item: any) => !this.parsedDataShortened.includes(item))
                .map((item: any) => item.full_name)
        }
    },
    async beforeMount () {
        await cached.getList('employees')
        if (this.data) {
            await this.parseData()
        }
    },
    methods: {
        async parseData (): Promise<void> {
            await Promise.all(this.data?.map(async employee => {
                const person = await this.getOne('employees', employee)

                if (person && person !== -1) {
                    this.parsedData.push(person)
                }
            }))
        }
    }
})
