import { PortfolioRouteName } from './enum/PortfolioRouteName.ts'
const Portfolios = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/main.vue')
const AddProjects = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/add-projects/AddProjects.vue')
const SinglePortfolio = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/single-portfolio/SinglePortfolio.vue')
const PortfolioSummary = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/portfolio-summary/PortfolioSummary.vue')
const LandAcquisition = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/land-acquisition/LandAcquisitionList.vue')
const FundingSources = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/funding-sources/FundingSources.vue')
const PortfolioSettings = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/portfolio-settings/PortfolioSettings.vue')
const Projects = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/projects/Projects.vue')
const MasterSchedule = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/master-schedule/MasterSchedule.vue')
const Timeline = () => import(/* webpackChunkName: "portfolio" */ '@/modules/portfolios/views/portfolio-timeline/Timeline.vue')


export default [
    {
        path: '/portfolios/list',
        name: 'portfolios-list',
        component: Portfolios,
        meta: {
            title: 'Portfolios List',
        },
    },
    {
        path: '/portfolios/:pid/projects-manage',
        name: PortfolioRouteName.PORTFOLIO_MANAGE_PROJECTS,
        component: AddProjects,
        meta: {
            ignoreHideLoading: true,
            title: 'Portfolio Projects Manage',
        },
    },
    {
        path: '/portfolios/:pid',
        name: 'single-portfolio',
        component: SinglePortfolio,
        redirect: { name: 'single-portfolio-summary' },
        children: [
            {
                path: 'summary',
                name: PortfolioRouteName.PORTFOLIO_SUMMARY,
                component: PortfolioSummary,
                meta: {
                    title: 'Portfolio Summary',
                },
            },
            {
                path: 'land-acquisition',
                name: PortfolioRouteName.PORTFOLIO_LAND_ACQUISITION,
                component: LandAcquisition,
                meta: {
                    title: 'Portfolio Land Acquisition',
                },
            },
            {
                path: 'funding',
                name: PortfolioRouteName.PORTFOLIO_FUNDING,
                component: FundingSources,
                meta: {
                    title: 'Portfolio Funding',
                },
            },
            {
                path: 'settings',
                name: PortfolioRouteName.PORTFOLIO_SETTINGS,
                component: PortfolioSettings,
                meta: {
                    inviteType: 'portfolio',
                    title: 'Portfolio Settings',
                },
            },
            {
                path: 'projects',
                name: PortfolioRouteName.PORTFOLIO_PROJECTS,
                meta: {
                    ignoreHideLoading: true,
                    title: 'Portfolio Projects',
                },
                component: Projects,
            },
            {
                path: 'master-schedule',
                name: PortfolioRouteName.PORTFOLIO_MASTER_SCHEDULE,
                component: MasterSchedule,
                meta: {
                    title: 'Portfolio Master Schedule',
                },
            },
            {
                path: 'timeline',
                name: PortfolioRouteName.PORTFOLIO_TIMELINE,
                component: Timeline,
                meta: {
                    title: 'Portfolio Timeline',
                },
            },
        ],
    },
]
