export const setColumn = function ({commit}, payload) {
    commit('SET_COLUMN', payload)
}
export const setFilters = function ({commit}, payload) {
    commit('SET_FILTERS', payload)
}
export const setPagination = function ({commit}, value) {
    commit('SET_PAGINATION', value)
}
export const setPage = function ({commit}, value) {
    commit('SET_PAGE', value)
}
export const setSearch = function ({commit}, value) {
    commit('SET_SEARCH', value)
}
export const setIsSearching = function ({commit}, value) {
    commit('SET_ISSEARCHING', value)
}
export const resetFilter = function ({commit}, value) {
    commit('RESET_FILTER', value)
}
export const resetFilters = function ({commit}) {
    commit('RESET_FILTERS')
}
