import defaultState from './state'
import { cloneDeep } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export function SET_INVITED_PENDING (state, value) {
    state.invited_pending = value || {}
}

export function SET_INVITED_PEOPLE (state, value) {
    state.invited_people = value || {}
}

export function SET_INVITED (state, value) {
    state.invited = value || {}
}

export function SET_SHOW_ADD_CONTACTS_POPUP (state, value) {
    state.showAddContactsPopup = !state.showAddContactsPopup
}
