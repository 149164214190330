import { StepsList } from '@/interfaces/components/invite-modal/StepsList'

export default {
    search: '',
    takeRecords: 50,
    offset: 0,
    isLastPage: false,
    rendered: false,
    selectedContacts: [],
    selectedContactsToAction: {
        model_id: '',
        model_type: '',
        invite: [],
        save: [],
        remind: [],
        remove: [],
        model_section: null,
        skip_notifications: false,
    },
    groupedCompaniesContactsList: {},
    onItemContactsList: [],
    companiesList: [],
    selectedContact: null,
    selectedWorkspace: null,
    selectedWorkspaceType: null,
    matchedMyContacts: [],
    matchedMyContactsClone: [],
    matchedAccounts: [],
    matchedDomains: [],
    activeStep: StepsList.INITIAL,
    loading: false,
    isTabsVisible: false,
    isFormValid: false,
    searchEmailValid: false,
    contactFormData: null,
    selectedAction: null,
    selectMultiple: true,
    isCachedDataLoadRequired: false,
    isOtherRecordsExist: false,
    invitedContacts: [],
    bookmarkedContacts: []
}
