import { defineComponent } from 'vue'
import { invoiceViewStore } from '../../../../stores/invoiceView'
import { mapState } from 'pinia'
import CellType from '@/components/table/CellType'
import Table from '@/components/table/Table.vue'
import LienWaiver from '../../../../interfaces/lien-waivers/LienWaiver'
import LienWaiverPaymentType from '../../../../enums/lien-waivers/LienWaiverPaymentType'
import LienWaiverType from '../../../../enums/lien-waivers/LienWaiverType'
import StatusDot from '@/components/atoms/status-dot/StatusDot.vue'
import { DotVariant } from '@/components/atoms/status-dot/enums/StatusDotEnums'
import { HeaderTable } from '@/components/table/HeaderTableInterface'

export default defineComponent({
    name: 'LienWaiversTable',
    components: {
        Table,
        StatusDot,
    },
    data () {
        return {
            DotVariant,
            CellType,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, ['invoiceLienWaivers', 'isEditingMode']),

        tableHeaders (): HeaderTable[] {
            const headers = [
                {
                    text: this.$t('Payment Type'),
                    valueType: CellType.Text,
                    value: 'payment_type',
                    customValue: (item: LienWaiver): void => {
                        return LienWaiverPaymentType.PartialPayment === item.payment_type ? this.$t('Partial Payment') : this.$t('Final Payment')
                    }
                },
                {
                    text: this.$t('Type'),
                    value: 'type',
                    valueType: CellType.Text,
                    customValue: (item: LienWaiver): void => {
                        return LienWaiverType.Conditional === item.type ? this.$t('Conditional') : this.$t('Unconditional')
                    }
                },
                {
                    text: this.$t('Amount'),
                    value: 'amount',
                    valueType: CellType.Text,
                    customValue: (item: LienWaiver): void => {
                        return `${ this.$filters.formatCurrency(item.amount) }`
                    },
                    align: 'end',
                    valueAlign: 'aright',
                },
                {
                    text: this.$t('Status'),
                    valueType: CellType.StatusDot,
                    value: 'status',
                    customValue: (item: LienWaiver): void => {
                        return LienWaiverType.Conditional === item.type ? this.$t('Attached Conditional') : this.$t('Attached Unconditional')
                    }
                },
            ]

            if (this.isEditingMode) {
                headers.push({
                    text: '',
                    valueType: CellType.Actions,
                    customValue: (item: LienWaiver) => {
                        return [
                                {
                                    variant: 'link',
                                    icon: 'icon-edit',
                                    tooltip: { text: this.$t('Edit') },
                                    onClick: (item, itemIndex) => this.editItem(item, itemIndex)
                                },
                                {
                                    variant: 'link',
                                    icon: 'icon-trash',
                                    tooltip: { text: this.$t('Delete') },
                                    onClick: (item, itemIndex) => this.removeItem(item, itemIndex)
                                }
                            ]
                    },
                    sort: false,
                    extraStyles: 'width: 80px;',
                })
            }

            return headers
        }
    },
    methods: {
        removeItem (item: LienWaiver, itemIndex: number): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to delete lien waiver?'),
                icon: 'icon-trash',
                iconColor: 'var(--error-darker)',
                caption: this.$t('This action cannot be undone.'),
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        loaderOnAction: false,
                        action: null,
                    },
                    {
                        text: this.$t('Yes, Delete Lien Waiver'),
                        class: 'io-btn-primary',
                        loaderOnAction: false,
                        action: () => this.deleteLienWaiver(item, itemIndex),
                    },
                ],
            })
        },
        deleteLienWaiver (item: LienWaiver, itemIndex: number): void {
            this.invoiceLienWaivers.splice(itemIndex, 1)
        },
        editItem (item: LienWaiver, itemIndex: number): void {
            this.$emit('edit', { item, itemIndex })
        },

        openFilePreview (item: LienWaiver): void {
            if ((item.id && item.attached_documents && item.attached_documents[0]) || item.attached_document_ids && item.attached_document_ids[0]) {
                this.$emit('previewFile', item.attached_document_ids ? item.attached_document_ids[0] : item.attached_documents[0])
            }
        },
    }
})