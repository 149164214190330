<template>
    <div class="io-right-panel-inner" :class="{ 'io-white-background': !punches.length }">
        <div class="io-right-panel-inner__content">
            <AddPunch
                v-if="isAddingAnnotation"
                :selected-annotation="selectedAnnotation"
                :model="model"
            />

            <IOPlaceholder
                v-if="!punches.length"
                :subtitle="$t('There are no Punch Items on this drawing yet. Click Add Punch Item to add one.')"
                icon="icon-pin-2"
                class="bg-white"
            />

            <div v-else v-click-outside="annotationDeselected">
                <SinglePunch
                    v-for="(punch, index) in punches"
                    :key="punch.id"
                    :data="punch"
                    :punch-number="getPunchNumber(punch.id)"
                    :is-top-most="index === 0"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./PunchPanel.ts"></script>
<style lang="scss" src="./PunchPanel.scss" scoped></style>
