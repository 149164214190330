import {
    isEmpty,
    uniqueId,
    debounce,
    cloneDeep,
    get,
    isObject,
    isNumber,
    toString,
    clone,
    isArray,
    findIndex,
    filter,
    isUndefined,
    isNull,
    isFunction,
} from 'lodash'
import inviteModal from '@/layouts/offcanvas/invite-modal.vue'
import initials from '@/components/new-theme/initials.vue'
import cached from '@/store/component/cached-list/index'
import cachedListMixin from '@/mixins/cachedListMixin'
import invitationClient from '@/api/invitation/invitationClient'
import { mapGetters } from 'vuex'
import { InviteListType } from './enums/InviteListType'
import InviteModalBanner from '@/components/invite-modal/InviteModalBanner'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'

export default {
    props: {
        itemId: {
            required: false,
            default: null
        },
        attached_employees_ids: {
            type: Array,
            require: false,
            default: () => []
        },
        added_custom: {
            type: Array,
            require: false,
            default: () => []
        },
        invited: {
            type: Array,
            require: false,
            default: Array
        },
        invited_people: {
            type: Array,
            require: false,
            default: null
        },
        invited_pending: {
            type: Array,
            require: false,
            default: null
        },
        layout: {
            type: String,
            required: false,
            default: 'avatars'
        },
        section: {
            type: String,
            required: false,
            default: null
        },
        apiClient: {
            required: false
        },
        displayLimit: {
            type: Number,
            required: false,
            default: 3
        },
        isGenerator: {
            type: Boolean,
            required: false,
            default: false
        },
        hideIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        allowInvite: {
            type: Boolean,
            required: false,
            default: true
        },
        creatorMongoId: {
            required: true,
            type: String
        },
        hideSaveButton: {
            type: Boolean,
            require: false,
            default: false
        },
        /** Hide creator info */
        hideCreator: {
            type: Boolean,
            require: false,
            default: false
        },
        /** Hide creator record by email */
        hideEntriesWithEmail: {
            type: String,
            require: false,
            default: ''
        },
        /** Display or not invite button (for cases when we don't want to use button in the same place) */
        displayInviteButton: {
            type: Boolean,
            required: false,
            default: true
        },
        /** Display or not assign button (for cases when we don't want to use button in the same place) */
        displayAsignButton: {
            type: Boolean,
            required: false,
            default: true
        },
        /** Emit one after fist initialize */
        emitOnReady: {
            type: Boolean,
            required: false,
            default: false
        },
        /** Options for invitation modal */
        inviteModalOptions: {
            type: Object,
            required: false,
            default: null
        },
        /** Button for new modal and layout button-one */
        inviteButtonText: {
            required: false,
            type: String,
            default: ''
        },
        inviteButtonClass: {
            required: false,
            type: String,
            default: ''
        },
        inviteButtonIcon: {
            required: false,
            type: String,
            default: 'fas fa-plus-circle'
        },
        expandable: {
            required: false,
            type: Boolean,
            default: false
        },
        expandableLimit: {
            required: false,
            type: Number,
            default: 10
        },
        notDeletedEmployeesIds: {
            type: Array,
            required: false
        },
        usersBallInCourtIds:{
            type: Array,
            required: false
        },
        usersBallInCourtTooltip:{
            type: String,
            required: false
        },
        getAttached: {
            type: Boolean,
            required: false,
            default: true
        },
        separateCreator: {
            type: Boolean,
            required: false,
            default: false
        },
        /** Use employee mongo IDs instead of local MySQL IDs */
        useEmployeesMongoIds: {
            type: Boolean,
            required: false,
            default: false
        },
        companiesEmployees: {
            type: Object,
            required: false,
            default: () => ({})
        },
        customAction: {
            type: Object,
            required: false,
            default: null
        },
        usersOnline: {
            type: Object,
            required: false,
            default: null
        },
        forceGetEmployeesByMongoIds: {
            type: Boolean,
            required: false,
            default: false
        },
        isTypeTask: {
            required: false,
            type: Boolean,
            default: false
        },
        isTypeRfp: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        allowToInviteSelf: {
            type: Boolean,
            required: false,
            default: false
        },
        showInviteUserStatus: {
            type: Boolean,
            required: false,
            default: true
        },
        usersBallInCourt: {
            type: Array,
            required: false,
            default: () => []
        },
        avatarRingChecker: {
            type: Object,
            default: null
        },
        avatarRingTooltip: {
            type: String,
            default: null
        },
        showInviteModalFirst: {
            type: Boolean,
            default: false
        },
    },
    components: {
        inviteModal,
        initials,
        InviteModalBanner
    },
    mixins: [
        cachedListMixin,
        featureFlagsMixin
    ],
    setup () {
        return {
            isEmpty,
        }
    },
    data () {
        return {
            /**
             * Cloned data
             */
            cloned_attached_employees_ids: [],
            cloned_invited_people: null,
            cloned_invited_pending: null,
            cloned_companies_employees: {},

            duringLoadingData: false,
            inviteModal: false,
            attachedParsed: [],
            addedCustom: [],
            pending: null,
            countAllPeopleNumber: 0,
            isExpandable: false,
            originator: null,
            inviteModalKey: uniqueId(),
            hideInviteButton: false,
            employees: [],
            shouldShowMembers: false,
        }
    },
    created () {
        this.inviteModal = this.showInviteModalFirst

        this.setCountAllPeopleNumber = debounce(
            this.setCountAllPeopleNumber,
            100
        )
    },
    computed: {
        buttonText () {
            return this.inviteButtonText.length ? this.inviteButtonText : this.$t('Select or invite contact')
        },
        countAllPeople () {
            let sum = 0
            if (this.attachedParsed && this.attachedParsed.length) {
                sum += this.attachedParsed.length
            }
            if (
                this.cloned_invited_people &&
                this.cloned_invited_people.length
            ) {
                sum += this.cloned_invited_people.length
            }
            if (
                this.cloned_invited_pending &&
                this.cloned_invited_pending.length
            ) {
                sum += this.cloned_invited_pending.length
            }
            if (this.addedCustom && this.addedCustom.length) {
                sum += this.addedCustom.length
            }
            this.setCountAllPeopleNumber(sum)

            return this.countAllPeopleNumber
        },

        ...mapGetters('appStore', ['getAuthData']),
        userMongoId () {
            return this.getAuthData && this.getAuthData.u_mongo
                ? toString(this.getAuthData.u_mongo)
                : null
        },
        userRoleId () {
            return this.getAuthData && this.getAuthData.u_role_id
                ? parseInt(this.getAuthData.u_role_id)
                : -1
        },
        id () {
            return this.itemId || 0
        },

        /**
         * Num or users above current limit
         * @return {number}
         */
        remainingEmployees () {
            return this.countAllPeopleNumber - this.displayLimit
        },

        /**
         * Attached limited by displayLimit
         *
         * @return {T[]}
         */
        listLimited () {
            let sumList = []

            if (
                this.cloned_invited_people &&
                this.cloned_invited_people.length
            ) {
                let numList = this.cloned_invited_people.length
                for (let i = 0; i < numList; i++) {
                    sumList.push(this.cloned_invited_people[i])
                    if (sumList.length === this.displayLimit) {
                        break
                    }
                }
            }

            if (
                sumList.length < this.displayLimit &&
                this.attachedParsed &&
                this.attachedParsed.length
            ) {
                let numList = this.attachedParsed.length
                for (let i = 0; i < numList; i++) {
                    sumList.push(this.attachedParsed[i])
                    if (sumList.length === this.displayLimit) {
                        break
                    }
                }
            }

            if (
                sumList.length < this.displayLimit &&
                this.addedCustom &&
                this.addedCustom.length
            ) {
                let numList = this.addedCustom.length
                for (let i = 0; i < numList; i++) {
                    sumList.push(this.addedCustom[i])
                    if (sumList.length === this.displayLimit) {
                        break
                    }
                }
            }

            if (
                sumList.length < this.displayLimit &&
                this.cloned_invited_pending &&
                this.cloned_invited_pending.length
            ) {
                let numList = this.cloned_invited_pending.length
                for (let i = 0; i < numList; i++) {
                    let element = this.cloned_invited_pending[i]
                    if (
                        isObject(element) &&
                        element.hasOwnProperty('email')
                    ) {
                        element.isPending = true
                        sumList.push(element)
                        if (sumList.length === this.displayLimit) {
                            break
                        }
                    } else {
                        console.error(
                            'Invalid data - current element doesn\'t contain an email property!'
                        )
                    }
                }
            }

            if (this.usersBallInCourtIds && this.usersBallInCourtIds.length){
                sumList = sumList.map(u => {
                    if (this.usersBallInCourtIds.includes(u._id)){
                        return {
                            ...u,
                            isBallInCourt: true,
                            tooltip: this.usersBallInCourtTooltip
                        }
                    }

                    return { ...u }
                })
            }

            return sumList
        },

        /**
         * First username email or mail
         */
        firstUsernameEmail () {
            let user = null
            if (this.attachedParsed && this.attachedParsed.length) {
                user = this.attachedParsed[0]
            } else if (
                this.cloned_invited_pending &&
                this.cloned_invited_pending.length
            ) {
                user = this.cloned_invited_pending[0]
            }

            if (!user) {
                return ''
            }

            return (
                user.name ||
                user.full_name ||
                user.username ||
                user.email ||
                user.e_email ||
                ''
            )
        },
        hasEmptyListSlot () {
            return !!this.$slots.emptyListSlot
        },
        attachedParsedLimited () {
            this.prepareOriginator()
            if (
                this.expandable &&
                this.attachedParsed.length > this.expandableLimit &&
                !this.isExpandable
            ) {
                return this.attachedParsed.slice(0, this.expandableLimit)
            }
            return this.attachedParsed
        },

        isInviteButtonVisible () {
            return this.displayInviteButton && !this.hideInviteButton
        },
        showMembers () {
            return this.shouldShowMembers
        },
        groupedEmployees () {
            const companiesMembers = {}

            if (!this.employees.length) {
                return companiesMembers
            }
            this.employees.forEach(employee => {
                if (!companiesMembers[employee.company_id]) {
                    companiesMembers[employee.company_id] = {
                        id: employee.company_id,
                        name: employee.company_name,
                        app_type: employee.company_app_type,
                        employees: []
                    }
                }
                companiesMembers[employee.company_id].employees.push(employee)
            })

            return companiesMembers
        }
    },
    async beforeMount () {
        await cached.getList('employees')

        this.cloned_attached_employees_ids =
            clone(this.attached_employees_ids) || []
        this.cloned_companies_employees = clone(this.companiesEmployees) || {}
        this.addedCustom = clone(this.added_custom) || []
        this.getInvitedPending()
        this.getInvitedPeople()

        await this.rebuildAttachedList()
        if (this.emitOnReady) {
            this.emitData()
        }
    },
    watch: {
        inviteModal (value) {
            this.$emit('inviteModalVisibilityChanged', value)
            this.inviteModalKey = uniqueId()
        }
    },
    methods: {
        async cloneAttachedAndRebuild () {
            this.cloned_attached_employees_ids =
                clone(this.attached_employees_ids) || []
            await this.rebuildAttachedList()
        },

        cloneInvitedPending () {
            this.cloned_invited_pending = this.invited_pending
        },

        cloneInvitedPeople () {
            this.cloned_invited_people = this.invited_people
        },


        /**
         * Build all already attached
         */
        async rebuildAttachedList (getByIds = false) {
            if (!this.useEmployeesMongoIds) {
                this.attachedParsed = []
            }

            if (isArray(this.cloned_attached_employees_ids)) {
                if (this.useEmployeesMongoIds) {
                    let requestData = { section: this.section }

                    if (getByIds || this.forceGetEmployeesByMongoIds) {
                        requestData.employees_mongo_ids = this.cloned_attached_employees_ids
                    }

                    if (this.forceGetEmployeesByMongoIds) {
                        requestData.hideCreator = true
                    }

                    this.hideInviteButton = true
                    this.$store.dispatch('loadingBar/setLoading', true)
                    const { data } = await this.$api.realJson(`/meeting-minutes/${ this.$route.params.id }/get-employees-by-mongo-ids`, requestData)
                    this.attachedParsed = []
                    this.attachedParsed = data.result
                    this.hideInviteButton = false
                    this.$store.dispatch('loadingBar/setLoading', false)
                } else {
                    for (let item of this.cloned_attached_employees_ids) {
                        if (
                            !item ||
                            !isObject(item) ||
                            !item.hasOwnProperty('_id') ||
                            !item.hasOwnProperty('type') ||
                            isUndefined(item._id) ||
                            isNull(item._id)
                        ) {
                            continue
                        }
                        if (
                            item.type !== 'employee' &&
                            parseInt(item.type) !== 3
                        ) {
                            continue
                        }

                        const person = await this.getOne('employees', item._id)
                        if (person && person !== -1) {
                            if (
                                this.hideCreator &&
                                person.e_mongo === this.creatorMongoId
                            ) {
                                continue
                            }
                            this.attachedParsed.push(person)
                        }
                    }
                }
            }
            this.$forceUpdate()
        },

        /**
         * Remove person from invitation list
         *
         * @param person
         */
        async detachPerson (person) {
            if (person._id && isNumber(person._id)) {
                let index = findIndex(
                    this.cloned_attached_employees_ids,
                    item => {
                        return item._id === person._id
                    }
                )
                if (index > -1) {
                    this.cloned_attached_employees_ids.splice(index, 1)
                }
            } else if (person.e_email || person.email) {
                let email = person.e_email || person.email
                let index = findIndex(this.cloned_invited_pending, item => {
                    return item.email === email
                })
                if (index > -1) {
                    this.cloned_invited_pending.splice(index, 1)
                }
            }

            await this.rebuildAttachedList()
            this.emitData()
        },

        /**
         * Removed all invites
         */
        async removeAllInvitations () {
            this.cloned_attached_employees_ids = []
            this.cloned_invited_pending = null
            this.cloned_invited_people = null
            await this.rebuildAttachedList()
            this.emitData()
        },

        /**
         * Emit all data after changes
         */
        emitData (options) {
            let attachedEmployees = clone(this.cloned_attached_employees_ids)
            if (!this.useEmployeesMongoIds) {
                attachedEmployees.forEach(employee => {
                    employee.type = 3
                })
            }

            this.$emit('invitationsUpdate', {
                attached_employees_ids: attachedEmployees,
                invited_pending: this.cloned_invited_pending || [],
                invited_people: this.cloned_invited_people || [],
                attachedParsed: this.attachedParsed || [],
                addedCustom: this.addedCustom,
                options: cloneDeep(options)
            })
            this.shouldShowMembers = attachedEmployees.length > 0
        },

        /**
         * Load all pending invitations
         */
        getInvitedPending () {
            if (isArray(this.invited_pending)) {
                this.cloned_invited_pending = clone(this.invited_pending)
                return false
            }
            if (!this.itemId || this.itemId === '0' || this.layout === 'none') {
                return false
            }

            if (
                invitationClient &&
                invitationClient.getPending &&
                isFunction(invitationClient.getPending)
            ) {
                this.duringLoadingData = true
                invitationClient
                    .getPending(
                        this.itemId,
                        this.$route.meta.inviteType,
                        this.section
                    )
                    .then(response => {
                        if (
                            response.data.code === 200 &&
                            response.data.invitations
                        ) {
                            this.cloned_invited_pending =
                                response.data.invitations
                            this.duringLoadingData = false
                            this.$forceUpdate()
                        }
                    })
            }
        },

        /**
         * Load all connected invitations
         */
        getInvitedPeople () {
            if (isArray(this.invited_people)) {
                this.cloned_invited_people = clone(this.invited_people)
                return false
            }
            if (!this.itemId || this.itemId === '0' || this.layout === 'none') {
                return false
            }

            if (
                invitationClient &&
                invitationClient.getInvited &&
                isFunction(invitationClient.getInvited)
            ) {
                this.duringLoadingData = true
                invitationClient
                    .getInvited(
                        this.itemId,
                        this.$route.meta.inviteType,
                        this.section
                    )
                    .then(response => {
                        if (
                            response.data.code === 200 &&
                            response.data.invitations
                        ) {
                            this.cloned_invited_people =
                                response.data.invitations
                            this.duringLoadingData = false
                            this.$forceUpdate()
                        }
                    })
            }
        },

        /**
         * After update on modal, update data here
         *
         * @param update
         */
        async invitationsUpdate (update) {
            if (this.useEmployeesMongoIds) {
                this.cloned_attached_employees_ids = this.parseAttachedEmployeesMongoIds(update.added)
            } else {
                this.cloned_attached_employees_ids = this.parseAttachedEmployeesIds(
                    update.attached_employees_ids
                )
            }

            this.cloned_invited_pending = this.parseInvitedPending(update.invited_pending)
            this.cloned_invited_people = this.parseInvitedPending(update.invited)
            this.addedCustom = update.addedCustom

            const type = this.isTypeTask
                ? InviteListType.Task : this.isTypeRfp
                    ? InviteListType.Rfp : this.$route.meta.inviteType

            if (
                this.itemId &&
                this.itemId !== '0' &&
                invitationClient &&
                invitationClient.getAttached &&
                isFunction(invitationClient.getAttached) &&
                this.getAttached
            ) {
                let response = await invitationClient.getAttached(
                    this.itemId,
                    type,
                    this.section
                )
                if (response && response.data) {
                    this.cloned_attached_employees_ids = response.data.attached_employees_ids

                    if (response.data._attached_companies_employees) {
                        this.cloned_companies_employees = response.data._attached_companies_employees
                    }
                }
            }

            await this.rebuildAttachedList(true)
            const isModelStillOpened = Boolean(this.$refs.inviteModal)

            this.emitData({
                modalStillOpened: isModelStillOpened
            })
            if (isModelStillOpened) {
                this.$refs.inviteModal.getData()
            }
        },

        /**
         * Force reload all data using API
         */
        async loadData (options = null) {
            try {
                const response = await invitationClient.getAllInvited(
                    this.itemId,
                    this.$route.meta.inviteType,
                    this.section
                )
                if (response && response.data) {
                    if (this.useEmployeesMongoIds) {
                        this.cloned_attached_employees_ids = response.data.attached_employees_mongo_ids
                    } else {
                        this.cloned_attached_employees_ids = response.data.attached_employees_ids
                    }

                    this.cloned_invited_pending = response.data.invited_pending
                    this.cloned_invited_people = response.data.invited_people
                }
            } catch (e) {
                console.error(e)
            }

            await this.rebuildAttachedList()
            this.emitData(options)
        },

        /**
         * Parse all pending invitations from modal
         *
         * @param items
         * @return {[]}
         */
        parseAttachedEmployeesIds (items) {
            let result = []
            items.forEach(item => {
                result.push(item)
            })
            return result
        },

        /**
         * Parse all employees from modal
         *
         * @param items
         * @return {[]}
         */
        parseAttachedEmployeesMongoIds (items) {
            let result = []
            items.forEach(item => {
                result.push(item.mongoId)
            })

            return result
        },

        /**
         * Parse all pending invitations from modal
         *
         * @param items
         * @return {[]}
         */
        parseInvitedPending (items) {
            let result = []
            items.forEach(item => {
                result.push(item)
            })
            return result
        },

        showInviteModal () {
            if (!this.allowInvite) {
                return
            }
            this.inviteModal = true
        },
        setCountAllPeopleNumber (value) {
            this.countAllPeopleNumber = value
            this.$forceUpdate()
        },

        /**
         * Prepare originator
         */
        prepareOriginator () {
            if (this.separateCreator && isArray(this.attachedParsed)) {
                let creator = this.attachedParsed.find(
                    item =>
                        item.hasOwnProperty('e_mongo') &&
                        item.e_mongo === this.creatorMongoId
                )

                // look for Originator when we show the component for recipient
                if (!creator) {
                    creator = this.cloned_invited_people.find(
                        item =>
                            item.hasOwnProperty('_id') &&
                            item._id === this.creatorMongoId
                    )
                    if (isObject(creator)) {
                        creator.fullname = `${ creator.firstname } ${ creator.lastname }`
                        creator.mongoId = creator._id
                        creator.photo = creator._id
                        const index = this.cloned_invited_people.findIndex(
                            item =>
                                item.hasOwnProperty('_id') &&
                                item._id === this.creatorMongoId
                        )
                        if (index !== -1) {
                            this.cloned_invited_people[index]
                                .isOriginator = true
                        }
                    }
                }

                this.originator = isObject(creator) ? creator : null
            }
        },

        /**
         * Check if employee is archived
         */
        isArchivedEmployee (item) {
            return !item.e_active && item.typeList === 'employee'
        },

        /**
         * Check if user is online
         */
        isUserOnline (itemId) {
            if (!this.usersOnline) {
                return false
            }
            return this.usersOnline.some(el => el.mongo_id === itemId)
        },

        onCloseModal () {
            this.inviteModal = false
            this.$emit('closeListModal')
        },
        async updateAdded (itemId, action) {
            action === 'attach' ? await this.attachEmployee(itemId) : this.detachEmployee(itemId)
        },
        async attachEmployee (itemId) {
            try {
                const { data } = await this.$api.get('task/employees/' + itemId)

                if (!data.company_id) {
                    return
                }
                this.employees.push(data)
            } catch (e) {
                console.error(e)
            }
        },
        detachEmployee (itemId) {
            this.employees = this.employees.filter(employee => employee.id !== itemId)
        },
        getIsMarkedWithRing (itemId) {
            if (typeof this.avatarRingChecker === 'function') {
                return this.avatarRingChecker(itemId)
            }
            return false
        },
        avatarIsVisible (itemId) {
            return !!this.avatarRingTooltip?.length && this.getIsMarkedWithRing(itemId)
        }
    }
}
