export enum MenuItems {
    APPLIED_FUNDS,
    FUNDING_RULES,
    TIMELINE,
    DETAILS
}

export enum FundTypes {
    CAPITAL = 'capital',
    PROJECT_SPECIFIC = 'project'
}

export enum FundExportSortTypes {
    VENDOR ='vendor',
    PACKAGE = 'package_number'
}

export const fundExportSortTypesReadable: Record<FundExportSortTypes, string> = {
    [FundExportSortTypes.VENDOR]: 'Vendor',
    [FundExportSortTypes.PACKAGE]: 'Package Number',
}

export enum HeaderType {
    EXPORT,
    LIST
}
