<template>
    <tr :ref="`row-${folder._id}`"
        v-click-outside="clickOutsideRow"
        class="io-single-folder-row"
        @click.middle="loadFolders(true)"
    >
        <td class="io-select-file-cell"></td>

        <td>
            <div class="io-name">
                <div class="io-document-type" :class="folderIconClass" @click="loadFolders">
                    <i v-if="fileType" class="icon-file"></i>
                    <i v-else :class="{ 'icon-folder': userCreatedFolder, 'icon-folder-2': !userCreatedFolder, 'io-inherited': folder.inherited_access }"></i>
                </div>

                <i v-if="isVirtualFolder" class="io-virtual-folder"></i>
                <i v-else-if="!fromTrash && !isDeleted" class="icon-star" :class="{ 'io-active': folder.flag? folder.flag: false }" @click="toggleImportant"></i>

                <template v-if="!editMode">
                    <div class="io-name" @click="loadFolders">
                        <template v-if="isSearchResult">{{ folder.path }}</template>

                        <template v-else>{{ userCreatedFolder ? folder.name : $t(folder.name) }}</template>
                    </div>
                </template>

                <input
                    v-else
                    ref="inputName"
                    autofocus
                    :maxlength="300"
                    v-model="newFolderName"
                />
            </div>
        </td>

        <td class="io-size">
            <template v-if="isSearchResult && fileType">
                {{ $filters.fileSize(folder.file_size) }}
            </template>
            <template v-else>
                <span v-if="isCalculatingSize">...</span>
                <span v-else>{{ $filters.fileSize(folder.folder_size) }}</span>
            </template>
        </td>

        <td>
            <div v-if="!userCreatedFolder">-</div>
            <div v-else-if="!editMode && folder.creator_full_name && folder.creator_full_name.length" class="io-added-by">
                <Initials :fullName="folder.creator_full_name" />
                <div class="io-user-name">{{ folder.creator_full_name }}</div>
            </div>
        </td>


        <td>
            {{ $filters.parseDate(folder.updated_at) }}
        </td>

        <td v-if="!allowToSelectFiles" class="aright">
            <template v-if="!isSearchResult && userCreatedFolder">
                <DropdownButtonNew v-if="!editMode" :iconMode="true" fontAwesomeClass="fas fa-ellipsis-v">
                    <template v-if="fromTrash || isDeleted">
                        <div class="io-single-option" @click="onClickRestoreFolder">
                            <i class="fas fa-archive"></i>
                            <span>{{ $t('Yes, restore') }}</span>
                        </div>

                        <div class="io-single-option" @click="onClickHardDeleteFolder">
                            <i class="far fa-trash-alt"></i>
                            <span>{{ $t('Delete') }}</span>
                        </div>
                    </template>

                    <template v-else>
                        <div class="io-single-option js-rename" @click="onClickRenameFolder">
                            <i class="far fa-pen"></i><span>{{ $t('Rename') }}</span>
                        </div>

                        <div v-if="isSharingFeatureActive && folderBelongsToCurrentUser" class="io-single-option io-nohover" @click="onClickShareFolder">
                            <i class="far fa-share-alt"></i>
                            <span>{{ $t('Share') }}</span>
                        </div>

                        <div class="io-single-option js-rename" @click="onClickArchiveFolder(arrayId, folder._id)">
                            <i class="far fa-archive"></i><span>{{ $t('Archive') }}</span>
                        </div>
                    </template>
                </DropdownButtonNew>

                <div v-else class="io-edit-mode-btns-actions">
                    <div class="io-icon-action" @click="saveEditedData"><i class="fal fa-check"></i></div>
                    <div class="io-icon-action" @click="cancelEditing(folderNameNotChanged)"><i class="fal fa-times"></i></div>
                </div>
            </template>
        </td>
    </tr>
</template>
<script src="./single-folder.js"></script>
<style lang="scss" scoped src="./single-folder.scss"></style>
