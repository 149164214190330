<template>
    <div id="list-table">
        <Placeholder
            v-if="!hidePlaceholder && !items.length && !isLoading"
            icon="icon-inbox"
            :title="$t('No records found')"
            :subtitle="$t('Please change filters to see matching records.')"
        />
        <LoadingScreen v-else-if="isLoading" :custom-show-loading="isLoading"/>
        <div v-show="items.length && !isLoading" ref="tableWrapper" class="io-table-wrapper">
            <div class="io-invoices-table">
                <Table
                    :headers="headers"
                    :items="items"
                    :sort-by="sortBy"
                    @sortChange="onSortChange"
                >
                    <template #tbody>
                        <InvoiceTableRow
                            v-for="item in items"
                            :key="item.id"
                            :item="item"
                            :headers="headers"
                            @onRemoveAppPackage="onRemoveAppPackage"
                        />

                        <tr v-if="totals && isFFTotalsOn">
                            <td
                                v-for="item in headers"
                                class="io-invoices-table__total"
                            >
                                <template v-if="mappedTotals[item.value]">
                                    <span v-if="['type', 'sequence_number'].includes(item.value)" class="io-invoices-table__total-text">
                                        {{ mappedTotals[item.value] }}
                                    </span>
                                    <span v-else class="io-f-row io-f-end">
                                        {{ $filters.formatProjectCurrencyInt(mappedTotals[item.value] ?? 0) }}
                                    </span>
                                </template>
                            </td>
                        </tr>
                    </template>
                </Table>
            </div>
        </div>
        <LoadingScreen :custom-show-loading="loadingData" />
    </div>
</template>

<script lang="ts" src="./InvoicesListTable.ts"></script>
<style lang="scss" src="./InvoicesListTable.scss" scoped></style>
