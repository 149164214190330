<template>
    <div class="io-invoices-list-header-wrapper">
        <div
            class="io-invoices-list-header"
            :class="{'io-invoices-list-header--vendors': isVendorsList}"
        >
            <div class="io-f-row io-f-start">
                <h5>{{ title }}</h5>
                <StatusPill v-if="isVendorsList" variant="blue-light" :text="vendorsStatusPillText"/>
                <StatusPill v-if="isVendorDetailsView" variant="blue-light" :text="invoicesStatusPillText"/>
            </div>

            <div class="io-f-row">
                <IOButton
                    v-if="hasManageColumns"
                    variant="link"
                    icon="icon-grid-14"
                    :disabled="placeholderVisible"
                    @click="initManageColumns"
                >
                    {{ $t('Manage Columns') }}
                </IOButton>
                <MultiSwitch
                    v-if="invoicesTabs"
                    :options="invoicesTabs"
                    v-model="activeTab"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./InvoicesListHeader.ts"></script>
<style lang="scss" src="./InvoicesListHeader.scss" scoped></style>
