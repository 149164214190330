import mathHelpers from '@/helpers/mathHelpers'
import type Currency from '@/interfaces/Currency'

export function formatCurrencyValue (total: number, ifZeroValue: string = ''): string {
    if (total !== null && total !== undefined) {
        const value = mathHelpers.cutPrecision(total)
        if (isNaN(value)) {
            return '0.00'
        }

        // if total holds zero value then ex. N/A will be printed
        // ifZeroValue - any string
        if (total === 0 && ifZeroValue && typeof ifZeroValue === 'string') {
            return ifZeroValue
        }

        return value.toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
    } else {
        return '0.00'
    }
}

export function formatCurrencyValueIfNotString (value: string | number): string {
    if (typeof value !== 'string') {
        return formatCurrencyValue(value)
    } else {
        return value // ex. N/A
    }
}

// Extracted from iconLibrary
export function formatMoney (currency: Currency, value: number | null, precision: number = 2): string {
    const configFormatter = {
        maximumFractionDigits: Number(precision),
        minimumFractionDigits: Number(precision)
    }

    const formattedValue = new Intl.NumberFormat('en-US', configFormatter).format(value)

    return addSymbolToMoney(currency, formattedValue)
}

// Extracted from iconLibrary
function addSymbolToMoney (currency: Currency, value: string = '0.00'): string {
    if (currency === undefined || Object.keys(currency).length === 0) {
        return null
    }

    let result = null
    let val = Math.sign(parseFloat(value))
    let order = null

    if (val !== -1) {
        order = currency.format.pos.replaceAll('%', '').charAt(0)
        result = order === 'v' ? value + String.fromCharCode(160) + currency.symbol : currency.symbol + value
    } else {
        order = currency.format.pos.replaceAll('%', '')
            .replaceAll('(', '').replaceAll(')', '').charAt(0)

        result = order === 'v' ? '(' + value + currency.symbol + ')' : '(' + currency.symbol + value + ')'
        result = result.replace('-', '')
    }

    if (currency.hard_space_after_symbol === true) {
        result = result.replace(String.fromCharCode(160), '')
        result = currency.symbol + String.fromCharCode(160) + result.replace(currency.symbol, '')
    }

    return result
}
