import { ApiClientV2 } from '@/api/api'
import { DashboardRequestInterface } from '@/interfaces/modules/dashboard/DashboardRequestInterface'
import { DashboardInterface } from '@/interfaces/modules/dashboard/DashboardInterface'
let client = ApiClientV2

const dashboardClient = {
    getDashboard (params: DashboardRequestInterface): DashboardInterface {
        return client.get(`/dashboard`, params)
    }
}

export default dashboardClient
