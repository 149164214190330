import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import SingleNotification from './parts/SingleNotification.vue'
// @ts-ignore
import notificationMixin from '@/layouts/parts/top-bar/mixins/notificationMixin.js'

export default defineComponent({
    name: 'ToastrList',
    components: {
        SingleNotification
    },
    mixins: [notificationMixin],
    computed: {
        ...mapGetters('notificationToastr', ['alertsList'])
    },
    methods: {
        dismissAllAlerts (): void {
            this.markAllAsRead('invitation')
            this.markAllAsRead('notification')
        }
    }
})
