<template>
    <div>
        <div class="flex gap-24-px items-center px-24-px py-16-px border border-b border-light-bg">
            <IOInput
                icon="icon-search"
                class="grow"
                :placeholder="$t('Search for sections...')"
                clearable
                v-model="search"
            />
        </div>

        <Transition name="fade-from-bottom" mode="out-in">
            <div v-if="filteredDivisions.length">
                <DivisionsShow
                    v-for="division in filteredDivisions"
                    :key="division.id"
                    :division="division"
                    :search="search"
                />
            </div>

            <IOPlaceholder
                v-else
                icon="icon-file-text"
                :title="$t('No Records Found')"
            >
                <IOButton size="small" @click="search = ''">{{ $t('Clear filter') }}</IOButton>
            </IOPlaceholder>
        </Transition>
    </div>
</template>

<script lang="ts" src="./DivisionsIndex.ts"></script>
<style lang="scss" src="./DivisionsIndex.scss" scoped></style>
