<template>
    <div id="custom-holidays-items" class="io-holidays-view-container">

        <div class="io-f-row">
            <h2 class="holiday-calendar-title">
                Company Holidays
            </h2>

            <!-- top controls -->
            <div class="holiday-calendar-year-switch-container">
                <span class="holiday-calendar-year-previous" @click="changeYear(-1)">&larr;</span>
                <span class="holiday-calendar-year-switch">{{data.calendarData.year}}</span>
                <span class="holiday-calendar-year-next" @click="changeYear(1)">&rarr;</span>
            </div>
            <div class="holiday-legend-container">
                <span class="holiday-legend">
                    <span class="holiday-legend-color">
                        <color-picker :value="holidaysColorScheme.basic" pickerId="holidaysColorSchemeBasic" correctPosition="left_-100px"
                                      @onConfirm="colorSelectedBasic" @onShow="setOpenedPickerId" @onClose="saveChangedColor"/>
                    </span>
                    <span class="holiday-legend-name">Federal Holiday</span>
                </span>
                <span class="holiday-legend">
                    <span class="holiday-legend-color">
                        <color-picker :value="holidaysColorScheme.custom" pickerId="holidaysColorSchemeCustom" correctPosition="left_-100px"
                                      @onConfirm="colorSelectedCustom" @onShow="setOpenedPickerId" @onClose="saveChangedColor"/>
                    </span>
                    <span class="holiday-legend-name">Company Holiday</span>
                </span>
                <span class="holiday-legend">
                    <span class="holiday-legend-color">
                        <color-picker :value="holidaysColorScheme.blackout" pickerId="holidaysColorSchemeBlackout" correctPosition="left_-100px"
                                      @onConfirm="colorSelectedBlackout" @onShow="setOpenedPickerId" @onClose="saveChangedColor"/>
                    </span>
                    <span class="holiday-legend-name">PTO Blackout Day</span>
                </span>
            </div>
        </div>

        <!-- calendar boxes -->
        <div v-if="displayCalendar" class="component-wrapper holiday-calendar-container io-f-row">
            <div class="holiday-calendar-table-wrapper">
                <template v-for="(item, index) in data.calendarData.months" :key="index">
                    <table class="holiday-calendar-table">
                        <thead>
                            <tr>
                                <th colspan="7">{{ index }}</th>
                            </tr>
                            <tr>
                                <th>S</th>
                                <th>M</th>
                                <th>T</th>
                                <th>W</th>
                                <th>T</th>
                                <th>F</th>
                                <th>S</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item2, index2) in item" :key="index2">
                                <td v-for="(item3, index3) in item2" :key="index3"
                                    :class="setAttributeHoliday(data.calendarData.year, index, item3, 'holiday-calendar-day', 'type')"
                                    :title="setAttributeHoliday(data.calendarData.year, index, item3, '', 'name')"
                                    :style="{backgroundColor: setAttributeHoliday(data.calendarData.year, index, item3, '', 'color') + '!important'}"
                                    @click="openPopup(data.calendarData.year, index, item3)"
                                >
                                    {{item3}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>

        <!-- add new holiday popup -->
        <template v-if="popupDisplay">
            <div class="io-global-popup">
                <div class="io-popup-header io-f-row">
                    <p class="io-header">{{popupConf.headerTitle}}</p>
                    <div class="io-popup-close" @click="popupDisplay = false">
                        <img src="/new-theme/img/close-icon.svg"/>
                    </div>
                </div>
                <div class="io-popup-content">

                    <div class="io-popup-content-holiday-row">
                        <label class="io-popup-label">Name</label>
                        <input class="io-popup-input" type="text" v-model="popupConf.holidayName"/>
                    </div>

                    <div class="io-popup-content-holiday-row">
                        <label class="io-popup-label">Type of Day</label>
                        <label for="companyHoliday" class="io-popup-label-radio">
                            <input id="companyHoliday" class="io-popup-radio" style="width: 16px;" type="radio" value="custom" v-model="popupConf.holidayType"/>
                            <span>Company Holiday</span>
                        </label>
                        <label for="blackoutDay" class="io-popup-label-radio">
                            <input id="blackoutDay" class="io-popup-radio" style="width: 16px;" type="radio" value="blackout" v-model="popupConf.holidayType"/>
                            <span>Blackout Day</span>
                        </label>
                    </div>

                    <div class="io-popup-content-holiday-row">
                        <label class="io-popup-label">Applicable locations</label>
                        <template v-for="(item, index) in mappedLocationsPairs" :key="index">
                            <label class="io-popup-label-checkbox">
                                <input class="io-popup-checkbox" type="checkbox" :value="item.id" style="top: 4px;" v-model="popupConf.holidayLocation"/>
                                <span>{{item.name}}</span>
                            </label>
                        </template>
                    </div>

                    <div class="io-popup-content-holiday-row">
                        <label class="io-popup-label">Date Range</label>
                        <div class="ctk-datepicker-range">
                            <CtkDateTimePicker identifier="DateRangePicker" dateFormat="YYYY-MM-DD" dateFormatOutput="YYYY-MM-DD" dateFormatted="ll"
                                               :noValueToCustomElem="true" :autoUpdateCustomInput="true" :onlyDate="true" :rangePicker="true"
                                               :valueInput="popupConf.holidayDateRange" @updateDateRangeInput="updateDateRange" />
                        </div>
                    </div>

                </div>
                <div class="io-popup-buttons io-f-row">
                    <div class="io-popup-buttons-group-left">
                        <a class="io-btn io-btn-primary-compact io-btn-save io-holiday-btn io-holiday-btn-save" @click="saveHoliday()">Save</a>
                        <a v-if="popupConf.holidayRemovable" class="io-btn io-btn-primary-compact io-btn-delete io-holiday-btn" @click="deleteHoliday()">Delete</a>
                    </div>
                    <div class="io-popup-buttons-group-right">
                        <a class="io-btn io-btn-secondary-compact io-btn-cancel io-holiday-btn" @click="popupDisplay = false">Cancel</a>
                    </div>
                </div>
            </div>
            <div class="io-proposal-mask"></div>
        </template>

    </div>
</template>

<script>
    import moment from 'moment'
    import { cloneDeep } from 'lodash'
    import CtkDateTimePicker from '@/elements/CtkDateTimePicker.vue'
    import holidayMixin from '@/mixins/company-holidays/holidayMixin'
    import colorPicker from '@/components/color-pickers/chromeColorPicker'
    import { SweetAlert } from '@/plugins/sweet-alert/index.js'

    export default {
        name: 'FederalHolidaysCalendar',
        components: {
            CtkDateTimePicker,
            colorPicker
        },
        props: {
            data: {
                type: Object
            }
        },
        mixins: [holidayMixin, SweetAlert],
        data () {
            return {
                popupConf: {
                    headerTitle: 'Add',
                    holidayId: '',
                    holidayName: '',
                    holidayType: '',
                    holidayLocation: [],
                    holidayPTO: [],
                    holidayDateRange: '',
                    holidayRemovable: false
                },
                popupDisplay: false,
                displayCalendar: false,
                holidaysColorScheme: {
                    basic: '#d3d3d3',
                    custom: '#b0c4de',
                    blackout: '#000000'
                },
                openedPickerId: '',
                closedPickerId: ''
            }
        },
        computed: {
            mappedLocationsPairs () {
                return this.data.locationsPairs.map(location => {
                    return {
                        name: location.name,
                        id: location.id.$oid
                    }
                })
            },
        },
        mounted () {
            // set holidays color scheme
            this.holidaysColorScheme = this.data.holidaysColorScheme
            // show calendar
            this.displayCalendar = true
        },
        methods: {
            setOpenedPickerId (data) {
                this.openedPickerId = data.pickerId
            },
            saveChangedColor (data) {
                this.closedPickerId = data.pickerId
            },
            getHolidayBackgroundColor (holidayType) {
                return this.holidaysColorScheme[holidayType]
            },
            colorSelectedBasic (color) {
                this.holidaysColorScheme.basic = color
                this.updateColorScheme()
            },
            colorSelectedCustom (color) {
                this.holidaysColorScheme.custom = color
                this.updateColorScheme()
            },
            colorSelectedBlackout (color) {
                this.holidaysColorScheme.blackout = color
                this.updateColorScheme()
            },
            updateColorScheme () {
                if (this.openedPickerId === this.closedPickerId) {
                    this.$api.post('/settings/update-holidays-color-scheme', this.holidaysColorScheme).then((result) => {

                    }).catch((error) => {
                        this.errorHandle(error)
                    })
                }
            },
            changeYear (value) {
                this.data.calendarData.year = (parseInt(this.data.calendarData.year) + parseInt(value))
                this.displayCalendar = false
                this.$store.dispatch('loadingBar/setLoading', true, { root: true })

                this.$api.get('/settings/get-holidays-calendar/' + this.data.calendarData.year).then((response) => {
                    if (String(response.data.calendarData.year) === String(this.data.calendarData.year)) {
                        this.data.basicItems = response.data.basicItems
                        this.data.customItems = response.data.customItems
                        this.data.calendarData = response.data.calendarData
                        this.displayCalendar = true
                        this.$store.dispatch('loadingBar/setLoading', false, { root: true })
                    }
                }).catch((error) => {
                    this.errorHandle(error)
                })
            },
            updateDateRange (value) {
                this.popupConf.holidayDateRange = value
            },
            deleteHoliday () {
                let swalConfig = {
                    title: 'Delete holiday',
                    type: 'question',
                    cancelButtonText: 'No'
                }

                // popup - ask user to confirm action
                this.confirm(() => {
                    this.popupDisplay = false

                    this.$api.delete('/settings/delete-holiday/' + this.popupConf.holidayId).then((response) => {
                        this.data.customItems = response.data.customItems
                    }).catch((error) => {
                        this.errorHandle(error)
                    })
                }, swalConfig, () => {})
            },
            saveHoliday () {
                this.popupDisplay = false

                let postData = cloneDeep(this.popupConf)
                postData.year = this.data.calendarData.year

                this.$api.post('/settings/save-holiday', postData).then((response) => {
                    this.data.customItems = response.data.customItems
                }).catch((error) => {
                    this.errorHandle(error)
                })
            },
            openPopup (year, month, day) {
                if (day !== '') {
                    let date = moment(month + ' ' + day + ', ' + year).format('MM/DD/YYYY')
                    let result = this.isHoliday(date, 'all')

                    // edit, delete
                    // do not allow to manage basic holidays
                    if (result && result.type !== 'basic') {
                        let dateStart = moment(result.date1).format('MM/DD/YYYY')
                        let dateEnd = moment(result.date2).format('MM/DD/YYYY')
                        this.popupConf.headerTitle = 'Edit'
                        this.popupConf.holidayId = result.id
                        this.popupConf.holidayType = result.type
                        this.popupConf.holidayName = result.name
                        this.popupConf.holidayLocation = result.locations
                        this.popupConf.holidayDateRange = dateStart + ' - ' + dateEnd
                        this.popupConf.holidayRemovable = true
                        // add new
                    } else {
                        this.popupConf.headerTitle = 'Add'
                        this.popupConf.holidayType = 'custom'
                        this.popupConf.holidayName = ''
                        this.popupConf.holidayId = ''

                        if (this.data.headquartersId !== '') {
                            this.popupConf.holidayLocation = [this.data.headquartersId.$oid]
                        } else {
                            this.popupConf.holidayLocation = []
                        }

                        this.popupConf.holidayDateRange = date + ' - ' + date
                        this.popupConf.holidayRemovable = false
                    }

                    this.popupDisplay = true
                }
            },
            setAttributeHoliday (year, month, day, baseResult = '', name = '') {
                if (this.popupDisplay) {return false}

                if (day !== '') {
                    let date = moment(month + ' ' + day + ', ' + year).format('MM/DD/YYYY')
                    let result = this.isHoliday(date, name)

                    if (result) {
                        if (name === 'type') {
                            baseResult += ' ' + this.colorSchemeMap[result]
                        } else if (name === 'name') {
                            baseResult = result
                        } else if (name === 'color') {
                            baseResult = this.getHolidayBackgroundColor(result)
                        }
                    }
                    return baseResult
                }
            },
            isHoliday (date, name) {
                let result = false
                name = name === 'color' ? 'type' : name

                let itemsList = [this.data.basicItems, this.data.customItems]
                itemsList.forEach(function (items, idx) {
                    if (items && !result) {
                        items.forEach(function (holiday, idx2) {
                            if (moment(date).isBetween(holiday.date1, holiday.date2, 'days', '[]')) {
                                result = name === 'all' ? holiday : holiday[name]
                            }
                        })
                    }
                })
                return result
            }
        }
    }
</script>

<style lang="scss">
    .io-holidays-view-container .io-holiday-btn {
        margin-bottom: 0 !important;
        top: 0 !important;
    }
    .io-holidays-view-container .io-popup-buttons-group-left, .io-popup-buttons-group-right {
        display: flex;
    }
    .io-holidays-view-container .io-popup-buttons {
        justify-content: space-between !important;
    }
</style>

<style lang="scss" scoped>
    #custom-holidays-items {
        min-height: 500px;
    }
    .holiday-legend-container {
        width: 140px;
    }
    .holiday-calendar-container {
        flex-flow: row wrap;
    }

    .holiday-calendar-table {
        width: auto;
        th {
            text-align: center !important;
            padding: 16px !important;
            &:last-of-type {
                padding: 16px !important;
            }
        }
        td {
            padding: 10px 16px !important;
            text-align: center !important;
        }
    }
    .hlc-basic, .hlc-custom, .hlc-blackout {
        color: white !important;
    }
    .holiday-calendar-day, .holiday-calendar-year-previous, .holiday-calendar-year-next {
        cursor: pointer;
    }
    .holiday-legend {
        float: left;
        width: 140px;
    }
    .holiday-legend span {
        float: left;
    }
    .holiday-legend-name {
        padding-left: 5px;
    }
    .holiday-legend-color {
        width: 10px;
        height: 10px;
    }
    .io-holidays-view-container .io-global-popup {
        min-width: 600px;
        width: auto !important;
        top: 30vh !important;
        left: 35vw !important;
    }
    .holiday-calendar-table-wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
    .io-popup-content-holiday-row {
        margin-bottom: 8px;
    }
</style>
