export enum WebCredentialEnum {
    PDFTRON_LICENSE_KEY = 'PDFTRON_LICENSE_KEY',
    DROMO_API_KEY = 'DROMO_API_KEY',
    GOOGLE_MAPS_API_KEY = 'GOOGLE_MAPS_API_KEY',
    QRVEY_DOMAIN = 'QRVEY_DOMAIN',
    SENTRY_DSN = 'SENTRY_DSN',
    SENTRY_ALLOWED_URL = 'SENTRY_ALLOWED_URL',
    SENTRY_TRACES_SAMPLE_RATE = 'SENTRY_TRACES_SAMPLE_RATE',
    MAIN_DOMAIN = 'MAIN_DOMAIN',
    REPORTING_EXPORTER_DASHBOARD_ID = 'REPORTING_EXPORTER_DASHBOARD_ID',
    REGION = 'REGION',
    IS_EPHEMERAL = 'IS_EPHEMERAL',
    TRACES_DEBUG = 'TRACES_DEBUG',
    TRACES_ENABLED = 'TRACES_ENABLED',
    ENV = 'ENV',
    ENV_NAME = 'ENV_NAME',
}
