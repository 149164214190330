<template>
    <td class="io-cell-address-column">
        <div class="io-f-row">
            <span>{{ getText() }}</span>
        </div>
    </td>
</template>

<script lang="ts" src="./AddressColumn.ts"></script>
<style lang="scss" src="./AddressColumn.scss" scoped></style>
