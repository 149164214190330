import { defineComponent, PropType } from 'vue'
import Timeline from '@/components/timeline/timeline.vue'
import TimelineLogs from '@/interfaces/components/timeline/TimelineLogs'

export default defineComponent({
    name: 'FundingSourcesTimeline',
    components: { Timeline },
    props: {
        history: {
            required: true,
            type: Array as PropType<TimelineLogs[]>
        }
    }
})
