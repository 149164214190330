import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import DrawingSets from '@/components/drawings/embed/drawing-sets/DrawingSets.vue'
import DrawingSheets from '@/components/drawings/embed/drawing-sheets/DrawingSheets.vue'
import DrawingSheet from '@/components/drawings/embed/drawing-sheet/DrawingSheet.vue'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar'
import { PunchDrawingType } from '@/interfaces/modules/projects/punch-list/PunchDrawingType'

export default defineComponent({
    name: 'DrawingPunch',
    components: {
        DrawingSets,
        DrawingSheets,
        DrawingSheet
    },
    data () {
        return {
            selectReferenceSheetModalShown: false
        }
    },
    computed: {
        ...mapGetters('drawingsV2', {
            parentFormShown: 'getParentFormShown',
            drawingSetsShown: 'getDrawingSetsShown',
            drawingSheetsShown: 'getDrawingSheetsShown',
            drawingSheetShown: 'getDrawingSheetShown'
        }),
        ...mapGetters('drawingMarkup', {
            selectSheetPopup: 'getSelectSheetPopup',
            getSelectSheetPopupSkipDrawing: 'getSelectSheetPopupSkipDrawing'
        }),
        ...mapGetters('project', {
            projectObj: 'projectObj'
        }),
        drawingsShown (): boolean {
            return this.drawingSheetsShown || this.drawingSetsShown || this.drawingSheetShown
        }
    },
    watch: {
        parentFormShown: {
            async handler (newVal: boolean): Promise<void> {
                if (newVal) {
                    this.selectReferenceSheetModalShown = false
                    await this.$store.dispatch('lazyList/resetEndpointParams')
                    await this.$store.dispatch('lazyList/resetFiltersSelected')
                    await this.$store.dispatch('modal/setHidden', false)
                }
            }
        },
        selectSheetPopup: {
            handler (newVal: boolean): void {
                if (newVal) {
                    this.openSelectReferenceSheetModal()
                }
            }
        }
    },
    methods: {
        async openSelectReferenceSheetModal (): Promise<void> {
            this.setLoadingBar(true)
            this.selectReferenceSheetModalShown = true
            await this.$store.dispatch('modal/setHidden', true)
            if (!this.getSelectSheetPopupSkipDrawing) {
                this.showDrawingSets(RightSideBar.PUNCH)
            }
            this.setLoadingBar(false)
        },
        async addNewPunchItem (drawingType: PunchDrawingType): Promise<void> {
            const modalData = {
                task_item_id: {
                    _id: this.projectObj._id,
                    _name: this.projectObj._name,
                    id: this.projectObj._id,
                    type: this.projectObj.type
                },
                task_type: 9,
                task_type_id: 'punch_item',
                task_type_field: 'punch_item',
                drawing_type: drawingType
            }

            await this.$store.dispatch('modal/setAdditionalData', modalData)
            await this.$store.dispatch('modal/setShow', 'modal-task', { root: true })
            this.$store.commit('drawingMarkup/SET_DRAWING_PIN', {})
        },
        showDrawingSets (type: RightSideBar): void {
            this.$store.commit('drawingsV2/SET_PARENT_FORM_SHOWN', false)
            this.$store.commit('drawingsV2/SET_DRAWING_SETS_SHOWN', true)
            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                status: true,
                type: type
            })
        }
    }
})
