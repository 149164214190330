import { defineComponent } from 'vue'

const modalOnDelete = defineComponent({
    methods: {
        showModalOnDelete (onRemove: Function): void {
            return this.showPopupAlert({
                title: this.$t('Are you sure you want to remove this Fund from the project?'),
                caption: this.$t('This action cannot be undone.'),
                icon: 'icon-cross-rec',
                iconColor: 'var(--error-darker)',
                disabledInSessionCallback: onRemove,
                sessionKey: 'project-funding-remove',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Yes, Remove Fund'),
                        class: 'io-btn-primary',
                        saveDisabledInSession: true,
                        action: onRemove,
                    },
                ],
            })
        },
        showModalOnDeleteProjectSpecificFund (onRemove: Function): void {
            return this.showPopupAlert({
                title: this.$t('Are you sure you want to delete this project specific Fund?'),
                caption: this.$t('This action cannot be undone.'),
                icon: 'icon-trash',
                iconColor: 'var(--error-darker)',
                disabledInSessionCallback: onRemove,
                sessionKey: 'project-funding-remove',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Yes, Delete Fund'),
                        class: 'io-btn-primary',
                        saveDisabledInSession: true,
                        action: onRemove,
                    },
                ],
            })
        },
    },
})

export default modalOnDelete
