<template>
    <LoadingScreen :custom-show-loading="loading"/>
    <ContentTwoSidesTemplate v-if="!loading" class="io-content-template" iconClass="icon-info" :title="$t('Information')">
        <template #leftSide>
            <div class="io-form" :class="{ 'io-form--loading': dataLoading }">
                <div class="io-form__item io-form__item-id">
                    <div>
                        <IOLabel :text="$t('Select Vendor')" :is-required="true" />
                        <DropdownButton class="io-vendors-list" buttonClass="io-custom-dropdown" name="" :lazyLoadingMenu="true" @loadOptions="showMoreVendors">
                            <template #selectedItem>
                                <span v-if="selectedVendor" class="io-custom-dropdown__item">
                                    <Avatar
                                        size="sm"
                                        :employeeMongoId="selectedVendor.id"
                                        :employeeFullName="selectedVendor.name"
                                        :showFullName="true"
                                    />
                                </span>
                                <span v-else class="io-custom-dropdown__placeholder">
                                    <div class="io-custom-dropdown__placeholder-icon">
                                        <span class="icon-plus"></span>
                                    </div>
                                    <IOInput :placeholder="$t('Search or Choose Vendor')" v-model="searchVendor" />
                                </span>
                            </template>
                            <template #default>
                                <Avatar
                                    v-for="item in filteredVendors"
                                    :key="`vendor-${item.id}`"
                                    class="io-dropdown-option"
                                    size="sm"
                                    :employeeMongoId="item.id"
                                    :employeeFullName="item.name"
                                    :showFullName="true"
                                    @click="selectVendor(item)"
                                />
                            </template>
                        </DropdownButton>
                    </div>
                    <div>
                        <div class="io-label-wrapper">
                            <IOLabel :text="$t('Custom ID')" />
                            <span class="io-label-wrapper__counter">{{ uniqueNumberCounter }}</span>
                        </div>

                        <IOInput
                            :maxlength="uniqueNumberMaxCharacters"
                            :error-message="$t('ID already in use')"
                            :valid="uniqueNumberIsValid"
                            v-model="invoice.invoice_unique_number"
                        />
                    </div>
                </div>
                <div class="io-form__item io-form__item-category">
                    <IOLabel :text="$t('Category')" :is-required="true" />

                    <IODropdown
                        :preselected-value="selectedCategory"
                        :multiselect-settings="multiselectSettings"
                        :multiselect-options="categories"
                        @selected="onCategorySelect"
                    />
                </div>
                <div v-if="isOwnerRep" class="io-form__item io-form__item-paid-by">
                    <IOLabel :text="$t('Paid by')" :is-required="true" />
                    <div
                        v-for="company in invoicePaidByCompanies"
                        :key="`company-${company.id}`"
                        class="io-radio-wrapper"
                        @click="selectCompany(company)"
                    >
                        <Radio
                            :radio-value="company.value"
                            :inputName="company.value"
                            v-model="selectedCompany"
                        />
                        <Avatar
                            size="sm"
                            type="company"
                            :employeeMongoId="company.mongo_id"
                            :employeeFullName="company.name"
                            :showFullName="true"
                        />
                        <StatusPill :variant="PillVariant.BLUE_LIGHT" :text="company.type"/>
                    </div>
                </div>
                <div class="io-form__item io-form__item-date">
                    <div>
                        <IOLabel :text="$t('Invoice Date')" :is-required="true" />
                        <FieldDateTime :options="datepickerOptions" :showIcon="true" v-model="invoice.invoice_date"/>
                    </div>
                    <div>
                        <IOLabel :text="$t('Due Date')" :is-required="true" />
                        <FieldDateTime :options="{ ...datepickerOptions, placeholder: $t('Select Date') }" :showIcon="true" v-model="invoice.end_date"/>
                    </div>
                </div>
                <div class="io-form__item io-form__item-description">
                    <IOLabel :text="$t('Description')" />
                    <IOTextarea :autoHeight="true" v-model="invoice.description" />
                </div>
            </div>
        </template>
        <template #rightSide>
            <section>
                <IOLabel :text="$t('Custom ID')" />
                <div class="io-value">{{ $t('Your company\'s specific invoice ID, such as your accounting invoice number or any other referenced invoice number.') }}</div>
            </section>
            <section>
                <IOLabel :text="$t('Invoice Date')" />
                <div class="io-value">{{ $t('The date the invoice is being submitted.') }}</div>
            </section>
            <section>
                <IOLabel :text="$t('Due Date')" />
                <div class="io-value">{{ $t('The date the invoice is paid due.') }}</div>
            </section>
        </template>
    </ContentTwoSidesTemplate>
</template>

<script lang="ts" src="./EditNoNContractedInvoiceDetails.ts"></script>
<style lang="scss" src="./EditNoNContractedInvoiceDetails.scss" scoped></style>
