export function SET_FIRST_DRAFT_PROVIDER (state, value) {
    state.firstDraftProvider = value
}

export function SET_PROCEED_MODE (state, value) {
    state.proceedMode = value
}

export function SET_LETTER_OF_INTENT_FORMAT (state, value) {
    state.letterOfIntentFormat = value
}

export function SET_DESCRIPTION (state, value) {
    state.description = value
}

export function SET_DOCUMENT (state, value) {
    state.document = value
}

export function SET_CLIENT_APPROVED_BY (state, value) {
    state.clientApprovedBy = value
}

export function SET_IS_SIGNED (state, value) {
    state.isSigned = value
}

export function SET_CLIENT_APPROVED_AT (state, value) {
    state.clientApprovedAt = value
}

export function SET_CLIENT_APPROVED_NOTE (state, value) {
    state.clientApprovedNote = value
}

export function SET_CONFIRM_TEXT (state, value) {
    state.confirmText = value
}

export function SET_SHOW_LOADER (state, value) {
    state.showLoader = value
}

export function SET_SEND_VIA_MAIL (state, value) {
    state.sendViaMail = value
}

export function SET_VENDOR_RECIPIENTS (state, value) {
    state.vendorRecipients = value
}

export function SET_EMAIL_BODY (state, value) {
    state.emailBody = value
}

export function SET_SELECTED_TEMPLATE (state, value) {
    state.selectedTemplate = value
}

export function SET_IS_OFF_SYSTEM (state, value) {
    state.isOffSystem = value
}

export function SET_LETTER_ID (state, value) {
    state.letterId = value
}

export function SET_TEMPLATE_ID (state, value) {
    state.templateId = value
}

export function SET_REVISE_MODE (state, value) {
    state.reviseMode = value
}

export function SET_VIEW_ONLY_MODE (state, value) {
    state.viewOnlyMode = value
}

export function CLEAR_LETTER_OF_INTENT (state) {
    state.firstDraftProvider = null
    state.proceedMode = null
    state.letterOfIntentFormat = null
    state.document = null
    state.isSigned = false
    state.selectedTemplate = null
    state.description = ''

    state.clientApprovedBy = ''
    state.clientApprovedAt = null
    state.clientApprovedNote = ''
    state.confirmText = ''

    state.sendViaMail = false
    state.vendorRecipients = []
    state.emailBody = ''

    state.letterId = null
    state.templateId = null

    state.reviseMode = false
    state.viewOnlyMode = false
}
