<template>
    <div class="io-single-contact">
        <div class="io-single-contact__item_left">
            <div class="io-single-contact__contact-info">
                <Avatar
                    type="person"
                    size="sm"
                    :employeeMongoId="contact.avatar ?? ''"
                    :employeeFullName="`${ contact.firstname } ${ contact.lastname }`"
                    :checkMark="!isContactOffSystem"
                    :bgColor="isContactOffSystem ? '#E2E4EA' : undefined "
                    v-tooltip.bottom.center="{ content: isContactOffSystem ? 'Off-system' : 'On-system' }"
                />

                <div class="io-single-contact__contact-details">
                    <div class="io-single-contact__contact-details_top">
                        <div class="io-single-contact__contact-details_identification">
                            <div
                                v-tooltip.bottom.center="{ content: contact.firstname + ' ' + contact.lastname }"
                                class="io-single-contact__contact-details_person-name" data-testid="contact-name"
                            >
                                {{ contact.firstname }} {{ contact.lastname }}
                            </div>
                            <div class="io-single-contact__contact-details_identification-separator">•</div>
                            <div
                                v-if="contact.workspace?.name"
                                v-tooltip.bottom.center="{ content: contact.workspace.name }"
                                class="io-single-contact__contact-details_company-name" data-testid="contact-company-name"
                            >
                                {{ contact.workspace.name || '-' }}
                            </div>
                            <div class="io-single-contact__contact-details_pill">
                                <StatusPill
                                    :text="appTypeText"
                                    :variant="workspaceClass"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="io-single-contact__contact-details_bottom">
                        <div
                            class="io-single-contact__contact-details_email"
                            data-testid="contact-email"
                            v-tooltip.bottom.center="{ content: contact.email }"
                        >
                            {{ contact.email }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="isActionsVisible"
            class="io-single-contact__item_right"
            data-testid="invitation-controls"
            v-tooltip="{
                content: $t(actionsTooltipText),
                visible: isActionsDisabled,
                placement: 'bottom-center'
            }"
        >
            <template v-if="isContactNotSelected">
                <IOButton
                    v-if="!disableBookmarking && !disableMultiple"
                    variant="link"
                    icon="icon-bookmark"
                    size="small"
                    class="io-single-contact__button_link"
                    :class="{'disabled': !contact.matches_all_filters}"
                    :disabled="isActionsDisabled"
                    @click="insertContactToBookmarked()"
                >
                    {{ $t('Bookmark') }}
                </IOButton>

                <IOButton
                    v-if="!disableInviting && !disableMultiple"
                    variant="secondary"
                    size="small"
                    class="io-single-contact__button"
                    :class="{'disabled': !contact.matches_all_filters}"
                    icon="icon-send-2"
                    :disabled="isActionsDisabled"
                    @click="insertContactToInvited()"
                >
                    {{ $t('Invite') }}
                </IOButton>

                <IOButton
                    v-if="!disableRemoving"
                    variant="secondary"
                    size="small"
                    class="io-single-contact__button--fit"
                    :class="{'disabled': disableMultiple}"
                    icon="icon-user-minus"
                    :disabled="disableMultiple"
                    @click="insertContactToRemoved()"
                >
                    {{ $t('Select for Removal') }}
                </IOButton>

                <IOButton
                    v-if="!disableReplacing"
                    variant="secondary"
                    size="small"
                    class="io-single-contact__button--fit"
                    :class="{'disabled': disableMultiple}"
                    icon="icon-refresh-ccw"
                    :disabled="disableMultiple"
                    @click="insertContactToReplaced()"
                >
                    {{ $t('Select for Replacement') }}
                </IOButton>
            </template>
            <template v-else>
                <span class="io-icon-title text-base-px" v-if="isContactInvited"><i class='icon-button icon-send-3 io-accent io-rotate-neg-45'></i> {{ $t('Invited') }}</span>
                <span class="io-icon-title text-base-px" v-if="isContactBookmarked"><i class='icon-button icon-bookmark-2 io-accent'></i> {{ $t('Bookmarked') }}</span>
                <span class="io-icon-title text-sm-px" v-if="isContactRemoved"><i class='icon-button icon-user-minus-2 text-error'></i> {{ $t('Select for Removal') }}</span>
                <span class="io-icon-title text-sm-px" v-if="isContactReplaced"><i class='icon-button icon-refresh-ccw text-info'></i> {{ $t('Select for Replacement') }}</span>
            </template>
        </div>

        <div v-else>
            <div class="io-single-contact__item_right">
                <IOButton
                    v-if="isContactRemovable && isInvitationRemovable"
                    variant="small"
                    icon="icon-cross"
                    size="small"
                    class="io-single-contact__icon-clear"
                    @click="removeContactFromSelected()"
                >
                </IOButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./SingleContact.ts"></script>
<style src="./SingleContact.scss" lang="scss" scoped></style>
