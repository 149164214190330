import InfoCard from './info-card/info-card.vue'
import TaskStatistics from './task-statistics/task-statistics.vue'
import ProductivityRanking from './productivity-ranking/productivity-ranking.vue'
import ProjectsList from './projects-list/projects-list.vue'
import Tasks from './tasks/tasks.vue'
import projectWizardMixin from '@/modules/dashboard-statistics/projectWizard.js'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import InviteModal from '@/components/invite-modal/InviteModal.vue'
import IOBanner from '@/components/atoms/IOBanner/IOBanner.vue'
import dashboardClient from '@/api/dashboard/dashboardClient'
import { get, isString } from 'lodash'
import delightedSurveyMixin from '@/mixins/delightedSurveyMixin'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
import PopupNewProposal from '@/components/popup-new-proposal/popup-new-proposal.vue'

export default {
    name: 'dashboard',
    components: {
        InfoCard,
        TaskStatistics,
        ProductivityRanking,
        ProjectsList,
        Tasks,
        InviteModal,
        IOBanner,
        PopupNewProposal
    },
    mixins: [
        isLoggedMixin,
        projectWizardMixin,
        delightedSurveyMixin,
        featureFlagsMixin,
    ],
    props: {
        startProjectWizard: {
            type: Boolean,
            required: false
        }
    },
    data () {
        return {
            data: null,
            localStorageConfig: null,
            updatedPasswordExpirationStatus: false,
            showInviteModal: false,
            showPopupNewProposal: false,
        }
    },
    async created () {
        this.loadLocalStorageConfig()
        await this.fetchData()
    },
    computed: {
        cantStartProjectMessage () {
            return this.$tc(
                '{accountType} Account Type does not have permission to create Projects. Please contact your Administrator.',
                { accountType: this.authData.account_type }
            )
        },
        canStartProject () {
            return this.getAuthAccess.allow_project_creation
        },
        authData () {
            return this.getAuthData
        },
        resetPasswordText () {
            return this.$tc(
                'Your password is going to expire today | Your password is going to expire within {days} day | Your password is going to expire within {days} days',
                this.passwordExpirationDays,
                { days: this.passwordExpirationDays }
            )
        },

        resetPasswordButtonText () {
            return this.$t('Reset Password')
        },

        passwordExpirationStatus () {
            return null !== this.authData.password_expire_days && this.authData.user_service_account === 'no'
        },

        passwordExpirationDays () {
            return Number(this.authData.password_expire_days)
        },

        productivityRankingData () {
            return get(this.data, 'productivity_rating', [])
        },
        tasksData () {
            return get(this.data, 'tasks_list', [])
        },
        projectsData () {
            return get(this.data, 'projects_list', [])
        },
        projectIds () {
            if (this.projectsData.list && this.projectsData.list.length) {
                return this.projectsData.list
                    .filter(project => false === isNaN(project.id))
                    .map(project => project.id)
            }

            return []
        },
        taskStatisticsData () {
            return get(this.data, 'tasks_stats', [])
        },
        scheduleData () {
            return get(this.data, 'schedules', [])
        },
        flags () {
            return get(this.data, 'flags', {})
        },
        showProposalButton () {
            return this.isRole(['ROLE_PRINCIPAL', 'ROLE_ADMIN']) && this.authData.app_type !== this.appTypes.TYPE_OWNER
        },
        showNewWizard () {
            return this.isFeatureEnabled(FeatureFlagsConsts.PROJECT_CREATION_NEW_WIZARD, false)
        },
    },
    methods: {
        goToPasswordChange () {
            this.$router.push({ name: 'account-edit', query: { ref: 'secondTabForm'} })
        },
        loadLocalStorageConfig () {
            const localStorageConfig = localStorage.getItem(this.authData.u_mongo)
            if (isString(localStorageConfig)) {
                this.localStorageConfig = JSON.parse(localStorageConfig)
            }
        },
        async fetchData () {
            this.setLoadingBar(true)
            try {
                const params = {
                    projects: this.projectIds
                }

                const { data } = await dashboardClient.getDashboard(params)
                this.data = data
                this.updatedPasswordExpirationStatus = null !== data.password_expiration_status && this.authData.user_service_account === 'no'
            } catch (exception) {
                this.showNotification('error', this.$t('Error occurred during saving data'), exception)
            } finally {
                this.setLoadingBar(false)
            }
        },
        addProposal () {
            this.$refs.modalProposal.openModal()
        },
        onProjectWizardOpen () {
            if (this.getAuthAccess.allow_project_creation) {
                if (this.showNewWizard) {
                    this.$router.push({ name: 'project-add' })
                } else {
                    this.openProjectWizard()
                }
            } else {
                this.showNotification('error', this.$t('Project Creation Denied! Your company\'s preferences currently restrict Project Managers from initiating new projects. Contact your System Administrator to inquire about changing your account type.'))
            }
        },
        togglePopupNewProposal () {
            this.showPopupNewProposal = !this.showPopupNewProposal
        }
    }
}
