export default {
    model: {},
    notes: [],
    addresses: [],
    industries: [],
    form: {
        company_types: [],
        payment_types: [],
        divisions: [],
    }
}
