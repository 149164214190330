<template>
    <div
        v-if="showComponent"
        v-click-outside="hideSearch"
        class="io-design-system io-search-input-container"
        :style="searchInputWidth"
    >
        <template v-if="!visible">
            <div
                class="io-search-input-container__icon-search-wrapper"
                :class="{ 'io-is-action-disabled': openSearchDisabled }"
                @click="showSearch"
            >
                <i
                    v-tooltip="{ content: $t(tooltipText), delay: { show: 2000, hide: 500 } }"
                    class="icon-search"
                />
            </div>
        </template>

        <template v-else>
            <input
                ref="searchInputNode"
                type="text"
                class="io-search-input-container__input"
                :disabled="searchDisabled"
                v-model.trim="searchKeyword"
                @input="searchChanged"
            />

            <i
                v-if="searchKeyword.length === 0"
                class="icon-search io-search-input-container__icon-search"
                @click="hideSearch"
            />

            <i
                v-show="searchKeyword.length > 0"
                class="icon-cross io-search-input-container__icon-clear"
                @click="clearInput"
            />
        </template>
    </div>
</template>

<script lang="ts" src="./SearchInput.ts"></script>
<style lang="scss" src="./SearchInput.scss" scoped></style>
