<template>
    <div class="io-navbar-tile" v-click-outside="closeList">

        <transition name="mask">
            <div class="io-navbar-tile__mask" v-if="showList" @click="toggleShowList"></div>
        </transition>

        <div class="io-navbar-tile__content" @click="toggleShowList">
            <div class="io-navbar-tile__icon"><i class="far fa-envelope-open-text"></i></div>
            <div class="io-navbar-tile__dot" v-if="newChatEvent">{{ items.length }}</div>
        </div>

        <transition name="notifications-menu">
            <div class="io-notifications-menu" v-if="showList" :class="{'io-empty': !items.length}">
                <div class="io-notifications-menu__header">
                    <div class="io-notifications-menu__title">{{ $t('Messages') }}</div>
                    <div class="io-notifications-menu__indicator">{{ items.length }}</div>
                    <div class="io-notifications-menu__mark-read" @click="markAllAsRead('chat')">
                        {{ $t('Mark all as read') }}
                    </div>
                </div>

                <template v-if="items && items.length">
                    <div class="io-notifications-menu__content">

                        <template v-for="item in items" :key="item._id">
                            <DiscussionItem
                                :item="item"
                                @closeList="closeList"
                            />
                        </template>

                    </div>

                </template>

                <template v-else>
                    <div class="io-messages-placeholder">
                        <div class="io-messages-placeholder__illustration">
                            <svg xmlns="http://www.w3.org/2000/svg" width="164" height="155" viewBox="0 0 174 165" class="io-messages">
                                <g fill="none">
                                    <path fill="#D7DCEA" d="M87 81L106 81 106 165 87 165z"/>
                                    <path fill="#000" d="M87 81L106 81 87 100z" opacity=".2"/>
                                    <path fill="var(--accent)" d="M0 81c0-4.97 4.03-9 9-9h23V36.5C32 21.312 44.312 9 59.5 9H109v26h2V9h53c5.523 0 10 4.477 10 10v62H0z"/>
                                    <path fill="#000" d="M130 16L118 35 118 16z" opacity=".2"/>
                                    <path fill="#000" d="M32 72h55V36.5C87 21.312 74.688 9 59.5 9S32 21.312 32 36.5V72z" opacity=".3"/>
                                    <path fill="#000" d="M59.5 9C74.688 9 87 21.312 87 36.5V72h-5V36.5c0-14.345-10.984-26.125-25-27.388.824-.074 1.657-.112 2.5-.112z" opacity=".2"/>
                                    <path fill="var(--nav-illustration-accent)" d="M130 0L111 0 111 35 118 35 118 16 130.118 16.001z"/>
                                    <path fill="#FFF" d="M86.958 72L87 81H0c0-4.97 4.03-9 9-9h77.958z" opacity=".1"/>
                                </g>
                            </svg>
                        </div>
                        <div class="io-messages-placeholder__content">
                            <div class="io-messages-placeholder__title">{{ $t('No Messages') }}</div>
                            <div class="io-messages-placeholder__caption">
                                {{ $t('Hey! Looks like you are up-to-date.') }} <br> {{ $t('Good job! Keep rolling!') }}
                            </div>
                        </div>
                    </div>
                </template>

            </div>
        </transition>

    </div>
</template>

<script src="./discussions.js"></script>
