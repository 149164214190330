import { defineComponent } from 'vue'
import { cloneDeep } from 'lodash'
import { ScheduleTicketInterface } from '@/io-modules/lists-templates/schedule-templates/interfaces/ScheduleTicketInterface'
import { TranslateResult } from 'vue-i18n'
import { ScheduleTicketTypeEnum } from '@/io-modules/lists-templates/schedule-templates/enums/ScheduleTicketTypeEnum.ts'

export default defineComponent({
    name: 'ScheduleTicket',
    props: {
        scheduleTicket: { type: Object as () => ScheduleTicketInterface, required: true },
        canEdit: { type: Boolean, required: false },
    },
    emits: [
        'update-schedule-ticket',
        'reject-changes',
        'delete-schedule-ticket',
        'create-schedule-ticket'
    ],
    data () {
        return {
            editMode: false,
            clonedScheduleTicket: null as null | ScheduleTicketInterface,
        }
    },
    computed: {
        isValid (): boolean {
            return this.clonedScheduleTicket?.name.length > 0
        },
        isActivity (): boolean {
            return this.scheduleTicket.type === ScheduleTicketTypeEnum.TASK
        },
        placeholder (): TranslateResult {
            return this.$t(`Enter the ${ this.isActivity ? 'Activity' : 'Milestone' } Name and press “Enter”`)
        },
        containerClasses (): (string | Record<string, boolean>)[] {
            return [
                'io-schedule-ticket',
                `io-schedule-ticket-${ this.scheduleTicket.id }`,
                { 'io-is-form': this.editMode },
                { 'io-is-milestone': !this.isActivity },
            ]
        }
    },
    watch: {
        scheduleTicket: {
           handler (): void {
               this.resetScheduleTicket()
           },
            deep: true
        }
    },
    mounted () {
        this.resetScheduleTicket()
    },
    methods: {
        async onClickEdit (): Promise<void> {
            this.resetScheduleTicket()
            this.editMode = true

            await this.focusOnInput()
        },
        onClickDelete (): void {
            this.$emit('delete-schedule-ticket', this.scheduleTicket)
        },
        onClickSave (enterKey: boolean): void {
            if (!this.clonedScheduleTicket.name.length) {
                return
            }

            this.editMode = false

            if (this.scheduleTicket.is_new) {
                this.$emit('create-schedule-ticket', { scheduleTicket: this.clonedScheduleTicket, enterKey })
            } else {
                this.$emit('update-schedule-ticket', this.clonedScheduleTicket)
            }
        },
        onClickReject (): void {
            this.editMode = false
            this.$emit('reject-changes', this.clonedScheduleTicket)
            this.resetScheduleTicket()
        },
        resetScheduleTicket (): void {
            this.clonedScheduleTicket = cloneDeep(this.scheduleTicket)

            if (this.scheduleTicket.is_new) {
                this.editMode = true
            }
        },
        async focusOnInput (): Promise<void> {
            await this.$nextTick(() => {
                const scheduleTicketElement = this.$refs['schedule-ticket'] as HTMLElement

                scheduleTicketElement.querySelector('input').focus()
            })
        },
        onKeyupEnter (): void {
            this.onClickSave(true)
        }
    }
})
