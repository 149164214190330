<template>
    <PageHeader :title="$t('Funding')" :routeBack="routeBack">
        <template #actions>
            <SearchInput
                v-if="activeTab === menuItemsEnum.APPLIED_FUNDS"
                :placeholder="$t('Search...')"
                :loader="false"
                @searchChanged="onSearchChange"
            />

            <ActionDropdown :icon-tooltip="$t('Actions')">
                <template #default>
                    <span class="io-project-funding__export" @click="toggleExportModal(true, FundExportSortTypes.PACKAGE)">
                        {{ $t('Export Details to PDF Sorted by Package Number') }}
                    </span>
                    <span class="io-project-funding__export" @click="toggleExportModal(true, FundExportSortTypes.VENDOR)">
                        {{ $t('Export Details to PDF Sorted by Vendor') }}
                    </span>
                </template>
            </ActionDropdown>

            <IOButton
                icon="icon-plus"
                @click="addFund()"
            >
                {{ $t('Add Fund') }}
            </IOButton>

        </template>
    </PageHeader>

    <div class="io-project-funding__menu">
        <div class="io-project-funding__tabs io-section-tabs">
            <template v-for="tab in menuItems" :key="tab.name">
                <div
                    class="io-single-tab"
                    :class="{'io-is-active': activeTab === tab.number}"
                    @click.prevent="changeActiveTab(tab.number)"
                >
                    {{ tab.name }}
                </div>
            </template>
        </div>
    </div>

    <div class="io-project-funding">
        <template v-if="activeTab === menuItemsEnum.APPLIED_FUNDS">
            <ProjectFundingSummary v-if="projectFundingSummary" :summary="projectFundingSummary"/>
            <ProjectFundingFundsList :loading="loading" :project-funds="fundsList" @addFund="addFund()"/>
        </template>
        <ProjectFundingRules v-if="activeTab === menuItemsEnum.FUNDING_RULES"/>
        <div v-if="activeTab === menuItemsEnum.TIMELINE" class="io-project-funding__timeline">
            <Timeline :logs="timelineHistory"/>
        </div>
    </div>

    <SelectFundTypeModal v-if="addFundModal" @closeModal="addFund()"/>
    <FundsExportTableModal v-if="exportModal.open" :sort-type="exportModal.sortType" :loading="loading" :project-funds="fundsList" @close="toggleExportModal(false)"/>
</template>

<script lang="ts" src="./ProjectFundingDetails.ts"></script>
<style lang="scss" src="./ProjectFundingDetails.scss" scoped></style>
