import fineUploader from 'fine-uploader'
import { mapGetters, mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    props: ['model', 'addToTemporary', 'arrayId', 'modelType', 'filesSection', 'subSection', 'forceShared', 'modelId', 'validationOptions', 'inDocumentsUpload', 'projectLocalId', 'defaultShare', 'threadId', 'isUploadingIntoSharedFolder'],
    data () {
        return {
            progressCount: 0,
            loader: false,
            instance: {},
            isProjectView: false,
            countFiles: 0,
            folderCreated: false
        }
    },
    mounted () {
        if (this.$route.name === 'project-documents-list') {
            this.isProjectView = true
        }

        if (document.getElementById(this.setElementId)) {
            this.init()
        }
    },
    computed: {
        ...mapGetters(
            'appStore', ['getAuthData']
        ),
        setElementId () {
            return 'fileUpload-' + this.arrayId + '-' + this.filesSection + '-' + this.subSection
        },
        folderFlags () {
            return this.$store.getters['filesComponent/getFolderFlags']
        },
        templateName () {
            return 'qq-template-base-' + this.generateUuid()
        }
    },
    methods: {
        init () {
            let params = {
                section: this.filesSection,
                section_uuid: this.subSection,
                can_be_shared: this.forceShared || this.defaultShare ? 1 : 0,
                model_type: this.modelType,
                in_documents_upload: this.inDocumentsUpload || this.isProjectView,
                folder_id: this.folderFlags && this.folderFlags._id ? this.folderFlags._id : null
            }
            if (this.threadId) {
                params.thread_id = this.threadId
            }
            this.instance = new fineUploader.FineUploader({
                element: document.getElementById(this.setElementId),
                multiple: true,
                template: this.templateName,
                request: {
                    endpoint: this.getEndpoint(),
                    filenameParam: 'fileName',
                    inputName: 'file',
                    params: params
                },
                cors: {
                    expected: true,
                    sendCredentials: true,
                },
                thumbnails: {
                    placeholders: {
                        waitingPath: '/js/fine-uploader/placeholders/waiting-generic.png',
                        notAvailablePath: '/js/fine-uploader/placeholders/not_available-generic.png'
                    }
                },
                cors: {
                    expected: true,
                    sendCredentials: true,
                },
                deleteFile: {
                    enabled: true,
                    method: 'POST',
                    endpoint: '/files/uploadremove'
                },
                autoUpload: this.addToTemporary || this.inDocumentsUpload,
                validation: {
                    stopOnFirstInvalidFile: false
                },
                callbacks: {
                    onValidate: (data) => {
                        let extension = data.name.split('.').pop().toLowerCase()
                        if (this.validationOptions.allowedExtensions.length > 0) {
                            if (this.validationOptions.allowedExtensions.includes(extension)) {
                                return true
                            } else {
                                this.showPopupAlert({
                                    title: this.$t('File extension not allowed.'),
                                    caption: `${ this.$t('Accepted extensions: ') } ${ this.validationOptions.acceptFiles }`,
                                    icon: 'icon-cross-circle',
                                    buttons: [
                                        {
                                            text: this.$t('Close'),
                                            class: 'io-btn-light',
                                            action: null,
                                        },
                                    ],
                                })

                                return false
                            }
                        }
                        if (data.size === 0) {
                            this.showPopupAlert({
                                title: this.$t('Empty File'),
                                caption: data.name + ' ' + this.$t('is empty.'),
                                icon: 'icon-alert-triangle',
                                buttons: [
                                    {
                                        text: this.$t('Close'),
                                        class: 'io-btn-light',
                                        action: null,
                                    },
                                ],
                            })

                            return false
                        }
                        return true
                    },
                    onValidateBatch: (data) => {
                        const promise = new fineUploader.Promise()
                        this.validateUploadingIntoSharedFolder(promise)
                        return promise
                    },
                    onError: (id, name, errorReason) => {
                        this.loader = false
                        // @fixme find out why xhr.status always return 0
                        if (errorReason.toLocaleLowerCase() === 'unauthorized') {
                            this.showNotification('error', this.$t('Your session has expired. Refresh the page and upload your file again'))
                            return
                        }
                        this.showNotification('error', fineUploader.format('Error on file number {} - {}.  Reason: {}', id, name, errorReason))
                    },
                    onComplete: (id, name, response) => {
                        if (response.code !== 200) {
                            return
                        }



                        // this.response['name'] = name
                        this.$emit('updateDocuments', response)
                        this.showNotification('success', this.$t('Your file has been uploaded successfully.'))
                        this.$store.dispatch('storage/setRecalculateUsedStorage')
                    },
                    onAllComplete: (succeeded, failed) => {
                        this.loader = false
                        this.progressCount = 0
                    },
                    onTotalProgress: (uploadedBytes, totalBytes) => {
                        this.progressCount = ((uploadedBytes / totalBytes) * 100).toFixed(0)
                        if (this.progressCount === '100') {
                            this.showNotification('warning', this.$t('We are processing your files. Please wait...'))
                        }
                    },
                    onUpload: (data) => {
                        this.loader = true
                    },
                    onStatusChange: (id, oldStatus, newStatus) => {
                        if (id > this.countFiles) {
                            this.countFiles = id
                        }
                        if (!this.inDocumentsUpload && !this.addToTemporary) {
                            if (id === 0 && newStatus === 'submitting') {
                                this.createFolder()
                            } else if (this.folderCreated && id === this.countFiles && newStatus === 'submitted') {
                                this.createFolder()
                            }
                        }
                    }
                }
            })
        },
        async createFolder () {
            try {
                const data = await this.$api.post(`create-model-folder/${ this.modelType }/${ this.modelId }`)
                if (data.data.success) {
                    this.folderCreated = true
                    this.sendUpload()
                }
            } catch (e) {
                this.consoleError(e)
                this.showNotification('error', 'Error occurred during loading data.')
            }
        },
        sendUpload () {
            if (this.folderCreated) {
                this.instance.uploadStoredFiles()
            }
        },
        getEndpoint () {
            if (this.addToTemporary) {
                return `${ this.apiUrl }/attach-temporary`
            } else if (!isEmpty(this.folderFlags) && this.folderFlags.is_user_created) {
                return `${ this.apiUrl }/upload-folder-document/${ this.folderFlags._id }`
            } else if (this.modelType && this.modelId) {
                return `${ this.apiUrl }/upload-model-document/${ this.modelType }/${ this.modelId }`
            } else if (this.modelType && this.folderFlags.model_id) {
                return `${ this.apiUrl }/upload-model-document/${ this.modelType }/${ this.folderFlags.model_id }`
            } else if (!isEmpty(this.folderFlags) && this.folderFlags._id) {
                return `${ this.apiUrl }/upload-folder-document/${ this.folderFlags._id }`
            } else {
                return false
            }
        },
        validateUploadingIntoSharedFolder (promise) {
            if (this.isUploadingIntoSharedFolder) {
                this.showPopupAlert({
                    title: this.$t('Add file to a shared folder?'),
                    caption: this.$t('This will share the file with the same individuals who have access to the folder.'),
                    icon: 'fa fa-upload',
                    buttons: [
                        {
                            text: this.$t('Cancel'),
                            class: 'io-btn-light',
                            action: () => {
                                this.confirmedToUploadOnce = false
                                promise.failure()
                            }
                        },
                        {
                            text: this.$t('Yes, Add and Share'),
                            class: 'io-btn-primary',
                            action: () => {
                                this.confirmedToUploadOnce = true
                                promise.success()
                            }
                        }
                    ]
                })
            } else {
                promise.success()
            }
        }
    },
    watch: {
        'folderFlags' () {
            this.$emit('reloadUploader')
        }
    }
}
