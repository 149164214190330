import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'DocumentDownloadModal',
    components: {
        PopupModal,

    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    emits: ['close', 'download'],
    data () {
        return {
            includeMarkups: true
        }
    },
    computed: {
        statusText (): TranslateResult {
            return this.includeMarkups ? this.$t('Yes') : this.$t('No')
        }
    }
})
