import InvoiceRouteName from '../enums/InvoiceRouteName'
import Invoice from '../interfaces/Invoice'
import ApplicationPackage from '../interfaces/ApplicationPackage'
import InvoicesTab from '../enums/InvoicesTab'
import VendorDetails from '../interfaces/VendorDetails'
import InvoiceHeader from '../enums/InvoiceHeader'
import { mapState as mapStateVuex, mapGetters as mapGettersVuex } from 'vuex'
import { mapActions, mapState } from 'pinia'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import { defineComponent } from 'vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import invoicesListsClient from '@/io-modules/invoices/api-clients/invoicesListsClient.ts'
import ListingTotals from '@/io-modules/invoices/interfaces/invoices-list/ListingTotals.ts'

export default defineComponent({
    computed: {
        ...mapState(invoicesStore, [
            'getListItems',
            'getInvoicesListHeaders',
            'showContentLoader',
            'showSmallLoader'
        ]),

        ...mapStateVuex('filtersModal', ['filtersCounter']),

        ...mapStateVuex('project', ['projectObj']),

        ...mapGettersVuex('appStore', ['isOwner', 'isOwnerRep']),

        ...mapStateVuex('modal', {
            showModal: state => state.show,
        }),

        listType (): InvoicesTab {
            return this.$route.meta.listType
        },

        isLoading (): boolean {
            return this.showContentLoader || this.showSmallLoader
        },

        getListHeaders (): HeaderTable[] {
            const list = this.getInvoicesListHeaders(this.listType)
            if (!this.isOwner && !this.isOwnerRep) {
                const index = list.findIndex(item => InvoiceHeader.ApplicationPackageNumber === item.key)
                if (index > -1) {
                    list.splice(index, 1)
                }
            }

            return list
        },

        getList (): Invoice[] | ApplicationPackage[] | VendorDetails[] {
            const isVendorsList = InvoiceRouteName.InvoicesReceivedVendorsList === this.$route.name
            return this.getListItems(this.listType, isVendorsList, this.$route.params.id) || []
        },

        showMainPlaceholder (): boolean {
            return !this.filtersCounter && !this.getList.length && !this.isLoading && this.showModal !== 'modal-filters'
        },

        showNoFilteredDataPlaceholder (): boolean {
            return (this.filtersCounter || this.showModal === 'modal-filters') && !this.getList.length && !this.isLoading
        },

        placeholderVisible (): boolean {
            return this.showMainPlaceholder || this.showNoFilteredDataPlaceholder
        },
    },
    methods: {
        ...mapActions(invoicesStore, ['setContentLoaderValue']),

        async fetchInvoiceTotals (listType: string): Promise<ListingTotals> {
            try {
                const { data } = await invoicesListsClient.getInvoicesListTotals(this.projectObj.project_local_id, listType)
                return data
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },
    },
})
