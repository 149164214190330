<template>
    <div class="io-cont-timeline" :class="{ overflow }">
        <div v-if="history">
            <div class="io-timeline-component-holder">
                <div v-for="(revision, index) in history" :key="index" class="io-timeline">
                    <div class="io-single-row io-arrow-top">
                        <div class="io-icon"/>
                    </div>
                    <div v-for="(record, recordIndex) in revision.rows" :key="`record_${ recordIndex }`" class="io-single-row io-f-row">
                        <div class="io-left">
                            <div class="io-date">{{ parseDate(record.date, 'YYYY-MM-DD') }}</div>
                        </div>
                        <span class="io-center"/>
                        <div class="io-right">
                            <div v-if="record.description" class="io-text">{{ record.description }}</div>
                            <div v-if="record.comment" class="io-text">{{ $t('Comment') }}: {{ record.comment }}</div>
                            <div v-if="record.question" class="io-text">{{ $t('Question') }}: {{ record.question }}</div>
                            <div v-if="record.answer" class="io-text">{{ $t('Answer')}}: {{ record.answer }}</div>
                            <div class="io-author io-f-row">
                                <span
                                    v-if="record.avatar_url"
                                    class="io-img-holder"
                                    :style="'background-image: url(' + record.avatar_url +')'"
                                />
                                <initials
                                    v-else
                                    class="io-img-holder io-f-column"
                                    :bgColor="record.avatar_color"
                                    :bigAvatar="true"
                                    :fullName="record.creator"
                                />
                                <div class="io-name">{{ record.creator }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="io-start-row">
                        <p>{{ revision.name }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-else-if="logs && logs.length" class="io-timeline-component-holder">
            <div class="io-timeline">
                <div class="io-single-row io-arrow-top">
                    <div class="io-icon"/>
                </div>
                <div v-for="(item, itemIndex) in logs" :key="`item_${ itemIndex }`" class="io-single-row io-f-row">
                    <div class="io-left">
                        <div class="io-date">
                            <div>{{ $filters.parseDate(item.created_at) }}</div>
                            <div>
                                {{ $t('at') }} <span class="uppercase">{{ $filters.parseTime(item.created_at) }}</span>
                            </div>
                        </div>
                    </div>
                    <span class="io-center"/>
                    <div class="io-right">
                        <div class="io-text">
                            <div v-if="item.content" v-html="showContent(item.content, item.title)" class="io-text"/>
                            <template v-if="item.note">
                                <div>{{ $t('Note') }}:</div>
                                <p class="whitespace-pre-wrap" v-html="item.note.replace('\\n', '\n')"/>
                            </template>
                        </div>
                        <div class="io-author io-f-row">
                            <Avatar
                                v-if="item?.employee_mongo_id"
                                class="io-author__logo"
                                :employeeMongoId="item.employee_mongo_id"
                                :showFullName="false"
                                :employeeFullName="showUser(item.user)"
                            />
                            <div class="io-name">

                                <template v-if="useDisplayVariantWithAvatar">
                                    <div class="io-name__row">
                                        <p>{{ $t('by') }} {{ showUser(item.user) }} ({{ item.company_name }})</p>
                                    </div>
                                    <div class="io-avatar__row">
                                        <Avatar
                                            :employeeMongoId="item.user.user_mongo"
                                            :employeeFullName="item.user.user"
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="io-name__row">
                                        <p>{{ item.user }}</p>
                                        <div
                                            v-if="item.company_type"
                                            class="io-status-pill"
                                            :class="getPillColor(item.company_type)"
                                        >
                                            {{ appTypeName(item.company_type) }}
                                        </div>
                                    </div>
                                    <div class="io-role">
                                        <template v-if="item._company_name">@ {{ item._company_name }}</template>
                                        <template v-else-if="item.company_name">{{ item.company_name }}</template>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="io-start-row">
                    <p>{{ $t('Start') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./timeline.ts"></script>
<style lang="scss" src="./timeline.scss" scoped></style>
