import { computed } from 'vue'

import i18n from '@/base/i18n'

import { Status } from '@/io-modules/punch-lists/enums/PunchItem'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'

export type PunchItemStatusInfo = { [key in Status]: { title: string, pillVariant: PillVariant } }

const { t } = i18n.global

export default () => computed((): PunchItemStatusInfo => ({
    [Status.NOT_STARTED]: {
        title: t('Not Started'),
        pillVariant: PillVariant.RED_LIGHT,
    },
    [Status.IN_PROGRESS]: {
        title: t('In Progress'),
        pillVariant: PillVariant.BLUE_LIGHT,
    },
    [Status.UNDER_REVIEW]: {
        title: t('Under Review'),
        pillVariant: PillVariant.YELLOW_LIGHT,
    },
    [Status.COMPLETED]: {
        title: t('Completed'),
        pillVariant: PillVariant.GREEN_LIGHT,
    },
    [Status.ARCHIVED]: {
        title: t('Archived'),
        pillVariant: PillVariant.GRAY_LIGHT,
    },
}))
