<template>
    <PopupModal full-screen width="480" @close="$emit('close')">
        <template #header>
            <div class="io-popup-modal__icon"><i class="icon-edit-2"/></div>
            <div class="io-popup-modal__title">{{ $t('Rename') }}</div>
            <div class="io-popup-modal__close" @click="$emit('close')"><i class="icon-cross"/></div>
        </template>

        <IOLabel is-required :text="$t('Name')"/>

        <IOInput
            autofocus
            max-length="50"
            :error-message="$t('The field is required', { field: $t('Enter New Name') })"
            :valid="!v$.input.$error"
            v-model="v$.input.$model"
            @blur="v$.input.$touch()"
            @keyup.enter="$emit('save', fileNameWithExtension)"
        />

        <template #footer>
            <IOButton variant="secondary" @click="$emit('close')">{{ $t('Cancel') }}</IOButton>

            <IOButton :disabled="!input" @click="$emit('save', fileNameWithExtension)">{{ $t('Confirm') }}</IOButton>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./DocumentRenameModal.ts"></script>
