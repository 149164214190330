import ListFilters from '@/io-modules/invoices/interfaces/invoices-list/ListFilters'

const invoicesFiltersDefaultValues: ListFilters = {
    type: [],
    invoice_date: null,
    status: [],
    lien_waiver_status: [],
    ball_in_court: [],
    payment_status: [],
    billing_date: [],
}

export default invoicesFiltersDefaultValues