import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        data: { type: Array as () => any[], default: null },
        additionalData: { type: Object as () => any },
        maxLength: { type: Number, default: 2 },
        wrap: { type: Boolean, default: false },
        isTagObject: { type: Boolean, default: false },
        maxTagLength: { type: Number, default: 10 }
    },
    computed: {
        remainingTagsTooltipText (): string {
            return this.remainingTags.join(', ')
        },
        remainingTags (): string[] {
            return this.data.filter(
                (item: any) => !this.parsedDataShortened.includes(item)
            )
        },
        parsedDataShortened (): Array<any> {
            return this.data.length > this.maxLength
                ? this.data.slice(0, this.maxLength)
                : this.data
        }
    },
    methods: {
        tagText (tag: string): string {
            if (this.isTagObject) {
                tag = tag[this.additionalData.header]
            }

            return tag?.length > this.maxTagLength
                ? `${tag.substring(0, this.maxTagLength)}...`
                : tag
        }
    }
})
