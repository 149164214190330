import { defineComponent, PropType } from 'vue'
import Card from '@/components/card/Card.vue'
import Table from '@/components/table/Table.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import { PlaceholderButton } from '@/io-modules/bid-requests/interfaces'
import ProjectFundingFund from '../../interfaces/ProjectFundingFund'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import { PillVariant } from '@/components/atoms/status-pill/enums/StatusPillEnums'
import modalOnDelete from '@/io-modules/project-funding/helpers/modalOnDelete'
import projectFundingClient from '@/io-modules/project-funding/api-clients/projectFundingClient'
import { FundTypes, HeaderType } from '@/io-modules/project-funding/enums/ProjectFundingEnums'
import Placeholder from '@/components/placeholder/Placeholder.vue'
import FundDetailsModal from '@/io-modules/project-funding/components/modals/fund-details/FundDetailsModal.vue'
import { mapActions as piniaMapActions } from 'pinia'
import { projectFundingStore } from '@/io-modules/project-funding/stores/projectFundingStore'
import { mapGetters, mapState } from 'vuex'
import setTableHeaders from '@/io-modules/project-funding/helpers/setTableHeaders'

export default defineComponent({
    name: 'ProjectFundingFundsList',
    components: { FundDetailsModal, Placeholder, StatusPill, Avatar, Table, Card },
    props: {
        projectFunds: {
            required: true,
            type: Array as PropType<ProjectFundingFund[]>,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [modalOnDelete],
    emits: ['addFund'],
    data () {
        return {
            pillVariant: PillVariant,
            fundTypes: FundTypes,
            showFundDetails: false,
            idOpenedFund: null,
            HeaderType
        }
    },
    computed: {
        ...mapState('project', {
            projectObj: state => state.projectObj,
        }),
        ...mapGetters('appStore', [
            'isOwner',
        ]),
        buttonsCta (): Array<PlaceholderButton> {
            return [
                {
                    name: this.$tc('Add Fund'),
                    customAction: 'addFund',
                },
            ]
        },
    },
    methods: {
        setTableHeaders,
        ...piniaMapActions(projectFundingStore, [
            'setDataUpdation',
        ]),
        addFund (): void {
            this.$emit('addFund')
        },
        removeFundModals (fundType: string, fundId: string): void {
            if (fundType === this.fundTypes.CAPITAL) {
                this.showModalOnDelete(async () => await this.removeFundQuery(fundType, fundId))
            } else {
                this.showModalOnDeleteProjectSpecificFund(async () => await this.removeFundQuery(fundType, fundId))
            }
        },
        async removeFundQuery (fundType: string, fundId: string): Promise<void> {
            if (fundType === this.fundTypes.CAPITAL) {
                await projectFundingClient.removeCapFundFromProject(this.projectObj.project_local_id, fundId)
            } else {
                await projectFundingClient.removeFund(fundId)
            }

            this.setDataUpdation(true)
        },
        toggleFundDetails (fundId: string): void {
            this.idOpenedFund = fundId
            this.showFundDetails = !this.showFundDetails
        }
    }
})
