import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'
import defaultState from './state'
import { cloneDeep } from 'lodash'

export default {
    namespaced: true,
    namespace: 'project/',
    state () {
        return cloneDeep(defaultState)
    },
    actions,
    getters,
    mutations
}
