import { ApiClient } from '@/api/api'

let client = ApiClient

export default {
    getData (params = null) {
        return client.realJson(`contract-wizard/get-data`, params)
    },
    postContract (data) {
        return client.realJson('project-wizard/create', data)
    },
    postCheckId (data) {
        return client.post('bidsend/checkidfullavailability', data)
    },
    loadCompanyContacts (id) {
        return client.get('/contact/getbycompany/' + id)
    },
    loadCompanyContactsWithEmails (id) {
        return client.get(`/contacts/company/${ id }`)
    },
    loadClientsInternal () {
        return client.get('/resource/get-clients-internal')
    },
    getProposal (id) {
        return client.get('/project-wizard/get-proposal/' + id)
    },
    postCompany (data) {
        return client.realJson('/company/save/v2', data)
    },
    initialData () {
        return client.get('/project/create')
    },
    getDuplicateFields (duplicateId) {
        return client.get(`/project/info/${ duplicateId }`)
    },
    addNewProject (data) {
        return client.post('/project/create', data)
    },
    addNewProjectFromExternalPending (externalPendingID, data) {
        return client.patch(`/project/${ externalPendingID }`, data)
    },
}
