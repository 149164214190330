const documentMixin = {
    data () {
        return {
            editMode: false,
            cancelEditModeModalShown: false
        }
    },
    methods: {
        async toggleFavorite (value, id, type) {
            this.setLoadingBar(true)

            try {
                await this.$api.post(`document/${ id }/${ type }/flag/${ value }`)

                this.showNotification('success', this.$t('Success'))

                return true
            } catch (e) {
                this.showNotification('error', e.response.data.message)
            } finally {
                this.setLoadingBar(false)
            }
        },
        closeEditMode () {
            if (this.editMode) {
                this.editMode = false
            }
        },
        cancelEditing (fileNameChanged) {
            this.cancelEditModeModalShown = true

            if (fileNameChanged) {
                this.closeEditMode()

                return
            }

            this.showCancelEditingPopup()
        },
        showCancelEditingPopup () {
            this.showPopupAlert({
                title: 'There are unsaved changes. Are you sure you want to leave?',
                icon: 'far fa-exclamation-triangle',
                buttons: [
                    {
                        text: 'Cancel',
                        class: 'io-btn-light',
                        action: () => {
                            setTimeout(() => {
                                this.cancelEditModeModalShown = false
                            }, 100)
                        }
                    },
                    {
                        text: 'Leave without saving',
                        class: 'io-btn-primary',
                        action: () => {
                            this.closeEditMode()

                            this.cancelEditModeModalShown = false
                        }
                    }
                ]
            })
        }
    }
}

export default documentMixin
