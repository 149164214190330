import { HeaderTable } from '@/components/table/HeaderTableInterface'

export default {
    setFixedColumnsClasses (columnsSettings: HeaderTable[]): HeaderTable[] {
        if (!columnsSettings.length) {
            return
        }

        let lastFixedIndex = -1
        columnsSettings.forEach((item, index) => {
            item.extraClasses = item.fixed ? 'io-fixed-column' : ''
            if (item.fixed && item.visible) {
                if (-1 === lastFixedIndex) {
                    lastFixedIndex = index
                } else if (index !== lastFixedIndex) {
                    lastFixedIndex = item.order > columnsSettings[lastFixedIndex].order ? index : lastFixedIndex
                }
            }
        })

        if (columnsSettings[lastFixedIndex]) {
            columnsSettings[lastFixedIndex].extraClasses += ' io-fixed-column--last'
        }
    },

    setupTableColumnsLeft (fixedColumns: HeaderTable[], query: string): void {
        let sumWidth = 0
        const fixedColumnElements = document.querySelectorAll(query || 'th.io-fixed-column')

        fixedColumnElements.forEach((element, index) => {
            if (index > 0) {
                
                const previousElement = fixedColumnElements[index - 1]
                const previousElementWidth = previousElement.getBoundingClientRect().width
                sumWidth += previousElementWidth

                if (fixedColumns[index]?.extraStyles) {
                    fixedColumns[index].extraStyles = fixedColumns[index].extraStyles.split(';').filter(style => !style.includes('left')).join(';')
                    fixedColumns[index].extraStyles += `left: ${ sumWidth }px;`
                } else if (fixedColumns[index]) {
                    fixedColumns[index].extraStyles = `left: ${ sumWidth }px;`
                }
            }
        })
    }
}