import { debounce, capitalize } from 'lodash'
import { defineComponent, PropType } from 'vue'
import { mapState, mapActions } from 'pinia'
import { mapGetters as mapGettersVuex, mapState as mapStateVuex } from 'vuex'
import { inviteModalStore } from '@/components/invite-modal-v3/store/inviteModalStore'
import { InviteModalTabs } from '@/components/invite-modal-v3/enums/InviteModalTabs'
import { Tab } from '@/components/atoms/tabs/TabInterface'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import Tabs from '@/components/atoms/tabs/Tabs.vue'
import InfoPanel from '@/components/invite-modal-v3/components/info-panel/InfoPanel.vue'
import SearchInputNew from '@/components/search-input/SearchInput.vue'
import InitialView from '@/components/invite-modal-v3/views/InitialView.vue'
import {
    Contact,
    SplashScreen,
    InviteModalFilters,
    InviteModalPagination,
    InviteModalDonePayload,
} from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'
import Accordion from '@/components/accordion/Accordion.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import SelectedContacts from '@/components/invite-modal-v3/components/selected-contacts/SelectedContacts.vue'
import AddContactDetails from '@/components/invite-modal-v3/components/add-contact-details/AddContactDetails.vue'
import appTypes from '@/base/appTypes'

export default defineComponent({
    name: 'InviteModalV3',

    components: {
        PopupModal,
        Tabs,
        InfoPanel,
        SearchInputNew,
        Accordion,
        SelectedContacts,
        InitialView,
        AddContactDetails,
        StatusPill,
    },

    props: {
        modalZIndex: { type: Number, required: false, default: 25 },
        inviteModalTitle: { type: String, required: false, default: '' },
        componentKey: { type: String, required: true },
        resourceId: { type: [String, Number], required: false, default: null },
        resourceType: { type: String, required: false, default: null },
        projectGlobalIdProp: { type: String, required: false, default: null },
        filterByProjectGlobalId: { type: Boolean, required: false, default: false },
        role: { type: String, required: false, default: null },
        initialSearchValue: { type: String, required: false, default: '' },
        disableInviting: { type: Boolean, required: false, default: false },
        disableBookmarking: { type: Boolean, required: false, default: false },
        disableRemoving: { type: Boolean, required: false, default: true },
        disableReplacing: { type: Boolean, required: false, default: true },
        disableInviteRemoval: { type: Boolean, required: false, default: false },
        disableBookmarkRemoval: { type: Boolean, required: false, default: false },
        disableActionsOnCurrentWorkspaceContact: { type: Boolean, required: false, default: false },
        employeesOnly: { type: Boolean, required: false, default: false },
        onlyListContactsOnSearch: { type: Boolean, required: false, default: false },
        onlySearchWithValidEmail: { type: Boolean, required: false, default: false },
        categories: {
            type: Array as () => Array<InviteModalTabs>,
            required: false,
            default: () => []
        },
        bookmarkedContactPersons: {
            type: Array as () => Array<Contact>,
            required: false,
            default: () => [],
        },
        invitedContactPersons: {
            type: Array as () => Array<Contact>,
            required: false,
            default: () => [],
        },
        bookmarkedContactPersonIds: {
            type: Array as () => Array<string>,
            required: false,
            default: () => [],
        },
        invitedContactPersonIds: {
            type: Array as () => Array<string>,
            required: false,
            default: () => [],
        },
        singleSelect: { type: Boolean, required: false, default: false },
        withInvitationSurvey: { type: Boolean, required: false, default: true },
        preselectedWorkspaceType: { type: String, required: false, default: null },
        allowedClientsTypes: {
            type: Array as PropType<appTypes[]>,
            default: null
        },
        allowedCompaniesIds: {
            type: Array as PropType<string[]>,
            required: false,
            default: null
        },
        excludedCompaniesIds: {
            type: Array as PropType<string[]>,
            required: false,
            default: null
        },
        // dont allow to click "save" button without selected contacts
        requireSelectionToSave: {
            type: Boolean,
            required: false,
            default: false,
        },
        saveButtonCustomTitle: { type: String, required: false, default: null },
        searchInputCustomLabel: { type: String, required: false, default: null },
        splashScreen: { type: Object as PropType<SplashScreen>, required: false, default: null },
        emptySearchSplashScreen: { type: Object as PropType<SplashScreen>, required: false, default: null },
        forceBookmarkCreatedContacts: { type: Boolean, require: false, default: false },
        removeMode: { type: Boolean, required: false, default: false },
        replaceMode: { type: Boolean, required: false, default: false },
    },

    emits: [
        'setInitialBookmarkedList',
        'setInitialInvitedList',
        'setInviteModalOpened',
        'inviteModalDone',
    ],

    data () {
        return {
            currentTabId: 0,
            currentTabKey: 'all',
            hasModalBeenLoaded: false,
            isInfoPanelExpanded: false,
            subscriptionTypes: {
                'OpenCA': 'Basic',
                'PRO': 'Enterprise',
                'OpenCA+': 'Pro'
            }
        }
    },

    computed: {
        ...mapState(inviteModalStore, {
            assignedResourceId (store) {
                return store.assignedResourceId(this.componentKey)
            },
            assignedResourceType (store) {
                return store.assignedResourceType(this.componentKey)
            },
            assignedRole (store) {
                return store.assignedRole(this.componentKey)
            },
            assignedProjectGlobalId (store) {
                return store.assignedProjectGlobalId(this.componentKey)
            },
            isSearchValidEmail (store) {
                return store.isSearchValidEmail(this.componentKey)
            },
            isSearchDisabled (store) {
                return store.isSearchDisabled(this.componentKey)
            },
            isLoading (store) {
                return store.isLoading(this.componentKey)
            },
            isClosing (store) {
                return store.isClosing(this.componentKey)
            },
            isPaginating (store) {
                return store.isPaginating(this.componentKey)
            },
            isFormVisible (store) {
                return store.isFormVisible(this.componentKey)
            },
            isUpdatingWorkspaceType (store) {
                return store.isUpdatingWorkspaceType(this.componentKey)
            },
            invitedContacts (store) {
                return store.invitedContacts(this.componentKey)
            },
            bookmarkedContacts (store) {
                return store.bookmarkedContacts(this.componentKey)
            },
            removedContacts (store) {
                return store.removedContacts(this.componentKey)
            },
            replacedContacts (store) {
                return store.replacedContacts(this.componentKey)
            },
            addedToInvited (store) {
                return store.addedToInvited(this.componentKey)
            },
            addedToBookmarked (store) {
                return store.addedToBookmarked(this.componentKey)
            },
            removedFromInvited (store) {
                return store.removedFromInvited(this.componentKey)
            },
            removedFromBookmarked (store) {
                return store.removedFromBookmarked(this.componentKey)
            },
            contactsList (store) {
                return store.contactsList(this.componentKey)
            },
            search (store) {
                return store.search(this.componentKey)
            },
            contact (store) {
                return store.contact(this.componentKey)
            },
            isContactFormValid (store) {
                return store.isContactFormValid(this.componentKey)
            },
            isInfoPanelVisible (store) {
                return store.isInfoPanelVisible(this.componentKey)
            },
            isIntentionallyAddingContact (store) {
                return store.isIntentionallyAddingContact(this.componentKey)
            },
            skip (store) {
                return store.skip(this.componentKey)
            },
            take (store) {
                return store.take(this.componentKey)
            },
            lastTaken (store) {
                return store.lastTaken(this.componentKey)
            },
        }),

        ...mapGettersVuex('appStore', ['getAuthData']),

        ...mapStateVuex('project', {
            projectObj: (state) => state.projectObj
        }),

        projectGlobalId (): string {
            return this.projectGlobalIdProp || this.projectObj?.project_global_id
        },

        customTitle (): String {
            if (this.inviteModalTitle) {
                return this.inviteModalTitle
            }

            if (this.isModalEmployeesOnly) {
                return 'Select Employees'
            }

            return 'Select Users'
        },

        isUserEmailFromIngenious (): boolean {
            return this.getAuthData.u_email.includes('@ingenious.build')
        },

        isTrainingEnv (): boolean {
            return window.location.host.split('.')[2] === 'training'
        },

        isModalEmployeesOnly (): boolean {
            return this.employeesOnly ||
                (this.categories.length === 1 && this.categories[0] === InviteModalTabs.EMPLOYEES)
        },

        shouldShowDelightedSurvey (): boolean {
            return this.isProduction && !this.isUserEmailFromIngenious && !this.isTrainingEnv
        },

        validCategories (): Array<InviteModalTabs> {
            return this.categories.filter((value) => Object.values(InviteModalTabs).includes(value))
        },

        isTabsActive (): boolean {
            return !this.isModalEmployeesOnly && this.validCategories.length !== 1
        },

        defaultTab (): number {
            if (this.isModalEmployeesOnly) {
                return this.tabs().findIndex((obj) => obj.key === InviteModalTabs.EMPLOYEES)
            }

            if (this.categories.length) {
                return this.tabs().findIndex((obj) => obj.key === this.validCategories[0])
            }

            return 0
        },

        filters (): InviteModalFilters {
            return {
                search: this.search,
                project_global_id: this.projectGlobalId,
                categories: this.categoryFilters,
                workspace: {
                    ids: this.allowedCompaniesIds,
                    excluded_ids: this.excludedCompaniesIds,
                    app_types: this.allowedClientsTypes,
                },
                include_current_user: 1, // https://ingenious.atlassian.net/browse/INGIO-55586
            }
        },

        pagination (): InviteModalPagination {
            return {
                skip: this.search ? 0 : this.skip,
                take: this.search ? 100 : this.take,
            }
        },

        categoryFilters (): Array<InviteModalTabs> {
            return this.currentTabKey === 'all'
                ? Object.values(InviteModalTabs).filter(value => value !== 'all')
                : [this.currentTabKey]
        },

        invitedCount (): object {
            return {
                variant: this.invitedContacts.length > 0 ? 'blue-light' : 'gray-light',
                text: this.invitedContacts.length || 0
            }
        },

        bookmarkedCount (): object {
            return {
                variant: this.bookmarkedContacts.length > 0 ? 'blue-light' : 'gray-light',
                text: this.bookmarkedContacts.length || 0
            }
        },

        removedCount (): number {
            return {
                variant: 'red-light',
                text: this.removedContacts.length || 0
            }
        },

        replacedCount (): number {
            return {
                variant: 'red-light',
                text: this.replacedContacts.length || 0
            }
        },

        shouldDisableSearch (): boolean {
            return this.isSearchValidEmail && this.isSearchDisabled
        },

        shouldShowAddWithoutActionButton (): boolean {
            return this.isSearchValidEmail
                && this.contactsList.length > 0
                && !this.isModalEmployeesOnly
                && !this.isIntentionallyAddingContact
                && !this.isEmailOnContactList(this.search)
                && !this.isLoading
        },

        shouldPaginate (): boolean {
            return !this.isPaginating && !this.search && this.lastTaken >= this.take
        },

        disableMultiple (): boolean {
            if (this.singleSelect) {
                return this.bookmarkedContacts.length > 0
                    || this.invitedContacts.length > 0
                    || this.removedContacts.length > 0
                    || this.replacedContacts.length > 0
            }

            return false
        },

        disableSaveButton (): boolean {
            if (!this.requireSelectionToSave) {
                return false
            }

            if (this.removeMode) {
                return this.removedContacts.length === 0
            }

            if (this.replaceMode) {
                return this.replacedContacts.length === 0
            }

            if (this.disableBookmarking) {
                return this.invitedContacts.length === 0
            }

            if (this.disableInviting) {
                return this.bookmarkedContacts.length === 0
            }

            if (!this.disableInviting && !this.disableBookmarking) {
                return this.bookmarkedContacts.length === 0
                    && this.invitedContacts.length === 0
            }

            return false
        },

        searchInputLabel (): string {
            if (this.searchInputCustomLabel) {
                return this.searchInputCustomLabel
            }

            if (this.onlySearchWithValidEmail) {
                return 'Email'
            }

            if (this.isModalEmployeesOnly) {
                return 'Email / name'
            }

            return 'Email / name / company'
        },
    },

    async beforeMount (): Promise<void> {
        this.setStateObject(this.componentKey)
        this.initializeDelightedSurvey()
    },

    async created () {
        this.setContactListEventListener()
    },

    mounted () {
        this.setResource(
            this.componentKey,
            this.resourceId,
            this.resourceType,
            this.projectGlobalId,
            this.filterByProjectGlobalId
        )
        this.setRole(this.componentKey, this.role)
        // Set tabs first before fetching records so filters would take effect...
        this.setCurrentTab(this.defaultTab)

        this.setInitialSelectedList()

        this.fetchCountries(this.componentKey)
        this.fetchStates(this.componentKey)
        this.fetchInitialRecords()

        this.hasModalBeenLoaded = true
    },

    watch: {
        isFormVisible (value) {
            if (true === value) {
                this.contact.email = this.search
            }
        },
    },

    methods: {
        ...mapActions(inviteModalStore, [
            'fetchContacts',
            'fetchContactsByIds',
            'fetchContactsData',
            'fetchCountries',
            'fetchStates',
            'fetchAssignedContactsByResource',
            'resetState',
            'setResource',
            'setRole',
            'setSearch',
            'setContactsList',
            'setLoading',
            'setClosing',
            'resetContact',
            'createContact',
            'updateGlobalWorkspaceAppType',
            'pushInvitedContact',
            'pushBookmarkedContact',
            'pushRemovedContact',
            'pushReplacedContact',
            'setInitialInvitedList',
            'setInitialBookmarkedList',
            'setBookmarkedList',
            'setInvitedList',
            'setRemovedList',
            'setReplacedList',
            'setRemovedFromInvitedList',
            'setRemovedFromBookmarkedList',
            'setStateObject',
            'setSearchDisabled',
            'setIntentionallyAddingContact',
            'setUpdatingWorkspaceType',
            'setNextRecordsSkip',
            'setPaginating',
            'setLastTaken',
            'resetRecordsSkip',
            'resetContactsList',
            'resetFormVisibility',
        ]),

        fetchRecords: debounce(async function (this: any): Promise<void> {
            const params = {
                filters: { ...this.filters },
                pagination: { ...this.pagination }
            }

            const contactsData = await this.fetchContactsData(params, this.componentKey)

            this.setContactsList(contactsData, this.componentKey)
        }, 300),

        fetchInitialRecords(): void {
            this.setSearch(this.initialSearchValue, this.componentKey)

            if (! this.onlyListContactsOnSearch || this.initialSearchValue) {
                this.setLoading(true, this.componentKey)
                this.fetchRecords()
            }
        },

        tabs (): Tab[] {
            if (this.categories.length) {
                return this.validCategories.map((key) => this.getTabObject(key))
            }

            const tabs = Object.keys(InviteModalTabs).filter(
                (key) => InviteModalTabs[key.toUpperCase()] !== InviteModalTabs.DIRECTORY || Boolean(this.projectGlobalId)
            )

            return tabs.map((key) => this.getTabObject(key))
        },

        getTabObject (key: string): Tab {
            return {
                title: this.$t(capitalize(key)),
                key: InviteModalTabs[key.toUpperCase()],
                disabled: this.isFormVisible // We want the tabs to be disabled whenever we are showing the Contact form...
            }
        },

        onClosePopup (): void {
            this.resetState(this.componentKey)
            this.$emit('setInviteModalOpened', false)
        },

        setCurrentTab (index: number = 0): void {
            this.currentTabId = index
            this.currentTabKey = this.tabs()[this.currentTabId]['key']
        },

        onTabChange (index: number): void {
            this.setLoading(true, this.componentKey)

            this.resetRecordsSkip(this.componentKey)
            this.resetContactsList(this.componentKey)

            this.setCurrentTab(index)

            this.fetchRecords()
        },

        onSearchChange (searchValue: string): void {
            if (!searchValue) {
                this.resetContact(this.componentKey)
                this.setLastTaken(0, this.componentKey)
                this.setContactListEventListener()
            }

            this.setSearch(searchValue, this.componentKey)

            this.resetRecordsSkip(this.componentKey)
            this.resetContactsList(this.componentKey)

            if (!this.onlySearchWithValidEmail || this.isSearchValidEmail) {
                this.setLoading(true, this.componentKey)
                this.fetchRecords()
            }
        },

        onSave (): void {
            if (!this.removeMode) {
                this.setClosing(true, this.componentKey)
            }

            this.$emit('inviteModalDone', this.getInviteModalDonePayload())

            this.showDelightedSurvey()
            if (!this.removeMode) {
                this.onClosePopup()
            }
        },

        onSaveContact (): void {
            if (this.isUpdatingWorkspaceType) {
                this.updateGlobalWorkspaceType()
            } else {
                this.createNewContact()
            }
        },

        async updateGlobalWorkspaceType(): void {
            this.resetFormVisibility(this.componentKey)
            this.setLoading(true, this.componentKey)

            await this.updateGlobalWorkspaceAppType({
                global_workspace_id: this.contact.workspace.id,
                app_type: this.contact.workspace.app_type
            }, this.componentKey)

            let contacts = await this.fetchContactsData({
                filters: { ids: [this.contact.id] },
                pagination: { take: 1}
            }, this.componentKey)

            this.pushInvitedContact(contacts[0], this.componentKey)

            this.setLoading(true, this.componentKey)
            this.resetRecordsSkip(this.componentKey)
            this.resetContactsList(this.componentKey)

            this.setSearch('', this.componentKey)
            this.setUpdatingWorkspaceType(false, this.componentKey)

            this.fetchRecords()
        },

        async createNewContact (): void {
            this.resetFormVisibility(this.componentKey)
            this.setLoading(true, this.componentKey)

            try {
                const newContact = await this.createContact(this.contact, this.componentKey)

                if (this.forceBookmarkCreatedContacts) {
                    this.pushBookmarkedContact(newContact, this.componentKey)
                }

                this.resetRecordsSkip(this.componentKey)
                this.resetContactsList(this.componentKey)

                this.setSearch(this.contact.email, this.componentKey)
                this.setIntentionallyAddingContact(false, this.componentKey)

                this.fetchRecords()
            } catch (error) {
                this.errorHandle(error)
            }
        },

        async setInitialSelectedList (): Promise<void> {
            let preselectedBookmarkedContacts = [] as Contact[] | null
            let preselectedInvitedContacts = [] as Contact[] | null

            if (this.bookmarkedContactPersonIds && this.bookmarkedContactPersonIds.length) {
                preselectedBookmarkedContacts = await this.fetchContactsData({
                    filters: {
                        ids: this.bookmarkedContactPersonIds
                    }
                }, this.componentKey)
            }

            if (this.invitedContactPersonIds && this.invitedContactPersonIds.length) {
                preselectedInvitedContacts = await this.fetchContactsData({
                    filters: {
                        ids: this.invitedContactPersonIds
                    }
                }, this.componentKey)
            }

            const selectedContacts = await this.fetchAssignedContactsByResource(this.componentKey)

            let bookmarkedList = []
            let invitedList = []

            if (selectedContacts.items && selectedContacts.items.length) {
                selectedContacts.items.forEach((contact: Contact) => {
                    if (contact.invitation.is_bookmarked) {
                        bookmarkedList.push(contact)
                    } else {
                        invitedList.push(contact)
                    }
                })
            }

            bookmarkedList = [].concat(bookmarkedList).concat(preselectedBookmarkedContacts).concat(this.bookmarkedContactPersons)
            invitedList = [].concat(invitedList).concat(preselectedInvitedContacts).concat(this.invitedContactPersons.filter((item: Contact) => item))

            bookmarkedList.forEach((item: Contact) => item.is_bookmarked = true)
            invitedList.forEach((item: Contact) => item.is_bookmarked = false)

            this.setInitialBookmarkedList(bookmarkedList, this.componentKey)
            this.setInitialInvitedList(invitedList, this.componentKey)
            this.setBookmarkedList(bookmarkedList, this.componentKey)
            this.setInvitedList(invitedList, this.componentKey)
            this.setReplacedList([], this.componentKey)
            this.setRemovedList([], this.componentKey)
            this.setRemovedFromBookmarkedList([], this.componentKey)
            this.setRemovedFromInvitedList([], this.componentKey)

            this.$emit('setInitialBookmarkedList', bookmarkedList)
            this.$emit('setInitialInvitedList', invitedList)
        },

        initializeDelightedSurvey (): void {
            if (this.withInvitationSurvey) {
                !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"SeyW3ErpJDhZRi5t","delightedCes72");
            }
        },

        showDelightedSurvey (): void {
            if (!this.withInvitationSurvey || !this.shouldShowDelightedSurvey) {
                return
            }

            delightedCes72.survey({
                name: `${ this.getAuthData.u_firstname } ${ this.getAuthData.u_lastname }`,
                email: this.getAuthData.u_email,
                locale: this.getAuthData.user_language ?? 'en',
                properties: {
                    workspaceName: this.getAuthData.workspace_subdomain,
                    workspaceType: this.getAuthData.app_type,
                    platform: 'Web',
                }
            })
        },

        getInviteModalDonePayload (): InviteModalDonePayload {
            return {
                resource_id: this.assignedResourceId,
                resource_type: this.assignedResourceType,
                project_global_id: this.assignedProjectGlobalId,
                invited: this.invitedContacts,
                bookmarked: this.bookmarkedContacts,
                removed: this.removedContacts,
                replaced: this.replacedContacts,
            }
        },

        infoPanelExpanded(value: boolean) {
            this.isInfoPanelExpanded = value
        },

        onAddingContact(): void {
            this.setIntentionallyAddingContact(true, this.componentKey)
        },

        onCompanyTypeToggle(state: boolean): void {
            if (true === state) {
                setTimeout(() => {
                    let el = this.$refs.mainContent
                    el.scrollTop = el.scrollHeight
                }, 50)
            }
        },

        isEmailOnContactList (email: string): boolean {
            return !! this.contactsList.find(contact => contact.email === email)
        },

        setContactListEventListener(): void {
            this.$nextTick(() => {
                const element = this.$refs.contactsList

                if (element) {
                    element.addEventListener('scroll', this.handleScroll)
                }
            })
        },

        handleScroll: debounce(function (this: any, event: any): void {
            if (! this.shouldPaginate) {
                return
            }

            const content = this.$refs.contactsList
            const contentHeight = content ? content.offsetHeight : 0

            const currentScroll = event.target.scrollTop
            const scrollHeight = event.target.scrollHeight

            if (currentScroll >= scrollHeight - contentHeight - 10) {
                this.setPaginating(true, this.componentKey)
                this.setNextRecordsSkip(this.componentKey)

                this.fetchRecords()
            }
        }, 100),
    }
})
