import { defineComponent, PropType } from 'vue'
import FundingSourcesProjectRow from '../funding-sources-project-row/FundingSourcesProjectRow.vue'
import Table from '@/components/table/Table.vue'
import Accordion from '@/components/accordion/Accordion.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import fundingClient from '../../api-clients/fundingClient'
import { NavigationFailure } from 'vue-router'
import {
    FundingSourceFundProjectsItems, FundingSourcesFunds,
    FundingSourcesFundSummary,
} from '../../interfaces/GlobalFundingInterfaces.ts'
import { mapState, mapActions } from 'pinia'
import { globalFundingStore } from '../../stores/globalFundingStore'

export default defineComponent({
    name: 'CapitalFundsAccordion',
    components: { Accordion, Table, FundingSourcesProjectRow },
    props: {
        fund: {
            required: true,
            type: Object as PropType<FundingSourcesFunds>,
        },
    },
    data () {
        return {
            rows: null as FundingSourceFundProjectsItems[],
            totals: null as FundingSourcesFundSummary,
        }
    },
    computed: {
        ...mapState(globalFundingStore,['toggledOpenFund']),
        tableHeaders (): HeaderTable[] {
            const headers: HeaderTable[] = [
                {
                    text: this.$t('Project Name'),
                    value: 'project_name',
                },
                {
                    text: this.$t('Allocated to Project'),
                    value: 'allocated_amount',
                    align: 'end',
                    sort: (item) => parseFloat(item),
                },
                {
                    text: this.$t('Paid to Date'),
                    value: 'summary.total_paid_to_date_amount',
                    align: 'end',
                    sort: (item) => parseFloat(item),
                },
                {
                    text: this.$t('Remaining Balance'),
                    value: 'summary.remaining_amount',
                    align: 'end',
                    sort: (item) => parseFloat(item),
                },
            ]
            return headers
        },
    },
    methods: {
        ...mapActions(globalFundingStore,['setToggledOpenFund']),
        async getFundProjectsData (isOpen: boolean): Promise<void> {
            if (isOpen) {
                let response
                if (this.toggledOpenFund?.fundId === this.fund.id) {
                    response = this.toggledOpenFund
                } else {
                    try {
                        response = await fundingClient.getProviderCapitalFundProjects(this.fund.id)
                        this.setToggledOpenFund(this.fund.id, response)
                    } catch (e) {
                        this.errorHandleNoRedirect(e)
                    }
                }

                if (response && response.data.items) {
                    this.rows = response.data.items
                    this.totals = response.data.totals
                }
            }
        },
        goToFundDetails (id: string): Promise<void | NavigationFailure> {
            return this.$router.push({
                name: 'funding-sources-fund-details',
                params: { id: id },
            })
        },
    },
})
