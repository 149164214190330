<template>
    <div class="io-design-system io-double-input" data-testid="double-input">
        <IOInput type="number"
                 no-number-spinners
                 v-model="inputValue"
                 @update:modelValue="emitLeftValue"
        />

        <CustomMultiselect
            :settings="multiselectOptions"
            :options="dropdownOptions"
            :addingNewInProcess="false"
            :statusEnable="false"
            :addNewBtnDisabled="false"
            icon="icon-chevron-down"
            v-model="selected"
            @changeOption="emitRightValue"
        >
            <template #header="{ activeOption }">
                <div v-if="activeOption">
                    {{ activeOption.name }}
                </div>
            </template>
            <template #option="{ option }">
                {{ option.name }}
            </template>
        </CustomMultiselect>
    </div>
</template>

<script lang="ts" src="./DoubleInput.ts"></script>
<style lang="scss" src="./DoubleInput.scss" scoped></style>
