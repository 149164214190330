const fullScreenMixin = {
    data () {
        return {
            isFullScreenMode: false,
            fullScreenElementId: null
        }
    },
    methods: {
        launchIntoFullscreen (elementId) {
            if (this.isFullScreenMode) {
                this.exitFullscreen()
                return
            }

            this.fullScreenElementId = elementId
            const element = document.getElementById(elementId)

            if (!element) {
                return
            }

            if (element.requestFullscreen) {
                element.requestFullscreen()
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen()
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen()
            }

            this.setFullScreenMode(true)
        },
        exitFullscreenFromExternalComponent () {
            const canCloseFullScreen = document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement

            if (canCloseFullScreen) {
                this.exitFullscreen()
            }
        },
        exitFullscreen () {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
            }
            this.setFullScreenMode(false)
        },
        setFullScreenMode (value) {
            this.isFullScreenMode = value

            value
                ? document.getElementById(this.fullScreenElementId)?.classList.add('io-fullscreen')
                : document.getElementById(this.fullScreenElementId)?.classList.remove('io-fullscreen')
        },
        handleFullScreenChange (elementId) {
            if (this.isFullScreenMode) {
                this.exitFullscreen()
            } else {
                this.launchIntoFullscreen(elementId)
            }
        },
        handleFullScreeEvent () {
            // is exit event
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                this.setFullScreenMode(false)
            }
            // reload elements with fixed position
            document.documentElement.scrollTop++
        }
    },
    beforeMount () {
        document.addEventListener('fullscreenchange', this.handleFullScreeEvent)
    },
    beforeUnmount () {
        document.removeEventListener('fullscreenchange', this.handleFullScreeEvent)
    }
}

export default fullScreenMixin
