import defaultState from './state'
import { cloneDeep } from 'lodash'

export default {
    namespaced: true,
    namespace: 'meetingMinutesTemplates/',
    state () {
        return cloneDeep(defaultState)
    }
}
