<template>
    <RightModal
        class="io-attach-lien-waiver"
        width="1200px"
        @close="$emit('close')"
    >
        <template #header>
            <div class="io-attach-lien-waiver-header">
                <div class="io-attach-lien-waiver-header__title">{{ itemToEdit ? $t('Edit Lien Waiver') : $t('Attach Lien Waiver') }}</div>
                <div class="io-attach-lien-waiver-header__actions">
                    <IOButton
                        :disabled="!isValidForm"
                        @click="attach()"
                    >
                        {{ itemToEdit ? $t('Save') : $t('Attach') }}
                    </IOButton>
                    <span class="icon-cross" @click="$emit('close')"/>
                </div>
            </div>
        </template>
        <template #default>
            <div class="io-attach-lien-waiver-body">
                <ContentTwoSidesTemplate v-if="!loading" class="io-content-template" iconClass="icon-info" :title="$t('Information')">
                    <template #leftSide>
                        <div class="io-form">
                            <AlertBox v-if="lienWaiverTemplate" color="yellow">
                                <template #default>
                                    {{ $t('Lien waiver template was provided by') }}
                                    {{ clientName }}.

                                    <IOButton
                                        variant="link"
                                        size="sm"
                                        @click="downloadLienWaiverTemplate"
                                    >
                                        {{ $t('Download Template') }}
                                    </IOButton>
                                </template>
                            </AlertBox>

                            <div class="io-form__item io-form__payment-type">
                                <IOLabel :text="$t('Payment Type')" :is-required="true" />
                                <Radio
                                    :radio-value="LienWaiverPaymentType.PartialPayment"
                                    :input-name="LienWaiverPaymentType.PartialPayment"
                                    :label-text="$t('Partial Payment')"
                                    v-model="paymentType"
                                />
                                <Radio
                                    :radio-value="LienWaiverPaymentType.FinalPayment"
                                    :input-name="LienWaiverPaymentType.FinalPayment"
                                    :label-text="$t('Final Payment')"
                                    v-model="paymentType"
                                />
                            </div>
                            <div class="io-form__item io-form__type">
                                <IOLabel :text="$t('Type')" :is-required="true" />
                                <Radio
                                    :radio-value="LienWaiverType.Conditional"
                                    :input-name="LienWaiverType.Conditional"
                                    :label-text="$t('Conditional')"
                                    v-model="type"
                                />
                                <Radio
                                    :radio-value="LienWaiverType.Unconditional"
                                    :input-name="LienWaiverType.Unconditional"
                                    :label-text="$t('Unconditional')"
                                    v-model="type"
                                />
                            </div>
                            <div class="io-form__item io-form__amount">
                                <IOLabel :text="$t('Amount')" :is-required="true" />
                                <field-numeric
                                    :id="'amount'"
                                    class="io-amount-field"
                                    :min="0"
                                    :options="{ numeralPositiveOnly: false, numericOnly: false }"
                                    :showRounded="true"
                                    :sign="'$'"
                                    v-model="amount"
                                />
                            </div>
                            <div class="io-form__item io-form__file">
                                <IOLabel :text="$t('Attach Signed Lien Waiver')" :is-required="true" />
                                <FilesComponent
                                    :key="filesArrayId"
                                    modelType="lien_waivers_v2"
                                    :title="' '"
                                    :showSearch="false"
                                    :arrayId="filesArrayId"
                                    :showShared="false"
                                    :forceHideSharedTab="true"
                                    :showUploader="true"
                                    :hideShareColumn="true"
                                    :addToTemporary="true"
                                    :filesInNewTab="true"
                                    :allTabsVisible="false"
                                    :showViewToggle="false"
                                    :showFiles="false"
                                    :multipleFiles="false"
                                    @newFileAdded="onFileAdd"
                                    @tempFileRemove="onFileRemove"
                                />
                            </div>
                        </div>
                    </template>
                    <template #rightSide>
                        <section>
                            <IOLabel :text="$t('Payment Type')" />
                            <div class="io-sub-title">{{ $t('Partial Payment') }}</div>
                            <div class="io-value">{{ $t('Should be used when you are expecting future payments on the project.') }}</div>
                            <div class="io-sub-title">{{ $t('Final Payment') }}</div>
                            <div class="io-value">{{ $t('Should be used on final invoice of the project.') }}</div>
                        </section>
                        <section>
                            <IOLabel :text="$t('Type')" />
                            <div class="io-sub-title">{{ $t('Conditional') }}</div>
                            <div class="io-value">{{ $t('Conditional lien waivers waive lien rights on the condition of actual receipt of payment.') }}</div>
                            <div class="io-sub-title">{{ $t('Unconditional') }}</div>
                            <div class="io-value">{{ $t('Unconditional lien waivers are effective and enforceable upon signature independent of payment receipt.') }}</div>
                        </section>
                    </template>
                </ContentTwoSidesTemplate>
            </div>
        </template>
    </RightModal>
</template>

<script lang="ts" src="./AttachLienWaiverModal.ts"></script>
<style lang="scss" src="./AttachLienWaiverModal.scss" scoped></style>
