<template>
    <div class="toggle-switch-filter">
        <h6 class="toggle-switch-filter__header">{{ title }}</h6>
        <div class="io-switch">
            <label>
                <input
                    type="checkbox"
                    v-model="isChecked"
                    :checked="isChecked"
                    @change="change"
                >
                <span v-if="isChecked">{{ $t('Yes') }}</span>
                <span v-else>{{ $t('No') }}</span>

            </label>
        </div>
    </div>
</template>

<script lang="ts" src="./ToggleSwitchFilter.ts"></script>
<style lang="scss" src="./ToggleSwitchFilter.scss"></style>
