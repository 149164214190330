<template>
    <div class="io-single-toastr">
        <div class="io-single-toastr__text-holder" @click="previewNotification()">
            <div class="io-f-row io-f-start">
                <div class="io-single-toastr__icon">
                    <i class="io-accent far" :class="alert.icon"></i>
                </div>
                <div class="io-single-toastr__text">
                    <p class="io-single-toastr__header" v-html="prepareNotificationMessage(alert)"></p>
                    <p class="io-single-toastr__time">{{ alert.type_text }} {{ $filters.agoDate(alert.created_at) }}</p>
                </div>
            </div>
        </div>
        <div class="io-single-toastr__buttons">
            <template v-if="loading">
                <div class="io-loader-small"></div>
            </template>
            <template v-else-if="alert.type === 'notification'">
                <div class="io-single-toastr__single-btn io-dismiss" @click="dismissAlert()">{{ $t('Dismiss') }}</div>
                <div
                    v-if="alert.action && alert.action.show"
                    class="io-single-toastr__single-btn"
                    @click="dismissAlert()"
                >
                    <router-link
                        :to="alert.action.url"
                        class="io-accent"
                    >
                        {{ alert.action.text }}
                    </router-link>
                </div>
            </template>
            <template v-else>
                <div class="io-single-toastr__single-btn" @click.stop="acceptInvite()">{{ $t('Accept') }}</div>
                <div class="io-single-toastr__single-btn" @click.stop="previewNotification()">{{ $t('View') }}</div>
            </template>
        </div>
    </div>
</template>

<script src="./SingleNotification.ts" lang="ts"></script>
<style lang="scss" src="./SingleNotification.scss" scoped></style>
