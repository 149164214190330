<template>
    <div class="io-table-of-contents">
        <SearchInput
            :placeholder="$t('Search for sheets...')"
            :loader="false"
            :startLength="1"
            @searchChanged="onChangeSearchValue"
        />

        <div v-if="data" class="io-table-of-contents__content">
            <TableOfContentsItem
                v-for="(item, index) in filteredData"
                :key="index"
                :data="item"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./TableOfContents.ts"></script>
<style lang="scss" src="./TableOfContents.scss" scoped></style>
