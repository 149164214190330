<template>
    <div class="io-single-info-row">
        <p class="io-single-info-row__title">
            {{ label }}
            <slot name="labelRight"/>
        </p>
        <p v-if="!$slots.info" class="io-single-info-row__info">{{ info ? info : "-" }}</p>
        <slot name="info"/>
    </div>
</template>

<script lang="ts" src="./IOSingleInfoRow.ts"></script>
<style lang="scss" src="./IOSingleInfoRow.scss" scoped></style>
