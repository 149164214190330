import { defineComponent } from 'vue'
import throttle from 'lodash/throttle'

const SCROLL_RECALCULATION_INTERVAL_MS = 100

export default defineComponent({
    name: 'ActionDropdownMenu',

    props: {
        /** HTML element to which the menu will be positioned */
        anchor: {
            type: Object as () => HTMLElement,
            required: true,
        }
    },

    data () {
        return {
            // defines if menu should be opened below or above of the button,
            // depending on available space
            isDropup: false,
            scrollableParent: null as HTMLElement,
        }
    },

    computed: {
        directionClass (): string {
            return this.isDropup 
                ? 'io-action-dropdown__menu--dropup' 
                : 'io-action-dropdown__menu--dropdown'
        },
    },

    created (): void {
        this.calculateIsDropupThrottled = throttle(
            this.calculateIsDropup,
            SCROLL_RECALCULATION_INTERVAL_MS,
            { leading: false, trailing: true },
        )
    },

    mounted (): void {
        this.scrollableParent = this.findClosestScrollableParent(this.anchor)
        this.scrollableParent.addEventListener('scroll', this.calculateIsDropupThrottled)
        this.calculateIsDropup()
    },

    beforeUnmount (): void {
        this.scrollableParent.removeEventListener('scroll', this.calculateIsDropupThrottled)
    },

    methods: {
        findClosestScrollableParent (element: HTMLElement): HTMLElement {
            if (!element.parentElement) {
                return document.documentElement
            }

            const { overflowY } = getComputedStyle(element.parentElement)
            const isScrollable = overflowY === 'scroll' || overflowY === 'auto'

            return isScrollable 
                ? element.parentElement 
                : this.findClosestScrollableParent(element.parentElement)
        },

        calculateIsDropup (): void {
            const { height } = (this.$el as HTMLElement).getBoundingClientRect()
            const anchorPosition = this.anchor.getBoundingClientRect()
            const parentPosition = this.scrollableParent.getBoundingClientRect()
            const spaceAbove = anchorPosition.top - parentPosition.top
            const spaceBelow = parentPosition.bottom - anchorPosition.bottom
            const fitsAbove = spaceAbove > height
            const fitsBelow = spaceBelow > height

            // when menu does not fit either direction it will be rendered where more space is 
            // but still will be clipped, this issue can be resolved by assigning max height 
            // of the menu adjusted to available space but would require refactoring any nested 
            // submenus that open to the side (simple overflow-y here would clip those menus)
            this.isDropup = !fitsBelow && (fitsAbove || spaceAbove > spaceBelow)
        },

        // method must be assigned at component creation level to contain independent throttle
        // @see https://vuejs.org/guide/essentials/reactivity-fundamentals.html#stateful-methods
        calculateIsDropupThrottled (): void {
            throw Error('Not implemented') // failsafe if code changes and not overwrite
        },
    },
})
