import { defineComponent, PropType } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import SoVLineItemField from '@/io-modules/invoices/enums/SovLineItemField'
import SoVLineItem from '@/io-modules/invoices/interfaces/SoVLineItem'
import { LabelSize, LabelType } from '@/constants/LabelConstants'
import Comments from '@/components/comments/Comments.vue'
import CommentItem from '@/components/comments/interfaces/CommentItem'
import dayjs from 'dayjs'
import { mapState as mapStateVuex } from 'vuex'
import Table from '@/components/table/Table.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface.ts'
import RevisionModalRow from './revision-modal-row/RevisionModalRow.vue'
import { mapActions, mapState } from 'pinia'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView.ts'
import ReviseResubmitMixin from '@/io-modules/invoices/mixins/sov-wbs/ReviseResubmitMixin.ts'
import { omit } from 'lodash'
import SoVTableColumnsMixin from '@/io-modules/invoices/mixins/sov-wbs/SoVTableColumnsMixin.ts'
import reviseResubmitClient, {
    RevisionRequestClientRequestedData,
    RevisionRequestOriginalData, RevisionRequestVendorAppliedData,
} from '@/io-modules/invoices/api-clients/reviseResubmitClient.ts'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV.ts'

export default defineComponent({
    name: 'RevisionModal',
    components: {
        IOModal,
        IOSingleInfoRow,
        Comments,
        Table,
        RevisionModalRow
    },
    props: {
        item: {
            type: Object as PropType<SoVLineItem>,
            default: () => ({})
        },
    },
    mixins: [ReviseResubmitMixin, SoVTableColumnsMixin],
    emits: ['close', 'adjustedItem'],
    data () {
        return {
            SoVLineItemField,
            LabelSize,
            LabelType,
            isEditable: false,
            taggableUsers: [],
            comments: [],
            original: {} as RevisionRequestOriginalData,
            submitted: {} as RevisionRequestClientRequestedData,
            adjusted: {} as RevisionRequestVendorAppliedData,
        }
    },
    computed: {
        ...mapStateVuex('appStore', {
            authData: (state: any) => state.authData
        }),

        ...mapStateVuex('project', {
            projectGlobalId: (state: any) => state.projectObj.project_global_id
        }),

        ...mapState(invoiceViewStore, [
            'isMyInvoice',
            'isVendorInvoice',
            'invoice',
            'isClient',
            'isVendor',
            'invIsNotApproved'
        ]),

        isNewItem (): boolean {
            return (this.isVendor && null === (this.item.adjustments?.client_requested?.current_application ?? null))
                || (this.isClient && (null === (this.item.adjustments?.vendor_applied?.current_application ?? null)))
        },

        isAcceptedByClient (): boolean {
            return this.item.acceptedByClient
        },

        isCreatedByVendor (): boolean {
            return null === (this.item.adjustments.client_requested?.current_application ?? null)
        },

        modalTitle (): string {
            return `Adjustments: ${ this.item.numberFull ?? '' } - ${ this.item?.originalPhase?.name || this.item.name }`
        },

        tableHeaders (): HeaderTable[] {
            return [
                { text: this.$t('% of Completed Work'), sort: false },
                { text: this.$t('Current application'), sort: false },
                { text: `${ this.$t('Current Retention Percent') } (%)`, sort: false },
                { text: `${ this.$t('Total Retention Amount') } ($)`, sort: false },
                { text: `${ this.$t('Total Retention Percent') } (%)`, sort: false },
                { text: this.$t('Net Current application'), sort: false }
            ]
        },
    },
    async mounted () {
        await this.getComments()

        if (!this.item?.adjustments) {
            this.takeInitialValues()
        } else {
            this.prepareAdjustments()
        }

        if (this.isNewItem && ((this.isClient && this.canClientReviseInvoice) || (this.isVendor && this.isRevisionStatus))) {
            this.isEditable = true
        }
    },
    methods: {
        ...mapActions(invoiceRevisionStore, [
            'saveSingleRevisedItem',
            'loadAdjustmentsIntoWbsItems',
            'saveAdjustedItemAsClient',
            'removeSingleRevisedItem',
            'areSubmittedValuesEqual',
            'saveAdjustedItemAsVendor'
        ]),
        ...mapActions(invoiceSoVStore, ['getRealContractWbsId']),
        ...mapActions(invoiceViewStore,['setEditModeValue']),

        /**
         * Invoice: 3. Request Revision:
         * If has adjustments - take it, if not - take initial values
         */
        takeInitialValues (): void {
            const originalItemValues = omit(this.item, ['adjustments', 'originalPhase'])
            this.original = { ...originalItemValues }
            this.adjusted = { ...originalItemValues }
            this.submitted = { ...originalItemValues }
        },

        prepareAdjustments (): void {
            const originalItemValues = omit(this.item, ['adjustments', 'originalPhase'])
            if (this.isClient) {
                //When we are the client, submitted values are the original values, adjusted are the values we are sending to the vendor
                this.original = { ...originalItemValues, ...this.item.adjustments.original }
                if (null === (this.item.adjustments.vendor_applied?.current_application ?? null)) {
                    this.submitted = { ...originalItemValues }
                } else {
                    this.submitted = { ...originalItemValues, ...this.item.adjustments.vendor_applied }
                }
                this.adjusted = { ...originalItemValues, ...this.item.adjustments.client_requested }
            } else if (this.isVendor) {
                //When we are the vendor, submitted values are the values we are getting from the client, adjusted are the values we are sending to the client
                this.original = { ...originalItemValues, ...this.item.adjustments.original }
                if (null === (this.item.adjustments.client_requested?.current_application ?? null)) {
                    this.submitted = this.original
                } else {
                    this.submitted = {  ...originalItemValues, ...this.item.adjustments.client_requested }
                }
                if (null === (this.item.adjustments.vendor_applied?.current_application ?? null)) {
                    this.adjusted = { ...originalItemValues, ...this.item.adjustments.client_requested }
                } else {
                    this.adjusted = { ...originalItemValues }
                }
            } else {
                //We don't show revision request for any other role
                throw new Error('Unsupported role ' + this.invoice.role)
            }

            this.initTaskPercents(this.original)
            this.updateRetention(this.original)

            this.initTaskPercents(this.submitted)
            this.updateRetention(this.submitted)

            this.initTaskPercents(this.adjusted)
            this.updateRetention(this.adjusted)
        },

        getOriginallySubmittedValue (field: SoVLineItemField): number {
            return this.original[field] || 0
        },

        getAdjustedValue (field: SoVLineItemField): number {
            return this.adjusted[field] || 0
        },

        getDifferenceValue (field: SoVLineItemField): number {
            return (this.getAdjustedValue(field) - this.getOriginallySubmittedValue(field))
        },

        reviseAdjustments (): void {
            if (this.isClient) {
                this.setEditModeValue(true)
            }

            this.isEditable = true
        },

        async acceptAdjustments (): Promise<void> {
            if (this.isVendor) {
                this.setEditModeValue(true)
            }
            // Accepting means that adjusted values become equal to submitted values
            this.adjusted = { ...this.submitted }
            let item = null
            if (this.isClient) {
                item = this.adjustRevisionRequestItem(this.item, this.adjusted, this.comments)
                await this.saveAdjustedItemAsClient(item, this.adjusted, this.comments)
            } else {
                item = this.adjustRevisionRequestItem(this.item, this.adjusted, this.comments)
                await this.saveAdjustedItemAsVendor(item, this.adjusted, this.comments)
            }
            this.$emit('adjustedItem', item, this.comments)
            this.closeModal()
        },

        revise (): void {
            this.$emit('revisedItem', this.item)
            this.closeModal()
        },

        closeModal (): void {
            this.$emit('close')
        },

        async applyAdjustment (): Promise<void> {
            this.setLoadingBar(true)
            const item = this.adjustRevisionRequestItem(this.item, this.adjusted, this.comments)
            if (this.isClient) {
                if (!item.adjustments) {
                    await this.removeSingleRevisedItem(this.getRealContractWbsId(item))
                } else {
                    await this.saveAdjustedItemAsClient(item, this.adjusted, this.comments)
                    await this.exchangeComments()
                }
            } else if (this.isVendor) {
                await this.saveAdjustedItemAsVendor(item, this.adjusted, this.comments)
            }
            this.$emit('adjustedItem', item, this.comments)
            this.$emit('close')
            this.setLoadingBar(false)
        },

        async addComment (comment: CommentItem): Promise<void> {
            this.comments.unshift(
                {
                    ...comment,
                    created_at: dayjs(new Date()) ,
                    creator: {
                        id: this.authData.u_mongo,
                        first_name: this.authData.u_firstname,
                        last_name: this.authData.u_lastname
                    }
                })

            await this.exchangeComments()
        },

        async sendComments (): Promise<void> {
            try {
                await reviseResubmitClient.saveRevisionComments(this.projectGlobalId, this.$route.params.id, this.getRealContractWbsId(this.item), this.comments)
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },

        async getComments (): Promise<void> {
            try {
                const { data } = await reviseResubmitClient.getRevisionComments(this.projectGlobalId, this.$route.params.id, this.getRealContractWbsId(this.item))
                this.comments = data || []
            } catch (e) {
                this.errorHandleNoRedirect(e)
            }
        },

        async deleteComment (comment: CommentItem): Promise<void> {
            this.comments = this.comments.filter(elem => elem.id !== comment.id)
            await this.exchangeComments()
        },

        async updateComment (comment: CommentItem): Promise<void> {
            const commentToUpdate = this.comments.find(elem => elem.id === comment.id)
            if (commentToUpdate) {
                commentToUpdate.comment = comment.comment

                await this.exchangeComments()
            }
        },

        async exchangeComments (): Promise<void> {
            if (!this.isNewItem) {
                await this.sendComments()
                await this.getComments()
            }
        },

        clearRowUpdates (): void {
            if (this.isClient) {
                this.adjusted = { ...this.original }
            } else if (this.isVendor) {
                this.adjusted = { ...this.submitted }
            }
        }
    }
})
