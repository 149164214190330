<template>
    <tr class="io-single-approver" :class="{ 'io-single-approver--view-only': !edit && !allowDrag }">
        <td class="io-drag">
            <i v-if="edit || allowDrag" class="fas fa-grip-vertical"></i>
            <span v-if="approvalSequence">{{ moment.localeData().ordinal(index + 1) }}</span>
            <span v-else>-</span>
        </td>

        <td class="io-employee-role">
            <ApproverSelector
                v-if="edit"
                :approver="approver"
                :employees="employees"
                :active-employee="activeEmployee"
                :is-role-type-multiselect="isRoleTypeMultiselect"
                @changeOption="onChangeEmployee"
            />

            <div v-else-if="activeEmployee" class="io-f-row io-f-start io-employee-logo">
                <Initials
                    :bigAvatar="true"
                    :fullName="activeEmployee.name"
                    class="io-single-option__logo"
                />
                <div>
                    <span>{{ activeEmployee.name }}</span>
                </div>
            </div>

            <div v-else-if="approver.employee" class="io-f-row io-f-start io-employee-logo">
                <Initials
                    :bigAvatar="true"
                    :fullName="approver.employee.full_name"
                    class="io-single-option__logo"
                />
                <div>
                    <span>{{ approver.employee.full_name }}</span>
                </div>
            </div>
        </td>

        <td class="io-approver-type">
            <template v-if="edit">
                <AssignmentTypeSelector :default-option-value="approver.assignment_type" @changeOption="selectType" />
            </template>
            <template v-else>
                <span>{{ assignmentTypeText }}</span>
            </template>
        </td>

        <td class="io-approval-limit">
            <template v-if="edit && allowLimitEdit">
                <Dropdown
                    v-if="approvalLimit"
                    v-tooltip.bottom="{ content: $tc('You must have at least one unconstrained member of this approval workflow.'), visible: approvalDisabled }"
                    :class="{'io-no-limit': approvalDisabled}"
                    :disabled="approvalDisabled"
                    :options="limitOptions"
                    :selectedByDefault="defaultSelection"
                    :width="136"
                    :placeholder="$tc('Limit type')"
                    @select="selectLimitType"
                />
                <span v-else>-</span>
            </template>

            <template v-else-if="approvalLimit">
                <span>{{ defaultSelection.label }}</span>
                <span v-if="approver.limit_type !== 'no_limit' && approver.limit_value">{{ $filters.formatCurrency(approver.limit_value) }}</span>
            </template>

            <template v-else>
                <span>-</span>
            </template>
        </td>

        <td class="io-limit-input">
            <FieldNumeric
                v-if="showLimitValueInput"
                v-model="approver.limit_value"
                :options="{ numeralPositiveOnly: true, numericOnly: false }"
            />
        </td>

        <td></td>

        <td v-if="!showApprovalNote" class="io-remove aright">
            <template v-if="canDelete">
                <i class="far fa-trash-alt" @click="deleteApprover"></i>
            </template>
        </td>

        <td v-else class="io-single-approver__status aright">
            <SingleApprovalStatus
                :status="approver.approval.status"
                :date="approver.approval.updated_at"
                :disable-view-note="true"
                :approved-at-text="approvedAtText"
            />
        </td>

    </tr>
</template>

<script lang="ts" src="./SingleApproverV2.ts"></script>
<style lang="scss" src="./SingleApproverV2.scss"></style>
