import { defineComponent } from 'vue'
import { debounce } from 'lodash'

import Tabs from '@/components/atoms/tabs/Tabs.vue'
import Notification from './notifications/Notification.vue'
import SearchInput from '@/components/search-input/SearchInput.vue'

import { Tab } from '@/components/atoms/tabs/TabInterface'
import { NotificationsTabs } from '../../enums/Notifications'
import { PlaceholderConfig } from '@/layouts/parts/menu-left/interfaces/MenuLeftInterface'
import { Notification as NotificationInterface } from '@/layouts/parts/menu-left/interfaces/MenuLeftInterface'

import menuLeftClient from '../../api-clients/menuLeftClient'

export default defineComponent({
    name: 'NotificationsCenter',
    components: {
        Tabs,
        Notification,
        SearchInput
    },
    props: {
        isOpened: { type: Boolean, required: true },
        unreadNotifications: { type: Number, required: true }
    },
    data () {
        return {
            isLoading: true,
            isUnreadOnly: false,
            notificationsSelectedTab: 0,
            notificationsSelectedTabKey: '',
            notificationsList: [],
            scrollWrapperClass: '.io-notification-center .io-notification-center__items-container',
            lazySkip: 0,
            lazySkipStep: 50,
            prevScroll: 0,
            isLastPage: false,
            searchValue: ''
        }
    },
    computed: {
        notificationsTabs(): Tab[] {
            return [
                {
                    title: this.$t('All'),
                    key: NotificationsTabs.ALL
                },
                {
                    title: this.$t('Invitations'),
                    key: NotificationsTabs.INVITATIONS
                },
                {
                    title: this.$t('Messages'),
                    key: NotificationsTabs.MESSAGES
                },
                {
                    title: this.$t('General'),
                    key: NotificationsTabs.GENERAL
                }
            ]
        },
        isPlaceholderVisible (): boolean {
            return !this.isLoading && !this.notificationsList.length
        },
        placeholderConfig (): PlaceholderConfig {
            const config = {
                all: {
                    title: this.$t('No notifications'),
                    icon: 'icon-bell'
                },
                invitation: {
                    title: this.$t('No Invitations'),
                    icon: 'icon-send-2'
                },
                chat: {
                    title: this.$t('No Messages'),
                    icon: 'icon-comment-text'
                },
                notification: {
                    title: this.$t('No General Notifications'),
                    icon: 'icon-bell'
                }
            }

            return config[this.notificationsSelectedTabKey]
        }
    },
    watch: {
        $route (): void {
            this.onNotificationsCenterClose()
        }
    },
    created () {
        this.$nextTick(() => {
            const element = document.querySelector(this.scrollWrapperClass)
            if (element) {
                element.addEventListener('scroll', this.handleScroll)
            }
        })

    },
    beforeMount () {
        this.init()
    },
    beforeUnmount () {
        const element = document.querySelector(this.scrollWrapperClass)

        if (element) {
            element.removeEventListener('scroll', this.handleScroll)
        }
    },
    methods: {
        init (): void {
            this.updateIsUnreadOnly()
            this.setContactsListsSelectedTabKey()
            this.updateNotifications()
        },
        updateIsUnreadOnly (): void {
           this.isUnreadOnly = localStorage.getItem('isNotificationsUnreadOnly') === 'true'
        },
        async updateNotifications (): Promise<void> {
            this.setIsLoading(true)

            this.isLastPage = false
            this.lazySkip = 0
            this.notificationsList = []

            try {
                const { data } = await menuLeftClient.getNotifications({
                    type: this.notificationsSelectedTabKey,
                    skip: this.lazySkip,
                    payload: {
                        unreadsOnly: this.isUnreadOnly,
                        search: this.searchValue
                    }
                })

                this.notificationsList = data.items

                if (this.notificationsList.length < this.lazySkip) {
                    this.isLastPage = true
                }

                this.lazySkip = this.lazySkip + this.lazySkipStep
            } catch (error) {
                this.errorHandle(error)
            } finally {
                this.setIsLoading(false)
            }
        },
        async getMoreNotifications (): Promise<void> {
            this.setIsLoading(true)

            try {
                const { data } = await menuLeftClient.getNotifications({
                    type: this.notificationsSelectedTabKey,
                    skip: this.lazySkip,
                    payload: {
                        unreadsOnly: this.isUnreadOnly,
                        search: this.searchValue
                    }
                })

                this.notificationsList.push(...data.items)

                this.lazySkip = this.lazySkip + this.lazySkipStep

                if (this.notificationsList.length < this.lazySkipStep) {
                    this.isLastPage = true
                }
            } catch (error) {
                this.errorHandle(error)
            } finally {
                this.setIsLoading(false)
            }
        },
        onNotificationsCenterClose (): void {
            this.$emit('toggleNotificationsCenterOpened')
        },
        async onMarkAllAsReadClick (): Promise<void> {
            try {
                await menuLeftClient.markAllNotificationsAsRead()

                this.updateNotifications()
            } catch (error) {
                this.errorHandle(error)
            }
        },
        onUnreadOnlyChange (): void {
            this.isUnreadOnly = !this.isUnreadOnly

            localStorage.setItem('isNotificationsUnreadOnly', this.isUnreadOnly.toString())

            this.updateNotifications()
        },
        setContactsListsSelectedTabKey (): void {
            this.notificationsSelectedTabKey = this.notificationsTabs[this.notificationsSelectedTab].key
        },
        onNotificationsTabChange (index: number): void {
            this.notificationsSelectedTab = index

            this.setContactsListsSelectedTabKey()
            this.updateNotifications()
        },
        setIsLoading (isLoading: boolean): void {
            this.isLoading = isLoading
        },
        onNotificationUpdate(data: NotificationInterface) {
            const currentNotificationIndex = this.notificationsList.findIndex(item => item.id === data.id)
            this.notificationsList[currentNotificationIndex] = data
        },
        handleScroll: debounce (async function (this: any, event) {
            const scrollWindow = document.querySelector<HTMLElement>(this.scrollWrapperClass)
            const elementHeight = scrollWindow ? scrollWindow.offsetHeight : 0

            const currentScroll = event.target.scrollTop
            const scrollHeight = event.target.scrollHeight

            if (currentScroll > this.prevScroll) {
                this.prevScroll = currentScroll
            } else {
                this.prevScroll = currentScroll

                return
            }

            if (this.isLastPage) {
                return
            }

            // offset 10
            if (currentScroll >= scrollHeight - elementHeight - 10 && !this.isLoading && !this.isLastPage) {
                await this.getMoreNotifications()
            }
        }, 200),
        onSearchChange (value: string): void {
            this.searchValue = value

            this.updateNotifications()
        }
    }
})
