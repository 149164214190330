import { computed, defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { cloneDeep, keyBy, mapValues } from 'lodash'
import useLoader from '@/composables/useLoader.ts'

import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'

import WebViewer from './web-viewer/WebViewer.vue'
import RightPanel from './parts/right-panel/RightPanel.vue'
import ConfirmAlert from '@/components/atoms/confirm-alert/ConfirmAlert.vue'
import ToggleSwitch from '@/components/atoms/ToggleSwitch/ToggleSwitch.vue'
import Filters from './parts/filters/Filters.vue'
import SelectStamp from './parts/right-panel/tabs/markups/punch/select-stamp/SelectStamp.vue'
import PunchItemOperationsSlideout
    from '@/io-modules/punch-lists/components/punch-item-operations-slideout/PunchItemOperationsSlideout.vue'
import StampsIndex from '@/io-modules/punch-lists/components/stamps-index/StampsIndex.vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import ShareMarkupPopup from './parts/popups/share-markup-popup/ShareMarkupPopup.vue'

import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin.ts'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar.ts'
import { PunchDrawingType } from '@/interfaces/modules/projects/punch-list/PunchDrawingType.ts'
import { RfiDrawingType } from '@/interfaces/modules/projects/rfis/RfiDrawingType.ts'


import { Breadcrumb } from '@/store/app/breadcrumbs.ts'
import { RightPanelTab } from '@/io-modules/drawings/enums/RightPanelTab.ts'
import { Reference } from '@/components/drawing-reference-select/interfaces/Sheet.ts'
import { Stamp } from '@/components/drawing-reference-select/interfaces/Stamp.ts'
import { JobsiteLocation } from '@/components/jobsite-locations-select/interfaces/JobsiteLocations.ts'
import { InitialData as PunchItemInitialData, PunchItem } from '@/io-modules/punch-lists/interfaces/PunchItem.ts'
import { Status as PunchItemStatus } from '@/io-modules/punch-lists/enums/PunchItem.ts'
import { Person } from '@/io-modules/drawings/interfaces/Person.ts'
import RfiOperationsSlideout from '@/io-modules/rfis/components/rfi-operations-slideout/RfiOperationsSlideout.vue'


const filters = {
    types: [],
    statuses: [],
    created_by: [],
    visibility: [],
    assigned_to: [],
    created_at: {
        end: null,
        start: null,
    },
}

export default defineComponent({
    name: 'SheetsShow',
    components: {
        RfiOperationsSlideout,
        WebViewer,
        RightPanel,
        ConfirmAlert,
        ToggleSwitch,
        Filters,
        SelectStamp,
        ShareMarkupPopup,
        PunchItemOperationsSlideout,
        StampsIndex,
        IOModal,
    },
    provide () {
        return {
            mentionUsers: computed(() => this.mentionUsers),
        }
    },
    inject: ['projectId', 'projectName'],
    props: {
        pid: {
            type: String,
            required: true,
        },
        sheetId: {
            type: String,
            required: true,
        },
        versionId: {
            type: String,
            required: true,
        },
        defaultPanel: { type: String, required: false, default: RightSideBar.DETAILS },
        additionallyDisabledElements: {
            required: false,
            default: ['ribbonsDropdown', 'toolsHeader', 'pinOffButton', 'ribbons', 'contextMenuPopup'],
        },
        disableExistingAnnotations: { type: Boolean, required: false, default: false },
        fromPunchList: { type: Boolean, default: false },
    },
    mixins: [breadcrumbsMixin],
    setup () {
        const { load } = useLoader({ globalSpinner: true })
        return { load }
    },
    data () {
        return {
            rendered: false,
            isWebViewerLoaded: false,
            downloadAlertShown: false,
            includeMarkups: true,
            filters,
            defaultFilters: cloneDeep(filters),
            disabledElementsWebViewer: [
                'leftPanelButton',
                'viewControlsButton',
                'stickyToolGroupButton',
                'shapeToolGroupButton',
                'freeHandToolGroupButton',
                'freeHandHighlightToolGroupButton',
                'toolbarGroup-Edit',
                'toolbarGroup-Forms',
                'downloadButton',
                'printButton',
                'notesPanel',
                'toggleNotesButton',
                'annotationCommentButton',
                'linkButton',
                'undoButton',
                'redoButton',
                'eraserToolButton',
            ],
            rfiDrawingType: RfiDrawingType.DRAWING_TYPE_REFERENCE,
            headerTip: true,
            headerTipRendered: false,
            visibilityEnums: {},
            isLatestVersion: false,
            isArchived: false,
            drawingReference: null as Reference,
            stamp: null as Stamp,
            preservedPunchItem: {
                status: null as PunchItemStatus,
                dueDate: '',
                jobsiteLocations: null as JobsiteLocation[][],
            } as PunchItemInitialData,
            isRfiSlideoutOpen: false,
            mentionUsers: null as Person[],
        }
    },
    computed: {
        ...mapGetters('appStore', ['isSubcontractor']),
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
            selectedAnnotation: 'getSelectedAnnotation',
            selectedExistingAnnotation: 'getSelectedExistingAnnotation',
            addingAnnotation: 'getAddingAnnotation',
            panelContent: 'getPanelContent',
            activeComponent: 'getActiveComponent',
            getSelectStampPopup: 'getSelectStampPopup',
            getAnnotationAdded: 'getAnnotationAdded',
            annotationContent: 'getAnnotationContent',
        }),
        ...mapGetters('modal', {
            modalData: 'getReturnData',
            modalShow: 'getShow',
        }),
        ...mapState('drawingsV2', ['activeDrawingSet', 'activeDrawingSheet']),
        ...mapGetters('drawingsV2', {
            getShareMarkupPopupShown: 'getShareMarkupPopupShown',
            getShareMarkupData: 'getShareMarkupData',
            getDrawingVersionSwitched: 'getDrawingVersionSwitched',
        }),
        isDrawingShown (): boolean {
            return this.activeComponent === 'drawing' || this.activeComponent === 'punch' || this.activeComponent === 'comment' || this.activeComponent === 'rfi'
        },
        backBtnLink (): string {
            return this.$router.resolve({
                name: 'drawings-sheets-index',
                params: {
                    setId: this.model.drawing.drawing_set_id,
                },
            }).href
        },
        title (): string {
            return `${ this.model.page_label } ${ this.model.page_title }`
        },
        breadcrumbs (): Breadcrumb[] {
            return [
                {
                    text: this.$t('Projects'),
                    to: { name: 'project-list' },
                },
                {
                    text: this.projectName as string,
                    projId: this.projectId as string,
                    to: { name: 'project-dashboard', params: { pid: this.pid } },
                },
                {
                    text: this.$t('Drawings'),
                    to: { name: 'drawings-sets-index' },
                },
                {
                    text: `${ this.model.drawing_set_name }`,
                    to: {
                        name: 'drawings-sheets-index',
                        params: { setId: this.model.drawing.drawing_set_id, pid: this.pid },
                    },
                },
                {
                    text: `${ this.model.page_label } ${ this.model.page_title }`,
                    to: {
                        name: 'drawings-sheets-show',
                        params: {
                            sheetId: this.$route.params.sheetId,
                            pid: this.pid,
                            versionId: this.$route.params.versionId,
                        },
                    },
                },
            ]
        },
    },
    watch: {
        modalShow: {
            handler (newVal: boolean | string): void {
                if (!newVal) {
                    this.annotationCanceled()
                }

                if (!newVal) {
                    this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', 'drawing')
                }
            },
        },
        modalData: {
            handler (newVal: unknown): void {
                if (newVal.data.id) {
                    this.$store.commit('drawingMarkup/SET_ANNOTATION_OBJECT', {
                        id: newVal.data.id,
                        type: RightSideBar.PUNCH,
                    })
                    this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', 'drawing')
                }
            },
        },
        activeComponent: {
            handler (): void {
                this.annotationContent !== 'punch' && !this.isRfiSlideoutOpen && this.closePunchItemSlideout()
            },
        },
        async isDrawingShown (newValue: boolean): Promise<void> {
            if (newValue) {
                await this.getModel()
            }
        },
        activeDrawingSheet: {
            async handler (newVal: { id: string, version: string }, oldVal: {
                id: string,
                version: string
            }): Promise<void> {
                if (newVal?.version === oldVal?.version) {
                    return
                }

                await this.init()
            },
            deep: true,
            immediate: true,
        },
        async getAnnotationAdded (newVal: boolean): Promise<void> {
            if (!newVal) {
                return
            }

            await this.annotationAdded()
        },
    },
    async mounted (): Promise<void> {
        this.$store.commit('drawingMarkup/SET_PANEL_CONTENT', this.defaultPanel)
        await this.init()
        this.setBreadcrumbs(this.breadcrumbs)
        if (!this.isLatestVersion || this.isArchived) {
            this.disabledElementsWebViewer.push(...this.additionallyDisabledElements)
        }
        localStorage.headerTip ? this.headerTip = (localStorage.headerTip === 'true') : localStorage.setItem('headerTip', 'true')

        if (this.$route.query?.taskId) {
            await this.openTaskModal()
        }

    },
    beforeUnmount (): void {
        this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SHEET', {})
    },
    methods: {
        async init (): Promise<void> {
            await this.getVisibilityEnums()
            await this.getModel()
            await this.fetchMentionUsers()
        },
        async openTaskModal (): Promise<void> {
            if (this.$route.query.taskId) {
                await this.$store.dispatch('modal/setAdditionalData', {})
                await this.$store.dispatch('modal/setShow', 'modal-task')
            }
        },
        hideHeaderTip (): void {
            this.headerTip = false
            localStorage.setItem('headerTip', 'false')
        },
        annotationCanceled (): void {
            this.cancelAnnotation()
            this.$store.commit('drawingMarkup/SET_ACTIVE_COMPONENT', 'drawing')
            this.$store.commit('drawingMarkup/SET_DRAWING_PIN', {})
        },
        async getModel (): Promise<void> {
            return this.load(async (): Promise<void> => {
                let { data } = await setsClient.getDrawingVersion(this.activeDrawingSheet?.version ?? this.$route.params.versionId, {
                    filters: this.filters,
                })
                data.enum.visibility = mapValues(keyBy(this.visibilityEnums, 'id'), 'name')

                this.isLatestVersion = this.activeDrawingSheet?.version ?
                    data.drawing.current_version_id === this.activeDrawingSheet.version
                    : data.drawing.current_version_id === this.$route.params.versionId

                this.isArchived = data.drawing.is_archived

                this.$store.commit('drawingMarkup/SET_MODEL', data)

                if (this.getDrawingVersionSwitched) {
                    this.$store.commit('drawingsV2/SET_DRAWING_VERSION_SWITCHED', false)
                    const currentSheet = data.drawing.versions.find(item => {
                        return item.id === data.id
                    })
                    this.showNotification('information', this.$t('You are now viewing Version {VERSION} of this sheet.').replace('{VERSION}', currentSheet.version))
                }
                this.rendered = true
            })
        },

        drawingVersionSwitch (): void {
            this.drawingVersionSwitched()

            this.$router.replace({
                name: 'drawings-sheets-show',
                params: {
                    sheetId: this.model.drawing.id,
                    versionId: this.model.drawing.current_version_id,
                },
                query: {
                    tab: RightPanelTab.HISTORY,
                },
            }).then(() => {
                this.$router.go(0)
            })
        },
        drawingVersionSwitched (): void {
            this.$store.commit('drawingsV2/SET_DRAWING_VERSION_SWITCHED', true)
        },
        async getVisibilityEnums (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const { data } = await setsClient.getDrawingSetVisibility()
                this.visibilityEnums = data
            })
        },
        async applyFilters (type?: RightSideBar): Promise<void> {
            this.$refs.viewer.disableEvents()
            this.$refs.viewer.hideAllAnnotations()
            this.setLoadingBar(true)

            if (type && type === RightSideBar.PUNCH) {
                await new Promise(res => setTimeout(res, 2000))
            }

            await this.getModel()
            this.$refs.viewer.importAnnotations(true)
            this.$refs.viewer.enableEvents()
        },
        async configWebViewer (): Promise<void> {
            this.$refs.viewer.addHoverInfo()
            this.$refs.viewer.addRotateButtons()
            this.$refs.viewer.addAnnotationSelectEvents()
            this.$refs.viewer.addEscapeEvent()
            await this.$refs.viewer.importAnnotations(false)
            this.$refs.viewer.addPdftronMarkupEvents()
            this.$refs.viewer.replaceContextMenuPopup()
            await new Promise(res => setTimeout(res, 2000))
            this.$store.commit('drawingMarkup/SET_LINKS_IMPORTED', true)
            this.$refs.viewer.setCurrentUser()
        },
        async deletePdftronMarkup (val: string): Promise<void> {
            await this.$refs.viewer.deletePdftronMarkup(val)
        },
        documentLoaded (): void {
            this.configWebViewer()
            this.isWebViewerLoaded = true
            this.setLoadingBar(false)
            if (this.addingAnnotation.status) {
                this.$refs.viewer.toggleAnnotationClass(this.addingAnnotation)
            }
            this.headerTipRendered = true
        },
        cancelAnnotation (): void {
            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                status: false,
                type: RightSideBar.DEFAULT,
            })

            if (this.selectedAnnotation?.uuid) {
                this.$refs.viewer.hideAnnotation(this.selectedAnnotation.uuid)
            }

            this.$store.commit('drawingMarkup/CLEAR_SELECTED_ANNOTATION')
        },
        async annotationAdded (): Promise<void> {
            const addedAnnotation = this.selectedAnnotation
            await this.applyFilters(addedAnnotation.type)
            this.$store.commit('drawingMarkup/SET_ANNOTATION_ADDED', false)
        },
        annotationDeleted (uuid: string): void {
            this.$refs.viewer.hideAnnotation(uuid)
        },
        async fetchMentionUsers (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const { data } = await setsClient.getUsersWithAccess(this.activeDrawingSet ?? this.model.drawing.drawing_set_id, this.projectId)
                this.mentionUsers = data
            })
        },
        async onConfirmDownloadAlert (): Promise<void> {
            await this.$refs.viewer.instance.UI.downloadPdf({
                includeAnnotations: this.includeMarkups,
                flatten: true,
            })

            this.downloadAlertShown = false
        },
        cancelAddingAnnotation (): void {
            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                status: false,
                type: RightSideBar.DEFAULT,
            })

            this.$refs.viewer.removeStamp()
            this.clearPreservedPunchItem()
        },
        closeSelectStampDropdown (): void {
            this.$store.commit('drawingMarkup/SET_SELECT_STAMP_POPUP', false)
            this.$store.commit('drawingMarkup/SET_SELECTED_STAMP', {})
        },
        chooseSelectStamp (val: unknown): void {
            this.stamp = val
            const stamp = {
                id: val.id,
                color: val.color,
                code: val.initials,
                name: val.title,
            }

            this.$store.commit('drawingMarkup/SET_SELECTED_STAMP', stamp)
            this.$store.commit('drawingMarkup/SET_ADDING_ANNOTATION', {
                status: true,
                type: RightSideBar.PUNCH,
            })
            this.$store.commit('drawingMarkup/SET_SELECT_STAMP_POPUP', false)
        },
        async addNewPunchItem (drawingType: PunchDrawingType): Promise<void> {
            const modalData = {
                task_item_id: {
                    _id: this.projectId,
                    _name: this.projectName,
                    id: this.projectId,
                    type: 1,
                },
                task_type: 9,
                task_type_id: 'punch_item',
                task_type_field: 'punch_item',
                drawing_type: drawingType,
            }

            await this.$store.dispatch('modal/setAdditionalData', modalData)
            await this.$store.dispatch('modal/setShow', 'modal-task', { root: true })
            this.$store.commit('drawingMarkup/SET_DRAWING_PIN', {})
            this.$store.commit('drawingMarkup/SET_SELECT_SHEET_POPUP', false)
        },
        annotationChanged (annotation: { xfdf: string, uuid: string, type: string }): void {
            this.drawingReference = {
                id: null,
                xfdf: annotation.xfdf,
                uuid: annotation.uuid,
                version_id: this.model.drawing.current_version_id,
                drawing: this.model.drawing,
            }

            if (annotation.type === 'rfi') {
                this.isRfiSlideoutOpen = true
            }
        },
        punchItemSaved (punchItem: PunchItem, queued: boolean): void {
            queued ? this.queueNewPunchItem(punchItem) : this.clearPreservedPunchItem()
            this.annotationAdded()
        },
        queueNewPunchItem (punchItem: PunchItem): void {
            this.preservedPunchItem.status = punchItem.status
            this.preservedPunchItem.dueDate = punchItem.due_date
            this.preservedPunchItem.jobsiteLocations = punchItem.jobsite_locations
            this.closePunchItemSlideout()
            this.$store.commit('drawingMarkup/SET_SELECT_STAMP_POPUP', true)
        },
        clearPreservedPunchItem (): void {
            this.preservedPunchItem.status = null
            this.preservedPunchItem.dueDate = ''
            this.preservedPunchItem.jobsiteLocations = null
        },
        closePunchItemSlideout (): void {
            this.drawingReference = null
            this.stamp = null
        },
        closeRfiSlideout (): void {
            this.drawingReference = null
            this.isRfiSlideoutOpen = false
        },
    },
})
