<template>
    <PopupModal
        v-if="hasModalBeenLoaded"
        :width="1120"
        :zIndex="modalZIndex"
        :customClass="'io-invite-modal-wrapper'"
    >
        <template #header>
            <div class="io-popup-modal__icon">
                <slot name="header-icon">
                    <i class="icon-user-plus"></i>
                </slot>
            </div>
            <div class="io-popup-modal__title" data-testid="modal-title">{{ $t(customTitle) }}</div>
            <div
                class="io-popup-modal__close"
                @click.stop="onClosePopup"
            >
                <i class="icon-cross"></i>
            </div>
        </template>

        <template #default>
            <div class="io-invite-modal">
                <div class="io-invite-modal__main-content" ref="mainContent">
                    <Tabs
                        v-if="isTabsActive"
                        placement="right"
                        :tabs="tabs()"
                        :active-tab="currentTabId"
                        :showTitle="false"
                        @click="onTabChange"
                    />

                    <InfoPanel
                        :componentKey="componentKey"
                        :disable-bookmarking="disableBookmarking"
                        :disable-inviting="disableInviting"
                        :employees-only="isModalEmployeesOnly"
                        @infoPanelExpanded="infoPanelExpanded"
                    />

                    <div class="io-invite-modal__search-wrapper">
                        <div class="io-invite-modal__search-label">
                            {{ $t(searchInputLabel) }}
                        </div>

                        <SearchInputNew
                            ref="searchInput"
                            icon-position="left"
                            placeholder="Type..."
                            :loader="false"
                            :searchVal="search"
                            :searchDisabled="shouldDisableSearch"
                            @searchChanged="onSearchChange"
                        />
                    </div>

                    <div class="io-invite-modal__contacts">
                        <div
                            v-if="!isFormVisible || isModalEmployeesOnly"
                            class="io-invite-modal__contacts-list"
                            ref="contactsList"
                        >
                            <InitialView
                                v-if="!isLoading"
                                :componentKey="componentKey"
                                :current-tab-key="currentTabKey"
                                :disable-inviting="disableInviting"
                                :disable-bookmarking="disableBookmarking"
                                :disable-removing="disableRemoving"
                                :disable-replacing="disableReplacing"
                                :disable-multiple="disableMultiple"
                                :onlySearchWithValidEmail="onlySearchWithValidEmail"
                                :isModalEmployeesOnly="isModalEmployeesOnly"
                                :splashScreen="splashScreen"
                                :emptySearchSplashScreen="emptySearchSplashScreen"
                                :disableActionsOnCurrentWorkspaceContact="disableActionsOnCurrentWorkspaceContact"
                            />
                            <div
                                v-else
                                class="io-invite-modal__loading"
                            >
                                {{ $t('Fetching data...') }}
                            </div>
                        </div>
                        <AddContactDetails
                            v-else-if="!isModalEmployeesOnly"
                            :componentKey="componentKey"
                            :allowedClientsTypes="allowedClientsTypes"
                            :preselectedWorkspaceType="preselectedWorkspaceType"
                            :disableInviting="disableInviting"
                            :disableBookmarking="disableBookmarking"
                            @companyTypeToggled="onCompanyTypeToggle"
                        />

                        <div
                            v-if="shouldShowAddWithoutActionButton"
                            class="io-invite-modal__contacts-list-bottom"
                        >
                            <div class="io-invite-modal__contacts-list-bottom-content">
                                <div class="io-invite-modal__contacts-list-bottom-content-icon">
                                    <span class="icon-info"></span>
                                </div>
                                <div class="io-invite-modal__contacts-list-bottom-content-main">
                                    <label class="io-invite-modal__contacts-list-bottom-content-main-text">
                                        {{ $t('Don’t see the person you are trying to add?') }}
                                    </label>
                                    <label class="io-invite-modal__contacts-list-bottom-content-main-button">
                                        <a @click.stop="onAddingContact">{{ $t('Add new Contact') }}</a>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div class="io-invite-modal__aside">
                    <div class="io-invite-modal__aside-header">
                        <template v-if="!removeMode">
                            {{ $t('Selected') }}
                        </template>
                        <template v-else-if="removeMode">
                            {{ $t('Selected for Removal') }}
                            <StatusPill v-if="removedContacts.length" v-bind="removedCount"/>
                        </template>
                        <template v-else-if="replaceMode">
                            {{ $t('Selected for Replacement') }}
                            <StatusPill v-if="replacedContacts.length" v-bind="replacedCount"/>
                        </template>
                    </div>

                    <!-- Invited List -->
                    <div class="io-invite-modal__aside-content">
                        <Accordion
                            v-if="invitedContacts.length"
                            icon="icon-chevron-down"
                            :rotate-degrees="90"
                            rotate-direction="left"
                            icon-color="gray"
                            :title="$t('Invited')"
                            :open="true"
                            :status="invitedCount"
                            :show-line="false"
                        >
                            <div class="io-invite-modal__selected-container">
                                <SelectedContacts
                                    :componentKey="componentKey"
                                    :contacts="invitedContacts"
                                    :disableRemoval="disableInviteRemoval"
                                />
                            </div>
                        </Accordion>
                        <div
                            v-else-if="!disableInviting"
                            class="io-invite-modal__aside-empty"
                        >
                            <div class="io-invite-modal__aside-empty-title">{{ $t('Invited') }}</div>
                            <div class="io-invite-modal__aside-empty-content">
                                <div class="io-invite-modal__aside-empty-content-icon">
                                    <span class="icon-send-2"></span>
                                </div>
                                <div class="io-invite-modal__aside-empty-content-text">
                                    <h5>{{ $t('Nothing Here, Yet!') }}</h5>
                                    <span>{{ $t('Start by selecting users to collaborate on projects or specific items you assign them to.') }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- Bookmarked List -->
                        <Accordion
                            v-if="bookmarkedContacts.length"
                            icon="icon-chevron-down"
                            :rotate-degrees="90"
                            rotate-direction="left"
                            icon-color="gray"
                            :title="$t('Bookmarked')"
                            :open="true"
                            :status="bookmarkedCount"
                            :show-line="false"
                        >
                            <div class="io-invite-modal__selected-container">
                                <SelectedContacts
                                    :componentKey="componentKey"
                                    :contacts="bookmarkedContacts"
                                    :disableRemoval="disableBookmarkRemoval"
                                />
                            </div>
                        </Accordion>
                        <div
                            v-else-if="!disableBookmarking"
                            class="io-invite-modal__aside-empty"
                        >
                            <div class="io-invite-modal__aside-empty-title">{{ $t('Bookmarked') }}</div>
                            <div class="io-invite-modal__aside-empty-content">
                                <div class="io-invite-modal__aside-empty-content-icon">
                                    <span class="icon-bookmark"></span>
                                </div>
                                <div class="io-invite-modal__aside-empty-content-text">
                                    <h5>{{ $t('Nothing Here, Yet!') }}</h5>
                                    <span>{{ $t('Bookmark contacts for your private reference and to keep track of important connections.') }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- Removed List -->
                        <div v-if="removedContacts.length" class="io-invite-modal__selected-container">
                            <SelectedContacts
                                :componentKey="componentKey"
                                :contacts="removedContacts"
                                :disableRemoval="disableBookmarkRemoval"
                            />
                        </div>
                        <div
                            v-else-if="removeMode"
                            class="io-invite-modal__aside-empty"
                        >
                            <div class="io-invite-modal__aside-empty-content">
                                <div class="io-invite-modal__aside-empty-content-icon">
                                    <span class="icon-trash"></span>
                                </div>
                                <div class="io-invite-modal__aside-empty-content-text">
                                    <h5>{{ $t('How does it work?') }}</h5>
                                    <span>{{ $t('You have selected 5 projects. Please choose the project member you wish to remove from these projects. This action will remove the selected member from all chosen projects, affecting their access and involvement.') }}</span>
                                    <h5 class="mt-8">{{ $t('What happens next?') }}</h5>
                                    <span>{{ $t('In the next step, you will need to select a replacement for the removed member to ensure that all project tasks and responsibilities are reassigned appropriately.') }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- Replaced List -->
                        <div v-if="replacedContacts.length" class="io-invite-modal__selected-container">
                            <SelectedContacts
                                :componentKey="componentKey"
                                :contacts="replacedContacts"
                                :disableRemoval="disableBookmarkRemoval"
                            />
                        </div>
                        <div
                            v-else-if="replaceMode"
                            class="io-invite-modal__aside-empty"
                        >
                            <div class="io-invite-modal__aside-empty-content">
                                <div class="io-invite-modal__aside-empty-content-icon">
                                    <span class="icon-refresh-ccw"></span>
                                </div>
                                <div class="io-invite-modal__aside-empty-content-text">
                                    <h5>{{ $t('Nothing here, yet!') }}</h5>
                                    <span>{{ $t('Select the user you want to replace the selected person with.') }}</span>
                                    <span>{{ $t('This action will invite the selected member to all chosen projects.') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <IOButton
                variant="secondary"
                @click.stop="onClosePopup"
                :disabled="isLoading || isClosing"
            >
                {{ $t('Cancel') }}
            </IOButton>

            <template v-if="removeMode">
                <IOButton
                    :disabled="isLoading || disableSaveButton"
                    @click="onSave"
                >
                    {{ saveButtonCustomTitle || $t('Continue') }}
                </IOButton>
            </template>
            <template v-else>
                <IOButton
                    v-if="!isFormVisible"
                    @click="onSave"
                    :disabled="isLoading || isClosing || disableSaveButton"
                >
                    <span v-if="isClosing" class="fa fa-spinner fa-spin"></span> {{ saveButtonCustomTitle || $t('Save') }}
                </IOButton>
                <IOButton
                    v-else
                    @click="onSaveContact"
                    :disabled="!isContactFormValid || isLoading || isClosing"
                >
                    {{ $t('Save to Contacts List') }}
                </IOButton>
            </template>
        </template>
    </PopupModal>
</template>

<script lang="ts" src="./InviteModalV3.ts"></script>
<style src="./InviteModalV3.scss" lang="scss" scoped></style>
