import moment from 'moment'

const datepickerMixin = {
    methods: {
        /**
        * Fix utc correction
        * We have UTC date from server, but browser need date with local zone
        * Add or subtract local zone to UTC time, also get miliseconds to picker
        *
        * @type {number}
        */
        getUnixTimestampUTC (value) {
            if (typeof value === 'string') {
                value = moment.utc(value).unix()
            } else {
                /*
                // js timestamp & timezone offset
                let timeDifference = new Date(this.prepareTimestamp(value)).getTimezoneOffset()
                if (timeDifference >= 0) value = moment.unix(value).add(timeDifference, 'minutes').unix() // add
                else value = moment.unix(value).subtract(Math.abs(timeDifference), 'minutes').unix() // subtract
                */
            }
            // return UTC unix timestamp
            return value
        },
        prepareTimestamp (timestamp) {
            if (String(timestamp * 1000).length > 10) {
                // all good
                return timestamp
            } else {
                // not exacly - multiply
                return timestamp * 1000
            }
        }
    }
}

export default datepickerMixin