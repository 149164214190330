import { defineComponent } from 'vue'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource'
// @ts-ignore
import { eventBus } from '@/mixins/eventBus'
import lienWaiverMixin from '@/modules/projects/modules/apps/common/pay-apps/mixins/lienWaiverMixin'
import { mapActions, mapState } from 'vuex'

export default defineComponent({
    mixins: [lienWaiverMixin],
    computed: {
        ...mapState('financialApprovals', {
            resourceId: (state) => state.resourceId,
            currentResourceId: (state) => state.currentResourceId,
            activeResource: (state) => state.activeResource,
            currentAmendment: (state) => state.currentAmendment,
        }),
        resourceName (): string {
            switch (this.activeResource) {
            case Resource.BUDGET_APPROVAL:
                return 'Budget'
            case Resource.BUDGET_CHANGES:
                return 'Budget Change'
            case Resource.CHANGE_ORDERS:
                return 'Change Order'
            case Resource.AMENDMENTS:
                return this.resourceAmendmentName
            case Resource.CONTRACTS:
                return 'Contract'
            case Resource.INITIAL_CONTRACTS:
                return 'Initial Contract'
            case Resource.INVOICES:
                return 'Invoice'
            default:
                return ''
            }
        },
        projectSettingsRouting (): string {
            return {
                [Resource.BUDGET_APPROVAL]: 'project-settings-financial-approvals-budget-approval',
                [Resource.BUDGET_CHANGES]: 'project-settings-financial-approvals-budget-changes',
                [Resource.CONTRACT]: 'project-settings-financial-approvals-contracts',
                [Resource.INITIAL_CONTRACT]: 'project-settings-financial-approvals-initial-contracts',
                [Resource.CHANGE_ORDER]: 'project-settings-financial-approvals-change-orders',
                [Resource.AMENDMENT]: 'project-settings-financial-approvals-amendments',
                [Resource.INVOICES]: 'project-settings-financial-approvals-invoices-edit',
            }[this.activeResource]
        },
        resourceIsAmendment (): boolean {
            return this.activeResource === Resource.AMENDMENTS
        },
        resourceIsInitialAmendment (): boolean {
            return false === this.currentAmendment?.is_final
        },
        resourceAmendmentName (): string {
            if (!this.resourceIsAmendment) {
                return ''
            }

            return this.resourceIsInitialAmendment ? this.$tc('Amendment') : this.$tc('Final Amendment')
        }
    },
    methods: {
        ...mapActions('lienWaiverSettings', {
            loadLienWaiverSettings: 'loadLienWaiverSettings'
        }),
        redirectToProjectSettings (): void {
            this.$router.push({ name: this.projectSettingsRouting, params: { pid: this.$route.params.pid, id: '' } })
        },
        refreshResources (): void {
            eventBus.$emit('projectBudget/init')
            eventBus.$emit('budgetChange/fetchData')
            eventBus.$emit('changeorders/fetchData')
            eventBus.$emit('contract/fetchData')
            eventBus.$emit('payapps/fetchData')
            this.getLienWaiverSettingsMixin()
        }
    }
})
