<template>
    <LoadingScreen :custom-show-loading="loading || dataLoading"/>
    <ApprovalsWrapper
        v-if="!loading"
        ref="approvalsWrapper"
        :approvableResourceId="approvableResourceId"
        isClientOffSystem="!isClientOnSystem"
        v-model:approvalModalData="approvalModalData"
        @approved="refreshData"
        @rejected="handleRejection"
        @submitToClient="callSubmitToClientModal"
        @clientApproved="refreshData"
    >
        <template #default="{ resourceApprovals }">
            <div class="io-app-package-view-wrapper">
                <PageHeader :title="pageHeaderTitle" :route-back="backButtonLink">
                    <template #actions>
                        <TopBarActions
                            ref="appPackageActions"
                            :currentStep="currentStep"
                            :stepsCount="visibleSteps.length"
                            :allStepsCompleted="allStepsCompleted"
                            :resourceApprovals="resourceApprovals"
                            v-model:approvalModalData="approvalModalData"
                            @nextStep="nextStep"
                            @prevStep="prevStep"
                            @cancel="onCancelClick"
                            @refreshApprovableResourceData="refreshApprovableResourceData"
                        />
                    </template>
                    <template #tabs>
                        <Stepper
                            v-if="creating"
                            :isFloating="false"
                            :availableSteps="visibleSteps"
                            :shadow="false"
                            :no-content="true"
                            v-model="currentStep"
                            @onClickStep="onClickTab"
                        >
                            <template #default>
                                <StepperStep
                                    v-for="(step, index) in visibleSteps"
                                    :key="index"
                                    :step="index"
                                    :valid="step.valid"
                                    :title="step.title"
                                />
                            </template>
                        </Stepper>
                        <Tabs
                            v-else-if="appPackage.id"
                            :tabs="appPackageTabs"
                            :active-tab="currentStep"
                            :show-title="false"
                            @click="onClickTab"
                        />
                    </template>
                </PageHeader>
                <router-view 
                    :key="$route.name" 
                    :resourceApprovals="resourceApprovals"
                    v-model:approvalModalData="approvalModalData"
                    @markAsSubmittedToClient="initMarkAsSubmittedToClient"
                    @submitToClient="callSubmitToClientModal"
                    @initReject="initReject"
                />
            </div>
        </template>
    </ApprovalsWrapper>
</template>

<script lang="ts" src="./AppPackageViewTemplate.ts"></script>
<style lang="scss" src="./AppPackageViewTemplate.scss" scoped></style>
