<template>
    <div class="io-notifications-settings-dropdown" v-click-outside="closeDropdown" @click.stop="onToggleDropdown">
        <div class="io-dropdown__button"
             :class="{'is-open': optionsShown}"
             :style="dropdownWidth"
        >
            <!-- placeholder -->
            <span v-if="!value && !optionsShown" class="io-dropdown-placeholder">
                <template v-if="placeholder === 'USD'">
                    <img :src="getCurrencyCountryFlag(placeholder)" :alt="placeholder" style="height: 12px;">
                    <span>{{ placeholder }}</span>
                </template>
                <template v-if="placeholder === 'Group by...'">
                    <span>{{ placeholder }}</span>
                </template>
            </span>
            <!-- selected value -->
            <span v-else-if="!optionsShown" class="io-dropdown-value">
                <img :src="getCurrencyCountryFlag(groupBy)" :alt="groupBy" />
                <span>{{ groupBy }}</span>
            </span>
            <!-- search input -->
            <span v-else class="io-dropdown-search" @click.stop>
                <input v-model="search" placeholder="Search..." maxlength="5" />
            </span>

            <i class="fal fa-chevron-down"></i>
        </div>

        <transition name="fade">
            <div class="io-dropdown__list" v-if="optionsShown">
                <!--<div class="io-dropdown__mask" @click="closeDropdown"></div>-->
                <div class="io-dropdown__content">
                    <div class="io-dropdown__options">
                        <div
                            v-for="(option, index) in optionsSearched"
                            :key="index"
                            class="option"
                            :class="{ 'io-selected': value === option, 'io-invalid-currency': invalidCurrencies.includes(option) }"
                            @click.stop="onClickOption(option)"
                            v-tooltip="{ content: 'This conversion already exists', visible: invalidCurrencies.includes(option), delay: 0 }"
                        >
                            <img :src="getCurrencyCountryFlag(option)" :alt="option" />
                            <span>{{ option }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" src="./CurrencyDropdown.ts"></script>

<style lang="scss" src="./CurrencyDropdown.scss" scoped/>
