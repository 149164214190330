import { defineComponent, PropType } from 'vue'

import { Contact } from '@/components/invite-modal-v3/interfaces/InviteModalInterface'

import Avatar from '@/components/atoms/Avatar/Avatar.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'
import { mapActions, mapState } from 'pinia'
import { inviteModalStore } from '@/components/invite-modal-v3/store/inviteModalStore'

export default defineComponent({
    name: 'SingleContact',

    components: {
        StatusPill,
        Avatar,
        IOButton
    },

    props: {
        contact: { type: Object as PropType<Contact>, required: true },
        componentKey: { type: String, required: true },
        isActionsVisible: { type: Boolean, required: false, default: false },
        disableInviting: { type: Boolean, required: false, default: false },
        disableBookmarking: { type: Boolean, required: false, default: false },
        disableRemoving: { type: Boolean, required: false, default: false },
        disableReplacing: { type: Boolean, required: false, default: false },
        disableMultiple: { type: Boolean, required: false, default: false },
        disableRemoval: { type: Boolean, required: false, default: false },
        disableActionsOnCurrentWorkspaceContact: { type: Boolean, required: false, default: false },
    },

    computed: {
        ...mapState(inviteModalStore, {
            invitedContacts (store) {
                return store.invitedContacts(this.componentKey)
            },
            bookmarkedContacts (store) {
                return store.bookmarkedContacts(this.componentKey)
            },
            removedContacts (store) {
                return store.removedContacts(this.componentKey)
            },
            replacedContacts (store) {
                return store.replacedContacts(this.componentKey)
            },
        }),
        appTypeText (): string {
            if (this.isContactOffSystem) {
                return 'off-system'
            }

            return this.contact.workspace.app_type
        },

        workspaceClass (): string {
            if (this.isContactOffSystem) {
                return 'gray-light'
            }

            return this.getAppTypeStatusPillClass(this.contact.workspace.app_type)
        },

        isContactOffSystem (): boolean {
            return !this.contact.is_on_system
        },

        isContactArchivedOrPending (): boolean {
            return this.contact.is_archived || this.contact.is_pending
        },

        isContactRemovable (): boolean {
            return false === this.disableRemoval
        },

        isInvitationRemovable (): boolean {
            if (this.isContactBookmarked) {
                return true
            }

            return false !== this.contact.invitation?.can_delete
        },

        isContactNotSelected (): boolean {
            return this.invitedContacts.findIndex(item => item.id === this.contact.id) === -1
                && this.bookmarkedContacts.findIndex(item => item.id === this.contact.id) === -1
                && this.removedContacts.findIndex(item => item.id === this.contact.id) === -1
                && this.replacedContacts.findIndex(item => item.id === this.contact.id) === -1
        },

        isContactInvited (): boolean {
            return this.invitedContacts.findIndex(item => item.id === this.contact.id) > -1
        },

        isContactBookmarked (): boolean {
            return this.bookmarkedContacts.findIndex(item => item.id === this.contact.id) > -1
        },

        isContactRemoved (): boolean {
            return this.removedContacts.findIndex(item => item.id === this.contact.id) > -1
        },

        isContactReplaced (): boolean {
            return this.replacedContacts.findIndex(item => item.id === this.contact.id) > -1
        },

        actionsTooltipText (): string {
            if (!this.isActionsDisabled) {
                return ''
            }

            if (!this.contact.matches_all_filters) {
                return 'Contact does not match filter.'
            }

            if (this.disableActionsOnCurrentWorkspaceContact && this.contact.is_current_workspace_contact) {
                return 'User is already in Contact List.'
            }
        },

        isActionsDisabled (): boolean {
            if (!this.contact.matches_all_filters) {
                return true
            }

            return this.disableActionsOnCurrentWorkspaceContact && this.contact.is_current_workspace_contact;
        }
    },
    methods: {
        ...mapActions(inviteModalStore, [
            'setSearch',
            'setContact',
            'setSearchDisabled',
            'pushInvitedContact',
            'pushBookmarkedContact',
            'pushRemovedContact',
            'pushReplacedContact',
            'setUpdatingWorkspaceType',
            'removeFromSelectedContacts',
        ]),
        insertContactToInvited (): void {
            if (null === this.contact.workspace.app_type) {
                this.setSearch(this.contact.email, this.componentKey)
                this.setSearchDisabled(true, this.componentKey)
                this.setContact(this.contact, this.componentKey)
                this.setUpdatingWorkspaceType(true, this.componentKey)
            } else {
                this.pushInvitedContact(this.contact, this.componentKey)
            }
        },

        insertContactToBookmarked (): void {
            this.contact.is_bookmarked = true
            this.pushBookmarkedContact(this.contact, this.componentKey)
        },

        insertContactToRemoved (): void {
            this.contact.is_removed = true
            this.pushRemovedContact(this.contact, this.componentKey)
        },

        insertContactToReplaced (): void {
            this.contact.is_replaced = true
            this.pushReplacedContact(this.contact, this.componentKey)
        },

        removeContactFromSelected (): void {
            this.removeFromSelectedContacts(this.contact, this.componentKey)
        },
    }
})
