<template>
    <div class="io-files-header__box io-new-uploader-tabs" data-testid="files-tabs" :class="{ 'io-variant-skinny': isSkinnyVariant, 'io-variant-normal': !isSkinnyVariant }">
        <div
            v-for="tab in tabs"
            :key="tab.id"
            class="io-files-header__tab"
            :class="{ 'io-active': activeHeaderTab === tab.id }"
            @click="$emit('changeActiveHeaderTab', tab.id)"
        >
            {{ tab.name }}

            <StatusPill
                size="small"
                :variant="pillVariant(tab.id)"
                :text="tab.count"
            />
        </div>
    </div>
</template>

<script src="./FilesTabs.ts" lang="ts"></script>
<style src="./FilesTabs.scss" lang="scss" scoped></style>
