<template>
    <div
        class="io-files-module io-design-system"
        :class="{ 'io-is-inside-modal': showModalVersion, 'io-variant-skinny': isSkinnyVariant, 'io-list-view': !isGridView, 'io-has-files': my_documents_files.length }"
        @click="hideAddButtonDropdown"
    >
        <div class="io-files-header io-f-between">
            <div class="io-files-header__box">
                <template v-if="atLeastOneFileSelected">
                    <div class="io-files-module__actions-left">
                        <p class="io-selected-files-count">{{ selectedFilesText }}</p>

                        <IOButton size="small" variant="link" icon="icon-cross" @click="clearFilesSelection">
                            {{ $t('Clear Selection') }}
                        </IOButton>
                    </div>

                    <div class="io-files-module__actions-right io-bulk-actions">
                        <IOButton size="small" variant="link" icon="icon-cloud-download" @click="bulkDownload">
                            {{ $t('Download') }}
                        </IOButton>

                        <IOButton v-if="isBulkDeleteDisplayed" size="small" variant="link" icon="icon-trash-1" @click="bulkDelete">
                            {{ $t('Delete') }}
                        </IOButton>
                    </div>
                </template>

                <div v-else class="io-files-module__actions-left">
                    <div class="io-files-header__tab" :class="{'io-required': hasRequiredSign}">
                        {{ title || $t('Files') }}
                    </div>
                </div>

                <FilesTabs
                    v-if="isSkinnyVariant && !atLeastOneFileSelected && tabs.length"
                    :tabs="tabs"
                    :isSkinnyVariant="isSkinnyVariant"
                    :activeHeaderTab="activeHeaderTab"
                    @changeActiveHeaderTab="activeHeaderTab = $event"
                />
            </div>

            <div v-show="!selectedFilesCount" class="io-files-module__actions-right">
                <div v-if="showSearch && !showModalVersion" class="io-files-options__searchbox">
                    <input type="text" class="io-files-options__input" :placeholder="$t('Search') + '...'" v-model="searchKeyword"/>
                    <div class="io-files-options__search-icon">
                        <i v-if="searchKeyword === ''" class="far fa-search"></i>
                        <i v-else class="fal fa-times" @click="searchKeyword = ''"></i>
                    </div>
                </div>

                <ToggleSwitch
                    v-if="showViewToggle"
                    :options="toggleSwitchOptions"
                    :selectedItem="selectedView"
                    variant="white"
                    :group="arrayId"
                    size="small"
                    @change="toggleView"
                />

                <div
                    v-click-outside="hideAddButtonDropdown"
                    v-if="showUploader"
                    class="add-button-wrapper"
                    :class="{ 'io-disabled': hideAddFilesDropdown }"
                >
                    <IOButton
                        size="small"
                        icon="icon-plus"
                        variant="link"
                        @click.stop="showAddButtonDropdown"
                    >
                        {{ $t('Add Files') }} <i class="icon-chevron-down"></i>
                    </IOButton>
                    <div
                        :id="`teleport-actions-${arrayId}`"
                        class="add-button-dropdown"
                        :class="{'visible': addButtonDropdownVisible}"
                    >
                    </div>
                </div>
            </div>
        </div>

        <FilesTabs
            v-if="!isSkinnyVariant"
            :tabs="tabs"
            :isSkinnyVariant="isSkinnyVariant"
            :activeHeaderTab="activeHeaderTab"
            @changeActiveHeaderTab="activeHeaderTab = $event"
        />

        <FileUploader
            v-if="activeHeaderTab === 0 && showUploader"
            ref="ioFileUploader"
            :key="uploaderKey"
            :addToTemporary="addToTemporary"
            :inDocumentsUpload="inDocumentsUpload"
            :filesSection="filesSection"
            :subSection="subSection"
            :arrayId="arrayId"
            :modelType="modelType"
            :modelId="clonedModelId"
            :forceShared="forceShared"
            :defaultShare="defaultShare"
            :projectLocalId="projectLocalId"
            :validationOptions="validationOptions"
            :threadId="threadId"
            :multipleFiles="multipleFiles"
            :isVisible="my_documents_files.length === 0 && searchKeyword.length === 0"
            :enableFileExtensionFilteringInFileSelector="enableFileExtensionFilteringInFileSelector"
            @updateDocuments="onDocumentAddNewUploader"
            @reloadUploader="reloadUploader"
        />

        <LazyLoad
            v-if="isPhotoFolderRoot"
            :key="lazyLoadKey"
            :endpoint="endpoint"
            :fetchRecords="isPhotoFolderRoot"
            @onRecordsFetched="recordsFetched"
        >
            <template #content>
                <transition name="fade">
                    <SingleTabNew
                        :key="singleTabProps.key"
                        :arrayId="arrayId"
                        :addToTemporary="addToTemporary"
                        :toSync="singleTabProps.sync"
                        :fromTrash="singleTabProps.fromTrash"
                        :tabType="singleTabProps.type"
                        :modelType="modelType"
                        :showShared="showShared"
                        :hideShareColumn="hideShareColumn"
                        :forceShared="forceShared"
                        :defaultShare="defaultShare"
                        :modelId="clonedModelId"
                        :lockHotkeys="lockHotkeys"
                        :filesInNewTab="filesInNewTab"
                        :filesSection="filesSection"
                        :isProjectView="isProjectView"
                        :isDocumentsView="isDocumentsView"
                        :isGridView="isGridView"
                        :editable="editable"
                        :subSection="subSection"
                        :colorVariantLight="colorVariantLight"
                        :showNewFolderForm="createNewFolderInProjectPhotos"
                        :applySorting="applySorting"
                        :showModalVersion="showModalVersion"
                        :multipleFiles="multipleFiles"
                        @forceRefresh="onForceRefresh"
                        @tempFileRemove="tempFileRemoved"
                        @clearSearch="clearSearch"
                        @onDrop="onDrop"
                    />
                </transition>
            </template>
        </LazyLoad>

        <transition v-else name="fade">
            <SingleTabNew
                :key="singleTabProps.key"
                :arrayId="arrayId"
                :addToTemporary="addToTemporary"
                :toSync="singleTabProps.sync"
                :fromTrash="singleTabProps.fromTrash"
                :tabType="singleTabProps.type"
                :modelType="modelType"
                :showShared="showShared"
                :hideShareColumn="hideShareColumn"
                :forceShared="forceShared"
                :defaultShare="defaultShare"
                :modelId="clonedModelId"
                :lockHotkeys="lockHotkeys"
                :filesInNewTab="filesInNewTab"
                :filesSection="filesSection"
                :isProjectView="isProjectView"
                :isDocumentsView="isDocumentsView"
                :isGridView="isGridView"
                :editable="editable"
                :subSection="subSection"
                :colorVariantLight="colorVariantLight"
                :showNewFolderForm="createNewFolderInProjectPhotos"
                :applySorting="applySorting"
                :editableActions="editableActions"
                :searchKeyword="searchKeyword"
                :showModalVersion="showModalVersion"
                :multipleFiles="multipleFiles"
                :noFilesFiltering="noFilesFiltering"
                :variant="variant"
                @forceRefresh="onForceRefresh"
                @tempFileRemove="tempFileRemoved"
                @fileRemove="fileRemoved"
                @clearSearch="clearSearch"
                @onDrop="onDrop"
            />
        </transition>
    </div>
</template>

<script src="./files.js"></script>
<style lang="scss" src="./files.scss" scoped></style>
