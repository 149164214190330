import { ApiClient } from '@/api/api'
import { AxiosResponse } from 'axios'
import {
    CurrentInvoiceSettings,
    CurrentProjectSettings,
    IntegrationAccountingSyncResponse,
    IntegrationDetailsResponse,
} from '@/io-modules/integrations/interfaces/IntegrationInterface'
let client = ApiClient

export default {
    get (): Promise<AxiosResponse<IntegrationDetailsResponse>> {
        return client.get('integration-details')
    },
    getProjectSettings (projectId: string): Promise<AxiosResponse<CurrentProjectSettings>> {
        return client.get(`integrations/accounting/project-settings/${ projectId }`)
    },
    getInvoiceSettings (invoiceId: string): Promise<AxiosResponse<CurrentInvoiceSettings>> {
        return client.get(`integration/invoices/settings/data/${ invoiceId }`)
    },
    accountingValidateRecord (payload: object): Promise<AxiosResponse<IntegrationAccountingSyncResponse>> {
        return client.json('/accounting-integration/validate-record', payload)
    },
    accountingExportRecord (payload: object): Promise<AxiosResponse<IntegrationAccountingSyncResponse>> {
        return client.json(`/accounting-integration/export-record`, payload)
    },
}
