const Component403 = () => import(/* webpackChunkName: "errors" */ '@/modules/system/errors/403.vue')
const Component404 = () => import(/* webpackChunkName: "errors" */ '@/modules/system/errors/404.vue')
const Component410 = () => import(/* webpackChunkName: "errors" */ '@/modules/system/errors/410.vue')

export default [
    {
        path: '/404',
        name: '404',
        component: Component404,
        title: '404'
    },
    {
        path: '/403',
        name: '403',
        component: Component403,
        title: '403'
    },
    {
        path: '/invitation-not-found',
        name: 'invitation-not-found',
        component: Component410,
        title: 'Invitation Not Found'
    },
    {
        name: 'logout',
        path: '/auth/logout',
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('contactsList')
            localStorage.removeItem('employeesList')
            localStorage.removeItem('projectsList')
            localStorage.removeItem('proposalsList')
            localStorage.removeItem('tasksgeneralList')
            window.location.href = '/auth/logout'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: Component404,
        title: '404'
    }
]
