<template>
    <div class="io-contracts">
        <PageHeader :title="$t('Contracts')">
            <template #actions>
                <SearchInput
                    :placeholder="$t('Search...')"
                    :loader="false"
                    @searchChanged="searchTerm = $event"
                />

                <FiltersButton/>
            </template>
            <template #tabs>
                <Tabs
                    :active-tab="currentRouteTab"
                    :show-title="false"
                    :tabs="tabs"
                    @click="switchTab"
                />
            </template>
        </PageHeader>

        <ContractsListTable
            v-if="isMainContractsList"
            :key="ContractCategory.MAIN_CONTRACT"
            :contract-category="contractCategory"
        />

        <ContractsListTable
            v-else
            :key="ContractCategory.VENDOR_CONTRACT"
            :contract-category="contractCategory"
        />

    </div>
</template>

<script lang="ts" src="./ContractsList.ts"></script>

<style lang="scss" scoped src="./ContractsList.scss"></style>
