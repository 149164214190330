import { defineComponent } from 'vue'
import SelectWorkspaceType from '../select-workspace-type/SelectWorkspaceType.vue'

export default defineComponent({
    name: 'WorkspaceTypeForTheNewContact',
    components: {
        SelectWorkspaceType,
    },
    props: {
        componentKey: { type: String, required: true },
        preselectedWorkspaceType: { type: String, default: '' },
    },
    computed: {
        contactFormData () {
            return this.$store.getters['inviteModal/getContactFormData'](this.componentKey)
        },
        isPendingCompany (): boolean {
            return !!this.pendingWorkspaceType
        },
        pendingWorkspaceType (): string {
            return this.contactFormData.company?.pending_app_type || this.preselectedWorkspaceType
        },
        isOffSystemCompany (): boolean {
            return !!this.contactFormData.company?.off_system
        },
    },
})
