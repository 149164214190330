<template>
    <div v-if="!error && !loading">
        <div class="io-attached-invoices__header">
            <div class="io-attached-invoices__header__title">
                <span>{{ $t('Attached Invoices') }}</span>
                <StatusDot v-if="!areAttachedInvoices" variant="warning"/>
            </div>

            <!-- <IOButton
                v-if="areAttachedInvoices"
                variant="link"
                icon="icon-grid-14"
                @click=""
            >
                {{ $t('Manage columns') }}
            </IOButton> -->
            <IOButton
                v-if="isDraft"
                variant="link"
                @click="goToAttachInvoicesStep"
            >
                {{ $t('Attach Invoices') }}
            </IOButton>
        </div>

        <div v-if="!areAttachedInvoices && !error && !loading" class="io-attached-invoices__placeholder">
            {{ $t('You haven’t attached any invoices yet') }}
        </div>
        <div v-else class="io-attached-invoices__body">
            <DismissibleBanner
                v-if="attachedInvoicesList.length > 1"
                :header="bannerHeader"
                header-icon="page-break"
                :text="bannerText"
                session-key="app-packages-multi-funded-invoices"
            />

            <div
                v-for="group in attachedInvoicesList"
                :key="group.fund_id"
            >
                <template v-if="group.attached_invoices.length" >
                    <div class="io-attached-invoices__table-header">
                        <span>{{ group.fund_name }}</span>
                        <div class="io-attached-invoices__table-actions">
                            <!-- <IOButton
                                variant="link"
                                icon="icon-export"
                                @click="initExportToPdf"
                            >
                                {{ $t('Export to PDF') }}
                            </IOButton> -->
                            <!-- <IOButton
                                variant="link"
                                icon="icon-file-text"
                                @click=""
                            >
                                {{ $t('Cover Page') }}
                            </IOButton> -->
                        </div>
                    </div>

                    <div class="io-attached-invoices__table-content">
                        <Table
                            :headers="getTableHeaders"
                            :items="group.attached_invoices"
                        >
                            <template #tbody="{ sortedItems }">
                                <InvoiceTableRow
                                    v-for="item in sortedItems"
                                    :key="item.id"
                                    :item="item"
                                    :headers="getTableHeaders"
                                />
                            </template>
                        </Table>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div v-if="error || loading" class="io-details-wrapper__placeholder">
        <RequestedDataErrorPlaceholder :title="$t('Application package attached invoices')" :loading="loading" @reload="fetchAttachedInvoices" />
    </div>
</template>

<script lang="ts" src="./AttachedInvoicesTable.ts"></script>
<style lang="scss" src="./AttachedInvoicesTable.scss" scoped></style>
