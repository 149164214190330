import { defineComponent, PropType } from 'vue'
import DocumentsShow from '@/io-modules/documents/views/DocumentsShow.vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import ActionDropdown from '@/components/action-dropdown/ActionDropdown.vue'
import documentMixin from '@/modules/documents/details/mixins/document'
import { FileInterface } from '../../../../../../interfaces/components/files-new/FileInterface'

export default defineComponent({
    name: 'FilePreview',
    components: {
        DocumentsShow,
        IOModal,
        ActionDropdown,
    },
    props: {
        file: Object as PropType<FileInterface>
    },
    mixins: [documentMixin],
    emits: ['close'],
    computed: {
        projectId (): string {
            return this.$route.params.pid
        },

        documentId (): string {
            return this.file.id || this.file._id
        },
    },
    methods: {
        downloadFile (): void {
            this.downloadDocument(this.documentId)
        },
    },
})
