import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { Rfi, RfiPayload, Permissions } from '@/io-modules/rfis/interfaces/Rfi.ts'
import { Contact } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'

export default {
    getRfi: (projectId: string, rfiId: string): AxiosPromise<Rfi> =>
        client.get(`/ca/rfi/${ projectId }/rfis/${ rfiId }`),

    updateRfi: (projectId: string, rfiId: string, payload: RfiPayload): AxiosPromise<Rfi> =>
        client.put(`/ca/rfi/${ projectId }/rfis/${ rfiId }`, payload),

    changeBallInCourt: (projectId: string, rfiId: string, payload: { ball_in_court_id: string }): AxiosPromise<void> =>
        client.put(`/ca/rfi/${ projectId }/rfis/${ rfiId }/ball-in-court`, payload),

    createRfi: (projectIdLocal: string, payload: RfiPayload): AxiosPromise<Rfi> =>
        client.post(`ca/rfi/${ projectIdLocal }/rfis`, payload),

    submitRfi: (projectIdLocal: string, rfiId: string, payload: { ball_in_court_id: string }): AxiosPromise<void> =>
        client.put(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/submit`, payload),

    respondRfi: (projectIdLocal: string, rfiId: string, payload: { ball_in_court_id: string }): AxiosPromise<void> =>
        client.put(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/respond`, payload),

    closeRfi: (projectIdLocal: string, rfiId: string, payload: {
        impacted_parties_ids: string[]
    }): AxiosPromise<void> =>
        client.put(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/close`, payload),

    clarifyRfi: (projectIdLocal: string, rfiId: string, payload: { comment: string }): AxiosPromise<void> =>
        client.put(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/clarify`, payload),

    clarifySolution: (projectIdLocal: string, rfiId: string, payload: { comment: string }): AxiosPromise<void> =>
        client.put(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/clarify-solution`, payload),

    redistributeRfi: (projectIdLocal: string, rfiId: string, payload: {
        impacted_parties_ids: string[]
    }): AxiosPromise<void> =>
        client.post(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/redistribute`, payload),

    archiveRfi: (projectIdLocal: string, rfiId: string): AxiosPromise<void> =>
        client.post(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/archive`),

    unarchiveRfi: (projectIdLocal: string, rfiId: string): AxiosPromise<void> =>
        client.post(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/unarchive`),

    revertToDraft: (projectIdLocal: string, rfiId: string): AxiosPromise<void> =>
        client.put(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/revert-to-draft`),

    exportRfiUrl: (projectIdLocal: string, rfiId: string): string =>
        client.getApi2RequestUrl(`/ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/export-pdf`, '_blank', 'noopener'),

    getOfficialReviewers: (projectIdLocal: string): AxiosPromise<{ items: Contact[] }> =>
        client.get(`ca/rfi/${ projectIdLocal }/dictionaries/official-reviewers`),

    getManagers: (projectIdLocal: string): AxiosPromise<{ items: Contact[] }> =>
        client.get(`ca/rfi/${ projectIdLocal }/dictionaries/managers`),

    getPermissions: (): AxiosPromise<Permissions> =>
        client.get('ca/rfi/permissions'),

    getRevisions: (projectIdLocal: string, rfiId: string): AxiosPromise<{ items: Rfi[] }> =>
        client.get(`ca/rfi/${ projectIdLocal }/rfis/${ rfiId }/versions`),
}
