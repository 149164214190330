import { createI18n } from 'vue-i18n'
import { ApiClient } from '@/api/api'

// Todo will be loaded dynamically
const defaultLanguage = 'en'

async function loadLocaleMessages (locale, i18n) {
    try {
        const response = await ApiClient.get(`locale/json`)
        i18n.setLocaleMessage(locale, response.data)
    } catch (error) {
        console.error(`Failed to load translations: ${ error.message }`)
        return {}
    }
}

async function getUserLanguage () {
    return await ApiClient.getAuthData().then(response => {
        return response.data.user_language
    }).catch(err => {
        return defaultLanguage
    })
}


const i18n = new createI18n({
    locale: defaultLanguage,
    fallbackLocale: defaultLanguage,
    silentTranslationWarn: true,
    formatFallbackMessages: true,
    sync: false,
    messages: {},
})

export default i18n
