export const resetState = function ({ commit, dispatch }) {
    commit('RESET_STATE')
}

export const setFileUploading = function ({ commit, dispatch }, value) {
    commit('SET_FILE_UPLOADING', value || true)
}

export const setRecalculateUsedStorage = function ({ commit, dispatch }, value) {
    commit('SET_RECALCULATE_USED_STORAGE', value || true)
}
