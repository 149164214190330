import ForecastingStore from '@/interfaces/modules/projects/modules/forecasting/ForecastingStore'
import CashFlowStatus from '@/interfaces/modules/projects/modules/forecasting/CashFlowStatus'
import CashFlowCostCode from '@/interfaces/modules/projects/modules/forecasting/CashFlowCostCode'
import CashFlowMonth from '@/interfaces/modules/projects/modules/forecasting/CashFlowMonth'
import CurveType from '@/interfaces/modules/projects/modules/forecasting/CurveType'

export function currentCashFlowIsDraft (state: ForecastingStore): boolean {
    return CashFlowStatus.DRAFT === state.cashFlow?.status
}

export function summaryFilterCountIndicator (state: ForecastingStore): number {
    let count = 0

    if (state.summaryActualForecast.length < 2) {
        ++count
    }

    if (state.summaryCostTypes.length < 2) {
        ++count
    }

    if (0 < state.summaryVisibleCostCodeIds.length) {
        ++count
    }

    if (state.summaryRange.start || state.summaryRange.end) {
        ++count
    }

    return count
}

export function costCodeByBudgetId (state: ForecastingStore): (id: string, parentId: string | null) => CashFlowCostCode {
    return (id: string, parentId: string | null): CashFlowCostCode => {
        let costCodes = state.cashFlow.cost_codes

        if (parentId) {
            const parentCashFlowCostCode = state.cashFlow.cost_codes
                .find((costCode: CashFlowCostCode): boolean => costCode.cost_code_id === parentId)

            if (parentCashFlowCostCode) {
                costCodes = costCodes
                    .filter((costCode: CashFlowCostCode): boolean => costCode.parent_id === parentCashFlowCostCode.id)
            }
        }

        return costCodes
            .find((costCode: CashFlowCostCode): boolean => costCode.cost_code_id === id)
    }
}

export function subItemsPeriodValue (state: ForecastingStore): (ids: string[], budgetItemId: string, periodCode: string) => number {
    return (ids: string[], budgetItemId: string, periodCode: string): number => {
        let costCodes = state.cashFlow.cost_codes

        if (budgetItemId) {
            const parentCashFlowCostCode = state.cashFlow.cost_codes
                .find((costCode: CashFlowCostCode): boolean => costCode.cost_code_id === budgetItemId)

            if (parentCashFlowCostCode) {
                costCodes = costCodes
                    .filter((costCode: CashFlowCostCode): boolean => costCode.parent_id === parentCashFlowCostCode.id)
            }
        }

        const cashFlowSubItems = costCodes.filter(
            (costCode: CashFlowCostCode): boolean => ids.includes(costCode.cost_code_id)
        )

        const costCodePeriodValues = {}

        cashFlowSubItems.forEach((costCode: CashFlowCostCode): void => {
            const valueField = CurveType.CUSTOM === costCode.curve_type ? 'manual_amount' : 'forecasted_amount'
            costCodePeriodValues[costCode.id] = costCode.cost_code_periods
                .filter((period: CashFlowMonth): boolean => period.start_date.substring(0, 10) === periodCode)
                .map((period: CashFlowMonth): number => period[valueField] || 0)
        })

        const subItemsPeriodMonths = Object.values(costCodePeriodValues)
            .reduce(
                (carry: number[], values: number[]): number[] => [...carry, ...values],
                []
            )

        return subItemsPeriodMonths.reduce((carry: number, value: number): number => carry + value, 0)
    }
}
