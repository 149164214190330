import i18n from '@/base/i18n'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource'
import { ChangeOrderStatus } from '@/interfaces/modules/projects/change-orders/ChangeOrderStatus'
import { ContractApprovalStatus } from '@/interfaces/modules/contract/ContractApprovalStatus'
import iconLibrary from '@/base/iconLibrary'
import { isEmpty } from 'lodash'
import { FinancialApprovalStatus } from '@/interfaces/modules/projects/financial-approvals/FinancialApprovalStatus'
import FinancialApprovalsState from '@/interfaces/store/FinancialsApprovalsState'
import FinancialApprovalsGetters from '@/interfaces/store/FinancialApprovalsGetters'
import PhaseStatus from '@/interfaces/modules/projects/financial-approvals/PhaseStatus'
import ApprovalPhaseData from '@/interfaces/modules/projects/financial-approvals/ApprovalPhaseData'
import PhaseType from '@/interfaces/modules/projects/financial-approvals/PhaseType'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus'
import ClientApprovalSetting from '@/interfaces/modules/projects/financial-approvals/ClientApprovalSetting'
import ApproverAssignmentType from '@/interfaces/modules/projects/financial-approvals/ApproverAssignmentType'

export function canCountResourceTotalValue (state: FinancialApprovalsState, _getters: FinancialApprovalsGetters, rootState: any): boolean {
    if (Resource.CHANGE_ORDERS === state.activeResource) {
        return [
            ChangeOrderStatus.DRAFT,
            ChangeOrderStatus.REVIEW_RESUBMIT,
            ChangeOrderStatus.NOT_ASSIGNED
        ].includes(state.currentChangeOrder.co_status)
    }

    if (Resource.AMENDMENTS === state.activeResource) {
        return [
            ChangeOrderStatus.DRAFT,
            ChangeOrderStatus.REVIEW_RESUBMIT,
            ChangeOrderStatus.NOT_ASSIGNED
        ].includes(state.currentAmendment.co_status)
    }

    if (Resource.CONTRACTS === state.activeResource || Resource.INITIAL_CONTRACTS === state.activeResource) {
        return ContractApprovalStatus.DRAFT === state.currentContract.approvalStatus
    }

    if (Resource.INVOICES === state.activeResource) {
        return rootState.payapps.payApp.flags.can_edit_values
            || rootState.payapps.payApp.flags.can_adjust_values
            || rootState.payapps.payApp.flags.can_negotiate_adjustments
    }

    return false
}

export function resourceTotalValue (state: FinancialApprovalsState, _getters: FinancialApprovalsGetters, rootState: any): number | null {
    switch (state.activeResource) {
    case Resource.CHANGE_ORDERS:
        return state.currentChangeOrder?.co_amount + attachedChangeOrdersTotal(state.currentChangeOrder)
    case Resource.AMENDMENTS:
        return state.currentAmendment?.co_amount + attachedChangeOrdersTotal(state.currentAmendment)
    case Resource.INVOICES:
        return iconLibrary.methods.toFloat(rootState.payapps.calculatedTotals.net_current_application)
    default:
        return null
    }
}

export function lineItemsTotalsWithCostCode (state: FinancialApprovalsState, _getters: FinancialApprovalsGetters, rootState: any): any[] {
    const companyMongoId = rootState.appStore.authData.company_mongo_id

    switch (state.activeResource) {
    case Resource.CHANGE_ORDERS:
        return [
            ...(state.currentChangeOrder?.co_line_items || []),
            ...attachedChangeOrdersLineItems(state.currentChangeOrder)
        ].map(lineItem => ({ total: lineItem.total, costCode: lineItem.costCodeProject[companyMongoId] }))
    case Resource.AMENDMENTS:
        return [
            ...(state.currentAmendment?.co_line_items || []),
            ...attachedChangeOrdersLineItems(state.currentAmendment)
        ].map(lineItem => ({ total: lineItem.total, costCode: lineItem.costCodeProject[companyMongoId] }))
    case Resource.CONTRACTS:
        return state.currentContract?.phases
            .map(lineItem => ({ total: lineItem.value, costCode: lineItem.costCode && lineItem.costCode[companyMongoId] }))
    case Resource.INVOICES:
        return invoiceTotalsWithCostCodes(
            rootState.payapps.payApp,
            companyMongoId
        )
    default:
        return []
    }
}

export function noApprovalProcessInPlace (state: FinancialApprovalsState): boolean {
    return isEmpty(state.approvalWorkflows)
}

export function isManualApprovalWorkflow (state: FinancialApprovalsState, getters: FinancialApprovalsGetters): boolean {
    return getters.noApprovalProcessInPlace || !state.hasConfiguredWorkflows
}

export function allWorkflowsSequenced (state: FinancialApprovalsState): boolean {
    return state.approvalWorkflows.every(workflow => workflow.sequence_enabled)
}

export function noWorkflowSequenced (state: FinancialApprovalsState): boolean {
    return state.approvalWorkflows.every(workflow => !workflow.sequence_enabled)
}

export function isRejectedByApprover (state: FinancialApprovalsState): boolean {
    return state.approvalWorkflows.some(workflow => {
        return workflow.approvers.some(approver => {
            return approver.approval && (FinancialApprovalStatus.REJECTED === approver.approval.status)
        })
    })
}

export function isActiveMemberSelectedInApprovers (state: FinancialApprovalsState, getters: FinancialApprovalsGetters, rootState: any, rootGetters: any): boolean {
    const currentUserId = rootGetters['appStore/getAuthData'].u_mongo

    return state.approvalWorkflows.some(workflow => {
        workflow.approvers.some(approver => {
            return approver.temporary_employee
                ? approver.temporary_employee.user_mongo_id === currentUserId
                : approver.employee.user_mongo_id === currentUserId
        })
    })
}

export function workflowsWithPendingUserApproval (state: FinancialApprovalsState, getters: FinancialApprovalsGetters, rootState: any, rootGetters: any): any[] {
    if (!getters.isSubmittedForApproval) {
        return []
    }

    const currentUserId = rootGetters['appStore/getAuthData'].u_mongo

    return state.approvalWorkflows.filter(workflow => {
        return workflow.approvers.some(approver => {
            return (
                approver.approval
                && FinancialApprovalStatus.PENDING === approver.approval.status
                && approver.employee.user_mongo_id === currentUserId
            )
        })
    })
}

export function isInApprovalSequence (state: FinancialApprovalsState, getters: FinancialApprovalsGetters, rootState: any, rootGetters: any): boolean {
    const currentUserId = rootGetters['appStore/getAuthData'].u_mongo

    return state.approvalWorkflows.some(workflow => {
        return workflow.approvers.some(approver => {
            return approver.employee.user_mongo_id === currentUserId
        })
    })
}

export function isManualActionTaken (state: FinancialApprovalsState, getters: FinancialApprovalsGetters): boolean {
    return getters.isApprovedManually || getters.isRejectedManually
}

export function isLastApprover (state: FinancialApprovalsState, getters: FinancialApprovalsGetters, rootState: any, rootGetters: any): boolean {
    const pendingApprovers = state.approvalWorkflows
        .reduce((approvals, workflow) => [...approvals, ...workflow.approvers], [])
        .filter(approver => null === approver.approval || FinancialApprovalStatus.PENDING === approver.approval.status)

    if (1 !== pendingApprovers.length) {
        return false
    }

    return pendingApprovers[0].employee.user_mongo_id === rootGetters['appStore/getAuthData'].u_mongo
}

export function isApprovedInternally (state: FinancialApprovalsState): boolean {
    return !!state.internalApproval
}

export function isApprovedManually (state: FinancialApprovalsState): boolean {
    return !!state.manualApproval
}

export function isRejectedManually (state: FinancialApprovalsState): boolean {
    return !!state.manualRejection
}

export function isApprovedByClient (state: FinancialApprovalsState): boolean {
    return !!state.clientApproval
}

export function isFullyApproved (state: FinancialApprovalsState): boolean {
    return !!state.fullApproval
}

export function isFullyRejected (state: FinancialApprovalsState): boolean {
    return !!state.fullRejection
}

export function isInternallyRejected (state: FinancialApprovalsState): boolean {
    return !!state.internalRejection
}

export function isSubmittedForApproval (state: FinancialApprovalsState): boolean {
    return state.submittedForApproval
}

export function isLocked (state: FinancialApprovalsState, getters: FinancialApprovalsGetters): boolean {
    return getters.isSubmittedForApproval || getters.isApprovedInternally || getters.isFullyApproved
}

export function pendingUserApprovals (state: FinancialApprovalsState, getters: FinancialApprovalsGetters, rootState: any, rootGetters: any): any[] {
    const currentUserId = rootGetters['appStore/getAuthData'].u_mongo

    return getters.workflowsWithPendingUserApproval.map(({ name, approvers }) => {
        const approver = approvers.find(approver => approver.employee.user_mongo_id === currentUserId)
        return {
            workflowName: name,
            approverId: approver._id
        }
    })
}

export function userApproverAssignmentType (state: FinancialApprovalsState, getters: FinancialApprovalsGetters, rootState: any, rootGetters: any): ApproverAssignmentType | null {
    const currentUserId = rootGetters['appStore/getAuthData'].u_mongo

    for (const workflow of state.approvalWorkflows) {
        return workflow.approvers.find(approver => approver.employee.user_mongo_id === currentUserId)?.assignment_type
    }

    return ApproverAssignmentType.APPROVER
}

export function approveActionText (state: FinancialApprovalsState, getters: FinancialApprovalsGetters): string {
    return ApproverAssignmentType.APPROVER === getters.userApproverAssignmentType
        ? i18n.global.tc('Approve')
        : i18n.global.tc('Mark as Reviewed')
}

export function hasMultiplePendingUserApprovals (state: FinancialApprovalsState, getters: FinancialApprovalsGetters): boolean {
    return getters.pendingUserApprovals.length > 1
}

export function isActiveApprover (state: FinancialApprovalsState, getters: FinancialApprovalsGetters): boolean {
    return getters.pendingUserApprovals.length > 0
}

export function phases (state: FinancialApprovalsState): ApprovalPhaseData[] {
    return state.phases || []
}

export function isApprovedAsPhase (state: FinancialApprovalsState): boolean {
    const approvedPhasesTypes = (state.phases || [])
        .filter((phase: ApprovalPhaseData) => PhaseStatus.APPROVED === phase.status)
        .map((phase: ApprovalPhaseData) => phase.type)

    return approvedPhasesTypes.includes(PhaseType.PHASED) && !approvedPhasesTypes.includes(PhaseType.FINAL)
}

export function currentPhase (state: FinancialApprovalsState): ApprovalPhaseData | undefined {
    return [...(state.phases || [])]
        .filter((phase: ApprovalPhaseData) => PhaseStatus.APPROVED === phase.status)
        .sort((a: ApprovalPhaseData, b: ApprovalPhaseData) => a.created_at > b.created_at ? 1 : 0)[0]
}

export function newestPhase (state: FinancialApprovalsState): ApprovalPhaseData | undefined {
    return [...(state.phases || [])]
        .sort((a: ApprovalPhaseData, b: ApprovalPhaseData) => a.created_at > b.created_at ? 1 : 0)[0]
}

export function phaseSnapshot (state: FinancialApprovalsState): any {
    return state.phase?.snapshot
}

export function isSubmittingBlockedByExpiredInsurance (state: FinancialApprovalsState): boolean {
    return state.isSubmittingBlockedByExpiredInsurance
}

export function isApprovalBlockedByExpiredInsurance (state: FinancialApprovalsState): boolean {
    return state.isApprovalBlockedByExpiredInsurance
}

export function isExternalClientApprovalStatusPending (state: FinancialApprovalsState): boolean {
    return ApprovalStatus.PENDING === state.externalClientApproval?.status
}

export function isExternalClientApprovalStatusApproved (state: FinancialApprovalsState): boolean {
    return ApprovalStatus.APPROVED === state.externalClientApproval?.status
}

export function isExternalClientApprovalStatusRejected (state: FinancialApprovalsState): boolean {
    return ApprovalStatus.REJECTED === state.externalClientApproval?.status
}

export function isLoggedCompanyExternalClientApprovalReceiver (state: FinancialApprovalsState, _getters: any, _rootState: any, rootGetters: any): boolean {
    return rootGetters.appStore.getAuthData.company_mongo_id === state.externalClientApproval?.external_company_id
}

export function isManualRecordClientApprovalEnabled (state: FinancialApprovalsState): boolean {
    return ClientApprovalSetting.RECORD_APPROVAL === state.clientApprovalSetting
}

export function isSendToClientEnabled (state: FinancialApprovalsState): boolean {
    return ClientApprovalSetting.SEND_TO_CLIENT === state.clientApprovalSetting
}

export function currentResourceId (state: FinancialApprovalsState): string | null {
    return state.resourceId
}

export function currentChangeOrder (state: FinancialApprovalsState): any {
    return state.currentChangeOrder
}

export function isFromOtherParty (state: FinancialApprovalsState): boolean {
    return state.isFromOtherParty
}

function attachedChangeOrdersTotal (changeOrder: any): number {
    return changeOrder?.co_attached_change_orders?.reduce(
        (total, changeOrder) => total + changeOrder.co_amount,
        0
    ) || 0
}

function attachedChangeOrdersLineItems (currentChangeOrder: any): any[] {
    return currentChangeOrder?.co_attached_change_orders
        .reduce((lineItems, changeOrder) => [...lineItems, ...(changeOrder?.co_line_items || [])], []) || []
}

function invoiceTotalsWithCostCodes (payApp: any, companyMongoId: any): any[] {
    if (payApp.flags.is_non_contracted) {
        return payApp.specific.general_invoicable_items
            .map(lineItem => ({
                total: iconLibrary.methods.toFloat(lineItem.total),
                costCode: lineItem.costCode && lineItem.costCode[companyMongoId]
            }))
    }

    return payApp.quoted_items.phases
        .map(lineItem => ({
            total: iconLibrary.methods.toFloat(
                lineItem.tasks.reduce((total, task) => total + task._current_application, 0)
            ),
            costCode: lineItem.costCode && lineItem.costCode[companyMongoId]
        }))
}
