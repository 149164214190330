<template>
    <div class="io-single-comment" :class="{ 'io-selected': isCommentSelected, 'io-resolved': isCommentArchived, 'io-last': isLastComment }" @click="selectComment">
        <div class="io-single-comment__header">
            <div class="io-header-info">
                <i class="icon-comment-text"/>
                <span class="io-comment-number">
                    {{ $t('Comment no') + ': ' }}
                    <span class="io-comment-number__id">{{ data.internal_id }}</span>
                </span>
                <Tooltip :text="$t('This Comment will be visible to everyone who has access to this document file.')"/>
            </div>

            <div class="io-header-actions">
                <i
                    v-tooltip="{ content: $t('Mark as Resolved'), visible: !isCommentArchived }"
                    :class="isCommentArchived ? 'icon-checkbox-circle-2' : 'icon-checkbox-circle'"
                    @click="resolveComment"
                />

                <DropdownButtonNew v-if="!isCommentArchived" icon-mode fontAwesomeClass="icon-more-vert-2">
                    <div class="io-single-option" @click="turnOnEditMode">
                        <i class="icon-edit"/>
                        {{ $t('Edit') }}
                    </div>
                    <div class="io-single-option" @click="onDeleteComment(data.id)">
                        <i class="icon-trash"/>
                        {{ $t('Delete') }}
                    </div>
                </DropdownButtonNew>
            </div>
        </div>

        <div class="io-single-comment__content" :class="{ 'io-replies-exist': data.comment.replies.length > 1 }">
            <div class="io-content-info">
                <Avatar
                    :companyName="data.created_by.position"
                    :employeeMongoId="data.created_by.id"
                    :employeeFullName="data.created_by.name"
                    showFullName
                />

                <div class="io-date">{{ $filters.formatDate(data.created_at) }}</div>
            </div>

            <div v-if="editMode" class="io-edit-wrapper">
                <Mentions
                    :attached_employees_ids="uniqueMentionUsers"
                    :placeholder="$t('Add Comment...')"
                    :customPeopleList="uniqueMentionUsers"
                    :attachBelow="true"
                    :getByMongo="true"
                    :value="editedComment"
                    v-model="editedComment"
                    @update="onUpdateReply($event, 'editedSharedWith')"
                />

                <div class="io-buttons">
                    <IOButton variant="secondary" size="small" @click="cancelEditing">{{ $t('Cancel') }}</IOButton>
                    <IOButton size="small" :disabled="!editedComment.length" @click="saveComment">{{ $t('Save') }}</IOButton>
                </div>
            </div>

            <div v-else class="io-content-text" @click="turnOnReplyMode" v-html="data.comment.comment_text"></div>
        </div>

        <div v-if="isAddingReply" class="io-reply-wrapper">
            <Mentions
                :placeholder="$t('Add Reply...')"
                :attached_employees_ids="uniqueMentionUsers"
                :customPeopleList="uniqueMentionUsers"
                :attachBelow="true"
                :getByMongo="true"
                :value="replyText"
                v-model="replyText"
                @update="onUpdateReply($event, 'replySharedWith')"
            />

            <div class="io-buttons">
                <IOButton variant="secondary" size="small" @click="turnOffReplyMode">{{ $t('Cancel') }}</IOButton>
                <IOButton size="small" :disabled="!replyText" @click="addReply">{{ $t('Post') }}</IOButton>
            </div>
        </div>

        <div v-if="data.comment.replies.length > 1 || allRepliesVisible" class="io-replies-divider">
            <div @click="toggleRepliesVisibility">{{ repliesVisibilityText }}</div>
        </div>

        <div v-if="data.comment.replies.length" class="io-replies">
            <Reply
                v-for="reply in replies"
                :key="reply.id"
                :replyData="reply"
                :isParentCommentArchived="isCommentArchived"
                :mentionUsers="uniqueMentionUsers"
                @delete-reply="onDeleteComment($event.id, $event.isReply)"
                @save-reply="saveComment($event)"
            />
        </div>
    </div>
</template>

<script lang="ts" src="./SingleComment.ts"></script>
<style lang="scss" src="./SingleComment.scss" scoped></style>
