import { defineComponent } from 'vue'
import { DotVariant, DotSize } from './enums/StatusDotEnums'

interface Tooltip {
    text: ''
    left?: boolean
    right?: boolean
    top?: boolean
    bottom?: boolean
    start?: boolean
    end?: boolean
}

export default defineComponent({
    name: 'StatusDot',
    props: {
        variant: { type: String as () => DotVariant, required: true },
        size: { type: String as () => DotSize, default: DotSize.NORMAL },
        text: { type: String, default: '' },
        tooltip: { type: Object as () => Tooltip, default: null },
    },
    computed: {
        variantClassName (): string {
            return `io-${ this.variant }`
        },
        sizeClassName (): string {
            return `io-${ this.size }-size`
        },
        dotClasses (): string[] {
            return [this.variantClassName, this.sizeClassName]
        },
    },
})
