export default {
    path: 'budget/:budget_id/budget-change',
    redirect: { name: 'budget-change-creation' },
    name: 'budget-changes',
    meta: {
        name: 'Budget Changes',
        title: 'Project Contracts',
        ignoreProjectLayout: true,
        navigationKey: 'budget-change',
    },
    children: [
        {
            path: 'creation',
            name: 'budget-change-creation',
            component: () => import('@/io-modules/budget-changes/views/budget-change-wizard/BudgetChangeWizard.vue'),
            meta: {
                name: 'Budget Change Creation',
                title: 'Budget Change Creation',
                ignoreProjectLayout: true,
                navigationKey: 'budget-change-creation',
            },
        },
        {
            path: ':budget_change_id/edition',
            name: 'budget-change-edition',
            component: () => import('@/io-modules/budget-changes/views/budget-change-wizard/BudgetChangeWizard.vue'),
            meta: {
                name: 'Budget Change Creation',
                title: 'Budget Change Creation',
                ignoreProjectLayout: true,
                navigationKey: 'budget-change-creation',
            },
        },
        {
            path: ':budget_change_id',
            component: () => import('@/io-modules/budget-changes/views/budget-change-view/BudgetChangeView.vue'),
            meta: {
                name: 'Budget Change Details',
                title: 'Budget Change Details',
                ignoreProjectLayout: true,
                navigationKey: 'budget-change-details',
            },
            children: [
                {
                    path: 'details',
                    name: 'budget-change-v3-details',
                    component: () => import('@/io-modules/budget-changes/components/budget-change-view-details/BudgetChangeViewDetails.vue'),
                    meta: {
                        name: 'Budget Change Details',
                        title: 'Budget Change Details',
                        ignoreProjectLayout: true,
                        navigationKey: 'budget-change-details',
                    },
                },
                {
                    path: 'documents',
                    name: 'budget-change-v3-documents',
                    component: () => import('@/io-modules/budget-changes/components/budget-change-view-documents/BudgetChangeViewDocuments.vue'),
                    meta: {
                        name: 'Budget Change Documents',
                        title: 'Budget Change Documents',
                        ignoreProjectLayout: true,
                        navigationKey: 'budget-change-documents',
                    },
                },
                {
                    path: 'approvals',
                    name: 'budget-change-v3-approvals',
                    component: () => import('@/io-modules/budget-changes/components/budget-change-view-approvals/BudgetChangeViewApprovals.vue'),
                    meta: {
                        name: 'Budget Change Approvals',
                        title: 'Budget Change Approvals',
                        ignoreProjectLayout: true,
                        navigationKey: 'budget-change-approvals',
                    },
                },
                {
                    path: 'timeline',
                    name: 'budget-change-v3-timeline',
                    component: () => import('@/io-modules/budget-changes/components/budget-change-view-timeline/BudgetChangeViewTimeline.vue'),
                    meta: {
                        name: 'Budget Change Timeline',
                        title: 'Budget Change Timeline',
                        ignoreProjectLayout: true,
                        navigationKey: 'budget-change-timeline',
                    },
                },
            ]
        },
    ],
}
