import { computed, defineComponent } from 'vue'
import { Tab } from '@/components/atoms/tabs/TabInterface.ts'
import ContractCategory from '@/io-modules/contracts/enums/ContractCategory.ts'
import ContractListChildrenRouteName from '@/io-modules/contracts/enums/ContractListChildrenRouteName.ts'
import { FilterInterface } from '@/interfaces/components/lazy-list/FilterInterface'
import Tabs from '@/components/atoms/tabs/Tabs.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import ContractsListTable
    from '@/io-modules/contracts/components/contracts-list-table/ContractsListTable.vue'

export default defineComponent({
    name: 'ContractsList',
    components: {
        Tabs,
        SearchInput,
        ContractsListTable,
    },
    provide () {
        return {
            searchTerm: computed(() => this.searchTerm),
        }
    },
    setup () {
        return {
            ContractCategory,
        }
    },
    data () {
        return {
            currentRouteTab: this.$route.name,
            searchTerm: '',
            filtersSchema: [
                {
                    field: 'status',
                    label: 'Status',
                    type: 'status',
                    additional: {
                        hideSelectAllStatus: true,
                    },
                },
                {
                    field: 'date',
                    label: 'Upload date',
                    type: 'date',
                },
            ] as FilterInterface[],
        }
    },
    computed: {
        tabs (): Tab[] {
            return [
                {
                    key: ContractListChildrenRouteName.Main,
                    title: this.$t('Your main contracts'),
                },
                {
                    key: ContractListChildrenRouteName.Vendor,
                    title: this.$t('Vendor contracts'),
                },
            ]
        },
        isMainContractsList (): boolean {
            return this.currentRouteTab === ContractListChildrenRouteName.Main
        },
        isVendorContractsList (): boolean {
            return this.currentRouteTab === ContractListChildrenRouteName.Vendor
        },
        contractCategory (): ContractCategory {
            switch (this.currentRouteTab) {
            case ContractListChildrenRouteName.Main:
                return ContractCategory.MAIN_CONTRACT
            default:
                return ContractCategory.VENDOR_CONTRACT
            }
        },
    },
    watch: {
        $route (): void {
            this.currentRouteTab = this.$route.name
        },
    },
    methods: {
        async switchTab (index: number): void {
            await this.$router.push({ name: this.tabs[index].key })
        },
    },
})
