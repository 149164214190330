import { InviteModalState } from '@/components/invite-modal-v3/interfaces/InviteModalInterface.ts'

export default {
    isLoading: false,
    isClosing: false,
    contactsList: [],
    matchedMyContacts: [],
    matchedAccounts: [],
    matchedDomains: [],
    existingWorkspaces: [],
    initialInvitedContacts: [],
    initialBookmarkedContacts: [],
    invitedContacts: [],
    bookmarkedContacts: [],
    removedContacts: [],
    replacedContacts: [],
    addedToInvited: [],
    addedToBookmarked: [],
    removedFromInvited: [],
    removedFromBookmarked: [],
    isInfoPanelVisible: true,
    companiesList: [],
    countriesList: [],
    statesList: [],
    contact: {
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        action_type: null,
        is_new_company: false,
        workspace: {
            id: null,
            name: null,
            app_type: null,
            division_id: null,
            address_line1: null,
            address_line2: null,
            zip: null,
            city: null,
            state_id: null,
            country_id: null,
        }
    },
    search: '',
    resourceId: '',
    resourceType: '',
    role: '',
    invitationName: '',
    projectGlobalId: null,
    isContactFormValid: false,
    isIntentionallyAddingContact: false,
    isUpdatingWorkspaceType: false,
    isSearchDisabled: false,
    isFormVisible: false,
    selectedWorkspaceType: '',
    filters: null,
    isPaginating: false,
    pagination: null,
    skip: 0,
    take: 30,
    lastTaken: null,
} as InviteModalState
