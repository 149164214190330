export const SET_CHAT_WINDOW_COMPONENT = (state, val) => {
    state.showChatWindowComponent = val
}

export const CLEAR_CHAT_CONFIG = (state) => {
    state.chatConfig = {}
}

export const SET_CHAT_CONFIG = (state, val) => {
    state.chatConfig = val
}

export const SET_CHAT_EXPANDED = (state, val) => {
    state.chatExpanded = val
}

export const SET_UNREAD_MESSAGES = (state, val) => {
    state.unreadMessages = val
}

export const SET_NOTIFICATION_ID = (state, val) => {
    state.notificationId = val
}
