import { defineComponent } from 'vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import SingleApprover from '../single-approver/SingleApprover.vue'
import SingleApproverV2 from '../single-approver-v2/SingleApproverV2.vue'
import Draggable from 'vuedraggable'
import { Workflow } from '@/interfaces/modules/projects/financial-approvals/Workflow'
import { Approver } from '@/interfaces/modules/projects/financial-approvals/Approver'
import CostCodesDropdown from '../cost-codes-dropdown/CostCodesDropdown.vue'
import { Employee } from '@/interfaces/modules/projects/financial-approvals/Employee'
import { Code } from '@/interfaces/modules/projects/financial-approvals/Code'
import { CostCodeCategory } from '@/interfaces/modules/projects/financial-approvals/CostCodeCategory'
import WorkflowTemplatesPopup from '../popups/workflow-templates-popup/WorkflowTemplatesPopup.vue'
import { isArray, cloneDeep, head, filter, find, isNil } from 'lodash'
import { WorkflowTemplate } from '@/interfaces/modules/projects/financial-approvals/WorkflowTemplate'
import { ApproverTemplate } from '@/interfaces/modules/projects/financial-approvals/ApproverTemplate'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import { Role } from '@/interfaces/modules/projects/financial-approvals/Role'
import { ApproverType } from '@/interfaces/modules/projects/financial-approvals/ApproverType'
import AssignedCostCodesPopup from '../popups/assigned-cost-codes-popup/AssignedCostCodesPopup.vue'
import ApproverAssignmentType from '@/interfaces/modules/projects/financial-approvals/ApproverAssignmentType'
import ApprovalStatus from '@/interfaces/modules/projects/financial-approvals/ApprovalStatus'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'
import BudgetCostCodeSelect from '@/components/budget-cost-code-select/BudgetCostCodeSelect.vue'
import BudgetType from '@/interfaces/modules/projects/modules/common/budget/BudgetType.ts'
import { Category } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'

export default defineComponent({
    name: 'SingleWorkflow',
    components: {
        IOButton,
        SingleApprover,
        SingleApproverV2,
        Draggable,
        CostCodesDropdown,
        WorkflowTemplatesPopup,
        AlertBox,
        AssignedCostCodesPopup,
        BudgetCostCodeSelect,
    },
    props: {
        data: { type: Object as () => Workflow },
        removeEnable: { type: Boolean, required: false },
        edit: { type: Boolean, required: true, default: false },
        costCodesCategories: { type: Array as () => Category[], required: true },
        lockedCostCodes: { type: Array as () => Code[], required: false },
        unassignedCostCodes: { type: Array as () => Code[], required: false },
        costCodesEnabled: { type: Boolean, required: true },
        employees: { type: Array as () => Employee[], required: true },
        roles: { type: Array as () => Role[], required: false },
        individualResourceView: { type: Boolean, required: false, default: false },
        defaultWorkflow: { type: Boolean, required: false, default: true },
        allowTemplates: { type: Boolean, required: false, default: true },
        allowLimitEdit: { type: Boolean, required: false, default: true },
        allowSequencing: { type: Boolean, required: false, default: true },
        allowCostCodeSelection: { type: Boolean, required: false, default: true },
        costCodesUpdated: { type: Boolean, default: false },
    },
    emits: ['update:costCodesUpdated', 'remove-workflow', 'update:dataCostCodes'],
    data () {
        return {
            notSelectedEmployees: [] as Employee[],
            showWorkflowTemplatesPopup: false as boolean,
            showAssignedCostCodesPopup: false as boolean,
            ApprovalStatus,
            BudgetType,
        }
    },
    computed: {
        ...mapState('projectBudget', {
            templates: (state: any) => state.approval_workflow_templates,
            loadedConfiguration: (state: any) => state.loadedConfiguration,
        }),
        ...mapState('cached', {
            employeesCachedList: (state: any) => state.lists.employees,
        }),
        ...mapState('project', {
            projectIdLocal: (state: any) => state.projectObj.project_local_id,
        }),
        ...mapGetters('appStore', {
            isOwnerRep: 'isOwnerRep',
        }),
        assignedCostCodesIds (): number[] {
            if (!isArray(this.data.cost_codes)) {
                return []
            }
            return this.data.cost_codes.map(code => code.cost_code_id)
        },
        assignedCostCodesCategories (): CostCodeCategory[] {
            if (!isArray(this.data.cost_codes)) {
                return []
            }

            const matchingCodes = []

            const searchBudgetCodes = (categories) => {
                categories.forEach(category => {
                    if (category.budget_cost_codes) {
                        const matches = category.budget_cost_codes.filter(code => this.assignedCostCodesIds.includes(code.id))
                        matchingCodes.push(...matches)
                    }
                    if (category.subcategories) {
                        searchBudgetCodes(category.subcategories)
                    }
                })
            }

            searchBudgetCodes(cloneDeep(this.costCodesCategories))
            return matchingCodes
        },

        workflowName (): string {
            return this.data.name
        },

        costCodesCount (): number {
            return this.data.cost_codes.length
        },

        templateApplied (): boolean {
            return !isNil(this.data.template_workflow_id)
        },

        appliedTemplate (): WorkflowTemplate | undefined {
            if (!this.templateApplied) {
                {
                    return
                }
            }
            return this.templates.find(template => this.data.template_workflow_id === template._id)
        },

        unassignedApprovers (): Approver[] {
            return this.data.approvers.filter(approver => approver.is_unassigned)
        },

        unassignedApproversCount (): number {
            return this.unassignedApprovers.length
        },

        unassignedApproverDepratmentTypeLinkEnabled (): any {
            const unassignedApprover = head(this.unassignedApprovers) as Approver

            return unassignedApprover &&
                (unassignedApprover.template?.approver_type === ApproverType.ROLE
                    || unassignedApprover.template?.approver_type === ApproverType.EXTERNAL_ARCHITECTS_TYPE)
                && unassignedApprover.approvers_to_assign?.length === 0
        },

        unassignedUserApproversCount (): number {
            return filter(this.unassignedApprovers, (unassignedApprover) => {
                return unassignedApprover.template?.approver_type === ApproverType.USER
            }).length
        },

        firstFullNameOfUnassignedUserApprovers (): string | undefined {
            const { approver_id } = find(this.unassignedApprovers, (unassignedApprover) => {
                return unassignedApprover.template?.approver_type === ApproverType.USER
            })?.template as ApproverTemplate

            return this.employeesCachedList.find(employee => employee._id === approver_id)?.full_name
        },

        unassignedRoleApproversCount (): number {
            return filter(this.unassignedApprovers, (unassignedApprover) => {
                return (unassignedApprover.template?.approver_type === ApproverType.ROLE
                    || unassignedApprover.template?.approver_type === ApproverType.EXTERNAL_ARCHITECTS_TYPE)
                    && unassignedApprover.approvers_to_assign?.length === 0
            }).length
        },

        firstRoleNameOfUnassignedRoleApprovers (): string | undefined {
            return find(this.unassignedApprovers, (unassignedApprover) => {
                return (unassignedApprover.template?.approver_type === ApproverType.ROLE
                    || unassignedApprover.template?.approver_type === ApproverType.EXTERNAL_ARCHITECTS_TYPE)
                    && unassignedApprover.approvers_to_assign?.length === 0
            })?.role_name
        },

        routeProjectDirectory (): any {
            return this.$router.resolve({ name: 'project-directory', params: { pid: this.$route.params.pid } })
        },

        alertBoxText (): string | undefined {
            if (this.unassignedApproversCount === 0) {
                return
            }
            const unassignedUserApproversCount = this.unassignedUserApproversCount

            if (unassignedUserApproversCount > 0) {
                if (unassignedUserApproversCount > 1) {
                    return this.$t('You have multiple approvers not assigned to this project. Please assign another team member below.')
                }

                return this.$t('Approver {firstFullNameOfUnassignedUserApprovers} is not assigned to this project. Please assign another team member below.', { firstFullNameOfUnassignedUserApprovers: this.firstFullNameOfUnassignedUserApprovers })
            }

            const unassignedRoleApproversCount = this.unassignedRoleApproversCount
            const { role_name } = head(this.unassignedApprovers) as Approver

            if (unassignedRoleApproversCount > 0) {
                if (unassignedRoleApproversCount > 1) {
                    return `${ this.$t('You have multiple roles not assigned to this project.') }
                            ${ this.$t('Please assign these roles in') } <a href="${ this.routeProjectDirectory.href }">‘${ this.$t('My Project Directory') }’</a>.`
                }

                return `${ this.$t('You do not have any {firstRoleNameOfUnassignedRoleApprovers} assigned to this project.', { firstRoleNameOfUnassignedRoleApprovers: this.firstRoleNameOfUnassignedRoleApprovers }) }
                        ${ this.$t('Please assign one in') } <a href="${ this.routeProjectDirectory.href }">‘${ this.$t('My Project Directory') }’</a>.`

            }

            return this.$t('You have multiple {role_name} assigned to this project. Please assign one below.', { role_name })
        },
        selectedCostCodes: {
            get (): { id: string }[] {
                return this.data?.cost_codes.map(code => ({
                    id: code.cost_code_id,
                })) || []
            },
            set (selectedCodes: { id: string }[]): void {
                this.$emit('update:costCodesUpdated', this.data?.cost_codes.length)
                this.$emit('update:dataCostCodes', {
                    ...this.data,
                    cost_codes: selectedCodes.map(code => ({
                        cost_code_id: code.id
                    }))
                })
            }
        }
    },
    watch: {
        data: {
            handler (_newValue, _oldValue): void {
                this.setNotSelectedEmployees()
            },
            immediate: true,
            deep: true,
        },
        'data.cost_codes': {
            handler (): void {
                this.$emit('update:costCodesUpdated', this.data?.cost_codes.length)
            },
            immediate: true,
            deep: true
        },
        defaultWorkflow (): void {
            const noLimitApproverExist = this.data.approvers.find((approver: Approver) => approver.limit_type === 'no_limit' || approver.limit_type === null)

            if (!noLimitApproverExist) {
                this.addApprover()
            }
        },
    },
    mounted () {
        this.notSelectedEmployees = this.employees
        this.setNotSelectedEmployees()
    },
    methods: {
        ...mapActions('projectBudget', {
            getConfiguration: 'getConfiguration',
        }),


        handleApplyTemplate (): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to Apply Template?'),
                caption: this.$t('Changes made to this workflow will be overwritten by the chosen template.'),
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Apply Template'),
                        class: 'io-btn-primary',
                        action: async () => await this.openWorkflowTemplatesPopup()
                    }
                ]
            })
        },

        applyTemplate (template: WorkflowTemplate): void {
            this.data['sequence_enabled'] = template.sequence_enabled
            this.data['limit_enabled'] = template.limit_enabled
            this.data['template_workflow_id'] = template._id
            this.data['approvers'] = []
            this.applyApprovers(template.approvers)
            this.closeWorkflowTemplatesPopup()
        },

        applyApprovers (approvers: ApproverTemplate[]): void {
            approvers.forEach(approver => {
                const { approver_type } = approver

                if (approver_type === ApproverType.USER) {
                    this.addEmployeeTypeApprover(approver)
                }

                if (approver_type === ApproverType.ROLE) {
                    this.addRoleTypeApprover(approver)
                }

                if (ApproverType.EXTERNAL_ARCHITECTS_TYPE === approver_type) {
                    this.addExternalApprover(approver)
                }
            })
        },

        addEmployeeTypeApprover (template: ApproverTemplate): void {
            const { approver_id, assignment_type, limit_type, limit_value, sequence_value } = template

            const employeeExists = this.employees.some(employee => employee.id === approver_id)
            const role_name = this.roles.find(role => role.id === approver_id)?.name || '?'

            this.data.approvers.push({
                user_id: employeeExists ? approver_id : null,
                is_unassigned: !employeeExists,
                assignment_type,
                template,
                limit_type,
                limit_value,
                sequence_value,
                role_name,
            })
        },

        addRoleTypeApprover (template: ApproverTemplate): void {
            const { approver_id, assignment_type, limit_type, limit_value, sequence_value } = template

            const approvers_to_assign = this.employees.filter(employee => employee.role?.id === approver_id)
            const assignAutomatically = approvers_to_assign.length === 1
            const role_name = this.roles.find(role => role.id === approver_id)?.name || '?'

            this.data.approvers.push({
                user_id: assignAutomatically ? head(approvers_to_assign)!.id : null,
                is_unassigned: !assignAutomatically,
                assignment_type,
                approvers_to_assign,
                role_name,
                template,
                limit_type,
                limit_value,
                sequence_value,
            })
        },

        addExternalApprover (template: ApproverTemplate): void {
            const { limit_type, assignment_type, limit_value, sequence_value } = template

            const approvers_to_assign = this.employees.filter(employee => employee.is_external)
            const employee = this.employees.find(employee => employee.is_external)
            const role_name = this.$tc('External Architect / Engineer')

            this.data.approvers.push({
                user_id: employee ? employee.id : null,
                is_unassigned: !employee,
                assignment_type,
                approvers_to_assign,
                template,
                limit_type,
                limit_value,
                sequence_value,
                role_name,
            })
        },

        async openWorkflowTemplatesPopup (): Promise<void> {
            await this.fetchWorkflowTemplates()
            this.showWorkflowTemplatesPopup = true
        },

        closeWorkflowTemplatesPopup (): void {
            this.showWorkflowTemplatesPopup = false
        },

        openAssignedCostCodesPopup (): void {
            this.showAssignedCostCodesPopup = true
        },

        closeAssignedCostCodesPopup (): void {
            this.showAssignedCostCodesPopup = false
        },

        deleteApprover (index: number): void {
            this.data.approvers.splice(index, 1)
        },

        availableEmployees (approver: Approver): Employee[] {
            const selectedEmployee = this.employees.find((employee: Employee) => {
                return employee.id === approver.user_id
            })

            if (selectedEmployee) {
                return [selectedEmployee, ...this.notSelectedEmployees]
            }

            return this.notSelectedEmployees
        },

        setNotSelectedEmployees (): void {
            let selectedIds = this.data.approvers.map((approver: Approver) => approver.user_id)

            this.notSelectedEmployees = this.employees.filter((employee: Employee) => !selectedIds.includes(employee.id))
        },

        addApprover (): void {
            const sequenceValue = this.data.sequence_enabled
                ? Math.max(...this.data.approvers.map(approver => approver.sequence_value)) + 1
                : null

            const newApprover: Approver = {
                approval: null,
                approvers_to_assign: [],
                is_unassigned: false,
                role_name: '',
                template: undefined,
                user_id: null,
                assignment_type: ApproverAssignmentType.APPROVER,
                limit_type: 'no_limit',
                limit_value: 0,
                sequence_value: sequenceValue,
                can_edit: true,
            }

            this.data.approvers.push(newApprover)
        },

        onClickRemoveWorkflow (): void {
            this.$emit('remove-workflow')
        },

        isStatusApprovedOrRejected (status: ApprovalStatus | null): boolean {
            return [ApprovalStatus.APPROVED, ApprovalStatus.REJECTED].includes(status)
        },

        async fetchWorkflowTemplates (): Promise<void> {
            if (this.loadedConfiguration) {
                return
            }

            this.setLoadingBar(true)
            await this.getConfiguration()
            this.setLoadingBar(false)
        },
    }
})
