<template>
    <div class="io-invoices-to-revert-list">
        <div class="io-invoices-to-revert-list__caption">
            {{ $t('Reverting this invoice to the draft will automatically mark as draft all the invoices ahead of it listed below.') }}
        </div>
        <InvoicesListTable
            :headers="invoiceHeaders" 
            :items="invoices"
            :defineTableHeight="false"
            :lazyLoading="false"
            :manageColumns="false"
        />
    </div>
</template>

<script lang="ts" src="./InvoicesToRevertList.ts"></script>
<style lang="scss" src="./InvoicesToRevertList.scss" scoped></style>