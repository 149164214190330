export enum BuildingsRouteName {
    BUILDINGS = 'buildings',
    BUILDINGS_DETAILS = 'buildings-details',
    BUILDINGS_PROJECTS = 'buildings-projects',
    BUILDINGS_PROJECT_SUMMARY = 'buildings-project-summary',
    BUILDINGS_SUBSITES = 'buildings-subsites',
    BUILDINGS_OCCUPANTS = 'buildings-occupants',
    BUILDINGS_FILES = 'buildings-files',
    BUILDINGS_TIMELINE = 'buildings-timeline',
}
