import Invoice from '@/io-modules/invoices/interfaces/Invoice'
import StatusDot from '@/components/atoms/status-dot/StatusDot.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import CellType from '@/components/table/CellType'
import InvoiceHeader from '@/io-modules/invoices/enums/InvoiceHeader'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { defineComponent, PropType } from 'vue'
import ApplicationPackage from '@/io-modules/invoices/interfaces/ApplicationPackage'
import statusColorMixin from '../../../../mixins/StatusColorsMixin'
import { NavigationFailure } from 'vue-router'
import InvoiceRouteName from '@/io-modules/invoices/enums/InvoiceRouteName'
import { invoicesStore } from '@/io-modules/invoices/stores/invoicesList'
import InvoiceStatus from '@/io-modules/invoices/enums/InvoiceStatus'
import AppPackageRouteName from '../../../../enums/AppPackageRouteName'

export default defineComponent({
    name: 'InvoiceTableRow',
    components: {
        StatusDot,
        StatusPill,
    },
    props: {
        headers: {
            type: Array as PropType<HeaderTable[]>,
        },
        item: {
            type: Object as PropType<Invoice | ApplicationPackage>,
        },
    },
    mixins: [statusColorMixin],
    data () {
        return {
            CellType,
            maxAvatarsCount: 5,
            InvoiceHeader,
            hoveringOn: null,
        }
    },
    methods: {
        getCellValue (header: HeaderTable): string {
            if (header.filter) {
                return this.item[header.value] ? this.$filters[header.filter](this.item[header.value]) : '-'
            }

            return (header.customValue instanceof Function ?
                header.customValue(this.item) : this.item[header.value]) || '-'
        },

        getStatusDotVariant (header: HeaderTable): string {
            return {
                lien_waiver_status: this.getLienWaiverStatusColor(this.item.lien_waiver_status),
                payment_status: this.getPaymentStatusColor(this.item.payment_status),
                billing_date: this.item.billing_date ? this.getBillingDateStatusColor(this.item.billing_date) : '-'
            }[header.value]
        },

        getStatusDotText (header: HeaderTable): string {
            return {
                lien_waiver_status: this.$tc(`${ this.item[`${ header.value }_text`] }`),
                payment_status: this.$tc(`${ this.item[`${ header.value }_text`] }`),
                billing_date: header.customValue ? this.$tc(`${ header.customValue(this.item) }`) : '',
            }[header.value]
        },

        getStatusPillText (header: HeaderTable): string {
            return this.$tc(`${ this.item[`${ header.value }_text`] }`)
        },

        getAvatarsTooltip (users: Array<object>): string {
            return users.reduce((accumulator, currentValue, index) =>
                `${ accumulator }${ 0 === index ? '' : '<br>' }${ currentValue.name }`, '')
        },

        openRowDetails (): Promise<void | NavigationFailure> {
            if ('invoice_number' in this.item) {
                if (this.$route.meta.list) {
                    return this.$router.push({ name: InvoiceRouteName.InvoiceDetails, params: { id: this.item.id } })
                } else {
                    const route = this.$router.resolve({ name: InvoiceRouteName.InvoiceDetails, params: { id: this.item.id } })
                    window.open(route.href, '_blank', 'noopener')
                }
            } else {
                if (this.$route.meta.appPackagesList) {
                    return this.$router.push({ name: AppPackageRouteName.AppPackageDetails, params: { id: this.item.id } })
                } else {
                    const route = this.$router.resolve({ name: AppPackageRouteName.AppPackageDetails, params: { id: this.item.id } })
                    window.open(route.href, '_blank', 'noopener')
                }
            }
        },

        onButtonClick (button: object): void {
            if (button.onClick && button.onClick instanceof Function) {
                return button.onClick(this.item)
            } else if (button.onClick) {
                return this.$emit(button.onClick, this.item)
            }
        },
    }
})
