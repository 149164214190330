<template>
    <div class="io-single-breadcrumb" :class="{'io-single-breadcrumb--current': isCurrentItem}">
        <p v-tooltip.bottom="{ content: tooltipText, visible: showTooltip }">
            <template v-if="isCurrentItem">
                {{ $t(textToShow) }}
            </template>
            <template v-else>
                <router-link :to="breadcrumb.to">{{ $t(textToShow) }}</router-link>
                <span class="icon-chevron-right"></span>
            </template>
        </p>
    </div>
</template>
<script src="./SingleBreadcrumb.ts" lang="ts"></script>
<style src="./SingleBreadcrumb.scss" lang="scss" scoped></style>
