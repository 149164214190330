import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        options: { type: Array, required: true },
        optionLabel: { type: Function, required: true },
        selectedLabel: { type: String, required: true },
        disabled: { type: Boolean, default: false },
    },
    data () {
        return {
            dropdownExpanded: false,
        }
    },
    methods: {
        toggleDropdown (): void {
            if (!this.disabled) {
                this.dropdownExpanded = !this.dropdownExpanded
            }
        },
        closeDropdown (): void {
            this.dropdownExpanded = false
        },
        select (option: unknown): void {
            this.$emit('select', option)
        },
    },
})
