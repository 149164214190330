import { ApiClient } from '@/api/api'
let client = ApiClient

function ClientException (message) {
    this.messag = message
    this.name = 'BidrequestException'
}

export default {
    getContractData (projectId, contractId) {
        if (!projectId || !contractId) {
            throw new ClientException('No IDs Provided!')
        }
        return client.get(`/change-orders/get-data-contract/${projectId}/${contractId}/cor`)
    }
}
