<template>
    <div>
        <div class="io-design-system io-accordion" @click="toggleAccordion">
            <div
                data-testid="accordion-header"
                class="io-accordion__header"
                :class="{ 'io-accordion__header--open': isOpen }"
                :style="headerStyles"
            >
                <div class="left-content">
                    <span class="left-content__icon" :class="[icon, iconColorClass]" :style="rotateChevronIcon" />
                    <h3 v-if="title">{{ title }}</h3>
                    <StatusDot v-if="statusDot" :variant="statusDot.variant" :text="statusDot.text"/>
                    <StatusPill v-if="status" :variant="status.variant" :text="String(status.text)"/>
                    <Tooltip v-if="tooltip" :text="tooltip"/>
                    <label v-if="tooltipLabel" class="io-tooltip-label">{{ tooltipLabel }}</label>
                    <slot name="header-left"></slot>
                </div>
                <div class="right-content">
                    <IOButton v-if="button" :variant="button.variant" :icon="button.icon" @click.stop="emitCallToAction(id)">
                        {{ button.text }}
                    </IOButton>
                    <slot name="header-right"></slot>
                </div>
            </div>
        </div>
        <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
        >
            <div v-if="isOpen" class="io-accordion__content">
                <hr v-if="showLine" class="line"/>
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" src="./Accordion.ts"></script>
<style lang="scss" src="./Accordion.scss" scoped></style>
