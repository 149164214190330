<template>
    <div v-if="schema != null" :key="formGeneratorMainKey" class="vue-form-generator io-form-generator">
        <template v-for="(field, fieldIdx) in fields" :key="`fieldForm_${ fieldIdx }`">
            <template v-if="schema.fields && field?.virtual !== true">
                <template v-if="field.mode !== 'combo'">

                    <div
                        v-if="fieldVisible(field)"
                        class="form-group row io-f-row io-form-label-row"
                        :class="getFieldRowClasses(field)"
                    >
                        <!-- icon -->
                        <i v-if="fieldTypeHasIcon(field)" class="io-ing-icon" :class="getFieldIcon(field)"/>

                        <!-- label -->
                        <label v-if="fieldTypeHasLabel(field)" :for="getFieldID(field)" :class="getColumn(field)">
                            <template v-if="!field.html">{{ $t(field.label) }}</template>
                            <span v-else>{{ field.label }}</span>
                            <Tooltip v-if="field.help" :htmlContent="field.help"/>
                        </label>

                        <div
                            v-if="field.mode === 'template'"
                            class="field-wrap io-form-field-holder"
                            :class="getClass(field)"
                        >
                            <component
                                :is="getFieldTypeByName(field.template_schema[0], field)"
                                :editable="fieldIsEditable(field)"
                                :formOptions="options"
                                :id="getFieldIDIdxe(field, index)"
                                :index="index"
                                :model="model"
                                :schema="field"
                                @addFieldElement="addFieldElement"
                                @model-updated="modelUpdated"
                                @validated="onFieldValidated"
                            >
                            </component>

                            <!-- sub set of fields -->
                            <blockquote>
                                <template v-for="(fieldType, index) in field.set" :key="`component_${ index }`">
                                    <component
                                        :is="getFieldTypeByName(fieldType, field)"
                                        :formOptions="options"
                                        :editable="fieldIsEditable(field)"
                                        :id="getFieldIDIdxe(field, index)"
                                        :index="index"
                                        :model="model"
                                        :schema="field"
                                        @addFieldElement="addFieldElement"
                                        @model-updated="modelUpdated"
                                        @validated="onFieldValidated"
                                    />
                                </template>
                            </blockquote>
                        </div>

                        <div
                            v-if="field.mode === 'mix'"
                            class="field-wrap io-form-field-holder"
                            :class="getClass(field)"
                        >
                            <template v-for="(input, index) in model[field.model]" :key="`model_${index}`">
                                <div class="row no-gutters io-f-row" style="max-width: 400px;">
                                    <div class="col-11">
                                        <component
                                            :is="getFieldType(field)"
                                            :formOptions="options"
                                            :editable="fieldIsEditable(field)"
                                            :id="getFieldIDIdxe(field, index)"
                                            :index="index"
                                            :model="model"
                                            :schema="field"
                                            @addFieldElement="addFieldElement"
                                            @model-updated="modelUpdated"
                                            @validated="onFieldValidated"
                                        />
                                    </div>

                                    <div class="col-1 text-left align-middle">
                                        <i
                                            v-if="index !== 0"
                                            class="fas fa-trash trash-icon io-trash-icon"
                                            @click="deleteRow(field.model, index)"
                                        ></i>
                                    </div>

                                    <div class="clear"></div>
                                    <div v-if="field.errors" class="support-block">
                                        <div v-if="fieldErrors(field).length > 0" class="errors help-block">
                                            <span
                                                v-for="error in fieldErrors(field)"
                                                track-by="index"
                                            >{{ error }}</span>
                                        </div>
                                    </div>
                                    <div v-if="field.hint" v-html="field.hint" class="hint" />
                                    <div class="clear"></div>
                                </div>
                            </template>
                            <div class="row no-gutters">
                                <div class="col-11">
                                    <div class="multi">
                                        <a
                                            class="button float-right io-btn-primary mr-0"
                                            @click="addMixRow(field.model)"
                                        >
                                            <i class="fa fa-plus-circle"></i> Add License
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>

                        <!-- input multi -->
                        <div
                            v-if="field.mode === 'multi'"
                            class="field-wrap io-form-field-holder"
                            :class="getClass(field)"
                        >
                            <template v-for="(input, index) in model[field.model]" :key="`model2_${ index }`">
                                <div class="row no-gutters io-f-row" style="margin-bottom: 8px;">
                                    <div class="col-11">
                                        <component
                                            :is="getFieldType(field)"
                                            v-focus
                                            :formOptions="options"
                                            :editable="fieldIsEditable(field)"
                                            :id="getFieldIDIdxe(field, index)"
                                            :index="index"
                                            :model="model"
                                            :schema="field"
                                            @addFieldElement="addFieldElement"
                                            @model-updated="modelUpdated"
                                            @validated="onFieldValidated"
                                        />
                                    </div>

                                    <div class="col-1 text-left align-middle" style="margin-left: 8px;">
                                        <i
                                            v-if="index !== 0"
                                            class="fas fa-trash trash-icon io-trash-icon"
                                            style="width: 16px;"
                                            @click="deleteRow(field.model, index)"
                                        ></i>
                                    </div>

                                    <div class="clear"></div>
                                    <div v-if="field.errors" class="support-block">
                                        <div v-if="fieldErrors(field).length > 0" class="errors help-block">
                                            <span
                                                v-for="error in fieldErrors(field)"
                                                track-by="index"
                                            >{{ error }}</span>
                                        </div>
                                    </div>
                                    <div v-if="field.hint" v-html="field.hint" class="hint" />
                                    <div class="clear"></div>
                                </div>
                            </template>

                            <!-- add email -->
                            <div class="row no-gutters">
                                <div class="col-11">
                                    <a class="button io-btn-primary float-right mr-0" @click="addRow(field.model)"><i
                                        class="fa fa-plus-circle"
                                    ></i> Add Email</a>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>

                        <template v-if="field.type === 'group'">
                            <div class="field-wrap" :class="getClass(field)">
                                <div class="group-field-container">
                                    <div
                                        v-for="fieldGroup in field.groups[0].fields"
                                        class="group-field"
                                        :style="bindGroupFieldSplit(fieldGroup.split)"
                                    >
                                        <component
                                            :is="getFieldType(fieldGroup)"
                                            :formOptions="options"
                                            :disabled="fieldDisabled(fieldGroup)"
                                            :editable="fieldIsEditable(field)"
                                            :model="model"
                                            :myButtons="myButtons"
                                            :schema="fieldGroup"
                                            @addFieldElement="addFieldElement"
                                            @model-updated="modelUpdated"
                                            @validated="onFieldValidated"
                                        >
                                            <div v-if="buttonVisibility(fieldGroup)" class="buttons">
                                                <button
                                                    v-for="btn in fieldGroup.buttons"
                                                    :class="btn.classes"
                                                    @click="buttonClickHandler(btn, fieldGroup, $event)"
                                                >
                                                    {{ $t(btn.label) }}
                                                </button>
                                            </div>
                                        </component>

                                        <div v-if="fieldGroup.hint" v-html="fieldGroup.hint" class="hint"/>
                                        <div class="clear"></div>
                                        <div v-if="fieldGroup.errors" class="support-block">
                                            <div v-if="fieldErrors(fieldGroup).length > 0" class="errors help-block">
                                                <span
                                                    v-for="error in fieldErrors(fieldGroup)"
                                                    track-by="index"
                                                >{{ error }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <!-- most common case -->
                        <div
                            v-if="field.type !== 'group' && field.mode !== 'mix'  && field.mode !== 'multi'"
                            class="field-wrap io-form-field-holder"
                            :class="getClass(field) "
                        >
                            <component
                                :is="getFieldType(field)"
                                :formOptions="options"
                                :disabled="fieldDisabled(field)"
                                :editable="fieldIsEditable(field)"
                                :model="model"
                                :myButtons="myButtons"
                                :schema="field"
                                :schemaField="getSchemaField(field, fieldIdx)"
                                @addFieldElement="addFieldElement"
                                @model-updated="modelUpdated"
                                @validated="onFieldValidated"
                            >

                                <div v-if="buttonVisibility(field)" class="buttons">
                                    <button
                                        v-for="btn in field.buttons"
                                        :class="btn.classes"
                                        @click="buttonClickHandler(btn, field, $event)"
                                    >
                                        {{ $t(btn.label) }}
                                    </button>
                                </div>
                            </component>

                            <!--
                            <template v-if="getSchemaField(field, fieldIdx).extraComponent">
                                <component
                                    :is="getSchemaField(field, fieldIdx).extraComponent"
                                    :model="model"
                                />
                            </template>
                            -->

                            <div class="clear"></div>
                            <div v-if="fieldErrors(field).length > 0" class="errors help-block w-100 float-left">
                                <span v-for="error in fieldErrors(field)" track-by="index">{{ error }}</span>
                            </div>

                            <template v-if="field.hint">
                                <template v-if="getSchemaField(field).hintOptions && getSchemaField(field).hintOptions.isPopup">
                                    <div class="hint">
                                        <a
                                            :class="{'hint-larger': getSchemaField(field).hintOptions.size === 'large'}"
                                            @click="getSchemaField(field).hintOptions.showPopup = true"
                                            v-html="$t(field.hint)"
                                        />
                                        <!-- component with popup -->
                                        <component
                                            :is="getSchemaField(field).hintOptions.componentName"
                                            :show="getSchemaField(field).hintOptions.showPopup"
                                            @closePopup="getSchemaField(field).hintOptions.showPopup = false"
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="hint" v-html="$t(field.hint)"/>
                                </template>
                            </template>

                            <div class="clear"></div>
                        </div>
                    </div>
                </template>

                <template v-if="field.mode === 'combo' && field.layout === 'vertical'">
                    <template v-for="(input, index) in model[field.model]" :key="`model3_${ index }`">
                        <div class="form-group row io-f-row io-form-label-row">
                            <div class="col-12">
                                <h2
                                    v-if="index === 0 && field.editableFirst !== true && !field.disableHeader"
                                    :id="field.inputName"
                                >
                                    {{ $t(field.label) }}</h2>
                                <h2
                                    v-else-if="!field.disableHeader && ((index > 0 && field.next_label !== false) || (field.editableFirst === true))"
                                    class="io-f-row io-head-row"
                                >
                                    <template v-if="model[field.model][index].editLocation">
                                        <input
                                            type="text"
                                            v-model="model[field.model][index].location"
                                            v-on:keyup.enter="model[field.model][index].editLocation = !model[field.model][index].editLocation"
                                        />
                                    </template>
                                    <template v-else>
                                        <template v-if="!model[field.model][index].location">
                                            {{ field.next_label }} #{{ index + 1 }}
                                        </template>
                                        <template v-else>
                                            {{ model[field.model][index].location }}
                                        </template>
                                    </template>
                                    <template v-if="model[field.model][index].isDisabled !== true">
                                        <i
                                            v-if="field.onlyRemove !== true"
                                            :class="[!model[field.model][index].editLocation ? 'fa fa-pen' : 'fa fa-save']"
                                            @click="model[field.model][index].editLocation = !model[field.model][index].editLocation"
                                        ></i>
                                        <i
                                            class="fas fa-trash trash-icon io-trash-icon"
                                            @click="deleteRow(field.model, index, false, false, field)"
                                        ></i>
                                    </template>
                                </h2>
                                <h2 v-else-if="field.disableHeader && index > 0"></h2>
                            </div>
                        </div>

                        <template v-for="(subField, subIndex) in field.pack" :key="`subField_${subIndex}`">
                            <template v-if="fieldVisible(subField)">
                                <div
                                    v-if="fieldVisible(field)"
                                    class="form-group row io-f-row io-form-label-row"
                                    :class="getFieldRowClasses(field, subIndex, index)"
                                >
                                    <i
                                        v-if="fieldTypeHasIcon(field)"
                                        class="io-ing-icon"
                                        :class="getFieldIcon(field)"
                                    />
                                    <label
                                        v-if="fieldTypeHasLabel(field)"
                                        :class="getColumn(field)"
                                        :for="getFieldID(field)"
                                    >
                                        {{ subField.label }}
                                        <Tooltip v-if="field.help" :htmlContent="field.help"/>
                                    </label>

                                    <div
                                        class="field-wrap io-form-field-holder io-f-row io-row-delete"
                                        :class="getClass(field)"
                                    >
                                        <component
                                            :is="getFieldType(subField)"
                                            :disabled="getDisabledValue(model[field.model][index], subField)"
                                            :editable="fieldIsEditable(field)"
                                            :formOptions="options"
                                            :id="getFieldIDIdxe(field, index, subIndex)"
                                            :index="index"
                                            :indexation="true"
                                            :model="model"
                                            :schema="subField"
                                            :subindex="subIndex"
                                            @addFieldElement="addFieldElement"
                                            @formGeneratorForceRefresh="formGeneratorForceRefresh"
                                            @model-updated="modelUpdated"
                                            @validated="onFieldValidated"
                                        />
                                        <!--DELETE -->
                                        <div
                                            v-if="index > 0 && field.next_label === false && subIndex === field.pack.length - 1"
                                            class="delete-vertical-1 pl-2 float-left" style="line-height:34px;"
                                        >
                                            <i
                                                class="fas fa-trash trash-icon io-trash-icon"
                                                @click="deleteRow(field.model, index)"
                                            ></i>
                                        </div>
                                        <div v-if="subField.errors" class="support-block">
                                            <div
                                                v-if="indexedErrors(subField.inputName, index, subField.indexationStorage)"
                                                class="errors help-block"
                                            >
                                            <span
                                                v-for="(error,index) in indexedFieldErrors[getPrefixForIndexed(subField.inputName, index, subField.indexationStorage)]"
                                                track-by="index"
                                            >
                                                {{ error }}
                                            </span>
                                            </div>
                                        </div>
                                        <div v-if="subField.hint" v-html="subField.hint" class="hint" />
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>

                    <template v-if="field.disableAddNewRow !== true">
                        <div class="form-group row io-f-row io-form-label-row field-headline">
                            <div :class="getColumn(field)"></div>
                            <div :class="getClass(field)">
                                <div class="multi" style="margin-left: 230px; display: flex; justify-content: end;">
                                    <a
                                        class="io-btn-primary-compact"
                                        @click="addComboRow(field.model, false, field)"
                                    >
                                        <i class="fa fa-plus-circle"></i>
                                        <template v-if="field.button !== undefined">
                                            {{ field.button }}
                                        </template>
                                        <template v-else>
                                            {{ $t('Add Row') }}
                                        </template>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>

                <template v-if="field.mode === 'combo' && field.layout === 'horizontal'">
                    <div class="form-group row io-f-row io-form-label-row field-headline">
                        <div class="col-12" :id="field.inputName">
                            <h2>{{ $t(field.label) }}</h2>
                        </div>
                    </div>

                    <template v-for="(input, index) in model[field.model]" :key="`model4_${ index }`">
                        <div class="row" style="margin-bottom: 10px">
                            <div
                                v-for="(subField, subIndex) in field.pack"
                                :class="[field.layout === 'vertical' ?  'col-12 mb-2': 'col-6']"
                            >

                                <div
                                    v-if="fieldVisible(field)" class="form-group row io-f-row io-form-label-row"
                                    :class="getFieldRowClasses(field, subIndex, index)"
                                >

                                    <i
                                        v-if="fieldTypeHasIcon(field)" class="io-ing-icon"
                                        :class="getFieldIcon(field)"
                                    />
                                    <label
                                        v-if="fieldTypeHasLabel(field)"
                                        class="col-sm-4 text-lg-right text-sm-right align-middle"
                                        :for="getFieldID(field)"
                                    >
                                        {{ subField.label }}
                                        <Tooltip v-if="field.help" :htmlContent="field.help"/>
                                    </label>

                                    <div
                                        class="io-form-field-holder io-row-delete col-sm-7 text-lg-right text-sm-left align-middle"
                                        :id="getFieldIDIdxe(field, index, subField.indexationStorage)"
                                    >
                                        <component
                                            :is="getFieldType(subField)"
                                            :editable="fieldIsEditable(field)"
                                            :formOptions="options"
                                            :indexation="true"
                                            :id="getFieldIDIdxe(field, index, subIndex) + '2'"
                                            :index="index"
                                            :model="model"
                                            :schema="subField"
                                            :subindex="subIndex"
                                            @addFieldElement="addFieldElement"
                                            @model-updated="modelUpdated"
                                            @validated="onFieldValidated"
                                        />

                                        <div class="clear"></div>
                                        <div v-if="subField.errors" class="support-block">
                                            <div
                                                v-if="indexedErrors(subField.inputName, index, subField.indexationStorage)"
                                                class="errors help-block"
                                            >
                                                <span
                                                    v-for="(error,index) in indexedFieldErrors[getPrefixForIndexed(subField.inputName, index, subField.indexationStorage)]"
                                                    style="text-align: left !important;position: absolute !important;; top: 30px !important;font-size: 9px !important;"
                                                    track-by="index"
                                                >
                                                    {{ error }}
                                                </span>
                                            </div>
                                        </div>

                                        <div v-if="subField.hint" v-html="subField.hint" class="hint" />
                                        <div
                                            v-if="subIndex === field.pack.length - 1"
                                            class="col-1 text-left align-middle"
                                        >
                                            <i
                                                class="fas fa-trash trash-icon io-trash-icon"
                                                @click="deleteRow(field.model, index)"
                                            ></i>
                                        </div>
                                    </div>

                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="row no-gutters io-f-row">
                        <div class=" text-right  col-12">
                            <a
                                class="button float-right mr-0 io-btn-primary"
                                @click="addComboRow(field.model, false, field)"
                            >
                                <i class="fa fa-plus-circle"></i> Add Row
                            </a>
                        </div>
                    </div>
                </template>

                <template v-if="field.mode === 'combo' && field.layout === 'table'">
                    <template
                        v-for="(section, sectionId) in field.generate.content.data"
                        :key="`section_${ sectionId }`"
                    >
                        <template v-if="section.section.hide !== true">
                            <!--
                            if hideHeadlines = true then h3 should not be visible, however
                            we can override it when sections[] = 0 but customs[] > 0
                            -->
                            <h3 v-if="determineHeadlineHiding(field, section.section.sec_id)">
                                {{ section.section.sec_name }}
                            </h3>

                            <!-- START this block generates extra fields that will show "per section" within combos -->
                            <template v-if="field.per_combo_section_extra_fields !== undefined && section.section.disableExtraFields !== true">

                                <template
                                    v-for="(extrafield, extrafieldIdx) in field.per_combo_section_extra_fields"
                                    :key="`extrafield_${extrafieldIdx}`"
                                >
                                    <div
                                        v-if="fieldVisible(extrafield)"
                                        class="form-group row io-f-row io-form-label-row"
                                        :class="[getClass(extrafield),getFieldRowClasses(extrafield)]"
                                    >

                                        <i
                                            v-if="fieldTypeHasIcon(extrafield)"
                                            class="io-ing-icon"
                                            :class="getFieldIcon(extrafield)"
                                        />
                                        <label
                                            v-if="fieldTypeHasLabel(extrafield)"
                                            :class="getColumn(extrafield)"
                                            :for="getFieldID(extrafield)"
                                        >
                                            {{ extrafield.label }}
                                            <span v-if="extrafield.help" class="help">
                                            <i class="icon">
                                                <div class="helpText">{{ extrafield.help }}</div>
                                            </i>
                                        </span>
                                        </label>

                                        <div
                                            class="field-wrap io-form-field-holder"
                                            :class="getClass(extrafield)"
                                        >
                                            <component
                                                :is="getFieldType(extrafield)"
                                                :comboExtraGroup="extrafield.indexed"
                                                :comboMode="true"
                                                :disabled="fieldDisabled(extrafield, field.generate.content.isLocked)"
                                                :editable="fieldIsEditable(field)"
                                                :formOptions="options"
                                                :index="section.section.sec_id"
                                                :model="model"
                                                :schema="extrafield"
                                                @addFieldElement="addFieldElement"
                                                @model-updated="modelUpdated"
                                                @validated="onFieldValidated"
                                            />

                                            <div class="clear"></div>
                                            <div v-if="extrafield.errors" class="support-block">
                                                <div
                                                    v-if="fieldErrors(extrafield).length > 0"
                                                    class="errors help-block"
                                                >
                                                <span
                                                    v-for="(error,extrafieldIdx) in fieldErrors(extrafield)"
                                                    track-by="extrafieldIdx"
                                                >
                                                    {{ extrafieldIdx }}
                                                </span>
                                                </div>
                                            </div>
                                            <div v-if="extrafield.hint" class="hint">{{ extrafield.hint }}</div>
                                            <div class="clear"></div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <!-- START - end -->

                            <template v-if="(model[field.model].sections && model[field.model].sections[section.section.sec_id] !== undefined && model[field.model].sections[section.section.sec_id].length > 0) || (field.generate.content.integrateSubrows === true)">
                                <table
                                    :class="'noHover combo-component-table io-table with-content io-union-edit' + (field.subrows === true ? ' mb-0' : '') + (field.classCss ? ' ' + field.classCss : '')"
                                >
                                    <thead v-if="model[field.model].sections[section.section.sec_id] !== undefined && model[field.model].sections[section.section.sec_id].length > 0">
                                        <tr v-if="hideSectionHeader(model[field.model].sections[section.section.sec_id]) === true">
                                            <th
                                                v-for="(subField, subIndex) in field[getPack(section.section)]"
                                                :class="getFieldRowClasses(field, subIndex, index, subField.viewCss)"
                                                :style="setStyle('max-width', subField.fixedWidth)"
                                                :width="subField.fixedWidth"
                                            >
                                                <template v-if="subField.html === true">
                                                    <i
                                                        v-if="fieldTypeHasIcon(field)" class="io-ing-icon"
                                                        :class="getFieldIcon(field)"
                                                    />
                                                    <label
                                                        v-if="fieldTypeHasLabel(field)"
                                                        class="col-sm-4 text-lg-right text-sm-right align-middle"
                                                        :for="getFieldID(field)"
                                                    >
                                                        {{ subField.label }}
                                                    </label>
                                                </template>
                                                <template v-else>
                                                    <i
                                                        v-if="fieldTypeHasIcon(field)" class="io-ing-icon"
                                                        :class="getFieldIcon(field)"
                                                    />
                                                    <label
                                                        v-if="fieldTypeHasLabel(field)"
                                                        class="col-sm-4 text-lg-right text-sm-right align-middle"
                                                        :for="getFieldID(field)"
                                                    >
                                                        {{ subField.label }}
                                                        <Tooltip v-if="field.help" :htmlContent="field.help"/>
                                                    </label>
                                                </template>
                                            </th>
                                            <th
                                                v-if="field.generate.content.isLocked === false"
                                                style="width: 100px !important;"
                                                width="100"
                                            ></th>
                                        </tr>
                                    </thead>

                                    <thead v-else>
                                        <tr>
                                            <th
                                                v-for="(subField, subIndex) in field.custompack"
                                                :colspan="subField.colspan"
                                                :width="subField.fixedWidth"
                                                :style="setStyle('max-width', subField.fixedWidth)"
                                                :class="getFieldRowClasses(field, subIndex, index, subField.viewCss)"
                                            >
                                                <template v-if="subField.html === true">
                                                    <i
                                                        v-if="fieldTypeHasIcon(field)"
                                                        class="io-ing-icon"
                                                        :class="getFieldIcon(field)"
                                                    />
                                                    <label
                                                        v-if="fieldTypeHasLabel(field)"
                                                        class="col-sm-4 text-lg-right text-sm-right align-middle"
                                                        :for="getFieldID(field)"
                                                    >
                                                        {{ subField.label }}
                                                    </label>
                                                </template>
                                                <template v-else>
                                                    <i
                                                        v-if="fieldTypeHasIcon(field)"
                                                        class="io-ing-icon"
                                                        :class="getFieldIcon(field)"
                                                    />
                                                    <label
                                                        v-if="fieldTypeHasLabel(field)"
                                                        class="col-sm-4 text-lg-right text-sm-right align-middle"
                                                        :for="getFieldID(field)"
                                                    >
                                                        {{ subField.label }}
                                                        <Tooltip v-if="field.help" :htmlContent="field.help"/>
                                                    </label>
                                                </template>
                                            </th>
                                            <th
                                                v-if="field.generate.content.isLocked === false"
                                                style="width: 100px !important;"
                                                width="100"
                                            ></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <template v-if="model[field.model].sections">
                                            <template
                                                v-for="(row, index) in model[field.model].sections[section.section.sec_id]"
                                                :key="`row_${ index }`"
                                            >
                                                <tr v-if="model[field.model].sections[section.section.sec_id][index].hide !== true">
                                                    <td
                                                        v-for="(subField, subIndex) in field[getPack(section.section)]"
                                                        :class="getFieldRowClasses(field, subIndex, index, subField.viewCss)"
                                                        :id="getFieldIDIdxe(field, index, subField.indexationStorage)"
                                                        :style="setStyle('max-width', subField.fixedWidth)"
                                                        :width="subField.fixedWidth"
                                                    >
                                                        <template v-if="subField.subtype === 'calculator'">
                                                            <template v-if="subField.formatter === 'currency'">
                                                                <div
                                                                    class="calculator-field-val"
                                                                    :class="subField.viewCss"
                                                                >
                                                                    {{
                                                                        $filters.formatCurrency($parent.$parent.calculator(subField, section.section.sec_id, index))
                                                                    }}
                                                                </div>
                                                            </template>
                                                            <template v-else-if="subField.formatter === 'percent'">
                                                                <div
                                                                    class="calculator-field-val"
                                                                    :class="subField.viewCss"
                                                                >
                                                                    {{
                                                                        $filters.formatPercent($parent.$parent.calculator(subField, section.section.sec_id, index))
                                                                    }}
                                                                </div>
                                                            </template>
                                                            <template v-else>
                                                                <div
                                                                    class="calculator-field-val"
                                                                    :class="subField.viewCss"
                                                                >{{
                                                                        $parent.$parent.calculator(subField, section.section.sec_id,
                                                                            index)
                                                                    }}
                                                                </div>
                                                            </template>
                                                        </template>

                                                        <template v-else-if="subField.subtype === 'picker'">
                                                            <template>
                                                                <div
                                                                    class="calculator-field-val"
                                                                    :class="subField.viewCss"
                                                                >{{
                                                                        $parent.$parent.calculator(subField, section.section.sec_id,
                                                                            index)
                                                                    }}
                                                                </div>
                                                            </template>
                                                        </template>

                                                        <template v-else>
                                                            <template v-if="field.generate.content.isLocked === true">
                                                                <template v-if="subField.formatter === 'currency'">
                                                                    <div :class="subField.viewCss">
                                                                        {{
                                                                            $filters.formatCurrency(model[field.model].sections[section.section.sec_id][index][subField.indexationStorage])
                                                                        }}
                                                                    </div>
                                                                </template>
                                                                <template v-else-if="subField.formatter === 'percent'">
                                                                    <div :class="subField.viewCss">
                                                                        {{
                                                                            $filters.formatPercent(model[field.model].sections[section.section.sec_id][index][subField.indexationStorage])
                                                                        }}
                                                                    </div>
                                                                </template>
                                                                <template v-else>
                                                                    <div :class="subField.viewCss">
                                                                        {{
                                                                            model[field.model].sections[section.section.sec_id][index][subField.indexationStorage]
                                                                        }}
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                <component
                                                                    :is="getFieldType(subField)"
                                                                    :editable="fieldIsEditable(field)"
                                                                    :formOptions="options"
                                                                    :id="getFieldIDIdxe(field, index, subIndex, section.section.sec_id)"
                                                                    :index="index"
                                                                    :indexation="true"
                                                                    :model="model"
                                                                    :schema="subField"
                                                                    :sectionId="section.section.sec_id"
                                                                    :subindex="subIndex"
                                                                    @addFieldElement="addFieldElement"
                                                                    @model-updated="modelUpdated"
                                                                    @validated="onFieldValidated"
                                                                />
                                                            </template>
                                                        </template>

                                                        <div class="clear"></div>
                                                        <div v-if="subField.errors" class="support-block">
                                                            <div
                                                                v-if="indexedErrors(subField.inputName, index, subField.indexationStorage)"
                                                                class="errors help-block"
                                                            >
                                                        <span
                                                            v-for="(error,index) in indexedFieldErrors[getPrefixForIndexed(subField.inputName, index, subField.indexationStorage)]"
                                                            style="text-align: left !important;position: absolute !important;; top: 30px !important;font-size: 9px !important;"
                                                            track-by="index"
                                                        >
                                                            {{ error }}
                                                        </span>
                                                            </div>
                                                        </div>
                                                        <div v-if="subField.hint" v-html="subField.hint" class="hint"/>
                                                    </td>
                                                    <td v-if="field.generate.content.isLocked === false">
                                                        <template v-if="field.generate.content.removeRows === true">
                                                            <i
                                                                class="fas fa-trash trash-icon io-trash-icon"
                                                                @click="deleteRow(field.model, index, section.section.sec_id)"
                                                            ></i>
                                                        </template>
                                                    </td>
                                                </tr>

                                                <template v-if="row.children !== undefined">
                                                    <tr v-for="(child, childIdx) in row.children">
                                                        <td style="padding-left: 50px">{{ child.prec_provider }}</td>
                                                        <td class="aright">{{ child.prec_date }}</td>
                                                        <td class="aright"></td>
                                                        <td class="aright">{{
                                                                $filters.formatCurrency(child.prec_cost)
                                                            }}
                                                        </td>
                                                        <td v-if="child.is_bill === 1" class="aright">
                                                        </td>
                                                        <td v-else class="aright">
                                                        </td>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>

                                        <!--- THIS IS SUBSECTION ROWS BUT AS INTEGRAL PART OF MAIN TABLE -->
                                        <template v-if="field.generate.content.integrateSubrows === true">
                                            <template
                                                v-for="(row, integratedIndex) in model[field.model][field.subsection][section.section.sec_id]"
                                                v-if="model[field.model][field.subsection]"
                                            >
                                                <tr>
                                                    <td
                                                        v-for="(subField, subIndex) in field.custompack"
                                                        :class="getFieldRowClasses(field, subIndex, integratedIndex, subField.viewCss)"
                                                        :colspan="subField.colspan"
                                                        :id="getFieldIDIdxe(field, integratedIndex, subField.indexationStorage)"
                                                        :style="setStyle('max-width', subField.fixedWidth)"
                                                        :width="subField.fixedWidth"
                                                    >
                                                        <template v-if="subField.subtype === 'calculator'">
                                                            <div style="text-align: right; font-weight: bold">
                                                                {{
                                                                    $filters.formatCurrency($parent.$parent.calculator(subField, section.section.sec_id, integratedIndex))
                                                                }}
                                                            </div>
                                                        </template>
                                                        <template v-else-if="subField.subtype === 'picker'">
                                                            <div style="text-align: right; font-weight: bold">
                                                                {{
                                                                    $parent.$parent.calculator(subField, section.section.sec_id,
                                                                        index)
                                                                }}
                                                            </div>
                                                        </template>

                                                        <template v-else>
                                                            <template v-if="field.generate.content.isLocked === true">
                                                                <template v-if="subField.formatter === 'currency'">
                                                                    <div :class="subField.viewCss">
                                                                        {{
                                                                            $filters.formatCurrency(model[field.model][field.subsection][section.section.sec_id][integratedIndex][subField.indexationStorage])
                                                                        }}
                                                                    </div>
                                                                </template>
                                                                <template v-else-if="subField.subtype === 'picker'">
                                                                    <div style="text-align: right; font-weight: bold">
                                                                        {{
                                                                            model[field.model][field.subsection][section.section.sec_id][integratedIndex][subField.indexationStorage]
                                                                        }}
                                                                    </div>
                                                                </template>
                                                                <template v-else-if="subField.formatter === 'percent'">
                                                                    <div :class="subField.viewCss">
                                                                        {{
                                                                            $filters.formatPercent(model[field.model][field.subsection][section.section.sec_id][integratedIndex][subField.indexationStorage])
                                                                        }}
                                                                    </div>
                                                                </template>
                                                                <template v-else>
                                                                    <div :class="subField.viewCss">
                                                                        {{
                                                                            model[field.model][field.subsection][section.section.sec_id][integratedIndex][subField.indexationStorage]
                                                                        }}
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                <component
                                                                    :is="getFieldType(subField)"
                                                                    :editable="fieldIsEditable(field)"
                                                                    :formOptions="options"
                                                                    :id="getFieldIDIdxe(field, integratedIndex, subIndex, section.section.sec_id)"
                                                                    :index="integratedIndex"
                                                                    :indexation="true"
                                                                    :model="model"
                                                                    :schema="subField"
                                                                    :sectionId="section.section.sec_id"
                                                                    :subindex="subIndex"
                                                                    @addFieldElement="addFieldElement"
                                                                    @model-updated="modelUpdated"
                                                                    @validated="onFieldValidated"
                                                                />
                                                            </template>
                                                        </template>

                                                        <div class="clear"></div>
                                                        <div v-if="subField.errors" class="support-block">
                                                            <div
                                                                v-if="indexedErrors(subField.inputName, integratedIndex, subField.indexationStorage)"
                                                                class="errors help-block"
                                                            >
                                                        <span
                                                            v-for="(error,integratedIndex) in indexedFieldErrors[getPrefixForIndexed(subField.inputName, integratedIndex, subField.indexationStorage)]"
                                                            style="text-align: left !important;position: absolute !important;; top: 30px !important;font-size: 9px !important;"
                                                            track-by="integratedIndex"
                                                        >
                                                            {{ error }}
                                                        </span>
                                                            </div>
                                                        </div>
                                                        <div v-if="subField.hint" v-html="subField.hint" class="hint" />
                                                    </td>
                                                    <td
                                                        v-if="field.generate.content.isLocked === false"
                                                        style="max-width: 80px"
                                                        :class="getFieldRowClasses(field, subIndex, integratedIndex)"
                                                    >
                                                        <template v-if="field.generate.content.removeSubRows !== false">
                                                            <i
                                                                class="fas fa-trash trash-icon io-trash-icon"
                                                                @click="deleteRow(field.model, integratedIndex, section.section.sec_id, field.subsection)"
                                                            ></i>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>

                                    </tbody>

                                    <tfoot v-if="field.summary !== undefined || (field.generate.content.isLocked === false && field.subrows !== true)">
                                        <template v-if="field.summary !== undefined">
                                            <tr v-if="field.summary.enabled === true">
                                                <td
                                                    v-for="subField in field[getPack(section.section)]"
                                                    class="calculator aright"
                                                >
                                                    <template
                                                        v-if="inArray(subField.indexationStorage, field.summary.columns)
                                                            && field.discounted === true
                                                            && field.discounted_field !== undefined
                                                            && parseInt(model[field.discounted_field]) > 0"
                                                    >
                                                <span style="color: #f00; font-style: normal; text-decoration: line-through">
                                                    {{
                                                        $filters.formatCurrency(calculateTotalColumn(field, model, section.section.sec_id, subField.indexationStorage))
                                                    }}
                                                </span>
                                                        <br/>

                                                        <span
                                                            v-if="model[field.discounted_field + '_type'] === '$'"
                                                            style="color: green; font-weight: bold;"
                                                        >
                                                    -${{ model[field.discounted_field] }} discount
                                                </span>

                                                        <span
                                                            v-if="model[field.discounted_field + '_type'] !== '$'"
                                                            style="color: green; font-weight: bold;"
                                                        >
                                                    -{{ model[field.discounted_field] }}% discount
                                                </span>
                                                        <br/>

                                                        <span style="color: #000; font-size: 110%">
                                                    {{
                                                                $filters.formatCurrency(calculateTotalColumn(field,
                                                                    model,
                                                                    section.section.sec_id,
                                                                    subField.indexationStorage,
                                                                    parseInt(model[field.discounted_field]),
                                                                    model[field.discounted_field + '_type']))
                                                            }}
                                                </span>
                                                    </template>
                                                    <template v-else-if="inArray(subField.indexationStorage, field.summary.columns)">
                                                        {{
                                                            $filters.formatCurrency(calculateTotalColumn(field,
                                                                model, section.section.sec_id, subField.indexationStorage))
                                                        }}
                                                    </template>
                                                </td>
                                                <td v-if="field.generate.content.isLocked === false"></td>
                                            </tr>
                                        </template>

                                        <tr v-if="field.generate.content.isLocked === false && field.subrows !== true">
                                            <td
                                                class="aright noBorder"
                                                style="padding: 10px;"
                                                :colspan="field[getPack(section.section)].length + 1"
                                            >
                                                <a
                                                    class="button float-right mr-0 mb-0 io-btn-primary"
                                                    @click="addComboRow(field.model, section.section.sec_id, field, false, section.section.reimbursable)"
                                                >
                                                    <i class="fa fa-plus-circle"></i> Add Row
                                                </a>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </template>
                            <template v-else>
                                <table
                                    v-if="field.generate.content.isLocked === false && field.subrows !== true"
                                    :class="'noHover combo-component-table' + (field.subrows === true ? ' mb-0' : '') + (field.classCss ? ' ' + field.classCss : '')"
                                >
                                    <tfoot>
                                        <tr>
                                            <td class="aright noBorder" style="padding: 10px;">
                                                <a
                                                    class="button pull-right mr-0 mb-0 io-btn-primary"
                                                    style="margin-bottom: 0"
                                                    @click="addComboRow(field.model, section.section.sec_id, field, false, section.section.reimbursable)"
                                                >
                                                    <i class="fa fa-plus-circle"></i> Add Row
                                                </a>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <template v-if="field.subrows !== true">
                                    <br clear="all"/>
                                    <div class="section-divider"></div>
                                </template>
                            </template>

                            <template v-if="field.subrows === true">
                                <table
                                    :class="'noHover combo-component-table mb-40' + (model[field.model][field.subsection] ? ' with-content' : '') + (field.classCss ? ' ' + field.classCss : '')"
                                >
                                    <thead v-if="field.generate.content.hideSubRowsHeadlines !== true && model[field.model][field.subsection]">
                                        <tr>
                                            <th
                                                v-for="subField in field.custompack"
                                                :class="subField.viewCss"
                                                :style="setStyle('max-width', subField.fixedWidth)"
                                                :width="subField.fixedWidth"
                                            >
                                                <template v-if="subField.hidden !== true">
                                                    <i
                                                        v-if="fieldTypeHasIcon(field)"
                                                        class="io-ing-icon"
                                                        :class="getFieldIcon(field)"
                                                    />
                                                    <label
                                                        v-if="fieldTypeHasLabel(field)"
                                                        class="col-sm-4 text-lg-right text-sm-right align-middle"
                                                        :for="getFieldID(field)"
                                                    >
                                                        {{ subField.label }}
                                                        <Tooltip v-if="field.help" :htmlContent="field.help"/>
                                                    </label>
                                                </template>
                                            </th>
                                            <th
                                                v-if="field.generate.content.isLocked === false"
                                                style="width: 100px !important"
                                                width="100"
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="field.generate.content.integrateSubrows !== true">
                                        <template v-if="model[field.model][field.subsection]">
                                            <template v-for="(row, index) in model[field.model][field.subsection][section.section.sec_id]">
                                                <tr>
                                                    <td
                                                        v-for="(subField, subIndex) in field.custompack"
                                                        :class="getFieldRowClasses(field, subIndex, index, subField.viewCss)"
                                                        :id="getFieldIDIdxe(field, index, subField.indexationStorage)"
                                                    >
                                                        <template v-if="subField.hidden !== true">
                                                            <template v-if="subField.subtype === 'calculator'">
                                                                <div style="text-align: right; font-weight: bold">
                                                                    {{
                                                                        $filters.formatCurrency($parent.$parent.calculator(subField,
                                                                            section.section.sec_id, index))
                                                                    }}
                                                                </div>
                                                            </template>

                                                            <template v-else-if="subField.subtype === 'calculatorEditable'">
                                                                {{ model._logic_initialized }}
                                                                <component
                                                                    :is="getFieldType(subField)"
                                                                    :editable="fieldIsEditable(field)"
                                                                    :formOptions="options"
                                                                    :id="getFieldIDIdxe(field, index, subIndex, section.section.sec_id)"
                                                                    :index="index"
                                                                    :indexation="true"
                                                                    :model="model"
                                                                    :schema="subField"
                                                                    :sectionId="section.section.sec_id"
                                                                    :subindex="subIndex"
                                                                    :value="$parent.$parent.calculator(subField, section.section.sec_id,index)"
                                                                    @addFieldElement="addFieldElement"
                                                                    @formGeneratorForceRefresh="formGeneratorForceRefresh"
                                                                    @model-updated="modelUpdated"
                                                                    @validated="onFieldValidated"
                                                                />
                                                            </template>

                                                            <template v-else-if="subField.subtype === 'picker'">
                                                                <div style="text-align: right; font-weight: bold">
                                                                    {{
                                                                        $parent.$parent.calculator(subField,
                                                                            section.section.sec_id,
                                                                            index)
                                                                    }}
                                                                </div>
                                                            </template>

                                                            <template v-else>
                                                                <template v-if="field.generate.content.isLocked === true">
                                                                    <template v-if="subField.formatter === 'currency'">
                                                                        <div :class="subField.viewCss">
                                                                            {{
                                                                                $filters.formatCurrency(model[field.model][field.subsection][section.section.sec_id][index][subField.indexationStorage])
                                                                            }}
                                                                        </div>
                                                                    </template>
                                                                    <template v-else-if="subField.formatter === 'picker'">
                                                                        <div :class="subField.viewCss">
                                                                            {{
                                                                                model[field.model][field.subsection][section.section.sec_id][index][subField.indexationStorage]
                                                                            }}
                                                                        </div>
                                                                    </template>
                                                                    <template v-else-if="subField.formatter === 'percent'">
                                                                        <div :class="subField.viewCss">
                                                                            {{
                                                                                $filters.formatPercent(model[field.model][field.subsection][section.section.sec_id][index][subField.indexationStorage])
                                                                            }}
                                                                        </div>
                                                                    </template>
                                                                    <template v-else-if="subField.formatter === 'objectpicker'">
                                                                        <div :class="subField.viewCss">
                                                                            {{
                                                                                model[field.model][field.subsection][section.section.sec_id][index][subField.indexationStorage].name
                                                                            }}
                                                                        </div>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div :class="subField.viewCss">
                                                                            {{
                                                                                model[field.model][field.subsection][section.section.sec_id][index][subField.indexationStorage]
                                                                            }}
                                                                        </div>
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <component
                                                                        :is="getFieldType(subField)"
                                                                        :editable="fieldIsEditable(field)"
                                                                        :formOptions="options"
                                                                        :id="getFieldIDIdxe(field, index, subIndex, section.section.sec_id)"
                                                                        :index="index"
                                                                        :indexation="true"
                                                                        :model="model"
                                                                        :schema="subField"
                                                                        :sectionId="section.section.sec_id"
                                                                        :subindex="subIndex"
                                                                        @addFieldElement="addFieldElement"
                                                                        @formGeneratorForceRefresh="formGeneratorForceRefresh"
                                                                        @model-updated="modelUpdated"
                                                                        @validated="onFieldValidated"
                                                                    />
                                                                </template>
                                                            </template>

                                                            <div class="clear"></div>
                                                            <div v-if="subField.errors" class="support-block">
                                                                <div
                                                                    v-if="indexedErrors(subField.inputName, index, subField.indexationStorage)"
                                                                    class="errors help-block"
                                                                >
                                                    <span
                                                        v-for="(error,index) in indexedFieldErrors[getPrefixForIndexed(subField.inputName, index, subField.indexationStorage)]"
                                                        style="text-align: left !important;position: absolute !important;; top: 30px !important;font-size: 9px !important;"
                                                        track-by="index"
                                                    >
                                                        {{ error }}
                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div v-if="subField.hint" v-html="subField.hint" class="hint"/>
                                                        </template>
                                                    </td>
                                                    <td
                                                        v-if="field.generate.content.isLocked == false"
                                                        style="max-width: 80px"
                                                        :class="getFieldRowClasses(field, subIndex, index)"
                                                    >
                                                        <template v-if="field.generate.content.removeSubRows !== false">
                                                            <i
                                                                class="fas fa-trash trash-icon io-trash-icon"
                                                                @click="deleteRow(field.model, index, section.section.sec_id, field.subsection)"
                                                            >
                                                            </i>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>

                                        <!-- summary last row -->
                                        <template v-if="field.summary !== undefined">
                                            <tr v-if="field.summary.enabled === true">
                                                <td
                                                    v-for="subField in field.custompack"
                                                    class="calculator"
                                                    style="background-color: unset;"
                                                >
                                                    <template v-if="inArray(subField.indexationStorage, field.summary.columns)">
                                                        <template
                                                            v-if="field.discounted === true && field.discounted_field !== undefined && parseInt(model[field.discounted_field][section.section.sec_id]) > 0"
                                                        >
                                                    <span style="color: #f00; font-style: normal; text-decoration: line-through">
                                                        {{
                                                            $filters.formatCurrency(calculateTotalCustomColumn(field,
                                                                model,
                                                                section.section.sec_id,
                                                                subField.indexationStorage))
                                                        }}
                                                    </span>
                                                            <br/>

                                                            <span
                                                                v-if="model[field.discounted_field + '_type'][section.section.sec_id] === '$'"
                                                                style="color: green; font-weight: bold;"
                                                            >
                                                        -${{ model[field.discounted_field][section.section.sec_id] }} discount
                                                    </span>

                                                            <span
                                                                v-if="model[field.discounted_field + '_type'][section.section.sec_id] !== '$'"
                                                                style="color: green; font-weight: bold;"
                                                            >
                                                        -{{ model[field.discounted_field][section.section.sec_id] }}% discount
                                                    </span>
                                                            <br/>

                                                            <span style="color: #000; font-size: 110%">
                                                        {{
                                                                    $filters.formatCurrency(calculateTotalCustomColumn(field,
                                                                        model,
                                                                        section.section.sec_id,
                                                                        subField.indexationStorage,
                                                                        model[field.discounted_field][section.section.sec_id],
                                                                        model[field.discounted_field + '_type'][section.section.sec_id]))
                                                                }}
                                                    </span>
                                                        </template>
                                                        <template
                                                            v-if="(field.discounted === true && field.discounted_field !== undefined && (parseInt(model[field.discounted_field][section.section.sec_id]) === 0 || model[field.discounted_field][section.section.sec_id] === '')) || field.discounted !== true"
                                                        >
                                                    <span style="color: #000; font-size: 110%">
                                                        {{
                                                            $filters.formatCurrency(calculateTotalCustomColumn(field, model, section.section.sec_id, subField.indexationStorage))
                                                        }}
                                                    </span>
                                                        </template>
                                                    </template>
                                                </td>
                                                <td
                                                    v-if="field.generate.content.isLocked === false"
                                                    style="background-color: unset;"
                                                ></td>
                                            </tr>
                                        </template>

                                    </tbody>
                                    <tfoot>
                                        <tr v-if="field.generate.content.isLocked === false && field.subrows === true">
                                            <td
                                                class="aright noBorder"
                                                style="padding: 10px; background-color: unset;"
                                                :colspan="field.custompack.length + 1"
                                            >
                                                <a
                                                    class="button float-right mr-0 mb-0 io-btn-primary"
                                                    @click="addComboRow(field.model, section.section.sec_id, field, field.subrows, section.section.reimbursable)"
                                                >
                                                    <i class="fa fa-plus-circle"></i>
                                                    Add Row
                                                </a>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </template>
                        </template>
                    </template>
                </template>

            </template>
        </template>

        <template v-for="(group, index) in groups" :key="`group_${index}`">
            <fieldset>
                <legend v-if="group.legend">{{ group.legend }}</legend>
                <template v-for="(field, fieldIndex) in group.fields" :key="`field_${ fieldIndex }`">
                    <div v-if="fieldVisible(field)" class="form-group" :class="getFieldRowClasses(field)">
                        <i v-if="fieldTypeHasIcon(field)" class="io-ing-icon" :class="getFieldIcon(field)"/>
                        <label
                            v-if="fieldTypeHasLabel(field)"
                            :for="getFieldID(field)"
                        >
                            {{ field.label }}
                            <span v-if="field.help" class="help">
                            <i class="icon">
                                <div class="helpText">{{ field.help }}</div>
                            </i>
                        </span>
                        </label>

                        <div class="field-wrap io-form-field-holder">
                            <component
                                :is="getFieldType(field)"
                                :disabled="fieldDisabled(field)"
                                :editable="fieldIsEditable(field)"
                                :formOptions="options"
                                :model="model"
                                :myButtons="myButtons"
                                :schema="field"
                                @addFieldElement="addFieldElement"
                                @model-updated="modelUpdated"
                                @validated="onFieldValidated"
                            >
                                <div v-if="buttonVisibility(field)" class="buttons">
                                    <button
                                        v-for="btn in field.buttons"
                                        :class="btn.classes"
                                        @click="buttonClickHandler(btn, field, $event)"
                                    >
                                        {{ $t(btn.label) }}
                                    </button>
                                </div>
                            </component>
                        </div>

                        <div v-if="field.hint" v-html="field.hint" class="hint" />
                        <div v-if="fieldErrors(field).length > 0" class="errors help-block">
                            <span
                                v-for="(error,index) in fieldErrors(field)"
                                :key="index"
                                track-by="index"
                            >
                                {{ index }}
                            </span>
                        </div>
                    </div>
                </template>
            </fieldset>
        </template>

        <div style="display: none">{{ trigger }}</div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { isNil, isFunction, each, isArray, isString, isUndefined, isNull } from 'lodash'
    import { slugifyFormID } from './utils/schema'
    import validators from '@/plugins/form-generator/utils/validators.js'
    import customComboToggler from '@/elements/ComboCustomTableToggler.vue'
    import apiMigrate from '@/base/apiMigrate'
    import isLoggedMixin from '@/mixins/isLoggedMixin'
    import ExportLogoPreview from '@/elements/ExportLogoPreview.vue'

    export default {
        mixins: [ apiMigrate, isLoggedMixin ],
        components: {
            fieldBar: defineAsyncComponent(() => import('./fields/core/fieldBar.vue')),
            fieldButton: defineAsyncComponent(() => import('./fields/core/fieldButton.vue')),
            fieldCheckbox: defineAsyncComponent(() => import('./fields/core/fieldCheckbox.vue')),
            fieldChecklist: defineAsyncComponent(() => import('./fields/core/fieldChecklist.vue')),
            fieldCombo: defineAsyncComponent(() => import('./fields/core/fieldCombo.vue')),
            fieldCustomBlock: defineAsyncComponent(() => import('./fields/core/fieldCustomBlock.vue')),
            fieldDiscount: defineAsyncComponent(() => import('./fields/core/fieldDiscount.vue')),
            fieldHeadline: defineAsyncComponent(() => import('./fields/core/fieldHeadline.vue')),
            fieldInput: defineAsyncComponent(() => import('./fields/core/fieldInput.vue')),
            fieldInputEdit: defineAsyncComponent(() => import('./fields/core/fieldInputEdit.vue')),
            fieldInputMulti: defineAsyncComponent(() => import('./fields/core/fieldInputMulti.vue')),
            fieldLabel: defineAsyncComponent(() => import('./fields/core/fieldLabel.vue')),
            fieldMix: defineAsyncComponent(() => import('./fields/core/fieldMix.vue')),
            fieldNumeric: defineAsyncComponent(() => import('./fields/core/fieldNumeric.vue')),
            fieldRadios: defineAsyncComponent(() => import('./fields/core/fieldRadios.vue')),
            fieldSelect: defineAsyncComponent(() => import('./fields/core/fieldSelect.vue')),
            fieldSelectIdx: defineAsyncComponent(() => import('./fields/core/fieldSelectIdx.vue')),
            fieldSubmit: defineAsyncComponent(() => import('./fields/core/fieldSubmit.vue')),
            fieldTemplate: defineAsyncComponent(() => import('./fields/core/fieldTemplate.vue')),
            fieldTextArea: defineAsyncComponent(() => import('./fields/core/fieldTextArea.vue')),
            fieldCleave: defineAsyncComponent(() => import('./fields/optional/fieldCleave.vue')),
            fieldCleaveIdx: defineAsyncComponent(() => import('./fields/optional/fieldCleaveIdx.vue')),
            fieldCleaveInt: defineAsyncComponent(() => import('./fields/optional/fieldCleaveInt.vue')),
            fieldColorPicker: defineAsyncComponent(() => import('./fields/optional/fieldColorPicker.vue')),
            fieldColorPicker2: defineAsyncComponent(() => import('./fields/optional/fieldColorPicker2.vue')),
            fieldDateTime: defineAsyncComponent(() => import('./fields/optional/fieldDateTime.vue')),
            fieldDateTimePicker: defineAsyncComponent(() => import('./fields/optional/fieldDateTimePicker.vue')),
            fieldDayRangePicker: defineAsyncComponent(() => import('./fields/optional/fieldDayRangePicker.vue')),
            fieldGroupmasked: defineAsyncComponent(() => import('./fields/optional/fieldGroupmasked.vue')),
            fieldImage: defineAsyncComponent(() => import('./fields/optional/fieldImage.vue')),
            fieldLabelSelect: defineAsyncComponent(() => import('./fields/optional/fieldLabelSelect.vue')),
            fieldMasked: defineAsyncComponent(() => import('./fields/optional/fieldMasked.vue')),
            fieldMath: defineAsyncComponent(() => import('./fields/optional/fieldMath.vue')),
            fieldProposedId: defineAsyncComponent(() => import('./fields/optional/fieldProposedId.vue')),
            fieldRiskTags: defineAsyncComponent(() => import('./fields/optional/fieldRiskTags.vue')),
            fieldSeparator: defineAsyncComponent(() => import('./fields/optional/fieldSeparator.vue')),
            fieldSmartTags: defineAsyncComponent(() => import('./fields/optional/fieldSmartTags.vue')),
            fieldSortableList: defineAsyncComponent(() => import('./fields/optional/fieldSortableList.vue')),
            fieldSwitch: defineAsyncComponent(() => import('./fields/optional/fieldSwitch.vue')),
            fieldTags: defineAsyncComponent(() => import('./fields/optional/fieldTags.vue')),
            fieldTreeSelect: defineAsyncComponent(() => import('./fields/optional/fieldTreeSelect.vue')),
            fieldUpload: defineAsyncComponent(() => import('./fields/optional/fieldUpload.vue')),
            fieldVueDateTime: defineAsyncComponent(() => import('./fields/optional/fieldVueDateTime.vue')),
            fieldVueMultiSelect: defineAsyncComponent(() => import('./fields/optional/fieldVueMultiSelect.vue')),
            fieldVueMultiSelectCachedList: defineAsyncComponent(() => import('./fields/optional/fieldVueMultiSelectCachedList.vue')),
            fieldVueMultiSelectIdx: defineAsyncComponent(() => import('./fields/optional/fieldVueMultiSelectIdx.vue')),
            fieldVueMultiSelectEditable: defineAsyncComponent(() => import('./fields/optional/fieldVueMultiSelectEditable.vue')),
            fieldWysiwyg: defineAsyncComponent(() => import('./fields/optional/fieldWysiwyg.vue')),
            fieldLegalEntityLogo: defineAsyncComponent(() => import('./fields/optional/fieldLegalEntityLogo.vue')),
            fieldProjectClientChange: defineAsyncComponent(() => import('./fields/optional/fieldProjectClientChange.vue')),
            'custom-combo-toggler': customComboToggler,
            ExportLogoPreview
        },
        props: {
            schema: Object,
            myButtons: Array,
            model: Object,
            options: {
                type: Object,
                default () {
                    return {
                        validateAfterLoad: false,
                        validateAfterChanged: false,
                        validationErrorClass: 'error',
                        validationSuccessClass: '',
                    }
                },
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            isNewModel: {
                type: Boolean,
                default: false,
            },
            tag: {
                type: String,
                default: 'fieldset',
                validator (value) {
                    return value.length > 0
                },
            },
        },
        data () {
            return {
                formGeneratorMainKey: 1,
                vErrors: false,
                virtualFields: [], // list of virtual fields for indexed sets req for validation
                errors: [], // Validation errors,
                indexedFieldErrors: {}, // special array for indexed fields
                indexedFieldErrorsFound: null, // special array for indexed fields
                fieldElements: [],
            }
        },
        computed: {
            trigger () {
                if (this.$parent.reqLogicChanged === true) {
                    this.clearValidationErrors()
                    this.validate()
                }
            },
            fields () {
                let res = []
                if (this.schema && this.schema.fields) {
                    each(this.schema.fields, (field) => {
                        if (!this.multiple || field.multi === true) {
                            res.push(field)
                        }
                    })
                }
                return res
            },
            groups () {
                let res = []
                if (this.schema && this.schema.groups) {
                    each(this.schema.groups, (group) => {
                        res.push(group)
                    })
                }
                return res
            },
        },

        created () {
            if (this.model) {
                this.setModelInitialData()
                // First load, running validation if necessary
                if (this.options.validateAfterLoad === true && this.isNewModel !== true) {
                    this.validate()
                } else {
                    this.clearValidationErrors()
                }
            }
        },

        methods: {
            addFieldElement (e) {
                this.fieldElements.push(e)
            },
            setModelInitialData () {
                if (this.isNewModel) {
                    for (const field of this.fields) {
                        if (field.initialValue && isFunction(field.initialValue)) {
                            Object.assign(this.model, field.initialValue(field))
                        }
                    }
                }
            },
            getSchemaField (field, fieldIdx) {
                let schemaFieldElement = {}
                let formElementsFields = []
                if (typeof this.$parent.getCurrentTabFormElements === 'function') {
                    formElementsFields = this.$parent.getCurrentTabFormElements().fields
                }

                for (let i = 0; i < formElementsFields.length; i++) {
                    if (fieldIdx !== undefined && i === fieldIdx) {
                        schemaFieldElement = formElementsFields[i]
                        break
                    } else if ((formElementsFields[i] !== undefined) && (formElementsFields[i].model === field.model)) {
                        schemaFieldElement = formElementsFields[i]
                        break
                    }
                }
                return schemaFieldElement
            },
            formGeneratorForceRefresh () {
                this.$forceUpdate()
            },
            setStyle (key, value) {
                return key + ': ' + value + 'px'
            },
            determineHeadlineHiding (field, sectionId) {
                var hide = false

                // but we also need to consider 2 cases
                if (field.generate.content.hideHeadlines === true) {
                    hide = !field.generate.content.hideHeadlines
                    if (this.model[field.model].sections) {
                        if (this.model[field.model].sections[sectionId]) {
                            if ((this.model[field.model].sections[sectionId].length > 0) === true) {
                                // sa sekcje
                                hide = true
                            } else {
                                // nie ma sekcji, ale sprawdzamy czt jakies cystomowe
                                if (this.model[field.model][field.subsection]) {
                                    if (this.model[field.model][field.subsection][sectionId]) {
                                        if (this.model[field.model][field.subsection][sectionId].length > 0) {
                                            hide = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return !hide
            },
            getFieldPack (index, packSchema) {
                return this.fields[index][packSchema]
            },
            getPrefixForIndexed (name, index, subindex) {
                return name + '_' + index + '_' + subindex
            },
            indexedErrors (name, index, subindex) {
                let prefix = name + '_' + index + '_' + subindex
                if (this.indexedFieldErrors[prefix] !== undefined && this.indexedFieldErrors[prefix].length > 0) {
                    return true
                }
                return false
            },
            bindGroupFieldSplit (split) {
                return 'display: inline-block; width: ' + split + '%'
            },
            getFieldIDIdxe (schema, idx, subindex = false, sectionId = false) {
                let x = schema.inputName
                if (schema.subsection !== undefined) {
                    x = x + '_' + schema.subsection
                }
                if (sectionId !== false) {
                    x = x + '_' + sectionId
                }
                x = x + '_' + idx
                if (subindex !== false) {
                    x = x + '_' + subindex
                }

                return x
            },
            getFieldIDPrefixIdx (schema, prefix, idx) {
                return schema.inputName + '_' + prefix + '_' + idx
            },
            addRow (fieldModel) {
                this.model[fieldModel].push('')
            },
            addMixRow (fieldModel) {
                this.model[fieldModel].push({ key: '', value: '' })
            },
            addComboRow (fieldModel, sectionId = false, field = null, subrows = false, reimbursable = false) {
                if (sectionId === false) {
                    if (fieldModel === 'res_ownerships') {
                        this.model[fieldModel].push({
                            id: false,
                            param0: {
                                id: '',
                                name: '',
                            },
                            param1: '',
                            param2: '',
                            param3: '',
                            param4: '',
                            param5: {
                                id: '',
                                name: '',
                            },
                            param6: '',
                            param7: '',
                        })
                    } else if (fieldModel === 'c_address' || fieldModel === 'res_address') {
                        let countryIndex = fieldModel === 'c_address' ? 10 : 6
                        this.model[fieldModel].push({
                            editLocation: false,
                            param1: '',
                            param2: '',
                            param3: '',
                            param4: {
                                id: 0,
                                name: '',
                            },
                            param5: '',
                            param6: '',
                            param7: '',
                            param8: '',
                            param9: '',
                            param10: '',
                        })
                        this.addComboGoogleAddressSearch(this.model[fieldModel], fieldModel, countryIndex)
                    } else {
                        this.model[fieldModel].push({})
                    }
                } else {
                    if (subrows === true) {
                        if (this.model[fieldModel].custom[sectionId]) {
                            this.model[fieldModel].custom[sectionId].push({})
                        } else {
                            // element not exists in original model, so we use field schema
                            // this.model[fieldModel].custom[sectionId] = []
                            // this.model[fieldModel].custom[sectionId].push(field.pack_scheme)
                            this.model[fieldModel].custom[sectionId] = []
                            this.model[fieldModel].custom[sectionId][0] = field.pack_scheme
                        }
                        // this will set QTY = 1 when adding new subrow
                        var totalx = this.model[fieldModel].custom[sectionId].length - 1

                        this.model[fieldModel].custom[sectionId][totalx].qty = 1
                        if (reimbursable === true) {
                            this.model[fieldModel].custom[sectionId][totalx].reimbursable = true
                        }

                        if (this.$parent) {
                            if (isFunction(this.$parent.onAfterAddRowComboTable)) {
                                this.$parent.onAfterAddRowComboTable.call(this, fieldModel, 'custom', sectionId)
                            } else if (this.$parent) {
                                if (isFunction(this.$parent.onAfterAddRowComboTable)) {
                                    this.$parent.onAfterAddRowComboTable.call(this, fieldModel, 'custom', sectionId)
                                }
                            }
                        }
                    } else {
                        if (this.model[fieldModel].sections[sectionId]) {
                            this.model[fieldModel].sections[sectionId].push({})
                        } else {
                            // element not exists in original model, so we use field schema
                            // this.model[fieldModel].sections[sectionId] = []
                            // this.model[fieldModel].sections[sectionId].push(field.pack_scheme)

                            this.model[fieldModel].sections[sectionId] = []
                            this.model[fieldModel].sections[sectionId][0] = field.pack_scheme
                        }
                        // this will set QTY = 1 when adding new subrow
                        var totaly = this.model[fieldModel].sections[sectionId].length - 1
                        this.model[fieldModel].sections[sectionId][totaly].qty = 1
                        // this.model[fieldModel].sections[sectionId][totalx].unit_price = 0
                        // this.model[fieldModel].sections[sectionId][totalx].row_price = 0
                    }
                }

                if (fieldModel === 'res_ownerships' && this.$parent.config.customMethods.changeMinority) {
                    let selfObj = this
                    var m = this.$parent.config.customMethods.changeMinority
                    var len = this.model[fieldModel].length - 1
                    var module = {
                        exec () {
                            return {
                                model: selfObj.model,
                                fields: selfObj.fields,
                                index: len,
                            }
                        },
                    }
                    this.$watch('model.res_ownerships.' + len + '.param0', m.bind(module), { deep: true })
                }
            },
            deleteRowDiversity (fieldModel, index) {
                this.model[fieldModel].splice(index, 1)
            },
            deleteRow (fieldModel, index, sectionId = false, custom = false, field) {
                let self = this
                if (sectionId === false) {
                    self.model[fieldModel].splice(index, 1)
                } else {
                    if (custom !== false) {
                        self.model[fieldModel][custom][sectionId].splice(index, 1)
                        self.$forceUpdate()
                    } else {
                        self.model[fieldModel].sections[sectionId].splice(index, 1)
                    }
                }
            },
            // Get style classes of field
            getFieldRowClasses (field, index = false, indexMain = false, viewCssForSubfield = false) {
                var hasErrors = false
                var groupFieldReq = false

                if (field.type === 'group') {
                    // console.log(this.errors)
                    let self = this
                    // console.log(field)
                    // we check if field of group is in error
                    for (var i = 0; i < field.groups[0].fields.length; i++) {
                        for (var j = 0; j < self.errors.length; j++) {
                            // console.log('Check in ERR' + self.errors[j].field.inputName + ' vs ' + field.groups[0].fields[i].inputName)
                            // console.log(field.groups[0])
                            if (self.errors[j].field.model === field.groups[0].fields[i].model) {
                                hasErrors = true
                            }
                        }
                    }
                    // check if field is required
                    for (var z = 0; z < field.groups[0].fields.length; z++) {
                        if (field.groups[0].fields[z].required === true) {
                            groupFieldReq = true
                        }
                    }
                } else if (field.type === 'combo' && (field.layout === 'vertical' || field.layout === 'horizontal' || field.layout === 'table')) {
                    if (index !== false) {
                        if (field.pack[index].required !== undefined) {
                            if (field.pack[index].required === true) {
                                groupFieldReq = true
                            }
                        }

                        if (this.model[field.inputName][indexMain] !== undefined) {
                            let indexationStorage = field.pack[index].indexationStorage
                            if (this.model[field.inputName][indexMain][indexationStorage]) {
                                let customRequired = this.model[field.inputName][indexMain][indexationStorage].required
                                if (customRequired !== undefined) {
                                    groupFieldReq = customRequired
                                }
                            }
                        }

                        let prefix = field.pack[index].inputName + '_' + indexMain + '_' + field.pack[index].indexationStorage
                        if (this.indexedFieldErrors[prefix] !== undefined) {
                            hasErrors = this.indexedFieldErrors[prefix].length > 0
                        }
                    }
                } else {
                    hasErrors = this.fieldErrors(field).length > 0
                }

                let baseClasses = {
                    error: hasErrors,
                    disabled: this.fieldDisabled(field),
                    readonly: this.fieldReadonly(field),
                    featured: this.fieldFeatured(field),
                    required: (groupFieldReq === true) ? true : this.fieldRequired(field),
                }

                let { validationErrorClass, validationSuccessClass } = this.options
                if (validationErrorClass && validationSuccessClass) {
                    if (hasErrors) {
                        baseClasses[validationErrorClass] = true
                        baseClasses.error = false
                    } else {
                        baseClasses[validationSuccessClass] = true
                    }
                }

                if (isArray(field.styleClasses)) {
                    each(field.styleClasses, (c) => (baseClasses[c] = true))
                } else if (isString(field.styleClasses)) {
                    baseClasses[field.styleClasses] = true
                }

                // tak dziala dodawanie wierszy
                // baseClasses['field-' + field.type] = true
                // z tym nie dziala dodawanie wierszy
                if (field.type === 'combo' && (field.layout === 'vertical' || field.layout === 'horizontal' || field.layout === 'table')) {
                    if (field.pack[index] !== undefined) {
                        baseClasses['field-' + field.pack[index].type] = true
                        baseClasses['io-field-' + field.pack[index].type] = true
                    }
                } else {
                    baseClasses['field-' + field.type] = true
                    baseClasses['io-field-' + field.type] = true
                }

                if (viewCssForSubfield !== false) {
                    baseClasses[viewCssForSubfield] = true
                }

                if (this.getSchemaField(field, index).extraComponent) {
                    baseClasses['io-form-row-extra-component'] = true
                }

                return baseClasses
            },
            getDisabledValue (data, subField) {
                // let disabled = this.model[field.inputName][indexMain][indexationStorage].disabled
                // if (disabled !== undefined) {
                //    groupFieldDisabled = disabled
                // }
                if (subField.indexationStorage) {
                    let indexationStorage = subField.indexationStorage
                    if (data[indexationStorage] && (data[indexationStorage].isDisabled !== undefined)) {
                        return data[indexationStorage].isDisabled
                    }
                }
                if (data.isDisabled === true) {
                    if (subField.type === 'switch') {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            },
            // Check if field is editable
            fieldIsEditable (field) {
                if (isUndefined(field.editable) || isNull(field.editable)) {
                    return true
                }
                if (!this.fieldVisible(field)) {
                    return false
                }
                if (this.fieldDisabled(field)) {
                    return false
                }
                if (typeof field.editable === 'string' && 'true' === field.editable) {
                    return true
                }
                return field.editable
            },

            // Get type of field 'field-xxx'. It'll be the name of HTML element
            getFieldTypeByName (fieldSchemaName) {
                return 'field-' + fieldSchemaName
            },
            getFieldType (fieldSchema) {
                return 'field-' + fieldSchema.type
            },
            getFieldIcon (fieldSchema) {
                let classes = {}
                for (let i = 0; i < fieldSchema.icon.length; i++) {
                    classes[fieldSchema.icon[i]] = true
                }
                return classes
            },
            // Should field type have a icon?
            fieldTypeHasIcon (field) {
                return (field.icon !== undefined && field.icon.length)
            },
            // Should field type have a label?
            fieldTypeHasLabel (field) {
                if (field.fullWidth === true) {
                    return false
                }

                let relevantType = ''
                if (field.type === 'input') {
                    relevantType = field.inputType
                } else {
                    relevantType = field.type
                }

                switch (relevantType) {
                    case 'formbar':
                    case 'button':
                    case 'headline':
                    case 'separator':
                    case 'submit':
                    case 'reset':
                        return false
                    default:
                        return true
                }
            },
            getClass (field) {
                if (field.component === true) {
                    return 'col-xl-10 col-lg-9 col-md-8 col-sm-6 col-12'
                }
                if (this.schema.separate === true) {
                    return 'col-12'
                }
                if (field.type === 'formbar' || field.type === 'headline' || field.type === 'separator' || field.fullWidth === true) {
                    return 'col-12'
                } else if (this.$parent.form_elements.col === undefined) {
                    return 'col-xl-10 col-lg-9 col-md-8 col-sm-6 col-12'
                } else if (this.$parent.form_elements.col === 'additional_right_menu') {
                    return 'col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12'
                }
            },
            getColumn (field) {
                if (field.component === true) {
                    return 'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 text-lg-right text-sm-right align-middle'
                }
                let css = ''

                if (this.schema.separate === true) {
                    return css
                }
                if (this.$parent.form_elements.col === undefined) {
                    css += 'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 text-lg-right text-sm-right align-middle'
                } else if (this.$parent.form_elements.col === 'additional_right_menu') {
                    css += 'col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 text-lg-right text-xl-right align-middle'
                }
                if (field.labelCss !== undefined) {
                    css = css + ' ' + field.labelCss
                }
                return css
            },
            // Get disabled attr of field
            fieldDisabled (field, isGlobalLock = false) {
                if (isGlobalLock === true) {
                    return true
                }
                if (isFunction(field.disabled)) {
                    return field.disabled.call(this, this.model, field, this)
                }
                if (isNil(field.disabled)) {
                    return false
                }
                return field.disabled
            },
            // Get required prop of field
            fieldRequired (field) {
                if (isFunction(field.required)) {
                    return field.required.call(this, this.model, field, this)
                }
                if (isNil(field.required)) {
                    return false
                }
                return field.required
            },
            // Get visible prop of field
            fieldVisible (field) {
                if (field.appTypes !== undefined && !this.appAvail(field.appTypes)) {
                    return false
                }

                if (isFunction(field.visible)) {
                    const func = new Function('model', `return ${ field.visible }`)

                    return func.call(this, this.model)
                }

                if (isNil(field.visible)) {
                    return true
                }
                return field.visible
            },
            // Get readonly prop of field
            fieldReadonly (field) {
                if (isFunction(field.readonly)) {
                    return field.readonly.call(this, this.model, field, this)
                }
                if (isNil(field.readonly)) {
                    return false
                }
                return field.readonly
            },
            // Get featured prop of field
            fieldFeatured (field) {
                if (isFunction(field.featured)) {
                    return field.featured.call(this, this.model, field, this)
                }
                if (isNil(field.featured)) {
                    return false
                }
                return field.featured
            },
            buttonClickHandler (btn, field, event) {
                return btn.onclick.call(this, this.model, field, event, this)
            },
            // Child field executed validation
            onFieldValidated (res, errors, field) {
                if (field.indexation === true) {
                }
                // Remove old errors for this field
                this.errors = this.errors.filter(e => e.field !== field.schema)

                if (!res && errors && errors.length > 0) {
                    // Add errors with this field
                    errors.forEach((err) => {
                        this.errors.push({
                            field: field.schema,
                            error: err,
                        })
                    })
                }

                let isValid = this.errors.length === 0
                this.$emit('validated', isValid, this.errors)
            },
            // Validating the model properties
            validate () {
                this.clearValidationErrors()
                let self = this
                // clear
                self.virtualFields = []
                self.indexedFieldErrors = []

                // reindex all indexed fields by model...
                for (let i = 0; i < this.fields.length; i++) {
                    if (this.fields[i].indexation === true) {
                        // jest indexowane pole, a wiec musimy sie odwolac do modelu
                        let modelHandler = this.model[this.fields[i].inputName]
                        // now we create virtual fields
                        for (let j = 0; j < modelHandler.length; j++) {
                            // rob X razy
                            for (let z = 0; z < this.fields[i].pack.length; z++) {
                                var obj = JSON.stringify(this.schema.fields[i].pack[z])

                                obj = JSON.parse(obj)

                                if (modelHandler[j][obj.indexationStorage]) {
                                    let customRequired = modelHandler[j][obj.indexationStorage].required
                                    if (customRequired !== undefined) {
                                        obj.required = customRequired
                                    }

                                    let customValidator = modelHandler[j][obj.indexationStorage].validator
                                    if (customValidator !== undefined) {
                                        obj.validator = customValidator
                                    }
                                }
                                // this.fields[i].pack[z].indexedName = this.fields[i].inputName + '_' + j + '_' + obj.indexationStorage
                                obj.virtual = true
                                obj.indexedName = this.fields[i].inputName + '_' + j + '_' + obj.indexationStorage
                                obj.indexID = j
                                obj.subindexID = z

                                let exists = false
                                this.fieldElements.forEach((child) => {
                                    if (child.schema && child.schema.inputName === obj.indexedName) {
                                        exists = true
                                    }
                                })
                                if (exists === false) {
                                    // this.fields[i].pack[z].inputName = self.schema.fields[i].pack[z].indexedName
                                    self.virtualFields.push(obj)
                                }
                                // this.model[this.fields[i].inputName + '_' + j + '_' + this.fields[i].pack[z].indexationStorage] = this.model[this.schema.fields[i].inputName][j][this.fields[i].pack[z].indexationStorage]
                                // console.log(this.model)
                            }
                        }
                    }
                }

                // reindex also all fields used within separate components (classified as vField[])
                for (let i = 0; i < this.$parent.vFields.length; i++) {
                    if (this.$parent.vFields[i].indexation === true) {
                        // jest indexowane pole, a wiec musimy sie odwolac do modelu
                        let modelHandler = this.model[this.$parent.vFields[i].inputName]
                        if (modelHandler !== undefined && modelHandler !== null && modelHandler !== '') {
                            // now we create virtual fields
                            for (let j = 0; j < modelHandler.length; j++) {
                                // rob X razy
                                for (let z = 0; z < this.$parent.vFields[i].pack.length; z++) {
                                    var vobj = JSON.stringify(this.$parent.vFields[i].pack[z])

                                    vobj = JSON.parse(vobj)

                                    // this.fields[i].pack[z].indexedName = this.fields[i].inputName + '_' + j + '_' + obj.indexationStorage

                                    vobj.virtual = true
                                    vobj.indexedName = this.$parent.vFields[i].inputName + '_' + j + '_' + vobj.indexationStorage
                                    vobj.indexID = j
                                    vobj.subindexID = z

                                    let exists = false
                                    this.fieldElements.forEach((child) => {
                                        if (child.schema.inputName === vobj.indexedName) {
                                            exists = true
                                        }
                                    })
                                    if (exists === false) {
                                        // this.fields[i].pack[z].inputName = self.schema.fields[i].pack[z].indexedName
                                        this.virtualFields.push(vobj)
                                    }
                                    // this.model[this.fields[i].inputName + '_' + j + '_' + this.fields[i].pack[z].indexationStorage] = this.model[this.schema.fields[i].inputName][j][this.fields[i].pack[z].indexationStorage]
                                    // console.log(this.model)
                                }
                            }
                        }
                    }
                }
                var vErrors = false
                // run validation for indexed sets
                for (let i = 0; i < this.virtualFields.length; i++) {
                    // this.fields.push(this.virtualFields[i])
                    this.indexedFieldErrors[this.virtualFields[i].indexedName] = []
                    var indexedErr = []
                    if (this.virtualFields[i].validator !== undefined) {
                        for (let j = 0; j < this.virtualFields[i].validator.length; j++) {
                            let validator = this.virtualFields[i].validator[j]
                            let testedVal = this.model[this.virtualFields[i].inputName][this.virtualFields[i].indexID][this.virtualFields[i].indexationStorage]
                            let errors = validators[validator](testedVal, this.virtualFields[i])
                            if (errors) {
                                for (let z = 0; z < errors.length; z++) {
                                    indexedErr.push(errors[z])
                                    vErrors = true
                                }
                            }
                        }
                        this.indexedFieldErrors[this.virtualFields[i].indexedName] = indexedErr
                    }
                }
                this.fieldElements.forEach((child) => {
                    if (child.schema && child.schema.indexationStorage !== undefined) {
                    } else if (child.schema) {
                        // this validates 'normal' fields
                        if (isFunction(child.validate)) {
                            let errors = child.validate(true)
                            errors.forEach((err) => {
                                this.errors.push({
                                    field: child.schema,
                                    error: err,
                                })
                            })
                        }
                    }
                })
                let isValid = (this.errors.length === 0 && vErrors === false)
                this.$emit('validated', isValid, this.errors)
                return isValid
            },
            // Clear validation errors
            clearValidationErrors () {
                this.errors.splice(0)

                each(this.fieldElements, (child) => {
                    if (typeof child.clearValidationErrors === 'function') {
                        child.clearValidationErrors()
                    }
                })
            },
            modelUpdated (newVal, schema) {
                this.$emit('model-updated', newVal, schema)
            },
            buttonVisibility (field) {
                return field.buttons && field.buttons.length > 0
            },
            fieldErrors (field) {
                let res = this.errors.filter(e => e.field === field)
                return res.map(item => item.error)
            },
            getFieldID (schema) {
                const idPrefix = this.options && this.options.fieldIdPrefix ? this.options.fieldIdPrefix : ''
                return slugifyFormID(schema, idPrefix)
            },
            reIndexField (field) {
                return field
            },
            getPack (section) {
                if (section.pack !== undefined) {
                    return section.pack
                } else {
                    return 'pack'
                }
            },
            hideSectionHeader (arr) {
                var total = (arr.length !== undefined) ? arr.length : 0
                var hidden = 0
                for (let i = 0; i < total; i++) {
                    if (arr[i].hide !== undefined && arr[i].hide === true) {
                        hidden++
                    }
                }
                if (total === hidden) {
                    return false
                }
                return true
            },
        },
        directives: {
            focus: {
                inserted (el) {
                    el.querySelector('input').focus()
                },
            },
        },
    }
</script>
