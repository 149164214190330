export function SET_LIST (state, value) {
    state.lists[value.type] = value.data
}

export function ADD_TO_LIST (state, payload) {
    const type = payload.type
    const data = payload.data

    data.forEach(item => {
        const itemIndex = state.lists[type].findIndex(origin => origin._id === item._id)
        if (-1 === itemIndex) {
            state.lists[type].push(item)
        } else {
            state.lists[type][itemIndex] = item
        }
    })
}

export function SET_CACHE_INFO (state, value) {
    state.cacheInfo[value.type] = {...value.data}
}

export function SET_LAST_MODIFIED (state, value) {
    state.lastModified[value.type] = value.data
}

export function SET_PROMISE (state, data) {
    state.promises[data.type] = data.promise
}

export function REMOVE_PROMISE (state, type) {
    state.promises[type] = false
}

export function CLEAR_LIST (state, type) {
    state.lists[type] = []
    state.lastModified[type] = null
    state.cacheInfo[type] = null
}

export function UPDATE_PROJECT_CONTRACT_OPEN_BOOK_BY_ID (state, { id, openBook }) {
    const projects = [ ...state.lists.projects ]
    const index = projects.findIndex((project) => project.id === id)
    if (index !== -1) {
        projects[index]._contract_open_book = openBook
        state.lists.projects = [ ...projects ]
    }
}
