<template>
    <div :class="checkFormContainerClass()">
        <div v-if="rendered" class="io-inner-form-container">
            <vuePageTitle
                v-if="!simpleMode && config.hideTitle !== true"
                :title="$t(pageTitle)"
                :tabs="selectedTabs"
                :schemaSlug="currentSchemaSlug"
                :record="model"
            />

            <form action="" ref="editForm" enctype="multipart/form-data" :class="getFormClass()" v-on:submit.prevent>
                <div
                    v-for="(tab, index) in selectedTabs" v-show="activeTab === index && allowedToShow(tab.ref) === true"
                    :key="index"
                    class="tab-content" :id="setTabIndex(index)"
                >
                    <vue-form-generator
                        :isNewModel="!$route.params.id"
                        :key="uuid"
                        :schema="tab.schema"
                        :model="model"
                        :options="formOptions"
                        :ref="tab.ref"
                        @model-updated="checkForChanges"
                    />

                    <div>
                        <template v-if="tab.widgets.length > 0">
                            <template v-for="(widget, index) in tab.widgets" :key="index">
                                <component
                                    :is="getComponent(widget).id"
                                    :label="getComponent(widget).title"
                                    :widget="widget"
                                    :ref="`widget_${widget}`"
                                    :messages="i18n.messages"
                                    :data="getExtraData(getComponent(widget))"
                                    :settings="config.componentsSettings"
                                    :sourceId="$route.params.id"
                                />
                            </template>
                        </template>
                    </div>
                </div>
                <form-bar
                    v-if="!simpleMode"
                    viewMode="edit"
                    :buttons="bottomButtons"
                />
            </form>
        </div>

        <div v-if="rendered && config.layoutTwoColumns" class="io-form-two-columns-second-column">
            <component
                :is="config.secondColumnComponent"
                :model="model"
            />
        </div>

    </div>
</template>

<script>
    import { isUndefined, uniqueId, isFunction, isArray, get, merge, cloneDeep, assign, isObject, isEqual } from 'lodash'
    import { SweetAlert } from '@/plugins/sweet-alert/index.js'
    import VueFormGenerator from '@/plugins/form-generator/formGenerator.vue'
    import isLoggedMixin from '@/mixins/isLoggedMixin'
    import ModalPopup from '@/elements/ModalPopup.vue'
    import FederalHolidaysCalendar from '@/elements/FederalHolidaysCalendar.vue'
    import ComboTable from '@/elements/ComboTable.vue'
    import UsersRateUpdater from '@/elements/UsersRateUpdater.vue'
    import ExportLogoPreview from '@/elements/ExportLogoPreview.vue'
    import apiMigrate from '@/base/apiMigrate'
    import vuePageTitle from '@/components/page-title/PageTitle.vue'
    import formBar from '@/components/form-bar/FormBar.vue'
    import { mapState } from 'vuex'
    import qs from '@/helpers/qs.js'
    import { defineAsyncComponent } from 'vue'
    import widget from '@/filters/widgets'
    import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'

    export default {
        props: {
            /**
             * Simple mode with only form, without page title & buttons
             */
            simpleMode: false,
            /**
             * Simple mode with only form, without page title
             */
            hideTitle: false,

            /**
             * Connection object to move data to parent (for simple mode) by reference
             */
            parentReference: {},

            forcedId: {},
            forcedData: {},
            forcedParams: {},
            forceTriggerRefresh: {},
            forceHideBottomButtons: {
                type: Boolean,
                required: false,
                default: false,
            },
            forceModalClose: {
                type: Boolean,
                required: false,
            },
            forcedSource: {
                type: String,
                required: false, // not req cos alternatively we use from url
            },
            returnData: {},
            parentActions: {},
            onlySetReturnData: {
                type: Boolean,
                required: false,
                defeult: false,
            },
            otherParams: {
                allowAutocomplete: false,
            },
        },
        name: 'EditDefault',
        mixins: [ SweetAlert, isLoggedMixin, apiMigrate, featureFlagsMixin ],
        components: {
            'modal': ModalPopup,
            'vue-form-generator': VueFormGenerator,
            vuePageTitle,
            FederalHolidaysCalendar,
            ComboTable,
            UsersRateUpdater,
            formBar,
            ExportLogoPreview
        },
        data () {
            return {
                blockSubmit: false,
                modalData: {},
                modalVisible: false,
                currentSchemaSlug: '',
                reqLogicChanged: false,
                reqLogicChangedActivator: false,
                brandImage: null,
                selectedTabs: [],
                activeTab: 0,
                tabSwitched: false,
                rendered: false,
                config: {},
                module_config: {
                    componentsSettings: {},
                },
                i18n: {
                    interface: {},
                    buttons: {},
                    entity: {},
                    messages: {},
                },
                model: {},
                cloneModel: {},
                original_model: {},
                appType: '',
                orgFields: '',
                fields: [],
                extraData: {
                    widgets: [],
                    selections: {},
                    extraPack: {},
                    interfaceElementsLogic: {},
                },
                form_elements: {
                    tabs: [],
                },
                vFields: [],
                formOptions: {
                    validateAfterLoad: false,
                    validateAfterChanged: true,
                    validateBeforeSave: true,
                },
                back: '',
                bottomButtons: [],
                widgets: [],
                unsavedChanges: false,
                ajaxFiles: {},
                instances: {},
                refreshRequired: false,

                // Flags for components
                isModeEdit: true,
                isModeView: false,
                uuid: this.generateUuid(),
                saveSuccess: false,
            }
        },
        watch: {
            refreshRequired () {
                if (this.refreshRequired === true) {
                    this.refreshRequired = false
                }
            },
            parentActions (value) {
                if (typeof value !== 'undefined') {
                    if (value.submit) {
                        this.onSubmit(value.payload)
                        delete this.parentActions.submit
                    }
                }
            },
            rendered () {
                this.$emit('rendered')
            },
        },
        methods: {
            checkFormContainerClass () {
                let classes = 'io-standard-form-container io-container'
                if (this.config.layoutTwoColumns) {
                    classes += ' io-form-two-columns'
                }
                return classes
            },
            getFormClass () {
                return ''
            },
            allowedToShow (ref) {
                if (this.extraData.interfaceElementsLogic !== undefined) {
                    if (this.extraData.interfaceElementsLogic[ref] !== undefined) {
                        if (this.extraData.interfaceElementsLogic[ref] === true) {
                            return true
                        } else {
                            return false
                        }
                    }
                }
                return true
            },
            onTabSwitch () {
                this.tabSwitched = !this.tabSwitched
            },
            setTabLink (index) {
                return '#tab' + index
            },
            setTabIndex (index) {
                return 'tab' + index
            },
            getExtraData (widget) {
                if (widget.data !== undefined) {
                    widget = widget.data
                } else {
                    widget = widget.id
                }
                // check if data remap for common widgets
                if (this.extraData.widgets[widget] !== undefined) {
                    return this.extraData.widgets[widget]
                }
                return {}
            },
            getSweetAlertMessages (key) {
                if (this.i18n.messages !== undefined) {
                    if (this.i18n.messages[key] !== undefined) {
                        return this.i18n.messages[key]
                    }
                }
                return false
            },
            getCurrentTabFormElements () {
                return this.getFormTabs()[this.activeTab]
            },
            getFormTabs () {
                var tabs = this.form_elements.tabs
                // check if there is separate mode in tabs
                if (!isUndefined(this.form_elements.tabsSeparate) && !isUndefined(this.form_elements.tabsSeparate[this.appType])) {
                    tabs = this.form_elements.tabsSeparate[this.appType]
                }
                return tabs
            },
            onSubmitValidateForm () {
                var isValid = true

                // Get tabs or separate tabs
                var tabs = this.getFormTabs()
                // loop each tab and check fif there is anyt form and valid it,
                // if any of them if invalid - system will halt
                for (let i = 0; i < tabs.length; i++) {
                    var ref = tabs[i].ref
                    if (this.$refs?.[ref]?.[0]) {
                        isValid = this.$refs[ref][0].validate()
                    }

                    if (isValid) {
                        continue
                    }

                    let field = null
                    this.activeTab = i

                    if (this.$refs[ref] && this.$refs[ref][0].errors[0] !== undefined) {
                        field = $('[name="' + this.$refs[ref][0].errors[0].field.model + '"]')
                    } else {
                        // check indexed fields
                        var foundIndErr = false
                        var foundIndErrEl = ''
                        if (this.$refs[ref] && this.$refs[ref][0].indexedFieldErrors !== undefined) {
                            Object.keys(this.$refs[ref][0].indexedFieldErrors).forEach(key => {
                                var msg = this.$refs[ref][0].indexedFieldErrors[key]
                                if (msg.length > 0 && foundIndErr === false) {
                                    foundIndErr = true
                                    foundIndErrEl = key
                                }
                            })
                        }
                        if (foundIndErr === true) {
                            field = $('#' + foundIndErrEl)
                        }
                    }

                    if (field && field.length) {
                        $('html, body').animate({ scrollTop: field.offset().top / 2 }, 100)
                    }

                    this.$emit('isNotValid', this.activeTab)
                    isValid = false
                    return false
                }

                return isValid
            },

            onSubmit (payload) {
                if (this.blockSubmit) {
                    return
                }

                this.blockSubmit = true
                let validationResult = this.onSubmitValidateForm()
                if (!validationResult) {
                    this.blockSubmit = false
                    return false
                }

                this.saveSuccess = false

                let model
                if (this.model === undefined) {
                    model = {
                        extraDataSelections: [],
                    }
                    model.extraDataSelections = this.extraData
                } else {
                    model = this.model
                    model.extraDataSelections = []
                    model.extraDataSelections = this.extraData.selections
                }


                if (!payload.withoutRedirect) {
                    window.parent.$('#loader-wrap').show()
                    this.$store.dispatch('loadingBar/setLoadingSpinner', { status: true, button: payload })
                }

                var postPayload = this.model

                // Get additional data from vuex
                postPayload.vuex = this.$store.getters['appStore/getPostData']

                // remap to formData pack if postFormData required in schema
                this.model._all_files = this.ajaxFiles
                if (this.config.filesUploadMode === 'form') {
                    // this.remapToFormData()
                    this.formData.append('pack', JSON.stringify(this.model))
                    postPayload = this.formData
                } else if (this.config.filesUploadMode === 'ajax') {
                    Object.keys(this.ajaxFiles).forEach(key => {
                        var m = this.ajaxFiles[key]
                        this.model[key] = m
                    })
                    this.model.needQs = true
                    postPayload = this.model
                } else {
                    this.model.needQs = true
                    postPayload = this.model
                }

                let id = payload.id
                if (this.forcedId !== undefined) {
                    id = this.forcedId
                }

                // check if there is any CUSTOM fetch url
                if (this.$route.meta.save !== undefined) {
                    this.module_config.urls.save = this.$route.meta.save
                }

                var url = this.module_config.urls.save + '/' + id

                // Additional POST params
                if (this.module_config.isSubcomponent) {
                    postPayload = Object.assign({
                        [this.module_config.componentId]: this.$route.params[this.module_config.componentId],
                    }, postPayload)
                }

                let requestType = 'post'
                // send as json
                if (this.module_config.realJson) {
                    requestType = 'realJson'
                }


                return this.migrateToApi2(requestType, url, postPayload).then(async response => {
                    response = this.setResponseData(response)

                    window.parent.$('#loader-wrap').hide()

                    // emit
                    this.$emit('responseReceived', response)

                    if (this.callAfterSubmit && isFunction(this.callAfterSubmit)) {
                        this.callAfterSubmit(response)
                    }

                    // Save all widgets data if required
                    if (isArray(this.selectedTabs)) {
                        for (let tabIndex in this.selectedTabs) {
                            if (!isArray(this.selectedTabs[tabIndex].widgets)) {
                                continue
                            }

                            const widgets = this.selectedTabs[tabIndex].widgets
                            for (let widgetIndex in widgets) {
                                const refName = `widget_${ widgets[widgetIndex] }`
                                if (this.$refs[refName] && this.$refs[refName][0] && isFunction(this.$refs[refName][0].afterSubmit)) {
                                    await this.$refs[refName][0].afterSubmit()
                                }
                            }
                        }
                    }

                    // set payload id from response
                    if (get(response, 'result.id', null) !== null || get(response, 'id', null) !== null) {
                        payload.id = response.result.id || response.id
                        // used to redirect to view mode after saving new element
                    } else if (get(response, 'viewElementId', null) !== null) {
                        payload.id = response.viewElementId
                    }

                    this.blockSubmit = false

                    // MODALS
                    if (this.forceModalClose) {
                        // This is use for close old modal in project bills
                        window.parent.$('.fancybox-button--close').click()

                        this.$parent.showModalForm = false
                        this.$parent.triggerRefresh = this.forceTriggerRefresh
                        // if response OK
                        if (response.code === 200) {
                            this.model.card_id = response.result
                            this.$store.dispatch('modal/setReturnData', {
                                type: this.$store.getters['modal/getShow'],
                                data: this.model,
                                response: response,
                                cancel: this.module_config.urls[payload.execBackModal] + ((response.id) ? '/' + response.id : ''),
                            })

                            // When modal open other modal
                            if (this.onlySetReturnData) {
                                this.$store.dispatch('loadingBar/setLoadingSpinner', { status: false, button: payload })
                                return true
                            }

                            if (payload.exec === 'submitandnext') {
                                // SAVE -> LOAD NEW MODAL ADD-NEXT
                                this.$store.dispatch('loadingBar/setLoadingSpinner', { status: 2, button: payload })
                                setTimeout(() => {
                                    this.$store.dispatch('loadingBar/setLoadingSpinner', { status: 3, button: payload })
                                }, 1000)

                                setTimeout(() => {
                                    this.$store.dispatch('modal/setRefresh', Date.now())
                                    this.$store.dispatch('modal/setEditItemId', 0)
                                    this.$store.dispatch('loadingBar/setLoadingSpinner', {
                                        status: false,
                                        button: payload,
                                    })
                                    // do not get data for form if add next screen is enabled
                                    if (payload.addNext !== true) {
                                        this.getRecord()
                                    }
                                }, 2000)

                                // add next screen is enabled
                                if (payload.addNext === true) {
                                    this.$store.dispatch('modal/setShow', 'add-next')
                                }
                                return true
                            } else {
                                // SAVE -> LOAD PAGE SUCCESS -> SUCCESS AUTO CLOSE
                                this.$store.dispatch('loadingBar/setLoadingSpinner', { status: 2, button: payload })
                                setTimeout(() => {
                                    this.$store.dispatch('loadingBar/setLoadingSpinner', { status: 3, button: payload })
                                }, 1000)

                                setTimeout(() => {
                                    this.$store.dispatch('modal/setRefresh', Date.now())
                                    this.$store.dispatch('modal/setEditItemId', 0)
                                    this.$store.dispatch('loadingBar/setLoadingSpinner', {
                                        status: false,
                                        button: payload,
                                    })
                                    this.$store.dispatch('modal/setShow', 'success')
                                }, 2000)
                                return true
                            }
                        } else {
                            this.$store.dispatch('modal/setReturnData', {
                                type: null,
                                data: null,
                                response: null,
                            })
                        }

                        // MODAL VUEX
                        this.$store.dispatch('modal/setShow', false)
                        this.$store.dispatch('modal/setRefresh', Date.now())
                        this.$store.dispatch('modal/setEditItemId', 0)
                        this.$store.dispatch('loadingBar/setLoadingSpinner', { status: false, button: payload })
                        return true
                    } else if (!payload.withoutRedirect) {
                        this.$store.dispatch('loadingBar/setLoadingSpinner', { status: false, button: payload })

                        if (response.code === 408) {
                            window.location = response.redirectTo
                        } else if (response.code === 401) {
                            this.$router.push({ name: 'dashboard' })
                        } else {
                            if (window.parent.$('.fancybox-button--close').length === 0) {
                                this.$store.dispatch('notification/setShow', {
                                    type: 'success',
                                    text: response.message,
                                })
                            }
                            if (response.code === 200) {
                                this.$store.dispatch('loadingBar/setLoadingSpinner', { status: 2, button: payload })
                                setTimeout(() => {
                                    this.$store.dispatch('loadingBar/setLoadingSpinner', { status: 3, button: payload })
                                }, 1000)
                                this.$emit('save-done')

                                if (
                                    postPayload.workspace_type
                                    && postPayload.workspace_type !== localStorage.getItem('appType')
                                ) {
                                    localStorage.setItem('appType', postPayload.workspace_type)
                                }
                                // Get localstorage func and run if needed
                                localStorage.setItem('modalResult', response.result)
                                let localFunc = localStorage.getItem('modalFunc')
                                if (localFunc !== undefined) {
                                    if (typeof window.parent[localFunc] === 'function') {
                                        window.parent[localFunc]()
                                    }
                                }


                                window.parent.$('#newElementData').val(response.result_angular)
                                window.parent.$('#updateNewElement').trigger('click')

                                window.parent.$('#last_id').val(response.result).trigger('change')
                                window.parent.$('#last_id1').val(response.result).trigger('change')
                                if (!response.contactType) {
                                    window.parent.$('#bid_client_id').trigger('change')
                                }


                                if (window.parent.$('input[name="contract_recipient_id"]').length > 0) {
                                    if (window.parent.contractScripts) {
                                        window.parent.contractScripts.fetchContractRecipientContacts(window.parent.$('input[name="contract_recipient_id"]').val())
                                    }
                                }

                                window.parent.$('#rfpId').change()

                                window.parent.$('#proposal_rfp_id').change()

                                this.$store.dispatch('appStore/refreshAuthData')
                                if (payload.exec === 'submitandnext') {
                                    payload.id = 100
                                    if (this.$route.name === 'settings-cost-codes-add') {
                                        this.model.cc_code = ''
                                        this.model.cc_description = ''
                                        this.model.cc_title = ''
                                    } else {
                                        this.uuid = uniqueId()
                                        this.getRecord()
                                    }
                                }

                                // check if api request redirection
                                if (response.redirectTo !== undefined) {
                                    window.location = response.redirectTo
                                } else {
                                    if (parseInt(payload.id) === 0) {
                                        return setTimeout(() => {
                                            this.$store.dispatch('loadingBar/setLoadingSpinner', {
                                                status: false,
                                                button: payload,
                                            })
                                            this.$store.dispatch('loadingBar/setLoading', false)
                                            /**
                                             * isSubcomponent:  e.g. (projects (pid))
                                             */
                                            if (this.module_config.isSubcomponent) {
                                                return this.$router.push({
                                                    name: this.module_config.urls.index,
                                                    params: { [this.module_config.componentId]: this.$route.params[this.module_config.componentId] },
                                                })
                                            }

                                            // prevent NavigationDuplicated error
                                            if (this.$route.path !== this.module_config.urls.index) {
                                                return this.$router.push(this.module_config.urls.index)
                                            }
                                        }, 2000)
                                    } else {
                                        if (payload.keepId !== undefined) {
                                            if (payload.execBack === 'refresh') {
                                                this.$emit('refreshRequired', true)
                                                window.parent.location = this.module_config.urls[payload.execBackUrl] + '/' + payload.id
                                                // this.$parent.$router.push(this.module_config.urls[payload.execBackUrl] + '/' + payload.id)
                                            } else if (payload.execBack === 'projectbills') {
                                                this.$router.push(payload.execUrl)
                                            } else {
                                                return setTimeout(() => {
                                                    this.$store.dispatch('loadingBar/setLoadingSpinner', {
                                                        status: false,
                                                        button: payload,
                                                    })
                                                    this.$store.dispatch('loadingBar/setLoading', false)
                                                    /**
                                                     * isSubcomponent:  e.g. (projects (pid))
                                                     */
                                                    if (this.module_config.isSubcomponent) {
                                                        return this.$router.push({
                                                            name: this.module_config.urls[payload.execBack],
                                                            params: {
                                                                [this.module_config.componentId]: this.$route.params[this.module_config.componentId],
                                                                id: payload.id,
                                                            },
                                                        })
                                                    } else if (payload.execBack === 'index' || payload.execBack === 'list') {
                                                        return this.$router.push(this.module_config.urls[payload.execBack])
                                                    }
                                                    return this.$router.push(this.module_config.urls[payload.execBack] + '/' + payload.id)
                                                }, 2000)
                                            }
                                        } else if (this.module_config.isSubcomponent) {
                                            setTimeout(() => {
                                                this.$store.dispatch('loadingBar/setLoadingSpinner', {
                                                    status: false,
                                                    button: payload,
                                                })
                                                this.$store.dispatch('loadingBar/setLoading', false)

                                                return this.$router.push({
                                                    name: this.module_config.urls[payload.execBack],
                                                    params: {
                                                        [this.module_config.componentId]: this.$route.params[this.module_config.componentId],
                                                        id: payload.id,
                                                    },
                                                })
                                            }, 2000)
                                        } else {
                                            setTimeout(() => {
                                                this.$store.dispatch('loadingBar/setLoadingSpinner', {
                                                    status: false,
                                                    button: payload,
                                                })
                                                this.$store.dispatch('loadingBar/setLoading', false)
                                                this.$router.push(this.module_config.urls[payload.execBack])
                                            }, 2000)
                                        }
                                    }

                                    if (payload.exec !== 'submitandnext') {

                                        window.parent.$('.fancybox-button--close').click()
                                    }
                                }
                                // if response code not 200
                            } else {
                                // validation
                                if (response.result) {
                                    Object.keys(response.result).forEach(key => {
                                        var m = response.result[key]
                                        Object.keys(m).forEach(err => {
                                            const payload = {
                                                type: 'error',
                                                text: m[err],
                                            }
                                            this.$store.dispatch('notification/setShow', payload)
                                        })
                                    })
                                } else if (response.message) {
                                    const payload = {
                                        type: 'error',
                                        text: response.message,
                                    }
                                    this.$store.dispatch('notification/setShow', payload)
                                } else {
                                    const payload = {
                                        type: 'error',
                                        text: 'Error! Try again later.',
                                    }
                                    this.$store.dispatch('notification/setShow', payload)
                                }
                                this.setLoadingSpinner(4, false, payload)
                            }
                        }
                    }

                    // Emit to parent that we already make request and save element
                    if (typeof payload.emitAfter !== 'undefined') {
                        this.$emit(payload.emitAfter, response)
                    }

                    this.$emit('afterSubmit', response)
                    this.saveSuccess = true
                    this.$emit('unsaved-changes', false)
                    return this.saveSuccess
                }).catch(error => {
                    console.log(error)
                    this.saveSuccess = false
                    /**
                     *  CATCH ERROR
                     *  REDIRECT TO DASHBOARD
                     **/
                    this.consoleError(error)
                    this.errorHandle(error)
                    this.setLoadingSpinner(4, false, payload)
                    this.blockSubmit = false
                })
            },
            setLoadingSpinner (step, redirect = true, payload) {
                this.$store.dispatch('loadingBar/setLoadingSpinner', { status: 2, button: payload })
                setTimeout(() => {
                    this.$store.dispatch('loadingBar/setLoadingSpinner', { status: step, button: payload })
                }, 100)

                if (redirect === true) {
                    setTimeout(() => {
                        this.$store.dispatch('modal/setRefresh', Date.now())
                        this.$store.dispatch('loadingBar/setLoadingSpinner', { status: false, button: payload })
                        this.$router.push({ name: 'dashboard' })
                    }, 1000)
                } else {
                    setTimeout(() => {
                        this.$store.dispatch('modal/setRefresh', Date.now())
                        this.$store.dispatch('loadingBar/setLoadingSpinner', { status: false, button: payload })
                        // this.$router.push({name: 'dashboard'})
                    }, 450)
                }
            },
            onEdit () {
                if (this.$route.params.id !== undefined) {
                    /**
                     * isSubcomponent:  e.g. (projects (pid))
                     */
                    if (this.module_config.isSubcomponent) {
                        return this.$router.push({
                            name: this.module_config.urls.edit,
                            params: {
                                [this.module_config.componentId]: this.$route.params[this.module_config.componentId],
                                id: this.$route.params.id,
                            },
                        })
                    }
                    this.$router.push(this.module_config.urls.edit + '/' + this.$route.params.id)
                } else {
                    this.$router.push(this.module_config.urls.edit)
                }
            },
            onDelete (payload) {
                var messages = this.getSweetAlertMessages('delete')
                let id = payload.id

                if (this.forcedId !== undefined) {
                    id = this.forcedId
                }

                var url = this.module_config.urls.delete + '/' + id
                this.confirm(() => {

                    this.migrateToApi2('get', url).then((response, status) => {
                        response = this.setResponseData(response)

                        if (response.code === 401) {
                            this.callbackDelete(response)
                            this.$router.push({ name: 'dashboard' })
                        } else if (response.code === 404) {
                            this.callbackDelete(response)
                            this.$router.push(this.module_config.urls.index)
                        } else if (status === 'success' || response.code === 200) {
                            this.$store.dispatch('loadingBar/setLoading', false)
                            // close all open modals

                            window.parent.$('.fancybox-button--close').click()
                            this.$store.dispatch('notification/setShow', {
                                type: 'success',
                                text: response.message,
                            })

                            if (this.forceModalClose) {
                                this.$parent.showModalForm = false
                                this.$parent.triggerRefresh = this.forceTriggerRefresh

                                // MODAL VUEX
                                this.$store.dispatch('modal/setShow', false)
                                this.$store.dispatch('modal/setRefresh', Date.now())
                                this.$store.dispatch('modal/setEditItemId', 0)
                            }

                            this.callbackDelete(response)
                            return this.customRouterPush(this.module_config.urls.index)
                        } else {
                            this.$store.dispatch('notification/setShow', {
                                type: 'error',
                                text: response.message,
                            })
                        }
                    }).catch(({ response }) => {
                        this.$store.dispatch('notification/setShow', {
                            type: 'error',
                            text: response.data.message,
                        })
                    })
                }, messages)
            },
            onCancel (payload) {
                if (payload.keepId !== undefined) {
                    if (parseInt(payload.id) !== 0) {
                        if (payload.execBackModal !== undefined) {
                            return this.$router.push(this.module_config.urls[payload.execBackModal] + '/' + payload.id)
                        } else {
                            if (!this.closeModal()) {
                                return this.customRouterPush(this.module_config.urls[payload.execBack], payload.id)
                            }
                        }
                    } else {
                        if (!this.closeModal()) {
                            return this.customRouterPush(this.module_config.urls.index)
                        }
                    }
                } else {
                    if (!this.closeModal()) {
                        return this.customRouterPush(this.module_config.urls[payload.execBack])
                    }
                }
            },
            customRouterPush (name, id) {
                if (id) {
                    /**
                     * isSubcomponent:  e.g. (module projects (pid))
                     */
                    if (this.module_config.isSubcomponent) {
                        return this.$router.push({
                            name: name,
                            params: {
                                [this.module_config.componentId]: this.$route.params[this.module_config.componentId],
                                id: id,
                            },
                        })
                    }
                    if (!this.closeModal()) {
                        return this.$router.push(name + '/' + id)
                    }
                }

                /**
                 * isSubcomponent:  e.g. (module projects (pid))
                 */
                if (this.module_config.isSubcomponent) {
                    return this.$router.push({
                        name: name,
                        params: { [this.module_config.componentId]: this.$route.params[this.module_config.componentId] },
                    })
                }

                return this.$router.push(name)
            },
            closeModal () {
                window.parent.$('.fancybox-button--close').click()
                if (this.forceModalClose) {
                    this.$store.dispatch('modal/setShow', false)
                    // track time (top bar)
                    this.$store.dispatch('modal/setEditItemId', 0)
                    this.$parent.showModalForm = false

                    return true
                }
                return false
            },
            getRecord () {
                this.$store.dispatch('loadingBar/setLoading', true)

                if (this.module_config.urls.editGet !== undefined) {
                    this.module_config.urls.save = this.module_config.urls.editGet
                }

                if (window.$('body').hasClass('isModal')) {
                    this.isModal = true
                } else {
                    this.isModal = false
                }

                // check if there is any CUSTOM fetch url
                if (this.$route.meta.save !== undefined) {
                    this.module_config.urls.save = this.$route.meta.save
                }

                let id = ''
                let stringifiedParams = {}
                stringifiedParams = merge(stringifiedParams, this.$route.params)
                if (this.forcedId === undefined) {
                    id = this.$route.params.id
                } else {
                    id = this.forcedId
                    stringifiedParams = merge(stringifiedParams, this.forcedData)
                }

                let forcedData = {}
                if (this.forcedData !== undefined) {
                    forcedData = this.forcedData
                }

                // attach special params to url
                var paramsIds = ''
                var urlIds = (id !== undefined) ? ('/' + id) : ''
                if (id === '') {
                    urlIds = ''
                }

                var getUrl = (this.module_config.urls.save)
                if (urlIds !== '') {
                    paramsIds = urlIds
                }

                if (this.config.backParams === true) {
                    var returnParams = '/back/' + this.$route.params.back
                    paramsIds = paramsIds + returnParams
                }

                getUrl = getUrl + '' + paramsIds

                let vuexData = this.$store.getters['appStore/getPostData']
                if (vuexData !== undefined && vuexData !== null) {
                    let vuexParams = qs.stringify(vuexData)
                    if (vuexParams) {
                        getUrl += '?' + vuexParams
                    }
                }

                // Add additional params
                if (this.forcedParams !== undefined) {
                    for (let param in this.forcedParams) {
                        getUrl += '/' + param + '/' + this.forcedParams[param]
                    }
                }


                this.migrateToApi2('get', getUrl, { custom: JSON.stringify(stringifiedParams) }).then(response => {
                    response = this.setResponseData(response)
                    if (response.code === 200) {
                        // http success, call the mutator and change something in state
                        this.model = response.result
                        this.cloneModel = cloneDeep(this.model)
                        this.appType = response.app

                        this.$emit('response-data', this.model)
                        // this creates safe copy of fields data - required i.e. in customComputed
                        this.orgFields = JSON.stringify(response.fields)
                        this.fields = response.fields
                        this.$emit('response-data-fields', this.fields)
                        this.i18n = response.i18n
                        this.extraData = assign(this.extraData, response.extra_data)

                        if (response.extra_data !== undefined && response.extra_data.extra_pack !== undefined) {
                            this.extraData.extraPack = response.extra_data.extra_pack
                        }

                        // Make reference
                        this.extra_data = this.extraData

                        if (isObject(forcedData)) {
                            Object.keys(forcedData).forEach(key => {
                                var m = forcedData[key]
                                this.model[key] = m
                            })
                        }

                        let promise = new Promise((resolve, reject) => {
                            this.prepareFields(response)
                            if (this.isModal === true) {
                                this.modal = false
                            }
                            resolve()
                            // when an error occurred, reject
                            reject(new Error('Something happened!'))
                        })
                        promise.then(response => {
                            if (this.customBootstrap !== undefined) {
                                this.customBootstrap()
                            }
                        }, error => {
                            console.error(error)
                        })
                        promise.then(response => {
                            if (this.reqLogicChangedActivator === true) {
                                this.reqLogicChanged = true
                            }
                        }, error => {
                            console.error(error)
                        })

                        // nav counter
                    } else if (response.code === 404 || response.code === 403) {
                        this.$router.push(this.module_config.urls.index)
                    } else {
                        this.$router.push({ name: 'dashboard' })
                    }
                }).then((data) => {
                    this.rendered = true
                    this.$store.dispatch('loadingBar/setLoading', false)

                    // Pass data by reference
                    if (typeof this.parentReference !== 'undefined' && this.parentReference !== null) {
                        this.parentReference.model = this.model
                        this.parentReference.orgFields = this.orgFields
                        this.parentReference.fields = this.fields
                        this.parentReference.extra_data = this.extra_data
                        this.parentReference.i18n = this.i18n
                        this.parentReference.config = this.config
                        this.parentReference.module_config = this.module_config
                        this.parentReference.form_elements = this.form_elements
                        this.parentReference.selectedTabs = this.selectedTabs
                        this.$emit('parentUpdated', true)
                    }
                }).catch(error => {
                    this.errorHandle(error)
                })
            },
            inSeparateTabArray (needle, separateTabs) {
                var length = separateTabs.length
                for (let i = 0; i < length; i++) {
                    if (separateTabs[i] === needle) {
                        return true
                    }
                }
                return false
            },
            prepareFields (response) {

                let self = this
                var tabs = []
                tabs = this.form_elements.tabs
                // check if there is separate mode in tabs
                if (this.form_elements.separateTabAppMode !== undefined) {
                    if (this.inSeparateTabArray(this.getCurrentApp(), this.form_elements.separateTabAppMode) === true) {
                        tabs = this.form_elements.tabsSeparate[this.getCurrentApp()]
                        // loop each tab
                        for (let i = 0; i < tabs.length; i++) {
                            var tabComponents = tabs[i].components
                            tabs[i].widgets = []
                            if (tabComponents !== undefined) {
                                tabComponents.forEach(component => {
                                    if (this.widgets[component] !== undefined) {
                                        tabs[i].widgets.push(component)
                                        this.$options.components[this.widgets[component].id] = defineAsyncComponent(() => import( /* @vite-ignore */ '../../elements/' + this.widgets[component].id + '.vue'))
                                    }
                                })
                            }
                        }
                    }
                }

                // loop each tab
                for (let i = 0; i < tabs.length; i++) {
                    var fields = tabs[i].fields
                    tabs[i].schema = {
                        fields: [],
                        groupedFields: [],
                    }
                    for (let j = 0; j < fields.length; j++) {
                        // first check if this field is presented in model
                        if (response.fields[fields[j].model] === undefined) {
                            // check if not headline
                            if (fields[j].headline === true || fields[j].separatorX === true || fields[j].group === true) {
                            } else {
                                continue
                            }
                        }

                        if (fields[j].componentMode === true) {
                            continue
                        }

                        // skip view-only fields
                        if (fields[j].viewOnly !== undefined) {
                            if (fields[j].viewOnly === true) {
                                continue
                            }
                        }
                        let field = fields[j].model

                        // IF group fields
                        if (fields[j].group === true) {
                            let groupFields = []
                            for (let x = 0; x < fields[j].groups[0].fields.length; x++) {
                                var fObj = response.fields[fields[j].groups[0].fields[x].model]
                                if (fObj && typeof fObj.getCode === 'string') {

                                    let fn = eval(fObj.getCode)
                                    fObj.get = fn
                                }
                                fObj.split = fields[j].groups[0].fields[x].split
                                groupFields.push(fObj)
                            }
                            var gSchema = [
                                {
                                    legend: this.i18n.entity[fields[j].label],
                                    fields: groupFields,
                                },
                            ]
                            // add to groupedFields
                            tabs[i].schema.groupedFields.push(gSchema)
                            // but also add markup to schema]
                            var groupFieldScheme = {
                                type: 'group',
                                inputType: 'text',
                                label: (this.i18n.entity[fields[j].label] !== undefined) ? this.i18n.entity[fields[j].label] : fields[j].label,
                                value: '',
                                groups: gSchema,
                            }
                            tabs[i].schema.fields.push(groupFieldScheme)
                            continue
                        }
                        var obj = response.fields[field]
                        if (obj && typeof obj.visibleCode === 'string') {

                            obj.visible = eval('((model) => ' + obj.visibleCode + ')')
                        }
                        if (obj && typeof obj.disabled === 'string') {
                            obj.disabled = eval('((model) => ' + obj.disabled + ')')
                        }
                        if (obj && typeof obj.getCode === 'string') {

                            let fn = eval(obj.getCode)
                            obj.get = fn
                        }
                        if (obj && typeof obj.onChangedCode === 'string') {

                            obj.onChanged = eval('((model, newVal, oldVal, field) => ' + obj.onChangedCode + ')')
                        }
                        if (fields[j].initialValue && isFunction(fields[j].initialValue)) {
                            obj.initialValue = fields[j].initialValue
                        }

                        // handle headlines
                        if (fields[j].headline === true) {
                            var hScheme = {
                                type: 'headline',
                                inputType: fields[j].type,
                                label: 'Headline',
                                headlineType: field.type,
                                value: (this.i18n.entity[fields[j].label] !== undefined) ? this.i18n.entity[fields[j].label] : fields[j].label,
                                visible: !fields[j].hidden,
                            }
                            obj = hScheme
                            // logic for headline
                            if (typeof fields[j].visibleCode === 'string') {

                                obj.visible = eval('((model) =>  ' + fields[j].visibleCode + ')')
                            }
                        }

                        if (fields[j].featureFlag?.name) {
                            const flag = this.isFeatureEnabled(fields[j].featureFlag?.name, false)
                            obj.visible = fields[j].featureFlag.showIfTrue ? flag : !flag
                        }

                        if (fields[j].separatorX === true) {
                            var sScheme = {
                                type: 'separator',
                                model: '',
                                label: '',
                            }
                            obj = sScheme
                        }
                        // include optional descriptions
                        if (fields[j].desc !== undefined) {
                            obj.desc = this.i18n.entity[fields[j].desc]
                        }
                        obj.inputName = fields[j].model

                        if (fields[j].editDisabled !== undefined) {
                            var checkDisable = fields[j].editDisabled
                            if (this.model[checkDisable] === true) {
                                obj.disabled = true
                            }
                        }

                        if (fields[j].showAsLabel !== undefined) {
                            var showAsLabel = fields[j].showAsLabel
                            if (showAsLabel === true) {
                                obj.type = 'label'
                                obj.label = (this.i18n.entity[fields[j].label] !== undefined) ? this.i18n.entity[fields[j].label] : fields[j].label
                                obj.required = false
                                obj.formatter = fields[j].formatter
                            }
                        }
                        if (fields[j].multi === true) {
                            obj.mode = 'multi'
                            obj.type = 'inputMulti'
                        }
                        if (fields[j].combo === true) {
                            obj.mode = 'combo'
                            obj.type = 'combo'
                            if (fields[j].disableAddNewRow) {
                                obj.disableAddNewRow = true
                            }
                        }
                        if (fields[j].mix === true) {
                            obj.mode = 'mix'
                            obj.type = 'mix'
                        }
                        if (fields[j].template === true) {
                            obj.mode = 'template'
                            obj.type = 'template'
                        }
                        if (fields[j].mix2 === true) {
                            obj.mode = 'mix2'
                        }

                        if (fields[j].labelCss !== undefined) {
                            obj.labelCss = fields[j].labelCss
                        }
                        obj.html = fields[j].html || false
                        // final checking if there is any hideIf logic for this field...
                        if (fields[j].hideIf !== undefined) {
                            var checkField = fields[j].hideIf
                            // new method using LOGIC[]
                            if (this.model.LOGIC !== undefined) {
                                if (this.model.LOGIC[checkField] === true) {
                                    if (fields[j].hideIfAuto !== true) {
                                        // hide field
                                        // continue
                                        obj.visible = false
                                    }
                                }
                            } else {
                                if (fields[j].hideOnInit !== undefined) {
                                    var checkFieldonInit = fields[j].hideOnInit
                                    if (this.model[checkFieldonInit] === true) {
                                        // hide field
                                        // continue
                                        obj.visible = false
                                    }
                                } else {
                                    if (this.model[checkField] === true) {
                                        if (fields[j].hideIfAuto !== true) {
                                            // hide field
                                            // continue
                                            obj.visible = false
                                        }
                                    }
                                }
                            }
                        }
                        if (fields[j].virtual === true) {
                            this.vFields.push(obj)
                        } else {
                            tabs[i].schema.fields.push(obj)
                        }
                    }
                }

                // Skip view-only tabs
                tabs = tabs.filter((element) => {
                    return !element.onlyView
                })

                // Skip tabs for only existing
                if (this.model._is_new) {
                    tabs = tabs.filter((element) => {
                        return !element.onlyExisting
                    })
                }

                // bind to vue
                this.selectedTabs = tabs

                // this binds schema buttons to form (as bottom button bar)
                var bottomButtons = []
                if (!this.forceHideBottomButtons) {
                    for (let i = 0; i < this.config.buttons.length; i++) {
                        let btn = this.config.buttons[i]
                        if (typeof btn.onSubmitName === 'string') {
                            btn.onSubmit = this[btn.onSubmitName]
                        }
                        if (typeof btn.visibleCode === 'string') {
                            btn.visible = eval('((model) =>  ' + btn.visibleCode + ')')
                        }
                        if (typeof btn.disabledCode === 'string') {
                            btn.disabled = eval('((model) =>  ' + btn.disabledCode + ')')
                        }
                        btn.iconClassProgress = ''
                        btn.inProgress = false
                        bottomButtons.push(btn)
                    }
                }
                this.bottomButtons = bottomButtons
            },
            getComponent (widget) {
                return this.widgets[widget]
            },
            async setSchema () {
                let slug = this.$route.meta.schema

                if (this.forcedSource !== undefined) {
                    slug = this.forcedSource // get explicit
                }

                this.currentSchemaSlug = slug

                if (slug !== undefined) {

                    const schema = this.$moduleSchemas[`./modules/${ slug }/schema.js`] || this.$moduleSchemas[`./io-modules/${ slug }/schema.js`]
                    this.config = (await schema()).default

                    this.module_config = this.config.module
                    this.form_elements = this.config.form_elements
                    this.buttons = this.config.buttons
                    this.back = this.module_config.urls.index

                    // process tab components
                    this.widgets = widget

                    var tabs = this.form_elements.tabs
                    // loop each tab
                    for (let i = 0; i < tabs.length; i++) {
                        var tabComponents = tabs[i].components
                        tabs[i].widgets = []
                        if (tabComponents !== undefined) {
                            tabComponents.forEach(component => {
                                if (this.widgets[component] !== undefined) {
                                    tabs[i].widgets.push(component)
                                    if (
                                        'federalHolidaysCalendar' !== component &&
                                        'usersRateUpdater' !== component
                                    ) {
                                        this.$options.components[this.widgets[component].id] = defineAsyncComponent(() => import( /* @vite-ignore */ '../../elements/' + this.widgets[component].id + '.vue'))
                                    }
                                }
                            })
                        }
                    }

                    // bind custom methods
                    if (this.config.customMethods !== undefined) {
                        Object.keys(this.config.customMethods).forEach(key => {
                            var m = this.config.customMethods[key]
                            this[key] = m.bind(this)
                        })
                    }

                    this.getRecord()

                    if (this.$route.query?.ref === 'secondTabForm') {
                        let tabs = this.form_elements.tabs

                        this.activeTab = tabs.indexOf(tabs.find(tab => tab.ref === this.$route.query?.ref))
                    }

                } else {
                    console.warn('Missing Config Data')
                }
            },
            getColumns (version, type) {
                if (version === undefined) {
                    if (type === 'label') {
                        return 'col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 view-label io-view-label text-lg-right text-xl-right text-md-right text-sm-right'
                    } else {
                        return 'col-xl-6 col-lg-7 col-md-8 col-sm-6 col-12 io-view-value'
                    }
                } else if (version === 'additional_right_menu') {
                    if (type === 'label') {
                        return 'col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 view-label io-view-label text-lg-right text-xl-right'
                    } else {
                        return 'col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 io-view-value'
                    }
                }
            },
            created () {
                this.formData = new FormData()
            },
            addToFormData (fileArray, file) {
                this.formData.append(fileArray, file)
            },
            replacer (value) {
                if (value !== '') {
                    return value.replace(/[^\w\s]/gi, '')
                }
                return value
            },
            remapToFormData () {
                Object.keys(this.model).forEach(key => {
                    var m = this.model[key]
                    if (typeof m === 'object' && m !== undefined) {
                        this.formData.append(key, JSON.stringify(m))
                    } else {
                        this.formData.append(key, m)
                    }
                })
            },
            inArray (needle, haystack) {
                var length = haystack.length
                for (let i = 0; i < length; i++) {
                    if (haystack[i] === needle) {
                        return true
                    }
                }
                return false
            },
            reqLogicSwitcher (value) {
                // check if there is skipRequiredIf Logic
                // in loop switch each selectedTabs
                for (let tabIndex = 0; tabIndex < this.selectedTabs.length; tabIndex++) {
                    let tabFields = this.selectedTabs[tabIndex].schema.fields
                    for (let tabFieldIndex = 0; tabFieldIndex < tabFields.length; tabFieldIndex++) {
                        // check if there is skipRequiredIf Logic
                        if (tabFields[tabFieldIndex].skipRequiredIf !== undefined) {
                            let entityFieldValues = tabFields[tabFieldIndex].skipRequiredIf.values
                            let modelFieldValue = this.model[tabFields[tabFieldIndex].skipRequiredIf.field]

                            // check model field value is not and object
                            // if field is an object
                            if (modelFieldValue && modelFieldValue.id && this.inArray(modelFieldValue.id, entityFieldValues)) {
                                tabFields[tabFieldIndex].required = false
                                // default - primitive type value check
                            } else if (modelFieldValue && this.inArray(modelFieldValue, entityFieldValues)) {
                                tabFields[tabFieldIndex].required = false
                                // set field is required
                            } else {
                                tabFields[tabFieldIndex].required = true
                            }
                        }
                    }
                }
            },
            calculator (field, section, index) {
                let inputName = field.inputName
                let storage = field.indexationStorage

                // special case for label type
                if (field.type === 'label' && this[field.getCodeDisplay] !== undefined) {
                    return this[field.getCodeDisplay](inputName, section, index, storage)
                }

                // default
                if (this[field.getCode] !== undefined) {
                    return this[field.getCode](inputName, section, index, storage)
                } else {
                    return 'CALC ERROR'
                }
            },
            applyLogic () {
                // check if there is skipRequiredIf Logic
                // in loop switch each selectedTabs
                for (let i = 0; i < this.selectedTabs.length; i++) {
                    for (let j = 0; j < this.selectedTabs[i].schema.fields.length; j++) {
                        let currentModel = this.selectedTabs[i].schema.fields[j].model
                        for (let z = 0; z < this.selectedTabs[i].fields.length; z++) {
                            if (this.selectedTabs[i].fields[z].model === currentModel) {
                                if (this.selectedTabs[i].fields[z].hideIf !== undefined) {
                                    if (this.model.LOGIC !== undefined && Object.keys(this.model.LOGIC).length > 0) {
                                        if (this.model.LOGIC[this.selectedTabs[i].fields[z].hideIf] === true) {
                                            this.selectedTabs[i].schema.fields[j].visible = false
                                        } else {
                                            this.selectedTabs[i].schema.fields[j].visible = true
                                        }
                                    } else {
                                        if (this.model[this.selectedTabs[i].fields[z].hideIf] === true) {
                                            this.selectedTabs[i].schema.fields[j].visible = false
                                        } else {
                                            this.selectedTabs[i].schema.fields[j].visible = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            applyDisabledLogic () {
                // check if there is skipRequiredIf Logic
                // in loop switch each selectedTabs
                for (let i = 0; i < this.selectedTabs.length; i++) {
                    for (let j = 0; j < this.selectedTabs[i].schema.fields.length; j++) {
                        let currentModel = this.selectedTabs[i].schema.fields[j].model
                        for (let z = 0; z < this.selectedTabs[i].fields.length; z++) {
                            if (this.selectedTabs[i].fields[z].model === currentModel) {
                                if (this.selectedTabs[i].fields[z].disabledIf !== undefined) {
                                    if (this.model.LOGIC !== undefined) {
                                        if (this.model.LOGIC[this.selectedTabs[i].fields[z].disabledIf] === true) {
                                            this.selectedTabs[i].schema.fields[j].disabled = true
                                        } else {
                                            this.selectedTabs[i].schema.fields[j].disabled = false
                                        }
                                    } else {
                                        if (this.model[this.selectedTabs[i].fields[z].disabledIf] === true) {
                                            this.selectedTabs[i].schema.fields[j].disabled = true
                                        } else {
                                            this.selectedTabs[i].schema.fields[j].disabled = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },

            /**
             * Run delete callback
             * @param response
             */
            callbackDelete (response) {
                if (this.callAfterDelete && isFunction(this.callAfterDelete)) {
                    this.callAfterDelete(response)
                }
            },

            checkForChanges () {
                this.$emit('general-ledger-changed', !isEqual(this.model.general_ledger, this.cloneModel.general_ledger))
                this.$emit('unsaved-changes', !isEqual(this.model, this.cloneModel))
            },
        },
        computed: {
            ...mapState('modal', {
                refreshData: state => state.refresh,
                returnData: state => state.returnData,
            }),
            // compute page title
            pageTitle () {
                if (this.forcedId !== undefined && this.forcedId !== '') {
                    if (parseInt(this.forcedId) === 0) {
                        return this.i18n.interface.label_module_title_add
                    } else {
                        return this.i18n.interface.label_module_title_edit
                    }
                } else {
                    if (this.$route.params.id !== undefined) {
                        if (this.$route.params.id === 0 || this.$route.params.id === '0') {
                            return this.i18n.interface.label_module_title_add
                        }
                        return this.i18n.interface.label_module_title_edit
                    } else {
                        return this.i18n.interface.label_module_title_add
                    }
                }
            },
        },
        mounted () {
            this.setSchema()
        },
        created () {
            this.formData = new FormData()
        },
        async beforeCreate () {

            let slug = this.$route.meta.schema
            if (this.forcedSource) {
                slug = this.forcedSource
            }
            if (slug !== undefined) {


                let schema = this.$moduleSchemas[`./modules/${ slug }/schema.js`] || this.$moduleSchemas[`./io-modules/${ slug }/schema.js`]

                this.config = (await schema()).default

                // bind custom computed
                if (this.config.customComputed !== undefined) {
                    Object.keys(this.config.customComputed).forEach(key => {
                        var m = this.config.customComputed[key]
                        this.$options.computed[key] = m.bind(this)
                    })
                }
                // bind custom computed
                if (this.config.customWatch !== undefined) {
                    this.reqLogicChangedActivator = true
                    Object.keys(this.config.customWatch).forEach(key => {
                        var m = this.config.customWatch[key]
                        // this.$options.watch[key] = m.bind(this)
                        this.$watch(key, m.bind(this), { deep: true })
                    })
                }
            }
        },
    }
</script>


<style lang="scss">
    .io-main-wrapper.io-rfp-edit {
        .io-container.io-panel-body {
            padding-top: 0 !important;
        }

        .io-standard-form-container.io-container {
            padding-top: 0 !important;
        }

        .io-page-title {
            padding: 16px !important;
        }
    }
    .io-form-two-columns {
        display: flex;

        .io-inner-form-container {
            padding-top: 24px;
        }
    }
    .io-form-two-columns-second-column {
        display: flex;
        width: 100%;
        flex-direction: column;
        background-color: var(--main-background);
        padding-left: 24px;
        padding-top: 24px;
    }
</style>
