import { defineComponent } from 'vue'
import { mapState as mapStateVuex, mapGetters as mapGettersVuex } from 'vuex'
import { appPackageViewStore } from '../../../../stores/appPackageView'
import { mapActions, mapState } from 'pinia'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import dayjs from 'dayjs'
import SingleColumnTimeline from '@/components/single-column-timeline/SingleColumnTimeline.vue'
import SingleColumnTimelineItem from '@/interfaces/components/single-column-timeline/SingleColumnTimelineItem'
import RequestedDataErrorPlaceholder from '../../../requested-data-error-placeholder/RequestedDataErrorPlaceholder.vue'
import statusColorsMixin from '@/io-modules/invoices/mixins/StatusColorsMixin'
import ApplicationPackageStatus from '../../../../enums/ApplicationPackageStatus'

export default defineComponent ({
    name: 'DetailsSection',
    components: {
        IOSingleInfoRow,
        SingleColumnTimeline,
        RequestedDataErrorPlaceholder,
    },
    mixins: [statusColorsMixin],
    data () {
        return {
            loading: false,
            error: false,
        }
    },
    computed: {
        ...mapStateVuex('project', ['projectObj']),

        ...mapGettersVuex('appStore', ['isOwnerRep', 'isOwner']),

        ...mapState(appPackageViewStore, ['appPackage', 'isDetailsFilled']),

        ...mapStateVuex('appStore', {
            authData: (state: any) => state.authData
        }),

        grossBilled (): string {
            return this.$filters.formatProjectCurrencyInt(this.appPackage.gross_total_this_period || 0)
        },

        netBilled (): string {
            return this.$filters.formatProjectCurrencyInt(this.appPackage.net_total_this_period || 0)
        },

        dueDate (): string {
            return this.appPackage.billing_period_due_date ? dayjs(this.appPackage.billing_period_due_date).format('DD MMM YYYY') : ''
        },

        billingPeriodOpenDate (): string {
            return this.appPackage.billing_period_open_date ? dayjs(this.appPackage.billing_period_open_date).format('DD MMM YYYY') : '-'
        },

        billingPeriod (): string {
            return this.appPackage.billing_period_start_date ? `${ dayjs(this.appPackage.billing_period_start_date).format('DD MMM YYYY') } - ${ dayjs(this.appPackage.billing_period_end_date).format('DD MMM YYYY') }` : '-'
        },

        timelineItems (): SingleColumnTimelineItem[] {
            return [
                ...this.isOwnerRep ? [{
                    title: this.$t('Draft'),
                    description: '',
                    isActive: true,
                    isCurrent: true,
                }] : [],
                ...this.isOwnerRep ? [{
                    title: this.$t('Client approval'),
                    description: '',
                    isActive: this.clientApprovalStatus || this.paidStatus,
                    isCurrent: false,
                }] : [],
                ...this.isOwner ? [{
                    title: this.$t('Internal approval'),
                    description: '',
                    isActive: this.internalApprovalStatus,
                    isCurrent: false,
                }] : [],
                {
                    title: this.$t('Paid'),
                    description: '',
                    isActive: this.paidStatus,
                    isCurrent: false,
                },
            ]
        },

        internalApprovalStatus (): boolean {
            return [
                ApplicationPackageStatus.PendingInternalApproval,
                ApplicationPackageStatus.InternallyApproved,
                ApplicationPackageStatus.InternallyRejected
            ].includes(this.appPackage.status)
        },

        clientApprovalStatus (): boolean {
            return [
                ApplicationPackageStatus.RejectedByClient,
                ApplicationPackageStatus.ApprovedByClient,
                ApplicationPackageStatus.SubmittedToClient,
            ].includes(this.appPackage.status)
        },

        paidStatus (): boolean {
            return [
                ApplicationPackageStatus.PartiallyPaid,
                ApplicationPackageStatus.Paid,
            ].includes(this.appPackage.status)
        },
    },
    async created () {
        this.fetchData()
    },
    methods: {
        ...mapActions(appPackageViewStore, ['fetchAppPackageDetails']),

        async fetchData (): Promise<void> {
            try {
                this.loading = true
                await this.fetchAppPackageDetails(this.projectObj.project_local_id, this.$route.params.id)
                this.loading = false
                this.error = false
            } catch (e) {
                this.error = true
            } finally {
                this.loading = false
            }
        },
    },
})
