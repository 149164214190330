import { defineComponent } from 'vue'
import { Color } from '@/components/atoms/IOModal/IOModal.ts'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOTextarea from '@/components/atoms/IOTextarea/IOTextarea.vue'

export default defineComponent({
    name: 'VoidInvoice',
    components: {
        IOModal,
        IOTextarea,
    },
    emits: ['close', 'void'],
    data () {
        return {
            reasonText: '',
            Color,
        }
    },
    methods: {
        closeModal (): void {
            this.$emit('close')
        },

        requestRevision (): void {
            this.$emit('void', this.reasonText)
        },
    }
})
