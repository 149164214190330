import { defineComponent, PropType } from 'vue'
import Table from '@/components/table/Table'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import Draggable from 'vuedraggable'
import FieldInput from '@/components/fields/fieldInput.vue'
import { mapState } from 'vuex'
import FieldNumeric from '@/components/fields/fieldNumeric.vue'
import mathHelpers from '@/helpers/mathHelpers'
import FinancialTableCalculatorLines from './interfaces/FinancialTableCalculatorLines'
import FinancialTableTitles from './interfaces/FinancialTableTitles'
import useUnitsClient from '@/io-modules/budget/composables/UnitsClientComposable.ts'

export default defineComponent({
    name: 'FinancialTableCalculator',
    components: { FieldNumeric, FieldInput, Table, IOModal, Draggable },
    props: {
        initialFilledLines: {
            type: Array as PropType<FinancialTableCalculatorLines[]>,
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
        titles: Object as PropType<FinancialTableTitles>,
        prefillInitialLines: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        const { getByUnitType } = useUnitsClient()
        return {
            getByUnitType,
        }
    },
    data () {
        return {
            adjustedLines: [] as FinancialTableCalculatorLines[],
            haveLinesChanged: false,
            totalAllocated: 0,
            showTable: false,
            units: null,
        }
    },
    computed: {
        ...mapState('project', {
            projectObj: state => state.projectObj,
            projectMetricSystem: state => state.projectObj.unit_type,
            usableArea: state => parseFloat(state.projectObj.usable_sqft),
            rentableArea: state => parseFloat(state.projectObj._proposal_rentable_sqft),
            grossArea: state => parseFloat(state.projectObj._proposal_total_area),
        }),

        hasLines (): boolean {
            return this.initialFilledLines?.length > 0
        },

        canSave (): boolean {
            return this.adjustedLines.every(line => {
                return line.description !== '' &&
                    line.unit_type !== null &&
                    line.unit_quantity !== '' &&
                    line.unit_amount !== ''
            })
        },
    },
    async beforeMount () {
        await this.getUnits()
    },
    methods: {
        toggleCalculatorModal (value: boolean): void {
            this.showTable = value

            if (value === false) {
                this.adjustedLines = []
                return
            }

            if (this.hasLines && value === true) {
                this.manageRows()
                this.calcTotalAllocated()
            } else {
                this.addRow()
            }
        },

        closeModal (): void {
            this.$emit('close')
        },

        manageRows (): void {
            this.adjustedLines = JSON.parse(JSON.stringify(this.initialFilledLines))
            if (this.hasLines) {
                this.adjustedLines = this.initialFilledLines.map(line => {
                    return {
                        ...line,
                        id: crypto.randomUUID(),
                        unit_type: this.prefillInitialLines
                            ? this.units.find(unit => unit.id === line.unit_type.id)
                            : line.unit_type,
                    }
                })
            }
        },

        addRow (): void {
            this.adjustedLines.push({
                id: crypto.randomUUID(),
                description: '',
                unit_type: null,
                unit_quantity: '',
                unit_amount: '',
                total: 0,
                total_allocated: '',
            })
        },

        calcTotal (lineId: number, qty: number, price: number): number {
            const lineToUpdate = this.adjustedLines.find((line) => {
                return line.id === lineId
            })

            let total
            if (lineToUpdate.unit_type && lineToUpdate.unit_type.name === '% of') {
                total = mathHelpers.getPercent(lineToUpdate.unit_amount, lineToUpdate.unit_quantity, 2)
            } else {
                total = qty * price
            }
            lineToUpdate.total = total

            this.calcTotalAllocated()

            return total
        },

        calcTotalAllocated (): void {
            this.totalAllocated = this.adjustedLines?.length
                ? this.adjustedLines.reduce((totalSum, line) => totalSum + parseFloat(line.total), 0)
                : 0
        },

        deleteLine (id: number): void {
            this.adjustedLines = this.adjustedLines.filter(line => line.id !== id)
            this.calcTotalAllocated()
        },

        saveLines (): void {
            this.$emit('filledLines', this.adjustedLines)
            this.$emit('totalAllocated', this.totalAllocated)
            this.toggleCalculatorModal(false)
        },

        unitSelect (id: number): void {
            const line = this.adjustedLines.find(line => line.id === id)

            switch (line.unit_type.name) {
            case 'Gross Square Meters':
            case 'Gross Square Feet':
                line.unit_quantity = this.grossArea || 1
                break
            case 'Rentable Square Meters':
            case 'Rentable Square Feet':
                line.unit_quantity = this.rentableArea || 1
                break
            case 'Usable Square Meters':
            case 'Usable Square Feet':
                line.unit_quantity = this.usableArea || 1
                break
            default:
                line.unit_quantity = 1
                break
            }
        },

        async getUnits (): Promise<void> {
            try {
                if (this.projectMetricSystem) {
                    const data = await this.getByUnitType(this.projectMetricSystem)
                    this.units = data
                }
            } catch (e) {
                this.units = []
            }
        },
    },
})
