import { defineComponent } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'

export default defineComponent({
    name: 'BulkEditModal',
    components: {
        IOModal,
    },
    props: {
        categories: {
            type: Array as () => { label: string, value: string }[],
            required: true,
        },
    },
    data () {
        return {
            selectedCategory: null,
        }
    },
})
