import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import {
    FundingCommonItems,
    FundingSourceFundProjects, FundingSourcesFund,
    FundingSourcesFunds,
    FundingSourcesFundSummary,
    FundingSourcesProvider, FundingSourceTimeline,
} from '@/io-modules/global-funding/interfaces/GlobalFundingInterfaces.ts'

export default {
    getProvidersList (): AxiosPromise<FundingCommonItems<FundingSourcesProvider[]>> {
        return client.get('/funds-providers')
    },
    getProviderInfo (providerId: string): AxiosPromise<FundingSourcesProvider> {
        if (!providerId) {
            return Promise.reject(new Error('No provider ID provided'))
        }

        return client.get(`/funds-providers/${ providerId }`)
    },
    getProviderDetailsSummary (providerId: string): AxiosPromise<FundingSourcesFundSummary> {
        if (!providerId) {
            return Promise.reject(new Error('No provider ID provided'))
        }

        return client.get(`/funds-providers/${ providerId }/summary`)
    },
    getProviderCapitalFunds (providerId: string): AxiosPromise<FundingCommonItems<FundingSourcesFunds[]>> {
        if (!providerId) {
            return Promise.reject(new Error('No provider ID provided'))
        }

        return client.get(`/funds-providers/${ providerId }/funds`)
    },
    getProviderCapitalFundProjects (fundId: string): AxiosPromise<FundingSourceFundProjects> {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds/${ fundId }/projects`)
    },
    getAllRelatedProjects (fundId: string): AxiosPromise<FundingSourceFundProjects> {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds-providers/${ fundId }/projects`)
    },
    getCapitalFundInfo (fundId: string): AxiosPromise<FundingSourcesFund> {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds/${ fundId }`)
    },
    removeCapitalFund (fundId: string): AxiosPromise {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.delete(`/funds/${ fundId }`)
    },
    getCapitalFundSummary (fundId: string): AxiosPromise<FundingSourcesFundSummary> {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds/${ fundId }/summary`)
    },
    getCapitalFundProjects (fundId: string): AxiosPromise<FundingSourceFundProjects> {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds/${ fundId }/projects`)
    },
    getCapitalFundProviderInfo (fundId: string): AxiosPromise<FundingSourcesProvider> {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds/${ fundId }/provider`)
    },
    getCapitalFundTimeline (fundId: string): AxiosPromise<FundingCommonItems<FundingSourceTimeline[]>> {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds/${ fundId }/timeline`)
    },
    setProvider (providerId: string): AxiosPromise<{ id: string }> {
        if (!providerId) {
            return Promise.reject(new Error('No provider ID provided'))
        }

        return client.post('/funds-providers', {
            contact_id: providerId
        })
    },
    createCapFund (providerId: string, request: FundingSourcesFund): AxiosPromise {
        if (!providerId) {
            return Promise.reject(new Error('No provider ID provided'))
        }

        return client.post(`/funds-providers/${ providerId }/funds`, request)
    },
    createCapFundFromProject (request: FundingSourcesFund): AxiosPromise {
        return client.post('/funds-providers/funds', request)
    },
    editCapitalFund (providerId: string, fundId: string, request: FundingSourcesFund): AxiosPromise {
        if (!providerId) {
            return Promise.reject(new Error('No provider ID provided'))
        }

        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.put(`/funds-providers/${ providerId }/funds/${ fundId }`, request)
    }
}
