<template>
    <slot :resourceApprovals="resourceApprovals"></slot>
    <ApprovalModal
        v-if="showApprovalModal"
        :action="approvalModalData.action"
        :step="approvalModalData.step"
        :resourceName="resourceName"
        :resourceApprovals="resourceApprovals"
        :hide-files="hideFiles"
        :is-client-off-system="isClientOffSystem"
        :z-index="zIndex"
        :force-client-approval="forceClientApproval"
        @approved="approved"
        @rejected="rejected"
        @close="closeModal()"
        @recordClientApproval="openClientApprovalModal()"
        @submitToClient="submitToClient()"
    />
    <RecordClientApprovalModal
        v-if="showRecordClientApprovalModal"
        :resourceName="resourceName"
        :approvableResourceId="approvableResourceId"
        :hide-files="hideFiles"
        @close="closeModal()"
        @clientApproved="clientApproved"
    />
</template>

<script lang="ts" src="./ApprovalsWrapper.ts"></script>

