import { defineComponent } from 'vue'
import { isEmpty, cloneDeep } from 'lodash'

export default defineComponent({
    props: {
        width: { type: Number, default: 120 },
        options: { type: Object as () => any, required: true },
        placeholder: { type: String, default: '' },
        value: { type: [String, null], default: null },
        invalidCurrencies: { type: Array as () => string[], default: () => [] }
    },
    emits: ['select'],
    data () {
        return {
            groupBy: '',
            optionsShown: false,
            search: '',
            optionsSearched: []
        }
    },
    computed: {
        dropdownWidth (): string {
            return `width: ${ this.width }px`
        }
    },
    watch: {
        'value' () {
            this.groupBy = this.value
        },
        'optionsShown' () {
            if (this.optionsShown) {
                setTimeout(() => {
                    // @ts-ignore
                    $(this.$el).find('.io-dropdown-search input').focus()
                }, 10)
            } else {
                this.onAfterCloseDropdown()
            }
        },
        'search' () {
            this.optionsSearched = this.options.filter((item: any) => isEmpty(this.search) || (item).toLowerCase().includes((this.search).toLowerCase()))
        }
    },
    created () {
        this.groupBy = this.value
        // @ts-ignore
        this.optionsSearched = cloneDeep(this.options)
    },
    methods: {
        onToggleDropdown (): void {
            this.optionsShown = !this.optionsShown
        },
        onClickOption (val: any): void {
            if (!this.invalidCurrencies.includes(val.label)) {
                this.$emit('select', val)
                this.closeDropdown()
            }
        },
        closeDropdown (): void {
            this.optionsShown = false
        },
        onAfterCloseDropdown () {
            this.search = ''
        }
    }
})
