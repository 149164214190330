<template>
    <div
        v-click-outside="vcoConfig"
        class="io-action-dropdown"
        :class="{ 'io-action-dropdown--disabled': disabled }"
    >
        <span
            ref="activator"
            v-tooltip="{
                content: iconTooltip || $t('Import / Export'),
                delay: { show: 2000, hide: 500 },
                visible: showTooltip,
            }"
            class="io-action-dropdown__icon-wrapper"
            :data-testid="testId"
            @click.stop="onIconClick"
        >
            <i :class="iconClass"/>
        </span>

        <ActionDropdownMenu
            v-if="isOpen"
            :anchor="$refs.activator"
            :data-testid="menuTestId"
            :class="menuClass"
            @click="hideMenu"
        >
            <slot v-if="$slots.default" :close="hideMenu" />

            <template v-else>
                <a class="io-action-dropdown__menu__button-import" @click="onImport">
                    <i class="icon-import"/>

                    <span>{{ $t('Import') }}</span>
                </a>

                <a class="io-action-dropdown__menu__button-export" @click="onExport">
                    <i class="icon-export"/>

                    <span>{{ $t('Export') }}</span>
                </a>
            </template>
        </ActionDropdownMenu>
    </div>
</template>

<script lang="ts" src="./ActionDropdown.ts"></script>
<style lang="scss" src="./ActionDropdown.scss" scoped/>
