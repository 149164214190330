export function SET_IS_ADDING_ANNOTATION (state, value) {
    state.isAddingAnnotation = value
}

export function SET_ADDING_ANNOTATION_TYPE (state, value) {
    state.addingAnnotationType = value
}

export function SET_ADDED_TASK (state, value) {
    state.addedTask = value
}

export function SET_REMOVING_ANNOTATION (state, value) {
    state.removingAnnotation = value
}

export function SET_ADDED_ANNOTATION_ID (state, value) {
    state.addedAnnotationId = value
}

export function SET_ANNOTATION_DATA (state, data) {
    state['annotationData'] = data
}

export function CLEAR_ANNOTATION_DATA (state) {
    state['annotationData'] = {}
}
export function SET_ANNOTATION_ID_TO_REMOVE (state, id) {
    state.annotationIdToRemove = id
}

export function SET_SELECTED_ANNOTATION_ID (state, id) {
    state.selectedAnnotationId = id
}

export function SET_DOCUMENT_VERSION_ID (state, id) {
    state.documentVersionId = id
}

export function SET_MENTION_USERS (state, users) {
    state.mentionUsers = users
}

export function SET_MENTION_USERS_FOR_MY_COMPANY (state, users) {
    state.myCompanyMentionUsers = users
}

export function SET_MENTION_USERS_FOR_OTHER_PARTY_COMPANY (state, users) {
    state.otherPartyCompanyMentionUsers = users
}

export function SET_SIDEBAR_OPEN (state, value) {
    state.isSidebarOpen = value
}

export function SET_SIDEBAR_ACTIVE_TAB (state, value) {
    state.sidebarActiveTab = value
}
export function SET_SELECTED_MARKUP_TYPES (state, value) {
    state.selectedMarkupTypes = value
}
