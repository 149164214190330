import qs from '@/helpers/qs'
import { ApiClientV3, ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import CashFlow from '@/interfaces/modules/projects/modules/forecasting/CashFlow'
import CreateProjectCashFlowData
    from '@/interfaces/modules/projects/modules/forecasting/request/CreateProjectCashFlowRequestData'
import CashFlowCostCode from '@/interfaces/modules/projects/modules/forecasting/CashFlowCostCode'
import TimelineEvent from '@/interfaces/modules/projects/modules/forecasting/TimelineEvent'
import CashFlowResponse from '@/interfaces/modules/projects/modules/forecasting/request/CashFlowResponse.ts'
import CashFlowMonthResponse from '@/interfaces/modules/projects/modules/forecasting/request/CashFlowMonthResponse.ts'
import CurveType from '@/interfaces/modules/projects/modules/forecasting/CurveType.ts'
import CashFlowOption from '@/interfaces/modules/projects/modules/forecasting/CashFlowOption.ts'
import CashFlowTask from '@/interfaces/modules/projects/modules/forecasting/CashFlowTask.ts'

const cashFlowClient = {
    getById (cashFlowId: string): AxiosPromise<CashFlowResponse> {
        return ApiClientV3.get(`cash-flows/${ cashFlowId }`)
    },

    getCashFlowsByProjectId (projectId: string): AxiosPromise<CashFlowOption[]> {
        return ApiClientV3.get('/cash-flows', {
            params: { projectId },
            paramsSerializer: params => qs.stringify(params),
        })
    },

    createCashFlow ({ type, projectId } : CreateProjectCashFlowData): AxiosPromise<void> {
        return ApiClientV3.post('/cash-flows', { type, project_id: projectId })
    },

    delete (cashFlowId: string): AxiosPromise<void> {
        return ApiClientV3.delete(`/cash-flows/${ cashFlowId }`)
    },

    fixDuplicatedPeriods (cashFlowId: string): AxiosPromise<void> {
        return client.post(`/cash-flows/${ cashFlowId }/fix-duplicated-periods`)
    },

    fixItemPeriodsFromCategory (cashFlowId: string, costCodeId: string): AxiosPromise<void> {
        return client.post(`/cash-flows/${ cashFlowId }/cost-codes/${ costCodeId }/fix-item-periods-from-category`)
    },

    updateConfiguration (cashFlowId: string, includeApprovedInvoices: boolean): AxiosPromise<void> {
        return ApiClientV3.put(`/cash-flows/${ cashFlowId }/configuration`, { include_approved_invoices: includeApprovedInvoices })
    },

    saveProjectCashFlow (data: CashFlow): AxiosPromise<void> {
        return ApiClientV3.put(`/cash-flows/${ data.id }`, {
            id: data.id,
            start_date: data.startDate.format('YYYY-MM'),
            end_date: data.endDate.format('YYYY-MM'),
            status: data.status,
            phases: data.phases.map(phase => ({
                id: phase.id,
                categories: phase.categories.map(category => ({
                    id: category.id,
                    cost_codes: category.costCodes.map(costCode => ({
                        id: costCode.id,
                        name: costCode.name,
                        budget_cost_code_id: costCode.budgetCostCodeId,
                        code: costCode.code,
                        tasks: costCode.tasks.map(task => ({
                            id: task.id,
                            name: task.name,
                            code: task.code,
                            start_date: task.startDate.format('YYYY-MM'),
                            end_date: task.endDate.format('YYYY-MM'),
                            curve_type: task.curveType,
                            periods: task.periods.map(period => ({
                                id: period.id,
                                value: (period.value && !isNaN(period.value) ? Number(period.value) : 0).toFixed(2),
                                start_date: period.startDate.format('YYYY-MM'),
                            })),
                        })),
                    })),
                })),
            })),
        })
    },

    saveProjectCostCode (cashFlow: CashFlow, costCode: CashFlowCostCode, task: CashFlowTask): AxiosPromise<void> {
        return ApiClientV3.put(`/cash-flows/${ cashFlow.id }/cost-codes/${ costCode.id }/tasks/${ task.id }`, {
            id: task.id,
            name: task.name,
            code: task.code,
            start_date: task.startDate.format('YYYY-MM'),
            end_date: task.endDate.format('YYYY-MM'),
            curve_type: task.curveType,
            periods: task.periods.map(period => ({
                id: period.id,
                value: period.value.toFixed(2),
                start_date: period.startDate.format('YYYY-MM'),
            })),
        })
    },

    generateCurveData (projectId: string, cashFlowId: string, costCode: CashFlowCostCode, curveType: CurveType, totalAnticipatedValue: string): AxiosPromise<CashFlowMonthResponse[]> {
        return client.post(`/projects/${ projectId }/cash-flows/${ cashFlowId }/cost-codes/${ costCode.id }/generate`, {
            id: costCode.id,
            curve_type: curveType,
            start_date: costCode.startDate.format('YYYY-MM'),
            end_date: costCode.endDate.format('YYYY-MM'),
            total_anticipated_value: totalAnticipatedValue,
        })
    },

    shareCashFlow (cashFlowId: string, isShare: boolean): AxiosPromise<void> {
        return client.post('/cash-flows/share', {
            cash_flow_id: cashFlowId,
            is_share: isShare
        })
    },

    cashFlowTimeline (cashFlow: CashFlow, projectId: string): AxiosPromise<TimelineEvent[]> {
        return client.get(`/projects/${ projectId || cashFlow.projectId }/cash-flows/${ cashFlow.id }/timeline`)
    }
}

export default cashFlowClient
