<template>
    <div class="io-notifications-settings-dropdown">
        <div
            :class="{'is-open': optionsShown}"
            :style="dropdownWidth"
            class="io-dropdown__button"
            @click="onToggleDropdown"
        >
            <span v-if="!selectedItem.label || optionsShown" class="io-dropdown-placeholder">{{ placeholder }}</span>
            <span v-else>{{ selectedItem.label }}</span>
        </div>

        <transition name="fade">
            <div v-if="optionsShown" :style="dropdownWidth" class="io-dropdown__list">
                <div class="io-dropdown__mask" @click="closeDropdown"></div>
                <div class="io-dropdown__content">
                    <div class="io-dropdown__options">
                        <template v-for="(option, index) in options" :key="index">
                            <div
                                :class="{'io-selected': selectedItem.label === option.label}"
                                class="option"
                                @click="onClickOption(option)">
                                {{ option.label }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" src="./Dropdown.ts"></script>
<style lang="scss" scoped src="./Dropdown.scss"></style>
