import { defineComponent } from 'vue'
import type { Tab } from '../TabInterface'
import StatusDot from '@/components/atoms/status-dot/StatusDot.vue'
import { DotVariant } from '../../status-dot/enums/StatusDotEnums'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
    name: 'Tab',
    components: {
        StatusDot,
    },
    props: {
        tab: { type: Object as () => Tab, required: true },
        activeTab: { type: [ String, Number ], default: 0 },
        translate: { type: Boolean, default: false },
        isVertical: { type: Boolean, default: false },
        mustCompareOnlyKeyForActive: { type: Boolean, default: false },
    },
    emits: ['click', 'remove'],
    data () {
        return {
            DotVariant,
        }
    },
    computed: {
        title (): TranslateResult | string {
            return this.translate ? this.$t(this.tab.title) : this.tab.title
        },
        isActive (): boolean {
            if (this.mustCompareOnlyKeyForActive) {
                return this.activeTab === this.tab.key
            }

            return (this.activeTab === this.tab.index || this.activeTab === this.tab.key)
        },
    },
    methods: {
        onClick (index: number): void {
            this.$emit('click', index)
        },
        onClickRemoveTab (index: number): void {
            this.$emit('remove', index)
        },
    }
})
