<template>
    <div class="io-num-field-holder" :class="{ 'input-group': !isFloated, 'input-addon': !disableAddon, 'io-is-disabled': disabled }">
        <div v-if="!disableAddon" class="input-group-addon">{{ signComputed }}</div>
        <Cleave
            :id="id"
            ref="inputCleave"
            :options="cleaveOptions"
            :placeholder="showPlaceholder ? placeholder : ''"
            :autocomplete="autocomplete"
            :readonly="readonly"
            :max="max"
            :disabled="disabled"
            :class="classes"
            :name="name"
            v-model="componentValue"
            @keydown.tab="checkTabKeyup"
            @focus.prevent="onFocus"
            @blur="onBlur"
        />
        <div v-if="!valid && errorMessage" class="io-num-field-holder__error-message">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts">
    import Cleave from 'vue-cleave-component'
    import { assign, debounce, isEmpty, isNull, isString, isUndefined } from 'lodash'
    import { defineComponent } from 'vue'

    const allowedNonNumericValues = [
        '-',
        '',
        '0.',
        '-0',
        '-0.',
        null,
    ]

    export default defineComponent({
        name: 'FieldNumeric',
        components: {
            Cleave,
        },
        props: {
            id: {
                type: String,
                default: '',
                required: false,
            },
            name: {
                type: String,
                default: '',
                required: false,
            },
            classname: {
                type: String,
                default: '',
                required: false,
            },
            disableAddon: {
                type: Boolean,
                default: false,
                required: false,
            },
            autocomplete: {
                type: String,
                default: 'on',
                required: false,
            },
            sign: {
                type: String,
                default: '$',
                required: false,
            },
            modelValue: {
                type: [ Number, String ],
                default: null,
            },
            min: {
                type: Number,
                default: null,
                required: false,
            },
            max: {
                type: Number,
                default: null,
                required: false,
            },
            readonly: {
                type: Boolean,
                default: false,
                required: false,
            },
            options: {
                type: Object,
                default: () => ({}),
                required: false,
            },
            disabled: {
                type: Boolean,
                default: false,
                required: false,
            },
            placeholder: {
                type: String,
                default: '0.00',
                required: false,
            },
            lockTabClick: {
                type: Boolean,
                default: false,
                required: false,
            },
            showPlaceholder: {
                type: Boolean,
                default: true,
                required: false
            },
            castToNumber: {
                type: Boolean,
                default: true,
                required: false
            },
            valid: {
                type: Boolean,
                default: true
            },
            errorMessage: {
                type: String,
            },
            numberCharsLimit: {
                type: Number,
                required: false,
            },
        },

        emits: [ 'update:modelValue', 'onFocus', 'onBlur', 'onTabClick' ],
        data () {
            return {
                componentValue: null,
                cleaveOptions: {
                    creditCard: false,
                    phone: false,
                    phoneRegionCode: 'US',
                    date: false,
                    datePattern: [ 'm', 'd', 'Y' ],
                    time: false,
                    timePattern: [ 'h', 'm', 's' ],
                    timeFormat: '12',
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralDecimalScale: 2,
                    numeralDecimalMark: '.',
                    numeralPositiveOnly: true,
                    stripLeadingZeroes: true,
                    blocks: [],
                    delimiter: ',',
                    prefix: null,
                    numericOnly: true,
                    uppercase: false,
                    lowercase: false,
                },
                previousValue: null,
            }
        },
        computed: {
            isFloated: function () {
                if (this.classname) {
                    if (this.classname.includes('fright') || this.classname.includes('fleft')) {
                        return true
                    }
                }
                return false
            },

            classes () {
                if (!isNull(this.classname) && this.classname !== '') {
                    return this.classname.split(' ')
                } else {
                    return null
                }
            },

            limitMax () {
                if (this.max === undefined || this.max === null) {
                    return null
                }
                const value = Number(this.max)
                if (Number.isNaN(value)) {
                    return null
                }
                return value
            },

            limitMin () {
                if (this.min === undefined || this.min === null) {
                    return null
                }
                const value = Number(this.min)
                if (Number.isNaN(value)) {
                    return null
                }
                return value
            },

            signComputed () {
                return this.sign === '$' ? this.getWorkspaceCurrencySymbol() : this.sign
            },
        },

        watch: {
            modelValue () {
                this.initDebounced()
            },

            componentValue (newData) {
                const modelValueIsEmptyString = isString(this.modelValue) && isEmpty(this.modelValue)
                if (!modelValueIsEmptyString && Number(newData) === Number(this.modelValue)) {
                    return
                }

                if (!isNull(this.numberCharsLimit) && !isUndefined(this.numberCharsLimit) && newData.length > this.numberCharsLimit) {
                    this.componentValue = this.previousValue
                } else {
                    this.previousValue = newData
                    this.componentValue = this.prepareValue(newData)
                    this.$emit('update:modelValue', this.componentValue)
                }
            }
        },

        created () {
            this.cleaveOptions = assign(this.cleaveOptions, this.options)
        },
        beforeMount () {
            this.init()
        },

        methods: {
            init () {
                this.componentValue = this.prepareValue(this.modelValue)
                this.previousValue = this.componentValue
            },
            initDebounced: debounce(function () {
                this.init()
            }, 1000),
            prepareValue (value) {
                if (!this.cleaveOptions.numericOnly && allowedNonNumericValues.includes(value)) {
                    return value
                }

                if (Number.isNaN(Number(value))) {
                    value = null
                }
                if (this.limitMax !== null && value > this.limitMax) {
                    return this.limitMax
                }
                if (this.limitMin !== null && value < this.limitMin) {
                    return this.limitMin
                }

                if (this.castToNumber) {
                    return value !== null ? Number(value) : null
                }

                return value
            },

            focus () {
                this.$refs.inputCleave.$el.focus()
            },

            onFocus () {
                this.$emit('onFocus')
            },

            onBlur () {
                this.$emit('onBlur')
            },

            checkTabKeyup (e) {
                if (this.lockTabClick) {
                    e.preventDefault()
                }
                this.$emit('onTabClick', e)
            },
        },
    })
</script>

<style lang="scss" scoped>
    .input-group-addon {
        padding: 0;
        width: 30px;
        text-align: center;
        background-color: var(--light-bg);
        color: var(--closed);
        display: inline;
        font-weight: 500;
        max-height: 36px;
        line-height: 36px;
        border: 1px solid var(--main-lighten-4);
        border-radius: 4px;
        position: absolute;
    }

    .io-num-field-holder {
        position: relative;

        &.input-addon {
            input {
                padding-left: 36px !important;
            }
        }

        &__error-message {
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            color: var(--input-error-message-color);
        }

        &.io-is-disabled {
            background: var(--lighten-bg-3);
        }
    }
</style>
