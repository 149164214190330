const FormDefault = () => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue')
const ContactDetails = () => import(/* webpackChunkName: "form-generator" */ '@/modules/contact/details-new/ContactDetails.vue')
const ContactsList = () => import(/* webpackChunkName: "form-generator" */ './list/ContactsList.vue')

export default [
    {
        path: '/contact/list',
        name: 'contact-list',
        component: ContactsList,
        meta: {
            schema: 'contact',
            title: 'Contacts List'
        }
    },
    {
        path: '/contact/edit/:id',
        name: 'contact-edit',
        component: FormDefault,
        meta: {
            schema: 'contact',
            title: 'Edit Contact'
        }
    },
    {
        path: '/contact/edit',
        name: 'contact-new',
        component: FormDefault,
        meta: {
            schema: 'contact',
            title: 'Edit Contact'
        }
    },
    {
        path: '/contact/import',
        name: 'contact-import',
        component: FormDefault,
        meta: {
            path: '/contact/:id',
            schema: 'contact/import',
            title: 'Import Contact'
        }
    },
    {
        path: '/contact/view/:id',
        name: 'contact-details',
        component: ContactDetails,
        meta: {
            schema: 'contact',
            title: 'Contact Details'
        }
    },
]
