<template>
    <tr
        class="io-simple-lazy-list__row"
        :class="{ 'io-simple-lazy-list__row--clickable': isClickable }"
        @click.stop="handleLeftClick(item)"
        @click.middle.exact.stop.prevent="handleMiddleClick(item)"
    >
        <template v-for="column in columns" :key="column.id">
            <Component
                :is="getCellComponent(column.valueType)"
                :item="item"
                :column="column"
            />
        </template>
    </tr>
</template>

<script lang="ts" src="./SimpleLazyListRow.ts"></script>
<style lang="scss" src="./SimpleLazyListRow.scss" scoped></style>
