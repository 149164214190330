import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { ProjectFundingAllocatedRows } from '@/io-modules/project-funding/interfaces/ProjectFundingAllocatedRows'
import ProjectFundingProviderFund from '@/io-modules/project-funding/interfaces/ProjectFundingProviderFund'
import ProjectFundingSummaryInt from '@/io-modules/project-funding/interfaces/ProjectFundingSummary'
import ProjectFundingCommonItems from '@/io-modules/project-funding/interfaces/ProjectFundingCommonItems'
import ProjectFundingFund from '@/io-modules/project-funding/interfaces/ProjectFundingFund'
import ProjectFundingProviderFunds from '@/io-modules/project-funding/interfaces/ProjectFundingProviderFunds'
import DocumentInterface from '@/io-modules/documents/interfaces/DocumentInterface'
import ProjectFundingTimeline from '@/io-modules/project-funding/interfaces/ProjectFundingTimeline'
import ProjectFundingContact from '@/io-modules/project-funding/interfaces/ProjectFundingContact.ts'

export default {
    getProjectFundingSummary (projectId: string): AxiosPromise<ProjectFundingSummaryInt> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        return client.get(`/funds-projects/${ projectId }/summary`)
    },

    getProjectFundingFunds (projectId: string, search: string = ''): AxiosPromise<ProjectFundingCommonItems<ProjectFundingFund[]>> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        return client.get(`/funds-projects/${ projectId }/funds?search=${ search }`)
    },

    getProjectProviderFunds (projectId: string, search: string = ''): AxiosPromise<ProjectFundingCommonItems<ProjectFundingProviderFunds[]>> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        return client.get(`/funds-providers/aggregate/funds?skip_project_id=${ projectId }&search=${ search }`)
    },

    setAllocationToFund (projectId: string, allocation: ProjectFundingAllocatedRows[]): AxiosPromise<ProjectFundingAllocatedRows[]> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }
        if (!allocation) {
            return Promise.reject(new Error('Allocation data is invalid or empty'))
        }
        return client.post(`/funds-projects/${ projectId }/funds/bulk-assign`, allocation)
    },

    removeFund (fundId: string): AxiosPromise {
        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }
        return client.delete(`/funds/${ fundId }`)
    },

    removeCapFundFromProject (projectId: string, fundId: string): AxiosPromise {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.delete(`/funds-projects/${ projectId }/funds/${ fundId }`)
    },

    createProjSpecFund (projectId: string, data: ProjectFundingProviderFund): AxiosPromise<ProjectFundingProviderFund> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        if (!data) {
            return Promise.reject(new Error('No data provided'))
        }

        return client.post(`/funds-projects/${ projectId }/funds`, data)
    },

    getProjectFundDetails (projectId: string, fundId: string): AxiosPromise<ProjectFundingProviderFund> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds-projects/${ projectId }/funds/${ fundId }`)
    },

    getProjectFundDetailsFiles (projectId: string, fundId: string): AxiosPromise<DocumentInterface> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds-projects/${ projectId }/funds/${ fundId }/documents`)
    },

    updateProjectFundDetails (projectId: string, fundId: string, data: ProjectFundingProviderFund): AxiosPromise<ProjectFundingProviderFund> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        if (!data) {
            return Promise.reject(new Error('No data provided'))
        }

        return client.put(`/funds-projects/${ projectId }/funds/${ fundId }`, data)
    },

    exportProjectFunds (projectId: string, fundIds: string[], type: string): string {
        if (!projectId) {
            throw new Error('No project ID provided')
        }

        if (!fundIds) {
            throw new Error('No fund IDs provided')
        }

        if (!type) {
            throw new Error('No type provided')
        }

        return client.getApi2RequestUrlWithParams(`/funds-projects/${ projectId }/pdf`, {
                params: {
                    fund_ids: fundIds,
                    type: type,
                },
            },
        )
    },

    getParticularFundTimeline (projectId: string, fundId: string): AxiosPromise<ProjectFundingCommonItems<ProjectFundingTimeline[]>> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        if (!fundId) {
            return Promise.reject(new Error('No fund ID provided'))
        }

        return client.get(`/funds-projects/${ projectId }/funds/${ fundId }/timeline`)
    },

    getProjectFundingTimeline (projectId: string): AxiosPromise<ProjectFundingCommonItems<ProjectFundingTimeline[]>> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        return client.get(`/funds-projects/${ projectId }/timeline`)
    },

    getDefaultProvider (projectId: string): AxiosPromise<ProjectFundingContact> {
        if (!projectId) {
            return Promise.reject(new Error('No project ID provided'))
        }

        return client.get(`/funds-projects/${ projectId }/default-contact`)
    }
}
