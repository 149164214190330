import { defineComponent } from 'vue'
import useLoader from '@/composables/useLoader.ts'

import drawingAnnotationsClient from '@/io-modules/drawings/api-clients/drawingAnnotationsClient.ts'

import PopupModal from '@/components/popup-modal/PopupModal.vue'
import TooltipIcon from '@/components/atoms/TooltipIcon/TooltipIcon.vue'

import { DrawingAnnotationInterface } from '@/interfaces/modules/projects/drawings-v2/DrawingAnnotationInterface.ts'

export default defineComponent({
    name: 'ShareMarkupPopup',
    components: {
        PopupModal,
        TooltipIcon,
    },
    props: {
        data: {
            type: Array as () => DrawingAnnotationInterface[],
            required: true,
        },
        visibilityEnums: {
            type: Object,
            required: true,
        },
    },
    setup () {
        const { load } = useLoader({ globalSpinner: true })
        return { load }
    },
    data () {
        return {
            markupVisibility: this.data[0].visibility,
        }
    },
    computed: {
        multipleSelected (): boolean {
            return this.data.length > 1
        },
        visibilityChanged (): boolean {
            if (this.multipleSelected) {
                return true
            }
            return this.markupVisibility !== this.data[0]?.visibility
        },
    },
    methods: {
        onDiscard (): void {
            this.$store.commit('drawingsV2/SET_SHARE_MARKUP_POPUP_SHOWN', false)
        },
        async onChange (): Promise<void> {
            await this.shareMarkup()
            this.onDiscard()
        },
        async shareMarkup (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const { data } = this.multipleSelected
                    ? await drawingAnnotationsClient.changeManyAnnotationsVisibility({
                        ids: this.data.map((item: DrawingAnnotationInterface) => item.id),
                        visibility: this.markupVisibility,
                    })
                    : await drawingAnnotationsClient.changeSingleAnnotationVisibility({
                        id: this.data[0].id,
                        visibility: this.markupVisibility,
                    })

                this.$store.commit('drawingsV2/SET_SHARE_MARKUP_DATA', data)
                this.$emit('markupVisibilityChanged')
                this.showNotification('success', this.$t('drawing.annotations.visibility.success'))
            })
        },
    },
})
