export const isAddingAnnotation = (state) => {
    return state.isAddingAnnotation
}

export const addingAnnotationType = (state) => {
    return state.addingAnnotationType
}

export const getSelectedAnnotationId = (state) => {
    return state.selectedAnnotationId
}

export const getMentionUsers = (state) => {
    return state.mentionUsers
}

export const getMyCompanyMentionUsers = (state) => {
    return state.myCompanyMentionUsers
}

export const getOtherPartyCompanyMentionUsers = (state) => {
    return state.otherPartyCompanyMentionUsers
}

