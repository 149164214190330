<template>
    <Table
        class="io-purchase-order-table"
        :headers="headers"
    >   
        <template #tbody>
            <tr>
                <td>
                    <span>{{ getPayAppPurchaseOrder.name }}</span>
                    <span class="io-table-icon icon-external-link" @click="goPurchaseOrder()"></span>
                </td>
                <td class="align-end">
                    <span>{{ $filters.formatProjectCurrencyValue(getPayAppPurchaseOrder.amount) }}</span>
                </td>
                <td class="align-end">
                    <span>
                        {{ $filters.formatProjectCurrencyValue(getPayAppPurchaseOrder.billed_against_to_date) }}
                    </span>
                </td>
                <td v-if="isEditingMode" class="align-end">
                    <span v-if="!isLoading" class="io-table-icon icon-trash" @click="handleRemovePurchaseOrder"></span>
                </td>
            </tr>
        </template>
    </Table>
</template>

<script lang="ts" src="./PurchaseOrderField.ts"></script>
<style lang="scss" src="./PurchaseOrderField.scss" scoped></style>