import ProposalResponse from '@/io-modules/rfp-proposal/interfaces/ProposalResponse'
import { ApiClient } from '@/api/api'
import { AxiosPromise } from 'axios'
import type { BidProposalResponse } from '../interfaces/BidProposalResponse'
import type MarkAsLostResponse from '../interfaces/MarkAsLostResponse'

const proposalClient = {
    getOneItem (id: string): AxiosPromise<ProposalResponse> {
        return ApiClient.post('proposal/get', { id })
    },

    create (data: {bidrequest_id: string}): AxiosPromise<{ code: number, id: string }> {
        return ApiClient.post('proposal/create', data)
    },

    update (data: Object): AxiosPromise<{code: number, id: string}> {
        return ApiClient.post('proposal/update', data)
    },

    uploadFile (data: Object, proposalQuote: string): AxiosPromise<{code: number, success: boolean}> {
        return ApiClient.formData(`upload-model-document/proposals/${ proposalQuote }`, data)
    },

    createFolder (proposalQuote: string): AxiosPromise<{code: number, success: boolean}> {
        return ApiClient.post(`create-model-folder/proposals/${ proposalQuote }`)
    },

    removeFileProposal (fileId: string): AxiosPromise<{code: number, success: boolean}> {
        return ApiClient.delete('documents/remove', { params:
            {
                files: [ fileId ]
            }
        })
    },

    deleteFileProposal (fileId: string): AxiosPromise<{code: number, success: boolean}> {
        return ApiClient.delete('documents/hard-delete', { params:
            {
                files: [ fileId ]
            }
        })
    },

    restoreFileProposal (fileId: string): AxiosPromise<{code: number, success: boolean}> {
        return ApiClient.put('documents/restore', { files: [fileId] })
    },

    submitProposal (data: { id: string }): AxiosPromise<{code: number, success: boolean}> {
        return ApiClient.post('proposal/submit/client', data)
    },

    getProposalByBid (quoteId: string ): AxiosPromise<{ model: BidProposalResponse }> {
        return ApiClient.get(`proposal/get-bid/${ quoteId }`)
    },

    markAsLost (id: string): AxiosPromise<MarkAsLostResponse> {
        return ApiClient.post('proposal/mark-as-lost', { id })
    },

    deleteProposal (id: string): AxiosPromise<void> {
        return ApiClient.delete(`/proposal/${ id }`, { params: { id } })
    }
}

export default proposalClient
