<template>
    <div v-if="!error && !loading" class="io-invoice-details-wrapper">
        <div class="io-info-card--white">
            <div class="io-invoice-details-wrapper__title">
                {{ invoiceDetailsTitle }}
                <StatusPill
                    v-if="isVendorInvoice"
                    size="big"
                    :variant="isReceivedInvoice ? 'green-light' : 'gray-light'"
                    :text="isReceivedInvoice ? $t('Provided via System') : $t('Provided Manually')"
                />
                <StatusPill
                    v-if="isOwner && invoice.is_shared"
                    size="big"
                    variant="blue-light"
                    :text=" $t('Shared Invoice')"
                />
            </div>
            <div v-if="appPackageData" class="io-invoice-details-wrapper__sub-title" @click="goToAppPackage">
                <span>{{ $t('Application Package') }}: {{ appPackageData.name }}</span>
                <span class="icon-external-link" @click="gotToAppPackage"></span>
            </div>
            <div class="io-info-wrapper">
                <div class="io-info-wrapper__left-wrapper">
                    <div class="io-info-wrapper__left">
                        <div>
                            <IOSingleInfoRow :label="$t('Project')" :info="projectName" />
                            <IOSingleInfoRow :label="$t('Invoice Date')" :info="invoiceDate" />
                            <IOSingleInfoRow
                                v-if="!isNonContractedInvoice"
                                :label="isVendorInvoice && isReceivedInvoice ? $t('Received from') : $t('Created by')"
                            >
                                <template #info>
                                    <Avatar
                                        v-if="invoice.creator"
                                        size="sm"
                                        :show-full-name="true"
                                        :employee-mongo-id="invoice.creator.id"
                                        :employee-full-name="invoice.creator.name"
                                        :companyName="invoice.creator.role || ''"
                                    />
                                    <span v-else>-</span>
                                </template>
                            </IOSingleInfoRow>
                            <IOSingleInfoRow v-if="isNonContractedInvoice" class="io-non-contracted-category" :label="$t('Category')" :info="invoice.category_name || '-'" />
                        </div>
                        <div>
                            <IOSingleInfoRow :label="$t('Generated ID')" :info="invoice.invoice_number" />
                            <IOSingleInfoRow v-if="!isNonContractedInvoice" :label="$t('Invoice Start & End Date')" :info="invoiceStartEndDate" />
                            <IOSingleInfoRow v-else :label="$t('Due Date')" :info="invoiceDueDate" />
                            <IOSingleInfoRow v-if="isNonContractedInvoice" :label="$t('Vendor')">
                                <template #info>
                                    <Avatar
                                        v-if="invoice.vendor"
                                        size="sm"
                                        :show-full-name="true"
                                        :employee-mongo-id="invoice.vendor.id"
                                        :employee-full-name="invoice.vendor.name"
                                    />
                                    <span v-else>-</span>
                                </template>
                            </IOSingleInfoRow>
                        </div>
                        <div>
                            <IOSingleInfoRow :label="$t('Custom ID')" :info="invoice.vendor_invoice_number || invoice.invoice_unique_number" />
                        </div>
                    </div>
                    <IOSingleInfoRow  :label="$t('Description')" :info="invoice.description || '-'" />
                </div>

                <div class="io-info-wrapper__right">
                    <IOSingleInfoRow class="io-invoice-net-amount" :label="$t('Net Current Application')" :info="netInvoiceAmount" />
                    <IOSingleInfoRow v-if="!isNonContractedInvoice" :label="$t('Current Retention Amount')" :info="currentRetentionAmount" />
                    <IOSingleInfoRow v-if="!isNonContractedInvoice" :label="$t('Gross Current Application')" :info="grossInvoiceAmount" />

                    <IOSingleInfoRow v-if="isOwner && invoiceWithTaxFieldFeature"
                                     :label="$t('Invoice Amount Including Tax')"
                                     :info="invAmountWithTaxValue"
                    >
                        <template #labelRight>
                            <span v-if="invIsNotApproved || !invAmountWithTax"
                                  class="icon-edit io-invoice-tax-amount"
                                  @click="toggleInvAmountWithTaxModal"
                            />
                        </template>
                    </IOSingleInfoRow>
                </div>
            </div>
        </div>
        <div class="io-info-card--grey">
            <div class="io-timeline-header">
                <span class="io-timeline-title">{{ $t('Status') }}</span>
                <StatusPill size="big" :variant="getInvoiceStatusColor(invoice.status)" :text="invoice.status_text"/>
            </div>
            <SingleColumnTimeline :class="{ 'io-invoice-status-timeline--2h': 2 === timelineItems.length }" :timeline-items="timelineItems"/>
            <div class="io-timeline-footer">
                <div class="io-timeline-title">{{ $t('Ball in Court') }}</div>
                <Avatar
                    :show-full-name="true"
                    :employee-mongo-id="getBallInCourt().id"
                    :employee-full-name="getBallInCourt().name"
                />
            </div>
        </div>
    </div>
    <div v-if="error || loading" class="io-invoice-details-wrapper__placeholder">
        <RequestedDataErrorPlaceholder :title="invoiceDetailsTitle" :loading="loading" @reload="fetchData" />
    </div>

    <InvoiceAmountWithTaxModal v-if="showInvAmountWithTaxModal"
                               :value-with-tax="invAmountWithTax"
                               @filledAmount="updateInvAmountWithTax"
                               @close="toggleInvAmountWithTaxModal"
    />
</template>

<script lang="ts" src="./InvoiceDetails.ts"></script>
<style lang="scss" src="./InvoiceDetails.scss" scoped></style>
