import InvoicesTab from '../enums/InvoicesTab'
import ListType from '../enums/ListType'

export default function listTypeName (type: InvoicesTab): ListType {
    switch (type) {
        case InvoicesTab.Received:
            return ListType.Received
        case InvoicesTab.My:
            return ListType.My
        case InvoicesTab.Shared:
            return ListType.Shared
        case InvoicesTab.AppPackages:
            return ListType.AppPackages
    }
}