<template>
    <div class="io-files-table">
        <table>
            <thead>
                <tr>
                    <th class="aleft io-column-xs">
                        <!--                        <label>-->
                        <!--                            <input v-model="allSelected" type="checkbox" :value="allSelected" @change="handleSelectAll">-->
                        <!--                        </label>-->
                    </th>

                    <th
                        class="aleft"
                        @click="changeSort('file_name')"
                    >
                        <span>{{ $t('Name') }}</span>
                        <TableSortIcon
                            :key="1"
                            name="file_name"
                            :sortBy="computedOrder.orderBy"
                            :sortDir="computedOrder.orderDir"
                        />
                    </th>

                    <th
                        class="aleft"
                        @click="changeSort('file_size')"
                    >
                        <span>{{ $t('File Size') }}</span>
                        <TableSortIcon
                            :key="2"
                            name="file_size"
                            :sortBy="computedOrder.orderBy"
                            :sortDir="computedOrder.orderDir"
                        />
                    </th>

                    <th class="aleft no-pointer">
                        <span>{{ $t('Added by') }}</span>
                    </th>

                    <th
                        class="aleft"
                        @click="changeSort('updated_at')"
                    >
                        <span>{{ $t('Last Update') }}</span>
                        <TableSortIcon
                            :key="4"
                            name="updated_at"
                            :sortBy="computedOrder.orderBy"
                            :sortDir="computedOrder.orderDir"
                        />
                    </th>

                    <th
                        v-if="!allowToSelectFiles"
                        class="io-column-xs"
                    ></th>
                </tr>
            </thead>

            <tbody>
                <template v-if="isSearching">
                    <tr class="io-text">
                        <td colspan="6">
                            {{ $t('Fetching data') }}
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <template v-if="folders && folders.length">
                        <SingleFolder
                            v-for="(folder, idx) in folders"
                            :key="idx + folder._id "
                            :allowToSelectFiles="allowToSelectFiles"
                            :arrayId="arrayId"
                            :folder="folder"
                            :fromTrash="fromTrash"
                            :hasFiles="hasFiles"
                            :idx="idx"
                            :isDocumentsView="isDocumentsView"
                            :isProjectView="isProjectView"
                            :isSharingFeatureActive="isSharingFeatureActive"
                            :delegateNavigation="delegateNavigation"
                            @clearFilters="clearSearch"
                            @refreshData="onDataRefresh"
                            @handleNavigation="$emit('handleNavigation', $event)"
                        />
                    </template>

                    <template v-if="showFiles">
                        <SingleFile
                            v-for="(file, idx) in filteredRequests"
                            :key="file._id + idx"
                            :allowToSelectFiles="allowToSelectFiles"
                            :arrayId="arrayId"
                            :file="file"
                            :forceShared="forceShared"
                            :fromTrash="fromTrash"
                            :hideShareColumn="hideShareColumn"
                            :idx="idx"
                            :isProjectView="isProjectView"
                            :lockHotkeys="lockHotkeys"
                            :modelId="modelId"
                            :modelType="modelType"
                            :showShared="showShared"
                            :toSync="toSync"
                            :allowedExtensions="allowedExtensions"
                            @refreshData="onDataRefresh"
                            @tempFileRemove="onTempRemove"
                        />
                    </template>
                    <template v-if="!folders.length && !filteredRequests.length  && !isSearching && !isLoading">
                        <tr class="io-text">
                            <td colspan="6">{{
                                $t('No records found')
                            }}
                            </td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script lang="ts" src="./tab.ts"/>
<style lang="scss" src="./tab.scss" scoped/>
