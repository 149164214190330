import { defineComponent } from 'vue'
import { ItemRouteInterface } from '@/interfaces/components/lazy-list/ItemRoute'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'TableOfContentsItem',
    props: {
        data: { type: Object, required: true },
    },
    computed: {
        ...mapGetters('drawingsV2', {
            activeDrawingSheet: 'getActiveDrawingSheet',
        }),
        sheetName (): string {
            return `${ this.data.page_label } ${ this.data.page_title }`
        },
        itemRoute (): ItemRouteInterface {
            return {
                name: 'drawings-sheets-show',
                params: {
                    sheetId: this.data.id,
                    versionId: this.data.current_version_id,
                },
            }
        },
        isSelectedSheet (): boolean {
            if (this.activeDrawingSheet?.version) {
                return this.data.current_version_id === this.activeDrawingSheet.version
            }
            return this.data.id === this.$store.getters['drawingMarkup/getModel']?.drawing.id
        },
    },
    methods: {
        onSelectSheet (): void {
            this.$store.commit('drawingsV2/SET_ACTIVE_DRAWING_SHEET', {
                id: this.data.id,
                version: this.data.current_version_id,
            })
        },
    },
})
