import { defineComponent, PropType } from 'vue'
import SimpleOption from '../../../interfaces/SimpleOption.ts'
import { mapState } from 'pinia'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView.ts'

export default defineComponent({
    name: 'SitesNavigation',
    props: {
        sites: {
            type: [] as PropType<SimpleOption[]>,
            default: null,
        },
        showHeader: {
            type: Boolean,
            default: false,
        },
        completedIcon: {
            type: String,
            default: 'icon-check-circle-3',
        },
    },
    emits: ['scrollToItem', 'editSites'],
    data () {
        return {
            activeItem: 0,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, ['creating', 'editing']),

        canEditSites (): boolean {
            return (this.creating || this.editing) && !!this.sites
        }
    },
    watch: {
        sites (): void {
            this.activeItem = this.sites[0].project_site_id
        },
    },
    mounted () {
        if (this.sites && this.sites.length) {
            this.activeItem = this.sites[0].project_site_id
        }
    },
    methods: {
        scrollToItem (id: string): void {
            this.$emit('scrollToItem', id)
            this.activeItem = id
        },

        initEditSites (): void {
            this.$emit('editSites')
        },
    }
})
