import intercomClient from '@/api/intercom/intercomClient'

export const loadIntercomCompanyData = async function ({ commit }) {
    try {
        const { data } = await intercomClient.getIntercomCompanyData()

        if (data) {
            commit('SET_BUDGETS_NUMBER', data.budgets_number)
        }
    } catch (exception) {
        return exception
    }
}

export const loadIntercomUserData = async function ({ commit }) {
    try {
        const { data } = await intercomClient.getIntercomUserData()

        if (data) {
            commit('SET_CHANGE_ORDERS_NUMBER', data.change_orders_number)
            commit('SET_BUDGET_CHANGES_NUMBER', data.budget_changes_number)
            commit('SET_INVOICES_NUMBER', data.invoices_number)
            commit('SET_MEETING_MINUTES_NUMBER', data.meeting_minutes_number)
            commit('SET_EMPLOYEE_STATUS', data.employee_status)
        }
    } catch (exception) {
        return exception
    }
}
