<template>
    <div class="io-invite-modal__search-view">
        <p>{{ $tc('The following account was found. | The following accounts were found.', accountsPluralization) }}</p>
        <p>{{ $t('Please select the account that you would like to proceed with.') }}</p>

        <template v-for="(item, idx) in matchedAccountsList" :key="idx">
            <SingleRadioRow
                :item="item"
                :idx="idx"
                :value="item.id"
                :componentKey="componentKey"
                v-model="selectedContact"
            />
        </template>

        <form v-if="!isAddingNewContact">
            <ActionsView
                :componentKey="componentKey"
                :isPartOfView="true"
            />
        </form>

        <p>{{ $t('if_dont_see_workspace_contact_admin') }}
            <a href="mailto:admin@ingenious.build">{{ $t('email') }}</a>.
        </p>
    </div>
</template>
<script lang="ts" src="./ContactFound.ts"></script>
