import ContentTwoSidesTemplate from '@/components/content-two-sides-template/ContentTwoSidesTemplate.vue'
import FieldDateTime from '@/components/fields/fieldDateTime.vue'
import { appPackageViewStore } from '../../../../stores/appPackageView'
import dateTimeHelper from '@/helpers/dateTime'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import LoadingScreen from '@/components/new-theme/loading-screen.vue'

export default defineComponent({
    name: 'AppPackageCreateEdit',
    components: {
        ContentTwoSidesTemplate,
        FieldDateTime,
        LoadingScreen,
    },
    data () {
        return {
            datepickerOptions: {
                'only-date': true,
                noClearButton: true,
                format: 'YYYY-MM-DD',
                formatted: dateTimeHelper.getDateFormat(),
            },
            loading: false,
        }
    },
    computed: {
        ...mapState(appPackageViewStore, ['appPackage', 'editing', 'creating', 'dataLoading']),

        ...mapStateVuex('project', ['projectObj']),

        dateRange: {
            get (): object {
                return {
                    start: this.appPackage.billing_period_start_date,
                    end: this.appPackage.billing_period_end_date,
                }
            },
            set (value: object): void {
                if (value.start !== this.appPackage.billing_period_start_date || value.end !== this.appPackage.billing_period_end_date) {
                    this.appPackage.billing_period_start_date = value.start
                    this.appPackage.billing_period_end_date = value.end
                }
            }

        }
    },
    async beforeMount () {
        await this.loadBillingSettings()
    },
    methods: {
        ...mapActions(appPackageViewStore, ['setBillingSettings', 'setBillingSettingsDates']),

        async loadBillingSettings (): Promise<void> {
            const { data } = await this.$api.get(`payapps/project-settings/${ this.projectObj.project_local_id }`)

            if (data.settings) {
                this.setBillingSettings(data.settings)
                this.setBillingSettingsDates()
            }
        }
    },
})
