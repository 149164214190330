<template>
    <div v-if="fundProvider" class="io-capital-funds">
        <PageHeader :title="$t(fundInfo.name)"
                    :route-back="{ name: 'funding-sources-provider-details', params: { id: fundProvider.id }}">
            <template #actions>
                <IOButton
                    v-if="fundProjects && !fundProjects.items.length"
                    variant="secondary"
                    @click="showModalOnDelete"
                >
                    {{ $t('Delete') }}
                </IOButton>
                <IOButton
                    variant="secondary"
                    @click="toggleShowNewFundModal"
                >
                    {{ $t('Edit') }}
                </IOButton>
            </template>
        </PageHeader>

        <div class="io-capital-funds__info">
            <CapitalFundInfo :info="fundInfo" :provider="fundProvider"/>
            <FundingSourcesSummary :is-capital-fund="true" :capital-fund-id="fundId" :summary="fundSummary"/>
        </div>

        <div class="io-capital-funds__menu">
            <div class="io-capital-funds__tabs io-section-tabs">
                <template v-for="tab in menuItems" :key="tab.name">
                    <div
                        class="io-single-tab"
                        :class="{'io-is-active': activeTab === tab.number}"
                        @click.prevent="changeActiveTab(tab.number)"
                    >
                        {{ tab.name }}
                    </div>
                </template>
            </div>
        </div>

        <template v-if="activeTab === menuItemsEnum.FUND_PROJECTS">
            <Table
                v-if="!loading && fundProjects && fundProjects.items.length"
                :headers="tableHeaders"
                :items="fundProjects.items"
            >
                <template #tbody="{ sortedItems }">
                    <FundingSourcesProjectRow
                        v-for="project in sortedItems"
                        :key="project.id"
                        :project="project"
                    />
                    <FundingSourcesProjectRow
                        :project="fundProjects.totals"
                        :is-final="true"
                    />
                </template>
            </Table>

            <Placeholder
                v-else-if="!loading && !fundProjects.items.length"
                :title="$t('There are no Projects associated with this Fund')"
                icon="icon-elements"
            />
        </template>

        <FundingSourcesTimeline v-else v-if="fundTimeline && fundTimeline.length" :history="fundTimeline"/>

        <AddNewCapitalFundModal
            v-if="addNewCapitalFundModal.show"
            :provider-id="fundProvider.id"
            :provider-company-id="fundProvider.provider.company_id"
            :fund-id="fundId"
            @fund-edited="updateFundInfo"
            @close="toggleShowNewFundModal"
        />
    </div>
</template>

<script lang="ts" src="./CapitalFundDetails.ts"></script>
<style lang="scss" src="./CapitalFundDetails.scss" scoped></style>
