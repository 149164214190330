import { defineComponent } from 'vue'

import cachedListMixin from '@/mixins/cachedListMixin.js'
import getAppTypeStatusPillVariant from '@/helpers/getAppTypeStatusPillVariant.ts'

import Initials from '@/components/new-theme/initials.vue'

import { AvatarSize, AvatarType } from '@/constants/AvatarConstants.ts'
import CachedEmployee from '@/io-modules/rfp-proposal/interfaces/CachedEmployee.ts'

export default defineComponent({
    name: 'Avatar',
    components: {
        Initials
    },
    props: {
        employeeMongoId: { type: String, required: true },
        employeeFullName: { type: String, default: null },
        showFullName: { type: Boolean, default: false },
        companyName: { type: String, default: null },
        appType: { type: String, default: '' },
        size: { type: String, default: AvatarSize.MD },
        type: { type: String, default: AvatarType.PERSON },
        externalResourceId: { type: String },
        tooltip: { type: String, default: null },
        markWithRing: { type: Boolean, default: false },
        initials: { type: String, default: '' },
        forceRender: { type: Boolean, default: false },
        forceShowEmployeeInitials: { type: Boolean, default: false },
        bgColor: { type: String, required: false },
        checkMark: { type: Boolean, required: false, default: false },
    },
    mixins: [cachedListMixin],
    setup () {
        return { getAppTypeStatusPillVariant, AvatarType }
    },
    data () {
        return {
            rendered: false,
            showEmployeeInitials: false,
            userCachedList: {
                _name: '',
                _color: '',
            } as CachedEmployee
        }
    },
    computed: {
        fullName (): string {
            return this.employeeFullName ?? this.userCachedList?._name
        },
        photoUrl (): string {
            if (!this.employeeMongoId) {
                this.showEmployeeInitials = true
                return ''
            }

            switch (this.type) {
            case AvatarType.PERSON:
                let relativeUrl: string = this.getApiUrlAvatar(this.employeeMongoId)

                // If the file is not found, the initials will be shown.
                const param = 'abort_if_file_not_found=true'

                // If it has query params, add the param with an ampersand, otherwise add it with a question mark.
                relativeUrl += relativeUrl.includes('?') ? '&' : '?'

                return `${ relativeUrl }${ param }`
            case AvatarType.COMPANY:
                return this.getApiUrlCompanyLogo(this.employeeMongoId)
            }
        },
        showTooltip (): boolean {
            return !!this.tooltip?.length
        }
    },
    watch: {
        employeeMongoId (): void {
            this.init()
        }
    },
    async created (): Promise<void> {
        await this.init()
    },
    methods: {
        async init (): Promise<void> {
            this.showEmployeeInitials = false

            if (!this.employeeFullName) {
                this.userCachedList = await this.getOneBy('employees', 'e_mongo', this.employeeMongoId) as CachedEmployee
            }

            this.rendered = true
        },
    }
})
