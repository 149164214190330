import { defineComponent } from 'vue'
import ContentTwoSidesTemplate from '@/components/content-two-sides-template/ContentTwoSidesTemplate.vue'
import StatusPill from '@/components/atoms/status-pill/StatusPill.vue'
import ProgressBar from '@/components/atoms/progress-bar/ProgressBar.vue'
import LabelText from '@/components/labelText/LabelText.vue'
import FundingSourcesTable from '@/io-modules/invoices/components/invoice-view/funding/funding-sources-table/FundingSourcesTable.vue'
import { ProgressBarItemInterface } from '@/components/atoms/progress-bar/ProgressBarItemInterface'
import SelectAppPackage from '@/io-modules/invoices/components/invoice-view/funding/select-app-package/SelectAppPackage.vue'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import { invoiceSoVStore } from '@/io-modules/invoices/stores/invoiceSoV'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex, mapGetters as mapGettersVuex, mapActions as mapActionsVuex } from 'vuex'
import { PurchaseOrder } from '@/interfaces/modules/projects/purchase-orders/PurchaseOrderInterface'
import PurchaseOrdersPopup from '@/modules/projects/modules/apps/common/pay-apps/views/funding/parts/purchase-order/purchase-orders-popup/PurchaseOrdersPopup.vue'
import { useBillingStore } from '@/store/component/project-settings-billing/store'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts'
import featureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin'
import PurchaseOrderField
    from '@/io-modules/invoices/components/invoice-view/funding/purchase-order-field/PurchaseOrderField.vue'
import invoiceEditingClient from '@/io-modules/invoices/api-clients/invoiceEditingClient.ts'

export default defineComponent({
    name: 'InvoiceFunding',
    components: {
        ContentTwoSidesTemplate,
        StatusPill,
        ProgressBar,
        LabelText,
        SelectAppPackage,
        FundingSourcesTable,
        PurchaseOrdersPopup,
        PurchaseOrderField,
    },
    mixins: [featureFlagsMixin],
    data () {
        return {
            applicationPackage: null,
            showPurchaseOrderPopup: false,
            init: false,
        }
    },
    computed: {
        ...mapState(invoiceViewStore, [
            'appliedFundingAmount',
            'isEqualAppliedFundToInvoiceValue',
            'percentsOfInvoiceApplied',
            'fundingSources',
            'isEditingMode',
            'appliedFundingSources',
            'invoice',
            'draftAppPackages',
            'isMyInvoice',
            'isNonContractedInvoice',
            'isVendorInvoice'
        ]),

        ...mapState(invoiceSoVStore, ['netInvoiceValue']),

        ...mapStateVuex('project', {
            projectLocalID: (state: any) => state.projectObj.project_local_id
        }),

        ...mapGettersVuex('payapps', ['hasPurchaseOrderAssociate', 'canBelongToAPurchaseOrder']),

        ...mapGettersVuex('project', ['projectCompanyMongoId']),

        ...mapState(useBillingStore, ['invoicesSettings']),

        progressBarItems (): ProgressBarItemInterface[] {
            return [
                {
                    value: this.percentsOfInvoiceApplied,
                    color: 100 === this.percentsOfInvoiceApplied ? 'var(--success-darker)' : 'var(--info)',
                },
                {
                    value: 100 - this.percentsOfInvoiceApplied,
                    color: 'var(--darker-bg)',
                },
            ]
        },

        purchaseOrderIsOn (): boolean {
            return this.isFeatureEnabled(FeatureFlagsConsts.PURCHASE_ORDERS, false)
                && this.canBelongToAPurchaseOrder
                && this.invoicesSettings?.enabled_purchase_orders
        },

        isPurchaseOrderRequired (): boolean {
            return true
        },
    },
    watch: {
        async isEditingMode (): Promise<void> {
            this.setDataLoadingValue(true)
            await this.fetchApplicableFundingSources(this.projectLocalID, this.invoice.id)
            this.setDataLoadingValue(false)
        }
    },
    async mounted () {
        try {
            this.getInvoicesSettings(this.projectCompanyMongoId)
            if (this.isEditingMode) {
                await this.fetchApplicableFundingSources(this.projectLocalID, this.invoice.id)
            } else if (!this.appliedFundingSources.length) {
                await this.fetchInvoiceFundingSources(this.invoice.id, this.projectLocalID)
            }
        } catch (error) {
            this.errorHandleNoRedirect(error)
        } finally {
            this.setDataLoadingValue(false)
        }
    },
    methods: {
        ...mapActions(invoiceViewStore, [
            'fetchApplicableFundingSources',
            'fetchInvoiceFundingSources',
            'setDataLoadingValue'
        ]),

        ...mapActions(useBillingStore, ['getInvoicesSettings']),

        ...mapActionsVuex('payapps', ['setPurchaseOrder']),

        addFundingSource (): void {
            this.init = true
            const fundingSourcesCount = this.appliedFundingSources.length
            this.appliedFundingSources.push({
                name: '',
                id: '',
                covered_amount: !fundingSourcesCount ? this.netInvoiceValue : this.netInvoiceValue - this.appliedFundingAmount,
                covered_factor: !fundingSourcesCount ? 100 * process.env.SCALE_FACTOR : (100 - this.percentsOfInvoiceApplied) * process.env.SCALE_FACTOR,
                amount: 0,
            })
            setTimeout(()=>{
                this.init = false
            }, 100)
        },

        openPurchaseOrderPopup (): void {
            this.showPurchaseOrderPopup = true
        },

        closePurchaseOrderPopup (): void {
            this.showPurchaseOrderPopup = false
        },

        onSelectedPurchaseOrder (purchaseOrder: PurchaseOrder): void {
            this.setPurchaseOrder(purchaseOrder)
        }
    },
})
