import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import DocumentInterface from '@/io-modules/documents/interfaces/DocumentInterface'
import { AnnotationsResponseInterface } from '@/io-modules/documents/interfaces/AnnotationsResponseInterface'
import { DocumentFolderResponseInterface } from '@/io-modules/documents/interfaces/DocumentFolderResponseInterface'
import DocumentGetInvitationsResponseInterface
    from '@/io-modules/documents/interfaces/DocumentGetInvitationsResponseInterface'
import GetDocumentInvitationPersonsInterface
    from '@/io-modules/documents/interfaces/GetDocumentInvitationPersonsInterface'

const documentsClient = {
    fetchDocument (documentId: string): AxiosPromise<{ data: DocumentInterface }> {
        return client.get(`/document/${ documentId }`)
    },
    renameDocument (documentId: string, name: string): AxiosPromise<void> {
        return client.post(`/document/${ documentId }/rename`, { file_name: name })
    },
    fetchDocumentAnnotations (documentId: string): AxiosPromise<AnnotationsResponseInterface> {
        return client.get(`document/${ documentId }/annotations`)
    },
    fetchDocumentFolder (folderId: string, data: { take: number }): AxiosPromise<DocumentFolderResponseInterface> {
        return client.post(`/documents/get-folder/${ folderId }`, data)
    },
    async getInvitations (documentId: string): AxiosPromise<DocumentGetInvitationsResponseInterface> {
        return client.get(`/documents/${ documentId }/get-invitations`)
    },
    async getInvitationPersons (documentId: string): AxiosPromise<GetDocumentInvitationPersonsInterface> {
        return client.get(`/documents/document-sharing/${ documentId }`)
    },
    async downloadDocuments (documentsIds: string[]): AxiosPromise<BlobPart> {
        if (documentsIds.length > 1) {
            const query = documentsIds.map((value, index) => `files[${ index }]=${ value }`).join('&')
            return client.get(`documents/download?${ query }`, { responseType: 'blob' })
        } else {
            return client.get(`document/${ documentsIds[0] }/download?with_annotations=0`, { responseType: 'blob' })
        }
    },
}

export default documentsClient
