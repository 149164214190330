import InvitationITem from './invitation-item'
import notificationMixin from './mixins/notificationMixin'
import { findIndex } from 'lodash'

export default {
    name: 'invitations',
    mixins: [notificationMixin],
    components: {
        InvitationITem
    },
    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        },
        newInviteEvent: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            duringAction: false,
            showList: false
        }
    },
    methods: {
        toggleAction () {
            this.duringAction = !this.duringAction
        },

        toggleShowList () {
            this.showList = !this.showList
            if (this.showList) {
                this.$emit('clearDot', 'invitation')
            }
        },
        closeListOutside () {
            this.closeList(false)
        },
        closeList (forceToggleAction = false) {
            this.showList = false
            if (forceToggleAction) {
                this.toggleAction()
            }
        },
        onReject (id) {
            const inviteIndex = findIndex(this.items, (item) => item._id === id)
            if (inviteIndex > -1) {
                this.items.splice(inviteIndex, 1)
            }
        }
    }
}
