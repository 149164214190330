export enum InvitationModelTypes {
    TASK = 'task',
    PROJECT_DIRECTORY = 'project_directory',
    EDIT_CHANGE_ORDER = 'edit-change-order',
    REQUEST_PACKAGE_ITEM = 'request-package-item',
    DOCUMENT = 'document',
    DRAWING_SET = 'drawing_set',
    BID_REQUEST_MANAGER = 'bid_request_manager',
    BID_REQUEST_BIDDER = 'bidrequest',
    PORTFOLIO = 'portfolio',
    PORTFOLIO_EDITORS = 'portfolios_editors',
    PORTFOLIO_VIEWERS = 'portfolios_viewers',
    MEETING_MINUTES = 'meeting_minutes',
    CONTRACT_FEASIBILITY = 'contract-feasibility',
    CONTRACT_RECEIVER = 'contract-receiver',
    CONTRACT_MANAGER = 'contract-manager',
    RFP = 'rfp',
    MEETINGS_V2 = 'meetings_v2',
    MEETINGS_V2_CREATOR = 'meetings_v2_creator',
    MEETINGS_V2_AUTHORS = 'meetings_v2_authors',
    MEETINGS_V2_MEMBERS = 'meetings_v2_members',
    PROJECT_EXECUTIVE = 'project-executive',
    PROJECT_MANAGER = 'project-manager',
    PROJECT_SCHEDULE_V2_TICKET = 'project_schedule_v2_ticket',
    PROJECT_SCHEDULE_V2 = 'project_schedule_v2',
    SITES_EDITORS = 'sites_editors',
    SITES_VIEWERS = 'sites_viewers',
    SCHEDULE_RESPONSIBLE_PARTY = 'schedule_responsible_party',
    INSPECTIONS_MEMBERS = 'inspections_members',
    INSPECTIONS_INSPECTOR = 'inspections_inspector',
}
