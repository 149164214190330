import { mapState } from 'vuex'
import modal from '@/components/new-theme/modal.vue'
import { defineComponent } from 'vue'

const modalMixin = defineComponent({
    components: {
        modal
    },
    computed: {
        ...mapState('modal', {
            refreshData: state => state.refresh,
            showModal: state => state.show,
            additionalData: state => state.additionalData,
            modalKey: state => state.key,
            returnData: state => state.returnData
        })
    },
    methods: {
        openModal (modal: unknown): void {
            window.parent.$('.intercom-app').hide()
            this.$store.dispatch('modal/setShow', modal, { root: true })
            this.$store.dispatch('lazyList/setShowFilters', true)
            this.$store.commit('drawingsV2/SET_SHOW_FILTERS', true)
            this.$store.commit('submittalV2/SET_SHOW_FILTERS', true)
            this.$store.commit('forecasting/SET_CASH_FLOW_FILTERS_VISIBLE', true)
        },
        toggleModal (modal: unknown): void {
            if (this.showModal === modal) {
                this.$store.dispatch('modal/setShow', null, { root: true })
                window.parent.$('.intercom-app').show()
            } else {
                this.$store.dispatch('modal/setShow', modal, { root: true })
                window.parent.$('.intercom-app').hide()
            }
            $('body').toggleClass('io-no-scroll')
        },
        closeModal (): void {
            this.$store.dispatch('modal/setShow', false, { root: true })
            this.$store.dispatch('lazyList/setShowFilters', false)
            this.$store.commit('drawingsV2/SET_SHOW_FILTERS', false)
            this.$store.commit('drawingMarkup/SET_DRAWING_PIN', {})
            this.$store.commit('drawingMarkup/SET_SELECTED_ANNOTATION', {})
            this.$store.commit('submittalV2/SET_SHOW_FILTERS', false)
            this.$store.commit('forecasting/SET_CASH_FLOW_FILTERS_VISIBLE', false)
            window.parent.$('.intercom-app').show()
        }
    }
})

export default modalMixin
