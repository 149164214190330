import {  defineComponent } from 'vue'

export default defineComponent({
    name: 'SingleCompanyType',
    components: {
    },
    props: {
        label: { type: String },
        description: { type: String },
        iconClass: { type: String },
        iconSvg: { type: String },
        hideDescription: { type: Boolean, default: false }
    },
    data () {
        return {
            currentValue: null,
        }
    },
    computed: {
    },
})
