import * as actions from './actions'
import * as mutations from './mutations'
import state from './state'
import { cloneDeep } from 'lodash'

export default {
    namespaced: true,
    namespace: 'communicationV2/',
    state () {
        return cloneDeep(state)
    },
    actions,
    mutations
}
