import { delay } from 'lodash'

export const addNotice = ({ commit }, notice) => {
    commit('ADD_NOTICE', notice)
    if (notice.timer) {
        delay(() => {
            commit('REMOVE_NOTICE', notice.id)
        }, notice.timer)
    }
}

export const removeNotice = ({ commit }, noticeId) => {
    commit('REMOVE_NOTICE', noticeId)
}

export const addAlert = ({ commit }, alert) => {
    commit('ADD_ALERT', alert)
}

export const removeAlert = ({ commit }, alertId) => {
    commit('REMOVE_ALERT', alertId)
}
