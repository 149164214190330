import { defineComponent } from 'vue'
import { LabelSize, LabelType } from '@/constants/LabelConstants'

export default defineComponent({
    name: 'IOLabel',
    props: {
        isRequired: Boolean,
        text: { type: String, default: '' },
        size: { type: String as () => LabelSize, default: LabelSize.Small },
        type: { type: String as () => LabelType, default: LabelType.Bold },
    },
})
