<template>
    <div class="io-project-breadcrumbs">
        <div
            class="io-project-breadcrumbs-root"
            @click="onProjectRootClick"
        >
            {{ $t('Projects') }}

            <i class="icon-chevron-right"></i>
        </div>

        <div v-tooltip.right="{ content: currentProjectName }" class="io-project-breadcrumbs-project-name">
            {{ currentProjectName }}
        </div>
    </div>
</template>

<script src="./ProjectBreadcrumbs.ts"></script>
<style lang="scss" src="./ProjectBreadcrumbs.scss" scoped></style>
