import defaultState from './state'
import { cloneDeep } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export function SET_COST_CODES_CATEGORIES (state, value) {
    state.costCodes.categories = value
}
