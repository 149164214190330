import {  defineComponent } from 'vue'

export default defineComponent({
    name: 'ContactRadio',
    components: {
    },
    props: {
        labelText: { type: String },
        description: { type: String },
        inputName: { type: String, required: true },
        modelValue: { required: false },
        radioValue: { required: true },
        disabled: { type: Boolean, default: false },
        iconClass: { type: String },
    },
    emits: ['input', 'update:modelValue'],
    data () {
        return {
            currentValue: null,
        }
    },
    computed: {
        isSelected(): boolean {
            return this.modelValue === this.radioValue
        }
    },
    watch: {
        currentValue (): void {
            this.$emit('update:modelValue', this.currentValue)
        },
        modelValue (): void {
            this.currentValue = this.modelValue
        },
    },
    mounted () {
        this.currentValue = this.modelValue
    },

    methods: {
        onClick (): void {
            this.currentValue = this.radioValue;
        }
    }
})
