<template>
    <ul class="io-design-system toggle-switch" :class="[className, `toggle-switch--${this.variant}`]">
        <template v-for="(label, index) in defaultOptions.items.labels" :key="index">
            <li class="toggle-switch__li">
                <input
                    :disabled="defaultOptions.items.disabled || disabled"
                    :id="label.name + group"
                    :value="label.name"
                    :name="name"
                    type="radio"
                    class="toggle-switch__input"
                    @click.stop.prevent="label.name !== selectedItemCopy ? checkMode($event, label.mode) : null"
                >

                <label
                    v-if="label.name === selectedItemCopy"
                    :class="[{'js-toggle-switch__label--is-active': !defaultOptions.items.disabled || disabled}, `toggle-switch__label--${defaultOptions.items.style}`]"
                    class="toggle-switch__label toggle-switch__label--selected"
                    :for="label.name + group"
                    type="radio"
                >
                    <i v-if="label.icon" :class="[label.icon, iconClass(label)]"></i>
                    <span v-html="label.text"></span>
                </label>
                <label
                    v-else
                    :class="[{'js-toggle-switch__label--is-active': !defaultOptions.items.disabled || disabled}, `toggle-switch__label--${defaultOptions.items.style}`]"
                    class="toggle-switch__label"
                    :for="label.name + group"
                    type="radio"
                >
                    <i v-if="label.icon" :class="[label.icon, iconClass(label)]"></i>
                    <span v-html="label.text"></span>
                </label>
            </li>
        </template>
    </ul>
</template>

<script lang="ts" src="./ToggleSwitch.ts"></script>
<style lang="scss" src="./ToggleSwitch.scss"></style>
