import defaultState from './state'
import { cloneDeep } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState))
}

export function SET_REFRESH_TIME (state, value) {
    state.refreshTime = value
}

export function SET_COUNTRIES (state, value) {
    state.countries = value
}

export function SET_APP_TYPES (state, value) {
    state.app_types = value
}

export function SET_APP_TYPES_MAP (state, value) {
    state.app_types_map = value
}

export function SET_STATES (state, value) {
    state.states = value
}

export function SET_AREA_TYPES (state, value) {
    state.area_types = value
}

export function SET_COUNTRIES_PHONES_CODES (state, value) {
    state.countries_phones_codes = value
}

export function SET_DEPARTMENTS (state, value) {
    state.departments = value
}

export function SET_UNITS_SYSTEMS (state, value) {
    state.units_systems = value
}

export function SET_REIMBURSABLE_TYPES (state, value) {
    state.reimbursable_types = value
}

export function SET_UNITS_MATERIALS (state, value) {
    state.units_materials = value
}

export function SET_UNITS_BUDGET_ITEM (state, value) {
    state.units_budget_item = value
}

export function SET_SECTORS (state, value) {
    state.sectors = value
}

export function SET_DIVISIONS (state, value) {
    state.divisions = value
}

export function SET_DIVISIONS_CUSTOM (state, value) {
    state.divisions_custom = value
}

export function ADD_DIVISION_CUSTOM (state, value) {
    if (typeof value === 'object' && value.id && value.name) {
        state.divisions_custom.push(value)
    }
}

export function SET_TYPES_OF_SERVICE (state, value) {
    state.types_of_service = value
}

export function SET_CONTRACT_TYPES (state, value) {
    state.contract_types = value
}

export function SET_CONTRACT_HOLDERS (state, value) {
    state.contract_holders = value
}

export function SET_DELIVER_METHODS (state, value) {
    state.deliver_methods = value
}

export function SET_BID_QUESTIONS_TYPES (state, value) {
    state['bid_questions_types'] = value
}

export function SET_CURRENCIES (state, value) {
    state['currencies'] = value
}

export function SET_CURRENCIES_LIST (state, value) {
    state['currencies_list'] = value
}

export function SET_CUSTOM_FIELD_TYPES (state, value) {
    state['custom_field_types'] = value
}

export function SET_CUSTOM_FIELD_LISTS_TYPES (state, value) {
    state['custom_field_lists_types'] = value
}

export function SET_AGREEMENT_TYPES (state, value) {
    state['agreement_types'] = value
}
