export enum AvatarType {
    PERSON = 'person',
    COMPANY = 'company',
    EXTERNAL_COMPANY = 'externalCompany'
}

export enum AvatarSize {
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}
