import axios from 'axios'
import echo from '../echo'
import { ApiClientCore } from './core'
import { getAuthUrl } from '@/helpers/urls'
import dayjs from 'dayjs'
import { ingeniousTelemetry } from '@/telemetry/provider'

function getBaseAPI2URL (apiVersion = 1) {
    if (process.env.STORYBOOK) {
        return process.env.API_URL
    }

    const appName = (window.location.host).split('.')[0]
    const appType = localStorage.getItem('appType')
    const apiUrl = localStorage.getItem('apiUrl')

    let baseUrl =  (apiUrl ? apiUrl : '')  + '/api/v1/'
    baseUrl = baseUrl.replace('/v1/', `/v${ apiVersion }/`)

    return baseUrl + `${ appName }/${ appType }`
}

function createApiClient (apiVersion = 1, withBasicParams = true) {
    const client = axios.create({
        withCredentials: true,
        baseURL: withBasicParams ? getBaseAPI2URL(apiVersion) : null,
        validateStatus: status => status >= 200 && status < 300,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'X-Requested-With': 'XMLHttpRequest'
        }
    })

    ingeniousTelemetry.instrumentAxios(client)

    client.interceptors.request.use(function (config) {
        const socketId = echo.socketId()
        if (socketId) {
            config.headers['X-Socket-ID'] = socketId
        }
        return config
    })

    // Logout user on 401 error (invalid session)
    client.interceptors.response.use(res => {
        if (res.status === 200) {
            localStorage.setItem('lastRefreshTime', dayjs().unix())
        }
        return res
    }, error => {
        if (error.response) {
            if (error.response.status === 401) {
                localStorage.clear()
                const subdomain = (window.location.host).split('.')[0]
                window.location.href = `${ getAuthUrl() }/logout/${ subdomain }`
            } else {
                return Promise.reject(error)
            }
        }

    })

    return client
}

class ApiClient extends ApiClientCore {
    constructor (apiVersion = 1, withBasicParams = true) {
        super(apiVersion, withBasicParams)

        if (!this.instance) {
            this.apiVersion = apiVersion
            this.client = createApiClient(this.apiVersion, withBasicParams)
            this.instance = this
        }
        return this.instance
    }
    getAuthData (type, data, conf) {
        return this.client
            .get('session-status', this.prepareConfig(conf))
            .then((response) => Promise.resolve(response.data))
            .catch((error) => Promise.reject(error))
    }
    formData (url, data = {}, conf = {}) {
        const requestConfig = this.prepareConfig(conf)
        requestConfig.headers['Content-Type'] = 'multipart/form-data'
        return this.client.post(url, data, requestConfig)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    }

    /**
     * POST method request with JSON data
     * @param {string} url
     * @param {any} data
     * @param {AxiosRequestConfig} conf
     * @returns {Promise<axios.AxiosResponse<*>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     */
    json (url, data = {}, conf = {}) {
        return this.post(url, data, conf)
    }

    /**
     * POST method request with JSON data
     * @param {string} url
     * @param {any} data
     * @param {AxiosRequestConfig} conf
     * @returns {Promise<axios.AxiosResponse<*>>}
     * @throws {AxiosError<ApiErrorResponse>} when errors occur from backend
     * @deprecated
     */
    realJson (url, data = {}, conf = {}) {
        return this.post(url, data, conf)
    }
    getApi2RequestUrl (url) {
        return getBaseAPI2URL(this.apiVersion) + url
    }
    getApi2RequestUrlWithParams (url, options = {}) {
        const baseUrl = this.getApi2RequestUrl(url)
        const params = options.params || {}
        const queryString = this.buildQueryString(params)

        return baseUrl + queryString
    }
    buildQueryString (params) {
        if (Object.keys(params).length === 0) {
            return ''
        }

        let searchParams = new URLSearchParams()
        for (let key in params) {
            if (Array.isArray(params[key])) {
                params[key].forEach(value => searchParams.append(`${ key }[]`, value))
            } else {
                searchParams.set(key, params[key])
            }
        }

        return searchParams.toString() ? `?${ searchParams }` : ''
    }
}

const ApiClientInstance = new ApiClient()
Object.freeze(ApiClientInstance)
export { ApiClientInstance as ApiClient }

const ApiV2ClientInstance = new ApiClient(2)
Object.freeze(ApiV2ClientInstance)
export { ApiV2ClientInstance as ApiClientV2 }

const ApiV3ClientInstance = new ApiClient( 3)
Object.freeze(ApiV2ClientInstance)
export { ApiV3ClientInstance as ApiClientV3 }

const ApiBaseClientInstance = new ApiClient(1, false)
Object.freeze(ApiBaseClientInstance)
export { ApiBaseClientInstance as ApiClientBase }
