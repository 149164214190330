<template>
    <div>
        <PageHeader no-bottom-margin :title="$t('Open the set where you want to add Reference pin.')">
            <template #actions>
                <SearchInput/>

                <FiltersButton class="io-project-drawings__filter-button"/>

                <IOButton variant="secondary" @click="cancelDrawingSets">{{ $t('Cancel') }}</IOButton>
            </template>
        </PageHeader>

        <LazyList
            key="drawing-sets-lazy-list"
            :endpoint="endpoint"
            :itemRoute="itemRoute"
            :columns="columns"
            :filtersSchema="filtersSchema"
            :noReroute="true"
            :pillsColorFunction="pillsColorFunction"
            :dotsColorFunction="dotsColorFunction"
            @changeFavorite="changeFavorite"
            @tableRowClick="drawingSetSelected"
        />
    </div>
</template>

<script lang="ts" src="./DrawingSets.ts"></script>
<style lang="scss" src="./DrawingSets.scss" scoped></style>
