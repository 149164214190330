import { defineComponent } from 'vue'
import { ProgressBarItemInterface } from './ProgressBarItemInterface'

enum ProgressBarVariant {
    SMALL = 'small',
    NORMAL = 'normal'
}

export default defineComponent({
    name: 'ProgressBar',
    props: {
        items: { type: Array as () => ProgressBarItemInterface[], required: true, default: () => [] },
        showLegend: { type: Boolean, default: true },
        useItemValueAsRemains: { type: Boolean, default: false },
        variant: { type: String as () => ProgressBarVariant, default: ProgressBarVariant.NORMAL }
    },
    computed: {
        variantClassName(): string {
            return `io-variant-${this.variant}`
        },
        remainsPercent(): number {
            const itemValue = this.items[this.items.length - 1].value
            return !this.useItemValueAsRemains ? 100 - itemValue : itemValue
        },
        itemsWithoutLegend(): number {
            return this.items.filter((item: any) => {
                return item.show_legend === false
            }).length
        },
        remainsPercentWith(): number {
            if (this.items.length === 1 || this.itemsWithoutLegend === this.items.length) {
                return 100
            }

            const defaultMinValue = 5
            return this.remainsPercent > defaultMinValue
                ? this.remainsPercent
                : defaultMinValue
        }
    },
    methods: {
        legendStyles(index: number, item: ProgressBarItemInterface): object {
            return {
                width: `${index > 0 ? this.items[index - 1].value : item.value}%`,
                color: item.color,
                left: `${index > 0 ? this.items[index - 1].value : 0}%`
            }
        },

        labelStyles(index: number, item: ProgressBarItemInterface): object {
            return {
                width: item.value + '%',
                backgroundColor: item.color,
                zIndex: 10 - index
            }
        }
    }
})
