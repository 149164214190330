<template>
    <tr :key="item.id" @click="openRowDetails">
        <td v-if="isEditingMode" class="io-drag-action"><span class="io-icon-content icon-drag"></span></td>
        <td>
            <IOInput v-if="isEditingMode" v-model="item.description" />
            <span v-else>{{ item.description }}</span>
        </td>
        <td>
            <BudgetCostCodeSelect
                v-if="isEditingMode && providedCostCodes"
                :key="item.id"
                v-progress="dataLoading"
                class="io-budget-cost-code-select"
                :load-cost-code-categories="false"
                :provided-cost-code-categories="providedCostCodes"
                :model-value="item.cost_code_id"
                @update:modelValue="updateCostCode"
            />
            <span v-else>{{ costCodeName }}</span>
        </td>
        <td class="align-end">
            <field-numeric-int
                v-if="isEditingMode"
                placeholder="0"
                :modelValue="item.quantity"
                :min="0"
                :options="{ numeralPositiveOnly: false, numericOnly: false }"
                :showRounded="true"
                :disableAddon="true"
                @update:modelValue="updateQuantity"
                @click.stop
            />
            <span v-else>{{ lineQuantity }}</span>
        </td>
        <td class="align-end io-item-cost">
            <field-numeric-int
                v-if="isEditingMode"
                :modelValue="item.unit_price"
                :options="{ numeralPositiveOnly: false, numericOnly: false }"
                :showRounded="true"
                sign="$"
                class="io-input-cost"
                @update:modelValue="updateCostValue"
                @click.stop
            />
            <span v-else>{{ $filters.formatProjectCurrencyInt(item.unit_price) }}</span>
        </td>
        <td class="io-total-value">
            <span>{{ $filters.formatProjectCurrencyInt(item.total) }}</span>
        </td>
        <td v-if="isEditingMode">
            <span class="io-icon-content icon-trash" @click="$emit('removeItem')"></span>
        </td>
    </tr>
</template>

<script lang="ts" src="./LineItemRow.ts"></script>
