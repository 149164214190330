import apiClient from '@/api/base/baseClient'

export default {
    methods: {
        /**
         *
         * @param type
         * @param url
         * @param params
         * @returns {*}
         */
        migrateToApi2 (type, url, params) {
            // post
            if (type === 'post') {
                return apiClient.post(url, params)
            } else if (type === 'realJson') {
                return apiClient.realJson(url, params)
            } else if (type === 'get') {
                return apiClient.get(url, {
                    params: params
                })
            } else if (type === 'delete') {
                return apiClient.delete(url, {
                    params: params
                })
            } else if (type === 'put') {
                return apiClient.put(url, {
                    params: params
                })
            }
        },
        /**
         *
         * @param response
         * @returns {*}
         */
        setResponseData (response) {
            if (response) {
                return response.data
            }
            return response
        }
    }
}
