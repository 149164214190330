import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ActionBar',
    props: {
        itemsQuantity: {
            type: Number,
            default: 0
        }
    }
})
