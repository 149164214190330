import moment from 'moment'
import dayjs from 'dayjs'
import CashFlow from '@/interfaces/modules/projects/modules/forecasting/CashFlow'
import ForecastingStore from '@/interfaces/modules/projects/modules/forecasting/ForecastingStore'
import CompanyLogoSelectOption from '@/interfaces/modules/projects/modules/common/CompanyLogoSelectOption'
import CostCodeCategoryConverted from '@/interfaces/modules/projects/modules/common/budget/CostCodeCategoryConverted'
import CurveType from '@/interfaces/modules/projects/modules/forecasting/CurveType'
import CashFlowCostCode from '@/interfaces/modules/projects/modules/forecasting/CashFlowCostCode'
import CashFlowMonth from '@/interfaces/modules/projects/modules/forecasting/CashFlowMonth'
import CashFlowStatus from '@/interfaces/modules/projects/modules/forecasting/CashFlowStatus'
import CashFlowViewTab from '@/interfaces/modules/projects/modules/forecasting/CashFlowViewTab'
import ShowCostCodesEnum from '@/interfaces/modules/projects/modules/forecasting/ShowCostCodes'
import ActualForecastEnum from '@/interfaces/modules/projects/modules/forecasting/ActualForecastEnum'
import CostTypeEnum from '@/interfaces/modules/projects/modules/forecasting/CostTypeEnum'
import CashFlowOption from '@/interfaces/modules/projects/modules/forecasting/CashFlowOption.ts'
import CashFlowCategory from '@/interfaces/modules/projects/modules/forecasting/CashFlowCategory.ts'
import CashFlowTask from '@/interfaces/modules/projects/modules/forecasting/CashFlowTask.ts'
import { ProjectSite } from '@/components/budget-site-select/domain/ProjectSite.ts'
import minMax from 'dayjs/plugin/minMax'
import { getDefaultState } from './state.ts'

dayjs.extend(minMax)

export default {
    SET_CASH_FLOW (state: ForecastingStore, cashFlow: CashFlow): void {
        state.cashFlow = cashFlow
        state.loading = false
    },

    SET_CASH_FLOWS_OPTIONS (state: ForecastingStore, options: CashFlowOption[]): void {
        state.cashFlowOptions = options
    },

    CLEAR_CASH_FLOW (state: ForecastingStore): void {
        state.cashFlow = null
    },

    SET_CASH_FLOW_STATUS (state: ForecastingStore, status: CashFlowStatus): void {
        state.cashFlow.status = status
    },

    SET_CASH_FLOW_RANGE (state: ForecastingStore, { start, end }: { start: dayjs.Dayjs, end: dayjs.Dayjs }): void {
        state.cashFlow.startDate = start
        state.cashFlow.endDate = end
        state.cashFlow.startDateFormatted = start.format('YYYY-MM-DD')
        state.cashFlow.endDateFormatted = end.format('YYYY-MM-DD')
    },

    DELETE_CASH_FLOW (state: ForecastingStore): void {
        state.cashFlow = null
    },

    SET_CURRENT_CASH_FLOW_OPTION (state: ForecastingStore, option: CompanyLogoSelectOption): void {
        state.currentCashFlowOption = option
    },

    UPDATE_CURRENT_CASH_FLOW_OPTION_ID (state: ForecastingStore, id: string): void {
        state.currentCashFlowOption.id = id
    },

    SET_CASH_FLOW_PERIODS (state: ForecastingStore, periods: moment.Moment[]): void {
        state.cashFlowPeriods = periods
    },

    TOGGLE_COLLAPSED (state: ForecastingStore): void {
        state.collapsed = !state.collapsed
    },

    SET_CASH_FLOW_SHARED (state: ForecastingStore): void {
        state.cashFlow.isShared = true
    },

    SET_ALL_CHECKBOXES (state: ForecastingStore, checked: boolean): void {
        state.allCheckboxes = checked
    },

    SET_CATEGORY_CHECKBOXES (state: ForecastingStore, categoryCheckboxes: string[]): void {
        state.categoryCheckboxes = categoryCheckboxes
    },

    SET_ITEMS_CHECKBOXES (state: ForecastingStore, itemsCheckboxes: string[]): void {
        state.itemsCheckboxes = itemsCheckboxes
    },

    SET_ITEMS_FILTERED (state: ForecastingStore, itemsFiltered: CostCodeCategoryConverted[]): void {
        state.itemsFiltered = itemsFiltered
    },

    SET_VISIBLE_COST_CODE_IDS (state: ForecastingStore, visibleCostCodeIds: string[]): void {
        state.visibleCostCodeIds = visibleCostCodeIds
    },

    SET_HIDE_ZERO_VALUES (state: ForecastingStore, hide: boolean): void {
        state.hideZeroValues = hide
    },

    SET_SHOW_COST_CODE_COMMITMENT_MODE (state: ForecastingStore, mode: ShowCostCodesEnum): void {
        state.showCostCodeCommitmentMode = mode
    },

    SET_CURVE_COLUMN_MENU_OPEN (state: ForecastingStore, open: boolean): void {
        state.curveColumnMenuOpen = open
    },

    SET_RANGE_COLUMN_MENU_OPEN (state: ForecastingStore, open: boolean): void {
        state.rangeColumnMenuOpen = open
    },

    SET_COST_CODE_FORECASTED_BALANCES (state: ForecastingStore, costCodeForecastedBalances: { [key: string]: number }): void {
        state.costCodeForecastedBalances = costCodeForecastedBalances
    },

    SET_EDITING_CASH_FLOW (state: ForecastingStore, active = true): void {
        state.editing = active
    },

    SET_LOADING_CASH_FLOW (state: ForecastingStore, loading = true): void {
        state.loading = loading
    },

    SET_CASH_FLOW_VIEW_TAB (state: ForecastingStore, value: CashFlowViewTab): void {
        state.currentViewTab = value
    },

    SET_CASH_FLOW_FILTERS_VISIBLE (state: ForecastingStore, visible: boolean): void {
        state.filtersVisible = visible
    },

    SET_CASH_FLOW_SUMMARY_FILTERS_VISIBLE (state: ForecastingStore, visible: boolean): void {
        state.summaryFiltersVisible = visible
    },

    SET_CASH_FLOW_SUMMARY_ACTUAL_FORECAST (state: ForecastingStore, actualForecast: ActualForecastEnum[]): void {
        state.summaryActualForecast = actualForecast
    },

    SET_CASH_FLOW_SUMMARY_COST_TYPES (state: ForecastingStore, costTypes: CostTypeEnum[]): void {
        state.summaryCostTypes = costTypes
    },

    SET_CASH_FLOW_SUMMARY_VISIBLE_COST_CODE_IDS (state: ForecastingStore, ids: string[]): void {
        state.summaryVisibleCostCodeIds = ids
    },

    SET_CASH_FLOW_SUMMARY_RANGE_FILTER (
        state: ForecastingStore,
        range: { start: moment.Moment | null, end: moment.Moment | null }
    ): void {
        state.summaryRange = range
    },

    SET_COST_CODE_VIEW_ID (state: ForecastingStore, id: string | null): void {
        state.costCodeViewId = id

        if (null === id) {
            state.editing = false
        }
    },

    SET_ITEM_CURVE_TYPE (state: ForecastingStore, { categoryId, costCodeId, taskId, type }: { categoryId: string, costCodeId: string, taskId: string, type: CurveType }): void {
        state.cashFlow.phases.forEach(phase => {
            phase.categories.forEach((category: CashFlowCategory) => {
                if (categoryId === category.id) {
                    category.costCodes.forEach((costCode: CashFlowCostCode) => {
                        if (costCodeId === costCode.id) {
                            costCode.tasks.forEach((task: CashFlowTask) => {
                                if (taskId === task.id) {
                                    task.curveType = type
                                    return
                                }
                            })
                        }
                    })
                }
            })
        })
    },

    CLEAR_ITEM_PERIODS_VALUES (state: ForecastingStore, { categoryId, costCodeId, taskId }: { categoryId: string, costCodeId: string, taskId: string }): void {
        state.cashFlow.phases.forEach(phase => {
            phase.categories.forEach((category: CashFlowCategory) => {
                if (categoryId === category.id) {
                    category.costCodes.forEach((costCode: CashFlowCostCode) => {
                        if (costCodeId === costCode.id) {
                            costCode.tasks.forEach((task: CashFlowTask) => {
                                if (task.id === taskId) {
                                    task.periods = task.periods.map((month: CashFlowMonth) => {
                                        month.actualValue = 0
                                        month.value = 0

                                        return month
                                    })
                                }
                            })
                        }
                    })
                }
            })
        })
    },

    SET_ITEM_START_DATE (state: ForecastingStore, { categoryId, costCodeId, taskId, date }: { categoryId: string, costCodeId: string, taskId: string, date: dayjs.Dayjs }): void {
        const costCodeDates = []

        state.cashFlow.phases.forEach(phase => {
            phase.categories.forEach((category: CashFlowCategory) => {
                if (categoryId === category.id) {
                    category.costCodes.forEach((costCode: CashFlowCostCode) => {
                        if (costCodeId === costCode.id) {
                            costCode.tasks.forEach((task: CashFlowTask) => {
                                if (task.id === taskId) {
                                    task.startDate = date
                                    return
                                }
                            })

                            costCode.startDate = dayjs.min(costCode.tasks.map((task): dayjs.Dayjs => task.startDate))

                            costCodeDates.push(costCode.startDate)
                        }
                    })
                }
            })
        })

        const cashFlowStartDate = dayjs.min(costCodeDates)

        state.cashFlow.startDate = cashFlowStartDate
        state.cashFlow.startDateFormatted = cashFlowStartDate.format('YYYY-MM-DD')
    },

    SET_ITEM_END_DATE (state: ForecastingStore, { categoryId, costCodeId, taskId, date }: { categoryId: string, costCodeId: string, taskId: string, date: dayjs.Dayjs }): void {
        const costCodeDates = []

        state.cashFlow.phases.forEach(phase => {
            phase.categories.forEach((category: CashFlowCategory) => {
                if (categoryId === category.id) {
                    category.costCodes.forEach((costCode: CashFlowCostCode) => {
                        if (costCodeId === costCode.id) {
                            costCode.tasks.forEach((task: CashFlowTask) => {
                                if (task.id === taskId) {
                                    task.endDate = date
                                    return
                                }
                            })

                            costCode.endDate = dayjs.max(costCode.tasks.map((task): dayjs.Dayjs => task.endDate))

                            costCodeDates.push(costCode.endDate)
                        }
                    })
                }
            })
        })

        const cashFlowEndDate = dayjs.max(costCodeDates)

        state.cashFlow.endDate = cashFlowEndDate
        state.cashFlow.endDateFormatted = cashFlowEndDate.format('YYYY-MM-DD')
    },

    SET_TASK_PERIODS (state: ForecastingStore, { categoryId, costCodeId, taskId, periods }: { categoryId: string, costCodeId: string, taskId: string, periods: CashFlowMonth[] }): void {
        state.cashFlow.phases.forEach(phase => {
            phase.categories.forEach((category: CashFlowCategory) => {
                if (category.id === categoryId) {
                    category.costCodes.forEach((costCode: CashFlowCostCode) => {
                        if (costCode.id === costCodeId) {
                            costCode.tasks.forEach((item: CashFlowTask) => {
                                if (item.id === taskId) {
                                    item.periods = periods
                                    return
                                }
                            })
                        }
                    })
                }
            })
        })
    },

    SET_ITEM_PERIOD (state: ForecastingStore, { categoryId, costCodeId, taskId, month, value }: { categoryId: string, costCodeId: string, taskId: string, month: CashFlowMonth, value: number }): void {
        state.cashFlow.phases.forEach(phase => {
            phase.categories.forEach((category: CashFlowCategory) => {
                if (category.id === categoryId) {
                    category.costCodes.forEach((costCode: CashFlowCostCode) => {
                        if (costCode.id === costCodeId) {
                            costCode.tasks.forEach((task: CashFlowTask) => {
                                if (task.id === taskId) {
                                    task.periods.forEach((item: CashFlowMonth) => {
                                        if (item.id === month.id) {
                                            item.value = value
                                            return
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
        })
    },

    SET_VIEW_MODE_ONLY (state: ForecastingStore, active: boolean = true): void {
        state.viewModeOnly = active
    },

    SET_SITES (state: ForecastingStore, sites: ProjectSite[]): void {
        state.sites = sites
    },

    SET_ACTIVE_SITE_ID (state: ForecastingStore, id: string | null): void {
        state.activeSiteId = id
    },

    RESET_STATE (state: ForecastingStore): void {
        Object.assign(state, getDefaultState())
    }
}
