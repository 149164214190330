import modalMixin from '@/mixins/modalMixin'

export default {
    name: 'RightModal',
    data () {
        return {
            escPressed: false,
        }
    },
    emits: ['close', 'clickOutside', 'escDown', 'escUp'],
    mixins: [modalMixin],
    props: {
        customClass: {
            type: [Object, String],
            required: false,
            default: () => ({}),
        },
        cropToContent: {
            type: Boolean,
            required: false,
            default: false,
        },
        cropToContentNoGap: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableMaskClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableKeyClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        disableAutoClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        width: {
            type: String,
            required: false,
        },
        showDivider: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        getContentStyle () {
            if (this.cropToContent) {
                return {
                    top: '64px',
                    height: 'calc(100% - 64px)',
                    width: 'calc(100% - var(--left-menu-width))',
                }
            } else if (this.cropToContentNoGap) {
                return {
                    top: '0',
                    height: '100%',
                    width: 'calc(100% - var(--left-menu-width))',
                }
            } else if (this.width) {
                return {
                    width: this.width,
                }
            }
            return null
        },
    },
    methods: {
        handleKeyDown (e) {
            if (e.keyCode === 27) {
                this.escPressed = true
                this.$emit('escDown', e)
            }
        },
        handleKeyUp (e) {
            if (e.keyCode === 27) {
                if (!this.disableAutoClose) {
                    this.closeModal()
                }
                this.$emit('escUp', e)
            }
        },
        handleMaskClick (e) {
            if (!this.disableMaskClose) {
                this.$emit('clickOutside', e)
                if (!this.disableAutoClose) {
                    this.close(e)
                }
            }
        },
        close (e) {
            this.$emit('close', e)
            this.closeModal()
        },
    },
    beforeMount () {
        if (!this.disableKeyClose) {
            document.addEventListener('keydown', this.handleKeyDown)
            document.addEventListener('keyup', this.handleKeyUp)
        }
    },
    beforeUnmount () {
        if (!this.disableKeyClose) {
            document.removeEventListener('keydown', this.handleKeyDown)
            document.removeEventListener('keyup', this.handleKeyUp)
        }
    },
}
