import * as actions from './actions'
import * as mutations from './mutations'
import * as getters from './getters'

export default {
    namespaced: true,
    namespace: 'notificationToastr/',
    state () {
        return {
            alerts: [],
            isDuringAction: false
        }
    },
    actions,
    mutations,
    getters
}
