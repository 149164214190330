<template>
    <div id="io-project-exclusions" class="io-project-exclusions">
        <h3>{{ $t('Exclusions from Project Scope') }}</h3>
        <template v-if="projectExclusionsCopy">
            <FieldWysiwyg
                :settings="{ readonly: 1, hideToolbar: true }"
                v-model="projectExclusionsCopy"
            />
        </template>
        <template v-else>
            <EmptyDescription
                :description="$t('You haven\'t set the Exclusions from Project Scope yet. You can add it in the Project Settings.')"
            />
        </template>
    </div>
</template>
<script lang="ts" src="./ProjectExclusions.ts"></script>
<style lang="scss" src="./ProjectExclusions.scss" scoped></style>
