<template>
    <div
        v-if="!showContentLoader"
        class="io-invoices-list"
        :class="{ 'io-invoices-list--empty': placeholderVisible, 'io-vendors-list': vendorsList }"
    >
        <Placeholder
            v-if="showMainPlaceholder && !loading"
            icon="icon-invoice"
            :title="$t('Looking for Received Invoices from Vendors?')"
            :subtitle="$t('When vendors send you invoices, they\'ll appear here for your review and approval or you can create invoices on behalf of vendors.')"
            :buttons-cta="ctaButtons"
        />
        <div v-else>
            <InvoicesListHeader
                :title="$t('Vendor Invoices')"
                @openManageColumns="$emit('openManageColumns')"
            />
            <LoadingScreen :custom-show-loading="loading"/>
            <router-view v-if="!loading" />
        </div>
    </div>
</template>

<script lang="ts" src="./ReceivedInvoicesList.ts"></script>
<style lang="scss" src="../InvoicesLists.scss" scoped></style>
