import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'

import {
    AutocompleteItem,
    BulkPayload,
    FiltersPayload,
    ListPunchItem,
    PunchItem,
    PunchItemPayload,
    PunchPermissions,
} from '@/io-modules/punch-lists/interfaces/PunchItem.ts'
import { Company } from '@/io-modules/punch-lists/interfaces/ProjectDirectory.ts'

export default {
    getPermissions: (): AxiosPromise<PunchPermissions> =>
        client.get('/ca/punch-item/permissions'),

    getPunchItems: (projectId: string, params: { punch_list_id?: string } & FiltersPayload = {}): AxiosPromise<{ items: ListPunchItem[] }> =>
        client.get(`/ca/punch-item/${ projectId }/punch-lists/punch-items`, { params }),

    getPunchItem: (projectId: string, punchItemId: string): AxiosPromise<PunchItem> =>
        client.get(`/ca/punch-item/${ projectId }/punch-lists/punch-items/${ punchItemId }`),

    createPunchItem: (projectId: string, payload: PunchItemPayload): AxiosPromise<PunchItem> =>
        client.post(`/ca/punch-item/${ projectId }/punch-lists/punch-items`, payload),

    editPunchItem: (projectId: string, payload: PunchItemPayload): AxiosPromise<PunchItem> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/punch-items/${ payload.id }`, payload),

    bulkArchivePunchItems: (projectId: string, payload: BulkPayload): AxiosPromise<void> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/punch-items/archive`, payload),

    bulkChangeStatusPunchItems: (projectId: string, payload: BulkPayload): AxiosPromise<void> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/punch-items/status`, payload),

    bulkChangeDueDatePunchItems: (projectId: string, payload: { due_date: string, punch_items_ids: string[] }): AxiosPromise<void> =>
        client.put(`/ca/punch-item/${ projectId }/punch-lists/punch-items/due-date`, payload),

    getPunchItemTitles: (projectId: string, payload: { search_string: string }): AxiosPromise<{ items: AutocompleteItem[] }> =>
        client.post(`/ca/punch-item/${ projectId }/punch-lists/punch-items/titles/search`, payload),

    getProjectDirectoryCompanies: (projectId: string): AxiosPromise<{ items: Company[] }> =>
        client.get(`/ca/punch-item/${ projectId }/dictionaries/project-directory/companies`),
}
