/**
 * @deprecated
 * Multiselect should be used for this purpose.
 */

import { defineComponent, onMounted, computed } from 'vue'
import { assign } from 'lodash'
import store from '@/store'
import type MultiselectCheckboxInputData from './interfaces/MultiselectCheckboxInputData'
import type { MultiselectOptionsInterface } from '@/interfaces/components/custom-multiselect/MultiselectOptionsInterface'

export default defineComponent({
    name: 'MultiselectCheckbox',
    props: {
        settings: {
            type: Object as () => MultiselectOptionsInterface,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array as () => MultiselectCheckboxInputData[],
            required: true,
        },
        selectAll: {
            type: String,
            default: undefined,
        },
        labelSelected: {
            type: String,
            default: '',
        },
        modelValue: {
            type: Array,
            default: undefined,
        },
        multiple: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['update:modelValue', 'menuToggled', 'changeOption'],

    setup () {
        const isProduction = computed(() => store.getters['appStore/isProduction'])

        onMounted(() => {
            !isProduction.value && console.warn('Replace this deprecated component with Multiselect. You may  find a good example using checkbox in BudgetCostCodeSelect.vue or BudgetSiteSelect.vue')
        })
    },
    data () {
        return {
            showMenu: false,
            searchKeyword: '',
            isCheckAll: false as boolean,
        }
    },

    computed: {
        getActiveLabel (): string {
            return true === this.multiple
                ? `${ this.getChecked.length } ${ this.labelSelected } ${ this.$t('selected') } `
                : this.modelValue?.title || ''
        },
        getChecked (): Array<unknown> {
            return this.isWithSections
                ? this.data.map((item: MultiselectCheckboxInputData<unknown>) => item.options).flat().filter(item => true === item.checked)
                : this.data.filter(item => true === item.checked)
        },
        config (): MultiselectOptionsInterface {
            const defaultConfig = {
                textPlaceholder: this.$t('Please Select'),
                enableAddNew: false,
                closeAfterSelect: false,
                enableSearch: false,
            }
            return assign(defaultConfig, this.settings)
        },
        isWithSections (): boolean {
            return this.data.length ? !!this.data[0].options : false
        },
    },
    watch: {
        active: {
            handler (): void {
                this.activeOption = this.active
            }
        },
        modelValue: {
            handler (newVal?: unknown, oldVal?: unknown): void {
                // when previous value was falsy or empty array and new value is falsy or
                // empty array as well, there is no need to reset, it can cause infinite loop
                // when used with computed property in parent component (when array is modified)
                if (this.isFalsyOrEmptyArray(newVal) && !this.isFalsyOrEmptyArray(oldVal)) {
                    this.resetOptions()
                }
            },
            deep: true
        },
        showMenu (state: boolean): void {
            this.$emit('menuToggled', state)
        },
    },
    created () {
        if (this.modelValue?.length) {
            return
        }
        this.resetOptions()
    },
    methods: {
        isFalsyOrEmptyArray (value: unknown): boolean {
            return !value || (Array.isArray(value) && !value.length)
        },

        resetOptions (): void {
            this.isWithSections ? this.changeCheckAllSections() : this.changeCheckSection(this.data)
        },
        toggleMenu (): void {
            if (this.disabled) {
                return
            }
            this.showMenu = !this.showMenu
            this.$nextTick(() => {
                if (this.$refs.searchInput) {
                    this.$refs.searchInput.focus()
                }
            })
        },
        hideMenu (): void {
            this.showMenu = false
        },
        changeCheckAllSections (status: boolean | undefined = false): void {
            this.data.forEach(item => {
                this.setCheckedProperty(item, status)
                this.changeCheckSection(item.options, status)
            })
        },
        changeCheckSection (section: Array<any>, status: boolean | undefined = false): void {
            section.forEach(item => this.setCheckedProperty(item, status))
        },
        setCheckedProperty (item: Object, status: boolean | undefined = false): void {
            item['checked'] = status
            this.propagateChanges()
        },
        changeCheckAll (): void {
            this.isCheckAll = !this.isCheckAll

            this.isWithSections
                ? this.changeCheckAllSections(this.isCheckAll)
                : this.changeCheckSection(this.data, this.isCheckAll)
        },
        validateCheckAll (): void {
            this.data.every(item => true === item.checked)
                ? this.isCheckAll = true
                : this.isCheckAll = false
        },
        changeCheckItemBySection (item: any): void {
            item.options.every(item => true === item.checked)
                ? item.checked = true
                : item.checked = false

            this.propagateChanges()
        },
        onClickSection (section: Array<any>, status: boolean | undefined = false, item: any): void {
            if (false === this.multiple) {
                return
            }

            item['checked'] = !item.checked

            this.changeCheckSection(section, status)
        },
        onClickItemFromSection (item: any, option: any): void {
            option['checked'] = !option.checked

            true === this.multiple
                ? this.handleMultipleSelection(item)
                : this.handleNonMultipleSelection(option)
        },
        handleMultipleSelection (item: any): void {
            this.changeCheckItemBySection(item)
        },
        changeCheckOption (item: { checked: boolean }): void {
            item['checked'] = !item.checked
            this.propagateChanges()
        },
        handleNonMultipleSelection (option: any): void {
            this.$emit('update:modelValue', option)
            this.$emit('changeOption', option)
            this.hideMenu()
        },
        propagateChanges (): void {
            this.validateCheckAll()
            this.$emit('update:modelValue', this.getChecked)
            this.$emit('changeOption', this.getChecked)
        },
    },
})
