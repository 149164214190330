import { defineComponent } from 'vue'
import useLoader from '@/composables/useLoader.ts'
import { mapGetters } from 'vuex'

import drawingAnnotationsClient from '@/io-modules/drawings/api-clients/drawingAnnotationsClient.ts'
import setsClient from '@/io-modules/drawings/api-clients/setsClient.ts'

import Reply from './reply/Reply.vue'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'
import Mentions from '@/components/mentions/mentions.vue'

import { RightSideBar } from '@/interfaces/modules/projects/drawings/RightSideBar.ts'

export default defineComponent({
    components: {
        Mentions,
        Reply,
        DropdownButtonNew,
    },
    inject: ['mentionUsers'],
    props: {
        data: { type: Object },
        isResolved: { type: Boolean },
    },
    setup () {
        const { load } = useLoader({ globalSpinner: true })
        return { load }
    },
    data () {
        return {
            replyingToComment: false,
            replyMessage: '',
            showAllComments: false,
            editMode: false,
            editedComment: '',
            shareWithComment: [] as string[],
            shareWithReply: [] as string[],
            annotationType: RightSideBar.COMMENTS,
        }
    },
    computed: {
        ...mapGetters('appStore', {
            getAuthData: 'getAuthData',
            isAdmin: 'isAdmin',
        }),
        ...mapGetters('drawingMarkup', {
            model: 'getModel',
        }),
        ...mapGetters('drawingMarkup', {
            getSelectedExistingAnnotation: 'getSelectedExistingAnnotation',
        }),
        isSelected (): boolean {
            return this.getSelectedExistingAnnotation.uuid === this.data.uuid &&
                this.getSelectedExistingAnnotation.type === this.data.type
        },
        commentId (): string {
            return `drawing-comment-${ this.data.id }`
        },
        toggleCommentsText (): string {
            return this.showAllComments
                ? `Hide ${ this.data.comment.comments.length - 1 } Replies`
                : `Show All ${ this.data.comment.comments.length } Replies`
        },
        isCreator (): boolean {
            return this.getAuthData.u_mongo === this.data.created_by_full_name.id
        },
        canApplyActions (): boolean {
            return this.isCreator || this.isAdmin
        },
        getLastReplyId (): string {
            return this.data.comment.comments[this.data.comment.comments.length - 1].id ?? ''
        },
    },
    watch: {
        isSelected (value: boolean): void {
            if (!value) {
                return
            }
            this.scrollToCommentDelay()
        },
    },
    methods: {
        async deleteComment (): Promise<void> {
            return this.load(async (): Promise<void> => {
                this.$emit('annotation-deleted', this.data.uuid)
                await drawingAnnotationsClient.deleteCommentAnnotation(this.data.comment.id)
                this.showNotification('success', this.$t('Comment deleted'))
                await this.updateDrawingVersion()
            })
        },
        parsedComments (): unknown[] {
            return this.showAllComments
                ? this.data.comment.comments
                : this.data.comment.comments.slice(-1)
        },
        toggleComments (): void {
            this.showAllComments = !this.showAllComments
        },
        setReplyingToComment (): void {
            if (this.data.is_resolved) {
                return
            }
            this.replyingToComment = true
        },
        async addReply (): Promise<void> {
            return this.load(async (): Promise<void> => {
                await drawingAnnotationsClient.replyToComment({
                    id: this.data.comment.id,
                    comment: this.replyMessage,
                    shared_with: this.shareWithReply,
                })
                this.cancelReplying()
                await this.updateDrawingVersion()
            })
        },
        cancelReplying (): void {
            this.replyingToComment = false
            this.replyMessage = ''
        },
        openEditMode (): void {
            this.editMode = true
            this.editedComment = this.data.comment.comment
        },
        cancelEditMode (): void {
            this.editMode = false
        },
        async saveComment (): Promise<void> {
            this.setLoadingBar(true)

            try {
                await drawingAnnotationsClient.updateCommentAnnotation({
                    id: this.data.comment.id,
                    comment: this.editedComment,
                    shared_with: this.shareWithComment,
                })
                this.data.comment.comment = this.editedComment
                this.cancelEditMode()
            } catch (e) {
                this.showNotification('error', e)
            } finally {
                this.setLoadingBar(false)
            }
        },
        deleteCommentPopup (): void {
            this.showPopupAlert({
                title: this.$t('Are you sure you want to delete this comment?'),
                caption: this.$t('Deleting this comment will delete all replies.'),
                icon: 'icon-trash',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Yes, Delete'),
                        class: 'io-btn-primary',
                        action: async () => {
                            await this.deleteComment()
                        },
                    },
                ],
            })
        },
        async resolveCommentPopup (): Promise<void> {
            if (this.data.is_resolved) {
                await this.resolveComment(false)
                return
            }
            this.showPopupAlert({
                title: this.$t('Are you sure you want to close this comment?'),
                icon: 'icon-check-circle',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t('Yes, Close Comment'),
                        class: 'io-btn-primary',
                        action: async () => {
                            await this.resolveComment(true)
                        },
                    },
                ],
            })
        },
        async resolveComment (state: boolean): Promise<void> {
            return this.load(async (): Promise<void> => {
                await drawingAnnotationsClient.resolve(this.data.comment.id, {
                    is_resolved: state,
                })
                await this.updateDrawingVersion()
            })
        },
        async updateDrawingVersion (): Promise<void> {
            return this.load(async (): Promise<void> => {
                const { data } = await setsClient.getDrawingVersion(this.model.drawing.current_version_id)
                this.$store.commit('drawingMarkup/SET_MODEL', data)
            })
        },
        scrollToCommentDelay (): void {
            this.$emit('scroll-to-comment-delay', this.commentId)
        },
        selectAnnotation (): void {
            this.$emit('scroll-to-comment', this.commentId)
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: this.data.uuid,
                type: this.annotationType ?? this.data.type,
            })
        },
    },
})
