import webCredentialClient from '../api-clients/webCredentialClient.ts'
import WebCredential from '../interfaces/WebCredential.ts'

export const webCredentialStore = {
    webCredentials: [] as WebCredential[],

    async loadWebCredentials (): Promise<void> {
        // Skip request if app uses index from laravel
        if (window.appConfig) {
            this.webCredentials = window.appConfig
            return
        }
        const { data } = await webCredentialClient.getWebCredentials()
        this.webCredentials = data
    },
    findWebCredential (id: string): string | null {
        let item = this.webCredentials.find((item: WebCredential) => item.id === id)
        return item ? item.value : null
    },
    resetState (): void {
        this.webCredentials = []
    }
}

export default webCredentialStore
