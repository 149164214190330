import ScheduleTicketsList from '@/io-modules/lists-templates/schedule-templates/views/schedule-tickets-list/ScheduleTicketsList.vue'
import ScheduleTemplatesListing
    from '@/io-modules/lists-templates/schedule-templates/views/schedule-templates-listing/ScheduleTemplatesListing.vue'

const routes = [
    {
        path: '/lists-templates/schedule-templates',
        name: 'lists-templates-schedule-templates',
        meta: { title: 'Schedule Templates' },
        component: ScheduleTemplatesListing
    },
    {
        path: '/lists-templates/schedule-templates/:id',
        name: 'lists-templates-schedule-templates-view',
        meta: { title: 'Schedule List' },
        component: ScheduleTicketsList
    },
]
export default routes
