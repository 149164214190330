<template>
    <div
        class="io-financial-info-tile"
        @click="$emit('clicked')"
    >
        <div class="io-financial-info-tile__title">
            {{ $tc(title) }}
        </div>

        <div class="io-financial-info-tile__value">
            {{ value }}
        </div>

        <div v-if="$slots.description" class="io-financial-info-tile__description">
            <slot name="description"/>
        </div>

        <div v-if="iconPath" class="io-financial-info-tile__icon">
            <img :src="iconPath" :alt="iconAlt"/>
        </div>

        <div v-else class="io-financial-info-tile__icon-span" :style="{ backgroundColor: iconSpan.backgroundColor}">
            <span :class="iconSpan.name"></span>
        </div>

        <div v-if="showWarning" class="io-financial-info-tile__icon--warning">
            <img src="/new-theme/img/c-warning.svg" alt="warning"/>
        </div>
    </div>
</template>

<script src="./FinancialInfoTile.ts" lang="ts"/>
<style src="./FinancialInfoTile.scss" lang="scss" scoped/>
