import { defineComponent } from 'vue'
import Uploader from '@/components/files-new/parts/uploader/uploader.vue'
import RenameFileModal from '@/components/file-uploader-with-table/components/rename-file-modal/RenameFileModal.vue'
import FilesTable from '@/components/file-uploader-with-table/components/files-table/FilesTable.vue'
import SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument'
import client from '@/components/file-uploader-with-table/api-clients/filesClient'
import { FileInterface, ValidationOptions } from '@/components/file-uploader-with-table/interfaces/File'


export default defineComponent({
    name: 'FileUploaderWithTable',
    components: {
        Uploader,
        RenameFileModal,
        FilesTable
    },
    props: {
        files: {
            type: Array as () => FileInterface[],
            required: true
        },
        validationOptions: {
            type: Object as () => ValidationOptions,
            default: () => ({})
        },
        modelType: {
            type: String,
        },
        isViewMode: {
            type: Boolean,
            default: false
        },
        documentsPreview: {
            type: Boolean,
            default: false
        },
    },
    emits: ['update:files'],
    data () {
        return {
            renameModalShown: false,
            fileToRename: null
        }
    },
    methods: {
        fileAdded ({ document }: { document: SingleModelDocument }): void {
            this.$emit('update:files', [
                ...this.files,
                {
                    name: document.file_name,
                    fileSize: document.file_size,
                    id: document._id
                }
            ])
        },
        closeRenameModal (): void {
            this.renameModalShown = false
            this.fileToRename = null
        },
        openRenameModal (fileId: string): void {
            this.fileToRename = this.files.find((file: FileInterface) => file.id === fileId)
            this.renameModalShown = true
        },
        async preview (fileId: string): Promise<void> {
            const url = this.documentsPreview
                ? this.$router.resolve({ name: 'project-documents-show', params: { pid: this.$route.params.pid, id: fileId } })?.href
                : await client.preview(fileId)
            window.open(url, '_blank', 'noopener')
        },
        async remove (fileId: string): Promise<void> {
            const files: FileInterface[] = this.files.filter((file: FileInterface) => file.id !== fileId)
            this.$emit('update:files', files)
        },
        async rename ({ fileId, fileName }: { fileId: string, fileName: string }): Promise<void> {
            this.setLoadingBar(true)

            try {
                await client.rename(fileId, fileName)

                const files = this.files.map((file: FileInterface) => {
                    if (file.id === fileId) {
                        file.name = fileName
                    }

                    return file
                })

                this.renameModalShown = false
                this.fileToRename = null
                this.$emit('update:files', files)
            } catch (e) {
                this.errorHandle(e)
            } finally {
                this.setLoadingBar(false)
            }
        }
    }
})
