export enum InvoiceStatus {
    Pending = 'pending',
    Sent = 'under_review',
    RevisionRequested = 'revision_requested',
    RevisedAndResubmit = 'revised_resubmit',
    DeclinedToInvoice = 'declined_to_invoice',
    SavedAsVendorSubmission = 'saved_as_vendor_submission',
    UnderReview = 'under_review',
    PendingModified = 'pending_modified',
    Rejected = 'declined_to_invoice',
    Approved = 'approved',
    SubmittedForFunding = 'submitted_for_funding',
    ApprovedForFunding = 'approved_for_funding',
    RejectedForFunding = 'rejected_for_funding',
    RevisedResubmit = 'revised_resubmit',
    //invoice v3
    Draft = 'draft', // timeline - Draft
    PendingInternalApproval = 'pending_internal_approval', // timeline - Internal Approval
    InternallyApproved = 'internally_approved', // timeline - Internal Approval
    InternallyRejected = 'internally_rejected', // timeline - Internal Approval
    SubmittedToClient ='submitted_to_client', // timeline - Client Approval
    RejectedByClient = 'rejected_by_client', // timeline - Client Approval
    RequestedRevision = 'requested_revision', // timeline - Client Approval
    Voided = 'voided', // timeline - Client Approval
    ApprovedByClient = 'approved_by_client', // timeline - Client Approval
    SubmittedToAccounting = 'submitted_to_accounting', // timeline - Accounting
    RejectedByAccounting = 'rejected_by_accounting', // timeline - Accounting
    ApprovedByAccounting = 'approved_by_accounting', // timeline - Accounting
    PartiallyPaid = 'partially_paid', // timeline - Paid
    Paid = 'paid', // timeline - Paid
}

export default InvoiceStatus
