export function getStepsModels (state) {
    return Object.entries(state.dromoConfiguration)
}

export function getStepsFields (state) {
    return (modelName) => {
        return Object.values(state.dromoConfiguration[modelName]['fields'])
    }
}

export function getStepsName (state) {
    return (modelName) => {
        return state.dromoConfiguration[modelName]['name']
    }
}

export function getImportConfiguration (state) {
    return state.importConfiguration
}

export function getClients (state) {
    return state.clients
}

export function getAllImportCounters (state) {
    return state.importCounters
}

export function getImportCounter (state) {
    return (modelName) => {
        if (state.importCounters && modelName in state.importCounters) {
            return state.importCounters[modelName]
        }
        return 0
    }
}

export function getImportDuplicatedCounter (state) {
    return (modelName) => {
        if (state.importDuplicatedCounter && modelName in state.importDuplicatedCounter) {
            return state.importDuplicatedCounter[modelName]
        }
        return 0
    }
}

export function getFailedRecords (state) {
    return (modelName) => {
        if (state.importFailedRecords && modelName in state.importFailedRecords) {
            return state.importFailedRecords[modelName]
        }
        return []
    }
}

export function getCompletedRecords (state) {
    return state.importCompletedRecords
}

export function getDataImporterId (state) {
    return state.dataImporter?.id
}

export function getDataImporterName (state) {
    return state.dataImporter?.name
}

export function getDataImporterStatus (state) {
    return state.dataImporter?.status
}

export function getDataImporterErrorMessage (state) {
    return state.dataImporter?.import_error_message
}

export function getDataImporterStartDate (state) {
    return state.dataImporter?.start_date
}

export function getDataImporterEndDate (state) {
    return state.dataImporter?.end_date
}

export function getDataImporterPercentage (state) {
    return state.dataImporter?.percentage
}

export function getDataImporterBudgetCodeTemplateId (state) {
    return state.dataImporter?.budget_code_template_id
}

export function getDataImporterClientMongoId (state) {
    return state.dataImporter?.client_mongo_id
}
