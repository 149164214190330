import { defineComponent } from 'vue'
import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'

export default defineComponent({
    name: 'PinSvgGenerator',
    methods: {
        generatePinIconSvg (color: string, text: string, type: AnnotationTypes): string {
            if (type === AnnotationTypes.PUNCH) {
                return `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" version="1.1">
                     <g>
                       <circle stroke-width="2" cx="16" cy="16" r="15" stroke="${ color }" fill="none" />
                       <text font-family="'Inter', sans-serif" font-weight="600" fill="${ color }" x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="14px" >${ text }</text>
                     </g>
                    </svg>`
            }
            return `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" version="1.1">
                 <g>
                   <path d="M0 0.627451C0 0.280919 0.280919 0 0.627451 0H16C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16V0.627451Z" fill="${ color }"/>
                   <text font-family="'Inter', sans-serif" font-weight="600" fill="#fff" x="50%" y="50%" text-anchor="middle" dy=".3em" font-size="14px" >${ text }</text>
                 </g>
                </svg>`
        },
        // this Blob Url is needed to be provided to WebViewer as a source of Tool Image
        generateSvgBlobUrl (color: string, text: string, type: AnnotationTypes): string {
            return URL.createObjectURL(new Blob([this.generatePinIconSvg(color, text, type)], {
                type: 'image/svg+xml'
            }))
        }
    }
})
