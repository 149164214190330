import defaultState from './state'
import { cloneDeep } from 'lodash'

export function RESET_STATE (state) {
    Object.assign(state, cloneDeep(defaultState)) // @note forcing this line to update in dev
}

export function SET_FILE_UPLOADING (state, value) {
    state.fileUploading = value
}

export function SET_RECALCULATE_USED_STORAGE (state, value) {
    state.recalculateUsedStorage = value
}
