export enum StatusClass {
    sub = 'accent-sc',
    sc = 'accent-sc',
    gc = 'accent-gc',
    ac = 'accent-ac',
    dev = 'accent-dev',
    rep = 'accent-dev',
    design = 'accent-ac',
    owner = 'accent-dev',
    investor = 'accent-investor',
    vc = 'accent-light',
    genius = 'accent-genius',
    default = 'accent-light'
}
