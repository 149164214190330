export const setShow = function ({commit}, payload) {
    commit('SET_SHOW', payload)
    commit('REFRESH_KEY')
}
export const setRefresh = function ({commit}, payload) {
    commit('SET_REFRESH', payload)
}
export const setRefreshResource = function ({commit}, payload) {
    commit('SET_REFRESH_RESOURCE', payload)
}
export const setReturnData = function ({commit}, payload) {
    commit('SET_RETURN_DATA', payload)
}
export const setEditItemId = function ({commit}, payload) {
    commit('SET_EDIT_ITEM_ID', payload)
}
export const setAdditionalData = function ({commit}, payload) {
    commit('SET_ADDITIONAL_DATA', payload)
}
export const resetAdditionalData = function ({commit}) {
    commit('RESET_ADDITIONAL_DATA')
}

export const setModalLoading = function ({commit}, payload) {
    commit('SET_MODAL_LOADING', payload)
}
export const resetModalLoading = function ({commit}, payload) {
    commit('RESET_MODAL_LOADING', payload)
}
export const setHidden = function ({commit}, payload) {
    commit('SET_HIDDEN', payload)
}
