import { ApiClientV3 as clientV3 } from '@/api/api'
import { AxiosPromise } from 'axios'
import { JobsiteLocationsInterface } from '@/components/jobsite-locations/interfaces/JobsiteLocationsInterface.ts'

const jobsiteLocationsClient = {
    async fetchJobsiteLocations (projectId: string): AxiosPromise<JobsiteLocationsInterface[]> {
        return clientV3.get(`/jobsite-location/${ projectId }/locations`)
    },

}

export default jobsiteLocationsClient
