<template>
    <td class="io-employee-list">
        <div class="io-employee-list__container">
            <template v-for="(employee, index) in parsedDataShortened" :key="index">
                <div>
                    <img
                        v-if="employee.e_photo"
                        class="io-avatar"
                        :src="getApiUrlAvatar(employee.e_mongo)"
                        alt="employee avatar"
                        v-tooltip.top="employee.full_name"
                    />
                    <Initials v-else :fullName="employee.full_name" v-tooltip.top="employee.fullname"/>
                </div>
            </template>
            <div
                class="io-employee-list__plus"
                v-if="parsedData.length > 4"
                v-tooltip.top="remainingEmployeesNames.join(', ')"
            >
                {{ `+${parsedData.length - 3}` }}
            </div>
        </div>
    </td>
</template>

<script lang="ts" src="./EmployeeList.ts"></script>
<style lang="scss" src="./EmployeeList.scss" scoped></style>
