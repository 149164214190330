import { PropType, defineComponent } from 'vue'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import SoVLineItemField from '@/io-modules/invoices/enums/SovLineItemField'
import SoVLineItem from '@/io-modules/invoices/interfaces/SoVLineItem'
import { LabelSize, LabelType } from '@/constants/LabelConstants'
import Comments from '@/components/comments/Comments.vue'
import CommentItem from '@/components/comments/interfaces/CommentItem'
import dayjs from 'dayjs'
import { mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'

export default defineComponent({
    name: 'AdjustmentsModal',
    components: {
        IOModal,
        IOSingleInfoRow,
        Comments,
    },
    props: {
        item: {
            type: Object as PropType<SoVLineItem>,
            default: () => ({})
        },
    },
    data () {
        return {
            SoVLineItemField,
            percentOfCompletedWork: 0,
            currentApplication: 0,
            materialsStored: 0,
            LabelSize,
            LabelType,
            isEditable: false,
            taggableUsers: [],
            comments: [],
        }
    },
    computed: {
        ...mapStateVuex('appStore', {
            authData: (state: any) => state.authData
        }),

        modalTitle (): string {
            return `Adjustments: ${ this.item.numberFull } - ${ this.item.name }`
        },

        maxCompletedPercent (): number {
            return this.toInt(100)
        },
    },
    mounted () {
        this.percentOfCompletedWork = this.getAdjustedValue(SoVLineItemField.PercentOfCompletedWork)
        this.currentApplication = this.getAdjustedValue(SoVLineItemField.CurrentApplication)
    },
    methods: {
        getSubmittedValue (field: SoVLineItemField): number {
            return this.item.adjustments?.values.original[field] || 0
        },

        getAdjustedValue (field: SoVLineItemField): number {
            return this.item.adjustments?.values[field] || 0
        },

        getDifferenceValue (field: SoVLineItemField): number {
            if (field === SoVLineItemField.PercentOfCompletedWork && this.getAdjustedValue(field) !== this.percentOfCompletedWork) {
                return (this.getSubmittedValue(field) - this.percentOfCompletedWork)
            }
            if (field === SoVLineItemField.CurrentApplication && this.getAdjustedValue(field) !== this.currentApplication) {
                return (this.getSubmittedValue(field) - this.currentApplication)
            }

            return (this.getSubmittedValue(field) - this.getAdjustedValue(field))
        },

        reviseAdjustments (): void {
            this.isEditable = true
        },

        acceptAdjustments (): void {
            this.closeModal()
        },

        revise (): void {
            this.closeModal()
        },

        closeModal (): void {
            this.$emit('close')
        },

        addComment (comment: CommentItem): void {
            this.comments.unshift(
                {
                    ...comment,
                    id: 0,
                    created_at: dayjs(new Date()) ,
                    creator: {
                        id: this.authData.u_mongo,
                        first_name: this.authData.u_firstname,
                        last_name: this.authData.u_lastname,
                    },
                })
        },

        inputCurrentValue (value: number): void {
            this.percentOfCompletedWork = ((this.item[SoVLineItemField.PreviousApplied] + value)/this.item[SoVLineItemField.ScheduleValue]) * 100 * process.env.SCALE_FACTOR
        },

        inputPercent (value: number): void {
            if (value <= this.maxCompletedPercent) {
                this.currentApplication = this.sub([this.intMultiplyPercent(this.item[SoVLineItemField.ScheduleValue], value), this.item[SoVLineItemField.PreviousApplied]])
            }

            this.correctTaskPercent()
        },

        correctTaskPercent (): void {
            if (this.item[SoVLineItemField.PercentOfCompletedWork] > this.maxCompletedPercent) {
                this.item[SoVLineItemField.PercentOfCompletedWork] = value
            }
        },
    }
})
