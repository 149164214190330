<template>
    <div v-if="items && items.length" class="io-progress-bar" :class="variantClassName">
        <div class="io-progress__legend" v-if="showLegend">
            <template v-for="(item, index) in items" :key="'progress-legend-' + index">
                <span class="io-done" :style="legendStyles(index, item)">
                <template v-if="item.show_legend">
                    {{ item.value }}%
                </template>
            </span>
            </template>

            <span class="io-remains" :style="{ width: remainsPercentWith + '%' }">{{ remainsPercent }}%</span>
        </div>

        <template v-for="(item, index) in items" :key="'progress-label-' + index">
            <div class="io-progress__label" :style="labelStyles(index, item)"></div>
        </template>
    </div>
</template>

<script lang="ts" src="./ProgressBar.ts"></script>
<style lang="scss" src="./ProgressBar.scss" scoped></style>
