export enum InvoiceHeader {
    VendorInvoiceId = 'vendor_invoice_id',
    InvoiceNumber = 'invoice_number',
    UniqueId = 'invoice_unique_number',
    InvoiceDate = 'invoice_date',
    Type = 'type',
    ApplicationPackageNumber = 'application_package_number',
    Vendor = 'vendor',
    Status = 'status',
    LienWaiverStatus = 'lien_waiver_status',
    BallInCourt = 'ball_in_court',
    GrossBilledThisPeriod = 'gross_billed_this_period',
    NetBilledThisPeriod = 'net_billed_this_period',
    PaymentStatus = 'payment_status',
    BilledToDate = 'billed_to_date',
    BillingDate = 'billing_date',
}

export default InvoiceHeader
