import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import { Category } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'


export default defineComponent({
    name: 'AssignedCostCodesPopup',
    components: {
        PopupModal
    },
    props: {
        workflowName: { type: String },
        assignedCostCodesCategories: {
            type: Array as () => Category[],
            default: () => []
        },
    },
    methods: {
        close (): void {
            this.$emit('close')
        }
    }
})
