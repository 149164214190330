import Vue, { PropType, defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import SearchInputNew from '@/components/search-input/SearchInput.vue'
import Tabs from '@/components/atoms/tabs/Tabs.vue'
import ActionsView from './views/actions-view/ActionsView.vue'
import AddContactDetails from './views/add-contact-details/AddContactDetails.vue'
import ContactFound from './views/contact-found/ContactFound.vue'
import WorkspaceFound from './views/workspace-found/WorkspaceFound.vue'
import UserCompanyNotFound from './views/user-company-not-found/UserCompanyNotFound.vue'
import WorkspaceTypeOffSystemCompany from './views/workspace-type-off-system-company/WorkspaceTypeOffSystemCompany.vue'
import WorkspaceTypeForTheNewContact from './views/workspace-type-for-the-new-contact/WorkspaceTypeForTheNewContact.vue'
import SingleContact from './parts/SingleContact.vue'
import AlertBox from '@/components/atoms/AlertBox/AlertBox.vue'
import Accordion from '@/components/accordion/Accordion.vue'
import appTypes from '@/base/appTypes'
import { mapActions, mapMutations, mapState } from 'vuex'
import { StepsList } from '@/interfaces/components/invite-modal/StepsList'
import { ContactActions } from '@/interfaces/components/invite-modal/ContactActions'
import client from '@/api/invite-modal/inviteModalClient'
import { InviteModalClientKeys } from '@/interfaces/components/invite-modal/InviteModalClientKeys'
import { TranslateResult } from 'vue-i18n'
import { Tab } from '@/components/atoms/tabs/TabInterface'
import { Contact, ContactToAction, ContactToActionResponse } from '@/interfaces/components/invite-modal/Contact'
import { InviteModalFilters } from '@/interfaces/components/invite-modal/InviteModalFilters'
import { InvitationStatuses } from '@/interfaces/components/invite-modal/InvitationStatuses'
import { ContactsListsTabs } from '@/interfaces/components/invite-modal/ContactsListsTabs'
import { FullScreenModalTypes } from '@/interfaces/components/invite-modal/FullScreenModalTypes'
import { cloneDeep, findIndex } from 'lodash'
import { PopupAlertOptionsInterface } from '@/components/invite-modal/interfaces/popupAlertInterface'

export default defineComponent({
    name: 'InviteModal',
    components: {
        PopupModal,
        SearchInputNew,
        ActionsView,
        AddContactDetails,
        ContactFound,
        WorkspaceFound,
        UserCompanyNotFound,
        WorkspaceTypeOffSystemCompany,
        WorkspaceTypeForTheNewContact,
        SingleContact,
        AlertBox,
        Accordion,
        Tabs,
    },
    props: {
        selectMultiple: { type: Boolean, default: true },
        componentKey: { type: [String, String as () => InviteModalClientKeys], required: true },
        resourceId: { type: String, required: false, default: '' },
        saveFromModal: { type: Boolean, required: false, default: false },
        modelType: { type: [String, null], required: true },
        section: { type: String, required: false, default: null },
        customModalTitle: { type: String, required: false, default: '' },
        showEmployeesOnly: { type: Boolean, required: false, default: false },
        showContactsOnly: { type: Boolean, required: false, default: false },
        showDirectoryOnly: { type: Boolean, required: false, default: false },
        showDirectory: { type: Boolean, required: false, default: false },
        skipNotifications: { type: Boolean, default: false },
        hideRemoveInvitationButton: { type: Boolean, default: false },
        preselectedWorkspaceType: { type: String, default: '' },
        modalZIndex: { type: Number, required: false },
        initialSearchValue: { type: String, required: false, default: '' },
        allowedClientsTypes: { type: Array as PropType<appTypes[]>, default: null },
        allowedCompaniesIds: { type: Array as PropType<string[]>, required: false, default: null },
        customProjectMongoId: { type: String, required: false, default: null },
        personShowSaveOnlyAction: { type: Boolean, required: false, default: false },
        setStateOnMount: { type: Boolean, required: false, default: true },
        isEnablePopupAlertOnRemoveSelected: { type: Boolean, required: false, default: false },
        popupAlertOptionsOnRemoveSelected: { type: Object as () => PopupAlertOptionsInterface, required: false },
        isAddingAllowed: { type: Boolean, default: true },
        allowToRemind: { type: Boolean, required: false, default: true }
    },
    emits: [
        'change',
        'loadingFinished',
        'close',
        'saveChanges',
        'updateOnItemContactsList',
        'modalSaveInProgress',
        'modalSaveFinished',
        'modalSaveError',
        'modalSaveSuccess',
        'updateInvitePreviewData',
    ],
    data () {
        return {
            search: this.initialSearchValue,
            StepsList: StepsList,
            initialState: '',
            ContactActions: ContactActions,
            scrollWrapperClass: '.io-invite-modal__popup .io-invite-modal__content',
            prevScroll: 0,
            invitationStatuses: InvitationStatuses,
            fullScreenModalTypes: FullScreenModalTypes,
            isAddThemselves: false,
            emailErrors: {
                response: {
                    data: {
                        failed: {
                            invites: [],
                            saves: [],
                            removes: [],
                            reminds: [],
                        },
                    },
                },
            },
            isOnItemAccordionOpened: true,
            contactsListsSelectedTab: 0,
            contactsListsSelectedTabKey: '',
        }
    },
    computed: {
        ...mapState('appStore', {
            authData: (state: any) => state.authData,
        }),
        ...mapState('project', {
            projectObj: state => state.projectObj,
        }),
        disabledText (): string {
            return this.$t('Not Available For This Item')
        },
        modalWidth (): number {
            return this.stepIsNotForAddingData ? 960 : 720
        },
        stepIsNotForAddingData (): boolean {
            return [this.StepsList.INITIAL, this.StepsList.NOT_FOUND, this.StepsList.SAVE_TO_BIDDERS_LIST].includes(this.activeStep)
        },

        actionText (): TranslateResult {
            if (!this.isWorkspaceTypeSelected && !this.isWorkspaceSelected) {
                return this.$t('Next')
            }

            return this.selectedAction === ContactActions.SEND_INVITE
                ? this.$t('Send Invitation')
                : this.$t('Save')
        },
        allowToSave (): boolean {
            return [
                InviteModalClientKeys.PROJECT_WIZARD_CLIENT,
                InviteModalClientKeys.CONTRACT_RECEIVER,
                InviteModalClientKeys.MEETING_MINUTES_MEMBERS_ADD_CONTACT,
                InviteModalClientKeys.PROJECT_DIRECTORY_COMPANIES,
                InviteModalClientKeys.CONTRACT_WIZARD_VENDOR,
                InviteModalClientKeys.PROJECT_FUNDING_SOURCES_PROVIDER,
            ].includes(this.componentKey as InviteModalClientKeys)
        },
        allowToSaveOnly (): boolean {
            return [
                InviteModalClientKeys.MEETING_MINUTES_MEMBERS_ADD_CONTACT,
            ].includes(this.componentKey as InviteModalClientKeys)
        },
        myContactsList (): any[] {
            return this.$store.getters['inviteModal/getMyContactsList'](this.componentKey)
        },
        matchedDomainsList (): any[] {
            return this.$store.getters['inviteModal/getMatchedDomainsList'](this.componentKey)
        },
        matchedAccountsList (): any[] {
            return this.$store.getters['inviteModal/getMatchedAccountsList'](this.componentKey)
        },
        selectedContactsList (): Contact[] {
            return this.$store.getters['inviteModal/getSelectedContactsList'](this.componentKey)
        },
        rendered (): boolean {
            return this.$store.getters['inviteModal/getIsRendered'](this.componentKey)
        },
        isLoading (): boolean {
            return this.$store.getters['inviteModal/getIsLoading'](this.componentKey)
        },
        isTabsVisible (): boolean {
            return this.$store.getters['inviteModal/getIsTabsVisible'](this.componentKey)
        },
        isFormValid (): boolean {
            return this.$store.getters['inviteModal/getIsFormValid'](this.componentKey)
        },
        onItemContactsList (): Contact[] {
            return this.$store.getters['inviteModal/getOnItemContactsList'](this.componentKey)
        },
        selectedContactsToAction (): ContactToAction {
            return this.$store.getters['inviteModal/getSelectedContactsToAction'](this.componentKey)
        },
        selectedContact (): Contact {
            return this.$store.getters['inviteModal/getSelectedContact'](this.componentKey)
        },
        headerText (): TranslateResult {
            if (this.selectMultiple) {
                return this.$t('Select Contacts')
            }

            if (this.isAddingNewContact) {
                return this.$t('Add New Contact')
            }

            return this.$t('Select Contact')
        },
        allowToSelectItems (): boolean {
            return this.selectMultiple || (!this.selectMultiple && !this.newItemsAdded)
        },
        newItemsAdded (): boolean {
            return this.selectedContactsList.some((item: Contact) => {
                return item._newAdded
            })
        },
        selectedItemsLabel (): TranslateResult {
            if (this.componentKey === InviteModalClientKeys.PROJECT_FUNDING_SOURCES_PROVIDER || this.componentKey === InviteModalClientKeys.PROJECT_FUNDING_SOURCES_CAPITAL_FUNDS) {
                return this.$t('On Item')
            } else {
                return this.selectMultiple ? this.$t('On Item') : this.$t('On Project')
            }
        },
        activeStep (): StepsList {
            return this.$store.getters['inviteModal/getActiveStep'](this.componentKey)
        },
        selectedAction (): ContactActions {
            return this.$store.getters['inviteModal/getSelectedAction'](this.componentKey)
        },
        isWorkspaceSelected (): boolean {
            const selectedWorkspace = this.$store.getters['inviteModal/getSelectedWorkspace'](this.componentKey)
            return selectedWorkspace && typeof selectedWorkspace === 'object' && Object.keys(selectedWorkspace).length > 0
        },
        isContactSelected (): boolean {
            const selectedContact = this.$store.getters['inviteModal/getSelectedContact'](this.componentKey)
            return selectedContact && typeof selectedContact === 'object'
        },
        isWorkspaceTypeSelected (): boolean {
            return !!this.$store.getters['inviteModal/getSelectedWorkspaceType'](this.componentKey)
        },
        isSearchValidEmail (): boolean {
            return this.$store.getters['inviteModal/isSearchValidEmail'](this.componentKey)
        },
        projectIdForDirectoryTab (): string | null {
            const routes = [
                'project-documents-list',
                'project-documents-details',
                'project-todos',
                'drawings-sheets-index',
                'meeting-members',
                'meeting-minutes',
                'meeting-details',
            ]

            if (routes.includes(this.$route.name as string)) {
                return this.projectObj?.project_global_id
            }

            return this.customProjectMongoId ? this.customProjectMongoId : null
        },
        filters (): InviteModalFilters {
            let filters = {
                showDirectory: false,
                showEmployees: true,
                showContacts: true,
            } as InviteModalFilters

            if (this.contactsListsSelectedTabKey === ContactsListsTabs.EMPLOYEES) {
                filters.showContacts = false
            }

            if (this.contactsListsSelectedTabKey === ContactsListsTabs.DIRECTORY) {
                filters = {
                    showDirectory: true,
                    showEmployees: false,
                    showContacts: false,
                    projectId: this.projectIdForDirectoryTab,
                } as InviteModalFilters
            }

            if (this.contactsListsSelectedTabKey === ContactsListsTabs.CONTACTS) {
                filters.showEmployees = false
            }

            if (this.allowedClientsTypes) {
                filters.allowedClientsTypes = this.allowedClientsTypes
            }

            if (this.allowedCompaniesIds) {
                filters.allowedCompaniesIds = this.allowedCompaniesIds
            }

            if (this.componentKey === InviteModalClientKeys.PROJECT_DIRECTORY_COMPANIES) {
                return filters
            }

            return this.isSingleTabModal
                ? { ...filters, isSingleTab: true }
                : filters
        },
        contactsListsTabs (): Tab[] {
            if (this.showDirectoryOnly && this.projectIdForDirectoryTab) {
                return [
                    {
                        title: this.$t('Directory'),
                        key: ContactsListsTabs.DIRECTORY,
                    },
                ]
            }

            if (this.showEmployeesOnly) {
                return [
                    {
                        title: this.$t('Employees'),
                        key: ContactsListsTabs.EMPLOYEES,
                    },
                ]
            }

            if (this.showContactsOnly) {
                return [
                    {
                        title: this.$t('Contacts'),
                        key: ContactsListsTabs.CONTACTS,
                    },
                ]
            }

            let tabs = [
                {
                    title: this.$t('All'),
                    key: ContactsListsTabs.ALL,
                },
            ]

            if (this.projectIdForDirectoryTab || this.showDirectory) {
                tabs.push({
                    title: this.$t('Directory'),
                    key: ContactsListsTabs.DIRECTORY,
                })
            }

            return [
                ...tabs,
                {
                    title: this.$t('Employees'),
                    key: ContactsListsTabs.EMPLOYEES,
                },
                {
                    title: this.$t('Contacts'),
                    key: ContactsListsTabs.CONTACTS,
                },
            ]
        },
        searchKeywordStored (): string {
            return this.$store.getters['inviteModal/getSearchKeyword'](this.componentKey)
        },
        showPlaceholder (): boolean {
            if (this.isLoading) {
                return false
            }

            if (this.isAddThemselves
                || (this.search
                    && this.isOtherRecordsExist
                    && !this.isSingleTabModal)) {
                return true
            }

            if (this.showEmployeesOnly || this.showDirectoryOnly) {
                return !this.myContactsList.length
                    && !this.matchedDomainsList.length
                    && !this.matchedAccountsList.length
                    && !this.isLoading
            }

            return !this.myContactsList.length && !this.isLoading
        },
        isFreshWorkspace (): boolean {
            return !this.search
                && !this.myContactsList.length
                && !this.matchedDomainsList.length
                && !this.matchedAccountsList.length
        },
        placeholderTitle (): TranslateResult {
            if (this.isOtherRecordsExist && !this.isSingleTabModal) {
                if (this.isContactsOnlyTabActive) {
                    return this.$t('User Not Found on the Contacts List')
                }

                if (this.isEmployeesOnlyTabActive) {
                    return this.$t('User Not Found on the Employees List')
                }

                if (this.isDirectoryOnlyTabActive) {
                    return this.$t('User Not Found on the Directory List')
                }
            }

            if (this.isAddThemselves) {
                return this.$t('You are currently logged in with this email')
            }

            if (!this.isSingleTabModal) {
                if (this.isAllTabActive) {
                    if (this.isFreshWorkspace) {
                        return this.$t('Enter the email for the person you want to add in the search box above.')
                    }
                    return this.$t('User Not Found')
                }

                if (this.isContactsOnlyTabActive) {
                    if (this.isFreshWorkspace) {
                        return this.$t('No Contacts Found')
                    }
                    return this.$t('User Not Found on the Contacts List')
                }

                if (this.isEmployeesOnlyTabActive) {
                    if (this.isFreshWorkspace) {
                        return this.$t('No Employees Found')
                    }
                    return this.$t('User Not Found on the Employees List')
                }

                if (this.isDirectoryOnlyTabActive) {
                    if (this.isFreshWorkspace) {
                        return this.$t('No Directory Found')
                    }
                    return this.$t('User Not Found on the Directory List')
                }
            }

            if (this.isSingleTabModal && this.isFreshWorkspace) {
                if (this.isAllTabActive) {
                    return this.$t('Enter the email for the person you want to add in the search box above.')
                }

                if (this.isContactsOnlyTabActive) {
                    return this.$t('Enter the email for the person you want to add in the search box above.')
                }

                if (this.isEmployeesOnlyTabActive) {
                    return this.$t('No Employees Found')
                }

                if (this.isDirectoryOnlyTabActive) {
                    return this.$t('No Directory Found')
                }
            }

            return `${ this.$t('User Not Found') } ${ this.isContactsOnlyTabActive ? 'in Your Contacts' : 'in Your Employees List' }`
        },
        placeholderSubTitle (): TranslateResult {
            if (this.isSingleTabModal && this.isFreshWorkspace) {
                if (this.isContactsOnlyTabActive) {
                    return ''
                }
            }

            if (!this.isSingleTabModal && this.isFreshWorkspace) {
                if (this.isAllTabActive) {
                    return ''
                }

                if (this.isContactsOnlyTabActive) {
                    return this.$t('Enter the email for the person you want to add in the search box above.')
                }

                if (this.isEmployeesOnlyTabActive) {
                    return this.$t('You haven\'t added any employees yet.')
                }

                if (this.isDirectoryOnlyTabActive) {
                    return this.$t('Looks like you haven\'t set up your Project Directory yet.')
                }
            }

            if (this.isOtherRecordsExist && !this.isSingleTabModal) {
                if (this.isContactsOnlyTabActive) {
                    return this.$t('We couldn\'t find a match in the “Contacts” tab')
                }

                if (this.isEmployeesOnlyTabActive) {
                    return this.$t('We couldn\'t find a match in the “Employees” tab')
                }

                if (this.isDirectoryOnlyTabActive) {
                    return this.$t('We couldn\'t find a match in the “Directory” tab')
                }
            }

            if (this.isAddThemselves) {
                return this.$t('You don\'t need to add yourself, you are already here')
            }

            if (this.isContactsOnlyTabActive) {
                if (!this.isSingleTabModal) {
                    return this.$t('We couldn\'t find a match in the “Contacts” tab')
                }
            }

            if (this.isEmployeesOnlyTabActive) {
                if (this.isSingleTabModal) {
                    return this.$t('Please make sure you have entered the correct information')
                }
                return this.$t('We couldn\'t find a match in the “Employees” tab')
            }

            if (this.isDirectoryOnlyTabActive) {
                if (this.isSingleTabModal) {
                    return this.$t('Please make sure you have entered the correct information')
                }
                return this.$t('We couldn\'t find a match in the “Directory” tab')
            }

            return this.$t('Enter the email for the person you want to invite in the search box above.')
        },
        placeholderAdditionalText (): TranslateResult {
            return ((this.isContactsOnlyTabActive || this.isEmployeesOnlyTabActive || this.isDirectoryOnlyTabActive)
                && !this.isAddThemselves && !this.isSingleTabModal && !this.isFreshWorkspace)
                ? this.$t('To add a new person, please enter their email address in the search bar')
                : ''
        },
        searchPlaceholder (): TranslateResult {
            return this.showEmployeesOnly || this.showDirectoryOnly
                ? this.$t('Search by email or name ')
                : this.$t('Search by email, company or name...')
        },
        isLastPage (): boolean {
            return this.$store.getters['inviteModal/getIsLastPage'](this.componentKey)
        },
        showSelectedContactAlert (): boolean {
            return !this.selectMultiple
                && this.selectedContactsList
                && this.selectedContactsList.length
                && this.activeStep !== StepsList.INITIAL
                && !this.isAddWorkspaceTypeStep
                && !this.isSelectWorkspaceTypeStep
        },
        showSearchInput (): boolean {
            return this.activeStep !== StepsList.ACTIONS
                && this.activeStep !== StepsList.ADD_CONTACT_DETAILS
                && !this.isAddWorkspaceTypeStep
                && !this.isSelectWorkspaceTypeStep
        },
        selectedContactName (): string {
            const selectedContact = this.selectedContactsList[0]
            if (this.showSelectedContactAlert) {
                return selectedContact.firstname ? selectedContact.firstname + ' ' + selectedContact.lastname : selectedContact.company_name
            }

            return '-'
        },
        isSelectedContactListChanged (): boolean {
            if (this.onItemContactsList?.length) {
                return this.onItemContactsList.length !== this.selectedContactsList.length
            }

            return !!this.selectedContactsToAction.save.length
                || !!this.selectedContactsToAction.remind.length
                || !!this.selectedContactsToAction.remove.length
                || !!this.selectedContactsToAction.invite.length
        },
        isFullScreenModal (): boolean {
            // @ts-ignore
            return Object.values(this.fullScreenModalTypes)?.includes(this.componentKey)
        },
        isAddingNewContact (): boolean {
            return this.componentKey === 'contact-create-client'
        },
        isAnExistingContact (): boolean {
            return '' !== this.search
                && this.isAddingNewContact
                && (this.myContactsList && this.myContactsList.length > 0)
        },
        isReadyForSavingNewContact (): boolean {
            return this.isAddingNewContact && this.selectedContactsToAction.save.length === 1
        },
        modalTitle (): TranslateResult {
            return this.customModalTitle ? this.customModalTitle : this.headerText
        },
        isSingleContactInfoVisible (): boolean {
            return this.isLastPage && this.showEmployeesOnly
        },
        isSaveChangesButtonDisabled (): boolean {
            return !this.selectedContactsToAction.invite.length
                && !this.selectedContactsToAction.remind.length
                && !this.selectedContactsToAction.remove.length
                && !this.selectedContactsToAction.save.length
        },
        isAddWorkspaceTypeStep (): boolean {
            return this.activeStep === StepsList.ADD_WORKSPACE_TYPE
        },
        isSelectWorkspaceTypeStep (): boolean {
            return this.activeStep === StepsList.SELECT_WORKSPACE_TYPE
        },
        onItemAccordionTitle (): TranslateResult {
            return this.isOnItemAccordionOpened ? this.$t('Collapse') : this.$t('Expand')
        },
        isAllTabActive (): boolean {
            return this.contactsListsSelectedTabKey === ContactsListsTabs.ALL
        },
        isContactsOnlyTabActive (): boolean {
            return this.contactsListsSelectedTabKey === ContactsListsTabs.CONTACTS
        },
        isEmployeesOnlyTabActive (): boolean {
            return this.contactsListsSelectedTabKey === ContactsListsTabs.EMPLOYEES
        },
        isDirectoryOnlyTabActive (): boolean {
            return this.contactsListsSelectedTabKey === ContactsListsTabs.DIRECTORY
        },
        isOtherRecordsExist (): boolean {
            return this.$store.getters['inviteModal/isOtherRecordsExist'](this.componentKey)
        },
        contactsListLabel (): TranslateResult {
            if (this.showEmployeesOnly) {
                return this.$t('Employees')
            }

            if (this.showDirectoryOnly) {
                return this.$t('Directory')
            }

            return this.$t('Contacts')
        },
        isSingleTabModal (): boolean {
            return this.showContactsOnly || this.showEmployeesOnly || this.showDirectoryOnly
        },
        isTabsActive (): boolean {
            return this.isTabsVisible && !this.isSingleTabModal
        },
        isProjectDirectoryCompanies (): boolean {
            return this.componentKey === InviteModalClientKeys.PROJECT_DIRECTORY_COMPANIES
        },
        isUserEmailFromIngenious (): boolean {
            return this.authData.u_email.includes('@ingenious.build')
        },
        isTrainingEnv (): boolean {
            return window.location.host.split('.')[2] === 'training'
        },
        shouldShowDelightedSurvey (): boolean {
            return this.isProduction && !this.isUserEmailFromIngenious && !this.isTrainingEnv
        },
    },
    watch: {
        searchKeywordStored (value: string): void {
            if (value !== this.search) {
                this.search = value
            }
        },
        initialSearchValue (value: string): void {
            if (value !== this.search) {
                this.search = value
            }
        },
        selectedContactsList: {
            handler (contacts: Contact[]): void {
                this.$emit('change', this.selectMultiple ? contacts : contacts[0])
            },
            deep: true,
        },
    },
    created () {
        this.$nextTick(() => {
            const element = document.querySelector(this.scrollWrapperClass)

            if (element) {
                element.addEventListener('scroll', this.handleScroll)
            }
        })
    },
    beforeMount () {
        !!this.onItemContactsList?.length && this.setIsCachedDataLoadRequired({ key: this.componentKey, value: true })

        if (this.setStateOnMount) {
            this.setStateObj(this.componentKey)
        }
        this.setModel({ key: this.componentKey, id: this.resourceId, type: this.modelType })
        this.setSection({ key: this.componentKey, value: this.section })
        this.setSkipNotifications({ key: this.componentKey, value: this.skipNotifications })
        this.fetchContacts(this.componentKey)
        this.initializeDelightedSurvey()
    },
    mounted () {
        this.initialState = JSON.stringify(this.selectedContactsList)
        this.setContactsListsSelectedTabKey()
        this.fetchRecords()
        this.setSelectMultiple({ key: this.componentKey, value: this.selectMultiple })

        this.$nextTick(() => {
            // @ts-ignore
            this.$refs.searchInput.$refs.searchInputNode.focus()
        })

        this.$emit('loadingFinished')
    },
    beforeUnmount () {
        this.clearSearchResults({ key: this.componentKey })

        const element = document.querySelector(this.scrollWrapperClass)

        if (element) {
            element.removeEventListener('scroll', this.handleScroll)
        }
    },
    methods: {
        ...mapActions('inviteModal', [
            'fetchContacts',
            'saveContact',
            'inviteContact',
            'removeSelected',
            'saveOrInviteNewContact',
            'inviteSavedContact',
            'addRemovedContact',
            'removeContactToSave',
            'removeContactToInvite',
            'removeContactToRemind',
            'invitationRemind',
        ]),
        ...mapMutations('inviteModal', {
            setStateObj: 'SET_STATE_OBJECT',
            setActiveStep: 'SET_ACTIVE_STEP',
            clearSearchResults: 'CLEAR_SEARCH_RESULTS',
            clearSelectedContactsToAction: 'CLEAR_SELECTED_CONTACTS_TO_ACTION',
            removeAllSelectedContacts: 'REMOVE_ALL_SELECTED',
            setSelectMultiple: 'SET_SELECT_MULTIPLE',
            setGroupedCompaniesContactsList: 'SET_GROUPED_COMPANIES_CONTACTS_LIST',
            setOnItemContactsList: 'SET_ON_ITEM_CONTACTS_LIST',
            setIsCachedDataLoadRequired: 'SET_CACHED_DATA_LOAD_REQUIRED',
            setModel: 'SET_MODEL',
            setSection: 'SET_SECTION',
            setSkipNotifications: 'SET_SKIP_NOTIFICATIONS',
            setLoading: 'SET_LOADING',
            setIsTabsVisible: 'SET_IS_TABS_VISIBLE',
            setContactFormData: 'SET_CONTACT_FORM_DATA',
            resetSelectedWorkspaceType: 'RESET_SELECTED_WORKSPACE_TYPE',
            setSelectedContactFromSelectedContacts: 'SET_SELECTED_CONTACT_FROM_SELECTED_CONTACTS',
            setSelectedContactWorkspaceType: 'SET_SELECTED_CONTACT_WORKSPACE_TYPE',
            resetSelectedContact: 'RESET_SELECTED_CONTACT',
            setSelectedAddedContactsProjectDirectory: 'SET_SELECTED_ADDED_CONTACTS_PROJECT_DIRECTORY',
        }),
        initializeDelightedSurvey (): void {
            !function (e, t, r, n) {
                if (!e[n]) {
                    for (var a = e[n] = [], i = ['survey', 'reset', 'config', 'init', 'set', 'get', 'event', 'identify', 'track', 'page', 'screen', 'group', 'alias'], s = 0; s < i.length; s++) {
                        let c = i[s]
                        a[c] = a[c] || function (e) {
                            return function () {
                                let t = Array.prototype.slice.call(arguments)
                                a.push([e, t])
                            }
                        }(c)
                    }
                    a.SNIPPET_VERSION = '1.0.1'
                    let o = t.createElement('script')
                    o.type = 'text/javascript', o.async = !0, o.src = 'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' + r + '/' + n + '.js'
                    let p = t.getElementsByTagName('script')[0]
                    p.parentNode.insertBefore(o, p)
                }
            }(window, document, 'SeyW3ErpJDhZRi5t', 'delightedCes72')
        },
        showDelightedSurvey (): void {
            if (!this.shouldShowDelightedSurvey) {
                return
            }

            delightedCes72.survey({
                name: `${ this.authData.u_firstname } ${ this.authData.u_lastname }`,
                email: this.authData.u_email,
                properties: {
                    workspaceName: this.authData.workspace_subdomain,
                    workspaceType: this.authData.app_type,
                    locale: 'en',
                },
            })
        },
        actionOnClick (): void {
            if (!this.isWorkspaceTypeSelected && !this.isWorkspaceSelected) {
                this.setSelectWorkspaceTypeStep()
                return
            }

            this.selectedAction === ContactActions.SEND_INVITE || !this.allowToSave
                ? this.onInviteAddedContact()
                : this.onSaveAddedContact()
        },
        onSelectWorkspaceTypeNextClick (): void {
            this.selectedAction === ContactActions.SEND_INVITE || !this.allowToSave
                ? this.onInviteAddedContact()
                : this.onSaveAddedContact()
        },
        fetchRecords (): void {
            this.onSearchChange(this.search, true)
        },
        onSaveContact (id: number): void {
            this.saveContact({ id, key: this.componentKey })
            this.searchAgainIfNoneIsLeft()
        },
        onInviteContact (id: number): void {
            this.inviteContact({ id, key: this.componentKey })
            this.searchAgainIfNoneIsLeft()
        },
        searchAgainIfNoneIsLeft (): void {
            if (!this.myContactsList.length) {
                this.onSearchChange('', false)
            }
        },
        showPopupAlertOnRemoveSelected (contact: Contact): void {
            const title = this.popupAlertOptionsOnRemoveSelected?.title || '{ fullname } has been removed. Would you like to remove him as well?'

            return this.showPopupAlert({
                title: this.$t(title, { fullname: `${ contact.firstname } ${ contact.lastname }` }),
                caption: this.$t(this.popupAlertOptionsOnRemoveSelected?.caption || 'If selected yes, the user will be removed entirely'),
                icon: 'icon-trash danger',
                buttons: [
                    {
                        text: this.$t(this.popupAlertOptionsOnRemoveSelected?.buttons[0]?.text || 'No'),
                        class: 'io-btn-light',
                        action: null,
                    },
                    {
                        text: this.$t(this.popupAlertOptionsOnRemoveSelected?.buttons[1]?.text || 'Yes'),
                        class: 'io-btn-primary',
                        action: () => {
                            this.processRemoveSelected(contact)
                        },
                    },
                ],
                zIndex: 2500,
            })
        },
        onRemoveSelected (contact: Contact): void {
            this.isEnablePopupAlertOnRemoveSelected
                ? this.showPopupAlertOnRemoveSelected(contact)
                : this.processRemoveSelected(contact)
        },
        onInviteSavedContact (id: number): void {
            this.removeContactToSave({ id, key: this.componentKey })
                .then(() => this.inviteSavedContact({ id, key: this.componentKey }))
        },
        async onSearchChange (search: string, isInitial: boolean = false, isTabSwitch: boolean = false): Promise<void> {
            if (this.search !== search) {
                this.setContactFormData({ key: this.componentKey, data: null })
            }

            this.search = search

            this.setInitialStep()

            this.clearSearchResults({ key: this.componentKey })
            this.setLoading({ key: this.componentKey, value: true })
            this.setIsTabsVisible({ key: this.componentKey, value: false })

            await this.$store.dispatch('inviteModal/fetchRecords', {
                search: this.search,
                key: this.componentKey,
                isInitial,
                filters: this.filters,
                resourceId: this.resourceId,
                currentUserEmail: this.authData.u_email,
                isAddingNewContact: this.isAddingNewContact,
                showDirectoryOnly: this.showDirectoryOnly,
                showEmployeesOnly: this.showEmployeesOnly,
                isTabSwitch: isTabSwitch,
            })

            this.isAddThemselves = !this.matchedAccountsList.length
                && !this.matchedDomainsList.length
                && this.search === this.authData.u_email
                && ![
                    InviteModalClientKeys.PROJECT_DIRECTORY_COMPANIES,
                    InviteModalClientKeys.PROJECT_WIZARD_MANAGER,
                    InviteModalClientKeys.CONTRACT_RECEIVER,
                    InviteModalClientKeys.CONTRACT_MANAGER,
                ].includes(this.componentKey as InviteModalClientKeys)
        },
        onClosePopup (): void {
            if (this.isSelectedContactListChanged) {
                const isEnabledKey = [
                    InviteModalClientKeys.PROJECT_WIZARD_CLIENT,
                    InviteModalClientKeys.PROJECT_WIZARD_MANAGER,
                    InviteModalClientKeys.PROJECT_WIZARD_EXECUTIVE,
                ].includes(this.componentKey as InviteModalClientKeys)

                const isClearState = !!this.onItemContactsList.length || isEnabledKey

                return this.showPopupAlert({
                    title: this.$t('Are you sure you want to leave?'),
                    caption: this.$t('You have some unsaved changes. If you leave now, your changes will be lost.'),
                    buttons: [
                        {
                            text: this.$t('Stay here'),
                            class: 'io-btn-light',
                            action: null,
                        },
                        {
                            text: this.$t('Leave without saving'),
                            class: 'io-btn-primary',
                            action: () => {
                                this.clearSelectedContactsToAction(this.componentKey)
                                this.closePopup(isClearState)
                            },
                        },
                    ],
                    zIndex: 2500,
                })
            }
            this.closePopup()
        },
        closePopup (clearState: boolean = false): void {
            if (clearState) {
                this.removeAllSelectedContacts(this.componentKey)
                this.setStateObj(this.componentKey)
                this.$emit('close', true)

                return
            }

            this.$emit('close')
        },
        async submit (): Promise<void> {
            if (this.saveFromModal) {
                await this.saveSelectedContacts()
                this.$emit('saveChanges')
                this.showDelightedSurvey()
                return
            }

            this.updateSelectedContactsList()
            this.updateOnItemContactsList()
            this.closePopup()

            this.$emit('saveChanges')
            this.showDelightedSurvey()
        },
        updateSelectedContactsList (): void {
            const groupedList = {}

            this.selectedContactsList.forEach((contact: Contact) => {
                contact.company_name = contact.company_name ?? contact.newCompany?.company_name
                contact.company_id = contact.company_id ?? contact.company?.workspace_id ?? contact.company?.id ?? contact.company_name
                contact.app_type = contact.app_type ?? contact.company?.shared_workspace_type
                const is_onsystem = contact.app_type && !contact._newAppTypeAdded

                if (!groupedList[contact.company_id]) {
                    groupedList[contact.company_id] = {
                        company: {
                            company_id: contact.company_id,
                            company_name: contact.company_name,
                            app_type: contact.app_type,
                            logo: contact.company_logo ?? contact.logo ?? contact.company?.logo,
                            is_onsystem: is_onsystem,
                        },
                        invited: [],
                    }
                }

                groupedList[contact.company_id].invited.push(contact)
            })

            this.setGroupedCompaniesContactsList({ key: this.componentKey, value: groupedList })
        },
        updateOnItemContactsList (): void {
            const contactsList = cloneDeep(this.selectedContactsList)

            this.setOnItemContactsList({ key: this.componentKey, value: contactsList })

            this.$emit('updateOnItemContactsList', contactsList)
        },
        setAddContactStep (): void {
            if (this.isSearchValidEmail) {
                this.setActiveStep({ key: this.componentKey, value: StepsList.ADD_CONTACT_DETAILS })
            }
        },
        setActionsStep (): void {
            this.setActiveStep({ key: this.componentKey, value: StepsList.ACTIONS })
        },
        setMatchedAccountsStep (): void {
            this.setActiveStep({ key: this.componentKey, value: StepsList.MATCHED_ACCOUNTS })
        },
        setSelectWorkspaceStep (): void {
            if (this.isWorkspaceSelected) {
                this.setActiveStep({ key: this.componentKey, value: StepsList.MATCHED_DOMAINS })
            }

            if (this.isWorkspaceTypeSelected) {
                this.setActiveStep({ key: this.componentKey, value: StepsList.NOT_FOUND })
            }
        },
        setInitialStep (): void {
            this.setActiveStep({ key: this.componentKey, value: StepsList.INITIAL })
        },
        setSelectWorkspaceTypeStep (): void {
            this.setActiveStep({ key: this.componentKey, value: StepsList.SELECT_WORKSPACE_TYPE })
        },
        removeSelectedContactAndWorkspaceType (): void {
            this.resetSelectedWorkspaceType({ key: this.componentKey })
            this.processRemoveSelected(this.selectedContact)
            this.setInitialStep()
        },
        onSetAddWorkspaceTypeStepSaveAction (contact: Contact): void {
            this.saveContact({ id: contact.id, key: this.componentKey })
            this.setSelectedContactFromSelectedContacts({ key: this.componentKey, value: contact })
            this.setActiveStep({ key: this.componentKey, value: StepsList.ADD_WORKSPACE_TYPE })
        },
        onSetAddWorkspaceTypeStepSendAction (contact: Contact): void {
            this.inviteContact({ id: contact.id, key: this.componentKey })
            this.setSelectedContactFromSelectedContacts({ key: this.componentKey, value: contact })
            this.setActiveStep({ key: this.componentKey, value: StepsList.ADD_WORKSPACE_TYPE })
        },
        saveWorkspaceTypeForOffSystemCompany (): void {
            this.setSelectedContactWorkspaceType({ key: this.componentKey, value: this.selectedContact })
            this.resetSelectedContact({ key: this.componentKey })
            this.setInitialStep()
        },
        setSelectWorkspaceStepFromContactDetails (): void {
            this.setSelectWorkspaceStep()
            this.setContactFormData({ key: this.componentKey, data: null })
        },
        onSaveAddedContact (): void {
            this.saveOrInviteNewContact({
                key: this.componentKey,
                action: ContactActions.SAVE,
                filters: this.filters,
                resourceId: this.resourceId,
            }).then(() => {
                if (this.isAddingNewContact) {
                    this.saveSelectedContacts().then(() => {
                        console.log('here')
                    })
                }
            })
                .finally(() => this.fetchRecords())
        },
        onInviteAddedContact (): void {
            this.saveOrInviteNewContact({
                key: this.componentKey,
                action: ContactActions.SEND_INVITE,
                filters: this.filters,
                resourceId: this.resourceId,
            })

            this.$nextTick(() => {
                this.fetchRecords()
            })
        },
        onBackClickFromAddContactDetailsStep (): void {
            this.setActiveStep({
                key: this.componentKey,
                value: this.isWorkspaceSelected ? StepsList.MATCHED_DOMAINS : StepsList.NOT_FOUND,
            })
        },
        onBackClickFromActionsStep (): void {
            this.setActiveStep({
                key: this.componentKey,
                value: StepsList.NOT_FOUND,
            })
        },
        handleScroll (event: any): void {
            const scrollWindow = document.querySelector<HTMLElement>(this.scrollWrapperClass)
            const elementHeight = scrollWindow ? scrollWindow.offsetHeight : 0

            const currentScroll = event.target.scrollTop
            const scrollHeight = event.target.scrollHeight

            if (currentScroll > this.prevScroll) {
                this.prevScroll = currentScroll
            } else {
                this.prevScroll = currentScroll
                return
            }

            if (this.isLastPage) {
                return
            }

            if (this.isAddWorkspaceTypeStep) {
                return
            }

            // offset 10
            if (currentScroll >= scrollHeight - elementHeight - 10 && !this.isLoading) {
                this.$store.dispatch('inviteModal/fetchRecords', {
                    search: this.search,
                    key: this.componentKey,
                    filters: this.filters,
                    resourceId: this.resourceId,
                    showDirectoryOnly: this.showDirectoryOnly,
                    showEmployeesOnly: this.showEmployeesOnly,
                })
            }
        },
        processRemoveSelected (contact: Contact): void {
            if (!contact._addedFromList) {
                this.addRemovedContact({ contact, key: this.componentKey })
                this.removeSelected({ id: contact.id, key: this.componentKey })

                return
            }

            if (contact.invitation_status === this.invitationStatuses.SAVED
                || contact.invitation_status === this.invitationStatuses.READY_TO_SAVE) {
                this.removeContactToSave({ id: contact.id, key: this.componentKey })
            }

            if (contact.invitation_status === this.invitationStatuses.READY_TO_SEND) {
                this.removeContactToInvite({ id: contact.id, key: this.componentKey })
            }

            if (contact.invitation_status === this.invitationStatuses.READY_TO_REMIND) {
                this.removeContactToRemind({ id: contact.id, key: this.componentKey })
            }

            this.removeSelected({ id: contact.id, key: this.componentKey })
        },
        onInvitationRemind (id: number): void {
            this.invitationRemind({ id, key: this.componentKey })
        },
        async saveSelectedContacts (): Promise<void> {
            this.setLoadingBar(true)

            this.$emit('modalSaveInProgress')

            await this.saveContacts()
        },
        async saveContacts (): Promise<void> {
            const clonedContacts = cloneDeep(this.selectedContactsList)

            try {
                this.validateEmails()

                const response = await client.batchInvitations(this.selectedContactsToAction)
                this.setBatchResponse(response.data.successful)

                this.$emit('modalSaveSuccess', response.data.successful)
            } catch (error) {
                const data = error.response.data
                const failedContacts = []

                if (data.failed?.invites.length) {
                    data.failed.invites.forEach((contact) => {
                        failedContacts.push(contact.email)

                        const contactIndex = findIndex(clonedContacts, (item: Contact) => {
                            return item.id === contact.id
                        })

                        contactIndex && clonedContacts.splice(contactIndex, 1)
                    })
                }

                if (data.failed?.saves.length) {
                    data.failed.saves.forEach((contact) => {
                        failedContacts.push(contact.email)

                        const contactIndex = findIndex(clonedContacts, (item: Contact) => {
                            return item.id === contact.id
                        })

                        contactIndex && clonedContacts.splice(contactIndex, 1)
                    })
                }

                if (data.failed?.removes.length) {
                    data.failed.removes.forEach((contact) => {
                        failedContacts.push(contact.email)
                        clonedContacts.push(contact)
                    })
                }

                data.failed?.reminds.length && data.failed.reminds.forEach(contact => failedContacts.push(contact.email))

                if (failedContacts.length) {
                    this.showNotification('error', `${ this.$t('Invitation action could not be completed for the following email(s):') } ${ failedContacts.join(', ') }`)
                }

                if (error.response.data.message?.length) {
                    this.showNotification('error', error.response.data.message)
                }

                this.$emit('modalSaveError')
            } finally {
                this.setLoadingBar(false)

                this.$emit('modalSaveFinished')
                this.$emit('updateInvitePreviewData', clonedContacts)

                this.closePopup(true)
            }
        },
        validateEmails (): void {
            if (this.selectedContactsToAction.invite.length) {
                this.selectedContactsToAction.invite.forEach((contact) => {
                    if (!this.validateEmail(contact.email)) {
                        this.emailError('invites', contact)
                    }
                })
            }

            if (this.selectedContactsToAction.save.length) {
                this.selectedContactsToAction.save.forEach((contact) => {
                    if (!this.validateEmail(contact.email)) {
                        this.emailError('saves', contact)
                    }
                })
            }

            if (this.emailErrors.response.data.failed.invites.length || this.emailErrors.response.data.failed.saves.length) {
                throw this.emailErrors
            }
        },
        validateEmail (email: string): boolean {
            const regex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return regex.test(email)
        },
        emailError (errorType: string, contact: Contact): void {
            this.emailErrors.response.data.failed[errorType].push(contact)
        },
        onOnItemAccordionToggle (): void {
            this.isOnItemAccordionOpened = !this.isOnItemAccordionOpened
        },
        setContactsListsSelectedTabKey (): void {
            this.contactsListsSelectedTabKey = this.contactsListsTabs[this.contactsListsSelectedTab].key
        },
        onContactsListsTabChange (index: number): void {
            if (this.contactsListsTabs.length === 1) {
                return
            }

            this.contactsListsSelectedTab = index
            this.setContactsListsSelectedTabKey()
            this.clearSearchResults({ key: this.componentKey })
            this.setLoading({ key: this.componentKey, value: true })
            this.onSearchChange(this.search, false, true)
        },
        setBatchResponse (response: ContactToActionResponse): void {
            if (this.isProjectDirectoryCompanies) {
                this.setSelectedAddedContactsProjectDirectory({
                    key: this.componentKey,
                    value: response,
                })
            }
        },
    },
})
