import { RouteLocationNormalized } from 'vue-router'

const routes = {
    path: 'project-cost-codes/v3',
    redirect: { name: 'project-cost-codes-list' },
    children: [
        {
            path: '',
            name: 'project-cost-codes-list',
            component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-codes-main/ProjectCostCodesMain.vue'),
            redirect: { name: 'my-project-cost-codes' },
            children: [
                {
                    path: 'my-cost-codes',
                    name: 'my-project-cost-codes',
                    component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-codes-main/my-project-cost-codes/MyProjectCostCodes.vue'),
                    meta: {
                        name: 'My Project Cost Codes',
                        title: 'My Project Cost Codes',
                        navigationKey: 'my_project_cost_codes_v3',
                    },
                },
                {
                    path: 'shared-cost-codes',
                    name: 'shared-project-cost-codes',
                    component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-codes-main/shared-cost-codes/SharedCostCodes.vue'),
                    meta: {
                        name: 'Shared Cost Codes',
                        title: 'Shared Cost Codes',
                        navigationKey: 'shared_project_cost_codes_v3',
                    },
                },
            ],
        },
        {
            path: ':id/:tab?',
            name: 'project-cost-code-list-detail',
            component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-code-list-edit/ProjectCostCodeListEdit.vue'),
            meta: {
                name: 'Project Cost Code List Detail',
                title: 'Project Cost Code List Detail',
                navigationKey: 'project_cost_code_list_detail_v3',
            },
            props: true,
        },
        {
            path: 'shared/:id/:tab?',
            name: 'shared-cost-code-list-detail',
            component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-code-list-edit/ProjectCostCodeListEdit.vue'),
            meta: {
                name: 'Shared Cost Code List Detail',
                title: 'Shared Cost Code List Detail',
                navigationKey: 'shared_cost_code_list_detail_v3',
            },
            props: (route: RouteLocationNormalized) => ({
                ...route.params,
                isShared: true,
            }),
        },
        {
            path: ':id/edit/:tab?',
            name: 'project-cost-code-list-edit',
            component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-code-list-edit/ProjectCostCodeListEdit.vue'),
            meta: {
                name: 'Project Cost Code List Edit',
                title: 'Project Cost Code List Edit',
                navigationKey: 'project_cost_code_list_edit_v3',
            },
            props: (route: RouteLocationNormalized) => ({
                ...route.params,
                editMode: true,
            }),
        },
        {
            path: 'shared/:id/edit/:tab?',
            name: 'shared-cost-code-list-edit',
            component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-code-list-edit/ProjectCostCodeListEdit.vue'),
            meta: {
                name: 'Shared Cost Code List Edit',
                title: 'Shared Cost Code List Edit',
                navigationKey: 'shared_cost_code_list_edit_v3',
            },
            props: (route: RouteLocationNormalized) => ({
                ...route.params,
                editMode: true,
                isShared: true,
            }),
        },
        {
            path: ':listId/template',
            name: 'project-cost-code-template',
            component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-code-template-detail/ProjectCostCodeTemplateDetail.vue'),
            meta: {
                name: 'Project Cost Code Template',
                title: 'Project Cost Code Template',
                navigationKey: 'project_cost_code_template_v3',
            },
            props: true,
        },
        {
            path: 'template/:id?',
            name: 'project-cost-code-template-edit',
            component: () => import('@/io-modules/lists-templates/project-cost-codes/views/project-cost-code-template-detail/ProjectCostCodeTemplateDetail.vue'),
            meta: {
                name: 'Project Cost Code Template',
                title: 'Project Cost Code Template',
                navigationKey: 'project_cost_code_template_edit_v3',
            },
            props: true,
        },
    ],
}

export default routes
