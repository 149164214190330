<template>
    <TableRow
        :injected="false"
        :item="item"
        :headers="headers"
        :nestedRowsIndicator="nestedRowsIndicator"
        :levelIndex="levelIndex"
        :index="index"
        :topShadow="topShadow"
        :bottomShadow="bottomShadow"
        :parentLevelIndex="parentLevelIndex"
        :parentItem="parentItem"
        :classes="classes"
        :isExpanded="isExpanded"
        :itemIdentifier="itemIdentifier"
        @click="toggleRow"
        @click-edit-item="handleClickEditItem"
        @inputClicked="emitInputClick"
    />
    <template v-for="(inject, injectIndex) in itemInjects" :key="`inject-for-${item[itemIdentifier]}-${injectIndex}`">
        <TableRow
            :injected="true"
            :item="inject"
            :headers="headers"
            :levelIndex="levelIndex"
            :index="index"
            :classes="classes"
            :isExpanded="isExpanded"
            :itemIdentifier="itemIdentifier"
        />
    </template>
    <template v-if="hasNestedRows">
        <template v-for="(subItem, subIndex) in item[nestedRowsIndicator]" :key="subItem[itemIdentifier]">
            <TableRowContainer
                :item="subItem"
                :headers="headers"
                :nestedRowsIndicator="nestedRowsIndicator"
                :levelIndex="`${ levelIndex }.${ subIndex + 1 }`"
                :parentLevelIndex="levelIndex"
                :parentItem="item"
                :index="subIndex"
                :classes="isExpanded ? 'io-visible-row' : 'io-hidden-row'"
                :topShadow="subIndex === 0"
                :bottomShadow="item[nestedRowsIndicator].length > 1 && subIndex === item[nestedRowsIndicator].length - 1"
                :rowsToExpand="rowsToExpand"
                :itemIdentifier="itemIdentifier"
                @expandRowId="sendRowsToExpand"
                @click-edit-item="handleClickEditItem"
                @inputClicked="emitInputClick"
            />
        </template>
    </template>
</template>

<script lang="ts" src="./TableRowContainer.ts"></script>
