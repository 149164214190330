import { defineComponent } from 'vue'
import PageHeader from '@/components/page-header/PageHeader.vue'
import ProjectInformation from '../components/project-information/ProjectInformation.vue'
import ProjectSummaryNav from '../components/project-summary-nav/ProjectSummaryNav.vue'
import ProjectExclusions from '../components/project-exclusions/ProjectExclusions.vue'
import ProjectScope from '../components/project-scope/ProjectScope.vue'
import breadcrumbsMixin from '@/mixins/breadcrumbs/breadcrumbsMixin.ts'
import { Breadcrumb } from '@/store/app/breadcrumbs.ts'
import projectObjMixin from '@/modules/projects/mixins/projectObjMixin.js'
import { mapState } from 'vuex'
import projectSummaryClient from '../api-clients/projectSummaryClient.ts'
import { paths } from '@/interfaces/api/apidefinitions'
export type ProjectSummary = paths['api/v1/{subdomain}/{apptype}/project-summary/{id}']['get']['responses']['200']['content']['application/json']

export default defineComponent({
    components: {
        PageHeader,
        ProjectInformation,
        ProjectSummaryNav,
        ProjectExclusions,
        ProjectScope,
    },
    mixins: [breadcrumbsMixin, projectObjMixin],
    data () {
        return {
            summaryData: null as ProjectSummary | null,
            rendered: false,
        }
    },
    computed: {
        ...mapState('project', {
            projectObj: (state: any) => state.projectObj,
        }),
        breadcrumbs (): Breadcrumb[] {
            return [
                {
                    text: this.$t('Projects'),
                    to: { name: 'project-list' },
                },
                {
                    text: this.projectObj._project_name || this.projectObj._name || this.$t('Project'),
                    to: { name: 'project-dashboard', params: { pid: this.$route.params.pid } },
                },
                {
                    text: this.$t('Project Summary'),
                    to: {
                        name: 'project-summary-v2',
                        params: { pid: this.$route.params.pid },
                    },
                },
            ]
        },
    },
    watch: {
        projectObj (): void {
            this.loadBreadcrumbs()
        },
    },
    async created (): Promise<void> {
        await this.loadProject()
        await this.fetchSummaryData()
        this.loadBreadcrumbs()
    },
    methods: {
        loadBreadcrumbs (): void {
            this.setBreadcrumbs(this.breadcrumbs)
        },
        async fetchSummaryData (): void {
            this.setLoadingBar(true)
            try {
                const { data } = await projectSummaryClient.getSummary(this.$route.params.pid)
                this.summaryData = data
            } catch (error) {
                this.errorHandle(error)
            } finally {
                this.setLoadingBar(false)
                this.rendered = true
            }
        }
    }
})
