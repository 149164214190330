<template>
    <div
        class="io-design-system io-atoms-checkbox"
        data-testid="checkbox-component"
        :class="{
            'io-minus': partialCheck,
            'io-atoms-checkbox__checked': isChecked,
            'io-atoms-checkbox__no-margins': noMargins,
        }"
    >
        <label>
            <input
                :id="inputName"
                type="checkbox"
                data-testid="checkbox-input"
                :disabled="disabled"
                :value="checkboxValue"
                v-model="value"
                @click="$emit('click')"
            />
            <span v-if="withSlot" :class="classSpan"><slot/></span>
            <span v-else :class="classSpan">{{ labelText }}</span>
        </label>
    </div>
</template>

<script lang="ts" src="./Checkbox.ts"></script>
<style lang="scss" src="./Checkbox.scss" scoped/>
