import { defineComponent, PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import IOButton from '@/components/atoms/IOButton/IOButton.vue'

export interface Button {
    name: string | TranslateResult
    id?: string
    icon?: string
    url?: string
    customAction?: string
    disabled?: boolean
    onSubmitName?: string
    callback?: () => void
    className?: string
    variant?: string
}

export default defineComponent({
    name: 'Placeholder',
    components: {
        IOButton,
    },
    props: {
        title: { type: [ String, Object as () => TranslateResult ] },
        subtitle: { type: [ String, Object as () => TranslateResult ] },
        size: { type: String, default: 'small' },
        icon: { type: String, default: 'icon-inbox' },
        iconColor: { type: String, default: '' },
        displayIcon: { type: Boolean, default: true },
        buttonsCta: { type: Object as PropType<Array<Button>>, default: () => [] },
        textSize: { type: String as PropType<'small' | 'large'>, default: 'default' }
    },
    methods: {
        buttonClass (button: Button): string {
            if (button.className) {
                return button.className
            }
            return ''
        },
        buttonVariant (button: Button, index: number): string {
            if (button?.variant) {
                return button.variant
            }

            return !index ? 'primary' : 'secondary'
        },
        handleButtonClick (button: Button): void {
            if (button.url) {
                this.$router.push(button.url)
            } else if (button.customAction) {
                this.$emit(button.customAction)
            } else if (button.onSubmitName) {
                this.$parent[button.onSubmitName]()
            } else if (button.callback) {
                button.callback()
            }
        }
    }
})
