<template>
    <span class="io-sort">
        <template v-if="sortBy === name">
            <span class="icon-chevron-down" v-if="sortDir === 'desc'"></span>

            <span class="icon-chevron-up" v-else></span>
        </template>
        <template v-else>
            <span class="icon-sort"></span>
        </template>
    </span>
</template>

<script lang="ts" src="./TableSortIcon.ts"></script>
<style lang="scss" scoped src="./TableSortIcon.scss"></style>
