<template>
    <div class="io-modal-types-holder">
        <!-- first option -->
        <div v-if="false" class="io-select-type io-f-column io-cant-select">
            <div class="io-f-row io-select-row">
                <div class="io-choose-contract-icon io-choose-contract-icon-first"></div>
                <div class="io-text-type">
                    <h3>{{ $t('Digital Contracts') }}</h3>
                    <p>{{ $t('Coming soon') }}</p>
                </div>
                <i class="fas fa-ban io-select-icon io-ban-icon"></i>
            </div>
        </div>

        <!-- second option -->
        <div v-if="showManualOption" class="io-select-type io-f-column" v-on:click.stop="$emit('upload-contract')">
            <div class="io-f-row io-select-row">
                <div class="io-choose-contract-icon">
                    <i class="far fa-file"></i>
                </div>
                <div class="io-text-type">
                    <h3>{{ $t('Manual Contract') }}</h3>
                    <p>{{ $t('Start a project with a executed contract completed outside of IngeniousIO') }}</p>
                </div>
                <i class="fas io-select-icon fa-chevron-right"></i>
            </div>
        </div>

        <!-- third option -->
        <div class="io-select-type io-f-column" v-on:click.stop="$emit('skip-contract')">
            <div class="io-f-row io-select-row">
                <div class="io-choose-contract-icon">
                    <i class="fas fa-rocket"></i>
                </div>
                <div class="io-text-type">
                    <h3>{{ $t('Without Contract') }}</h3>
                    <p>{{ $t('Start new project without contract and kick off the project in feasibility mode') }}</p>
                </div>
                <i class="fas io-select-icon fa-chevron-right"></i>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'StepSelectContractType',
        props: {
            showManualOption: {
                type: Boolean,
                required: false,
                default: false,
            }
        }
    })
</script>

<style lang="scss" src="./StepSelectContractType.scss" scoped></style>
