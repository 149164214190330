import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import InvoicesListResponse from '../interfaces/responses/InvoicesListResponse'
import ListType from '../enums/ListType'
import Invoice from '../interfaces/Invoice'
import VendorDetails from '../interfaces/VendorDetails'
import ManageColumnsSettings from '../interfaces/invoices-list/ManageColumnsSettings'
import DataResponse from '@/interfaces/DataResponse'
import AppPackagesListResponse from '../interfaces/responses/AppPackagesListResponse'
import ListFilters from '../interfaces/invoices-list/ListFilters'
import qs from '@/helpers/qs'
import ListFiltersListingParams from '@/io-modules/invoices/interfaces/invoices-list/ListFiltersListingParams'
import ListingTotals from '@/io-modules/invoices/interfaces/invoices-list/ListingTotals.ts'

export default {
    getReceivedInvoicesListV3 (projectMongoIdLocal: string, filters: ListFiltersListingParams = {}, listParams: object): AxiosPromise<InvoicesListResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/listing/received-invoice/${ projectMongoIdLocal }`, {
            params: { filters, ...listParams },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    getMyInvoicesListV3 (projectMongoIdLocal: string, filters: ListFiltersListingParams = {}, listParams: object): AxiosPromise<InvoicesListResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/listing/my-invoice/${ projectMongoIdLocal }`, {
            params: { filters, ...listParams },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    getSharedInvoicesListV3 (projectMongoIdLocal: string, filters: ListFiltersListingParams = {}, listParams: object): AxiosPromise<InvoicesListResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/listing/shared-invoice/${ projectMongoIdLocal }`, {
            params: { filters, ...listParams },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    getApplicationPackagesListV3 (projectMongoIdLocal: string, filters: ListFiltersListingParams = {}, listParams: object): AxiosPromise<AppPackagesListResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/application-packages/${ projectMongoIdLocal }`, {
            params: { filters, ...listParams },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    getManageColumnsSettings (projectMongoIdLocal: string, type: ListType): AxiosPromise<DataResponse<ManageColumnsSettings>> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!type) {
            throw new Error('No Invoices Type Provided!')
        }

        return client.get(`/manage-columns/${ projectMongoIdLocal }/${ type }_invoices`)
    },

    setManageColumnsSettings (projectMongoIdLocal: string, type: ListType, settings: ManageColumnsSettings): AxiosPromise<DataResponse<ManageColumnsSettings>> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!type) {
            throw new Error('No Invoices Type Provided!')
        }

        if (!settings) {
            throw new Error('No Settings Provided!')
        }

        return client.put(`/manage-columns/${ projectMongoIdLocal }/${ type }_invoices`, {
            manage_columns: settings
        })
    },

    getReceivedInvoicesVendorsV3 (projectMongoIdLocal: string, filters: ListFiltersListingParams = {}): AxiosPromise<VendorDetails[]> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/vendors`, {
            params: { filters },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    getVendorInvoicesV3 (projectMongoIdLocal: string, vendorId: string, filters: ListFiltersListingParams = {}, listParams: object): AxiosPromise<DataResponse<Invoice[]>> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }
        if (!vendorId) {
            throw new Error('No Vendor ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/vendors/${ vendorId }`, {
            params: { filters, ...listParams },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        })
    },

    getVendorDetailsV3 (projectMongoIdLocal: string, vendorId: string): AxiosPromise<DataResponse<VendorDetails>> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }
        if (!vendorId) {
            throw new Error('No Vendor ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/vendors/${ vendorId }/details`)
    },

    getInvoicesListFiltersV3 (projectMongoIdLocal: string, type: ListType): DataResponse<ListFilters> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!type) {
            return
        }

        return client.get(`invoices/default-filters/${ type }-invoice-listing/${ projectMongoIdLocal }`)
    },

    getApplicationPackagesListFiltersV3 (projectMongoIdLocal: string): DataResponse<ListFilters> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`application-packages/${ projectMongoIdLocal }/view`)
    },

    getInvoicesListTotals (projectMongoIdLocal: string, listingType: string): DataResponse<ListingTotals> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!listingType) {
            throw new Error('No Listing Type Provided!')
        }

        return client.get(`invoices/totals/${ listingType }/${ projectMongoIdLocal }`)
    },

    getAppPackageListTotals (projectMongoIdLocal: string): DataResponse<ListFilters> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`application-packages/totals/${ projectMongoIdLocal }`)
    }
}
