import { defineComponent, PropType } from 'vue'
import Table from '@/components/table/Table.vue'
import LineItemRow from '../line-item-row/LineItemRow.vue'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import { mapState, mapActions } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import NonContractedLineItem from '@/io-modules/invoices/interfaces/NonContractedLineItem'
import Draggable from 'vuedraggable'
import useCostCodeCategories from '@/components/budget-cost-code-select/composables/useCostCodeCategories.ts'
import ListHeader from '@/io-modules/invoices/components/invoice-view/sov-wbs/list-header/ListHeader.vue'
import type { Category } from '@/components/budget-cost-code-select/BudgetCostCodeInterface.ts'

export default defineComponent({
    name: 'LineItemsTable',
    components: {
        Table,
        LineItemRow,
        Draggable,
        ListHeader,
    },
    props: {
        lineItems: {
            type: Array as PropType<NonContractedLineItem[]>,
            default: () => [],
        },
        siteId: String,
    },
    emits: ['addNewRow', 'removeItem'],
    setup () {
        const { getProjectCostCodeCategories } = useCostCodeCategories()

        return { getProjectCostCodeCategories }
    },
    computed: {
        ...mapState(invoiceViewStore, ['isEditingMode', 'projectBudgetCostCodes', 'projectBudgetCostCodesSites', 'invoice']),

        ...mapStateVuex('project', ['projectObj']),

        providedCostCodes (): Category[] {
            if (this.siteId) {
                return this.projectBudgetCostCodesSites[this.siteId] || []
            } else {
                return this.projectBudgetCostCodes || []
            }
        },

        headers (): HeaderTable[] {
            const allHeaders = [
                {
                    text: '',
                    visible: this.isEditingMode,
                    sort: false,
                },
                {
                    text: this.$t('Description'),
                    visible: true,
                    sort: false,
                    extraStyles: 'min-width: 370px;',
                    asterisk: true,
                },
                {
                    text: this.$t('Project Cost Code'),
                    visible: true,
                    sort: false,
                    extraStyles: 'min-width: 280px;',
                    asterisk: true,
                },
                {
                    text: this.$t('Qty'),
                    visible: true,
                    sort: false,
                    align: this.isEditingMode ? 'start' : 'end',
                    extraStyles: 'width: 180px;',
                    asterisk: true,
                },
                {
                    text: this.$t('Unit Cost'),
                    visible: true,
                    sort: false,
                    align: 'end',
                    extraStyles: 'min-width: 180px;',
                    asterisk: true,
                },
                {
                    text: this.$t('Total'),
                    visible: true,
                    sort: false,
                    align: 'end',
                    extraStyles: 'min-width: 180px;',
                },
                {
                    text: '',
                    visible: this.isEditingMode,
                    sort: false,
                },
            ]

            return allHeaders.filter(item => item.visible)
        },

        lineItemsTotal (): number {
            return this.lineItems.reduce((prevValue, currItem) => prevValue + currItem.total, 0) / process.env.SCALE_FACTOR
        },
    },
    watch: {
        isEditingMode (): void {
            if (this.isEditingMode) {
                this.fetchCostCodes()

                if (!this.lineItems.length) {
                    this.$emit('addNewRow')
                }
            }
        }
    },
    mounted () {
        if (this.isEditingMode) {
            this.fetchCostCodes()

            if (!this.lineItems.length) {
                this.$emit('addNewRow')
            }
        }
    },
    methods: {
        ...mapActions(invoiceViewStore, ['setDataLoadingValue', 'setProjectBudgetCostCodes']),

        async fetchCostCodes (): Promise<void> {
            if (this.projectBudgetCostCodes.length || this.projectBudgetCostCodesSites[this.siteId]?.length) {
                return
            }

            try {
                this.setDataLoadingValue(true)
                const { data } = await this.getProjectCostCodeCategories(this.projectObj.project_local_id, this.invoice.paid_by, this.siteId)
                this.setProjectBudgetCostCodes(data, this.siteId)
            } catch (error) {
                this.errorHandleNoRedirect(error)
            } finally {
                this.setDataLoadingValue(false)
            }
        },
    }
})
