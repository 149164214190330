import { defineComponent } from 'vue'
import IOLabel from '@/components/atoms/IOLabel/IOLabel.vue'
import IOInput from '@/components/atoms/IOInput/IOInput.vue'
import { LabelType } from '@/constants/LabelConstants'

export default defineComponent({
    name: 'FormGroup',
    components: {
        IOLabel,
        IOInput,
    },
    props: {
        label: { type: String, default: '' },
        labelType: { type: String as () => LabelType, required: false },
        placeholder: { type: String, default: '' },
        isRequired: { type: Boolean, default: false },
        modelValue: { type: [String, Number] },
        valid: { type: Boolean, default: true },
        errorMessage: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
        maxLength: { type: Number, default: null },
        inputType: { type: String, default: 'text' },
        autocompleteItems: { type: Array as () => unknown[], default: () => [] },
        readonly: { type: Boolean },
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get (): string | number {
                return this.modelValue
            },
            set (value: string): void {
                this.$emit('update:modelValue', value)
            },
        },
    },
})
