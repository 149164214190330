<template>
    <div
        class="io-files-listing"
        :class="{
            'io-hide-shared': !(!fromTrash && !hideShareColumn),
            'grid-view': isGridView,
            'list-view': !isGridView,
            'io-dragging-over': isDraggingOver,
            'io-is-inside-modal': showModalVersion,
            'io-variant-skinny': isSkinnyVariant,
        }"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
    >
        <div
            v-if="!uploadLocked"
            class="io-drag-area"
            @drop="onDrop"
            @dragover.prevent
            @dragenter="onDragEnter"
            @dragleave="onDragLeave"
        ></div>
        <div v-if="!isGridView && showListViewTable" class="io-file-head-row">
            <!--            <div class="io-file-head-row__box io-f-center" v-if="!folders.length && my_documents_files && my_documents_files.length"></div>-->
            <div class="io-file-head-row__box io-check-all-files">
                <Checkbox
                    v-if="isCheckboxVisible"
                    v-model="mainCheckbox"
                    :checkboxValue="mainCheckbox"
                    :partialCheck="partialSelectedCheck"
                />
            </div>
            <template v-if="!isSkinnyVariant">
                <div class="io-file-head-row__box">
                    <div class="io-file-head-row__text" @click="changeSort('file_name')">{{ $t('File') }}
                        <span class="io-file-head-row__sort">
                            <template v-if="sortBy === 'file_name'">
                                <i v-if="sortDir === 'asc'" class="fas fa-sort-down io-text-dark"></i>
                                <i v-else class="fas fa-sort-up io-text-dark"></i>
                            </template>
                            <template v-else>
                                <i class="fas fa-sort"></i>
                            </template>
                        </span>
                    </div>
                </div>
                <div v-if="!showModalVersion" class="io-file-head-row__box">
                    <div class="io-file-head-row__text" @click="changeSort('creator_full_name')">{{ $t('Owner') }}
                        <span class="io-file-head-row__sort">
                            <template v-if="sortBy === 'creator_full_name'">
                                <i v-if="sortDir === 'asc'" class="fas fa-sort-down io-text-dark"></i>
                                <i v-else class="fas fa-sort-up io-text-dark"></i>
                            </template>
                            <template v-else>
                                <i class="fas fa-sort"></i>
                            </template>
                        </span>
                    </div>
                </div>
                <div class="io-file-head-row__box">
                    <div class="io-file-head-row__text" @click="changeSort('created_at')">{{ $t('Uploaded') }}
                        <span class="io-file-head-row__sort">
                            <template v-if="sortBy === 'created_at'">
                                <i v-if="sortDir === 'asc'" class="fas fa-sort-down io-text-dark"></i>
                                <i v-else class="fas fa-sort-up io-text-dark"></i>
                            </template>
                            <template v-else>
                                <i class="fas fa-sort"></i>
                            </template>
                        </span>
                    </div>
                </div>
                <div v-if="!showModalVersion" class="io-file-head-row__box">
                    <div class="io-file-head-row__text" @click="changeSort('file_size')">{{ $t('Size') }}
                        <span class="io-file-head-row__sort">
                            <template v-if="sortBy === 'file_size'">
                                <i v-if="sortDir === 'asc'" class="fas fa-sort-down io-text-dark"></i>
                                <i v-else class="fas fa-sort-up io-text-dark"></i>
                            </template>
                            <template v-else>
                                <i class="fas fa-sort"></i>
                            </template>
                        </span>
                    </div>
                </div>

                <template v-for="(column, index) in customColumns" :key="index">
                    <div
                        class="io-file-head-row__box"
                    >
                        <div class="io-file-head-row__text" @click="changeSort(column)">{{ $t(`files_${ column }`) }}
                            <span class="io-file-head-row__sort">
                            <template v-if="sortBy === column">
                                <i v-if="sortDir === 'asc'" class="fas fa-sort-down io-text-dark"></i>
                                <i v-else class="fas fa-sort-up io-text-dark"></i>
                            </template>
                            <template v-else>
                                <i class="fas fa-sort"></i>
                            </template>
                        </span>
                        </div>
                    </div>
                </template>
            </template>

            <div class="io-file-head-row__box"></div>
        </div>

        <template v-if="uploadingFiles && uploadingFiles.length && tabType === 'myFiles'">
            <template v-if="!isGridView">
                <UploadingListItem
                    v-for="item in uploadingFiles"
                    :key="item.tempId"
                    :item="item"
                    :showModalVersion="showModalVersion"
                />
            </template>
        </template>

        <div v-if="showNewFolderForm">
            <SingleFolder
                :arrayId="arrayId"
                :folder="newFolder"
                :isProjectView="isProjectView"
                :isDocumentsView="isDocumentsView"
                :isCreateMode="true"
            />
        </div>

        <!--show folders-->
        <template v-if="searchPathVal && searchPathVal.length > 1">
            <template v-if="foldersSearchPaths && foldersSearchPaths.length">
                <template v-for="(folder, idx) in foldersSearchPaths" :key="folder._id">
                    <single-folder
                        :idx="idx"
                        :arrayId="arrayId"
                        :isSearchResult="true"
                        :folder="folder"
                        @clearFilters="clearSearch"
                        :isProjectView="isProjectView"
                        :hasFiles="hasFiles"
                        :isDocumentsView="isDocumentsView"
                    ></single-folder>
                </template>
            </template>
            <template v-else-if="isSearching">
                <p class="io-no-records">{{ $t('Fetching data') }}</p>
            </template>
            <template v-else>
                <p class="io-no-records">{{ $t('No records found') }}</p>
            </template>
        </template>
        <template v-else>
            <!--breadcrumbs-->
            <template v-if="foldersBreadcrumbs && foldersBreadcrumbs.length && !isEmpty(folderFlags) && folderFlags._id && !lockBreadcrumbs">
                <single-folder
                    v-if="foldersBreadcrumbs[foldersBreadcrumbs.length - 1].folder_id && foldersBreadcrumbs[foldersBreadcrumbs.length - 1]._id !== folderFlags._id"
                    :key="breadCrumbFolderKey"
                    :isBreadcrumb="true"
                    :arrayId="arrayId"
                    :hasFiles="hasFiles"
                    :isProjectView="isProjectView"
                    :isDocumentsView="isDocumentsView"
                />
            </template>
            <template v-if="sortedFolders && sortedFolders.length">
                <template v-for="(folder, idx) in sortedFolders" :key="folder._id">
                    <single-folder
                        :idx="idx"
                        :arrayId="arrayId"
                        :folder="folder"
                        @clearFilters="clearSearch"
                        :isProjectView="isProjectView"
                        :hasFiles="hasFiles"
                        :isDocumentsView="isDocumentsView"
                    ></single-folder>
                </template>
            </template>
            <template v-if="showFiles">
                <template v-if="isGridView">
                    <div
                        class="io-grid-select-container"
                    >
                        <template v-if="uploadingFiles && uploadingFiles.length && tabType === 'myFiles'">
                            <UploadingGridItem
                                v-for="item in uploadingFiles"
                                :key="item.tempId"
                                :item="item"
                                :showModalVersion="showModalVersion"
                            />
                        </template>
                        <template v-for="(file, idx) in sortedArray" :key="file._id">
                            <FileUploaderGridItem
                                :idx="idx"
                                :arrayId="arrayId"
                                :addToTemporary="addToTemporary"
                                :file="file"
                                :toSync="toSync"
                                :fromTrash="fromTrash"
                                :showShared="showShared"
                                :lockHotkeys="lockHotkeys"
                                :hideShareColumn="hideShareColumn"
                                :forceShared="forceShared"
                                :modelId="modelId"
                                :filesInNewTab="filesInNewTab"
                                :modelType="modelType"
                                :isProjectView="isProjectView"
                                :editable="editable"
                                :editableActions="editableActions"
                                :showImportantToggle="showImportantToggle"
                                :ref="`item-${idx}`"
                                :showModalVersion="showModalVersion"
                                class="io-selectable-item"
                                @refreshData="onDataRefresh"
                                @tempFileRemove="onTempRemove"
                                @fileRemove="onRemove"
                                @selectFilesOnRange="onSelectFilesRange"
                                @hideAllDropdowns="onHideAllDropdowns"
                            />
                        </template>
                    </div>
                    <Teleport to=".io-main-container" :disabled="!showModalVersion">
                        <div v-if="isSelecting" class="io-selection-box" :class="{ 'io-in-modal': showModalVersion }" :style="selectionBoxStyles"></div>
                    </Teleport>
                </template>
                <template v-else>
                    <template v-for="(file, idx) in sortedArray" :key="file._id">
                        <SingleFile
                            :idx="idx"
                            :arrayId="arrayId"
                            :addToTemporary="addToTemporary"
                            :file="file"
                            :toSync="toSync"
                            :fromTrash="fromTrash"
                            :showShared="showShared"
                            :lockHotkeys="lockHotkeys"
                            :hideShareColumn="hideShareColumn"
                            :forceShared="forceShared"
                            :modelId="modelId"
                            :filesInNewTab="filesInNewTab"
                            :modelType="modelType"
                            :isProjectView="isProjectView"
                            :editable="editable"
                            :editableActions="editableActions"
                            :showImportantToggle="showImportantToggle"
                            :showModalVersion="showModalVersion"
                            :variant="variant"
                            @refreshData="onDataRefresh"
                            @tempFileRemove="onTempRemove"
                            @fileRemove="onRemove"
                            @selectFilesOnRange="onSelectFilesRange"
                        />
                    </template>
                </template>
            </template>
            <template v-else-if="isEmpty(folderFlags) && !uploadingFiles.length">
                <template v-if="tabType === 'sharedFiles' && !searchKeyword.length">
                    <div class="io-files-placeholder io-no-shared">
                        <div class="io-files-placeholder__row">
                            <div class="io-files-placeholder__icon">
                                <span class="io-files-placeholder__icon icon-cloud-cross"></span>
                            </div>
                            <div class="io-files-placeholder__text">
                                <h3>{{ $t('Nothing here yet!') }}</h3>
                                <p>{{ $t("No files have been shared with you. You can upload your files in the 'My Files' section.") }}</p>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="searchKeyword.length">
                    <div class="io-files-placeholder">
                        <div class="io-files-placeholder__row">
                            <div class="io-files-placeholder__icon">
                                <span class="io-files-placeholder__icon icon-search"></span>
                            </div>
                            <div class="io-files-placeholder__text">
                                <h3>{{ $t('No File Found') }}</h3>
                                <p>{{ $t('Try a different name or check spelling.') }}</p>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </template>
    </div>
</template>
<script src="./tab.js"></script>
<style src="./tab.scss" lang="scss" scoped></style>
<style src="./tabNoScoped.scss" lang="scss" scoped></style>
