<template>
    <div
        class="io-stamp-preview"
        :class="[
            `io-stamp-preview--${ this.size }`,
            { 'io-stamp-preview--button-mode': buttonMode },
            { 'io-stamp-preview--active': isActive },
            { 'io-stamp-preview--disabled': disabled },
        ]"
        :style="{ color: this.color }"
        @click="buttonMode && this.$emit('toggleActive')"
    >
        <div class="io-stamp-preview__icon" :style="{ color: this.color }">
            <template v-if="initials">{{ initials }}</template>

            <i v-if="buttonMode && !initials" class="icon-plus" />
        </div>

        <div v-if="title" class="io-stamp-preview__title">{{ title }}</div>
    </div>
</template>

<script lang="ts" src="./StampPreview.ts"></script>
<style scoped lang="scss" src="./StampPreview.scss"></style>
