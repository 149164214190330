import financialApprovalsClient from '@/api/financial-approvals/financialApprovalsClient'
import financialApprovalWorkflowsClient from '@/api/financial-approvals/financialApprovalWorkflowsClient'
import { ApprovalSubmissionParams } from '@/interfaces/modules/projects/financial-approvals/ApprovalSubmissionParams'
import FinancialApprovalsMutations from '@/constants/store/financial-approvals/FinancialApprovalsMutations'
import ApprovalPhasesResourceRequestData from '@/interfaces/modules/projects/financial-approvals/ApprovalPhasesResourceRequestData'
import ApprovalPhaseDeleteNewestRequestData
    from '@/interfaces/modules/projects/financial-approvals/ApprovalPhaseDeleteNewestRequestData'
import ExpiredInsuranceConsequence from '@/constants/ExpiredInsuranceRequirementConsequenceEnum'
import invoiceSettingsClient from '@/api/invoice-settings/invoice-settings'
import { Resource } from '@/interfaces/modules/projects/financial-approvals/Resource'
import financialApprovalSubmissionClient from '@/api/financial-approvals/financialApprovalSubmissionClient.ts'
import financialApprovalPhasesClient from '@/api/financial-approvals/financialApprovalPhasesClient.ts'

export async function getPhasePreview ({ commit }, phaseId: string): Promise<void> {
    const { data } = await financialApprovalPhasesClient.getSingleApprovalPhase(phaseId)

    commit(FinancialApprovalsMutations.SET_PHASE, data)
    commit('SET_MANUAL_APPROVAL', data.manualApprove)
    commit('SET_MANUAL_REJECTION', data.manualRejection)
}

export async function getSharedPhasePreview ({ commit }, phaseId: string): Promise<void> {
    const { data } = await financialApprovalPhasesClient.getSharedSingleApprovalPhase(phaseId)

    commit(FinancialApprovalsMutations.SET_PHASE, data)
    commit('SET_MANUAL_APPROVAL', data.manualApprove)
    commit('SET_MANUAL_REJECTION', data.manualRejection)
}

export async function getApprovalSubmissions ({ commit, dispatch }, params: ApprovalSubmissionParams): Promise<void> {
    if (!params.project_company_id || !params.approvable_resource_name || !params.resource_id) {
        return
    }

    commit('SET_RESOURCE_ID', params.resource_id)

    try {
        const { data } = await financialApprovalSubmissionClient.getApprovalSubmissions(params)

        if (data) {
            commit('SET_ACTIVE_RESOURCE', params.approvable_resource_name)
            commit('SET_APPROVAL_SUBMISSION_ID', data._id)
            commit('SET_COMPANY_PROJECT_RESOURCE_ID', data.company_project_approvable_resource_id)
            commit('SET_APPROVAL_SUBMISSION_COMPANY_ID', data.company_id)
            commit('SET_COST_CODES_ENABLED', data.cost_codes_enabled)
            commit('SET_APPROVAL_WORKFLOWS', data.workflows)
            commit('SET_SUBMITTED_FOR_APPROVAL', data.submitted_for_approval)
            commit('SET_CLIENT_APPROVAL_SETTING', data.client_approval_setting)
            commit('SET_EXTERNAL_CLIENT_APPROVAL', data.external_client_approval)
            commit('SET_MANUAL_APPROVAL', data.approval_details?.approvals.manual)
            commit('SET_MANUAL_REJECTION', data.approval_details?.rejections.manual)
            commit('SET_CLIENT_REJECTION', data.approval_details?.rejections.client)
            commit('SET_INTERNAL_APPROVAL', data.approval_details?.approvals.internal)
            commit('SET_CLIENT_APPROVAL', data.approval_details?.approvals.client)
            commit('SET_FULL_APPROVAL', data.approval_details?.approvals.full)
            commit('SET_FULL_REJECTION', data.approval_details?.rejections.full)
            commit('SET_INTERNAL_REJECTION', data.approval_details?.rejections.internal)
            commit('SET_HAS_CONFIGURED_WORKFLOWS', data.has_configured_workflows)
            commit('SET_IS_FROM_OTHER_PARTY', data.is_from_other_party)
        }
    } catch (error) {
        console.log(error)
        dispatch('notification/setShow', {
            type: 'error',
            text: 'Approvals loading error occurred',
        }, { root: true })
    } finally {
        commit('SET_APPROVALS_LOADED', true)
    }
}

export function unsetApprovalSubmissions ({ commit }): void {
    commit('SET_RESOURCE_ID', null)
    commit('SET_SUBMITTED_FOR_APPROVAL', null)
    commit('SET_MANUAL_APPROVAL', null)
    commit('SET_APPROVAL_WORKFLOWS', [])
    commit(FinancialApprovalsMutations.SET_PHASE, null)
    commit('SET_MANUAL_REJECTION', null)
    commit('SET_INTERNAL_APPROVAL', null)
    commit('SET_CLIENT_APPROVAL', null)
    commit('SET_FULL_APPROVAL', null)
    commit('SET_FULL_REJECTION', null)
    commit('SET_INTERNAL_REJECTION', null)
    commit('SET_HAS_CONFIGURED_WORKFLOWS', null)
    commit('SET_EXTERNAL_CLIENT_APPROVAL', null)
    commit('SET_COMPANY_PROJECT_RESOURCE_ID', null)
    commit('SET_APPROVAL_SUBMISSION_COMPANY_ID', null)
}

export async function getApprovalWorkflows ({ commit, dispatch }, params: ApprovalSubmissionParams) {
    const response = await financialApprovalWorkflowsClient.getApprovalWorkflows(
        params.project_company_id,
        params.approvable_resource_name,
        params.approvable_resource_subtype
    )

    commit('SET_ACTIVE_RESOURCE', params.approvable_resource_name)
    commit('SET_APPROVAL_WORKFLOWS', response.data)
    commit('SET_APPROVALS_LOADED', true)
    commit('SET_COMPANY_PROJECT_RESOURCE_ID', response.data[0]?.company_project_approvable_resource_id)
}

export function setCurrentChangeOrder ({ commit }, value: any): void {
    commit('SET_CURRENT_CHANGE_ORDER', value)
}

export function setCurrentAmendment ({ commit }, value: any): void {
    commit('SET_CURRENT_AMENDMENT', value)
}

export async function resetApprovalSubmissions (context: any, params: ApprovalSubmissionParams): Promise<void> {
    await financialApprovalSubmissionClient.deleteApprovalSubmissions(params)
}

export async function resetManualApproval (context: any, params: ApprovalSubmissionParams): Promise<void> {
    await financialApprovalsClient.deleteManualApproval(params)
}

export async function approvalPhasesList ({ commit }, request: ApprovalPhasesResourceRequestData): Promise<void> {
    const { data } = await financialApprovalPhasesClient.getApprovalPhasesList(request)

    commit(FinancialApprovalsMutations.SET_PHASES, data)
}

export async function sharedApprovalPhasesList ({ commit }, request: ApprovalPhasesResourceRequestData): Promise<void> {
    const { data } = await financialApprovalPhasesClient.getSharedApprovalPhasesList(request)

    commit(FinancialApprovalsMutations.SET_PHASES, data)
}

export async function setSharedApprovalPhasesListToEmpty ({ commit }): Promise<void> {
    commit(FinancialApprovalsMutations.SET_PHASES, [])
}

export async function revertToApprovedPhase (context: any, request: ApprovalPhasesResourceRequestData): Promise<void> {
    await financialApprovalPhasesClient.revertToApprovedPhase(request)
}

export async function deleteNewestPhase (context: any, request: ApprovalPhaseDeleteNewestRequestData): Promise<void> {
    await financialApprovalPhasesClient.deleteNewestPhase(request)
}

export async function setExpiredInsuranceConsequencesForInvoice ({ commit }, invoiceId: String): Promise<void> {
    const { data } = await invoiceSettingsClient.getInvoiceSettings(invoiceId)

    commit('SET_EXPIRED_INSURANCE_CONSEQUENCE', data.expired_insurance_consequence)
    commit('SET_IS_INVOICE_CONTRACT_HOLDER', data.is_contract_holder)

    switch (data.expired_insurance_consequence) {
        case ExpiredInsuranceConsequence.BLOCK_APPROVAL: {
            commit('SET_INVOICE_APPROVAL_BLOCK', true)
            break
        }
        case ExpiredInsuranceConsequence.BLOCK_SUBMIT: {
            commit('SET_INVOICE_SUBMITING_BLOCK', true)
            break
        }
        default: {
            commit('SET_INVOICE_APPROVAL_BLOCK', false)
            commit('SET_INVOICE_APPROVAL_BLOCK', false)
        }
    }
}

export function setActiveResource ({ commit }, value: Resource): void {
    commit('SET_ACTIVE_RESOURCE', value)
}

export function setResourceId ({ commit }, value: string): void {
    commit('SET_RESOURCE_ID', value)
}
