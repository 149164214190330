<!--
 * @deprecated
 * Use AlertBox instead
-->

<template>
    <div class="io-design-system io-banner">
        <div :class="`io-banner__container io-banner__container--${ getStyle }`">
            <div :class="`io-banner__ico io-banner__ico--${ getStyle }`">
                <i :class="icon" />
            </div>

            <div class="io-banner__text">
                {{ text }}
            </div>

            <div v-if="callToActionText" :class="`io-banner__link io-banner__link--${ getStyle }`" @click="callToAction()">
                {{ callToActionText }} <span v-if="callToActionIcon" :class="callToActionIcon"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./IOBanner.ts"></script>
<style lang="scss" src="./IOBanner.scss" scoped></style>
