import { defineComponent, PropType } from 'vue'

type IconSpan = {
    name: string,
    backgroundColor: string,
}

export default defineComponent({
    name: 'FinancialInfoTile',
    props: {
        title: String,
        value: [Number, String],
        iconPath: String,
        iconAlt: String,
        showWarning: {
            type: Boolean,
            required: false,
            default: false
        },
        iconSpan: {
            type: Object as PropType<IconSpan>,
        }
    },
})
