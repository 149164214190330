import { PropType } from 'vue'
import CellType from '@/components/table/CellType'
import { SoVTableViewMode } from '@/io-modules/invoices/enums/SoVTableViewMode'
import { invoiceSoVStore } from '../../stores/invoiceSoV'
import { invoiceViewStore } from '../../stores/invoiceView'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import SoVLineItem from '../../interfaces/SoVLineItem'
import InvoiceStatus from '../../enums/InvoiceStatus'
import SoVCommonMixin from '@/io-modules/invoices/mixins/sov-wbs/SoVCommonMixin.ts'
import FeatureFlagsMixin from '@/mixins/feature-flags/featureFlagsMixin.ts'
import FeatureFlagsConsts from '@/constants/FeatureFlagsConsts.ts'
import {
    RevisionRequestComment,
    RevisionRequestItem,
    RevisionRequestVendorAppliedData,
} from '@/io-modules/invoices/api-clients/reviseResubmitClient.ts'
import { invoiceRevisionStore } from '@/io-modules/invoices/stores/invoiceRevision.ts'
import { omit } from 'lodash'

const reviseResubmitMixin = {
    props: {
        phases: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        modalAdjust: {
            type: Boolean,
            default: false,
        },
        adjustItem: {
            type: Object as PropType<SoVLineItem>
        },
    },
    mixins: [SoVCommonMixin, FeatureFlagsMixin],
    emits: ['update:modalAdjust', 'update:modalAdjustRetention', 'update:adjustItem'],
    computed: {
        ...mapState(invoiceSoVStore, [
            'columnsViewMode',
            'contractWBS',
            'changeOrdersWBS',
            'changeOrderWithinOriginalScope',
            'invoiceWBSRecalculating'
        ]),

        ...mapState(invoiceRevisionStore, [
            'revisionRequest',
        ]),

        ...mapState(invoiceViewStore, [
            'isMyInvoice',
            'isVendorInvoice',
            'invoiceIsApproved',
            'invoice',
            'isClient',
            'isVendor'
        ]),

        ...mapStateVuex('project', {
            projectObj: (state) => state.projectObj
        }),

        ...mapStateVuex('payapps', {
            flags: (state) => state.payApp.flags,
        }),

        ...mapState('appStore', {
            authData: state => state.authData,
        }),

        allColumnsView (): boolean {
            return SoVTableViewMode.AllColumns === this.columnsViewMode
        },

        isRevisionStatus (): boolean {
            return [InvoiceStatus.RequestedRevision, InvoiceStatus.RevisionRequested].includes(this.invoice.status)
        },

        canClientReviseInvoice (): boolean {
            return this.isClient && !this.invoice.is_provided_manually && [
                InvoiceStatus.PendingInternalApproval,
                InvoiceStatus.InternallyRejected,
                InvoiceStatus.RejectedByClient
            ].includes(this.invoice.status)
        },

        clientRevisedRevisions (): boolean {
            const clientHasRevised = this.contractWBS.some(item => item.needAttention && !item.acceptedByClient)
            const clientHasRevisedCO = this.changeOrdersWBS.some(item => item.needAttention && !item.acceptedByClient)
            return this.isVendorInvoice && this.isRevisionStatus && (clientHasRevised || clientHasRevisedCO)
        },

        vendorRevisedRevisions (): boolean {
            const vendorHasRevised = this.contractWBS.some(item => item.needAttention && !item.revisedByVendor)
            const vendorHasRevisedCO = this.changeOrdersWBS.some(item => item.needAttention && !item.revisedByVendor)
            return this.isVendorInvoice && this.isRevisionStatus && (vendorHasRevised || vendorHasRevisedCO)
        },

        /**
         * Invoices: 2. Request Revision: Define what actions to show
         */
        actionsColumn (): object {
            return {
                name: '',
                valueType: CellType.Actions,
                actions: (item: SoVLineItem) => {
                    if (this.hasSubTasksItem(item)) {
                        return []
                    }

                    if (item.needAttention && this.isMyInvoice) {
                        if (!item.revisedByVendor) {
                            return [
                                {
                                    text: this.$t('Review Revision'),
                                    icon: 'icon-edit',
                                    variant: 'link',
                                    onClick: (item: object): void => this.clickAdjust(item),
                                }
                            ]
                        } else {
                            if (item.acceptedByVendor) {
                                return this.getAcceptedItemAction()
                            } else {
                                return this.getAdjustedItemAction()
                            }
                        }
                    }

                    if (this.canClientReviseInvoice && !item.adjustments) {
                        return [
                            {
                                text: this.$t('Adjust for Revision'),
                                icon: 'icon-edit',
                                variant: 'link',
                                onClick: (item: object): void => this.clickAdjust(item),
                            }
                        ]
                    }

                    if (this.resolvedAccepted(item)) {
                        return this.getAcceptedItemAction()
                    }

                    if (item.adjustments) {
                        return this.getAdjustedItemAction()
                    }
                },
                classes: 'io-fixed-column io-fixed-column--last io-align-right',
                fixed: true,
                hasSmallPadding: true,
            }
        },

        scheduleValueColumnClasses (): string {
            let classes = 'io-align-right io-fixed-column'
            if (!this.requestRevisionActionsColumnVisible) {
                classes += ' io-fixed-column--last'
            }

            return classes
        },

        requestRevisionActionsColumnVisible (): boolean {
            return this.areActionsColumnsVisible(this.isFeatureEnabled(FeatureFlagsConsts.INVOICES_REVISION_REQUEST_V2, false)) && this.revisionRequest?.adjustments
        }
    },
    methods: {
        ...mapActions(invoiceRevisionStore, [
            'areSubmittedValuesEqual', 'saveAdjustedItemAsVendor']),

        getAdjustedItemAction (): object[] {
            return [
                {
                    text: 'Adjusted',
                    variant: 'text',
                    icon: 'icon-alert-triangle-2 io-warning-icon',
                    onClick: () => {}
                },
                {
                    text: this.$t('Details'),
                    variant: 'link',
                    onClick: (item: object): void => this.clickAdjust(item)
                }
            ]
        },

        getAcceptedItemAction (): object[] {
            return [
                {
                    text: 'Accepted',
                    variant: 'text',
                    icon: 'icon-check-circle-2-2 io-success-icon',
                    onClick: () => {}
                },
                {
                    text: this.$t('Details'),
                    variant: 'link',
                    onClick: (item: object): void => this.clickAdjust(item)
                }
            ]
        },

        ...mapActions(invoiceSoVStore, [
            'areActionsColumnsVisible',
            'getRealContractWbsId',
            'hasSubTasksItem',
            'getItemsForContractWbsId',
            'getLeafItems'
        ]),

        ...mapActions(invoiceRevisionStore, ['recalculateAdjustedItem']),

        setupAttentionClass (item: object): void {
            if (!this.hasSubTasksItem(item) && item.needAttention) {
                item.classes = 'io-warning-bg'
            } else if (item.classes) {
                item.classes = ''
            }

            if (this.hasSubTasksItem(item)) {
                item.children.forEach(child => this.setupAttentionClass(child))
            }
        },

        modalAdjustToggle (item: object = null): void {
            this.$emit('update:adjustItem', !this.modalAdjust ? item : null)
            this.$emit('update:modalAdjust', !this.modalAdjust)
        },

        vendorNotReviewed (item: SoVLineItem): boolean {
          return item?.needAttention && !item?.revisedByVendor
        },

        resolvedAccepted (item: SoVLineItem): boolean {
            if (!item?.adjustments) {
                return false
            }

            return item?.needAttention && item?.acceptedByClient
        },

        resolvedRevised (item: SoVLineItem): boolean {
            if (!item?.adjustments) {
                return false
            }
            if (!item?.needAttention) {
                return false
            }

            if (this.isClient) {
                return item?.revisedByClient
            } else if (this.isVendor) {
                return item?.revisedByVendor
            }
        },

        onVendorStartedAdjustmentOnItem (item: SoVLineItem, contractWbsId: string): void {
            const items = this.getItemsForContractWbsId(contractWbsId)
                .filter(item => false === this.hasSubTasksItem(item))
            items.forEach(item => {
                item.inputAdjustmentAllowed = true
            })
            const leafItems = this.getLeafItems(items)

            let adjustments = null
            leafItems.forEach(child => {
                child.inputAdjustmentAllowed = true
                if (null === adjustments) {
                    if (!child.adjustments) {
                        this.adjustRevisionRequestItem(child, omit(child, ['adjustments', 'originalPhase']), [])
                    }
                    adjustments = child.adjustments
                } else {
                    child.adjustments = adjustments
                }
            })
        },

        onAdjustedItemValueChangedInInputs (item: SoVLineItem): void {
            if (this.invoiceWBSRecalculating) {
                return
            }
            let adjustments = omit(item, ['adjustments', 'originalPhase'])
            let comments = item.adjustments.comments ?? []
            this.adjustRevisionRequestItem(item, adjustments, comments)
            this.saveAdjustedItemAsVendor(item, adjustments, comments)
        },

        /**
         * Applies adjustments made to an item in the revision request store
         * Doesn't save to backend yet
         * @param item
         * @param adjustments
         * @param comments
         */
        adjustRevisionRequestItem (item: SoVLineItem, adjustments: RevisionRequestVendorAppliedData, comments: RevisionRequestComment[]): SoVLineItem {
            if (null === this.revisionRequest) {
                this.revisionRequest = {
                    adjustments: [],
                    note: null,
                    id: null,
                }
            }
            const contractWbsId = this.getRealContractWbsId(item)

            //Check if we need to remove this item from the revision request
            let removeItem = false
            if (this.isClient) {
                if (this.areSubmittedValuesEqual(item, adjustments)
                        && null === item.adjustments?.vendor_applied
                        && !comments.length) {
                    removeItem = true
                }
            }

            if (true === removeItem) {
                delete item.adjustments

                this.recalculateAdjustedItem(item)
                this.revisionRequest.adjustments = this.revisionRequest.adjustments.filter(a => a.contract_wbs_id !== contractWbsId)

                return item
            }

            let revisionRequestItem: RevisionRequestItem = this.revisionRequest.adjustments.find(a => a.contract_wbs_id === contractWbsId)

            if (!revisionRequestItem) {
                const originalItem = omit(item, ['adjustments', 'originalPhase'])

                if (this.isClient) {
                    revisionRequestItem = {
                        contract_wbs_id: contractWbsId,
                        original: originalItem,
                        vendor_applied: null,
                        client_requested: originalItem,
                        comments: [],
                    }
                } else {
                    revisionRequestItem = {
                        contract_wbs_id: contractWbsId,
                        original: originalItem,
                        vendor_applied: originalItem,
                        client_requested: null,
                        comments: [],
                    }
                }
                this.revisionRequest.adjustments.push(revisionRequestItem)
            }

            revisionRequestItem.comments = comments

            if (this.isClient) {
                revisionRequestItem.client_requested = adjustments
            } else if (this.isVendor) {
                revisionRequestItem.vendor_applied = adjustments
            } else {
                throw new Error('Unsupported role ' + this.invoice.role)
            }
            item.adjustments = revisionRequestItem

            this.recalculateAdjustedItem(item)

            return item
        },

        clickAdjust (item: object): void {
            this.modalAdjustToggle(item)
        },

        getItem (contractWbsId: string): SoVLineItem {
            const findItemRecursively = (items) => {
                for (const item of items) {
                    if (item.contract_wbs_id === contractWbsId) {
                        return item
                    }
                    if (item.children && item.children.length > 0) {
                        const found = findItemRecursively(item.children)
                        if (found) {
                            return found
                        }
                    }
                }
                return null
            }

            let item = findItemRecursively(this.contractWBS)
            item = findItemRecursively(this.contractWBS) || findItemRecursively(this.changeOrdersWBS) || findItemRecursively(this.changeOrderWithinOriginalScope)
            if (item.inputAdjustmentAllowed) {
                return
            }
            return item
        },

        decideOnModalOnInputClick (contractWbsId: string, item: object): void {
            if (!this.isMyInvoice || !this.isRevisionStatus) {
                return
            }

            const realContractWbsId = this.getRealContractWbsId(item)
            if (item.inputAdjustmentAllowed) {
                return
            }

            item.inputAdjustmentAllowed = true
            return this.showPopupAlert({
                title: this.$t('Are you sure you want to adjust this line?'),
                caption: this.$t('This will mark this line for the client as adjusted.'),
                icon: 'icon-alert-triangle',
                width: 590,
                alert: item?.classes ? {
                    content: this.$t('To review in detail client revision and accept it, click'),
                    buttonText: this.$t('Review Revision'),
                    color: 'bg',
                    action: () => this.clickAdjust(item)
                } : null,
                sessionKey: 'invoice-vendor-revision',
                disabledInSessionCallback: () => this.onVendorStartedAdjustmentOnItem(item, realContractWbsId),
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, Adjust'),
                        saveDisabledInSession: true,
                        action: () => this.onVendorStartedAdjustmentOnItem(item, realContractWbsId)
                    },
                ]
            })
        }
    },
}

export default reviseResubmitMixin
