import { defineComponent } from 'vue'
import { FileInterface } from '@/io-modules/rfis/interfaces/Rfi'
import { HeaderTable } from '@/components/table/HeaderTableInterface'
import Table from '@/components/table/Table.vue'
import ActionBar from '@/components/action-bar/ActionBar.vue'
import { BulkAction } from '@/components/file-uploader-with-table/interfaces/File.ts'
import documentsClient from '@/io-modules/documents/api-clients/documentsClient.ts'
import { addIf } from '@/helpers/util.ts'
import getCascadeAnimationDelay from '@/helpers/getCascadeAnimationDelay.ts'

export default defineComponent({
    name: 'FilesTable',
    components: {
        ActionBar,
        Table,
    },
    props: {
        files: {
            type: Array as () => FileInterface[],
            required: true,
        },
        isViewMode: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['preview', 'edit', 'delete'],
    setup () {
        return {
            getCascadeAnimationDelay,
        }
    },
    data () {
        return {
            checkedFilesIds: [] as string[],
        }
    },
    computed: {
        headers (): HeaderTable[] {
            return [
                { text: this.$t('Name'), value: 'name' },
                { text: this.$t('File Size'), value: 'fileSize' },
                ...addIf(!this.isViewMode, { text: '', value: 'call', sort: false }),
            ]
        },
        allFilesSelected (): boolean {
            return this.checkedFilesIds.length === this.files.length
        },
        bulkActions (): BulkAction[] {
            return [
                {
                    id: 'bulk-action-1',
                    name: 'download',
                    icon: 'icon-file-download',
                    title: this.$t('Download'),
                },
            ]
        },
    },
    methods: {
        selectAllFiles (allChecked: boolean): void {
            this.checkedFilesIds = allChecked
                ? this.files.map(item => item.id)
                : []
        },
        async doBulkAction (action: BulkAction): Promise<void> {
            switch (action.name) {
                case 'download':
                    try {
                        await documentsClient.downloadDocuments(this.checkedFilesIds).then((response) => {
                            const href = URL.createObjectURL(response.data)
                            const link = document.createElement('a')
                            const fileName = this.checkedFilesIds.length === 1 ? response.headers['content-disposition'].split('=')[1] : 'files.zip'
                            link.href = href
                            link.setAttribute('download', fileName)
                            document.body.appendChild(link)
                            link.click()

                            document.body.removeChild(link)
                            URL.revokeObjectURL(href)
                        })
                    } catch (error) {
                        this.showNotification('error', error.response.data.message, error)
                    }
                    break
            }
        },
    },
})
