import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import Breadcrumbs from '@/modules/documents/main/parts/breadcrumbs/Breadcrumbs.vue'
import SingleTab from '@/modules/documents/main/parts/files-list/parts/tab/tab.vue'
import { debounce, isEmpty, uniqBy, uniqueId } from 'lodash'
import { mapGetters } from 'vuex'

export default defineComponent({
    components: {
        PopupModal,
        SingleTab,
        Breadcrumbs,
    },
    props: {
        modelId: {
            type: String,
            required: false,
            default: null
        },
        modelType: {
            type: String,
            required: true
        },
        filesSection: {
            type: [String, null],
            required: false,
            default: null
        },
        subSection: {
            type: [String, null],
            required: false,
            default: null
        },
        forceShared: {
            type: Boolean,
            required: false,
            default: false
        },
        allowedExtensions: {
            default: () => [],
            type: Array<string>
        },
        multipleFiles: {
            type: Boolean,
            default: true
        },
        defaultShare: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['updateDocuments', 'close'],
    setup () {
        return {
            uniqueId,
        }
    },
    data () {
        return {
            searchVal: '',
            activeTab: 0,
            singleTabType: 'myFiles',
            filesArrayId: 'select-files-popup',
            canCreateFolder: false,
            sendingFiles: false,
            order: {
                orderBy: 'updated_at',
                orderDir: 'desc'
            },
            offset: 0,
            limit: 50,
            documentsFullyLoaded: false,
            preventSearchFetching: true,
            observer: null as unknown as IntersectionObserver,
            onSearchChange: null as unknown,
            folderId: null as string,
            rendered: false,
        }
    },
    computed: {
        ...mapGetters('filesComponent', {
            isLoading: 'getLoading',
            folderFlags: 'getFolderFlags',
            selectedFiles: 'getSelectedFiles',
            hasSelectedFiles: 'hasSelectedFiles',
            isSearching: 'getSearchState',
        }),
        filtersQuery () {
            return {
                favorites: false,
                uploaded_by: null,
                last_updated: { start: null, end: null }
            }
        },
    },
    watch: {
        async 'searchVal' (): Promise<void> {
            await this.$store.dispatch('filesComponent/setIsSearching', true)
            this.onSearchChange()
        }
    },
    async beforeMount (): Promise<void> {
        await this.fetchFolderData()
    },
    mounted (): void {
        this.createIntersectionObserver()

        this.onSearchChange = debounce(async function (): Promise<void> {
            await this.resetRecords()
            await this.fetchData()
        }, 200)
    },
    async beforeUnmount (): Promise<void> {
        await this.$store.dispatch('filesComponent/clearBreadcrumbs', [])
        this.observer.disconnect()
        await this.resetRecords()
    },
    methods: {
        async onHandleNavigation (folderId?: string): Promise<void> {
            // a small trick to avoid firing the observer twice when navigating to a folder
            this.observer.disconnect()
            this.folderId = folderId
            this.clearSearch()
            await this.resetRecords()
            await this.fetchData()
            this.createIntersectionObserver()
        },
        async resetRecords (): Promise<void> {
            this.offset = 0
            this.documentsFullyLoaded = false
            await this.$store.dispatch('filesComponent/setFolders', {
                folders: [],
                arrayId: this.filesArrayId,
            })

            await this.$store.dispatch('filesComponent/setMyDocs', {
                files: [],
                arrayId: this.filesArrayId,
            }, { root: true })
        },
        createIntersectionObserver (): void {
            if (this.$refs['limitOfScrollableArea']) {
                this.observer = new IntersectionObserver(this.infiniteScroll, {
                    rootMargin: '250px'
                })
                this.observer.observe(this.$refs['limitOfScrollableArea'])
            }
        },
        async infiniteScroll (entries: any): Promise<void> {
            if (entries?.[0]?.isIntersecting) {
                if (this.rendered) {
                    await this.fetchData()
                }
            }
        },
        async fetchData (): Promise<void> {
            await this.$store.dispatch('filesComponent/setLoading', true)
            await this.$store.dispatch('filesComponent/setSelectMultipleFiles', this.multipleFiles)

            const params = {
                folder_type: 'root',
                skip: this.offset,
                take: this.limit,
                order_by: this.order.orderBy,
                order: this.order.orderDir,
                search: this.searchVal
            }

            const paramFolderId: string = this.folderId ? `/${ this.folderId }` : ''

            try {
                const { data } = await this.$api.realJson(`/documents/get-folder${ paramFolderId }`, {
                    ...params,
                    filters: this.filtersQuery
                })
                await this.setData(data)
                this.offset += this.limit

                /**
                 * FIXME it seems to me like BE is not well paginating folders
                 */
                this.documentsFullyLoaded = (data.files.own.length + data.folders.length) < this.limit
            } catch (e) {
                await this.$store.dispatch('filesComponent/setLoading', false)
                this.errorHandleNoRedirect(e)
            } finally {
                this.rendered = true
            }
        },
        async setData (data): Promise<void> {
            // FIXME seems like folders are not paginated - checking on BE
            const folders = uniqBy([
                ...this.$store.getters['filesComponent/getFolders'](this.filesArrayId),
                ...data.folders
            ], '_id')

            await this.$store.dispatch('filesComponent/clearBreadcrumbs', [])
            await this.$store.dispatch('filesComponent/setFolders', {
                folders,
                arrayId: this.filesArrayId,
            })

            if (data.breadcrumb && data.breadcrumb.length) {
                this.$store.dispatch('filesComponent/setFolderBreadcrumb', data.breadcrumb)
            }

            if (data.files && !isEmpty(data.files)) {
                const files = [
                    ...(this.$store.getters['filesComponent/getMyFiles'](this.filesArrayId) ?? []),
                    ...data.files.own
                ]

                this.$store.dispatch('filesComponent/setMyDocs', {
                    files,
                    arrayId: this.filesArrayId,
                }, { root: true })
            }

            await this.$store.dispatch('filesComponent/setFolderFlags', data.current_folder)
            await this.$store.dispatch('filesComponent/setIsSearching', false)
            await this.$store.dispatch('filesComponent/setLoading', false)
        },
        clearSearch () {
            this.searchVal = ''
        },
        close () {
            this.$emit('close')
        },
        async cloneSingleFile (file) {
            const params = {
                document_id: file._id,
                model_id: this.modelId ? this.modelId.toString() : null,
                model_type: this.modelType,
                section: this.filesSection,
                section_uuid: this.subSection,
                can_be_shared: this.forceShared || this.defaultShare ? 1 : 0
            }

            return await this.$api.post('documents/make-copy', { ...params })
                .then((result) => {
                    this.$store.dispatch('filesComponent/setClonedDocumentId', {
                        _id: file._id,
                        document_id: result.data.document_id
                    })

                    return {
                        document: {
                            ...file,
                            can_be_shared: this.forceShared || this.defaultShare,
                            _id: result.data.document_id,
                            thumbnail: result.data.thumbnail
                        },
                        // to cover edge cases in some modules, for example while adding a new task
                        _id: result.data.document_id,
                        _isFromLibrary: true,
                        // sign and finalize popup
                        name: file.file_name,
                    }
                })
        },
        async cloneSelectedFiles () {
            this.sendingFiles = true

            const actions = this.selectedFiles.map(this.cloneSingleFile)

            await Promise.all(actions)
                .then((res) => {
                    res.forEach(item => this.$emit('updateDocuments', item))

                    this.sendingFiles = false
                    this.close()
                    this.$store.dispatch('filesComponent/clearSelectedFiles')
                })
        },
        async fetchFolderData () {
            if (!this.modelId || !this.modelType) {
                await this.fetchData()
                return
            }

            await this.$api.get(`/documents/get-model-folder/${ this.modelType }/${ this.modelId }`).then(response => {
                this.folderId = response.data.folderId
            }).catch((error) => {
                this.errorHandle(error)
            }).finally(async () => {
                await this.fetchData()
            })
        }
    }
})
