import { cloneDeep, isObject } from 'lodash'

export function SET_LOADING (state, value) {
    state.loading = value
}

export function SET_PROJECTS_LIST (state, value) {
    state.projectsList = value
}

export function SET_PROJECTS_WITH_TASKS (state, value) {
    state.projects = value
}

export function SET_TASKS (state, value) {
    state.tasks = value
}

export function SET_CATEGORY (state, value) {
    state.category = value
}

export function SET_EDIT_MODE (state, value) {
    state.currentTaskCopy = value ? cloneDeep(state.currentTask) : null
    state.editMode = value
}

export function SET_TASK_FIELDS (state, value) {
    state.fields = value
}
export function SET_TASK_EXTRA_DATA (state, value) {
    state.extraData = value
}
export function SET_DELETED_TASK_ID (state, value) {
    state.deletedTaskId = value
}

export function SET_CURRENT_TASK (state, value) {
    let checkList = value._task_checklist_items ? value._task_checklist_items : []
    if (isObject(checkList)) {
        checkList = Object.values(checkList)
    }
    value._task_checklist_items = checkList.map((item) => {
        item.status = parseInt(item.status)
        return item
    })
    value.task_priority = value.task_priority ? value.task_priority : 0
    state.currentTask = { ...value, ...state.currentTaskAdditionalData }
}

export function SET_SAVING (state, value) {
    state.saving = value.saving
    state.saved = value.saved
}

export function SET_EMPLOYEES (state, value) {
    state.employees = value
}

export function SET_RENDERED (state, value) {
    state.rendered = value
}

export function SET_SEARCH (state, value) {
    state.search = value
}

export function CHANGE_TASKS_FILTERS (state, payload) {
    state.tasks_filters[payload['filterName']] = payload.filterValue
}

export function CHANGE_ELEMENT_ID (state, payload) {
    state.elementData.id = payload.id
}

export function CHANGE_ELEMENT_TYPE (state, payload) {
    state.elementData.type = payload.type
}

export function DISABLE_INPUTS (state, value) {
    state.disableInputs = value
}

export function CHANGE_CURRENT_TASK_PROPERTY (state, payload) {
    let clonedTask = cloneDeep(state.currentTask)
    clonedTask[payload.propertyName] = payload.propertyValue
    state.currentTask = clonedTask
}
export function SET_CURRENT_TASK_LOCKED (state, value) {
    state.currentTaskLocked = value
}

export function SET_CURRENT_TASK_LOADING (state, value) {
    state.currentTaskLoading = value
}

export function CHANGE_CURRENT_TASK_COPY_PROPERTY (state, payload) {
    let clonedTask = cloneDeep(state.currentTaskCopy)
    clonedTask[payload.propertyName] = payload.propertyValue
    state.currentTaskCopy = clonedTask
}

export function CANCEL_CHANGES (state) {
    state.currentTask = cloneDeep(state.currentTaskCopy)
}

export function ADD_CHECKLIST_ITEM (state, payload) {
    payload.status = parseInt(payload.status)
    state.currentTask._task_checklist_items.push(payload)
}

export function CHANGE_CHECKLIST_ITEM_STATUS (state, payload) {
    const checklistItems = cloneDeep(state.currentTask._task_checklist_items)
    checklistItems[payload].status = checklistItems[payload].status ? 0 : 1
    state.currentTask._task_checklist_items = checklistItems
}

export function CHANGE_CHECKLIST_ITEM_PROPERTY (state, payload) {
    let checklistItems = cloneDeep(state.currentTask._task_checklist_items)
    if (checklistItems && checklistItems[payload.index] && checklistItems[payload.index].hasOwnProperty(payload.propertyName)) {
        checklistItems[payload.index][payload.propertyName] = payload.propertyValue
    }
    state.currentTask._task_checklist_items = checklistItems
}

export function REMOVE_CHECKLIST_ITEM (state, payload) {
    state.currentTask._task_checklist_items.splice(payload, 1)
}

export function SET_CHECKLIST_ADD_MODE (state, payload) {
    state.checkList.addMode = payload
}

export function SET_CHECKLIST_EDIT_MODE (state, payload) {
    state.checkList.editMode = payload
}

export function EDIT_CHECKLIST_ITEM (state, payload) {
    state.checkList.task = cloneDeep(payload)
}

export function UPDATE_CHECKLIST_ITEM (state) {
    const index = state.checkList.task.localIndex
    const checklistItems = cloneDeep(state.currentTask._task_checklist_items)

    if (checklistItems && checklistItems[index]) {
        checklistItems[index].name = state.checkList.task.name
        checklistItems[index].status = state.checkList.task.status
        state.currentTask._task_checklist_items = checklistItems
    }
}

export function UPDATE_CACHED_LISTS (state, payload) {
    if (payload && payload.length > 0) {
        state.cachedLists = payload
    }
}

export function SET_REFRESH (state, value) {
    state.refresh = value
}

export function REFRESH_STATISTICS (state, value) {
    state.statisticsRefresh = value
}

export function ADD_DOCUMENT (state, value) {
    state.currentTask.model_documents.push(value)
}

export function REMOVE_DOCUMENT (state, value) {
    state.currentTask.model_documents.splice(value, 1)
}

export function ADD_DOCUMENT_ALLOWED (state, value) {
    state.currentTask.model_documents_allowed.push(value)
}

export function REMOVE_DOCUMENT_ALLOWED (state, value) {
    state.currentTask.model_documents_allowed.splice(value, 1)
}

export function ADD_DOCUMENT_TRASH (state, value) {
    state.currentTask.model_documents_trashed.push(value)
}

export function RESTORE_DOCUMENT_TRASHED (state, value) {
    state.currentTask.model_documents_trashed.splice(value, 1)
}

export function SET_KANBAN_VIEW_MODE (state, value) {
    state.kanbanViewMode = value
}

export function SET_TASKS_VIEW_MODE (state, value) {
    state.tasksViewMode = value
}

export function SET_WORKFLOW_DATA (state, value) {
    state.workflowData = value
}

export function SET_KANBAN_SHOW_TAGS (state, value) {
    state.kanbanShowTags = value
}

export function SET_BOARD_TEMPLATES (state, value) {
    state.boardTemplates = value
}

export function ADD_BOARD_TEMPLATE (state, value) {
    state.boardTemplates.push(value)
}

export function SET_REFRESHED_TASK (state, value) {
    state.refreshedTask = value
}

export function SET_TASK_PROJECT_MONGO_ID (state, value) {
    state.taskProjectMongoId = value
}
export function SET_TASK_PROJECT_ID (state, value) {
    state.taskProjectId = value
}

export function SET_TASK_SAVED (state, value) {
    state.taskSaved = value
}

export function SET_SHOW_ADD_FROM_TEMPLATE_MODAL (state, value) {
    state.showAddFromTemplateModal = value
}
export function RESET_CURRENT_TASK (state) {
    state.currentTask = null
}
export function SET_SHOW_TIMELINE (state, value) {
    state.timelineShown = value
}

export function APPEND_CURRENT_TASK_ADDITIONAL_DATA  (state, payload) {
    state.currentTaskAdditionalData = payload
}
