const punchPillColorMixin = {
    methods: {
        getPunchPillColor (code: number): string {
            const colorCodes = {
                100: 'io-red',
                200: 'io-blue',
                250: 'io-yellow',
                300: 'io-green',
            }

            return colorCodes[code] ?? 'io-grey'
        },

    },
}

export default punchPillColorMixin
