import { defineComponent, PropType } from 'vue'

export default defineComponent({
    name: 'FundingSourcesProjectRow',
    props: {
        project: {
            type: Object as PropType<any>,
        },
        isCapitalFund: {
            type: Boolean,
            default: true,
        },
        isFinal: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        openProject (): void {
            const routeData = this.$router.resolve({
                name: 'project-dashboard',
                params: { pid: this.project.project_id },
            })
            window.open(routeData.href, '_blank', 'noopener')

        },
    },
})
