<template>
    <div class="io-contracts-list">
        <SimpleLazyList
            :columns="columns"
            :endpoint="lazyListProps.endpoint"
            :filters-endpoint="lazyListProps.filtersEndpoint"
            :additional-params="additionalParams"
            :item-route="lazyListProps.itemRoute"
            :allow-local-sorting="true"
            :filter-pills-color-function="getFilterContractStatusColorClass"
            :filters-schema="filtersSchema"
            :is-frontend-sorting-disabled="true"
        >
            <template #no-items>
                <IOPlaceholder
                    :icon="searchTerm.length ? 'icon-inbox' : 'icon-file-agreement'"
                    :title="$t(searchTerm.length ? 'Didn\'t find what you are looking for?' : 'There are no contracts')"
                    :subtitle="$t(searchTerm.length ? 'Try adjusting your search' : '')"
                />
            </template>
        </SimpleLazyList>
    </div>
</template>

<script lang="ts" src="./ContractsListTable.ts"></script>
<style scoped lang="scss" src="./ContractsListTable.scss"></style>
