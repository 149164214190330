<template>
    <LoadingScreen :custom-show-loading="loading"/>
    <ContentTwoSidesTemplate v-if="!loading && appPackage" class="io-content-template" iconClass="icon-info"
                             :title="$t('Information')">
        <template #leftSide>
            <div class="io-form" :class="{ 'io-form--loading': dataLoading }">
                <div class="io-form__item io-form__item-date-range io-application-package-name">
                    <IOLabel :text="$t('Name')" is-required/>
                    <IOInput v-model="appPackage.name"/>
                </div>

                <div class="io-form__item io-form__item-date-range io-application-package-period">
                    <IOLabel :text="$t('Billing Period')" is-required/>
                    <FieldDateTime
                        :options="{ ...datepickerOptions, range: true, placeholder: $t('Select Date Range') }"
                        :showIcon="true"
                        v-model="dateRange"
                    />
                </div>

                <div class="io-content-template__dates">
                    <div class="io-form__item io-form__item-date-range io-application-package-open_date">
                        <IOLabel :text="$t('Billing Period Open Date')" is-required/>
                        <FieldDateTime
                            :options="{ ...datepickerOptions, range: false }"
                            :showIcon="true"
                            v-model="appPackage.billing_period_open_date"
                        />
                    </div>

                    <div class="io-form__item io-form__item-date-range io-application-package-due_date">
                        <IOLabel :text="$t('Due Date')" is-required/>
                        <FieldDateTime
                            :options="{ ...datepickerOptions, range: false }"
                            :showIcon="true"
                            v-model="appPackage.billing_period_due_date"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template #rightSide>
            <section>
                <IOLabel :text="$t('Billing Period')"/>
                <div class="io-value">{{ $t('Start and end dates of the billing cycle.') }}</div>

                <IOLabel :text="$t('Billing Period Open Date')"/>
                <div class="io-value">{{ $t('The date that marks the beginning of the billing period.') }}</div>

                <IOLabel :text="$t('Due Date')"/>
                <div class="io-value">{{ $t('The deadline the application package must be submitted.') }}</div>
            </section>
        </template>
    </ContentTwoSidesTemplate>
</template>

<script lang="ts" src="./AppPackageCreateEdit.ts"></script>
<style lang="scss" src="./AppPackageCreateEdit.scss" scoped></style>
