import {  defineComponent } from 'vue'

export default defineComponent({
    name: 'SingleCompany',
    components: {
    },
    props: {
        name: { type: String },
        logo: { type: String },
        offSystem: { type: Boolean },
        workspaceType: { type: String },
        address: { type: Object },
        hideDetails: { type: Boolean, default: false}
    },
    data () {
    },

    methods: {
        getCompanyAddress (address): string {
            const fields = [
                'address_line1',
                'address_line2',
                'city',
                'state',
                'zip',
                'country'
            ]

            let addressData = []
            for (const field of fields) {
                !!address[field] && addressData.push(address[field])
            }

            return addressData.join(', ').trim()
        }
    }
})
