<template>
    <div class="io-items-list">
        <div class="io-items-list__header">
            <IOLabel
                v-if="label"
                :text="label"
                :isRequired="showRequiredLabelMark"
                :type="LabelType.BoldMax"
                :size="labelSize"
            />
            <slot name="headerRight"/>
        </div>
        <template v-for="item in items" :key="item.id">
            <div
                class="io-items-list__item"
                :data-testid="`list-item-${item.id}`"
                :class="{
                    'io-items-list__item--selected': value === item.id,
                    'io-items-list__item--disabled': item.disabled,
                }"
                @click="selectItem(item)"
            >
                <AlertBox
                    v-if="item.info && item.info.text"
                    icon="icon-info"
                    color="warning"
                >
                    {{ item.info.text }}
                    <span v-if="item.info.linkData" class="io-info-link" @click="goToLink(item)">{{ item.info.linkText || '' }}</span>
                </AlertBox>
                <div class="io-items-list__item-wrapper">
                    <Radio
                        :radio-value="item.id"
                        :input-name="item.id"
                        :disabled="item.disabled"
                        v-model="value"
                    />
                    <div
                        v-if="item.icon"
                        class="io-items-list__item__icon"
                        :class="{ 'io-items-list__item__icon--disabled': item.disabled }"
                        :style="`background-color: ${item.backgroundColor || ''}`"
                    >
                        <span :class="item.icon" :style="`color: ${item.color || ''}`"></span>
                    </div>
                    <Avatar
                        v-else-if="showLogo"
                        :employeeMongoId="item.logo"
                        :employeeFullName="item.title"
                    />
                    <div class="io-items-list__item__content">
                        <div
                            v-if="item.title"
                            class="io-items-list__item__title"
                            :class="{ 'io-items-list__item__title--disabled': item.disabled }"
                        >
                            <span>{{ item.title }}</span>
                            <slot name="itemTitleRight" v-bind="{ item: item }"/>
                        </div>
                        <div
                            v-if="item.description"
                            class="io-items-list__item__description"
                            :class="{ 'io-items-list__item__description--disabled': item.disabled }"
                        >
                            <span>{{ item.description }}</span>
                            <slot name="itemDescriptionRight" v-bind="{ item: item }"/>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts" src="./ChooseItemRadioCard.ts"></script>
<style lang="scss" src="./ChooseItemRadioCard.scss" scoped></style>
