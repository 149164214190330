<template>
    <RightModal
        customClass="io-design-system io-right-modal--filters"
        @escDown="setEscPressed(true)"
        @escUp="setEscPressed(false)"
        @clickOutside="$emit('clickOutside')"
    >
        <template #default>
            <div class="io-filters-modal">
                <div class="io-filters-modal__header">
                    <div class="io-filters-modal__title">
                        <h1>{{ $t('Filters') }}</h1>
                        <StatusPill variant="filters-counter" :text="String(countFilters) || ''" />
                    </div>
                    <div :class="{'io-active': escPressed}">
                        <IOButton variant="link" @click="clearChanges">
                            <i class="fal fa-times"></i>
                        </IOButton>
                    </div>
                </div>

                <div class="io-filters-modal__content">
                    <slot/>
                </div>

                <div
                    class="io-filters-modal__btn-holder"
                >
                    <IOButton
                        v-if="showClearFiltersButton"
                        variant="link"
                        @click="clearFilters"
                    >
                        {{ $t('Clear all') }}
                    </IOButton>
                    <IOButton
                        class="io-apply-filters-btn"
                        @click="apply"
                    >
                        {{ $t('Apply filters') }}
                    </IOButton>
                </div>
            </div>
        </template>
    </RightModal>
</template>

<script lang="ts" src="./ModalFilters.ts"></script>
<style lang="scss" src="./ModalFilters.scss"></style>
