<template>
    <div class="io-invite-preview-vertical-list" data-testid="invite-preview-vertical-list">
        <div
            v-for="(contacts, company) in groupedSelectedContactsList"
            :key="company"
            class="io-single-contact"
        >
            <div class="io-single-contact__top-row">
                <div
                    v-if="contacts[0].company_logo"
                    class="io-single-contact__photo"
                    :style="getBackgroundCompanyLogo(contacts[0].company_logo)"
                >
                </div>
                <initials
                    v-else
                    class="io-single-contact__initials"
                    :class="{ 'io-offsystem-logo': companyIsOffSystem(contacts[0]) }"
                    :fullName="`${ company }`"
                />
                <div class="io-single-contact__name">
                    {{ company }}
                </div>
                <div
                    v-if="!companyIsOffSystem(contacts[0])"
                    class="io-status-pill"
                    :class="getPillColor(contacts[0].workspace.app_type)"
                >
                    {{ getPillName(contacts[0].workspace.app_type) }}
                </div>
                <div
                    v-if="!disableInviting"
                    class="io-single-contact__refresh-invitation"
                    @click="showInviteModal"
                >
                    <i class="icon-refresh-ccw"></i>
                </div>
            </div>

            <div class="io-single-contact__employees">
                <div
                    v-for="contact in contacts" :key="contact.id"
                    class="io-single-employee"
                >
                    <div
                        class="io-single-employee__avatar"
                        :class="{ 'io-ball-in-court': isBallInCourt(contact) }"
                        @click="setBallInCourt(contact)"
                    >
                        <div v-if="isUserOnline(contact)"
                             v-tooltip.top="getContactInvitedStatus(contact)"
                             class="io-single-employee__online-dot"
                        ></div>
                        <div
                            v-if="contact.avatar"
                            class="io-single-employee__photo"
                            :style="getBackgroundAvatar(contact.id)"
                        ></div>
                        <initials
                            v-else-if="contact.firstname"
                            class="io-single-employee__initials"
                            :class="{ 'io-offsystem-logo': isArchivedOrPendingOrOffSystem(contact) }"
                            :fullName="`${ contact.firstname } ${ contact.lastname }`"
                        />
                        <initials
                            v-else
                            class="io-single-employee__initials"
                            :class="{ 'io-offsystem-logo': isArchivedOrPendingOrOffSystem(contact) }"
                            :fullName="contact.email"
                        />
                    </div>
                    <div class="io-single-employee__content">
                        <div
                            v-if="contact.firstname || contact.lastname"
                            class="io-single-employee__name">
                            {{ contact.firstname }}

                            <template v-if="contact.lastname">
                                {{ contact.lastname }}
                            </template>

                            <template v-if="isArchivedEmployee(contact)">
                                {{ '(archived)' }}
                            </template>

                        </div>

                        <div v-else class="io-single-employee__name">
                            {{ contact.email }}
                        </div>

                        <div
                            v-if="contact.title"
                            class="io-single-employee__role"
                        >
                            {{ contact.title }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./InvitePreviewVerticalList.ts"></script>
<style src="./InvitePreviewVerticalList.scss" lang="scss" scoped></style>
