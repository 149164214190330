import { ApiClient } from '@/api/api'
const client = ApiClient

export default {
    getPayApp (id, pid) {
        const model = {
            params: {
                project_id: pid
            }
        }
        return client.get(`/payapps/pay-app/${id}`, model)
    },

    getAttachedPayApp (id, pid, attachedId) {
        const model = {
            params: {
                project_id: pid
            }
        }
        
        return client.get(`/payapp/${id}/attached-payapp/${attachedId}`, model)
    },

    /**
     * Save payapp with action
     * @param payApp
     * @param action
     * @return {*}
     */
    savePayapp (payApp, action) {
        const payload = {
            action: action,
            quoted_items: payApp.quoted_items,
            specific: payApp.specific
        }
        return client.realJson(`/payapps/pay-app/action/${payApp.id}`, payload)
    },

    /**
     * Save basic invoice details
     *
     * @param id
     * @param model
     * @return {*}
     */
    savePayappBasic (id, model) {
        return client.put(`/payapps/pay-app/${id}`, model, {
            headers: {
                Accept: 'application/json'
            }
        })
    },

    /**
     * Load billing periods for project
     * @param projectId
     * @return {*}
     */
    loadPeriods (projectId) {
        return client.get(`/payapps/get-billing-period/${projectId}`)
    },

    /**
     * Load funding sources for project
     * @param projectMongoLocalId
     * @return {*}
     */
    loadProjectFundingSources (projectMongoLocalId) {
        const model = {
            params: {
                id: projectMongoLocalId
            }
        }
        return client.get(`/funding-sources-attached-to-project`, model)
    },


    /**
     * Load contract for my invoice
     * @param contractId
     * @return {*}
     */
    invoiceGetContract (contractId) {
        return client.get(`/payapps/contractor/sender-data/${contractId}`)
    },

    /**
     * Load vendors for project invoice
     * @param projectId
     * @return {*}
     */
    invoiceGetVendors (projectId) {
        return client.get(`payapps/get-contractors/${projectId}`)
    },

    /**
     * Load attention users
     * @param contractId
     * @return {*}
     */
    invoiceGetAttenders (payappId) {
        return client.get(`payapps/get-payapp-attenders/${payappId}`)
    },


    invoiceRetentionConfirmMessage (payappId) {
        return client.put(`pay-apps/${payappId}/retention-messages-confirmations`)
    },


    /**
     * get provider list
     * @param contractId
     * @return {*}
     */
    getProviderList () {
        return client.get(`payapps/provider/list`)
    }
}
