import { defineComponent } from 'vue'
import { isObject, isEmpty } from 'lodash'
import At from 'vue-at'
import initials from '@/components/new-theme/initials.vue'
import cachedListMixin from '@/mixins/cachedListMixin'

export default defineComponent({
    name: 'mentions',
    components: {
        At,
        initials,
    },
    props: {
        modelValue: {
            type: String,
            required: false,
        },
        attached_employees_ids: {
            type: Array,
            required: false,
            default: () => [],
        },
        attached_contacts: {
            type: Array,
            required: false,
            default: () => [],
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        isSubmitOnEnter: {
            type: Boolean,
            default: true,
        },
        contentEditable: {
            type: Boolean,
            default: true,
        },
        customPeopleList: {
            type: Array,
            required: false,
            default: () => [],
        },
        attachBelow: {
            type: Boolean,
            default: false,
        },
        inputVariant: Boolean,
    },
    mixins: [cachedListMixin],
    emits: ['update:modelValue', 'update', 'change', 'updateMentions', 'onSubmit', 'blur', 'triggerMention', 'keyDown'],
    data () {
        return {
            content: this.modelValue,
            attachedPeople: [],
            mentions: [],
            blockBlur: false,
            placeholderText: '',
        }
    },
    watch: {
        attached_employees_ids: {
            handler () {
                this.getAttachedPeople()
            },
            immediate: true,
        },
        customPeopleList: {
            handler (newPeople) {
                this.attachedPeople = newPeople
            },
            immediate: true,
        },
    },
    mounted () {
        this.placeholderText = this.placeholder || ''
        this.$emit('update', this.$refs.mentionsField)
    },
    methods: {
        getAttachedPeople () {
            if (this.customPeopleList.length) {
                this.attachedPeople = this.customPeopleList
                return
            }
            this.attachedPeople = []

            this.attached_employees_ids.forEach(async (item) => {
                const user = await this.getOne('employees', item)
                if (isObject(user) && !isEmpty(user)) {
                    const userDto = {
                        id: user._id,
                        name: user.fullname,
                        photo: user.e_photo ? user.e_photo : user.photo,
                        role: user._role_name ? user._role_name : '-',
                    }
                    this.attachedPeople.push(userDto)
                }
            })

            this.attached_contacts.forEach(async (item) => {
                if (!isEmpty(item.email)) {
                    // try to find contact full name by email
                    const user = await this.findAttachedToCompanyContactByEmail(item.email)
                    if (isObject(user) && !isEmpty(user.fullname)) {
                        item.name = user.fullname
                    }
                }

                if (!isEmpty(item.name)) {
                    this.attachedPeople.push(item)
                }
            })
        },
        getMentions () {
            this.clearMentions()

            const spans = this.$refs.mentionsField.getElementsByClassName('io-mention')

            for (let item of spans) {
                if (!this.mentions.includes(item.dataset.id)) {
                    this.mentions.push(item.dataset.id)
                }
            }
        },
        clearMentions () {
            this.mentions = []
        },
        triggerMention () {
            let e = document.createEvent('HTMLEvents')
            this.setFocus()
            document.execCommand('insertText', 0, ' @')
            e.initEvent('input', true, true)
            this.$refs.mentionsField.dispatchEvent(e)
        },
        onInput () {
            setTimeout(() => {
                this.$emit('change')
                this.$emit('update:modelValue', this.$refs.mentionsField?.innerHTML)
                this.$emit('update', this.$refs.mentionsField)
                this.getMentions()
                this.$emit('updateMentions', this.mentions)
            }, 1)
        },
        onKeyPress (e) {
            if ('@' === e.key) {
                this.$emit('triggerMention')
            }
        },
        onEnter (e) {
            if (!this.isSubmitOnEnter) {
                return
            }
            e.preventDefault()
            this.onSubmit()
        },
        onSubmit () {
            if (!this.isNew) {
                this.$emit('onSubmit')
                return
            }
            setTimeout(() => {
                const menu = document.querySelector('.atwho-panel')
                if (!menu) {
                    this.getMentions()
                    let todo = Object.assign({}, {
                        name: this.modelValue,
                        id: Date.now(),
                    })
                    this.$emit('onSubmit', todo)
                    this.$emit('update:modelValue', '')
                    this.resetField()
                }
            }, 3)
        },
        setFocus () {
            const range = document.createRange()
            const selection = window.getSelection()

            range.selectNodeContents(this.$refs.mentionsField)
            range.collapse(false)

            selection.removeAllRanges()
            selection.addRange(range)
        },
        resetField () {
            this.$refs.mentionsField.innerHTML = ''
        },
        sanitizeText (e) {
            e.preventDefault()
            let text = e.clipboardData.getData('text/plain')
            document.execCommand('insertText', false, text)
        },
        onBlur () {
            this.placeholderText = this.placeholder
            setTimeout(() => {
                if (this.blockBlur) {
                    return
                }
                this.$emit('blur')
            }, 80)
        },
        selectItem () {
            this.blockBlur = true
            this.onInput()
            setTimeout(() => {
                this.blockBlur = false
            }, 130)
        },
        onFocus () {
            this.placeholderText = ''
        },
        onKeydown (e) {
            this.$emit('keyDown', e)
        },
    },
})
