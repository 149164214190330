import { defineComponent } from 'vue'

import useLoader from '@/composables/useLoader.ts'
import useNewTabRouteOpener from '@/composables/useNewTabRouteOpener.ts'
import drawingReferenceSelectClient
    from '@/components/drawing-reference-select/api-clients/drawingReferenceSelectClient.ts'

import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOPlaceholder from '@/components/atoms/IOPlaceholder/IOPlaceholder.vue'
import ToggleSwitch from '@/components/atoms/ToggleSwitch/ToggleSwitch.vue'
import FloatMenu from '@/components/atoms/FloatMenu/FloatMenu.vue'
import SelectSetModal from '@/components/drawing-reference-select/components/select-set-modal/SelectSetModal.vue'
import SheetsGridView from '@/components/drawing-reference-select/components/sheets-grid-view/SheetsGridView.vue'
import SheetsListView from '@/components/drawing-reference-select/components/sheets-list-view/SheetsListView.vue'
import SheetWebViewer from '@/components/drawing-reference-select/components/sheet-web-viewer/SheetWebViewer.vue'

import { Status, View } from '@/components/drawing-reference-select/enums/Set.ts'
import { ReferenceType } from '@/components/drawing-reference-select/enums/ReferenceType.ts'
import type { Set } from '@/components/drawing-reference-select/interfaces/Set.ts'
import type { Sheet, Reference } from '@/components/drawing-reference-select/interfaces/Sheet.ts'
import type { Stamp, BaseStamp } from '@/components/drawing-reference-select/interfaces/Stamp.ts'
import type { OptionsType } from '@/components/atoms/ToggleSwitch/ToggleSwitch.ts'
import type { Action } from '@/components/atoms/FloatMenu/FloatMenu.ts'
import type { Props as IOThumbnailProps } from '@/components/atoms/IOThumbnail/IOThumbnail.ts'

export default defineComponent({
    name: 'DrawingReferenceSelect',
    components: {
        SelectSetModal,
        IOModal,
        IOPlaceholder,
        ToggleSwitch,
        FloatMenu,
        SheetsGridView,
        SheetsListView,
        SheetWebViewer,
    },
    props: {
        modelValue: {
            type: Array as () => Reference[],
            required: true,
        },
        stamp: {
            type: Object as () => Stamp | BaseStamp,
            default: null,
        },
        type: {
            type: String as () => ReferenceType,
            default: ReferenceType.STAMP,
        },
        pinColor: {
            type: String,
            default: '#1952E1',
        },
        cursorClass: {
            type: String,
            default: '',
        },
        thumbnail: {
            type: Object as () => Omit<IOThumbnailProps, 'url'>,
            default: () => ({}),
        },
        editable: Boolean,
        multiple: Boolean,
    },
    emits: ['update:modelValue'],
    setup () {
        const { loading, load } = useLoader()
        const { openNewTabRoute } = useNewTabRouteOpener()

        return {
            loading, load,
            openNewTabRoute,
            View,
            ReferenceType
        }
    },
    data () {
        return {
            view: View.GRID as View,
            search: '',
            selectSetModalShown: false,
            newReferenceModalShown: false,

            currentSets: null as Set[],
            sheets: null as Sheet[],
            selectedSheet: null as Sheet,
            reference: null as Reference,
        }
    },
    computed: {
        projectId (): string {
            return this.$store.getters['project/projectCompanyMongoId']
        },
        viewOptions (): OptionsType {
            return {
                items: {
                    style: 'text',
                    delay: 0,
                    preSelected: 'unknown',
                    disabled: false,
                    labels: [
                        {
                            name: View.GRID,
                            text: this.$t('Grid'),
                            icon: 'icon-elements',
                        },
                        {
                            name: View.LIST,
                            text: this.$t('List'),
                            icon: 'icon-list-unordered',
                        },
                    ],
                },
            }
        },
        filteredSheets (): Sheet[] {
            return this.search.length < 3
                ? this.sheets
                : this.sheets?.filter((sheet: Sheet) => sheet.page_title.toLowerCase().includes(this.search.toLowerCase()))
        },
    },
    methods: {
        async openNewReferenceModalOrSelectSetModal (): Promise<void> {
            await this.getCurrentSets()

            this.currentSets.length === 1
                ? this.openNewReferenceModal(this.currentSets[0])
                : this.selectSetModalShown = true
        },
        getCurrentSets (): Promise<void> {
            return this.load(async () => {
                const { data } = await drawingReferenceSelectClient.getDrawings(this.projectId)
                this.currentSets = data.items
            })
        },
        getActiveSheets (set: Set): Promise<void> {
            return this.load(async () => {
                const { data } = await drawingReferenceSelectClient.getSheets(set.id)
                const activeSheetsOnly = (sheet: Sheet): boolean => sheet.status === Status.ACTIVE

                // temporal solution to filter out archived sheets until we add a proper lazy loading
                this.sheets = data.items.filter(activeSheetsOnly)
            })
        },
        getReferenceActions (reference: Reference): Action[] {
            const actions: Action[] = []

            const preview: Action = {
                icon: 'icon-eye',
                name: 'preview',
                thumbnail: {
                    url: reference.drawing.thumbnail,
                    shadow: true,
                    align: 'end',
                    offset: 16,
                    width: 520,
                    ...this.thumbnail,
                },
            }
            const clear: Action = {
                icon: 'icon-cross',
                name: 'clear',
            }

            actions.push(preview)
            this.editable && actions.push(clear)

            return actions
        },
        saveDrawingReference (): void {
            const newReference: Reference = {
                ...this.reference,
                id: null,
                drawing: this.selectedSheet,
            }
            this.$emit('update:modelValue', [...this.modelValue, newReference])
        },
        async openNewReferenceModal (set: Set): Promise<void> {
            await this.getActiveSheets(set)
            this.selectSetModalShown = false
            this.newReferenceModalShown = true
        },
        removeReference (referenceToRemove: Reference): void {
            const filteredReferences = this.modelValue.filter((reference: Reference) => reference !== referenceToRemove)
            this.$emit('update:modelValue', filteredReferences)
        },
        closeSelectSetModal (): void {
            this.selectSetModalShown = false
            this.reset()
        },
        closeNewReferenceModal (): void {
            this.newReferenceModalShown = false
            this.reset()
        },
        backToSelectSetModal (): void {
            this.newReferenceModalShown = false
            this.selectSetModalShown = true
        },
        reset (): void {
            this.currentSets = null
            this.sheets = null
            this.selectedSheet = null
            this.reference = null
        },
        closeModalAndOpenDrawingsList (): void {
            this.openNewTabRoute({ name: 'drawings-sets-index' })

            this.closeSelectSetModal()
        },
        getReferenceTitle (drawing: Partial<Sheet>): string {
            return `${ drawing.page_label } - ${ drawing.page_title }`
        },
    },
})
