<template>
    <div class="io-comments-tab" :class="{ 'io-not-empty': comments.length || isAddCommentInputVisible }">
        <AddComment
            v-if="isAddCommentInputVisible"
            @cancel-adding-annotation="cancelAddingAnnotation"
            @add-comment="addComment"
        />

        <template v-if="comments.length">
            <SingleComment
                v-for="(item, index) in comments"
                :key="item.id"
                :data="item"
                :isLastComment="isLastComment(index)"
                @update-annotations="updateAnnotations"
                @select-annotation="selectAnnotation"
            />
        </template>

        <Placeholder
            v-else-if="!isAddCommentInputVisible"
            :subtitle="$t('There are no Comments on this drawing yet. Click Add Comment to add one.')"
            iconColor="var(--main-lighten-2)"
            icon="icon-comment-text"
        />
    </div>
</template>

<script lang="ts" src="./Comments.ts"></script>
<style lang="scss" src="./Comments.scss" scoped></style>


<!-- Flow of adding comment:
1. Click on Add Comment button or right click menu item, change values of [cursor, isAnnotationBeingAdded, typeOfAnnotationBeingAdded]
2. Cursor changes and after clicking on PDF Canvas an AddComment component is being displayed with input, change values of [cursor, isAnnotationInputVisible]
3.
    3a. User clicks away, presses escape, clicks cancel => process ends, change values of [isAnnotationBeingAdded, typeOfAnnotationBeingAdded, isAnnotationInputVisible]
    3b. User fills out input and clicks Post => process ends, change values of [isAnnotationBeingAdded, typeOfAnnotationBeingAdded, isAnnotationInputVisible]
-->