export const resetState = function ({commit, dispatch}) {
    commit('RESET_STATE')
}

export const setShowAddContactsPopup = function ({commit}, payload) {
    commit('SET_SHOW_ADD_CONTACTS_POPUP', payload)
}

export const saveDirectoryData = function ({commit, dispatch, state}, data) {
    commit('SET_INVITED_PENDING', data.invited_pending)
    commit('SET_INVITED_PEOPLE', data.invited_people)
    commit('SET_INVITED', data.invited)
}