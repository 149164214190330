<template>
    <div class="io-main-banner" :class="classes">
        {{ banner.text }}
        <template v-for="(button, index) in (banner.buttons || [])" :key="button.text">
            <router-link :to="button.route">
                {{ button.text }}
            </router-link>
            <a v-if="banner.showDismiss" @click="dismiss()" class="dismiss-banner">Dismiss</a>
        </template>
    </div>
</template>
<script src="./banner.js"></script>
<style type="text/css" lang="scss" src="./banner.scss" scoped />
