import { ApiClient as client } from '@/api/api'
import { AxiosPromise } from 'axios'
import { paths } from '@/interfaces/api/apidefinitions'
import InvoiceSoV from '@/io-modules/invoices/interfaces/responses/InvoiceSoV.ts'

export type NonContractedInvoiceEditingDataResponse = paths['v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/editing/non-contracted/{invoice_id?}']['get']['responses']['200']['content']['application/json']
export type VendorInvoiceEditingDataResponse = paths['v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/editing/vendor/{invoice_id?}']['get']['responses']['200']['content']['application/json']
export type MyInvoiceEditingDataResponse = paths['v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/editing/my/{invoice_id?}']['get']['responses']['200']['content']['application/json']

export type UpdateMyInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/my/{invoice_id}']['put']['requestBody']['content']['application/json']
export type UpdateVendorInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/vendor/{invoice_id}']['put']['requestBody']['content']['application/json']
export type UpdateNonContractedInvoiceRequest = paths['/api/v1/{subdomain}/{apptype}/invoices/{project_local_id}/invoice/non-contracted/{invoice_id}']['put']['requestBody']['content']['application/json']
export type ApplyFundingRequest = paths['v1/{subdomain}/{apptype}/invoices/{projectLocalId}/invoice/{invoice_id}/apply-funding']['post']['requestBody']['content']['application/json']
export default {
    /**
     * Gets data required to perform my invoice editing
     * @param projectMongoIdLocal
     * @param invoiceId
     */
    getMyInvoiceEditingData (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<MyInvoiceEditingDataResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/editing/my/${ invoiceId }`)
    },

    /**
     * Gets data required to perform non-contracted invoice editing
     * @param projectMongoIdLocal
     * @param invoiceId
     */
    getNonContractedInvoiceEditingData (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<NonContractedInvoiceEditingDataResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/editing/non-contracted/${ invoiceId }`)
    },

    /**
     * Gets data required to perform vendor invoice editing
     * @param projectMongoIdLocal
     * @param invoiceId
     */
    getVendorInvoiceEditingData (projectMongoIdLocal: string, invoiceId: string): AxiosPromise<VendorInvoiceEditingDataResponse> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }
        if (!invoiceId) {
            throw new Error('No Invoice ID Provided!')
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/invoice/editing/vendor/${ invoiceId }`)
    },

    saveMyInvoice (projectMongoIdLocal: string, data: UpdateMyInvoiceRequest, invoiceId: string): AxiosPromise {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.put(`/invoices/${ projectMongoIdLocal }/invoice/my/${ invoiceId }`, data)
    },

    saveNonContractedInvoice (projectMongoIdLocal: string, data: UpdateNonContractedInvoiceRequest, invoiceId: string): AxiosPromise {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.put(`/invoices/${ projectMongoIdLocal }/invoice/non-contracted/${ invoiceId }`, data)
    },

    saveVendorInvoice (projectMongoIdLocal: string, data: UpdateVendorInvoiceRequest, invoiceId: string): AxiosPromise {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.put(`/invoices/${ projectMongoIdLocal }/invoice/vendor/${ invoiceId }`, data)
    },

    applyFunding (projectLocalId: string, invoiceId: string, data: ApplyFundingRequest): AxiosPromise {
        return client.post(`/invoices/${ projectLocalId }/invoice/${ invoiceId }/apply-funding`, data)
    },

    getInvoiceAvailableActions (projectMongoIdLocal: string, invoiceId: string = ''): AxiosPromise<string[]> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        return client.get(`invoices/${ projectMongoIdLocal }/available-actions/${ invoiceId }`)
    },

    getInvoiceSoV (projectMongoIdLocal: string, contractId: string, invoiceId: string): AxiosPromise<InvoiceSoV> {
        if (!projectMongoIdLocal) {
            throw new Error('No Project Mongo ID Local Provided!')
        }

        if (!contractId) {
            throw new Error('No Contract ID Provided!')
        }

        if (invoiceId && '0' !== invoiceId) {
            return client.get(`/invoices/${ projectMongoIdLocal }/wbs/${ contractId }/${ invoiceId }`)
        }

        return client.get(`/invoices/${ projectMongoIdLocal }/wbs/${ contractId }`)
    },
}
