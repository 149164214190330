<template>
    <div v-if="!error && !loading" class="io-gross-billing-summary">
        <div class="io-gross-billing-summary__title">{{ $t('Gross Billing Summary') }}</div>
        <HorizontalBarChart :items="barChartItems" />
        <div class="io-gross-billing-summary__content">
            <div>
                <StatusPill variant="green-light" :text="grossBilledToDatePercentage" />
                <div>
                    <span class="io-gross-billing-summary__value">{{ grossBilledToDate }}</span>
                    <span class="io-gross-billing-summary__label">{{ $t('Previous Applications') }}</span>
                </div>
            </div>
            <div>
                <StatusPill variant="blue-light" :text="grossInvoicePercentage" />
                <div>
                    <span class="io-gross-billing-summary__value">{{ grossInvoiceAmount }}</span>
                    <span class="io-gross-billing-summary__label">{{ $t('Current Application') }}</span>
                </div>
            </div>
            <div>
                <StatusPill variant="gray-light" :text="balanceToCompletePercentage" />
                <div>
                    <span class="io-gross-billing-summary__value">{{ balanceToComplete }}</span>
                    <span class="io-gross-billing-summary__label">{{ $t('Balance to Complete') }}</span>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="io-gross-billing-summary__placeholder">
        <RequestedDataErrorPlaceholder :title="$t('Gross Billing Summary')" :loading="loading" @reload="fetchData" />
    </div>
</template>

<script lang="ts" src="./GrossBillingSummary.ts"></script>
<style lang="scss" src="./GrossBillingSummary.scss" scoped></style>
