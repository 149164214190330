<template>
    <div
        :class="['io-initials', bigAvatar ? 'io-img-holder-big-avatar' : '']"
        :style="{'backgroundColor': backgroundColor, 'color': fontColor}"
        data-testid="initials-component"
    >
        {{ initials || getInitials }}
    </div>
</template>

<script>
    import cachedListMixin from '@/mixins/cachedListMixin'

    export default {
        props: {
            fullName: { type: String, required: false },
            bgColor: { type: String, required: false },
            bigAvatar: { type: Boolean, required: false },
            id: { type: [Number, String], required: false },
            initials: { type: String, required: false },
            isArchived: { type: Boolean, required: false, default: false }
        },
        mixins: [cachedListMixin],
        data () {
            return {
                color: null
            }
        },
        computed: {
            getInitials () {
                if (this.fullName && typeof this.fullName === 'string') {
                    const dividedFullName = this.fullName.split(' ')
                    if (dividedFullName && dividedFullName.length > 1) {
                        return dividedFullName[0].charAt(0).toUpperCase() + dividedFullName[1].charAt(0).toUpperCase()
                    } else if (dividedFullName && dividedFullName.length > 0) {
                        return dividedFullName[0].charAt(0).toUpperCase()
                    }
                }
            },
            backgroundColor () {
                return this.fullName ? this.calculateColor() : this.bgColor || this.color
            },
            fontColor () {
                return this.isArchived ? '#63666E' : '#1A1A1A'
            }
        },
        async mounted () {
            if (this.id) {
                let emp = await this.getOne('employees', this.id)
                this.color = emp._color
            }
        },
        methods: {
            calculateColor () {
                if (this.isArchived) {
                    return '#E2E4EA'
                }

                if (this.bgColor) {
                    return this.bgColor
                }

                const colors = [
                    '#E9CFCF', //--oyster-pink
                    '#E8E9CF', //--white-rock
                    '#DFE9CF', //--dirty-white
                    '#D5E9CF', //--green-tea
                    '#CFE9DA', //--gainsboro
                    '#CFE6E9', //--botticelli
                    '#CFDBE9', //--columbia-blue
                    '#D0CFE9', //--soap
                    '#DCCFE9', //--lavender
                    '#E9CFE6', //--melanie
                    '#E9CFD7'  //--queen-pink
                ]

                let sum = 0
                for (let i = 0; i < this.fullName?.length; i++) {
                    sum += this.fullName.charCodeAt(i)
                }

                return colors[sum % colors.length]
            }
        },
    }
</script>
