const ProjectProvider = () => import(/* webpackChunkName: "project-provider" */ '@/layouts/project-provider/ProjectProvider.vue')
const MeetingsMainView = () => import(/* webpackChunkName: "meetings" */ '@/modules/projects/modules/apps/common/meetings/views/Main/Main.vue')
const MeetingView = () => import(/* webpackChunkName: "meeting-view" */ '@/modules/projects/modules/apps/common/meetings/views/MeetingView/MeetingView.vue')
const MeetingDetailsView = () => import(/* webpackChunkName: "meeting-details-view" */ '@/modules/projects/modules/apps/common/meetings/views/MeetingDetailsView/MeetingDetailsView.vue')
const MeetingMembersView = () => import(/* webpackChunkName: "meeting-details-view" */ '@/modules/projects/modules/apps/common/meetings/views/MeetingMembersView/MeetingMembersView.vue')
const MeetingFilesView = () => import(/* webpackChunkName: "meeting-files-view" */ '@/modules/projects/modules/apps/common/meetings/views/MeetingFilesView/MeetingFilesView.vue')
const MeetingMinutes = () => import(/* webpackChunkName: "meeting-minutes-view" */ '@/modules/projects/modules/apps/common/meetings/views/MeetingMinutesView/MeetingMinutesView.vue')

export default [
    {
        path: '/project/:pid',
        component: ProjectProvider,
        children: [
            {
                path: 'meetings',
                name: 'meetings',
                meta: { ignoreProjectLayout: true, title: 'Meetings', name: 'Meetings', navigationKey: 'meetings' },
                component: MeetingsMainView,
            },

            {
                path: 'meeting-view/:id',
                name: 'meeting-view',
                component: MeetingView,
                meta: {
                    ignoreProjectLayout: true,
                    name: 'Project Meeting',
                    inviteType: 'meeting_minutes',
                    title: 'Meeting Details',
                },
                redirect: { name: 'meeting-details' },
                children: [
                    {
                        path: 'details',
                        name: 'meeting-details',
                        component: MeetingDetailsView,
                        meta: {
                            ignoreProjectLayout: true,
                            name: 'Project Meeting',
                            inviteType: 'meeting_minutes',
                            title: 'Meeting Details',
                            navigationKey: 'meetings',
                        },
                    },
                    {
                        path: 'members',
                        name: 'meeting-members',
                        component: MeetingMembersView,
                        meta: {
                            ignoreProjectLayout: true,
                            name: 'Project Meeting',
                            inviteType: 'meeting_minutes',
                            title: 'Meeting Members',
                            navigationKey: 'meetings',
                        },
                    },
                    {
                        path: 'minutes',
                        name: 'meeting-minutes',
                        component: MeetingMinutes,
                        meta: {
                            ignoreProjectLayout: true,
                            name: 'Project Meeting',
                            inviteType: 'meeting_minutes',
                            title: 'Meeting Minutes',
                            navigationKey: 'meetings',
                        },
                    },
                    {
                        path: 'files',
                        name: 'meeting-files',
                        component: MeetingFilesView,
                        meta: {
                            ignoreProjectLayout: true,
                            name: 'Project Meeting',
                            inviteType: 'meeting_minutes',
                            title: 'Meeting Files',
                            navigationKey: 'meetings',
                        },
                    },
                ],
            },
        ],
    },
]
