<template>
    <div data-testid="cost-impact">
        <IOLabel>{{ $t('Cost Impact') }}</IOLabel>

        <div class="flex gap-3">
            <Radio
                v-for="option in options"
                :key="option.type"
                :label-text="option.label"
                input-name="cost-impact"
                :radio-value="option.type"
                v-model="value.occurred"
            />
        </div>

        <div v-if="value.occurred === ImpactType.YES">
            <div class="flex gap-3 mt-7 items-center">
                <FieldNumeric
                    sign="$"
                    :class="{ 'error': errorMessage }"
                    :options="{ numeralPositiveOnly: false, numericOnly: false }"
                    :disabled="value.isValueTbd"
                    :placeholder="value.isValueTbd ? '' : '0.00'"
                    v-model="value.value"
                />

                <Checkbox
                    :label-text="$t('TBD')"
                    v-model="value.isValueTbd"
                />
            </div>

            <span v-if="errorMessage" class="io-error-message">{{ errorMessage }}</span>
        </div>
    </div>
</template>

<script lang="ts" src="./CostImpact.ts"></script>
<style scoped lang="scss" src="./CostImpact.scss"></style>
