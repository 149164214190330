import { defineComponent } from 'vue'
import IOSingleInfoRow from '@/components/atoms/io-single-info-row/IOSingleInfoRow.vue'
import { mapActions, mapState } from 'pinia'
import { mapState as mapStateVuex } from 'vuex'
import { invoiceViewStore } from '@/io-modules/invoices/stores/invoiceView'
import RequestedDataErrorPlaceholder from '@/io-modules/invoices/components/requested-data-error-placeholder/RequestedDataErrorPlaceholder.vue'
import ContractDetailsChildrenRouteName from '@/io-modules/project-contracts/enums/ContractDetailsChildrenRouteName.ts'

export default defineComponent ({
    name: 'ContractSummary',
    components: {
        IOSingleInfoRow,
        RequestedDataErrorPlaceholder,
    },
    data () {
        return {
            loading: false,
            error: false,
        }
    },
    computed: {
        ...mapStateVuex('project', ['projectObj']),

        ...mapState(invoiceViewStore, ['invoiceContractSummary']),

        originalContractAmount (): string {
            const value = this.invoiceContractSummary.original_amount || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        executedChangeOrders (): string {
            const value = this.invoiceContractSummary.executed_amount || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },

        adjustedContractAmount (): string {
            const value = this.invoiceContractSummary.total_amount || 0
            return this.$filters.formatProjectCurrencyInt(value)
        },
    },

    mounted () {
        this.fetchData()
    },

    methods: {
        ...mapActions(invoiceViewStore, ['fetchInvoiceContractSummary']),

        async fetchData (): Promise<void> {
            try {
                this.loading = true
                await this.fetchInvoiceContractSummary(this.projectObj.project_local_id, this.$route.params.id)
                this.loading = false
                this.error = false
            } catch (e) {
                this.error = true
            } finally {
                this.loading = false
            }
        },

        goToContract (): void {
            const route = this.$router.resolve({ name: ContractDetailsChildrenRouteName.Details, params: { pid: this.$route.params.pid, id: this.invoiceContractSummary.id } })
            window.open(route.href, '_blank', 'noopener')
        },
    },
})
