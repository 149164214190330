<template>
    <span
        ref="textRef"
        v-tooltip="tooltipContent"
        class="io-truncated-text"
        data-testid="truncated-text"
    >
        {{ text }}
    </span>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import { Tooltip } from '@/interfaces/components/tooltip/Tooltip.ts'

    export default defineComponent({
        name: 'TruncatedText',
        props: {
            linesLimit: { type: Number, default: 1 },
            text: { type: String, default: '' }
        },
        data () {
            return {
                actualLinesCount: 0,
                resizeObserver: null as ResizeObserver | null,
            }
        },
        computed: {
            isTooltipVisible (): boolean {
                return this.actualLinesCount > this.linesLimit
            },
            tooltipContent (): Tooltip {
                return {
                    content: this.text,
                    visible: this.isTooltipVisible,
                }
            },
        },
        mounted () {
            this.calculateAndSetLinesCount()

            this.resizeObserver = new ResizeObserver(() => {
                this.calculateAndSetLinesCount()
            })

            if (this.$refs.textRef) {
                this.resizeObserver.observe(this.$refs.textRef as Element)
            }
        },
        beforeUnmount () {
            if (this.resizeObserver && this.$refs.textRef) {
                this.resizeObserver.unobserve(this.$refs.textRef as Element)
            }
        },
        methods: {
            calculateLines () {
                const style = window.getComputedStyle(this.$refs.textRef)
                let lineHeight = parseInt(style.lineHeight)
                const fontSize = parseInt(style.fontSize)

                if(isNaN(lineHeight)) {
                    lineHeight = fontSize * 1.2
                }

                const hiddenElement = document.createElement('div')
                hiddenElement.style.font = style.font
                hiddenElement.style.fontSize = style.fontSize
                hiddenElement.style.lineHeight = style.lineHeight
                hiddenElement.style.width = style.width
                hiddenElement.style.visibility = 'hidden'
                hiddenElement.style.whiteSpace = 'pre-wrap'
                hiddenElement.textContent = this.$refs.textRef.textContent || ''

                document.body.appendChild(hiddenElement)

                const actualLinesCount = Math.floor(hiddenElement.offsetHeight / lineHeight)

                document.body.removeChild(hiddenElement)

                return actualLinesCount
            },
            calculateAndSetLinesCount () {
                this.actualLinesCount = this.calculateLines()
            }
        }
    })
</script>

<style lang="scss" scoped>
    .io-truncated-text {
        display: -webkit-box;
        -webkit-line-clamp: v-bind(linesLimit);
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
</style>
