import { cloneDeep, isObject } from 'lodash'

export function SET_CUSTOM_DATA (state, data) {
    if (isObject(data)) {
        for (let key in data) {
            state.customData[key] = cloneDeep(data[key])
        }
    }
}

