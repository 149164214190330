import swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

window.swal = swal;

export const SweetAlert = {
    methods: {
        alert (options) {
            swal(options)
        },
        alertSuccess ({title = 'Success!', text = 'That\'s all done!', timer = 1000, showConfirmationButton = false} = {}) {
            this.alert({
                title: title,
                text: text,
                timer: timer,
                showConfirmButton: showConfirmationButton,
                type: 'success'
            })
        },
        alertError ({title = 'Error!', text = 'Oops...Something went wrong'} = {}) {
            this.alert({
                title: title,
                text: text,
                type: 'error'
            })
        },
        alertInfo ({title = '', text = ''} = {}) {
            this.alert({
                title: title,
                text: text,
                type: 'info'
            })
        },
        alertWarning ({title = '', text = ''} = {}) {
            this.alert({
                title: title,
                text: text,
                type: 'warning'
            })
        },
        confirm (callback, options, cancelAction) {
            options = Object.assign({
                text: 'Are you sure you want to do that?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Yes'
            }, options)

            // eslint-disable-next-line
            swal(options).then((result) => {
                if (result.value) {
                    callback(result)
                } else if (result.dismiss === 'cancel') {
                    cancelAction(result)
                }
            }).catch(swal.noop)  //eslint-disable-line
        }
    }
}
