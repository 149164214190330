import { defineStore } from 'pinia'
import { RightPanelTabsStoreStateInterface } from '@/io-modules/documents/interfaces/RightPanelTabsStoreStateInterface'
import { RightPanelTabs } from '@/io-modules/documents/enums/RightPanelTabs'
import { MarkupsTabs } from '@/io-modules/documents/enums/MarkupsTabs'
import { AnnotationTypes } from '@/io-modules/documents/enums/AnnotationTypes'


export const rightPanelTabsStore = defineStore('rightPanelTabs', {
    state: (): RightPanelTabsStoreStateInterface => ({
        rightPanelActiveTab: RightPanelTabs.DETAILS,
        markupsActiveTab: MarkupsTabs.COMMENT,
    }),
    getters: {
        isDetailsTabActive (): boolean {
            return this.rightPanelActiveTab === RightPanelTabs.DETAILS
        },
        isMarkupsTabActive (): boolean {
            return this.rightPanelActiveTab === RightPanelTabs.MARKUPS
        }
    },
    actions: {
        setRightPanelActiveTab (tab: RightPanelTabs): void {
            this.rightPanelActiveTab = tab
        },
        setMarkupsActiveTabByAnnotationType (annotationType: AnnotationTypes): void {
            this.setRightPanelActiveTab(RightPanelTabs.MARKUPS)

            const annotationTypesList = {
                basic: MarkupsTabs.BASIC,
                punch: MarkupsTabs.PUNCH,
                rfi: MarkupsTabs.RFI,
                comment: MarkupsTabs.COMMENT
            }
            this.markupsActiveTab = annotationTypesList[annotationType]
        }
    }
})
