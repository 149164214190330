import { defineComponent } from 'vue'
import { Color } from '@/components/atoms/IOModal/IOModal.ts'
import IOModal from '@/components/atoms/IOModal/IOModal.vue'
import IOTextarea from '@/components/atoms/IOTextarea/IOTextarea.vue'
import IOSwitcher from '@/components/atoms/IOSwitcher/IOSwitcher.vue'

export default defineComponent({
    name: 'RemoveCoverPageModal',
    components: {
        IOSwitcher,
        IOModal,
        IOTextarea,
    },
    emits: ['close', 'exportInvoice'],
    data () {
        return {
            Color,
            removeCoverPage: '0',
            toggleSwitchOptions: {
                items: {
                    style: 'text',
                    delay: 0,
                    disabled: false,
                    labels: [
                        { name: '1', text: 'NO' },
                        { name: '0', text: 'YES' },
                    ],
                },
            },
            selectedMainTabName: '0',
        }
    },
    methods: {
        closeModal (): void {
            this.$emit('close')
        },

        exportInvoice (): void {
            this.$emit('close')
            this.$emit('exportInvoice', this.removeCoverPage)
        },
        changeCoverPage (checked: boolean): void {
            this.removeCoverPage = checked ? '0' : '1'
        },
    },
})
