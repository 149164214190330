<template>
    <div class="io-navbar-tile" v-click-outside="closeList">

        <transition name="mask">
            <div class="io-navbar-tile__mask" v-if="showList" @click="toggleShowList"></div>
        </transition>

        <div class="io-navbar-tile__content" @click="toggleShowList">
            <div class="io-navbar-tile__icon"><i class="far fa-bell"></i></div>
            <div class="io-navbar-tile__dot" v-if="newNotificationEvent">{{ items.length }}</div>
        </div>

        <transition name="notifications-menu">
            <div class="io-notifications-menu" v-if="showList" :class="{'io-empty': !items.length}">
                <div class="io-notifications-menu__header">
                    <div class="io-notifications-menu__title">{{ $t('Notifications') }}</div>
                    <div class="io-notifications-menu__indicator">{{ items.length }}</div>
                    <div class="io-notifications-menu__mark-read" @click="markAllAsRead('notification')">
                        {{ $t('Mark all as read') }}
                    </div>
                </div>

                <template v-if="items && items.length">
                    <div class="io-notifications-menu__content">

                        <template v-for="item in items" :key="item._id">
                            <NotificationItem
                                :item="item"
                                @closeList="closeList"
                            />
                        </template>

                    </div>

                </template>

                <template v-else>
                    <div class="io-messages-placeholder">
                        <div class="io-messages-placeholder__illustration">
                            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120">
                                <g fill="none" fill-rule="evenodd">
                                    <g fill-rule="nonzero">
                                        <g>
                                            <g>
                                                <path fill="var(--accent)" d="M117.391 104.348H2.61c-1.441 0-2.609-1.167-2.609-2.609 0-10.715 5.59-29.355 20.87-38.076V39.13C20.87 17.553 38.423 0 60 0s39.13 17.553 39.13 39.13v24.526C114.41 72.307 120 90.98 120 101.739c0 1.442-1.168 2.609-2.609 2.609z" transform="translate(-919 -179) translate(856 80) translate(63 99)"/>
                                                <path fill="var(--nav-illustration-accent)" d="M60 50.5c14.021 0 28.124 2.772 39.13 8.202l.002 5C88.125 58.272 74.022 55.5 60 55.5c-14.022 0-28.125 2.773-39.132 8.202l.001-5C31.876 53.272 45.98 50.5 60 50.5z" transform="translate(-919 -179) translate(856 80) translate(63 99)"/>
                                                <path fill="var(--accent)" d="M60 120c-29.817 0-60-6.272-60-18.26 0-11.99 30.183-18.262 60-18.262s60 6.272 60 18.261C120 113.728 89.817 120 60 120z" transform="translate(-919 -179) translate(856 80) translate(63 99)"/>
                                                <path fill="#000" d="M60 120c-29.817 0-60-6.272-60-18.26 0-11.99 30.183-18.262 60-18.262s60 6.272 60 18.261C120 113.728 89.817 120 60 120z" opacity=".3" transform="translate(-919 -179) translate(856 80) translate(63 99)"/>
                                                <path fill="var(--accent)" d="M44.43 85.878l-.022.019c.323 8.36 7.153 15.052 15.592 15.052s15.27-6.692 15.592-15.052l-.01-.018c-5.096-.385-10.343-.583-15.582-.583-5.235 0-10.478.198-15.57.582z" transform="translate(-919 -179) translate(856 80) translate(63 99)"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div class="io-messages-placeholder__content">
                            <div class="io-messages-placeholder__title">{{ $t('No notifications') }}.</div>
                            <div class="io-messages-placeholder__caption">
                                {{ $t('Nothing new to see here.') }} <br> {{ $t('Check back later!') }}
                            </div>
                        </div>
                    </div>
                </template>

            </div>
        </transition>

    </div>
</template>

<script src="./notifications.js"></script>
