<template>
    <div class="io-single-checklist-item" :class="[{ 'completed': isCompleted }, {'is-next': isNextAction} ]">
        <i :class="icon"></i>
        <div class="io-single-checklist-item__text">
            <p>{{ $t(title) }}</p>
            <a :href="learnMoreUrl" target="_blank">{{ $t('Learn More') }}</a>
        </div>
        <div class="io-single-checklist-item__button-holder">
            <div class="io-single-checklist-item__button">
                <template v-if="isCompleted">
                    <div class="io-single-checklist-item__completed">
                        <i class="icon-check-circle-2-2"></i>
                        <p>{{ $t('Done') }}</p>
                    </div>
                </template>
                <template v-else>
                    <IOButton
                        size="small"
                        :variant="isNextAction ? 'primary' : 'secondary'"
                        @click="redirect"
                    >
                        {{ $t('Start') }}
                    </IOButton>
                </template>
            </div>
            <ActionDropdown
                icon-class="icon-more-vert-2"
                :icon-tooltip="$t('More actions')"
            >
                <div
                    class="io-single-option"
                    @click="removeFromChecklist()"
                >
                    <span>{{ $t('Remove from checklist') }}</span>
                </div>
                <div
                    v-if="isCompleted"
                    class="io-single-option"
                    @click="undoneChecklistItem()"
                >
                    <span>{{ $t('Mark as undone') }}</span>
                </div>
                <div
                    v-if="!isCompleted"
                    class="io-single-option"
                    @click="doneChecklistItem()"
                >
                    <span>{{ $t('Mark as done') }}</span>
                </div>
            </ActionDropdown>
        </div>
    </div>
</template>
<script lang="ts" src="./SingleChecklistItem.ts"></script>
<style lang="scss" src="./SingleChecklistItem.scss" scoped></style>
