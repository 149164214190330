<template>
    <Table
        v-show="getAppliedFundingSources.length"
        class="io-funding-sources-table"
        :headers="headers"
        :items="getAppliedFundingSources"
    >
        <template #tbody="{ sortedItems }">
            <template
                v-for="(item, itemIndex) in sortedItems"
                :key="item.id"
            >
                <tr>
                    <td
                        v-for="(header, index) in headers"
                        :key="index"
                        :class="`align-${header.align || 'start'}`"
                        :style="header.extraStyles"
                    >
                        <DropdownButton
                            v-if="CellType.Dropdown === header.valueType && isEditingMode"
                            buttonClass="io-custom-dropdown"
                            name=""
                        >
                            <template #selectedItem>
                                <span v-if="item.id">
                                    {{ item.name }} - {{ $filters.formatProjectCurrencyInt(item.unallocated_amount) }}
                                </span>
                                <span v-else></span>
                            </template>
                            <template #default>
                                <template v-if="getAvailableFundingSources.length">
                                    <div
                                        v-for="item in getAvailableFundingSources"
                                        :key="item.id"
                                        class="io-dropdown-option"
                                        @click="selectFundingSource(item, itemIndex)"
                                    >
                                        {{ item.name }} - {{ $filters.formatProjectCurrencyInt(item.unallocated_amount) }}
                                    </div>
                                </template>

                                <div v-else class="io-dropdown-option__placeholder">
                                    <div class="io-dropdown-option__placeholder__title">{{ getAppliedFundingSources.length ? $t('You\'ve added all funds') : $t('No funds in the project') }}</div>
                                    <div class="io-dropdown-option__placeholder__subtitle">
                                        {{ $t('To add one, go to the') }}
                                        <IOButton variant="link" size="small" @click="goToFunds">
                                            {{ $t('Funds Module') }}
                                        </IOButton>
                                    </div>
                                </div>
                            </template>
                        </DropdownButton>
                        <field-numeric-int
                            v-else-if="CellType.InputNumInt === header.valueType && isEditingMode"
                            :key="header.field + randomID"
                            placeholder="0"
                            :modelValue="item[header.field]"
                            :options="{ numeralPositiveOnly: false, numericOnly: false }"
                            :showRounded="true"
                            :sign="header.sign"
                            :max="header.maxValue(item)"
                            :min="header.minValue(item)"
                            @update:modelValue="data=>updateValue(header, item, data)"
                        />
                        <span v-else-if="CellType.Actions === header.valueType && isEditingMode" class="io-column-actions">
                            <template v-for="(button, index) in header.customValue(item)" :key="index">
                                <IOButton
                                    v-if="undefined !== button.isDisplayed ? button.isDisplayed : true"
                                    v-tooltip.top="{
                                        content: button.tooltip?.text,
                                        visible: undefined !== button.tooltip?.text
                                    }"
                                    :variant="button.variant"
                                    :icon="button.icon"
                                    @click.stop="button.onClick(item, itemIndex)"
                                >
                                    <span v-if="button.text">{{ button.text }}</span>
                                </IOButton>
                            </template>
                        </span>
                        <span v-else>{{ header.customValue(item) }}</span>
                    </td>
                </tr>
            </template>
        </template>
    </Table>
</template>

<script lang="ts" src="./FundingSourcesTable.ts"></script>
<style lang="scss" src="./FundingSourcesTable.scss" scoped></style>
