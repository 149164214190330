import { defineAsyncComponent } from 'vue'
import { orderBy } from 'lodash'
import isLoggedMixin from '@/mixins/isLoggedMixin'
import { mapGetters, mapState } from 'vuex'
import modalMixinn from '@/mixins/modalMixin'
import moment from 'moment'
import initials from '@/components/new-theme/initials'
import DonutChart from '@/components/atoms/DonutChart/DonutChart.vue'
import Invitations from './invitations.vue'
import Discussions from './discussions.vue'
import Notifications from './notifications.vue'
import notificationMixin from './mixins/notificationMixin'
import Profile from './profile.vue'
import { isFunction } from 'lodash'

const EditForm = defineAsyncComponent(() => import(/* webpackChunkName: "form-generator" */ '@/views/default/edit.vue'))

export default {
    name: 'TopBar',
    setup () {
        return {
            orderBy,
        }
    },
    data () {
        return {
            loaded: false,
            triggerRefresh: false,
            topBarActive: null,
            notificationType: 2,
            newNotificationEvent: false,
            newChatEvent: false,
            newInviteEvent: false,
            // STORAGE data
            totalStorage: 0,
            usedStorage: 0,
            usedStoragePercent: 0,
            chartColor: 'var(--accent)',
            invitationsArr: [],
            icons: {
                'todo-employee-assigned': 'fa-user-plus',
                'todo-employee-removed': 'fa-user-times',
                'todo-status-changed': 'fa-info-circle',
                'todo-done': 'fa-check-circle',
                'todo-date-changed': 'fa-calendar-alt',
                'communication-new-message': 'fa-comments',
                'communication-employee-assigned': 'fa-comment-medical',
                'revision-request': 'fa-info-circle',
                'rfp-new-bid': 'fa-info-circle',
                'rfp-has-been-read': 'fa-info-circle',
                'rfi-submit': 'fa-info-circle',
                'invitation-task': 'fa-user-plus',
                'payapp-received': 'fa-info-circle',
                'payapp-revision-request': 'fa-info-circle',
                'payapp-revised-resubmit': 'fa-info-circle',
                'payapp-pending-modified': 'fa-info-circle',
                'payapp-approved': 'fa-check-circle',
                'payapp-rejected': 'fa-times-circle',
                'payapp-paid': 'fa-check-circle',
                'funding-package-submitted': 'fa-info-circle',
                'funding-package-approved': 'fa-check-circle',
                'funding-package-rejected': 'fa-times-circle',
                'chat-new-message': 'fa-info-circle',
                'contract-message': 'fa-info-circle',
                'submittal-sent': 'fa-info-circle',
                'submittal-answered-sent': 'fa-info-circle',
                'meeting-minutes-send-agenda': 'fa-info-circle',
                'meeting-minutes-share': 'fa-info-circle',
                'storage-warning': 'fa-thin fa-database',
                'drawing-set': 'fa-file-alt',
                'insurance-will-expire-in-several-days': 'fas fa-exclamation-triangle',
                'insurance-expired': 'fas fa-exclamation-triangle',
                'user-mentioned-in-comment': 'fa-thin fa-clipboard',
                'admin-area-data-importer-completed': 'fa-check-circle',
                'admin-area-data-importer-failed': 'fa-times-circle',
                'specification-draft-published': 'icon-clipboard-text',
            },
        }
    },
    components: {
        EditForm,
        initials,
        DonutChart,
        Invitations,
        Discussions,
        Notifications,
        Profile,
    },
    watch: {
        'triggerRefresh': function () {
            this.$store.dispatch('appStore/refreshAuthData')
            this.triggerRefresh = false
        },
        'refreshData': function () {
            if (this.refreshData !== false) {
                setTimeout(() => {
                    this.$store.dispatch('modal/setRefresh', false, { root: true })
                }, 1000)
            }
        },
    },
    computed: {
        ...mapState('topbar', {
            chat: state => state.chat,
            alerts: state => state.alerts,
        }),
        ...mapState('main', {
            hoverLeftMenu: state => state.hoverLeftMenu,
            lockLeftMenu: state => state.lockLeftMenu,
        }),
        authData: function () {
            let xx = this.getAuthData
            if (xx) {
                this.loaded = true
            }
            return xx
        },
        ...mapState('modal', {
            refreshData: state => state.refresh,
        }),
        ...mapState('notification', {
            showNotifications: state => state.show,
        }),
        ...mapGetters('appStore', [
            'getAuthStatus',
            'getAuthData',
            'getAuthAccess',
        ]),
        logo: function () {
            let xx = this.getAuthData
            if (this.loaded) {
                this.loaded = false
                if (xx.logo) {
                    return xx.logo
                }
            }
        },
        isOpenCaOrPRo () {
            return this.authData.accountType === 1 || this.authData.is_open_ca_plus || (this.authData.is_open_ca === 0 && this.authData.is_open_ca_plus === 0)
        },
        actions () {
            let actions = [{
                label: 'Add Task',
                icon: '/new-theme/img/lm-bid-request.svg',
                condition: true,
                onClick: 'openModal',
                onClickParam: 'modal-task',
                order: this.isOpenCaOrPRo ? 1 : 2,
            }]

            return actions
        },
    },
    mixins: [isLoggedMixin, modalMixinn, notificationMixin],
    methods: {
        resizeLeftMenu (value) {
            this.$store.dispatch('main/setHoverLeftMenu', value)
        },
        openModal (modal) {
            this.closeTopBarMenu()
            this.$store.dispatch('modal/setShow', modal, { root: true })
        },
        openTopBarMenu (el) {
            if (this.topBarActive !== el) {
                this.topBarActive = el
                if (el === 'element-0') {
                    this.newChatEvent = false
                } else if (el === 'element-3') {
                    this.newNotificationEvent = false
                }
            } else {
                this.closeTopBarMenu()
            }
        },
        async getStorageInfo () {
            const { data } = await this.$api.get('cloud-space-info')
            this.usedStorage = data.used
            this.totalStorage = data.total
            this.usedStoragePercent = Math.round((data.used / data.total) * 100)

            // change chart color based on value
            if (this.usedStoragePercent > 70) {
                this.chartColor = 'var(--error-lighten)'
            } else {
                this.chartColor = 'var(--accent)'
            }
        },
        closeTopBarMenu () {
            this.topBarActive = null
        },
        goTo: function (url) {
            window.location.href = url
        },
        goToVue (name) {
            this.closeTopBarMenu()
            this.$router.push({ name: name })
        },

        /**
         * Register and ask for notifications for current user, through websockets
         */
        afterConnect () {
            this.$api.post('/notifications/unread')
        },

        getNotifications (data) {
            const self = this
            this.$store.dispatch('notificationToastr/removeAllAlerts')

            this.alerts.items = Object.keys(data.notifications).map((key) => {
                let item = data.notifications[key]
                item.icon = self.icons[item.actionType] || ''
                item.action = self.notificationCallToActions(item)
                if (!item.read) {
                    self.newNotificationEvent = true
                    this.$store.dispatch('notificationToastr/addAlert', item)
                }
                return item
            })

            // set current tab for notifications
            if (this.alerts.items.length > 0) {
                this.notificationType = 1
            }

            this.chat.items = Object.keys(data.chat).map((key) => {
                let item = data.chat[key]
                item.icon = self.icons[item.actionType] || ''
                item.action = self.notificationCallToActions(item)
                if (!item.read) {
                    self.newChatEvent = true
                }
                return item
            })

            this.invitationsArr = Object.keys(data.invitations).map((key) => {
                let item = data.invitations[key]
                item.icon = self.icons[item.actionType] || ''
                item.action = self.notificationCallToActions(item)
                if (!item.read) {
                    self.newInviteEvent = true
                    this.$store.dispatch('notificationToastr/addAlert', item)
                }
                return item
            })
        },


        notificationCallToActions (notification) {
            switch (notification.actionType) {
            case 'user-mentioned-in-comment':
                return {
                    url: {
                        name: 'contract-document-created-view-only',
                        params: {
                            id: notification.additionalParams.contract_id,
                            pid: notification.additionalParams.project_id,
                            documentId: notification.additionalParams.document_id,
                        },
                        query: {
                            annotationId: notification.relatedId,
                        },
                    },
                    text: 'View Contract',
                    show: true,
                }
            case 'storage-warning':
                return {
                    url: '/settings/company/general',
                    text: this.$t('Upgrade Subscription'),
                    // @note show the upgrade notification link only if logged used is admin
                    show: this.isRole(['ROLE_PRINCIPAL', 'ROLE_ADMIN']),
                }
            case 'drawing-set':
                return {
                    show: true,
                    text: this.$t('View Set'),
                    url: {
                        name: 'project-sheets-list',
                        params: {
                            pid: notification.additionalParams.pid,
                            id: notification.relatedId,
                        },
                    },
                }
            case 'drawing-set-comment':
                return {
                    show: true,
                    text: this.$t('View Comment'),
                    url: {
                        name: 'project-sheets-view',
                        params: {
                            pid: notification.additionalParams.pid,
                            id: notification.additionalParams.drawing_set_id,
                            version_id: notification.additionalParams.drawing_version_id,
                        },
                        query: {
                            comment_id: notification.additionalParams.annotation_uuid,
                        },
                    },
                }
            case 'section-version-tag-user':
                return {
                    show: true,
                    text: this.$t('View Comment'),
                    url: {
                        name: 'specifications-show',
                        params: {
                            pid: notification.additionalParams.pid,
                            id: notification.additionalParams.section_version_id,
                        },
                        query: {
                            markup_id: notification.additionalParams.markup_id,
                            'sidebar-tab': 'details',
                        },
                    },
                }
            case 'specification-draft-published':
                return {
                    show: true,
                    text: this.$t('View Narrative'),
                    url: {
                        name: 'specifications-narratives',
                        params: {
                            pid: notification.additionalParams.projectId,
                            id: notification.relatedId,
                        },
                    },
                }
            case 'submittal-approved':
            case 'submittal-rejected':
                return {
                    show: true,
                    text: this.$t('View'),
                    url: {
                        name: 'submittals-viewer-details',
                        params: {
                            pid: notification.additionalParams.projectId,
                            id: notification.relatedId,
                        },
                    },
                }
            default:
                return null
            }
        },

        async newNotification (data) {
            data.notification.icon = this.icons[data.notification.actionType] || ''
            data.notification.action = this.notificationCallToActions(data.notification)
            if (data.notification.type === 'notification') {
                await this.$store.dispatch('notificationToastr/addAlert', data.notification)
                this.alerts.items.unshift(data.notification)
                this.notificationType = 1
                this.newNotificationEvent = true
            } else if (data.notification.type === 'chat') {
                this.chat.items = this.chat.items.filter(item => item._id !== data.notification._id)
                this.chat.items.unshift(data.notification)
                this.newChatEvent = true
            } else {
                await this.$store.dispatch('notificationToastr/addAlert', data.notification)
                this.invitationsArr.unshift(data.notification)
                this.newInviteEvent = true
            }
        },

        removeNotifications (data) {
            if (!data.eventType || data.eventType === 'notification') {
                this.alerts.items = this.alerts.items.filter(value => data.notifications.indexOf(value._id) === -1)
            }

            if (!data.eventType || data.eventType === 'chat') {
                this.chat.items = this.chat.items.filter(value => data.notifications.indexOf(value._id) === -1)
            }

            if (!data.eventType || data.eventType === 'invitation') {
                this.invitationsArr = this.invitationsArr.filter(value => data.notifications.indexOf(value._id) === -1)
            }

            if (this.alerts.items.length === 0) {
                this.newNotificationEvent = false
            }

            if (this.chat.items.length === 0) {
                this.newChatEvent = false
            }

            if (this.invitationsArr.length === 0) {
                this.newInviteEvent = false
            }
        },

        sprintf (text, data) {
            let i = 0

            function r (match, offset, string) {
                switch (match) {
                case '%$':
                    return this.$filters.formatCurrency(data[i++])
                case '%D':
                    return moment(data[i++], 'YYYY-MM-DD').format('MMM Do YYYY')
                default:
                    return data[i++]
                }
            }

            return text.replace(/(%[^%])/g, r)
        },

        logout () {
            localStorage.removeItem('contactsList')
            localStorage.removeItem('employeesList')
            localStorage.removeItem('projectsList')
            localStorage.removeItem('proposalsList')
            localStorage.removeItem('tasksgeneralList')
        },
        executeMethod (onClick, onClickParam) {
            if (onClick && onClickParam && isFunction(this[onClick])) {
                this[onClick](onClickParam)
            }
        },
        spaceUpdate (space) {
            const used = space.spaceInformation.used
            const total = space.spaceInformation.total

            this.usedStorage = used
            this.totalStorage = total

            this.usedStoragePercent = Math.round((used / total) * 100)

            // if (this.usedStoragePercent >= 70) {
            //     this.showNotification('error', 'Space limit dropped to' + freePercent)
            // }

            // change chart color based on value
            if (this.usedStoragePercent > 70) {
                this.chartColor = 'var(--error-lighten)'
            } else {
                this.chartColor = 'var(--accent)'
            }
        },

        onClearDot (type) {
            if (type === 'notification') {
                this.newNotificationEvent = false
            } else if (type === 'chat') {
                this.newChatEvent = false
            } else {
                this.newInviteEvent = false
            }
        },

        async acceptInvite (toaster) {
            await this.$store.dispatch('loadingBar/setLoading', true, { root: true })
            try {
                const { data } = await this.$api.post(`/invitation/accept/${ toaster.notification.relatedId }`)
                this.showNotification('success', data.msg)
                await this.$store.dispatch('cached/refreshList', 'projects')
                this.$router.push({ path: data.custom_url })
            } catch (error) {
                console.error(error)
                this.showNotification('error', error.response.data.message)
            } finally {
                await this.$store.dispatch('loadingBar/setLoading', false, { root: true })
            }
        },

        redirectToInvite (toaster) {
            this.$emit('closeList')
            this.markAlertAsRead(toaster.notification)
            this.$router.push({ name: 'invitation-view', params: { id: toaster.notification.relatedId } })
        },
    },
    beforeMount () {
        this.getStorageInfo()
    },
    mounted () {
        let self = this
        this.$nextTick()
            .then(() => {
                $('.main-container').on('click', function () {
                    self.closeTopBarMenu()
                })
            })
    },
    created () {
        this.$echo.private('UserChannel.' + this.authData.u_mongo)
            .listen('Notification.Event.NotificationsList', this.getNotifications)
            .listen('Notification.Event.Notification', this.newNotification)
            .listen('Notification.Event.RemoveNotifications', this.removeNotifications)

        this.afterConnect()

        // space change watch
        this.$echo.private('WorkspaceChannel.' + this.authData.company_mongo_id)
            .listen('Document.Events.CompanySpaceCalculateEvent', this.spaceUpdate)
    },
    unmounted () {
        this.$echo.private('UserChannel.' + this.authData.u_mongo)
            .stopListening('Notification.Event.Notification')
            .stopListening('Notification.Event.RemoveNotifications')
            .stopListening('Notification.Event.NotificationsList')

        this.$echo.private('WorkspaceChannel.' + this.authData.company_mongo_id)
            .stopListening('Document.Events.CompanySpaceCalculateEvent')
    },
}
