import i18n from '@/base/i18n'
import VueI18n from 'vue-i18n'
import { PopupConfig } from '@/layouts/offcanvas/popup-alert/PopupAlert'

class ConfirmationModalsService {
    static i18n: typeof VueI18n = i18n

    public static leaveWithoutChanges (confirmationCallback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to leave?'),
            caption: this.i18n.global.t('You have some unsaved changes. If you leave now, your changes will be lost.'),
            buttons: [
                {
                    text: this.i18n.global.t('Stay Here'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Leave Without Saving'),
                    class: 'io-btn-primary',
                    loaderOnAction: false,
                    action: confirmationCallback,
                },
            ],
        }
    }

    public static deleteInvoice (confirmationCallback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to delete this invoice?'),
            icon: 'icon-trash',
            iconColor: 'var(--error-darker)',
            caption: this.i18n.global.t('This action cannot be undone.'),
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Delete Invoice'),
                    class: 'io-btn-primary',
                    loaderOnAction: false,
                    action: confirmationCallback,
                },
            ],
        }
    }

    public static deleteAppPackage (confirmationCallback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to delete this application package?'),
            icon: 'icon-trash',
            iconColor: 'var(--error-darker)',
            caption: this.i18n.global.t('This action cannot be undone.'),
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Delete Application Package'),
                    class: 'io-btn-primary',
                    loaderOnAction: false,
                    action: confirmationCallback,
                },
            ],
        }
    }

    public static submitToAccountingInvoice (confirmationCallback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to submit this invoice to Accounting?'),
            caption: this.i18n.global.t('This will allow you to proceed with Invoice Payment'),
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Submit to Accounting'),
                    class: 'io-btn-primary',
                    loaderOnAction: false,
                    action: confirmationCallback,
                },
            ],
        }
    }

    public static submitToAccountingAppPackage (confirmationCallback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            title: this.i18n.global.t('Are you sure you want to submit this application package to Accounting?'),
            caption: this.i18n.global.t('This action will also submit each of the invoices within this application package to accounting.'),
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Submit to Accounting'),
                    class: 'io-btn-primary',
                    loaderOnAction: false,
                    action: confirmationCallback,
                },
            ],
        }
    }

    public static markAsPendingInternalApproval (confirmationCallback: () => void, cancelCallback: () => void = null): PopupConfig {
        return {
            icon: 'icon-arrow-right-rec',
            iconColor: 'var(--info)',
            title: this.i18n.global.t('Are you sure you want to mark this invoice as pending internal approval?'),
            buttons: [
                {
                    text: this.i18n.global.t('Cancel'),
                    class: 'io-btn-light',
                    loaderOnAction: false,
                    action: cancelCallback,
                },
                {
                    text: this.i18n.global.t('Yes, Mark as Pending Internal Approval'),
                    class: 'io-btn-primary',
                    loaderOnAction: false,
                    action: confirmationCallback,
                },
            ],
        }
    }
}

export default ConfirmationModalsService