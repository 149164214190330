<template>
    <div class="io-invite-modal__search-view">

        <template v-if="preselectedWorkspace">
            <SingleWorkspace
                :key="preselectedWorkspace.id"
                :value="preselectedWorkspace.shortName"
                :item="preselectedWorkspace"
                :description="preselectedWorkspace.description"
                :componentKey="componentKey"
                :isDisabled="true"
                v-model="selectedWorkspaceType"
            />
        </template>

        <template v-else-if="!preselectedWorkspaceType" v-for="workspace in workspacesList" :key="workspace.id">
            <SingleWorkspace
                :value="workspace.shortName"
                :item="workspace"
                :description="workspace.description"
                :componentKey="componentKey"
                v-model="selectedWorkspaceType"
            />
        </template>
    </div>
</template>
<script lang="ts" src="./SelectWorkspaceType.ts"></script>
