import { defineComponent } from 'vue'
import { Core } from '@pdftron/webviewer'

import getHighlightText from '@/io-modules/specifications/components/spec-reference-select/utils/getHighlightText'
import setHighlightTool from '@/io-modules/specifications/components/spec-reference-select/utils/setHighlightTool'

import WebViewer from '@/components/web-viewer/WebViewer.vue'

import { Annotation } from '@/io-modules/specifications/components/spec-reference-select/interfaces/Specification'

export default defineComponent({
    name: 'SpecificationWebViewer',
    extends: WebViewer,
    emits: ['annotationChanged', 'annotationSelected'],
    watch: {
        async url (url: string): Promise<void> {
            const document = await this.instance.Core.createDocument(url)
            this.instance.UI.loadDocument(document, { withCredentials: true })
        },
        async loaded (): void {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { docViewer, Tools } = this.instance
            setHighlightTool(docViewer, Tools)

            const emits = ['annotationChanged', 'annotationSelected']
            emits.forEach((name: string) => this.instance.Core.annotationManager.addEventListener(name, this.emitAnnotation(name)))

            this.instance.Core.documentViewer.addEventListener('documentLoaded', () => setHighlightTool(docViewer, Tools))
        },
    },
    methods: {
        emitAnnotation (name: 'annotationChanged' | 'annotationSelected'): Function {
            return async (annotations: Core.Annotations.MarkupAnnotation[], action: string): Promise<void> => {
                const { annotationManager, documentViewer } = this.instance.Core
                const [annotation] = annotations // working with only one annotation at a time

                /**
                 * WebViewer occasionally fires 'annotationChanged' event with 'null' annotation
                 * Checking author property to make sure that annotation is made by user
                 */
                if (!annotation.Author) {
                    return
                }

                /**
                 * After annotation is deleted, events are fired in the following order:
                 * 1. 'annotationChanged' with action 'delete'
                 * 2. 'annotationSelected' with action 'deselected'
                 * Because of this, we need to check if annotation is already deleted
                 * since we want to emit 'null' instead of deleted annotation
                 */
                annotation.IsDeleted = annotation.IsDeleted || action === 'delete'

                action === 'add' && this.keepAnnotation(annotation.Id)

                const payload: Annotation = annotation.IsDeleted
                    ? null
                    : {
                        action,
                        uuid: annotation.Id,
                        data: annotation,
                        text: getHighlightText(annotation, documentViewer),
                        xfdf: await annotationManager.exportAnnotations({ annotList: [annotation] }),
                    }
                this.$emit(name, payload)
            }
        },
        keepAnnotation (annotationId: string): void {
            const { annotationManager } = this.instance.Core

            annotationManager.getAnnotationsList().forEach((annotation: Core.Annotations.Annotation) =>
                annotation.Id !== annotationId && annotationManager.deleteAnnotation(annotation))
        },
    },
})
