export enum FeatureFlagsConsts {
    FOLDER_SHARRING = 'folder-sharing',
    INSURANCE_REQUIREMENTS = 'INSURANCE_REQUIREMENTS',
    PURCHASE_ORDERS = 'PURCHASE_ORDERS',
    GUARANTEED_MAXIMUM_PRICE_CONTRACT = 'GUARANTEED_MAXIMUM_PRICE_CONTRACT',
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    DATA_EXPORTER = 'DATA_EXPORTER',
    PROJECT_CREATION_NEW_WIZARD = 'PROJECT_CREATION_NEW_WIZARD',
    PROJECT_CHECKLIST_WIDGET = 'PROJECT_CHECKLIST_WIDGET',
    PROJECTS_LIST_NEW = 'PROJECTS_LIST_NEW',
    NEW_INVITATION_FACADE = 'NEW_INVITATION_FACADE',
    APPROVED_INVOICE_NOTIFICATION_MAIL = 'APPROVED_INVOICE_NOTIFICATION_MAIL',
    SUBMITTAL_ENHANCEMENTS = 'SUBMITTAL_ENHANCEMENTS',
    OXFORD_INTEGRATION_JDE_ASSET = 'OXFORD_INTEGRATION_JDE_ASSET',
    REPORTING_MODULE = 'REPORTING_MODULE',
    REPORTING_MODULE_ROLES = 'REPORTING_MODULE_ROLES',
    DOCUMENTS_V2 = 'DOCUMENTS_V2',
    INVOICE_TAX_INTEGRATION = 'INVOICE_TAX_INTEGRATION',
    BUDGET_V3_SITES = 'BUDGET_V3_SITES',
    BUDGET_COMMENTS = 'BUDGET_COMMENTS',
    BUDGET_CODES_AUTOINCREMENTING = 'BUDGET_CODES_AUTOINCREMENTING',
    LINK_SITE_AND_PHASES_WITH_ACCOUNTING_ENTITY = 'LINK_SITE_AND_PHASES_WITH_ACCOUNTING_ENTITY',
    BUDGET_LINE_TEMPLATE_IN_COST_CODE_TEMPLATE = 'BUDGET_LINE_TEMPLATE_IN_COST_CODE_TEMPLATE',
    BUDGET_PERCENT_OF_DEPENDENCY = 'BUDGET_PERCENT_OF_DEPENDENCY',
    SCHEDULE_COMMENCEMENT_AND_COMPLETION = 'SCHEDULE_COMMENCEMENT_AND_COMPLETION',
    SCHEDULE_BASELINE_DATE_LOCKS = 'SCHEDULE_BASELINE_DATE_LOCKS',
    REPORTING_EXPORTER = 'REPORTING_EXPORTER',
    SSO_SETTINGS = 'SSO_SETTINGS',
    PROJECT_DELETE = 'PROJECT_DELETE',
    PROJECT_DUPLICATE = 'PROJECT_DUPLICATE',
    PROJECT_ARCHIVAL = 'PROJECT_ARCHIVAL',
    PROJECT_MANAGE_COLUMNS = 'PROJECT_MANAGE_COLUMNS',
    BULK_PROJECTS = 'BULK_PROJECTS',
    BULK_PROJECTS_REPLACE = 'BULK_PROJECTS_REPLACE',
    OAUTH_APPS = 'OAUTH_APPS',
    PROJECT_TYPES_SETTINGS = 'PROJECT_TYPES_SETTINGS',
    CONTACTS_COMPANIES_IMPORT = 'CONTACTS_COMPANIES_IMPORT',
    INVOICE_TAX_VALUE_FIELD = 'INVOICE_TAX_VALUE_FIELD',
    INTEGRATION_ENGINE_V2 = 'INTEGRATION_ENGINE_V2',
    INTEGRATION_ENGINE_V2_MENU = 'INTEGRATION_ENGINE_V2_MENU',
    INVOICES_REVISION = 'INVOICES_REVISION',
    INVOICES_EXPORT_REMOVE_COVER_PAGE = 'INVOICES_EXPORT_REMOVE_COVER_PAGE',
    BID_PACKAGES_DOWNLOAD_ALL_RFP = 'BID_PACKAGES_DOWNLOAD_ALL_RFP',
    DRAWINGS_V3_2 = 'DRAWINGS_V3_2',
    JOBSITE_LOCATIONS_MODAL_V3 = 'JOBSITE_LOCATIONS_MODAL_V3',
    CONTRACT_CHANGE_CUSTOM_RETENTION = 'CONTRACT_CHANGE_CUSTOM_RETENTION',
    INVOICES_LIST_TOTALS = 'INVOICES_LIST_TOTALS',
    COPY_BID_REQUEST = 'COPY_BID_REQUEST',
    INVOICES_REVISION_REQUEST_V2 = 'INVOICES_REVISION_REQUEST_V2',
    DASHBOARD_V2 = 'DASHBOARD_V2'
}

export default FeatureFlagsConsts
