import BidCompareByType from '../enums/BidCompareByType'
import BidOrderByType from '../enums/BidOrderByType'
import { BidRequestStoreState, BidRequest } from '../interfaces'
import CompanyType from '../enums/CompanyType'

const bidRequest: BidRequest = {
    bid_questions: [],
    bid_quoted_items: [],
    selectedSites: [],
    is_editable: true,
    is_shared: false,
}

const defaultState:  BidRequestStoreState = {
    searchString: '',
    recipientTypePopup: false,

    elementId: null,
    elementType: null,
    list: [],
    listLoaded: false,

    activeBidPackage: null,

    /**
     * Bidrequest content
     */
    model: bidRequest,
    wizardLocationFields: false,
    wizardBidManagers: false,
    wizardReferencesFiles: false,
    wizardInsuranceRequirements: false,

    costCodes: [],
    quotedItemsList: [],

    filtersData: {
        managers: [],
        bid_request_to: [],
        statuses: [],
        quote_awarded_to: [],
    },
    filtersPostData: null,

    previousBidders: [],
    isPreviousBiddersSet: false,
    quotes: [],
    openedManualBid: null,
    openedManualBidNew: false,
    afterFetch: false,

    states: {},
    divisions: [],

    invitedContactsEmails: [],
    resources: [],
    resourcesLoaded: false,
    previousResources: [],
    onSystemResources: [],
    offSystemResources: [],
    usedIds: [],

    tempCompanyHolder: null,
    sendingInvitations: false,
    popupSendInvitation: false,
    sendDisableConfirmation: false,

    selectedCompanies: [],
    selectedCompaniesCached: [],
    saveInProgress: false,
    sentToBidders: false,
    shouldSuppressMessage: false,

    biddersFilters: {
        searchKeyword: '',
        selectedCountry: null,
        selectedDivisions: [],
        selectedCities: [],
        selectedStates: [],
        selectedTags: [],
        localContacts: true,
        globalContacts: true,
        sort: null,
        source: CompanyType.WORKSPACE_COMPANY,
        selectedAppTypes: [],
    },

    bidsOrderBy: {
        type: BidOrderByType.ASC,
        label: 'Low',
    },

    bidsComparedBy: {
        type: BidCompareByType.QUOTED_ITEMS,
        label: 'Bid Form',
    },

    /**
     * List companies available for manual bid
     */
    companies: [],
    vendors: [],
    specificationUploadId: null,
    discussion_bid: null,

    revisions: [],
    timelineEvents: [],
    biddersList: [],

    /**
     * Current manual bid data
     */
    manualBid: null,

    viewBid: null,

    popup: {
        company: null
    },

    modalRevision: {
        enabled: false,
        readOnly: false,
        item: null,
        itemOrigin: null,
        type: null
    },

    /**
     * New/edited items that need revision
     */
    revision_global_sending: false,

    tabActive: null,
    tabActiveName: null,
    activeFilesTab: null,

    tabLast: null,

    /**
     * Proposed Quoted Item
     */
    proposedItem: null,
    proposedBid: null,
    reloadBid: false,

    showHiddenBids: true,
    showModal: false,
    contacts: [],

    openInviteBidderModal: false,

    areBidsLoaded: false,

    areUnfilledFields: false,

    hasSites: false,

    sitesPhasesMain: [],
    sitesPhasesAlternate: [],
    sitesPhasesProposed: [],

    closedMain: [],
    closedAlternates: [],
    closedProposed: []
}

export default defaultState
