const ProjectProvider = () => import('@/layouts/project-provider/ProjectProvider.vue')
const SpecificationsIndex = () => import('@/io-modules/specifications/views/specifications-index/SpecificationsIndex.vue')
const SpecificationsShow = () => import('@/io-modules/specifications/views/specifications-show/SpecificationsShow.vue')
const SpecificationNarrativesIndex = () => import('@/io-modules/specifications/views/specification-narratives-index/SpecificationNarrativesIndex.vue')
const SpecificationNarrativeShow = () => import('@/io-modules/specifications/views/narrative-show/SpecificationNarrativeShow.vue')
const ReviewSpecificationsShow = () => import('@/io-modules/specifications/views/review-specifications-show/ReviewSpecificationsShow.vue')

export default [
    {
        path: '/project/:pid',
        component: ProjectProvider,
        children: [
            {
                path: 'specifications',
                name: 'specifications',
                meta: {
                    navigationKey: 'specifications',
                    title: 'Specifications',
                },
                component: SpecificationsIndex,
            },
            {
                path: 'specifications/viewer/:id',
                name: 'specifications-show',
                meta: {
                    navigationKey: 'specifications',
                    showNotificationsForRoutes: ['specifications-show'],
                    title: 'Specifications',
                },
                component: SpecificationsShow,
            },
            {
                path: 'specifications/narratives/:narrativeId',
                name: 'specifications-narratives',
                props: true,
                meta: {
                    navigationKey: 'specifications',
                    title: 'Specifications',
                },
                component: SpecificationNarrativesIndex,
            },
            {
                path: 'specifications/narratives/:nid/viewer/:id',
                name: 'specifications-narratives-viewer',
                meta: {
                    navigationKey: 'specifications',
                    title: 'Specifications',
                },
                component: SpecificationNarrativeShow,
            },
            {
                path: 'specifications/review/:draftId',
                name: 'review-specifications-show',
                props: true,
                meta: {
                    navigationKey: 'specifications',
                    title: 'Specifications',
                },
                component: ReviewSpecificationsShow,
            },
        ],
    },
]
