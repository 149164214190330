import { defineComponent } from 'vue'
import FilterSectionCollapse from '../../parts/filter-section-collapse/FilterSectionCollapse.vue'
import { FilterSectionInterface } from '@/interfaces/components/lazy-list/FilterSectionInterface'

export default defineComponent({
    components: {
        FilterSectionCollapse
    },
    props: {
        filterSections: { type: Array as () => FilterSectionInterface[], default: () => [] }
    },
    data () {
        return {
            toggleSection: false
        }
    }
})
