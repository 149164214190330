<template>
    <div v-if="summary" :class="isCapitalFund ? 'io-provider-details-summary__column' : 'io-provider-details-summary'">
        <FinancialInfoTile
            :title="isCapitalFund ? 'Fund Amount' : 'Total Funding Source Amount'"
            icon-path="/new-theme/img/founding.svg"
            class="io-financial-info-tile--default"
            :value="$filters.formatCurrency(isCapitalFund ? summary.total_fund_amount : summary.total_funds_amount)"
        />
        <FinancialInfoTile
            :title="isCapitalFund ? 'Amount Unallocated to Projects' : 'Amount Allocated to Projects'"
            :icon-span="isCapitalFund ? {name: 'icon-chart-line', backgroundColor: '#EDB208'} : {name: 'icon-cash', backgroundColor: '#00E8DC'}"
            class="io-financial-info-tile"
            :value="$filters.formatCurrency(isCapitalFund ? summary.total_projects_unallocated_amount : summary.total_projects_allocated_amount)"
        />
        <FinancialInfoTile
            :title="isCapitalFund ? 'Amount Allocated to Projects' : 'Amount Unallocated to Projects'"
            :icon-span="isCapitalFund ? {name: 'icon-cash', backgroundColor: '#00E8DC'} : {name: 'icon-chart-line', backgroundColor: '#EDB208'}"
            class="io-financial-info-tile"
            :value="$filters.formatCurrency(isCapitalFund ? summary.total_projects_allocated_amount : summary.total_projects_unallocated_amount)"
        />
        <FinancialInfoTile
            title="Paid to Date"
            :icon-span="{name: 'icon-wallet', backgroundColor: '#288681'}"
            class="io-financial-info-tile"
            :value="$filters.formatCurrency(summary.total_paid_to_date_amount)"
        />
    </div>
</template>

<script lang="ts" src="./FundingSourcesSummary.ts"></script>
<style lang="scss" src="./FundingSourcesSummary.scss" scoped></style>
