<template>
    <div
        class="io-menu-lock icon-lock"
        v-tooltip.right="{ content: tooltipText }"
        :class="{ 'icon-lock-2-opened': isLocked,
                  'icon-lock-2': !isLocked }"
        @click="onToggleMenuClick"
    ></div>
</template>

<script src="./MenuLock.ts"></script>
<style lang="scss" src="./MenuLock.scss" scoped></style>
