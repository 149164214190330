<template>
    <div>
        <IOModal
            class="io-modal-select-fund"
            icon="icon-cash"
            width="680"
            :title="$t('Select Fund Type')"
            @close="closeModal"
        >
            <template #default>
                <ChooseItemRadioCard
                    :label="$t('Select one')"
                    show-required-label-mark
                    :items="fundTypes"
                    v-model="selectedFundType"
                />
            </template>
            <template #footer>
                <IOButton variant="secondary" @click="closeModal">
                    {{ $t('Cancel') }}
                </IOButton>
                <IOButton :disabled="!selectedFundType" @click="goToTheNextStep">
                    {{ $t('Next Step') }}
                </IOButton>
            </template>
        </IOModal>

        <SelectCapitalFundModal v-if="showCapitalFundsModal" @close="toggleCapitalFundsModal"/>
        <ManageProjectSpecFundModal v-if="showProjectSpecFundsModal" @closeModal="toggleProjectSpecFundsModal"/>
    </div>
</template>

<script lang="ts" src="./SelectFundTypeModal.ts"></script>
<style lang="scss" src="./SelectFundTypeModal.scss" scoped></style>
