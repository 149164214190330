import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        status: { type: String, required: true, default: null },
        options: { type: Array, default: false }
    },
    data () {
        return {
            dropdownExpanded: false
        }
    },
    computed: {
        selected (): any {
            return this.options.find((option: any) => option.id === this.status)
        },
        selectedOptionClassName (): string {
            return this.selected.class
        },
        selectedOptionText (): string {
            return this.selected.name
        }
    },
    methods: {
        toggleDropdown (): void {
            this.dropdownExpanded = !this.dropdownExpanded
        },
        closeDropdown (): void {
            this.dropdownExpanded = false
        },
        select(option: any): void {
            this.$emit('update', option.id)
        }
    }
})
