import { defineComponent } from 'vue'
import documentsFolderClient from '@/api/document/documentsFolderClient'

export default defineComponent({
    name: 'ArchiveFolderMixin',
    methods: {
        async onClickArchiveFolder (arrayId: string, folderId: string, archiveFromTable: boolean = true): Promise<void> {
            let folderHasSharedFiles = false

            const popupOptions = {
                title: this.$t('Archive Folder'),
                caption: this.$t('If you remove this folder the files contained within will be archived. Would you like to continue?'),
                icon: 'far fa-archive',
                buttons: [
                    {
                        text: this.$t('Cancel'),
                        class: 'io-btn-light',
                        action: null
                    },
                    {
                        text: this.$t('Yes, archive'),
                        class: 'io-btn-primary',
                        action: async () => {
                            await this.deleteFolder(arrayId, folderId, archiveFromTable)
                        }
                    }
                ]
            }

            // @note check if folder can be deleted
            try {
                const { data } = await documentsFolderClient.hasSharedDocument(folderId)
                folderHasSharedFiles = data.folder_has_shared_document

                if (folderHasSharedFiles) {
                    popupOptions.caption = this.$t('This folder contains shared files and cannot be removed.')
                    popupOptions.buttons = [
                        {
                            text: this.$t('Cancel'),
                            class: 'io-btn-light',
                            action: null
                        }
                    ]
                }

                this.showPopupAlert(popupOptions)
            } catch (error) {
                this.showNotification('error', error.message)
            }
        },
        async deleteFolder (arrayId: string, folderId: string, archiveFromTable: boolean): Promise<void> {
            try {
                await documentsFolderClient.archiveFolder(folderId)

                if (archiveFromTable) {
                    this.$store.dispatch('storage/setRecalculateUsedStorage')
                    this.$store.dispatch('filesComponent/removeMyFolder', {
                        arrayId: arrayId,
                        folderId: folderId
                    })
                    this.$emit('refreshData')
                } else {
                    this.goBack()
                }
            } catch (error) {
                this.showNotification('error', error.message)
            }
        },
    }
})
