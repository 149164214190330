import { defineComponent } from 'vue'
import PopupModal from '@/components/popup-modal/PopupModal.vue'
import FormInputColumn from '@/components/atoms/FormInputColumn/FormInputColumn.vue'
import Shared from '@/mixins/financial-approvals/Shared'
import ApprovalType from '@/interfaces/modules/projects/financial-approvals/ApprovalType'
import { mapGetters } from 'vuex'
import ApprovalTypeRadio from '@/components/financial-approvals/controls/approval-type-radio/ApprovalTypeRadio.vue'
import ApproverAssignmentType from '@/interfaces/modules/projects/financial-approvals/ApproverAssignmentType'
import ApprovalNoteVisibility from '@/io-modules/approval-workflows/enums/ApprovalNoteVisibility'
import ClientApprovalSetting from '@/interfaces/modules/projects/financial-approvals/ClientApprovalSetting.ts'

export default defineComponent({
    name: 'PopupMixin',
    components: {
        ApprovalTypeRadio,
        PopupModal,
        FormInputColumn,
    },
    mixins: [Shared],
    data () {
        return {
            loading: false as boolean,
            approvalType: null as ApprovalType,
            isNotePublic: true,
        }
    },
    computed: {
        ...mapGetters('financialApprovals', {
            isLastApprover: 'isLastApprover',
            userApproverAssignmentType: 'userApproverAssignmentType',
            approveActionText: 'approveActionText',
            isSendToClientEnabled: 'isSendToClientEnabled',
            currentResourceId: 'currentResourceId',
            currentChangeOrder: 'currentChangeOrder'
        }),

        isSendToClientEnabledCheck () : boolean {
            if (this.currentChangeOrder) {
                return this.currentChangeOrder.client_approval_setting === ClientApprovalSetting.SEND_TO_CLIENT
            }

            return this.isSendToClientEnabled;
        },

        approveAndSubmitToClientText (): string {
            return this.$tc('Approve & Submit to Client')
        },

        approveAndHoldText (): string {
            return ApproverAssignmentType.APPROVER === this.userApproverAssignmentType
                ? this.$tc('Approve & Hold')
                : this.$tc('Review & Hold')
        },

        approveTitleText (): string {
            return ApproverAssignmentType.APPROVER === this.userApproverAssignmentType
                ? this.$tc('Approve')
                : this.$tc('Review')
        },

        approveAdjectiveText (): string {
            return ApproverAssignmentType.APPROVER === this.userApproverAssignmentType
                ? this.$tc('Approving')
                : this.$tc('Reviewing')
        },

        noteVisibility (): ApprovalNoteVisibility {
            return this.isNotePublic
                ? ApprovalNoteVisibility.PUBLIC
                : ApprovalNoteVisibility.WORKSPACE
        },
    },
    methods: {
        closePopup (): void {
            this.$emit('close')
        },

        approvalTypeChanged (value: ApprovalType): void {
            this.approvalType = value
        },
    }
})
