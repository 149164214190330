<template>
    <IOModal
        color="blue"
        :width="680"
        :title="$t('Select Project Site Location(s)')"
        icon="icon-home-3"
    >
        <template #default>
            <AlertBox :style="{ marginBottom: '32px' }">
                <template #default>
                    {{ $t('Choose from project site locations configured in') }}
                    <IOButton variant="link" size="small" @click="goToBudget">
                        {{ $t('Master Project Budget / ACR') }}
                    </IOButton>
                </template>
            </AlertBox>

            <BudgetSiteSelect
                :project-id="projectID"
                :budget-type="budgetType"
                :preselected-site-ids="preselectedSites"
                :multiple="true"
                can-uncheck-preselected-sites
                v-model="sitesSelected"
            />
        </template>

        <template #footer>
            <IOButton
                variant="secondary"
                @click="$emit('close')"
            >
                {{ $t('Cancel') }}
            </IOButton>

            <IOButton
                @click="selectSites"
            >
                {{ $t('Select') }}
            </IOButton>
        </template>
    </IOModal>
</template>

<script lang="ts" src="./SelectProjectSiteModal.ts"></script>
