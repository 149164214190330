import { ObjectUtils } from '@/components/atoms/DropdownButtonMultilayer/parts/TieredMenu/parts/utils'
import { isEmpty } from 'lodash'
import { Item } from '../TieredMenu'

export default {
    name: 'TieredMenuSub',
    emits: ['item-click', 'item-mouseenter'],
    props: {
        menuId: {
            type: String,
            default: null,
        },
        focusedItemId: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            default: null,
        },
        level: {
            type: Number,
            default: 0,
        },
        templates: {
            type: Object as () => { item, submenuicon, itemicon },
            default: null,
        },
        activeItemPath: {
            type: Object,
            default: null,
        },
        exact: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        getItemId (processedItem: Item): string {
            return `${ this.menuId }_${ processedItem.key }`
        },
        getItemKey (processedItem: Item): string {
            return this.getItemId(processedItem)
        },
        getItemProp (processedItem: Item, name: string, params?: Object): any {
            return processedItem && processedItem.item ? ObjectUtils.getItemValue(processedItem.item[name], params) : undefined
        },
        getItemLabel (processedItem: Item): string {
            return this.getItemProp(processedItem, 'label')
        },
        isItemActive (processedItem: Item): boolean {
            return this.activeItemPath.some((path) => path.key === processedItem.key)
        },
        isItemVisible (processedItem: Item): boolean {
            return this.getItemProp(processedItem, 'visible') !== false
        },
        isItemDisabled (processedItem: Item): boolean {
            return this.getItemProp(processedItem, 'disabled')
        },
        isItemFocused (processedItem: Item): boolean {
            return this.focusedItemId === this.getItemId(processedItem)
        },
        isItemGroup (processedItem: Item): boolean {
            return !isEmpty(processedItem.items)
        },
        onItemClick (event, processedItem: Item): void {
            this.getItemProp(processedItem, 'command', { originalEvent: event, item: processedItem.item })
            this.$emit('item-click', { originalEvent: event, processedItem, isFocus: true })
        },
        onItemMouseEnter (event, processedItem: Item): void {
            this.$emit('item-mouseenter', { originalEvent: event, processedItem })
        },
        onItemActionClick (event: Event, navigate: Function): void {
            navigate && navigate(event)
        },
        getAriaSetSize (): number {
            return this.items.filter((processedItem) => this.isItemVisible(processedItem) && !this.getItemProp(processedItem, 'separator')).length
        },
        getAriaPosInset (index: number): number {
            return index - this.items.slice(0, index).filter((processedItem) => this.isItemVisible(processedItem) && this.getItemProp(processedItem, 'separator')).length + 1
        },
        getItemClass (processedItem: Item): string[] {
            return [
                'io-menuitem',
                this.getItemProp(processedItem, 'class'),
                {
                    'io-menuitem-active p-highlight': this.isItemActive(processedItem),
                    'p-focus': this.isItemFocused(processedItem),
                    'p-disabled': this.isItemDisabled(processedItem),
                },
            ]
        },
        getItemActionClass (processedItem: Item, routerProps?: Object) {
            return [
                'io-menuitem-link',
                {
                    'router-link-active': routerProps && routerProps.isActive,
                    'router-link-active-exact': this.exact && routerProps && routerProps.isExactActive,
                },
            ]
        },
        getItemIconClass (processedItem: Item): string[] {
            return ['io-menuitem-icon', this.getItemProp(processedItem, 'icon')]
        },
        getSeparatorItemClass (processedItem: Item): string[] {
            return ['io-menuitem-separator', this.getItemProp(processedItem, 'class')]
        },
    },
}
