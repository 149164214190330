<template>
    <td>
        <div class="io-single-contact">
            <div class="io-single-contact__row">
                <div class="io-single-contact__cell io-single-contact__cell--main">
                    <div
                        v-if="data.avatar"
                        class="io-avatar-holder__avatar"
                        :style="getBackgroundAvatar(data.avatar)"
                    ></div>
                    <Initials
                        v-else
                        class="io-avatar-holder__initials"
                        :bigAvatar="true"
                        :class="{ 'io-offsystem': data.off_system }"
                        :fullName="`${ data.firstname } ${ data.lastname }`"
                    />
                    <div class="io-single-contact__details">
                        <div class="io-single-contact__text">
                        <span class="io-single-contact__text io-single-contact__text--dark">
                            {{ data.firstname }} {{ data.lastname }}
                        </span>
                            <span class="io-dot"></span>
                            {{ data.company_name }}
                        </div>
                        <div class="io-single-contact__text io-single-contact__text--small">
                            <StatusPill
                                v-if="data.app_type"
                                size="normal"
                                :variant="statusClass(data.app_type)"
                                :text="data.app_type"
                            />
                            <span v-else class="io-small-pill io-grey">
                            {{ $t('OFF-SYSTEM') }}
                        </span>
                            {{ data.email }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </td>
</template>

<script lang="ts" src="./SingleContact.ts"></script>
<style lang="scss" src="./SingleContact.scss" scoped></style>
