<template>
    <div class="io-invoices-actions-wrapper">
        <FiltersButton :disabled="showMainPlaceholder || showContentLoader"/>
        <div class="io-invoices-actions">
            <ActionDropdown v-if="$route.meta.list" :show-menu="false" :icon-tooltip="$t('Export to Excel')" @click="$emit('openExportModal')" />
            <router-link
                v-if="(isOwner || isOwnerRep) && $route.meta.list"
                :to="{ name: selectRouteName, params: { pid: $route.params.pid } }"
            >
                <IOButton variant="secondary">{{ $t('View Funding Sources') }}</IOButton>
            </router-link>
            <IOButton
                icon="icon-plus"
                @click="createInvoice"
            >
                {{ $t('Create') }}
            </IOButton>
        </div>
    </div>
</template>

<script lang="ts" src="./TopbarActions.ts"></script>
<style lang="scss" src="./TopbarActions.scss" scoped></style>
