import { defineStore } from 'pinia'
import contractClient from '@/io-modules/project-contracts/api-clients/contractV3.ts'
import ContractType from '@/base/ContractType.ts'
import { InsuranceRequirement } from '@/interfaces/modules/list-templates/InsuranceRequirementsInterface.ts'
import { GmpContractPhase } from '@/interfaces/modules/contract/GmpContractPhase.ts'
import { Contact } from '@/interfaces/components/invite-modal/Contact.ts'
import SingleModelDocument from '@/components/simple-file-list/interfaces/SingleModelDocument.ts'
import {
    ContractCreatePayload,
    ContractEditPayload,
} from '@/io-modules/project-contracts/interfaces/ContractWizardPayload.ts'
import { Phase, QuotedItem, Task } from '@/io-modules/project-contracts/interfaces/WbsGroup.ts'
import AgreementTypeOption from '@/io-modules/project-contracts/interfaces/AgreementTypeOption.ts'
import ContractHolderOption from '@/io-modules/project-contracts/interfaces/ContractHolderOption.ts'
import { WbsPhase, WbsQuotedItem, WbsTask } from '@/io-modules/project-contracts/interfaces/WbsItem.ts'
import WbsType from '@/io-modules/project-contracts/enums/WbsType.ts'
import ContractStatus from '@/io-modules/project-contracts/enums/ContractStatus.ts'
import ContractWizardTab from '@/io-modules/project-contracts/enums/ContractWizardTab.ts'
import ContractSovStatus from '@/io-modules/project-contracts/enums/ContractSovStatus.ts'
import ContractHolderType from '@/io-modules/project-contracts/enums/ContractHolderType.ts'
import ContractExecutionType from '@/io-modules/project-contracts/enums/ContractExecutionType.ts'
import ContractCreationMode from '@/io-modules/project-contracts/enums/ContractCreationMode.ts'
import { DeprecatedFlags } from '@/io-modules/project-contracts/interfaces/DeprecatedFlags.ts'
import ContractAgreementType from '@/io-modules/project-contracts/enums/ContractAgreementType.ts'
import ContractChanges from '@/io-modules/project-contracts/interfaces/ContractChanges.ts'
import {
    formatDate,
    formatQuotedItem,
    formatRequirement,
} from '@/io-modules/project-contracts/utils/formatUtilities.ts'
import areSitesValid from '@/io-modules/project-contracts/utils/areSitesValid.ts'

export type ContractWizardState = {
    filesArrayId: string,
    isDisplayUnitsEnabled: boolean,
    isDisplayRetentionEnabled: boolean,
    searchString: string,

    clientProjectId: string,
    mainContractId: string,
    mainContractWbs: WbsQuotedItem[],
    mainContractSites: { id: string, name: string }[],
    contractChanges: ContractChanges,
    mainContractAgreementType: ContractAgreementType,

    isMainContract: boolean,
    isClient: boolean,
    isVendor: boolean,
    contractExecutionType: ContractExecutionType,
    executionNote: string,
    executionFiles: SingleModelDocument[],

    isContractWizardLoading: boolean,
    activeWizardTab: ContractWizardTab,
    status: ContractStatus,
    sovStatus: ContractSovStatus,
    name: string,
    client: Contact,
    vendor: Contact,
    sendInvitation: boolean,
    holder: ContractHolderOption,
    agreementType: AgreementTypeOption,
    projectManagers: Contact[],
    type: ContractType,
    initiationDate: string,
    effectiveDate: string,
    uploadedFiles: SingleModelDocument[],
    insuranceRequirements: InsuranceRequirement[],
    insuranceExhibits: SingleModelDocument[],
    retention: number,
    lienWaiverTemplate: {
        id: string,
        name: string,
    },
    wbsItems: WbsQuotedItem[],
    quotedItemsTemplateId: string,
    quotedItemsTemplateIsList: boolean,
    quotedItemsTemplateMasterList: object[],
    holderType: ContractHolderType,
    initialClient: Contact,
    initialVendor: Contact,
    initialProjectManagers: Contact[],
    id: string,
    deprecatedFlags: DeprecatedFlags,
    activeSiteId: string,
    initialSites: { id: string, name: string }[],

    isIdUnique: boolean,
}

const contractWizardInitialState: ContractWizardState = {
    filesArrayId: null as string,
    isDisplayUnitsEnabled: false as boolean,
    isDisplayRetentionEnabled: true as boolean,
    searchString: null as string,

    clientProjectId: null as string,
    mainContractId: null as string,
    mainContractWbs: null as WbsQuotedItem[],
    mainContractSites: [],
    contractChanges: null as ContractChanges,
    mainContractAgreementType: null as ContractAgreementType,

    isMainContract: null as boolean,
    isClient: null as boolean,
    isVendor: null as boolean,
    contractExecutionType: null as ContractExecutionType,
    executionNote: null as string,
    executionFiles: [] as SingleModelDocument[],

    isContractWizardLoading: true as boolean,
    activeWizardTab: ContractWizardTab.Details,
    status: null as ContractStatus,
    sovStatus: null as ContractSovStatus,
    id: null as string,
    name: null as string,
    client: null as Contact,
    vendor: null as Contact,
    sendInvitation: true as boolean,
    holder: null as ContractHolderOption,
    agreementType: null as AgreementTypeOption,
    projectManagers: [] as Contact[],
    type: null as ContractType,
    initiationDate: null as string,
    effectiveDate: null as string,
    uploadedFiles: [] as SingleModelDocument[],
    insuranceRequirements: [] as InsuranceRequirement[],
    insuranceExhibits: [] as SingleModelDocument[],
    retention: null as number,
    lienWaiverTemplate: {
        id: null as string,
        name: null as string,
    },
    wbsItems: [] as WbsQuotedItem[],
    quotedItemsTemplateId: null as string,
    quotedItemsTemplateIsList: false as boolean,
    quotedItemsTemplateMasterList: [] as object[],
    holderType: null as ContractHolderType,

    initialClient: null as Contact,
    initialVendor: null as Contact,
    initialProjectManagers: [] as Contact[],

    deprecatedFlags: null as DeprecatedFlags,
    activeSiteId: null as string,
    initialSites: [],

    isIdUnique: true as boolean,
}

export const contractWizardStore = defineStore('contractWizard', {
    state: (): ContractWizardState => structuredClone(contractWizardInitialState),
    getters: {
        wbsItemsMain (): WbsQuotedItem[] {
            return this.wbsItems.filter((item) => !item.isAlternate).sort((a, b) => a.number - b.number)
        },
        wbsItemsMainTotalValue (): number {
            let summaryValue = 0

            this.wbsItemsMain.forEach(item => summaryValue += Number(item.value))

            return summaryValue
        },
        wbsItemsMainInitialTotalValue (): number {
            let summaryValue = 0

            this.wbsItemsMain.forEach(item => summaryValue += Number(item.initialValue))

            return summaryValue
        },
        wbsItemsMainTotalRetention (): number {
            let summaryRetention = 0

            this.wbsItemsMain.forEach(item => summaryRetention += Number(item.value) * Number(item.retention))

            return (summaryRetention / this.wbsItemsMainTotalValue) || 0
        },
        wbsItemsAlternate (): WbsQuotedItem[] {
            return this.wbsItems.filter((item) => item.isAlternate).sort((a, b) => a.number - b.number)
        },
        wbsItemsAlternateTotalValue (): number {
            let summaryValue = 0

            this.wbsItemsAlternate.forEach(item => summaryValue += Number(item.value))

            return summaryValue
        },
        wbsItemsAlternateTotalRetention (): number {
            let summaryRetention = 0

            this.wbsItemsAlternate.forEach(item => summaryRetention += Number(item.value) * Number(item.retention))

            return (summaryRetention / this.wbsItemsAlternateTotalValue) || 0
        },
        originalScopeQIs (): WbsQuotedItem[] {
            return this.wbsMainItemsFromActiveSite.filter((item) => !Boolean(item.contract_change_id))
        },
        executedCOsQIs (): WbsQuotedItem[] {
            return this.wbsMainItemsFromActiveSite.filter((item) => Boolean(item.contract_change_id))
        },
        contractCreatePayload (): ContractCreatePayload {
            return {
                //TODO: Remove user_contract_id here and on BE side
                user_contract_id: this.id,
                unique_id: this.id,
                name: this.name,
                project_id: this.clientProjectId,
                vendor_contact_person_id: this.isMainContract ? null : this.vendor?.id,
                client_contact_person_id: this.isMainContract ? this.client?.id : null,
                contract_holder: this.isMainContract ? null : this.holder?.value,
                agreement_type: this.isMainContract ? this.agreementType?.value : null,
                project_manager_ids: this.projectManagers.map(item => item.id).filter(id => !!id),
                contract_type: this.type,
                initiation_date: formatDate(this.initiationDate),
                effective_date: formatDate(this.effectiveDate),
                documents: this.uploadedFiles.map(file => file._id),
                insurance_requirements: this.insuranceRequirements.map(formatRequirement),
                insurance_exhibits_ids: this.insuranceExhibits.map(item => item._id),
                status: null,
                initial_status: null,
                retention: this.retention || 0,
                lien_waiver_configuration: {
                    file_id: this.lienWaiverTemplate.id
                },
                send_invitation: this.sendInvitation,
                execution_note: this.executionNote || '',
                execution_documents: this.executionFiles.map(file => file._id),
                wbs: {
                    display_units: this.isDisplayUnitsEnabled,
                    display_retention: this.isDisplayRetentionEnabled,
                    quoted_items: this.wbsItems.map(formatQuotedItem),
                    type: GmpContractPhase.ORIGINAL,
                },
                quoted_items_template_id: this.quotedItemsTemplateId,
            }
        },
        contractEditPayload (): ContractEditPayload {
            return {
                unique_id: this.id,
                name: this.name,
                vendor_contact_person_id: this.vendor?.id,
                client_contact_person_id: this.client?.id,
                contract_holder: this.isMainContract ? null : this.holder?.value,
                agreement_type: this.isMainContract ? this.agreementType?.value : null,
                project_manager_ids: this.projectManagers.map(item => item.id),
                initiation_date: formatDate(this.initiationDate),
                effective_date: formatDate(this.effectiveDate),
                wbs: {
                    display_units: this.isDisplayUnitsEnabled,
                    display_retention: this.isDisplayRetentionEnabled,
                    quoted_items: this.wbsItems.map(formatQuotedItem),
                    type: GmpContractPhase.ORIGINAL,
                },
                insurance_requirements: this.insuranceRequirements.map(formatRequirement),
                insurance_exhibits_ids: this.insuranceExhibits.map(item => item._id),
                retention: this.retention,
                lien_waiver_configuration: {
                    file_id: this.lienWaiverTemplate.id
                },
                quoted_items_template_id: this.quotedItemsTemplateId,
            }
        },
        wbsItemsFromActiveSite (): WbsQuotedItem[] {
            return this.activeSiteId
                ? this.wbsItems.filter((item) => item.projectSiteId === this.activeSiteId)
                : this.wbsItems
        },
        wbsMainItemsFromActiveSite (): WbsQuotedItem[] {
            return this.activeSiteId
                ? this.wbsItemsMain.filter((item) => item.projectSiteId === this.activeSiteId)
                : this.wbsItemsMain
        },
        wbsAlternateItemsFromActiveSite (): WbsQuotedItem[] {
            return this.activeSiteId
                ? this.wbsItemsAlternate.filter((item) => item.projectSiteId === this.activeSiteId)
                : this.wbsItemsAlternate
        },
        areSitesValid (): Record<string, boolean> {
            return areSitesValid(this.wbsItems)
        },
        initialSiteIds (): string[] {
            return this.initialSites.map(site => site.project_site_id)
        },
        hasExecutedCOs (): boolean {
            return ContractType.GMP === this.summary?.type
                ? this.amendments?.length > 0
                : this.changeOrders?.length > 0
        },
        changeOrders (): any[] {
            return this.contractChanges?.change_orders || []
        },
        amendments (): any[] {
            return this.contractChanges?.amendments || []
        },
    },
    actions: {
        resetContractWizardState (): void {
            this.$reset()
        },

        async getContractDetails (contractId: string): Promise<void> {
            await Promise.all([
                this.getContractSummary(contractId),
                this.getContractDocuments(contractId),
                this.getInsuranceDetails(contractId),
            ])

            await this.getSovWbs(
                contractId,
                ContractType.STANDARD === this.type || ContractType.GMP === this.type && ContractStatus.EXECUTED === this.status
                    ? WbsType.Original
                    : WbsType.Initial,
            )

            await this.getContractChanges(contractId)
        },

        async getContractSummary (contractId: string): Promise<void> {
            const { data } = await contractClient.getContractSummary(contractId)

            this.status = data.contract_status.status
            this.sovStatus = data.sov_status.status
            this.id = data.unique_id
            this.name = data.name
            this.initialClient = data.client
                ? {
                    id: data.client.person_id,
                    firstname: data.contact?.firstname,
                    lastname: data.contact?.lastname,
                    email: data.contact?.email,
                    is_on_system: data.contact?.is_on_system,
                    workspace: {
                        id: data.client.id,
                        name: data.client.name,
                        app_type: data.client.type,
                    },
                }
                : null
            this.initialVendor = data.vendor
                ? {
                    id: data.vendor.person_id,
                    firstname: data.contact?.firstname,
                    lastname: data.contact?.lastname,
                    email: data.contact?.email,
                    is_on_system: data.contact?.is_on_system,
                    workspace: {
                        id: data.vendor.id,
                        name: data.vendor.name,
                        app_type: data.vendor.type,
                    },
                }
                : null
            this.initialProjectManagers = data.project_managers.map(item => {
                return {
                    id: item.id,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    email: item.email,
                    is_on_system: item.is_on_system,
                    workspace: {
                        id: item.company_id,
                        name: item.company_name,
                        app_type: item.app_type,
                    },
                }
            })
            this.holderType = data.contract_holder.type
            this.type = data.type
            this.initiationDate = data.initiation_date
            this.effectiveDate = data.effective_date
            this.retention = data.retention
            this.isMainContract = data.is_main
            this.isClient = data.is_client
            this.isVendor = data.is_vendor
            this.deprecatedFlags = data.deprecated_flags
            this.sendInvitation = data.should_send_invitation
        },

        async getContractDocuments (contractId: string): Promise<void> {
            const { data } = await contractClient.getContractDocuments(contractId)

            this.uploadedFiles = data.agreement_documents
            this.insuranceExhibits = data.supporting_documents.insurance_exhibit
            this.lienWaiverTemplate.id = data.supporting_documents.lien_waiver_template?.file_id
            this.lienWaiverTemplate.name = data.supporting_documents.lien_waiver_template?.file_name
        },

        async getInsuranceDetails (contractId: string): Promise<void> {
            if (this.insuranceRequirements.length > 0) {
                return
            }

            const { data } = await contractClient.getInsuranceDetails(contractId)

            this.insuranceRequirements = data.insurance_requirements
        },

        async getSovWbs (contractId: string, type: WbsType): Promise<void> {
            const { data } = await contractClient.getSovWbs(contractId, type)

            this.initialSites = data.project_sites

            this.wbsItems = data.quoted_items.map((item: QuotedItem): WbsQuotedItem => {
                return {
                    contract_change_id: item.contract_change_id,
                    contract_change_relates_to_id: item.contract_change_relates_to_id,
                    has_related_contract_changes: item.has_related_contract_changes,
                    id: item.id,
                    number: item.number,
                    number_global: item.number_global,
                    lvl: item.lvl,
                    name: item.name,
                    value: item.value,
                    initialValue: item.value,
                    costCode: item.cost_code?.id,
                    retention: item.retention,
                    isAlternate: item.is_alternate,
                    enabled: item.enabled,
                    selected: false,
                    focus: false,
                    sovLine: item.contract_quoted_item,
                    unit: item.unit,
                    unitPrice: item.unit_price,
                    qty: item.qty,
                    projectSiteId: item.project_site_id,
                    tasks: item.phases.map((phase: Phase): WbsPhase => {
                        return {
                            contract_change_id: phase.contract_change_id,
                            contract_change_relates_to_id: phase.contract_change_relates_to_id,
                            has_related_contract_changes: phase.has_related_contract_changes,
                            parent_has_contract_changes: phase.parent_has_contract_changes,
                            id: phase.id,
                            number: phase.number,
                            number_global: phase.number_global,
                            lvl: phase.lvl,
                            description: phase.description,
                            value: phase.value,
                            retention: phase.retention,
                            parent: phase.parent_id,
                            costType: phase.cost_type,
                            unit: phase.unit,
                            unitPrice: phase.unit_price,
                            qty: phase.qty,
                            tasks: phase.tasks.map((task: Task): WbsTask => {
                                return {
                                    contract_change_id: task.contract_change_id,
                                    contract_change_relates_to_id: task.contract_change_relates_to_id,
                                    has_related_contract_changes: task.has_related_contract_changes,
                                    parent_has_contract_changes: task.parent_has_contract_changes,
                                    id: task.id,
                                    number: task.number,
                                    number_global: task.number_global,
                                    lvl: task.lvl,
                                    description: task.description,
                                    value: task.value,
                                    retention: task.retention,
                                    parent: task.parent_id,
                                    costType: task.cost_type,
                                    unit: task.unit,
                                    unitPrice: task.unit_price,
                                    qty: task.qty,
                                }
                            }),
                        }
                    }),
                }
            })

            this.isDisplayRetentionEnabled = data.display_retention
            this.isDisplayUnitsEnabled = data.display_units
        },

        async createFromBidRequest (bidRequestId: string, invite: boolean): Promise<string> {
            const { data } = await contractClient.createFromBidRequest(bidRequestId, invite)

            return data
        },

        async update (contractId: string, selectedSiteIds: string[] = []): Promise<void> {
            await contractClient.save(contractId, {
                ...this.contractEditPayload,
                project_sites: selectedSiteIds,
            })
        },

        async create (status: ContractStatus, selectedSiteIds: string[] = []): Promise<string> {
            const { data } = await contractClient.create({
                ...this.contractCreatePayload,
                status: status,
                creation_mode: this.contractExecutionType === ContractExecutionType.STANDARD
                    ? ContractCreationMode.STANDARD
                    : ContractCreationMode.FAST_TRACK,
                project_sites: selectedSiteIds,
            })

            return data
        },

        async getMainContractWbs (): Promise<void> {
            const { data } = await contractClient.getMainContractInfo(this.clientProjectId)

            this.mainContractWbs = data.wbs.quoted_items
            this.mainContractSites = data.wbs.sites
        },

        async getMainContractAgreementType (globalProjectId: string): Promise<void> {
            const { data } = await contractClient.getMainContractAgreementType(globalProjectId)

            this.mainContractAgreementType = data.agreement_type
        },

        async getContractChanges (contractId: string): Promise<void> {
            const { data } = await contractClient.getContractChanges(contractId)

            this.contractChanges = data
        },
    },
})
