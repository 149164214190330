<template>
    <RightModal
        customClass="io-manage-invoices-columns"
        @escDown="setEscPressed(true)"
        @escUp="setEscPressed(false)"
    >
        <template #header>
            <div class="io-manage-columns-header">
                <div class="io-f-row io-f-start">
                    <span class="io-manage-columns-header__icon icon-grid-14"></span>
                    <span class="io-manage-columns-header__title">{{ $t('Manage columns') }}</span>
                </div>
                <span class="io-manage-columns-header__icon icon-cross" @click="closeModal"></span>
            </div>
        </template>
        <template #default>
            <div class="io-manage-columns-content">
                <div class="io-columns-list">
                    <div v-if="!fixedColumnsCopy.length" class="io-no-data-block">
                        <div class="io-no-data-block__content" :class="{'io-no-data-block__content--drop': moveToEmptyList}">
                            <p class="io-no-data-block__title">{{ $t('No Fixed Columns') }}</p>
                            <p class="io-no-data-block__subtitle">{{ $t('You can drag and drop columns here') }}</p>
                        </div>
                    </div>
                    <div v-else class="io-columns-list__header">
                        <Checkbox
                            :labelText="$t('Fixed Columns')"
                            :no-value-validation="true"
                            :partial-check="allFixedPartialCheck"
                            :checkbox-value="allFixedVisible"
                            v-model="allFixedVisible"
                        />
                    </div>
                    <Draggable
                        group="columns"
                        ghost-class="io-drag-ghost"
                        class="io-columns-list"
                        fallback-class="io-drag-item"
                        handle=".io-columns-list__item"
                        :force-fallback="true"
                        :class="{'io-columns-list--empty': moveToEmptyList}"
                        :list="fixedColumnsCopy"
                        :move="handleMove"
                        @start="drag = true"
                        @end="drag = false"
                        :item-key="item => `${ fixedColumnsCopy.indexOf(item) }-col-fixed`"
                    >
                        <template #item="{ element: column }" >
                            <div
                                v-if="fixedColumnsCopy.length"
                                class="io-columns-list__item"
                            >
                                <div class="io-columns-list__item-content">
                                    <span class="icon-drag"></span>
                                    <Checkbox
                                        :labelText="$t(column.text)"
                                        :checkbox-value="column.visible"
                                        :no-value-validation="true"
                                        v-model="column.visible"
                                    />
                                    <Tooltip v-if="column.manageInfo" :text="column.manageInfo"/>
                                </div>
                            </div>
                        </template>
                    </Draggable>
                </div>
                <div class="io-columns-list">
                    <div v-if="scrollableColumnsCopy.length" class="io-columns-list__header">
                        <Checkbox
                            :labelText="$t('Scrollable Columns')"
                            :no-value-validation="true"
                            :partial-check="allScrollablePartialCheck"
                            :checkbox-value="allScrollableVisible"
                            v-model="allScrollableVisible"
                        />
                    </div>
                    <Draggable
                        group="columns"
                        ghost-class="io-drag-ghost"
                        class="io-columns-list"
                        fallback-class="io-drag-item"
                        handle=".io-columns-list__item"
                        :force-fallback="true"
                        :class="{'io-columns-list--empty': moveToEmptyList}"
                        :list="scrollableColumnsCopy"
                        :move="handleMove"
                        @start="drag = true"
                        @end="drag = false"
                        :item-key="item => `${ scrollableColumnsCopy.indexOf(item) }-col-scrollable`"
                    >
                        <template #item="{ element: column }" >
                            <div v-if="!scrollableColumnsCopy.length" class="io-no-data-block">
                                <div class="io-no-data-block__content" :class="{'io-no-data-block__content--drop': moveToEmptyList}">
                                    <p class="io-no-data-block__title">{{ $t('No Scrollable Columns') }}</p>
                                    <p class="io-no-data-block__subtitle">{{ $t('You can drag and drop columns here') }}</p>
                                </div>
                            </div>
                            <div
                                v-else
                                class="io-columns-list__item"
                            >
                                <div class="io-columns-list__item-content">
                                    <span class="icon-drag"></span>
                                    <Checkbox
                                        :labelText="$t(column.text)"
                                        :no-value-validation="true"
                                        :checkbox-value="column.visible"
                                        v-model="column.visible"
                                    />
                                    <Tooltip v-if="column.manageInfo" :text="column.manageInfo"/>
                                </div>
                            </div>
                        </template>
                    </Draggable>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="io-manage-columns-footer">
                <IOButton variant="secondary" @click="closeModal">{{ $t('Cancel') }}</IOButton>
                <div class="io-tooltip-wrapper">
                    <IOButton
                        :disabled="saveButtonDisabled"
                        @click="saveChanges"
                    >
                        {{ $t('Save') }}
                    </IOButton>
                    <div v-if="saveButtonDisabled" class="io-tooltip io-tooltip--top-start">
                        {{ saveButtonTooltipText }}
                    </div>
                </div>
            </div>
        </template>
    </RightModal>
</template>

<script lang="ts" src="./ManageColumnsModal.ts"></script>
<style lang="scss" src="./ManageColumnsModal.scss" scoped></style>
