import { defineComponent } from 'vue'
import SvgIconComponent from './svg-icon-component/SvgIconComponent.vue'
import { mapGetters } from 'vuex'
import DropdownButtonNew from '@/components/atoms/DropdownButtonNew/DropdownButtonNew.vue'

export default defineComponent({
    name: 'SingleMarkup',
    components: {
        DropdownButtonNew,
        SvgIconComponent
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        data: { type: Object }
    },
    data () {
        return {
            svgIconTypes: ['ARROW', 'ARC', 'LINE', 'POLYLINE', 'POLYGON', 'ELLIPSE', 'CLOUD', 'TEXT']
        }
    },
    computed: {
        ...mapGetters('drawingMarkup', {
            getSelectedExistingAnnotation: 'getSelectedExistingAnnotation'
        }),
        isSelected (): boolean {
            return this.getSelectedExistingAnnotation.uuid === this.data.uuid &&
                this.getSelectedExistingAnnotation.type === this.data.type
        },
        getToolIcon (): string {
            const mappedIcons = [
                {
                    name: 'MEASUREMENT',
                    class: 'icon-ruler'
                },
                {
                    name: 'STAMP',
                    class: 'icon-image-3'
                }
            ]

            return mappedIcons.find(icon => icon?.name === this.data?.tool_name)?.class
        }
    },
    methods: {
        openSharingSettingsPopup (): void {
            this.$store.commit('drawingsV2/SET_SHARE_MARKUP_POPUP_SHOWN', true)
            this.$store.commit('drawingsV2/SET_SHARE_MARKUP_DATA', [this.data])
        },
        deleteMarkup (): void {
            this.$emit('delete-pdftron-markup', this.data.id)
            this.$emit('annotation-deleted', this.data.uuid)
        },
        selectAnnotation (): void {
            this.$store.commit('drawingMarkup/SET_SELECTED_EXISTING_ANNOTATION', {
                uuid: this.data.uuid,
                type: this.annotationType ?? this.data.type
            })
        }
    },
})
